import React from 'react';

const IconHelp = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M7 7L10 10" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 10L17 7" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14 14L17 17" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 14L7 17" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconHelp;
