export const JOB_DETAIL_ACTIONS = {
    JOB_DETAIL: 'ACTION_OPEN_JOB_DETAIL',
    CLOSE_JOB_DETAIL: 'ACTION_CLOSE_JOB_DETAIL'
};

export const actionOpenJobDetail = (payload) => {
    return {
        type: JOB_DETAIL_ACTIONS.JOB_DETAIL,
        payload
    };
};

export const actionCloseJobDetail = () => {
    return {
        type: JOB_DETAIL_ACTIONS.CLOSE_JOB_DETAIL
    };
};
