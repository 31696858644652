import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';
import BoxRows from '../BoxRows';
import AddressDropdown from './AddressDropdown';
import AddressInfo from './AddressInfo';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_STATUS } from 'app/modules/customer/const';

const AddressService = ({ defaultStatus = 1, ...props }, ref) => {
    const { t } = useTranslation(['customers']);
    const { service_location, updateContext } = useContext(AddCustomerContext);
    const refLocationName = useRef(null);
    const refAddressInfo = useRef(null);
    const refAddress = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    useEffect(() => {
        refAddressInfo.current.setValue({
            ...service_location.service_address,
            name: service_location.service_address.street1
        });
        refLocationName.current.value = service_location.location_name;
    }, [service_location]);

    const _handleGetValue = () => {
        return {
            address_to: refAddress.current.getValue()?.full_name || '',
            location_name: refLocationName.current.value,
            service_address: {
                ...refAddressInfo.current.getValue()
            }
        };
    };

    const _handleBlur = (event) => {
        updateContext({ location_name: event.target.value }, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION);
    };

    return (
        <>
            <BoxRows label={t('customers:address_name')}>
                <input
                    ref={refLocationName}
                    name="location_name"
                    type="text"
                    className="field-input"
                    placeholder={t('customers:address_name')}
                    defaultValue={service_location.location_name}
                    onBlur={_handleBlur}
                />
            </BoxRows>
            <BoxRows label={t('customers:address_to')}>
                <AddressDropdown ref={refAddress} id="add_customer_address_to" />
            </BoxRows>

            <BoxRows
                label={t('customers:service_address')}
                labelClass="flexcenter"
                fieldClass="has-many-address"
                isRequired={defaultStatus !== CUSTOMER_STATUS[2].type}
            >
                <AddressInfo ref={refAddressInfo} {...props} serviceLocation={service_location.service_address} />
            </BoxRows>
        </>
    );
};

export default forwardRef(AddressService);
