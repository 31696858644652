import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import { CUSTOMERS_PAYMENTS } from 'app/config/routes.js';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumns = (actionsStatus, columns = []) => {
    const HEADER_PAYMENT = {
        account_number: {
            title: 'account_number_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        payment_for: {
            title: 'payment_for',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        method: {
            title: 'method',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        amount: {
            title: 'amount',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        view: { title: '', style: 'col col-xs', isShow: true, currentValue: COMMON.NOT_SET },
        qb_sync: {
            title: 'qb_sync',
            style: 'col col-xs',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_PAYMENT = [
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'payment_for',
            title: 'document',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'amount',
            title: 'amount',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        { id: 'view', title: '', status: true, isShow: true },
        {
            id: 'qb_sync',
            title: 'qb_sync',
            status: true
        }
    ];

    const REPORT_CONTENT_PAYMENT = {
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        payment_for: {
            id: 'payment_for',
            style: 'col col-xl d-flex',
            type: LIST_TABLE_ITEM_TYPE.ALL_PAYMENT_FOR
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            urlConfig: {
                baseUrl: CUSTOMERS_PAYMENTS
            }
        },
        method: {
            id: 'method',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        date: {
            id: 'date',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        amount: {
            id: 'amount',
            style: 'col col-total',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        view: {
            id: 'view',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_EDIT,
            isLink: true,
            isShow: true,
            title: 'View'
        },
        qb_sync: {
            id: 'qb_sync',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.CUSTOM
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_PAYMENT });
    const newColumns = [];

    REPORT_COLUMNS_PAYMENT.forEach((col) => {
        const item = col.id;
        if (columns.includes(item) || col.isShow) {
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: REPORT_CONTENT_PAYMENT
    };
};
