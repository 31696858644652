import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_TOTAL_SALES_GET_LIST,
    REPORT_TOTAL_SALES_GET_LIST_DATA_CHART,
    REPORT_TOTAL_SALES_GET_LIST_YEAR
} from 'app/const/Api';

export function* getListReportTotalSales({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_TOTAL_SALES_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListDataChartTotalSales({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_TOTAL_SALES_GET_LIST_DATA_CHART, params);

        if (response && response.success) {
            response.year = params.year;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListYearTotalSales({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_TOTAL_SALES_GET_LIST_YEAR);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
