import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CUSTOMERS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { getJobStatus } from 'common/utils/JobStatusUtils';

export const ColumnDefault = ({ className = '', labelClassName = 'col-label', value }) => {
    return (
        <div className={className}>
            <p className={labelClassName} title={value}>
                {value}
            </p>
        </div>
    );
};
export const ColumnTechAssigned = ({ className = '', value }) => {
    return (
        <div className={className}>
            <div className="schedule-user">
                {Array.isArray(value)
                    ? value?.map((item) => {
                          return (
                              <div key={item.id} className="tech-name" title={item.name}>
                                  {!!item.avatar ? (
                                      <div className="avt-img">
                                          <img src={item?.avatar} width="24" height="24" />
                                      </div>
                                  ) : (
                                      <div className="avt">{item?.name?.substring(0, 2)}</div>
                                  )}
                                  <span className="txt-ellipsis">{item.name}</span>
                                  {item.is_primary ? <span className="txt-primary">Primary</span> : null}
                              </div>
                          );
                      })
                    : null}
            </div>
        </div>
    );
};

const TitleStatusJob = ({ label = '', isNotTypeJob = false }) => {
    if (isNotTypeJob) return label;

    const jobStatus = getJobStatus(label);
    if (!jobStatus) return null;
    const { name: statusName, label: statusLabel } = jobStatus;
    return (
        <div className="flexcenter flex-wrap gap-3">
            <span className="txt-ellipsis">{statusName}</span>
            {!!statusLabel && (
                <div className="black tag tag-label" title={statusLabel}>
                    {statusLabel}
                </div>
            )}
        </div>
    );
};

export const ColumnStatus = ({ value, isNotTypeJob = false }) => {
    return (
        <div className="col col-md job-status --custom-event" style={{ '--color-custom-event': value.color }}>
            <span className="dots" />
            <TitleStatusJob label={value.label} isNotTypeJob={isNotTypeJob} />
        </div>
    );
};
export const ColumnInvoiceStatus = ({ className = 'col col-sm-plus', value }) => {
    return (
        <div className={className}>
            <div className={classNames('status-btn', value)}>{value}</div>
        </div>
    );
};
export const ColumnDisplayTags = ({ className = '', value = [] }) => {
    return (
        <div className={className}>
            <div className="tags">
                <div className="tags__info flexcenter flex-wrap">
                    {Array.isArray(value)
                        ? value.map((item, index) => (
                              <span key={index} className="tag-label tag cursor-default">
                                  {item}
                              </span>
                          ))
                        : null}
                </div>
            </div>
        </div>
    );
};
export const ColumnCustomer = ({ className = '', value }) => {
    return (
        <div className={className}>
            <Link to={addBranchPath(`${CUSTOMERS}/${value?.id}`)} className="name">
                {value?.full_name}
            </Link>
        </div>
    );
};
