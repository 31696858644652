import React, { Fragment } from 'react';

const VOIPAddonsLoadingHold = () => {
    return (
        <Fragment>
            <div className="box-voip wrap-loading bg-white">
                <div className="box-voip__rows flexcenter flex-wrap">
                    <div className="flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="loading --animation --line --onefifth" />
                    <div className="loading --animation --line --full mt-3" />
                    <div className="loading --animation --line --onefourth" />
                    <div className="audio-loading loading --full mt-3" />
                    <div className="d-flex gap-8 mt-3 w-100">
                        <div className="v2-btn-default loading --grey flex-1 justify-center">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey flex-1 justify-center">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="v2-btn-default loading --grey w-100 mt-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="d-flex justify-end w-100 mt-2">
                        <div className="v2-btn-default loading --grey btn-x-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-voip wrap-loading bg-white dp-hide">
                <div className="box-voip__rows flexcenter flex-wrap">
                    <div className="flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="loading --animation --line --onefifth" />
                    <div className="loading --animation --line --full mt-3" />
                    <div className="audio-loading loading --full mt-3" />
                    <div className="d-flex gap-8 mt-3 w-100">
                        <div className="v2-btn-default loading --grey flex-1 justify-center">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey flex-1 justify-center">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="v2-btn-default loading --grey w-100 mt-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="d-flex justify-end w-100 mt-2">
                        <div className="v2-btn-default loading --grey btn-x-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default VOIPAddonsLoadingHold;
