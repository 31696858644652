import { CUSTOMERS_DETAIL, CUSTOMERS } from 'app/config/routes.js';

const getUrlCustomerPage = ({ baseUrl = CUSTOMERS_DETAIL, isOpenNewTab = true, customerId }) => {
    return {
        url: customerId ? baseUrl.replace(':id', customerId) : CUSTOMERS,
        isOpenNewTab: isOpenNewTab
    };
};

export const replaceOrAddTarget = (text) => {
    if (!text) return '';
    return text.replace(/<a([^>]*)>/g, (match, p1) => {
        if (p1.includes('target')) {
            // Replace target="_self" with target="_blank"
            return match.replace('target="_self"', 'target="_blank"');
        } else {
            // Add target="_blank" to links without a target attribute
            return match.replace('>', ' target="_blank">');
        }
    });
};

export { getUrlCustomerPage };
