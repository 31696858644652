import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import ReportTabs from 'app/modules/report/components/ReportTabs';

import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';

import { getDefaultParams } from 'app/const/report/ReportParams';

function ReportFilterTabs({
    list,
    reportType,
    onSelect,
    classTabList = 'tab-selectors btn-item ml-0',
    classWrapper = 'header-items tabs'
}) {
    const { state } = useLocation();
    const [tab, setTab] = useState(null);
    const isFirstTime = useRef({ current: true });

    useEffect(() => {
        setParamsLocal(false);
    }, [state?.reloadScreen]);

    useEffect(() => {
        setParamsLocal(true);
    }, [tab]);

    const handleChangeTab = (tab) => {
        setTab(tab);
        isFirstTime.current = false;
    };

    const setParamsLocal = (update) => {
        let params = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType)) || getDefaultParams(reportType);

        const currentTab = tab ? JSON.parse(tab) : {};
        if (update && tab) {
            params = {
                ...params,
                ...currentTab,
                filterTab: tab,
                currentPage: 1,
                offset: 0
            };

            if (!isFirstTime.current) {
                setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), {
                    ...params
                });
                onSelect(params);
            }
        } else {
            const { filterTab } = params;
            setTab(filterTab);
        }
    };

    return (
        <ReportTabs
            list={list}
            onChange={handleChangeTab}
            tabActive={tab}
            classWrapper={classWrapper}
            classTabList={classTabList}
        />
    );
}

ReportFilterTabs.propTypes = {};

export default ReportFilterTabs;
