export default function IconFilter({ isHasColor = false }) {
    if (isHasColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 14.5V17.9648C13.5 18.2992 13.3329 18.6114 13.0547 18.7969L12 19.5C11.8415 19.6057 11.6553 19.662 11.4648 19.662C10.932 19.662 10.5 19.2301 10.5 18.6972V14.5H13.5ZM12 11C14.1776 11 16.03 11.4176 16.716 12.0004L17 12V13L16.715 13.0004C16.0282 13.5828 14.1765 14 12 14C9.82349 14 7.97179 13.5828 7.285 13.0004L7 13V12L7.28399 12.0004C7.96996 11.4176 9.82241 11 12 11Z"
                    fill="#FF871E"
                />
                <path
                    d="M12 9.5C15.5899 9.5 18.5 8.60457 18.5 7.5C18.5 6.39543 15.5899 5.5 12 5.5C8.41015 5.5 5.5 6.39543 5.5 7.5C5.5 8.60457 8.41015 9.5 12 9.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.5 7.5C5.5 12.1667 7.66667 14.5 12 14.5C16.3333 14.5 18.5 12.1667 18.5 7.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 14.5V18.6972C10.5 19.2301 10.932 19.662 11.4648 19.662C11.6553 19.662 11.8415 19.6057 12 19.5L13.0547 18.7969C13.3329 18.6114 13.5 18.2992 13.5 17.9648V14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 9.5C15.5899 9.5 18.5 8.60457 18.5 7.5C18.5 6.39543 15.5899 5.5 12 5.5C8.41015 5.5 5.5 6.39543 5.5 7.5C5.5 8.60457 8.41015 9.5 12 9.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.5 7.5C5.5 12.1667 7.66667 14.5 12 14.5C16.3333 14.5 18.5 12.1667 18.5 7.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 14.5V18.6972C10.5 19.2301 10.932 19.662 11.4648 19.662C11.6553 19.662 11.8415 19.6057 12 19.5L13.0547 18.7969C13.3329 18.6114 13.5 18.2992 13.5 17.9648V14.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
