import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import IconLoading from 'assets/icon/IconLoading';
import IconUser from 'assets/icon/IconUser';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import ScheduleList from '../../ScheduleList';

const SelectSchedule = ({ defaultValue = null }, ref) => {
    const { t } = useTranslation('calendar');
    const dispatch = useDispatch();
    const refDropdown = useRef(null);
    const calendarSchedules = useSelector(({ calendar }) => calendar);
    const schedulesReducer = useSelector(({ schedules }) => schedules);

    const isScheduleFirstLoad = schedulesReducer.isFirstTime;
    const calendarFirstSchedule = calendarSchedules.schedules[0];
    const dataSchedule = schedulesReducer?.data?.schedules || [];
    const [state, dispatchState] = useReducer(reducer, {
        selected: defaultValue || [],
        isLoading: isScheduleFirstLoad
    });
    const { selected, isLoading } = state;

    useImperativeHandle(ref, () => ({ getValue: () => selected }));

    const _handleOpen = () => {
        if (!isScheduleFirstLoad) return;
        dispatch(
            actionGetListSchedule(
                {},
                () => dispatchState({ isLoading: false }),
                () => {}
            )
        );
    };

    const _handleSelect = (id) => {
        const newSelect = dataSchedule.find((schedule) => schedule.id === id);

        if (selected.some((item) => item.id === id)) {
            dispatchState((prevState) => ({
                selected: prevState.selected.filter((item) => item.id !== id)
            }));
        } else {
            dispatchState((prevState) => ({ selected: [...prevState.selected, newSelect] }));
        }
    };

    const _handleSelectAll = () => {
        dispatchState({ selected: selected.length === dataSchedule.length ? [] : dataSchedule });
    };

    const _handleDelete = (id) => {
        dispatchState((prevState) => ({
            selected: prevState.selected.filter((item) => item.id !== id)
        }));
    };

    return (
        <div className="wrap-rows flextop border-bottom-line pr-1">
            <div className="wrap-rows__icons flex-auto">
                <IconUser />
            </div>
            <div className="wrap-rows__details flex-1 flextop">
                {!selected.length ? <p className="assign-title grey-soft ml-1 mr-2">{t('assigned_to')}</p> : null}
                <div className="schedule-user w-100">
                    {selected.length ? <ScheduleList selected={selected} onDelete={_handleDelete} /> : null}
                    <DropdownPopper
                        ref={refDropdown}
                        onOpen={_handleOpen}
                        wrapperClassName="v2-dropdown dropdown-listname"
                        wrapperListClass="v2-dropdown__menu content-user scrolls"
                    >
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <ListOptions
                                data={dataSchedule}
                                selected={selected || [calendarFirstSchedule.id]}
                                onSelect={_handleSelect}
                                onSelectAll={_handleSelectAll}
                            />
                        )}
                    </DropdownPopper>
                </div>
            </div>
        </div>
    );
};

const ListOptions = ({ data = [], selected = [], onSelect = () => {}, onSelectAll = () => {}, update = () => {} }) => {
    const { t } = useTranslation('calendar');
    const { users } = useSelector(({ companyUsers }) => companyUsers);

    const _handleSelect = (id) => {
        !id ? onSelectAll() : onSelect(id);
        update();
    };

    return (
        <ul>
            <li
                className={classNames('items has-icon justify-start', { active: selected.length === data.length })}
                onClick={() => _handleSelect()}
            >
                <IconDoubleCheck />
                {t('select_all_users')}
            </li>
            {data.map((item) => {
                const avatar = users.find((user) => user.id === item.user_id)?.avatar || null;
                return (
                    <li
                        key={item.id}
                        className={classNames('items', {
                            active: selected.some((selected) => selected.id === item.id)
                        })}
                        onClick={() => _handleSelect(item.id)}
                    >
                        <div className="user-name">
                            <AvatarUser avatar={avatar} name={item.name} />
                            <span className="word-break">{item.name}</span>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const AvatarUser = ({ avatar = '', name = '' }) => {
    if (avatar)
        return (
            <div className="avt-img">
                <img src={avatar} width={24} height={24} alt="" />
            </div>
        );
    return <div className="avt">{name.substring(0, 2)}</div>;
};

// Create this component to avoid warning
const Loading = () => {
    return (
        <div className="items justify-center">
            <div className="loading -ajaxbar">
                <IconLoading />
            </div>
        </div>
    );
};

export default forwardRef(SelectSchedule);
