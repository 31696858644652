import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { STATUS_MULTI_BRANCH } from '../const';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import GDModalWarning from 'app/components/modal/ModalWarning';
import ButtonSave from 'app/components/button/ButtonSave';

export const MultipleBranchEdit = ({ item }) => {
    const { id: branchID, onChangeStatus = () => {}, onEditBranch = () => {}, status } = item;
    const { t } = useTranslation();
    const refDropdown = useRef(null);
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);
    const refData = useRef(null);

    const options = [
        {
            id: STATUS_MULTI_BRANCH.EDIT,
            label: t('addons:edit')
        },
        {
            id: STATUS_MULTI_BRANCH.ACTIVE,
            label: t('addons:active'),
            description: t('addons:desc_active_this_branch'),
            title: t('addons:active_branch')
        },
        {
            id: STATUS_MULTI_BRANCH.ARCHIVE,
            label: t('addons:archive'),
            description: t('addons:desc_archive_this_branch'),
            title: t('addons:archive_branch')
        },
        {
            id: STATUS_MULTI_BRANCH.DELETE,
            label: t('addons:delete'),
            description: t('addons:please_contact_support'),
            title: t('addons:delete_branch')
        }
    ];

    const _handleSelectItem = ({ id, label, title, description }) => {
        refDropdown.current._close();

        if (id === STATUS_MULTI_BRANCH.EDIT) {
            onEditBranch(item);
            return;
        }
        refWarning.current._open({
            title,
            description
        });
        refData.current = { id, label };
    };

    const _renderOptions = () => {
        return options.map(({ id, label, description, title }) => {
            if (id === status) return null;
            return (
                <li
                    key={id}
                    className="items"
                    onClick={() => _handleSelectItem({ id, label, title, description })}
                    style={{ maxWidth: '165px' }}
                >
                    {label}
                </li>
            );
        });
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
    };

    const _handleConfirmWarning = () => {
        if (refData.current.id === STATUS_MULTI_BRANCH.DELETE) {
            _handleCloseWarning();
            return;
        }
        onChangeStatus({ branchID, data: refData.current });
    };

    const FooterWaring = ({ onConfirm = () => {} }) => {
        return (
            <div className="footer-modal footer-hasbtn btn-close">
                <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                    {t('cancel')}
                </span>
                <ButtonSave
                    ref={refButtonSave}
                    title={t('addons:confirm')}
                    className="v2-btn-main"
                    onSave={onConfirm}
                />
            </div>
        );
    };

    return (
        <Fragment>
            <DropdownPopper
                ref={refDropdown}
                isUseToggle
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu"
                buttonClassName="dropbtn v2-btn-default --transparent --icon-sm"
                customButton={<IconThreeDots />}
            >
                <ul>{_renderOptions()}</ul>
            </DropdownPopper>
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                onConfirm={_handleConfirmWarning}
                footer={<FooterWaring />}
            />
        </Fragment>
    );
};
