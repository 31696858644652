import React, { Fragment, useContext } from 'react';
import { VOIPDropdownUser } from '../VOIPDropdownUser';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { useTranslation } from 'react-i18next';
import IconAlert from 'assets/icon/IconAlert';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ADDONS_VOIP_ACTIVE_USERS } from 'app/config/routes';
import { AddonsManageContext } from '../VOIPAddonsManageProvider';
import { useSelector } from 'react-redux';
import { addBranchPath } from 'app/const/Branch';

export const AssignGroupMember = ({
    item = {},
    isReload = true,
    isShowButtonManage = true,
    isShowAlert = false,
    wrapperClassName = 'box-voip__rows flex-betweenitems',
    wrapperClassMembers = 'wrap-member-group flexcenter',
    onUpdateMember = () => {}
}) => {
    const { t } = useTranslation();
    const { activedUsers } = useContext(AddonsManageContext);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const { id, assignees } = item;

    const maxShowUsers = 14;

    const _renderRowAssign = () => {
        const lengthAssign = assignees.length;

        if (isShowAlert && !lengthAssign)
            return (
                <div className="attention --danger flexcenter gap-6 w-100">
                    <IconAlert />
                    <p className="red-default fs-13 flexcenter gap-4">
                        {t('addons:there_are_no_active_voip_users')}
                        <Link to={addBranchPath(ADDONS_VOIP_ACTIVE_USERS)} className="is-link">
                            {t('addons:manage_voip_users')}
                        </Link>
                    </p>
                </div>
            );

        return (
            <Fragment>
                <div className={wrapperClassMembers}>
                    {_renderMember()}
                    {lengthAssign > maxShowUsers && (
                        <span className="avt-img avt-more fs-10 fw-500">{lengthAssign - maxShowUsers}</span>
                    )}
                </div>
                {isShowButtonManage && (
                    <VOIPDropdownUser
                        id={id}
                        item={item}
                        companyUsers={activedUsers}
                        isReload={isReload}
                        onHide={onUpdateMember}
                    >
                        <div className="v2-btn-default svg-purple --purple --icon-r --sm">
                            {t('addons:manage_users')}
                            <IconCogWheel />
                        </div>
                    </VOIPDropdownUser>
                )}
            </Fragment>
        );
    };

    const _renderMember = () => {
        return [...assignees].splice(0, maxShowUsers).map((assignee) => {
            const userAssign = companyUsers.find((item) => parseInt(item.id) === assignee) || {};
            const { avatar: finalAvatar, full_name: finalName } = userAssign;
            return (
                <span key={assignee} className="avt-img tooltip">
                    <img src={finalAvatar} width={22} height={22} alt={finalName} />
                    <span className="tooltiptext top">{finalName}</span>
                </span>
            );
        });
    };

    return <div className={wrapperClassName}>{_renderRowAssign()}</div>;
};
