import React from 'react';

function GdButton({
    title = null,
    disabled = false,
    onClick = () => {},
    className = '',
    iconSvg = null,
    maxWidth = '',
    children = null,
    iconClassName = '',
    iconHTMLTag = null,
    isWhiteLoading = false,
    isLoading = false,
    id = '',
    tabIndex = '0'
}) {
    const renderChildren = () => {
        return children ? (
            children
        ) : (
            <>
                <div className={iconClassName}>
                    {iconSvg && iconSvg}
                    {iconHTMLTag && iconHTMLTag}
                </div>
                {maxWidth ? <button type="button">{title}</button> : <span>{title}</span>}
            </>
        );
    };

    const renderLoadingButton = () => {
        if (isWhiteLoading) {
            return (
                <div className={`${className} ${'is-disable'}`}>
                    <div className="preloader-small">
                        <span className="preloader-small__items" />
                    </div>
                </div>
            );
        }

        return (
            <div className={`${className} ${'is-disable'}`}>
                <div className="loading --ajaxbar">
                    <div className="loading__barlittle --first"></div>
                    <div className="loading__barlittle --second"></div>
                    <div className="loading__barlittle --three"></div>
                    <div className="loading__barlittle --four"></div>
                </div>
            </div>
        );
    };

    return isLoading ? (
        renderLoadingButton()
    ) : (
        <div id={id} className={`${className} ${disabled ? 'is-disable' : ''}`} onClick={onClick} tabIndex={tabIndex}>
            {renderChildren()}
        </div>
    );
}

export default GdButton;
