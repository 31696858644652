import React, { useReducer, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ADDONS_MULTI_UNIT } from 'app/config/routes';
import IconArrow from 'assets/icon/IconArrow';
import { useTranslation } from 'react-i18next';
import MultiUnitManagerAddonsLoading from './MultiUnitManagerAddonsLoading';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_MULTI_UNIT_DETAIL_SETTING } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { addBranchPath } from 'app/const/Branch';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import classNames from 'classnames';

const MultiTrackingManager = () => {
    const [state, dispatchState] = useReducer(reducer, {
        mduManager: {},
        isLoading: true,
        isEnabled: false,
        isDisable: false
    });
    const { t } = useTranslation(['addons,common']);
    const { isLoading, mduManager, isDisable } = state;
    const refAlert = useRef(null);

    useEffect(() => {
        _getListMultiManager();
    }, []);

    const _getListMultiManager = () => {
        clientQuery(ADDONS_MULTI_UNIT_DETAIL_SETTING, { method: 'GET' }, _handleGetSuccess, _handleGetFail);
    };

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ mduManager: data, isLoading: false });
    };

    const _handleGetFail = ({ message }) => {
        dispatchState({ isDisable: true, isLoading: false });
        refAlert.current.showStatusBar('mdu', message, LIST_STATUS.ERROR);
    };

    const _handleChange = (e) => {
        const { id: key, checked } = e.target;
        dispatchState({ isEnabled: checked });

        clientQuery(ADDONS_MULTI_UNIT_DETAIL_SETTING, {
            data: { value: checked ? 1 : 0, keyword: key },
            method: 'PUT'
        });
    };

    const _renderContent = () => {
        if (isLoading) return <MultiUnitManagerAddonsLoading />;

        return (
            <div className={classNames('boxs-wrapper multi-unit-settings', { 'is-disable': isDisable })}>
                <div className="manage-tracking">
                    <div className="manage-tracking__title">{t('addons:tracking_title')}</div>
                    <div className="manage-tracking__desc">{t('addons:tracking_desc')}</div>
                    <div className="manage-tracking__content">
                        <div className="rows">
                            <div>{t('addons:unit_notes')}</div>
                            <div className="switch large">
                                <input
                                    id="mdu_unit_note"
                                    className="toggle toggle-round"
                                    type="checkbox"
                                    onChange={_handleChange}
                                    defaultChecked={mduManager.mdu_unit_note}
                                />
                                <label htmlFor="mdu_unit_note">
                                    <span className="onoff" />
                                </label>
                            </div>
                        </div>
                        <div className="rows">
                            <div>{t('addons:signature')}</div>
                            <div className="switch large">
                                <input
                                    id="mdu_signature"
                                    className="toggle toggle-round "
                                    type="checkbox"
                                    onChange={_handleChange}
                                    defaultChecked={mduManager.mdu_signature}
                                />
                                <label htmlFor="mdu_signature">
                                    <span className="onoff" />
                                </label>
                            </div>
                        </div>
                        <div className="rows">
                            <div>{t('addons:materials')}</div>
                            <div className="switch large">
                                <input
                                    id="mdu_material"
                                    className="toggle toggle-round "
                                    type="checkbox"
                                    onChange={_handleChange}
                                    defaultChecked={mduManager.mdu_material}
                                />
                                <label htmlFor="mdu_material">
                                    <span className="onoff" />
                                </label>
                            </div>
                        </div>
                        <div className="rows">
                            <div>{t('addons:check_in_out')}</div>
                            <div className="switch large">
                                <input
                                    id="mdu_check_in_out"
                                    className="toggle toggle-round "
                                    type="checkbox"
                                    onChange={_handleChange}
                                    defaultChecked={mduManager.mdu_check_in_out}
                                />
                                <label htmlFor="mdu_check_in_out">
                                    <span className="onoff" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container --unit-settings">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_MULTI_UNIT)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('addons:multi_unit_tracking')}
                    </Link>
                </div>
                <GDStatusBar ref={refAlert} />
                <div className="scrolls">{_renderContent()}</div>
            </div>
        </div>
    );
};

export default MultiTrackingManager;
