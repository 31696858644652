import React from 'react';

const Loading = () => {
    return (
        <div className="body-modal">
            {/* loading has-form */}
            <div className="wrap-loading" style={{}}>
                <div className="has-form">
                    <div className="rows row-haft fs-13">
                        <div className="col">
                            <div className="txt mb-1">
                                <div className="loading --animation --line --twothird" />
                            </div>
                            <div className="loading v2-btn-default --grey --full">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="txt mb-1">
                                <div className="loading --animation --line --twothird" />
                            </div>
                            <div className="loading v2-btn-default --grey --full">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* loading search-form */}
            <div className="wrap-loading" style={{}}>
                <div className="search-form">
                    <div className="loading v2-btn-default --grey --full">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
            </div>
            {/* loading List document */}
            <div className="wrap-loading" style={{}}>
                <div className="document-list">
                    <div className="document-rows">
                        <div className="action-document">
                            <div className="add-document">
                                <div className="v2-btn-default loading --grey btn-x-sm">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="docs-name">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                    </div>
                    <div className="document-rows">
                        <div className="action-document">
                            <div className="add-document">
                                <div className="v2-btn-default loading --grey btn-x-sm">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="docs-name">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="document-rows">
                        <div className="action-document">
                            <div className="add-document">
                                <div className="v2-btn-default loading --grey btn-x-sm">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="docs-name">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="document-rows">
                        <div className="action-document">
                            <div className="add-document">
                                <div className="v2-btn-default loading --grey btn-x-sm">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="docs-name">
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
