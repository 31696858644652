import React from 'react';
import { useSelector } from 'react-redux';

const LocationModalLoading = () => {
    const isActiveUnit = useSelector(({ auth }) => auth?.user?.settings?.addons?.mdu_tracking);
    return (
        <div className="content-modal">
            <div className="wrap-column-content">
                <div className="column-content left-column">
                    <div className="w-100 d-flex">
                        <div className="column-location">
                            <div className="wrap-loading --add-location">
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                                <div className="rows --multi">
                                    <div className="col col-2 first-field">
                                        <div className="txt loading --full">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                        <div className="loading --full v2-btn-default --grey">
                                            <div className="loading --animation --line --fourth" />
                                        </div>
                                    </div>
                                    <div className="col col-2 second-field">
                                        <div className="txt loading --full">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                        <div className="loading --full v2-btn-default --grey">
                                            <div className="loading --animation --line --fourth" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows mt-3">
                                    <div className="loading --full txt">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="has-many-address">
                                        <div className="loading --full v2-btn-default --grey field-street --first">
                                            <div className="loading --animation --line --onefourth"></div>
                                        </div>
                                        <div className="loading --full v2-btn-default --grey field-street --second">
                                            <div className="loading --animation --line --onefourth"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-city">
                                            <div className="loading --animation --line --twothird"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-state">
                                            <div className="loading --animation --line --threefourth"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-zip">
                                            <div className="loading --animation --line --half"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap-loading --add-location mt-3">
                                <div className="rows">
                                    <div className="col">
                                        <div className="txt loading --full">
                                            <div className="loading --animation --line --fourth" />
                                        </div>
                                        <div className="d-flex">
                                            <div className="first-field loading --half">
                                                <div className="loading --full v2-btn-default --grey">
                                                    <div className="loading --animation --line --fourth" />
                                                </div>
                                            </div>
                                            <div className="second-field loading --half">
                                                <div className="loading --full v2-btn-default --grey">
                                                    <div className="loading --animation --line --fourth" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Loading Note */}
                            <div className="wrap-loading --add-location mt-3">
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="notes-location field-h100" />
                                </div>
                            </div>
                            {/* Loading Units */}
                            {isActiveUnit ? (
                                <div className="wrap-loading --add-location mt-3">
                                    <div className="rows">
                                        <div className="txt loading --full">
                                            <div className="loading --animation --line --onefifth" />
                                        </div>
                                        <div className="loading v2-btn-default --grey btn-md">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            {/* End Loading */}
                        </div>
                        <div className="column-location">
                            {/* Loading Billing Address */}
                            <div className="wrap-loading --add-location">
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                                <div className="rows mt-3">
                                    <div className="loading --full txt">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="has-many-address">
                                        <div className="loading --full v2-btn-default --grey field-street --first">
                                            <div className="loading --animation --line --onefourth"></div>
                                        </div>
                                        <div className="loading --full v2-btn-default --grey field-street --second">
                                            <div className="loading --animation --line --onefourth"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-city">
                                            <div className="loading --animation --line --twothird"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-state">
                                            <div className="loading --animation --line --threefourth"></div>
                                        </div>
                                        <div className="loading v2-btn-default --grey field-zip">
                                            <div className="loading --animation --line --half"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="d-flex">
                                        <div className="loading v2-btn-default --grey flex-1 mr-2">
                                            <div className="loading --animation --line --fourth" />
                                        </div>
                                        <div className="v2-btn-default --icon-lg --grey loading" />
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt loading --full">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            </div>
                            {/* Loading tags */}
                            <div className="wrap-loading --add-location mt-3">
                                <div className="rows mt-2">
                                    <div className="loading --full txt">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="column-content column-preferences messaging-preferences --wrap">
                    {/* Loading Preferences */}
                    <div className="wrap-loading">
                        <div className="rows mb-4">
                            <div className="txt loading --full">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="boxs boxs-modified">
                            <div className="lines">
                                <div className="txt svg-conversation">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="v2-dropdown v2-btn-default --grey loading ml-0">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="v2-dropdown v2-btn-default --grey loading ml-0">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --icon-lg --grey loading" />
                                </div>
                            </div>
                            <div className="lines">
                                <div className="txt svg-conversation">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="v2-dropdown v2-btn-default --grey loading ml-0">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --icon-lg --grey loading" />
                                </div>
                                <div className="field">
                                    <div className="v2-dropdown v2-btn-default --grey loading ml-0">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --icon-lg --grey loading" />
                                </div>
                            </div>
                        </div>
                        <div className="box-appointment box-preferences tabs">
                            <div className="lines">
                                <div className="txt">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="lines">
                                <div className="txt">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="lines">
                                <div className="txt">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="lines">
                                <div className="txt">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="lines">
                                <div className="txt">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column-content column-tax">
                {/* Loading tax rate */}
                <div className="wrap-loading --add-location">
                    <div className="tax-rate">
                        <div className="col-2 text-info">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col-2">
                            <div className="rows --multi">
                                <div className="col">
                                    <div className="loading --full txt">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="loading --full txt">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LocationFooterModalLoading = () => (
    <div className="wrap-loading footer-modal footer-hasbtn">
        <div className="v2-btn-default --transparent btn-x-sm loading">
            <div className="loading --animation --line --full" />
        </div>
        <div className="v2-btn-default --grey btn-x-sm loading">
            <div className="loading --animation --line --full" />
        </div>
    </div>
);

export default LocationModalLoading;
