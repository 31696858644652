import { createPopper } from '@popperjs/core';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

const generateGetBoundingClientRect = (x = 0, y = 0) => {
    return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x
    });
};

const TooltipAllDaySlot = (props, ref) => {
    const virtualElement = { getBoundingClientRect: generateGetBoundingClientRect() };
    const refPopper = useRef(null);
    const instance = useRef(null);

    useImperativeHandle(ref, () => ({ createInstance, updateInstance, removeInstance }));

    const createInstance = () => {
        instance.current = createPopper(virtualElement, refPopper.current, {
            placement: 'right-start',
            modifiers: [{ name: 'offset', options: { offset: [10, 10] } }]
        });
    };

    const updateInstance = (x, y, content) => {
        refPopper.current.style.display = 'block';
        refPopper.current.textContent = content;
        virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
        instance.current && instance.current.update();
    };

    const removeInstance = () => {
        refPopper.current.style.display = 'none';
    };

    return (
        <p
            id="tooltip-all-day-slot"
            className="tooltip"
            ref={refPopper}
            style={{
                color: '#ffffff',
                backgroundColor: '#464b55',
                borderRadius: '4px',
                fontSize: '11px',
                lineHeight: '13px',
                fontWeight: 500,
                padding: '5px 8px',
                zIndex: 999,
                display: 'none',
                userSelect: 'none'
            }}
        />
    );
};

export default forwardRef(TooltipAllDaySlot);
