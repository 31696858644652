import React from 'react';
import PropsTypes from 'prop-types';
import GdLazyImage from 'app/components/lazyimage';
import { LINK_CDN_IMG } from 'app/const/URL';

import FooterBackLogin from 'app/modules/auth/components/FooterBackLogin';
import { SRC_LOGO_GORILLADESK } from 'app/const/App';

const NotiChangePassword = ({ message, status }) => {
    const _renderMessage = (data) => {
        return data.map((item, index) => {
            if (index === 0) return null;
            return <p key={item}>{item}</p>;
        });
    };

    if (!Array.isArray(message)) return null;

    return (
        <div className="gorilladesk auth-page">
            <div className="wrapper">
                <h1 className="logo-text-gorilla">
                    <GdLazyImage
                        src={SRC_LOGO_GORILLADESK}
                        // alt={t('common:gorilladesk')}
                        width={335}
                        height={80}
                    />
                </h1>
                <div className="main-login">
                    <div className="gd-form-v2 py-10">
                        <h2 className="fs-27">{message[0]}</h2>
                        {_renderMessage(message)}
                        {!status && <FooterBackLogin />}
                    </div>
                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            // alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

NotiChangePassword.propTypes = {
    message: PropsTypes.array,
    status: PropsTypes.bool
};

export default NotiChangePassword;
