import { API_VERSION } from 'app/const/URL';

export const getDocumentDetail = (documentId) => {
    return `${API_VERSION}documents/${documentId}`;
};

export const actionJobDocument = (jobDocumentId) => {
    return `${API_VERSION}documents/${jobDocumentId}`;
};

export const documentListSms = `${API_VERSION}template/document/sms`;

export const documentListEmail = `${API_VERSION}template/document/email`;

export const documentEmailSend = `${API_VERSION}document/email/send`;

export const documentSmsSend = `${API_VERSION}document/sms/send`;

export const resetDocument = (jobDocumentId) => {
    return `${API_VERSION}documents/${jobDocumentId}/reset`;
};

export const updateStatusEsign = (jobDocumentId) => {
    return `${API_VERSION}documents/${jobDocumentId}/esig`;
};
