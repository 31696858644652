import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import GDStatusBar from 'app/components/status/statusbar';
import { SEND_MAIL, VERIFY_CODE } from 'app/const/api/V2';
import { GD_DEVICE_ID, KEY_DEVICE_ID, SRC_LOGO_GORILLADESK } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { METHOD_2FA, TWO_AUTHENTICATION_TYPES } from 'app/modules/profileAuthentication/constants';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';

const TwoStepAuthentication = ({ data = [], handleSuccess = () => {} }) => {
    const { t } = useTranslation('auth');

    const [state, dispatchState] = useReducer(reducer, { isTryAnotherWay: true, disableButton: true, method: null });
    const { isTryAnotherWay, disableButton, method: finalMethod } = state;
    const { id: finalMethodId, method: finalNameMethod } = finalMethod || {};
    const deviceId = getLocalStorage(KEY_DEVICE_ID) || '';
    const customHeaders = { [GD_DEVICE_ID]: deviceId };

    const refDigitCode = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refWarning = useRef(null);

    useEffect(() => {
        const methodDefault = data.find((item) => item.is_default);
        dispatchState((prev) => ({ ...prev, method: methodDefault, isTryAnotherWay: !methodDefault }));
    }, [data]);

    const _handleChangeMethod = (value) => {
        const isMethodEmail = value === TWO_AUTHENTICATION_TYPES.EMAIL;
        const methodSelected = data.find((item) => item.method === value);
        dispatchState((prev) => ({
            ...prev,
            method: methodSelected,
            isTryAnotherWay: isMethodEmail ? prev.isTryAnotherWay : false,
            disableButton: true
        }));
        if (isMethodEmail) {
            refWarning.current?._open({
                title: t('send_email'),
                description: t('send_email_description')
            });
        }
    };

    const _handleChangeTryOtherWay = () => {
        refAlert.current?.clearAllStatusBar();
        dispatchState((prev) => ({ ...prev, isTryAnotherWay: !prev.isTryAnotherWay, disableButton: true }));
    };

    const _renderListOptionTwoStepAuth = () => {
        return data.map(({ id, method, is_default }, index) => {
            const { icon, title } = METHOD_2FA[method] || {};

            return (
                <Fragment key={id}>
                    {!!index ? <div className="is-divider --horizontal" /> : null}
                    <div
                        className={classNames('authentication-items flexcenter gap-8', { active: is_default })}
                        onClick={() => _handleChangeMethod(method)}
                    >
                        {icon}
                        <p className="fw-500 flex-1">{title}</p>
                    </div>
                </Fragment>
            );
        });
    };

    const _handleSubmit2FA = () => {
        const digitCode = refDigitCode.current?.value;

        const _handleSuccess = ({ message }) => {
            showStatusBar({ id: 'verify_2fa_success', message, success: true, refAlert });
            _removeLoading();
            dispatchState((prev) => ({ ...prev, disableButton: true }));
            handleSuccess();
        };
        const _handleFail = ({ message }) => {
            showStatusBar({ id: 'verify_2fa_failed', message, refAlert });
            _removeLoading();
        };

        clientQuery(
            VERIFY_CODE,
            {
                data: { code: digitCode || '', method_id: Number(finalMethodId) || 0, device_id: deviceId || '' },
                headers: customHeaders,
                method: 'POST',
                toFormData: false,
                useGdBranchID: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleChangeDigitCode = ({ target }) => {
        const { value = 0 } = target || {};
        const { lengthCode = 0 } = METHOD_2FA[finalNameMethod] || {};
        dispatchState((prev) => ({ ...prev, disableButton: !(value.length === lengthCode) }));
    };

    const _handleConfirm = () => {
        const _handleSuccess = ({ message }) => {
            dispatchState((prev) => ({
                ...prev,
                method: data.find((item) => item.method === TWO_AUTHENTICATION_TYPES.EMAIL),
                isTryAnotherWay: false
            }));
            _handleDisableBtnSave();
            showStatusBar({ id: 'send_mail_success', message, success: true, refAlert });
            refWarning.current?._close();
        };
        const _handleFail = ({ message }) => {
            refWarning.current?._setStatusAlert(message);
            _handleDisableBtnSave();
        };

        clientQuery(
            SEND_MAIL,
            {
                data: { method_id: Number(finalMethodId) || 0 },
                headers: customHeaders,
                method: 'POST',
                toFormData: false,
                useGdBranchID: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleDisableBtnSave = () => {
        refWarning.current?._setLoadingBtnSave(false);
    };

    return (
        <div className="gd-form-v2 px-6">
            <GDStatusBar ref={refAlert} />
            <div className="logo-text-gorilla">
                <img src={SRC_LOGO_GORILLADESK} width={377} height={90} />
            </div>
            <h3 className="fs-26 black text-center">{t('two_step_authentication_title')}</h3>
            <p className="text-center pb-4 mt-1">
                {t(isTryAnotherWay ? 'two_step_sign_in_description' : METHOD_2FA[finalNameMethod]?.description)}
            </p>
            {isTryAnotherWay ? (
                <div className="flex-column gap-4 pb-6">{_renderListOptionTwoStepAuth()}</div>
            ) : (
                <Fragment>
                    <div className="gd-login-btn-group flex-column gap-4 p-0">
                        <span className="fs-13">{t('enter_the_digit_code')}</span>
                        <input
                            ref={refDigitCode}
                            className="field-input"
                            type="number"
                            placeholder={t('enter_the_digit_code')}
                            autoFocus
                            onChange={_handleChangeDigitCode}
                        />
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        wrapClass="gd-getstarted v2-btn-main gd-btn-getstarted fw-500"
                        title={t('continue')}
                        disabled={disableButton}
                        onSave={_handleSubmit2FA}
                    />
                    <div
                        className="purple-default fw-500 fs-13 text-center mt-2 dp-block pb-6 cursor-pointer"
                        onClick={_handleChangeTryOtherWay}
                    >
                        {t('try_another_way')}
                    </div>
                </Fragment>
            )}
            <GDModalWarning ref={refWarning} buttonSave onConfirm={_handleConfirm} />
        </div>
    );
};

export default TwoStepAuthentication;
