import React, { Fragment } from 'react';

export const VOIPAddonsLoadingModal = () => {
    return (
        <Fragment>
            <div>
                <div className="wrap-loading header-modal">
                    <div className="loading --animation --line --fourth" />
                    <div className="v2-btn-default loading --transparent --icon-lg bg-white-cultured" />
                </div>
                <div className="wrap-loading body-modal">
                    <div className="wrap-manager flexcenter">
                        <div className="tab-items flex-column loading --twothird">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="tab-items flex-column loading --twothird">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="tab-items flex-column loading --twothird">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="tab-content has-form">
                        <div className="rows row-haft pt-2">
                            <div className="col">
                                <div className="loading --animation --line --onefourth mb-1" />
                                <div className="v2-btn-default loading --grey w-100">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="loading --animation --line --onefourth mb-1" />
                                <div className="v2-btn-default loading --grey w-100">
                                    <div className="loading --animation --line --fourth" />
                                </div>
                            </div>
                        </div>
                        <div className="loading --animation --line --threefourth mt-4" />
                    </div>
                </div>
                <div className="wrap-loading footer-modal">
                    <div className="v2-btn-default loading btn-x-sm ml-n1">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
