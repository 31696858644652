export const TYPE_REPORT_EMAIL_HISTORY = {
  GET_LIST: "GET_LIST_REPORT_EMAIL_HISTORY",
  GET_LIST_SERVICE_FILTER_REQUEST: "GET_LIST_SERVICE_FILTER_REQUEST",
  EXPORT_EMAIL_HISTORY: "EXPORT_EMAIL_HISTORY",
}

export const getListReportEmailHistory = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_EMAIL_HISTORY.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListServiceFilterRequest = (actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_EMAIL_HISTORY.GET_LIST_SERVICE_FILTER_REQUEST,
    actionSuccess,
    actionFailed
  }
}

export const exportEmailHistory = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_EMAIL_HISTORY.EXPORT_EMAIL_HISTORY,
    params,
    actionSuccess,
    actionFailed,
  }
}