import classNames from 'classnames';
import React, { Fragment, forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import {
    CURRENCY_VALUE,
    OVERRIDE_KEYWORD,
    OVERRIDE_TYPE,
    PERCENT_VALUE,
    TITLE_OVERRIDE_RATE,
    TYPE_INPUT_OVERRIDE
} from 'app/const/setting/SettingGlobalOverride';
import GridLoading from 'app/components/grid/components/GridLoading';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import ButtonSave from 'app/components/button/ButtonSave';
import { clientQuery } from 'common/utils/ApiUtils';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import { GLOBAL_OVERRIDE_SETTING } from 'app/const/api/V2';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { SocketContext } from 'app/services/socket/SocketProvider';
import GdConfirm from 'app/components/confirm';
import GDInputNumber from 'app/components/input/InputNumber';
import { previewNewRate } from '../utils/override';

const TableOverride = (
    {
        keyGetRate = 'rate',
        currency,
        data = [],
        isLoading = false,
        exceptNegative = false,
        typeOverride = OVERRIDE_KEYWORD.TAX,
        onApplySuccess = () => {}
    },
    ref
) => {
    const { t } = useTranslation('setting');
    const refDataApply = useRef({});
    const refBtnApply = useRef(null);
    const refAlert = useRef(null);
    const refForm = useRef(null);
    const refChanged = useRef(false);
    const refBtnCancel = useRef(null);
    const refConfirm = useRef(null);
    const refInputs = useRef({});
    const refIsReceived = useRef(false);
    const { SUCCESS, WARNING, ERROR } = LIST_STATUS;
    const keyOverride = OVERRIDE_TYPE[typeOverride];

    const { override } = useContext(SocketContext);
    const { title, change_rate } = TITLE_OVERRIDE_RATE[typeOverride];

    const [state, dispatchState] = useReducer(reducer, {
        isDisableApply: false
    });
    const { isDisableApply } = state;

    useImperativeHandle(ref, () => ({
        showAlert: _handleShowStatus
    }));

    useEffect(() => {
        refBtnApply.current?.setDisable(true);
    }, []);

    useEffect(() => {
        if (!override) return;
        _handleShowStatus({ message: override.message, success: true });
        refChanged.current = false;
        refIsReceived.current = true;
        isDisableApply && dispatchState({ isDisableApply: false });
    }, [override]);

    const _handleChangeOverride = ({ id, value = '', type }) => {
        const newData = { ...refDataApply.current };
        if (!newData[id] && !value) return;

        const newValue = { ...(newData[id] || {}), type, value };
        newData[id] = newValue;
        refDataApply.current = newData;
        _handleChangeDisableBtn(true);
    };

    const _handleApply = () => {
        const items = [];
        Object.entries(refDataApply.current).forEach(([key, value]) => {
            if (!!value.value.length) items.push({ id: key, ...value });
        });

        if (!items.length) {
            _handleShowStatus({
                message: t('customers:cannot_be_blank', { name: `${t(change_rate)} ${t('column')}` }),
                success: false
            });
            refBtnApply.current.removeLoading();
            _handleChangeDisableBtn(false);
            return;
        }
        refIsReceived.current = false;

        const _handleSuccess = ({ data }) => {
            const { message, status } = data;
            onApplySuccess({ dataApply: { ...refDataApply.current } });
            _handleClickCancel(false);

            if (refIsReceived.current) return;
            _handleShowStatus({ message, type: !!status ? SUCCESS : WARNING });
            refBtnApply.current.setDisable(true);
            !status && dispatchState({ isDisableApply: true });
        };

        const _handleFail = (err) => {
            _handleShowStatus(err);
        };

        const _handleFinal = () => {
            refConfirm.current?.close();
            refBtnApply.current?.removeLoading();
        };

        clientQuery(
            GLOBAL_OVERRIDE_SETTING,
            {
                method: 'POST',
                toFormData: false,
                data: { override_type: keyOverride, items }
            },
            _handleSuccess,
            _handleFail,
            _handleFinal
        );
    };

    const _renderTableList = () => {
        if (isLoading) return <GridLoading />;
        if (!data.length) return <GridEmpty />;

        return data.map((item) => (
            <ItemOverride
                ref={(ref) => (refInputs.current[item.id] = ref)}
                key={item.id}
                item={item}
                currency={currency}
                keyGetRate={keyGetRate}
                typeOverride={typeOverride}
                exceptNegative={exceptNegative}
                onChangeOverride={_handleChangeOverride}
            />
        ));
    };

    const _handleShowStatus = ({ message = '', success = false, type }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('override', message, type ?? (success ? SUCCESS : ERROR));
    };

    const _handleClickCancel = (isResetNewRate = true) => {
        if (!refForm.current) return;
        refForm.current
            .querySelectorAll(`.tab-items.active-tab-selector[data-type-input="${TYPE_INPUT_OVERRIDE[1].id}"]`)
            .forEach((el) => el.previousSibling?.click());
        Object.keys(refDataApply.current).forEach((id) => refInputs.current[id]?.reset({ isResetNewRate }));
        refDataApply.current = {};
        _handleChangeDisableBtn(false);
    };

    const _handleChangeDisableBtn = (value = true) => {
        if (refChanged.current === value) return;
        refBtnApply.current.setDisable(!value);
        refChanged.current = value;
        _disableBtnCancel(!value);
    };

    const _disableBtnCancel = (value = false) => {
        refBtnCancel.current && refBtnCancel.current.classList.toggle('is-disable', value);
    };

    const _handleOpenConfirm = () => {
        refConfirm.current.open(null, t(`message_warning_${typeOverride}_override`), t('addons:warning'));
    };

    return (
        <Fragment>
            <GDStatusBar ref={refAlert} />
            <div className="tables table-multi-column scrolls-x">
                <div className="rows --fixed --header">
                    <div className="col col-xl">
                        <div className="col-label">{t(title)}</div>
                    </div>
                    <div className="col col-lg">
                        <div className="col-label">{t('rate')}</div>
                    </div>
                    <div className="col col-lg">
                        <div className="col-label">{t(change_rate)}</div>
                    </div>
                    <div className="col col-lg">
                        <div className="col-label">{t('new_rate')}</div>
                    </div>
                </div>
                <form ref={refForm} className="tables-list">
                    {_renderTableList()}
                </form>
            </div>
            <div className="override-container__footer flex-betweenitems">
                <div ref={refBtnCancel} className="v2-btn-default is-disable" onClick={() => _handleClickCancel()}>
                    {t('cancel')}
                </div>
                <div className={classNames({ tooltip: isDisableApply })}>
                    <ButtonSave
                        ref={refBtnApply}
                        disabled={isDisableApply}
                        title={t('common:apply')}
                        onSave={_handleOpenConfirm}
                    />
                    <span className="tooltiptext top">{t('override_adjusted_in_process')}</span>
                </div>
            </div>
            <GdConfirm
                ref={refConfirm}
                listButton={{ confirm: true, close: true }}
                titleConfirm={t('common:yes')}
                titleClose={t('common:cancel')}
                onConfirm={_handleApply}
                onClose={() => refBtnApply.current?.removeLoading()}
            />
        </Fragment>
    );
};

export default forwardRef(TableOverride);

const ItemOverride = forwardRef(
    (
        {
            item = {},
            keyGetRate = '',
            currency = null,
            typeOverride = OVERRIDE_KEYWORD.TAX,
            exceptNegative = false,
            onChangeOverride = () => {}
        },
        ref
    ) => {
        const refInput = useRef(null);
        const refNewRate = useRef(null);
        const refTimer = useRef(null);
        const { id: finalId, name: finalName } = item;

        const [state, dispatchState] = useReducer(reducer, {
            type: TYPE_INPUT_OVERRIDE[0]
        });
        const { type: finalType } = state;
        const rateValue = item[keyGetRate];
        const { limitValue, id: typeId } = finalType;

        useEffect(() => {
            _newRate(rateValue);
            return () => clearTimeout(refTimer.current);
        }, []);

        useImperativeHandle(ref, () => ({
            reset: _handleResetInput
        }));

        const _handleChangeValue = (value) => {
            const newValue = { value, type: typeId };
            onChangeOverride({ id: finalId, ...newValue });
            _newRate(previewNewRate({ type: typeOverride, prevValue: rateValue, newValue }));
        };

        const _handleOnBlur = (e) => {
            e.target.value = refInput.current.value;
        };

        const _handleChangeType = (item) => {
            dispatchState({ type: item });
            if (!refInput.current.value) return;
            onChangeOverride({ id: finalId, value: '', type: item.type });
            _handleResetInput({});
        };

        const _handleResetInput = ({ isResetNewRate = true }) => {
            clearTimeout(refTimer.current);
            refTimer.current = setTimeout(() => {
                refInput.current?.reset();
                isResetNewRate && _newRate(rateValue);
            }, 100);
        };

        const _renderType = () => {
            return TYPE_INPUT_OVERRIDE.map((item) => {
                const { id, value } = item;
                const finalValue = value === CURRENCY_VALUE ? currency?.symbol : value;

                if (!finalValue) return null;
                return (
                    <button
                        key={id}
                        className={classNames('tab-items', { 'active-tab-selector': typeId === id })}
                        type="button"
                        data-type-input={typeId}
                        tabIndex={-1}
                        onClick={() => _handleChangeType(item)}
                    >
                        {finalValue}
                    </button>
                );
            });
        };

        const _newRate = (value, isNewRate = true) => {
            let content = value;
            switch (typeOverride) {
                case OVERRIDE_KEYWORD.TAX:
                    content = `${value}${PERCENT_VALUE}`;
                    break;
                case OVERRIDE_KEYWORD.ITEM:
                    content = transformToCurrency(value, currency);
                    break;
                default:
                    break;
            }
            if (isNewRate) !!refNewRate.current && (refNewRate.current.innerText = content);
            else return content;
        };

        return (
            <div className="rows">
                <div className="col col-xl">
                    <div className="txt-ellipsis fw-500" title={finalName}>
                        {finalName}
                    </div>
                </div>
                <div className="col col-lg">
                    <div className="col-label fw-500">{_newRate(rateValue, false)}</div>
                </div>
                <div className="col col-lg">
                    <div className="tabs">
                        <div className="btn-item --has-value m-0">
                            <GDInputNumber
                                ref={refInput}
                                className="btn-item__input"
                                type="number"
                                placeholder={0}
                                defaultValue=""
                                exceptNegative={exceptNegative}
                                isStringValue
                                onChange={_handleChangeValue}
                                onBlur={_handleOnBlur}
                                limitValue={limitValue}
                            />
                            {_renderType()}
                        </div>
                    </div>
                </div>
                <div className="col col-lg">
                    <div ref={refNewRate} className="col-label fw-500"></div>
                </div>
            </div>
        );
    }
);
