import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import IconDeviceSession from 'assets/icon/IconDeviceSession';
import IconLogout from 'assets/icon/IconLogout';
import IconSignedOut from 'assets/icon/IconSignedOut';
import { caculateTimeAgo } from 'common/utils/TimeUtils';

const SessionDeviceItems = ({ data = [], onSignOut = () => {} }) => {
    const { t } = useTranslation('setting');

    return data.map((item) => {
        const { active, user_agent, id, created, country_name } = item;
        return (
            <div className="device-items" key={id}>
                <div className="device-items__icon flex-centeritem">
                    <IconDeviceSession type={user_agent?.type} />
                </div>
                <div className="device-items__info flex-1">
                    <div className="word-break">{user_agent?.platform}</div>
                    <div className="last-login">
                        <span className="word-break">{country_name}</span>
                        <span className="time">{caculateTimeAgo(created)}</span>
                    </div>
                </div>
                <div
                    className={classNames('btn-modal v2-btn-default has-icon', { 'is-disable': !active })}
                    onClick={() => onSignOut(item)}
                >
                    {active ? <IconLogout /> : <IconSignedOut />}
                    {active ? t('sign_out') : t('signed_out')}
                </div>
            </div>
        );
    });
};

export default SessionDeviceItems;
