import AddPaymentSquare from 'app/components/payment/AddPaymentSquare';
import AddPaymentStripe from 'app/components/payment/AddPaymentStripe';
import { PAYMENT_MERCHANT, PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import IconBell from 'assets/icon/IconBell';
import IconPlus from 'assets/icon/IconPlus';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import BoxRows from '../BoxRows';
import ListCard from './ListCard';
import Preferences from './Preferences';
import { useTranslation } from 'react-i18next';
import { METHOD_CONVERT } from 'app/const/App';

const BoxPayment = (props, ref) => {
    const { t } = useTranslation(['common, customers']);
    const addonsUser = useSelector(({ auth }) => auth.user.settings.addons);

    const refListCard = useRef(null);
    const refPreferences = useRef(null);
    const refAddStripe = useRef(null);
    const refAddSquare = useRef(null);

    const isDisplaySquare = !!addonsUser.square;
    const isDisplayStripe = !!addonsUser.stripe;

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        return {
            messaging_preferences: refPreferences.current._getValue(),
            cards: refListCard.current?.getValue() || []
        };
    };

    const _handleOpenPreference = () => {
        refPreferences.current._open();
    };

    const _handleAddCard = (paymentSelected) => {
        switch (paymentSelected) {
            case PAYMENT_METHODS.STRIPE:
                refAddStripe.current && refAddStripe.current._open(true, true);
                break;
            case PAYMENT_METHODS.SQUARE:
                refAddSquare.current && refAddSquare.current._open(true);
                break;
            default:
                break;
        }
    };

    /**
     * @param data - the data that you want to submit.
     * @param keyword - the keyword of type card.
     */
    const _handleSubmitOneTime = (data, keyword) => {
        switch (keyword) {
            case PAYMENT_MERCHANT_KEYWORD.STRIPE:
                const card = data.token.card || {};
                refListCard.current.handleAdd({
                    name: 'Stripe',
                    account_number: `${METHOD_CONVERT} ${card.last4}`,
                    last_4: card.last4,
                    exp_month: card.exp_month,
                    exp_year: card.exp_year,
                    account_name: '',
                    account_type: card.brand,
                    token: data.token.id,
                    merchant: PAYMENT_MERCHANT.STRIPE_GATE_WAY,
                    keyword: 1,
                    meta: {
                        address_line1: card.address_line1,
                        address_line2: card.address_line2,
                        address_city: card.address_city,
                        address_state: card.address_state,
                        address_country: card.address_country
                    }
                });
                break;
            case PAYMENT_MERCHANT_KEYWORD.SQUARE:
                const cardSquare = data.card || {};
                refListCard.current.handleAdd({
                    name: 'Square',
                    account_number: `${METHOD_CONVERT} ${cardSquare.last4}`,
                    last_4: cardSquare.last4,
                    exp_month: cardSquare.expMonth,
                    exp_year: cardSquare.expYear,
                    account_type: cardSquare.brand,
                    token: data.nonce,
                    merchant: PAYMENT_MERCHANT.SQUARE_GATE_WAY,
                    keyword: 3
                });
                break;

            default:
                break;
        }
    };

    return (
        <>
            <div className="box-has-rows">
                <BoxRows label="Messaging">
                    <div
                        className="v2-btn-main --bg-green has-icon svg-white-stroke"
                        onClick={_handleOpenPreference}
                        tabIndex="0"
                    >
                        <IconBell />
                        {t('customers:preferences')}
                    </div>
                </BoxRows>
                {(isDisplayStripe || isDisplaySquare) && (
                    <BoxRows label="Payment" fieldClass="has-many-field">
                        <ListCard ref={refListCard} />
                        {isDisplayStripe && (
                            <div
                                className="v2-btn-main --bg-green has-icon svg-white"
                                onClick={() => _handleAddCard(PAYMENT_METHODS.STRIPE)}
                                tabIndex="0"
                            >
                                <IconPlus />
                                {t('customers:add_credit_card_in_stripe')}
                            </div>
                        )}

                        {isDisplaySquare && (
                            <div
                                className="v2-btn-main --bg-green has-icon svg-white"
                                onClick={() => _handleAddCard(PAYMENT_METHODS.SQUARE)}
                                tabIndex="0"
                            >
                                <IconPlus />
                                {t('customers:add_credit_card_in_square')}
                            </div>
                        )}
                    </BoxRows>
                )}
            </div>

            <AddPaymentStripe
                ref={refAddStripe}
                hideStripeLinkExisting
                onSubmitOneTime={(data) => _handleSubmitOneTime(data, PAYMENT_MERCHANT_KEYWORD.STRIPE)}
            />
            <AddPaymentSquare
                ref={refAddSquare}
                onSubmitOneTime={(data, nonce) =>
                    _handleSubmitOneTime({ card: data, nonce }, PAYMENT_MERCHANT_KEYWORD.SQUARE)
                }
            />
            <Preferences ref={refPreferences} />
        </>
    );
};

BoxPayment.displayName = 'AddCustomerBoxPayment';
export default forwardRef(BoxPayment);
