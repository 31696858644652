import { reducer } from 'app/const/Reducer';
import { useCallback, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useBlocker from './useBlocker';

const useCallbackPrompt = (when = true) => {
    const history = useHistory();
    const location = useLocation();
    const [state, dispatchState] = useReducer(reducer, {
        showPrompt: false,
        lastLocation: null,
        confirmedNavigation: false
    });
    const { showPrompt, lastLocation, confirmedNavigation } = state;

    const cancelNavigation = () => {
        dispatchState({ showPrompt: false });
    };

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (!confirmedNavigation && nextLocation.pathname !== location.pathname) {
                dispatchState({ showPrompt: true, lastLocation: nextLocation });
            }
        },
        [confirmedNavigation]
    );

    const confirmNavigation = () => {
        dispatchState({ showPrompt: false, confirmedNavigation: true });
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            history.push(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
};

export default useCallbackPrompt;
