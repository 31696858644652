import React from 'react';

import { AMOUNT_PAGE_PAGINATION, AMOUNT_POSISION_PAGINATION } from 'app/const/App';
import IconDoubleStep from 'assets/icon/IconDoubleStep';
import IconSingleStep from 'assets/icon/IconSingleStep';

function GdPager({ currentPage = 1, totalPage = 1, onChangePage = () => {} }) {
    const handleChangePage = (value) => {
        if (value === currentPage) {
            return false;
        }
        onChangePage(value);
    };

    const onChangePrevPage = () => {
        if (currentPage > 1) {
            handleChangePage(currentPage - 1);
        }
    };

    const onChangeNextPage = () => {
        if (currentPage < totalPage) {
            handleChangePage(currentPage + 1);
        }
    };

    const onChangeFirstPage = () => {
        if (currentPage > 1) {
            handleChangePage(1);
        }
    };

    const onChangeLastPage = () => {
        if (currentPage < totalPage) {
            handleChangePage(totalPage);
        }
    };

    const getListPager = (currentPage, totalPage) => {
        if (totalPage <= AMOUNT_POSISION_PAGINATION) {
            return {
                startPage: 1,
                endPage: totalPage,
                showThreeDot: false
            };
        }

        if (currentPage - (AMOUNT_PAGE_PAGINATION - 1) / 2 <= 0) {
            return {
                startPage: 1,
                endPage: AMOUNT_PAGE_PAGINATION,
                showThreeDot: true
            };
        } else if (currentPage > totalPage - AMOUNT_POSISION_PAGINATION) {
            return {
                startPage: totalPage - AMOUNT_POSISION_PAGINATION + 1,
                endPage: totalPage,
                showThreeDot: false
            };
        }

        return {
            startPage: currentPage - (AMOUNT_PAGE_PAGINATION - 1) / 2,
            endPage: currentPage + (AMOUNT_PAGE_PAGINATION - 1) / 2,
            showThreeDot: true
        };
    };

    const _renderListPage = () => {
        const listPage = [];

        const { startPage, endPage, showThreeDot } = getListPager(currentPage, totalPage);

        for (let i = startPage; i <= endPage; i++) {
            listPage.push(i);
        }

        return (
            <>
                {listPage.map((item) => {
                    const clsActive = item === currentPage;
                    return (
                        <li key={item} onClick={() => handleChangePage(item)}>
                            <div className={`page ${clsActive && 'active'}`}>
                                <p className="txt-ellipsis">{item}</p>
                            </div>
                        </li>
                    );
                })}
                {showThreeDot && (
                    <>
                        <li>
                            <div className="page">...</div>
                        </li>
                        <li onClick={() => handleChangePage(totalPage)}>
                            <div className="page">
                                <p className="txt-ellipsis">{totalPage}</p>
                            </div>
                        </li>
                    </>
                )}
            </>
        );
    };

    return (
        <div className="pagination">
            <ul className="justify-end">
                <li onClick={onChangeFirstPage}>
                    <div className="prev-all mr-2">
                        <IconDoubleStep isPrev />
                    </div>
                </li>
                <li onClick={onChangePrevPage}>
                    <div className="prev">
                        <IconSingleStep isPrev />
                    </div>
                </li>
                {_renderListPage()}
                <li onClick={onChangeNextPage}>
                    <div className="next ml-2">
                        <IconSingleStep />
                    </div>
                </li>
                <li onClick={onChangeLastPage}>
                    <div className="next-all">
                        <IconDoubleStep />
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default GdPager;
