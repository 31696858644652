export const GET_SMS_CONVERSATION_TIMEOUT = 60;

export const SMS_SENDING = 'sending';
export const SMS_FAILED = 'failed';
export const SMS_SUCCEEDED = 'succeeded';

export const SMS_TYPE_CUSTOMER_SENDER = 1;
export const SMS_TYPE_USERS_SENDER = 2;

export const STRING_TODAY = 'Today, ';

export const IS_RECEIVER_PHONE_DELETED = 1;
export const NOT_RECEIVER_PHONE_DELETED = 0;

export const UNREAD_STATUS = 1;
export const READ_STATUS = 2;

export const SMS_INCREMENT_TOTAL = 'increment';
export const SMS_DECREMENT_TOTAL = 'decrement';

export const SMS_INCREMENT_TOTAL_CODE = 1;
export const SMS_DECREMENT_TOTAL_CODE = 2;

export const CONVERSATION_TYPE = {
    CUSTOMER: 1,
    UNKNOW: 2
};

export const DELETED = 1;

export const TABS_SMS = {
    LIST: 'LIST',
    ITEM: 'ITEM',
    CUSTOMER: 'CUSTOMER',
    UN_ACTIVE: 'UN_ACTIVE'
};

export const LIST_SMS_LIMIT = 20;
export const LIST_SMS_CONVERSATION_LIMIT = 20;

export const MODULE_SMS = {
    OFFSET_SCROLL_TOP: 1,
    STATUS_LIST_CONVERSATION: {
        UNREAD: 1,
        READ: 2
    },
    TYPE_SMS: {
        GO: 2,
        TO: 1
    }
};

export const DEFAULT_PHONES = {
    loading: true,
    list: [],
    receivers: [],
    fetched: false,
    triggerSms: true
};

export const DEFAULT_SMS = {
    isLoading: true,
    offset: 0,
    list: [],
    attachments: [],
    show_more: false,
    isLoadMore: false
};

export const SMS_TYPE = {
    SEND: 'send',
    RECEIVE: 'receive'
};

export const PDF_TYPE = 'application/pdf';

export const TYPE_OPEN_SMS = {
    DETAIL: 'JOB_DETAIL',
    PREVIEW: 'JOB_PREVIEW',
    CUSTOMER: 'CUSTOMER'
};
