import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken } from 'common/utils/ApiUtils';

import { SETTING_GET_LIST_EMAIL_VARIABLE, SETTING_SEND_TEST_EMAIL } from 'app/const/Api';

import { TYPE_EMAIL_SETTING } from 'common/redux/actions/settings/emailAction';

export function* getListEmailVariable({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTING_GET_LIST_EMAIL_VARIABLE, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_EMAIL_SETTING.GET_LIST_EMAIL_VARIABLE_SUCCESS,
                payload: response.data
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* sendTestEmail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTING_SEND_TEST_EMAIL, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
