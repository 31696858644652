import { call, put } from 'redux-saga/effects';
import { fetchWithToken, putWithToken } from 'common/utils/ApiUtils';

import {
    SMS_GET_LIST_PHONE,
    SMS_GET_LIST_BY_CUSTOMER,
    SMS_GET_LIST_VARIABLE,
    SMS_MARK_STATUS
} from 'app/const/api/Sms';
import { TYPE_SMS } from 'common/redux/actions/sms/smsAction';

export function* smsGetListPhones({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SMS_GET_LIST_PHONE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* smsGetListByCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SMS_GET_LIST_BY_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* smsGetListVariable({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SMS_GET_LIST_VARIABLE, params);
        if (response && response.success) {
            yield put({
                type: TYPE_SMS.SMS_SET_LIST_VARIABLE,
                payload: response.data
            });
            actionSuccess();
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* smsUpdateStatus({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SMS_MARK_STATUS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
