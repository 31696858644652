import { call } from "redux-saga/effects";
import { fetchWithToken } from "common/utils/ApiUtils";

import { REVIEW_LIKELY_GET_LIST } from "app/const/Api";

export function* getListReviewLikely({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, REVIEW_LIKELY_GET_LIST, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}