import { MIXPANEL_EVENT_NAME, MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';
import { getTimeEventMixpanel } from 'common/utils/MixpanelUtils';

export const mixpanelAddEstimate = ({ id = null }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.LAST_ESTIMATE_ADDED]: getTimeEventMixpanel(),
        [MIXPANEL_PROPERTIES.ADD_ESTIMATE]: getTimeEventMixpanel()
    };

    return {
        type: MIXPANEL_EVENT_NAME.ADD_ESTIMATE,
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet,
            [MIXPANEL_METHOD.SET_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_ESTIMATE_ADDED]: getTimeEventMixpanel()
            },
            [MIXPANEL_METHOD.INCREMENT]: MIXPANEL_PROPERTIES.INCREMENT_ESTIMATE_ADDED,
            [MIXPANEL_METHOD.REGISTER_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_ESTIMATE_ADDED]: getTimeEventMixpanel()
            }
        }
    };
};
