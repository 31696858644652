import React from 'react';

export default function IconUpload() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                d="M4.5 10.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5H16.5C17.6046 5.5 18.5 6.39543 18.5 7.5V10.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path d="M11.5 19.5L11.5 10.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" />{' '}
            <path
                d="M16 15L11.5 10.5L7 15"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
        </svg>
    );
}
