import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { TYPE_SETTING_PIPELINES } from 'common/redux/actions/settings/pipelinesAction';
import { ADDONS_PIPELINES, actionAlterPipelines, actionCreatePipelines, actionUpdatePipelines } from 'app/const/api/V2';
import { TYPE_ALTER_PIPELINES } from 'app/const/setting/SettingPipelines';

export function* getListPipelines({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, ADDONS_PIPELINES, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_PIPELINES.GET_LIST_SUCCESS,
                payload: response.data
            });
        } else {
            actionFailed(response);
            yield put({ type: TYPE_SETTING_PIPELINES.GET_LIST_FAIL });
        }
    } catch (error) {
        actionFailed(error);
        yield put({ type: TYPE_SETTING_PIPELINES.GET_LIST_FAIL });
    }
}

export function* createPipelines({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const idGroup = params?.idGroup;
        delete params.idGroup;
        const response = yield call(postWithToken, actionCreatePipelines(idGroup), params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_PIPELINES.CREATE_SUCCESS,
                payload: { data: response.data, id: idGroup }
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
export function* updatePipelines({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const id = params?.id;
        const idGroup = params?.idGroup;
        delete params.id;
        delete params.idGroup;
        const response = yield call(putWithToken, actionUpdatePipelines(idGroup, id), params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_PIPELINES.UPDATE_SUCCESS,
                payload: { data: response.data, id: idGroup }
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deletePipelines({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const id = params?.id;
        const idGroup = params?.idGroup;
        delete params.id;
        delete params.idGroup;
        const response = yield call(putWithToken, actionAlterPipelines(idGroup, id), {
            type: TYPE_ALTER_PIPELINES.DELETE,
            ...params
        });
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_PIPELINES.DELETE_SUCCESS,
                payload: { id, idGroup }
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
