export default function IconCall({
    isMissing = false,
    isCallIn = false,
    isCallOut = false,
    isOnCall = false,
    isWhisper = false,
    isInActive = false,
    isHeaderNotification = false
}) {
    if (isMissing) {
        if (isInActive)
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none">
                    <path
                        d="M1.43335 3.0666C1.43335 2.85443 1.51763 2.65095 1.66766 2.50092C1.81769 2.35089 2.02118 2.2666 2.23335 2.2666H3.95602C4.14534 2.26672 4.32849 2.33397 4.47289 2.4564C4.6173 2.57883 4.7136 2.74851 4.74468 2.93527L5.33668 6.48327C5.36497 6.65224 5.33826 6.82583 5.26049 6.97849C5.18272 7.13115 5.058 7.25481 4.90468 7.33127L3.66602 7.94993C4.11009 9.05046 4.77146 10.0502 5.61062 10.8893C6.44978 11.7285 7.44949 12.3899 8.55002 12.8339L9.16935 11.5953C9.24574 11.4422 9.36919 11.3176 9.52157 11.2399C9.67395 11.1621 9.84725 11.1353 10.016 11.1633L13.5647 11.7553C13.7514 11.7863 13.9211 11.8827 14.0435 12.0271C14.166 12.1715 14.2332 12.3546 14.2333 12.5439V14.2666C14.2333 14.4788 14.1491 14.6823 13.999 14.8323C13.849 14.9823 13.6455 15.0666 13.4333 15.0666H11.8333C6.08935 15.0666 1.43335 10.4106 1.43335 4.6666V3.0666Z"
                        fill="#C1C9D4"
                    />
                    <path
                        d="M13.1986 3.30135C13.3484 3.45135 13.4326 3.65468 13.4326 3.86668C13.4326 4.07868 13.3484 4.28201 13.1986 4.43201L12.1646 5.46668L13.1986 6.50135C13.3399 6.653 13.4169 6.85358 13.4132 7.06084C13.4096 7.26809 13.3256 7.46583 13.179 7.61241C13.0324 7.75898 12.8347 7.84294 12.6274 7.8466C12.4202 7.85026 12.2196 7.77332 12.068 7.63201L11.0333 6.59801L9.99862 7.63201C9.92438 7.70625 9.83625 7.76514 9.73925 7.80532C9.64225 7.8455 9.53828 7.86618 9.43329 7.86618C9.3283 7.86618 9.22434 7.8455 9.12734 7.80532C9.03034 7.76514 8.9422 7.70625 8.86796 7.63201C8.79372 7.55777 8.73483 7.46963 8.69465 7.37263C8.65447 7.27563 8.63379 7.17167 8.63379 7.06668C8.63379 6.96169 8.65447 6.85772 8.69465 6.76072C8.73483 6.66372 8.79372 6.57559 8.86796 6.50135L9.90196 5.46668L8.86796 4.43201C8.72665 4.28036 8.64971 4.07978 8.65337 3.87252C8.65703 3.66527 8.74099 3.46752 8.88756 3.32095C9.03414 3.17438 9.23188 3.09042 9.43913 3.08676C9.64639 3.0831 9.84697 3.16003 9.99862 3.30135L11.0333 4.33535L12.068 3.30135C12.218 3.15153 12.4213 3.06738 12.6333 3.06738C12.8453 3.06738 13.0486 3.15153 13.1986 3.30135Z"
                        fill="#7A83A6"
                    />
                </svg>
            );

        return (
            <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.43335 3.0666C1.43335 2.85443 1.51763 2.65095 1.66766 2.50092C1.81769 2.35089 2.02118 2.2666 2.23335 2.2666H3.95602C4.14534 2.26672 4.32849 2.33397 4.47289 2.4564C4.6173 2.57883 4.7136 2.74851 4.74468 2.93527L5.33668 6.48327C5.36497 6.65224 5.33826 6.82583 5.26049 6.97849C5.18272 7.13115 5.058 7.25481 4.90468 7.33127L3.66602 7.94993C4.11009 9.05046 4.77146 10.0502 5.61062 10.8893C6.44978 11.7285 7.44949 12.3899 8.55002 12.8339L9.16935 11.5953C9.24574 11.4422 9.36919 11.3176 9.52157 11.2399C9.67395 11.1621 9.84725 11.1353 10.016 11.1633L13.5647 11.7553C13.7514 11.7863 13.9211 11.8827 14.0435 12.0271C14.166 12.1715 14.2332 12.3546 14.2333 12.5439V14.2666C14.2333 14.4788 14.1491 14.6823 13.999 14.8323C13.849 14.9823 13.6455 15.0666 13.4333 15.0666H11.8333C6.08935 15.0666 1.43335 10.4106 1.43335 4.6666V3.0666Z"
                    fill="#EF5B5B"
                />
                <path
                    d="M13.1986 3.30135C13.3484 3.45135 13.4326 3.65468 13.4326 3.86668C13.4326 4.07868 13.3484 4.28201 13.1986 4.43201L12.1646 5.46668L13.1986 6.50135C13.3399 6.653 13.4169 6.85358 13.4132 7.06084C13.4096 7.26809 13.3256 7.46583 13.179 7.61241C13.0324 7.75898 12.8347 7.84294 12.6274 7.8466C12.4202 7.85026 12.2196 7.77332 12.068 7.63201L11.0333 6.59801L9.99862 7.63201C9.92438 7.70625 9.83625 7.76514 9.73925 7.80532C9.64225 7.8455 9.53828 7.86618 9.43329 7.86618C9.3283 7.86618 9.22434 7.8455 9.12734 7.80532C9.03034 7.76514 8.9422 7.70625 8.86796 7.63201C8.79372 7.55777 8.73483 7.46963 8.69465 7.37263C8.65447 7.27563 8.63379 7.17167 8.63379 7.06668C8.63379 6.96169 8.65447 6.85772 8.69465 6.76072C8.73483 6.66372 8.79372 6.57559 8.86796 6.50135L9.90196 5.46668L8.86796 4.43201C8.72665 4.28036 8.64971 4.07978 8.65337 3.87252C8.65703 3.66527 8.74099 3.46752 8.88756 3.32095C9.03414 3.17438 9.23188 3.09042 9.43913 3.08676C9.64639 3.0831 9.84697 3.16003 9.99862 3.30135L11.0333 4.33535L12.068 3.30135C12.218 3.15153 12.4213 3.06738 12.6333 3.06738C12.8453 3.06738 13.0486 3.15153 13.1986 3.30135Z"
                    fill="#EF5B5B"
                />
            </svg>
        );
    }
    if (isCallIn) {
        return (
            <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.3646 6.26715L13.9986 3.63249C14.1399 3.48083 14.2169 3.28025 14.2132 3.073C14.2096 2.86574 14.1256 2.668 13.979 2.52142C13.8325 2.37485 13.6347 2.29089 13.4275 2.28723C13.2202 2.28358 13.0196 2.36051 12.868 2.50182L10.2333 5.13582V3.86715C10.2333 3.65498 10.149 3.4515 9.99899 3.30147C9.84896 3.15144 9.64547 3.06715 9.4333 3.06715C9.22113 3.06715 9.01764 3.15144 8.86762 3.30147C8.71759 3.4515 8.6333 3.65498 8.6333 3.86715V7.06982C8.63321 7.17478 8.65386 7.27872 8.69407 7.37567C8.73427 7.47262 8.79324 7.56067 8.86758 7.63476C8.94192 7.70885 9.03017 7.76753 9.12725 7.80741C9.22434 7.84729 9.32834 7.86759 9.4333 7.86715H12.6333C12.8455 7.86715 13.049 7.78287 13.199 7.63284C13.349 7.48281 13.4333 7.27933 13.4333 7.06715C13.4333 6.85498 13.349 6.6515 13.199 6.50147C13.049 6.35144 12.8455 6.26715 12.6333 6.26715H11.3646Z"
                    fill="#1EAA5C"
                />
                <path
                    d="M1.43335 3.0666C1.43335 2.85443 1.51763 2.65095 1.66766 2.50092C1.81769 2.35089 2.02118 2.2666 2.23335 2.2666H3.95602C4.14534 2.26672 4.32849 2.33397 4.47289 2.4564C4.6173 2.57883 4.7136 2.74851 4.74468 2.93527L5.33668 6.48327C5.36497 6.65224 5.33826 6.82583 5.26049 6.97849C5.18272 7.13115 5.058 7.25481 4.90468 7.33127L3.66602 7.94993C4.11009 9.05046 4.77146 10.0502 5.61062 10.8893C6.44978 11.7285 7.44949 12.3899 8.55002 12.8339L9.16935 11.5953C9.24574 11.4422 9.36919 11.3176 9.52157 11.2399C9.67395 11.1621 9.84725 11.1353 10.016 11.1633L13.5647 11.7553C13.7514 11.7863 13.9211 11.8827 14.0435 12.0271C14.166 12.1715 14.2332 12.3546 14.2333 12.5439V14.2666C14.2333 14.4788 14.1491 14.6823 13.999 14.8323C13.849 14.9823 13.6455 15.0666 13.4333 15.0666H11.8333C6.08935 15.0666 1.43335 10.4106 1.43335 4.6666V3.0666Z"
                    fill="#1EAA5C"
                />
            </svg>
        );
    }
    if (isCallOut) {
        return (
            <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.1727 2.75994C14.1327 2.66375 14.0743 2.57632 14.0007 2.5026L13.9974 2.49927C13.8476 2.35007 13.6448 2.26639 13.4334 2.2666H10.2334C10.0212 2.2666 9.81771 2.35089 9.66768 2.50092C9.51765 2.65095 9.43336 2.85443 9.43336 3.0666C9.43336 3.27877 9.51765 3.48226 9.66768 3.63229C9.81771 3.78232 10.0212 3.8666 10.2334 3.8666H11.502L8.86803 6.50127C8.72672 6.65292 8.64979 6.8535 8.65344 7.06076C8.6571 7.26801 8.74106 7.46576 8.88763 7.61233C9.03421 7.75891 9.23195 7.84286 9.43921 7.84652C9.64646 7.85018 9.84705 7.77325 9.9987 7.63193L12.6334 4.99794V6.2666C12.6334 6.47877 12.7177 6.68226 12.8677 6.83229C13.0177 6.98232 13.2212 7.0666 13.4334 7.0666C13.6455 7.0666 13.849 6.98232 13.999 6.83229C14.1491 6.68226 14.2334 6.47877 14.2334 6.2666V3.0666C14.2334 2.96127 14.2134 2.85727 14.1727 2.75994Z"
                    fill="#1EAA5C"
                />
                <path
                    d="M1.43335 3.0666C1.43335 2.85443 1.51763 2.65095 1.66766 2.50092C1.81769 2.35089 2.02118 2.2666 2.23335 2.2666H3.95602C4.14534 2.26672 4.32849 2.33397 4.47289 2.4564C4.6173 2.57883 4.7136 2.74851 4.74468 2.93527L5.33668 6.48327C5.36497 6.65224 5.33826 6.82583 5.26049 6.97849C5.18272 7.13115 5.058 7.25481 4.90468 7.33127L3.66602 7.94993C4.11009 9.05046 4.77146 10.0502 5.61062 10.8893C6.44978 11.7285 7.44949 12.3899 8.55002 12.8339L9.16935 11.5953C9.24574 11.4422 9.36919 11.3176 9.52157 11.2399C9.67395 11.1621 9.84725 11.1353 10.016 11.1633L13.5647 11.7553C13.7514 11.7863 13.9211 11.8827 14.0435 12.0271C14.166 12.1715 14.2332 12.3546 14.2333 12.5439V14.2666C14.2333 14.4788 14.1491 14.6823 13.999 14.8323C13.849 14.9823 13.6455 15.0666 13.4333 15.0666H11.8333C6.08935 15.0666 1.43335 10.4106 1.43335 4.6666V3.0666Z"
                    fill="#1EAA5C"
                />
            </svg>
        );
    }
    if (isOnCall)
        return (
            <svg width={16} height={17} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width={16} height={16} rx={8} fill="#1EAA5C" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.88 10.4601C11.2318 9.94346 10.574 9.63048 9.93377 10.1464L9.55147 10.4582C9.27177 10.6845 8.75171 11.7421 6.74101 9.58638C4.73073 7.43338 5.92702 7.09815 6.20714 6.87376L6.59153 6.56156C7.22841 6.04448 6.98806 5.39355 6.52872 4.72349L6.25153 4.31763C5.7901 3.64913 5.28763 3.2101 4.64908 3.7264L4.30405 4.00738C4.02183 4.19899 3.23296 4.82183 3.04161 6.00507C2.81131 7.42479 3.53779 9.05057 5.20221 10.8344C6.86454 12.619 8.49211 13.5154 10.0338 13.4998C11.3151 13.4869 12.0889 12.8461 12.3309 12.6132L12.6772 12.3318C13.3141 11.8159 12.9184 11.2906 12.2698 10.7727L11.88 10.4601Z"
                    fill="white"
                />
            </svg>
        );

    if (isWhisper)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                <rect width={16} height={16} rx={8} fill="#1E7EF7" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 3.16667C6.13654 3.16667 4.5 4.95706 4.5 7.33333V10.5486L4.86689 11.2824C4.96573 11.4801 5.13905 11.6304 5.34872 11.7003L6.35239 12.0349C6.56008 11.713 6.92183 11.5 7.33333 11.5H8.66667C9.311 11.5 9.83333 12.0223 9.83333 12.6667C9.83333 13.311 9.311 13.8333 8.66667 13.8333H7.33333C6.82267 13.8333 6.38863 13.5052 6.23053 13.0483L5.0325 12.649C4.57122 12.4952 4.18991 12.1645 3.97246 11.7296L3.64541 11.0755L2.29773 10.6263C1.82134 10.4675 1.5 10.0217 1.5 9.51949V7.81384C1.5 7.31167 1.82133 6.86584 2.29773 6.70704L3.5956 6.27442C4.0158 3.97461 5.77715 2.16667 8 2.16667C10.2228 2.16667 11.9842 3.97461 12.4044 6.27442L13.7023 6.70704C14.1787 6.86584 14.5 7.31167 14.5 7.81384V9.51949C14.5 10.0217 14.1787 10.4675 13.7023 10.6263L12.1581 11.141C12.0056 11.1918 11.838 11.1663 11.7076 11.0723C11.5773 10.9783 11.5 10.8274 11.5 10.6667V7.33333C11.5 4.95706 9.86346 3.16667 8 3.16667ZM3.5 7.36038L2.61396 7.65573C2.54591 7.67841 2.5 7.7421 2.5 7.81384V9.51949C2.5 9.59123 2.54591 9.65492 2.61396 9.67761L3.5 9.97295V7.36038ZM13.386 7.65573L12.5 7.36038V9.97295L13.386 9.67761C13.4541 9.65492 13.5 9.59123 13.5 9.51949V7.81384C13.5 7.7421 13.4541 7.67841 13.386 7.65573ZM7.16667 12.6667C7.16667 12.5746 7.24129 12.5 7.33333 12.5H8.66667C8.75871 12.5 8.83333 12.5746 8.83333 12.6667C8.83333 12.7587 8.75871 12.8333 8.66667 12.8333H7.33333C7.24129 12.8333 7.16667 12.7587 7.16667 12.6667Z"
                    fill="white"
                />
            </svg>
        );

    if (isHeaderNotification) {
        if (isInActive)
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="4 4 24 24" fill="none">
                    <path
                        d="M8.93311 11.0666C8.93311 10.8544 9.01739 10.6509 9.16742 10.5009C9.31745 10.3509 9.52093 10.2666 9.73311 10.2666H11.4558C11.6451 10.2667 11.8282 10.334 11.9726 10.4564C12.1171 10.5788 12.2134 10.7485 12.2444 10.9353L12.8364 14.4833C12.8647 14.6522 12.838 14.8258 12.7602 14.9785C12.6825 15.1312 12.5578 15.2548 12.4044 15.3313L11.1658 15.9499C11.6098 17.0505 12.2712 18.0502 13.1104 18.8893C13.9495 19.7285 14.9492 20.3899 16.0498 20.8339L16.6691 19.5953C16.7455 19.4422 16.8689 19.3176 17.0213 19.2399C17.1737 19.1621 17.347 19.1353 17.5158 19.1633L21.0644 19.7553C21.2512 19.7863 21.4209 19.8827 21.5433 20.0271C21.6657 20.1715 21.733 20.3546 21.7331 20.5439V22.2666C21.7331 22.4788 21.6488 22.6823 21.4988 22.8323C21.3488 22.9823 21.1453 23.0666 20.9331 23.0666H19.3331C13.5891 23.0666 8.93311 18.4106 8.93311 12.6666V11.0666Z"
                        fill="#7A83A6"
                    />
                    <path
                        d="M20.6986 11.3013C20.8484 11.4513 20.9326 11.6547 20.9326 11.8667C20.9326 12.0787 20.8484 12.282 20.6986 12.432L19.6646 13.4667L20.6986 14.5013C20.8399 14.653 20.9169 14.8536 20.9132 15.0608C20.9096 15.2681 20.8256 15.4658 20.679 15.6124C20.5324 15.759 20.3347 15.8429 20.1274 15.8466C19.9202 15.8503 19.7196 15.7733 19.568 15.632L18.5333 14.598L17.4986 15.632C17.4244 15.7063 17.3362 15.7651 17.2392 15.8053C17.1422 15.8455 17.0383 15.8662 16.9333 15.8662C16.8283 15.8662 16.7243 15.8455 16.6273 15.8053C16.5303 15.7651 16.4422 15.7063 16.368 15.632C16.2937 15.5578 16.2348 15.4696 16.1946 15.3726C16.1545 15.2756 16.1338 15.1717 16.1338 15.0667C16.1338 14.9617 16.1545 14.8577 16.1946 14.7607C16.2348 14.6637 16.2937 14.5756 16.368 14.5013L17.402 13.4667L16.368 12.432C16.2266 12.2804 16.1497 12.0798 16.1534 11.8725C16.157 11.6653 16.241 11.4675 16.3876 11.3209C16.5341 11.1744 16.7319 11.0904 16.9391 11.0868C17.1464 11.0831 17.347 11.16 17.4986 11.3013L18.5333 12.3353L19.568 11.3013C19.718 11.1515 19.9213 11.0674 20.1333 11.0674C20.3453 11.0674 20.5486 11.1515 20.6986 11.3013Z"
                        fill="#7A83A6"
                    />
                </svg>
            );

        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="4 4 24 24" fill="none">
                <path
                    d="M8.93311 11.0666C8.93311 10.8544 9.01739 10.6509 9.16742 10.5009C9.31745 10.3509 9.52093 10.2666 9.73311 10.2666H11.4558C11.6451 10.2667 11.8282 10.334 11.9726 10.4564C12.1171 10.5788 12.2134 10.7485 12.2444 10.9353L12.8364 14.4833C12.8647 14.6522 12.838 14.8258 12.7602 14.9785C12.6825 15.1312 12.5578 15.2548 12.4044 15.3313L11.1658 15.9499C11.6098 17.0505 12.2712 18.0502 13.1104 18.8893C13.9495 19.7285 14.9492 20.3899 16.0498 20.8339L16.6691 19.5953C16.7455 19.4422 16.8689 19.3176 17.0213 19.2399C17.1737 19.1621 17.347 19.1353 17.5158 19.1633L21.0644 19.7553C21.2512 19.7863 21.4209 19.8827 21.5433 20.0271C21.6657 20.1715 21.733 20.3546 21.7331 20.5439V22.2666C21.7331 22.4788 21.6488 22.6823 21.4988 22.8323C21.3488 22.9823 21.1453 23.0666 20.9331 23.0666H19.3331C13.5891 23.0666 8.93311 18.4106 8.93311 12.6666V11.0666Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M20.6986 11.3013C20.8484 11.4513 20.9326 11.6547 20.9326 11.8667C20.9326 12.0787 20.8484 12.282 20.6986 12.432L19.6646 13.4667L20.6986 14.5013C20.8399 14.653 20.9169 14.8536 20.9132 15.0608C20.9096 15.2681 20.8256 15.4658 20.679 15.6124C20.5324 15.759 20.3347 15.8429 20.1274 15.8466C19.9202 15.8503 19.7196 15.7733 19.568 15.632L18.5333 14.598L17.4986 15.632C17.4244 15.7063 17.3362 15.7651 17.2392 15.8053C17.1422 15.8455 17.0383 15.8662 16.9333 15.8662C16.8283 15.8662 16.7243 15.8455 16.6273 15.8053C16.5303 15.7651 16.4422 15.7063 16.368 15.632C16.2937 15.5578 16.2348 15.4696 16.1946 15.3726C16.1545 15.2756 16.1338 15.1717 16.1338 15.0667C16.1338 14.9617 16.1545 14.8577 16.1946 14.7607C16.2348 14.6637 16.2937 14.5756 16.368 14.5013L17.402 13.4667L16.368 12.432C16.2266 12.2804 16.1497 12.0798 16.1534 11.8725C16.157 11.6653 16.241 11.4675 16.3876 11.3209C16.5341 11.1744 16.7319 11.0904 16.9391 11.0868C17.1464 11.0831 17.347 11.16 17.4986 11.3013L18.5333 12.3353L19.568 11.3013C19.718 11.1515 19.9213 11.0674 20.1333 11.0674C20.3453 11.0674 20.5486 11.1515 20.6986 11.3013Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    }

    return (
        <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.43335 3.0666C1.43335 2.85443 1.51763 2.65095 1.66766 2.50092C1.81769 2.35089 2.02118 2.2666 2.23335 2.2666H3.95602C4.14534 2.26672 4.32849 2.33397 4.47289 2.4564C4.6173 2.57883 4.7136 2.74851 4.74468 2.93527L5.33668 6.48327C5.36497 6.65224 5.33826 6.82583 5.26049 6.97849C5.18272 7.13115 5.058 7.25481 4.90468 7.33127L3.66602 7.94993C4.11009 9.05046 4.77146 10.0502 5.61062 10.8893C6.44978 11.7285 7.44949 12.3899 8.55002 12.8339L9.16935 11.5953C9.24574 11.4422 9.36919 11.3176 9.52157 11.2399C9.67395 11.1621 9.84725 11.1353 10.016 11.1633L13.5647 11.7553C13.7514 11.7863 13.9211 11.8827 14.0435 12.0271C14.166 12.1715 14.2332 12.3546 14.2333 12.5439V14.2666C14.2333 14.4788 14.1491 14.6823 13.999 14.8323C13.849 14.9823 13.6455 15.0666 13.4333 15.0666H11.8333C6.08935 15.0666 1.43335 10.4106 1.43335 4.6666V3.0666Z"
                fill="#1EAA5C"
            />
        </svg>
    );
}
