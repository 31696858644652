import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertTimeNumber } from 'common/utils/TimeUtils';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getListTimeWindow } from 'common/redux/actions/timeWindowAction';

export default function Templates({ onChange = () => {}, selected = '', isActive = false }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        list: listTemplates = [],
        isFirstTime,
        isLoading
    } = useSelector(({ timeWindowReducer }) => timeWindowReducer);

    useEffect(() => {
        if (isFirstTime) {
            dispatch(getListTimeWindow());
        }
    }, []);

    const returnTime = (timeNumber) => {
        if (!timeNumber) {
            return '';
        }
        const { hour, minute, midDay } = convertTimeNumber(timeNumber);
        return `${hour}:${minute} ${midDay}`;
    };

    const _handleChangeTemplate = (newTemplate) => {
        onChange({
            id: newTemplate.id,
            start: convertTimeToISO(moment(returnTime(newTemplate.start), 'hh:mm A')),
            end: convertTimeToISO(moment(returnTime(newTemplate.end), 'hh:mm A'))
        });
    };

    if (isLoading) {
        return (
            <div className="wrap-select-time box-selection">
                <div className="loading --animation --line --twothird mb-2" />
                <div className="check-radio">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
                <div className="check-radio">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
                <div className="check-radio">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        );
    }

    const _renderListTemplete = () => {
        return listTemplates.map((item) => {
            const { id: itemId, name, start, end } = item || {};

            return (
                <div className="check-radio" key={itemId}>
                    <input
                        onChange={() => _handleChangeTemplate(item)}
                        checked={itemId === selected}
                        id={`item_template_${itemId}`}
                        type="radio"
                        name="window-template"
                    />
                    <label htmlFor={`item_template_${itemId}`} className="template-name js-select-box">
                        <p className="template-name__label">{name}</p>
                        <span className="template-name__time">
                            {returnTime(start)} - {returnTime(end)}
                        </span>
                    </label>
                </div>
            );
        });
    };

    return (
        <div className={`wrap-select-time box-selection --template ${isActive ? 'is-selected' : ''}`}>
            <p className="box-selection__title">{t('select_custom_template')}</p>
            {_renderListTemplete()}
        </div>
    );
}
