export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.4361 4C16.3276 4 16.6509 4.09283 16.9768 4.26713C17.3028 4.44144 17.5586 4.69723 17.7329 5.02315C17.9072 5.34908 18 5.67237 18 6.56389V16.4361C18 17.3276 17.9072 17.6509 17.7329 17.9768C17.5586 18.3028 17.3028 18.5586 16.9768 18.7329C16.6509 18.9072 16.3276 19 15.4361 19H8.56389C7.67237 19 7.34908 18.9072 7.02315 18.7329C6.69723 18.5586 6.44144 18.3028 6.26713 17.9768C6.09283 17.6509 6 17.3276 6 16.4361V6.56389C6 5.67237 6.09283 5.34908 6.26713 5.02315C6.44144 4.69723 6.69723 4.44144 7.02315 4.26713C7.34908 4.09283 7.67237 4 8.56389 4H15.4361ZM14 5H10C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9H14C15.1046 9 16 8.10457 16 7C16 5.89543 15.1046 5 14 5Z"
            fill="#C1C9D4"
        ></path>
        <path
            d="M14 6H10C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8H14C14.5523 8 15 7.55228 15 7C15 6.44772 14.5523 6 14 6Z"
            fill="#7A83A6"
        ></path>
        <path
            d="M15 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11Z"
            fill="#7A83A6"
        ></path>
        <path
            d="M12 14H9C8.44772 14 8 14.4477 8 15C8 15.5523 8.44772 16 9 16H12C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14Z"
            fill="#7A83A6"
        ></path>
    </svg>
);
