export const TABS = {
    WORK_POOL: 'work_pool',
    TASKS: 'tasks',
    JOB_LIST: 'JOB_LIST'
};

export const STATUS = {
    ACTIVE: 1,
    DONE: 0
};

export const WOORPOOL_TAB = {
    POOL: 'pool',
    MISSED: 'missed'
};

export const TASK_JOB_LIST = {
    ACTIVE: 'active',
    HISTORY: 'history'
};
