import {
    SETTINGS_TEMPLATES_ESTIMATE,
    SETTINGS_TEMPLATES_NOTES,
    SETTINGS_TEMPLATES_SMS,
    SETTINGS_TEMPLATES_TODO
} from 'app/config/routes';
import { GET_TEMPLATES_NOTE, updateTemplateNote } from './api/Notes';
import { GET_TEMPLATES_ESTIMATES, GET_TEMPLATES_SMS, actionTemplateEstimates, updateTemplateSMS } from './api/V2';
import { PERMISSIONS } from './Permissions';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { ADDONS_FOOTER_OPTIONS } from './addons';

export const NOTES_TEMPLATE_TYPE = {
    CUSTOMER: 6,
    ESTIMATE: 7,
    INVOICE: 8,
    JOB: 9,
    TOP: 10,
    WORK_ORDER: 11
};

export const ESTIMATES_TEMPLATE_TYPE = {
    BASIC: 14,
    DYNAMIC: 15,
    PACKAGES: 16
};

export const ESTIMATE_TEMPLATE_ID = {
    [ADDONS_FOOTER_OPTIONS.MANAGE_DYNAMIC_ESTIMATE]: ESTIMATES_TEMPLATE_TYPE.DYNAMIC,
    [ADDONS_FOOTER_OPTIONS.MANAGE_ESTIMATE_PACKAGE]: ESTIMATES_TEMPLATE_TYPE.PACKAGES
};

export const NOTES_TEMPLATE_OPTIONS = [
    {
        id: NOTES_TEMPLATE_TYPE.CUSTOMER,
        name: 'customer_notes',
        link: SETTINGS_TEMPLATES_NOTES
    },
    {
        id: NOTES_TEMPLATE_TYPE.ESTIMATE,
        name: 'estimate_notes',
        link: SETTINGS_TEMPLATES_NOTES
    },
    {
        id: NOTES_TEMPLATE_TYPE.INVOICE,
        name: 'invoice_notes',
        link: SETTINGS_TEMPLATES_NOTES
    },
    {
        id: NOTES_TEMPLATE_TYPE.JOB,
        name: 'job_notes',
        link: SETTINGS_TEMPLATES_NOTES
    },
    {
        id: NOTES_TEMPLATE_TYPE.TOP,
        name: 'top_notes',
        link: SETTINGS_TEMPLATES_NOTES
    },
    {
        id: NOTES_TEMPLATE_TYPE.WORK_ORDER,
        name: 'work_order_notes',
        link: SETTINGS_TEMPLATES_NOTES
    }
];

export const ESTIMATES_TEMPLATE_OPTIONS = [
    {
        id: ESTIMATES_TEMPLATE_TYPE.BASIC,
        name: 'basic_estimates',
        link: SETTINGS_TEMPLATES_ESTIMATE,
        parentId: 1,
        isDefault: true
    },
    {
        id: ESTIMATES_TEMPLATE_TYPE.DYNAMIC,
        name: 'dynamic_estimates',
        key: 'estimate_dynamic',
        link: SETTINGS_TEMPLATES_ESTIMATE,
        parentId: 2
    },
    {
        id: ESTIMATES_TEMPLATE_TYPE.PACKAGES,
        name: 'estimate_packages',
        key: 'estimate_package',
        link: SETTINGS_TEMPLATES_ESTIMATE,
        parentId: 3
    }
];

export const TEMPLATES_MANAGER_MENU = [
    ...NOTES_TEMPLATE_OPTIONS,
    {
        id: 12,
        name: 'line',
        link: null
    },
    {
        id: 13,
        name: 'todo_lists',
        link: SETTINGS_TEMPLATES_TODO
    },
    {
        id: 14,
        name: 'line',
        link: null
    },
    {
        id: 1533,
        name: 'sms_templates',
        link: SETTINGS_TEMPLATES_SMS
    },
    {
        id: 16,
        name: 'line',
        link: null
    }
];

export const TITLE_NOTES_TEMPLATE = {
    [-1]: 'all_templates',
    [NOTES_TEMPLATE_TYPE.CUSTOMER]: 'customer_notes',
    [NOTES_TEMPLATE_TYPE.ESTIMATE]: 'estimate_notes',
    [NOTES_TEMPLATE_TYPE.INVOICE]: 'invoice_notes',
    [NOTES_TEMPLATE_TYPE.JOB]: 'job_notes',
    [NOTES_TEMPLATE_TYPE.TOP]: 'top_notes',
    [NOTES_TEMPLATE_TYPE.WORK_ORDER]: 'work_order_notes'
};

export const TITLE_EDIT_NOTES_TEMPLATE = {
    [NOTES_TEMPLATE_TYPE.CUSTOMER]: 'edit_customer_note',
    [NOTES_TEMPLATE_TYPE.ESTIMATE]: 'edit_estimate_note',
    [NOTES_TEMPLATE_TYPE.INVOICE]: 'edit_invoice_note',
    [NOTES_TEMPLATE_TYPE.JOB]: 'edit_job_note',
    [NOTES_TEMPLATE_TYPE.TOP]: 'edit_top_note',
    [NOTES_TEMPLATE_TYPE.WORK_ORDER]: 'edit_work_order_note'
};

export const TITLE_ADD_NOTES_TEMPLATE = {
    [NOTES_TEMPLATE_TYPE.CUSTOMER]: 'add_customer_note',
    [NOTES_TEMPLATE_TYPE.ESTIMATE]: 'add_estimate_note',
    [NOTES_TEMPLATE_TYPE.INVOICE]: 'add_invoice_note',
    [NOTES_TEMPLATE_TYPE.JOB]: 'add_job_note',
    [NOTES_TEMPLATE_TYPE.TOP]: 'add_top_note',
    [NOTES_TEMPLATE_TYPE.WORK_ORDER]: 'add_work_order_note'
};

export const TAG_ESTIMATE_TEMPLATE = {
    [ESTIMATE_TYPE.BASIC]: 'basic_estimate',
    [ESTIMATE_TYPE.DYNAMIC]: 'dynamic_estimate',
    [ESTIMATE_TYPE.PACKAGES]: 'package_estimate'
};

export const TITLE_ESTIMATES_TEMPLATE = {
    [ESTIMATES_TEMPLATE_TYPE.BASIC]: 'basic_estimates',
    [ESTIMATES_TEMPLATE_TYPE.DYNAMIC]: 'dynamic_estimates',
    [ESTIMATES_TEMPLATE_TYPE.PACKAGES]: 'estimate_packages'
};

export const TEMPLATE_KEYWORD = {
    NOTE: 'note_template',
    SMS: 'sms_template',
    ESTIMATE: 'estimate_template'
};

export const ACTION_LIST_TEMPLATE = {
    [TEMPLATE_KEYWORD.NOTE]: {
        titleHeader: TITLE_NOTES_TEMPLATE,
        pathTemplate: SETTINGS_TEMPLATES_NOTES,
        getListTemplate: GET_TEMPLATES_NOTE,
        updateTemplate: updateTemplateNote,
        tagTemplate: TITLE_NOTES_TEMPLATE,
        additionalAccess: [PERMISSIONS.accessSettingsTab, PERMISSIONS.manageNoteTemplate]
    },
    [TEMPLATE_KEYWORD.SMS]: {
        titleHeader: 'sms_templates',
        pathTemplate: SETTINGS_TEMPLATES_SMS,
        getListTemplate: GET_TEMPLATES_SMS,
        updateTemplate: updateTemplateSMS,
        tagTemplate: 'sms',
        additionalAccess: [PERMISSIONS.accessSettingsTab]
    },
    [TEMPLATE_KEYWORD.ESTIMATE]: {
        titleHeader: TITLE_ESTIMATES_TEMPLATE,
        pathTemplate: SETTINGS_TEMPLATES_ESTIMATE,
        getListTemplate: GET_TEMPLATES_ESTIMATES,
        updateTemplate: actionTemplateEstimates,
        tagTemplate: TAG_ESTIMATE_TEMPLATE,
        additionalAccess: [PERMISSIONS.accessSettingsTab]
    }
};
