import { LIST_STATUS } from 'app/const/App';
import {
    ADDONS_VOIP_GROUP,
    SOURCE_PHONE_NUMBER_VOIP,
    TAB_ADDONS_VOIP_ADD_GROUP_NUMBER,
    TEXT_SAVE_VOIP_ADDONS,
    TYPE_MANAGE_ADDONS_VOIP
} from 'app/const/addons';
import { ACTION_PHONE_NUMBER, GET_LIST_SEARCH_PHONE, MIGRATE_SMS_NUMBER_VOIP } from 'app/const/api/Voip';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip, rawPhone, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import VOIPAddonsFormNumber from './VOIPAddonsFormNumber';
import SMSAddonsPhonesTable from '../../sms/components/SMSAddonsPhonesTable';
import ButtonSave from 'app/components/button/ButtonSave';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ADDONS_SMS_COUNTRIES } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { ADDONS_SMS_DETAIL } from 'app/const/Api';
import { VOIPAddonsLoadingAddNumber } from './loadings/VOIPAddonsLoadingAddNumber';

export const VOIPAddonsAddNumber = ({
    keyword,
    onClose = () => {},
    onUpdateListNumber = () => {},
    onShowVerifyNumber = () => {},
    listNumber = []
}) => {
    const { t } = useTranslation();
    const refFirstTime = useRef(false);
    const refFormNewNumber = useRef(null);
    const refFormExternalNumber = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refPhoneSelect = useRef(null);
    const refCheckBoxUnderstand = useRef(null);
    const refTablePrefix = useRef(null);
    const refTablePrefixEdit = useRef(null);
    const type = TYPE_MANAGE_ADDONS_VOIP[keyword];
    const {
        NEW_NUMBER: tabNewNumber,
        EXTERNAL_NUMBER: tabExternal,
        PORT_BYOC: tabPorting,
        EXIST_SMS_NUMBER: tabExistSMSNumber
    } = TAB_ADDONS_VOIP_ADD_GROUP_NUMBER;

    const isGroupHasNumber =
        keyword === ADDONS_VOIP_GROUP &&
        !!listNumber.filter((number) => number.source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL).length;

    const tabActiveAddNumber = isGroupHasNumber ? tabExternal : tabNewNumber;

    const initialState = {
        isLoading: false,
        tabActive: tabActiveAddNumber,
        phones: [],
        isShowPhones: false,
        textSave: TEXT_SAVE_VOIP_ADDONS[tabActiveAddNumber],
        isRequestNew: true,
        dataEdit: null,
        smsAccount: false
    };
    const [state, dispatchState] = useReducer(reducer, {
        ...initialState,
        isLoading: true,
        phoneCountries: []
    });
    const { tabActive, phoneCountries, isLoading, isShowPhones, phones, textSave, isRequestNew, dataEdit, smsAccount } =
        state;
    const isEditing = !!dataEdit?.id;

    const tabs = [
        {
            id: tabNewNumber,
            title: 'new_number',
            description: 'new_number_desc',
            isDisable: isGroupHasNumber
        },
        {
            id: tabExternal,
            title: 'external_number',
            description: 'external_number_desc'
        },
        {
            id: tabPorting,
            title: 'porting_and_byoc',
            description: 'porting_and_byoc_desc'
        }
    ];

    useEffect(() => {
        _open();
    }, []);

    const handleChangeTab = (id) => {
        dispatchState((prev) => {
            return {
                ...prev,
                tabActive: id,
                isRequestNew: true,
                phones: [],
                isShowPhones: false,
                textSave: TEXT_SAVE_VOIP_ADDONS[id]
            };
        });
    };

    const handleSetLoadingButton = (value) => {
        refButtonSave.current.setLoading(value);
        refButtonSave.current.setDisable(false);
    };
    const handleSetLoadingPrefix = (value) => {
        refTablePrefix.current?.setLoading(value);
        refTablePrefixEdit.current?.setLoading(value);
    };

    const _renderTabs = () => {
        const smsActive = tabActive === tabExistSMSNumber;

        const _renderTabCol = () => {
            return tabs.map((item) => {
                const { id, title, description, isDisable } = item;
                const isActive = id === tabActive;
                return (
                    <div
                        key={id}
                        className={classNames('tab-items flex-column', {
                            active: isActive,
                            'is-disable': isDisable
                        })}
                        onClick={() => handleChangeTab(id)}
                    >
                        <div className="check-items">
                            <input id={id} type="checkbox" checked={isActive} />
                            <div className="item-checkbox">
                                <label htmlFor={id} />
                            </div>
                        </div>
                        <b className="fw-600 black">{t(`addons:${title}`)}</b>
                        <span className="fs-11 grey-very-dark">{t(`addons:${description}`)}</span>
                    </div>
                );
            });
        };

        return (
            <div className="p-3">
                {smsAccount && (
                    <div
                        className={classNames('tab-items flex-column mb-1', {
                            active: smsActive,
                            'is-disable': isGroupHasNumber
                        })}
                        onClick={() => handleChangeTab(tabExistSMSNumber)}
                    >
                        <div className="check-items">
                            <input id="cb_1" type="checkbox" checked={smsActive} />
                            <div className="item-checkbox">
                                <label htmlFor="cb_1" />
                            </div>
                        </div>
                        <b className="fw-600 black">{t('addons:use_existing_sms_number')}</b>
                        <span className="fs-11 grey-very-dark">{formatPhoneNumberVoip(smsAccount?.phone_number)}</span>
                    </div>
                )}
                <div className="flexcenter gap-5">{_renderTabCol()}</div>
            </div>
        );
    };

    const _renderContent = () => {
        const _tabNewNumber = () => {
            return (
                <div id="elm_new_number" className="container-print tab-panel active">
                    <VOIPAddonsFormNumber
                        ref={refFormNewNumber}
                        id="new_number"
                        classWrapper="rows row-haft px-3 mb-3"
                        phoneCountries={phoneCountries}
                        onPressEnter={() => _handleSave(true)}
                        maxLength={5}
                        isOnlyNumber
                    />
                    {isShowPhones ? (
                        <SMSAddonsPhonesTable ref={refTablePrefix} data={phones} onSelect={_handleSelectPhoneNumber} />
                    ) : null}
                </div>
            );
        };

        const _tabExternalNumber = () => {
            return (
                <div id="elm_external_number" className="tab-panel px-3 pb-3 active">
                    <p className="message-info bg-blue-extralight">
                        <b className="mr-1 black">{t('note')}:</b>
                        {t('addons:external_number_note')}
                    </p>
                    <VOIPAddonsFormNumber
                        ref={refFormExternalNumber}
                        id="external_number"
                        textNumber={t('addons:phone_number_to_add')}
                        phoneCountries={phoneCountries}
                    />
                </div>
            );
        };

        const _tabPorting = () => {
            return (
                <p id="elm_porting" className="tab-panel px-3 pb-3 active">
                    {t('addons:desc_porting_byoc')}
                    <a
                        href="https://intercom.help/gorilladesk/en/articles/8010193-porting-a-number"
                        rel="noreferrer"
                        target="_blank"
                        className="is-link pl-1"
                    >
                        {t('addons:link_here')}
                    </a>
                </p>
            );
        };

        switch (tabActive) {
            case tabNewNumber:
                return _tabNewNumber();
            case tabExternal:
                return _tabExternalNumber();
            case tabPorting:
                return _tabPorting();
        }
    };

    const _renderFooterModal = () => {
        return (
            <div className="box-voip__rows d-flex justify-end">
                <span className="v2-btn-default --transparent" onClick={onClose}>
                    {t('addons:cancel')}
                </span>
                <ButtonSave
                    ref={refButtonSave}
                    wrapClass="v2-btn-main"
                    onSave={_handleSave}
                    title={t(`addons:${textSave}`)}
                />
            </div>
        );
    };

    const _handleSelectPhoneNumber = (phone) => {
        if (isRequestNew) {
            dispatchState((prev) => ({ ...prev, isRequestNew: false, textSave: 'save' }));
        }
        if (isEditing && !refCheckBoxUnderstand.current.checked) {
            refButtonSave.current.setDisable(true);
        }
        refPhoneSelect.current = phone;
    };

    const _open = () => {
        if (!refFirstTime.current) {
            Promise.all([
                clientQuery(ADDONS_SMS_COUNTRIES, { method: 'GET' }),
                clientQuery(ADDONS_SMS_DETAIL, { method: 'GET' })
            ]).then(([resCountry, resSMS]) => {
                dispatchState({
                    phoneCountries: resCountry?.data,
                    smsAccount: resSMS?.data?.account,
                    isLoading: false
                });
            });
        }
    };

    const _handleSave = (isPress = false) => {
        handleSetLoadingButton(true);
        let data = null;
        switch (tabActive) {
            case tabNewNumber:
                handleSaveNewNumber(isPress);
                break;
            case tabExternal:
                data = refFormExternalNumber.current._getValue();
                handleVerifyNumber(data);
                break;
            case tabExistSMSNumber:
                handleUseSmsNumber();
                break;
            default:
                data = {};
                onClose();
        }

        return {
            tabActive,
            ...data
        };
    };

    const handleSaveNewNumber = (isPress) => {
        if (isRequestNew || isPress) {
            refPhoneSelect.current = null;
            handleSetLoadingPrefix(true);
            handleSearchVOIPNumber(refFormNewNumber.current._getValue());
        } else handleBuyNewNumber({ type, number: rawPhone(refPhoneSelect.current) });
    };

    const handleSearchVOIPNumber = (data) => {
        const {
            country: { country_code },
            number: prefix
        } = data;
        const _handleSuccess = (res) => {
            handleSetLoadingButton(false);
            handleSetLoadingPrefix(false);
            dispatchState((prev) => ({
                ...prev,
                phones: res.data,
                isShowPhones: true,
                isRequestNew: true,
                textSave: TEXT_SAVE_VOIP_ADDONS[tabActive]
            }));
        };

        const _handleSearchFail = (err) => {
            dispatchState((prev) => ({
                ...prev,
                phones: [],
                isShowPhones: true,
                isRequestNew: true,
                textSave: TEXT_SAVE_VOIP_ADDONS[tabActive]
            }));
            _handleFail(err);
        };

        if (!/^\d*$/.test(prefix)) {
            _handleSearchFail({ message: t('addons:error_prefix', { prefix }) });
            return;
        }

        clientQuery(
            GET_LIST_SEARCH_PHONE,
            { method: 'GET', data: { country: country_code, prefix } },
            _handleSuccess,
            _handleSearchFail
        );
    };

    const handleBuyNewNumber = (data) => {
        clientQuery(ACTION_PHONE_NUMBER, { method: 'POST', toFormData: false, data }, _handleBuySuccess, _handleFail);
    };

    const handleVerifyNumber = (data) => {
        const {
            number,
            country: { sms_code }
        } = data;
        const newNumber = sms_code + number;
        if (!validatePhoneNumberVoip(newNumber)) {
            _handleFail({ message: t('addons:error_phone_number', { newNumber }) });
            return;
        }
        onShowVerifyNumber({ ...data, number: newNumber, type });
        handleSetLoadingButton(false);
    };

    const handleUseSmsNumber = () => {
        clientQuery(
            MIGRATE_SMS_NUMBER_VOIP,
            { method: 'POST', toFormData: false, data: { type } },
            _handleBuySuccess,
            _handleFail
        );
    };

    const _handleBuySuccess = (res) => {
        onUpdateListNumber(res.data);
        handleSetLoadingButton(false);
        onClose();
    };

    const _handleFail = (err) => {
        handleSetLoadingButton(false);
        handleSetLoadingPrefix(false);
        refAlert.current.showStatusBar({ id: 'add_number', message: err.message, type: LIST_STATUS.ERROR });
    };

    if (isLoading) return <VOIPAddonsLoadingAddNumber />;

    return (
        <div className="manager-phone-number">
            <div className="wrap-manager">
                <div className="box-voip bg-white">
                    <div className="box-voip__rows py-4">
                        <h3 className="fs-18">{t(`addons:add_new_${keyword}_number`)}</h3>
                    </div>
                    <AlertCustomer ref={refAlert} />
                    <div className={`box-voip__rows fs-14 p-0`}>
                        {_renderTabs()}
                        <div className="tab-content has-form elm-parent">{_renderContent()}</div>
                    </div>
                    {_renderFooterModal()}
                </div>
            </div>
        </div>
    );
};
