export const CALENDAR_BROADCAST_ACTIONS = {
    CALENDAR_SEND_ACTION: 'CALENDAR_SEND_ACTION',
    CALENDAR_GET_TOTAL_BROADCAST_REQUEST: 'CALENDAR_GET_TOTAL_BROADCAST_REQUEST',
    CALENDAR_GET_TOTAL_BROADCAST_SUCCESS: 'CALENDAR_GET_TOTAL_BROADCAST_SUCCESS',
    CALENDAR_SEND_BROADCAST: 'CALENDAR_SEND_BROADCAST'
};

export const calendarSendAction = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: CALENDAR_BROADCAST_ACTIONS.CALENDAR_SEND_ACTION,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getTotalBroadcast = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: CALENDAR_BROADCAST_ACTIONS.CALENDAR_GET_TOTAL_BROADCAST_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const calendarSendBroadcast = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: CALENDAR_BROADCAST_ACTIONS.CALENDAR_SEND_BROADCAST,
        params,
        actionSuccess,
        actionFailed
    };
};
