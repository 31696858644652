/* eslint-disable no-unused-vars */
import React, { useReducer, Suspense, Fragment } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addNewBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { reducer } from 'app/const/Reducer';
import ReactModal from 'react-modal';
import EstimateService from 'app/modules/estimate/service';
import loadable from '@loadable/component';
import { useEnterKeydownClick } from 'common/hooks';
import InvoiceImages from '../jobdetail/tabs/invoice/InvoiceImages';
import { TYPES_PHOTO } from '../jobdetail/const/Images';
import InsertImageInvoice from '../invoice/InsertImages';

const EstimateItem = loadable(() => import('../jobdetail/tabs/estimate/EstimateItem'));
const Header = loadable(() => import('app/modules/jobdetail/tabs/invoice/header'));
const CustomerInfo = loadable(() => import('app/modules/jobdetail/tabs/invoice/CustomerInfo'));
const Summary = loadable(() => import('app/modules/jobdetail/tabs/invoice/summary'));
const Terms = loadable(() => import('app/modules/jobdetail/tabs/invoice/terms'));
const Notes = loadable(() => import('app/modules/jobdetail/tabs/invoice/notes'));
const InvoiceSignature = loadable(() => import('app/modules/jobdetail/tabs/invoice/signature'));
const InvoiceHistory = loadable(() => import('app/modules/jobdetail/tabs/invoice/history'));
const InvoiceDetailContextProvider = loadable(() => import('app/modules/jobdetail/contexts/InvoiceDetailContext'));
const HeaderModal = loadable(() => import('app/modules/invoice/HeaderModal'));
const ServiceInvoice = loadable(() => import('app/modules/jobdetail/tabs/invoice/service'));
const Insert = loadable(() => import('app/modules/invoice/Insert'));

export default function EstimateDetail() {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        estimateData: null
    });
    const dispatch = useDispatch();

    const { isVisible: finalIsVisible, estimateData } = state;
    const { onUpdateSuccess, onTriggerUpdated } = estimateData || {};

    function _handleUpdateEstimate() {}

    useEnterKeydownClick(finalIsVisible);

    function _handleOpenEstimate(estimateData) {
        if (!estimateData) {
            return false;
        }

        dispatchState({
            isVisible: true,
            estimateData
        });
    }

    function _handleColEstimate(invoiceDetail) {
        dispatch(
            addNewBookMarkAction({
                type: 'estimate_detail',
                id: moment().format('x'),
                data: {
                    id: invoiceDetail.id,
                    name: `Estimate #${invoiceDetail.number}`,
                    customer: invoiceDetail.customer,
                    oldData: estimateData
                }
            })
        );
        _handleClose();
    }

    const _closeEstimate = () => {
        dispatchState({
            isVisible: false,
            invoiceData: null
        });
    };

    function _handleClose(e) {
        e && e.stopPropagation();

        _closeEstimate();
        dispatch(actionOpenEstimate(null));
    }

    function _handleUpdateSuccess() {
        onUpdateSuccess && onUpdateSuccess();
    }

    function _renderEsimateDetail() {
        if (!finalIsVisible) {
            return false;
        }
        return (
            <ReactModal
                isOpen
                onRequestClose={_handleClose}
                ariaHideApp={false}
                shouldCloseOnEsc
                style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
                className="modal container-modal c-job-details open"
            >
                <Suspense fallback>
                    <div className="modal__overlay bg-fixed" />
                    <div className="modal__container --preview-r has-existing-notes">
                        <InvoiceDetailContextProvider
                            isInvoice={false}
                            invoiceData={estimateData}
                            isActive
                            onUpdate={_handleUpdateEstimate}
                            onEditSuccess={_handleUpdateSuccess}
                            onTriggerUpdated={onTriggerUpdated}
                            isOpenWithJob={false}
                        >
                            <HeaderModal onCol={_handleColEstimate} onClose={_handleClose} />
                            <div className="body-modal scrolls">
                                <div
                                    className={'tab-conts form-tabpane-details tab-content-active form-add-estimate'}
                                    id={'tab_inoive_detail_setting'}
                                >
                                    <div className="container-column form-has-content">
                                        <Header
                                            isEstimateCustomer={estimateData.isEstimateCustomer}
                                            isInvoice={false}
                                        />
                                        <CustomerInfo isInvoice={false} />
                                        <EstimateItem />
                                        <Summary isInvoice={false} />
                                        <div className="content-elm rows note-details">
                                            <div className="dashboard-wrapper --main">
                                                <div className="content-top-notes">
                                                    <Terms isInvoice={false} />
                                                    <Notes isInvoice={false} />
                                                    <InvoiceImages parentType={TYPES_PHOTO.ESTIMATE} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="content-elm rows box-signature is-signed">
                                            <InvoiceSignature isInvoice={false} />
                                        </div>
                                        <InvoiceHistory isInvoice={false} />
                                    </div>
                                </div>
                            </div>
                            <Insert />
                            <InsertImageInvoice />
                            <ServiceInvoice isInvoice={false} parentId={estimateData?.id} />
                        </InvoiceDetailContextProvider>
                    </div>
                </Suspense>
            </ReactModal>
        );
    }

    return (
        <Fragment>
            {_renderEsimateDetail()}
            <EstimateService onOpenEstimateDetail={_handleOpenEstimate} onCloseEstimate={_closeEstimate} />
        </Fragment>
    );
}
