import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-loading boxs">
            {/* header */}
            <div className="boxs__header">
                <div className="has-form__label fs-13 mb-2">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="desc">
                    <div className="loading --animation --line --onefifth" />
                </div>
                <div className="desc mt-1">
                    <div className="loading --animation --line --onefourth" />
                </div>
                <div className="desc mt-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="v2-btn-default loading btn-x-sm --grey">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
            {/* content has field */}
            <div className="boxs__content has-form">
                <div className="rows">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="rows__view">
                        <div className="v2-btn-default loading v2-dropdown --grey">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading btn-sm --grey">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="loading --animation --line --threefourth mt-1" />
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="v2-btn-default loading w-100 --grey">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="loading --animation --line --half mt-1" />
                </div>
                <div className="rows">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="rows__view">
                        <div className="v2-btn-default loading v2-dropdown --grey">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading btn-sm --grey">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="loading --animation --line --threefourth mt-1" />
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="v2-btn-default loading w-100 --grey">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="loading --animation --line --half mt-1" />
                </div>
                <div className="rows">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="rows__view">
                        <div className="v2-btn-default loading v2-dropdown --grey">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading btn-sm --grey">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="loading --animation --line --threefourth mt-1" />
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="v2-btn-default loading w-100 --grey">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="loading --animation --line --half mt-1" />
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default loading --grey w-100 --grey">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="loading --animation --line --threefourth mt-1" />
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="field-input --form no-effect border-border-color-grey">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            {/* content switch toggle */}
            <div className="boxs__content">
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-lg">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="name flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="switch btn-sm d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
