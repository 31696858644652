import { convertFromHTML, convertToHTML } from 'draft-convert';
import { CompositeDecorator } from 'draft-js';
import ImageDecorator from '../components/ImageDecorator';
import { LINK_REGEX } from '../const';
import { DecoratorLink } from '../const/decoratorComponent';

/**
 * Get the current selection, get the current block, get the start and end offset of the selection, and
 * return the text between the start and end offset
 * @param editorState - The current editor state
 * @returns The selected text.
 */
export const handleGetSelectedText = (editorState) => {
    // Get block for current selection
    const selection = editorState.getSelection();
    const anchorKey = selection.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);

    //Then based on the docs for SelectionState
    const start = selection.getStartOffset();
    const end = selection.getEndOffset();
    return currentBlock.getText().slice(start, end);
};

export const customBlockRender = ({
    contentBlock,
    itemIdPresign,
    typePresign,
    onUploadSuccess = () => {},
    onResize = () => {}
}) => {
    const type = contentBlock.getType();
    if (type === 'atomic') {
        return {
            component: ImageDecorator,
            editable: false,
            props: {
                typePresign,
                itemIdPresign,
                onUploadSuccess,
                onResize,
                type: contentBlock?.getData()?._root?.entries?.[0]?.[1]
            }
        };
    }
    return null;
};

export const compositeDecorator = (toolbarConfig = {}) => {
    const hasLink = checkProperties(toolbarConfig, 'link');
    const result = [];
    if (hasLink) result.push({ strategy: linkStrategy, component: DecoratorLink });
    return new CompositeDecorator(result);
};

export const checkProperties = (toolbarConfig = {}, keyCheck = 'link') => {
    let result = false;
    const isArray = Array.isArray(keyCheck);
    for (const key in toolbarConfig) {
        if (Object.hasOwnProperty.call(toolbarConfig, key)) {
            const element = toolbarConfig[key];
            if (Array.isArray(element)) {
                element.forEach((itemElement) => {
                    if (Array.isArray(itemElement.children)) {
                        itemElement.children.forEach((itemChildren) => {
                            if (isArray ? keyCheck.includes(itemChildren.type) : itemChildren.type === keyCheck)
                                result = true;
                        });
                    }
                });
                if (result) break;
            }
        }
    }

    return result;
};

const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
};

const linkStrategy = (contentBlock, callback) => {
    findWithRegex(LINK_REGEX, contentBlock, callback);
};

export const handleGetActiveInlineStyle = (editorState, style) => {
    if (typeof style !== 'string') {
        throw new Error('Style must be string and capitalized');
    }
    return editorState.getCurrentInlineStyle().has(style);
};

export const handleConvertFromHTML = (html) => {
    return convertFromHTML({
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'img') {
                const data = { src: node.src };
                if (node.dataset.width) data['width'] = node.dataset.width;

                return createEntity('IMAGE', 'IMMUTABLE', data);
            }
            if (nodeName === 'a') {
                return createEntity('LINK', 'MUTABLE', { url: node.href, text: node.textContent }, false);
            }
        },
        htmlToBlock: (nodeName, node) => {
            if (nodeName === 'hr' || nodeName === 'img') {
                return {
                    type: 'atomic',
                    data: { type: nodeName === 'hr' ? 'HORIZONTAL_RULE' : 'IMAGE', src: node?.html || '' }
                };
            }
        }
    })(html);
};

export const handleConvertToHTML = (editorState) => {
    return convertToHTML({
        blockToHTML: (block) => {
            if (block.type === 'unstyled') {
                if (block.text === ' ' || block.text === '') return <br />;
                return <p />;
            }
            if (block.type === 'atomic') {
                const entityKey = editorState.getBlockForKey(block.key).getEntityAt(0);
                if (!entityKey) return <hr />;
                const data = editorState.getEntity(entityKey).getData();
                const width = data.width || 'fit-content';
                if (data.type === 'HORIZONTAL_RULE') return <hr />;
                return <img src={data.src} width={width} data-width={width} />;
            }
        },
        entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
                const { url, text, isBlank } = entity.data;
                return (
                    <a href={url} target={isBlank ? '_blank' : '_self'} rel="noreferrer">
                        {text}
                    </a>
                );
            }
            return originalText;
        }
    })(editorState);
};

/**
 * The function checks if a given text is a valid URL starting with "ftp", "http", or "https".
 * @param text - The text parameter is a string that represents a URL or a link that needs to be checked for validity. The
 * function uses a regular expression to check if the link starts with "ftp", "http", or "https" and is followed by "://"
 * and any non-space characters until the end of the
 */
export const checkLink = (text) => /^(ftp|http|https):\/\/[^ "]+$/.test(text);

export const isCtrlKeyCommand = () => {
    if (navigator.platform.indexOf('Mac') > -1) {
        return event.metaKey === true && event.key === 'Enter';
    } else {
        return event.ctrlKey === true && event.key === 'Enter';
    }
};
