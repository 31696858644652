import { ID_CONTENT_PREVIEW, ID_TITLE_PREVIEW } from 'app/const/Settings';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useSettingTemplate } from '../SettingTemplateContext';

const PreviewContent = () => {
    const { typePreviewing, onPreviewTemplate } = useSettingTemplate();
    const company = useSelector(({ auth }) => auth.user.company);
    const { name = '', first_name = '', last_name = '' } = useSelector(({ auth }) => auth?.user?.profile || {});
    const isSMSPreviewing = typePreviewing === 'sms';

    const avatarName = () => {
        let finalName = first_name.substring(0, 1) || '';
        if (last_name.substring(0, 1)) finalName = finalName + last_name.substring(0, 1);
        return finalName;
    };

    if (!typePreviewing) return null;
    return (
        <div className="wrap-container__preview flex-column wrap-preview-email is-open">
            <div className="wrap-header flex-betweenitems">
                <h3 className="flex-1 fs-17 mr-2">Live Preview</h3>
                <div className="v2-btn-default --icon-lg --transparent" onClick={() => onPreviewTemplate('')}>
                    <IconClose />
                </div>
            </div>
            <div className="wrap-body">
                {!isSMSPreviewing ? <div id={ID_TITLE_PREVIEW} className="is-subject" /> : null}
                <div
                    className={classNames('wrap-body__inner container-content-email', {
                        'is-sms': isSMSPreviewing,
                        'is-email': !isSMSPreviewing
                    })}
                >
                    <div className="wrap-content-email">
                        {!isSMSPreviewing ? (
                            <div className="logo">
                                <img src={company.logo} alt="" width={150} height={100} />
                            </div>
                        ) : null}
                        {isSMSPreviewing ? (
                            <div className="sms-message flextop">
                                <div className="sms-message__info flex-1 mr-1">
                                    <div id={ID_CONTENT_PREVIEW} className="message-desc" />
                                    <div className="text-right fs-11">
                                        <span className="mr-1">{name}</span>
                                        <span className="granite-gray">
                                            {moment().format('DD MMM yy')}, {moment().format('HH:MM A')}
                                        </span>
                                    </div>
                                </div>
                                <div className="avt">{avatarName()}</div>
                            </div>
                        ) : (
                            <div>
                                <div>
                                    <div id={ID_CONTENT_PREVIEW} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PreviewContent;
