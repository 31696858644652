export default function IconViewPdf({ isWhite = false }) {
    const fill = isWhite ? '#FFFFFF' : 'none';
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5V9.62132C18.5 8.26306 17.9604 6.96043 17 6C16.0396 5.03957 14.7369 4.5 13.3787 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V18.5C4.5 19.6046 5.39543 20.5 6.5 20.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5 11.5V10.5C18.5 9.39543 17.6046 8.5 16.5 8.5C15.3954 8.5 14.5 7.60457 14.5 6.5C14.5 5.39543 13.6046 4.5 12.5 4.5H11.4923"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 10.5H18.5C19.6046 10.5 20.5 11.3954 20.5 12.5V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H4.5C3.39543 18.5 2.5 17.6046 2.5 16.5V12.5C2.5 11.3954 3.39543 10.5 4.5 10.5Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 10.5H18.5C19.6046 10.5 20.5 11.3954 20.5 12.5V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H4.5C3.39543 18.5 2.5 17.6046 2.5 16.5V12.5C2.5 11.3954 3.39543 10.5 4.5 10.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.61646 17H6.23293V15.1406H7.34657C8.53229 15.1406 9.06674 14.4197 9.06674 13.5199C9.06674 12.62 8.53229 11.9091 7.33663 11.9091H5.61646V17ZM6.23293 14.5937V12.456H7.31674C8.14451 12.456 8.46021 12.9084 8.46021 13.5199C8.46021 14.1314 8.14451 14.5937 7.32668 14.5937H6.23293Z"
                fill="#4D5054"
            />
            <path
                d="M11.6308 17C13.182 17 14.057 16.0355 14.057 14.4446C14.057 12.8636 13.182 11.9091 11.7004 11.9091H10.0598V17H11.6308ZM10.6763 16.4531V12.456H11.6607C12.8538 12.456 13.4604 13.2116 13.4604 14.4446C13.4604 15.6875 12.8538 16.4531 11.5911 16.4531H10.6763Z"
                fill="#4D5054"
            />
            <path
                d="M15.0911 17H15.7075V14.723H17.9149V14.1761H15.7075V12.456H18.1436V11.9091H15.0911V17Z"
                fill="#4D5054"
            />
        </svg>
    );
}
