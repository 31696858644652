import IconTelephone from 'assets/icon/IconTelephone';
import { TYPE_SELECT_PHONE } from './Customers';
import IconSMS from 'assets/icon/IconSMS';
import IconCopy from 'assets/icon/IconCopy';

export const DEFAULT_ID_PHONE_MOBILE = '1';
export const DEFAULT_PHONE_MOBILE = { id: DEFAULT_ID_PHONE_MOBILE, name: 'Mobile', is_default: 1 };
export const PHONE_CHECK_VALID_TYPE = {
    VALID: 1,
    INVALID: 0,
    UNKNOWN: -1
};

export const LIST_ACTION_DROPDOWN_PHONE = [
    {
        id: TYPE_SELECT_PHONE.CALL,
        title: 'call',
        icon: <IconTelephone isNormal />,
        isCheckMobile: true
    },
    {
        id: TYPE_SELECT_PHONE.SMS,
        title: 'sms',
        icon: <IconSMS isOpportunity />,
        isCheckMobile: true,
        isCheckCusId: true
    },
    {
        id: TYPE_SELECT_PHONE.COPY,
        title: 'copy',
        icon: <IconCopy />
    }
];
