import { useRef } from 'react';
import Detail from './detail';
import List from './list';

const NotesTemplate = () => {
    const refList = useRef(null);
    const refDetail = useRef(null);
    const _getDetail = (item) => {
        refDetail.current._getDetail(item);
    };

    const _addNew = (type) => {
        refDetail.current._addNew(type);
    };

    const _addItemToList = (item) => {
        refList.current._addItemToList(item);
    };

    const _updateItemToList = (item) => {
        refList.current._updateItemToList(item);
    };

    const _showStatusBar = (id, message, status) => {
        refDetail.current?._showStatusBar(id, message, status);
    };

    return (
        <>
            <List ref={refList} onGetDetail={_getDetail} onAddNew={_addNew} onShowStatusBar={_showStatusBar} />
            <Detail ref={refDetail} onAddNew={_addItemToList} onUpdate={_updateItemToList} />
        </>
    );
};
export default NotesTemplate;
