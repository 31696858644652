import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getColumnsPayments = (actionsStatus, quickbooks) => {
    const HEADER_PAYMENT = {
        invoice_number: {
            title: 'payment_for',
            style: 'col --info-md --flex-box',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        methodName: {
            title: 'method',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col --info-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        amount: {
            title: 'amount',
            style: 'col --info-md --flex-box',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_status: {
            title: 'status',
            style: 'col --md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col col-xs --qb'
        },
        edit: { title: '', style: 'col col-xs' }
    };

    const COLUMN_PAYMENT = [
        {
            id: 'invoice_number',
            title: 'status',
            status: true
        },
        {
            id: 'methodName',
            title: 'method',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'amount',
            title: 'amount',
            status: true
        },
        {
            id: 'invoice_status',
            title: 'status',
            status: true
        },
        {
            id: 'qb_sync',
            title: 'qb_sync',
            status: true
        },
        { id: 'edit', title: '', status: true }
    ];

    const COLUMN_CONTENT_PAYMENT = {
        invoice_number: {
            title: 'invoice_number',
            style: 'col --info-md --flex-box',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_PAYMENT_FOR
        },
        methodName: {
            title: 'method',
            style: 'col --info text-capitalize'
        },
        date: {
            title: 'date',
            style: 'col --info-md'
        },
        amount: {
            title: 'amount',
            style: 'col --info-md --flex-box',
            type: LIST_TABLE_ITEM_TYPE.TOTAL_CREDIT
        },
        invoice_status: {
            title: 'status',
            style: 'col --md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col col-xs --qb',
            type: LIST_TABLE_ITEM_TYPE.QB_SYNC
        },
        edit: { title: '', style: 'col col-xs', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_EDIT, isLink: true }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_PAYMENT });
    const newContent = { ...COLUMN_CONTENT_PAYMENT };

    COLUMN_PAYMENT.forEach((col) => {
        if (col.id === LIST_TABLE_ITEM_TYPE.QB_SYNC_CREDIT) {
            col.status = !!quickbooks;
        }
    });

    return {
        header: newHeader,
        columns: COLUMN_PAYMENT,
        contentConfig: newContent
    };
};
