import { GET_LIST_EVENT_ROUTE } from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListEventRoute({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_EVENT_ROUTE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
