import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Input from 'app/components/input/Input';
import { reducer } from 'app/const/Reducer';
import { LIST_CODE_BY_COUNTRY } from 'app/const/Settings';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { CODE, INDUSTRY, LIST_INDUSTRY, DEFAULT_INDUSTRY, COUNTRY_CODE, INDUSTRIES } from './const';
import { formatPhoneTrial } from 'common/utils/PhoneUtils';

const TrialStepOne = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    handleFocus = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {},
    handleSetValue = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);

    const [state, dispatchState] = useReducer(reducer, {
        code: infoTrial?.country_code || '',
        industry: infoTrial?.industry || INDUSTRIES.CLEANING.value,
        phone: formatPhoneTrial(infoTrial?.company_phone || '')
    });

    const { code: finalCode, industry: finalIndustry, phone: finalPhone } = state;

    const refDropdownCodeByCountry = useRef(null);
    const refDropdownIndustry = useRef(null);

    const _onChangePhone = (event) => {
        const finalValue = event.target.value.replace(/\D+/g, '');
        event.target.value = finalValue;

        dispatchState({ phone: formatPhoneTrial(finalValue) });

        onChangeInput(event);
    };

    const _renderListCodeByCountry = () => {
        return (
            <ul>
                {LIST_CODE_BY_COUNTRY.map(({ dial_code, code }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: COUNTRY_CODE, value: code });
                        dispatchState((prev) => ({ ...prev, [CODE]: code }));
                        refDropdownCodeByCountry.current?._close();
                    };

                    return (
                        <li
                            key={code}
                            className={classNames('items', { active: code === finalCode })}
                            onClick={onClickItem}
                        >
                            {dial_code} ({code.toUpperCase()})
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownCodeByCountry = () => {
        const countrySelected = LIST_CODE_BY_COUNTRY.find((country) => country.code === finalCode) || {};

        return (
            <Fragment>
                <div className="txt-ellipsis">
                    {countrySelected.dial_code} ({countrySelected.code ? countrySelected.code : ''})
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _renderButtonDropdownIndustry = () => {
        return (
            <Fragment>
                <p className="txt-ellipsis">{finalIndustry || t('choose_your_industry')}</p>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _renderListIndustry = () => {
        return (
            <div className="drop-container">
                {Object.keys(LIST_INDUSTRY).map((industry) => {
                    return (
                        <div key={industry} className="drop-items">
                            {industry !== DEFAULT_INDUSTRY ? (
                                <div className="flex-betweenitems drop-items__title">
                                    <span className="fw-600">{industry}</span>
                                </div>
                            ) : null}
                            <ul className="drop-items__content">
                                {LIST_INDUSTRY[industry].map(({ id, value }) => {
                                    const onClickItem = () => {
                                        handleSetValue({ key: INDUSTRY, value });
                                        dispatchState((prev) => ({ ...prev, [INDUSTRY]: value }));
                                        refDropdownIndustry.current?._close();
                                        handleFocus();
                                    };

                                    return (
                                        <li
                                            key={id}
                                            className={classNames('items --ticked', {
                                                active: finalIndustry === value
                                            })}
                                            onClick={onClickItem}
                                        >
                                            <IconCheck />
                                            <span className="txt-ellipsis">{value}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <Fragment>
            <div className="box-input">
                <Input
                    placeholder={t('auth:first_name')}
                    type="text"
                    name="first_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.first_name}
                    error={error?.first_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="box-input">
                <Input
                    placeholder={t('auth:last_name')}
                    type="text"
                    name="last_name"
                    classWrapper="user-box rows"
                    initValue={infoTrial.last_name}
                    error={error?.last_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <div className="rows d-flex">
                <div className="first-field">
                    <DropdownPopper
                        ref={refDropdownCodeByCountry}
                        wrapperClassName="v2-dropdown --area-code"
                        wrapperListClass="v2-dropdown__menu scrolls"
                        buttonClassName="dropbtn items"
                        isUseToggle
                        isCalculateWidth
                        customStyle={{ width: 'auto', maxWidth: 'auto' }}
                        customButton={_renderButtonDropdownCodeByCountry()}
                    >
                        {_renderListCodeByCountry()}
                    </DropdownPopper>
                </div>
                <div className="second-field flex-1">
                    <Input
                        placeholder="000-000-0000"
                        type="text"
                        name="company_phone"
                        classWrapper="user-box rows"
                        value={finalPhone}
                        error={error?.company_phone}
                        maxLength={10}
                        isShowIconError={false}
                        onFocus={handleFocus}
                        onChange={_onChangePhone}
                        handleKeypress={onKeyPress}
                    />
                </div>
            </div>
            <DropdownPopper
                ref={refDropdownIndustry}
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls"
                buttonClassName="dropbtn items"
                isUseToggle
                isCalculateWidth
                customButton={_renderButtonDropdownIndustry()}
                error={error?.industry}
                isShowIconError={false}
            >
                {_renderListIndustry()}
            </DropdownPopper>
        </Fragment>
    );
};

export default TrialStepOne;
