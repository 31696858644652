import IconUnOrderList from 'assets/icon/IconUnOrderList';
import classNames from 'classnames';
import React from 'react';
import { handleGetActiveInlineStyle } from '../utils';

const CustomUlOption = ({ editorState, onInsert = () => {} }) => {
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'unordered-list-item')
            })}
            onMouseDown={(e) => onInsert(e, 'unordered-list-item')}
        >
            <IconUnOrderList />
            <span className="tooltiptext top">Unordered list</span>
        </span>
    );
};

export default CustomUlOption;
