import React from 'react';
import TaskItem from 'app/modules/calendar/components/sidebar/components/tasks/TaskItem';
import GridLoading from 'app/components/grid/components/GridLoading';
import { useTranslation } from 'react-i18next';

const TaskList = ({
    tasks = [],
    haveSelected = false,
    textEmpty = '',
    isLoading = false,
    isLoadingLoadMore = false
}) => {
    const { t } = useTranslation(['calendar']);
    function _renderTaskList() {
        if (isLoading) {
            return <GridLoading />;
        }

        if (!tasks.length) {
            return (
                <div className="btn-loadmore">
                    <p className="btn-loadmore__label">
                        {haveSelected ? textEmpty : t('calendar:select_a_user_to_display_tasks')}
                    </p>
                </div>
            );
        }

        return tasks.map((item) => {
            return <TaskItem taskData={item} key={item.id} />;
        });
    }

    return (
        <div className="contents tasks-list">
            {_renderTaskList()}
            {isLoadingLoadMore && <GridLoading />}
        </div>
    );
};

export default TaskList;
