import React from 'react';

const Loading = () => {

    return (
        <div className="history__content">
            <div className="timeline-history main-timeline">
                <div className="timeline">
                    <div className="timeline__details w-100">
                        <div className="line" />
                        <div className="circle" />
                        <div className="detail">
                            <div className="info">
                                <div className="name loading --animation --line --onefourth" />
                                <div className="datetime loading --animation --line --twothird mt-0" />
                            </div>
                            <div className="conts">
                                <div className="loading --animation --line --onefourth" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="timeline --last">
                    <div className="timeline__details w-100">
                        <div className="line" />
                        <div className="circle" />
                        <div className="detail">
                            <div className="info">
                                <div className="name loading --animation --line --onefourth" />
                                <div className="datetime loading --animation --line --twothird mt-0" />
                            </div>
                            <div className="conts d-flex align-center">
                                <div className="loading --animation --line --fourth mr-2" />
                                <span className="loading --animation --twothird --line mt-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Loading