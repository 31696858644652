import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { JOB_CUSTOM } from 'app/const/Job';
import { CUSTOM_FIELDS_TYPES } from 'app/modules/customfields/constant';
import { getListJobStatus } from 'common/utils/JobStatusUtils';
import { FILTERS_LIST } from '../../constants';
import { COLUMNS_OPTIONS } from '../../constants/columns';
import { ESTIMATE_TABLE_DATA, JOB_TABLE_DATA, OPPORTUNITIES_TABLE_DATA } from '../../constants/table';
import { OBJECT_TYPES } from '../../constants/types';

const DEFAULT_CLASS_COLUMN = 'col col-custom-field';
export const DEFAULT_SORT_VALUES = { actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET };
export const COLUMNS_GROUP_CALLS = ['latestOutgoingCallDate', 'latestIncomingCallDate', 'countCall'];
export const COLUMNS_GROUP_SMS = ['latestSmsMessage', 'latestSmsDate', 'countSms'];
export const DEFAULT_COLUMN_NAME = [{ field_id: 'info', type_id: 'CUSTOMER' }];
export const DEFAULT_COLUMNS = [DEFAULT_COLUMN_NAME];

// Convert from customer_name => customerName
export const convertColumnsName = (value) => value.replace(/_(.)/g, (_, nextLetter) => nextLetter.toUpperCase());
// Convert from customerName => customer_name
export const revertColumnsName = (value) => value.replace(/([A-Z])/g, '_$1').toLowerCase();
export const getColumnsSmartView = (columns) => {
    return [...COLUMNS_OPTIONS].map((item) => {
        item['options'] = [
            ...item.options.map((option) => ({
                ...option,
                checked: item.value === 'info' || columns.some((col) => col.field_id === option.value)
            }))
        ];
        return item;
    });
};

const convertSortedArrayToObject = (sorted) => {
    const convertedSorted = {};
    sorted.forEach((item) => {
        convertedSorted[item.fieldId] = { direction: item.direction };
    });
    return convertedSorted;
};
export const getGridColumns = (columns, { format = '', timezone = '', sort = [] }) => {
    const newContent = {};
    const newHeaders = {};
    const newColumns = [];
    const convertedSorted = convertSortedArrayToObject(sort);

    const timeConfig = { format, timezone };

    const HEADER_CUSTOMER = {
        info: {
            title: 'name',
            style: 'col col-xl',
            keyGetDirection: 'name',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            keyGetDirection: 'status',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        phones: {
            title: 'phone',
            style: 'col col-phone',
            keyGetDirection: 'phone_number',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        email: {
            title: 'email',
            style: 'col col-email',
            keyGetDirection: 'email',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        company: {
            title: 'company',
            style: 'col col-lg',
            keyGetDirection: 'company',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        source: {
            title: 'source',
            style: 'col col-md',
            keyGetDirection: 'source',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        createdDate: {
            title: 'created_date',
            style: 'col col-md',
            keyGetDirection: 'created_at',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        topNote: {
            title: 'top_note',
            style: 'col col-note word-break',
            keyGetDirection: 'top_note',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        serviceAddress: {
            title: 'service_address',
            style: 'col col-xl',
            keyGetDirection: 'service_address',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        billingAddress: {
            title: 'billing_address',
            style: 'col col-xl',
            keyGetDirection: 'billing_address',
            objectType: OBJECT_TYPES.CUSTOMER,
            ...DEFAULT_SORT_VALUES
        },
        customerTag: {
            title: 'customer_tag',
            style: 'col --tags',
            objectType: OBJECT_TYPES.CUSTOMER
        },
        locationTag: {
            title: 'location_tag',
            style: 'col --tags',
            objectType: OBJECT_TYPES.CUSTOMER
        },

        latestOutgoingCallDate: {
            title: 'latest_outgoing_call_date',
            style: 'col col-xl',
            keyGetDirection: 'latest_outgoing_call_date',
            objectType: OBJECT_TYPES.CALL,
            ...DEFAULT_SORT_VALUES
        },
        latestIncomingCallDate: {
            title: 'latest_incoming_call_date',
            style: 'col col-xl',
            keyGetDirection: 'latest_incoming_call_date',
            objectType: OBJECT_TYPES.CALL,
            ...DEFAULT_SORT_VALUES
        },
        countCall: {
            title: '#_count_call',
            style: 'col col-md',
            objectType: OBJECT_TYPES.CALL,
            keyGetDirection: 'count_call',
            ...DEFAULT_SORT_VALUES
        },
        countOutgoingCall: {
            title: '#_outgoing_call',
            style: 'col col-md',
            keyGetDirection: 'count_outgoing_call',
            objectType: OBJECT_TYPES.CALL,
            ...DEFAULT_SORT_VALUES
        },
        countIncomingCall: {
            title: '#_incoming_call',
            style: 'col col-md',
            keyGetDirection: 'count_incoming_call',
            objectType: OBJECT_TYPES.CALL,
            ...DEFAULT_SORT_VALUES
        },
        latestSmsMessage: {
            title: 'latest_sms',
            style: 'col col-xl',
            keyGetDirection: 'latest_sms_message',
            objectType: OBJECT_TYPES.SMS,
            ...DEFAULT_SORT_VALUES
        },
        latestSmsDate: {
            title: 'latest_sms_date',
            style: 'col col-xl',
            keyGetDirection: 'latest_sms_date',
            objectType: OBJECT_TYPES.SMS,
            ...DEFAULT_SORT_VALUES
        },
        countSms: {
            title: '#_sms_messages_title',
            style: 'col col-lg',
            keyGetDirection: 'count_sms',
            objectType: OBJECT_TYPES.SMS,
            ...DEFAULT_SORT_VALUES
        },
        nextTask: {
            title: 'next_task',
            style: 'col col-lg',
            keyGetDirection: 'next_task_description',
            objectType: OBJECT_TYPES.TASK,
            ...DEFAULT_SORT_VALUES
        },
        nextTaskDate: {
            title: 'next_task_date',
            style: 'col col-md',
            keyGetDirection: 'next_task_date',
            objectType: OBJECT_TYPES.TASK,
            ...DEFAULT_SORT_VALUES
        },
        countOpenTask: {
            title: '#_open_task',
            style: 'col col-md',
            keyGetDirection: 'count_open_task',
            objectType: OBJECT_TYPES.TASK,
            ...DEFAULT_SORT_VALUES
        },
        // Opportunities columns
        ...OPPORTUNITIES_TABLE_DATA.HEADER,
        // Jobs columns
        ...JOB_TABLE_DATA.HEADER,
        // Estimates columns
        ...ESTIMATE_TABLE_DATA.HEADER
    };
    const CUSTOMER_COLUMNS = [
        { id: 'info', title: 'name', status: true },
        { id: 'status', title: 'status', status: true },
        { id: 'phones', title: 'phones', status: true },
        { id: 'email', title: 'email', status: true },
        { id: 'company', title: 'company', status: true },
        { id: 'source', title: 'source', status: true },
        { id: 'createdDate', title: 'status', status: true },
        { id: 'topNote', title: 'top_note', status: true },
        { id: 'serviceAddress', title: 'service_address', status: true, keyGetValue: 'location' },
        { id: 'billingAddress', title: 'billing_address', status: true, keyGetValue: 'location' },
        { id: 'customerTag', title: 'customer_tag', status: true },
        { id: 'locationTag', title: 'location_tag', status: true },
        {
            id: 'latestOutgoingCallDate',
            title: 'latest_outgoing_call_date',
            status: true,
            keyGetValue: 'latestOutgoingCall'
        },
        {
            id: 'latestIncomingCallDate',
            title: 'latest_incoming_call_date',
            status: true,
            keyGetValue: 'latestIncomingCall'
        },
        { id: 'countCall', title: 'count_call', status: true },
        { id: 'countOutgoingCall', title: '#_outgoing_call', status: true },
        { id: 'countIncomingCall', title: '#_incoming_call', status: true },
        { id: 'latestSmsMessage', title: 'latest_sms', status: true, keyGetValue: 'latestSms' },
        { id: 'latestSmsDate', title: 'latest_sms_date', status: true, keyGetValue: 'latestSms' },
        { id: 'countSms', title: 'count_sms', status: true },
        { id: 'nextTask', title: 'next_task', keyGetValue: 'nextTask', status: true },
        { id: 'nextTaskDate', title: 'next_task_date', keyGetValue: 'nextTask', status: true },
        { id: 'countOpenTask', title: '#_open_task', status: true },
        // Opportunities columns
        ...OPPORTUNITIES_TABLE_DATA.COLUMNS,
        // Jobs columns
        ...JOB_TABLE_DATA.COLUMNS,
        // Estimates columns
        ...ESTIMATE_TABLE_DATA.COLUMNS
    ];
    const CUSTOMER_COLUMN_CONTENT = {
        info: { title: 'name', style: 'col col-xl', type: LIST_TABLE_ITEM_TYPE.NAME_CUSTOMER },
        status: { title: 'status', style: 'col col-sm d-flex', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS_SMART_VIEW },
        phones: { title: 'phones', style: 'col col-phone', type: LIST_TABLE_ITEM_TYPE.PHONE, isGetFirstPhone: true },
        email: { title: 'email', style: 'col col-email' },
        company: { title: 'company', style: 'col col-lg' },
        source: { title: 'source', style: 'col col-md', type: LIST_TABLE_ITEM_TYPE.SOURCE },
        createdDate: {
            title: 'created_date',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DATE_CREATED,
            ...timeConfig
        },
        topNote: { title: 'top_note', style: 'col col-note word-break', type: LIST_TABLE_ITEM_TYPE.PARSE_HTML },
        serviceAddress: {
            title: 'service_address',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.ADDRESS
        },
        billingAddress: {
            title: 'billing_address',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.ADDRESS,
            isBillingAddress: true
        },
        customerTag: {
            title: 'customer_tag',
            style: 'col --tags',
            keyGetValue: 'customerTag',
            type: LIST_TABLE_ITEM_TYPE.TAGS_DISPLAY
        },
        locationTag: {
            title: 'location_tag',
            style: 'col --tags',
            keyGetValue: 'locationTag',
            type: LIST_TABLE_ITEM_TYPE.TAGS_DISPLAY
        },
        latestOutgoingCallDate: {
            title: 'latest_outgoing_call_date',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.TIME_AGO,
            ...timeConfig,
            styleText: 'col-label'
        },
        latestIncomingCallDate: {
            title: 'latest_incoming_call_date',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.TIME_AGO,
            ...timeConfig,
            styleText: 'col-label'
        },
        countCall: { title: 'count_call', style: 'col col-md' },
        countOutgoingCall: { title: '#_outgoing_call', style: 'col col-md' },
        countIncomingCall: { title: '#_incoming_call', style: 'col col-md' },
        latestSmsMessage: { title: 'latest_sms', style: 'col col-xl', styleText: 'col-label', type: LIST_TABLE_ITEM_TYPE.SMS_MESSAGE },
        latestSmsDate: {
            title: 'latest_sms_date',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.TIME_AGO,
            ...timeConfig,
            styleText: 'col-label'
        },
        countSms: { title: 'count_sms', style: 'col col-lg' },
        nextTask: { title: 'next_task', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.NEXT_TASK },
        nextTaskDate: {
            title: 'next_task_date',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.NEXT_TASK_DATE,
            ...timeConfig,
            styleText: 'col-label'
        },
        countOpenTask: { title: '#_open_task', style: 'col col-md' },
        // Opportunities columns
        ...OPPORTUNITIES_TABLE_DATA.COLUMN_CONTENT,
        // Jobs columns
        ...JOB_TABLE_DATA.COLUMN_CONTENT,
        // Estimates columns
        ...ESTIMATE_TABLE_DATA.COLUMN_CONTENT
    };

    if (columns.length) {
        CUSTOMER_COLUMNS.forEach((col) => {
            const item = col.id;
            if (columns.some((el) => convertColumnsName(el.field_id) === item)) {
                newContent[item] = CUSTOMER_COLUMN_CONTENT[item];
                newColumns.push(col);
            }
        });
    }

    Object.keys(HEADER_CUSTOMER).forEach((key) => {
        if (columns.some((col) => convertColumnsName(col.field_id) === key)) {
            const currentColumn = HEADER_CUSTOMER[key];
            newHeaders[key] = currentColumn;
            if (HEADER_CUSTOMER[key]['actionType'] === COMMON.ORDER) {
                const keyDirection = currentColumn['keyGetDirection'] || convertColumnsName(key);
                newHeaders[key]['currentValue'] = convertedSorted[keyDirection]?.['direction'] || COMMON.NOT_SET;
            }
        }
    });
    return { header: newHeaders, columns: newColumns, contentConfig: newContent };
};

// TODO: Improve this function to better performance
export const searchFilters = (searchText) => {
    const normalizedSearchText = searchText.toLowerCase();
    const filteredList = FILTERS_LIST.filter((filter) =>
        filter.subcategories.some((subcategory) =>
            subcategory.fields.some((field) => field.label.toLowerCase().includes(normalizedSearchText))
        )
    ).map((filter) => ({
        ...filter,
        subcategories: filter.subcategories
            .map((subcategory) => ({
                ...subcategory,
                fields: subcategory.fields.filter((field) => field.label.toLowerCase().includes(normalizedSearchText))
            }))
            .filter((subcategory) => subcategory.fields.length > 0)
    }));

    return filteredList;
};

export const getColumnsCustomFields = (columns, customFields, { format = '', sort, timezone = '' }) => {
    const convertedSorted = convertSortedArrayToObject(sort);
    const result = { header: {}, columns: [], contentConfig: {} };

    columns.forEach((column) => {
        const field = customFields.find((item) => item.id === column.field_id);
        if (field) {
            let style = DEFAULT_CLASS_COLUMN;
            const {
                id: fieldId,
                label: title,
                type: fieldType,
                accepts_multiple_values: acceptsMultipleValues
            } = field;

            if (fieldType === CUSTOM_FIELDS_TYPES.DATE_TIME) style += ' col-xl';
            const defaultValue = { title, style };
            const key = fieldId;

            // Header settings for custom fields
            const columnHeader = { ...defaultValue, keyGetDirection: key, objectType: OBJECT_TYPES.CUSTOM_FIELD };
            const acceptSort = fieldType !== CUSTOM_FIELDS_TYPES.CHOICES ? true : !acceptsMultipleValues;
            if (acceptSort) {
                columnHeader['actionType'] = COMMON.ORDER;
                columnHeader['currentValue'] = convertedSorted[key]?.['direction'] || COMMON.NOT_SET;
            }
            result['header'][key] = columnHeader;

            // Columns settings for custom fields
            result['columns'].push({ id: fieldId, title, status: true });
            // Content settings for custom fields
            switch (fieldType) {
                case CUSTOM_FIELDS_TYPES.DATE:
                case CUSTOM_FIELDS_TYPES.DATE_TIME:
                    const colConfig = { ...defaultValue, type: LIST_TABLE_ITEM_TYPE.DATE_CREATED, isShowEmpty: true };
                    if (fieldType === CUSTOM_FIELDS_TYPES.DATE_TIME) colConfig['format'] = `${format} hh:mm A`;
                    if (fieldType === CUSTOM_FIELDS_TYPES.DATE) colConfig['format'] = format;
                    if (timezone) colConfig['timezone'] = timezone;
                    result['contentConfig'][key] = colConfig;
                    break;
                default:
                    result['contentConfig'][key] = defaultValue;
                    break;
            }
        }
    });
    return result;
};

export const getColumnsCustomJobStatus = (columns, { sort } = {}) => {
    const statuses = getListJobStatus({ filter: [JOB_CUSTOM], isInclude: true, isIncludeDeleted: true });
    const convertedSorted = convertSortedArrayToObject(sort);
    const result = { header: {}, columns: [], contentConfig: {} };

    columns.forEach((column) => {
        const status = statuses.find((item) => item.id === column.field_id);
        if (status) {
            const key = status.id;
            const title = `${status.name} ${status.label || ''} Jobs`;
            const defaultValue = { title, style: DEFAULT_CLASS_COLUMN };
            result['header'][key] = {
                ...defaultValue,
                currentValue: convertedSorted[key]?.['direction'] || COMMON.NOT_SET,
                actionType: COMMON.ORDER,
                keyGetDirection: key,
                objectType: OBJECT_TYPES.JOB
            };
            result['columns'].push({ id: status.id, title, status: true });
            result['contentConfig'][key] = { ...defaultValue, type: LIST_TABLE_ITEM_TYPE.COUNT };
        }
    });
    return result;
};

export const convertResponseSmartView = (data = []) => {
    try {
        const result = data.map((item) => {
            const result = { ...item };
            const customFields = [...(item?.customFieldValues || [])];
            const customJobStatuses = [...(item?.customJobStatusValues || [])];
            delete result.customFieldValues;
            delete result.customJobStatusValues;

            customJobStatuses.forEach((status) => {
                result[status.id] = status.count;
            });

            customFields.forEach((field) => {
                const customFieldId = field.customField.id;
                if (field.__typename === 'CustomFieldMultipleValues') {
                    result[customFieldId] = field.values.map((item) => item.choices).join(', ');
                } else {
                    switch (field.value.__typename) {
                        case 'CustomFieldTextValue':
                            result[customFieldId] = field.value.text;
                            break;
                        case 'CustomFieldNumberValue':
                            result[customFieldId] = field.value.number;
                            break;
                        case 'CustomFieldDateValue':
                            result[customFieldId] = field.value.date;
                            break;
                        case 'CustomFieldDateTimeValue':
                            result[customFieldId] = field.value.dateTime;
                            break;
                        case 'CustomFieldChoicesValue':
                            result[customFieldId] = field.value.choices;
                            break;
                        default:
                            break;
                    }
                }
            });
            return result;
        });

        return result;
    } catch (error) {
        console.error('SmartViewTable: dataConverted', error);
        return [];
    }
};
