import { PREFIX_TITLE } from 'app/const/App';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @function useSiteTitle Change title of page
 * @param {string} title
 * @param  {...any} deps
 */
export function useSiteTitle(title) {
    const { t } = useTranslation('pageTitle');
    useEffect(() => {
        document.title = `${PREFIX_TITLE} ${title ? `- ${t(title)}` : ''}`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);
}
