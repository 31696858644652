import { ADDONS_VOIP } from 'app/config/routes';
import { reducer } from 'app/const/Reducer';
import AddonsBoxHeader from 'app/modules/addons/components/AddonsBoxHeader';
import IconArrow from 'assets/icon/IconArrow';
import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { VOIPAddonsSelectPlan } from './VOIPAddonsSelectPlan';
import { VOIPAddonsModifyPlan } from './VOIPAddonsModifyPlan';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACCOUNT_DETAIL_VOIP } from 'app/const/api/Voip';
import { VOIPAddonsLoadingSelectPlan } from '../loadings/VOIPAddonsLoadingSelectPlan';
import { useSelector } from 'react-redux';
import ErrorPage from 'app/modules/error';
import { ACCOUNT_ROLE } from 'app/const/App';
import { KEY_VOIP } from 'app/const/addons';
import { addBranchPath } from 'app/const/Branch';

export const VOIPAddonsPlan = () => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const addons = useSelector(({ auth }) => auth.user.settings.addons);

    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    if (!isSupperAdmin || !(KEY_VOIP in addons)) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    const PLAN_STEP = {
        ACTIVATED: {
            step: 0,
            handleBack: () => history.push(addBranchPath(ADDONS_VOIP))
        },
        ONE: {
            step: 1,
            handleBack: () => history.push(addBranchPath(ADDONS_VOIP))
        },
        TWO: {
            step: 2,
            handleBack: () => dispatchState({ ...PLAN_STEP.ONE })
        }
    };

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        ...PLAN_STEP.ACTIVATED
    });

    const { step, handleBack: _handleBack, isLoading } = state;

    useEffect(() => {
        _fetchPlan();
    }, []);

    const _renderContent = () => {
        switch (step) {
            case PLAN_STEP.ONE.step:
                return <VOIPAddonsSelectPlan data={state} onContinue={_handleNextStep} />;
            case PLAN_STEP.TWO.step:
                return <VOIPAddonsModifyPlan data={state} />;
            case PLAN_STEP.ACTIVATED.step:
                return <div className="tables-empty m-0 mt-3 bg-white">{t('your_voip_plan_has_been_activated')}</div>;
            default:
                return null;
        }
    };

    const _handleNextStep = (value) => {
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...value }, ...PLAN_STEP.TWO }));
    };

    const _fetchPlan = () => {
        const _handleSuccess = (res) => {
            const { account, plans } = res.data;
            let newData = { ...res.data };
            if (!account) {
                newData = { ...newData, ...PLAN_STEP.ONE };
                newData.account = {
                    plan_id: plans.find((item) => !!item.is_default)['id'],
                    user_quantity: 1,
                    isActive: false
                };
            }
            dispatchState({ ...newData, isLoading: false });
        };

        clientQuery(ACCOUNT_DETAIL_VOIP, { method: 'GET' }, _handleSuccess);
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <div className="v2-btn-default has-icon --grey" onClick={_handleBack}>
                    <IconArrow isPrev />
                    {t('back')}
                </div>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <VOIPAddonsLoadingSelectPlan />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border ">
                                <AddonsBoxHeader
                                    keyword={'VOIP'}
                                    name={t('voip_by_gorilladesk')}
                                    status={1}
                                    level={20}
                                    isHideCheckbox
                                />
                            </div>
                            {_renderContent()}
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};
