export const LoadingTemplates = () => {
    return (
        <div className="wrap-loading wrap-list-items flex-auto">
            <div className="header-title">
                <div className="loading --animation --line --onefifth"></div>
                <div className="loading v2-btn-default --grey btn-sm">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
            <div className="wrap-list-items__search">
                <div className="v2-btn-default loading --grey --full">
                    <div className="loading --animation --line --onefifth"></div>
                </div>
            </div>
            <div className="list-details is-active">
                <div className="list-details__items pointer-events-none">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
                <div className="list-details__items pointer-events-none">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
                <div className="list-details__items pointer-events-none">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
            </div>
        </div>
    );
};

export const LoadingDetailItems = () => {
    return (
        <div className="wrap-loading wrap-note-template flex-column flex-1 is-show">
            <div className="template-header">
                <div className="loading --animation --line --twothird"></div>
                <div className="template-header__name mt-3">
                    <div className="loading --animation --line --onefifth mb-1"></div>
                    <div className="v2-btn-default loading --grey --full">
                        <div className="loading --animation --line --onefifth"></div>
                    </div>
                </div>
            </div>
            <div className="template-content flex-column flex-1">
                <div className="wrap-content">
                    <div className="todo-items">
                        <div className="todo-items__action flextop">
                            <div className="loading v2-btn-default --transparent --icon-sm mx-2 bg-white-cultured pointer-events-none"></div>
                            <div className="col-desc flex-1 pr-3">
                                <div className="loading --animation --line --full"></div>
                                <div className="loading --animation --line --threefourth mt-2"></div>
                            </div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                        </div>
                    </div>
                    <div className="todo-items">
                        <div className="todo-items__action flextop">
                            <div className="loading v2-btn-default --transparent --icon-sm mx-2 bg-white-cultured pointer-events-none"></div>
                            <div className="col-desc flex-1 pr-3">
                                <div className="loading --animation --line --full"></div>
                                <div className="loading --animation --line --threefourth mt-2"></div>
                            </div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                        </div>
                    </div>
                    <div className="todo-items">
                        <div className="todo-items__action flextop">
                            <div className="loading v2-btn-default --transparent --icon-sm mx-2 bg-white-cultured pointer-events-none"></div>
                            <div className="col-desc flex-1 pr-3">
                                <div className="loading --animation --line --full"></div>
                                <div className="loading --animation --line --threefourth mt-2"></div>
                            </div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-1"></div>
                        </div>
                    </div>
                </div>
                <div className="btn-action shadow-top-brown">
                    <div className="loading v2-btn-default --transparent btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="template-content__footer shadow-top-brown flex-betweenitems">
                    <div className="loading v2-btn-default --grey btn-x-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="loading v2-btn-default --grey btn-md">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
