export default function IconItem({ isSmall = false, isLarge = false }) {
    if (isSmall)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 6.5H18.5C19.0523 6.5 19.5 6.94772 19.5 7.5C19.5 8.05228 19.0523 8.5 18.5 8.5H5.5C4.94772 8.5 4.5 8.05228 4.5 7.5C4.5 6.94772 4.94772 6.5 5.5 6.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 16.5H18.5C19.0523 16.5 19.5 16.9477 19.5 17.5C19.5 18.0523 19.0523 18.5 18.5 18.5H5.5C4.94772 18.5 4.5 18.0523 4.5 17.5C4.5 16.9477 4.94772 16.5 5.5 16.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 11.5H18.5C19.0523 11.5 19.5 11.9477 19.5 12.5C19.5 13.0523 19.0523 13.5 18.5 13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5C4.5 11.9477 4.94772 11.5 5.5 11.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    if (isLarge)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78194 6.5H18.2181C18.6638 6.5 18.8255 6.54641 18.9884 6.63357C19.1514 6.72072 19.2793 6.84861 19.3664 7.01158C19.4536 7.17454 19.5 7.33618 19.5 7.78194V16.2181C19.5 16.6638 19.4536 16.8255 19.3664 16.9884C19.2793 17.1514 19.1514 17.2793 18.9884 17.3664C18.8255 17.4536 18.6638 17.5 18.2181 17.5H5.78194C5.33618 17.5 5.17454 17.4536 5.01158 17.3664C4.84861 17.2793 4.72072 17.1514 4.63357 16.9884C4.54641 16.8255 4.5 16.6638 4.5 16.2181V7.78194C4.5 7.33618 4.54641 7.17454 4.63357 7.01158C4.72072 6.84861 4.84861 6.72072 5.01158 6.63357C5.17454 6.54641 5.33618 6.5 5.78194 6.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.78194 6.5H18.2181C18.6638 6.5 18.8255 6.54641 18.9884 6.63357C19.1514 6.72072 19.2793 6.84861 19.3664 7.01158C19.4536 7.17454 19.5 7.33618 19.5 7.78194V9.21806C19.5 9.66382 19.4536 9.82546 19.3664 9.98842C19.2793 10.1514 19.1514 10.2793 18.9884 10.3664C18.8255 10.4536 18.6638 10.5 18.2181 10.5H5.78194C5.33618 10.5 5.17454 10.4536 5.01158 10.3664C4.84861 10.2793 4.72072 10.1514 4.63357 9.98842C4.54641 9.82546 4.5 9.66382 4.5 9.21806V7.78194C4.5 7.33618 4.54641 7.17454 4.63357 7.01158C4.72072 6.84861 4.84861 6.72072 5.01158 6.63357C5.17454 6.54641 5.33618 6.5 5.78194 6.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.78194 13.5H18.2181C18.6638 13.5 18.8255 13.5464 18.9884 13.6336C19.1514 13.7207 19.2793 13.8486 19.3664 14.0116C19.4536 14.1745 19.5 14.3362 19.5 14.7819V16.2181C19.5 16.6638 19.4536 16.8255 19.3664 16.9884C19.2793 17.1514 19.1514 17.2793 18.9884 17.3664C18.8255 17.4536 18.6638 17.5 18.2181 17.5H5.78194C5.33618 17.5 5.17454 17.4536 5.01158 17.3664C4.84861 17.2793 4.72072 17.1514 4.63357 16.9884C4.54641 16.8255 4.5 16.6638 4.5 16.2181V14.7819C4.5 14.3362 4.54641 14.1745 4.63357 14.0116C4.72072 13.8486 4.84861 13.7207 5.01158 13.6336C5.17454 13.5464 5.33618 13.5 5.78194 13.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
