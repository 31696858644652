import { updateTask } from 'app/const/Api';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import IconDone from 'assets/icon/IconDone';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment, formatDate } from 'common/utils/DateUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { STATUS } from '../../const/SideBar';
import TaskEditForm from './TaskEditForm';
import TaskItemActions from './TaskItemActions';
import TaskItemLogs from './TaskItemLogs';
import TaskItemTags from './TaskItemTags';

const TaskItem = forwardRef(
    (
        {
            dataTask = {},
            isModalItem = false,
            avatarSize = { width: 24, height: 24 },
            onUpdate = () => {},
            onDelete = () => {},
            onMarkDone = () => {},
            onEditing = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation();
        const [state, dispatchState] = useReducer(reducer, {
            isDone: !!!dataTask.active,
            isEditing: false,
            task: null
        });

        const currentUser = useSelector(({ auth }) => auth.user);
        const formatCompany = currentUser.company.date_format;
        const timezone = currentUser.settings.timezone;

        const isHavePermissionTask = useMemo(() => {
            return checkPermission(currentUser.permissions.enabled || [], PERMISSIONS.editOrDeleteTaskInbox);
        }, []);

        const task = state.task;
        const dateData = task?.dateData || dataTask.dateData;
        const firstName = task?.assignee?.first_name || dataTask.assignee.first_name;
        const lastName = task?.assignee?.last_name || dataTask.assignee.last_name;
        const completedAt = task?.completed_at || dataTask.completed_at;
        const completedBy = task?.completed_by || dataTask?.completed_by || {};

        const formatDisplay = convertPhpFormatToMoment(formatCompany);
        const createdAtTimezone = moment(completedAt).tz(timezone);
        const timeDisplay = {
            date: createdAtTimezone.isValid() ? createdAtTimezone.format(formatDisplay) : '',
            time: createdAtTimezone.isValid() ? createdAtTimezone.format('h:mm A') : ''
        };

        useImperativeHandle(ref, () => ({ _getStatusDone: () => state.isDone }));

        useEffect(() => {
            if (dataTask.active !== state.isDone)
                dispatchState({ isDone: !!!dataTask.active, completed_at: dataTask.completed_at });
        }, [dataTask]);

        const _changeStatus = () => {
            const active = !!state.isDone ? STATUS.ACTIVE : STATUS.DONE;
            const dataUpdate = {
                ...dataTask,
                active,
                completed_by: !!active ? null : currentUser.profile,
                completed_at: !!active ? null : moment().utc().toISOString()
            };
            onMarkDone(dataUpdate, active);
            dispatchState({ isDone: !state.isDone, task: dataUpdate });
        };

        const _toggleEditForm = () => {
            dispatchState({ isEditing: !state.isEditing });
            onEditing();
        };

        const _updateTask = (dataUpdate) => {
            dispatchState({ task: dataUpdate });
            onUpdate(dataUpdate);
        };

        const _deleteTask = (dataDelete) => {
            onDelete(dataDelete || task || dataTask);
            clientQuery(updateTask(dataTask.id), {
                // eslint-disable-next-line no-undef
                data: { socket_id: global.socket_id_task_service },
                method: 'DELETE'
            });
        };

        return (
            <div className={classNames('box-task', { done: state.isDone, 'is-edited': state.isEditing })}>
                <div className="title">
                    <div className="v2-btn-default task-done text-capitalize" onClick={_changeStatus}>
                        <IconDone />
                        {t('done')}
                    </div>
                    <div className="avt-img">
                        <img src={task?.assignee?.avatar || dataTask.assignee.avatar} alt="" {...avatarSize} />
                    </div>
                    <div className="title__info">
                        <div className="name">{`${firstName} ${lastName}`}</div>
                        <div className={classNames('time', { 'fs-13': isModalItem })}>{`${formatDate(
                            dateData,
                            formatCompany
                        )} ${moment(dateData).utc().format('h:mm A')}`}</div>
                    </div>
                    {isHavePermissionTask && (
                        <TaskItemActions isDone={state.isDone} onEdit={_toggleEditForm} onDelete={_deleteTask} />
                    )}
                </div>
                <div className="description">{task?.title || dataTask.title}</div>

                <TaskItemTags customer={task?.customer || dataTask.customer} job={task?.job || dataTask.job} />

                <TaskItemLogs
                    isVisible={state.isDone}
                    doneBy={completedBy?.full_name || currentUser.profile?.name}
                    time={`${timeDisplay.date} ${timeDisplay.time}`}
                />

                <TaskEditForm
                    dataTask={task || dataTask}
                    isHideHeader={isModalItem}
                    isVisible={state.isEditing}
                    onToggleVisible={_toggleEditForm}
                    onUpdate={_updateTask}
                    onDelete={_deleteTask}
                />
            </div>
        );
    }
);

TaskItem.propTypes = {
    dataTask: PropTypes.shape({}),
    onMarkDone: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
};
TaskItem.displayName = 'TaskItem';
export default TaskItem;
