import React, { useReducer, forwardRef, useImperativeHandle } from 'react';
import GdButton from 'app/components/button';
import { reducer } from 'app/const/Reducer';

const ButtonSave = forwardRef(
    (
        {
            children,
            onSave,
            wrapClass,
            defaultDisabled = false,
            disabled = false,
            title = '',
            isNotLoading = false,
            isWhiteLoading = false,
            tabIndex = '0'
        },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            isLoading: false,
            disabledBtn: defaultDisabled
        });

        useImperativeHandle(ref, () => ({
            getValue: state.isLoading,
            setLoading: _setLoading,
            removeLoading: _removeLoading,
            setDisable: (type) => {
                dispatchState({ disabledBtn: type });
            },
            getStatusBtn: () => state.disabledBtn
        }));

        function _setLoading(isLoading) {
            dispatchState((prev) => {
                return { ...prev, isLoading };
            });
        }

        function _removeLoading() {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: false
                };
            });
        }

        function _handleSave(e) {
            e.stopPropagation();
            !isNotLoading &&
                dispatchState((prev) => {
                    return {
                        ...prev,
                        isLoading: true
                    };
                });
            onSave();
        }

        return (
            <GdButton
                disabled={disabled || state.disabledBtn}
                onClick={_handleSave}
                className={wrapClass || 'v2-btn-main'}
                isLoading={state.isLoading}
                isWhiteLoading={children || isWhiteLoading ? true : false}
                tabIndex={tabIndex}
            >
                {title || children || 'Save'}
            </GdButton>
        );
    }
);

export default ButtonSave;
