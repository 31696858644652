import React, { useContext, useEffect, useRef } from 'react';
import CustomerPhoneLabel from 'app/components/phoneLabel';
import { AddCustomerContext } from '../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../contexts/types';

const AddCustomerPhone = () => {
    const { updateContext, profile } = useContext(AddCustomerContext);
    const refCustomerPhone = useRef(null);

    useEffect(() => {
        profile.phones.length && refCustomerPhone.current._setValue(profile.phones);
    }, [profile.phones]);

    const _handleUpdatePhone = (data) => {
        updateContext(data, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_PROFILE_PHONES);
    };

    return <CustomerPhoneLabel ref={refCustomerPhone} onRetrieveChange={_handleUpdatePhone} />;
};

export default AddCustomerPhone;
