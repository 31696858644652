export const TYPE_DRIP_MESSAGE_TEMPLATES = {
  GET_LIST: "DRIP_GET_LIST_MESSAGE_TEMPLATES",
  GET_DETAIL_TEMPLATE: "CAMPAING_GET_DETAIL_TEMPLATE",

  CREATE_MESSAGE_TEMPLATE: "CREATE_MESSAGE_TEMPLATE",
  UPDATE_MESSAGE_TEMPLATE: "UPDATE_MESSAGE_TEMPLATE",

  SEND_TEST_EMAIL: "SEND_TEST_EMAIL",
  DELETE_MESSAGE_TEMPLATE: "DELETE_MESSAGE_TEMPLATE",
  DUPLICATE: "DUPLICATE",

  GET_LIST_VARIABLE: "GET_LIST_VARIABLE",
  GET_LIST_VARIABLE_SUCCESS: "GET_LIST_VARIABLE_SUCCESS",
};

// actions
export const getListDripMessageTemplates = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const getDetailMessageTemplate = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.GET_DETAIL_TEMPLATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const createMessageTemplate = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.CREATE_MESSAGE_TEMPLATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateMessageTemplate = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.UPDATE_MESSAGE_TEMPLATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const sendTestEmail = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.SEND_TEST_EMAIL,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const deleteDripMessageTemplates = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.DELETE_MESSAGE_TEMPLATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const duplicateTemplate = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.DUPLICATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

// variables
export const getListVariable = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_MESSAGE_TEMPLATES.GET_LIST_VARIABLE,
    params,
    actionSuccess,
    actionFailed,
  };
};
