import { call, put } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE } from 'app/const/Api';
import { EMAIL_TEMPLATE } from 'common/redux/actions/email/template';

export function* getListEmailTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, params);
        if (response && response.success) {
            yield put({ type: EMAIL_TEMPLATE.ACTION_GET_LIST_EMAIL_TEMPLATE_SUSSESS, payload: response });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
