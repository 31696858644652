export const TYPE_LOCATION = {
    LOCATION_GET_LIST_CITY: 'LOCATION_GET_LIST_CITY',
    LOCATION_GET_LIST_ZIP: 'LOCATION_GET_LIST_ZIP'
};

// actions
export const getListLocationCity = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_LOCATION.LOCATION_GET_LIST_CITY,
        params,
        actionSuccess,
        actionFailed
    };
};

// actions
export const getListLocationZip = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_LOCATION.LOCATION_GET_LIST_ZIP,
        params,
        actionSuccess,
        actionFailed
    };
};
