import { DEFAULT_ALL } from 'app/const/App';

export const MESSAGE_TABS = {
    OFF: 4,
    BOTH: 3,
    EMAIL: 2,
    SMS: 1
};

export const LOCATION_DEFAULT_ALL = { id: DEFAULT_ALL, address: 'All Locations' };

export const TYPE_JOB_META = {
    EMOJI: 1
};

export const JOB_ACTIVE = {
    UNACTIVE: 0,
    ACTIVE: 1
};

export const LOCKED = 'locked';
export const CONFIRMED = 'confirmed';
