import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { ADDONS_VOIP_GROUP, ADDONS_VOIP_PERSONAL, SOURCE_PHONE_NUMBER_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconStar from 'assets/icon/IconStar';
import IconSync from 'assets/icon/IconSync';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AssignGroupMember } from './settings/AssignGroupMember';
import { GdAudioPlayer } from 'app/components/audio/GdAudioPlayer';
import { ACCOUNT_ROLE } from 'app/const/App';

export const VOIPItemNumber = ({
    item = {},
    keyword = '',
    onUpdateOptions = () => {},
    onChangeNumber = () => {},
    onSetPrimary = () => {},
    onDeleteNumber = () => {},
    onUpdateMember = () => {}
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const refCheckboxVoicemail = useRef(null);
    const refCheckboxForward = useRef(null);

    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { id: userId, role: userRole } = useSelector(({ auth }) => auth.user.profile);

    const { id, assignees, phone_number, source, is_primary, type, forwarding, voicemail, recording_disclosure } = item;

    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const isGroupOnlySupperAdmin = isSupperAdmin || type !== TYPE_PHONE_VOIP.GROUP;
    const isAccessGroup =
        isGroupOnlySupperAdmin || (assignees.includes(userId) && userRole !== ACCOUNT_ROLE.TECHNICIAN);
    const isExternalNumber = source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL;

    const _renderHeader = () => {
        const _renderNoteExternal = () => {
            if (source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL)
                return <p className="w-100 grey-extrlight fs-11">{t('addons:external_number_note')}</p>;
            return null;
        };

        switch (keyword) {
            case ADDONS_VOIP_PERSONAL:
                return (
                    <Fragment>
                        <div className="title-number flex-1 flexcenter flex-wrap col-gap-8">
                            <b className="fw-600">{formatPhoneNumberVoip(phone_number)}</b>
                            {_renderInfoPersonal()}
                            {_renderNoteExternal()}
                        </div>
                        {_renderTreeDots()}
                    </Fragment>
                );
            case ADDONS_VOIP_GROUP:
                return (
                    <Fragment>
                        <div className="title-number flex-1 flexcenter flex-wrap col-gap-8">
                            <b className="fw-600">{formatPhoneNumberVoip(phone_number)}</b>
                            {is_primary ? (
                                <span className="status-btn bg-orange-red --sm">{t('addons:primary')}</span>
                            ) : null}
                            {_renderNoteExternal()}
                        </div>
                        {_renderTreeDots()}
                    </Fragment>
                );
            default:
                return null;
        }
    };

    const _renderTreeDots = () => {
        if (!isExternalNumber && !isAccessGroup) return null;

        const TYPE_OPTIONS = {
            SETTING: 'setting',
            PRIMARY: 'primary',
            CHANGE_NUMBER: 'change_number',
            DELETE: 'delete'
        };
        const _handleCallback = (value) => {
            const { id, typeOption } = value;
            switch (typeOption) {
                case TYPE_OPTIONS.SETTING:
                    history.push({ search: `?id=${id}`, state: item });
                    break;
                case TYPE_OPTIONS.PRIMARY:
                    onSetPrimary(id);
                    break;
                case TYPE_OPTIONS.CHANGE_NUMBER:
                    onChangeNumber(value);
                    break;
                case TYPE_OPTIONS.DELETE:
                    onDeleteNumber(item);
                    break;
                default:
                    return;
            }
        };

        const options = [
            {
                id: TYPE_OPTIONS.SETTING,
                name: (
                    <Fragment>
                        <IconCogWheel />
                        <span className="txt-ellipsis">{t('addons:settings')}</span>
                    </Fragment>
                ),
                value: { type, typeOption: TYPE_OPTIONS.SETTING, id },
                isHide: false
            },
            {
                id: TYPE_OPTIONS.PRIMARY,
                name: (
                    <Fragment>
                        <IconStar isFlat />
                        <span className="txt-ellipsis">{t('addons:set_as_primary')}</span>
                    </Fragment>
                ),
                value: { type, typeOption: TYPE_OPTIONS.PRIMARY, id },
                isHide: true
            },
            {
                id: TYPE_OPTIONS.CHANGE_NUMBER,
                name: (
                    <Fragment>
                        <IconSync />
                        <span className="txt-ellipsis">{t('addons:change_number')}</span>
                    </Fragment>
                ),
                value: { type, typeOption: TYPE_OPTIONS.CHANGE_NUMBER, id },
                isHide: true
            },
            {
                id: TYPE_OPTIONS.DELETE,
                name: (
                    <Fragment>
                        <IconTrash />
                        <span className="txt-ellipsis red-delete">{t('addons:delete_number')}</span>
                    </Fragment>
                ),
                value: { type, typeOption: TYPE_OPTIONS.DELETE, id },
                isHide: !isSupperAdmin
            }
        ];

        const _options = () => {
            return options.filter((item) => !item.isHide);
        };

        return (
            <CalendarDropdown
                id={`tree_dots_${id}`}
                buttonClassName="dropbtn v2-btn-default --icon-lg"
                customDropButton={() => <IconThreeDots />}
                wrapperListClass={'v2-dropdown__menu --right content-full scrolls'}
                keyGetValue="value"
                keyGetName="name"
                keyGetKey="id"
                wrapperItemClass="items has-icon"
                spanItemClassName=""
                options={_options()}
                onSelect={_handleCallback}
            />
        );
    };

    const _renderFooter = () => {
        switch (keyword) {
            case ADDONS_VOIP_GROUP:
                if (!isGroupOnlySupperAdmin || source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) return null;
                return <AssignGroupMember item={item} onUpdateMember={onUpdateMember} isShowAlert />;
            default:
                return null;
        }
    };

    const _renderInfoPersonal = () => {
        const findUser = companyUsers.find((user) => user.id * 1 === assignees[0]);
        const { avatar: finalAvatar, full_name: finalName } = findUser || {};
        return (
            <div className="dots personal-info flexcenter">
                {!!findUser && (
                    <Fragment>
                        <span className="avt-img --large">
                            <img src={finalAvatar} width={32} height={32} alt={finalName} />
                        </span>
                        <span className="personal-info__name fw-500 fs-15">{finalName}</span>
                    </Fragment>
                )}
                {is_primary ? <span className="status-btn bg-orange-red --sm">{t('addons:primary')}</span> : null}
            </div>
        );
    };

    const _handleUpdateOptions = (checked, idElm) => {
        const type = idElm.replace(`_${id}`, '');
        const _callbackOnFail = () => {
            switch (type) {
                case 'voicemail':
                    return refCheckboxVoicemail.current.setIsActive(!!voicemail.enable);
                case 'forwarding':
                    return refCheckboxForward.current.setIsActive(!!forwarding.enable);
                default:
                    break;
            }
        };

        onUpdateOptions({ id, type, value: checked, callbackOnFail: _callbackOnFail });
    };

    const _renderOptionsNumber = () => {
        if (source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) return null;

        return (
            <Fragment>
                <div className="box-voip__rows flexcenter flex-wrap is-enabled">
                    <p className="flex-1">{t('addons:voicemail_greeting')}</p>
                    <SwitchCheckbox
                        ref={refCheckboxVoicemail}
                        id={`voicemail_${id}`}
                        textChecked={t('addons:enabled')}
                        textUnChecked={t('addons:disabled')}
                        defaultChecked={!!voicemail.enable}
                        onChange={_handleUpdateOptions}
                        disabled={(!voicemail.greeting && !voicemail.greeting_text) || !isAccessGroup}
                    />
                    {!!voicemail.enable && !!voicemail.greeting ? (
                        <div className="detail-enable w-100 mt-2">
                            <GdAudioPlayer src={voicemail.greeting} />
                        </div>
                    ) : null}
                </div>

                <div className="box-voip__rows flexcenter flex-wrap is-enabled">
                    <p className="flex-1">{t('addons:call_forwarding')}</p>
                    <SwitchCheckbox
                        ref={refCheckboxForward}
                        id={`forwarding_${id}`}
                        textChecked={t('addons:enabled')}
                        textUnChecked={t('addons:disabled')}
                        defaultChecked={!!forwarding.enable}
                        onChange={_handleUpdateOptions}
                        disabled={!forwarding.number || !isAccessGroup}
                    />
                    {!!forwarding.enable && !!forwarding.number ? (
                        <div className="detail-enable w-100 mt-2">
                            <p className="black word-break">{forwarding.number}</p>
                        </div>
                    ) : null}
                </div>
            </Fragment>
        );
    };

    const _renderOptionDisclosure = () => {
        return (
            <div className="box-voip__rows flexcenter flex-wrap is-enabled">
                <p className="flex-1">{t('addons:call_recording_disclosure')}</p>
                <SwitchCheckbox
                    ref={refCheckboxForward}
                    id={`recording_disclosure_${id}`}
                    textChecked={t('addons:enabled')}
                    textUnChecked={t('addons:disabled')}
                    defaultChecked={!!recording_disclosure.enable}
                    onChange={_handleUpdateOptions}
                    disabled={isExternalNumber ? false : !isAccessGroup}
                />
            </div>
        );
    };

    return (
        <div className="box-voip bg-white">
            <div className="box-voip__rows flexcenter">
                <div className="title-number flex-1 flexcenter flex-wrap col-gap-8">{_renderHeader()}</div>
            </div>
            {_renderOptionsNumber()}
            {_renderOptionDisclosure()}
            {_renderFooter()}
        </div>
    );
};
