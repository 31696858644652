import React, { useReducer, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import Select from 'app/modules/jobdetail/tabs/materials/components/Select';
import ButtonSave from 'app/components/button/ButtonSave';
import IconTooltip from 'assets/icon/IconTooltip';
import IconEmail from 'assets/icon/IconEmail';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import { TYPE_SEND_EMAIL_SMS } from 'app/modules/jobdetail/const';
import { LIMIT_SMS_COUNT } from 'app/const/Settings';
import { useTranslation } from 'react-i18next';
import { validatePhoneNumberVoip } from 'common/utils/PhoneUtils';

const Sms = forwardRef(
    (
        {
            isVisible,
            handleCloseForm,
            urlGetSMS = '',
            params,
            onSend,
            isEmailAndSms = false,
            parentSendEmail = () => {},
            onChangeTab = () => {},
            onCallbackMessage = () => {},
            objectSms,
            isAllowOnSMSInput = false
        },
        ref
    ) => {
        const { t } = useTranslation(['setting', 'report']);
        const [state, dispatchState] = useReducer(reducer, {
            listSms: [],
            smsAddress: '',
            smsLabel: '',
            smsContent: ''
        });

        useImperativeHandle(ref, () => ({
            handleSend: _handleSendSMS
        }));

        const refButtonSave = useRef(null);
        const refStatusBar = useRef(null);
        const { listSms, smsAddress, smsContent, smsLabel } = state;

        useEffect(() => {
            if (isVisible) {
                if (!objectSms) {
                    _getListSms();
                } else {
                    dispatchState({
                        ...objectSms
                    });
                }
            }
            // isFirsTime.current && isVisible && _getListSms();
        }, [isVisible]);

        const _getListSms = () => {
            const _getListSmsFail = ({ message }) => {
                _handleCallback(false, message);
            };

            clientQuery(urlGetSMS, { method: 'GET', data: params }, _getListSmsSuccess, _getListSmsFail);
        };

        const _getListSmsSuccess = (response) => {
            let dataPhones = response.phones || [];
            dataPhones = dataPhones
                .filter((itemPhone) => itemPhone.value)
                .map((item, index) => {
                    return {
                        id: index.toString(),
                        name: item.label,
                        value: item.value,
                        is_active: item.is_active
                    };
                });

            const findNumberPrimary = dataPhones.find((item) => !!item.is_active) || dataPhones?.[0] || {};

            dispatchState({
                listSms: dataPhones,
                smsAddress: findNumberPrimary.value || '',
                smsContent: response.content || '',
                smsLabel: findNumberPrimary.name || ''
            });
        };

        const _handleOnSelect = (name, value = '', isTyping = false) => {
            const isAllowTyping = isTyping && isAllowOnSMSInput;
            const findItemSelected = isAllowTyping ? {} : listSms.find((item) => item.id === value) || {};
            dispatchState((prev) => ({
                ...prev,
                smsAddress: isAllowTyping ? value : findItemSelected.value || '',
                smsLabel: isAllowTyping ? value : findItemSelected.name || ''
            }));
        };

        const _handleChangeSmsContent = (e) => {
            dispatchState({ smsContent: e.target.value });
        };

        function _handleSendSMS(fromComponent = false) {
            if (smsAddress.length === 0) {
                _handleCallback(false, t('report:receiver_require'));
                return false;
            }

            if (!validatePhoneNumberVoip(smsAddress)) {
                _handleCallback(false, t('report:receiver_invalid'));
                return false;
            }

            if (!smsContent) {
                _handleCallback(false, t('report:sms_content_require'));
                return false;
            }
            if (smsContent.replace(/(\r\n|\n|\r)/g, '').length > 600) {
                _handleCallback(false, t('report:sms_content_limit'));
                return false;
            }

            if (fromComponent && isEmailAndSms) {
                if (!parentSendEmail()) {
                    _handleCloseLoading();
                    return false;
                }
            }

            onSend({ sms_address: smsAddress, sms_content: smsContent, request_sig: false }, _handleCallback);
            return true;
        }

        function _handleCallback(status, message) {
            if (isEmailAndSms) {
                onCallbackMessage(status ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR, message);
            } else {
                refStatusBar.current.showStatusBar(
                    'show_error',
                    message,
                    status ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
                );
            }

            _handleCloseLoading();

            if (status) {
                dispatchState({
                    message: '',
                    attachments: []
                });

                setTimeout(() => {
                    !isEmailAndSms && handleCloseForm();
                }, 2000);
            } else {
                isEmailAndSms && onChangeTab(null, TYPE_SEND_EMAIL_SMS.SEND_SMS);
            }
            return status;
        }

        function _handleCloseLoading() {
            refButtonSave.current.removeLoading();
        }

        if (!isVisible) return null;

        return (
            <>
                <StatusBar ref={refStatusBar} />
                <div className="wrap-send-email">
                    <div className="row-multi --email-to --sms-to">
                        <div className="left-txt">
                            <IconEmail isEmailAddress />
                            <span className="txt-ellipsis">To:</span>
                        </div>
                        <Select
                            name="sms_address"
                            classWrapper={'v2-dropdown'}
                            options={listSms}
                            selected={smsLabel}
                            onSelect={_handleOnSelect}
                            isInput
                            onlyNumber
                            isSelectName
                            isAllowOnInput={isAllowOnSMSInput}
                        />
                    </div>
                    <div className="body-modal scrolls">
                        <textarea
                            className={'container-content-email field-textarea --no-border --no-resize d-flex'}
                            value={smsContent}
                            onChange={_handleChangeSmsContent}
                        />
                    </div>
                    <div className="footer-modal">
                        <div className="flexcenter gap-8">
                            <div className="tooltip d-flex">
                                <IconTooltip />
                                <span className="tooltiptext top">{t('setting:tooltip_sms_template')}</span>
                            </div>
                            <div className="blue-default">
                                <span>{smsContent.replace(/(\r\n|\n|\r)/g, '').length}</span>
                                <span>/{LIMIT_SMS_COUNT}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-end flex-1">
                            <span onClick={handleCloseForm} className="v2-btn-default --noborder --label">
                                {t('setting:cancel')}
                            </span>
                            <ButtonSave
                                disabled={smsAddress.length === 0 || smsContent.length === 0}
                                onSave={() => _handleSendSMS(true)}
                                ref={refButtonSave}
                                title={'Send'}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
);

export default Sms;
