import { reducer } from 'app/const/Reducer';
import { DEVICE_ID_AUDIO_DEFAULT, KEY_PHONE_SETTINGS, TYPE_DEVICE_AUDIO, TYPE_SOUND } from 'app/const/Voip';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconPlay from 'assets/icon/IconPlay';
import classNames from 'classnames';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { getDefaultParamsPhoneSettings } from '.';
import IconArrowDown from 'assets/icon/IconArrowDown';

export const AudioSettings = ({ isDisable }) => {
    const { onSetDeviceAudio, onTestSound, onSetVolume, onResumeAudioContext } = useContext(VOIPContext);
    const { INPUT: typeInput, OUTPUT: typeOutput, RINGING: typeRinging } = TYPE_DEVICE_AUDIO;

    const [state, dispatchState] = useReducer(
        reducer,
        {
            audioDevices: [],
            deviceSelected: getDefaultParamsPhoneSettings().deviceSelected
        },
        (params) => {
            return {
                ...params,
                deviceSelected: getLocalStorage(KEY_PHONE_SETTINGS)?.deviceSelected || params.deviceSelected
            };
        }
    );
    const { audioDevices, deviceSelected } = state;
    const refVolumeInput = useRef(null);
    const refDisable = useRef(isDisable);
    const refDropdownOutput = useRef(null);
    const refDropdownRinging = useRef(null);
    const refDropdownInput = useRef(null);

    useEffect(() => {
        fetchAudio();
        onSetVolume(handleSetVolume);
        onResumeAudioContext();
        return () => {
            !refDisable.current && onSetDeviceAudio(typeInput, null);
        };
    }, []);

    useEffect(() => {
        refDisable.current = isDisable;
        if (isDisable) handleCloseDropdown();
    }, [isDisable]);

    const handleSelected = ({ type, item }) => {
        handleCloseDropdown();
        if (onSetDeviceAudio(type, item) && !!item)
            dispatchState((prev) => {
                const newDeviceSelected = {
                    ...prev.deviceSelected,
                    [type]: { ...item, id: item.deviceId }
                };
                setLocalStorage(KEY_PHONE_SETTINGS, {
                    ...getLocalStorage(KEY_PHONE_SETTINGS),
                    deviceSelected: newDeviceSelected
                });
                return { ...prev, deviceSelected: newDeviceSelected };
            });
    };

    const handleSetVolume = (volume) => {
        const newVolume = Math.round(volume * 14);
        refVolumeInput.current?.childNodes?.forEach((item, index) => {
            if (index < newVolume) item.classList.add('is-on-volume');
            else item.classList.remove('is-on-volume');
        });
    };

    const getLabelDefault = (type) => {
        return (
            audioDevices.find((item) => {
                let newType = type;
                switch (type) {
                    case typeRinging:
                        newType = typeOutput;
                        break;
                    case typeInput:
                    case typeOutput:
                    default:
                        break;
                }
                return newType === item.kind && item.deviceId === deviceSelected[type]['id'];
            })?.label || 'Unknown Device'
        );
    };

    const fetchAudio = async () => {
        if (navigator.mediaDevices) {
            navigator.mediaDevices
                ?.enumerateDevices()
                .then((devices) => {
                    const defaultDevice = {
                        [typeInput]: {},
                        [typeOutput]: {}
                    };
                    const newDevices = devices
                        .filter((item) => !!item?.deviceId)
                        .map((item) => {
                            const newItem = item.toJSON();
                            const { kind, deviceId } = newItem;
                            switch (kind) {
                                case typeInput:
                                case typeOutput:
                                    if (deviceId === deviceSelected[kind]['id'])
                                        defaultDevice[kind]['item'] = { ...newItem, id: deviceId };
                                    if (deviceId === DEVICE_ID_AUDIO_DEFAULT)
                                        defaultDevice[kind]['default'] = { ...newItem, id: deviceId };
                                    break;
                            }
                            return newItem;
                        });

                    if (!isDisable) {
                        for (const type in defaultDevice) {
                            const device = defaultDevice[type]['item'] ?? defaultDevice[type]['default'];
                            if (device)
                                handleSelected({
                                    type: type,
                                    item: device
                                });
                        }
                    }
                    dispatchState({ audioDevices: newDevices });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const _renderListDevice = (type) => {
        return (
            <div>
                <ul>
                    {audioDevices
                        .filter((item) => (type === typeRinging ? item.kind === typeOutput : item.kind === type))
                        .map((item, index) => {
                            const { deviceId } = item;
                            return (
                                <li
                                    className={classNames('items', {
                                        active: deviceSelected[type]?.id === deviceId
                                    })}
                                    tabIndex={index}
                                    key={index.toString()}
                                    onClick={() => handleSelected({ type: type, item: { ...item, id: deviceId } })}
                                >
                                    <span className="txt-ellipsis">{item.label}</span>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    };

    const handleCloseDropdown = () => {
        refDropdownOutput.current._close();
        refDropdownRinging.current._close();
        refDropdownInput.current._close();
    };

    return (
        <div className={classNames('setting-device line-bottom-grey gap-module', { 'is-disable': isDisable })}>
            <b className="fs-13 black">Audio Settings</b>
            <div className="setting-device__rows flexcenter">
                <p className="device-label flex-auto granite-gray">Sound Output:</p>
                <DropdownPopper
                    ref={refDropdownOutput}
                    isUseToggle
                    placement="bottom-start"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    wrapperClassName="v2-dropdown flex-1 elm-select-sound v2-dropdown"
                    buttonClassName="elm-select-sound__btn dropbtn v2-btn-default w-100 tooltip"
                    customButton={
                        <Fragment>
                            <span className="txt-ellipsis">{getLabelDefault(typeOutput)}</span>
                            <span className="tooltiptext top">{getLabelDefault(typeOutput)}</span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    }
                >
                    {_renderListDevice(typeOutput)}
                </DropdownPopper>
                <div className="v2-btn-default --icon-lg tooltip" onClick={() => onTestSound(TYPE_SOUND.SPEAKER)}>
                    <IconPlay />
                    <span className="tooltiptext top">Test</span>
                </div>
            </div>
            <div className="setting-device__rows flexcenter">
                <p className="device-label flex-auto granite-gray">Ringing:</p>
                <DropdownPopper
                    ref={refDropdownRinging}
                    isUseToggle
                    placement="bottom-start"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    wrapperClassName="v2-dropdown flex-1 elm-select-sound v2-dropdown"
                    buttonClassName="elm-select-sound__btn dropbtn v2-btn-default w-100 tooltip"
                    customButton={
                        <Fragment>
                            <span className="txt-ellipsis">{getLabelDefault(typeRinging)}</span>
                            <span className="tooltiptext top">{getLabelDefault(typeRinging)}</span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    }
                >
                    {_renderListDevice(typeRinging)}
                </DropdownPopper>
                <div className="v2-btn-default --icon-lg tooltip" onClick={() => onTestSound(TYPE_SOUND.RINGING)}>
                    <IconPlay />
                    <span className="tooltiptext top">Test</span>
                </div>
            </div>
            <div className="setting-device__rows flexcenter">
                <p className="device-label flex-auto granite-gray">Microphone:</p>
                <DropdownPopper
                    ref={refDropdownInput}
                    isUseToggle
                    placement="bottom-start"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    wrapperClassName="v2-dropdown flex-1 elm-select-sound v2-dropdown"
                    buttonClassName="elm-select-sound__btn dropbtn v2-btn-default w-100 tooltip"
                    customButton={
                        <Fragment>
                            <span className="txt-ellipsis">{getLabelDefault(typeInput)}</span>
                            <span className="tooltiptext top">{getLabelDefault(typeInput)}</span>
                            <span className="arrow">
                                <IconArrowDown />
                            </span>
                        </Fragment>
                    }
                >
                    {_renderListDevice(typeInput)}
                </DropdownPopper>
            </div>
            <div ref={refVolumeInput} className="microphone-level-indicator flexcenter">
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
                <span className="level-indicator-step flex-1" />
            </div>
        </div>
    );
};
