import React, { useEffect, useReducer, useRef } from 'react';

import { ADDONS_LIST } from 'app/const/Api';
import { DEFAULT_ALL } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { useSiteTitle } from 'common/hooks';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonsList from '../components/AddonsList';
import LoadingListAddons from '../components/LoadingListAddons';
import {
    ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE,
    ADDONS_DASHBOARD_LOCAL_KEY,
    LIST_ADDON_LEVELS,
    LIST_ADDON_STATUSES
} from '../constants';
import HeaderDashboard from './components/Header';
import { useTranslation } from 'react-i18next';

const AddonsDashboard = () => {
    useSiteTitle('addons');

    const { t } = useTranslation();
    const localValue = getLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY);
    const localStatus = localValue?.status ?? DEFAULT_ALL;
    const localLevel = localValue?.level ?? DEFAULT_ALL;

    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true,
        isSearching: false,
        status: LIST_ADDON_STATUSES.includes(localStatus) ? localStatus : DEFAULT_ALL,
        level: LIST_ADDON_LEVELS.includes(localLevel) ? localLevel : DEFAULT_ALL
    });
    const refData = useRef([]);
    const refValueSearch = useRef('');
    const { data, isLoading, status, level, isSearching } = state;

    useEffect(() => {
        // Always get all addons when access this page
        _getListAddons(DEFAULT_ALL, DEFAULT_ALL);
    }, []);

    const _getListAddons = (status, level) => {
        clientQuery(ADDONS_LIST, { data: { status, level }, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        refData.current = data;

        // If there is no filter, then show all data
        if (!isSearching && status === DEFAULT_ALL && level === DEFAULT_ALL) {
            dispatchState({ data, isLoading: false });
        } else {
            dispatchState({
                data: _handleFilter(data, refValueSearch.current, status, level),
                isLoading: false
            });
        }
    };

    const _handleSearch = (event) => {
        let newData = refData.current;
        const valueSearch = event.target.value.toLowerCase();
        const isSearching = valueSearch.length;

        if (isSearching || status !== DEFAULT_ALL || level !== DEFAULT_ALL)
            newData = _handleFilter(data, valueSearch, status, level);
        refValueSearch.current = isSearching ? valueSearch : '';
        dispatchState({ data: newData, isSearching });
    };

    const _handleClearSearch = () => {
        refValueSearch.current = '';
        dispatchState({ isSearching: false, data: _handleFilter(refData.current, '', status, level) });
    };

    const _handleFilter = (data, valueSearch, status, level) => {
        return refData.current.filter((item) => {
            return (
                item.name.toLowerCase().includes(valueSearch) &&
                (item.status === status || status === DEFAULT_ALL) &&
                (item.level === level || level === DEFAULT_ALL)
            );
        });
    };

    const _handleChangeStatus = (status) => {
        setLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY, {
            ...(localValue || ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE),
            status
        });
        dispatchState({ status, data: _handleFilter(refData.current, refValueSearch.current, status, level) });
    };

    const _handleChangeLevel = (level) => {
        setLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY, {
            ...(localValue || ADDONS_DASHBOARD_DEFAULT_LOCAL_VALUE),
            level
        });
        dispatchState({ level, data: _handleFilter(refData.current, refValueSearch.current, status, level) });
    };

    const _handleChange = (id, checked) => {
        const data = [...refData.current].map((item) => {
            if (item.id === id) return { ...item, status: checked };
            return item;
        });

        refData.current = data;
        dispatchState({ data: _handleFilter(data, refValueSearch.current, status, level) });
    };

    return (
        <>
            <HeaderDashboard
                isSearching={isSearching}
                currentStatus={status}
                currentLevel={level}
                onChangeStatus={_handleChangeStatus}
                onChangeLevel={_handleChangeLevel}
                onClearSearch={_handleClearSearch}
                onSearch={_handleSearch}
            />
            {isLoading ? (
                <LoadingListAddons />
            ) : (
                <AddonsList
                    data={data}
                    onChange={_handleChange}
                    msgEmpty={
                        !!isSearching
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:addons') })
                    }
                />
            )}
        </>
    );
};

export default AddonsDashboard;
