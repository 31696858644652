import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumnsCustomerCredits = (actionsStatus, quickbooks) => {
    const header = {
        method_name: {
            title: 'method',
            style: 'col --lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        amount: {
            title: 'total',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col --info'
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col d-flex'
        }
    };

    const columns = [
        {
            id: 'method_name',
            status: true
        },
        {
            id: 'date',
            status: true
        },
        {
            id: 'amount',
            status: true
        },
        {
            id: 'status',
            status: true
        },
        {
            id: 'qb_sync',
            title: 'qb_sync',
            status: true
        }
    ];

    const contentConfig = {
        method_name: {
            style: 'col --lg --name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_CREDIT
        },
        date: {
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        amount: {
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            styleText: 'col-label fw-normal'
        },
        status: {
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.STATUS_CREDIT
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col d-flex',
            type: LIST_TABLE_ITEM_TYPE.QB_SYNC
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...header });
    const newContent = { ...contentConfig };

    columns.forEach((col) => {
        if (col.id === LIST_TABLE_ITEM_TYPE.QB_SYNC_CREDIT) {
            col.status = !!quickbooks && !!quickbooks.sync_credit;
        }
    });

    return {
        header: newHeader,
        columns,
        contentConfig: newContent
    };
};
