import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { CONDITION_1, CONDITION_2, CONDITION_3, EXCEPTON_OPTIONS } from 'app/modules/jobdetail/const';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { useTranslation } from 'react-i18next';
import { reducer } from 'app/const/Reducer';

const Codition = () => {
    const { t } = useTranslation(['jobDetail']);

    const { repeatSettings, onChangeException } = useContext(DataRepeatContext);
    const jobExceptionDetail = repeatSettings.exception.options;
    const isFistTime = useRef(true);
    const [state, dispatchState] = useReducer(reducer, parseExceptionCondition(jobExceptionDetail));
    const activeTabOne = state.tabOne;
    const activeTabTwo = state.tabTwo;
    const activeTabThere = state.tabThere;

    function parseExceptionCondition(exception) {
        const tabOne = CONDITION_1.find((o) => o?.value === parseInt(exception?.first))?.type || CONDITION_1[0].type;
        const tabTwo = CONDITION_2.find((o) => o?.value === parseInt(exception?.second))?.type || CONDITION_2[0].type;
        const tabThere = CONDITION_3.find((o) => o?.value === parseInt(exception?.third))?.type || CONDITION_3[0].type;

        return {
            tabOne,
            tabTwo,
            tabThere
        };
    }

    useEffect(() => {
        if (!isFistTime.current) {
            _changeSummary();
        }
    }, [activeTabOne, activeTabTwo, activeTabThere]);

    function _handleChangeTabOne(value) {
        dispatchState({ tabOne: value });
        isFistTime.current = false;
    }

    function _handleChangeTabTwo(value) {
        dispatchState({ tabTwo: value });
        isFistTime.current = false;
    }

    function _handleChangeTabThere(value) {
        dispatchState({ tabThere: value });
        isFistTime.current = false;
    }

    function _changeSummary() {
        const tempOne = CONDITION_1.find((o) => o?.type === activeTabOne)?.value;
        const tempTwo = CONDITION_2.find((o) => o?.type === activeTabTwo)?.value;
        const tempThere = CONDITION_3.find((o) => o?.type === activeTabThere)?.value;
        const summary = `${t(`jobDetail:except`)} ${t(`jobDetail:txt_${activeTabOne}`)} ${t(
            `jobDetail:txt_${activeTabTwo}`
        )} ${t(`jobDetail:txt_${activeTabThere}`)}`;

        const newException = {
            summary: summary,
            options: {
                ...EXCEPTON_OPTIONS,
                first: tempOne,
                second: tempTwo,
                third: tempThere
            }
        };
        onChangeException({ ...repeatSettings, exception: newException });
    }

    function _renderListCodition() {
        return CONDITION_1.map((item) => {
            const finalType = item.type;
            const isActive = activeTabOne === finalType;

            return (
                <button
                    key={finalType}
                    className={`tab-items ${isActive ? 'active-tab-selector' : ''}`}
                    type="button"
                    onClick={() => _handleChangeTabOne(finalType)}
                >
                    {t(`jobDetail:${finalType}`)}
                </button>
            );
        });
    }

    function _renderTabTwo() {
        return CONDITION_2.map((item) => {
            const finalType = item.type;
            const isActive = activeTabTwo === finalType;

            return (
                <button
                    key={finalType}
                    className={`tab-items ${isActive ? 'active-tab-selector' : ''}`}
                    type="button"
                    onClick={() => _handleChangeTabTwo(finalType)}
                >
                    {t(`jobDetail:${finalType}`)}
                </button>
            );
        });
    }

    function _renderTabThere() {
        return CONDITION_3.map((item) => {
            const finalType = item.type;
            const isActive = activeTabThere === finalType;

            return (
                <button
                    key={finalType}
                    className={`tab-items ${isActive ? 'active-tab-selector' : ''}`}
                    type="button"
                    onClick={() => _handleChangeTabThere(finalType)}
                >
                    {t(`jobDetail:${finalType}`)}
                </button>
            );
        });
    }

    return (
        <div className="tab-panel active">
            <div className="rows">
                <div className="txt no-icon"> Period </div>
                <div className="details d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">{_renderListCodition()}</div>
                    </div>
                    <div className="tabs detail-repeaton ml-1">
                        <div className="btn-item m-0">{_renderTabTwo()}</div>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="txt no-icon"> Every </div>
                <div className="details tabs">
                    <div className="btn-item m-0">{_renderTabThere()}</div>
                </div>
            </div>
        </div>
    );
};

export default Codition;
