import IconEye from 'assets/icon/IconEye';
import React from 'react';
import { useSelector } from 'react-redux';
import { getBranchId } from 'app/const/Branch';

const PreviewTemplateButton = ({ action }) => {
    const token = useSelector(({ auth }) => auth.token);
    const _handlePreview = () => {
        if (!action || !token) return;
        const form = document.createElement('form');

        form.action = action;
        form.method = 'POST';
        form.style.display = 'none';
        form.innerHTML = `
            <input name="token" value="${token}" >
            <input name="gd-branch-id" value="${getBranchId()}">
        `;

        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();
    };

    return (
        <div className="v2-btn-default has-icon" onClick={_handlePreview}>
            <IconEye />
            Preview
        </div>
    );
};

export default PreviewTemplateButton;
