import { call, put } from 'redux-saga/effects';
import { postWithToken, fetchWithToken } from 'common/utils/ApiUtils';

import { GET_LIST_SENDING_APPOINTMENT, SENDING_BROADCAST } from 'app/const/Api';

import { CALENDAR_BROADCAST_ACTIONS } from 'common/redux/actions/calendar/broadcastAction';

export function* calendarSendAction({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, GET_LIST_SENDING_APPOINTMENT, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getTotalBroadcast({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SENDING_BROADCAST, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: CALENDAR_BROADCAST_ACTIONS.CALENDAR_GET_TOTAL_BROADCAST_SUCCESS,
                payload: response
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* calendarSendBroadcast({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SENDING_BROADCAST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
