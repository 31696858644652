import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-container__form flex-column wrap-document-edit wrap-loading --devices">
            <div className="wrap-header">
                <div className="flex-betweenitems">
                    <div className="loading --animation --line --half" />
                    <div className="v2-btn-default --icon-lg loading bg-white-cultured" />
                </div>
            </div>
            <div className="wrap-body overflow-hidden">
                <div className="content-insert-variable">
                    <div className="box-use-variable flex-betweenitems py-3">
                        <div className="flex-1 mr-6">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable py-3">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --half mt-2" />
                        <div className="flex-betweenitems mt-4">
                            <div className="loading --fourth">
                                <div className="loading --animation --line --onefifth mb-2" />
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                            <div className="loading --fourth">
                                <div className="loading --animation --line --onefifth mb-2" />
                                <div className="field">
                                    <div className="loading v2-btn-default --grey --full">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable py-3">
                        <div className="loading --animation --line --twothird mb-4" />
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full my-2" />
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="mt-4 mb-2">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="field">
                            <div className="loading v2-btn-default --grey --full">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                <div className="loading --animation --line --threefourth mt-0 ml-2" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable py-3">
                        <div className="mb-3">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="wrap-content">
                            <div className="wrapbox-editor border-border-color-grey">
                                <div className="public-DraftEditor-content py-3">
                                    <div className="loading --animation --line --full" />
                                    <div className="loading --animation --line --full my-2" />
                                    <div className="loading --animation --line --twothird" />
                                </div>
                            </div>
                        </div>
                        <div className="my-3">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey --onefifth">
                            <div className="loading --animation --line --full my-1" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-footer">
                <div className="v2-btn-default loading --grey btn-sm">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --grey btn-sm ml-2">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
};

export default Loading;
