import GdRichtext from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';

const Edit = ({ onClose }, ref) => {
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const refRichtext = useRef(null);

    useImperativeHandle(ref, () => ({
        setValue: (newValue, isConcat = false) => {
            if (isConcat) {
                refRichtext.current?.setValue(refRichtext.current.getValue().concat(newValue));
            } else {
                refRichtext.current?.setValue(newValue);
            }
        }
    }));

    useEffect(() => {
        refRichtext.current.focus();
    }, []);

    function _handleSaveContent() {
        const finalValue = refRichtext.current.getValue();
        updateUnitDetailContextData({
            top_note: !finalValue || finalValue === '<br/>' ? '' : finalValue
        });
        onClose();
    }

    return (
        <GdRichtext
            ref={refRichtext}
            defaultContent={unitDetailContextData.top_note}
            wrapperClass="wrap-content is-editing"
            toolbarConfig={{
                left: [
                    {
                        group: 'emoji',
                        children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }]
                    },
                    {
                        group: 'style',
                        children: [
                            { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                            { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                            { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                            { type: TYPE_FUNCTION_RICHTEXT.LINK }
                        ]
                    },
                    {
                        group: 'list',
                        children: [{ type: TYPE_FUNCTION_RICHTEXT.UL }, { type: TYPE_FUNCTION_RICHTEXT.OL }]
                    }
                ],
                right: [
                    {
                        group: 'save_button',
                        children: [
                            {
                                type: TYPE_FUNCTION_RICHTEXT.SAVE_BUTTON,
                                props: { showCancel: true, onSubmit: _handleSaveContent, onCancel: onClose }
                            }
                        ]
                    }
                ]
            }}
        />
    );
};

export default forwardRef(Edit);
