import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import GDStatusBar from 'app/components/status/statusbar';
import { ERROR_CODE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { LIST_STATUS } from 'app/const/Status';
import { URL_EXPORT_STATUS_CHANGES } from 'app/const/api/Export';
import { REPORT_STATUS_CHANGES } from 'app/const/api/V2';
import { LIST_EXPORT } from 'app/const/report/Common';
import { STATUS_CHANGES_LIST_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import ErrorPage from 'app/modules/error';
import Export from 'app/modules/report/components/Export';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import FilterOpportunity from '../FilterOpportunity';
import TableStatusChanges from './TableStatusChanges';
import { LIST_OPTIONS_SELECT_DATE_DEFAULT, OPTION_SELECT_DATE_RANGE_PICKER } from 'app/const/drip/Common';
import { ACCESS_STATUS_CHANGES } from 'app/const/Permissions';

const StatusChanges = () => {
    const { t } = useTranslation(['report', 'common']);

    const refAlert = useRef(null);
    const refFilter = useRef(null);
    const refButtonUpdate = useRef(null);

    const reportType = REPORT_TYPE.STATUS_CHANGES;
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    const paramsStatusChanges = getLocalStorage(keyLocalStore);
    !paramsStatusChanges && setLocalStorage(keyLocalStore, getDefaultParams(reportType));

    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const isAccessOpportunity = !!opportunityAddon && checkPermission(permissionsList, ACCESS_STATUS_CHANGES);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        refreshScreen: 0,
        isDisable: false
    });

    const { data: finalData, isLoading, refreshScreen, isDisable } = state;

    useEffect(() => {
        if (!isAccessOpportunity) return;

        _fetchData();
    }, []);

    const _fetchData = () => {
        const params = getLocalStorage(keyLocalStore);
        if (!!params.currentPage) delete params.currentPage;

        dispatchState((prev) => ({ ...prev, isLoading: true }));

        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                data: data || [],
                isLoading: false,
                refreshScreen: prev.refreshScreen + 1
            }));
        };

        const _handleFail = ({ message, statusCode }) => {
            refAlert.current?.showStatusBar('status_changes', message, LIST_STATUS.ERROR);
            dispatchState({ isLoading: false, isDisable: statusCode === ERROR_CODE.UNPROCESSABLE });
        };

        clientQuery(
            REPORT_STATUS_CHANGES,
            {
                method: 'GET',
                data: {
                    ...params,
                    staffs: params.staffs?.toString()
                }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _toggleBtnUpdate = (value = true) => {
        refButtonUpdate.current && refButtonUpdate.current.classList[value ? 'add' : 'remove']('is-disable');
    };

    const _handleUpdate = () => {
        _toggleBtnUpdate(true);
        _fetchData();
    };

    if (!isAccessOpportunity) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <Fragment>
            <div className={classNames('nav-top header flexcenter', { 'is-disable': isDisable })}>
                <div className="header__left flex-1">
                    <ReportDateRangePicker
                        reportType={reportType}
                        onSelect={_handleUpdate}
                        listOptionsDateDefault={[
                            ...LIST_OPTIONS_SELECT_DATE_DEFAULT,
                            OPTION_SELECT_DATE_RANGE_PICKER.ALL_TIME
                        ]}
                    />
                    <FilterOpportunity
                        ref={refFilter}
                        reportType={reportType}
                        filters={STATUS_CHANGES_LIST_FILTER}
                        onSelect={() => _toggleBtnUpdate(false)}
                    />
                    <div ref={refButtonUpdate} className="header-items v2-btn-main is-disable" onClick={_handleUpdate}>
                        {t('common:update')}
                    </div>
                </div>
                <Export
                    title={t('report:records', { count: finalData.length || 0 })}
                    params={getLocalStorage(keyLocalStore)}
                    url={URL_EXPORT_STATUS_CHANGES}
                    pageExport={LIST_EXPORT.EXPORT_STATUS_CHANGES}
                    isDisable={isLoading}
                    refresh={refreshScreen}
                />
            </div>
            <div className="status-changes container-print scrolls">
                <div className="status-changes__container">
                    <div className="status-changes__title">
                        <div className="title-left flex-1">
                            <h3>{t('report:opportunities')}</h3>
                            <p className="title-desc">{t('report:desc_opportunity_status_changes')}</p>
                        </div>
                    </div>
                    <GDStatusBar ref={refAlert} />
                    <TableStatusChanges data={finalData} isLoading={isLoading} />
                </div>
            </div>
        </Fragment>
    );
};

export default StatusChanges;
