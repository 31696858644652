import React from 'react';
import { useTranslation } from 'react-i18next';

const TagsDisplay = ({
    data = [],
    isTagsSideMenu = false,
    isEditAccount = false,
    classRow = 'rows__info --has-tag',
    classWrapper = 'rows has-divider',
    btnAdd = () => {}
}) => {
    const { t } = useTranslation(['customers']);
    if (!data.length && !isEditAccount) return null;

    return (
        <div className={classWrapper}>
            {!isTagsSideMenu && !isEditAccount && <div className="rows__label">{t('customers:tags')}</div>}
            <div className={classRow}>
                {btnAdd()}
                {data.map((item) => (
                    <div key={item} className="tag-label tag cursor-default">
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TagsDisplay;
