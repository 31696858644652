export const TYPE_REPORT_REVENUE_CLIENT = {
    GET_LIST_MONTH_TAB: 'GET_LIST_REPORT_REVENUE_CLIENT_MONTH_TAB',
    GET_LIST_DETAIL_TAB: 'GET_LIST_REPORT_REVENUE_CLIENT_DETAIL_TAB',
    GET_LIST_YEAR: 'GET_LIST_YEAR'
};

// actions
export const getListYearRevenue = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_REVENUE_CLIENT.GET_LIST_YEAR,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByClientMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_CLIENT.GET_LIST_MONTH_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByClientDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_CLIENT.GET_LIST_DETAIL_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};
