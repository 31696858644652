import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import TimePicker from 'app/modules/jobdetail/components/time/picker';
import IconClose from 'assets/icon/IconClose';
import ReactModal from 'react-modal';
import IconVoicemail from 'assets/icon/IconVoicemail';
import VOIPVoicemailDrop from '../VOIPVoicemailDrop';
import ExtensionsAutoAttendant from './ExtensionsAutoAttendant';
import ButtonSave from 'app/components/button/ButtonSave';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_PHONE_NUMBER, AUTO_ATTENDANT_VOIP } from 'app/const/api/Voip';
import { VOIPAddonsLoadingAutoAttendant } from '../loadings/VOIPAddonsLoadingAutoAttendant';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import { useSelector } from 'react-redux';
import { convertTimeToNumber } from 'common/utils/TimeUtils';
import { EnabledNumbersAttendant } from './EnabledNumbersAttendant';
import IconTime from 'assets/icon/IconTime';
import { convertMinuteToTime } from 'common/utils/DateUtils';

const VOIPAddonsAutoAttendant = (props, ref) => {
    const { t } = useTranslation('addons');
    const refTimePickerFrom = useRef(null);
    const refTimePickerTo = useRef(null);
    const refExtension = useRef(null);
    const refButtonSave = useRef(null);
    const refVoicemail = useRef(null);
    const refAlert = useRef(null);

    const business_hours = useSelector(({ auth }) => auth.user.company.business_hours);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true,
        extensions: []
    });

    const {
        isVisible,
        isLoading,
        start: finalTimeStart,
        end: finalTimeEnd,
        extensions: finalExtensions,
        greeting: finalGreeting,
        greeting_text: finalGreetingText,
        number_ids: finalNumberIds
    } = state;

    useImperativeHandle(ref, () => ({
        setVisible: _setVisible
    }));

    useEffect(() => {
        isVisible && _fetchAutoAttendant();

        return () => {
            // eslint-disable-next-line no-undef
            global.listNumberVoip = [];
        };
    }, [isVisible]);

    const _setVisible = (value) => {
        dispatchState({ isVisible: value });
    };

    const _handleClose = () => {
        dispatchState({ isVisible: false, isLoading: true });
    };

    const _convertTimeToMinute = (time) => {
        const [hour, minute] = time.split(':');
        return parseInt(hour) * 60 + parseInt(minute);
    };

    const _fetchAutoAttendant = () => {
        Promise.all([
            clientQuery(AUTO_ATTENDANT_VOIP, { method: 'GET' }),
            clientQuery(ACTION_PHONE_NUMBER, { method: 'GET' })
        ]).then(([resAttendant, resNumber]) => {
            const {
                start = _convertTimeToMinute(business_hours.start),
                end = _convertTimeToMinute(business_hours.end),
                extensions = [],
                greeting = '',
                greeting_text = '',
                number_ids = []
            } = resAttendant?.data || {};

            // eslint-disable-next-line no-undef
            global.listNumberVoip = resNumber?.data || [];

            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                start,
                end,
                extensions,
                greeting,
                greeting_text,
                number_ids
            }));
        });
    };

    const _renderBoxHour = () => {
        return (
            <div className="box-st-hour border-bottom-grey-verylight-brown flex-column gap-8">
                <h3 className="flexcenter gap-8 black fs-14 svg-green">
                    <IconTime />
                    {t('time_frame')}
                </h3>

                <p>{t('desc_auto_attendant_settings')}</p>
                <div className="flex-betweenitems gap-24">
                    <div className="flex-1">
                        <div className="mb-1">{t('from')}</div>
                        <TimePicker
                            ref={refTimePickerFrom}
                            timeSelected={convertMinuteToTime(finalTimeStart)}
                            isCloseOnSelected
                        />
                    </div>
                    <div className="flex-1">
                        <div className="mb-1">{t('to')}</div>
                        <TimePicker
                            ref={refTimePickerTo}
                            timeSelected={convertMinuteToTime(finalTimeEnd)}
                            isCloseOnSelected
                        />
                    </div>
                </div>
            </div>
        );
    };

    const _renderBoxVoiceMail = () => {
        return (
            <div className="box-st-hour border-bottom-grey-verylight-brown flex-column gap-8">
                <h3 className="flexcenter gap-8 black fs-14">
                    <IconVoicemail isGreen />
                    {t('voicemail')}
                </h3>
                <p>{t('desc_voicemail_auto_attendant_settings')}</p>
                <div className="flex-column gap-8">
                    <VOIPVoicemailDrop
                        ref={refVoicemail}
                        greeting={finalGreeting}
                        greetingText={finalGreetingText}
                        msgError={t('auto_attendant_voicemail_is_required')}
                        isUseTyping
                        isUploadS3OnSuccess
                        onUploadFile={() => _handleDisableButtonSave(true)}
                        onUploadSuccess={() => _handleDisableButtonSave(false)}
                    />
                </div>
            </div>
        );
    };

    const _renderFooter = () => {
        return (
            <div className="footer-modal">
                <div className="v2-dropdown nav-call-module"></div>
                <div className="flex-1 flexcenter justify-end">
                    <div className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main" onSave={_handleSave}>
                        {t('save')}
                    </ButtonSave>
                </div>
            </div>
        );
    };

    const _handleSave = async () => {
        const _convertToMinute = (value) => {
            const { hour, midDay, minute } = value;
            return convertTimeToNumber(hour, minute, midDay);
        };
        const { greeting, greetingText, objectKey } = refVoicemail.current.getValue();

        const _handleSuccess = (res) => {
            const { message } = res;
            _handleShowStatus({
                message: !!message.length ? message : t('save_auto_attendant_successfully'),
                isSuccess: true
            });
            refButtonSave.current.setLoading(false);
        };

        const _handleFail = (err) => {
            _handleShowStatus(err);
            refButtonSave.current.setLoading(false);
        };

        clientQuery(
            AUTO_ATTENDANT_VOIP,
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    extensions: refExtension.current.getExtensions(),
                    start: _convertToMinute(refTimePickerFrom.current._getTime()),
                    end: _convertToMinute(refTimePickerTo.current._getTime()),
                    greeting: objectKey || greeting,
                    greeting_text: greetingText,
                    number_ids: finalNumberIds
                }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleShowStatus = ({ message, isSuccess = false }) => {
        refAlert.current.showStatusBar({
            id: 'auto_attendant',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    const _handleChangeEnableNumber = ({ id, value }) => {
        dispatchState((prev) => ({
            ...prev,
            number_ids: value ? [...prev.number_ids, id] : prev.number_ids.filter((item) => item !== id)
        }));
    };

    const _handleDeleteEnableNumber = (id) => {
        _handleChangeEnableNumber({ id, value: false });
    };

    const _handleDisableButtonSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    if (!isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen={isVisible}
            id="auto_attendant_form"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-extensions open"
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --preview-r --xl-plus">
                {isLoading ? (
                    <VOIPAddonsLoadingAutoAttendant />
                ) : (
                    <Fragment>
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('auto_attendant_settings')}</h3>
                            <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                                <IconClose />
                            </div>
                        </div>

                        <AlertCustomer ref={refAlert} />
                        <div className="body-modal p-0 scrolls">
                            {_renderBoxHour()}
                            <EnabledNumbersAttendant
                                number_ids={finalNumberIds}
                                onChange={_handleChangeEnableNumber}
                                onDelete={_handleDeleteEnableNumber}
                            />
                            {_renderBoxVoiceMail()}
                            <ExtensionsAutoAttendant ref={refExtension} extensions={finalExtensions} />
                        </div>

                        {_renderFooter()}
                    </Fragment>
                )}
            </div>
        </ReactModal>
    );
};

export default forwardRef(VOIPAddonsAutoAttendant);
