import { reducer } from 'app/const/Reducer';
import AddAttachment from 'app/modules/customer/components/AddAttachment';
import Attachment from 'app/modules/customer/components/Attachment';
import Notify from 'app/modules/customer/note/components/Notify';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

const BlockAttachment = (
    {
        itemId,
        typeAttachment,
        haveNotify = false,
        haveEditNotify = true,
        defaultNotify = null,
        defaultAttachments = [],
        onSetIsUploading = () => {}
    },
    ref
) => {
    const [state, dispatchState] = useReducer(reducer, {
        idsUpLoading: [],
        attachments: defaultAttachments.map((item) => ({ ...item, isDefault: true }))
    });
    const { attachments } = state;
    const refNotify = useRef(null);
    const refAddAttachment = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => attachments.filter((item) => (!item.isUploading && item.object_key) || item.isDefault),
        getNotify: () => {
            if (haveNotify) return refNotify.current.getValue() || [];
            return [];
        },
        clearAttachments: () => {
            dispatchState({ attachments: [] });
        },
        clearNotify: () => {
            haveNotify && refNotify.current.resetSelected();
        },
        uploadAttachments: (files) => {
            refAddAttachment.current.uploadAttachments(files);
        }
    }));

    const _handleAddNewAttachment = (newAttachment) => {
        dispatchState((prevState) => ({
            ...prevState,
            idsUpLoading: prevState.idsUpLoading.concat(newAttachment.id),
            attachments: prevState.attachments.concat(newAttachment)
        }));
        onSetIsUploading(true);
    };

    const _handleUpdateAttachment = ({ idAttach, object_key, object_tag, thumbnail_url, public_url }) => {
        dispatchState((prevState) => {
            const newAttachments = [...prevState.attachments];
            const index = newAttachments.findIndex((item) => item.id === idAttach);

            if (index >= 0) {
                const newIdsUploading = prevState.idsUpLoading.filter((id) => id !== idAttach);
                newAttachments[index]['isUploading'] = false;
                newAttachments[index]['object_key'] = object_key;
                newAttachments[index]['object_tag'] = object_tag;
                newAttachments[index]['url'] = public_url;
                newAttachments[index]['thumbnail_url'] = thumbnail_url;

                if (newIdsUploading.length === 0) onSetIsUploading(false);
                return {
                    ...prevState,
                    idsUpLoading: newIdsUploading,
                    attachments: newAttachments
                };
            }
            return prevState;
        });
    };

    const _handleDeleteAttachment = (idAttachDelete) => {
        dispatchState((prevState) => {
            return {
                ...prevState,
                attachments: [...prevState.attachments].filter((item) => item.id !== idAttachDelete)
            };
        });
    };

    return (
        <>
            <Attachment attachments={attachments || []} onDeleteAttachment={_handleDeleteAttachment} />
            <div className="wrap-content-notify">
                <AddAttachment
                    ref={refAddAttachment}
                    itemId={itemId}
                    onAddNew={_handleAddNewAttachment}
                    onUpdate={_handleUpdateAttachment}
                    onDelete={_handleDeleteAttachment}
                    type={typeAttachment}
                />
                {haveNotify && <Notify ref={refNotify} haveEditNotify={haveEditNotify} defaultNotify={defaultNotify} />}
            </div>
        </>
    );
};

export default forwardRef(BlockAttachment);
