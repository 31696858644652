export default function IconAddonApi({ isGreen = false }) {
    if (!isGreen) {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="#FFFFFF" fillOpacity="0.06"></rect>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26 9C26 10.6569 24.6569 12 23 12C22.2753 12 21.6106 11.743 21.092 11.3152L16.8965 14.9514C16.9633 15.1213 17 15.3064 17 15.5C17 15.5328 16.9989 15.5653 16.9969 15.5975L21.3971 17.4833C21.6712 17.1862 22.0638 17 22.5 17C23.3284 17 24 17.6716 24 18.5C24 19.3284 23.3284 20 22.5 20C21.6716 20 21 19.3284 21 18.5C21 18.4672 21.0011 18.4347 21.0031 18.4025L16.6029 16.5167C16.5351 16.5902 16.46 16.657 16.3788 16.7158L17.9333 22.0011C17.9554 22.0004 17.9777 22 18 22C19.1046 22 20 22.8954 20 24C20 25.1046 19.1046 26 18 26C16.8954 26 16 25.1046 16 24C16 23.2706 16.3905 22.6324 16.9738 22.283L15.4194 16.9979C15.1224 16.9821 14.8483 16.88 14.6218 16.7161L10.8772 19.3085C10.9566 19.524 11 19.7569 11 20C11 21.1046 10.1046 22 9 22C7.89543 22 7 21.1046 7 20C7 18.8954 7.89543 18 9 18C9.49991 18 9.95698 18.1834 10.3076 18.4866L14.0523 15.8941C14.0182 15.7685 14 15.6364 14 15.5C14 15.3493 14.0222 15.2038 14.0636 15.0666L9.26596 11.5484C8.92116 11.8307 8.48036 12 8 12C6.89543 12 6 11.1046 6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10C10 10.2623 9.94951 10.5128 9.85772 10.7423L14.6551 14.2604C14.7611 14.188 14.8769 14.1289 15 14.0854V10.9146C14.4174 10.7087 14 10.1531 14 9.5C14 8.67157 14.6716 8 15.5 8C16.3284 8 17 8.67157 17 9.5C17 10.1531 16.5826 10.7087 16 10.9146V14.0854C16.0842 14.1151 16.1649 14.1522 16.2414 14.1958L20.4368 10.5598C20.1597 10.1053 20 9.57128 20 9C20 7.34315 21.3431 6 23 6C24.6569 6 26 7.34315 26 9Z"
                    fill="#1C1F22"
                    fillOpacity="0.6"
                ></path>
            </svg>
        );
    } else {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="#FFFFFF"></rect>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26 9C26 10.6569 24.6569 12 23 12C22.2753 12 21.6106 11.743 21.092 11.3152L16.8965 14.9514C16.9633 15.1213 17 15.3064 17 15.5C17 15.5328 16.9989 15.5653 16.9969 15.5975L21.3971 17.4833C21.6712 17.1862 22.0638 17 22.5 17C23.3284 17 24 17.6716 24 18.5C24 19.3284 23.3284 20 22.5 20C21.6716 20 21 19.3284 21 18.5C21 18.4672 21.0011 18.4347 21.0031 18.4025L16.6029 16.5167C16.5351 16.5902 16.46 16.657 16.3788 16.7158L17.9333 22.0011C17.9554 22.0004 17.9777 22 18 22C19.1046 22 20 22.8954 20 24C20 25.1046 19.1046 26 18 26C16.8954 26 16 25.1046 16 24C16 23.2706 16.3905 22.6324 16.9738 22.283L15.4194 16.9979C15.1224 16.9821 14.8483 16.88 14.6218 16.7161L10.8772 19.3085C10.9566 19.524 11 19.7569 11 20C11 21.1046 10.1046 22 9 22C7.89543 22 7 21.1046 7 20C7 18.8954 7.89543 18 9 18C9.49991 18 9.95698 18.1834 10.3076 18.4866L14.0523 15.8941C14.0182 15.7685 14 15.6364 14 15.5C14 15.3493 14.0222 15.2038 14.0636 15.0666L9.26596 11.5484C8.92116 11.8307 8.48036 12 8 12C6.89543 12 6 11.1046 6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10C10 10.2623 9.94951 10.5128 9.85772 10.7423L14.6551 14.2604C14.7611 14.188 14.8769 14.1289 15 14.0854V10.9146C14.4174 10.7087 14 10.1531 14 9.5C14 8.67157 14.6716 8 15.5 8C16.3284 8 17 8.67157 17 9.5C17 10.1531 16.5826 10.7087 16 10.9146V14.0854C16.0842 14.1151 16.1649 14.1522 16.2414 14.1958L20.4368 10.5598C20.1597 10.1053 20 9.57128 20 9C20 7.34315 21.3431 6 23 6C24.6569 6 26 7.34315 26 9Z"
                    fill="#35B36D"
                ></path>
            </svg>
        );
    }
}
