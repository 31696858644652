export const TYPE_REPORT_TOTAL_SALES = {
    GET_LIST: 'GET_LIST_REPORT_TOTAL_SALES',
    GET_LIST_SERVICE_FILTER_REQUEST: 'GET_LIST_SERVICE_FILTER_REQUEST',
    GET_LIST_CHART: 'GET_LIST_DATA_CHART_TOTAL_SALES',
    GET_LIST_YEAR: 'GET_LIST_YEAR_TOTAL_SALES'
};

export const getListReportTotalSales = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_TOTAL_SALES.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListServiceFilterRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_TOTAL_SALES.GET_LIST_SERVICE_FILTER_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const getDataChartTotalSales = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_TOTAL_SALES.GET_LIST_CHART,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListYearTotalSales = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_TOTAL_SALES.GET_LIST_YEAR,
        actionSuccess,
        actionFailed
    };
};
