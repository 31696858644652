import { forwardRef, useReducer, useEffect, useRef, useImperativeHandle } from 'react';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconClose from 'assets/icon/IconClose';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconLoading from 'assets/icon/IconLoading';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import PinToTop from 'app/modules/jobdetail/tabs/materials/components/PinToTop';
import { handleStickyMaterial } from 'common/utils/FunctionUtils';

/**
 * options = {origin: Array, list: Array}
 */
const GdAutoComplete = forwardRef(
    (
        {
            classWrapper,
            isDropDownIcon,
            name,
            options,
            defaultValue,
            onSelect,
            limit,
            removeTag,
            removeAllTags,
            openOptions,
            isFirstTimeFetch,
            showClose = true,
            isPinToTop = false,
            onAddSticky = () => {}
        },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            dropDownVisible: false,
            data: options
        });
        const refDropDown = useRef(null);

        const { dropDownVisible, data: finalData } = state;

        useEffect(() => {
            if (dropDownVisible) {
                document.addEventListener('click', handleClickOutside, true);
                document.addEventListener('keydown', handleHideDropdown, true);
            } else {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            }
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            };
        }, [dropDownVisible]);

        const _handleOnSelect = (name, value) => {
            onSelect(name, value);
            showClose && _closeDropdown();
        };

        useImperativeHandle(ref, () => ({
            _closeDropdown
        }));

        const handleHideDropdown = (event) => {
            const elPrevent = document.getElementById('show_list_dropdown');
            if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
                _closeDropdown();
            }
        };

        const handleClickOutside = (event) => {
            const elPrevent = document.getElementById('show_list_dropdown');
            if (
                refDropDown.current &&
                elPrevent &&
                !elPrevent.contains(event.target) &&
                !refDropDown.current.contains(event.target)
            ) {
                _closeDropdown();
            }
        };

        const _openDropdown = (e) => {
            e.stopPropagation();
            if (!dropDownVisible && isFirstTimeFetch) {
                openOptions();
            }
            dispatchState({ dropDownVisible: !dropDownVisible });
        };

        const _closeDropdown = () => {
            dropDownVisible && dispatchState({ dropDownVisible: false });
        };

        const _handleRemoveTag = (e, value) => {
            e && e.stopPropagation();
            removeTag(name, value);
        };

        const _handleRemoveAllTags = (e) => {
            e && e.stopPropagation();
            removeAllTags(name);
        };

        const _handleSticky = (sticky, id) => {
            const { ids, list } = handleStickyMaterial(id, sticky, finalData);
            onAddSticky(ids, name);
            dispatchState({
                data: list
            });
        };

        const _renderOptionList = () => {
            if (isFirstTimeFetch)
                return (
                    <li className="items">
                        <IconLoading />
                    </li>
                );

            return finalData.map((item) => {
                const selected = defaultValue.find((value) => value === item.id);
                return (
                    <li
                        key={item.id}
                        className={classNames('items', {
                            active: selected,
                            'justify-space-between': isPinToTop
                        })}
                        onClick={() => {
                            _handleOnSelect(name, item.id);
                        }}
                        tabIndex="0"
                    >
                        <span
                            className={classNames('tag-label', {
                                'txt-ellipsis': !isPinToTop
                            })}
                        >
                            {item.name}
                        </span>
                        {isPinToTop && (
                            <PinToTop sticky={item.sticky} onPin={(sticky) => _handleSticky(sticky, item.id)} />
                        )}
                    </li>
                );
            });
        };

        const _renderListTagsSelected = (list) => {
            if (!Array.isArray(list) || list?.length === 0) return null;

            return list.map((item, index) => {
                const itemSelected = options.find((row) => row.id === item);

                return (itemSelected && index < limit) || (!limit && itemSelected) ? (
                    <div key={item} className="tag-label">
                        <span className="tag-label__ellipsis">{itemSelected.name}</span>
                        <div
                            onClick={(e) => {
                                _handleRemoveTag(e, item);
                            }}
                            className="svg-remove black"
                        >
                            <IconClose isSmall />
                        </div>
                    </div>
                ) : null;
            });
        };
        const numberSelected = defaultValue?.length;

        return (
            <div
                ref={refDropDown}
                className={`${classWrapper ? classWrapper : 'v2-dropdown'}  ${dropDownVisible ? 'active' : ''}`}
            >
                <div onClick={(e) => _openDropdown(e)} className="dropbtn items group-tags has-search" tabIndex="0">
                    <div className="box-tags">
                        {_renderListTagsSelected(defaultValue)}
                        {numberSelected > limit && !!limit && (
                            <div className="tag-label tag-more"> +{`${numberSelected - limit}`} </div>
                        )}
                        <input type="text" className="select-input-tag" readOnly />
                        {numberSelected > 0 ? (
                            <div
                                onClick={(e) => {
                                    _handleRemoveAllTags(e);
                                }}
                                className="close-tags"
                            >
                                <IconCircleClose />
                            </div>
                        ) : null}
                    </div>
                    {isDropDownIcon && (
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    )}
                </div>
                <div
                    id="show_list_dropdown"
                    className={classNames('v2-dropdown__menu scrolls', {
                        'disable-anchor': isPinToTop
                    })}
                >
                    <ul>{_renderOptionList()}</ul>
                </div>
            </div>
        );
    }
);

export default GdAutoComplete;
