import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { formatDateDashboard } from 'common/utils/DateUtils';
import { formatNumberWithCommas, transformToCurrency } from 'common/utils/NumberUtils';

const WidgetsInfo = ({
    dataWidget,
    isTransform = true,
    startDate = null,
    endDate = null,
    isShowLabel = true,
    isNotUseClassNameRow = false
}) => {
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const { total = null, value = null, description = null, data = [] } = dataWidget;

    const _checkRenderContent = (inputValue) => {
        if (!isTransform) return inputValue;

        if (typeof inputValue === 'string') inputValue = parseFloat(inputValue.replaceAll(',', ''));

        return transformToCurrency(inputValue, currency);
    };

    const _renderInfoLabel = () => {
        if (!isShowLabel) return null;

        const _renderData = () => {
            const totalCount = formatNumberWithCommas(data.reduce((sum, { count }) => sum + count, 0));

            return data.map(({ label, percent, value, color, count }, index) => {
                const valueRender = _checkRenderContent(value);
                const percentRender = `${percent}%`;
                const finalActualTotalRender = `${formatNumberWithCommas(count)}/${totalCount}`;

                return (
                    <div className="rows" key={index.toString()}>
                        <div className="rows__label">
                            <i className="dots-color" style={{ background: color }} />
                            <span className="txt-ellipsis text-capitalize" style={{ color }} title={label}>
                                {label}
                            </span>
                        </div>
                        <p className="rows__percent --label-r txt-ellipsis" title={percentRender}>
                            {percentRender}
                        </p>
                        <p className="rows__job --label-r txt-ellipsis" title={finalActualTotalRender}>
                            {finalActualTotalRender}
                        </p>
                        <p className="rows__price --label-r txt-ellipsis" title={valueRender}>
                            {valueRender}
                        </p>
                    </div>
                );
            });
        };

        if (isNotUseClassNameRow) {
            return _renderData();
        }

        return (
            <div className="rows">
                <div className="wrap-rows">{_renderData()}</div>
            </div>
        );
    };

    const _renderTotal = () => {
        const _renderDesc = () => {
            if (!!description) return <p className="fs-13 txt-ellipsis">{description}</p>;

            if (!!startDate && !!endDate)
                return <p className="fs-13 txt-ellipsis">{formatDateDashboard(startDate, endDate)}</p>;

            return null;
        };

        return (
            <div className="is-summary">
                <div className="rows">
                    <div className="col">
                        <div className="flexcenter">
                            <p className="fs-26 txt-ellipsis">{_checkRenderContent(total)}</p>
                            {!!value && (
                                <Fragment>
                                    <span className="dot" />
                                    <p className="fs-26 txt-ellipsis">{value}</p>
                                </Fragment>
                            )}
                        </div>
                        {_renderDesc()}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            {_renderTotal()}
            {_renderInfoLabel()}
        </Fragment>
    );
};

export default WidgetsInfo;
