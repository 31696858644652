import GdButton from 'app/components/button';
import { customerCreateCredit } from 'app/const/Api';
import { COMMON, LIST_STATUS, REGEX_NUMBER, TODAY } from 'app/const/App';
import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import CreditPaymentMethod from 'app/modules/customer/detail/credits/components/CreditPaymentMethod';
import { LIST_PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { clientQuery } from 'common/utils/ApiUtils';
import moment from 'moment';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import IconClose from 'assets/icon/IconClose';
import { roundingNumber } from 'common/utils/NumberUtils';
import { reducer } from 'app/const/Reducer';

const CreditForm = forwardRef(({ onCreateSuccess, onCreateFail }, ref) => {
    const initState = {
        isLoading: false,
        isOpen: false,
        data: {
            id: null,
            amount: '',
            date: TODAY,
            memo: '',
            check_number: '',
            check_value: ''
        }
    };

    const { t } = useTranslation(['customers']);
    const { customer } = useContext(CustomerDetailContext);
    const [state, dispatchState] = useReducer(reducer, initState);
    const { id } = useParams();
    const refAlert = useRef(null);
    const dataCredit = state.data;
    const dateFormat = moment(dataCredit.date).format('YYYY/MM/DD');
    const methodName = dataCredit?.method?.name || LIST_PAYMENT_METHODS[1].name;
    const refAmount = useRef(null);

    useImperativeHandle(ref, () => ({
        showModal,
        hideModal
    }));
    const showModal = (dataCredit = initState.data) => {
        dispatchState({
            data: dataCredit,
            isLoading: false,
            isOpen: true
        });
    };

    const hideModal = () => {
        dispatchState({ data: {}, isOpen: false });
    };

    const _handleSelect = (value) => {
        dispatchState({ data: { ...state.data, method: value, memo: '', check_number: '' } });
    };

    const _handleChange = (e, field) => {
        const value = e.target.value;
        if (field === COMMON.AMOUNT && value !== '' && !REGEX_NUMBER.test(value)) {
            return false;
        }
        dispatchState({ data: { ...state.data, [field]: value } });
    };

    const _handleBlur = (e, field) => {
        const value = e.target.value;
        refAmount.current.value = roundingNumber(value);
        dispatchState({ data: { ...state.data, [field]: value } });
    };

    const _handleSelectDate = (date) => {
        dispatchState({ data: { ...state.data, date: date } });
    };

    const _handleSave = () => {
        dispatchState({ isLoading: true });
        const amount = dataCredit.amount || 0;
        if (isNaN(amount))
            return refAlert.current.showStatusBar(
                {
                    id: LIST_STATUS.ERROR,
                    message: t('customers:error_submit_form_credit'),
                    type: LIST_STATUS.ERROR
                },
                dispatchState({ isLoading: false })
            );
        if (Number(amount) < 0)
            return refAlert.current.showStatusBar(
                {
                    id: LIST_STATUS.ERROR,
                    message: t('customers:amount_is_not_negative_value'),
                    type: LIST_STATUS.ERROR
                },
                dispatchState({ isLoading: false })
            );

        clientQuery(
            customerCreateCredit(id),
            {
                data: {
                    ...dataCredit,
                    amount,
                    date: dateFormat,
                    method_name: methodName
                },
                method: 'POST'
            },
            ({ data, message }) => onCreateSuccess({ ...data }, message),
            ({ message }) => onCreateFail(message),
            null
        );
    };

    const _renderHeader = () => {
        return (
            <div className="header-modal">
                <h3 className="header-modal__label">{t('customers:new_credit')}</h3>
                <div className="v2-btn-default --icon-lg --transparent" onClick={hideModal}>
                    <IconClose />
                </div>
            </div>
        );
    };

    const _renderFooter = () => {
        return (
            <div className="footer-modal">
                <span className="v2-btn-default --noborder --label" onClick={hideModal}>
                    {t('customers:cancel')}
                </span>
                <GdButton
                    title={t('customers:save')}
                    isLoading={state.isLoading}
                    onClick={_handleSave}
                    className="v2-btn-main"
                />
            </div>
        );
    };

    return (
        <ReactModal
            id="addCredit"
            isOpen={state.isOpen}
            className="modal container-modal --credit open"
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            onRequestClose={hideModal}
        >
            <div className="modal__overlay bg-fixed" onClick={hideModal} />
            <div className="modal__container">
                <AlertCustomer ref={refAlert} />
                {_renderHeader()}
                <div className="body-modal">
                    <div className="has-form has-sm-datepicker">
                        <div className="rows --customer-name">
                            <div className="txt">{t('customers:customer')}</div>
                            <div className="field-input">{customer.full_name}</div>
                        </div>
                        <div className="rows mt-3">
                            <div className="width-xs">
                                <div className="txt">{t('customers:amount')}</div>
                                <div className="cost-input --right" data-currency-unit="$">
                                    <input
                                        ref={refAmount}
                                        className="field-input"
                                        type="number"
                                        placeholder={t('customers:amount')}
                                        onBlur={(e) => {
                                            _handleBlur(e, COMMON.AMOUNT);
                                        }}
                                        defaultValue={dataCredit.amount}
                                        autoFocus
                                    />
                                </div>
                            </div>
                            <div className="date-box width-xs">
                                <div className="txt">{t('customers:date')}</div>
                                <CalendarDatePicker selected={dataCredit.date} onChange={_handleSelectDate} />
                            </div>
                        </div>
                        <CreditPaymentMethod
                            isOpen={state.isOpen}
                            defaultCheckNumber={dataCredit.check_number}
                            defaultCheckValue={dataCredit.check_value}
                            defaultMemo={dataCredit.memo}
                            onSelect={_handleSelect}
                            onChange={_handleChange}
                            defaultMethod={dataCredit.method || LIST_PAYMENT_METHODS[1]}
                        />
                    </div>
                </div>
                {_renderFooter()}
            </div>
        </ReactModal>
    );
});

export default CreditForm;
