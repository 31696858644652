import React, { useRef, useEffect, useReducer } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconClose from 'assets/icon/IconClose';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateMaterialWithService } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';

const UpdateMaterial = ({ serviceId }) => {
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const refButtonSave = useRef();
    const refStatusBar = useRef();
    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        if (event.keyCode === KEY_CODE_ESCAPE) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleUpdateMaterial() {
        const optionsQuery = { method: 'PUT' };
        clientQuery(
            updateMaterialWithService(serviceId),
            optionsQuery,
            _handleUpdateMaterialSuccess,
            _handleUpdateMaterialFailed
        );
    }

    function _handleUpdateMaterialSuccess(response) {
        refStatusBar.current.showStatusBar('error', response?.message?.toString() || 'Success', LIST_STATUS.SUCCESS);
        _removeLoading();
    }

    function _handleUpdateMaterialFailed(response) {
        refStatusBar.current.showStatusBar(
            'error',
            response?.message?.toString() || 'Please try again',
            LIST_STATUS.ERROR
        );
        _removeLoading();
    }

    function _removeLoading() {
        refButtonSave.current.removeLoading();
    }

    function _renderModalConfirm() {
        if (!finalIsVisible) {
            return false;
        }
        return (
            <div className="modal container-modal open">
                <div className="modal__overlay bg-fixed" onClick={_closeSearchResult} />
                <div className="modal__container large">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">Update Materials</h3>
                        <span onClick={_closeSearchResult} className="v2-btn-default --icon-lg --transparent ml-0">
                            <IconClose />
                        </span>
                    </div>
                    <div className="body-modal scrolls">
                        <StatusBar ref={refStatusBar} />
                        <div className="alert --success dp-hide">
                            <p className="alert__description fs-13">
                                Materials for this service have been updated successfully.
                            </p>
                        </div>
                        <p>
                            Are you sure you want to update materials for all Active Jobs? This will not effect past
                            Completed or Canceled jobs.
                        </p>
                    </div>
                    <div className="footer-modal btn-close justify-end">
                        <span onClick={_closeSearchResult} className="v2-btn-default --transparent mr-2" tabIndex="0">
                            Cancel
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            onSave={_handleUpdateMaterial}
                            title={' Yes. Update all my active jobs with new materials.'}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            <p onClick={(e) => _handleOpen(e)} className="v2-btn-default mr-2" tabIndex="0">
                Update Materials for All Active Jobs
            </p>
            {_renderModalConfirm()}
        </>
    );
};

export default UpdateMaterial;
