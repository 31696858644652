import classNames from 'classnames';
import React, { Fragment } from 'react';
import IconLoadingChart from 'assets/icon/IconLoadingChart';

const LoadingChart = ({ isLoadingLineAndPieChart = false }) => {
    return (
        <Fragment>
            <div
                className={classNames('content-l chart-area loading --animation', {
                    'w-100': !isLoadingLineAndPieChart
                })}
            >
                <IconLoadingChart />
            </div>
            {isLoadingLineAndPieChart && (
                <div className="content-r d-flex justify-center">
                    <div className="chart-circle"></div>
                </div>
            )}
        </Fragment>
    );
};

export default LoadingChart;
