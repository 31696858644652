export const EMAIL_TEMPLATE = {
    ACTION_GET_LIST_EMAIL_TEMPLATE: 'ACTION_GET_LIST_EMAIL_TEMPLATE',
    ACTION_GET_LIST_EMAIL_TEMPLATE_SUSSESS: 'ACTION_GET_LIST_EMAIL_TEMPLATE_SUSSESS',
    UPDATE_EMAIL_TEMPLATE_SUCCESS: 'UPDATE_EMAIL_TEMPLATE_SUCCESS'
};

export const actionGetEmailTemplate = (params, actionSuccess, actionFailed) => {
    return {
        type: EMAIL_TEMPLATE.ACTION_GET_LIST_EMAIL_TEMPLATE,
        params,
        actionSuccess,
        actionFailed
    };
};
export const actionUpdateEmailTemplate = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_SUCCESS,
        params,
        actionSuccess,
        actionFailed
    };
};
