import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import GDDropdownWithSearch from 'app/components/dropdown/DropdownWithSearch';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { COLUMNS_OPTIONS } from '../../constants/columns';
import { OBJECT_TYPES } from '../../constants/types';
import { useSmartView } from '../../context';
import { getColumnsSmartView } from '../table/utils';
import { filterSortOptions } from '../utils';

const FilterColumns = () => {
    const { columns, handleApplyColumns } = useSmartView();

    const { t } = useTranslation('smartView');
    const settings = useSelector(({ auth }) => auth.user.settings);
    const [state, dispatchState] = useReducer(reducer, {}, () => ({ columns: columns || [] }));
    const { columns: columnsLocalState } = state;

    const refDataOptions = useRef(COLUMNS_OPTIONS);
    const refDropdown = useRef(null);
    const isActivateVoip = checkIsEnableVoip(settings, false);

    useEffect(() => {
        const dataColumns = getColumnsSmartView(columns);
        dispatchState((prevState) => ({ ...prevState, columns: dataColumns }));
        refDataOptions.current = dataColumns;
    }, [columns]);

    const _handleSearch = (value) => {
        dispatchState((prevState) => ({ ...prevState, columns: filterSortOptions(refDataOptions.current, value) }));
    };

    const _handleHideDropdown = () => {
        dispatchState((prevState) => ({ ...prevState, columns: refDataOptions.current }));
    };

    const _handleUpdateSelect = (data, itemUpdate) => {
        const newColumns = data.map((item) => {
            if (item.objectType === itemUpdate.objectType) {
                return {
                    ...item,
                    options: item.options.map((option) => {
                        if (option.value === itemUpdate.fieldId) return { ...option, checked: !option?.checked };
                        return option;
                    })
                };
            }
            return item;
        });

        return newColumns;
    };

    const _handleSelect = (data) => {
        dispatchState((prevState) => {
            const newColumns = _handleUpdateSelect([...prevState.columns], data);
            refDataOptions.current = _handleUpdateSelect([...refDataOptions.current], data);
            return { ...prevState, columns: newColumns };
        });
    };

    const _handleApply = () => {
        const checkedValues = [];
        refDataOptions.current.forEach((item) => {
            item.options.forEach((option) => {
                if (option.checked === true) checkedValues.push({ field_id: option.value, type_id: option.objectType });
            });
        });

        handleApplyColumns(checkedValues);
        refDropdown.current?.close();
    };

    const finalColumns = () => {
        const result = columnsLocalState.filter((item) => {
            if (item.objectType === OBJECT_TYPES.CALL && !isActivateVoip) return false;
            if (item.objectType === OBJECT_TYPES.SMS_MESSAGES && !settings?.addons?.smsplivo) return false;
            return true;
        });
        return result;
    };

    return (
        <GDDropdownWithSearch
            id="smart_view_columns"
            isCheckbox
            ref={refDropdown}
            placeholder={t('search_placeholder')}
            customDropButton={() => (
                <>
                    <p className="txt-ellipsis">{t('columns')}</p>
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </>
            )}
            buttonClassName="dropbtn v2-btn-default --sm --icon-r fs-13"
            options={finalColumns()}
            onSearch={_handleSearch}
            onHide={_handleHideDropdown}
            onSelect={_handleSelect}
            onApply={_handleApply}
        />
    );
};

FilterColumns.displayName = 'FilterColumnsSmartView';
export default FilterColumns;
