import React from 'react';

import { WithTitle } from 'app/components/routes/RouteWithSubRoutes';
import 'app/modules/customer/css/customer.scss';
import { Route } from 'react-router-dom';

function CustomerImportLayout({ routes = [], ...props }) {
    return (
        <div className="container-wrap">
            <div className="contents-pages customer-review-column h-100">
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    return (
                        <Route
                            key={i}
                            path={result.path}
                            exact
                            render={() => (
                                <WithTitle title={route.title}>
                                    <route.component {...props} />
                                </WithTitle>
                            )}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default CustomerImportLayout;
