import React from 'react';

const LoadingPhoneList = () => {
    return (
        <div className="wrap-toggle-phone v2-dropdown wrap-loading">
            <div className="dropbtn items group-tags --label gap-8 pointer-events-none">
                <div className="loading --animation --line --fourth"></div>
            </div>
        </div>
    );
};

export default LoadingPhoneList;
