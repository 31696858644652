import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { TYPE_ACCOUNT } from 'app/const/Account';
import { UPDATE_QUEST_LOG_OPTION } from 'app/const/api/V2';
import { ID_QUEST_LOG, ID_SWITCH_VERSION } from 'app/const/Header';
import { GORILLA_CURRENT_DESKTOP, GORILLA_DESK_TRAINING_CALL_LINK } from 'app/const/Route';
import { helpOptions } from 'app/const/StaticLinks';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { ACCESS_TOKEN } from 'app/const/App';
import { updateSettings } from 'common/redux/actions/authAction';
import { useHistory } from 'react-router-dom';
import { addBranchPath } from 'app/const/Branch';

function Help({ onHandleChangeQuestLog = () => {}, isExpired = false }) {
    const { t } = useTranslation(['header', 'common']);
    const dispatch = useDispatch();
    const history = useHistory();
    const questLog = useSelector(({ auth }) => auth?.user?.settings?.questlog?.question_active);
    const userStatus = useSelector((state) => state?.auth?.user?.profile?.plan?.status || '');
    const refDropdown = useRef(null);
    const refValue = useRef(!!questLog);

    const _handleOpenLink = ({ link, isNewTab = true }) => {
        if (!isNewTab) {
            history.push(addBranchPath(link));
            refDropdown.current?._closeDropdown();
            return;
        }

        const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const _handleQuestLog = (checked) => {
        const value = checked ? 1 : 0;
        clientQuery(UPDATE_QUEST_LOG_OPTION, { data: { value }, method: 'PUT' });
        dispatch(updateSettings({ questlog: { question_active: value } }));
        onHandleChangeQuestLog(checked);
        refValue.current = checked;
    };

    const _switchToClassic = () => {
        window.open(`${GORILLA_CURRENT_DESKTOP}/token/${getLocalStorageValue(ACCESS_TOKEN)}`, '_self');
    };

    const renderList = () => {
        return helpOptions.map((item, index) => {
            const isUserTrial = userStatus === TYPE_ACCOUNT.FREE_TRIAL;

            if (isUserTrial && item.isHideTrial) return null;

            if (item.type === 'line') {
                if (helpOptions[index - 1]?.id === ID_QUEST_LOG && isExpired) return null;
                return <li key={item.id} className={item.type} />;
            }
            const _handleClick = () => {
                if (item.id === ID_SWITCH_VERSION) return _switchToClassic();
                if (item.id !== 'chat_with_support') return _handleOpenLink(item);

                window.Intercom('boot');
                window.Intercom('show');
            };

            if (!isUserTrial) {
                if (item.id === 'book-s-sales-call') {
                    item.id = 'book_a_training_call';
                    item.link = GORILLA_DESK_TRAINING_CALL_LINK;
                }
            }

            if (item.id === ID_QUEST_LOG) {
                if (isExpired) return null;
                return (
                    <li key={item.id}>
                        <div className="items has-icon cursor-default">
                            {item.icon}
                            <span className="flex-1">{t('header:questlog')}</span>
                            <GdSwitchCheckbox
                                id={item.id}
                                textChecked={t('common:on')}
                                textUnChecked={t('common:off')}
                                inputClassName="toggle toggle-round"
                                defaultChecked={() => refValue.current}
                                onChange={_handleQuestLog}
                            />
                        </div>
                    </li>
                );
            }

            return (
                <li key={item.id} onClick={_handleClick} className="items has-icon">
                    {item.icon}
                    <p className="txt-ellipsis">{t(`header:${item?.id}`)}</p>
                </li>
            );
        });
    };

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="help_options_dropdown"
            wrapperClassName="nav-help"
            wrapperListClass="v2-dropdown__menu --right"
            buttonClassName="dropbtn v2-btn-default --sm --icon-r fs-13 tooltip"
            customDropButton={() => (
                <>
                    <span className="txt-ellipsis">{t('help')}</span>
                    <p className="tooltiptext bottom">{t('help')}</p>
                    <span className="arrow">
                        <IconArrowDown />
                    </span>
                </>
            )}
        >
            <ul>{renderList()}</ul>
        </CalendarDropdown>
    );
}

export default Help;
