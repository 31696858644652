export const EVENT_CUSTOM_NAME = {
    UPDATE_WORK_POOL: 'update_work_pool'
};

export const MAPBOX_DRAW_SETTINGS = {
    displayControlsDefault: false,
    controls: { polygon: false, trash: false },
    styles: [
        // ACTIVE (being drawn)
        // line stroke
        {
            id: 'gl-draw-line',
            type: 'line',
            filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#1E7EF7',
                'line-dasharray': [0.2, 2],
                'line-width': 2
            }
        },
        // polygon fill
        {
            id: 'gl-draw-polygon-fill',
            type: 'fill',
            filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            paint: {
                'fill-color': '#1E7EF7',
                'fill-outline-color': '#1E7EF7',
                'fill-opacity': 0.2
            }
        },

        // polygon outline stroke
        // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
        {
            id: 'gl-draw-polygon-stroke-active',
            type: 'line',
            filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
            layout: {
                'line-cap': 'round',
                'line-join': 'round'
            },
            paint: {
                'line-color': '#1E7EF7',
                'line-dasharray': [0.2, 2],
                'line-width': 2
            }
        },
        // vertex point halos
        {
            id: 'gl-draw-polygon-and-line-vertex-halo-active',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
                'circle-radius': 8,
                'circle-color': '#FFF'
            }
        },
        // vertex points
        {
            id: 'gl-draw-polygon-and-line-vertex-active',
            type: 'circle',
            filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
            paint: {
                'circle-radius': 5,
                'circle-color': '#1E7EF7'
            }
        }
    ]
};

export const TYPES_MAP_SEARCH = {
    GOOGLE: 'google',
    GORILLA: 'gorilla'
};

export const MAP_SEARCH_LIMIT = 20;
export const MAP_SEARCH_POINT = 'map_search_point';
export const MAP_USER_SCHEDULE = 'map_user_schedule';
export const MAP_LOCATION = 'map_location';
