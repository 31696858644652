import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_REVENUE_BY_SERVICE_MONTH_GET_LIST,
    REPORT_REVENUE_BY_SERVICE_DETAIL_GET_LIST,
    REPORT_REVENUE_SERVICE_METHOD_FILTER,
    REPORT_REVENUE_SERVICE_SERVICES_FILTER
} from 'app/const/Api';

export function* getListReportRevenueByServiceMonth({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_SERVICE_MONTH_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_customer');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportRevenueByServiceDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_SERVICE_DETAIL_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_payment');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterRevenueByService({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_SERVICE_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListServiceFilterRevenueByService({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_SERVICE_SERVICES_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
