import GdGridView from 'app/components/grid/GdGridView';
import { getUrlGetSessionList } from 'app/const/Api';
import { KEY_REPORT_LOCAL_STORAGE, MODES_HANDLE_SETTING_USERS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { useImperativeHandle, forwardRef, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { COLUMN_SESSION } from 'app/modules/settings/users/const';

const SessionList = ({ onChangeMode = () => {}, onSignOut = () => {}, userId = '' }, ref) => {
    const { t } = useTranslation();
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.USER_SESSION_LIST);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        sessionList: [],
        totalPage: 1
    });
    const { sessionList, isLoading, totalPage } = state;

    useImperativeHandle(ref, () => ({ _updateStatusActive }));

    useEffect(() => {
        const paramsReport = getDefaultParams(REPORT_TYPE.USER_SESSION_LIST);
        setLocalStorage(keyLocal, paramsReport);
        _getSessionList(paramsReport);
        return () => removeLocalStorage(keyLocal);
    }, []);

    const _updateStatusActive = (sessionId) => {
        dispatchState({
            sessionList: sessionList.map((item) => {
                if (item.id === sessionId) return { ...item, active: 0 };
                return item;
            })
        });
    };

    const _handleClick = (item) => {
        onSignOut(item);
    };

    const _getSessionList = (params) => {
        dispatchState({ isLoading: true });
        const _success = ({ data, total }) => {
            dispatchState({ isLoading: false, sessionList: data, totalPage: Math.ceil(total / params.limit) });
        };

        const _fail = () => {
            dispatchState({ isLoading: false });
        };

        clientQuery(getUrlGetSessionList(userId), { method: 'GET', data: params }, _success, _fail);
    };

    const _handleUpdate = () => {
        _getSessionList(getLocalStorage(keyLocal));
    };

    return (
        <div className="wrap-session-history container-print flex-column">
            <div className="wrap-session-history__header d-flex">
                <div className="flex-1">
                    <div
                        className="v2-btn-default has-icon --transparent"
                        onClick={() => onChangeMode(MODES_HANDLE_SETTING_USERS.PREVIEW)}
                    >
                        <IconArrow isPrev />
                        {t('report:back')}
                    </div>
                </div>
                <ReportDateRangePicker onSelect={_handleUpdate} reportType={REPORT_TYPE.USER_SESSION_LIST} />
            </div>
            <GdGridView
                isLoading={isLoading}
                classTable="tables-visible"
                content={sessionList}
                fileTranslation="setting"
                handleClick={_handleClick}
                {...COLUMN_SESSION}
                isScroll
            />
            <ReportPagination
                reportType={REPORT_TYPE.USER_SESSION_LIST}
                totalPage={totalPage}
                onSelect={_handleUpdate}
            />
        </div>
    );
};
export default forwardRef(SessionList);
