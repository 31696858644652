import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useReducer, useRef } from 'react';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

const MonthPicker = ({ changeMonth, monthDate, customHeaderCount }) => {
    const refDropDown = useRef(null);
    const refWrapper = useRef(null);
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const finalIsVisible = state.isVisible;
    const currentMonth = moment(monthDate).format('MMMM');

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);

            refWrapper.current?.querySelector('.active')?.scrollIntoView({ behavior: 'auto', block: 'start' });
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        if (event.keyCode === KEY_CODE_ESCAPE && refWrapper.current) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        if (
            refDropDown.current &&
            refWrapper.current &&
            !refWrapper.current.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function onChangeMonth(month) {
        changeMonth(months.indexOf(month) - customHeaderCount);
        _closeSearchResult();
    }

    function _renderListMonth() {
        return months.map((item) => {
            const isActive = currentMonth === item;

            return (
                <li className={`items ${isActive ? 'active' : ''}`} key={item} onClick={() => onChangeMonth(item)}>
                    <p className="txt-ellipsis">{item}</p>
                </li>
            );
        });
    }

    return (
        <div className="datepicker-header-custom__option --month">
            <div
                ref={refDropDown}
                className={classNames('v2-dropdown datepicker-selected', { active: finalIsVisible })}
            >
                <div className="dropbtn v2-btn-default --sm --icon-r fs-13" onClick={_handleOpen}>
                    {currentMonth}
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </div>
                <div ref={refWrapper} className="v2-dropdown__menu scrolls">
                    <ul>{_renderListMonth()}</ul>
                </div>
            </div>
        </div>
    );
};

export default MonthPicker;
