import React, { useState, useEffect, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import InsertVariableButton from 'app/modules/drip/components/InsertVariableButton';
import GdRichText from 'app/components/richtext';
import RestoreDefaultButton from 'app/modules/drip/components/RestoreDefaultButton';
import GdConfirm from 'app/components/confirm';

import { KEY_NAME_EDIT_CONTENT, LIMIT_CHARACTER_NAME, LIMIT_CHARACTER_SMS, USER_PROFILE } from 'app/const/drip';
import { DEFAULT_CONTENT, STATUS_CONTENT_TEMPLATE } from 'app/const/drip/MessageTemplates';
import IconEmail from 'assets/icon/IconEmail';
import IconSentSms from 'assets/icon/IconSentSms';

const HeaderEditor = ({ icon, type, status, email, onToggle, onSendTestEmail, hideSwitch, hideSendTestEmail }) => {
    if (hideSwitch) return null;

    const _sendTestEmail = () => {
        onSendTestEmail();
    };

    const handleOnClickSwitchBoxLabel = () => {
        if (typeof onToggle !== 'function') return;
        onToggle(type);
    };

    return (
        <div className="l-switchboxs__title">
            {icon}

            {status != 0 && email && !hideSendTestEmail && <BtnSendTestEmail onSendTestEmail={_sendTestEmail} />}

            <div className="switch large pl-2">
                <input id={`toggle_edit${type}`} className="toggle toggle-round" type="checkbox" checked={status} />
                <label htmlFor={`toggle_edit${type}`} onClick={handleOnClickSwitchBoxLabel}>
                    <span className="enable-disable" />
                </label>
            </div>
        </div>
    );
};

const BtnSendTestEmail = ({ onSendTestEmail }) => {
    const { t } = useTranslation(['drip']);
    const companyEmail = USER_PROFILE?.company_email;

    return (
        <div className="v2-btn-default has-icon v2-tooltip" onClick={onSendTestEmail}>
            <IconEmail />
            {t('drip:send_test_email')}
            {companyEmail && <span className="tooltiptext">{companyEmail}</span>}
        </div>
    );
};

const DripEditContent = forwardRef(
    ({ dataReset, data, onGetData, onSendTestEmail, moduleSetting, hideSms, hideSwitch, hideSendTestEmail }, ref) => {
        const { t } = useTranslation(['drip']);
        const refSubjectLine = useRef(null);
        const refNotificationSendTestEmail = useRef(null);

        const initContent = useMemo(() => {
            return {
                [KEY_NAME_EDIT_CONTENT.SUBJECT]:
                    dataReset?.email?.subject || DEFAULT_CONTENT[KEY_NAME_EDIT_CONTENT.SUBJECT],
                [KEY_NAME_EDIT_CONTENT.EMAIL]:
                    dataReset?.email?.content || DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.EMAIL]],
                [KEY_NAME_EDIT_CONTENT.SMS]: dataReset?.sms?.content || DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.SMS]]
            };
        }, [dataReset]);

        const [editContent, setEditContent] = useState({
            default: {
                [KEY_NAME_EDIT_CONTENT.SUBJECT]: DEFAULT_CONTENT[KEY_NAME_EDIT_CONTENT.SUBJECT],
                [KEY_NAME_EDIT_CONTENT.EMAIL]: DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.EMAIL]],
                [KEY_NAME_EDIT_CONTENT.SMS]: DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.SMS]]
            },
            email: {
                status: STATUS_CONTENT_TEMPLATE.ENABLED,
                content: DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.EMAIL]],
                subject: DEFAULT_CONTENT[KEY_NAME_EDIT_CONTENT.SUBJECT]
            },
            sms: {
                status: STATUS_CONTENT_TEMPLATE.ENABLED,
                content: DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.SMS]]
            }
        });

        const [insertText, setInsertText] = useState({
            [KEY_NAME_EDIT_CONTENT.SUBJECT]: null,
            [KEY_NAME_EDIT_CONTENT.EMAIL]: null,
            [KEY_NAME_EDIT_CONTENT.SMS]: null
        });

        useEffect(() => {
            dataReset &&
                setEditContent({
                    default: initContent,
                    ...dataReset
                });
        }, [dataReset]);

        useEffect(() => {
            data &&
                setEditContent({
                    default: {
                        [KEY_NAME_EDIT_CONTENT.SUBJECT]: data?.email?.subject,
                        [KEY_NAME_EDIT_CONTENT.EMAIL]: data?.email?.content,
                        [KEY_NAME_EDIT_CONTENT.SMS]: data?.sms?.content
                    },
                    ...data
                });
        }, [data]);

        useEffect(() => {
            if (onGetData) {
                const data = handleGetData();
                onGetData({ ...data });
            }
        }, [editContent]);

        useImperativeHandle(ref, () => {
            const data = handleGetData();
            return { data };
        });

        const handleGetData = () => {
            const { email, sms } = editContent;

            const subject = email?.subject || '';
            const content = email?.content || '';
            const emailStatus = email?.status;

            const contentSms = sms?.content || '';
            const smsStatus = sms?.status;

            const emailSubject = emailStatus
                ? subject && subject.trim()
                : DEFAULT_CONTENT[KEY_NAME_EDIT_CONTENT.SUBJECT];
            const emailContent = emailStatus ? content : DEFAULT_CONTENT[KEY_NAME_EDIT_CONTENT.EMAIL];
            const smsContent = smsStatus
                ? contentSms && contentSms.trim()
                : DEFAULT_CONTENT[[KEY_NAME_EDIT_CONTENT.SMS]];

            return {
                email: {
                    ...editContent.email,
                    subject: emailSubject,
                    content: emailContent
                },
                sms: {
                    ...editContent.sms,
                    content: smsContent
                }
            };
        };

        const handleToggle = (field) => {
            const isDisableField = editContent[field]?.status == STATUS_CONTENT_TEMPLATE.ENABLED;
            const newStatus = isDisableField ? STATUS_CONTENT_TEMPLATE.DISABLED : STATUS_CONTENT_TEMPLATE.ENABLED;

            setEditContent({
                ...editContent,
                [field]: {
                    ...editContent[field],
                    status: newStatus
                }
            });
        };

        const handleRestoreContent = (field) => {
            setEditContent({
                ...editContent,
                default: {
                    ...editContent.default,
                    [field]: initContent[field]
                },
                [field]: { ...editContent[field], content: initContent[field] }
            });
        };

        const handleInsertTextEditor = (key, value) => {
            setInsertText({
                ...insertText,
                [key]: { variable: value }
            });
        };

        const handleInsertText = (text, position, value) => {
            return [text.slice(0, position), value, text.slice(position)].join('');
        };

        const handleInsertTextSubject = (value) => {
            let subject = editContent?.email?.subject;
            const inputSubject = refSubjectLine.current;
            const position = inputSubject.selectionEnd;

            subject = handleInsertText(subject, position, value);

            inputSubject.focus();

            setEditContent({
                ...editContent,
                email: {
                    ...editContent.email,
                    subject: subject
                }
            });
        };

        const handleChangeSubjectLine = (e) => {
            let value = e.target.value;
            value = value.length > LIMIT_CHARACTER_NAME ? value.substring(0, LIMIT_CHARACTER_NAME) : value;

            !value.includes('  ') &&
                setEditContent((prevState) => {
                    const newEmail = { ...prevState?.email };
                    newEmail.subject = value;
                    return { ...prevState, email: newEmail };
                });
        };

        const handleChangeContent = (type, value) => {
            setEditContent((prev) => {
                const next = { ...prev[type] };
                next.content = value;
                return {
                    ...prev,
                    [type]: next,
                    default: { ...prev.default, [type]: '' }
                };
            });
        };

        const handleSendTestEmail = () => {
            const { subject, content } = editContent.email;
            if (subject && content != DEFAULT_CONTENT.EMAIL) {
                onSendTestEmail();
            } else {
                refNotificationSendTestEmail.current.open();
            }
        };

        const renderSubjectLine = () => {
            return (
                <>
                    <span className="txt">{t(`subject_line`)}</span>
                    <input
                        ref={refSubjectLine}
                        className="field-input"
                        value={editContent?.email?.subject}
                        placeholder="Test"
                        onChange={handleChangeSubjectLine}
                    />
                    <div className="l-switchboxs__content-action">
                        <InsertVariableButton onSelect={handleInsertTextSubject} />
                    </div>
                </>
            );
        };

        const renderEmailBody = () => {
            const isShowEmailBody = editContent?.email?.status;
            const emailBodyContent = editContent?.default[KEY_NAME_EDIT_CONTENT.EMAIL];

            const icon = (
                <div className="title svg-email-blue">
                    <IconEmail isEmailAddress />
                    {t(`drip:email`)}
                </div>
            );

            return (
                <>
                    <div className="col-full mt-4">
                        <HeaderEditor
                            icon={icon}
                            type="email"
                            status={isShowEmailBody}
                            email
                            onToggle={handleToggle}
                            onSendTestEmail={handleSendTestEmail}
                            hideSwitch={hideSwitch}
                            hideSendTestEmail={hideSendTestEmail}
                        />

                        {moduleSetting && renderSubjectLine()}
                    </div>
                    <div className={`col-full mt-4 ${!isShowEmailBody && 'disable'}`}>
                        <span className="txt">{t('drip:email_body')} </span>

                        <GdRichText
                            content={emailBodyContent}
                            textInsert={insertText[KEY_NAME_EDIT_CONTENT.EMAIL]}
                            editorClassname="description-box container-column"
                            toolbarClassName="toolbar flex-auto"
                            onChangeEditor={(value) => {
                                handleChangeContent('email', value);
                            }}
                        />

                        <div className="l-switchboxs__content-action">
                            <InsertVariableButton
                                onSelect={(value) => {
                                    handleInsertTextEditor(KEY_NAME_EDIT_CONTENT.EMAIL, value);
                                }}
                            />
                            <RestoreDefaultButton
                                onReset={() => {
                                    handleRestoreContent('email');
                                }}
                            />
                        </div>
                    </div>
                </>
            );
        };

        const renderSms = () => {
            const isShowSms = editContent?.sms?.status;
            const smsContent = editContent?.default[KEY_NAME_EDIT_CONTENT.SMS];

            const icon = (
                <div className="title svg-sms-yellow">
                    <IconSentSms isHasColor />
                    {t(`drip:sms`)}
                </div>
            );

            return (
                <div className="l-switchboxs">
                    <HeaderEditor
                        icon={icon}
                        type="sms"
                        status={isShowSms}
                        onToggle={handleToggle}
                        hideSwitch={hideSwitch}
                    />

                    <div
                        className={`l-switchboxs__content reminder-email-body form-newnote ${!isShowSms && 'disable'}`}
                    >
                        <div className="custom-description-box container-column">
                            <GdRichText
                                content={smsContent}
                                textInsert={insertText[KEY_NAME_EDIT_CONTENT.SMS]}
                                editorClassname="field-input no-effect box-auto scrolls"
                                toolbarClassName="toolbar flex-auto"
                                onChangeEditor={(value) => {
                                    handleChangeContent('sms', value);
                                }}
                                toolbarHidden
                                maxLength={LIMIT_CHARACTER_SMS}
                            />
                        </div>

                        <div className="l-switchboxs__content-action">
                            <InsertVariableButton
                                classDrop="v2-dropup"
                                onSelect={(value) => {
                                    handleInsertTextEditor(KEY_NAME_EDIT_CONTENT.SMS, value);
                                }}
                            />

                            <RestoreDefaultButton
                                onReset={() => {
                                    handleRestoreContent('sms');
                                }}
                            />
                        </div>
                    </div>
                    <GdConfirm
                        ref={refNotificationSendTestEmail}
                        title={t('common:notification')}
                        message={t('drip:subject_or_content_of_email_is_empty_please_check_again')}
                        listButton={{ close: true }}
                        onClose={() => {}}
                    />
                </div>
            );
        };

        if (moduleSetting) {
            if (hideSms) {
                return <>{renderEmailBody()}</>;
            }

            return (
                <>
                    {renderEmailBody()}

                    {renderSms()}
                </>
            );
        }

        return (
            <div className="has-form">
                {renderSubjectLine()}
                <div className="has-form__line" />
                {renderEmailBody()}
                <div className="has-form__line" />
                {renderSms()}
            </div>
        );
    }
);

DripEditContent.propTypes = {
    data: PropTypes.object,
    dataReset: PropTypes.object,
    onGetData: PropTypes.func,
    onSendTestEmail: PropTypes.func
};

DripEditContent.defaultProps = {
    onSendTestEmail: () => {}
};
export default DripEditContent;
