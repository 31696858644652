import { OPPORTUNITIES_INBOX, SALES_FUNNEL, STATUS_CHANGES } from 'app/config/routes';
import { TYPE_OPPORTUNITY_INBOX } from 'app/modules/inbox/const';
import IconDone from 'assets/icon/IconDone';
import IconFuture from 'assets/icon/IconFuture';
import IconPipeline from 'assets/icon/IconPipeline';
import { ACCESS_PIPELINE_REPORT, ACCESS_SALES_FUNNEL, ACCESS_STATUS_CHANGES } from '../Permissions';

export const OPTIONS_VALUE_OPPORTUNITY = [
    { id: 1, name: 'One-time', value: 1 },
    { id: 20, name: 'Monthly', value: 20 },
    { id: 21, name: 'Bi-Monthly', value: 21 },
    { id: 22, name: 'Quarterly ', value: 22 },
    { id: 30, name: 'Annual', value: 30 }
];

export const OPTIONS_STATUS_OPPORTUNITY = [
    { id: 1, name: 'New' },
    { id: 3, name: 'Won' },
    { id: 4, name: 'Lost' }
];

export const LIST_SIDE_BAR_OPPORTUNITY = [
    {
        id: TYPE_OPPORTUNITY_INBOX.PIPELINE,
        permission: ACCESS_PIPELINE_REPORT,
        path: OPPORTUNITIES_INBOX,
        title: 'opportunities_pipeline',
        icon: <IconPipeline />
    },
    {
        id: TYPE_OPPORTUNITY_INBOX.SALES_FUNNEL,
        path: SALES_FUNNEL,
        permission: ACCESS_SALES_FUNNEL,
        title: 'sales_funnel',
        icon: <IconDone />,
        isSub: true
    },
    {
        id: TYPE_OPPORTUNITY_INBOX.STATUS_CHANGES,
        permission: ACCESS_STATUS_CHANGES,
        path: STATUS_CHANGES,
        title: 'status_changes',
        icon: <IconFuture />,
        isSub: true
    }
];

export const TYPE_OPTIONS_PIPELINE = {
    EDIT: 'edit',
    DELETE: 'delete'
};

export const CLASS_NAME_DRAG = 'is-drag';

export const TYPE_CONTACT_PIPELINE = {
    EMAIL: 'email',
    SMS: 'sms',
    CALL: 'call'
};

export const KEY_PERIOD = {
    ONE_TIME: 'one_time',
    MONTHLY: 'monthly',
    BI_MONTHLY: 'bi_monthly',
    QUARTERLY: 'quarterly',
    ANNUAL: 'annual',
    ANNUALIZED: 'annualized'
};

export const CONFIDENCE_VALUE = {
    won: 100,
    lost: 0
};

export const TYPE_MATH = {
    SUB: 'subtraction',
    ADD: 'addition'
};

export const DEFAULT_VALUE_OPPORTUNITY_REPORT = {
    [KEY_PERIOD.ONE_TIME]: 0,
    [KEY_PERIOD.MONTHLY]: 0,
    [KEY_PERIOD.BI_MONTHLY]: 0,
    [KEY_PERIOD.QUARTERLY]: 0,
    [KEY_PERIOD.ANNUAL]: 0,
    [KEY_PERIOD.ANNUALIZED]: 0
};

export const COUNT = 'count';
export const WEIGHTED_VALUE = 'weighted_value';
export const WON = 'won';
export const WON_LOST = 'won_lost';
export const CONVERSION_RATE = 'conversion_rate';

export const SALE_FUNNEL_TAB = {
    COUNT: 'count',
    VALUE: 'value',
    WEIGHTED_VALUE: 'weighted_value'
};
export const SALE_FUNNEL_TAB_LIST = [
    { id: SALE_FUNNEL_TAB.COUNT, name: 'count', isAnnualized: false },
    { id: SALE_FUNNEL_TAB.VALUE, name: 'value', isAnnualized: true },
    { id: SALE_FUNNEL_TAB.WEIGHTED_VALUE, name: 'weighted', isAnnualized: true }
];

export const CONTACT_ID_DEFAULT = '0';
