import IconAlert from 'assets/icon/IconAlert';
import React from 'react';

const AlertBulkEmail = ({ desc, content = null, title }) => {
    return (
        <div className="rows --alert">
            <div className="col-icon">
                <IconAlert />
            </div>
            <div className="col-desc">
                <p className="col-desc__title pt-0">{title}</p>
                {desc ? <p>{desc}</p> : null}
                {content}
            </div>
        </div>
    );
};

export default AlertBulkEmail;
