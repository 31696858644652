import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import GridLoading from 'app/components/grid/components/GridLoading';
import { COMMON } from 'app/const/App';
import { getStyleHeader } from '../utils';
import SortOrderTable from 'app/components/grid/components/SortOrderTable';

const GDRowHeader = ({
    isSticky = false,
    isActiveSticky = false,
    title = null,
    startTransform,
    row,
    columns = [],
    fileTranslation,
    handleClickHeader = () => {},
    measureRef,
    isLoading = false,
    aboveHeaderLoading = false
}) => {
    const { t } = useTranslation(fileTranslation);

    return (
        <div
            ref={measureRef}
            style={{
                ...getStyleHeader({
                    isLoading: aboveHeaderLoading,
                    startTransform,
                    isSticky,
                    isActiveSticky
                })
            }}
        >
            {title ? <div className={title.className}>{title.name}</div> : null}
            <div className="rows --header">
                {columns.map((column) => {
                    const { id, status } = column;
                    const rowData = row[id];
                    const columnHeaderProps = {
                        id,
                        key: id,
                        style: `${rowData.style} ${isLoading ? 'is-disable' : ''}`,
                        title: t(rowData.title)
                    };

                    if (status && rowData?.actionType === COMMON.ORDER) {
                        columnHeaderProps.handleOrder = ({ nextOrder = '' }) => {
                            if (isLoading) return false;
                            handleClickHeader({
                                actionType: rowData.actionType,
                                columnsTarget: id,
                                currentValue: nextOrder
                            });
                        };

                        columnHeaderProps.orderStatus = rowData.currentValue;
                        return <RowOrder key={rowData} {...columnHeaderProps} />;
                    }

                    if (status) return <RowNormal key={id} {...columnHeaderProps} />;
                    return null;
                })}
            </div>
            {isLoading ? <GridLoading /> : null}
        </div>
    );
};

const RowNormal = ({ id, style, title }) => {
    return (
        <div key={id} className={style}>
            <p className="col-label">{title}</p>
        </div>
    );
};

const RowOrder = ({ id, style, title, handleOrder, orderStatus }) => {
    return (
        <div key={id} className={classNames(style)}>
            <div className={classNames('has-orderby', orderStatus)}>
                {title}
                <span className="caret" />
                <SortOrderTable value={id} currentSort={orderStatus} onClickOrder={handleOrder} />
            </div>
        </div>
    );
};

export default GDRowHeader;
