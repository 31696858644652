import { actionComment } from 'app/const/Api';
import Content from 'app/modules/customer/components/Content';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const EditComment = forwardRef(({ noteId, commentDetail, onUpdate, type, jobId, unitId }, ref) => {
    const { id } = useParams();
    const refContent = useRef(null);

    const [state, setState] = useState({
        isVisible: false,
        commentData: {}
    });

    const finalIsVisible = state.isVisible;
    const commentId = commentDetail.id;

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide
    }));

    useEffect(() => {
        if (finalIsVisible) refContent.current && refContent.current.focus();
    }, [finalIsVisible]);

    function _handleToggleShowHide() {
        setState({ ...state, isVisible: true, commentData: commentDetail });
    }

    function _handleCancelEdit() {
        refContent.current.resetValue();
        const elm = document.getElementById(`form_time_line_note_detail_${noteId}`);
        const elmComment = document.getElementById(`comment_item_${commentId}`);
        if (elm && elmComment) {
            setState({ ...state, isVisible: false });
            elmComment.classList.remove('is-editing-cmt');
            const elmEditExisted = elm.getElementsByClassName('is-editing-cmt');
            !elmEditExisted?.length && elm.classList.remove('has-edit-comments');
        }
    }

    function _handleEdit(content) {
        const attachments = refContent.current.getAttachments() || [];
        const notifySelected = refContent.current.getNotify() || [];
        const newAttachments = [];

        attachments.forEach((item) => {
            if (!item.isDefault) {
                newAttachments.push({
                    object_key: item.object_key,
                    object_tag: item.object_tag,
                    mime: item.mime,
                    name: item.name,
                    size: item.size,
                    type: item.type
                });
            }
        });

        clientQuery(
            actionComment(commentId),
            {
                method: 'PUT',
                data: { content: content, attachments: newAttachments, notified_users: notifySelected },
                toFormData: false
            },
            (response) => _handleSuccess(response, content, attachments, notifySelected)
        );
    }

    const _handleSuccess = ({ data }, content, prevAttach, notified_users = []) => {
        const newAttach = data.attachments.map((item) => {
            return { ...item, isUpdate: true };
        });

        onUpdate({
            ...state.commentData,
            attachments: [...prevAttach, ...newAttach].filter((item) => item.isUpdate || item.isDefault),
            content,
            notified_users
        });

        _handleCancelEdit();
    };

    if (!finalIsVisible) {
        return false;
    }

    function _getItemId() {
        switch (type) {
            case TYPE_NOTE.CUSTOMER:
                return id;
            case TYPE_NOTE.JOB:
                return jobId;
            case TYPE_NOTE.UNIT:
                return unitId;
            default:
                return '';
        }
    }

    return (
        <div className="wrap-content is-editing">
            <div className="wrap-content-box">
                <Content
                    ref={refContent}
                    content={commentDetail.content}
                    typePresign="comment"
                    itemIdPresign={_getItemId()}
                    attachmentProps={{
                        itemId: _getItemId(),
                        typeAttachment: 'note',
                        haveNotify: true,
                        haveEditNotify: false,
                        defaultNotify: commentDetail.notified_users || [],
                        defaultAttachments: state.commentData.attachments || []
                    }}
                    onCloseEdit={_handleCancelEdit}
                    onUpdate={_handleEdit}
                />
            </div>
        </div>
    );
});

export default EditComment;
