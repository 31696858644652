import React, { createContext, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';

export const SetupEmailContext = createContext();

const SetupEmailProvider = ({ children }) => {
    const [state, dispatchState] = useReducer(reducer, {
        step: 1,
        email: '',
        errors: null,
        setup: {},
        isAutomatically: true
    });
    const { step } = state;

    const _handleNextStep = () => {
        if (step === 4) return;
        dispatchState({ step: (state.step += 1) });
    };

    const _handlePrevStep = () => {
        if (step === 1) return;
        dispatchState({ step: (state.step -= 1) });
    };

    const _handleChangeEmail = () => {
        dispatchState({ step: 1 });
    };

    const _setEmailAddress = (email) => {
        dispatchState({ email });
    };

    const _handleAutomatically = (isAutomatically) => {
        dispatchState({ isAutomatically });
    };

    const _handleSetErrors = (errors) => {
        dispatchState({ errors });
    };

    const _handleSetState = (state) => dispatchState(state);
    const _handleRestoreState = () =>
        dispatchState({ step: 1, email: '', errors: null, setup: {}, isAutomatically: true });

    const contextValue = {
        ...state,
        setup: state,
        restoreState: _handleRestoreState,
        nextStep: _handleNextStep,
        prevStep: _handlePrevStep,
        changeEmail: _handleChangeEmail,
        setEmail: _setEmailAddress,
        setAutomatically: _handleAutomatically,
        setErrors: _handleSetErrors,
        setState: _handleSetState
    };
    return <SetupEmailContext.Provider value={contextValue}>{children}</SetupEmailContext.Provider>;
};

export default SetupEmailProvider;
