import {
    REPORT_REVENUE_BY_ITEM_DETAIL_GET_LIST,
    REPORT_REVENUE_BY_ITEM_MONTH_GET_LIST,
    REPORT_REVENUE_ITEM_ITEMS_FILTER,
    REPORT_REVENUE_ITEM_METHOD_FILTER
} from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportRevenueByItemMonth({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_ITEM_MONTH_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_customer');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportRevenueByItemDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_ITEM_DETAIL_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_payment');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterRevenueByItem({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_ITEM_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListServiceFilterRevenueByItem({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_ITEM_ITEMS_FILTER);
        if (response && response.success) {
            const newData = response.data;
            newData.forEach((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
