import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import LoadingSmsList from 'app/components/loading/sms/LoadingSmsList';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { ALTER_CHATBOT_REPORT } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT } from 'app/const/Reports';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import ConversationContent from 'app/modules/sms/components/ConversationContent';
import LoadMore from 'app/modules/sms/conversation/LoadMore';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { smsGetListByCustomer } from 'common/redux/actions/sms/smsAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_STATUS_OPTIONS } from '../constants';

const SummaryConversation = ({ onUpdateData = () => {} }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        conversation: [],
        data: {},
        show_more: false,
        resolvedSelected: null
    });
    const {
        isLoading,
        conversation: finalConversation,
        data: finalData,
        isOpen,
        show_more: showMore,
        resolvedSelected
    } = state;
    const {
        customer: { full_name, id: customerId } = {},
        summary,
        start_date: start,
        end_date: end,
        id: finalID
    } = finalData || {};
    const {
        id: resolvedSelectedID = '',
        name: resolvedSelectedName = '',
        classNameStatus: resolvedSelectedClassNameStatus = ''
    } = resolvedSelected || {};

    const refConversationDetail = useRef(null);
    const refLoadMore = useRef(null);
    const refWrapper = useRef(null);
    const refOffset = useRef(0);
    const refPreScroll = useRef(null);
    const refConfirm = useRef(null);
    const refRequestFrame = useRef(null);
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        open: _handleOpen,
        close: _handleClose
    }));

    useEffect(() => {
        document.addEventListener('click', _handleClickOutside, true);

        return () => {
            document.removeEventListener('click', _handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (!isOpen) return;

        _getListSms();
        return () => {
            cancelAnimationFrame(refRequestFrame.current);
        };
    }, [isOpen]);

    const _handleOpen = (data = {}) => {
        refPreScroll.current = {};
        refOffset.current = 0;
        dispatchState({
            isOpen: true,
            isLoading: true,
            data,
            resolvedSelected: REPORT_STATUS_OPTIONS.find((item) => item.id === data.resolved)
        });
    };

    const _handleClose = () => {
        dispatchState({ isOpen: false, conversation: [] });
    };

    const _handleClickOutside = (event) => {
        if (refConversationDetail.current && !refConversationDetail.current.contains(event.target)) {
            _handleClose();
        }
    };

    const _getListSms = ({ filter = {}, isLoadMore = false } = {}) => {
        const _handleSuccess = ({ data, show_more }) => {
            dispatchState((prev) => ({
                ...prev,
                isLoading: false,
                show_more,
                conversation: isLoadMore ? [...data, ...prev.conversation] : data
            }));
            cancelAnimationFrame(refRequestFrame.current);
            refRequestFrame.current = requestAnimationFrame(() => {
                if (!refWrapper.current) return;
                let scrollTop = refWrapper.current.scrollHeight;
                if (isLoadMore) {
                    const { height = 0, top = 0 } = refPreScroll.current;
                    scrollTop = top + (scrollTop - height);
                }
                refWrapper.current.scrollTop = scrollTop;
                refLoadMore.current?.setLoading(false);
            });
        };

        const _handleFail = ({ message }) => {
            refConfirm.current._open({ description: message });
        };

        dispatch(
            smsGetListByCustomer(
                {
                    customer_id: customerId,
                    offset: refOffset.current,
                    limit: REPORT_LIMIT,
                    start,
                    end,
                    ...filter
                },
                _handleSuccess,
                _handleFail
            )
        );
    };

    const _handleOnScroll = (e) => {
        e && e.stopPropagation();

        if (refWrapper.current.scrollTop < 50 && !isLoading && !refLoadMore.current?.getState() && showMore) {
            refLoadMore.current?.setLoading(true);
            refOffset.current += REPORT_LIMIT;
            refPreScroll.current = {
                height: refWrapper.current.scrollHeight,
                top: refWrapper.current.scrollTop
            };

            _getListSms({ isLoadMore: true });
        }
    };

    const _onChangeStatus = (data) => {
        const { value } = data || {};
        if (resolvedSelectedID === value) return;
        dispatchState((prev) => ({
            ...prev,
            resolvedSelected: REPORT_STATUS_OPTIONS.find((item) => item.id === value)
        }));

        const _handleSuccess = () => {
            onUpdateData(value);
        };
        const _handleFail = ({ message }) => {
            refConfirm.current._open({ description: message });
        };
        clientQuery(
            ALTER_CHATBOT_REPORT,
            { data: { ids: finalID, status: value }, method: 'PUT' },
            _handleSuccess,
            _handleFail
        );
        refDropdown.current?._closeDropdown();
    };

    const _renderListStatus = () => {
        return REPORT_STATUS_OPTIONS.map(({ id, classNameStatus, name }) => (
            <li
                key={id}
                className={classNames('items --ticked', { active: id === resolvedSelectedID })}
                onClick={() => _onChangeStatus({ id: finalID, value: id })}
            >
                <IconCheck />
                <div className={classNames('status-btn', classNameStatus)}>{name}</div>
            </li>
        ));
    };

    const _renderCustomDropButton = () => {
        return (
            <Fragment>
                <p className="txt-ellipsis">{resolvedSelectedName}</p>
                <span className="arrow">
                    <IconDropUpDown />
                </span>
            </Fragment>
        );
    };

    if (!isOpen) return null;

    return (
        <div className="box-report-sms boxs-notification">
            <div ref={refConversationDetail} className="conversation-sms notification-dropdown active">
                <div className="message-header">
                    <div className="message-header__title flexcenter gap-8">
                        <div className="flex-column flex-1 txt-ellipsis">
                            <div className="fs-14 fw-500 purple-default">{full_name}</div>
                            <div className="black">{summary}</div>
                        </div>
                        <CalendarDropdown
                            id="select_status_train_ai"
                            ref={refDropdown}
                            buttonClassName={classNames(
                                'dropbtn items status-btn svg-white white',
                                resolvedSelectedClassNameStatus
                            )}
                            wrapperListClass="v2-dropdown__menu content-full --right scrolls"
                            customDropButton={_renderCustomDropButton}
                        >
                            <ul>{_renderListStatus()}</ul>
                        </CalendarDropdown>
                    </div>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                {isLoading ? (
                    <LoadingSmsList />
                ) : !!finalConversation.length ? (
                    <div ref={refWrapper} className="message-contents" onScroll={_handleOnScroll}>
                        <div className="box-conversation">
                            <div className="conts-conversation">
                                <LoadMore ref={refLoadMore} />
                                <ConversationContent listSms={finalConversation} isHiddenMark />
                            </div>
                        </div>
                    </div>
                ) : (
                    <GridEmpty isFlat />
                )}
                <GDModalWarning ref={refConfirm} isLargeContent={false} title={t('addons:warning')} buttonConfirm />
            </div>
        </div>
    );
};

export default forwardRef(SummaryConversation);
