import GdConfirm from 'app/components/confirm';
import GdRichText from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import React, { forwardRef, Fragment, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Content = (
    {
        onCloseEdit = () => {},
        onUpdate = () => {},
        content = '',
        typePresign = 'item',
        itemIdPresign = '',
        showCancel = true,
        disableSend = false,
        attachmentProps = {}
    },
    ref
) => {
    const { t } = useTranslation(['customers', 'common']);
    const refRichtext = useRef(null);
    const refConfirm = useRef(null);

    function _handleCancelEdit() {
        onCloseEdit();
    }

    useImperativeHandle(ref, () => ({
        focus: () => {
            refRichtext.current.focus();
        },
        getContent: () => {
            if (refRichtext.current.isEmpty()) return '';
            return refRichtext.current.getValue();
        },
        resetValue: () => {
            _handleReset();
        },
        shouldUpdateAttach: () => refRichtext.current.shouldUpdateAttach(),
        getAttachments: () => refRichtext.current.getValueWithAttach()?.attachments || [],
        getNotify: () => refRichtext.current.getNotify() || [],
        clearNotify: () => {
            refRichtext.current.clearNotify();
        },
        clearAttachments: () => {
            refRichtext.current.clearAttachments();
        },
        setValue: (newValue) => {
            refRichtext.current.setValue(newValue);
        },
        refImagesUploading: () => refRichtext.current.getUploadingImages(),
        setUploadLoading: (isLoading) => refRichtext.current.setUploadLoading(isLoading)
    }));

    function _handleUpdate() {
        if (refRichtext.current.isEmpty()) {
            refConfirm.current.open(null, t('customers:your_note_requires'));
        } else {
            onUpdate(refRichtext.current.getValue());
        }
    }

    const _handleReset = () => {
        refRichtext.current.setValue('');
    };

    return (
        <Fragment>
            <GdRichText
                ref={refRichtext}
                canDropImage
                wrapperClass="wrap-content is-editing"
                typePresign={typePresign}
                itemIdPresign={itemIdPresign}
                defaultContent={content || ' '}
                attachmentProps={attachmentProps}
                haveAttachmentBlock
                toolbarConfig={{
                    left: [
                        { group: 'emoji', children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }] },
                        {
                            group: 'inline-style',
                            children: [
                                { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                { type: TYPE_FUNCTION_RICHTEXT.LINK }
                            ]
                        },
                        {
                            group: 'list',
                            children: [{ type: TYPE_FUNCTION_RICHTEXT.UL }, { type: TYPE_FUNCTION_RICHTEXT.OL }]
                        }
                    ],
                    right: [
                        {
                            group: 'save-group',
                            children: [
                                {
                                    type: TYPE_FUNCTION_RICHTEXT.SAVE_BUTTON,
                                    props: {
                                        showCancel,
                                        disableSubmit: disableSend,
                                        onSubmit: _handleUpdate,
                                        onCancel: _handleCancelEdit
                                    }
                                }
                            ]
                        }
                    ]
                }}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('common:confirm')}
            />
        </Fragment>
    );
};

export default forwardRef(Content);
