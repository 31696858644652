import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';
import { reducer } from 'app/const/Reducer';

const NumbersBetween = ({
    selectedValue = {
        moreThan: { floatValue: '', includeEquals: false },
        lessThan: { floatValue: '', includeEquals: true }
    },
    onChange = () => {}
}) => {
    const { t } = useTranslation('smartView');
    const refValue = useRef(selectedValue);

    const _handleChangeValue = ({ key, value }) => {
        refValue.current = { ...refValue.current, [key]: { ...refValue.current[key], floatValue: value } };
        const { moreThan, lessThan } = refValue.current;
        if (moreThan.floatValue !== '' && lessThan.floatValue !== '') onChange(refValue.current);
    };

    const _handleToggleIncludeEquals = ({ key, includeEquals }) => {
        refValue.current = { ...refValue.current, [key]: { ...refValue.current[key], includeEquals } };
        const { moreThan, lessThan } = refValue.current;
        if (moreThan.floatValue !== '' && lessThan.floatValue !== '') onChange(refValue.current);
    };

    return (
        <div className="is-between field">
            <NumberTab
                isGreater
                placeholder="2"
                keyValue="moreThan"
                isIncludeEqual={selectedValue.moreThan.includeEquals}
                defaultValue={selectedValue.moreThan.floatValue}
                onChange={_handleChangeValue}
                onToggleIncludeEqual={_handleToggleIncludeEquals}
            />
            <div className="flexcenter gap-4">
                <span className="fw-500 black-darker">{t('and')}</span>
                <div className="border-top-black-darker flex-1" />
            </div>
            <NumberTab
                placeholder="5"
                keyValue="lessThan"
                isIncludeEqual={selectedValue.lessThan.includeEquals}
                defaultValue={selectedValue.lessThan.floatValue}
                onChange={_handleChangeValue}
                onToggleIncludeEqual={_handleToggleIncludeEquals}
            />
        </div>
    );
};

const NumberTab = ({
    isGreater = false,
    keyValue = 'lessThan',
    placeholder = '2',
    isIncludeEqual: defaultEqual = false,
    defaultValue = '',
    onChange = () => {},
    onToggleIncludeEqual = () => {}
}) => {
    const [state, dispatchState] = useReducer(reducer, { isIncludeEqual: defaultEqual });
    const { isIncludeEqual } = state;

    const _handleInputChange = (value) => {
        onChange({ key: keyValue, value });
    };
    const _handleToggleEqual = (isIncludeEqual) => {
        onToggleIncludeEqual({ key: keyValue, includeEquals: isIncludeEqual });
        dispatchState((prevState) => {
            return { ...prevState, isIncludeEqual };
        });
    };
    return (
        <div className="flexcenter gap-4">
            <div className="tabs">
                <div className="btn-item m-0">
                    <div
                        className={classNames('tab-items', { 'active-tab-selector': isIncludeEqual })}
                        onClick={() => _handleToggleEqual(true)}
                    >
                        <span>{isGreater ? '≥' : '≤'}</span>
                    </div>
                    <div
                        className={classNames('tab-items', { 'active-tab-selector': !isIncludeEqual })}
                        onClick={() => _handleToggleEqual(false)}
                    >
                        <span>{isGreater ? '>' : '<'}</span>
                    </div>
                </div>
            </div>
            <GDInputNumber
                autoFocus={isGreater}
                className="field-input"
                placeholder={placeholder}
                defaultValue={defaultValue}
                onChange={_handleInputChange}
            />
        </div>
    );
};

export default NumbersBetween;
