import { MAP_ACTIONS } from 'common/redux/actions/map';

const mapReducer = (
    state = {
        widthofmap: 312,
        heightofmap: 204,
        openmap: 0,
        statusofmap: -1,
        dayofmap: -1,
        typeofmap: -1
    },
    action
) => {
    switch (action.type) {
        case MAP_ACTIONS.UPDATE_MAP_SIZE:
        case MAP_ACTIONS.UPDATE_MAP_VISIBLE:
            return { ...state, ...(action.payload || {}) };
        default:
            return state;
    }
};

export default mapReducer;
