import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconError from 'assets/icon/IconError';
import { EMAIL } from '../const';
import { validEmail } from 'common/utils/ValidationUtils';

const FieldInputPlan = (
    { label = '', placeholder = '', name = '', defaultValue = '', wrapperClassName = 'flex-column gap-4' },
    ref
) => {
    const { t } = useTranslation(['setting', 'addons']);
    const refInput = useRef(null);
    const refTimer = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        visibleError: false,
        errMessage: ''
    });
    const { visibleError, errMessage } = state;

    useImperativeHandle(ref, () => ({
        _getValue: _handleGetData
    }));

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    const _handleGetData = () => {
        const value = refInput.current.value;
        let isValidEmail = true;
        const isFieldEmail = name === EMAIL;

        if (isFieldEmail && !!value) {
            isValidEmail = validEmail(value);
            if (isValidEmail) return value;
        }
        if (!!value && !isFieldEmail) return value;

        dispatchState({
            visibleError: true,
            errMessage: `${t(label)} ${t(isValidEmail ? 'can_not_be_blank' : 'is_not_valid', {
                ns: 'addons'
            })}`
        });
        refTimer.current = setTimeout(() => {
            dispatchState({ visibleError: false, errMessage: '' });
        }, 5000);
        return false;
    };

    return (
        <div className={wrapperClassName}>
            <span className="txt">{t(label)}</span>
            <input
                ref={refInput}
                className={classNames('field-input', { 'field-error': visibleError })}
                placeholder={t(placeholder || label)}
                type="text"
                name={name}
                defaultValue={defaultValue}
            />
            {visibleError ? (
                <p className="txt-incorrect">
                    <IconError />
                    {errMessage}
                </p>
            ) : null}
        </div>
    );
};
export default forwardRef(FieldInputPlan);
