import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import DropdownTime from 'app/components/dropdown/DropdownTime';
import { TIMEOFF_HOURS, TIMEOFF_MINUTES } from 'app/const/App';
import { getDefaultValue } from 'app/modules/calendar/components/magnet/utils';
import IconTooltip from 'assets/icon/IconTooltip';
import { AVAILABLE_CLASS_NAME_DROPDOWN_TIME } from '../constants';

const AvailableTimeSlot = ({ defaultData = {}, onDisableBtnSave = () => {} }, ref) => {
    const { t } = useTranslation(['addons', 'common']);

    const refData = useRef({
        available_calendar_slot: defaultData?.available_calendar_slot || false,
        hour: defaultData?.hour || 0,
        minute: defaultData?.minute || 0
    });

    useImperativeHandle(ref, () => ({
        getValue: () => refData.current
    }));

    const handleToggle = (checked) => {
        onDisableBtnSave(false);
        refData.current = { ...(refData.current || {}), available_calendar_slot: checked };
    };

    const handleSelectTime = (unit, newSelect) => {
        onDisableBtnSave(false);
        refData.current = { ...(refData.current || {}), [unit === 'h' ? 'hour' : 'minute']: newSelect?.value };
    };

    return (
        <div className="flexcenter gap-8">
            <div className="flexcenter gap-4 flex-1">
                <GdSwitchCheckbox
                    onChangeImmediately={handleToggle}
                    shouldLabelActive={false}
                    textPosition="right"
                    textChecked={t('available_calendar_slot')}
                    textUnChecked={t('available_calendar_slot')}
                    defaultChecked={defaultData?.['available_calendar_slot'] || false}
                />
                <div className="d-flex tooltip">
                    <IconTooltip />
                    <p className="tooltiptext top">{t('job_magnet_tooltip')}</p>
                </div>
            </div>
            <div className="field-input wrapper-choosetime flex-1">
                <DropdownTime
                    id="minutes-length-timeoff"
                    unit="h"
                    subText="hrs"
                    options={TIMEOFF_HOURS}
                    defaultValue={getDefaultValue(defaultData?.['hour'], TIMEOFF_HOURS)}
                    {...AVAILABLE_CLASS_NAME_DROPDOWN_TIME}
                    onSelect={handleSelectTime}
                />
                <DropdownTime
                    id="minutes-length-timeoff"
                    unit="m"
                    subText="mins"
                    options={TIMEOFF_MINUTES}
                    defaultValue={getDefaultValue(defaultData?.['minute'], TIMEOFF_MINUTES)}
                    {...AVAILABLE_CLASS_NAME_DROPDOWN_TIME}
                    onSelect={handleSelectTime}
                />
            </div>
        </div>
    );
};

export default forwardRef(AvailableTimeSlot);
