import React from 'react';

const LoadingPreviewEmail = () => {
    return (
        <div className="flex-column scrolls wrap-loading">
            <div className="wrap-form bg-white">
                <div className="form">
                    <div className="form__label field-h20">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="form__field field-h20">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="line" />
                <div className="form">
                    <div className="form__label field-h20">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="form__field field-h20">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>

            <div className="scrolls overflow-hidden">
                <div className="rows border-none to-email field-h20">
                    <div className="loading --animation --line --onefifth" />
                </div>
                <div className="wrap-content-email flex-column gap-30">
                    <div className="logo loading" />
                    <div className="loading --animation --line --onefifth" />
                    <div className="flex-1">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --fourth mt-2" />
                    </div>
                    <div className="loading --animation --line --half" />
                    <div className="loading --animation --line --onefifth" />
                    <div className="line" />
                    <div className="loading --animation --line --onefifth" />
                    <div className="flex-1">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --fourth mt-2" />
                    </div>
                    <div className="loading --animation --line --half" />
                    <div className="button-email" />
                    <div className="line" />
                    <div className="loading --animation --line --onefifth" />
                    <div className="flex-1">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --fourth mt-2" />
                    </div>
                    <div className="loading --animation --line --half" />
                </div>
            </div>
        </div>
    );
};

export default LoadingPreviewEmail;
