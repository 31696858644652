import React, { useContext } from 'react';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconHashtag from 'assets/icon/IconHashtag';

const PO = () => {
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);

    function _handleChangeValue(e) {
        onUpdateInvoiceDataContext({ po_number: e.target.value });
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconHashtag />
                PO
            </div>
            <div className="detail">
                <input
                    className="field-input"
                    type="text"
                    defaultValue={invoiceData.po_number}
                    onBlur={(e) => _handleChangeValue(e)}
                />
            </div>
        </div>
    );
};

export default PO;
