import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TagItem from './TagItem';

const TagDropButton = (
    {
        data = [],
        deleteWithId = false,
        onCloseDropdown = () => {},
        onRemoveTag = () => {},
        onChange = () => {},
        onKeyPress = () => {},
        onClearTag = () => {},
        type = 1,
        isTagsSideMenu = false,
        isTagsAccount = false,
        classWrapper = 'box-tags',
        autoFocus = false,
        placeholder
    },
    ref
) => {
    const { t } = useTranslation(['customers']);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        focus: () => refInput.current && refInput.current.focus(),
        setValue: (value) => refInput.current && refInput.current && (refInput.current.value = value)
    }));

    return (
        <>
            <div className={classWrapper}>
                <ListSelected
                    data={data}
                    deleteWithId={deleteWithId}
                    onRemoveTag={onRemoveTag}
                    type={type}
                    isTagsSideMenu={isTagsSideMenu}
                    isTagsAccount={isTagsAccount}
                />
                <input
                    ref={refInput}
                    type="text"
                    className="select-input-tag"
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    placeholder={placeholder ?? t('customers:tag')}
                    autoFocus={autoFocus}
                    spellCheck
                />
                {!!data.length && !isTagsSideMenu && !isTagsAccount && (
                    <div className="close-tags" onClick={onClearTag}>
                        <IconCircleClose />
                    </div>
                )}
            </div>
            {!isTagsSideMenu && !isTagsAccount && (
                <div className="arrow" onClick={onCloseDropdown}>
                    <IconArrowDown />
                </div>
            )}
        </>
    );
};

const ListSelected = ({
    data = [],
    deleteWithId = false,
    onRemoveTag = () => {},
    type = 1,
    isTagsSideMenu = false,
    isTagsAccount = false
}) => {
    if (!data.length) return null;
    return data.map((item) => (
        <TagItem
            key={item.id}
            {...item}
            deleteWithId={deleteWithId}
            onRemoveTag={onRemoveTag}
            type={type}
            isTagsSideMenu={isTagsSideMenu}
            isTagsAccount={isTagsAccount}
        />
    ));
};

export default forwardRef(TagDropButton);
