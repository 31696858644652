export default function IconDeviceTracking({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V12Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 2C23.9706 2 28 6.02944 28 11C28 12.5097 27.6283 13.9326 26.9713 15.1822L26.0857 14.7174C26.6696 13.6067 27 12.342 27 11C27 6.58172 23.4183 3 19 3C14.5817 3 11 6.58172 11 11C11 12.7074 11.5349 14.2899 12.4463 15.5891L11.6265 16.1619C10.6016 14.7005 10 12.9205 10 11C10 6.02944 14.0294 2 19 2ZM19 5C22.3137 5 25 7.68629 25 11C25 12.0068 24.752 12.9558 24.3138 13.789L23.4283 13.3239C23.7934 12.6296 24 11.8389 24 11C24 8.23858 21.7614 6 19 6C16.2386 6 14 8.23858 14 11C14 12.067 14.3342 13.0559 14.9036 13.8678L14.0843 14.4412C13.401 13.4669 13 12.2803 13 11C13 7.68629 15.6863 5 19 5ZM19 8C20.6569 8 22 9.34315 22 11C22 11.5032 21.8761 11.9774 21.6572 12.3939L20.771 11.9301C20.9172 11.6523 21 11.3358 21 11C21 9.89543 20.1046 9 19 9C17.8954 9 17 9.89543 17 11C17 11.4268 17.1337 11.8223 17.3614 12.1471L16.5421 12.7205C16.2005 12.2334 16 11.6401 16 11C16 9.34315 17.3431 8 19 8Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9797 17H20.0203C20.9751 17 21.8985 17.3416 22.6235 17.963L23.6032 18.8027C24.4897 19.5626 25 20.672 25 21.8397V24C25 25.1046 24.1046 26 23 26H9C7.89543 26 7 25.1046 7 24V21.8397C7 20.672 7.51025 19.5626 8.39683 18.8027L9.37653 17.963C10.1015 17.3416 11.0249 17 11.9797 17Z"
                    fill="#C0E5CF"
                />
                <path
                    d="M17 22H10C9.44772 22 9 22.4477 9 23C9 23.5523 9.44772 24 10 24H17C17.5523 24 18 23.5523 18 23C18 22.4477 17.5523 22 17 22Z"
                    fill="#1EAA5C"
                />
                <path
                    d="M22 22H21C20.4477 22 20 22.4477 20 23C20 23.5523 20.4477 24 21 24H22C22.5523 24 23 23.5523 23 23C23 22.4477 22.5523 22 22 22Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V12Z"
                    fill="#4D5054"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 2C23.9706 2 28 6.02944 28 11C28 12.5097 27.6283 13.9326 26.9713 15.1822L26.0857 14.7174C26.6696 13.6067 27 12.342 27 11C27 6.58172 23.4183 3 19 3C14.5817 3 11 6.58172 11 11C11 12.7074 11.5349 14.2899 12.4463 15.5891L11.6265 16.1619C10.6016 14.7005 10 12.9205 10 11C10 6.02944 14.0294 2 19 2ZM19 5C22.3137 5 25 7.68629 25 11C25 12.0068 24.752 12.9558 24.3138 13.789L23.4283 13.3239C23.7934 12.6296 24 11.8389 24 11C24 8.23858 21.7614 6 19 6C16.2386 6 14 8.23858 14 11C14 12.067 14.3342 13.0559 14.9036 13.8678L14.0843 14.4412C13.401 13.4669 13 12.2803 13 11C13 7.68629 15.6863 5 19 5ZM19 8C20.6569 8 22 9.34315 22 11C22 11.5032 21.8761 11.9774 21.6572 12.3939L20.771 11.9301C20.9172 11.6523 21 11.3358 21 11C21 9.89543 20.1046 9 19 9C17.8954 9 17 9.89543 17 11C17 11.4268 17.1337 11.8223 17.3614 12.1471L16.5421 12.7205C16.2005 12.2334 16 11.6401 16 11C16 9.34315 17.3431 8 19 8Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.9797 17H20.0203C20.9751 17 21.8985 17.3416 22.6235 17.963L23.6032 18.8027C24.4897 19.5626 25 20.672 25 21.8397V24C25 25.1046 24.1046 26 23 26H9C7.89543 26 7 25.1046 7 24V21.8397C7 20.672 7.51025 19.5626 8.39683 18.8027L9.37653 17.963C10.1015 17.3416 11.0249 17 11.9797 17Z"
                    fill="#C1C9D4"
                />
                <path
                    d="M17 22H10C9.44772 22 9 22.4477 9 23C9 23.5523 9.44772 24 10 24H17C17.5523 24 18 23.5523 18 23C18 22.4477 17.5523 22 17 22Z"
                    fill="#4D5054"
                />
                <path
                    d="M22 22H21C20.4477 22 20 22.4477 20 23C20 23.5523 20.4477 24 21 24H22C22.5523 24 23 23.5523 23 23C23 22.4477 22.5523 22 22 22Z"
                    fill="#4D5054"
                />
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                d="M20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V12Z"
                fill="#8d4afc"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 2C23.9706 2 28 6.02944 28 11C28 12.5097 27.6283 13.9326 26.9713 15.1822L26.0857 14.7174C26.6696 13.6067 27 12.342 27 11C27 6.58172 23.4183 3 19 3C14.5817 3 11 6.58172 11 11C11 12.7074 11.5349 14.2899 12.4463 15.5891L11.6265 16.1619C10.6016 14.7005 10 12.9205 10 11C10 6.02944 14.0294 2 19 2ZM19 5C22.3137 5 25 7.68629 25 11C25 12.0068 24.752 12.9558 24.3138 13.789L23.4283 13.3239C23.7934 12.6296 24 11.8389 24 11C24 8.23858 21.7614 6 19 6C16.2386 6 14 8.23858 14 11C14 12.067 14.3342 13.0559 14.9036 13.8678L14.0843 14.4412C13.401 13.4669 13 12.2803 13 11C13 7.68629 15.6863 5 19 5ZM19 8C20.6569 8 22 9.34315 22 11C22 11.5032 21.8761 11.9774 21.6572 12.3939L20.771 11.9301C20.9172 11.6523 21 11.3358 21 11C21 9.89543 20.1046 9 19 9C17.8954 9 17 9.89543 17 11C17 11.4268 17.1337 11.8223 17.3614 12.1471L16.5421 12.7205C16.2005 12.2334 16 11.6401 16 11C16 9.34315 17.3431 8 19 8Z"
                fill="#dbcbff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9797 17H20.0203C20.9751 17 21.8985 17.3416 22.6235 17.963L23.6032 18.8027C24.4897 19.5626 25 20.672 25 21.8397V24C25 25.1046 24.1046 26 23 26H9C7.89543 26 7 25.1046 7 24V21.8397C7 20.672 7.51025 19.5626 8.39683 18.8027L9.37653 17.963C10.1015 17.3416 11.0249 17 11.9797 17Z"
                fill="#dbcbff"
            />
            <path
                d="M17 22H10C9.44772 22 9 22.4477 9 23C9 23.5523 9.44772 24 10 24H17C17.5523 24 18 23.5523 18 23C18 22.4477 17.5523 22 17 22Z"
                fill="#8d4afc"
            />
            <path
                d="M22 22H21C20.4477 22 20 22.4477 20 23C20 23.5523 20.4477 24 21 24H22C22.5523 24 23 23.5523 23 23C23 22.4477 22.5523 22 22 22Z"
                fill="#8d4afc"
            />
        </svg>
    );
}
