export const TASK_DEFAUL_VALUE = {
    id: '',
    title: '',
    start: '',
    end: '',
    date: '',
    active: 1,
    job: null,
    customer: {},
    assignee: {},
    completed_at: null,
    completed_by: null,
    type: 'task'
};

export const TYPE_TASK = {
    CUSTOMER: 'customer',
    JOB: 'job'
};
