import ButtonSave from 'app/components/button/ButtonSave';
import CustomerPhoneLabel from 'app/components/phoneLabel';
import SearchQBModal from 'app/components/quickbooks/SearchQBModal';
import { editCustomerAccount } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/App';
import IconSearch from 'assets/icon/IconSearch';
import IconTooltip from 'assets/icon/IconTooltip';
import { clientQuery } from 'common/utils/ApiUtils';
import { validateEmail } from 'common/utils/EmailUtils';
import React, { Fragment, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectStatusCustomer } from '../../add/components/information';
import SelectSource from '../../add/components/information/SelectSource';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import CustomerTags from '../layouts/CustomerTags';
import IconTrash from 'assets/icon/IconTrash';
import GDModalWarning from 'app/components/modal/ModalWarning';

const EditInformation = ({ onEdit = () => {}, onEditSuccess = () => {}, renderHideBalance = () => {}, refAlert }) => {
    const { t } = useTranslation(['common', 'customers']);
    const { customer, _handleUpdateCustomer, _handleSortPayment, _handleDeleteCustomer } =
        useContext(CustomerDetailContext);
    const { id, account_number, status, qb_id, first_name, last_name, email, company, source, title = '' } = customer;
    const phones = customer.phones || [];
    const customerQBAddon = useSelector(({ auth }) => auth.user.settings.addons.quickbooks);

    const refButtonSave = useRef(null);
    const refForm = useRef(null);
    const refPhone = useRef(null);
    const refSources = useRef(null);
    const refQBModal = useRef(null);
    const refStatus = useRef(null);
    const refWarning = useRef(null);

    const _handleSaveChange = () => {
        refAlert.current.closeStatusBar();
        const emailValue = refForm.current['email'].value;
        if (emailValue && !validateEmail(emailValue)) {
            _handleShowAlert({ message: t('email_is_not_a_valid_email_address') });
            return refButtonSave.current.removeLoading();
        }

        const dataSubmit = {};
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { type, value, name } = elements[index];
            if (type === 'text' && name && name !== 'phone') dataSubmit[name] = value;
            if (type === 'text' && name === 'title') dataSubmit[name] = value.trim() || '';
        }

        const sourceSelect = refSources.current.getValue();
        dataSubmit['phones'] = refPhone.current._getValue();
        dataSubmit['source'] = sourceSelect.id;
        dataSubmit['status'] = refStatus.current.getValue();

        clientQuery(
            editCustomerAccount(id),
            { data: { profile: dataSubmit }, toFormData: false, method: 'PUT' },
            (res) => _handleSaveSuccess(res, { ...dataSubmit, source: sourceSelect.id ? sourceSelect : '' }),
            _handleSaveFailed
        );
    };

    const _handleSaveSuccess = (res, data) => {
        refButtonSave.current.removeLoading();
        const { first_name = '', last_name = '' } = data || {};
        _handleUpdateCustomer({
            ...data,
            full_name: first_name?.concat(' ', last_name).trim(),
            email: data.email.trim(),
            account_number: data.account_no
        });
        onEditSuccess({ message: res.message, data });
        _handleSortPayment();
    };

    const _handleSaveFailed = ({ message }) => {
        refButtonSave.current.removeLoading();
        _handleShowAlert({ message });
    };

    const _handleSelectQBId = (id) => {
        if (id) refForm.current['qb_id'].value = id;
    };

    const _handleSearchQB = () => {
        refQBModal.current._open(qb_id, id);
    };

    const _handleCloseEdit = () => {
        _handleSortPayment();
        onEdit();
    };

    const _handleWaringDelete = () => {
        refWarning.current._open({
            title: t('customers:confirm_delete_customer'),
            description: t('customers:confirm_delete_customer_desc'),
            isLargeContent: true
        });
    };

    const _handleConfirmWarning = () => {
        _handleDeleteCustomer((messageFail) => {
            if (!refAlert.current) return;

            _handleShowAlert({ message: messageFail });
            document.querySelector('.wrapper-columns.wrapper-profile.scrolls').scrollTop = 0;
            _handleCloseWarning();
        });
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
    };

    const _handleShowAlert = ({ message, type = LIST_STATUS.ERROR }) => {
        refAlert.current.showStatusBar({
            id: 'error',
            message,
            type: type
        });
    };

    return (
        <Fragment>
            <form ref={refForm} className="form-edit">
                <RowInput
                    label={t('customers:account_#')}
                    defaultValue={account_number}
                    name={'account_no'}
                    toolTip={t('customers:account_customer_profile_tooltip')}
                    required
                    autoFocus
                />
                <RowInput
                    label={t('customers:status')}
                    customRowInfo={<SelectStatusCustomer ref={refStatus} defaultValue={status} />}
                />
                {!!customerQBAddon && (
                    <RowInput
                        label={t('customers:quickbooks_id')}
                        classNameInfo="rows__info flex-1 flexcenter"
                        name="qb_id"
                        customRowInfo={
                            <Fragment>
                                <input
                                    className="field-input"
                                    type="text"
                                    name="qb_id"
                                    placeholder={t('customers:quickbooks_id')}
                                    defaultValue={qb_id}
                                />
                                <div
                                    className="v2-btn-default --icon-lg btn-quickbook btn-modal"
                                    onClick={_handleSearchQB}
                                >
                                    <IconSearch />
                                </div>
                            </Fragment>
                        }
                    />
                )}
                <RowInput label={t('customers:first_name')} defaultValue={first_name} name={'first_name'} required />
                <RowInput label={t('customers:last_name')} defaultValue={last_name} name={'last_name'} />
                <RowInput label={t('customers:title')} defaultValue={title} name="title" />
                <RowInput label={t('customers:email')} defaultValue={email} name={'email'} />
                <RowInput label={t('customers:company')} defaultValue={company} name={'company'} />
                <div className="rows mt-0 has-phone">
                    <div className="rows__label">{t('customers:phone')}</div>
                    <div className="rows__field flex-1 has-many-field">
                        <CustomerPhoneLabel
                            ref={refPhone}
                            firstFieldClass="col --col-input first-field"
                            secondFieldClass="col --col-dropdown second-field"
                            defaultSelected={phones.length && phones}
                            isGetAllType
                            classWrapper="has-many-field__items phone-field"
                        />
                    </div>
                </div>
                <RowInput
                    label={t('customers:source')}
                    customRowInfo={<SelectSource ref={refSources} defaultValue={source} isEditAccount />}
                />
                <CustomerTags
                    isEditAccount
                    classWrapper="rows rows-has-tag mt-0"
                    classLabel="rows__label"
                    classInfo="rows__info flex-1"
                />
                {renderHideBalance()}
                <div className="head-main d-flex justify-space-between border-top-black-extradark">
                    <div className="v2-btn-default has-icon has-bg-red --delete" onClick={_handleWaringDelete}>
                        <IconTrash />
                        {t('customers:confirm_delete_customer')}
                    </div>
                    <div className="wrap-button">
                        <div className="v2-btn-default --transparent " onClick={_handleCloseEdit}>
                            {t('cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            title="Save"
                            wrapClass="v2-btn-main ml-2"
                            onSave={_handleSaveChange}
                        />
                    </div>
                </div>
            </form>

            {!!customerQBAddon && <SearchQBModal ref={refQBModal} onSaveId={_handleSelectQBId} />}

            <GDModalWarning
                ref={refWarning}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('yes')}
                            className="v2-btn-main"
                            onSave={_handleConfirmWarning}
                        />
                    </div>
                }
            />
        </Fragment>
    );
};

export default EditInformation;

const RowInput = ({
    label = '',
    defaultValue = '',
    name = '',
    required = false,
    autoFocus = false,
    toolTip = '',
    customRowInfo = '',
    classNameInfo = 'rows__info flex-1'
}) => {
    return (
        <div className="rows mt-0">
            <div className="rows__label">
                {label}
                {required && <span className="required red-default mx-1">*</span>}
                {!!toolTip && (
                    <span className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext top ml-3">{toolTip}</span>
                    </span>
                )}
            </div>
            <div className={classNameInfo}>
                {customRowInfo || (
                    <input
                        className="field-input --account"
                        type="text"
                        name={name}
                        placeholder={label}
                        defaultValue={defaultValue}
                        autoFocus={autoFocus}
                        spellCheck
                    />
                )}
            </div>
        </div>
    );
};
