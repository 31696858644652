import GridLoading from 'app/components/grid/components/GridLoading';
import Switch from 'app/components/switch';
import { getUrlManageCommissionUser } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconTooltip from 'assets/icon/IconTooltip';
import { resetCommissionData } from 'common/redux/actions/commission';
import { getSettingByUserCommission } from 'common/redux/actions/commissionAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { useRef, useImperativeHandle, forwardRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TYPES_OPTION_ACTIVE } from '../const';
import RepeatItem from './RepeatItem';

const LineItemsCommission = (
    { updateOptions = () => {}, userSelected = null, setDisableButtonSave = () => {} },
    ref
) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isFirstTime, isFirstTimeUsers } = useSelector((state) => state.commissionReducer);
    const refItems = useRef([]);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true
    });

    const { id: userId, production_active: productionActive, sale_active: saleActive } = userSelected || {};

    useImperativeHandle(ref, () => ({
        _getLineItems,
        _onSaveSetup,
        _setLoading: _handleSetLoading
    }));

    const _onSaveSetup = (callback = () => {}) => {
        const items = refItems.current.map((item) => item._getItemValue());

        const _success = () => {
            callback();
            (!isFirstTime || !isFirstTimeUsers) && dispatch(resetCommissionData());
        };
        clientQuery(getUrlManageCommissionUser(userId), { method: 'PUT', data: { items } }, _success);
    };

    const _getLineItems = (shouldLoading = false) => {
        if (shouldLoading) dispatchState({ isLoading: true });
        const _success = ({ data }) => {
            dispatchState({
                data,
                isLoading: false
            });
        };
        const _fail = () => {
            dispatchState({ isLoading: false });
        };

        dispatch(getSettingByUserCommission({ id: userId }, _success, _fail));
    };

    const _onChangeOption = (e, keyword) => {
        updateOptions({ userId: userId, keyword, value: e.target.checked ? 1 : 0 });
    };

    const _renderRows = () => {
        if (state.isLoading) return <GridLoading />;
        return state.data.map((item, index) => {
            return (
                <RepeatItem
                    item={item}
                    key={item.id}
                    ref={(el) => (refItems.current[index] = el)}
                    userSelected={userSelected}
                    setDisableButtonSave={setDisableButtonSave}
                />
            );
        });
    };

    const _handleSetLoading = (isLoading = false) => {
        dispatchState({ isLoading });
    };

    return (
        <div className="tables table-setup scrolls-x">
            <div className="rows --fixed --header">
                <div className="col --line-item">
                    <p className="col-label">{t('commission:line_items')}</p>
                </div>
                <div className="col --cost pr-2">
                    <p className="col-label">{t('settings:cost')}</p>
                </div>
                <div className="col --commission d-flex">
                    <Switch
                        checked={!!productionActive}
                        handleToggle={(e) => _onChangeOption(e, TYPES_OPTION_ACTIVE.PRODUCTION)}
                        value={`production-commission-${userId}`}
                    />
                    <span className="mx-1">{t('commission:production_commission')}</span>
                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext bottom">{t('commission:tooltip_production_commission')}</span>
                    </div>
                </div>
                <div className="col-divider"></div>
                <div className="col --commission d-flex">
                    <Switch
                        checked={!!saleActive}
                        handleToggle={(e) => _onChangeOption(e, TYPES_OPTION_ACTIVE.SALE)}
                        value={`sales-commission-${userId}`}
                    />
                    <span className="mx-1">{t('commission:sales_commission')}</span>
                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext bottom ml-n3">{t('commission:tooltip_sales_commission')}</span>
                    </div>
                </div>
            </div>
            <div className="tables-list">{_renderRows()}</div>
        </div>
    );
};
export default forwardRef(LineItemsCommission);
