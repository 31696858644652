export const COLUMN_HEADER_STYLE = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#191919',
    overflow: 'hidden',
    position: 'relative'
};
export const COLUMN_HEADER_DATE_STYLE = {
    marginRight: '4px',
    fontWeight: '600'
};
