import ButtonSave from 'app/components/button/ButtonSave';
import { getUpdatePhoneType } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { updatePhoneTypes } from 'common/redux/actions/phones';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

const EditPhoneLabel = forwardRef(({ onShowAlert = () => {} }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [state, dispatchState] = useReducer(reducer, { isOpen: false, data: {} });
    const { isOpen, data } = state;
    const refInput = useRef(null);
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, data });
    };

    const _close = () => {
        dispatchState({ isOpen: false, data: {} });
    };

    const _handleSubmitUpdate = () => {
        const idUpdate = data.id;

        const _handleUpdateSuccess = ({ data: dataUpdate }) => {
            dispatch(updatePhoneTypes({ idUpdate, dataUpdate }));
        };

        const _handleUpdateFinally = ({ message, success }) => {
            const typeAlert = success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR;

            onShowAlert({
                id: typeAlert,
                message: success ? t('update_successfully') : message,
                type: typeAlert
            });
            _close();
        };

        clientQuery(
            getUpdatePhoneType(idUpdate),
            { data: { name: refInput.current.value }, method: 'PUT' },
            _handleUpdateSuccess,
            null,
            _handleUpdateFinally
        );
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="edit_phone_type"
            isOpen
            style={{ overlay: { zIndex: 11, background: 'transparent' } }}
            portalClassName="ReactModalPortal_customer"
            onRequestClose={_close}
            className="modal --has-contain container-modal --form-no-border open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --md">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('edit_phone_label')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal has-form">
                    <p className="has-form__label">{t('phone_label')}</p>
                    <input
                        ref={refInput}
                        className="field-input"
                        type="text"
                        defaultValue={data.name}
                        placeholder={t('phone_label')}
                        autoFocus
                        spellCheck
                    />
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                        {t('cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        className="v2-btn-main"
                        title={t('update')}
                        onSave={_handleSubmitUpdate}
                    />
                </div>
            </div>
        </ReactModal>
    );
});

export default EditPhoneLabel;
