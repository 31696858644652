import { call, delay, put } from 'redux-saga/effects';

import { ADDONS_WORK_LOG_USER, workLogUserUpdate } from 'app/const/api/V2';
import { WORK_LOGS_ACTIONS } from 'common/redux/actions/worklogs';
import { clientQuery } from 'common/utils/ApiUtils';
import { AUTH_TYPE } from 'common/redux/actions/authAction';

export function* getWorklogUsers({ actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(clientQuery, ADDONS_WORK_LOG_USER, { data: {}, method: 'GET' });
        const { data, message, success } = response;
        if (!success) throw new Error(message);

        const finalData = data.sort((a, b) => a.full_name.localeCompare(b.full_name));
        actionSuccess({ data: finalData });
        yield put({
            type: WORK_LOGS_ACTIONS.GET_LIST_USERS_SUCCESS,
            payload: finalData
        });
    } catch (error) {
        actionFailed(error);
        yield put({ type: WORK_LOGS_ACTIONS.GET_LIST_USERS_FAIL });
    }
}

export function* updateWorklogUser({ payload = {} }) {
    yield delay(300);
    const data = { ...payload };
    delete data?.id;
    delete data?.locked;
    const response = yield call(clientQuery, workLogUserUpdate(payload.id), { data, toFormData: false, method: 'PUT' });
    if (response?.success) {
        if (!!payload.locked) yield put({ type: AUTH_TYPE.UPDATE_TRACKING, payload });
        yield put({ type: WORK_LOGS_ACTIONS.UPDATE_LIST_WORKLOGS_USERS_SUCCESS, payload });
    }
}
