export const STATUS_BAR_ACTIONS = {
    ADD_ITEM_TO_LIST_STATUS: 'ADD_ITEM_TO_LIST_STATUS',
    ADD_LIST_TO_LIST_STATUS: 'ADD_LIST_TO_LIST_STATUS',
    REMOVE_ITEM_FROM_LIST_STATUS: 'REMOVE_ITEM_FROM_LIST_STATUS',
    CLEAR_LIST_STATUS: 'CLEAR_LIST_STATUS'
};

export const addItemToListStatus = ({ message = '', type = 'error' }) => {
    return {
        type: STATUS_BAR_ACTIONS.ADD_ITEM_TO_LIST_STATUS,
        payload: { message: message, type: type, id: Date.now() }
    };
};

export const addListToListStatus = ({ listStatus = [] }) => {
    return {
        type: STATUS_BAR_ACTIONS.ADD_LIST_TO_LIST_STATUS,
        payload: listStatus
    };
};

export const removeItemFormListStatus = (idOfItem) => {
    return {
        type: STATUS_BAR_ACTIONS.REMOVE_ITEM_FROM_LIST_STATUS,
        payload: idOfItem
    };
};

export const clearListStatusBar = () => {
    return {
        type: STATUS_BAR_ACTIONS.CLEAR_LIST_STATUS
    };
};
