import React, { useContext } from 'react';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';

const MODES = {
    DAY_WEEK: 'dayofweek',
    DAY_YEAR: 'dayofyear'
};

const RepeatYear = () => {
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);

    const jobCurrentceDetail = invoiceRepeatData.offset;
    const finalModeYear = jobCurrentceDetail.byyearday;

    function _handleChangeMode(e, value) {
        _sendDataToContext(jobCurrentceDetail.interval, value);
    }

    function _handleChangeValue(interval) {
        _sendDataToContext(interval, finalModeYear);
    }

    function _sendDataToContext(interval, mode) {
        const refValue = {
            ...invoiceRepeatData,
            offset: {
                ...jobCurrentceDetail,
                byyearday: mode,
                interval: interval
            }
        };
        updateInvoiRepeatContext(refValue);
    }

    return (
        <div className={'tab-panel active'}>
            <div className="rows mt-3">
                <div className="txt pl-6"> Every </div>
                <div className="details field-every">
                    <FormInterval interval={jobCurrentceDetail.interval} onChange={_handleChangeValue} />
                    <span className="text-last">year</span>
                </div>
            </div>
            <div className="rows mt-3">
                <div className="txt pl-6"> Repeat By </div>
                <div className="details d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button
                                className={`tab-items ${finalModeYear === MODES.DAY_WEEK ? 'active-tab-selector' : ''}`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES.DAY_WEEK)}
                            >
                                Day Of The Week
                            </button>
                            <button
                                className={`tab-items ${finalModeYear === MODES.DAY_YEAR ? 'active-tab-selector' : ''}`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES.DAY_YEAR)}
                            >
                                Day Of The Year
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RepeatYear;
