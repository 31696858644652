import { TYPE_ESTIMATE } from 'common/redux/actions/estimateAction';

const estimateReducer = (state = {
    estimateData: null,
    closeEstimate: 0
}, action) => {
    switch (action.type) {
        case TYPE_ESTIMATE.ACTION_OPEN_ESTIMATE_DETAIL:
            return { ...state, estimateData: action.params };
        case TYPE_ESTIMATE.ACTION_CLOSE_ESTIMATE_DETAIL:
            return { ...state, estimateData: null, closeEstimate: Date.now() };

        default:
            return state;
    }
};

export default estimateReducer;
