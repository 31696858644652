import { COMMON, KEY_REPORT_LOCAL_STORAGE, DELAY_TIME, TOTAL_BILLED, LIST_STATUS } from 'app/const/App';
import {
    REVENUE_BY_CLIENT_LIST_FILER,
    REVENUE_BY_CLIENT_TABS,
    SORT_BY,
    TOTAL_COLLECTED
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { URL_REPORT_REVENUE_BY_CLIENT, URL_EXPORT_PAYMENT_CLIENT } from 'app/const/api/Export';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { MonthTabGridView } from './components/MouthTab';
import { DetailTabGridView } from './components/DetailsTab';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import {
    removeObjParams,
    getLocalParamsReport,
    initStateRevenueReport,
    getListRevenueSuccess,
    convertParamFields
} from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { handleAbortController } from 'app/modules/customer/utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_REVENUE_BY_CLIENT_DETAIL_GET_LIST, REPORT_REVENUE_BY_CLIENT_MONTH_GET_LIST } from 'app/const/Api';
import GDStatusBar from 'app/components/status/statusbar';

const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportRevenueByClient() {
    const MONTH_TAB = REPORT_TYPE.REVENUE_BY_CLIENT_MONTH_TAB;
    const DETAIL_TAB = REPORT_TYPE.REVENUE_BY_CLIENT_DETAIL_TAB;

    const keyTotalRow = {
        [MONTH_TAB]: 'total_customer',
        [DETAIL_TAB]: 'total_payments_received'
    };

    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.REVENUE_BY_CLIENT);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVENUE_BY_CLIENT);
    const initialState = initStateRevenueReport({ keyCurrentTab, monthTab: MONTH_TAB, detailTab: DETAIL_TAB });
    const [dataReport, dispatchActionReport] = useReducer(reducer, initialState);
    let timer;

    const { currentTab, refreshScreen, isLoadMore: finalIsLoadMore, reloadFilter, rerender } = dataReport;
    const { data: finalData, isLoading: finalIsLoading, total: finalTotal = 0 } = dataReport[currentTab];

    const paramsReport = getLocalParamsReport(keyLocalStore, REPORT_TYPE.REVENUE_BY_CLIENT);
    const abortController = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            getListReport({ ...paramsReport, offset: 0, changeTab: Date.now() });
            setLocalStorage(keyCurrentTab, currentTab);
        }, DELAY_TIME);
        return () => {
            handleAbortController(abortController);
            removeLocalStorage(keyCurrentTab);
            clearTimeout(timer);
        };
    }, [currentTab]);

    useEffect(() => {
        let timeoutId = null;
        if (finalIsLoadMore) {
            timeoutId = setTimeout(() => {
                getListReport({ ...paramsReport, offset: finalData.length });
            }, DELAY_TIME);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [finalIsLoadMore]);

    const getListReport = (params) => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        let objectParams = convertParamFields({ params, isEmptyFields: finalIsLoadMore, isPaymentMethods: true });
        delete objectParams?.changeTab;
        delete objectParams?.currentPage;

        switch (currentTab) {
            case MONTH_TAB:
                objectParams.order = objectParams['orderMonth'];
                objectParams = removeObjParams(objectParams, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for',
                    'customer_id'
                ]);

                return clientQuery(
                    REPORT_REVENUE_BY_CLIENT_MONTH_GET_LIST,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );
            case DETAIL_TAB:
                objectParams.order = objectParams['orderDetail'];
                objectParams = removeObjParams(objectParams, ['orderMonth', 'orderDetail', 'year', 'customer_id']);

                return clientQuery(
                    REPORT_REVENUE_BY_CLIENT_DETAIL_GET_LIST,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );
            default:
                break;
        }
    };

    const getListSuccess = (response, { changeTab }) => {
        dispatchActionReport((prev) =>
            getListRevenueSuccess({
                prev,
                response,
                changeTab,
                keyTotalRow,
                monthTab: MONTH_TAB,
                titleTotalRow: 'client'
            })
        );
    };

    function getListFailed({ isAborted = false, message }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({
            ...prev,
            [currentTab]: { ...prev[currentTab], isLoading: false },
            isLoadMore: false
        }));
        refAlert.current?.handeAddStatus({ id: 'revenue_by_client_error', message, type: LIST_STATUS.ERROR });
    }

    const _handleChangeFilter = (params, type) => {
        switch (type) {
            case SORT_BY:
                _handleUpdate();
                break;
            default:
                break;
        }

        dispatchActionReport({ reloadFilter: params.sale === TOTAL_BILLED, rerender: params.sale === TOTAL_COLLECTED });
    };

    const _handleUpdate = () => {
        dispatchActionReport({ [currentTab]: { ...initialState[currentTab] } });
        getListReport(getLocalStorage(keyLocalStore) || paramsReport);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.REVENUE_BY_CLIENT,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate,
            keyOrder: currentTab === DETAIL_TAB ? 'orderDetail' : 'orderMonth'
        });
    };

    function _renderHeaderLeft() {
        return <ReportTabs list={REVENUE_BY_CLIENT_TABS} onChange={handleChangeTab} tabActive={currentTab} />;
    }

    function _renderHeaderBottom() {
        return (
            <HeaderBottom
                classNameHeader="header --filter"
                typeReport={REPORT_TYPE.REVENUE_BY_CLIENT}
                filters={REVENUE_BY_CLIENT_LIST_FILER.ROW_2}
                keyLocalStore={keyLocalStore}
                forceRerenderFilter={rerender || reloadFilter}
                params={paramsReport}
                currentTab={currentTab}
                isNotShowDateRange={currentTab !== DETAIL_TAB}
                isLoading={finalIsLoading}
                handleChangeFilter={_handleChangeFilter}
                handleUpdate={_handleUpdate}
            />
        );
    }

    function _getParamsExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case MONTH_TAB:
                paramsExport.order = paramsExport['orderMonth'];
                paramsExport = removeObjParams(paramsExport, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);
                return paramsExport;
            case DETAIL_TAB:
                paramsExport.order = paramsExport['orderDetail'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'year']);
                return paramsExport;
            default:
                return null;
        }
    }

    function _getUrlExport() {
        switch (currentTab) {
            case MONTH_TAB:
                return URL_REPORT_REVENUE_BY_CLIENT;
            case DETAIL_TAB:
                return URL_EXPORT_PAYMENT_CLIENT;
            default:
                return null;
        }
    }

    function _getPageExport() {
        switch (currentTab) {
            case MONTH_TAB:
                return LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT;
            case DETAIL_TAB:
                return LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT_DETAIL;
            default:
                return null;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab, [tab]: { isLoading: true } });
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: finalTotal })}
                activePrint
                params={_getParamsExport()}
                url={_getUrlExport()}
                pageExport={_getPageExport()}
                isDisable={finalIsLoading}
                refresh={refreshScreen}
            />
        );
    }

    const _handleLoadMore = () => {
        const numberData = finalData?.length;

        if (!!numberData && numberData < finalTotal && !finalIsLoadMore) {
            dispatchActionReport((prev) => ({ ...prev, isLoadMore: true }));
        }
    };

    const _renderGirdView = () => {
        const commonProps = {
            dataReport: dataReport[currentTab],
            paramsReport,
            handleActionHeader: _handleActionHeader,
            handleOpenInvoice: _handleOpenInvoice,
            isLoadMore: finalIsLoadMore,
            onScrollToEnd: _handleLoadMore
        };

        switch (currentTab) {
            case MONTH_TAB:
                return <MonthTabGridView monthTab={currentTab} {...commonProps} />;
            case DETAIL_TAB:
                return <DetailTabGridView {...commonProps} />;
            default:
                break;
        }
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVENUE_BY_CLIENT}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <div className="tab-contents">{_renderGirdView()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRevenueByClient;
