import { API_VERSION } from 'app/const/URL';

export const ADDON_MATERIAL_USAGE_DETAIL = `${API_VERSION}addons/material`;

export const actionMaterialColumn = (columnId) => `${API_VERSION}addons/material/types/${columnId}`;

export const actionMaterialItem = (itemId) => `${API_VERSION}addons/material/items/${itemId}`;

export const ADD_NEW_MATERIAL_ITEM = `${API_VERSION}addons/material/items`;

export const ACION_MATERIAL_ADDON_OPTIONS = `${API_VERSION}addons/material/options`;

export const ACTION_CHANGE_ORDER_ITEM = `${API_VERSION}addons/material/items/orders`;
