import { CUSTOMER_MERGE_TYPE } from 'app/const/Customers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconClose from 'assets/icon/IconClose';

const ProgressBarImport = ({
    import_number_key,
    count = 0,
    total = 0,
    success = 0,
    type = null,
    failed = parseInt(count - success),
    percent = parseInt((count / total) * 100),
    onRemove = () => {},
    onReloadCustomer = () => {},
    hideProgress = () => {}
}) => {
    const { t } = useTranslation(['common', 'customers']);
    const isMergeProgress = type === CUSTOMER_MERGE_TYPE;

    const _handleRemove = () => onRemove([import_number_key]);
    const _handleReload = (e) => {
        e.preventDefault();
        onReloadCustomer();
    };

    const _checkProgress = () => {
        if (count === total || !count) onRemove([import_number_key]);
        else hideProgress([import_number_key]);
    };

    if (count >= total) {
        return (
            <div className="alert --success">
                <p className="alert__description">
                    {t(`customers:${isMergeProgress ? 'merged_count_customers' : 'imported_count_customers'}`, {
                        count,
                        total
                    })}
                    <Link to="/#" onClick={_handleReload}>
                        &nbsp; {t('reload_page')}.
                    </Link>
                </p>
                <div className="alert__btn svg-white-stroke" onClick={_handleRemove}>
                    <IconClose />
                </div>
            </div>
        );
    }

    const _renderTitleProgress = () => {
        let text = isMergeProgress ? 'merging_customer_merged' : 'progressing_customer_imported';

        if (count >= total && failed) {
            if (isMergeProgress) {
                text = 'merging_customer_merged_failed';
            } else {
                text = 'progressing_customer_imported_failed';
            }
        }

        if (failed) {
            if (isMergeProgress) {
                text = 'merging_customer_merging_failed';
            } else {
                text = 'progressing_customer_importing_failed';
            }
        }

        return t(`customers:${text}`, {
            count: `${success}`,
            total: `${total}`,
            failed: `${failed}`
        });
    };

    return (
        <div className="wrap-process --has-icon">
            <p className="wrap-process__label">{_renderTitleProgress()}</p>
            <div className={`progress-bar --blue per-${percent}`}>
                <span />
            </div>
            <div className="v2-btn-default --delete" onClick={_checkProgress}>
                {t('remove')}
            </div>
        </div>
    );
};

export default ProgressBarImport;
