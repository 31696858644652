import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_SALES_TAX_GET_LIST,
    REPORT_TAX_SUMMARY_GET_LIST,
    REPORT_LIST_METHOD_TAX_SUMMARY
} from 'app/const/Api';

export function* getListReportSalesTax({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_SALES_TAX_GET_LIST,
            params
        );
        if (response && response.success) {
            actionSuccess(response, 'total_sales');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportTaxSummary({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_TAX_SUMMARY_GET_LIST,
            params
        );
        if (response && response.success) {
            actionSuccess(response, 'total_tax_summary');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodTaxSummary({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_LIST_METHOD_TAX_SUMMARY
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
