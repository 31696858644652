import { forwardRef, useContext, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CUSTOMERS } from 'app/const/Route';
import { customerOpportunity } from 'app/const/api/V2';
import { TYPE_LOAD_TIME_LINE } from 'app/modules/customer/const/Email';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { CONFIDENCE_VALUE, TYPE_CONTACT_PIPELINE } from 'app/const/opportunity';
import { openSmsDetail } from 'common/redux/actions/sms/smsAction';
import { getAvatarCustomer } from 'common/utils/StringUtils';
import { addBranchPath } from 'app/const/Branch';

const ServicePipeline = ({ refCols = () => {}, onShowModalConfirm = () => {} }, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { data: dataPipelines } = useSelector(({ pipelines }) => pipelines);

    const { onOutGoingCall } = useContext(VOIPContext);
    const settings = useSelector(({ auth }) => auth.user.settings);
    const { inbox_email, smsplivo } = settings.addons;
    const isVoipActive = checkIsEnableVoip(settings, false);

    useImperativeHandle(ref, () => ({
        moveOpp: _handleChangeStatus,
        update: _handleUpdate,
        delete: _handleDelete,
        contact: _handleContact
    }));

    const _handleChangeStatus = ({ destination, source, isQuery = true }) => {
        if (!destination || destination.droppableId === source.droppableId) return;

        const refSource = refCols().current[parseInt(source.droppableId)];
        const refDestination = refCols().current[parseInt(destination.droppableId)];
        const itemRemove = refSource.getItem({ indexItem: source.index });
        refSource.onRemoveItem({ indexItem: source.index });
        refDestination.onAddItem({ item: itemRemove });
        const { id: pipelineId, status } = refDestination.getPipeline();

        const { id: oppId, customer_id, user, close_date, value, contact_id, note, confidence, period } = itemRemove;

        const _handleSuccess = ({ data }) => {
            refDestination.setItem(data);
        };

        const _handleFail = ({ message, statusCode }) => {
            onShowModalConfirm({ message, statusCode });
            _handleChangeStatus({ destination: source, source: destination, isQuery: false });
        };

        if (!isQuery) return;

        clientQuery(
            customerOpportunity(customer_id, oppId),
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    pipeline_id: pipelineId,
                    confidence: CONFIDENCE_VALUE[status.toLowerCase()] ?? confidence,
                    user_id: user?.id,
                    close_date,
                    value: value.value,
                    contact_id,
                    note,
                    period: period.value
                }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleUpdate = (data, oldData) => {
        const obj = {};

        dataPipelines[0].pipelines.forEach((item, index) => {
            if (item.id === data.pipeline.id) obj['destination'] = { droppableId: index };
            if (item.id === oldData.pipeline.id) obj['source'] = { droppableId: index };
        });

        refCols().current[parseInt(obj.source.droppableId)].onRemoveItem({ id: data.id });
        refCols().current[parseInt(obj.destination.droppableId)].onAddItem({ item: { ...oldData, ...data } });
    };

    const _handleDelete = ({ id, pipelineIndex }) => {
        refCols().current[parseInt(pipelineIndex)].onRemoveItem({ id });
    };

    const _handleContact = ({ type, contact }) => {
        const { email, phones, customer_id, first_name, last_name, id: contactId } = contact;
        const finalPhone = phones[0]?.phone;

        switch (type) {
            case TYPE_CONTACT_PIPELINE.EMAIL:
                !!inbox_email
                    ? history.push({
                          pathname: addBranchPath(`${CUSTOMERS}/${customer_id}`),
                          state: { type: TYPE_LOAD_TIME_LINE.ADD_EMAIL, email }
                      })
                    : (location.href = `mailto:${email}`);
                break;
            case TYPE_CONTACT_PIPELINE.SMS:
                const full_name = `${first_name} ${last_name}`;
                !!smsplivo
                    ? dispatch(
                          openSmsDetail({
                              ...contact,
                              id: customer_id,
                              full_name,
                              avatar: getAvatarCustomer(full_name),
                              default_phone: finalPhone,
                              isPhoneAction: true
                          })
                      )
                    : (location.href = `sms:${finalPhone}`);
                break;
            case TYPE_CONTACT_PIPELINE.CALL:
                isVoipActive
                    ? onOutGoingCall({ toPhoneNumber: finalPhone, customerId: customer_id, contactId })
                    : (location.href = `tel:${finalPhone}`);
                break;
        }
    };
};

export default forwardRef(ServicePipeline);
