export default () => {
    return (
        <div className="wrap-loading details-job mt-3">
            <div className="rows">
                <div className="txt flex-auto">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="details detail-schedule d-flex">
                    <div className="schedule-user flex-1 mt-2">
                        <div className="loading --animation --line btn-sm mr-2" />
                        <div className="loading --animation --line btn-sm mt-0" />
                    </div>
                    <div className="loading v2-btn-default --grey --icon-lg">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        </div>
    );
};
