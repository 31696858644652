import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import AddressInfo from 'app/modules/customer/add/components/address/AddressInfo';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import ManageUnit from './ManageUnit';

const LocationServices = (
    {
        customerId = '',
        options = [],
        selected = {},
        dataUpdate = {},
        isUpdate = false,
        isLoading = true,
        onSelect = () => {},
        onVisible = () => {},
        onChange = () => {}
    },
    ref
) => {
    const isActiveUnit = useSelector(({ auth }) => auth.user.settings.addons.mdu_tracking);
    const refDropdown = useRef(null);
    const refLat = useRef(null);
    const refLng = useRef(null);
    const refFormLocation = useRef(null);
    const refLocationName = useRef(null);
    const refLocationNote = useRef(null);
    const refManageUnit = useRef(null);
    const refAddressInfo = useRef(null);

    const serviceDefault = dataUpdate.address?.service || {};

    useImperativeHandle(ref, () => ({ _getValue, _handleGetDataAddress }));

    const _getValue = () => {
        const data = {
            location_name: refLocationName.current.value,
            location_note: refLocationNote.current.value,
            service_address: {
                ..._handleGetDataAddress(),
                address_lat: refLat.current.value,
                address_lng: refLng.current.value
            }
        };

        if (!!isActiveUnit) data['mdu'] = refManageUnit.current.getValue();

        return data;
    };

    const _handleGetDataAddress = () => {
        const result = refAddressInfo.current.getValue();

        delete result['lat'];
        delete result['lng'];
        delete result['formattedAddress'];

        return result;
    };

    const _handleAutocomplete = (name, value, isStreet2 = false) => {
        if (!isStreet2) {
            refLat.current.value = value.lat;
            refLng.current.value = value.lng;
        }

        const dataComplete = _handleGetDataAddress();

        if (isStreet2) {
            dataComplete['street2'] = value.name;
        } else {
            dataComplete['street1'] = value.name;
        }

        onChange(null, dataComplete);
    };

    const _handleChange = (e) => {
        onChange(e.target.name.split('_')[1], refFormLocation.current[`${e.target.name}`].value);
    };

    return (
        <div className="column-location">
            <span className="title">Service Address</span>
            <div className="rows --multi">
                <div className="col col-2 first-field">
                    <div className="txt txt-ellipsis">
                        <span className="red-delete">*&nbsp;</span>
                        Address to
                    </div>
                    <CalendarDropdown
                        id="address_to_location"
                        ref={refDropdown}
                        options={options}
                        keyGetValue="id"
                        keyGetName="full_name"
                        keyGetKey="id"
                        selected={selected.full_name}
                        selectedOption={selected}
                        isLoading={isLoading}
                        onVisible={onVisible}
                        onSelect={onSelect}
                    />
                </div>
                <div className="col col-2 second-field">
                    <div className="txt txt-ellipsis">Location Name</div>
                    <input
                        ref={refLocationName}
                        className="field-input"
                        type="text"
                        autoFocus
                        defaultValue={dataUpdate.location_name}
                        spellCheck
                    />
                </div>
            </div>

            <form ref={refFormLocation} className="rows">
                <div className="txt txt-ellipsis">
                    <span className="red-delete">*&nbsp;</span>
                    Service Address
                </div>
                <div className="has-many-address">
                    <AddressInfo
                        ref={refAddressInfo}
                        type="service"
                        shouldCheckExisted={false}
                        defaultValue={
                            isUpdate
                                ? {
                                      street1: serviceDefault.line1,
                                      street2: serviceDefault.line2,
                                      city: serviceDefault.city,
                                      state: serviceDefault.state,
                                      zip: serviceDefault.zip
                                  }
                                : {}
                        }
                        onChange={_handleChange}
                        onAutoComplete={_handleAutocomplete}
                    />
                </div>
            </form>

            <div className="rows">
                <div className="col">
                    <div className="txt txt-ellipsis">Map Coordinates</div>
                    <div className="d-flex">
                        <div className="first-field col-2">
                            <input
                                ref={refLat}
                                className="field-input"
                                type="number"
                                defaultValue={parseFloat(dataUpdate?.address?.service?.address_lat) || ''}
                                placeholder="Latitude"
                            />
                        </div>
                        <div className="second-field col-2">
                            <input
                                ref={refLng}
                                className="field-input"
                                type="number"
                                defaultValue={parseFloat(dataUpdate?.address?.service?.address_lng) || ''}
                                placeholder="Longitude"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="rows">
                <div className="txt txt-ellipsis">Location Note</div>
                <textarea
                    ref={refLocationNote}
                    name="location_note"
                    className="field-textarea --no-resize --h-120"
                    cols={30}
                    rows={10}
                    defaultValue={dataUpdate.location_note || ''}
                    spellCheck
                />
            </div>
            {!!isActiveUnit && (
                <ManageUnit
                    ref={refManageUnit}
                    customerId={customerId}
                    locationId={dataUpdate.id}
                    units={dataUpdate.units}
                />
            )}
        </div>
    );
};

LocationServices.displayName = 'LocationServices';
export default forwardRef(LocationServices);
