import React from 'react';

const MultipleBranchLoading = () => {
    return (
        <div className="boxs-wrapper wrap-loading --addon">
            <div className="boxs boxs--no-border --hasline">
                <div className="boxs__header page-heading">
                    <div className="header-title">
                        <div className="header-title__svg v2-btn-default loading --animation --light --transparent mr-0" />
                        <div className="title flex-column gap-4">
                            <div className="loading --animation --light --line --half" />
                            <div className="v2-btn-default loading --animation --light --transparent field-h16 btn-x-sm" />
                        </div>
                    </div>
                    <div className="loading --onefifth switch ml-0">
                        <div className="loading --animation --light --line --full" />
                    </div>
                </div>
                <div className="boxs__contents mt-3 mb-0">
                    <div className="description loading-lines">
                        <div className="loading --animation --light --line --half" />
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer loading">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="description loading-lines">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="is-divider --horizontal my-4" />
                    <div className="flex-betweenitems">
                        <div className="loading --animation --line --onefourth" />
                        <div className="loading --animation --line --onetenth mt-0" />
                    </div>
                </div>
                <div className="boxs__footer --multi loading">
                    <div className="loading" />
                    <div className="v2-btn-default --transparent loading btn-sm p-0">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultipleBranchLoading;
