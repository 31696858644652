/**
 * Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return {start:number, end:number}[]
 */
const combineChunks = (chunks) => {
    return chunks
        .sort((first, second) => first.start - second.start)
        .reduce((processedChunks, nextChunk) => {
            if (processedChunks.length === 0) {
                return [nextChunk];
            } else {
                const prevChunk = processedChunks.pop();
                if (nextChunk.start <= prevChunk.end) {
                    const endIndex = Math.max(prevChunk.end, nextChunk.end);
                    processedChunks.push({ highlight: false, start: prevChunk.start, end: endIndex });
                } else {
                    processedChunks.push(prevChunk, nextChunk);
                }
                return processedChunks;
            }
        }, []);
};

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return {start:number, end:number}[]
 */
const findChunks = ({ searchWords, textToHighlight }) => {
    const textCheck = textToHighlight;
    return searchWords.reduce((chunks, word) => {
        if (!word) return chunks;
        const regex = new RegExp(word, 'gi');
        let match;
        while ((match = regex.exec(textCheck))) {
            const start = match.index;
            const end = regex.lastIndex;
            if (end > start) chunks.push({ highlight: false, start, end });
            if (match.index === regex.lastIndex) regex.lastIndex++;
        }
        return chunks;
    }, []);
};

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
const fillInChunks = ({ chunksToHighlight, totalLength }) => {
    const allChunks = [];
    const append = (start, end, highlight) => {
        if (end - start > 0) allChunks.push({ start, end, highlight });
    };

    if (chunksToHighlight.length === 0) {
        append(0, totalLength, false);
    } else {
        let lastIndex = 0;
        chunksToHighlight.forEach((chunk) => {
            append(lastIndex, chunk.start, false);
            append(chunk.start, chunk.end, true);
            lastIndex = chunk.end;
        });
        append(lastIndex, totalLength, false);
    }
    return allChunks;
};

/**
 * Creates an array of chunk objects representing both higlightable and non highlightable pieces of text that match each search word.
 * @return Array of "chunks" (where a Chunk is { start:number, end:number, highlight:boolean })
 */
export const findAll = ({ searchWords, textToHighlight }) => {
    const chunks = findChunks({
        searchWords: Array.isArray(searchWords) ? searchWords : [searchWords],
        textToHighlight
    });
    return fillInChunks({
        chunksToHighlight: combineChunks(chunks),
        totalLength: textToHighlight ? textToHighlight.length : 0
    });
};
