import IconStar from 'assets/icon/IconStar';

export const ButtonPreview = ({ text, onTranslate = () => { } }) => {
  return (
    <div className="v2-btn-main btn-pay-online"><span>{onTranslate(text)}</span></div>
  )
}

export const RatingStarPreview = ({ onTranslate = () => { } }) => {
  const listRating = [
    { id: 1, name: onTranslate("drip:terrible") },
    { id: 2, name: onTranslate("drip:poor") },
    { id: 3, name: onTranslate("drip:average") },
    { id: 4, name: onTranslate("drip:good") },
    { id: 5, name: onTranslate("drip:great") },
  ]

  const renderStars = () => {
    return listRating.map(item => {
      return (
        <li key={item.id}>
          <IconStar />
          <p>{item.name}</p>
        </li>
      )
    })
  }

  return (
    <div className="txt-feedback">
      <h5> {onTranslate("drip:tell_us_what_you_think")} </h5>
      <p> {onTranslate("drip:thanks_for_your_business")}{onTranslate("drip:how_would_you_rate_your_experience")}</p>
      <ul className="rate">
        {renderStars()}
      </ul>
    </div>
  )
}

export const LinkPreview = ({ text, onTranslate = () => { } }) => {
  return (
    <span className="cursor-pointer manage-link">{onTranslate(text)}</span>
  )
}