export const LoadingFormResult = () => (
    <div className="wrap-loading form-results">
        <div className="loading --animation --line --half" />
        <div className="form-results__items">
            <div className="item-header flex-betweenitems">
                <div className="label-id flex-1">
                    <div className="label-id__btn btn-md bg-white-cultured" />
                </div>
                <div className="label-id__btn btn-sm bg-white-cultured" />
            </div>
            <div className="item-information mt-5">
                <div className="loading --animation --line --onefourth mb-2" />
                <div className="loading --animation --line --half mb-2" />
                <div className="loading --animation --line --half mb-2" />
            </div>
            <div className="item-list-card mt-5">
                <div className="loading --animation --line --onefourth mb-2" />
                <div className="item-list-card__line">
                    <div className="info-card">
                        <div className="card-number btn-lg bg-white-cultured" />
                    </div>
                    <div className="status-btn btn-x-sm bg-white-cultured" />
                </div>
            </div>
        </div>
    </div>
);
