import React, { Fragment, forwardRef, useEffect, useId, useImperativeHandle, useReducer, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import Checkbox from 'app/components/checkbox';
import IconSaveStick from 'assets/icon/IconSaveStick';
import IconClose from 'assets/icon/IconClose';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import { DELAY_TIME } from 'app/const/App';
import NameTagPdf from './NameTagPdf';

const RowItemPdf = (
    {
        item = {},
        isUploading = false,
        isHidden = false,
        idSelected = '',
        onSelectDoc = () => {},
        onRemoveDoc = () => {},
        onUploadSuccess = () => {},
        onUpdateItem = () => {},
        onRemoveError = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const refInput = useRef(null);
    const refRowAction = useRef(null);
    const refInterval = useRef(null);
    const refTimer = useRef(DELAY_TIME);
    const refDataSuccess = useRef(null);
    const refPercent = useRef(0);
    const refIsSuccess = useRef(null);
    const uuid = useId(null);

    const { id: finalId, name: finalName, active } = item;
    const isActive = finalId === idSelected;

    const [state, dispatchState] = useReducer(reducer, {
        isEdit: false,
        active: !!active,
        name: finalName,
        isError: false
    });
    const { isEdit, active: checked, name: docName, isError } = state;

    useImperativeHandle(ref, () => ({
        uploadSuccess: _handleUploadSuccess,
        uploadFail: _handleUploadFail
    }));

    useEffect(() => {
        if (!isUploading) return;
        _handleProgress();

        return () => {
            clearTimeout(refInterval.current);
        };
    }, []);

    const _handleProgress = async () => {
        const elTitle = refRowAction.current.querySelector('span');
        const elProgress = refRowAction.current.querySelector('.circular-progress');
        const newPercent = `${refPercent.current}%`;
        elTitle.innerText = newPercent;
        elProgress.setAttribute('style', `--progress: ${newPercent}`);
        refInterval.current = setTimeout(_handleProgress, refTimer.current);
        refPercent.current++;
        if (refPercent.current > 99) {
            clearTimeout(refInterval.current);
            refIsSuccess.current && onUploadSuccess(refDataSuccess.current);
            refIsSuccess.current = true;
        }
    };

    const _handleUploadSuccess = (res) => {
        if (refIsSuccess.current) {
            onUploadSuccess(res);
            return;
        }
        refIsSuccess.current = true;
        refDataSuccess.current = res;
        refTimer.current = 10;
    };

    const _handleUploadFail = () => {
        clearTimeout(refInterval.current);
        dispatchState((prev) => ({ ...prev, isError: true }));
    };

    const _handleToggleEdit = (value = false) => {
        dispatchState((prev) => ({ ...prev, isEdit: value }));
    };

    const _handleChecked = (e) => {
        const checked = e.target.checked ? 1 : 0;
        dispatchState((prev) => ({ ...prev, active: checked }));
        _handleChangeItem({ key: 'active', value: checked });
    };

    const _handleSaveChange = () => {
        const newDocName = refInput.current.value;
        dispatchState((prev) => ({ ...prev, name: newDocName, isEdit: false }));
        _handleChangeItem({ key: 'name', value: newDocName });
    };

    const _handleSelectDocs = () => {
        if (isUploading || isActive) return;
        onSelectDoc(item);
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleChangeItem = ({ key, value }) => {
        onUpdateItem({ data: { ...item, ...state, [key]: value } });
    };

    const _renderContent = () => {
        if (isEdit) {
            return (
                <Fragment>
                    <div className="rows__name">
                        <input
                            ref={refInput}
                            className="field-input"
                            defaultValue={docName}
                            onClick={_stopPropagation}
                            autoFocus
                        />
                    </div>
                    <div className="rows__action" onClick={_stopPropagation}>
                        <div className="v2-btn-main --icon-lg svg-white-stroke" onClick={_handleSaveChange}>
                            <IconSaveStick />
                        </div>
                        <div className="v2-btn-default --icon-lg" onClick={() => _handleToggleEdit(false)}>
                            <IconClose />
                        </div>
                    </div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <div className="rows__name">
                    <NameTagPdf name={docName} />
                </div>
                <div ref={refRowAction} className="rows__action" onClick={_stopPropagation}>
                    {isUploading ? (
                        <Fragment>
                            <span>{isError ? t('error') : '0%'}</span>
                            <div style={{ '--progress': '0%' }} className="circular-progress" />
                            {isError ? (
                                <div
                                    className="v2-btn-default --icon-lg has-bg-red btn-modal"
                                    onClick={() => onRemoveError(finalId)}
                                >
                                    <IconTrash />
                                </div>
                            ) : null}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="v2-btn-default --icon-lg" onClick={() => _handleToggleEdit(true)}>
                                <IconEdit />
                            </div>
                            <div
                                className="v2-btn-default --icon-lg has-bg-red btn-modal"
                                onClick={() => onRemoveDoc({ id: finalId, name: docName })}
                            >
                                <IconTrash />
                            </div>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    };

    return (
        <div
            className={classNames('rows', {
                '--uploading': isUploading,
                '--error': isError,
                active: isActive,
                'dp-hide': isHidden
            })}
            onClick={_handleSelectDocs}
        >
            <div className="rows__icon" onClick={_stopPropagation}>
                <div className="check-items">
                    <Checkbox id={uuid} onChangeValue={_handleChecked} checked={checked} value={item.id} />
                </div>
            </div>
            {_renderContent()}
        </div>
    );
};

export default forwardRef(RowItemPdf);
