import { SCHEDULES_LIST_ACTIONS } from '../actions/schedulesList';

const initialState = { data: [], isFirstTime: true };
const schedulesList = (state = initialState, action) => {
    switch (action.type) {
        case SCHEDULES_LIST_ACTIONS.GET_LIST_SUCCESS:
            return { ...state, data: action.payload.data || [], isFirstTime: false };
        default:
            return state;
    }
};
export default schedulesList;
