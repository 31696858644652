import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Slider from 'react-slick';

import { ACCEPTED_ATTACHMENTS } from 'app/const/App';
import { getIconTypeFile } from 'app/const/icon/IconsType';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import IconDownload from 'assets/icon/IconDownload';
import IconTrash from 'assets/icon/IconTrash';
import { isFileImage } from 'common/utils/FileUtils';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import ItemAttachPreview from './components/ItemAttachPreview';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment, formatTime } from 'common/utils/DateUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionAttachment } from 'app/const/Api';
import { actionDeleteLocationPhoto } from 'app/const/api/V2';
import AttachmentDesciption from './Description';
import { GDGlobalImg } from '../img';

Modal.setAppElement(document.getElementById('root'));

const GDPreviewAttach = forwardRef(
    (
        {
            attachments = [],
            hasDetail = false,
            preventSetSource = false,
            onDeleteAttachment = () => {},
            noteId,
            commentId,
            idLocation,
            isHaveDesciption = false,
            onUpdateAttachment = () => {},
            isCallApiDelete = true
        },
        ref
    ) => {
        const { t } = useTranslation();
        const refSlider = useRef(null);
        const [state, dispatchState] = useReducer(reducer, {
            setting: { isOpen: false },
            sources: attachments,
            currentAttach: {}
        });

        const { company = {}, profile = {} } = useSelector(({ auth }) => auth?.user);
        const dateFormat = company?.date_format;
        const formatDisplay = convertPhpFormatToMoment(dateFormat);

        const { sources, setting, currentAttach } = state;
        const {
            url: urlCurrent,
            name: nameCurrent,
            id: idCurrent,
            created_date,
            created,
            user: createdBy
        } = currentAttach;
        const createdDateCurrent = created_date || created || '';

        useImperativeHandle(ref, () => ({
            openPreview,
            openPreviewWithAttach,
            closePreview
        }));

        useEffect(() => {
            if (setting?.isOpen) {
                setTimeout(() => {
                    const contentDiv = document.getElementById('gd-preview-attach');
                    contentDiv &&
                        contentDiv
                            .querySelector('.content-viewattachment')
                            .addEventListener('click', _handleClickClose);
                    _handleFocus();
                }, 0);
            }
        }, [setting]);

        // Set source when preventSetSource = false
        // If preventSetSource = true, setSources by openPreviewWithAttach function
        useEffect(() => {
            if (!preventSetSource) {
                const newAttachments = [];
                attachments.forEach((attach) => {
                    if (ACCEPTED_ATTACHMENTS.includes(attach?.type)) {
                        newAttachments.push(attach);
                    }
                });

                dispatchState({ sources: newAttachments });
            }
        }, [attachments, preventSetSource]);

        const _handleFocus = () => {
            if (refSlider.current && refSlider.current.innerSlider) {
                refSlider.current.innerSlider.list.setAttribute('tabindex', 0);
                refSlider.current.innerSlider.list.focus();
            }
        };

        const openPreview = (url = '') => {
            let indexInit = sources.findIndex((item) => item?.url === url);

            if (indexInit < 0) indexInit = 0;

            const newState = { isOpen: true, initialSlide: indexInit };
            if (hasDetail) newState['currentAttach'] = sources[indexInit];
            dispatchState(newState);
        };

        const openPreviewWithAttach = (attachments = [], url, openEditDescription = false) => {
            const newAttachments = [...attachments];
            let indexInit = newAttachments.findIndex((item) => item?.url === url);

            if (indexInit < 0) indexInit = 0;

            const newState = {
                sources: newAttachments,
                setting: { isOpen: true, initialSlide: indexInit }
            };

            if (hasDetail) newState['currentAttach'] = { ...newAttachments[indexInit], openEditDescription };

            dispatchState(newState);
        };

        const closePreview = () => {
            dispatchState({ setting: { ...setting, isOpen: false } });
        };

        const settingsSlide = React.useMemo(() => {
            const renderThumbnailItem = (index) => {
                const { type, url, thumbnail_url, name } = sources[index];

                if (isFileImage(type))
                    return (
                        <div draggable="false" title={name} className="thumb-item" data-close-preview="not-close">
                            <GDGlobalImg
                                draggable="false"
                                data-close-preview="not-close"
                                thumbnailUrl={thumbnail_url}
                                src={url}
                            />
                        </div>
                    );

                return (
                    <div title={name} draggable="false" className="thumb-item" data-close-preview="not-close">
                        {getIconTypeFile(type, name)}
                    </div>
                );
            };

            const _afterChange = (current) => {
                hasDetail && dispatchState({ currentAttach: sources[current] });
                _handleFocus();
            };

            return {
                customPaging: renderThumbnailItem,
                appendDots: (dots) => <ul onClick={(e) => _handleClickClose(e)}> {dots} </ul>,
                afterChange: _afterChange,
                dotsClass: 'thumbnail scrolls-x',
                className: 'content-viewattachment',
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
                initialSlide: setting?.initialSlide || 0,
                draggable: true,
                dots: true,
                // lazyLoad: 'ondemand',
                prevArrow: (
                    <div data-close-preview="not-close">
                        <div className="attach-btn svg-white" data-close-preview="not-close">
                            <IconArrow isPrev data-close-preview="not-close" />
                        </div>
                    </div>
                ),
                nextArrow: (
                    <div data-close-preview="not-close">
                        <div className="attach-btn svg-white" data-close-preview="not-close">
                            <IconArrow data-close-preview="not-close" />
                        </div>
                    </div>
                )
            };
        }, [hasDetail, setting?.initialSlide, sources]);

        const _handleClickClose = (e) => {
            const shouldClose = e.target.getAttribute('data-close-preview');
            const tagName = e.target.tagName;

            if (
                shouldClose !== 'not-close' &&
                tagName.toLowerCase() !== 'li' &&
                tagName !== 'path' &&
                tagName !== 'svg'
            )
                closePreview();
        };

        const _renderSlides = useMemo(() => {
            return sources.map((slide, index) => (
                <ItemAttachPreview
                    key={slide?.thumbnail_url || slide?.url || index.toString()}
                    {...slide}
                    onClosePreview={closePreview}
                />
            ));
        }, [sources]);

        const _renderListAttachment = () => {
            if (sources.length === 1) {
                return (
                    <div className="content-viewattachment is-separate">
                        {sources.map((item, index) => {
                            return (
                                <ItemAttachPreview
                                    key={item?.thumbnail_url || item?.url || index.toString()}
                                    {...item}
                                    onClosePreview={closePreview}
                                />
                            );
                        })}
                    </div>
                );
            }

            return (
                <Slider {...settingsSlide} ref={refSlider} className="content-viewattachment">
                    {_renderSlides}
                </Slider>
            );
        };

        const _handleDownLoad = () => {
            fetch(urlCurrent).then((res) =>
                res.blob().then((file) => {
                    const tempUrl = window.URL.createObjectURL(file);
                    const aTag = document.createElement('a');
                    aTag.href = tempUrl;
                    aTag.download = nameCurrent || urlCurrent;
                    document.body.appendChild(aTag);
                    aTag.click();
                    aTag.remove();
                })
            );
        };

        const _handleDeleteAttachment = () => {
            if (confirm(t('are_you_sure_you_want_to_delete_this_file'))) {
                if (isCallApiDelete) {
                    clientQuery(idLocation ? actionDeleteLocationPhoto(idLocation) : actionAttachment(idCurrent), {
                        method: 'DELETE'
                    });
                }

                onDeleteAttachment(idCurrent, noteId || commentId);
                closePreview();
            }
        };

        const _handleUpdateDesciprion = (id, value, type) => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    sources: prev.sources.map((item) => {
                        if (item.id === id) {
                            return {
                                ...item,
                                [type]: value
                            };
                        }
                        return item;
                    }),
                    currentAttach: {
                        ...prev.currentAttach,
                        [type]: value
                    }
                };
            });
            onUpdateAttachment(id, value, type);
        };

        if (!setting?.isOpen) return null;
        const getInfoAdded = () => {
            let infoDesc = `Added on ${createdDateCurrent || formatTime(new Date(), `${formatDisplay} hh:mm A`)}`;
            const createdByName = createdBy?.full_name || profile?.name;
            if (createdByName) infoDesc += ` by ${createdByName}`;
            return infoDesc;
        };
        return (
            <Modal
                isOpen
                id="gd-preview-attach"
                onRequestClose={closePreview}
                shouldCloseOnOverlayClick
                className={classNames('gd-preview-attach', { 'has-details': hasDetail })}
                style={{
                    content: {
                        maxHeight: '100%',
                        maxWidth: '100%',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'transparent',
                        border: '0',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    overlay: { zIndex: 99999, background: 'rgba(146,155,169,.8)' }
                }}
            >
                {_renderListAttachment()}

                {hasDetail && (
                    <div className="detail-menu">
                        <div className="detail-menu__label name">{nameCurrent || ''}</div>
                        <div className="detail-menu__label info">{getInfoAdded()}</div>

                        {isHaveDesciption && (
                            <AttachmentDesciption
                                attachmentDetail={{
                                    id: currentAttach.id,
                                    description: currentAttach.description || '',
                                    openEditDescription: currentAttach.openEditDescription
                                }}
                                onUpdate={_handleUpdateDesciprion}
                            />
                        )}

                        <div className="v2-btn-default" onClick={_handleDownLoad}>
                            <IconDownload />
                            {t('download')}
                        </div>
                        <div className="v2-btn-default" onClick={_handleDeleteAttachment}>
                            <IconTrash />
                            {t('delete')}
                        </div>
                    </div>
                )}

                <div onClick={closePreview} className="btn-close-menu attach-btn">
                    <IconClose />
                </div>
            </Modal>
        );
    }
);

GDPreviewAttach.propTypes = { attachments: PropTypes.array };
export default GDPreviewAttach;
