import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import FieldInput from './FieldInput';
import LoadingModalVerify from './LoadingModalVerify';
import { TYPE_MICRODEPOSITS_ACH, TYPE_MICRODEPOSITS_ACH_VERIFY } from 'app/const/Customers';
import ButtonSave from 'app/components/button/ButtonSave';
import { clientQuery } from 'common/utils/ApiUtils';
import { verifyBankAccount } from 'app/const/api/V2';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import { getValueElements } from 'common/utils/ObjectUtils';

const VerifyBankACH = ({ onVerifySuccess = () => {} }, ref) => {
    const { t } = useTranslation('customers');
    const refForm = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const stripe = useStripe();

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        item: null,
        isLoading: false,
        typeMicroDeposits: TYPE_MICRODEPOSITS_ACH.DESCRIPTOR_CODE,
        msgError: null
    });
    const { isVisible, typeMicroDeposits, isLoading, item: finalItem, msgError } = state;
    const { customer_id, id: bankId } = finalItem || {};
    const isDepositCode = typeMicroDeposits === TYPE_MICRODEPOSITS_ACH.DESCRIPTOR_CODE;

    useImperativeHandle(ref, () => ({
        _open,
        _close
    }));

    const _open = (data) => {
        stripe
            .handleNextAction({ clientSecret: data.item.meta.client_secret })
            .then((res) => {
                const nextAction = res.setupIntent.next_action;
                const typeAction = nextAction[nextAction['type']]['microdeposit_type'];

                dispatchState((prev) => ({
                    ...prev,
                    ...data,
                    isVisible: true,
                    typeMicroDeposits: typeAction
                }));
            })
            .catch((err) => {
                dispatchState((prev) => ({
                    ...prev,
                    msgError: err.message,
                    isVisible: true
                }));
            });
    };

    const _close = () => {
        dispatchState((prev) => ({ ...prev, isVisible: false, msgError: null }));
    };

    const _handleVerify = () => {
        refAlert.current?.clearAllStatusBar();
        const { deposit_code, first_deposit, second_deposit } = getValueElements(refForm.current.elements);

        if (isDepositCode && !deposit_code) {
            _showAlert({ message: t('cannot_be_blank', { name: t('deposit_code') }) });
            return;
        }

        const _handleSuccess = () => {
            onVerifySuccess({ id: finalItem.id });
            _close();
        };

        const _handleFail = ({ message }) => {
            _showAlert({ message });
        };

        const data = { type: TYPE_MICRODEPOSITS_ACH_VERIFY[typeMicroDeposits] };
        if (isDepositCode) data.descriptor_code = `SM${deposit_code}`;
        else {
            data.first_deposit = first_deposit;
            data.second_deposit = second_deposit;
        }

        clientQuery(verifyBankAccount(customer_id, bankId), { method: 'PUT', data }, _handleSuccess, _handleFail);
    };

    const _showAlert = ({ message, success = false }) => {
        refAlert.current.handeAddStatus({
            id: 'err_verify',
            message,
            type: success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
        refButtonSave.current.setLoading(false);
    };

    const _renderFields = () => {
        if (isDepositCode) return <FieldInput label="deposit_code" name="deposit_code" unit="SM" maxLength={4} />;

        return (
            <Fragment>
                <FieldInput label="first_deposit" name="first_deposit" unit="0." />
                <FieldInput label="second_deposit" name="second_deposit" unit="0." />
            </Fragment>
        );
    };

    return (
        <ReactModal
            isOpen={isVisible}
            className={classNames('modal container-modal modal-verify-account open', {
                'wrap-loading': isLoading
            })}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            {isLoading ? (
                <LoadingModalVerify />
            ) : (
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label pr-1">
                            {t(isDepositCode ? 'verify_bank_account' : 'microdeposit_amounts_in_cents')}
                        </h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>
                    {!!msgError ? (
                        <div className="body-modal">
                            <div className="rows --alert">
                                <p className="fw-600 word-breakall">{t('common:error')}</p>
                                <p>{msgError}</p>
                            </div>
                        </div>
                    ) : (
                        <form
                            ref={refForm}
                            onSubmit={(e) => e.preventDefault()}
                            className="body-modal has-form flex-column scrolls"
                        >
                            <div className="rows">
                                <GDStatusBar ref={refAlert} />
                            </div>
                            <div className="black-3	fw-500 px-3 mt-3">
                                {t(isDepositCode ? 'desc_descriptor_code_verify' : 'desc_microdeposit_verify')}
                            </div>
                            {_renderFields()}
                        </form>
                    )}

                    <div className="footer-modal btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            className="v2-btn-main"
                            onSave={_handleVerify}
                            disabled={!!msgError}
                        >
                            {t('verify')}
                        </ButtonSave>
                    </div>
                </div>
            )}
        </ReactModal>
    );
};

export default forwardRef(VerifyBankACH);

export const ElementWrapper = ({ children }) => {
    const userSetting = useSelector(({ auth }) => auth.user?.settings);
    const stripeKey = userSetting?.stripe?.key || userSetting?.stripe?.public_key || '';
    const stripePromise = !!stripeKey && loadStripe(stripeKey);

    return <Elements stripe={stripePromise}>{children}</Elements>;
};
