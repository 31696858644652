export default function IconPrice({ isLogTimeLine = false }) {
    if (isLogTimeLine) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM13.1136 9.5H11.206L11.191 10.314L11.081 10.3379L10.8978 10.3862C9.69704 10.7361 8.91196 11.6352 8.92189 12.8167L8.92699 12.9907L8.94842 13.1948C9.02765 13.7266 9.28255 14.1578 9.68979 14.488L9.787 14.562L8.75351 14.5625L8.75569 15.0647L8.76109 15.2565L8.77746 15.4603C8.91548 16.7276 9.70231 17.5411 10.9635 17.826L11.075 17.848L11.0612 18.6818H12.9689L12.982 17.865L13.1012 17.8436L13.2875 17.8005C14.5055 17.4874 15.2335 16.6551 15.24 15.4359L15.2338 15.261L15.2158 15.0788C15.12 14.3635 14.7424 13.8061 14.0928 13.4178L13.996 13.363L15.1534 13.3636L15.1506 12.8608L15.1446 12.6878L15.1264 12.5008C14.9894 11.4568 14.2786 10.6859 13.1993 10.3766L13.099 10.35L13.1136 9.5ZM12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z"
                    fill="#C1C9D4"
                />
                <path
                    d="M12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z"
                    fill="#C1C9D4"
                />
                <path
                    d="M13.1136 9.5H11.206L11.191 10.314L11.081 10.3379L10.8978 10.3862C9.69704 10.7361 8.91196 11.6352 8.92189 12.8167L8.92699 12.9907L8.94842 13.1948C9.02765 13.7266 9.28255 14.1578 9.68979 14.488L9.787 14.562L8.75351 14.5625L8.75569 15.0647L8.76109 15.2565L8.77746 15.4603C8.91548 16.7276 9.70231 17.5411 10.9635 17.826L11.075 17.848L11.0612 18.6818H12.9689L12.982 17.865L13.1012 17.8436L13.2875 17.8005C14.5055 17.4874 15.2335 16.6551 15.24 15.4359L15.2338 15.261L15.2158 15.0788C15.12 14.3635 14.7424 13.8061 14.0928 13.4178L13.996 13.363L15.1534 13.3636L15.1506 12.8608L15.1446 12.6878L15.1264 12.5008C14.9894 11.4568 14.2786 10.6859 13.1993 10.3766L13.099 10.35L13.1136 9.5Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.7867 18.5L12.8077 17.2965C15.1259 17.1238 16.4895 16.0117 16.5 14.1328C16.4895 12.5484 15.514 11.4363 13.1434 10.9438L12.9073 10.893L12.9388 9.1207C13.3269 9.29336 13.5262 9.61328 13.5629 10.05H16.3531C16.3427 8.24219 15.0734 6.97773 12.9755 6.70859L12.9965 5.5H11.507L11.486 6.6832C9.27273 6.88633 7.75699 8.15586 7.77273 9.96875C7.76748 11.5938 8.93182 12.4977 10.8357 12.8937L11.3864 13.0105L11.3549 14.9148C10.6888 14.727 10.3059 14.2648 10.2692 13.5437H7.5C7.51049 15.8898 8.98427 17.073 11.3182 17.2863L11.2972 18.5H12.7867ZM11.4283 10.5172C10.993 10.3344 10.799 10.0957 10.8147 9.76562C10.8199 9.44062 11.014 9.18164 11.4493 9.05469L11.4283 10.5172ZM12.8444 14.9047L12.8706 13.3965C13.3322 13.5895 13.5157 13.823 13.521 14.1328C13.5157 14.4832 13.285 14.7625 12.8444 14.9047Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM13.1136 9.5H11.206L11.191 10.314L11.081 10.3379L10.8978 10.3862C9.69704 10.7361 8.91196 11.6352 8.92189 12.8167L8.92699 12.9907L8.94842 13.1948C9.02765 13.7266 9.28255 14.1578 9.68979 14.488L9.787 14.562L8.75351 14.5625L8.75569 15.0647L8.76109 15.2565L8.77746 15.4603C8.91548 16.7276 9.70231 17.5411 10.9635 17.826L11.075 17.848L11.0612 18.6818H12.9689L12.982 17.865L13.1012 17.8436L13.2875 17.8005C14.5055 17.4874 15.2335 16.6551 15.24 15.4359L15.2338 15.261L15.2158 15.0788C15.12 14.3635 14.7424 13.8061 14.0928 13.4178L13.996 13.363L15.1534 13.3636L15.1506 12.8608L15.1446 12.6878L15.1264 12.5008C14.9894 11.4568 14.2786 10.6859 13.1993 10.3766L13.099 10.35L13.1136 9.5ZM12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7C14 5.89543 13.1046 5 12 5Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4773 18.1818L12.49 17.4244C13.9027 17.3157 14.7337 16.6158 14.74 15.4332C14.7337 14.4361 14.1392 13.7361 12.6946 13.4261L12.5508 13.3942L12.5699 12.2788C12.8065 12.3874 12.9279 12.5888 12.9503 12.8636H14.6506C14.6442 11.7259 13.8707 10.93 12.5923 10.7607L12.6051 10H11.6974L11.6847 10.7447C10.3359 10.8725 9.41228 11.6715 9.42187 12.8125C9.41867 13.8352 10.1282 14.4041 11.2883 14.6534L11.6239 14.7269L11.6048 15.9254C11.1989 15.8072 10.9655 15.5163 10.9432 15.0625H9.25568C9.26207 16.5391 10.1601 17.2837 11.5824 17.418L11.5696 18.1818H12.4773ZM11.6495 13.1577C11.3842 13.0426 11.266 12.8924 11.2756 12.6847C11.2788 12.4801 11.397 12.3171 11.6623 12.2372L11.6495 13.1577ZM12.5124 15.919L12.5284 14.9698C12.8097 15.0913 12.9215 15.2383 12.9247 15.4332C12.9215 15.6538 12.7809 15.8295 12.5124 15.919Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
