import IconClose from 'assets/icon/IconClose';
import React from 'react';
import { useSelector } from 'react-redux';

const ScheduleList = ({ selected = [], onDelete }) => {
    const { users } = useSelector(({ companyUsers }) => companyUsers);

    if (!selected.length) return null;
    return selected.map((item) => {
        const avatar = users.find((user) => user.id === item.user_id)?.avatar || null;
        return (
            <div key={item.id} className="tech-name">
                <AvatarUser avatar={avatar} name={item.name} />
                <span className="txt-ellipsis" title={item.name}>
                    {item.name}
                </span>
                {onDelete ? (
                    <div className="close" onClick={() => onDelete(item.id)}>
                        <IconClose isSmall />
                    </div>
                ) : null}
            </div>
        );
    });
};

const AvatarUser = ({ avatar = '', name = '' }) => {
    if (avatar)
        return (
            <div className="avt-img">
                <img src={avatar} width={24} height={24} alt="" />
            </div>
        );
    return <div className="avt">{name.substring(0, 2)}</div>;
};

export default ScheduleList;
