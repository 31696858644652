import React, { useRef } from 'react';
import EditTopNote from 'app/modules/customer/topnote/edit';
import RenderAttachment from 'app/modules/customer/components/RenderAttachment';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionNotes } from 'app/const/Api';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import IconTopNote from 'assets/icon/IconTopNote';
import Options from 'app/modules/customer/note/detail/Options';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'app/const/Permissions';
import { checkPermission } from 'common/utils/PermissionUtils';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import TemplateOptions from 'app/components/templatenotes';

const TopNote = ({
    topNoteDetail = {},
    onUpdate,
    type = TYPE_NOTE.CUSTOMER,
    jobId = '',
    unitId = '',
    contentEmpty = '',
    isShowLeftIcon = true,
    customerDetail = {},
    titleHeader = '',
    filterTemplate = 6
}) => {
    const { t } = useTranslation();
    const refEditTopNote = useRef(null);
    const refConfirm = useRef(null);

    const finalContent = topNoteDetail.content || '';
    const isEdited = !!!topNoteDetail.edited;
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);

    function _openEditTopNote() {
        const elm = document.getElementById(`form_top_note_detail_${topNoteDetail.id}`);
        elm && elm.classList.toggle('has-edited');
        refEditTopNote.current.toggleShowHide();
    }

    function _handleRemoveTopNote() {
        clientQuery(actionNotes(topNoteDetail.id), { method: 'DELETE' });

        onUpdate({
            ...topNoteDetail,
            content: contentEmpty || `This note will appear on all ${customerDetail.first_name}'s jobs`,
            attachments: [],
            edited: 0
        });
    }

    const _openConfirmDelete = () => {
        refConfirm.current.open();
    };

    const _handleDeleteAttachment = (idAttachDelete) => {
        onUpdate({
            ...topNoteDetail,
            attachments: [...topNoteDetail.attachments].filter((item) => item.id !== idAttachDelete)
        });
    };

    const _handleSelectTemplate = ({ content: newContent }) => {
        refEditTopNote.current.setValue(newContent, true);
    };

    return (
        <>
            <div className="boxs is-note --topnote">
                {isShowLeftIcon && (
                    <div className="left-icons">
                        <IconTopNote />
                    </div>
                )}
                <div className="content elm-parent" id={`form_top_note_detail_${topNoteDetail.id}`}>
                    <div className="header-box">
                        {!isShowLeftIcon && (
                            <div className="header-box__icons">
                                <IconTopNote />
                            </div>
                        )}
                        <div className="name mr-1">
                            <h5 className="name__label">{titleHeader || 'Top Note'}</h5>
                            <p className="name__des word-break">
                                {contentEmpty || `This note will appear on all ${customerDetail.first_name}'s jobs`}
                            </p>
                        </div>
                        {checkPermission(permissionsList, PERMISSIONS.editOrDeleteTopNote) && (
                            <div className="header-box__action">
                                <Options
                                    openFormEdit={_openEditTopNote}
                                    onDelete={_openConfirmDelete}
                                    addCommment={false}
                                    textDelete={'Remove Top Note'}
                                    textEdit={'Edit Top Note'}
                                    isHaveValue={!isEdited && !!finalContent}
                                    content={finalContent}
                                />
                            </div>
                        )}
                        <TemplateOptions isShow typeFilter={filterTemplate} onSelectTemplate={_handleSelectTemplate} />
                    </div>
                    <div className="wrap-content is-complete">
                        <div className="wrap-content-box">
                            <div
                                className={`description ${isEdited ? '--default-topnote' : ''}`}
                                dangerouslySetInnerHTML={{
                                    __html: finalContent
                                }}
                            />
                            <div className="attachment-image scrolls-x">
                                <RenderAttachment
                                    attachments={topNoteDetail.attachments}
                                    topNoteDetail={topNoteDetail}
                                    onDeleteAttachment={_handleDeleteAttachment}
                                />
                            </div>
                        </div>
                    </div>
                    <EditTopNote
                        topNoteDetail={topNoteDetail}
                        ref={refEditTopNote}
                        isEdited={isEdited}
                        onUpdate={onUpdate}
                        type={type}
                        jobId={jobId}
                        unitId={unitId}
                    />
                </div>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('Delete Note')}
                titleConfirm={t('common:confirm')}
                message={t('customers:are_you_sure_delete_note')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleRemoveTopNote}
            />
        </>
    );
};

export default TopNote;
