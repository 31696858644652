import React from 'react';
import { NOTE_REPEAT } from 'app/modules/customer/const/Note';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionNotesRecurring } from 'app/const/Api';
import IconSync from 'assets/icon/IconSync';

const Repeat = ({ noteDetail, onUpdateRepeat }) => {
    if (noteDetail.is_customer_note) {
        return false;
    }

    const isRepeat = noteDetail.is_recurring;

    function _handleEdit() {
        const newRepeat = isRepeat === NOTE_REPEAT.REPEAT ? NOTE_REPEAT.NONE_REPEAT : NOTE_REPEAT.REPEAT;

        const optionsQuery = {
            method: 'PUT',
            data: {
                active: newRepeat
            }
        };

        clientQuery(actionNotesRecurring(noteDetail.id), optionsQuery);

        onUpdateRepeat(newRepeat);
    }

    return (
        <div
            className={`v2-btn-default --icon-lg --transparent tooltip js-toggle-active click-icon ${
                isRepeat ? 'active' : ''
            }`}
            onClick={_handleEdit}
        >
            <div className="click-icon__btn stick">
                <IconSync />
                <span className="tooltiptext top sp-hide">Repeat this job note each time this job recurs</span>
            </div>
            <div className="click-icon__btn unstick">
                <IconSync isActive />
                <span className="tooltiptext top sp-hide">This job note repeats each time this job recurs</span>
            </div>
        </div>
    );
};

export default Repeat;
