import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { updateOrderStatusOnTable } from 'app/modules/settings/utils/statusOrder.js';

import {
    LIST_TAB_BASIC,
    LIST_BUTTON_ACTION_SOURCES as LIST_BUTTONS,
    LIST_BUTTON_MESSAGES_SOURCES as LIST_BUTTON_MESSAGES,
    LIST_ACTION_BASIC
} from 'app/const/Settings';

export const TAB = {
    LIST: LIST_TAB_BASIC,
    DEFAULT_SELECTED: LIST_ACTION_BASIC.ACTIVE
};
const getColumns = ({ actionStatus, currentTab }) => {
    const newColumns = {
        header: {
            name: {
                title: 'sources',
                style: 'col --lg --full flex-1  js-order-by',
                actionType: COMMON.ORDER,
                currentValue: COMMON.ASC
            }
        },
        columns: [
            {
                id: 'name',
                status: true
            }
        ],
        contentConfig: {
            name: {
                id: 'name',
                style: 'col --lg --full flex-1 --name',
                styleText: 'col-label btn-modal',
                type: LIST_TABLE_ITEM_TYPE.LINK
            }
        }
    };
    if (actionStatus) {
        newColumns.header = updateOrderStatusOnTable({ headerTable: newColumns.header, actionStatus: actionStatus });
    }
    if (currentTab && currentTab === LIST_ACTION_BASIC.DELETE) {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.DEFAULT;
        newColumns.contentConfig.name.style = 'col --lg --full flex-1';
    } else {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.LINK;
        newColumns.contentConfig.name.style = 'col --lg --full flex-1 --name';
    }
    return newColumns;
};

export { LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
