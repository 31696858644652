export default function IconSpeaker({ isBroadcast = false }) {
    if (isBroadcast)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.68093 11.0771C5.5472 10.5769 6.41346 10.0768 7.27972 9.57667C9.09657 8.52771 10.5111 6.72568 11.5234 4.17058C11.7268 3.65711 12.308 3.40577 12.8214 3.6092C13.031 3.69223 13.2064 3.84366 13.3191 4.0389L18.0953 12.3114C18.3714 12.7897 18.2076 13.4013 17.7293 13.6774C17.534 13.7902 17.3064 13.8336 17.0834 13.8007C14.3645 13.3998 12.0966 13.7239 10.2797 14.7728C9.41346 15.273 8.5472 15.7731 7.68093 16.2732C6.72435 16.8255 5.50117 16.4978 4.94888 15.5412L3.94888 13.8091C3.3966 12.8525 3.72435 11.6294 4.68093 11.0771Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.27979 9.57665L10.2798 14.7728"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.68091 16.2732L9.68091 19.7373C9.95705 20.2156 10.5686 20.3795 11.0469 20.1034C11.5505 19.8126 11.7788 19.2069 11.5925 18.6561L10.279 14.7732"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.2072 5.57708C15.1637 5.02479 16.3869 5.35254 16.9392 6.30913L17.9392 8.04118C18.4915 8.99776 18.1637 10.2209 17.2072 10.7732"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.1713 6.17515L21.7693 4.67515"
                    stroke="#4D5054"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.1713 4.4431L19.4033 2.57707"
                    stroke="#4D5054"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.1713 7.9072L22.4033 7.77323"
                    stroke="#4D5054"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 7.5C7.50027 7.5 8.50055 7.5 9.50082 7.5C11.5987 7.5 13.7248 6.64668 15.879 4.94004C16.3119 4.59707 16.9409 4.66998 17.2838 5.10288C17.4238 5.27959 17.5 5.49842 17.5 5.72386V15.2761C17.5 15.8284 17.0523 16.2761 16.5 16.2761C16.2746 16.2761 16.0557 16.2 15.879 16.06C13.7248 14.3533 11.5987 13.5 9.50082 13.5C8.50055 13.5 7.50027 13.5 6.5 13.5C5.39543 13.5 4.5 12.6046 4.5 11.5V9.5C4.5 8.39543 5.39543 7.5 6.5 7.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.50085 7.5V13.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.5 13.5V17.5C6.5 18.0523 6.94772 18.5 7.5 18.5C8.08146 18.5 8.58208 18.0896 8.69612 17.5194L9.5 13.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M17.5 7.5C18.6046 7.5 19.5 8.39543 19.5 9.5V11.5C19.5 12.6046 18.6046 13.5 17.5 13.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
