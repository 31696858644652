import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import { CUSTOMERS_CREDITS } from 'app/config/routes';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumns = (actionsStatus, columns = []) => {
    const HEADER_CREDIT = {
        total_credit: {
            title: '',
            style: 'col'
        },
        account_number: {
            title: 'account_number_#',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        method: {
            title: 'method',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        total: {
            title: 'total',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        available_credit: {
            title: 'available_credit',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-status'
        }
    };

    const REPORT_COLUMNS_CREDIT = [
        {
            id: 'total_credit',
            title: '',
            status: true
        },
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'total',
            title: 'total',
            status: true
        },
        {
            id: 'available_credit',
            title: 'available_credit',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];

    const REPORT_CONTENT_CREDIT = {
        total_credit: {
            id: 'total_credit',
            style: 'col',
            title: ''
        },
        account_number: {
            id: 'account_number',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            urlConfig: { baseUrl: CUSTOMERS_CREDITS }
        },
        method: { id: 'method', style: 'col col-lg' },
        date: { id: 'date', style: 'col col-md' },
        total: { id: 'total', style: 'col col-total', type: LIST_TABLE_ITEM_TYPE.BALANCE },
        available_credit: {
            id: 'available_credit',
            style: 'col col-total',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        status: {
            id: 'status',
            style: 'col col-status flex-column gap-5',
            type: LIST_TABLE_ITEM_TYPE.STATUS_CREDIT
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_CREDIT });
    const newColumns = [];
    const newContent = {};

    REPORT_COLUMNS_CREDIT.map((col) => {
        const item = col.id;
        if (columns.includes(item)) {
            newContent[item] = REPORT_CONTENT_CREDIT[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
