import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

const HEADER_QUICK_BOOKS = {
    id: {
        title: 'id',
        style: 'col col-sm'
    },
    name: {
        title: 'name',
        style: 'col --lg'
    },
    address: {
        title: 'address',
        style: 'col --lg --full'
    },
    phone: {
        title: 'phone',
        style: 'col --info'
    },
    email: {
        title: 'email',
        style: 'col --info'
    }
};

const QUICK_BOOKS_COLUMNS = [
    {
        id: 'id',
        title: 'id',
        status: true
    },
    {
        id: 'name',
        title: 'name',
        status: true
    },
    {
        id: 'address',
        title: 'address',
        status: true
    },
    {
        id: 'phone',
        title: 'phones',
        status: true
    },
    {
        id: 'email',
        title: 'email',
        status: true
    }
];

const QUICK_BOOKS_CONTENT = {
    id: {
        title: 'id',
        style: 'col col-sm'
    },
    name: {
        title: 'name',
        style: 'col --lg'
    },
    address: {
        title: 'address',
        style: 'col --lg --full',
        type: LIST_TABLE_ITEM_TYPE.QB_ADDRESS
    },
    phone: {
        title: 'phones',
        style: 'col --info'
    },
    email: {
        title: 'email',
        style: 'col --info'
    }
};

export const getGridColumns = () => {
    const newHeader = {};
    const newContent = {};
    const newColumns = [];

    QUICK_BOOKS_COLUMNS.forEach((col) => {
        const item = col.id;

        newHeader[item] = HEADER_QUICK_BOOKS[item];
        newContent[item] = QUICK_BOOKS_CONTENT[item];
        newColumns.push(col);
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
