import DropdownGroupSchedule from 'app/components/dropdown/DropdownGroupSchedule';
import React from 'react';

const ColumnGroupSchedule = ({ item, style, onHandleActionGroups = () => {}, idRow }) => {
    return (
        <div className={style}>
            <DropdownGroupSchedule
                id={item.id}
                groups={item.groups}
                onHandleActionGroups={onHandleActionGroups}
                idRow={idRow}
            />
        </div>
    );
};

export default ColumnGroupSchedule;
