import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ReportTabs({ list, onChange, tabActive, classTabList, classWrapper = 'header-items tabs' }) {
    const { t } = useTranslation(['report']);

    const handleOnChange = (value) => {
        if (value !== tabActive) onChange(value);
    };

    const renderlistTab = (list) => {
        return list.map((item) => {
            return (
                <button
                    key={item.id}
                    className={`tab-items ${item.value === tabActive && 'active-tab-selector'}`}
                    type="button"
                    onClick={() => {
                        handleOnChange(item.value);
                    }}
                >
                    {t(`report:${item.name}`)}
                </button>
            );
        });
    };

    return (
        <div className={classWrapper}>
            <div className={classTabList}>{renderlistTab(list)}</div>
        </div>
    );
}

ReportTabs.propTypes = {
    list: PropTypes.array,
    onChange: PropTypes.func,
    classTabList: PropTypes.string
};

ReportTabs.defaultProps = {
    classTabList: 'tab-selectors btn-item ml-0',
    onChange: () => {},
    list: []
};

const compare = (prevProps, nextProps) => {
    return prevProps.tabActive === nextProps.tabActive;
};

export default memo(ReportTabs, compare);
