import React from 'react';
import { useTranslation } from 'react-i18next';

import IconBananaReferral from 'assets/icon/IconBananaReferral';
import { LABEL_STATUS_REFERRAL } from '../constant';

const ColumReferralStatus = ({ value, style, isIcon = false }) => {
    const { t } = useTranslation('referral');

    if (isIcon)
        return (
            <div className={style}>
                <IconBananaReferral type={value} />
            </div>
        );

    const { label, style: styleLabel } = LABEL_STATUS_REFERRAL[value] || {};
    return (
        <div className={style}>
            <div className={`status-btn ${styleLabel}`}>{t(label)}</div>
        </div>
    );
};

export default ColumReferralStatus;
