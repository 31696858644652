export const CUSTOMER_ACTIONS = {
    UPDATE_SOURCES: 'UPDATE_SOURCES',
    ADD_SOURCES: 'ADD_SOURCES',
    CREATE_CUSTOMER: 'CREATE_CUSTOMER'
};

export const updateCustomerSources = (payload) => {
    return {
        type: CUSTOMER_ACTIONS.UPDATE_SOURCES,
        payload
    };
};

export const addCustomerSources = (payload) => {
    return {
        type: CUSTOMER_ACTIONS.ADD_SOURCES,
        payload
    };
};

export const createCustomer = (payload) => {
    return { type: CUSTOMER_ACTIONS.CREATE_CUSTOMER, payload };
};
