import { takeLatest } from 'redux-saga/effects';

// sagas
import { getDriveMatrix, updateDriveMatrix } from './driveMatrix';
import { getListEventRoute } from './eventRoute';
import { optimizedEventsRoute, acceptNewRoute } from './optimizedRoute';

// TYPE
import { TYPE_ROUTE_OPTIMIZER } from 'common/redux/actions/routeOptimizerAction';

export function* getDriveMatrixWatcher() {
    yield takeLatest(TYPE_ROUTE_OPTIMIZER.GET_DRIVE_MATRIX_REQUEST, getDriveMatrix);
}

export function* updateDriveMatrixWatcher() {
    yield takeLatest(TYPE_ROUTE_OPTIMIZER.UPDATE_DRIVE_MATRIX_REQUEST, updateDriveMatrix);
}

export function* getListEventRouteWatcher() {
    yield takeLatest(TYPE_ROUTE_OPTIMIZER.GET_LIST_EVENT_ROUTE_REQUEST, getListEventRoute);
}

export function* optimizedEventsRouteWatcher() {
    yield takeLatest(TYPE_ROUTE_OPTIMIZER.OPTIMIZED_EVENT_ROUTE_REQUEST, optimizedEventsRoute);
}

export function* acceptNewRouteWatcher() {
    yield takeLatest(TYPE_ROUTE_OPTIMIZER.ACCEPT_NEW_ROUTE_REQUEST, acceptNewRoute);
}
