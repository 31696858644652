import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { createPopper } from '@popperjs/core';
import IconClose from 'assets/icon/IconClose';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

const ListTaskModal = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, popper: null, data: [], onOpenTask: () => {} });
    const schedules = useSelector(({ calendar }) => calendar.schedules);
    const { isOpen, popper, date, data: tasks, onOpenTask } = state;

    const refPopper = useRef(null);

    useEffect(() => {
        if (isOpen && refPopper.current && popper) {
            createPopper(popper, refPopper.current, {
                modifiers: [{ name: 'flip' }, { name: 'offset', options: { offset: [0, 5] } }]
            });
        }
    }, [isOpen, popper]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    function handleHideDropdown(event) {
        const elPrevent = refPopper.current;

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleClose();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = refPopper.current;
        if (elPrevent && !elPrevent.contains(event.target)) {
            _handleClose();
        }
    }

    useImperativeHandle(ref, () => ({
        _open: (data) => {
            dispatchState({ isOpen: true, popper: data.element, ...data });
        },
        _close: _handleClose,
        _updateRealtime
    }));

    const _handleClose = () => {
        dispatchState({ isOpen: false, popper: null });
    };

    const _updateRealtime = (data) => {
        // eslint-disable-next-line no-undef
        if (!state.isOpen || data.socket_id === global.socket_id_task_service) return;
        let result = [...tasks];

        switch (data.type) {
            case 'add':
                const dateAdd = moment(data.task.date).utc();
                if (moment.utc(date).isSame(dateAdd, 'day')) result.push(data.task);
                break;
            case 'delete':
                result = result.filter((item) => !data.task.includes(item.id));
                break;
            case 'update':
                const isUpdate = data.task.schedules.some((el) => el.id === schedules?.[0]?.id);
                const newDate = moment(data.task.date).utc();
                const inRangeDate = moment.utc(date).isSame(newDate, 'day');

                if (data.task.assignee.id !== schedules?.[0]?.user_id || !inRangeDate || !isUpdate) {
                    result = result.filter((item) => item.id !== data.task.id);
                } else {
                    const indexUpdate = result.findIndex((item) => item.id === data.task.id);
                    if (indexUpdate !== -1) {
                        result = result.map((item) => {
                            if (item.id === data.task.id) return { ...item, ...data.task };
                            return item;
                        });
                    } else {
                        result.push(data.task);
                    }
                }

                break;
            case 'done':
                result = result.map((item) => {
                    const dataUpdate = data.tasksDone.find((el) => el.id === item.id);
                    if (dataUpdate) return { ...item, ...dataUpdate, active: dataUpdate.active };
                    return item;
                });
                break;
            default:
                break;
        }

        const newState = { data: result };
        newState['isOpen'] = !!result.length;
        dispatchState(newState);
    };

    if (!isOpen) return null;
    return (
        <div ref={refPopper} className="wrapper-tasklist flex-column is-open" style={{ zIndex: 999 }}>
            <div className="wrapper-tasklist__header flexcenter">
                {date ? <h3 className="flex-1 fs-16 black">{moment(date).format('MMMM DD, YYYY')}</h3> : null}
                <div className="v2-btn-default btn-bg-grey --icon-lg" onClick={_handleClose}>
                    <IconClose />
                </div>
            </div>
            <div className="wrapper-tasklist__body flex-1">
                {tasks.map((item) => (
                    <div
                        key={item.id}
                        className="items cursor-pointer"
                        style={{ textDecoration: !!item.active ? 'unset' : 'line-through' }}
                        onClick={() => {
                            onOpenTask(item);
                            _handleClose();
                        }}
                    >
                        {item.title}
                    </div>
                ))}
            </div>
        </div>
    );
});

ListTaskModal.displayName = 'ListTaskModal';
export default ListTaskModal;
