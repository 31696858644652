import React from 'react';
import { useTranslation } from 'react-i18next';

import FooterBackLogin from 'app/modules/auth/components/FooterBackLogin';

const SuccessForgotPassword = ({ message, email }) => {
    const { t } = useTranslation(['auth', 'common']);

    return (
        <div className="flex-centeritem flex-1">
            <div className="gd-form-v2">
                <h2 className="fs-27">{t('auth:reset_password')}</h2>
                <section className="gd-login-btn-group">
                    <div>
                        <p>
                            {t('common:we_have_send_an_email_to')}: <b>{email}</b>
                        </p>
                        <p>{message}</p>
                    </div>
                </section>

                <FooterBackLogin />
            </div>
        </div>
    );
};

export default SuccessForgotPassword;
