import React from 'react';

const Loading = () => {
    return (
        <div className="contents-pages container-column wrapper-box-edit is-editing scrolls">
            <div className="wrapper-box-edit__content form-editing">
                <div className="wrap-loading page-wrapper wrap-portal">
                    <div className="wrap-portal__box border-border-color-grey">
                        <div className="mb-6 loading --animation --line --half" />
                        <div className="rows">
                            <div className="rows__label mb-1">
                                <div className="loading --animation --line --onefifth" />
                            </div>
                            <div className="v2-btn-default --grey loading --full">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="row-haft flex-betweenitems">
                            <div className="col-lg rows">
                                <div className="loading --animation --line --twothird mb-1" />
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                            </div>
                            <div className="col-lg rows mt-0">
                                <div className="loading --animation --line --twothird mb-1" />
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                            </div>
                        </div>
                        <div className="rows d-flex justify-end">
                            <div className="v2-btn-default --grey loading btn-sm">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-portal__box flex-betweenitems border-border-color-grey">
                        <div className="flex-1">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --fourth mt-1" />
                        </div>
                        <div className="v2-btn-default --grey loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="wrap-portal__box flex-betweenitems border-border-color-grey">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line btn-x-sm" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
