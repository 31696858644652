import React, { useReducer, useRef, useEffect } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

export default function ListTax({ valueTax, onSelect, isGroup, tax_codes, isFetched }) {
    const { t } = useTranslation('common');
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const finalListTax = tax_codes || [];
    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_quickbox_tax');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_quickbox_tax');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleSelect(e, itemId, isActive) {
        e.stopPropagation();

        if (isActive) {
            return false;
        }

        onSelect(itemId);

        setState({
            isVisible: false
        });
    }

    function _getCurrentTax() {
        const taxSelected = finalListTax.find((item) => item.id === valueTax) || {};
        return taxSelected.name || 'Please select tax code';
    }

    function _renderListTax() {
        if (!isFetched) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }

        if (isFetched && finalListTax.length === 0) {
            return (
                <li className="items pointer-events-none">
                    <div className="txt-ellipsis">{t('no_data_to_display', { title: t('taxes') })}</div>
                </li>
            );
        }

        return finalListTax.map((item) => {
            const itemId = item.id;
            const isActive = itemId === valueTax;

            return (
                <li
                    onClick={(e) => _handleSelect(e, itemId, isActive)}
                    key={itemId}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <div className="txt-ellipsis">{item.name}</div>
                </li>
            );
        });
    }

    return (
        <div
            ref={refDropdown}
            className={`v2-dropdown ml-5 pt-2 ${finalIsVisible ? 'active' : ''} ${isGroup ? 'is-disable' : ''}`}
        >
            <div onClick={(e) => _handleOpen(e)} className="dropbtn items --grey ">
                <div className="txt-ellipsis fw-500">{_getCurrentTax()}</div>
                <IconDropUpDown />
            </div>
            <div id={'show_list_quickbox_tax'} className="v2-dropdown__menu scrolls">
                <ul>{_renderListTax()}</ul>
            </div>
        </div>
    );
}
