import GdPhoneItem from 'app/components/phone/ItemPhone';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PhonesDisplay = ({ data = [], classWrapper = 'rows', customerId = '' }) => {
    const { t } = useTranslation();
    const renderList = () =>
        data.map((item) => (
            <GdPhoneItem
                key={item.id}
                isValid={item.is_valid}
                phoneType={item.type.name}
                {...item}
                isUsePopup
                typeOpen={TYPE_OPEN_SMS.CUSTOMER}
                customerId={customerId}
            />
        ));

    if (!data.length) return null;
    return (
        <div className={classWrapper}>
            <div className="rows__label"> {t('customers:phone')}</div>
            <div className="rows__info">{renderList()}</div>
        </div>
    );
};

export default PhonesDisplay;
