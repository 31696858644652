export default function IconCheck({ isSuccess = false }) {
    if (isSuccess) {
        return (
            <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={16} height={16} rx={8} fill="#1EAA5C" />
                <path
                    d="M5.5 8.5L7.5 10.5L11.5 6.5"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    } else {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.5 12.5L11 16L17.5 9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
}
