/* eslint-disable no-unused-vars */
import { COMMON, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { setLocalStorage } from './LocalStorageUtils';

export const handleActionHeaderReport = ({
    actionType,
    reportType,
    columnsTarget,
    currentValue,
    paramsReport,
    callBack = () => {},
    keyOrder = '',
    quickResponse = false
}) => {
    const newParamsReport = { ...paramsReport };

    switch (actionType) {
        case COMMON.ORDER:
            newParamsReport[actionType] = columnsTarget.concat(' ', currentValue);
            break;
        default:
            break;
    }

    if (keyOrder) newParamsReport[keyOrder] = newParamsReport[actionType];
    !quickResponse && setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), newParamsReport);

    callBack(newParamsReport);
};

export const mapCurrentValueHeader = (actionsStatus, headers) => {
    if (actionsStatus) {
        const [orderName, value] = actionsStatus.split(' ');

        for (const keyHeader in headers) {
            headers[keyHeader].currentValue = COMMON.NOT_SET;
        }

        if (headers[orderName]) headers[orderName].currentValue = value;
    }

    return headers;
};

export const changeStyleRowsDropdown = (id, isOpen = false) => {
    const row = document.getElementById(id);
    const header = document.querySelector('.rows.--fixed.--header');
    if (!row || !header) return;
    row.style.overflow = isOpen ? 'visible' : 'hidden';
    row.style.zIndex = isOpen ? 1 : 0;
    header.style.zIndex = isOpen ? Number(header.style.zIndex) + 1 : 1;
};
