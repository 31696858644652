import { call } from "redux-saga/effects";
import {
  fetchWithToken,
} from "common/utils/ApiUtils";
import {
  DRIP_REPORT_ACTIVITY,
  DRIP_REPORT_SUMMARY,
  DRIP_REPORT_LINK,
  DRIP_REPORT_DETAIL_CUSTOMER
} from "app/const/Api";

export function* getReportActivity({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, DRIP_REPORT_ACTIVITY, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* getReportSummary({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, DRIP_REPORT_SUMMARY, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* getReportLink({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, DRIP_REPORT_LINK, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* getReportDetail({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, DRIP_REPORT_DETAIL_CUSTOMER, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}
