import { SPECICAL_KEYS } from 'app/const/App';
import { getBranchWithKey } from 'app/const/Branch';

//Set Local Storage
export const setLocalStorage = (key, value) => {
    localStorage.setItem(
        SPECICAL_KEYS.includes(key) ? key : getBranchWithKey(key),
        !value || typeof value === 'string' ? value : JSON.stringify(value)
    );
};

function isJSON(str) {
    try {
        return JSON.parse(str);
    } catch (e) {
        return str;
    }
}

//Get Local Storage
export const getLocalStorage = (key) => {
    try {
        const value = localStorage.getItem(SPECICAL_KEYS.includes(key) ? key : getBranchWithKey(key));
        if (value === null) return null;
        return isJSON(value);
    } catch (err) {
        console.error(`Error getting localStorage item ${key}: ${err}`);
        return null;
    }
};

//Get Local Storage
export const getLocalStorageValue = (key) => {
    return localStorage.getItem(SPECICAL_KEYS.includes(key) ? key : getBranchWithKey(key));
};

//Delete Local Storage
export const removeLocalStorage = (key) => {
    localStorage.removeItem(SPECICAL_KEYS.includes(key) ? key : getBranchWithKey(key));
};
