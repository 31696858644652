import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { REPORT_MENU_OPTIONS } from 'app/const/StaticLinks';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { getPermissionReport, isNotPermissionValue } from 'common/utils/PermissionUtils';

function SideMenu() {
    const { t } = useTranslation(['report']);

    const location = useLocation();

    const addonsList = useSelector((state) => state.auth.user?.settings?.addons);
    const permissions = getPermissionReport(addonsList);

    const ItemMenu = ({ item }) => {
        return (
            <li key={item.id} id={`side-report-item-${item.id}`}>
                <Link
                    className={classNames('sidebar-items flexcenter', {
                        active: matchPath(location.pathname, {
                            path: item.subsLink?.map((pathSub) => addPrefixPath(pathSub)) || addPrefixPath(item.link),
                            exact: true
                        })
                    })}
                    to={{
                        pathname: addBranchPath(item.link),
                        state: item.tab ? { reload: Date.now(), newTab: item.tab } : null
                    }}
                >
                    <p className="txt-ellipsis">{t(`report:${item.value}`)}</p>
                </Link>
            </li>
        );
    };

    const renderMenu = (list) => {
        return list.map((item) => {
            const { link, id, value } = item;
            if (isNotPermissionValue(permissions, value)) return null;
            return link ? (
                <ItemMenu key={item.id} item={item} />
            ) : (
                <div key={id} className="is-divider --horizontal my-2 mx-1"></div>
            );
        });
    };

    return (
        <div
            className={classNames('sidebar-menu sidebar-left scrolls', {
                'is-hide-sidebar': !getToggleLocal('toggledReport')
            })}
        >
            <ul className="sidebar-menu__nav flex-column">{renderMenu(REPORT_MENU_OPTIONS)}</ul>
        </div>
    );
}

export default SideMenu;
