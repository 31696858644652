import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { UPDATE_ADDONS_EMAIL_ACCOUNT } from 'app/const/Api';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSMTP from './ContentSMTP';
import EditEmailInfoGG from './EditEmailInfoGG';
import EditFromAddress from './EditFromAddress';
import EmailType from './EmailType';

const EmailInfo = ({
    email,
    name,
    type = 'google',
    smtp = {},
    imap = {},
    onUpdateAccountSuccess = () => {},
    onUpdateSMTPSuccess = () => {},
    onUpdateSyncSuccess = () => {},
    onUpdateSyncFail = () => {},
    onDeleteSuccess = () => {}
}) => {
    const { t } = useTranslation();
    const isDisplayEdit = type === 'imap';
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);

    const _handleDelete = () => {
        refWarning.current._open({ description: 'Are you sure you want to delete this account?' });
    };

    const _handleConfirmDelete = () => {
        const _handleDeleteSuccess = ({ message }) => {
            refWarning.current._close();
            onDeleteSuccess(null, message);
        };
        clientQuery(UPDATE_ADDONS_EMAIL_ACCOUNT, { data: { type: 3 }, method: 'DELETE' }, _handleDeleteSuccess);
    };

    return (
        <div className="boxs boxs-has-footer active">
            <div className="boxs__header">
                <div className="title txt-ellipsis">{name}</div>
                <div className="v2-btn-default --icon-sm --delete" onClick={_handleDelete}>
                    <IconTrash />
                </div>
            </div>
            <div className="boxs__contents">
                <span className="email-title mb-1 txt-ellipsis">{email}</span>
                <EditFromAddress name={name} email={email} type={type} onUpdateSuccess={onUpdateAccountSuccess} />
                <EmailType userName={smtp?.username} type={type} />
                <ContentSMTP
                    title="SMTP (Outgoing mail)"
                    name={name}
                    email={email}
                    isDisplayEdit={isDisplayEdit}
                    {...smtp}
                    onUpdateSuccess={onUpdateSMTPSuccess}
                    onDeleteSuccess={onDeleteSuccess}
                />
                <ContentSMTP
                    title="IMAP (2-way sync)"
                    name={name}
                    email={email}
                    isDisplayEdit={isDisplayEdit}
                    isImap
                    onUpdateSuccess={onUpdateSMTPSuccess}
                    onDeleteSuccess={onDeleteSuccess}
                    {...imap}
                />
            </div>
            {!isDisplayEdit ? (
                <EditEmailInfoGG
                    isSended={smtp?.active}
                    isSynced={imap?.active}
                    onUpdateSuccess={onUpdateSyncSuccess}
                    onUpdateSyncFail={onUpdateSyncFail}
                />
            ) : null}
            <GDModalWarning
                ref={refWarning}
                title="Delete account"
                modalClassName="modal container-modal modal-confirm-delete open"
                headerClassName="header-modal"
                bodyClassName="body-modal"
                descriptionClassName="text"
                isLargeContent={false}
                isDisplayClose={false}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={() => refWarning.current._close()}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title="Confirm"
                            className="v2-btn-main"
                            onSave={_handleConfirmDelete}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default EmailInfo;
