import { TYPE_ACTION } from 'common/redux/actions/calendar/bookMarkAction';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER } from 'app/modules/calendar/const/BookMark';

const bookMarkReducer = (
    state = {
        list: []
    },
    action
) => {
    switch (action.type) {
        case TYPE_ACTION.ACTION_ADD_NEW_BOOK_MARK:
            const oldList = [...state.list];
            const newitem = { ...action.params };
            const index = oldList.findIndex((item) => item.type === newitem.type && item.data.id === newitem.data.id);
            if (index >= 0) oldList[index] = newitem;
            else oldList.push(newitem);
            setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, oldList);
            return {
                list: oldList
            };
        case TYPE_ACTION.ACTION_REMOVE_BOOK_MARK:
            const newListRemove = [...state.list];

            const listRemoved = newListRemove.filter((item) => item.id !== action.params.id);
            setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, listRemoved);
            return {
                list: listRemoved
            };
        case TYPE_ACTION.ACTION_REMOVE_ALL_BOOK_MARK: {
            setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);
            return { list: [] };
        }
        case TYPE_ACTION.SET_ALL_BOOK_MARK: {
            return { list: action.params };
        }
        default:
            return state;
    }
};

export default bookMarkReducer;
