export const TYPE_REPORT_LOCATIONS_WO_ACTIVE_JOBS = {
    GET_LIST: 'GET_LIST_REPORT_LOCATIONS_WO_ACTIVE_JOBS'
};

// actions
export const getListReportLocationsWoActiveJobsRequest = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_LOCATIONS_WO_ACTIVE_JOBS.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};
