import ConversationUnActive from 'app/modules/sms/customer/components/ConversationUnActive';
import React, { useEffect, useId, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import RealTimeSMS from '../../services/RealTimeSms';
import ConversationDetail from 'app/modules/sms/conversation/Detail';
import { reducer } from 'app/const/Reducer';
import IconConversation from 'assets/icon/IconConversation';
import { clientQuery } from 'common/utils/ApiUtils';
import { SMS_COUNT } from 'app/const/api/Sms';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { TYPE_SHOW_SMS } from 'app/modules/inbox/const';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';

const SmsJobDetail = ({ customer, smsTo = [], isJobPreview = false, jobId = '', keyTriggerOpen }) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        total: 0,
        isOpen: false,
        isTriggerOpen: false,
        isPhoneAction: false
    });
    const refDropdown = useRef(null);
    const refConversation = useRef(null);
    const activateSms = useSelector((state) => state.auth?.user?.settings?.addons?.activate_sms) || 0;
    const dropdownId = useId('');
    const refDefaultPhone = useRef(null);

    const {
        isVisible,
        total: finalTotal,
        isOpen: finalIsOpen,
        isTriggerOpen,
        isPhoneAction: finalIsPhoneAction
    } = state;

    const finalCustomer = customer || {};
    const customerId = finalCustomer.id;

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(dropdownId);
        const grammarly = document.querySelector('grammarly-mirror');
        const smsTypeWarning = document.getElementById('sms_warning_type');
        const previewEl = document.getElementById('gd-preview-attach');
        const deleteSmsEl = document.getElementById('modal_delete_sms');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target) &&
            !grammarly?.contains(event.target) &&
            !smsTypeWarning?.contains(event.target) &&
            event.target.dataset.closeSms !== 'false' &&
            !previewEl?.contains(event.target) &&
            !deleteSmsEl?.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        isVisible &&
            dispatchState({ isVisible: false, smsReceiver: null, smsConversation: null, isTriggerOpen: false });
    }

    function _handleUpdateTotalSMS(total, smsReciverData, smsConversationData) {
        dispatchState((prevState) => ({
            ...prevState,
            total,
            smsReceiver: smsReciverData,
            smsConversation: smsConversationData
        }));
    }

    useEffect(() => {
        if (customerId) {
            const _countSuccess = ({ data }) => {
                dispatchState((prev) => {
                    return { ...prev, total: data?.total ?? prev.total };
                });
            };

            if (activateSms) {
                clientQuery(SMS_COUNT, { method: 'GET', data: { status: 1, customer_id: customerId } }, _countSuccess);
                refConversation.current?.reset();
            }
        }
    }, [customerId]);

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_SEND_SMS, _openSMSWithCustomEvent);
        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_SEND_SMS, _openSMSWithCustomEvent);
        };
    }, [customerId]);

    const _openSMSWithCustomEvent = (event) => {
        const { default_phone: phoneNumber = '', typeOpen = '', isPhoneAction = false } = event?.detail || {};

        if (typeOpen === keyTriggerOpen && phoneNumber) _toggleMenu(phoneNumber, isPhoneAction);
    };

    const _toggleMenu = (defaultPhone, isPhoneAction = false) => {
        if (isPhoneAction && !isVisible && defaultPhone !== refDefaultPhone.current) {
            refConversation.current?.reset();
            refDefaultPhone.current = defaultPhone;
        }

        dispatchState((prev) => {
            const newState = {
                ...prev,
                isVisible: !isVisible,
                smsReceiver: null,
                smsConversation: null,
                isOpen: true,
                isPhoneAction: false
            };
            if (defaultPhone) {
                newState['defaultPhone'] = defaultPhone;
                newState['autoOpenBoxMessage'] = true;
                newState['isTriggerOpen'] = true;
                newState['isPhoneAction'] = isPhoneAction;
            }
            return newState;
        });
    };

    const _renderContent = () => {
        if (!customer || !isVisible) return null;

        if (!activateSms && isVisible) {
            return <ConversationUnActive customer={finalCustomer} onClose={_toggleMenu} />;
        }

        if (activateSms && finalIsOpen) {
            const finalCustomer = { ...customer, phone_location: smsTo.map((item) => item.phone) };
            if (state.defaultPhone) finalCustomer['default_phone'] = state.defaultPhone;

            return (
                <ConversationDetail
                    ref={refConversation}
                    socketReceiver={state.smsReceiver}
                    socketConversation={state.smsConversation}
                    autoOpenBoxMessage={state.autoOpenBoxMessage}
                    customer={finalCustomer}
                    setStep={() => _toggleMenu()}
                    onClose={_toggleMenu}
                    isJobPreview={isJobPreview}
                    jobId={isTriggerOpen ? '' : jobId}
                    isActive={isVisible}
                    type={TYPE_SHOW_SMS.JOB}
                    isPhoneAction={finalIsPhoneAction}
                />
            );
        }
    };

    return (
        <div ref={refDropdown} className="boxs-notification boxs-sms wrap-btn-primary" id={dropdownId}>
            <div
                onClick={() => _toggleMenu()}
                className={`dropbtn btn-primary --icon ${finalTotal > 0 ? 'has-message' : ''}`}
            >
                <IconConversation />
                {finalTotal > 0 && <span className="budget">{finalTotal > 99 ? '+99' : finalTotal}</span>}
            </div>
            {_renderContent()}
            <RealTimeSMS currentTotal={finalTotal} onUpdateTotalSms={_handleUpdateTotalSMS} customerId={customerId} />
        </div>
    );
};

export default SmsJobDetail;
