import { CUSTOMER_INVOICES_DELETE_PAYMENT, CUSTOMER_INVOICES_TO_CREDIT } from 'app/const/Api';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import ButtonSave from 'app/components/button/ButtonSave';
import { reducer } from 'app/const/Reducer';

const CustomerInvoiceConfirmDelete = forwardRef(({ onDeleteSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        data_payment: []
    });
    const refButtonSaveCredit = useRef(null);
    const refButtonSavePayment = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, ...data });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleDelete = (url) => {
        const ids = state.ids;
        clientQuery(
            url,
            {
                data: { ids },
                method: 'DELETE',
                toFormData: false
            },
            ({ message, error }) => onDeleteSuccess({ message, error }, null, ids),
            null,
            _close
        );
    };

    const _renderListRows = () =>
        state.data_payment.map((item) => (
            <div key={item.number} className="rows">
                <div className="col --info">
                    <p className="txt-ellipsis">{item.number}</p>
                </div>
                <div className="col --info">
                    <p className="txt-ellipsis">{item.name}</p>
                </div>
                <div className="col --md">
                    <p className="txt-ellipsis">{item.method}</p>
                </div>
                <div className="col --sm">
                    <p className="txt-ellipsis">{item.date}</p>
                </div>
                <div className="col --sm">
                    <p className="txt-ellipsis">{item.amount}</p>
                </div>
            </div>
        ));

    if (!state.isOpen) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal modal-delete-job --form-invoice open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">How to handle invoice payments</h3>
                    <span onClick={_close} className="v2-btn-default --icon-lg --transparent">
                        <IconClose />
                    </span>
                </div>
                <div className="body-modal scrolls">
                    <p className="px-5">{t('delete_invoice_description')}</p>
                    <div className="tables">
                        <div className="rows --header">
                            <div className="col --info">
                                <p className="txt-ellipsis">Invoice</p>
                            </div>
                            <div className="col --info">
                                <p className="txt-ellipsis">Client</p>
                            </div>
                            <div className="col --md">
                                <p className="txt-ellipsis">Method</p>
                            </div>
                            <div className="col --sm">
                                <p className="txt-ellipsis">Date</p>
                            </div>
                            <div className="col --sm">
                                <p className="txt-ellipsis">Amount</p>
                            </div>
                        </div>
                        <div className="tables__list scrolls">{_renderListRows()}</div>
                    </div>
                </div>
                <div className="footer-modal btn-close justify-end">
                    <ButtonSave
                        title={t('convert_payment_to_credit')}
                        ref={refButtonSaveCredit}
                        onSave={() => _handleDelete(CUSTOMER_INVOICES_TO_CREDIT)}
                    />
                    <ButtonSave
                        wrapClass={'v2-btn-default has-icon --delete ml-2'}
                        ref={refButtonSavePayment}
                        onSave={() => _handleDelete(CUSTOMER_INVOICES_DELETE_PAYMENT)}
                    >
                        <IconTrash />
                        {t('delete_permanently_payments')}
                    </ButtonSave>
                </div>
            </div>
        </ReactModal>
    );
});

export default CustomerInvoiceConfirmDelete;
