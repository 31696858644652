import { TYPES_TIME_WINDOW } from '../actions/timeWindowAction';

const timeWindowReducer = (
    state = {
        list: [],
        isFirstTime: true,
        isLoading: true
    },
    action
) => {
    switch (action.type) {
        case TYPES_TIME_WINDOW.ACTION_GET_LIST_TIME_WINDOW_SUCCESS:
            return { ...state, list: action.payload, isFirstTime: false, isLoading: false };
        case TYPES_TIME_WINDOW.RESET_LIST_TIME_WINDOW:
            return { list: [], isFirstTime: true, isLoading: true };
        default:
            return state;
    }
};

export default timeWindowReducer;
