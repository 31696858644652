import React from 'react';
import Tooltip from 'app/components/tooltip';
import classNames from 'classnames';

const BoxRows = ({
    label = '',
    rowClass = '',
    labelClass = '',
    fieldClass = '',
    tooltip = '',
    count = 0,
    isDisplayCount = false,
    isRequired = false,
    children
}) => {
    if (!children) return null;
    return (
        <div className={classNames('rows', rowClass)}>
            <div className={classNames('rows__label', labelClass)}>
                {label} {isRequired && <span className="required">*</span>} {tooltip && <Tooltip tooltip={tooltip} />}
                {isDisplayCount && <span className="budget bg-btn-hover-black">{count}</span>}
            </div>
            <div className={classNames('rows__field', fieldClass)}>{children}</div>
        </div>
    );
};

export const BoxRowsInput = ({ inputProps = {}, ...props }) => {
    return (
        <BoxRows {...props}>
            <input className="field-input" {...inputProps} />
        </BoxRows>
    );
};

export default BoxRows;
