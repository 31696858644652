import { TYPE_DOCUMENT } from 'common/redux/actions/documentAction';

const documentReducer = (state = { documentData: null }, action) => {
    switch (action.type) {
        case TYPE_DOCUMENT.ACTION_OPEN_DOCUMENT_DETAIL:
            return { ...state, documentData: action.params };
        default:
            return state;
    }
};

export default documentReducer;
