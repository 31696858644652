import { checkIsExpiredAccount } from 'common/utils/PermissionUtils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ServiceLayout = () => {
    const { toggled } = useSelector((state) => state.app);
    const profile = useSelector((state) => state.auth?.user?.profile);

    if (checkIsExpiredAccount(profile)) return null;

    useEffect(() => {
        const elmMainPage = document.getElementById('main-page');

        if (toggled) {
            elmMainPage && elmMainPage.classList.add('active');
        } else {
            elmMainPage && elmMainPage.classList.remove('active');
        }
    }, [toggled]);

    return false;
};

export default ServiceLayout;
