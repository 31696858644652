import { ACCOUNT_ROLE } from 'app/const/App';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import {
    _getTimeOptimizeWorkTime as getTimeFromNumber,
    ROUTE_OPTIMIZER_WORK_TIME_OPTIONS
} from 'app/modules/calendar/const/Header';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import IconClock from 'assets/icon/IconClock';
import React, { Fragment, useCallback, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

export function ActionTime() {
    const refDropdown = useRef(null);

    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);
    const {
        offset: { action_time: actionTimeValue = 0 }
    } = invoiceRepeatData;

    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    const _handleOpen = () => {};

    const _getFinalTime = useCallback(() => {
        return getTimeFromNumber(parseInt(actionTimeValue));
    }, [actionTimeValue]);

    const _handleChangeValue = (newValue) => {
        updateInvoiRepeatContext({
            ...invoiceRepeatData,
            offset: {
                ...invoiceRepeatData.offset,
                action_time: newValue
            }
        });
        refDropdown.current._closeDropdown();
    };

    return (
        <div className="flexcenter gap-6 w-100 mt-2">
            <p>Invoice action executes at</p>

            <CalendarDropdown
                id={'modal-action-time'}
                ref={refDropdown}
                onVisible={_handleOpen}
                disable={!isSupperAdmin}
                buttonClassName="dropbtn v2-btn-default has-icon --sm svg-purple tooltip"
                wrapperClassName="v2-dropdown dropdown-frequency"
                wrapperListClass="v2-dropdown__menu scrolls content-full popper-content"
                customDropButton={() => (
                    <Fragment>
                        <IconClock />
                        <span className="fs-13 purple-default">{_getFinalTime()}</span>
                        <p className="tooltiptext">
                            Customize the time when your Invoice Frequency action will execute.
                            <span className="fw-600 ml-1">
                                This is a global setting; it will apply to ALL your invoice frequency actions 
                                and ALL invoices with actions. Changes will take effect the following day.
                            </span>
                        </p>
                    </Fragment>
                )}
            >
                <ul>
                    {ROUTE_OPTIMIZER_WORK_TIME_OPTIONS.map((item) => {
                        const { id, name, value } = item;
                        const isActive = actionTimeValue === value;
                        if (value % 60 === 30) {
                            return null;
                        }
                        return (
                            <li
                                key={id}
                                className={`items ${isActive ? 'active' : ''}`}
                                onClick={() => _handleChangeValue(value)}
                            >
                                {name}
                            </li>
                        );
                    })}
                </ul>
            </CalendarDropdown>
        </div>
    );
}
