const EXAMPLE_MAP = {
    '': '',
    invoice_total: '$45',
    customer_name: 'Christopher Moreschi',
    location_name: 'Home',
    location_tags: 'Location Tags',
    service_type: 'Ant Service',
    service_zip: '98034',
    service_city: 'Kirkland',
    calendar_time: '9:15-9:45',
    time_window: '9:15 to 9:45',
    service_address_1: '134th Court Northeast',
    service_address_county: 'King County',
    balance: '$0.00',
    customer_tags: 'Customer Tags',
    company_name: 'TruGanix',
    location_notes: '1015 Highway 59 Loop N',
    last_service_date: 'Mon Jan 16th',
    next_service_date: 'Mon Jan 23th',
    job_count: '1/3',
    job_cycle: '1/4',
    account_number: '16042',
    work_order: '571507',
    po_number: 'PO 5000'
};

const NUMBER_FIELDS = 10;

export { EXAMPLE_MAP, NUMBER_FIELDS };
