import React from 'react';

export default function Loading() {
    return (
        <div className="wrap-loading">
            <div className="items justify-space-between pointer-events-none">
                <div className="loading --animation --line --half" />
                <div className="loading --onefifth field-h24 mx-1">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
}
