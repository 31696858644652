import i18n from 'assets/i18n';

import { OPTIONS_VALUE_OPPORTUNITY } from 'app/const/opportunity';
import {
    CALL_DIRECTION_STATUSES_OPTIONS,
    CALL_STATUSES_OPTIONS,
    ESTIMATE_STATUSES,
    FIELDS_TYPE,
    OBJECT_TYPES,
    REFERENCE_TYPES
} from './types';

export const BUILT_IN_TYPE_DEFINITION = [
    { id: OBJECT_TYPES.CUSTOM_FIELD, filterableType: OBJECT_TYPES.CUSTOM_FIELD, fields: [] },
    {
        id: 'customer',
        filterableType: OBJECT_TYPES.CUSTOMER,
        fields: [
            {
                id: 'status',
                label: 'Current status',
                prefixLabel: 'Customer ',
                type: FIELDS_TYPE.STATUS,
                queryKeyword: 'customer_status',
                keyGetValue: 'customer_status'
            },
            {
                id: 'name',
                label: 'Name',
                type: FIELDS_TYPE.TEXT
            },
            {
                id: 'account_no',
                label: 'Account no',
                type: FIELDS_TYPE.TEXT
            },
            {
                id: 'company',
                label: 'Company',
                type: FIELDS_TYPE.TEXT
            },
            {
                id: 'created_at',
                label: 'Created date',
                type: FIELDS_TYPE.CREATED_DATE
            },
            {
                id: 'balance',
                label: 'Balance',
                type: FIELDS_TYPE.TEXT
            },
            {
                id: 'created_date',
                label: 'Date created',
                type: FIELDS_TYPE.CUSTOMER_CREATED_DATE
            },
            {
                id: 'source_id',
                label: 'Source',
                type: FIELDS_TYPE.SOURCE_ID,
                keyGetValue: 'sources'
            },
            { id: 'count_phone_number', label: i18n.t('smartView:count_phone_number'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_email_address', label: i18n.t('smartView:count_email_address'), type: FIELDS_TYPE.INTEGER },
            {
                id: 'customer_tag',
                label: i18n.t('smartView:customer_tags'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'customerTags',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'tag',
                referencedType: REFERENCE_TYPES.TAG
            },
            {
                id: 'location_tag',
                label: i18n.t('smartView:location_tags'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'locationTags',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'tag',
                referencedType: REFERENCE_TYPES.TAG
            },
            {
                id: 'city',
                label: i18n.t('smartView:city'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'city',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'city',
                referencedType: REFERENCE_TYPES.CITY
            },
            {
                id: 'zip',
                label: i18n.t('smartView:zip'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'zip',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'zip',
                referencedType: REFERENCE_TYPES.ZIP
            },
            {
                id: 'items',
                label: i18n.t('smartView:line_items'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'items',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'item',
                referencedType: REFERENCE_TYPES.ITEMS
            },
            {
                id: 'item_date',
                label: i18n.t('smartView:line_items_date'),
                type: FIELDS_TYPE.DATE_WITHOUT_PRESENT
            }
        ]
    },
    {
        id: 'opportunity',
        filterableType: OBJECT_TYPES.OPPORTUNITY,
        fields: [
            { id: 'count_opportunity', label: i18n.t('smartView:count_opportunity'), type: FIELDS_TYPE.INTEGER },
            {
                id: 'count_active_opportunity',
                label: i18n.t('smartView:count_active_opportunity'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_won_opportunity',
                label: i18n.t('smartView:count_won_opportunity'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_lost_opportunity',
                label: i18n.t('smartView:count_lost_opportunity'),
                type: FIELDS_TYPE.INTEGER
            },
            // Second group
            {
                id: 'created_by',
                label: i18n.t('smartView:created_by'),
                type: FIELDS_TYPE.CREATED_BY,
                keyGetValue: 'users',
                keyJoin: 'full_name'
            },
            {
                id: 'user_id',
                label: i18n.t('smartView:assigned_to'),
                type: FIELDS_TYPE.REFERENCE_USER,
                keyGetValue: 'users',
                keyJoin: 'full_name'
            },
            {
                id: 'status',
                label: i18n.t('smartView:status_type'),
                prefixLabel: i18n.t('smartView:opportunity'),
                keyChoose: i18n.t('smartView:status'),
                type: FIELDS_TYPE.STATUS_OPPORTUNITY,
                keyGetValue: 'opportunity_status'
            },
            {
                id: 'close_date',
                label: i18n.t('smartView:close_date'),
                type: FIELDS_TYPE.CREATED_DATE
            },
            {
                id: 'confidence',
                label: i18n.t('smartView:confidence'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'value',
                label: i18n.t('smartView:value'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'period',
                label: i18n.t('smartView:period'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: OPTIONS_VALUE_OPPORTUNITY,
                keyJoin: 'name',
                shouldFetch: false,
                keyChoose: 'value'
            },
            {
                id: 'opportunity_status',
                prefixLabel: i18n.t('smartView:opportunity'),
                label: i18n.t('smartView:current_status'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                options: null,
                keyGetValue: 'pipelines',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'status',
                referencedType: REFERENCE_TYPES.STATUS_OPPORTUNITY
            }
        ]
    },
    {
        id: 'tasks',
        filterableType: OBJECT_TYPES.TASK,
        fields: [
            {
                id: 'count_open_task',
                label: 'Total number of open tasks',
                type: FIELDS_TYPE.COUNT_TASK,
                queryKeyword: 'count_task'
            },
            {
                id: 'count_completed_task',
                label: 'Number of completed tasks',
                type: FIELDS_TYPE.COUNT_TASK,
                queryKeyword: 'count_task'
            }
        ]
    },
    {
        id: 'SMS',
        filterableType: OBJECT_TYPES.SMS,
        fields: [
            {
                id: 'count_sms',
                label: 'Total number of SMS',
                type: FIELDS_TYPE.COUNT_SMS,
                queryKeyword: 'count_sms'
            },
            {
                id: 'count_sent_sms',
                label: 'Number of sent SMS',
                type: FIELDS_TYPE.COUNT_SMS,
                queryKeyword: 'count_sent_sms'
            },
            {
                id: 'count_received_sms',
                label: 'Number of received SMS',
                type: FIELDS_TYPE.COUNT_SMS,
                queryKeyword: 'count_received_sms'
            }
        ]
    },
    {
        id: 'call',
        filterableType: OBJECT_TYPES.CALL,
        fields: [
            {
                id: 'count_call',
                label: 'Total number of calls',
                type: FIELDS_TYPE.COUNT_CALL,
                queryKeyword: 'count_call'
            },
            {
                id: 'count_outgoing_call',
                label: 'Number of outgoing calls',
                type: FIELDS_TYPE.COUNT_CALL,
                queryKeyword: 'count_outgoing_call'
            },
            {
                id: 'count_incoming_call',
                label: 'Number of incoming calls',
                type: FIELDS_TYPE.COUNT_CALL,
                queryKeyword: 'count_incoming_call'
            },
            {
                id: 'count_missed_call',
                label: 'Number of missed calls',
                type: FIELDS_TYPE.COUNT_CALL,
                queryKeyword: 'count_missed_call'
            },
            {
                id: 'latest_call_created_date',
                prefixLabel: i18n.t('smartView:call'),
                label: 'Date',
                type: FIELDS_TYPE.LATEST_CALL_CREATED_DATE,
                queryKeyword: 'latest_call_created_date'
            },
            {
                id: 'created_date',
                label: i18n.t('smartView:date_created'),
                type: FIELDS_TYPE.DATE_WITHOUT_PRESENT
            },
            {
                id: 'incoming_latest_call_date',
                label: i18n.t('smartView:incoming_call_date'),
                type: FIELDS_TYPE.CREATED_DATE
            },
            {
                id: 'outgoing_latest_call_date',
                label: i18n.t('smartView:outgoing_call_date'),
                type: FIELDS_TYPE.CREATED_DATE
            },
            {
                id: 'direction',
                label: i18n.t('smartView:direction'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                keyChoose: 'value',
                keySelect: 'value',
                keyJoin: 'name',
                options: CALL_DIRECTION_STATUSES_OPTIONS
            },
            {
                id: 'duration',
                label: i18n.t('smartView:duration'),
                type: FIELDS_TYPE.DURATION
            },
            {
                id: 'status',
                label: i18n.t('smartView:status'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                keyChoose: 'value',
                keySelect: 'value',
                keyJoin: 'name',
                options: CALL_STATUSES_OPTIONS
            }
        ]
    },
    {
        id: 'job',
        filterableType: OBJECT_TYPES.JOB,
        fields: [
            { id: 'count_active_job', label: i18n.t('smartView:total_jobs_active'), type: FIELDS_TYPE.COUNT_JOB },
            {
                id: 'service_id',
                label: i18n.t('smartView:service_type'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                shouldFetch: true,
                keyGetValue: 'services',
                keyJoin: 'name'
            },
            {
                id: 'status',
                label: i18n.t('smartView:job_status'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                keyJoin: 'name',
                isJobStatus: true,
                shouldFetch: false,
                options: []
            },

            {
                id: 'count_unconfirmed_job',
                label: i18n.t('smartView:count_unconfirmed_job'),
                type: FIELDS_TYPE.INTEGER
            },
            { id: 'count_confirmed_job', label: i18n.t('smartView:count_confirmed_job'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_reschedule_job', label: i18n.t('smartView:count_reschedule_job'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_pending_job', label: i18n.t('smartView:count_pending_job'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_completed_job', label: i18n.t('smartView:count_completed_job'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_canceled_job', label: i18n.t('smartView:count_canceled_job'), type: FIELDS_TYPE.INTEGER },
            {
                id: 'date',
                label: i18n.t('smartView:date'),
                prefixLabel: i18n.t('smartView:job'),
                type: FIELDS_TYPE.DATE_WITHOUT_PRESENT
            },
            {
                id: 'created_date',
                label: i18n.t('smartView:created_date'),
                prefixLabel: i18n.t('smartView:job'),
                type: FIELDS_TYPE.DATE_WITHOUT_PRESENT
            },
            {
                id: 'completed_job_by_tech',
                label: i18n.t('smartView:last_serviced_by'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                referencedType: REFERENCE_TYPES.SCHEDULES,
                options: null,
                keyGetValue: 'schedulesList',
                keyJoin: 'name',
                shouldFetch: true,
                keyChoose: 'tech'
            }
        ]
    },
    {
        id: 'estimate',
        filterableType: OBJECT_TYPES.ESTIMATE,
        fields: [
            {
                id: 'count_estimate',
                label: i18n.t('smartView:total_number_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_draft_estimate',
                label: i18n.t('smartView:number_of_draft_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_pending_estimate',
                label: i18n.t('smartView:number_of_pending_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_won_estimate',
                label: i18n.t('smartView:number_of_won_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_invoiced_estimate',
                label: i18n.t('smartView:number_of_invoiced_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'count_lost_estimate',
                label: i18n.t('smartView:number_of_lost_estimates'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'status',
                label: i18n.t('smartView:status_type'),
                prefixLabel: i18n.t('smartView:estimate'),
                type: FIELDS_TYPE.REFERENCE_TYPE,
                keyChoose: i18n.t('smartView:status'),
                keyJoin: 'name',
                keySelect: 'value',
                shouldFetch: false,
                options: ESTIMATE_STATUSES
            },
            {
                id: 'value',
                label: i18n.t('smartView:value'),
                prefixLabel: i18n.t('smartView:estimate'),
                type: FIELDS_TYPE.INTEGER
            },
            {
                id: 'created_by',
                label: i18n.t('smartView:created_by'),
                prefixLabel: i18n.t('smartView:estimate'),
                type: FIELDS_TYPE.REFERENCE_USER,
                keyGetValue: 'users',
                keyJoin: 'full_name'
            },
            {
                id: 'created_date',
                label: i18n.t('smartView:created_date'),
                prefixLabel: i18n.t('smartView:estimate'),
                type: FIELDS_TYPE.DATE_WITHOUT_PRESENT
            }
        ]
    },
    {
        id: 'email',
        filterableType: OBJECT_TYPES.EMAIL,
        fields: [
            { id: 'count_email', label: i18n.t('smartView:count_email'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_sent_email', label: i18n.t('smartView:count_sent_email'), type: FIELDS_TYPE.INTEGER },
            { id: 'count_received_email', label: i18n.t('smartView:count_received_email'), type: FIELDS_TYPE.INTEGER }
        ]
    }
];
