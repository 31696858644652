import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

const ServiceHideInvoiceBalance = ({ onHideInvoiceBalance = () => {} }) => {
    const { overrideCustomer } = useContext(SocketContext);

    useEffect(() => {
        if (!!overrideCustomer) {
            onHideInvoiceBalance(overrideCustomer);
        }
    }, [overrideCustomer]);

    return null;
};
export default ServiceHideInvoiceBalance;
