export const REMAINING_DAYS = 14;

export const STATUS_ACCOUNT = {
    ACTIVE: 'active',
    EXPIRED: 'expired',
    FREE_TRIAL: 'free_trial'
};

export const TYPE_ACCOUNT = {
    PRO: 'Pro',
    ANNUAL_PRO: 'Annual_Pro',
    FREE_TRIAL: 'free_trial'
};

export const ACCOUNT_ROLE = {
    SUPERADMIN: 'SuperAdmin',
    ADMIN: 'Admin',
    TECHNICIAN: 'Technician'
};
