export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 6H6C5.44772 6 5 6.44772 5 7V17C5 17.5523 5.44772 18 6 18H18C18.5523 18 19 17.5523 19 17V7C19 6.44772 18.5523 6 18 6Z"
            fill="#C1C9D4"
        ></path>
        <path
            d="M7 7C7 6.44772 6.55228 6 6 6C5.44772 6 5 6.44772 5 7V17C5 17.5523 5.44772 18 6 18C6.55228 18 7 17.5523 7 17V7Z"
            fill="#7A83A6"
        ></path>
    </svg>
);
