export default function IconLink({ isHorizontal = false, ...props }) {
    if (isHorizontal)
        return (
            <svg
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                transform="rotate(45)"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
            >
                <path
                    d="M9.73234 13.7678L13.2678 10.2322"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.61096 11.6464L4.8886 14.3688C3.65845 15.599 3.61096 17.5459 4.78253 18.7175C5.95411 19.8891 7.90109 19.8416 9.13124 18.6114L11.8536 15.8891"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.1465 8.11091L13.8688 5.38855C15.099 4.1584 17.046 4.11091 18.2176 5.28249C19.3891 6.45406 19.3416 8.40104 18.1115 9.63119L15.3891 12.3536"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.73234 13.7678L13.2678 10.2322"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.61096 11.6464L4.8886 14.3688C3.65845 15.599 3.61096 17.5459 4.78253 18.7175C5.95411 19.8891 7.90109 19.8416 9.13124 18.6114L11.8536 15.8891"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.1465 8.11091L13.8688 5.38855C15.099 4.1584 17.046 4.11091 18.2176 5.28249C19.3891 6.45406 19.3416 8.40104 18.1115 9.63119L15.3891 12.3536"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}