import IconSync from 'assets/icon/IconSync';
import React from 'react';

const CustomRestoreOption = ({ onInsert = () => {} }) => {
    return (
        <span className="editor-controls__btn tooltip restore-default" onClick={() => onInsert()}>
            <IconSync />
            <span className="tooltiptext top">Restore Default</span>
        </span>
    );
};

export default CustomRestoreOption;
