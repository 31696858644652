import {
    LIST_BUTTON_ACTION_BROADCAST as LIST_BUTTONS,
    LIST_BUTTON_MESSAGES_BROADCAST as LIST_BUTTON_MESSAGES
} from 'app/const/Settings';
import { LIST_TABLE_ITEM_TYPE } from '../App';

const getColumns = () => {
    return {
        header: {
            name: {
                title: 'template',
                style: 'col --lg --full'
            }
        },
        columns: [
            {
                id: 'name',
                status: true
            }
        ],
        contentConfig: {
            name: {
                id: 'name',
                style: 'col --lg --full --name',
                styleText: 'col-label btn-modal',
                type: LIST_TABLE_ITEM_TYPE.LINK
            }
        }
    };
};

export { LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
