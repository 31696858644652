import React from 'react';
import IconStick from 'assets/icon/IconStick';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const PinToTop = ({ sticky = 0, onPin = () => {} }) => {
    const { t } = useTranslation(['common']);

    const _actionPin = (e) => {
        e.stopPropagation();
        onPin(!sticky ? 1 : 0);
    };

    return (
        <div className={classNames('ml-1 wrap-pinicon', { 'has-pinned': !!sticky })}>
            {!!sticky ? (
                <div
                    onClick={_actionPin}
                    className="v2-btn-default --icon-lg --transparent btn-bg-blue tooltip is-unpin"
                >
                    <IconStick isBlue />
                    <span className="tooltiptext top ml-n4" tabIndex="-1">
                        {t('unpin_to_top')}
                    </span>
                </div>
            ) : (
                <div onClick={_actionPin} className="v2-btn-default --icon-lg --transparent btn-bg-blue tooltip is-pin">
                    <IconStick />
                    <span className="tooltiptext top ml-n4" tabIndex="-1">
                        {t('pin_to_top')}
                    </span>
                </div>
            )}
        </div>
    );
};

export default PinToTop;
