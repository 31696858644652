export default function IconCoordinate() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.5 18.5C15.366 18.5 18.5 15.366 18.5 11.5C18.5 7.63401 15.366 4.5 11.5 4.5C7.63401 4.5 4.5 7.63401 4.5 11.5C4.5 15.366 7.63401 18.5 11.5 18.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 13.5C12.6046 13.5 13.5 12.6046 13.5 11.5C13.5 10.3954 12.6046 9.5 11.5 9.5C10.3954 9.5 9.5 10.3954 9.5 11.5C9.5 12.6046 10.3954 13.5 11.5 13.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 2.5V6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M2.5 11.5H6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 16.5V20.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M16.5 11.5H20.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
