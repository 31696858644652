export const COMPONENT_TYPES = {
    TEXT_INPUT: 'text',
    SECTION: 'section',
    RADIO: 'radio',
    CHECKBOCK: 'checkbox',
    TEXT_AREA: 'textarea',
    LIST_BOX: 'listbox',
    SIGNATURE: 'signature',
    IMAGE: 'image',
    LIST_DATA: 'listData',
    SUB_LIST_BOX: 'sublistbox'
};

export const INPUT_TYPE_SPE = {
    CREDIT: 'credit'
};

export const LIST_BOX_SPECIAL = {
    CALENDAR: 'calendar',
    TECHNICIAN: 'technician',
    SUB_LIST_BOX: 'sub_list_box',
    CARD: 'card'
};

export const TEXT_AREA_TYPE = {
    ONLY_MAX_LINE: 'only_max_line',
    BREAK_LINE_ENTER: 'break_line_and_allow_enter',
    BREAK_LINE_NO_ENTER: 'break_line_and_no_enter',
    ENTER_FREEDOM: 'ENTER_FREEDOM'
};

export const TEXT_INPUT_TYPE = {
    NORMAL: 'normal',
    ADDRESS_FULL: 'address_full',
    ADDRESS_NORMAL: 'address_normal'
};
