import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import './styles/index.scss';

const EmojiMart = ({ handleOnClick = () => {} }) => {
    const addEmoji = (value) => handleOnClick(value);
    return <Picker data={data} autoFocus theme="light" onEmojiSelect={addEmoji} previewPosition="none" perLine={8} />;
};

export default EmojiMart;
