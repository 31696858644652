/* eslint-disable no-undef */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { TRACKER } from 'app/config/routes';
import { addPrefixPath } from 'app/const/Branch';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { updateAddonStatus, updateSettings } from 'common/redux/actions/authAction';
import { TRACKING_TYPES_ERRORS } from '../constant';
import { clearTracking, setTracking, trackerAlert } from '../utils';

const RealtimeWorkLog = ({ onStart = () => {}, onStop = () => {} }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const isTrackingPage = matchPath(pathname, { path: addPrefixPath(TRACKER), exact: true });

    const timeTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking);
    const { workLogStart, workLogStop } = useContext(SocketContext);

    useEffect(() => {
        if (workLogStart) {
            const data = workLogStart.data;
            setTracking(data);
            onStart(null, data.start);
        }
    }, [workLogStart]);

    useEffect(() => {
        if (workLogStop) {
            clearTracking();
            onStop(null, workLogStop.data, global.tracker_socket_id === workLogStop.socket_id);

            if (workLogStop.type) {
                trackerAlert('Time clocking is currently off. Please contact your admin.');
                if (isTrackingPage) history.replace(addPrefixPath('/'));
            }
            switch (workLogStop.type) {
                case TRACKING_TYPES_ERRORS.ADDONS:
                case TRACKING_TYPES_ERRORS.DOWNGRADE:
                    dispatch(updateAddonStatus({ keyword: 'WORK_LOG', data: 0 }));
                    break;
                case TRACKING_TYPES_ERRORS.PERMISSION:
                    dispatch(updateSettings({ time_tracking: { ...timeTracking, tracking: false } }));
                    break;
                default:
                    break;
            }
        }
    }, [workLogStop]);

    return null;
};

export default RealtimeWorkLog;
