import React, { useEffect, useRef, useState } from 'react';
import IconSearch from 'assets/icon/IconSearch';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconLoading from 'assets/icon/IconLoading';

let timer;

const GlobalSearch = ({
    placeholder,
    onSearch,
    style = '',
    defaultValue = '',
    isLoading = false,
    clearKeyword,
    onFocusSearch = () => {},
    autoFocus = false,
    spellCheck = true
}) => {
    const searchInputRef = useRef(null);

    const [keyword, setKeyword] = useState(defaultValue);

    useEffect(() => {
        return () => {
            clearTimeout(timer);
        };
    }, []);

    function _changeKeyword(keyword) {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            onSearch(keyword);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }

    const _handleSearchTerm = (e) => {
        const finalKeyword = e.target.value;
        if (finalKeyword === '') {
            timer && clearTimeout(timer);
            clearKeyword();
        } else {
            _changeKeyword(finalKeyword);
        }
        setKeyword(finalKeyword);
    };

    const _handleClearTerm = (e) => {
        e.stopPropagation();

        clearKeyword();
        setKeyword('');
    };

    const _handleOnClickWrapperSearchInput = (e) => {
        e.stopPropagation();
        if (!searchInputRef.current) return;
        searchInputRef.current.focus();
    };

    function _renderCloseSearch() {
        if (defaultValue === 0) {
            return <div className="close-icon" />;
        }

        return (
            <div className="close-icon" onClick={(e) => _handleClearTerm(e)}>
                {isLoading ? <IconLoading width={10} height={18} /> : <IconCircleClose />}
            </div>
        );
    }

    return (
        <div className={`${style}`} onClick={_handleOnClickWrapperSearchInput}>
            <span className="svg-search-absolute">
                <IconSearch />
            </span>
            <input
                ref={searchInputRef}
                type="text"
                value={keyword}
                placeholder={placeholder}
                onClick={(e) => onFocusSearch(e)}
                onChange={_handleSearchTerm}
                autoFocus={autoFocus}
                spellCheck={spellCheck}
            />

            {!!keyword && _renderCloseSearch()}
        </div>
    );
};

export default GlobalSearch;
