import React from 'react';

const LoadingDefault = () => {
    return (
        <div className="wrapper-box-edit__inner wrap-loading ">
            <div className="page-wrapper flex-auto form-account">
                <div className="head-main flex-betweenitems mb-2">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default --grey btn-x-sm loading">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="form-default">
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="rows__info">
                            <div className="status-btn btn-x-sm --sm loading bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__info loading">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --fourth" />
                            <div className="loading --animation --line --fourth mt-2" />
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__info">
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__label">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="rows__info --has-tag">
                            <div className="tag-label loading bg-white-cultured btn-x-sm" />
                            <div className="tag-label loading bg-white-cultured btn-x-sm" />
                            <div className="tag-label loading bg-white-cultured btn-x-sm" />
                        </div>
                    </div>
                    <div className="rows mt-1 rows-balance">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
            <div className="page-wrapper flex-auto form-field">
                <div className="head-main flex-betweenitems border-bottom-black-extradark">
                    <div className="loading --animation --line --onefourth"></div>
                    <div className="flexcenter gap-8">
                        <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                    </div>
                </div>
                <div className="form-default">
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="rows__info flexcenter">
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="rows__info flexcenter ">
                            <div className="tag-label loading --onefourth"></div>
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="rows__info flexcenter ">
                            <div className="tag-label loading --onefourth"></div>
                            <div className="tag-label loading --onefourth"></div>
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="rows__info flexcenter">
                            <div className="loading --animation --line --twothird"></div>
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="rows__info flexcenter">
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-wrapper flex-auto form-method form-inner">
                <div className="head-main flex-betweenitems">
                    <div className="loading --animation --line --twothird"></div>
                </div>
                <div className="rows mt-0">
                    <div className="flex-betweenitems w-100">
                        <div className="flexcenter gap-4 flex-1">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="loading --onefifth --animation --line"></div>
                        </div>
                        <div className="loading --twothird --animation --line"></div>
                    </div>
                    <div className="v2-btn-default --grey btn-md loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="rows mt-0">
                    <div className="flex-betweenitems w-100">
                        <div className="flexcenter gap-4 flex-1">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="loading --onefifth --animation --line"></div>
                        </div>
                        <div className="loading --twothird --animation --line"></div>
                    </div>
                    <div className="v2-btn-default --grey btn-md loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="rows mt-0">
                    <div className="flex-betweenitems w-100">
                        <div className="flexcenter gap-4 flex-1">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="loading --onefifth --animation --line"></div>
                        </div>
                        <div className="loading --twothird --animation --line"></div>
                    </div>
                    <div className="v2-btn-default --grey btn-md loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="page-wrapper flex-auto form-contact form-inner">
                <div className="head-main flex-betweenitems">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default loading --icon-lg bg-white-cultured" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
            </div>
            <div className="page-wrapper flex-auto form-location form-inner">
                <div className="head-main flex-betweenitems">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default loading --icon-lg bg-white-cultured" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows mt-0 p-3">
                    <div className="loading --animation --line --half" />
                </div>
            </div>
        </div>
    );
};

export default LoadingDefault;
