export const MAP_ACTIONS = {
    UPDATE_MAP_SIZE: 'UPDATE_MAP_SIZE',
    UPDATE_MAP_VISIBLE: 'UPDATE_MAP_VISIBLE'
};

export const updateSizeMap = (payload) => {
    return {
        type: MAP_ACTIONS.UPDATE_MAP_SIZE,
        payload
    };
};

export const updateMapVisible = (payload) => {
    return {
        type: MAP_ACTIONS.UPDATE_MAP_VISIBLE,
        payload
    };
};
