import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const LocationService = ({ onOpenLocation = () => {} }) => {
    const { locationData } = useSelector(({ locationReducer }) => locationReducer);

    useEffect(() => {
        if (locationData) onOpenLocation(locationData);
    }, [locationData]);

    return null;
};

export default LocationService;
