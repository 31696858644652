import { PAYMENT_MERCHANT, PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';

export const getMerchant = (key) => {
    switch (key) {
        case PAYMENT_MERCHANT_KEYWORD.SQUARE:
            return PAYMENT_MERCHANT.SQUARE_GATE_WAY;
        case PAYMENT_MERCHANT_KEYWORD.STRIPE:
        case PAYMENT_MERCHANT_KEYWORD.BANK_ACCOUNT:
        case PAYMENT_MERCHANT_KEYWORD.BANK:
            return PAYMENT_MERCHANT.STRIPE_GATE_WAY;
        default:
            return null;
    }
};

export function validateCardExpiration(month, year) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // January is 0
    const cardExpiration = new Date(year, month - 1, 1); // Set day to 1 to avoid issues with different number of days in a month

    if (cardExpiration < currentDate) {
        return 'Card is expired';
    } else if (year < currentYear || (year === currentYear && month < currentMonth)) {
        return 'Card expiration is before current date';
    } else if (month < 1 || month > 12) {
        return 'Invalid month';
    } else {
        return null;
    }
}
