import React, { useContext, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { useSmartView } from '.';
import { searchFilters } from '../components/table/utils';
import { checkRevertValue, convertFilterToFiltered, getNewValueOperator, isValidFilterDate } from '../components/utils';
import { FILTERS_LIST } from '../constants';
import { CUSTOM_ID, DATE_FIELD_TYPES, FUTURE_ID, PAST_ID } from '../constants/dateOptions';
import { OBJECT_TYPES, OPERATORS_EXCEPT_VALUE } from '../constants/types';

const SmartViewFilterContext = React.createContext({});
export const SmartViewFilterProvider = ({ initialData = {}, children }) => {
    const { handleUpdateFiltered, handleUpdateSnapshot } = useSmartView();
    const isActiveSMS = useSelector(({ auth }) => !!auth.user.settings.addons.smsplivo);
    const isActiveOpps = useSelector(({ auth }) => !!auth.user.settings.addons.opportunity);
    const isActiveVoip = useSelector(({ auth }) => !!auth?.user?.settings?.voip);
    const getListFilters = () => {
        let results = [...FILTERS_LIST];
        if (!isActiveSMS) results = results.filter((item) => item.id !== OBJECT_TYPES.SMS);
        if (!isActiveVoip) results = results.filter((item) => item.id !== OBJECT_TYPES.CALL);
        if (!isActiveOpps) results = results.filter((item) => item.id !== OBJECT_TYPES.OPPORTUNITY);
        return results;
    };
    const [state, dispatchState] = useReducer(reducer, {
        categoryId: null,
        filters: {},
        listOfFilters: getListFilters(),
        ...initialData
    });

    const handleCategoryId = (id) => {
        dispatchState((prevState) => ({ ...prevState, categoryId: id }));
    };

    const handleSearchFilter = (searchText) => {
        dispatchState((prevState) => ({
            ...prevState,
            categoryId: searchText ? 'search' : '',
            listOfFilters: searchFilters(searchText)
        }));
    };

    const handleUpdateFilters = ({ objectType, fieldName, fieldType, operatorId, value, checked }) => {
        dispatchState((prevState) => {
            let newFilters = { ...prevState.filters };
            const oldFilterGroup = newFilters?.[objectType]?.[fieldName];
            const filterValue = { objectType, fieldName, fieldType, operatorId, value };
            if (!oldFilterGroup && checked)
                newFilters = { ...newFilters, [objectType]: { ...newFilters[objectType], [fieldName]: filterValue } };
            if (oldFilterGroup && !checked) {
                delete newFilters[objectType][fieldName];
                if (
                    oldFilterGroup?.value !== undefined ||
                    (OPERATORS_EXCEPT_VALUE.includes(oldFilterGroup?.operatorId) && !oldFilterGroup?.value)
                ) {
                    const snapshot = Object.values(newFilters).flatMap((filter) => convertFilterToFiltered(filter));
                    handleUpdateSnapshot(snapshot, true);
                }
            }

            return { ...prevState, filters: newFilters };
        });
    };

    const handleUpdateConditionValue = ({ objectType, fieldName, fieldType, operatorId, value }) => {
        dispatchState((prevState) => {
            const newFilters = { ...prevState.filters };
            const filter = newFilters[objectType][fieldName];
            if (filter) filter['value'] = value;

            let shouldLoad = true;
            const isFieldDate = DATE_FIELD_TYPES.includes(fieldType);
            if (isFieldDate && !isValidFilterDate(value, operatorId)) shouldLoad = false;

            if (prevState.isEditing) {
                const oldData = prevState.oldData;
                const shouldRevertOldData = checkRevertValue({
                    fieldType,
                    operatorId,
                    newValue: value,
                    oldValue: oldData.value
                });

                if (shouldLoad) {
                    handleUpdateFiltered({
                        indexFilter: prevState.indexFilter,
                        filter: shouldRevertOldData ? oldData : filter
                    });
                }
            } else {
                const newSnapshot = Object.values(newFilters).flatMap((filter) => {
                    // False is editing mode or not
                    return convertFilterToFiltered(filter, false);
                });
                handleUpdateSnapshot(newSnapshot, shouldLoad);
            }

            return { ...prevState, filters: newFilters };
        });
    };

    const handleUpdateCondition = ({ objectType, fieldName, fieldType, operatorId, value: newValue }) => {
        dispatchState((prevState) => {
            const filters = { ...prevState.filters };
            let newFilter = filters?.[objectType]?.[fieldName];
            let shouldLoad = false;
            let shouldRevert = false;
            const filterCanNoneValue = OPERATORS_EXCEPT_VALUE.includes(operatorId);

            // Create new filter if not exist
            if (newFilter?.value === undefined || filterCanNoneValue) {
                if (filterCanNoneValue || OPERATORS_EXCEPT_VALUE.includes(newFilter?.operatorId)) shouldLoad = true;
                newFilter = { objectType, fieldName, fieldType, operatorId, value: newValue };
                filters[objectType][fieldName] = { objectType, fieldName, fieldType, operatorId, value: newValue };
                shouldRevert = newValue === undefined && !filterCanNoneValue;
            } else {
                shouldLoad = true;
                // Handle change operator and value if exist
                const value = getNewValueOperator({
                    fieldType,
                    oldValue: newFilter.value,
                    oldOperatorId: newFilter.operatorId,
                    newOperatorId: operatorId,
                    newValue
                });
                shouldRevert = value === undefined && !filterCanNoneValue;
                newFilter = { ...filters[objectType][fieldName], operatorId: operatorId, value };
                filters[objectType][fieldName] = newFilter;
                const isFieldDate = DATE_FIELD_TYPES.includes(fieldType);
                if (isFieldDate && value?.value) {
                    if (
                        ([PAST_ID, FUTURE_ID].includes(value.value) && !value.offset) ||
                        (CUSTOM_ID === value.value && !value.customDate)
                    ) {
                        shouldLoad = false;
                        shouldRevert = true;
                    }
                }
            }

            if (prevState.isEditing) {
                handleUpdateFiltered({
                    indexFilter: prevState.indexFilter,
                    filter: shouldRevert ? prevState.oldData : newFilter
                });
            }
            if (!prevState.isEditing) {
                const snapshot = Object.values(filters).flatMap((filter) => convertFilterToFiltered(filter));
                handleUpdateSnapshot(snapshot, shouldLoad);
            }
            return { ...prevState, filters };
        });
    };

    const dataProvider = {
        ...state,
        handleCategoryId,
        handleUpdateFilters,
        handleSearchFilter,
        handleUpdateCondition,
        handleUpdateConditionValue
    };
    return <SmartViewFilterContext.Provider value={dataProvider}>{children}</SmartViewFilterContext.Provider>;
};

export const useFilters = () => useContext(SmartViewFilterContext);
