import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { ACCOUNT_DETAIL_VOIP, ACTIVE_ACCOUNT_VOIP } from 'app/const/api/Voip';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ServiceSettingVoip from 'app/services/voip/ServiceSettingVoip';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { VOIPAddonsLoadingManagePlan } from '../loadings/VOIPAddonsLoadingManagePlan';
import ModalTooltip from './ModalTooltip';
import PlanChooseCard from './PlanChooseCard';
import { VOIPAddonsModifyPlan } from './VOIPAddonsModifyPlan';
import { VOIPAddonsSelectPlan } from './VOIPAddonsSelectPlan';

const ModalManagePlan = ({
    onClose = () => {},
    onUpdateSuccess = () => {},
    onChangePlan = () => {},
    isCanceled = false
}) => {
    const { t } = useTranslation('addons');
    const refAlert = useRef(null);
    const refCard = useRef(null);
    const refTooltip = useRef(null);
    const refSetting = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        balance: 250,
        below: 25,
        isLoading: true,
        account: {},
        oldAccount: {},
        isChanged: false
    });
    const { isLoading, account, isChanged } = state;

    useEffect(() => {
        _fetchPlan();
    }, []);

    const _handleChangePlan = (values) => {
        dispatchState((prev) => {
            const newData = {
                ...prev,
                account: { ...prev.account, ...values }
            };
            if (typeof values?.isChanged !== 'undefined') {
                newData.isChanged = !!values.isChanged;
            }

            return newData;
        });
    };

    const _handleUpdatePlan = ({ isNewCard = false, cardDetail = {} }) => {
        const newData = {
            plan_id: account.plan_id,
            user_quantity: account.user_quantity,
            recharge_amount: account.recharge_amount,
            recharge_threshold: account.recharge_threshold
        };

        if (isNewCard) {
            newData.stripe_card_token = cardDetail.token.id;
        }

        const _handleSuccess = ({ message }) => {
            refCard.current._close();

            onChangePlan();
            refSetting.current?.onFetchSetting();
            onUpdateSuccess({ account });
            _handleShowAlert({ message: !!message.length ? message : t('your_plan_has_been_successfully_updated') });
            dispatchState((prev) => ({ ...prev, oldAccount: prev.account }));
        };

        const _handleFail = ({ message }) => {
            refCard.current._removeLoading(message);
        };

        clientQuery(
            ACTIVE_ACCOUNT_VOIP,
            {
                method: 'PUT',
                toFormData: false,
                data: newData
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleClickUpdate = () => {
        refCard.current._open();
    };

    const _handleCancelVOIP = () => {
        _handleShowAlert({ message: t('please_contact_support'), isSuccess: false });
    };

    const _fetchPlan = () => {
        const _handleSuccess = (res) => {
            dispatchState({ ...res.data, isLoading: false, oldAccount: res.data.account });
        };

        clientQuery(ACCOUNT_DETAIL_VOIP, { method: 'GET' }, _handleSuccess);
    };

    const _handleShowAlert = ({ message = '', isSuccess = true }) => {
        refAlert.current.showStatusBar({
            id: 'modal_manage_plan',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    const _handleVisibleToolTip = () => {
        refTooltip.current.changeVisible();
    };

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className={`modal container-modal wrapper-has-preview is-preview-template wrapper-billing open`}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --preview-r">
                <div className="wrap-container">
                    <div className="wrap-container__form flex-column wrap-document-edit">
                        {isLoading ? (
                            <VOIPAddonsLoadingManagePlan />
                        ) : (
                            <Fragment>
                                <div className="header-modal">
                                    <h3 className="header-modal__label">{t('manage_your_plan')}</h3>
                                    <div className="btn-action cursor-pointer" onClick={onClose}>
                                        <IconClose />
                                    </div>
                                </div>
                                <AlertCustomer ref={refAlert} />
                                <div className="body-modal scrolls">
                                    <VOIPAddonsSelectPlan
                                        isShowFooter={false}
                                        isEffectChange
                                        wrapClassName="wrap-plans flex-column gap-12"
                                        data={state}
                                        onContinue={_handleChangePlan}
                                        isCanceled={isCanceled}
                                    />
                                    <div className="is-divider --horizontal my-3" />
                                    <VOIPAddonsModifyPlan
                                        isShowFooter={false}
                                        data={state}
                                        onChange={_handleChangePlan}
                                        isManage
                                        onVisibleToolTip={_handleVisibleToolTip}
                                        isCanceled={isCanceled}
                                    />
                                </div>
                                <div className="footer-modal">
                                    <div className="v2-btn-default has-bg-red" onClick={_handleCancelVOIP}>
                                        {t('request_to_cancel_voip')}
                                    </div>
                                    <div className="flexcenter justify-end flex-1">
                                        <div className="v2-btn-default --transparent" onClick={onClose}>
                                            {t('cancel')}
                                        </div>
                                        <div
                                            className={classNames('v2-btn-main ml-2', {
                                                'is-disable': !isChanged && isCanceled
                                            })}
                                            onClick={_handleClickUpdate}
                                        >
                                            {t('update_plan')}
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <ModalTooltip ref={refTooltip} />
                </div>
            </div>
            <PlanChooseCard ref={refCard} onChooseCardSuccess={_handleUpdatePlan} />
            <ServiceSettingVoip ref={refSetting} />
        </ReactModal>
    );
};

export default ModalManagePlan;
