export const TYPE_REPORT_REVENUE_TAGS = {
    GET_LIST: 'GET_LIST_REPORT_REVENUE_TAGS',
    GET_LIST_PAYMENT: 'GET_LIST_REPORT_REVENUE_TAGS_PAYMENT',
    GET_LIST_METHOD_FILTER_REVENUE_BY_TAGS: 'GET_LIST_METHOD_FILTER_REVENUE_BY_TAGS'
};

// actions
export const getListReportRevenueByTagsMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_TAGS.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByTagsDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_TAGS.GET_LIST_PAYMENT,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterRevenueByTagsRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_TAGS.GET_LIST_METHOD_FILTER_REVENUE_BY_TAGS,
        actionSuccess,
        actionFailed
    };
};
