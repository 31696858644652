import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCountNotificationRequest } from 'common/redux/actions/notificationAction';
import { TAB_NOTIFICATIONS } from 'app/const/Header';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

import IconBell from 'assets/icon/IconBell';
import Notification from 'app/modules/notifications';
import classNames from 'classnames';

function NotificationHeader({ isExpired = false }) {
    const { t } = useTranslation('header');
    const dispatch = useDispatch();
    const refDowpdown = useRef(null);
    const [totalNoti, setTotalNoti] = useState({ unread: 0, read: 0 });
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
        !isExpired && _handleGetCountNoti();
    }, []);

    useEffect(() => {
        if (isMenuVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isMenuVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown_notification_list');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown_notification_list');
        if (
            refDowpdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDowpdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isMenuVisible && setIsMenuVisible(false);
    };

    const _handleGetCountNoti = () => {
        dispatch(getCountNotificationRequest(getCountNotificationSuccess));
    };

    const getCountNotificationSuccess = (response) => {
        const dataRenponse = response?.data;
        if (!dataRenponse || typeof dataRenponse !== 'object') {
            return false;
        }
        setTotalNoti({
            unread: dataRenponse.total_unread || 0,
            read: dataRenponse.total_read || 0
        });
    };

    const _handleToggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const handleOnChangeTotalUnread = (numberMarked, currentTab) => {
        if (currentTab === TAB_NOTIFICATIONS.READ) {
            setTotalNoti((prev) => {
                const tempUnread = prev.unread + numberMarked;
                const tempRead = prev.read - numberMarked;
                return {
                    unread: tempUnread > 0 ? tempUnread : 0,
                    read: tempRead > 0 ? tempRead : 0
                };
            });
        } else {
            setTotalNoti((prev) => {
                const tempUnread = prev.unread - numberMarked;
                const tempRead = prev.read + numberMarked;
                return {
                    unread: tempUnread > 0 ? tempUnread : 0,
                    read: tempRead > 0 ? tempRead : 0
                };
            });
        }
    };

    const isHasNoti = totalNoti.unread > 0;

    return (
        <div
            ref={refDowpdown}
            className={classNames('boxs-notification notifi nav-notification tabs-notify', { 'is-disable': isExpired })}
        >
            <div
                className={`btn-notification notify-btn tooltip ${isHasNoti && 'has-notify'} ${
                    isMenuVisible && 'active'
                }`}
                onClick={_handleToggleMenu}
            >
                <IconBell isDoubleActive />
                {isHasNoti && <span className="budget">{totalNoti.unread > 999 ? '+999' : totalNoti.unread || 0}</span>}
                <p className="tooltiptext bottom">{t('notifications')}</p>
            </div>

            <Notification
                clsActive={isMenuVisible}
                onClose={_handleToggleMenu}
                totalUnread={totalNoti.unread}
                totalRead={totalNoti.read}
                onChangeTotalUnread={handleOnChangeTotalUnread}
            />
        </div>
    );
}

export default NotificationHeader;
