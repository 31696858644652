import { GdGridRowContent } from 'app/components/grid/GdGridRow';
import classNames from 'classnames';
import React, { useId } from 'react';

const GDRow = ({
    isOddItem = false,
    startTransform,
    row,
    columns,
    contentConfig,
    measureRef,
    handleClick = () => {}
}) => {
    const idRow = useId();
    return (
        <div
            ref={measureRef}
            style={{
                position: 'absolute',
                transform: `translateY(${startTransform}px)`,
                top: 0,
                left: 0,
                width: '100%'
            }}
            className={classNames('rows', { '--odd': isOddItem, '--even': !isOddItem })}
        >
            {/* WARNING: When using GDTableSticky must add more props for GDGridRowContent */}
            <GdGridRowContent
                idRow={idRow}
                row={row}
                columns={columns}
                contentConfig={contentConfig}
                handleClick={handleClick}
            />
        </div>
    );
};

export default GDRow;
