import { SMART_VIEWS_TYPES } from 'common/redux/actions/smartviews';

const DEFAULT_STATE = { isFirstTime: true, data: [] };
const initialState = { city: DEFAULT_STATE, zip: DEFAULT_STATE, items: DEFAULT_STATE };

const SVReducer = (state = initialState, action) => {
    switch (action.type) {
        case SMART_VIEWS_TYPES.SV_GET_CITY_SUCCESS:
            return { ...state, city: { isFirstTime: false, data: action.payload } };
        case SMART_VIEWS_TYPES.SV_GET_ZIP_SUCCESS:
            return { ...state, zip: { isFirstTime: false, data: action.payload } };
        case SMART_VIEWS_TYPES.SV_GET_ITEMS_SUCCESS:
            return { ...state, items: { isFirstTime: false, data: action.payload } };
        default:
            return state;
    }
};

export default SVReducer;
