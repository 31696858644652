import React, { memo } from 'react';

const GdLazyImage = ({ className, src, alt, width, height }) => {
    const intWith = width || '100%';
    const intHeight = height || '100%';

    return <img loading="lazy" src={src} width={intWith} height={intHeight} alt={alt} className={className} />;
};

const compare = (prevProps, nextProps) => {
    return (
        prevProps.src === nextProps.src &&
        prevProps.alt === nextProps.alt &&
        prevProps.width === nextProps.width &&
        prevProps.height === nextProps.height
    );
};

export default memo(GdLazyImage, compare);
