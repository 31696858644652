export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        viewBox="0 0 24 24"
    >
        <defs>
            <path id="0y0jk29yqa" d="M0 0H24V24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <path d="M0 0H1175V3224H0z" transform="translate(-307 -2969)" />
                <g>
                    <g transform="translate(-307 -2969) translate(128 2969) translate(179)">
                        <mask id="xwkgcbbi2b" fill="#fff">
                            <use xlinkHref="#0y0jk29yqa" />
                        </mask>
                        <g mask="url(#xwkgcbbi2b)">
                            <path d="M0 0H24V24H0z" />
                            <path
                                fill="#C4CCD9"
                                d="M5 7h14c.552 0 1 .448 1 1v10.586c0 .552-.448 1-1 1-.265 0-.52-.106-.707-.293l-2-2c-.188-.188-.442-.293-.707-.293H5c-.552 0-1-.448-1-1V8c0-.552.448-1 1-1z"
                            />
                            <circle cx={9} cy={7} r={2} fill="#7489AE" /> <circle cx={15} cy={7} r={2} fill="#7489AE" />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
