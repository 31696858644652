import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { GLOBAL_SEARCH } from 'app/const/Api';

export function* customerSearch({ request, onSuccess, onFailed }) {
    try {
        const response = yield call(fetchWithToken, GLOBAL_SEARCH, request);
        if (response && response.success) {
            if (typeof onSuccess === 'function') {
                onSuccess(response);
            }
            return;
        }
        if (typeof onFailed === 'function') {
            onFailed(response);
        }
    } catch (error) {
        if (typeof onFailed === 'function') {
            onFailed(error);
        }
    }
}
