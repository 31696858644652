import moment from 'moment';
import React from 'react';
import { mergeTimeOverlap } from 'common/utils/TimeUtils';

const EventContent = ({ event }) => {
    const { overlapEvents = [], startUnix } = event._def.extendedProps;
    if (!overlapEvents.length) return null;

    return mergeTimeOverlap(overlapEvents).map((overlap, index) => {
        const width = moment.unix(overlap.end).diff(moment.unix(overlap.start), 'minutes');
        if (!width) return null;

        return (
            <div
                key={event.id + overlap.start + index}
                className="overlap-time-event"
                style={{ width, position: 'absolute', top: 0, left: (overlap.start - startUnix) / 60 }}
            />
        );
    });
};

export default EventContent;
