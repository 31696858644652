import React, { Fragment, useContext, useEffect, useId, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { reducer } from 'app/const/Reducer';
import IconDropDown from 'assets/icon/IconDropDown';
import {
    DEFAULT_VALUE_PACKAGE,
    ESTIMATE_DYNAMIC_TYPE,
    ESTIMATE_TYPE,
    LIST_ESTIMATE_TYPE
} from 'app/modules/jobdetail/const/Estimate';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import GdConfirm from 'app/components/confirm';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { useSelector } from 'react-redux';
import { getPlanUser } from 'common/utils/PermissionUtils';
import IconGrowth from 'assets/icon/IconGrowth';
import IconTooltip from 'assets/icon/IconTooltip';
import { addBranchPath } from 'app/const/Branch';
import { ADDONS_ESTIMATE } from 'app/config/routes';

const EstimateType = () => {
    const { t } = useTranslation('');

    const [state, setState] = useReducer(reducer, {
        selected: ESTIMATE_TYPE.BASIC
    });
    const { onChangeWithPreData, onChangeEstimatePackage, invoiceData } = useContext(AddInvoiceContext);
    const { type: parentTypeSelected, deposit } = invoiceData;
    const isPayDeposit = !!deposit.invoices?.length;
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const estimateAddon = useSelector(({ auth }) => auth.user.settings.addons.estimates);

    const { isTrial, isGrowthPlan } = getPlanUser(profileData);
    const shouldActiveGrowth = !isGrowthPlan && !isTrial;

    const { selected: finalSelected } = state;
    const refDropdown = useRef(null);
    const dropdowId = useId(null);
    const refConfirm = useRef(null);

    useEffect(() => {
        if (parentTypeSelected && parentTypeSelected !== finalSelected) {
            setState({ selected: parentTypeSelected });
        }
    }, [parentTypeSelected]);

    const _convertItem = (newType) => {
        switch (newType) {
            case ESTIMATE_TYPE.BASIC:
                onChangeWithPreData((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.BASIC,
                        items: prev.items.map((liveItem) => {
                            return {
                                ...liveItem,
                                type: '',
                                package_id: ''
                            };
                        }),
                        discount: prev.discount.type ? prev.discount : DEFAULT_VALUE_PACKAGE.discount,
                        deposit: prev.deposit?.type ? prev.deposit : DEFAULT_VALUE_PACKAGE.deposit,
                        packages: []
                    };
                });
                break;
            case ESTIMATE_TYPE.DYNAMIC:
                onChangeWithPreData((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.DYNAMIC,
                        items: prev.items.map((liveItem) => {
                            return {
                                ...liveItem,
                                type: ESTIMATE_DYNAMIC_TYPE.REQUIRED,
                                package_id: ''
                            };
                        }),
                        packages: [],
                        discount: prev.discount.type ? prev.discount : DEFAULT_VALUE_PACKAGE.discount,
                        deposit: prev.deposit?.type ? prev.deposit : DEFAULT_VALUE_PACKAGE.deposit
                    };
                });
                break;
            case ESTIMATE_TYPE.PACKAGES:
                const packageId = Date.now();

                onChangeEstimatePackage((prev) => {
                    return {
                        ...prev,
                        type: ESTIMATE_TYPE.PACKAGES,
                        items: prev.items.map((liveItem) => {
                            return {
                                ...liveItem,
                                type: '',
                                package_id: packageId
                            };
                        }),
                        packages: [
                            {
                                ...DEFAULT_VALUE_PACKAGE,
                                id: packageId,
                                discount: prev.discount.type ? prev.discount : DEFAULT_VALUE_PACKAGE.discount,
                                deposit: prev.deposit.type ? prev.deposit : DEFAULT_VALUE_PACKAGE.deposit
                            },
                            {
                                ...DEFAULT_VALUE_PACKAGE,
                                id: packageId + 1,
                                color: '#f6d21b'
                            }
                        ],
                        taxes: [],
                        discount: {}
                    };
                });
                break;
            default:
                break;
        }
    };

    const _getContent = (oldType, newType) => {
        switch (oldType) {
            case ESTIMATE_TYPE.DYNAMIC:
                if (newType === ESTIMATE_TYPE.BASIC) {
                    return 'desc_confirm_estimate_dynamic_to_basic';
                }
                return 'desc_confirm_estimate_dynamic_to_package';
            case ESTIMATE_TYPE.PACKAGES:
                if (newType === ESTIMATE_TYPE.BASIC) {
                    return 'desc_confirm_estimate_package_to_basic';
                }
                return 'desc_confirm_estimate_package_to_dynamic';
            default:
                return '';
        }
    };

    function _handleSelect(e, selected, isActive) {
        if (!isActive) {
            const oldType = finalSelected;
            if (finalSelected === ESTIMATE_TYPE.BASIC) {
                _convertItem(selected);
                setState({
                    selected
                });
            } else {
                refConfirm.current.open(selected, t(`setting:${_getContent(oldType, selected)}`));
            }
        }
        refDropdown.current._closeDropdown();
    }

    const _handleConfirm = (valueSelected) => {
        _convertItem(valueSelected);
    };

    const _renderItemType = () => {
        return LIST_ESTIMATE_TYPE.map((item) => {
            const { id, name, isDefault, description, key } = item;
            const isActive = id === parentTypeSelected;
            const isActiveOptions = !!estimateAddon?.[key];
            const isNavigate = !isDefault && (!isActiveOptions || shouldActiveGrowth);

            if (isNavigate) {
                if (!!estimateAddon && !shouldActiveGrowth && !isActiveOptions) return null;

                return (
                    <Link
                        key={id}
                        to={addBranchPath(ADDONS_ESTIMATE)}
                        className={classNames('items', { active: isActive })}
                        target="_blank"
                    >
                        <p className="txt-ellipsis">{name}</p>
                        <p className="tooltip ml-1 d-flex">
                            <IconTooltip />
                            <span className="tooltiptext top">{description}</span>
                        </p>
                        {shouldActiveGrowth ? (
                            <div className="flex-1 d-flex justify-end">
                                <div className="status-btn --has-icon growth-plan">
                                    <IconGrowth />
                                    {t('addons:growth_upgrade')}
                                </div>
                            </div>
                        ) : null}
                    </Link>
                );
            }

            return (
                <li
                    onClick={(e) => _handleSelect(e, id, isActive)}
                    key={id}
                    className={classNames('items', { active: isActive })}
                >
                    <p className="txt-ellipsis">{name}</p>
                </li>
            );
        });
    };

    const getSelected = useMemo(() => {
        return LIST_ESTIMATE_TYPE.find((item) => item.id === finalSelected) || {};
    }, [finalSelected]);

    return (
        <div className="sm-row">
            <div className="txt">
                <IconGrowth isHeader />
                {t('type')}
            </div>
            <div className={`detail ${isPayDeposit ? 'is-disable' : ''}`}>
                <CalendarDropdown
                    ref={refDropdown}
                    id={dropdowId}
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu content-full --right"
                    customDropButton={() => (
                        <Fragment>
                            <p className="txt-ellipsis">{getSelected.name}</p>
                            <IconDropDown />
                        </Fragment>
                    )}
                >
                    <ul>{_renderItemType()}</ul>
                </CalendarDropdown>
            </div>

            <GdConfirm
                ref={refConfirm}
                title={t('confirm')}
                message={t('setting:msg_confirm_move_job_and_all_recurring')}
                listButton={{ close: true, confirm: true }}
                onConfirm={_handleConfirm}
            />
        </div>
    );
};

export default EstimateType;
