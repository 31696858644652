const LoadingTable = ({ isShowDescriptionLoading = false, isShowTotalLoading = false, numberRowLoading = 10 }) => {
    const renderLoadingElements = () => {
        const loadingElements = [];
        for (let i = 0; i < numberRowLoading; i++) {
            loadingElements.push(
                <div key={i.toString()} className={`flexcenter gap-20 p-3 ${i !== 0 ? 'shadow-grey' : ''}`}>
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --twothird mt-0"></div>
                    <div className="loading --animation --line --onetenth mt-0"></div>
                    <div className="loading --animation --line --onefifth mt-0"></div>
                </div>
            );
        }

        return loadingElements;
    };

    return (
        <div className="boxs__content --progress-bar">
            {isShowDescriptionLoading && (
                <div className="is-summary">
                    <div className="rows py-3">
                        <div className="flex-column gap-6 flex-1 py-2 px-3">
                            <div className="loading --animation --line --onefifth"></div>
                            <div className="loading --animation --line --onefifth"></div>
                        </div>
                    </div>
                </div>
            )}
            {isShowTotalLoading && (
                <div className="is-summary">
                    <div className="rows py-3">
                        <div className="flex-column gap-6 flex-1 py-2 px-3">
                            <div className="loading --animation --line --fourth"></div>
                            <div className="loading --animation --line --half"></div>
                        </div>
                        <div className="flex-column gap-6 flex-1 py-2 px-3 border-left-black-extradark">
                            <div className="loading --animation --line --onefifth"></div>
                            <div className="loading --animation --line --fourth"></div>
                        </div>
                    </div>
                </div>
            )}
            <div>{renderLoadingElements()}</div>
        </div>
    );
};

export default LoadingTable;
