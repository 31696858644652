import { TYPE_CUSTOMER } from "common/redux/actions/customers/customerAction";

const customerReducer = (state = {
    isRefresh: false
}, action) => {
    switch (action.type) {
        case TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER:
            return { ...state, isRefresh: true };

        case TYPE_CUSTOMER.GET_LIST_CUSTOMER_SUCCESS:
        case TYPE_CUSTOMER.GET_LIST_CUSTOMER_FAILED:
            return { ...state, isRefresh: false };
        default:
            return state;
    }
};

export default customerReducer;
