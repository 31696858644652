import classNames from 'classnames';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const DropdownContacts = forwardRef(({ modifiers = [], defaultValue = '', contacts = [] }, ref) => {
    const { t } = useTranslation('customers');

    const initialState = ({ defaultValue }) => {
        const result = { selected: {} };
        if (defaultValue) {
            result.selected = contacts?.find((contact) => contact.id === defaultValue) || {};
        } else {
            result.selected = contacts?.length === 1 ? contacts?.[0] || {} : {};
        }

        return result;
    };
    const [state, dispatchState] = useReducer(reducer, { defaultValue }, initialState);
    const { selected } = state;
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: () => selected }));

    useEffect(() => {
        if (defaultValue !== selected?.id)
            dispatchState((prevState) => ({ ...prevState, ...initialState({ defaultValue }) }));
    }, [defaultValue]);

    const _handleSelect = (contact) => {
        refDropdown.current?._close();
        dispatchState((prevState) => ({ ...prevState, selected: contact }));
    };

    const _renderName = (contact) => {
        return (
            contact.customer?.full_name ||
            // eslint-disable-next-line no-prototype-builtins
            (contact.hasOwnProperty('first_name') ? `${contact.first_name} ${contact.last_name}` : t('none'))
        );
    };

    const _renderButtonCustom = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis ml-1">{_renderName(selected)}</span>
                <IconArrowDown />
            </Fragment>
        );
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isUseToggle
            isCalculateWidth
            modifiers={modifiers}
            wrapperClassName="v2-dropdown"
            buttonClassName="dropbtn items"
            wrapperListClass="v2-dropdown__menu content-user scrolls"
            customButton={_renderButtonCustom()}
        >
            <ul>
                {contacts?.map((contact) => (
                    <li
                        key={contact.id}
                        className={classNames('items', { active: contact.id === selected?.id })}
                        onClick={() => _handleSelect(contact)}
                    >
                        {_renderName(contact)}
                    </li>
                ))}
                {!contacts?.length ? <li className="items">{t('no_matches')}</li> : null}
            </ul>
        </DropdownPopper>
    );
});

export default DropdownContacts;
