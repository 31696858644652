import React from 'react';

export default function TabWaitingSync({ data = [] }) {
    function _renderListData() {
        return data.map((item, index) => {
            return (
                <p className="screen-sync__history" key={index.toString()}>
                    {item.log}
                </p>
            );
        });
    }
    return (
        <div className="tab-content-active">
            <div className="screen-sync">{_renderListData()}</div>
        </div>
    );
}
