import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { SETTINGS_TEMPLATES_NOTES } from 'app/config/routes';
import { DEFAULT_ALL } from 'app/const/App';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { ESTIMATES_TEMPLATE_OPTIONS, TEMPLATES_MANAGER_MENU } from 'app/const/Notes';
import { getPermissionTemplateManager, isNotPermissionValue } from 'common/utils/PermissionUtils';
import { checkAddon } from 'common/utils/AddonUtils';

const SideMenu = () => {
    const { t } = useTranslation(['setting']);
    const location = useLocation();
    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});

    const _renderMenus = () => {
        let previousWasDivider = false;

        const _getEstimateItems = () => {
            return ESTIMATES_TEMPLATE_OPTIONS.filter(
                (item) => item.isDefault || checkAddon(addonsList?.estimates?.[item.key])
            );
        };

        return [
            {
                id: DEFAULT_ALL,
                name: 'all_templates',
                link: SETTINGS_TEMPLATES_NOTES
            },
            ...TEMPLATES_MANAGER_MENU,
            ..._getEstimateItems()
        ].map((item, index) => {
            const { id: itemId, name: itemName, link: itemLink } = item;
            if (isNotPermissionValue(getPermissionTemplateManager(permissions), itemName)) return null;

            if (!itemLink) {
                if (previousWasDivider) return null;
                previousWasDivider = true;
                return <div key={itemId} className="border-line my-1 mx-2"></div>;
            }

            previousWasDivider = false;
            const linkReplace = itemLink.replace(':id', itemId);

            return (
                <li key={`${index}_${itemId}`}>
                    <Link
                        to={addBranchPath(linkReplace)}
                        className={classNames('items', {
                            active: matchPath(location.pathname, {
                                path: addPrefixPath(linkReplace),
                                exact: true
                            })
                        })}
                    >
                        {t(`setting:${itemName}`)}
                    </Link>
                </li>
            );
        });
    };

    return (
        <div className="sidebar-pages --sm flex-auto h-100">
            <ul>{_renderMenus()}</ul>
        </div>
    );
};
export default SideMenu;
