import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import Header from './components/Header';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    GET_QUICKBOOK_DETAIL,
    ADDON_CONNECT_QUICKBOOK,
    GET_LIST_QUICKBOOKS_ACCOUNT,
    ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON
} from 'app/const/api/V2';
import Loading from './components/Loading';
import { useLocation, useHistory } from 'react-router-dom';
import queryStrings from 'query-string';
import TabTaxSync from './tabs/tax';
import TabDiscountSync from './tabs/discount';
import { TYPE_QUICKBOOKS_SYNC } from 'app/const/addons/Quickbooks';
import TabStripeSync from './tabs/stripe';
import TabCreditSync from './tabs/credit';
import SalesProductIncome from './tabs/sales';
import { reducer } from 'app/const/Reducer';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import AddonBack from '../components/AddonBack';
import { ADDONS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { checkAccessFail } from 'common/utils/PermissionUtils';

export default function AddonQuickBooksSync() {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        status: 0,
        account: [],
        isFetchAccount: false,
        tax: {},
        chart: {},
        qb_connected: false
    });
    const { search } = useLocation();
    const history = useHistory();
    const branchId = useSelector(({ auth }) => auth.user.company.branch.id);
    const { t } = useTranslation(['common', 'addons']);

    const {
        options: finalListOptions = [],
        account: finalListAccount = [],
        chart: finalChart = {},
        isFetchAccount: finalIsFetchAccount,
        qb_connected: qbConnected
    } = state;

    const refConfirm = useRef(null);

    useEffect(() => {
        if (search) {
            const { code, realmId } = queryStrings.parse(search);
            _connectQuickbook(code, realmId);
        } else {
            _getQuickbookDetail();
        }
    }, []);

    function _getQuickbookDetail() {
        clientQuery(
            GET_QUICKBOOK_DETAIL,
            { method: 'GET', data: { url: window.location.href.replace(`/${branchId}`, '') } },
            _getQuickbookDetailSuccess,
            _connectFailed
        );
    }

    function _connectQuickbook(authorization_code, realm_id) {
        clientQuery(
            ADDON_CONNECT_QUICKBOOK,
            {
                method: 'POST',
                data: {
                    authorization_code,
                    realm_id
                }
            },
            _connectQuickbookSuccess,
            _connectFailed
        );
    }

    function _connectQuickbookSuccess() {
        history.replace({ search: '' });
        _getQuickbookDetail();
    }

    function _connectFailed(error = {}) {
        refConfirm.current.open(null, t('addons:connect_quickbook_failed'));
        checkAccessFail(error);
    }

    function _getQuickbookDetailSuccess(response) {
        dispatchState({
            ...response.data,
            isLoading: false
        });

        if (response.data.status) {
            _getListAccountIncome();
        }
    }

    function _getListAccountIncome() {
        clientQuery(
            GET_LIST_QUICKBOOKS_ACCOUNT,
            {
                method: 'GET',
                data: { type: 'income' }
            },
            _getListAccountIncomeSuccess
        );
    }

    function _getListAccountIncomeSuccess(reponse) {
        dispatchState({
            account: reponse.data,
            isFetchAccount: true
        });
    }

    function _handleUpdate(newData) {
        dispatchState({
            ...newData
        });
    }

    function _handleUpdateTax(newValue) {
        dispatchState({
            tax: newValue
        });
    }

    function _handleChangeAccount(accountId) {
        dispatchState((prev) => {
            let tempOptions = [...prev.options];

            tempOptions = tempOptions.map((item) => {
                if (item.keyword === TYPE_QUICKBOOKS_SYNC.ACCOUNT) {
                    return {
                        ...item,
                        value: accountId
                    };
                }
                return item;
            });

            return {
                ...prev,
                options: tempOptions
            };
        });
    }

    function _handleUpdateOption(keyword, value) {
        clientQuery(ACTIONN_UPDATE_OPTION_QUICKBOOKS_ADDON, {
            method: 'PUT',
            data: { keyword, value }
        });
    }

    function _handleChangeChart(bankAccount, expenseAccount) {
        dispatchState((prev) => {
            return {
                ...prev,
                chart: {
                    ...prev.chart,
                    act_bank: bankAccount,
                    act_exp: expenseAccount
                }
            };
        });
    }

    function _handleUpdateAccountIncome(accountIncome) {
        dispatchState((prev) => {
            return {
                ...prev,
                chart: {
                    ...prev.chart,
                    acc_income: accountIncome
                }
            };
        });
    }

    function _renderTabs() {
        if (!qbConnected) {
            return false;
        }

        let renderTabStripe = false;

        return finalListOptions.map((item) => {
            const finalType = item.keyword;

            switch (finalType) {
                case TYPE_QUICKBOOKS_SYNC.TAX:
                    return (
                        <TabTaxSync
                            key={finalType}
                            keyword={finalType}
                            parentData={item}
                            taxData={state.tax}
                            onUpdateTaxData={_handleUpdateTax}
                            onUpdateOption={_handleUpdateOption}
                        />
                    );
                case TYPE_QUICKBOOKS_SYNC.DISCOUNT:
                    const accountQuickbooks =
                        finalListOptions.find((parent) => parent.keyword === TYPE_QUICKBOOKS_SYNC.ACCOUNT) || {};

                    return (
                        <TabDiscountSync
                            keyword={finalType}
                            key={finalType}
                            parentData={item}
                            onUpdateOption={_handleUpdateOption}
                            accountSelected={accountQuickbooks.value?.toString() || 0}
                            isFetched={finalIsFetchAccount}
                            account={finalListAccount}
                            onChange={_handleChangeAccount}
                        />
                    );
                case TYPE_QUICKBOOKS_SYNC.STRIPE:
                case TYPE_QUICKBOOKS_SYNC.SQUARE:
                    if (renderTabStripe) {
                        return false;
                    }
                    renderTabStripe = true;

                    return (
                        <TabStripeSync
                            keyword={finalType}
                            key={finalType}
                            squareData={
                                finalListOptions.find((parent) => parent.keyword === TYPE_QUICKBOOKS_SYNC.SQUARE) || {}
                            }
                            stripeData={
                                finalListOptions.find((parent) => parent.keyword === TYPE_QUICKBOOKS_SYNC.STRIPE) || {}
                            }
                            onUpdateOption={_handleUpdateOption}
                            onChangeAccount={_handleChangeChart}
                            act_bank={finalChart.act_bank || ''}
                            act_exp={finalChart.act_exp || ''}
                        />
                    );
                case TYPE_QUICKBOOKS_SYNC.CREDIT:
                    return (
                        <TabCreditSync
                            parentData={item}
                            keyword={finalType}
                            key={finalType}
                            onUpdateOption={_handleUpdateOption}
                            isFetched={finalIsFetchAccount}
                            account={finalListAccount}
                        />
                    );
                default:
                    return false;
            }
        });
    }

    const renderContent = () => {
        const { authorization_code_url, status, qb_connected, keyword, name, level, upgrade } = state;
        return (
            <div className="addons-wrap__container">
                <div className="header">
                    <AddonBack />
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper --quickbooks">
                        <Header
                            linkConnect={authorization_code_url}
                            onUpdate={_handleUpdate}
                            isActiveAddon={!!status}
                            qbConnected={qb_connected}
                            keyword={keyword}
                            name={name}
                            status={status}
                            level={level}
                            upgrade={upgrade}
                        />
                        {_renderTabs()}
                        {qbConnected && (
                            <SalesProductIncome
                                isFetched={finalIsFetchAccount}
                                account={finalListAccount}
                                acc_income={finalChart.acc_income || ''}
                                onUpdate={_handleUpdateAccountIncome}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const handleGoBack = () => history.push(addBranchPath(ADDONS));

    return (
        <Fragment>
            {state.isLoading ? <Loading /> : renderContent()}

            <GdConfirm
                ref={refConfirm}
                title={t('common:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('common:confirm')}
                onConfirm={handleGoBack}
                onClose={handleGoBack}
            />
        </Fragment>
    );
}
