import React from 'react';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';

const TagItem = ({
    id,
    name,
    deleteWithId = false,
    type,
    onRemoveTag = () => {},
    isTagsSideMenu = false,
    isTagsAccount = false
}) => {
    const _handleRemove = () => {
        onRemoveTag(deleteWithId ? id : name);
    };

    return (
        <div data-tag-label={`tag-label_${type}_${id}`} className={classNames('tag-label', { tag: !isTagsAccount })}>
            <span className={classNames({ 'tag-label__ellipsis': !isTagsSideMenu })}>{name}</span>
            <div className="svg-remove black" onClick={_handleRemove}>
                <IconClose isSmall />
            </div>
        </div>
    );
};

export default TagItem;
