import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDStatusBar from 'app/components/status/statusbar';
import { REPORT_TYPE } from 'app/const/Reports';
import { GET_CHATBOT_REPORT } from 'app/const/api/V2';
import { CHATBOT_REPORT_FILTER } from 'app/const/report/ReportFilter';
import { showStatusBar } from 'common/utils/FunctionUtils';
import TableChatbot from '../components/TableChatbot';
import { TYPE_DATA_CHATBOT } from '../constants';
import { getColumnReportAI } from '../constants/getColumnReportAI';
import SummaryConversation from './SummaryConversation';

const ChatbotReport = () => {
    const { t } = useTranslation('addons');
    const refSummary = useRef(null);

    const typeReport = REPORT_TYPE.CHATBOT_REPORT;

    const refAlert = useRef(null);
    const refTable = useRef(null);
    const refRowSelected = useRef({});

    const _handleGetData = () => {
        refTable.current?.getData();
    };

    const _handleClickRow = ({ key, row = {} } = {}) => {
        if (key !== 'summary') return;
        refRowSelected.current = row;
        refSummary.current.open(row);
    };

    const _handleShowAlert = (alert = {}) => {
        showStatusBar({ id: 'chat_bot_report', ...alert, refAlert });
    };

    const _handleUpdateData = (value) => {
        refTable.current?.updateDataByType({
            type: TYPE_DATA_CHATBOT.UPDATE,
            data: { ...refRowSelected.current, resolved: value }
        });
    };

    return (
        <div className="wrapper-chatbot flex-column js-contents-content">
            <div className="flex-betweenitems">
                <h3 className="wrapper-chatbot__title fs-600 fs-18 black">{t('report')}</h3>
            </div>
            <GDStatusBar ref={refAlert} />
            <div className="container-print container-wrap">
                <TableChatbot
                    ref={refTable}
                    reportType={typeReport}
                    endpoint={GET_CHATBOT_REPORT}
                    gridColumnReport={getColumnReportAI}
                    isShowDateRange
                    isShowCheckbox={false}
                    filters={CHATBOT_REPORT_FILTER}
                    onClickRow={_handleClickRow}
                    onGetData={_handleGetData}
                    onShowAlert={_handleShowAlert}
                />
            </div>
            <SummaryConversation ref={refSummary} onUpdateData={_handleUpdateData} />
        </div>
    );
};
export default ChatbotReport;
