import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { LIST_CURRENCY } from 'app/const/Settings';
import classNames from 'classnames';
import React, { useEffect, useReducer, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import { reducer } from 'app/const/Reducer';

const PlanInputPrice = forwardRef(({ id = 'plan-input-price' }, ref) => {
    const { typePlanSelect, selectedPlans } = useContext(SubscriptionContext);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        selected: LIST_CURRENCY.find((item) => item.value === typePlanSelect.currency)
    });
    const refInput = useRef(null);
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        _getValue: () => ({
            amount: parseFloat(refInput.current.value) || 0,
            currency: state.selected.value || LIST_CURRENCY[0]['value']
        })
    }));

    const isVisible = state.isVisible;

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(id);
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        isVisible && dispatchState({ isVisible: false });
    }

    function _openDropdown() {
        dispatchState({ isVisible: !isVisible });
    }

    function _selectOption(selected) {
        dispatchState({ selected });
        _closeDropdown();
    }

    const _renderListOptions = () => {
        return LIST_CURRENCY.map((item) => (
            <li
                key={item.id}
                className={classNames('items', { active: item.id === state.selected.id })}
                onClick={() => _selectOption(item)}
            >
                <div className="txt-ellipsis">{item.value}</div>
            </li>
        ));
    };

    return (
        <div ref={refDropdown} className={classNames('v2-dropdown cost-input', { active: isVisible })}>
            <input ref={refInput} className="field-input" type="number" placeholder={0} />
            <div
                className={classNames('dropbtn field-amount', { 'is-disable': selectedPlans.length })}
                onClick={_openDropdown}
            >
                {state.selected.value}
            </div>
            <div id={id} className="v2-dropdown__menu scrolls">
                <ul>{_renderListOptions()}</ul>
            </div>
        </div>
    );
});

export default PlanInputPrice;
