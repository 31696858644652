export const COMMISSION_ACTIONS = {
    GET_COMMISSION_ITEMS: 'GET_COMMISSION_ITEMS',
    GET_COMMISSION_ITEMS_SUCCESS: 'GET_COMMISSION_ITEMS_SUCCESS',
    GET_COMMISSION_USERS: 'GET_COMMISSION_USERS',
    GET_COMMISSION_USERS_SUCCESS: 'GET_COMMISSION_USERS_SUCCESS',
    RESET_COMMISSION_DATA: 'RESET_COMMISSION_DATA'
};

export const getCommissionItems = (payload) => {
    return {
        type: COMMISSION_ACTIONS.GET_COMMISSION_ITEMS,
        payload
    };
};

export const getCommissionUsers = (payload) => {
    return {
        type: COMMISSION_ACTIONS.GET_COMMISSION_USERS,
        payload
    };
};

export const resetCommissionData = () => {
    return {
        type: COMMISSION_ACTIONS.RESET_COMMISSION_DATA
    };
};
