import classNames from 'classnames';
import React, { Fragment, forwardRef, useContext, useImperativeHandle } from 'react';

import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import EstimateTemplate from '../../addestimate/form/template';
import { CLASS_NAME_OPEN_LOCATION } from 'app/components/dropdown/constant';
import { eventLocationDropdown, getQueryLocationMap } from 'app/modules/customer/detail/locations/utils';

const InvoiceLocation = forwardRef(({ locationInfo, isInvoice }, ref) => {
    const { invoiceData } = useContext(AddInvoiceContext);
    const { deposit } = invoiceData;
    const isPayDeposit = !!deposit.invoices?.length;

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return invoiceData;
        }
    }));

    function _handleOpenLocation(target) {
        eventLocationDropdown({
            target,
            address: getQueryLocationMap(locationInfo),
            dataLatLng: { ...(locationInfo || {}) }
        });
    }

    return (
        <div className="customer-info-number has-customer-name">
            <div className="name txt-ellipsis">{locationInfo.name}</div>

            <div
                onClick={_handleOpenLocation}
                className={classNames('detail word-break cursor-pointer', CLASS_NAME_OPEN_LOCATION)}
            >
                {locationInfo && (
                    <Fragment>
                        <p>{locationInfo.street1}</p>
                        <p>{locationInfo.street2}</p>
                        <p>
                            {locationInfo.city}, {locationInfo.state} {locationInfo.zip}
                        </p>
                    </Fragment>
                )}
            </div>
            {!isInvoice && <EstimateTemplate isDisable={isPayDeposit} />}
        </div>
    );
});
export default InvoiceLocation;
