import React from 'react';
import { useSettingTemplate } from '../SettingTemplateContext';
import BoxContentSchedule from './BoxContentSchedule';
import BoxEmailContent from './BoxEmailContent';
import BoxServiceContent from './BoxServiceContent';
import BoxSMSContent from './BoxSMSContent';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { useRef } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

const BodyTemplate = (props, ref) => {
    const { isDisplaySMS, isDisplaySchedule, isDisplayService, template } = useSettingTemplate();
    const refAlert = useRef(null);

    useImperativeHandle(ref, () => ({
        displayAlert: (dataAlert) => {
            refAlert.current && refAlert.current.showStatusBar(dataAlert);
        }
    }));

    if (!template?.reminders?.length) return null;
    return (
        <div className="wrap-body">
            <AlertCustomer ref={refAlert} />
            {!!isDisplaySchedule ? <BoxContentSchedule /> : null}
            <BoxEmailContent />
            {!!isDisplaySMS ? <BoxSMSContent /> : null}
            {!!isDisplayService ? <BoxServiceContent idTemplate={parseInt(template.id)} /> : null}
        </div>
    );
};

BodyTemplate.displayName = 'BodyTemplate';
export default forwardRef(BodyTemplate);
