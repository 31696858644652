import IconPhone from 'assets/icon/IconPhone';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { CallNoteForm } from '.';
import { reducer } from 'app/const/Reducer';
import { CustomerDetailContext } from '../detail/context/CustomerDetailContext';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_ADD_CALL_LOG } from 'app/const/api/Voip';
import { LIST_STATUS } from 'app/const/App';

const AddCallLog = forwardRef(({ customerDetail, onAddNew }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        selected: '',
        isVisible: false
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refNoteContent = useRef(null);
    const refDuration = useRef(null);

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide
    }));

    const { selected: finalSelected, isVisible: finalIsVisible } = state;

    function _handleToggleShowHide() {
        dispatchState({ isVisible: !finalIsVisible });
    }

    const _handleSelect = (phoneSelected) => {
        dispatchState({
            selected: phoneSelected
        });
    };

    const _handleSave = () => {
        if (!finalSelected) {
            _handleShowStatus('Please select customer phone.', LIST_STATUS.ERROR);
            _removeLoading();
            return false;
        }

        const duration = refDuration.current.value || 0;

        clientQuery(
            ACTION_ADD_CALL_LOG,
            {
                method: 'POST',
                data: {
                    customer_id: customerDetail.id,
                    receiver: finalSelected,
                    duration: parseFloat(duration) * 60,
                    note: refNoteContent.current.value
                }
            },
            _handleAddNoteSuccess,
            _handleAddNoteFailed
        );
    };

    const _handleShowStatus = (message, type) => {
        refStatusBar.current.showStatusBar(Date.now(), message, type);
    };

    const _handleAddNoteSuccess = (response) => {
        onAddNew(response.data);
        _removeLoading();
        dispatchState({
            selected: '',
            isVisible: false
        });
    };

    const _handleAddNoteFailed = (response) => {
        _removeLoading();
        refStatusBar.current.showStatusBar(Date.now(), response.message?.toString(), LIST_STATUS.ERROR);
    };

    const _removeLoading = () => {
        refButtonSave.current.removeLoading();
    };

    if (!finalIsVisible) {
        return false;
    }

    return (
        <div className="boxs boxs-calls --logs">
            <div className="left-icons">
                <IconPhone isGreen />
            </div>
            <div className="boxs-calls__inner">
                <StatusBar ref={refStatusBar} />

                <div className="header betweenitems">
                    <CallNoteForm isNew onSelect={_handleSelect} finalSelected={finalSelected} />
                    <input ref={refDuration} className="field-input" type="number" placeholder="Minutes" />
                </div>

                <div className="notes-calls">
                    <textarea
                        className="field-textarea --no-border --h-120 d-flex"
                        placeholder="Add a note about this call"
                        defaultValue={''}
                        autoFocus
                        ref={refNoteContent}
                    />
                    <div className="footer --align-right">
                        <div onClick={_handleToggleShowHide} className="v2-btn-default --transparent">
                            Cancel
                        </div>
                        <ButtonSave ref={refButtonSave} title="Save" onSave={_handleSave} />
                    </div>
                </div>
            </div>
            {!finalSelected && <ServicePhone finalSelected={finalSelected} onSelect={_handleSelect} />}
        </div>
    );
});

const ServicePhone = ({ onSelect }) => {
    const {
        customer: { phones = [] },
        contacts = []
    } = useContext(CustomerDetailContext);

    useEffect(() => {
        const findCustomerPhone = phones.find((item) => !!item.phone);

        if (!findCustomerPhone) {
            const finalContacts = contacts || [];

            const find = () => {
                for (const item of finalContacts) {
                    const result = item.phones.find((item) => !!item.phone);
                    if (result)
                        return {
                            ...result,
                            name: item.customer.first_name.concat(item.customer.last_name)
                        };
                }
            };
            onSelect(find()?.phone);
        } else {
            onSelect(findCustomerPhone?.phone);
        }
    }, [phones, contacts]);
};

export default AddCallLog;
