import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { formatTimeUnix } from 'common/utils/TimeUtils';

const RowTotal = forwardRef(({ total: defaultTotal = { amount: 0, duration: 0 } }, ref) => {
    const { t } = useTranslation();
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const [state, dispatchState] = useReducer(reducer, { total: defaultTotal });
    const { total } = state;

    useImperativeHandle(ref, () => ({
        setTotal: (total) => {
            dispatchState((prevState) => ({ ...prevState, total }));
        }
    }));

    return (
        <div className="rows --total">
            <div className="col col-date">
                <p className="col-label">{t('total')}:</p>
            </div>
            <div className="col col-clock"></div>
            <div className="col col-duration">
                <p className="col-label">{formatTimeUnix(total.duration || 0)}</p>
            </div>
            <div className="col-center"></div>
            <div className="col col-amount">
                <p className="col-label">{transformToCurrency(total.amount, currency)}</p>
            </div>
        </div>
    );
});

export default RowTotal;
