import classNames from 'classnames';
import React, { useContext } from 'react';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import InfoCard from './InfoCard';
import { useTranslation } from 'react-i18next';

const PaymentsDisplay = ({ isPaymentSideBar = false }) => {
    const { t } = useTranslation(['customers', 'common']);
    const { payments } = useContext(CustomerDetailContext);

    if (!payments.length) return null;

    return (
        <div className="rows has-divider has-card">
            {!isPaymentSideBar && <div className="rows__label">{t('customers:payment_method')}</div>}
            <div className="rows__info">
                {payments.map((item) => (
                    <div key={item.id} className="row-has-card mt-1">
                        <InfoCard isPaymentSideBar={isPaymentSideBar} {...item} />
                        {!isPaymentSideBar && (
                            <div className={classNames('set-card', { selected: item.is_default })}>
                                <div className="default-btn is-default">{t('common:default')}</div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PaymentsDisplay;
