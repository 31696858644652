import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function ServiceSms({ onOpenCustomerSms }) {
    const { detail_sms: detailSms } = useSelector(({ sms }) => sms);

    useEffect(() => {
        detailSms && onOpenCustomerSms(detailSms);
    }, [detailSms]);

    return false;
}
