import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import AddPaymentSquare from 'app/components/payment/AddPaymentSquare';
import AddPaymentStripe from 'app/components/payment/AddPaymentStripe';
import LinkStripeModal from 'app/components/payment/components/LinkStripeModal';
import { DELETE_CUSTOMER_STRIPE_ID } from 'app/const/api/V2';
import { METHOD_CONVERT } from 'app/const/App';
import {
    ADD_PAYMENT_IN_SQUARE,
    ADD_PAYMENT_IN_STRIPE,
    PAYMENT,
    PAYMENT_MERCHANT_KEYWORD
} from 'app/const/customer/CustomerPayment';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconPlus from 'assets/icon/IconPlus';
import IconSquare from 'assets/icon/IconSquare';
import IconStripe from 'assets/icon/IconStripe';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import CardPaymentInfo from './CardPaymentInfo';
import { MERCHANT_ID, STATUS_ACH } from 'app/const/Customers';
import EditCard from './EditCard';
import SquareDetailCard from './SquareDetailCard';
import PaymentACH from './paymentACH';

const AccountPayment = () => {
    const { t } = useTranslation();
    const { id: customerId } = useParams();

    const { STRIPE: typeStripe, SQUARE: typeSquare } = PAYMENT_MERCHANT_KEYWORD;

    const userCountry = useSelector(({ auth }) => auth.user.company.country);
    const { addons: addonsUser, square_countries } = useSelector(({ auth }) => auth.user.settings);
    const isCountryHasSquare = square_countries.includes(userCountry);
    const {
        customer,
        payments,
        contacts,
        isPaymentLoaded,
        _handleUpdateCustomer,
        _handleAddPayment,
        _handleDeletePayment,
        _handleDeleteAllStripe,
        _handleVerifyAchSuccess
    } = useContext(CustomerDetailContext);
    const { merchant_customer_id } = customer;
    const stripeId = !!merchant_customer_id && merchant_customer_id[typeStripe.toLowerCase()];
    const squareId = !!merchant_customer_id && merchant_customer_id[typeSquare.toLowerCase()];

    const [state, dispatchState] = useReducer(reducer, {
        paymentsStripe: [],
        paymentsSquare: [],
        paymentsBank: []
    });
    const refAddStripe = useRef(null);
    const refEditCardStripe = useRef(null);
    const refAddSquare = useRef(null);
    const refSquareCard = useRef(null);
    const refWarning = useRef(null);
    const refDataWarning = useRef({ typeWarning: null, dataWarning: null });
    const refButtonSave = useRef(null);
    const refDropdownStripe = useRef(null);
    const refDropdownSquare = useRef(null);
    const refFirstTime = useRef(true);

    const isDisplayStripe = !!addonsUser.stripe;
    const isDisplaySquare = !!addonsUser.square && isCountryHasSquare;
    const isDisplayACH = !!addonsUser.stripe.ach;
    const { paymentsStripe, paymentsSquare, paymentsBank } = state;

    useEffect(() => {
        refFirstTime.current = true;
    }, [customerId]);

    useEffect(() => {
        if (!refFirstTime.current || !isPaymentLoaded) return;

        const paymentsStripe = [];
        const paymentsSquare = [];
        const paymentsBank = [];
        payments.forEach((item) => {
            switch (item.name) {
                case typeStripe:
                    !item.isACH ? paymentsStripe.push(item) : paymentsBank.push(item);
                    break;
                case typeSquare:
                    paymentsSquare.push(item);
                    break;
            }
        });
        if (isPaymentLoaded) refFirstTime.current = false;
        dispatchState({
            paymentsStripe,
            paymentsSquare,
            paymentsBank
        });
    }, [payments]);

    /**
     * Takes in a type and then opens the appropriate modal based on the type
     * @param type - The type of payment method you want to add.
     */
    const _handleAdd = (type) => {
        switch (type) {
            case ADD_PAYMENT_IN_STRIPE:
                refAddStripe.current && refAddStripe.current._open();
                break;
            case ADD_PAYMENT_IN_SQUARE:
                refAddSquare.current && refAddSquare.current._open();
                break;
            default:
                break;
        }
    };

    /**
     * It updates the customer's account_customer_id in the database.
     * @param newCard - The new card object that was just added.
     */
    const _handleSubmit = (newCard) => {
        let finalNewCard = newCard;
        const isArray = Array.isArray(newCard);
        if (isArray) {
            finalNewCard = finalNewCard.map((item) => {
                const isACH = item.name === PAYMENT_MERCHANT_KEYWORD.BANK_ACCOUNT;
                if (isACH) {
                    return { ...item, name: PAYMENT_MERCHANT_KEYWORD.STRIPE, isACH };
                }
                return item;
            });
        }
        const itemCheck = isArray ? finalNewCard[0] : finalNewCard;

        const newCustomer = {};
        if ((!stripeId && itemCheck.name === typeStripe) || (!squareId && itemCheck.name === typeSquare)) {
            newCustomer.merchant_customer_id = {
                ...merchant_customer_id,
                [itemCheck.name.toLowerCase()]: itemCheck.account_customer_id
            };
        }

        !!Object.keys(newCustomer).length && _handleUpdateCustomer(newCustomer);
        _handleAddPayment(finalNewCard);
        handleAddCard(finalNewCard);
    };

    const _handleDeleteStripeId = (type, id) => {
        const _handleDeleteSuccess = () => {
            const newCustomer = {
                merchant_customer_id: {
                    ...merchant_customer_id,
                    [type.toLowerCase()]: false
                }
            };
            _handleUpdateCustomer(newCustomer);
            _handleDeleteAllStripe(type);
            _handleCloseWarning();
        };
        clientQuery(
            DELETE_CUSTOMER_STRIPE_ID,
            {
                data: { account_customer_id: id, merchant: MERCHANT_ID[type] },
                method: 'DELETE'
            },
            _handleDeleteSuccess,
            _handleCloseWarning
        );
    };
    const _handleWarning = (type, data = null) => {
        switch (type) {
            case PAYMENT:
                const isACH = data.isACH;
                refWarning.current._open({
                    title: t(`customers:${isACH ? 'remove_this_bank_account' : 'remove_card_confirm'}`, {
                        keyword: data.name
                    }),
                    description: t(
                        `customers:${isACH ? 'confirm_delete_bank_account' : 'confirm_delete_card_number'}`,
                        {
                            account_number: `${METHOD_CONVERT} ${data.account_number}`
                        }
                    ),
                    isLargeContent: false
                });
                break;
            case 'stripe_id':
                refWarning.current._open({
                    title: t('customers:confirm_delete_stripe_id'),
                    description: t('customers:confirm_delete_stripe_id_desc'),
                    isLargeContent: true
                });
                break;
            case 'square_id':
                refWarning.current._open({
                    title: t('customers:confirm_delete_square_id'),
                    description: t('customers:confirm_delete_square_id_desc'),
                    isLargeContent: true
                });
                break;
            default:
                break;
        }

        refDataWarning.current = { typeWarning: type, dataWarning: data };
    };

    const _handleConfirmWarning = () => {
        const { typeWarning, dataWarning } = refDataWarning.current;
        switch (typeWarning) {
            case PAYMENT:
                if (dataWarning) {
                    _handleDeletePayment(
                        dataWarning,
                        () => {
                            refWarning.current?._close();
                            handleDeleteCard({ type: PAYMENT, data: dataWarning });
                        },
                        ({ message }) => {
                            refWarning.current?._setStatusAlert(message || t('common:something_went_wrong'));
                            refButtonSave.current?.removeLoading();
                        },
                        () => {
                            refWarning.current?._clearStatusAlert();
                        }
                    );
                }
                break;
            case 'stripe_id':
                _handleDeleteStripeId(typeStripe, stripeId);
                break;
            case 'square_id':
                _handleDeleteStripeId(typeSquare, squareId);
                break;
            default:
                break;
        }
        if (typeWarning !== PAYMENT) {
            handleDeleteCard({ type: typeWarning, data: dataWarning });
        }
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
        refDataWarning.current = null;
    };

    const renderOption = (name) => {
        let options = [];
        switch (name) {
            case typeStripe:
                options = [
                    {
                        key: 'stripe_delete',
                        text: t('customers:delete_id'),
                        callback: () => {
                            _handleWarning('stripe_id');
                            refDropdownStripe.current._closeDropdown();
                        }
                    }
                ];
                break;
            case typeSquare:
                options = [
                    {
                        key: 'square_delete',
                        text: t('customers:delete_id'),
                        callback: () => {
                            _handleWarning('square_id');
                            refDropdownSquare.current._closeDropdown();
                        }
                    }
                ];
                break;
            default:
                return null;
        }

        return (
            <ul>
                {options.map((item) => (
                    <li key={item.key} className={'items fs-13 fw-500'} onClick={item.callback}>
                        {item.text}
                    </li>
                ))}
            </ul>
        );
    };

    const handleDeleteCard = ({ type, data = {} }) => {
        switch (type) {
            case PAYMENT:
                const { isACH, name, account_id } = data;
                const paymentsName = `payments${isACH ? PAYMENT_MERCHANT_KEYWORD.BANK : name}`;
                dispatchState((prev) => ({
                    ...prev,
                    [paymentsName]: prev[paymentsName].filter((payment) => payment.account_id !== account_id)
                }));
                break;
            case 'stripe_id':
                dispatchState((prev) => ({
                    ...prev,
                    ['paymentsStripe']: [],
                    ['paymentsBank']: []
                }));
                break;
            case 'square_id':
                dispatchState((prev) => ({
                    ...prev,
                    ['paymentsSquare']: []
                }));
                break;
        }
    };

    const handleAddCard = (newCard) => {
        const isArray = Array.isArray(newCard);

        dispatchState((prev) => {
            const newData = {};
            if (!isArray) {
                const paymentsName = `payments${newCard.isACH ? PAYMENT_MERCHANT_KEYWORD.BANK : newCard.name}`;
                newData[paymentsName] = [...prev[paymentsName], newCard];
            } else {
                newCard.forEach((item) => {
                    const paymentsName = `payments${item.isACH ? PAYMENT_MERCHANT_KEYWORD.BANK : item.name}`;
                    newData[paymentsName] = [...prev[paymentsName], ...(newData[paymentsName] || []), item];
                });
            }
            return { ...prev, ...newData };
        });
    };

    function _handleEditStripe(dataCard) {
        if (refEditCardStripe.current) refEditCardStripe.current._handleOpen(dataCard);
    }

    function _handleUpdateStripeSuccess(dataCard) {
        if (dataCard) return;
        dispatchState((prevState) => {
            return {
                ...prevState,
                paymentsStripe: paymentsStripe.map((item) => (item.id === dataCard.id ? dataCard : item))
            };
        });
    }

    function _handleOpenSquare(dataCard) {
        if (refSquareCard.current) refSquareCard.current._handleOpen(dataCard);
    }

    const _handleVerifyCardSuccess = ({ id }) => {
        dispatchState((prev) => ({
            ...prev,
            paymentsBank: prev.paymentsBank.map((item) => ({
                ...item,
                status: item.id === id ? STATUS_ACH.VERIFY : item.status
            }))
        }));
        _handleVerifyAchSuccess({ id });
    };

    return (
        <Fragment>
            <div className="page-wrapper flex-auto form-method form-inner">
                <div className="head-main flex-betweenitems">
                    <div className="title-font15">{t('customers:payment_method')}</div>
                </div>

                {isDisplayStripe && (
                    <Fragment>
                        {!!stripeId ? (
                            <EditCard ref={refEditCardStripe} onUpdateSuccess={_handleUpdateStripeSuccess} />
                        ) : null}
                        <div className="rows mt-0">
                            <div className="method-header w-100 flex-betweenitems">
                                <div className="method-info flexcenter">
                                    <span className="method-info__icon">
                                        <IconStripe hasColor />
                                    </span>
                                    <p className="method-info__name flex-column">
                                        <b className="black-eerie-dark">{t('customers:stripe')}</b>
                                        <span className="fs-10 grey-generic dp-block txt-ellipsis">{stripeId}</span>
                                    </p>
                                </div>
                                {!!stripeId ? (
                                    <CalendarDropdown
                                        id="dropdown_stripe"
                                        ref={refDropdownStripe}
                                        buttonClassName="dropbtn v2-btn-default --icon-lg --transparent"
                                        wrapperListClass="v2-dropdown__menu"
                                        wrapperClassName="v2-dropdown v2-dropdown--more d-flex"
                                        customDropButton={() => <IconThreeDots />}
                                    >
                                        {renderOption(typeStripe)}
                                    </CalendarDropdown>
                                ) : (
                                    <LinkStripeModal
                                        classWrapper=""
                                        classButton="purple-default fw-600 flex-auto btn-modal cursor-pointer"
                                        textButton={t('customers:connect_existing_stripe')}
                                        customer={customer}
                                        contacts={contacts ? contacts.filter((contact) => +contact?.id !== 0) : []}
                                        stripeId={stripeId}
                                        type={typeStripe}
                                        onConnectSuccess={_handleSubmit}
                                    />
                                )}
                            </div>
                            <CardPaymentInfo
                                data={paymentsStripe}
                                onEdit={_handleEditStripe}
                                onWarning={_handleWarning}
                                classWrapper="method-items w-100 flex-betweenitems"
                                isShowDelete={false}
                                isShowMore
                            />
                            <div
                                className="v2-btn-main has-icon --bg-green svg-white btn-modal fs-14"
                                onClick={() => _handleAdd(ADD_PAYMENT_IN_STRIPE)}
                            >
                                <IconPlus />
                                {t('customers:credit_card_in_stripe')}
                            </div>
                        </div>
                        <AddPaymentStripe
                            ref={refAddStripe}
                            customerId={customerId}
                            customerInfo={customer}
                            stripeId={stripeId}
                            onSubmit={_handleSubmit}
                        />
                    </Fragment>
                )}

                {isDisplaySquare && (
                    <Fragment>
                        {!!squareId ? <SquareDetailCard ref={refSquareCard} /> : null}
                        <div className="rows mt-0">
                            <div className="method-header w-100 flex-betweenitems">
                                <div className="method-info flexcenter">
                                    <span className="method-info__icon">
                                        <IconSquare hasColor />
                                    </span>
                                    <p className="method-info__name flex-column">
                                        <b className="black-eerie-dark">{t('customers:square')}</b>
                                        <span className="fs-10 grey-generic dp-block txt-ellipsis">{squareId}</span>
                                    </p>
                                </div>
                                {!!squareId ? (
                                    <CalendarDropdown
                                        id="dropdown_stripe"
                                        ref={refDropdownSquare}
                                        buttonClassName="dropbtn v2-btn-default --icon-lg --transparent"
                                        wrapperListClass="v2-dropdown__menu"
                                        wrapperClassName="v2-dropdown v2-dropdown--more d-flex"
                                        customDropButton={() => <IconThreeDots />}
                                    >
                                        {renderOption(typeSquare)}
                                    </CalendarDropdown>
                                ) : (
                                    <LinkStripeModal
                                        classWrapper=""
                                        classButton="purple-default fw-600 flex-auto btn-modal cursor-pointer"
                                        textButton={t('customers:connect_existing_square')}
                                        contacts={contacts ? contacts.filter((contact) => +contact?.id !== 0) : []}
                                        customer={customer}
                                        stripeId={squareId}
                                        type={typeSquare}
                                        onConnectSuccess={_handleSubmit}
                                    />
                                )}
                            </div>
                            <CardPaymentInfo
                                data={paymentsSquare}
                                onWarning={_handleWarning}
                                onEdit={_handleOpenSquare}
                                classWrapper="method-items w-100 flex-betweenitems"
                                isShowDelete={false}
                                isShowMore
                            />

                            <div
                                className="v2-btn-main has-icon --bg-green svg-white btn-modal fs-14"
                                onClick={() => _handleAdd(ADD_PAYMENT_IN_SQUARE)}
                            >
                                <IconPlus />
                                {t('customers:credit_card_in_square')}
                            </div>
                        </div>
                        <AddPaymentSquare ref={refAddSquare} customerId={customerId} onSubmit={_handleSubmit} />
                    </Fragment>
                )}

                {isDisplayACH && (
                    <PaymentACH
                        data={paymentsBank}
                        onWarning={_handleWarning}
                        onAddSuccess={_handleSubmit}
                        onVerifySuccess={_handleVerifyCardSuccess}
                    />
                )}
            </div>
            <GDModalWarning
                ref={refWarning}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('yes')}
                            className="v2-btn-main"
                            onSave={_handleConfirmWarning}
                        />
                    </div>
                }
            />
        </Fragment>
    );
};

export default AccountPayment;
