import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { LIST_STATUS } from 'app/const/Status';
import AddSubscriptionModal from 'app/modules/subscription/add';
import SubscriptionTable from 'app/modules/subscription/CustomerSubscriptionTable';
import IconPlus from 'assets/icon/IconPlus';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';

const CustomerSubscription = ({ type = PAYMENT_CARD_CUSTOMER.STRIPE, onShowAlert = () => {} }) => {
    const { t } = useTranslation('customers');
    const { customer, reloadCustomerPage } = useContext(CustomerDetailContext);
    const { stripe, square } = useSelector(({ auth }) => auth.user.settings.addons);
    const refAddSubscription = useRef(null);
    const refTableSubscription = useRef(null);

    const _addSubscription = () => {
        refAddSubscription.current._open();
    };

    const _handleAddSubscriptionSuccess = (newSubscription, message) => {
        refTableSubscription.current._add(newSubscription);
        refAddSubscription.current._close();
        onShowAlert(LIST_STATUS.SUCCESS, {
            id: LIST_STATUS.SUCCESS,
            message,
            type: LIST_STATUS.SUCCESS
        });
    };

    if (
        type === PAYMENT_CARD_CUSTOMER.SQUARE &&
        (!square || !square.square_subscription || !customer?.merchant_customer_id?.square)
    )
        return null;

    if (
        [PAYMENT_CARD_CUSTOMER.STRIPE, PAYMENT_CARD_CUSTOMER.ACH].includes(type) &&
        (!stripe || !stripe.subscription || !customer?.merchant_customer_id?.stripe || !customer?.payment_card)
    )
        return null;

    return (
        <div className="tab-content__list wrap-tables">
            <div className="title-header --subs">
                <p className="title-header__decs mr-3">
                    {t(`${type}_id`)}: {customer.merchant_customer_id?.[type]}
                </p>
                <div className="v2-btn-default has-icon btn-bg-purple --sm svg-purple-fill" onClick={_addSubscription}>
                    <IconPlus />
                    {t('add_subscription')}
                </div>
            </div>
            <AddSubscriptionModal
                typeSubscription={type}
                ref={refAddSubscription}
                customer={customer}
                onAddSuccess={_handleAddSubscriptionSuccess}
            />
            <SubscriptionTable
                typeSubscription={type}
                ref={refTableSubscription}
                customer={customer}
                onShowAlert={onShowAlert}
                reloadCustomerPage={reloadCustomerPage}
            />
        </div>
    );
};

export default CustomerSubscription;
