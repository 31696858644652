import React, { useEffect, useRef, useReducer, Fragment } from 'react';
import { getListPaymentMethod } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import classNames from 'classnames';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { COMMON, MERCHANT_METHOD } from 'app/const/App';
import { useTranslation } from 'react-i18next';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { onKeyDownOnlyNumber } from 'common/utils/NumberUtils';

const CreditPaymentMethod = ({
    defaultMethod,
    defaultMemo,
    defaultCheckNumber,
    defaultCheckValue,
    isOpen,
    onSelect = () => {},
    onChange = () => {},
    disable
}) => {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        paymentSelected: defaultMethod,
        methods: []
    });
    const { t } = useTranslation(['customers']);
    const isStripe = defaultMethod?.id.includes(PAYMENT_CARD_CUSTOMER.STRIPE);
    const isSquare = defaultMethod?.id.includes(PAYMENT_CARD_CUSTOMER.SQUARE);
    const isACH = defaultMethod?.id.includes(PAYMENT_CARD_CUSTOMER.ACH.replace('_', ''));

    const refDropdown = useRef(null);

    useEffect(() => {
        if (isOpen) {
            clientQuery(
                getListPaymentMethod,
                {
                    data: { merchant_method: MERCHANT_METHOD },
                    method: 'GET'
                },
                _getListPaymentMethodSuccess
            );
        }
    }, [isOpen]);

    const _getListPaymentMethodSuccess = ({ data }) => {
        dispatchState({
            isLoading: false,
            methods: data.some((item) => item.id === defaultMethod.id) ? data : [...data, defaultMethod]
        });
    };

    const _handleSelect = (e, item, isActive) => {
        onSelect(item);
        e.stopPropagation();
        if (isActive) return false;

        dispatchState({
            paymentSelected: item
        });
        refDropdown.current._closeDropdown();
    };

    function _renderOptions() {
        return state.methods.map((item) => {
            const paymentId = item.id;
            const isActive = paymentId === defaultMethod.id;
            return (
                <li
                    key={paymentId}
                    onClick={(e) => _handleSelect(e, item, isActive)}
                    className={classNames('items', { active: isActive })}
                >
                    <p className="txt-ellipsis" onSelect={(value) => onSelect(value)}>
                        {item.name}
                    </p>
                </li>
            );
        });
    }

    const _handleOnKeyDownNumber = (event) => {
        const finalKey = event.key;
        const currentValue = event.target.value;

        if (finalKey === '-' && currentValue !== '') {
            const convertToNegative = Number(`-${currentValue}`);
            event.target.value = isNaN(convertToNegative) ? 0 : convertToNegative;
            event.preventDefault();
            onChange(event, COMMON.CHECK_VALUE);
        }

        const isKeyAccept =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !isKeyAccept && event.preventDefault();
    };

    function _renderValuePayment() {
        switch (defaultMethod.id) {
            case PAYMENT_METHODS.CHECK:
                return (
                    <Fragment>
                        <div className="txt">{t('customers:check_number')}</div>
                        <input
                            onChange={(e) => onChange(e, COMMON.CHECK_NUMBER)}
                            type="text"
                            className="field-input"
                            value={defaultCheckNumber || ''}
                            placeholder={t('customers:check_number')}
                        />
                        <input
                            type="number"
                            className="field-input mt-1"
                            value={defaultCheckValue}
                            onKeyDown={_handleOnKeyDownNumber}
                            placeholder={'Check value'}
                            onChange={(e) => onChange(e, COMMON.CHECK_VALUE)}
                        />
                    </Fragment>
                );
            case PAYMENT_METHODS.CREDIT:
                return null;
            default:
                return (
                    <>
                        <div className="txt">{t('customers:memo')}</div>
                        <input
                            onChange={(e) => onChange(e, COMMON.MEMO)}
                            type="text"
                            className="field-input"
                            value={defaultMemo || ''}
                            placeholder={t('customers:memo')}
                        />
                    </>
                );
        }
    }

    function _renderValuePaymentCard() {
        return null;
    }

    return (
        <div className="rows mt-3">
            <div className="width-xs">
                <div className="txt">{t('customers:method')}</div>
                <CalendarDropdown
                    disable={disable}
                    ref={refDropdown}
                    id="method_select_edit"
                    wrapperClassName="v2-dropdown"
                    buttonClassName="dropbtn items selection text-capitalize border-right-grey-soft"
                    selected={defaultMethod.name}
                >
                    <ul>{_renderOptions()}</ul>
                </CalendarDropdown>
            </div>
            <div className="width-xs">
                {isSquare || isStripe || isACH ? _renderValuePaymentCard() : _renderValuePayment()}
            </div>
        </div>
    );
};

export default CreditPaymentMethod;
