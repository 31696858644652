import GDInputNumber from 'app/components/input/InputNumber';
import React from 'react';

const DurationCondition = ({ value = {}, onChange = () => {} }) => {
    const _handleChange = (number, name) => {
        const offset = { ...value, [name]: Number(number) ?? undefined };
        onChange({ durationValue: Object.values(offset).every((number) => number === undefined) ? {} : offset });
    };

    return (
        <div className="field wrap-time">
            <GDInputNumber
                autoFocus
                className="field-input"
                type="text"
                name="hours"
                placeholder="hours"
                defaultValue={value?.hours || ''}
                onChange={_handleChange}
            />
            :
            <GDInputNumber
                className="field-input"
                type="text"
                name="minutes"
                placeholder="minutes"
                defaultValue={value?.minutes || ''}
                onChange={_handleChange}
            />
            :
            <GDInputNumber
                className="field-input"
                type="text"
                name="seconds"
                placeholder="seconds"
                defaultValue={value?.seconds || ''}
                onChange={_handleChange}
            />
        </div>
    );
};

export default DurationCondition;
