import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { updateOrderStatusOnTable } from 'app/modules/settings/utils/statusOrder.js';

import {
    LIST_TAB_TAGS,
    LIST_BUTTON_ACTION_TAGS,
    LIST_BUTTON_MESSAGES_TAGS,
    LIST_ACTION_TAGS
} from 'app/const/Settings';

const TAB = {
    LIST: LIST_TAB_TAGS,
    DEFAULT_SELECTED: LIST_ACTION_TAGS.CUSTOMER
};

const LIST_BUTTONS = LIST_BUTTON_ACTION_TAGS;

const LIST_BUTTON_MESSAGES = LIST_BUTTON_MESSAGES_TAGS;

const TABLE = {
    header: {
        name: {
            title: 'tags',
            style: 'col --lg js-order-by flex-1',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        type: {
            title: 'type',
            style: 'col --lg'
        }
    },
    columns: [
        {
            id: 'name',
            status: true
        },
        {
            id: 'type',
            status: true
        }
    ],
    contentConfig: {
        name: {
            id: 'name',
            style: 'col --lg --name flex-1',
            styleText: 'col-label btn-modal',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        type: {
            id: 'type',
            style: 'col --lg',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        }
    }
};

const getColumns = ({ actionStatus }) => {
    const newColumns = TABLE;
    if (actionStatus) {
        newColumns.header = updateOrderStatusOnTable({ headerTable: newColumns.header, actionStatus: actionStatus });
    }
    return newColumns;
};

export { TAB, LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
