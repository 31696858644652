export const TYPE_REPORT_SERVICE_LOOKUP = {
    GET_LIST_REPORT_SERVICE_LOOKUP_REQUEST: 'GET_LIST_REPORT_SERVICE_LOOKUP_REQUEST',
    GET_LIST_SCHEDULE_SERVICE_LOOKUP_REQUEST: 'GET_LIST_SCHEDULE_SERVICE_LOOKUP_REQUEST',
    GET_LIST_SERVICE_SERVICE_LOOKUP_REQUEST: 'GET_LIST_SERVICE_SERVICE_LOOKUP_REQUEST'
};

// actions
export const getListReportServiceLookupRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_REPORT_SERVICE_LOOKUP_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
export const getListScheduleServiceLookupRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_SCHEDULE_SERVICE_LOOKUP_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
export const getListServiceTypeRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SERVICE_LOOKUP.GET_LIST_SERVICE_SERVICE_LOOKUP_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
