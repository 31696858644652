import React from 'react';

import { CUSTOMER_STATUSES } from 'app/const/Customers';
import IconClose from 'assets/icon/IconClose';
import { OPERATOR_TYPES } from '../../constants/types';

const ItemFilteredStatus = ({
    statuses = CUSTOMER_STATUSES,
    filter,
    field,
    onUpdate = () => {},
    onRemove = () => {}
}) => {
    const { values } = filter?.value?.set || {};
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    const { label, prefixLabel } = field || {};
    let currentStatus;
    if (values && values.length === 1)
        currentStatus = statuses.find((status) => status?.value?.toString() === values?.[0]?.toString());

    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">
                    {prefixLabel ? `${prefixLabel} ` : null}
                    {label}:
                </span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{prefixToken}</span> : null}
                {values && values.length > 1 ? <span className="budget --black">{values.length}</span> : null}
                {currentStatus && values.length === 1 ? (
                    <span className="fw-600 ml-1">{currentStatus.label || currentStatus.name || values[0]}</span>
                ) : null}
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

ItemFilteredStatus.displayName = 'ItemFilteredStatus';
export default ItemFilteredStatus;
