import { getUrlSettingUsersWithId, SETTING_USERS } from 'app/const/Api';
import { ACCOUNT_ROLE } from 'app/const/App';
import { checkCrewUser } from 'app/modules/settings/utils/userCompany';
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListUsersSettings({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTING_USERS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getUserDetail({ params, actionSuccess, actionFailed }) {
    const URL = getUrlSettingUsersWithId(params.id);
    try {
        const response = yield call(fetchWithToken, URL, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createUserSetting({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTING_USERS, params);
        if (response && response.success) {
            response.data.user = {
                id: response.data.id,
                first_name: params.first_name,
                last_name: params.last_name,
                full_name: `${params.first_name} ${params.last_name}`,
                avatar: params.avatar_url
            };
            response.data.is_owner = 0;
            response.data.role = params.is_admin ? 'Admin' : 'Technician';
            response.data.last_visit = 'Never';
            response.schedules = [];
            if (checkCrewUser(params.type)) {
                response.data.user.groups = params.tags;
            }
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateUserSetting({ params, actionSuccess, actionFailed }) {
    try {
        const isOwner = params.is_owner;
        const response = yield call(putWithToken, getUrlSettingUsersWithId(params.id), params);
        if (response && response.success) {
            const userUpdated = { ...response.data };
            userUpdated.user = {
                id: params.id,
                first_name: params.first_name,
                last_name: params.last_name,
                full_name: `${params.first_name} ${params.last_name}`,
                avatar: params.avatar_url,
                email: params.email
            };
            if (!isOwner) {
                userUpdated.role = params.is_admin ? ACCOUNT_ROLE.ADMIN : ACCOUNT_ROLE.TECHNICIAN;
            } else {
                userUpdated.role = ACCOUNT_ROLE.SUPERADMIN_SETTING;
            }
            userUpdated.is_owner = isOwner;
            if (checkCrewUser(params.type)) {
                userUpdated.user.groups = params.tags;
            }
            actionSuccess({ ...response, data: userUpdated });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteUserSettings({ params, actionSuccess, actionFailed }) {
    const URL = getUrlSettingUsersWithId(params.id);
    try {
        const response = yield call(deleteWithToken, URL);
        if (response && response.success) {
            response.id = params.id;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
