import React from 'react';
import IconMicrosoft from 'assets/icon/IconMicrosoft';
import { MS_LOGIN_URL } from 'app/const/Keys';
import { useSelector } from 'react-redux';
import { setBranchLocalStorage } from 'app/const/Branch';

const ConnectWithMS = ({ onClick = () => {} }) => {
    const MS_CLIENT_ID = useSelector(({ auth }) => auth.user.settings.microsoft.client_id);
    const branchId = useSelector(({ auth }) => auth.user.company.branch.id);

    const _handleClick = () => {
        setBranchLocalStorage(branchId);
        location.href = MS_LOGIN_URL(MS_CLIENT_ID);
        onClick();
    };

    return (
        <div className="btn-signin is-ms" onClick={_handleClick}>
            <div className="btn-icon flex-centeritem">
                <IconMicrosoft width={24} height={24} />
            </div>
            <div className="btn-label flex-1">Sign in with Microsoft</div>
        </div>
    );
};

export default ConnectWithMS;
