import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import GDInputRange from 'app/components/input/InputRange';
import { reducer } from 'app/const/Reducer';

const OpportunityRangeValue = forwardRef(({ defaultValue = 50, pipeline = null }, ref) => {
    const { t } = useTranslation('customers');
    const [state, dispatchState] = useReducer(reducer, { value: defaultValue });
    const { value, isDisable } = state;

    useImperativeHandle(ref, () => ({ getValue: () => value }));

    useEffect(() => {
        if (pipeline) {
            if (pipeline.status === 'Lost') dispatchState({ value: 0, isDisable: true });
            if (pipeline.status === 'Won') dispatchState({ value: 100, isDisable: true });
            if (!['Lost', 'Won'].includes(pipeline.status)) dispatchState({ isDisable: false, value: defaultValue });
        }
    }, [pipeline]);

    const _handleChange = (value) => {
        dispatchState({ value });
    };

    return (
        <div className={classNames('flex-column gap-4 flex-1', { 'is-disable': isDisable })}>
            <p className="d-flex">
                {t('confidence')} <span className="text-right fw-600 flex-1">{value}%</span>
            </p>
            <div className="wrap-plans flex-centeritem">
                <GDInputRange
                    step={5}
                    min={0}
                    max={100}
                    debounceTime={0}
                    displayOverlay
                    defaultValue={value}
                    classWrapper="progress-bar --md relative flex-1"
                    classSlider="slider-main"
                    onChange={_handleChange}
                />
            </div>
        </div>
    );
});

export default OpportunityRangeValue;
