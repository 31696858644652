import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { AGENDAR_MODE } from 'app/modules/calendar/const/Header';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { updateAgenda, updateShowWeekends } from 'common/redux/actions/calendar';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { clientQuery } from 'common/utils/ApiUtils';
import { CALENDAR_STORE } from 'app/const/Api';
import { useTranslation } from 'react-i18next';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_CALENDAR_SETTINGS } from 'app/const/App';
import { mixpanelCalendarViews } from 'app/modules/mixpanel/MixpanelCalendarViews';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { MODES_ALLOWS_CHANGE_SHOW_WEEKEND } from '../../const';

const CalendarAgenda = () => {
    const dispatch = useDispatch();
    const { view, weekends } = useSelector((state) => state.calendar);
    const profileUser = useSelector(({ auth }) => auth.user.profile);
    const [finalIsVisible, setFinalIsVisible] = useState(false);
    const refDowpdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_mode_agenda');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_mode_agenda');
        if (
            refDowpdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDowpdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        finalIsVisible && setFinalIsVisible(false);
    }

    function _handleOpen(e) {
        e.preventDefault();
        e.stopPropagation();

        setFinalIsVisible(!finalIsVisible);
    }

    function _handleClickMode(modeId) {
        if (modeId !== view) {
            handleTrackingEvent(mixpanelCalendarViews({ profile: profileUser, viewType: modeId }));
            setLocalStorage(KEY_CALENDAR_SETTINGS, { view: modeId });
            dispatch(updateAgenda(modeId));
            clientQuery(CALENDAR_STORE, { data: { type: 2, value: modeId }, method: 'PUT' });
        }
        _closeDropdown();
    }

    function _changeShowWeekend(value) {
        dispatch(updateShowWeekends({ weekends: value }));
        clientQuery(CALENDAR_STORE, { method: 'PUT', data: { type: 4, value: value } });
    }

    function _renderCurrentMode() {
        const currentMode = AGENDAR_MODE.find((item) => item.id === view) || {};
        return (
            <div onClick={(e) => _handleOpen(e)} className="dropbtn btn-agenda v2-btn-default --icon-r mr-0">
                <p className="txt-ellipsis">{currentMode.name}</p>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </div>
        );
    }

    return (
        <div
            ref={refDowpdown}
            className={classNames('v2-dropdown list-agenda', {
                active: finalIsVisible
            })}
        >
            {_renderCurrentMode()}
            {finalIsVisible && (
                <div id="show_list_mode_agenda" className="v2-dropdown__menu">
                    <ListAgenda
                        modeActive={view}
                        onChangeMode={_handleClickMode}
                        onChangeWeekend={_changeShowWeekend}
                        weekends={weekends}
                    />
                </div>
            )}
        </div>
    );
};

const ListAgenda = ({ modeActive, onChangeMode = () => {}, onChangeWeekend = () => {}, weekends }) => {
    const { t } = useTranslation(['calendar']);
    const [checked, setChecked] = useState(weekends);
    const isDisable = !MODES_ALLOWS_CHANGE_SHOW_WEEKEND.includes(modeActive);

    function _handleChangeMode() {
        const final = weekends === 1 ? 0 : 1;
        setChecked(final);
        onChangeWeekend(final);
    }

    return (
        <ul>
            {AGENDAR_MODE.map((item) => {
                return (
                    <li onClick={() => onChangeMode(item.id)} key={item.id}>
                        <div className={classNames('items', { active: modeActive === item.id })}>{item.name}</div>
                    </li>
                );
            })}
            <div className={classNames('has-toggle', { 'is-disable': isDisable })}>
                <div className="items">
                    <div className="switch large mr-2">
                        <input
                            onChange={(e) => _handleChangeMode(e)}
                            id="toggle_show_weekends"
                            className="toggle toggle-round"
                            type="checkbox"
                            checked={checked ? true : false}
                        />
                        <label htmlFor="toggle_show_weekends">
                            <span className="label">{t('calendar:show_weekends')}</span>
                        </label>
                    </div>
                </div>
            </div>
        </ul>
    );
};

export default CalendarAgenda;
