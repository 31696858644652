import i18n from 'assets/i18n';
import { OBJECT_TYPES } from './types';

export const COLUMNS_BUILT_IN = {
    info: {
        value: 'info',
        label: i18n.t('smartView:name'),
        disabled: true,
        checked: true,
        objectType: OBJECT_TYPES.CUSTOMER
    },
    status: { value: 'status', label: i18n.t('smartView:status'), objectType: OBJECT_TYPES.CUSTOMER },
    phones: { value: 'phones', label: i18n.t('smartView:phone'), objectType: OBJECT_TYPES.CUSTOMER },
    email: { value: 'email', label: i18n.t('smartView:email'), objectType: OBJECT_TYPES.CUSTOMER },
    company: { value: 'company', label: i18n.t('smartView:company'), objectType: OBJECT_TYPES.CUSTOMER },
    source: { value: 'source', label: i18n.t('smartView:source'), objectType: OBJECT_TYPES.CUSTOMER },
    created_date: { value: 'created_date', label: i18n.t('smartView:created_date'), objectType: OBJECT_TYPES.CUSTOMER },
    top_note: { value: 'top_note', label: i18n.t('smartView:top_note'), objectType: OBJECT_TYPES.CUSTOMER },
    service_address: {
        value: 'service_address',
        label: i18n.t('smartView:service_address'),
        objectType: OBJECT_TYPES.CUSTOMER
    },
    billing_address: {
        value: 'billing_address',
        label: i18n.t('smartView:billing_address'),
        objectType: OBJECT_TYPES.CUSTOMER
    },
    customer_tag: {
        value: 'customer_tag',
        label: i18n.t('smartView:customer_tag'),
        objectType: OBJECT_TYPES.CUSTOMER
    },
    location_tag: {
        value: 'location_tag',
        label: i18n.t('smartView:location_tag'),
        objectType: OBJECT_TYPES.CUSTOMER
    },

    // Object call
    latest_outgoing_call_date: {
        value: 'latest_outgoing_call_date',
        label: i18n.t('smartView:latest_outgoing_call_date'),
        objectType: OBJECT_TYPES.CALL
    },
    latest_incoming_call_date: {
        value: 'latest_incoming_call_date',
        label: i18n.t('smartView:latest_incoming_call_date'),
        objectType: OBJECT_TYPES.CALL
    },
    count_call: { value: 'count_call', label: i18n.t('smartView:#_call'), objectType: OBJECT_TYPES.CALL },
    count_outgoing_call: {
        value: 'count_outgoing_call',
        label: i18n.t('smartView:#_outgoing_call'),
        objectType: OBJECT_TYPES.CALL
    },
    count_incoming_call: {
        value: 'count_incoming_call',
        label: i18n.t('smartView:#_incoming_call'),
        objectType: OBJECT_TYPES.CALL
    },

    // SMS
    latest_sms_message: {
        value: 'latest_sms_message',
        label: i18n.t('smartView:latest_sms_message'),
        objectType: OBJECT_TYPES.SMS_MESSAGES
    },
    latest_sms_date: {
        value: 'latest_sms_date',
        label: i18n.t('smartView:latest_sms_date'),
        objectType: OBJECT_TYPES.SMS_MESSAGES
    },
    count_sms: { value: 'count_sms', label: i18n.t('smartView:#_sms_messages'), objectType: OBJECT_TYPES.SMS_MESSAGES },

    // Task
    next_task: { value: 'next_task', label: i18n.t('smartView:next_task'), objectType: OBJECT_TYPES.TASK },
    next_task_date: {
        value: 'next_task_date',
        label: i18n.t('smartView:next_task_date'),
        objectType: OBJECT_TYPES.TASK
    },
    count_open_task: {
        value: 'count_open_task',
        label: i18n.t('smartView:#_open_task'),
        objectType: OBJECT_TYPES.TASK
    },

    // Opportunity
    active_opportunity_value: {
        value: 'active_opportunity_value',
        label: i18n.t('smartView:active_opportunity_value'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    won_opportunity_value: {
        value: 'won_opportunity_value',
        label: i18n.t('smartView:won_opportunity_value'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    lost_opportunity_value: {
        value: 'lost_opportunity_value',
        label: i18n.t('smartView:lost_opportunity_value'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    opportunity_value: {
        value: 'opportunity_value',
        label: i18n.t('smartView:opportunity_value'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },

    count_opportunity: {
        value: 'count_opportunity',
        label: i18n.t('smartView:#_opportunity'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    count_active_opportunity: {
        value: 'count_active_opportunity',
        label: i18n.t('smartView:#_active_opportunity'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    count_won_opportunity: {
        value: 'count_won_opportunity',
        label: i18n.t('smartView:#_won_opportunity'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },
    count_lost_opportunity: {
        value: 'count_lost_opportunity',
        label: i18n.t('smartView:#_lost_opportunity'),
        objectType: OBJECT_TYPES.OPPORTUNITY
    },

    // Job
    count_active_job: {
        value: 'count_active_job',
        label: i18n.t('smartView:#_active_jobs'),
        objectType: OBJECT_TYPES.JOB
    },
    count_unconfirmed_job: {
        value: 'count_unconfirmed_job',
        label: i18n.t('smartView:#_unconfirmed_job'),
        objectType: OBJECT_TYPES.JOB
    },
    count_confirmed_job: {
        value: 'count_confirmed_job',
        label: i18n.t('smartView:#_confirmed_job'),
        objectType: OBJECT_TYPES.JOB
    },
    count_reschedule_job: {
        value: 'count_reschedule_job',
        label: i18n.t('smartView:#_reschedule_job'),
        objectType: OBJECT_TYPES.JOB
    },
    count_pending_job: {
        value: 'count_pending_job',
        label: i18n.t('smartView:#_pending_job'),
        objectType: OBJECT_TYPES.JOB
    },
    count_completed_job: {
        value: 'count_completed_job',
        label: i18n.t('smartView:#_completed_job'),
        objectType: OBJECT_TYPES.JOB
    },
    count_canceled_job: {
        value: 'count_canceled_job',
        label: i18n.t('smartView:#_canceled_job'),
        objectType: OBJECT_TYPES.JOB
    },

    // Estimate
    count_estimate: {
        value: 'count_estimate',
        label: i18n.t('smartView:#_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    },
    count_draft_estimate: {
        value: 'count_draft_estimate',
        label: i18n.t('smartView:#_draft_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    },
    count_pending_estimate: {
        value: 'count_pending_estimate',
        label: i18n.t('smartView:#_pending_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    },
    count_won_estimate: {
        value: 'count_won_estimate',
        label: i18n.t('smartView:#_won_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    },
    count_invoiced_estimate: {
        value: 'count_invoiced_estimate',
        label: i18n.t('smartView:#_invoiced_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    },
    count_lost_estimate: {
        value: 'count_lost_estimate',
        label: i18n.t('smartView:#_lost_estimates'),
        objectType: OBJECT_TYPES.ESTIMATE
    }
};

export const COLUMNS_OPTIONS = [
    {
        objectType: OBJECT_TYPES.CUSTOMER,
        title: i18n.t('smartView:customers'),
        options: [
            COLUMNS_BUILT_IN['info'],
            COLUMNS_BUILT_IN['status'],
            COLUMNS_BUILT_IN['phones'],
            COLUMNS_BUILT_IN['email'],
            COLUMNS_BUILT_IN['company'],
            COLUMNS_BUILT_IN['source'],
            COLUMNS_BUILT_IN['created_date'],
            COLUMNS_BUILT_IN['top_note'],
            COLUMNS_BUILT_IN['service_address'],
            COLUMNS_BUILT_IN['billing_address'],
            COLUMNS_BUILT_IN['customer_tag'],
            COLUMNS_BUILT_IN['location_tag']
        ]
    },
    {
        objectType: OBJECT_TYPES.CALL,
        title: i18n.t('smartView:calls'),
        options: [
            COLUMNS_BUILT_IN['latest_outgoing_call_date'],
            COLUMNS_BUILT_IN['latest_incoming_call_date'],
            COLUMNS_BUILT_IN['count_call'],
            COLUMNS_BUILT_IN['count_outgoing_call'],
            COLUMNS_BUILT_IN['count_incoming_call']
        ]
    },
    {
        objectType: OBJECT_TYPES.SMS_MESSAGES,
        title: i18n.t('smartView:sms_messages'),
        options: [
            COLUMNS_BUILT_IN['latest_sms_message'],
            COLUMNS_BUILT_IN['latest_sms_date'],
            COLUMNS_BUILT_IN['count_sms']
        ]
    },
    {
        objectType: OBJECT_TYPES.TASK,
        title: i18n.t('smartView:tasks'),
        options: [
            COLUMNS_BUILT_IN['next_task'],
            COLUMNS_BUILT_IN['next_task_date'],
            COLUMNS_BUILT_IN['count_open_task']
        ]
    },
    {
        objectType: OBJECT_TYPES.OPPORTUNITY,
        title: i18n.t('smartView:opportunities'),
        options: [
            COLUMNS_BUILT_IN['active_opportunity_value'],
            COLUMNS_BUILT_IN['won_opportunity_value'],
            COLUMNS_BUILT_IN['lost_opportunity_value'],
            COLUMNS_BUILT_IN['opportunity_value'],

            COLUMNS_BUILT_IN['count_opportunity'],
            COLUMNS_BUILT_IN['count_active_opportunity'],
            COLUMNS_BUILT_IN['count_won_opportunity'],
            COLUMNS_BUILT_IN['count_lost_opportunity']
        ]
    },
    {
        objectType: OBJECT_TYPES.JOB,
        title: i18n.t('smartView:jobs'),
        options: [
            COLUMNS_BUILT_IN['count_active_job'],
            COLUMNS_BUILT_IN['count_unconfirmed_job'],
            COLUMNS_BUILT_IN['count_confirmed_job'],
            COLUMNS_BUILT_IN['count_reschedule_job'],
            COLUMNS_BUILT_IN['count_pending_job'],
            COLUMNS_BUILT_IN['count_completed_job'],
            COLUMNS_BUILT_IN['count_canceled_job']
        ]
    },
    {
        objectType: OBJECT_TYPES.ESTIMATE,
        title: i18n.t('smartView:estimates'),
        options: [
            COLUMNS_BUILT_IN['count_estimate'],
            COLUMNS_BUILT_IN['count_draft_estimate'],
            COLUMNS_BUILT_IN['count_pending_estimate'],
            COLUMNS_BUILT_IN['count_won_estimate'],
            COLUMNS_BUILT_IN['count_invoiced_estimate'],
            COLUMNS_BUILT_IN['count_lost_estimate']
        ]
    }
];
