import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { updateOrderStatusOnTable } from 'app/modules/settings/utils/statusOrder.js';

import {
    LIST_TAB_BASIC,
    LIST_BUTTON_ACTION_TAXES,
    LIST_BUTTON_MESSAGES_TAXES,
    LIST_ACTION_BASIC
} from 'app/const/Settings';

const TAB = {
    LIST: LIST_TAB_BASIC,
    DEFAULT_SELECTED: LIST_ACTION_BASIC.ACTIVE
};

const LIST_BUTTONS = LIST_BUTTON_ACTION_TAXES;

const LIST_BUTTON_MESSAGES = LIST_BUTTON_MESSAGES_TAXES;

const TABLE = {
    header: {
        name: {
            title: 'tax_name',
            style: 'col --lg --full flex-1',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        rate: {
            title: 'rate',
            style: 'col --lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    },
    columns: [
        {
            id: 'name',
            status: true
        },
        {
            id: 'rate',
            status: true
        }
    ],
    contentConfig: {
        name: {
            id: 'name',
            style: 'col --lg --full --name flex-1',
            styleText: 'col-label btn-modal',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        rate: {
            id: 'rate',
            style: 'col --lg',
            type: LIST_TABLE_ITEM_TYPE.RATE_PERCENT
        }
    }
};

const getColumns = ({ actionStatus, currentTab }) => {
    const newColumns = TABLE;
    if (actionStatus) {
        newColumns.header = updateOrderStatusOnTable({ headerTable: newColumns.header, actionStatus: actionStatus });
    }
    if (currentTab && currentTab === LIST_ACTION_BASIC.DELETE) {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.DEFAULT;
        newColumns.contentConfig.name.style = 'col --lg --full flex-1';
    } else {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.LINK;
        newColumns.contentConfig.name.style = 'col --lg --full --name flex-1';
    }
    return newColumns;
};

export { TAB, LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
