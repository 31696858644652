import GdGridView from 'app/components/grid/GdGridView';
import ModalLoading from 'app/components/loading/ModalLoading';
import { CALENDAR_GET_LIST_JOB, CUSTOMER_JOBS_UNDELETE, getUrlCustomerJobs } from 'app/const/Api';
import { COMMON, DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import {
    CLASS_TABLES_CUSTOMER_JOBS,
    JOB_STATUS_VIEW_ACTIVE,
    JOB_STATUS_VIEW_CANCELLED,
    JOB_STATUS_VIEW_COMPLETED,
    JOB_STATUS_VIEW_DELETED,
    getGridColumns,
    orderByStatusJobs
} from 'app/const/customer/CustomerJobs';
import { getDefaultParams } from 'app/const/report/ReportParams';
import DeleteJobInvoice from 'app/modules/jobdetail/modal/DeleteJobInvoice';
import DeleteJobRepeat from 'app/modules/jobdetail/modal/DeleteJobRepeat';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionCreateJob } from 'common/redux/actions/job/create';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatDateLocal, formatTime } from 'common/utils/DateUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AlertCustomer from '../../components/AlertCustomer';
import { handleAbortController, resetPagingLocation } from '../../utils';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { responseToLocationSelected } from '../locations/utils';
import CustomerJobActions from './components/CustomerJobActions';
import { useTranslation } from 'react-i18next';
import GDModalWarning from 'app/components/modal/ModalWarning';
import ButtonSave from 'app/components/button/ButtonSave';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { JOB_CANCELED, JOB_COMPLETED } from 'app/const/Job';

export default function CustomerJobs() {
    const { id: customer_id } = useParams();
    const { t } = useTranslation('customers');
    const dispatch = useDispatch();

    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMER_JOBS);

    let paramsJobs = getLocalStorage(keyLocalStore);

    if (!paramsJobs || paramsJobs.customer_id !== customer_id) {
        paramsJobs = getDefaultParams(REPORT_TYPE.CUSTOMER_JOBS);
        paramsJobs.customer_id = customer_id;
        setLocalStorage(keyLocalStore, paramsJobs);
    }

    const { _handleUpdateUnitList, _handleUpdateLocationList } = useContext(CustomerDetailContext);
    const dateFormat = useSelector(({ auth }) => auth.user.company.date_format);
    const { newJobData } = useSelector(({ createJobReducer }) => createJobReducer);
    const jobDelete = useSelector(({ jobReducer }) => jobReducer.jobDelete);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        status: !!paramsJobs.deleted ? JOB_STATUS_VIEW_DELETED : paramsJobs.status,
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        total: 0,
        isLoading: true,
        isDeleted: false
    });
    const { location_ids, unitSelected, reloadCustomerPage, customer } = useContext(CustomerDetailContext);

    const refLoading = useRef(null);
    const refDeleteJobRepeat = useRef(null);
    const refAlert = useRef(null);
    const refOldLocation = useRef(null);
    const refOldUnit = useRef(null);
    const refDeleteJobInvoice = useRef(null);
    const refWarning = useRef(null);
    const { data: jobs, status: jobStatusView, isDeleted, isLoading, checkedItems: finalCheckedItems } = state;
    const { ids: checkedIds } = finalCheckedItems;
    const abortController = useRef(null);

    useEffect(() => {
        if (checkReload(location_ids, unitSelected) || reloadCustomerPage) {
            if (!isLoading) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            refOldUnit.current = unitSelected?.id || null;
            _getListJob(resetPagingLocation({ params: paramsJobs, key: keyLocalStore }));
        }

        return () => {
            handleAbortController(abortController);
        };
    }, [location_ids, reloadCustomerPage, unitSelected]);

    useEffect(() => {
        (newJobData || jobDelete) && _getListJob(paramsJobs, false);
    }, [newJobData, jobDelete]);

    const checkReload = (location_ids, unitSelected) => {
        if (!location_ids || !unitSelected) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current;
    };

    /**
     * Get list jobs by params
     * @param {object} data - Params to get list jobs
     * @param {boolean} notLoading - Display loading in table or not
     */
    const _getListJob = (data, isShowLoading = true) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const params = getNewRequest(data);
        const paramsDeleted = !!params.deleted;
        const statusFinal = paramsDeleted ? JOB_STATUS_VIEW_DELETED : params.status;
        params.status = statusFinal;
        const newState = {};
        if (!isShowLoading) {
            newState.isLoading = false;
        } else {
            newState.isLoading = true;
        }
        newState.isDeleted = paramsDeleted;
        newState.checkedItems = { is_check_all: false, ids: [] };
        newState.status = statusFinal;
        dispatchState(newState);

        const _success = ({ data, total }) => {
            dispatchState({
                data,
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total
            });
        };
        const _failed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(
            getUrlCustomerJobs(customer_id),
            {
                data: { ...params, location_ids, unit_ids: unitSelected?.id || DEFAULT_ALL },
                method: 'GET',
                abortController: abortController.current
            },
            _success,
            _failed
        );
    };

    const getNewRequest = (params) => {
        const newParam = { ...params };
        const statusFinal = !!newParam.deleted ? JOB_STATUS_VIEW_DELETED : params.status;
        let newOrder = newParam[orderByStatusJobs[statusFinal]];
        newOrder = newOrder.replace(COMMON.ACTIVE_JOBS, COMMON.NAME);
        newOrder = newOrder.replace(COMMON.NEXT_SERVICE, COMMON.DATE);
        newParam.order = newOrder;

        const statuses = Object.values(orderByStatusJobs);
        statuses.forEach((item) => {
            delete newParam[item];
        });
        delete newParam.filterTab;
        return newParam;
    };

    /**
     * Select job on table to handle delete||unDelete
     * @param {array} checkedItems - List label checked
     */
    const _handleSelect = (checkedItems) => dispatchState({ checkedItems });

    /* Handle update by pagination, reload page and set local storage. */
    const _handleUpdate = () => {
        _getListJob(getLocalStorage(keyLocalStore));
    };

    const _handleDeleteSuccess = ({ message, error, recurrence, invoice }, ids) => {
        _handleCloseWarning();
        if (Array.isArray(invoice)) return refDeleteJobInvoice.current.showModal(invoice);
        if (recurrence) return refDeleteJobRepeat.current.showModal(_handleDeleteJob);
        refDeleteJobRepeat.current.closeModal();

        let idsSuccess = [];
        if (error?.message?.length) {
            ids.forEach((item) => {
                if (!error.items.includes(parseInt(item))) {
                    idsSuccess.push(item);
                }
            });
            refAlert.current.showStatusBar({ id: 'error', message: error.message, type: LIST_STATUS.ERROR });
        } else {
            idsSuccess = ids;
        }

        if (message.length) {
            refAlert.current.showStatusBar({ id: 'success', message, type: LIST_STATUS.SUCCESS });
        }

        if (idsSuccess.length) _getListJob(getLocalStorage(keyLocalStore), false);
    };

    const _handleClickAction = (type) => {
        refLoading.current._open();

        switch (type) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                _handleConfirmWarning();
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                _handleUnDelete(checkedIds);
                break;
            default:
                break;
        }
    };

    const _handleDeleteJob = (flagDelete = 0) => {
        clientQuery(
            CALENDAR_GET_LIST_JOB,
            { data: { ids: checkedIds, delete: flagDelete }, method: 'DELETE' },
            (res) => _handleDeleteSuccess(res, checkedIds),
            _handleCloseWarning
        );
    };

    const _handleUnDelete = (ids) => {
        clientQuery(
            CUSTOMER_JOBS_UNDELETE,
            { data: { ids }, method: 'PUT' },
            (res) => _handleDeleteSuccess(res, ids),
            null,
            () => refLoading.current._close()
        );
    };

    const _handleUpdateJobData = (jobData) => {
        if (!jobData || !jobData.job) return;

        let shouldUpdate = false;
        const jobStatus = getJobStatus(jobData.job.status)?.type;
        const isCancelJob = [JOB_CANCELED, JOB_COMPLETED].includes(jobStatus);

        // Check should update or delete
        if (jobStatusView === JOB_STATUS_VIEW_ACTIVE && !isCancelJob) shouldUpdate = true;
        if (jobStatusView === JOB_STATUS_VIEW_COMPLETED && jobStatus === JOB_COMPLETED) shouldUpdate = true;
        if (jobStatusView === JOB_STATUS_VIEW_CANCELLED && jobStatus === JOB_CANCELED) shouldUpdate = true;
        if (jobStatusView === JOB_STATUS_VIEW_DELETED) shouldUpdate = true;

        if (shouldUpdate) {
            const invoiceData = jobData?.invoice || {};
            const isInvoiceChange = invoiceData.recurrence;

            const data = [...jobs];
            const index = data.findIndex((job) => job.id === jobData.job.id);

            // If not found any job - break code
            if (index < 0) return;

            const startTime = jobData.event.start;

            let frequency = 'Once';
            if (!!jobData.event.repeat) {
                frequency = jobData.event.recurrence.offset.frequency;
                frequency = `${frequency.charAt(0)}${frequency.substring(1).toLowerCase()}`;
            }

            data[index] = {
                ...data[index],
                name: jobData.job.name,
                location: jobData.location.name,
                assignees: jobData.schedule,
                invoice_total: invoiceData.total,
                invoice_status: invoiceData.status,
                invoice_number: invoiceData.invoice_number,
                job_recurrence: {
                    exception_text: jobData.event.exception.summary,
                    frequency,
                    repeat_text: jobData.event.recurrence.summary
                },
                date: formatDateLocal(startTime, dateFormat),
                time: `${formatTime(startTime, 'hh:mm a')} - ${formatTime(jobData.event.end, 'hh:mm a')}`
            };

            if (isInvoiceChange) {
                const { frequency: newFrequency = null, summary: newRepeatText = null } = isInvoiceChange || {};

                const currentDataInvoice = data[index]?.invoice_recurrence || {};

                if (
                    (newFrequency !== null && currentDataInvoice.frequency !== newFrequency) ||
                    (newRepeatText !== null && currentDataInvoice.repeat_text !== newRepeatText)
                ) {
                    data[index]['invoice_recurrence'] = {
                        frequency: newFrequency,
                        repeat_text: newRepeatText
                    };
                }
            }

            dispatchState({ data });
        } else {
            _getListJob(paramsJobs, false);
        }
    };

    const _handleOpenJob = (id) => {
        dispatch(
            actionOpenJobDetail({
                id,
                onUpdateJobData: _handleUpdateJobData,
                onUpdateUnits: _handleUpdateUnitList
            })
        );
    };

    const _handleClickInvoice = ({ row }) => {
        dispatch(
            actionOpenInvoice({
                id: row.invoice_id,
                status: row.invoice_status,
                total: row.invoice_total,
                isRecurring: false,
                onDeleteSuccess: () => _getListJob(paramsJobs, false)
            })
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CUSTOMER_JOBS,
            columnsTarget,
            currentValue,
            paramsReport: paramsJobs,
            callBack: _handleUpdate,
            keyOrder: orderByStatusJobs[jobStatusView]
        });
    };

    function roundMinutesToNearestInterval(minutes) {
        const intervals = [0, 15, 30, 45];
        return intervals.find((item) => minutes <= item) || 0;
    }

    const _handleAddJob = () => {
        const currentMinute = parseInt(moment().format('mm'));
        const finalMinute = roundMinutesToNearestInterval(currentMinute);

        const preData = {
            customer: {
                id: customer_id,
                account_no: customer.account_number,
                avatar: customer.avatar,
                first_name: customer.first_name,
                full_name: customer.full_name,
                last_name: customer.last_name
            },
            customer_id: customer_id,
            isAddFromDetail: true,
            start: `${moment().format(`yyyy-MM-DDTHH:${finalMinute > 0 ? finalMinute : '0'.concat(finalMinute)}:ss`)}Z`
        };

        const _handleCreateLocation = (newLocation, customerId) => {
            if (customer_id === customerId)
                _handleUpdateLocationList(responseToLocationSelected(newLocation, customer_id), 'create');
        };

        const _handleUpdateUnit = (data, type, customerId) => {
            if (customerId === customer_id) _handleUpdateUnitList(data, type);
        };

        dispatch(actionCreateJob(preData, _handleCreateLocation, _handleUpdateUnit));
    };

    const _handleConfirmWarning = () => {
        const quantity = checkedIds.length;
        refWarning.current._open({
            title: t('customers:delete_job'),
            description: t(`customers:confirm_delete_job${quantity > 1 ? 's' : ''}_desc`, {
                quantity
            }),
            isLargeContent: true
        });
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
        refLoading.current._close();
    };

    return (
        <Fragment>
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages gap-8">
                    <div className="wrap-tables flex-column relative">
                        <AlertCustomer ref={refAlert} />
                        <CustomerJobActions
                            totalSelected={finalCheckedItems.ids.length}
                            isDeleted={isDeleted}
                            onClick={_handleClickAction}
                            onSelectTab={_handleUpdate}
                            totalItems={state.total || 0}
                            jobStatusView={jobStatusView}
                            reportType={REPORT_TYPE.CUSTOMER_JOBS}
                            onUpdate={_handleUpdate}
                            onAddJob={_handleAddJob}
                            checkedItems={finalCheckedItems}
                        />
                        <GdGridView
                            content={jobs}
                            isLoading={isLoading}
                            msgEmpty={t('no_jobs')}
                            fileTranslation="report"
                            checkedItems={finalCheckedItems}
                            onChangeStateCheckedItems={_handleSelect}
                            showCheckBox
                            handleClickHeader={_handleActionHeader}
                            onOpenJob={_handleOpenJob}
                            classTable={CLASS_TABLES_CUSTOMER_JOBS[jobStatusView]}
                            handleClick={_handleClickInvoice}
                            {...getGridColumns(paramsJobs, jobStatusView)}
                            isScroll
                        />
                        <ReportPagination
                            reportType={REPORT_TYPE.CUSTOMER_JOBS}
                            totalPage={state.totalPage || 1}
                            onSelect={_handleUpdate}
                            isCustomerPage
                        />
                        <ModalLoading ref={refLoading} />
                        <DeleteJobInvoice ref={refDeleteJobInvoice} />
                        <DeleteJobRepeat ref={refDeleteJobRepeat} />
                    </div>
                </div>

                <GDModalWarning
                    ref={refWarning}
                    onClose={() => refLoading.current._close()}
                    footer={
                        <div className="footer-modal footer-hasbtn btn-close">
                            <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                                {t('common:cancel')}
                            </span>
                            <ButtonSave title={t('common:yes')} className="v2-btn-main" onSave={_handleDeleteJob} />
                        </div>
                    }
                />
            </div>
        </Fragment>
    );
}
