import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getGridTableTracker = () => {
    const HEADERS = {
        user: { title: 'user', style: 'col col-user' },
        rate: { title: 'rate', style: 'col col-sm' },
        date: { title: 'date', style: 'col col-md' },
        clock_in_out: { title: 'clock_in_out', style: 'col col-clock' },
        duration: { title: 'duration', style: 'col col-duration' },
        amount: { title: 'amount', style: 'col col-md-plus' }
    };
    const COLUMNS = [
        { id: 'user', status: true },
        { id: 'rate', status: true },
        { id: 'date', status: true },
        { id: 'clock_in_out', status: true },
        { id: 'duration', status: true },
        { id: 'amount', status: true }
    ];
    const CONTENTS = {
        user: { title: 'user', style: 'col col-user', type: LIST_TABLE_ITEM_TYPE.CREATED_BY },
        rate: { title: 'rate', style: 'col col-sm', type: LIST_TABLE_ITEM_TYPE.RATE_PER_HOUR },
        date: { title: 'date', style: 'col col-md' },
        clock_in_out: { title: 'clock_in_out', style: 'col col-clock', type: LIST_TABLE_ITEM_TYPE.IN_OUT_TIME },
        duration: { title: 'duration', style: 'col col-duration', type: LIST_TABLE_ITEM_TYPE.DURATION },
        amount: { title: 'amount', style: 'col col-md-plus', type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL }
    };

    return {
        header: HEADERS,
        columns: COLUMNS,
        contentConfig: CONTENTS
    };
};
