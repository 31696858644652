import IconPlus from 'assets/icon/IconPlus';
import GdButton from 'app/components/button';
import Add from 'app/modules/jobdetail/tabs/timeline/todo/checklist/Add';
import { useRef, useReducer, useEffect, forwardRef, useImperativeHandle } from 'react';
import BoxItem from './BoxItem';
import { reducer } from 'app/const/Reducer';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';

const BoxTodoControl = ({ items = [], templateId = '', onHasBeenChange = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        items
    });

    const { items: finalItems } = state;

    const { t } = useTranslation();

    const refAdd = useRef(null);
    const refConfirm = useRef(null);

    useImperativeHandle(ref, () => ({
        _getFinalItems,
        _setItems
    }));

    useEffect(() => {
        dispatchState({ items });
    }, [templateId]);

    const _openFormAdd = () => refAdd.current._open();

    const _getFinalItems = () => {
        return finalItems;
    };

    const _setItems = (items) => {
        dispatchState({ items });
    };

    const _addItem = (text) => {
        dispatchState({ items: [...finalItems, { id: uuidv4(), description: text }] });
        onHasBeenChange();
    };

    const _editItem = (data) => {
        dispatchState({
            items: finalItems.map((item) => {
                if (item.id === data.id) return { ...item, description: data.description };
                return item;
            })
        });
        onHasBeenChange();
    };

    const _deleteItem = (id) => {
        dispatchState({ items: finalItems.filter((item) => item.id !== id) });
        onHasBeenChange();
    };

    const _openFormDelete = (id) => {
        refConfirm.current.open(id);
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        ...draggableStyle,
        userSelect: 'none',
        opacity: isDragging ? 0.7 : 1,
        backgroundColor: isDragging ? 'rgba(0, 0, 0, 0.1)' : 'white',
        top: 'auto',
        left: 'auto',
        border: 1
    });

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const _onDragEnd = (result) => {
        if (!result.destination) {
            return false;
        }
        dispatchState((prev) => {
            return {
                ...prev,
                items: reorder(finalItems, result.source.index, result.destination.index)
            };
        });
        onHasBeenChange();
    };

    const _renderItems = () => {
        return (
            <DragDropContext onDragEnd={_onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {finalItems.map((item, index) => {
                                const draggableId = item.id || index.toString();
                                return (
                                    <Draggable key={draggableId} draggableId={draggableId} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                <BoxItem item={item} onDelete={_openFormDelete} onEdit={_editItem} />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    };

    return (
        <>
            <div className="wrap-content">
                {_renderItems()}
                <Add ref={refAdd} onSave={_addItem} />
            </div>
            <div className="btn-action shadow-top-brown">
                <GdButton
                    className="v2-btn-default has-icon --purple svg-purple-fill"
                    iconSvg={<IconPlus />}
                    title={t('setting:new_todo')}
                    onClick={_openFormAdd}
                />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('setting:confirm_delete_todo_item')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_deleteItem}
            />
        </>
    );
};
export default forwardRef(BoxTodoControl);
