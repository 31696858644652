import React from 'react';

const IconGrowth = ({ isHeader }) => {
    if (isHeader)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.6457 15.585C11.6234 14.1152 11.4642 12.1126 10.168 10.8163C8.02733 8.67572 2.67578 8.67572 2.67578 8.67572C2.67578 8.67572 2.67578 14.0273 4.8164 16.1679C6.11262 17.4641 8.11525 17.6234 9.58506 16.6457L12.2197 19.2803L13.2803 18.2196L10.6457 15.585Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5763 12.2857C16.0275 12.7218 17.6657 12.3666 18.8123 11.2201C21.2185 8.81384 21.2185 2.79822 21.2185 2.79822C21.2185 2.79822 15.2029 2.79822 12.7967 5.20447C11.1355 6.86564 11.1355 9.55893 12.7967 11.2201C12.9358 11.3592 13.0822 11.4867 13.2346 11.6026C12.4222 13.5853 11.9998 15.7146 11.9998 17.8732V21H13.4998V17.8732C13.4998 15.9518 13.8679 14.0559 14.5763 12.2857Z"
                    fill="#1EAA5C"
                />
            </svg>
        );
    return (
        <svg
            width="16px"
            height="15px"
            viewBox="0 0 16 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>growth-plan</title>
            <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <g id="Main-Nav" transform="translate(-587.000000, -1573.000000)">
                    <g id="growth-plan" transform="translate(587.257359, 1573.257359)">
                        <path
                            d="M11.2426407,7.74264069 C12.6233526,7.74264069 13.7426407,6.52386028 13.7426407,5.02041846 C13.7426407,3.4996054 12.9093074,2.07367947 11.2426407,0.742640687 C9.57597402,2.07367947 8.74264069,3.4996054 8.74264069,5.02041846 C8.74264069,6.52386028 9.86192881,7.74264069 11.2426407,7.74264069 Z"
                            id="Oval"
                            fill="#FFFFFF"
                            transform="translate(11.242641, 4.242641) rotate(-315.000000) translate(-11.242641, -4.242641) "
                        />
                        <path
                            d="M4.24264069,11.7426407 C5.62335256,11.7426407 6.74264069,10.5238603 6.74264069,9.02041846 C6.74264069,7.4996054 5.90930735,6.07367947 4.24264069,4.74264069 C2.57597402,6.07367947 1.74264069,7.4996054 1.74264069,9.02041846 C1.74264069,10.5238603 2.86192881,11.7426407 4.24264069,11.7426407 Z"
                            id="Oval"
                            fill="#FFFFFF"
                            opacity="0.5"
                            transform="translate(4.242641, 8.242641) rotate(-45.000000) translate(-4.242641, -8.242641) "
                        />
                        <path
                            d="M8.24264069,14.2426407 L8.24264069,11.0710678 C8.24264069,9.26005634 8.96206219,7.52321918 10.2426407,6.24264069 L10.2426407,6.24264069 L10.2426407,6.24264069"
                            id="Path-5"
                            stroke="#FFFFFF"
                            strokeWidth="1.3"
                        />
                        <line
                            x1="6.24264069"
                            y1="10.2426407"
                            x2="8.24264069"
                            y2="12.2426407"
                            id="Path-6"
                            stroke="#FFFFFF"
                            strokeWidth="1.3"
                            opacity="0.5"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default IconGrowth;
