import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StatusEmail = ({ status = 0, isImap = false }) => {
    const { t } = useTranslation('common');

    const _renderIcon = () => {
        switch (status) {
            case 0:
                return <IconClose isSmall />;
            case 1:
                return isImap ? <IconDoubleCheck /> : <IconCheck />;
            case 2:
                return <IconCheck />;
            default:
                return <IconClose isSmall />;
        }
    };

    const _renderTitle = () => {
        switch (status) {
            case 0:
                return t('disabled');
            case 1:
                return isImap ? t('initial_sync_in_progress') : t('connected');
            case 2:
                return t('connected');
            default:
                return t('disabled');
        }
    };

    return (
        <div
            className={classNames('status-btn fw-normal fs-13 pr-2', {
                connected: (status === 1 && !isImap) || status === 2,
                progress: status === 1 && isImap,
                disabled: status !== 1 && status !== 2
            })}
        >
            {_renderIcon()}
            {_renderTitle()}
        </div>
    );
};

export default StatusEmail;
