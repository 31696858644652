import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import { LIST_STATUS } from '../Customers';

export const getGirdColumnsCustomerDocuments = (actionsStatus, deleted) => {
    const header = {
        number: {
            title: 'document #',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        img_document: {
            title: 'img document',
            style: 'col --customer'
        },
        type: {
            title: 'type',
            style: 'col --lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        job: {
            title: 'job',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        created_by: {
            title: 'created by',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date_created: {
            title: 'date created',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col --status',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const columns = [
        {
            id: 'number',
            title: 'number',
            status: true
        },
        {
            id: 'img_document',
            title: 'img document',
            status: false
        },
        {
            id: 'type',
            title: 'type',
            status: true,
            keyGetValue: 'name'
        },
        {
            id: 'job',
            title: 'job',
            status: true
        },
        {
            id: 'created_by',
            title: 'created_by',
            status: true
        },
        {
            id: 'date_created',
            title: 'date_created',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];
    const contentConfig = {
        number: {
            title: 'number',
            style: 'col col-sm',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        img_document: {
            title: 'img document',
            style: 'col --customer',
            type: LIST_TABLE_ITEM_TYPE.IMG_DOCUMENT
        },
        type: {
            title: 'type',
            style: 'col --lg --name',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK,
            showTypeDoc: true
        },
        job: {
            title: 'job',
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.JOB_DOCUMENT
        },
        created_by: {
            title: 'created_by',
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.CREATED_BY
        },
        date_created: {
            title: 'date_created',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DATE_CREATED
        },
        status: {
            title: 'status',
            style: 'col --status',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...header });
    const newContent = { ...contentConfig };
    newContent['type'].styleText = deleted === LIST_STATUS.archived ? 'dp-inline' : 'name dp-inline';
    newContent['number'].styleText = deleted === LIST_STATUS.archived ? '' : 'name';

    return {
        header: newHeader,
        columns,
        contentConfig: newContent
    };
};
