export default function IconInternet({ isSlow = false }) {
    if (isSlow)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={4} y={4} width={4} height={16} rx={1} fill="black" fillOpacity="0.1" />
                <rect x={10} y={12} width={4} height={8} rx={1} fill="#FF6E26" />
                <rect x={16} y={16} width={4} height={4} rx={1} fill="#FF6E26" />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={4} y={4} width={4} height={16} rx={1} fill="black" fillOpacity="0.1" />
            <rect x={10} y={12} width={4} height={8} rx={1} fill="black" fillOpacity="0.1" />
            <rect x={16} y={16} width={4} height={4} rx={1} fill="black" fillOpacity="0.1" />
        </svg>
    );
}
