import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import Chart from '../index';
import { EMPTY_CHART_COLOR } from 'app/const/App';

const DoughnutChart = ({ dataCharts, unitChart = '%' }) => {
    const getConfig = (dataChart) => {
        const inputConfig = {
            labelTooltip: [],
            percent: [],
            color: []
        };
        dataChart.forEach(({ label, percent, color }) => {
            inputConfig.labelTooltip.push(capitalizeFirstLetter(label));
            inputConfig.percent.push(percent);
            inputConfig.color.push(color);
        });

        return {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: inputConfig.percent.some((item) => +item > 0) ? inputConfig.percent : [],
                        backgroundColor: inputConfig.color,
                        hoverBackgroundColor: inputConfig.color,
                        hoverBorderColor: '#fff'
                    }
                ],
                option: {
                    legend: {
                        display: false
                    }
                }
            },
            options: {
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        callbacks: {
                            label: ({ dataIndex, parsed }) => {
                                return `${inputConfig.labelTooltip[dataIndex]} - ${parsed}${unitChart}`;
                            },
                            fontsize: '30px'
                        },
                        boxPadding: 3
                    },
                    emptyDoughnut: {
                        backgroundColor: EMPTY_CHART_COLOR,
                        color: EMPTY_CHART_COLOR,
                        width: 2,
                        radiusDecrease: 1
                    }
                }
            }
        };
    };

    const emptyDoughnutPlugin = {
        id: 'emptyDoughnut',
        afterDraw(chart, args, options) {
            const { datasets } = chart.data;
            const { backgroundColor, radiusDecrease } = options;
            let hasData = false;

            for (let i = 0; i < datasets.length; i += 1) {
                const dataset = datasets[i];
                hasData |= dataset.data.length > 0;
            }

            if (!hasData) {
                const {
                    chartArea: { left, top, right, bottom },
                    ctx
                } = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                const r = Math.min(right - left, bottom - top) / 2;

                ctx.beginPath();
                ctx.arc(centerX, centerY, r - radiusDecrease || 0, 0, 2 * Math.PI);
                ctx.fillStyle = backgroundColor || 'rgba(255, 128, 0, 0.5)';
                ctx.fill();
            }
        }
    };

    const configObj = getConfig(dataCharts);

    return (
        <div>
            <Chart chartConfig={configObj} plugins={[emptyDoughnutPlugin]} />
        </div>
    );
};

export default DoughnutChart;
