import { useState, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

export default function useClickOutside(initialIsVisible, idPreventClose = '') {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(idPreventClose);
        if (event.keyCode === KEY_CODE_ESCAPE && !elPrevent) {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        const modalElement = document.getElementById('modal-root');
        const elPrevent = document.getElementById(idPreventClose);

        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            (modalElement ? !modalElement.contains(event.target) : true) &&
            !elPrevent
        ) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        if (isComponentVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
            document.addEventListener('click', handleClickOutside, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [isComponentVisible]);

    return [ref, isComponentVisible, setIsComponentVisible];
}
