import React, { useEffect, useReducer, useRef } from 'react';

import GdGridView from 'app/components/grid/GdGridView';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT } from 'app/const/Reports';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListColumnsReferral } from '../constant/columnReferral';
import { GET_LIST_REFERRAL_ACCOUNT } from 'app/const/api/V2';
import { handleAbortController } from 'app/modules/customer/utils';

const TableReferral = () => {
    const abortController = useRef();

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        isLoadingMore: false,
        data: [],
        cursor: null,
        limit: REPORT_LIMIT
    });
    const {
        isLoading: finalLoading,
        data: finalData,
        cursor: finalCursor,
        showMore: finalShowMore,
        isLoadingMore,
        limit
    } = state;

    useEffect(() => {
        getListReport({});

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (isLoadingMore) {
            setTimeout(() => {
                getListReport({ isLoadMore: true });
            }, 200);
        }
    }, [isLoadingMore]);

    const getListReport = ({ isLoadMore = false }) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        !isLoadMore && dispatchState((prev) => ({ ...prev, isLoading: true }));

        const _handleSuccess = ({ data, cursor, show_more }) => {
            dispatchState((prev) => ({
                ...prev,
                data: isLoadMore ? [...prev.data, ...data] : data,
                cursor,
                showMore: show_more,
                isLoading: false,
                isLoadingMore: false
            }));
        };

        const _handleFail = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(
            GET_LIST_REFERRAL_ACCOUNT,
            { method: 'GET', data: { limit, cursor: finalCursor }, abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleScroll = () => {
        if (!isLoadingMore && finalShowMore) {
            dispatchState((prev) => ({ ...prev, isLoadingMore: true }));
        }
    };

    return (
        <div className="container-print flex-1">
            <div className="wrap-tables flex-column relative">
                <GdGridView
                    isEmptyFlat
                    isLoading={finalLoading}
                    classTable="table-multi-column scrolls-x has-text-ellipsis"
                    content={finalData}
                    fileTranslation={'referral'}
                    {...getListColumnsReferral()}
                    isScroll
                    isLoadmore={isLoadingMore}
                    onScrollToEnd={_handleScroll}
                />
            </div>
        </div>
    );
};

export default TableReferral;
