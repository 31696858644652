import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import StatusBar from 'app/components/status/statusbar';
import { PREFIX_TITLE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { SMART_VIEWS, getSmartView } from 'app/const/api/V2';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { getSmartViewUrl } from '../utils';
import ShareOptions from './ShareOptions';
import { addBranchPath } from 'app/const/Branch';
import { DEFAULT_COLUMN_NAME } from '../table/utils';

const initialState = { isOpen: false, dataSmartView: {} };
const UpdateModal = forwardRef(({ onActionSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'smartView']);
    const history = useHistory();
    const { pathname } = useLocation();
    const urlId = pathname.split('/smart-view/')?.[1];
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isOpen, dataSmartView } = state;
    const refConfirmDelete = useRef(null);
    const refStatus = useRef(null);
    const refButtonSave = useRef(null);
    const refForm = useRef(null);
    const refOption = useRef(null);

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));

    const _handleOpen = (dataSmartView = {}) => {
        dispatchState((prevState) => ({ ...prevState, isOpen: true, dataSmartView }));
    };

    const _handleClose = () => {
        dispatchState(initialState);
    };

    const _handleChangeName = (e) => {
        if (e) e.preventDefault();
        const name = e.target.value.trim();
        if (name) refButtonSave.current?.setDisable(false);
        else refButtonSave.current?.setDisable(true);
    };

    const _handleSave = (e) => {
        try {
            e && e.preventDefault();
            refButtonSave.current?.setLoading(true);
            const { isEditing, id } = dataSmartView;
            const name = refForm.current.name.value.trim();

            if (!name) {
                refButtonSave.current?.removeLoading();
                refStatus.current?.showStatusBar('error', t('smartView:smart_view_name_required'), LIST_STATUS.ERROR);
                return;
            }

            if (isEditing && !id) throw new Error('Cannot edit smart view without an ID');

            const _success = ({ data }) => {
                const columns = data?.definition?.columns || DEFAULT_COLUMN_NAME;
                onActionSuccess({
                    type: isEditing ? 'update' : 'create',
                    data: isEditing ? data : { ...data, definition: { query: null, sort: [], limit: null, columns } }
                });
                _handleClose();

                if (isEditing) {
                    if (urlId === data.id) document.title = `${PREFIX_TITLE} - ${data.name}`;
                } else {
                    history.push(addBranchPath(getSmartViewUrl(data.id)), {
                        name: data.name,
                        definition: {
                            query: null,
                            sort: [],
                            columns,
                            limit: null
                        }
                    });
                }
            };
            const _fail = ({ message }) => {
                refStatus.current?.showStatusBar(
                    'error',
                    message || t('smartView:save_smart_view_fail'),
                    LIST_STATUS.ERROR
                );
                refButtonSave.current?.removeLoading();
            };

            const shareOption = refOption.current?.getValue() || {};
            clientQuery(
                isEditing ? getSmartView(id) : SMART_VIEWS,
                { data: { name, ...shareOption }, method: isEditing ? 'PUT' : 'POST', toFormData: false },
                _success,
                _fail
            );
        } catch (error) {
            console.error(error);
        }
    };

    const _handleDelete = () => {
        refConfirmDelete.current?._open({ id: dataSmartView.id });
    };
    const _handleCloseConfirmDelete = () => {
        refConfirmDelete.current?._close();
    };

    const _handleConfirmDelete = () => {
        const idSmartView = dataSmartView.id;
        const _deleteSuccess = () => {
            onActionSuccess({ type: 'delete', data: { id: idSmartView } });
            _handleCloseConfirmDelete();
            _handleClose();
        };
        const _deleteFail = ({ message }) => {
            _handleCloseConfirmDelete();
            refStatus.current.showStatusBar(
                'error',
                message || t('smartView:delete_smart_view_fail'),
                LIST_STATUS.ERROR
            );
        };

        clientQuery(getSmartView(idSmartView), { data: {}, method: 'DELETE' }, _deleteSuccess, _deleteFail);
    };

    const _handleAfterOpen = () => {
        if (!dataSmartView?.name?.trim()) refButtonSave.current?.setDisable(true);
    };

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                onAfterOpen={_handleAfterOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal open modal-edit-smartview"
                ariaHideApp={false}
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h2 className="header-modal__label fs-17 fw-600">
                            {dataSmartView.isEditing
                                ? t('smartView:title_edit_smart_view')
                                : t('smartView:title_add_smart_view')}
                        </h2>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                            <IconClose />
                        </div>
                    </div>

                    <form ref={refForm} className="body-modal" onSubmit={_handleSave}>
                        <StatusBar ref={refStatus} />
                        <div className="row">
                            <span className="txt fs-14">{t('name')}</span>
                            <input
                                className="field-input"
                                type="text"
                                name="name"
                                autoComplete="off"
                                defaultValue={dataSmartView.name || ''}
                                placeholder={t('name')}
                                onChange={_handleChangeName}
                                autoFocus
                            />
                        </div>
                        <ShareOptions
                            ref={refOption}
                            isEditing={dataSmartView.isEditing}
                            createdBy={dataSmartView?.created_by || ''}
                            defaultValue={{
                                shareOption: dataSmartView?.share_option || null,
                                sharedWith: dataSmartView?.shared_with || []
                            }}
                        />
                    </form>
                    <div className="footer-modal">
                        {dataSmartView.isEditing ? (
                            <div className="v2-btn-default has-icon --delete --transparent" onClick={_handleDelete}>
                                <IconTrash />
                                {t('delete')}
                            </div>
                        ) : null}
                        <div className="flexcenter justify-end flex-1">
                            <div className="v2-btn-default --transparent" onClick={_handleClose}>
                                {t('cancel')}
                            </div>
                            <ButtonSave ref={refButtonSave} onSave={_handleSave} />
                        </div>
                    </div>
                </div>
            </ReactModal>
            <GDModalWarning
                ref={refConfirmDelete}
                buttonConfirm
                modalClassName="modal container-modal modal-confirm-delete open"
                headerClassName="header-modal"
                subDescription={t('smartView:confirm_delete_smart_view_desc')}
                title={t('smartView:confirm_delete_smart_view_title')}
                descriptionClassName="text"
                footer={
                    <div className="footer-modal btn-close">
                        <span className="v2-btn-default --noborder --label" onClick={_handleCloseConfirmDelete}>
                            {t('cancel')}
                        </span>
                        <ButtonSave className="v2-btn-main" onSave={_handleConfirmDelete}>
                            {t('confirm')}
                        </ButtonSave>
                    </div>
                }
            />
        </>
    );
});

export default UpdateModal;
