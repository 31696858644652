import React, { useReducer } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'common/hooks/useClickOutside';

import { AVATAR_DEFAULT } from 'app/const/App';
import GdLazyImage from 'app/components/lazyimage';
import DropdownProfile from 'app/modules/profile/index';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { getAvatarCustomer } from 'common/utils/StringUtils';
import { reducer } from 'app/const/Reducer';
import { getAvtColorBranch } from 'app/const/Branch';

function Profile({ isExpired = false }) {
    const { t } = useTranslation(['common']);

    const [refMenu, isMenuVisible, setIsMenuVisible] = useClickOutside(false);

    const { profile, company } = useSelector(({ auth }) => auth?.user) || {};

    const [state, dispatchState] = useReducer(reducer, {
        isShowBranchInfo: false
    });
    const { isShowBranchInfo } = state;
    const { avatar: userAvatar = AVATAR_DEFAULT, first_name = '' } = profile;

    const _handleChangeBranches = (branches = []) => {
        dispatchState({ isShowBranchInfo: branches.length > 1 });
    };

    const handleActiveMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    return (
        <div className={`v2-dropdown wrap-nav nav-user ${isMenuVisible && 'active'}`}>
            <div ref={refMenu} className="dropbtn v2-btn-default nav" onClick={handleActiveMenu}>
                <div className="avatar relative flexcenter">
                    <GdLazyImage
                        className="avt-img --lg"
                        src={userAvatar}
                        alt={t('common:avatar')}
                        width={32}
                        height={32}
                    />
                    <AvatarBranch isShowBranch={isShowBranchInfo} company={company} />
                </div>
                <LabelInfo isShowBranch={isShowBranchInfo} username={first_name} company={company} />
                <span className="arrow">
                    <IconDropUpDown />
                </span>
            </div>
            <div className="v2-dropdown__menu user-content --right scrolls">
                <DropdownProfile
                    isExpired={isExpired}
                    isVisible={isMenuVisible}
                    isShowBranchInfo={isShowBranchInfo}
                    onChangeBranches={_handleChangeBranches}
                />
            </div>
        </div>
    );
}

export default Profile;

const LabelInfo = ({ isShowBranch = false, username = '', company = {} }) => {
    if (!isShowBranch) return <span className="nav__label">{username}</span>;

    const { is_branch, branch, name } = company;
    return (
        <div className="nav__label flex-column">
            <p className="fs-13 fw-600 grey-extradark">{username}</p>
            <p className="fs-11 fw-500 grey-outer-space">{is_branch ? branch.name : name}</p>
        </div>
    );
};

const AvatarBranch = ({ isShowBranch = false, company = {} }) => {
    if (!isShowBranch) return null;

    const { branch, name: finalName } = company;
    const url = branch?.avatar;
    const { color: branchColor, name: branchName } = branch || {};

    return !!url ? (
        <img className="avt-img --lg" width={32} height={32} src={url || AVATAR_DEFAULT} alt="Branch Avatar" />
    ) : (
        <span className="avt --lg" style={{ '--branch-color': getAvtColorBranch({ color: branchColor }) }}>
            {getAvatarCustomer(branchName || finalName)}
        </span>
    );
};
