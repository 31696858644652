import loadable from '@loadable/component';
import { URL_EXPORT_LOCATIONS_WO_ACTIVE_JOBS } from 'app/const/api/Export';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGidColumns } from 'app/const/report/LocationsWoActiveJobs';
import { LOCATIONS_WO_ACTIVE_JOBS_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getListReportLocationsWoActiveJobsRequest } from 'common/redux/actions/reports/locationsWoActiveJobsAction';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

const ReportLocationsWoActiveJobs = () => {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refreshScreen: 0,
        refesh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        isLoading: true
    });
    const { refreshScreen } = dataReport;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS);
    useEffect(() => {
        getListReport(paramsReport);
    }, []);

    const getListReport = (params, notShowLoading = false) => {
        const checkItemChecked = dataReport.checkedItems.ids.length != 0;
        const checkShouldLoading = !notShowLoading && !dataReport.isLoading;

        if (checkItemChecked || checkShouldLoading) {
            const paramReducer = {};

            if (checkShouldLoading) {
                paramReducer.checkedItems = { is_check_all: false, ids: [] };
            }

            if (checkShouldLoading) {
                paramReducer.isLoading = true;
            }

            dispatchActionReport(paramReducer);
        }

        delete params?.columns;
        delete params?.currentPage;

        dispatch(
            getListReportLocationsWoActiveJobsRequest(
                params,
                (response) => getListSuccess(response, params),
                getListFailed
            )
        );
    };

    const getListSuccess = (response) => {
        const dataReducer = {};

        dataReducer.isLoading = false;
        dataReducer.data = response.data;
        dataReducer.refreshScreen = refreshScreen + 1;

        dispatchActionReport(dataReducer);
    };

    function getListFailed() {
        dispatchActionReport({ isLoading: false });
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') dispatchActionReport({ refesh: !dataReport.refesh });
    };

    const _handleUpdate = () => {
        getListReport(getLocalStorage(keyLocal));
    };

    function _renderHeaderRight() {
        const totalReport = dataReport.data.length;
        return (
            <Export
                title={t('report:records', { count: totalReport })}
                activePrint
                url={URL_EXPORT_LOCATIONS_WO_ACTIVE_JOBS}
                refresh={refreshScreen}
                params={paramsReport}
                isDisable={dataReport.isLoading}
                pageExport={LIST_EXPORT.EXPORT_REPORT_LOCATION_WO_ACTIVE}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={REPORT_TYPE.LOCATIONS_WO_ACTIVE_JOBS}
                            filters={LOCATIONS_WO_ACTIVE_JOBS_LIST_FILTER}
                            isLoading={dataReport.isLoading}
                            handleChangeFilter={handleChangeFilter}
                            handleUpdate={_handleUpdate}
                        />
                        <GdGridView
                            isEmptyFlat
                            isLoading={dataReport.isLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent=""
                            content={dataReport.data.filter((item) => !item.manualHide)}
                            fileTranslation={'report'}
                            showCheckBox={false}
                            {...getGidColumns(paramsReport?.columns)}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ReportLocationsWoActiveJobs;
