import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { SketchPicker } from 'react-color';
import { createPopper } from '@popperjs/core';
import { reducer } from 'app/const/Reducer';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconClose from 'assets/icon/IconClose';

const InputColor = ({ defaultColor = '#ad63da' }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, color: defaultColor });
    const { isVisible, color } = state;

    const refDropdown = useRef(null);
    const refReference = useRef(null);
    const refPopper = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => color,
        setValue: (color) => dispatchState((prev) => ({ ...prev, color }))
    }));

    useEffect(() => {
        if (isVisible) {
            createPopper(refReference.current, refPopper.current, {
                modifiers: { name: 'offset', options: { offset: [0, 3] } },
                strategy: 'fixed'
            });
        }
    }, [isVisible]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    function handleClickOutside(event) {
        const elPrevent = refPopper.current;

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function handleHideDropdown(event) {
        const elPrevent = refPopper.current;
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    const _closeDropdown = () => {
        isVisible && dispatchState({ isVisible: false });
    };

    const _handleChangeColor = (color) => {
        dispatchState({ color: color.hex });
    };

    const _handleFocus = () => {
        dispatchState({ isVisible: true });
    };

    return (
        <div ref={refDropdown} className="wrap-rows flextop border-bottom-line">
            <div className="wrap-rows__icons flex-auto">
                <div className="is-dots cursor-pointer" style={{ backgroundColor: color }} onClick={_handleFocus} />
            </div>
            <div className="wrap-rows__details flex-1">
                <input
                    ref={refReference}
                    type="text"
                    className="field-control"
                    value={color.toUpperCase()}
                    onFocus={_handleFocus}
                    spellCheck
                    readOnly
                />
                {isVisible && (
                    <div ref={refPopper} className="choose-color" style={{ zIndex: 9 }}>
                        <div className="color-picker color-picker__inactive">
                            <div className="color-picker__close">
                                <div className="v2-btn-default btn-bg-grey --icon-sm" onClick={_closeDropdown}>
                                    <IconClose />
                                </div>
                            </div>
                            <SketchPicker disableAlpha color={color} onChange={_handleChangeColor} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default forwardRef(InputColor);
