import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

const ModalTooltip = (props, ref) => {
    const { t } = useTranslation('addons');

    const [state, dispatchState] = useReducer(reducer, { isVisible: false });

    const { isVisible } = state;

    useImperativeHandle(ref, () => ({
        changeVisible: _handleChange
    }));

    const _handleChange = () => {
        dispatchState((prev) => ({ isVisible: !prev.isVisible }));
    };

    if (!isVisible) return null;

    return (
        <div className="wrap-container__preview flex-column is-open">
            <div className="header-modal">
                <h3 className="header-modal__label">{t('tooltip')}</h3>
                <div className="btn-action cursor-pointer" onClick={_handleChange}>
                    <IconClose />
                </div>
            </div>
            <div className="body-modal flex-column gap-6">
                <h3 className="fs-14 fw-600">{t('voip_credit_balance_title')}</h3>
                <div className="flex-column gap-4">
                    <p className="dots">{t('desc_local_numbers')}</p>
                    <p className="dots">{t('desc_toll_free_numbers')}</p>
                    <p>{t('desc_set_up_your_credit')}</p>
                    <p className="mt-4">{t('desc_credit_notice')}</p>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(ModalTooltip);
