import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { ACCESS_TOKEN, COMMON } from 'app/const/App';
import moment from 'moment';
import {
    URL_REPORT_ACCOUNTS_AGING_PDF_STATEMENT,
    URL_REPORT_ACCOUNTS_AGING_PRINT_STATEMENT
} from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getBranchId } from 'app/const/Branch';

function batchActionStatement(data) {
    const token = getLocalStorageValue(ACCESS_TOKEN);
    const form = document.createElement('form');
    const temp_id = moment().format('x');
    const paramsExport = data.paramsExport;
    const columns = paramsExport.columns.map((column) => {
        return `statement_${column}`;
    });
    const idList = data.ids.toString();
    const outputFinal = data.output || '';
    form.method = 'POST';
    form.id = temp_id;
    form.style.display = 'none';
    form.action =
        data.typeExport === COMMON.PRINT
            ? URL_REPORT_ACCOUNTS_AGING_PRINT_STATEMENT
            : URL_REPORT_ACCOUNTS_AGING_PDF_STATEMENT;

    switch (data.typeBatchAction) {
        case LIST_EXPORT.EXPORT_ACCOUNT_AGING_BATCH_ACTION:
            form.innerHTML = `
                <input name="token" value="${token}">
                <input name="customer_id" value="${idList.toString()}">
                <input name="location_id" value="${data.idsLocation.toString()}">
                <input name="to_date" value="${paramsExport.period_ending}">
                <input name="ids" value="${idList.toString()}">
                <input name="output" value="${outputFinal}">
            `;
            break;
        case LIST_EXPORT.EXPORT_CUSTOMER_STATEMENT_BATCH_ACTION:
            form.innerHTML = `
                <input name="token" value="${token}">
                <input name="invoice_type" value="${paramsExport.type}">
                <input name="from_date" value="${paramsExport.start}">
                <input name="to_date" value="${paramsExport.end}">
                <input name="statement_type" value="${paramsExport.statement_type}">
                <input name="columns" value="${columns}">
                <input name="customer_id" value="${idList}">
                <input name="location_id" value="${paramsExport.location_ids}">
                <input name="ids" value="${idList}">
                <input name="output" value="${outputFinal}">
            `;
            break;
        default:
            break;
    }
    form.innerHTML += `<input name="gd-branch-id" value="${getBranchId()}">`;
    form.setAttribute('target', '_blank');
    document.body.append(form);
    form.submit();
    const elements = document.getElementById(temp_id);
    elements.parentNode.removeChild(elements);
}

export { batchActionStatement };
