export default () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 16C17.4051 16 17.7915 15.9197 18.144 15.7742L19.151 17.4655C17.5063 19.6142 14.915 21 12 21C11.466 21 10.9429 20.9535 10.4345 20.8643L10.035 18.8167C11.1819 18.3951 12 17.2931 12 16C12 15.8048 11.9813 15.6139 11.9457 15.429L14.4053 14.5068C14.9249 15.3997 15.8924 16 17 16ZM9 19L9.051 18.998L9.36531 20.6082C7.38966 20.0043 5.70282 18.7389 4.56298 17.0701L6.14637 16.9282C6.53726 18.1308 7.66712 19 9 19ZM20.822 10.2101C20.9387 10.7886 21 11.3871 21 12C21 13.6596 20.5508 15.2143 19.7673 16.5491L18.9911 15.2441C19.61 14.6945 20 13.8928 20 13C20 12.412 19.8308 11.8635 19.5385 11.4006L20.822 10.2101ZM5.1542 6.15722L7.12175 7.15124C7.04252 7.42036 7 7.70522 7 8C7 9.35655 7.90038 10.5028 9.13613 10.8738L8.87 13.004L8.82373 13.0051C7.27001 13.0951 6.03351 14.3676 6.00067 15.936L3.99461 16.1168C3.3589 14.8831 3 13.4834 3 12C3 9.77002 3.81102 7.72947 5.1542 6.15722ZM12.1174 10.1252L14.302 11.6866C14.1086 12.0833 14 12.5289 14 13C14 13.1949 14.0186 13.3854 14.0541 13.57L11.5941 14.4922C11.2153 13.8419 10.5989 13.3469 9.86387 13.1262L10.129 10.996L10.1763 10.9949C10.9326 10.9511 11.6137 10.6271 12.1174 10.1252ZM12 3C15.963 3 19.3278 5.56143 20.529 9.11906L18.8705 10.6544C18.3576 10.2448 17.7074 10 17 10C16.174 10 15.426 10.3338 14.8835 10.8738L12.698 9.31343C12.8914 8.91675 13 8.47105 13 8C13 6.77137 12.2614 5.71525 11.204 5.2514L11.837 3.002L12 3ZM10.7741 3.08279L10.2328 5.0089C10.156 5.003 10.0783 5 10 5C8.99496 5 8.10534 5.49422 7.56094 6.2529L5.87919 5.40179C7.20265 4.17351 8.89625 3.33854 10.7741 3.08279Z"
            fill="#C1C9D4"
        />
        <path
            d="M10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10Z"
            fill="#7A83A6"
        />
        <path
            d="M17 15C18.1046 15 19 14.1046 19 13C19 11.8954 18.1046 11 17 11C15.8954 11 15 11.8954 15 13C15 14.1046 15.8954 15 17 15Z"
            fill="#7A83A6"
        />
        <path
            d="M9 18C10.1046 18 11 17.1046 11 16C11 14.8954 10.1046 14 9 14C7.89543 14 7 14.8954 7 16C7 17.1046 7.89543 18 9 18Z"
            fill="#7A83A6"
        />
    </svg>
);
