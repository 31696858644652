export const TYPE_REPORT_ACCOUNTS_AGING = {
    GET_LIST_REPORT_ACCOUNTS_AGING_REQUEST: 'GET_LIST_REPORT_ACCOUNTS_AGING_REQUEST',
    PRINT_INVOICE: 'PRINT_INVOICE_REPORT_ACCOUNTS_AGING',
    GENERATE_INVOICE_PDF: 'GENERATE_INVOICE_PDF_REPORT_ACCOUNTS_AGING',
    EMAIL_LATE_PAYMENT_REMINDERS: 'EMAIL_LATE_PAYMENT_REMINDERS_REPORT_ACCOUNTS_AGING',
    SMS_LATE_PAYMENT_REMINDERS: 'SMS_LATE_PAYMENT_REMINDERS_REPORT_ACCOUNTS_AGING',
    EMAIL_STATEMENTS: 'EMAIL_STATEMENTS_REPORT_ACCOUNTS_AGING'
};

export const getListReportAccountsAgingRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.GET_LIST_REPORT_ACCOUNTS_AGING_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const printInvoices = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.PRINT_INVOICE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const generateInvoicesPdf = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.GENERATE_INVOICE_PDF,
        params,
        actionSuccess,
        actionFailed
    };
};

export const emailLatePaymentReminders = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.EMAIL_LATE_PAYMENT_REMINDERS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const smsLatePaymentReminders = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.SMS_LATE_PAYMENT_REMINDERS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const emailStatements = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_REPORT_ACCOUNTS_AGING.EMAIL_STATEMENTS,
        params,
        actionSuccess,
        actionFailed
    };
};
