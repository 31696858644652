import { WORK_LOGS_ACTIONS } from '../../actions/worklogs';

const initialState = { isFirstTime: true, users: [] };
const worklogReducer = (state = initialState, action) => {
    switch (action.type) {
        case WORK_LOGS_ACTIONS.GET_LIST_USERS_SUCCESS:
        case WORK_LOGS_ACTIONS.GET_LIST_USERS_FAIL:
            return { ...state, isFirstTime: false, users: action.payload || [] };
        case WORK_LOGS_ACTIONS.UPDATE_LIST_WORKLOGS_USERS_SUCCESS:
            return {
                ...state,
                users: [...(state.users || [])].map((item) =>
                    item.id === action.payload.id ? { ...item, ...action.payload } : item
                )
            };
        default:
            return state;
    }
};
export default worklogReducer;
