import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import moment from 'moment';
import { DEFAULT_VALUE, INVOICE_ITEM_SIMPLE } from 'app/modules/jobdetail/const/Invoice';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { createEstimate } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ReactModal from 'react-modal';

import InvoiceInfo from 'app/modules/jobdetail/tabs/addinvoice/form/info';
import InvoiceService from 'app/modules/jobdetail/tabs/addinvoice/form/items';
import InvoiceSummary from 'app/modules/jobdetail/tabs/addinvoice/form/summary';
import InvoiceTerms from 'app/modules/jobdetail/tabs/addinvoice/form/terms';
import InvoiceNotes from 'app/modules/jobdetail/tabs/addinvoice/form/notes';
import CustomerInfo from 'app/modules/quickadd/invoice/CustomerInfo';
import AddInvoiceContextProvider from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import StatusBar from 'app/components/status/statusbar';
import ButtonSave from 'app/components/button/ButtonSave';
import IconClose from 'assets/icon/IconClose';
import { useDispatch, useSelector } from 'react-redux';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { useEnterKeydownClick } from 'common/hooks';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddEstimate } from 'app/modules/mixpanel/MixpanelAddEstimate';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';

const NewEstimate = forwardRef(({ onAddSuccess = () => {}, defaultCustomer = null }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const refCustomer = useRef();
    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);
    const isVisible = state.isVisible;
    const dateRef = moment().toISOString();
    const dispatch = useDispatch();
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    // eslint-disable-next-line no-undef
    const defaultCustomerFinal = global.customerDetail || defaultCustomer;

    useEnterKeydownClick(isVisible);

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm
    }));

    const _handleOpenForm = () => {
        dispatchState({
            isVisible: true
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false
        });
    };

    const defaultData = {
        ...DEFAULT_VALUE,
        customer_job_id: null,
        customer_id: defaultCustomerFinal?.id || '',
        defaultCustomer: defaultCustomerFinal,
        location_id: null,
        locationInfo: {},
        date: dateRef,
        items: [
            {
                ...INVOICE_ITEM_SIMPLE,
                id: new Date().getTime().toString()
            }
        ],
        isAddInternal: !!defaultCustomer,
        type: ESTIMATE_TYPE.BASIC,
        packages: []
    };

    const _handleError = (msg) => {
        refStatusBar.current.showStatusBar(
            'show_error',
            msg || 'One or more of your estimate fields are not complete.',
            LIST_STATUS.ERROR
        );
        _handleRemoveLoading();
        return false;
    };

    const _checkDeposit = (depositValue, total, packageId) => {
        const finalValueDeposit = parseFloat(depositValue.total.value);

        if (parseFloat(total) >= 0 && finalValueDeposit > parseFloat(total)) {
            document.getElementById(`deposit_cost_${packageId}`).classList.add('field-error');
            _handleError('The value of the deposit cannot exceed the total value of the estimate.');
            return false;
        }
        return true;
    };

    function _handleSaveEstimate() {
        const refValue = refCustomer.current.getValue();
        const itemsSubmit = [];
        const { location_id: locationId, type: estimateType, packages: estimatePackages, items: listItems } = refValue;
        const finalDeposit = { ...(refValue.deposit || DEFAULT_VALUE.deposit) };

        let checkPass = true;

        if (!refValue.customer_id) {
            checkPass = false;
            const elm = document.getElementById('com_select_customer_info');
            elm && elm.classList.add('field-error');
        }

        if (!locationId) {
            refStatusBar.current.showStatusBar('show_error', 'Please select location', LIST_STATUS.ERROR);

            const elm = document.getElementById('location_id');
            elm && elm.classList.add('field-error');
            const elmClick = document.getElementById('location_id_trigger_click');
            elmClick && elmClick.click();
            _handleRemoveLoading();
            return false;
        }

        if (estimateType === ESTIMATE_TYPE.PACKAGES) {
            let checkPassDeposit = true;

            estimatePackages.every((itemPackage) => {
                const { id: packageId, name: packageName, deposit, total } = itemPackage;

                checkPassDeposit = _checkDeposit(deposit, total, packageId);

                if (!checkPassDeposit) {
                    return false;
                }

                if (!!!packageName) {
                    checkPass = false;
                    document.getElementById(`package_item_name_${packageId}`).classList.add('field-error');
                }

                if (!listItems.some((lineItem) => lineItem.package_id === packageId)) {
                    checkPass = false;
                    document.getElementById(`package_item_detail_${packageId}`).classList.remove('dp-hide');
                }

                return true;
            });

            if (!checkPassDeposit) {
                return false;
            }
        } else {
            if (!_checkDeposit(finalDeposit, refValue.total, '')) {
                return false;
            }
        }

        if (!checkPass) {
            return _handleError();
        }

        listItems.forEach((element) => {
            const checkItemCost = element.cost.value.length !== 0;
            const { item_id: serviceItemId, id: itemId, tax1: tax1Detail, tax2: tax2Detail } = element;

            if (checkItemCost && serviceItemId) {
                itemsSubmit.push({
                    item_id: serviceItemId,
                    name: element.name,
                    quantity: element.quantity,
                    cost: element.cost.value,
                    tax1_id: tax1Detail?.id || '',
                    tax2_id: tax2Detail?.id || '',
                    tax1_name: tax1Detail?.name || '',
                    tax2_name: tax2Detail?.name || '',
                    tax1_rate: tax1Detail?.rate || '',
                    tax2_rate: tax2Detail?.rate || '',
                    description: element.description,
                    one_time: element.one_time || 0,
                    type: element.type || '',
                    package_id: element.package_id || ''
                });
            } else {
                checkPass = false;
                if (!checkItemCost) {
                    document.getElementById(`service_item_cost_${itemId}`).classList.add('field-error');
                }
                if (!serviceItemId) {
                    document.getElementById(`service_item_detail_${itemId}`).classList.add('field-error');
                }
            }
        });

        if (!checkPass) {
            return _handleError();
        }

        const finalDiscount = { ...refValue.discount };
        delete finalDiscount.total;

        clientQuery(
            createEstimate,
            {
                method: 'POST',
                data: {
                    customer_job_id: null,
                    customer_id: refValue.customer_id,
                    discount: finalDiscount,
                    deposit: finalDeposit,
                    number: refValue.number,
                    po_number: refValue.po_number,
                    date: refValue.date,
                    items: itemsSubmit,
                    subtotal: refValue.subtotal,
                    total: refValue.total,
                    location_id: locationId,
                    terms: refValue.terms,
                    note: refValue.note,
                    packages: estimatePackages,
                    type: estimateType
                }
            },
            _handleSaveEstimateSuccess,
            _handleSaveEstimateFail
        );
    }

    function _handleRemoveLoading() {
        refButtonSave.current && refButtonSave.current.removeLoading();
    }

    const _handleSaveEstimateSuccess = ({ data }) => {
        handleTrackingEvent(mixpanelAddEstimate({ id: profileId }));
        onAddSuccess();
        dispatchState({
            isVisible: false
        });
        _handleRemoveLoading();
        dispatch(
            actionOpenEstimate({
                id: data.id,
                status: data.status
            })
        );
    };

    const _handleSaveEstimateFail = (response) => {
        refStatusBar.current &&
            refStatusBar.current.showStatusBar(
                'show_error',
                response?.message || 'Pleast try again',
                LIST_STATUS.ERROR
            );
        _handleRemoveLoading();
    };

    const _handleChangeLocation = (value) => {
        refButtonSave.current?.setDisable(value);
    };

    function _handleTriggerButtonSave(value) {
        refButtonSave.current.setDisable(value);
    }

    if (!isVisible) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal --new-job container-modal open"
            onRequestClose={_handleCloseForm}
            ariaHideApp={false}
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">New Estimate</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseForm} tabIndex="0">
                        <IconClose />
                    </div>
                </div>

                <StatusBar ref={refStatusBar} />

                <div
                    className="body-modal form-tabpane-details scrolls c-job-details tab-conts form-add-estimate"
                    id="tab_inoive_detail_setting"
                >
                    <div className="container-column form-edit-template has-form elm-parent">
                        <AddInvoiceContextProvider
                            onUpdateButtonSave={_handleTriggerButtonSave}
                            invoiceData={defaultData}
                            onChangeLocation={_handleChangeLocation}
                        >
                            <div className="rows --header-info">
                                <div className="content-elm-edit customer-info">
                                    <CustomerInfo isInvoice={false} ref={refCustomer} />
                                    <InvoiceInfo isInvoice={false} />
                                </div>
                            </div>

                            <InvoiceService isInvoice={false} />
                            <InvoiceSummary isInvoice={false} isAddPayment={false} />

                            <div className="content-elm-edit rows note-details">
                                <div className="dashboard-wrapper --main">
                                    <div className="content-top-notes">
                                        <InvoiceTerms isInvoice={false} />
                                        <InvoiceNotes isInvoice={false} />
                                    </div>
                                </div>
                            </div>
                        </AddInvoiceContextProvider>
                    </div>
                </div>
                <div className="footer-modal btn-close">
                    <span onClick={_handleCloseForm} className="v2-btn-default --noborder --label" tabIndex="0">
                        Cancel
                    </span>
                    <ButtonSave onSave={_handleSaveEstimate} ref={refButtonSave} title={'Add'} />
                </div>
            </div>
        </ReactModal>
    );
});

export default NewEstimate;
