import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';
import AdditionalContactsModal from './AdditionalContactsModal';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const AdditionalContacts = (props, ref) => {
    const { updateContext } = useContext(AddCustomerContext);
    const [state, dispatchState] = useReducer(reducer, { data: [] });
    const { data } = state;
    const refAdditionalContact = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: () => data }));

    const _handleAddAdditionalContact = () => {
        refAdditionalContact.current._open();
    };

    const _handleUpdateAdditionalContact = (dataUpdate) => {
        refAdditionalContact.current._open({
            ...dataUpdate,
            phones: dataUpdate?.phones?.length ? dataUpdate?.phones : null
        });
    };

    const _handleAddContactSuccess = (newContact) => {
        dispatchState({ data: [...data, newContact] });
        updateContext(newContact, ADD_CUSTOMER_CONTEXT_TYPES.ADD_ADDITIONAL_CONTACT);
    };

    const _handleUpdateContactSuccess = (dataUpdate) => {
        const newData = [...data].map((item) => (dataUpdate.id === item.id ? dataUpdate : item));
        dispatchState({ data: newData });
        updateContext(newData, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_ADDITIONAL_CONTACT);
    };

    const _handleDeleteContact = (id) => {
        dispatchState({ data: data.filter((item) => item.id !== id) });
        updateContext(id, ADD_CUSTOMER_CONTEXT_TYPES.DELETE_ADDITIONAL_CONTACT);
    };

    return (
        <>
            <ListContacts data={data} onUpdate={_handleUpdateAdditionalContact} onDelete={_handleDeleteContact} />
            <div
                className="v2-btn-main --bg-green has-icon svg-white btn-modal"
                onClick={_handleAddAdditionalContact}
                tabIndex="0"
            >
                <IconPlus />
                Add Contact
            </div>
            <AdditionalContactsModal
                ref={refAdditionalContact}
                onUpdate={_handleUpdateContactSuccess}
                onDelete={_handleDeleteContact}
                onAdd={_handleAddContactSuccess}
            />
        </>
    );
};

const ListContacts = ({ data = [], onUpdate = () => {}, onDelete = () => {} }) => {
    const renderListPhone = (phones = []) => {
        if (!phones.length) return null;
        return <p className="dots">{formatPhoneNumberVoip(phones[0].phone)}</p>;
    };

    return data.map((item) => {
        return (
            <div key={item.id} className="has-many-field__items contact-field">
                <div className="contact-field__info">
                    <div className="valid-name">
                        <div
                            className="valid-name__title --purple cursor-pointer"
                            onClick={() => onUpdate(item)}
                            tabIndex="0"
                        >
                            {item.first_name} {item.last_name}
                        </div>
                        {item?.title ? <div className="label-content">{item.title}</div> : null}
                    </div>
                    {renderListPhone(item.phones)}
                    {item.email && <p className="dots">{item.email}</p>}
                </div>
                <div className="v2-btn-default --delete --icon-sm" onClick={() => onDelete(item.id)} tabIndex="0">
                    <IconTrash />
                </div>
            </div>
        );
    });
};

ListContacts.displayName = 'ListContacts';
export default forwardRef(AdditionalContacts);
