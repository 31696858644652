import {
    ColumnCustomer,
    ColumnDefault,
    ColumnDisplayTags,
    ColumnInvoiceStatus,
    ColumnStatus,
    ColumnTechAssigned
} from '../components/listView/Cells';

export const DEFAULT_COLUMNS_LIST_VIEW = [
    {
        key: 'job_status',
        label: 'Job Status',
        className: 'col col-md job-status',
        keyValue: 'status',
        cell: ColumnStatus,
        header: null,
        isDefault: true
    },
    {
        key: 'calendar_time',
        label: 'Calendar Time',
        className: 'col col-md-plus',
        labelClassName: 'col-label fw-600',
        keyValue: 'calendar_time',
        cell: ColumnDefault,
        header: ColumnDefault,
        isDefault: true
    },
    {
        key: 'customer_name',
        label: 'Customer Name',
        className: 'col col-xl',
        labelClassName: 'purple-default',
        keyValue: 'customer',
        cell: ColumnCustomer,
        header: ColumnDefault,
        isDefault: true
    },

    {
        key: 'customer_company_name',
        label: 'Company Name',
        className: 'col col-md',
        keyValue: 'customer.company_name',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'emoji',
        label: 'Emoji',
        className: 'col col-xs',
        labelClassName: 'emoji-content',
        keyValue: 'emoji',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'service_type',
        label: 'Service type',
        className: 'col col-sm-plus',
        keyValue: 'name',
        cell: ColumnDefault,
        header: ColumnDefault,
        isDefault: true
    },
    {
        key: 'service_address',
        label: 'Service address',
        className: 'col col-xl',
        keyValue: 'location.service_address',
        cell: ColumnDefault,
        header: ColumnDefault,
        isDefault: true
    },
    {
        key: 'date_and_time',
        label: 'Date and time',
        className: 'col col-date',
        keyValue: 'date_and_time',
        cell: ColumnDefault,
        header: ColumnDefault,
        isDefault: true
    },
    {
        key: 'tech_assigned',
        label: 'Tech assigned',
        className: 'col col-xl --feedback',
        keyValue: 'schedule_agenda',
        cell: ColumnTechAssigned,
        header: ColumnDefault,
        isDefault: true
    }
];

export const COLUMNS_LIST_VIEW = [
    {
        key: 'account_number',
        label: 'Account Number',
        className: 'col col-md',
        keyValue: 'customer.account_number',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'balance',
        label: 'Balance',
        keyValue: 'customer.balance',
        className: 'col col-md-plus',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'billing_address',
        label: 'Billing Address',
        className: 'col col-xl',
        keyValue: 'location.billing_address',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'check_in_time',
        label: 'Check In Time',
        className: 'col col-md',
        keyValue: 'check_in_time',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'check_out_time',
        label: 'Check Out Time',
        className: 'col col-md',
        keyValue: 'check_out_time',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'created_by',
        label: 'Created By',
        className: 'col col-md',
        keyValue: 'created_by',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'customer_tags',
        label: 'Customer Tags',
        className: 'col --tags',
        keyValue: 'customer.tags',
        cell: ColumnDisplayTags,
        header: ColumnDefault
    },
    // {
    //     key: 'customer_email',
    //     label: 'Email',
    //     className: 'col col-email',
    //     keyValue: 'customer.email',
    //     cell: ColumnDefault,
    //     header: ColumnDefault
    // },
    {
        key: 'invoice_number',
        label: 'Invoice #',
        className: 'col col-md',
        keyValue: 'invoice.invoice_number',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'invoice_total',
        label: 'Invoice Total/Value',
        className: 'col col-md-plus',
        keyValue: 'invoice.total',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'invoice_status',
        label: 'Invoice Status',
        keyValue: 'invoice.status',
        className: 'col col-md-plus',
        cell: ColumnInvoiceStatus,
        header: ColumnDefault
    },
    {
        key: 'last_service_date',
        label: 'Last Service Date',
        keyValue: 'last_service_date',
        className: 'col col-md-plus',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'location_name',
        label: 'Location Name',
        className: 'col col-md',
        keyValue: 'location.name',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'location_notes',
        label: 'Location Notes',
        className: 'col col-note',
        keyValue: 'location.note',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'location_tags',
        label: 'Location Tags',
        className: 'col --tags',
        keyValue: 'location.tags',
        cell: ColumnDisplayTags,
        header: ColumnDefault
    },
    // {
    //     key: 'customer_phone',
    //     label: 'Phone',
    //     className: 'col col-phone',
    //     keyValue: 'location.note',
    //     cell: ColumnDefault,
    //     header: ColumnDefault
    // },
    {
        key: 'service_city',
        label: 'Service City',
        className: 'col col-sm',
        keyValue: 'location.city',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'service_address_state',
        label: 'Service Address State',
        className: 'col col-xl',
        keyValue: 'location.state',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'service_country',
        label: 'Service Country',
        className: 'col col-md',
        keyValue: 'location.service_country',
        cell: ColumnDefault,
        header: ColumnDefault
    },
    {
        key: 'service_zip',
        label: 'Service Zip',
        className: 'col col-sm',
        keyValue: 'location.zip',
        cell: ColumnDefault,
        header: ColumnDefault
    }
    // {
    //     key: 'source',
    //     label: 'Source',
    //     className: 'col col-sm',
    //     keyValue: 'location.note',
    //     cell: ColumnDefault,
    //     header: ColumnDefault
    // },
    // {
    //     key: 'wo_tax',
    //     label: 'W/O Tax',
    //     className: 'col col-md-plus',
    //     keyValue: 'location.note',
    //     cell: ColumnDefault,
    //     header: ColumnDefault
    // }
];
