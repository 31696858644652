import classNames from 'classnames';
import { useRef, useState } from 'react';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';

const DEFAULT_SELECT = { name: '00', value: 0 };

const DropdownTime = ({
    id,
    unit,
    subText = '',
    defaultValue = DEFAULT_SELECT,
    options = null,
    wrapperClassName = 'from-time',
    buttonClassName = 'dropbtn v2-btn-default',
    timeTextClassName = 'time-text',
    wrapperDropdownClassName = '',
    onSelect = () => {}
}) => {
    const [selected, setSelected] = useState(defaultValue);
    const refDropdown = useRef(null);

    const _handleSelect = (value) => {
        const newSelect = options.find((option) => option.value === value);
        setSelected(newSelect);
        onSelect(unit, newSelect);
        refDropdown.current._closeDropdown();
    };

    const renderListItem = () => {
        return options.map((option) => {
            const optionValue = option.value;

            return (
                <li
                    key={optionValue}
                    onClick={() => _handleSelect(optionValue)}
                    className={classNames('items', { active: selected.value === optionValue })}
                >
                    {option.name} {optionValue !== 0 && optionValue !== 1 ? subText : subText.replace('s', '')}
                </li>
            );
        });
    };

    return (
        <div className={wrapperClassName}>
            <CalendarDropdown
                id={id}
                ref={refDropdown}
                buttonClassName={buttonClassName}
                keyGetValue="value"
                wrapperClassName={wrapperDropdownClassName}
                customDropButton={() => (
                    <>
                        <span>{selected.name}</span>
                        <span className={timeTextClassName}>{unit}</span>
                        <span className="ml-1 arrow">
                            <IconDropDown width={16} height={16} />
                        </span>
                    </>
                )}
            >
                <ul>{renderListItem()}</ul>
            </CalendarDropdown>
        </div>
    );
};

export default DropdownTime;
