import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import IconPlus from 'assets/icon/IconPlus';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { useSelector } from 'react-redux';
import { clientQuery } from 'common/utils/ApiUtils';
import { LIST_USER_VOIP } from 'app/const/api/Voip';

export const ActiveUserHeader = ({ users, account, onAddUser = () => {} }) => {
    const { t } = useTranslation('addons');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const { user_quantity: maxUser = 0 } = account || {};
    const isMaxUserActive = users.length >= maxUser;

    const options = () => {
        const listUser = companyUsers
            .filter((item) => !users.some((user) => user.id === item.id))
            .map((item) => {
                const { id, avatar, full_name } = item;
                return {
                    id,
                    label: (
                        <div className="user-name">
                            <div className="avt-img">
                                <img src={avatar} width={24} height={24} />
                            </div>
                            <span className="txt-ellipsis fw-500">{full_name}</span>
                        </div>
                    ),
                    value: { id, avatar, full_name }
                };
            });

        return !!listUser.length ? listUser : null;
    };

    const _renderAddUser = () => {
        return (
            <CalendarDropdown
                id="add_active_user"
                wrapperClassName="dropdown-add-user"
                customDropButton={() => (
                    <Fragment>
                        <IconPlus />
                        {t('add_user')}
                    </Fragment>
                )}
                buttonClassName="v2-btn-main has-icon svg-white"
                wrapperListClass="v2-dropdown__menu content-user scrolls"
                spanItemClassName="w-100"
                options={options()}
                keyGetName="label"
                keyGetKey="id"
                keyGetValue="value"
                onSelect={_handleClickItem}
                disable={isMaxUserActive}
            >
                <div className="grey-generic p-2 fs-13">{t('addons:user_is_empty')}</div>
            </CalendarDropdown>
        );
    };

    const _handleClickItem = ({ id, avatar, full_name }) => {
        const _handleSuccess = () => {
            onAddUser({ id, avatar, full_name });
        };

        clientQuery(LIST_USER_VOIP, { method: 'POST', toFormData: false, data: { user_id: id } }, _handleSuccess);
    };

    return (
        <div className="header flex-betweenitems align-center black pt-0">
            <p className="fw-600">{t('active_voip_users')}</p>
            <div className="flexcenter gap-8">
                <p className={classNames('fw-600', { 'orange-default': isMaxUserActive })}>
                    {t('available_seats', { available: `${users.length}/${maxUser}` })}
                </p>
                {_renderAddUser()}
            </div>
        </div>
    );
};
