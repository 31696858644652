/* eslint-disable no-unused-vars */
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';
import { CALENDAR_MODES, TIME_LINE_MODES, TIME_LINE_MODE_VIEW } from 'app/modules/calendar/const';
import moment from 'moment';

const calendarReducer = (
    state = {
        color: 1,
        view: 'agendaTwoWeeks',
        weekends: 1,
        schedules: [],
        horizontal_type: TIME_LINE_MODES[TIME_LINE_MODE_VIEW.SMALL]
    },
    action
) => {
    const newDateTime = moment().toISOString();

    switch (action.type) {
        case CALENDAR_ACTIONS.UPDATE_CALENDAR:
            const newStateCalendar = { ...state, ...(action.payload || {}) };
            return { ...newStateCalendar, date: newStateCalendar.date || newDateTime };
        case CALENDAR_ACTIONS.UPDATE_AGENDA:
            const newStateUpdateAgenda = { ...state, view: action.payload };

            if (action.payload === CALENDAR_MODES.DAY_GRID_MONTH && state.schedules.length > 1) {
                const schedulesUpdateAgenda = [...state.schedules];
                schedulesUpdateAgenda.length = 1;
                newStateUpdateAgenda.schedules = schedulesUpdateAgenda;
            }

            return { ...newStateUpdateAgenda, date: newStateUpdateAgenda.date || newDateTime };

        case CALENDAR_ACTIONS.UPDATE_SERVICE_COLOR:
        case CALENDAR_ACTIONS.UPDATE_SELECT_DATE:
        case CALENDAR_ACTIONS.UPDATE_SHOW_WEEKENDS:
        case CALENDAR_ACTIONS.UPDATE_TIME_LINE_VIEW:
        case CALENDAR_ACTIONS.UPDATE_SCHEDULING:
            const newStateUpdateData = { ...state, ...(action.payload || {}) };
            return { ...newStateUpdateData, date: newStateUpdateData.date || newDateTime };
        case CALENDAR_ACTIONS.UPDATE_COLUMNS_LIST_VIEW:
            return { ...state, columns: action.payload || '' };
        case CALENDAR_ACTIONS.UPDATE_SCHEDULES:
            const newSchedules = action.payload;
            return { ...state, schedules: newSchedules.length > 0 ? newSchedules : [...state.schedules] };
        default:
            return state;
    }
};

export default calendarReducer;
