import LoadingPhoneList from 'app/components/loading/sms/LoadingPhoneList';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

function HeaderPhoneList({ data = [], onSelectPhone = () => {}, loading = true }) {
    const { t } = useTranslation('header');
    const [isVisible, setIsVisible] = useState(false);
    const refDropdown = useRef(null);
    const refForm = useRef(null);
    const listPhoneSelected = data.filter((item) => item.is_active);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('list-customer-phone-sms');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('list-customer-phone-sms');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        isVisible && setIsVisible(false);
    }

    const _toggleDropdown = () => setIsVisible(!isVisible);

    const _onApply = () => {
        const result = [];
        const elements = [...(refForm.current.elements || [])];

        elements.forEach((element, index) => {
            if (element.type === 'checkbox') {
                result.push({ ...data[index], is_active: element.checked });
            }
        });

        onSelectPhone(result);
        _closeDropdown();
    };

    const _selectAll = () => {
        const elements = [...(refForm.current.elements || [])];
        const isAllChecked = !elements.some((element) => !element.checked);

        elements.forEach((element) => {
            if (element.type === 'checkbox') {
                element.checked = !isAllChecked;
            }
        });
    };

    const renderListPhone = () => {
        if (!data.length) {
            return (
                <li className={'items'}>
                    <span className="name"> {'Not found'} </span>
                </li>
            );
        }

        return data.map((item) => {
            const itemId = item.id;

            return (
                <label
                    key={itemId}
                    className={classNames('items', {
                        active: item.is_active
                    })}
                    htmlFor={itemId}
                >
                    <div className="check-items --no-affect">
                        <input id={itemId} type="checkbox" defaultChecked={item.is_active} />
                        <div className="item-checkbox">
                            <label htmlFor={itemId} />
                        </div>
                    </div>
                    <div className="avt">{item.avatar}</div>
                    <div className="info-name">
                        <div className="flexcenter">
                            <span className="info-name__elm name"> {item.name} </span>
                            <span className="info-name__elm phone">{formatPhoneNumberVoip(item.phone)}</span>
                        </div>
                        {item.title ? <div className="label-content ml-1">{item.title}</div> : null}
                    </div>
                </label>
            );
        });
    };

    if (loading) return <LoadingPhoneList />;

    return (
        <div ref={refDropdown} className={classNames('wrap-toggle-phone v2-dropdown', { active: isVisible })}>
            <div onClick={_toggleDropdown} className="dropbtn items group-tags --label">
                <div className="box-tags">
                    <PhoneListSelected data={listPhoneSelected} />
                    {listPhoneSelected.length > 4 && (
                        <div className="tag-label tag-more">+{listPhoneSelected.length - 4}</div>
                    )}
                </div>
                <div className="arrow">
                    <IconDropUpDown />
                </div>
            </div>
            {isVisible && (
                <div className="v2-dropdown__menu dropdown-list-friends">
                    {data.length > 0 && (
                        <div className="wrap-title-list">
                            <span onClick={_selectAll}>{t('select_all')}</span>
                            <div className="v2-btn-main" onClick={_onApply}>
                                {t('apply')}
                            </div>
                        </div>
                    )}
                    <form ref={refForm} className="list-user scrolls" id={'list-customer-phone-sms'}>
                        {renderListPhone()}
                    </form>
                </div>
            )}
        </div>
    );
}

const PhoneListSelected = ({ data = [] }) => {
    return data.map((phone, index) => {
        return (
            index < 4 && (
                <div key={phone.id} className="tag-label">
                    {phone.name}
                </div>
            )
        );
    });
};

export default HeaderPhoneList;
