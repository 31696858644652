export const TYPE_ESTIMATE = {
    ACTION_OPEN_ESTIMATE_DETAIL: 'ACTION_OPEN_ESTIMATE_DETAIL',
    ACTION_CLOSE_ESTIMATE_DETAIL: 'ACTION_CLOSE_ESTIMATE_DETAIL'
};

export const actionOpenEstimate = (params) => {
    return {
        type: TYPE_ESTIMATE.ACTION_OPEN_ESTIMATE_DETAIL,
        params
    };
};

export const actionCloseEstimate = (params) => {
    return {
        type: TYPE_ESTIMATE.ACTION_CLOSE_ESTIMATE_DETAIL,
        params
    };
};
