import { TYPES_VOIP } from '../actions/voipAction';

const voipReducer = (
    state = {
        data: null,
        dataDuration: null,
        newCall: null,
        triggerNumber: 0,
        dialers: []
    },
    action
) => {
    switch (action.type) {
        case TYPES_VOIP.TRIGGER_CALL_ACTION:
            return { ...state, data: action.params };
        case TYPES_VOIP.HAVE_NEW_CALL:
            return { ...state, newCall: action.params };
        case TYPES_VOIP.ACTION_TRIGGER_NUMBERS:
            return { ...state, triggerNumber: Date.now() };
        case TYPES_VOIP.GET_LIST_INDEX_DIALER:
            return { ...state, dialers: !state.dialers.length ? action.params : state.dialers };
        case TYPES_VOIP.CREATE_INDEX_DIALER:
            return { ...state, dialers: [...state.dialers, action.params] };
        case TYPES_VOIP.UPDATE_INDEX_DIALER:
            return {
                ...state,
                dialers: state.dialers.map((item) => ({
                    ...item,
                    call_index: item.id === action.params.id ? action.params.index : item.call_index
                }))
            };
        default:
            return state;
    }
};

export default voipReducer;
