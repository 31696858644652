import React from 'react';
import { useSelector } from 'react-redux';

import { formatTimeUnix, formatUnix } from 'common/utils/TimeUtils';
import TimerTotal from '../TimerTotal';

const ResourceTimeEdit = ({ isEditing = false, start = 0, end = 0, duration = 0, onBlur = () => {} }) => {
    const timezone = useSelector(({ auth }) => auth.user.settings.timezone);
    if (!isEditing) {
        return (
            <>
                <div className="col col-clock flexcenter gap-8">
                    <span className="txt-time black-jungle-green">{formatUnix(start, timezone)}</span>
                    <span className="txt">-</span>
                    <span className="txt-time black-jungle-green">{formatUnix(end, timezone)}</span>
                </div>
                <div className="col col-duration">
                    <span className="black-jungle-green fw-600">{formatTimeUnix(duration)}</span>
                </div>
            </>
        );
    }
    return <TimerTotal defaultInTime={start} defaultOutTime={end} defaultDuration={duration} onBlur={onBlur} />;
};

export default ResourceTimeEdit;
