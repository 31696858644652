import React, { useReducer, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { DEFAULT_ALL } from 'app/const/App';
import Checkbox from 'app/components/checkbox';
import UserNotify from 'app/modules/customer/note/components/UserNotify';
import IconPlus from 'assets/icon/IconPlus';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const Notify = forwardRef(({ haveEditNotify = true, defaultNotify = [] }, ref) => {
    const { t } = useTranslation(['common']);
    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        selected: defaultNotify || []
    });

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return state.selected.map((item) => {
                return {
                    ...item,
                    full_name: item.first_name.concat(' ', item.last_name)
                };
            });
        },
        resetSelected: _handleResetSelected
    }));

    const finalIsVisible = state.isVisible;
    const listSelected = state.selected;

    const refDropdown = useRef(null);

    const companyUsers = useSelector((state) => state.companyUsers.users);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function _handleResetSelected() {
        setState({
            ...state,
            selected: []
        });
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_user_notify');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_user_notify');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    };

    function _handleSelectOption(userSelected) {
        // NOTE: Don't do anything if not have edit notify block
        if (!haveEditNotify) return;
        let newSelected = listSelected.length === companyUsers.length ? companyUsers : [...listSelected];

        if (newSelected.find((item) => item.id === userSelected.id)) {
            newSelected = newSelected.filter((item) => item.id !== userSelected.id);
        } else {
            newSelected.push(userSelected);
        }

        setState({
            selected: newSelected
        });
    }

    const _handleSelectAll = (e) => {
        e.preventDefault();
        setState({
            selected: listSelected.length === companyUsers.length ? [] : companyUsers
        });
    };

    function _renderAvatar(userData) {
        if (userData?.avatar) {
            return <img src={userData.avatar} alt="" width="24" height="24" />;
        } else {
            return (
                <div className="avt fs-11">{`${userData.first_name.substring(0, 1)}${userData.last_name.substring(
                    0,
                    1
                )}`}</div>
            );
        }
    }

    function _renderListUser() {
        return companyUsers.map((item) => {
            const isActive = listSelected.find((itemCheck) => itemCheck.id === item.id);

            return (
                <UserNotify
                    key={item.id}
                    userDetail={item}
                    userId={item.id}
                    isActive={isActive}
                    handleSelect={_handleSelectOption}
                />
            );
        });
    }

    function _renderSelectAll() {
        const isCheckAll = listSelected === DEFAULT_ALL || companyUsers.length === listSelected.length;

        return (
            <div className="content-checked__all" onClick={_handleSelectAll}>
                <div className="items">
                    <div className="check-items">
                        <Checkbox
                            label={t('common:select_all')}
                            checked={isCheckAll}
                            id={`check-box-all`}
                            onChangeValue={_handleSelectAll}
                            value={listSelected?.length || 0}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function _renderUserSelected() {
        return listSelected.map((item) => {
            return (
                <li
                    key={item.id}
                    className="notify-items"
                    onClick={(e) => {
                        e.preventDefault();
                        _handleSelectOption(item);
                    }}
                >
                    <div className="avt-img">{_renderAvatar(item)}</div>
                    <span className="notify-items__name">{`${item.first_name} ${item.last_name}`}</span>
                </li>
            );
        });
    }

    return (
        <>
            {haveEditNotify ? (
                <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                    <div className="dropbtn v2-btn-default --icon-r --transparent" onClick={_handleOpen}>
                        {t('common:notify')} <IconPlus />
                    </div>
                    <div className="v2-dropdown__menu content-user content-full content-checked">
                        {_renderSelectAll()}
                        <ul className="scrolls" id={'show_list_user_notify'}>
                            {_renderListUser()}
                        </ul>
                    </div>
                </div>
            ) : null}
            <div className="list-notify">{_renderUserSelected()}</div>
        </>
    );
});

export default Notify;
