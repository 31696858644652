import { WS_NAMESPACES } from 'app/const/Socket';
import { useConnect } from 'common/utils/SocketUtils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function ServiceRealTimeQuickBook({ onAdd = () => {} }) {
    const { profile } = useSelector(({ auth }) => auth.user);
    const { response } = useConnect(WS_NAMESPACES.QUICKBOOKS, profile?.ws_room, 'status');

    useEffect(() => {
        response && onAdd(response);
    }, [response]);
    return false;
}
