import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'app/components/pagination';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconDropDown from 'assets/icon/IconDropDown';

function CustomerPagination({ currentPageSize, pageSize, currentPage, total, handleChangePageOrPageSize, children }) {
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        const totalPage = currentPageSize && total > 0 ? Math.ceil(total / currentPageSize) : 1;
        setTotalPage(totalPage);
    }, [total, currentPageSize]);

    const changePageOrPageSize = (pager) => {
        handleChangePageOrPageSize(pager);
    };

    return (
        <Pagination
            currentPageSize={currentPageSize}
            pageSize={pageSize}
            currentPage={currentPage}
            totalPage={totalPage}
            onChange={changePageOrPageSize}
            isCustomerPage
        >
            {children}
        </Pagination>
    );
}

export function CustomerPageSize({ pageSize, onChangePageSize, currentPageSize }) {
    const { t } = useTranslation(['common']);
    const refDropdown = useRef(null);

    const _renderListPageSize = () => {
        return pageSize.map((item) => {
            return (
                <li
                    key={item}
                    onClick={(e) => {
                        handleChangePageSize(e, parseInt(item));
                    }}
                    className={classNames('items', { active: item === currentPageSize })}
                >
                    <div className="txt-ellipsis">
                        {item} {t('common:per_page')}
                    </div>
                </li>
            );
        });
    };

    const handleChangePageSize = (e, value) => {
        e.preventDefault();
        onChangePageSize(value);
        refDropdown.current && refDropdown.current._close();
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isCalculateWidth
            wrapperClassName="v2-dropup"
            wrapperListClass="v2-dropdown__menu scrolls"
            placement="top-start"
            customButton={
                <>
                    {currentPageSize} {t('common:per_page')}
                    <span className="svg-selectbox">
                        <IconDropDown />
                    </span>
                </>
            }
            buttonClassName="dropbtn v2-btn-default selection justify-start"
            isUseToggle
        >
            <ul>{_renderListPageSize()}</ul>
        </DropdownPopper>
    );
}

CustomerPagination.propTypes = {
    currentPageSize: PropTypes.number,
    total: PropTypes.number
};

CustomerPagination.defaultProps = {
    total: 1
};

export default CustomerPagination;
