import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timelinePlugin from '@fullcalendar/timeline';

import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { TRACKER } from 'app/config/routes';
import { ERROR_CODE } from 'app/const/App';
import { addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { WORK_LOGS, workLogsUpdate } from 'app/const/api/V2';
import { FULL_CALENDAR_KEY } from 'app/modules/calendar/const/SettingCalendar';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { calculateDaysPassed } from 'common/utils/TimeUtils';
import { RESOURCE_EMPTY, RESOURCE_TYPES, TIME_LABEL_FORMAT, TRACKER_EVENT_COLOR } from '../../constant';
import {
    clearTracking,
    convertDataWorkLogs,
    convertWorklog,
    createNewParentLog,
    getTotalResource,
    labelClassName,
    setExtendedProps,
    trackerAlert,
    updateEventWithOverlap,
    updateOverlapEvents
} from '../../utils';
import EventContent from './EventContent';
import Loading from './Loading';
import ResourceAreaHeader from './ResourceAreaHeader';
import ResourceAreaRight from './ResourceAreaRight';
import ResourceLabelContent from './ResourceLabel';
import RowTotal from './RowTotal';

const TrackerCalendar = forwardRef((props, ref) => {
    const {
        start = '',
        end = '',
        userId = '',
        title = '',
        isShowTotalRow = true,
        isDisableEdit = false,
        isShouldCathError = true,
        isHideEdit = false,
        onSetTotal = () => {}
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const isTrackingPage = matchPath(pathname, { path: addPrefixPath(TRACKER), exact: true });

    const refCalendar = useRef(null);
    const refTotal = useRef(null);
    const refStoreEditing = useRef(null);
    const refResourceAreaRight = useRef(null);
    const refIdResourceEditing = useRef(null);

    const companyTimezone = useSelector((state) => state.auth.user.settings.timezone);
    const businessHours = useSelector(({ auth }) => auth.user.company.business_hours || {});
    const permissionEdit = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.edit);
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isRefetching: false,
        dataResources: [],
        events: [],
        total: {}
    });
    const { isLoading, isRefetching, dataResources, events, total } = state;

    useImperativeHandle(ref, () => ({
        setView: _handleSetView,
        setUser: _handleSetUser,
        addWorkLog: _handleAddWorkLog
    }));

    useEffect(() => {
        getWorkLogs();
    }, []);

    const _getLogsSuccess = ({ data = [], total }, callback = () => {}) => {
        callback();
        const { resources, events } = convertDataWorkLogs(data, companyTimezone);
        dispatchState({ dataResources: resources, events, total, isLoading: false, isRefetching: false });
        refTotal.current?.setTotal(total);
        onSetTotal(total);
        refResourceAreaRight.current?.forceReload();
    };

    const _getLogsFail = ({ message, statusCode }) => {
        trackerAlert(message);
        if (statusCode === ERROR_CODE.PERMISSION_DENIED && isShouldCathError) {
            if (isTrackingPage) history.replace(addPrefixPath('/'));
            clearTracking();
            dispatch(updateAddonStatus({ keyword: 'WORK_LOG', data: 0 }));
        }
    };

    const _handleSetView = ({ start, end, user_id }) => {
        dispatchState((prevState) => ({ ...prevState, isRefetching: true }));
        const payload = { start, end };
        if (user_id) payload['user_id'] = user_id;
        clientQuery(WORK_LOGS, { data: payload, method: 'GET' }, _getLogsSuccess);
    };

    const _handleSetUser = (user_id = '') => {
        if (!user_id) return;
        dispatchState((prevState) => ({ ...prevState, isRefetching: true }));
        clientQuery(WORK_LOGS, { data: { user_id, start, end }, method: 'GET' }, _getLogsSuccess, _getLogsFail);
    };

    const getWorkLogs = () => {
        const payload = { start, end };
        if (userId) payload['user_id'] = userId;
        clientQuery(WORK_LOGS, { data: payload, method: 'GET' }, _getLogsSuccess, _getLogsFail);
    };
    // Above functions are using with apis

    // Below functions are using with calendar
    const _handleGetResources = () => {
        if (!refCalendar.current) return;
        const calendarApi = refCalendar.current.getApi();
        return calendarApi.getTopLevelResources();
    };

    const _handleGetResourceById = (idResource = '') => {
        if (!refCalendar.current) return;
        const calendarApi = refCalendar.current.getApi();
        return calendarApi.getResourceById(idResource);
    };

    const _handleUpdateAndReload = (idResource = '') => {
        refIdResourceEditing.current = idResource;
        refResourceAreaRight.current?.forceReload();
    };

    const _handleUpdateTotal = () => {
        const newTotal = getTotalResource(refCalendar.current?.getApi()?.getTopLevelResources());
        refTotal.current?.setTotal(newTotal);
        onSetTotal(newTotal);
        refResourceAreaRight.current?.forceReload();
    };

    const _handleAddWorkLog = (data = {}) => {
        if (!refCalendar.current) return;
        let eventsAdd = [];
        const calendarApi = refCalendar.current.getApi();
        const newResource = { ...data };
        const parentResource = _handleGetResourceById(newResource.group_id);
        _handleGetResourceById('resource_empty')?.remove();

        // If parent resource is exist, add new resource to parent resource
        if (!!parentResource) {
            newResource['parentId'] = data.group_id;
            newResource['isSubItem'] = true;
            const { amount = 0, duration = 0, start = 0, end = 0 } = parentResource._resource.extendedProps || {};
            setExtendedProps(parentResource, {
                amount: amount + data.amount,
                duration: duration + data.duration,
                start: Math.min(start, data.start),
                end: Math.max(end, data.end)
            });

            const eventParent = convertWorklog(
                { ...data, props: { overlapEvents: data.overlap || [], parentId: data.group_id } },
                RESOURCE_TYPES.GROUP
            );

            eventsAdd = [...eventsAdd, eventParent];
        } else {
            let newResourceParent = null;
            // Step 1: Check if resource have resource same day
            const resourceSameDay = calendarApi.getResources().find((item) => {
                const extendedProps = item._resource.extendedProps;
                const extendedDate = moment.tz(extendedProps.date, companyTimezone);
                return extendedDate.isSame(moment.tz(newResource.date, companyTimezone), 'day' || false || null);
            });
            // Step 2: If resource same day is exist, update data of resource same day
            // after that add new resource for parent resource
            if (resourceSameDay && data.group_id) {
                const groupId = data.group_id || '';
                // Set data for resource same day
                resourceSameDay.setProp('parentId', groupId);
                setExtendedProps(resourceSameDay, { isSubItem: true, parentId: groupId, group_id: groupId });
                // Create data for new parent resource
                newResourceParent = createNewParentLog(data, resourceSameDay._resource.extendedProps);

                // Set data for new resource
                newResource['parentId'] = groupId;
                newResource['isSubItem'] = true;
            }

            // Step 3: If resource same day is not exist, add new resource
            if (newResourceParent) {
                const eventResource = convertWorklog(
                    { ...newResourceParent, props: { parentId: data.group_id } },
                    RESOURCE_TYPES.GROUP
                );

                calendarApi.getEventById(`${resourceSameDay.id}_${RESOURCE_TYPES.GROUP}`)?.remove();
                const resourceSameDayProps = resourceSameDay._resource.extendedProps;
                const dataEvent = { ...resourceSameDayProps, props: { parentId: data.group_id } };

                // Add new event for resource same day and new resource
                const eventSameDay = convertWorklog({ ...dataEvent, id: resourceSameDay.id }, RESOURCE_TYPES.CHILDREN);
                eventsAdd = [...eventsAdd, eventResource, eventSameDay];
                calendarApi.addResource(newResourceParent);
            }
        }

        const eventResource = convertWorklog(
            { ...newResource, props: { parentId: data.group_id || newResource.id } },
            newResource['isSubItem'] ? RESOURCE_TYPES.CHILDREN : RESOURCE_TYPES.GROUP
        );

        eventsAdd = [...eventsAdd, eventResource];
        calendarApi.addResource(newResource);
        eventsAdd.forEach((item) => calendarApi.addEvent(item));
        _handleUpdateTotal();
        _handleUpdateOverlapEvents(data.group_id || newResource.id);
    };

    const getObjectTime = (time, isStart = false) => {
        return { hours: time.get('hours'), minutes: time.get('minutes'), second: isStart ? 0 : time.get('seconds') };
    };

    // Update overlap events when update resource
    const _handleUpdateOverlapEvents = (group_id) => {
        const calendarApi = refCalendar.current.getApi();
        // Get all events from calendar
        const events = calendarApi.getEvents();
        const resources = calendarApi.getTopLevelResources();

        // Get all resources match with group_id
        const resourceUpdate = resources.find((item) => item.id === group_id);
        const resourceChildren = resourceUpdate?.getChildren() || [];

        let eventsMatch = [];
        let eventsGroup = [];
        // Get all events match with group_id
        events.forEach((item) => {
            if (/_group_group/.test(item.id)) {
                item.remove();
                return;
            }
            if (item.extendedProps.parentId === group_id || item.extendedProps.idMatch === group_id) {
                // If event is not group, add to array eventsMatch
                if (!/_group/.test(item.id) || !resourceChildren.length) eventsMatch = [...eventsMatch, item];
                // If event is group, add to array eventsGroup
                if (/_group/.test(item.id)) eventsGroup = [...eventsGroup, item];
            }
        });

        // Update overlap events match with group_id
        const { events: newEvents } = updateOverlapEvents(eventsMatch, companyTimezone, resourceUpdate);
        // Remove old events and add new events
        eventsGroup.forEach((item) => item.remove());
        newEvents.forEach((item) => calendarApi.addEvent(item));
    };

    const _handleUpdateResource = ({ id, group_id }, callback = () => {}) => {
        // eslint-disable-next-line no-undef
        const payload = { socket_id: global.tracker_socket_id };
        const calendarApi = refCalendar.current.getApi();

        // Step 1: Get current data resource
        const resourceUpdate = _handleGetResourceById(id);
        const prevData = resourceUpdate._resource.extendedProps;
        const dataUpdate = refStoreEditing.current || {
            inTime: prevData.start || 0,
            outTime: prevData.end || 0,
            duration: prevData.duration || 0,
            dayOver: calculateDaysPassed(start, end, companyTimezone) || 0
        };
        const { inTime, outTime, dayOver } = dataUpdate;

        const inTimeMoment = moment.unix(inTime).tz(companyTimezone);
        const outTimeMoment = moment.unix(outTime).tz(companyTimezone);
        const dateMoment = moment.tz(prevData.date, companyTimezone);

        payload['start'] = dateMoment.clone().set(getObjectTime(inTimeMoment, true)).unix();
        payload['end'] = !!dayOver
            ? dateMoment.clone().add('day', dayOver).set(getObjectTime(outTimeMoment)).unix()
            : dateMoment.clone().set(getObjectTime(outTimeMoment)).unix();

        const _updateSuccess = ({ data, message }) => {
            // First step: update start end time
            const idEvent = `${id}_${group_id ? RESOURCE_TYPES.CHILDREN : RESOURCE_TYPES.GROUP}`;
            const event = calendarApi.getEventById(idEvent);
            updateEventWithOverlap(
                event,
                { payload, props: { startUnix: data.start, endUnix: data.end } },
                companyTimezone
            );

            // Step 2: Update resource current resource
            setExtendedProps(resourceUpdate, {
                duration: dataUpdate.duration,
                start: payload['start'],
                end: payload['end'],
                amount: data.amount
            });

            // If group_id is not null, update parent resource
            if (group_id) {
                // Step 1: Get current data parent resource
                const parentResource = _handleGetResourceById(group_id);
                const dataParent = parentResource?.extendedProps || {};

                // Update overlap events
                _handleUpdateOverlapEvents(group_id);

                // Step 2: Temp remove old data out of parent resource
                const tempData = {
                    duration: dataParent.duration - prevData.duration,
                    amount: dataParent.amount - prevData.amount
                };

                const children = parentResource?.getChildren() || [];
                let start = 0;
                let end = 0;

                // find min start time of children
                children.forEach((item) => {
                    const itemData = item._resource.extendedProps;
                    if (start === 0) start = itemData.start;
                    if (end === 0) start = itemData.end;

                    start = Math.min(start, itemData.start);
                    end = Math.max(end, itemData.end);
                });

                // Step 3: Re-calculate new data and update parent resource
                setExtendedProps(parentResource, {
                    duration: tempData.duration + dataUpdate.duration,
                    amount: tempData.amount + data.amount,
                    start,
                    end
                });
            }

            // Step 3: Update resource total and reload area right
            _handleUpdateTotal();

            // Final step: Reset store and call api update, callback
            refStoreEditing.current = null;
            trackerAlert(message || t('update_saved_successfully'), LIST_STATUS.SUCCESS);
            callback();
        };

        const _updateFail = ({ message }) => {
            trackerAlert(message);
            callback();
        };

        if ((dataUpdate?.duration || 0) < 60) {
            callback();
            return;
        } else {
            clientQuery(workLogsUpdate(id), { data: payload, method: 'PUT' }, _updateSuccess, _updateFail);
        }
    };

    const _handleToggleUpdate = ({ idResource = '', type = 'on' }) => {
        if (!refCalendar.current) return;
        const oldIdResourceEditing = refIdResourceEditing.current || null;
        if (oldIdResourceEditing) _handleGetResourceById(oldIdResourceEditing)?.setExtendedProp('isEditing', false);
        switch (type) {
            case 'on':
                _handleGetResourceById(idResource).setExtendedProp('isEditing', true);
                _handleUpdateAndReload(idResource);
                break;
            case 'off':
                _handleUpdateAndReload(null);
                refStoreEditing.current = null;
                break;
            default:
                break;
        }
    };
    const _handleDeleteResource = (idResource = '', type = RESOURCE_TYPES.CHILDREN, callback = () => {}) => {
        let ids = [idResource];

        // Get resource and parent resource
        const resource = _handleGetResourceById(idResource);
        let parentId = resource._resource.parentId || '';

        if (type === RESOURCE_TYPES.GROUP) {
            const childrenResources = resource?.getChildren() || [];
            if (childrenResources.length) {
                ids = [];
                parentId = childrenResources[0]?._resource?.parentId || '';
                childrenResources?.forEach((item) => {
                    ids.push(item.id);
                });
            }
        }

        const _handleDeleteSuccess = ({ message }) => {
            const calendarApi = refCalendar.current.getApi();
            // Remove events associated with the resource
            const events = calendarApi.getEvents();
            events.forEach((event) => {
                if (event.extendedProps.idMatch === idResource) event.remove();
            });

            const resourceParent = _handleGetResourceById(parentId);
            // Get data of resource and parent resource
            const dataParent = resourceParent?._resource?.extendedProps || {};
            const resourceData = resource._resource.extendedProps;
            if (type === RESOURCE_TYPES.GROUP) {
                ids = [];
                events.forEach((event) => {
                    if (event.extendedProps.parentId === idResource) event.remove();
                });
                resource?.getChildren()?.forEach((item) => {
                    item.remove();
                });
            }

            // Remove resource
            resource.remove();

            const children = resourceParent?.getChildren() || [];
            // If resource has no children, remove parent resource and update total
            if (children.length <= 1) {
                if (children[0]) {
                    let eventChildren = null;
                    const lastChildrenId = children[0].id;
                    const currentEvents = calendarApi.getEvents() || [];

                    currentEvents.forEach((item) => {
                        const extendedProps = item.extendedProps || {};
                        if (extendedProps.idMatch === lastChildrenId) eventChildren = item;
                        if (extendedProps.parentId === parentId) item.remove();
                    });

                    if (eventChildren) {
                        const props = eventChildren.extendedProps || {};
                        calendarApi.addEvent(
                            convertWorklog(
                                { start: props.startUnix, end: props.endUnix, id: lastChildrenId },
                                RESOURCE_TYPES.GROUP
                            )
                        );
                    }

                    // Setup data for resource
                    setExtendedProps(children[0], { isSubItem: false, group_id: null, isHaveOverlap: false });
                }
                if (resourceParent) resourceParent.remove();
            } else {
                const children = resourceParent?.getChildren() || [];
                let start = 0;
                let end = 0;

                // find min start time of children
                children.forEach((item) => {
                    const itemData = item._resource.extendedProps;
                    if (start === 0) start = itemData.start;
                    if (end === 0) start = itemData.end;

                    start = Math.min(start, itemData.start);
                    end = Math.max(end, itemData.end);
                });

                // If resource has children, update parent resource
                setExtendedProps(resourceParent, {
                    duration: dataParent.duration - resourceData.duration,
                    amount: dataParent.amount - resourceData.amount,
                    start,
                    end
                });
            }

            // Add resource empty if calendar has no resource
            if (!calendarApi.getTopLevelResources().length) calendarApi.addResource(RESOURCE_EMPTY);

            // Update total and overlap events
            _handleUpdateTotal();
            _handleUpdateOverlapEvents(parentId);

            // Display alert delete successfully
            trackerAlert(message || t('delete_successfully'), LIST_STATUS.SUCCESS);
            callback();
        };

        const _handleDeleteFail = ({ message }) => {
            // Display alert delete failed
            trackerAlert(message || t('delete_failed'), LIST_STATUS.ERROR);
            callback();
        };

        // Call API delete
        clientQuery(WORK_LOGS, { data: { ids }, method: 'DELETE' }, _handleDeleteSuccess, _handleDeleteFail);
    };

    const _handleToggleGroup = (idResource = '1') => {
        const resourceById = _handleGetResourceById(idResource);
        const newToggle = resourceById._resource.extendedProps.expanded;
        resourceById.setExtendedProp('expanded', !newToggle);
        refResourceAreaRight.current?.forceReload();
    };

    const _resourceLabelDidMount = ({ resource = {}, ...arg }) => {
        const isParentResource = resource._resource.extendedProps?.isParent;
        if (!isParentResource) return;
        const expanderButton = arg.el.querySelector('.fc-datagrid-expander');
        expanderButton.addEventListener('click', () => _handleToggleGroup(resource.id));
    };

    const _handleChangeTime = ({ inTime, outTime, duration, dayOver }) => {
        refStoreEditing.current = {
            inTime: inTime || 0,
            outTime: outTime || 0,
            duration: duration || 0,
            dayOver: dayOver || 0,
            idResource: refIdResourceEditing.current
        };
    };

    if (isLoading) return <Loading />;
    return (
        <div className="wrapper-clock__container">
            {title ? <h4 className="fs-16 mb-1">{title}</h4> : null}
            <div className="clock-view">
                <div className="clock-table relative">
                    {isRefetching ? (
                        <div className="preloader absolute" style={{ zIndex: 9999 }}>
                            <div className="loader-wave">
                                <span className="loader-wave__items" />
                            </div>
                        </div>
                    ) : null}
                    <FullCalendar
                        ref={refCalendar}
                        plugins={[timelinePlugin, resourceTimelinePlugin]}
                        schedulerLicenseKey={FULL_CALENDAR_KEY}
                        initialView="resourceTimelineDay"
                        resources={dataResources}
                        events={events || []}
                        resourceOrder="start"
                        // Customization view
                        businessHours={{
                            daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
                            startTime: businessHours.start,
                            endTime: businessHours.end
                        }}
                        slotLabelFormat={TIME_LABEL_FORMAT}
                        headerToolbar={false}
                        contentHeight={'auto'}
                        resourceAreaWidth={435}
                        eventMinWidth={5}
                        eventBackgroundColor={TRACKER_EVENT_COLOR.MAIN}
                        eventBorderColor={TRACKER_EVENT_COLOR.MAIN}
                        // Render props
                        eventContent={EventContent}
                        resourceAreaHeaderContent={ResourceAreaHeader}
                        resourceLabelContent={(props) => (
                            <ResourceLabelContent {...props} onChangeTime={_handleChangeTime} />
                        )}
                        resourceLabelDidMount={_resourceLabelDidMount}
                        resourceLaneClassNames={labelClassName}
                        resourceLabelClassNames={labelClassName}
                    />
                    <ResourceAreaRight
                        ref={refResourceAreaRight}
                        isDisableEdit={isDisableEdit || !permissionEdit}
                        isHideEdit={isHideEdit}
                        onGetResources={_handleGetResources}
                        onUpdateResource={_handleUpdateResource}
                        onDeleteResource={_handleDeleteResource}
                        onToggleUpdate={_handleToggleUpdate}
                    />
                </div>
                {isShowTotalRow ? <RowTotal ref={refTotal} total={total} /> : null}
            </div>
        </div>
    );
});

export default TrackerCalendar;
