import { ACCESS_TOKEN, ENTER_KEY_CODE, SINGULAR_NOUN, SRC_LOGO_GORILLADESK } from 'app/const/App';
import { SIGNUP_STEP } from 'app/const/Auth';
import { AUTH_SIGN_UP_SOCIAL, HOME, SIGN_UP_SUCCESS } from 'app/const/Route';
import AuthError from 'app/modules/auth/components/AuthError';
import LoginSocial from 'app/modules/auth/login/components/FormLoginSocial';
import { useSiteTitle } from 'common/hooks/useSiteTitle';
import { clearInfoSignup, userSignUpRequest, userSignupSocialRequest } from 'common/redux/actions/authAction';
import { validateEmail } from 'common/utils/EmailUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import FormIdentify from './components/FormIdentify';
import { deepCloneObject } from 'common/utils/FunctionUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { SIGN_UP_VALIDATE_EMAIL } from 'app/const/api/V2';
import { validPassword } from 'common/utils/ValidationUtils';
import { reducer } from 'app/const/Reducer';
import FreeTrial from './components/freeTrial';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import {
    MIXPANEL_PROPERTIES,
    SIGN_UP_TRACKING_INFO,
    START_SIGN_UP_FORM,
    SUBMIT_SIGN_UP_FORM
} from 'app/const/Mixpanel';
import { WORD_VALIDATE_EMAIL, REGEX_VALIDATE_EMAIL } from './components/freeTrial/const';
import queryString from 'query-string';
import { REFERRAL_QUERY } from 'app/modules/referral/constant';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';
import TwoStepAuthentication from '../twoStepAuthentication';

const Signup = () => {
    useSiteTitle('auth:sign_up');
    const [state, dispatchState] = useReducer(reducer, {
        error: null,
        errorsForm: null,
        formDisable: false,
        step: SIGNUP_STEP.one,
        captchaStatus: false,
        infoSignup: null,
        isShow2FA: false,
        list2FA: []
    });

    const { t } = useTranslation(['auth', 'common']);
    const dispatch = useDispatch();

    const refForm = useRef(null);
    const refDataLogin = useRef({});
    const history = useHistory();
    const { search } = useLocation();

    const { [REFERRAL_QUERY]: referralCode } = queryString.parse(search);

    const access_token = localStorage.getItem(ACCESS_TOKEN);
    const isLogin = useSelector((state) => state.auth.isLogin);

    useEffect(() => {
        clearInfoSignup();
    }, []);

    const handleCheckCaptcha = () => {
        dispatchState({ captchaStatus: true, errorsForm: null });
    };

    const submitUsernamePassword = () => {
        const msgError = {};
        const { username, password } = refForm.current;
        const lengthForm = refForm.current.length;

        for (let i = 0; i < lengthForm; i++) {
            const { name, value, dataset } = refForm.current[i];
            if (name && !value.trim()) {
                msgError[name] = t(`auth:${dataset?.labelField}`).concat(' ', t('auth:can_not_be_blank'));
            }
        }

        const usernameValue = !!username?.value ? username.value.trim().toLowerCase() : '';
        const passwordValue = password?.value;

        if (usernameValue) {
            if (!validateEmail(usernameValue)) {
                msgError['username'] = t('common:email_is_not_a_valid_email_address');
            } else if (!REGEX_VALIDATE_EMAIL.test(usernameValue)) {
                dispatchState((prev) => ({
                    ...prev,
                    error: t('auth:username').concat(' ', t('common:can_contain_only', { word: WORD_VALIDATE_EMAIL }))
                }));
                return;
            }
        }

        if (!validPassword(passwordValue)) {
            msgError['password'] = t('common:password_limit').concat(' ', t('common:require_least_7_char_with_1_num'));
        }

        const errorKeys = Object.keys(msgError);

        if (errorKeys.length) {
            refForm.current[errorKeys[0]].focus();
            dispatchState({ errorsForm: msgError });
            return false;
        }

        const info = {
            username: usernameValue,
            password: passwordValue
        };

        dispatchState({ formDisable: true });

        if (state.captchaStatus) {
            clientQuery(SIGN_UP_VALIDATE_EMAIL, { data: { email: usernameValue }, method: 'GET' }, (response) =>
                _success(response)
            );

            const _handleNextStep = ({ data }) => {
                handleTrackingEvent(
                    mixpanelSignup({
                        profile: {
                            id: data.user.id,
                            email: usernameValue,
                            registration_method: MIXPANEL_PROPERTIES.EMAIL
                        },
                        company: { [MIXPANEL_PROPERTIES.INDUSTRY]: '' },
                        event: START_SIGN_UP_FORM
                    })
                );
                dispatchState({ step: SIGNUP_STEP.two, infoSignup: { ...data.user, ...info }, formDisable: false });
            };

            const _success = ({ data, message }) => {
                const _handleTracking = () => {
                    const _handleTrackingError = ({ message }) => {
                        dispatchState({ formDisable: false, error: message });
                    };

                    clientQuery(
                        SIGN_UP_TRACKING_INFO,
                        { data: { email: usernameValue }, toFormData: false, useGdBranchID: false, method: 'POST' },
                        _handleNextStep,
                        _handleTrackingError
                    );
                };

                data.is_valid === SINGULAR_NOUN
                    ? !data.user
                        ? _handleTracking()
                        : _handleNextStep({ data })
                    : dispatchState({ error: message, formDisable: false });
            };
        }
    };

    const submitFormSignup = (data) => {
        const { username, password } = state.infoSignup;
        const postData = deepCloneObject(data);

        postData.company.email = username;
        postData.profile.email = username;
        postData.profile.username = username;
        postData.profile.password = password;
        postData.referral_code = referralCode;

        dispatchState({ error: null });
        dispatch(userSignUpRequest(postData, () => signUpSuccess(postData), signUpFailed));
    };

    const signUpSuccess = ({ profile, company }) => {
        handleTrackingEvent(mixpanelSignup({ profile, company, event: SUBMIT_SIGN_UP_FORM }));
        dispatchState({ step: SIGNUP_STEP.three });
    };

    const signUpFailed = (response) => {
        dispatchState({ error: response?.message });
    };

    const handleLoginFirebase = (data) => {
        refDataLogin.current = data;
        dispatchState({ formDisable: true, error: null, errorsForm: null });
        dispatch(userSignupSocialRequest(data, loginSocialStepCompany, loginSocialFailed));
    };

    const loginSocialStepCompany = (response, params) => {
        if (response.token) {
            restoreOptionJobCalendar();
        } else {
            const { id, email } = response?.user || {};
            const data = {
                id,
                type: params.type,
                email: params.email || email,
                referral_code: referralCode
            };

            handleTrackingEvent(
                mixpanelSignup({
                    profile: {
                        id: data.id,
                        email: data.email,
                        registration_method: MIXPANEL_PROPERTIES.SOCIAL[data.type]
                    },
                    company: { [MIXPANEL_PROPERTIES.INDUSTRY]: '' },
                    event: START_SIGN_UP_FORM
                })
            );
            history.push({ pathname: AUTH_SIGN_UP_SOCIAL, state: { dataUser: data, params } });
        }
    };

    const loginSocialFailed = (response) => {
        const { list_2fa = [], redirect_2fa = false, message } = response || {};
        if (redirect_2fa) {
            dispatchState((prev) => ({ ...prev, isShow2FA: true, list_2fa }));
        } else {
            dispatchState({ formDisable: false, error: message });
        }
    };

    const _handleKeypress = (e) => {
        if (e.charCode === ENTER_KEY_CODE) {
            submitUsernamePassword();
        }
    };

    const _handleFocusField = () => {
        dispatchState({ error: null, errorsForm: null });
    };

    if (state.step === SIGNUP_STEP.two) {
        return <FreeTrial submitSignup={submitFormSignup} errorSignupForm={state.error} userId={state.infoSignup.id} />;
    }

    if (state.step === SIGNUP_STEP.three) {
        return <Redirect to={{ pathname: SIGN_UP_SUCCESS, state: { info: state.infoSignup } }} />;
    }

    if (isLogin && access_token) {
        return <Redirect to={HOME} />;
    }

    return (
        <React.Fragment>
            {state.isShow2FA ? (
                <TwoStepAuthentication data={state.list2FA} handleSuccess={handleLoginFirebase} />
            ) : (
                <div id="gd_login" className="gd-form-v2 px-6">
                    <AuthError text={state.error} />
                    <h1 className="logo-text-gorilla">
                        <img src={SRC_LOGO_GORILLADESK} width={377} height={90} />
                    </h1>
                    <LoginSocial onLoginSocial={handleLoginFirebase} t={t} />
                    <div className="gd-line flex-betweenitems">
                        <div className="line" />
                        <span> {t('common:or')} </span>
                        <div className="line" />
                    </div>
                    <form id="login-form" ref={refForm} onSubmit={(e) => e.preventDefault()}>
                        <section className="gd-login-btn-group boxs has-form p-0">
                            <FormIdentify
                                onSubmitForm={submitUsernamePassword}
                                errorsForm={state.errorsForm}
                                onCheckCaptcha={handleCheckCaptcha}
                                formDisable={state.formDisable}
                                t={t}
                                onKeyPress={_handleKeypress}
                                onFocusField={_handleFocusField}
                            />
                        </section>
                    </form>
                </div>
            )}
        </React.Fragment>
    );
};

export default Signup;
