import { ADDONS_BOOKING } from 'app/config/routes';
import { ADDONS_BOOKING_SETTINGS } from 'app/const/Api';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AddonsAlert from '../../components/AddonsAlert';
import Loading from './components/Loading';
import ServiceList from './components/ServiceList';
import ServiceOptions from './components/ServiceOptions';
import { reducer } from 'app/const/Reducer';
import { addBranchPath } from 'app/const/Branch';
import { LIST_STATUS } from 'app/const/App';

const AddonsBookingSettings = () => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { settings: {}, isLoading: true, isDisable: false });
    const { settings, isLoading, isDisable } = state;
    const refAlert = useRef(null);

    useEffect(() => {
        _getSettingDetail();
    }, []);

    const _getSettingDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ settings: data, isLoading: false });

        const _handleFail = ({ message }) => {
            dispatchState({ isLoading: false, isDisable: true });
            _handleShowAlert({ id: 'booking', message, type: LIST_STATUS.ERROR });
        };

        clientQuery(ADDONS_BOOKING_SETTINGS, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleShowAlert = (data) => refAlert.current.showStatusBar(data);

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS_BOOKING)} className="v2-btn-default has-icon">
                    <IconArrow isPrev />
                    {t('online_booking')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    <AddonsAlert ref={refAlert} />
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <ServiceOptions
                                dataOptions={{
                                    card_payment_required: settings.card_payment_required || 0,
                                    display_service_pricing: settings.display_service_pricing || 0,
                                    exclude_weekends: settings.exclude_weekends || 0,
                                    same_day_bookings: settings.same_day_bookings || 0
                                }}
                                isDisable={isDisable}
                            />
                            <ServiceList
                                data={settings.services || []}
                                onShowAlert={_handleShowAlert}
                                isDisable={isDisable}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsBookingSettings;
