import React from 'react';
import { useTranslation } from 'react-i18next';

function GridEmpty({ msgEmpty, customStyle = {}, isFlat = false, ...props }) {
    const { t } = useTranslation();

    return isFlat ? (
        <div className="content-empty" style={customStyle} {...props}>
            {msgEmpty || t('empty_table_text')}
        </div>
    ) : (
        <div className="wrap-empty p-4" style={customStyle}>
            <div className="tables-empty m-0" {...props}>
                {msgEmpty || t('there_is_no_data')}
            </div>
        </div>
    );
}

export default GridEmpty;
