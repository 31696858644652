import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';

const FooterModal = (
    {
        className = 'wrapper-events__footer flex-betweenitems',
        isNotLoading = false,
        onCancel = () => {},
        onSave = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const refButton = useRef(null);

    useImperativeHandle(ref, () => ({ removeLoading: () => refButton.current.removeLoading() }));

    return (
        <div className={className}>
            <div className="v2-btn-default --transparent" onClick={onCancel}>
                {t('cancel')}
            </div>
            <ButtonSave ref={refButton} title={t('save')} isNotLoading={isNotLoading} onSave={onSave} />
        </div>
    );
};

export default forwardRef(FooterModal);
