export const TYPE_REPORT_CUSTOMERS_WO_ACTIVE_JOB = {
    GET_LIST: 'GET_LIST_REPORT_CUSTOMERS_WO_ACTIVE_JOB'
};

export const getListReportCustomersWoActiveJobs = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_CUSTOMERS_WO_ACTIVE_JOB.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};
