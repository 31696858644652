import Todos from 'app/modules/settings/templatesManager/components/todoList/Todos';
import DetailItems from 'app/modules/settings/templatesManager/components/todoList/DetailItems';
import { Fragment, useEffect, useRef, useState } from 'react';
import GdConfirm from 'app/components/confirm';
import { useCallbackPrompt } from 'common/hooks';
import { useTranslation } from 'react-i18next';

const TodoListManager = () => {
    const refDetailItems = useRef(null);
    const refTodos = useRef(null);
    const refConfirm = useRef(null);
    const [isChanged, setIsChanged] = useState(false);

    const { t } = useTranslation(['setting']);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isChanged);

    useEffect(() => {
        showPrompt && refConfirm.current.open();
    }, [showPrompt]);

    const _getDetailItems = (item) => {
        refDetailItems.current._getDetail(item);
    };

    const _openAddTemplate = () => {
        refDetailItems.current._openAddTemplate();
    };

    const _closeDetailItems = () => {
        refDetailItems.current._closeForm();
    };

    const _addTemplate = (item) => {
        refTodos.current._addTodo(item);
    };

    const _updateTemplate = (item) => {
        refTodos.current._updateTodo(item);
    };

    const _showStatusBar = (id, message, status) => {
        refDetailItems.current._showStatusBar(id, message, status);
    };

    const _onHasBeenChange = (status) => setIsChanged(status);

    return (
        <Fragment>
            <Todos
                ref={refTodos}
                getDetailItems={_getDetailItems}
                onOpenAddTemplate={_openAddTemplate}
                onCloseDetailItems={_closeDetailItems}
                onShowStatusBar={_showStatusBar}
            />
            <DetailItems
                ref={refDetailItems}
                onAddTemplate={_addTemplate}
                onUpdateTemplate={_updateTemplate}
                onHasBeenChange={_onHasBeenChange}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('confirm_unsaved_changes')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={confirmNavigation}
                onCancel={cancelNavigation}
                onClose={cancelNavigation}
            />
        </Fragment>
    );
};
export default TodoListManager;
