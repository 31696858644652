export const TYPE_REPORT_SALES_FORECAST = {
  GET_LIST: "GET_LIST_REPORT_SALES_FORECAST"
}


// actions
export const getListReportSalesForecast = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_SALES_FORECAST.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}