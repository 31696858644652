import { ENDPOINT_AVATAR } from 'app/const/URL';
import { COMMON } from 'app/const/App';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { getListTypeFilterRequest } from 'common/redux/actions/reports/documentAction';
import {
    ACCESS_CUSTOMERS_TAB,
    ACCESS_REPORT_TAB,
    ACCESS_SETTINGS_TAB,
    ACCESS_ACCOUNT,
    EXPORT_CUSTOMER_LIST,
    SMS_FILTER_BY_USER,
    EDIT_OR_DELETE_NOTES,
    DELETE_COMMENTS,
    EDIT_OR_DELETE_TOP_NOTE,
    EDIT_OR_DELETE_TASK_INBOX,
    EDIT_CHECK_TIME,
    ONLINE_PAYMENT_NOTIFICATION,
    E_SIGNED_DOCUMENT_NOTIFICATION,
    E_SIGNED_ESTIMATE_NOTIFICATION,
    E_SIGNED_INVOICE_NOTIFICATION,
    INBOUND_LEADS_NOTIFICATION,
    JOB_CONFIRMATION_NOTIFICATION,
    JOB_RESCHEDULE_NOTIFICATION,
    REVIEWS_NOTIFICATION,
    SUBSCRIPTION_NOTIFICATION,
    ONLINE_BOOKING_NOTIFICATION,
    ACCESS_DESKTOP,
    ACCESS_MOBILE_APP,
    APP_SEARCH_CUSTOMER,
    APP_ADD_CUSTOMER,
    APP_EDIT_CUSTOMER,
    APP_ADD_JOB,
    APP_EDIT_JOB,
    DELETE_JOBS,
    MOVE_ALL_RECURRING,
    APP_STRIPE_CARD,
    SHOW_WEATHER,
    APP_INVOICE_CARD,
    SALES_COMMISSION,
    MANAGE_TODO_TEMPLATES,
    SHOW_WORK_POOL,
    EDIT_OR_DELETE_TIMEOFF,
    EDIT_OR_DELETE_EVENT,
    INBOX,
    // EDIT_SCHEDULE_COLOR,
    MANAGE_NOTE_TEMPLATES,
    ADD_TIMEOFF,
    ADD_EVENT,
    ACCESS_DASHBOARD,
    CONTACT_INFORMATION,
    WO_OPTIONS,
    DOCUMENT_CARD,
    ACCESS_MANAGE_BRANCH,
    ACCESS_ADDONS,
    MISSED_CALL_NOTIFICATION
} from 'app/const/Permissions';

const TABS = 'tabs';
const ACCESS = 'access';
const NOTIFICATIONS = 'notification';
const DEVICES = 'devices';
const MOBILE = 'mobile';
const DESKTOP_MOBILE = 'desktop_mobile';
const E_SIGNED_DOCUMENTS = 'e_signed_documents';
const JOB_CONFIRMATIONS = 'job_confirmations';
const JOB_RESCHEDULES = 'job_reschedules';

const ADMIN_PERMISSION = {
    // TABS GROUP

    [ACCESS_CUSTOMERS_TAB]: {
        id: 'admin_tabs_1',
        title: 'customers',
        name: ACCESS_CUSTOMERS_TAB,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },
    [ACCESS_REPORT_TAB]: {
        id: 'admin_tabs_2',
        title: 'reports',
        name: ACCESS_REPORT_TAB,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },
    [ACCESS_SETTINGS_TAB]: {
        id: 'admin_tabs_3',
        title: 'settings',
        name: ACCESS_SETTINGS_TAB,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },
    [ACCESS_ACCOUNT]: {
        id: 'admin_tabs_4',
        title: 'account',
        name: ACCESS_ACCOUNT,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },
    [ACCESS_ADDONS]: {
        id: 'admin_tabs_5',
        title: 'addons',
        name: ACCESS_ADDONS,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },

    // ACCESS GROUP

    [EXPORT_CUSTOMER_LIST]: {
        id: 'admin_access_1',
        title: 'export_customer_list',
        name: EXPORT_CUSTOMER_LIST,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [EDIT_OR_DELETE_NOTES]: {
        id: 'admin_access_2',
        title: 'edit_or_delete_notes',
        name: EDIT_OR_DELETE_NOTES,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [DELETE_COMMENTS]: {
        id: 'admin_access_3',
        title: 'edit_or_delete_comments',
        name: DELETE_COMMENTS,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [EDIT_OR_DELETE_TOP_NOTE]: {
        id: 'admin_access_4',
        title: 'edit_or_delete_top_notes',
        name: EDIT_OR_DELETE_TOP_NOTE,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [EDIT_OR_DELETE_TASK_INBOX]: {
        id: 'admin_access_5',
        title: 'edit_or_delete_tasks',
        name: EDIT_OR_DELETE_TASK_INBOX,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [EDIT_CHECK_TIME]: {
        id: 'admin_access_6',
        title: 'edit_check_in_out_time',
        name: EDIT_CHECK_TIME,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [SALES_COMMISSION]: {
        id: 'admin_access_7',
        title: 'sales_commission',
        name: SALES_COMMISSION,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [MANAGE_TODO_TEMPLATES]: {
        id: 'admin_access_8',
        title: 'manage_todo_templates',
        name: MANAGE_TODO_TEMPLATES,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [MANAGE_NOTE_TEMPLATES]: {
        id: 'admin_access_24',
        title: 'manage_note_templates',
        isDisabledWithOwner: true,
        name: MANAGE_NOTE_TEMPLATES,
        value: false,
        group: ACCESS
    },
    [SHOW_WORK_POOL]: {
        id: 'admin_access_9',
        title: 'show_work_pool',
        name: SHOW_WORK_POOL,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_SEARCH_CUSTOMER]: {
        id: 'admin_access_11',
        title: 'search_customer_profiles',
        name: APP_SEARCH_CUSTOMER,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_ADD_CUSTOMER]: {
        id: 'admin_access_12',
        title: 'add_customers',
        name: APP_ADD_CUSTOMER,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_EDIT_CUSTOMER]: {
        id: 'admin_access_13',
        title: 'edit_customers',
        name: APP_EDIT_CUSTOMER,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_ADD_JOB]: {
        id: 'admin_access_14',
        title: 'add_jobs',
        name: APP_ADD_JOB,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_EDIT_JOB]: {
        id: 'admin_access_15',
        title: 'edit_existing_jobs',
        name: APP_EDIT_JOB,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [DELETE_JOBS]: {
        id: 'admin_access_16',
        title: 'delete_jobs',
        name: DELETE_JOBS,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [MOVE_ALL_RECURRING]: {
        id: 'admin_access_17',
        title: 'move_all_recurring',
        name: MOVE_ALL_RECURRING,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_STRIPE_CARD]: {
        id: 'admin_access_18',
        title: 'stripe_or_square_card',
        name: APP_STRIPE_CARD,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [SHOW_WEATHER]: {
        id: 'admin_access_19',
        title: 'weather_card',
        name: SHOW_WEATHER,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [APP_INVOICE_CARD]: {
        id: 'admin_access_20',
        title: 'invoice_card',
        name: APP_INVOICE_CARD,
        isDisabledWithOwner: true,
        value: false,
        group: ACCESS
    },
    [EDIT_OR_DELETE_TIMEOFF]: {
        id: 'admin_access_21',
        title: EDIT_OR_DELETE_TIMEOFF,
        isDisabledWithOwner: true,
        name: EDIT_OR_DELETE_TIMEOFF,
        value: false,
        group: ACCESS
    },
    [EDIT_OR_DELETE_EVENT]: {
        id: 'admin_access_22',
        title: EDIT_OR_DELETE_EVENT,
        isDisabledWithOwner: true,
        name: EDIT_OR_DELETE_EVENT,
        value: false,
        group: ACCESS
    },
    [INBOX]: {
        id: 'admin_access_25',
        title: INBOX,
        name: INBOX,
        value: false,
        group: ACCESS,
        isDisabledWithOwner: true
    },
    [ACCESS_DASHBOARD]: {
        id: 'admin_access_26',
        title: ACCESS_DASHBOARD,
        name: ACCESS_DASHBOARD,
        value: false,
        group: ACCESS,
        isDisabledWithOwner: true
    },
    [ACCESS_MANAGE_BRANCH]: {
        id: 'admin_access_27',
        title: ACCESS_MANAGE_BRANCH,
        name: ACCESS_MANAGE_BRANCH,
        value: false,
        group: ACCESS,
        isDisabledWithOwner: true
    },
    // [EDIT_SCHEDULE_COLOR]: {
    //     id: 'admin_access_23',
    //     title: EDIT_SCHEDULE_COLOR,
    //     isDisabledWithOwner: true,
    //     name: EDIT_SCHEDULE_COLOR,
    //     value: false,
    //     group: ACCESS
    // },

    // NOTIFICATIONS GROUP

    [ONLINE_PAYMENT_NOTIFICATION]: {
        id: 'admin_notify_1',
        title: 'all_online_payments',
        name: ONLINE_PAYMENT_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [E_SIGNED_DOCUMENT_NOTIFICATION]: {
        id: 'admin_notify_2',
        title: 'e_signed_document',
        name: E_SIGNED_DOCUMENT_NOTIFICATION,
        value: false,
        subPermission: {
            name: E_SIGNED_DOCUMENTS
        },
        group: NOTIFICATIONS
    },
    [E_SIGNED_ESTIMATE_NOTIFICATION]: {
        id: 'admin_notify_3',
        title: 'e_signed_estimate',
        name: E_SIGNED_ESTIMATE_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [E_SIGNED_INVOICE_NOTIFICATION]: {
        id: 'admin_notify_4',
        title: 'e_signed_invoice',
        name: E_SIGNED_INVOICE_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [INBOUND_LEADS_NOTIFICATION]: {
        id: 'admin_notify_5',
        title: 'inbound_leads',
        name: INBOUND_LEADS_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [JOB_CONFIRMATION_NOTIFICATION]: {
        id: 'admin_notify_6',
        title: 'job_confirmations',
        name: JOB_CONFIRMATION_NOTIFICATION,
        value: false,
        subPermission: {
            name: JOB_CONFIRMATIONS
        },
        group: NOTIFICATIONS
    },
    [JOB_RESCHEDULE_NOTIFICATION]: {
        id: 'admin_notify_7',
        title: 'job_reschedules',
        name: JOB_RESCHEDULE_NOTIFICATION,
        value: false,
        subPermission: {
            name: JOB_RESCHEDULES
        },
        group: NOTIFICATIONS
    },
    [REVIEWS_NOTIFICATION]: {
        id: 'admin_notify_8',
        title: 'reviews',
        name: REVIEWS_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [SUBSCRIPTION_NOTIFICATION]: {
        id: 'admin_notify_9',
        title: 'subscription',
        name: SUBSCRIPTION_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [ONLINE_BOOKING_NOTIFICATION]: {
        id: 'admin_notify_10',
        title: 'online_booking',
        name: ONLINE_BOOKING_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [MISSED_CALL_NOTIFICATION]: {
        id: 'admin_notify_11',
        title: 'voip_missed_call',
        name: MISSED_CALL_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    }
};

const TECHNICIAN_PERMISSIONS = {
    // TABS GROUP

    [ACCESS_CUSTOMERS_TAB]: {
        id: 'tech_tabs_1',
        title: 'customers',
        name: ACCESS_CUSTOMERS_TAB,
        value: false,
        group: TABS
    },
    [ACCESS_REPORT_TAB]: {
        id: 'tech_tabs_2',
        title: 'reports',
        name: ACCESS_REPORT_TAB,
        value: false,
        group: TABS
    },
    [ACCESS_SETTINGS_TAB]: {
        id: 'tech_tabs_3',
        title: 'settings',
        name: ACCESS_SETTINGS_TAB,
        value: false,
        group: TABS
    },
    [ACCESS_ADDONS]: {
        id: 'admin_tabs_5',
        title: 'addons',
        name: ACCESS_ADDONS,
        isDisabledWithOwner: true,
        value: false,
        group: TABS
    },

    // DEVICES GROUP

    [ACCESS_DESKTOP]: {
        id: 'tech_devices_1',
        title: 'desktop',
        name: ACCESS_DESKTOP,
        value: false,
        group: DEVICES
    },
    [ACCESS_MOBILE_APP]: {
        id: 'tech_devices_2',
        title: 'mobile',
        name: ACCESS_MOBILE_APP,
        value: false,
        group: DEVICES
    },

    // MOBILE GROUP

    [APP_SEARCH_CUSTOMER]: {
        id: 'tech_mobile_1',
        title: 'search_customer_profiles',
        name: APP_SEARCH_CUSTOMER,
        value: false,
        group: MOBILE
    },
    [APP_ADD_CUSTOMER]: {
        id: 'tech_mobile_2',
        title: 'add_customers',
        name: APP_ADD_CUSTOMER,
        value: false,
        group: MOBILE
    },
    [APP_EDIT_CUSTOMER]: {
        id: 'tech_mobile_3',
        title: 'edit_customers',
        name: APP_EDIT_CUSTOMER,
        value: false,
        group: MOBILE
    },
    [APP_ADD_JOB]: {
        id: 'tech_mobile_4',
        title: 'add_jobs',
        name: APP_ADD_JOB,
        value: false,
        group: MOBILE
    },
    [APP_EDIT_JOB]: {
        id: 'tech_mobile_5',
        title: 'edit_existing_jobs',
        name: APP_EDIT_JOB,
        value: false,
        group: MOBILE
    },
    [DELETE_JOBS]: {
        id: 'tech_mobile_6',
        title: 'delete_jobs',
        name: DELETE_JOBS,
        value: false,
        group: MOBILE
    },
    [MOVE_ALL_RECURRING]: {
        id: 'tech_mobile_7',
        title: 'move_all_recurring',
        name: MOVE_ALL_RECURRING,
        value: false,
        group: MOBILE
    },
    [APP_STRIPE_CARD]: {
        id: 'tech_mobile_8',
        title: 'stripe_or_square_card',
        name: APP_STRIPE_CARD,
        value: false,
        group: MOBILE
    },
    [SHOW_WEATHER]: {
        id: 'tech_mobile_9',
        title: 'weather_card',
        name: SHOW_WEATHER,
        value: false,
        group: MOBILE
    },
    [APP_INVOICE_CARD]: {
        id: 'tech_mobile_10',
        title: 'invoice_card',
        name: APP_INVOICE_CARD,
        value: false,
        group: MOBILE
    },
    [CONTACT_INFORMATION]: {
        id: 'tech_mobile_11',
        title: 'contact_information',
        name: CONTACT_INFORMATION,
        value: false,
        group: MOBILE
    },
    [WO_OPTIONS]: {
        id: 'tech_mobile_12',
        title: 'wo_options',
        name: WO_OPTIONS,
        value: false,
        group: MOBILE
    },
    [DOCUMENT_CARD]: {
        id: 'tech_mobile_14',
        title: 'document_card',
        name: DOCUMENT_CARD,
        value: false,
        group: MOBILE
    },

    // DESKTOP & MOBILE GROUP

    [SMS_FILTER_BY_USER]: {
        id: 'tech_desktop_and_mobile_0',
        title: 'sms_filter_by_user',
        name: SMS_FILTER_BY_USER,
        value: false,
        tooltip: 'sms_filter_by_user_tooltip',
        group: DESKTOP_MOBILE
    },
    [EDIT_OR_DELETE_NOTES]: {
        id: 'tech_desktop_and_mobile_1',
        title: 'edit_or_delete_notes',
        name: EDIT_OR_DELETE_NOTES,
        value: false,
        group: DESKTOP_MOBILE
    },
    [DELETE_COMMENTS]: {
        id: 'tech_desktop_and_mobile_2',
        title: 'edit_or_delete_comments',
        name: DELETE_COMMENTS,
        value: false,
        group: DESKTOP_MOBILE
    },
    [EDIT_OR_DELETE_TOP_NOTE]: {
        id: 'tech_desktop_and_mobile_3',
        title: 'edit_or_delete_top_notes',
        name: EDIT_OR_DELETE_TOP_NOTE,
        value: false,
        group: DESKTOP_MOBILE
    },
    [EDIT_OR_DELETE_TASK_INBOX]: {
        id: 'tech_desktop_and_mobile_4',
        title: 'edit_or_delete_tasks',
        name: EDIT_OR_DELETE_TASK_INBOX,
        value: false,
        group: DESKTOP_MOBILE
    },
    [EDIT_CHECK_TIME]: {
        id: 'tech_desktop_and_mobile_5',
        title: 'edit_check_in_check_out_time',
        name: EDIT_CHECK_TIME,
        value: false,
        group: DESKTOP_MOBILE
    },
    [SALES_COMMISSION]: {
        id: 'tech_desktop_and_mobile_6',
        title: 'sales_commission',
        name: SALES_COMMISSION,
        value: false,
        group: DESKTOP_MOBILE
    },
    [MANAGE_TODO_TEMPLATES]: {
        id: 'tech_desktop_and_mobile_7',
        title: 'manage_todo_templates',
        name: MANAGE_TODO_TEMPLATES,
        value: false,
        group: DESKTOP_MOBILE
    },
    [MANAGE_NOTE_TEMPLATES]: {
        id: 'tech_desktop_and_mobile_12',
        title: 'manage_note_templates',
        name: MANAGE_NOTE_TEMPLATES,
        value: false,
        group: DESKTOP_MOBILE
    },
    [SHOW_WORK_POOL]: {
        id: 'tech_desktop_and_mobile_8',
        title: 'show_work_pool',
        name: SHOW_WORK_POOL,
        value: false,
        group: DESKTOP_MOBILE
    },
    [EDIT_OR_DELETE_TIMEOFF]: {
        id: 'tech_desktop_and_mobile_9',
        title: EDIT_OR_DELETE_TIMEOFF,
        name: EDIT_OR_DELETE_TIMEOFF,
        value: false,
        group: DESKTOP_MOBILE
    },
    [EDIT_OR_DELETE_EVENT]: {
        id: 'tech_desktop_and_mobile_10',
        title: EDIT_OR_DELETE_EVENT,
        name: EDIT_OR_DELETE_EVENT,
        value: false,
        group: DESKTOP_MOBILE
    },
    [INBOX]: {
        id: 'tech_desktop_and_mobile_13',
        title: INBOX,
        name: INBOX,
        value: false,
        group: DESKTOP_MOBILE
    },
    [ADD_TIMEOFF]: {
        id: 'tech_desktop_and_mobile_14',
        title: ADD_TIMEOFF,
        name: ADD_TIMEOFF,
        value: false,
        group: DESKTOP_MOBILE
    },
    [ADD_EVENT]: {
        id: 'tech_desktop_and_mobile_15',
        title: ADD_EVENT,
        name: ADD_EVENT,
        value: false,
        group: DESKTOP_MOBILE
    },
    [ACCESS_DASHBOARD]: {
        id: 'tech_desktop_and_mobile_16',
        title: ACCESS_DASHBOARD,
        name: ACCESS_DASHBOARD,
        value: false,
        group: DESKTOP_MOBILE
    },

    // NOTIFICATIONS GROUP

    [E_SIGNED_DOCUMENT_NOTIFICATION]: {
        id: 'tech_notify_1',
        title: 'e_signed_document',
        name: E_SIGNED_DOCUMENT_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [E_SIGNED_ESTIMATE_NOTIFICATION]: {
        id: 'tech_notify_2',
        title: 'e_singed_estimate',
        name: E_SIGNED_ESTIMATE_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [E_SIGNED_INVOICE_NOTIFICATION]: {
        id: 'tech_notify_3',
        title: 'e_signed_invoice',
        name: E_SIGNED_INVOICE_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [JOB_CONFIRMATION_NOTIFICATION]: {
        id: 'tech_notify_4',
        title: 'job_confirmations',
        name: JOB_CONFIRMATION_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [JOB_RESCHEDULE_NOTIFICATION]: {
        id: 'tech_notify_5',
        title: 'job_reschedules',
        name: JOB_RESCHEDULE_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [ONLINE_PAYMENT_NOTIFICATION]: {
        id: 'tech_notify_6',
        title: 'online_payments',
        name: ONLINE_PAYMENT_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [REVIEWS_NOTIFICATION]: {
        id: 'tech_notify_7',
        title: 'reviews',
        name: REVIEWS_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [SUBSCRIPTION_NOTIFICATION]: {
        id: 'tech_notify_8',
        title: 'subscription',
        name: SUBSCRIPTION_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [ONLINE_BOOKING_NOTIFICATION]: {
        id: 'tech_notify_9',
        title: 'online_booking',
        name: ONLINE_BOOKING_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    },
    [MISSED_CALL_NOTIFICATION]: {
        id: 'tech_notify_10',
        title: 'voip_missed_call',
        name: MISSED_CALL_NOTIFICATION,
        value: false,
        group: NOTIFICATIONS
    }
};
const URL_AVATAR = `${ENDPOINT_AVATAR}/static/avatar_`;

const LIST_AVATAR_DEFAULT = [
    {
        id: 1,
        url: `${URL_AVATAR}1.jpg`
    },
    {
        id: 2,
        url: `${URL_AVATAR}2.jpg`
    },
    {
        id: 3,
        url: `${URL_AVATAR}3.jpg`
    },
    {
        id: 4,
        url: `${URL_AVATAR}4.jpg`
    },
    {
        id: 5,
        url: `${URL_AVATAR}5.jpg`
    },
    {
        id: 6,
        url: `${URL_AVATAR}6.jpg`
    },
    {
        id: 7,
        url: `${URL_AVATAR}7.jpg`
    },
    {
        id: 8,
        url: `${URL_AVATAR}8.jpg`
    },
    {
        id: 9,
        url: `${URL_AVATAR}9.jpg`
    },
    {
        id: 10,
        url: `${URL_AVATAR}10.jpg`
    }
];

const SUB_PERMISSION_MAP = {
    [E_SIGNED_DOCUMENTS]: {
        nameStore: COMMON.DOCUMENTS,
        action: getListTypeFilterRequest
    },
    [JOB_CONFIRMATIONS]: {
        nameStore: COMMON.SCHEDULES,
        action: actionGetListSchedule
    },
    [JOB_RESCHEDULES]: {
        nameStore: COMMON.SCHEDULES,
        action: actionGetListSchedule
    }
};

const TECHNICIAN_DEFAULT_PERMISSION = [
    ACCESS_CUSTOMERS_TAB,
    ACCESS_DESKTOP,
    ACCESS_MOBILE_APP,
    MOVE_ALL_RECURRING,
    APP_STRIPE_CARD,
    APP_INVOICE_CARD,
    EDIT_OR_DELETE_NOTES,
    EDIT_OR_DELETE_TOP_NOTE,
    DELETE_COMMENTS,
    SHOW_WORK_POOL,
    EDIT_OR_DELETE_TIMEOFF,
    EDIT_OR_DELETE_EVENT,
    CONTACT_INFORMATION,
    WO_OPTIONS,
    DOCUMENT_CARD
];

const ADMIN_DEFAULT_PERMISSION = [
    ACCESS_CUSTOMERS_TAB,
    ACCESS_REPORT_TAB,
    ACCESS_SETTINGS_TAB,
    ACCESS_ACCOUNT,
    EXPORT_CUSTOMER_LIST,
    EDIT_OR_DELETE_NOTES,
    DELETE_COMMENTS,
    EDIT_OR_DELETE_TOP_NOTE,
    EDIT_OR_DELETE_TASK_INBOX,
    EDIT_CHECK_TIME,
    ONLINE_PAYMENT_NOTIFICATION,
    E_SIGNED_DOCUMENT_NOTIFICATION,
    E_SIGNED_ESTIMATE_NOTIFICATION,
    E_SIGNED_INVOICE_NOTIFICATION,
    INBOUND_LEADS_NOTIFICATION,
    JOB_CONFIRMATION_NOTIFICATION,
    JOB_RESCHEDULE_NOTIFICATION,
    REVIEWS_NOTIFICATION,
    SUBSCRIPTION_NOTIFICATION,
    ONLINE_BOOKING_NOTIFICATION,
    SALES_COMMISSION,
    MANAGE_TODO_TEMPLATES,
    SHOW_WORK_POOL,
    APP_SEARCH_CUSTOMER,
    APP_ADD_CUSTOMER,
    APP_EDIT_CUSTOMER,
    APP_ADD_JOB,
    APP_EDIT_JOB,
    DELETE_JOBS,
    MOVE_ALL_RECURRING,
    APP_STRIPE_CARD,
    SHOW_WEATHER,
    APP_INVOICE_CARD,
    EDIT_OR_DELETE_TIMEOFF,
    EDIT_OR_DELETE_EVENT,
    INBOX,
    // EDIT_SCHEDULE_COLOR,
    MANAGE_NOTE_TEMPLATES,
    ACCESS_DASHBOARD,
    ACCESS_MANAGE_BRANCH
];

const TECHNICIAN_ALL_PERMISSION = [
    ONLINE_BOOKING_NOTIFICATION,
    SUBSCRIPTION_NOTIFICATION,
    REVIEWS_NOTIFICATION,
    ONLINE_PAYMENT_NOTIFICATION,
    JOB_RESCHEDULE_NOTIFICATION,
    JOB_CONFIRMATION_NOTIFICATION,
    E_SIGNED_INVOICE_NOTIFICATION,
    E_SIGNED_ESTIMATE_NOTIFICATION,
    E_SIGNED_DOCUMENT_NOTIFICATION,
    EDIT_CHECK_TIME,
    EDIT_OR_DELETE_TASK_INBOX,
    EDIT_OR_DELETE_TOP_NOTE,
    DELETE_COMMENTS,
    SMS_FILTER_BY_USER,
    EDIT_OR_DELETE_NOTES,
    APP_INVOICE_CARD,
    SHOW_WEATHER,
    APP_STRIPE_CARD,
    MOVE_ALL_RECURRING,
    DELETE_JOBS,
    APP_EDIT_JOB,
    APP_ADD_JOB,
    APP_EDIT_CUSTOMER,
    APP_ADD_CUSTOMER,
    APP_SEARCH_CUSTOMER,
    ACCESS_MOBILE_APP,
    ACCESS_DESKTOP,
    ACCESS_SETTINGS_TAB,
    ACCESS_REPORT_TAB,
    ACCESS_CUSTOMERS_TAB,
    SALES_COMMISSION,
    MANAGE_TODO_TEMPLATES,
    SHOW_WORK_POOL,
    EDIT_OR_DELETE_TIMEOFF,
    EDIT_OR_DELETE_EVENT,
    INBOX,
    MANAGE_NOTE_TEMPLATES,
    ADD_TIMEOFF,
    ADD_EVENT,
    ACCESS_DASHBOARD,
    CONTACT_INFORMATION,
    WO_OPTIONS,
    DOCUMENT_CARD,
    ACCESS_ADDONS,
    MISSED_CALL_NOTIFICATION
];

const ADMIN_ALL_PERMISSION = [...ADMIN_DEFAULT_PERMISSION, ACCESS_ADDONS, MISSED_CALL_NOTIFICATION];

export {
    ADMIN_PERMISSION,
    ADMIN_DEFAULT_PERMISSION,
    TECHNICIAN_PERMISSIONS,
    TECHNICIAN_DEFAULT_PERMISSION,
    TABS,
    DEVICES,
    MOBILE,
    DESKTOP_MOBILE,
    ACCESS,
    NOTIFICATIONS,
    LIST_AVATAR_DEFAULT,
    SUB_PERMISSION_MAP,
    ADMIN_ALL_PERMISSION,
    TECHNICIAN_ALL_PERMISSION,
    E_SIGNED_DOCUMENTS,
    JOB_CONFIRMATIONS,
    JOB_RESCHEDULES
};
