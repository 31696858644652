import { JOB_DETAIL_ACTIONS } from 'common/redux/actions/job/detail';

const jobReducer = (
    state = {
        // jobDetail: {
        //     id: 369473
        // },
        jobDetail: null,
        closeJobDetail: 0
    },
    action
) => {
    switch (action.type) {
        case JOB_DETAIL_ACTIONS.JOB_DETAIL:
            return { ...state, jobDetail: action.payload };
        case JOB_DETAIL_ACTIONS.CLOSE_JOB_DETAIL:
            return { ...state, jobDetail: null, closeJobDetail: Date.now() };

        default:
            return state;
    }
};

export default jobReducer;
