export default function IconColumns({ isHasColor = false, isChecklist = false, isCheckListColor = false }) {
    if (isHasColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 5H5.5C6.32843 5 7 5.67157 7 6.5C7 7.32843 6.32843 8 5.5 8H4.5C3.67157 8 3 7.32843 3 6.5C3 5.67157 3.67157 5 4.5 5Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 10H5.5C6.32843 10 7 10.6716 7 11.5C7 12.3284 6.32843 13 5.5 13H4.5C3.67157 13 3 12.3284 3 11.5C3 10.6716 3.67157 10 4.5 10Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 15H5.5C6.32843 15 7 15.6716 7 16.5C7 17.3284 6.32843 18 5.5 18H4.5C3.67157 18 3 17.3284 3 16.5C3 15.6716 3.67157 15 4.5 15Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 5H18.5C19.3284 5 20 5.67157 20 6.5C20 7.32843 19.3284 8 18.5 8H10.5C9.67157 8 9 7.32843 9 6.5C9 5.67157 9.67157 5 10.5 5Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 10H18.5C19.3284 10 20 10.6716 20 11.5C20 12.3284 19.3284 13 18.5 13H10.5C9.67157 13 9 12.3284 9 11.5C9 10.6716 9.67157 10 10.5 10Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.5 15H18.5C19.3284 15 20 15.6716 20 16.5C20 17.3284 19.3284 18 18.5 18H10.5C9.67157 18 9 17.3284 9 16.5C9 15.6716 9.67157 15 10.5 15Z"
                    fill="#C1C9D4"
                />
            </svg>
        );
    }
    if (isChecklist) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x={10} y={6} width={12} height={2} rx={1} fill="#7A83A6" />
                <rect x={10} y={14} width={8} height={2} rx={1} fill="#7A83A6" />
                <circle cx={5} cy={7} r={3} fill="#C1C9D4" />
                <circle cx={5} cy={15} r={3} fill="#C1C9D4" />
                <path d="M3.5 7L4.5 8L6.5 6" stroke="#7A83A6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.5 15L4.5 16L6.5 14" stroke="#7A83A6" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    if (isCheckListColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="10" y="6" width="12" height="2" rx="1" fill="#8d4afc"></rect>
                <rect x="10" y="14" width="8" height="2" rx="1" fill="#8d4afc"></rect>
                <circle cx="5" cy="7" r="3" fill="#dac2f2"></circle>
                <circle cx="5" cy="15" r="3" fill="#dac2f2"></circle>
                <path d="M3.5 7L4.5 8L6.5 6" stroke="#8d4afc" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M3.5 15L4.5 16L6.5 14" stroke="#8d4afc" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 6.5C7.60457 6.5 8.5 7.39543 8.5 8.5C8.5 9.60457 7.60457 10.5 6.5 10.5C5.39543 10.5 4.5 9.60457 4.5 8.5C4.5 7.39543 5.39543 6.5 6.5 6.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 8.5H19.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 13.5C7.60457 13.5 8.5 14.3954 8.5 15.5C8.5 16.6046 7.60457 17.5 6.5 17.5C5.39543 17.5 4.5 16.6046 4.5 15.5C4.5 14.3954 5.39543 13.5 6.5 13.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 15.5H16.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
