import React from 'react';

const MultiUnitManagerAddonsLoading = () => {
    return (
        <div className="boxs-wrapper multi-unit-settings">
            <div className="manage-tracking">
                <div className="manage-tracking__title flex-1 mb-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="manage-tracking__desc flex-1 mb-4">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="manage-tracking__content">
                    <div className="rows">
                        <div className="flex-1">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch loading --onefifth pl-5">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows">
                        <div className="flex-1">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch loading --onefifth pl-5">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows">
                        <div className="flex-1">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch loading --onefifth pl-5">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows">
                        <div className="flex-1">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="switch loading --onefifth pl-5">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MultiUnitManagerAddonsLoading;
