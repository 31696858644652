export const LoadingVoicemailDrop = () => {
    return (
        <div className="box-voip wrap-loading bg-white">
            <div className="box-voip__rows flexcenter flex-wrap">
                <div className="flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="loading --animation --line --onefifth" />
                <div className="loading --animation --line --full mt-3" />
                <div className="loading --animation --line --onefourth" />
                <div className="audio-loading loading --full mt-3" />
                <div className="d-flex gap-8 mt-3 w-100">
                    <div className="v2-btn-default loading --grey flex-1 justify-center">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey flex-1 justify-center">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        </div>
    );
};
