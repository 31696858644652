import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { cardUpdate } from 'app/const/api/V2';
import { PAYMENT_MERCHANT } from 'app/const/customer/CustomerPayment';
import IconStripe from 'assets/icon/IconStripe';
import { clientQuery } from 'common/utils/ApiUtils';
import AdditionalOptions from './AdditionalOptions';
import LogsCard from './LogsCard';
import { validateCardExpiration } from 'app/components/payment/utils';
import { LIST_STATUS } from 'app/const/Status';

const EditCard = ({ onUpdateSuccess = () => {} }, ref) => {
    const { id: customerId } = useParams();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, dataCard: {} });
    const { isOpen, dataCard } = state;
    const refMeta = useRef(null);
    const refDate = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refAdditional = useRef(null);

    useImperativeHandle(ref, () => ({ _handleOpen, _handleClose }));

    function _handleOpen(dataCard) {
        const exp_month = dataCard?.exp_month?.toString()?.padStart(2, '0');
        dispatchState({ isOpen: true, dataCard: { ...dataCard, exp_month } });
    }

    function _handleClose() {
        dispatchState({ isOpen: false, dataCard: {} });
    }

    function _handleSubmit() {
        if (!dataCard.id) alert("Don't have card id");
        const { month: exp_month, year: exp_year } = refDate.current.getValue();
        const errorMessage = validateCardExpiration(exp_month, `20${exp_year}`);
        if (errorMessage) {
            refAlert.current?.showStatusBar('show_error_card_edit', errorMessage, LIST_STATUS.ERROR);
            refButtonSave.current?.removeLoading();
            return;
        }

        function _handleSubmitSuccess({ data }) {
            _handleClose();
            onUpdateSuccess(data);
        }

        function _handleSubmitFailed({ message }) {
            refAlert.current?.showStatusBar('show_error_card_edit', message || 'Please try again!', LIST_STATUS.ERROR);
            refButtonSave.current?.removeLoading();
        }

        clientQuery(
            cardUpdate(customerId),
            {
                data: {
                    account_number: dataCard.account_number,
                    exp_month,
                    exp_year: `20${exp_year}`,
                    meta: refMeta.current?.getValue() || {},
                    merchant: PAYMENT_MERCHANT.STRIPE_GATE_WAY,
                    card_id: dataCard.id,
                    edit_card: 1
                },
                toFormData: false,
                method: 'PUT'
            },
            _handleSubmitSuccess,
            _handleSubmitFailed
        );
    }

    function _handleExpand(isOpen) {
        if (isOpen) refAdditional.current.classList.add('active');
        else refAdditional.current.classList.remove('active');
    }

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="modal-edit-stripe"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-methods open"
            onRequestClose={_handleClose}
            ariaHideApp={false}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div ref={refAdditional} className="modal__container form-addcard stripe-additional">
                <div className="header-modal">
                    <h3 className="header-modal__label">Edit Credit Card in Stripe</h3>
                </div>
                <div className="content has-form scrolls">
                    <StatusBar ref={refAlert} />
                    <IconStripe hasColor />
                    <div className="content-info">
                        <div className="rows">
                            <div className="txt relative">Card Number</div>
                            <input
                                type="text"
                                className="field-input number-card is-disable"
                                defaultValue={`**** **** **** ${dataCard.account_number}`}
                            />
                        </div>
                        <div className="rows d-flex mt-5">
                            <div className="col col-cvc">
                                <div className="txt">Expiry</div>
                                <DateInput ref={refDate} defaultValue={`${dataCard.exp_month}/${dataCard.exp_year}`} />
                            </div>
                            <div className="col col-cvc">
                                <div className="txt">CVC</div>
                                <input className="field-input is-disable" type="text" defaultValue={'***'} />
                            </div>
                        </div>
                    </div>
                    <AdditionalOptions ref={refMeta} defaultValue={dataCard.meta || {}} onExpand={_handleExpand} />
                </div>
                <LogsCard id={dataCard.id} />
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        Cancel
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                </div>
            </div>
        </ReactModal>
    );
};

const DateInput = forwardRef(({ defaultValue = '' }, ref) => {
    const [value, setValue] = React.useState(defaultValue);

    useImperativeHandle(ref, () => ({ getValue }));

    function getValue() {
        const [month, year] = value.split('/');
        return { month, year };
    }

    const handleChange = (e) => {
        const input = e.target.value;
        // Remove any non-digit characters from the input
        const sanitizedInput = input.replace(/\D/g, '');

        // Format the input as "mm/yy"
        let formattedInput = '';
        if (sanitizedInput.length >= 3) {
            let month = sanitizedInput.slice(0, 2);
            const year = sanitizedInput.slice(2, 4);
            if (month > 12) {
                month = '12';
            }
            formattedInput = `${month}/${year}`;
        } else {
            formattedInput = sanitizedInput;
        }

        setValue(formattedInput);
    };

    return (
        <input
            className="field-input"
            type="text"
            value={value}
            autoFocus
            placeholder="MM/YY"
            maxLength={5}
            onChange={handleChange}
        />
    );
});

export default forwardRef(EditCard);
