import React from 'react';

const ListDocumentLoading = () => {
    return (
        <div className="wrap-loading --addon">
            <div className="list">
                <div className="list__document bg-white">
                    <div className="rows">
                        <div className="rows__icon">
                            <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                        </div>
                        <div className="rows__name">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="rows__action">
                            <div className="v2-btn-default --transparent loading btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__icon">
                            <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                        </div>
                        <div className="rows__name">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__action">
                            <div className="v2-btn-default --transparent loading btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__icon">
                            <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                        </div>
                        <div className="rows__name">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__action">
                            <div className="v2-btn-default --transparent loading btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__icon">
                            <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                        </div>
                        <div className="rows__name">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__action">
                            <div className="v2-btn-default --transparent loading btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="rows__icon">
                            <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                        </div>
                        <div className="rows__name">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="rows__action">
                            <div className="v2-btn-default --transparent loading btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListDocumentLoading;
