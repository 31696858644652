import { call, select } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_REVENUE_BY_STAFF_MONTH_GET_LIST,
    REPORT_REVENUE_BY_STAFF_DETAIL_GET_LIST,
    REPORT_REVENUE_STAFF_METHOD_FILTER
} from 'app/const/Api';

export function* getListReportRevenueByStaffMonth({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_STAFF_MONTH_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_customer');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportRevenueStaffPayment({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_STAFF_DETAIL_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_payment');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterRevenueByStaff({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_STAFF_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListServiceFilterRevenueByStaff({ actionSuccess, actionFailed }) {
    try {
        const users = yield select(({ companyUsers }) => companyUsers?.users || []);
        const newUsers = users.map((item) => ({ id: item.id, name: item.full_name })) || [];
        actionSuccess({ data: newUsers });
    } catch (error) {
        actionFailed(error);
    }
}
