import GridLoading from 'app/components/grid/components/GridLoading';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { VERIFY_NUMBER_VOIP } from 'app/const/api/Voip';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ManagePhones from 'app/modules/layouts/GDVoip/settings/ManagePhones';
import IconClose from 'assets/icon/IconClose';
import IconTelephone from 'assets/icon/IconTelephone';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import ServiceVerifyNumber from './ServiceVerifyNumber';
import ServiceManagePhones from 'app/modules/layouts/GDVoip/settings/ServiceManagePhones';

const VOIPAddonsVerifyNumber = ({ typeModal, onAddSuccess = () => {}, onClose = () => {} }, ref) => {
    const { t } = useTranslation();

    const refAlert = useRef(null);
    const refManagePhone = useRef(null);
    const refIsOpen = useRef(true);
    const refServiceManagePhone = useRef(null);

    const TAB_VERIFY = {
        CALL_NOW: 'call_now',
        SHOW_CODE: 'show_code',
        DONE: 'done'
    };

    const initialState = {
        isOpen: false,
        tabContent: TAB_VERIFY.CALL_NOW,
        isLoadingCode: true,
        country: '',
        number: '',
        code: '',
        numbers: []
    };

    const [state, dispatchState] = useReducer(reducer, { ...initialState, typeModal });

    const { isOpen, tabContent, isLoadingCode, number, code, numbers } = state;
    const numberFormat = formatPhoneNumberVoip(`+${number}`);

    useEffect(() => {
        if (!isOpen) dispatchState({ ...initialState, typeModal });
        refIsOpen.current = isOpen;
    }, [isOpen]);

    useEffect(() => {
        if (tabContent === TAB_VERIFY.DONE) {
            refServiceManagePhone.current.getNumbers();
        }
    }, [tabContent]);

    useImperativeHandle(ref, () => ({
        _setVisible,
        _setData
    }));

    const _setVisible = (value) => {
        dispatchState({ isOpen: value });
    };

    const _setData = (data) => {
        dispatchState((prev) => ({ ...prev, ...data }));
    };

    const _close = () => {
        _setVisible(false);
        tabContent === TAB_VERIFY.DONE && onClose();
    };

    const _renderTabCallNow = () => {
        return (
            <div className="text-center">
                <p className="fs-24 fw-600 mb-1">{numberFormat}</p>
                <p>{t('addons:needs_to_be_verified')}</p>
                <p className="my-3">{t('addons:desc_verify_call_now')}</p>
                <div
                    className="v2-btn-default has-icon has-bg-blue white svg-white-stroke --transparent"
                    onClick={_handleClickCallMeNow}
                >
                    <IconTelephone isNormal />
                    {t('addons:call_me_now')}
                </div>
            </div>
        );
    };

    const _renderTabShowCode = () => {
        return (
            <div className="text-center ">
                <p className="mb-2">
                    {t('addons:calling')} <span className="fw-600">{numberFormat}</span>
                </p>
                <GridLoading />
                <div className="line" />
                <p className="mb-1">{t('addons:answer_and_enter_the_verification_code')}</p>
                {isLoadingCode ? <GridLoading /> : <h1 className="verify-code">{code}</h1>}
            </div>
        );
    };

    const _renderTabDone = () => {
        return (
            <div className="text-center ">
                <p className="fs-24 fw-600 mb-1">{numberFormat}</p>
                <p>{t('addons:has_been_verified')}</p>
                <p className="my-3">{t('addons:set_phone_number_as_caller_id')}</p>
                <ManagePhones
                    ref={refManagePhone}
                    isCallSetting={false}
                    finalNumbers={numbers}
                    classWrapper="nav-call-module"
                    classWrapperDropdown="caller select-caller flexcenter mb-3"
                />

                <div className="v2-btn-default has-bg-blue white px-4 --transparent" onClick={_close}>
                    {t('addons:done')}
                </div>
            </div>
        );
    };

    const _renderTab = () => {
        switch (tabContent) {
            case TAB_VERIFY.CALL_NOW:
                return _renderTabCallNow();
            case TAB_VERIFY.SHOW_CODE:
                return _renderTabShowCode();
            case TAB_VERIFY.DONE:
                return _renderTabDone();
            default:
                return null;
        }
    };

    const _handleClickCallMeNow = () => {
        const _handleSuccess = (res) => {
            dispatchState({ code: res.data.code, isLoadingCode: false });
        };

        const _handleCallFail = (err) => {
            _handleFail(err);
            dispatchState({ tabContent: TAB_VERIFY.CALL_NOW });
        };

        clientQuery(
            VERIFY_NUMBER_VOIP,
            {
                method: 'POST',
                toFormData: false,
                data: {
                    type: typeModal,
                    number
                }
            },
            _handleSuccess,
            _handleCallFail
        );

        dispatchState({ tabContent: TAB_VERIFY.SHOW_CODE });
    };

    const _handleFail = (err) => {
        refAlert.current?.showStatusBar({ id: 'verify_number', message: err.message, type: LIST_STATUS.ERROR });
    };

    const _serviceVerifySuccess = ({ number_info }) => {
        if (!refIsOpen.current) return;
        dispatchState({ tabContent: TAB_VERIFY.DONE });
        onAddSuccess({
            assignees: [],
            is_primary: false,
            name: '',
            role: '',
            forwarding: { enable: 0, number: '' },
            voicemail: { enable: 0, greeting: '' },
            recording_disclosure: { enable: 0, statement: '' },
            ...number_info
        });
    };

    const _serviceVerifyFail = () => {
        if (!refIsOpen.current) return;
        dispatchState({ tabContent: TAB_VERIFY.CALL_NOW, isLoadingCode: true });
        _handleFail({ message: t('addons:desc_verify_number_fail') });
    };

    const _handleGetNumbersSuccess = (numbers) => {
        dispatchState({ numbers });
    };

    return (
        <Fragment>
            <ReactModal isOpen={isOpen} id="modal_verify_number" className="modal modal-verify-number open">
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container large">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('addons:verify_external_number')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>

                    <div className="body-modal pt-0 pb-6 js-verify-modal">
                        <div className="py-3">
                            <AlertCustomer ref={refAlert} />
                        </div>
                        {_renderTab()}
                    </div>
                </div>
            </ReactModal>
            <ServiceVerifyNumber onSuccess={_serviceVerifySuccess} onFail={_serviceVerifyFail} />
            <ServiceManagePhones ref={refServiceManagePhone} onGetListSuccess={_handleGetNumbersSuccess} />
        </Fragment>
    );
};

export default forwardRef(VOIPAddonsVerifyNumber);
