import { CREATE_JOB_ACTIONS } from 'common/redux/actions/job/create';

const createJob = (
    state = {
        jobData: null,
        newJobData: null
    },
    action
) => {
    switch (action.type) {
        case CREATE_JOB_ACTIONS.CREATE_JOB:
            return {
                ...state,
                jobData: action.payload,
                createLocation: action.createLocation,
                updateUnit: action.updateUnit
            };
        case CREATE_JOB_ACTIONS.CREATE_JOB_SUCCESS:
            return { ...state, newJobData: action.payload };
        default:
            return state;
    }
};

export default createJob;
