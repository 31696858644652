export const LOCATION_ACTIONS = {
    OPEN_LOCATION: 'OPEN_LOCATION'
};

export const openLocation = (payload) => {
    return {
        type: LOCATION_ACTIONS.OPEN_LOCATION,
        payload
    };
};
