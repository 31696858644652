import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_MULTIPLE_BRANCH_MANAGE } from 'app/config/routes';
import { ADDONS_MULTIPLE_BRANCH_DETAIL } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/App';
import { addBranchPath, isFetchedBranch } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { transformToCurrency } from 'common/utils/NumberUtils';
import PlanChooseCard from '../../voip/components/plan/PlanChooseCard';
import { FIELD_COPY_DATA, FIELD_INPUT_COMPANY } from '../const';
import FieldInputPlan from './FieldInputPlan';
import FieldUploadAvatar from './FieldUploadAvatar';

const PlanStepTwo = ({ data = {} }) => {
    const { t } = useTranslation(['addons']);
    const history = useHistory();
    const refValues = useRef({});
    const refCopy = useRef({});
    const refCard = useRef({});
    const refAlert = useRef(null);
    const refData = useRef({});
    const refFieldAvatar = useRef(false);

    const { planSelected, plans, handleBack = () => {} } = data;
    const { term, package: finalPackage, schedules: finalSchedules } = planSelected;

    const _renderFieldInput = () => {
        return Object.entries(FIELD_INPUT_COMPANY).map(([key, value]) => (
            <FieldInputPlan
                key={key}
                ref={(ref) => (refValues.current[value] = ref)}
                label={key.toLowerCase()}
                name={value}
            />
        ));
    };

    const _renderFieldCheckbox = () => {
        const _handleChecked = ({ fieldName, value }) => {
            if (!value) {
                delete refCopy.current[fieldName];
                return;
            }
            refCopy.current[fieldName] = value;
        };
        return Object.entries(FIELD_COPY_DATA).map(([key, value]) => (
            <FieldCheckbox
                key={key}
                id={key}
                label={key.toLowerCase()}
                onChecked={(e) => _handleChecked({ fieldName: value, value: e.target.checked })}
            />
        ));
    };

    const _handleClickActive = () => {
        let flag = false;
        const newData = {};
        Object.entries(refValues.current).forEach(([key, value]) => {
            const finalValue = value._getValue();
            if (!finalValue) flag = true;
            newData[key] = finalValue;
        });
        if (flag) return;

        const { id: plan_id, type: plan_type } = plans[term][finalPackage].find(
            (item) => item.schedule_num === finalSchedules
        );
        const { url: urlImage, avtObject } = refFieldAvatar.current.getValue();

        refData.current = {
            ...newData,
            plan_id,
            plan_type,
            copy_data: Object.keys(refCopy.current),
            avatar: avtObject || urlImage
        };
        refCard.current._open();
    };

    const _handleActivePlan = async ({ isNewCard = false, cardDetail = {} }) => {
        const newData = { ...refData.current };

        if (isNewCard) {
            newData.stripe_card_token = cardDetail.token.id;
        }

        const _handleSuccess = () => {
            isFetchedBranch.isFetched = false;
            history.push({ pathname: addBranchPath(ADDONS_MULTIPLE_BRANCH_MANAGE), state: { isCreateSuccess: true } });
            refCard.current._close();
        };

        const _handleFail = ({ message }) => {
            refAlert.current?.showStatusBar('multi_branch', message, LIST_STATUS.ERROR);
            refCard.current?._close();
        };

        clientQuery(
            ADDONS_MULTIPLE_BRANCH_DETAIL,
            { method: 'POST', toFormData: false, data: newData },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleSetDisableBtnSave = (value = false) => {
        refCard.current?._setDisable(value);
    };

    return (
        <div className="wrap-plans flex-column gap-12">
            <GDStatusBar ref={refAlert} />

            <div className="flex-column gap-6">
                <h3 className="fs-14 fw-600">{t('branch_settings')}</h3>
                <div className="box-plans --lg flex-column gap-8">
                    <FieldUploadAvatar
                        ref={refFieldAvatar}
                        onUpFile={() => _handleSetDisableBtnSave(true)}
                        onUpFileSuccess={() => _handleSetDisableBtnSave(false)}
                    />
                    {_renderFieldInput()}
                </div>
            </div>
            <div className="flex-column gap-6">
                <h3 className="fs-14 fw-600">{t('copy_data_from_primary_company')}</h3>
                <div className="box-plans --lg box-checkboxs flex-column gap-12">{_renderFieldCheckbox()}</div>
            </div>
            <div className="is-divider --horizontal" />
            <BoxTotal planSelected={planSelected} plans={plans} />
            <div className="flex-betweenitems">
                <div className="v2-btn-default has-icon --grey" onClick={handleBack}>
                    <IconArrow isPrev />
                    {t('back')}
                </div>
                <div className="v2-btn-default has-bg-blue white --transparent" onClick={_handleClickActive}>
                    {t('active_plan_and_create')}
                </div>
            </div>
            <PlanChooseCard ref={refCard} onChooseCardSuccess={_handleActivePlan} />
        </div>
    );
};

export default PlanStepTwo;

const FieldCheckbox = ({ id = '', label = '', onChecked = () => {} }) => {
    const { t } = useTranslation(['setting']);

    return (
        <div className="check-items">
            <input id={id} type="checkbox" name="copy_data" onChange={onChecked} />
            <div className="item-checkbox">
                <label htmlFor={id}>
                    <span className="txt-ellipsis">{t(label)}</span>
                </label>
            </div>
        </div>
    );
};

const BoxTotal = ({ planSelected = {}, plans = {} }) => {
    const { t } = useTranslation(['addons']);
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const { package: finalPackage, schedules, term } = planSelected;
    const dataPackage = plans[term][finalPackage.toLowerCase()].find((item) => item.schedule_num === schedules);
    const totalMoney = t(`price_per_${term}`, { price: transformToCurrency(dataPackage.price, currency) });

    return (
        <div className="box-plans --lg flex-column gap-10 black fs-14">
            <div className="flex-betweenitems">
                <span>{t(`${finalPackage}_plan`)}</span>
                <span className="blue-default fw-600"></span>
            </div>
            <div className="flex-betweenitems">
                <span>{t('activated_schedules_number_includes_one_schedule_free', { number: schedules })}</span>
                <span className="blue-default fw-600"></span>
            </div>
            <div className="is-divider --horizontal" />
            <div className="flex-betweenitems fw-600">
                <span>{t('total')}</span>
                <span className="blue-default fs-16">{totalMoney}</span>
            </div>
            <div className="note-billing flex-centeritem gap-4 fs-12">
                {t(`future_${term}_branch_plan_billing`)} <span className="blue-default">{totalMoney}</span>
            </div>
        </div>
    );
};
