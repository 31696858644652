/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from 'react';
import FormTimeWindow from 'app/modules/jobdetail/tabs/detail/timewindow/form';
import { TabDetailContext } from 'app/modules/jobdetail/contexts/TabDetailContext';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { convertTimeNumber } from 'common/utils/TimeUtils';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateTimeWindowJob } from 'app/const/api/V2';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { actionUpdateJobTimeWindow } from 'common/redux/actions/job';
import { MODES_TIME_WINDOW } from './Const';
import { actionReloadCalendar } from 'common/redux/actions/calendar';
import { useTranslation } from 'react-i18next';

export default function TimeWindow({ isLoading }) {
    const { t } = useTranslation();
    const { jobData, updateJobDataContext } = useContext(TabDetailContext);
    const { settings: userSetting, company: companySetting } = useSelector(({ auth }) => auth.user);

    const isActiveTimeWindow = userSetting?.addons?.time_window || false;

    const refFormTimeWindow = useRef(null);
    const dispatch = useDispatch();

    const returnTime = (timeNumber) => {
        if (!timeNumber) {
            return '12:00 AM';
        }
        const { hour, minute, midDay } = convertTimeNumber(timeNumber);
        return `${hour}:${minute} ${midDay}`;
    };
    const jobEventData = jobData.event || {};
    const dataTime = jobEventData?.time_window || {};

    const timeStart = returnTime(dataTime.start);
    const timeEnd = returnTime(dataTime.end);

    const _handleOpen = () => {
        refFormTimeWindow.current.openForm({
            ...dataTime,
            timeStart,
            timeEnd,
            jobInfo: { name: jobData.job.name, start: jobEventData.start, end: jobEventData.end }
        });
    };

    const _updateScheduleSuccess = (response, newData, callbackSuccess) => {
        if (isActiveTimeWindow) {
            dispatch(actionReloadCalendar());
            dispatch(
                actionUpdateJobTimeWindow({
                    id: jobData.job.id,
                    time_window:
                        newData.id === MODES_TIME_WINDOW.ANY_TIME
                            ? null
                            : {
                                  start: convertTimeToISO(
                                      moment(
                                          `${moment(jobData.event.start).utc().format('MM-DD-YYYY')} ${returnTime(
                                              newData.start
                                          )}`,
                                          'MM-DD-YYYY hh:mm A'
                                      )
                                  ),
                                  end: convertTimeToISO(
                                      moment(
                                          `${moment(jobData.event.start).utc().format('MM-DD-YYYY')} ${returnTime(
                                              newData.end
                                          )}`,
                                          'MM-DD-YYYY hh:mm A'
                                      )
                                  )
                              }
                })
            );
        }

        updateJobDataContext({ event: { ...jobData?.event, time_window: newData } });
        callbackSuccess();
    };

    const _handleSave = (newData, callbackSuccess, callbackFailed) => {
        clientQuery(
            updateTimeWindowJob(jobData.job.id),
            { method: 'PUT', data: { all: '2', time_window: newData } },
            (response) => _updateScheduleSuccess(response, newData, callbackSuccess),
            callbackFailed
        );
    };

    if (!!!isActiveTimeWindow) {
        return false;
    }

    if (isLoading) {
        return (
            <div className="wrap-loading details-job mt-3">
                <div className="rows">
                    <div className="txt flex-auto">
                        <div className="loading --animation --line --half"></div>
                    </div>
                    <div className="details flexcenter">
                        <div className="loading --animation --line --twothird flex-1 mr-2"></div>
                        <div className="loading v2-btn-default --grey --icon-lg">
                            <div className="loading --animation --line --half"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const _contentTime = () => {
        if (dataTime.id === '-1') {
            const { start, end } = companySetting?.business_hours || {};
            return `${moment(start, 'HH:mm').format('hh:mm A')} to ${moment(end, 'HH:mm').format('hh:mm A')}`;
        }
        return `${timeStart} to ${timeEnd}`;
    };

    return (
        <>
            <div className="details-job__elm rows has-line-bottom">
                <div className="txt">{t('time_window')}</div>
                <div className="details flexcenter">
                    <div className="flex-1 mr-3">{_contentTime()}</div>
                    <div onClick={_handleOpen} className="v2-btn-default --icon-lg btn-modal">
                        <IconCogWheel />
                    </div>
                </div>
            </div>
            <FormTimeWindow ref={refFormTimeWindow} onSubmit={_handleSave} isJobDetail />
        </>
    );
}
