import { TASK_LIST_ACTIONS } from 'common/redux/actions/calendar/listTaskAction';

const ListTaskReducers = (state = {
    taskUpdateStatus: null,
    taskUpdateRealtime: null
}, action) => {
    switch (action.type) {
        case TASK_LIST_ACTIONS.UPDATE_TASK_STATUS:
            return { ...state, taskUpdateRealtime: null, taskUpdateStatus: action.payload };
        case TASK_LIST_ACTIONS.UPDATE_TASK_STATUS_REALTIME:
            return { ...state, taskUpdateStatus: null, taskUpdateRealtime: action.payload };
        default:
            return state;
    }
};

export default ListTaskReducers;
