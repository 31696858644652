const IconClockInOut = ({ type = '' }) => {
    switch (type) {
        case 'addons':
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#FFF"
                    />
                    <circle opacity="0.35" cx={16} cy={16} r={10} fill="#1EAA5C" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="#1EAA5C"
                    />
                </svg>
            );

        case 'inactive':
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={4} fill="#1C1F22" fillOpacity="0.06" />
                    <circle opacity="0.35" cx={16} cy={16} r={10} fill="#5F6164" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="#5F6164"
                    />
                </svg>
            );

        case 'active':
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#E061E2" />
                    <circle cx={16} cy={16} r={10} fill="#EDB8EE" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="white"
                    />
                </svg>
            );

        default:
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={12} fill="#F9E2FA" />
                    <circle cx={16} cy={16} r={10} fill="#EDB8EE" />
                    <path
                        d="M12.576 23.2302C12.3396 23.7294 12.5514 24.3314 13.0737 24.511C13.886 24.7903 14.7359 24.9524 15.5975 24.991C16.7929 25.0445 17.9869 24.8591 19.1098 24.4457C20.2327 24.0322 21.2618 23.399 22.137 22.5831C23.0123 21.7671 23.716 20.7849 24.2071 19.6937C24.6982 18.6025 24.9668 17.4244 24.9971 16.2282C25.0275 15.032 24.819 13.8418 24.3838 12.7272C23.9487 11.6125 23.2957 10.5958 22.4629 9.73657C21.8627 9.11722 21.1778 8.58862 20.43 8.16578C19.9493 7.89393 19.3582 8.13463 19.1413 8.64255L18.1303 11.0106C17.9134 11.5185 18.1589 12.0979 18.6053 12.4231C18.8095 12.5718 19.0011 12.7381 19.1777 12.9204C19.5872 13.3428 19.9083 13.8427 20.1222 14.3908C20.3362 14.9389 20.4387 15.5241 20.4238 16.1122C20.4088 16.7004 20.2768 17.2796 20.0353 17.8161C19.7939 18.3527 19.4478 18.8356 19.0175 19.2368C18.5872 19.638 18.0811 19.9493 17.529 20.1526C16.9769 20.3559 16.3898 20.4471 15.8021 20.4208C15.5485 20.4094 15.297 20.3763 15.0503 20.3221C14.5109 20.2035 13.9144 20.404 13.678 20.9032L12.576 23.2302Z"
                        fill="#E061E2"
                    />
                </svg>
            );
    }
};

export default IconClockInOut;
