import { reducer } from 'app/const/Reducer';
import { COMMON } from 'app/const/App';
import { REVENUE, SALES_TAX_SUMMARY_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import FilterReport from 'app/modules/report/components/FilterReport';
import React, { useReducer, useEffect } from 'react';

export default function SalesTaxSummaryFilter({ initRevenue, onChangeDisableButtonSave = () => {} }) {
    const [state, dispatchState] = useReducer(reducer, {
        forceRerender: false,
        listFilter: [...SALES_TAX_SUMMARY_LIST_FILTER.ROW_1]
    });

    const { forceRerender, listFilter } = state;

    const createListFilter = (revenueValue) => {
        const result = [...SALES_TAX_SUMMARY_LIST_FILTER.ROW_1];
        if (revenueValue === 'accrual') {
            result.pop();
        }
        return result;
    };

    useEffect(() => {
        dispatchState({ listFilter: createListFilter(initRevenue) });
    }, []);

    const _handleChangeFilter = (params, type) => {
        switch (type) {
            case REVENUE:
                dispatchState({
                    listFilter: createListFilter(params.revenue),
                    forceRerender: params.revenue === COMMON.CASH
                });
                break;
            default:
                break;
        }
        !!type && onChangeDisableButtonSave(false);
    };

    return (
        <FilterReport
            reportType={REPORT_TYPE.SALES_TAX_SUMMARY}
            filters={listFilter}
            onSelect={_handleChangeFilter}
            forceRerender={forceRerender}
        />
    );
}
