import React from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { CALENDAR_MODES } from 'app/modules/calendar/const';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const Today = ({ onToday = () => {} }) => {
    const { t } = useTranslation(['calendar']);
    const { date, view: finalCalendarView } = useSelector(({ calendar }) => calendar);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const currentDateUtc = moment().utc();

    function _selectToday() {
        const newDate = moment().utc().unix();

        if (moment.unix(newDate).format('MM-DD-YYYY') === moment(date).utc().format('MM-DD-YYYY')) {
            onToday(false);
            return;
        }

        if (
            (finalCalendarView === CALENDAR_MODES.AGENDA_WEEK ||
                finalCalendarView === CALENDAR_MODES.AGENDA_2_WEEK ||
                finalCalendarView === CALENDAR_MODES.DAY_GRID_MONTH) &&
            moment.utc(start).unix() <= newDate &&
            newDate <= moment.utc(end).subtract(1, 'd').unix()
        ) {
            // Should fetch is false because we don't want to get the data from the server
            onToday(!_handleGetActiveToday());
        } else {
            onToday();
        }
    }

    const _handleGetActiveToday = () => {
        if (finalCalendarView === CALENDAR_MODES.DAY_GRID_MONTH)
            return (
                moment(currentDateUtc).utc().isAfter(moment(date).startOf('month').utc()) &&
                moment(currentDateUtc).utc().isBefore(moment(date).endOf('month').utc())
            );

        return (
            moment(currentDateUtc).utc().isAfter(moment(start).utc()) &&
            moment(currentDateUtc).utc().isBefore(moment(end).utc())
        );
    };

    return (
        <div
            onClick={_selectToday}
            className={classNames('action__items v2-btn-default btn-today mr-0', {
                active: _handleGetActiveToday()
            })}
        >
            {t('calendar:today')}
        </div>
    );
};

export default Today;
