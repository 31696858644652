import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import Content from 'app/modules/customer/components/Content';
import { useParams } from 'react-router-dom';
import { NOTE_DEFAULT_VALUE, TYPE_NOTE } from 'app/modules/customer/const/Note';
import { clientQuery } from 'common/utils/ApiUtils';
import { customerNotes, jobNotes, getUnitNotes } from 'app/const/Api';
import IconNote from 'assets/icon/IconNote';
import TemplateOptions from 'app/components/templatenotes';

const AddNote = forwardRef(
    (
        {
            onAddNew,
            cleanQuickAdd,
            type = TYPE_NOTE.CUSTOMER,
            jobId = '',
            defaultShow = false,
            isShowLeftIcon = true,
            unitId = '',
            showCancel = true,
            closeAfterAdd = true,
            filterTemplate = 6
        },
        ref
    ) => {
        const { id: customerId } = useParams();

        const refFormAddNote = useRef(null);
        const refContentNote = useRef(null);

        const [state, setState] = useState({ noteData: NOTE_DEFAULT_VALUE });

        const finalNoteData = state.noteData || {};
        const userData = useSelector(({ auth }) => auth.user);
        const userProfile = userData.profile || {};
        const formatDisplay = convertPhpFormatToMoment(userData.company?.date_format);
        const finalListAttachments = finalNoteData.attachments || [];

        useImperativeHandle(ref, () => ({
            toggleShowHide: _handleToggleShowHide,
            checkIsOpen: _handleCheckFormIsOpen
        }));

        useEffect(() => {
            defaultShow && _handleToggleShowHide();
        }, []);

        function _handleCheckFormIsOpen() {
            if (refFormAddNote.current.classList.contains('is-show')) {
                const currentContent = refContentNote.current.getContent();
                if (currentContent.length !== 0) {
                    _handleSave(currentContent);
                }
                _handleClose();
            }
        }

        function _handleToggleShowHide() {
            const currentRefFormAdd = refFormAddNote.current;

            if (currentRefFormAdd) {
                currentRefFormAdd.classList.toggle('is-show');
                if (currentRefFormAdd.classList.contains('is-show')) {
                    refContentNote.current && refContentNote.current.focus();
                    !defaultShow && currentRefFormAdd.scrollIntoView();
                }
            }
        }

        /**
         * Handle close & clean data
         */
        function _handleClose() {
            if (refFormAddNote.current) {
                refFormAddNote.current.classList.remove('is-show');
                setState({
                    noteData: NOTE_DEFAULT_VALUE
                });
            }
        }

        function _getUrl() {
            switch (type) {
                case TYPE_NOTE.CUSTOMER:
                    return customerNotes(customerId);
                case TYPE_NOTE.JOB:
                    return jobNotes(jobId);
                case TYPE_NOTE.UNIT:
                    return getUnitNotes(unitId);

                default:
                    break;
            }
        }

        function _handleSave(content) {
            const notifySelected = refContentNote.current.getNotify() || [];
            const attachments = refContentNote.current.getAttachments() || [];
            const idTemp = Date.now();

            const optionsQuery = {
                method: 'POST',
                data: {
                    content: content,
                    notified_users: notifySelected,
                    attachments: attachments.map((item) => {
                        return {
                            object_key: item.object_key,
                            object_tag: item.object_tag,
                            mime: item.mime,
                            name: item.name,
                            size: item.size,
                            type: item.type
                        };
                    })
                }
            };

            clientQuery(_getUrl(), optionsQuery, (reponse) =>
                _addNoteSuccess(reponse, idTemp, content, notifySelected)
            );

            setTimeout(() => {
                setState({ noteData: { ...NOTE_DEFAULT_VALUE } });
                refContentNote.current.clearNotify();
            }, 500);

            closeAfterAdd && _handleClose();
        }

        function _addNoteSuccess(reponse, idTemp, content, notifySelected) {
            refContentNote.current.resetValue();
            onAddNew({
                ...finalNoteData,
                id: idTemp,
                content: content,
                user: {
                    ...userProfile,
                    full_name: userProfile.first_name.concat(' ', userProfile.last_name)
                },
                date: `${moment().format(formatDisplay)} ${moment().format('h:mmA')}`,
                notified_users: notifySelected,
                attachments: reponse.data.attachments,
                quick_add: true,
                is_customer_note: type === TYPE_NOTE.CUSTOMER ? 1 : 0,
                type: 'note',
                total_notifications:
                    notifySelected.length && notifySelected.some((item) => item.id === String(userProfile.id)) ? 1 : 0
            });

            cleanQuickAdd(reponse.data.id, idTemp);
            if (refContentNote.current) {
                refContentNote.current.clearNotify();
                refContentNote.current.clearAttachments();
            }
        }

        function _getItemId() {
            switch (type) {
                case TYPE_NOTE.CUSTOMER:
                    return customerId;
                case TYPE_NOTE.JOB:
                    return jobId;
                case TYPE_NOTE.UNIT:
                    return unitId;
                default:
                    return '';
            }
        }

        function _handleSelectTemplate({ content: newContent }) {
            refContentNote.current.setValue(refContentNote.current.getContent().concat(newContent));
        }

        return (
            <div ref={refFormAddNote} className="boxs is-note --new-note">
                {isShowLeftIcon && (
                    <div className="left-icons">
                        <IconNote />
                    </div>
                )}

                <div className="content">
                    <div className="header-box">
                        <div className="avt-img">
                            <img alt={userProfile.name} src={userProfile.avatar} width={24} height={24} />
                        </div>
                        <div className="name">
                            <h5 className="name__label">{userProfile.name}</h5>
                            <p className="name__des">
                                <span className="info">{moment().format(formatDisplay)}</span>
                                <span className="info">{moment().format('h:mmA')}</span>
                            </p>
                        </div>
                        <TemplateOptions isShow typeFilter={filterTemplate} onSelectTemplate={_handleSelectTemplate} />
                    </div>
                    <Content
                        ref={refContentNote}
                        content={''}
                        typePresign="note"
                        itemIdPresign={_getItemId()}
                        attachmentProps={{ itemId: _getItemId(), typeAttachment: 'note', haveNotify: true }}
                        onCloseEdit={_handleClose}
                        onUpdate={_handleSave}
                        showCancel={showCancel}
                        disableSend={finalListAttachments.some((item) => item.isUploading)}
                    />
                </div>
            </div>
        );
    }
);

export default AddNote;
