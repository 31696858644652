import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import IconAmount from 'assets/icon/IconAmount';
import IconCalendar from 'assets/icon/IconCalendar';
import IconInvoice from 'assets/icon/IconInvoice';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import CustomerPaymentMethod from './CustomerPaymentMethod';
import IconClose from 'assets/icon/IconClose';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { COMMON, LIST_STATUS } from 'app/const/App';
import { clientQuery } from 'common/utils/ApiUtils';
import { useParams } from 'react-router-dom';
import { convertPhpFormatToMoment, convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import ButtonSave from 'app/components/button/ButtonSave';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';
import { roundingNumber } from 'common/utils/NumberUtils';
import { customerEditPayment } from 'app/const/api/V2';

const CustomerEditPayment = forwardRef(({ editSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { company } = useSelector(({ auth }) => auth.user);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const { id } = useParams();
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        dataInvoice: {}
    });
    const dataInvoice = state.dataInvoice;
    const methodName = dataInvoice.method_name;
    const dateFormat = convertTimeToISO(moment(dataInvoice.date, convertPhpFormatToMoment(company.date_format)));
    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (dataPayment = {}) => {
        dispatchState({
            isOpen: true,
            dataInvoice: {
                ...dataPayment,
                method_name: dataPayment.method_name || {},
                memo: dataPayment.memo || '',
                check_number: dataPayment.check_number || '',
                check_value: dataPayment.check_value || '',
                amount: dataPayment.amount || ''
            }
        });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleKeyDown = (event) => {
        /^[eE]{1}$/.test(event.key) && event.preventDefault();
    };

    const _handleSave = () => {
        const paymentEdited = {
            memo: dataInvoice.memo,
            check: dataInvoice.check_number,
            check_value: dataInvoice.check_value,
            amount: dataInvoice.payment_amount,
            date: dateFormat,
            payment_method: methodName.name || methodName,
            customer_id: id || dataInvoice.customer?.id
        };
        if (!paymentEdited.amount) {
            refButtonSave.current.removeLoading();
            return refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('customers:error_submit_form_credit'),
                type: LIST_STATUS.ERROR
            });
        }

        clientQuery(
            customerEditPayment(dataInvoice.id),
            { data: paymentEdited, method: 'PUT' },
            (res) => _handleEditSuccess(res, dataInvoice.id, paymentEdited),
            _handleEditFailed
        );
    };

    const _handleEditSuccess = ({ message }, id, paymentEdited) => {
        editSuccess(message, id, paymentEdited);
        _close();
    };

    const _handleEditFailed = ({ message }) => {
        refButtonSave.current.removeLoading();
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: message || t('customers:error_submit_form_credit'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleSelectDate = (date) => {
        dispatchState({ dataInvoice: { ...dataInvoice, date } });
    };

    const _handleSelect = ({ id }) => {
        dispatchState({
            dataInvoice: {
                ...dataInvoice,
                method_name: id,
                memo: '',
                check_number: ''
            }
        });
    };

    const _handleChange = (e, type) => {
        dispatchState({ dataInvoice: { ...dataInvoice, [type]: e.target.value } });
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            id="edit_payment"
            isOpen={state.isOpen}
            onRequestClose={_close}
            style={{ overlay: { zIndex: 9999, background: 'transparent' } }}
            className="modal --new-job --new-payment container-modal open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label text-left">Edit Payment</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal form-tabpane-details scrolls">
                    <AlertCustomer ref={refAlert} />
                    <div className="container-column form-add-payment">
                        <div className="rows --header-info">
                            <div className="customer-info pt-5 pb-6 w-100">
                                <div className="customer-info-number">
                                    <div className="sm-row">
                                        <div className="txt --lg">
                                            <IconInvoice />
                                            Invoice
                                        </div>
                                        <div className="detail">#{dataInvoice.invoice_number}</div>
                                    </div>
                                    <div className="sm-row has-icon">
                                        <div className="txt --lg">Invoice Total:</div>
                                        <div className="detail">{dataInvoice.invoice_total}</div>
                                    </div>
                                    <div className="sm-row has-icon">
                                        <div className="txt --lg">Amount Received</div>
                                        <div className="detail">
                                            {dataInvoice.total_received || dataInvoice.invoice_received}
                                        </div>
                                    </div>
                                    <div className="sm-row has-icon">
                                        <div className="txt --lg">Invoice Balance</div>
                                        <div className="detail">
                                            {dataInvoice.amount_due || dataInvoice.invoice_balance}
                                        </div>
                                    </div>
                                    <div className="sm-row mt-5">
                                        <div className="txt">
                                            <IconAmount />
                                            Amount
                                        </div>
                                        <div className="detail">
                                            <input
                                                className="field-input"
                                                type="number"
                                                onKeyDown={_handleKeyDown}
                                                onChange={(e) => _handleChange(e, COMMON.PAYMENT_AMOUNT)}
                                                defaultValue={roundingNumber(dataInvoice.payment_amount)}
                                            />
                                        </div>
                                    </div>
                                    <div className="sm-row">
                                        <div className="txt">
                                            <IconCalendar />
                                            Date
                                        </div>
                                        <div className="detail">
                                            <CalendarDatePicker
                                                onChange={_handleSelectDate}
                                                selectDefault={dateFormat}
                                            />
                                        </div>
                                    </div>
                                    <CustomerPaymentMethod
                                        defaultCheckNumber={dataInvoice.check_number}
                                        defaultCheckValue={dataInvoice.check_value}
                                        defaultMemo={dataInvoice.memo}
                                        defaultMethod={{
                                            id: methodName.id,
                                            name: methodName.name
                                        }}
                                        onChange={_handleChange}
                                        onSelect={_handleSelect}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --noborder --label" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        title={t('customers:save')}
                        wrapClass="v2-btn-main"
                        onSave={_handleSave}
                    />
                </div>
            </div>
        </ReactModal>
    );
});

export default CustomerEditPayment;
