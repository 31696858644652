export default function IconClock({
    isHasColor = false,
    isOpenClock = false,
    clockActive = false,
    clockBlue = false,
    ...props
}) {
    if (clockBlue) {
        return (
            <svg width={24} height={24} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.625 6.75C10.9032 6.75 12.75 8.59683 12.75 10.875C12.75 13.1532 10.9032 15 8.625 15C6.34683 15 4.5 13.1532 4.5 10.875C4.5 8.59683 6.34683 6.75 8.625 6.75Z"
                    fill="#EDF2FB"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.625 6.375C11.1103 6.375 13.125 8.38972 13.125 10.875C13.125 13.3603 11.1103 15.375 8.625 15.375C6.13972 15.375 4.125 13.3603 4.125 10.875C4.125 8.38972 6.13972 6.375 8.625 6.375Z"
                    stroke="#1E7EF7"
                    strokeWidth="0.975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.625 9.375C9.03921 9.375 9.375 9.71079 9.375 10.125C9.375 10.5392 9.03921 10.875 8.625 10.875C8.21079 10.875 7.875 10.5392 7.875 10.125C7.875 9.71079 8.21079 9.375 8.625 9.375Z"
                    stroke="#1E7EF7"
                    strokeWidth="0.975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.625 7.125V5.625C5.625 3.96815 6.96815 2.625 8.625 2.625C10.2819 2.625 11.625 3.96815 11.625 5.625V7.125"
                    stroke="#1E7EF7"
                    strokeWidth="0.975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.625 10.875V12.375"
                    stroke="#1E7EF7"
                    strokeWidth="0.975"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (clockActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 8.5C15.8137 8.5 18.5 11.1863 18.5 14.5C18.5 17.8137 15.8137 20.5 12.5 20.5C9.18629 20.5 6.5 17.8137 6.5 14.5C6.5 11.1863 9.18629 8.5 12.5 8.5Z"
                    fill="#FFF5EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 8.5C14.8137 8.5 17.5 11.1863 17.5 14.5C17.5 17.8137 14.8137 20.5 11.5 20.5C8.18629 20.5 5.5 17.8137 5.5 14.5C5.5 11.1863 8.18629 8.5 11.5 8.5Z"
                    stroke="#FF871E"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 12.5C12.0523 12.5 12.5 12.9477 12.5 13.5C12.5 14.0523 12.0523 14.5 11.5 14.5C10.9477 14.5 10.5 14.0523 10.5 13.5C10.5 12.9477 10.9477 12.5 11.5 12.5Z"
                    stroke="#FF871E"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 9.5V7.5C7.5 5.29086 9.29086 3.5 11.5 3.5C13.7091 3.5 15.5 5.29086 15.5 7.5V9.5"
                    stroke="#FF871E"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.5 14.5V16.5"
                    stroke="#FF871E"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isOpenClock) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                {' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 8.5C15.8137 8.5 18.5 11.1863 18.5 14.5C18.5 17.8137 15.8137 20.5 12.5 20.5C9.18629 20.5 6.5 17.8137 6.5 14.5C6.5 11.1863 9.18629 8.5 12.5 8.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 12.5C13.0523 12.5 13.5 12.9477 13.5 13.5C13.5 14.0523 13.0523 14.5 12.5 14.5C11.9477 14.5 11.5 14.0523 11.5 13.5C11.5 12.9477 11.9477 12.5 12.5 12.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M8.53589 10.0981L7.53589 8.36602C6.43132 6.45285 7.08682 4.00649 8.99999 2.90192C10.9132 1.79735 13.3595 2.45285 14.4641 4.36602L15.4641 6.09807"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M12.5 14.5V16.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
            </svg>
        );
    }
    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4ZM12 6C11.4477 6 11 6.44772 11 7L11.0001 9.17067C9.83485 9.58249 9 10.6937 9 12C9 13.6569 10.3431 15 12 15C13.3059 15 14.4169 14.1656 14.829 13.0009L17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11L14.8293 11.0001C14.5282 10.1479 13.8529 9.47248 13.0009 9.17102L13 7C13 6.44772 12.5523 6 12 6Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.5 20.5C16.9183 20.5 20.5 16.9183 20.5 12.5C20.5 8.08172 16.9183 4.5 12.5 4.5C8.08172 4.5 4.5 8.08172 4.5 12.5C4.5 16.9183 8.08172 20.5 12.5 20.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.5 8.5L12.5 12.5L14.5 14.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.5 3.5L3.5 6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.5 3.5L21.5 6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
