import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken, deleteWithToken } from 'common/utils/ApiUtils';
import { TYPE_TAGS_MAP } from 'app/const/App';
import { GET_LIST_SIMPLE_TAGS, SETTINGS_TAGS, getUrlSettingUpdateTag } from 'app/const/Api';
import { TYPE_SETTING_TAGS } from 'common/redux/actions/settings/tagsAction';
import { GET_LIST_CONVERSATION_TAGS } from 'app/const/api/V2';

export function* getListTags({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_SIMPLE_TAGS, params);
        if (response && response.success) {
            actionSuccess(response);
            if (params?.type) {
                yield put({
                    type: TYPE_SETTING_TAGS.GET_LIST_SUCCESS,
                    payload: {
                        type: params.type,
                        response: response
                    }
                });
            }
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListConversationTags({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_CONVERSATION_TAGS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListSettingTags({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_TAGS, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createSettingTag({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_TAGS, params);
        if (response && response.success) {
            response.data.name = params.name;
            response.data.type = TYPE_TAGS_MAP[params.type];
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateSettingTag({ params, actionSuccess, actionFailed }) {
    try {
        const URL = getUrlSettingUpdateTag(params.id);
        const response = yield call(putWithToken, URL, { name: params.name });
        if (response && response.success) {
            actionSuccess(response);
            yield put({ type: TYPE_SETTING_TAGS.UPDATE_TAGS_SUCCESS, payload: params });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteSettingTags({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, SETTINGS_TAGS, params);
        if (response && response.success) {
            response.listIdChecked = params.ids;
            actionSuccess(response);
            yield put({ type: TYPE_SETTING_TAGS.DELETE_TAGS_SUCCESS, payload: params });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
