import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { actionJobStatus, actionDeleteJob, actionJobMove } from 'app/modules/calendar/ultil/JobAction';
import { convertOneEvent } from 'app/modules/calendar/ultil/Calendar';

const JobListService = ({ jobActive, jobHistory, onUpdateJobs = () => {} }) => {
    const { realtimeStatus, realtimeDelete, realtimeMove, eventUpdateJL } = useSelector(
        ({ workPoolReducer }) => workPoolReducer
    );

    useEffect(() => {
        realtimeMove && _realtimeMove(realtimeMove);
    }, [realtimeMove]);

    useEffect(() => {
        realtimeStatus && _realtimeStatus(realtimeStatus);
    }, [realtimeStatus]);

    useEffect(() => {
        if (Array.isArray(eventUpdateJL)) {
            const newActive = jobActive.map((item) => {
                const currentJobId = item.id.toString();
                const jobFindUpdate = eventUpdateJL.find((jobFind) => jobFind.job.id.toString() === currentJobId);
                if (!!jobFindUpdate) {
                    const oldTile = jobFindUpdate.tile;
                    return convertOneEvent({
                        ...jobFindUpdate,
                        tile: {
                            ...oldTile,
                            content: oldTile.content_long || oldTile.header,
                            header: oldTile.header_long || oldTile.header
                        }
                    });
                } else {
                    return item;
                }
            });

            onUpdateJobs(newActive, jobHistory);
        }
    }, [eventUpdateJL]);

    useEffect(() => {
        realtimeDelete && _realtimeDelete(realtimeDelete);
    }, [realtimeDelete]);

    function _realtimeDelete(jobData) {
        onUpdateJobs(
            actionDeleteJob(jobData, [...jobActive])?.list || jobActive,
            actionDeleteJob(jobData, [...jobHistory])?.list || jobHistory
        );
    }

    function _realtimeStatus(jobData) {
        onUpdateJobs(
            actionJobStatus(jobData, [...jobActive])?.list || jobActive,
            actionJobStatus(jobData, [...jobHistory])?.list || jobHistory
        );
    }

    function _realtimeMove(jobData) {
        onUpdateJobs(
            actionJobMove(jobData, [...jobActive]) || jobActive,
            actionJobMove(jobData, [...jobHistory]) || jobHistory
        );
    }
    return false;
};

export default JobListService;
