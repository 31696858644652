import React from 'react';

export default function IconAddComment() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                d="M6.5 8.5C7.60457 8.5 8.5 7.60457 8.5 6.5C8.5 5.39543 7.60457 4.5 6.5 4.5C5.39543 4.5 4.5 5.39543 4.5 6.5C4.5 7.60457 5.39543 8.5 6.5 8.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 10.5H7.5H17.5C18.6046 10.5 19.5 11.3954 19.5 12.5V16.5C19.5 17.6046 18.6046 18.5 17.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V12.5C4.5 11.3954 5.39543 10.5 6.5 10.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path d="M7.5 13.5H16.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />{' '}
            <path d="M7.5 15.5H12.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />{' '}
            <path d="M10.5 6.5H16.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />{' '}
        </svg>
    );
}
