import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ACCESS_TOKEN, COMMON } from 'app/const/App';
import { getBranchId } from 'app/const/Branch';
import { URL_EXPORT_SMART_VIEW } from 'app/const/api/Export';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconPrint from 'assets/icon/IconPrint';
import IconSent from 'assets/icon/IconSent';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { useSmartView } from '../../context';
import { DEFAULT_COLUMNS } from '../table/utils';
import { convertToQuery, getColumnPrint } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';

const PrintButton = ({ isDisable = false }) => {
    const { t } = useTranslation('smartView');
    const { filtered, sort, limit, columns: columnsData } = useSmartView();
    const refDropdown = useRef(null);

    const _handlePrint = (type = 'print') => {
        const token = getLocalStorageValue(ACCESS_TOKEN);
        const columnsPrint = getColumnPrint(columnsData || DEFAULT_COLUMNS);
        const form = document.createElement('form');

        form.action = URL_EXPORT_SMART_VIEW;
        form.method = 'POST';
        form.id = 'smart_view_print_form';
        form.style.display = 'none';
        form.innerHTML = `
            <input name="token" value="${token}">
            <input name="columns" value="${columnsPrint}">
            <input name="type" value="${type}">
            <input name="gd-branch-id" value="${getBranchId()}">
        `;
        const inputQuery = document.createElement('input');
        inputQuery.name = 'query';
        inputQuery.value = JSON.stringify({
            q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
            sort: sortToQuery(sort),
            limit
        });
        form.append(inputQuery);
        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();
        document.querySelector('#smart_view_print_form').remove();
    };

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="smart_view_print_button"
            customDropButton={() => <IconThreeDots />}
            wrapperClassName="header-items"
            buttonClassName={classNames('dropbtn v2-btn-default --icon-lg', { 'is-disable': isDisable })}
            wrapperListClass="v2-dropdown__menu content-full --right"
        >
            <ul>
                <li className="items has-icon" onClick={() => _handlePrint(COMMON.PRINT)}>
                    <IconPrint />
                    <div className="txt-ellipsis">{t('print')}</div>
                </li>
                <li className="items has-icon" onClick={() => _handlePrint(COMMON.CSV)}>
                    <IconSent isSendMail />
                    <div className="txt-ellipsis">{t('export_csv')}</div>
                </li>
                <li className="items has-icon" onClick={() => _handlePrint(COMMON.XLS)}>
                    <IconSent isSendMail />
                    <div className="txt-ellipsis">{t('export_excel')}</div>
                </li>
            </ul>
        </CalendarDropdown>
    );
};

export default PrintButton;
