import { call } from 'redux-saga/effects';
import { getUrlCustomerContact, getUrlUpdateCustomerContact } from 'app/const/Api';
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';

export function* getListContact({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, getUrlCustomerContact(params.customer_id), params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createContact({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, getUrlCustomerContact(params.customer_id), params);
        if (response && response.success) {
            response.data = {
                ...response.data,
                phones: params.phones.map((contact) => {
                    return { contact_id: response?.data?.id, ...contact };
                }),
                email: params.email
            };
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateContact({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            putWithToken,
            getUrlUpdateCustomerContact(params.customer_id, params.contact_id),
            params
        );
        if (response && response.success) {
            response.data = {
                ...response.data,
                phones: params.phones,
                email: params.email,
                title: params.title || ''
            };
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteContacts({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, getUrlCustomerContact(params.customer_id), params);
        if (response && response.success) {
            actionSuccess();
        } else {
            actionFailed();
        }
    } catch (error) {
        actionFailed(error);
    }
}
