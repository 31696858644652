export default function IconSentSms({ isHasColor = false, isFillOrange = false, isHasDot = false, isWhite = false }) {
    if (isHasColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 13C18 14.1046 17.1046 15 16 15H10C10 16 10.5 17 11.5 17H14.446C14.8085 17 15.1642 17.0985 15.475 17.285L18.4855 19.0913C18.9591 19.3755 19.5733 19.2219 19.8575 18.7483C19.9507 18.5929 20 18.4151 20 18.2338V12C20 10.8954 19.1046 10 18 10V13Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70156 14C9.24742 14 8.8068 14.1546 8.45217 14.4383L5.62469 16.7002C5.19343 17.0453 4.56414 16.9753 4.21913 16.5441C4.07728 16.3668 4 16.1464 4 15.9194V7C4 5.89543 4.89543 5 6 5H15C16.1046 5 17 5.89543 17 7V12C17 13.1046 16.1046 14 15 14H9.70156Z"
                    fill="#C1C9D4"
                ></path>
            </svg>
        );
    if (isFillOrange)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 13C18 14.1046 17.1046 15 16 15H10C10 16 10.5 17 11.5 17H14.446C14.8085 17 15.1642 17.0985 15.475 17.285L18.4855 19.0913C18.9591 19.3755 19.5733 19.2219 19.8575 18.7483C19.9507 18.5929 20 18.4151 20 18.2338V12C20 10.8954 19.1046 10 18 10V13Z"
                    fill="#FF871E"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70156 14C9.24742 14 8.8068 14.1546 8.45217 14.4383L5.62469 16.7002C5.19343 17.0453 4.56414 16.9753 4.21913 16.5441C4.07728 16.3668 4 16.1464 4 15.9194V7C4 5.89543 4.89543 5 6 5H15C16.1046 5 17 5.89543 17 7V12C17 13.1046 16.1046 14 15 14H9.70156Z"
                    fill="#FF871E"
                ></path>
            </svg>
        );
    if (isHasDot)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 19H7C5.89543 19 5 18.1046 5 17V14C5 12.8954 5.89543 12 7 12H15L17.4 10.2C17.8418 9.86863 18.4686 9.95817 18.8 10.4C18.9298 10.5731 19 10.7836 19 11V17C19 18.1046 18.1046 19 17 19Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    d="M7.5 10C8.88071 10 10 8.88071 10 7.5C10 6.11929 8.88071 5 7.5 5C6.11929 5 5 6.11929 5 7.5C5 8.88071 6.11929 10 7.5 10Z"
                    fill="#7A83A6"
                />{' '}
            </svg>
        );
    if (isWhite)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18 13C18 14.1046 17.1046 15 16 15H10C10 16 10.5 17 11.5 17H14.446C14.8085 17 15.1642 17.0985 15.475 17.285L18.4855 19.0913C18.9591 19.3755 19.5733 19.2219 19.8575 18.7483C19.9507 18.5929 20 18.4151 20 18.2338V12C20 10.8954 19.1046 10 18 10V13Z"
                    fill="#c1c9d4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.70156 14C9.24742 14 8.8068 14.1546 8.45217 14.4383L5.62469 16.7002C5.19343 17.0453 4.56414 16.9753 4.21913 16.5441C4.07728 16.3668 4 16.1464 4 15.9194V7C4 5.89543 4.89543 5 6 5H15C16.1046 5 17 5.89543 17 7V12C17 13.1046 16.1046 14 15 14H9.70156Z"
                    fill="#FFFFFF"
                ></path>
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 14.5V15.5C10.5 16.2398 10.9017 16.8858 11.4989 17.2318C11.879 17.4254 12.0976 17.4887 12.5 17.5H14.946C15.3085 17.5 15.6642 17.5985 15.975 17.785L18.9855 19.5913C19.4591 19.8755 20.0733 19.7219 20.3575 19.2483C20.4507 19.0929 20.5 18.9151 20.5 18.7338V12.5C20.5 11.3954 19.6046 10.5 18.5 10.5H17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
            />
            <path
                d="M10.2016 14.5C9.74742 14.5 9.3068 14.6546 8.95217 14.9383L6.1247 17.2002C5.69343 17.5453 5.06414 17.4753 4.71913 17.0441C4.57728 16.8668 4.5 16.6464 4.5 16.4194V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5H15.5C16.6046 5.5 17.5 6.39543 17.5 7.5V12.5C17.5 13.6046 16.6046 14.5 15.5 14.5H10.2016Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            />
        </svg>
    );
}
