import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DoughnutChart from 'app/components/chart/doughnut';
import { reducer } from 'app/const/Reducer';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import ServiceDashboard from '../../ServiceDashboard';
import WidgetsInfo from '../../components/widgetsInfo';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, getDefaultParamsWidget } from '../../const';
import { CHART_COLORS } from 'app/const/App';
import LoadingDoughnutChart from '../loading/LoadingDoughChart';

export default function WidgetInvoiceAging() {
    const { t } = useTranslation('report');

    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.INVOICES_AGING;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        params: defaultParams,
        total: '',
        description: ''
    });

    const { isLoading: finalIsLoading, data: finalData, total: finalTotal, description: finalDescription } = state;

    useEffect(() => {
        fetchInvoiceagingData(defaultParams);
    }, []);

    const fetchInvoiceagingData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleGetDataSuccess = ({ data, total, description }) => {
        dispatchState((prev) => ({
            ...prev,
            data: data.map((item, index) => ({ ...item, color: item.color || CHART_COLORS[index] })),
            total,
            isLoading: false,
            description
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderContent = () => {
        if (finalIsLoading) {
            return <LoadingDoughnutChart />;
        }
        return (
            <div className="boxs__content">
                <div className="chart">
                    <div className="chart__content">
                        <div className="content-l">
                            <WidgetsInfo
                                dataWidget={{ total: finalTotal, data: finalData, description: finalDescription }}
                            />
                        </div>
                        <div className="content-r">
                            <div style={{ width: '160px' }}>
                                <DoughnutChart dataCharts={finalData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <Fragment>
            <div className="boxs">
                <div className="boxs__header">
                    <div className="header-label flex-1">{t('invoices_aging')}</div>
                </div>
                {_renderContent()}
            </div>
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </Fragment>
    );
}
