import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'common/utils/EmailUtils';

import { DELAY_CLICK } from 'app/const/App';

import { useSiteTitle } from 'common/hooks/useSiteTitle';
import { userForgotPasswordRequest } from 'common/redux/actions/authAction';

import SuccessForgotPassword from './components/SuccessForgotPassword';
import AuthError from 'app/modules/auth/components/AuthError';
import AuthInputField from 'app/modules/auth/components/AuthInputField';
import ButtonLoading from 'app/modules/auth/components/ButtonLoading';

let timer;

function ForgotPassword() {
    useSiteTitle('auth:reset_password');
    const dispatch = useDispatch();
    const { t } = useTranslation(['auth', 'common']);

    const refForm = useRef(null);

    const [state, setState] = useState({
        success: false,
        message: '',
        error: null,
        email: '',
        errorsForm: null,
        disableForm: false
    });

    useEffect(() => {
        return clearTimeout(timer);
    }, []);

    const _handleForgotPassword = (data) => {
        dispatch(
            userForgotPasswordRequest(data, (response) => _handleForgotSuccess(response, data), handleForgotFailed)
        );
    };

    const _handleForgotSuccess = (response, data) => {
        setState((prevState) => {
            return {
                ...prevState,
                ...response,
                email: data.email,
                disableForm: false
            };
        });
    };

    const handleForgotFailed = (response) => {
        refForm.current['email'].focus();
        setState((prevState) => {
            return {
                ...prevState,
                error: response?.message,
                disableForm: false
            };
        });
    };

    const _handleFocus = () => {
        setState({
            ...state,
            errorsForm: null,
            error: null
        });
    };

    const handleKeypress = (e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            _handleCheckForm(e);
        }
    };

    const _handleCheckForm = (e) => {
        e && e.preventDefault();
        const msgError = {};

        const { email } = refForm.current;

        for (let i = 0; i < refForm.current.length; i++) {
            const { name, value } = refForm.current[i];
            if (!value.trim()) {
                msgError[name] = t(`auth:${name}`).concat(' ', t('auth:must_not_be_empty'), '!');
            }
        }

        const emailValue = email.value.trim();

        if (emailValue && !validateEmail(emailValue)) {
            msgError['email'] = t('auth:incorrect_email');
        }

        const keysErrors = Object.keys(msgError);

        if (keysErrors.length) {
            refForm.current[keysErrors[0]].focus();
            setState({ ...state, errorsForm: msgError });
            return false;
        }

        setState({
            ...state,
            disableForm: true
        });

        _handleForgotPassword({ email: emailValue });
    };

    const _handleDebounceSubmit = () => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            _handleCheckForm();
        }, DELAY_CLICK);
    };

    if (state.success) {
        return <SuccessForgotPassword message={state.message} email={state.email} />;
    }

    return (
        <div className="flex-centeritem flex-1">
            <div id="gd_login" className="gd-form-v2">
                <AuthError text={state.error} />
                <h2 className="fs-27">{t('auth:reset_password')}</h2>
                <form id="forgot-password-form" ref={refForm} action="#">
                    <section className="gd-login-btn-group">
                        <div>
                            <AuthInputField
                                label={t('auth:your_email')}
                                error={state.errorsForm?.email}
                                onKeyPress={handleKeypress}
                                placeholder={''}
                                name="email"
                                type="email"
                                onFocus={_handleFocus}
                                autoFocus
                            />
                        </div>

                        <ButtonLoading
                            classWrapper="gd-getstarted v2-btn-main gd-btn-getstarted mb-10"
                            onSubmit={_handleDebounceSubmit}
                            text={t('auth:send_me_instructions')}
                            isLoading={state.disableForm}
                        />
                    </section>
                </form>
            </div>
        </div>
    );
}

export default ForgotPassword;
