import { TYPE_TAGS } from 'app/const/Customers';
import { TYPE_SETTING_TAGS } from 'common/redux/actions/settings/tagsAction';

const tagsReducer = (
    state = {
        all: {
            isFirstTime: true,
            data: []
        },
        customer: {
            isFirstTime: true,
            data: []
        },
        location: {
            isFirstTime: true,
            data: []
        },
        crew: {
            isFirstTime: true,
            data: []
        }
    },
    action
) => {
    const nameType = TYPES_TAG[action?.payload?.type || -1];
    switch (action.type) {
        case TYPE_SETTING_TAGS.GET_LIST_SUCCESS:
            return {
                ...state,
                [nameType]: { ...state[nameType], isFirstTime: false, data: action.payload?.response?.data || [] }
            };

        case TYPE_SETTING_TAGS.UPDATE_TAG_SIMPLE:
            return {
                ...state,
                [nameType]: { ...state[nameType], data: action.payload.data }
            };

        case TYPE_SETTING_TAGS.UPDATE_TAGS_SUCCESS:
            if (state[nameType]?.isFirstTime) return state;
            return {
                ...state,
                [nameType]: {
                    ...state[nameType],
                    data: state[nameType].data.map((item) => {
                        if (action.payload.oldName === item.name)
                            return { ...item, name: action.payload.name, id: action.payload.name };
                        return item;
                    })
                }
            };
        case TYPE_SETTING_TAGS.DELETE_TAGS_SUCCESS:
            if (state[nameType]?.isFirstTime) return state;
            return {
                ...state,
                [nameType]: {
                    ...state[nameType],
                    data: state[nameType].data.filter((item) => action.payload.idsDeleted.indexOf(item.id) === -1)
                }
            };
        default:
            return state;
    }
};

export const TYPES_TAG = {
    [-1]: 'all',
    [TYPE_TAGS.TAG_DEFAULT]: 'customer',
    [TYPE_TAGS.TAG_CUSTOMER]: 'location',
    [TYPE_TAGS.TAG_CREW]: 'crew',
    [TYPE_TAGS.TAG_CONVERSATION_CHATBOT_REPORT]: 'conversation_chatbot_report'
};

export default tagsReducer;
