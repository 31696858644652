export const TYPE_SETTING_PAYMENT_METHOD = {
    GET_LIST_SIMPLE: 'SETTING_GET_LIST_SIMPLE_PAYMENT_METHODS ',
    GET_LIST: 'SETTING_GET_LIST_PAYMENT_METHODS',
    CREATE: 'SETTING_CREATE_PAYMENT_METHODS',
    UPDATE: 'SETTING_UPDATE_PAYMENT_METHODS',
    UPDATE_STATUS: 'SETTING_UPDATE_STATUS_PAYMENT_METHODS'
};

// actions

export const getListPaymentMethods = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PAYMENT_METHOD.GET_LIST_SIMPLE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListSettingPaymentMethods = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PAYMENT_METHOD.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createPaymentMethod = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PAYMENT_METHOD.CREATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updatePaymentMethod = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PAYMENT_METHOD.UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateStatusSettingPaymentMethods = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PAYMENT_METHOD.UPDATE_STATUS,
        params,
        actionSuccess,
        actionFailed
    };
};
