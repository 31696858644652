import React, { useEffect, useReducer } from 'react';

import { reducer } from 'app/const/Reducer';
import { SMART_VIEW_CUSTOM_EVENTS } from 'app/modules/smartview/constants';
import IconClose from 'assets/icon/IconClose';

const AlertInboxPage = () => {
    const initialState = { isDisplayAlert: false, notification: '', timeout: 0 };
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isDisplayAlert, notification, timeout } = state;

    useEffect(() => {
        addEventListener(SMART_VIEW_CUSTOM_EVENTS.DISPLAY_ALERT, _handleDisplayAlert);
        return () => {
            removeEventListener(SMART_VIEW_CUSTOM_EVENTS.DISPLAY_ALERT, _handleDisplayAlert);
        };
    }, []);

    useEffect(() => {
        let timer = null;
        if (timer) clearTimeout(timer);
        if (isDisplayAlert && timeout) {
            timer = setTimeout(() => {
                dispatchState(() => ({ isDisplayAlert: false, notification: '', timeout: 0 }));
            }, timeout);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [isDisplayAlert]);

    const _handleDisplayAlert = (event) => {
        const { notification, timeout = 0 } = event.detail || {};
        if (notification) dispatchState(() => ({ isDisplayAlert: true, notification, timeout }));
    };

    const _handleHideAlert = () => {
        dispatchState(() => initialState);
    };

    if (!isDisplayAlert || !notification) return null;
    return (
        <div className="alert --error --smart-views">
            <p className="alert__description">{notification}</p>
            <div className="alert__btn svg-white-stroke" onClick={_handleHideAlert}>
                <IconClose />
            </div>
        </div>
    );
};

export default AlertInboxPage;
