import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import DatePickerInput from 'app/components/date/DateInput';
import DropdownUsers from 'app/components/dropdown/DropdownUsers';
import GDModalWarning from 'app/components/modal/ModalWarning';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { customerOpportunity } from 'app/const/api/V2';
import { OPTIONS_VALUE_OPPORTUNITY } from 'app/const/opportunity';
import { OPPORTUNITY_ACTIONS } from 'app/modules/customer/detail/constant';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeToISO } from 'common/utils/DateUtils';
import Name from '../task/Name';
import DropdownContacts from './DropdownContacts';
import DropdownStatus from './DropdownStatus';
import OpportunityRangeValue from './OpportunityRangeValue';
import OpportunityValue from './OpportunityValue';
import classNames from 'classnames';

const initialState = { isOpen: false, isEdit: false, dataOpportunity: {} };
const Opportunity = forwardRef(
    (
        {
            wrapperClassName = '',
            onSetButtonAdd = () => {},
            onSubmitSuccess = () => {},
            onDeleteSuccess = () => {},
            onDeleteFail = () => {},
            onClose = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation(['common', 'customers']);
        const { id: customerId } = useParams();
        const currentUserId = useSelector(({ auth }) => auth.user?.profile?.id);
        const users = useSelector(({ companyUsers }) => companyUsers.users || []);
        const currentUser = users?.find((user) => +user.id === +currentUserId);
        const { isLoading: isLoadingPipelines, data: dataPipelines } = useSelector(({ pipelines }) => pipelines);
        const [state, dispatchState] = useReducer(reducer, initialState);
        const { isOpen, isEdit, dataOpportunity } = state;
        const finalCustomerId = dataOpportunity?.customer_id || customerId;
        const contacts = dataOpportunity?.contacts || [];

        const refStatus = useRef(null);
        const refButtonSave = useRef(null);
        const refBoxContainer = useRef(null);
        const refContacts = useRef(null);
        const refConfidence = useRef(null);
        const refWarning = useRef('');
        const refUsers = useRef(null);
        const refValue = useRef(null);
        const refNotes = useRef(null);
        const refInputPicker = useRef(null);
        const refPrevIdEditing = useRef(null);

        useImperativeHandle(ref, () => ({ open: _open, close: _close, delete: _handleDeleteItem }));

        const _open = (data, contacts = []) => {
            const dataOpportunity = !!data ? data : { pipeline: dataPipelines?.[0]?.pipelines?.[0] || null };
            dispatchState({ isOpen: true, isEdit: !!data, dataOpportunity: { ...dataOpportunity, contacts } });
            refPrevIdEditing.current = !!data ? data?.id : null;
        };

        const _close = () => {
            onSetButtonAdd(false);
            dispatchState(initialState);
            onClose();
        };

        const _handleDateChange = (date) => {
            const newDate = convertTimeToISO(moment(date).startOf('date'));
            dispatchState((prevState) => ({
                ...prevState,
                dataOpportunity: { ...prevState.dataOpportunity, close_date: newDate }
            }));
        };

        const _handleChangePipeline = (pipeline) => {
            dispatchState((prevState) => ({
                ...prevState,
                dataOpportunity: { ...prevState.dataOpportunity, pipeline }
            }));
        };

        const _handleSubmit = (e) => {
            e && e.preventDefault();
            const data = {
                pipeline_id: dataOpportunity?.pipeline?.id || '',
                close_date: dataOpportunity?.close_date || moment().toISOString() || '',
                // Default: 0, Min: 0, Max: 100
                confidence: refConfidence.current?.getValue() || 0,
                user_id: refUsers.current?.getValue()?.id || '',
                contact_id: refContacts.current?.getValue()?.id || '',
                note: refNotes.current?.getValue() || '',
                // {value: 0, period: 'One-time'}
                ...(refValue.current?.getValue() || OPTIONS_VALUE_OPPORTUNITY[0])
            };
            clientQuery(
                customerOpportunity(finalCustomerId, dataOpportunity?.id),
                { data, method: isEdit ? 'PUT' : 'POST', toFormData: false },
                _submitSuccess,
                _submitFail
            );
        };

        const _submitSuccess = ({ data }) => {
            refButtonSave.current?.removeLoading();
            onSubmitSuccess(data, isEdit ? OPPORTUNITY_ACTIONS.EDIT : OPPORTUNITY_ACTIONS.ADD);
            _close();
        };

        const _submitFail = ({ message }) => {
            refStatus.current.showStatusBar('message_warning', message, LIST_STATUS.ERROR);
            refButtonSave.current?.removeLoading();
        };

        const _openConfirm = () => {
            refWarning.current._open({ id: dataOpportunity?.id });
        };

        const _closeConfirm = () => {
            refWarning.current._close();
        };

        const _handleConfirmDelete = () => {
            clientQuery(
                customerOpportunity(finalCustomerId, dataOpportunity?.id),
                { method: 'DELETE' },
                _deleteSuccess,
                _deleteFail,
                _deleteFinally
            );
        };

        const _deleteSuccess = () => {
            _close();
            onDeleteSuccess(dataOpportunity?.id, dataOpportunity?.pipeline?.id);
        };
        const _deleteFail = ({ message, success }) => {
            refStatus.current?.showStatusBar('message_warning', message, LIST_STATUS.ERROR);
            onDeleteFail({ pipelineId: dataOpportunity?.pipeline?.id, message, success });
        };
        const _deleteFinally = () => {
            _closeConfirm();
        };

        const _handleDeleteItem = (data) => {
            dispatchState({ dataOpportunity: data });
            refWarning.current._open();
        };

        const preventOverflowSetup = { name: 'preventOverflow', options: { boundary: refBoxContainer.current } };

        return (
            <>
                {isOpen ? (
                    <div ref={refBoxContainer} className={classNames('box-custom-sidebar open', wrapperClassName)}>
                        <div className="box__container">
                            <div className="wrap-header flex-betweenitems btn-close">
                                <h4 className="fs-18">
                                    {isEdit ? t('customers:edit_opportunity') : t('customers:new_opportunity')}
                                </h4>
                                <span onClick={_close} className="v2-btn-default --noborder just-icon">
                                    <IconClose />
                                </span>
                            </div>

                            <div className="box-form flex-column gap-8 px-3 pb-2">
                                <StatusBar ref={refStatus} />
                                <div className="flex-column gap-4 flex-1">
                                    <p>{t('customers:status')}</p>
                                    <DropdownStatus
                                        modifiers={[
                                            { name: 'offset', options: { offset: [0, 5] } },
                                            preventOverflowSetup
                                        ]}
                                        defaultValue={dataOpportunity?.pipeline || null}
                                        onChangePipeline={_handleChangePipeline}
                                    />
                                </div>

                                <div className="flex-betweenitems align-end gap-8">
                                    <div className="flex-column gap-4 flex-1">
                                        <p>{t('customers:estimated_close')}</p>
                                        <div ref={refInputPicker}>
                                            <DatePickerInput
                                                selectDefault={dataOpportunity?.close_date || ''}
                                                modifiers={[
                                                    { name: 'offset', options: { offset: [0, 2] } },
                                                    {
                                                        name: 'preventOverflow',
                                                        options: { boundary: refInputPicker.current }
                                                    }
                                                ]}
                                                onChange={_handleDateChange}
                                            />
                                        </div>
                                    </div>
                                    <OpportunityRangeValue
                                        ref={refConfidence}
                                        pipeline={dataOpportunity?.pipeline || null}
                                        defaultValue={dataOpportunity?.confidence ?? 50}
                                    />
                                </div>

                                <OpportunityValue
                                    ref={refValue}
                                    prevIdEditing={refPrevIdEditing.current || null}
                                    defaultValue={{
                                        value: dataOpportunity?.value?.value || 0,
                                        period: dataOpportunity?.period?.value || OPTIONS_VALUE_OPPORTUNITY[0]['id']
                                    }}
                                />

                                <div className="flex-column gap-4 flex-1">
                                    <p>{t('customers:contact')}</p>
                                    <DropdownContacts
                                        ref={refContacts}
                                        modifiers={[
                                            { name: 'offset', options: { offset: [0, 5] } },
                                            preventOverflowSetup
                                        ]}
                                        defaultValue={dataOpportunity?.contact_id || ''}
                                        contacts={contacts || []}
                                    />
                                </div>

                                <div className="flex-column gap-4 flex-1">
                                    <p>{t('customers:user')}</p>
                                    <DropdownUsers
                                        ref={refUsers}
                                        modifiers={[
                                            { name: 'offset', options: { offset: [0, 5] } },
                                            preventOverflowSetup
                                        ]}
                                        defaultUser={dataOpportunity?.user || currentUser}
                                    />
                                </div>

                                <Name
                                    ref={refNotes}
                                    title={t('customers:notes')}
                                    defaultValue={dataOpportunity?.note || ''}
                                />
                            </div>

                            <div className="flex-betweenitems border-top-grey-verylight-brown p-3">
                                {isEdit ? (
                                    <span className="v2-btn-default has-bg-red has-icon" onClick={_openConfirm}>
                                        <IconTrash />
                                        {t('common:delete')}
                                    </span>
                                ) : null}
                                <div className="flexcenter justify-end gap-8 flex-1 btn-close">
                                    <span className="v2-btn-default --transparent" onClick={_close}>
                                        {t('common:cancel')}
                                    </span>
                                    <ButtonSave
                                        ref={refButtonSave}
                                        onSave={_handleSubmit}
                                        disabled={isLoadingPipelines}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}

                <GDModalWarning
                    ref={refWarning}
                    isLargeContent={false}
                    title={t('customers:delete_opportunity')}
                    description={t('customers:are_you_sure_delete_opportunity')}
                    footer={
                        <div className="footer-modal footer-hasbtn btn-close">
                            <span className="v2-btn-default --transparent" onClick={_closeConfirm}>
                                {t('cancel')}
                            </span>
                            <ButtonSave
                                ref={refButtonSave}
                                title={t('yes')}
                                className="v2-btn-main"
                                onSave={_handleConfirmDelete}
                            />
                        </div>
                    }
                />
            </>
        );
    }
);

export default Opportunity;
