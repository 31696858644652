import classNames from 'classnames';
import React, { useEffect, useReducer, useImperativeHandle, forwardRef } from 'react';
import { useDispatch } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { getListCustomerCharacter } from 'common/redux/actions/customers/customerAction';
import { SORT_BY } from 'app/const/report/ReportFilter';
import { getParamsCustomerList } from '../utils';
import { CHARACTER_DEFAULT_ITEM } from '../const';

const CustomerAlphabet = ({ activeCharacter = '', onClick = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { characters: [], isLoading: true });
    const { characters, isLoading } = state;
    const dispatch = useDispatch();

    useEffect(() => {
        getListCharacter();
    }, []);

    useImperativeHandle(ref, () => ({
        fetchList: getListCharacter
    }));

    const getListCharacter = (sortBy = '') => {
        const { sort_by } = getParamsCustomerList();

        const onSuccess = (res) => {
            dispatchState((prev) => ({
                ...prev,
                characters: [CHARACTER_DEFAULT_ITEM, ...res.data],
                isLoading: false
            }));
        };
        const onError = () => {
            dispatchState((prev) => ({
                ...prev,
                characters: [CHARACTER_DEFAULT_ITEM],
                isLoading: false
            }));
        };
        if (!isLoading) dispatchState({ isLoading: true });
        dispatch(getListCustomerCharacter({ [SORT_BY]: sortBy || sort_by }, onSuccess, onError));
    };

    if (isLoading) return <LoadingCharacter />;
    return (
        <div className="header filter-alphabet">
            <ListCharacter data={characters} activeCharacter={activeCharacter} onClick={onClick} />
        </div>
    );
};

const ListCharacter = ({ data = [], activeCharacter = '', onClick = () => {} }) => {
    if (!Array.isArray(data) || data.length === 0) return null;
    return data.map(({ id, name }) => {
        if (!id && !name) return null;

        return (
            <div
                key={id}
                className={classNames('alphabet-item', { 'is-active': activeCharacter === id })}
                onClick={() => onClick(id)}
            >
                {name}
            </div>
        );
    });
};

const LoadingCharacter = () => (
    <div className="wrap-loading flex-column gap-8">
        <div className="header filter-alphabet">
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
            <div className="v2-btn-default loading --animation --light --icon-x-sm bg-white-cultured field-h20"></div>
        </div>
    </div>
);

export default forwardRef(CustomerAlphabet);
