import GdButton from 'app/components/button';
import { GET_LIST_PHONE_TYPES } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { deletePhoneTypes } from 'common/redux/actions/phones';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

const DeletePhoneLabel = forwardRef(({ onShowAlert = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, idDelete: null, isLoading: false });
    const { idDelete, isOpen, isLoading } = state;
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (idDelete) => {
        dispatchState({ isOpen: true, idDelete });
    };

    const _close = () => {
        dispatchState({ isOpen: false, idDelete: null, isLoading: false });
    };

    const _handleSubmitUpdate = () => {
        dispatchState({ isLoading: true });
        const _handleUpdateSuccess = () => {
            dispatch(deletePhoneTypes(idDelete));
        };

        const _handleUpdateFinally = ({ message, success }) => {
            const typeAlert = success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR;

            onShowAlert({
                id: typeAlert,
                message: success ? t('delete_successfully') : message,
                type: typeAlert
            });
            _close();
        };

        clientQuery(
            GET_LIST_PHONE_TYPES,
            { data: { ids: [idDelete] }, method: 'DELETE' },
            _handleUpdateSuccess,
            null,
            _handleUpdateFinally
        );
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="confirm_deleted"
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_customer"
            onRequestClose={_close}
            className="modal --has-contain container-modal modal-confirm-delete open"
        >
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">Delete Confirmation</h3>
                </div>
                <div className="body-modal">
                    <p className="text">Are you sure you want to delete this label?</p>
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                        {t('cancel')}
                    </span>
                    <GdButton isLoading={isLoading} className="v2-btn-main" onClick={_handleSubmitUpdate}>
                        {t('confirm')}
                    </GdButton>
                </div>
            </div>
        </ReactModal>
    );
});

export default DeletePhoneLabel;
