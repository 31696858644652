/* eslint-disable no-unused-vars */
import React, { useReducer, forwardRef, useImperativeHandle } from 'react';
import { reducer } from 'app/const/Reducer';

const LoadMore = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        disabledBtn: false
    });

    useImperativeHandle(ref, () => ({
        setLoading: _setLoading,
        removeLoading: _removeLoading,
        getCurrent: state.isLoading,
        getState: () => state.isLoading
    }));

    function _setLoading(isLoading) {
        dispatchState((prev) => {
            return { ...prev, isLoading };
        });
    }

    function _removeLoading() {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false
            };
        });
    }

    if (!state.isLoading) {
        return false;
    }

    return (
        <div className="wrap-loading conts-conversation">
            <div className="load-inline">
                <div className="wrapper-message box-message-send mt-6">
                    <div className="wrap-items">
                        <div className="description loading --animation --half" />
                        <div className="loading --animation --line --twothird" />
                    </div>

                    <div className="images">
                        <div className="avt loading --animation" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LoadMore;
