import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconStar from 'assets/icon/IconStar';

function SelectRatingOption({ onSelect = () => {}, label, isCheck, value }) {
    return (
        <li
            onClick={(e) => {
                onSelect(e, value);
            }}
            className={classNames('items pr-2', { active: isCheck })}
        >
            <div className="rate">
                <p className="label orange-default">{label}</p>
                <StarIcons value={parseInt(value)} />
            </div>
        </li>
    );
}

const StarIcons = ({ value = 5 }) =>
    [...Array(value)].map((star, index) => (
        <div key={index} className="rate__items active">
            <IconStar />
        </div>
    ));

SelectRatingOption.propTypes = {
    onSelect: PropTypes.func,
    id: PropTypes.string,
    label: PropTypes.string,
    isCheck: PropTypes.bool,
    value: PropTypes.number
};

export default SelectRatingOption;
