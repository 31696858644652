import moment from "moment";
import { renderToString } from 'react-dom/server';
import { ButtonPreview, RatingStarPreview, LinkPreview } from "app/modules/drip/components/ComponentPreview";


import { TODAY } from "app/const/App";
import {
  USER_PROFILE,
  getRegexVariable
} from "app/const/drip";
import { formatDateTime } from 'common/utils/DateTimeUtils';

export const DATE_FORMAT = USER_PROFILE?.date_format || "Y/m/d";

export const LIST_VALUE_VARIABLE_CONTENT = [
  "[::ACH_invite::]",
  "[::ACH_verify_account::]",
  "[::book_online::]",
  "[::credit_card_invite::]",
  "[::online_payment_link::]",
  "[::portal_button::]",
  "[::preview_sign_estimate::]",
  "[::preview_sign_invoice::]",
  "[::view_estimate::]",
  "[::view_invoice::]",
  "[::preview_sign::]",
  "[::view_document::]",

  "[::client_first_name::]",
  "[::client_last_name::]",
  "[::service_address::]",
  "[::service_name::]",
  "[::tech_name::]",
  "[::sms_bot::]",
  "[::estimate_number::]",
  "[::invoice_number::]",
  "[::start_time::]",
  "[::time::]",

  "[::service_rating::]",
  "[::likely_rating::]",
  "[::portal_link::]",
  "[::confirmation_link::]",
  "[::work_order_link::]",
  "[::reschedule_link::]",
  "[::short_url::]",
  "[::Date/Time::]",
  "[::day::]"
]

export const LIST_VALUE_VARIABLE_TITLE = [
  "[::client_first_name::]",
  "[::client_last_name::]",
  "[::service_address::]",
  "[::service_name::]",
  "[::tech_name::]",
  "[::sms_bot::]",
  "[::estimate_number::]",
  "[::invoice_number::]",
  "[::start_time::]",
  "[::time::]",

  "[::Date/Time::]",
  "[::day::]"
]

export const VALUE_VARIABLE_USER = {
  company_email: "[::company_email::]",
  company_name: "[::company_name::]",
  company_phone: "[::company_phone::]",
  user_first_name: "[::user_first_name::]"
}

export const getDataPreview = (variable = "", callback = () => { }) => {
  const configVariable = CONFIG_INSERT_VARIABLE[variable];
  let replaceText = "";

  const { type, text, isNotTranslate } = configVariable;

  if (type) {
    replaceText = getItemPreview(type, text, callback);
  } else {
    replaceText = isNotTranslate ? text : callback(text);
  }

  return replaceText;
}

const LIST_TYPE_INSERT_VARIABLE = {
  BUTTON: "button",
  RATING_STAR: "rating_star",
  RATING_NUMBER: "rating_number",
  TEXT: "text",
  LINK: "link",
}

const DEFINE_ITEM_PREVIEW = {
  [LIST_TYPE_INSERT_VARIABLE.BUTTON]: ButtonPreview,
  [LIST_TYPE_INSERT_VARIABLE.LINK]: LinkPreview,
  [LIST_TYPE_INSERT_VARIABLE.RATING_STAR]: RatingStarPreview,
  [LIST_TYPE_INSERT_VARIABLE.RATING_NUMBER]: RatingStarPreview,
}

const getItemPreview = (type, text, callback) => {
  const Item = DEFINE_ITEM_PREVIEW[type];
  return renderToString(<Item text={text} onTranslate={callback} />)
}

const CONFIG_INSERT_VARIABLE = {
  "[::ACH_invite::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "add_bank_account" },
  "[::ACH_verify_account::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "verify_my_account" },
  "[::book_online::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "booking_online" },
  "[::credit_card_invite::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "add_a_credit_card" },
  "[::online_payment_link::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "pay_online" },
  "[::portal_button::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "manage_your_account" },
  "[::preview_sign_estimate::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "preview_and_sign_estimate" },
  "[::preview_sign_invoice::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "preview_and_sign_invoice" },
  "[::view_estimate::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "view_estimate" },
  "[::view_invoice::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "view_invoice" },
  "[::preview_sign::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "preview_sign" },
  "[::view_document::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "view_document" },
  "[::confirmation_link::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "confirmation_link" },
  "[::reschedule_link::]": { type: LIST_TYPE_INSERT_VARIABLE.BUTTON, text: "reschedule_link" },

  "[::client_first_name::]": { text: "client_first_name" },
  "[::client_last_name::]": { text: "client_last_name" },
  "[::service_address::]": { text: "service_address" },
  "[::service_name::]": { text: "service_name" },
  "[::tech_name::]": { text: "tech_name" },
  "[::sms_bot::]": { text: "sms_bot" },
  "[::estimate_number::]": { text: "123456789" },
  "[::invoice_number::]": { text: "123456789" },
  "[::start_time::]": { text: "9:00 AM", isNotTranslate: true },
  "[::user_first_name::]": { text: "user_first_name" },
  "[::time::]": { text: "9:00 AM - 2:30 PM", isNotTranslate: true },

  "[::service_rating::]": { type: LIST_TYPE_INSERT_VARIABLE.RATING_NUMBER, text: "" },
  "[::likely_rating::]": { type: LIST_TYPE_INSERT_VARIABLE.RATING_STAR, text: "" },

  "[::portal_link::]": { type: LIST_TYPE_INSERT_VARIABLE.LINK, text: "manage_your_account" },
  "[::work_order_link::]": { type: LIST_TYPE_INSERT_VARIABLE.LINK, text: "work_order" },
  "[::short_url::]": { type: LIST_TYPE_INSERT_VARIABLE.LINK, text: "short_url" },

  "[::Date/Time::]": { text: `${moment().format("dddd")}, ${formatDateTime(TODAY, DATE_FORMAT, true)} ${moment().format('h:mm A')}`, isNotTranslate: true },
  "[::day::]": { text: formatDateTime(TODAY, DATE_FORMAT, true), isNotTranslate: true },
};

export const replaceInfoUserInfo = value => {
  let newValue = value;

  const listValueUser = Object.keys(VALUE_VARIABLE_USER);

  USER_PROFILE && listValueUser.map(item => {
    if (USER_PROFILE[item]) {
      const regEx = getRegexVariable(VALUE_VARIABLE_USER[item]);
      newValue = newValue.replace(regEx, USER_PROFILE[item])
    }
  })

  return newValue;
}

export const replaceInfoVariables = (list, value, translation) => {
  let newValue = value;

  list.map(item => {
    const regexVariable = getRegexVariable(item);
    newValue = newValue.replace(regexVariable, getDataPreview(item, translation));
  });

  return newValue;
}