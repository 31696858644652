import { ApolloProvider } from '@apollo/client';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

import { INBOX } from 'app/config/routes';
import { PREFIX_TITLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { JOB_CUSTOM } from 'app/const/Job';
import { reducer } from 'app/const/Reducer';
import { getSmartView } from 'app/const/api/V2';
import { CUSTOM_FIELDS_ACTIONS } from 'common/redux/actions/customFields';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListJobStatus } from 'common/utils/JobStatusUtils';
import HeaderTable from './components/HeaderTable';
import HeaderSmartView from './components/header';
import ListFiltered from './components/listFiltered';
import SmartViewTable from './components/table';
import { convertCustomFieldsToColumns, convertCustomFieldsToFilters } from './components/utils';
import { convertCustomStatusToColumns } from './components/utils/index';
import {
    convertToColumnsWithValues,
    convertToFiltersWithValues,
    convertToSortWithValues
} from './components/utils/operator';
import { FILTERS_LIST } from './constants';
import { COLUMNS_OPTIONS } from './constants/columns';
import { BUILT_IN_TYPE_DEFINITION } from './constants/definitions';
import { OBJECT_TYPES } from './constants/types';
import { SmartViewProvider } from './context';
import { client } from './graphql';
import { DEFAULT_COLUMN_NAME } from './components/table/utils';

const SmartView = () => {
    const dispatch = useDispatch();

    const { id, branchid } = useParams();
    const { state: stateLocation } = useLocation();
    const history = useHistory();

    const { isFirstTime: loadingCustomField } = useSelector(({ customFields }) => customFields);
    const smartViewAddon = useSelector(({ auth }) => auth.user.settings.addons.smart_views);
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const customJobStatusAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_job_status);

    const [state, dispatchState] = useReducer(reducer, {
        filtered: [],
        sort: [],
        columns: DEFAULT_COLUMN_NAME,
        isLoading: true
    });
    const { isLoading } = state;
    const refWrapper = useRef(null);
    const refFirstTime = useRef(true);
    const isAccessSmartView = !!smartViewAddon;

    const _handleExpandFilter = () => {
        refWrapper.current?.classList?.toggle('is-hide-filter');
    };

    // This effect is used to get the list of job status
    useEffect(() => {
        if (!customJobStatusAddon) return;
        const statusesCustom = getListJobStatus({ filter: [JOB_CUSTOM], isInclude: true, isIncludeDeleted: true });
        const columnsConverted = convertCustomStatusToColumns(statusesCustom);

        const indexCustomJobStatus = COLUMNS_OPTIONS.findIndex((item) => item.isCustomJobStatus);
        if (indexCustomJobStatus < 0) {
            const indexJobGroup = COLUMNS_OPTIONS.findIndex((item) => item.objectType === OBJECT_TYPES.JOB);
            COLUMNS_OPTIONS.splice(indexJobGroup + 1, 0, columnsConverted);
        } else {
            COLUMNS_OPTIONS[indexCustomJobStatus] = columnsConverted;
        }
    }, []);

    // This effect is used to fetch custom fields
    // and add them to the filter and column list
    useEffect(() => {
        const actionSuccess = (data) => {
            // ***NOTE***
            // *** This stage we only need add to the first filter ***
            // ***NOTE***

            // Add custom fields to the filter list
            const length = FILTERS_LIST[0]['subcategories'].length;
            const newData = convertCustomFieldsToFilters(data);
            FILTERS_LIST[0]['subcategories'][length - 1]['fields'] = newData;
            BUILT_IN_TYPE_DEFINITION[0]['fields'] = [...newData];

            // Add custom fields to the column list
            const indexCustomField = COLUMNS_OPTIONS.findIndex((item) => item.objectType === OBJECT_TYPES.CUSTOM_FIELD);
            indexCustomField < 0
                ? COLUMNS_OPTIONS.splice(1, 0, convertCustomFieldsToColumns(data))
                : (COLUMNS_OPTIONS[indexCustomField] = convertCustomFieldsToColumns(data));
        };

        if (!customFieldAddon) {
            // Add custom fields to the column list
            const indexCustomField = COLUMNS_OPTIONS.findIndex((item) => item.objectType === OBJECT_TYPES.CUSTOM_FIELD);
            if (indexCustomField >= 0) COLUMNS_OPTIONS.splice(indexCustomField, 1);
        }

        if (loadingCustomField && !!customFieldAddon)
            dispatch({ type: CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS, payload: { actionSuccess } });
    }, []);

    useEffect(() => {
        if ((!refFirstTime.current && !stateLocation?.hardReload) || !isAccessSmartView) return;
        if (!isLoading) dispatchState((prevState) => ({ ...prevState, isLoading: true }));

        const _handleFetchSuccess = ({ data }) => {
            refFirstTime.current = false;
            document.title = `${PREFIX_TITLE} - ${data.name}`;

            const allows = [];
            if (customFieldAddon) allows.push(OBJECT_TYPES.CUSTOM_FIELD);
            if (customJobStatusAddon) allows.push(OBJECT_TYPES.CUSTOM_JOB_STATUSES);
            dispatchState((prevState) => {
                const definition = data.definition || {};
                return {
                    ...prevState,
                    name: data.name,
                    isLoading: false,
                    // For now, we are only supporting one filter
                    filtered: convertToFiltersWithValues(definition.query, { allows })[0]?.filters || [],
                    sort: convertToSortWithValues(definition.sort || []) || [],
                    columns: convertToColumnsWithValues(definition.columns, { allows }),
                    limit: definition.limit
                };
            });
        };
        const _handleFetchFail = () => {
            history.replace(addBranchPath(INBOX));
        };

        clientQuery(getSmartView(id), { data: {}, method: 'GET' }, _handleFetchSuccess, _handleFetchFail);
    }, [stateLocation]);

    const clientServer = useMemo(() => client(branchid), [branchid]);
    if (!isAccessSmartView) return <Redirect to={addBranchPath(INBOX)} />;
    const loading = isLoading || (loadingCustomField && customFieldAddon);
    return (
        <>
            <ApolloProvider client={clientServer}>
                <SmartViewProvider
                    name={state.name || ''}
                    filtered={state.filtered}
                    sort={state.sort}
                    limit={state.limit}
                    columns={state.columns}
                    isLoading={loading}
                >
                    <HeaderSmartView isLoading={isLoading} />
                    <div ref={refWrapper} className="wrap-tables flex-column relative smart-view-table">
                        <HeaderTable isLoading={loading} onExpandFilter={_handleExpandFilter} />
                        <ListFiltered isLoading={loading} />
                        <SmartViewTable isLoading={loading} />
                    </div>
                </SmartViewProvider>
            </ApolloProvider>
        </>
    );
};

export default SmartView;
