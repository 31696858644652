import { KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB } from 'app/const/App';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

const ChangeStatusCancel = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar, common']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        checked: false
    });
    const refEvents = useRef(null);

    function _showOptions(infoEvent, status, functionCallBack) {
        refEvents.current = {
            infoEvent,
            status,
            functionCallBack
        };

        dispatchState({ isVisible: true });
    }

    function _closeOptions(e) {
        e.stopPropagation();
        refEvents.current.infoEvent.revert();
        dispatchState({ isVisible: false });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _handleClickOption(e) {
        e.stopPropagation();

        if (state.checked) {
            setLocalStorage(KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB, 1);
        }

        refEvents.current && refEvents.current.functionCallBack(refEvents.current.status);

        dispatchState({ isVisible: false });
    }

    function _renderRecuring() {
        if (!state.isVisible) {
            return false;
        }

        return (
            <ReactModal
                isOpen
                onRequestClose={_closeOptions}
                className="modal container-modal batch-job --recurring open"
            >
                <div className="modal__overlay bg-fixed" onClick={(e) => _closeOptions(e)} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('calendar:canceled_job_status')}</h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={(e) => _closeOptions(e)}>
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal">
                        <div className="rows">
                            <p className="fs-14">
                                {t('calendar:cancelling_this_job_will_also_void_the_attached_invoice')}
                            </p>
                        </div>
                        <div className="rows" onClick={(e) => _handleClickOption(e)}>
                            <div className="v2-btn-default">{t('common:yes')}</div>
                        </div>
                    </div>
                    <div className="footer-modal" onClick={(e) => _closeOptions(e)}>
                        <div className="v2-btn-default --transparent w-100">{t('common:cancel')}</div>
                    </div>
                </div>
            </ReactModal>
        );
    }

    return _renderRecuring();
});

export default ChangeStatusCancel;
