import React from 'react';

export default function Loading() {
    return (
        <div className="addons-wrap__container addons-wrap__material">
            <div className="wrap-loading header --addon">
                <div className="header__back v2-btn-default loading --grey btn-x-sm">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper material-content pb-0">
                    <div className="wrap-loading --addon">
                        <div className="boxs boxs--no-border --hasline">
                            <div className="boxs__header">
                                <div className="header-title">
                                    <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                                    <div className="title">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                </div>
                                <div className="loading --onefifth switch ml-0">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                            <div className="boxs__contents mt-4">
                                <div className="description loading-lines">
                                    <div className="loading --animation --line --full" />
                                    <div className="loading --animation --line --twothird" />
                                </div>
                                <div className="btn-print v2-btn-default --grey loading mb-3">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="check-items">
                                    <div className="checkbox" />
                                    <div className="item-checkbox flex-1">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                </div>
                                <div className="check-items">
                                    <div className="checkbox" />
                                    <div className="item-checkbox flex-1">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="material-content-full boxs-wrapper">
                    <div className="wrap-loading --addon">
                        <div className="boxs --material boxs--no-border">
                            <div className="boxs__header pr-2">
                                <div className="header-action">
                                    <div className="title flex-1">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="switch loading --twothird">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                            <div className="boxs__contents">
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-2" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-2" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-2" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-2" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                        <div className="loading --animation --line --threefourth mt-2" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows btn-add-material">
                                    <div className="v2-btn-default --grey loading">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading --addon">
                        <div className="boxs --material boxs--no-border">
                            <div className="boxs__header pr-2">
                                <div className="header-action">
                                    <div className="title flex-1">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="switch loading --twothird">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                            <div className="boxs__contents">
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows btn-add-material">
                                    <div className="v2-btn-default --grey loading">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading --addon">
                        <div className="boxs --material boxs--no-border">
                            <div className="boxs__header pr-2">
                                <div className="header-action">
                                    <div className="title flex-1">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="switch loading --twothird">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                            <div className="boxs__contents">
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows py-3">
                                    <div className="rows__action">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0 ml-1" />
                                </div>
                                <div className="rows btn-add-material">
                                    <div className="v2-btn-default --grey loading">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
