import React, { useEffect, useRef, useState, useContext } from "react";
import { MONTHS } from 'app/modules/jobdetail/const'
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext'
import { useTranslation } from 'react-i18next';
import { parseExceptionMonth, converMonths } from 'app/modules/jobdetail/const/Utils'

const MonthExcept = () => {
    const { t } = useTranslation(['jobDetail']);

    const { repeatSettings, onChangeException } = useContext(DataRepeatContext);
    const jobExceptionDetail = repeatSettings.exception.options;
    
    const [monthActive, setMonthActive] = useState(parseExceptionMonth(jobExceptionDetail));
    const isFirstTime = useRef(true);

    useEffect(() => {
        if( monthActive.length > 0 && !isFirstTime.current ){
            const newException = {
                summary: `${t(`jobDetail:except`)} ${monthActive.join(', ')}`,
                options: {...converMonths(monthActive)}
            }
            onChangeException({...repeatSettings, exception: newException});
        }
    }, [monthActive]);

    function _handleChangeMonth(item){

        const checkIndex = monthActive.indexOf(item);
        const activeLength = monthActive.length

        if( (activeLength === 11 && checkIndex === -1) || (activeLength === 1 && checkIndex === 0) ) return false;

		if (checkIndex === -1) {
			setMonthActive([...monthActive, item]);
		} else {
			setMonthActive([
				...monthActive.slice(0, checkIndex),
				...monthActive.slice(checkIndex + 1),
			]);
		}
        isFirstTime.current = false
    }

    function _renderMonth(check) {
        const finalMonth = MONTHS.filter((item, index) => check ? index < 6 : index > 5  )
        return finalMonth.map((element) => {
            const monthName = element.name
            const isChecked = monthActive.includes(monthName)

            return (
                <div
                    key={monthName}
                    className="check-items"
                    onClick={() => _handleChangeMonth(monthName)}
                >
                    <input
                        id="check-jan"
                        type="checkbox"
                        checked={isChecked}
                        onChange={() => {}}
                    />
                    <div className="item-checkbox">
                        <label>
                            <span className="txt-ellipsis">{monthName}</span>
                        </label>
                    </div>
                </div>
            )
        });
    }

    return (
        <div className="tab-panel active">
            <div className="rows row-except-month">
                <div className="txt" />
                <div className="details d-flex">
                    <div className="col-haft">
                        {_renderMonth(true)}
                    </div>
                    <div className="col-haft">
                        {_renderMonth(false)}
                    </div>
                    <div className="col-haft">
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonthExcept