import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import VOIPAddonsLoadingHold from '../loadings/VOIPAddonsLoadingHold';
import BoxMusic from './BoxMusic';
import { ADDONS_VOIP } from 'app/config/routes';
import { GET_DETAIL_VOIP_MUSIC } from 'app/const/api/Voip';
import { addBranchPath } from 'app/const/Branch';
import GDStatusBar from 'app/components/status/statusbar';
import { ACCOUNT_ROLE, LIST_STATUS } from 'app/const/App';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';

const VOIPAddonsHold = () => {
    const { t } = useTranslation('addons');
    const { settings, profile } = useSelector(({ auth }) => auth.user);
    const refAlert = useRef(null);

    const isHavePermission = checkIsEnableVoip(settings) || profile.role === ACCOUNT_ROLE.SUPERADMIN;

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        options: []
    });

    const { isLoading, data: finalData, options: finalOptions } = state;

    useEffect(() => {
        if (!isHavePermission) return;
        fetchData();
    }, []);

    const fetchData = () => {
        const _handleSuccess = ({ data, options }) => {
            dispatchState((prev) => ({ ...prev, isLoading: false, data, options }));
        };

        clientQuery(GET_DETAIL_VOIP_MUSIC, { method: 'GET' }, _handleSuccess);
    };

    const _renderOptions = () => {
        return finalData.map((item) => {
            const { key } = item;

            if (key === 'ring') return null;

            return (
                <BoxMusic
                    key={key}
                    title={t(`${key}_music_title`)}
                    description={t(`desc_${key}_music`)}
                    initialData={item}
                    musics={finalOptions}
                    onShowStatus={_handleShowStatus}
                />
            );
        });
    };

    const _handleShowStatus = ({ success = false, message = '' }) => {
        refAlert.current.showStatusBar('hold_music', message, success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
    };

    if (!isHavePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_VOIP)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('back')}
                    </Link>
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper">
                        <GDStatusBar ref={refAlert} />
                        <div className="wrap-manager --music">
                            {isLoading ? <VOIPAddonsLoadingHold /> : <Fragment>{_renderOptions()}</Fragment>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VOIPAddonsHold;
