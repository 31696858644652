import React from 'react';

export default () => {
    return (
        <div className="wrap-loading nav-top header flex-betweenitems --smart-views">
            <div className="header__left flex-1">
                <div className="header-items flexcenter gap-4">
                    <div className="header-label loading btn-sm">
                        <div className="loading --animation --light --line --full" />
                    </div>
                    <div className="btn-action flexcenter dots loading">
                        <div className="v2-btn-default bg-white loading --grey btn-sm field-h24">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="header-items v2-btn-default btn-x-sm loading --grey bg-white">
                <div className="loading --animation --line --full" />
            </div>
            <div className="header-items v2-btn-default btn-sm loading --grey bg-white">
                <div className="loading --animation --line --full" />
            </div>
            <div className="header-items v2-btn-default --grey loading --icon-lg bg-white" />
        </div>
    );
};

export const LoadingHeaderTable = () => (
    <div className="header --filter align-center gap-8 wrap-loading">
        <div className="flexcenter w-100 flex-wrap gap-4">
            <div className="flex-1 flexcenter gap-4 field-h32">
                <div className="v2-btn-default --transparent --icon-sm loading bg-white-cultured" />
                <div className="loading --animation --line btn-sm" />
            </div>
            <div className="header-items loading field-h24 v2-btn-default --grey btn-x-sm">
                <div className="loading --animation --line --full" />
            </div>
            <div className="is-divider" />
            <div className="header-items loading field-h24 v2-btn-default --grey btn-sm">
                <div className="loading --animation --line --full" />
            </div>
        </div>
    </div>
);

export const LoadingFiltered = () => (
    <div className="header --filter wrapper__filter align-center wrap-loading">
        <div className="wrapper__filter-tags flexcenter bg-white border-border-color-grey gap-4">
            <div className="btn-x-sm loading --animation --line --twothird" />
            <div className="btn-sm --animation loading --line m-0" />
            <div className="v2-btn-default loading --icon-x-sm bg-white-cultured" />
            <div className="btn-x-sm loading --animation --line --twothird" />
        </div>
        <div className="wrapper__filter-tags flexcenter bg-white border-border-color-grey gap-4">
            <div className="btn-x-sm loading --animation --line --twothird" />
            <div className="btn-sm --animation loading --line m-0" />
            <div className="v2-btn-default loading --icon-x-sm bg-white-cultured" />
            <div className="btn-x-sm loading --animation --line --twothird" />
        </div>
        <div className="wrapper__filter-tags flexcenter bg-white border-border-color-grey gap-4">
            <div className="btn-x-sm loading --animation --line --twothird" />
            <div className="btn-sm --animation loading --line m-0" />
            <div className="v2-btn-default loading --icon-x-sm bg-white-cultured" />
            <div className="btn-x-sm loading --animation --line --twothird" />
        </div>
        <div className="loading field-h24 v2-btn-default --grey btn-x-sm">
            <div className="loading --animation --line --full" />
        </div>
    </div>
);

export const LoadingSetLimit = () => {
    return (
        <div className="wrap-loading">
            <div className="field-h32 btn-sm">
                <div className="loading --animation --light --line --full" />
            </div>
        </div>
    );
};
