import GdButton from 'app/components/button';
import { LOCATION_CUSTOMER_LIST, TABS_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import FilterReport from 'app/modules/report/components/FilterReport';
import { useTranslation } from 'react-i18next';
import IconBell from 'assets/icon/IconBell';
import React, { useRef } from 'react';
import classNames from 'classnames';
import MessagePreferences from './MessagePreferences';
import Export, { generateTitleExport } from 'app/modules/report/components/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { urlExportLocationsCustomer } from 'app/const/api/Export';
import IconTrash from 'assets/icon/IconTrash';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import LocationHeader from './LocationHeader';

const LocationActions = ({
    onAddLocation = () => {},
    handleUpdate = () => {},
    totalSelected = 0,
    ids,
    handleSaveMassageSuccess,
    params = {},
    customerId,
    totalItems = 0,
    refresh = 0,
    onHandleChangeFilter = () => {},
    onHandleDelete = () => {},
    onHandleUnDelete = () => {},
    checkedItems = {}
}) => {
    const { t } = useTranslation(['customers', 'common']);
    const refMessage = useRef(null);
    const titleExport = generateTitleExport(totalItems, 'customers', 'item');
    const isTabActive = params.filterTab === TABS_FILTER.ACTIVE_CUSTOMER_LOCATION.value;

    const _openMessage = () => {
        refMessage.current._openModalSetting();
    };

    return (
        <>
            <div className="header --filter align-center">
                <div className="header__left flex-1">
                    <CheckBoxHeader checkedItems={checkedItems} isShowTotal total={totalItems} />
                    {isTabActive ? (
                        <>
                            <div
                                onClick={onHandleDelete}
                                className={classNames('v2-btn-default has-icon svg-delete-grey header-items', {
                                    'is-disable': !totalSelected
                                })}
                            >
                                <IconTrash />
                                {t('customers:delete')}
                            </div>
                            <div
                                className={classNames('v2-btn-default has-icon header-items', {
                                    'is-disable': !totalSelected
                                })}
                                onClick={_openMessage}
                            >
                                <IconBell /> {t('customers:messaging')}
                            </div>

                            <MessagePreferences
                                handleSaveMassageSuccess={handleSaveMassageSuccess}
                                ref={refMessage}
                                location_ids={ids}
                            />

                            <FilterReport
                                isPermission
                                reportType={REPORT_TYPE.LOCATION_CUSTOMER_LIST}
                                filters={LOCATION_CUSTOMER_LIST}
                                onSelect={onHandleChangeFilter}
                            />
                            <GdButton onClick={handleUpdate} className="v2-btn-main header-items">
                                {t('common:update')}
                            </GdButton>
                        </>
                    ) : (
                        <div
                            onClick={onHandleUnDelete}
                            className={classNames('v2-btn-default has-icon svg-delete-grey header-items', {
                                'is-disable': !totalSelected
                            })}
                        >
                            <IconDoubleCheck />
                            {t('customers:un_delete')}
                        </div>
                    )}
                </div>
                {isTabActive && (
                    <Export
                        title={titleExport}
                        activePrint
                        url={urlExportLocationsCustomer(customerId)}
                        params={params}
                        pageExport={LIST_EXPORT.EXPORT_LOCATIONS_CUSTOMER}
                        refresh={refresh}
                    />
                )}
                <LocationHeader onAddLocation={onAddLocation} />
            </div>
        </>
    );
};

export default LocationActions;
