import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortOrderTable from './SortOrderTable';

//Grid Header Column with order feature
function GirdHeaderOrder({ id, style, title, handleOrder, orderStatus }) {
    return (
        <div key={id} className={classNames(style)}>
            <div className={classNames('has-orderby', orderStatus)} title={title}>
                <span className="orderby-txt">{title}</span>
                <span className="caret" />
                <SortOrderTable value={id} currentSort={orderStatus} onClickOrder={handleOrder} />
            </div>
        </div>
    );
}
GirdHeaderOrder.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.string,
    title: PropTypes.string,
    handleOrder: PropTypes.func,
    orderStatus: PropTypes.string
};

//Grid Header Column
function GirdHeader({ id, style, title }) {
    return (
        <div key={id} className={style}>
            <p className="col-label">{title}</p>
        </div>
    );
}
GirdHeader.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.string,
    title: PropTypes.string
};

export { GirdHeaderOrder, GirdHeader };
