export const EMAIL_DATA_DEFAULT = {
    subject: ''
};

export const LIST_OPTIONS_SHCEDULE = [
    {
        name: '1 month',
        number: 1,
        type: 'months',
        ago: 'month'
    },
    {
        name: '2 M',
        number: 2,
        type: 'months',
        ago: 'months'
    },
    {
        name: '3 M',
        number: 3,
        type: 'months',
        ago: 'months'
    },
    {
        name: '1 week',
        number: 1,
        type: 'weeks',
        ago: 'week'
    },
    {
        name: '2 W',
        number: 2,
        type: 'weeks',
        ago: 'weeks'
    },
    {
        name: '3 W',
        number: 3,
        type: 'weeks',
        ago: 'weeks'
    },
    {
        name: '1 day',
        number: 1,
        type: 'days',
        ago: 'day'
    },
    {
        name: '2 D',
        number: 2,
        type: 'days',
        ago: 'days'
    },
    {
        name: '3 D',
        number: 3,
        type: 'days',
        ago: 'days'
    }
];

export const EMAIL_OPTIONS = {
    REPLY: 'reply',
    REPLY_ALL: 'reply_all',
    FORWARD: 'forward',
    UPDATE_DRAF: 'update_draf'
};

export const EMAIL_DEFAULT_DATA = {
    id: '',
    email_id: '',
    customer_id: '',
    status: '0',
    snooze_date: null,
    user: null,
    subject: '',
    emails: [],
    type: 'email_inbox'
};

export const EMAIL_DETAIL_DEFAULT_DATA = {
    id: '',
    full_name: '',
    content: '',
    date: '',
    tracking: [],
    subject: '',
    draft: 0,
    send_error: 0,
    schedule: '',
    follow_up: ''
};

export const OPTION_FOLLOW_UP = {
    REMIND: 'remind_me_follow_up',
    OFF: 'off'
};

export const TYPES_REALTIME_EMAIL_INBOX = {
    SYNC_NEW_EMAIL: 'sync_cus_new',
    SYNC_REPLY_NEW_EMAIL: 'sync_cus_update',
    GD_REPLY_NEW_EMAIL: 'update_new',
    GD_NEW_EMAIL: 'even_new',
    DELETE_EMAIL: 'even_delete_email_cus'
};

export const TYPE_LOAD_TIME_LINE = {
    ADD_EMAIL: 'addemail'
};
