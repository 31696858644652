import IconUnderline from 'assets/icon/IconUnderline';
import classNames from 'classnames';
import React from 'react';
import { handleGetActiveInlineStyle } from '../utils';

const CustomUnderlineOption = ({ editorState, onInsert = () => {} }) => {
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'UNDERLINE')
            })}
            onMouseDown={(e) => onInsert(e, 'UNDERLINE')}
        >
            <IconUnderline />
            <span className="tooltiptext top">Underline (CTRL+U)</span>
        </span>
    );
};

export default CustomUnderlineOption;
