import React, { useReducer, useImperativeHandle, forwardRef } from 'react';
import StatusBar from 'app/components/status';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';

const CustomerAccountStatus = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        id: null,
        message: '',
        type: LIST_STATUS.SUCCESS
    });

    useImperativeHandle(ref, () => ({
        showStatusBar: _handleShowStatus
    }));

    function _handleShowStatus(id, message, type) {
        dispatchState({ id, message, type });
    }

    function _closeStatusBar() {
        dispatchState({ id: null });
    }

    if (!state.id) {
        return false;
    }

    return (
        <StatusBar
            key={state.id}
            message={state.message}
            type={state.type}
            id={state.id}
            closeStatusBar={_closeStatusBar}
        />
    );
});

export default CustomerAccountStatus;
