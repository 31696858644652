export const LoadingHeader = () => {
    return (
        <div className="wrap-loading header-job-detail header-modal">
            <h3 className="header-modal__label">
                <div className="loading --animation --line --twothird" />
            </h3>
            <div className="loading --animation v2-btn-default --transparent --icon-sm" />
            <div className="line" />
            <div className="loading --animation v2-btn-default --transparent --icon-sm" />
            <div className="loading --animation v2-btn-default --transparent --icon-sm ml-3" />
        </div>
    );
};

export const LoadingService = () => {
    return (
        <div className="wrap-loading details-job">
            <div className="rows justify-start">
                <div className="txt">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="loading v2-btn-default --grey btn-lg">
                    <div className="loading --animation --line --threefourth" />
                </div>
            </div>
        </div>
    );
};
