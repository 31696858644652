import React from 'react';

export const VOIPAddonsLoadingManagePlan = () => {
    return (
        <div className="wrap-loading ">
            <div className="header-modal">
                <div className="header-modal__label">
                    <div className="loading --animation --line --onefourth" />
                </div>
                <div className="v2-btn-default --icon-lg loading bg-white-cultured" />
            </div>
            <div className="body-modal">
                <div className="wrap-plans flex-column gap-12">
                    <div className="loading --animation --line --fourth" />
                    <div className="wrap-plans__option d-flex gap-4">
                        <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                            <div className="header-plans flex-column flexcenter">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                            </div>
                            <div className="content-plans grey-dark flex-column gap-4">
                                <div className="loading --animation --line --full mt-1" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                            </div>
                            <div className="loading --animation --line --fourth mt-4" />
                        </div>
                        <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                            <div className="header-plans flex-column flexcenter">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="v2-btn-default field-h24 loading --grey btn-sm mt-2 mb-1">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                            <div className="content-plans grey-dark flex-column gap-4">
                                <div className="loading --animation --line --full mt-1" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                            </div>
                            <div className="loading --animation --line --fourth mt-4" />
                        </div>
                        <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                            <div className="header-plans flex-column flexcenter">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="v2-btn-default field-h24 loading --grey btn-sm mt-2 mb-1">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                            <div className="content-plans grey-dark flex-column gap-4">
                                <div className="loading --animation --line --full mt-1" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --half mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                                <div className="loading --animation --line --threefourth mt-2" />
                            </div>
                            <div className="loading --animation --line --fourth mt-4" />
                        </div>
                    </div>
                    <div className="is-divider --horizontal" />
                    <div className="wrap-plans__user">
                        <div className="flex-betweenitems fw-600">
                            <div className="loading --animation --line --twothird" />
                            <div className="loading --animation --line --onefifth mt-2" />
                        </div>
                        <div className="loading --animation --line --threefourth mt-2 mb-3" />
                        <div className="box-plans border-border-color-grey shadow-none --lg">
                            <div className="slider">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="slider --range">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="is-divider --horizontal my-3" />
                <div className="wrap-plans flex-column gap-12">
                    <div className="flex-column gap-6">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --full mt-2" />
                        <div className="loading --animation --line --threefourth mt-2" />
                        <div className="loading --animation --line --full mt-4" />
                        <div className="loading --animation --line --half mt-2" />
                    </div>
                    <div className="is-divider --horizontal" />
                    <div className="wrap-plans__balance">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --full mt-2" />
                    </div>
                    <div className="is-divider --horizontal" />
                    <div className="wrap-plans__cost flexcenter gap-24">
                        <div className="flex-column gap-4 flex-1">
                            <div className="loading --animation --line --threefourth" />
                            <div className="box-plans pb-6 border-border-color-grey">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --full mt-2" />
                            </div>
                        </div>
                        <div className="flex-column gap-4 flex-1">
                            <div className="loading --animation --line --threefourth" />
                            <div className="box-plans pb-6 border-border-color-grey">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --full mt-2" />
                            </div>
                        </div>
                    </div>
                    <div className="box-plans --lg flex-column gap-10 border-border-color-grey">
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --onefifth" />
                            <div className="loading --animation --line --onefifth mt-0" />
                        </div>
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --onefifth mt-0" />
                        </div>
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefifth mt-0" />
                        </div>
                        <div className="is-divider --horizontal" />
                        <div className="flex-betweenitems fw-600">
                            <div className="loading --animation --line --onefifth" />
                            <div className="loading --animation --line --onefifth mt-0" />
                        </div>
                        <div className="note-billing">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-modal">
                <div className="loading --animation --line --onefourth" />
                <div className="flexcenter justify-end gap-8 flex-1">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default loading --grey btn-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};
