import { LIST_STATUS } from 'app/const/Status';
import React, { useRef } from 'react';
import AlertCustomer from '../../components/AlertCustomer';
import CustomerInvoiceTable from './components/CustomerInvoiceTable';
import CustomerInvoiceTableRecurring from './components/CustomerInvoiceTableRecurring';
import CustomerSubscription from './components/CustomerSubscription';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';

const CustomerInvoices = () => {
    const refAlert = useRef(null);
    const refAlertSuccess = useRef(null);
    const refInvoiceRecurring = useRef(null);

    const _handleShowAlert = (type, data) => {
        switch (type) {
            case LIST_STATUS.ERROR:
                refAlert.current.showStatusBar(data);
                break;
            case LIST_STATUS.SUCCESS:
                refAlertSuccess.current.showStatusBar(data);
                break;
            default:
                break;
        }
    };

    const _reloadInvoicesRecurring = (params) =>
        refInvoiceRecurring.current._getListInvoiceRecurring({ archived: params?.archived, deleted: params?.deleted });
    return (
        <>
            <div className="wrapper-columns p-0">
                <div className="container-print has-tab invoice-page contents-pages">
                    <AlertCustomer ref={refAlert} />
                    <AlertCustomer ref={refAlertSuccess} />
                    <div className="invoice-list scrolls flex-1">
                        <div className="tab-content flex-column gap-16">
                            <CustomerSubscription type={PAYMENT_CARD_CUSTOMER.STRIPE} onShowAlert={_handleShowAlert} />
                            <CustomerSubscription type={PAYMENT_CARD_CUSTOMER.SQUARE} onShowAlert={_handleShowAlert} />
                            <CustomerInvoiceTableRecurring ref={refInvoiceRecurring} onShowAlert={_handleShowAlert} />
                            <CustomerInvoiceTable
                                onShowAlert={_handleShowAlert}
                                reloadInvoicesRecurring={_reloadInvoicesRecurring}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerInvoices;
