export default function IconUpgrade({
    isFillOrange = false,
    isFillWhite = false,
    isAddonUpgrade = false,
    width = 24,
    height = 24
}) {
    if (isFillWhite) {
        return (
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.5 3C19.7761 3 20 3.22386 20 3.5V5H21.5C21.7761 5 22 5.22386 22 5.5C22 5.77614 21.7761 6 21.5 6H20V7.5C20 7.77614 19.7761 8 19.5 8C19.2239 8 19 7.77614 19 7.5V6H17.5C17.2239 6 17 5.77614 17 5.5C17 5.22386 17.2239 5 17.5 5H19V3.5C19 3.22386 19.2239 3 19.5 3Z"
                    fill="#FFFFFF"
                />
                <path
                    opacity="0.49335"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5047 3.21947L14 13L5.59933 13.7637C5.04931 13.8137 4.5629 13.4084 4.5129 12.8583C4.4872 12.5756 4.58285 12.2954 4.776 12.0873L13.7835 2.38697C14.1593 1.98226 14.792 1.95882 15.1967 2.33462C15.4397 2.56027 15.5551 2.89171 15.5047 3.21947Z"
                    fill="#FFFFFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5578 10.8584L18.4006 10.2363C18.9506 10.1863 19.437 10.5917 19.487 11.1417C19.5127 11.4244 19.4171 11.7047 19.2239 11.9127L10.2164 21.613C9.84065 22.0178 9.20792 22.0412 8.80321 21.6654C8.5602 21.4397 8.44486 21.1083 8.49528 20.7805L9.76213 12.5461C9.90186 11.6378 10.6426 10.9416 11.5578 10.8584Z"
                    fill="#FFFFFF"
                />
            </svg>
        );
    }
    if (isAddonUpgrade) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.49335"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5047 3.21947L14 13L5.59933 13.7637C5.04931 13.8137 4.5629 13.4084 4.5129 12.8583C4.4872 12.5756 4.58285 12.2954 4.776 12.0873L13.7835 2.38697C14.1593 1.98226 14.792 1.95882 15.1967 2.33462C15.4397 2.56027 15.5551 2.89171 15.5047 3.21947Z"
                    fill="#FFFFFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5578 10.8584L18.4006 10.2363C18.9506 10.1863 19.437 10.5917 19.487 11.1417C19.5127 11.4244 19.4171 11.7047 19.2239 11.9127L10.2164 21.613C9.84065 22.0178 9.20792 22.0412 8.80321 21.6654C8.5602 21.4397 8.44486 21.1083 8.49528 20.7805L9.76213 12.5461C9.90186 11.6378 10.6426 10.9416 11.5578 10.8584Z"
                    fill="#FFFFFF"
                />
            </svg>
        );
    }
    if (isFillOrange)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.5 3C19.7761 3 20 3.22386 20 3.5V5H21.5C21.7761 5 22 5.22386 22 5.5C22 5.77614 21.7761 6 21.5 6H20V7.5C20 7.77614 19.7761 8 19.5 8C19.2239 8 19 7.77614 19 7.5V6H17.5C17.2239 6 17 5.77614 17 5.5C17 5.22386 17.2239 5 17.5 5H19V3.5C19 3.22386 19.2239 3 19.5 3Z"
                    fill="#FF871E"
                />
                <path
                    opacity="0.49335"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.5047 3.21947L14 13L5.59933 13.7637C5.04931 13.8137 4.5629 13.4084 4.5129 12.8583C4.4872 12.5756 4.58285 12.2954 4.776 12.0873L13.7835 2.38697C14.1593 1.98226 14.792 1.95882 15.1967 2.33462C15.4397 2.56027 15.5551 2.89171 15.5047 3.21947Z"
                    fill="#FF871E"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5578 10.8584L18.4006 10.2363C18.9506 10.1863 19.437 10.5917 19.487 11.1417C19.5127 11.4244 19.4171 11.7047 19.2239 11.9127L10.2164 21.613C9.84065 22.0178 9.20792 22.0412 8.80321 21.6654C8.5602 21.4397 8.44486 21.1083 8.49528 20.7805L9.76213 12.5461C9.90186 11.6378 10.6426 10.9416 11.5578 10.8584Z"
                    fill="#FF871E"
                />
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.1506 4.26363C14.3826 4.49566 14.486 4.82677 14.4273 5.14962L13.426 10.6567L17.2125 10.2787C17.762 10.2238 18.2521 10.6247 18.307 11.1743C18.3369 11.4727 18.2312 11.7688 18.0191 11.9809L10.2636 19.7364C9.8731 20.1269 9.23994 20.1269 8.84942 19.7364C8.61738 19.5043 8.51395 19.1732 8.57265 18.8504L9.57399 13.3427L5.7875 13.7212C5.23795 13.7762 4.74791 13.3753 4.69296 12.8257C4.66311 12.5273 4.7688 12.2312 4.98089 12.0191L12.7364 4.26363C13.1269 3.8731 13.76 3.8731 14.1506 4.26363Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M19.5 3.5V7.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M17.5 5.5H21.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
