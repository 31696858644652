import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getCrewUser } from 'app/modules/settings/utils/userCompany';
import IconCircleClose from 'assets/icon/IconCircleClose';
import { MAX_LENGTH_CREW } from 'app/modules/jobdetail/const';
import RenderAvatar from 'app/components/avatar/RenderAvatar';

const CrewGroup = ({
    crewSelected = {},
    group = {},
    isWithOutGroup = false,
    virtualRow = {},
    onSelectCrew = () => {}
}) => {
    const { id, name: nameGroup, crew_ids: finalCrews } = group || {};
    const isActive =
        finalCrews.length <= Object.keys(crewSelected).length && finalCrews.every((id) => !!crewSelected[id]);

    return (
        <div
            ref={virtualRow.measureRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualRow.start}px)`,
                paddingBottom: '4px'
            }}
        >
            <div className={classNames('wrap-crew__items', { active: isActive, '--has-group': !isWithOutGroup })}>
                {!isWithOutGroup ? (
                    <CrewItem id={id} groupName={nameGroup} isActive={isActive} isGroup onSelectCrew={onSelectCrew} />
                ) : null}
                {finalCrews.map((item) => (
                    <CrewItem
                        key={item}
                        id={item}
                        groupName={nameGroup}
                        isActive={!!crewSelected[item]}
                        onSelectCrew={onSelectCrew}
                    />
                ))}
            </div>
        </div>
    );
};

export default CrewGroup;

const CrewItem = ({ id, groupName = '', isActive = false, isGroup = false, onSelectCrew = () => {} }) => {
    const crewData = getCrewUser({ crewId: id }) || {};
    const finalName = isGroup ? groupName : crewData?.full_name;
    const idElm = `${groupName}_${id}`;

    const _handleOnChange = (e) => {
        onSelectCrew({ id, isChecked: e.target.checked, groupName, isGroup });
    };

    return (
        <div className={classNames('items', { active: isActive })}>
            <div className="check-items">
                <input id={idElm} type="checkbox" checked={isActive} onChange={_handleOnChange} />
                <div className="item-checkbox">
                    <label htmlFor={idElm}>
                        {!isGroup ? <RenderAvatar userData={crewData} /> : null}
                        <span className={classNames('txt-ellipsis', { 'fw-600 black': isGroup })}>{finalName}</span>
                    </label>
                </div>
            </div>
        </div>
    );
};

const CrewAvtAssign = ({ id, crew = {}, number = 0 }) => {
    if (number > 0) return <div className="avt-img avt-more fs-10 fw-500">{number}</div>;
    return <RenderAvatar userData={crew[id]} />;
};

export const CrewAssign = ({ listCrew = [], isHiddenClose = false, onCloseCrewSelect = () => {} }) => {
    const { t } = useTranslation('setting');
    const crewUsers = useSelector(({ companyUsers }) => companyUsers.crew.users);
    const lengthCrew = listCrew.length;

    const _handleClose = () => {
        onCloseCrewSelect([]);
    };

    if (!lengthCrew) return null;

    return (
        <div className="list-crew">
            {listCrew.map((item, index) => {
                const isCrewLatest = index + 1 === lengthCrew || index >= MAX_LENGTH_CREW;
                const countNumber = lengthCrew - MAX_LENGTH_CREW;

                if (index > MAX_LENGTH_CREW) return null;

                if (isCrewLatest)
                    return (
                        <div key={item} className="tech-name">
                            <CrewAvtAssign id={item} crew={crewUsers} number={countNumber} />
                            <span className="txt-ellipsis">
                                {countNumber < 1 ? crewUsers[item]?.full_name : t('crews')}
                            </span>
                            {!isHiddenClose ? (
                                <div className="close" onClick={_handleClose}>
                                    <IconCircleClose />
                                </div>
                            ) : null}
                        </div>
                    );

                return <CrewAvtAssign key={item} id={item} crew={crewUsers} />;
            })}
        </div>
    );
};
