import { CUSTOMER_ACTIONS } from 'common/redux/actions/customer';

const customerReducer = (state = {
    sources: null
}, action) => {
    switch (action.type) {
        case CUSTOMER_ACTIONS.UPDATE_SOURCES:
            return { ...state, sources: [...(action.payload || [])] };
        case CUSTOMER_ACTIONS.ADD_SOURCES:
            return { ...state, sources: [...(state.sources || []), action.payload] };
        default:
            return state;
    }
};

export default customerReducer;
