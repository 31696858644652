export default function IconError() {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z"
                stroke="#EE4646"
                strokeWidth="1.3"
            ></path>
            <path
                d="M6 9.5C6.55228 9.5 7 9.05228 7 8.5C7 7.94772 6.55228 7.5 6 7.5C5.44772 7.5 5 7.94772 5 8.5C5 9.05228 5.44772 9.5 6 9.5Z"
                fill="#EE4646"
            ></path>
            <path
                d="M6.5 6.5V4.5C6.5 4.22386 6.27614 4 6 4C5.72386 4 5.5 4.22386 5.5 4.5V6.5C5.5 6.77614 5.72386 7 6 7C6.27614 7 6.5 6.77614 6.5 6.5Z"
                fill="#EE4646"
            ></path>
        </svg>
    );
}
