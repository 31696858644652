import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { getPhoneTypes } from 'common/redux/actions/phones';
import PropTypes from 'prop-types';
import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const ItemPhoneSelect = ({
    id,
    hasPhone = false,
    defaultPhone = '',
    defaultType = null,
    onRemove = () => {},
    onSelect = () => {},
    onChange = () => {},
    onBlur = () => {},
    onOpenManage = () => {},
    onOpenAddPhone = () => {},
    classWrapper = '',
    childrenPhone,
    isDisable = false
}) => {
    const dispatch = useDispatch();
    const dataPhones = useSelector(({ phoneTypes }) => phoneTypes || {});
    const [selected, setSelected] = useState(defaultType || { id: '1', name: 'Mobile', is_default: 1 });

    const phoneTypes = dataPhones.phoneTypes || [];
    const refDropdown = useRef(null);
    const refPhone = useRef(null);
    const refValue = useRef(defaultPhone);

    useLayoutEffect(() => {
        if (refValue.current !== defaultPhone) refPhone.current.value = defaultPhone;
    }, [defaultPhone]);

    useLayoutEffect(() => {
        if (defaultType) setSelected(defaultType);
    }, [defaultType]);

    useLayoutEffect(() => {
        const newPhoneAdded = dataPhones.newPhoneType;
        if (newPhoneAdded && id === newPhoneAdded.idPhone) {
            setSelected(newPhoneAdded);
            onSelect(id, newPhoneAdded);
        }
    }, [dataPhones.newPhoneType]);

    const _handleVisible = () => {
        if (dataPhones.isFirstTime) dispatch(getPhoneTypes());
    };

    const _closeDropdown = () => {
        refDropdown.current._closeDropdown();
    };

    const _selectType = (idType) => {
        const newSelected = phoneTypes.find((item) => item.id === idType);
        setSelected(newSelected);
        onSelect(id, newSelected);
        _closeDropdown();
    };

    const _handleChange = (e) => {
        onChange(id, e.target.value);
    };

    const _handleBlur = (e) => {
        onBlur(id, e.target.value);
    };

    const _handleOpenManage = () => {
        _closeDropdown();
        onOpenManage();
    };

    const _handleAddPhone = () => {
        _closeDropdown();
        onOpenAddPhone(id);
    };

    return (
        <div className={classWrapper || classNames('has-many-field__items phone-field', { 'is-new-field': !hasPhone })}>
            {!!childrenPhone ? (
                childrenPhone
            ) : (
                <input
                    ref={refPhone}
                    name="phone"
                    className="field-input"
                    type="text"
                    defaultValue={defaultPhone}
                    placeholder="000-000-0000"
                    onChange={_handleChange}
                    onBlur={_handleBlur}
                    spellCheck
                    disabled={isDisable}
                />
            )}

            <CalendarDropdown
                id="customer_phones"
                ref={refDropdown}
                buttonClassName="dropbtn items"
                isLoading={dataPhones.isFirstTime}
                wrapperListClass="v2-dropdown__menu content-checked"
                customDropButton={() => (
                    <Fragment>
                        <span className="txt-ellipsis">{selected?.type?.name || selected?.name}</span>
                        <span className="arrow">
                            <IconArrowDown />
                        </span>
                    </Fragment>
                )}
                onVisible={_handleVisible}
            >
                <ListPhones
                    data={phoneTypes || []}
                    selected={selected?.type || selected}
                    onSelect={_selectType}
                    onOpenManage={_handleOpenManage}
                    onOpenAddPhone={_handleAddPhone}
                />
            </CalendarDropdown>

            {!hasPhone && (
                <div className="v2-btn-default --delete --icon-lg" onClick={() => onRemove(id)} tabIndex="0">
                    <IconTrash />
                </div>
            )}
        </div>
    );
};

const ListPhones = ({
    data = [],
    selected,
    onSelect = () => {},
    onOpenManage = () => {},
    onOpenAddPhone = () => {}
}) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="content-checked__all">
                <span className="manage-phone-label cursor-pointer" onClick={onOpenManage} tabIndex="0">
                    {t('customers:manage_phone_labels')}
                </span>
                <span className="v2-btn-default --icon-lg --transparent" onClick={onOpenAddPhone} tabIndex="0">
                    <IconPlus />
                </span>
            </div>
            <ul className="scrolls">
                {data.map((item) => {
                    return (
                        <li
                            key={item.id}
                            className={classNames('items', { active: selected?.id === item.id })}
                            onClick={() => onSelect(item.id)}
                            tabIndex="0"
                        >
                            <span className="txt-ellipsis">{item.name}</span>
                        </li>
                    );
                })}
            </ul>
        </Fragment>
    );
};

ItemPhoneSelect.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hasPhone: PropTypes.bool,
    isMaxPhones: PropTypes.bool,
    phoneTypes: PropTypes.array,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    onAddPhone: PropTypes.func
};

export default ItemPhoneSelect;
