import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import {
  LIST_VALUE_VARIABLE_TITLE,
  replaceInfoUserInfo,
  replaceInfoVariables
} from "app/const/drip/InsertVariables";

function DripPreviewSubject({
  subject,
  style
}) {
  
  const { t } = useTranslation(["drip"]);

  let previewSubject = subject || "";

  previewSubject = replaceInfoUserInfo(previewSubject);
  previewSubject = replaceInfoVariables(LIST_VALUE_VARIABLE_TITLE, previewSubject, t);

  return (
    <div className={style} dangerouslySetInnerHTML={{ __html: previewSubject }} />
  )
}

DripPreviewSubject.propTypes = {
  subject: PropTypes.string,
}

const compare = (prevProps, nextProps) => {
  return prevProps.subject == nextProps.subject && prevProps.style == nextProps.style
}

export default React.memo(DripPreviewSubject, compare);

