import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
    getDetailServiceTemplate,
    getListServiceTemplate,
    createAndUpdateServiceTemplate,
    previewServiceTemplate
} from 'common/redux/actions/settings/templateAction';

import GdConfirm from 'app/components/confirm';
import SettingTemplateForm from 'app/modules/settings/components/SettingTemplateForm';

import { SETTING_TEMPLATE_TYPE } from 'app/const/Settings';

const Invoice = () => {
    const { t } = useTranslation(['setting']);
    const dispatch = useDispatch();
    const refNotificationSaveSuccess = useRef(null);
    const [state, setState] = useState({
        data: null,
        templates: null
    });

    useEffect(() => {
        handleFetchDetailService();
    }, []);

    const handleFetchDetailService = () => {
        dispatch(getDetailServiceTemplate({ type: SETTING_TEMPLATE_TYPE.INVOICE }, getDetailTemplateSuccess));
    };
    const handleFetchListTemplate = () => {
        dispatch(getListServiceTemplate({ type: SETTING_TEMPLATE_TYPE.INVOICE }, getListTemplateSuccess));
    };

    const getDetailTemplateSuccess = (response) => {
        setState((prevState) => {
            return { ...prevState, data: response.data };
        });
        handleFetchListTemplate();
    };

    const getListTemplateSuccess = (response) => {
        const templates = response.data;

        setState((prevState) => {
            return { ...prevState, templates };
        });
    };

    const handleSubmit = (params) => {
        dispatch(
            createAndUpdateServiceTemplate(
                { ...params, type: SETTING_TEMPLATE_TYPE.INVOICE },
                createAndUpdateTemplateSuccess,
                () => {}
            )
        );
    };

    const createAndUpdateTemplateSuccess = (response) => {
        setState((prevState) => {
            return { ...prevState, data: response.data };
        });

        refNotificationSaveSuccess.current.open();
    };

    const handleCancel = () => {
        handleFetchDetailService();
    };

    const handleEdit = () => {};

    const handlePreview = (params) => {
        dispatch(previewServiceTemplate({ ...params, type: SETTING_TEMPLATE_TYPE.INVOICE }, previewSuccess, () => {}));
    };

    const previewSuccess = (response) => {
        const link = response?.data?.file || '';
        window.open(link, '_blank');
    };

    const renderTemplateContent = () => {
        if (!state?.data || !state.templates) return null;

        return (
            <SettingTemplateForm
                data={state.data}
                templates={state.templates}
                type={SETTING_TEMPLATE_TYPE.INVOICE}
                onSubmit={handleSubmit}
                onEdit={handleEdit}
                onPreview={handlePreview}
                onCancel={handleCancel}
            />
        );
    };

    return (
        <>
            <div className="contents-pages invoice">
                <div className="invoice__content">{renderTemplateContent()}</div>
            </div>
            <GdConfirm
                ref={refNotificationSaveSuccess}
                title={t('common:notification')}
                message={t('setting:invoice_template_was_update_successfully')}
                listButton={{ close: true }}
                onClose={() => {}}
            />
        </>
    );
};

export default Invoice;
