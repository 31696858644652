import 'app/modules/report/css/report.scss';
import React, { useEffect } from 'react';
import Commission from '..';

function CommissionLayout() {
    // Add class for page when mount ->> remove when unmount
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('report-page');
        return () => {
            mainPageDiv.classList.remove('report-page');
        };
    }, []);

    return (
        <div className="container-wrap container-table container-commision">
            <Commission />
        </div>
    );
}

export default CommissionLayout;
