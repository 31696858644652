import classNames from 'classnames';
import React from 'react';

const PreviewDocStatic = ({ url = '', wrapClassName = '', isFitHorizontal = false }) => {
    return (
        <iframe
            className={classNames(wrapClassName, 'border-none')}
            src={`${url}#${isFitHorizontal ? 'view=fitH' : ''}&toolbar=0`}
            type="application/pdf"
        />
    );
};

export default PreviewDocStatic;
