import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { ACCOUNT_ROLE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { LIST_STATUS } from 'app/const/Status';
import { JOB_STATUS_END_POINT } from 'app/const/api/V2';
import { CUSTOM_FIELDS_ACTIONS, CUSTOM_FIELDS_VIEWS } from 'app/modules/customfields/constant';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionUpdateJobStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListJobStatus } from 'common/utils/JobStatusUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderTable from '../../customFields/manage/HeaderTable';
import JobStatusManageDeleteTable from '../components/ManageDeleteGdGridView';
import { JOB_STATUS_CONFIRM_TITLE, JOB_STATUS_DELETE_ACTION, STATE_JOB_STATUS } from '../utils';
import GDModalWarning from 'app/components/modal/ModalWarning';

const JobStatusManageDelete = () => {
    const { t } = useTranslation(['addons']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        checkedItems: [],
        total: 0
    });
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const userRole = useSelector(({ auth }) => auth?.user?.profile?.role);
    const isSuperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const permissionUndelete = checkPermission(permissionList, PERMISSIONS.editCustomJobStatus) || isSuperAdmin;
    const { total, totalPage, isLoading, data, checkedItems } = state;
    const isCheckedAll = data.length > 0 && checkedItems.length === data.length;
    const refAlert = useRef(null);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);
    const refTypeDelete = useRef(JOB_STATUS_DELETE_ACTION.UNDELETE);
    const listJobStatus = getListJobStatus({ isIncludeDeleted: true });

    const reportType = REPORT_TYPE.CUSTOM_JOB_STATUS_MANAGE_DELETE;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    getLocalParamsReport(keyLocal, reportType);

    useEffect(() => {
        _getJobStatusDeleteList();
    }, []);

    const _getJobStatusDeleteList = () => {
        const params = getLocalStorage(keyLocal);

        clientQuery(
            JOB_STATUS_END_POINT,
            { method: 'GET', data: { ...params, state: STATE_JOB_STATUS.DELETED } },
            _handleGetSuccess,
            _handleFail
        );
    };

    const _handleUnDelete = () => {
        const checkedIds = checkedItems.map((item) => item.id);

        const _handleUndeleteSuccess = ({ message, error, data = [] }) => {
            const { message: messageItemError } = error || {};
            const removeIds = data || checkedIds;

            refAlert.current.clearAllStatusBar();
            if (!!error) {
                refAlert.current.handeAddStatus({ id: 'error', message: messageItemError, type: LIST_STATUS.ERROR });
            }

            if (message.length && data.length) {
                refAlert.current.handeAddStatus({ id: 'success', message, type: LIST_STATUS.SUCCESS });
            }

            _handleLocalParams(removeIds);
            dispatchState((prevState) => ({
                ...prevState,
                checkedItems: [],
                data: prevState.data.filter((item) => !removeIds.includes(item.id)),
                total: prevState.total - removeIds.length
            }));
            _handleUpdatedRetrieve(removeIds);
            if (removeIds.length) _getJobStatusDeleteList();
        };

        clientQuery(
            JOB_STATUS_END_POINT,
            {
                data: {
                    ids: checkedIds,
                    type: refTypeDelete.current
                },
                method: 'PUT',
                toFormData: false
            },
            _handleUndeleteSuccess,
            _handleFail,
            _handleStopLoadingConfirm
        );
    };

    const _handleUpdatedRetrieve = (ids = []) => {
        let newListJobStatus = [...listJobStatus];
        const idsRemove = [];
        data.forEach((item) => {
            if (ids.includes(item.id)) idsRemove.push(item.status_id);
        });

        if (refTypeDelete.current === JOB_STATUS_DELETE_ACTION.DELETE_PERMANENTLY) {
            newListJobStatus = newListJobStatus.filter(({ id }) => !idsRemove.includes(id));
        } else {
            newListJobStatus = newListJobStatus.map((item) => {
                if (!idsRemove.includes(item.id)) return item;
                return { ...item, deleted: false };
            });
        }

        dispatch(actionUpdateJobStatus(newListJobStatus));
    };

    const _handleGetSuccess = ({ data, total = {} }) => {
        const params = getLocalStorage(keyLocal);
        const { deleted } = total;

        dispatchState((prevState) => ({
            ...prevState,
            data,
            total: deleted,
            isLoading: false,
            totalPage: Math.ceil(deleted / params.limit),
            checkedItems: []
        }));
    };

    const _handleCheckAll = () =>
        dispatchState((prevState) => ({
            ...prevState,
            checkedItems: isCheckedAll ? [] : data
        }));

    const _hanldeCheckedItem = (item) => {
        const updatedCheckedItems = checkedItems.some((checkedItem) => checkedItem?.id === item?.id)
            ? checkedItems.filter((checkedItem) => checkedItem?.id !== item?.id)
            : [...checkedItems, item];

        dispatchState((prevState) => ({
            ...prevState,
            checkedItems: updatedCheckedItems
        }));
    };

    const _handleFail = (err) => {
        _handleDisplayAlert(err);
        dispatchState((prevState) => ({
            ...prevState,
            isLoading: false,
            checkedItems: []
        }));
    };

    const _handleDisplayAlert = ({ message, success = true }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('alert_create', message, success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
    };

    const _handleCloseConfirm = () => refConfirm.current._close();

    const _handleStopLoadingConfirm = () => {
        refButtonSave.current?.removeLoading();
        _handleCloseConfirm();
    };

    const _handleUpdatePagination = () => {
        if (!isLoading) dispatchState((prevState) => ({ ...prevState, isLoading: true }));
        _getJobStatusDeleteList();
    };

    const _handleTypeDelete = (action) => {
        const isUndelete = action === CUSTOM_FIELDS_ACTIONS.UN_DELETE;
        refTypeDelete.current = isUndelete
            ? JOB_STATUS_DELETE_ACTION.UNDELETE
            : JOB_STATUS_DELETE_ACTION.DELETE_PERMANENTLY;
        const type = isUndelete ? JOB_STATUS_CONFIRM_TITLE.UNDELETE : JOB_STATUS_CONFIRM_TITLE.DELETE_PERMANENTLY;
        const title = t('title_confirm_job_status', { type: t(type) });
        const message = t('message_confirm_custom_field', { type: t(type) });
        refConfirm.current?._open({ title, description: message });
    };

    const _handleLocalParams = (deletedItem) => {
        const newParams = { ...(getLocalStorage(keyLocal) || {}) };
        const { currentPage, offset, limit } = newParams;
        if (limit >= total) return;
        if (deletedItem.length === data.length && offset + limit >= total) {
            newParams['currentPage'] = currentPage - 1;
            newParams.offset = offset - limit;
        }
        setLocalStorage(keyLocal, newParams);
    };

    return (
        <div className="tabs-job container-print container-wrap">
            <GDStatusBar ref={refAlert} />
            <div className="wrap-tables flex-column relative">
                <HeaderTable
                    type={CUSTOM_FIELDS_VIEWS.DELETED}
                    ids={checkedItems}
                    onActions={_handleTypeDelete}
                    onUncheckAll={_handleCheckAll}
                    total={total}
                    isShowArchive={false}
                    isLoading={isLoading}
                />
                <JobStatusManageDeleteTable
                    jobStatusList={data}
                    loading={isLoading}
                    onCheckedItems={_hanldeCheckedItem}
                    checkedItems={checkedItems}
                    isAllowUndelete={permissionUndelete}
                />
                <ReportPagination
                    reportType={REPORT_TYPE.CUSTOM_JOB_STATUS_MANAGE_DELETE}
                    totalPage={totalPage || 1}
                    onSelect={_handleUpdatePagination}
                />
            </div>
            <GDModalWarning
                ref={refConfirm}
                isLargeContent={false}
                footer={
                    <div className="footer-modal btn-close justify-end">
                        <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                            {t('cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('yes')}
                            wrapClass="v2-btn-main ml-2"
                            onSave={_handleUnDelete}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default JobStatusManageDelete;
