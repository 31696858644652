import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken, deleteWithToken } from 'common/utils/ApiUtils';
import { SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, SETTINGS_EMAIL_CREATE, SETTINGS_EMAIL_UPDATE } from 'app/const/Api';
import { EMAIL_TEMPLATE } from 'common/redux/actions/email/template';

export function* getListEmailInbox({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusSettingEmailInbox({
    params,
    actionSuccess = () => {},
    actionFailed = () => {},
    actionFinally = () => {}
}) {
    try {
        const response = yield call(deleteWithToken, SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({ type: EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_SUCCESS });
        } else {
            actionFailed(response);
        }
        actionFinally();
    } catch (error) {
        actionFailed(error);
    }
}

export function* getEmailInboxDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createSettingEmailInbox({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_EMAIL_CREATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateSettingEmailInbox({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_EMAIL_UPDATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
