import {
    MenuSidebarBottomAddonIcon,
    MenuSidebarBottomSettingIcon,
    MenuSidebarClockInOutIcon,
    MenuSidebarTopCalendarIcon,
    MenuSidebarTopCommissionIcon,
    MenuSidebarTopCustomerIcon,
    MenuSidebarTopDashboardIcon,
    MenuSidebarTopExportIcon,
    MenuSidebarTopInboxIcon,
    MenuSidebarTopLeadIcon,
    MenuSidebarTopReviewIcon
} from 'app/const/IconSvg';

import {
    ADD_ONS,
    CALENDAR,
    COMMISSION,
    CUSTOMERS,
    DRIP_CAMPAIGN,
    GORILLA_DESK_ACADEMY_LINK,
    GORILLA_DESK_COACHING_CALL_LINK,
    GORILLA_DESK_GETTING_STARTED_LINK,
    GORILLA_DESK_GROUP_FACEBOOK_LINK,
    GORILLA_DESK_INTERCOM_HELP_LINK,
    GORILLA_DESK_TRAINING_LINK,
    GORILLA_DESK_TYPEFORM_LINK,
    GORILLA_DESK_YOUTUBE_LINK,
    INBOX,
    REPORT_INVOICES,
    REPORT_RATING,
    SETTINGS_ACCOUNT,
    SETTINGS_COMPANY,
    SETTINGS_PLANS,
    REPORT,
    SETTINGS,
    LEADS,
    DASHBOARD
} from 'app/const/Route';
import IconUser from 'assets/icon/IconUser';
import IconCompany from 'assets/icon/IconCompany';
import IconInvoice from 'assets/icon/IconInvoice';
import IconAccount from 'assets/icon/IconAccount';
import IconCalendar from 'assets/icon/IconCalendar';
import IconTax from 'assets/icon/IconTax';
import IconAmount from 'assets/icon/IconAmount';
import IconPayment from 'assets/icon/IconPayment';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import IconSource from 'assets/icon/IconSource';
import IconTag from 'assets/icon/IconTag';
import IconTile from 'assets/icon/IconTile';
import IconDocument from 'assets/icon/IconDocument';
import IconBell from 'assets/icon/IconBell';
import IconCustom from 'assets/icon/IconCustom';
import IconBroadcast from 'assets/icon/IconBroadcast';
import IconEmailInbox from 'assets/icon/IconEmailInbox';
import IconCommission from 'assets/icon/IconCommission';
import IconAddonDocument from 'assets/icon/IconAddonDocument';
import IconAddonGps from 'assets/icon/IconAddonGps';
import IconAddonMaterial from 'assets/icon/IconAddonMaterial';
import IconBuilding from 'assets/icon/IconBuilding';
import IconDeviceTracking from 'assets/icon/IconDeviceTracking';
import IconOnlineBooking from 'assets/icon/IconOnlineBooking';
import IconOptimize from 'assets/icon/IconOptimize';
import IconPortal from 'assets/icon/IconPortal';
import IconAddonApi from 'assets/icon/IconAddonApi';
import IconQuickBook from 'assets/icon/IconQuickBook';
import IconSMS from 'assets/icon/IconSMS';
import IconSquare from 'assets/icon/IconSquare';
import IconStar from 'assets/icon/IconStar';
import IconStripe from 'assets/icon/IconStripe';
import IconTrigger from 'assets/icon/IconTrigger';
import IconZapier from 'assets/icon/IconZapier';
import IconProfile from 'assets/icon/IconProfile';
import IconUpgrade from 'assets/icon/IconUpgrade';
import IconRequest from 'assets/icon/IconRequest';
import IconLogout from 'assets/icon/IconLogout';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';
import IconRocket from 'assets/icon/IconRocket';
import IconTraining from 'assets/icon/IconTraining';
import IconBook from 'assets/icon/IconBook';
import IconYoutube from 'assets/icon/IconYoutube';
import IconFaceBook from 'assets/icon/IconFaceBook';
import IconPhone from 'assets/icon/IconPhone';
import IconMenuReport from 'assets/icon/IconMenuReport';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconMenuAddons from 'assets/icon/IconMenuAddons';
import IconMenuDrip from 'assets/icon/IconMenuDrip';
import IconEstimate from 'assets/icon/IconEstimate';
import IconPen from 'assets/icon/IconPen';
import IconNote from 'assets/icon/IconNote';
import IconDone from 'assets/icon/IconDone';
import IconTimeWindow from 'assets/icon/IconTimeWindow';
import IconMenuLead from 'assets/icon/IconMenuLead';
import SyncWithGGAction from 'app/modules/layouts/components/SyncWithGGAction';
import { ID_QUEST_LOG, ID_SWITCH_VERSION } from './Header';
import { REPORT_TRACKER_TAB, REPORT_TYPE, REVIEW_LIKELY_TAB, REVIEW_SERVICE_TAB } from './report/ReportTypeContent';
import {
    ACCOUNT_MAIN_PATH,
    ACCOUNT_PLANS_DOWNGRADE,
    ACCOUNT_PLANS_THANKS,
    ACCOUNT_PLANS_UPGRADE,
    ADDONS_CUSTOM_FIELDS,
    ADDONS_MAIN_JOB_STATUS,
    REFERRAL,
    ADDONS_ESTIMATE,
    REPORT_LEADS,
    REPORT_NEW_JOB,
    REPORT_TRACKER,
    SETTINGS_TEMPLATES_SMS,
    TRACKER,
    SETTINGS_TEMPLATES_ESTIMATE,
    PROFILE_AUTHENTICATION
} from 'app/config/routes';
import { SETTINGS_TEMPLATES_NOTES, SETTINGS_TEMPLATES_TODO } from 'app/config/routes';
import IconSwitch from 'assets/icon/IconSwitch';
import IconDashboard from 'assets/icon/IconDashboard';
import IconOpps from 'assets/icon/IconOpps';
import IconMultiBranch from 'assets/icon/IconMultiBranch';
import IconClockInOut from 'assets/icon/IconClockInOut';
import IconACH from 'assets/icon/IconACH';
import IconSmartView from 'assets/icon/IconSmartView';
import i18n from 'assets/i18n';
import IconCustomFields from 'assets/icon/IconCustomFields';
import IconJobStatus from 'assets/icon/IconJobStatus';
import IconCall from 'assets/icon/IconCall';
import { NOTIFICATION_TYPES } from 'app/modules/notifications/constants';
import IconChatbot from 'assets/icon/IconChatbot';
import IconRoute from 'assets/icon/IconRoute';

export const sidebarsTop = [
    {
        id: 'calendar',
        to: '/calendar',
        classId: 'menu-calendar',
        tooltip: true,
        icon: MenuSidebarTopCalendarIcon()
    },
    {
        id: 'work_log',
        to: TRACKER,
        classId: 'menu-tracker',
        tooltip: true,
        icon: <MenuSidebarClockInOutIcon />
    },
    {
        id: 'customers',
        to: '/customers',
        classId: 'menu-customers',
        tooltip: true,
        icon: MenuSidebarTopCustomerIcon()
    },
    {
        id: 'dashboard',
        to: '/dashboard',
        classId: 'menu-dashboard',
        tooltip: true,
        icon: MenuSidebarTopDashboardIcon()
    },
    {
        id: 'inbox',
        to: '/inbox',
        classId: 'menu-inbox',
        tooltip: true,
        icon: MenuSidebarTopInboxIcon(),
        permission: []
    },
    {
        id: 'leads',
        to: '/reports/leads',
        classId: 'menu-leads',
        tooltip: true,
        icon: MenuSidebarTopLeadIcon()
    },
    {
        id: 'reports',
        to: '/reports/invoices',
        classId: 'menu-reports',
        tooltip: true,
        icon: MenuSidebarTopExportIcon()
    },
    {
        id: 'reviews',
        to: '/reviews',
        classId: 'menu-reviews',
        tooltip: true,
        icon: MenuSidebarTopReviewIcon()
    },
    {
        id: 'commissions',
        to: '/commissions',
        classId: 'menu-commission',
        tooltip: true,
        icon: MenuSidebarTopCommissionIcon()
    }
];

export const sidebarsBottom = [
    {
        id: 'settings',
        to: '/settings/company',
        classId: 'menu-settings',
        tooltip: true,
        icon: MenuSidebarBottomSettingIcon(),
        permission: []
    },
    {
        id: 'addons',
        to: '/addons',
        classId: 'menu-addons',
        tooltip: true.valueOf,
        icon: MenuSidebarBottomAddonIcon(),
        permission: []
    }
];

export const TYPE_LINK = {
    INTERNAL: 'internal',
    EXTERNAL: 'external',
    ACTION: 'action',
    COMPONENT: 'component'
};

export const profileOptions = [
    {
        id: 'sync_google',
        type: TYPE_LINK.COMPONENT,
        isCheckPermision: false,
        component: <SyncWithGGAction />
    },
    {
        id: 'my-account',
        type: TYPE_LINK.INTERNAL,
        path: SETTINGS_ACCOUNT,
        icon: <IconProfile />,
        isCheckPermision: true
    },
    {
        id: 'my-profile',
        type: TYPE_LINK.INTERNAL,
        path: PROFILE_AUTHENTICATION,
        icon: <IconProfile isProfileDropdown />,
        isCheckPermision: false
    },
    {
        id: 'upgrade-plan',
        type: TYPE_LINK.INTERNAL,
        path: SETTINGS_PLANS,
        icon: <IconUpgrade />,
        isCheckPermision: true
    },
    {
        id: 'line-1',
        type: 'line',
        isCheckPermision: true
    },
    {
        id: 'log-out',
        type: TYPE_LINK.ACTION,
        icon: <IconLogout />
    }
];

export const helpOptions = [
    {
        id: 'referral_program',
        link: REFERRAL,
        icon: <IconStar isReferral />,
        isNewTab: false,
        isHideTrial: true
    },
    {
        id: 'acdemy',
        link: GORILLA_DESK_ACADEMY_LINK,
        icon: <IconBachelorsHat isNormal />
    },
    {
        id: 'getting-started',
        link: GORILLA_DESK_GETTING_STARTED_LINK,
        icon: <IconRocket />
    },
    {
        id: 'training',
        link: GORILLA_DESK_TRAINING_LINK,
        icon: <IconTraining />
    },
    {
        id: 'knowledge_base',
        link: GORILLA_DESK_INTERCOM_HELP_LINK,
        icon: <IconBook />
    },
    {
        id: 'line-2',
        type: 'line'
    },
    {
        id: 'youTube',
        link: GORILLA_DESK_YOUTUBE_LINK,
        icon: <IconYoutube />
    },
    {
        id: 'facebook',
        link: GORILLA_DESK_GROUP_FACEBOOK_LINK,
        icon: <IconFaceBook isNormal />
    },
    {
        id: 'line-3',
        type: 'line'
    },
    {
        id: 'request-a-feature',
        link: GORILLA_DESK_TYPEFORM_LINK,
        icon: <IconRequest />
    },
    {
        id: 'chat_with_support',
        link: '#',
        icon: <IconSMS />
    },
    {
        id: 'book-s-sales-call',
        link: GORILLA_DESK_COACHING_CALL_LINK,
        icon: <IconPhone />
    },
    {
        id: 'line-1',
        type: 'line'
    },
    {
        id: ID_QUEST_LOG,
        icon: <IconRocket isNormal />
    },
    {
        id: 'line-1-hide-trial',
        type: 'line',
        isHideTrial: true
    },
    {
        id: ID_SWITCH_VERSION,
        icon: <IconSwitch isToggle />,
        isHideTrial: true
    }
];

// List all Add-ons
export const listAllAddOns = [
    {
        id: 'square',
        title: 'Square',
        icon: <IconSquare hasColor />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'stripe',
        title: 'Stripe',
        icon: <IconStripe hasColor />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'commission_tracking',
        title: 'commission_tracking',
        icon: <IconCommission />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false,
        url: '/commissions'
    },
    {
        id: 'custom_fields',
        title: i18n.t('custom_fields'),
        icon: <IconCustomFields type="addons" />,
        url: ADDONS_CUSTOM_FIELDS,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'custom_job_status',
        title: 'custom_job_status_title',
        icon: <IconJobStatus />,
        url: ADDONS_MAIN_JOB_STATUS,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'customer_portal',
        title: 'customer_portal',
        icon: <IconPortal isActive />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'device_tracking',
        title: 'device_tracking',
        icon: <IconDeviceTracking isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'documents',
        title: 'documents',
        icon: <IconAddonDocument isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'estimates',
        title: i18n.t('addons:dynamic_estimates'),
        icon: <IconEstimate isAddons />,
        url: ADDONS_ESTIMATE,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false,
        isBeta: true,
        isCheckWhiteList: true
    },
    {
        id: 'inbox_email',
        title: 'inbox',
        icon: <IconEmailInbox isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'gps_tracking',
        title: 'gps_tracking',
        icon: <IconAddonGps isGreen />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'material',
        title: 'material_usage',
        icon: <IconAddonMaterial isGreen />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'mdu_tracking',
        title: 'mdu_tracking',
        icon: <IconBuilding isAddonsGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'online_booking',
        title: 'online_booking',
        icon: <IconOnlineBooking isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'opportunity',
        title: 'Opportunity Pipeline',
        icon: <IconOpps />,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'quickbooks',
        title: 'quickbooks',
        icon: <IconQuickBook hasColor />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'review_engine',
        title: 'review_engine',
        icon: <IconStar isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'routing',
        title: 'route_optimizer',
        icon: <IconOptimize isGreen />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'smart_views',
        title: 'Smart Views',
        icon: <IconSmartView isGreen />,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'sms',
        title: 'SMS Text Messaging',
        icon: <IconSMS isGreen />,
        isBadge: false,
        badgeTitle: '',
        isActive: false
    },
    {
        id: 'work_log',
        title: 'Time Clocking',
        icon: <IconClockInOut type="addons" />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false,
        isBeta: true
    },
    {
        id: 'time_window',
        title: 'time_window',
        icon: <IconTimeWindow isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'triggers',
        title: 'Triggers',
        icon: <IconTrigger isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'voip',
        title: 'voip_by_gorilladesk',
        icon: <IconPhone isSmallGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'api_key',
        title: 'api',
        icon: <IconAddonApi isGreen />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'zapier',
        title: 'Zapier',
        icon: <IconZapier />,
        isBadge: true,
        badgeTitle: 'PRO',
        isActive: false
    },
    {
        id: 'multiple_branch',
        title: 'multiple_branch',
        icon: <IconMultiBranch isGreen />,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'ai_agents',
        title: 'ai_agents',
        icon: <IconChatbot />,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    },
    {
        id: 'advance_routing',
        title: 'advance_routing',
        icon: <IconRoute type="active" />,
        isGrowthBadge: true,
        badgeTitle: 'Growth',
        isActive: false
    }
];

// list feature menu dropdown (top)
export const MAIN_FEATURES_MENU_HEADER = [
    {
        id: 'calendar',
        icon: <IconCalendar isMenu />,
        iconActive: <IconCalendar isMenuActive />,
        title: 'calendar',
        url: CALENDAR,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: CALENDAR,
        isShow: true
    },
    {
        id: 'work_log',
        icon: <IconClockInOut />,
        iconActive: <IconClockInOut type="active" />,
        title: 'Time Clocking',
        url: TRACKER,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: TRACKER,
        isShow: true
    },
    {
        id: 'customers',
        icon: <IconUser isMenu />,
        iconActive: <IconUser isMenuActive />,
        title: 'customers',
        url: CUSTOMERS,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: CUSTOMERS,
        isShow: true
    },
    {
        id: 'dashboard',
        icon: <IconDashboard />,
        iconActive: <IconDashboard isMenuActive />,
        title: 'dashboard',
        url: DASHBOARD,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: true,
        path: DASHBOARD,
        isShow: true
    },
    {
        id: 'inbox',
        icon: <IconEmailInbox hasColor />,
        iconActive: <IconEmailInbox isMenuActive />,
        title: 'inbox',
        url: INBOX,
        isBadge: true,
        badgeTitle: 'Pro',
        isRequiredPermission: false,
        path: INBOX,
        isShow: true
    },
    {
        id: 'leads',
        icon: <IconMenuLead />,
        iconActive: <IconMenuLead isMenuActive />,
        title: 'Leads',
        url: REPORT_LEADS,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: REPORT_LEADS,
        isShow: true
    },
    {
        id: 'reports',
        icon: <IconMenuReport />,
        iconActive: <IconMenuReport isMenuActive />,
        title: 'reports',
        url: REPORT_INVOICES,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: REPORT,
        isShow: true
    },
    {
        id: 'reviews',
        icon: <IconStar isAddons />,
        iconActive: <IconStar isMenuActive />,
        title: 'Reviews',
        url: REPORT_RATING,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: REPORT_RATING,
        isShow: true
    },
    {
        id: 'commissions',
        icon: <IconCommission />,
        iconActive: <IconCommission isMenuActive />,
        title: 'Commissions',
        url: COMMISSION,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: COMMISSION,
        isShow: true
    },
    { id: 'line-1', type: 'line' },
    {
        id: 'settings',
        icon: <IconCogWheel isMenu />,
        iconActive: <IconCogWheel isMenuActive />,
        title: 'settings',
        url: SETTINGS_COMPANY,
        url_account: SETTINGS_ACCOUNT,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: true,
        path: SETTINGS,
        isSetting: true,
        isShow: true
    },
    {
        id: 'addons',
        icon: <IconMenuAddons />,
        iconActive: <IconMenuAddons isMenuActive />,
        title: 'addons',
        url: ADD_ONS,
        isBadge: false,
        badgeTitle: '',
        isRequiredPermission: false,
        path: ADD_ONS,
        isShow: true
    }
];

export const listActivePath = [
    {
        iconActive: <IconMenuLead isMenuActive />,
        titleActive: 'Leads',
        url: LEADS
    },
    {
        iconActive: <IconCalendar isMenuActive />,
        titleActive: 'calendar',
        url: CALENDAR
    },
    {
        iconActive: <IconClockInOut type="active" />,
        titleActive: 'Time Clocking',
        url: TRACKER
    },
    {
        iconActive: <IconUser isMenuActive />,
        titleActive: 'customers',
        url: CUSTOMERS
    },
    {
        iconActive: <IconEmailInbox isMenuActive />,
        titleActive: 'inbox',
        url: INBOX
    },
    {
        iconActive: <IconMenuReport isMenuActive />,
        titleActive: 'reports',
        url: REPORT
    },
    {
        iconActive: <IconMenuAddons isMenuActive />,
        titleActive: 'addons',
        url: ADD_ONS
    },
    {
        iconActive: <IconCogWheel isMenuActive />,
        titleActive: 'settings',
        url: SETTINGS
    },
    {
        iconActive: <IconCogWheel isMenuActive />,
        titleActive: 'settings',
        url: ACCOUNT_MAIN_PATH
    },
    {
        iconActive: <IconMenuDrip isMenuActive />,
        titleActive: 'drip',
        url: DRIP_CAMPAIGN
    },
    {
        iconActive: <IconStar isMenuActive />,
        titleActive: 'reviews',
        url: REPORT_RATING
    },
    {
        iconActive: <IconCommission isMenuActive />,
        titleActive: 'commissions',
        url: COMMISSION
    },
    {
        iconActive: <IconDashboard isMenuActive />,
        titleActive: 'dashboard',
        url: DASHBOARD
    },
    {
        iconActive: <IconStar isReferral />,
        titleActive: 'referral',
        url: REFERRAL
    },
    {
        iconActive: <IconCogWheel isMenuActive />,
        titleActive: 'profile_authentication',
        url: PROFILE_AUTHENTICATION
    }
];

export const REPORT_MENU_OPTIONS = [
    { id: 1, value: 'invoices', link: '/reports/invoices' },
    { id: 2, value: 'estimates', link: '/reports/estimates' },
    { id: 3, value: 'documents', link: '/reports/documents' },
    { id: 4, value: 'payments', link: '/reports/payments' },
    { id: 5, value: 'credits', link: '/reports/credits' },
    { id: 6, value: null, link: null },
    { id: 7, value: 'recurring_invoices', link: '/reports/recurringinvoices' },
    { id: 8, value: 'stripe_subscriptions', link: '/reports/subscriptions' },
    { id: 9, value: 'square_subscriptions', link: '/reports/squareSubscriptions' },
    { id: 10, value: null, link: null },
    { id: 11, value: 'service-lookup', link: '/reports/servicelookup' },
    { id: 38, value: 'new_jobs', link: REPORT_NEW_JOB },
    { id: 12, value: 'new_customers', link: '/reports/newcustomers' },
    {
        id: 13,
        value: 'customers_wo_active_jobs',
        link: '/reports/customerswoactivejobs'
    },
    {
        id: 14,
        value: 'locations_wo_active_jobs',
        link: '/reports/locationswoactivejobs'
    },
    { id: 15, value: null, link: null },
    { id: 16, value: 'accounts-aging', link: '/reports/accountsaging' },
    { id: 17, value: 'payments-collected', link: '/reports/paymentscollected' },
    { id: 18, value: 'total_sales', link: '/reports/totalsales' },
    { id: 19, value: 'sales_forecast', link: '/reports/salesforecast' },
    { id: 20, value: 'sales-tax-summary', link: '/reports/salestaxsummary' },
    { id: 21, value: null, link: null },
    { id: 22, value: 'revenue_by_client', link: '/reports/revenue/client' },
    { id: 23, value: 'revenue_by_service', link: '/reports/revenue/service' },
    { id: 24, value: 'revenue_by_item', link: '/reports/revenue/item' },
    { id: 25, value: 'revenue_by_staff', link: '/reports/revenue/staff' },
    { id: 26, value: 'revenue_by_source', link: '/reports/revenue/source' },
    { id: 27, value: 'revenue_by_tags', link: '/reports/revenue/tags' },
    { id: 28, value: null, link: null },
    { id: 29, value: 'material_use', link: '/reports/materialuse' },
    { id: 30, value: 'email_history', link: '/reports/emailhistory' },
    { id: 31, value: null, link: null },
    {
        id: 32,
        value: 'likely_rating',
        link: '/reviews/likely',
        tab: REVIEW_LIKELY_TAB
    },
    {
        id: 33,
        value: 'service_rating',
        link: '/reviews/service',
        tab: REVIEW_SERVICE_TAB,
        subsLink: ['/reviews/service', '/reviews']
    },
    {
        id: 34,
        value: 'inbound_leads',
        link: '/reports/leads/inbound',
        tab: REPORT_TYPE.LEADS.inbound_leads,
        subsLink: ['/reports/leads/inbound', '/reports/leads']
    },
    { id: 35, value: 'online_bookings', link: '/reports/leads/booking', tab: REPORT_TYPE.LEADS.online_bookings },
    { id: 36, value: null, link: null },
    { id: 37, value: 'time_tracking', link: REPORT_TRACKER, tab: REPORT_TRACKER_TAB }
];

export const REPORT_DROPDOWN_LEFT_OPTIONS = [
    { id: 1, value: 'invoices', link: '/reports/invoices' },
    { id: 2, value: 'estimates', link: '/reports/estimates' },
    { id: 3, value: 'documents', link: '/reports/documents' },
    { id: 4, value: 'payments', link: '/reports/payments' },
    { id: 5, value: 'credits', link: '/reports/credits' },
    { id: 6, value: null, link: null },
    { id: 7, value: 'recurring_invoices', link: '/reports/recurringinvoices' },
    { id: 8, value: 'stripe_subscriptions', link: '/reports/subscriptions' },
    { id: 9, value: 'square_subscriptions', link: '/reports/squareSubscriptions' },
    { id: 10, value: null, link: null },
    { id: 11, value: 'service-lookup', link: '/reports/servicelookup' },
    { id: 21, value: 'new_jobs', link: REPORT_NEW_JOB },
    { id: 12, value: 'new_customers', link: '/reports/newcustomers' },
    {
        id: 13,
        value: 'customers_wo_active_jobs',
        link: '/reports/customerswoactivejobs'
    },
    {
        id: 14,
        value: 'locations_wo_active_jobs',
        link: '/reports/locationswoactivejobs'
    },
    { id: 15, value: null, link: null },
    { id: 16, value: 'accounts-aging', link: '/reports/accountsaging' },
    { id: 17, value: 'payments-collected', link: '/reports/paymentscollected' },
    { id: 18, value: 'total_sales', link: '/reports/totalsales' },
    { id: 19, value: 'sales_forecast', link: '/reports/salesforecast' },
    { id: 20, value: 'sales-tax-summary', link: '/reports/salestaxsummary' }
];

export const REPORT_DROPDOWN_RIGHT_OPTIONS = [
    { id: 1, value: 'revenue_by_client', link: '/reports/revenue/client' },
    { id: 2, value: 'revenue_by_service', link: '/reports/revenue/service' },
    { id: 3, value: 'revenue_by_item', link: '/reports/revenue/item' },
    { id: 4, value: 'revenue_by_staff', link: '/reports/revenue/staff' },
    { id: 5, value: 'revenue_by_source', link: '/reports/revenue/source' },
    { id: 6, value: 'revenue_by_tags', link: '/reports/revenue/tags' },
    { id: 7, value: null, link: null },
    { id: 8, value: 'material_use', link: '/reports/materialuse' },
    { id: 9, value: 'email_history', link: '/reports/emailhistory' },
    { id: 10, value: null, link: null },
    {
        id: 11,
        value: 'likely_rating',
        link: '/reviews/likely',
        tab: REVIEW_LIKELY_TAB
    },
    {
        id: 12,
        value: 'service_rating',
        link: '/reviews/service',
        tab: REVIEW_SERVICE_TAB,
        subsLink: ['/reviews/service', '/reviews']
    },
    {
        id: 13,
        value: 'inbound_leads',
        link: '/reports/leads/inbound',
        tab: REPORT_TYPE.LEADS.inbound_leads,
        subsLink: ['/reports/leads/inbound', '/reports/leads']
    },
    { id: 14, value: 'online_bookings', link: '/reports/leads/booking', tab: REPORT_TYPE.LEADS.online_bookings },
    { id: 15, value: null, link: null },
    { id: 16, value: 'time_tracking', link: REPORT_TRACKER, tab: REPORT_TYPE.WORK_LOG }
];

export const SETTING_MENU_OPTIONS = [
    { id: 1, value: 'your_account', link: null, style: 'sub-title', isPermissionAccount: true },
    {
        id: 2,
        value: 'account',
        link: '/settings/account',
        class: 'items svg-account svg-noeffect',
        icon: <IconAccount />,
        isPermissionAccount: true
    },
    {
        id: 3,
        value: 'plans',
        link: '/settings/plans',
        subsLink: [SETTINGS_PLANS, ACCOUNT_PLANS_THANKS, ACCOUNT_PLANS_UPGRADE, ACCOUNT_PLANS_DOWNGRADE],
        class: 'items svg-plans svg-noeffect',
        icon: <IconStar isChecked />,
        isPermissionAccount: true
    },
    {
        id: 4,
        value: 'invoices',
        link: '/settings/invoices',
        subsLink: ['/settings/invoices', '/settings/invoice/:id'],
        class: 'items',
        icon: <IconInvoice />,
        isPermissionAccount: true
    },
    { id: 5, value: 'system_settings', link: null, style: 'sub-title pt-4' },
    {
        id: 6,
        value: 'company',
        link: '/settings/company',
        class: 'items',
        icon: <IconCompany />
    },
    {
        id: 7,
        value: 'users',
        link: '/settings/users',
        class: 'items',
        icon: <IconUser />
    },
    {
        id: 8,
        value: 'schedules',
        link: '/settings/schedules',
        class: 'items svg-schedule',
        icon: <IconCalendar isHasDollar />
    },
    {
        id: 9,
        value: 'taxes',
        link: '/settings/taxes',
        class: 'items',
        icon: <IconTax />
    },
    {
        id: 10,
        value: 'line_items',
        link: '/settings/items',
        class: 'items svg-lines svg-noeffect',
        icon: <IconAmount />
    },
    {
        id: 11,
        value: 'paperwork_templates',
        link: '/settings/paperwork',
        class: 'items',
        icon: <IconDocument />
    },
    {
        id: 12,
        value: 'payment_methods',
        link: '/settings/paymentmethods',
        class: 'items svg-credit svg-noeffect',
        icon: <IconPayment isHasColor />
    },
    {
        id: 13,
        value: 'service_templates',
        link: '/settings/service',
        subsLink: ['/settings/service', '/settings/service/edit/:id', '/settings/service/add'],
        class: 'items svg-service svg-noeffect',
        icon: <IconServiceTemplate />
    },
    {
        id: 14,
        value: 'sources',
        link: '/settings/sources',
        class: 'items svg-sources-default',
        icon: <IconSource />
    },
    {
        id: 15,
        value: 'tags',
        link: '/settings/tags',
        class: 'items',
        icon: <IconTag />
    },
    {
        id: 16,
        value: 'tiles',
        link: '/settings/tiles',
        class: 'items svg-tiles svg-noeffect',
        icon: <IconTile />
    },
    {
        id: 22,
        value: 'notes_and_todo_list',
        link: '/settings/templates',
        subsLink: [
            SETTINGS_TEMPLATES_TODO,
            SETTINGS_TEMPLATES_NOTES,
            SETTINGS_TEMPLATES_SMS,
            SETTINGS_TEMPLATES_ESTIMATE
        ],
        class: 'items svg-label svg-noeffect',
        icon: <IconPen isHasColor />,
        isPermissionTemplate: true
    },
    {
        id: 17,
        value: 'sms_and_email_templates',
        link: null,
        style: 'sub-title pt-4'
    },
    {
        id: 18,
        value: 'systems',
        link: '/settings/emails',
        class: 'items',
        icon: <IconBell isHasColor />
    },
    {
        id: 19,
        value: 'customs',
        link: '/settings/customs',
        class: 'items svg-tempt svg-noeffect',
        icon: <IconCustom />
    },
    {
        id: 20,
        value: 'broadcast',
        link: '/settings/broadcast',
        class: 'items svg-broad svg-noeffect',
        icon: <IconBroadcast />
    },
    {
        id: 21,
        value: 'email_inbox',
        link: '/settings/emailinbox',
        class: 'items svg-inbox',
        icon: <IconEmailInbox />
    }
];

export const SETTING_DROPDOWN_LEFT_OPTIONS = [
    {
        id: 1,
        value: 'system_settings',
        link: null,
        type: 'title'
    },
    {
        id: 2,
        value: 'company',
        link: '/settings/company',
        icon: <IconCompany />
    },
    {
        id: 3,
        value: 'users',
        link: '/settings/users',
        icon: <IconUser />
    },
    {
        id: 4,
        value: 'schedules',
        link: '/settings/schedules',
        icon: <IconCalendar isHasDollar />,
        iconStyle: 'svg-schedule'
    },
    {
        id: 5,
        value: 'taxes',
        link: '/settings/taxes',
        icon: <IconTax />
    },
    {
        id: 6,
        value: 'line_items',
        link: '/settings/items',
        icon: <IconAmount />,
        iconStyle: 'svg-lines'
    },
    {
        id: 7,
        value: 'paperwork_templates',
        link: '/settings/paperwork',
        icon: <IconDocument />
    },
    {
        id: 8,
        value: 'payment_methods',
        link: '/settings/paymentmethods',
        icon: <IconPayment isHasColor />,
        iconStyle: 'svg-credit'
    },
    {
        id: 9,
        value: 'service_templates',
        link: '/settings/service',
        icon: <IconServiceTemplate />,
        iconStyle: 'svg-service svg-noeffect'
    },
    {
        id: 10,
        value: 'sources',
        link: '/settings/sources',
        icon: <IconSource />,
        iconStyle: 'svg-sources-default'
    },
    {
        id: 11,
        value: 'tags',
        link: '/settings/tags',
        icon: <IconTag />
    },
    {
        id: 12,
        value: 'tiles',
        link: '/settings/tiles',
        icon: <IconTile />
    },
    {
        id: 13,
        value: 'notes_and_todo_list',
        link: '/settings/templates',
        subsLink: [SETTINGS_TEMPLATES_TODO, SETTINGS_TEMPLATES_NOTES, SETTINGS_TEMPLATES_SMS],
        icon: <IconPen isHasColor />,
        iconStyle: 'svg-label svg-noeffect'
    }
];

export const SETTING_DROPDOWN_RIGHT_OPTIONS = [
    {
        id: 0,
        value: 'sms_and_email_templates',
        link: null,
        type: 'title'
    },
    {
        id: 1,
        value: 'systems',
        link: '/settings/emails',
        icon: <IconBell isHasColor />,
        iconStyle: 'svg-bell'
    },
    {
        id: 2,
        value: 'customs',
        link: '/settings/customs',
        icon: <IconCustom />,
        iconStyle: 'svg-tempt svg-noeffect'
    },
    {
        id: 3,
        value: 'broadcast',
        link: '/settings/broadcast',
        icon: <IconBroadcast />,
        iconStyle: 'svg-broad svg-noeffect'
    },
    {
        id: 4,
        value: 'email_inbox',
        link: '/settings/emailinbox',
        icon: <IconEmailInbox />,
        iconStyle: 'svg-inbox'
    },
    { id: 5, value: null, link: null, isHideOnHeader: true },
    {
        id: 6,
        value: 'your_account',
        link: null,
        type: 'title',
        isHideOnHeader: true
    },
    {
        id: 7,
        value: 'account',
        link: '/settings/account',
        icon: <IconAccount />,
        iconStyle: 'svg-account',
        isHideOnHeader: true
    },
    {
        id: 8,
        value: 'plans',
        link: '/settings/plans',
        icon: <IconStar isChecked />,
        iconStyle: 'svg-plans',
        isHideOnHeader: true
    },
    {
        id: 9,
        value: 'invoices',
        link: '/settings/invoices',
        icon: <IconInvoice />,
        isHideOnHeader: true
    }
];

export const CUSTOMER_DETAIL_DROPDOWN_MENU = [
    {
        id: 'timeline',
        value: 'timeline',
        link: '/customers/:id'
    },
    {
        id: 'account',
        value: 'account',
        link: '/customers/account/:id'
    },
    {
        id: 'customer_portal',
        value: 'customer_portal',
        link: '/customers/portal/:id'
    },
    {
        id: 'contacts',
        value: 'contacts',
        link: '/customers/contacts/:id'
    },
    {
        id: 'locations',
        value: 'locations',
        link: '/customers/locations/:id'
    },
    {
        id: 'jobs',
        value: 'jobs',
        link: '/customers/jobs/:id'
    },
    {
        id: 'estimates',
        value: 'estimates',
        link: '/customers/estimates/:id'
    },
    {
        id: 'documents',
        value: 'documents',
        link: '/customers/documents/:id'
    },
    {
        id: 'invoices',
        value: 'invoices',
        link: '/customers/invoices/:id'
    },
    {
        id: 'payments',
        value: 'payments',
        link: '/customers/payments/:id'
    },
    {
        id: 'credits',
        value: 'credits',
        link: '/customers/credits/:id'
    },
    {
        id: 'statement',
        value: 'statement',
        link: '/customers/statements/:id'
    }
];

export const DRIP_MENU_OPTIONS = [
    {
        id: 1,
        value: 'campaigns',
        link: '/drip/campaign',
        link_detail: '/drip/campaign/detail/id',
        link_report: '/drip/campaign/report',
        icon: true
    },
    {
        id: 2,
        value: 'smart_lists',
        link: '/drip/smartlist',
        link_detail: '/drip/smartlist/detail/id',
        link_report: null,
        icon: true
    },
    {
        id: 3,
        value: 'unsubscribed',
        link: '/drip/unsubscribed',
        link_detail: '/drip/unsubscribed/detail',
        link_report: null,
        icon: false
    },
    {
        id: 4,
        value: 'message_templates',
        link: '/drip/template',
        link_detail: '/drip/template/detail/id',
        link_report: null,
        icon: true
    }
];

export const typesNotification = {
    [NOTIFICATION_TYPES.COMMENT]: {
        active: <IconPen isActiveColor />,
        inActive: <IconPen isHasColor />
    },
    [NOTIFICATION_TYPES.NOTE]: {
        active: <IconNote isActiveColor />,
        inActive: <IconNote />
    },
    [NOTIFICATION_TYPES.ONLINE_PAYMENT]: {
        active: <IconAmount isActiveColor />,
        inActive: <IconAmount />
    },
    [NOTIFICATION_TYPES.JOB_RESCHEDULE]: {
        active: <IconCalendar isActiveColor />,
        inActive: <IconCalendar />
    },
    [NOTIFICATION_TYPES.JOB_CONFIRMATION]: {
        active: <IconDone isActiveColor />,
        inActive: <IconDone isHasColor />
    },
    [NOTIFICATION_TYPES.E_SIGNED_DOCUMENT]: {
        active: <IconDocument isActiveColor />,
        inActive: <IconDocument isActive />
    },
    [NOTIFICATION_TYPES.E_SIGNED_INVOICE]: {
        active: <IconInvoice isActiveColor />,
        inActive: <IconInvoice isActive />
    },
    [NOTIFICATION_TYPES.E_SIGNED_ESTIMATE]: {
        active: <IconEstimate isActiveColor />,
        inActive: <IconEstimate isActive />
    },
    [NOTIFICATION_TYPES.NEW_E_SIGNED_ESTIMATE]: {
        active: <IconEstimate isActiveColor />,
        inActive: <IconEstimate />
    },
    [NOTIFICATION_TYPES.SUBSCRIPTION]: {
        active: <IconCalendar isDollarActive />,
        inActive: <IconCalendar isHasDollar />
    },
    [NOTIFICATION_TYPES.SUBSCRIPTION_FAILED]: {
        active: <IconCalendar isDollarActive />,
        inActive: <IconCalendar isHasDollar />
    },
    [NOTIFICATION_TYPES.SUBSCRIPTION_CANCEL]: {
        active: <IconCalendar isDollarActive />,
        inActive: <IconCalendar isHasDollar />
    },
    [NOTIFICATION_TYPES.RECURRING_FAILED]: {
        active: <IconInvoice isPurple />,
        inActive: <IconInvoice />
    },
    [NOTIFICATION_TYPES.SMS_FAILED]: {
        active: <IconAmount isActiveColor />,
        inActive: <IconAmount />
    },
    [NOTIFICATION_TYPES.REVIEWS_LIKELY]: {
        active: <IconStar isCheckActive />,
        inActive: <IconStar isChecked />
    },
    [NOTIFICATION_TYPES.REVIEWS_SERVICE]: {
        active: <IconStar isCheckActive />,
        inActive: <IconStar isChecked />
    },
    [NOTIFICATION_TYPES.PAYMENT]: {
        active: <IconAmount isActiveColor />,
        inActive: <IconAmount />
    },
    [NOTIFICATION_TYPES.INBOUND_LEADS]: {
        active: <IconUser isCustomerActive />,
        inActive: <IconUser isCustomer />
    },
    [NOTIFICATION_TYPES.ONLINE_BOOKING]: {
        active: <IconOnlineBooking isSmallPurple />,
        inActive: <IconOnlineBooking isSmallGrey />
    },
    [NOTIFICATION_TYPES.ACH_INVITATION]: {
        active: <IconACH isActive />,
        inActive: <IconACH isInactive />
    },
    [NOTIFICATION_TYPES.ACH_VERIFY]: {
        active: <IconACH isActive />,
        inActive: <IconACH isInactive />
    },
    [NOTIFICATION_TYPES.MISSED_CALL]: {
        active: <IconCall isMissing />,
        inActive: <IconCall isMissing isInActive />
    },
    [NOTIFICATION_TYPES.JOB]: {
        active: <IconDone isActiveColor />,
        inActive: <IconDone isHasColor />
    }
};
