import GdButton from 'app/components/button';
import { getUrlCustomerSubscriptionCancel } from 'app/const/Api';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { IMMEDIATELY } from 'app/const/drip/Campaigns';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

const SubscriptionCancelModal = forwardRef(
    ({ customerId, typeSubscription, onCancelSuccess = () => {}, onCancelFail = () => {} }, ref) => {
        const [state, dispatchState] = useReducer(reducer, {
            subscription: {},
            isOpen: false,
            isLoading: false,
            immediately: typeSubscription !== PAYMENT_CARD_CUSTOMER.SQUARE
        });
        const { t } = useTranslation(['report']);
        const { immediately, subscription } = state;

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = (subscription) => {
            dispatchState({ isOpen: true, subscription });
        };

        const _close = () => {
            dispatchState({ isOpen: false });
        };

        const _handleChange = (e) => {
            dispatchState({ immediately: e.target.name === IMMEDIATELY });
        };

        const _handleCancelSubscription = () => {
            dispatchState({ isLoading: true });

            const _handleCancelSuccess = ({ message }) => {
                onCancelSuccess(subscription, message, immediately);
                dispatchState({ isLoading: false, isOpen: false, immediately: 0 });
            };

            const _handleCancelFailed = ({ message }) => {
                onCancelFail(message);
                dispatchState({ isLoading: false, isOpen: false });
            };

            clientQuery(
                getUrlCustomerSubscriptionCancel(subscription?.customer?.id || customerId),
                {
                    data: {
                        subscription_id: subscription.subscription_id,
                        account_customer_id: subscription.account_customer_id,
                        period: immediately ? 2 : 1,
                        merchant: typeSubscription === 'square' ? 2 : 1
                    },
                    method: 'PUT',
                    toFormData: false
                },
                _handleCancelSuccess,
                _handleCancelFailed
            );
        };

        return (
            <ReactModal
                id="cancel_subscription"
                isOpen={state.isOpen}
                style={{ overlay: { zIndex: 99, background: 'transparent' } }}
                className="modal container-modal form-stripe-subscription --form-cancel open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container large">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('report:end_subscription')}</h3>
                        <div className="btn-action btn-close">
                            <span className="v2-btn-default --transparent" onClick={_close}>
                                {t('report:cancel_date_picker')}
                            </span>
                            <GdButton
                                className="v2-btn-main"
                                onClick={_handleCancelSubscription}
                                isLoading={state.isLoading}
                            >
                                {t('report:end_subscription')}
                            </GdButton>
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <div className="billing-content">
                            {typeSubscription !== PAYMENT_CARD_CUSTOMER.SQUARE ? (
                                <div className={classNames('rows', { active: immediately })}>
                                    <div className="check-radio">
                                        <input
                                            id="end_subscription_immediately"
                                            type="radio"
                                            name={IMMEDIATELY}
                                            checked={immediately}
                                            onChange={_handleChange}
                                        />
                                        <label
                                            htmlFor="end_subscription_immediately"
                                            className="check-radio__label js-selected-box"
                                            data-after="Set as Default"
                                            data-after-selected="Default"
                                        >
                                            {t('report:immediately')}
                                        </label>
                                    </div>
                                    <p className="desc">{t('report:end_the_subscription_immediately')}</p>
                                </div>
                            ) : null}
                            <div className={classNames('rows', { active: !immediately })}>
                                <div className="check-radio">
                                    <input
                                        id="end_subscription_period"
                                        type="radio"
                                        name="period"
                                        checked={!immediately}
                                        onChange={_handleChange}
                                    />
                                    <label
                                        htmlFor="end_subscription_period"
                                        className="check-radio__label js-selected-box"
                                        data-after="Set as Default"
                                        data-after-selected="Default"
                                    >
                                        {t('report:at_period_end')}
                                    </label>
                                    <span className="tag status-btn na">{subscription.period_end}</span>
                                </div>
                                <p className="desc">
                                    {t('report:end_the_subscription_at_the_end_of_the_current_billing_period')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default SubscriptionCancelModal;
