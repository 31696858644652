import {
    GET_LIST_CUSTOM_SETTING,
    SETTINGS_GET_LIST_BROADCAST_TEMPLATE,
    SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE
} from 'app/const/Api';
import {
    getTemplateAutoMessage,
    getTemplateBroadcast,
    getTemplateEmailInbox,
    getTemplateSystem
} from 'app/const/api/V2';
import { DEFAULT_REMINDER_TEMPLATE, TEMPLATE_DEFAULT_TITLE, TYPE_TEMPLATE, TYPE_TEMPLATE_ID } from 'app/const/Settings';

export const getDefaultTemplate = (typeTemplate) => {
    switch (typeTemplate) {
        case TYPE_TEMPLATE.BROADCAST:
            return {
                isDisplaySMS: true,
                template: {
                    reminders: [{ ...DEFAULT_REMINDER_TEMPLATE, with_sms: 1, sms_content: '' }],
                    title: TEMPLATE_DEFAULT_TITLE[TYPE_TEMPLATE_ID.BROADCAST]
                },
                currentReminder: { ...DEFAULT_REMINDER_TEMPLATE, with_sms: 1, sms_content: '' }
            };
        case TYPE_TEMPLATE.EMAIL_INBOX:
            return {
                template: {
                    reminders: [{ ...DEFAULT_REMINDER_TEMPLATE }],
                    title: TEMPLATE_DEFAULT_TITLE[TYPE_TEMPLATE_ID.SMS]
                },
                currentReminder: DEFAULT_REMINDER_TEMPLATE
            };
        default:
            break;
    }
};

export const _handleGetURLTemplate = (typeTemplate, idTemplate) => {
    switch (typeTemplate) {
        case TYPE_TEMPLATE.AUTO_MESSAGE:
            return getTemplateAutoMessage(idTemplate);
        case TYPE_TEMPLATE.EMAIL_INBOX:
            return getTemplateEmailInbox(idTemplate);
        case TYPE_TEMPLATE.BROADCAST:
            return getTemplateBroadcast(idTemplate);
        case TYPE_TEMPLATE.SYSTEM:
            return getTemplateSystem(idTemplate);
    }
};

export const _handleGetURLCreate = (type) => {
    switch (type) {
        case TYPE_TEMPLATE.AUTO_MESSAGE:
            return GET_LIST_CUSTOM_SETTING;
        case TYPE_TEMPLATE.BROADCAST:
            return SETTINGS_GET_LIST_BROADCAST_TEMPLATE;
        case TYPE_TEMPLATE.EMAIL_INBOX:
            return SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE;
    }
};
