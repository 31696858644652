import React from 'react';

const LoadingSettings = () => {
    return (
        <div className="wrap-loading box-setting">
            <div className="flex-column gap-16">
                <div className="flexcenter">
                    <div className="flex-1">
                        <div className="avt-img --large loading" />
                    </div>
                    <div className="flexcenter">
                        <div className="v2-btn-default --grey loading btn-md">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default bg-white-cultured --icon-lg --transparent loading ml-2" />
                    </div>
                </div>
                <div className="flex-column gap-5 mt-1">
                    <div className="loading --animation --line --onefifth" />
                    <div className="v2-btn-default --grey loading w-100" />
                </div>
            </div>
            <div className="flex-column gap-10 box-setting__container">
                <div className="loading --animation --line --onefourth" />
                <div className="flex-betweenitems my-2">
                    <div className="loading --animation --line --half" />
                    <div className="loading --animation --line --onefifth" />
                </div>
                <div className="flex-betweenitems gap-16">
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --onefifth" />
                        <div className="v2-btn-default --grey loading --full">
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --onefifth" />
                        <div className="v2-btn-default --grey loading --full">
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-betweenitems my-3 gap-30">
                    <div className="loading --animation --line --onefifth" />
                    <div className="d-flex justify-end flex-1 gap-4">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --full mt-0" />
                        <div className="loading --animation --line --full mt-0" />
                        <div className="loading --animation --line --full mt-0" />
                        <div className="loading --animation --line --full mt-0" />
                        <div className="loading --animation --line --full mt-0" />
                        <div className="loading --animation --line --full mt-0" />
                    </div>
                </div>
                <div className="flex-betweenitems gap-16">
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --half" />
                        <div className="v2-btn-default --grey loading w-100" />
                    </div>
                    <div className="flex-column gap-4 flex-1">
                        <div className="loading --animation --line --half" />
                        <div className="v2-btn-default --grey loading w-100" />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-end">
                <div className="v2-btn-default loading btn-x-sm --grey">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
};

export default LoadingSettings;
