import GdButton from 'app/components/button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';

const HeaderModalAdd = ({ onClose = () => {}, isDisplayButton = false, isDisableSave = false }) => {
    const { t } = useTranslation(['common', 'customers']);
    const { isLoadingAdd, _handleSubmitAdd } = useContext(SubscriptionContext);

    return (
        <div className="header-modal">
            <h3 className="header-modal__label">{t('customers:add_subscription')}</h3>
            {isDisplayButton ? (
                <div className="btn-action btn-close">
                    <span className="v2-btn-default --transparent" onClick={onClose}>
                        {t('cancel')}
                    </span>
                    {!isDisableSave && (
                        <GdButton className="v2-btn-main" isLoading={isLoadingAdd} onClick={_handleSubmitAdd}>
                            {t('save')}
                        </GdButton>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default HeaderModalAdd;
