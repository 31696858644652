import ButtonSave from 'app/components/button/ButtonSave';
import { GET_LIST_TIME_WINDOW_TEMPLATE } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeToNumber, isTimeOverlapping } from 'common/utils/TimeUtils';
import { forwardRef, React, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import TimeWindowDateItem from './TimeWindowDateItem';
import { resetTimeWindow } from 'common/redux/actions/timeWindowAction';
import { useDispatch } from 'react-redux';
import StatusBar from 'app/components/status/statusbar';

const TimeWindowAddonsForm = forwardRef(({ onHandleUpdate = () => {}, onHandleSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['addons']);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        dataUpdate: []
    });

    useImperativeHandle(ref, () => ({ open: _handleOpen }));
    const refStatusBar = useRef(null);
    const itemsRef = useRef([]);
    const refButtonSave = useRef(null);
    const dispatch = useDispatch();

    const { dataUpdate } = state;

    const _handleOpen = (data) => {
        dispatchState({ isOpen: true, dataUpdate: data });
    };

    const _handleClose = () => {
        if (state.isOpen) {
            dispatchState({ isOpen: false });
        }
    };

    const _getTimeValue = () => {
        const newTime = itemsRef.current.map((item) => {
            return item?.getTime() || {};
        });

        const finalTime = newTime.map((time) => {
            const { hour: hourStart, minute: minuteStart, midDay: midDayStart } = time.start || {};
            const { hour: hourEnd, minute: minuteEnd, midDay: midDayEnd } = time.end || {};
            const numberStart = convertTimeToNumber(
                parseInt(hourStart) + (midDayStart === 'PM' && hourStart !== '12' ? 12 : 0),
                minuteStart
            );
            const numberEnd = convertTimeToNumber(
                parseInt(hourEnd) + (midDayEnd === 'PM' && hourEnd !== '12' ? 12 : 0),
                minuteEnd
            );

            return { startTime: numberStart, endTime: numberEnd, idTime: time.idTime };
        });

        return finalTime;
    };

    const _handleAction = () => {
        // Closes the status bar.
        refStatusBar.current.closeStatusBar();
        const timesCheck = [];
        const time = _getTimeValue();
        let isTimeError = false;
        let isTimeOverlap = false;

        const newData = dataUpdate.map((item) => {
            const finalTime = time.find((time) => time.idTime === item.id);

            if (finalTime) {
                if (finalTime.startTime >= finalTime.endTime) {
                    isTimeError = true;
                }
                const newItem = {
                    ...item,
                    start: finalTime.startTime.toString(),
                    end: finalTime.endTime.toString()
                };

                // Push to list time check overlapping
                timesCheck.push(newItem);

                // Set id to null if it is new template
                newItem['id'] = item.idTemp ? null : item.id;

                // Remove idTemp
                delete newItem.idTemp;
                return newItem;
            }

            return item;
        });

        if (!isTimeError) {
            timesCheck.forEach((element) => {
                if (isTimeOverlapping(timesCheck, element)) {
                    isTimeOverlap = true;
                }
            });
        }

        if (isTimeError || isTimeOverlap) {
            refStatusBar.current.showStatusBar(
                LIST_STATUS.ERROR,
                isTimeError
                    ? t('addons:the_start_time_must_be_greater_than_the_end_time')
                    : t('addons:message_error_time_overlap'),
                LIST_STATUS.ERROR
            );
            refButtonSave.current.removeLoading();
            return;
        }

        clientQuery(
            GET_LIST_TIME_WINDOW_TEMPLATE,
            {
                method: 'PUT',
                data: { templates: newData },
                toFormData: false
            },
            _handleSuccess,
            _handleFail,
            _handleFinal
        );
    };

    const _handleSuccess = ({ data }) => {
        dispatchState({
            dataUpdate: data || []
        });
        onHandleUpdate(data || []);

        dispatch(resetTimeWindow());

        _handleClose();
        onHandleSuccess();
    };

    const _handleFail = ({ message }) => {
        dispatchState({
            isOpen: true
        });
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, message, LIST_STATUS.ERROR);
    };

    const _handleFinal = () => {
        refButtonSave.current.removeLoading();
    };

    const _handleAddTemplate = () => {
        dispatchState({
            dataUpdate: [...dataUpdate, { end: '00', id: Date.now().toString(), name: '', start: '00', idTemp: true }]
        });
    };

    const _handleDeleteTemplate = (id) => {
        dispatchState((prev) => {
            return {
                ...prev,
                dataUpdate: prev.dataUpdate.filter((item) => item.id !== id)
            };
        });
    };

    const _handleChangeInput = (e, id) => {
        const newData = dataUpdate.map((element) => {
            if (element.id === id) {
                return {
                    ...element,
                    name: e.target.value
                };
            }
            return element;
        });
        dispatchState({ dataUpdate: newData });
    };

    const _renderList = () => {
        if (!dataUpdate?.length) {
            return <div className="tables-empty">{t('addons:no_template_please_add_new_window')}</div>;
        }

        return dataUpdate.map((item, index) => {
            return (
                <TimeWindowDateItem
                    ref={(el) => (itemsRef.current[index] = el)}
                    key={item.id}
                    {...item}
                    onChangeInput={_handleChangeInput}
                    onDelete={_handleDeleteTemplate}
                />
            );
        });
    };

    if (!state.isOpen) {
        return false;
    }

    return (
        <div className="boxs active form-timewindow is-active">
            <div className="boxs__header">
                <div className="header-title">
                    <h4 className="dots">{t('addons:templates')}</h4>
                </div>
                <div className="v2-btn-default --transparent js-edit-template" onClick={_handleClose}>
                    {t('addons:cancel')}
                </div>
                <ButtonSave
                    ref={refButtonSave}
                    wrapClass="v2-btn-main js-edit-template ml-2"
                    onSave={_handleAction}
                    title={t('addons:save')}
                />
            </div>

            <div className="boxs__contents">
                <StatusBar ref={refStatusBar} />
                {_renderList()}
            </div>
            <div className="boxs__footer">
                <div onClick={_handleAddTemplate} className="v2-btn-default has-icon --grey js-add-wd">
                    <IconPlus />
                    {t('addons:add_new_window')}
                </div>
            </div>
        </div>
    );
});
export default TimeWindowAddonsForm;
