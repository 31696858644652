export const TYPE_CALL_TIME_LINE = {
    INCOMMING: 1,
    OUTGOING: 2,
    VOICE_MAIL: 3,
    PARTICIPANTS: 4
};

export const STATUS_CALL_TIME_LINE = {
    CALLED: 1,
    MISSING: 0
};
