import GdGridView from 'app/components/grid/GdGridView';
import { getUrlCustomerLocation, getUrlCustomerSubscription } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { getGridColumnSubscription } from 'app/const/customer/CustomerInvoices';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { forwardRef } from 'react';
import SubscriptionCancelModal from './add/components/SubscriptionCancelModal';
import DetailSubscription from './detail';
import EditSubscription from './edit';
import { useParams } from 'react-router-dom';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const CustomerSubscriptionTable = forwardRef(
    ({ typeSubscription, customer = {}, onShowAlert = () => {}, reloadCustomerPage = 0 }, ref) => {
        const { t } = useTranslation('customers');
        const [state, dispatchState] = useReducer(reducer, {
            data: [],
            locations: [],
            isLoadingSubscription: true,
            isLoadingLocation: true
        });
        const refCancelSubscription = useRef(null);
        const refEditSubscription = useRef(null);
        const refDetailSubscription = useRef(null);

        const { id: customerId } = useParams();
        const subscriptions = state.data;
        const isLoadingSubscription = state.isLoadingSubscription;

        useImperativeHandle(ref, () => ({ _add: (newData) => dispatchState({ data: [...state.data, newData] }) }));

        useEffect(() => {
            if (!customerId) return;
            if (!isLoadingSubscription) {
                dispatchState({
                    isLoadingSubscription: true
                });
            }
            _getListSubscription();
            _getListLocation();
        }, [customerId, reloadCustomerPage]);

        const _getListSubscription = () => {
            if (!customerId) return;

            const _success = ({ data }) => dispatchState({ data, isLoadingSubscription: false });
            const _failed = () => dispatchState({ data: [], isLoadingSubscription: false });
            /**
             * get customer subscriptions
             * merchant: isSquare ? 2 : 1
             */
            clientQuery(
                getUrlCustomerSubscription(customerId),
                { data: { merchant: typeSubscription === 'square' ? 2 : 1 }, method: 'GET' },
                _success,
                _failed
            );
        };

        const _getListLocation = () => {
            if (!customerId) return;

            const _success = ({ data }) => dispatchState({ locations: data, isLoadingLocation: false });
            const _failed = () => dispatchState({ locations: [], isLoadingLocation: false });
            clientQuery(getUrlCustomerLocation(customerId), { data: {}, method: 'GET' }, _success, _failed);
        };

        const _handleCancelSubscription = (subscription) => {
            refCancelSubscription.current._open(subscription);
        };

        const _handleEditSubscription = (subscription) => {
            refEditSubscription.current._open(customer, subscription);
        };

        const _handleCancelSuccess = (subscription, message, immediately) => {
            const subscription_id = subscription.subscription_id;
            onShowAlert(LIST_STATUS.SUCCESS, {
                id: LIST_STATUS.SUCCESS,
                message,
                type: LIST_STATUS.SUCCESS
            });
            if (immediately)
                dispatchState({ data: subscriptions.filter((item) => item.subscription_id !== subscription_id) });
            else
                dispatchState({
                    data: subscriptions.map((item) => {
                        if (item.subscription_id === subscription_id) return { ...item, upcoming: '' };
                        return item;
                    })
                });
        };

        const _handleCancelFail = (message) => {
            onShowAlert(LIST_STATUS.ERROR, {
                id: LIST_STATUS.ERROR,
                message,
                type: LIST_STATUS.ERROR
            });
        };

        const _handleEditSuccess = (subscription, message) => {
            onShowAlert(LIST_STATUS.SUCCESS, {
                id: LIST_STATUS.SUCCESS,
                message,
                type: LIST_STATUS.SUCCESS
            });
            dispatchState({
                data: subscriptions.map((item) => {
                    if (item.subscription_id === subscription.subscription_id) return { ...item, ...subscription };
                    return item;
                })
            });

            refEditSubscription.current && refEditSubscription.current._close();
        };

        const _handleOpenSubscription = (subscription) => {
            refDetailSubscription.current._open(subscription);
        };

        const _handleDeleteSubscription = (subscription_id, message, isImmediately = false) => {
            if (isImmediately) {
                dispatchState({ data: subscriptions.filter((item) => item.subscription_id !== subscription_id) });
                refDetailSubscription.current && refDetailSubscription.current._close();
            } else {
                dispatchState({
                    data: subscriptions.map((item) => {
                        if (item.subscription_id === subscription_id) return { ...item, upcoming: '' };
                        return item;
                    })
                });
            }

            onShowAlert(LIST_STATUS.SUCCESS, {
                id: LIST_STATUS.SUCCESS,
                message,
                type: LIST_STATUS.SUCCESS
            });
        };

        return (
            <>
                <GdGridView
                    msgEmpty={t('no_subscriptions')}
                    content={subscriptions}
                    locations={state.locations}
                    isLoading={isLoadingSubscription || state.isLoadingLocation}
                    classTable="table-subscription table-multi-column"
                    fileTranslation="report"
                    isHasDropdown
                    onCancelSubscription={_handleCancelSubscription}
                    onEditSubscription={_handleEditSubscription}
                    onOpenSubscription={_handleOpenSubscription}
                    {...getGridColumnSubscription({ typeSubscription })}
                    isScroll
                />
                {/* Modals */}
                <DetailSubscription
                    ref={refDetailSubscription}
                    customer={customer}
                    typeSubscription={typeSubscription}
                    onReactiveSuccess={_getListSubscription}
                    onEdit={_handleEditSubscription}
                    onDelete={_handleDeleteSubscription}
                />
                <EditSubscription
                    ref={refEditSubscription}
                    typeSubscription={typeSubscription}
                    customer={customer}
                    onEditSuccess={_handleEditSuccess}
                />
                <SubscriptionCancelModal
                    ref={refCancelSubscription}
                    customerId={customerId}
                    typeSubscription={typeSubscription}
                    onCancelSuccess={_handleCancelSuccess}
                    onCancelFail={_handleCancelFail}
                />
            </>
        );
    }
);

export default CustomerSubscriptionTable;
