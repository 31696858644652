import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import {
    getUrlCustomerSubscriptionCancel,
    getUrlCustomerSubscriptionReactive,
    getUrlSubscriptionDetail
} from 'app/const/Api';
import { SUBSCRIPTION_CANCEL_IMMEDIATELY } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconPen from 'assets/icon/IconPen';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import SubscriptionCancelModal from '../add/components/SubscriptionCancelModal';
import CustomerInfo from './components/CustomerInfo';
import InvoicesTable from './components/InvoicesTable';
import Loading from './components/Loading';
import PlansTable from './components/PlansTable';
import SubscriptionDetailFooter from './components/SubscriptionDetailFooter';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/Status';
import classNames from 'classnames';
import { linkStripeDashboard } from 'app/const/api/V2';

const DetailSubscription = forwardRef(
    ({ customer, typeSubscription, onReactiveSuccess = () => {}, onEdit = () => {}, onDelete = () => {} }, ref) => {
        const { t } = useTranslation(['common', 'customers']);
        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            isCanceled: false,
            isImmediately: false,
            isLoading: true,
            isShowMore: false,
            isLoadingButton: false,
            subscription: {}
        });
        const refAlert = useRef(null);
        const refConfirm = useRef(null);
        const refConfirmReactive = useRef(null);
        const refButtonSave = useRef(null);
        const refCancelSubscription = useRef(null);
        const { isLoading, subscription, isCanceled, isImmediately, isShowMore } = state;
        const isSquare = typeSubscription === 'square';

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = (data) => {
            dispatchState({ isOpen: true });
            _getSubscription({
                account_customer_id: data.account_customer_id,
                subscription_id: data.subscription_id,
                merchant: isSquare ? 2 : 1
            });
        };

        const _close = () => {
            dispatchState({
                isOpen: false,
                isCanceled: false,
                isImmediately: false,
                isLoading: true,
                subscription: {},
                isLoadingButton: false
            });
        };

        const _getSubscription = (optionQuery = null) => {
            if (!optionQuery) return;

            const _handleSuccess = ({ data, showMore = false }) => {
                dispatchState({
                    isLoading: false,
                    subscription: data,
                    isCanceled: !!data.canceled_at,
                    isShowMore: showMore
                });
            };

            const _handleFail = ({ message }) => {
                alert(message);
                _close();
            };

            clientQuery(
                getUrlSubscriptionDetail(customer.id),
                { data: optionQuery, method: 'GET' },
                _handleSuccess,
                _handleFail
            );
        };

        const _handleCancel = () => {
            refCancelSubscription.current._open(subscription);
        };

        const _handleCancelSuccess = (subscription, message, isImmediately) => {
            onDelete(subscription?.subscription_id, message, isImmediately);
            dispatchState({ isCanceled: true, isImmediately });
            if (isImmediately && refCancelSubscription.current) refCancelSubscription.current._close();
        };

        const _handleCancelImmediate = () => {
            refConfirm.current._open({
                title: 'Cancel subscription now',
                description: t('customers:message_cancel_immediately_subscription_confirm', {
                    date: subscription.period_end
                })
            });
        };

        const _handleReactive = () => {
            if (!refConfirmReactive.current) return;
            refConfirmReactive.current._open({
                title: 'Reactivate subscription',
                description: t('customers:message_reactive_subscription_confirm', {
                    date: subscription.period_end
                })
            });
        };

        const _handelConfirmImmediate = () => {
            clientQuery(
                getUrlCustomerSubscriptionCancel(customer.id),
                {
                    data: {
                        subscription_id: subscription.subscription_id,
                        account_customer_id: subscription.account_customer_id,
                        period: SUBSCRIPTION_CANCEL_IMMEDIATELY
                    },
                    method: 'PUT',
                    toFormData: false
                },
                ({ message }) => _handleCancelImmediateSuccess(subscription.subscription_id, message),
                (err) => console.error(err),
                () => refButtonSave.current && refButtonSave.current.removeLoading()
            );
        };

        const _showAlert = (data) => {
            refAlert.current && refAlert.current.showStatusBar(data);
        };

        const _handelConfirmReactive = () => {
            const _handleReactiveSuccess = ({ message }) => {
                onReactiveSuccess();
                _showAlert({ id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
                dispatchState({ isCanceled: false, isImmediately: false });
                refConfirmReactive.current._close();
            };

            const _handleReactiveFail = (error) => {
                console.error(error);
            };

            const _handleReactiveFinally = () => {
                refButtonSave.current && refButtonSave.current.removeLoading();
                refConfirmReactive.current._close();
            };

            clientQuery(
                getUrlCustomerSubscriptionReactive(customer.id),
                {
                    data: {
                        subscription_id: subscription.subscription_id,
                        account_customer_id: subscription.account_customer_id
                    },
                    method: 'PUT',
                    toFormData: false
                },
                _handleReactiveSuccess,
                _handleReactiveFail,
                _handleReactiveFinally
            );
        };

        const _handleCancelImmediateSuccess = (idDeleted, message) => {
            dispatchState({ isCanceled: true, isImmediately: true });
            onDelete(idDeleted, message, !isSquare);
            refConfirm.current && refConfirm.current._close();
        };

        const _handleCloseWarning = (type) => {
            switch (type) {
                case 'reactive':
                    refConfirmReactive.current && refConfirmReactive.current._close();
                    break;
                case 'warning':
                    refConfirm.current && refConfirm.current._close();
                    break;
                default:
                    break;
            }
        };

        const upcomingDesc = subscription.upcoming
            ? t('customers:view_next_invoice', {
                  total: subscription.upcoming?.total,
                  date: subscription.upcoming?.date
              })
            : '';

        return (
            <>
                <ReactModal
                    id="view_subscription"
                    isOpen={state.isOpen}
                    style={{ overlay: { zIndex: 99, background: 'transparent' } }}
                    className="modal container-modal form-stripe-subscription --form-main open"
                    onRequestClose={_close}
                >
                    <div className="modal__overlay bg-fixed" onClick={_close} />
                    <div className="modal__container">
                        <div className="header-modal">
                            <div className="header-modal__label">Subscription</div>
                            <div
                                className={classNames('v2-btn-default has-icon', { 'is-disable': isLoading })}
                                onClick={() => onEdit(subscription)}
                            >
                                <IconPen />
                                Update Subscription
                            </div>
                            <div className="line" />
                            <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                                <IconClose />
                            </div>
                        </div>
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <>
                                <div className="body-modal scrolls">
                                    <AlertCustomer ref={refAlert} />
                                    <CustomerInfo customer={customer} subscription={subscription} isSquare={isSquare} />
                                    <PlansTable plans={subscription.plans || []} total={subscription.total} />
                                    <InvoicesTable
                                        invoices={subscription.invoices || []}
                                        isShowMore={isShowMore && isSquare}
                                        typeSubscription={typeSubscription}
                                        subscriptionId={subscription.subscription_id}
                                    />
                                    {subscription.upcoming ? (
                                        <div className="plans-content mt-5">
                                            <div className="header">
                                                <div className="title-x-sm">Upcoming invoice</div>
                                                {!isSquare ? (
                                                    <a
                                                        href={linkStripeDashboard(
                                                            subscription.account_customer_id,
                                                            subscription.subscription_id
                                                        )}
                                                        target="_blank"
                                                        className="header__link purple-default"
                                                        rel="noreferrer"
                                                    >
                                                        {upcomingDesc}
                                                    </a>
                                                ) : (
                                                    <p className="header__link purple-default">{upcomingDesc}</p>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                {!isImmediately && (
                                    <SubscriptionDetailFooter
                                        isCanceled={isCanceled}
                                        typeSubscription={typeSubscription}
                                        onCancel={_handleCancel}
                                        onReactive={_handleReactive}
                                        onCancelImmediate={_handleCancelImmediate}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </ReactModal>
                {/* Modals */}
                <GDModalWarning
                    ref={refConfirm}
                    isLargeContent
                    footer={
                        <div className="footer-modal justify-end">
                            <span className="v2-btn-default" onClick={() => _handleCloseWarning('warning')}>
                                No, cancel at period end
                            </span>
                            <ButtonSave
                                ref={refButtonSave}
                                className="v2-btn-main ml-2"
                                onSave={_handelConfirmImmediate}
                            >
                                Yes, cancel subscription now
                            </ButtonSave>
                        </div>
                    }
                />

                <GDModalWarning
                    ref={refConfirmReactive}
                    isLargeContent
                    footer={
                        <div className="footer-modal justify-end">
                            <span className="v2-btn-default" onClick={() => _handleCloseWarning('reactive')}>
                                No, cancel at period end
                            </span>
                            <ButtonSave
                                ref={refButtonSave}
                                className="v2-btn-main ml-2"
                                onSave={_handelConfirmReactive}
                            >
                                Yes, reactivate subscription
                            </ButtonSave>
                        </div>
                    }
                />
                <SubscriptionCancelModal
                    ref={refCancelSubscription}
                    customerId={customer.id}
                    typeSubscription={typeSubscription}
                    onCancelSuccess={_handleCancelSuccess}
                />
            </>
        );
    }
);

export default DetailSubscription;
