import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { ADDONS_CUSTOM_FIELDS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import { checkPermission } from 'common/utils/PermissionUtils';
import { CUSTOM_FIELDS_VIEWS } from '../../../customfields/constant';

const Header = ({
    activeTab = CUSTOM_FIELDS_VIEWS.ACTIVE,
    tabs = [],
    isLoading = true,
    onChangeTab = () => {},
    onOpenAddField = () => {}
}) => {
    const { t } = useTranslation('common');
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const permissionAdd = checkPermission(permissionList, PERMISSIONS.addCustomField);

    return (
        <>
            <div className="header flex-betweenitems">
                <div className="flexcenter gap-8">
                    <Link to={addBranchPath(ADDONS_CUSTOM_FIELDS)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('custom_fields')}
                    </Link>
                    <TabSwitcher listTabs={tabs} activeTab={activeTab} onChange={onChangeTab} />
                </div>
                {permissionAdd ? (
                    <div
                        className={classNames('v2-btn-main has-icon svg-white btn-modal', { 'is-disable': isLoading })}
                        onClick={onOpenAddField}
                    >
                        <IconPlus />
                        {t('new_field')}
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default Header;
