import React, { useCallback, useReducer, forwardRef, useImperativeHandle, useEffect, useRef, Fragment } from 'react';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';

const PickerHour = forwardRef(({ value = '01', onChange = () => {}, reset = '', isCloseOnSelected = false }, ref) => {
    const refDropdown = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        selected: value
    });

    const { selected: finalSelected } = state;

    useEffect(() => {
        if (value !== finalSelected) {
            dispatchState({ selected: value });
        }
    }, [value]);

    useEffect(() => {
        if (value !== finalSelected && !!reset) {
            dispatchState({ selected: value });
        }
    }, [reset]);

    useEffect(() => {
        if (finalSelected !== value) {
            onChange();
        }
    }, [finalSelected]);

    useImperativeHandle(ref, () => ({
        _getValue: () => {
            return finalSelected;
        }
    }));

    function _selectItem(e, item) {
        isCloseOnSelected && refDropdown.current._close();
        dispatchState({
            isVisible: false,
            selected: item
        });
    }

    const _renderHours = useCallback(() => {
        let list = [];
        let defaultValue = 1;

        while (defaultValue < 13) {
            const checkConcat = defaultValue < 10 ? '0' : '';
            list = list.concat(checkConcat.concat(defaultValue));
            defaultValue = defaultValue + 1;
        }

        return list.map((item) => {
            return (
                <li key={item} onClick={(e) => _selectItem(e, item)} tabIndex="0">
                    <div className="items">{item}</div>
                </li>
            );
        });
    }, [finalSelected]);

    return (
        <div className="from-time">
            <DropdownPopper
                ref={refDropdown}
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn v2-btn-default"
                dropdownIcon={
                    <Fragment>
                        <span>{finalSelected}</span>
                        <span className="time-text">h</span>
                        <span className="ml-1">
                            <IconDropDown />
                        </span>
                    </Fragment>
                }
                strategy="fixed"
                isUseToggle
                isCalculateWidth
            >
                <ul>{_renderHours()}</ul>
            </DropdownPopper>
        </div>
    );
});

export default PickerHour;
