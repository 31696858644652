import React, { useImperativeHandle, useState, forwardRef } from 'react';
import { LINK_CDN_IMG } from 'app/const/URL';

const ModalLoading = forwardRef((props, ref) => {
    const [isVisible, setIsVisible] = useState(false);

    useImperativeHandle(ref, () => ({ _open: () => setIsVisible(true), _close: () => setIsVisible(false) }));

    if (!isVisible) return null;
    return (
        <div id="processing" className="modal container-modal modal-loading open">
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container --sm">
                <div className="body-modal is-processing">
                    <div className="is-processing__load">
                        <img src={`${LINK_CDN_IMG}ajax-loader.gif`} width={125} height={15} alt="loading" />
                    </div>
                    <p className="is-processing__label fs-14 black">Processing... Please wait</p>
                </div>
            </div>
        </div>
    );
});

export default ModalLoading;
