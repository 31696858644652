import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { COMMON, KEY_REPORT_LOCAL_STORAGE, METHOD_API, METHOD_CONVERT } from 'app/const/App';
import { COMMISSTION_COLUMN, COMMISSION_TYPE, COMMISSIONS_TABS_BUTTON } from 'app/const/Commissions';
import { COMMISSIONS_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkAddon } from 'common/utils/AddonUtils';

import { getGridColumns } from 'app/const/report/Commissions';
import { getListDataCommission } from 'common/redux/actions/commissionAction';
import Export from 'app/modules/report/components/Export';
import { URL_EXPORT_COMMISSION } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import classNames from 'classnames';
import ErrorPage from 'app/modules/error';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';

import FilterReport from 'app/modules/report/components/FilterReport';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import GdGridView from 'app/components/grid/GdGridView';
import GdGridRowTotal from 'app/components/grid/GdGridRowTotal';
import GdButton from 'app/components/button';
import ManageCommission from './components/Manage';
import Header from './components/Header';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { useLocation } from 'react-router-dom';
import { convertParamsJobStatus } from 'common/utils/JobStatusUtils';

const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.COMMISSIONS);
const keyLocalStoreTab = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.COMMISSIONS, '_tab');

const Commission = () => {
    const initStateReport = {
        refreshScreen: 0,
        currentTab: getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.COMMISSIONS, '_tab')),
        dataTracking: {
            data: [],
            rowTotal: [],
            total: 0,
            isLoading: true
        },
        user: {
            id: null,
            id_from: null,
            data: [],
            isLoading: true
        },
        lineItem: {
            data: [],
            isLoading: true
        },
        listButton: { close: true, confirm: false },
        contentConfirm: ''
    };
    const { t } = useTranslation(['common', 'commission']);

    const companyUsers = useSelector((state) => state.companyUsers.users || []);

    const dispatch = useDispatch();
    const { state } = useLocation();
    const refManageCommission = useRef(null);
    const refHeader = useRef(null);
    const refStatusBar = useRef(null);
    const userInfo = useSelector((state) => state.auth.user);
    const addonsList = userInfo.settings.addons || {};
    const permissionList = userInfo.permissions.enabled || [];

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refreshScreen: 0,
        currentTab: getLocalStorage(keyLocalStoreTab) || COMMISSION_TYPE.production,
        dataTracking: {
            data: [],
            rowTotal: [],
            total: 0,
            isLoading: true
        },
        isManage: !!state?.redirectManage
    });

    const { dataTracking, currentTab, refreshScreen, isManage } = dataReport;
    const tabValue = currentTab === COMMISSION_TYPE.production ? 0 : 1;

    const isPermission =
        checkPermission(permissionList, PERMISSIONS.admin) && checkAddon(addonsList?.commission_tracking);

    const paramsReport = getLocalStorage(keyLocalStore);
    !paramsReport && setLocalStorage(keyLocalStore, getDefaultParams(REPORT_TYPE.COMMISSIONS));

    useEffect(() => {
        //Get list data user
        _handleUpdate();
        // set filter status are save in localstorage.
        setLocalStorage(keyLocalStoreTab, currentTab);
    }, [currentTab]);

    const getListReport = (params) => {
        dispatchActionReport({
            dataTracking: {
                ...dataTracking,
                isLoading: true
            }
        });
        params = getNewAPIRequest(params);

        dispatch(
            getListDataCommission(
                params,
                (response, key) => getListDataCommissionSuccess(response, key),
                getListDataCommissionFailed
            )
        );
    };

    const getNewAPIRequest = (params) => {
        params.staffs = params.staffs.toString();
        params.order = params.order?.replace(/invoice_date|payment_date/, 'date');
        params.invoice_status = params.invoice_status.toString();
        params.payment_methods = params.payment_methods.toString();
        params.report_type = tabValue;
        params.job_status = convertParamsJobStatus(params?.job_status)?.toString();

        return params;
    };

    const getListDataCommissionSuccess = (response, key) => {
        const dataReducer = {};

        dataReducer.isLoading = false;
        dataReducer.data = response.data.map((item) => {
            return {
                ...item,
                service_name: item.job.service_name,
                method: item.method?.replace(METHOD_API, METHOD_CONVERT)
            };
        });
        dataReducer.total = response.total;
        handleCreateRowTotal(response[key], COMMISSTION_COLUMN[COMMISSION_TYPE.production], response.total);

        dispatchActionReport({ dataTracking: dataReducer, refreshScreen: refreshScreen + 1 });
    };

    function getListDataCommissionFailed({ message }) {
        dispatchActionReport({ dataTracking: { isLoading: false } });
        refStatusBar.current?.showStatusBar('alert', message, LIST_STATUS.ERROR);
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore);
        getListReport(params);
    };

    function _handleChangeTab(tab) {
        if (currentTab !== tab)
            dispatchActionReport({
                dataTracking: initStateReport.dataTracking,
                user: initStateReport.user,
                lineItem: initStateReport.lineItem,
                currentTab: tab
            });
    }

    const handleCreateRowTotal = (data, columns, total) => {
        const totalColumns = columns.map((column) => {
            if (data[column]) {
                return { id: column, isShow: true, totalAmount: data[column], isCurrency: true };
            }
            return { id: column, isShow: true };
        });
        totalColumns[0].title = `${t('report:total')} ${total || dataTracking.total} ${t('report:commission')}`;
        dispatchActionReport({ rowTotal: totalColumns });
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: REPORT_TYPE.COMMISSIONS,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleClick = ({ key, row }) => {
        switch (key) {
            case COMMON.ITEM_NAME:
            case COMMON.ITEM_VALUE:
            case COMMON.INVOICE_NUMBER:
                dispatch(
                    actionOpenInvoice({
                        id: row.invoice_id,
                        status: row.invoice_status,
                        isRecurring: false
                    })
                );
                break;

            case COMMON.SERVICE_NAME:
                dispatch(actionOpenJobDetail({ id: row.job.id }));
                break;

            default:
                break;
        }
    };

    const _onChangeManage = () => {
        _setDisableButtonSave(true);
        dispatchActionReport({ isManage: !isManage });
    };

    const _setDisableButtonSave = (status = false) => {
        refHeader.current._setDisable(status);
    };

    const _handleSaveSetup = () => {
        refManageCommission.current._onSaveSetup(() => {
            refHeader.current._removeLoading();
            _setDisableButtonSave(true);
            refStatusBar.current.showStatusBar(
                'save_setup_success',
                t('commission:save_setup_successfully'),
                LIST_STATUS.SUCCESS
            );
        });
    };

    function _renderHeaderLeft() {
        return (
            <>
                <ReportDateRangePicker
                    reportType={REPORT_TYPE.COMMISSIONS}
                    onSelect={_handleUpdate}
                    wrapperClassName="header-items"
                />
                <FilterReport
                    reportType={REPORT_TYPE.COMMISSIONS}
                    filters={COMMISSIONS_LIST_FILTER}
                    companyUsers={companyUsers}
                />
                <GdButton onClick={_handleUpdate} className="v2-btn-main header-items">
                    {t('common:update')}
                </GdButton>
            </>
        );
    }

    function _renderHeaderRight() {
        const totalExport = dataTracking.total;
        const paramsReport = getLocalStorage(keyLocalStore) || getDefaultParams(REPORT_TYPE.COMMISSIONS);
        return (
            <Export
                title={t('report:records', { count: totalExport })}
                activePrint
                url={URL_EXPORT_COMMISSION}
                params={{ ...paramsReport, report_type: tabValue }}
                refresh={refreshScreen}
                pageExport={LIST_EXPORT.EXPORT_REPORT_COMMISSION}
            />
        );
    }

    const _renderListTabsFilter = () => {
        return (
            <div className={classNames('tabs', { 'dp-hide': isManage })}>
                <div className="tab-selectors btn-item">
                    {COMMISSIONS_TABS_BUTTON.map((item) => {
                        return (
                            <button
                                key={item.id}
                                className={classNames('tab-items', { 'active-tab-selector': currentTab === item.id })}
                                type="button"
                                onClick={() => _handleChangeTab(item.id)}
                            >
                                {t(`commission:${item.name}`)}
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    const _renderMainReport = () => {
        return (
            <div className="wrap-commission__content content-data tab-contents">
                <div className="tab-conts tab-content-active">
                    <GdGridView
                        isLoading={dataTracking.isLoading}
                        classTable="table-multi-column scrolls-x"
                        classTableContent="--hastotal"
                        content={dataTracking.data}
                        fileTranslation={'commission'}
                        handleClick={_handleClick}
                        handleClickHeader={_handleActionHeader}
                        {...getGridColumns(currentTab, paramsReport?.order, paramsReport?.display_by)}
                        rowTotal={(props) => (
                            <GdGridRowTotal
                                columns={dataReport.rowTotal}
                                contentConfig={getGridColumns(currentTab)?.contentConfig}
                                showCheckBox={false}
                                {...props}
                            />
                        )}
                    />
                </div>
            </div>
        );
    };

    if (!isPermission) {
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    }

    return (
        <div className="container-print accessible-tabs-container">
            <StatusBar ref={refStatusBar} />
            <Header
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                contentTabs={_renderListTabsFilter}
                isManage={isManage}
                onChangeManage={_onChangeManage}
                handleSave={_handleSaveSetup}
                ref={refHeader}
            />

            <div className="wrap-commission">
                {isManage ? (
                    <ManageCommission ref={refManageCommission} setDisableButtonSave={_setDisableButtonSave} />
                ) : (
                    _renderMainReport()
                )}
            </div>
        </div>
    );
};

export default Commission;
