export default function IconSmartView({ isGreen = false, isGrey = false }) {
    if (isGreen) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                ></path>
                <path
                    d="M12 10C12 8.89543 11.1046 8 10 8H21C22.6569 8 24 9.34315 24 11V22.5858C24 23.4767 22.9229 23.9229 22.2929 23.2929L18.7071 19.7071C18.3166 19.3166 17.6834 19.3166 17.2929 19.7071L13.7071 23.2929C13.0771 23.9229 12 23.4767 12 22.5858V10Z"
                    fill="#1EAA5C"
                ></path>
                <path
                    opacity="0.35"
                    d="M8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10V14H8V10Z"
                    fill="#1EAA5C"
                ></path>
            </svg>
        );
    }

    if (isGrey) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <rect width="32" height="32" rx="4" fill="#1C1F22" fillOpacity="0.06"></rect>
                <path
                    d="M12 10C12 8.89543 11.1046 8 10 8H21C22.6569 8 24 9.34315 24 11V22.5858C24 23.4767 22.9229 23.9229 22.2929 23.2929L18.7071 19.7071C18.3166 19.3166 17.6834 19.3166 17.2929 19.7071L13.7071 23.2929C13.0771 23.9229 12 23.4767 12 22.5858V10Z"
                    fill="#5F6164"
                ></path>
                <path
                    opacity="0.35"
                    d="M8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10V14H8V10Z"
                    fill="#5F6164"
                ></path>
            </svg>
        );
    }

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 7C9 5.89543 8.10457 5 7 5V5H16C17.6569 5 19 6.34315 19 8V17.9194C19 18.7579 18.0301 19.2241 17.3753 18.7002L14.6247 16.4998C14.2595 16.2076 13.7405 16.2076 13.3753 16.4998L10.6247 18.7002C9.96993 19.2241 9 18.7579 9 17.9194V7Z"
                stroke="black"
                strokeWidth="1.3"
            ></path>
            <path
                d="M5 7C5 5.89543 5.89543 5 7 5V5C8.10457 5 9 5.89543 9 7V11H5.13636C5.06105 11 5 10.9389 5 10.8636V7Z"
                stroke="black"
                strokeWidth="1.3"
            ></path>
        </svg>
    );
}
