import {
    REPORT_INVOICE_EXPORT,
    URL_REPORT_ACCOUNTS_AGING,
    URL_REPORT_INVOICE_PDF,
    URL_REPORT_INVOICE_PRINT
} from 'app/const/api/Export';
import {
    ACCESS_TOKEN,
    COMMON,
    DEFAULT_ALL,
    KEY_CURRENCY_COLUMNS,
    KEY_REPORT_LOCAL_STORAGE,
    LIST_STATUS
} from 'app/const/App';
import {
    getGridColumns,
    KEY_DAYS_1,
    KEY_DAYS_2,
    KEY_DAYS_3,
    KEY_DAYS_4,
    KEY_DAYS_MAP
} from 'app/const/report/AccountsAging';
import { LIST_EXPORT } from 'app/const/report/Common';
import { ACCOUNTS_AGING_LIST_FILTER, getColumnsParams } from 'app/const/report/ReportFilter';
import { REPORT_BATCH_ACTION_ITEMS, REPORT_LIST_BATCH_ACTIONS, REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { getListReportAccountsAgingRequest } from 'common/redux/actions/reports/accountsAgingAction';
import { getLocalStorage, getLocalStorageValue, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clientQuery } from 'common/utils/ApiUtils';
import { SEND_LATE_PAYMENT_INVOICE_EMAIL, SEND_LATE_PAYMENT_INVOICE_SMS, SEND_STATEMENT_EMAIL } from 'app/const/api/V2';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import classNames from 'classnames';
import { batchActionStatement } from './components/BatchActionFunction';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import CheckBoxHeader from '../components/CheckBoxHeader';
import { getBranchId } from 'app/const/Branch';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const SingleSelect = loadable(() => import('app/components/select/singleSelect'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const AlertCustomer = loadable(() => import('app/modules/customer/components/AlertCustomer'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const ModalLoading = loadable(() => import('app/components/loading/ModalLoading'));
const GDModalWarning = loadable(() => import('app/components/modal/ModalWarning'));

function ReportAccountsAging() {
    const initState = {
        data: [],
        isLoading: true,
        total: 0,
        rowTotal: [],
        checkedItems: { is_check_all: false, ids: [] },
        valueBatchAction: null,
        refreshScreen: 0
    };

    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const [dataReport, dispatchActionReport] = useReducer(reducer, initState);
    const refStatusBar = useRef(null);
    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ACCOUNTS_AGING);
    const paramsReport = getLocalParamsReport(keyLocalStorage, REPORT_TYPE.ACCOUNTS_AGING);

    const { total, data, isLoading, refreshScreen, checkedItems } = dataReport || initState;
    const { columns, order, period_ending: periodEnding } = paramsReport;
    const idsSelected = checkedItems.ids;
    const idsLength = idsSelected?.length || 0;
    const export_limit = useSelector(({ auth }) => auth?.user?.settings?.export_limit);
    const refAlert = useRef(null);
    const refConfirm = useRef(null);
    const refLoading = useRef(null);
    const refWarning = useRef(null);

    useEffect(() => {
        window.sessionStorage.removeItem(KEY_CURRENCY_COLUMNS);
    }, [data]);

    useEffect(() => {
        getListReport(paramsReport);
        return () => {
            window.sessionStorage.removeItem(KEY_CURRENCY_COLUMNS);
        };
    }, []);

    const updateColumns = (filtersParams) => {
        let result = getColumnsParams([REPORT_TYPE.ACCOUNTS_AGING]);
        if (filtersParams && Array.isArray(filtersParams)) {
            result = result.filter((item) => {
                const valueDays = KEY_DAYS_MAP[item];
                if (valueDays) {
                    if (filtersParams.includes(valueDays)) {
                        return true;
                    }
                    return false;
                }
                return true;
            });
        }
        return result;
    };

    const getListReport = (params) => {
        params.filters = params?.filters?.toString() ?? DEFAULT_ALL;
        params.service = params?.service?.toString() ?? DEFAULT_ALL;
        params.email = params?.email?.toString() ?? DEFAULT_ALL;

        delete params?.columns;
        delete params?.currentPage;

        dispatch(getListReportAccountsAgingRequest(params, getListSuccess, getListFailed));
    };

    const createRowTotal = (data, total) => {
        const { columns } = getLocalStorage(keyLocalStorage);
        const totalColumns = columns.map((column) => {
            if (typeof data?.[column] !== 'undefined') {
                return {
                    id: column,
                    isShow: true,
                    isCurrency: true,
                    totalAmount: data[column]
                };
            }
            return { id: column, isShow: true };
        });
        if (!!totalColumns.length) {
            totalColumns[0].title = `${t('report:total')} ${total ?? dataReport?.total} ${t('report:accounts_aging')}`;
        }
        return totalColumns;
    };

    const getListSuccess = (response) => {
        const dataReducer = {
            isLoading: false,
            data: response.data,
            total: response.data.length,
            rowTotal: createRowTotal(response['total_account'], response.data.length),
            refreshScreen: refreshScreen + 1
        };
        dispatchActionReport(dataReducer);
    };

    function getListFailed() {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
        dispatchActionReport({ ...initState, isLoading: false });
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ACCOUNTS_AGING));
        params.columns = updateColumns(params.filters);
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ACCOUNTS_AGING), params);

        dispatchActionReport({ isLoading: true, checkedItems: initState.checkedItems, rowTotal: [], total: 0 });
        getListReport(params);
    };

    const handleBatchActions = (name, value, actionPrint) => {
        if (!idsLength) return false;

        //Create list Customer ID
        const listCustomerIds = data.reduce((result, item) => {
            if (idsSelected.includes(item.id)) {
                return result.concat(item.customer.id);
            }
            return result;
        }, []);
        const filterCustomerIds = [...new Set(listCustomerIds)];

        const listCustomers = data.reduce((result, item) => {
            if (idsSelected.includes(item.id)) {
                return result.concat({ id: item.customer.id, location_id: item.id });
            }
            return result;
        }, []);

        const listInvoiceIds = data.reduce((result, item) => {
            if (idsSelected.includes(item.id)) {
                const idInvoiceThirties = item[KEY_DAYS_1]?.invoice?.map((invoice) => invoice.id);
                const idInvoiceSixTies = item[KEY_DAYS_2]?.invoice?.map((invoice) => invoice.id);
                const idInvoiceNineties = item[KEY_DAYS_3]?.invoice?.map((invoice) => invoice.id);
                const idInvoiceOverNineties = item[KEY_DAYS_4]?.invoice?.map((invoice) => invoice.id);
                return result.concat(idInvoiceThirties, idInvoiceSixTies, idInvoiceNineties, idInvoiceOverNineties);
            }
            return result;
        }, []);
        const totalInvoice = listInvoiceIds.filter((id) => id).length;

        switch (value) {
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_INVOICE:
                handleOnClickExport(listInvoiceIds, actionPrint);
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_INVOICE_PDF:
                totalInvoice > export_limit
                    ? _handleSendInvoices(REPORT_INVOICE_EXPORT, listInvoiceIds) & refWarning.current._open()
                    : handleOnClickExport(listInvoiceIds);
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_PRINT_STATEMENT:
                batchActionStatement({
                    typeExport: COMMON.PRINT,
                    ids: filterCustomerIds,
                    paramsExport: paramsReport,
                    idsLocation: DEFAULT_ALL,
                    typeBatchAction: LIST_EXPORT.EXPORT_ACCOUNT_AGING_BATCH_ACTION,
                    output: COMMON.PRINT
                });
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_GENERATE_STATEMENT_PDF:
                batchActionStatement({
                    typeExport: COMMON.PDF,
                    ids: filterCustomerIds,
                    paramsExport: paramsReport,
                    idsLocation: DEFAULT_ALL,
                    typeBatchAction: LIST_EXPORT.EXPORT_ACCOUNT_AGING_BATCH_ACTION,
                    output: COMMON.PRINT
                });
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER:
                refConfirm.current.open(
                    listInvoiceIds,
                    t('report:confirm_send_invoice', { total: totalInvoice, type: t('report:email') }),
                    t('report:email_late_payment_reminders')
                );
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER:
                refConfirm.current.open(
                    listInvoiceIds,
                    t('report:confirm_send_invoice', { total: totalInvoice, type: t('report:sms') }),
                    t('report:sms_late_payment_reminders')
                );
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT:
                refConfirm.current.open(
                    listCustomers,
                    t('report:confirm_send_email_statement', { total: listCustomers.length }),
                    t('report:email_statements')
                );
                break;
            default:
                break;
        }

        dispatchActionReport({ valueBatchAction: value });
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport({
            checkedItems: stateCheckedItems
        });
    };

    function handleOnClickExport(listInvoiceIds, actionPrint) {
        const token = getLocalStorageValue(ACCESS_TOKEN);
        const form = document.createElement('form');

        form.action = actionPrint ? URL_REPORT_INVOICE_PRINT : URL_REPORT_INVOICE_PDF;
        form.method = 'POST';
        form.style.display = 'none';
        form.className = 'export-invoice-history';

        form.innerHTML = `
            <input name="token" value="${token}" >
            <input name="ids" value="${listInvoiceIds.join().split(',')}" >
            <input name="gd-branch-id" value="${getBranchId()}">
        `;

        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();
        const elements = document.getElementsByClassName('export-invoice-history');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
        return false;
    }

    const _handleSend = (params) => {
        switch (dataReport.valueBatchAction) {
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER:
                _handleSendInvoices(SEND_LATE_PAYMENT_INVOICE_EMAIL, params);
                refLoading.current._open();
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER:
                _handleSendInvoices(SEND_LATE_PAYMENT_INVOICE_SMS, params);
                refLoading.current._open();
                break;
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT:
                _handleSendInvoices(
                    SEND_STATEMENT_EMAIL,
                    params,
                    REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT
                );
                refLoading.current._open();
                break;
            default:
                break;
        }
    };

    const _handleSendInvoices = (url, params, key) => {
        let data = {};

        switch (key) {
            case REPORT_BATCH_ACTION_ITEMS.ACCOUNTS_AGING_EMAIL_STATEMENT:
                data = { customers: params, to_date: periodEnding };
                break;
            default:
                data = { from: 2, ids: params.filter((id) => id) };
                break;
        }

        clientQuery(
            url,
            { data, method: 'POST' },
            (response) => _handleSendInvoiceSuccess(response),
            (response) => _handleSendInvoiceFail(response),
            _handleSendInvoiceFinally
        );
    };

    const _handleSendInvoiceSuccess = (response) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: response.message?.toString() || t('report:send_success'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleSendInvoiceFail = (response) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: response.message?.toString() || t('report:send_fail'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleSendInvoiceFinally = () => {
        refLoading.current._close();
        dispatchActionReport({ checkedItems: initState.checkedItems });
    };

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: total })}
                    activePrint
                    params={paramsReport}
                    url={URL_REPORT_ACCOUNTS_AGING}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_ACCOUNT_AGING}
                    isDisable={isLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.ACCOUNTS_AGING}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    function _renderHeaderBottom() {
        return (
            <div className={classNames('header --filter', { 'is-disable': !idsLength })}>
                <CheckBoxHeader checkedItems={checkedItems} isShowTotal total={total} />
                <SingleSelect
                    options={REPORT_LIST_BATCH_ACTIONS.ACCOUNTS_AGING}
                    title="batch_actions"
                    fileTranslation="report"
                    name="batch_actions"
                    onSelect={handleBatchActions}
                    classWrapper={'header-items'}
                />
            </div>
        );
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.ACCOUNTS_AGING,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleOpenInvoice = (invoiceId) => {
        dispatch(
            actionOpenInvoice({
                id: invoiceId,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };
    const _handleCloseWarning = () => refWarning.current._close();

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.ACCOUNTS_AGING}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <StatusBar ref={refStatusBar} />
                    <AlertCustomer ref={refAlert} />
                    <HeaderBottom
                        typeReport={REPORT_TYPE.ACCOUNTS_AGING}
                        filters={ACCOUNTS_AGING_LIST_FILTER}
                        isLoading={isLoading}
                        handleUpdate={_handleUpdate}
                    />
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={isLoading}
                            classTable="table-multi-column has-checkbox scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={data}
                            showCheckBox
                            fileTranslation={'report'}
                            onOpenInvoice={_handleOpenInvoice}
                            handleClickHeader={_handleActionHeader}
                            checkedItems={dataReport.checkedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGridColumns(order, columns)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGridColumns(null, columns)?.contentConfig}
                                    showCheckBox={true}
                                    {...props}
                                />
                            )}
                        />
                    </div>

                    <GdConfirm
                        ref={refConfirm}
                        title={t('report:email_late_payment_reminders')}
                        listButton={{ cancel: true, confirm: true }}
                        titleConfirm={t('report:send')}
                        onConfirm={_handleSend}
                    />
                    <GDModalWarning
                        ref={refWarning}
                        title={t('report:export')}
                        description={t('report:warning_export_pdf')}
                        footer={
                            <div className="footer-modal btn-close">
                                <span className="v2-btn-default --noborder --label" onClick={_handleCloseWarning}>
                                    {t('common:confirm')}
                                </span>
                            </div>
                        }
                    />
                    <ModalLoading ref={refLoading} />
                </div>
            </div>
        </>
    );
}

export default ReportAccountsAging;
