import classNames from 'classnames';
import React, { Suspense, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import { reducer } from 'app/const/Reducer';
import GridLoading from 'app/components/grid/components/GridLoading';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { formatNumberWithCommas } from 'common/utils/NumberUtils';
import IconMinus from 'assets/icon/IconMinus';
import Tooltip from './Tooltip';
import { NET_CHANGE, getGridColumnStatusChange } from './const';

const DiagramReport = loadable(() => import('./DiagramReport'));

const TableStatusChanges = ({ data = [], isLoading = true }) => {
    const { t } = useTranslation('report');

    const { headers, columns, contentConfig } = getGridColumnStatusChange();
    const refListItem = useRef([]);
    const refIdOpen = useRef('');
    const refTooltip = useRef(null);

    const _handleClickItem = (id) => {
        if (id === refIdOpen.current) return;
        data.forEach((item, index) => {
            if (item.id === refIdOpen.current) {
                refListItem.current[index]?.visibleChart(false);
            }
        });
        refIdOpen.current = id;
    };

    const _displayTooltip = (el, value) => {
        el ? refTooltip.current?.visible({ el, value }) : refTooltip.current?.visible(null);
    };

    const _handleMouseOver = (event) => {
        const { tooltip } = event.target.dataset;
        !!tooltip && _displayTooltip(event?.currentTarget, tooltip);
    };

    const _handleMouseOut = () => {
        _displayTooltip(null);
    };

    const _renderData = () => {
        if (!data.length) return <GridEmpty msgEmpty={t('there_is_no_data')} isFlat />;

        return data.map((item, index) => (
            <RowTable
                ref={(ref) => (refListItem.current[index] = ref)}
                key={item.id}
                item={item}
                contentConfig={contentConfig}
                columns={columns}
                onClickItem={_handleClickItem}
                onMouseOver={_handleMouseOver}
                onMouseOut={_handleMouseOut}
            />
        ));
    };

    const _renderHeaderColumnTable = () => {
        return headers.map(({ id, style, title, tooltip }) => (
            <div key={id} className={style}>
                {!!title ? (
                    <div
                        className="col-label"
                        onMouseOver={_handleMouseOver}
                        onMouseOut={_handleMouseOut}
                        data-tooltip={t(tooltip)}
                    >
                        {t(title)}
                    </div>
                ) : null}
            </div>
        ));
    };

    return (
        <div className="wrap-tables flex-column relative tooltip">
            <div className="tables table-multi-column scrolls-x has-text-ellipsis">
                <div className="rows --fixed --header">{_renderHeaderColumnTable()}</div>
                <div className="tables-list">{isLoading ? <GridLoading /> : _renderData()}</div>
            </div>
            <Tooltip ref={refTooltip} />
        </div>
    );
};

export default TableStatusChanges;

const RowTable = forwardRef(
    (
        {
            item = {},
            columns = [],
            contentConfig = {},
            onClickItem = () => {},
            onMouseOver = () => {},
            onMouseOut = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation('report');

        const { id, name, entered, left, net_change, diagram = [], percent } = item;
        const isGrowth = net_change > 0;

        const [state, dispatchState] = useReducer(reducer, {
            isShowFlowChart: false
        });
        const { isShowFlowChart } = state;

        useImperativeHandle(ref, () => ({
            visibleChart: _handleVisibleChart
        }));

        const _handleVisibleChart = (value = false) => {
            if (!!value) onClickItem(id);
            dispatchState((prev) => ({ ...prev, isShowFlowChart: !!prev.isShowFlowChart ? false : value }));
        };

        const _handleRenderFlowChart = () => {
            if (!diagram?.length) return <p className="empty">{t('desc_empty_flow_chart')}</p>;

            return (
                <DiagramReport
                    diagramData={diagram.map((item) => {
                        if (!item.prev_pipeline_id) {
                            (item.prev_pipeline_id = 'create_id'), (item.prev_pipeline_name = t('created'));
                        }
                        return item;
                    })}
                    activeId={id}
                />
            );
        };

        const _renderNetChangeColumn = (isShowNetChangePercent = false) => {
            const finalPercent = `${Math.abs(percent)}%`;

            const _renderContent = () => {
                if (!!isShowNetChangePercent) return percent !== 0 ? finalPercent : null;
                return !!net_change ? net_change : <IconMinus />;
            };

            const _renderTooltip = () => {
                return t(
                    percent === 0
                        ? 'tooltip_net_change_value'
                        : isGrowth
                          ? 'tooltip_net_change_increase_value'
                          : 'tooltip_net_change_decrease_value',
                    {
                        quantity: Math.abs(net_change),
                        status: name,
                        percent: finalPercent,
                        entered: formatNumberWithCommas(entered),
                        left: formatNumberWithCommas(left)
                    }
                );
            };

            return (
                <div
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    data-tooltip={_renderTooltip()}
                    className="tooltip"
                >
                    {_renderContent()}
                </div>
            );
        };

        const _renderContentColumn = () => {
            return columns.map((key) => {
                const { id: columnId, style, className, isShowTooltip, icon } = contentConfig[key];

                const finalContent = item[columnId];

                if (key === NET_CHANGE) {
                    return (
                        <div key={key} className={classNames(style, { '--reduce': !isGrowth })}>
                            <div className="txt-ellipsis">
                                {!!net_change ? _renderNetChangeColumn() : <IconMinus />}
                            </div>
                            <div className="flexcenter gap-2 net-percent">{_renderNetChangeColumn(true)}</div>
                        </div>
                    );
                }

                return (
                    <div key={key} className={style}>
                        {icon}
                        {!!columnId ? (
                            <div className={className}>
                                <div
                                    onMouseOver={onMouseOver}
                                    onMouseOut={onMouseOut}
                                    className={classNames('txt-ellipsis', { tooltip: isShowTooltip })}
                                    data-tooltip={
                                        isShowTooltip
                                            ? t(`tooltip_${columnId}_value`, {
                                                  quantity: formatNumberWithCommas(finalContent),
                                                  status: name
                                              })
                                            : null
                                    }
                                    title={!isShowTooltip ? finalContent : null}
                                >
                                    {finalContent}
                                </div>
                            </div>
                        ) : null}
                    </div>
                );
            });
        };

        return (
            <Suspense fallback={<GridLoading />}>
                <div
                    className={classNames('rows', { 'is-chart': isShowFlowChart })}
                    onClick={() => _handleVisibleChart(true)}
                >
                    {_renderContentColumn()}
                </div>
                {isShowFlowChart ? (
                    <div className="rows">
                        <div className="col-chart">{_handleRenderFlowChart()}</div>
                    </div>
                ) : null}
            </Suspense>
        );
    }
);
