import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';

import Loading from './Loading';
import SettingsContent from './SettingsContent';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_SMS_AFTER_HOURS } from 'app/const/Api';
import LivePreview from './LivePreview';

const SettingsAfterHours = (props, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isLoading: true });
    const { isOpen, isLoading, data } = state;
    const refPreview = useRef(null);

    useImperativeHandle(ref, () => ({ _open }));

    const _open = (data) => {
        dispatchState((prevState) => ({ ...prevState, data, isLoading: true, isOpen: true }));
        clientQuery(ADDONS_SMS_AFTER_HOURS, { method: 'GET' }, _getDetailSuccess);
    };

    const _getDetailSuccess = ({ data }) => {
        dispatchState((prevState) => ({ ...prevState, data, isLoading: false }));
    };

    const _close = () => {
        dispatchState((prevState) => ({ ...prevState, isOpen: false }));
    };

    const _handlePreview = () => {
        refPreview.current && refPreview.current._open();
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-has-preview is-preview-template open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --preview-r">
                <div className="wrap-container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <SettingsContent data={data} onClose={_close} onPreview={_handlePreview} />
                            <LivePreview ref={refPreview} />
                        </>
                    )}
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(SettingsAfterHours);
