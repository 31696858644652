import { CHANGE_PASSWORD } from 'app/const/Api';
import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER } from 'app/modules/calendar/const/BookMark';
import { AUTH_TYPE } from 'common/redux/actions/authAction';
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';
import { MAP_ACTIONS } from 'common/redux/actions/map';
import { postFetchWithoutToken } from 'common/utils/ApiUtils';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { call, put } from 'redux-saga/effects';

export function* userChangePassword({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, CHANGE_PASSWORD, payload, put);
    if (response && response.success) {
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);
        const calendar = response?.calendar;
        if (!calendar) {
            actionSuccess(response);
            return;
        }
        yield put({
            type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
            payload: calendar
        });
        yield put({
            type: MAP_ACTIONS.UPDATE_MAP_SIZE,
            payload: {
                heightofmap: calendar?.heightofmap,
                widthofmap: calendar?.widthofmap,
                statusofmap: calendar?.statusofmap,
                typeofmap: calendar?.typeofmap,
                dayofmap: calendar?.dayofmap,
                openmap: calendar?.openmap
            }
        });
        yield put({ type: AUTH_TYPE.LOGIN_USER_SUCCESS, payload: response });
        actionSuccess(response);
    } else {
        yield put({ type: AUTH_TYPE.CHANGE_PASSWORD_USER_ERROR, payload: response });
        actionFailed(response);
    }
}
