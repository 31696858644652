import React from 'react';

const LoadingPlan = () => {
    return (
        <div className="page-plans">
            <div className="loading --animation --line --twothird --light"></div>
            <div className="boxs boxs--no-border mt-2">
                <div className="boxs tabs-plan">
                    <div className="wrap-loading">
                        <div className="title-header tabs">
                            <div className="title-header__label loading --onetenth">
                                <div className="loading --animation --line --full"></div>
                            </div>
                            <div className="v2-btn-default --grey loading --onefourth">
                                <div className="loading --animation --line --threefourth"></div>
                                <div className="loading --animation --line --threefourth mt-0 ml-2"></div>
                            </div>
                            <div className="plan-price">
                                <div className="btn-status bg-white-cultured btn-sm"></div>
                                <div className="loading --onefifth">
                                    <div className="loading --animation --line --full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading">
                        <div className="tab-contents tabs">
                            <div
                                className="tab-conts tab-content-active w-100"
                                data-id="6"
                                tabIndex="0"
                                role="tabpanel"
                                id="tab-content-1161_6_5"
                            >
                                <div className="slider flex-1 mt-6">
                                    <div className="loading --animation --line --full"></div>
                                </div>
                                <div className="slider flex-1 mt-6">
                                    <div className="loading --animation --line --full"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="plans-accout">
                    <div className="wrap-loading boxs box-plans">
                        <div className="header-box">
                            <div className="check-items mt-0">
                                <div className="checkbox"></div>
                                <div className="item-checkbox"></div>
                            </div>
                            <div className="btn-status bg-white-cultured btn-x-sm"></div>
                        </div>
                        <div className="conts-box">
                            <div className="flex-1">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --twothird"></div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading boxs box-plans">
                        <div className="header-box">
                            <div className="check-items mt-0">
                                <div className="checkbox"></div>
                                <div className="item-checkbox"></div>
                            </div>
                            <div className="btn-status bg-white-cultured btn-x-sm"></div>
                        </div>
                        <div className="conts-box">
                            <div className="flex-1">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --twothird"></div>
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading boxs box-plans">
                        <div className="header-box">
                            <div className="check-items mt-0">
                                <div className="checkbox"></div>
                                <div className="item-checkbox"></div>
                            </div>
                            <div className="btn-status bg-white-cultured btn-x-sm"></div>
                        </div>
                        <div className="conts-box">
                            <div className="flex-1">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --fourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --threefourth"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --half"></div>
                            </div>
                            <div className="flex-1 mt-5">
                                <div className="loading --animation --line --twothird"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPlan;
