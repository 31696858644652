import { ABILITY_PHOTO_ACTION } from '../actions/photosAction';
const photosReducer = (
    state = {
        toggleInsert: null
    },
    action
) => {
    switch (action.type) {
        case ABILITY_PHOTO_ACTION.TOGGLE_IMPORT_JOB_IMAGES:
            return { ...state, toggleInsert: action.params };
        default:
            return state;
    }
};

export default photosReducer;
