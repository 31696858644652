import React from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'app/components/input/Input';

const TrialStepThree = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    handleFocus = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);

    return (
        <div className="box-input">
            <Input
                placeholder={t('how_did_you_find_out_about_the_gorilladesk')}
                type="text"
                name="source"
                initValue={infoTrial?.source}
                classWrapper="user-box rows"
                error={error?.source}
                isShowIconError={false}
                onFocus={handleFocus}
                onChange={onChangeInput}
                handleKeypress={onKeyPress}
            />
        </div>
    );
};

export default TrialStepThree;
