import { UPDATE_ADDONS_MATERIAL_OPTIONS } from 'app/const/Api';
import { ADDON_MATERIAL_USAGE_DETAIL } from 'app/const/api/Material';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer } from 'react';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import Column from './column';
import HeaderItem from './components/HeaderItem';
import Loading from './components/Loading';
import { reducer } from 'app/const/Reducer';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

export default function AddonsMaterialUsage() {
    const [state, dispatchState] = useReducer(reducer, { isLoading: true });

    useEffect(() => {
        _getMaterialDetail();
    }, []);

    function _getMaterialDetail() {
        clientQuery(ADDON_MATERIAL_USAGE_DETAIL, { method: 'GET' }, _getMaterialDetailSuccess, checkAccessFail);
    }

    function _getMaterialDetailSuccess(response) {
        dispatchState({
            ...response.data,
            isLoading: false
        });
    }

    if (state.isLoading) {
        return <Loading />;
    }

    function _renderColumns() {
        return state.materials.map((itemColumn) => {
            return <Column key={itemColumn.id} data={itemColumn} />;
        });
    }

    return (
        <div className="addons-wrap__container addons-wrap__material">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper material-content pb-0">
                    <div className="boxs boxs--no-border --hasline">
                        <AddonsBoxHeader
                            keyword={state.keyword}
                            name={state.name}
                            upgrade={state.upgrade}
                            status={state.status}
                            level={state.level}
                            urlUpdateStatus={UPDATE_ADDONS_MATERIAL_OPTIONS}
                        />
                        <HeaderItem options={state.options} />
                    </div>
                </div>
                <div className="boxs-wrapper material-content-full">{_renderColumns()}</div>
            </div>
        </div>
    );
}
