import classNames from 'classnames';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { AddCustomerContext } from '../../contexts';
import BoxRows from '../BoxRows';
import AddressDropdown from './AddressDropdown';
import AddressInfo from './AddressInfo';
import { useTranslation } from 'react-i18next';

const AddressBilling = ({ isSame = false, hideSame = false, isFastForm = false, ...props }, ref) => {
    const { t } = useTranslation(['customers']);
    const refAddress = useRef(null);
    const refAddressInfo = useRef(null);
    const { service_location } = useContext(AddCustomerContext);
    const shouldHide = isSame && (isFastForm || hideSame);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    useEffect(() => {
        if (refAddressInfo.current) {
            refAddressInfo.current.setValue({
                ...service_location.billing_address,
                name: service_location.billing_address.street1
            });
        }
    }, [service_location]);

    const _handleGetValue = () => {
        if (!refAddressInfo.current) return {};
        return {
            billing_address: {
                bill_to: refAddress.current.getValue()?.full_name || '',
                ...refAddressInfo.current.getValue()
            }
        };
    };

    return (
        <>
            <BoxRows label={t('customers:bill_to')} rowClass={classNames({ 'dp-hide': shouldHide })}>
                <AddressDropdown ref={refAddress} id="add_customer_billing_to" isSame={isSame} />
            </BoxRows>
            <BoxRows
                label={t('customers:address')}
                rowClass={classNames({ 'dp-hide': shouldHide })}
                labelClass="flexcenter"
                fieldClass="has-many-address"
            >
                <AddressInfo ref={refAddressInfo} type="billing" isBillingCustomer {...props} />
            </BoxRows>
        </>
    );
};

export default forwardRef(AddressBilling);
