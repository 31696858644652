export const TYPE_CONTACT = {
    GET_LIST_CONTACT: 'GET_LIST_CONTACT',
    DELETE_CONTACTS: ' DELETE_CONTACTS',
    CREATE_CONTACT: 'CREATE_CONTACT',
    UPDATE_CONTACT: 'UPDATE_CONTACT'
};

export const getListContact = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CONTACT.GET_LIST_CONTACT,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createContact = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CONTACT.CREATE_CONTACT,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateContact = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CONTACT.UPDATE_CONTACT,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteContacts = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CONTACT.DELETE_CONTACTS,
        params,
        actionSuccess,
        actionFailed
    };
};
