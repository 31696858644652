import RenderAvatar from 'app/components/avatar/RenderAvatar';
import { reducer } from 'app/const/Reducer';
import {
    ADDONS_VOIP_GROUP,
    ADDONS_VOIP_PERSONAL,
    SOURCE_PHONE_NUMBER_VOIP,
    TYPE_MANAGE_ADDONS_VOIP
} from 'app/const/addons';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { AddonsManageContext } from '../VOIPAddonsManageProvider';
import { ACCOUNT_ROLE } from 'app/const/App';

const HeaderSettings = ({ data, listData = [] }, ref) => {
    const { t } = useTranslation('addons');
    const refCurrentData = useRef({ ...data });

    const { assignees, role, type, phone_number, source } = data;
    const { activedUsers } = useContext(AddonsManageContext);

    const [state, dispatchState] = useReducer(reducer, {
        assignees,
        role
    });
    const { assignees: finalAssign } = state;
    const { [ADDONS_VOIP_PERSONAL]: typePersonal, [ADDONS_VOIP_GROUP]: typeGroup } = TYPE_MANAGE_ADDONS_VOIP;

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _getValue = () => {
        const { assignees, role, name } = refCurrentData.current;
        return {
            assignees,
            role,
            name
        };
    };

    const _renderBox = () => {
        if (role !== ACCOUNT_ROLE.SUPERADMIN || type === typeGroup || source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) {
            return (
                <div className="box-voip__rows">
                    <b className="fw-600">{formatPhoneNumberVoip(phone_number)}</b>
                </div>
            );
        }

        switch (type) {
            case typePersonal:
                if (role !== ACCOUNT_ROLE.SUPERADMIN) return null;

                return (
                    <div className="box-voip__rows d-flex gap-8">
                        <div className="flex-column gap-2 flex-1">
                            <span>{t('addons:user')}</span>
                            <CalendarDropdown
                                id={'personal_user'}
                                options={_options()}
                                selectedOption={{ id: finalAssign[0] ?? null }}
                                selected={_selected()}
                                keyGetName="label"
                                keyGetValue="value"
                                keyGetKey="id"
                                onSelect={_handleSelect}
                                spanItemClassName="flex-1"
                                wrapperListClass="v2-dropdown__menu content-user scrolls"
                            />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    const _handleSelect = ({ key, value }) => {
        dispatchState({ [key]: value });
        refCurrentData.current[key] = value;
    };

    const _options = () => {
        return activedUsers
            .filter(
                (user) =>
                    !listData.some((item) => {
                        return item.id == user.id;
                    })
            )
            .map((user) => {
                const { id, full_name } = user;
                const userId = id * 1;
                return {
                    id: userId,
                    value: { key: 'assignees', value: [userId] },
                    label: (
                        <div key={`${(full_name, userId)}`} className="user-name">
                            <RenderAvatar userData={user} />
                            <span className="txt-ellipsis">{full_name}</span>
                        </div>
                    )
                };
            });
    };

    const _selected = () => {
        const full_name = activedUsers.find(
            (item) => !!finalAssign.length && item.id * 1 === finalAssign[0]
        )?.full_name;
        return full_name;
    };

    return _renderBox();
};

export default forwardRef(HeaderSettings);
