import { CUSTOMER_ACTIONS } from 'common/redux/actions/customer';

const createCustomer = (
    state = {
        createCustomerData: { isVisibleModal: false, isGetAllDataSuccess: false, onSubmit: () => {}, onClose: () => {} }
    },
    action
) => {
    switch (action.type) {
        case CUSTOMER_ACTIONS.CREATE_CUSTOMER:
            return { ...state, createCustomerData: action.payload || null };
        default:
            return state;
    }
};

export default createCustomer;
