import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

const RealtimeEstimate = ({ onUpdate = () => {} }) => {
    const { estimateStatus } = useContext(SocketContext);

    useEffect(() => {
        if (!!estimateStatus) {
            onUpdate(estimateStatus);
        }
    }, [estimateStatus]);
};
export default RealtimeEstimate;
