export const PHONE_TYPE_ACTIONS = {
    GET_LIST_PHONE_TYPES: 'GET_LIST_PHONE_TYPES',
    UPDATE_PHONE_TYPES: 'UPDATE_PHONE_TYPES',
    DELETE_PHONE_TYPES: 'DELETE_PHONE_TYPES',
    SET_STORE_LIST_PHONE_TYPES: 'SET_STORE_LIST_PHONE_TYPES',
    ADD_PHONE_TYPES: 'ADD_PHONE_TYPES',
    CALL_API_FAILURE: 'CALL_API_FAILURE'
};

export const getPhoneTypes = (actionSuccess, actionFailed) => {
    return {
        type: PHONE_TYPE_ACTIONS.GET_LIST_PHONE_TYPES,
        actionSuccess,
        actionFailed
    };
};

export const updatePhoneTypes = (payload) => {
    return {
        type: PHONE_TYPE_ACTIONS.UPDATE_PHONE_TYPES,
        payload
    };
};

export const deletePhoneTypes = (payload) => {
    return {
        type: PHONE_TYPE_ACTIONS.DELETE_PHONE_TYPES,
        payload
    };
};

export const actionSetStorePhoneTypes = ({ phoneTypes, phoneSelect }) => {
    return {
        type: PHONE_TYPE_ACTIONS.SET_STORE_LIST_PHONE_TYPES,
        payload: { phoneTypes, phoneSelect }
    };
};

export const addPhoneTypes = (payload) => {
    return {
        type: PHONE_TYPE_ACTIONS.ADD_PHONE_TYPES,
        payload
    };
};
