import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ACCOUNT_ROLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { checkPermission } from 'common/utils/PermissionUtils';

const LIMIT_AVATAR = 14;

const ListAvatar = ({ data = [] }) => {
    return data.map((item) => {
        const { id, full_name, avatar } = item;
        return (
            <span key={id} className="avt-img tooltip">
                <img src={avatar} alt={full_name} width={22} height={22} />
                <span className="tooltiptext top">{full_name}</span>
            </span>
        );
    });
};
export const ListItemsUsers = ({ data = [] }) => {
    const length = data?.length || 0;
    return (
        <div className="wrap-member-group flexcenter flex-1">
            <ListAvatar data={[...(data || [])].splice(0, LIMIT_AVATAR)} />
            {length > LIMIT_AVATAR && <span className="avt-img avt-more fs-10 fw-500">{length - LIMIT_AVATAR}</span>}
        </div>
    );
};

const CustomFieldsFooter = ({ keyword = '', upgrade, pathManage, items = null }) => {
    const { t } = useTranslation('addons');
    const customFieldsAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const userRole = useSelector(({ auth }) => auth.user.profile.role);

    const havePermissionSetting = !!customFieldsAddon && checkPermission(permissionsList, ACCESS_SETTINGS_TAB);
    const isDisableSetting = !havePermissionSetting || !!upgrade;
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const isDisable =
        isDisableSetting ||
        (!isSupperAdmin && keyword === ADDONS_FOOTER_OPTIONS.PERMISSIONS) ||
        !!!customFieldsAddon[keyword];

    return (
        <div className="boxs__footer">
            {items && !isNaN(items) ? <p className="grey-extrlight txt-ellipsis flex-1">{items} fields</p> : null}
            {items && isNaN(items) ? <ListItemsUsers data={items} /> : null}
            <Link
                to={addBranchPath(pathManage)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': isDisable
                })}
            >
                {t('manage')}
                <IconCogWheel />
            </Link>
        </div>
    );
};

export default CustomFieldsFooter;
