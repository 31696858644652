import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { reducer } from 'app/const/Reducer';
import VOIPVoicemailDrop from '../VOIPVoicemailDrop';

const VoicemailSettings = ({ voicemail = {}, required = false, msgError, onDisableButtonSave = () => {} }, ref) => {
    const { t } = useTranslation();
    const refCurrentData = useRef({ ...voicemail });
    const refVoicemail = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isActive: !!voicemail.enable,
        ...voicemail,
        greetingText: voicemail.greeting_text,
        objectKey: null
    });
    const {
        isActive,
        enable,
        greeting: finalGreeting,
        greetingText: finalGreetingText,
        objectKey: finalObjectKey
    } = state;

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _getValue = async () => {
        const { enable } = refCurrentData.current;
        const { greeting, greetingText, objectKey } = refVoicemail.current ? refVoicemail.current.getValue() : {};
        return {
            voicemail: enable * 1,
            greeting: enable ? greeting : finalGreeting,
            greeting_text: enable ? greetingText : finalGreetingText,
            objectKey: objectKey || finalObjectKey
        };
    };

    const _handleChange = (value) => {
        refCurrentData.current.enable = value ? 1 : 0;
        const newGreeting = !value ? refVoicemail.current.getValue() : {};
        dispatchState((prev) => ({ ...prev, isActive: value, ...newGreeting }));
    };

    return (
        <div className="box-voip__rows flexcenter flex-wrap is-enabled">
            <p className="flex-1 fw-500">{t('addons:voicemail_greeting')}</p>
            <SwitchCheckbox
                id="disable_voicemail_setting"
                textChecked={t('addons:enabled')}
                textUnChecked={t('addons:disabled')}
                defaultChecked={!!enable}
                onChangeImmediately={_handleChange}
                timeOut={0}
            />
            <div className="detail-enable flex-column gap-8 w-100 mt-2">
                <p>{t('addons:desc_voicemail_greeting_settings')}</p>
                {!!isActive && (
                    <VOIPVoicemailDrop
                        ref={refVoicemail}
                        greeting={finalGreeting}
                        greetingText={finalGreetingText}
                        required={required}
                        msgError={msgError}
                        isUseTyping
                        isHideOnNull
                        isUploadS3OnSuccess
                        onUploadFile={() => onDisableButtonSave(true)}
                        onUploadSuccess={() => onDisableButtonSave(false)}
                    />
                )}
            </div>
        </div>
    );
};

export default forwardRef(VoicemailSettings);
