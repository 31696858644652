import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { VOIPContext } from './VOIPProvider';

const ServiceSettingVoip = ({ keyFeature, onTrigger = () => {}, isAllFeature = false }, ref) => {
    const { features = {}, isFetched, onFetchSetting } = useContext(VOIPContext);
    const finalKeyFlag = features?.[keyFeature];

    useEffect(() => {
        if (isFetched) {
            !!isAllFeature ? onTrigger(features) : !!keyFeature ? onTrigger(finalKeyFlag) : null;
        }
    }, [isFetched, finalKeyFlag]);

    useImperativeHandle(ref, () => ({
        checkHavePermission,
        onFetchSetting
    }));

    const checkHavePermission = (key) => {
        return !!features[key];
    };
};

export default forwardRef(ServiceSettingVoip);
