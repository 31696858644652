import { reducer } from 'app/const/Reducer';
import IconTrash from 'assets/icon/IconTrash';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';

const ListCard = (props, ref) => {
    const [state, dispatchState] = useReducer(reducer, { data: [] });
    const { data } = state;

    useImperativeHandle(ref, () => ({ handleAdd: _handleAdd, getValue: _handleGetValue }));

    const _handleGetValue = () => {
        return data.map((item) => ({ ...item, account_number: item?.last_4 }));
    };

    const _handleAdd = (newCard) => dispatchState({ data: [...data, newCard] });
    const _handleDelete = (token) => dispatchState({ data: [...data].filter((item) => item.token !== token) });

    if (!data.length) return null;
    return data.map((item) => <CardItem key={item.id} onDelete={_handleDelete} {...item} />);
};

const CardItem = ({ name, token, account_number, exp_month, exp_year, onDelete = () => {} }) => {
    return (
        <div className="has-many-field__items">
            <div className="info-card flex-1">
                <div className="card-number">
                    <p className="card-number__label">{name}</p>
                    <div className="number">
                        <p className="number__digit txt-ellipsis">{account_number}</p>
                    </div>
                    <span className="date">
                        {exp_month}/{String(exp_year).slice(-2)}
                    </span>
                </div>
            </div>
            <div className="v2-btn-default --delete --icon-lg" onClick={() => onDelete(token)} tabIndex="0">
                <IconTrash />
            </div>
        </div>
    );
};

export default forwardRef(ListCard);
