import { EVENT_ACTIONS } from 'common/redux/actions/event';

const initialState = { data: null, isEdit: false, newEventData: null };

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_ACTIONS.OPEN_ADD_EVENT_MODAL:
            return { ...state, isEdit: false, data: { ...(action.payload || {}) } };
        case EVENT_ACTIONS.OPEN_EDIT_EVENT_MODAL:
            return { ...state, isEdit: true, data: { ...(action.payload || {}) } };
        case EVENT_ACTIONS.CLOSE_EVENT_MODAL:
            return { ...state, isEdit: false, data: null };
        case EVENT_ACTIONS.CREATE_EVENT_SUCCESS:
            return { ...state, newEventData: { ...(action.payload || {}) } };
        default:
            return state;
    }
};

export default eventReducer;
