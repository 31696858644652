export function handlePlacesAddressComponent(addressComponent) {
    const result = { zip: null, state: null, city: null, nameInput: '', country: '', county: '' };
    if (!addressComponent) return result;
    for (let i = 0; i < addressComponent.length; i++) {
        for (let j = 0; j < addressComponent[i].types.length; j++) {
            const type = addressComponent[i].types[j];
            switch (type) {
                case 'postal_code':
                    result['zip'] = addressComponent[i].long_name;
                    break;
                case 'administrative_area_level_1':
                    result['state'] = addressComponent[i].short_name;
                    break;
                case 'administrative_area_level_2':
                    result['county'] = addressComponent[i].short_name;
                    break;
                case 'locality':
                    result['city'] = addressComponent[i].short_name;
                    break;
                case 'sublocality_level_1':
                    if (!result['city']) result['city'] = addressComponent[i].short_name;
                    break;
                case 'street_number':
                    result['nameInput'] += `${addressComponent[i].long_name} `;
                    break;
                case 'route':
                    result['nameInput'] += addressComponent[i].long_name;
                    break;
                case 'country':
                    result['country'] += addressComponent[i].long_name;
                    break;
                default:
                    break;
            }
        }
    }
    return result;
}
