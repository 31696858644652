import React from 'react';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import IconCalendar from 'assets/icon/IconCalendar';
import IconFuture from 'assets/icon/IconFuture';
import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import { convertTimeToISO } from 'common/utils/DateUtils';

const InvoiceDate = ({ date, invoiceRecurring = false, onChange }) => {
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);

    function _renderDate() {
        const newDate = moment(date).utc();
        const formatDisplay = convertPhpFormatToMoment(date_format);
        return newDate.format(formatDisplay);
    }

    function _handleChangeDate(date) {
        onChange(convertTimeToISO(moment(date)));
    }

    const _renderInput = () => {
        if (invoiceRecurring) {
            return (
                <div className="details detail-select-date relative">
                    <CalendarDatePicker onChange={_handleChangeDate} selectDefault={date} />
                </div>
            );
        }

        return (
            <div className={'details detail-select-date relative'}>
                <input className="field-input" type="text" disabled={!!!invoiceRecurring} value={_renderDate()} />
                <span className="svg-calendar-absolute">
                    <IconFuture />
                </span>
            </div>
        );
    };

    return (
        <div className="rows --inv-date row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconCalendar />
                Invoice Date
            </div>
            {_renderInput()}
        </div>
    );
};

export default InvoiceDate;
