import React from 'react';

const LoadingSideMenu = () => {
    return (
        <div className="sidebar-details wrap-loading --new-customer --view-task">
            <div className="wrap-filter flexcenter pointer-events-none">
                <div className="v2-dropdown wrap-filter__location is-lg flex-1 bg-white px-2">
                    <div className="filter-btn flexcenter gap-4 h-100">
                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                        <div className="filter-btn__info flex-column flex-1">
                            <span className="loading --animation --line --threefourth" />
                            <span className="loading --animation --line --threefourth mt-1" />
                            <span className="loading --animation --line --full mt-1" />
                        </div>
                        <span className="v2-btn-default loading bg-white-cultured arrow" />
                    </div>
                </div>
                <div className="v2-dropdown wrap-filter__mdu is-sm flex-1 bg-white px-2">
                    <div className="filter-btn flexcenter gap-4 cursor-pointer h-100">
                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                        <div className="filter-btn__info flex-column flex-1">
                            <span className="loading --animation --line --half" />
                            <span className="loading --animation --line --threefourth mt-1" />
                        </div>
                        <span className="v2-btn-default loading bg-white-cultured arrow" />
                    </div>
                </div>
            </div>
            <div className="scrolls overflow-hidden">
                <div className="details-info flexcenter flex-wrap gap-16 bg-white pb-12 overflow-hidden">
                    <div className="flex-1 mt-1">
                        <div className="loading --animation --line --threefourth mb-2" />
                        <div className="loading --animation --line --half mb-1" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="d-flex gap-4 w-100">
                        <div className="v2-btn-default field-h24 --grey loading --half">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default field-h24 --grey loading --half">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="v2-dropdown tags list-add-tags">
                        <div className="tags__label flex-betweenitems">
                            <div className="title-sx flexcenter gap-4 w-100">
                                <div className="loading --animation --line --twothird" />
                                <div className="v2-btn-default loading --icon-sm bg-white-cultured --onefourth" />
                            </div>
                        </div>
                        <div className="tags__info d-flex flex-wrap">
                            <div className="tag-label loading --twothird" />
                            <div className="tag-label loading --onefifth" />
                            <div className="tag-label loading --twothird" />
                        </div>
                    </div>
                    <div className="info-card loading --threefourth">
                        <div className="card-number w-100">
                            <div className="loading --animation --line" />
                            <span className="date flex-1" />
                        </div>
                    </div>
                    <div className="price bg-white">
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --onefourth" />
                            <div className="loading --animation --line --fourth mt-1" />
                        </div>
                        <div className="flex-betweenitems mt-1">
                            <div className="loading --animation --line --onefourth" />
                            <div className="loading --animation --line --twothird mt-1" />
                        </div>
                    </div>
                </div>
                <div className="sidebar-details__content overflow-hidden">
                    <div className="details-info wrap-item is-open">
                        <div className="wrap-item__header flex-betweenitems field-h32">
                            <div className="flex-1">
                                <div className="loading --animation --line --twothird" />
                            </div>
                            <div className="v2-btn-default loading bg-white-cultured --icon-sm mr-2" />
                            <div className="pl-2 border-left-border-color-grey field-h32">
                                <div className="v2-btn-default loading bg-white-cultured --icon-sm " />
                            </div>
                        </div>
                        <div className="wrap-item__content flex-column gap-6 tasks-list">
                            <div className="box-task">
                                <div className="title mb-2">
                                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                    <div className="avt-img" />
                                    <div className="title__info">
                                        <div className="name">
                                            <div className="loading --animation --line --twothird" />
                                        </div>
                                        <div className="time">
                                            <div className="loading --animation --line --threefourth mt-1" />
                                        </div>
                                    </div>
                                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                </div>
                                <div className="time">
                                    <div className="loading --animation --line --twothird my-1" />
                                </div>
                                <div className="v2-dropdown tags list-add-tags">
                                    <div className="tags__info d-flex flex-wrap">
                                        <div className="tag-label loading --twothird" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-info wrap-item is-open">
                        <div className="wrap-item__header flex-betweenitems field-h32">
                            <div className="flex-1">
                                <div className="loading --animation --line --twothird" />
                            </div>
                            <div className="v2-btn-default loading bg-white-cultured --icon-sm mr-2" />
                            <div className="pl-2 border-left-border-color-grey field-h32">
                                <div className="v2-btn-default loading bg-white-cultured --icon-sm " />
                            </div>
                        </div>
                        <div className="wrap-item__content flex-column gap-6 tasks-list">
                            <div className="box-task box-opportunity border-none pl-1">
                                <div className="title mb-2">
                                    <div className="avt-img ml-0" />
                                    <div className="title__info">
                                        <div className="name">
                                            <div className="loading --animation --line --twothird" />
                                        </div>
                                        <div className="time">
                                            <div className="loading --animation --line --threefourth mt-1" />
                                        </div>
                                    </div>
                                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                </div>
                                <div className="ml-2">
                                    <div className="valid-phone">
                                        <div className="valid-phone__btn loading --twothird" />
                                    </div>
                                </div>
                                <div className="contact flex-betweenitems my-3">
                                    <div className="loading --animation --line --twothird" />
                                    <div className="d-flex gap-4">
                                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                    </div>
                                </div>
                                <div className="flex-column gap-4">
                                    <div className="loading --animation --line --full" />
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="details-info wrap-item is-open">
                        <div className="wrap-item__header flex-betweenitems field-h32">
                            <div className="loading --animation --line --twothird" />
                            <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                        </div>
                        <div className="wrap-item__content flex-column gap-10">
                            <div className="flex-column gap-2">
                                <div className="loading --animation --line --twothird mt-1" />
                                <div className="loading --animation --line --twothird mt-1" />
                                <div className="valid-phone">
                                    <div className="valid-phone__number loading --animation --line --fourth" />
                                    <div className="valid-phone__btn loading --onefourth" />
                                </div>
                                <div className="loading --animation --line --threefourth mt-1" />
                            </div>
                            <div className="flex-column gap-2">
                                <div className="loading --animation --line --twothird mt-1" />
                                <div className="loading --animation --line --twothird mt-1" />
                                <div className="valid-phone">
                                    <div className="valid-phone__number loading --animation --line --fourth" />
                                    <div className="valid-phone__btn loading --onefourth" />
                                </div>
                                <div className="loading --animation --line --threefourth mt-1" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-locations">
                        <div className="details-info wrap-location">
                            <div className="wrap-item__header flex-betweenitems field-h32">
                                <div className="loading --animation --line --twothird" />
                                <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                            </div>
                            <div className="wrap-collapse__details flex-column gap-10">
                                <div className="address word-break flex-column gap-8">
                                    <div className="loading --animation --line --twothird mt-1" />
                                    <p className="address__info purple-default"></p>
                                    <div className="loading --animation --line --threefourth" />
                                    <div className="loading --animation --line --half" />
                                    <p />
                                </div>
                                <div className="wrap-note wrap-collapse is-open">
                                    <div className="note-header p-0 flex-betweenitems js-toggle-notes">
                                        <div className="loading --animation --line --twothird mt-1" />
                                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                                    </div>
                                    <div className="note-details wrap-collapse__details p-0">
                                        <div className="loading --animation --line --half mt-1" />
                                    </div>
                                </div>
                                <div className="v2-dropdown tags list-add-tags">
                                    <div className="tags__label flex-betweenitems">
                                        <div className="title-sx flexcenter gap-4 w-100">
                                            <div className="loading --animation --line --twothird" />
                                            <div className="v2-btn-default loading --icon-sm bg-white-cultured --onefourth" />
                                        </div>
                                    </div>
                                    <div className="tags__info d-flex flex-wrap">
                                        <div className="tag-label loading --twothird" />
                                        <div className="tag-label loading --onefifth" />
                                        <div className="tag-label loading --twothird" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="details-info wrap-location">
                            <div className="wrap-item__header flex-betweenitems field-h32">
                                <div className="loading --animation --line --twothird" />
                                <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingSideMenu;
