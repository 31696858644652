import React, { useReducer, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ENTER_KEY_CODE } from 'app/const/Keyboard';
import { clientQuery } from 'common/utils/ApiUtils';
import { settingTaxes } from 'app/const/Api';
import { setListTaxesReducer } from 'common/redux/actions/taxesAction';

import GdInput from 'app/components/input';
import LoadingBarLittle from 'app/components/loading/LoadingBarLittle';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddTax } from 'app/modules/mixpanel/MixpanelAddTax';

const TaxAddForm = forwardRef(({ handleSubmit }, ref) => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: false,
        type: 1,
        errors: {}
    });

    const { list_taxes } = useSelector((store) => store.taxesReducer);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const refForm = useRef(null);
    const isFirstTime = useRef(true);
    const { isVisible, isLoading, type } = state;

    useEffect(() => {
        dispatchState({
            errors: {}
        });
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm
    }));

    const _preventDefault = (e) => {
        e && e.preventDefault();
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleOpenForm = (type) => {
        dispatchState({
            isVisible: true,
            type: type
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleOnKeypress = (e) => {
        if (e.charCode === ENTER_KEY_CODE) {
            _handleSubmit(e);
        }
    };

    const _handleResetErrors = (value) => {
        if (value.keyword.trim() !== '' && isFirstTime.current) {
            isFirstTime.current = false;
            dispatchState({ errors: {} });
        }
    };

    const _handleSubmit = (e) => {
        _preventDefault(e);
        _stopPropagation(e);

        const errors = {};
        const params = {
            name: refForm.current['name'].value.trim(),
            rate: refForm.current['rate'].value.trim()
        };
        if (!params?.name?.length) {
            errors['name'] = 'Name cannot be blank';
        }
        if (!params?.rate?.length) {
            errors['rate'] = 'Rate cannot be blank';
        }
        if (isNaN(params?.rate)) {
            errors['rate'] = 'Rate must be a number';
        }
        if (Object.keys(errors).length) {
            isFirstTime.current = true;
            dispatchState({ errors: errors });
        } else {
            dispatchState({
                isLoading: true
            });
            const optionsQuery = { data: params, method: 'POST' };
            clientQuery(
                settingTaxes,
                optionsQuery,
                (response) => {
                    _handleSubmitSuccess(response, params);
                },
                () => {}
            );
        }
    };

    const _handleSubmitSuccess = (response, params) => {
        handleTrackingEvent(mixpanelAddTax({ id: profileId }));
        params.id = response?.data?.id;
        params.type = type;
        const newListTaxes = list_taxes;
        newListTaxes.push(params);
        //Set data to redux
        dispatch(setListTaxesReducer(newListTaxes));
        handleSubmit(params);
        dispatchState({
            isLoading: false,
            isVisible: false
        });
    };

    if (!isVisible) return null;

    return (
        <div id="add_tax_rate" className="modal container-modal open">
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <form ref={refForm} onSubmit={_preventDefault}>
                    <div className="header-modal">
                        <h3 className="header-modal__label">New Tax Name and Rate</h3>
                        <div
                            className="v2-btn-default --icon-lg --transparent"
                            onClick={(e) => {
                                _handleCloseForm(e);
                            }}
                            tabIndex="0"
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <GdInput
                            label="Name"
                            type="text"
                            withClassName="rows --inmodal"
                            placeholder="Name"
                            name="name"
                            error={state?.errors?.name}
                            handleOnChange={_handleResetErrors}
                            handleKeypress={_handleOnKeypress}
                        />
                        <GdInput
                            label="Rate"
                            type="text"
                            withClassName="rows --inmodal mt-4"
                            placeholder="Rate"
                            name="rate"
                            error={state?.errors?.rate}
                            handleOnChange={_handleResetErrors}
                            handleKeypress={_handleOnKeypress}
                        />
                    </div>
                    <div className="footer-modal">
                        <div
                            onClick={(e) => {
                                _handleCloseForm(e);
                            }}
                            className="v2-btn-default --transparent"
                            tabIndex="0"
                        >
                            Cancel
                        </div>
                        <div
                            onClick={(e) => {
                                _handleSubmit(e);
                            }}
                            className={`v2-btn-main ${isLoading && '--loader'}`}
                            tabIndex="0"
                        >
                            Save
                            {isLoading && <LoadingBarLittle />}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
});

export default TaxAddForm;
