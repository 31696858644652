import React, { useEffect, useReducer } from 'react';

import { BEST_AVAILABLE_TIME_MANAGE, JOB_MAGNET_MANAGE } from 'app/config/routes';
import { ADDONS_FOOTER_OPTIONS, LIST_API_UPDATE_ADDONS_OPTIONS } from 'app/const/addons';
import { ADDONS_ADVANCE_ROUTING, UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import AddonBack from '../components/AddonBack';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import { AddonsOptionsItem } from '../components/AddonsOptions';
import Loading from './components/Loading';
import { useSelector } from 'react-redux';

const AdvanceRouting = () => {
    const [state, dispatchState] = useReducer(reducer, { data: {}, isLoading: true });
    const { isLoading, data } = state;

    useEffect(() => {
        getAdvanceRouting();
    }, []);

    const getAdvanceRouting = () => {
        const _handleGetSuccess = ({ data }) => dispatchState({ data, isLoading: false });
        clientQuery(ADDONS_ADVANCE_ROUTING, { data: {}, method: 'GET' }, _handleGetSuccess);
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="boxs-wrapper">
                        <div className="boxs boxs--no-border">
                            <AddonsBoxHeader {...data} urlUpdateStatus={LIST_API_UPDATE_ADDONS_OPTIONS[data.keyword]} />
                            <div className="boxs__contents mt-3">
                                <p className="description mt-5">{data.sub_title}</p>
                            </div>
                            <div className="is-divider --horizontal my-3" />
                        </div>
                        <AddonsAdvanceRoutingOptions data={data.options || []} />
                    </div>
                )}
            </div>
        </div>
    );
};

const AddonsAdvanceRoutingOptions = ({ data = [] }) => {
    const isActivated = useSelector(({ auth }) => !!auth.user.settings.addons.advance_routing);
    if (!data || !data.length) return null;
    return data.map((item) => {
        const keyword = item.keyword;
        const pathManage =
            keyword === ADDONS_FOOTER_OPTIONS.BEST_AVAILABLE_TIME ? BEST_AVAILABLE_TIME_MANAGE : JOB_MAGNET_MANAGE;
        return (
            <AddonsOptionsItem
                key={keyword}
                isPowerUp
                shouldCheckManage
                pathManage={pathManage}
                urlUpdate={UPDATE_ADDONS_ADVANCE_ROUTING_OPTIONS}
                status={isActivated}
                {...item}
            />
        );
    });
};

export default AdvanceRouting;
