import React, { createContext, useEffect, useReducer } from 'react';
import { getDayOfWeekModMonth, getSummaryWeekly, getDayOfWeekModYear } from 'app/modules/jobdetail/const/Utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { TABS_INOIVE_REPEAT } from 'app/modules/jobdetail/const/Invoice';
import moment from 'moment';
import { TABS_ENDS } from 'app/modules/jobdetail/const';
import { reducer } from 'app/const/Reducer';

export const InvoiceRepeatContext = createContext();

const InvoiceRepeatContextProvider = ({ invoiceRepeatData, invoiceDate = {}, children, isJobInvoice = false }) => {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, _preData());
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);

    useEffect(() => {
        if (invoiceRepeatData) {
            dispatchState({
                ...invoiceRepeatData,
                offset: {
                    ...invoiceRepeatData.offset,
                    ...invoiceDate
                }
            });
        }
    }, []);

    function _preData() {
        const tempFrequency = invoiceRepeatData?.offset?.frequency || '';

        if (!tempFrequency || tempFrequency === TABS_INOIVE_REPEAT.OFF) {
            invoiceRepeatData.offset = {
                ...invoiceRepeatData.offset,
                frequency: TABS_INOIVE_REPEAT.OFF,
                interval: '1',
                bymonthday: 'dayofweek',
                byyearday: 'dayofyear',
                SU: '0',
                MO: '1',
                TU: '1',
                WE: '0',
                TH: '0',
                FR: '0',
                SA: '0',
                countChecked: '2',
                ends: 'never',
                occurances: '',
                endDate: ''
            };
        }

        return {
            ...invoiceRepeatData
        };
    }

    function _renderRecurrenceSummary(recurringData) {
        const { offset: recurringOffset, repeat: recurringRepeat } = recurringData || {};

        const dataRepeat = typeof recurringOffset === 'object' ? recurringOffset : {};

        const { frequency: type, interval } = dataRepeat;

        if (recurringRepeat) {
            switch (type) {
                case TABS_INOIVE_REPEAT.MONTHLY:
                    const typeMonth = dataRepeat.bymonthday;
                    return getDayOfWeekModMonth(t, typeMonth, recurringData.offset.nextDateInvoice, interval);
                case TABS_INOIVE_REPEAT.YEARLY:
                    const typeYear = dataRepeat.byyearday;
                    return getDayOfWeekModYear(t, typeYear, recurringData.offset.nextDateInvoice, interval);
                case TABS_INOIVE_REPEAT.WEEKLY:
                    const days = {
                        MO: dataRepeat.MO,
                        TU: dataRepeat.TU,
                        WE: dataRepeat.WE,
                        TH: dataRepeat.TH,
                        FR: dataRepeat.FR,
                        SA: dataRepeat.SA,
                        SU: dataRepeat.SU
                    };
                    return getSummaryWeekly(t, interval, days);
                default:
                    return isJobInvoice ? 'This invoice repeats with the job.' : 'Does not repeat';
            }
        }

        if (type === TABS_INOIVE_REPEAT.NONE) {
            return 'Does not repeat';
        }

        return isJobInvoice ? 'This invoice repeats with the job.' : 'Does not repeat';
    }

    function _renderEndSummary(summaryData) {
        if (summaryData.repeat) {
            const dataRepeat = summaryData.offset || {};
            const typeEnd = dataRepeat.ends;

            switch (typeEnd) {
                case TABS_ENDS.MODE_NEVER:
                    return '';
                case TABS_ENDS.MODE_AFTER:
                    return `, ${dataRepeat.occurances} ${t(`jobDetail:times`)}`;
                case TABS_ENDS.MODE_ON_DATE:
                    return `, ${t(`jobDetail:until`)} ${moment(dataRepeat.endDate)
                        .utc()
                        .format(convertPhpFormatToMoment(date_format))}`;
                default:
                    break;
            }
        }
        return '';
    }

    function _handleChangeData(newData) {
        dispatchState({
            ...newData,
            summary: `${_renderRecurrenceSummary(newData)}${_renderEndSummary(newData)}`
        });
    }

    const InvoiceRepeatContextData = {
        invoiceRepeatData: state,
        updateInvoiRepeatContext: _handleChangeData
    };

    return <InvoiceRepeatContext.Provider value={InvoiceRepeatContextData}>{children}</InvoiceRepeatContext.Provider>;
};

export default InvoiceRepeatContextProvider;
