import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_REVENUE_BY_SOURCE,
    REPORT_REVENUE_BY_SOURCE_PAYMENT,
    REPORT_REVENUE_SOURCE_METHOD_FILTER,
    REPORT_REVENUE_SOURCE_SOURCES_FILTER
} from 'app/const/Api';

export function* getListReportRevenueBySourceMonth({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_SOURCE, params);
        if (response && response.success) {
            actionSuccess(response, 'total_customer');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportRevenueBySourceDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_SOURCE_PAYMENT, params);
        if (response && response.success) {
            actionSuccess(response, 'total_payment');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterRevenueBySource({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_SOURCE_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListSourceFilterRevenueBySource({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_SOURCE_SOURCES_FILTER);
        if (response && response.success) {
            const newData = [...response.data];
            newData.map((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
