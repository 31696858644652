import classNames from 'classnames';
import React, { useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

import IconThreeDots from 'assets/icon/IconThreeDots';
import { LIMIT_SMART_VIEW_LIST } from '../../constants';
import { getItemStyle, getListStyle, getSmartViewUrl } from '../utils';
import ItemSmartView from './ItemSmartView';
import { addBranchPath } from 'app/const/Branch';

const queryAttr = 'data-rbd-drag-handle-draggable-id';
const ListWrapper = ({
    data = [],
    dataLength = 0,
    isShowAll = false,
    isSearching = false,
    onEdit = () => {},
    onShowAll = () => {},
    onDragEnd = () => {}
}) => {
    const { pathname } = useLocation();
    const { t } = useTranslation('smartView');
    const refPlaceHolder = useRef(null);

    const _handleDragEnd = (result) => {
        if (!result.destination) return;
        const destinationIndex = result.destination.index;
        onDragEnd({ destinationIndex, sourceIndex: result.source.index });
        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'none';
            refPlaceHolder.current.style.top = 0;
            refPlaceHolder.current.style.left = 0;
        }
    };

    const _handleDragUpdate = (update) => {
        if (!update.destination) return;
        const draggableId = update.draggableId;
        const initialIndex = update.source.index;
        const destinationIndex = update.destination.index;

        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) return;
        const parentDom = draggedDOM.closest('.sidebar-list');

        const arr = [...parentDom.children];
        if (initialIndex < destinationIndex) {
            arr.splice(initialIndex, 1);
        }

        const clientY =
            parseFloat(window.getComputedStyle(parentDom).paddingTop) +
            arr.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        if (refPlaceHolder.current) {
            const textDiv = refPlaceHolder.current.querySelector('.txt-ellipsis');
            if (textDiv) textDiv.textContent = data[initialIndex].name;
            refPlaceHolder.current.style.display = 'flex';
            refPlaceHolder.current.style.top = `${clientY}px`;
            refPlaceHolder.current.style.left = `${parseFloat(window.getComputedStyle(parentDom).paddingLeft)}px`;
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={_handleDragEnd} onDragUpdate={_handleDragUpdate}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            role="list"
                            className="sidebar-list flex-column scrolls"
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {data?.length ? (
                                data.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => {
                                            const pathTo = getSmartViewUrl(item.id);
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    className={classNames('rows sidebar-items flexcenter gap-2', {
                                                        active: matchPath(addBranchPath(pathTo), {
                                                            path: pathname,
                                                            exact: true
                                                        })
                                                    })}
                                                    {...provided.draggableProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <ItemSmartView
                                                        id={item.id}
                                                        name={item.name}
                                                        pathTo={pathTo}
                                                        definition={item.definition}
                                                        isSearching={isSearching}
                                                        dragHandleProps={{ ...provided.dragHandleProps }}
                                                        onEdit={() => onEdit(item)}
                                                    />
                                                </div>
                                            );
                                        }}
                                    </Draggable>
                                ))
                            ) : (
                                <div className="rows">
                                    <p className="py-1">{t('no_matching_smart_view')}</p>
                                </div>
                            )}

                            {provided.placeholder}
                            <div
                                ref={refPlaceHolder}
                                className="rows sidebar-items flexcenter gap-2 has-drag absolute"
                                style={{ display: 'none' }}
                            >
                                <div className="v2-btn-default --icon-sm --transparent --drag">
                                    <IconThreeDots isDouble />
                                </div>
                                <span className="txt-ellipsis flex-1" />
                            </div>
                            {dataLength > LIMIT_SMART_VIEW_LIST ? (
                                <div className="v2-btn-default --grey my-1" onClick={onShowAll}>
                                    {isShowAll ? t('show_less') : t('show_all')}
                                </div>
                            ) : null}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default ListWrapper;
