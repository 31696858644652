import { useEffect, useContext } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { useSelector } from 'react-redux';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';

export default function RealTimeSMS({ onUpdateTotalSms = () => {}, isInbox }) {
    const {
        smsReceiver: smsReceiverData,
        smsConversation: smsConversationData,
        smsUnknown: smsUnknownData
    } = useContext(SocketContext);

    const user_id = useSelector(({ auth }) => auth.user?.profile?.id);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);

    const checkPermissionSmsFilter = checkPermission(permissionsList, PERMISSIONS.smsFilterByUser);

    useEffect(() => {
        if (smsReceiverData) {
            if (!checkPermissionSmsFilter || smsReceiverData.unAssignSmsUsers?.includes(user_id))
                onUpdateTotalSms(1, smsReceiverData || null, null);
        }
    }, [smsReceiverData]);

    useEffect(() => {
        if (smsUnknownData) {
            if (!checkPermissionSmsFilter || smsUnknownData.unAssignSmsUsers?.includes(user_id))
                onUpdateTotalSms(1, smsUnknownData || null, null);
        }
    }, [smsUnknownData]);

    /**
     * We need check count total with case socketid != current conversationListSocketId
     * We need smsConversationData to check remove convertsation list
     */
    useEffect(() => {
        if (smsConversationData) {
            const conversations = smsConversationData.conversations || [];
            const totalConversations = checkPermissionSmsFilter
                ? conversations
                      .filter((item) => !item.unAssignSmsUsers?.includes(user_id))
                      .reduce((a, b) => a + b.message_ids?.length, 0)
                : conversations.reduce((a, b) => a + b.message_ids?.length, 0);

            // eslint-disable-next-line no-undef
            const socketCheck = isInbox ? global.inboxSmsSocketId : global.conversationListSocketId;

            onUpdateTotalSms(
                smsConversationData.socket_id !== socketCheck
                    ? smsConversationData.check
                        ? parseInt(totalConversations)
                        : -parseInt(totalConversations)
                    : 0,
                null,
                smsConversationData || null
            );
        }
    }, [smsConversationData]);

    useEffect(() => {
        _setSocketId();
    }, []);

    const _setSocketId = () => {
        if (isInbox) {
            // eslint-disable-next-line no-undef
            global.inboxSmsSocketId = `${Date.now()}_sms_inbox`;
        } else {
            // eslint-disable-next-line no-undef
            global.conversationListSocketId = `${Date.now()}_sms_header`;
        }
    };

    return null;
}
