import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { AddCustomerContext } from '../../contexts';
import EmailTo from './EmailTo';

const BillingEmail = (props, ref) => {
    const { profile, additional_contacts } = useContext(AddCustomerContext);
    const [state, dispatchState] = useReducer(reducer, {
        listEmail: [],
        selectedEmail: []
    });
    const { listEmail, selectedEmail } = state;
    const refSelected = useRef({ email_to: [] });
    const refMailTo = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => refMailTo.current.getValue().map((item) => item.id && item.email)
    }));

    useEffect(() => {
        const { email_to } = refSelected.current;
        const result = { listEmail: [], selectedEmail: email_to };

        // Handle profile email
        if (profile.email) {
            const newEmail = { id: `${new Date().getTime()}`, email: profile.email, isFromProfile: true };

            if (email_to.length) {
                const indexUpdate = email_to.findIndex((item) => item.isFromProfile);

                if (indexUpdate >= 0) {
                    const emailUpdated = { ...email_to[indexUpdate], email: profile.email };
                    result['selectedEmail'][indexUpdate] = emailUpdated;
                    result['listEmail'].push(emailUpdated);
                }
            } else {
                result['listEmail'] = [newEmail];
                result['selectedEmail'] = [newEmail];
            }
        }

        // Handle from additional contact
        additional_contacts.forEach((item) => {
            const indexUpdateEmail = email_to.findIndex((el) => el.id === item.id);

            if (indexUpdateEmail >= 0) result['selectedEmail'][indexUpdateEmail] = item;
            if (item.email) result['listEmail'].push({ id: item.id, email: item.email });
        });

        // Update state for default message preferences
        dispatchState(result);
    }, [profile, additional_contacts]);

    const _handleSelect = (options) => {
        refSelected.current = { email_to: options };
    };

    return (
        <EmailTo
            ref={refMailTo}
            selected={selectedEmail}
            data={[{ id: '', email: 'None' }, ...listEmail]}
            onSelect={_handleSelect}
            isFirstTimeFetch={false}
        />
    );
};

export default forwardRef(BillingEmail);
