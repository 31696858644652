import React, { useEffect } from 'react';
import 'app/modules/dashboard/css/dashboard.scss';
import WidgetStats from './widgets/stats';
import WidgetJobs from './widgets/jobs';
import WidgetRevenuePerMonth from './widgets/revenuepermonth';
import WidgetSalesForeCast from './widgets/salesforecast';
import WidgetInvoiceTotal from './widgets/invoicestotal';
import WidgetInvoiceAging from './widgets/invoicesaging';
import WidgetEstimates from './widgets/estimates';
import WidgetCustomerAndLead from './widgets/customersanddead';
import WidgetTags from './widgets/tags';
import WidgetPayments from './widgets/payments';
import WidgetActiveService from './widgets/activeservice';
import WidgetRevenueByStaff from './widgets/revenuebystaff';

import { checkPermission } from 'common/utils/PermissionUtils';
import { useSelector } from 'react-redux';
import { ACCESS_DASHBOARD } from 'app/const/Permissions';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../error';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_WIDGET_LOCAL_STORAGE, getAllParamsWidget } from './const';

export default function Dashboard() {
    const { t } = useTranslation(['common']);

    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const isHaveAccessDashBoard = checkPermission(permissionsList, ACCESS_DASHBOARD);
    const params = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE);

    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('dashboard-page', 'container-main');

        if (!params) setLocalStorage(KEY_WIDGET_LOCAL_STORAGE, getAllParamsWidget());

        const hideTooltipOnScroll = () => {
            const tooltipEl = document.getElementById('chartjs-tooltip');
            if (tooltipEl) {
                tooltipEl.style.transition = 'opacity 0.5s ease-in-out';
                tooltipEl.style.opacity = 0;
                tooltipEl.style.pointerEvents = 'none';
            }
        };

        const containerDashboard = document.querySelector('.container-dashboard');
        let scrollsElement;
        if (containerDashboard) {
            scrollsElement = containerDashboard.querySelector('.scrolls');

            if (scrollsElement) {
                scrollsElement.addEventListener('scroll', hideTooltipOnScroll);
            }
        }

        return () => {
            mainPageDiv.classList.remove('dashboard-page', 'container-main');

            if (scrollsElement) {
                scrollsElement.removeEventListener('scroll', hideTooltipOnScroll);
            }
        };
    }, []);

    if (!isHaveAccessDashBoard) {
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    }

    return (
        <div className="container-wrap container-dashboard">
            <div className="scrolls">
                <div className="wrapper-dashboard">
                    <div className="wrapper-dashboard__content">
                        <WidgetStats />
                        <WidgetJobs />
                        <WidgetRevenuePerMonth />
                        <WidgetSalesForeCast />
                        <WidgetInvoiceTotal />
                        <WidgetInvoiceAging />
                        <WidgetEstimates />
                        <WidgetCustomerAndLead />
                        <div className="rows wrapper-widget">
                            <div className="col">
                                <WidgetRevenueByStaff />
                                <WidgetPayments />
                            </div>
                            <div className="col">
                                <WidgetActiveService />
                                <WidgetTags />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
