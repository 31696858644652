import React from 'react';

import SlideBarContent from 'app/modules/calendar/components/sidebar/SideBarContent';
import InlinePicker from 'app/modules/calendar/components/sidebar/components/InlinePicker';
import JobPreView from 'app/modules/calendar/job';
import TimeOffContent from './components/timeoff';

const SlideBar = () => {
    return (
        <div id="calendar_sidebar" className="calendar-sidebar">
            <InlinePicker />
            <div className="sidebar-details">
                <SlideBarContent />
                <JobPreView />
            </div>
            <TimeOffContent />
        </div>
    );
};

export default SlideBar;
