import React, { useEffect, useReducer, useRef } from 'react';

import GdGridView from 'app/components/grid/GdGridView';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import { getGridColumns } from '../constants/table';
import { CONFIG_ADDON_ROUTING, TYPE_ADVANCE_ROUTING } from '../constants';

const ManageUsersTable = ({
    title = '',
    description = '',
    reportType = REPORT_TYPE.ADVANCE_ROUTING_PERMISSIONS,
    type = TYPE_ADVANCE_ROUTING.MAGNET
}) => {
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, totalPage, isLoading } = state;
    const refTimer = useRef({});

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    getLocalParamsReport(keyLocal, reportType);
    const { endPointPermission, permissionId } = CONFIG_ADDON_ROUTING[type];

    useEffect(() => {
        getListUsersPermission();

        return () => {
            removeLocalStorage(keyLocal);
        };
    }, []);

    const getListUsersPermission = () => {
        const params = getLocalStorage(keyLocal);

        const handleSuccess = ({ data = [], total = 0 }) => {
            dispatchState({ data: data, isLoading: false, totalPage: Math.ceil(total / params.limit) });
        };
        const handleFail = ({ message = [] }) => {
            dispatchState({ data: [], errors: message, isLoading: false });
        };
        clientQuery(
            endPointPermission,
            { data: { ...params, total: 1 }, method: 'GET', toFormData: false },
            handleSuccess,
            handleFail
        );
    };

    const handleSwitch = ({ id, value, key: keyPermission }) => {
        clearTimeout(refTimer.current[id]);
        let newPermission = [];

        dispatchState((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.user.id === id) {
                    const permission = item.permission;
                    newPermission = value
                        ? [...permission, keyPermission]
                        : permission.filter((key) => key !== keyPermission);

                    return { ...item, permission: newPermission };
                }
                return item;
            })
        }));

        refTimer.current[id] = setTimeout(() => {
            clientQuery(endPointPermission + '/' + id, {
                method: 'PUT',
                toFormData: false,
                data: { permissions: newPermission }
            });
        }, 500);
    };

    const handleUpdate = () => {
        if (!isLoading) dispatchState({ isLoading: true });
        getListUsersPermission();
    };

    return (
        <div className="wrap-tables flex-column relative">
            <div className="table-title flex-column gap-4">
                <p className="fs-15 fw-700 black">{title}</p>
                <p className="black-darker3">{description}</p>
            </div>
            <GdGridView
                isScroll
                isEmptyFlat
                content={data || []}
                isLoading={isLoading}
                classTable="tables table-multi-column"
                fileTranslation="report"
                keyGetId="user.id"
                {...getGridColumns({ permissionId })}
                onChangeSwitchOppPermission={handleSwitch}
            />
            <ReportPagination reportType={reportType} totalPage={totalPage || 1} onSelect={handleUpdate} />
        </div>
    );
};

export default ManageUsersTable;
