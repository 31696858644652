export const TYPES_TIME_WINDOW = {
    ACTION_GET_LIST_TIME_WINDOW: 'ACTION_GET_LIST_TIME_WINDOW',
    ACTION_GET_LIST_TIME_WINDOW_SUCCESS: 'ACTION_GET_LIST_TIME_WINDOW_SUCCESS',
    RESET_LIST_TIME_WINDOW: 'RESET_LIST_TIME_WINDOW'
};

export const getListTimeWindow = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPES_TIME_WINDOW.ACTION_GET_LIST_TIME_WINDOW,
        params,
        actionSuccess,
        actionFailed
    };
};

export const resetTimeWindow = () => {
    return {
        type: TYPES_TIME_WINDOW.RESET_LIST_TIME_WINDOW
    };
};
