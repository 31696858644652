import React, { useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { Route, Switch } from 'react-router-dom';
import { addPrefixPath } from 'app/const/Branch';
import { ROUTES_AUTH_PROFILE } from './constants/routesProfile';

const ProfileAuthLayout = () => {
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('settings-page');
        return () => {
            mainPageDiv.classList.remove('settings-page');
        };
    }, []);

    return (
        <div className="container-wrap container-setting">
            <div className="wrapper-columns">
                <Sidebar />
                <div className="contents-pages container-column contents-user">
                    <div className="contents-user__details scrolls-x">
                        <Switch>
                            {ROUTES_AUTH_PROFILE.map(({ path, component, exact }) => (
                                <Route key={path} exact={exact} path={addPrefixPath(path)} component={component} />
                            ))}
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileAuthLayout;
