import { COLUMNS_BUILT_IN } from '../../constants/columns';
import { OBJECT_TYPES } from '../../constants/types';

export const sortToQuery = (sort) => {
    try {
        if (!sort?.length) return [];
        return sort.map((item) => {
            const sortQuery = { direction: item.direction.toUpperCase() };
            switch (item.objectType) {
                case OBJECT_TYPES.CUSTOM_FIELD:
                    sortQuery['field'] = { customField: { customFieldId: item.fieldId } };
                    break;
                case OBJECT_TYPES.JOB:
                    sortQuery['field'] = COLUMNS_BUILT_IN[item.fieldId]
                        ? { fieldName: item.fieldId, objectType: item.objectType }
                        : { customJobStatus: item.fieldId, objectType: OBJECT_TYPES.JOB };
                    break;
                default:
                    sortQuery['field'] = { fieldName: item.fieldId, objectType: item.objectType };
                    break;
            }
            return sortQuery;
        });
    } catch (error) {
        console.error('Error sortToQuery', error);
        return [];
    }
};
