import React, { Fragment, useEffect, useReducer, useRef } from 'react';

import GDLineChart from 'app/components/chart/line';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { getChatbotOccurrences } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { handleAbortController } from 'app/modules/customer/utils';
import { SIZE_CHART } from 'app/modules/dashboard/const';
import { clientQuery } from 'common/utils/ApiUtils';
import { MODE_CHART_TRAIN_AI, TAB_CHART_TRAIN_AI } from '../constants';
import { configModalChartAI } from './config';
import LoadingChartAI from './LoadingChartAI';

const ChartTrainAI = ({ id }) => {
    const abortController = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        data: { labels: [], values: [] },
        isLoading: true,
        mode: MODE_CHART_TRAIN_AI.THIS_MONTH
    });
    const { data: finalData, isLoading, mode } = state;

    useEffect(() => {
        getDataOccurrences({ mode });
        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _handleChangeTab = (value) => {
        dispatchState((prev) => ({ ...prev, mode: value, isLoading: true }));
        getDataOccurrences({ mode: value });
    };

    const getDataOccurrences = ({ mode = '' }) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _handleSuccess = ({ data = [] }) => {
            const dataLabel = [];
            const dataValues = [];
            data.forEach((item) => {
                dataLabel.push(item.key);
                dataValues.push(item.value);
            });
            dispatchState((prev) => ({ ...prev, data: { labels: dataLabel, values: dataValues }, isLoading: false }));
        };

        const _handleFail = () => {
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(
            getChatbotOccurrences(id),
            { method: 'GET', data: { mode }, abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    return (
        <Fragment>
            <div className="flexcenter row-gap-8 flex-wrap">
                <TabSwitcher
                    wrapperClassName="tabs"
                    listTabs={TAB_CHART_TRAIN_AI}
                    activeTab={mode}
                    onChange={_handleChangeTab}
                />
            </div>
            {isLoading ? (
                <LoadingChartAI />
            ) : (
                <div className={'chart'}>
                    <GDLineChart
                        id={'chart_train_ai'}
                        configLineChart={configModalChartAI({ data: finalData })}
                        size={SIZE_CHART.MEDIUM}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default ChartTrainAI;
