import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { getSmartViewUrl } from 'app/modules/smartview/components/utils';
import IconArrow from 'assets/icon/IconArrow';
import IconBalloon from 'assets/icon/IconBalloon';
import IconStartOver from 'assets/icon/IconStartOver';
import IconTooltip from 'assets/icon/IconTooltip';
import ErrorPage from 'app/modules/error';
import { addBranchPath } from 'app/const/Branch';

const DialerCallDone = () => {
    const { t } = useTranslation('smartView');
    const { state: stateLocation } = useLocation();
    const { id, name } = stateLocation?.dialer || {};

    if (!id) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="wrap-called">
            <div className="relative">
                <div className="rows --icon">
                    <IconBalloon />
                </div>
                <div className="rows">
                    <p className="title">{t('all_leads_have_been_called')}</p>
                    <p>{t('based_on_leads_that_appeared_while_the_dialer_was_active')}</p>
                    <span />
                    <Link className="v2-btn-default has-icon js-back" to={addBranchPath(getSmartViewUrl(id))}>
                        <IconArrow isPrev />
                        {t('back_to_name', { name })}
                    </Link>
                    <div className="line" />
                    <Link
                        className="v2-btn-default has-icon"
                        to={{ pathname: addBranchPath(getSmartViewUrl(id)), state: { startOver: true } }}
                    >
                        <IconStartOver />
                        {t('start_over')}
                    </Link>
                    <div className="desc">
                        <span>{t('caution')}:</span> {t('this_may_result_in_calling_leads_that_recently_answered')}
                        <div className="tooltip">
                            <IconTooltip />
                            <p className="tooltiptext top">{t('tooltip_caution_smart_dialer')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DialerCallDone;
