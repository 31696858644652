import 'app/components/chart/css/index.scss';
import Chartjs from 'chart.js/auto';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import LoadingChart from 'app/modules/lead/components/LoadingChart';

function Chart(
    {
        id = 'charLineOnlineBooking',
        chartConfig,
        isLoading,
        isShow,
        classWrapper = 'chartjs__content --signle-chart',
        classCanvas = 'chartjs-render-monitor',
        style = {},
        plugins = []
    },
    ref
) {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useImperativeHandle(ref, () => ({
        getInstance: () => chartInstance
    }));

    useEffect(() => {
        let newChartInstance = null;

        if (chartConfig && chartContainer.current) {
            if (chartInstance) chartInstance.destroy();

            newChartInstance = new Chartjs(chartContainer.current, {
                ...chartConfig,
                plugins: [...(chartConfig?.plugins || []), ...plugins]
            });
        }

        setChartInstance(newChartInstance);

        return () => {
            if (newChartInstance) newChartInstance.destroy();
        };
    }, [chartConfig]);

    return (
        <div
            className={classNames(`${classWrapper}`, {
                active: !isShow,
                'wrap-loading overflow-hidden': isLoading
            })}
            style={style}
        >
            {isLoading ? (
                <LoadingChart isLoadingLineAndPieChart={false} />
            ) : (
                <canvas id={id} ref={chartContainer} className={classCanvas} />
            )}
        </div>
    );
}

export default forwardRef(Chart);
