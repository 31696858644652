import GdButton from 'app/components/button';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const ConfirmDelete = forwardRef(({ title, onCallSuccess = () => {}, onCallFail = () => {} }, ref) => {
    const initialState = {
        isOpen: false,
        isLoading: false,
        document: {},
        optionQuery: {}
    };

    const { t } = useTranslation(['common', 'addons']);
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { optionQuery, document } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (document = {}, optionQuery = {}) => {
        dispatchState({ isOpen: true, document, optionQuery });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleConfirm = () => {
        dispatchState({ isLoading: true });
        clientQuery(
            optionQuery.url,
            optionQuery.options,
            ({ message }) => _handleConfirmSuccess(message),
            _handleConfirmFail
        );
    };

    const _handleConfirmSuccess = (message) => {
        onCallSuccess(document.id, message);
        dispatchState(initialState);
    };

    const _handleConfirmFail = ({ message }) => {
        onCallFail(message);
        dispatchState(initialState);
    };

    return (
        <ReactModal
            id="document-confirm-delete"
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal modal-confirm-delete open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close}></div>
            <div className="modal__container">
                <div className="header-modal">
                    <div className="header-modal__label">{title}</div>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <p className="text">{t('addons:delete_document_description', { name: document.name })}</p>
                </div>
                <div className="footer-modal footer-hasbtn btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('common:cancel')}
                    </span>
                    <GdButton isLoading={state.isLoading} className="v2-btn-main" onClick={_handleConfirm}>
                        {t('common:yes')}
                    </GdButton>
                </div>
            </div>
        </ReactModal>
    );
});

export default ConfirmDelete;
