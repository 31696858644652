export const getIconTypeFile = (type, width = 43, height = 56) => {
    switch (type) {
        case 'application/pdf':
            return (
                <svg width={width} height={height} viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.485 0H1.463C0.655 0 0 0.655 0 1.926V55C0 55.345 0.655 56 1.463 56H41.537C42.345 56 43 55.345 43 55V12.978C43 12.282 42.907 12.058 42.743 11.893L31.107 0.257C30.942 0.093 30.718 0 30.485 0Z"
                        fill="#E9E9E0"
                    />
                    <path d="M31 0.151001V12H42.849L31 0.151001Z" fill="#D9D7CA" />
                    <path
                        d="M13.0142 33.324C12.6662 33.324 12.3322 33.211 12.0472 32.998C11.0062 32.217 10.8662 31.348 10.9322 30.756C11.1142 29.128 13.1272 27.424 16.9172 25.688C18.4212 22.392 19.8522 18.331 20.7052 14.938C19.7072 12.766 18.7372 9.94802 19.4442 8.29502C19.6922 7.71602 20.0012 7.27202 20.5782 7.08002C20.8062 7.00402 21.3822 6.90802 21.5942 6.90802C22.0982 6.90802 22.5412 7.55702 22.8552 7.95702C23.1502 8.33302 23.8192 9.13002 22.4822 14.759C23.8302 17.543 25.7402 20.379 27.5702 22.321C28.8812 22.084 30.0092 21.963 30.9282 21.963C32.4942 21.963 33.4432 22.328 33.8302 23.08C34.1502 23.702 34.0192 24.429 33.4402 25.24C32.8832 26.019 32.1152 26.431 31.2202 26.431C30.0042 26.431 28.5882 25.663 27.0092 24.146C24.1722 24.739 20.8592 25.797 18.1812 26.968C17.3452 28.742 16.5442 30.171 15.7982 31.219C14.7732 32.654 13.8892 33.324 13.0142 33.324ZM15.6762 28.198C13.5392 29.399 12.6682 30.386 12.6052 30.942C12.5952 31.034 12.5682 31.276 13.0362 31.634C13.1852 31.587 14.0552 31.19 15.6762 28.198ZM29.3132 23.756C30.1282 24.383 30.3272 24.7 30.8602 24.7C31.0942 24.7 31.7612 24.69 32.0702 24.259C32.2192 24.05 32.2772 23.916 32.3002 23.844C32.1772 23.779 32.0142 23.647 31.1252 23.647C30.6202 23.648 29.9852 23.67 29.3132 23.756ZM21.8432 17.174C21.1282 19.648 20.1842 22.319 19.1692 24.738C21.2592 23.927 23.5312 23.219 25.6652 22.718C24.3152 21.15 22.9662 19.192 21.8432 17.174ZM21.2362 8.71202C21.1382 8.74502 19.9062 10.469 21.3322 11.928C22.2812 9.81302 21.2792 8.69802 21.2362 8.71202Z"
                        fill="#CC4B4C"
                    />
                    <path
                        d="M41.537 56H1.463C0.655 56 0 55.345 0 54.537V39H43V54.537C43 55.345 42.345 56 41.537 56Z"
                        fill="#CC4B4C"
                    />
                    <path
                        d="M10.8851 53H9.24414V42.924H12.1421C12.5701 42.924 12.9941 42.992 13.4131 43.129C13.8321 43.266 14.2081 43.471 14.5411 43.744C14.8741 44.017 15.1431 44.348 15.3481 44.735C15.5531 45.122 15.6561 45.557 15.6561 46.041C15.6561 46.552 15.5691 47.014 15.3961 47.429C15.2231 47.844 14.9811 48.193 14.6711 48.475C14.3611 48.757 13.9871 48.976 13.5501 49.131C13.1131 49.286 12.6291 49.363 12.1011 49.363H10.8841V53H10.8851ZM10.8851 44.168V48.16H12.3891C12.5891 48.16 12.7871 48.126 12.9841 48.057C13.1801 47.989 13.3601 47.877 13.5241 47.722C13.6881 47.567 13.8201 47.351 13.9201 47.073C14.0201 46.795 14.0701 46.451 14.0701 46.041C14.0701 45.877 14.0471 45.687 14.0021 45.474C13.9561 45.26 13.8631 45.055 13.7221 44.859C13.5801 44.663 13.3821 44.499 13.1271 44.367C12.8721 44.235 12.5341 44.169 12.1151 44.169H10.8851V44.168Z"
                        fill="white"
                    />
                    <path
                        d="M25.719 47.682C25.719 48.511 25.63 49.22 25.452 49.808C25.274 50.396 25.049 50.888 24.775 51.285C24.501 51.682 24.194 51.994 23.852 52.222C23.51 52.45 23.18 52.62 22.861 52.735C22.542 52.849 22.25 52.922 21.986 52.954C21.722 52.984 21.526 53 21.398 53H17.584V42.924H20.619C21.467 42.924 22.212 43.059 22.854 43.327C23.496 43.595 24.03 43.954 24.454 44.4C24.878 44.846 25.194 45.355 25.404 45.924C25.614 46.494 25.719 47.08 25.719 47.682ZM20.852 51.797C21.964 51.797 22.766 51.442 23.258 50.731C23.75 50.02 23.996 48.99 23.996 47.641C23.996 47.222 23.946 46.807 23.846 46.397C23.745 45.987 23.552 45.616 23.265 45.283C22.978 44.95 22.588 44.681 22.096 44.476C21.604 44.271 20.966 44.168 20.182 44.168H19.225V51.797H20.852Z"
                        fill="white"
                    />
                    <path
                        d="M29.7661 44.168V47.34H33.9771V48.461H29.7661V53H28.0981V42.924H34.4001V44.168H29.7661Z"
                        fill="white"
                    />
                </svg>
            );
        case 'audio/basic':
        case 'audio/l24':
        case 'audio/mp4':
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/vorbis':
        case 'audio/vnd.rn-realaudio':
        case 'audio/vnd.wave':
        case 'audio/3gpp':
        case 'audio/3gpp2':
        case 'audio/ac3':
        case 'audio/webm':
        case 'audio/amr-nb':
        case 'audio/amr':
            return (
                <svg width={43} height={56} viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.485 0H1.463C0.655 0 0 0.655 0 1.926V55C0 55.345 0.655 56 1.463 56H41.537C42.345 56 43 55.345 43 55V12.978C43 12.282 42.907 12.058 42.743 11.893L31.107 0.257C30.942 0.093 30.718 0 30.485 0Z"
                        fill="#E9E9E0"
                    />
                    <path d="M31 0.151001V12H42.849L31 0.151001Z" fill="#D9D7CA" />
                    <path
                        d="M41.537 56H1.463C0.655 56 0 55.345 0 54.537V39H43V54.537C43 55.345 42.345 56 41.537 56Z"
                        fill="#7D6599"
                    />
                    <path
                        d="M29.17 14.986C28.603 14.19 27.87 13.443 26.862 12.635C22.948 9.504 22.105 6.358 22 5.897V5C22 4.447 21.553 4 21 4C20.447 4 20 4.447 20 5V6V14.359V23.412H16.294C12.412 23.412 10 25.373 10 28.529C10 31.995 12.24 34.235 15.706 34.235C19.177 34.235 22 31.412 22 27.941V16.468L22.298 16.711C22.638 17.047 23.159 17.431 23.819 17.916C26.137 19.625 30.019 22.483 29.043 25.709C29.014 25.807 29 25.904 29 26C29 26.43 29.278 26.826 29.71 26.957C29.807 26.986 29.904 27 30 27C30.43 27 30.826 26.722 30.957 26.29C32.584 20.915 30.535 16.9 29.17 14.986ZM20 27.941C20 30.309 18.074 32.235 15.706 32.235C13.351 32.235 12 30.884 12 28.529C12 25.953 14.335 25.412 16.294 25.412H20V27.941ZM25.005 16.308C24.434 15.886 23.94 15.523 23.634 15.227L22 13.887V10.414C22.827 11.588 23.987 12.897 25.612 14.197C26.47 14.885 27.084 15.505 27.541 16.147C28.257 17.15 28.972 18.486 29.329 20.125C28.002 18.515 26.245 17.221 25.005 16.308Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M15.8998 42.924H17.5678V53H15.8998V46.068L13.6438 51.673H12.1948L9.92484 46.068V53H8.25684V42.924H9.92484L12.9188 49.8149L15.8998 42.924Z"
                        fill="white"
                    />
                    <path
                        d="M21.7108 53H20.0698V42.924H22.9678C23.3968 42.924 23.8208 42.992 24.2388 43.129C24.6568 43.266 25.0338 43.4709 25.3668 43.7439C25.6998 44.0169 25.9688 44.348 26.1738 44.735C26.3788 45.122 26.4818 45.557 26.4818 46.041C26.4818 46.552 26.3948 47.014 26.2218 47.429C26.0488 47.844 25.8068 48.1929 25.4968 48.4749C25.1868 48.7569 24.8128 48.976 24.3758 49.131C23.9388 49.286 23.4548 49.3629 22.9268 49.3629H21.7098V53H21.7108ZM21.7108 44.1679V48.16H23.2148C23.4148 48.16 23.6128 48.1259 23.8098 48.0569C24.0068 47.9879 24.1858 47.877 24.3498 47.722C24.5138 47.567 24.6458 47.351 24.7458 47.073C24.8458 46.795 24.8958 46.451 24.8958 46.041C24.8958 45.877 24.8738 45.6869 24.8278 45.4739C24.7818 45.2609 24.6888 45.055 24.5478 44.859C24.4068 44.663 24.2078 44.499 23.9528 44.367C23.6978 44.235 23.3598 44.1689 22.9408 44.1689H21.7108V44.1679Z"
                        fill="white"
                    />
                    <path
                        d="M32.1149 47.695C32.3249 47.722 32.5409 47.791 32.7639 47.9C32.9869 48.009 33.1949 48.165 33.3859 48.365C33.5769 48.565 33.7369 48.809 33.8649 49.096C33.9929 49.383 34.0559 49.713 34.0559 50.087C34.0559 50.543 33.9899 50.937 33.8579 51.27C33.7259 51.603 33.5589 51.878 33.3589 52.097C33.1589 52.316 32.9329 52.492 32.6819 52.623C32.4309 52.754 32.1879 52.853 31.9509 52.917C31.7139 52.981 31.4979 53.021 31.3019 53.04C31.1059 53.059 30.9529 53.067 30.8439 53.067C30.0779 53.067 29.4749 53.014 29.0319 52.91C28.5889 52.806 28.2819 52.698 28.1089 52.589L28.4779 51.413C28.5599 51.459 28.6369 51.509 28.7099 51.563C28.7829 51.617 28.8879 51.67 29.0239 51.72C29.1599 51.77 29.3519 51.811 29.5979 51.843C29.8439 51.875 30.1809 51.891 30.6099 51.891C31.2389 51.891 31.7089 51.72 32.0179 51.378C32.3269 51.036 32.4829 50.606 32.4829 50.086C32.4829 49.594 32.3409 49.179 32.0589 48.842C31.7769 48.505 31.3619 48.336 30.8149 48.336H29.4199H29.4339L29.4199 47.229H30.3499C30.5499 47.229 30.7529 47.224 30.9579 47.215C31.1629 47.206 31.3559 47.155 31.5389 47.065C31.7219 46.975 31.8719 46.819 31.9899 46.6C32.1079 46.381 32.1679 46.067 32.1679 45.657C32.1679 45.493 32.1539 45.32 32.1269 45.137C32.0999 44.954 32.0239 44.786 31.9009 44.631C31.7779 44.476 31.5979 44.351 31.3609 44.255C31.1239 44.159 30.7959 44.111 30.3769 44.111C29.9579 44.111 29.6229 44.15 29.3719 44.227C29.1209 44.304 28.9329 44.366 28.8049 44.412L28.3259 43.182C28.5449 43.119 28.8159 43.05 29.1389 42.977C29.4619 42.904 29.9299 42.868 30.5399 42.868C30.9689 42.868 31.3739 42.921 31.7569 43.025C32.1399 43.129 32.4749 43.296 32.7619 43.524C33.0489 43.752 33.2769 44.044 33.4459 44.399C33.6149 44.754 33.6989 45.173 33.6989 45.657C33.6989 45.939 33.6489 46.197 33.5489 46.429C33.4489 46.661 33.3209 46.865 33.1659 47.037C33.0109 47.209 32.8379 47.353 32.6459 47.468C32.4539 47.583 32.2789 47.659 32.1149 47.695Z"
                        fill="white"
                    />
                </svg>
            );
        case 'text/vcard':
        case 'text/csv':
        case 'text/rtf':
        case 'text/richtext':
        case 'text/calendar':
        case 'text/directory':
            return (
                <svg width={44} height={56} viewBox="0 0 44 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.985 0H1.963C1.155 0 0.5 0.655 0.5 1.926V55C0.5 55.345 1.155 56 1.963 56H42.037C42.845 56 43.5 55.345 43.5 55V12.978C43.5 12.282 43.407 12.058 43.243 11.893L31.607 0.257C31.442 0.093 31.218 0 30.985 0Z"
                        fill="#E9E9E0"
                    />
                    <path d="M31.5 0.151001V12H43.349L31.5 0.151001Z" fill="#D9D7CA" />
                    <path
                        d="M42.037 56H1.963C1.155 56 0.5 55.345 0.5 54.537V39H43.5V54.537C43.5 55.345 42.845 56 42.037 56Z"
                        fill="#95A5A5"
                    />
                    <path d="M15.8668 42.924V44.0449H12.8588V53H11.2048V44.0449H8.19678V42.924H15.8668Z" fill="white" />
                    <path
                        d="M22.4427 48.105L24.9997 53H23.0997L21.4997 49.199H21.3627L19.6407 53H17.7407L20.2977 48.105L17.5767 42.923H19.4497L21.2267 47.025H21.3637L23.2917 42.923H25.1647L22.4427 48.105Z"
                        fill="white"
                    />
                    <path d="M34.5289 42.924V44.0449H31.5209V53H29.8669V44.0449H26.8589V42.924H34.5289Z" fill="white" />
                    <path
                        d="M12.5 13H6.5C5.947 13 5.5 12.552 5.5 12C5.5 11.448 5.947 11 6.5 11H12.5C13.053 11 13.5 11.448 13.5 12C13.5 12.552 13.053 13 12.5 13Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M15.5 18H6.5C5.947 18 5.5 17.552 5.5 17C5.5 16.448 5.947 16 6.5 16H15.5C16.053 16 16.5 16.448 16.5 17C16.5 17.552 16.053 18 15.5 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M19.5 18C19.24 18 18.979 17.89 18.79 17.71C18.609 17.52 18.5 17.27 18.5 17C18.5 16.73 18.609 16.48 18.8 16.29C19.16 15.92 19.84 15.92 20.21 16.29C20.39 16.48 20.5 16.74 20.5 17C20.5 17.26 20.39 17.52 20.21 17.71C20.02 17.89 19.76 18 19.5 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M31.5 18H23.5C22.947 18 22.5 17.552 22.5 17C22.5 16.448 22.947 16 23.5 16H31.5C32.053 16 32.5 16.448 32.5 17C32.5 17.552 32.053 18 31.5 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M6.5 33C6.24 33 5.979 32.89 5.79 32.71C5.609 32.52 5.5 32.26 5.5 32C5.5 31.74 5.609 31.48 5.79 31.29C6.16 30.92 6.84 30.92 7.21 31.3C7.39 31.48 7.5 31.74 7.5 32C7.5 32.26 7.39 32.52 7.21 32.71C7.02 32.89 6.76 33 6.5 33Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M18.5 33H10.5C9.947 33 9.5 32.552 9.5 32C9.5 31.448 9.947 31 10.5 31H18.5C19.053 31 19.5 31.448 19.5 32C19.5 32.552 19.053 33 18.5 33Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37.5 18H35.5C34.947 18 34.5 17.552 34.5 17C34.5 16.448 34.947 16 35.5 16H37.5C38.053 16 38.5 16.448 38.5 17C38.5 17.552 38.053 18 37.5 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M28.5 23H6.5C5.947 23 5.5 22.552 5.5 22C5.5 21.448 5.947 21 6.5 21H28.5C29.053 21 29.5 21.448 29.5 22C29.5 22.552 29.053 23 28.5 23Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37.5 23H31.5C30.947 23 30.5 22.552 30.5 22C30.5 21.448 30.947 21 31.5 21H37.5C38.053 21 38.5 21.448 38.5 22C38.5 22.552 38.053 23 37.5 23Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M10.5 28H6.5C5.947 28 5.5 27.552 5.5 27C5.5 26.448 5.947 26 6.5 26H10.5C11.053 26 11.5 26.448 11.5 27C11.5 27.552 11.053 28 10.5 28Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M24.5 28H14.5C13.947 28 13.5 27.552 13.5 27C13.5 26.448 13.947 26 14.5 26H24.5C25.053 26 25.5 26.448 25.5 27C25.5 27.552 25.053 28 24.5 28Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37.5 28H28.5C27.947 28 27.5 27.552 27.5 27C27.5 26.448 27.947 26 28.5 26H37.5C38.053 26 38.5 26.448 38.5 27C38.5 27.552 38.053 28 37.5 28Z"
                        fill="#C8BDB8"
                    />
                </svg>
            );
        case 'video/x-ms-asf':
        case 'video/x-ms-wmv':
        case 'video/x-ms-wmx':
        case 'video/x-ms-wm':
        case 'video/avi':
        case 'video/divx':
        case 'video/x-flv':
        case 'video/quicktime':
        case 'video/mpeg':
        case 'video/mp4':
        case 'video/ogg':
        case 'video/webm':
        case 'video/x-matroska':
            return (
                <svg width={width} height={height} viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.485 0H1.463C0.655 0 0 0.655 0 1.926V55C0 55.345 0.655 56 1.463 56H41.537C42.345 56 43 55.345 43 55V12.978C43 12.282 42.907 12.058 42.743 11.893L31.107 0.257C30.942 0.093 30.718 0 30.485 0Z"
                        fill="#E9E9E0"
                    />
                    <path
                        d="M41.537 56H1.463C0.655 56 0 55.345 0 54.537V39H43V54.537C43 55.345 42.345 56 41.537 56Z"
                        fill="#FF5364"
                    />
                    <path
                        d="M15.8998 42.924H17.5678V53H15.8998V46.068L13.6438 51.673H12.1948L9.92484 46.068V53H8.25684V42.924H9.92484L12.9188 49.815L15.8998 42.924Z"
                        fill="white"
                    />
                    <path
                        d="M21.7108 53H20.0698V42.924H22.9678C23.3958 42.924 23.8198 42.992 24.2388 43.129C24.6578 43.266 25.0338 43.471 25.3668 43.744C25.6998 44.017 25.9688 44.348 26.1738 44.735C26.3788 45.122 26.4818 45.557 26.4818 46.041C26.4818 46.552 26.3948 47.014 26.2218 47.429C26.0488 47.844 25.8068 48.193 25.4968 48.475C25.1868 48.757 24.8128 48.976 24.3758 49.131C23.9388 49.286 23.4548 49.363 22.9268 49.363H21.7098V53H21.7108ZM21.7108 44.168V48.16H23.2148C23.4148 48.16 23.6128 48.126 23.8098 48.057C24.0058 47.989 24.1858 47.877 24.3498 47.722C24.5138 47.567 24.6458 47.351 24.7458 47.073C24.8458 46.795 24.8958 46.451 24.8958 46.041C24.8958 45.877 24.8728 45.687 24.8278 45.474C24.7818 45.26 24.6888 45.055 24.5478 44.859C24.4058 44.663 24.2078 44.499 23.9528 44.367C23.6978 44.235 23.3598 44.169 22.9408 44.169H21.7108V44.168Z"
                        fill="white"
                    />
                    <path
                        d="M31.9787 50.648H27.6177V49.35L31.9787 42.924H33.6467V49.35H34.6997V50.649H33.6467V53H31.9787V50.648ZM31.9787 49.35V44.838L29.0797 49.35H31.9787Z"
                        fill="white"
                    />
                    <path
                        d="M18 28C17.834 28 17.669 27.959 17.519 27.877C17.199 27.701 17 27.365 17 27V13C17 12.635 17.199 12.299 17.519 12.123C17.84 11.948 18.229 11.961 18.538 12.156L29.538 19.156C29.825 19.34 30 19.658 30 20C30 20.342 29.825 20.66 29.537 20.844L18.537 27.844C18.374 27.947 18.187 28 18 28ZM19 14.821V25.178L27.137 20L19 14.821Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M22 35C13.729 35 7 28.271 7 20C7 11.729 13.729 5 22 5C30.271 5 37 11.729 37 20C37 28.271 30.271 35 22 35ZM22 7C14.832 7 9 12.832 9 20C9 27.168 14.832 33 22 33C29.168 33 35 27.168 35 20C35 12.832 29.168 7 22 7Z"
                        fill="#C8BDB8"
                    />
                    <path d="M31 0.151001V12H42.849L31 0.151001Z" fill="#D9D7CA" />
                </svg>
            );
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-word.document.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.template.macroEnabled.12':
            return (
                <svg width={width} height={height} viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.485 0H1.463C0.655 0 0 0.655 0 1.926V55C0 55.345 0.655 56 1.463 56H41.537C42.345 56 43 55.345 43 55V12.978C43 12.282 42.907 12.058 42.743 11.893L31.107 0.257C30.942 0.093 30.718 0 30.485 0Z"
                        fill="#E9E9E0"
                    />
                    <path d="M31 0.151001V12H42.849L31 0.151001Z" fill="#D9D7CA" />
                    <path
                        d="M41.537 56H1.463C0.655 56 0 55.345 0 54.537V39H43V54.537C43 55.345 42.345 56 41.537 56Z"
                        fill="#95A5A5"
                    />
                    <path
                        d="M12 13H6C5.447 13 5 12.552 5 12C5 11.448 5.447 11 6 11H12C12.553 11 13 11.448 13 12C13 12.552 12.553 13 12 13Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M15 18H6C5.447 18 5 17.552 5 17C5 16.448 5.447 16 6 16H15C15.553 16 16 16.448 16 17C16 17.552 15.553 18 15 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M19 18C18.74 18 18.479 17.89 18.29 17.71C18.109 17.52 18 17.27 18 17C18 16.73 18.109 16.48 18.3 16.29C18.66 15.92 19.34 15.92 19.71 16.29C19.89 16.48 20 16.74 20 17C20 17.26 19.89 17.52 19.71 17.71C19.52 17.89 19.26 18 19 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M31 18H23C22.447 18 22 17.552 22 17C22 16.448 22.447 16 23 16H31C31.553 16 32 16.448 32 17C32 17.552 31.553 18 31 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M6 33C5.74 33 5.479 32.89 5.29 32.71C5.109 32.52 5 32.26 5 32C5 31.74 5.109 31.48 5.29 31.29C5.66 30.92 6.34 30.92 6.71 31.3C6.89 31.48 7 31.74 7 32C7 32.26 6.89 32.52 6.71 32.71C6.52 32.89 6.26 33 6 33Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M18 33H10C9.447 33 9 32.552 9 32C9 31.448 9.447 31 10 31H18C18.553 31 19 31.448 19 32C19 32.552 18.553 33 18 33Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37 18H35C34.447 18 34 17.552 34 17C34 16.448 34.447 16 35 16H37C37.553 16 38 16.448 38 17C38 17.552 37.553 18 37 18Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M28 23H6C5.447 23 5 22.552 5 22C5 21.448 5.447 21 6 21H28C28.553 21 29 21.448 29 22C29 22.552 28.553 23 28 23Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37 23H31C30.447 23 30 22.552 30 22C30 21.448 30.447 21 31 21H37C37.553 21 38 21.448 38 22C38 22.552 37.553 23 37 23Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M10 28H6C5.447 28 5 27.552 5 27C5 26.448 5.447 26 6 26H10C10.553 26 11 26.448 11 27C11 27.552 10.553 28 10 28Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M24 28H14C13.447 28 13 27.552 13 27C13 26.448 13.447 26 14 26H24C24.553 26 25 26.448 25 27C25 27.552 24.553 28 24 28Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M37 28H28C27.447 28 27 27.552 27 27C27 26.448 27.447 26 28 26H37C37.553 26 38 26.448 38 27C38 27.552 37.553 28 37 28Z"
                        fill="#C8BDB8"
                    />
                    <path
                        d="M4.01172 53V43.0469H6.95117C7.83073 43.0469 8.61003 43.2428 9.28906 43.6348C9.97266 44.0267 10.5013 44.5827 10.875 45.3027C11.2487 46.0228 11.4355 46.8477 11.4355 47.7773V48.2764C11.4355 49.2197 11.2464 50.0492 10.8682 50.7646C10.4945 51.4801 9.95898 52.0316 9.26172 52.4189C8.56901 52.8063 7.77376 53 6.87598 53H4.01172ZM5.74121 44.4414V51.6191H6.86914C7.77604 51.6191 8.47103 51.3366 8.9541 50.7715C9.44173 50.2018 9.6901 49.3861 9.69922 48.3242V47.7705C9.69922 46.6904 9.46452 45.8656 8.99512 45.2959C8.52572 44.7262 7.8444 44.4414 6.95117 44.4414H5.74121Z"
                        fill="white"
                    />
                    <path
                        d="M21.1016 48.2832C21.1016 49.2585 20.9329 50.1152 20.5957 50.8535C20.2585 51.5872 19.7754 52.1523 19.1465 52.5488C18.5221 52.9408 17.8021 53.1367 16.9863 53.1367C16.1797 53.1367 15.4596 52.9408 14.8262 52.5488C14.1973 52.1523 13.7096 51.5895 13.3633 50.8604C13.0215 50.1312 12.8483 49.2904 12.8438 48.3379V47.7773C12.8438 46.8066 13.0146 45.9499 13.3564 45.207C13.7028 44.4642 14.1882 43.8968 14.8125 43.5049C15.4414 43.1084 16.1615 42.9102 16.9727 42.9102C17.7839 42.9102 18.5016 43.1061 19.126 43.498C19.7549 43.8854 20.2402 44.446 20.582 45.1797C20.9238 45.9089 21.097 46.7588 21.1016 47.7295V48.2832ZM19.3721 47.7637C19.3721 46.6608 19.1624 45.8154 18.7432 45.2275C18.3285 44.6396 17.7383 44.3457 16.9727 44.3457C16.2253 44.3457 15.6396 44.6396 15.2158 45.2275C14.7965 45.8109 14.5824 46.638 14.5732 47.709V48.2832C14.5732 49.377 14.7852 50.2223 15.209 50.8193C15.6374 51.4163 16.2298 51.7148 16.9863 51.7148C17.752 51.7148 18.3398 51.4232 18.75 50.8398C19.1647 50.2565 19.3721 49.4043 19.3721 48.2832V47.7637Z"
                        fill="white"
                    />
                    <path
                        d="M30.4189 49.7598C30.3187 50.8216 29.9268 51.651 29.2432 52.248C28.5596 52.8405 27.6504 53.1367 26.5156 53.1367C25.7227 53.1367 25.0231 52.9499 24.417 52.5762C23.8154 52.1979 23.3506 51.6624 23.0225 50.9697C22.6943 50.277 22.5234 49.4727 22.5098 48.5566V47.627C22.5098 46.6882 22.6761 45.861 23.0088 45.1455C23.3415 44.43 23.8177 43.8786 24.4375 43.4912C25.0618 43.1038 25.7819 42.9102 26.5977 42.9102C27.696 42.9102 28.5801 43.2087 29.25 43.8057C29.9199 44.4027 30.3096 45.2458 30.4189 46.335H28.6963C28.6143 45.6195 28.4046 45.1045 28.0674 44.79C27.7347 44.471 27.2448 44.3115 26.5977 44.3115C25.8457 44.3115 25.2669 44.5872 24.8613 45.1387C24.4603 45.6855 24.2552 46.4899 24.2461 47.5518V48.4336C24.2461 49.5091 24.4375 50.3294 24.8203 50.8945C25.2077 51.4596 25.7728 51.7422 26.5156 51.7422C27.1947 51.7422 27.7051 51.5895 28.0469 51.2842C28.3887 50.9788 28.6051 50.4707 28.6963 49.7598H30.4189Z"
                        fill="white"
                    />
                    <path
                        d="M35.3955 46.6426L37.4873 43.0469H39.4834L36.4756 47.9824L39.5518 53H37.5352L35.3955 49.3496L33.249 53H31.2393L34.3223 47.9824L31.3076 43.0469H33.3037L35.3955 46.6426Z"
                        fill="white"
                    />
                </svg>
            );
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-access':
        case 'application/vnd.ms-project':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'application/vnd.ms-excel.template.macroEnabled.12':
        case 'application/vnd.ms-excel.addin.macroEnabled.12':
            return (
                <svg width={width} height={height} viewBox="0 0 43 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M30.485 0H1.463C0.655 0 0 0.655 0 1.926V55C0 55.345 0.655 56 1.463 56H41.537C42.345 56 43 55.345 43 55V12.978C43 12.282 42.907 12.058 42.743 11.893L31.107 0.257C30.942 0.093 30.718 0 30.485 0Z"
                        fill="#E9E9E0"
                    />
                    <path d="M31 0.151001V12H42.849L31 0.151001Z" fill="#D9D7CA" />
                    <path
                        d="M41.537 56H1.463C0.655 56 0 55.345 0 54.537V39H43V54.537C43 55.345 42.345 56 41.537 56Z"
                        fill="#91CDA0"
                    />
                    <path
                        d="M9.87921 48.105L12.4362 53H10.5362L8.93621 49.199H8.79921L7.07621 53H5.17621L7.73321 48.105L5.01221 42.923H6.88521L8.66221 47.025H8.79921L10.7272 42.923H12.6002L9.87921 48.105Z"
                        fill="white"
                    />
                    <path
                        d="M34.867 48.105L37.424 53H35.524L33.924 49.199H33.787L32.064 53H30.164L32.721 48.105L30 42.923H31.873L33.65 47.025H33.787L35.715 42.923H37.588L34.867 48.105Z"
                        fill="white"
                    />
                    <path d="M16.5371 42.9241V51.7561H21.1721V53.0001H14.8691V42.9241H16.5371Z" fill="white" />
                    <path
                        d="M28.541 50.238C28.541 50.602 28.466 50.956 28.315 51.298C28.164 51.64 27.953 51.94 27.68 52.2C27.407 52.46 27.069 52.667 26.668 52.822C26.267 52.977 25.811 53.054 25.301 53.054C25.082 53.054 24.857 53.042 24.624 53.02C24.391 52.998 24.157 52.958 23.92 52.904C23.683 52.849 23.457 52.774 23.243 52.678C23.029 52.582 22.844 52.466 22.689 52.329L22.976 51.153C23.103 51.226 23.265 51.297 23.461 51.365C23.657 51.433 23.859 51.497 24.069 51.556C24.278 51.616 24.488 51.663 24.698 51.7C24.907 51.736 25.103 51.755 25.286 51.755C25.842 51.755 26.268 51.625 26.564 51.365C26.86 51.105 27.008 50.72 27.008 50.21C27.008 49.9 26.903 49.636 26.694 49.417C26.484 49.198 26.222 49 25.908 48.822C25.594 48.644 25.254 48.467 24.889 48.289C24.524 48.111 24.182 47.901 23.864 47.66C23.545 47.419 23.281 47.134 23.071 46.806C22.861 46.478 22.757 46.068 22.757 45.576C22.757 45.13 22.839 44.733 23.003 44.387C23.167 44.041 23.388 43.746 23.666 43.505C23.944 43.264 24.268 43.079 24.637 42.951C25.006 42.823 25.396 42.76 25.806 42.76C26.225 42.76 26.649 42.799 27.077 42.876C27.505 42.953 27.851 43.079 28.116 43.252C28.061 43.37 27.997 43.5 27.925 43.642C27.852 43.784 27.783 43.915 27.72 44.038C27.656 44.161 27.601 44.264 27.556 44.346C27.51 44.428 27.483 44.474 27.474 44.483C27.419 44.456 27.358 44.42 27.289 44.374C27.22 44.328 27.122 44.283 26.995 44.237C26.867 44.191 26.699 44.16 26.489 44.141C26.279 44.122 26.01 44.127 25.682 44.155C25.499 44.174 25.327 44.225 25.162 44.312C24.997 44.399 24.852 44.505 24.724 44.633C24.596 44.761 24.496 44.904 24.423 45.064C24.35 45.223 24.314 45.377 24.314 45.522C24.314 45.886 24.418 46.18 24.628 46.404C24.837 46.628 25.097 46.823 25.407 46.992C25.717 47.161 26.054 47.325 26.419 47.484C26.783 47.643 27.123 47.838 27.438 48.065C27.753 48.292 28.014 48.578 28.224 48.919C28.436 49.261 28.541 49.7 28.541 50.238Z"
                        fill="white"
                    />
                    <path
                        d="M17 16V12H5V16V18V20V22V24V26V28V30V34H15H17H38V30V28V26V24V22V20V16H17ZM7 14H15V16H7V14ZM7 18H15V20H7V18ZM7 22H15V24H7V22ZM7 26H15V28H7V26ZM15 32H7V30H15V32ZM36 32H17V30H36V32ZM36 28H17V26H36V28ZM36 24H17V22H36V24ZM17 20V18H36V20H17Z"
                        fill="#C8BDB8"
                    />
                </svg>
            );
        default:
            return (
                <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2663:314651)">
                        <path
                            d="M16.065 0H3.627C3.28071 0 3 0.280714 3 0.825429V23.5714C3 23.7193 3.28071 24 3.627 24H20.8016C21.1479 24 21.4286 23.7193 21.4286 23.5714V5.562C21.4286 5.26371 21.3887 5.16771 21.3184 5.097L16.3316 0.110143C16.2609 0.0398571 16.1649 0 16.065 0Z"
                            fill="#E9E9E0"
                        />
                        <path d="M16.2856 0.0646973V5.14284H21.3638L16.2856 0.0646973Z" fill="#D9D7CA" />
                        <path
                            d="M20.8016 24.0001H3.627C3.28071 24.0001 3 23.7194 3 23.3731V16.7144H21.4286V23.3731C21.4286 23.7194 21.1479 24.0001 20.8016 24.0001Z"
                            fill="#8B93DA"
                        />
                        <path
                            d="M6.71486 18.5331V19.8926H8.51957V20.373H6.71486V22.3183H6V18H8.70086V18.5331H6.71486Z"
                            fill="white"
                        />
                        <path
                            d="M15.7149 18.5331V19.8926H17.5196V20.373H15.7149V22.3183H15V18H17.7009V18.5331H15.7149Z"
                            fill="white"
                        />
                        <path d="M17.7009 21.7461H15V22.3242H17.7009V21.7461Z" fill="white" />
                        <path d="M10.7383 22.2656H10V18H10.7383V22.2656Z" fill="white" />
                        <path d="M12.7149 18V21.7851H14.7013V22.3183H12V18H12.7149Z" fill="white" />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.37504 5.625H12.9751C13.2845 5.625 13.5813 5.74792 13.8001 5.96671L15.45 7.61662C15.6688 7.83542 15.7917 8.13216 15.7917 8.44158V13.2083C15.7917 13.8527 15.2694 14.375 14.625 14.375H9.37504C8.73071 14.375 8.20837 13.8527 8.20837 13.2083V6.79167C8.20837 6.14733 8.73071 5.625 9.37504 5.625Z"
                            fill="#8B93DA"
                            stroke="#8B93DA"
                            strokeWidth="0.758333"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.4968 5.62502C12.4968 5.41561 12.6666 5.24585 12.876 5.24585H13.5C13.6003 5.24585 13.6965 5.28557 13.7675 5.35633L16.0592 7.63811C16.1307 7.70926 16.1708 7.80595 16.1708 7.9068V8.54168C16.1708 8.75109 16.0011 8.92085 15.7917 8.92085H14.0427C13.1889 8.92085 12.4968 8.22876 12.4968 7.37502V5.62502Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_2663:314651">
                            <rect width={24} height={24} fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
    }
};
