import React, { useReducer, useImperativeHandle, forwardRef, useRef } from 'react';
import ManageBuilding from 'app/modules/jobdetail/tabs/unit/manage/building';
import ManageUnit from 'app/modules/jobdetail/tabs/unit/manage/unit';
import DetailUnit from 'app/modules/jobdetail/tabs/unit/manage/detail';
import Footer from 'app/modules/jobdetail/tabs/unit/manage/Footer';
import ManageUnitContextProvider from 'app/modules/jobdetail/contexts/ManageUnitContext';
import { reducer } from 'app/const/Reducer';

const ManageBuildingUnit = forwardRef(
    ({ unitContextData, onUpdate = () => {}, onClose = () => {}, parentContact = [], parentTotalContact = 0 }, ref) => {
        const [state, dispatchState] = useReducer(reducer, { isVisible: false });
        const refUnitDetail = useRef(null);

        useImperativeHandle(ref, () => ({
            open: _handleOpen,
            close: _handleClose
        }));

        function _handleClose() {
            dispatchState({
                isVisible: false
            });
            onClose();
        }

        function _handleOpen() {
            dispatchState({
                isVisible: true
            });
        }

        function _getData() {
            const litBuilding = [...unitContextData.buildingData].filter((item) => item.deleted !== -1);
            const buildSelected = litBuilding.length === 0 ? null : litBuilding[0];
            const listUnit = buildSelected ? [...buildSelected.units] : [];

            return {
                buildingData: litBuilding,
                buildingSelected: Array.isArray(litBuilding) && litBuilding.length > 0 ? litBuilding[0].id : '',
                unitSelected: Array.isArray(listUnit) && listUnit.length > 0 ? listUnit[0].id : '',
                unitData: unitContextData.unitData,
                jobId: unitContextData.jobId,
                locationId: unitContextData.locationId,
                customerId: unitContextData.customerId,
                withoutFetch: !!unitContextData.withoutFetch
            };
        }

        function _handleSave(callback) {
            refUnitDetail.current && refUnitDetail.current.onSave(callback);
        }

        if (!state.isVisible) return null;
        return (
            <div className="modal modal--mdu container-modal --contains open">
                <div className="modal__overlay bg-fixed" />
                <ManageUnitContextProvider manageUnitData={_getData()}>
                    <div className="modal__container">
                        <div className="header-modal">
                            <h2 className="header-modal__label">Manage Units</h2>
                        </div>
                        <div className="body-modal wrap-mdu --has-details">
                            <ManageBuilding onUpdate={onUpdate} />
                            <ManageUnit onUpdate={onUpdate} />
                            <DetailUnit
                                ref={refUnitDetail}
                                onUpdate={onUpdate}
                                parentContact={parentContact}
                                parentTotalContact={parentTotalContact}
                            />
                        </div>
                        <Footer onSave={_handleSave} onClose={_handleClose} />
                    </div>
                </ManageUnitContextProvider>
            </div>
        );
    }
);

export default ManageBuildingUnit;
