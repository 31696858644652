import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CalendarDropdown from '../CalendarDropdown';

function TaskAssignee({ defaultAssign, onSelect = () => {} }) {
    const [selected, setSelected] = useState(null);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const refDropdown = useRef(null);

    const finalSelectedName = selected ? selected.first_name.concat(' ', selected.last_name) : defaultAssign.full_name;
    const finalSelected = selected || defaultAssign;

    function _handleSelectUser(user) {
        if (user.id === finalSelected) return;
        refDropdown.current._closeDropdown();
        onSelect('assignee', user);
        setSelected(user);
    }

    return (
        <CalendarDropdown id="list_task_assign_to_edit" ref={refDropdown} selected={finalSelectedName}>
            <CompanyUsers data={companyUsers} onSelect={_handleSelectUser} selected={finalSelected} />
        </CalendarDropdown>
    );
}

const CompanyUsers = ({ data = [], selected = {}, onSelect = () => {} }) => {
    return (
        <ul>
            {data.map((user) => (
                <li
                    key={user.id}
                    className={classNames('items', { active: user.id === selected?.id })}
                    onClick={() => onSelect(user)}
                >
                    <div className="txt-ellipsis">{user.first_name.concat(' ', user.last_name)}</div>
                </li>
            ))}
        </ul>
    );
};

TaskAssignee.propTypes = {};

export default TaskAssignee;
