export default function IconMenuLead({ isMenuActive = false }) {
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#F3743E"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.9995 13L19.0001 16V21.5858C19.0001 21.851 18.8947 22.1054 18.7072 22.2929L16.2929 24.7071C16.1054 24.8946 15.8511 25 15.5858 25H14.0001C13.4478 25 13.0001 24.5523 13.0001 24V16L9.99951 13H21.9995Z"
                    fill="white"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23 8C23.5523 8 24 8.44772 24 9V10.1716C24 10.702 23.7893 11.2107 23.4143 11.5858L22.9985 12H8.99951L8.6063 11.6062C8.21917 11.2191 8.00766 10.6902 8.02111 10.1429L8.04981 8.97543C8.06314 8.43288 8.50679 8 9.0495 8H23Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FEF4EF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.9995 13L19.0001 16V21.5858C19.0001 21.851 18.8947 22.1054 18.7072 22.2929L16.2929 24.7071C16.1054 24.8946 15.8511 25 15.5858 25H14.0001C13.4478 25 13.0001 24.5523 13.0001 24V16L9.99951 13H21.9995Z"
                fill="#F3743E"
            />
            <path
                opacity="0.6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 8C23.5523 8 24 8.44772 24 9V10.1716C24 10.702 23.7893 11.2107 23.4143 11.5858L22.9985 12H8.99951L8.6063 11.6062C8.21917 11.2191 8.00766 10.6902 8.02111 10.1429L8.04981 8.97543C8.06314 8.43288 8.50679 8 9.0495 8H23Z"
                fill="#F3743E"
            />
        </svg>
    );
}
