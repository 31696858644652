import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import StripeContentInfo from 'app/components/payment/StripeContentInfo';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';

const ConfirmCharge = (
    { onSubmit, onSubmitNewCard, isDisableOnCharge = false, isNotCloseClickOutside = false },
    ref
) => {
    const { t } = useTranslation('setting');
    const refButtonSave = useRef(null);
    const refStripeForm = useRef(null);
    const refStatusBar = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        cardDefault: null,
        isStripeForm: false,
        isDisable: false,
        planInfo: null
    });

    useImperativeHandle(ref, () => ({ _open, _close, _removeLoading, _showLoading, _setDisable }));

    const _open = (cardDefault, planInfo) => {
        dispatchState({ isVisible: true, cardDefault, isStripeForm: !!!cardDefault, planInfo });
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const { cardDefault: finalCardDefault, isDisable, planInfo } = state;

    const _close = (e) => {
        if (e && state.isStripeForm) {
            dispatchState({ isStripeForm: false, isVisible: !!finalCardDefault });
        } else {
            dispatchState({ isVisible: false, isStripeForm: false, isDisable: false });
        }
    };

    const _removeLoading = (message) => {
        refButtonSave.current && refButtonSave.current.removeLoading();
        refStatusBar.current.showStatusBar('success', message, LIST_STATUS.ERROR);
        isDisableOnCharge && dispatchState({ isDisable: false });
    };

    const _showLoading = () => {
        dispatchState({ isVisible: true });
        setTimeout(() => {
            refButtonSave.current && refButtonSave.current.setLoading(true);
        }, 0);
    };

    function _handleUseCard() {
        isDisableOnCharge && dispatchState({ isDisable: true });
        onSubmit();
    }

    const _handleUseNewCard = async () => {
        const tokenCard = await refStripeForm.current._getTokenCardStripe();

        if (tokenCard.token) {
            onSubmitNewCard(tokenCard);
        } else {
            refStatusBar.current.showStatusBar('success', tokenCard.message, LIST_STATUS.ERROR);
            refButtonSave.current.removeLoading();
        }
    };

    function _handleOpenStripeForm(e) {
        e.stopPropagation();
        dispatchState({
            isStripeForm: true
        });
    }

    const _setDisable = (value = false) => {
        dispatchState({ isDisable: value });
    };

    const _renderPlanInfo = ({ defaultClassName = 'fs-16 fw-600 black-2 mb-2 pl-4' }) => {
        if (!planInfo) return null;
        return (
            <p className={defaultClassName}>
                {!!planInfo?.plan ? `${t('select_plan')}: ${planInfo.plan}` : null}
                {!!planInfo?.price ? <span className="ml-2">{planInfo.price}</span> : null}
            </p>
        );
    };

    function _renderFormConfirm() {
        return (
            <div className="modal__container --lg">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">Payment</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </span>
                </div>
                <StatusBar ref={refStatusBar} />
                <div className={classNames('body-modal', { 'is-disable': isDisable })}>
                    {_renderPlanInfo({})}
                    <div className="flex-centeritem">
                        <ButtonSave
                            wrapClass={'v2-btn-main js-thankyou'}
                            title={`Use current card **** **** **** ${finalCardDefault?.account_number || ''}`}
                            ref={refButtonSave}
                            onSave={_handleUseCard}
                        />
                        <div onClick={(e) => _handleOpenStripeForm(e)} className="v2-btn-main btn-modal ml-2">
                            Use different card
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function _renderFormStripeCard() {
        return (
            <div className="modal__container form-addcard stripe-additional">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">Payment</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </span>
                </div>
                <div className="content has-form scrolls">
                    <StatusBar ref={refStatusBar} />
                    {_renderPlanInfo({ defaultClassName: 'fs-16 fw-600 black-2 mb-2' })}
                    <StripeContentInfo showNameCard ref={refStripeForm} isGDCompany />
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default" onClick={_close}>
                        Cancel
                    </span>
                    <ButtonSave title={'Subscribe'} ref={refButtonSave} onSave={_handleUseNewCard} />
                </div>
            </div>
        );
    }

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            onRequestClose={_close}
            style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
            className="modal container-modal modal-confirm-delete open"
        >
            <div className="modal__overlay bg-fixed" onClick={() => !isNotCloseClickOutside && _close()} />
            {!state.isStripeForm ? _renderFormConfirm() : _renderFormStripeCard()}
        </ReactModal>
    );
};

export default forwardRef(ConfirmCharge);
