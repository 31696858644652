import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const InvoiceService = ({ onOpenInvoiceDetail }) => {
    const { invoiceData } = useSelector(({ invoiceReducer }) => invoiceReducer);
    const { openTime } = useSelector(({ notesReducer }) => notesReducer.toggleInsert || {});
    const openTimeInsertImages = useSelector(({ photosReducer }) => photosReducer.toggleInsert?.openTime);

    const dispatch = useDispatch();

    useEffect(() => {
        onOpenInvoiceDetail(invoiceData);

        if (!invoiceData) {
            if (openTime) {
                dispatch(actionToggleInsertJobNotes(null));
            }
            if (openTimeInsertImages) {
                dispatch(actionToggleInsertJobImages(null));
            }
        }
    }, [invoiceData]);

    return false;
};

export default InvoiceService;
