import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Service = ({ onOnpenJobDetail, onCloseJobDetail }) => {
    const { jobDetail, closeJobDetail } = useSelector(({ jobDetailReducer }) => jobDetailReducer);

    useEffect(() => {
        onOnpenJobDetail(jobDetail);
    }, [jobDetail]);

    useEffect(() => {
        closeJobDetail && onCloseJobDetail();
    }, [closeJobDetail]);

    return false;
};

export default Service;
