export const NOTES_TEMPLATES_ACTION = {
    RESET_NOTES_TEMPLATES: 'RESET_NOTES_TEMPLATES',
    TOGGLE_IMPORT_JOB_NOTES: 'TOGGLE_IMPORT_JOB_NOTES'
};

export const actionResetNoteTemplates = () => {
    return {
        type: NOTES_TEMPLATES_ACTION.RESET_NOTES_TEMPLATES
    };
};

export const actionToggleInsertJobNotes = (params) => {
    return {
        params,
        type: NOTES_TEMPLATES_ACTION.TOGGLE_IMPORT_JOB_NOTES
    };
};
