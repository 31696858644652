import React, { useContext, useReducer, useRef, useEffect } from 'react';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { TABS_ENDS } from 'app/modules/jobdetail/const';
import { convertTimeToISO, formartDateWithString } from 'common/utils/DateUtils';
import IconFlag from 'assets/icon/IconFlag';
import { reducer } from 'app/const/Reducer';

const Ends = ({ typeEnd = 'jobs', isEvent = false }) => {
    const refDropDown = useRef(null);
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCurrentceDetail = repeatSettings.recurrence.offset || {};
    const jobDetailRepeatEnd = jobCurrentceDetail.ends;

    const [state, dispatchState] = useReducer(reducer, {
        tabEndsActive: jobDetailRepeatEnd || TABS_ENDS.MODE_NEVER,
        showCalendar: false
    });

    useEffect(() => {
        dispatchState({
            tabEndsActive: jobDetailRepeatEnd
        });
    }, [jobDetailRepeatEnd]);

    const finalTabEndsActive = state.tabEndsActive;

    const isTabNever = finalTabEndsActive === TABS_ENDS.MODE_NEVER;
    const isTabAfter = finalTabEndsActive === TABS_ENDS.MODE_AFTER;
    const isTabOndate = finalTabEndsActive === TABS_ENDS.MODE_ON_DATE;
    const isShowCalendar = state.showCalendar;

    //Dropdow
    useEffect(() => {
        if (isShowCalendar) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isShowCalendar]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    function _closeDropdown() {
        dispatchState({ showCalendar: false });
    }

    function _handleChangeTab(e, value) {
        let refValue = { ...repeatSettings };

        switch (value) {
            case TABS_ENDS.MODE_ON_DATE:
                dispatchState({ tabEndsActive: value, showCalendar: !isShowCalendar });

                const finalString = moment().format('YYYY-MM-DD HH:mm:ss');

                const newOption = {
                    ...jobCurrentceDetail,
                    ends: TABS_ENDS.MODE_ON_DATE,
                    endDate:
                        jobCurrentceDetail.endDate === ''
                            ? moment.tz(finalString, 'YYYY-MM-DD HH:mm:ss', 'UTC').toISOString()
                            : jobCurrentceDetail.endDate
                };
                refValue = {
                    ...refValue,
                    recurrence: {
                        ...repeatSettings.recurrence,
                        offset: newOption
                    }
                };
                break;
            case TABS_ENDS.MODE_AFTER:
                dispatchState({ tabEndsActive: value, showCalendar: false });

                const newOptionAfter = {
                    ...jobCurrentceDetail,
                    ends: TABS_ENDS.MODE_AFTER,
                    occurances: 1
                };

                refValue = {
                    ...refValue,
                    recurrence: {
                        ...repeatSettings.recurrence,
                        offset: newOptionAfter
                    }
                };

                break;
            case TABS_ENDS.MODE_NEVER:
                dispatchState({ tabEndsActive: value, showCalendar: false });

                const newOptionNever = {
                    ...jobCurrentceDetail,
                    ends: TABS_ENDS.MODE_NEVER,
                    occurances: 1
                };

                refValue = {
                    ...refValue,
                    recurrence: {
                        ...repeatSettings.recurrence,
                        offset: newOptionNever
                    }
                };
                break;
            default:
                break;
        }

        onChangeDataContext(refValue);
    }

    function _onChangeDate(date) {
        dispatchState({ showCalendar: false });

        const newOption = {
            ...jobCurrentceDetail,
            endDate: convertTimeToISO(moment(date).startOf('day'))
        };

        const refValue = {
            ...repeatSettings,
            recurrence: {
                ...repeatSettings.recurrence,
                offset: newOption
            }
        };
        onChangeDataContext(refValue);
    }

    function _handleChangeJobs(interval) {
        const refValue = {
            ...repeatSettings,
            recurrence: {
                ...repeatSettings.recurrence,
                offset: {
                    ...jobCurrentceDetail,
                    occurances: interval
                }
            }
        };
        onChangeDataContext(refValue);
    }

    if (!repeatSettings.repeat) return false;

    return (
        <div className="rows row-has-line border-top-border-color-grey">
            <div className="txt">
                {!isEvent ? <IconFlag /> : null}
                Ends
            </div>
            <div
                ref={refDropDown}
                className={`details details-repeat-end tabs ${isShowCalendar ? 'active-ondate' : ''}`}
            >
                <div className="tabs-daily btn-item m-0">
                    <button
                        className={`tab-items ${isTabNever ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_NEVER)}
                        type="button"
                    >
                        Never
                    </button>
                    <button
                        className={`tab-items js-toggle-after ${isTabAfter ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_AFTER)}
                        type="button"
                    >
                        After
                    </button>
                    <button
                        className={`tab-items js-toggle-ondate ${isTabOndate ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_ON_DATE)}
                        type="button"
                    >
                        On date
                    </button>
                </div>
                {isTabAfter && (
                    <div className="field-every">
                        <FormInterval interval={jobCurrentceDetail.occurances} onChange={_handleChangeJobs} />
                        <span className="text-last">{typeEnd}</span>
                    </div>
                )}
                {isShowCalendar && (
                    <div id="show_date_picker" className="monthly-calendar">
                        <DatePicker
                            inline
                            dateFormat="MM-DD-YYYY"
                            selected={formartDateWithString(
                                moment(moment(jobCurrentceDetail.endDate)).utc().format('YYYY-MM-DD HH:mm')
                            )}
                            onSelect={(date) => _onChangeDate(date)}
                            renderCustomHeader={HeaderCustom}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Ends;
