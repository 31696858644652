import React, { useRef } from 'react';

import AmountRow from './AmountRow';
import MenuContentPopper from './MenuContentPopper';

const AmountList = ({
    data = [],
    onToggleActive = () => {},
    onDeleteResource = () => {},
    onToggleUpdate = () => {},
    onUpdate = () => {},
    ...props
}) => {
    const refMenuContent = useRef(null);

    const _handleOpenMenu = (el, data) => {
        el ? refMenuContent.current?.open({ el, data }) : refMenuContent.current?.close();
    };

    const _handleCloseMenu = () => {
        refMenuContent.current?.close();
    };

    const _handleEditResource = (idResource) => {
        _handleCloseMenu();
        onToggleUpdate({ idResource, type: 'on' });
    };

    const _handleDeleteResource = (idResource, type) => {
        _handleCloseMenu();
        onDeleteResource(idResource, type);
    };

    if (!data.length) return null;
    return (
        <>
            {data.map((item) => {
                const { isGroupExpander, _resource = {} } = item;
                const extendedProps = _resource.extendedProps || {};
                const idResource = _resource.id;

                return (
                    <AmountRow
                        id={idResource}
                        key={idResource}
                        isGroupExpander={isGroupExpander}
                        {...extendedProps}
                        {..._resource}
                        {...props}
                        onOpenMenu={_handleOpenMenu}
                        onCloseMenu={_handleCloseMenu}
                        onToggleActive={onToggleActive}
                        onToggleUpdate={onToggleUpdate}
                        onUpdate={onUpdate}
                    />
                );
            })}
            <MenuContentPopper
                ref={refMenuContent}
                onToggleActive={onToggleActive}
                onToggleUpdate={_handleEditResource}
                onDeleteResource={_handleDeleteResource}
            />
        </>
    );
};

export default AmountList;
