import React from 'react';

const UserNotify = ({ userDetail, userId, isActive, handleSelect }) => {

    function _renderAvatar(userData) {
        if (userData?.avatar) {
            return <img src={userData.avatar} alt="" width="24" height="24" />
        } else {
            return <div className="avt fs-11">{`${userData.first_name.substring(0, 1)}${userData.last_name.substring(0, 1)}`}</div>
        }
    }

    function _handleSelect(e){
        e.preventDefault();
        handleSelect(userDetail)
    }

    return (
        <li
            key={userId.toString()}
            className={`items ${isActive ? 'active' : ''}`}
            onClick={_handleSelect}
        >
            <div className="user-name">
                <div className="avt-img">
                    {_renderAvatar(userDetail)}
                </div>
                <span className="txt-ellipsis">{`${userDetail.first_name} ${userDetail.last_name}`}</span>
            </div>
        </li>
    )
};

export default UserNotify;