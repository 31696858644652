import DatePicker from 'app/components/date';
import { KEY_REPORT_LOCAL_STORAGE, TODAY, DATE_FORMAT } from 'app/const/App';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import { reducer } from 'app/const/Reducer';
function ReportDatePicker({ reportType, keyLocal, onSelect }) {
    const paramsLocal =
        getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType)) || getDefaultParams(reportType);

    const defaultDate = {
        [keyLocal]: paramsLocal[keyLocal] || moment(TODAY).format(DATE_FORMAT)
    };

    const [state, dispatchState] = useReducer(reducer, defaultDate);

    const handleSelectDate = (value) => {
        const formatValue = moment(value).format(DATE_FORMAT);
        dispatchState({
            [keyLocal]: formatValue
        });
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), { ...paramsLocal, [keyLocal]: formatValue });
        onSelect && onSelect({ ...paramsLocal, [keyLocal]: formatValue });
    };

    const renderDatePicker = () => {
        return (
            <DatePicker
                onChange={handleSelectDate}
                selectDefault={state[keyLocal]}
                wrapClassInput="header-items has-bg-blue"
            />
        );
    };

    return renderDatePicker();
}

ReportDatePicker.propTypes = {
    reportType: PropTypes.string,
    keyLocal: PropTypes.string
};

ReportDatePicker.defaultProps = {
    keyLocal: 'period_ending'
};

export default ReportDatePicker;
