import React from 'react';

export default function LoadingInsert() {
    return (
        <div className="wrap-loading">
            <div className="header-modal px-3 border-bt-grey">
                <div className="loading --animation --line --half" />
            </div>
            <div className="wrap-content">
                <div className="wrap-images">
                    <div className="border-grey-softlight field-h100 pointer-events-none py-16" />
                    <div className="loading --animation --line --full mt-1" />
                    <div className="loading --animation --line --full" />
                    <div className="loading --animation --line --half" />
                </div>
                <div className="wrap-images">
                    <div className="border-grey-softlight field-h100 pointer-events-none" />
                    <div className="loading --animation --line --full mt-1" />
                    <div className="loading --animation --line --half" />
                </div>
                <div className="wrap-images">
                    <div className="border-grey-softlight field-h100 pointer-events-none" />
                    <div className="loading --animation --line --full mt-1" />
                    <div className="loading --animation --line --half" />
                </div>
            </div>
        </div>
    );
}
