import React, { useContext } from 'react';
import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import moment from 'moment';
import IconCalendar from 'assets/icon/IconCalendar';

const SelectDate = () => {
    const { updatePaymentDataContext } = useContext(AddPaymentContext);

    function _handleChangeDate(dateSelect) {
        updatePaymentDataContext({ date: moment(dateSelect).format('YYYY-MM-DD') || null });
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconCalendar />
                Date
            </div>
            <div className="detail">
                <CalendarDatePicker onChange={_handleChangeDate} selectDefault={''} />
            </div>
        </div>
    );
};

export default SelectDate;
