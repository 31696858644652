import React from 'react';
import GdGridView from 'app/components/grid/GdGridView';
import {
    getGidColumnsTableMaterial,
    getGridColumnsTableDetailed,
    getGidColumnsTableCompletedJob,
    getGridColumnsTableNewYork
} from 'app/const/report/MaterialUse';
import { useTranslation } from 'react-i18next';
import ReportPagination from '../../components/ReportPagination';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { useSelector } from 'react-redux';
import { MATERIAL_USE_SELECT_OPTIONS } from 'app/const/App';

function TableMaterial({ data, isShow, isLoading, orderStatus, isShowCountyColumn, onClickHeaderCallBack }) {
    const country = useSelector(({ auth }) => auth.user.company.country);

    return (
        <>
            {isShow && (
                <GdGridView
                    isLoading={isLoading}
                    classTable="table-multi-column has-footer --material scrolls-x has-text-ellipsis"
                    classTableContent=""
                    content={data}
                    fileTranslation={'report'}
                    handleClick={() => {}}
                    handleClickHeader={(event) => {
                        onClickHeaderCallBack(event);
                    }}
                    {...getGidColumnsTableMaterial(orderStatus, isShowCountyColumn, country)}
                />
            )}
        </>
    );
}

function TableCompletedJob({ data, isShow, isLoading, handleClick }) {
    const { t } = useTranslation(['report']);
    return (
        <>
            {isShow && (
                <GdGridView
                    isLoading={isLoading}
                    classTable="--material scrolls-x"
                    classTableContent=""
                    content={data}
                    fileTranslation={'report'}
                    handleClick={handleClick}
                    title={t('report:completed_job_w_o_a_county_match')}
                    {...getGidColumnsTableCompletedJob()}
                />
            )}
        </>
    );
}

function TableDetailed({
    data,
    isShow,
    isLoading,
    orderStatus,
    columnStatus,
    onClickHeaderCallBack,
    handleClick,
    totalPage,
    handleUpdate,
    countyParam = null
}) {
    const { t } = useTranslation(['report']);
    const country = useSelector(({ auth }) => auth.user.company.country);
    const isNYPRL = countyParam === MATERIAL_USE_SELECT_OPTIONS.NY_PRL;
    const gridColumn = isNYPRL
        ? getGridColumnsTableNewYork(orderStatus, columnStatus)
        : getGridColumnsTableDetailed(orderStatus, columnStatus, country);

    return (
        isShow && (
            <>
                <GdGridView
                    isEmptyFlat
                    isLoading={isLoading}
                    classTable="table-multi-column --material scrolls-x has-footer has-text-ellipsis"
                    classTableContent=""
                    content={data}
                    fileTranslation={'report'}
                    handleClick={handleClick}
                    handleClickHeader={onClickHeaderCallBack}
                    title={t(`report:${isNYPRL ? 'new_york_material_use' : 'all_detailed_material_use'}`)}
                    isScroll
                    {...gridColumn}
                />
                <ReportPagination reportType={REPORT_TYPE.MATERIAL_USE} totalPage={totalPage} onSelect={handleUpdate} />
            </>
        )
    );
}

export { TableMaterial, TableCompletedJob, TableDetailed };
