import React from 'react';

const IconText = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width={22} height={22}>
            <path
                fill="#000000b3"
                d="M13 2.643c0 .355-.28.643-.625.643h-3.75v10.07c0 .356-.28.644-.625.644a.634.634 0 0 1-.625-.643V3.286h-3.75A.634.634 0 0 1 3 2.643C3 2.288 3.28 2 3.625 2h8.75c.346 0 .625.288.625.643Z"
            />
        </svg>
    );
};

export default IconText;
