import { TYPE_SETTING_SERVICE } from 'common/redux/actions/settings/serviceAction';

const servicesReducer = (state = {
    isFirstTime: true,
    data: []
}, action) => {
    switch (action.type) {
        case TYPE_SETTING_SERVICE.SETTING_GET_LIST_SERVICE_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: action.payload?.data || []
            };
        default:
            return state;
    }
};

export default servicesReducer;
