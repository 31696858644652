import React from 'react';
import IconMaterial from 'assets/icon/IconMaterial';
import Actions from 'app/modules/jobdetail/components/actions';
import { LIST_MATERIAL_ACTION_OPTIONS } from 'app/modules/jobdetail/const';

export default function MaterialItem({ item = {}, itemId, onSelected }) {
    if (typeof item !== 'object') {
        return false;
    }

    const { unit = {}, area = {}, material: materialItem = {}, location, target } = item;

    const _renderListTags = (list) => {
        if (!Array.isArray(list) || list.length === 0) return null;

        const finalList =
            list.sort(function (a, b) {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }) || [];

        return finalList.map((item) => {
            return (
                <div key={item.id} className="tag-label --large">
                    {item.name}
                </div>
            );
        });
    };

    return (
        <div key={item.id} className="wrap-material">
            <div className="material rows-menu">
                <div className="material__svg">
                    <IconMaterial />
                </div>
                {materialItem.active ? <div className="material__name txt-ellipsis">{materialItem.name}</div> : ''}
                <div className="material__qty txt-ellipsis">
                    {!!unit.active &&
                        !!parseFloat(unit.quantity) &&
                        `${parseFloat(unit.quantity).toFixed(2)} ${unit.name || ''}`}
                    &nbsp;&nbsp;
                    {item.dilution || ''}
                </div>
                {!!area.active && !!parseFloat(area?.quantity) && (
                    <div className="material__items txt-ellipsis">{`${parseFloat(area.quantity).toFixed(2)} ${
                        area.name || ''
                    }`}</div>
                )}
                <Actions
                    classWrapper="v2-dropdown v2-dropdown--more"
                    itemId={itemId}
                    id={item.id}
                    options={LIST_MATERIAL_ACTION_OPTIONS}
                    onSelected={onSelected}
                />
            </div>
            {!!location.active && <div className="list-material">{_renderListTags(location?.items)}</div>}
            {!!target.active && <div className="list-material">{_renderListTags(target?.items)}</div>}
        </div>
    );
}
