import { SET_APP_LOADING, SET_TOGGLED_SIDEBAR } from 'common/redux/actions/appAction';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const appReducer = (
    state = {
        loading: true,
        toggled: false
    },
    action
) => {
    switch (action.type) {
        case SET_APP_LOADING:
            return {
                ...state,
                loading: action.payload,
                toggled: !!getLocalStorage('toggled')
            };
        case SET_TOGGLED_SIDEBAR: {
            const newToggle = !state.toggled;
            setLocalStorage('toggled', newToggle);
            return { ...state, toggled: newToggle };
        }
        default:
            return state;
    }
};

export default appReducer;
