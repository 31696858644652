import React, { Suspense, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import StatusBar from 'app/components/status/statusbar';
import { ACCOUNT_ROLE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { LIST_STATUS } from 'app/const/Status';
import { CUSTOM_FIELDS } from 'app/const/api/V2';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import {
    CUSTOM_FIELDS_ACTIONS,
    CUSTOM_FIELDS_TABS,
    CUSTOM_FIELDS_VIEWS,
    CUSTOM_FIELDS_VIEWS_VALUES,
    DEFAULT_TOTAL_CUSTOM_FIELDS
} from '../../../customfields/constant';
import { CUSTOM_FIELDS_ACTIONS as CustomFieldReduxActions } from 'common/redux/actions/customFields';
import Header from './Header';
import TableManage from './TableManage';

const CustomFieldModal = React.lazy(() => import('../../../customfields/customFieldModal'));
const CustomFieldConfirm = React.lazy(() => import('app/modules/customfields/components/CustomFieldConfirm'));
const CustomFieldsManage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const isFirstTime = useSelector(({ customFields }) => customFields.isFirstTime);
    const permissions = {
        add: checkPermission(permissionList, PERMISSIONS.addCustomField),
        edit: checkPermission(permissionList, PERMISSIONS.editCustomField)
    };

    // Permissions
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const isSupperAdmin = userProfile.role === ACCOUNT_ROLE.SUPERADMIN;
    const havePermission = ((isTrial || isGrowthPlan) && customFieldAddon) || isSupperAdmin;

    // Local storage
    const reportType = REPORT_TYPE.CUSTOM_FIELDS_MANAGE;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    const paramsLocal = getLocalParamsReport(keyLocal, reportType);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        tabs: CUSTOM_FIELDS_TABS,
        total: DEFAULT_TOTAL_CUSTOM_FIELDS,
        activeTab: paramsLocal?.activeTab || CUSTOM_FIELDS_VIEWS.ACTIVE
    });

    const { data, isLoading, tabs, activeTab } = state;
    const refModal = useRef(null);
    const refStatus = useRef(null);
    const refConfirm = useRef(null);

    const _getCustomFields = () => {
        const params = getLocalStorage(keyLocal) || {};

        const _handleGetSuccess = ({ data = [], total }) => {
            dispatchState((prevState) => {
                return { ...prevState, data, tabs, total, isLoading: false };
            });
        };

        const _handleGetFail = ({ message }) => {
            _showStatus(message);
            dispatchState((prevState) => {
                return { ...prevState, data: [], isLoading: false };
            });
        };

        clientQuery(
            CUSTOM_FIELDS,
            { data: { state: CUSTOM_FIELDS_VIEWS_VALUES[params?.activeTab || 1] }, method: 'GET' },
            _handleGetSuccess,
            _handleGetFail
        );
    };

    useEffect(() => {
        if (!havePermission) return;
        _getCustomFields();
    }, []);

    const _handleAddField = () => {
        refModal.current?.open();
    };

    const _handleEditField = (data) => {
        if (data) refModal.current?.open(data);
    };

    const _handleAction = (type, ids = []) => {
        refConfirm.current?.open(type, ids);
    };

    const _handleChangeTab = (activeTab) => {
        if (activeTab === state.activeTab) return;
        setLocalStorage(keyLocal, { activeTab });
        dispatchState((prevState) => ({ ...prevState, isLoading: isLoading || true, activeTab }));
        _getCustomFields();
    };

    const _showStatus = (message, type = LIST_STATUS.ERROR) => {
        refStatus.current?.showStatusBar('alert', message, type);
    };

    const _handleSuccess = ({ type, data, ids = [], message }) => {
        // Clear all old statuses
        refStatus.current?.clearAllStatusBar();

        switch (type) {
            case CUSTOM_FIELDS_ACTIONS.EDIT:
                dispatchState((prevState) => ({
                    ...prevState,
                    data: prevState.data.map((item) => (item.id === data.id ? data : item))
                }));
                break;
            case CUSTOM_FIELDS_ACTIONS.ADD:
                dispatchState((prevState) => {
                    const newData = [...prevState.data];
                    if (activeTab === CUSTOM_FIELDS_VIEWS.ACTIVE) newData.push(data);
                    return {
                        ...prevState,
                        data: newData,
                        total: {
                            ...prevState.total,
                            [CUSTOM_FIELDS_VIEWS.ACTIVE]: prevState.total[CUSTOM_FIELDS_VIEWS.ACTIVE] + 1
                        }
                    };
                });
                break;
            case CUSTOM_FIELDS_ACTIONS.DELETE:
            case CUSTOM_FIELDS_ACTIONS.ARCHIVE:
            case CUSTOM_FIELDS_ACTIONS.ACTIVE:
            case CUSTOM_FIELDS_ACTIONS.UN_DELETE:
            case CUSTOM_FIELDS_ACTIONS.PERMANENTLY_DELETE:
                refConfirm.current?.close();
                dispatchState((prevState) => {
                    const total = { ...prevState.total };
                    switch (type) {
                        case CUSTOM_FIELDS_ACTIONS.DELETE:
                            if (activeTab === CUSTOM_FIELDS_VIEWS.ACTIVE)
                                total[CUSTOM_FIELDS_VIEWS.ACTIVE] -= ids.length;
                            if (activeTab === CUSTOM_FIELDS_VIEWS.ARCHIVED)
                                total[CUSTOM_FIELDS_VIEWS.ARCHIVED] -= ids.length;
                            total[CUSTOM_FIELDS_VIEWS.DELETED] += ids.length;
                            break;
                        case CUSTOM_FIELDS_ACTIONS.ARCHIVE:
                            if (activeTab === CUSTOM_FIELDS_VIEWS.ACTIVE)
                                total[CUSTOM_FIELDS_VIEWS.ACTIVE] -= ids.length;
                            if (activeTab === CUSTOM_FIELDS_VIEWS.DELETED)
                                total[CUSTOM_FIELDS_VIEWS.DELETED] -= ids.length;
                            total[CUSTOM_FIELDS_VIEWS.ARCHIVED] += ids.length;
                            break;
                        case CUSTOM_FIELDS_ACTIONS.ACTIVE:
                            total[CUSTOM_FIELDS_VIEWS.ARCHIVED] -= ids.length;
                            total[CUSTOM_FIELDS_VIEWS.ACTIVE] += ids.length;
                            break;
                        case CUSTOM_FIELDS_ACTIONS.UN_DELETE:
                            total[CUSTOM_FIELDS_VIEWS.DELETED] -= ids.length;
                            total[CUSTOM_FIELDS_VIEWS.ACTIVE] += ids.length;
                            break;
                        case CUSTOM_FIELDS_ACTIONS.PERMANENTLY_DELETE:
                            total[CUSTOM_FIELDS_VIEWS.DELETED] -= ids.length;
                            break;
                        default:
                            break;
                    }
                    const data = prevState.data.filter((item) => !ids.includes(item.id));
                    return { ...prevState, data, total };
                });
                break;
            default:
                break;
        }

        _showStatus(message, LIST_STATUS.SUCCESS);
        // if custom fields list has been fetched, reset the custom fields list
        !isFirstTime && dispatch({ type: CustomFieldReduxActions.RESET_CUSTOM_FIELD });
    };

    const tabsValue = () => {
        const tabs = { ...state.tabs };
        for (const key in state.total) {
            const value = state.total[key];
            if (tabs[key]) tabs[key].label = t(`${tabs[key].value}_with_ct`, { count: value });
        }
        return Object.values(tabs);
    };

    if (!havePermission) return <ErrorPage errorMessage={t('page_is_unavailable')} />;
    return (
        <>
            <div className="container-print container-wrap container-user-list">
                <Header
                    tabs={tabsValue()}
                    activeTab={activeTab}
                    isLoading={isLoading}
                    isSupperAdmin={isSupperAdmin}
                    onChangeTab={_handleChangeTab}
                    onOpenAddField={_handleAddField}
                />
                <div className="wrap-tables flex-column relative">
                    <StatusBar ref={refStatus} />
                    <TableManage
                        defaultData={data}
                        isLoading={isLoading}
                        activeTab={activeTab}
                        isSupperAdmin={isSupperAdmin}
                        onEdit={_handleEditField}
                        onActions={_handleAction}
                    />
                </div>
            </div>

            <Suspense fallback={null}>
                {permissions.add || permissions.edit ? (
                    <CustomFieldModal ref={refModal} onSuccess={_handleSuccess} />
                ) : null}
            </Suspense>
            <Suspense fallback={null}>
                {isSupperAdmin ? <CustomFieldConfirm ref={refConfirm} onSuccess={_handleSuccess} /> : null}
            </Suspense>
        </>
    );
};

export default CustomFieldsManage;
