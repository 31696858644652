import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { SINGULAR_NOUN } from 'app/const/App';
import { SETTINGS_PLANS } from 'app/const/Route';
import { checkPermission } from 'common/utils/PermissionUtils';
import { TYPE_ACCOUNT, REMAINING_DAYS } from 'app/const/Account';
import { PERMISSIONS } from 'app/const/Permissions';
import IconClose from 'assets/icon/IconClose';
import { addBranchPath } from 'app/const/Branch';

function TrialBar() {
    const history = useHistory();
    const { t } = useTranslation(['common']);

    const [showTrialBar, setShowTrialBar] = useState(false);
    const auth = useSelector((state) => state.auth);

    const permissions = auth?.user?.permissions?.enabled || [];
    const plan = auth?.user?.profile?.plan;
    const typeAccount = plan?.name;
    const remainingDays = plan?.remaining_days ?? REMAINING_DAYS;
    const accessAccount = checkPermission(permissions, PERMISSIONS.accessAccount);
    const isTrial = typeAccount === TYPE_ACCOUNT.FREE_TRIAL && accessAccount;

    useEffect(() => {
        setShowTrialBar(isTrial);
    }, [isTrial]);

    useEffect(() => {
        const elmMainPage = document.getElementById('main-page');

        if (showTrialBar) {
            elmMainPage && elmMainPage.classList.add('has-trialbar');
        } else {
            elmMainPage && elmMainPage.classList.remove('has-trialbar');
        }
    }, [showTrialBar]);

    const _handleClose = () => {
        setShowTrialBar(false);
    };

    const _handleRedirectPagePlans = () => {
        history.push(addBranchPath(SETTINGS_PLANS));
    };

    if (!showTrialBar) {
        return false;
    }

    return (
        <div className="trial-bar">
            <div className="alert --success">
                <p className="alert__description">
                    {remainingDays} {remainingDays === SINGULAR_NOUN ? t('common:day') : t('common:days')}{' '}
                    {t('common:remaining_on_your_trial')}
                    <span className="link cursor-pointer" onClick={_handleRedirectPagePlans}>
                        {t('common:choose_a_plan')}
                    </span>
                </p>
                <div className="alert__btn svg-white-stroke" onClick={_handleClose}>
                    <IconClose />
                </div>
            </div>
        </div>
    );
}

export default TrialBar;
