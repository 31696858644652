import React from 'react';

const Loading = () => {

    return (
        <div className="details-note">
            <div className="dashboard-wrapper --main">
                <div className="wrap-loading content-top-notes">
                    <div className="header-action">
                        <div className="d-flex">
                            <div className="loading v2-btn-default --grey btn-sm justify-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="loading v2-btn-default --grey btn-sm justify-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="loading v2-btn-default --grey btn-lg justify-start">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
                <div className="wrap-loading content-top-notes">
                    <div className="boxs">
                        <div className="left-icons">
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                        </div>
                        <div className="content">
                            <div className="header-box dp-block">
                                <div className="loading --animation --line --onefifth" />
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="wrap-content pt-2">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --threefourth" />
                                <div className="attachment-image mt-2">
                                    <div className="attachment-image-items bg-white-cultured" />
                                    <div className="attachment-image-items ml-1 bg-white-cultured" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-loading content-top-notes pt-0">
                    <div className="boxs">
                        <div className="left-icons">
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                        </div>
                        <div className="content">
                            <div className="header-box">
                                <div className="avt-img" />
                                <div className="name dp-block">
                                    <div className="loading --animation --line --onefifth" />
                                    <div className="loading --animation --line --twothird" />
                                </div>
                            </div>
                            <div className="wrap-content pt-2">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --threefourth" />
                                <div className="attachment-image mt-2">
                                    <div className="attachment-image-items" />
                                    <div className="attachment-image-items ml-1" />
                                </div>
                                <div className="wrap-content-notify">
                                    <div className="notify-items" />
                                    <div className="notify-items" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-loading content-top-notes pt-0">
                    <div className="boxs">
                        <div className="content --comment">
                            <div className="header-box">
                                <div className="avt-img" />
                                <div className="name dp-block">
                                    <div className="loading --animation --line --onefifth" />
                                    <div className="loading --animation --line --twothird" />
                                </div>
                            </div>
                            <div className="wrap-content pt-2">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --half" />
                                <div className="attachment-image mt-2">
                                    <div className="attachment-image-items" />
                                </div>
                                <div className="wrap-content-notify">
                                    <div className="notify-items" />
                                    <div className="notify-items" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-loading --view-task content-top-notes pt-0">
                    <div className="boxs">
                        <div className="left-icons">
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                        </div>
                        <div className="content">
                            <div className="header-box">
                                <div className="loading v2-btn-default --grey btn-x-sm mr-3">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="avt-img" />
                                <div className="name dp-block">
                                    <div className="loading --animation --line --onefifth" />
                                    <div className="loading --animation --line --twothird" />
                                </div>
                            </div>
                            <div className="wrap-content pt-2">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-loading content-top-notes pt-0">
                    <div className="boxs">
                        <div className="left-icons">
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                        </div>
                        <div className="content content-log">
                            <div className="log-title">
                                <div className="loading --animation --line --threefourth" />
                                <div className="loading --animation --line --half" />
                            </div>
                            <p className="log-time loading --animation --line --threefourth" />
                            <div className="log-stt status-btn" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;