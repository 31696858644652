import loadable from '@loadable/component';
import { REPORT_REVENUE_BY_CLIENT_DETAIL_GET_LIST } from 'app/const/Api';
import { getGidColumnsDetailTab, getGidColumnsMonthTab } from 'app/const/report/RevenueByClient';
import { getParamsRevenuePage } from 'common/utils/ReportUtils';
import PropTypes from 'prop-types';
import React, { Fragment, useRef } from 'react';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ReportRevenuePopupDetail = loadable(() => import('app/modules/report/components/ReportRevenuePopupDetail'));

function MonthTabGridView({
    dataReport,
    paramsReport,
    handleActionHeader = () => {},
    handleOpenInvoice,
    monthTab,
    isLoadMore = false,
    onScrollToEnd = () => {}
}) {
    const refPopup = useRef(null);
    const isLoading = dataReport.isLoading;

    const handleClickTable = ({ key, row = {} }) => {
        refPopup.current.openPopup({ ...getParamsRevenuePage({ key, paramsReport }), clients: row.customer?.id });
    };

    return (
        <Fragment>
            <div className="tab-conts tab-content-active">
                <GdGridView
                    isEmptyFlat
                    isLoading={isLoading}
                    classTable="table-multi-column --revenue scrolls-x has-text-ellipsis"
                    classTableContent="--hastotal"
                    content={dataReport?.data}
                    fileTranslation={'report'}
                    handleClick={handleClickTable}
                    handleClickHeader={handleActionHeader}
                    {...getGidColumnsMonthTab(paramsReport?.orderMonth)}
                    rowTotal={(props) => (
                        <GdGridRowTotal
                            columns={dataReport?.rowTotal}
                            contentConfig={getGidColumnsMonthTab()?.contentConfig}
                            showCheckBox={false}
                            isLoading={isLoading}
                            {...props}
                        />
                    )}
                    isScroll
                    isLoadmore={isLoadMore}
                    onScrollToEnd={onScrollToEnd}
                />
            </div>

            <ReportRevenuePopupDetail
                ref={refPopup}
                endpointAPI={REPORT_REVENUE_BY_CLIENT_DETAIL_GET_LIST}
                girdColumn={getGidColumnsDetailTab}
                keyTotalRow={'total_payments_received'}
                handleOpenInvoice={handleOpenInvoice}
                monthTab={monthTab}
            />
        </Fragment>
    );
}

MonthTabGridView.propTypes = {
    dataReport: PropTypes.object,
    paramsReport: PropTypes.object,
    onChange: PropTypes.func,
    handleOpenInvoice: PropTypes.func
};

MonthTabGridView.defaultProp = {
    onChange: () => {},
    handleOpenInvoice: () => {}
};

export { MonthTabGridView };
