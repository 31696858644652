export default function IconCompany() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4961 8.28351L18.4961 11.7121C18.8077 11.8901 19 12.2215 19 12.5803V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V12.5803C5 12.2215 5.19229 11.8901 5.50386 11.7121L11.5039 8.28351C11.8113 8.10783 12.1887 8.10783 12.4961 8.28351ZM9 14C7.89543 14 7 14.8954 7 16V20H11V16C11 14.8954 10.1046 14 9 14ZM15.5 14H14.5C13.6716 14 13 14.6716 13 15.5V16.5C13 17.3284 13.6716 18 14.5 18H15.5C16.3284 18 17 17.3284 17 16.5V15.5C17 14.6716 16.3284 14 15.5 14Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 4C17.5523 4 18 4.44772 18 5L17.9999 7.571L20.53 9.152C20.9983 9.44471 21.1407 10.0617 20.848 10.53C20.5762 10.9649 20.0248 11.1187 19.5725 10.9041L19.47 10.848L12 6.178L4.53001 10.848C4.09513 11.1198 3.5321 11.0165 3.21953 10.6253L3.15201 10.53C2.88021 10.0951 2.98356 9.53209 3.37468 9.21952L3.47001 9.152L11.47 4.152C11.7583 3.97185 12.1154 3.95183 12.4189 4.09195L12.53 4.152L15.9999 6.321L16 5C16 4.44772 16.4477 4 17 4Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
