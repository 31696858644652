/* eslint-disable no-unused-vars */
import { METHOD_API, METHOD_CONVERT } from 'app/const/App';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CardDate from 'app/components/payment/components/CardDate';

const PayMent = ({ invoice, onUpdateSuccess = () => {} }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const listInvoices = invoice?.items || [];
    const listPayments = invoice?.payment_methods || [];

    const _handleOpenInvoice = () => {
        dispatch(
            actionOpenInvoice({
                id: invoice.id,
                status: invoice.status,
                total: invoice.total,
                isRecurring: false,
                onEditSuccess: onUpdateSuccess
            })
        );
    };

    function _renderListInvoice() {
        return listInvoices.map((item) => {
            return (
                <div className="wrap-invoice flex-betweentop" key={item.id.toString()}>
                    <span className="word-break">{item.name}</span>
                    <span className="invoice-value" title={item.cost}>
                        {item.cost}
                    </span>
                </div>
            );
        });
    }

    function _renderListCard() {
        return listPayments.map((item, index) => {
            return (
                <div key={index.toString()} className="info-card mt-1">
                    <div className="card-number">
                        <p className="card-number__label flex-1 txt-ellipsis">{item.type}</p>
                        <div className="number">
                            <p className="number__digit">{item.number.replace(METHOD_API, METHOD_CONVERT)}</p>
                        </div>
                        <CardDate {...item} />
                    </div>
                </div>
            );
        });
    }

    return (
        <div className="wrap-information wrap-payment">
            {!!listInvoices.length && (
                <div className="v2-btn-default btn-bg-purple tooltip mb-1" onClick={_handleOpenInvoice}>
                    <div className="fs-13 fw-600">{`${t('customers:invoice')} #${invoice.invoice_number}`}</div>
                    <p className="tooltiptext top">{t('customers:open_invoice')}</p>
                </div>
            )}

            {_renderListInvoice()}

            {!!invoice && (
                <Fragment>
                    <div className="wrap-invoice flex-betweentop border-bottom-line">
                        <span className="word-break">{t('customers:total')}</span>
                        <span className="invoice-value" title={invoice.total}>
                            {invoice.total}
                        </span>
                    </div>

                    <div className="balance-info flex-betweentop">
                        <div className="word-break">{t('customers:balance')}</div>
                        <div className="invoice-value" title={invoice.balance}>
                            {invoice.balance}
                        </div>
                    </div>
                </Fragment>
            )}

            {_renderListCard()}
        </div>
    );
};

export default PayMent;
