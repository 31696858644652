import React, { Fragment, useContext, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { VOIP_VOICEMAIL_DROP_CALL } from 'app/const/api/Voip';
import { TYPE_CALL_DISCONNECT } from 'app/const/Voip';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconVoicemail from 'assets/icon/IconVoicemail';
import { clientQuery } from 'common/utils/ApiUtils';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import GDModalWarning from 'app/components/modal/ModalWarning';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';

const VoicemailDropCallBar = ({ callData = {}, onDropped = () => {} }) => {
    const { t } = useTranslation(['header', 'addons']);

    const voip = useSelector(({ auth }) => auth.user.settings.addons.voip);
    const { voicemailDrop, onHangUp } = useContext(VOIPContext);
    const isHaveVoicemailDrop = voip?.voicemail_drop;
    const vmLength = voicemailDrop.length;
    const { id: callId, transfer_call_id: idDrop } = callData;

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false
    });
    const { isLoading } = state;

    const refDropdown = useRef(null);
    const refWarning = useRef(null);

    const _handleDropCall = (id) => {
        let finalId = id;
        if (vmLength === 1) finalId = voicemailDrop[0].id;
        if (!finalId) return;
        refDropdown.current?._close();
        dispatchState({ isLoading: true });

        const _handleSuccess = () => {
            onDropped({ voicemailId: finalId });
            onHangUp(callId, { status: TYPE_CALL_DISCONNECT.VOICEMAIL_DROP, excludeDisconnect: true });
        };

        const _handleFail = ({ message }) => {
            refWarning.current?._open({ description: message });
            dispatchState({ isLoading: false });
        };

        clientQuery(
            VOIP_VOICEMAIL_DROP_CALL,
            {
                method: 'POST',
                data: { call_id: idDrop, voicemail_id: finalId },
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderOptions = () => {
        if (vmLength === 1) return <Fragment />;
        return (
            <ul>
                {voicemailDrop.map(({ id, label }) => (
                    <li key={id} className="items" onClick={() => _handleDropCall(id)}>
                        <IconVoicemail />
                        <p className="txt-ellipsis ml-2 flex-1 fw-500">{label || t('addons:voicemail_drop')}</p>
                    </li>
                ))}
            </ul>
        );
    };

    if (!isHaveVoicemailDrop || !vmLength) return null;

    return (
        <div className="call-bar__controll gap-md flexcenter">
            <TooltipPopper
                tooltipText={t('tooltip_voicemail')}
                popperConfig={{
                    placement: 'bottom',
                    modifiers: [{ name: 'offset', options: { offset: [0, 2] } }],
                    strategy: 'fixed'
                }}
            >
                <DropdownPopper
                    ref={refDropdown}
                    wrapperClassName="v2-dropdown wrap-voicemail"
                    wrapperListClass="v2-dropdown__menu --center scrolls"
                    buttonClassName="dropbtn v2-btn-default --transparent btn-bg-grey tooltip"
                    customButton={
                        <Fragment>
                            {isLoading ? <IconLoading isPurple /> : <IconVoicemail />}
                            {vmLength === 1 && (
                                <div className="txt-ellipsis ml-1">
                                    {voicemailDrop[0].label || t('addons:voicemail_drop')}
                                </div>
                            )}
                        </Fragment>
                    }
                    onOpen={() => _handleDropCall()}
                >
                    {_renderOptions()}
                </DropdownPopper>
            </TooltipPopper>

            <GDModalWarning
                ref={refWarning}
                title={t('addons:voicemail_drop')}
                titleButtonConfirm={t('addons:confirm')}
                buttonConfirm
                isLargeContent={false}
                onConfirm={() => refWarning.current?._close()}
            />
        </div>
    );
};

export default VoicemailDropCallBar;
