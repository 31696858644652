import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { LIST_TYPE_CALL_ENDED, TITLE_END_CALL, TYPE_CALL_DISCONNECT, TYPE_OUT_GOING } from 'app/const/Voip';
import IconMicrophone from 'assets/icon/IconMicrophone';
import IconPhone from 'assets/icon/IconPhone';
import TimeCounter from './Time';
import Dialpad from './Dialpad';
import Transfer from './Tranfer';
import IconPlus from 'assets/icon/IconPlus';
import IconAssign from 'assets/icon/IconAssign';
import { CUSTOMERS } from 'app/const/Route';
import { reducer } from 'app/const/Reducer';
import BargeBanner from './BargeBanner';
import ListBarge from './ListBarge';
import { addBranchPath } from 'app/const/Branch';
import { calculateSecondVoip } from 'common/utils/TimeUtils';
import ButtonHoldCall from './components/ButtonHoldCall';
import SelectMultiContact from './SelectMultiContact';
import VoicemailDropCallBar from 'app/modules/addons/voip/components/voicemailDrop/VoicemailDropCallBar';
import ServiceVoicemailDrop from 'app/modules/addons/voip/components/voicemailDrop/ServiceVoicemailDrop';

export default function OutGoing({
    item,
    onDisconnect,
    onTransfer,
    onHangUp,
    onCallBack,
    onMuteCall,
    onCallDial,
    onDelete,
    onCreateLead,
    onAssignLead,
    onInvite,
    features
}) {
    const {
        id: callId,
        status: statusOutgoing,
        to: toPhone,
        is_mute: isMuteCall,
        customer_name,
        customer_title,
        company_name,
        isSyncCustomer,
        transferData,
        customer_id: customerId,
        dataAnswered,
        startTime
    } = item;
    const listMember = item.inviteData?.members;

    const { t } = useTranslation('header');
    const history = useHistory();
    const refInvite = useRef(null);
    const refServiceVmDrop = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        showCallBack: false,
        isShowHoldCall: true
    });

    const isUnknow = !!!customerId && !isSyncCustomer;
    const { showCallBack, isShowHoldCall } = state;

    useEffect(() => {
        if (LIST_TYPE_CALL_ENDED.includes(statusOutgoing)) {
            setTimeout(() => {
                dispatchState({
                    showCallBack: true
                });
            }, 3000);

            setTimeout(() => {
                onDisconnect(callId);
            }, 6000);
        }

        if (statusOutgoing === TYPE_OUT_GOING.TRANSFER) {
            setTimeout(() => {
                onDelete(callId);
            }, 2000);
        }
    }, [statusOutgoing]);

    const _navigateCustomer = () => {
        !isUnknow &&
            customerId &&
            history.push(addBranchPath(`${CUSTOMERS}/${customerId}`), {
                isFromCall: true
            });
    };

    const _handleCallBack = () => {
        onCallBack({
            fromNumber: null,
            toPhoneNumber: toPhone,
            isCallBack: true
        });
        dispatchState({
            showCallBack: false
        });
    };

    const _handleInviteCall = (callData, item) => {
        onInvite(callData, item);
        dispatchState({ isShowHoldCall: false });
    };

    const _handleHoldCall = (value) => {
        refInvite.current?._setDisable(value);
    };

    const _handleVoicemailDrop = (id) => {
        refServiceVmDrop.current?.setVoicemailDrop(id);
    };

    const _renderActions = () => {
        switch (statusOutgoing) {
            case TYPE_OUT_GOING.ACTIVE:
                return (
                    <Fragment>
                        <div className="call-bar__controll gap-md flexcenter">
                            <span className="status-label">Calling ...</span>
                        </div>
                        <span className="is-divider" />
                        <div className="call-bar__action flexcenter pr-2">
                            <span
                                onClick={() => onHangUp(callId, { isSmartDialer: true })}
                                className="action-call is-icon bg-red-default flex-centeritem"
                            >
                                <IconPhone isClose />
                            </span>
                        </div>
                        <span className="call-bar__status w-100 is-calling" />
                    </Fragment>
                );
            case TYPE_OUT_GOING.CALLING:
                const callInfo = {
                    parentId: dataAnswered?.ParentCallSid,
                    callSId: dataAnswered?.CallSid,
                    inviteCallId: dataAnswered?.ParentCallSid
                };

                return (
                    <Fragment>
                        <div className="call-bar__controll gap-md flexcenter">
                            <ListBarge listUser={listMember} />
                            {!!features['call_coaching'] ? (
                                <Fragment>
                                    <Transfer
                                        ref={refInvite}
                                        callData={{ ...item, ...callInfo }}
                                        isInvite
                                        isCloseOnSelect
                                        buttonClassName="btn-invite"
                                        onTransfer={_handleInviteCall}
                                    />
                                    <span className="is-divider" />
                                </Fragment>
                            ) : null}

                            <div
                                onClick={() => onMuteCall(callId, !isMuteCall)}
                                className={`v2-btn-default --icon-lg --transparent btn-bg-grey tooltip ${
                                    isMuteCall ? 'microphone-muted' : ''
                                }`}
                            >
                                <IconMicrophone />
                                <span className="tooltiptext bottom" id="tooltip_mute">
                                    Mute Microphone
                                </span>
                            </div>
                            <Transfer callData={item} onTransfer={onTransfer} isDisable={!!listMember?.length} />
                            <Dialpad onCallDial={onCallDial} callId={callId} />
                            <VoicemailDropCallBar callData={item} onDropped={_handleVoicemailDrop} />
                        </div>
                        <span className="is-divider" />
                        <div className="call-bar__action flexcenter pr-2  gap-none">
                            <TimeCounter isUseTime startAt={calculateSecondVoip(startTime)} />
                            <span
                                onClick={() => onHangUp(callId)}
                                className="action-call is-icon bg-red-default flex-centeritem"
                            >
                                <IconPhone isClose />
                            </span>
                            <ButtonHoldCall
                                callInfo={callInfo}
                                onHoldCall={_handleHoldCall}
                                isHidden={!isShowHoldCall}
                            />
                        </div>
                        <span className="call-bar__status w-100" />
                    </Fragment>
                );
            case TYPE_OUT_GOING.END:
            case TYPE_OUT_GOING.REJECT:
            case TYPE_OUT_GOING.BUSY:
            case TYPE_CALL_DISCONNECT.VOICEMAIL_DROP:
                return (
                    <Fragment>
                        <div className="call-bar__controll gap-md flexcenter">
                            <span className="status-label">{t(TITLE_END_CALL[statusOutgoing])}</span>
                        </div>
                        <span className="is-divider" />
                        <div className="call-bar__action flexcenter pr-2">
                            {showCallBack && (
                                <span
                                    onClick={_handleCallBack}
                                    className="action-call has-label flex-centeritem border-grey-verylight grey-very-dark"
                                >
                                    <IconPhone isBlack />
                                    {t('call_back')}
                                </span>
                            )}
                        </div>
                        <span className="call-bar__status w-100 is-end" />
                    </Fragment>
                );
            case TYPE_OUT_GOING.TRANSFER:
            case TYPE_OUT_GOING.TRANSFERRING:
                const isTranferToUser = transferData.full_name;

                return (
                    <div className="call-bar__action flexcenter pr-2">
                        <span className="flex-auto">{t(`${statusOutgoing}_to`)}</span>
                        <span className="schedule-user">
                            <span className="tech-name m-0">
                                {!!isTranferToUser && (
                                    <img className="avt-img" src={transferData.avatar} width={24} height={24} />
                                )}
                                <span className="tech-name__label">
                                    {!!isTranferToUser ? transferData.full_name : transferData.phone_number}
                                </span>
                            </span>
                        </span>
                    </div>
                );
            case TYPE_OUT_GOING.BARGER:
                return <BargeBanner />;
            default:
                return null;
        }
    };

    return (
        <div className="call-bar flexcenter">
            <span className="call-bar__btn flex-column align-center fw-500">
                <IconPhone isCallOut />
                OUT
            </span>
            <div className="call-bar__info flex-1">
                <div className="gap-md flexcenter">
                    <span
                        onClick={_navigateCustomer}
                        className={`fs-16 fw-500 cursor-pointer ${isUnknow ? 'black-6' : 'is-link'}`}
                    >
                        {customer_name || (isSyncCustomer ? '' : 'Unknown')}
                    </span>
                    {!isUnknow && customer_title ? <div className="label-content">{customer_title}</div> : null}
                    {isUnknow && (
                        <Fragment>
                            <div
                                onClick={() => onCreateLead(callId, toPhone)}
                                className="v2-btn-default has-icon btn-bg-purple --sm svg-purple js-create-lead"
                            >
                                <IconPlus isCircle />
                                Create Lead
                            </div>
                            <div
                                onClick={() => onAssignLead(callId, toPhone)}
                                className="v2-btn-default has-icon btn-bg-purple --sm js-assign-lead"
                            >
                                <IconAssign />
                                Assign Lead
                            </div>
                        </Fragment>
                    )}
                </div>
                <div className="flexcenter mt-2">
                    {!!company_name && (
                        <span
                            onClick={_navigateCustomer}
                            className="status-btn --sm bg-black-light fs-14 purple-default txt-ellipsis"
                        >
                            {company_name}
                        </span>
                    )}
                    <SelectMultiContact item={item} phoneNumber={toPhone} />
                </div>
            </div>
            {_renderActions()}
            <ServiceVoicemailDrop ref={refServiceVmDrop} typeEnd={statusOutgoing} callId={callId} />
        </div>
    );
}
