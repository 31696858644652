import React from 'react';

const LoadingChangeLogs = () => {
    return (
        <div className="wrap-loading">
            <div className="changelog-box__content">
                <div className="rows">
                    <div className="log-date">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="log-content">
                        <div className="log-content__left">
                            <div className="avt-img" />
                            <div className="v2-btn-default --icon-lg --transparent" />
                        </div>
                        <div className="log-content__right">
                            <div className="d-flex align-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="log-date">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="log-content">
                        <div className="log-content__left">
                            <div className="avt-img" />
                            <div className="v2-btn-default --icon-lg --transparent" />
                        </div>
                        <div className="log-content__right">
                            <div className="d-flex align-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingChangeLogs;
