import React, { useContext } from 'react';
import { TABS_REPEAT, EXCEPTON_OPTIONS } from 'app/modules/jobdetail/const';
import Daily from 'app/modules/jobdetail/tabs/settingschedule/repeat/Daily';
import Weekly from 'app/modules/jobdetail/tabs/settingschedule/repeat/Weekly';
import Monthly from 'app/modules/jobdetail/tabs/settingschedule/repeat/Monthly';
import Yearly from 'app/modules/jobdetail/tabs/settingschedule/repeat/Yearly';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconSync from 'assets/icon/IconSync';
import { checkRepeatWeekLy } from 'app/modules/jobdetail/const/Utils';
import classNames from 'classnames';

const Repeat = ({ isEvent = false }) => {
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCurrentceDetail = repeatSettings.recurrence.offset || {};

    function _changeTab(e, tabAcitve) {
        e.stopPropagation();

        let refValue = {
            ...repeatSettings,
            repeat: 1
        };

        switch (tabAcitve) {
            case TABS_REPEAT.OFF:
                refValue = {
                    ...refValue,
                    repeat: 0,
                    recurrence: {
                        ...refValue.recurrence,
                        offset: {
                            ...jobCurrentceDetail,
                            frequency: TABS_REPEAT.OFF,
                            interval: 0
                        }
                    },
                    exception: {
                        id: refValue?.exception?.id || '',
                        summary: '',
                        options: EXCEPTON_OPTIONS
                    }
                };
                break;
            case TABS_REPEAT.DAILY:
                refValue = {
                    ...refValue,
                    recurrence: {
                        ...refValue.recurrence,
                        offset: {
                            ...jobCurrentceDetail,
                            frequency: TABS_REPEAT.DAILY,
                            interval: 1
                        }
                    }
                };
                break;
            case TABS_REPEAT.WEEKLY:
                refValue = {
                    ...refValue,
                    recurrence: {
                        ...refValue.recurrence,
                        offset: {
                            ...checkRepeatWeekLy(
                                {
                                    ...jobCurrentceDetail,
                                    MO: '0',
                                    TU: '0',
                                    WE: '0',
                                    TH: '0',
                                    FR: '0',
                                    SA: '0',
                                    SU: '0',
                                    interval: 1,
                                    frequency: TABS_REPEAT.WEEKLY
                                },
                                repeatSettings.start
                            )
                        }
                    }
                };
                break;
            case TABS_REPEAT.MONTHLY:
                refValue = {
                    ...refValue,
                    recurrence: {
                        ...refValue.recurrence,
                        offset: {
                            ...jobCurrentceDetail,
                            frequency: TABS_REPEAT.MONTHLY,
                            interval: 1,
                            bymonthday: 'dayofweek'
                        }
                    }
                };
                break;
            case TABS_REPEAT.YEARLY:
                refValue = {
                    ...refValue,
                    recurrence: {
                        ...refValue.recurrence,
                        offset: {
                            ...jobCurrentceDetail,
                            frequency: TABS_REPEAT.YEARLY,
                            interval: 1,
                            byyearday: 'dayofyear'
                        }
                    }
                };
                break;
            default:
                break;
        }

        onChangeDataContext(refValue);
    }

    const finalTabActive = !repeatSettings.repeat ? TABS_REPEAT.OFF : jobCurrentceDetail.frequency;
    const isTabOff = finalTabActive === TABS_REPEAT.OFF;
    const isTabDaily = finalTabActive === TABS_REPEAT.DAILY;
    const isTabWeekly = finalTabActive === TABS_REPEAT.WEEKLY;
    const isTabMonthly = finalTabActive === TABS_REPEAT.MONTHLY;
    const isTabYearly = finalTabActive === TABS_REPEAT.YEARLY;

    return (
        <>
            <div className={classNames('tabs tabs-daily nav-tabs', { rows: !isEvent })}>
                {!isEvent && (
                    <div className="txt">
                        <IconSync isHasColor />
                        Repeat
                    </div>
                )}
                <div className="btn-item m-0">
                    <button
                        onClick={(e) => _changeTab(e, TABS_REPEAT.OFF)}
                        className={`tab-items item-off ${isTabOff ? 'active-tab-selector' : ''}`}
                        type="button"
                    >
                        Off
                    </button>
                    <button
                        onClick={(e) => _changeTab(e, TABS_REPEAT.DAILY)}
                        className={`tab-items ${isTabDaily ? 'active-tab-selector' : ''}`}
                        type="button"
                    >
                        Daily
                    </button>
                    <button
                        onClick={(e) => _changeTab(e, TABS_REPEAT.WEEKLY)}
                        className={`tab-items ${isTabWeekly ? 'active-tab-selector' : ''}`}
                        type="button"
                    >
                        Weekly
                    </button>
                    <button
                        onClick={(e) => _changeTab(e, TABS_REPEAT.MONTHLY)}
                        className={`tab-items ${isTabMonthly ? 'active-tab-selector' : ''}`}
                        type="button"
                    >
                        Monthly
                    </button>
                    <button
                        onClick={(e) => _changeTab(e, TABS_REPEAT.YEARLY)}
                        className={`tab-items ${isTabYearly ? 'active-tab-selector' : ''}`}
                        type="button"
                    >
                        Yearly
                    </button>
                </div>
            </div>

            <div className={classNames('tab-content', { 'my-4': isEvent })}>
                <div className="tab-panel" />
                {isTabDaily && <Daily isEvent={isEvent} />}
                {isTabWeekly && <Weekly isEvent={isEvent} />}
                {isTabMonthly && <Monthly isEvent={isEvent} />}
                {isTabYearly && <Yearly isEvent={isEvent} />}
            </div>
        </>
    );
};

export default Repeat;
