import React from 'react';

const LoadingActiveJobStatus = () => {
    return (
        <div className="wrapper-job-status__content wrap-loading">
            <div className="boxs">
                <div className="boxs__header flex-column gap-4">
                    <div className="loading --animation --light --line --twothird"></div>
                </div>
                <div className="rows --custom-status">
                    <div className="col-status flex-1">
                        <div className="v2-btn-default loading --grey --half flex-centeritem"></div>
                        <div className="v2-btn-default loading --grey --half flex-centeritem"></div>
                    </div>
                    <div className="v2-btn-default loading --grey btn-add-job flex-centeritem"></div>
                </div>
            </div>
            <div className="boxs">
                <div className="rows">
                    <div className="col-status flex-1">
                        <div className="status-btn loading --onefourth bg-white-cultured ml-0"></div>
                    </div>
                </div>
                <div className="box-draggable">
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxs">
                <div className="rows">
                    <div className="col-status flex-1">
                        <div className="status-btn loading --onefourth bg-white-cultured ml-0"></div>
                    </div>
                </div>
                <div className="box-draggable">
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="boxs">
                <div className="boxs__header flex-column gap-4">
                    <div className="loading --animation --light --line --twothird"></div>
                    <div className="loading --animation --light --line --half"></div>
                </div>
                <div className="rows --custom-status">
                    <div className="col-status flex-1 gap-4">
                        <div className="v2-btn-default loading --grey --half flex-centeritem"></div>
                        <div className="v2-btn-default loading --grey --half flex-centeritem"></div>
                        <div className="v2-btn-default loading --grey --half flex-centeritem"></div>
                    </div>
                    <div className="v2-btn-default loading --grey btn-add-job flex-centeritem"></div>
                </div>
            </div>
            <div className="boxs">
                <div className="box-draggable">
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                    <div className="rows">
                        <div className="col-status flex-1">
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                            <div className="status-btn loading --onefourth bg-white-cultured"></div>
                        </div>
                        <div className="col-icon flexcenter gap-4">
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                            <div className="v2-btn-default --icon-lg loading bg-white-cultured"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingActiveJobStatus;
