import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import {
    Elements,
    useElements,
    useStripe,
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

const StripeContentInfo = forwardRef(({ stripeKey, showNameCard = false, isGDCompany = false }, ref) => {
    const refForm = useRef(null);

    const userSetting = useSelector(({ auth }) => auth.user?.settings);

    const STRIPE_API_KEY = isGDCompany ? userSetting?.stripe?.public_key || '' : userSetting?.stripe?.key;
    const finalStripeKey = stripeKey || STRIPE_API_KEY;

    useImperativeHandle(ref, () => ({ _getTokenCardStripe: () => refForm.current._getTokenCardStripe() }));

    const stripePromise = finalStripeKey && loadStripe(finalStripeKey);

    return (
        <Elements stripe={stripePromise}>
            <FormInformation showNameCard={showNameCard} ref={refForm} />
        </Elements>
    );
});

const FormInformation = forwardRef(({ showNameCard }, ref) => {
    const elements = useElements();
    const stripe = useStripe();
    const refNameCard = useRef('');

    useImperativeHandle(ref, () => ({ _getTokenCardStripe }));

    const _getTokenCardStripe = async () => {
        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardNumberElement);
        const result = await stripe.createToken(card, { name: refNameCard.current ? refNameCard.current.value : '' });

        if (result.error) {
            return result.error;
        } else {
            return result;
        }
    };

    return (
        <div className="content-info">
            <div className="rows --inmodal">
                <div className="txt relative">Card Number</div>
                <CardNumberElement />
            </div>

            <div className="rows --inmodal d-flex mt-3">
                <div className="col col-cvc">
                    <div className="txt">Month</div>
                    <CardExpiryElement />
                </div>

                <div className="col col-cvc">
                    <div className="txt">CVC</div>
                    <CardCvcElement />
                </div>
            </div>
            {showNameCard && (
                <div className="rows --inmodal mt-3">
                    <div className="col">
                        <span className="txt">Name on card</span>
                        <input
                            ref={refNameCard}
                            className="field-input"
                            type="text"
                            name
                            placeholder="Name on card"
                            spellCheck
                        />
                    </div>
                </div>
            )}
        </div>
    );
});

export default StripeContentInfo;
