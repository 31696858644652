import ListAgenda from 'app/modules/calendar/header/components/Agenda';
import CalendarColor from 'app/modules/calendar/header/components/Colors';
import HeaderDate from 'app/modules/calendar/header/components/date';
import IconMap from 'app/modules/calendar/header/components/Map';
import ModeView from 'app/modules/calendar/header/components/ModeView';
import Schedule from 'app/modules/calendar/header/components/Schedules';
import React from 'react';
import loadable from '@loadable/component';

const CalendarActions = loadable(() => import('./components/CalendarActions'));

const Header = ({
    onPrev = () => {},
    onNext = () => {},
    onToday = () => {},
    onGetTitle = () => {},
    onChangeView = () => {},
    onToggleMap = () => {},
    onGoToDate = () => {},
    onActivateMagnet = () => {},
    onReloadEventsOptimized = () => {}
}) => {
    return (
        <div className="calendar-header">
            <div className="left-menu flexcenter">
                <IconMap onToggleMap={onToggleMap} />
                <ListAgenda />
                <div className="is-divider --h24 hide-mobile" />
                <ModeView onChangeView={onChangeView} />
                <CalendarColor />
                <Schedule />
            </div>
            <div className="center-menu relative">
                <HeaderDate
                    onNext={onNext}
                    onPrev={onPrev}
                    onGetTitle={onGetTitle}
                    onGoToDate={onGoToDate}
                    onToday={onToday}
                />
            </div>
            <div className="right-menu relative flexcenter">
                <CalendarActions
                    onActivateMagnet={onActivateMagnet}
                    onReloadEventsOptimized={onReloadEventsOptimized}
                />
            </div>
        </div>
    );
};

Header.displayName = 'HeaderCalendar';
export default Header;
