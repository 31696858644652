import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GET_LIST_CUSTOMER_SOURCE } from 'app/const/Api';
import { updateCustomerSources } from 'common/redux/actions/customer';
import { actionGetListSchedules } from 'common/redux/actions/schedulesList';
import { getListPipelines } from 'common/redux/actions/settings/pipelinesAction';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { smartViewsGetItems } from 'common/redux/actions/smartviews';
import { clientQuery } from 'common/utils/ApiUtils';
import { deepCloneObject } from 'common/utils/FunctionUtils';
import { FILTERS_LIST } from '../../constants';
import { FIELDS_TYPE, OBJECT_TYPES } from '../../constants/types';
import { useSmartView } from '../../context';
import AddFilterModal from '../addFilter';
import { LoadingFiltered } from '../header/Loading';
import { getField } from '../utils';
import ItemFiltered from './ItemFiltered';
import ItemFilteredError from './ItemFilteredError';
import Tooltip from './Tooltip';

const ListFiltered = ({ isLoading = true }) => {
    const dispatch = useDispatch();
    const sources = useSelector(({ customerReducer }) => customerReducer.sources);
    const isFirstTimeService = useSelector(({ serviceReducer }) => serviceReducer?.first_time);
    const firstTimePipeline = useSelector(({ pipelines }) => pipelines?.isFirstTime);
    const firstTimeSchedulesList = useSelector(({ schedulesList }) => schedulesList?.isFirstTime);
    const firstTimeItems = useSelector(({ smartViews }) => smartViews?.items?.isFirstTime);
    const { filtered: filters, handleRemoveFiltered } = useSmartView();
    const refAddFilter = useRef(null);
    const refTooltip = useRef(null);

    useEffect(() => {
        if (isFirstTimeService && filters.some((filter) => filter.fieldName === 'service_id'))
            dispatch(getListSettingService({ localSaveStore: true }));
        if (firstTimePipeline && filters.some((filter) => filter.fieldName === 'opportunity_status'))
            dispatch(getListPipelines({}));
        if (firstTimeSchedulesList && filters.some((filter) => filter.fieldName === 'completed_job_by_tech'))
            dispatch(actionGetListSchedules());
        if (firstTimeItems && filters.some((filter) => filter.fieldName === 'items')) dispatch(smartViewsGetItems());
        /* It's checking if the sources is already loaded. */
        if (sources || !filters.some((filter) => filter.fieldName === FIELDS_TYPE.SOURCE_ID.toLowerCase())) return;
        const _getSourcesSuccess = ({ data }) => {
            dispatch(updateCustomerSources(data));
        };
        clientQuery(GET_LIST_CUSTOMER_SOURCE, { data: {}, method: 'GET' }, _getSourcesSuccess);
    }, [filters]);

    const findFilters = (objectType, fieldName) => {
        const finalType = objectType === OBJECT_TYPES.CUSTOM_FIELD ? OBJECT_TYPES.CUSTOMER : objectType;
        const filters = FILTERS_LIST.find((filter) => filter.id === finalType);
        const index = filters.subcategories.findIndex((subcategory) => {
            return subcategory.fields.some((field) => field.fieldName === fieldName);
        });
        if (index === -1) return [];
        const matchingFields = filters.subcategories[index].fields.filter((field) => field.id === fieldName);
        return [{ ...filters, subcategories: [{ ...filters.subcategories[index], fields: matchingFields }] }];
    };

    const _handleUpdateFilter = (field, indexFilter) => {
        refAddFilter.current?.open({
            categoryId: field.objectType,
            isEditing: true,
            indexFilter,
            oldData: deepCloneObject({
                objectType: field.objectType,
                fieldName: field.fieldName,
                fieldType: field.type,
                operatorId: field.operatorId,
                value: field.value
            }),
            listOfFilters: findFilters(field.objectType, field.fieldName),
            filters: {
                [field.objectType]: {
                    [field.fieldName]: {
                        objectType: field.objectType,
                        fieldName: field.fieldName,
                        fieldType: field.type,
                        operatorId: field.operatorId,
                        value: field.value
                    }
                }
            }
        });
    };

    const _displayTooltip = (el, operatorData) => {
        el ? refTooltip.current?.visible({ el, operatorData }) : refTooltip.current?.visible(null);
    };

    if (isLoading) return <LoadingFiltered />;
    if (!filters.length) return null;
    return (
        <>
            <div className="header --filter wrapper__filter align-center">
                {filters.map((item, index) => {
                    const field = getField(item.objectType, item.fieldName);
                    const keyValue = `${item.fieldName}_${item.objectType}_${index}`;

                    if (!field)
                        return (
                            <ItemFilteredError
                                key={keyValue}
                                idError={item.fieldName}
                                onDisplayTooltip={_displayTooltip}
                                onRemove={() => handleRemoveFiltered(index)}
                            />
                        );
                    return (
                        <ItemFiltered
                            key={keyValue}
                            type={field.type}
                            field={field}
                            filter={{ value: item.value, operatorId: item.operatorId }}
                            onDisplayTooltip={_displayTooltip}
                            onRemove={() => handleRemoveFiltered(index)}
                            onUpdate={() => _handleUpdateFilter({ ...item, ...field }, index)}
                        />
                    );
                })}
            </div>
            <AddFilterModal ref={refAddFilter} />
            <Tooltip ref={refTooltip} />
        </>
    );
};

export default ListFiltered;
