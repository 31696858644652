import loadable from '@loadable/component';
import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { REPORT_NEW_CUSTOMERS_GET_LIST } from 'app/const/Api';
import { DELAY_TIME, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { ARROW_DOWN_KEY_CODE, END_KEY_CODE, PAGE_DOWN_KEY_CODE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { HEIGHT_TO_SCROLL, REPORT_LIMIT } from 'app/const/Reports';
import { URL_EXPORT_NEW_CUSTOMERS } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGidColumns } from 'app/const/report/NewCustomers';
import { NEW_CUSTOMERS_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { getDataChartNewCustomers, getListYearNewCustomers } from 'common/redux/actions/reports/newCustomersAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ReportLineAndPieChart = loadable(() => import('app/modules/report/components/ReportLineAndPieChart'));
const Export = loadable(() => import('../components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportNewCustomers() {
    const { t } = useTranslation(['report']);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users) || [];
    const contentRef = useRef(null);
    const parentRef = useRef(null);
    const abortController = useRef(null);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        isLoading: true,
        total: 0,
        totalData: 0,
        refreshScreen: 0,
        isLoadMore: false
    });
    const {
        refreshScreen,
        data: finalData,
        isLoadMore: finalLoadMore,
        isLoading: finalLoading,
        total: finalTotal
    } = dataReport;

    const FINAL_KEY_LOCAL_STORAGE = useMemo(() => {
        return KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.NEW_CUSTOMERS);
    }, []);

    const paramsReport = getLocalParamsReport(FINAL_KEY_LOCAL_STORAGE, REPORT_TYPE.NEW_CUSTOMERS);

    useEffect(() => {
        const parentHeight = parentRef.current.clientHeight || 0;
        const contentHeight = contentRef.current?.clientHeight || 0;
        if (contentHeight <= parentHeight && !finalLoading && !finalLoadMore) {
            onScrollToEnd();
        }
    }, [finalData]);

    useEffect(() => {
        getListReport(true, paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        const loadMoreTimer =
            finalLoadMore &&
            setTimeout(() => {
                getListReport(false, { ...paramsReport, offset: finalData.length }, true);
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [finalLoadMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const checkShouldLoading = !notShowLoading && !finalLoading;

        if (checkShouldLoading) {
            dispatchActionReport((prev) => ({
                ...prev,
                data: isReset ? [] : prev.data,
                isLoading: !!checkShouldLoading || prev.isLoading
            }));
        }

        delete params?.sign_status;
        clientQuery(
            REPORT_NEW_CUSTOMERS_GET_LIST,
            {
                data: convertParamFields({ params: { ...params, limit: REPORT_LIMIT }, isEmptyFields: finalLoadMore }),
                abortController: abortController.current
            },
            getListSuccess,
            getListFailed
        );
    };

    const getListSuccess = (response) => {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                isLoading: false,
                isLoadMore: false,
                data: [...prev.data, ...response.data],
                total: response.total ?? prev.total ?? 0,
                refreshScreen: prev.refreshScreen + 1
            };
        });
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadMore: false }));
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(FINAL_KEY_LOCAL_STORAGE);
        getListReport(true, params);
    };

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.NEW_CUSTOMERS,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    function onScrollToEnd() {
        if (!finalLoadMore && !finalLoading && finalTotal > finalData.length) {
            dispatchActionReport((prev) => {
                return { ...prev, isLoadMore: true };
            });
        }
    }

    function _handleScroll(e) {
        isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && onScrollToEnd();
    }

    function _handleKeyDown(e) {
        const listKeyDown = [PAGE_DOWN_KEY_CODE, END_KEY_CODE, ARROW_DOWN_KEY_CODE];
        if (listKeyDown.includes(e.keyCode)) {
            isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && onScrollToEnd();
        }
    }

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: finalTotal })}
                url={URL_EXPORT_NEW_CUSTOMERS}
                params={paramsReport}
                isDisable={finalLoading}
                pageExport={LIST_EXPORT.EXPORT_REPORT_NEW_CUSTOMERS}
                refresh={refreshScreen}
                activePrint
            />
        );
    }

    return (
        <Fragment>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.NEW_CUSTOMERS}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns has-chart pr-0">
                <div
                    ref={parentRef}
                    className="container-print contents-pages new-customer gap-8"
                    tabIndex={0}
                    onKeyDown={_handleKeyDown}
                    onWheel={_handleScroll}
                >
                    <div className="wrap-border">
                        <ReportLineAndPieChart
                            getDataAPI={getDataChartNewCustomers}
                            getListYearAPI={getListYearNewCustomers}
                        />
                    </div>
                    <div ref={contentRef} className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={REPORT_TYPE.NEW_CUSTOMERS}
                            filters={NEW_CUSTOMERS_LIST_FILTER}
                            handleUpdate={_handleUpdate}
                            companyUsers={companyUsers}
                            isLoading={finalLoading}
                        />
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent=""
                            content={finalData}
                            fileTranslation={'report'}
                            handleClickHeader={(event) => {
                                handleActionHeader(event);
                            }}
                            isLoadmore={finalLoadMore}
                            {...getGidColumns(paramsReport?.order)}
                            isScroll
                            isHaveChart
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ReportNewCustomers;
