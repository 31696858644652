import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

const HEADER_COMMISSION = {
    production: {
        uid: {
            title: 'staff',
            style: 'col col-md'
        },
        item_name: {
            title: 'line_item',
            style: 'col'
        },
        item_value: { title: 'item_value', style: 'col col-sm' },
        customer_name: {
            title: 'customer_name',
            style: 'col col-md'
        },
        service_name: {
            title: 'service',
            style: 'col'
        },
        job_status: {
            title: 'job_status',
            style: 'col col-job-status'
        },
        invoice_number: {
            title: 'invoice',
            style: 'col col-xs'
        },
        invoice_date: {
            title: 'invoice_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        payment_date: {
            title: 'payment_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        invoice_status: {
            title: 'status',
            style: 'col col-sm'
        },
        method: {
            title: 'method',
            style: 'col '
        },
        type: { title: 'type', style: 'col col-xs' },
        com_value: { title: 'prod_com', style: 'col' },
        excluding_tax: {
            title: 'excluding_tax',
            style: ' col col-md'
        },
        split_value: {
            title: 'split_value',
            style: 'col'
        },
        split_tax: {
            title: 'split_tax',
            style: 'col col-xl'
        }
    },
    sold_by: {
        uid: {
            title: 'staff',
            style: 'col col-md'
        },
        item_name: {
            title: 'line_item',
            style: 'col'
        },
        item_value: { title: 'item_value', style: 'col col-sm' },
        customer_name: {
            title: 'customer_name',
            style: 'col col-md'
        },
        service_name: {
            title: 'service',
            style: 'col'
        },
        invoice_number: {
            title: 'invoice',
            style: 'col col-xs'
        },
        job_status: {
            title: 'job_status',
            style: 'col col-job-status'
        },
        invoice_date: {
            title: 'invoice_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        payment_date: {
            title: 'payment_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        invoice_status: {
            title: 'status',
            style: 'col col-sm'
        },
        method: {
            title: 'method',
            style: 'col '
        },
        type: { title: 'type', style: 'col col-xs' },
        com_value: { title: 'sale_value', style: 'col' },
        excluding_tax: {
            title: 'excluding_tax',
            style: 'col col-md'
        },
        split_value: {
            title: 'split_value',
            style: 'col'
        },
        split_tax: {
            title: 'split_tax',
            style: 'col col-xl'
        }
    }
};

const REPORT_COLUMNS_COMMISSION = {
    production: [
        {
            id: 'uid',
            title: 'user_name',
            status: true
        },
        {
            id: 'item_name',
            title: 'item_name',
            status: true
        },
        {
            id: 'item_value',
            title: 'item_value',
            status: true
        },
        {
            id: 'customer_name',
            title: 'customer_name',
            status: true
        },
        {
            id: 'service_name',
            title: 'service_name',
            status: true
        },
        {
            id: 'invoice_number',
            title: 'invoice_number',
            status: true
        },
        {
            id: 'job_status',
            title: 'job_status',
            status: true
        },
        {
            id: 'invoice_date',
            title: 'invoice_date',
            status: true
        },
        {
            id: 'payment_date',
            title: 'payment_date',
            status: true
        },
        {
            id: 'invoice_status',
            title: 'invoice_status',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'type',
            title: 'type',
            status: true
        },
        {
            id: 'com_value',
            title: 'prod_value',
            status: true
        },
        {
            id: 'excluding_tax',
            title: 'excluding_tax',
            status: true
        },
        {
            id: 'split_value',
            title: 'split_value',
            status: true
        },
        {
            id: 'split_tax',
            title: 'split_tax',
            status: true
        }
    ],
    sold_by: [
        {
            id: 'uid',
            title: 'user_name',
            status: true
        },
        {
            id: 'item_name',
            title: 'item_name',
            status: true
        },
        {
            id: 'item_value',
            title: 'item_value',
            status: true
        },
        {
            id: 'customer_name',
            title: 'customer_name',
            status: true
        },
        {
            id: 'service_name',
            title: 'service_name',
            status: true
        },
        {
            id: 'invoice_number',
            title: 'invoice_number',
            status: true
        },
        {
            id: 'job_status',
            title: 'job_status',
            status: true
        },
        {
            id: 'invoice_date',
            title: 'invoice_date',
            status: true
        },
        {
            id: 'payment_date',
            title: 'payment_date',
            status: true
        },
        {
            id: 'invoice_status',
            title: 'invoice_status',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'type',
            title: 'type',
            status: true
        },
        {
            id: 'com_value',
            title: 'sale_value',
            status: true
        },
        {
            id: 'excluding_tax',
            title: 'excluding_tax',
            status: true
        },
        {
            id: 'split_value',
            title: 'split_value',
            status: true
        },
        {
            id: 'split_tax',
            title: 'split_tax',
            status: true
        }
    ]
};

const REPORT_CONTENT_COMMISSION = {
    production: {
        uid: {
            id: 'user_name',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.ASSIGNED_TO
        },
        item_name: {
            id: 'item_name',
            style: 'col',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        item_value: {
            id: 'item_value',
            style: 'col col-sm',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL,
            isLink: true
        },
        customer_name: {
            id: 'customer_name',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        service_name: {
            id: 'service_name',
            style: 'col',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        invoice_number: {
            id: 'invoice_number',
            style: 'col col-xs',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        job_status: {
            id: 'job_status',
            style: 'col col-job-status d-flex gap-4',
            type: LIST_TABLE_ITEM_TYPE.JOBS_STATUS
        },
        invoice_date: {
            id: 'invoice_date',
            style: 'col col-md',
            styleText: 'invoice_date'
        },
        payment_date: {
            id: 'payment_date',
            style: 'col col-md',
            styleText: 'invoice_date'
        },
        invoice_status: {
            id: 'invoice_status',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        method: {
            id: 'method',
            style: 'col ',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        type: {
            id: 'type',
            style: 'col col-xs',
            styleText: 'type',
            type: LIST_TABLE_ITEM_TYPE.TYPE_COMMISSION
        },
        com_value: {
            id: 'com_value',
            style: 'col',
            styleText: 'com_value',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        excluding_tax: {
            id: 'excluding_tax',
            style: 'col col-md',
            styleText: 'excluding_tax',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        split_value: {
            id: 'split_value',
            style: 'col',
            styleText: 'split_value',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        split_tax: {
            id: 'split_tax',
            style: 'col col-xl',
            styleText: 'split_tax',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        }
    },
    sold_by: {
        uid: {
            id: 'user_name',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.ASSIGNED_TO
        },
        item_name: {
            id: 'item_name',
            style: 'col',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        item_value: {
            id: 'item_value',
            style: 'col col-sm',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL,
            isLink: true
        },
        customer_name: {
            id: 'customer_name',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        service_name: {
            id: 'service_name',
            style: 'col',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        invoice_number: {
            id: 'invoice_number',
            style: 'col col-xs',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        job_status: {
            id: 'job_status',
            style: 'col col-job-status d-flex gap-4',
            type: LIST_TABLE_ITEM_TYPE.JOBS_STATUS
        },
        invoice_date: {
            id: 'invoice_date',
            style: 'col col-md',
            styleText: 'invoice_date'
        },
        payment_date: {
            id: 'payment_date',
            style: 'col col-md',
            styleText: 'invoice_date'
        },
        invoice_status: {
            id: 'invoice_status',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        method: {
            id: 'method',
            style: 'col ',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        type: {
            id: 'type',
            style: 'col col-xs',
            styleText: 'type',
            type: LIST_TABLE_ITEM_TYPE.TYPE_COMMISSION
        },
        com_value: {
            id: 'sale_value',
            style: 'col',
            styleText: 'sale_value',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        excluding_tax: {
            id: 'excluding_tax',
            style: 'col col-md',
            styleText: 'excluding_tax',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        split_value: {
            id: 'split_value',
            style: 'col',
            styleText: 'split_value',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        split_tax: {
            id: 'split_tax',
            style: 'col col-xl',
            styleText: 'split_tax',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        }
    }
};

export const getGridColumns = (tab, actionsStatus = '', displayBy = 0) => {
    if (tab) {
        const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_COMMISSION[tab] });

        const newColumns = REPORT_COLUMNS_COMMISSION[tab].filter((col) => {
            if (!displayBy) {
                if (col.id === COMMON.PAYMENT_DATE) return false;
            } else {
                if (col.id === COMMON.INVOICE_DATE) return false;
            }
            return true;
        });

        return {
            header: newHeader,
            columns: newColumns,
            contentConfig: REPORT_CONTENT_COMMISSION[tab]
        };
    }

    return {
        header: HEADER_COMMISSION[tab],
        columns: REPORT_COLUMNS_COMMISSION[tab],
        contentConfig: REPORT_CONTENT_COMMISSION[tab]
    };
};
