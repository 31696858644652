import MonthPicker from 'app/modules/calendar/header/components/date/MonthPicker';
import YearPicker from 'app/modules/calendar/header/components/date/YearPicker';
import IconSingleStep from 'assets/icon/IconSingleStep';

export function HeaderCustom(props) {
    const _renderDatePickerHeader = () => {
        return (
            <div className="d-flex gap-8">
                <MonthPicker {...props} />
                <YearPicker {...props} />
            </div>
        );
    };

    return (
        <div className="datepicker-header-custom">
            <div
                onClick={() => props.decreaseMonth()}
                className="btn-navigation --previous v2-btn-default --icon-sm --transparent"
            >
                <IconSingleStep isPrev />
            </div>
            {_renderDatePickerHeader()}
            <div
                onClick={() => props.increaseMonth()}
                className="btn-navigation --next v2-btn-default --icon-sm --transparent"
            >
                <IconSingleStep />
            </div>
        </div>
    );
}
