import moment from 'moment';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';
import { clientQuery } from 'common/utils/ApiUtils';
import { isFileImage, uploadToS3 } from 'common/utils/FileUtils';
import Attachment from './components/Attachment';
import ItemAttachList from './components/ItemAttachList';
import { TYPE_ATTACH_DISPLAY } from './constant';

const GDAttachments = forwardRef((props, ref) => {
    const {
        data = [],
        itemId = null,
        typeDisplay = 'thumbnail',
        type = 'attachments',
        onConfirm = null,
        isHideClose = false,
        onRemove = () => {},
        onUploadSuccess = () => {},
        onUploadFailed = () => {}
    } = props;
    const refTimer = useRef(null);
    const [attachments, setAttachments] = useState(data);

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    useImperativeHandle(ref, () => ({
        addAttachment: (newAttach) => _addNewAttachments(newAttach),
        clearAttachments: () => setAttachments([]),
        getAttachments: () => attachments,
        getFilesInfo: () => {
            return {
                totalFiles: attachments?.length || 0,
                totalSizes: attachments?.reduce((total, item) => total + item?.file?.size || 0, 0)
            };
        },
        setAttachments
    }));

    /**
     * Receive files and add presign it, then upload to S3.
     * @param {array | object} attachment - Array of file or file
     * @return {void}
     */
    const _addNewAttachments = (attachment) => {
        if (Array.isArray(attachment)) {
            attachment.forEach((attach) => {
                _addAttachWithQuery(attach);
            });
        } else {
            _addAttachWithQuery(attachment);
        }
    };

    /**
     * Presign attachment and add to array attachments with isUploading is true
     * If success we call `_uploadToS3` function and upload to S3
     * If failure we remove it from array
     * @param {object} attach - Attachment object
     */
    const _addAttachWithQuery = (attach) => {
        const idAttach = `${attach.name}${moment().format('x')}`;
        const optionQuery = {
            data: {
                name: `web-${attach.name}`,
                item_id: itemId,
                type: type ?? 'attachments'
            },
            method: 'POST'
        };

        // Set attachment to list with `isUploading: true`
        setAttachments((attachments) => [
            {
                ...attach,
                url: !isFileImage(attach?.mime) ? attach.url : LINK_CDN_IMG_BROKEN,
                type: attach.mime,
                id: idAttach,
                isUploading: true
            },
            ...attachments
        ]);

        // Request success we execute function global `uploadToS3`
        // see more info at `common/utils/FileUtils.js`
        const _addSuccess = (response) => {
            const { presigned_url, object_key, object_tag, public_url, thumbnail_url } = response.data;
            const optionsHeaders = { 'x-amz-tagging': object_tag };
            const data = {
                idAttach,
                object_key,
                public_url,
                thumbnail_url,
                name: attach.name,
                size: attach.file.size,
                mime: attach.file.type
            };

            uploadToS3(presigned_url, optionsHeaders, attach.file, data, _uploadS3Success, _uploadS3Failure);
        };

        const _addFailure = ({ message }) => {
            onUploadFailed(message);
            _handleRemoveAttachments(idAttach);
        };

        clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, _addFailure);
    };

    const _uploadS3Success = ({ idAttach, object_key, public_url, thumbnail_url, name, size, mime }) => {
        setAttachments((attachments) => {
            const newAttach = [...attachments];
            const index = newAttach.findIndex((item) => item.id === idAttach);

            if (index < 0) return newAttach;
            newAttach[index]['isUploading'] = false;
            newAttach[index]['object_key'] = object_key;
            newAttach[index]['url'] = thumbnail_url || public_url;
            newAttach[index]['thumbnail_url'] = thumbnail_url || public_url;
            newAttach[index]['public_url'] = public_url;

            return newAttach;
        });
        refTimer.current = setTimeout(() => {
            onUploadSuccess({ object_key, name, size, mime, public_url });
        }, 0);
    };

    const _uploadS3Failure = ({ idAttach }) => {
        _removeAttach(idAttach);
    };

    /**
     * Remove attachment from list
     * @param {idAttach} - Id of attachment
     * @return {void}
     */
    const _removeAttach = useCallback((idAttach, isConfirmed = false) => {
        if (onConfirm && !isConfirmed) {
            onConfirm(idAttach, () => _removeAttach(idAttach, true));
            return;
        }

        _handleRemoveAttachments(idAttach);
        onRemove(idAttach);
    }, []);

    const _handleRemoveAttachments = (idAttach) => {
        setAttachments((attachments) => {
            const newAttachments = [...attachments].filter((item) => (item.object_key || item.id) !== idAttach);
            return newAttachments;
        });
    };

    if (!attachments?.length) return null;
    return attachments.map((attach) => {
        const props = { ...attach, isHideClose, onRemove: _removeAttach };
        switch (typeDisplay) {
            case TYPE_ATTACH_DISPLAY.LIST:
                return (
                    <ItemAttachList
                        key={attach.object_key || attach.id}
                        keyObj={attach.object_key || attach.key}
                        {...props}
                    />
                );
            default:
                return (
                    <Attachment
                        key={attach.id || attach.object_key}
                        keyObj={attach.object_key || attach.key}
                        isImage={isFileImage(attach?.mime)}
                        {...props}
                    />
                );
        }
    });
});

export default GDAttachments;
