import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumns = (columns = [], actionsStatus = '') => {
    const HEADER_ESTIMATE = {
        estimate_number: {
            title: 'estimate_number',
            style: 'col col-md'
        },
        account_number: {
            title: 'account_number_#',
            style: 'col col-md'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl'
        },
        phones: {
            title: 'phones',
            style: 'col col-phone'
        },
        billing_email: { title: 'billing_email', style: 'col col-email' },
        created_by: { title: 'created_by', style: 'col col-md' },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        total: { title: 'total', style: 'col col-total' },
        status: {
            title: 'status',
            style: 'col col-status'
        },
        signature_status: {
            title: 'signature_status',
            style: 'col col-sm'
        }
    };

    const REPORT_COLUMNS_ESTIMATE = [
        {
            id: 'estimate_number',
            title: 'status',
            status: true
        },
        {
            id: 'account_number',
            title: 'status',
            status: true
        },
        {
            id: 'customer',
            title: 'customer_name',
            status: true
        },
        {
            id: 'phones',
            title: 'status',
            status: true
        },
        { id: 'billing_email', title: 'status', status: true },
        { id: 'created_by', title: 'status', status: true },
        { id: 'date', title: 'status', status: true },
        { id: 'total', title: 'status', status: true },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'signature_status',
            title: 'status',
            status: true
        }
    ];

    const REPORT_CONTENT_ESTIMATE = {
        estimate_number: {
            id: 'estimate_number',
            style: 'col col-md',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        account_number: {
            id: 'account_number',
            style: 'col col-md'
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        phones: {
            id: 'phones',
            style: 'col col-phone',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        billing_email: { id: 'billing_email', style: 'col col-email' },
        created_by: { id: 'created_by', style: 'col col-md', type: LIST_TABLE_ITEM_TYPE.CREATED_BY },
        date: { id: 'date', style: 'col col-md' },
        total: { id: 'total', style: 'col col-total', type: LIST_TABLE_ITEM_TYPE.BALANCE },
        status: {
            id: 'status',
            style: 'col col-status',
            type: LIST_TABLE_ITEM_TYPE.ESTIMATE_STATUS
        },
        signature_status: {
            id: 'signature_status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_ESTIMATE });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_ESTIMATE.map((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_ESTIMATE[item];
            newContent[item] = REPORT_CONTENT_ESTIMATE[item];
            newColumns.push(col);
        }
    });
    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
