import { ACCEPT_OPTIMIZED_ROUTE, OPTIMIZED_EVENT_ROUTE } from 'app/const/Api';
import { putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* optimizedEventsRoute({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, OPTIMIZED_EVENT_ROUTE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* acceptNewRoute({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, ACCEPT_OPTIMIZED_ROUTE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
