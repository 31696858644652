import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { GET_LIST_CUSTOMER_SOURCE } from 'app/const/Api';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconLoading from 'assets/icon/IconLoading';
import { updateCustomerSources } from 'common/redux/actions/customer';
import { clientQuery } from 'common/utils/ApiUtils';

const SourceCondition = ({ selected = [], onChange = () => {} }) => {
    const dispatch = useDispatch();
    const sources = useSelector(({ customerReducer }) => customerReducer.sources);
    const refDropdown = useRef(null);

    const _handleOpenDropdown = () => {
        /* It's checking if the sources is already loaded. */
        if (sources) return;
        const _getSourcesSuccess = ({ data }) => {
            dispatch(updateCustomerSources(data));
        };
        clientQuery(GET_LIST_CUSTOMER_SOURCE, { data: {}, method: 'GET' }, _getSourcesSuccess);
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            id="select-condition"
            isUseToggle
            isCalculateWidth
            isCheckHidden
            onOpen={_handleOpenDropdown}
            wrapperClassName="v2-dropdown field"
            buttonClassName="dropbtn items"
            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            customButton={<CustomButton sources={sources || []} selected={selected} />}
        >
            {sources ? (
                <ListSourceContent sources={sources} selected={selected} onChange={onChange} />
            ) : (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            )}
        </DropdownPopper>
    );
};

const ListSourceContent = ({ update = () => {}, onChange = () => {}, sources = [], selected }) => {
    useEffect(() => {
        update();
    }, [sources]);

    return (
        <ul>
            {sources.map((source) => {
                const value = source?.id?.toString();
                const isSomeSelected =
                    Array.isArray(selected) && selected?.some((selectedItem) => selectedItem?.toString() === value);
                const isActive = selected?.value?.toString() === value || isSomeSelected;
                return <SourceItem key={source.id} isActive={isActive} item={source} onChange={onChange} />;
            })}
        </ul>
    );
};

const CustomButton = ({ selected = [], sources = [] }) => {
    const { t } = useTranslation('smartView');
    const finalTitle = () => {
        if (!selected?.length || !Array.isArray(selected)) return t('choose_a_source');
        const arrayNames = [];
        selected.forEach((item) => {
            const name = sources?.find((source) => source?.id?.toString() === item?.toString())?.name || '';
            if (name) arrayNames.push(name);
        });
        return arrayNames.join(', ');
    };
    return (
        <>
            <span className="txt-ellipsis mr-2">{finalTitle()}</span>
            <div className="arrow">
                <IconArrowDown />
            </div>
        </>
    );
};

const SourceItem = ({ item, isActive = false, onChange = () => {} }) => {
    const refInput = useRef(null);
    const idCheckbox = `${item.id}`;

    const _handleClick = () => {
        onChange(item?.id?.toString());
    };

    return (
        <li className={classNames('items', { active: isActive })} onClick={_handleClick}>
            <div className="check-items">
                <input
                    ref={refInput}
                    id={idCheckbox}
                    type="checkbox"
                    disabled
                    checked={isActive}
                    onChange={(e) => e.preventDefault()}
                />
                <div className="item-checkbox">
                    <label htmlFor={idCheckbox}>
                        <span className="txt-ellipsis">{item.name}</span>
                    </label>
                </div>
            </div>
        </li>
    );
};

SourceCondition.displayName = 'SourceCondition';
export default SourceCondition;
