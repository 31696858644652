import React from 'react';
import { useTranslation } from 'react-i18next';
import GDButtonInputSearch from 'app/components/button/ButtonInputSearch';
import IconPlus from 'assets/icon/IconPlus';
import IconSmartView from 'assets/icon/IconSmartView';

const Header = ({ isSupperAdmin = false, onSearch = () => {}, onAddNew = () => {} }) => {
    const { t } = useTranslation('smartView');
    return (
        <div className="sidebar-header">
            <div className="flexcenter gap-4 flex-1">
                <IconSmartView />
                <p className="black fw-500">{t('smart_view_title')}</p>
            </div>
            <GDButtonInputSearch onSearch={onSearch} />
            {isSupperAdmin ? (
                <div className="v2-btn-default --icon-lg btn-modal" onClick={onAddNew}>
                    <IconPlus />
                </div>
            ) : null}
        </div>
    );
};
export default Header;
