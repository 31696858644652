import { useEffect, useContext } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';

export default function RealTimeService({ onUpdate = () => {}, jobId = '' }) {
    const {
        checkListAdd: checkListAddResponse,
        checkListUpdate: checkListUpdateResponse,
        checkListDelete: checkListDeleteResponse,
        checkListMove: checkListMoveResponse
    } = useContext(SocketContext);

    useEffect(() => {
        checkListAddResponse && _handleAddCheckList(checkListAddResponse);
    }, [checkListAddResponse]);

    useEffect(() => {
        checkListUpdateResponse && _handleUpdateCheckList(checkListUpdateResponse);
    }, [checkListUpdateResponse]);

    useEffect(() => {
        checkListDeleteResponse && _handleDelteCheckList(checkListDeleteResponse);
    }, [checkListDeleteResponse]);

    useEffect(() => {
        checkListMoveResponse && _handleMoveCheckList(checkListMoveResponse);
    }, [checkListMoveResponse]);

    function _handleDelteCheckList(response) {
        const { socket_id, data: newData, job_id: jobIdResponse } = response;

        /* eslint-disable no-undef */
        if ((socket_id && socket_id === global.socketIdJobDetail) || jobIdResponse !== jobId) {
            return false;
        }

        onUpdate((prev) => {
            let oldData = [...prev.data];

            oldData = oldData
                .filter((item) => {
                    if (!newData.find((findItem) => findItem === item.id)) {
                        return item;
                    }
                })
                .sort(function (a, b) {
                    if (a.sort_order < b.sort_order) {
                        return -1;
                    }
                    if (a.sort_order > b.sort_order) {
                        return 1;
                    }
                    return 0;
                });

            return {
                ...prev,
                data: [...oldData],
                isVisible: !!oldData.length,
                renderShowMore: oldData.length > 10
            };
        });
    }

    function _handleUpdateCheckList(response) {
        const { socket_id, data: newData, job_id: jobIdResponse } = response;

        /* eslint-disable no-undef */
        if ((socket_id && socket_id === global.socketIdJobDetail) || jobIdResponse !== jobId) {
            return false;
        }

        onUpdate((prev) => {
            let oldData = [...prev.data];

            oldData = oldData
                .map((item) => {
                    const findItem = newData.find(
                        (findItem) => findItem.id === item.id && item.updated_date < findItem.updated_date
                    );
                    if (findItem) {
                        return {
                            ...item,
                            ...findItem
                        };
                    }
                    return item;
                })
                .sort(function (a, b) {
                    if (a.sort_order < b.sort_order) {
                        return -1;
                    }
                    if (a.sort_order > b.sort_order) {
                        return 1;
                    }
                    return 0;
                });

            return {
                ...prev,
                data: [...oldData]
            };
        });
    }

    function _handleMoveCheckList(response) {
        const { socket_id, data: newData, job_id: jobIdResponse } = response;

        /* eslint-disable no-undef */
        if ((socket_id && socket_id === global.socketIdJobDetail) || jobIdResponse !== jobId) {
            return false;
        }

        onUpdate((prev) => {
            let oldData = [...prev.data];
            oldData = oldData
                .map((item) => {
                    if (item.id === newData.id) {
                        return {
                            ...item,
                            ...newData
                        };
                    }
                    return item;
                })
                .sort(function (a, b) {
                    if (a.sort_order < b.sort_order) {
                        return -1;
                    }
                    if (a.sort_order > b.sort_order) {
                        return 1;
                    }
                    return 0;
                });
            return {
                ...prev,
                data: [...oldData]
            };
        });
    }

    function _handleAddCheckList(response) {
        const { socket_id, data: newData, job_id: jobIdResponse } = response;

        /* eslint-disable no-undef */
        if ((socket_id && socket_id === global.socketIdJobDetail) || jobIdResponse !== jobId) {
            return false;
        }

        onUpdate((prev) => {
            if (!prev.data.length) {
                document.getElementById('js-add-todolist').classList.add('is-disable');
            }
            const newDataCheckList = [...prev.data, ...newData];

            return {
                ...prev,
                data: newDataCheckList,
                isVisible: true,
                isLoading: false,
                renderShowMore: newDataCheckList.length > 10
            };
        });
    }

    return false;
}
