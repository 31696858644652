import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import EmojiMart from 'app/components/emoji/index';
import IconEmoji from 'assets/icon/IconEmoji';
import React, { useRef } from 'react';

const Emoji = ({
    wrapperClassName = 'editor-controls__btn select-emoji',
    buttonClassName = 'select-emoji__btn tooltip',
    isCalculateWidth = false,
    customStyle = {},
    onInsert = () => {}
}) => {
    const refDropdown = useRef(null);

    const _closeDropdown = () => {
        refDropdown.current._close();
    };

    const _addEmoji = (value) => {
        _closeDropdown();
        onInsert(value.native);
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            wrapperClassName={wrapperClassName}
            wrapperListClass="v2-dropdown__menu content-full"
            buttonClassName={buttonClassName}
            isUseToggle
            placement="top-end"
            modifiers={[{ name: 'offset', options: { offset: [0, 10] } }]}
            isCalculateWidth={isCalculateWidth}
            customStyle={customStyle}
            customButton={
                <>
                    <IconEmoji />
                    <span className="tooltiptext top">Emoji</span>
                </>
            }
            isDropUp
        >
            <EmojiMart handleOnClick={_addEmoji} />
        </DropdownPopper>
    );
};

export default React.memo(Emoji);
