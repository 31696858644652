import IconSplash from 'assets/icon/IconSplash';
import React from 'react';

export const LoadingPipeline = () => {
    return (
        <div className="opportunity-container">
            <div className="splash">
                <div className="wrapper">
                    <div className="wrapper__loader">
                        <IconSplash />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoadingMoreItem = ({ count = 1 }) => {
    const arr = [];
    for (let i = 0; i < count; i++) {
        arr.push(
            <div key={i} className="opportunity-column__item">
                <div className="item-title flex-betweenitems">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                </div>
                <div className="item-info js-open-contact ">
                    <div className="title mb-2">
                        <div className="avt-img ml-0" />
                        <div className="title__info">
                            <div className="name">
                                <div className="loading --animation --line --twothird" />
                            </div>
                            <div className="loading --animation --line --threefourth mt-1" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return arr;
};

export const LoadingHeaderOppCol = () => {
    return (
        <div className="opportunity-column__header">
            <div className="flex-betweenitems border-bottom-border-color-grey header-status">
                <div className="v2-btn-default loading bg-white-cultured btn-x-sm field-h24" />
                <div className="loading --animation --line --onetenth" />
            </div>
            <div className="header-info">
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --twothird" />
                    <div className="loading --animation --line --onetenth" />
                </div>
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --onefourth" />
                    <div className="loading --animation --line --onetenth" />
                </div>
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --onefourth" />
                    <div className="loading --animation --line --onetenth" />
                </div>
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --twothird" />
                    <div className="loading --animation --line --onetenth" />
                </div>
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --twothird" />
                    <div className="loading --animation --line --onetenth" />
                </div>
                <div className="flex-betweenitems items">
                    <div className="loading --animation --line --twothird" />
                    <div className="loading --animation --line --onetenth" />
                </div>
            </div>
        </div>
    );
};
