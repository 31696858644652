export const CALENDAR_JOB_ACTIONS = {
    CALENDAR_RESIZE_TIME_JOB: 'CALENDAR_RESIZE_TIME_JOB',
    CALENDAR_MOVE_JOB: 'CALENDAR_MOVE_JOB',
    CALENDAR_MOVE_JOB_TO_WORK_POOL: 'CALENDAR_MOVE_JOB_TO_WORK_POOL',
    CALENDAR_GET_LIST_JOBS_EVENTS: 'CALENDAR_GET_LIST_JOBS_EVENTS',
    CALENDAR_BATCH_ACTION_MOVE_JOB: 'CALENDAR_BATCH_ACTION_MOVE_JOB'
};

export const actionResizeTimeJob = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: CALENDAR_JOB_ACTIONS.CALENDAR_RESIZE_TIME_JOB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const actionMoveJob = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: CALENDAR_JOB_ACTIONS.CALENDAR_MOVE_JOB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const calendarGetListJobEvents = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: CALENDAR_JOB_ACTIONS.CALENDAR_GET_LIST_JOBS_EVENTS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const calendarBatchActionMoveJobs = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: CALENDAR_JOB_ACTIONS.CALENDAR_BATCH_ACTION_MOVE_JOB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const actionMoveJobToWorkPool = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: CALENDAR_JOB_ACTIONS.CALENDAR_MOVE_JOB_TO_WORK_POOL,
        params,
        actionSuccess,
        actionFailed
    };
};
