import { SETTINGS_TILES, SETTINGS_TILES_OPTIONS } from 'app/const/Api';
import { fetchWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListSettingTiles({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_TILES, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateListSettingTiles({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_TILES, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getOptionsSettingTiles({ actionSuccess, actionFailed, initDataList }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_TILES_OPTIONS);
        if (response && response.success) {
            response.initDataList = initDataList;
            actionSuccess(response);
        } else {
            response.initDataList = initDataList;

            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
