import React from 'react';

const PlansTable = ({ plans = [], total }) => {
    const _renderListPlan = () => {
        return plans.map((item) => {
            return (
                <div key={item.name} className="rows --modify">
                    <div className="col fw-600">{item.name}</div>
                    <div className="col">{item.price}</div>
                    <div className="col --sm">{item.quantity}</div>
                    <div className="col fw-600 --remove">
                        <p className="txt-ellipsis">{item.total}</p>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="plans-content mt-9">
            <div className="header">
                <div className="title-x-sm">PLANS</div>
            </div>
            <div className="content">
                <div className="rows --modify --header">
                    <div className="col fw-600">Pricing Plan</div>
                    <div className="col fw-600">Pricing Details</div>
                    <div className="col fw-600 --sm">Qty</div>
                    <div className="col fw-600 --remove">Total</div>
                </div>
                {_renderListPlan()}
                <div className="rows --modify">
                    <div className="col" />
                    <div className="col" />
                    <div className="col --sm">Total</div>
                    <div className="col fw-600 --remove">
                        <p className="txt-ellipsis">{total}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlansTable;
