import React from 'react';
import { TypeDropdown } from 'app/modules/report/components/TypeDropdown';

const FilterDropdown = (props) => {
    const handleSelect = (name, selected) => {
        props.onSelect(name, selected);
    };
    const filterName = props['type'];
    const FilterComponent = TypeDropdown[filterName];

    return FilterComponent ? <FilterComponent {...props} onSelect={handleSelect} /> : null;
};

export default FilterDropdown;
