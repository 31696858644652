import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { reducer } from 'app/const/Reducer';
import { ID_TEMPLATE_FOLLOWUP, OPTIONS_SCHEDULE_TEMPLATE, TYPE_TEMPLATE_ID } from 'app/const/Settings';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconChevron from 'assets/icon/IconChevron';
import { onKeyDownOnlyNumber } from 'common/utils/NumberUtils';
import { useSettingTemplate } from '../SettingTemplateContext';

const BoxContentSchedule = () => {
    const { t } = useTranslation();
    const { template, idTypeTemplate, currentReminder, activeIndex, onGetCurrentData, onUpdateDataReminder } =
        useSettingTemplate();
    const [state, dispatchState] = useReducer(reducer, {
        isImmediately: typeof currentReminder?.schedule !== 'number'
    });
    const { isImmediately } = state;
    const [sendToText, descText] = template.schedule_text.split('{schedule_input}');
    const isDisplaySelect = idTypeTemplate === TYPE_TEMPLATE_ID.SYSTEM && template.id === ID_TEMPLATE_FOLLOWUP;

    const refCheckbox = useRef(null);
    const refValue = useRef(null);
    const refDropdown = useRef(null);
    const refInput = useRef(null);

    useEffect(() => {
        const dataCurrentReminder = onGetCurrentData();
        const isSetImmediately = typeof dataCurrentReminder?.schedule !== 'number';
        if (refInput.current) {
            refInput.current.type = isSetImmediately ? 'text' : 'number';
            refInput.current.disabled = isSetImmediately;
            refInput.current.value = isSetImmediately
                ? 'immediately'
                : dataCurrentReminder?.schedule ?? (currentReminder?.default_schedule || 0);
        }
        if (refCheckbox.current) refCheckbox.current.setIsActive(dataCurrentReminder.status);
        dispatchState({ isImmediately });
    }, [activeIndex]);

    const _handleChangeStatus = (checked) => {
        onUpdateDataReminder('status', checked ? 1 : 0);
    };

    const _handleChangeSchedule = (event) => {
        const newValue = event.target.value;
        onUpdateDataReminder('schedule', refInput.current.type === 'number' ? +newValue : newValue);
        refValue.current = newValue;
    };

    const _handleSelect = (value) => {
        const isSetImmediately = value === 'immediately';
        dispatchState({ isImmediately: isSetImmediately });
        refInput.current.type = isSetImmediately ? 'text' : 'number';
        refInput.current.value = isSetImmediately
            ? 'immediately'
            : refValue.current ?? (currentReminder?.default_schedule || 0);
        onUpdateDataReminder('schedule', !isSetImmediately ? refInput.current.value : 'immediately');
    };

    const _handleKeydown = (event) => {
        if (!onKeyDownOnlyNumber(event)) event.preventDefault();
    };

    return (
        <div className="content-insert-variable">
            <div className="box-reminder">
                <GdSwitchCheckbox
                    id={`toggle_reminder_${activeIndex}`}
                    ref={refCheckbox}
                    inputClassName="toggle toggle-round"
                    textChecked={t('enabled')}
                    textUnChecked={t('disabled')}
                    defaultChecked={!!currentReminder.status}
                    onChange={_handleChangeStatus}
                />
                <div className="box-reminder__desc fs-13">
                    {sendToText}

                    {isDisplaySelect ? (
                        <div className="wrap-dropmenu">
                            <input
                                ref={refInput}
                                className="field-input border-none"
                                type={isImmediately ? 'text' : 'number'}
                                defaultValue={currentReminder?.schedule || 'immediately'}
                                disabled={isImmediately}
                                spellCheck
                                onKeyDown={_handleKeydown}
                                onChange={_handleChangeSchedule}
                            />
                            <CalendarDropdown
                                ref={refDropdown}
                                id="calendar_schedule_template"
                                customDropButton={() => (
                                    <div className="arrow">
                                        <IconChevron type="right" />
                                    </div>
                                )}
                                keyGetValue="value"
                                onSelect={_handleSelect}
                                options={OPTIONS_SCHEDULE_TEMPLATE}
                                wrapperListClass="v2-dropdown__menu content-search content-full --right"
                                buttonClassName="dropbtn v2-btn-default --icon-lg --transparent"
                            />
                        </div>
                    ) : (
                        <input
                            ref={refInput}
                            className="field-input"
                            type={isImmediately ? 'text' : 'number'}
                            defaultValue={currentReminder?.schedule || 'immediately'}
                            disabled={isImmediately}
                            spellCheck
                            onKeyDown={_handleKeydown}
                            onChange={_handleChangeSchedule}
                        />
                    )}
                    {descText}
                </div>
            </div>
        </div>
    );
};

export default BoxContentSchedule;
