import React from 'react';
import PropTypes from 'prop-types';
import TaskItem from './TaskItem';

function TaskList({ data = [], ...props }) {
    return data.map((item) => {
        return <TaskItem key={item.id} dataTask={item} {...props} />;
    });
}

TaskList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({}))
};

export default TaskList;
