import { call } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { SETTINGS_SOURCES, SETTINGS_UPDATE_STATUS_SOURCES, getUrlSettingUpdateSource } from 'app/const/Api';

export function* getListSources({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_SOURCES, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createSource({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_SOURCES, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateSource({ params, actionSuccess, actionFailed }) {
    try {
        const URL = getUrlSettingUpdateSource(params.id);
        delete params.id;
        const response = yield call(putWithToken, URL, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusSources({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_UPDATE_STATUS_SOURCES, params);
        if (response && response.success) {
            response.listIdChecked = params.ids;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
