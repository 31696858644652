import React, { useRef } from 'react';
import CustomerService from 'app/modules/jobdetail/tabs/detail/CustomerService';
import CustomerInfo from 'app/modules/jobdetail/tabs/detail/CustomerInfo';
import Schedule from 'app/modules/jobdetail/tabs/detail/Schedule';
import Location from 'app/modules/jobdetail/tabs/detail/location';
import JobDetailTimeLine from 'app/modules/jobdetail/tabs/timeline';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import TimeWindow from './timewindow';
import Commission from './commission';

export default function TabJobDetail({ isActive, isLoading, jobData, onOpenSettingsSchedule }) {
    const finalJobData = jobData?.job || {};
    const jobId = finalJobData?.id || 0;
    const refStatusBar = useRef(null);

    const _handleUpdateJobSuccess = (message) => {
        refStatusBar.current && refStatusBar.current.showStatusBar('show_success', message, LIST_STATUS.SUCCESS);
    };

    return (
        <div id="tab_job_detail" className={`tab-conts dashboard-job-detail ${isActive ? 'tab-content-active' : ''}`}>
            <div className="container-column details-wrap">
                <StatusBar ref={refStatusBar} />
                <div className="details-job">
                    <CustomerService
                        isLoading={isLoading}
                        serviceDetail={finalJobData.service}
                        jobId={jobId}
                        parentJobId={finalJobData.parent_job_id || finalJobData.id}
                        emoji={finalJobData.emoji}
                    />
                    <CustomerInfo isJobDetail isLoading={isLoading} customerDetail={jobData.customer} />
                    <Schedule
                        isShowSetting
                        jobData={jobData}
                        isShowTextWPool
                        onOpenSettingsSchedule={onOpenSettingsSchedule}
                    />
                    <Commission isLoading={isLoading} jobData={jobData} />
                    <TimeWindow isLoading={isLoading} />
                    <Location isLoading={isLoading} jobData={jobData} onUpdateSuccess={_handleUpdateJobSuccess} />
                </div>

                {!isLoading && (
                    <JobDetailTimeLine
                        jobCreated={finalJobData.logs}
                        jobId={jobId}
                        serviceName={finalJobData?.service?.name}
                        customerDetail={jobData.customer}
                        jobTodo={finalJobData.todo}
                        jobEvent={jobData?.event}
                        locationName={jobData.location.name}
                        messaging={jobData.messaging_preferences}
                        contacts={jobData.contact}
                    />
                )}
            </div>
        </div>
    );
}
