import {
    GET_LIST_NOTIFICATION,
    MARK_READ_AND_UNREAD_NOTIFICATION,
    NOTIFICATION_COUNT,
    NOTIFICATION_SETTING_LIST,
    NOTIFICATION_SETTING_UPDATE_STATUS
} from 'app/const/Api';
import { fetchWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getCountNotification({ actionSuccess, actionFailed }) {
    const response = yield call(fetchWithToken, NOTIFICATION_COUNT);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* getListNotification({ params, actionSuccess, actionFailed }) {
    const response = yield call(fetchWithToken, GET_LIST_NOTIFICATION, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* updateMarkReadAndUnRead({ params, actionSuccess, actionFailed }) {
    const response = yield call(putWithToken, MARK_READ_AND_UNREAD_NOTIFICATION, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* getListNotificationsSettingRequest({ actionSuccess, actionFailed }) {
    const response = yield call(fetchWithToken, NOTIFICATION_SETTING_LIST);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* updateNotificationsSettingStatus({ params, actionSuccess, actionFailed }) {
    const response = yield call(putWithToken, NOTIFICATION_SETTING_UPDATE_STATUS, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}
