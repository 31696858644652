import React from 'react';

export default function IconRocket({ isAngle = false }) {
    if (isAngle) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4571 8.47372L17.734 11.609C15.4562 14.2317 12.311 15.9474 8.87275 16.4428L5.87275 11.2466C8.02086 8.51673 11.0794 6.6507 14.4896 5.98941L18.5663 5.19886C19.6507 4.98858 20.7002 5.69717 20.9105 6.78154C21.0276 7.38531 20.8603 8.00938 20.4571 8.47372Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M12.5689 10.8447C13.1211 11.8013 14.3443 12.129 15.3009 11.5768C16.2575 11.0245 16.5852 9.8013 16.033 8.84471C15.4807 7.88812 14.2575 7.56037 13.3009 8.11266C12.3443 8.66494 12.0166 9.88812 12.5689 10.8447Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M9.6174 15.9759L8.64072 20.0409L9.14072 20.9069L12.8655 17.6017L15.4189 13.7811"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M6.6334 10.8075L2.6247 9.62083L2.1247 8.75481L6.84944 7.18168L11.4349 6.88061"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M11.7029 11.3447L5.6407 14.8447"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5643 3.16231L14.9181 7.08816C16.0505 10.3721 15.9637 13.9539 14.6736 17.1792L8.6736 17.1792C7.38349 13.9539 7.29671 10.3721 8.42912 7.08816L9.78286 3.16231C10.1429 2.11808 11.2814 1.56347 12.3256 1.92355C12.907 2.12404 13.3639 2.58089 13.5643 3.16231Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M11.6736 11.1792C12.7782 11.1792 13.6736 10.2837 13.6736 9.17917C13.6736 8.0746 12.7782 7.17917 11.6736 7.17917C10.569 7.17917 9.67358 8.0746 9.67358 9.17917C9.67358 10.2837 10.569 11.1792 11.6736 11.1792Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M14.6416 16.3008L17.6736 19.1791L18.6736 19.1791L17.6736 14.3008L15.6416 10.1791"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M8.67358 16.3008L5.64157 19.1791L4.64157 19.1791L5.64157 14.3008L7.67358 10.1791"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M11.6736 12.1791L11.6736 19.1791"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
        </svg>
    );
}
