import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdConfirm from 'app/components/confirm';
import { reducer } from 'app/const/Reducer';
import { AFTER_HOURS_KEY, TYPE_TEMPLATE_ID } from 'app/const/Settings';
import { SMS_GET_LIST_VARIABLE } from 'app/const/api/Sms';
import { GET_LIST_TEMPLATE_VARIABLES } from 'app/const/api/V2';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { useSettingTemplate } from 'app/modules/settings/templates/SettingTemplateContext';
import IconInsertVariable from 'assets/icon/IconInsertVariable';
import { clientQuery } from 'common/utils/ApiUtils';

const CustomVariablesOption = ({ id, type = null, template = null, isSMSVariable = false, onInsert = () => {} }) => {
    const { t } = useTranslation();
    const { typeAppointment, isFetchedVariables, onUpdateFetchedVariables } = useSettingTemplate();
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, isLoading } = state;
    const isGetSMSVariable = type === AFTER_HOURS_KEY || isSMSVariable;
    const isTemplateCustom = template === TYPE_TEMPLATE_ID.CUSTOM;

    const refFirstLoad = useRef(true);
    const refDropdown = useRef(null);
    const refConfirm = useRef(null);

    useEffect(() => {
        if (isTemplateCustom && !isFetchedVariables) {
            dispatchState((prev) => ({ ...prev, data: [], isLoading: true }));
            refFirstLoad.current = true;
        }
    }, [isFetchedVariables]);

    const _handleGetData = () => {
        if (!refFirstLoad.current) return;
        /* Checking if the template or type is empty, if it is empty, it will set the data to empty and remove loading. */
        if ((!template || !type) && !isGetSMSVariable) {
            dispatchState({ data: [], isLoading: false });
            refFirstLoad.current = false;
            return;
        }

        const dataQuery = { type, template };
        const paramsId = isTemplateCustom ? typeAppointment : id;
        if (paramsId) {
            dataQuery['id'] = paramsId;
        }

        clientQuery(
            isGetSMSVariable ? SMS_GET_LIST_VARIABLE : GET_LIST_TEMPLATE_VARIABLES,
            { data: dataQuery, method: 'GET' },
            _handleGetSuccess,
            _handleGetFailure,
            _handleGetFinally
        );
    };

    const _handleGetSuccess = ({ data }) => {
        let finalData = data;
        if (isGetSMSVariable)
            finalData = data.map((item) => ({ id: item.value, name: item.name, variable: item.value }));
        dispatchState({ data: finalData, isLoading: false });
    };

    const _handleGetFailure = ({ message }) => {
        refConfirm.current?.open(null, message);
        dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
    };

    const _handleGetFinally = () => {
        if (isTemplateCustom) {
            onUpdateFetchedVariables(true);
        }
        refFirstLoad.current = false;
    };

    const _handleInsertVariable = (variable) => {
        refDropdown.current._closeDropdown();
        onInsert(variable);
    };

    return (
        <div className="editor-controls --action">
            <CalendarDropdown
                ref={refDropdown}
                id="insert-variable"
                wrapperClassName="list-variable insert-variable"
                buttonClassName="dropbtn editor-controls__btn tooltip"
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                options={data}
                isLoading={isLoading}
                keyGetValue="variable"
                keyGetName="name"
                keyGetKey="id"
                useMouseDownOpen
                isDropUp
                onVisible={_handleGetData}
                onSelect={_handleInsertVariable}
                customDropButton={() => (
                    <>
                        <IconInsertVariable />
                        <span className="tooltiptext top">{t('insert_variable')}</span>
                    </>
                )}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('confirm')}
                titleConfirm={t('confirm')}
                listButton={{ confirm: true, cancel: true }}
            />
        </div>
    );
};

export default CustomVariablesOption;
