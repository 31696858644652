import { ADDONS_LEVEL_ALL, ADDONS_STATUS_ALL } from 'app/const/addons';
import React from 'react';
import HeaderSearch from './HeaderSearch';
import HeaderAddonsTabs from './HeaderTabs';

const HeaderDashboard = ({
    isSearching = false,
    currentStatus = ADDONS_STATUS_ALL,
    currentLevel = ADDONS_LEVEL_ALL,
    onChangeStatus = () => {},
    onChangeLevel = () => {},
    onClearSearch = () => {},
    onSearch = () => {}
}) => {
    return (
        <div className="tabs-heading my-6">
            <HeaderAddonsTabs
                currentStatus={currentStatus}
                currentLevel={currentLevel}
                onChangeStatus={onChangeStatus}
                onChangeLevel={onChangeLevel}
            />
            <HeaderSearch isSearching={isSearching} onSearch={onSearch} onClearSearch={onClearSearch} />
        </div>
    );
};

export default HeaderDashboard;
