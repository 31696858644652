import { MIXPANEL_EVENT_NAME, MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';
import { getTimeEventMixpanel } from 'common/utils/MixpanelUtils';
import { capitalizeEachWord } from 'common/utils/StringUtils';

export const mixpanelLogin = ({ profile = {}, company = {}, isHaveToken = false }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.LAST_LOGIN]: getTimeEventMixpanel(),
        [MIXPANEL_PROPERTIES.$NAME]: capitalizeEachWord(profile.name),
        [MIXPANEL_PROPERTIES.$EMAIL]: profile.email,
        [MIXPANEL_PROPERTIES.$PHONE]: company.phone,
        [MIXPANEL_PROPERTIES.COMPANY]: capitalizeEachWord(company.name),
        [MIXPANEL_PROPERTIES.ROLE]: profile.role,
        [MIXPANEL_PROPERTIES.PLAN_TYPE]: profile.plan?.type,
        [MIXPANEL_PROPERTIES.TERM_LENGTH]: profile.plan?.term_length,
        [MIXPANEL_PROPERTIES.PLAN_PRICE]: profile.plan?.price
    };

    return {
        type: isHaveToken ? MIXPANEL_EVENT_NAME.LOGIN_BY_TOKEN : MIXPANEL_EVENT_NAME.LOGIN,
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: profile.id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet,
            [MIXPANEL_METHOD.SET_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_LOGIN]: getTimeEventMixpanel(profile.signed_up_at, true)
            },
            [MIXPANEL_METHOD.INCREMENT]: MIXPANEL_PROPERTIES.INCREMENT_LOGINS,
            [MIXPANEL_METHOD.REGISTER_ONCE]: {
                [MIXPANEL_PROPERTIES.FIRST_LOGIN]: getTimeEventMixpanel(profile.signed_up_at, true)
            }
        }
    };
};
