import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';

const calendarReducer = (state = {
    reloadCalendar: 0
}, action) => {
    switch (action.type) {
        case CALENDAR_ACTIONS.ACTION_RELOAD_FULL_CALENDAR:
            return { ...state, reloadCalendar: new Date().getTime() };
        default:
            return state;
    }
};

export default calendarReducer;
