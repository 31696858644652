import React, { useReducer, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListDocumentType, GET_LIST_DOCUMENTS } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import ReactModal from 'react-modal';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';

import GdSearch from 'app/components/search/GdSearch';
import Loading from 'app/modules/quickadd/components/Loading';
import SearchCustomer from 'app/modules/quickadd/components/SearchCustomer';
import FilterLocations from './FilterLocations';
import IconClose from 'assets/icon/IconClose';
import IconPlus from 'assets/icon/IconPlus';
import StatusBar from 'app/components/status/statusbar';
import ButtonSave from 'app/components/button/ButtonSave';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import LoadMore from 'app/modules/jobdetail/tabs/documents/components/LoadMore';
import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { DOCUMENT_LIBRARY, TABS_DOCUMENT_FILTER } from 'app/modules/addons/documents/constants';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { LoadingDocumentType } from 'app/modules/jobdetail/tabs/documents/components/Loading';
import { handleAbortController } from 'app/modules/customer/utils';
import { ADDONS_MANAGE_DOCUMENTS_LIBRARY } from 'app/const/addons';
import NameTagPdf from 'app/modules/addons/documents/components/manage/NameTagPdf';

const NewDocument = forwardRef(({ type = 1, idCustomer = null, onAddDocsSuccess = () => {}, isDocCustomer }, ref) => {
    // eslint-disable-next-line no-undef
    const customerDetail = global.customerDetail;
    const isQuickAddCustomer = !!customerDetail;

    const documentsAddon = useSelector(({ auth }) => auth.user.settings.addons.documents);
    const isHaveAccessPdf = !!documentsAddon?.[ADDONS_MANAGE_DOCUMENTS_LIBRARY];

    const { t } = useTranslation(['header', 'customers']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoading: true,
        isLoadingSubmit: false,
        customerId: idCustomer,
        locationId: '',
        templates: [],
        errors: {},
        activeTab: DOCUMENT_LIBRARY.DOC,
        isLoadingDocs: true,
        data: [],
        keyword: '',
        showMore: false
    });
    const listRef = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refLoadMore = useRef(null);
    const abortController = useRef(null);

    const {
        isVisible,
        isLoading,
        customerId,
        locationId,
        templates,
        activeTab,
        isLoadingDocs,
        keyword,
        showMore,
        data: documents
    } = state;
    const isEmptyLocation = !!!locationId;

    useEffect(() => {
        if (!isVisible) return;
        _getListDocumentType({ keyword: keyword, offset: 0, reset: true, type: activeTab });
    }, [isVisible]);

    useImperativeHandle(ref, () => ({
        _handleOpenForm
    }));

    const _handleOnScrollContent = (e) => {
        if (!isLoading && showMore && isScrollToEndBottom(e.target) && !refLoadMore.current.getStatus()) {
            _getListDocumentType({
                keyword: keyword,
                offset: documents?.length,
                limit: 20,
                reset: false,
                type: activeTab
            });
            refLoadMore.current.showLoading();
        }
    };

    const _getListDocumentType = (params) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _getListDocumentTypeSuccess = ({ data, show_more }, params) => {
            dispatchState((prev) => {
                const finalData = data.map((item) => ({ ...item, id: item.parent_id }));

                return {
                    ...prev,
                    data: params?.reset ? finalData : [...prev.data, ...finalData],
                    showMore: show_more,
                    isLoadingDocs: false,
                    isLoading: false
                };
            });
            refLoadMore.current?.removeLoading();
        };

        const _getListDocumentTypeFailed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ isLoading: false });
            refLoadMore.current?.removeLoading();
        };

        const newParams = { ...params, limit: 20 };
        delete newParams.reset;
        const optionsQuery = {
            data: newParams,
            method: 'GET',
            abortController: abortController.current
        };
        clientQuery(
            getListDocumentType,
            optionsQuery,
            (response) => _getListDocumentTypeSuccess(response, params),
            _getListDocumentTypeFailed
        );
    };

    const _handleSelectCustomer = (id) => {
        customerId !== id &&
            dispatchState({
                customerId: id,
                locationId: ''
            });
    };

    const _getLocation = (locationId) => {
        dispatchState({
            locationId: locationId
        });
    };

    const _handleAdd = (e, value) => {
        e && e.stopPropagation();
        const newTemplates = [...templates];
        newTemplates.push(value);
        dispatchState({
            templates: newTemplates
        });
    };

    const _handleRemove = (e, value) => {
        e && e.stopPropagation();
        const newTemplates = [...templates].filter((item) => item !== value);
        dispatchState({
            templates: newTemplates
        });
    };

    const _handleSearch = (value) => {
        listRef.current && (listRef.current.scrollTop = 0);
        dispatchState((prev) => ({ ...prev, isLoadingDocs: true, keyword: value }));
        const params = { keyword: value, offset: 0, limit: 20, reset: true, type: activeTab };
        _getListDocumentType(params);
    };

    const _preventDefault = (e) => {
        e && e.preventDefault();
    };

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const _handleOpenForm = () => {
        dispatchState((prev) => ({
            ...prev,
            isVisible: true,
            customerId: idCustomer,
            isLoadingDocs: true
        }));
    };

    const _handleCloseForm = (e) => {
        _stopPropagation(e);
        dispatchState((prev) => ({
            ...prev,
            isVisible: false,
            templates: [],
            locationId: '',
            errors: {},
            customerId: '',
            activeTab: DOCUMENT_LIBRARY.DOC,
            keyword: ''
        }));
    };

    const _handleRemoveLoading = () => {
        refButtonSave.current && refButtonSave.current.removeLoading();
    };

    const _handleSubmit = (e) => {
        _preventDefault(e);
        _stopPropagation(e);

        const _handleSubmitAddDocumentSuccess = (response) => {
            onAddDocsSuccess(response.data);
            refStatusBar.current?.showStatusBar(
                'show_error',
                response.message?.toString() || 'Success',
                LIST_STATUS.SUCCESS
            );
            _handleRemoveLoading();
            setTimeout(() => {
                _handleCloseForm();
            }, 2000);
        };

        const _handleSubmitAddDocumentFailed = (response) => {
            refStatusBar.current?.showStatusBar(
                'show_error',
                response.message?.toString() || 'Please try again',
                LIST_STATUS.ERROR
            );
            _handleRemoveLoading();
        };

        const errors = [];
        const params = {
            type_id: customerId || customerDetail?.id,
            type: type,
            location_id: locationId,
            template_ids: templates
        };

        if (!params.type_id) {
            errors.push('Customer cannot be blank');
        }
        if (params.template_ids.length === 0) {
            errors.push('Template cannot be blank.');
        }

        if (errors.length) {
            refStatusBar.current.showStatusBar('show_error', errors, LIST_STATUS.ERROR);
            _handleRemoveLoading();
            return false;
        } else {
            const optionsQuery = { data: params, method: 'POST' };
            clientQuery(
                GET_LIST_DOCUMENTS,
                optionsQuery,
                _handleSubmitAddDocumentSuccess,
                _handleSubmitAddDocumentFailed
            );
        }
    };

    const _renderList = (list) => {
        if (!Array.isArray(list) || list.length === 0) return <GridEmpty isFlat />;

        return list.map((item) => {
            const isSelected = templates?.includes(item.id) || false;
            return (
                <div key={item?.id} className={`document-rows ${isSelected && 'active'}`}>
                    <div className="action-document">
                        <div
                            onClick={(e) => {
                                _handleAdd(e, item?.id);
                            }}
                            className="add-docs"
                        >
                            <div className="v2-btn-default has-icon" tabIndex="0">
                                <IconPlus />
                                {t('jobDetail:add')}
                            </div>
                        </div>
                        <div
                            onClick={(e) => {
                                _handleRemove(e, item?.id);
                            }}
                            className="remove-docs"
                        >
                            <div className="v2-btn-default has-icon" tabIndex="0">
                                <IconClose />
                                {t('jobDetail:remove')}
                            </div>
                        </div>
                    </div>
                    <NameTagPdf wrapClassName="docs-name" {...item} />
                </div>
            );
        });
    };

    const _renderLocations = () => {
        if (!customerId && !isQuickAddCustomer) return null;

        return (
            <div className="col col-location">
                <p className="txt txt-ellipsis">
                    {t('customers:location')}
                    <span className="red-default ml-1">*</span>
                </p>
                <FilterLocations
                    customerId={customerDetail?.id || customerId}
                    selected={locationId}
                    onSelect={_getLocation}
                    isDocCustomer={isDocCustomer}
                />
            </div>
        );
    };

    const _renderCustomer = () => {
        return (
            <div className="col col-customer">
                <p className="txt txt-ellipsis">{t('customers:customer')}</p>
                <SearchCustomer
                    classWrapper="v2-dropdown"
                    selected={customerId}
                    onSelect={_handleSelectCustomer}
                    autoFocus={!isQuickAddCustomer}
                    isDisable={isQuickAddCustomer}
                    customerName={customerDetail?.full_name}
                />
            </div>
        );
    };

    const _handleChangeTab = (id) => {
        dispatchState((prev) => ({ ...prev, activeTab: id, isLoadingDocs: true }));
        _getListDocumentType({ keyword: keyword, offset: 0, limit: 20, reset: true, type: id });
    };

    if (!isVisible) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_handleCloseForm}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleCloseForm} />
            <div className="modal__container modal-document">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:new_documents')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseForm} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                {isLoading ? (
                    <Loading />
                ) : (
                    <div className="body-modal ">
                        <div className="has-form">
                            <StatusBar ref={refStatusBar} />
                            <div className="rows row-haft fs-13">
                                {!idCustomer && _renderCustomer()}
                                {_renderLocations()}
                            </div>
                        </div>
                        <div className={`search-form ${isEmptyLocation ? 'is-disable' : ''}`}>
                            {isHaveAccessPdf ? (
                                <TabSwitcher
                                    activeTab={activeTab}
                                    listTabs={TABS_DOCUMENT_FILTER}
                                    wrapperClassName="tabs mr-2"
                                    onChange={_handleChangeTab}
                                />
                            ) : null}
                            <GdSearch
                                placeholder={`${t('jobDetail:search')}`}
                                onSearch={_handleSearch}
                                style="search-input w-100"
                                defaultValue={keyword}
                                isSearchOnClear
                            />
                        </div>
                        {isLoadingDocs ? (
                            <LoadingDocumentType />
                        ) : (
                            <div
                                className={`document-list ${isEmptyLocation ? 'is-disable' : ''}`}
                                onScroll={_handleOnScrollContent}
                                ref={listRef}
                            >
                                <div className="scrolls">
                                    {_renderList(documents)}
                                    <LoadMore ref={refLoadMore} />
                                </div>
                            </div>
                        )}
                    </div>
                )}
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_handleCloseForm} tabIndex="0">
                        {t('customers:cancel')}
                    </span>
                    <ButtonSave onSave={_handleSubmit} ref={refButtonSave} disabled={isEmptyLocation} />
                </div>
            </div>
        </ReactModal>
    );
});

export default NewDocument;
