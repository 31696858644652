import React from 'react';

import { LIST_ITEMS_PARSE_HTML } from 'app/const/addons';

const AddonsDescription = ({ descriptionClassName = 'description', keyword = '', description = '' }) => {
    const isParseHTML = LIST_ITEMS_PARSE_HTML.includes(keyword);

    if (isParseHTML) return <p className={descriptionClassName} dangerouslySetInnerHTML={{ __html: description }} />;

    return <p className={descriptionClassName}>{description}</p>;
};

export default AddonsDescription;
