import React, { useEffect, useRef, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconClose from 'assets/icon/IconClose';
import { getListCustomerReport } from 'common/redux/actions/customers/customerAction';
import IconLoading from 'assets/icon/IconLoading';
import { useTranslation } from 'react-i18next';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { KEY_REPORT_LOCAL_STORAGE, DEFAULT_ALL, CUSTOMER_SEARCH_LIMIT, KEY_CODE_ESCAPE } from 'app/const/App';
import IconDropDown from 'assets/icon/IconDropDown';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';

const MultiSelectClients = ({ name, selected, title, onSelect, reportType, classWrapper = '' }) => {
    const { t } = useTranslation(['report', 'header', 'common']);
    const { list_customer, total_customer } = useSelector((store) => store.CustomerReport);
    const dispatch = useDispatch();
    const listRef = useRef(null);
    const refDropDown = useRef(null);
    const KEY_FILTER = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType, '_', name);

    const setStorage = (selectedClients = []) => {
        setLocalStorage(KEY_FILTER, selectedClients);
    };

    const initState = {
        isOpen: false,
        isLoading: true,
        options: [],
        keyword: '',
        totalSearch: 0,
        selected: getLocalStorage(KEY_FILTER) || selected
    };

    const [state, dispatchState] = useReducer(reducer, { ...initState });
    const listClients = state.options;
    const listClientsSelected = state.selected || [];
    const isSelectAll = listClientsSelected === DEFAULT_ALL;
    const isLoading = state.isLoading;
    const isOpen = state.isOpen;
    const listClientIds = isSelectAll ? [] : listClientsSelected.map((client) => client.id);
    const keyword = state.keyword;
    const totalSearch = state.totalSearch;
    const isFirstTime = useRef(true);
    const SELECTED_LIMIT_CLIENT = 3;

    const dropdownId = `multi_select_${name}`;
    const paramsSearch = {
        limit: CUSTOMER_SEARCH_LIMIT,
        offset: 0,
        total: 1,
        localSaveStore: true,
        localResetData: true
    };

    useEffect(() => {
        if (isOpen) {
            isFirstTime.current && _getListCustomer({ ...paramsSearch });
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isOpen]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            dispatchState({ isOpen: false });
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            dispatchState({ isOpen: false });
        }
    };

    function _getListCustomer(params) {
        dispatch(
            getListCustomerReport(
                params,
                (response) => _getListCustomerSuccess(response, params),
                () => {}
            )
        );
    }

    function _getListCustomerSuccess(response, params) {
        isFirstTime.current = false;

        dispatchState({
            isLoading: false,
            options: !params.localResetData ? [...listClients, ...response.data] : response.data,
            totalSearch: keyword && params.total === 1 ? response?.total : totalSearch
        });
    }

    const toggleOpen = () => {
        dispatchState({ isOpen: !isOpen });
    };

    const createListClients = (selectedClient, currentList, totalLength) => {
        let listClients = [];
        if (currentList === DEFAULT_ALL) {
            listClients.push(selectedClient);
            return listClients;
        }
        if (Array.isArray(currentList)) {
            listClients = currentList.filter((client) => client.id !== selectedClient.id);
            if (currentList.length === listClients.length) listClients.push(selectedClient);
            if (listClients.length === totalLength) listClients = DEFAULT_ALL;
            return listClients;
        }
    };

    const handleSelect = (e, value) => {
        e && e.stopPropagation();
        const selectedList = createListClients(value, listClientsSelected, total_customer);
        setStorage(selectedList);
        dispatchState({ selected: selectedList });
        onSelect(name, Array.isArray(selectedList) ? selectedList.map((client) => client.id).toString() : DEFAULT_ALL);
    };

    const handleSelectAll = (e) => {
        e && e.stopPropagation();
        const value = listClientsSelected === DEFAULT_ALL ? [] : DEFAULT_ALL;
        setStorage(value);
        dispatchState({ selected: value });
        onSelect(name, value.toString());
    };

    const handleClearAll = (e) => {
        e && e.stopPropagation();
        setStorage('');
        dispatchState({ selected: [] });
        onSelect(name, '');
    };

    function _handleChangeSearch(keyword) {
        if (keyword === '') {
            dispatchState({
                isLoading: false,
                options: list_customer,
                keyword: ''
            });
            listRef.current.scrollTo(0, 0);
        } else {
            dispatchState({
                isLoading: true,
                options: [],
                keyword: keyword
            });
            const params = { ...paramsSearch, localSaveStore: false, keyword };
            _getListCustomer(params);
        }
    }

    function handleOnScrollContent(e) {
        const listClientsLength = listClients.length;
        const isSearching = keyword !== '';
        const finalTotalCompare = isSearching ? totalSearch : total_customer;

        if (!isLoading && isScrollToEndBottom(e.target) && listClientsLength < finalTotalCompare) {
            dispatchState({ isLoading: true });
            const params = {
                ...paramsSearch,
                offset: listClientsLength,
                localSaveStore: !isSearching,
                localResetData: false,
                keyword: keyword
            };
            _getListCustomer(params);
        }
    }

    const createName = (client) => {
        return `${client.first_name} ${client.last_name}`;
    };

    const renderSelectAllOption = () => {
        return (
            <li className={`items ${isSelectAll ? 'active' : ''}`} onClick={(e) => handleSelectAll(e)}>
                <div className="txt-ellipsis">{title || t('report:select_all')}</div>
            </li>
        );
    };

    const renderList = (list) => {
        return list.map((item) => {
            const itemId = item.id;

            const isSelected = isSelectAll || listClientIds.includes(itemId);
            return (
                <li
                    key={itemId}
                    className={`items ${isSelected ? 'active' : ''}`}
                    onClick={(e) => handleSelect(e, item)}
                >
                    <div className="user-name">
                        <div className="avt fs-11 fw-500">{item.avatar}</div>
                        <span className="txt-ellipsis">{createName(item)}</span>
                    </div>
                </li>
            );
        });
    };

    const renderTitle = () => {
        if (isSelectAll || !listClientsSelected?.length)
            return (
                <p className="budget --grey ml-1">
                    <span className="txt-ellipsis m-0">{title}</span>
                    <span onClick={(e) => handleClearAll(e)}>
                        <IconClose isSmall />
                    </span>
                </p>
            );

        return listClientsSelected.map((client, index) => {
            if (index < SELECTED_LIMIT_CLIENT) {
                return (
                    <p key={client.id} className="budget --grey ml-1">
                        <span className="txt-ellipsis m-0">{createName(client)}</span>
                        <span onClick={(e) => handleSelect(e, client)}>
                            <IconClose isSmall />
                        </span>
                    </p>
                );
            }
            return false;
        });
    };

    return (
        <div ref={refDropDown} id={dropdownId} className={classNames('v2-dropdown', classWrapper, { active: isOpen })}>
            <div className="dropbtn v2-btn-default selection" onClick={toggleOpen}>
                <span className="txt">{t('report:clients')}</span>
                {renderTitle()}
                {listClientsSelected.length > SELECTED_LIMIT_CLIENT && (
                    <span className="budget --grey ml-1">
                        <span>+{listClientsSelected.length - SELECTED_LIMIT_CLIENT}</span>
                    </span>
                )}
                {listClientIds.length || isSelectAll ? (
                    <div onClick={(e) => handleClearAll(e)}>
                        <IconCircleClose />
                    </div>
                ) : null}
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </div>

            <div className="v2-dropdown__menu content-search content-user">
                <div className="container-column">
                    <SearchOption
                        placeholder={t('header:search')}
                        onSearch={_handleChangeSearch}
                        defaultValue={''}
                        style="field-search"
                        isSearchIcon
                    />
                    {keyword === '' && <div className="content-checked__all">{renderSelectAllOption()}</div>}
                    <ul ref={listRef} className="box-auto scrolls" onScroll={handleOnScrollContent}>
                        {renderList(listClients)}
                        {isLoading && (
                            <div className="items justify-center">
                                <div className="loading -ajaxbar">
                                    <IconLoading />
                                </div>
                            </div>
                        )}
                    </ul>
                    {listClients.length === 0 && !isLoading && (
                        <div className="items justify-center pointer-events-none">
                            <div className="loading -ajaxbar">
                                {keyword
                                    ? t('header:search_not_match')
                                    : t('common:no_data_to_display', { title: t('report:clients') })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MultiSelectClients;
