import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DoughnutChart from 'app/components/chart/doughnut';
import GDLineChart from 'app/components/chart/line';
import { reducer } from 'app/const/Reducer';
import ServiceDashboard from '../../ServiceDashboard';
import Schedules from '../../components/Schedules';
import WidgetDatePicker from '../../components/datepicker';
import WidgetsInfo from '../../components/widgetsInfo';
import {
    KEY_WIDGET_LOCAL_STORAGE,
    LIST_RENDER_LINE_CHART_JOBS,
    LIST_WIDGETS,
    SIZE_CHART,
    getDefaultParamsWidget
} from '../../const';
import { configCompleteOrInComplete } from './config';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatDateDashboard } from 'common/utils/DateUtils';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import { useSelector } from 'react-redux';
import LoadingJobs from '../loading/LoadingJobs';
import classNames from 'classnames';

export default function WidgetJobs() {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.JOBS;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        params: defaultParams,
        total: ''
    });

    const { isLoading: finalIsLoading, data: finalData, total: finalTotal } = state;

    useEffect(() => {
        fetchJobsData(defaultParams);
    }, []);

    const fetchJobsData = (params) => {
        refService.current._handleGetData({ ...params });
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchJobsData(newParams);
    };

    const _handleGetDataSuccess = ({ data, total }) => {
        dispatchState((prev) => ({
            ...prev,
            data: data.map((item) => {
                const color = item.color;

                return {
                    ...item,
                    label: capitalizeFirstLetter(item.status),
                    color,
                    borderColor: color
                };
            }),
            total,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderContent = () => {
        const { start, end } = defaultParams;
        if (finalIsLoading) {
            return <LoadingJobs />;
        }
        return (
            <div className="boxs__content">
                <div className="chart">
                    <div className="chart__content --job-status">
                        <div className="content-l">
                            <WidgetsInfo dataWidget={{ total: finalTotal, data: finalData }} />
                        </div>
                        <div className="content-r">
                            <div style={{ width: '160px' }}>
                                <DoughnutChart dataCharts={finalData} />
                            </div>
                        </div>
                    </div>
                    <div className="chart__footer">
                        <p>{formatDateDashboard(start, end)}</p>
                        <GDLineChart
                            id={keyWidget}
                            configLineChart={configCompleteOrInComplete({
                                data: finalData.filter((dataFilter) =>
                                    LIST_RENDER_LINE_CHART_JOBS.includes(dataFilter.status)
                                ),
                                currency
                            })}
                            size={SIZE_CHART.SMALL}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className={classNames('boxs', { 'wrap-loading': finalIsLoading })}>
                <div className="boxs__header">
                    <div className="header-label flex-1">{t('jobs')}</div>
                    <Schedules
                        keyWidget={keyWidget}
                        defaultSelected={defaultParams.schedules}
                        onSelect={(value) => _handleChangeFilterWidget({ schedules: value })}
                    />
                    <WidgetDatePicker
                        keyWidget={keyWidget}
                        isShowIcon
                        selectDefault={defaultParams}
                        onSelect={(values) => _handleChangeFilterWidget({ ...values })}
                    />
                </div>
                {_renderContent()}
            </div>
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </Fragment>
    );
}
