import React, { Fragment, useEffect, useReducer } from 'react';

import { ADDONS_ESTIMATE_DETAIL, UPDATE_ADDONS_ESTIMATE_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonBack from '../components/AddonBack';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import LoadingAddonsEstimate from './components/LoadingAddonsEstimate';
import AddonsOptions from '../components/AddonsOptions';

const AddonsEstimate = () => {
    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true
    });
    const { isLoading, data: finalData } = state;
    const { options, sub_title, status } = finalData;

    useEffect(() => {
        _getDetailAddons();
    }, []);

    const _getDetailAddons = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, data, isLoading: false }));
        };

        clientQuery(ADDONS_ESTIMATE_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) => {
        dispatchState((prev) => ({ ...prev, data: { ...prev.data, status: checked ? 1 : 0 } }));
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>

            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <LoadingAddonsEstimate />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border">
                                <AddonsBoxHeader
                                    {...finalData}
                                    urlUpdateStatus={UPDATE_ADDONS_ESTIMATE_OPTIONS}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                />
                                <div className="boxs__contents mt-3">
                                    <p className="description mt-5">{sub_title}</p>
                                </div>
                                <div className="is-divider --horizontal my-3" />
                            </div>
                            <AddonsOptions
                                data={options || []}
                                urlUpdate={UPDATE_ADDONS_ESTIMATE_OPTIONS}
                                status={status}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsEstimate;
