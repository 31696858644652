export default function IconViewRow({ isHorizontal = false, isGrid }) {
    if (isGrid)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78194 4.5H9.21806C9.66382 4.5 9.82546 4.54641 9.98842 4.63357C10.1514 4.72072 10.2793 4.84861 10.3664 5.01158C10.4536 5.17454 10.5 5.33618 10.5 5.78194V9.21806C10.5 9.66382 10.4536 9.82546 10.3664 9.98842C10.2793 10.1514 10.1514 10.2793 9.98842 10.3664C9.82546 10.4536 9.66382 10.5 9.21806 10.5H5.78194C5.33618 10.5 5.17454 10.4536 5.01158 10.3664C4.84861 10.2793 4.72072 10.1514 4.63357 9.98842C4.54641 9.82546 4.5 9.66382 4.5 9.21806V5.78194C4.5 5.33618 4.54641 5.17454 4.63357 5.01158C4.72072 4.84861 4.84861 4.72072 5.01158 4.63357C5.17454 4.54641 5.33618 4.5 5.78194 4.5Z"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7819 4.5H18.2181C18.6638 4.5 18.8255 4.54641 18.9884 4.63357C19.1514 4.72072 19.2793 4.84861 19.3664 5.01158C19.4536 5.17454 19.5 5.33618 19.5 5.78194V9.21806C19.5 9.66382 19.4536 9.82546 19.3664 9.98842C19.2793 10.1514 19.1514 10.2793 18.9884 10.3664C18.8255 10.4536 18.6638 10.5 18.2181 10.5H14.7819C14.3362 10.5 14.1745 10.4536 14.0116 10.3664C13.8486 10.2793 13.7207 10.1514 13.6336 9.98842C13.5464 9.82546 13.5 9.66382 13.5 9.21806V5.78194C13.5 5.33618 13.5464 5.17454 13.6336 5.01158C13.7207 4.84861 13.8486 4.72072 14.0116 4.63357C14.1745 4.54641 14.3362 4.5 14.7819 4.5Z"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78194 13.5H9.21806C9.66382 13.5 9.82546 13.5464 9.98842 13.6336C10.1514 13.7207 10.2793 13.8486 10.3664 14.0116C10.4536 14.1745 10.5 14.3362 10.5 14.7819V18.2181C10.5 18.6638 10.4536 18.8255 10.3664 18.9884C10.2793 19.1514 10.1514 19.2793 9.98842 19.3664C9.82546 19.4536 9.66382 19.5 9.21806 19.5H5.78194C5.33618 19.5 5.17454 19.4536 5.01158 19.3664C4.84861 19.2793 4.72072 19.1514 4.63357 18.9884C4.54641 18.8255 4.5 18.6638 4.5 18.2181V14.7819C4.5 14.3362 4.54641 14.1745 4.63357 14.0116C4.72072 13.8486 4.84861 13.7207 5.01158 13.6336C5.17454 13.5464 5.33618 13.5 5.78194 13.5Z"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.7819 13.5H18.2181C18.6638 13.5 18.8255 13.5464 18.9884 13.6336C19.1514 13.7207 19.2793 13.8486 19.3664 14.0116C19.4536 14.1745 19.5 14.3362 19.5 14.7819V18.2181C19.5 18.6638 19.4536 18.8255 19.3664 18.9884C19.2793 19.1514 19.1514 19.2793 18.9884 19.3664C18.8255 19.4536 18.6638 19.5 18.2181 19.5H14.7819C14.3362 19.5 14.1745 19.4536 14.0116 19.3664C13.8486 19.2793 13.7207 19.1514 13.6336 18.9884C13.5464 18.8255 13.5 18.6638 13.5 18.2181V14.7819C13.5 14.3362 13.5464 14.1745 13.6336 14.0116C13.7207 13.8486 13.8486 13.7207 14.0116 13.6336C14.1745 13.5464 14.3362 13.5 14.7819 13.5Z"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    if (isHorizontal)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78194 6.5H12.2181C12.6638 6.5 12.8255 6.54641 12.9884 6.63357C13.1514 6.72072 13.2793 6.84861 13.3664 7.01158C13.4536 7.17454 13.5 7.33618 13.5 7.78194V9.21806C13.5 9.66382 13.4536 9.82546 13.3664 9.98842C13.2793 10.1514 13.1514 10.2793 12.9884 10.3664C12.8255 10.4536 12.6638 10.5 12.2181 10.5H5.78194C5.33618 10.5 5.17454 10.4536 5.01158 10.3664C4.84861 10.2793 4.72072 10.1514 4.63357 9.98842C4.54641 9.82546 4.5 9.66382 4.5 9.21806V7.78194C4.5 7.33618 4.54641 7.17454 4.63357 7.01158C4.72072 6.84861 4.84861 6.72072 5.01158 6.63357C5.17454 6.54641 5.33618 6.5 5.78194 6.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.78194 13.5H18.2181C18.6638 13.5 18.8255 13.5464 18.9884 13.6336C19.1514 13.7207 19.2793 13.8486 19.3664 14.0116C19.4536 14.1745 19.5 14.3362 19.5 14.7819V16.2181C19.5 16.6638 19.4536 16.8255 19.3664 16.9884C19.2793 17.1514 19.1514 17.2793 18.9884 17.3664C18.8255 17.4536 18.6638 17.5 18.2181 17.5H5.78194C5.33618 17.5 5.17454 17.4536 5.01158 17.3664C4.84861 17.2793 4.72072 17.1514 4.63357 16.9884C4.54641 16.8255 4.5 16.6638 4.5 16.2181V14.7819C4.5 14.3362 4.54641 14.1745 4.63357 14.0116C4.72072 13.8486 4.84861 13.7207 5.01158 13.6336C5.17454 13.5464 5.33618 13.5 5.78194 13.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.78194 4.5H9.21806C9.66382 4.5 9.82546 4.54641 9.98842 4.63357C10.1514 4.72072 10.2793 4.84861 10.3664 5.01158C10.4536 5.17454 10.5 5.33618 10.5 5.78194V18.2181C10.5 18.6638 10.4536 18.8255 10.3664 18.9884C10.2793 19.1514 10.1514 19.2793 9.98842 19.3664C9.82546 19.4536 9.66382 19.5 9.21806 19.5H7.78194C7.33618 19.5 7.17454 19.4536 7.01158 19.3664C6.84861 19.2793 6.72072 19.1514 6.63357 18.9884C6.54641 18.8255 6.5 18.6638 6.5 18.2181V5.78194C6.5 5.33618 6.54641 5.17454 6.63357 5.01158C6.72072 4.84861 6.84861 4.72072 7.01158 4.63357C7.17454 4.54641 7.33618 4.5 7.78194 4.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7819 4.5H16.2181C16.6638 4.5 16.8255 4.54641 16.9884 4.63357C17.1514 4.72072 17.2793 4.84861 17.3664 5.01158C17.4536 5.17454 17.5 5.33618 17.5 5.78194V12.2181C17.5 12.6638 17.4536 12.8255 17.3664 12.9884C17.2793 13.1514 17.1514 13.2793 16.9884 13.3664C16.8255 13.4536 16.6638 13.5 16.2181 13.5H14.7819C14.3362 13.5 14.1745 13.4536 14.0116 13.3664C13.8486 13.2793 13.7207 13.1514 13.6336 12.9884C13.5464 12.8255 13.5 12.6638 13.5 12.2181V5.78194C13.5 5.33618 13.5464 5.17454 13.6336 5.01158C13.7207 4.84861 13.8486 4.72072 14.0116 4.63357C14.1745 4.54641 14.3362 4.5 14.7819 4.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
