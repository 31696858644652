import { useEffect } from 'react';

const useEnterKeydownClick = (depend) => {
    useEffect(() => {
        depend
            ? document.addEventListener('keydown', _handleOnKeydown)
            : document.removeEventListener('keydown', _handleOnKeydown);
        return () => document.removeEventListener('keydown', _handleOnKeydown);
    }, [depend]);

    function _handleOnKeydown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            const elActive = document.activeElement;
            if (elActive.tagName === 'TEXTAREA' || elActive.getAttribute('contenteditable')) return;
            e.preventDefault();
            const style = window.getComputedStyle(elActive);
            style.getPropertyValue('pointer-events') !== 'none' && elActive.click();
        }
    }
};

export default useEnterKeydownClick;
