import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import Content from 'app/modules/customer/components/Content';
import { useParams } from 'react-router-dom';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionNotes } from 'app/const/Api';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';

const EditTopNote = forwardRef(({ topNoteDetail, onUpdate, type, jobId, unitId, isEdited = false }, ref) => {
    const { id } = useParams();

    const [state, setState] = useState({ isVisible: false, topNoteData: {} });

    const { topNoteData, isVisible: finalIsVisible } = state;
    const finalListAttachments = topNoteData?.attachments || [];

    const refContent = useRef(null);

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide,
        setValue: (newContent, isConcat) => {
            if (isConcat) {
                refContent.current.setValue(refContent.current.getContent().concat(newContent));
            } else {
                refContent.current.setValue(newContent);
            }
        }
    }));

    useEffect(() => {
        if (finalIsVisible && refContent.current) {
            refContent.current.focus();
        }
    }, [finalIsVisible]);

    function _handleEdit(content) {
        refContent.current.setUploadLoading(true);
        const attachments = refContent.current.getAttachments() || [];
        const newAttachments = [];
        attachments.forEach((item) => {
            if (!item.isDefault) {
                newAttachments.push({
                    object_key: item.object_key,
                    object_tag: item.object_tag,
                    mime: item.mime,
                    name: item.name,
                    size: item.size,
                    type: item.type
                });
            }
        });

        const _handleFailed = () => refContent.current.setUploadLoading(false);

        clientQuery(
            actionNotes(topNoteDetail.id),
            {
                method: 'PUT',
                data: { content, attachments: newAttachments },
                toFormData: false
            },
            (response) => _handleSuccess(response, content, attachments),
            _handleFailed
        );
    }

    const _handleSuccess = ({ data }, content, prevAttach) => {
        const newAttach = data.attachment.map((item) => {
            return { ...item, isUpdate: true };
        });

        onUpdate({
            ...topNoteData,
            content,
            edited: 1,
            attachments: [...prevAttach, ...newAttach].filter((item) => item.isUpdate || item.isDefault)
        });
        if (refContent.current) refContent.current.setUploadLoading(false);
        _handleCancelEdit();
    };

    function _handleToggleShowHide() {
        setState((prev) => ({ ...prev, isVisible: !finalIsVisible, topNoteData: topNoteDetail }));
    }

    function _handleCancelEdit() {
        if (refContent.current) refContent.current.resetValue();
        const elm = document.getElementById(`form_top_note_detail_${topNoteDetail.id}`);
        elm && elm.classList.toggle('has-edited');

        setState((prev) => ({ ...prev, isVisible: false }));
    }

    if (!finalIsVisible) {
        return false;
    }

    function _getItemId() {
        switch (type) {
            case TYPE_NOTE.CUSTOMER:
                return id;
            case TYPE_NOTE.JOB:
                return jobId;
            case TYPE_NOTE.UNIT:
                return unitId;
            default:
                return '';
        }
    }

    return (
        <div className="wrap-content is-editing">
            <div className="wrap-content-box">
                <Content
                    disableSend={finalListAttachments.some((item) => item.isUploading)}
                    ref={refContent}
                    typePresign="note"
                    itemIdPresign={_getItemId()}
                    content={isEdited ? '' : topNoteDetail.content}
                    attachmentProps={{
                        itemId: _getItemId(),
                        typeAttachment: 'note',
                        defaultAttachments: topNoteDetail.attachments || []
                    }}
                    onCloseEdit={_handleCancelEdit}
                    onUpdate={_handleEdit}
                />
            </div>
        </div>
    );
});

export default EditTopNote;
