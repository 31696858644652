import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ReactModal from 'react-modal';

import { clientQuery } from 'common/utils/ApiUtils';
import { UPDATE_ADDONS_REVIEW_OPTIONS_SETTING } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import {
    ADDONS_BYPASS_SERVICE_REVIEW,
    ADDONS_BYPASS_LIKELY_REVIEW,
    ADDONS_SERVICE_REVIEW,
    ADDONS_LIKELY_REVIEW
} from 'app/const/addons';
import ModalLoadingReviewManager from './ModalLoadingReviewManager';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ButtonSave from 'app/components/button/ButtonSave';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const ModalReviewManager = forwardRef(({ keyword, handleManagerSuccess }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        review: {},
        isDisable: false
    });
    const { t } = useTranslation(['addons', 'commons']);
    const refAlert = useRef(null);
    const refInput = useRef(null);
    const isFirstTime = useRef(true);
    const refButtonSave = useRef(null);
    const refPrevLink = useRef('');
    const { review, isLoading, isDisable } = state;
    const isServiceReview = keyword === ADDONS_SERVICE_REVIEW;
    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        isFirstTime.current && _handleGetReviewSetting();
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleGetReviewSetting = () => {
        const _fail = (err) => {
            _handleFailure(err);
            dispatchState({ isLoading: false, isDisable: true });
        };
        clientQuery(UPDATE_ADDONS_REVIEW_OPTIONS_SETTING, { method: 'GET' }, _handleGetSuccess, _fail);
    };

    const _handleGetSuccess = ({ data }) => {
        isFirstTime.current = false;
        const review = isServiceReview ? data.service : data.likely;
        dispatchState({
            review,
            isLoading: false
        });

        refPrevLink.current = review.link;
    };

    const _handleChange = (e) => {
        const checked = e.target.checked;
        dispatchState({ isEnabled: checked });
        clientQuery(UPDATE_ADDONS_REVIEW_OPTIONS_SETTING, {
            data: {
                value: checked ? 1 : 0,
                keyword: isServiceReview ? ADDONS_BYPASS_SERVICE_REVIEW : ADDONS_BYPASS_LIKELY_REVIEW
            },
            method: 'PUT'
        });
    };

    const _handleSave = () => {
        const value = refInput.current.value;
        refPrevLink.current = value;
        clientQuery(
            UPDATE_ADDONS_REVIEW_OPTIONS_SETTING,
            {
                data: {
                    value,
                    keyword: isServiceReview ? ADDONS_SERVICE_REVIEW : ADDONS_LIKELY_REVIEW
                },
                method: 'PUT'
            },
            _handleManagerSuccess,
            _handleFailure,
            _handleFinally
        );
    };

    const _handleManagerSuccess = () => {
        _close();
        handleManagerSuccess();
    };

    const _handleFailure = (res) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: res.message || t('addons:value_cannot_blank'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleFinally = (res) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: res.message || t('addons:update_social_link_successfully'),
            type: LIST_STATUS.SUCCESS
        });
        refButtonSave.current.removeLoading();
    };

    if (!state.isOpen) return null;

    return (
        <>
            <ReactModal
                id="service_reviews"
                isOpen={state.isOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container large">
                    <AlertCustomer ref={refAlert} />
                    {isLoading ? (
                        <ModalLoadingReviewManager />
                    ) : (
                        <>
                            <div className="header-modal">
                                <h3 className="header-modal__label">
                                    {t(`addons:service_${isServiceReview ? 'reviews' : 'likely'}`)}
                                </h3>
                                <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                                    <IconClose />
                                </div>
                            </div>
                            <div className={classNames('body-modal --reviews scrolls', { 'is-disable': isDisable })}>
                                <div className="description">{t('addons:description_modal_review_line_one')}</div>
                                <div className="description">{t('addons:description_modal_review_line_two')}</div>
                                <div className="description">{t('addons:description_modal_review_line_three')}</div>
                                <span className="link-review">{t('addons:description_modal_review_line_four')}</span>
                                <input
                                    ref={refInput}
                                    defaultValue={refPrevLink.current}
                                    className="field-input"
                                    type="text"
                                    spellCheck
                                />
                                <div className="row-multi mt-3">
                                    <div className="description ">{t('addons:description_modal_review_bottom')}</div>
                                    <div className="switch large">
                                        <input
                                            id="service"
                                            className="toggle toggle-round"
                                            type="checkbox"
                                            checked={state.isEnabled}
                                            defaultChecked={review.bypass}
                                            onChange={_handleChange}
                                        />
                                        <label htmlFor="service"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-modal">
                                <span className="v2-btn-default --transparent" onClick={_close}>
                                    {t('common:cancel')}
                                </span>
                                <ButtonSave ref={refButtonSave} disabled={isDisable} onSave={_handleSave} />
                            </div>
                        </>
                    )}
                </div>
            </ReactModal>
        </>
    );
});

ModalReviewManager.displayName = 'ModalReviewManager';

export default ModalReviewManager;
