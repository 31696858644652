import classNames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconTelephone from 'assets/icon/IconTelephone';
import ServiceSmartDialer from 'app/services/voip/ServiceSmartDialer';
import IconPhone from 'assets/icon/IconPhone';
import IconAlert from 'assets/icon/IconAlert';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import { TYPE_CALL } from 'app/const/Voip';

const CallButton = ({ smartId, smartName, isDisable = false }) => {
    const { t } = useTranslation('header');
    const { state } = useLocation();

    const settings = useSelector(({ auth }) => auth.user.settings);
    const dialers = useSelector(({ voipReducer }) => voipReducer.dialers);
    const { calls, features } = useContext(VOIPContext);
    const refDropdown = useRef(null);
    const refServiceDialer = useRef(null);
    const refFirstTime = useRef(true);

    const isHaveVoip = checkIsEnableVoip(settings);
    const isHaveCallDialer = calls.some((item) => item.type === TYPE_CALL.SMART);
    const callIndex = dialers.find((item) => item.id === smartId)?.call_index;
    const finalDisable = isDisable || !isHaveVoip || isHaveCallDialer || !features?.smart_dialer;
    const isStart = !callIndex;

    useEffect(() => {
        if (!finalDisable && state?.startOver && refFirstTime.current) {
            _handleCreateSmartDialer({ isScratch: true });
            refFirstTime.current = false;
        }
    }, [finalDisable]);

    const _handleCreateSmartDialer = ({ isScratch = false }) => {
        refServiceDialer.current?.createSmartDialer({
            id: smartId,
            name: smartName,
            isScratch,
            callIndex
        });
    };

    const _handleSelectOption = (isScratch = false) => {
        refDropdown.current?._closeDropdown();
        _handleCreateSmartDialer({ isScratch });
    };

    const _renderOptions = () => {
        const typeCall = !isStart ? 'continue' : 'start';
        return (
            <ul>
                <li className="items has-icon gap-8" onClick={() => _handleSelectOption(isStart)}>
                    <IconPhone />
                    <div className="flex-column gap-4 flex-1">
                        <p className="fw-600">{t(`header:${typeCall}_calling`)}</p>
                        <p>{t(`header:desc_${typeCall}_calling`)}</p>
                    </div>
                </li>
                {!isStart ? (
                    <li className="items has-icon gap-8" onClick={() => _handleSelectOption(true)}>
                        <IconAlert />
                        <div className="flex-column gap-4 flex-1">
                            <p className="fw-600">{t('header:start_from_scratch')}</p>
                            <p>{t('header:desc_start_from_scratch')}</p>
                        </div>
                    </li>
                ) : null}
            </ul>
        );
    };

    return (
        <div className={classNames('header-items wrap-button')}>
            <div className="tooltip">
                <div
                    className={classNames('v2-btn-default has-icon wrap-button__left', { 'is-disable': finalDisable })}
                    onClick={() => _handleSelectOption(isStart)}
                >
                    <IconTelephone isNormal />
                    {t('call')}
                </div>
                <span className="tooltiptext top">
                    {!features?.smart_dialer
                        ? t('smartView:tooltip_disable_smart_dialer')
                        : t(
                              `smartView:${
                                  isHaveCallDialer
                                      ? 'tooltip_disable_have_call'
                                      : isDisable
                                      ? 'disable_call_leads_in_this_smart_view'
                                      : 'call_leads_in_this_smart_view'
                              }`
                          )}
                </span>
            </div>
            <CalendarDropdown
                ref={refDropdown}
                id="smart_view_call_button"
                customDropButton={() => (
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                )}
                wrapperClassName={classNames('v2-dropdown wrap-button__right', { 'is-disable': finalDisable })}
                buttonClassName="dropbtn items --icon-lg"
                wrapperListClass="v2-dropdown__menu --right scrolls"
            >
                {_renderOptions()}
            </CalendarDropdown>
            <ServiceSmartDialer ref={refServiceDialer} />
        </div>
    );
};

export default CallButton;
