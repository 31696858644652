import React, { Fragment } from 'react';
import { showImageLoadError } from 'common/utils/FunctionUtils';
import { useDispatch } from 'react-redux';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { useTranslation } from 'react-i18next';
import { combineString } from 'common/utils/ReportUtils';

const Header = ({ noteDetail }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const {
        user: userDetail = {},
        date,
        job,
        location_name: locationName = '',
        building_name: buildingName = '',
        unit_name: unitName = ''
    } = noteDetail || {};

    const _handleJobDetail = () => {
        job.id && dispatch(actionOpenJobDetail({ id: job.id }));
    };

    return (
        <Fragment>
            <div className="avt-img">
                <img
                    onError={showImageLoadError}
                    alt={userDetail.full_name}
                    src={userDetail.avatar}
                    width={24}
                    height={24}
                />
            </div>
            <div className="name">
                <h5 className="name__label">{userDetail.full_name}</h5>
                <div className="name__des">
                    <p className="info">
                        {date}
                        {!!job && (
                            <>
                                {` ${t('for')} `}
                                <span className="purple-default word-break cursor-pointer" onClick={_handleJobDetail}>
                                    {job.name}
                                </span>
                            </>
                        )}
                    </p>
                </div>
                <div className="name__des">
                    <p className="info">{combineString([locationName, buildingName, unitName])}</p>
                </div>
            </div>
        </Fragment>
    );
};

export default Header;
