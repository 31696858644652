import { SOURCE_PHONE_NUMBER_VOIP, TYPE_MANAGE_ADDONS_VOIP } from 'app/const/addons';
import { GET_USER_ASSSIGNED_NUMBER } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const ServiceManagePhones = ({ onGetListSuccess = () => {}, onGetListFail = () => {} }, ref) => {
    const { t } = useTranslation();

    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    useImperativeHandle(ref, () => ({
        getNumbers: _handleGetListNumber
    }));

    const _handleGetListNumber = () => {
        clientQuery(GET_USER_ASSSIGNED_NUMBER, { method: 'GET' }, _getNumberSuccess, onGetListFail);
    };

    const _getNumberSuccess = (response) => {
        const listPhones = (response.data || []).map((item) => {
            const { type, assignees, source } = item;
            const isGroup = type === TYPE_MANAGE_ADDONS_VOIP.group;
            const isExternal = source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL;

            const finalName = isExternal
                ? t('addons:external_number')
                : isGroup
                ? t('addons:group_number')
                : !!assignees?.length
                ? companyUsers.find((user) => parseInt(user.id) === assignees[0])?.first_name
                : '';

            return { ...item, name: item.name || finalName };
        });

        // eslint-disable-next-line no-undef
        global.isHavePhoneNumber = !!listPhones.length;
        onGetListSuccess(listPhones);
    };

    return null;
};

export default forwardRef(ServiceManagePhones);
