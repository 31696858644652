import React, { useRef, useEffect, useContext, Fragment } from 'react';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import { DISCOUNT_TYPE } from 'app/modules/jobdetail/const/Invoice';
import IconDropDown from 'assets/icon/IconDropDown';
import { roundingNumber, onKeyDownOnlyNumber } from 'common/utils/NumberUtils';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';

import { ESTIMATE_STATUS } from 'app/modules/jobdetail/const/Estimate';

const Deposit = ({ packageId = '' }) => {
    const { invoiceData, updateInvoiceDataContext, onChangeEstimatePackage } = useContext(AddInvoiceContext);

    const refDropdown = useRef(null);
    const refInputVallue = useRef(null);
    const refError = useRef(null);

    const {
        deposit: depositData,
        type: estimateType,
        packages: estimatePackages,
        status: estimateStatus
    } = invoiceData;

    const isEstimatePackage = estimateType === ESTIMATE_TYPE.PACKAGES;

    const currentDeposit = () => {
        if (!packageId || !isEstimatePackage) {
            return depositData;
        }
        return estimatePackages.find((item) => item.id === packageId)?.deposit || {};
    };

    const { type: finalDepositType, value: finalDepositValue, is_paid } = currentDeposit();
    const isBlockDeposit = estimateStatus === ESTIMATE_STATUS.WON || is_paid;

    useEffect(() => {
        if (finalDepositValue !== refInputVallue.current.value) {
            refInputVallue.current.value = finalDepositValue;
        }
    }, [finalDepositValue]);

    function _handleChangeDepositType(e, type) {
        e.stopPropagation();

        if (finalDepositType === type) {
            return false;
        }

        if (!!packageId && isEstimatePackage) {
            onChangeEstimatePackage((prev) => {
                return {
                    ...prev,
                    packages: prev.packages.map((item) => {
                        if (item.id === packageId) {
                            return {
                                ...item,
                                deposit: {
                                    ...item.deposit,
                                    type: type
                                }
                            };
                        }
                        return item;
                    })
                };
            });
        } else {
            updateInvoiceDataContext({
                ...invoiceData,
                deposit: {
                    ...depositData,
                    type: type
                }
            });
        }
        _removeError();
        refDropdown.current._closeDropdown();
    }

    const _removeError = () => {
        refError.current && refError.current.classList.remove('field-error');
    };

    function _handleChangeValue(e) {
        const finalDEposit = roundingNumber(e.target.value);
        refInputVallue.current.value = finalDEposit;
        _removeError();
        if (!!packageId && isEstimatePackage) {
            onChangeEstimatePackage((prev) => {
                return {
                    ...prev,
                    packages: prev.packages.map((item) => {
                        if (item.id === packageId) {
                            return {
                                ...item,
                                deposit: {
                                    ...item.deposit,
                                    value: finalDEposit
                                }
                            };
                        }
                        return item;
                    })
                };
            });
        } else {
            updateInvoiceDataContext({
                ...invoiceData,
                deposit: {
                    ...depositData,
                    value: finalDEposit
                }
            });
        }
    }

    const _handleKeyDown = (e) => {
        const finalKey = e.key;
        const finalValue = onKeyDownOnlyNumber(e) || finalKey === '.';
        !finalValue && e.preventDefault();
    };

    return (
        <div
            ref={refError}
            id={`deposit_cost_${packageId}`}
            className={`txt relative ${isBlockDeposit ? 'is-disable' : ''}`}
        >
            <div className="first-field">
                <CalendarDropdown
                    ref={refDropdown}
                    buttonClassName="dropbtn items"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    wrapperClassname="v2-dropdown"
                    customDropButton={() => (
                        <Fragment>
                            Deposit
                            <span className="text">{finalDepositType === DISCOUNT_TYPE.NUMBER.type ? '$' : '%'}</span>
                            <span className="svg-selectbox">
                                <IconDropDown />
                            </span>
                        </Fragment>
                    )}
                >
                    <ul>
                        <li
                            className={`items ${DISCOUNT_TYPE.PERCENT.type === finalDepositType ? 'active' : ''}`}
                            onClick={(e) => _handleChangeDepositType(e, DISCOUNT_TYPE.PERCENT.type)}
                            tabIndex="0"
                        >
                            {DISCOUNT_TYPE.PERCENT.name}
                        </li>
                        <li
                            className={`items ${DISCOUNT_TYPE.NUMBER.type === finalDepositType ? 'active' : ''}`}
                            onClick={(e) => _handleChangeDepositType(e, DISCOUNT_TYPE.NUMBER.type)}
                            tabIndex="0"
                        >
                            {DISCOUNT_TYPE.NUMBER.name}
                        </li>
                    </ul>
                </CalendarDropdown>
            </div>
            <div className="second-field ">
                <input
                    className="field-input"
                    type="text"
                    defaultValue={finalDepositValue}
                    ref={refInputVallue}
                    onBlur={(e) => {
                        _handleChangeValue(e);
                    }}
                    onKeyDown={_handleKeyDown}
                />
            </div>
        </div>
    );
};

export default Deposit;
