import { call } from 'redux-saga/effects';
import {
    getUrlCustomerJobsHistory,
    CUSTOMER_JOB_WOOK_POOL,
    CUSTOMER_LIST_TASKS,
    CUSTOMER_UPDATE_TASK_STATUS
} from 'app/const/Api';
import { fetchWithToken, putWithToken } from 'common/utils/ApiUtils';

export function* getCustomerJobs({ params, actionSuccess, actionFailed }) {
    const customerId = params.customer_id;
    delete params?.customer_id;

    try {
        const response = yield call(fetchWithToken, getUrlCustomerJobsHistory(customerId), params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionJobWorkPool({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CUSTOMER_JOB_WOOK_POOL, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionGetCustomerListTask({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CUSTOMER_LIST_TASKS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionUpdateTaskStatus({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, CUSTOMER_UPDATE_TASK_STATUS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
