/* eslint-disable no-unused-vars */
import { useRef, useEffect, useReducer } from 'react';
import { io } from 'socket.io-client';
import { SOCKET_EVENTS } from 'app/const/App';
import { WS_ENDPOINT } from 'app/const/URL';
import { reducer } from 'app/const/Reducer';

export const useConnect = (namespace, room, url) => {
    const { CONNECT, CONNECTED, RECONNECTING, CONNECT_ERROR, CONNECT_FAILED } = SOCKET_EVENTS;
    const [state, dispatchState] = useReducer(reducer, {
        response: null,
        socketId: Date.now().toString()
    });

    const socket = useRef();

    useEffect(() => {
        if (parseInt(room) && namespace && url) {
            const options = {
                reconnection: false,
                reconnectionAttempts: 10,
                reconnectionDelay: 5 * 60 * 1000, // reconnect every 5m
                transports: ['websocket', 'polling'], // avoid blocking
                forceNew: true
            };
            socket.current = io(`${WS_ENDPOINT}/${namespace}`, options);
            socket.current.connect();

            socket.current.on(CONNECT, () => {
                socket.current.emit(CONNECTED, parseInt(room));
                if (Array.isArray(url)) {
                    url.forEach((item) => {
                        socket.current.on(item.url, (response) => {
                            dispatchState({
                                response: { [item.socketName]: { data: response } }
                            });
                        });
                    });
                } else {
                    socket.current.on(url, (response) => {
                        dispatchState({ response });
                    });
                }
            });

            // prevent infinite reconnect
            socket.current.on(CONNECT_ERROR, () => {
                socket.current.disconnect();
            });
            socket.current.on(CONNECT_FAILED, () => {
                socket.current.disconnect();
            });

            socket.current.on('disconnect', () => {});

            socket.current.on(RECONNECTING, () => {
                //
            });
        }

        window.addEventListener('beforeunload', disconnectSocket);

        return () => {
            window.removeEventListener('beforeunload', disconnectSocket);
            disconnectSocket();
        };
    }, []);

    const pushEmit = (event, message) => {
        socket.current.emit(event, {
            body: message,
            senderId: socket.current.id
        });
    };

    const disconnectSocket = () => {
        if (socket.current) {
            socket.current.close();
            socket.current.disconnect();
            socket.current.removeAllListeners();
            socket.current.off(url);
            socket.current.off(RECONNECTING);
        }
    };

    const reconnectSocket = () => {
        socket.current.connect();
    };

    const clearResponse = () => {
        dispatchState({ response: null });
    };

    return {
        response: state.response,
        socketId: state.socketId,
        pushEmit,
        disconnectSocket,
        reconnectSocket,
        clearResponse
    };
};
