import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';
import { reducer } from 'app/const/Reducer';
import IconProfile from 'assets/icon/IconProfile';
import { useSmartView } from '../context';
import { LoadingSetLimit } from './header/Loading';
import classNames from 'classnames';
import { formatNumberWithCommas } from 'common/utils/NumberUtils';

const SetLimitSmartView = () => {
    const { count, loading, limit, handleSetLimit } = useSmartView();
    const { t } = useTranslation(['common', 'smartView']);
    const [state, dispatchState] = useReducer(reducer, { isEditing: false });
    const { isEditing } = state;
    const refInput = useRef(null);

    useEffect(() => {
        if (isEditing && loading) dispatchState({ isEditing: false });
    }, [loading]);

    const _handleEdit = (value = true) => {
        dispatchState((prevState) => ({ ...prevState, isEditing: value }));
    };

    const _handleReset = () => {
        handleSetLimit(null, true);
        dispatchState((prevState) => ({ ...prevState, isEditing: false }));
    };

    const _handleSetLimit = (e) => {
        if (e) e.preventDefault();
        handleSetLimit(+refInput.current.value);
        dispatchState((prevState) => ({ ...prevState, isEditing: false }));
    };

    return (
        <>
            {loading ? (
                <LoadingSetLimit />
            ) : (
                <p className="header-label has-icon fs-14 flexcenter gap-4">
                    <IconProfile />
                    {t(`smartView:total_customers_${count <= 1 ? 'singular' : 'plural'}`, {
                        total: formatNumberWithCommas(count)
                    })}
                </p>
            )}

            <div className="btn-action flexcenter dots">
                {isEditing ? (
                    <form onSubmit={_handleSetLimit} className="btn-limit --done flexcenter gap-4 fw-500 fs-13">
                        <GDInputNumber
                            ref={refInput}
                            placeholder="1000"
                            className="field-input fs-14"
                            maxLength={8}
                            defaultValue={limit || ''}
                            exceptZero
                            autoFocus
                        />
                        <div className="flexcenter">
                            <div className="btn-limit__option --left" onClick={_handleSetLimit}>
                                {t('done')}
                            </div>
                            <div className="btn-limit__option --right" onClick={() => _handleEdit(false)}>
                                {t('cancel')}
                            </div>
                        </div>
                    </form>
                ) : !limit ? (
                    <div
                        className={classNames('v2-btn-default --sm btn-bg-purple', { 'is-disable': loading })}
                        onClick={_handleEdit}
                    >
                        {t('smartView:set_limit')}
                    </div>
                ) : null}

                {limit && !isEditing ? (
                    <div
                        className={classNames('btn-limit --edit flexcenter gap-4 fw-500 fs-13', {
                            'is-disable': loading
                        })}
                    >
                        <p className="txt-ellipsis fw-400 fs-14 mr-1">Limited to {limit}</p>
                        <div className="flexcenter">
                            <div className="btn-limit__option --left" onClick={_handleEdit}>
                                {t('edit')}
                            </div>
                            <div className="btn-limit__option --right" onClick={_handleReset}>
                                {t('reset')}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default SetLimitSmartView;
