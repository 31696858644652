import React from 'react';

const ZapierAddonsLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --onefifth" />
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="has-form form-zaipier">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="d-flex justify-space-between">
                        <div className="password-input v2-btn-default --grey loading btn-sm" />
                        <div className="v2-btn-default --grey loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default --grey loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ZapierAddonsLoading;
