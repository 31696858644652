import React from 'react';
import RenderAttachment from 'app/modules/customer/components/RenderAttachment';

const Content = ({ noteDetail, onHandleDeleteAttachment = () => {}, noteId }) => {
    return (
        <div className="wrap-content-box">
            <div className="description" dangerouslySetInnerHTML={{ __html: noteDetail.content }} />
            <div className="attachment-image scrolls-x">
                <RenderAttachment
                    attachments={noteDetail.attachments}
                    onDeleteAttachment={onHandleDeleteAttachment}
                    noteId={noteId}
                />
            </div>
        </div>
    );
};

export default Content;
