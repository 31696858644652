import { getGridColumnsDetailTab, getGridColumnsMonthTab } from 'app/const/report/RevenueByTags';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import loadable from '@loadable/component';
import { getParamsRevenuePage } from 'common/utils/ReportUtils';
import { REPORT_REVENUE_BY_TAGS_DETAIL_GET_LIST } from 'app/const/Api';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const ReportRevenuePopupDetail = loadable(() => import('app/modules/report/components/ReportRevenuePopupDetail'));

function MonthTabGridView({
    dataReport,
    paramsReport,
    handleActionHeader = () => {},
    handleOpenInvoice,
    monthTab,
    isLoadmore = false,
    onScrollToEnd = () => {}
}) {
    const refPopup = useRef(null);
    const getColumnsMonthTab = getGridColumnsMonthTab(paramsReport?.orderMonth || null);

    const handleClickTable = ({ key, row }) => {
        refPopup.current.openPopup({
            ...getParamsRevenuePage({ key, paramsReport }),
            tag_id: row.tag_id,
            tag_type: paramsReport.tag_type
        });
    };

    return (
        <div className="tab-conts tab-content-active">
            <GdGridView
                isEmptyFlat
                isLoading={dataReport.isLoading}
                classTable="--revenue table-multi-column scrolls-x has-text-ellipsis"
                classTableContent="--hastotal"
                content={dataReport?.data}
                fileTranslation={'report'}
                handleClick={(event) => {
                    handleClickTable(event);
                }}
                handleClickHeader={handleActionHeader}
                {...getColumnsMonthTab}
                rowTotal={(props) => (
                    <GdGridRowTotal
                        columns={dataReport?.rowTotal}
                        contentConfig={getColumnsMonthTab?.contentConfig}
                        showCheckBox={false}
                        isLoading={dataReport.isLoading}
                        {...props}
                    />
                )}
                isScroll
                isLoadmore={isLoadmore}
                onScrollToEnd={onScrollToEnd}
                keyGetId="tag_id"
            />

            <ReportRevenuePopupDetail
                ref={refPopup}
                endpointAPI={REPORT_REVENUE_BY_TAGS_DETAIL_GET_LIST}
                girdColumn={getGridColumnsDetailTab}
                keyTotalRow={'total_payments_received'}
                handleOpenInvoice={handleOpenInvoice}
                monthTab={monthTab}
            />
        </div>
    );
}

MonthTabGridView.propTypes = {
    dataReport: PropTypes.object
};

function handleExportMonthTab() {
    return false;
}

export { MonthTabGridView, handleExportMonthTab };
