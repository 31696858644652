import React, { useReducer, useImperativeHandle, forwardRef } from 'react';
import MessageConfig from 'app/modules/calendar/job/components/messaging/MessageConfig';
import { updateLocationCustomerMessagingPreferences } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';

const MessagePreferences = forwardRef(({ location_ids, handleSaveMassageSuccess }, ref) => {
    const activateSms = useSelector(({ auth }) => auth.user?.settings?.addons?.activate_sms || false);
    const defaultCheckPreference = activateSms ? 3 : 2;
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isModalLocation: true,
        message: {
            appointment_confirmations: defaultCheckPreference,
            appointment_followups: defaultCheckPreference,
            appointment_reminders: defaultCheckPreference,
            broadcasts: defaultCheckPreference,
            late_payment_reminders: defaultCheckPreference
        }
    });

    const { id } = useParams();

    useImperativeHandle(ref, () => ({
        _openModalSetting,
        _closeModalSetting
    }));

    const finalIsVisible = state.isVisible;

    function _openModalSetting() {
        dispatchState({ isVisible: true });
    }

    function _closeModalSetting() {
        dispatchState({ isVisible: false });
    }

    function _handleSave(confirmations, reminders, followups, broadcasts, payment) {
        const params = {
            appointment_confirmations: confirmations,
            appointment_reminders: reminders,
            appointment_followups: followups,
            broadcasts: broadcasts,
            late_payment_reminders: payment
        };

        const optionsQuery = {
            method: 'PUT',
            data: { ...params, location_ids }
        };

        clientQuery(updateLocationCustomerMessagingPreferences(id), optionsQuery, () => _updateSuccess(params));
    }

    function _updateSuccess(params) {
        dispatchState({ message: params, isVisible: false });
        handleSaveMassageSuccess();
    }

    return (
        finalIsVisible && (
            <MessageConfig
                isModalLocation={state.isModalLocation}
                message={state.message}
                onSave={_handleSave}
                onClose={_closeModalSetting}
            />
        )
    );
});

export default MessagePreferences;
