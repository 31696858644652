import { generateDataSet, optionsDefault } from 'app/components/chart/line/chart';
import { LABEL_CHART_THE_MONTHS_OF_YEAR } from 'app/const/App';

export const configRevenuePerMonth = ({ currency, data, onToggleLegendChart = () => {} }) => {
    return {
        data: {
            labels: LABEL_CHART_THE_MONTHS_OF_YEAR,
            datasets: generateDataSet({
                datasets: data,
                isFillColor: true,
                isShowLabel: true
            })
        },
        options: optionsDefault({ isShowLabel: true, currency, onToggleLegendChart })
    };
};
