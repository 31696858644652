import React, { useContext } from 'react';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import IconHashtag from 'assets/icon/IconHashtag';

const Balance = ({ currentBalance = '' }) => {
    const { addPayment } = useContext(AddPaymentContext);

    return (
        <div className="sm-row">
            <div className="txt">
                <IconHashtag />
                Current Balance
            </div>
            <div className="detail">{addPayment?.balanceDue?.format || currentBalance}</div>
        </div>
    );
};

export default Balance;
