import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import {
    START_TRACKING_EVENT,
    STOP_TRACKING_EVENT,
    TRACKING_EVENTS,
    TRACKING_EVENTS_DETAIL_PAGE,
    TRACKING_OFFLINE,
    TRACKING_ONLINE
} from '../../constant';
import { getTracking, reducerTrackingTime } from '../../utils';
import TimerCounter from '../TimerCounter';

const TimerContent = () => {
    const { t } = useTranslation();

    const [state, dispatchState] = useReducer(reducerTrackingTime, {
        isDisabled: false,
        isCounting: false
    });

    const { isCounting, isDisabled } = state;
    const refCounter = useRef(null);
    const refButtonSave = useRef(null);

    const _handleStart = () => {
        refCounter.current?.start();
        refButtonSave.current?.removeLoading();
        dispatchState({ type: TRACKING_EVENTS.START });
    };
    const _handleStop = () => {
        refCounter.current?.pause();
        refButtonSave.current?.removeLoading();
        dispatchState({ type: TRACKING_EVENTS.STOP });
    };

    const _handleSettedTrackingId = () => {
        dispatchState({ type: TRACKING_EVENTS.SETTED_TRACKING_ID });
    };

    const _handleSettingTrackingId = () => {
        dispatchState({ type: TRACKING_EVENTS.SETTING_TRACKING_ID });
    };

    useLayoutEffect(() => {
        addEventListener(START_TRACKING_EVENT, _handleStart);
        addEventListener(STOP_TRACKING_EVENT, _handleStop);
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.SETTED_TRACKING_ID, _handleSettedTrackingId);
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.SETTING_TRACKING_ID, _handleSettingTrackingId);
        return () => {
            removeEventListener(START_TRACKING_EVENT, _handleStart);
            removeEventListener(STOP_TRACKING_EVENT, _handleStop);
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.SETTED_TRACKING_ID, _handleSettedTrackingId);
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.SETTING_TRACKING_ID, _handleSettingTrackingId);
        };
    }, []);

    useLayoutEffect(() => {
        const _handleOnline = () => {
            dispatchState({ type: TRACKING_ONLINE });
        };
        const _handleOffline = () => {
            dispatchState({ type: TRACKING_OFFLINE });
        };

        addEventListener('online', _handleOnline);
        addEventListener('offline', _handleOffline);
        return () => {
            removeEventListener('online', _handleOnline);
            removeEventListener('offline', _handleOffline);
        };
    }, []);

    useEffect(() => {
        const { id } = getTracking();
        if (!!id) {
            refCounter.current?.start();
            refButtonSave.current?.removeLoading();
            dispatchState({ type: TRACKING_EVENTS.START_WITH_ID_DETAIL });
        }
    }, []);

    const _handleClick = () => {
        if (isCounting) {
            refButtonSave.current?.removeLoading();
            dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.CONFIRM_STOP_TRACKING));
        } else {
            refButtonSave.current?.removeLoading();
            _handleStart();
            dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.START));
        }
    };

    return (
        <>
            <TimerCounter ref={refCounter} />
            <ButtonSave
                ref={refButtonSave}
                disabled={isDisabled || !navigator.onLine}
                wrapClass="v2-btn-main flex-1 justify-center"
                onSave={_handleClick}
            >
                {isCounting ? t('stop') : t('start')}
            </ButtonSave>
        </>
    );
};

export default TimerContent;
