import { NetworkStatus, useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ACCOUNT_ROLE, ERROR_CODE, PREFIX_TITLE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { INBOX } from 'app/const/Route';
import { TYPE_FEATURE_SETTING } from 'app/modules/inbox/const';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { deepCloneObject } from 'common/utils/FunctionUtils';
import {
    convertToColumnsWithValues,
    convertToFiltersWithValues,
    convertToSortWithValues
} from '../components/utils/operator';
import { getVariablesQuery } from '../components/utils/query';
import { LIMIT_SMART_VIEW_DATA, SMART_VIEW_ACTIONS } from '../constants';
import { OBJECT_TYPES, SORT_TYPES } from '../constants/types';
import { SEARCH_CUSTOMERS_QUERY } from '../graphql/query/filters';
import { DEFAULT_COLUMN_NAME } from '../components/table/utils';

const SmartViewContext = React.createContext({});
export const SmartViewProvider = ({
    initialData = {},
    name = '',
    isLoading = true,
    filtered: propsFiltered,
    sort: propsSort,
    columns: propsColumns,
    limit: propsLimit,
    children
}) => {
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const customJobStatusAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_job_status);
    const customFields = useSelector(({ customFields }) => customFields.data);
    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const allows = [];
    if (customFieldAddon) allows.push(OBJECT_TYPES.CUSTOM_FIELD);
    if (customJobStatusAddon) allows.push(OBJECT_TYPES.CUSTOM_JOB_STATUSES);

    const dispatch = useDispatch();
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const { state: stateLocation } = useLocation();
    const history = useHistory();

    const _handleErrorPermissionSmartView = ({ error = {} }, callbackSuccess = () => {}) => {
        const haveError = Object.keys(error).length > 0;

        if (!haveError) callbackSuccess();
        if (haveError) {
            let isErrorPermission = false;
            error?.graphQLErrors?.forEach(({ extensions }) => {
                if (extensions?.statusCode === ERROR_CODE.PERMISSION_DENIED) {
                    isErrorPermission = true;
                    return;
                }
            });

            if (isErrorPermission) {
                dispatch(updateAddonStatus({ keyword: TYPE_FEATURE_SETTING.SMART_VIEWS, data: 0 }));
                if (history.location.pathname.includes(INBOX)) history.replace(addBranchPath(INBOX));
            }
        }
    };

    const [loadData, { loading, data, networkStatus, fetchMore }] = useLazyQuery(SEARCH_CUSTOMERS_QUERY, {
        notifyOnNetworkStatusChange: true,
        errorPolicy: 'all',
        onError: (error) => _handleErrorPermissionSmartView({ error })
    });

    const isFetchingMore = networkStatus === NetworkStatus.fetchMore;
    const customersData = data?.searchCustomers?.data || [];
    const dataLength = customersData.length || 0;
    const count = data?.searchCustomers?.pagination?.total || 0;

    const [state, dispatchState] = useReducer(reducer, {
        ...initialData,
        isSupperAdmin,
        isColumnsChanged: false,
        name,
        snapshot: [],
        filterChanged: !!propsFiltered?.length,
        filtered: propsFiltered || [],
        sort: propsSort || [],
        columns: propsColumns || []
    });

    const refFilters = useRef([]);
    const refFirstLoad = useRef(true);

    useEffect(() => {
        // First time load data, when initial data is ready
        if ((!isLoading && refFirstLoad.current) || !!stateLocation?.hardReload) {
            const propsData = { sort: propsSort, limit: propsLimit, columns: propsColumns };

            _handleLoadData({ ...propsData, filters: propsFiltered }, true);
            dispatchState((prevState) => {
                return {
                    ...prevState,
                    name,
                    filtered: propsFiltered,
                    isHaveFilter: propsFiltered.length > 0,
                    ...propsData
                };
            });
            refFilters.current = deepCloneObject(propsFiltered);
            refFirstLoad.current = false;
        }
    }, [isLoading, stateLocation]);

    useEffect(() => {
        if (stateLocation?.definition) {
            const { definition, name } = stateLocation;
            document.title = `${PREFIX_TITLE} - ${name}`;

            const sort = convertToSortWithValues(definition.sort || []);
            const filters = convertToFiltersWithValues(definition.query, { allows })[0]?.filters || [];
            const columns = convertToColumnsWithValues(definition.columns, { allows });
            const limit = definition.limit;
            const deepCloneFilters = deepCloneObject(filters);

            _handleLoadData({ filters, sort, limit, columns }, true);
            dispatchState((prevState) => {
                return {
                    ...prevState,
                    name,
                    filtered: deepCloneFilters,
                    isHaveFilter: !!deepCloneFilters.length,
                    sort,
                    limit,
                    columns,
                    filterChanged: false,
                    isColumnsChanged: false
                };
            });
            refFilters.current = deepCloneFilters;
        }
    }, [stateLocation]);

    const _handleLoadData = ({ filters, sort, limit, columns }, hardReload = false, callbackSuccess = () => {}) => {
        const variables = getVariablesQuery({
            filters,
            sort: sort || state.sort,
            limit: limit || state.limit || LIMIT_SMART_VIEW_DATA,
            columns: columns || state.columns || DEFAULT_COLUMN_NAME,
            hardReload,
            customFields
        });

        loadData({ variables, fetchPolicy: 'network-only' }).then((response) =>
            _handleErrorPermissionSmartView(response, callbackSuccess)
        );
    };

    const handleRefetch = ({ filters, sort, limit, columns }, callbackSuccess = () => {}) => {
        const dataLoad = {
            filters: filters || refFilters.current,
            sort: sort || state.sort,
            limit: limit || state.limit || LIMIT_SMART_VIEW_DATA,
            columns: columns || state.columns
        };
        _handleLoadData(dataLoad, false, callbackSuccess);
        if (filters) refFilters.current = deepCloneObject(filters);
    };

    const handleUpdateFilters = (filtered) => {
        dispatchState((prevState) => ({ ...prevState, filtered: [...prevState.filtered, ...filtered] }));
    };

    const handleUpdateFiltered = (data) => {
        dispatchState((prevState) => {
            const newFiltered = [...prevState.filtered];
            newFiltered[data.indexFilter] = { ...newFiltered[data.indexFilter], ...data.filter };
            _handleLoadData({ filters: newFiltered });
            return { ...prevState, filtered: newFiltered, filterChanged: !!newFiltered.length };
        });
    };

    const handleRemoveFiltered = (indexFilter) => {
        dispatchState((prevState) => {
            const newFiltered = [...prevState.filtered];
            newFiltered.splice(indexFilter, 1);
            refFilters.current = deepCloneObject(newFiltered);
            _handleLoadData({ filters: newFiltered });
            return { ...prevState, filtered: newFiltered, filterChanged: !!newFiltered.length };
        });
    };

    const handleUpdateSnapshot = (filters, shouldLoad = false) => {
        if (shouldLoad)
            _handleLoadData({ filters: [...refFilters.current, ...filters], sort: state.sort, limit: state.limit });
        dispatchState((prevState) => {
            return {
                ...prevState,
                filtered: [...refFilters.current, ...filters],
                snapshot: filters,
                filterChanged: true
            };
        });
    };

    const handleUpdateSort = ({ type, payload = {} }) => {
        const { objectType, fieldId, direction } = payload;
        if (!fieldId) throw new Error('FieldId is required');
        switch (type) {
            case SMART_VIEW_ACTIONS.SORT_SET_SINGLE:
                dispatchState((prevState) => {
                    const sort = [{ objectType, fieldId, direction: direction || SORT_TYPES.ASC }];
                    handleRefetch({ sort });
                    return { ...prevState, sort, filterChanged: true };
                });
                break;
            default:
                throw new Error('Invalid action type on handleUpdateSort function');
        }
    };

    const handleSetLimit = (limit, isReset = false) => {
        if (limit === state.limit) return;
        handleRefetch({ limit: isReset ? LIMIT_SMART_VIEW_DATA : limit });
        dispatchState((prevState) => ({ ...prevState, limit, filterChanged: true }));
    };

    const handleFetchMore = () => {
        if (loading || isFetchingMore || dataLength >= count || (state.limit && dataLength >= state.limit)) return;
        fetchMore({
            variables: { offset: dataLength, limit: LIMIT_SMART_VIEW_DATA },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousResult;
                return Object.assign({}, previousResult, {
                    searchCustomers: {
                        ...(previousResult?.searchCustomers || {}),
                        data: [
                            ...(previousResult?.searchCustomers?.data || []),
                            ...(fetchMoreResult?.searchCustomers?.data || [])
                        ]
                    }
                });
            }
        });
    };

    const handleStoreFiltered = () => {
        refFilters.current = JSON.parse(JSON.stringify(state.filtered));
        dispatchState((prevState) => ({ ...prevState, snapshot: [] }));
    };

    const handleSaveMutationSuccess = (data) => {
        const filtered =
            convertToFiltersWithValues(data.updateSmartView.definition.query, { allows })[0]?.filters || [];
        refFilters.current = deepCloneObject(filtered);
        dispatchState((prevState) => ({
            ...prevState,
            isHaveFilter: filtered.length > 0,
            filtered,
            filterChanged: false,
            isColumnsChanged: false
        }));
    };

    const handleCancelFilter = () => {
        const filters = deepCloneObject(refFilters.current);
        _handleLoadData({ filters, sort: state.sort, limit: state.limit });
        dispatchState((prevState) => ({ ...prevState, filtered: filters }));
    };

    const handleApplyColumns = (columns) => {
        handleRefetch({ columns });
        dispatchState((prevState) => ({ ...prevState, isColumnsChanged: true, columns }));
    };

    const dataProvider = {
        data: customersData || [],
        loading: loading && !isFetchingMore,
        isLoadingMore: isFetchingMore,
        count,
        // Above is data from server with useLazyQuery
        ...state,
        handleFetchMore,
        handleUpdateSort,
        handleRefetch,
        handleUpdateFilters,
        handleUpdateFiltered,
        handleRemoveFiltered,
        handleUpdateSnapshot,
        handleSetLimit,
        handleSaveMutationSuccess,
        handleStoreFiltered,
        handleCancelFilter,
        handleApplyColumns
    };
    return <SmartViewContext.Provider value={dataProvider}>{children}</SmartViewContext.Provider>;
};

export const useSmartView = () => useContext(SmartViewContext);
