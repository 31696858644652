import { addBranchPath } from 'app/const/Branch';
import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

const MenuWithLink = ({ name, itemClassName, path, onSetRef = () => {}, icon = null }) => {
    return (
        <div ref={(el) => onSetRef(el)} className="wrap-nav nav-menu">
            <NavLink to={addBranchPath(path)} className={classNames('v2-btn-default nav mr-1', itemClassName)}>
                {icon || null}
                <p className="nav__label is-nav-items">{name}</p>
            </NavLink>
        </div>
    );
};

export default MenuWithLink;
