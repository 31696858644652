import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-loading wrap-container__form flex-column wrap-document-edit">
            <div className="wrap-header bg-white pb-4">
                <div className="loading --animation --line --half my-3" />
                <div className="loading --animation --line --full mt-1" />
                <div className="loading --animation --line --full mt-1" />
                <div className="loading --animation --line --threefourth mt-1" />
            </div>
            <div className="wrap-body">
                <div className="box-use-variable border-bottom-black-extradark">
                    <div className="box-header flex-betweenitems mb-1">
                        <div className="flexcenter flex-1">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm ml-1">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                    <div className="box-subject mb-3">
                        <div className="loading --animation --line --half my-2" />
                        <div className="loading v2-btn-default --grey w-100" />
                    </div>
                    <div className="wrap-content">
                        <div className="loading --animation --line --twothird my-2" />
                        <div className="loading v2-btn-default --grey w-100 flex-column align-top pt-4 pb-16">
                            <div className="loading --animation --line --twothird" />
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                        </div>
                    </div>
                </div>
                <div className="box-use-variable">
                    <div className="box-header flex-betweenitems mb-1">
                        <div className="flexcenter flex-1">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                    <div className="wrap-content">
                        <div className="loading --animation --line --twothird my-2" />
                        <div className="loading v2-btn-default --grey w-100 flex-column align-top pt-4 pb-16">
                            <div className="loading --animation --line --twothird" />
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-footer">
                <div className="v2-btn-default loading --grey btn-x-sm">
                    <div className="loading --animation --line --full"></div>
                </div>
                <div className="v2-btn-default loading --grey btn-x-sm ml-2">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
