import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import { getCommissionUsers } from 'common/redux/actions/commission';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const CommissionDropdown = ({ selected = [], onSelect = () => {}, onUnselect = () => {} }) => {
    const dispatch = useDispatch();
    const refDropdown = useRef(null);
    const { isFirstTimeUsers, users } = useSelector(({ commissionReducer }) => commissionReducer);

    const _handleVisible = () => {
        isFirstTimeUsers && dispatch(getCommissionUsers());
    };

    return (
        <>
            <ListUserSelected data={selected} onUnselect={onUnselect} />
            <CalendarDropdown
                ref={refDropdown}
                isLoading={isFirstTimeUsers}
                id="select-commission"
                wrapperClassName="dropdown-listname"
                buttonClassName="dropbtn v2-btn-default --icon-lg"
                customDropButton={() => <IconPlus />}
                onVisible={_handleVisible}
            >
                <ListUser data={users} selected={selected} onSelect={onSelect} />
            </CalendarDropdown>
        </>
    );
};

const ListUserSelected = ({ data = [], onUnselect = () => {} }) => {
    if (!data.length) return null;

    const _handleDelete = (userId) => {
        onUnselect(userId);
    };

    return (
        <>
            <div className="schedule-user">
                {data.map((item) => (
                    <div key={item.id} className="tech-name">
                        <div className="avt-img">
                            <img src={item.avatar} width={24} height={24} />
                        </div>
                        <span className="txt-ellipsis">{item.full_name}</span>
                        <div className="close" onClick={() => _handleDelete(item.id)}>
                            <IconCircleClose />
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const ListUser = ({ data = [], selected = [], onSelect = () => {} }) => {
    const _handleSelect = (userInfo) => {
        onSelect(userInfo);
    };

    return (
        <ul>
            {data.map((item) => {
                return (
                    <li
                        key={item.id}
                        className={classNames('items', { active: selected.some((el) => el.id === item.id) })}
                        onClick={() => _handleSelect(item)}
                        tabIndex="0"
                    >
                        <div className="name">
                            <span className="avt-img">
                                <img src={item.avatar} width={24} height={24} />
                            </span>
                            <span className="text-name">{item.full_name}</span>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default CommissionDropdown;
