export const NOTIFICATION_TYPES = {
    E_SIGNED_INVOICE: 'e_signed_invoice',
    E_SIGNED_ESTIMATE: 'e_signed_estimate',
    E_SIGNED_DOCUMENT: 'e_signed_document',
    SUBSCRIPTION: 'subscription',
    SUBSCRIPTION_FAILED: 'subscription_failed',
    SUBSCRIPTION_CANCEL: 'subscription_cancel',
    RECURRING_FAILED: 'recurring_failed',
    NEW_E_SIGNED_ESTIMATE: 'new_e_signed_estimate',
    INBOUND_LEADS: 'inbound_leads',
    COMMENT: 'comment',
    NOTE: 'note',
    JOB_CONFIRMATION: 'job_confirmation',
    JOB_RESCHEDULE: 'job_reschedule',
    ONLINE_BOOKING: 'online_booking',
    ONLINE_PAYMENT: 'online_payment',
    ACH_VERIFY: 'ach_verify',
    REVIEWS_LIKELY: 'reviews_likely',
    REVIEWS_SERVICE: 'reviews_service',
    PAYMENT: 'payment',
    ACH_INVITATION: 'ach_invitation',
    SMS_FAILED: 'sms_failed',
    MISSED_CALL: 'missed_call',
    JOB: 'job'
};
