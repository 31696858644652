import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumnsSettingSchedules = () => {
    const header = {
        name: {
            title: 'name',
            style: 'col col-sm pl-0'
        },
        nickname: {
            title: 'nickname',
            style: 'col col-lg'
        },
        assignee: {
            title: 'assignee_to',
            style: 'col --assign pl-2'
        },
        total_active_jobs: {
            title: 'jobs_active',
            style: 'col col-sm'
        },
        start_point: {
            title: 'start_end_address',
            style: 'col --address pr-2'
        },
        groups: {
            title: 'group',
            style: 'col --group'
        }
    };
    const columns = [
        {
            id: 'name',
            title: 'name',
            status: true
        },
        {
            id: 'nickname',
            title: 'nickname',
            status: true
        },
        {
            id: 'assignee',
            title: 'assignee_to',
            status: true
        },
        {
            id: 'total_active_jobs',
            title: 'jobs_active',
            status: true
        },
        {
            id: 'start_point',
            title: 'start_point',
            status: true
        },
        {
            id: 'groups',
            title: 'group',
            status: true
        }
    ];
    const contentConfig = {
        name: {
            title: 'name',
            style: 'col col-sm'
        },
        nickname: {
            title: 'nickname',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.SCHEDULES_NICKNAME
        },
        assignee: {
            title: 'assignee_to',
            style: 'col --assign pl-2',
            type: LIST_TABLE_ITEM_TYPE.SCHEDULES_ASSIGNEE
        },
        total_active_jobs: {
            title: 'jobs_active',
            style: 'col col-sm'
        },
        start_point: {
            title: 'start_end_address',
            style: 'col --address pr-2',
            type: LIST_TABLE_ITEM_TYPE.SCHEDULES_ADDRESS
        },
        groups: {
            title: 'group',
            style: 'col --group',
            type: LIST_TABLE_ITEM_TYPE.GROUPS
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
