import React from 'react';

const IconBillingAddress = () => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 10H12H15C15.5523 10 16 10.4477 16 11V15C16 15.5523 15.5523 16 15 16H9C8.44772 16 8 15.5523 8 15V11C8 10.4477 8.44772 10 9 10ZM12 12C13.1046 12 14 12.8954 14 14V16H10V14C10 12.8954 10.8954 12 12 12ZM18 10C18.5523 10 19 10.4477 19 11V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V11C17 10.4477 17.4477 10 18 10ZM6 10C6.55228 10 7 10.4477 7 11V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V11C5 10.4477 5.44772 10 6 10Z"
            fill="#C1C9D4"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.47 4.152C11.7583 3.97185 12.1154 3.95183 12.4189 4.09195L12.53 4.152L19.53 7.152C19.9983 7.44471 20.1407 8.06166 19.848 8.53C19.5762 8.96488 19.0248 9.11871 18.5725 8.9041L18.47 8.848H5.53001C5.09513 9.1198 4.5321 9.01645 4.21953 8.62533L4.15201 8.53C3.88021 8.09511 3.98356 7.53209 4.37468 7.21952L4.47001 7.152L11.47 4.152ZM5.00001 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5.00001C4.44773 19 4.00001 18.5523 4.00001 18C4.00001 17.4477 4.44773 17 5.00001 17Z"
            fill="#7A83A6"
        />
    </svg>
);

export default IconBillingAddress;
