import React from 'react';

const GDButtonTooltip = ({ className = '', tooltips = [], children, ...props }) => {
    return (
        <div className={className} {...props}>
            {children} {tooltips ? <ListTooltip dataTooltip={tooltips} {...props} /> : null}
        </div>
    );
};

const ListTooltip = ({ dataTooltip, tooltipClassName = 'tooltiptext' }) => {
    if (Array.isArray(dataTooltip))
        return dataTooltip.map((item, index) => (
            <p key={index} className={item.className}>
                {item.text}
            </p>
        ));

    if (typeof dataTooltip === 'string') return <p className={tooltipClassName}>{dataTooltip}</p>;
    return null;
};

export default GDButtonTooltip;
