import React from 'react';

const LoadingModalVerify = () => {
    return (
        <div className="modal__container wrap-loading ">
            <div className="header-modal">
                <div className="loading --animation --line --half" />
                <div className="v2-btn-default --icon-lg loading bg-white-cultured" />
            </div>
            <div className="body-modal">
                <div className="flex-column gap-6 rows">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default loading --grey w-100" />
                </div>
            </div>
            <div className="footer-modal">
                <div className="v2-btn-default loading --transparent btn-x-sm">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --grey btn-x-sm ml-2">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
};

export default LoadingModalVerify;
