import React from 'react';

const LoadingManageDocument = () => {
    return (
        <div className="wrap-loading --addon list__document">
            <div className="list__document-upload flex-column gap-10">
                <div className="v2-btn-default --grey loading justify-center w-100">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="v2-btn-default --grey loading justify-center w-100">
                    <div className="loading --animation --line --half" />
                </div>
            </div>
            <div className="list__document-upload py-3">
                <div className="loading --animation --line --twothird" />
            </div>
            <div className="flex-1">
                <div className="rows">
                    <div className="rows__icon">
                        <div className="check-items">
                            <div className="checkbox" />
                        </div>
                    </div>
                    <div className="rows__name">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__action">
                        <div className="v2-btn-default --transparent --icon-sm loading" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__icon">
                        <div className="check-items">
                            <div className="checkbox" />
                        </div>
                    </div>
                    <div className="rows__name">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__action">
                        <div className="v2-btn-default --transparent --icon-sm loading" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__icon">
                        <div className="check-items">
                            <div className="checkbox" />
                        </div>
                    </div>
                    <div className="rows__name">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="rows__action">
                        <div className="v2-btn-default --transparent --icon-sm loading" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingManageDocument;
