import React, { useReducer, useRef, useEffect, useId } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/App';
import { LIST_ACTION_HEADER_IN_DETAIL_INVOICE } from 'app/modules/jobdetail/const';
import SendByEmail from 'app/modules/jobdetail/tabs/invoice/actions/SendByEmail';
import SendBySms from 'app/modules/jobdetail/tabs/invoice/actions/SendBySms';
import IconEmail from 'assets/icon/IconEmail';
import IconSentSms from 'assets/icon/IconSentSms';
import IconSent from 'assets/icon/IconSent';
import { clientQuery } from 'common/utils/ApiUtils';
import { jobEmailSend, jobListSms, jobTemplateEmail, jobSmsSend } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import IconThreeDots from 'assets/icon/IconThreeDots';

const Options = ({
    customerId,
    jobId,
    messaging,
    contacts: { email: emailContact = [], phones: smsContact = [] },
    customerPhones
}) => {
    const { t } = useTranslation(['common']);
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || false);
    const { email, name } = useSelector(({ auth }) => auth.user.company);

    const isVisible = state.isVisible;
    const refDowpdown = useRef(null);
    const refSendByEmail = useRef(null);
    const refSendBySms = useRef(null);
    const refSendByEmailAndSms = useRef(null);
    const dropdownId = useId();
    const finalEmails = (messaging.email_to || [])
        .map((item) => {
            return {
                email: item.email
            };
        })
        .concat(emailContact);

    const finalSms = (messaging.sms_to || [])
        .map((item) => {
            return {
                ...item,
                value: item.phone,
                name: `${item.name} ${item.phone}`
            };
        })
        .concat([...customerPhones, ...smsContact]);

    const uniquePhoneNumbersArray = [];

    finalSms.forEach((contact) => {
        if (!uniquePhoneNumbersArray.some((item) => item.id.toString() === contact.id.toString())) {
            uniquePhoneNumbersArray.push(contact);
        }
    });

    const objectEmail = {
        listEmail: finalEmails,
        to: [finalEmails?.[0]?.email],
        message: `<br/><br/><br/><br/><br/>${name}<br/>${email}`,
        subject: `${name} Reschedule Request`
    };

    const objectSms = {
        listSms: uniquePhoneNumbersArray,
        smsAddress: uniquePhoneNumbersArray?.[0]?.value || '',
        smsContent: `\n\n\n\n${name}\n${email}`,
        smsLabel: uniquePhoneNumbersArray?.[0]?.name || ''
    };

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(dropdownId);

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(dropdownId);
        if (
            refDowpdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDowpdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isVisible && setState({ isVisible: false });
    };

    const _handleOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setState({ isVisible: !isVisible });
    };

    const _handleOnClick = (e, value) => {
        e && e.stopPropagation();
        switch (value) {
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL:
                refSendByEmail.current._handleOpenForm();
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_SMS:
                refSendBySms.current._handleOpenForm();
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL_AND_SMS:
                refSendByEmailAndSms.current._handleOpenForm();
                break;

            default:
                break;
        }
        _closeDropdown();
    };

    function _handleSendEmail(params, callback) {
        const optionQuery = {
            method: 'POST',
            toFormData: false,
            data: {
                job_id: jobId,
                ...params,
                send_type: 1
            }
        };

        clientQuery(
            jobEmailSend,
            optionQuery,
            (response) => _handleSendEmailSuccess(response, callback),
            (response) => _handleSendEmailFail(response, callback)
        );
    }

    function _handleSendEmailSuccess(response, callback) {
        if (typeof callback === 'function') {
            callback(true, response.message.toString() || 'Your email has been sent.');
        }
    }

    function _handleSendEmailFail(response, callback) {
        if (typeof callback === 'function') {
            callback(false, response.message.toString());
        }
    }

    function _handleSendSms(params, callback) {
        const optionQuery = {
            method: 'POST',
            toFormData: false,
            data: {
                job_id: jobId,
                customer_id: customerId,
                ...params
            }
        };
        clientQuery(
            jobSmsSend,
            optionQuery,
            (response) => _handleSendSMSSuccess(response, callback),
            (response) => _handleSendSMSFail(response, callback)
        );
    }

    function _handleSendSMSSuccess(response, callback) {
        if (typeof callback === 'function') {
            callback(true, response.message.toString() || 'Your sms has been sent.');
        }
    }

    function _handleSendSMSFail(response, callback) {
        if (typeof callback === 'function') {
            callback(false, response.message.toString());
        }
    }

    return (
        <>
            <div ref={refDowpdown} className={`v2-dropdown ${isVisible && 'active'}`}>
                <div onClick={_handleOpen} className="dropbtn v2-btn-default">
                    <IconSent />
                    <span className="ml-1">Reply</span>
                    <IconThreeDots />
                </div>
                <div id={dropdownId} className="v2-dropdown__menu content-full --right">
                    <ul>
                        <li
                            onClick={(e) => {
                                _handleOnClick(e, LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL);
                            }}
                            className="items has-icon btn-modal"
                        >
                            <IconEmail />
                            <span className="txt-ellipsis">{t('common:send_by_email')}</span>
                        </li>

                        {activateSms && (
                            <li
                                onClick={(e) => {
                                    _handleOnClick(e, LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_SMS);
                                }}
                                className="items has-icon btn-modal"
                            >
                                <IconSentSms />
                                <span className="txt-ellipsis">{t('common:send_by_sms')}</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>
            <SendByEmail
                customerId={customerId}
                ref={refSendByEmail}
                onSend={_handleSendEmail}
                urlGetEmail={jobTemplateEmail}
                paramsEmail={{ job_id: jobId }}
                objectEmail={objectEmail}
            />
            {activateSms && (
                <SendBySms
                    urlGetSMS={jobListSms}
                    params={{ job_id: jobId }}
                    ref={refSendBySms}
                    onSend={_handleSendSms}
                    objectSms={objectSms}
                />
            )}
        </>
    );
};

export default Options;
