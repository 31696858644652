export default function IconMessageBox() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 8C4 6.89543 4.89543 6 6 6H18C19.1046 6 20 6.89543 20 8V19C20 19.824 19.0592 20.2944 18.4 19.8L16.5333 18.4C16.1871 18.1404 15.7661 18 15.3333 18H6C4.89543 18 4 17.1046 4 16V8Z"
                fill="#999999"
            />
            <path
                d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
                fill="#CCCCCC"
            />
            <path
                d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                fill="#CCCCCC"
            />
            <path
                d="M16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12C15 12.5523 15.4477 13 16 13Z"
                fill="#CCCCCC"
            />
        </svg>
    );
}
