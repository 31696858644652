import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_MATERIAL_USE_GET_LIST,
    REPORT_MATERIAL_USE_GET_LIST_JOBS_WO_COUNTY,
    REPORT_MATERIAL_USE_W_O_COUNTY
} from 'app/const/Api';
import { MATERIAL_NY_PRL, MATERIAL_USE_SELECT_OPTIONS } from 'app/const/App';

export function* getListReportMaterialUse({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCountyMaterialUseFilter({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST_JOBS_WO_COUNTY);
        if (response && response.success) {
            response.data.unshift({ id: 'all-by-county', name: 'All By County (USA)' });
            response.data.push(
                { id: MATERIAL_USE_SELECT_OPTIONS.ALL_DETAILED, name: 'All Detailed' },
                { id: MATERIAL_USE_SELECT_OPTIONS.NY_PRL, name: MATERIAL_NY_PRL }
            );
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCompletedJobWoCounty({ params, actionSuccess, actionFailed }) {
    try {
        const responseCompletedJob = yield call(fetchWithToken, REPORT_MATERIAL_USE_W_O_COUNTY);
        const responseMaterial = yield call(fetchWithToken, REPORT_MATERIAL_USE_GET_LIST, params);

        const response = {
            completedJob: responseCompletedJob?.data?.map((item) => {
                const listName = [item.date];
                const listService = [item.service_name];
                const listApplication = [''];
                item.material.forEach((value) => {
                    listName.push(value.name);
                    listService.push(`${value.qty || ''} ${value.unit_name || ''}`);
                    listApplication.push(value.applications);
                });
                return {
                    ...item,
                    list_material_name: listName,
                    service_name: listService,
                    list_application: listApplication
                };
            }),
            material: responseMaterial?.data.map((item) => {
                return { ...item, qty: `${item.qty || ''} ${item.unit_name || ''}` };
            }),
            success: responseCompletedJob.success && responseMaterial.success
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
