import React, { useEffect, useReducer, useRef } from 'react';
import TableOverride from './TableOverride';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListTaxes } from 'app/const/Api';
import { OVERRIDE_KEYWORD } from 'app/const/setting/SettingGlobalOverride';
import { previewNewRate } from '../utils/override';

const TaxOverride = () => {
    const refTable = useRef(null);
    const typeOverride = OVERRIDE_KEYWORD.TAX;

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true
    });

    const { data: finalData, isLoading } = state;

    useEffect(() => {
        fetchDataOverride();
    }, []);

    const fetchDataOverride = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState({ data, isLoading: false });
        };

        const _handleFail = (err) => {
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
            refTable.current?.showAlert(err);
        };

        clientQuery(getListTaxes, { method: 'GET', data: { fields: 'rate' } }, _handleSuccess, _handleFail);
    };

    const _handleApplySuccess = ({ dataApply }) => {
        // eslint-disable-next-line no-undef
        global.fetchedListTax = false;
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    const newValue = dataApply[item.id];
                    if (!newValue || !newValue.value?.length) return item;
                    return {
                        ...item,
                        rate: previewNewRate({ type: typeOverride, newValue })
                    };
                })
            };
        });
    };

    return (
        <div className="override-container flex-column">
            <TableOverride
                data={finalData}
                isLoading={isLoading}
                exceptNegative
                typeOverride={typeOverride}
                onApplySuccess={_handleApplySuccess}
            />
        </div>
    );
};

export default TaxOverride;
