import { useRef, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import { io } from 'socket.io-client';
import { SOCKET_EVENTS } from 'app/const/App';
import { WS_ENDPOINT } from 'app/const/URL';
import { WS_NAMESPACES } from 'app/const/Socket';
import {
    LIST_TYPE_CALL_ENDED,
    TYPE_CALL_DISCONNECT,
    TYPE_CALL_DISCONNECT_WITH_APP,
    TYPE_OUT_GOING
} from 'app/const/Voip';
import { useDispatch } from 'react-redux';
import { triggerRealtimeCall } from 'common/redux/actions/voipAction';
import { SocketContext } from 'app/services/socket/SocketProvider';

const SocketVOIP = forwardRef(({ dispatchState, onDisconnect = () => {} }, ref) => {
    const { CONNECT, CONNECTED } = SOCKET_EVENTS;

    const { timelineCall } = useContext(SocketContext);

    const socketVOIP = useRef();
    const currentRoom = useRef('');
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        joinRoom: _handleJoinRoom,
        getCurrentRoom: () => currentRoom.current
    }));

    useEffect(() => {
        if (timelineCall) {
            dispatch(
                triggerRealtimeCall({
                    ...timelineCall
                })
            );
        }

        return () => {
            window.removeEventListener('visibilitychange', _handleCheckConnection);
        };
    }, [timelineCall]);

    const _handleJoinRoom = (roomId) => {
        const options = {
            reconnection: true,
            transports: ['websocket', 'polling'], // avoid blocking
            forceNew: true,
            pingInterval: 25000
        };

        document.addEventListener('visibilitychange', _handleCheckConnection);

        socketVOIP.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.VOIP}`, options);

        socketVOIP.current.on(CONNECT, () => {
            socketVOIP.current.emit(CONNECTED, roomId);

            // socketVOIP.current.on('voip/inbox', (response) => {
            //     if (response) {
            //         dispatch(
            //             triggerRealtimeCall({
            //                 ...response
            //             })
            //         );
            //     }
            // });

            socketVOIP.current.on('voip/twilio/disconnected', (response) => {
                const { ParentCallSid, CallStatus, CallSid, Called } = response;

                const isIncoming = Called.includes('client');
                if (isIncoming && CallStatus !== TYPE_CALL_DISCONNECT.NOANSWER) return;

                const callId = isIncoming ? CallSid : ParentCallSid;
                const newStatus = TYPE_CALL_DISCONNECT_WITH_APP[CallStatus];

                onDisconnect(callId);

                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: prev.calls.map((item) => {
                            if (item.id === callId) {
                                const oldStatus = item.status;
                                item?.connection?.disconnect();

                                return {
                                    ...item,
                                    status: isIncoming
                                        ? TYPE_OUT_GOING.END
                                        : !LIST_TYPE_CALL_ENDED.includes(oldStatus)
                                          ? newStatus
                                          : oldStatus
                                };
                            }
                            return item;
                        })
                    };
                });
            });

            socketVOIP.current.on('voip/twilio/answered', (response) => {
                const { ParentCallSid, CallSid, Called } = response;
                const isIncoming = Called.includes('client');
                const callId = isIncoming ? CallSid : ParentCallSid;

                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: prev.calls.map((item) => {
                            if (item.id === callId) {
                                return {
                                    ...item,
                                    dataAnswered: response
                                };
                            }
                            return item;
                        })
                    };
                });
            });
        });
    };

    const _handleCheckConnection = () => {
        if (document.visibilityState === 'visible') {
            if (socketVOIP.current) {
                !socketVOIP.current.connected && socketVOIP.current?.connect();
            }
        }
    };

    return false;
});
export default SocketVOIP;
