import ButtonSave from 'app/components/button/ButtonSave';
import { ADDONS_BOOKING_SERVICES } from 'app/const/Api';
import { getListSettingService } from 'common/redux/actions/settings/serviceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import LoadingAdd from './LoadingAdd';
import { reducer } from 'app/const/Reducer';

const ServiceAdd = ({ onAddSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const { list_service: data, first_time: isFirstTime } = useSelector(({ serviceReducer }) => serviceReducer);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isLoading: isFirstTime, selected: [] });
    const { isOpen, isLoading, selected } = state;
    const refForm = useRef(null);
    const refButtonSave = useRef(null);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (selected) => {
        dispatchState({ isOpen: true, selected });
        isFirstTime && _handleGetServices();
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleGetServices = () => {
        dispatch(
            getListSettingService(
                { localSaveStore: true },
                () => dispatchState({ isLoading: false }),
                () => {}
            )
        );
    };

    const _handleSave = () => {
        const ids = [];
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { type, name, checked } = elements[index];
            if (type === 'checkbox' && checked) ids.push(name);
        }

        const _handleSaveSuccess = ({ data }) => {
            onAddSuccess(data);
            _close();
        };

        clientQuery(ADDONS_BOOKING_SERVICES, { data: { ids }, method: 'PUT' }, _handleSaveSuccess);
    };

    const _renderListService = () => {
        return data.map((item) => {
            return (
                <div key={item.id} className="row">
                    <div className="check-items">
                        <input
                            id={item.id}
                            type="checkbox"
                            name={item.id}
                            defaultChecked={selected.includes(item.id)}
                        />
                        <div className="item-checkbox">
                            <label htmlFor={item.id} className="txt-ellipsis">
                                <span>{item.name}</span>
                            </label>
                        </div>
                    </div>
                </div>
            );
        });
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="add_service_booking"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal">
                    <span className="txt-ellipsis">Available Services</span>
                </div>
                <form ref={refForm} className="body-modal --assign scrolls">
                    {isLoading ? <LoadingAdd /> : _renderListService()}
                </form>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ServiceAdd);
