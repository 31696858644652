import { forwardRef, useContext, useImperativeHandle } from 'react';
import { VOIPContext } from './VOIPProvider';

const ServiceSmartDialer = (props, ref) => {
    const { calls, onResetListDialer, onCreateSmartDialer } = useContext(VOIPContext);

    useImperativeHandle(ref, () => ({
        onResetDialer: _handleOnSaveSmartView,
        createSmartDialer: onCreateSmartDialer
    }));

    const _handleOnSaveSmartView = ({ id }) => {
        const callSmart = calls.find((item) => item?.smartDialer?.id === id);

        if (!callSmart) return;
        onResetListDialer();
    };
};

export default forwardRef(ServiceSmartDialer);
