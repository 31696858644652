import { REPORT_LEADS, REVIEWS } from 'app/config/routes';
import { LIST_TABS_FILTER } from 'app/const/report/ReportFilter';
import {
    REPORT_DROPDOWN_LEFT_OPTIONS,
    REPORT_DROPDOWN_RIGHT_OPTIONS,
    REPORT_MENU_OPTIONS
} from 'app/const/StaticLinks';
import IconSideBar from 'assets/icon/IconSideBar';
import { handleToggleSideBar } from 'common/utils/ReportUtils';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DropdownMenu from './DropdownMenu';
import ReportFilterTabs from './ReportTabsFilter';
import { getPermissionReport } from 'common/utils/PermissionUtils';
import { useState } from 'react';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { addBranchPath } from 'app/const/Branch';

const MainHeaderReport = ({
    reportType = '',
    contentLeft = () => {},
    contentRight = () => {},
    onSelectTab = () => {}
}) => {
    const location = useLocation();
    const addonsList = useSelector(({ auth }) => auth.user.settings.addons);
    const [toggle, setToggle] = useState(getToggleLocal('toggledReport'));

    const _getTitle = () => {
        const pathname = location.pathname;
        if (pathname === addBranchPath(REPORT_LEADS)) {
            return 'inbound_leads';
        }
        if (pathname === addBranchPath(REVIEWS)) {
            return 'service_rating';
        }
        return REPORT_MENU_OPTIONS.find((item) => addBranchPath(item.link) === pathname)?.value || '';
    };

    const renderListTabsFilter = () => {
        const tabs = LIST_TABS_FILTER[reportType];
        if (tabs) return <ReportFilterTabs list={tabs} reportType={reportType} onSelect={onSelectTab} />;
        return null;
    };

    const _toggleSideBar = () => {
        setToggle(!toggle);
        setLocalStorage('toggledReport', !toggle);
        handleToggleSideBar(!toggle);
    };

    return (
        <div className="header --main">
            <div className="header__left flex-1">
                <div className="header-items v2-btn-default --icon-lg" onClick={_toggleSideBar}>
                    <IconSideBar isActive={toggle} />
                </div>
                <DropdownMenu
                    leftOptions={REPORT_DROPDOWN_LEFT_OPTIONS}
                    rightOptions={REPORT_DROPDOWN_RIGHT_OPTIONS}
                    title={_getTitle()}
                    fileTranslation="report"
                    isHaveBgGrey
                    permissions={getPermissionReport(addonsList)}
                />
                {renderListTabsFilter()}
                {contentLeft()}
            </div>
            {contentRight()}
        </div>
    );
};
export default MainHeaderReport;
