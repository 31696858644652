import classNames from 'classnames';
import React, { Fragment, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDModalWarning from 'app/components/modal/ModalWarning';
import { actionDeleteUnit } from 'app/const/Api';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { Footer } from '../building';

export default function ItemUnit({ itemUnit, isActive, onSelect, onDelete, withoutFetch = false }) {
    const { t } = useTranslation('jobDetail');
    const refItemUnit = useRef(null);
    const refNotificationPopup = useRef(null);
    const refButtonSave = useRef(null);

    const unitId = itemUnit.id;

    useEffect(() => {
        if (itemUnit.created) {
            refItemUnit.current && refItemUnit.current.scrollIntoView();
        }
    }, [itemUnit.created]);

    function _handleDeleteUnit(e) {
        e && e.stopPropagation();
        const _success = () => {
            onDelete(e, unitId);
        };
        const _fail = ({ message }) => {
            refNotificationPopup.current?._setStatusAlert(message);
        };
        const _finally = () => {
            refButtonSave.current?.removeLoading();
        };
        if (!withoutFetch) {
            clientQuery(actionDeleteUnit(unitId), { method: 'delete' }, _success, _fail, _finally);
        }
    }

    function _handleConfirmDelete(e) {
        e && e.stopPropagation();
        refNotificationPopup.current?._open({
            title: t('deleting_unit'),
            description: t('delete_desc', { name: t('unit') })
        });
    }

    const _handleClose = () => {
        refNotificationPopup.current?._close();
    };

    return (
        <Fragment>
            <div
                onClick={(e) => onSelect(e, unitId, isActive)}
                ref={refItemUnit}
                className={classNames('content__items', { selected: isActive })}
            >
                <p className="content__items-name">{itemUnit.name}</p>
                <div onClick={(e) => _handleConfirmDelete(e)} className="content__items-icon">
                    <IconTrash />
                </div>
            </div>
            <GDModalWarning
                ref={refNotificationPopup}
                footer={
                    <Footer ref={refButtonSave} handleCloseConfirm={_handleClose} handleConfirm={_handleDeleteUnit} />
                }
            />
        </Fragment>
    );
}
