import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import IconTooltip from 'assets/icon/IconTooltip';

const JobCycles = () => {
    const { t } = useTranslation(['setting']);
    const companyId = useSelector(({ auth }) => auth.user.company.user_id);
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCycles = repeatSettings?.job_cycle || 1;
    const companiesAcceptedIDList = process.env.REACT_APP_WHITE_LIST_USER.split(',');

    const _handleChangeJobCycle = (interval) => onChangeDataContext({ ...repeatSettings, job_cycle: interval });

    if (!companiesAcceptedIDList.some((item) => item === companyId.toString())) return null;

    return (
        <div className="rows row-has-line border-top-border-color-grey">
            <div className="txt no-icon">
                {t('setting:job_cycle')}
                <div className="tooltip ml-1">
                    <IconTooltip />
                    <span className="tooltiptext top">{t('setting:tooltip_job_cycle')}</span>
                </div>
            </div>
            <div className="field-every">
                <FormInterval interval={jobCycles} onChange={_handleChangeJobCycle} />
            </div>
        </div>
    );
};

export default JobCycles;
