import React, { useEffect, useRef } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateGetJobActive } from 'app/const/Api';
import { useSelector, useDispatch } from 'react-redux';
import ModalRecurringJob from 'app/modules/calendar/components/modal/RecurringJob';
import { addNewBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import moment from 'moment';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import IconUnderscore from 'assets/icon/IconUnderscore';
import IconClose from 'assets/icon/IconClose';
import { useTranslation } from 'react-i18next';

const Header = ({ onClose, jobData, setPastActive, finalJobPastActive }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || false);

    const refModalRecurringJob = useRef(null);

    useEffect(() => {
        if (!jobData.isLoading && activateSms) {
            if (jobData.recurrence.length !== 0) {
                clientQuery(updateGetJobActive(jobData.id), { method: 'GET' }, _getJobActiveSuccess);
            }
        }
    }, [jobData.isLoading]);

    function _handleOpenJobDetail(e) {
        e && e.preventDefault();
        if (jobData.recurrence.length !== 0) {
            if (finalJobPastActive) {
                _openModalRecurringJob(finalJobPastActive);
                return false;
            } else {
                clientQuery(updateGetJobActive(jobData.id), { method: 'GET' }, _getJobActiveOpen);
            }
        } else {
            _setJobDetailReducer(jobData);
        }
    }

    function _setJobDetailReducer(data) {
        dispatch(actionOpenJobDetail({ id: data.id || 0 }));
    }

    function _getJobActiveOpen(response) {
        _openModalRecurringJob(response.data);
    }

    function _openModalRecurringJob(finalJobData) {
        refModalRecurringJob.current &&
            refModalRecurringJob.current._showOptions(finalJobData, () => _setJobDetailReducer(finalJobData));
    }

    function _getJobActiveSuccess(response) {
        setPastActive(response.data);
    }

    function _closeModalJob(e) {
        e.stopPropagation();
        onClose();
    }

    function _handleBookMarkJob() {
        dispatch(addNewBookMarkAction({ type: 'job', id: moment().format('x'), data: jobData }));
        onClose();
    }

    return (
        <div className="sidebar-preview-header flexcenter">
            <div className="flex-1">
                <div className="v2-btn-default" onClick={_handleOpenJobDetail}>
                    {t('open_job')}
                </div>
            </div>
            <div className="v2-btn-default has-bg-grey --icon-lg" onClick={_handleBookMarkJob}>
                <IconUnderscore isHyphen />
            </div>

            <div className="v2-btn-default has-bg-grey --icon-lg ml-1" onClick={_closeModalJob}>
                <IconClose />
            </div>
            <ModalRecurringJob ref={refModalRecurringJob} />
        </div>
    );
};

export default Header;
