import React from 'react';

import { ADDONS_CHAT_BOT_CONTENT } from 'app/config/routes';
import AddonsOptionsFooterManage from '../../components/AddonsOptionsFooterManage';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';

const AddonsChatbotFooter = ({ keyword = '', isEnabled = false, status = false }) => {
    const _renderFooter = () => {
        switch (keyword) {
            case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_SMS:
            case ADDONS_FOOTER_OPTIONS.CHATBOT_AI_PRICING:
                return <AddonsOptionsFooterManage isDisable={!isEnabled || !status} path={ADDONS_CHAT_BOT_CONTENT} />;
            default:
                return null;
        }
    };

    return <div className="boxs__footer">{_renderFooter()}</div>;
};

export default AddonsChatbotFooter;
