export const TYPE_EMAIL_SETTING = {
  GET_LIST_EMAIL_VARIABLE: "GET_LIST_EMAIL_VARIABLE",
  GET_LIST_EMAIL_VARIABLE_SUCCESS: "GET_LIST_EMAIL_VARIABLE_SUCCESS",

  SEND_TEST_EMAIL: "SEND_TEST_EMAIL_SETTING"
};

// actions
export const getListEmailVariable = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_EMAIL_SETTING.GET_LIST_EMAIL_VARIABLE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const sendTestEmailAction = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_EMAIL_SETTING.SEND_TEST_EMAIL,
    params,
    actionSuccess,
    actionFailed,
  };
};
