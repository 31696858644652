import {
    CODE_HIGHT_LIGHT,
    CODE_HIGHT_LIGHT_1,
    CODE_HIGHT_LIGHT_2,
    CODE_HIGHT_LIGHT_3,
    KEY_PORTAL_TOKEN,
    KEY_SUB_DOMAIN,
    LINK_POST_CUSTOMER_PORTAL
} from 'app/const/addons';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ButtonCopy from 'app/components/button/ButtonCopy';
const BookingShowCode = ({ scriptInfo = {} }) => {
    const { t } = useTranslation(['addons']);
    const [isShow, setIsShow] = useState(false);

    const _handleToggleShow = () => setIsShow(!isShow);

    return (
        <>
            {isShow && <BookingCode scriptInfo={scriptInfo} />}
            <div className="d-flex justify-space-between align-center mt-3">
                <div className="v2-btn-default --purple svg-purple --icon-r ml-n2" onClick={_handleToggleShow}>
                    {isShow ? 'Hide Code' : 'Show Code'}
                    {isShow ? <IconArrowDown isUp /> : <IconArrowDown />}
                </div>
                <a
                    href={LINK_POST_CUSTOMER_PORTAL}
                    target="_blank"
                    className="svg-purple purple-default link-portal --md"
                    rel="noreferrer"
                >
                    <IconBachelorsHat />
                    <span className="txt-ellipsis ml-1">{t('i_need_detailed_instructions')}</span>
                </a>
            </div>
        </>
    );
};

const BookingCode = ({ scriptInfo = {} }) => {
    const { t } = useTranslation(['addons']);
    const refValueCodeInstall = useRef(null);

    const codeHightLightFormat = () => {
        let content = CODE_HIGHT_LIGHT;
        content = content.replace(KEY_PORTAL_TOKEN, scriptInfo.account_id);
        content = content.replace(KEY_SUB_DOMAIN, scriptInfo.src);
        refValueCodeInstall.current = content;
        return content;
    };

    return (
        <div id="show_code" className="show-code mt-5 open">
            <p className="mb-1">{t('your_website_installation_code_for_both_addons')}</p>
            <div className="rows">
                {t('add_the_following_code_just_before_the_closing')}
                <div className="status-btn tag-body fs-13 bg-background-grey violet-name">
                    <span>&lt;</span>
                    {t('body')}
                    <span>&gt;</span>
                </div>
                {t('tag')}
            </div>
            <SyntaxHighlighter
                language="javascript"
                showLineNumbers
                style={coy}
                lineNumberStyle={{ textAlign: 'left', minWidth: '10px' }}
                customStyle={{
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #e3e8ee',
                    backgroundColor: '#f9f9f9',
                    lineHeight: '22px',
                    fontSize: '13px'
                }}
            >
                {codeHightLightFormat()}
            </SyntaxHighlighter>
            <ButtonCopy
                content={refValueCodeInstall.current}
                className="v2-btn-default has-icon --grey mt-1"
                tooltipText={t('copy_to_clipboard')}
            />
            <div className="show-code__more">
                {t('use_one_of_the_following_codes_to_link_directly')}
                <ItemCodeCopy code={CODE_HIGHT_LIGHT_1} />
                <ItemCodeCopy code={CODE_HIGHT_LIGHT_2} />
                <ItemCodeCopy code={CODE_HIGHT_LIGHT_3} />
            </div>
        </div>
    );
};

const ItemCodeCopy = ({ code = '' }) => {
    return (
        <div className="form-installation">
            <div className="installation-content-code" dangerouslySetInnerHTML={{ __html: code.display }} />
            <ButtonCopy content={code.value} className="v2-btn-default --icon-lg --grey tooltip" />
        </div>
    );
};

export default BookingShowCode;
