import React from 'react';
import LoadingBarLittle from 'app/components/loading/LoadingBarLittle';

function ButtonLoading({
    onSubmit = () => {},
    text = 'Submit',
    isLoading = false,
    classWrapper = 'gd-getstarted v2-btn-main gd-btn-getstarted fw-500'
}) {
    return (
        <div className={`${classWrapper} ${isLoading ? '--loader' : ''}`} onClick={onSubmit}>
            {text}
            {isLoading && <LoadingBarLittle />}
        </div>
    );
}

export default ButtonLoading;
