import { TYPE_JOBLIST_ACTION } from 'common/redux/actions/calendar/jobListAction';

const bookMarkReducer = (
    state = {
        customer: null
    },
    action
) => {
    switch (action.type) {
        case TYPE_JOBLIST_ACTION.ACTION_SELECT_CUSTOMER:
            const finalParam = {
                ...action.params,
                getTime: Date.now()
            };
            return {
                customer: finalParam
            };
        default:
            return state;
    }
};

export default bookMarkReducer;
