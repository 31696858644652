import React, { Fragment } from 'react';

export const VOIPAddonsLoadingManage = () => {
    return (
        <Fragment>
            <div className="wrap-loading">
                <div className="flex-betweenitems">
                    <div className="loading --animation --light --line btn-sm"></div>
                    <div className="v2-btn-default loading btn-sm bg-grey-extralight"></div>
                </div>
                <div className="box-voip bg-white">
                    <div className="box-voip__rows flexcenter flex-wrap">
                        <div className="flex-1 flexcenter flex-wrap col-gap-8">
                            <div className="loading --animation --line --onefourth"></div>
                            <div className="loading --animation --line --threefourth mt-2"></div>
                        </div>
                        <div className="v2-btn-default loading --icon-lg bg-white-cultured"></div>
                    </div>
                    <div className="box-voip__rows flexcenter flex-wrap">
                        <div className="flex-1">
                            <div className="loading --animation --line --twothird"></div>
                        </div>
                        <div className="loading --animation --line --onefifth"></div>
                        <div className="audio-loading loading --full mt-2"></div>
                    </div>
                    <div className="box-voip__rows flexcenter flex-wrap">
                        <div className="flex-1">
                            <div className="loading --animation --line --twothird"></div>
                        </div>
                        <div className="loading --animation --line --onefifth"></div>
                        <div className="w-100 mt-2">
                            <div className="loading --animation --line --twothird"></div>
                        </div>
                    </div>
                    <div className="box-voip__rows flexcenter flex-wrap">
                        <div className="wrap-member-group flexcenter flex-1">
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                            <div className="avt-img"></div>
                        </div>
                        <div className="loading --animation --line --twothird"></div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
