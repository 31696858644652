import React from 'react';
import { useTranslation } from 'react-i18next';

export const AddonItemAfterHours = ({ usage = {} }) => {
    const { t } = useTranslation();

    if (!usage) return null;

    const { this_month = 0, last_month = 0, last_month_label = 'Mar' } = usage;

    return (
        <div className="mt-3 pt-3 border-top-border-color-grey">
            <div className="flex-betweenitems mb-1">
                <p>{t('addons:minutes_used_this_month')}</p>
                <p className="fw-500">{this_month}</p>
            </div>
            <div className="flex-betweenitems">
                <p>{t('addons:minutes_used_last_month', { month: last_month_label })}</p>
                <p className="fw-500">{last_month}</p>
            </div>
        </div>
    );
};
