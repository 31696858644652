import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { ACCESS_TOKEN } from 'app/const/App';

const httpLink = createHttpLink({
    // eslint-disable-next-line no-undef
    uri: process.env.REACT_APP_GRAPHQL_URL
});

const authLinkGenerator = (branchId) => {
    return new ApolloLink((operation, forward) => {
        const token = localStorage.getItem(ACCESS_TOKEN);

        operation.setContext(({ headers }) => ({
            headers: {
                ...headers,
                'Gd-Branch-Id': branchId,
                Authorization: token ? `Bearer ${token}` : ''
            }
        }));

        return forward(operation);
    });
};

export const client = (branchId) => {
    const authLink = authLinkGenerator(branchId);
    return new ApolloClient({
        link: ApolloLink.from([authLink, httpLink]),
        cache: new InMemoryCache({ typePolicies: { CustomJobStatusItem: { keyFields: ['id', 'count'] } } })
    });
};
