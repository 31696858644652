import React, { Fragment, forwardRef, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { ESTIMATES_TEMPLATE_OPTIONS, ESTIMATES_TEMPLATE_TYPE, TEMPLATE_KEYWORD } from 'app/const/Notes';
import ErrorPage from 'app/modules/error';
import { ESTIMATE_TYPE } from 'app/modules/jobdetail/const/Estimate';
import { checkPermission } from 'common/utils/PermissionUtils';
import List from '../notes/list';
import EstimateDetail from './Detail';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'app/const/Permissions';
import { checkAddon } from 'common/utils/AddonUtils';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_TEMPLATES_ESTIMATE } from 'app/config/routes';

const EstimatesTemplate = () => {
    const { t } = useTranslation('auth');
    const permissions = useSelector(({ auth }) => auth.user.permissions);
    const isAccessSettingTab = checkPermission(permissions?.enabled || [], PERMISSIONS.accessSettingsTab);

    const refList = useRef(null);
    const refDetailItems = useRef(null);
    const { id: estimateType } = useParams();
    const finalEstimateType = parseInt(estimateType);
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});
    const history = useHistory();

    const getKey = ESTIMATES_TEMPLATE_OPTIONS.find((item) => item.id === finalEstimateType);

    if (!getKey || (!checkAddon(addonsList?.estimates?.[getKey.key]) && !getKey.isDefault)) {
        history.push(addBranchPath(SETTINGS_TEMPLATES_ESTIMATE.replace(':id', ESTIMATES_TEMPLATE_TYPE.BASIC)));
    }

    const finalType = useMemo(() => {
        switch (finalEstimateType) {
            case ESTIMATES_TEMPLATE_TYPE.BASIC:
                return ESTIMATE_TYPE.BASIC;
            case ESTIMATES_TEMPLATE_TYPE.DYNAMIC:
                return ESTIMATE_TYPE.DYNAMIC;
            case ESTIMATES_TEMPLATE_TYPE.PACKAGES:
                return ESTIMATE_TYPE.PACKAGES;
            default:
                return ESTIMATE_TYPE.BASIC;
        }
    }, [estimateType]);

    useEffect(() => {
        refDetailItems.current?._getDetail({});
    }, [finalType]);

    const _handleGetDetail = (item) => {
        refDetailItems.current._getDetail(item || {});
    };

    const _handleClickAddNew = () => {
        refDetailItems.current._openAddTemplate(finalType);
    };

    const _addTemplate = (item, isEdit = false) => {
        if (isEdit) {
            refList.current._updateItemToList(item);
            _handleGetDetail(item);
        } else {
            refList.current._addItemToList(item, true);
        }
    };

    const _showStatusBar = (id, message, status) => {
        refDetailItems.current?._showStatusBar(id, message, status);
    };

    if (!isAccessSettingTab) return <ErrorPage errorMessage={t('no_permission_access')} />;

    return (
        <Fragment>
            <List
                ref={refList}
                typeTemplate={TEMPLATE_KEYWORD.ESTIMATE}
                onAddNew={_handleClickAddNew}
                onGetDetail={_handleGetDetail}
                onShowStatusBar={_showStatusBar}
            />
            <EstimateDetail onAddTemplate={_addTemplate} ref={refDetailItems} />
        </Fragment>
    );
};

export default forwardRef(EstimatesTemplate);
