export default function IconHashtag() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2121 4.51323C10.984 4.61052 11.5342 5.2101 11.4986 5.88359L11.4849 6.01063L9.48493 18.3636C9.36778 19.0872 8.60798 19.59 7.78788 19.4867C7.01602 19.3894 6.46583 18.7898 6.50145 18.1163L6.51508 17.9893L8.51508 5.63628C8.62535 4.95523 9.30487 4.46977 10.0682 4.5012L10.2121 4.51323Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.0682 4.5012L15.2121 4.51323C15.984 4.61052 16.5342 5.2101 16.4986 5.88359L16.4849 6.01063L14.4849 18.3636C14.3678 19.0872 13.608 19.59 12.7879 19.4867C12.016 19.3894 11.4658 18.7898 11.5015 18.1163L11.5151 17.9893L13.5151 5.63628C13.6253 4.95523 14.3049 4.46977 15.0682 4.5012Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8217 7.99995C18.5049 7.99995 19.0588 8.67152 19.0588 9.49995C19.0588 10.2796 18.5682 10.9204 17.9408 10.9931L17.8217 10.9999H6.17828C5.49504 10.9999 4.94116 10.3284 4.94116 9.49995C4.94116 8.72025 5.43179 8.0795 6.05913 8.00681L6.17828 7.99995H17.8217Z"
                fill="#7A83A6"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.8217 12.9999C17.5049 12.9999 18.0588 13.6715 18.0588 14.4999C18.0588 15.2796 17.5682 15.9204 16.9408 15.9931L16.8217 15.9999H5.17828C4.49504 15.9999 3.94116 15.3284 3.94116 14.4999C3.94116 13.7203 4.43179 13.0795 5.05913 13.0068L5.17828 12.9999H16.8217Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}
