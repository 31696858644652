import React from 'react';

export default function Duration({ duration, isCallLog = true, onlyText = false }) {
    if (!duration && !isCallLog) {
        return null;
    }
    const numberMinutes = Math.floor(duration / 60);

    if (onlyText) {
        return `${numberMinutes > 0 ? `${numberMinutes}`.concat('m : ') : ''} ${duration % 60}s`;
    }

    return (
        <span className="call-summary__duration dots">
            {numberMinutes > 0 ? `${numberMinutes}`.concat('m : ') : ''}
            {duration % 60}s
        </span>
    );
}
