import IconCoordinate from 'assets/icon/IconCoordinate';
import IconGoogleMaps from 'assets/icon/IconGoogleMaps';

export const LINK_OPEN_GOOGLE_MAP = 'https://maps.google.com/maps?q=';
export const LINK_OPEN_GOOGLE_COORDINATE = 'https://maps.google.com/?q=';
export const OPEN_COORDINATES = 'open_coordinates';
export const OPEN_GOOGLE_MAP = 'open_google_map';
export const CLASS_NAME_OPEN_LOCATION = 'open_location';

export const LIST_OPTION_OPEN_LOCATION = [
    { id: OPEN_COORDINATES, icon: <IconCoordinate /> },
    { id: null },
    { id: OPEN_GOOGLE_MAP, icon: <IconGoogleMaps /> }
];
