import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';

import GdGridView from 'app/components/grid/GdGridView';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT, REPORT_TYPE } from 'app/const/Reports';
import { URL_EXPORT_CALL_ACTIVITY } from 'app/const/api/Export';
import Export from 'app/modules/report/components/Export';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { useTranslation } from 'react-i18next';
import { getGridColumns } from './ColumnPopupCallActivity';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { REPORT_CALL_ACTIVITY_COMPARISON } from 'app/const/Api';
import { convertParamFields } from 'common/utils/ReportUtils';
import { handleAbortController } from 'app/modules/customer/utils';
import GDStatusBar from 'app/components/status/statusbar';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import { TYPE_SELECT_PHONE } from 'app/const/Customers';

const PopupReportCallActivity = (props, ref) => {
    const { t } = useTranslation();
    const keyReport = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CALL_ACTIVITY);
    const abortController = useRef(null);
    const refStatus = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        data: [],
        isLoading: true,
        isLoadingMore: false,
        refreshScreen: 0,
        show_more: false,
        cursor: null,
        total: 0
    });
    const {
        isVisible,
        data: finalData,
        isLoading: finalIsLoading,
        refreshScreen,
        isLoadingMore: finalIsLoadingMore,
        show_more: showMore,
        cursor,
        total: finalTotal
    } = state;
    const lengthData = finalData.length;

    useImperativeHandle(ref, () => ({
        open: _handleOpen
    }));

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.CLOSE_PHONE_POPPER, _handleClickPhoneSuccess);

        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.CLOSE_PHONE_POPPER, _handleClickPhoneSuccess);
        };
    }, []);

    useEffect(() => {
        if (finalIsLoadingMore) {
            _getDataReport({ params: { ..._getParams(), cursor }, isLoadingMore: true });
        }
    }, [finalIsLoadingMore]);

    const _getParams = () => getLocalStorage(keyReport);

    const _handleClickPhoneSuccess = ({ detail = {} }) => {
        if (detail.event === TYPE_SELECT_PHONE.SMS) {
            _handleClose();
        }
    };

    const _handleOpen = ({ start, end, id, action }) => {
        setLocalStorage(keyReport, { start, end, id, call_type: action, limit: REPORT_LIMIT, total: 1 });

        dispatchState((prev) => ({ ...prev, isVisible: true, isLoading: true, data: [], total: 0 }));
        _getDataReport({ params: _getParams() });
    };

    const _handleClose = () => {
        handleAbortController(abortController);
        dispatchState((prev) => ({ ...prev, isVisible: false, isLoading: true }));
        removeLocalStorage();
    };

    const _handleShowStatus = ({ message, success }) => {
        showStatusBar({ id: 'popup_call_activity', message, success, refAlert: refStatus });
    };

    const _getDataReport = ({ params = {}, isLoadingMore = false }) => {
        abortController.current = new AbortController();
        const _handleSuccess = ({ data, cursor, show_more, total }) => {
            const newData = data.map((item) => {
                const { phone, customer, contact } = item;
                return {
                    ...item,
                    phone: { ...phone, phone_type: '', contact_id: contact?.id || '' },
                    customer: { ...customer, name: !!customer.id ? customer.full_name : t('report:unknown') },
                    contact: contact?.full_name,
                    account_number: customer.account_number || ''
                };
            });

            dispatchState((prev) => {
                return {
                    ...prev,
                    data: isLoadingMore ? [...prev.data, ...newData] : newData,
                    cursor,
                    show_more,
                    isLoading: false,
                    isLoadingMore: false,
                    total: isLoadingMore ? prev.total : total
                };
            });
        };

        const _handleFail = (err) => {
            if (err.isAborted) return;
            _handleShowStatus(err);
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
        };

        clientQuery(
            REPORT_CALL_ACTIVITY_COMPARISON,
            {
                method: 'GET',
                data: convertParamFields({ params, isEmptyFields: isLoadingMore }),
                abortController: abortController.current
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleUpdate = () => {
        handleAbortController(abortController);
        dispatchState((prev) => ({
            ...prev,
            data: [],
            isLoading: true,
            isLoadingMore: false,
            total: 0,
            refreshScreen: Date.now()
        }));
        _getDataReport({ params: _getParams() });
    };

    const _handleLoadMore = () => {
        if (!finalIsLoadingMore && !finalIsLoading && showMore) {
            dispatchState({ isLoadingMore: true });
        }
    };

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CALL_ACTIVITY,
            columnsTarget,
            currentValue,
            paramsReport: _getParams(),
            callBack: _handleUpdate
        });
        dispatchState({ refreshScreen: Date.now() });
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal modal-outbound open">
            <div className="modal__overlay bg-transparent" onClick={_handleClose} />
            <div className="modal__container container-table">
                <div className="container-print">
                    <div className="header --main pb-0 pl-2 justify-start">
                        <div className="v2-btn-default has-icon --transparent" onClick={_handleClose}>
                            <IconArrow isPrev />
                            {t('back')}
                        </div>
                    </div>
                    <div className="header --filter">
                        <ReportDateRangePicker
                            reportType={REPORT_TYPE.CALL_ACTIVITY}
                            onSelect={_handleUpdate}
                            wrapperClassName="header__left flex-1"
                        />
                        <Export
                            title={t(`report:record${lengthData !== 1 ? 's' : ''}`, { count: finalTotal })}
                            activePrint
                            url={URL_EXPORT_CALL_ACTIVITY}
                            params={_getParams()}
                            pageExport={LIST_EXPORT.EXPORT_CALL_ACTIVITY}
                            refresh={refreshScreen}
                            isDisable={finalIsLoading}
                        />
                    </div>
                    <GDStatusBar ref={refStatus} />
                    <GdGridView
                        isEmptyFlat
                        isLoading={finalIsLoading}
                        isLoadmore={finalIsLoadingMore}
                        classTable="table-multi-column scrolls-x has-text-ellipsis"
                        classTableContent=""
                        content={finalData}
                        fileTranslation={'report'}
                        {...getGridColumns({ actionsStatus: _getParams()?.order })}
                        isScroll
                        onScrollToEnd={_handleLoadMore}
                        handleClickHeader={handleActionHeader}
                    />
                </div>
            </div>
        </div>
    );
};

PopupReportCallActivity.displayName = 'PopupReportCallActivity';
export default forwardRef(PopupReportCallActivity);
