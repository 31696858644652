import React from 'react';

import { SETTINGS_TEMPLATES_ESTIMATE } from 'app/config/routes';
import { ESTIMATE_TEMPLATE_ID } from 'app/const/Notes';
import AddonsOptionsFooterManage from './AddonsOptionsFooterManage';

const FooterManageEstimate = ({ keyword = '' }) => {
    return (
        <div className="boxs__footer">
            <AddonsOptionsFooterManage
                path={SETTINGS_TEMPLATES_ESTIMATE.replace(':id', ESTIMATE_TEMPLATE_ID[keyword])}
            />
        </div>
    );
};

export default FooterManageEstimate;
