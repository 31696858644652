export const TABS_QUICKBOOKS_LOG = {
    WAITING: 'waiting',
    HISTORY: 'sync_history',
    CONSOLE: 'sync_console'
};

export const TYPE_QUICKBOOKS_SYNC = {
    TAX: 'synctax',
    DISCOUNT: 'sync_discount',
    ACCOUNT: 'discount_qb',
    STRIPE: 'syncfee',
    SQUARE: 'squarefee',
    CREDIT: 'sync_credit'
};
