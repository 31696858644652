import ButtonSave from 'app/components/button/ButtonSave';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconTooltip from 'assets/icon/IconTooltip';
import classNames from 'classnames';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ACCOUNT_ROLE } from 'app/const/Account';

const Header = (
    {
        contentTabs = () => {},
        contentLeft = () => {},
        contentRight = () => {},
        isManage = false,
        onChangeManage = () => {},
        handleSave = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const refButtonSave = useRef(null);
    const [disabled, setDisable] = useState(true);
    const role = useSelector(({ auth }) => auth.user.profile.role);

    const _setDisable = (status) => {
        status !== disabled && setDisable(status);
    };

    useImperativeHandle(ref, () => ({
        _removeLoading: () => refButtonSave.current.removeLoading(),
        _setDisable
    }));

    return (
        <>
            <div className="header-title">
                <h3 className="header-title__label">{t('commission:commission_tracking')}</h3>
                <div className="tooltip ml-1">
                    <IconTooltip />
                    <span className="tooltiptext top">{t('commission:commission_tracking')}</span>
                </div>
                {contentTabs()}

                {role === ACCOUNT_ROLE.SUPERADMIN && !isManage && (
                    <div className="v2-btn-default has-icon" onClick={onChangeManage}>
                        <IconCogWheel /> {t('commission:manage')}
                    </div>
                )}
                <div className={classNames('wrap-btn', { 'dp-hide': !isManage })}>
                    <div className="v2-btn-default --transparent" onClick={onChangeManage}>
                        {t('common:cancel')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        title={t('common:save')}
                        wrapClass="v2-btn-main ml-2"
                        onSave={handleSave}
                        disabled={disabled}
                    />
                </div>
            </div>
            <div className={classNames('header --filter pt-0', { 'dp-hide': isManage })}>
                <div className="header__left flex-1">{contentLeft()}</div>
                {contentRight()}
            </div>
        </>
    );
};
export default forwardRef(Header);
