import { LIST_API_UPDATE_ADDONS_OPTIONS } from 'app/const/addons';
import { React } from 'react';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const ApiKeysAddonsContent = ({ keyword = '', name = '', sub_title = '', upgrade, level, status, handleCreateAPI }) => {
    return (
        <div className="boxs boxs--no-border">
            <AddonsBoxHeader
                keyword={keyword}
                name={name}
                upgrade={upgrade}
                status={status}
                level={level}
                urlUpdateStatus={LIST_API_UPDATE_ADDONS_OPTIONS.API_KEY}
                handleCreateAPI={handleCreateAPI}
            />
            <div className="boxs__contents my-3">
                <p className="description">{sub_title}</p>
            </div>
        </div>
    );
};

export default ApiKeysAddonsContent;
