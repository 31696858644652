export const TYPE_DRIP_CAMPAIGNS = {
  GET_LIST: "DRIP_GET_LIST_CAMPAIGNS",
  GET_LIST_CATEGORIES: "GET_LIST_CATEGORIES",
  UPDATE_STATUS_CAMPAIGN: "UPDATE_STATUS_CAMPAIGN",
  DUPLICATE_CAMPAIGN: "DUPLICATE_CAMPAIGN",
  DELETE_CAMPAIGN: "DELETE_CAMPAIGN",

  GET_DETAIL_CAMPAIGN: "GET_DETAIL_CAMPAIGN",
  CREATE_NEW_CAMPAIGN: "CREATE_NEW_CAMPAIGN",
  UPDATE_DETAIL_CAMPAIGN: "UPDATE_DETAIL_CAMPAIGN"
};

// actions
export const getListDripCampaigns = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

// actions
export const getListCategoriesCampaigns = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.GET_LIST_CATEGORIES,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateStatusCampaign = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.UPDATE_STATUS_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const deleteCampaign = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.DELETE_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const getCampaignDetailAction = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.GET_DETAIL_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  }
}

export const createNewCampaign = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.CREATE_NEW_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  }
}

export const updateCampaign = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.UPDATE_DETAIL_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  }
}

export const duplicateCampaign = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_CAMPAIGNS.DUPLICATE_CAMPAIGN,
    params,
    actionSuccess,
    actionFailed,
  }
}