const Loading = () => {
    return (
        <div className="wrap-loading wrap-list-items flex-auto">
            <div className="header-title">
                <div className="loading --animation --line --twothird"></div>
            </div>
            <div className="wrap-list-items__search">
                <div className="v2-btn-default loading --grey --full">
                    <div className="loading --animation --line --onefifth"></div>
                </div>
            </div>
            <div className="list-details pointer-events-none is-active">
                <div className="list-details__items">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn --sm loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
                <div className="list-details__items">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn --sm loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
                <div className="list-details__items">
                    <div className="template-name flex-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="status-btn --sm loading --onefifth mt-1 bg-white-cultured"></div>
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
                </div>
            </div>
        </div>
    );
};
export default Loading;
