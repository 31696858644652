import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconTag from 'assets/icon/IconTag';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import ServiceDashboard from '../../ServiceDashboard';
import RowItemWidget from '../../components/RowItemWidget';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, TAGS_TAB, getDefaultParamsWidget } from '../../const';
import LoadingTable from '../loading/LoadingTable';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const WidgetTags = () => {
    const { t } = useTranslation('report');
    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.TAGS;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData, params: finalParams } = state;

    useEffect(() => {
        fetchWidgetData(defaultParams);
    }, []);

    const fetchWidgetData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleGetDataSuccess = ({ data }) => {
        dispatchState((prev) => ({
            ...prev,
            data,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderItem = ({ label, percent, value }, key) => {
        return <RowItemWidget key={key} icon={<IconTag isNormal />} label={label} percent={percent} value={value} />;
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState((prev) => ({
            ...prev,
            isLoading: true,
            params: { ...prev.params, ...params }
        }));
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchWidgetData(newParams);
    };

    const _renderTab = () => {
        return (
            <div className="btn-item ml-0">
                {Object.values(TAGS_TAB).map(({ id, label }) => {
                    return (
                        <button
                            key={id}
                            className={classNames('tab-items', {
                                'active-tab-selector': id === finalParams['type']
                            })}
                            type="button"
                            onClick={() => _handleChangeFilterWidget({ type: id })}
                        >
                            {t(label)}
                        </button>
                    );
                })}
            </div>
        );
    };

    const _renderTableTags = () => {
        if (finalIsLoading) return <LoadingTable numberRowLoading={12} />;

        return (
            <div className="boxs__content --progress-bar">
                {finalData?.length > 0 ? (
                    <table className="dashboard-table">
                        <tbody>{finalData.map((item, index) => _renderItem(item, index.toString()))}</tbody>
                    </table>
                ) : (
                    <GridEmpty isFlat />
                )}
            </div>
        );
    };

    return (
        <div className="boxs">
            <div className="boxs__header">
                <div className="header-label flex-1">{t('tags')}</div>
                <div className="header-items tabs">{_renderTab()}</div>
            </div>
            {_renderTableTags()}
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </div>
    );
};

export default WidgetTags;
