import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { actionAddNoteCall } from 'app/const/api/Voip';
import { TYPE_CALL_DISCONNECT } from 'app/const/Voip';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { clientQuery } from 'common/utils/ApiUtils';

const ServiceVoicemailDrop = ({ typeEnd = '', callId = '' }, ref) => {
    const { t } = useTranslation('');
    const { timelineCall } = useContext(SocketContext);
    const refVoicemailId = useRef(null);
    const refSaveNote = useRef('');

    useImperativeHandle(ref, () => ({
        setVoicemailDrop: _handleDropped
    }));

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.SAVE_CALL_NOTE, _handleSaveNote);
        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.SAVE_CALL_NOTE, _handleSaveNote);
        };
    }, []);

    useEffect(() => {
        if (!timelineCall) return;
        const { call_id, id: callLog } = timelineCall;
        if (typeEnd === TYPE_CALL_DISCONNECT.VOICEMAIL_DROP && call_id === callId) {
            _handleAddNote(callLog);
        }
    }, [timelineCall]);

    const _handleAddNote = (noteId) => {
        clientQuery(actionAddNoteCall(noteId), {
            data: {
                note: `${t('customers:left_vm')}${refSaveNote.current || ''}`,
                voicemail_id: refVoicemailId.current
            },
            method: 'PUT'
        });
    };

    const _handleSaveNote = ({ detail }) => {
        const { id, note = '' } = detail || {};
        if (id !== callId) return;
        refSaveNote.current = note;
    };

    const _handleDropped = ({ voicemailId }) => {
        refVoicemailId.current = voicemailId;
        dispatchEvent(
            new CustomEvent(CUSTOMER_CUSTOM_EVENTS.VOICEMAIL_DROPPED, {
                detail: { call_id: callId, voicemail: { id: voicemailId } }
            })
        );
    };
};

export default forwardRef(ServiceVoicemailDrop);
