import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GDModalWarning from 'app/components/modal/ModalWarning';
import GDStatusBar from 'app/components/status/statusbar';
import { PROFILE_AUTHENTICATION_ACTIVE_DEVICE } from 'app/config/routes';
import { getUrlDeleteSession, getUrlGetSessionList } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { handleAbortController } from 'app/modules/customer/utils';
import SessionUser from 'app/modules/settings/users/components/userform/components/SessionUser';
import { clientQuery } from 'common/utils/ApiUtils';
import LoadingSessionList from './LoadingSessionList';
import { showStatusBar } from 'common/utils/FunctionUtils';

const ProfileSessionUser = () => {
    const history = useHistory();
    const { t } = useTranslation('setting');
    const { id: userId } = useSelector(({ auth }) => auth.user.profile);
    const [state, dispatchState] = useReducer(reducer, { deviceItems: [], isLoading: true });
    const { deviceItems: finalDeviceItems, isLoading: finalIsLoading } = state;

    const abortControllerSession = useRef(null);
    const refWarning = useRef(null);
    const refStatusBar = useRef(null);

    useEffect(() => {
        _getSessionLimit();

        return () => {
            handleAbortController(abortControllerSession);
        };
    }, []);

    const _handleClickSession = () => {
        history.push(addBranchPath(PROFILE_AUTHENTICATION_ACTIVE_DEVICE));
    };

    const _openConfirmSignOut = (item) => {
        if (!item) return null;
        const { id, device_id, user_agent = {} } = item || {};
        const { platform } = user_agent;
        refWarning.current?._open({
            dataConfirm: { id, device_id },
            title: t('sign_out_confirm_notification', { device: platform })
        });
    };

    const _getSessionLimit = () => {
        handleAbortController(abortControllerSession);
        abortControllerSession.current = new AbortController();

        const _handleSuccess = ({ data = [] }) => {
            dispatchState({ deviceItems: data, isLoading: false });
        };

        const _handleFail = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ deviceItems: [], isLoading: false });
        };

        clientQuery(
            getUrlGetSessionList(userId),
            {
                method: 'GET',
                data: { limit: 5, offset: 0, active: 1 },
                abortController: abortControllerSession.current
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleSignOut = (_, dataConfirm) => {
        const { id: sessionId, device_id: deviceId } = dataConfirm || {};
        const _handleSuccess = () => {
            dispatchState((prev) => ({
                deviceItems: prev.deviceItems.map((item) => {
                    if (item.id === sessionId) return { ...item, active: 0 };
                    return item;
                })
            }));
            showStatusBar({
                id: 'sign_out_success',
                message: t('signed_out_success'),
                success: true,
                refAlert: refStatusBar
            });
            refWarning.current?._close();
        };
        const _handleFail = ({ message }) => {
            refWarning.current?._setStatusAlert(message);
        };

        clientQuery(
            getUrlDeleteSession({ userId, sessionId: sessionId }),
            { method: 'DELETE', data: { device_id: deviceId } },
            _handleSuccess,
            _handleFail
        );
    };

    return (
        <Fragment>
            <GDStatusBar ref={refStatusBar} />
            {finalIsLoading ? (
                <LoadingSessionList />
            ) : (
                <SessionUser
                    id="2fa"
                    deviceItems={finalDeviceItems}
                    onSignOut={_openConfirmSignOut}
                    onClickSession={_handleClickSession}
                />
            )}
            <GDModalWarning
                ref={refWarning}
                description={t('sign_out_confirm_message')}
                isLargeContent
                buttonSave
                titleButtonConfirm={t('sign_out')}
                onConfirm={_handleSignOut}
            />
        </Fragment>
    );
};

export default ProfileSessionUser;
