import IconTypes from 'assets/icon/IconTypes';
import React from 'react';

const AvatarAutoAttendant = ({ isCustom = false, isGroup = false, avatar }) => {
    if (isCustom) return null;

    return (
        <div className="avt-img">{isGroup ? <IconTypes isGroup /> : <img src={avatar} width={24} height={24} />}</div>
    );
};

export default AvatarAutoAttendant;
