import React from 'react';

const Loading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--booking loading">
                    <div className="boxs__header">
                        <div className="title flex-1">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines mb-5">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="rows">
                            <div className="rows__name flex-1">
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="switch loading --onefifth">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__name flex-1">
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="switch loading --onefifth">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__name flex-1">
                                <div className="loading --animation --line --fourth" />
                            </div>
                            <div className="switch loading --onefifth">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__name flex-1">
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="switch loading --onefifth">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--booking loading">
                    <div className="boxs__header border-bottom-border-color-grey">
                        <div className="title flex-1">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="v2-btn-default --grey loading btn-service">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents --service">
                        <div className="rows">
                            <div className="rows__title">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__multi">
                                <div className="price">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="hour loading --half">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="v2-dropdown --booking-menu">
                                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__title">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__multi">
                                <div className="price">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="hour loading --half">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="v2-dropdown --booking-menu">
                                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            </div>
                            <div className="description loading-lines">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__user">
                                <span className="avt-img loading" />
                                <span className="loading --animation --line --twothird" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__title">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__multi">
                                <div className="price">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="hour loading --half">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="v2-dropdown --booking-menu">
                                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            </div>
                            <div className="description loading-lines">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__title">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__multi">
                                <div className="price">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="hour loading --half">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="v2-dropdown --booking-menu">
                                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            </div>
                        </div>
                        <div className="rows">
                            <div className="rows__title">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__multi">
                                <div className="price">
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="hour loading --half">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="v2-dropdown --booking-menu">
                                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            </div>
                            <div className="description loading-lines">
                                <div className="loading --animation --line --full" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="rows__user">
                                <span className="avt-img loading" />
                                <span className="loading --animation --line --twothird" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loading;
