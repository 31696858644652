import {
    boxSize,
    generateDataSet,
    hideDot,
    tooltip,
    handleShowCursorLabelLegend
} from 'app/components/chart/line/chart';

export const configCompleteOrInComplete = ({ data, currency }) => {
    const datasets = generateDataSet({
        datasets:
            data?.[0]?.amount_due?.length === 1
                ? data?.map((item) => ({
                      ...item,
                      amount_due: [0, ...item.amount_due]
                  }))
                : data,
        isFillColor: false,
        isShowLabel: true,
        tension: 0.3
    });
    return {
        data: {
            labels: datasets?.[0]?.data?.map(() => 'Jobs'),
            datasets
        },
        options: {
            layout: {
                padding: {
                    left: -16,
                    right: 0,
                    top: 16,
                    bottom: 0
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    display: false
                },
                y: {
                    display: false,
                    beginAtZero: true
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        usePointStyle: true,
                        ...boxSize,
                        padding: 16
                    },
                    align: 'start',
                    ...handleShowCursorLabelLegend
                },
                tooltip: tooltip(currency)
            },
            elements: hideDot
        }
    };
};
