import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { ADDONS_QUICKBOOKS_SYNC_LOG } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import { ADDONS_API_KEYS, ADDONS_CUSTOMER_PORTAL, LIST_ICONS_ADDONS } from 'app/const/addons';
import { mixpanelManageAddons } from 'app/modules/mixpanel/MixpanelManageAddons';
import IconArrow from 'assets/icon/IconArrow';
import IconCogWheel from 'assets/icon/IconCogWheel';
import IconGrowth from 'assets/icon/IconGrowth';
import IconPlus from 'assets/icon/IconPlus';
import IconUpgrade from 'assets/icon/IconUpgrade';
import classNames from 'classnames';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SettingPortalAddons from '../portal/components/SettingPortalAddons';
import { ADDON_LEVEL } from '../constants';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsBoxHeader = (
    {
        keyword,
        name,
        status = 0,
        level = 1,
        isHideCheckbox = false,
        isMerchant = false,
        shouldConfirm = false,
        urlUpdateStatus = '',
        urlSetting = '',
        upgrade = 0,
        onUpdateStatusSuccess = null,
        isQuickBook = false,
        onDisableUpdate = () => {},
        onConfirmChange = () => {},
        handleCreateAPI
    },
    ref
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const refWrapper = useRef(null);
    const refCheckbox = useRef(null);
    const refStatus = useRef(!!status);
    const refSettings = useRef(null);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);

    useImperativeHandle(ref, () => ({
        _handleChangeStatus,
        _handleChangeImmediately
    }));

    const _handleChange = (checked) => {
        !isMerchant && _handleChangeStatus(checked ? 1 : 0);
    };

    const _handleSettingModal = () => refSettings.current._open();

    const _handleChangeStatus = (value, shouldSetStatusCheckbox = false) => {
        const _handleUpdateSuccess = ({ data }) => {
            handleTrackingEvent(mixpanelManageAddons({ id: profileId }));
            dispatch(updateAddonStatus({ keyword, data: value ? data[keyword.toLowerCase()] : 0 }));
            onUpdateStatusSuccess && onUpdateStatusSuccess(!!value);
        };

        if (shouldSetStatusCheckbox) {
            refCheckbox.current.setIsActive(!!value);
            _handleChangeImmediately(!!value);
        }
        clientQuery(urlUpdateStatus, { method: 'PUT', data: { value } }, _handleUpdateSuccess, checkAccessFail);
    };

    const _handleChangeImmediately = (checked) => {
        refStatus.current = checked;
        checked ? refWrapper.current.classList.add('is-active') : refWrapper.current.classList.remove('is-active');
    };

    const _handleClickChange = (e) => {
        if (isMerchant && !refStatus.current) {
            e.preventDefault();
            onDisableUpdate();
        }

        if (refStatus.current && isMerchant) {
            e.preventDefault();
            onConfirmChange();
        }

        if (shouldConfirm) {
            e.preventDefault();
            onConfirmChange(refStatus.current);
        }
    };

    return (
        <>
            <div ref={refWrapper} className={classNames('boxs__header page-heading', { 'is-active': !!status })}>
                <div className="header-title">
                    <span className="header-title__svg">{LIST_ICONS_ADDONS[keyword]}</span>
                    <div className="wrap-title">
                        <div className="title fs-15">{name}</div>
                        {level === ADDON_LEVEL.PRO && (
                            <div className="status-btn --has-icon --pro">
                                <IconUpgrade isFillWhite />
                                {t('pro')}
                            </div>
                        )}
                        {level === ADDON_LEVEL.GROWTH && (
                            <div className="status-btn --has-icon growth-plan">
                                <IconGrowth />
                                {t('growth')}
                            </div>
                        )}
                    </div>
                </div>

                {!!urlSetting && !!!upgrade && (
                    <Link
                        to={addBranchPath(urlSetting)}
                        className={classNames('v2-btn-default has-icon --grey mr-3', {
                            'is-disable': !refStatus.current
                        })}
                    >
                        <IconCogWheel />
                        {t('addons:settings')}
                    </Link>
                )}

                {keyword === ADDONS_API_KEYS && (
                    <div
                        className={classNames('v2-btn-main has-icon svg-white white mr-3 btn-modal', {
                            'is-disable': !!upgrade
                        })}
                        onClick={handleCreateAPI}
                    >
                        <IconPlus />
                        {t('addons:add_api_key')}
                    </div>
                )}

                {keyword === ADDONS_CUSTOMER_PORTAL && (
                    <div
                        className={classNames('v2-btn-default has-icon --grey btn-modal mr-2', {
                            'is-disable': !refStatus.current
                        })}
                        onClick={_handleSettingModal}
                    >
                        <IconCogWheel />
                        {t('addons:settings')}
                    </div>
                )}

                {!!isQuickBook && (
                    <Link
                        to={addBranchPath(ADDONS_QUICKBOOKS_SYNC_LOG)}
                        className="v2-btn-default --grey --icon-r mr-2"
                    >
                        QB Sync
                        <IconArrow />
                    </Link>
                )}

                {!!!upgrade && !isHideCheckbox && (
                    <GdSwitchCheckbox
                        id={`checkbox_addons_${keyword}`}
                        ref={refCheckbox}
                        textChecked={t('on')}
                        textUnChecked={t('off')}
                        defaultChecked={!!status}
                        inputClassName="toggle toggle-round"
                        onChange={_handleChange}
                        onChangeImmediately={_handleChangeImmediately}
                        onClickLabel={_handleClickChange}
                        isEffectDefaultChecked
                    />
                )}
                {!!upgrade && level === ADDON_LEVEL.PRO && (
                    <Link to={addBranchPath(SETTINGS_PLANS)} className="btn-upgrage white">
                        <IconUpgrade isFillWhite />
                        {t('upgrade_pro')}
                    </Link>
                )}
                {!!upgrade && level === ADDON_LEVEL.GROWTH && (
                    <Link to={addBranchPath(SETTINGS_PLANS)} className="v2-btn-main bg-green-default has-icon white">
                        <IconGrowth />
                        {t('upgrade_growth')}
                    </Link>
                )}
            </div>
            {keyword === ADDONS_CUSTOMER_PORTAL && <SettingPortalAddons ref={refSettings} />}
        </>
    );
};

AddonsBoxHeader.displayName = 'AddonsBoxHeader';
export default forwardRef(AddonsBoxHeader);
