import { getUrlCustomerContact, getUrlCustomerLocation } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import LocationBilling from './LocationBilling';
import LocationServices from './LocationServices';

const LocationContent = ({ dataUpdate = {}, customer = {}, isUpdate = false, customerId = '' }, ref) => {
    const addressDefault = dataUpdate?.address_contact?.address_to || '';
    const billingDefault = dataUpdate?.address_contact?.bill_to || '';
    const [state, dispatchState] = useReducer(reducer, {
        customers: [customer || {}],
        addressSelected: isUpdate ? { id: addressDefault, full_name: addressDefault } : customer || {},
        billingSelected: isUpdate ? { id: billingDefault, full_name: billingDefault } : customer || {},
        isSame: isUpdate ? !!dataUpdate.same_billing_location : true,
        isFetching: true,
        billingAddressOptions: []
    });
    const refLocationServices = useRef(null);
    const refLocationBilling = useRef(null);
    const refFirstTime = useRef(true);
    const refFetchAddress = useRef(true);
    const refIsSame = useRef(true);
    const { customers, addressSelected, billingSelected, isSame, isFetching } = state;

    useImperativeHandle(ref, () => ({
        _getValue,
        _updateTagGlobal: () => refLocationBilling.current._updateTagGlobal()
    }));

    useEffect(() => {
        refIsSame.current = isSame;
    }, [isSame]);

    const _getValue = () => {
        return {
            address_to: addressSelected.full_name,
            same_billing_location: isSame,
            ...refLocationServices.current._getValue(),
            ...refLocationBilling.current._getValue()
        };
    };

    const _handleGetCustomerSimply = () => {
        if (!refFirstTime.current) return;

        const _handleGetSuccess = ({ data }) => {
            const customerData = { ...customer, id: `${customer?.id}_main` };
            const customers = data.map((item) => ({ ...item, full_name: `${item.first_name} ${item.last_name}` }));
            const addressSelected = isUpdate
                ? customers.find((item) => item.full_name.trim() === dataUpdate.address_contact.address_to.trim()) ||
                  customerData
                : customerData || customers[0];
            const billingSelected = isUpdate
                ? customers.find((item) => item.full_name.trim() === dataUpdate.address_contact.bill_to.trim()) ||
                  customerData
                : customerData || customers[0];

            refFirstTime.current = false;
            dispatchState({
                customers: [customerData, ...customers],
                addressSelected,
                billingSelected,
                isFetching: false
            });
        };

        clientQuery(
            getUrlCustomerContact(customer?.id || ''),
            { method: 'GET', data: { inc: 'company' } },
            _handleGetSuccess
        );
    };

    const _handleCheckSame = (value) => {
        dispatchState({ isSame: value });
    };

    const _handleSelectAddress = (id) => {
        const newSelected = customers.find((item) => item.id === id);
        const newState = { addressSelected: newSelected };
        if (isSame) newState['billingSelected'] = newSelected;
        dispatchState(newState);
    };

    const _handleSelectBilling = (id) => {
        dispatchState({ billingSelected: customers.find((item) => item.id === id), isSame: false });
    };

    const _handleSetSameAddress = (event) => {
        const newState = { isSame: event.target.checked };
        if (!isSame) newState['billingSelected'] = addressSelected;
        dispatchState((prevState) => ({ ...prevState, ...newState }));
        _handleServiceChange(null, refLocationServices.current._handleGetDataAddress(), true);
    };

    // eslint-disable-next-line no-unused-vars
    const _handleServiceChange = (name, newValue, isForceSet = false) => {
        delete newValue['country'];
        delete newValue['county'];
        if (refIsSame.current || isForceSet) refLocationBilling.current._setValueForm(name, newValue);
    };

    const _handleGetBillingAddress = () => {
        if (!refFetchAddress.current) return;

        clientQuery(
            getUrlCustomerLocation(customer?.id || customerId),
            { data: {}, method: 'GET' },
            _getBillingAddressSuccess
        );
    };

    const _getBillingAddressSuccess = ({ data }) => {
        refFetchAddress.current = false;
        dispatchState({ billingAddressOptions: data });
    };

    return (
        <div className="column-content left-column">
            <div className="w-100 d-flex">
                <LocationServices
                    ref={refLocationServices}
                    options={customers}
                    selected={addressSelected}
                    customerId={customer?.id}
                    dataUpdate={dataUpdate}
                    isUpdate={isUpdate}
                    isLoading={isFetching}
                    onSelect={_handleSelectAddress}
                    onVisible={_handleGetCustomerSimply}
                    onChange={_handleServiceChange}
                />
                <LocationBilling
                    ref={refLocationBilling}
                    customerId={customer?.id}
                    options={customers}
                    selected={billingSelected}
                    dataUpdate={dataUpdate}
                    isUpdate={isUpdate}
                    isLoading={isFetching}
                    isSame={isSame}
                    onSelect={_handleSelectBilling}
                    onVisible={_handleGetCustomerSimply}
                    onSetSameAddress={_handleSetSameAddress}
                    onHandleGetBillingAddress={_handleGetBillingAddress}
                    billingAddressOptions={state.billingAddressOptions}
                    isLoadingAddress={refFetchAddress.current}
                    onHandleCheckSame={_handleCheckSame}
                />
            </div>
        </div>
    );
};

export default forwardRef(LocationContent);
