import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconClose from 'assets/icon/IconClose';
import IconLoading from 'assets/icon/IconLoading';
import IconUser from 'assets/icon/IconUser';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

export default function FirstName({ isLoadingContact, isFetchedContact, dataContact, onFetch, totalContact }) {
    const { t } = useTranslation('common');
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentUnitFirstName = unitDetailContextData.first_name;

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        value: parentUnitFirstName
    });

    const finalFirstName = state.value;
    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (!isFetchedContact && finalIsVisible) {
            onFetch(0, 1);
        }
    }, [finalIsVisible]);

    useEffect(() => {
        parentUnitFirstName !== finalFirstName && setState({ value: parentUnitFirstName });
    }, [parentUnitFirstName]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_email_contact_customer');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_email_contact_customer');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleChangeValue(e) {
        const finalValue = e.target.value;
        setState({
            value: finalValue,
            isVisible: false
        });
        updateUnitDetailContextData({
            first_name: finalValue
        });
    }

    function _handleOnScrollContent(e) {
        if (
            !isLoadingContact &&
            isScrollToEndBottom(e.target) &&
            totalContact !== 0 &&
            totalContact > dataContact.length - 1
        ) {
            onFetch(null, 1);
        }
    }

    function _handleSelectContact(e, contact) {
        let newListPhones = [...contact.phones];
        newListPhones = newListPhones.map((item) => {
            return {
                number: item.phone,
                type: item.phone_type_id,
                name: item.phone_type
            };
        });

        updateUnitDetailContextData({
            first_name: contact.first_name || '',
            last_name: contact.last_name || '',
            email: contact.email || '',
            phone: newListPhones,
            refreshUinitDetail: Date.now()
        });
        _closeSearchResult();
    }

    function _renderListContact() {
        if (dataContact.length === 0 && !isLoadingContact) {
            return (
                <li className="items justify-center pointer-events-none">
                    <div className="loading -ajaxbar">{t('no_data_to_display', { title: t('contacts') })}</div>
                </li>
            );
        }

        if (isLoadingContact) {
            return (
                <li className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </li>
            );
        }

        return dataContact.map((item) => {
            return (
                <li onClick={(e) => _handleSelectContact(e, item)} key={item.id} className="items">
                    <span className="txt-ellipsis">{`${item.first_name} ${item.last_name}`}</span>
                </li>
            );
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconUser />
                First Name
            </div>
            <div className="rows__form">
                <div ref={refDropdown} className={`${finalIsVisible ? 'active' : ''} v2-dropdown`}>
                    <div onClick={(e) => _handleOpen(e)} className="dropbtn items">
                        <div className="search-input">
                            <input
                                type="text"
                                placeholder="First name"
                                value={finalFirstName}
                                onChange={(e) => _handleChangeValue(e)}
                            />
                            <div className="close-icon dp-hide">
                                <IconClose />
                            </div>
                        </div>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </div>
                    <div className="v2-dropdown__menu scrolls" onScroll={_handleOnScrollContent}>
                        <ul id={'show_list_email_contact_customer'}>{_renderListContact()}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
