export const TYPE_TAXES = {
    ACTION_GET_LIST_TAXES: 'ACTION_GET_LIST_TAXES',
    ACTION_GET_LIST_TAXES_SUCCESS: 'ACTION_GET_LIST_TAXES_SUCCESS',
    ACTION_ADD_NEW_ITEM: 'ACTION_ADD_NEW_ITEM',
    ACTION_ADD_LIST_TAXES: 'ACTION_ADD_LIST_TAXES',
    ACTION_REMOVE_LIST_TAXES: 'ACTION_REMOVE_LIST_TAXES',
    ACTION_UPDATE_TAX_ITEM: 'ACTION_UPDATE_TAX_ITEM'
};

// actions
export const getListTaxes = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_TAXES.ACTION_GET_LIST_TAXES,
        params,
        actionSuccess,
        actionFailed
    };
};

export const setListTaxesReducer = (payload) => {
    return {
        type: TYPE_TAXES.ACTION_GET_LIST_TAXES_SUCCESS,
        payload
    };
};
