import React from 'react';
import { useSelector } from 'react-redux';

import GdGridView from 'app/components/grid/GdGridView';
import { COMMON } from 'app/const/App';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { SMART_VIEW_ACTIONS } from '../../constants';
import { useSmartView } from '../../context';
import {
    COLUMNS_GROUP_CALLS,
    COLUMNS_GROUP_SMS,
    DEFAULT_COLUMNS,
    convertResponseSmartView,
    getColumnsCustomFields,
    getColumnsCustomJobStatus,
    getGridColumns
} from './utils';

const SmartViewTable = ({ isLoading = true }) => {
    const format = useSelector(({ auth }) => auth.user.company.date_format);
    const isActivateSMS = useSelector(({ auth }) => auth.user.settings.addons.smsplivo);
    const settings = useSelector(({ auth }) => auth.user.settings);
    const customFields = useSelector(({ customFields }) => customFields.data);
    const isActivateVoip = checkIsEnableVoip(settings, false);

    const {
        loading,
        isLoadingMore,
        sort,
        data,
        columns: columnsData,
        handleFetchMore,
        handleUpdateSort
    } = useSmartView();
    const currentLoading = isLoading || loading;

    const getColumnsWithPermission = () => {
        let results = columnsData || DEFAULT_COLUMNS;
        const excludedColumns = [];
        if (!isActivateSMS) excludedColumns.push(...COLUMNS_GROUP_SMS);
        if (!isActivateVoip) excludedColumns.push(...COLUMNS_GROUP_CALLS);
        results = results.filter((item) => !excludedColumns.includes(item));
        return results;
    };

    const columns = React.useMemo(() => {
        const result = { header: {}, columns: [], contentConfig: {} };
        const listWithPermission = getColumnsWithPermission();
        const dataGet = { format: convertPhpFormatToMoment(format), timezone: settings.timezone, sort };

        const gridDefault = getGridColumns(listWithPermission, dataGet);
        const gridCustomFields = getColumnsCustomFields(columnsData, customFields, dataGet);
        const gridCustomJobStatuses = getColumnsCustomJobStatus(columnsData, dataGet);
        [gridDefault, gridCustomFields, gridCustomJobStatuses].forEach((item) => {
            result['header'] = { ...result['header'], ...item['header'] };
            result['columns'] = [...result['columns'], ...item['columns']];
            result['contentConfig'] = { ...result['contentConfig'], ...item['contentConfig'] };
        });
        return result;
    }, [data, sort, columnsData]);

    const _handleHeaderClick = ({ objectType, actionType, currentValue, keyGetDirection, columnsTarget }) => {
        switch (actionType) {
            case COMMON.ORDER:
                handleUpdateSort({
                    type: SMART_VIEW_ACTIONS.SORT_SET_SINGLE,
                    payload: {
                        objectType,
                        direction: currentValue,
                        fieldId: keyGetDirection || columnsTarget
                    }
                });
                break;
            default:
                break;
        }
    };

    // Should be in useMemo because for now multiple calculation in this function
    const dataConverted = React.useMemo(() => convertResponseSmartView(data), [data]);
    return (
        <GdGridView
            isEmptyFlat
            showCheckBox={false}
            content={dataConverted}
            isLoading={currentLoading}
            isLoadmore={isLoadingMore && !currentLoading}
            classTable="has-text-ellipsis table-multi-column scrolls-x"
            fileTranslation="smartView"
            onScrollToEnd={handleFetchMore}
            handleClickHeader={_handleHeaderClick}
            isScroll
            {...columns}
        />
    );
};

export default SmartViewTable;
