import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { reducer } from 'app/const/Reducer';
import { cardLogs } from 'app/const/api/V2';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { clientQuery } from 'common/utils/ApiUtils';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const LogsCard = ({ id }) => {
    const { id: customerId } = useParams();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isLoading: true });
    const { isOpen, isLoading } = state;
    const refFirstTime = useRef(true);

    function _handleExpand() {
        if (!isOpen && refFirstTime.current) _getLogs();
        dispatchState({ isOpen: !isOpen });
    }

    function _getLogs() {
        function _getLogsSuccess({ data = [] }) {
            refFirstTime.current = false;
            dispatchState((prevState) => ({
                ...prevState,
                data,
                isLoading: false
            }));
        }

        function _getLogsFailed() {}

        clientQuery(cardLogs(customerId, id), {}, _getLogsSuccess, _getLogsFailed);
    }

    return (
        <div className={classNames('stripe-additional__logs', { active: isOpen })}>
            <div className="logs-header flex-betweenitems" onClick={_handleExpand}>
                Show All Logs
                <span className="toggle-icon">
                    <IconArrowDown />
                </span>
            </div>
            {isLoading && isOpen ? <LoadingCard /> : null}
            {!isLoading && isOpen ? <LogList logs={state.data} /> : null}
        </div>
    );
};

function LogList({ logs = [] }) {
    if (!logs.length) return <GridEmpty />;
    return (
        <div className="logs-content fs-13 scrolls">
            {logs.map((log) => (
                <LogItem key={log.id} {...log} />
            ))}
        </div>
    );
}

function LogItem({ date, log, full_name }) {
    return (
        <div className="logs-item flex-betweentop p-2">
            <div className="item-content">
                <span className="txt-log mr-1">{log}</span>
                {!!full_name ? (
                    <div className="avt-name">
                        <span className="txt-ellipsis mx-1">{full_name}</span>
                    </div>
                ) : null}
            </div>
            <p className="grey-generic">{date}</p>
        </div>
    );
}

function LoadingCard() {
    return (
        <div className="border-top-border-color-grey">
            <div className="logs-item flex-betweenitems p-2 gap-8">
                <div className="item-content flexcenter gap-4">
                    <div className="loading --animation --line --half"></div>
                    <div className="loading --animation --line --half mt-0"></div>
                </div>
                <div className="loading --animation --line --half"></div>
            </div>
            <div className="logs-item flex-betweenitems p-2 gap-8">
                <div className="item-content flexcenter gap-4">
                    <div className="loading --animation --line --half"></div>
                    <div className="loading --animation --line --half mt-0"></div>
                </div>
                <div className="loading --animation --line --half"></div>
            </div>
        </div>
    );
}

export default LogsCard;
