export const TYPE_REPORT_DOCUMENT = {
    GET_LIST: 'GET_LIST_REPORT_DOCUMENT',
    DELETE_REQUEST: 'DELETE_REPORT_DOCUMENT_REQUEST',
    UNDELETE_REQUEST: 'UNDELETE_REPORT_DOCUMENT_REQUEST',
    TRASH_REQUEST: 'TRASH_REQUEST',
    GET_LIST_TYPE_FILTER_REQUEST: 'GET_LIST_TYPE_FILTER_REQUEST'
};

// actions
export const getListReportDocument = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_DOCUMENT.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListTypeFilterRequest = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_DOCUMENT.GET_LIST_TYPE_FILTER_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
export const deleteReportDocumentRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_DOCUMENT.DELETE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const unDeleteReportDocumentRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_DOCUMENT.UNDELETE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const trashReportDocumentRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_DOCUMENT.TRASH_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
