import ButtonSave from 'app/components/button/ButtonSave';
import { updateSettingsNumberVoip } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from 'assets/icon/IconClose';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import ReactModal from 'react-modal';
import { AssignGroupMember } from './AssignGroupMember';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { actionTriggerLoadNumbers } from 'common/redux/actions/voipAction';
import { useDispatch } from 'react-redux';

const ModalWarningDelete = ({ onSuccess = () => {}, onFail = () => {}, onCancel = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const refButtonSaveWarning = useRef(null);
    const dispatch = useDispatch(null);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false
    });

    const { isOpen, id, phone_number, assignees } = state;

    useImperativeHandle(ref, () => ({
        onDelete: _handleWarningDelete
    }));

    const _handleCloseWarning = () => {
        onCancel();
        refButtonSaveWarning.current.setLoading(false);
        dispatchState({ isOpen: false });
    };

    const _handleDeleteNumber = () => {
        refButtonSaveWarning.current.setLoading(true);
        const _handleSuccess = () => {
            onSuccess(id);
            _handleCloseWarning();
            dispatch(actionTriggerLoadNumbers());
        };
        const _handleOnFail = (err) => {
            _handleCloseWarning();
            onFail(err);
        };
        clientQuery(updateSettingsNumberVoip(id), { method: 'DELETE' }, _handleSuccess, _handleOnFail);
    };

    const _handleWarningDelete = (data) => {
        dispatchState({ isOpen: true, ...data });
    };

    const _renderContent = () => {
        const length = assignees.length;
        return (
            <div className="body-modal text-center black-eerie-dark">
                <h1>{formatPhoneNumberVoip(phone_number)}</h1>
                <div className="my-1">
                    <p className={classNames({ 'grey-generic': !length })}>{`${t('assigned')} ${length} ${
                        length <= 1 ? t('one_user') : t('more_user')
                    }`}</p>
                    <AssignGroupMember
                        wrapperClassMembers="wrap-member-group flex-centeritem"
                        wrapperClassName=""
                        isShowButtonManage={false}
                        item={state}
                    />
                </div>
                <p className="mt-3">{t('addons:desc_delete_phone_number')}</p>
            </div>
        );
    };

    if (!isOpen) return null;

    return (
        <ReactModal isOpen={isOpen} className="modal container-modal open">
            <div className="modal__overlay bg-fixed" onClick={_handleCloseWarning} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('delete_phone_number')}</h3>
                    <span className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseWarning}>
                        <IconClose />
                    </span>
                </div>
                {_renderContent()}
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                        {t('cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSaveWarning}
                        title={t('delete_number')}
                        wrapClass="v2-btn-default bg-red-default white ml-2"
                        onSave={_handleDeleteNumber}
                    />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalWarningDelete);
