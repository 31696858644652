import { FIREBASE_CONFIG, LOGIN_TYPE } from 'app/const/Auth';
import IconFaceBook from 'assets/icon/IconFaceBook';
import IconGoogle from 'assets/icon/IconGoogle';
import firebase from 'firebase/app';
import 'firebase/auth';
import PropTypes from 'prop-types';
import React from 'react';

firebase.initializeApp(FIREBASE_CONFIG);

function FormLoginSocial({ onLoginSocial, t }) {
    const loginWithGoogle = () => {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        googleProvider.setCustomParameters({ prompt: 'select_account' });
        googleProvider.addScope('email');
        firebase
            .auth()
            .signInWithPopup(googleProvider)
            .then((result) => {
                const profile = result.additionalUserInfo.profile;
                const postData = {
                    access_token: profile.id,
                    type: LOGIN_TYPE.google,
                    email: profile.email,
                    first_name: profile.given_name,
                    last_name: profile.family_name
                };
                onLoginSocial(postData);
            })
            .catch(() => {});
    };
    const loginWithFaceBook = () => {
        const facebookProvider = new firebase.auth.FacebookAuthProvider();
        firebase
            .auth()
            .signInWithPopup(facebookProvider)
            .then((result) => {
                const profile = result.additionalUserInfo.profile;
                const postData = {
                    access_token: profile.id,
                    type: LOGIN_TYPE.facebook,
                    email: profile.email,
                    first_name: profile.first_name,
                    last_name: profile.last_name
                };
                onLoginSocial(postData);
            })
            .catch(() => {});
    };

    return (
        <div className="login-action">
            <div className="login-action-btn --fb" onClick={loginWithFaceBook}>
                <div className="login-action-btn__icon">
                    <IconFaceBook isCenter />
                </div>
                <div className="login-action-btn__label">{t('common:login_with_facebook')}</div>
            </div>
            <div className="login-action-btn --gg" onClick={loginWithGoogle}>
                <div className="login-action-btn__icon">
                    <IconGoogle />
                </div>
                <div className="login-action-btn__label">{t('common:login_with_google')}</div>
            </div>
        </div>
    );
}

FormLoginSocial.propTypes = {
    onLoginSocial: PropTypes.func
};

export default FormLoginSocial;
