import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import { SETTINGS_SOURCES } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';

const AddSourceModal = ({ onAddSuccess = () => {} }, ref) => {
    const { t } = useTranslation(['customers']);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const refInput = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSubmit = () => {
        const value = refInput.current.value;

        const _handleSubmitFailed = ({ message = '' }) => {
            refStatusBar.current?.showStatusBar('show_error', message, LIST_STATUS.ERROR);
            refButtonSave.current?.removeLoading();
        };

        if (!value) {
            _handleSubmitFailed({ message: t('customers:cannot_be_blank', { name: t('customers:source_name') }) });
            return;
        }

        clientQuery(
            SETTINGS_SOURCES,
            { data: { name: value }, toFormData: false, method: 'POST' },
            _handleSubmitSuccess,
            _handleSubmitFailed
        );
    };

    const _handleSubmitSuccess = ({ data }) => {
        onAddSuccess(data);
        _close();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            isOpen={state.isOpen}
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --form-no-border open"
            onRequestClose={_close}
            portalClassName="ReactModalPortal_customer"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --md">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:add_new_source')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <StatusBar ref={refStatusBar} />
                <div className="body-modal has-form">
                    <div className="has-form__label">{t('customers:source_name')}</div>
                    <input
                        ref={refInput}
                        type="text"
                        className="field-input"
                        placeholder={t('customers:add_source')}
                        autoFocus
                    />
                </div>
                <div className="footer-modal btn-close">
                    <div className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                        {t('customers:cancel')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        className="v2-btn-main"
                        title={t('customers:add')}
                        onSave={_handleSubmit}
                    />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(AddSourceModal);
