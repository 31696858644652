export const SEARCH_TYPES = {
  SEARCH_CUSTOMERS: "SEARCH_CUSTOMERS"
};

// actions
export const customerSearchRequest = (request, onSuccess, onFailed) => {
  return {
    type: SEARCH_TYPES.SEARCH_CUSTOMERS,
    request,
    onSuccess,
    onFailed,
  };
};

