import useClickOutside from 'common/hooks/useClickOutside';
import { getListVariable } from 'common/redux/actions/drip/messageTemplatesAction';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import IconPlus from 'assets/icon/IconPlus';

function InsertVariableButton({ classDrop, onSelect }) {
    const { t } = useTranslation(['drip']);
    const dispatch = useDispatch();

    const variablesStore = useSelector((state) => state.drip?.variables);
    const [refBtn, isOpen, setIsOpen] = useClickOutside(false);

    const handleGetListVariables = () => {
        dispatch(
            getListVariable(
                {},
                () => {},
                () => {}
            )
        );
    };

    const handleOpen = () => {
        !isOpen && !variablesStore && handleGetListVariables();
        setIsOpen(!isOpen);
    };

    const handleSelect = (id) => {
        onSelect(id);
    };

    const renderListVariable = () => {
        if (!Array.isArray(variablesStore)) return null;

        return variablesStore.map((item) => {
            return (
                <li
                    key={item.id}
                    className="items"
                    onClick={() => {
                        handleSelect(item.variable);
                    }}
                    dangerouslySetInnerHTML={{ __html: item.name }}
                ></li>
            );
        });
    };

    return (
        <div className={`${classDrop} v2-dropdown--variable ${isOpen && 'active'}`}>
            <div ref={refBtn} className="dropbtn v2-btn-default has-icon" onClick={handleOpen}>
                <IconPlus />
                {t(`drip:insert_variable`)}
            </div>

            <div className="v2-dropdown__menu scrolls">
                <ul>{renderListVariable()}</ul>
            </div>
        </div>
    );
}

InsertVariableButton.propTypes = {
    classDrop: PropTypes.string,
    onSelect: PropTypes.func
};

InsertVariableButton.defaultProps = {
    classDrop: 'v2-dropdown',
    onSelect: () => {}
};

export default InsertVariableButton;
