import React, { useReducer, useEffect, useRef } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import TimeWindowDateItem from './components/TimeWindowAddonsContent';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { ADDONS_TIME_WINDOW, UPDATE_ADDONS_TIME_WINDOW_OPTIONS } from 'app/const/api/V2';
import TimeWindowAddonsTemplates from './components/TimeWindowAddonsTemplates';
import { reducer } from 'app/const/Reducer';
import { TimeWindowAddonsLoading } from './components/TimeWindowAddonsLoading';
import { useSelector } from 'react-redux';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AddonsOptions from '../components/AddonsOptions';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsTimeWindow = () => {
    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        status: false,
        isLoading: true
    });
    const { profile } = useSelector((state) => state.auth.user);
    const refAlert = useRef(null);
    const { isLoading, data } = state;
    const { isBasicPlan } = getPlanUser(profile);

    useEffect(() => {
        _getDetailTimeWindow();
    }, []);

    const _getDetailTimeWindow = () => {
        clientQuery(ADDONS_TIME_WINDOW, { method: 'GET' }, _handleGetTimeSuccess, checkAccessFail);
    };

    const _handleGetTimeSuccess = ({ data }) => {
        dispatchState({ data: data, isLoading: false, status: data.status });
    };

    const _handleUpdateSuccess = (isEnable) => {
        dispatchState({ status: isEnable });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <TimeWindowAddonsLoading />
                    ) : (
                        <>
                            <TimeWindowDateItem {...data} onHandleUpdateSuccess={_handleUpdateSuccess} />
                            {!isBasicPlan && <TimeWindowAddonsTemplates isDisable={!state.status} />}
                            <AddonsOptions data={data.options} urlUpdate={UPDATE_ADDONS_TIME_WINDOW_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsTimeWindow;
