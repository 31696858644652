import { addBranchPath } from 'app/const/Branch';
import { CUSTOMERS } from 'app/const/Route';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function TaskItemTags({ customer = null, job = null }) {
    const dispatch = useDispatch();

    const _openJobDetail = () => {
        dispatch(actionOpenJobDetail({ id: job.id }));
    };

    return (
        <div className="box-task__list-tags">
            {customer && (
                <Link to={addBranchPath(`${CUSTOMERS}/${customer?.id}`)} className="tag-label">
                    {customer.full_name}
                </Link>
            )}
            {job?.name && (
                <div className="tag-label" onClick={_openJobDetail}>
                    {job.name}
                </div>
            )}
        </div>
    );
}

TaskItemTags.propTypes = {};

export default TaskItemTags;
