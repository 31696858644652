import { AUTH_LOGIN, CALENDAR } from 'app/const/Route';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, Redirect, useLocation } from 'react-router-dom';
import GdLazyImage from 'app/components/lazyimage';
import { LINK_CDN_IMG } from 'app/const/URL';
import 'app/modules/auth/css/opp.scss';
import { useTranslation } from 'react-i18next';
import IconArrow from 'assets/icon/IconArrow';
import { AUTHENTICATE_ROUTES } from 'app/config/router';
import { addBranchPath } from 'app/const/Branch';

const NotFoundPage = () => {
    const { t } = useTranslation(['common']);
    const location = useLocation();
    const { user } = useSelector((state) => state.auth);
    if (!user) return <Redirect to={AUTH_LOGIN} />;

    if (AUTHENTICATE_ROUTES[0].routes.some((item) => item.path === location.pathname))
        return <Redirect to={CALENDAR} />;

    return (
        <div className="c-error">
            <div className="container">
                <h2 className="title">{t('common:oops')}!</h2>
                <div className="container__wrap">
                    <p className="label">{t('common:sorry_the_page_not_found')}</p>
                    <Link to={addBranchPath(CALENDAR)}>
                        <span className="v2-btn-default --transparent --icon-r">
                            {t('common:go_to_calendar')} <IconArrow />
                        </span>
                    </Link>
                </div>
            </div>
            <div className="logo">
                <GdLazyImage
                    src={`${LINK_CDN_IMG}bottom_logo.png`}
                    width={143}
                    height={143}
                    alt={t('common:gorilladesk')}
                />
            </div>
        </div>
    );
};

export default NotFoundPage;
