export const CUSTOM_FIELDS_ACTIONS = {
    GET_CUSTOM_FIELDS: 'GET_CUSTOM_FIELDS',
    GET_CUSTOM_FIELDS_SUCCESS: 'GET_CUSTOM_FIELDS_SUCCESS',
    GET_CUSTOM_FIELDS_FAILED: 'GET_CUSTOM_FIELDS_FAILED',

    // Add custom field
    ADD_CUSTOM_FIELD: 'ADD_CUSTOM_FIELD',
    ADD_CUSTOM_FIELD_SUCCESS: 'ADD_CUSTOM_FIELD_SUCCESS',
    ADD_CUSTOM_FIELD_FAILED: 'ADD_CUSTOM_FIELD_FAILED',
    // Edit custom field
    EDIT_CUSTOM_FIELD: 'EDIT_CUSTOM_FIELD',
    EDIT_CUSTOM_FIELD_SUCCESS: 'EDIT_CUSTOM_FIELD_SUCCESS',
    EDIT_CUSTOM_FIELD_FAILED: 'EDIT_CUSTOM_FIELD_FAILED',
    // Reorder custom field
    REORDER_CUSTOM_FIELD: 'REORDER_CUSTOM_FIELD',
    // Reset custom field
    RESET_CUSTOM_FIELD: 'RESET_CUSTOM_FIELD'
};

export const actionAddCustomField = ({ data, callbackSuccess = () => {}, callbackFail = () => {} }) => {
    return { type: CUSTOM_FIELDS_ACTIONS.ADD_CUSTOM_FIELD, payload: { data, callbackSuccess, callbackFail } };
};
export const actionEditCustomField = ({ data, callbackSuccess = () => {}, callbackFail = () => {} }) => {
    return { type: CUSTOM_FIELDS_ACTIONS.EDIT_CUSTOM_FIELD, payload: { data, callbackSuccess, callbackFail } };
};
