export const getStyles = (isVerticalMode, classTimeLine, isMonthView) => {
    let finalStyle = {
        evenTitleCommon: {
            display: 'flex',
            alignItems: 'center',
            borderRadius: '2px',
            fontSize: '10px',
            lineHeight: '10px',
            backgroundColor: 'inherit',
            paddingLeft: '1px',
            whiteSpace: 'nowrap',
            fontFamily: 'Arial, Helvetica, sans-serif',
            zIndex: '1',
            height: 'auto'
        },
        evenContentCommon: {
            flex: '1 1',
            lineHeight: '1.15',
            fontSize: '11px',
            color: 'inherit',
            paddingLeft: '1px',
            fontFamily: 'Arial, Helvetica, sans-serif',
            overflow: 'hidden',
            zIndex: '1'
        },
        icLoading: { margin: '0 8px 0 5px', display: 'none' },
        iconCommon: {
            cursor: 'pointer'
        },
        icon: {
            width: '13px',
            height: '13px',
            borderRadius: '50%',
            backgroundColor: '#fff',
            marginRight: '1px'
        },
        iconSvg: {
            marginTop: '-1px',
            marginLeft: '-1px'
        },
        iconSMS: {
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            backgroundColor: '#00aadd',
            position: 'absolute',
            top: '2px',
            right: '2px',
            cursor: 'pointer'
        },
        nameCommon: {
            overflow: 'hidden',
            flex: '1 1',
            padding: '1px 0px',
            fontWeight: isMonthView ? '600' : '400'
        }
    };

    if (!isVerticalMode) {
        switch (classTimeLine) {
            case 'small-tasks':
                finalStyle = {
                    ...finalStyle,
                    eventJob: {
                        height: '32px'
                    }
                };
                break;
            case 'regular-tasks':
                finalStyle = {
                    ...finalStyle,
                    eventJob: {
                        height: '67px'
                    }
                };
                break;
            case 'large-tasks':
                finalStyle = {
                    ...finalStyle,
                    eventJob: {
                        height: '150px'
                    }
                };
                break;
            default:
                break;
        }
    }

    return finalStyle;
};

export const RESOURCE_EVENT_STYLE = {
    colHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#191919',
        overflow: 'hidden'
    },
    colHeaderDate: {
        marginRight: '4px',
        fontWeight: '600'
    },
    schedulesWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30px',
        padding: '0px 2px',
        cursor: 'pointer'
    },
    scheduleName: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '13px'
    },
    todayBgColor: {
        backgroundColor: '#1e7ef714'
    }
};
