import React, { Fragment, useRef } from 'react';
import IconCalendarPin from 'assets/icon/IconCalendarPin';
import JobMagnetModal from './components/magnet';
import Header from './header';
import MainCalendar from './MainCalendar';
import Map from './map';
import Timeoff from './timeoff';
import { convertEventToDataMarker } from './ultil/Map';

const CalendarContainer = () => {
    const refMainCalendar = useRef(null);
    const refWrapperDiv = useRef(null);
    const refMapView = useRef(null);
    const refJobMagnetModal = useRef(null);

    const _goTodate = (date) => {
        refMainCalendar.current.onGoToDate(date);
    };

    const _getTitle = () => refMainCalendar?.current?.onGetTitle() || '';
    const _nextAgenda = () => refMainCalendar.current.onNext();
    const _prevAgenda = () => refMainCalendar.current.onPrev();
    const _onToday = (shouldFetch = true) => refMainCalendar.current.onToday(shouldFetch);
    const _toggleMap = (isClose = false) => {
        isClose ? refMapView.current._openMap() : refMapView.current._closeMap();
        setTimeout(() => {
            refMainCalendar.current && refMainCalendar.current.onResizeCalendar();
        }, 100);
    };
    const _getCurrentRange = () => refMainCalendar.current.onGetRangeDate();

    const _changeView = () => refMainCalendar.current.onChangeView();

    const _reloadEventsOptimized = () => refMainCalendar.current.reloadEventsOptimized();

    const _handleUpdateEvents = (...rest) => {
        refMapView.current._handleUpdateEvents(...rest);
    };

    const _handleRefetchCalendar = () => {
        refMainCalendar.current.onRefetch();
    };

    const _handleSelectMarker = (...rest) => {
        refMainCalendar.current.onSelectMarker(...rest);
    };

    const _handleResizeCalendar = () => {
        refMainCalendar.current && refMainCalendar.current.onResizeCalendar();
    };

    const _handleServiceClick = (...rest) => {
        refMainCalendar.current.onServiceClick(...rest);
    };

    const _handleDisplayPopup = ({ eventJob, customer, event, location }) => {
        if (refMapView.current) {
            refMapView.current._displayPopup(
                [+location.lng, +location.lat],
                convertEventToDataMarker({ eventJob, customer, event, location }),
                true
            );
        }
    };

    const handleActivateMagnet = ({ cbFinish = () => {} }) => {
        let currentActivate = false;
        if (refWrapperDiv.current) {
            const classList = refWrapperDiv.current.classList;
            currentActivate = classList.contains('active-magnet');
            classList.toggle('active-magnet', !currentActivate);
        }
        refMainCalendar.current && refMainCalendar.current.onActivateMagnet(!currentActivate, cbFinish);
    };

    return (
        <Fragment>
            <div ref={refWrapperDiv} id="displayView" className="calendar-container active-magnet">
                <Header
                    onGoToDate={_goTodate}
                    onNext={_nextAgenda}
                    onPrev={_prevAgenda}
                    onToday={_onToday}
                    onGetTitle={_getTitle}
                    onToggleMap={_toggleMap}
                    onChangeView={_changeView}
                    onReloadEventsOptimized={_reloadEventsOptimized}
                    onActivateMagnet={handleActivateMagnet}
                />
                <div id="wrapper_main_calendar" className="wrapper-main-page">
                    <Map
                        id="map"
                        ref={refMapView}
                        containerClassName="container-map"
                        onRefetchCalendar={_handleRefetchCalendar}
                        onServiceClick={_handleServiceClick}
                        onGetRangeDate={_getCurrentRange}
                        onSelectedMarker={_handleSelectMarker}
                        onResizeCalendar={_handleResizeCalendar}
                    />

                    <MainCalendar
                        ref={refMainCalendar}
                        onUpdateEvents={_handleUpdateEvents}
                        onDisplayPopup={_handleDisplayPopup}
                    />
                </div>
            </div>
            <Timeoff />
            <JobMagnetModal ref={refJobMagnetModal} onRefetchCalendar={_handleRefetchCalendar} />
            {/* This SVG sprite for job magnet, maybe update all for calendar later */}
            <IconCalendarPin />
        </Fragment>
    );
};

export default CalendarContainer;
