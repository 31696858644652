import store from 'common/redux/store/configureStore';

/**
 *
 * @param {Object|int} addons
 * @returns boolean
 */
export const checkAddon = (addons) => {
    return typeof addons === 'object' || addons === 1;
};

export const isBlockedUser = () => {
    const userId = store.getState().auth.user?.company?.user_id;
    // eslint-disable-next-line no-undef
    return !process.env.REACT_APP_WHITE_LIST_USER.split(',').some((item) => `${item}` === `${userId}`);
};

export const checkAccessManageFail = (history) => {
    if (!history) return;
    history.goBack();
};
