export const TYPE_PICKER_CALENDAR_ACTION = {
    ACTION_SET_START_END_PICKER_CALENDAR: 'ACTION_SET_START_END_PICKER_CALENDAR',
    ACTION_SET_START_END_OPTIMIZE_CALENDAR: 'ACTION_SET_START_END_OPTIMIZE_CALENDAR'
};

export const setDateInlineCalendar = (params) => {
    return {
        type: TYPE_PICKER_CALENDAR_ACTION.ACTION_SET_START_END_PICKER_CALENDAR,
        params
    };
};
