import React from 'react';

const LoadingSurchargeManage = () => {
    return (
        <>
            <div className="wrap-loading body-modal has-form">
                <div className="rows">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                    <div className="v2-btn-default loading --grey w-100">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__note">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows mt-4">
                    <div className="txt mb-1">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                    <div className="v2-btn-default loading --grey w-100">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="rows__note">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows --multi mt-4">
                    <div className="col">
                        <div className="txt mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="txt mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading footer-modal">
                <div className="v2-btn-default loading --transparent btn-x-sm ml-n1">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </>
    );
};

export default LoadingSurchargeManage;
