import React from 'react';
import IconPen from 'assets/icon/IconPen';

const Created = ({ logDetail = {} }) => {

    return (
        <div className="boxs">
            <div className="left-icons">
                <IconPen isHasColor />
            </div>
            <div className="content content-log">
                <div className="log-title">
                    {logDetail.text}
                </div>
                <p className="log-time">
                    {logDetail.created}
                </p>
            </div>
        </div>
    );
};

export default Created;