import React, { useRef } from 'react';
import { useSettingTemplate } from '../SettingTemplateContext';
import BodyTemplate from './BodyTemplate';
import FooterTemplate from './FooterTemplate';
import HeaderTemplate from './HeaderTemplate';
import Loading from './Loading';
import PreviewContent from './PreviewContent';

const SettingTemplateContent = () => {
    const { isLoading, onCloseTemplate } = useSettingTemplate();
    const refContent = useRef(null);

    const _handleDisplayAlert = (dataAlert) => {
        refContent.current && refContent.current.displayAlert(dataAlert);
    };

    return (
        <>
            <div className="modal__overlay bg-fixed" onClick={onCloseTemplate} />
            <div className="modal__container --preview-r">
                <div className="wrap-container">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="wrap-container__form flex-column wrap-document-edit">
                            <HeaderTemplate />
                            <BodyTemplate ref={refContent} />
                            <FooterTemplate onAlert={_handleDisplayAlert} />
                        </div>
                    )}
                    <PreviewContent />
                </div>
            </div>
        </>
    );
};

export default SettingTemplateContent;
