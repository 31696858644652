import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { RouteWithSubRoutes } from 'app/components/routes/RouteWithSubRoutes';
import { AUTHENTICATE_ROUTES } from 'app/config/router';
import NotFoundPage from 'app/modules/notFoundPage';

import 'app/modules/auth/css/auth.scss';
import { ACCESS_TOKEN } from 'app/const/App';
import { CALENDAR } from 'app/const/Route';
import { addBranchPath } from 'app/const/Branch';

const UnAuthenticatedApp = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    if (accessToken) {
        window.location.href = addBranchPath(CALENDAR);
        return;
    }

    return (
        <Switch>
            {AUTHENTICATE_ROUTES.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
            ))}
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
};

export default UnAuthenticatedApp;
