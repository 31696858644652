import { reducer } from 'app/const/Reducer';
import PlaceInput from 'app/modules/customer/add/components/address/PlaceInput';
import IconLocation from 'assets/icon/IconLocation';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

const SelectLocation = ({ defaultValue = '' }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { location: defaultValue });
    const refPlace = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => refPlace.current.getValue()
    }));

    const _handleSelect = (name, value) => {
        dispatchState({ location: value.formattedAddress });
    };

    return (
        <div className="wrap-rows flextop border-bottom-line">
            <div className="wrap-rows__icons flex-auto">
                <IconLocation isTimeoff />
            </div>
            <div className="wrap-rows__details flex-1">
                <PlaceInput
                    ref={refPlace}
                    isTextarea
                    isGetFullAddress
                    withClassName="field-control"
                    placeholder="Location"
                    defaultValue={state.location || ''}
                    onSelect={_handleSelect}
                />
            </div>
        </div>
    );
};

export default forwardRef(SelectLocation);
