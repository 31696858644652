import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconClose from 'assets/icon/IconClose';
import IconArrow from 'assets/icon/IconArrow';
import { addBranchPath } from 'app/const/Branch';
import { ADDONS } from 'app/config/routes';
function ConversationUnActive({ customer = {}, onClose = () => {} }) {
    const { t } = useTranslation(['header']);

    return (
        <div className={'notification-dropdown notification-off active'}>
            <div className="boxoff-header">
                <p className="boxoff-header__name mr-2">{customer.full_name}</p>
                <div onClick={onClose} className="v2-btn-default --icon-sm --transparent">
                    <IconClose />
                </div>
            </div>
            <div className="notification-off__btn">{t('sms_current_off')}</div>
            <Link
                to={addBranchPath(ADDONS)}
                onClick={onClose}
                href="#"
                className="notification-off__link v2-btn-default --purple --icon-r"
            >
                {t('active_package_to_enable_sms')}
                <IconArrow />
            </Link>
        </div>
    );
}

ConversationUnActive.propTypes = { customer: PropTypes.shape({}) };

export default ConversationUnActive;
