import React, { useMemo } from 'react';

import { useFilters } from '../../context/addFilterContext';
import Categories from './Categories';
import FilterSearchContent from './FilterSearchContent';
import WrapperSubcategories from './WrapperSubcategories';
import { OBJECT_TYPES } from '../../constants/types';

const FilterContent = () => {
    const { categoryId, listOfFilters } = useFilters();

    const category = useMemo(() => {
        return listOfFilters.find(
            ({ id }) => id === (categoryId === OBJECT_TYPES.CUSTOM_FIELD ? OBJECT_TYPES.CUSTOMER : categoryId)
        );
    }, [categoryId, listOfFilters]);

    if (categoryId === 'search') return <FilterSearchContent />;
    if (category && category.subcategories)
        return <WrapperSubcategories title={category.title} subcategories={category.subcategories} />;
    return <Categories />;
};

export default FilterContent;
