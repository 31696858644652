import { addCardMerchant } from 'app/const/Api';
import { PAYMENT_MERCHANT } from 'app/const/customer/CustomerPayment';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import CustomerAccountStatus from 'app/modules/customer/detail/accounts/components/CustomerAccountStatus';
import IconClose from 'assets/icon/IconClose';
import IconStripe from 'assets/icon/IconStripe';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonSave from '../button/ButtonSave';
import LinkStripeModal from './components/LinkStripeModal';
import StripeSCAContentInfo from './StripeSCAContentInfo';
import AdditionalOptions from 'app/modules/customer/detail/accounts/components/AdditionalOptions';

const AddPaymentStripe = forwardRef(
    (
        {
            customerId = null,
            customerInfo = null,
            stripeId = '',
            hideStripeLinkExisting = false,
            onSubmit = () => {},
            onSubmitOneTime = () => {},
            isHideAdditionalOption = false
        },
        ref
    ) => {
        const { t } = useTranslation(['common', 'customers']);
        const [state, dispatchState] = useReducer(reducer, {
            isVisible: false,
            isExpanded: false,
            isLoadingButton: false,
            isOneTimeCharge: false,
            hideStripeLinkExisting,
            isHideAdditionalOption
        });
        const refStripeForm = useRef(null);
        const refMeta = useRef(null);
        const refStatusBar = useRef(null);
        const refButtonSave = useRef(null);
        const refDropdown = useRef(null);

        const { isVisible, isExpanded } = state;

        useEffect(() => {
            if (isVisible) {
                document.addEventListener('click', handleClickOutside, true);
                document.addEventListener('keydown', handleHideDropdown, true);
            } else {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            }
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            };
        }, [isVisible]);

        function handleHideDropdown(event) {
            const elPrevent = document.getElementById('form_add_payment_stripe');
            if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
                _close();
            }
        }

        function handleClickOutside(event) {
            const elPrevent = document.getElementById('form_add_payment_stripe');

            if (
                refDropdown.current &&
                elPrevent &&
                !elPrevent.contains(event.target) &&
                !refDropdown.current.contains(event.target) &&
                hideStripeLinkExisting
            ) {
                _close();
            }
        }

        useImperativeHandle(ref, () => ({
            _open,
            _close,
            confirmSCA: refStripeForm.current?.confirmSCA
        }));

        // eslint-disable-next-line no-unused-vars
        const _open = (isOneTimeCharge, isHideStripeLink = false, isHideAdditionalOption = false) => {
            dispatchState({
                isVisible: true,
                isOneTimeCharge,
                hideStripeLinkExisting: isHideStripeLink,
                isHideAdditionalOption
            });
        };
        const _close = () => dispatchState({ isVisible: false, isExpanded: false, isOneTimeCharge: false });

        const _handleExpand = () => dispatchState({ isExpanded: !isExpanded });

        const _handleAdd = async () => {
            const tokenCard = await refStripeForm?.current?._getTokenCardStripe();

            if (tokenCard.token) {
                if (!state.isOneTimeCharge) {
                    _addCardMerchant(tokenCard);
                } else {
                    onSubmitOneTime(tokenCard);
                    dispatchState({ isVisible: false, isOneTimeCharge: false });
                }
            } else {
                _handleAddShowError(tokenCard.message);
            }
        };

        const _addCardMerchant = (tokenCard) => {
            const _finally = () => {
                refButtonSave.current.removeLoading();
            };

            const dataCardToken = tokenCard.token;

            const params = {
                exp_year: dataCardToken.card.exp_year,
                exp_month: dataCardToken.card.exp_month,
                account_number: dataCardToken.card.last4,
                account_type: dataCardToken.card.brand,
                merchant: PAYMENT_MERCHANT.STRIPE_GATE_WAY
            };

            if (refMeta.current) params['meta'] = refMeta.current?.getValue();
            if (dataCardToken.payment_method) {
                params.payment_method = dataCardToken.payment_method;
            } else {
                params.token = dataCardToken.id;
            }

            clientQuery(
                addCardMerchant(customerId),
                { data: params, toFormData: false, method: 'POST' },
                (res) => _handleAddCardSuccess(res.data),
                ({ message }) => _handleAddShowError(message),
                _finally
            );
        };

        const _handleAddCardSuccess = (data) => {
            onSubmit(data);
            _close();
        };

        const _handleAddShowError = (message) => {
            refStatusBar.current.showStatusBar('success', message, LIST_STATUS.ERROR);
            refButtonSave.current.removeLoading();
        };

        // Returns additional data from the meta field.
        // If the meta field is empty, an empty object is returned.
        const _handleGetAdditionalData = () => {
            return refMeta.current?.getValue() || {};
        };

        return (
            <div className={classNames('modal container-modal modal-methods fixed', { open: isVisible })}>
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div
                    ref={refDropdown}
                    className={classNames('modal__container form-addcard stripe-additional', { active: isExpanded })}
                    id="form_add_payment_stripe"
                >
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('customers:add_a_card_in_stripe')}</h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                            <IconClose />
                        </div>
                    </div>
                    <div className="content has-form scrolls">
                        <IconStripe hasColor />
                        {isVisible && <CustomerAccountStatus ref={refStatusBar} />}
                        <StripeSCAContentInfo
                            isVisible={isVisible}
                            ref={refStripeForm}
                            onGetAdditionalData={_handleGetAdditionalData}
                            isHideLinked={state.isHideAdditionalOption}
                        />
                        {!state.isHideAdditionalOption ? (
                            <AdditionalOptions ref={refMeta} onExpand={_handleExpand} />
                        ) : null}
                    </div>
                    {!state.hideStripeLinkExisting && isVisible && (
                        <LinkStripeModal
                            customer={{ id: customerId, ...(customerInfo || {}) }}
                            stripeId={stripeId}
                            isDisplayStripeLinked={state.isDisplayStripeLinked}
                            onConnectSuccess={_handleAddCardSuccess}
                        />
                    )}
                    {isVisible && (
                        <div className="footer-modal btn-close">
                            <span className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                                {t('cancel')}
                            </span>
                            <ButtonSave
                                ref={refButtonSave}
                                className="v2-btn-main"
                                onSave={_handleAdd}
                                title={t('add')}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default AddPaymentStripe;
