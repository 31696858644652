import React from 'react';

export default function LoadingInvoices() {
    return (
        <div className="wrap-loading --add-payment">
            <div className="table-grid">
                <div className="rows grid-content">
                    <div className="col-checkbox">
                        <div className="check-items d-flex mt-0">
                            <div className="checkbox mr-0" />
                        </div>
                    </div>
                    <div className="col-invoice">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="col-date pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-status pl-2">
                        <div className="status-btn loading" />
                    </div>
                    <div className="col-charge pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-charge pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-charge col-payment pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="rows grid-content">
                    <div className="col-checkbox">
                        <div className="check-items d-flex mt-0">
                            <div className="checkbox mr-0" />
                        </div>
                    </div>
                    <div className="col-invoice">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="col-date pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-status pl-2">
                        <div className="status-btn loading" />
                    </div>
                    <div className="col-charge pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-charge pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="col-charge col-payment pl-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        </div>
    );
}
