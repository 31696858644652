import { MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';
import { capitalizeEachWord } from 'common/utils/StringUtils';

export const mixpanelEditCompany = ({ type = '', company = {} }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.$PHONE]: company.phone,
        [MIXPANEL_PROPERTIES.COMPANY]: capitalizeEachWord(company.name)
    };

    return {
        type,
        data: {
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet
        }
    };
};
