import { ADDONS, CALENDAR, COMMISSIONS, CUSTOMERS, INBOX, REVIEWS, SETTINGS_ACCOUNT, TRACKER } from 'app/config/routes';
import {
    MenuSidebarBottomAddonIcon,
    MenuSidebarBottomSettingIcon,
    MenuSidebarTopCalendarIcon,
    MenuSidebarTopCommissionIcon,
    MenuSidebarTopCustomerIcon,
    MenuSidebarTopExportIcon,
    MenuSidebarTopInboxIcon,
    MenuSidebarTopLeadIcon,
    MenuSidebarTopReviewIcon,
    MenuSidebarTopDashboardIcon,
    MenuSidebarClockInOutIcon
} from 'app/const/IconSvg';
import { DASHBOARD, LEADS, REPORT_INVOICES } from 'app/const/Route';
import {
    REPORT_DROPDOWN_LEFT_OPTIONS,
    REPORT_DROPDOWN_RIGHT_OPTIONS,
    SETTING_DROPDOWN_LEFT_OPTIONS,
    SETTING_DROPDOWN_RIGHT_OPTIONS
} from 'app/const/StaticLinks';
import { getPermissionReport, getPermissionSetings } from 'common/utils/PermissionUtils';

export const MENUS_PIN_NAME = {
    CALENDAR: 'calendar',
    CUSTOMERS: 'customers',
    INBOX: 'inbox',
    LEADS: 'leads',
    REPORTS: 'reports',
    REVIEWS: 'reviews',
    COMMISSIONS: 'commissions',
    SETTINGS: 'settings',
    ADDONS: 'addons',
    DASHBOARD: 'dashboard',
    WORK_LOG: 'work_log'
};

export const MENU_CONTENT = {
    [MENUS_PIN_NAME.CALENDAR]: {
        idPin: MENUS_PIN_NAME.CALENDAR,
        isDropdown: false,
        name: 'Calendar',
        itemClassName: 'item-calendar',
        path: CALENDAR,
        icon: <MenuSidebarTopCalendarIcon />
    },
    [MENUS_PIN_NAME.WORK_LOG]: {
        idPin: MENUS_PIN_NAME.WORK_LOG,
        isDropdown: false,
        name: 'Time Clocking',
        itemClassName: 'item-clock',
        path: TRACKER,
        icon: <MenuSidebarClockInOutIcon />
    },
    [MENUS_PIN_NAME.CUSTOMERS]: {
        idPin: MENUS_PIN_NAME.CUSTOMERS,
        isDropdown: false,
        name: 'Customers',
        path: CUSTOMERS,
        itemClassName: 'item-customer',
        icon: <MenuSidebarTopCustomerIcon />
    },
    [MENUS_PIN_NAME.INBOX]: {
        idPin: MENUS_PIN_NAME.INBOX,
        isDropdown: false,
        name: 'Inbox',
        path: INBOX,
        itemClassName: 'item-inbox',
        icon: <MenuSidebarTopInboxIcon />
    },
    [MENUS_PIN_NAME.LEADS]: {
        idPin: MENUS_PIN_NAME.LEADS,
        isDropdown: false,
        name: 'Leads',
        path: LEADS,
        itemClassName: 'item-lead',
        icon: <MenuSidebarTopLeadIcon />
    },
    [MENUS_PIN_NAME.REVIEWS]: {
        idPin: MENUS_PIN_NAME.REVIEWS,
        isDropdown: false,
        name: 'Reviews',
        path: REVIEWS,
        itemClassName: 'item-review',
        icon: <MenuSidebarTopReviewIcon />
    },
    [MENUS_PIN_NAME.REPORTS]: {
        idPin: MENUS_PIN_NAME.REPORTS,
        isDropdown: true,
        name: 'Reports',
        path: REPORT_INVOICES,
        itemClassName: 'item-report',
        icon: <MenuSidebarTopExportIcon />
    },
    [MENUS_PIN_NAME.SETTINGS]: {
        idPin: MENUS_PIN_NAME.SETTINGS,
        isDropdown: true,
        name: 'Settings',
        path: SETTINGS_ACCOUNT,
        itemClassName: 'item-setting',
        icon: <MenuSidebarBottomSettingIcon />
    },
    [MENUS_PIN_NAME.COMMISSIONS]: {
        idPin: MENUS_PIN_NAME.COMMISSIONS,
        isDropdown: false,
        name: 'Commission',
        path: COMMISSIONS,
        itemClassName: 'item-commission',
        icon: <MenuSidebarTopCommissionIcon />
    },
    [MENUS_PIN_NAME.ADDONS]: {
        idPin: MENUS_PIN_NAME.ADDONS,
        isDropdown: true,
        name: 'Addons',
        path: ADDONS,
        itemClassName: 'item-addons',
        icon: <MenuSidebarBottomAddonIcon />
    },
    [MENUS_PIN_NAME.DASHBOARD]: {
        idPin: MENUS_PIN_NAME.DASHBOARD,
        isDropdown: false,
        name: 'Dashboard',
        path: DASHBOARD,
        itemClassName: 'item-dashboard',
        icon: <MenuSidebarTopDashboardIcon />
    }
};

export const MENU_DROPDOWN_CONTENT = {
    [MENUS_PIN_NAME.REPORTS]: {
        left: REPORT_DROPDOWN_LEFT_OPTIONS,
        right: REPORT_DROPDOWN_RIGHT_OPTIONS,
        ns: 'report',
        funcGetPermission: getPermissionReport
    },
    [MENUS_PIN_NAME.SETTINGS]: {
        left: SETTING_DROPDOWN_LEFT_OPTIONS,
        right: SETTING_DROPDOWN_RIGHT_OPTIONS.filter((item) => !item.isHideOnHeader),
        ns: 'setting',
        funcGetPermission: getPermissionSetings
    }
};
