import React from 'react';

export default function IconDownload() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="#4D5054" strokeWidth="1.3" d="M5.5 14.5v3a2 2 0 002 2h10a2 2 0 002-2v-3"></path>
            <path stroke="#4D5054" strokeLinecap="round" strokeWidth="1.3" d="M12.5 14.5v-9"></path>
            <path
                stroke="#4D5054"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M8 10l4.5 4.5L17 10"
            ></path>
        </svg>
    );
}
