export const TYPE_SETTING_EMAIL_INBOX = {
    GET_LIST: 'SETTING_GET_LIST_EMAIL_INBOX',
    UPDATE_STATUS: 'SETTING_UPDATE_EMAIL_INBOX',
    CREATE_EMAIL_INBOX: 'SETTING_CREATE_EMAIL_INBOX',
    UPDATE_EMAIL_INBOX: 'UPDATE_EMAIL_INBOX',
    GET_DETAIL: 'SETTINGS_GET_DETAIL_EMAIl_INBOX'
};

// actions
export const getListSettingEmailInboxTemplate = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_EMAIL_INBOX.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateStatusSettingEmailInbox = (
    params,
    actionSuccess,
    actionFailed = () => {},
    actionFinally = () => {}
) => {
    return {
        type: TYPE_SETTING_EMAIL_INBOX.UPDATE_STATUS,
        params,
        actionSuccess,
        actionFailed,
        actionFinally
    };
};

export const updateEmailInbox = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_EMAIL_INBOX.UPDATE_EMAIL_INBOX,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createEmailInbox = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_EMAIL_INBOX.CREATE_EMAIL_INBOX,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getDetailEmailInbox = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_EMAIL_INBOX.GET_DETAIL,
        params,
        actionSuccess,
        actionFailed
    };
};
