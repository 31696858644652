import { DEFAULT_ALL } from 'app/const/App';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconPen from 'assets/icon/IconPen';
import IconProfile from 'assets/icon/IconProfile';
import IconThreeDots from 'assets/icon/IconThreeDots.js';
import IconTrash from 'assets/icon/IconTrash';
import React, { useRef } from 'react';

const ServiceItem = ({
    id,
    name,
    price,
    length,
    learn_more,
    schedule,
    onAssign = () => {},
    onDelete = () => {},
    onEditLearnMore = () => {}
}) => {
    const refDropdown = useRef(null);

    const _handleEditLearnMore = () => {
        onEditLearnMore(id, learn_more);
        refDropdown.current._closeDropdown();
    };

    const _handleEditAssign = () => {
        onAssign(id, schedule?.id || DEFAULT_ALL);
        refDropdown.current._closeDropdown();
    };

    const _handleDelete = () => {
        onDelete(id);
    };

    return (
        <div className="rows">
            <div className="rows__title">
                <IconThreeDots isDouble />
                <span className="txt-ellipsis">{name}</span>
            </div>
            <div className="rows__multi">
                <span className="price">{price}</span>
                <span className="hour">{length}</span>
            </div>
            <CalendarDropdown
                ref={refDropdown}
                id="dropdown_service_booking_item"
                wrapperClassName="--booking-menu d-flex"
                listOptionsClass="v2-dropdown__menu scrolls"
                buttonClassName="dropbtn v2-btn-default --icon-sm --transparent"
                customDropButton={() => <IconThreeDots />}
            >
                <ul>
                    <li>
                        <div className="items has-icon btn-modal" onClick={_handleEditLearnMore}>
                            <IconPen />
                            <div className="txt-ellipsis">Edit Learn More</div>
                        </div>
                    </li>
                    <li>
                        <div className="items has-icon btn-modal" onClick={_handleEditAssign}>
                            <IconProfile />
                            <div className="txt-ellipsis">Assign to</div>
                        </div>
                    </li>
                    <li className="items has-icon" onClick={_handleDelete}>
                        <IconTrash />
                        <div className="txt-ellipsis red-delete">Delete Service Item</div>
                    </li>
                </ul>
            </CalendarDropdown>
            {learn_more && <p className="description">{learn_more}</p>}
            {schedule && (
                <div className="rows__user">
                    {schedule?.user?.avatar ? (
                        <>
                            <span className="avt-img">
                                <img src={schedule.user.avatar} alt="" width={24} height={24} />
                            </span>
                        </>
                    ) : (
                        <div className="avt fs-11">{schedule.name.substring(0, 2)}</div>
                    )}

                    <span className="txt-ellipsis">{schedule.name}</span>
                </div>
            )}
        </div>
    );
};

export default ServiceItem;
