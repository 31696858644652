import ButtonSave from 'app/components/button/ButtonSave';
import { getUpdateBookingServiceAssign } from 'app/const/Api';
import { DEFAULT_ALL } from 'app/const/App';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import LoadingAssign from './LoadingAssign';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const ServiceAssign = ({ onAssignSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const { isFirstTime, data: dataSchedules } = useSelector(({ schedules }) => schedules);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen, idUpdate, idSchedule } = state;
    const schedules = dataSchedules?.schedules || [];
    const refButtonSave = useRef(null);
    const refForm = useRef(null);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (idUpdate, idSchedule) => {
        dispatchState({ isOpen: true, idUpdate, idSchedule });
        isFirstTime && dispatch(actionGetListSchedule({}));
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSave = () => {
        let schedule_id = '';
        const values = refForm.current['assign_to'];

        for (let index = 0; index < values.length; index++) {
            const { value, checked } = values[index];
            if (checked) schedule_id = value;
        }

        const _handleSaveSuccess = ({ data, message }) => {
            const dataSchedule = schedules.find((item) => item.id === schedule_id) || null;
            if (data?.user) dataSchedule['user'] = data.user;
            onAssignSuccess(dataSchedule, message, idUpdate);
            _close();
        };

        clientQuery(
            getUpdateBookingServiceAssign(idUpdate),
            { data: { schedule_id }, method: 'PUT' },
            _handleSaveSuccess
        );
    };

    const _renderListSchedule = () => {
        return schedules.map((item) => {
            return (
                <div key={item.id} className="row">
                    <div className="check-radio">
                        <input
                            id={`schedule_${item.id}`}
                            type="radio"
                            defaultChecked={idSchedule === item.id}
                            value={item.id}
                            name="assign_to"
                        />
                        <label htmlFor={`schedule_${item.id}`}>{item.name}</label>
                    </div>
                </div>
            );
        });
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="add_service_booking"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">Assign To</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <form ref={refForm} className="body-modal --assign scrolls">
                    {isFirstTime ? (
                        <LoadingAssign />
                    ) : (
                        <>
                            <div className="row">
                                <div className="check-radio">
                                    <input
                                        id="schedule"
                                        type="radio"
                                        name="assign_to"
                                        value={-1}
                                        defaultChecked={idSchedule === DEFAULT_ALL}
                                    />
                                    <label htmlFor="schedule">Any Schedule</label>
                                </div>
                            </div>
                            <p className="title">Schedules</p>
                            {_renderListSchedule()}
                        </>
                    )}
                </form>

                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ServiceAssign);
