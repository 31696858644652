import IconGoogle from 'assets/icon/IconGoogle';
import IconMicrosoft from 'assets/icon/IconMicrosoft';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EmailType = ({ type, userName = '' }) => {
    const { t } = useTranslation();
    if (type === 'imap') return null;
    return (
        <div className="address">
            {type === 'google' ? <IconGoogle isComplex /> : <IconMicrosoft />}
            <div className="address__content ml-2">
                <div className="title">{t(type)}</div>
                <div className="email">{userName}</div>
            </div>
        </div>
    );
};

export default EmailType;
