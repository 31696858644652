import { API_VERSION } from 'app/const/URL';

export const SMS_CONVERSATION = `${API_VERSION}sms/conversation`;
export const SMS_COUNT = `${API_VERSION}sms/count`;
export const CONVERSATION_COUNT = `${API_VERSION}sms/count/conversation`;
export const SMS_LIST_CUSTOMER = `${API_VERSION}search/elastic`;
export const SMS_MARK_STATUS = `${API_VERSION}sms/update`;
export const SEND_SMS_BY_CUSTOMER = `${API_VERSION}sms/send`;
export const GET_SMS_BY_CUSTOMER = `${API_VERSION}sms`;
export const SMS_GET_LIST_BY_CUSTOMER = `${API_VERSION}sms`;
export const UPLOAD_FILE = `${API_VERSION}sms/action/upload`;
export const CUSTOMER_LIST = `${API_VERSION}customer/list`;
export const UPDATE_SMS_BY_CUSTOMER = `${API_VERSION}sms/update`;
export const SMS_LOAD_CHAT_BOX = `${API_VERSION}sms/phone`;
export const SMS_GET_LIST_PHONE = `${API_VERSION}sms/phone`;
export const RE_SEND_SMS_BY_CUSTOMER = `${API_VERSION}sms/resend`;
export const SMS_GET_LIST_VARIABLE = `${API_VERSION}sms/variable`;
export const SMS_GET_LIST_SENDER = `${API_VERSION}sms/senders`;
