import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconDoubleStep from 'assets/icon/IconDoubleStep';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const CallCollapse = () => {
    const { t } = useTranslation();

    const { calls } = useContext(VOIPContext);

    const toggleCollapseCall = (isToggle = true, value = true) => {
        const className = 'is-disable-calls';
        const classList = document.querySelector('.wrapper-call.relative')?.classList;
        if (!classList) return;

        if (isToggle) return classList.toggle(className);
        if (value) return classList.add(className);
        else return classList.remove(className);
    };

    const _handleToggle = () => {
        toggleCollapseCall();
    };

    if (!calls.length) {
        toggleCollapseCall(false, false);
        return null;
    }

    return (
        <div className="v2-btn-default --icon-sm btn-collapse tooltip" onClick={_handleToggle}>
            <IconDoubleStep />
            <p className="tooltiptext bottom">{t('header:collapse')}</p>
            <p className="tooltiptext bottom">{t('header:expand')}</p>
        </div>
    );
};
