import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
let timer = null;

const GdSwitchCheckbox = (
    {
        id = 'gdSwitchCheckbox',
        textChecked,
        textUnChecked,
        inputClassName = 'toggle toggle-round label-enabled',
        shouldLabelActive = true,
        defaultChecked = false,
        isEffectDefaultChecked = false,
        textPosition = 'left',
        onChange,
        onChangeImmediately,
        onClickLabel = () => {},
        wrapClassName = 'switch large',
        timeOut = 500,
        ...props
    },
    ref
) => {
    const [isActive, setIsActive] = useState(defaultChecked);

    useImperativeHandle(ref, () => ({
        setIsActive: (value) => {
            isActive !== value && setIsActive(value);
        },
        getIsActive: () => isActive
    }));

    /* This is a React Hook that is used to set the isActive state to the defaultChecked value. */
    useEffect(() => {
        if (isEffectDefaultChecked && defaultChecked !== isActive) setIsActive(defaultChecked);
    }, [defaultChecked]);

    /**
     * It takes an event object as an argument, and sets the isActive state to the value of the event's
     * target's checked property.
     */
    const _handleChange = (e) => {
        const checked = e.target.checked;
        setIsActive(checked);
        onChangeImmediately && onChangeImmediately(checked, id, e);
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (onChange) onChange(checked, id, e);
        }, timeOut);
    };

    return (
        <div className={wrapClassName}>
            {textPosition === 'left' && (
                <span className={classNames('switch__label', { '--active': isActive && shouldLabelActive })}>
                    {isActive ? textChecked : textUnChecked}
                </span>
            )}
            <input
                id={id}
                className={inputClassName}
                checked={isActive}
                type="checkbox"
                onChange={_handleChange}
                {...props}
            />
            <label htmlFor={id} onClick={onClickLabel} />
            {textPosition === 'right' && (
                <span className={classNames('switch__label ml-2', { '--active': isActive && shouldLabelActive })}>
                    {isActive ? textChecked : textUnChecked}
                </span>
            )}
        </div>
    );
};

export default forwardRef(GdSwitchCheckbox);
