export const TYPE_DEVICES_ACTIONS = {
    GET_LIST_AREA_SUCCESS: 'GET_LIST_AREA_SUCCESS',
    GET_LIST_DEVICES_TYPE_SUCCESS: 'GET_LIST_DEVICES_TYPE_SUCCESS',
    GET_LIST_DEVICES_STATUS_SUCCESS: 'GET_LIST_DEVICES_STATUS_SUCCESS',
    GET_LIST_ACTIVITY_LEVEL_SUCCESS: 'GET_LIST_ACTIVITY_LEVEL_SUCCESS'
};

export const setListAreaReducer = (payload) => {
    return {
        type: TYPE_DEVICES_ACTIONS.GET_LIST_AREA_SUCCESS,
        payload
    };
};

export const setListDevicesTypeReducer = (payload) => {
    return {
        type: TYPE_DEVICES_ACTIONS.GET_LIST_DEVICES_TYPE_SUCCESS,
        payload
    };
};

export const setListDevicesStatusReducer = (payload) => {
    return {
        type: TYPE_DEVICES_ACTIONS.GET_LIST_DEVICES_STATUS_SUCCESS,
        payload
    };
};

export const setListActivityLevelReducer = (payload) => {
    return {
        type: TYPE_DEVICES_ACTIONS.GET_LIST_ACTIVITY_LEVEL_SUCCESS,
        payload
    };
};
