/* eslint-disable no-undef */
export const GOOGLE_PLACES_API_URL = (clientId) =>
    `https://maps.googleapis.com/maps/api/js?key=${clientId}&libraries=places`;
export const MS_LOGIN_URL = (clientId) =>
    `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${process.env.REACT_APP_MICROSOFT_REDIRECT_URI}&response_mode=query&scope=offline_access user.read user.read.all mail.read mail.send`;
export const DISCOVERY_DOCS_GOOGLE_EMAIL = process.env.REACT_APP_DISCOVERY_DOCS_GOOGLE_EMAIL;
export const DISCOVERY_DOCS_GOOGLE_CALENDAR = process.env.REACT_APP_DISCOVERY_DOCS_GOOGLE_CALENDAR;
export const SCOPES_GOOGLE_EMAIL = process.env.REACT_APP_SCOPES_GOOGLE_EMAIL;
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
export const INTERCOM_SQUARE_URL = process.env.REACT_APP_INTERCOM_SQUARE_URL;
export const REACT_APP_USER_VOICE_KEY = process.env.REACT_APP_USER_VOICE_URL;
