import classNames from 'classnames';
import moment from 'moment';
import React, { Suspense, forwardRef, useEffect, useImperativeHandle, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import GdConfirm from 'app/components/confirm';
import { CREATE_CUSTOMER, GET_CUSTOMER_ACCOUNT_NUMBER } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { ZIP_ST_NOT_REQUIRE_COUNTRYS_CODE } from 'app/const/Locations';
import { reducer } from 'app/const/Reducer';
import { CALENDAR, CUSTOMERS } from 'app/const/Route';
import { LIST_STATUS } from 'app/const/Status';
import {
    CUSTOMER_ADD_OPTION_NEW_JOB,
    CUSTOMER_ADD_OPTION_OPEN_CUSTOMER,
    CUSTOMER_ADD_OPTION_VIEW_MAP
} from 'app/const/customer/CustomerAdd';
import { CUSTOMER_STATUS } from 'app/modules/customer/const';
import { mixpanelAddCustomer } from 'app/modules/mixpanel/MixpanelAddCustomer';
import { mixpanelAddLead } from 'app/modules/mixpanel/MixpanelAddLead';
import IconClose from 'assets/icon/IconClose';
import { useEnterKeydownClick } from 'common/hooks';
import { createCustomer } from 'common/redux/actions/customer';
import { actionCreateJob } from 'common/redux/actions/job/create';
import { clientQuery } from 'common/utils/ApiUtils';
import { validateEmail } from 'common/utils/EmailUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import AlertCustomer from '../components/AlertCustomer';
import AlertExistCustomer from './components/AlertExistCustomer';
import ButtonSaveWithOption from './components/ButtonSaveWithOption';
import Loading from './components/Loading';
import BoxAddress from './components/address';
import FastForm from './components/fastform';
import BoxInformation from './components/information';
import BoxPayment from './components/payment';
import AddCustomerProvider from './contexts';

const BoxCustomFields = React.lazy(() => import('./components/customFields'));
const AddNewCustomerModal = (props, ref) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'customers']);

    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const permissionCustomField = (isTrial || isGrowthPlan) && customFieldAddon;

    const LIST_ERRORS = useMemo(() => {
        return {
            first_name: 'First Name cannot be blank.',
            account_no: 'Account No cannot be blank.',
            zip: 'Zip cannot be blank.',
            street1: 'Street 1 cannot be blank.',
            city: 'City cannot be blank.',
            state: 'St cannot be blank.',
            email: 'Email is not a valid email address.'
        };
    }, []);

    const idCurrentUser = useSelector(({ auth }) => auth.user.profile.id);
    const GOOGLE_MAPS_CLIENT_KEY = useSelector(({ auth }) => auth.user.settings.google.geo_key);
    const companyCountry = useSelector(({ auth }) => auth.user.company.country);
    const dataCreateCustomer = useSelector(({ createCustomer }) => createCustomer.createCustomerData);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isFastForm: false,
        isLoading: true,
        defaultStatus: CUSTOMER_STATUS[1].type,
        defaultPhones: [],
        call_log_id: ''
    });
    const {
        defaultStatus,
        accountNumber,
        isFastForm,
        isLoading,
        isOpen,
        defaultPhones,
        call_log_id: callIdCreateLead
    } = state;

    const isStatusLead = defaultStatus === CUSTOMER_STATUS[2].type;

    // Props from reducers open modal create customer
    const { isGetAllDataSuccess = false, onSubmit = () => {}, onClose = () => {} } = dataCreateCustomer || {};

    const refFastForm = useRef(null);
    const refInformation = useRef(null);
    const refButtonSave = useRef(null);
    const refButtonWithOption = useRef(null);
    const refPayment = useRef(null);
    const refCustomFields = useRef(null);
    const refAddress = useRef(null);
    const refWrapper = useRef(null);
    const refAlert = useRef(null);
    const refAlertExist = useRef(null);
    const refConfirm = useRef(null);

    useEnterKeydownClick(isOpen);

    useImperativeHandle(ref, () => ({ showModal, hideModal }));

    useEffect(() => {
        if (isOpen) loadGoogleMapScript();
    }, [isOpen]);

    useEffect(() => {
        if (dataCreateCustomer?.isVisibleModal) showModal(dataCreateCustomer);
    }, [dataCreateCustomer]);

    // load google map script
    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object' && callback) {
            callback();
        } else {
            if (!window?.google?.maps) {
                const googleMapScript = document.createElement('script');
                googleMapScript.src = GOOGLE_PLACES_API_URL(GOOGLE_MAPS_CLIENT_KEY);
                window.document.body.appendChild(googleMapScript);
                googleMapScript.addEventListener('load', callback);
            }
        }
    };

    const showModal = (params) => {
        dispatchState({ isOpen: true, ...(params || {}) });
        _handleInitNumber();
    };

    const handleClose = () => {
        onClose();
        dispatch(createCustomer(null));
        dispatchState({ isOpen: false, isFastForm: false, isLoading: true, defaultStatus: 1, defaultPhones: [] });
    };

    const hideModal = (isForceClose = false) => {
        if (isForceClose) return handleClose();
        let canClose = true;

        if (isFastForm) {
            const result = refFastForm.current.getValue();

            for (const key in result) {
                const element = result[key];

                for (const keyNested in result[key]) {
                    const elementNested = element[keyNested];

                    if (typeof elementNested === 'string' && keyNested !== 'account_no' && elementNested.length)
                        canClose = false;
                    if (Array.isArray(elementNested) && elementNested.length) canClose = false;

                    if (typeof elementNested === 'object') {
                        switch (keyNested) {
                            case 'service_address':
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                            default:
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                        }
                    }
                }
            }
        } else {
            const result = _handleGetDataSubmit();

            for (const key in result) {
                const element = result[key];
                for (const keyNested in result[key]) {
                    const elementNested = element[keyNested];
                    if (typeof elementNested === 'string' && keyNested !== 'account_no' && elementNested.length)
                        canClose = false;
                    if (Array.isArray(elementNested) && elementNested.length) canClose = false;
                    if (typeof elementNested === 'object') {
                        switch (keyNested) {
                            case 'service_address':
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                            case 'messaging_preferences':
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                            case 'billing_address':
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                            default:
                                if (canClose && !_handleCheckObjectEmpty(elementNested)) canClose = false;
                                break;
                        }
                    }
                }
            }
        }

        if (canClose) {
            handleClose();
        } else {
            refConfirm.current.open(null, t('customers:do_you_want_to_leave_without_saving'));
        }
    };

    const _handleCheckObjectEmpty = (data) => {
        let canClose = true;

        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (typeof element === 'string' && element) canClose = false;
                if (Array.isArray(element) && element.length) canClose = false;
            }
        }

        return canClose;
    };

    const _handleChangeFastForm = () => {
        dispatchState({ isFastForm: !isFastForm });
    };

    const _handleInitNumber = () => {
        const _getNumberSuccess = ({ data }) => dispatchState({ isLoading: false, accountNumber: data.number });
        clientQuery(GET_CUSTOMER_ACCOUNT_NUMBER, { data: {}, method: 'GET' }, _getNumberSuccess);
    };

    const _handleSubmit = (option = null) => {
        let dataSubmit = _handleGetDataSubmit();
        if (permissionCustomField) dataSubmit['custom_fields'] = refCustomFields.current?.getValue();
        if (isFastForm) {
            dataSubmit = refFastForm.current.getValue();
            dataSubmit['profile'] = {
                ...dataSubmit['profile'],
                status: refInformation.current?.getValue()?.profile?.status
            };
        }

        if (_hasErrors(dataSubmit)) return refButtonWithOption.current.closeDropdown();
        _handleCreate(dataSubmit, isFastForm, option);
    };

    const _handleGetDataSubmit = () => {
        const dataAddress = refAddress.current?.getValue() || {};
        const dataPayment = refPayment.current?.getValue() || {};

        return {
            ...(refInformation.current?.getValue() || {}),
            service_location: { ...dataAddress, messaging_preferences: dataPayment.messaging_preferences },
            cards: dataPayment.cards
        };
    };

    const _handleChangeStatus = (value) => {
        dispatchState({ ...state, defaultStatus: value });
    };

    const _handleCreate = (data, isFastForm = false, option) => {
        const optionsQuery = {
            data: { ...data, fast_form: isFastForm },
            toFormData: false,
            method: 'POST'
        };
        clientQuery(
            CREATE_CUSTOMER,
            optionsQuery,
            ({ data: dataSuccess }) => _handleCreateSuccess(dataSuccess, data, option),
            _handleCreateFailed
        );
    };

    const _handleCreateFailed = ({ message }) => {
        refAlert.current.showStatusBar({ id: 'create_customer_failed', message, type: LIST_STATUS.ERROR });
        refButtonSave.current.removeLoading();
        refButtonWithOption.current.setLoading(false);
    };

    const _handleCreateSuccess = (dataSuccess, dataSubmit, optionCallback = null) => {
        handleTrackingEvent(
            isStatusLead ? mixpanelAddLead({ id: idCurrentUser }) : mixpanelAddCustomer({ id: idCurrentUser })
        );
        const { profile } = dataSubmit;

        onSubmit(
            isGetAllDataSuccess
                ? {
                      ...dataSubmit,
                      id: dataSuccess.customer_id,
                      customer: {
                          avatar: `${profile.first_name.split('')[0]}${profile.last_name.split('')[0] || ''}`,
                          first_name: profile.first_name,
                          last_name: profile.last_name,
                          full_name: `${profile.first_name} ${profile.last_name}`
                      },
                      added_by: idCurrentUser,
                      call_log_id: callIdCreateLead
                  }
                : dataSuccess
        );
        hideModal(true);

        switch (optionCallback) {
            case CUSTOMER_ADD_OPTION_VIEW_MAP:
                history.push({
                    pathname: addBranchPath(CALENDAR),
                    state: { location: dataSuccess.location }
                });
                break;
            case CUSTOMER_ADD_OPTION_NEW_JOB:
                const currentMinute = parseInt(moment().format('mm'));
                const finalMinute = currentMinute - (currentMinute % 5);
                dispatch(
                    actionCreateJob({
                        customer: {
                            account_no: profile.account_no,
                            id: dataSuccess.customer_id,
                            avatar: `${profile.first_name.split('')[0]}${profile.last_name.split('')[0]}`,
                            first_name: profile.first_name,
                            last_name: profile.last_name,
                            full_name: `${profile.first_name} ${profile.last_name}`
                        },
                        customer_id: dataSuccess.customer_id,
                        isAddFromDetail: true,
                        start: `${moment().format(
                            `yyyy-MM-DDTHH:${finalMinute > 9 ? finalMinute : '0'.concat(finalMinute)}:ss`
                        )}Z`
                    })
                );
                break;
            case CUSTOMER_ADD_OPTION_OPEN_CUSTOMER:
                history.push(addBranchPath(`${CUSTOMERS}/${dataSuccess.customer_id}`));
                break;

            default:
                break;
        }
    };

    const _hasErrors = ({ profile, service_location }) => {
        const keysExclude = ['country', 'street2', 'county'];
        if (ZIP_ST_NOT_REQUIRE_COUNTRYS_CODE.includes(companyCountry)) {
            keysExclude.push('zip', 'state');
        }
        const errors = [];
        const profileNameCheck = !profile.first_name.trim().length;
        const profileEmailCheck = profile.email.length && !validateEmail(profile.email);
        const profileNumberCheck = !isFastForm && !profile.account_no.toString().trim().length;

        if (profileNameCheck || profileNumberCheck || profileEmailCheck) {
            if (profileNameCheck) errors.push(LIST_ERRORS.first_name);
            if (profileNumberCheck) errors.push(LIST_ERRORS.account_no);
            if (profileEmailCheck) errors.push(LIST_ERRORS.email);
        } else if (!isStatusLead) {
            let servicesHasError = false;
            let hasError = false;

            for (const key in service_location.service_address) {
                const element = service_location.service_address[key];
                if (typeof element === 'string' && !element.trim().length) {
                    if (!hasError && !keysExclude.includes(key)) {
                        errors.push(LIST_ERRORS[key]);
                        servicesHasError = true;
                        hasError = true;
                    }
                }
            }

            if (!servicesHasError && !service_location.same_billing_location) {
                for (const key in service_location.billing_address) {
                    const element = service_location.billing_address[key];
                    if (typeof element === 'string' && !element.trim().length && !servicesHasError) {
                        if (!hasError && !keysExclude.includes(key)) {
                            errors.push(LIST_ERRORS[key]);
                            hasError = true;
                        }
                    }
                }
            }
        }

        if (!!errors.length) {
            refWrapper.current.scrollTop = 0;
            refAlert.current.showStatusBar({ id: 'create_customer_failed', message: errors, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
            refButtonWithOption.current.setLoading(false);
        }

        return !!errors.length;
    };

    const _handleExistCustomer = (data) => {
        refAlertExist.current._open(data);
    };

    return (
        <>
            <ReactModal
                isOpen={state.isOpen}
                id="add_new_customer"
                portalClassName="ReactModalPortal_customer"
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal modal-addcustomer open"
                onRequestClose={() => hideModal(false)}
            >
                <div className="modal__overlay bg-fixed" onClick={() => hideModal(false)} />

                <div className="modal__container --preview-r">
                    {/* Header Modal */}
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('customers:new_customer')}</h3>
                        <div
                            className="v2-btn-default --icon-lg --transparent"
                            onClick={() => hideModal(false)}
                            tabIndex="0"
                        >
                            <IconClose />
                        </div>
                    </div>
                    {/* End header Modal */}

                    {/* Body Modal */}
                    <div
                        ref={refWrapper}
                        className={classNames('body-modal switch-form scrolls', { 'is-fastform': isFastForm })}
                    >
                        <AlertCustomer ref={refAlert} />
                        {isLoading ? (
                            <Loading />
                        ) : (
                            <AddCustomerProvider accountNumber={accountNumber} defaultPhones={defaultPhones}>
                                <p className="required-label">
                                    {t('customers:fields_with')} <span className="red-default mx-1">*</span>{' '}
                                    {t('customers:are_required')}
                                </p>
                                <div className="form-default">
                                    <BoxInformation
                                        ref={refInformation}
                                        accountNumber={accountNumber}
                                        defaultStatus={defaultStatus}
                                        onHandleChangeStatus={_handleChangeStatus}
                                    />
                                    <BoxAddress
                                        ref={refAddress}
                                        hideSame
                                        onExistCustomer={_handleExistCustomer}
                                        defaultStatus={defaultStatus}
                                    />
                                    <BoxPayment ref={refPayment} />
                                    <Suspense fallback={null}>
                                        {permissionCustomField ? <BoxCustomFields ref={refCustomFields} /> : null}
                                    </Suspense>
                                </div>
                                <FastForm ref={refFastForm} defaultStatus={defaultStatus} />
                            </AddCustomerProvider>
                        )}
                    </div>
                    {/* End body Modal */}

                    {/* Footer Modal */}
                    <div className={classNames('footer-modal', { 'is-disable': isLoading })}>
                        <div className="switch-form">
                            <GdSwitchCheckbox
                                id="switch-customer"
                                textPosition="right"
                                textChecked={t('customers:fast_form')}
                                textUnChecked={t('customers:fast_form')}
                                onChangeImmediately={_handleChangeFastForm}
                            />
                        </div>
                        <div className="flexcenter">
                            <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                            <ButtonSaveWithOption ref={refButtonWithOption} onSave={_handleSubmit} />
                        </div>
                    </div>
                    {/* End footer Modal */}
                </div>
                <GdConfirm
                    ref={refConfirm}
                    title={t('common:confirm')}
                    listButton={{ confirm: true, cancel: false }}
                    titleConfirm={t('common:confirm')}
                    onConfirm={handleClose}
                />
            </ReactModal>
            <AlertExistCustomer ref={refAlertExist} />
        </>
    );
};

AddNewCustomerModal.displayName = 'AddNewCustomerModal';
export default forwardRef(AddNewCustomerModal);
