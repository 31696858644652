import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToggledSidebar } from 'common/redux/actions/appAction';
import IconSideBar from 'assets/icon/IconSideBar';

function SidebarHeader() {
    const dispatch = useDispatch();
    const { toggled } = useSelector(({ app }) => ({ toggled: app.toggled }));

    function handleOnClick() {
        dispatch(setToggledSidebar());
    }

    return (
        <div className="collapse-sidebar" onClick={handleOnClick}>
            <div className={`btn-sidebar items-collapse ${toggled && 'active'}`}>
                <div className="default">
                    <IconSideBar />
                </div>
                <div className="active">
                    <IconSideBar isActive />
                </div>
            </div>
        </div>
    );
}

export default SidebarHeader;
