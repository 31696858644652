import { DEFAULT_ALL } from 'app/const/App';

export const _handleSortLocation = (locations) => {
    return locations.sort((a, b) => {
        const aIsDeleted = a.deleted;
        const bIsDeleted = b.deleted;

        if (aIsDeleted && bIsDeleted) {
            // both a and b are deleted
            return a.name.localeCompare(b.name);
        } else if (aIsDeleted) {
            return 1; // b comes first
        } else if (bIsDeleted) {
            return -1; // a comes first
        } else {
            // neither a nor b are deleted
            return a.name.localeCompare(b.name);
        }
    });
};

export const _handleSortTags = (tags = []) => {
    return tags.sort((a, b) => a.localeCompare(b));
};

export const _handleConvertTags = (tags = []) => {
    return tags.map((item) => {
        if (typeof item === 'string') return item;
        return item.name;
    });
};

export const handleCombineLocationSameAddress = (locations = []) => {
    const results = [];
    locations.forEach((location) => {
        const { id, address } = location;
        const index = results.findIndex((item) => item.address === address);
        if (index === -1) {
            results.push(location);
        } else {
            const result = { ...results[index] };
            if (!result['id'].includes(id)) results[index]['id'] = results[index]['id'] + ',' + id;
        }
    });
    return results;
};

export const _handleUpdateMultipleLocations = (itemsToUpdate, items) => {
    const changesById = {};
    for (const { id, ...changes } of itemsToUpdate) {
        changesById[id] = { ...changes };
    }

    const updatedItems = [...items].map((item) => {
        const changes = changesById[item.id];
        if (changes) {
            return { ...item, ...changes };
        } else {
            return item;
        }
    });

    return updatedItems;
};

export const _handelSortContacts = (contacts) => {
    return contacts.sort((a, b) => {
        return a.customer.full_name.localeCompare(b.customer.full_name);
    });
};

const sortIdsUnit = (ids) => {
    return ids
        .toString()
        .split(',')
        .sort((a, b) => +a - +b)
        .toString();
};

export const _handleCombineUnits = (units = [], prevUnitSelected = null) => {
    if (prevUnitSelected) {
        const results = [];

        units.forEach((unit) => {
            const { id, name, location_id } = unit;
            const index = results.findIndex((item) => item.name === name);
            if (index === DEFAULT_ALL) {
                results.push(unit);
            } else {
                const result = results[index];
                const prevUnitId = prevUnitSelected?.id?.toString() || [];
                if (prevUnitId.includes(id) || prevUnitId?.id === id) result['temp_select'] = true;
                if (!result['id'].includes(id)) result['id'] = result['id'] + ',' + id;
                if (!result['location_id'].includes(location_id))
                    result['location_id'] = result['location_id'] + ',' + location_id;
            }
        });

        // Handle final list units and selected unit
        let selected = prevUnitSelected;
        const finalResult = [];
        results.forEach((item) => {
            if (item.temp_select) selected = { ...item, id: sortIdsUnit(item.id) };
            finalResult.push({
                id: sortIdsUnit(item.id),
                name: item.name,
                location_id: item.location_id
            });
        });

        return { units: results, selected };
    } else {
        if (!units) return [];
        const results = [];

        units.forEach((unit) => {
            const { id, name, location_id } = unit;
            const index = results.findIndex((item) => item.name === name);
            if (index === -1) {
                results.push(unit);
            } else {
                const result = results[index];
                if (!result['id'].includes(id)) result['id'] = result['id'] + ',' + id;
                if (!result['location_id'].includes(location_id))
                    result['location_id'] = result['location_id'] + ',' + location_id;
            }
        });

        return results.map((item) => {
            return {
                id: sortIdsUnit(item.id),
                name: item.name,
                location_id: item.location_id
            };
        });
    }
};

export const utilsDeleteUnit = ({ units = [], unit, justGetList = true, prevIdSelected = [] }) => {
    let shouldRemoveSelected = false;
    let newSelectedIds = '';
    const newList = [];

    units.forEach((item) => {
        const ids = item?.id?.toString()?.split(',');
        if (ids.includes(unit.unit_id)) {
            const finalIds = ids.filter((id) => +id !== +unit.unit_id) || [];
            if (prevIdSelected && prevIdSelected.includes(unit.unit_id)) newSelectedIds = finalIds.toString();
            if (finalIds.length) {
                newList.push({ ...item, id: finalIds.toString() });
            } else {
                shouldRemoveSelected = true;
            }
        } else {
            newList.push(item);
        }
    });

    if (justGetList) return newList;
    return { units: newList, shouldRemoveSelected, newSelectedIds };
};

// NOTES: Should check old name difference new name before run this function.
export const utilsUpdateUnit = ({ units = [], unit = null, prevSelected = null }) => {
    if (!prevSelected) return units;

    const results = [];
    let newUnit = null;
    let selected = null;
    const indexMatchName = units.findIndex((item) => item.name === unit.name);

    const unitName = unit.name;
    const unitId = unit.unit_id || unit.id;
    const prevSelectedIds = prevSelected.id || DEFAULT_ALL;
    const isOldSelectAll = prevSelectedIds === DEFAULT_ALL;
    const isSelectedUnit = isOldSelectAll
        ? prevSelectedIds === unitId || prevSelectedIds.toString().includes(unitId)
        : false;

    [...units].forEach((unitItem) => {
        const itemIds = unitItem?.id?.toString()?.split(',');
        const isSelectedUnit = prevSelectedIds === itemIds || prevSelectedIds.toString().includes(itemIds);

        if (itemIds.includes(unitId)) {
            const isJustUpdateName = itemIds.length <= 1;
            if (isJustUpdateName) results.push({ ...unitItem, name: unitName });
            if (!isJustUpdateName) {
                if (indexMatchName === -1) newUnit = unit;
                const newIds = itemIds.filter((id) => +id !== +unitId).toString();
                const newUnitItem = { ...unitItem, id: newIds };
                if (!selected && isSelectedUnit) selected = newUnitItem;
                results.push(newUnitItem);
                return;
            }
        } else {
            results.push(unitItem);
        }
    });

    // Remove or Update old unit have same name with new unit
    if (indexMatchName > -1 && !isSelectedUnit) {
        const indexOldUnit = units.findIndex((item) => item.id === unitId || item.id.includes(unitId));
        const oldUnit = units[indexOldUnit];
        if (
            indexOldUnit > -1 &&
            oldUnit &&
            oldUnit?.id?.toString()?.split(',')?.length === 1 &&
            unitName !== oldUnit.name
        )
            results.splice(indexOldUnit, 1);
    }

    if (indexMatchName > -1) {
        const itemMatched = results[indexMatchName];
        if (itemMatched) itemMatched['id'] = sortIdsUnit(itemMatched.id.concat(',', unitId));
        if (!selected && itemMatched && (!isOldSelectAll || isSelectedUnit)) selected = itemMatched;
    }

    if (newUnit) {
        const unitPush = {
            id: sortIdsUnit(newUnit.unit_id || newUnit.id),
            name: newUnit.name,
            location_id: newUnit.location_id
        };
        if (!selected && isSelectedUnit && !isOldSelectAll) selected = unitPush;
        results.push(unitPush);
    }

    if (!prevSelected) return results;
    return { units: results, selected };
};
