import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_WORK_LOG } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import IconTooltip from 'assets/icon/IconTooltip';
import { WORK_LOGS_ACTIONS } from 'common/redux/actions/worklogs';
import { getPlanUser } from 'common/utils/PermissionUtils';
import WorkLogItem from './components/WorkLogItem';
import { ManageLoading } from './components/WorkLogLoading';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const WorkLogManage = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const refAlert = useRef(null);

    const { isFirstTime, users } = useSelector(({ worklogs }) => worklogs);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const worklogAddon = useSelector(({ auth }) => auth.user.settings.addons.work_log);
    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermissionManage = (isProPlan || isTrial || isGrowthPlan) && !!worklogAddon;

    useEffect(() => {
        if (!isFirstTime) return;
        dispatch({ type: WORK_LOGS_ACTIONS.GET_LIST_USERS_REQUEST, actionFailed: _handleGetFail });
    }, []);

    const _handleGetFail = ({ message }) => {
        refAlert.current?.showStatusBar('worklog', message, LIST_STATUS.ERROR);
    };
    if (!havePermissionManage) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS_WORK_LOG)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('back')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --clock container-print">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables">
                        <div className="tables table-multi-column">
                            <div className="rows --fixed --header">
                                <div className="col col-lg">
                                    <p className="col-label">User</p>
                                </div>
                                <div className="col col-sm">
                                    <p className="col-label">Time Clocking</p>
                                </div>
                                <div className="col col-sm flexcenter gap-4">
                                    <p className="col-label">Edit Time</p>
                                    <div className="tooltip">
                                        <IconTooltip />
                                        <span className="tooltiptext bottom">
                                            This permission grants access to edit everyone's time and is recommended for
                                            admins. All users can edit their own time without permission control
                                        </span>
                                    </div>
                                </div>
                                <div className="col col-rate">
                                    <p className="col-label">Rate</p>
                                </div>
                            </div>
                            {isFirstTime ? (
                                <ManageLoading />
                            ) : (
                                <div className="tables-list">
                                    {!!users.length ? (
                                        users.map((item) => {
                                            return <WorkLogItem key={item.id} item={item} />;
                                        })
                                    ) : (
                                        <GridEmpty isFlat />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WorkLogManage;
