import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ADDONS } from 'app/config/routes';
import { ADDONS_CHATBOT_AI, UPDATE_ADDONS_CHATBOT_AI_OPTIONS } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import LoadingAddonsChatbotAI from './components/LoadingChatbotAI';

const AddonsChatBotAI = () => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { chatbotDetail: {}, isLoading: true });

    const { chatbotDetail, isLoading } = state;

    useEffect(() => {
        _getChatbotDetail();
    }, []);

    const _getChatbotDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ chatbotDetail: data, isLoading: false });
        clientQuery(ADDONS_CHATBOT_AI, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    const _handleUpdateSuccess = (checked) =>
        dispatchState((prev) => ({ ...prev, chatbotDetail: { ...prev.chatbotDetail, status: checked ? 1 : 0 } }));

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('addons')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <LoadingAddonsChatbotAI />
                    ) : (
                        <Fragment>
                            <div className="boxs boxs--no-border --hasline --mr-line">
                                <AddonsBoxHeader
                                    upgrade={chatbotDetail.upgrade}
                                    keyword={chatbotDetail.keyword}
                                    name={chatbotDetail.name}
                                    status={chatbotDetail.status}
                                    level={chatbotDetail.level}
                                    onUpdateStatusSuccess={_handleUpdateSuccess}
                                    urlUpdateStatus={UPDATE_ADDONS_CHATBOT_AI_OPTIONS}
                                />
                                {chatbotDetail?.sub_title ? (
                                    <div className="boxs__contents mt-3">
                                        <p className="description">{chatbotDetail.sub_title}</p>
                                    </div>
                                ) : null}
                            </div>
                            <AddonsOptions
                                isPowerUp
                                data={chatbotDetail?.options || []}
                                status={chatbotDetail?.status || false}
                                upgrade={chatbotDetail?.upgrade}
                                urlUpdate={UPDATE_ADDONS_CHATBOT_AI_OPTIONS}
                                handleManagerSuccess={() => {}}
                            />
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsChatBotAI;
