import React from 'react';

export default function Loading() {
    return (
        <div className="list-mapping">
            <h5 className="list-mapping__title">
                <div className="loading --animation --line --twothird" />
            </h5>
            <div className="list-mapping__content">
                <div className="col --tax-name">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --tag flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --icon flex-auto">
                    <div className="v2-btn-default --icon-lg">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            <div className="list-mapping__content">
                <div className="col --tax-name">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --tag flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --icon flex-auto">
                    <div className="v2-btn-default --icon-lg">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            <div className="list-mapping__content">
                <div className="col --tax-name">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --tag flex-1">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="col --icon flex-auto">
                    <div className="v2-btn-default --icon-lg">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        </div>
    );
}
