import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumnsSettingCustom = () => {
    const header = {
        type: {
            title: 'type',
            style: 'col --info'
        },
        name: {
            title: 'name',
            style: 'col --template'
        },
        status: {
            title: 'status',
            style: 'col --info'
        }
    };
    const columns = [
        {
            id: 'type',
            title: 'type',
            status: true
        },
        {
            id: 'name',
            title: 'name',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];
    const contentConfig = {
        type: {
            title: 'type',
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.SETTING_TYPE_CUSTOM
        },
        name: {
            title: 'name',
            style: 'col --template --name',
            type: LIST_TABLE_ITEM_TYPE.NAME_CLICK
        },
        status: {
            title: 'status',
            style: 'col --info',
            type: LIST_TABLE_ITEM_TYPE.SETTING_STATUS_SYSTEM
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
