import loadable from '@loadable/component';
import React, { Fragment, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { TYPE_OPEN_MODAL } from '../../../constant';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import ServicesBalance from '../../services/ServicesBalance';
import LoadingSideMenu from '../LoadingSideMenu';
import LocationsCollapse from '../LocationsCollapse';
import SideMenuFilter from '../SideMenuFilter';
import BlockContacts from './BlockContacts';
import BlockInfo from './BlockInfo';
import BlockOpportunity from './BlockOpportunity';
import BlockTask from './task';
import { TYPE_OPEN_SMS } from 'app/const/Sms';

const SmsJobDetail = loadable(() => import('app/modules/jobdetail/components/sms/Sms'));

const SideMenu = () => {
    const { t } = useTranslation(['common', 'customers']);
    const { customer, isLoading, _handleUpdateCustomer } = useContext(CustomerDetailContext);
    const { balance, credits, id: customerId } = customer;
    const refWarning = useRef(null);
    const refBlockTask = useRef(null);
    const refBlockOpportunity = useRef(null);

    const _openConfirm = (dataOpen) => {
        refWarning.current?._open(dataOpen);
    };

    const _closeConfirm = () => {
        refWarning.current?._close();
    };

    const _closeOtherModal = (type) => {
        switch (type) {
            case TYPE_OPEN_MODAL.OPPORTUNITY:
                refBlockTask.current?.closeModal();
                break;
            case TYPE_OPEN_MODAL.TASK:
                refBlockOpportunity.current?.closeModal();
                break;
            default:
                break;
        }
    };

    if (isLoading) return <LoadingSideMenu />;
    return (
        <Fragment>
            <div className="sidebar-details">
                <SideMenuFilter />
                <div className="scrolls">
                    <BlockInfo customer={customer} credits={credits} balance={balance} />
                    <div className="sidebar-details__content">
                        <BlockTask
                            ref={refBlockTask}
                            onConfirm={_openConfirm}
                            onCloseConfirm={_closeConfirm}
                            onCloseOtherModal={_closeOtherModal}
                        />
                        <BlockOpportunity
                            ref={refBlockOpportunity}
                            onConfirm={_openConfirm}
                            onCloseConfirm={_closeConfirm}
                            onCloseOtherModal={_closeOtherModal}
                        />
                        <BlockContacts />
                        <div className="wrap-locations">
                            <LocationsCollapse />
                        </div>
                        <ServicesBalance customerId={customerId} onUpdate={_handleUpdateCustomer} />
                    </div>
                </div>
            </div>

            {!isLoading && !!customer && (
                <SmsJobDetail smsTo={[]} customer={customer || {}} keyTriggerOpen={TYPE_OPEN_SMS.CUSTOMER} />
            )}
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                title={t('customers:delete_opportunity')}
                description={t('customers:are_you_sure_delete_opportunity')}
                footer={<FooterConfirm />}
            />
        </Fragment>
    );
};

const FooterConfirm = ({ onClose = () => {}, onConfirm = () => {} }) => {
    const { t } = useTranslation(['common', 'customers']);
    const refButtonSave = useRef(null);
    return (
        <div className="footer-modal footer-hasbtn btn-close">
            <span className="v2-btn-default --transparent" onClick={onClose}>
                {t('cancel')}
            </span>
            <ButtonSave ref={refButtonSave} title={t('yes')} className="v2-btn-main" onSave={onConfirm} />
        </div>
    );
};
export default SideMenu;
