import PropTypes from 'prop-types';
import React from 'react';

import { transformToCurrency } from 'common/utils/NumberUtils';
import { formatTimeUnix } from 'common/utils/TimeUtils';

const GdGridRowTotal = ({ columns, contentConfig, showCheckBox, isLoading, zIndex = 1, currency }) => {
    const _renderListItem = (list) => {
        if (isLoading) return null;
        return list.map((item) => {
            const { id = '', totalAmount, title = '', style = '' } = item;
            if (item.isShow) {
                if (title) {
                    return (
                        <div className={`${contentConfig[id]?.style}` || style} key={id}>
                            <p className="col-label">{title}</p>
                        </div>
                    );
                }

                if (item.isCurrency) {
                    const finalValue = transformToCurrency(totalAmount, currency);
                    return (
                        <div key={id} className={`${contentConfig[id]?.style}` || style} title={finalValue}>
                            <p className="col-label">{finalValue}</p>
                        </div>
                    );
                }

                if (item.isDuration) {
                    const finalValue = formatTimeUnix(item.duration || 0);
                    return (
                        <div key={id} className={`${contentConfig[id]?.style}` || style} title={finalValue}>
                            <p className="col-label">{finalValue}</p>
                        </div>
                    );
                }

                return (
                    <div key={id} className={`${contentConfig[id]?.style}` || style} title={totalAmount}>
                        <p className="col-label">{totalAmount}</p>
                    </div>
                );
            } else {
                if (title) {
                    return <div key={id} className={`${contentConfig[id]?.style}` || style}></div>;
                }

                return null;
            }
        });
    };

    return columns.length > 0 ? (
        <div id="row_total" className="rows --fixed --total" style={{ zIndex }}>
            {showCheckBox && <div className="col --checkbox"></div>}
            {_renderListItem(columns)}
        </div>
    ) : null;
};

GdGridRowTotal.propTypes = {
    columns: PropTypes.array,
    showCheckBox: PropTypes.bool,
    isLoading: PropTypes.bool
};

GdGridRowTotal.defaultProps = {
    columns: [],
    showCheckBox: false,
    isLoading: false
};

export default GdGridRowTotal;
