import moment from 'moment';
import { MONTHS, EXCEPTON_OPTIONS } from 'app/modules/jobdetail/const';
import { createPopper } from '@popperjs/core';
import { convertTimeToNumber } from 'common/utils/TimeUtils';
import store from 'common/redux/store/configureStore';

export const getDayOfWeekModMonth = (t, type, date, interval) => {
    const momentDate = moment(date).utc();
    const dateInMonth = momentDate.format('DD');
    interval = parseInt(interval);

    if (type === 'dayofmonth') {
        const newDate = momentDate.format('Do');
        if (interval > 1) {
            return `${t('jobDetail:every')} ${interval} ${t('jobDetail:months')} ${t('jobDetail:on_the')} ${newDate}`;
        } else {
            return `${t('jobDetail:monthly')} ${t('jobDetail:on_the')} ${newDate}`;
        }
    }

    const weekIndex = Math.ceil(dateInMonth / 7);
    const renderTextWeekOfMonth = new Map([
        [1, t('jobDetail:txt_the_1st')],
        [2, t('jobDetail:txt_the_2nd')],
        [3, t('jobDetail:txt_the_3rd')],
        [4, t('jobDetail:txt_the_4th')],
        [5, t('jobDetail:txt_the_last')]
    ]);

    if (interval > 1) {
        return `${t('jobDetail:every')} ${interval} ${t('jobDetail:months')} on ${renderTextWeekOfMonth
            .get(weekIndex)
            .concat(' ', momentDate.format('dddd'))}`;
    } else {
        return `${t('jobDetail:monthly')} on ${renderTextWeekOfMonth
            .get(weekIndex)
            .concat(' ', momentDate.format('dddd'))}`;
    }
};

export const getDayOfWeekModYear = (t, type, date, interval) => {
    const momentDate = moment(date).utc();
    const dateInMonth = momentDate.format('DD');
    interval = parseInt(interval);

    if (type === 'dayofyear') {
        if (interval > 1) {
            return `${t('jobDetail:every')} ${interval} ${t('jobDetail:years')} ${t(
                'jobDetail:on_the'
            )} ${momentDate.format('MMM Do')}`;
        } else {
            return `${t('jobDetail:yearly')} ${t('jobDetail:on_the')} ${momentDate.format('MMM Do')}`;
        }
    }

    const weekIndex = Math.ceil(dateInMonth / 7);
    const renderTextWeekOfMonth = new Map([
        [1, t('jobDetail:txt_the_1st')],
        [2, t('jobDetail:txt_the_2nd')],
        [3, t('jobDetail:txt_the_3rd')],
        [4, t('jobDetail:txt_the_4th')],
        [5, t('jobDetail:txt_the_last')]
    ]);

    if (interval > 1) {
        return `${t('jobDetail:every')} ${interval} ${t('jobDetail:years')} on ${renderTextWeekOfMonth.get(
            weekIndex
        )} ${momentDate.format('ddd')} of ${momentDate.format('MMM')}`;
    } else {
        return `${t('jobDetail:yearly')} on ${renderTextWeekOfMonth.get(weekIndex)} ${momentDate.format(
            'ddd'
        )} of ${momentDate.format('MMM')}`;
    }
};

export const getSummaryWeekly = (t, value, days) => {
    let summary = `${t(`jobDetail:every`)} ${value} ${t(`jobDetail:weeks`)}`;
    const newInterval = Number(value);

    if (newInterval) {
        if (newInterval > 1) {
            summary = `${t(`jobDetail:every`)} ${newInterval} ${t(`jobDetail:weeks`)}`;
        } else {
            summary = t(`jobDetail:weekly`);
        }
    }

    if (typeof days === 'object') {
        const daysKeys = Object.keys(days);
        const strings = [];
        daysKeys.forEach((item) => {
            if (days[item] === '1') {
                strings.push(t(`jobDetail:txt_${item}`));
            }
        });

        /**
         * Check case add service not required select weekday
         */
        if (strings.length !== 0) {
            summary = summary.concat(' on ', ` ${strings.join(', ')}`);
        }
    }

    return summary;
};

export const getSummaryDaily = (t, value) => {
    const newInterval = Number(value);
    let summary = `${t(`jobDetail:daily`)}`;

    if (newInterval > 1) {
        summary = `${t(`jobDetail:every`)} ${newInterval} ${t(`jobDetail:days`)}`;
    }

    return summary;
};

export const getExceptMonth = () => {};

export const parseExceptionMonth = (exception) => {
    const values = Object.values(exception);

    const result = values
        .map((item, index) => {
            if (index === 0) return MONTHS.find((o) => o.value === parseInt(item))?.name || '';
            else {
                const parsedInt = parseInt(item);
                if (parsedInt) {
                    const value = parsedInt - 12 * (index + 1) - 1;
                    return MONTHS.find((o) => o.value === value)?.name || '';
                }
                return '';
            }
        })
        .filter((item) => item !== null && item !== '');

    return result;
};

export const converMonths = (monthActive) => {
    const keys = Object.keys(EXCEPTON_OPTIONS);
    const result = { ...EXCEPTON_OPTIONS };

    monthActive.forEach((month, index) => {
        const resultKey = keys[index];

        if (index === 0) {
            const value = MONTHS.find((o) => o?.name === month);
            result[resultKey] = value?.value || '';
        } else {
            const month_idx = MONTHS.findIndex((o) => o?.name === month);
            result[resultKey] = 31 + 12 * (index - 1) + month_idx;
        }
    });

    return result;
};

export const timeConvert = (num) => {
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);

    return {
        minute: rminutes,
        hour: rhours
    };
};

export const checkRepeatWeekLy = (jobCurrentceDetail, date) => {
    const isActiveWeekDay =
        parseInt(jobCurrentceDetail.MO) === 1 ||
        parseInt(jobCurrentceDetail.TU) === 1 ||
        parseInt(jobCurrentceDetail.WE) === 1 ||
        parseInt(jobCurrentceDetail.TH) === 1 ||
        parseInt(jobCurrentceDetail.FR) === 1 ||
        parseInt(jobCurrentceDetail.SA) === 1 ||
        parseInt(jobCurrentceDetail.SU) === 1;

    if (!isActiveWeekDay) {
        return {
            ...jobCurrentceDetail,
            [moment.utc(date).format('dd').toUpperCase()]: '1'
        };
    }

    return {
        ...jobCurrentceDetail
    };
};

export const handleCreatePopperAttach = (idParent, idTooltip) => {
    const parentElement = document.getElementById(idParent);
    const tooltip = document.getElementById(`${idTooltip}`);

    if (parentElement && tooltip) {
        createPopper(parentElement, tooltip, {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
        });
    }
};

export const handleGetInfoAttach = (data = {}) => {
    let value = data?.value;

    if (value) {
        const splitValue = value.split('.');
        const typeName = splitValue[splitValue.length - 1] || '';
        const prefixName = value.substr(0, value.length - typeName.length - 1) || '';

        if (prefixName.length > data.limitCharacter) {
            value = `${prefixName.substr(0, data.maxCharacter)}...${typeName}`;
        }
        return value;
    }
};

export const convertTimeWindow = ({ repeatSettings, startMoment, jobLength = 0 }) => {
    const { timeWindowReducer, auth } = store.getState();
    const listTemplates = timeWindowReducer.list || [];
    const isActiveTimeGlobal = !!auth.user.settings.addons?.time_window?.time_window_global;
    const objectTimeWindow = repeatSettings.time_window;

    if (!repeatSettings.changedTimeWindow && isActiveTimeGlobal) {
        const startTimeWindow = convertTimeToNumber(startMoment.format('HH'), startMoment.format('mm'));

        const findTemplate = listTemplates.find(
            (item) => parseInt(item.start) <= startTimeWindow && startTimeWindow < parseInt(item.end)
        );
        if (findTemplate) return findTemplate;

        return {
            ...objectTimeWindow,
            start: startTimeWindow,
            end: startTimeWindow + jobLength,
            id: ''
        };
    }

    return objectTimeWindow;
};
