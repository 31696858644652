import { takeLatest } from 'redux-saga/effects';
import { AUTH_TYPE } from 'common/redux/actions/authAction';

import { userLogin, userLoginSocial, getRetrieveUser, userSignupSocial, userLogout, userLoginByToken } from './login';
import { userSignup, userActivateAccount, signupSocialCompany } from './signup';

import { userForgotPassword } from './forgot';
import { userChangePassword } from './change';
import { refreshListJobStatus, userGetSignature } from './setting';

export function* authWatcher() {
    yield takeLatest(AUTH_TYPE.LOGIN_USER_REQUESTING, userLogin);
    yield takeLatest(AUTH_TYPE.LOGIN_BY_TOKEN_REQUEST, userLoginByToken);
    yield takeLatest(AUTH_TYPE.SIGNUP_SOCIAL_REQUESTING, userSignupSocial);
    yield takeLatest(AUTH_TYPE.LOGIN_SOCIAL_REQUESTING, userLoginSocial);
    yield takeLatest(AUTH_TYPE.FORGOT_PASSWORD_USER_REQUESTING, userForgotPassword);
    yield takeLatest(AUTH_TYPE.CHANGE_PASSWORD_USER_REQUESTING, userChangePassword);
    yield takeLatest(AUTH_TYPE.SIGNUP_USER_REQUESTING, userSignup);
    yield takeLatest(AUTH_TYPE.ACTIVATE_USER_ACCOUNT, userActivateAccount);
    yield takeLatest(AUTH_TYPE.SIGNUP_SOCIAL_COMPANY, signupSocialCompany);
    yield takeLatest(AUTH_TYPE.GET_RETRIEVE_USER, getRetrieveUser);
    yield takeLatest(AUTH_TYPE.LOG_OUT_REQUEST, userLogout);
    yield takeLatest(AUTH_TYPE.GET_EMAIL_INBOX_SIGNATURE, userGetSignature);
    yield takeLatest(AUTH_TYPE.GET_LIST_CUSTOM_JOB_STATUS, refreshListJobStatus);
}
