import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RouteRender } from 'app/components/routes/RouteWithSubRoutes';
import { ADDONS } from 'app/config/routes';
import { addPrefixPath } from 'app/const/Branch';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { Route, matchPath, withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { ADDONS_DASHBOARD_LOCAL_KEY } from '../constants';
import AddonsDashboard from '../dashboard';

import '../css/addons.scss';
import ErrorPage from 'app/modules/error';

const AddonsLayout = ({ routes = [] }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation('');
    const localValue = getLocalStorage(ADDONS_DASHBOARD_LOCAL_KEY);

    const isAddonsDashBoard = matchPath(pathname, {
        path: addPrefixPath(ADDONS),
        exact: true
    });

    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('addons-page', 'container-main');
        return () => {
            mainPageDiv.classList.remove('addons-page', 'container-main');
        };
    }, []);

    if (!isAddonsDashBoard) {
        return (
            <div className="addons-wrap container-wrap">
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }
                    return (
                        <Route
                            key={i}
                            path={result.path}
                            exact
                            render={(prop) =>
                                !route?.isHidden ? (
                                    <RouteRender props={prop} route={route} />
                                ) : (
                                    <ErrorPage errorMessage={t('common:page_is_unavailable')} />
                                )
                            }
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container addons-wrap__container-dashboard">
                <div
                    id="dashboard-container"
                    className={classNames('tabs-dashboard accessible-tabs-container', {
                        'is-grid-view': localValue?.isGridView || false
                    })}
                >
                    <AddonsDashboard />
                </div>
            </div>
        </div>
    );
};

export default withRouter(AddonsLayout);
