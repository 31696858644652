import { NOTES_TEMPLATES_ACTION } from '../actions/notesAction';

const notesReducer = (
    state = {
        resetTemplates: 0,
        toggleInsert: null
    },
    action
) => {
    switch (action.type) {
        case NOTES_TEMPLATES_ACTION.RESET_NOTES_TEMPLATES:
            return { ...state, resetTemplates: Date.now() };
        case NOTES_TEMPLATES_ACTION.TOGGLE_IMPORT_JOB_NOTES:
            return { ...state, toggleInsert: action.params };
        default:
            return state;
    }
};

export default notesReducer;
