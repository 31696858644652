import SyncGoogleCalendar from 'app/modules/syncGoogleCalendar';
import IconCalendar from 'assets/icon/IconCalendar';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SyncWithGGAction = () => {
    const { t } = useTranslation('header');
    const refSyncCalendar = useRef(null);

    const _handleSyncGoogleCalendar = () => {
        refSyncCalendar.current._open();
    };

    return (
        <>
            <li
                className="items has-icon"
                onClick={_handleSyncGoogleCalendar}
            >
                <IconCalendar isGoogle />
                {t('header:sync_with_google_calendar')}
            </li>
            <SyncGoogleCalendar ref={refSyncCalendar} />
        </>
    );
};

export default SyncWithGGAction;
