export const TYPE_REPORT_PAYMENTS_COLLECTED = {
    GET_LIST: 'GET_LIST_REPORT_PAYMENTS_COLLECTED',
    GET_LIST_YEARS: 'GET_LIST_YEARS_PAYMENTS_COLLECTED',
    GET_LIST_SERVICE_FILTER_REQUEST: 'GET_LIST_SERVICE_FILTER_REQUEST',
    GET_LIST_CHART: 'GET_LIST_DATA_CHART_PAYMENTS_COLLECTED',
    GET_LIST_STAFFS_FILTER: 'GET_LIST_STAFFS_FILTER_PAYMENTS_COLLECTED',
    GET_LIST_METHOD_FILTER: 'GET_LIST_METHOD_FILTER_PAYMENTS_COLLECTED'
};

export const getListReportPaymentsCollected = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListServiceFilterRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_SERVICE_FILTER_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const getDataChartPaymentsCollected = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_CHART,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListYearPaymentsCollected = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_YEARS,
        actionSuccess,
        actionFailed
    };
};

export const getListStaffFilterPaymentsCollected = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_STAFFS_FILTER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterPaymentsCollected = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_PAYMENTS_COLLECTED.GET_LIST_METHOD_FILTER,
        actionSuccess,
        actionFailed
    };
};
