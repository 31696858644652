import MultiSelect from 'app/components/select/multiSelect';
import SingleSelect from 'app/components/select/singleSelect';
import multiSelectClients from 'app/components/select/multiSelectClients';
import SelectWithSearch from 'app/components/select/SelectWithSearch';
import ReportSort from 'app/modules/report/components/ReportSort';
import FilterCustomers from 'app/modules/report/filter/Customers.js';
import MultiSelectRating from 'app/components/select/multiSelectRating';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';

export const TypeDropdown = {
    [TYPE_SELECT.MULTI]: MultiSelect,
    [TYPE_SELECT.SINGLE]: SingleSelect,
    [TYPE_SELECT.CUSTOMERS]: FilterCustomers,
    [TYPE_SELECT.CLIENTS]: multiSelectClients,
    [TYPE_SELECT.SORT]: ReportSort,
    [TYPE_SELECT.SELECT_WITH_SEARCH]: SelectWithSearch,
    [TYPE_SELECT.MULTI_RATING]: MultiSelectRating
};
