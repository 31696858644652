import { call } from 'redux-saga/effects';
import {
    deleteWithToken,
    fetchWithToken,
    putWithToken
} from 'common/utils/ApiUtils';
import {
    REPORT_ESTIMATE_GET_LIST,
    REPORT_ESTIMATE,
    REPORT_ESTIMATE_TRASH
} from 'app/const/Api';

export function* getListReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_ESTIMATE_GET_LIST,
            params
        );
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(deleteWithToken, REPORT_ESTIMATE, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* activeReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(putWithToken, REPORT_ESTIMATE, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* archiveReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(putWithToken, REPORT_ESTIMATE, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* unDeleteReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(putWithToken, REPORT_ESTIMATE, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* trashReportEstimatesRequest({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(
            deleteWithToken,
            REPORT_ESTIMATE_TRASH,
            params
        );
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
