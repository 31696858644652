import { EMAIL_TEMPLATE } from 'common/redux/actions/email/template';

const emailTemplateReducer = (state = { templates: [], first_time: true }, action) => {
    switch (action.type) {
        case EMAIL_TEMPLATE.ACTION_GET_LIST_EMAIL_TEMPLATE_SUSSESS:
            return {
                ...state,
                templates: action.payload?.data || [],
                first_time: false
            };
        case EMAIL_TEMPLATE.UPDATE_EMAIL_TEMPLATE_SUCCESS:
            return { ...state, templates: [], first_time: true };
        default:
            return state;
    }
};

export default emailTemplateReducer;
