import React from 'react';

const ModalLoadingReviewManager = () => {
    return (
        <>
            <div className="wrap-loading header-modal">
                <div className="flex-1">
                    <div className="loading --animation --line --fourth" />
                </div>
            </div>
            <div className="wrap-loading body-modal --reviews">
                <div className="description">
                    <div className="loading --animation --line --threefourth" />
                    <div className="loading --animation --line --threefourth mt-1" />
                    <div className="loading --animation --line --half mt-1" />
                </div>
                <div className="link-review">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="v2-btn-default loading --grey w-100">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="row-multi mt-3">
                    <div className="description w-100">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --half mt-1" />
                    </div>
                </div>
            </div>
            <div className="wrap-loading footer-modal btn-close">
                <div className="v2-btn-default loading --transparent btn-x-sm ml-n1 btn-syned">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </>
    );
};

export default ModalLoadingReviewManager;
