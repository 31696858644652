import GdButton from 'app/components/button';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';

const HeaderModalEdit = ({ payment, onClose = () => {} }) => {
    const { t } = useTranslation(['common', 'customers']);
    const { isLoadingAdd, _handleSubmitUpdate } = useContext(SubscriptionContext);

    return (
        <div className="header-modal">
            <h3 className="header-modal__label">{t('customers:edit_subscription')}</h3>
            <div className="btn-action btn-close">
                <span className="v2-btn-default --transparent" onClick={onClose}>
                    {t('cancel')}
                </span>
                <GdButton
                    className="v2-btn-main"
                    isLoading={isLoadingAdd}
                    onClick={() => _handleSubmitUpdate(payment?.account_id)}
                >
                    {t('save')}
                </GdButton>
            </div>
        </div>
    );
};

export default HeaderModalEdit;
