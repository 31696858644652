import classNames from 'classnames';
import React, { forwardRef, useRef } from 'react';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { RESOURCE_TYPES } from '../../constant';

const AmountRow = ({
    id,
    group_id = '',
    isHaveOverlap = false,
    isDisableEdit = false,
    isEditing = false,
    isSubItem = false,
    expanded = false,
    isGroupExpander = false,
    isResourceEmpty = false,
    amount = 0,
    isHideEdit = false,
    onOpenMenu = () => {},
    onCloseMenu = () => {},
    onToggleActive = () => {},
    onToggleUpdate = () => {},
    onUpdateResource = () => {}
}) => {
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const formattedAmount = transformToCurrency(amount, currency);
    const refDropdownButton = useRef(null);
    const refButtonSave = useRef(null);

    const _handleOpenMenu = (type = RESOURCE_TYPES.CHILDREN) => {
        if (!refDropdownButton.current) return;
        const isActive = refDropdownButton.current.classList.contains('active');

        if (!isActive) {
            refDropdownButton.current.classList.toggle('active');
            let finalType = type;
            if (!isGroupExpander && type === RESOURCE_TYPES.GROUP) finalType = RESOURCE_TYPES.CHILDREN;
            onOpenMenu(refDropdownButton.current, { idSchedule: id, type: finalType });
        } else {
            onCloseMenu();
            onToggleActive();
        }
    };

    const _handleCancelUpdate = () => {
        onToggleUpdate({ idResource: id, type: 'off' });
    };

    const _handleUpdate = () => {
        const _callBack = () => {
            refButtonSave.current?.removeLoading();
            onToggleUpdate({ idResource: id, type: 'off' });
        };
        onUpdateResource({ id, group_id }, _callBack);
    };

    if (isResourceEmpty) return null;
    if (isGroupExpander) {
        // If is group row don't display menu edit
        return (
            <div className={classNames('rows --content', { today: expanded, '--overlap': isHaveOverlap })}>
                <div className={classNames('col col-amount', { 'border-none': isHideEdit })}>
                    <p className="col-label fw-600">{formattedAmount}</p>
                </div>
                {isHideEdit ? null : (
                    <div className="col --menu">
                        <ThreeDotsButton
                            ref={refDropdownButton}
                            type={RESOURCE_TYPES.GROUP}
                            isDisable={isDisableEdit}
                            onOpenMenu={_handleOpenMenu}
                        />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            data-resource-id={id}
            className={classNames('rows --content', {
                'edit-clock': isEditing,
                'today-child': isSubItem,
                '--overlap-child': isHaveOverlap && !isEditing
            })}
        >
            <div className={classNames('col col-amount', { 'border-none': isHideEdit })}>
                <p className="col-label fw-600">{formattedAmount}</p>
            </div>
            {isHideEdit ? null : (
                <div className="col --menu">
                    {isEditing ? (
                        <div className="col-edit">
                            <div className="v2-btn-default --icon-lg" onClick={_handleCancelUpdate}>
                                <IconClose />
                            </div>
                            <ButtonSave
                                ref={refButtonSave}
                                wrapClass="v2-btn-main --icon-lg svg-white-stroke"
                                onSave={_handleUpdate}
                            >
                                <IconCheck />
                            </ButtonSave>
                        </div>
                    ) : (
                        <ThreeDotsButton
                            ref={refDropdownButton}
                            type={isSubItem ? RESOURCE_TYPES.CHILDREN : RESOURCE_TYPES.GROUP}
                            isDisable={isDisableEdit}
                            onOpenMenu={_handleOpenMenu}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const ThreeDotsButton = forwardRef(
    ({ isDisable = false, type = RESOURCE_TYPES.CHILDREN, onOpenMenu = () => {} }, ref) => {
        return (
            <div ref={ref} className={classNames('v2-dropdown track-col', { 'is-disable': isDisable })}>
                <div className="dropbtn v2-btn-default --icon-lg" onClick={() => onOpenMenu(type)}>
                    <IconThreeDots />
                </div>
            </div>
        );
    }
);

export default AmountRow;
