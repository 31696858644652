import { TYPE_ITEMS } from 'common/redux/actions/itemsAction';

const itemsReducer = (
    state = {
        list_items: [],
        first_time: true
    },
    action
) => {
    switch (action.type) {
        case TYPE_ITEMS.ACTION_GET_LIST_ITEMS_SUCCESS:
            return { ...state, list_items: action.payload, first_time: false };
        case TYPE_ITEMS.ACTION_RESET_ITEMS:
            return { ...state, list_items: [], first_time: true };
        default:
            return state;
    }
};

export default itemsReducer;
