import React, { forwardRef, useContext, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import AddSubscriptionProvider from '../context/SubscriptionContext';
import AlertAddSubscription from './components/AlertAddSubscription';
import BillingContent from './components/BillingContent';
import HeaderModalAdd from './components/HeaderModalAdd';
import PlansContent from './components/PlansContent';

const AddSubscription = forwardRef(({ typeSubscription, customer = {}, onAddSuccess = () => {} }, ref) => {
    const { t } = useTranslation('customers');
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });

    const { payments } = useContext(CustomerDetailContext);
    const isStripe = typeSubscription === PAYMENT_CARD_CUSTOMER.STRIPE;
    const paymentCard = isStripe
        ? customer?.payment_card
        : payments.find((card) => !!card.is_default && card.name.toLowerCase() === typeSubscription);
    const isDisplayContent =
        (payments.length && customer?.merchant_customer_id?.[typeSubscription]) || (isStripe && customer?.payment_card);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    return (
        <ReactModal
            id="add_subscription"
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal form-stripe-subscription --form-main open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <AddSubscriptionProvider
                defaultCardId={paymentCard?.account_id}
                typeSubscription={typeSubscription}
                onAddSuccess={onAddSuccess}
            >
                <div className="modal__container">
                    <HeaderModalAdd onClose={_close} isDisplayButton={isDisplayContent} isDisableSave={!paymentCard} />
                    <div className="body-modal scrolls">
                        {isDisplayContent ? (
                            <>
                                <AlertAddSubscription />
                                <div className="user-info">
                                    <div className="flexinline-center">
                                        <span className="avt fs-12">{customer.avatar}</span>
                                        <div className="name">{customer.full_name}</div>
                                    </div>
                                    {customer.email && <span className="info dots">{customer.email}</span>}
                                    <span className="info dots">
                                        {customer.merchant_customer_id?.[typeSubscription]}
                                    </span>
                                </div>
                                <PlansContent customerId={customer.id} />
                                <BillingContent paymentDefault={paymentCard} />
                            </>
                        ) : (
                            <div className="user-info">
                                <div className="flexinline-center">
                                    <div className="name">{t('report:card_no_longer_available')}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </AddSubscriptionProvider>
        </ReactModal>
    );
});

export default AddSubscription;
