import { call } from "redux-saga/effects";
import { fetchWithToken, postWithToken } from "common/utils/ApiUtils";
import {
  SETTINGS_DELETE_SCHEDULES,
  SETTINGS_REASSIGN_SCHEDULES,
  SETTINGS_ADD_AND_UPDATE_SCHEDULES,
  SETTINGS_GET_LIST_SCHEDULES,
} from "app/const/Api";

export function* getListSchedules({ actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, SETTINGS_GET_LIST_SCHEDULES);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* reassignSchedules({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(postWithToken, SETTINGS_REASSIGN_SCHEDULES, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* addAndUpdateSchedules({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(postWithToken, SETTINGS_ADD_AND_UPDATE_SCHEDULES, params);
    if (response && response.success) {
      actionSuccess(response, params);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* deleteSchedules({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(postWithToken, SETTINGS_DELETE_SCHEDULES, params);
    if (response && response.success) {
      actionSuccess();
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}
