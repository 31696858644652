import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { OBJECT_TYPES } from './types';

export const DEFAULT_SORT_VALUES = { actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET };
export const JOB_TABLE_DATA = {
    HEADER: {
        countActiveJob: {
            title: '#_active_jobs',
            style: 'col col-lg',
            keyGetDirection: 'count_active_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countUnconfirmedJob: {
            title: '#_unconfirmed_job',
            style: 'col col-lg',
            keyGetDirection: 'count_unconfirmed_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countConfirmedJob: {
            title: '#_confirmed_job',
            style: 'col col-lg',
            keyGetDirection: 'count_confirmed_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countRescheduleJob: {
            title: '#_reschedule_job',
            style: 'col col-lg',
            keyGetDirection: 'count_reschedule_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countPendingJob: {
            title: '#_pending_job',
            style: 'col col-lg',
            keyGetDirection: 'count_pending_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countCompletedJob: {
            title: '#_completed_job',
            style: 'col col-lg',
            keyGetDirection: 'count_completed_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        },
        countCanceledJob: {
            title: '#_canceled_job',
            style: 'col col-lg',
            keyGetDirection: 'count_canceled_job',
            objectType: OBJECT_TYPES.JOB,
            ...DEFAULT_SORT_VALUES
        }
    },
    COLUMNS: [
        { id: 'countActiveJob', title: 'countActiveJob', status: true },
        { id: 'countUnconfirmedJob', title: 'countUnconfirmedJob', status: true },
        { id: 'countConfirmedJob', title: 'countConfirmedJob', status: true },
        { id: 'countRescheduleJob', title: 'countRescheduleJob', status: true },
        { id: 'countPendingJob', title: 'countPendingJob', status: true },
        { id: 'countCompletedJob', title: 'countCompletedJob', status: true },
        { id: 'countCanceledJob', title: 'countCanceledJob', status: true }
    ],
    COLUMN_CONTENT: {
        countActiveJob: { title: '#_active_jobs', style: 'col col-lg' },
        countUnconfirmedJob: { title: '#_unconfirmed_job', style: 'col col-lg' },
        countConfirmedJob: { title: '#_confirmed_job', style: 'col col-lg' },
        countRescheduleJob: { title: '#_reschedule_job', style: 'col col-lg' },
        countPendingJob: { title: '#_pending_job', style: 'col col-lg' },
        countCompletedJob: { title: '#_completed_job', style: 'col col-lg' },
        countCanceledJob: { title: '#_canceled_job', style: 'col col-lg' }
    }
};

export const OPPORTUNITIES_TABLE_DATA = {
    HEADER: {
        activeOpportunityValue: {
            title: 'active_opportunity_value',
            style: 'col col-lg',
            keyGetDirection: 'total_value_active_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        wonOpportunityValue: {
            title: 'won_opportunity_value',
            style: 'col col-lg',
            keyGetDirection: 'total_value_won_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        lostOpportunityValue: {
            title: 'lost_opportunity_value',
            style: 'col col-lg',
            keyGetDirection: 'total_value_lost_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        opportunityValue: {
            title: 'opportunity_value',
            style: 'col col-lg',
            keyGetDirection: 'total_value_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        countOpportunity: {
            title: '#_opportunity',
            style: 'col col-lg',
            keyGetDirection: 'count_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        countActiveOpportunity: {
            title: '#_active_opportunity',
            style: 'col col-lg',
            keyGetDirection: 'count_active_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        countWonOpportunity: {
            title: '#_won_opportunity',
            style: 'col col-lg',
            keyGetDirection: 'count_won_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        },
        countLostOpportunity: {
            title: '#_lost_opportunity',
            style: 'col col-lg',
            keyGetDirection: 'count_lost_opportunity',
            objectType: OBJECT_TYPES.OPPORTUNITY,
            ...DEFAULT_SORT_VALUES
        }
    },
    COLUMNS: [
        {
            id: 'activeOpportunityValue',
            title: 'active_opportunity_value',
            status: true,
            keyGetValue: 'opportunityValue'
        },
        {
            id: 'wonOpportunityValue',
            title: 'won_opportunity_value',
            status: true,
            keyGetValue: 'opportunityValue'
        },
        {
            id: 'lostOpportunityValue',
            title: 'lost_opportunity_value',
            status: true,
            keyGetValue: 'opportunityValue'
        },
        { id: 'opportunityValue', title: 'opportunity_value', status: true, keyGetValue: 'opportunityValue' },
        { id: 'countOpportunity', title: 'countOpportunity', status: true },
        { id: 'countActiveOpportunity', title: 'countActiveOpportunity', status: true },
        { id: 'countWonOpportunity', title: 'countWonOpportunity', status: true },
        { id: 'countLostOpportunity', title: 'countLostOpportunity', status: true }
    ],
    COLUMN_CONTENT: {
        activeOpportunityValue: {
            title: 'activeOpportunityValue',
            style: 'col col-lg',
            styleText: 'col-label',
            typeValue: 'totalActive',
            type: LIST_TABLE_ITEM_TYPE.OPPORTUNITY_VALUE
        },
        wonOpportunityValue: {
            title: 'wonOpportunityValue',
            style: 'col col-lg',
            styleText: 'col-label',
            typeValue: 'totalWon',
            type: LIST_TABLE_ITEM_TYPE.OPPORTUNITY_VALUE
        },
        lostOpportunityValue: {
            title: 'lostOpportunityValue',
            style: 'col col-lg',
            styleText: 'col-label',
            typeValue: 'totalLost',
            type: LIST_TABLE_ITEM_TYPE.OPPORTUNITY_VALUE
        },
        opportunityValue: {
            title: 'opportunity_value',
            style: 'col col-lg',
            styleText: 'col-label',
            typeValue: 'totalAll',
            type: LIST_TABLE_ITEM_TYPE.OPPORTUNITY_VALUE
        },

        countOpportunity: { title: '#_opportunity', style: 'col col-lg' },
        countActiveOpportunity: { title: '#_active_opportunity', style: 'col col-lg' },
        countWonOpportunity: { title: '#_won_opportunity', style: 'col col-lg' },
        countLostOpportunity: { title: '#_lost_opportunity', style: 'col col-lg' }
    }
};

export const ESTIMATE_TABLE_DATA = {
    HEADER: {
        countEstimate: {
            title: '#_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        },
        countDraftEstimate: {
            title: '#_draft_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_draft_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        },
        countPendingEstimate: {
            title: '#_pending_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_pending_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        },
        countWonEstimate: {
            title: '#_won_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_won_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        },
        countInvoicedEstimate: {
            title: '#_invoiced_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_invoiced_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        },
        countLostEstimate: {
            title: '#_lost_estimates',
            style: 'col col-lg',
            keyGetDirection: 'count_lost_estimate',
            objectType: OBJECT_TYPES.ESTIMATE,
            ...DEFAULT_SORT_VALUES
        }
    },
    COLUMNS: [
        { id: 'countEstimate', title: 'countEstimate', status: true },
        { id: 'countDraftEstimate', title: 'countDraftEstimate', status: true },
        { id: 'countPendingEstimate', title: 'countPendingEstimate', status: true },
        { id: 'countWonEstimate', title: 'countWonEstimate', status: true },
        { id: 'countInvoicedEstimate', title: 'countInvoicedEstimate', status: true },
        { id: 'countLostEstimate', title: 'countLostEstimate', status: true }
    ],
    COLUMN_CONTENT: {
        countEstimate: { title: '#_estimate', style: 'col col-lg' },
        countDraftEstimate: { title: '#_draft_estimates', style: 'col col-lg' },
        countPendingEstimate: { title: '#_pending_estimates', style: 'col col-lg' },
        countWonEstimate: { title: '#_won_estimates', style: 'col col-lg' },
        countInvoicedEstimate: { title: '#_invoiced_estimates', style: 'col col-lg' },
        countLostEstimate: { title: '#_lost_estimates', style: 'col col-lg' }
    }
};
