import parsePhoneNumberFromString, { AsYouType } from 'libphonenumber-js';
import { companyCountryCode } from './ObjectUtils';
import { LIST_COUNTRY } from 'app/const/Settings';

/**
 * Validate phone number
 * @param {string || number} phone
 * @returns {boolean} phone is validate or not
 */
export function phoneValidate(phone) {
    const regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
    return phone.match(regex);
}

/**
 * Given a phone number in string form, return a formatted version of the number
 * @returns a string.
 */
export function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

export function formatPhoneNumberVoip(phoneNumber = '') {
    if (!phoneNumber) return '';

    const cleanedNumber = phoneNumber.toString().replace(/\D/g, '');
    const countryCode = companyCountryCode.countryCode.toUpperCase();

    if (cleanedNumber.length < 10) return cleanedNumber;

    if (parsePhoneNumberFromString(phoneNumber, countryCode)?.country === LIST_COUNTRY[0].value.toUpperCase())
        return formatPhoneNumber(phoneNumber) || phoneNumber;

    return new AsYouType(countryCode).input(phoneNumber) || phoneNumber;
}

export function validatePhoneNumberVoip(phoneNumber) {
    return (
        /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/.test(
            phoneNumber
        ) && !!Number(phoneNumber?.toString().replace(/\D/g, ''))
    );
}

export function formatPhoneUs(phone = '') {
    // check match phone US
    if (phone.match(/^(\+?1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/)) {
        return phone.replace(/\(?([2-9][0-8][0-9])\)?[-. ]?([2-9][0-9]{2})[-. ]?([0-9]{4})/, '($1) $2-$3');
    }
    return phone;
}

export function rawPhone(phone = '', isRemovePlus = false) {
    const raw = phone.replace(/(?:!^[\s\+]|$)\+|[^\d+]+/g, '');
    return isRemovePlus ? raw.replace(/\+/g, '') : raw;
}

export const formatPhoneTrial = (phone = '') => {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
