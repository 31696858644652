import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { formatPhoneNumberVoip, rawPhone, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import { SOURCE_PHONE_NUMBER_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import AvatarAutoAttendant from './AvatarAutoAttendant';
import IconClose from 'assets/icon/IconClose';
import IconCheck from 'assets/icon/IconCheck';

export const ForwardAutoAttendant = ({ item, onChangeForward = () => {}, onSelectPhone = () => {} }) => {
    const { t } = useTranslation('addons');
    const refInput = useRef(null);
    const refTimer = useRef(null);
    const refChanged = useRef(false);

    // eslint-disable-next-line no-undef
    const listNumber = global.listNumberVoip || [];
    const { id: finalId, numbers: finalPhone } = item;

    const [state, dispatchState] = useReducer(reducer, {
        isCustomPhone: false,
        numberSelected: finalPhone,
        phoneMap: listNumber.reduce((phones, phone) => {
            phones[phone.phone_number] = phone;
            return phones;
        }, {})
    });

    const { isCustomPhone, numberSelected, phoneMap } = state;

    const listCustomPhone = useMemo(() => {
        const array = [];
        numberSelected.forEach((item) => {
            if (!listNumber.some((phone) => phone.phone_number === item.phone_number)) {
                array.push({ ...item, isCustom: true });
            }
        });
        return array;
    }, [numberSelected]);
    const finalListPhone = [...listCustomPhone, ...listNumber];

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    const _customButton = () => {
        const _renderSelected = () => {
            let tooltip = '';
            let isShowComma = -1;
            return {
                content: numberSelected.map(({ phone_number: phoneItem }) => {
                    if (!phoneItem) return null;
                    const phone = phoneMap[phoneItem];
                    const assignees = phone?.assignees;
                    if (Array.isArray(assignees) && !assignees.length) return null;
                    const { full_name = '' } = getUserInfo(assignees?.[0]) || {};
                    const nameNumber = phone?.type === TYPE_PHONE_VOIP.GROUP ? t('group_number') : full_name;
                    const finalPhone = formatPhoneNumberVoip(phoneItem || '');
                    tooltip += `${nameNumber} ${finalPhone}`.trim() + '\n';
                    isShowComma++;

                    return (
                        <Fragment key={phoneItem}>
                            <span className={classNames({ 'mr-2': !!nameNumber })}>{`${
                                !!isShowComma ? ', ' : ''
                            }${nameNumber}`}</span>
                            <span className="black-darklight">{finalPhone}</span>
                        </Fragment>
                    );
                }),
                tooltip
            };
        };

        const { content, tooltip } = _renderSelected();
        return (
            <Fragment>
                <span className="phone-info txt-ellipsis fw-normal black-jungle-green" title={tooltip.trim()}>
                    {content}
                </span>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _renderContent = () => {
        const _renderItem = () => {
            return finalListPhone.map((item, index) => {
                const { id: number_id, phone_number, assignees, source, type, isCustom } = item;
                if (!phone_number) return null;
                const isGroup = type === TYPE_PHONE_VOIP.GROUP;
                const assigneeId = assignees?.[0];
                if ((!assigneeId && !isGroup && !isCustom) || source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) return null;

                const { avatar = '', full_name = '' } = getUserInfo(assigneeId) || {};
                const nameNumber = isGroup ? t('group_number') : full_name;
                const isActive = numberSelected?.some((phone) => phone.phone_number === item.phone_number);
                const idElement = index.toString() + phone_number;

                const _handleClick = () => {
                    _handleSelectOption({ value: { number_id, phone_number }, isChecked: !isActive });
                };

                return (
                    <li key={idElement} className={classNames('items', { active: isActive })}>
                        <div className="check-items">
                            <input id={idElement} type="checkbox" checked={isActive} onChange={_handleClick} />
                            <div className="item-checkbox">
                                <label htmlFor={idElement} className="user-name">
                                    <AvatarAutoAttendant isCustom={isCustom} isGroup={isGroup} avatar={avatar} />
                                    <span className="txt-ellipsis fw-500">
                                        {`${nameNumber} ${formatPhoneNumberVoip(phone_number)}`.trim()}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </li>
                );
            });
        };
        return (
            <Fragment>
                <div className="content-checked__all">{_renderCustomField()}</div>
                {!!finalListPhone.length ? <ul className="scrolls">{_renderItem()}</ul> : null}
            </Fragment>
        );
    };

    const _handleAddPhone = () => {
        const value = refInput.current?.value;
        clearTimeout(refTimer.current);
        if (
            !validatePhoneNumberVoip(value) ||
            finalListPhone.some((phone) => rawPhone(phone.phone_number, true) === rawPhone(value, true))
        ) {
            refInput.current?.classList?.add('field-error');

            refTimer.current = setTimeout(() => {
                refInput.current?.classList?.remove('field-error');
            }, 2000);
            return;
        }
        refChanged.current = true;
        dispatchState((prev) => {
            const numberSelected = [...prev.numberSelected, { number_id: null, phone_number: value }];
            onSelectPhone({ id: finalId, selected: numberSelected });
            return {
                ...prev,
                isCustomPhone: false,
                numberSelected
            };
        });
    };

    const _renderCustomField = () => {
        if (!isCustomPhone)
            return (
                <div className="items fw-500 grey-very-dark" onClick={() => _handleClickCustomPhone()}>
                    {t('custom_phone_number')}
                </div>
            );

        return (
            <div className="field-custom flexcenter gap-4">
                <input ref={refInput} className="field-input flex-1" type="number" placeholder={'+1'} autoFocus />
                <div className="v2-btn-default --icon-lg" onClick={() => _handleClickCustomPhone(false)}>
                    <IconClose />
                </div>
                <div className="v2-btn-main --icon-lg svg-white-stroke" onClick={_handleAddPhone}>
                    <IconCheck />
                </div>
            </div>
        );
    };

    const _handleClickCustomPhone = (value = true) => {
        dispatchState((prev) => ({ ...prev, isCustomPhone: value }));
    };

    const _handleSelectOption = ({ value, isChecked = false }) => {
        refChanged.current = true;
        dispatchState((prev) => {
            let finalSelected = prev.numberSelected;
            if (isChecked) finalSelected.push(value);
            else finalSelected = finalSelected.filter((item) => item.phone_number !== value.phone_number);
            onSelectPhone({ id: finalId, selected: finalSelected });
            return { ...prev, numberSelected: finalSelected };
        });
    };

    const _handleOnClose = () => {
        if (!refChanged.current) return;
        _handleClickCustomPhone(false);
        onChangeForward();
        refChanged.current = false;
    };

    return (
        <DropdownPopper
            wrapperClassName="v2-dropdown elm-custom-parent has-img-user "
            customButton={_customButton()}
            buttonClassName="dropbtn v2-btn-default --transparent w-100 pl-3"
            wrapperListClass="v2-dropdown__menu content-checked"
            isUseToggle
            strategy={'absolute'}
            onClose={_handleOnClose}
        >
            {_renderContent()}
        </DropdownPopper>
    );
};
