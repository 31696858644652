import SingleSelect from 'app/components/select/singleSelect';
import Switch from 'app/components/switch';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import GdLazyImage from 'app/components/lazyimage';
import GridLoading from 'app/components/grid/components/GridLoading';
import { TYPES_OPTION_ACTIVE } from '../const';
import GdConfirm from 'app/components/confirm';
import { useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { useDispatch } from 'react-redux';
import { applySettingFromAnotherUserCommission } from 'common/redux/actions/commissionAction';

const UserListCommission = ({
    updateOptions = () => {},
    users = [],
    userSelected = null,
    isLoading = true,
    getLineItems = () => {},
    selectUser = () => {}
}) => {
    const { t } = useTranslation(['common', 'commission']);
    const refApplySetting = useRef(null);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        userIdFrom: null,
        listButton: { close: true, confirm: false },
        contentConfirm: null
    });
    const { userIdFrom } = state;

    const { id: userId, full_name: toUserName } = userSelected || {};
    const fromUser = users.find((userTo) => userTo.id === userIdFrom) || {};
    const fromUserName = fromUser.full_name;

    const handleSwitchUser = (e, userId) => {
        updateOptions({ userId, keyword: TYPES_OPTION_ACTIVE.USER, value: e.target.checked ? 1 : 0 });
    };

    const handleOnClickUser = (value) => {
        if (value.id === userIdFrom) dispatchState({ userIdFrom: null });
        selectUser(value);
    };

    const handleChangeValue = (name, value) => {
        dispatchState({ userIdFrom: value });
    };

    const _convertUsersToDropdown = () => {
        const newUsers = users.filter((user) => user.id !== userId);
        return newUsers.map((item) => {
            return { id: item.id, name: item.full_name };
        });
    };

    const _renderContentCommissionSetup = () => (
        <>
            <p>{t('commission:message_apply_commision_setup', { fromUser: fromUserName, toUser: toUserName })}</p>
            <br />
            <p>{t('commission:can_not_reverted')}</p>
        </>
    );

    const _renderContentCommissionSetupSuccess = () => (
        <p>
            {t('commission:message_apply_commision_setup_success', {
                fromUser: fromUserName,
                toUser: toUserName
            })}
        </p>
    );

    const _openApplySettingConfirm = () => refApplySetting.current.open();

    const _handleApplySettingConfirm = () => {
        if (!userIdFrom || userId === userIdFrom) return;
        dispatchState({
            contentConfirm: _renderContentCommissionSetup(),
            listButton: { close: true, confirm: true }
        });
        _openApplySettingConfirm();
    };

    const applyAnotherUser = () => {
        dispatch(
            applySettingFromAnotherUserCommission(
                {
                    source: userIdFrom,
                    target: userId
                },
                applySettingAnotherUserSuccess
            )
        );
    };

    const applySettingAnotherUserSuccess = () => {
        getLineItems();
        dispatchState({ listButton: { close: true }, contentConfirm: _renderContentCommissionSetupSuccess() });
        _openApplySettingConfirm();
    };

    const renderListUsers = () => {
        return users.map((item) => {
            const itemId = item.id;
            return (
                <div
                    key={itemId}
                    onClick={() => handleOnClickUser(item)}
                    className={classNames('item-user', { selected: itemId === userId })}
                >
                    <div onClick={(e) => e.stopPropagation()}>
                        <Switch
                            checked={!!item.active}
                            handleToggle={(e) => handleSwitchUser(e, itemId)}
                            value={itemId}
                        />
                    </div>
                    <div className="item-user__avt">
                        <GdLazyImage src={item.avatar} alt={t('common:avatar')} width={32} height={32} />
                    </div>
                    <span className="item-user__name">{item.full_name}</span>
                </div>
            );
        });
    };
    return (
        <>
            <div className="setting-user">
                {isLoading ? <GridLoading /> : <div className="setting-user__list">{renderListUsers()}</div>}
                <div className="setting-user__bottom">
                    <p className="label">{t('commission:apply_settings_from_another_user')}</p>
                    <div className="flex-betweenitems">
                        <SingleSelect
                            options={_convertUsersToDropdown()}
                            name="user"
                            onSelect={handleChangeValue}
                            selected={userIdFrom}
                            classWrapper="v2-dropdown"
                            title={t('commission:select_user')}
                            onGetAsyncOptions={null}
                        />
                        <div className="v2-btn-main" onClick={_handleApplySettingConfirm}>
                            {t('commission:save')}
                        </div>
                    </div>
                </div>
            </div>
            <GdConfirm
                ref={refApplySetting}
                title={t('commission:commission_setup')}
                message={t('commission:update_another_user_success')}
                listButton={state.listButton}
                titleConfirm={t('commission:confirm_apply_settings')}
                onConfirm={applyAnotherUser}
                renderContent={state.contentConfirm}
            />
        </>
    );
};
export default UserListCommission;
