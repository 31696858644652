import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { updateOrderStatusOnTable } from 'app/modules/settings/utils/statusOrder.js';
import {
    LIST_TAB_BASIC,
    LIST_BUTTON_ACTION_PAYMENT_METHODS as LIST_BUTTONS,
    LIST_BUTTON_MESSAGES_PAYMENT_METHODS as LIST_BUTTON_MESSAGES,
    LIST_ACTION_BASIC
} from 'app/const/Settings';

export const TAB = {
    LIST: LIST_TAB_BASIC,
    DEFAULT_SELECTED: LIST_ACTION_BASIC.ACTIVE
};

export const TABLE = {
    header: {
        name: {
            title: 'methods',
            style: 'col --lg --full flex-1  js-order-by',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        }
    },
    columns: [
        {
            id: 'name',
            status: true
        }
    ],
    contentConfig: {
        name: {
            id: 'name',
            style: 'col --lg --full flex-1',
            styleText: 'col-label btn-modal',
            type: LIST_TABLE_ITEM_TYPE.LINK_PAYMENT_METHODS
        }
    }
};

const getColumns = ({ actionStatus, currentTab }) => {
    const newColumns = { ...TABLE };
    if (actionStatus) {
        newColumns.header = updateOrderStatusOnTable({ headerTable: newColumns.header, actionStatus: actionStatus });
    }
    if (currentTab === LIST_ACTION_BASIC.DELETE) {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.DEFAULT;
    } else {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.LINK_PAYMENT_METHODS;
    }

    return newColumns;
};

export { LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
