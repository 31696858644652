export const TYPE_SETTING_USER = {
    GET_LIST: 'GET_LIST_USER_SETTING',
    GET_DETAIL: 'GET_USER_DETAIL',
    DELETE: 'DELETE_USER_SETTING',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER'
};

export const getListUsersSettings = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_USER.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getUserDetail = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_USER.GET_DETAIL,
        params,
        actionFailed,
        actionSuccess
    };
};

export const createUser = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_USER.CREATE_USER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateUser = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_USER.UPDATE_USER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteUserSettings = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_USER.DELETE,
        params,
        actionSuccess,
        actionFailed
    };
};
