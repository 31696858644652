import React from 'react';

import IconArrow from 'assets/icon/IconArrow';
import { useFilters } from '../../context/addFilterContext';

const Category = ({ category }) => {
    const { handleCategoryId } = useFilters();
    const _handleClick = () => {
        handleCategoryId(category.id);
    };

    return (
        <li className="rows" onClick={_handleClick}>
            <div className="txt-ellipsis">{category.title}</div>
            <IconArrow />
        </li>
    );
};

export default Category;
