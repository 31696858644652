export default function IconMethod() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00003 7V6.47214C9.00003 6.16165 8.92774 5.85542 8.78888 5.57771L8.50003 5C8.32936 4.65867 8.46772 4.24361 8.80905 4.07295C8.90499 4.02498 9.01079 4 9.11806 4H14C14.5523 4 15 4.44772 15 5C15 5.62474 14.6878 6.20815 14.168 6.5547L12 8V8.9635C12 9.64327 12.1733 10.3118 12.5034 10.9061L14.524 14.5431C15.3286 15.9914 14.8068 17.8178 13.3584 18.6225C12.9127 18.8701 12.4113 19 11.9015 19H9.09857C7.44172 19 6.09857 17.6569 6.09857 16C6.09857 15.4902 6.2285 14.9887 6.4761 14.5431L8.49666 10.9061C8.82679 10.3118 9.00003 9.64327 9.00003 8.9635V7Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4346 8.46086C13.2084 10.328 14.1388 12.1886 15.6945 12.7435L17 12.5221C16.008 10.9719 15.5215 8.73331 15.5155 6.56564L13.4346 8.46086Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}
