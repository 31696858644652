import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { OPTIONS_VALUE_OPPORTUNITY } from 'app/const/opportunity';
import IconEdit from 'assets/icon/IconEdit';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import OpportunityContact from './OpportunityContact';
import { checkPermission } from 'common/utils/PermissionUtils';
import { ACCESS_CUSTOMER_OPP } from 'app/const/Permissions';

const Opportunity = ({
    id,
    note = '',
    contact_id = '',
    user = '',
    close_date = '',
    pipeline = {},
    value = {},
    period = {},
    confidence = 0,
    onUpdate = () => {}
}) => {
    return (
        <div className={`box-task box-opportunity --${pipeline?.status?.toLowerCase() || 'lost'}`}>
            <OpportunityUser
                idOpportunity={id}
                user={user}
                date={close_date}
                value={value || {}}
                period={period || {}}
                confidence={confidence}
                pipeline={pipeline}
                onUpdate={onUpdate}
            />
            <div className="status-btn">{pipeline?.name || 'Lost'}</div>
            {contact_id ? <OpportunityContact contactId={contact_id} /> : null}
            {note ? (
                <div className="description" style={{ whiteSpace: 'pre-wrap' }}>
                    {note}
                </div>
            ) : null}
        </div>
    );
};

const OpportunityUser = ({
    user = null,
    idOpportunity = '',
    date = '',
    value = {},
    period = {},
    confidence = 0,
    pipeline = {},
    onUpdate = () => {}
}) => {
    const currentUser = useSelector(({ auth }) => auth.user);
    const formatCompany = currentUser.company.date_format;

    const formatDisplay = convertPhpFormatToMoment(formatCompany);
    const createdAtTimezone = moment(date);
    const closeDateFormatted = date && createdAtTimezone.isValid() ? createdAtTimezone.format(formatDisplay) : '';
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const isHaveCustomerOpp = checkPermission(permissionsList, ACCESS_CUSTOMER_OPP);

    // Just display confidence when pipeline is active and confidence is not null|0
    const isDisplayConfidence = pipeline?.status?.toLowerCase() === 'active' && !!confidence;
    if (!user) return null;
    return (
        <div className="title">
            <div className="avt-img tooltip">
                <img src={user?.avatar} width={32} height={32} />
                <p className="tooltiptext top">{user.full_name}</p>
            </div>
            <div className="title__info">
                <OpportunityValueDisplay value={value} period={period} />
                <div className="time">
                    {isDisplayConfidence ? `${confidence}% on ` : ''}
                    {closeDateFormatted}
                </div>
            </div>
            {isHaveCustomerOpp ? (
                <span
                    id={`button-opportunity-${idOpportunity}`}
                    className="v2-btn-default --icon-sm --transparent ml-1 btn-modal"
                    onClick={() => onUpdate()}
                >
                    <IconEdit />
                </span>
            ) : null}
        </div>
    );
};

const OpportunityValueDisplay = ({ value = {}, period = {} }) => {
    const { t } = useTranslation('customers');
    if (value?.value === 0) return <div className="name">{t('unknown')}</div>;
    return (
        <div className="name">
            {value?.format}
            {period?.value !== OPTIONS_VALUE_OPPORTUNITY[0]['value'] ? ` ${period?.name}` : ''}
        </div>
    );
};

export default Opportunity;
