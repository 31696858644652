import { CUSTOMER_UPDATE_TASK_STATUS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { updateTaskStatus } from 'common/redux/actions/calendar/listTaskAction';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import TaskItem from './TaskItem';

const UpdateTaskModal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isEditing: false, task: {} });
    const refTaskItem = useRef(null);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        _openTask,
        _closeTask
    }));

    const _openTask = (task) => {
        dispatchState({ isOpen: true, task });
    };

    const _closeTask = () => {
        dispatchState({ isOpen: false, isEditing: false });
    };

    const _handleEditing = () => {
        dispatchState({ isEditing: !state.isEditing });
    };

    const _updateStatusTask = (dataTask, active) => {
        clientQuery(CUSTOMER_UPDATE_TASK_STATUS, {
            method: 'PUT',
            // eslint-disable-next-line no-undef
            data: { active, ids: [state.task.id], socket_id: global.socket_id_task_service }
        });
        dispatchState({ task: { ...state.task, ...dataTask, active } });
        dispatch(updateTaskStatus({ ...state.task, ...dataTask, active, typeAction: 'status' }));
    };

    const _handleUpdateTask = (dataUpdate) => {
        dispatchState({ task: { ...state.task, ...dataUpdate } });
        dispatch(updateTaskStatus({ ...dataUpdate, typeAction: 'update' }));
    };

    const _handleDeleteTask = (dataDelete) => {
        dispatch(updateTaskStatus({ ...dataDelete, typeAction: 'delete' }));
    };

    if (!state.isOpen) {
        return false;
    }

    return (
        <ReactModal
            id="viewtaskPopup"
            isOpen
            style={{ overlay: { zIndex: 999, backgroundColor: 'transparent' } }}
            className="modal container-modal --contains --viewtask-details open"
            onRequestClose={_closeTask}
        >
            <div onClick={_closeTask} className="modal__overlay bg-fixed" />
            <div className="modal__container tasks-list --in-details">
                <div className="header-modal">
                    <h2 className="header-modal__label">{state.isEditing ? t('edit_task') : t('task')}</h2>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_closeTask}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <TaskItem
                        ref={refTaskItem}
                        dataTask={state.task}
                        avatarSize={{ width: 32, height: 32 }}
                        isModalItem
                        onMarkDone={_updateStatusTask}
                        onEditing={_handleEditing}
                        onDelete={_handleDeleteTask}
                        onUpdate={_handleUpdateTask}
                    />
                </div>
            </div>
        </ReactModal>
    );
});
UpdateTaskModal.displayName = 'UpdateTaskModal';
export default UpdateTaskModal;
