import { createPopper } from '@popperjs/core';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import {
    CLASS_NAME_OPEN_LOCATION,
    LINK_OPEN_GOOGLE_COORDINATE,
    LIST_OPTION_OPEN_LOCATION,
    OPEN_COORDINATES,
    OPEN_GOOGLE_MAP
} from './constant';

const LocationDropdown = () => {
    const { t } = useTranslation();

    const [state, dispatchState] = useReducer(reducer, { dataLatLng: {}, address: '', visible: false, target: null });
    const { dataLatLng, address, visible, target } = state;

    const refPopper = useRef(null);
    const refInstance = useRef(null);
    const refOpen = useRef(false);
    const refFirstTime = useRef(true);

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_LOCATION_POPPER, _openPopper);

        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_LOCATION_POPPER, _openPopper);
        };
    }, []);

    useEffect(() => {
        if (visible) {
            refOpen.current = true;
            _createInstance();
            addEventListener('click', _handleClickOutside, true);
            addEventListener('scroll', _closeInstance, true);
            return;
        }
        if (!refFirstTime.current) {
            removeEventListener('click', _handleClickOutside, true);
            removeEventListener('scroll', _closeInstance, true);
            refInstance.current?.destroy();
            refOpen.current = false;
            return;
        }
        refFirstTime.current = false;
    }, [visible]);

    const _createInstance = () => {
        const eventTarget = target?.target;
        if (!eventTarget) return;
        const element = eventTarget.classList?.contains(CLASS_NAME_OPEN_LOCATION)
            ? eventTarget
            : eventTarget.parentElement;
        refInstance.current = createPopper(element, refPopper.current, {
            placement: 'bottom-start',
            modifiers: [{ name: 'offset', options: { offset: [0, 0], zIndex: 999 } }],
            strategy: 'fixed'
        });
    };

    const _closeInstance = useCallback(() => {
        dispatchState((prev) => ({ ...prev, visible: false }));
    }, []);

    const _openPopper = ({ detail }) => {
        const { isClose, target, isUpdate } = detail || {};
        if (isUpdate) {
            refInstance.current?.update();
            return;
        }
        if (isClose) {
            _closeInstance();
            return;
        }
        if (!target) return;
        target.stopPropagation();
        dispatchState((prev) => ({ ...prev, ...detail, visible: true }));
    };

    const _handleClickOutside = useCallback((e) => {
        if (!refOpen.current || refPopper.current.contains(e.target)) return;
        _closeInstance();
        refOpen.current = false;
    }, []);

    const _handleOpenMap = (id) => {
        let url = '';
        switch (id) {
            case OPEN_COORDINATES:
                const { lat = '', lng = '' } = dataLatLng;
                if (!lat || !lng) return alert(t('alert_open_coordinate'));
                url = `${LINK_OPEN_GOOGLE_COORDINATE}${lat},${lng}`;
                break;
            case OPEN_GOOGLE_MAP:
                url = address;
                break;
            default:
                break;
        }
        window.open(url);
        _closeInstance();
    };

    const _renderOptions = () => {
        return LIST_OPTION_OPEN_LOCATION.map(({ id, icon }, index) => {
            if (!id) return <div key={index.toString()} className="is-divider" />;
            const _handleClickOption = (e) => {
                e.stopPropagation();
                _handleOpenMap(id);
            };

            return (
                <li key={id} className="items has-icon" onClick={_handleClickOption}>
                    {icon}
                    <div className="txt-ellipsis fw-500 black-dark-charcoal fs-14">{t(id)}</div>
                </li>
            );
        });
    };

    if (!visible) return null;
    return (
        <div
            ref={refPopper}
            className="v2-dropdown dropdown-location active"
            style={{ zIndex: 999999, width: '345px' }}
        >
            <div className="v2-dropdown__menu">
                <ul className="flex-betweenitems">{_renderOptions()}</ul>
            </div>
        </div>
    );
};

export default LocationDropdown;
