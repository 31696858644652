export const TYPE_REPORT_PAYMENT = {
  GET_LIST: "GET_LIST_REPORT_PAYMENT",
  SEARCH: "SEARCH_REPORT_PAYMENT",
  DELETE_REQUEST: "DELETE_PAYMENT_REQUEST",
  SYNCQB_REQUEST: "SYNCQB_PAYMENT_REQUEST",
  GET_LIST_METHOD_FILTER_REQUEST: "GET_LIST_METHOD_FILTER_REQUEST",
}


// actions
export const getListReportPayment = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_PAYMENT.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const searchReportPayment = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_PAYMENT.SEARCH,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListMethodFilterRequest = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_PAYMENT.GET_LIST_METHOD_FILTER_REQUEST,
    actionSuccess,
    actionFailed
  };
}
export const deleteReportPaymentRequest = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_PAYMENT.DELETE_REQUEST,
    params,
    actionSuccess,
    actionFailed
  };
};

export const syncQbReportPaymentRequest = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_PAYMENT.SYNCQB_REQUEST,
    params,
    actionSuccess,
    actionFailed
  };
};

