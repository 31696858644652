import React, { useRef, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconProfile from 'assets/icon/IconProfile';
import { reducer } from 'app/const/Reducer';

const FilterAssign = ({ onSelect = () => {} }) => {
    const companyUsers = useSelector((state) => state.companyUsers.users);
    const userProfile = useSelector(({ auth }) => auth.user.profile);

    const [state, setState] = useReducer(reducer, {
        selected: null,
        isVisible: false
    });
    const refSearch = useRef(null);

    const { isVisible: finalIsVisible, selected: finalSelected } = state;

    useEffect(() => {
        if (userProfile) {
            const userId = userProfile.id.toString();
            onSelect(userId);
            setState({
                selected: {
                    id: userId,
                    first_name: userProfile.first_name,
                    last_name: userProfile.last_name
                }
            });
        }
    }, []);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('list_task_assign_to');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeListOptions();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('list_task_assign_to');
        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeListOptions();
        }
    }

    function _closeListOptions() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleSelectUser(e, user, isActive) {
        e.stopPropagation();

        if (isActive) {
            return false;
        }

        onSelect(user.id);
        setState({ selected: user, isVisible: false });
    }

    function _renderListCompanyUser() {
        return companyUsers.map((item) => {
            const isActive = finalSelected?.id === item.id;

            return (
                <li
                    key={item.id}
                    onClick={(e) => _handleSelectUser(e, item, isActive)}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <p className="txt-ellipsis">{item.first_name.concat(' ', item.last_name)}</p>
                </li>
            );
        });
    }

    return (
        <div ref={refSearch} className={`v2-dropdown select-list ${finalIsVisible ? 'active' : ''}`}>
            <div className="dropbtn items has-icon" onClick={(e) => _handleOpen(e)}>
                <IconProfile />
                <p className="txt-ellipsis">
                    {finalSelected ? finalSelected.first_name.concat(' ', finalSelected.last_name) : 'Assigned to'}
                </p>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </div>
            <div className="v2-dropdown__menu scrolls">
                <ul id="list_task_assign_to">{finalIsVisible && _renderListCompanyUser()}</ul>
            </div>
        </div>
    );
};

export default FilterAssign;
