import { CALENDAR_BROADCAST_ACTIONS } from 'common/redux/actions/calendar/broadcastAction';

const broadcastReducer = (state = {
    isFirstTime: true,
    data: []
}, action) => {
    switch (action.type) {
        case CALENDAR_BROADCAST_ACTIONS.CALENDAR_GET_TOTAL_BROADCAST_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: action.payload?.data || []
            };
        default:
            return state;
    }
};

export default broadcastReducer;
