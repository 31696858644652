import { updateTask } from 'app/const/Api';
import Date from 'app/modules/customer/task/components/Date';
import Time from 'app/modules/customer/task/components/Time.js';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { combineDateTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TASK_BACKGROUND_DEFAULT, TASK_BORDER_DEFAULT, TASK_CLASS_NAME, TASK_TEXT_DEFAULT } from '../../const';
import TaskAssignee from './TaskAssignee';
import TaskItemLogs from './TaskItemLogs';
import TaskItemTags from './TaskItemTags';

function TaskEditForm({
    dataTask = {},
    isVisible = false,
    isHideHeader = false,
    onUpdate = () => {},
    onDelete = () => {},
    onToggleVisible = () => {}
}) {
    const { t } = useTranslation();
    const calendarReducers = useSelector(({ calendar }) => calendar);
    const refInput = useRef(null);
    const refUpdate = useRef({});
    const refTaskTime = useRef({});
    const refTaskDate = useRef({});
    const dateStart = dataTask.date || dataTask.dateData;

    // Clear data update when unmount or renew open form
    useEffect(() => {
        refUpdate.current = {};
    }, [isVisible]);

    const _handleUpdate = () => {
        const objectUpdate = refUpdate.current;
        const title = refInput.current.value;
        const assignee = objectUpdate.assignee || dataTask.assignee;

        if (Object.keys(objectUpdate).length || title) {
            const taskDate = refTaskDate.current.getValue();
            const taskTime = refTaskTime.current.getValue();
            const finalDate = combineDateTimeToISO(taskDate, taskTime);
            const newSchedule = calendarReducers.schedules.find((item) => item.user_id === assignee.id);

            const optionsQuery = {
                data: {
                    title,
                    assignee: objectUpdate.assignee
                        ? parseInt(objectUpdate.assignee.id)
                        : parseInt(dataTask.assignee.id),
                    // eslint-disable-next-line no-undef
                    socket_id: global.socket_id_task_service,
                    date: finalDate
                },
                method: 'PUT'
            };

            onUpdate({
                ...dataTask,
                dateData: finalDate,
                start: finalDate,
                end: finalDate,
                date: finalDate,
                title,
                assignee,
                allDay: true,
                textColor: TASK_TEXT_DEFAULT,
                backgroundColor: TASK_BACKGROUND_DEFAULT,
                borderColor: TASK_BORDER_DEFAULT,
                classNames: TASK_CLASS_NAME,
                resourceId: newSchedule?.id || '',
                schedule: newSchedule
            });

            clientQuery(updateTask(dataTask.id), optionsQuery);
        }

        onToggleVisible();
    };

    const _handleSelect = (key, value) => {
        refUpdate.current = { ...refUpdate.current, [key]: value };
    };

    const _deleteTask = () => {
        const valueConfirm = window.confirm(t('are_you_sure_you_want_to_delete_this_task'));
        if (valueConfirm) onDelete(dataTask);
    };

    if (!isVisible) return null;

    return (
        <>
            <div className="form-editing">
                {!isHideHeader && (
                    <div className="form-editing__header">
                        <h2 className="fs-18 grey-extradark">{t('edit_task')}</h2>
                        <div onClick={onToggleVisible} className="v2-btn-default --icon-lg --transparent js-edit-tasks">
                            <IconClose />
                        </div>
                    </div>
                )}

                <div className="form-editing__content has-form">
                    <div className="rows">
                        <p className="txt">{t('assign_to')}</p>
                        <TaskAssignee defaultAssign={dataTask.assignee} onSelect={_handleSelect} />
                    </div>
                    <div className="rows row-haft mt-3">
                        <Date defaultValue={dataTask.date || dataTask.dateData} ref={refTaskDate} />
                        <Time defaultValue={moment(dateStart).utc().format('h:mm a')} ref={refTaskTime} />
                    </div>
                    <div className="rows --edit-field mt-3">
                        <p className="txt">{t('task_')}</p>
                        <textarea ref={refInput} autoFocus className="field-textarea --no-resize --h-120" defaultValue={dataTask.title} />
                    </div>
                    <TaskItemTags customer={dataTask.customer} job={dataTask.job} />
                    {dataTask.completed_by && (
                        <TaskItemLogs isVisible doneBy={dataTask.completed_by.full_name} time={dataTask.completed_at} />
                    )}
                </div>
                <div className="form-editing__footer">
                    <div className="v2-btn-default has-icon --delete" onClick={_deleteTask}>
                        <IconTrash />
                        {t('delete_task')}
                    </div>
                    <div className="d-flex">
                        <div onClick={onToggleVisible} className="v2-btn-default --transparent js-edit-tasks">
                            {t('cancel')}
                        </div>
                        <div className="v2-btn-main ml-2" onClick={_handleUpdate}>
                            {t('save')}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TaskEditForm;
