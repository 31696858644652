import { forwardRef, useReducer, useImperativeHandle, useRef } from 'react';
import IconClose from 'assets/icon/IconClose';
import SingleSelect from 'app/components/select/singleSelect';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

const RessignJob = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar, common']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        scheduleId: null
    });
    const refEvents = useRef(null);
    const schedulesReducer = useSelector((state) => state.schedules);

    function _showOptions(infoEvent, scheduleId, functionCallBack) {
        refEvents.current = {
            infoEvent,
            functionCallBack
        };

        dispatchState({
            isVisible: true,
            scheduleId: scheduleId
        });
    }

    function _closeOptions(e) {
        e.stopPropagation();
        dispatchState({ isVisible: false });
        refEvents.current && refEvents.current.infoEvent.revert();
    }

    function _handleSelectReassignJobs(name, newScheduleId) {
        dispatchState({
            scheduleId: newScheduleId
        });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _handleClickOption(all = 0) {
        refEvents.current && refEvents.current.functionCallBack(all, state.scheduleId);

        dispatchState({ isVisible: false });
    }

    if (!state.isVisible) {
        return false;
    }

    return (
        <ReactModal isOpen onRequestClose={_closeOptions} className="modal container-modal batch-job --recurring open">
            <div className="modal__overlay bg-fixed" onClick={(e) => _closeOptions(e)} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('calendar:reassign_jobs')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={(e) => _closeOptions(e)}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <div className="rows --multi">
                        <div className="txt">{t('calendar:reassign_this_job_to')}</div>
                        <SingleSelect
                            options={schedulesReducer.data?.schedules || []}
                            selected={state.scheduleId.toString()}
                            name="reassign_jobs"
                            fileTranslation="report"
                            onSelect={_handleSelectReassignJobs}
                            classWrapper={'v2-dropdown'}
                        />
                    </div>
                    <div className="rows" onClick={() => _handleClickOption(0)}>
                        <div className="v2-btn-default">{t('calendar:reassign_this_job_only')}</div>
                    </div>
                    <div className="rows" onClick={() => _handleClickOption(1)}>
                        <div className="v2-btn-default">{t('calendar:reassign_this_job_and_all_recurring')}</div>
                    </div>
                </div>
                <div className="footer-modal" onClick={(e) => _closeOptions(e)}>
                    <div className="v2-btn-default --transparent w-100">{t('common:cancel')}</div>
                </div>
            </div>
        </ReactModal>
    );
});

export default RessignJob;
