import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { customerPortal, customerPortalLogin } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import { clientQuery } from 'common/utils/ApiUtils';
import { debounce } from 'common/utils/FunctionUtils';
import { getPermissionCustomerDetail, isNotPermissionValue } from 'common/utils/PermissionUtils';
import AlertCustomer from '../../components/AlertCustomer';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import CredentialsBox from './components/CredentialsBox';
import EmailPreviewBox from './components/EmailPreviewBox';
import PortalLoading from './components/Loading';

const CustomerPortal = () => {
    const { t } = useTranslation('auth');
    const { id: customerId } = useParams();

    const addons = useSelector(({ auth }) => auth.user.settings.addons);
    const permissions = getPermissionCustomerDetail(addons);
    const { reloadCustomerPage } = useContext(CustomerDetailContext);
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, portal_info: {} });
    const { portal_info } = state;

    const refAlert = useRef(null);

    useEffect(() => {
        if (!customerId) return;
        if (!state.isLoading) dispatchState({ isLoading: true });
        const _handleSuccess = ({ data }) => dispatchState({ portal_info: data, isLoading: false });
        const _handleFailed = () => {
            dispatchState({
                portal_info: { email_template: null, require_password: 0, username: '', reset_password_token: null },
                isLoading: false
            });
        };

        clientQuery(customerPortal(customerId), { data: {}, method: 'GET' }, _handleSuccess, _handleFailed);
    }, [customerId, reloadCustomerPage]);

    const _handleUpdatePortal = (data) => dispatchState({ portal_info: { ...state.portal_info, ...data } });
    const _handleShowStatus = ({ id, message, type }) => {
        refAlert.current.showStatusBar({ id, message, type });
    };

    const _handleChange = debounce((e) => {
        clientQuery(customerPortalLogin(customerId), {
            data: { require_password: e.target.checked ? 1 : 0 },
            method: 'PUT'
        });
    }, 300);

    if (isNotPermissionValue(permissions, 'customer_portal'))
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    return (
        <>
            <div className="wrapper-columns">
                {!state.isLoading ? (
                    <div className="contents-pages container-column wrapper-box-edit is-editing scrolls">
                        <div className="wrapper-box-edit__content form-editing">
                            <AlertCustomer ref={refAlert} />
                            <div className="page-wrapper wrap-portal">
                                <CredentialsBox
                                    userName={portal_info.username}
                                    customerId={customerId}
                                    onShowStatus={_handleShowStatus}
                                />
                                <EmailPreviewBox
                                    customerId={customerId}
                                    emailContent={portal_info.email_template}
                                    resetPasswordToken={portal_info.reset_password_token}
                                    onShowStatus={_handleShowStatus}
                                    onUpdatePortal={_handleUpdatePortal}
                                />
                                <div className="wrap-portal__box flex-betweenitems">
                                    Require a password reset on the next log in.
                                    <div className="switch large">
                                        <input
                                            id="require_password"
                                            className="toggle toggle-round"
                                            type="checkbox"
                                            defaultChecked={!!portal_info.require_password}
                                            onChange={_handleChange}
                                        />
                                        <label htmlFor="require_password" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <PortalLoading />
                )}
            </div>
        </>
    );
};

export default CustomerPortal;
