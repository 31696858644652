import { ADDONS_MANAGE_DOCUMENT, ATTACHMENT_PRESIGN, getDeleteDocumentAddons } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { uploadToS3 } from 'common/utils/FileUtils';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { DOCUMENT_LIBRARY, TYPE_PRESIGN_PDF } from '../../constants';

let isUnmount = false;
const ServiceManageDocument = ({ onUploadSuccess = () => {}, onUploadFail = () => {} }, ref) => {
    const userId = useSelector(({ auth }) => auth.user.profile.id);

    useEffect(() => {
        isUnmount = false;
        return () => {
            isUnmount = true;
        };
    }, []);

    useImperativeHandle(ref, () => ({
        upload: _handleUploadFile,
        remove: _handleRemove
    }));

    const _handleUploadFile = ({ file, readFile = () => {} }) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const fileData = {
                id: Date.now(),
                file,
                name: file.name,
                url: reader.result,
                mime: file.type,
                size: file.size
            };
            readFile(fileData);
            _handleUpload({ fileData });
        };
    };

    const _handleUpload = ({ fileData = {} }) => {
        const { name: fileName, mime, size, id: uploadId } = fileData;

        const optionQuery = {
            data: { name: fileName, item_id: userId, type: TYPE_PRESIGN_PDF },
            method: 'POST'
        };

        const _handleFail = (error) => {
            if (isUnmount) return;
            onUploadFail({ id: uploadId, error });
        };

        const _addSuccess = (response) => {
            const idAttach = `${fileName}${new Date().getTime()}`;
            const { presigned_url, object_key, object_tag } = response.data;
            const optionsHeaders = { 'x-amz-tagging': object_tag };

            const _handleSuccess = () => {
                if (isUnmount) return;
                clientQuery(
                    ADDONS_MANAGE_DOCUMENT,
                    {
                        method: 'POST',
                        toFormData: false,
                        data: { name: fileName, object_key, mime, size }
                    },
                    (res) => !isUnmount && onUploadSuccess({ ...res, uploadId }),
                    _handleFail
                );
            };

            uploadToS3(
                presigned_url,
                optionsHeaders,
                fileData.file,
                {
                    idAttach,
                    object_key
                },
                _handleSuccess,
                _handleFail
            );
        };

        clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, _handleFail);
    };

    const _handleRemove = ({ id, success = () => {}, fail = () => {} }) => {
        clientQuery(getDeleteDocumentAddons(id, DOCUMENT_LIBRARY.PDF), { method: 'DELETE', data: {} }, success, fail);
    };

    return null;
};

export default forwardRef(ServiceManageDocument);
