import { TYPE_SEND_EMAIL_SMS } from 'app/modules/jobdetail/const';
import Email from 'app/modules/jobdetail/tabs/invoice/actions/components/email';
import Sms from 'app/modules/jobdetail/tabs/invoice/actions/components/Sms';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import StatusBarEmail from 'app/components/status/statusbar';
import StatusBarSms from 'app/components/status/statusbar';

const SendByEmailAndSms = forwardRef(
    (
        { customerId, onSendEmail, urlGetSMS, params, onSendSMS, urlGetEmail, paramsEmail, isAllowOnSMSInput = false },
        ref
    ) => {
        const [state, dispatchState] = useReducer(reducer, {
            isVisible: false,
            tab: TYPE_SEND_EMAIL_SMS.SEND_EMAIL
        });

        const { isVisible, tab } = state;
        const refSendSms = useRef(null);
        const refSendEmail = useRef(null);
        const refStatusBarEmail = useRef(null);
        const refStatusBarSms = useRef(null);
        const refCount = useRef(0);

        useImperativeHandle(ref, () => ({
            _handleOpenForm
        }));

        const _stopPropagation = (e) => {
            e && e.stopPropagation();
        };

        const _handleOpenForm = () => {
            dispatchState({
                isVisible: true
            });
        };

        const _handleCloseForm = () => {
            dispatchState({
                isVisible: false
            });
        };

        const _handleChangeTab = (e, type) => {
            _stopPropagation(e);
            if (tab !== type) {
                dispatchState({ tab: type });
            }
        };

        const _handleSendSms = () => {
            return refSendSms.current.handleSend();
        };

        const _handleSendEmail = () => {
            return refSendEmail.current.handleSend();
        };

        const _handleShowMessageEmail = (type, message) => {
            refCount.current += 1;
            refStatusBarEmail.current.showStatusBar(Date.now().toString(), message, type);

            if (refCount.current === 2) {
                setTimeout(() => {
                    refCount.current = 0;
                    _handleCloseForm();
                }, 2000);
            }
        };

        const _handleShowMessageSms = (type, message) => {
            refCount.current += 1;
            refStatusBarSms.current.showStatusBar(Date.now().toString(), message, type);

            if (refCount.current === 2) {
                setTimeout(() => {
                    refCount.current = 0;
                    _handleCloseForm();
                }, 2000);
            }
        };

        if (!isVisible) return null;

        return (
            <div className="modal container-modal modal-email-send --mail-dialog open">
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container">
                    <div className="header-modal tabs nav-tabs">
                        <h3 className="header-modal__label">Send by Email & SMS</h3>
                        <div className="btn-item ml-0">
                            <div
                                onClick={(e) => {
                                    _handleChangeTab(e, TYPE_SEND_EMAIL_SMS.SEND_EMAIL);
                                }}
                                className={`tab-items ${
                                    tab === TYPE_SEND_EMAIL_SMS.SEND_EMAIL && 'active-tab-selector'
                                }`}
                            >
                                Email
                            </div>
                            <div
                                onClick={(e) => {
                                    _handleChangeTab(e, TYPE_SEND_EMAIL_SMS.SEND_SMS);
                                }}
                                className={`tab-items ${tab === TYPE_SEND_EMAIL_SMS.SEND_SMS && 'active-tab-selector'}`}
                            >
                                SMS
                            </div>
                        </div>
                        <span onClick={_handleCloseForm} className="v2-btn-default --icon-lg --transparent">
                            <IconClose />
                        </span>
                    </div>

                    <StatusBarEmail ref={refStatusBarEmail} />
                    <StatusBarSms ref={refStatusBarSms} />

                    <div className="wrap-send-email tab-content">
                        <div className={`tab-panel ${tab === TYPE_SEND_EMAIL_SMS.SEND_EMAIL ? 'active' : ''}`}>
                            <Email
                                ref={refSendEmail}
                                customerId={customerId}
                                isVisible={isVisible}
                                handleCloseForm={_handleCloseForm}
                                onSend={onSendEmail}
                                urlGetEmail={urlGetEmail}
                                paramsEmail={paramsEmail}
                                parentSendSms={_handleSendSms}
                                isEmailAndSms
                                onChangeTab={_handleChangeTab}
                                onCallbackMessage={_handleShowMessageEmail}
                            />
                        </div>
                        <div className={`tab-panel ${tab === TYPE_SEND_EMAIL_SMS.SEND_SMS ? 'active' : ''}`}>
                            <Sms
                                ref={refSendSms}
                                isVisible={isVisible}
                                handleCloseForm={_handleCloseForm}
                                onSend={onSendSMS}
                                urlGetSMS={urlGetSMS}
                                params={params}
                                onChangeTab={_handleChangeTab}
                                isEmailAndSms
                                parentSendEmail={_handleSendEmail}
                                onCallbackMessage={_handleShowMessageSms}
                                isAllowOnSMSInput={isAllowOnSMSInput}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

SendByEmailAndSms.propTypes = {
    handleSendSuccess: PropTypes.func
};

SendByEmailAndSms.defaultProps = {
    handleSendSuccess: () => {}
};

export default SendByEmailAndSms;
