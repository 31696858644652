import { createPopper } from '@popperjs/core';
import React, { forwardRef, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';

import { reducer } from 'app/const/Reducer';

const Tooltip = forwardRef(({ popperConfig = null }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { data: null });
    const data = state?.data || null;
    const refEl = useRef(null);

    useImperativeHandle(ref, () => ({
        visible: (data) => {
            dispatchState((prevState) => ({ ...prevState, data }));
        },
        isHaveData: !!data
    }));

    useLayoutEffect(() => {
        const config = popperConfig || {
            placement: 'top',
            strategy: 'fixed',
            modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
        };
        const popper = createPopper(data?.el, refEl.current, config);

        return () => {
            popper.destroy();
        };
    }, [data]);

    return (
        <div ref={refEl} className="tooltiptext  visibility-show" style={{ display: !!data ? 'flex' : 'none' }}>
            {!!data ? <p className="tooltiptext-title">{data?.value}</p> : null}
        </div>
    );
});

export default Tooltip;
