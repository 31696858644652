import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import classNames from 'classnames';
import React, { Fragment, useMemo, useReducer } from 'react';
import { useSelector } from 'react-redux';
import './style/index.scss';

const PreviewNoteCollapse = ({
    title,
    note = '',
    jobNote,
    extraClass = '',
    contentClass = 'note-details wrap-content wrap-collapse__details',
    noteHeaderClass = 'note-header flex-betweenitems',
    isInnerHTML = false
}) => {
    const [state, dispatchState] = useReducer(reducer, { isOpen: true });
    const { isOpen } = state;

    const _handleShowMore = (e) => {
        e.stopPropagation();
        dispatchState((prev) => ({ isOpen: !prev.isOpen }));
    };

    if (!note.length) return null;
    return (
        <div className={classNames('wrap-note wrap-collapse', extraClass, { 'is-open': isOpen })}>
            <div className={noteHeaderClass} onClick={_handleShowMore}>
                {jobNote ? <NoteUserHeader {...jobNote} /> : <p className="header-name fw-500">{title}</p>}
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </div>
            <NoteContent contentClass={contentClass} isInnerHTML={isInnerHTML} note={note} />
        </div>
    );
};

const NoteUserHeader = ({ user_id, date }) => {
    const users = useSelector(({ companyUsers }) => companyUsers.users);

    const user = useMemo(() => {
        return users.find((item) => item.id === user_id);
    }, [user_id]);

    if (!user) return null;

    return (
        <Fragment>
            <div className="avt-img mr-1">
                <img src={user.avatar} alt="avatar" width="24" height="24" />
            </div>
            <div className="flex-1">
                <p className="header-name word-break fw-500">{user.full_name}</p>
                <p className="granite-gray fs-10">{date}</p>
            </div>
        </Fragment>
    );
};

const NoteContent = ({ note, contentClass = 'note-details wrap-content wrap-collapse__details', isInnerHTML = false }) => {
    if (isInnerHTML) return <div className={contentClass} dangerouslySetInnerHTML={{ __html: note }} />;
    return <div className={contentClass}>{note}</div>;
};

export default PreviewNoteCollapse;
