import React from 'react';

export const VOIPAddonsLoadingAutoAttendant = () => {
    return (
        <div className="wrap-loading ">
            <div className="header-modal">
                <div className="header-modal__label">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="v2-btn-default --icon-lg loading bg-white-cultured" />
            </div>
            <div className="body-modal p-0 h-100">
                <div className="box-st-hour border-bottom-grey-verylight-brown flex-column gap-8">
                    <div className="loading --animation --line --threefourth" />
                    <div className="loading --animation --line --onefourth" />
                    <div className="flex-betweenitems gap-24">
                        <div className="flex-1">
                            <div className="loading --animation --line --onefifth mb-1" />
                            <div className="v2-btn-default --grey loading --full">
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="loading --animation --line --onefifth mb-1" />
                            <div className="v2-btn-default --grey loading --full">
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                                <div className="v2-btn-default loading --twothird">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box-st-hour border-bottom-grey-verylight-brown flex-column gap-8">
                    <div className="loading --animation --line --onefifth" />
                    <div className="loading --animation --line --threefourth" />
                    <div className="loading --animation --line --twothird" />
                    <div className="flexcenter gap-8">
                        <div className="v2-btn-default loading --grey --half flex-centeritem">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey --half flex-centeritem">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="audio-loading" />
                </div>
                <div className="box-st-hour flex-column gap-8">
                    <div className="loading --animation --line --onefifth" />
                    <div className="loading --animation --line --threefourth" />
                    <div className="loading --animation --line --twothird" />
                    <div className="container-print gap-12">
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="wrap-border tables flex-none border-border-color-grey shadow-none">
                            <div className="tables-list">
                                <div className="rows gap-0">
                                    <div className="col --sm p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                    <div className="col --lg p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                    </div>
                                    <div className="col --action">
                                        <div className="v2-btn-default --icon-sm loading bg-white-cultured btn-plus mx-1" />
                                    </div>
                                </div>
                                <div className="rows gap-0">
                                    <div className="col --sm p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                    <div className="col --lg p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                    </div>
                                    <div className="col --action">
                                        <div className="v2-btn-default --icon-sm loading bg-white-cultured btn-plus mx-1" />
                                    </div>
                                </div>
                                <div className="rows gap-0">
                                    <div className="col --sm p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                    <div className="col --lg p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                    </div>
                                    <div className="col --action">
                                        <div className="v2-btn-default --icon-sm loading bg-white-cultured btn-plus mx-1" />
                                    </div>
                                </div>
                                <div className="rows gap-0">
                                    <div className="col --sm p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                    <div className="col --lg p-0 border-right-border-color-grey">
                                        <div className="v2-btn-default btn-plus loading --full">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                    </div>
                                    <div className="col --action">
                                        <div className="v2-btn-default --icon-sm loading bg-white-cultured btn-plus mx-1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-modal">
                <div className="v2-btn-default loading btn-md --grey">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="flex-1 text-right">
                    <div className="v2-btn-default loading btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading btn-x-sm --grey">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};
