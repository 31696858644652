import ButtonSave from 'app/components/button/ButtonSave';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CalendarSyncConnect = ({ isConnected = false, isFirstConnect = true, onAuth = () => {} }, ref) => {
    const { t } = useTranslation();
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ _setRemoveLoading: () => refButtonSave.current.removeLoading() }));

    return (
        <div className="boxs">
            <h3 className="title --sm">{t('google_calendar_setup')}</h3>
            {!isFirstConnect && (
                <ButtonSave
                    ref={refButtonSave}
                    title={isConnected ? t('disconnect') : t('connect')}
                    wrapClass="v2-btn-main"
                    onSave={onAuth}
                />
            )}
            {isFirstConnect && !isConnected && (
                <ButtonSave ref={refButtonSave} title={t('connect')} wrapClass="v2-btn-main" onSave={onAuth} />
            )}
        </div>
    );
};

export default forwardRef(CalendarSyncConnect);
