import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import StatusBar from 'app/components/status/statusbar';
import { ADDONS_SMS_DETAIL, SMS_ADDONS_OPTIONS, SMS_ADDONS_OVERRIDE_GLOBAL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AddonsOptions from '../components/AddonsOptions';
import SMSAddonsContent from './components/SMSAddonsContent';
import SMSAddonsInformation from './components/SMSAddonsInformation';
import SMSAddonsLoading from './components/SMSAddonsLoading';
import SMSAddonsPacks from './components/SMSAddonsPacks';
import { SMS_ADDON_STATUS } from '../constants';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsSms = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation(['common', 'addons']);
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, sms: {} });
    const refConfirmOverride = useRef(null);
    const refStatusBar = useRef(null);
    const refButtonSave = useRef(null);
    const { isLoading, sms } = state;

    useEffect(() => {
        _getDetailSMSAddons();
    }, []);

    const _getDetailSMSAddons = () => {
        clientQuery(ADDONS_SMS_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    /** When the server returns a successful response, update the state with the data. */
    const _handleGetSuccess = ({ data }) => {
        dispatchState({ sms: data, isLoading: false });
    };

    const _handleRentSuccess = (data) => {
        dispatchState({ sms: { ...sms, account: { ...state.account, ...data } } });
    };

    const _handleDeactiveSuccess = (message) => {
        dispatch(updateAddonStatus({ keyword: 'smsplivo', data: 0 }));
        clientQuery(SMS_ADDONS_OPTIONS, { data: { value: 0, keyword: 'sms_global' }, method: 'PUT' });
        dispatchState((prevState) => {
            return {
                sms: {
                    ...prevState.sms,
                    account: { ...prevState.sms.account, status: SMS_ADDON_STATUS.INACTIVE },
                    options: sms.options.map((item) => ({ ...item, value: 0 }))
                }
            };
        });
        refStatusBar.current.showStatusBar('deactivate', message, LIST_STATUS.ERROR);
    };

    const _handleActiveSuccess = ({ data, message, sms_package_id }) => {
        dispatch(
            updateAddonStatus({
                keyword: 'smsplivo',
                data: { sms_global: sms.options.find((item) => item.keyword === 'sms_global')?.value || 0 }
            })
        );
        dispatchState((prevState) => {
            return {
                sms: {
                    ...prevState.sms,
                    account: {
                        ...prevState.sms.account,
                        status: SMS_ADDON_STATUS.ACTIVE,
                        sms_amount: data.sms_amount,
                        sms_package_id
                    }
                }
            };
        });
        refStatusBar.current.showStatusBar('activate_success', message, LIST_STATUS.SUCCESS);
    };

    const _handleActiveFail = (message) => {
        refStatusBar.current.showStatusBar('activate_fail', message, LIST_STATUS.ERROR);
    };

    const _handleOpenOverride = () => refConfirmOverride.current._open();

    const _handleCloseOverride = () => refConfirmOverride.current._close();

    const _handleConfirmOverride = () => {
        const _handleOverrideError = ({ message }) => {
            refConfirmOverride.current._setStatusAlert(message);
        };

        const _handleOverrideSuccess = () => {
            clientQuery(SMS_ADDONS_OPTIONS, { data: { value: 1, keyword: 'sms_global' }, method: 'PUT' });
            dispatch(updateAddonStatus({ keyword: 'smsplivo', data: { sms_global: 1 } }));

            dispatchState((prevState) => {
                const options = [...prevState.sms.options];
                return {
                    ...prevState,
                    sms: {
                        ...prevState.sms,
                        options: options.map((item) => (item.keyword === 'sms_global' ? { ...item, value: 1 } : item))
                    }
                };
            });
            _handleCloseOverride();
        };

        const _handleOverrideFinally = () => {
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            SMS_ADDONS_OVERRIDE_GLOBAL,
            { data: {}, method: 'PUT' },
            _handleOverrideSuccess,
            _handleOverrideError,
            _handleOverrideFinally
        );
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper boxs-wrapper--sms">
                    {isLoading ? (
                        <SMSAddonsLoading />
                    ) : (
                        <>
                            <StatusBar ref={refStatusBar} />
                            <SMSAddonsContent
                                keyword={sms.keyword}
                                name={sms.name}
                                status={sms.status}
                                upgrade={sms.upgrade}
                                level={sms.level}
                                subscriptionFee={sms.subscription_fee}
                            />
                            <SMSAddonsPacks
                                data={sms.package}
                                packageActive={sms.account?.sms_package_id}
                                statusAddon={sms.account?.status}
                                isMustRentPhone={!sms.account?.app_id}
                                globalOverride={sms.options.find((item) => item.keyword === 'sms_global')?.value}
                                onOpenOverrideModal={_handleOpenOverride}
                                onRentSuccess={_handleRentSuccess}
                                onActiveSuccess={_handleActiveSuccess}
                                onActiveFail={_handleActiveFail}
                                onDeactiveSuccess={_handleDeactiveSuccess}
                            />
                            <SMSAddonsInformation data={sms.account} />
                            <AddonsOptions data={sms.options} status={sms.status} urlUpdate={SMS_ADDONS_OPTIONS} />
                            <GDModalWarning
                                ref={refConfirmOverride}
                                title={t('addons:turn_on_global_override')}
                                description={t('addons:description_override_sms_global')}
                                footer={
                                    <div className="footer-modal">
                                        <div className="v2-btn-main" onClick={_handleCloseOverride}>
                                            {t('addons:not_now')}
                                        </div>
                                        <ButtonSave
                                            ref={refButtonSave}
                                            title={t('addons:turn_on_sms_global_override')}
                                            wrapClass="v2-btn-main ml-2"
                                            onSave={_handleConfirmOverride}
                                        />
                                    </div>
                                }
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsSms;
