export default function IconTimeWindow({ isGreen = false, isNotMatch = false, ...props }) {
    if (isGreen) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={32} height={32} rx={4} fill="#FFFFFF" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.7151 6.02057L22.2849 6.92694C22.6019 7.10991 22.7935 7.45138 22.7846 7.81721L22.7316 10.0261C23.5317 10.9271 24.1537 11.9898 24.5424 13.159L26.4789 14.2158C26.8001 14.391 27 14.7277 27 15.0937V16.9064C27 17.2723 26.8001 17.6091 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1829C22.7935 24.5487 22.6019 24.8902 22.2849 25.0731L20.7151 25.9795C20.3981 26.1625 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9374 16.6199 25 16 25C15.3801 25 14.7748 24.9374 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1625 11.2849 25.9795L9.71506 25.0731C9.39815 24.8902 9.20648 24.5487 9.21536 24.1829L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.6091 5 17.2723 5 16.9064V15.0937C5 14.7277 5.19989 14.391 5.52115 14.2158L7.45762 13.159C7.84629 11.9898 8.46829 10.9271 9.26841 10.0261L9.21536 7.81721C9.20648 7.45138 9.39815 7.10991 9.71506 6.92694L11.2849 6.02057C11.6019 5.8376 11.9934 5.84235 12.3058 6.03295L14.1902 7.18207C14.7748 7.06271 15.3801 7.00003 16 7.00003C16.6199 7.00003 17.2252 7.06271 17.8098 7.18207L19.6942 6.03295C20.0066 5.84235 20.3981 5.8376 20.7151 6.02057ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                    fill="#1EAA5C"
                />
                <path
                    d="M16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                    fill="#1EAA5C"
                />
                <circle cx={16} cy={16} r={7} fill="#C0E5CF" />
                <path
                    d="M16 11V16L19 18"
                    stroke="#1EAA5C"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    if (isNotMatch) {
        return (
            <svg width={22} height={22} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.7151 6.02057L22.2849 6.92694C22.6019 7.10991 22.7935 7.45138 22.7846 7.81721L22.7316 10.0261C23.5317 10.9271 24.1537 11.9898 24.5424 13.159L26.4789 14.2158C26.8001 14.391 27 14.7277 27 15.0937V16.9064C27 17.2723 26.8001 17.6091 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1829C22.7935 24.5487 22.6019 24.8902 22.2849 25.0731L20.7151 25.9795C20.3981 26.1625 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9374 16.6199 25 16 25C15.3801 25 14.7748 24.9374 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1625 11.2849 25.9795L9.71506 25.0731C9.39815 24.8902 9.20648 24.5487 9.21536 24.1829L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.6091 5 17.2723 5 16.9064V15.0937C5 14.7277 5.19989 14.391 5.52115 14.2158L7.45762 13.159C7.84629 11.9898 8.46829 10.9271 9.26841 10.0261L9.21536 7.81721C9.20648 7.45138 9.39815 7.10991 9.71506 6.92694L11.2849 6.02057C11.6019 5.8376 11.9934 5.84235 12.3058 6.03295L14.1902 7.18207C14.7748 7.06271 15.3801 7.00003 16 7.00003C16.6199 7.00003 17.2252 7.06271 17.8098 7.18207L19.6942 6.03295C20.0066 5.84235 20.3981 5.8376 20.7151 6.02057ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                    fill="#FF4A00"
                />
                <circle cx={16} cy={16} r={7} fill="#ffe0e0" />
                <path
                    d="M16 11V16L19 18"
                    stroke="#FF4A00"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={32} height={32} rx={4} fill="#F0F0F0" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.7151 6.02057L22.2849 6.92694C22.6019 7.10991 22.7935 7.45138 22.7846 7.81721L22.7316 10.0261C23.5317 10.9271 24.1537 11.9898 24.5424 13.159L26.4789 14.2158C26.8001 14.391 27 14.7277 27 15.0937V16.9064C27 17.2723 26.8001 17.6091 26.4789 17.7843L24.5424 18.8411C24.1537 20.0104 23.5316 21.0731 22.7314 21.9741L22.7846 24.1829C22.7935 24.5487 22.6019 24.8902 22.2849 25.0731L20.7151 25.9795C20.3981 26.1625 20.0066 26.1577 19.6942 25.9671L17.8098 24.818C17.2252 24.9374 16.6199 25 16 25C15.3801 25 14.7748 24.9374 14.1902 24.818L12.3058 25.9671C11.9934 26.1577 11.6019 26.1625 11.2849 25.9795L9.71506 25.0731C9.39815 24.8902 9.20648 24.5487 9.21536 24.1829L9.26856 21.9741C8.46837 21.0731 7.84631 20.0104 7.45762 18.8411L5.52115 17.7843C5.19989 17.6091 5 17.2723 5 16.9064V15.0937C5 14.7277 5.19989 14.391 5.52115 14.2158L7.45762 13.159C7.84629 11.9898 8.46829 10.9271 9.26841 10.0261L9.21536 7.81721C9.20648 7.45138 9.39815 7.10991 9.71506 6.92694L11.2849 6.02057C11.6019 5.8376 11.9934 5.84235 12.3058 6.03295L14.1902 7.18207C14.7748 7.06271 15.3801 7.00003 16 7.00003C16.6199 7.00003 17.2252 7.06271 17.8098 7.18207L19.6942 6.03295C20.0066 5.84235 20.3981 5.8376 20.7151 6.02057ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                fill="#666666"
            />
            <path
                d="M16 11C13.2386 11 11 13.2386 11 16C11 18.7615 13.2386 21 16 21C18.7614 21 21 18.7615 21 16C21 13.2386 18.7614 11 16 11Z"
                fill="#666666"
            />
            <circle cx={16} cy={16} r={7} fill="#CCCCCC" />
            <path d="M16 11V16L19 18" stroke="#666666" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
}
