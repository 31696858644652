import React from 'react';

const LoadingList = () => {
    return (
        <div className="wrap-loading --material">
            <div className="wrap-material">
                <div className="material rows-menu">
                    <div className="material__name">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="material__qty">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="material__items d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="list-material">
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                </div>
                <div className="list-material">
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                    <div className="tag-label loading --animation" />
                </div>
            </div>
        </div>
    );
};

const LoadingForm = () => {
    return (
        <div className="details-job">
            <div className="rows --sm wrap-loading">
                <div className="txt">
                    <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey details">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            <div className="rows --sm wrap-loading mt-5">
                <div className="txt">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="details field-every">
                        <div className="field-input no-effect">
                            <div className="v2-btn-default loading --grey just-left">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --grey number">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --grey just-right">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="v2-btn-default loading --grey flex-1 ml-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
            </div>
            <div className="rows --sm wrap-loading">
                <div className="txt">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="v2-btn-default loading --grey details">
                    <div className="loading --animation --line --threefourth" />
                </div>
            </div>
            <div className="rows --sm wrap-loading">
                <div className="txt">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="v2-btn-default loading --grey details">
                    <div className="loading --animation --line --threefourth" />
                </div>
            </div>
            <div className="line" />
            <div className="wrap-loading mt-5">
                <div className="rows --sm">
                    <div className="txt">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="details">
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading mt-5">
                <div className="rows --sm">
                    <div className="txt">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="details">
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="line" />
            <div className="rows wrap-loading mt-5">
                <div className="txt">
                    <div className="loading --animation --line --twothird" />
                </div>
                <div className="details --area d-flex">
                    <div className="v2-btn-default loading --grey btn-sm mr-2">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="v2-btn-default loading --grey flex-1">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { LoadingList, LoadingForm };
