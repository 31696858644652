export default function IconSent({ isSendMail = false }) {
    if (isSendMail)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5 11.5005L12.5 17.5005V13.5005L9.3343 14.0281C7.63558 14.3112 6.29967 15.6341 6 17.33C5.97579 17.467 5.84509 17.5585 5.70808 17.5343C5.58773 17.513 5.5 17.4084 5.5 17.2862V14.8369C5.5 12.3487 7.32965 10.2391 9.79289 9.88719L12.5 9.50046V5.49707L18.5 11.5005Z"
                    stroke="#4C4C4C"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.53636 10.7813L18.6736 6.14462C19.1944 5.96081 19.7656 6.23399 19.9494 6.75479C20.0185 6.95048 20.025 7.1628 19.9679 7.36233L16.873 18.1948C16.7212 18.7258 16.1678 19.0333 15.6367 18.8816C15.5401 18.854 15.4482 18.812 15.364 18.7569L10.6124 15.6488L9.50005 20.5L8.51913 14.4278L5.36531 12.5881C4.88826 12.3098 4.72712 11.6975 5.0054 11.2204C5.12478 11.0158 5.31294 10.8602 5.53636 10.7813Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.5 14.5L19.5 6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 15.5L19.5 6.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 20.5L13.5 17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
