import React from 'react';

import GDButtonTooltip from 'app/components/button/ButtonTooltip';
import IconDoubleStep from 'assets/icon/IconDoubleStep';
import IconSideMenu from 'assets/icon/IconSideMenu';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const ButtonSetupLayout = () => {
    const _handleToggleSideMenu = () => {
        document.getElementById('customer_detail_layout')?.classList?.toggle('has-collapsed');
        const oldToggle = getToggleLocal('toggledCustomerDetail');
        setLocalStorage('toggledCustomerDetail', !oldToggle);
    };

    const _handlePosition = () => {
        document.getElementById('customer_detail_layout')?.classList?.toggle('move-sidebar');
        const oldSide = getLocalStorage('sideMenuCustomerDetail');
        setLocalStorage('sideMenuCustomerDetail', oldSide === 'left' ? 'right' : 'left');
    };

    return (
        <div className="btn-switch flex-column gap-4">
            <GDButtonTooltip
                className="v2-btn-default --icon-sm tooltip btn-move-sidebar"
                onClick={_handlePosition}
                tooltips={[
                    { className: 'tooltiptext left --collapse', text: 'Left' },
                    { className: 'tooltiptext left --expand', text: 'Right' }
                ]}
            >
                <IconSideMenu />
            </GDButtonTooltip>
            <GDButtonTooltip
                className="v2-btn-default --icon-sm tooltip btn-collapse"
                onClick={_handleToggleSideMenu}
                tooltips={[
                    { className: 'tooltiptext left --collapse', text: 'Collapse' },
                    { className: 'tooltiptext left --expand', text: 'Expand' }
                ]}
            >
                <IconDoubleStep />
            </GDButtonTooltip>
        </div>
    );
};

export default ButtonSetupLayout;
