import React, { useEffect, useReducer, useRef } from 'react';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_LIST_QUICKBOOKS_ACCOUNT, ACTIONN_UPDATE_QUICKBOOKS_ADDON } from 'app/const/api/V2';
import ListAccount from 'app/modules/addons/quickbooks/tabs/discount/Account';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

let timmerStripe, timmerSquare;

export default function TabStripeSync({ stripeData, squareData, act_bank, act_exp, onChangeAccount, onUpdateOption }) {
    const { t } = useTranslation(['report']);
    const parentAccountBank = act_bank.toString();
    const parentAccountExpense = act_exp.toString();

    const [state, dispatchState] = useReducer(reducer, {
        isFetched: true,
        stripeData,
        squareData,
        bankSelected: parentAccountBank,
        expenseSelected: parentAccountExpense,
        accountBank: [],
        accountExpense: []
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    const {
        bankSelected: finalBankSelected,
        expenseSelected: finalExpenseSelected,
        stripeData: finalStripeData,
        squareData: finalSquareDate
    } = state;

    const finalActiveStripe = !!finalStripeData.value;
    const finalActiveSquare = !!finalSquareDate.value;

    useEffect(() => {
        _getListAccount();
    }, []);

    function _getListAccount() {
        clientQuery(
            GET_LIST_QUICKBOOKS_ACCOUNT,
            {
                method: 'GET'
            },
            _getListAccountSuccess
        );
    }

    function _getListAccountSuccess(reponse) {
        const responseData = { ...reponse.data };
        dispatchState({
            accountBank: responseData.bank,
            accountExpense: responseData.expense,
            isFetchAccount: true
        });
    }

    function _handleChangeStripe() {
        const nextActive = !finalActiveStripe;

        if (nextActive) {
            if (!parentAccountBank || !parentAccountExpense) {
                refStatusBar.current.showStatusBar(
                    'show_error',
                    'To enable Stripe Sync, please select chart accounts and save.',
                    LIST_STATUS.ERROR
                );
                return false;
            }
        }

        dispatchState({
            stripeData: {
                ...finalStripeData,
                value: nextActive
            }
        });

        timmerStripe && clearTimeout(timmerStripe);
        timmerStripe = setTimeout(() => {
            onUpdateOption(stripeData.keyword, nextActive ? 1 : 0);
        }, 500);
    }

    function _handleChangeSquare() {
        const nextActive = !finalActiveSquare;

        if (nextActive) {
            if (!parentAccountBank || !parentAccountExpense) {
                refStatusBar.current.showStatusBar(
                    'show_error',
                    'To enable Square Sync, please select chart accounts and save.',
                    LIST_STATUS.ERROR
                );
                return false;
            }
        }

        dispatchState({
            squareData: {
                ...finalSquareDate,
                value: nextActive
            }
        });

        timmerSquare && clearTimeout(timmerSquare);
        timmerSquare = setTimeout(() => {
            onUpdateOption(squareData.keyword, nextActive ? 1 : 0);
        }, 500);
    }

    function _handleSelectAccountBank(accountId) {
        dispatchState({
            bankSelected: accountId
        });
    }

    function _handleSelectAccountExpense(accountId) {
        dispatchState({
            expenseSelected: accountId
        });
    }

    function _checkDisable() {
        if (
            finalBankSelected &&
            finalExpenseSelected &&
            (parentAccountBank !== finalBankSelected || parentAccountExpense !== finalExpenseSelected)
        ) {
            return false;
        }
        return true;
    }

    function _handleSave() {
        if (!finalExpenseSelected || !finalBankSelected) {
            refStatusBar.current.showStatusBar(
                'show_error',
                'You need to select chart accounts and save them.',
                LIST_STATUS.ERROR
            );
            _removeButtonLoading();
            return false;
        }

        clientQuery(
            ACTIONN_UPDATE_QUICKBOOKS_ADDON,
            {
                method: 'PUT',
                data: {
                    is_chart: 1,
                    act_bank: finalBankSelected,
                    act_exp: finalExpenseSelected
                }
            },
            _handleSaveSuccess,
            _handleSaveFailed
        );
    }

    function _handleSaveSuccess() {
        onChangeAccount(finalBankSelected, finalExpenseSelected);
        _removeButtonLoading();
        refStatusBar.current.showStatusBar('show_error', 'Update successfully', LIST_STATUS.SUCCESS);
    }

    function _handleSaveFailed(response) {
        _removeButtonLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || 'Please try again',
            LIST_STATUS.ERROR
        );
    }

    function _removeButtonLoading() {
        refButtonSave.current.removeLoading();
    }

    return (
        <div className={`boxs boxs-has-footer ${finalActiveStripe || finalActiveSquare ? 'active' : ''}`}>
            <div className="boxs__header --header-qbid">
                {!!stripeData.keyword && (
                    <>
                        <div className="flex-betweenitems w-100">
                            <div className="header-title">
                                <div className="title">{t('report:stripe_sync')}</div>
                                <div className="dots powerups">
                                    <IconUpgrade isFillOrange />
                                    {t('report:power_up')}
                                </div>
                            </div>
                            <div className="switch large">
                                <input
                                    id={'toggle_qb_stripe'}
                                    className="toggle toggle-round"
                                    checked={finalActiveStripe}
                                    onChange={(e) => _handleChangeStripe(e)}
                                    type="checkbox"
                                />
                                <label htmlFor={'toggle_qb_stripe'}>
                                    <span className="enable-disable" />
                                </label>
                            </div>
                        </div>
                        <p className="mt-1">
                            {t('report:automatically_sync_stripe_fees_into_the_qb_account_of_your_choosing')}
                        </p>
                    </>
                )}

                {!!squareData.keyword && (
                    <>
                        <div className="flex-betweenitems w-100 mt-3">
                            <div className="header-title">
                                <div className="title">{t('report:square_sync')}</div>
                                <div className="dots powerups">
                                    <IconUpgrade isFillOrange />
                                    {t('report:power_up')}
                                </div>
                            </div>
                            <div className="switch large">
                                <input
                                    id={'toggle_qb_square'}
                                    className="toggle toggle-round"
                                    checked={finalActiveSquare}
                                    onChange={(e) => _handleChangeSquare(e)}
                                    type="checkbox"
                                />
                                <label htmlFor={'toggle_qb_square'}>
                                    <span className="enable-disable" />
                                </label>
                            </div>
                        </div>
                        <p className="mt-1 pb-3 border-bottom-border-color-grey">
                            {t('report:automatically_sync_square_fees_into_the_qb_account_of_your_choosing')}
                        </p>
                    </>
                )}
            </div>
            <div className="boxs__contents">
                <StatusBar ref={refStatusBar} />
                <p className="required txt-ellipsis">
                    <span className="fw-500">{t('report:bank_account_to_deposit_payouts')}</span> ({t('report:qb_account_bank')})
                    <span className="red-delete">*</span>
                </p>
                <ListAccount
                    onSelect={_handleSelectAccountBank}
                    accountSelected={finalBankSelected}
                    account={state.accountBank}
                    isFetched={state.isFetched}
                />
                <p className="required mt-2 txt-ellipsis">
                    <span className="fw-500">{t('report:payout_fees_and_adjustments_account')}</span> ({t('report:qb_account_expense')})
                    <span className="red-delete">*</span>
                </p>
                <ListAccount
                    onSelect={_handleSelectAccountExpense}
                    accountSelected={finalExpenseSelected}
                    account={state.accountExpense}
                    isFetched={state.isFetched}
                />
            </div>
            <div className="boxs__footer">
                <ButtonSave disabled={_checkDisable()} ref={refButtonSave} onSave={_handleSave} />
            </div>
        </div>
    );
}
