import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { CUSTOMERS_IMPORT_PATHS } from 'app/config/routes';
import { CUSTOMERS } from 'app/const/Route';
import CustomerDetailLayout from '../detail/layouts/CustomerDetailLayout';
import Customers from '../index';
import CustomerImportLayout from './CustomerImportLayout';
import 'app/modules/customer/css/customer.scss';
import classNames from 'classnames';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { addPrefixPath } from 'app/const/Branch';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
// import { Route } from 'react-router-dom/cjs/react-router-dom.min';

function CustomerLayout({ routes = [], ...props }) {
    const { pathname } = useLocation();

    const isCustomerList = matchPath(pathname, { path: addPrefixPath(CUSTOMERS), exact: true });

    const isImportCustomer = CUSTOMERS_IMPORT_PATHS.some((item) =>
        matchPath(pathname, { path: addPrefixPath(item), exact: true })
    );

    // Add class for page when mount ->> remove when unmount
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('customers-page', 'container-main');
        return () => {
            mainPageDiv.classList.remove('customers-page', 'container-main');
        };
    }, []);

    if (isImportCustomer)
        return <CustomerImportLayout routes={routes.filter((route) => route.isImportPage)} {...props} />;
    if (!isCustomerList && !isImportCustomer) return <CustomerDetailLayout routes={routes} {...props} />;
    return (
        <div
            id="wrapper-customer-list"
            className={classNames('container-wrap container-table custom-grid-sidebar container-customer-list', {
                'is-hide-panel': !getToggleLocal('toggledCustomers')
            })}
        >
            <Customers />
        </div>
    );
}

export default withRouter(CustomerLayout);
