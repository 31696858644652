/*

FIREBASE_CONFIG test (Firebase of Hoang Vu)
If you want test feature Login by Facebook do this:
1 - Replace FIREBASE_CONFIG by key of comment.
2 - Start localhost by command: set HTTPS=true&&npm start
3 - Turn on insecure-localhost in your browse, with chrome paste this line to url bar: chrome://flags/#allow-insecure-localhost

export const FIREBASE_CONFIG = {
    apiKey: 'AIzaSyAf2CY0HiHR9VMy5-6dLl2Nw3APUORcgdI',
    authDomain: 'gorillladesk-dev-4d844.firebaseapp.com',
    projectId: 'gorillladesk-dev-4d844',
    storageBucket: 'gorillladesk-dev-4d844.appspot.com',
    messagingSenderId: '671119472512',
    appId: '1:671119472512:web:e54397c674874cd8b67365',
    measurementId: 'G-1312S58B9N'
};

*/

import { CALENDAR, SETTINGS_PLANS, SETTINGS_COMPANY } from 'app/const/Route';

export const FIREBASE_CONFIG = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT,
    storageBucket:  process.env.REACT_APP_FIREBASE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP
};

export const LOGIN_TYPE = {
    facebook: 1,
    google: 2
};

export const PASSWORD_LIMIT_CHARACTER = 7;

export const SIGNUP_STEP = {
    one: 1,
    two: 2,
    three: 3
};

export const SETTINGS_PLANS_REDIRECT_CODE = 3;

export const REDIRECT_MAP = {
    1: CALENDAR,
    2: SETTINGS_COMPANY,
    3: SETTINGS_PLANS
};
