export const GET_REVIEW_SERVICE = 'GET_REVIEW_SERVICE';
export const GET_LIST_USER_FILTER_REQUEST = 'GET_LIST_USER_FILTER_REQUEST';
export const GET_LIST_USER_REQUEST = 'GET_LIST_USER_FILTER_REQUEST';
export const GET_LIST_USER_REVIEW = 'GET_LIST_USER_FILTER_REVIEW';
export const GET_LIST_USER_REVIEW_SUCCESS = 'GET_LIST_USERS_REVIEW_SUCCESS';

export const getReviewService = (params, actionSuccess, actionFailed) => {
    return {
        type: GET_REVIEW_SERVICE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListUserFilterRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: GET_LIST_USER_FILTER_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const getListUserReview = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: GET_LIST_USER_REVIEW,
        params,
        actionSuccess,
        actionFailed
    };
};
