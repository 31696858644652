import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const AssignTo = forwardRef(({ defaultValue, modifiers = {} }, ref) => {
    const { t } = useTranslation(['customers']);
    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const userData = useSelector(({ auth }) => auth.user.profile);

    const [state, dispatchState] = useReducer(reducer, {
        selected:
            companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
            companyUsers[0] ||
            {}
    });
    const { selected } = state;

    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return selected;
        },
        resetValue: () => {
            dispatchState({
                selected:
                    companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
                    companyUsers[0] ||
                    {}
            });
        }
    }));

    useEffect(() => {
        dispatchState({
            selected:
                companyUsers.find((item) => parseInt(item.id) === parseInt(defaultValue || userData.id)) ||
                companyUsers[0] ||
                {}
        });
    }, [defaultValue]);

    const _handleSelect = (selected) => {
        refDropdown.current?._close();
        dispatchState({ selected });
    };

    return (
        <div className="flex-column gap-4 flex-1">
            <div className="txt">{t('customers:assign_to')}</div>
            <DropdownPopper
                ref={refDropdown}
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items"
                wrapperListClass="v2-dropdown__menu scrolls"
                isUseToggle
                isCalculateWidth
                modifiers={modifiers}
                dropdownIcon={
                    <>
                        <div className="txt-ellipsis">{selected?.full_name || 'Select Time'}</div>
                        <div className="arrow">
                            <IconArrowDown width={16} height={16} />
                        </div>
                    </>
                }
            >
                <ul>
                    {companyUsers.map((item) => (
                        <li
                            key={item}
                            className={classNames('items', { active: +selected?.id === +item.id })}
                            onClick={() => _handleSelect(item)}
                        >
                            {item.full_name}
                        </li>
                    ))}
                </ul>
            </DropdownPopper>
        </div>
    );
});

export default AssignTo;
