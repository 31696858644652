import React from 'react';

const ResourceAreaHeader = () => {
    return (
        <div className="rows --header">
            <div className="col col-date">Date</div>
            <div className="col col-clock">Clock In/Out Time</div>
            <div className="col col-duration">Duration</div>
        </div>
    );
};

export default ResourceAreaHeader;
