export const LIST_STATUS_PIPELINES = [
    {
        id: 1,
        status: 'active'
    },
    {
        id: 2,
        status: 'won'
    },
    {
        id: 3,
        status: 'lost'
    }
];

export const TYPE_ALTER_PIPELINES = {
    DELETE: 4
};
