export const TYPE_SETTING_PIPELINES = {
    GET_LIST: 'SETTING_GET_LIST_PIPELINES',
    GET_LIST_SUCCESS: 'SETTING_GET_LIST_PIPELINES_SUCCESS',
    GET_LIST_FAIL: 'SETTING_GET_LIST_PIPELINES_FAIL',
    CREATE: 'SETTING_CREATE_PIPELINES',
    CREATE_SUCCESS: 'SETTING_CREATE_PIPELINES_SUCCESS',
    UPDATE: 'SETTING_UPDATE_PIPELINES',
    UPDATE_SUCCESS: 'SETTING_UPDATE_PIPELINES_SUCCESS',
    DELETE: 'SETTING_DELETE_PIPELINES',
    DELETE_SUCCESS: 'SETTING_DELETE_PIPELINES_SUCCESS',
    ORDER_SUCCESS: 'SETTING_ORDER_PIPELINES_SUCCESS'
};

export const getListPipelines = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PIPELINES.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createPipelines = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PIPELINES.CREATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updatePipelines = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PIPELINES.UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deletePipelines = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_PIPELINES.DELETE,
        params,
        actionSuccess,
        actionFailed
    };
};
