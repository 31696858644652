import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getGridColumnsActiveVoipUser = ({ groupNumber }) => {
    const header = {
        user: {
            title: 'user',
            style: 'col col-name'
        },
        personal_number: {
            title: 'personal_local_number',
            style: 'col col-lg'
        },
        group_number: {
            title: 'group_number',
            style: 'col col-lg'
        },
        call_recording: {
            title: 'call_recording',
            style: 'col col-lg'
        },
        transcription: {
            title: 'generate_transcript',
            style: 'col col-lg'
        },
        edit: {
            title: '',
            style: 'col --menu'
        }
    };
    const columns = [
        {
            id: 'user',
            title: 'user',
            status: true
        },
        {
            id: 'personal_number',
            title: 'personal_number',
            status: true
        },
        {
            id: 'group_number',
            title: 'group_number',
            status: !!groupNumber
        },
        {
            id: 'call_recording',
            title: 'call_recording',
            status: true
        },
        {
            id: 'transcription',
            title: 'generate_transcript',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true
        }
    ];
    const contentConfig = {
        user: {
            title: 'user',
            style: 'col col-name wrapper-call py-1',
            type: LIST_TABLE_ITEM_TYPE.USER_VOIP
        },
        personal_number: {
            title: 'personal_local_number',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.PERSONAL_NUMBER_VOIP
        },
        group_number: {
            title: 'group_number',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.GROUP_NUMBER_VOIP
        },
        call_recording: {
            title: 'call_recording',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.CALL_RECORDING_VOIP
        },
        transcription: {
            title: 'generate_transcript',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.CALL_TRANSCRIPTION
        },
        edit: {
            title: '',
            style: 'col --menu',
            type: LIST_TABLE_ITEM_TYPE.EDIT_ACTIVE_USER_VOIP
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
