import React, { Fragment, forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { TYPE_EMAIL_ACH } from 'app/const/Customers';
import { emailInvitationBank, smsInvitationBank } from 'app/const/api/V2';
import SendByEmail from 'app/modules/jobdetail/tabs/invoice/actions/SendByEmail';
import SendByEmailAndSms from 'app/modules/jobdetail/tabs/invoice/actions/SendByEmailAndSms';
import SendBySms from 'app/modules/jobdetail/tabs/invoice/actions/SendBySms';
import { clientQuery } from 'common/utils/ApiUtils';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { LIST_ACTION_SEND_ACH_INVITATION } from 'app/modules/jobdetail/const';

const EmailACH = ({ isShowInvitation = false }, ref) => {
    const { t } = useTranslation('customers');
    const { customer } = useContext(CustomerDetailContext);
    const activateSms = useSelector(({ auth }) => auth.user.settings.addons.activate_sms);

    const refDropdown = useRef(null);
    const refSendByEmail = useRef(null);
    const refSendBySms = useRef(null);
    const refSendByEmailAndSms = useRef(null);
    const refType = useRef(TYPE_EMAIL_ACH.INVITE);

    const { id: customerId } = customer;
    const { SEND_BY_EMAIL, SEND_BY_SMS, SEND_BY_EMAIL_AND_SMS } = LIST_ACTION_SEND_ACH_INVITATION;
    const typeSend = !isShowInvitation ? TYPE_EMAIL_ACH.VERIFY : TYPE_EMAIL_ACH.INVITE;
    const urlGetTemplateEmail = emailInvitationBank(customerId);
    const urlGetTemplateSms = smsInvitationBank(customerId);

    useImperativeHandle(ref, () => ({
        _open: _handleOpenEmail
    }));

    const _handleOpenEmail = ({ isReminder = false, id }) => {
        const type = isReminder ? TYPE_EMAIL_ACH.VERIFY : TYPE_EMAIL_ACH.INVITE;
        refType.current = type;
        _handleOnClick({ id, type });
    };

    const _handleSendContent = (params, callback, isEmail = false) => {
        const { receiver, message, subject, sms_address, sms_content } = params || {};

        clientQuery(
            isEmail ? urlGetTemplateEmail : urlGetTemplateSms,
            {
                method: 'POST',
                toFormData: false,
                data: {
                    type: refType.current,
                    address: isEmail ? receiver : sms_address,
                    content: isEmail ? message : sms_content,
                    subject
                }
            },
            (response) => _handleSendEmailSuccess({ ...response, isEmail }, callback),
            (response) => _handleSendEmailFail(response, callback)
        );
    };

    const _handleSendEmailSuccess = (response = {}, callback) => {
        if (typeof callback === 'function') {
            const { message, isEmail } = response;
            callback(true, message.toString() || t(`customers:${isEmail ? 'email' : 'sms'}_sent_successfully`));
        }
    };

    const _handleSendEmailFail = (response, callback) => {
        if (typeof callback === 'function') {
            callback(false, response.message.toString() || t('customers:send_email_invitation_failed'));
        }
    };

    const _handleSendEmail = (params, callback) => {
        _handleSendContent(params, callback, true);
    };

    const _handleOnClick = ({ id, type }) => {
        switch (id) {
            case SEND_BY_EMAIL.id:
                refSendByEmail.current._handleOpenForm(type);
                break;
            case SEND_BY_SMS.id:
                refSendBySms.current._handleOpenForm(type);
                break;
            case SEND_BY_EMAIL_AND_SMS.id:
                refSendByEmailAndSms.current._handleOpenForm(type);
                break;
            default:
                break;
        }
        isShowInvitation && refDropdown.current._close();
    };

    const _renderOptions = () => {
        return Object.values(LIST_ACTION_SEND_ACH_INVITATION).map(({ id, title, icon, checkPermission }) => {
            if (checkPermission && !activateSms) return null;
            return (
                <li key={id} className="items has-icon fs-13 fw-500 btn-modal" onClick={() => _handleOpenEmail({ id })}>
                    {icon}
                    <span className="txt-ellipsis">{t(`common:${title}`)}</span>
                </li>
            );
        });
    };

    return (
        <Fragment>
            {isShowInvitation ? (
                <DropdownPopper
                    ref={refDropdown}
                    wrapperClassName="v2-dropdown"
                    wrapperListClass="v2-dropdown__menu --right content-full popper-content"
                    buttonClassName="dropbtn v2-btn-default --sm --icon-r fs-13"
                    isUseToggle
                    customButton={
                        <Fragment>
                            <p className="txt-ellipsis">{t(`common:ACH_invite`)}</p>
                            <span className="arrow">
                                <IconDropUpDown />
                            </span>
                        </Fragment>
                    }
                >
                    <ul>{_renderOptions()}</ul>
                </DropdownPopper>
            ) : null}

            <SendByEmail
                ref={refSendByEmail}
                customerId={customerId}
                onSend={_handleSendEmail}
                urlGetEmail={urlGetTemplateEmail}
            />
            {!!activateSms && (
                <SendBySms ref={refSendBySms} urlGetSMS={urlGetTemplateSms} onSend={_handleSendContent} />
            )}
            {!!activateSms && (
                <SendByEmailAndSms
                    ref={refSendByEmailAndSms}
                    customerId={customerId}
                    onSendEmail={_handleSendEmail}
                    urlGetSMS={urlGetTemplateSms}
                    urlGetEmail={urlGetTemplateEmail}
                    onSendSMS={_handleSendContent}
                    paramsEmail={{ type: typeSend }}
                    params={{ type: typeSend }}
                />
            )}
        </Fragment>
    );
};

export default forwardRef(EmailACH);
