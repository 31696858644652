import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IconError from 'assets/icon/IconError';

let timer;

const GdInput = ({
    label,
    name,
    type,
    error,
    dataHelper,
    disable,
    placeholder,
    handleKeypress,
    handleKeyDown,
    value,
    delay,
    withClassName,
    handleOnChange
}) => {
    const [keyword, setKeyword] = useState(value);

    useEffect(() => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (keyword !== value) {
                handleOnChange({ keyword: keyword });
            }
        }, delay);

        return () => timer && clearTimeout(timer);
    }, [keyword]);

    useEffect(() => {
        setKeyword(value);
    }, [value]);

    const onchange = (e) => {
        setKeyword(e.target.value);
    };

    return (
        <div className={withClassName || 'user-box rows mt-5'}>
            {label && <span className="txt">{label}</span>}
            <input
                type={type}
                name={name}
                data-helper={dataHelper}
                className={`field-input ${error && 'field-error'} ${disable && 'disable'}`}
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                onKeyPress={handleKeypress}
                value={keyword}
                onChange={onchange}
                spellCheck
            />
            {error && (
                <span className="txt-incorrect">
                    <IconError />
                    {error}
                </span>
            )}
        </div>
    );
};

GdInput.defaultProps = {
    value: '',
    delay: 0,
    handleOnChange: () => {}
};

GdInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    error: PropTypes.string,
    dataHelper: PropTypes.string,
    disable: PropTypes.bool,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    delay: PropTypes.number,
    handleOnChange: PropTypes.func
};

export default GdInput;
