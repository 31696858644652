import React from 'react';

export default function IconFileType({ type }) {
    switch (type) {
        case 'imageActive':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    {' '}
                    <path
                        d="M18 5H6C4.89543 5 4 5.89543 4 7V17C4 18.1046 4.89543 19 6 19H18C19.1046 19 20 18.1046 20 17V7C20 5.89543 19.1046 5 18 5Z"
                        fill="#DAC2F2"
                    />{' '}
                    <path
                        d="M14 10C15.1046 10 16 9.10457 16 8C16 6.89543 15.1046 6 14 6C12.8954 6 12 6.89543 12 8C12 9.10457 12.8954 10 14 10Z"
                        fill="#8D4AFC"
                    />{' '}
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.4111 10.2828L19.043 18.13C19.3812 18.4375 19.1637 19 18.7067 19H6C4.89543 19 4 18.1046 4 17V14L7.65153 10.3485C8.40642 9.59359 9.62114 9.56468 10.4111 10.2828Z"
                        fill="#8D4AFC"
                    />{' '}
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.3533 12.0299L20 13V17.4338C20 17.8968 19.425 18.1111 19.122 17.7611L15 13L16.2749 11.7251C17.056 10.944 18.3223 10.944 19.1034 11.7251C19.1965 11.8182 19.2802 11.9203 19.3533 12.0299Z"
                        fill="#8D4AFC"
                    />{' '}
                </svg>
            );
        case 'image/jpeg':
        case 'image/gif':
        case 'image/png':
        case 'image/bmp':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 11.5C13.6046 11.5 14.5 10.6046 14.5 9.5C14.5 8.39543 13.6046 7.5 12.5 7.5C11.3954 7.5 10.5 8.39543 10.5 9.5C10.5 10.6046 11.3954 11.5 12.5 11.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.5 5.5H17.5C18.6046 5.5 19.5 6.39543 19.5 7.5V16.5C19.5 17.6046 18.6046 18.5 17.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.5 14.5L7.79289 12.2071C8.18342 11.8166 8.81658 11.8166 9.20711 12.2071L15.5 18.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12.5 15.5L14.7929 13.2071C15.1834 12.8166 15.8166 12.8166 16.2071 13.2071L18.5 15.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'application/pdf':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.5 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5V9.62132C18.5 8.26306 17.9604 6.96043 17 6C16.0396 5.03957 14.7369 4.5 13.3787 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V18.5C4.5 19.6046 5.39543 20.5 6.5 20.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />{' '}
                    <path
                        d="M18.5 11.5V10.5C18.5 9.39543 17.6046 8.5 16.5 8.5C15.3954 8.5 14.5 7.60457 14.5 6.5C14.5 5.39543 13.6046 4.5 12.5 4.5H11.4923"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />{' '}
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 10.5H18.5C19.6046 10.5 20.5 11.3954 20.5 12.5V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H4.5C3.39543 18.5 2.5 17.6046 2.5 16.5V12.5C2.5 11.3954 3.39543 10.5 4.5 10.5Z"
                        fill="white"
                    />{' '}
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.5 10.5H18.5C19.6046 10.5 20.5 11.3954 20.5 12.5V16.5C20.5 17.6046 19.6046 18.5 18.5 18.5H4.5C3.39543 18.5 2.5 17.6046 2.5 16.5V12.5C2.5 11.3954 3.39543 10.5 4.5 10.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />{' '}
                    <path
                        d="M5.61646 17H6.23293V15.1406H7.34657C8.53229 15.1406 9.06674 14.4197 9.06674 13.5199C9.06674 12.62 8.53229 11.9091 7.33663 11.9091H5.61646V17ZM6.23293 14.5937V12.456H7.31674C8.14451 12.456 8.46021 12.9084 8.46021 13.5199C8.46021 14.1314 8.14451 14.5937 7.32668 14.5937H6.23293Z"
                        fill="#4D5054"
                    />{' '}
                    <path
                        d="M11.6308 17C13.182 17 14.057 16.0355 14.057 14.4446C14.057 12.8636 13.182 11.9091 11.7004 11.9091H10.0598V17H11.6308ZM10.6763 16.4531V12.456H11.6607C12.8538 12.456 13.4604 13.2116 13.4604 14.4446C13.4604 15.6875 12.8538 16.4531 11.5911 16.4531H10.6763Z"
                        fill="#4D5054"
                    />{' '}
                    <path
                        d="M15.0911 17H15.7075V14.723H17.9149V14.1761H15.7075V12.456H18.1436V11.9091H15.0911V17Z"
                        fill="#4D5054"
                    />{' '}
                </svg>
            );
        case 'audio/basic':
        case 'audio/l24':
        case 'audio/mp4':
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/vorbis':
        case 'audio/vnd.rn-realaudio':
        case 'audio/vnd.wave':
        case 'audio/3gpp':
        case 'audio/3gpp2':
        case 'audio/ac3':
        case 'audio/webm':
        case 'audio/amr-nb':
        case 'audio/amr':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <ellipse cx="10.5" cy={16} rx={3} ry="2.5" stroke="#4D5054" strokeWidth="1.3" />

                    <path
                        d="M13.5 16V3.5C13.8333 4.5 15.1 6.5 17.5 6.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M13.5 6.5C13.8333 7.5 15.1 9.5 17.5 9.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'text/vcard':
        case 'text/csv':
        case 'text/rtf':
        case 'text/richtext':
        case 'text/calendar':
        case 'text/directory':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5C5.5 4.94772 5.94772 4.5 6.5 4.5H17.5C18.0523 4.5 18.5 4.94772 18.5 5.5V18.5C18.5 19.0523 18.0523 19.5 17.5 19.5H6.5C5.94772 19.5 5.5 19.0523 5.5 18.5V5.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                    />

                    <path
                        d="M6 7.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 10.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 13.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 16.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M9.5 19V4.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'video/x-ms-asf':
        case 'video/x-ms-wmv':
        case 'video/x-ms-wmx':
        case 'video/x-ms-wm':
        case 'video/avi':
        case 'video/divx':
        case 'video/x-flv':
        case 'video/quicktime':
        case 'video/mpeg':
        case 'video/mp4':
        case 'video/ogg':
        case 'video/webm':
        case 'video/x-matroska':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12 19.5C16.1421 19.5 19.5 16.1421 19.5 12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 16.1421 7.85786 19.5 12 19.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M10.5 15.5V8.5L15.5 12L10.5 15.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.ms-word.document.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.template.macroEnabled.12':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M6.5 20.5H16.5C17.6046 20.5 18.5 19.6046 18.5 18.5V9.62132C18.5 8.26306 17.9604 6.96043 17 6C16.0396 5.03957 14.7369 4.5 13.3787 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V18.5C4.5 19.6046 5.39543 20.5 6.5 20.5Z"
                            stroke="#4D5054"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.5 11.5V10.5C18.5 9.39543 17.6046 8.5 16.5 8.5C15.3954 8.5 14.5 7.60457 14.5 6.5C14.5 5.39543 13.6046 4.5 12.5 4.5H11.4923"
                            stroke="#4D5054"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 10.5H20.5C21.6046 10.5 22.5 11.3954 22.5 12.5V16.5C22.5 17.6046 21.6046 18.5 20.5 18.5H2.5C1.39543 18.5 0.5 17.6046 0.5 16.5V12.5C0.5 11.3954 1.39543 10.5 2.5 10.5Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.5 10.5H20.5C21.6046 10.5 22.5 11.3954 22.5 12.5V16.5C22.5 17.6046 21.6046 18.5 20.5 18.5H2.5C1.39543 18.5 0.5 17.6046 0.5 16.5V12.5C0.5 11.3954 1.39543 10.5 2.5 10.5Z"
                            stroke="#4D5054"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M4.18748 17C5.73861 17 6.61361 16.0355 6.61361 14.4446C6.61361 12.8636 5.73861 11.9091 4.25708 11.9091H2.61646V17H4.18748ZM3.23293 16.4531V12.456H4.21731C5.41049 12.456 6.01702 13.2116 6.01702 14.4446C6.01702 15.6875 5.41049 16.4531 4.14771 16.4531H3.23293Z"
                            fill="#4D5054"
                        />
                        <path
                            d="M11.9432 14.4545C11.9432 12.8437 10.9986 11.8395 9.696 11.8395C8.39344 11.8395 7.44884 12.8437 7.44884 14.4545C7.44884 16.0653 8.39344 17.0696 9.696 17.0696C10.9986 17.0696 11.9432 16.0653 11.9432 14.4545ZM11.3466 14.4545C11.3466 15.777 10.6207 16.4929 9.696 16.4929C8.77128 16.4929 8.04543 15.777 8.04543 14.4545C8.04543 13.1321 8.77128 12.4162 9.696 12.4162C10.6207 12.4162 11.3466 13.1321 11.3466 14.4545Z"
                            fill="#4D5054"
                        />
                        <path
                            d="M17.0763 13.5C16.8874 12.456 16.0522 11.8395 15.028 11.8395C13.7255 11.8395 12.7809 12.8437 12.7809 14.4545C12.7809 16.0653 13.7255 17.0696 15.028 17.0696C16.0522 17.0696 16.8874 16.4531 17.0763 15.4091H16.4599C16.3107 16.115 15.7042 16.4929 15.028 16.4929C14.1033 16.4929 13.3775 15.777 13.3775 14.4545C13.3775 13.1321 14.1033 12.4162 15.028 12.4162C15.7042 12.4162 16.3107 12.794 16.4599 13.5H17.0763Z"
                            fill="#4D5054"
                        />
                        <path
                            d="M18.3298 11.9091H17.6039L19.2446 14.4545L17.6039 17H18.3298L19.6423 14.9219H19.6821L20.9946 17H21.7204L20.1196 14.4545L21.7204 11.9091H20.9946L19.6821 14.027H19.6423L18.3298 11.9091Z"
                            fill="#4D5054"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0">
                            <rect width={24} height={24} fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            );
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-access':
        case 'application/vnd.ms-project':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
        case 'application/vnd.ms-excel.template.macroEnabled.12':
        case 'application/vnd.ms-excel.addin.macroEnabled.12':
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5.5 5.5C5.5 4.94772 5.94772 4.5 6.5 4.5H17.5C18.0523 4.5 18.5 4.94772 18.5 5.5V18.5C18.5 19.0523 18.0523 19.5 17.5 19.5H6.5C5.94772 19.5 5.5 19.0523 5.5 18.5V5.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                    />

                    <path
                        d="M6 7.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 10.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 13.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M6 16.5H18"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M9.5 19V4.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
        default:
            return (
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 4.5H13.6716C14.202 4.5 14.7107 4.71071 15.0858 5.08579L17.9142 7.91421C18.2893 8.28929 18.5 8.79799 18.5 9.32843V17.5C18.5 18.6046 17.6046 19.5 16.5 19.5H7.5C6.39543 19.5 5.5 18.6046 5.5 17.5V6.5C5.5 5.39543 6.39543 4.5 7.5 4.5Z"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />

                    <path
                        d="M13.5017 4.5V7.5C13.5017 8.60457 14.3971 9.5 15.5017 9.5H18.5"
                        stroke="#4D5054"
                        strokeWidth="1.3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            );
    }
}
