import { call } from "redux-saga/effects";
import { getUrlCustomerTags
} from "app/const/Api";
import { fetchWithToken } from "common/utils/ApiUtils";

export function* getListCustomerTag({ params, actionSuccess, actionFailed }) {

	const customerId = params.customer_id
	delete params?.customer_id

	try {
		const response = yield call(
            fetchWithToken,
			getUrlCustomerTags(customerId),
			params
		);
		if (response && response.success) {
			actionSuccess(response);
		} else {
			actionFailed(response);
		}
	} catch (error) {
		actionFailed(error);
	}
}