import React, { useEffect, useReducer, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { TYPE_CALL, TYPE_OUT_GOING } from 'app/const/Voip';
import IconArrow from 'assets/icon/IconArrow';
import { CUSTOMERS } from 'app/const/Route';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListSmartDialer } from 'app/const/Api';
import StatusCallDialer from './components/smartDialer/StatusCallDialer';
import { updateIndexDialer } from 'common/redux/actions/voipAction';
import { SMART_DIALER } from 'app/config/routes';
import ServiceShortcut from './components/smartDialer/ServiceShortcut';
import { addBranchPath } from 'app/const/Branch';

const SmartDialer = ({
    item = {},
    calls = [],
    onHangUp = () => {},
    onDisconnect = () => {},
    onTransfer = () => {},
    onMuteCall = () => {},
    onCallDial = () => {},
    onOutGoingCall = () => {},
    onSetIndexDialer = () => {}
}) => {
    const { t } = useTranslation('header');
    const history = useHistory();
    const dispatch = useDispatch();
    const refTimer = useRef(null);
    const refTimeOut = useRef(null);
    const refForceNext = useRef(false);
    const refNewList = useRef(null);
    const refTotal = useRef(0);
    const refLeaveCall = useRef(null);
    const timeOut = 5;
    const limit = 50;

    const { ACCEPTING, CALLING, REJECT, BUSY, NEXT_LEAD, PAUSED, PAUSED_EDIT, EMPTY, PREPARE, END, TRANSFER } =
        TYPE_OUT_GOING;

    const { type: typeItem, status: statusItem, isPaused, is_mute: isMuteCall, smartDialer: finalDialer } = item;

    const [state, dispatchState] = useReducer(reducer, {
        type: PREPARE,
        isCalling: false,
        count: 0,
        numbers: [],
        total: 0,
        offset: 0
    });

    const {
        type: finalType,
        isCalling,
        count: finalCount,
        numbers: finalNumbers,
        total: finalTotal,
        offset: finalOffset
    } = state;

    useEffect(() => {
        if (typeItem !== TYPE_CALL.SMART) return;
        fetchListCall({ isScratch: finalDialer.isScratch, offset: finalDialer.callIndex });
    }, [typeItem]);

    useEffect(() => {
        _handleChangeType();
    }, [finalType]);

    useEffect(() => {
        if (!!statusItem) {
            if ([PAUSED, PAUSED_EDIT, EMPTY].includes(finalType)) return;
            if (statusItem === BUSY && isPaused) {
                _handlePauseCall();
                return;
            }

            let isFlagNextCall = false;

            dispatchState((prev) => {
                isFlagNextCall = prev.type === NEXT_LEAD && [BUSY, END].includes(statusItem);

                return {
                    ...prev,
                    type: (prev.type === BUSY && statusItem === END) || isFlagNextCall ? prev.type : statusItem,
                    isCalling: [ACCEPTING, NEXT_LEAD, PREPARE].includes(statusItem)
                };
            });

            setTimeout(() => {
                if (isFlagNextCall && refForceNext.current) {
                    _handleNextCall();
                }
            }, 0);
        }
    }, [statusItem]);

    useEffect(() => {
        if (finalDialer?.isReset) {
            fetchListCall({ isReset: true });
        }
    }, [finalDialer?.isReset]);

    const fetchListCall = ({ isReset = false, isScratch = false, offset = finalOffset }) => {
        offset = isScratch || isReset ? 0 : parseInt(offset);

        const _handleSuccess = ({ data }) => {
            const { customers, current_call_index, total } = data;
            if (isReset) {
                refNewList.current = { customers, total };
                return;
            }

            const customerLength = customers?.length;
            const newCurrentIndex = current_call_index >= total ? 0 : current_call_index;
            let newCount = 0;
            if (!!customerLength) {
                const position = isScratch ? 0 : newCurrentIndex - offset;

                const customerIndex = customers.find((item, index) => {
                    if (index >= position && !!item.phone) {
                        item.index = index;
                        return true;
                    }
                    return false;
                });

                const { phone: phoneItem, index } = customerIndex || {};

                newCount = index ?? -1;
                refTotal.current = total;
                phoneItem && _handleMakeCallLead(phoneItem, newCount + offset);
            }

            if (newCount < 0 && offset + customerLength < total) {
                fetchListCall({ offset: offset + limit });
                return;
            }

            dispatchState((prev) => ({
                ...prev,
                type: newCount < 0 || !customerLength ? EMPTY : NEXT_LEAD,
                isCalling: !!customerLength,
                numbers: customers,
                current_index: newCurrentIndex,
                count: newCount + offset,
                total,
                offset
            }));
        };

        const _handleFail = () => {
            dispatchState((prev) => ({ ...prev, type: EMPTY, isCalling: false }));
        };

        clientQuery(
            getListSmartDialer(finalDialer.id),
            { method: 'GET', data: { limit, offset, total: 1 } },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderNextCall = () => {
        if ([NEXT_LEAD, EMPTY, TRANSFER].includes(finalType)) return null;

        return (
            <div
                className={classNames('action-call tooltip mr-2', { 'is-disable': finalType === PREPARE })}
                onClick={_handleClickNextCall}
            >
                <div id="btn_nextCall" className={`btn-next-call svg-white has-label --icon-r`}>
                    {t('next_call')}
                    <IconArrow />
                </div>
                <span className="tooltiptext bottom">Ctrl + Shift + X</span>
            </div>
        );
    };

    const _handleChangeType = () => {
        clearTimeout(refLeaveCall.current);
        switch (finalType) {
            case REJECT:
            case BUSY:
            case END:
                if (refForceNext.current) {
                    setTimeout(() => {
                        _handleNextCall();
                    }, 100);
                    return;
                }

                _handleOnPause();
                return;

            case ACCEPTING:
                clearTimeout(refTimeOut.current);
                _navigateCustomer();
                return;

            case EMPTY:
                dispatch(updateIndexDialer({ id: finalDialer.id, index: 0 }));
                onSetIndexDialer(0);
                history.push(addBranchPath(SMART_DIALER), { dialer: finalDialer });
                refLeaveCall.current = setTimeout(() => {
                    _handleLeaveCall();
                }, 3000);
                return;

            default:
                return;
        }
    };

    const _handleHangUp = () => {
        onHangUp(null, { isSmartDialer: true });
        dispatchState((prev) => ({ ...prev, type: END, isCalling: false }));
    };

    const _handleMakeCallLead = (toPhoneNumber, index = 0) => {
        const newCount = index + 1 >= refTotal.current ? 0 : index + 1;

        onOutGoingCall({
            toPhoneNumber,
            type: TYPE_CALL.SMART,
            smartViewId: finalDialer.id,
            callIndex: newCount
        }).then((res) => {
            dispatch(updateIndexDialer({ id: finalDialer.id, index: newCount }));
            if (!res) {
                _handleNextCall();
            }
        });
    };

    const _handleClickNextCall = () => {
        if (finalType === PREPARE) return;

        _clearTimeout();
        let isForce = false;

        dispatchState((prev) => {
            const { type, count, total } = prev;
            if (count + 1 >= total) return { ...prev, type: EMPTY, isCalling: false };

            calls.forEach((item) => {
                if (
                    (item.type === TYPE_CALL.IN && item.status === CALLING) ||
                    item.type === TYPE_CALL.OUT ||
                    item.type === TYPE_CALL.SMART
                ) {
                    item.connection?.disconnect();
                }
            });

            if ([CALLING, ACCEPTING].includes(type)) {
                refForceNext.current = true;
                isForce = true;
            }

            return isForce ? { ...prev, type: NEXT_LEAD } : prev;
        });

        setTimeout(() => {
            !isForce && _handleNextCall();
        }, 0);
    };

    const _handleNextCall = () => {
        refForceNext.current = false;

        const { total, customers } = refNewList.current || {};
        const newList = !!customers ? [...customers] : null;
        refNewList.current = null;
        let phoneNumber = null;
        let newIndex = 0;

        dispatchState((prev) => {
            const newTotal = (refTotal.current = !newList ? prev.total : total);
            const newOffset = !newList ? prev.offset : 0;

            const countNotOffset = prev.count - prev.offset;
            const newNumbers = !newList ? prev.numbers : newList;
            const lengthNumber = newNumbers.length;
            const maxLength = lengthNumber - (!newList ? countNotOffset + 1 : 0);
            let newCount = !newList ? countNotOffset : -1;

            const indexNumber = newNumbers.findIndex((item, index) => {
                if (index === 0 || index > maxLength) return false;
                phoneNumber = newNumbers[newCount + index]?.phone;
                return !!phoneNumber;
            });

            newCount += Math.max(0, indexNumber);

            if (newCount >= newTotal || !lengthNumber) return { ...prev, type: EMPTY, isCalling: false };
            newIndex = newCount + newOffset;

            return {
                ...prev,
                type: NEXT_LEAD,
                isCalling: true,
                count: newIndex,
                numbers: newNumbers,
                total: newTotal,
                offset: newOffset
            };
        });

        setTimeout(() => {
            if (!!phoneNumber) {
                _handleMakeCallLead(phoneNumber, newIndex);
                return;
            }
            fetchListCall({ offset: finalOffset + limit });
        }, 0);
    };

    const _handlePauseCall = () => {
        _clearTimeout();
        dispatchState((prev) => ({ ...prev, type: PAUSED, isCalling: false }));
    };

    const _handleOnPause = () => {
        _handleToggleClassName();

        refTimer.current = setTimeout(() => {
            _handleClickNextCall();
        }, timeOut * 1000);
    };

    const _handleLeaveCall = () => {
        onDisconnect(null, false, true);
    };

    const _clearTimeout = () => {
        _handleToggleClassName(false);
        clearTimeout(refTimer.current);
    };

    const _handleToggleClassName = (isAdd = true) => {
        const elm = document.getElementById('btn_nextCall')?.classList;
        elm?.[isAdd ? 'add' : 'remove']('is-processing', `second-${timeOut}`);
    };

    const _navigateCustomer = () => {
        const customerId = finalNumbers?.[finalCount - finalOffset]?.id;
        if (!customerId) return;
        history.push(addBranchPath(`${CUSTOMERS}/${customerId}`), {
            isFromCall: true
        });
    };

    if (item.type !== TYPE_CALL.SMART) return;

    return (
        <div className={classNames(`call-bar flexcenter --smart-views`, { 'is-calling': isCalling })}>
            <StatusCallDialer
                type={finalType}
                dialer={finalDialer}
                count={finalCount}
                total={finalTotal}
                customer={finalNumbers?.[finalCount - finalOffset]}
                isMuteCall={isMuteCall}
                callData={item}
                onNavigate={_navigateCustomer}
                onHangUp={_handleHangUp}
                onPauseCall={_handlePauseCall}
                onLeaveCall={_handleLeaveCall}
                onMuteCall={onMuteCall}
                onTransfer={onTransfer}
                onCallDial={onCallDial}
            />
            {_renderNextCall()}
            <span
                className={classNames(`call-bar__status w-100`, {
                    'is-calling': isCalling,
                    'is-failed': [REJECT, BUSY].includes(finalType),
                    'is-end': finalType === EMPTY
                })}
            />
            <ServiceShortcut
                onPauseCall={_handlePauseCall}
                onHangUp={_handleHangUp}
                onNextCall={_handleClickNextCall}
                type={finalType}
            />
        </div>
    );
};

export default SmartDialer;
