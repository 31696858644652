import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import ServiceName from './Name';
import ServiceTime from './Time';
import Confirmed from 'app/modules/jobdetail/tabs/settingschedule/Confirmed';
import Summary from 'app/modules/jobdetail/tabs/settingschedule/Summary';
import Repeat from 'app/modules/jobdetail/tabs/settingschedule/repeat';
import Except from 'app/modules/jobdetail/tabs/settingschedule/except';
import Ends from 'app/modules/jobdetail/tabs/settingschedule/ends';
import JobCycles from 'app/modules/jobdetail/tabs/settingschedule/JobCycle';
import ServiceColor from './ServiceColor';

const ServiceInfo = forwardRef(({ tabActive }, ref) => {
    const refSummary = useRef(null);
    const refServiceTime = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    function _getValue() {
        const timeSchedule = refServiceTime.current.getValue() || {};
        const serviceLength = timeSchedule.jobLength || {};

        return {
            hours: serviceLength.hour,
            minutes: serviceLength.minute
        };
    }

    return (
        <div className={`tab-conts dashboard-job-detail ${tabActive ? 'tab-content-active' : ''}`}>
            <div className="container-setting-center">
                <div className="header-modal header-service">
                    <h3 className="name-tabs fs-18 fw-600 flex-1">Service</h3>
                </div>
                <div className="container-column new-service schedule-wrapper">
                    <div className="details-job">
                        <ServiceName />
                        <ServiceTime ref={refServiceTime} />
                        <div className="line" />
                        <Repeat />
                        <Ends />
                        <Except showFrequency={false} />
                        <Summary ref={refSummary} />
                        <Confirmed />
                        <JobCycles />
                        <ServiceColor />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ServiceInfo;
