import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableOverride from './TableOverride';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListItems } from 'app/const/Api';
import { resetListItems } from 'common/redux/actions/itemsAction';
import { OVERRIDE_KEYWORD } from 'app/const/setting/SettingGlobalOverride';
import { previewNewRate } from '../utils/override';

const ItemOverride = () => {
    const refTable = useRef(null);
    const dispatch = useDispatch();
    const typeOverride = OVERRIDE_KEYWORD.ITEM;

    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true
    });

    const { data: finalData, isLoading } = state;

    useEffect(() => {
        fetchDataOverride();
    }, []);

    const fetchDataOverride = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, data, isLoading: false }));
        };

        const _handleFail = (err) => {
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
            refTable.current?.showAlert(err);
        };

        clientQuery(getListItems, { method: 'GET', data: { fields: 'cost' } }, _handleSuccess, _handleFail);
    };

    const _handleApplySuccess = ({ dataApply }) => {
        dispatch(resetListItems());
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    const newValue = dataApply[item.id];
                    if (!newValue || !newValue.value?.length) return item;
                    return {
                        ...item,
                        cost: previewNewRate({ type: typeOverride, prevValue: item.cost, newValue })
                    };
                })
            };
        });
    };

    return (
        <div className="override-container flex-column">
            <TableOverride
                ref={refTable}
                data={finalData}
                keyGetRate="cost"
                currency={currency}
                isLoading={isLoading}
                typeOverride={typeOverride}
                onApplySuccess={_handleApplySuccess}
            />
        </div>
    );
};

export default ItemOverride;
