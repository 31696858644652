import React, { useReducer, useEffect, useRef, useMemo } from 'react';
import loadable from '@loadable/component';
import { useDispatch } from 'react-redux';

import ManageActiveJobStatusCreate from '../components/ManageActiveJobStatusCreate';
import ManageActiveJobStatusItem from '../components/ManageActiveJobStatusItem';
import LoadingActiveJobStatus from '../components/LoadingActiveJobStatus';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { JOB_STATUS_END_POINT, JOB_STATUS_ORDERS_END_POINT } from 'app/const/api/V2';
import { handleOrderRetrieve } from 'common/utils/JobStatusUtils';
import { JOB_CUSTOM, JOB_EXCLUDE, JOB_STATUS_ITEM_ACTION } from 'app/const/Job';
import { LIST_STATUS } from 'app/const/Status';
import { actionUpdateJobStatus } from 'common/redux/actions/authAction';
import { useTranslation } from 'react-i18next';
import { STATE_JOB_STATUS, convertGroups } from '../utils';
import useJobStatus from 'common/hooks/useJobStatus';

const GDStatusBar = loadable(() => import('app/components/status/statusbar'));

const JobStatusManageActive = () => {
    const { t } = useTranslation(['addons']);

    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        groups: {},
        isLoading: true
    });
    const { data: finalData, isLoading, groups: finalGroups } = state;
    const listJobStatus = useJobStatus({ isIncludeDeleted: true });
    const refAlert = useRef(null);

    useEffect(() => {
        _getJobStatusList();
    }, []);

    const _getJobStatusList = () => {
        const _handleGetSuccess = ({ data }) => {
            dispatchState((prevState) => ({
                ...prevState,
                data,
                groups: convertGroups({ data }),
                isLoading: false
            }));
        };
        const _handleGetFail = (err) => {
            _handleDisplayAlert(err);
            dispatchState({ data: [], groups: {}, isLoading: false });
        };

        clientQuery(
            JOB_STATUS_END_POINT,
            { method: 'GET', data: { state: STATE_JOB_STATUS.ACTIVE } },
            _handleGetSuccess,
            _handleGetFail
        );
    };

    const _handleCreate = (item) => {
        const newList = [...finalData, item];
        const groupTypeStatus = finalGroups[item.type] || [];
        const newListJobStatus = [...listJobStatus];
        const lengthGroup = groupTypeStatus.length;
        const lastId = lengthGroup ? groupTypeStatus[lengthGroup - 1].status_id : item.type;
        const indexLastType = newListJobStatus.findIndex((jobStatus) => jobStatus.id === lastId);
        const spliceIndex = indexLastType < 0 ? newListJobStatus.length - 1 : indexLastType + 1;

        newListJobStatus.splice(spliceIndex, 0, {
            ...item,
            id: item.status_id,
            deleted: false
        });

        dispatchState((prevState) => ({
            ...prevState,
            data: newList,
            groups: convertGroups({ data: newList })
        }));

        dispatch(actionUpdateJobStatus(newListJobStatus));
    };

    const optionsDefault = useMemo(() => {
        return listJobStatus.filter((job) => {
            return job.type !== JOB_CUSTOM && !job.label;
        });
    }, [listJobStatus]);

    const _handleDisplayAlert = ({ message, success = true }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('alert_create', message, success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
    };

    const _handleAction = (action, item) => {
        const newData = [...finalData];
        const newListJobStatus = [...listJobStatus];

        const index = newData.findIndex((data) => data.id === item.id);
        const indexItemInGlobal = newListJobStatus.findIndex((data) => data.id === item.status_id);

        if (action === JOB_STATUS_ITEM_ACTION.DELETE) {
            newData.splice(index, 1);
            newListJobStatus[indexItemInGlobal] = { ...item, deleted: true, id: item.status_id };
        } else if (action === JOB_STATUS_ITEM_ACTION.EDIT) {
            newData[index] = item;
            newListJobStatus[indexItemInGlobal] = { ...item, id: item.status_id };
        }

        dispatchState((prevState) => ({
            ...prevState,
            data: newData,
            groups: convertGroups({ data: newData })
        }));

        dispatch(actionUpdateJobStatus(newListJobStatus));

        _handleDisplayAlert({
            message: t(
                action === JOB_STATUS_ITEM_ACTION.EDIT
                    ? 'addons:job_status_edit_success'
                    : 'addons:job_status_delete_success'
            ),
            success: true
        });
    };

    const _handleDragEnd = ({ result, type }) => {
        const {
            source: { index: sourceIndex },
            destination: { index: destinationIndex }
        } = result;

        if (sourceIndex === destinationIndex) return;

        const nextFinalData = [...finalData];
        const item = finalGroups[type][sourceIndex];
        const replaceItem = finalGroups[type][destinationIndex];
        nextFinalData.splice(item.index, 1);
        nextFinalData.splice(replaceItem.index, 0, item);
        const afterId = nextFinalData[replaceItem.index - 1]?.id || '';
        const groups = convertGroups({ data: nextFinalData });

        dispatchState((prevState) => ({
            ...prevState,
            data: nextFinalData,
            groups
        }));

        const _handleSuccess = () => {
            dispatch(actionUpdateJobStatus(handleOrderRetrieve({ item, groups })));
        };

        clientQuery(
            JOB_STATUS_ORDERS_END_POINT,
            {
                method: 'PUT',
                data: { id: item.id, after_id: afterId },
                toFormData: false
            },
            _handleSuccess
        );
    };

    const _renderJobStatusItem = () => {
        if (!optionsDefault.length) return null;
        return optionsDefault.map((item) => {
            if (JOB_EXCLUDE.MANAGE_CUSTOM_JOB.includes(item.id)) return null;

            return (
                <ManageActiveJobStatusItem
                    key={item.id}
                    {...item}
                    group={finalGroups[item.type] || []}
                    onHandleAction={_handleAction}
                    onShowAlert={_handleDisplayAlert}
                    handleOnDragEnd={_handleDragEnd}
                />
            );
        });
    };

    const _renderJobStatusCustomItem = () => {
        const customList = finalGroups[JOB_CUSTOM] || [];
        if (!customList.length) return null;

        return (
            <ManageActiveJobStatusItem
                customList={customList}
                onHandleAction={_handleAction}
                onShowAlert={_handleDisplayAlert}
                isCustom
                type={JOB_CUSTOM}
                handleOnDragEnd={_handleDragEnd}
            />
        );
    };

    return (
        <div className="tabs-job scrolls">
            <div className="boxs-wrapper">
                <div className="container-column wrapper-job-status">
                    <GDStatusBar ref={refAlert} />
                    {isLoading ? (
                        <LoadingActiveJobStatus />
                    ) : (
                        <div className="wrapper-job-status__content">
                            <ManageActiveJobStatusCreate
                                onCreate={_handleCreate}
                                options={optionsDefault}
                                groups={finalGroups}
                            />
                            {_renderJobStatusItem()}
                            <ManageActiveJobStatusCreate onCreate={_handleCreate} groups={finalGroups} isCustom />
                            {_renderJobStatusCustomItem()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobStatusManageActive;
