import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { JOB_CUSTOM } from 'app/const/Job';
import { ADD_JOB_STATUS, EDIT_JOB_STATUS } from 'app/const/Permissions';

const HEADER_GRID = {
    user: { title: 'user', style: 'col col-lg' },
    role: { title: 'role', style: 'col col-role' },
    add_job_status: { title: 'add', style: 'col col-sm' },
    edit_job_status: { title: 'edit/delete', style: 'col col-sm' }
};
const COLUMNS_GRID = [
    { id: 'user', title: 'user', status: true },
    { id: 'role', title: 'account', status: true },
    { id: 'add_job_status', title: 'add', status: true, keyGetValue: 'permission' },
    { id: 'edit_job_status', title: 'edit/delete', status: true, keyGetValue: 'permission' }
];
const CONTENT_GRID = {
    user: { id: 'user', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.USER_HAS_AVT },
    role: { id: 'role', style: 'col col-role', type: LIST_TABLE_ITEM_TYPE.ROLE_USER },
    add_job_status: {
        id: ADD_JOB_STATUS,
        style: 'col col-sm',
        type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
    },
    edit_job_status: {
        id: EDIT_JOB_STATUS,
        style: 'col col-sm',
        type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
    }
};
export const GRID_COLUMNS = { header: HEADER_GRID, columns: COLUMNS_GRID, contentConfig: CONTENT_GRID };

export const DEFAULT_COLOR = '#FFE500';
export const DEFAULT_CONTRAST_COLOR = '#bbd6ee';

export const JOB_STATUS_DELETE_ACTION = {
    DELETE: 1,
    UNDELETE: 2,
    DELETE_PERMANENTLY: 3
};

export const JOB_STATUS_CONFIRM_TITLE = {
    DELETE: 'delete',
    UNDELETE: 'undelete',
    DELETE_PERMANENTLY: 'permanently_delete'
};

export const STATE_JOB_STATUS = {
    ACTIVE: 1,
    DELETED: 2
};

export const convertGroups = ({ data = [] }) => {
    return data.reduce((results, job, index) => {
        if (!results[job.type]) results[job.type] = [];
        if (!job.label && job.type !== JOB_CUSTOM) return results;
        results[job.type].push({ ...job, index });
        return results;
    }, {});
};
