import { gql } from '@apollo/client';

export const CREATE_SMART_VIEW_MUTATION = gql`
    mutation createSmartView($data: CreateSmartView!) {
        createSmartView(data: $data) {
            id
            name
            shareOption
            sharedWith
            createdBy
            definition {
                query
                sort
                limit
                columns {
                    field_id
                    type_id
                }
            }
        }
    }
`;
