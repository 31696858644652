import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import GDCollapse from 'app/components/collapse';
import GdListPhone from 'app/components/phone/ListPhone';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import AltContacts from '../AltContacts';
import { TYPE_OPEN_SMS } from 'app/const/Sms';

const BlockContacts = () => {
    const { t } = useTranslation('customers');
    const { customer, contacts } = useContext(CustomerDetailContext);
    const hideContent = customer?.phones?.length === 0 && !customer?.email && !contacts?.length;

    return (
        <GDCollapse
            isUp
            isArrowRight
            defaultCollapsed
            isDisable={hideContent}
            title={t('contacts')}
            className="details-info wrap-item wrap-collapse"
            classWrapTitle="wrap-item__header"
            classTitle="txt-ellipsis flex-1"
        >
            {!hideContent ? (
                <div className="wrap-collapse__details flex-column gap-6">
                    <div className="valid-name">
                        <p className="valid-name__title">{customer.full_name}</p>
                        <div className="label-content --primary">{t('primary')}</div>
                    </div>
                    <GdListPhone data={customer.phones} typeOpen={TYPE_OPEN_SMS.CUSTOMER} customer={customer} />
                    {customer.email ? (
                        <a href={`mailto:${customer.email}`} className="is-link">
                            {customer.email}
                        </a>
                    ) : null}
                    <AltContacts />
                </div>
            ) : null}
        </GDCollapse>
    );
};

export default BlockContacts;
