import moment from 'moment-timezone';
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { useSelector } from 'react-redux';

import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import { TODAY } from 'app/const/App';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconFuture from 'assets/icon/IconFuture';
import { convertPhpFormatToMoment, formartDateWithString } from 'common/utils/DateUtils';

const CustomInput = (
    {
        onClick,
        date,
        handleOnClick,
        showIconCalendar,
        shouldEmptyInput = false,
        dateFormatDefault = null,
        showIconClear = false
    },
    ref
) => {
    const dateFormat = dateFormatDefault || useSelector(({ auth }) => auth?.user?.company?.date_format);
    const newDate = moment(date);

    const formatDisplay = convertPhpFormatToMoment(dateFormat);

    return (
        <div className="relative">
            <input
                ref={ref}
                onClick={() => handleOnClick('set_open', { onClick })}
                className="field-input text-grey-light"
                type="text"
                placeholder={!date && shouldEmptyInput ? '' : newDate.format(formatDisplay)}
                value={!date && shouldEmptyInput ? '' : newDate.format(formatDisplay)}
                readOnly
            />
            {showIconClear && (
                <span className="svg-clear-date" onClick={() => handleOnClick('select', '')}>
                    <IconCircleClose />
                </span>
            )}
            {showIconCalendar && (
                <span className="svg-calendar-absolute">
                    <IconFuture />
                </span>
            )}
        </div>
    );
};

function CalendarDatePicker({
    selectDefault = null,
    onChange = () => {},
    showIconCalendar = true,
    minDate = false,
    shouldEmptyInput = false,
    dateFormatDefault = null,
    showIconClear = false
}) {
    const refDropDown = useRef(null);

    const currentDay = useMemo(() => {
        if (selectDefault) return formartDateWithString(moment(selectDefault).utc().format('YYYY-MM-DD HH:mm:ss'));
        if (shouldEmptyInput) return null;
        return TODAY;
    }, [selectDefault]);

    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(currentDay);
    const CustomInputRef = forwardRef(CustomInput);

    useEffect(() => {
        setSelected(currentDay);
    }, [currentDay]);

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isOpen]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _handleOpenDropdown = () => {
        setIsOpen(!isOpen);
    };

    const _closeDropdown = () => {
        isOpen && setIsOpen(false);
    };

    const handleOnClick = (type, value) => {
        switch (type) {
            case 'set_open':
                value.onClick();
                break;
            case 'select':
                setSelected(value);
                onChange(value);
                break;
            default:
                break;
        }
        if (value) _handleOpenDropdown();
    };

    const renderContainer = ({ children }) => {
        return (
            <div ref={refDropDown} id="show_date_picker" className="format-container-date-picker">
                <div className="wrapper-date-picker-container">
                    <CalendarContainer className="react-datepicker-custom">
                        <div style={{ position: 'relative' }}>{children}</div>
                    </CalendarContainer>
                </div>
            </div>
        );
    };

    return (
        <DatePicker
            open={isOpen}
            selected={selected}
            minDate={minDate ? new Date() : false}
            shouldCloseOnSelect={false}
            disabledKeyboardNavigation
            calendarContainer={renderContainer}
            onChange={(date) => handleOnClick('select', date)}
            customInput={
                <CustomInputRef
                    date={shouldEmptyInput && !selected ? '' : selected}
                    shouldEmptyInput={shouldEmptyInput}
                    handleOnClick={handleOnClick}
                    showIconCalendar={showIconCalendar}
                    dateFormatDefault={dateFormatDefault}
                    showIconClear={showIconClear}
                />
            }
            renderCustomHeader={HeaderCustom}
        />
    );
}

export default CalendarDatePicker;
