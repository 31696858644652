import { ADDONS_BOOKING_SETTINGS } from 'app/config/routes';
import { ADDONS_BOOKING, UPDATE_ADDONS_ONLINE_BOOKING_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import BookingLoading from './components/BookingLoading';
import BookingShowCode from './components/BookingShowCode';
import SaveSubdomain from './components/SaveSubdomain';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsBooking = () => {
    const { t } = useTranslation(['addons']);
    const [state, dispatchState] = useReducer(reducer, { booking: {}, isLoading: true });
    const { booking, isLoading } = state;

    useEffect(() => {
        _getBookingAddons();
    }, []);

    const _getBookingAddons = () => {
        const _getSuccess = ({ data }) => dispatchState({ booking: data, isLoading: false });
        clientQuery(ADDONS_BOOKING, { data: {}, method: 'GET' }, _getSuccess, checkAccessFail);
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <BookingLoading />
                    ) : (
                        <div className="boxs boxs--no-border">
                            <AddonsBoxHeader
                                keyword={booking.keyword}
                                name={booking.name}
                                status={booking.status}
                                upgrade={booking.upgrade}
                                level={booking.level}
                                urlUpdateStatus={UPDATE_ADDONS_ONLINE_BOOKING_OPTIONS}
                                urlSetting={ADDONS_BOOKING_SETTINGS}
                            />
                            <div className="boxs__contents mt-3">
                                <div className="email-description">
                                    {t('may_not_included_subject_new_billing_requirement')}
                                </div>
                                <p className="description mt-3">{t('start_accepting_bookings_directly')}</p>
                                <p className="description mt-5">{t('add_online_booking_to_your_website')}</p>
                                <p className="description mt-1">{t('hook_it_up_by_adding_the_following_code')}</p>
                                <BookingShowCode scriptInfo={booking.script_info} />
                                <span className="--hasline" />
                                <SaveSubdomain subdomain={booking.portal_url} domain={booking.portal_domain} />
                                <span className="--hasline" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsBooking;
