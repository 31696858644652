import { MIXPANEL_METHOD, MIXPANEL_PROPERTIES } from 'app/const/Mixpanel';

export const mixpanelPlan = ({ type = '', plan = {}, id = null }) => {
    const peopleSet = {
        [MIXPANEL_PROPERTIES.PLAN_TYPE]: plan.type,
        [MIXPANEL_PROPERTIES.TERM_LENGTH]: plan.term_length,
        [MIXPANEL_PROPERTIES.PLAN_PRICE]: plan.price
    };

    return {
        type,
        data: {
            [MIXPANEL_METHOD.IDENTIFY]: id,
            [MIXPANEL_METHOD.REGISTER]: peopleSet,
            [MIXPANEL_METHOD.SET]: peopleSet
        }
    };
};
