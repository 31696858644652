import { getLocalStorage } from "common/utils/LocalStorageUtils";

export const DOMAIN = window.location.origin;
export const KEY_USER_LOCAL = "user_profile";
export const CAMPAIGN = "campaign";
export const SMART_LIST = "smart_list";
export const TEMPLATE = "template";
export const DRIP = "drip";
export const RECIPIENT = "recipient";
export const RECIPIENTS = "recipients";
export const UNSUBSCRIBED = "unsubscribed";
const MOVE_TO_RECIPIENTS = "move_to_recipients";
const MOVE_TO_RECIPIENTS_LIST = "move_to_recipients_list";
const MOVE_TO_OPT_OUT_LIST = "move_to_opt_out_list";
export const ACTIVE = "active";
export const LIBRARY = "library";
export const RESULTS = "result";
export const START = "start";
export const PAUSED = "paused";
const START_A_NEW_CAMPAIGN = "start_a_new_campaign";
const STOP_CAMPAIGN = "stop_campaign"

export const DEFINE_COLOR_STYLE_STATUS = {
  BLUE: "blue",
  GREEN: "green",
  GREY: "grey",
  RED: "red",
  BLACK: "black",
  YELLOW: "yellow",
  ORANGE: "orange"
}

export const KEY_NAME_EDIT_CONTENT = {
  SUBJECT: "subject",
  EMAIL: "email",
  SMS: "sms",
}

export const KEYS_DRIP_LOCAL_STORAGE = {
  message_templates: "message_templates",
  unsubscribed: UNSUBSCRIBED,
  smart_lists: "smart_lists",
  smart_lists_detail: "smart_lists_detail",
  campaigns: "campaigns",
  smart_lists_columns: "smart_lists_columns",
  user_profile: "user_profile"
};

const ACTION_RECIPIENT = { id: 1, name: MOVE_TO_RECIPIENTS_LIST, value: RECIPIENT };
const ACTION_UNSUBSCRIBED = { id: 2, name: MOVE_TO_OPT_OUT_LIST, value: UNSUBSCRIBED };

export const ACTION_UPDATE_STATUS = {
  RECIPIENT: ACTION_RECIPIENT,
  UNSUBSCRIBED: ACTION_UNSUBSCRIBED
}

export const LIST_ACTION_BASIC = {
  EDIT: "edit",
  DUPLICATE: "duplicate",
  DELETE: "delete",
  MOVE_TO_RECIPIENTS: MOVE_TO_RECIPIENTS,
  UNSUBSCRIBED: UNSUBSCRIBED,
  START: START,
  PAUSED: PAUSED,
  STOP: "stop",
  START_A_NEW_CAMPAIGN: START_A_NEW_CAMPAIGN,
  STOP_CAMPAIGN: STOP_CAMPAIGN,
  REMOVE: "remove",
  NEW_CAMPAIGN: "new_campaign",
  SEND_BROADCAST: "send_broadcast"
};

export const ORDER = {
  ASC: "asc",
  DESC: "desc"
};


export const SMART_LIST_TYPE = {
  RECIPIENTS: RECIPIENTS,
  UNSUBSCRIBED: UNSUBSCRIBED,
}

export const MESSAGE_TEMPLATES_TYPE = {
  DRIP: 'drip'
}

export const LIST_TAB_SMART_LIST = [
  { id: 1, name: RECIPIENTS, value: RECIPIENT },
  { id: 2, name: UNSUBSCRIBED, value: UNSUBSCRIBED }
]

export const LIMIT_CHARACTER_SMS = 600;
export const LIMIT_CHARACTER_NAME = 255;

export const LOADING_TYPE = {
  TEMPLATE: 1,
  UNSUBSCRIBED: 2,
  SMART_LIST: 3,
  SMART_LIST_DETAIL: 4,
  CAMPAIGN_ACTIVE: 5,
  CAMPAIGN_LIBRARY: 6,
  CAMPAIGN_RESULT: 7,
  LIST_SUMMARY: 8,
  LIST_LINK: 9,
  LIST_DETAIL: 10,
  DOUBLE_CIRCLE: 11,
  CIRCLE: 12,
  PREVIEW: 13
}

export const USER_PROFILE = getLocalStorage(KEY_USER_LOCAL);

export const getLogoCompanyElementPreview = () => {
  const logoCompany = USER_PROFILE?.company_logo || "";
  return `<p><img src="${logoCompany}"/></p>`;
}

export const REPLACE_TEXT_A_ELEMENT_ADD_ATTRIBUTE_DATA_NO_BUTTON = '<a data-no-button="no_button" href';

export const REGEX_BR_ELEMENT_WITHOUT_CLOSE = /(\<br\>)/g;
export const REGEX_P_ELEMENT = /(\<p\>\<\/p\>)/g;
export const REGEX_A_ELEMENT_WITHOUT_ATTRIBUTE_DATA_NO_BUTTON = /(\<a\s(href))/g;
export const REGEX_CHECK_CONTENT_COPY_FROM_OUTSIDE_EDITOR = /(\<(?!(\/|span|meta|div|ul|ol|li|figure|a|br|img)))/g;

export const getRegexVariable = variable => {
  return new RegExp("\\" + variable, "g");
};

export const LIST_KEY_CODE_BASIC = [65, 67, 86, 88, 90]; // ctrl + a, c, v, x, z
export const LIST_KEY_CODE_INLINE_STYLE = [66, 73]; // ctrl + b, i
