/* eslint-disable no-unused-vars */
import GdButton from 'app/components/button';
import GdConfirm from 'app/components/confirm';
import GdConfirmCredit from 'app/components/confirm';

import AddPaymentSquare from 'app/components/payment/AddPaymentSquare';
import AddPaymentStripe from 'app/components/payment/AddPaymentStripe';
import { createPayment, initDataCustomer } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { LIST_PAYMENT_METHODS, PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { Fragment, useContext, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { METHOD_API } from 'app/const/App';
import classNames from 'classnames';

export default function PaymentFooter({
    onCallback,
    isInvoice = false,
    onCallbackUpdate = () => {},
    estimateValue = {},
    onTriggerClose = () => {}
}) {
    const { t } = useTranslation('customers');
    const { addPayment, updatePaymentDataContext } = useContext(AddPaymentContext);

    const currency = useSelector(({ auth }) => auth.user.settings?.currency);
    const [state, dispatchState] = useReducer(reducer, { isLoadingButton: false });
    const refConfirm = useRef(null);
    const refConfirmCredit = useRef(null);
    const refAddStripe = useRef(null);
    const refAddSquare = useRef(null);

    const {
        valuePayment,
        isDefaultCustomer,
        customerId,
        amountValue,
        paymentSelected: paymentSelectedOb,
        cardSelected,
        customerInfo,
        surchargeValue,
        isDeposit
    } = addPayment;

    const paymentSelected = paymentSelectedOb.id;
    const isDisable = !customerId || Number(amountValue) <= 0 || !paymentSelected;

    function _renderAddPaymentWithCard() {
        if (state.isLoadingButton) {
            return (
                <div className="rows btn-action">
                    <div className="v2-btn-main btn-modal is-disable">
                        <div className="loading --ajaxbar">
                            <div className="loading__barlittle --first"></div>
                            <div className="loading__barlittle --second"></div>
                            <div className="loading__barlittle --three"></div>
                            <div className="loading__barlittle --four"></div>
                        </div>
                    </div>
                </div>
            );
        }

        const isPaymentACH = paymentSelected === PAYMENT_METHODS.ACH;

        return (
            <div className="rows btn-action">
                {!isPaymentACH ? (
                    <div
                        onClick={(e) => _handleAddCard(e, true, true, true)}
                        className={classNames(`v2-btn-default --purple fw-normal btn-modal`, {
                            'is-disable': isDisable
                        })}
                        tabIndex="0"
                    >
                        {t('use_different_card')}
                    </div>
                ) : null}
                {cardSelected ? (
                    <div
                        onClick={(e) => _openConfirmChargeWithCard(e)}
                        className={`v2-btn-main btn-modal ml-2 ${isDisable ? 'is-disable' : ''}`}
                        tabIndex="0"
                    >
                        {` Charge to ${METHOD_API} ${cardSelected.account_number}`}
                    </div>
                ) : (
                    <div
                        onClick={(e) => _handleAddCard(e, false, false, false)}
                        className={classNames('v2-btn-main btn-modal ml-2', { 'is-disable': isPaymentACH })}
                        tabIndex="0"
                    >
                        {t(isPaymentACH ? 'add_payment' : 'add_card_to_account')}
                    </div>
                )}
            </div>
        );
    }

    /**
     *
     * @param {*} e
     * isOneTime this is flag for add card to customer and add card for one time
     */
    function _handleAddCard(e, isOneTime = false, hideLinked = true, hideAdditional = true) {
        e && e.stopPropagation();
        switch (paymentSelected) {
            case PAYMENT_METHODS.STRIPE:
                refAddStripe.current && refAddStripe.current._open(isOneTime, hideLinked, hideAdditional);
                break;
            case PAYMENT_METHODS.SQUARE:
                refAddSquare.current && refAddSquare.current._open(isOneTime);
                break;
            default:
                break;
        }
    }

    /**
     * _handleAddPaymentInvoiceSuccess Function update after add payment success with add payment for invoice
     * _handleAddPaymentSuccess Function update after add payment success with add payment for customer( header, customer list )
     */
    function _handleAddPayment() {
        dispatchState({ isLoadingButton: true });

        const invoices = addPayment.invoices || [];
        const selected = addPayment.invoiceSelected || [];

        const newListInvoice = [];

        selected.forEach((item) => {
            const itemSelected = invoices.find((itemFind) => itemFind.number === item);
            if (itemSelected) {
                newListInvoice.push({
                    id: itemSelected.id
                });
            }
        });

        const isPaymentCheck = paymentSelected === PAYMENT_METHODS.CHECK;

        const newData = {
            customer_id: customerId,
            date: addPayment.date,
            invoices: newListInvoice,
            estimates: [estimateValue],
            amount: amountValue,
            apply_credit: addPayment.activeCredit ? 1 : 0,
            card_merchant_id: '',
            payment_method: paymentSelected,
            save_card: 0,
            different_merchant_token: '',
            different_merchant_credit_card: '',
            merchant_payment_id: '',
            merchant_payment_method: '',
            check_value: addPayment.check_value || 0,
            paymentSelected,
            check: isPaymentCheck ? valuePayment : '',
            memo: !isPaymentCheck ? valuePayment : ''
        };

        if (isDeposit) {
            delete newData.invoices;
        } else {
            delete newData.estimates;
        }

        clientQuery(
            createPayment,
            { method: 'POST', data: { ...newData } },
            isInvoice ? _handleAddPaymentInvoiceSuccess : _handleAddPaymentSuccess,
            _handleAddPaymentFailed
        );
    }

    function _handleSubmitOneTime(cardData, squareToken) {
        dispatchState({ isLoadingButton: true });
        const isStripe = paymentSelected === PAYMENT_METHODS.STRIPE;
        const dataCardToken = cardData.token;
        const cardTokenDetail = isStripe ? dataCardToken.id : squareToken;
        const cardLast4 = isStripe ? dataCardToken.card.last4 : cardData.last4;

        const invoices = addPayment.invoices || [];
        const selected = addPayment.invoiceSelected || [];
        const newListInvoice = [];

        selected.forEach((item) => {
            const itemSelected = invoices.find((itemFind) => itemFind.number === item);
            if (itemSelected) {
                newListInvoice.push({
                    id: itemSelected.id
                });
            }
        });

        const newData = {
            customer_id: customerId,
            date: addPayment.date,
            invoices: newListInvoice,
            estimates: [estimateValue],
            amount: amountValue,
            apply_credit: 0,
            check: '',
            card_merchant_id: '',
            payment_method: paymentSelected,
            save_card: 0,
            memo: '',
            different_merchant_token: cardTokenDetail,
            different_merchant_credit_card: `**** ${cardLast4}`,
            merchant_payment_id: '',
            merchant_payment_method: '',
            check_value: addPayment.check_value || 0
        };

        if (isDeposit) {
            delete newData.invoices;
        } else {
            delete newData.estimates;
        }

        if (isStripe && dataCardToken.payment_method) {
            delete newData.different_merchant_token;
            newData.merchant_payment_method = dataCardToken.payment_method;
        }

        clientQuery(
            createPayment,
            { method: 'POST', data: newData },
            isInvoice ? _handleAddPaymentInvoiceSuccess : _handleAddPaymentSuccess,
            _handleAddPaymentFailed
        );
    }

    function _addPaymentWithCardSelected() {
        dispatchState({ isLoadingButton: true });

        const invoices = addPayment.invoices || [];
        const selected = addPayment.invoiceSelected || [];
        const newListInvoice = [];
        const cardSelected = addPayment.cardSelected;

        selected.forEach((item) => {
            const itemSelected = invoices.find((itemFind) => itemFind.number === item);
            if (itemSelected) {
                newListInvoice.push({
                    id: itemSelected.id
                });
            }
        });

        const newData = {
            customer_id: customerId,
            date: addPayment.date,
            invoices: newListInvoice,
            estimates: [estimateValue],
            amount: amountValue,
            apply_credit: 0,
            check: '',
            card_merchant_id: cardSelected.id,
            payment_method: paymentSelected,
            save_card: 0,
            memo: '',
            different_merchant_token: '',
            different_merchant_credit_card: '',
            merchant_payment_id: '',
            merchant_payment_method: '',
            check_value: addPayment.check_value || 0
        };

        if (isDeposit) {
            delete newData.invoices;
        } else {
            delete newData.estimates;
        }

        clientQuery(
            createPayment,
            { method: 'POST', data: newData },
            isInvoice ? _handleAddPaymentInvoiceSuccess : _handleAddPaymentSuccess,
            _handleAddPaymentFailed
        );
    }

    /**
     * _getDataCustomer Function call api update value balance, credit for edit invoice or invoice detail context.
     */
    function _handleAddPaymentInvoiceSuccess(response) {
        _checkStripeSCA(response);

        updatePaymentDataContext({
            amountValue: 0,
            isEditAmount: false,
            invoiceSelected: [],
            paymentApplied: 0,
            balanceDue: null,
            balanceDueValue: 0,
            newCredit: 0,
            isReloadInvoice: Date.now(),
            filterInvoice: 'sent',
            valuePayment: '',
            check_value: ''
        });
        dispatchState({ isLoadingButton: false });
        onCallback(response.message, LIST_STATUS.SUCCESS);
        _getDataCustomer();
    }

    function _handleAddPaymentSuccess(response) {
        _checkStripeSCA(response);

        updatePaymentDataContext({
            customerId: isDefaultCustomer ? customerId : '',
            cardSelected: null,
            listCard: [],
            paymentSelected: LIST_PAYMENT_METHODS[1],
            activeSurcharge: false,
            amountValue: 0,
            surchargeValue: 0,
            invoices: [],
            invoiceSelected: [],
            paymentApplied: 0,
            balanceDue: null,
            balanceDueValue: 0,
            newCredit: 0,
            isEditAmount: false,
            filterInvoice: '-1',
            valuePayment: '',
            isReloadInvoice: isDefaultCustomer ? Date.now() : addPayment.isReloadInvoice,
            triggerFocus: Date.now(),
            check_value: ''
        });
        dispatchState({ isLoadingButton: false });
        onCallback(response.message, LIST_STATUS.SUCCESS);
        _getDataCustomer();
    }

    function _checkStripeSCA(response) {
        const dataIntent = response?.data?.intent_confirm;
        if (dataIntent) {
            onTriggerClose(false);
            refAddStripe.current.confirmSCA(dataIntent, onCallback);
        }
    }

    function _handleAddPaymentFailed(response) {
        dispatchState({ isLoadingButton: false });
        onCallback(response?.message || 'Please try again', LIST_STATUS.ERROR);
    }

    function _getDataCustomer() {
        clientQuery(initDataCustomer(addPayment.customerId), { method: 'GET' }, _getCustomerDataSuccess);
    }

    function _getCustomerDataSuccess(response) {
        const { balance: tempBalance, credit: tempCredit } = response.data;

        isDeposit ? onCallbackUpdate(response) : onCallbackUpdate(tempBalance, tempCredit);

        updatePaymentDataContext({
            balanceDue: tempBalance,
            customerCredit: tempCredit,
            balanceDueValue: tempBalance.value
        });
    }

    const convertCard = (data) => {
        const expYear = data.exp_year.toString() || '';
        return {
            account_number: data.account_number,
            account_type: data.account_type,
            exp_month: data.exp_month,
            exp_year: expYear,
            id: data.id,
            is_default: data.is_default === 0 ? 0 : 1,
            keyword: 3,
            short_year: expYear?.substring(2, 2)
        };
    };

    function onSubmit(data) {
        let dataUpdate = null;

        if (Array.isArray(data)) {
            const newCards = data.map((item) => convertCard(item));
            const listCard = addPayment.listCard.concat(newCards) || [];
            dataUpdate = { listCard, cardSelected: listCard.find((item) => !!item.is_default) };
        } else {
            const newCard = convertCard(data);
            dataUpdate = { listCard: addPayment.listCard.concat(newCard), cardSelected: newCard };
        }

        updatePaymentDataContext(dataUpdate);
    }

    function _openConfirmChargeWithCard() {
        refConfirm.current.open(
            '',
            `Charge ${transformToCurrency(
                parseFloat(amountValue || 0) + parseFloat(surchargeValue),
                currency
            )} to card number ${METHOD_API} ${addPayment.cardSelected.account_number}`
        );
    }

    function _renderAddPayment() {
        return (
            <div className="rows btn-action">
                <GdButton
                    onClick={() => _handleCheckNewCredit(null, false)}
                    className={classNames(`v2-btn-main btn-modal`, { 'is-disable': isDisable })}
                    isLoading={state.isLoadingButton}
                    tabIndex="0"
                >
                    {t('add_payment')}
                </GdButton>
            </div>
        );
    }

    function _renderButton() {
        switch (paymentSelected) {
            case PAYMENT_METHODS.CASH:
            case PAYMENT_METHODS.CREDIT:
            case PAYMENT_METHODS.CHECK:
                return _renderAddPayment();
            case PAYMENT_METHODS.STRIPE:
            case PAYMENT_METHODS.SQUARE:
            case PAYMENT_METHODS.ACH:
                return _renderAddPaymentWithCard();
            default:
                return _renderAddPayment();
        }
    }

    const _handleCheckNewCredit = (dataCard, isOneTime) => {
        if (!!parseFloat(addPayment.newCredit)) {
            refConfirmCredit.current.open(
                { isOneTime: isOneTime, data: dataCard },
                `The payment you have entered will result in a credit of ${transformToCurrency(
                    parseFloat(addPayment.newCredit || 0),
                    currency
                )}`
            );
            return false;
        }

        if (isOneTime) {
            _handleSubmitOneTime(dataCard.cardData, dataCard.squareToken);
        } else {
            _handleSubmitPayment({ data: null, isOneTime: isOneTime });
        }
    };

    const _handleSubmitPayment = (finalData) => {
        if (finalData.isOneTime) {
            const dataCard = finalData.data || {};
            _handleSubmitOneTime(dataCard.cardData, dataCard.squareToken);
            return false;
        }

        switch (paymentSelected) {
            case PAYMENT_METHODS.CASH:
            case PAYMENT_METHODS.CREDIT:
            case PAYMENT_METHODS.CHECK:
                _handleAddPayment();
                break;
            case PAYMENT_METHODS.STRIPE:
            case PAYMENT_METHODS.SQUARE:
            case PAYMENT_METHODS.ACH:
                _addPaymentWithCardSelected();
                break;
            default:
                _handleAddPayment();
                break;
        }
    };

    return (
        <Fragment>
            {_renderButton()}
            <AddPaymentSquare
                ref={refAddSquare}
                customerId={addPayment.customerId}
                onSubmit={onSubmit}
                onSubmitOneTime={(cardData, squareToken) => _handleCheckNewCredit({ cardData, squareToken }, true)}
            />
            <AddPaymentStripe
                ref={refAddStripe}
                customerId={addPayment.customerId}
                customerInfo={customerInfo || {}}
                stripeId={customerInfo?.account_customer_id || ''}
                onSubmit={onSubmit}
                onSubmitOneTime={(cardData, squareToken) => _handleCheckNewCredit({ cardData, squareToken }, true)}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('jobDetail:payment_confirmation')}
                message={''}
                listButton={{ cancel: true, confirm: true }}
                onConfirm={() => _handleCheckNewCredit(null, false)}
            />
            <GdConfirmCredit
                ref={refConfirmCredit}
                title={'New customer credit'}
                message={''}
                listButton={{ cancel: true, confirm: true }}
                onConfirm={_handleSubmitPayment}
            />
        </Fragment>
    );
}
