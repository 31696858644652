import { KEY_CODE_DOT, KEY_CODE_X } from 'app/const/Keyboard';
import { TYPE_OUT_GOING } from 'app/const/Voip';
import { useEffect } from 'react';

const ServiceShortcut = ({ type, onPauseCall = () => {}, onHangUp = () => {}, onNextCall = () => {} }) => {
    const { ACCEPTING, CALLING, REJECT, BUSY, NEXT_LEAD, PAUSED_EDIT, EMPTY, PREPARE, END } = TYPE_OUT_GOING;

    useEffect(() => {
        window.addEventListener('keydown', _handleShortcuts);
        return () => {
            window.removeEventListener('keydown', _handleShortcuts);
        };
    }, [type]);

    const _handleShortcuts = (event = {}) => {
        if (event.ctrlKey && event.keyCode === KEY_CODE_DOT) {
            switch (type) {
                case BUSY:
                case REJECT:
                case PAUSED_EDIT:
                case END:
                    onPauseCall();
                    break;
                case ACCEPTING:
                case CALLING:
                    onHangUp();
                    break;
                default:
                    break;
            }
        }
        if (event.ctrlKey && event.shiftKey && event.keyCode === KEY_CODE_X) {
            switch (type) {
                case EMPTY:
                case PREPARE:
                case NEXT_LEAD:
                    break;
                default:
                    onNextCall();
            }
        }
    };
};

export default ServiceShortcut;
