import { call, put } from 'redux-saga/effects';

import { fetchWithToken, putWithToken, postWithToken } from 'common/utils/ApiUtils';
import {
    SETTINGS_GET_LIST_SERVICE,
    SETTINGS_GET_DETAIL_SERVICE,
    SETTINGS_ADD_AND_UPDATE_SERVICE,
    SETTINGS_UPDATE_STATUS_SERVICE,
    SETTINGS_UPDATE_SERVICE_COLOR
} from 'app/const/Api';

import { TYPE_SETTING_SERVICE } from 'common/redux/actions/settings/serviceAction.js';

export function* getListService({ params, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_SERVICE, params);
        if (response && response.success) {
            actionSuccess(response);

            if (params?.localSaveStore && response.data) {
                yield put({
                    type: TYPE_SETTING_SERVICE.GET_LIST_SUCCESS,
                    data: response,
                    resetData: params.localResetData
                });
            }
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateColorCode({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_UPDATE_SERVICE_COLOR, params);
        if (response && response.success) {
            actionSuccess(response, params);
            return;
        }
        actionFailed(response);
    } catch (err) {
        actionFailed(err);
    }
}

export function* addAndUpdateService({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_ADD_AND_UPDATE_SERVICE, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusService({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_UPDATE_STATUS_SERVICE[params.type], params.ids);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDetailService({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_DETAIL_SERVICE, params);
        if (response && response.success) {
            const newData = response.data;
            newData.forEach((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
