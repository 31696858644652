import {
    DEFAULT_ALL,
    LIST_TABLE_ITEM_TYPE,
    COMMON,
    START_DATE_OF_MONTH,
    END_DATE_OF_MONTH,
    DATE_FORMAT,
    PAGE_SIZE_DEFAULT
} from './App';
import moment from 'moment';

export const ARRAY_STAR = [5, 4, 3, 2, 1];
export const REVIEW_PAGE_PARAMS_SERVICE = 'reviews_page_params_service';
export const REVIEW_PAGE_PARAMS_LIKELY = 'reviews_page_params_likely';
export const REVIEW_PAGE_CURRENT_TAB = 'reviews_page_current_tab';
export const REVIEWS_LIST_TAB_BUTTON = [
    { id: 'service', title: 'service_ratings' },
    { id: 'likely', title: 'likely_ratings' }
];

export const REVIEW_COLUMN_LIKELY = [
    { id: 'customer', status: true },
    { id: 'date', status: true },
    { id: 'rating', status: true },
    { id: 'feedback', status: true }
];

export const REVIEW_COLUMN_SERVICE = [
    { id: 'customer', status: true },
    { id: 'date', status: true },
    { id: 'teammate', status: true },
    { id: 'rating', status: true },
    { id: 'feedback', status: true }
];

export const REVIEW_HEADER_SERVICE = {
    customer: {
        title: 'customer',
        style: 'col col-md-plus',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    date: {
        title: 'date',
        style: 'col col-date',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    teammate: {
        title: 'teammate',
        style: 'col col-md-plus',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    rating: {
        title: 'rating',
        style: 'col --lg --rating',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    feedback: {
        title: 'feedback',
        style: 'col col-xl --feedback',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    }
};

export const REVIEW_HEADER_SERVICE_LIKELY = {
    customer: {
        title: 'customer',
        style: 'col col-md-plus',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    date: {
        title: 'date',
        style: 'col col-date',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    rating: {
        title: 'rating',
        style: 'col col-sm',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    },
    feedback: {
        title: 'feedback',
        style: 'col col-xl --feedback',
        actionType: COMMON.ORDER,
        currentValue: COMMON.NOT_SET
    }
};

export const REVIEW_CONTENT_SERVICE = [
    {
        id: 'customer',
        status: true
    },
    {
        id: 'date',
        status: true
    },
    {
        id: 'teammate',
        status: true
    },
    {
        id: 'rating',
        status: true
    },
    {
        id: 'feedback',
        status: true
    }
];

export const REVIEW_CONTENT_SERVICE_CONFIG = {
    customer: {
        style: 'col col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
    },
    date: {
        style: 'col col-date'
    },
    teammate: {
        style: 'col col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
        isColumnTeammate: true
    },
    rating: {
        style: 'col --lg --rating d-flex align-center',
        type: LIST_TABLE_ITEM_TYPE.RATING_COUNTING
    },
    feedback: {
        style: 'col col-xl --feedback',
        type: LIST_TABLE_ITEM_TYPE.DESCRIPTION
    }
};

export const REVIEW_CONTENT_LIKELY_CONFIG = {
    customer: {
        style: 'col col-md-plus',
        type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
    },
    date: {
        style: 'col col-date'
    },
    rating: {
        style: 'col col-sm',
        type: LIST_TABLE_ITEM_TYPE.RATING_COUNTING_TEXT
    },
    feedback: {
        style: 'col col-xl --feedback',
        type: LIST_TABLE_ITEM_TYPE.DESCRIPTION
    }
};

export const getGridColumnsService = (actionsStatus) => {
    const newHeader = { ...REVIEW_HEADER_SERVICE };

    if (actionsStatus) {
        const [orderName, value] = actionsStatus.split(' ');

        for (const keyHeader in newHeader) {
            newHeader[keyHeader].currentValue = COMMON.NOT_SET;
        }

        newHeader[orderName].currentValue = value;
    }

    return {
        header: newHeader,
        columns: REVIEW_CONTENT_SERVICE,
        contentConfig: REVIEW_CONTENT_SERVICE_CONFIG
    };
};

export const getGridColumnsLikely = (actionsStatus) => {
    const newColumns = [...REVIEW_CONTENT_SERVICE];
    delete newColumns[2];
    const newHeader = { ...REVIEW_HEADER_SERVICE_LIKELY };
    const newContentConfig = { ...REVIEW_CONTENT_LIKELY_CONFIG };

    if (actionsStatus) {
        const [orderName, value] = actionsStatus.split(' ');

        for (const keyHeader in newHeader) {
            newHeader[keyHeader].currentValue = COMMON.NOT_SET;
        }

        newHeader[orderName].currentValue = value;
    }

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContentConfig
    };
};

export const REVIEW_DEFAULT_PARAMS = {
    start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
    end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
    offset: 0,
    items: DEFAULT_ALL,
    items_likely: DEFAULT_ALL,
    users: DEFAULT_ALL,
    limit: PAGE_SIZE_DEFAULT,
    order: COMMON.DATE.concat(' ', COMMON.DESC),
    total: 1
};

export const REVIEW_LIKELY_DEFAULT_PARAMS = {
    start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
    end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT),
    offset: 0,
    items: DEFAULT_ALL,
    items_likely: DEFAULT_ALL,
    limit: PAGE_SIZE_DEFAULT,
    order: COMMON.DATE.concat(' ', COMMON.DESC),
    total: 1,
    users: DEFAULT_ALL
};

export const EXPORT_REVIEWS = [
    {
        id: 'export_csv',
        name: 'export_csv',
        isI18: true,
        isShow: true
    },
    {
        id: 'export_excel',
        name: 'export_excel',
        isI18: true,
        isShow: true
    }
];
