import React from 'react';

const LoadingChartAI = () => {
    return (
        <div className="chart pt-15 wrap-loading">
            <div className="chart-area loading --animation">
                <svg viewBox="0 0 360 100">
                    <path d="M0 21.93L33.4351 31.39L65.4962 38.055L98.4733 34.185L130.992 43L196.364 26.23L229.466 4.08497L260.611 -5.97429e-05L294.504 17.415L327.939 27.52L360 21.5V-3.34978e-05H0V21.93Z" />
                </svg>
            </div>
        </div>
    );
};

export default LoadingChartAI;
