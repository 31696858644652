import classNames from 'classnames';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { TYPE_TAGS } from 'app/const/Customers';
import { KEY_CODE_ENTER, KEY_CODE_SPACE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { updateSimpleTag } from 'common/redux/actions/settings/tagsAction';
import DropdownPopper from '../dropdown/DropdownPopper';
import ItemAddTag from './components/ItemAddTag';
import ListTags from './components/ListTags';
import TagDropButton from './components/TagDropButton';

let timer = null;

const GdTags = (
    {
        type = TYPE_TAGS.TAG_DEFAULT,
        deleteWithId = false,
        defaultTags = null,
        isTagsSideMenu = false,
        isTagsAccount = false,
        classWrapper,
        autoFocus = false,
        isHidden = false,
        isCheckHidden = false,
        isCalculateWidth = true,
        defaultData,
        isNotUseAddTag = false,
        placeholder,
        isRemoveValueOnSelected = false,
        placement,
        onOpen = () => {},
        onChangeOption = () => {}
    },
    ref
) => {
    const dispatch = useDispatch();

    /* A cleanup function. */
    useEffect(() => {
        return () => timer && clearTimeout(timer);
    }, []);

    const [state, dispatchState] = useReducer(reducer, {
        tags: [],
        selectedTags: defaultTags || []
    });

    const { selectedTags, isLoading } = state;
    const refDropdown = useRef(null);
    const refTagDropButton = useRef(null);
    const refChildren = useRef(null);
    const refOpened = useRef(false);

    useImperativeHandle(ref, () => ({
        getValue: _handleGetValue,
        updateGlobal: () => {
            if (!refOpened.current) return;
            dispatch(updateSimpleTag({ type, data: refChildren.current?.onHandleGetList() || [] }));
        },
        onEdit: () => autoFocus && refDropdown.current?._open()
    }));

    useEffect(() => {
        if (autoFocus && refDropdown.current) refDropdown.current._open();
    }, [autoFocus]);

    useEffect(() => {
        refChildren.current && refChildren.current.update();
    }, [selectedTags]);

    const _handleGetValue = () => {
        return selectedTags;
    };

    const _handleVisible = () => {
        refOpened.current = true;
        refChildren.current?.onHandleFetch();
        dispatchState({ tags: refChildren.current?.onHandleGetList() });
        onOpen(true);
    };

    /**
     * It clears the selected tags.
     */
    const _handleClearTag = () => {
        dispatchState({ selectedTags: [] });
        refChildren.current && refChildren.current.hide();
    };

    /**
     * If the key pressed is the space bar or the enter key, then add the tag
     * @param e - The event object
     */
    const _handleKeyPress = (e) => {
        const value = e.target.value;
        if (isNotUseAddTag) return;
        if (e.which === KEY_CODE_SPACE || e.which === KEY_CODE_ENTER) {
            value.trim().length && _handleAddTag(value);
            e.preventDefault();
        }
    };

    /**
     * If the input value is not empty, display the add tag button and set the tag value to the input value
     * @param e - The event object
     * @returns the value of the input field.
     */
    const _handleInputChange = (e) => {
        if (!refChildren.current) return;
        const value = e.target.value;

        if (value.trim().length) {
            refChildren.current.display();
            refChildren.current.setTag(value);
        } else {
            refChildren.current.hide();
        }

        refChildren.current.onHandleSearch(value);
    };

    /**
     * Function adds a new tag to the list of tags and selected tags
     * @param newTag - The new tag that the user has entered.
     */
    const _handleAddTag = (newTag) => {
        const tag = { id: newTag, name: newTag, type, isNewTag: true };

        if (!selectedTags.some((item) => item.id === tag.id)) {
            dispatchState({ selectedTags: [...selectedTags, tag] });
            refChildren.current.onHandleAddTag(tag);

            refTagDropButton.current.focus();
            refTagDropButton.current.setValue('');
            refChildren.current && refChildren.current.hide();
        } else {
            _handleAlertLabelAvailable(tag.id);
        }
    };

    /**
     * If the tag is not already selected, add it to the selectedTags array. If it is already selected,
     * show an alert
     * @param tag - The tag object that was selected.
     */
    const _handleSelect = (tag) => {
        if (!selectedTags.some((item) => item.id === tag.id)) {
            const finalSelectedTags = [...selectedTags, tag];
            dispatchState({ selectedTags: finalSelectedTags });
            onChangeOption(finalSelectedTags);
            refChildren.current.onHandleSelect(tag);
            refChildren.current.hide();
            isRemoveValueOnSelected && refTagDropButton.current.setValue('');
        } else {
            _handleAlertLabelAvailable(tag?.id);
        }
        refTagDropButton.current.focus();
    };

    const _handleRemoveTag = (id) => {
        const finalSelectedTags = [...selectedTags].filter((item) => item.id !== id);
        dispatchState({
            selectedTags: finalSelectedTags
        });
        onChangeOption(finalSelectedTags);
        refTagDropButton.current.focus();
    };

    const _handleCloseDropdown = () => {
        refDropdown.current._close();
    };

    /**
     * It adds a class to a div for 300 milliseconds
     * @param id - the id of the tag
     */
    const _handleAlertLabelAvailable = (id) => {
        timer && clearTimeout(timer);
        const labelDiv = document.querySelector(`[data-tag-label="tag-label_${type}_${id}"]`);

        labelDiv.classList.add('is-available');
        timer = setTimeout(() => {
            labelDiv.classList.remove('is-available');
        }, 300);
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isAlwayShowOptions
            isCalculateWidth={isCalculateWidth}
            isCheckHidden={isCheckHidden}
            isLoading={isLoading}
            onOpen={_handleVisible}
            onClose={() => onOpen(false)}
            wrapperClassName={classNames('list-add-tags v2-dropdown', { 'dp-hide': isHidden })}
            wrapperListClass="v2-dropdown__menu content-checked scrolls"
            buttonClassName="dropbtn items group-tags has-search p-1"
            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
            placement={placement}
            customButton={
                <TagDropButton
                    ref={refTagDropButton}
                    data={selectedTags}
                    deleteWithId={deleteWithId}
                    onCloseDropdown={_handleCloseDropdown}
                    onRemoveTag={_handleRemoveTag}
                    onChange={_handleInputChange}
                    onKeyPress={_handleKeyPress}
                    onClearTag={_handleClearTag}
                    type={type}
                    isTagsSideMenu={isTagsSideMenu}
                    isTagsAccount={isTagsAccount}
                    classWrapper={classWrapper}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                />
            }
        >
            <ChildrenDropdown
                ref={refChildren}
                type={type}
                onSelect={_handleSelect}
                onAddTag={_handleAddTag}
                isTagsSideMenu={isTagsSideMenu}
                isTagsAccount={isTagsAccount}
                defaultData={defaultData}
                isNotUseAddTag={isNotUseAddTag}
            />
        </DropdownPopper>
    );
};

const ChildrenDropdown = forwardRef(
    (
        {
            type,
            isTagsSideMenu,
            isTagsAccount,
            defaultData,
            isNotUseAddTag = false,
            update = () => {},
            onAddTag = () => {},
            onSelect = () => {}
        },
        ref
    ) => {
        const refAddTag = useRef(null);
        const refListTags = useRef(null);

        useImperativeHandle(ref, () => ({
            update,
            hide: () => refAddTag.current?.hide(),
            display: () => refAddTag.current?.display(),
            setTag: (value) => {
                refAddTag.current?.setTag(value);
            },
            onHandleGetList: () => refListTags.current?.onHandleGetList() || [],
            onHandleSelect: (tag) => refListTags.current?.onHandleSelect(tag),
            onHandleFetch: () => refListTags.current?.onHandleFetch(),
            onHandleSearch: (valueSearch) => refListTags.current?.onHandleSearch(valueSearch),
            onHandleAddTag: (newTag) => refListTags.current?.onHandleAddTag(newTag)
        }));

        return (
            <Fragment>
                {!isNotUseAddTag ? <ItemAddTag ref={refAddTag} onAddTag={onAddTag} /> : null}
                <ul className="scrolls" style={{ maxHeight: 200 }}>
                    <ListTags
                        ref={refListTags}
                        onHandleSelect={onSelect}
                        type={type}
                        update={update}
                        isTagsSideMenu={isTagsSideMenu}
                        isTagsAccount={isTagsAccount}
                        defaultData={defaultData}
                    />
                </ul>
            </Fragment>
        );
    }
);

GdTags.displayName = 'GdTags';
export default forwardRef(GdTags);
