import { LIST_API_UPDATE_ADDONS_OPTIONS } from 'app/const/addons';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const TriggerAddonsContent = ({ keyword, upgrade, name, status, level }) => {
    const { t } = useTranslation(['addons']);

    return (
        <div className="boxs boxs--no-border --hasline">
            <AddonsBoxHeader
                keyword={keyword}
                name={name}
                upgrade={upgrade}
                status={status}
                level={level}
                urlUpdateStatus={LIST_API_UPDATE_ADDONS_OPTIONS[keyword]}
            />
            <div className="boxs__contents mt-3">
                <p className="description">{t('addons:description_trigger')}</p>
            </div>
        </div>
    );
};

export default TriggerAddonsContent;
