/* eslint-disable no-unused-vars */
import { API_VERSION } from 'app/const/URL';

export const GET_VOIP_ACCESS_TOKEN = `${API_VERSION}voip/settings`;

export const ACTION_CALL_MOVE = `${API_VERSION}inbox/call/move`;

export const ACTION_CALL_SNOOZE = `${API_VERSION}inbox/call/snooze`;

export const actionAddNoteCall = (callId) => `${API_VERSION}call/${callId}/note`;

export const GET_CUSTOMER_BY_PHONE = `${API_VERSION}addons/voip/customer`;

export const ACTION_ASSIGN_LEAD = `${API_VERSION}customers/assign`;

export const ACTION_USER_SETTING_CALL = `${API_VERSION}voip/settings`;

export const ACTION_PHONE_NUMBER = `${API_VERSION}addons/voip/numbers`;

export const GET_LIST_SEARCH_PHONE = `${API_VERSION}addons/voip/search`;

export const updateSettingsNumberVoip = (id) => `${API_VERSION}addons/voip/numbers/${id}`;

export const changeVoipNumber = (id) => `${API_VERSION}addons/voip/numbers/${id}/changeNumber`;

export const setPrimaryNumberVoip = (id) => `${API_VERSION}addons/voip/numbers/${id}/primary`;

export const GET_USER_ASSSIGNED_NUMBER = `${API_VERSION}voip/numbers`;

export const GET_VOIP_MEMBERS = `${API_VERSION}voip/members`;

export const ACTION_TRANSFER_CALL = `${API_VERSION}voip/transfer`;

export const ACTION_INVITE_CALL = `${API_VERSION}voip/invite`;

export const VERIFY_NUMBER_VOIP = `${API_VERSION}addons/voip/numbers/verify`;

export const MIGRATE_SMS_NUMBER_VOIP = `${API_VERSION}addons/voip/numbers/migrate`;

export const ACCOUNT_DETAIL_VOIP = `${API_VERSION}addons/voip/account`;

export const ACTIVE_ACCOUNT_VOIP = `${API_VERSION}addons/voip/plan`;

export const LIST_USER_VOIP = `${API_VERSION}addons/voip/users`;

export const actionActiveUserVoip = (userId) => `${API_VERSION}addons/voip/users/${userId}`;

export const assignVoipNumber = (userId) => `${API_VERSION}addons/voip/users/${userId}/numbers`;

export const swapVoipNumber = (userId) => `${API_VERSION}addons/voip/users/${userId}/numbers/swap`;

export const settingVoipNumber = (userId) => `${API_VERSION}addons/voip/users/${userId}/settings`;

export const AUTO_ATTENDANT_VOIP = `${API_VERSION}addons/voip/autoAttendant`;

export const ACTION_ADD_CALL_LOG = `${API_VERSION}call`;

export const updateCallLog = (logId) => `${API_VERSION}call/${logId}`;

export const AFTER_HOURS_SETTING = `${API_VERSION}addons/voip/afterHour`;

export const ACTION_JOIN_A_CALL = `${API_VERSION}voip/join`;

export const GET_DETAIL_VOIP_MUSIC = `${API_VERSION}addons/voip/music`;

export const VOIP_HOLD_CALL = `${API_VERSION}voip/hold`;

export const ACTION_REASSIGN_CALL = `${API_VERSION}inbox/call/reassign`;

export const voipTranscription = (callId) => `${API_VERSION}call/${callId}/transcription`;

export const GET_LIST_VOICEMAIL_DROP = `${API_VERSION}addons/voip/voicemaildrop`;

export const actionsVoipVoicemailDrop = (id) => `${GET_LIST_VOICEMAIL_DROP}/${id}`;

export const VOIP_VOICEMAIL_DROP_CALL = `${API_VERSION}voip/voicemail`;

export const actionsRecordingCallNote = (id) => `${API_VERSION}call/${id}/recording`;
