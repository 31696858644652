import React, { Fragment, useEffect, useId, useReducer, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { useSelector, useDispatch } from 'react-redux';
import { getListItems } from 'common/redux/actions/itemsAction';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { useTranslation } from 'react-i18next';

const ServiceItem = ({ itemId, itemName, onSelect, handleOpenForm, finalItemId }) => {
    const { t } = useTranslation(['header', 'common']);
    const { list_items, first_time } = useSelector((store) => store.itemsReducer);

    const [state, setState] = useReducer(reducer, {
        isLoading: true,
        items: list_items || [],
        isVisible: false
    });

    const dispatch = useDispatch();
    const refDropdown = useRef(null);
    const listRef = useRef(null);
    const idDropdown = useId();

    const { isVisible: finalIsVisible, items: finalListItems, isLoading } = state;

    useEffect(() => {
        if (!first_time) {
            setState({
                isLoading: false,
                items: list_items
            });
        }
    }, [list_items]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    useEffect(() => {
        if (finalIsVisible && first_time) {
            _handleGetListItems();
        }
    }, [finalIsVisible]);

    function _handleGetListItems() {
        dispatch(getListItems({ fields: 'description,cost,tax1,tax2' }));
    }

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(idDropdown);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(idDropdown);

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e && e.stopPropagation();
        setState({ isVisible: true });
    };

    function _handleChangeSearch(text) {
        setState({
            isVisible: true,
            keyword: text,
            items: [...list_items].filter((item) => item.name.toLowerCase().search(text.toLowerCase()) !== -1)
        });
        listRef.current && listRef.current.scrollTo(0, 0);
    }

    function _handleSelectItem(e, item) {
        e.stopPropagation();

        onSelect(item);

        _closeSearchResult();
    }

    const _handleOpenForm = (e) => {
        e.stopPropagation();
        handleOpenForm();
    };

    const _handlFocus = (e) => {
        _handleOpen(e);
    };

    function _renderListItems() {
        if (!isLoading && !finalListItems.length) {
            return (
                <li className="items pointer-events-none">
                    {state?.keyword
                        ? t('header:search_not_match')
                        : t('common:no_data_to_display', { title: t('common:items') })}
                </li>
            );
        }

        if (isLoading) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }

        return finalListItems.map((item) => {
            const active = finalItemId === item.id;

            return (
                <li
                    className={`items ${active ? 'active' : ''}`}
                    key={item.id}
                    onClick={(e) => _handleSelectItem(e, item)}
                    tabIndex="0"
                >
                    {item.name}
                </li>
            );
        });
    }

    return (
        <div
            ref={refDropdown}
            id={`service_item_detail_${itemId}`}
            className={`col col-item v2-dropdown select-service-name ${finalIsVisible ? 'active' : ''}`}
        >
            <SearchOption
                placeholder={''}
                onSearch={_handleChangeSearch}
                onFocus={_handlFocus}
                defaultValue={itemName || ''}
                parentStyle="dropbtn fw-600 items"
                isShowClose={false}
            />

            <div className="v2-dropdown__menu content-search content-full">
                <div className="container-column">
                    <ul ref={listRef} className="box-auto scrolls" id={idDropdown}>
                        {finalIsVisible && _renderListItems()}
                        {!isLoading && (
                            <Fragment>
                                <div className="line" />
                                <li onClick={_handleOpenForm} tabIndex="0" className="items">
                                    New
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ServiceItem;
