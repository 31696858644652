import { CUSTOMER_LIST_TASKS } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { TASK_DEFAUL_VALUE, TYPE_TASK } from 'app/modules/customer/const/Task';
import AssignTo from 'app/modules/customer/task/components/AssignTo';
import Date from 'app/modules/customer/task/components/Date';
import TaskName from 'app/modules/customer/task/components/TaskName';
import Time from 'app/modules/customer/task/components/Time';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import { combineDateTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import IconDone from 'assets/icon/IconDone';

const AddTask = forwardRef(
    (
        {
            onAddNew,
            customerDetail,
            socketIdKey = null,
            cleanQuickAdd,
            type = TYPE_TASK.CUSTOMER,
            jobId,
            jobDetail = null
        },
        ref
    ) => {
        const { id } = useParams();

        const refTaskName = useRef(null);
        const refStatusBar = useRef(null);
        const refTaskDate = useRef(null);
        const refTaskTime = useRef(null);
        const refAssignTo = useRef(null);
        const refFormAddTask = useRef(null);

        useImperativeHandle(ref, () => ({
            toggleShowHide: _handleToggleShowHide,
            checkIsOpen: _handleCheckFormIsOpen
        }));

        function _handleToggleShowHide() {
            if (refFormAddTask.current) {
                refFormAddTask.current.classList.toggle('is-show');
                if (refFormAddTask.current.classList.contains('is-show')) {
                    refFormAddTask.current.scrollIntoView();
                    refTaskName.current && refTaskName.current.focus();
                }
            }
        }

        function _handleCheckFormIsOpen() {
            if (refFormAddTask.current.classList.contains('is-show')) {
                const currentContent = refTaskName.current.getValue();
                if (currentContent.length !== 0) {
                    _handleSaveNewTask();
                }
                _handleClose();
            }
        }

        function _handleClose() {
            if (refFormAddTask.current) {
                refFormAddTask.current.classList.remove('is-show');
            }
        }

        function _handleSaveNewTask() {
            const idTemp = moment().format('x');

            const taskName = refTaskName.current.getValue();

            if (taskName.length === 0) {
                refStatusBar.current.showStatusBar('show_error', 'Task description is required.', LIST_STATUS.ERROR);
                refTaskName.current.checkVerifyError();
                return false;
            }

            const taskDate = refTaskDate.current.getValue();
            const taskTime = refTaskTime.current.getValue();
            const assignSelected = refAssignTo.current.getValue();
            const isCustomerTimeLine = type === TYPE_TASK.CUSTOMER;
            const finalDate = combineDateTimeToISO(taskDate, taskTime);

            onAddNew({
                ...TASK_DEFAUL_VALUE,
                assignee: {
                    ...assignSelected,
                    full_name: assignSelected.first_name.concat(' ', assignSelected.last_name)
                },
                customer: customerDetail,
                date: finalDate,
                start: finalDate,
                end: '',
                title: taskName,
                id: idTemp,
                quick_add: true,
                job: jobDetail,
                type: 'task'
            });

            refTaskName.current.resetValue();

            const optionsQuery = {
                method: 'POST',
                data: {
                    title: taskName,
                    date: finalDate,
                    assignee: assignSelected.id,
                    type: isCustomerTimeLine ? 1 : 2,
                    type_id: isCustomerTimeLine ? id : jobId
                }
            };
            // eslint-disable-next-line no-undef
            if (socketIdKey) optionsQuery['data'] = { ...optionsQuery['data'], socket_id: global[socketIdKey] };
            clientQuery(CUSTOMER_LIST_TASKS, optionsQuery, (reponse) => _addTaskSuccess(reponse, idTemp));
            _handleClose();
        }

        function _addTaskSuccess(response, idTemp) {
            refTaskName.current && refTaskName.current.resetValue();
            refTaskDate.current && refTaskDate.current.resetValue();
            refTaskTime.current && refTaskTime.current.resetValue();
            refAssignTo.current && refAssignTo.current.resetValue();

            cleanQuickAdd(response.data.id, idTemp);
        }

        return (
            <div ref={refFormAddTask} className="boxs boxs-view-task --editer-task --new-task">
                <div className="left-icons">
                    <IconDone isHasColor />
                </div>
                <div className="content has-form">
                    <div className="content__task">
                        <h5 className="fs-18">Add task</h5>
                        <StatusBar ref={refStatusBar} />
                        <AssignTo ref={refAssignTo} />
                        <div className="rows row-haft">
                            <Date ref={refTaskDate} />
                            <Time ref={refTaskTime} />
                        </div>
                        <TaskName ref={refTaskName} />
                    </div>
                    <div className="footer --align-right">
                        <div className="v2-btn-default --transparent js-toggletask" onClick={_handleClose}>
                            Cancel
                        </div>
                        <div className={'v2-btn-main'} onClick={_handleSaveNewTask}>
                            Save
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default AddTask;
