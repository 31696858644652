import React, { createContext, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';

export const ManageUnitContext = createContext();

const ManageUnitContextProvider = ({ manageUnitData, children }) => {
    const [state, dispatchState] = useReducer(reducer, {
        ...manageUnitData
    });

    function _handleUpdateManageUnitContext(newData) {
        dispatchState(newData);
    }

    const ManageUnitContextData = {
        manageUnitContextData: state,
        updateManageUnitContextData: _handleUpdateManageUnitContext
    };

    return <ManageUnitContext.Provider value={ManageUnitContextData}>{children}</ManageUnitContext.Provider>;
};

export default ManageUnitContextProvider;
