import React from 'react';

export const TimeWindowAddonsLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --twothird" />
                            </div>
                        </div>
                        <div className="v2-btn-default --grey loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const TimeWindowAddonsFormLoading = () => {
    return (
        <div className="wrap-loading --addon">
            <div className="boxs form-timewindow d-flex">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default --grey loading btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="wrap-select-time template-rows">
                        <div className="v2-btn-default --grey loading --onefifth mr-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="loading --animation --line --onetenth mx-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --grey --icon-sm loading ml-2">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="wrap-select-time template-rows">
                        <div className="v2-btn-default --grey loading --onefifth mr-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="loading --animation --line --onetenth mx-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --grey --icon-sm loading ml-2">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="wrap-select-time template-rows">
                        <div className="v2-btn-default --grey loading --onefifth mr-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="loading --animation --line --onetenth mx-2" />
                        <div className="v2-btn-default --grey loading --twothird">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --grey --icon-sm loading ml-2">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
                <div className="boxs__footer">
                    <div className="v2-btn-default --grey loading btn-md">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const TimeWindowTableLoading = () => {
    return (
        <div className="boxs boxs--power-up box-timewindow">
            {' '}
            <div className="wrap-loading --addon mt-2">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="dots powerups loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="v2-btn-default --grey loading --onetenth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="loading-lines">
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefourth mt-0" />
                        </div>
                        <div className="flex-betweenitems mt-3">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefourth mt-0" />
                        </div>
                        <div className="flex-betweenitems mt-3">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefourth mt-0" />
                        </div>
                        <div className="flex-betweenitems mt-3">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefourth mt-0" />
                        </div>
                        <div className="flex-betweenitems mt-3">
                            <div className="loading --animation --line --half" />
                            <div className="loading --animation --line --onefourth mt-0" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
