export default function IconDialpad() {
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x={15} y={10} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={11} y={10} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={19} y={10} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={15} y={14} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={11} y={14} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={19} y={14} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={15} y={18} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={15} y={22} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={11} y={18} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
            <rect x={19} y={18} width={2} height={2} rx={1} stroke="#4C4C4C" strokeWidth="1.3" strokeLinejoin="round" />
        </svg>
    );
}
