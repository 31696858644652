export const TYPE_CUSTOMER_JOB = {
	GET_LIST_JOB: "GET_CUSTOMER_LIST_JOB",
    GET_LIST_JOB_WORK_POOL: "GET_LIST_JOB_WORK_POOL",
	GET_LIST_TASKS: "GET_LIST_TASKS",
	UPDATE_TASK_STATUS: "UPDATE_TASK_STATUS",
};

export const actionGetCustomerJobs = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_CUSTOMER_JOB.GET_LIST_JOB,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const actionJobWorkPool = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_CUSTOMER_JOB.GET_LIST_JOB_WORK_POOL,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const actionGetCustomerListTask = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_CUSTOMER_JOB.GET_LIST_TASKS,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const actionUpdateTaskStatus = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_CUSTOMER_JOB.UPDATE_TASK_STATUS,
		params,
		actionSuccess,
		actionFailed,
	};
};

