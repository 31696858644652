import { React, useReducer, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { getGridColumnsCustomerStatement } from 'app/const/customer/CustomerStatement';
import { getListStatementCustomer } from 'app/const/Api';
import { customerStatements } from 'app/modules/customer/utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { formatDateLocal } from 'common/utils/DateUtils';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';
import { useContext } from 'react';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import StatementSummary from './components/StatementSummary';
import StatementActions from './components/StatementActions';
import GdGridView from 'app/components/grid/GdGridView';
import StatementTotal from './components/StatementTotal';
import ScheduleHistory from 'app/modules/jobdetail/tabs/settingschedule/history';
// import MainHeaderDetail from 'app/modules/customer/detail/layouts/MainHeaderDetail';

const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.STATEMENT_CUSTOMER_LIST);

const CustomerStatements = () => {
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        summary: [],
        totalStatement: [],
        isLoading: true,
        refresh: false
    });

    const { id: customerId } = useParams();
    const { data: statement, summary, totalStatement, isLoading } = state;
    const dateFormat = useSelector(({ auth }) => auth.user.company.date_format);
    const { location_ids, reloadCustomerPage } = useContext(CustomerDetailContext);
    const refOldLocation = useRef(null);

    const paramsCustomerStatements = getLocalStorage(keyLocalStore);
    !paramsCustomerStatements && setLocalStorage(keyLocalStore, getDefaultParams(REPORT_TYPE.STATEMENT_CUSTOMER_LIST));

    useEffect(() => {
        if ((location_ids && location_ids !== refOldLocation.current) || reloadCustomerPage) {
            if (!state.isLoading) {
                dispatchState({
                    isLoading: true
                });
            }
            refOldLocation.current = location_ids;
            const initParams = paramsCustomerStatements || getLocalStorage(keyLocalStore);
            initParams.location_ids = location_ids;
            setLocalStorage(keyLocalStore, initParams);
            _getListStatementCustomer(initParams);
        }
    }, [location_ids || reloadCustomerPage]);

    const _getListStatementCustomer = (data) => {
        const params = data || {};
        delete params.columns;

        dispatchState({ isLoading: true });

        const _success = (data) => {
            dispatchState({
                data: customerStatements(data),
                summary: data.summary,
                totalStatement: data.total_statement,
                isLoading: false
            });
        };

        const _failed = () => dispatchState({ data: [], isLoading: false });
        clientQuery(getListStatementCustomer(customerId), { data: params, method: 'GET' }, _success, _failed);
    };

    const _handleChangeFilter = (param, mode) => {
        if (mode === 'columns') return dispatchState({ refresh: !state.refresh });
    };

    const _handleUpdate = () => {
        _getListStatementCustomer({ ...getLocalStorage(keyLocalStore) });
    };

    return (
        <>
            <div className="wrapper-columns p-0">
                <div className="container-print statement-page contents-pages gap-8 scrolls">
                    <StatementSummary
                        params={paramsCustomerStatements}
                        idCustomer={customerId}
                        startDate={formatDateLocal(summary?.lowest_date || paramsCustomerStatements?.start, dateFormat)}
                        endDate={formatDateLocal(paramsCustomerStatements?.end, dateFormat)}
                        isLoading={isLoading}
                        summary={summary}
                    />
                    <StatementActions
                        handleUpdate={_handleUpdate}
                        handleChangeFilter={_handleChangeFilter}
                        statementType={paramsCustomerStatements?.statement_type}
                    />
                    <div className="wrap-tables flex-column">
                        <GdGridView
                            isLoading={isLoading}
                            content={statement}
                            fileTranslation="customers"
                            isScroll
                            {...getGridColumnsCustomerStatement(paramsCustomerStatements?.columns)}
                        />
                        <StatementTotal isLoading={isLoading} totalStatement={totalStatement} />
                        <ScheduleHistory customerId={customerId} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomerStatements;
