import ConversationTimeLine from 'app/modules/sms/components/ConversationTimeLine';
import React from 'react';
import SmsItem from './SmsItem';
import { sortListSms } from '../utils';

const ConversationContent = ({
    showModal,
    listSms = [],
    isShowButtonDelete = false,
    isHiddenMark = false,
    handleOnClick = () => {},
    onMarkSms = () => {},
    onResend = () => {},
    onDelete = () => {}
}) => {
    const _renderListSms = () => {
        if (!Array.isArray(listSms)) return;

        const newListSms = sortListSms({ data: [...listSms] });

        return newListSms.map((sms, index) => {
            const { key: groupKeyPre = '', value: groupValuePre = '' } = sms.group || {};
            const { key: groupKeyNext = '', value: groupValueNext = '' } = newListSms[index + 1]?.group || {};
            return (
                <React.Fragment key={sms.id || index}>
                    {index === 0 && <ConversationTimeLine date={groupValuePre} />}
                    <SmsItem
                        showModal={showModal}
                        sms={sms}
                        sending={listSms.sending}
                        isShowButtonDelete={isShowButtonDelete}
                        isHiddenMark={isHiddenMark}
                        handleOnClick={handleOnClick}
                        onMarkSms={onMarkSms}
                        onResend={onResend}
                        onDelete={onDelete}
                    />
                    {index < newListSms.length - 1 && groupKeyPre !== groupKeyNext && (
                        <ConversationTimeLine date={groupValueNext} />
                    )}
                </React.Fragment>
            );
        });
    };

    return _renderListSms();
};

export default ConversationContent;
