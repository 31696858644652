export const TYPE_SETTING_TEMPLATE = {
    GET_LIST: 'SETTING_GET_LIST_TEMPLATE',
    GET_DETAIL: 'SETTING_GET_DETAIL_TEMPLATE',
    CREATE_AND_UPDATE: 'SETTING_ADD_AND_EDIT_TEMPLATE',
    PREVIEW: 'PREVIEW_TEMPLATE',
    GET_LIST_TEMPLATE_REMINDER_REQUEST: 'GET_LIST_TEMPLATE_REMINDER_REQUEST',
    GET_LIST_TEMPLATE_REMINDER_SUCCESS: 'GET_LIST_TEMPLATE_REMINDER_SUCCESS',
    GET_LIST_TEMPLATE_CONFIRMATION_REQUEST: 'GET_LIST_TEMPLATE_REQUEST',
    GET_LIST_TEMPLATE_CONFIRMATION_SUCCESS: 'GET_LIST_TEMPLATE_CONFIRMATION_SUCCESS',
    GET_LIST_TEMPLATE_BROADCAST_REQUEST: 'GET_LIST_TEMPLATE_BROADCAST_REQUEST',
    GET_LIST_TEMPLATE_BROADCAST_SUCCESS: 'GET_LIST_TEMPLATE_BROADCAST_SUCCESS',
    GET_LIST_TEMPLATE_EMAIL_INBOX_REQUEST: 'GET_LIST_TEMPLATE_EMAIL_INBOX_REQUEST',
    GET_LIST_TEMPLATE_EMAIL_INBOX_SUCCESS: 'GET_LIST_TEMPLATE_EMAIL_INBOX_SUCCESS',
    RESET_LOAD_TEMPLATES: 'RESET_LOAD_TEMPLATES'
};

export const getListServiceTemplate = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getDetailServiceTemplate = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_DETAIL,
        params,
        actionFailed,
        actionSuccess
    };
};

export const createAndUpdateServiceTemplate = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.CREATE_AND_UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const previewServiceTemplate = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.PREVIEW,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListTemplateReminder = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_REMINDER_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListTemplateConfirmation = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_CONFIRMATION_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListTemplateBroadcast = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_BROADCAST_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
export const getListTemplateEmailInbox = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_EMAIL_INBOX_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
