import { TYPE_EMAIL_SETTING } from 'common/redux/actions/settings/emailAction';

const emailVariableReducer = (state = {
    isFirstTime: true,
    data: []
}, action) => {
    switch (action.type) {
        case TYPE_EMAIL_SETTING.GET_LIST_EMAIL_VARIABLE_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: action.payload?.data || []
            };
        default:
            return state;
    }
};

export default emailVariableReducer;
