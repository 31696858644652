import { call } from "redux-saga/effects";
import { fetchWithToken, postWithToken } from "common/utils/ApiUtils";

import { REPORT_EMAIL_HISTORY_EXPORT, REPORT_EMAIL_HISTORY_GET_LIST } from "app/const/Api";

export function* getListReportEmailHistory({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(fetchWithToken, REPORT_EMAIL_HISTORY_GET_LIST, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}

export function* exportEmailHistory({params, actionSuccess, actionFailed}) {
  try {
    const response = yield call(postWithToken, REPORT_EMAIL_HISTORY_EXPORT, params);
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}