import React, { useEffect, useReducer, useRef } from 'react';
import CustomerSearch from 'app/components/search/GlobalSearch';
import { customerSearchRequest } from 'common/redux/actions/searchAction';
import { useDispatch } from 'react-redux';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconLoading from 'assets/icon/IconLoading';
import classNames from 'classnames';
import IconSearch from 'assets/icon/IconSearch';
import IconCircleClose from 'assets/icon/IconCircleClose';
import { reducer } from 'app/const/Reducer';
import { TYPE_SEARCH_DEFAULT } from 'app/const/Customers';
import { useTranslation } from 'react-i18next';

const Search = ({ selectCustomer = () => {}, selectJobListCustomer = null }) => {
    const { t } = useTranslation(['header', 'common']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        keyWord: '',
        dataSearch: [],
        isLoading: false,
        customerSelected: null,
        showDataSearch: false,
        showMore: false,
        customerJobList: false,
        position: 0
    });
    const refSearch = useRef(null);

    const {
        showDataSearch: isVisibleResult,
        keyWord: finalKeyword,
        dataSearch: finaldataSearch,
        customerSelected,
        isLoading: finalIsLoading,
        position
    } = state;

    useEffect(() => {
        if (isVisibleResult) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisibleResult]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_search_result');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_search_result');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        isVisibleResult && dispatchState({ showDataSearch: false });
    }

    useEffect(() => {
        if (selectJobListCustomer) {
            selectCustomer(selectJobListCustomer);

            dispatchState({
                dataSearch: [],
                isLoading: false,
                showDataSearch: false,
                showMore: false,
                customerSelected: selectJobListCustomer,
                keyWord: selectJobListCustomer.label,
                customerJobList: true
            });
        }
    }, [selectJobListCustomer]);

    function _handleSearchKeyword(keyword, resetResult) {
        dispatch(
            customerSearchRequest(
                {
                    term: keyword || '',
                    limit: 20,
                    offset: resetResult ? 0 : finaldataSearch.length,
                    type: TYPE_SEARCH_DEFAULT
                },
                (response) => _handleSearchSuccess(response, resetResult),
                _handleSearchFailed
            )
        );
    }

    function _handleSearchSuccess(response, resetResult) {
        const finalCustomers = response?.customers || [];
        const finalResult = resetResult ? finalCustomers : [...finaldataSearch, ...finalCustomers];

        dispatchState({
            isLoading: false,
            dataSearch: finalResult,
            showDataSearch: true,
            showMore: response.show_more,
            customerJobList: false
        });
    }

    function _handleSearchFailed() {
        dispatchState({
            isLoading: false,
            dataSearch: [],
            showDataSearch: false,
            showMore: false,
            keyWord: ''
        });
    }

    function _handleOnFocusInput(e) {
        e.stopPropagation();

        !isVisibleResult && finalKeyword.length > 0 && dispatchState({ showDataSearch: true });
    }

    function _handleChangeSearch(text) {
        dispatchState({ keyWord: text, isLoading: true });

        _handleSearchKeyword(text, true);
    }

    function _handleSearch(e, showMore = false) {
        e.stopPropagation();

        dispatchState({ isLoading: true, showDataSearch: true });
        _handleSearchKeyword(finalKeyword, showMore);
    }

    function _handleClearKeyword() {
        dispatchState({
            keyWord: '',
            dataSearch: [],
            isLoading: false,
            customerSelected: null,
            showDataSearch: false,
            showMore: false,
            customerJobList: false
        });
    }

    function _selectCustomer(e, customer) {
        e.stopPropagation();
        selectCustomer(customer);
        dispatchState({ customerSelected: customer, showDataSearch: false, position: customer.index });
    }

    function _clickCustomerSelected(e) {
        e.stopPropagation();

        if (state.customerJobList) {
            _handleSearch(e, true);
        } else {
            dispatchState({ showDataSearch: !state.showDataSearch });
        }
    }

    function _clearCustomerSelected(e) {
        e.stopPropagation();

        selectCustomer(null);
        _handleClearKeyword();
    }

    function _renderDataResult() {
        const finalDataRender = finaldataSearch || [];

        const _renderListCustomer = () => {
            return finalDataRender.map((item, index) => {
                return (
                    <li key={index.toString()} onClick={(e) => _selectCustomer(e, item)}>
                        <div
                            className={`items ${
                                item.id === customerSelected?.id && item.index === position ? 'active' : ''
                            }`}
                        >
                            <div className="avt">{item.avatar.toUpperCase()}</div>
                            <span className="txt-ellipsis">{item.label}</span>
                        </div>
                    </li>
                );
            });
        };

        const _renderShowMore = () => {
            if (finalIsLoading) {
                return (
                    <li className="items --loadmore justify-center" key={'show_more'}>
                        <div className="v2-btn-default --noborder --label --loadmore bg-transparent">
                            <IconLoading />
                        </div>
                    </li>
                );
            }

            if (state.showMore && !finalIsLoading && finaldataSearch.length !== 0) {
                return (
                    <li
                        className="items --loadmore justify-center"
                        key={'show_more'}
                        onClick={(e) => _handleSearch(e, false)}
                    >
                        <div className="v2-btn-default --noborder --label --loadmore bg-transparent">
                            {t('common:show_more')}
                        </div>
                    </li>
                );
            }
        };

        return (
            <div id="show_search_result" className="search-result v2-dropdown__menu scrolls">
                <ul>
                    {_renderListCustomer()}
                    {_renderShowMore()}
                </ul>
            </div>
        );
    }

    function _renderNotFound() {
        if (finalKeyword && !finaldataSearch.length && !finalIsLoading) {
            return (
                <div id="show_search_result" className="search-result v2-dropdown__menu">
                    <ul className="scrolls">
                        <li className="items pointer-events-none">
                            <span className="txt-ellipsis">{t('header:search_not_match')}</span>
                        </li>
                    </ul>
                </div>
            );
        }
        return false;
    }

    function _renderSelected() {
        if (!customerSelected) {
            return false;
        }

        return (
            <div className="search-input has-closeicon" onClick={(e) => _clickCustomerSelected(e)}>
                <span className="svg-search-absolute">
                    <IconSearch />
                </span>
                <input className="field-search" type="text" name="" placeholder="Search" />
                <div className="close-icon" onClick={(e) => _clearCustomerSelected(e)}>
                    <IconCircleClose />
                </div>
                <div className="user-result">
                    <div className="w-100">
                        <div className="avt fs-11">{customerSelected.avatar}</div>
                        <span className="txt-ellipsis ml-1" title={customerSelected.label}>
                            {customerSelected.label}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div
            ref={refSearch}
            className={classNames('v2-dropdown search-jobs', {
                show: !!customerSelected,
                active: state.showDataSearch
            })}
        >
            {!!!customerSelected && (
                <CustomerSearch
                    isLoading={finalIsLoading}
                    placeholder="Search"
                    onSearch={_handleChangeSearch}
                    clearKeyword={_handleClearKeyword}
                    defaultValue={finalKeyword}
                    style="search-input has-closeicon"
                    onFocusSearch={_handleOnFocusInput}
                />
            )}
            {_renderSelected()}
            {_renderDataResult()}
            {_renderNotFound()}
        </div>
    );
};

export default Search;
