import IconClose from 'assets/icon/IconClose';
import IconLink from 'assets/icon/IconLink';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { handleGetSelectedText } from '../utils';

const CustomLinkOption = ({ editorState, onInsert = () => {} }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const refTitleLink = useRef(null);
    const refLinkTarget = useRef(null);
    const refTargetBlank = useRef(null);

    const _handleAfterOpen = () => {
        const selectedText = handleGetSelectedText(editorState);
        if (selectedText.trim().length && refTitleLink.current) refTitleLink.current.value = selectedText;
    };

    const _toggleModal = () => setIsOpen(!isOpen);

    const _handleInsertLink = () => {
        onInsert({
            url: refLinkTarget.current.value,
            text: refTitleLink.current.value,
            isBlank: refTargetBlank.current.checked
        });
        _toggleModal();
    };

    return (
        <>
            <div className="editor-controls__btn tooltip btn-modal" onClick={_toggleModal}>
                <IconLink />
                <span className="tooltiptext top">Link (CTRL+K)</span>
            </div>

            <ReactModal
                isOpen={isOpen}
                id="insert_link"
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal open"
                onAfterOpen={_handleAfterOpen}
                onRequestClose={_toggleModal}
                portalClassName="ReactModalPortal_customer"
            >
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label">{t('insert_link')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_toggleModal}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="body-modal modal-insert has-form scrolls">
                        <div className="rows">
                            <div className="txt">{t('insert_title')}</div>
                            <input ref={refTitleLink} className="field-input" type="text" autoFocus spellCheck />
                        </div>
                        <div className="rows mt-1">
                            <div className="txt">{t('insert_target')}</div>
                            <input ref={refLinkTarget} className="field-input" type="text" spellCheck />
                        </div>
                        <div className="rows mt-2">
                            <div className="check-items">
                                <input id="new_window" ref={refTargetBlank} type="checkbox" defaultChecked />
                                <div className="item-checkbox">
                                    <label htmlFor="new_window" className="txt-ellipsis">
                                        <span>{t('open_link_in_new_window')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --noborder --label" onClick={_toggleModal}>
                            {t('cancel')}
                        </span>
                        <div className="v2-btn-main" onClick={_handleInsertLink}>
                            {t('insert')}
                        </div>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export default React.memo(CustomLinkOption);
