import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import ReactModal from 'react-modal';

import { reducer } from 'app/const/Reducer';
import { useSmartView } from '../../context';
import { SmartViewFilterProvider } from '../../context/addFilterContext';
import FilterContent from './FilterContent';
import FilterFooter from './FilterFooter';
import FilterHeader from './FilterHeader';

const AddFilterModal = forwardRef((props, ref) => {
    const { handleStoreFiltered, handleCancelFilter } = useSmartView();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, dataFilter: {} });
    const { isOpen, dataFilter } = state;

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));

    const _handleOpen = (dataFilter = {}) => {
        dispatchState({ isOpen: true, dataFilter });
    };

    const _handleClose = () => {
        dispatchState({ isOpen: false });
        handleStoreFiltered && handleStoreFiltered();
    };

    const _handleCancel = () => {
        dispatchState({ isOpen: false });
        handleCancelFilter();
    };

    return (
        <ReactModal
            isOpen={isOpen}
            id="smart_views_filter_modal"
            style={{ overlay: { background: 'transparent' } }}
            className={classNames('modal modal-smart-views container-modal open', {
                '--filter-tags': dataFilter.isEditing
            })}
            onRequestClose={_handleClose}
        >
            <SmartViewFilterProvider initialData={dataFilter}>
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container flex-column">
                    <FilterHeader onClose={_handleClose} />
                    <div className="body-modal flex-column__content">
                        <FilterContent />
                    </div>
                    <FilterFooter onClose={_handleClose} onCancel={_handleCancel} />
                </div>
            </SmartViewFilterProvider>
        </ReactModal>
    );
});

AddFilterModal.displayName = 'AddFilterSmartViewModal';
export default AddFilterModal;
