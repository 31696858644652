/* eslint-disable no-constant-condition */
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_SMS_COUNTRIES } from 'app/const/Api';
import { TAB_ADDONS_VOIP_ADD_GROUP_NUMBER, TEXT_SAVE_VOIP_ADDONS } from 'app/const/addons';
import { GET_LIST_SEARCH_PHONE, changeVoipNumber } from 'app/const/api/Voip';
import VOIPAddonsFormNumber from './VOIPAddonsFormNumber';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import SMSAddonsPhonesTable from '../../sms/components/SMSAddonsPhonesTable';
import ButtonSave from 'app/components/button/ButtonSave';
import IconClose from 'assets/icon/IconClose';
import ReactModal from 'react-modal';
import { VOIPAddonsLoadingModal } from './loadings/VOIPAddonsLoadingModal';

const VOIPAddonsModalEdit = ({ onClose = () => {}, onUpdateListNumber = () => {} }, ref) => {
    const { t } = useTranslation();
    const refFirstTime = useRef(false);
    const refFormNewNumber = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refPhoneSelect = useRef(null);
    const refCheckBoxUnderstand = useRef(null);
    const refTablePrefixEdit = useRef(null);

    const { NEW_NUMBER: tabNewNumber } = TAB_ADDONS_VOIP_ADD_GROUP_NUMBER;

    const initialState = {
        isVisible: false,
        tabActive: tabNewNumber,
        phones: [],
        isShowPhones: false,
        textSave: TEXT_SAVE_VOIP_ADDONS[tabNewNumber],
        isRequestNew: true,
        dataEdit: null
    };
    const [state, dispatchState] = useReducer(reducer, {
        ...initialState,
        isLoading: true,
        phoneCountries: []
    });
    const { tabActive, phoneCountries, isLoading, isShowPhones, phones, textSave, isRequestNew, dataEdit, isVisible } =
        state;
    const isEditing = !!dataEdit?.id;

    useImperativeHandle(ref, () => ({
        _handleSave,
        _handleChangeNumber,
        _show: _handleShowModal,
        _close: _handleCloseModal
    }));

    useEffect(() => {
        isVisible && _open();
    }, [isVisible]);

    const _handleChangeNumber = (data) => {
        dispatchState((prev) => ({ ...prev, dataEdit: data, isVisible: true }));
    };

    const _handleCloseModal = () => {
        handleSetLoadingButton(false);
        dispatchState({ ...initialState });
        onClose();
    };

    const _handleShowModal = () => {
        dispatchState({ isVisible: true });
    };

    const handleSetLoadingButton = (value) => {
        refButtonSave.current.setLoading(value);
        refButtonSave.current.setDisable(false);
    };

    const handleSetLoadingPrefix = (value) => {
        refTablePrefixEdit.current?.setLoading(value);
    };

    const _renderHeaderModal = () => {
        return (
            <div className="header-modal">
                <h3 className="header-modal__label">{t(`addons:edit_number`)}</h3>
                <span className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseModal}>
                    <IconClose />
                </span>
            </div>
        );
    };

    const _renderFooterModal = () => {
        return (
            <div className="footer-modal">
                <span className="v2-btn-default --transparent" onClick={_handleCloseModal}>
                    {t('addons:cancel')}
                </span>
                <ButtonSave
                    ref={refButtonSave}
                    wrapClass="v2-btn-main"
                    onSave={_handleSave}
                    title={t(`addons:${textSave}`)}
                />
            </div>
        );
    };

    const _renderBodyModal = () => {
        return (
            <Fragment>
                <VOIPAddonsFormNumber
                    ref={refFormNewNumber}
                    id="eidt_number"
                    classWrapper="rows row-haft fs-14 px-3 pt-3"
                    phoneCountries={phoneCountries}
                    onPressEnter={() => _handleSave(true)}
                    maxLength={5}
                    isOnlyNumber
                />
                <div className="container-print flex-column gap-16 mt-2 pb-3">
                    <p className="granite-gray px-3">{t('addons:new_number_price')}</p>
                    {isShowPhones && (
                        <Fragment>
                            <SMSAddonsPhonesTable
                                ref={refTablePrefixEdit}
                                data={phones}
                                onSelect={_handleSelectPhoneNumber}
                            />
                            {!!phones.length && (
                                <div className="check-items px-3 pb-3">
                                    <input
                                        ref={refCheckBoxUnderstand}
                                        id="cb_organization"
                                        type="checkbox"
                                        onChange={_handleChangeChexbox}
                                    />
                                    <div className="item-checkbox">
                                        <label htmlFor="cb_organization" className="txt-ellipsis">
                                            <span className="word-break wrap-normal">
                                                {t('addons:understand_change_number')}
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )}
                        </Fragment>
                    )}
                    <div className="is-divider --horizontal mx-3" />
                    <span className="label px-3">
                        {t('addons:you_can_also')}
                        <span className="purple-default cursor-pointer ml-1">{t('addons:port_existing')}</span>
                    </span>
                </div>
            </Fragment>
        );
    };

    const _handleSelectPhoneNumber = (phone) => {
        if (isRequestNew) {
            dispatchState((prev) => ({ ...prev, isRequestNew: false, textSave: 'save' }));
        }
        if (isEditing && !refCheckBoxUnderstand.current.checked) {
            refButtonSave.current.setDisable(true);
        }
        refPhoneSelect.current = phone;
    };

    const _handleChangeChexbox = (e) => {
        !!refPhoneSelect.current && refButtonSave.current.setDisable(!e.target.checked);
    };

    const _open = () => {
        if (!refFirstTime.current) {
            const _handleGetPhoneCountriesSuccess = ({ data }) => {
                dispatchState({ phoneCountries: data, isLoading: false });
                refFirstTime.current = true;
            };
            clientQuery(ADDONS_SMS_COUNTRIES, { method: 'GET' }, _handleGetPhoneCountriesSuccess);
        }
    };

    const _handleSave = (isPress = false) => {
        handleSetLoadingButton(true);
        handleSaveNewNumber(isPress);
    };

    const handleSaveNewNumber = (isPress) => {
        if (isRequestNew || isPress) {
            refPhoneSelect.current = null;
            handleSetLoadingPrefix(true);
            handleSearchVOIPNumber(refFormNewNumber.current._getValue());
        } else handleChangeNumber({ number: refPhoneSelect.current });
    };

    const handleSearchVOIPNumber = (data) => {
        const {
            country: { country_code },
            number: prefix
        } = data;
        const _handleSuccess = (res) => {
            handleSetLoadingButton(false);
            handleSetLoadingPrefix(false);
            dispatchState((prev) => ({
                ...prev,
                phones: res.data,
                isShowPhones: true,
                isRequestNew: true,
                textSave: TEXT_SAVE_VOIP_ADDONS[tabActive]
            }));
        };

        const _handleSearchFail = (err) => {
            dispatchState((prev) => ({
                ...prev,
                phones: [],
                isShowPhones: true,
                isRequestNew: true,
                textSave: TEXT_SAVE_VOIP_ADDONS[tabActive]
            }));
            _handleFail(err);
        };

        if (!/^\d*$/.test(prefix)) {
            _handleSearchFail({ message: t('addons:error_prefix', { prefix }) });
            return;
        }

        clientQuery(
            GET_LIST_SEARCH_PHONE,
            { method: 'GET', data: { country: country_code, prefix } },
            _handleSuccess,
            _handleSearchFail
        );
    };

    const handleChangeNumber = (data) => {
        const _handleSuccess = (res) => {
            onUpdateListNumber(res.data);
            handleSetLoadingButton(false);
            _handleCloseModal();
        };
        clientQuery(changeVoipNumber(dataEdit.id), { method: 'PUT', data }, _handleSuccess, _handleFail);
    };

    const _handleFail = (err) => {
        handleSetLoadingButton(false);
        handleSetLoadingPrefix(false);
        refAlert.current.showStatusBar({ id: 'add_number', message: err.message, type: LIST_STATUS.ERROR });
    };

    return (
        <ReactModal isOpen={isVisible} className="modal container-modal --add-number open">
            <Fragment>
                <div className="modal__overlay bg-fixed" onClick={_handleCloseModal} />
                <div className="modal__container large">
                    {isLoading ? (
                        <VOIPAddonsLoadingModal />
                    ) : (
                        <Fragment>
                            {_renderHeaderModal()}
                            <div className={`body-modal p-0 has-form elm-parent black-eerie-dark`}>
                                <AlertCustomer ref={refAlert} />
                                {_renderBodyModal()}
                            </div>
                            {_renderFooterModal()}
                        </Fragment>
                    )}
                </div>
            </Fragment>
        </ReactModal>
    );
};

export default forwardRef(VOIPAddonsModalEdit);
