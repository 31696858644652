export const ACCEPT_ROUTE_TYPES = {
    MOVE_JOBS_ONLY: 0,
    MOVE_JOBS_AND_RECURRING: 1
};

export const ROUTING_JOBS_STATUS = {
    0: 'unconfirmed',
    1: 'confirmed',
    3: 'reschedule',
    4: 'pending-confirm',
    7: 'pending-booking'
};

export const RECURRING_JOB_INCLUDE = 6;

export const DATE_FORMAT_DEFAULT_ROUTING = 'm/d/Y';

export const ROUTING_EVENT_TYPE = {
    EVENT: 'event',
    TIMEOFF: 'timeoff',
    JOB: 'job'
};

export const LIST_TYPE_CALCULATE = [
    'workTimeStart',
    'workTimeEnd',
    'driveBuffer',
    'jobsPerDay',
    'jobsPerDayCustom',
    'exclude'
];
