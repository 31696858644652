import { TYPE_INVOICE } from 'common/redux/actions/invoiceAction';

const invoiceReducer = (state = {
    invoiceData: null
}, action) => {
    switch (action.type) {
        case TYPE_INVOICE.ACTION_OPEN_INVOICE_DETAIL:
            return { ...state, invoiceData: action.params };
        default:
            return state;
    }
};

export default invoiceReducer;
