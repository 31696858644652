import { MAX_PHONE } from 'app/const/Customers';
import { DEFAULT_PHONE_MOBILE } from 'app/const/Phone';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import { addPhoneTypes } from 'common/redux/actions/phones';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CreatePhoneLabel from './components/CreatePhoneLabel';
import ListPhones from './components/ListPhones';
import ManagePhoneLabels from './components/ManagePhoneLabels';

const CustomerPhoneLabel = (
    {
        limit = MAX_PHONE,
        defaultSelected = null,
        isGetAllType = false,
        isShowLabel = true,
        onRetrieveChange = () => {},
        ...props
    },
    ref
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { phoneTypes } = useSelector(({ phoneTypes }) => phoneTypes);
    const [state, dispatchState] = useReducer(reducer, {
        data: defaultSelected || [{ id: `${new Date().getTime()}`, phone: '', type: DEFAULT_PHONE_MOBILE }]
    });

    const refManageLabel = useRef(null);
    const refCreateLabel = useRef(null);

    const { data } = state;

    /* Checking if the number of phones is equal or larger to the limit. */
    const isMaxPhones = data.length >= limit;

    useImperativeHandle(ref, () => ({ _getValue: _handleGetValue, _setValue: _handleSetValue }));

    const _handleSetValue = (data) => {
        if (Array.isArray(data)) dispatchState({ data: [...data] });
    };

    const _handleGetValue = () => {
        const newValue = [];
        data.forEach((item) => {
            if (item.phone) {
                newValue.push(isGetAllType ? item : { phone: item.phone, type: item.type.id, name: item.type.name });
            }
        });

        return newValue;
    };

    /**
     * If the number of phones is equal to the limit, return. Otherwise, add a new phone to the list.
     */
    const _handleAdd = () => {
        /* Checking if the number of phones is equal to the limit.  */
        if (isMaxPhones) return;
        const newData = [...data, { id: `${new Date().getTime()}`, phone: '', type: DEFAULT_PHONE_MOBILE }];
        dispatchState({ data: newData });
        onRetrieveChange(newData);
    };

    /**
     * _handleAddPhone is a function that takes a newPhone as an argument and dispatches an action to add
     * the newPhone to the phoneTypes array in the state
     * @param newPhone - The new phone type to add to the list.
     */
    const _handleAddPhone = (newPhone) => {
        dispatch(addPhoneTypes(newPhone));
    };

    /**
     * It filters out the item with the given id from the data array and updates the state with the new
     * array.
     * @param id - The id of the item to be removed.
     */
    const _handleRemove = (id) => {
        const newData = [...data].filter((item) => item.id !== id);
        dispatchState({ data: newData });
        onRetrieveChange(newData);
    };

    /**
     * It takes an id and a type, and then it maps through the selected items, and if the id matches the
     * id of the item, it returns the item with the new type
     * @param id - The id of the item that was selected.
     * @param type - string - the type of the item
     */
    const _handleSelect = (id, type) => {
        const newData = data.map((item) => {
            if (item.id === id) return { ...item, type };
            return item;
        });

        dispatchState({ data: newData });
        onRetrieveChange(newData);
    };

    /**
     * It takes an id and a phone number, and then it returns a new array of data where the phone number of
     * the item with the given id is changed to the given phone number
     * @param id - the id of the row
     * @param phone - the phone number
     */
    const _handleBlur = (id, phone) => {
        const newData = [...data].map((item) => {
            if (item.id === id) return { ...item, phone: formatPhoneNumberVoip(phone) };
            return item;
        });

        dispatchState({ data: newData });
        onRetrieveChange(newData);
    };

    /**
     *  It opens the manage label phones modal.
     */
    const _handleManage = () => {
        refManageLabel.current._open();
    };

    /**
     * _handleAddPhoneModal is a function that opens the modal for adding a phone number
     * @param id - The id of the contact you want to add a phone number to.
     */
    const _handleAddPhoneModal = (id) => {
        refCreateLabel.current._open(id);
    };

    return (
        <>
            <ListPhones
                data={data}
                phoneTypes={phoneTypes}
                isMaxPhones={isMaxPhones}
                onSelect={_handleSelect}
                onRemove={_handleRemove}
                onBlur={_handleBlur}
                onAddPhone={_handleAddPhone}
                onOpenManage={_handleManage}
                onOpenAddPhone={_handleAddPhoneModal}
                isShowLabel={isShowLabel}
                {...props}
            />

            {!isMaxPhones && (
                <div className="v2-btn-main --bg-green has-icon svg-white" onClick={_handleAdd} tabIndex="0">
                    <IconPlus />
                    {isShowLabel && t('add_phone')}
                </div>
            )}

            {/* Modal Manage Phone Labels */}
            <ManagePhoneLabels ref={refManageLabel} onCreatePhone={_handleAddPhoneModal} />
            {/* Modal Create New Phone Labels */}
            <CreatePhoneLabel ref={refCreateLabel} onAddSuccess={_handleAddPhone} />
        </>
    );
};

export default forwardRef(CustomerPhoneLabel);
