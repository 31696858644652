import { COLOR_SUCCESS, KEY_LOCAL_STORAGE_HELLO_BAR } from 'app/const/App';
import IconClose from 'assets/icon/IconClose';
import { setLocalStorage, getLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function HelloBar() {
    const helloBar = useSelector(({ auth }) => auth.user.notifications?.hello_bar) || {};
    const { active, color, link_text, link_url, message, updated } = helloBar;
    const valueShowHelloBar = getLocalStorage(KEY_LOCAL_STORAGE_HELLO_BAR);
    const isShowHelloBar = parseInt(valueShowHelloBar) !== parseInt(updated);

    useEffect(() => {
        _handleShowBar(isShowHelloBar && active);
    }, []);

    const _handleShowBar = (isShow) => {
        const elmMainPage = document.getElementById('main-page');
        elmMainPage && elmMainPage.classList[isShow ? 'add' : 'remove']('has-notify-bar');
        if (!isShow) setLocalStorage(KEY_LOCAL_STORAGE_HELLO_BAR, updated);
    };

    if (!active || !isShowHelloBar) return null;

    return (
        <div className="notify-bar">
            <div className="alert" style={{ backgroundColor: color || COLOR_SUCCESS }}>
                <p className="alert__description">
                    {message}
                    <a href={link_url} target="_blank" className="link" rel="noreferrer">
                        {link_text}
                    </a>
                </p>
                <div className="alert__btn svg-white-stroke" onClick={() => _handleShowBar(false)}>
                    <IconClose />
                </div>
            </div>
        </div>
    );
}

export default HelloBar;
