import React from 'react';

const ReviewAddonsLoading = () => {
    return (
        <div className="boxs-wrapper">
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --twothird" />
                            </div>
                        </div>
                        <div className="loading --onefifth switch ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="description loading-lines mt-5">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="boxs__footer --multi loading">
                        <div className="loading" />
                        <div className="v2-btn-default --transparent loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --fifth" />
                        </div>
                    </div>
                    <div className="boxs__footer --multi loading">
                        <div className="loading" />
                        <div className="v2-btn-default --transparent loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReviewAddonsLoading;
