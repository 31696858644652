import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

import {
    LIST_TAB_BASIC,
    LIST_BUTTON_ACTION_LINE_ITEMS as LIST_BUTTONS,
    LIST_BUTTON_MESSAGES_LINE_ITEMS as LIST_BUTTON_MESSAGES,
    LIST_ACTION_BASIC
} from 'app/const/Settings';
import { updateOrderStatusOnTable } from 'app/modules/settings/utils/statusOrder';

export const TAB = {
    LIST: LIST_TAB_BASIC,
    DEFAULT_SELECTED: LIST_ACTION_BASIC.ACTIVE
};

export const TABLE = {
    header: {
        name: {
            title: 'name',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        description: {
            title: 'description',
            style: 'col --info',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        tax1: {
            title: 'tax_1',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        tax2: {
            title: 'tax_2',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        cost: {
            title: 'cost',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        }
    },
    columns: [
        {
            id: 'name',
            status: true
        },
        {
            id: 'description',
            status: true
        },
        {
            id: 'tax1',
            status: true
        },
        {
            id: 'tax2',
            status: true
        },
        {
            id: 'cost',
            status: true
        }
    ],
    contentConfig: {
        name: {
            id: 'name',
            style: 'col --name',
            styleText: 'col-label btn-modal',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        description: {
            title: 'description',
            style: 'col --info white-space-pre',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        tax1: {
            title: 'tax_1',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.TAXES
        },
        tax2: {
            title: 'tax_2',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.TAXES
        },
        cost: {
            title: 'cost',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.COST
        }
    }
};

const getColumns = ({ actionStatus, currentTab }) => {
    const newColumns = TABLE;
    if (actionStatus) {
        newColumns.header = updateOrderStatusOnTable({ headerTable: newColumns.header, actionStatus: actionStatus });
    }
    if (currentTab && currentTab === LIST_ACTION_BASIC.DELETE) {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.DEFAULT;
        newColumns.contentConfig.name.style = 'col';
    } else {
        newColumns.contentConfig.name.type = LIST_TABLE_ITEM_TYPE.LINK;
        newColumns.contentConfig.name.style = 'col --name';
    }
    return newColumns;
};

export { LIST_BUTTONS, LIST_BUTTON_MESSAGES, getColumns };
