import React, { useReducer, useEffect, useRef } from 'react';
import IconUpload from 'assets/icon/IconUpload';
import IconTrash from 'assets/icon/IconTrash';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { reducer } from 'app/const/Reducer';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { clientQuery } from 'common/utils/ApiUtils';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { ACTION_UPLOAD_IMAGE, actionDeleteLocationPhoto } from 'app/const/api/V2';
import { uploadToS3, imageType, bytesToSize } from 'common/utils/FileUtils';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import { limitSizeAttachment } from 'app/modules/customer/utils';
import { LINK_CDN_IMG_BROKEN } from 'app/const/URL';

export default function Options({ itemId, onUpdateImage }) {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });

    const { t } = useTranslation(['customers']);
    const refOptions = useRef(null);
    const refUpload = useRef(null);
    const finalIsVisible = state.isVisible;
    const refConfirm = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_options_location_note');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleClose();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_options_location_note');

        if (
            refOptions.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refOptions.current.contains(event.target)
        ) {
            _handleClose();
        }
    }

    function _handleClose() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen(e) {
        e && e.stopPropagation();

        dispatchState({
            isVisible: true
        });
    }

    const _uploadClick = () => {
        _handleClose();
        refUpload.current.click();
    };

    const _uploadAttachments = (event) => {
        const files = event.target.files;
        if (!files?.length) return;

        for (let index = 0; index < files.length; index++) {
            const file = files[index];

            if (imageType.includes(file.type)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = () => {
                    _addNewAttachments({
                        id: file.lastModified,
                        file,
                        name: file.name,
                        // TODO: Should replace with image loading not broken
                        // For now, we use file-broken.png
                        url: LINK_CDN_IMG_BROKEN,
                        mime: file.type
                    });
                };
            } else {
                refConfirm.current.open(null, 'Please select an image file type to upload.');
            }
        }

        refUpload.current.value = '';
    };

    const _addNewAttachments = (attachment) => {
        if (Array.isArray(attachment)) {
            attachment.forEach((attach) => {
                _addAttachWithQuery(attach);
            });
        } else {
            _addAttachWithQuery(attachment);
        }
    };

    const _addAttachWithQuery = (attach) => {
        if (limitSizeAttachment(attach.file.size)) {
            refConfirm.current.open(null, t('setting:maximum_file_size', { size: '20', unit: 'MB' }));
            return;
        }

        const idAttach = `${attach.name}${Date.now()}`;
        const optionQuery = {
            data: {
                name: `web-${attach.name}`,
                item_id: itemId,
                type: 'location'
            },
            method: 'POST'
        };

        onUpdateImage({
            ...attach,
            size: bytesToSize(attach.file.size),
            type: attach.mime,
            id: idAttach,
            isDisable: true
        });

        const _addSuccess = (response) => {
            const { presigned_url, object_key, object_tag, thumbnail_url, public_url } = response.data;
            const optionsHeaders = { 'x-amz-tagging': object_tag };
            const data = { idAttach, object_key, object_tag, thumbnail_url, public_url };

            uploadToS3(
                presigned_url,
                optionsHeaders,
                attach.file,
                data,
                (responseAdd) => {
                    _uploadS3Success(responseAdd, attach);
                },
                _uploadS3Failure
            );
        };

        const _addFailure = () => {
            _removeAttach();
        };

        clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, _addFailure);
    };

    const _uploadS3Success = ({ object_key, object_tag, thumbnail_url, public_url }, attach) => {
        const params = {
            url: { object_key: object_key, object_tag: object_tag },
            id: itemId,
            mime: attach.mime,
            name: attach.name,
            size: attach.file.size,
            location: true
        };

        clientQuery(
            ACTION_UPLOAD_IMAGE,
            { method: 'POST', data: params, toFormData: false },
            ({ data }) => {
                onUpdateImage({
                    mime: attach.mime,
                    name: attach.name,
                    size: attach.file.size,
                    thumbnail_url,
                    public_url,
                    url: public_url,
                    created: data.created,
                    isDisable: false
                });
            },
            _removeAttach
        );
    };

    const _uploadS3Failure = () => {
        _removeAttach();
    };

    const _removeAttach = () => {
        onUpdateImage('');
    };

    const _handleRemoveImage = () => {
        clientQuery(actionDeleteLocationPhoto(itemId), { method: 'DELETE' });

        dispatchState({
            isVisible: false
        });

        _removeAttach();
    };

    return (
        <div ref={refOptions} className={`v2-dropdown v2-dropdown--more ${finalIsVisible ? 'active' : ''}`}>
            <div onClick={_handleOpen} className="dropbtn">
                <IconThreeDots />
            </div>

            <div id={'show_options_location_note'} className="v2-dropdown__menu scrolls">
                <ul>
                    <li onClick={_uploadClick} className="items has-icon">
                        <IconUpload />
                        Upload New Image
                    </li>
                    <li onClick={_handleRemoveImage} className="items has-icon red-default btn-modal">
                        <IconTrash />
                        Delete Image
                    </li>
                </ul>
                <input
                    onChange={_uploadAttachments}
                    type="file"
                    ref={refUpload}
                    style={{ display: 'none' }}
                    accept="image/x-png,image/gif,image/jpeg"
                />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
        </div>
    );
}
