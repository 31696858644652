import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import IconAlert from 'assets/icon/IconAlert';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconPen from 'assets/icon/IconPen';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import React, { useContext, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import { reducer } from 'app/const/Reducer';
import moment from 'moment';

const PlanTax = ({ isEdit = false, isSquare = false }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isAdding: false,
        isUpdating: false,
        isError: false,
        isHiddenForm: false,
        isChangeEndDate: false,
        isChangeStartDate: false
    });

    const { selectedPlans, tax, _handleEditTax, _handleChangeDate, end_date, trial_end } =
        useContext(SubscriptionContext);
    const refInput = useRef(null);
    const { isAdding, isEditing, isError, isChangeEndDate, isChangeStartDate } = state;

    const _handleAdd = () => {
        dispatchState((prev) => ({ ...prev, isAdding: true, isHiddenForm: false }));
    };

    const _handleCloseForm = () => {
        dispatchState((prev) => ({ ...prev, isAdding: false, isEditing: false }));
    };

    const _handleEdit = () => {
        dispatchState((prev) => ({ ...prev, isEditing: true }));
    };

    const _handleRemoveTax = () => {
        dispatchState((prev) => ({ ...prev, isAdding: false, isError: false, isEditing: false }));
        refInput.current.value = null;
        _handleEditTax(null);
    };

    const _handleUpdateTax = () => {
        const value = parseFloat(refInput.current.value);
        const isGreaterHundred = value > 100;
        if (isGreaterHundred) refInput.current.value = 100;
        if (isGreaterHundred) {
            dispatchState((prev) => ({ ...prev, isError: isGreaterHundred }));
        } else {
            dispatchState((prev) => ({ ...prev, isAdding: false, isError: isGreaterHundred, isEditing: !isEdit }));
            _handleEditTax(isGreaterHundred ? 100 : value);
        }
    };

    const _handleEndDate = (date) => {
        _handleChangeDate(date, 'end');
        if (!isChangeEndDate) dispatchState((prev) => ({ ...prev, isChangeEndDate: true }));
    };

    const _handleStartDate = (date) => {
        _handleChangeDate(date, 'start');
        if (!isChangeStartDate) dispatchState((prev) => ({ ...prev, isChangeStartDate: true }));
    };

    const editView = isEditing || isAdding;
    const savedView = isEdit && !isEditing && tax;
    const isShowStartTime = !isSquare && (moment().isBefore(trial_end) || isChangeStartDate);

    if (!selectedPlans.length) return null;
    return (
        <div className="rows --flexend">
            <div className="wrap-col">
                <div className="wrap-col__content">
                    <div className="col --md">
                        {t('setting:tax')} {!!tax && <span className="value">({tax}%)</span>}
                    </div>
                    <div
                        className={classNames('col flex-1 --action col-tax-info', {
                            'is-edit': editView,
                            'is-saved': savedView
                        })}
                    >
                        <div className="btn-action">
                            <div className="btn-tax">
                                <div className="v2-btn-default --purple has-icon svg-purple-fill" onClick={_handleAdd}>
                                    <IconPlus />
                                    {t('setting:add_tax')}
                                </div>
                            </div>

                            <div className="elm-save-tax">
                                <div className="v2-btn-default --icon-lg tooltip" onClick={_handleEdit}>
                                    <IconPen />
                                    <p className="tooltiptext top">{t('edit')}</p>
                                </div>
                                <div
                                    className="v2-btn-default --icon-lg --delete ml-2 tooltip"
                                    onClick={_handleRemoveTax}
                                >
                                    <IconTrash />
                                    <p className="tooltiptext top">{t('remove')}</p>
                                </div>
                            </div>

                            <div className="wrap-btn">
                                <div className="cost-input --right" data-currency-unit="%">
                                    <input
                                        ref={refInput}
                                        className="field-input"
                                        type="number"
                                        placeholder={0}
                                        defaultValue={tax}
                                    />
                                </div>
                                <div className="v2-btn-default --icon-lg" onClick={_handleCloseForm}>
                                    <IconClose />
                                </div>
                                <div className="v2-btn-main --icon-lg svg-white-stroke" onClick={_handleUpdateTax}>
                                    <IconCheck />
                                </div>
                            </div>
                        </div>
                        {(isAdding || isEditing) && isError && (
                            <p className="txt-incorrect">
                                <IconAlert />
                                {t('common:tax_percent')}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            <div className="wrap-col">
                {(!isEdit || isShowStartTime) && (
                    <>
                        <div className="line" />
                        <div className="wrap-col__content">
                            <div className="col --md">{t('common:start_date')}</div>
                            <div className="col flex-1 --action">
                                <CalendarDatePicker
                                    selectDefault={trial_end}
                                    minDate
                                    onChange={_handleStartDate}
                                    shouldEmptyInput
                                    showIconClear={!!trial_end}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="line" />
                {!isChangeEndDate && !end_date && isEdit && isSquare ? null : (
                    <div className="wrap-col__content">
                        <div className="col --md">{t('common:end_date')}</div>
                        <div className="col flex-1 --action">
                            <CalendarDatePicker
                                minDate
                                selectDefault={end_date}
                                shouldEmptyInput
                                onChange={(date) => _handleEndDate(date)}
                                showIconClear={!!end_date}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlanTax;
