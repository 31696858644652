import React, { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MainHeaderSettings from '../components/MainHeaderSettings';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';
import { PERMISSIONS } from 'app/const/Permissions';
import BoxTipsSetting from '../components/BoxTipsSetting';
import IconArrow from 'assets/icon/IconArrow';

const SettingsGlobalOverride = ({ routes = [], onClickBack = () => {} }) => {
    const { t } = useTranslation();
    const { profile: userProfile, permissions } = useSelector(({ auth }) => auth.user);
    const { isBasicPlan } = getPlanUser(userProfile);

    const _buttonBack = () => {
        return (
            <div className="v2-btn-default --grey has-icon mr-0 btn-back" onClick={() => onClickBack(false)}>
                <IconArrow isPrev />
                {t('common:back')}
            </div>
        );
    };

    if (isBasicPlan || !checkPermission(permissions.enabled || [], PERMISSIONS.accessSettingsTab))
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <Fragment>
            <MainHeaderSettings contentLeft={_buttonBack} />
            <div className="wrapper-columns">
                <div className="container-print has-tab contents-pages maintables-page global-override">
                    <Switch>
                        {routes.map((route, i) => (
                            <Fragment key={i}>
                                <BoxTipsSetting typeId={route.boxTip} isHiddenButtonClose />
                                <Route path={route.path} component={route.component} />
                            </Fragment>
                        ))}
                    </Switch>
                </div>
            </div>
        </Fragment>
    );
};

export default SettingsGlobalOverride;
