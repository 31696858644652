import React from 'react';

const LoadingTwoStepAuthList = () => {
    return (
        <div className="wrapbox-user__frame px-0 container-print wrap-loading">
            <div className="row flex-betweenitems">
                <div className="loading --animation --line --twothird"></div>
                <div className="v2-btn-default --grey loading btn-lg">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
            <div className="row my-3">
                <div className="loading --animation --line --threefourth"></div>
            </div>
            <div className="tables table-multi-column has-text-ellipsis">
                <div className="rows --fixed --header">
                    <div className="col col-lg">
                        <p className="col-label"></p>
                        <div className="loading --animation --line --onefifth --light my-1"></div>
                        <p></p>
                    </div>
                    <div className="col col-md">
                        <p className="col-label"></p>
                        <div className="loading --animation --line --half --light my-1"></div>
                        <p></p>
                    </div>
                    <div className="col --menu"></div>
                </div>
                <div className="tables-list">
                    <div className="rows align-center">
                        <div className="col col-lg">
                            <div className="loading --animation --line --half"></div>
                        </div>
                        <div className="col col-md">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default --grey loading --icon-sm bg-white-cultured btn-plus"></div>
                        </div>
                    </div>
                    <div className="rows align-center">
                        <div className="col col-lg">
                            <div className="loading --animation --line --half"></div>
                        </div>
                        <div className="col col-md">
                            <div className="loading --animation --line --threefourth"></div>
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default --grey loading --icon-sm bg-white-cultured btn-plus"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-3">
                <div className="loading --animation --line --full"></div>
                <div className="loading --animation --line --onefourth"></div>
            </div>
        </div>
    );
};

export default LoadingTwoStepAuthList;
