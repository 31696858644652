import GdGridView from 'app/components/grid/GdGridView';
import ModalLoading from 'app/components/loading/ModalLoading';
import { CUSTOMER_GET_LIST_ESTIMATE, CUSTOMER_PERMANENTLY_DELETE_ESTIMATE } from 'app/const/Api';
import { DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { getGridColumnsCustomerEstimates } from 'app/const/customer/CustomerEstimates';
import { getDefaultParams } from 'app/const/report/ReportParams';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import { customerEstimates, handleAbortController, resetPagingLocation } from 'app/modules/customer/utils';
import NewEstimate from 'app/modules/quickadd/estimate';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { actionOpenEstimate } from 'common/redux/actions/estimateAction';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { React, useContext, useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEY_LOCATION_FILTER_SELECTED } from '../../const';
import RealtimeEstimate from './RealtimeEstimate';
import ActionHeader from './components/ActionHeader';
import { useTranslation } from 'react-i18next';

const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ESTIMATE_CUSTOMER_LIST);

const CustomerEstimates = () => {
    const { t } = useTranslation('customers');
    const refFormNewEstimate = useRef(null);
    const refLoading = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        checkedItems: { is_check_all: false, ids: [] },
        totalPage: 1,
        isLoading: true
    });
    const { id: customer_id } = useParams();
    const finalCheckedItems = state.checkedItems;
    const totalSelected = finalCheckedItems.ids.length || 0;
    const refAlert = useRef(null);
    const refOldLocation = useRef(getLocalStorage(KEY_LOCATION_FILTER_SELECTED)?.location_ids || DEFAULT_ALL);
    const dispatch = useDispatch();
    const abortController = useRef(null);

    const {
        customer: customerDataContext,
        isLoading: isLoadCustomerSuccess,
        location_ids,
        reloadCustomerPage
    } = useContext(CustomerDetailContext);

    let paramsCustomersEstimate = getLocalStorage(keyLocalStore);

    if (!paramsCustomersEstimate || paramsCustomersEstimate.customer_id !== customer_id) {
        paramsCustomersEstimate = getDefaultParams(REPORT_TYPE.ESTIMATE_CUSTOMER_LIST);
        paramsCustomersEstimate.customer_id = customer_id;
        setLocalStorage(keyLocalStore, paramsCustomersEstimate);
    }

    useEffect(() => {
        _reload(true);

        return () => {
            handleAbortController(abortController);
        };
    }, [customer_id]);

    useEffect(() => {
        if (reloadCustomerPage || (location_ids !== refOldLocation.current && !isLoadCustomerSuccess)) {
            if (!isLoadCustomerSuccess) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            _reload(true);
        }
    }, [location_ids, reloadCustomerPage]);

    const _handleRealTimeStatus = (responseStatus = {}) => {
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === responseStatus.id) return { ...item, status: responseStatus.status };
                    return item;
                })
            };
        });
    };

    function _reload(shouldResetPaging = false) {
        let initParams = paramsCustomersEstimate || getLocalStorage(keyLocalStore);
        if (shouldResetPaging)
            initParams = resetPagingLocation({
                params: initParams,
                key: keyLocalStore
            });
        initParams.state = initParams.filterTab;
        delete initParams.filterTab;
        _getListEstimates(initParams);
    }

    const _getListEstimates = (params) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _success = ({ data, total }) => {
            dispatchState({
                data: customerEstimates(data),
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total
            });
        };

        dispatchState({ checkedItems: { is_check_all: false, ids: [] }, isLoading: true });

        const _failed = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(
            CUSTOMER_GET_LIST_ESTIMATE,
            {
                data: {
                    customer_id: customer_id,
                    location_ids: location_ids || refOldLocation.current,
                    ...params
                },
                method: 'GET',
                abortController: abortController.current
            },
            _success,
            _failed
        );
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore);
        params.state = params.filterTab;
        params.deleted = params.state;
        delete params.filterTab;
        _getListEstimates(params);
    };

    const onChangeStateCheckedItems = (checkedItems) => {
        dispatchState({ checkedItems });
    };

    const _handleClickAction = (type) => {
        const ids = finalCheckedItems.ids;
        refLoading.current._open();

        switch (type) {
            case TYPE_BUTTON_ACTIONS.ACTIVE:
                _handleActionTypeInvoice(ids, type);
                break;
            case TYPE_BUTTON_ACTIONS.ARCHIVE:
                _handleActionTypeInvoice(ids, type);
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                _handleUnDelete(ids, type);
                break;
            case TYPE_BUTTON_ACTIONS.DELETE:
                _handleDeleteDocumentsCustomer(ids);
                break;
            case TYPE_BUTTON_ACTIONS.TRASH:
                _handlePermanentlyDelete(ids);
                break;
            default:
                break;
        }
    };

    const _handleActionTypeInvoice = (ids, type) => {
        clientQuery(
            CUSTOMER_GET_LIST_ESTIMATE,
            { data: { ids, type }, method: 'PUT' },
            (response) => _handleSuccessDelete(response),
            null,
            _handleSuccessFinally
        );
    };

    const _handleUnDelete = (ids, type) => {
        clientQuery(
            CUSTOMER_GET_LIST_ESTIMATE,
            { data: { ids, type }, method: 'PUT' },
            (response) => _handleSuccessDelete(response),
            null,
            _handleSuccessFinally
        );
    };

    const _handleDeleteDocumentsCustomer = (ids) => {
        clientQuery(
            CUSTOMER_GET_LIST_ESTIMATE,
            { data: { ids }, method: 'DELETE' },
            (response) => _handleSuccessDelete(response),
            null,
            _handleSuccessFinally
        );
    };

    const _handlePermanentlyDelete = (ids) => {
        clientQuery(
            CUSTOMER_PERMANENTLY_DELETE_ESTIMATE,
            { data: { ids }, method: 'DELETE' },
            (response) => _handleSuccessDelete(response),
            null,
            _handleSuccessFinally
        );
    };

    const _handleSuccessDelete = ({ message }) => {
        const params = getLocalStorage(keyLocalStore);
        params.state = params.filterTab;

        if (message.length) {
            refAlert.current.showStatusBar({ id: 'success', message, type: LIST_STATUS.SUCCESS });
        }

        _getListEstimates(params);
    };

    const _handleSuccessFinally = () => refLoading.current._close();

    function _handleAddEstimate(e) {
        e.stopPropagation();
        refFormNewEstimate.current._handleOpenForm();
    }

    function _handleClickEstimate(value) {
        dispatch(
            actionOpenEstimate({
                id: value.id,
                status: value.status,
                total: value.total,
                onUpdateSuccess: _reload,
                isEstimateCustomer: true
            })
        );
    }

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data.invoice_id,
                status: data.status,
                total: ''
            })
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.ESTIMATE_CUSTOMER_LIST,
            columnsTarget,
            currentValue,
            paramsReport: paramsCustomersEstimate,
            callBack: _handleUpdate
        });
    };

    return (
        <div className="wrapper-columns">
            <div className="container-print contents-pages gap-8">
                <div className="wrap-tables flex-column relative">
                    <AlertCustomer ref={refAlert} />
                    <ActionHeader
                        totalItems={state.total}
                        totalSelected={totalSelected}
                        isLoadCustomerSuccess={isLoadCustomerSuccess}
                        handleUpdate={_handleUpdate}
                        onClickAction={_handleClickAction}
                        onAddEstimate={_handleAddEstimate}
                        params={paramsCustomersEstimate}
                        checkedItems={finalCheckedItems}
                    />
                    <GdGridView
                        isLoading={state.isLoading}
                        msgEmpty={t('no_estimates')}
                        classTable="has-checkbox has-footer scrolls-x"
                        content={state.data}
                        showCheckBox
                        fileTranslation={'customers'}
                        checkedItems={finalCheckedItems}
                        onChangeStateCheckedItems={onChangeStateCheckedItems}
                        handleClick={_handleClickEstimate}
                        handleClickHeader={_handleActionHeader}
                        {...getGridColumnsCustomerEstimates(
                            paramsCustomersEstimate?.order,
                            paramsCustomersEstimate?.filterTab
                        )}
                        onOpenInvoice={_handleOpenInvoice}
                        isScroll
                    />
                    <ReportPagination
                        isCustomerPage
                        reportType={REPORT_TYPE.ESTIMATE_CUSTOMER_LIST}
                        totalPage={state.totalPage || 1}
                        onSelect={_handleUpdate}
                    />
                </div>
            </div>

            <ModalLoading ref={refLoading} />

            {customerDataContext && !state.isLoading && (
                <>
                    <NewEstimate
                        defaultCustomer={{
                            full_name: customerDataContext.full_name,
                            id: customerDataContext.id
                        }}
                        onAddSuccess={_reload}
                        ref={refFormNewEstimate}
                    />
                </>
            )}
            <RealtimeEstimate onUpdate={_handleRealTimeStatus} />
        </div>
    );
};

export default CustomerEstimates;
