export default function IconTrash() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 7.5H17.5L16.6528 17.6661C16.5664 18.7027 15.6999 19.5 14.6597 19.5H9.34027C8.30009 19.5 7.43356 18.7027 7.34717 17.6661L6.5 7.5Z"
                stroke="#EE4646"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.5 16.4167L10 10.5833"
                stroke="#EE4646"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.5 16.4167L14 10.5"
                stroke="#EE4646"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M4.5 7.5H19.5" stroke="#EE4646" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.9415 4.5H13.0585C13.9193 4.5 14.6836 5.05086 14.9558 5.86754L15.5 7.5H8.5L9.04415 5.86754C9.31638 5.05086 10.0807 4.5 10.9415 4.5Z"
                stroke="#EE4646"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
