import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGidColumns = (actionsStatus) => {
    const HEADER_SALES_FORECAST = {
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        jan: {
            title: 'jan',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        feb: {
            title: 'feb',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        mar: {
            title: 'mar',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        apr: {
            title: 'apr',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        may: {
            title: 'may',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        jun: {
            title: 'jun',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        jul: {
            title: 'jul',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        aug: {
            title: 'aug',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        sep: {
            title: 'sep',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        oct: {
            title: 'oct',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        nov: {
            title: 'nov',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        dec: {
            title: 'dec',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        total: {
            title: 'total',
            style: 'col col-md-plus --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_SALES_FORECAST = [
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'jan',
            title: 'jan',
            status: true
        },
        {
            id: 'feb',
            title: 'feb',
            status: true
        },
        {
            id: 'mar',
            title: 'mar',
            status: true
        },
        {
            id: 'apr',
            title: 'apr',
            status: true
        },
        {
            id: 'may',
            title: 'may',
            status: true
        },
        {
            id: 'jun',
            title: 'jun',
            status: true
        },
        {
            id: 'jul',
            title: 'jul',
            status: true
        },
        {
            id: 'aug',
            title: 'aug',
            status: true
        },
        {
            id: 'sep',
            title: 'sep',
            status: true
        },
        {
            id: 'oct',
            title: 'oct',
            status: true
        },
        {
            id: 'nov',
            title: 'nov',
            status: true
        },
        {
            id: 'dec',
            title: 'dec',
            status: true
        },
        {
            id: 'total',
            title: 'total',
            status: true
        }
    ];

    const REPORT_CONTENT_SALES_FORECAST = {
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        jan: {
            id: 'jan',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        feb: {
            id: 'feb',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        mar: {
            id: 'mar',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        apr: {
            id: 'apr',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        may: {
            id: 'may',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        jun: {
            id: 'jun',
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        jul: {
            id: 'jul',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        aug: {
            id: 'aug',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        sep: {
            id: 'sep',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        oct: {
            id: 'oct',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        nov: {
            id: 'nov',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        dec: {
            id: 'dec',
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        total: {
            id: 'total',
            style: 'col col-md-plus --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_SALES_FORECAST }),
        columns: REPORT_COLUMNS_SALES_FORECAST,
        contentConfig: REPORT_CONTENT_SALES_FORECAST
    };
};
