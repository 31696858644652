import { getUrlCustomerContact } from 'app/const/Api';
import { searchAccountCustomerCard } from 'app/const/api/V2';
import { TYPE_SEARCH_ACCOUNT_EMAIL } from 'app/const/Payments';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import FormSearchExisting from './FormSearchExisting';
import ListResultSearch from './ListResultSearch';
import { LoadingFormResult } from './Loading';
import { MERCHANT_ID } from 'app/const/Customers';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { useParams } from 'react-router-dom';

const SearchByEmail = ({
    isActive = false,
    customer = {},
    type = PAYMENT_MERCHANT_KEYWORD.STRIPE,
    onConnectSuccess = () => {}
}) => {
    const { id: customer_id } = useParams();
    const customerId = customer_id || customer?.id;
    const customerEmail = { id: customerId, email: customer?.email || '' };
    const [state, dispatchState] = useReducer(reducer, {
        selected: customerEmail,
        options: [],
        isLoading: customer.email
    });
    const { data, selected, options, isLoading } = state;
    const finalOptions = customerEmail.email ? [customerEmail, ...options] : [...options];

    const refFormSearch = useRef(null);
    const refAlert = useRef(true);
    const refFirstTime = useRef(true);

    useEffect(() => {
        isActive && customer.email && customerId && _handleSearch(customer.email);
    }, []);

    const _handleSearch = (email) => {
        const _handleSearchSuccess = ({ data }) => {
            dispatchState({ data, isLoading: false });
        };

        const _handleSearchFailed = () => {
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(
            searchAccountCustomerCard(customerId),
            { data: { type: TYPE_SEARCH_ACCOUNT_EMAIL, keyword: email, merchant: MERCHANT_ID[type] } },
            _handleSearchSuccess,
            _handleSearchFailed
        );
    };

    const _handleOpenDropdown = () => {
        if (!refFirstTime.current) return;

        const _handleGetSuccess = ({ data }) => {
            const options = [];
            data.forEach((item) => {
                if (item.email && selected.id !== item.id) options.push({ id: item.id, email: item.email });
            });
            dispatchState({ options });
        };

        const _handleGetFailed = () => {
            dispatchState({ options: [] });
        };

        const _handleGetFinally = () => {
            refFormSearch.current.removeLoading();
            refFirstTime.current = false;
        };

        clientQuery(
            getUrlCustomerContact(customerId),
            { method: 'GET' },
            _handleGetSuccess,
            _handleGetFailed,
            _handleGetFinally
        );
    };

    const _handleSelect = (id) => {
        const newSelected = id === customerEmail.id ? customerEmail : options.find((item) => item.id === id);

        if (!newSelected.email === selected.email) return;
        dispatchState({ selected: newSelected, isLoading: true });
        _handleSearch(newSelected.email);
    };

    const _handleConnectSuccess = ({ data }) => {
        onConnectSuccess(data);
    };

    const _handleConnectFailed = (message) => {
        refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
    };

    const _handleRemoveAlert = () => {
        refAlert.current.closeStatusBar();
    };

    return (
        <div className={classNames('tab-conts h-100', { 'tab-content-active': isActive })}>
            <FormSearchExisting
                ref={refFormSearch}
                title="Search by Email"
                selected={selected}
                options={finalOptions}
                disable={!customerId}
                onVisible={_handleOpenDropdown}
                onSelect={_handleSelect}
            />

            {isLoading ? (
                <LoadingFormResult />
            ) : (
                <div className="form-results">
                    <AlertCustomer ref={refAlert} />
                    <ListResultSearch
                        data={data}
                        customerId={customerId}
                        type={type}
                        onRemoveAlert={_handleRemoveAlert}
                        onConnectSuccess={_handleConnectSuccess}
                        onConnectFailed={_handleConnectFailed}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchByEmail;
