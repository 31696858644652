import React from 'react';

const IconBalloon = () => {
    return (
        <svg width={193} height={192} viewBox="0 0 193 192" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.6752 181.684H59.9531V93.2753H57.6752V181.684Z"
                fill="#77C5E3"
            />
            <path fillRule="evenodd" clipRule="evenodd" d="M94.3611 192H96.639V111.676H94.3611V192Z" fill="#77C5E3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M131.047 184.829H133.325V91.4362H131.047V184.829Z"
                fill="#77C5E3"
            />
            <path
                d="M37.2347 56.5652L59.5 78.5428L81.7649 56.5652H81.7281C83.8591 54.5497 85.7145 52.2476 87.2209 49.7213C90.1418 44.8709 91.8136 39.1955 91.8136 33.1251C91.8136 24.2584 88.2314 16.2294 82.463 10.3905C78.0541 5.93878 72.3593 2.76027 65.9847 1.45947C63.8904 1.03139 61.7226 0.80542 59.4998 0.80542C57.3504 0.80542 55.2562 1.01487 53.2354 1.41356C46.7692 2.68502 41.0011 5.88563 36.5371 10.3906C30.7504 16.2295 27.1865 24.2584 27.1865 33.1251C27.1865 39.0227 28.7663 44.5473 31.5218 49.3058C33.0833 51.9938 35.0303 54.4395 37.2715 56.5652H37.2347Z"
                fill="#5FBEBB"
            />
            <path d="M59.5 78.5428L50.8662 86.884H68.1345L59.5 78.5428Z" fill="#5FBEBB" />
            <path
                d="M109.235 56.5652L131.5 78.5428L153.765 56.5652H153.728C155.859 54.5497 157.715 52.2476 159.221 49.7213C162.142 44.8709 163.814 39.1955 163.814 33.1251C163.814 24.2584 160.231 16.2294 154.463 10.3905C150.054 5.93878 144.359 2.76027 137.985 1.45947C135.89 1.03139 133.723 0.80542 131.5 0.80542C129.35 0.80542 127.256 1.01487 125.235 1.41356C118.769 2.68502 113.001 5.88563 108.537 10.3906C102.75 16.2295 99.1865 24.2584 99.1865 33.1251C99.1865 39.0227 100.766 44.5473 103.522 49.3058C105.083 51.9938 107.03 54.4395 109.271 56.5652H109.235Z"
                fill="#E47053"
            />
            <path d="M131.5 78.5428L122.866 86.884H140.135L131.5 78.5428Z" fill="#E47053" />
            <g style={{ mixBlendMode: 'multiply' }}>
                <path
                    d="M73.2347 76.5652L95.4998 98.5428L117.765 76.5652H117.728C119.859 74.5497 121.715 72.2476 123.221 69.7213C126.142 64.8709 127.814 59.1955 127.814 53.1251C127.814 44.2584 124.231 36.2294 118.463 30.3905C114.054 25.9388 108.359 22.7603 101.985 21.4595C99.8904 21.0314 97.7226 20.8054 95.4998 20.8054C93.3504 20.8054 91.2562 21.0149 89.2354 21.4136C82.7692 22.685 77.0011 25.8856 72.5371 30.3906C66.7504 36.2295 63.1865 44.2584 63.1865 53.1251C63.1865 59.0227 64.7663 64.5473 67.5218 69.3058C69.0833 71.9938 71.0303 74.4395 73.2715 76.5652H73.2347Z"
                    fill="#F14B89"
                />
                <path d="M95.4998 98.5428L86.8662 106.884H104.135L95.4998 98.5428Z" fill="#F14B89" />
            </g>
        </svg>
    );
};

export default IconBalloon;
