import React from 'react';

const NotifySelected = ({ selected = [] }) => {
   
    function _renderUserSelected() {
        return selected.map(item => {

            return (
                <div
                    className="notify-items"
                    key={item.id}
                >
                    <div className="avt-img">
                        <img alt="" src={item.avatar} width={24} height={24} />
                    </div>
                    <p className="notify-items__name">{item.full_name}</p>
                </div>
            );
        });
    }

    return (
        <div className="wrap-content-notify">
            <div className="list-notify">
                { _renderUserSelected() }
            </div>
        </div>
    );
};

export default NotifySelected;