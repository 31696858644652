import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ADDONS_MULTIPLE_BRANCH_MANAGE } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import { ADDON_LEVEL } from '../../constants';
import { KEYWORD_MULTIPLE_BRANCH } from '../const';
import PlanStepOne from './PlanStepOne';
import PlanStepTwo from './PlanStepTwo';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_LIST_ACCOUNT_PLAN } from 'app/const/api/V2';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';
import { ACCESS_MANAGE_BRANCH } from 'app/const/Permissions';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const MultipleBranchPlan = () => {
    const { t } = useTranslation('addons');
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const isBranch = useSelector(({ auth }) => auth.user.company.is_branch);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan } = getPlanUser(profileData);
    const isPermissionMultipleBranch =
        checkPermission(permissionsList, ACCESS_MANAGE_BRANCH) && !isBranch && isGrowthPlan;
    const history = useHistory();
    const refStepOne = useRef(null);

    const PLAN_STEP = {
        ONE: {
            step: 1,
            handleBack: () => history.push(addBranchPath(ADDONS_MULTIPLE_BRANCH_MANAGE))
        },
        TWO: {
            step: 2,
            handleBack: () => dispatchState({ ...PLAN_STEP.ONE })
        }
    };

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        planSelected: {},
        plans: {},
        isFetchedPlan: false,
        ...PLAN_STEP.ONE
    });
    const { step, handleBack: _handleBack, planSelected, isFetchedPlan, plans } = state;

    useEffect(() => {
        if (!isPermissionMultipleBranch) return;
        _fetchDataPlans();
    }, []);

    const _fetchDataPlans = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, plans: data, isFetchedPlan: true }));
        };

        const _handleFail = (err) => {
            checkAccessFail(err, ACCESS_MANAGE_BRANCH);
        };

        clientQuery(GET_LIST_ACCOUNT_PLAN, { method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleChangeStep = () => {
        const valueStepOne = refStepOne.current?._getValue();
        dispatchState((prev) => ({ ...prev, ...PLAN_STEP.TWO, planSelected: valueStepOne }));
    };

    const _renderContent = () => {
        switch (step) {
            case PLAN_STEP.ONE.step:
                return (
                    <PlanStepOne
                        ref={refStepOne}
                        onContinue={_handleChangeStep}
                        initialData={planSelected}
                        isFetchedPlan={isFetchedPlan}
                        plans={plans}
                    />
                );
            case PLAN_STEP.TWO.step:
                return <PlanStepTwo data={state} />;
            default:
                return null;
        }
    };

    if (!isPermissionMultipleBranch) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div className={classNames('addons-wrap__container', { 'branch-plan': step === PLAN_STEP.ONE.step })}>
            <div className="header">
                <div className="v2-btn-default has-icon --grey" onClick={_handleBack}>
                    <IconArrow isPrev />
                    {t('back')}
                </div>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    <Fragment>
                        <div className="boxs boxs--no-border mb-2 has-svg-color">
                            <AddonsBoxHeader
                                keyword={KEYWORD_MULTIPLE_BRANCH}
                                name={t('multiple_branch')}
                                status={1}
                                level={ADDON_LEVEL.GROWTH}
                                isHideCheckbox
                            />
                        </div>
                        {_renderContent()}
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default MultipleBranchPlan;
