/* eslint-disable prefer-const */
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';

import IconArrowDown from 'assets/icon/IconArrowDown';
import { checkDisableTimeString } from 'common/utils/TimeUtils';
import DropdownPopper from '../dropdown/DropdownPopper';

const InputTime = forwardRef(({ defaultTime = '', prefix = '', timesDisable, onBlur = () => {}, ...props }, ref) => {
    const [time, setTime] = useState(defaultTime);
    const refDropdown = useRef(null);
    useImperativeHandle(ref, () => ({ setValue: (value) => setTime(value) }));

    const fixTimeFormat = (input) => {
        let timeString = input.trim().toUpperCase();

        // Extract AM/PM part if present, and then remove it from the time string
        const amPmMatch = timeString.match(/AM|PM/);
        const amPm = amPmMatch ? amPmMatch[0] : 'AM'; // Default to AM if not specified
        timeString = timeString.replace(/AM|PM/, '');

        // Split the remaining timeString into hours and minutes
        let [hours, minutes] = timeString.split(':');

        // Parse hours and minutes, defaulting to 0 if not present or invalid
        hours = parseInt(hours, 10);
        minutes = parseInt(minutes, 10);
        if (isNaN(hours) || hours < 1 || hours > 12) hours = 0;
        if (isNaN(minutes) || minutes < 0 || minutes > 59) minutes = 0;

        // Correct the hours and minutes to two-digit format
        hours = hours.toString().padStart(2, '0');
        minutes = minutes.toString().padStart(2, '0');

        // Construct the corrected time string
        return `${hours}:${minutes} ${amPm}`;
    };

    const isValidTime = (timeString) => {
        const regex = /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/;
        return regex.test(timeString);
    };

    const handleBlur = () => {
        const fixedTime = fixTimeFormat(time);
        const isValid = isValidTime(fixedTime);
        const timeResult = isValid ? fixedTime : defaultTime;
        isValid ? setTime(fixedTime) : setTime(defaultTime);
        onBlur(timeResult, prefix);
    };

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleBlur();
            e.target.blur();
        }
    };

    const handleChange = (e) => {
        setTime(e.target.value);
    };

    const _handleSelect = (item) => {
        setTime(item.label);
        onBlur(item.label, prefix);
        refDropdown.current._close();
    };

    const timeList = useMemo(() => {
        const timeList = [];
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 60; j += 10) {
                const hour = i === 0 ? 12 : i < 10 ? `0${i}` : `${i}`;
                const minute = j < 10 ? `0${j}` : `${j}`;
                const period = i < 12 ? 'AM' : 'PM';
                const formattedHour = i <= 12 ? hour : i - 12 < 10 ? `0${i - 12}` : `${i - 12}`;
                const label = `${formattedHour}:${minute} ${period}`;
                const ref = React.createRef(); // create a ref for each time
                timeList.push({ label, ref });
            }
        }
        return timeList;
    }, []);

    const _handleOpenDropdown = () => {
        let selectedItem = timeList.find((item) => item.label === time);
        if (!selectedItem) {
            const [currentTimeStr, period] = time.split(' ');
            let [hours, minutes] = currentTimeStr.split(':').map(Number);
            hours = period === 'PM' ? hours + 12 : hours;
            const currentTimeValue = hours * 60 + minutes;

            let minDifference = Infinity;
            timeList.forEach((item) => {
                const [itemTimeStr, itemPeriod] = item.label.split(' ');
                let [itemHours, itemMinutes] = itemTimeStr.split(':').map(Number);
                itemHours = itemPeriod === 'PM' ? itemHours + 12 : itemHours;
                const itemTimeValue = itemHours * 60 + itemMinutes;
                const difference = Math.abs(currentTimeValue - itemTimeValue);
                if (difference < minDifference) {
                    minDifference = difference;
                    selectedItem = item;
                }
            });
        }

        selectedItem?.ref.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isCalculateWidth
            isCheckHidden
            onOpen={_handleOpenDropdown}
            wrapperClassName="v2-dropdown"
            buttonClassName="dropbtn items"
            wrapperListClass="v2-dropdown__menu content-full --right scrolls"
            customStyle={{ zIndex: 99999 }}
            customButton={
                <>
                    <div className="search-input">
                        <input
                            type="text"
                            value={time}
                            className="field-input"
                            placeholder="00:00 AM"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={_handleKeyDown}
                            {...props}
                        />
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </div>
                </>
            }
        >
            <ul>
                {timeList?.map((item, index) => (
                    <li
                        ref={item.ref}
                        key={index}
                        className={classNames('items', {
                            'is-disable': timesDisable && checkDisableTimeString(item.label, timesDisable)
                        })}
                        onClick={() => _handleSelect(item)}
                    >
                        {item.label}
                    </li>
                ))}
            </ul>
        </DropdownPopper>
    );
});

export default InputTime;
