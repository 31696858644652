import React, { createContext, useContext, useReducer } from 'react';
import { TRACKER_CALENDAR_VIEWS } from 'app/modules/tracker/constant';

const TrackingTimeContext = createContext();

export const useTrackingTime = () => {
    const context = useContext(TrackingTimeContext);
    if (!context) {
        throw new Error('useTrackingTime must be used within a TrackingTimeProvider');
    }
    return context;
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_USER':
            return { ...state, ...action.payload };
        case 'CHANGE_VIEW':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
export const TrackingTimeProvider = ({ data = {}, children }) => {
    const [state, dispatchState] = useReducer(reducer, { ...data, view: TRACKER_CALENDAR_VIEWS.DAY });

    const changeUser = (user) => {
        dispatchState({ type: 'CHANGE_USER', payload: user });
    };

    const changeView = (view) => {
        dispatchState({ type: 'CHANGE_VIEW', payload: view });
    };

    return (
        <TrackingTimeContext.Provider value={{ ...state, changeUser, changeView }}>
            {children}
        </TrackingTimeContext.Provider>
    );
};
