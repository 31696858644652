import moment from 'moment';

import { CHART_LEGEND_LIMIT_NUMBER, COMMON, DATE_FORMAT, DEFAULT_ALL, TOTAL_BILLED } from 'app/const/App';
import { LIST_PARAM_FIELD, MONTH, REPORTS_REORDER_COLUMNS, REPORT_LIMIT, TOTAL, YEAR } from 'app/const/Reports';
import {
    URL_EXPORT_PAYMENT_CLIENT,
    URL_EXPORT_PAYMENT_ITEMS,
    URL_EXPORT_PAYMENT_REVENUES_SOURCE,
    URL_EXPORT_PAYMENT_REVENUES_STAFF,
    URL_EXPORT_PAYMENT_SERVICE,
    URL_EXPORT_PAYMENT_TAGS
} from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getColumnsParams } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getLocalStorage, setLocalStorage } from './LocalStorageUtils';
import i18n from 'assets/i18n';
import { PAYMENT_METHODS, SALE } from 'app/const/report/ReportFilter';

export const _getDataExport = (key) => {
    switch (key) {
        case REPORT_TYPE.REVENUE_BY_CLIENT_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_CLIENT,
                pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_CLIENT_DETAIL
            };
        case REPORT_TYPE.REVENUE_BY_SERVICE_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_SERVICE,
                pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_SERVICE_DETAIL
            };
        case REPORT_TYPE.REVENUE_BY_ITEM_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_ITEMS,
                pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_ITEMS_DETAIL
            };
        case REPORT_TYPE.REVENUE_BY_STAFF_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_REVENUES_STAFF,
                pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF_DETAIL
            };
        case REPORT_TYPE.REVENUE_BY_TAGS_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_TAGS,
                pageExport: LIST_EXPORT.EXPORT_REPORT_TAGS_DETAIL
            };
        case REPORT_TYPE.REVENUE_BY_SOURCE_MONTH_TAB:
            return {
                urlExport: URL_EXPORT_PAYMENT_REVENUES_SOURCE,
                pageExport: LIST_EXPORT.EXPORT_REPORT_SOURCE_DETAIL
            };
        default:
            return {};
    }
};

export const _formatAddressQBReport = (location = {}) => {
    const { street1 = '', line1 = '', street2 = '', line2 = '', city = '', state = '', zip = '' } = location || {};
    const street1Final = `${street1 || line1}`;
    const street2Final = `${street2 || line2}`;

    const streetFinal = `${street1Final}${street1Final && street2Final ? ' ' + street2Final : street2Final}`;
    let fullLocation = '';

    if (city) {
        fullLocation += `${city} `;
    }
    if (state) {
        fullLocation += `${state.toUpperCase()}`;
    }
    if (zip) {
        fullLocation += ` ${zip}`;
    }

    return { street: streetFinal, fullLocation };
};

export const combineString = (arr = []) => {
    if (!Array.isArray(arr)) throw new Error('Must be array');

    if (!arr.length) return '';

    return arr.reduce(function (p, d, i) {
        if (!d) return p;
        if (!p && i) return p + d;

        return p + ', ' + d;
    });
};

export const handleToggleSideBar = (toggled) => {
    const mainContent = document.querySelector('.container-wrap');
    const mainSidebar = document.querySelector('.sidebar-menu');
    if (!mainContent || !mainSidebar) return;
    if (!toggled) {
        mainSidebar.classList.add('is-hide-sidebar');
        mainContent.classList.add('is-hide-panel');
    } else {
        mainSidebar.classList.remove('is-hide-sidebar');
        mainContent.classList.remove('is-hide-panel');
    }
};

export const getFormatDateRevenueDetail = (month, year) => {
    return [year, moment().month(month).format('M') - 1];
};

export const removeObjParams = (object = {}, arrayParam = []) => {
    arrayParam.forEach((e) => {
        object[e] && delete object[e];
    });
    return object;
};

export const getLocalParamsReport = (keyLocal, typeReport) => {
    const defaultParams = getDefaultParams(typeReport) || {};
    const params = getLocalStorage(keyLocal);

    try {
        if (typeof params === 'object' && !Array.isArray(params) && params !== null) {
            const newParamsReport = { ...params };

            for (const key in defaultParams) {
                if (Object.hasOwnProperty.call(defaultParams, key)) {
                    const element = defaultParams[key];
                    if (!Object.hasOwnProperty.call(newParamsReport, key)) {
                        newParamsReport[key] = element;
                    } else if (element === DEFAULT_ALL) {
                        // If default params is "-1"
                        const newParamValue = newParamsReport[key];
                        let shouldResetParam = !Array.isArray(newParamValue) && isNaN(newParamValue);
                        // check if param is string contains ids or array of ids
                        if (typeof newParamValue === 'string') {
                            const ids = newParamValue.split(',');
                            if (ids.length > 1) shouldResetParam = false;
                        }

                        if (shouldResetParam || newParamValue === null) newParamsReport[key] = element;
                    }
                }
            }

            setLocalStorage(keyLocal, newParamsReport);
            return newParamsReport;
        } else {
            setLocalStorage(keyLocal, defaultParams);
            return defaultParams;
        }
    } catch (error) {
        console.error(`Error getting localStorage item ${keyLocal}: ${error}`);
        setLocalStorage(keyLocal, defaultParams);
        return defaultParams;
    }
};

export const getParamsRevenuePage = ({ key = '', paramsReport = {} }) => {
    const selectDate = getFormatDateRevenueDetail(key, paramsReport.year);
    const typeDate = key === TOTAL ? YEAR : MONTH;

    return {
        start: moment(selectDate).clone().startOf(typeDate).format(DATE_FORMAT),
        end: moment(selectDate).clone().endOf(typeDate).format(DATE_FORMAT),
        sort_by: paramsReport.sort_by,
        payment_for: paramsReport.payment_for,
        payment_methods: paramsReport.payment_methods,
        sale: paramsReport.sale,
        total: '1',
        order: COMMON.DATE.concat(' ', COMMON.ASC),
        offset: 0,
        limit: REPORT_LIMIT
    };
};

export const getListYear = ({ listYear = [] }) => {
    return {
        listTenYearFirst: listYear.slice(-CHART_LEGEND_LIMIT_NUMBER),
        listSubYear: listYear.slice(0, listYear.length - CHART_LEGEND_LIMIT_NUMBER)
    };
};

export const convertParamFields = ({
    params = {},
    isEmptyFields = false,
    limit = REPORT_LIMIT,
    offset = 0,
    isCheckLimitOffset = true,
    isPaymentMethods = false
}) => {
    const newParams = { ...(params || {}) };
    if (isCheckLimitOffset) {
        newParams.limit = newParams.limit ?? limit;
        newParams.offset = newParams.offset ?? offset;
    }
    if (isPaymentMethods) {
        if (newParams.sale === TOTAL_BILLED) {
            delete newParams.payment_methods;
        } else {
            newParams.payment_methods = newParams.payment_methods?.toString() || '';
        }
    }
    let fieldsToAdd = '';

    LIST_PARAM_FIELD.forEach((field) => {
        // eslint-disable-next-line no-prototype-builtins
        if (newParams.hasOwnProperty(field)) {
            delete newParams[field];
            !isEmptyFields && (fieldsToAdd += `${field},`);
        }
    });

    return { ...newParams, fields: fieldsToAdd };
};

export const initStateRevenueReport = ({ keyCurrentTab = '', monthTab = '', detailTab = '' }) => {
    return {
        [monthTab]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        [detailTab]: {
            data: [],
            total: 0,
            rowTotal: [],
            isLoading: true
        },
        refreshScreen: 0,
        isLoadMore: false,
        changeTab: 0,
        currentTab: getLocalStorage(keyCurrentTab) || monthTab,
        reloadFilter: false,
        rerender: false
    };
};

export const createRowTotal = ({
    data = {},
    columns = [],
    title = '',
    isGetColumnId = false,
    isActiveQuickBook = false,
    typeReport = ''
}) => {
    const totalColumns = [];
    const setCol = new Set(columns);
    const columnReOrder = REPORTS_REORDER_COLUMNS[typeReport];
    const isReOrder = !!columnReOrder;

    (isReOrder ? columnReOrder : columns).forEach((column) => {
        const finalColumn = isGetColumnId ? column.id : column;
        const totalAmount = data?.[finalColumn];

        if (isReOrder && !setCol.has(column)) return;
        if (typeof totalAmount !== 'undefined') {
            totalColumns.push({
                id: finalColumn,
                isShow: true,
                totalAmount,
                isCurrency: true
            });
        } else {
            totalColumns.push({ id: finalColumn, isShow: true });
        }
    });

    if (isActiveQuickBook) {
        totalColumns.push({ id: 'qb_sync', isShow: true });
    }
    if (!!totalColumns.length) totalColumns[0].title = title;
    return totalColumns;
};

export const getListRevenueSuccess = ({
    prev = {},
    response = {},
    changeTab = 0,
    keyTotalRow = '',
    monthTab = '',
    titleTotalRow = ''
}) => {
    const { data, total } = response;
    const { currentTab, changeTab: prevChangeTab = 0, refreshScreen = 0 } = prev;
    const checkDifferentTabs = !!changeTab && prevChangeTab !== changeTab;
    const newTotal = total ?? prev[currentTab]?.total ?? 0;
    const textPlural = newTotal > 1 ? 's' : '';

    return {
        ...prev,
        [currentTab]: {
            isLoading: false,
            data: checkDifferentTabs ? data : [...(prev[currentTab]?.data || []), ...data],
            total: newTotal,
            rowTotal: prev.isLoadMore
                ? prev[currentTab]?.rowTotal || []
                : createRowTotal({
                      data: response[keyTotalRow[currentTab]],
                      columns: getColumnsParams(currentTab),
                      title:
                          currentTab === monthTab
                              ? i18n.t('report:total_rows', {
                                    number: newTotal,
                                    title: i18n.t(`report:${titleTotalRow}${textPlural}`)
                                })
                              : i18n.t(`report:total_payment${textPlural}`, { number: newTotal })
                  })
        },
        refreshScreen: refreshScreen + 1,
        isLoadMore: false
    };
};

export const getTitleRowTotal = ({ fileTranslation = 'report', title = '', total = 0 }) => {
    return i18n.t(`${fileTranslation}:total_rows`, {
        number: total,
        title: i18n.t(`${fileTranslation}:${title}${total > 1 ? 's' : ''}`)
    });
};

export const getNewListFilterRevenue = ({
    listFilter = [],
    keyLocalStore = '',
    currentTab = '',
    keyCurrentTab = '',
    detailTab = ''
}) => {
    if (!listFilter?.length) return [];
    const finalParamsSale = getLocalStorage(keyLocalStore)?.[SALE];
    const finalCurrentTab = currentTab || getLocalStorage(keyCurrentTab);

    return [...listFilter].filter((item) => {
        const isTotalBilledAndPaymentMethods = finalParamsSale === TOTAL_BILLED && item === PAYMENT_METHODS;
        const isDetailTabAndYear = finalCurrentTab === detailTab && item === YEAR;

        return !isTotalBilledAndPaymentMethods && !isDetailTabAndYear;
    });
};
