import React, { forwardRef, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDModalWarning from 'app/components/modal/ModalWarning';
import { reducer } from 'app/const/Reducer';
import AmountList from './AmountList';
import { RESOURCE_TYPES } from '../../constant';
import ButtonSave from 'app/components/button/ButtonSave';

const ResourceAreaRight = forwardRef(
    (
        {
            isDisableEdit = false,
            isHideEdit = false,
            onGetResources = () => {},
            onUpdateResource = () => {},
            onDeleteResource = () => {},
            onToggleUpdate = () => {}
        },
        ref
    ) => {
        const { t } = useTranslation('common');
        const [state, dispatchState] = useReducer(reducer, { data: [], forceReload: 0 });
        const { data, forceReload } = state;
        const refWrapper = useRef(null);
        const refConfirmDelete = useRef(null);
        const refButtonSave = useRef(null);

        useImperativeHandle(ref, () => ({
            forceReload: () => {
                dispatchState((prevState) => ({ ...prevState, forceReload: new Date().getTime() }));
            }
        }));

        useLayoutEffect(() => {
            const results = [];
            const data = onGetResources() || [];

            data.sort((a, b) => a?._resource?.extendedProps?.start - b?._resource?.extendedProps?.start);
            data.forEach((item) => {
                const items = item.getChildren() || [];
                const extendedProps = item._resource.extendedProps || {};
                const isExpanded = extendedProps.expanded || false;
                results.push({ isGroupExpander: !!items.length, _resource: item._resource });
                if (isExpanded) {
                    items.sort((a, b) => a?._resource?.extendedProps?.start - b?._resource?.extendedProps?.start);
                    results.push(...items);
                }
            });

            dispatchState((prevState) => ({ ...prevState, data: results }));
        }, [forceReload]);

        const _handleToggleActive = () => {
            if (!refWrapper.current) return;
            refWrapper.current.querySelector('.v2-dropdown.track-col.active')?.classList?.remove('active');
        };

        const _handleDelete = (idResource = '', type = RESOURCE_TYPES.CHILDREN) => {
            const isGroup = type === RESOURCE_TYPES.GROUP;
            refConfirmDelete.current?._open({
                id: idResource,
                title: isGroup ? t('delete_all_entry') : t('delete_entry'),
                subDescription: isGroup ? t('are_you_sure_delete_all_entry') : t('are_you_sure_delete_entry'),
                dataConfirm: { idResource, type }
            });
        };

        const _handleConfirmDelete = (idResource = '', dataConfirm = null) => {
            onDeleteResource(idResource, dataConfirm.type, () => {
                refConfirmDelete.current?._close();
            });
        };

        const Footer = (props) => {
            return (
                <div className="footer-modal footer-hasbtn btn-close">
                    <div className="v2-btn-default --transparent" onClick={props.onClose}>
                        {t('cancel')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        title={t('yes')}
                        wrapClass="v2-btn-main ml-2"
                        onSave={() => _handleConfirmDelete(props.dataConfirm.idResource, props.dataConfirm)}
                    />
                </div>
            );
        };

        return (
            <>
                <div ref={refWrapper} className="col-right">
                    <div className="rows --header">
                        <div className="col col-amount">
                            <p className="col-label">{t('amount')}</p>
                        </div>
                    </div>
                    <AmountList
                        data={data}
                        isDisableEdit={isDisableEdit}
                        isHideEdit={isHideEdit}
                        onToggleActive={_handleToggleActive}
                        onDeleteResource={_handleDelete}
                        onUpdateResource={onUpdateResource}
                        onToggleUpdate={onToggleUpdate}
                    />
                </div>
                <GDModalWarning
                    ref={refConfirmDelete}
                    modalClassName="modal container-modal modal-confirm-delete open"
                    headerClassName="header-modal"
                    title={t('delete_entry')}
                    descriptionClassName="text"
                    buttonConfirm
                    onConfirm={_handleConfirmDelete}
                    footer={<Footer />}
                />
            </>
        );
    }
);

export default ResourceAreaRight;
