import { LINK_CDN_IMG } from 'app/const/URL';
import React from 'react';

export const AuthBanner = () => {
    return (
        <section className="banner">
            <div className="container">
                <div id="scene" className="scene">
                    <div className="layer" data-depth="0.015">
                        <img
                            src={`${LINK_CDN_IMG}background_right.png`}
                            className="background_right"
                            alt="background"
                        />
                    </div>
                    <div className="layer" data-depth="0.015">
                        <img src={`${LINK_CDN_IMG}background.png`} className="background" alt="background" />
                    </div>
                    <div className="layer" data-depth={0}>
                        <img src={`${LINK_CDN_IMG}water.png`} className="water" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.025">
                        <img src={`${LINK_CDN_IMG}right-middle.png`} className="right-middle" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.01">
                        <img src={`${LINK_CDN_IMG}mitddle-stik.png`} className="mitddle-stik" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.008">
                        <img src={`${LINK_CDN_IMG}left-platform.png`} className="left-platform" alt="background" />
                    </div>
                    <div className="layer" data-depth=".01">
                        <img
                            src={`${LINK_CDN_IMG}left-bottom-corner.png`}
                            className="left-bottom-corner"
                            alt="background"
                        />
                    </div>
                    {/* particles */}
                    <div className="particles">
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                        <div className="circle-container">
                            <div className="circle" />
                        </div>
                    </div>
                    <div className="layer" data-depth="0.01">
                        <img src={`${LINK_CDN_IMG}left-top-corner.png`} className="left-top-corner" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.01">
                        <img
                            src={`${LINK_CDN_IMG}right-bottom-corner.png`}
                            className="right-bottom-corner"
                            alt="background"
                        />
                    </div>
                    <div className="layer" data-depth="0.011">
                        <img src={`${LINK_CDN_IMG}banana.png`} className="banana" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.02">
                        <img src={`${LINK_CDN_IMG}flowers.png`} className="flowers" alt="background" />
                    </div>
                    <div className="layer" data-depth="0.06">
                        <img
                            src={`${LINK_CDN_IMG}right-top-corner.png`}
                            className="right-top-corner"
                            alt="background"
                        />
                    </div>
                    {/* noise */}
                    <div className="noise">
                        <svg viewBox="0 0 277 277" xmlns="http://www.w3.org/2000/svg">
                            <filter id="noiseFilter">
                                <feTurbulence
                                    type="fractalNoise"
                                    baseFrequency="4.16"
                                    numOctaves={6}
                                    stitchTiles="stitch"
                                />
                            </filter>
                            <rect width="100%" height="100%" filter="LINK_CDN_IMG(lter)" />
                        </svg>
                    </div>
                    <canvas className="canvas" />
                </div>
            </div>
        </section>
    );
};
