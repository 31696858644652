import React, { createContext, useReducer } from 'react';
import { TYPE_USER_SETTING } from '../const';
import { reducer } from 'app/const/Reducer';

export const UsersContext = createContext();
const UsersProvider = ({ children }) => {
    const [state, dispatchState] = useReducer(reducer, {
        typeUser: TYPE_USER_SETTING.USER.id,
        isLoading: false
    });
    const { isLoading: finalIsLoading } = state;

    const _handleChangeType = ({ id }) => {
        dispatchState({ typeUser: id, isLoading: true });
    };

    const _handleChangeLoading = (value = false) => {
        finalIsLoading !== value && dispatchState({ isLoading: value });
    };

    return (
        <div className="contents-pages container-column contents-user">
            <UsersContext.Provider
                value={{ ...state, onChangeType: _handleChangeType, onChangeLoading: _handleChangeLoading }}
            >
                {children}
            </UsersContext.Provider>
        </div>
    );
};

export default UsersProvider;
