import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import EmojiMart from 'app/components/emoji/index';
import IconEmoji from 'assets/icon/IconEmoji';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';

const EmojiPopper = ({
    onChange = () => {},
    onClose = () => {},
    onOpen = () => {},
    notCloseOnClick,
    limit = 5,
    listEmoji = [],
    wrapperClassName = 'v2-dropdown select-emoji',
    wrapperListClass = 'v2-dropdown__menu',
    buttonClassName = 'dropbtn v2-btn-default --icon-lg tooltip',
    placementTooltip = 'top',
    placementDropdown = 'bottom',
    isDisable
}) => {
    const refDropdown = useRef(null);
    const isArray = Array.isArray(listEmoji);
    const refChanged = useRef(false);
    const _closeDropdown = () => {
        refDropdown.current._close();
    };

    const _addEmoji = (value) => {
        !notCloseOnClick && _closeDropdown();
        if (isArray && listEmoji.length >= limit) return;
        refChanged.current = true;
        onChange([...listEmoji, value.native]);
    };

    const _removeEmoji = (index) => {
        refChanged.current = true;
        const newList = [...listEmoji];
        newList.splice(index, 1);
        onChange(newList);
    };

    const renderListEmoji = () => {
        return listEmoji.map((icon, index) => (
            <div className="emjoi-list__item" key={index.toString()}>
                {icon}
                <span className="emoji-close" onClick={() => _removeEmoji(index)}>
                    <IconClose isSmall />
                </span>
            </div>
        ));
    };

    const handleOnOpen = () => {
        refChanged.current = false;
        onOpen();
    };

    const handleOnClose = () => {
        refChanged.current && onClose();
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            wrapperClassName={wrapperClassName}
            wrapperListClass={wrapperListClass}
            buttonClassName={buttonClassName}
            dropdownIcon={
                <Fragment>
                    <IconEmoji />
                    <span className={classNames(['tooltiptext', placementTooltip])}>Emoji</span>
                </Fragment>
            }
            strategy="fixed"
            isUseToggle
            onClose={handleOnClose}
            onOpen={handleOnOpen}
            placement={placementDropdown}
            isDisable={isDisable}
        >
            <Fragment>
                {isArray && listEmoji.length > 0 && <div className="emjoi-list">{renderListEmoji()}</div>}
                <EmojiMart handleOnClick={_addEmoji} />
            </Fragment>
        </DropdownPopper>
    );
};

export default React.memo(EmojiPopper);
