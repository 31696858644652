import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken } from 'common/utils/ApiUtils';

import {
    SETTINGS_GET_LIST_TEMPLATE,
    SETTINGS_GET_DETAIL_TEMPLATE,
    SETTINGS_UPDATE_DETAIL_TEMPLATE,
    SETTINGS_PREVIEW_TEMPLATE,
    GET_LIST_TEMPLATE_REMINDER,
    GET_LIST_TEMPLATE_CONFIRMATION,
    SETTINGS_GET_LIST_BROADCAST_TEMPLATE,
    SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE
} from 'app/const/Api';

import { TYPE_SETTING_TEMPLATE } from 'common/redux/actions/settings/templateAction';

export function* getListServiceTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_TEMPLATE, params);
        if (response && response.success) {
            const newData = [...response.data];
            newData.forEach((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDetailServiceTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_DETAIL_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateDetailServiceTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_UPDATE_DETAIL_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* previewServiceTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_PREVIEW_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListTemplateReminder({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_TEMPLATE_REMINDER, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_REMINDER_SUCCESS,
                payload: response
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListTemplateConfirmation({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_TEMPLATE_CONFIRMATION, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_CONFIRMATION_SUCCESS,
                payload: response
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListTemplateBroadcast({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_BROADCAST_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_BROADCAST_SUCCESS,
                payload: response
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
export function* getListTemplateEmailInbox({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_EMAIL_INBOX_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({
                type: TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_EMAIL_INBOX_SUCCESS,
                payload: response
            });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
