import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import React, { Fragment, useRef } from 'react';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { useTranslation } from 'react-i18next';
import { GdDialPad } from 'app/modules/layouts/GDVoip/Dialpad';
import IconError from 'assets/icon/IconError';

export const DigitsAutoAttendant = ({ item, onChangeDigits = () => {} }) => {
    const { t } = useTranslation('addons');
    const refDigits = useRef(null);
    const refError = useRef(null);
    const refDropdown = useRef(null);
    const { id, digits } = item;

    const _customButton = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis fw-normal black">{digits}</span>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _handleSave = () => {
        const value = refDigits.current.value.trim();
        if (!Number.isInteger(value * 1)) {
            refDigits.current.classList.add('field-error');
            refError.current.classList.remove('dp-hide');
            setTimeout(() => {
                refDigits.current?.classList?.remove('field-error');
                refError.current?.classList?.add('dp-hide');
            }, 2000);
            return;
        }
        _handleClose();
        onChangeDigits({ id, value });
    };

    const _handleClickDialPad = (value) => {
        refDigits.current.value += value;
    };

    const _handleClose = () => {
        refDropdown.current?._close();
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            wrapperClassName="v2-dropdown"
            customButton={_customButton()}
            buttonClassName="dropbtn v2-btn-default --transparent w-100 pl-5"
            wrapperListClass="v2-dropdown__menu box-custom-extension"
            isUseToggle
            isUseMouseDownOutSide
        >
            <div className="flex-column">
                <div className="wrapper-call scrolls shadow-none">
                    <GdDialPad id="dialpad_attendant" onClickNumber={_handleClickDialPad} />
                    <div className="flex-column gap-2 p-3 border-top-grey-verylight-brown fs-14">
                        <span>{t('custom_value')}</span>
                        <input
                            ref={refDigits}
                            type="text"
                            className="field-input"
                            placeholder="001"
                            defaultValue={digits}
                        />
                        <p ref={refError} className="txt-incorrect dp-hide">
                            <IconError />
                            {t('digits_is_not_valid')}
                        </p>
                    </div>
                </div>
                <div className="flexcenter justify-end border-top-grey-verylight-brown gap-8 p-3">
                    <div className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('cancel')}
                    </div>
                    <div className="v2-btn-main" onClick={_handleSave}>
                        {t('save')}
                    </div>
                </div>
            </div>
        </DropdownPopper>
    );
};
