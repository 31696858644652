import { reducer } from 'app/const/Reducer';
import FilterAssign from 'app/modules/calendar/components/sidebar/components/tasks/FilterAssign';
import TaskList from 'app/modules/calendar/components/sidebar/components/tasks/List';
import StatusTabs from 'app/modules/calendar/components/sidebar/components/tasks/StatusTabs';
import { STATUS } from 'app/modules/calendar/const/SideBar';
import classNames from 'classnames';
import { actionGetCustomerListTask } from 'common/redux/actions/customers/jobAction';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sortTaskList } from '../../utils';

const TabTaskList = ({ activeTab }) => {
    const { t } = useTranslation(['calendar']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        tab: STATUS.ACTIVE,
        assignee: null,
        tasks: [],
        isLoading: false,
        showMore: false,
        isLoadingLoadMore: false
    });
    const { taskUpdateStatus, taskUpdateRealtime } = useSelector(({ listTaskReducer }) => listTaskReducer);
    const { tasks: finalTasks, assignee: finalAssignee, tab: finalTab, isLoading: finalLoading } = state;

    useEffect(() => {
        if (finalAssignee) _fetchListTask();
    }, [finalAssignee, finalTab]);

    useEffect(() => {
        const dataUpdate = taskUpdateRealtime || taskUpdateStatus || null;
        if (dataUpdate && finalAssignee) _handleUpdateRealtime(dataUpdate);
    }, [taskUpdateRealtime, taskUpdateStatus]);

    const _handleUpdateRealtime = (taskUpdate) => {
        let tasks = [...(finalTasks || [])];
        const isTabActive = finalTab === STATUS.ACTIVE;

        switch (taskUpdate.typeAction) {
            case 'create':
                if (isTabActive) {
                    const hadTask = tasks.some((task) => task.id === taskUpdate.id);
                    !hadTask && tasks.unshift(taskUpdate);
                }
                break;
            case 'status':
                const isMultipleUpdate = taskUpdate.isMultipleUpdate;
                const isUpdateActive = taskUpdate.active === STATUS.ACTIVE;

                if ((isUpdateActive && isTabActive) || (!isUpdateActive && !isTabActive)) {
                    tasks = _handleAdd(tasks, isMultipleUpdate ? taskUpdate.data : taskUpdate);
                }

                if ((isUpdateActive && !isTabActive) || (!isUpdateActive && isTabActive)) {
                    tasks = _handleDelete(
                        tasks,
                        isMultipleUpdate ? taskUpdate.data.map((item) => item.id) : taskUpdate.id
                    );
                }
                break;
            case 'update':
                const isUpdate = state.assignee === taskUpdate.assignee.id;

                if (!isUpdate) {
                    tasks = tasks.filter((item) => item.id !== taskUpdate.id);
                } else {
                    const index = tasks.findIndex((item) => item.id === taskUpdate.id);
                    const newTask = { ...taskUpdate };
                    delete newTask['typeAction'];
                    index >= 0 ? (tasks[index] = taskUpdate) : tasks.unshift(newTask);
                }
                break;
            case 'delete':
                tasks = _handleDelete(tasks, taskUpdate.id);
                break;
            default:
                break;
        }

        dispatchState({ tasks: sortTaskList(tasks) });
    };

    const _handleDelete = (tasks, id) => {
        return tasks.filter((item) => {
            return Array.isArray(id) ? !id.includes(item.id) : item.id !== id;
        });
    };

    const _handleAdd = (tasks, newTask) => {
        const result = [...tasks];
        Array.isArray(newTask) ? result.unshift(...newTask) : result.unshift(newTask);
        return result;
    };

    const handleOnScrollContent = (e) => {
        if (!finalLoading && isScrollToEndBottom(e.target) && state.showMore) {
            dispatchState({ isLoadingLoadMore: true });
            _fetchListTask();
        }
    };

    function _fetchListTask() {
        const params = {
            assignee_id: finalAssignee,
            active: finalTab,
            offset: finalTasks.length,
            limit: 20,
            tasks: []
        };

        dispatch(actionGetCustomerListTask(params, _getCustomerLitTaskSuccess, _getCustomerLitTaskFailed));
    }

    function _getCustomerLitTaskSuccess(response) {
        dispatchState({
            tasks: sortTaskList([...finalTasks, ...response.data]),
            showMore: response.show_more,
            isLoading: false,
            isLoadingLoadMore: false
        });
    }

    function _getCustomerLitTaskFailed() {
        dispatchState({ isLoading: false, isLoadingLoadMore: false });
    }

    function _selectTab(tab) {
        const isShowLoading = finalAssignee ? true : false;
        dispatchState({ tab: tab, isLoading: isShowLoading, tasks: [] });
    }

    function _selectUser(userId) {
        dispatchState({ assignee: userId, isLoading: true, tasks: [] });
    }

    return (
        <>
            <div className={classNames('tab-conts detail-tasks', { 'tab-content-active': activeTab })}>
                <div className="scrolls" onScroll={handleOnScrollContent}>
                    <div className="tab-header">
                        {activeTab && <StatusTabs tab={finalTab} onSelect={_selectTab} />}
                        <FilterAssign onSelect={_selectUser} />
                    </div>
                    {activeTab && (
                        <TaskList
                            tasks={finalTasks}
                            haveSelected={!!state.assignee}
                            textEmpty={
                                finalTab === STATUS.ACTIVE ? t('calendar:no_active_tasks') : t('calendar:no_done_tasks')
                            }
                            isLoading={finalLoading}
                            isLoadingLoadMore={state.isLoadingLoadMore}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default TabTaskList;
