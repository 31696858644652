import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

const HEADER_QUICK_BOOKS = {
    id: {
        title: 'id',
        style: 'col --md'
    },
    name: {
        title: 'customer',
        style: 'col --lg'
    },
    number: {
        title: 'invoice',
        style: 'col --lg --full'
    },
    address: {
        title: 'address',
        style: 'col --info'
    },
    total: {
        title: 'total',
        style: 'col --info'
    },
    created: {
        title: 'date',
        style: 'col --info'
    },
    delete: {
        title: '',
        style: 'col --info'
    }
};

const QUICK_BOOKS_COLUMNS = [
    {
        id: 'id',
        title: 'id',
        status: true
    },
    {
        id: 'name',
        title: 'customer',
        status: true
    },
    {
        id: 'number',
        title: 'number',
        status: true
    },
    {
        id: 'address',
        title: 'address',
        status: true
    },
    {
        id: 'total',
        title: 'totals',
        status: true
    },
    {
        id: 'created',
        title: 'date',
        status: true
    },
    {
        id: 'delete',
        title: 'delete',
        status: true
    }
];

const QUICK_BOOKS_CONTENT = {
    id: {
        title: 'id',
        style: 'col --md'
    },
    name: {
        title: 'customer',
        style: 'col --lg'
    },
    number: {
        title: 'number',
        style: 'col --lg --full'
    },
    address: {
        title: 'address',
        style: 'col --info',
        type: LIST_TABLE_ITEM_TYPE.QB_ADDRESS
    },
    total: {
        title: 'total',
        style: 'col --info'
    },
    created: {
        title: 'date',
        style: 'col --info'
    },
    delete: {
        title: 'delete',
        style: 'col --info',
        type: LIST_TABLE_ITEM_TYPE.DELETE_QUICK_BOOK_INVOICE
    }
};

export const getGridQuickBookInvoiceColumns = () => {
    const newHeader = {};
    const newContent = {};
    const newColumns = [];

    QUICK_BOOKS_COLUMNS.forEach((col) => {
        const item = col.id;

        newHeader[item] = HEADER_QUICK_BOOKS[item];
        newContent[item] = QUICK_BOOKS_CONTENT[item];
        newColumns.push(col);
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
