import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ADDONS_OPPORTUNITY } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ErrorPage from 'app/modules/error';
import IconArrow from 'assets/icon/IconArrow';
import { getListPipelines } from 'common/redux/actions/settings/pipelinesAction';
import { checkPermission } from 'common/utils/PermissionUtils';
import AddNewPipeline from './components/AddNewPipeline';
import GroupPipeline from './components/GroupPipeline';
import HeaderPipelines from './components/HeaderPipelines';
import { LoadingManager } from './components/Loading';
import ModalDeletePipeline from './components/ModalDeletePipeline';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { checkAccessManageFail } from 'common/utils/AddonUtils';

const AddonsOpportunityManage = () => {
    const history = useHistory();
    const { t } = useTranslation('addons');
    const dispatch = useDispatch();
    const { data = [], isLoading } = useSelector(({ pipelines }) => pipelines);
    const isActivated = useSelector(({ auth }) => !!auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const refAlert = useRef(null);
    const refModalDelete = useRef(null);
    const isAccessOpportunityManage = isActivated && checkPermission(permissionsList, ACCESS_SETTINGS_TAB);

    useEffect(() => {
        if (!isAccessOpportunityManage) return;
        dispatch(
            getListPipelines(
                {},
                () => {},
                () => checkAccessManageFail(history)
            )
        );
    }, []);

    const _handleShowAlert = ({ message = '', type = LIST_STATUS.ERROR }) => {
        refAlert.current.showStatusBar({ id: 'setting_pipelines', message, type });
    };

    const _handleWarningDelete = ({ id, idGroup }) => {
        refModalDelete.current._openModal({ id, idGroup });
    };

    const _handleFailDelete = ({ message }) => {
        refModalDelete.current._closeModal();
        _handleShowAlert({ message });
    };

    if (!isAccessOpportunityManage) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <>
            <div className="addons-wrap__container">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_OPPORTUNITY)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('back')}
                    </Link>
                </div>
                {isLoading ? (
                    <LoadingManager />
                ) : (
                    <div className="scrolls">
                        <div className="boxs-wrapper">
                            <div className="container-column wrapper-opp-pipelines">
                                <div className="wrapper-opp-pipelines__content">
                                    <div className="boxs">
                                        <AlertCustomer ref={refAlert} />
                                        <HeaderPipelines />
                                        <AddNewPipeline handleShowAlert={_handleShowAlert} />

                                        {data?.map((group) => (
                                            <GroupPipeline
                                                key={group.id}
                                                data={group.pipelines || []}
                                                idGroup={group.id}
                                                onShowAlert={_handleShowAlert}
                                                onWarningDelete={_handleWarningDelete}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <ModalDeletePipeline
                ref={refModalDelete}
                pipelines={data}
                onShowAlert={_handleShowAlert}
                onFail={_handleFailDelete}
            />
        </>
    );
};

export default AddonsOpportunityManage;
