import { DEFAULT_ALL } from '../App';
import { ACTIVE, LIBRARY, LIST_ACTION_BASIC, RESULTS, DEFINE_COLOR_STYLE_STATUS } from './index';
import { getListCategoriesCampaigns } from 'common/redux/actions/drip/campaignsAction';

const RUNNING = 'running';
const ENDED = 'ended';
const ALL_STATUSES = 'all_statuses';
const ALL_CATEGORIES = 'all_categories';
const SEND_AFTER_A_DELAY = 'send_after_a_delay_x_days';
const AFTER = 'after';
const DAY = 'day';
export const IMMEDIATELY = 'immediately';

const STATUS_START = 1;
const STATUS_STOP = 2;
const STATUS_RUNNING = 3;
const STATUS_ENDED = 4;

const ANY_DAY = 'any_day';
const ANY_TIME = 'any_time';
const MONDAY_FRIDAY = 'monday_friday';
const START_CAMPAIGN = 'start_campaign';
const END_CAMPAIGN = 'end_campaign';

export const START_DATE = 'start_date';
export const END_DATE = 'end_date';

export const LIST_NAME_TAB_CAMPAIGN_DETAIL = {
    EDIT: 'edit',
    PREVIEW: 'preview'
};

export const CAMPAIGNS_TYPE = {
    ACTIVE: ACTIVE,
    LIBRARY: LIBRARY,
    RESULTS: RESULTS
};

export const LIST_TAB_CAMPAIGNS = [
    { id: 1, name: ACTIVE, value: ACTIVE },
    { id: 2, name: LIBRARY, value: LIBRARY },
    { id: 3, name: 'results', value: RESULTS }
];

export const DEFINE_STATUS = {
    RUNNING: STATUS_RUNNING,
    ENDED: STATUS_ENDED,
    START: STATUS_START,
    STOP: STATUS_STOP
};

// Campaigns
const ACTION_STOP_CAMPAIGN = {
    id: 1,
    name: LIST_ACTION_BASIC.STOP_CAMPAIGN,
    value: LIST_ACTION_BASIC.STOP
};

const ACTION_START_CAMPAIGN = {
    id: 2,
    name: LIST_ACTION_BASIC.START_A_NEW_CAMPAIGN,
    value: LIST_ACTION_BASIC.START
};

const ACTION_DUPLICATE_CAMPAIGN = {
    id: 3,
    name: LIST_ACTION_BASIC.DUPLICATE,
    value: LIST_ACTION_BASIC.DUPLICATE
};

const ACTION_DELETE_CAMPAIGN = {
    id: 4,
    name: LIST_ACTION_BASIC.DELETE,
    value: LIST_ACTION_BASIC.DELETE
};

const DEFINE_LIST_ACTION_CAMPAIGN = {
    [STATUS_RUNNING]: [ACTION_STOP_CAMPAIGN, ACTION_DUPLICATE_CAMPAIGN],
    [STATUS_ENDED]: [ACTION_START_CAMPAIGN, ACTION_DUPLICATE_CAMPAIGN],
    [LIBRARY]: [ACTION_START_CAMPAIGN, ACTION_DUPLICATE_CAMPAIGN, ACTION_DELETE_CAMPAIGN]
};

export const getListActionCampaign = (type) => {
    return DEFINE_LIST_ACTION_CAMPAIGN[type];
};

export const CAMPAIGNS_ACTIVE_LIST_ACTIONS = [
    {
        id: 1,
        name: LIST_ACTION_BASIC.STOP,
        value: LIST_ACTION_BASIC.STOP
    },
    {
        id: 1,
        name: LIST_ACTION_BASIC.DUPLICATE,
        value: LIST_ACTION_BASIC.DUPLICATE
    }
];

export const CAMPAIGNS_LIBRARY_LIST_ACTIONS = [
    {
        id: 1,
        name: LIST_ACTION_BASIC.START,
        value: LIST_ACTION_BASIC.START
    },
    {
        id: 2,
        name: LIST_ACTION_BASIC.DUPLICATE,
        value: LIST_ACTION_BASIC.DUPLICATE
    },
    {
        id: 3,
        name: LIST_ACTION_BASIC.DELETE,
        value: LIST_ACTION_BASIC.DELETE
    }
];

export const LIST_TYPE = {
    email: 'email',
    sms: 'Sms' // "Sms" --> use only for text show on campaign/report/type_column
};

export const LIST_STATUS_STYLE = {
    [STATUS_RUNNING]: { name: RUNNING, style: DEFINE_COLOR_STYLE_STATUS.GREEN },
    [STATUS_ENDED]: { name: ENDED, style: DEFINE_COLOR_STYLE_STATUS.BLUE }
};

const LIST_STATUS_OPTIONS = [
    { id: 1, name: RUNNING, value: STATUS_RUNNING },
    { id: 2, name: ENDED, value: STATUS_ENDED }
];

const STATUS_FILTER_CONFIG = [
    {
        id: 1,
        keyParams: 'status',
        optionSelectAll: { id: -1, name: ALL_STATUSES, value: DEFAULT_ALL },
        selectedDefault: DEFAULT_ALL,
        options: LIST_STATUS_OPTIONS
    }
];

const CATEGORY_FILTER_CONFIG = [
    {
        id: 1,
        keyParams: 'category',
        options: null,
        optionSelectAll: { id: -1, name: ALL_CATEGORIES, value: DEFAULT_ALL },
        selectedDefault: DEFAULT_ALL,
        fetchAction: getListCategoriesCampaigns
    }
];

const DEFINE_FILTER_CONFIG = {
    [ACTIVE]: STATUS_FILTER_CONFIG,
    [LIBRARY]: CATEGORY_FILTER_CONFIG,
    [RESULTS]: STATUS_FILTER_CONFIG
};

export const getConfigFilter = (type) => {
    return DEFINE_FILTER_CONFIG[type];
};

const DEFINE_PARAMS = {
    [ACTIVE]: { keyword: '', status: DEFAULT_ALL },
    [LIBRARY]: { keyword: '', category: DEFAULT_ALL },
    [RESULTS]: { keyword: '', status: DEFAULT_ALL }
};

export const getParamsFilter = (type) => {
    return DEFINE_PARAMS[type];
};

export const DEFAULT_SETUP_STEP = {
    type: IMMEDIATELY,
    value: null
};

export const LIST_SETUP_STEP = [
    {
        id: 1,
        name: IMMEDIATELY,
        value: IMMEDIATELY
    },
    {
        id: 2,
        name: SEND_AFTER_A_DELAY,
        value: AFTER
    }
];

export const DEFAULT_DELAY_DAY = 1;

export const LIST_TAB_CAMPAIGNS_DETAIL = [
    { id: 1, name: LIST_NAME_TAB_CAMPAIGN_DETAIL.EDIT, value: LIST_NAME_TAB_CAMPAIGN_DETAIL.EDIT },
    { id: 2, name: LIST_NAME_TAB_CAMPAIGN_DETAIL.PREVIEW, value: LIST_NAME_TAB_CAMPAIGN_DETAIL.PREVIEW }
];

export const CONFIG_DEFAULT_STEP = {
    trigger: IMMEDIATELY,
    value: 0,
    email: { status: 1, subject: null, content: null },
    sms: { content: null, status: 1 }
};

export const LIST_DATE_RANGE = [
    { id: 1, name: 'S' },
    { id: 2, name: 'M' },
    { id: 3, name: 'T' },
    { id: 4, name: 'W' },
    { id: 5, name: 'T' },
    { id: 6, name: 'F' },
    { id: 7, name: 'S' }
];

export const TIME_RANGE = {
    ANY_DAY_ANY_TIME: 1,
    MONDAY_FRIDAY_ANY_TIME: 2,
    MONDAY_FRIDAY_9AM_4PM: 3
};

export const LIST_START_CAMPAIGN = [
    { id: 1, value: IMMEDIATELY, name: 'Immediately' },
    { id: 2, value: AFTER, name: 'Send After a Delay ("x" Days)' },
    { id: 3, value: DAY, name: 'Schedule' }
];

export const LIST_END_CAMPAIGN = [
    { id: 1, value: IMMEDIATELY, name: 'Never' },
    { id: 2, value: AFTER, name: 'Send After a Delay ("x" Days)' },
    { id: 3, value: DAY, name: 'Day' }
];

export const LIST_STATUS_CAMPAIGN = {
    IMMEDIATELY: IMMEDIATELY,
    AFTER: AFTER,
    DAY: DAY
};

export const LIST_TIME_RANGE = [
    { id: 1, name: ANY_DAY, value: TIME_RANGE.ANY_DAY_ANY_TIME, showListTimeZone: false, description: ANY_TIME },
    {
        id: 2,
        name: MONDAY_FRIDAY,
        value: TIME_RANGE.MONDAY_FRIDAY_ANY_TIME,
        showListTimeZone: true,
        description: ANY_TIME
    },
    {
        id: 3,
        name: MONDAY_FRIDAY,
        value: TIME_RANGE.MONDAY_FRIDAY_9AM_4PM,
        showListTimeZone: true,
        description: '9am_4pm'
    }
];

export const LIST_SCHEDULE_SELECT = [
    { id: 1, name: START_DATE, options: LIST_START_CAMPAIGN, title: START_CAMPAIGN },
    { id: 2, name: END_DATE, options: LIST_END_CAMPAIGN, title: END_CAMPAIGN }
];
