export const configModalChartAI = ({ data }) => {
    return {
        type: 'line',
        data: {
            labels: data.labels,
            datasets: [
                {
                    data: data.values,
                    fill: false,
                    borderColor: '#377AF3',
                    borderWidth: 1.5,
                    pointStyle: false
                }
            ]
        },
        options: {
            scales: { x: { grid: { display: false } } },
            plugins: { legend: false }
        }
    };
};
