export const ASSIGN_LEAD = {
    ACTION_ASSIGN_LEAD: 'ACTION_ASSIGN_LEAD'
};

export const actionAssignLead = (payload) => {
    return {
        type: ASSIGN_LEAD.ACTION_ASSIGN_LEAD,
        payload
    };
};
