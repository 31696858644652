import { createPopper } from '@popperjs/core';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MAX_HEIGHT_OF_ROW, MIN_HEIGHT_OF_ROW, STEP_CHANGE_HEIGHT_ROW } from '../const';

function CalendarControl({
    onUpdateSize = () => {},
    currentHeightProps = null,
    toolTipZoomInId = 'tooltip-zoom-in',
    toolTipZoomOutId = 'tooltip-zoom-out',
    controlZoomInId = 'calendar-control-zoom-in',
    controlZoomOutId = 'calendar-control-zoom-out'
}) {
    const { t } = useTranslation(['calendar']);
    const currentHeight = currentHeightProps || useSelector(({ calendar }) => calendar.heightofrow);
    const refFirstTime = useRef(true);

    const _changeSizeHeight = (action) => {
        let newHeight = currentHeight;

        switch (action) {
            case 'INCREMENT':
                newHeight = currentHeight + STEP_CHANGE_HEIGHT_ROW;
                if (newHeight > MAX_HEIGHT_OF_ROW) newHeight = MAX_HEIGHT_OF_ROW;
                break;
            case 'DECREMENT':
                newHeight = currentHeight - STEP_CHANGE_HEIGHT_ROW;
                if (newHeight < MIN_HEIGHT_OF_ROW) newHeight = MIN_HEIGHT_OF_ROW;
                break;

            default:
                break;
        }

        onUpdateSize(newHeight);
    };

    const _handleCreatePopper = (idPopcorn, idTooltip) => {
        const popcorn = document.getElementById(idPopcorn);
        const tooltip = document.getElementById(idTooltip);
        if (popcorn && tooltip) {
            createPopper(popcorn, tooltip, {
                placement: 'left',
                strategy: 'fixed',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 5]
                        }
                    }
                ]
            });
        }
    };

    /**
     * It gets the tooltip element, and if it exists, it gets the text element inside of it and sets its
     * visibility to visible
     * @param type - The type of tooltip to display.
     */
    const _handleMouseOverTooltip = (type) => {
        if (refFirstTime.current) {
            _handleCreatePopper(controlZoomInId, toolTipZoomInId);
            _handleCreatePopper(controlZoomOutId, toolTipZoomOutId);
            refFirstTime.current = false;
        }

        const tooltipDisplay = _handleGetTooltipEl(type);

        if (tooltipDisplay) tooltipDisplay.style.visibility = 'visible';
    };

    /**
     * It gets the tooltip element, and if it exists, it hides the text inside it
     * @param type - the type of the tooltip to be displayed.
     */
    const _handleMouseBlurTooltip = (type) => {
        const tooltipUnDisplay = _handleGetTooltipEl(type);
        if (tooltipUnDisplay) tooltipUnDisplay.style.visibility = 'hidden';
    };

    /**
     * It returns the tooltip element for the given event type
     * @param type - The type of tooltip you want to display.
     * @returns The tooltipDisplay variable is being returned.
     */
    const _handleGetTooltipEl = (type) => {
        let tooltipDisplay = null;

        switch (type) {
            case 'zoom-in':
                tooltipDisplay = document.getElementById(toolTipZoomInId);
                break;
            case 'zoom-out':
                tooltipDisplay = document.getElementById(toolTipZoomOutId);
                break;

            default:
                break;
        }

        return tooltipDisplay;
    };

    return (
        <>
            <div className="vector-control">
                <div className="action-vector action-zoom">
                    <div
                        id={controlZoomInId}
                        className="btn-vector btn-zoomin tooltip"
                        onClick={() => _changeSizeHeight('INCREMENT')}
                        onMouseOver={() => _handleMouseOverTooltip('zoom-in')}
                        onMouseLeave={() => _handleMouseBlurTooltip('zoom-in')}
                    >
                        <IconPlus />
                    </div>
                    <div
                        id={controlZoomOutId}
                        className="btn-vector btn-zoomout tooltip"
                        onClick={() => _changeSizeHeight('DECREMENT')}
                        onMouseOver={() => _handleMouseOverTooltip('zoom-out')}
                        onMouseLeave={() => _handleMouseBlurTooltip('zoom-out')}
                    >
                        <IconMinus />
                    </div>
                </div>
            </div>

            <p className="tooltip">
                <span id={toolTipZoomOutId} style={{ zIndex: 99999, left: 'auto' }} className="tooltiptext">
                    {t('reduce_timeline')}
                </span>
            </p>
            <p className="tooltip">
                <span id={toolTipZoomInId} style={{ zIndex: 99999, left: 'auto' }} className="tooltiptext">
                    {t('stretch_timeline')}
                </span>
            </p>
        </>
    );
}

CalendarControl.propTypes = { onUpdateSize: PropTypes.func };

export default CalendarControl;
