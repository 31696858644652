import { setJobStatusGlobal } from 'common/utils/JobStatusUtils';

//types
export const AUTH_TYPE = {
    LOG_OUT_REQUEST: 'LOG_OUT_REQUEST',
    LOGIN_USER_REQUESTING: 'LOGIN_USER_REQUESTING',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_USER_ERROR: 'LOGIN_USER_ERROR',
    USER_RETRIEVE_SUCCESS: 'USER_RETRIEVE_SUCCESS',

    LOGIN_SOCIAL_REQUESTING: 'LOGIN_SOCIAL_REQUESTING',
    SIGNUP_SOCIAL_REQUESTING: 'SIGNUP_SOCIAL_REQUESTING',

    FORGOT_PASSWORD_USER_REQUESTING: 'FORGOT_PASSWORD_USER_REQUESTING',
    FORGOT_PASSWORD_USER_SUCCESS: 'FORGOT_PASSWORD_USER_SUCCESS',
    FORGOT_PASSWORD_USER_ERROR: 'FORGOT_PASSWORD_USER_ERROR',
    CHANGE_PASSWORD_USER_REQUESTING: 'CHANGE_PASSWORD_USER_REQUESTING',
    CHANGE_PASSWORD_USER_SUCCESS: 'CHANGE_PASSWORD_USER_SUCCESS',
    CHANGE_PASSWORD_USER_ERROR: 'CHANGE_PASSWORD_USER_ERROR',
    SIGNUP_USER_REQUESTING: 'SIGNUP_USER_REQUESTING',
    SIGNUP_USER_SUCCESS: 'SIGNUP_USER_SUCCESS',
    SIGNUP_USER_ERROR: 'SIGNUP_USER_ERROR',

    CLEAR_INFO_SIGNUP: 'CLEAR_INFO_SIGNUP',

    ACTIVATE_USER_ACCOUNT: 'ACTIVATE_USER_ACCOUNT',
    ACTIVATE_USER_ACCOUNT_SUCCESS: 'ACTIVATE_USER_ACCOUNT_SUCCESS',
    ACTIVATE_USER_ACCOUNT_ERROR: 'ACTIVATE_USER_ACCOUNT_ERROR',

    GET_RETRIEVE_USER: 'GET_RETRIEVE_USER',

    SIGNUP_SOCIAL_COMPANY: 'SIGNUP_SOCIAL_COMPANY',
    CLEAR_USER_DATA: 'CLEAR_USER_DATA',
    UPDATE_COMPANY_SETTINGS: 'UPDATE_COMPANY_SETTINGS',
    GET_EMAIL_INBOX_SIGNATURE: 'GET_EMAIL_INBOX_SIGNATURE',
    GET_EMAIL_INBOX_SIGNATURE_SUCCESS: 'GET_EMAIL_INBOX_SIGNATURE_SUCCESS',
    UPDATE_EMAIL_INBOX_SIGNATURE: 'UPDATE_EMAIL_INBOX_SIGNATURE',
    // Addons in settings -> addons
    UPDATE_ADDONS_STATUS: 'UPDATE_ADDONS_STATUS',
    ACTIVE_SMS_CHARGE: 'ACTIVE_SMS_CHARGE',
    // Addons in settings
    UPDATE_SETTINGS: 'UPDATE_SETTINGS',
    LOGIN_BY_TOKEN_REQUEST: 'LOGIN_BY_TOKEN_REQUEST',
    UPDATE_URL_CUSTOMER_PORTAL: 'UPDATE_URL_CUSTOMER_PORTAL',
    UPDATE_ADDON_SMS_SUPPORT: 'UPDATE_ADDON_SMS_SUPPORT',
    UPDATE_ACCOUNT_PLAN: 'UPDATE_ACCOUNT_PLAN',
    UPDATE_SAVE_FILTER_TIMELINE: 'UPDATE_SAVE_FILTER_TIMELINE',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    FAIL_PERMISSION: 'FAIL_PERMISSION',
    // Headers pin
    UPDATE_HEADER_PIN_MENU: 'UPDATE_HEADER_PIN_MENU',
    // Time tracking
    UPDATE_TRACKING: 'UPDATE_TRACKING',
    // Update job status
    UPDATE_JOB_STATUS: 'UPDATE_JOB_STATUS',
    // Get List Custom JobStatus
    GET_LIST_CUSTOM_JOB_STATUS: 'GET_LIST_CUSTOM_JOB_STATUS',
    // Update magnet | available_time default values
    UPDATE_ADVANCE_ROUTING: 'UPDATE_ADVANCE_ROUTING'
};

// actions
export const userLogOutRequest = () => {
    return { type: AUTH_TYPE.LOG_OUT_REQUEST };
};

export const userLoginRequest = (payload, actionSuccess, actionFailed) => {
    return { type: AUTH_TYPE.LOGIN_USER_REQUESTING, payload, actionSuccess, actionFailed };
};

export const userLoginByTokenRequest = (payload, actionSuccess, actionFailed) => {
    return { type: AUTH_TYPE.LOGIN_BY_TOKEN_REQUEST, payload, actionSuccess, actionFailed };
};

export const userSignupSocialRequest = (payload, actionSuccess, actionFailed) => {
    return {
        type: AUTH_TYPE.SIGNUP_SOCIAL_REQUESTING,
        payload,
        actionSuccess,
        actionFailed
    };
};

export const userLoginSocialRequest = (payload, actionSuccess, actionFailed) => {
    return {
        type: AUTH_TYPE.LOGIN_SOCIAL_REQUESTING,
        payload,
        actionSuccess,
        actionFailed
    };
};

export const userForgotPasswordRequest = (payload, actionSuccess, actionFailed) => {
    return { type: AUTH_TYPE.FORGOT_PASSWORD_USER_REQUESTING, payload, actionSuccess, actionFailed };
};

export const userChangePasswordRequest = (payload, actionSuccess, actionFailed) => {
    return { type: AUTH_TYPE.CHANGE_PASSWORD_USER_REQUESTING, payload, actionSuccess, actionFailed };
};

export const userSignUpRequest = (payload, actionSuccess, actionFailed) => {
    return { type: AUTH_TYPE.SIGNUP_USER_REQUESTING, payload, actionSuccess, actionFailed };
};

export const clearInfoSignup = () => {
    return {
        type: AUTH_TYPE.CLEAR_INFO_SIGNUP
    };
};

export const activateAccount = (payload) => {
    return {
        type: AUTH_TYPE.ACTIVATE_USER_ACCOUNT,
        payload
    };
};

export const getEmailInboxSignature = () => {
    return {
        type: AUTH_TYPE.GET_EMAIL_INBOX_SIGNATURE
    };
};

export const updateEmailSignature = (payload) => {
    return { type: AUTH_TYPE.UPDATE_EMAIL_INBOX_SIGNATURE, payload };
};

export const updateHeaderPinMenu = (payload) => {
    return { type: AUTH_TYPE.UPDATE_HEADER_PIN_MENU, payload };
};

export const getRetrieveUser = (actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: AUTH_TYPE.GET_RETRIEVE_USER,
        actionSuccess,
        actionFailed
    };
};
export const signupSocialCompany = (payload, actionSuccess, actionFailed) => {
    return {
        type: AUTH_TYPE.SIGNUP_SOCIAL_COMPANY,
        payload,
        actionSuccess,
        actionFailed
    };
};

export const clearUserData = () => {
    return { type: AUTH_TYPE.CLEAR_USER_DATA };
};

export const updateCompanySetting = (payload, actionSuccess, actionFailed) => {
    return {
        type: AUTH_TYPE.UPDATE_COMPANY_SETTINGS,
        payload,
        actionSuccess,
        actionFailed
    };
};

/**
 * This function is used to update the status of the addons
 * @returns The action object.
 */
export const updateAddonStatus = (payload) => {
    return { type: AUTH_TYPE.UPDATE_ADDONS_STATUS, payload };
};

/**
 * This function is used to update the settings of the user
 * @returns The action object.
 */
export const updateSettings = (payload) => {
    return { type: AUTH_TYPE.UPDATE_SETTINGS, payload };
};

export const activeSmsCharge = () => {
    return { type: AUTH_TYPE.ACTIVE_SMS_CHARGE };
};

export const updateUserSettings = (payload) => {
    return { type: AUTH_TYPE.UPDATE_URL_CUSTOMER_PORTAL, payload };
};

export const updateSmsAddonSupport = (payload) => {
    return { type: AUTH_TYPE.UPDATE_ADDON_SMS_SUPPORT, payload };
};

export const updateAccountPlan = (payload) => {
    return { type: AUTH_TYPE.UPDATE_ACCOUNT_PLAN, payload };
};

export const actionUpdateFilterTimeLine = (payload) => {
    return { type: AUTH_TYPE.UPDATE_SAVE_FILTER_TIMELINE, payload };
};

export const actionUpdateProfile = (payload) => {
    return { type: AUTH_TYPE.UPDATE_PROFILE, payload };
};

export const actionFailPermission = (payload) => {
    return { type: AUTH_TYPE.FAIL_PERMISSION, payload };
};

export const actionUpdateJobStatus = (payload) => {
    setJobStatusGlobal(payload);
    return { type: AUTH_TYPE.UPDATE_JOB_STATUS, payload };
};

export const refreshListJobStatus = (payload) => {
    return { type: AUTH_TYPE.GET_LIST_CUSTOM_JOB_STATUS, payload };
};
