export const TYPE_INBOX = {
    GET_INBOX_COUNTER_REQUEST: 'GET_INBOX_COUNTER_REQUEST',
    GET_INBOX_COUNTER_SUCCESS: 'GET_INBOX_COUNTER_SUCCESS',
    SET_INBOX_COUNTER: 'SET_INBOX_COUNTER'
};

export const getInboxCounterRequest = (actionSuccess, actionFailed) => {
    return {
        type: TYPE_INBOX.GET_INBOX_COUNTER_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const setInboxCounter = (payload) => {
    return { type: TYPE_INBOX.SET_INBOX_COUNTER, payload };
};
