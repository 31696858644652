import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken, deleteWithToken } from 'common/utils/ApiUtils';
import { uploadToS3 } from 'common/utils/FileUtils';
import {
    SETTINGS_GET_DETAIL_COMPANY,
    SETTINGS_CREATE_AND_UPDATE_COMPANY,
    SETTINGS_DELETE_LOGO_COMPANY,
    SETTING_GET_LIST_TIMEZONE,
    ATTACHMENT_PRESIGN,
    SETTINGS_GET_LIST_INDUSTRIES
} from 'app/const/Api';
import { AUTH_TYPE } from 'common/redux/actions/authAction';

export function* getDetailCompany({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_DETAIL_COMPANY, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* crateAndUpdateCompany({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_CREATE_AND_UPDATE_COMPANY, params);
        if (response && response.success) {
            yield put({
                type: AUTH_TYPE.UPDATE_COMPANY_SETTINGS,
                payload: { ...params, coordinate: response.coordinate }
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListIndustries({ profileCompany, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_INDUSTRIES);
        if (response && response.success) {
            actionSuccess(profileCompany, response.data);
        } else {
            actionFailed(profileCompany, response);
        }
    } catch (error) {
        actionFailed(profileCompany, error);
    }
}

export function* uploadLogo({ params, actionSuccess, actionFailed }) {
    try {
        const paramsFirstStep = { ...params };
        delete paramsFirstStep.imgSelected;
        delete paramsFirstStep.url;
        paramsFirstStep.name = `web-${paramsFirstStep.name}`;
        const firstStep = yield call(postWithToken, ATTACHMENT_PRESIGN, paramsFirstStep);
        if (firstStep.success) {
            const { presigned_url, object_key, object_tag, public_url } = firstStep.data;
            const optionsHeaders = { 'x-amz-tagging': object_tag };
            const itemId = params.item_id;
            const imgSelected = params.imgSelected;
            const url = params.url;
            const data = {
                itemId,
                object_key
            };
            uploadToS3(
                presigned_url,
                optionsHeaders,
                imgSelected,
                data,
                () => actionSuccess({ url, object_key, object_tag, public_url }),
                actionFailed
            );
        } else {
            actionFailed();
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteLogo({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, SETTINGS_DELETE_LOGO_COMPANY);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListTimeZone({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTING_GET_LIST_TIMEZONE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
