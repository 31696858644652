export const LIST_ERRORS_ADDRESS = {
    zip: 'zip_can_not_blank',
    street1: 'street_1_can_not_blank',
    city: 'city_can_not_blank',
    state: 'st_can_not_blank',
    address_lat: 'lat_can_not_blank',
    address_lng: 'lng_can_not_blank'
};

export const SMS_LOGS_TYPES = {
    CREATE: 0,
    UPDATE: 1
};

export const ZIP_ST_NOT_REQUIRE_COUNTRYS_CODE = ['sa', 'ph'];
