import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, getDefaultParamsWidget } from '../../const';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { reducer } from 'app/const/Reducer';
import ServiceDashboard from '../../ServiceDashboard';
import WidgetDatePicker from '../../components/datepicker';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import { formatNumberWithCommas, transformToCurrency } from 'common/utils/NumberUtils';
import RowItemWidget from '../../components/RowItemWidget';
import LoadingTable from '../loading/LoadingTable';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const WidgetActiveService = () => {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.ACTIVE_SERVICE;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData } = state;

    useEffect(() => {
        fetchWidgetData(defaultParams);
    }, []);

    const fetchWidgetData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleGetDataSuccess = (data) => {
        dispatchState((prev) => ({
            ...prev,
            data,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchWidgetData(newParams);
    };

    const _renderItem = ({ label, percent, value, total }, key) => {
        return (
            <RowItemWidget
                key={key}
                icon={<IconWorkOrder />}
                label={label}
                percent={percent}
                value={value}
                total={total}
                currency={currency}
            />
        );
    };

    const _renderActiveServices = () => {
        if (finalIsLoading) return <LoadingTable isShowTotalLoading />;

        const { total_active, total_recurrence, total, data } = finalData;
        const newTotalActive = formatNumberWithCommas(total_active);
        const newTotal = transformToCurrency(total, currency);

        return (
            <div className="boxs__content --progress-bar">
                <div className="is-summary">
                    <div className="rows">
                        <div className="col">
                            <p className="fs-26 txt-ellipsis price">{newTotalActive}</p>
                            <p className="fs-13 txt-ellipsis">{t('total_active_services')}</p>
                        </div>
                        <div className="col">
                            <p className="fs-26 txt-ellipsis price">{formatNumberWithCommas(total_recurrence)}</p>
                            <p className="fs-13 txt-ellipsis">{t('recurring_services')}</p>
                        </div>
                    </div>
                </div>
                {data?.length > 0 ? (
                    <table className="dashboard-table">
                        <tbody>
                            {data.map((item, index) => _renderItem(item, index.toString()))}

                            <tr className="row-total">
                                <td colSpan={2} className="fs-13" title={t('total')}>
                                    {t('total')}
                                </td>
                                <td colSpan={2} className="fs-11 col-number" title={newTotalActive}>
                                    {newTotalActive}
                                </td>
                                <td className="fs-13 --label-r" title={newTotal}>
                                    {newTotal}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <GridEmpty isFlat />
                )}
            </div>
        );
    };

    return (
        <div className="boxs">
            <div className="boxs__header">
                <div className="header-label flex-1">{t('active_services')}</div>
                <WidgetDatePicker
                    key={keyWidget}
                    keyWidget={keyWidget}
                    isShowIcon
                    selectDefault={defaultParams}
                    onSelect={(values) => _handleChangeFilterWidget({ ...values })}
                />
            </div>
            {_renderActiveServices()}
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </div>
    );
};

export default WidgetActiveService;
