import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { useSelector } from 'react-redux';
import MoreOptions from 'app/modules/customer/components/MoreOptions';

const StatementSummary = ({ summary = {}, isLoading, params = {}, idCustomer = null, startDate, endDate }) => {
    const { t } = useTranslation('report');
    const { customer } = useContext(CustomerDetailContext);
    const { invoiced, payments, available_credit, account_balance } = summary;
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    if (isLoading) return <StatementSummaryLoading />;

    return (
        <div className="account-summary flex-column gap-4">
            <div className="details flex-betweenitems gap-10">
                <b className="fs-16">
                    {t('report:account_summary_from', { start_date: startDate, end_date: endDate })}
                </b>
                <MoreOptions paramsStatement={params} idCustomer={idCustomer} />
            </div>
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('invoiced')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(invoiced), currency)}
                </span>
            </div>
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('payments')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(payments), currency)}
                </span>
            </div>
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('available_credit')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(available_credit), currency)}
                </span>
            </div>
            <div className="details flex-betweenitems gap-10 fw-600">
                <span className="details__label txt-ellipsis">{t('account_balance')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(account_balance), currency)}
                </span>
            </div>
            <b className="fs-16 mt-2">{t('report:statement_for', { customer_name: customer.full_name })}</b>
        </div>
    );
};

const StatementSummaryLoading = () => (
    <div className="wrap-loading account-summary flex-column gap-10">
        <div className="details flex-betweenitems mb-2">
            <div className="loading --animation --line --half" />
        </div>
        <div className="details flex-betweenitems">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="details flex-betweenitems">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="details flex-betweenitems">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="details flex-betweenitems">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="loading --animation --line --half mt-2" />
    </div>
);

export default StatementSummary;
