import React from 'react';

const LoadingPaymentSquare = () => {
    return (
        <div className="wrap-loading --modal-profile pb-4">
            <div className="body-modal has-form">
                <div className="rows">
                    <div className="txt loading">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default --grey loading --full">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="row-multi">
                    <div className="col">
                        <div className="txt loading">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="v2-btn-default --grey loading --full">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="txt loading">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --grey loading --full">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="txt loading">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default --grey loading --full">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPaymentSquare;
