import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import IconSync from 'assets/icon/IconSync';

function RestoreDefaultButton({
  onReset
}) {
  const { t } = useTranslation(["drip"]);

  const handleReset = () => {
    onReset();
  }

  return (
    <div className="v2-btn-default has-icon" onClick={handleReset}>
      <IconSync />
      {t("drip:restore_default")}
    </div>
  )
}

RestoreDefaultButton.propTypes = {
  onReset: PropTypes.func
}

export default RestoreDefaultButton

