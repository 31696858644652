import React from 'react';

export default function LoadingUserList() {
    return (
        <div className="load-inline wrap-action justify-start">
            <div className="loading --animation" />
            <div className="loading --animation" />
            <div className="loading --animation" />
            <div className="loading --animation" />
        </div>
    );
}
