import React, { useReducer, useEffect, useRef } from 'react';
import { DEFAULT_ALL } from 'app/const/App';
import useClickOutside from 'common/hooks/useClickOutside';
import { getListCustomerTag } from 'common/redux/actions/customers/tagAction';
import { useDispatch } from 'react-redux';
import IconLoading from 'assets/icon/IconLoading';
import IconDropDown from 'assets/icon/IconDropDown';
import IconTag from 'assets/icon/IconTag';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const FilterTags = ({ customerId, onSelect = () => {} }) => {
    const { t } = useTranslation(['calendar, header']);
    const dispatch = useDispatch();
    const oldCustomer = useRef(customerId);

    const [state, dispatchState] = useReducer(reducer, {
        options: [],
        isFistTime: true,
        selected: DEFAULT_ALL,
        isLoading: false
    });
    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);

    const { selected: finalSelected, options: finalOptions, isLoading: finalLoading } = state;
    const numberOption = finalOptions.length;

    useEffect(() => {
        if (isOpen && (state.isFistTime || customerId !== oldCustomer.current)) {
            dispatchState({ isLoading: true, options: [] });
            _getListCustomer();

            oldCustomer.current = customerId;
        }
    }, [isOpen, customerId]);

    function _getListCustomer() {
        const params = { type: DEFAULT_ALL, customer_id: customerId };
        customerId && dispatch(getListCustomerTag(params, _getListCumtomerTagSuccess, _getListCumtomerTagFailed));
    }

    function _getListCumtomerTagSuccess(response) {
        dispatchState({ isLoading: false, options: response.data, isFistTime: false });
    }

    function _getListCumtomerTagFailed() {
        dispatchState({ isLoading: false, isFistTime: false });
    }

    function _handleOpen() {
        setIsOpen(!isOpen);
    }

    function handleSelectOption(e, value) {
        e.preventDefault();

        let newSelected = finalSelected === DEFAULT_ALL ? finalOptions.map((i) => i.id) : [...finalSelected];

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((item) => item !== value);
        } else {
            newSelected.push(value);
        }

        if (newSelected.length === numberOption) {
            newSelected = DEFAULT_ALL;
        }

        dispatchState({ selected: newSelected });
        onSelect(newSelected);

        return false;
    }

    function _handleSelectAll(e) {
        e.preventDefault();
        const value = DEFAULT_ALL === finalSelected ? [] : DEFAULT_ALL;
        dispatchState({ selected: value });
        onSelect(value);
    }

    function _renderListTag() {
        if (!isOpen || finalLoading || !numberOption) {
            return null;
        }

        return finalOptions.map((item) => {
            const tagId = item.id;
            const isCheck = finalSelected === DEFAULT_ALL || finalSelected.includes(tagId) ? 'active' : '';

            return (
                <li
                    key={tagId}
                    className={`items ${isCheck ? 'active' : ''}`}
                    onClick={(e) => handleSelectOption(e, tagId)}
                >
                    <div className="check-items">
                        <input id="checkbox_its" type="checkbox" checked={isCheck} />
                        <div className="item-checkbox">
                            <label htmlFor="checkbox_its">
                                <span className="txt-ellipsis">{item.name}</span>
                            </label>
                        </div>
                    </div>
                </li>
            );
        });
    }

    function _renderSelectAll() {
        if (finalLoading || !numberOption) {
            return null;
        }

        const isCheckAll = finalSelected === DEFAULT_ALL;

        return (
            <div className="content-checked__all">
                <div className={`items ${isCheckAll && 'active'}`} onClick={_handleSelectAll}>
                    <div className="check-items">
                        <input id="checkbox_all" type="checkbox" checked={isCheckAll} />
                        <div className="item-checkbox">
                            <label htmlFor="checkbox_all">
                                <span className="txt-ellipsis">{t('header:select_all')}</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div ref={refSelect} className={`v2-dropdown select-list ${isOpen ? 'active' : ''}`}>
            <div className="dropbtn items has-icon" onClick={_handleOpen}>
                <IconTag isNormal />
                <span className="txt-ellipsis flex-1">{t('calendar:tag')}</span>
                <span className="svg"><IconDropDown /></span>
            </div>

            <div className="v2-dropdown__menu content-checked">
                {_renderSelectAll()}

                <ul className="scrolls">
                    {_renderListTag()}

                    {!numberOption && !finalLoading && (
                        <div className="items justify-center">
                            <div className="loading -ajaxbar">{t('calendar:dont_have_tag')}</div>
                        </div>
                    )}

                    {finalLoading && (
                        <div className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default FilterTags;
