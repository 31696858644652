import { takeLatest } from 'redux-saga/effects';

import { GET_REVIEW_LIKELY } from 'common/redux/actions/reviews/likelyAction';
import {
    GET_REVIEW_SERVICE,
    GET_LIST_USER_FILTER_REQUEST,
    GET_LIST_USER_REVIEW
} from 'common/redux/actions/reviews/serviceAction';

import { getListReviewLikely } from 'common/redux/sagas/review/likely';
import { getListReviewService, getListUser, getListUserFilter } from 'common/redux/sagas/review/service';

export function* reviewGetLikeylyWatcher() {
    yield takeLatest(GET_REVIEW_LIKELY, getListReviewLikely);
}
export function* reviewGetServiceWatcher() {
    yield takeLatest(GET_REVIEW_SERVICE, getListReviewService);
}
export function* reviewGetListUserFilterWatcher() {
    yield takeLatest(GET_LIST_USER_FILTER_REQUEST, getListUserFilter);
}
export function* reviewGetListUserWatcher() {
    yield takeLatest(GET_LIST_USER_REVIEW, getListUser);
}
