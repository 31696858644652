import { OPERATOR_IDS } from '../../constants/types';

export const getOperatorNumberRange = (condition, negate) => {
    const { gte, lte, lt, gt } = condition;
    const betweenOperatorId = negate ? OPERATOR_IDS.NOT_BETWEEN : OPERATOR_IDS.BETWEEN;
    const someOperatorId = negate ? OPERATOR_IDS.SOME : OPERATOR_IDS.NONE;
    if (gte === 0 && lte === 0) return { value: 0, operatorId: someOperatorId };
    if ((gte || gte === 0) && (lte || lte === 0)) {
        if (gte === lte) return { value: gte, operatorId: negate ? OPERATOR_IDS.NOT_EXACTLY : OPERATOR_IDS.EXACTLY };
        return {
            value: {
                moreThan: { floatValue: condition.gte, includeEquals: true },
                lessThan: { floatValue: condition.lte, includeEquals: true }
            },
            operatorId: betweenOperatorId
        };
    }

    // Between case
    if ((gt || gt === 0) && (lt || lt === 0)) {
        if (gt === 0 && lt === 0) return { value: undefined, operatorId: OPERATOR_IDS.NONE };
        if (gt === lt) return { value: lt, operatorId: negate ? OPERATOR_IDS.NOT_EXACTLY : OPERATOR_IDS.EXACTLY };
        return {
            value: {
                moreThan: { floatValue: condition.gt, includeEquals: false },
                lessThan: { floatValue: condition.lt, includeEquals: false }
            },
            operatorId: betweenOperatorId
        };
    }

    if ((gt || gt === 0) && (lte || lte === 0)) {
        return {
            value: {
                moreThan: { floatValue: condition.gt, includeEquals: false },
                lessThan: { floatValue: condition.lte, includeEquals: true }
            },
            operatorId: betweenOperatorId
        };
    }
    if ((gte || gte === 0) && (lt || lt === 0)) {
        return {
            value: {
                moreThan: { floatValue: condition.gte, includeEquals: true },
                lessThan: { floatValue: condition.lt, includeEquals: false }
            },
            operatorId: betweenOperatorId
        };
    }
    if (gt || lt || gt === 0 || lt === 0) {
        if (gt === 0) return { value: 0, operatorId: OPERATOR_IDS.SOME };
        return {
            value: condition[gt || gt === 0 ? 'gt' : 'lt'],
            operatorId: gt || gt === 0 ? OPERATOR_IDS.MORE : OPERATOR_IDS.LESS
        };
    }
};
