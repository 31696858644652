import React from 'react';
import IconUser from 'assets/icon/IconUser';

const CustomerInfo = ({ customer }) => {
    return (
        <div className="sm-row">
            <div className="txt svg-name">
                <IconUser isCustomer />
                Customer
            </div>
            <div className="detail">{customer.full_name}</div>
        </div>
    );
};

export default CustomerInfo;
