import React from 'react';

export const VOIPAddonsLoadingAfterHour = () => {
    return (
        <div className="wrap-container wrap-loading">
            <div className="wrap-container__form flex-column">
                <div className="wrap-header py-3">
                    <div className="flex-betweenitems">
                        <div className="loading --animation --line --fourth" />
                        <div className="v2-btn-default --icon-lg loading bg-white-cultured" />
                    </div>
                </div>
                <div className="wrap-body">
                    <div className="content-insert-variable">
                        <div className="box-use-variable">
                            <div className="loading --animation --line --onefifth mb-3" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefourth mt-1" />
                            <div className="flex-betweenitems gap-24 mt-3">
                                <div className="flex-1">
                                    <div className="loading --animation --line --onefifth mb-1" />
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="loading --animation --line --onefifth mb-1" />
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="v2-btn-default loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flexcenter gap-16 mt-3">
                                <div className="loading --animation --line --onefifth" />
                                <div className="d-flex flex-1 gap-4">
                                    <div className="loading --animation --line --onetenth" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                    <div className="loading --animation --line --onetenth mt-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-insert-variable">
                        <div className="box-use-variable">
                            <div className="loading --animation --line --onefifth" />
                            <div className="flexcenter gap-8 my-3">
                                <div className="v2-btn-default loading --grey --half justify-center">
                                    <div className="loading --animation --line --half" />
                                </div>
                                <div className="v2-btn-default loading --grey --half justify-center">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                            <div className="audio-loading" />
                        </div>
                    </div>
                </div>
                <div className="wrap-footer">
                    <div className="v2-btn-default loading btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading btn-x-sm --grey">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};
