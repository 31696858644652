import React, { useLayoutEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import { useSettingTemplate } from '../SettingTemplateContext';
import TemplateSelectServices from './TemplateSelectServices';

const BoxServiceContent = () => {
    const { activeIndex, onGetCurrentData, onUpdateDataReminder } = useSettingTemplate();
    const [state, dispatchState] = useReducer(reducer, { selected: [] });
    const dataServices = useSelector(({ serviceReducer }) => serviceReducer.list_service);
    const { selected } = state;

    useLayoutEffect(() => {
        const currentReminder = onGetCurrentData();
        const defaultIds = currentReminder?.service_ids || [];
        if (dataServices.length && defaultIds) {
            const selected = [];
            dataServices.forEach((item) => {
                if (defaultIds.includes(item.id)) selected.push(item);
            });
            dispatchState({ selected });
        }
    }, [dataServices, activeIndex]);

    const _handleUpdateSelected = (selected) => {
        dispatchState({ selected });
        onUpdateDataReminder(
            ['service_ids', 'all_service'],
            [selected.map((item) => item.id), selected.length === dataServices.length ? 1 : 0]
        );
    };

    const _handleDeleteService = (id) => {
        const newSelected = selected.filter((item) => item.id !== id);
        dispatchState({ selected: newSelected });
        onUpdateDataReminder(
            ['service_ids', 'all_service'],
            [newSelected.map((item) => item.id), newSelected.length === dataServices.length ? 1 : 0]
        );
    };

    return (
        <div className="content-insert-variable">
            <div className="box-use-variable filter-service">
                <div className="flex-betweenitems mb-1">
                    <div className="flexcenter">
                        <IconServiceTemplate />
                        <p className="fw-500 black fs-14 ml-1">Apply to these services</p>
                    </div>

                    <TemplateSelectServices
                        data={dataServices}
                        selectedServices={selected}
                        onUpdateSelected={_handleUpdateSelected}
                    />
                </div>

                <div className="list-services">
                    <SelectedServiceList data={selected} onDelete={_handleDeleteService} />
                </div>
            </div>
        </div>
    );
};

const SelectedServiceList = ({ data = [], onDelete = () => {} }) => {
    if (!data.length) return null;
    return data.map((item) => {
        return (
            <div key={item.id} className="tag-label">
                <p className="tag-label__name">{item.name}</p>
                <span className="tag-label__close" onClick={() => onDelete(item.id)}>
                    <IconClose isSmall />
                </span>
            </div>
        );
    });
};

export default BoxServiceContent;
