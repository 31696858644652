import IconArrow from 'assets/icon/IconArrow';
import IconMessageBox from 'assets/icon/IconMessageBox';
import React from 'react';
import { linkSquareDashboard, linkStripeDashboard } from 'app/const/api/V2';

const CustomerInfo = ({ customer = {}, subscription = {}, isSquare }) => {
    const linkDashboard = () => {
        const { account_customer_id, subscription_id } = subscription;
        return isSquare
            ? linkSquareDashboard(account_customer_id)
            : linkStripeDashboard(account_customer_id, subscription_id);
    };

    return (
        <div className="user-info">
            <div className="flexinline-center">
                <span className="avt fs-12">{customer.avatar}</span>
                <div className="name">{customer.full_name}</div>
            </div>
            <span className="info dots">{subscription.account_customer_id}</span>
            <div className="user-info__details">
                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">ID</span>
                    </div>
                    <div className="rows__decs">{subscription.subscription_id}</div>
                </div>
                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">Created</span>
                    </div>
                    <div className="rows__decs">{subscription.date_created}</div>
                </div>

                {subscription.scheduled_cancel && (
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <IconMessageBox />
                            <span className="txt-ellipsis">Scheduled to cancel on</span>
                        </div>
                        <div className="rows__decs">{subscription.scheduled_cancel}</div>
                    </div>
                )}

                {subscription.current_period && (
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <IconMessageBox />
                            <span className="txt-ellipsis">Current period</span>
                        </div>
                        <div className="rows__decs">
                            <span className="grey-medium">from</span> {subscription.current_period?.start}{' '}
                            <span className="grey-medium">to</span> {subscription.current_period?.end}
                        </div>
                    </div>
                )}

                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">Customer</span>
                    </div>
                    <div className="rows__decs">
                        <a href={linkDashboard()} target="_blank" className="purple-default" rel="noreferrer">
                            {subscription.email || <IconArrow />}
                        </a>
                    </div>
                </div>
                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">Discount</span>
                    </div>
                    <div className="rows__decs --empty">{subscription.discount}</div>
                </div>
                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">Tax percent</span>
                    </div>
                    <div className="rows__decs --empty">{subscription.tax_percent}</div>
                </div>
                <div className="rows --modify --has-field">
                    <div className="rows__label">
                        <IconMessageBox />
                        <span className="txt-ellipsis">Billing method</span>
                    </div>
                    <div className="rows__decs">{subscription.billing_method}</div>
                </div>
            </div>
        </div>
    );
};

export default CustomerInfo;
