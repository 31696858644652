export const TYPE_ROUTE_OPTIMIZER = {
    GET_DRIVE_MATRIX_REQUEST: 'GET_DRIVE_MATRIX_REQUEST',
    GET_DRIVE_MATRIX_SUCCESS: 'GET_DRIVE_MATRIX_SUCCESS',
    UPDATE_DRIVE_MATRIX_REQUEST: 'UPDATE_DRIVE_MATRIX_REQUEST',
    GET_LIST_EVENT_ROUTE_REQUEST: 'GET_LIST_EVENT_ROUTE_REQUEST',
    GET_LIST_EVENT_ROUTE_SUCCESS: 'GET_LIST_EVENT_ROUTE_SUCCESS',
    OPTIMIZED_EVENT_ROUTE_REQUEST: 'OPTIMIZED_EVENT_ROUTE_REQUEST',
    OPTIMIZED_EVENT_ROUTE_SUCCESS: 'OPTIMIZED_EVENT_ROUTE_SUCCESS',
    ACCEPT_NEW_ROUTE_REQUEST: 'ACCEPT_NEW_ROUTE_REQUEST',
    SET_RANGE_TIME_OPTIMIZE: 'SET_RANGE_TIME_OPTIMIZE'
};

export const getDriveMatrix = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.GET_DRIVE_MATRIX_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateDriveMatrix = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.UPDATE_DRIVE_MATRIX_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListEventRoute = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.GET_LIST_EVENT_ROUTE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const optimizedEventsRoute = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.OPTIMIZED_EVENT_ROUTE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const acceptNewRoute = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.ACCEPT_NEW_ROUTE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const setRangeTimeOptimize = (range) => {
    return {
        type: TYPE_ROUTE_OPTIMIZER.SET_RANGE_TIME_OPTIMIZE,
        range
    };
};
