import moment from 'moment';
import React, { Suspense, forwardRef, lazy, useImperativeHandle, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import Loading from 'app/modules/tracker/components/TrackerCalendar/Loading';
import { TrackingTimeProvider } from './DetailTrackedContext';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';

const DetailTrackingTime = lazy(() => import('./components/DetailTrackingTime'));
const ModalDetailTracked = forwardRef((props, ref) => {
    const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);
    const dateFormat = convertPhpFormatToMoment(date_format);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, data: { userId: '' } });
    const { isOpen, data } = state;

    const _handleOpen = (data = {}) => {
        const { date } = data;
        dispatchState((prevState) => ({
            ...prevState,
            isOpen: true,
            data: {
                ...data,
                start: moment(date, dateFormat).format('YYYY-MM-DD'),
                end: moment(date, dateFormat).format('YYYY-MM-DD')
            }
        }));
    };

    const _handleClose = () => {
        dispatchState((prevState) => ({ ...prevState, isOpen: false, data: null }));
    };

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));

    if (!isOpen) return null;
    return (
        <Suspense fallback={<Loading />}>
            <TrackingTimeProvider data={data}>
                <DetailTrackingTime onClose={_handleClose} />
            </TrackingTimeProvider>
        </Suspense>
    );
});

export default ModalDetailTracked;
