import ButtonSave from 'app/components/button/ButtonSave';
import RichTextDropzone from 'app/components/richtext/components/RichTextDropzone';
import { VOIP_ACCEPTED_AUDIO } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import IconPlay from 'assets/icon/IconPlay';
import { IconRecord } from 'assets/icon/IconRecord';
import IconSync from 'assets/icon/IconSync';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { convertWebMToWAV } from 'common/utils/AudioUtils';
import TimeCounter from 'app/modules/layouts/GDVoip/Time';
import { convertSecondsToTime } from 'common/utils/DateUtils';

const TAB_RECORDING = {
    START: 'start',
    STOP: 'stop',
    PLAY: 'play',
    LOADING: 'loading'
};

const initialState = {
    tab: TAB_RECORDING.START,
    isOpenModal: false,
    isRecording: false,
    audioChunks: [],
    srcAudio: null,
    isPlayAudio: false,
    file: null,
    isError: false
};

const ModalRecording = ({ onUpload }, ref) => {
    const { t } = useTranslation();
    const mediaRecorder = useRef(null);
    const refStream = useRef(null);
    const refAudio = useRef(null);
    const refUpload = useRef(null);
    const refDropWarning = useRef(null);
    const refIsOpen = useRef(false);
    const refButtonSave = useRef(false);
    const refTimePlay = useRef(null);
    const mimeType = 'audio/webm';
    const mimeTypeRecord = 'audio/wav';

    let dragTimer;
    const filesAccept = VOIP_ACCEPTED_AUDIO.map((item) => item?.replace('audio/', '')).join(', ');

    const [state, dispatchState] = useReducer(reducer, initialState);
    const { tab: tabContent, srcAudio, audioChunks, isPlayAudio, isOpenModal, file, isError } = state;

    useEffect(() => {
        refAudio.current.onended = function () {
            _handleStopAudio();
        };

        document.addEventListener('dragover', _handleDragOver);
        document.addEventListener('dragleave', _handleDragEnd);

        return () => {
            document.removeEventListener('dragover', _handleDragOver);
            document.removeEventListener('dragleave', _handleDragEnd);
        };
    }, []);

    useEffect(() => {
        let intervalId = null;
        if (tabContent === TAB_RECORDING.PLAY) {
            intervalId = setInterval(() => {
                const seconds = refAudio.current.currentTime;
                if (refTimePlay.current) refTimePlay.current.innerText = convertSecondsToTime(seconds);
            }, 500);
        }

        return () => clearInterval(intervalId);
    }, [tabContent]);

    useImperativeHandle(ref, () => ({
        _setVisible
    }));

    const _setVisible = (value) => {
        refIsOpen.current = value;
        dispatchState({ isOpenModal: value });
    };

    const _handleCloseModal = () => {
        _setVisible(false);
        if (tabContent === TAB_RECORDING.STOP) stopRecording();
        dispatchState(initialState);
        refAudio.current.load();
    };

    const _renderHeader = () => {
        return (
            <div className="header-modal">
                <h3 className="header-modal__label">{t('addons:record_voicemail_drop')}</h3>
                <span className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseModal}>
                    <IconClose />
                </span>
            </div>
        );
    };

    const _renderFooter = () => {
        return (
            <div className="footer-modal btn-close">
                <span className="v2-btn-default --transparent" onClick={_handleCloseModal}>
                    {t('addons:cancel')}
                </span>
                <ButtonSave ref={refButtonSave} onSave={_handleOnSave}>
                    {t('addons:save')}
                </ButtonSave>
            </div>
        );
    };

    const _renderBody = () => {
        return (
            <div className="body-modal scrolls relative p-0">
                <p className="text-body-modal">{t('addons:desc_modal_recording_addons_voip')}</p>
                {_renderContent()}
                <input
                    ref={refUpload}
                    onChange={(e) => _handleUploadFile(e.target.files)}
                    type="file"
                    style={{ display: 'none' }}
                    multiple
                    accept={VOIP_ACCEPTED_AUDIO}
                />
                {tabContent === TAB_RECORDING.START ? (
                    <RichTextDropzone
                        ref={refDropWarning}
                        pointerEventNone={false}
                        text="Drop files here"
                        onDrop={_handleUploadFile}
                    />
                ) : null}
            </div>
        );
    };

    const _renderContent = () => {
        const _render = () => {
            switch (tabContent) {
                case TAB_RECORDING.START:
                    return _tabStartRecording();
                case TAB_RECORDING.STOP:
                    return _tabStopRecording();
                case TAB_RECORDING.PLAY:
                    return _tabPlayRecording();
                case TAB_RECORDING.LOADING:
                    return _renderTabLoading();
                default:
                    return null;
            }
        };

        return <div className="content-body-modal js-body-record">{_render()}</div>;
    };

    const _tabStartRecording = () => {
        return (
            <div className="text-center ">
                <div className="v2-btn-default has-icon has-bg-blue white --transparent " onClick={startRecording}>
                    <IconRecord isWhite />
                    {t('addons:start_recording')}
                </div>
                <p className="description black-eerie-dark">
                    {t('addons:desc_drag_drop_record')}
                    <span className="purple-default cursor-pointer" onClick={_handleOpenUpload}>
                        {t('addons:select_a_file')}
                    </span>
                </p>
                {isError && (
                    <div className="red-default text-left mt-4">
                        {t('addons:file_type_accept_recording', {
                            files: filesAccept
                        })}
                    </div>
                )}
            </div>
        );
    };

    const _tabStopRecording = () => {
        return (
            <div className="text-center js-done-record">
                <div className="v2-btn-default has-icon --color-icon --gray " onClick={stopRecording}>
                    <IconPlay isStop />
                    {t('addons:done_recording')}
                    <TimeCounter wrapperClassName="ml-1" isUseTime />
                </div>
            </div>
        );
    };

    const _tabPlayRecording = () => {
        return (
            <div className="flex-centeritem gap-8 js-play-record">
                {!isPlayAudio ? (
                    <div
                        className="v2-btn-default has-icon has-bg-blue --transparent white svg-white-stroke svg-white"
                        onClick={_handlePlayAudio}
                    >
                        <IconPlay />
                        {t('addons:play')}
                    </div>
                ) : (
                    <div className="flex-centeritem gap-8 ">
                        <div className="v2-btn-default has-icon stop-btn --color-icon " onClick={_handleStopAudio}>
                            <IconPlay isStop />
                            {t('addons:stop_playback')}
                            <span ref={refTimePlay} className="ml-1">
                                00:00
                            </span>
                        </div>
                    </div>
                )}
                <div className="v2-btn-default has-icon js-start-over-btn" onClick={_handleStartOver}>
                    <IconSync height={24} width={24} />
                    {t('addons:start_over')}
                </div>
            </div>
        );
    };

    const _renderTabLoading = () => {
        return (
            <div className="text-center js-loading-record dp-hide">
                <p className="black-eerie-dark mb-3">{t('addons:loading')}</p>
                <div className="preloader">
                    <div className="loader-wave">
                        <span className="loader-wave__items" />
                    </div>
                </div>
            </div>
        );
    };

    const _handlePlayAudio = () => {
        refAudio.current.play();
        dispatchState({ isPlayAudio: true });
    };

    const _handleStopAudio = () => {
        refAudio.current.load();
        dispatchState({ isPlayAudio: false });
    };

    const _handleStartOver = () => {
        dispatchState({ tab: TAB_RECORDING.START, file: null, srcAudio: null, isError: false });
    };

    const startRecording = async () => {
        refStream.current = await navigator.mediaDevices.getUserMedia({
            audio: true
        });
        const media = new MediaRecorder(refStream.current, { type: mimeType });
        mediaRecorder.current = media;
        mediaRecorder.current.start();
        const localAudioChunks = [];
        mediaRecorder.current.ondataavailable = (event) => {
            const eventData = event.data;
            if (typeof eventData === 'undefined') return;
            if (eventData.size === 0) return;
            localAudioChunks.push(eventData);
        };
        dispatchState({ audioChunks: localAudioChunks, tab: TAB_RECORDING.STOP });
        _handleDisableSave(true);
    };

    const stopRecording = () => {
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = async () => {
            const audioBlob = new Blob(audioChunks, { type: mimeType });
            const fileWebm = new File([audioBlob], 'new_record.webm', { type: mimeType });
            const fileWav = new File([await convertWebMToWAV(fileWebm)], 'new_record.wav', { type: mimeTypeRecord });
            refIsOpen.current &&
                dispatchState({
                    audioChunks: [],
                    tab: TAB_RECORDING.PLAY,
                    file: fileWav,
                    srcAudio: URL.createObjectURL(audioBlob)
                });
            refStream.current.getTracks().forEach(function (track) {
                track.stop();
            });
        };
        _handleDisableSave(false);
    };

    const _handleOpenUpload = () => {
        refUpload.current.click();
    };

    const _handleUploadFile = (files) => {
        if (!files.length) {
            return false;
        }
        if (!VOIP_ACCEPTED_AUDIO.includes(files[0].type)) {
            dispatchState({ isError: true });
            return false;
        }

        dispatchState({
            audioChunks: [],
            tab: TAB_RECORDING.PLAY,
            file: files[0],
            srcAudio: URL.createObjectURL(files[0]),
            isError: false
        });
        refUpload.current.value = '';
    };

    const _handleOnSave = () => {
        !!file && onUpload(file);
        _handleCloseModal();
    };

    const _handleDisableSave = (value) => {
        refButtonSave.current.setDisable(value);
    };

    const _handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (refDropWarning.current) refDropWarning.current.handleDisplay();
        window.clearTimeout(dragTimer);
    };

    const _handleDragEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragTimer = window.setTimeout(() => {
            if (refDropWarning.current) refDropWarning.current.handleHidden();
        }, 25);
    };

    return (
        <Fragment>
            <ReactModal
                id="modal_recording"
                isOpen={isOpenModal}
                className="modal container-modal modal-recording open"
                onRequestClose={_handleCloseModal}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleCloseModal} />
                <div className="modal__container">
                    {_renderHeader()}
                    {_renderBody()}
                    {_renderFooter()}
                </div>
            </ReactModal>
            <audio ref={refAudio} src={srcAudio} />
        </Fragment>
    );
};

export default forwardRef(ModalRecording);
