import React, { forwardRef, useReducer, useImperativeHandle, useRef } from 'react';
import AssignTo from 'app/modules/customer/task/components/AssignTo';
import Date from 'app/modules/customer/task/components/Date';
import Time from 'app/modules/customer/task/components/Time';
import TaskName from 'app/modules/customer/task/components/TaskName';
import StatusBar from 'app/components/status/statusbar';
import moment from 'moment';
import { LIST_STATUS } from 'app/const/Status';
import { combineDateTimeToISO } from 'common/utils/DateUtils';
import { updateTask } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';

const EditTask = forwardRef(({ taskDetail, socketId = null, onUpdate }, ref) => {
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const { id: taskId, date: dateStart } = taskDetail;

    const refTaskName = useRef(null);
    const refStatusBar = useRef(null);
    const refTaskDate = useRef(null);
    const refTaskTime = useRef(null);
    const refAssignTo = useRef(null);

    const finalIsVisible = state.isVisible;

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide
    }));

    function _handleToggleShowHide() {
        setState({ isVisible: !finalIsVisible });
    }

    function _handleCancelEdit() {
        const elm = document.getElementById(`form_time_line_task_detail_${taskId}`);
        if (elm) {
            elm.classList.toggle('--editer-task');
            setState({ isVisible: false });
        }
    }

    function _handleSaveNewTask() {
        const taskName = refTaskName.current.getValue();

        if (taskName.length === 0) {
            refStatusBar.current.showStatusBar('show_error', 'Task description is required.', LIST_STATUS.ERROR);
            refTaskName.current.checkVerifyError();
            return false;
        }

        const taskDate = refTaskDate.current.getValue();
        const taskTime = refTaskTime.current.getValue();
        const assignSelected = refAssignTo.current.getValue();
        const finalDate = combineDateTimeToISO(taskDate, taskTime);

        onUpdate({
            ...taskDetail,
            assignee: {
                ...assignSelected,
                full_name: assignSelected.first_name.concat(' ', assignSelected.last_name)
            },
            date: finalDate,
            start: finalDate,
            title: taskName
        });

        const optionsQuery = {
            method: 'PUT',
            data: {
                title: taskName,
                date: finalDate,
                assignee: assignSelected.id
            }
        };

        if (socketId) optionsQuery['data'] = { ...optionsQuery['data'], socket_id: socketId };

        clientQuery(updateTask(taskId), optionsQuery);
        _handleCancelEdit();
    }

    if (!finalIsVisible) {
        return false;
    }

    return (
        <div className="content has-form is-edit">
            <div className="content__task">
                <h5 className="fs-18">Edit task</h5>
                <StatusBar ref={refStatusBar} />
                <AssignTo defaultValue={taskDetail?.assignee?.id || ''} ref={refAssignTo} />
                <div className="rows row-haft">
                    <Date defaultValue={dateStart} ref={refTaskDate} />
                    <Time defaultValue={moment(dateStart).utc().format('h:mm a')} ref={refTaskTime} />
                </div>
                <TaskName defaultValue={taskDetail.title} ref={refTaskName} />
            </div>
            <div className="footer --align-right">
                <div className="v2-btn-default --transparent js-task-edit" onClick={_handleCancelEdit}>
                    Cancel
                </div>
                <div className="v2-btn-main" onClick={_handleSaveNewTask}>
                    Save
                </div>
            </div>
        </div>
    );
});

export default EditTask;
