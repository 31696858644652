import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import ServiceDashboard from '../../ServiceDashboard';
import WidgetDatePicker from '../../components/datepicker';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_DATA_STATS, LIST_WIDGETS, getDefaultParamsWidget } from '../../const';
import LoadingStats from '../loading/LoadingStats';
import { transformToCurrency, formatNumberWithCommas } from 'common/utils/NumberUtils';
import { useSelector } from 'react-redux';

export default function WidgetStats() {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.STATS;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData } = state;

    useEffect(() => {
        fetchStatsData(defaultParams);
    }, []);

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchStatsData(newParams);
    };

    const fetchStatsData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleGetDataSuccess = ({ data }) => {
        dispatchState((prev) => ({
            ...prev,
            data,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderStats = () => {
        if (finalIsLoading) {
            return <LoadingStats />;
        }

        const _renderItem = () => {
            return LIST_DATA_STATS.map(({ id, label, isBgBlue, isTransform }) => {
                const value = finalData[id];
                const valueRender = isTransform
                    ? transformToCurrency(value, currency)
                    : formatNumberWithCommas(parseInt(value));

                return (
                    <div key={id} className={classNames('col', { 'bg-blue-jeans': isBgBlue })}>
                        <p className="fs-13 txt-ellipsis">{t(label)}</p>
                        <p className="fs-26 txt-ellipsis price" title={valueRender}>
                            {valueRender}
                        </p>
                    </div>
                );
            });
        };
        return (
            <div className="boxs__content is-summary">
                <div className="rows">{_renderItem()}</div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="boxs --full">
                <div className="boxs__header">
                    <div className="header-label flex-1">Stats</div>
                    <WidgetDatePicker
                        key={keyWidget}
                        keyWidget={keyWidget}
                        isShowIcon
                        selectDefault={defaultParams}
                        onSelect={(values) => _handleChangeFilterWidget({ ...values })}
                    />
                </div>
                {_renderStats()}
            </div>
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </Fragment>
    );
}
