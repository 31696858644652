import { INVOICE_PAYMENT_STATUS } from 'app/modules/jobdetail/const/Invoice';
import AddPaymentContextProvider from 'app/modules/jobdetail/contexts/AddPaymentContext';
import Amount from 'app/modules/jobdetail/tabs/payment/components/Amount';
import Balance from 'app/modules/jobdetail/tabs/payment/components/Balance';
import CustomerInfo from 'app/modules/jobdetail/tabs/payment/components/CustomerInfo';
import SelectDate from 'app/modules/jobdetail/tabs/payment/components/Date';
import InvoiceStatus from 'app/modules/jobdetail/tabs/payment/components/InvoiceStatus';
import PaymentMethods from 'app/modules/jobdetail/tabs/payment/components/payment';
import PaymentFooter from 'app/modules/jobdetail/tabs/payment/components/PaymentFooter';
import PaymentInvoice from 'app/modules/jobdetail/tabs/payment/components/PaymentInvoice';
import PaymentSummary from 'app/modules/jobdetail/tabs/payment/components/PaymentSummary';
import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import IconClose from 'assets/icon/IconClose';
import StatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddPayment } from 'app/modules/mixpanel/MixpanelAddPayment';
import { useSelector } from 'react-redux';

const CustomerAddPayment = forwardRef(({ onSubmit = () => {}, isPaymentCustomer = false }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { customer } = useContext(CustomerDetailContext);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        dataInvoice: {}
    });
    const refStatusBar = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (dataInvoice = {}) => {
        dispatchState({ isOpen: true, dataInvoice });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    function _addPaymentSuccess(message, type) {
        refStatusBar.current.showStatusBar('show_message', message, type);
        if (type === LIST_STATUS.SUCCESS) {
            handleTrackingEvent(mixpanelAddPayment({ id: profileId }));
            onSubmit();
        }
    }

    return (
        <ReactModal
            id="edit_payment"
            isOpen={state.isOpen}
            onRequestClose={_close}
            style={{ overlay: { zIndex: 9999, background: 'transparent' } }}
            className="modal --new-job --new-payment container-modal open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customers:add_payment')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>

                <AddPaymentContextProvider
                    paymentData={{
                        customerId: customer.id,
                        filterInvoice: INVOICE_PAYMENT_STATUS[2].value,
                        customerInfo: customer,
                        isDefaultCustomer: true
                    }}
                >
                    <StatusBar ref={refStatusBar} />
                    <div className="body-modal form-tabpane-details scrolls">
                        <div className="container-column form-add-payment">
                            <div className="rows --header-info">
                                <div className="customer-info">
                                    <div className="customer-info-number">
                                        <Balance currentBalance={customer.balance} />
                                        <CustomerInfo
                                            customer={{ full_name: `${customer.first_name} ${customer.last_name}` }}
                                        />
                                        <Amount autoFocus={!!customer.id} />
                                        <SelectDate />
                                        <PaymentMethods />
                                        <InvoiceStatus />
                                    </div>
                                </div>
                            </div>
                            <PaymentInvoice invoiceData={{}} isPaymentCustomer={isPaymentCustomer} />
                            <PaymentSummary />
                        </div>
                    </div>
                    <div className="footer-modal btn-close">
                        <PaymentFooter onCallback={_addPaymentSuccess} />
                    </div>
                </AddPaymentContextProvider>
            </div>
        </ReactModal>
    );
});

export default CustomerAddPayment;
