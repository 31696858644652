import React, { forwardRef, useImperativeHandle, useState } from 'react';

const LoadMore = forwardRef((props, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    function _handleShow() {
        setIsLoading(true);
    }

    function _removeLoading() {
        isLoading && setIsLoading(false);
    }

    useImperativeHandle(ref, () => ({
        showLoading: _handleShow,
        removeLoading: _removeLoading,
        getStatus: () => {
            return isLoading;
        }
    }));

    if (!isLoading) {
        return false;
    }

    return (
        <div className="rows-load-more">
            <div className="rows-load-more__btn flex-centeritem">
                <div className="preloader-small">
                    <span className="preloader-small__items" />
                </div>
            </div>
        </div>
    );
});

export default LoadMore;
