export const TYPE_CUSTOMER = {
    GET_CUSTOMER_INFORMATION: 'GET_CUSTOMER_INFORMATION',
    GET_LIST_SEARCH_CUSTOMER: 'GET_LIST_SEARCH_CUSTOMER',
    GET_LIST_MAP_CUSTOMER: 'GET_LIST_MAP_CUSTOMER',
    GET_LIST_CUSTOMER: 'GET_LIST_CUSTOMER_REQUEST',
    MASK_AS_CUSTOMER: 'MASK_AS_CUSTOMER',
    UPDATE_STATUS_CUSTOMER: 'UPDATE_STATUS_CUSTOMER',
    REFRESH_LIST_CUSTOMER_REPORT: 'REFRESH_LIST_CUSTOMER_REPORT',
    REFRESH_LIST_CUSTOMER_REPORT_SUCCESS: 'REFRESH_LIST_CUSTOMER_REPORT_SUCCESS',
    REFRESH_LIST_CUSTOMER: 'REFRESH_LIST_CUSTOMER',
    GET_LIST_CUSTOMER_SUCCESS: 'GET_CUSTOMER_LIST_SUCCESS',
    GET_LIST_CUSTOMER_FAILED: 'GET_CUSTOMER_LIST_FAILED',
    GET_STATISTIC_CUSTOMER: 'GET_STATISTIC_CUSTOMER',
    MERGE_CUSTOMERS: 'MERGE_CUSTOMERS',
    GET_CUSTOMER_ACCOUNT_NUMBER: 'GET_CUSTOMER_ACCOUNT_NUMBER',
    GET_LIST_SETTING_COMPANY_PHONE_TYPE: 'GET_LIST_SETTING_COMPANY_PHONE_TYPE',
    GET_LIST_CUSTOMER_SOURCE: 'GET_LIST_CUSTOMER_SOURCE',
    GET_LIST_CUSTOMER_SEARCH_TAGS: 'GET_LIST_CUSTOMER_SEARCH_TAGS',
    GET_LIST_CUSTOMERS_SENDING: 'GET_LIST_CUSTOMERS_SENDING',
    GET_LIST_CUSTOMER_CHARACTER: 'GET_LIST_CUSTOMER_CHARACTER'
};

export const getCustomerInformation = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_CUSTOMER_INFORMATION,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListSearchCustomer = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_SEARCH_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCustomerCharacter = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_CUSTOMER_CHARACTER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCustomer = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMapCustomer = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_MAP_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const maskAsCustomers = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.MASK_AS_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateStatusCustomer = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.UPDATE_STATUS_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const refreshListCustomer = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getStatisticCustomer = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.GET_STATISTIC_CUSTOMER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const mergeCustomers = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.MERGE_CUSTOMERS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getCustomerAccountNumber = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_CUSTOMER_ACCOUNT_NUMBER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListSettingCompanyPhoneType = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_SETTING_COMPANY_PHONE_TYPE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCustomerSource = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_CUSTOMER_SOURCE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCustomersearchTags = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_CUSTOMER_SEARCH_TAGS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListCustomerReport = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER_REPORT,
        params,
        actionSuccess,
        actionFailed
    };
};

export const calendarGetListCustomerSending = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CUSTOMER.GET_LIST_CUSTOMERS_SENDING,
        params,
        actionSuccess,
        actionFailed
    };
};
