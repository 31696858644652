import React from 'react';

const LoadingSmsList = () => {
    return (
        <div className="message-contents scrolls">
            <div className="box-conversation">
                <div className="conts-conversation">
                    <div className="load-inline">
                        <div className="wrapper-message box-message-send mt-6">
                            <div className="wrap-items">
                                <div className="description loading --animation --half" />
                                <div className="loading --animation --line --twothird" />
                            </div>

                            <div className="images">
                                <div className="avt loading --animation" />
                            </div>
                        </div>
                    </div>
                    <div className="load-inline">
                        <div className="wrapper-message box-message-send mt-6">
                            <div className="wrap-items">
                                <div className="description loading --animation --half" />
                                <div className="loading --animation --line --twothird" />
                            </div>

                            <div className="images">
                                <div className="avt loading --animation" />
                            </div>
                        </div>
                    </div>
                    <div className="load-inline">
                        <div className="wrapper-message box-message-send mt-6">
                            <div className="wrap-items">
                                <div className="description loading --animation --half" />
                                <div className="loading --animation --line --twothird" />
                            </div>

                            <div className="images">
                                <div className="avt loading --animation" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingSmsList;
