import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import { PAYMENT_CARD_CUSTOMER } from '../Customers';

export const getGridColumns = (columns = [], type = PAYMENT_CARD_CUSTOMER.STRIPE, actionsStatus) => {
    const newContent = {};
    const newColumns = [];

    const isStripe = type === PAYMENT_CARD_CUSTOMER.STRIPE;
    const columnsEmail = isStripe ? 'stripe_email' : 'square_email';
    const columnsID = isStripe ? 'stripe_id' : 'square_id';

    const HEADER_SUBSCRIPTION = {
        account_number: {
            title: 'account_number_#',
            style: 'col col-md'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            isShow: true
        },
        email: { title: columnsEmail, style: 'col col-email' },
        subscription_id: { title: columnsID, style: 'col col-lg' },
        payment_card: {
            title: 'payment_method',
            style: 'col --card'
        },
        user_id: {
            title: 'assigned_to',
            style: 'col col-md'
        },
        status: {
            title: 'status',
            style: 'col col-md'
        },
        plan: { title: 'plan', style: 'col' },
        total_value: { title: 'total_value', style: 'col col-total' },
        started: {
            title: 'started',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        end_date: {
            title: 'end_date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        next_invoice: {
            title: 'next_invoice',
            style: 'col col-lg'
        },
        edit: {
            title: '',
            style: 'col --menu',
            isShow: true
        }
    };

    const REPORT_COLUMNS_SUBSCRIPTION = [
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true,
            isShow: true
        },
        { id: 'email', title: columnsEmail, status: true },
        { id: 'subscription_id', title: columnsID, status: true },
        {
            id: 'payment_card',
            title: 'payment_method',
            status: true
        },
        {
            id: 'user_id',
            title: 'assigned_to',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        { id: 'plan', title: 'plan', status: true },
        { id: 'total_value', title: 'total_value', status: true },
        { id: 'started', title: 'started', status: true },
        { id: 'end_date', title: 'end_date', status: true },
        {
            id: 'next_invoice',
            title: 'next_invoice',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true,
            isShow: true
        }
    ];

    const REPORT_CONTENT_SUBSCRIPTION = {
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isShow: true
        },
        email: { id: 'email', style: 'col col-email' },
        subscription_id: { id: 'subscription_id', style: 'col col-lg' },
        payment_card: {
            id: 'payment_card',
            style: 'col --card',
            type: LIST_TABLE_ITEM_TYPE.PAYMENT_CARD_WITH_ACTION
        },
        user_id: {
            id: 'user_id',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.ASSIGNED_TO
        },
        status: {
            id: 'status',
            style: 'col col-md d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        plan: { id: 'plan', style: 'col' },
        total_value: { id: 'total_value', style: 'col col-total', type: LIST_TABLE_ITEM_TYPE.BALANCE },
        started: { id: 'started', style: 'col col-md' },
        end_date: { id: 'end_date', style: 'col col-md' },
        next_invoice: {
            id: 'next_invoice',
            style: 'col col-lg',
            combine: 'next_invoice'
        },
        edit: {
            title: '',
            style: 'col --menu',
            type: LIST_TABLE_ITEM_TYPE.SUBSCRIPTION_EDIT,
            isShow: true,
            typeSubscription: type
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, HEADER_SUBSCRIPTION);

    REPORT_COLUMNS_SUBSCRIPTION.forEach((col) => {
        const item = col.id;

        if (columns.includes(item) || col.isShow) {
            newHeader[item] = HEADER_SUBSCRIPTION[item];
            newContent[item] = REPORT_CONTENT_SUBSCRIPTION[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
