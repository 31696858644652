import React from 'react';
import { useTranslation } from 'react-i18next';

const Commission = ({ commission = {}, canDisplayProduction = true, canDisplaySoldBy = true }) => {
    const { t } = useTranslation(['common']);
    const { productions = [], sales = [] } = commission || {};
    const isSalesActive = sales?.some((item) => !!item.active);
    const isProductionsActive = productions?.some((item) => !!item.active);
    const finalDisplayProduction = canDisplayProduction && isProductionsActive && !!productions?.length;
    const finalDisplaySoldBy = canDisplaySoldBy && isSalesActive && !!sales?.length;

    if (!finalDisplayProduction && !finalDisplaySoldBy) return null;
    return (
        <div className="wrap-commission">
            {finalDisplayProduction ? (
                <RenderListCommission title={t('production')} data={productions || []} isProduction />
            ) : null}
            {finalDisplaySoldBy ? <RenderListCommission title={t('sales')} data={sales || []} /> : null}
        </div>
    );
};

const RenderListCommission = ({ title = '', data = [], isProduction = false }) => {
    if (!data.length) return null;
    return (
        <div className="commission-rows d-flex">
            <p className="commission-rows__title fs-10">{title}</p>
            <div className="commission-rows__details">
                {data.map((item, index) => (
                    <CommissionItem key={index.toString()} isProduction={isProduction} {...item} />
                ))}
            </div>
        </div>
    );
};

const CommissionItem = ({ user = null, format, active = false }) => {
    if (!user || !!!active) return null;
    return (
        <div className="user-items">
            <div className="avt-img">
                <img src={user.avatar} width={24} height={24} />
            </div>
            <p className="user-items__name">{user.full_name}</p>
            <span className="status-btn bg-green-default">{format}</span>
        </div>
    );
};

export default Commission;
