import React from 'react';

const RoutingAddonsLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="loading --onefifth switch ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --onefourth" />
                            <div className="loading --animation --line --full mt-5" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --ninety" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="status-btn loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --fifth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-4">
                <div className="wrap-process customer-process">
                    <div className="description loading-lines">
                        <div className="loading --animation --line --threefourth" />
                        <div className="loading --animation --line --ninety mt-5" />
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --onefifth" />
                        <div className="loading --animation --line --threefourth mt-5" />
                    </div>
                    <div className="rows mt-5">
                        <div className="rows__label">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="progress flex-1 loading mx-2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="rows__number">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows mt-4">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="progress flex-1 loading mx-2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="rows__number d-flex justify-end">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoutingAddonsLoading;
