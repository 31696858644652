export default function IconGorilla({ isSmall = false }) {
    if (isSmall) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none">
                <path
                    d="M15.9811 12.9203C15.9761 14.6086 14.6086 15.9761 12.9203 15.9811H3.0608C1.37244 15.9761 0.00498537 14.6086 0 12.9203V3.0608C0.00477876 1.37235 1.37235 0.00477876 3.0608 0H12.9203C14.6087 0.00477876 15.9763 1.37235 15.9811 3.0608V12.9203Z"
                    fill="#45424A"
                />
                <path
                    d="M15.275 12.8269C15.275 14.4784 14.0196 15.8299 12.4846 15.8299H3.49665C1.96171 15.8299 0.706299 14.4784 0.706299 12.8269V3.15392C0.706299 1.50248 1.96171 0.151367 3.49665 0.151367H12.4846C14.0196 0.151367 15.275 1.50248 15.275 3.15392V12.8269Z"
                    fill="#56535B"
                />
                <path
                    d="M14.2158 12.7091C14.2158 14.3201 13.1427 15.6379 11.8329 15.6379H4.14982C2.83843 15.6379 1.76685 14.3201 1.76685 12.7091V3.27292C1.76685 1.66195 2.83994 0.34375 4.14982 0.34375H11.8313C13.1427 0.34375 14.2143 1.66195 14.2143 3.27292L14.2158 12.7091Z"
                    fill="#605D66"
                />
                <path
                    d="M3.61465 3.74797C3.61465 3.74797 0.00878906 2.72669 0.00878906 6.29851C0.00878906 10.544 2.6312 10.3863 2.6312 10.3863L3.61465 3.74797Z"
                    fill="#45424A"
                />
                <path
                    d="M3.61462 3.74806C3.61462 3.74806 0.286016 3.13037 0.185401 6.33302C0.0847867 9.53567 2.74465 10.5187 2.74465 10.5187L3.61462 3.74806Z"
                    fill="#605D66"
                />
                <path
                    d="M12.3271 3.74797C12.3271 3.74797 15.933 2.72669 15.933 6.29851C15.933 10.544 13.3106 10.3863 13.3106 10.3863L12.3271 3.74797Z"
                    fill="#45424A"
                />
                <path
                    d="M12.3271 3.74806C12.3271 3.74806 15.6558 3.13037 15.7567 6.33302C15.8577 9.53567 13.1971 10.5187 13.1971 10.5187L12.3271 3.74806Z"
                    fill="#605D66"
                />
                <path
                    d="M15.141 6.44824C15.0949 4.59481 14.0305 4.02063 12.2565 4.18706C11.273 4.28048 9.41468 4.70148 7.97846 4.70148C6.54224 4.70148 4.68465 4.28048 3.69893 4.18706C1.92532 4.02063 0.862054 4.59292 0.814394 6.44824C0.764844 8.41892 2.03652 9.32899 2.78546 9.69212C3.24655 10.0046 3.20721 10.2882 3.22272 10.7259L3.05288 12.2551C2.8456 14.1377 4.43766 15.4415 7.99926 15.4415C11.5896 15.4415 13.1836 14.272 12.9025 12.2551L12.7263 10.6559C12.7414 10.0212 12.8836 9.97202 13.0792 9.73448C13.8092 9.40351 15.194 8.50214 15.141 6.44824Z"
                    fill="#E5CC94"
                />
                <path
                    d="M5.78343 7.41731C5.78358 8.0498 5.4027 8.62009 4.8184 8.86224C4.2341 9.10439 3.56147 8.9707 3.11418 8.52352C2.66689 8.07634 2.53304 7.40374 2.77504 6.81938C3.01705 6.23503 3.58725 5.854 4.21974 5.854C5.08319 5.854 5.78322 6.55386 5.78343 7.41731Z"
                    fill="#FEFCFC"
                />
                <path
                    d="M4.95138 7.41719C4.95153 7.71311 4.77339 7.97997 4.50005 8.09331C4.2267 8.20666 3.91199 8.14417 3.7027 7.93498C3.4934 7.72579 3.43075 7.41111 3.54395 7.13771C3.65716 6.86431 3.92393 6.68604 4.21984 6.68604C4.62371 6.68604 4.95117 7.01332 4.95138 7.41719Z"
                    fill="#45424A"
                />
                <path
                    d="M13.1972 7.41731C13.1974 8.0498 12.8165 8.62009 12.2322 8.86224C11.6479 9.10439 10.9753 8.9707 10.528 8.52352C10.0807 8.07634 9.94686 7.40374 10.1889 6.81938C10.4309 6.23503 11.0011 5.854 11.6336 5.854C12.497 5.854 13.197 6.55386 13.1972 7.41731Z"
                    fill="#FEFCFC"
                />
                <path
                    d="M12.3649 7.41719C12.3651 7.71311 12.187 7.97997 11.9136 8.09331C11.6403 8.20666 11.3256 8.14417 11.1163 7.93498C10.907 7.72579 10.8443 7.41111 10.9575 7.13771C11.0707 6.86431 11.3375 6.68604 11.6334 6.68604C12.0373 6.68604 12.3647 7.01332 12.3649 7.41719Z"
                    fill="#45424A"
                />
                <path
                    d="M9.32623 8.69092C8.78308 8.69092 8.34277 9.13122 8.34277 9.67437V10.6578H9.32623C9.86937 10.6578 10.3097 10.2175 10.3097 9.67437C10.3097 9.13122 9.86937 8.69092 9.32623 8.69092Z"
                    fill="#45424A"
                />
                <path
                    d="M6.65337 8.69092C7.19652 8.69092 7.63683 9.13122 7.63683 9.67437V10.6578H6.65337C6.11023 10.6578 5.66992 10.2175 5.66992 9.67437C5.66992 9.13122 6.11023 8.69092 6.65337 8.69092Z"
                    fill="#45424A"
                />
                <path
                    d="M9.10258 13.8728C9.10674 13.9772 9.09501 14.0967 9.13321 14.2329C9.16196 14.3672 9.24064 14.5177 9.21302 14.6429C9.196 14.7564 8.97511 14.8722 8.70541 14.7594C8.44442 14.6607 8.13463 14.3335 8.14787 13.8728C8.13463 13.4121 8.44442 13.0849 8.70541 12.9858C8.97511 12.8724 9.19714 12.9889 9.21302 13.1027C9.24064 13.2279 9.16196 13.3785 9.13321 13.5127C9.09501 13.6485 9.10674 13.7684 9.10258 13.8728Z"
                    fill="#45424A"
                />
                <path
                    d="M8.5572 2.49988C8.5572 2.49988 10.0449 1.81903 11.1293 2.39926C11.1293 2.39926 10.3225 0.911227 8.88514 1.23917C8.88514 1.23917 9.6163 0.482669 10.5744 0.482669C10.5744 0.482669 8.53185 -0.727733 6.0354 0.93657C6.0354 0.93657 7.37214 0.154726 9.26339 0.532977L8.12864 1.56711C8.12864 1.56711 8.80949 1.21383 9.81791 1.81903C9.81791 1.81903 9.13706 1.89468 8.5572 2.49988Z"
                    fill="#45424A"
                />
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
            <path
                d="M31.9622 25.8406C31.9522 29.2173 29.2173 31.9522 25.8406 31.9622H6.12161C2.74488 31.9522 0.00997073 29.2173 0 25.8406V6.12161C0.00955753 2.7447 2.7447 0.00955753 6.12161 0H25.8406C29.2175 0.00955753 31.9526 2.7447 31.9622 6.12161V25.8406Z"
                fill="#45424A"
            />
            <path
                d="M30.5498 25.6538C30.5498 28.9567 28.0389 31.6597 24.969 31.6597H6.99306C3.92318 31.6597 1.41235 28.9567 1.41235 25.6538V6.30784C1.41235 3.00496 3.92318 0.302734 6.99306 0.302734H24.969C28.0389 0.302734 30.5498 3.00496 30.5498 6.30784V25.6538Z"
                fill="#56535B"
            />
            <path
                d="M28.4317 25.4183C28.4317 28.6402 26.2855 31.2759 23.6657 31.2759H8.29965C5.67686 31.2759 3.53369 28.6402 3.53369 25.4183V6.54585C3.53369 3.32391 5.67989 0.6875 8.29965 0.6875H23.6627C26.2855 0.6875 28.4286 3.32391 28.4286 6.54585L28.4317 25.4183Z"
                fill="#605D66"
            />
            <path
                d="M7.22906 7.49593C7.22906 7.49593 0.017334 5.45338 0.017334 12.597C0.017334 21.088 5.26216 20.7725 5.26216 20.7725L7.22906 7.49593Z"
                fill="#45424A"
            />
            <path
                d="M7.22924 7.49611C7.22924 7.49611 0.572032 6.26075 0.370803 12.666C0.169573 19.0713 5.48929 21.0375 5.48929 21.0375L7.22924 7.49611Z"
                fill="#605D66"
            />
            <path
                d="M24.6543 7.49593C24.6543 7.49593 31.866 5.45338 31.866 12.597C31.866 21.088 26.6212 20.7725 26.6212 20.7725L24.6543 7.49593Z"
                fill="#45424A"
            />
            <path
                d="M24.6543 7.49611C24.6543 7.49611 31.3115 6.26075 31.5135 12.666C31.7155 19.0713 26.3943 21.0375 26.3943 21.0375L24.6543 7.49611Z"
                fill="#605D66"
            />
            <path
                d="M30.282 12.8965C30.1897 9.18962 28.0609 8.04125 24.5129 8.37411C22.546 8.56097 18.8294 9.40295 15.9569 9.40295C13.0845 9.40295 9.36931 8.56097 7.39787 8.37411C3.85063 8.04125 1.72411 9.18584 1.62879 12.8965C1.52969 16.8378 4.07304 18.658 5.57092 19.3842C6.49309 20.0091 6.41442 20.5765 6.44543 21.4517L6.10576 24.5103C5.6912 28.2754 8.87531 30.883 15.9985 30.883C23.1792 30.883 26.3671 28.5439 25.8051 24.5103L25.4525 21.3118C25.4828 20.0424 25.7672 19.944 26.1583 19.469C27.6184 18.807 30.3879 17.0043 30.282 12.8965Z"
                fill="#E5CC94"
            />
            <path
                d="M11.5669 14.8346C11.5672 16.0996 10.8054 17.2402 9.6368 17.7245C8.4682 18.2088 7.12294 17.9414 6.22836 17.047C5.33378 16.1527 5.06607 14.8075 5.55009 13.6388C6.0341 12.4701 7.1745 11.708 8.43948 11.708C10.1664 11.708 11.5664 13.1077 11.5669 14.8346Z"
                fill="#FEFCFC"
            />
            <path
                d="M9.90251 14.8344C9.90281 15.4262 9.54654 15.9599 8.99985 16.1866C8.45316 16.4133 7.82375 16.2883 7.40515 15.87C6.98656 15.4516 6.86125 14.8222 7.08766 14.2754C7.31407 13.7286 7.84761 13.3721 8.43943 13.3721C9.24717 13.3721 9.90209 14.0266 9.90251 14.8344Z"
                fill="#45424A"
            />
            <path
                d="M26.3942 14.8346C26.3946 16.0996 25.6328 17.2402 24.4642 17.7245C23.2956 18.2088 21.9503 17.9414 21.0558 17.047C20.1612 16.1527 19.8935 14.8075 20.3775 13.6388C20.8615 12.4701 22.0019 11.708 23.2669 11.708C24.9938 11.708 26.3938 13.1077 26.3942 14.8346Z"
                fill="#FEFCFC"
            />
            <path
                d="M24.7301 14.8344C24.7304 15.4262 24.3742 15.9599 23.8275 16.1866C23.2808 16.4133 22.6514 16.2883 22.2328 15.87C21.8142 15.4516 21.6889 14.8222 21.9153 14.2754C22.1417 13.7286 22.6752 13.3721 23.2671 13.3721C24.0748 13.3721 24.7297 14.0266 24.7301 14.8344Z"
                fill="#45424A"
            />
            <path
                d="M18.6522 17.3818C17.5659 17.3818 16.6853 18.2624 16.6853 19.3487V21.3156H18.6522C19.7385 21.3156 20.6191 20.435 20.6191 19.3487C20.6191 18.2624 19.7385 17.3818 18.6522 17.3818Z"
                fill="#45424A"
            />
            <path
                d="M13.3067 17.3818C14.393 17.3818 15.2737 18.2624 15.2737 19.3487V21.3156H13.3067C12.2205 21.3156 11.3398 20.435 11.3398 19.3487C11.3398 18.2624 12.2205 17.3818 13.3067 17.3818Z"
                fill="#45424A"
            />
            <path
                d="M18.2052 27.7457C18.2135 27.9544 18.19 28.1935 18.2664 28.4658C18.3239 28.7344 18.4813 29.0355 18.426 29.2859C18.392 29.5128 17.9502 29.7443 17.4108 29.5189C16.8888 29.3214 16.2693 28.6671 16.2957 27.7457C16.2693 26.8242 16.8888 26.1699 17.4108 25.9717C17.9502 25.7447 18.3943 25.9777 18.426 26.2054C18.4813 26.4558 18.3239 26.7569 18.2664 27.0255C18.19 27.297 18.2135 27.5369 18.2052 27.7457Z"
                fill="#45424A"
            />
            <path
                d="M17.1144 4.99976C17.1144 4.99976 20.0897 3.63806 22.2586 4.79853C22.2586 4.79853 20.645 1.82245 17.7703 2.47834C17.7703 2.47834 19.2326 0.965338 21.1488 0.965338C21.1488 0.965338 17.0637 -1.45547 12.0708 1.87314C12.0708 1.87314 14.7443 0.309452 18.5268 1.06595L16.2573 3.13423C16.2573 3.13423 17.619 2.42765 19.6358 3.63806C19.6358 3.63806 18.2741 3.78936 17.1144 4.99976Z"
                fill="#45424A"
            />
        </svg>
    );
}
