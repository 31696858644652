import IconGoogle from 'assets/icon/IconGoogle';
import React from 'react';

const ConnectWithGG = ({ onClick = () => {} }) => {
    return (
        <div className="btn-signin is-google" onClick={onClick}>
            <div className="btn-icon flex-centeritem">
                <IconGoogle />
            </div>
            <div className="btn-label flex-1">Sign in with G Suite</div>
        </div>
    );
};

export default ConnectWithGG;
