import { createPopper } from '@popperjs/core';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { uploadToS3 } from 'common/utils/FileUtils';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useReducer, useRef } from 'react';

function generateGetBoundingClientRect(x = 0, y = 0) {
    return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x
    });
}

const virtualElement = {
    getBoundingClientRect: generateGetBoundingClientRect()
};

const ImageDecorator = ({ block, contentState, blockProps }) => {
    const { typePresign = 'item', itemIdPresign = '', onResize, onUploadSuccess, type } = blockProps || {};
    if (type === 'HORIZONTAL_RULE') return <hr />;
    const currentEntity = block.getEntityAt(0);
    if (!currentEntity) return null;
    const data = contentState.getEntity(currentEntity).getData();
    const { isShouldUpload, isLoadingUpload, width, fileInfo, type: typeData } = data || {};
    if (typeData === 'HORIZONTAL_RULE') return <hr />;
    const [state, dispatchState] = useReducer(reducer, { isVisible: false });
    const { isVisible } = state;

    const refImage = useRef(null);
    const refUrl = useRef(null);

    useLayoutEffect(() => {
        if (isVisible) {
            _handleCreatePopper();
            document.addEventListener('click', _handleHide, true);
        } else {
            document.removeEventListener('click', _handleHide, true);
        }
        return () => {
            document.removeEventListener('click', _handleHide, true);
        };
    }, [isVisible]);

    useEffect(() => {
        if (isShouldUpload && fileInfo) {
            onResize({ entity: currentEntity, data: { ...data, isShouldUpload: false } });
            _addAttachWithQuery({
                id: fileInfo.lastModified,
                file: fileInfo,
                name: fileInfo.name,
                url: data.src,
                mime: fileInfo.type
            });
        }
    }, []);

    const _addAttachWithQuery = (attach) => {
        const nameFile = `web-${attach.name}`;
        const data = { name: nameFile, item_id: itemIdPresign || `${moment().format('x')}`, type: typePresign };
        const _addSuccess = ({ data = {} }) => {
            const { presigned_url, object_key, object_tag, public_url } = data;
            refUrl.current = public_url;

            uploadToS3(
                presigned_url,
                { 'x-amz-tagging': object_tag },
                attach.file,
                { object_key },
                () =>
                    _uploadS3Success({
                        object_key,
                        object_tag,
                        public_url,
                        name: nameFile,
                        size: attach.file.size,
                        mime: attach.file.type
                    }),
                _uploadS3Failure
            );
        };
        clientQuery(ATTACHMENT_PRESIGN, { data, method: 'POST' }, _addSuccess);
    };

    const _uploadS3Success = (dataImage) => {
        const dataEntity = {
            entity: currentEntity,
            data: { ...data, src: refUrl.current, isShouldUpload: false, isLoadingUpload: false }
        };
        onUploadSuccess(dataEntity, dataImage);
    };

    const _uploadS3Failure = (e) => {
        console.error(e);
    };

    const _handleHide = (event) => {
        const elPrevent = refImage.current;
        const tooltip = document.getElementById('box-images-popover');

        if (refImage.current && elPrevent && !elPrevent.contains(event.target) && !tooltip.contains(event.target)) {
            dispatchState({ isVisible: false });
        }
    };

    const _handleCreatePopper = () => {
        const tooltip = document.getElementById('box-images-popover');
        const arrow = tooltip.querySelector('.images-popover__arrow');
        const instance = createPopper(virtualElement, tooltip, {
            modifiers: [{ name: 'arrow', options: { element: arrow } }]
        });

        refImage.current.addEventListener('click', ({ clientX: x, clientY: y }) => {
            virtualElement.getBoundingClientRect = generateGetBoundingClientRect(x, y);
            instance.update();
        });
    };

    const _handleClick = () => {
        isVisible && _handleCreatePopper();
        dispatchState({ isVisible: true });
    };

    const _handleDeleteBlock = () => {
        blockProps.deleteBlock(contentState, block);
        dispatchState({ isVisible: false });
    };

    const _handleSetSize = (width) => {
        onResize({ entity: currentEntity, data: { width: width ? `${width}%` : 'fit-content', src: data.src } });
        dispatchState({ isVisible: false });
    };

    return (
        <>
            <div
                className={classNames('relative', { 'is-uploading': isLoadingUpload })}
                style={{ width: width || 'fit-content' }}
                onClick={_handleClick}
            >
                <img ref={refImage} draggable={false} onClick={_handleClick} src={data.src} width="100%" />
                {isLoadingUpload ? (
                    <div className="has-progress">
                        <span className="has-progress__bar" />
                    </div>
                ) : null}
            </div>
            {isVisible && <ComponentPopper onDelete={_handleDeleteBlock} onSetSize={_handleSetSize} />}
        </>
    );
};

const ComponentPopper = ({ onSetSize = () => {} }) => {
    const _handleSetSize = (width) => {
        onSetSize(width);
    };
    return (
        <div id="box-images-popover" className="box-images-popover">
            <div className="images-popover">
                <div className="images-popover__arrow" />
                <div className="images-popover__content">
                    <div className="popover-items tooltip" onClick={() => _handleSetSize(100)}>
                        100%
                        <span className="tooltiptext bottom">Resize full</span>
                    </div>
                    <div className="popover-items tooltip" onClick={() => _handleSetSize(50)}>
                        50%
                        <span className="tooltiptext bottom">Resize half</span>
                    </div>
                    <div className="popover-items tooltip" onClick={() => _handleSetSize(25)}>
                        25%
                        <span className="tooltiptext bottom">Resize quarter</span>
                    </div>
                    <div className="popover-items tooltip" onClick={() => _handleSetSize(null)}>
                        Original
                        <span className="tooltiptext bottom">Original</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageDecorator;
