/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */
import { call } from 'redux-saga/effects';

export function* getStatementHistories({ params, actionSuccess, actionFailed }) {
    try {
        const response = {
            success: true,
            data: [
                {
                    date: '12 March, Thu 2020',
                    name: 'Adaora Azubuike',
                    time: '02:34PM 16/03/2025'
                }
            ]
        };
        if (response && response.success) {
            actionSuccess(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}

export function* statementToPDF({ params, actionSuccess, actionFailed }) {
    try {
        const response = {
            success: true
        };
        if (response && response.success) {
            actionSuccess(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}

export function* statementSendByEmail({ params, actionSuccess, actionFailed }) {
    try {
        const response = {
            sucess: true
        };

        if (response && response.success) {
            actionSuccess(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}

export function* getStatementSumary({ params, actionSuccess, actionFailed }) {
    try {
        const response = {
            success: true,
            data: {
                invoiced: '$260.00',
                payments: '$18.20',
                available_credit: '$288.60',
                account_balance: '$100.00',
                customer_name: 'Customer 195',
                from: '01 January 2020',
                to: '11 July 2020',
                amount_total: '$18.20',
                paid_total: '$288.60',
                detail_total: '$100.00',
                statements: [
                    {
                        id: 1,
                        date: 'Jul 17, 2020',
                        payment_date: 'Jul 17, 2020',
                        invoice: '#15160070',
                        po_number: '304591411',
                        service: 'Ant Montly Service',
                        address: '910 Edinburgh Ln, Charlotte, NC 28209',
                        amount: '$4.50',
                        paid: '130.00',
                        balance: '$50.60'
                    },
                    {
                        id: 2,
                        date: 'Jul 17, 2020',
                        payment_date: 'Jul 17, 2020',
                        invoice: '#15160070',
                        po_number: '304591411',
                        service: 'Ant Montly Service',
                        address: '910 Edinburgh Ln, Charlotte, NC 28209',
                        amount: '$4.50',
                        paid: '130.00',
                        balance: '$50.60'
                    },
                    {
                        id: 3,
                        date: 'Jul 17, 2020',
                        payment_date: 'Jul 17, 2020',
                        invoice: '#15160070',
                        po_number: '304591411',
                        service: 'Ant Montly Service',
                        address: '910 Edinburgh Ln, Charlotte, NC 28209',
                        amount: '$4.50',
                        paid: '130.00',
                        balance: '$50.60'
                    },
                    {
                        id: 4,
                        date: 'Jul 17, 2020',
                        payment_date: 'Jul 17, 2020',
                        invoice: '#15160070',
                        po_number: '304591411',
                        service: 'Ant Montly Service',
                        address: '910 Edinburgh Ln, Charlotte, NC 28209',
                        amount: '$4.50',
                        paid: '130.00',
                        balance: '$50.60'
                    }
                ]
            }
        };
        if (response && response.success) {
            actionSuccess(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}
