import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ManageMultipleBranchHeader from './ManageMultipleBranchHeader';
import GdGridView from 'app/components/grid/GdGridView';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import { REPORT_TYPE } from 'app/const/Reports';
import { getGridColumnsMultipleBranchManage } from './ManageMultipleBranchTable';
import { ADDONS_MULTIPLE_BRANCH } from 'app/config/routes';
import { addBranchPath, isFetchedBranch } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    ADDONS_LIST_MULTIPLE_BRANCH,
    ADDONS_MULTIPLE_BRANCH_DETAIL,
    ADDONS_TOGGLE_ASSIGN_USER_MULTIPLE_BRANCH,
    actionUpdateBranch
} from 'app/const/Api';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { getDefaultParams } from 'app/const/report/ReportParams';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import ModalEditBranch from './ModalEditBranch';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { ACCESS_MANAGE_BRANCH } from 'app/const/Permissions';

const MultipleBranchManage = () => {
    const { t } = useTranslation('addons');

    const { state: stateLocation } = useLocation();
    const refFirstTime = useRef(true);
    const refAlert = useRef(null);
    const refModalEdit = useRef(null);
    const refHeader = useRef(null);

    const { permissions: permissionsList, company, profile: profileData } = useSelector(({ auth }) => auth.user);
    const { isGrowthPlan } = getPlanUser(profileData);
    const isPermissionMultipleBranch =
        checkPermission(permissionsList.enabled, ACCESS_MANAGE_BRANCH) && !company.is_branch && isGrowthPlan;

    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.MANAGE_MULTIPLE_BRANCH);
    const paramsMultipleBranch = getLocalStorage(keyLocalStore) || getDefaultParams(REPORT_TYPE.MANAGE_MULTIPLE_BRANCH);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true
    });

    const { data: finalData, isLoading: finalLoading } = state;

    useEffect(() => {
        if (!isPermissionMultipleBranch) return;
        _fetchListMultipleBranch(paramsMultipleBranch);

        if (stateLocation?.isCreateSuccess) {
            _showAlert({ message: t('create_new_branch_successfully'), success: true });
        }
    }, []);

    const _fetchListMultipleBranch = (paramsMultipleBranch) => {
        dispatchState({ isLoading: true });

        const _handleSuccess = ({ data: finalData }) => {
            refFirstTime.current = false;
            refHeader.current?.setDisableBtn(false);
            dispatchState((prev) => ({ ...prev, data: finalData, isLoading: false }));
        };

        const _handleFailure = (err) => {
            refHeader.current?.setDisableBtn(true);
            _handleFail(err);
        };

        clientQuery(
            ADDONS_LIST_MULTIPLE_BRANCH,
            { data: { status: paramsMultipleBranch.filterTab || '' }, method: 'GET' },
            _handleSuccess,
            _handleFailure
        );
    };

    const handleActions = () => {
        return {
            onChangeStatus: _handleChangeStatus,
            onAssign: _handleAssign,
            onEditBranch: _handleOpenEditBranch,
            onChangeColor: _handleChangeColor
        };
    };

    const _showAlert = ({ message, success }) => {
        refAlert.current?.showStatusBar(
            'multi_branch',
            message.length ? message : t(`addons:${!!success ? 'update_successfully' : 'action_failure'}`),
            !!success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        );
    };

    const _handleFail = (res) => {
        _showAlert(res);
        checkAccessFail(res, ACCESS_MANAGE_BRANCH);
        dispatchState({ isLoading: false });
    };

    const _handleChangeStatus = ({ branchID, data }) => {
        const _success = ({ message }) => {
            isFetchedBranch.isFetched = false;
            _showAlert({
                success: true,
                message: !!message?.length
                    ? message
                    : t(`addons:branch_action_successfully`, { action: data.label?.toLowerCase() })
            });
            dispatchState((prev) => {
                return {
                    ...prev,
                    data: prev.data.filter((data) => data.id !== branchID)
                };
            });
        };

        clientQuery(
            ADDONS_MULTIPLE_BRANCH_DETAIL,
            { data: { branch_id: branchID, status: data.id }, toFormData: false, method: 'PUT' },
            _success,
            _handleFail
        );
    };

    const _handleAssign = ({ branchID, usersID = [] }) => {
        const _success = () => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    data: prev.data.map((item) => {
                        if (item.id === branchID) {
                            return {
                                ...item,
                                users: usersID
                            };
                        }
                        return item;
                    })
                };
            });
        };

        clientQuery(
            ADDONS_TOGGLE_ASSIGN_USER_MULTIPLE_BRANCH,
            { data: { branch_id: branchID, assignees: usersID }, toFormData: false, method: 'PUT' },
            _success,
            _handleFail
        );
    };

    const _handleUpdate = () => {
        _fetchListMultipleBranch(getLocalStorage(keyLocalStore));
    };

    const _handleOpenEditBranch = (item) => {
        refModalEdit.current.open(item);
    };

    const _handleUpdateSuccess = ({ id, name, avatar }) => {
        _showAlert({ message: t('branch_updated_successfully'), success: true });
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.id === id) {
                    return { ...item, name, avatar };
                }
                return item;
            })
        }));
    };

    const _handleChangeColor = ({ id, color }) => {
        const newData = finalData.find((item) => item.id === id);
        newData.color = color;
        dispatchState((prev) => ({ ...prev, data: finalData }));
        clientQuery(actionUpdateBranch(id), { method: 'PUT', data: newData, toFormData: false });
        isFetchedBranch.isFetched = false;
    };

    if (!isPermissionMultipleBranch) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div className="addons-wrap container-wrap container-table container-user-list">
            <div className="container-print container-wrap">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_MULTIPLE_BRANCH)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('multiple_branch')}
                    </Link>
                </div>
                <ManageMultipleBranchHeader ref={refHeader} />
                <GDStatusBar ref={refAlert} />
                <div className="wrap-tables flex-column relative">
                    <GdGridView
                        isLoading={finalLoading}
                        isHasDropdown
                        content={finalData.map((item) => ({
                            ...item,
                            ...handleActions(),
                            status: paramsMultipleBranch.filterTab
                        }))}
                        classTable="table-multi-column multiple-branch has-footer has-text-ellipsis"
                        fileTranslation={'addons'}
                        {...getGridColumnsMultipleBranchManage()}
                        isScroll
                        msgEmpty={t('addons:there_are_currently_no_branches_added')}
                        isEmptyFlat
                    />
                    <ReportPagination
                        reportType={REPORT_TYPE.MANAGE_MULTIPLE_BRANCH}
                        onSelect={_handleUpdate}
                        isMultipleBranchPage
                    />
                </div>
                <ModalEditBranch ref={refModalEdit} onUpdateSuccess={_handleUpdateSuccess} />
            </div>
        </div>
    );
};

export default MultipleBranchManage;
