export const TYPE_SMS = {
    SMS_GET_TOTAL_REQUEST: 'SMS_GET_TOTAL_REQUEST',
    SMS_GET_LIST_PHONE_REQUEST: 'SMS_GET_LIST_PHONE_REQUEST',
    SMS_GET_LIST_BY_CUSTOMER_REQUEST: 'SMS_GET_LIST_BY_CUSTOMER_REQUEST',
    SMS_GET_LIST_VARIABLE_REQUEST: 'SMS_GET_LIST_VARIABLE_REQUEST',
    SMS_SET_LIST_VARIABLE: 'SMS_SET_LIST_VARIABLE',
    SMS_UPDATE_STATUS_REQUEST: 'SMS_UPDATE_STATUS_REQUEST',
    OPEN_SMS_MODULE_DETAIL: 'OPEN_SMS_MODULE_DETAIL'
};

export const smsGetListPhones = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SMS.SMS_GET_LIST_PHONE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const smsGetListByCustomer = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SMS.SMS_GET_LIST_BY_CUSTOMER_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const smsGetListVariable = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SMS.SMS_GET_LIST_VARIABLE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const smsUpdateStatus = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SMS.SMS_UPDATE_STATUS_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const openSmsDetail = (payload) => {
    return {
        type: TYPE_SMS.OPEN_SMS_MODULE_DETAIL,
        payload
    };
};
