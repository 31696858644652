/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */
import {
    CUSTOMERS_LIST_CHARACTERS,
    getCustomerDetail,
    GET_CUSTOMER_ACCOUNT_NUMBER,
    GET_LIST_CUSTOMER,
    GET_LIST_CUSTOMERS_SENDING,
    GET_LIST_CUSTOMER_REPORT,
    GET_LIST_CUSTOMER_SOURCE,
    GET_LIST_SEARCH_CUSTOMER,
    GET_LIST_SETTING_COMPANY_PHONE_TYPE,
    MARK_AS_CUSTOMER,
    MERGE_CUSTOMERS,
    UPDATE_STATUS_CUSTOMER
} from 'app/const/Api';
import { TYPE_CUSTOMER } from 'common/redux/actions/customers/customerAction';
import { fetchWithToken, postWithToken } from 'common/utils/ApiUtils';
import { call, put } from 'redux-saga/effects';

export function* getCustomerInformation({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, getCustomerDetail(params));
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, UPDATE_STATUS_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess();
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getStatisticCustomer({ params, actionSuccess, actionFailed }) {
    try {
        // const response = yield call(fetchWithToken, CUSTOMER_GET_STATISTIC, params);
        const response = {
            success: true,
            data: {
                total_customers: 19312,
                with_service: 12092,
                without_service: 6209,
                total_leads: 1390,
                bounced_email: 250,
                expired_credit_card: 5,
                expiring_less_than_60_days: 12
            }
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListSearchCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, GET_LIST_SEARCH_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess(response);
            yield put({ type: TYPE_CUSTOMER.GET_LIST_CUSTOMER_SUCCESS });
        } else {
            actionFailed(response);
            yield put({ type: TYPE_CUSTOMER.GET_LIST_CUSTOMER_FAILED });
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCustomerCharacter({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CUSTOMERS_LIST_CHARACTERS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* markAsCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, MARK_AS_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* mergeCustomers({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, MERGE_CUSTOMERS, params);
        if (response && response.data) {
            actionSuccess(response);
            return;
        }
        actionFailed(response);
    } catch (error) {
        actionFailed(error);
    }
}

export function* getCustomerAccountNumber({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_CUSTOMER_ACCOUNT_NUMBER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListSettingCompanyPhoneType({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_SETTING_COMPANY_PHONE_TYPE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCustomerSource({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_CUSTOMER_SOURCE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCustomersearchTags({ params, actionSuccess, actionFailed }) {
    try {
        // const response = yield call(
        //   fetchWithToken,
        //   GET_LIST_CUSTOMER_TAGS,
        //   params
        // );
        const response = {
            success: true,
            data: ['tag1', 'tag2', 'tag3', 'tag4', 'tag5', 'tag6', 'tag7', 'tag8', 'tag9', 'tag10']
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCustomerReport({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_CUSTOMER_REPORT, params);
        if (response && response.success) {
            actionSuccess(response);
            if (params.localSaveStore) {
                yield put({
                    type: TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER_REPORT_SUCCESS,
                    data: response,
                    resetData: params.localResetData
                });
            }
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* calendarGetListCustomerSending({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_CUSTOMERS_SENDING, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
