import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import IconCogWheel from 'assets/icon/IconCogWheel';
import { MANAGE_BRANCH } from '../const';
import { ADDONS_MULTIPLE_BRANCH_MANAGE } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { ACCESS_MANAGE_BRANCH } from 'app/const/Permissions';

const MultipleBranchOptions = ({ options = [] }) => {
    const _renderOption = (option) => {
        switch (option.keyword) {
            case MANAGE_BRANCH:
                return <ManageBranchOption option={option} />;
            default:
                break;
        }
    };

    return options.map((option) => _renderOption(option));
};

export default MultipleBranchOptions;

const ManageBranchOption = ({ option }) => {
    const { t } = useTranslation('addons');

    const { permissions, profile } = useSelector(({ auth }) => auth.user);
    const { isGrowthPlan } = getPlanUser(profile);
    const isHavePermission = checkPermission(permissions.enabled, ACCESS_MANAGE_BRANCH) && isGrowthPlan;

    return (
        <div className="boxs boxs--power-up boxs-has-footer">
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{option.name}</div>
                </div>
            </div>
            <div className="boxs__contents">
                <p className="description">{option.description}</p>
                <span className="--hasline" />
                <div className="flex-betweenitems">
                    <p>{t('active_branches')}</p>
                    <p className="fw-500">{option.usage.total}</p>
                </div>
            </div>
            <div className="boxs__footer">
                <Link
                    to={addBranchPath(ADDONS_MULTIPLE_BRANCH_MANAGE)}
                    className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                        'is-disable': !!option.upgrade || !isHavePermission
                    })}
                >
                    {t('addons:manage')} <IconCogWheel />
                </Link>
            </div>
        </div>
    );
};
