import { convertSecondsToTime } from 'common/utils/DateUtils';
import React, { useEffect, useState } from 'react';

export default function TimeCounter({
    wrapperClassName = 'action-label',
    isUseTime = false,
    isHour = false,
    startAt = 0
}) {
    const [count, setCount] = useState(startAt || 0);

    useEffect(() => {
        setInterval(() => {
            setCount((prev) => prev + 1);
        }, 1000);
    }, []);

    const _renderContent = () => {
        if (isUseTime) return convertSecondsToTime(count, isHour);

        return `${Math.floor(count / 60)}:${count % 60}`;
    };

    return <span className={wrapperClassName}>{_renderContent()}</span>;
}
