import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import IconLoading from 'assets/icon/IconLoading';
import { GET_LIST_TEMPLATE } from 'app/const/api/Todo';
import { clientQuery } from 'common/utils/ApiUtils';

export default function Template({ onSelect = () => {} }) {
    const { t } = useTranslation(['common', 'jobDetail']);

    const listRef = useRef(null);

    const [state, setState] = useReducer(reducer, {
        isLoading: true,
        isLoadingMore: false,
        data: [],
        isVisible: false,
        keyword: '',
        total: 0
    });

    const { isLoading, data, keyword, isLoadingMore, total: finalTotal } = state;

    useEffect(() => {
        _getTodos({ limit: 20, offset: 0, total: 1 });
    }, []);

    const _getTodos = (params) => {
        const _success = ({ data, total }) => {
            setState((prev) => ({
                ...prev,
                data: [...prev.data, ...data],
                isLoading: false,
                isLoadingMore: false,
                total: total || prev.total
            }));
        };

        const _fail = () => {
            setState({ isLoading: false, isLoadingMore: false });
        };

        clientQuery(GET_LIST_TEMPLATE, { method: 'GET', data: params }, _success, _fail);
    };

    const _handleSelectItem = (templateId) => {
        onSelect(templateId);
    };

    function _handleChangeSearch(text) {
        setState((prev) => ({
            ...prev,
            keyword: text,
            data: [],
            isLoading: true,
            isLoadingMore: false
        }));

        _getTodos({ limit: 20, offset: 0, total: 1, keyword: text });

        listRef.current.scrollTo(0, 0);
    }

    function _handleOnScrollContent(e) {
        const currentLength = data.length;

        if (!isLoading && !isLoadingMore && isScrollToEndBottom(e.target) && data.length < finalTotal) {
            setState((prev) => ({
                ...prev,
                isLoadingMore: true
            }));
            _getTodos({ limit: 20, offset: currentLength, total: 0, keyword });
        }
    }

    if (isLoading) {
        return (
            <div className="wrap-loading">
                <div className="flex-auto header-search">
                    <div className="field-h32 field-input border-grey-softlight pointer-events-none">
                        <div className="loading --animation --line --half"></div>
                    </div>
                </div>
                <div className="field-h32 px-3 mt-1">
                    <div className="loading --animation --line --threefourth" />
                </div>
            </div>
        );
    }

    function _renderListTemplate() {
        if (!data.length) {
            return (
                <li className="items">
                    <p className="word-break">{t('common:not_found')}</p>
                </li>
            );
        }
        return data.map((item) => {
            return (
                <li
                    onClick={() => {
                        _handleSelectItem(item.id);
                    }}
                    key={item.id}
                    className="items"
                >
                    <p className="word-break">{item.name}</p>
                </li>
            );
        });
    }

    return (
        <Fragment>
            <SearchOption
                timeDdebound={500}
                onSearch={_handleChangeSearch}
                isSearchIcon
                defaultValue={keyword}
                placeholder={t('jobDetail:search_templates')}
            />
            <ul ref={listRef} className="box-auto scrolls" onScroll={_handleOnScrollContent}>
                {_renderListTemplate()}
                {isLoadingMore && (
                    <li className="items justify-center">
                        <div className="loading -ajaxbar">
                            <IconLoading />
                        </div>
                    </li>
                )}
            </ul>
        </Fragment>
    );
}
