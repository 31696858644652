import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GdGridView from 'app/components/grid/GdGridView';
import { REPORT_RECURRING_INVOICE_GET_LIST } from 'app/const/Api';
import { DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT } from 'app/const/Reports';
import { URL_REPORT_RECURRING_INVOICE } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/RecurringInvoices';
import { RECURRING_INVOICE_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, createRowTotal, getLocalParamsReport, getTitleRowTotal } from 'common/utils/ReportUtils';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const FilterReport = loadable(() => import('app/modules/report/components/FilterReport'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));

function ReportRecurringInvoices() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refStatusBar = useRef(null);
    const abortController = useRef(null);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        data: [],
        totalPage: 1,
        isLoading: true,
        isLoadingMore: false,
        total: 0,
        rowTotal: [],
        refreshScreen: 0,
        reloadFilter: 0
    });
    const { refreshScreen, isLoadingMore, total: finalTotal, isLoading: finalIsLoading, data: finalData } = dataReport;

    const finalTypeReport = REPORT_TYPE.RECURRING_INVOICE;
    const KEY_REPORT_RECURRING_INVOICE = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);

    const paramsReport = getLocalParamsReport(KEY_REPORT_RECURRING_INVOICE, finalTypeReport);

    const totalItemsExport = dataReport.total;

    useEffect(() => {
        getListReport(true, paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        const loadMoreTimer =
            isLoadingMore &&
            setTimeout(() => {
                getListReport(
                    false,
                    { ...paramsReport, offset: finalData.length, reloadFilter: dataReport.reloadFilter },
                    true
                );
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [isLoadingMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const checkRefresh = dataReport.reloadFilter;
        const columns = params?.columns;

        checkShouldLoading &&
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading
                };
            });

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;
        delete params?.reloadFilter;

        clientQuery(
            REPORT_RECURRING_INVOICE_GET_LIST,
            {
                data: convertParamFields({ params: { ...params, limit: REPORT_LIMIT }, isEmptyFields: isLoadingMore }),
                method: 'GET',
                abortController: abortController.current
            },
            (response) => getListSuccess(response, checkRefresh, isReset, columns),
            getListFailed
        );
    };

    const _handleCreateRowTotal = ({ data = {}, columns = [], total = 0 }) => {
        return createRowTotal({
            data,
            columns,
            title: getTitleRowTotal({ title: 'recurring_invoice', total }),
            typeReport: finalTypeReport
        });
    };

    const getListSuccess = ({ data, total, total_recurrence }, tabQuery, isReset, columns) => {
        dispatchActionReport((prev) => {
            const {
                reloadFilter: prevReloadFilter,
                data: prevData,
                total: prevTotal,
                isLoadingMore: prevIsLoadingMore,
                totalRecurrence: prevTotalRecurrence,
                refreshScreen: prevRefreshScreen,
                rowTotal: prevRowTotal
            } = prev;
            const checkDiffrentTabs = !!tabQuery && prevReloadFilter !== tabQuery;
            const newData = isReset ? data : checkDiffrentTabs ? prevData : [...prevData, ...data];
            const finalTotal = total ?? prevTotal ?? 0;

            return {
                ...prev,
                isLoading: false,
                isLoadingMore: false,
                data: newData,
                total: finalTotal,
                rowTotal: prevIsLoadingMore
                    ? prevRowTotal
                    : _handleCreateRowTotal({ data: total_recurrence, columns, total: finalTotal }),
                totalRecurrence: prevIsLoadingMore ? prevTotalRecurrence : total_recurrence,
                refreshScreen: prevRefreshScreen + 1
            };
        });
    };

    function getListFailed(response) {
        if (!response?.isAborted) {
            refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
            dispatchActionReport((prev) => ({ ...prev, isLoading: false, total: 0, isLoadingMore: false }));
        }
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            dispatchActionReport((prev) => ({
                ...prev,
                rowTotal: _handleCreateRowTotal({
                    data: prev.totalRecurrence,
                    columns: params?.columns,
                    total: prev.total
                })
            }));
        }
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        const params = getLocalStorage(KEY_REPORT_RECURRING_INVOICE);
        dispatchActionReport({ rowTotal: [], total: 0 });
        getListReport(true, params);
    };

    const _handleScroll = () => {
        if (!isLoadingMore && finalTotal > finalData.length) {
            const refreshId = Date.now();

            dispatchActionReport((prev) => ({ ...prev, isLoadingMore: true, reloadFilter: refreshId }));
        }
    };
    function _renderHeaderLeft() {
        return (
            <>
                <FilterReport
                    reportType={finalTypeReport}
                    filters={RECURRING_INVOICE_LIST_FILTER}
                    onSelect={handleChangeFilter}
                />
            </>
        );
    }

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: totalItemsExport })}
                    params={paramsReport}
                    url={URL_REPORT_RECURRING_INVOICE}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_RECURRING_INVOICE}
                    activePrint
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={finalTypeReport}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    const _handleOpenJobDetail = ({ key, row }) => {
        if (key === 'job_name') {
            dispatch(actionOpenJobDetail({ id: row.job_id }));
        }
    };

    const _handleOpenInvoice = (invoiceId) => {
        dispatch(
            actionOpenInvoice({
                id: invoiceId,
                status: '',
                total: '',
                isRecurring: true
            })
        );
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: finalTypeReport,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={finalTypeReport}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <div className="wrap-tables flex-column">
                        <StatusBar ref={refStatusBar} />
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={dataReport.data}
                            fileTranslation={'report'}
                            handleClick={_handleOpenJobDetail}
                            onOpenInvoice={_handleOpenInvoice}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGridColumns(paramsReport?.columns)?.contentConfig}
                                    {...props}
                                />
                            )}
                            isScroll
                            showRowTotalOnTop
                            isLoadmore={isLoadingMore}
                            onScrollToEnd={_handleScroll}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRecurringInvoices;
