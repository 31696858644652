import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconClose from 'assets/icon/IconClose';
import IconPhone from 'assets/icon/IconPhone';
import IconTooltip from 'assets/icon/IconTooltip';
import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import { AudioSettings } from './AudioSettings';
import { MakeCall } from './MakeCall';
import ManagePhones from './ManagePhones';
import { RecordingCall } from './RecordingCall';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { DEVICE_ID_AUDIO_DEFAULT, KEY_PHONE_SETTINGS, TYPE_DEVICE_AUDIO } from 'app/const/Voip';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';
import ServiceManagePhones from './ServiceManagePhones';

export const PhoneSettings = () => {
    const { calls } = useContext(VOIPContext);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        number: [],
        isLoading: true
    });
    const { isVisible, numbers: finalNumbers, isLoading } = state;
    const isDisable = calls.length !== 0;

    const refToggleSetting = useRef(null);
    const refManagePhone = useRef(null);
    const refFirstTime = useRef(true);
    const refServiceManagePhone = useRef(null);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const triggerNumber = useSelector(({ voipReducer }) => voipReducer.triggerNumber);

    const isActiveVoip = useSelector(({ auth }) => !!auth?.user?.settings?.voip);
    const isHaveVoip = useSelector(({ auth }) => !!auth?.user?.settings?.addons?.voip);
    const isEnableAddons = isActiveVoip && isHaveVoip;

    useEffect(() => {
        if (!refFirstTime.current || !companyUsers.length || !isEnableAddons || !isActiveVoip) return;
        refFirstTime.current = false;
        refServiceManagePhone.current.getNumbers();
    }, [isVisible, companyUsers]);

    useEffect(() => {
        refFirstTime.current = true;
    }, [triggerNumber]);

    useEffect(() => {
        checkPermissionMedia();
    }, [isEnableAddons]);

    const _getNumberSuccess = (listPhones) => {
        dispatchState((prev) => ({
            ...prev,
            numbers: listPhones,
            isLoading: false
        }));
    };

    const _getListFail = () => {
        dispatchState((prev) => ({
            ...prev,
            numbers: [],
            isLoading: false
        }));
    };

    const paramsSetting = getLocalStorage(KEY_PHONE_SETTINGS);
    if (!paramsSetting) {
        setLocalStorage(KEY_PHONE_SETTINGS, getDefaultParamsPhoneSettings());
    }

    const handleClose = (delay = 0) => {
        setTimeout(() => {
            refToggleSetting.current?._closeDropdown();
        }, delay);
    };

    const handleVisible = (value) => {
        dispatchState({ isVisible: value });
    };

    const checkPermissionMedia = async () => {
        if (!isEnableAddons) return;

        navigator.permissions.query({ name: 'microphone' }).then(({ state }) => {
            if (state === 'prompt') {
                navigator.mediaDevices?.getUserMedia({ audio: true }).then((stream) =>
                    stream.getTracks().forEach(function (track) {
                        track.stop();
                    })
                );
            }
        });
    };

    const _handleGetPhone = () => {
        return refManagePhone.current._getNumberSelected();
    };

    const _handleSetPrimary = (item) => {
        dispatchState((prev) => ({
            ...prev,
            numbers: prev.numbers.map((number) => ({ ...number, is_primary: number.id === item.id ? 1 : 0 }))
        }));
    };

    if (!isEnableAddons) return null;

    return (
        <Fragment>
            <CalendarDropdown
                ref={refToggleSetting}
                buttonClassName="dropbtn v2-btn-default"
                wrapperListClass="v2-dropdown__menu --center"
                wrapperClassName="v2-dropdown nav-call-module"
                customDropButton={() => (
                    <Fragment>
                        <IconPhone />
                        <span className="arrow ml-1">
                            <IconArrowDown />
                        </span>
                    </Fragment>
                )}
                // isAlwayShowOptions'
                id="switch-classic-toggle"
                onVisible={() => handleVisible(true)}
                onHide={() => handleVisible(false)}
            >
                <div className="wrap-call-module">
                    <div className="flexcenter line-bottom-grey gap-module">
                        <div className="flexcenter flex-1">
                            <b className="fs-16">Phone Settings</b>
                            <span className="tooltip flexcenter ml-2">
                                <IconTooltip />
                                <span className="tooltiptext top">Make a call</span>
                            </span>
                        </div>
                        <span className="v2-btn-default --icon-lg --transparent btn-bg-grey" onClick={handleClose}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="wrap-call-module__content">
                        <ManagePhones
                            ref={refManagePhone}
                            isDisable={isDisable}
                            onClose={handleClose}
                            finalNumbers={finalNumbers}
                            isLoading={isLoading}
                            onSetPrimary={_handleSetPrimary}
                        />
                        <RecordingCall isDisable={isDisable} />
                        {isVisible && <AudioSettings isDisable={isDisable} />}
                    </div>
                    <MakeCall onGetPhone={_handleGetPhone} onClose={handleClose} />
                </div>
            </CalendarDropdown>
            <div className="is-divider" />
            <ServiceManagePhones
                ref={refServiceManagePhone}
                onGetListSuccess={_getNumberSuccess}
                onGetListFail={_getListFail}
            />
        </Fragment>
    );
};

export const getDefaultParamsPhoneSettings = () => {
    const { INPUT: typeInput, OUTPUT: typeOutput, RINGING: typeRinging } = TYPE_DEVICE_AUDIO;

    return {
        deviceSelected: {
            [typeInput]: { id: DEVICE_ID_AUDIO_DEFAULT },
            [typeOutput]: { id: DEVICE_ID_AUDIO_DEFAULT },
            [typeRinging]: { id: DEVICE_ID_AUDIO_DEFAULT }
        },
        isRecording: false
    };
};
