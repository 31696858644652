import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { getDetailLocation, getListLocationCustomer, getUpdateLocation } from 'app/const/Api';
import { DELETE_LOCATION_CUSTOMER } from 'app/const/api/V2';
import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { LIST_ERRORS_ADDRESS, ZIP_ST_NOT_REQUIRE_COUNTRYS_CODE } from 'app/const/Locations';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import LocationContent from 'app/modules/location/components/LocationContent';
import LocationModalLoading, { LocationFooterModalLoading } from 'app/modules/location/components/LocationModalLoading';
import LocationPreferences from 'app/modules/location/components/LocationPreferences';
import LocationTaxes from 'app/modules/location/components/LocationTaxes';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import LocationService from './services';

ReactModal.setAppElement(document.getElementById('root'));

const LocationModal = (props, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const GOOGLE_MAPS_CLIENT_KEY = useSelector(({ auth }) => auth.user.settings.google.geo_key);
    const companyCountry = useSelector(({ auth }) => auth.user.company.country);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isUpdate: false,
        canDelete: false,
        isLoading: true,
        dataUpdate: {},
        customerId: null,
        customer: {}
    });
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);
    const refTaxes = useRef(null);
    const refLocation = useRef(null);
    const refPreferences = useRef(null);
    const refConfirm = useRef(null);
    const refContainer = useRef(null);
    const {
        isOpen,
        isUpdate,
        canDelete,
        isLoading,
        locationId,
        dataUpdate,
        customerId,
        customer,
        onAddLocationSuccess = () => {},
        onUpdateLocationSuccess = () => {},
        onDeleteLocationSuccess = () => {}
    } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    useEffect(() => {
        if (isOpen) loadGoogleMapScript();
    }, [isOpen]);

    // load google map script
    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object' && callback) {
            callback();
        } else {
            if (!window?.google?.maps) {
                const googleMapScript = document.createElement('script');
                googleMapScript.src = GOOGLE_PLACES_API_URL(GOOGLE_MAPS_CLIENT_KEY);
                window.document.body.appendChild(googleMapScript);
                googleMapScript.addEventListener('load', callback);
            }
        }
    };

    const _open = (data) => {
        const locationId = data?.id;
        dispatchState({
            isOpen: true,
            locationId,
            isUpdate: !!locationId,
            isLoading: !!locationId,
            canDelete: data?.canDelete || false,
            customer: data?.customer,
            customerId: data?.customer?.id,
            onAddLocationSuccess: data.onAddLocationSuccess,
            onUpdateLocationSuccess: data.onUpdateLocationSuccess,
            onDeleteLocationSuccess: data.onDeleteLocationSuccess
        });
        if (locationId) _handleGetDetail(locationId);
    };

    const _close = () => {
        dispatchState({ isOpen: false, isUpdate: false, isLoading: true, dataUpdate: {} });
    };

    const _handleGetDetail = (idLocation) => {
        const _handleSuccess = ({ data }) => dispatchState({ isLoading: false, dataUpdate: data });
        clientQuery(getDetailLocation(idLocation), { method: 'GET', data: { fields: 'name' } }, _handleSuccess);
    };

    const _handleSubmit = () => {
        /* Close alert when submit data */
        refAlert.current.closeStatusBar();
        const errors = [];
        const locationData = refLocation.current._getValue();
        const serviceAddress = locationData.service_address;
        const billingAddress = locationData.billing_address;
        const isZipStCountryRequired = !ZIP_ST_NOT_REQUIRE_COUNTRYS_CODE.includes(companyCountry);

        for (const key in serviceAddress) {
            if (Object.hasOwnProperty.call(serviceAddress, key)) {
                const element = serviceAddress[key];
                if (
                    !element.trim().length &&
                    key !== 'street2' &&
                    key !== 'address_lat' &&
                    key !== 'address_lng' &&
                    key !== 'country' &&
                    key !== 'county' &&
                    isZipStCountryRequired
                ) {
                    errors.push(t(LIST_ERRORS_ADDRESS[key]));
                }
            }
        }

        if (!errors.length) {
            for (const key in billingAddress) {
                if (Object.hasOwnProperty.call(billingAddress, key)) {
                    const element = billingAddress[key];
                    if (
                        typeof element !== 'undefined' &&
                        !element.trim().length &&
                        key !== 'street2' &&
                        key !== 'country' &&
                        key !== 'county' &&
                        isZipStCountryRequired
                    ) {
                        errors.push(t(LIST_ERRORS_ADDRESS[key]));
                    }
                }
            }
        }

        if (errors.length) {
            refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message: errors, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
            refContainer.current.scrollTo(0, 0);

            return;
        }

        const dataCall = {
            ...locationData,
            ...refTaxes.current._getValue(),
            ...refPreferences.current._getValue(),
            tags: locationData.tags.map((item) => item.name)
        };
        delete dataCall.service_address.country;
        if (isUpdate) delete dataCall.mdu;
        isUpdate ? _handleCallUpdateLocation(dataCall) : _handleCallAddLocation(dataCall);
    };

    const _handleCallUpdateLocation = (data) => {
        const _handleUpdateSuccess = ({ data, message }) => {
            refLocation.current._updateTagGlobal();
            dispatchState({ isOpen: false, isUpdate: false, isLoading: true, dataUpdate: {} });
            onUpdateLocationSuccess(data, { id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
        };

        const _handleUpdateFailed = ({ message }) => {
            if (!isOpen) return;
            refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            getUpdateLocation(customerId, dataUpdate.id),
            { data, method: 'PUT', toFormData: false },
            _handleUpdateSuccess,
            _handleUpdateFailed
        );
    };

    const _handleCallAddLocation = (data) => {
        const _handleAddSuccess = ({ data, message }) => {
            refLocation.current._updateTagGlobal();
            dispatchState({ isOpen: false, isUpdate: false, isLoading: true, dataUpdate: {} });
            onAddLocationSuccess(data, { id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
        };

        const _handleAddFailed = ({ message }) => {
            if (!isOpen) return;
            refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            getListLocationCustomer(customerId),
            { data, method: 'POST', toFormData: false },
            _handleAddSuccess,
            _handleAddFailed
        );
    };

    const _handleDeleteLocation = () => {
        refConfirm.current._open();
    };

    const _handleCloseDelete = () => {
        refConfirm.current._close();
    };

    const _handleConfirmDelete = () => {
        clientQuery(
            DELETE_LOCATION_CUSTOMER,
            { data: { ids: [locationId] }, method: 'DELETE' },
            _handleDeleteSuccess,
            null,
            _handleDeleteFinally
        );
    };

    const _handleDeleteSuccess = ({ message }) => {
        onDeleteLocationSuccess(locationId, { id: LIST_STATUS.SUCCESS, message, type: LIST_STATUS.SUCCESS });
    };

    const _handleDeleteFinally = () => {
        refButtonSave.current.removeLoading();
        _close();
    };

    const renderContent = () => {
        if (!isOpen) return null;
        return (
            <>
                <GDModalWarning
                    ref={refConfirm}
                    title={t('customers:delete_location')}
                    description={t('customers:confirm_delete_location')}
                    footer={
                        <div className="footer-modal btn-close justify-end">
                            <div className="v2-btn-default --transparent" onClick={_handleCloseDelete}>
                                {t('customers:cancel')}
                            </div>
                            <ButtonSave ref={refButtonSave} onSave={_handleConfirmDelete} title={t('customers:yes')} />
                        </div>
                    }
                />
                <ReactModal
                    id="addLocation"
                    isOpen
                    style={{ overlay: { background: 'transparent' } }}
                    className="modal container-modal modal-newlocation open"
                    onRequestClose={_close}
                >
                    <div className="modal__overlay bg-fixed" onClick={_close} />
                    <div className="modal__container">
                        <div className="header-modal btn-close">
                            <h3 className="header-modal__label">
                                {t(`customers:${isUpdate ? 'edit_a_location' : 'add_a_location'}`)}
                            </h3>
                            <span onClick={_close} className="v2-btn-default --icon-lg --transparent">
                                <IconClose />
                            </span>
                        </div>
                        <div ref={refContainer} className="body-modal has-form form-location scrolls scrolls-x">
                            <AlertCustomer ref={refAlert} />
                            {isLoading ? (
                                <LocationModalLoading />
                            ) : (
                                <div className="content-modal">
                                    <div className="wrap-column-content">
                                        <LocationContent
                                            ref={refLocation}
                                            customer={customer}
                                            dataUpdate={dataUpdate}
                                            isUpdate={isUpdate}
                                            customerId={customerId}
                                        />
                                        <LocationPreferences
                                            ref={refPreferences}
                                            defaultValueTabs={dataUpdate?.messaging_preferences || {}}
                                            isUpdate={isUpdate}
                                            customerId={customer?.id}
                                            locationId={locationId}
                                        />
                                    </div>
                                    <LocationTaxes ref={refTaxes} defaultValue={dataUpdate.taxes || []} />
                                </div>
                            )}
                        </div>
                        {isLoading ? (
                            <LocationFooterModalLoading />
                        ) : (
                            <div
                                className={classNames('footer-modal footer-hasbtn btn-close', {
                                    'justify-space-between': canDelete
                                })}
                            >
                                {canDelete && (
                                    <div className="v2-btn-default has-icon --delete" onClick={_handleDeleteLocation}>
                                        <IconTrash />
                                        {t('customers:delete')}
                                    </div>
                                )}

                                <div className="d-flex">
                                    <span className="v2-btn-default --transparent" onClick={_close}>
                                        {t('cancel')}
                                    </span>
                                    <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                                </div>
                            </div>
                        )}
                    </div>
                </ReactModal>
            </>
        );
    };

    return (
        <>
            {renderContent()}
            <LocationService onOpenLocation={_open} />
        </>
    );
};

export default forwardRef(LocationModal);
