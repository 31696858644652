import React, { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconTrash from 'assets/icon/IconTrash';
import IconLoading from 'assets/icon/IconLoading';
import IconPlus from 'assets/icon/IconPlus';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const EmailItem = ({
    removeItem,
    addItem,
    dataEmail,
    index,
    total,
    ids,
    isFirstTimeFetch,
    openOptions,
    data,
    selectOption
}) => {
    const refDropdown = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_sms_to');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_sms_to');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _toggleOptions(e) {
        e.stopPropagation();

        if (!finalIsVisible && isFirstTimeFetch) {
            openOptions();
        }
        dispatchState({ isVisible: !finalIsVisible });
    }

    function _handleAddNew() {
        addItem();
    }

    function _handleSelectOption(e, item) {
        e.stopPropagation();
        selectOption(item, index);
        dispatchState({ isVisible: false });
    }

    function _renderRemoveItem() {
        return (
            <div className="btn-more has-added" onClick={() => removeItem(index)}>
                <div className="v2-btn-default just-icon" tabIndex="0">
                    <IconTrash />
                </div>
            </div>
        );
    }

    function _renderAddItem() {
        if (total === 3) {
            return <div className="btn-more added-full" />;
        }
        return (
            <div className="btn-more" onClick={_handleAddNew}>
                <div className="v2-btn-default just-icon is-added" tabIndex="0">
                    <IconPlus />
                </div>
            </div>
        );
    }

    function _renderListOptions() {
        if (data.length === 0) {
            return (
                <li className="items">
                    <IconLoading />
                </li>
            );
        }

        return data.map((item) => {
            if (item.id.length && ids.includes(item.id)) {
                return false;
            }

            return (
                <li key={item.id} className={'items'} onClick={(e) => _handleSelectOption(e, item)} tabIndex="0">
                    <p className="txt-ellipsis">{item.email}</p>
                </li>
            );
        });
    }

    return (
        <div className="field">
            <div ref={refDropdown} className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
                <div onClick={(e) => _toggleOptions(e)} className="dropbtn items" tabIndex="0">
                    <p className="txt-ellipsis">{dataEmail?.email || ''}</p>
                    <span className="svg-selectbox">
                        <IconDropDown />
                    </span>
                </div>
                {finalIsVisible && (
                    <div id="show_result_sms_to" className="v2-dropdown__menu scrolls">
                        <ul>{_renderListOptions()}</ul>
                    </div>
                )}
            </div>
            {index > 0 ? _renderRemoveItem() : _renderAddItem()}
        </div>
    );
};

export default EmailItem;
