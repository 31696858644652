import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DatePickerInput from 'app/components/date/DateInput';
import { convertTimeToISO } from 'common/utils/DateUtils';

const Date = forwardRef(({ defaultValue }, ref) => {
    const { t } = useTranslation(['customers', 'common']);
    const [selected, setSelected] = useState(defaultValue || convertTimeToISO(moment()));
    const refInputPicker = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return selected;
        },
        resetValue: () => {
            setSelected(convertTimeToISO(moment()));
        }
    }));

    useEffect(() => {
        setSelected(defaultValue || convertTimeToISO(moment()));
    }, [defaultValue]);

    const _handleChangeDate = (dateSelect) => {
        setSelected(convertTimeToISO(moment(dateSelect).startOf('day')) || null);
    };

    return (
        <div className="flex-column gap-4 flex-1">
            <p>{t('customers:date')}</p>
            <div ref={refInputPicker}>
                <DatePickerInput
                    modifiers={[
                        { name: 'offset', options: { offset: [0, 2] } },
                        { name: 'preventOverflow', options: { boundary: refInputPicker.current } }
                    ]}
                    selectDefault={selected}
                    onChange={_handleChangeDate}
                />
            </div>
        </div>
    );
});

export default Date;
