import React, { useEffect, useState, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useClickOutside from 'common/hooks/useClickOutside';
import { getListCustomerReport } from 'common/redux/actions/customers/customerAction';
import { useTranslation } from 'react-i18next';
import { KEY_REPORT_LOCAL_STORAGE, DEFAULT_ALL, CUSTOMER_SEARCH_LIMIT } from 'app/const/App';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import IconArrowDown from 'assets/icon/IconArrowDown';
import classNames from 'classnames';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import ReportListDropdown from '../components/ReportListDropdown';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';

const FilterCustomers = ({
    name,
    selected,
    title,
    onSelect,
    reportType,
    classWrapper = '',
    isPopper = false,
    isUseToggle = false
}) => {
    const { t } = useTranslation(['report']);

    const dispatch = useDispatch();
    const listRef = useRef(null);

    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);
    const [state, setState] = useState({
        isLoading: true,
        options: [],
        fistTime: true,
        keyword: '',
        totalSearch: 0
    });

    const { isLoading, options, fistTime, keyword, totalSearch } = state;

    const { list_customer, first_time, total_customer } = useSelector((store) => store.CustomerReport);

    const paramsLocal = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType, '_', name));

    const finalTitle = paramsLocal || title;

    useEffect(() => {
        if (isOpen && fistTime) {
            _handleOpen();
        }
    }, [isOpen]);

    function _getListCustomer(params) {
        dispatch(
            getListCustomerReport(
                params,
                (response) => _getListCustomerSuccess(response, params),
                () => {}
            )
        );
    }

    function _getListCustomerSuccess(response, params) {
        setState((prev) => {
            return {
                ...prev,
                isLoading: false,
                options: [...prev.options, ...response.data],
                fistTime: false,
                totalSearch: prev.keyword !== '' && params.total === 1 ? response?.total : prev.totalSearch
            };
        });
    }

    const handleOpen = () => {
        !isPopper && setIsOpen(!isOpen);
    };

    const _handleSelectOption = (e, value) => {
        handleSelectOption(e, value);
        isPopper && refSelect.current?._close();
    };

    const handleSelectOption = (e, value) => {
        e.preventDefault();
        if (value.id !== selected) {
            handleOpen();
            setLocalStorage(
                KEY_REPORT_LOCAL_STORAGE.concat('_', reportType, '_', name),
                value.first_name.concat(' ', value.last_name)
            );
            onSelect(name, value.id);
        }
        return false;
    };

    const handleSelectAll = (e) => {
        if (DEFAULT_ALL !== selected) {
            removeLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType, '_', name));
            handleOpen();
            e.preventDefault();
            DEFAULT_ALL !== selected && onSelect(name, DEFAULT_ALL);
            return false;
        }
    };

    const _handleSelectAll = (e) => {
        handleSelectAll(e);
        isPopper && refSelect.current?._close();
    };

    function _handleChangeSearch(keyword) {
        if (keyword === '') {
            setState({
                ...state,
                isLoading: false,
                options: list_customer,
                fistTime: false,
                keyword: ''
            });
            listRef.current.scrollTo(0, 0);
        } else {
            setState({
                ...state,
                isLoading: true,
                options: [],
                keyword: keyword
            });
            const params = {
                limit: CUSTOMER_SEARCH_LIMIT,
                offset: 0,
                total: 1,
                localSaveStore: false,
                localResetData: true,
                keyword
            };

            _getListCustomer(params);
        }
    }

    function handleOnScrollContent(e) {
        const lengthCustomerCurrent = options.length;
        const isSearching = keyword !== '';
        const finalTotalCompare = isSearching ? totalSearch : total_customer;

        if (!isLoading && isScrollToEndBottom(e.target) && lengthCustomerCurrent < finalTotalCompare) {
            setState({ ...state, isLoading: true });

            const params = {
                limit: CUSTOMER_SEARCH_LIMIT,
                offset: lengthCustomerCurrent,
                localSaveStore: !isSearching,
                localResetData: false,
                keyword
            };

            _getListCustomer(params);
        }
    }

    const renderSelectAllOption = () => {
        const isCheckAll = selected === DEFAULT_ALL;

        return (
            <li className={`items ${isCheckAll && 'active'}`} onClick={_handleSelectAll}>
                <div className="txt-ellipsis">{title || t('report:select_all')}</div>
            </li>
        );
    };

    const _renderButton = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis">{finalTitle}</span>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </Fragment>
        );
    };

    const _renderReportListDropdown = () => {
        return (
            <ReportListDropdown
                refSelect={refSelect}
                listRef={listRef}
                keyword={keyword}
                selected={selected}
                options={options}
                isPopper={isPopper}
                isLoading={isLoading}
                typeDropdown={TYPE_SELECT.CUSTOMERS}
                handleChangeSearch={_handleChangeSearch}
                handleSelectOption={_handleSelectOption}
                handleOnScrollContent={handleOnScrollContent}
                renderSelectAllOption={renderSelectAllOption}
            />
        );
    };

    const _handleOpen = () => {
        if (list_customer.length === 0 && first_time) {
            const params = {
                limit: CUSTOMER_SEARCH_LIMIT,
                offset: 0,
                total: 1,
                localSaveStore: true,
                localResetData: true
            };
            _getListCustomer(params);
        } else {
            setState({
                ...state,
                isLoading: false,
                options: list_customer,
                fistTime: false
            });
        }
    };

    if (isPopper) {
        return (
            <DropdownPopper
                ref={refSelect}
                placement="bottom-start"
                customStyle={{ width: 'auto', minWidth: 'auto' }}
                buttonClassName="dropbtn v2-btn-default"
                wrapperListClass="v2-dropdown__menu content-search content-user"
                wrapperClassName={classNames(classWrapper, 'v2-dropdown')}
                isUseToggle={isUseToggle}
                customButton={_renderButton()}
                onOpen={_handleOpen}
            >
                {_renderReportListDropdown()}
            </DropdownPopper>
        );
    }

    return (
        <div ref={refSelect} className={classNames('v2-dropdown', classWrapper, { active: isOpen })}>
            <div className="dropbtn v2-btn-default selection" onClick={_handleOpen}>
                {_renderButton()}
            </div>
            <div className="v2-dropdown__menu content-search content-user">{_renderReportListDropdown()}</div>
        </div>
    );
};

export default FilterCustomers;
