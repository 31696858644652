export const TYPE_INVOICE = {
    ACTION_OPEN_INVOICE_DETAIL: 'ACTION_OPEN_INVOICE_DETAIL'
};

export const actionOpenInvoice = (params) => {
    return {
        type: TYPE_INVOICE.ACTION_OPEN_INVOICE_DETAIL,
        params
    };
};

export const actionCloseInvoice = () => {
    return {
        type: TYPE_INVOICE.ACTION_OPEN_INVOICE_DETAIL,
        params: null
    };
};
