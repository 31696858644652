import React from 'react';

export const VOIPAddonsLoadingAddNumber = () => {
    return (
        <div className="boxs-wrapper manager-phone-number">
            <div className="wrap-manager wrap-loading --devices">
                <div className="box-voip bg-white">
                    <div className="box-voip__rows py-4">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="box-voip__rows p-0">
                        <div className="p-3">
                            <div className="tab-items align-center flex-column mb-1 py-5">
                                <div className="check-items mt-1 v2-btn-default --icon-sm loading bg-white-cultured" />
                                <div className="loading --animation --line --twothird" />
                                <div className="loading --animation --line --onefourth" />
                            </div>
                            <div className="flexcenter gap-5">
                                <div className="tab-items align-center flex-column flex-1 py-4">
                                    <div className="check-items mt-1 v2-btn-default --icon-sm loading bg-white-cultured" />
                                    <div className="loading --animation --line --half" />
                                    <div className="loading --animation --line --full mt-3" />
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="tab-items align-center flex-column flex-1 py-4">
                                    <div className="check-items mt-1 v2-btn-default --icon-sm loading bg-white-cultured" />
                                    <div className="loading --animation --line --half" />
                                    <div className="loading --animation --line --full mt-3" />
                                    <div className="loading --animation --line --threefourth" />
                                </div>
                                <div className="tab-items align-center flex-column flex-1 py-4">
                                    <div className="check-items mt-1 v2-btn-default --icon-sm loading bg-white-cultured" />
                                    <div className="loading --animation --line --half" />
                                    <div className="loading --animation --line --threefourth mt-3" />
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                        </div>
                        <div className="tab-content px-3">
                            <div className="d-flex gap-16">
                                <div className="flex-column flex-1 gap-8">
                                    <div className="loading --animation --line --twothird" />
                                    <div className="v2-btn-default loading --grey w-100" />
                                </div>
                                <div className="flex-column flex-1 gap-8">
                                    <div className="loading --animation --line --half" />
                                    <div className="v2-btn-default loading --grey w-100" />
                                </div>
                            </div>
                            <div className="my-3">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                    </div>
                    <div className="box-voip__rows">
                        <div className="flexcenter justify-end">
                            <div className="v2-btn-default loading btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="v2-btn-default loading --grey btn-x-sm ml-2">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
