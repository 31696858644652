import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KEY_CODE_ENTER, KEY_CODE_ESCAPE, KEY_CODE_SPACE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconClose from 'assets/icon/IconClose';
import { validateEmail } from 'common/utils/EmailUtils';

const SelectEmail = forwardRef(({ emails, emailSelected = [], onSelect, max }, ref) => {
    const { t } = useTranslation('common');
    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        selected: emailSelected,
        data: [...emails]
    });

    const { isVisible: finalIsVisible, data: finalListEmail, selected: finalListSelected = [] } = state;
    const refDropdown = useRef(null);
    const refInputSearch = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return finalListSelected;
        }
    }));

    useEffect(() => {
        if (emails.length !== 0) {
            setState({
                data: [...emails],
                selected: emailSelected.length !== finalListSelected.length ? emailSelected : finalListSelected
            });
        }
    }, [emails]);

    useEffect(() => {
        if (emails.length !== 0) setState({ data: [...emails], selected: emailSelected });
    }, [emails, emailSelected]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_select_email_to');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
        if (event.keyCode === KEY_CODE_SPACE || event.keyCode === KEY_CODE_ENTER) {
            _handleBlurEmail(event);
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_select_email_to');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleSelectEmail(e, emailSelect, isActive, isSearch) {
        e && e.stopPropagation();

        let newSelected = [...finalListSelected];
        let findItem = null;

        newSelected = newSelected.filter((item) => {
            if (item === emailSelect) {
                findItem = item;
            } else {
                return item;
            }
        });

        if (emailSelected.length >= max && !isActive) {
            if (max === 1) {
                newSelected = [];
            } else {
                return false;
            }
        }

        let listSearch = [...finalListEmail];

        listSearch = listSearch.map((item) => {
            item.hide = false;
            return item;
        });

        // This case check enter new email is exist in selected
        if (isSearch && findItem) {
            setState((prev) => {
                return {
                    ...prev,
                    data: listSearch,
                    isVisible: false
                };
            });
            refInputSearch.current.value = '';
            return false;
        }

        !findItem && newSelected.push(emailSelect);
        onSelect && onSelect(newSelected);

        setState({
            selected: newSelected,
            isVisible: false,
            data: listSearch
        });

        if (!isActive) {
            refInputSearch.current.value = '';
        }
    }

    function _handleChangeValue(e) {
        const valueSearch = e.target.value;
        let listSearch = [...finalListEmail];
        if (valueSearch.length === 0) {
            listSearch = listSearch.map((item) => {
                item.hide = false;
                return item;
            });
        } else {
            listSearch = listSearch.map((item) => {
                if (item.email.toLowerCase().includes(valueSearch)) {
                    item.hide = false;
                } else {
                    item.hide = true;
                }
                return item;
            });
        }

        setState((prev) => {
            return {
                ...prev,
                data: listSearch,
                isVisible: true
            };
        });
    }

    function _handleClearAll() {
        onSelect && onSelect([]);
        setState({
            selected: []
        });
    }

    function _handleBlurEmail(e) {
        e && e.stopPropagation();
        const newEmail = refInputSearch.current.value;
        if (validateEmail(newEmail)) {
            _handleSelectEmail(e, newEmail, false, true);
        } else {
            refInputSearch.current.value = '';
            let listSearch = [...finalListEmail];
            listSearch = listSearch.map((item) => {
                item.hide = false;
                return item;
            });

            setState((prev) => {
                return {
                    ...prev,
                    data: listSearch
                };
            });
        }
    }

    function _renderListEmail() {
        let finalRenderListEmail = [...finalListEmail];

        finalRenderListEmail = finalRenderListEmail.filter((item) => !item.hide);

        if (finalRenderListEmail.length === 0) {
            return (
                <li className="items pointer-events-none">
                    <div className="txt-ellipsis">{t('no_data_to_display', { title: t('emails') })}</div>
                </li>
            );
        }

        return finalRenderListEmail.map((item) => {
            const currentEmail = item.email;
            const isActive = finalListSelected.some((itemFound) => itemFound === currentEmail);

            if (currentEmail.length === 0) {
                return false;
            }

            return (
                <li
                    className={`items ${isActive ? 'active' : ''}`}
                    key={currentEmail}
                    onClick={(e) => _handleSelectEmail(e, currentEmail, isActive)}
                >
                    <div className="txt-ellipsis">{currentEmail}</div>
                </li>
            );
        });
    }

    function _renderFirstEmail() {
        return finalListSelected.map((item) => {
            return (
                <div key={item} className="tag-label">
                    <span className="tag-label__ellipsis">{item}</span>
                    <div onClick={(e) => _handleSelectEmail(e, item, true)} className="svg-remove black">
                        <IconClose isSmall />
                    </div>
                </div>
            );
        });
    }

    function _renderButtonClearAll() {
        if (finalListSelected.length === 0) {
            return false;
        }
        return (
            <div onClick={_handleClearAll} className="close-tags">
                <IconCircleClose />
            </div>
        );
    }

    return (
        <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
            <div onClick={_handleOpen} className="dropbtn items group-tags has-search" ref={refDropdown}>
                <div className="box-tags">
                    {_renderFirstEmail()}
                    <input
                        ref={refInputSearch}
                        type="text"
                        className="select-input-tag"
                        onChange={_handleChangeValue}
                        onBlur={_handleBlurEmail}
                        readOnly={emailSelected.length >= max}
                        spellCheck
                    />
                    {_renderButtonClearAll()}
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </div>
            {finalIsVisible && (
                <div className="v2-dropdown__menu scrolls w-100">
                    <ul id={'show_list_select_email_to'}>{_renderListEmail()}</ul>
                </div>
            )}
        </div>
    );
});

export default SelectEmail;
