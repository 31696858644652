import IconSync from 'assets/icon/IconSync';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import PlanTax from './PlanTax';
import { useTranslation } from 'react-i18next';

const PlanListAdded = ({ isSquare = false, isEdit = false }) => {
    const { t } = useTranslation();
    const { selectedPlans, _handleDeletePlan, _handleEditPlan, _handleRestorePlan } = useContext(SubscriptionContext);

    const _handleChange = (id, e) => {
        _handleEditPlan(id, { qty: parseInt(e.target.value) || 0 });
    };

    const _renderListPlan = () => {
        return selectedPlans.map((item) => (
            <div key={item.id} className={classNames('rows --modify', { 'has-removed': isEdit && !!item.deleted })}>
                <div className="col fw-600 word-break mt-1">{item.name}</div>
                <div className="col --md --details word-break mt-1">{item.description}</div>
                <div className="col --action --qty">
                    <input
                        className="field-input"
                        type="number"
                        onChange={(e) => _handleChange(item.id, e)}
                        defaultValue={item.qty}
                        disabled={isSquare}
                    />
                </div>
                <div className="col --action --remove">
                    <div className="btn-action" onClick={() => _handleDeletePlan(item.id)}>
                        {!item.deleted && (
                            <div className="v2-btn-default --icon-lg --delete">
                                <IconTrash />
                            </div>
                        )}
                        {isEdit && !isSquare && !!item.deleted && (
                            <div
                                className="v2-btn-default --transparent --icon-r js-unrestore-plan"
                                onClick={() => _handleRestorePlan(item.id)}
                            >
                                {t('common:restore')}
                                <IconSync />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="content elm-plan-added">
            <div className="rows --modify --header">
                <div className="col fw-600">{t('common:price_plan')}</div>
                <div className="col fw-600 --md">{t('common:price_details')}</div>
                <div className="col fw-600 --qty">{t('common:qty')}</div>
                <div className="col fw-600 --remove" />
            </div>

            {selectedPlans.length ? (
                _renderListPlan()
            ) : (
                <div className="rows">
                    <p className="value-empty">{t('common:no_plan_notification')}</p>
                </div>
            )}

            <PlanTax isEdit={isEdit} isSquare={isSquare} />
        </div>
    );
};

export default PlanListAdded;
