import classNames from 'classnames';
import React, { Fragment, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconPhone from 'app/components/phone/IconPhone';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { TYPE_CALL } from 'app/const/Voip';

const SelectMultiContact = ({ item = {}, phoneNumber }) => {
    const contacts = item.contacts || [];

    return contacts.length > 1 ? (
        <MultiPhones item={item} phoneNumber={phoneNumber} />
    ) : (
        <SinglePhone contact={contacts[0]} phoneNumber={phoneNumber} isSingle />
    );
};

export default SelectMultiContact;

const SinglePhone = ({ contact = {}, isSingle = false, phoneNumber = '' }) => {
    const { phone_type, phone_valid: isValid } = contact || {};

    return (
        <div className={classNames('valid-phone', { 'ml-2': isSingle })}>
            <p className="valid-phone__number txt-ellipsis black">{formatPhoneNumberVoip(phoneNumber)}</p>
            {!!phone_type ? (
                <div className="valid-phone__btn bg-black-light">
                    <IconPhone isValid={isValid} />
                    <span className="phone-type">{phone_type}</span>
                </div>
            ) : null}
        </div>
    );
};

const MultiPhones = ({ item = {}, phoneNumber = '' }) => {
    const { onSelectContact } = useContext(VOIPContext);

    const { t } = useTranslation();
    const refDropdown = useRef(null);

    const {
        id: callId,
        contacts = [],
        customer_id,
        contact_id,
        type: callType,
        transfer_call_id: transferCallId
    } = item;

    const _renderContacts = () => {
        return contacts.map((item, index) => {
            const { company, first_name, last_name, contact_id: contactId, id: customerId } = item;
            const isSelected = contactId === contact_id && customerId === customer_id;

            const _handleSelect = () => {
                onSelectContact({
                    contact: item,
                    callId,
                    callIdAssign: callType === TYPE_CALL.IN ? transferCallId : callId
                });
                refDropdown.current?._close();
            };

            return (
                <li key={index.toString()} className="items" onClick={_handleSelect}>
                    <div className="items-content">
                        <p className="txt-ellipsis">{`${first_name} ${last_name}`}</p>
                        <p className="dots txt-ellipsis">{company}</p>
                    </div>
                    {isSelected ? <span className="budget ml-1">{t('primary')}</span> : null}
                </li>
            );
        });
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            wrapperClassName="v2-dropdown dropdown-assign-number"
            buttonClassName="dropbtn v2-btn-default selection has-icon"
            wrapperListClass="v2-dropdown__menu scrolls"
            customButton={
                <Fragment>
                    <div className="dropbtn__label">
                        <SinglePhone contact={item} phoneNumber={phoneNumber} />
                        <span className="budget ml-1">{`+${contacts.length - 1}`}</span>
                    </div>
                    <div className="arrow">
                        <IconDropUpDown />
                    </div>
                </Fragment>
            }
            isUseToggle
            placement="bottom-start"
        >
            <ul>{_renderContacts()}</ul>
        </DropdownPopper>
    );
};
