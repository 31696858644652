import { ADDONS_STRIPE_SUBSCRIPTION, ADDONS_SQUARE_SUBSCRIPTION } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const FooterSubscription = ({ isSquare }) => {
    const { t } = useTranslation(['addons']);
    const isSquareActive = useSelector(({ auth }) => !!auth.user.settings.addons.square);

    return (
        <div className="boxs__footer --multi">
            <p className="grey-extrlight txt-ellipsis">
                {t(`addons:only_want_some_${isSquare ? 'square' : 'stripe'}_plans_available_inside_gorilladesk`)}
            </p>
            {!isSquareActive && isSquare ? null : (
                <Link
                    to={addBranchPath(isSquare ? ADDONS_SQUARE_SUBSCRIPTION : ADDONS_STRIPE_SUBSCRIPTION)}
                    className="v2-btn-default svg-purple --purple --icon-r btn-modal"
                >
                    {t('addons:manage')}
                    <IconCogWheel />
                </Link>
            )}
        </div>
    );
};

export default FooterSubscription;
