import React, { useContext, useRef, useReducer, useEffect } from 'react';
import { ManageUnitContext } from 'app/modules/jobdetail/contexts/ManageUnitContext';
import IconError from 'assets/icon/IconError';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionCreatebuilding } from 'app/const/Api';
import GdButton from 'app/components/button';
import { LIST_STATUS } from 'app/const/Status';
import IconDone from 'assets/icon/IconDone';
import { reducer } from 'app/const/Reducer';
import { ENTER_KEY_CODE } from 'app/const/Keyboard';

let timmer = null;

export default function AddBuilding({ onUpdate }) {
    const [state, dispatchState] = useReducer(reducer, {
        buildingName: '',
        isLoading: false,
        isWarning: null
    });

    const { manageUnitContextData, updateManageUnitContextData } = useContext(ManageUnitContext);
    const refInput = useRef(null);
    const finalBuildingName = state.buildingName.trim();
    const finalWarning = state.isWarning;

    useEffect(() => {
        if (finalWarning) {
            timmer && clearTimeout(timmer);
            timmer = setTimeout(() => {
                dispatchState({
                    isWarning: null
                });
            }, 2000);
        }
    }, [finalWarning]);

    function _handleClickAdd(e) {
        e && e.stopPropagation();

        if (manageUnitContextData.buildingData.some((item) => item.name === finalBuildingName)) {
            dispatchState({
                isWarning: {
                    type: LIST_STATUS.ERROR,
                    message: 'Oops! This building has existed'
                }
            });
            return false;
        }

        const idTemp = Date.now();

        const newData = {
            id: idTemp,
            name: finalBuildingName,
            units: [],
            is_check: false,
            created: idTemp
        };
        if (manageUnitContextData.withoutFetch) {
            _handleCreateBuildingSuccess(
                {
                    data: { id: idTemp },
                    message: `Save building "${finalBuildingName}" successfully.`
                },
                newData
            );
            return false;
        }

        dispatchState({
            isLoading: true
        });

        const optionsQuery = {
            method: 'POST',
            data: {
                name: finalBuildingName,
                job_id: manageUnitContextData.jobId
            }
        };

        clientQuery(
            actionCreatebuilding(manageUnitContextData.locationId),
            optionsQuery,
            (res) => _handleCreateBuildingSuccess(res, newData),
            _handleCreateBuildingFailed
        );
    }

    function _handleCreateBuildingSuccess(response, newData) {
        const reponseData = response.data;
        const newBuildingId = reponseData.id;

        newData = {
            ...newData,
            id: newBuildingId
        };

        dispatchState({
            isLoading: false,
            buildingName: '',
            isWarning: {
                type: LIST_STATUS.SUCCESS,
                message: response.message.toString()
            }
        });

        const newBuildingData = [...manageUnitContextData.buildingData, newData].sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { numeric: true })
        );

        onUpdate(newBuildingData, manageUnitContextData.unitData);

        updateManageUnitContextData({
            buildingData: newBuildingData,
            buildingSelected: newBuildingId,
            unitSelected: ''
        });
    }

    function _handleCreateBuildingFailed(response) {
        dispatchState({
            isLoading: false,
            isWarning: {
                type: LIST_STATUS.ERROR,
                message: response.message.toString()
            }
        });
    }

    function _handleChangeBuildingName(e) {
        dispatchState({
            buildingName: e.target.value,
            isWarning: null
        });
    }

    function _handleKeyPress(e) {
        if (e.charCode === ENTER_KEY_CODE || e.key === 'Enter' || e.which === ENTER_KEY_CODE) {
            _handleClickAdd(e);
        }
    }

    function _renderWarning() {
        if (!finalWarning) {
            return false;
        }

        switch (finalWarning.type) {
            case LIST_STATUS.ERROR:
                return (
                    <p className="txt-incorrect">
                        <IconError />
                        {finalWarning.message}
                    </p>
                );
            case LIST_STATUS.SUCCESS:
                return (
                    <p className="txt-incorrect --is-correct">
                        <IconDone isSuccess />
                        {finalWarning.message}
                    </p>
                );
            default:
                false;
        }
        return (
            <p className="txt-incorrect">
                <IconError />
                {finalWarning.message}
            </p>
        );
    }

    return (
        <div className="flex-column__head header elm-parent-focus">
            <div className="mdu-label">Buildings</div>
            <div className="header__form">
                <input
                    ref={refInput}
                    className="field-input"
                    value={state.buildingName}
                    type="text"
                    placeholder="Building Name"
                    onKeyPress={_handleKeyPress}
                    onChange={(e) => _handleChangeBuildingName(e)}
                    autoFocus
                />

                <GdButton
                    isLoading={state.isLoading}
                    className={`v2-btn-main ${finalBuildingName.length === 0 ? 'is-disable' : ''}`}
                    onClick={_handleClickAdd}
                >
                    Add
                </GdButton>
                {_renderWarning()}
            </div>
        </div>
    );
}
