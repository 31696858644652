export const TYPE_REPORT_CREDIT = {
    GET_LIST: 'GET_LIST_REPORT_CREDIT',
    DELETE_REQUEST: 'DELETE_CREDIT_REQUEST'
};

// actions
export const getListReportCredit = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_CREDIT.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteReportCreditRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_CREDIT.DELETE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
