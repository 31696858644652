import { COMMON, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { LIST_BUTTONS_STATUS } from 'app/const/customer/CustomerJobs';
import { getDefaultParams } from 'app/const/report/ReportParams';
import classNames from 'classnames';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerJobHeader = ({
    activeStatus = COMMON.ACTIVE,
    reportType,
    isDeleted = false,
    onChangeStatusView = () => {}
}) => {
    const { t } = useTranslation('customers');

    const paramsLocal = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType));
    const params = paramsLocal ? paramsLocal : getDefaultParams(reportType);

    const _handleChangeView = (status) => {
        if (activeStatus !== status) {
            setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), {
                ...params,
                status,
                offset: 0,
                currentPage: 1
            });
            onChangeStatusView(status);
        }
    };

    const renderListButtons = () => {
        return LIST_BUTTONS_STATUS.map((item) => (
            <button
                key={item.id}
                type="button"
                className={classNames('tab-items', {
                    'active-tab-selector': item.id === activeStatus
                })}
                onClick={() => _handleChangeView(item.value)}
            >
                {t(item.name)}
            </button>
        ));
    };

    return (
        <div className="tabs flex-1">
            {!isDeleted && <div className="tab-selectors btn-item bg-white ml-0">{renderListButtons()}</div>}
        </div>
    );
};

export default CustomerJobHeader;
