export const IconRecord = ({ isWhite = false }) => {
    if (isWhite)
        return (
            <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5C16.9183 4.5 20.5 8.08172 20.5 12.5ZM18.5 12.5C18.5 15.8137 15.8137 18.5 12.5 18.5C9.18629 18.5 6.5 15.8137 6.5 12.5C6.5 9.18629 9.18629 6.5 12.5 6.5C15.8137 6.5 18.5 9.18629 18.5 12.5ZM12.5 16.5C14.7091 16.5 16.5 14.7091 16.5 12.5C16.5 10.2909 14.7091 8.5 12.5 8.5C10.2909 8.5 8.5 10.2909 8.5 12.5C8.5 14.7091 10.2909 16.5 12.5 16.5Z"
                    fill="white"
                />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                stroke="#5F6164"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                fill="#5F6164"
            />
        </svg>
    );
};
