import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumnsSettingSystem = () => {
    const header = {
        template: {
            title: 'template',
            style: 'col'
        },
        status: {
            title: 'status',
            style: 'col'
        },
        plan: {
            title: 'plan',
            style: 'col --label-r d-flex'
        }
    };
    const columns = [
        {
            id: 'template',
            title: 'template',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'plan',
            title: 'plan',
            status: true
        }
    ];
    const contentConfig = {
        template: {
            title: 'template',
            style: 'col --name',
            type: LIST_TABLE_ITEM_TYPE.NAME_CLICK
        },
        status: {
            title: 'status',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.SETTING_STATUS_SYSTEM
        },
        plan: {
            title: 'plan',
            style: 'col --label-r d-flex',
            type: LIST_TABLE_ITEM_TYPE.SETTING_PLAN_SYSTEM
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
