export const TYPES_VOIP = {
    TRIGGER_CALL_ACTION: 'TRIGGER_CALL_ACTION',
    HAVE_NEW_CALL: 'HAVE_NEW_CALL',
    ACTION_TRIGGER_NUMBERS: 'ACTION_TRIGGER_NUMBERS',
    GET_LIST_INDEX_DIALER: 'GET_LIST_INDEX_DIALER',
    CREATE_INDEX_DIALER: 'CREATE_INDEX_DIALER',
    UPDATE_INDEX_DIALER: 'UPDATE_INDEX_DIALER'
};

export const triggerRealtimeCall = (params) => {
    return {
        type: TYPES_VOIP.TRIGGER_CALL_ACTION,
        params
    };
};

export const actionNewCall = (params) => {
    return {
        type: TYPES_VOIP.HAVE_NEW_CALL,
        params
    };
};

export const actionTriggerLoadNumbers = () => {
    return {
        type: TYPES_VOIP.ACTION_TRIGGER_NUMBERS
    };
};

export const getListIndexDialer = (params) => {
    return {
        type: TYPES_VOIP.GET_LIST_INDEX_DIALER,
        params
    };
};

export const createIndexDialer = (params) => {
    return {
        type: TYPES_VOIP.CREATE_INDEX_DIALER,
        params
    };
};

export const updateIndexDialer = (params) => {
    return {
        type: TYPES_VOIP.UPDATE_INDEX_DIALER,
        params
    };
};
