import { call } from 'redux-saga/effects';
import { GET_USER_QUEST_LOG } from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';

export function* getQuestLogs({ onSuccess, onFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_USER_QUEST_LOG);
        if (response && response.success) {
            onSuccess(response);
        } else {
            onFailed(response);
        }
    } catch (error) {
        onFailed(error);
    }
}
