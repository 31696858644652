import React from 'react';

export const VOIPAddonsLoadingSelectPlan = () => {
    return (
        <div className="wrap-loading --addon ">
            <div className="boxs boxs--no-border">
                <div className="boxs__header page-heading">
                    <div className="header-title">
                        <div className="header-title__svg v2-btn-default loading --icon-lg" />
                        <div className="wrap-title flex-1">
                            <div className="loading --animation --line --light --twothird" />
                            <div className="status-btn loading --animation --line --light --onefifth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-plans flex-column gap-12 mt-2">
                <div className="loading --animation --light --line --fourth mt-1 mb-2" />
                <div className="wrap-plans__option d-flex gap-4">
                    <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                        <div className="header-plans flex-column flexcenter">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                        <div className="content-plans grey-dark flex-column gap-4">
                            <div className="loading --animation --line --full mt-1" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                        <div className="loading --animation --line --fourth mt-4" />
                    </div>
                    <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                        <div className="header-plans flex-column flexcenter">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="v2-btn-default field-h24 loading --grey btn-sm mt-2 mb-1">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="content-plans grey-dark flex-column gap-4">
                            <div className="loading --animation --line --full mt-1" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                        <div className="loading --animation --line --fourth mt-4" />
                    </div>
                    <div className="box-plans border-border-color-grey shadow-none flex-column justify-space-between flex-1 fs-12">
                        <div className="header-plans flex-column flexcenter">
                            <div className="loading --animation --line --threefourth" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="v2-btn-default field-h24 loading --grey btn-sm mt-2 mb-1">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="content-plans grey-dark flex-column gap-4">
                            <div className="loading --animation --line --full mt-1" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                            <div className="loading --animation --line --threefourth mt-2" />
                        </div>
                        <div className="loading --animation --line --fourth mt-4" />
                    </div>
                </div>
                <div className="is-divider --horizontal" />
                <div className="wrap-plans__user">
                    <div className="flex-betweenitems fw-600">
                        <div className="loading --animation --light --line --twothird" />
                        <div className="loading --animation --light --line --onefifth mt-2" />
                    </div>
                    <div className="loading --animation --light --line --threefourth mt-2 mb-3" />
                    <div className="box-plans border-border-color-grey shadow-none --lg">
                        <div className="slider">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="slider --range">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-end">
                    <div className="v2-btn-default loading btn-sm bg-grey-extralight" />
                </div>
            </div>
        </div>
    );
};
