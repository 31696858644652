export const TYPE_ITEMS = {
    ACTION_GET_LIST_ITEMS: 'ACTION_GET_LIST_ITEMS',
    ACTION_GET_LIST_ITEMS_SUCCESS: 'ACTION_GET_LIST_ITEMS_SUCCESS',
    ACTION_ADD_NEW_ITEM: 'ACTION_ADD_NEW_ITEM',
    ACTION_RESET_ITEMS: 'ACTION_RESET_ITEMS'
};

// actions
export const getListItems = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_ITEMS.ACTION_GET_LIST_ITEMS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const setListItemsReducer = (payload) => {
    return {
        type: TYPE_ITEMS.ACTION_GET_LIST_ITEMS_SUCCESS,
        payload
    };
};

export const resetListItems = () => {
    return {
        type: TYPE_ITEMS.ACTION_RESET_ITEMS
    };
};
