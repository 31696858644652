import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';

import { createPopper } from '@popperjs/core';
import { actionAttachment } from 'app/const/Api';
import { getIconTypeFile } from 'app/const/icon/IconsType';
import { reducer } from 'app/const/Reducer';
import { handleGetInfoAttach } from 'app/modules/jobdetail/const/Utils';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { isFileImage } from 'common/utils/FileUtils';
import { GDGlobalImg } from 'app/components/img';

const Attachment = ({ attachments, onDeleteAttachment }) => {
    const refTooltip = useRef(null);

    function _handleDeleteAttachment(e, item) {
        !item.object_key && clientQuery(actionAttachment(item.id), { method: 'DELETE' });
        onDeleteAttachment(item.id);
    }

    const _handleDisplayTooltip = (event, { id, size, name }) => {
        refTooltip.current.visible({ id, size, name, event });
    };
    const _handleHideTooltip = () => {
        refTooltip.current.visible(null);
    };

    function _renderAttachments() {
        return attachments.map((item) => {
            const { id, name, size, url, isUploading, mime, type, thumbnail_url } = item;
            const finalItem = mime || type;

            return (
                <div key={id} className="attachment-wrap">
                    <div
                        id={id}
                        onMouseOver={(event) => _handleDisplayTooltip(event, { id, size, name })}
                        onMouseOut={_handleHideTooltip}
                        className={classNames('attachment-image-items tooltip', { 'is-uploading': isUploading })}
                    >
                        {isFileImage(finalItem) ? (
                            <GDGlobalImg
                                src={thumbnail_url || url}
                                loading="lazy"
                                draggable={false}
                                data-close-preview="not-close"
                                width={75}
                                height={55}
                                alt=""
                            />
                        ) : (
                            getIconTypeFile(finalItem)
                        )}

                        {!isUploading && (
                            <div
                                onClick={(e) => _handleDeleteAttachment(e, item)}
                                className="btn-bg-overlay svg-white-stroke is-delete"
                            >
                                <IconTrash />
                            </div>
                        )}
                        {isUploading && (
                            <div className="has-progress">
                                <span className="has-progress__bar"></span>
                            </div>
                        )}
                    </div>
                    <p className="attachment-name">
                        <span className="prefix-name">
                            {handleGetInfoAttach({ value: name, limitCharacter: 36, maxCharacter: 29 })}
                        </span>
                    </p>
                </div>
            );
        });
    }

    if (!Array.isArray(attachments) || attachments.length === 0) return null;
    return (
        <div className="attachment-image scrolls-x">
            {_renderAttachments()}
            <TooltipElement ref={refTooltip} />
        </div>
    );
};

export const TooltipElement = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, { data: null });
    const { data } = state;
    const refEl = useRef(null);

    useImperativeHandle(ref, () => ({
        visible: (data) => {
            dispatchState({ data });
        }
    }));

    useLayoutEffect(() => {
        if (data) {
            const popper = createPopper(data.event.target.closest('.attachment-wrap'), refEl.current, {
                placement: 'top',
                strategy: 'fixed',
                modifiers: [{ name: 'offset', options: { offset: [0, 5] } }]
            });

            return () => {
                popper.destroy();
            };
        }
    }, [data]);

    return (
        <p ref={refEl} className="tooltiptext visibility-show" style={{ display: !!data ? 'flex' : 'none' }}>
            {!!data ? `${data.name} (${data.size})` : null}
        </p>
    );
});

export default Attachment;
