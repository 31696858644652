import GdDropdownPhone from 'app/components/phone/DropdownPhone';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import React from 'react';

const SMSItem = ({
    removeItem,
    addItem,
    dataPhone,
    index,
    total,
    isFirstTimeFetch,
    openOptions,
    data,
    selectOption,
    ids
}) => {
    function _handleAddNew() {
        addItem();
    }

    const _handleOpenDropdown = () => {
        if (isFirstTimeFetch) openOptions();
    };

    const _handleSelect = (item) => {
        selectOption(item, index);
    };

    function _renderRemoveItem() {
        return (
            <div className="btn-more has-added" onClick={() => removeItem(index)}>
                <div className="v2-btn-default just-icon" tabIndex="0">
                    <IconTrash />
                </div>
            </div>
        );
    }

    function _renderAddItem() {
        if (total === 2) {
            return <div className="btn-more added-full" />;
        }
        return (
            <div className="btn-more" onClick={_handleAddNew}>
                <div className="v2-btn-default just-icon is-added" tabIndex="0">
                    <IconPlus />
                </div>
            </div>
        );
    }

    return (
        <div className="field">
            <GdDropdownPhone
                data={data}
                idsSelected={ids}
                selectedPhone={dataPhone}
                isLoading={isFirstTimeFetch}
                onVisible={_handleOpenDropdown}
                onSelectPhone={_handleSelect}
            />
            {index > 0 ? _renderRemoveItem() : _renderAddItem()}
        </div>
    );
};

export default SMSItem;
