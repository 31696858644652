import GdTags from 'app/components/tags';
import { CUSTOMER_STATUSES, TYPE_TAGS } from 'app/const/Customers';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import React, { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';
import AddCustomerPhone from '../AddCustomerPhone';
import BoxRows, { BoxRowsInput } from '../BoxRows';
import AdditionalContacts from './AdditionalContacts';
import SelectSource from './SelectSource';

const BoxInformation = ({ defaultStatus, accountNumber, onHandleChangeStatus = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { updateContext, profile } = useContext(AddCustomerContext);

    const refForm = useRef(null);
    const refSources = useRef(null);
    const refTags = useRef(null);
    const refStatus = useRef(null);
    const refAdditionContact = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        const result = {};
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { type, value, name } = elements[index];
            if (type === 'text' && name) result[name] = value;
        }

        delete result['phone'];

        return {
            profile: {
                ...result,
                phones: profile.phones,
                source: refSources.current.getValue()?.id || '',
                tags: refTags.current.getValue().map((item) => item.name),
                status: refStatus.current.getValue()
            },
            additional_contacts: refAdditionContact.current.getValue()
        };
    };

    const _handleUpdateInformation = () => {
        const result = {};
        const elements = refForm.current.elements;

        for (let index = 0; index < elements.length; index++) {
            const { value, name, type } = elements[index];
            if (type === 'text') result[name] = value;
        }

        updateContext(result, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_PROFILE);
    };

    return (
        <form ref={refForm} className="box-has-rows">
            <BoxRows label={t('customers:status')}>
                <SelectStatusCustomer
                    ref={refStatus}
                    defaultValue={defaultStatus}
                    onHandleChangeStatus={onHandleChangeStatus}
                />
            </BoxRows>
            <BoxRowsInput
                label={t('customers:account_#')}
                labelClass="flexcenter"
                tooltip={t('customers:account_customer_profile_tooltip')}
                isRequired
                inputProps={{
                    className: 'field-input --account',
                    defaultValue: accountNumber,
                    placeholder: t('customers:account_#'),
                    name: 'account_no',
                    onBlur: _handleUpdateInformation
                }}
            />
            <BoxRowsInput
                label={t('customers:first_name')}
                labelClass="flexcenter"
                inputProps={{
                    defaultValue: profile.first_name,
                    placeholder: t('customers:first_name'),
                    name: 'first_name',
                    autoFocus: true,
                    onBlur: _handleUpdateInformation
                }}
                isRequired
            />
            <BoxRowsInput
                label={t('customers:last_name')}
                inputProps={{
                    defaultValue: profile.last_name,
                    placeholder: t('customers:last_name'),
                    name: 'last_name',
                    onBlur: _handleUpdateInformation
                }}
            />
            <BoxRowsInput
                label={t('customers:title')}
                inputProps={{
                    defaultValue: profile.title || '',
                    placeholder: t('customers:title'),
                    name: 'title',
                    onBlur: _handleUpdateInformation
                }}
            />
            <BoxRowsInput
                label={t('customers:email')}
                inputProps={{
                    defaultValue: profile.email,
                    placeholder: t('customers:email'),
                    name: 'email',
                    onBlur: _handleUpdateInformation
                }}
            />
            <BoxRowsInput
                label={t('customers:company')}
                inputProps={{ placeholder: t('customers:company'), name: 'company', onBlur: _handleUpdateInformation }}
            />
            <BoxRows label={t('customers:phone')} fieldClass="has-many-field has-new-field">
                <AddCustomerPhone />
            </BoxRows>
            <BoxRows label={t('customers:source')}>
                <SelectSource ref={refSources} />
            </BoxRows>
            <BoxRows label={t('customers:tags')}>
                <GdTags ref={refTags} id="add-customer-information-tags" type={TYPE_TAGS.TAG_DEFAULT} />
            </BoxRows>
            <BoxRows label={t('customers:additional_contacts')} fieldClass="has-many-field">
                <AdditionalContacts ref={refAdditionContact} />
            </BoxRows>
        </form>
    );
};

export const SelectStatusCustomer = forwardRef(({ defaultValue = 1, onHandleChangeStatus = () => {} }, ref) => {
    const [selected, setSelected] = useState(() => CUSTOMER_STATUSES.find((item) => item.value === defaultValue));
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => selected.value;

    const _handleSelect = (value) => {
        setSelected(CUSTOMER_STATUSES.find((item) => item.value === value));
        onHandleChangeStatus(value);
    };

    return (
        <CalendarDropdown
            id="add-customer-status"
            ref={refDropdown}
            selected={selected.name}
            selectedOption={selected}
            onSelect={_handleSelect}
            options={CUSTOMER_STATUSES}
        />
    );
});

BoxInformation.displayName = 'CustomerBoxInformation';
export default forwardRef(BoxInformation);
