export default function IconMaterial() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 4C11.5523 4 12 4.44772 12 5C12 5.51284 11.614 5.93551 11.1166 5.99327L11 6V10L12.253 12.256L10.5528 15.6584C10.3445 16.0749 10.2361 16.5343 10.2361 17C10.2361 17.7688 10.5253 18.47 11.0008 19.0009L6.39905 19C5.29448 19 4.39905 18.1046 4.39905 17C4.39905 16.6601 4.48567 16.3258 4.65073 16.0287L8.00002 10V6C7.44773 6 7.00002 5.55228 7.00002 5C7.00002 4.44772 7.44773 4 8.00002 4H11Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 8C13.4477 8 13 7.55228 13 7C13 6.44772 13.4477 6 14 6H17C17.5523 6 18 6.44772 18 7C18 7.55228 17.5523 8 17 8V11L19.5528 16.1056C20.0468 17.0935 19.6463 18.2949 18.6584 18.7889C18.3807 18.9277 18.0744 19 17.7639 19H13.2361C12.1315 19 11.2361 18.1046 11.2361 17C11.2361 16.6895 11.3084 16.3833 11.4472 16.1056L14 11V8Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}
