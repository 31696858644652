import GridEmpty from 'app/components/grid/components/GridEmpty';
import IconCheck from 'assets/icon/IconCheck';
import IconNote from 'assets/icon/IconNote';
import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';

const PlanList = ({ data = [], onSelect = () => {} }) => {
    const { t } = useTranslation(['common', 'customers']);
    const { selectedPlans } = useContext(SubscriptionContext);

    if (!data.length) return <GridEmpty />;
    return data.map((plan) => {
        const isAdded = selectedPlans.some((item) => `${item.id}` === `${plan.id}`);
        return (
            <div key={plan.id} className={classNames('rows', { selected: isAdded })}>
                <div className="col --icon">
                    <IconNote />
                </div>
                <div className="col --info flex-1 mt-1">
                    <span className="name word-break">{plan.name}</span>
                    <span className="word-breakall"> - {plan.id}</span>
                </div>
                <div className="col --price word-break mt-1">{plan.description}</div>
                <div className="col --action">
                    <div
                        className={classNames('v2-btn-default has-icon', { '--added': isAdded })}
                        onClick={() => onSelect(plan)}
                    >
                        {isAdded ? <IconCheck /> : <IconPlus />}
                        {isAdded ? t('added') : t('add')}
                    </div>
                </div>
            </div>
        );
    });
};

export default PlanList;
