import { addBranchPath } from 'app/const/Branch';
import { INBOX } from 'app/const/Route';
import RealTimeService from 'app/modules/inbox/service/RealTime';
import IconInbox from 'assets/icon/IconInbox';
import classNames from 'classnames';
import { getInboxCounterRequest, setInboxCounter } from 'common/redux/actions/inboxAction';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

function Inbox({ isExpired = false }) {
    const { t } = useTranslation('header');
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    const totalInbox = useSelector(({ inbox }) => inbox.total || 0);

    useEffect(() => {
        !isExpired && _handleGetCountInbox();
    }, []);

    const _handleGetCountInbox = () => {
        dispatch(getInboxCounterRequest());
    };

    const _handleClickInbox = () => {
        history.push(addBranchPath(INBOX));
    };

    const _setTotalInbox = (total) => {
        dispatch(setInboxCounter(total));
    };

    const isHasInbox = totalInbox > 0;

    return (
        <div className={classNames('boxs-notification notifi nav-inbox', { 'is-disable': isExpired })}>
            <div
                className={classNames('notify-btn tooltip', {
                    'has-notify': isHasInbox,
                    active: pathname.includes(INBOX)
                })}
                onClick={_handleClickInbox}
            >
                <IconInbox isActive />
                {isHasInbox && <span className="budget">{totalInbox > 999 ? '+999' : totalInbox}</span>}
                <p className="tooltiptext bottom">{t('inbox')}</p>
            </div>
            <RealTimeService onUpdateTotalInbox={_setTotalInbox} />
        </div>
    );
}

export default Inbox;
