import React from 'react';

export default function IconAttachment({ isHasColor = false }) {
    if (isHasColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.864 4.63616C19.9595 6.73165 20.0106 10.0974 18.0173 12.2548L17.864 12.4143L11.8531 18.4252C10.2912 19.987 7.759 19.987 6.19717 18.4252C4.68266 16.9107 4.63677 14.4837 6.05949 12.9139L6.19717 12.7693L12.2085 6.75795C13.1846 5.78191 14.7671 5.78191 15.7431 6.75795C16.6801 7.69496 16.7176 9.19086 15.8556 10.1726L15.7431 10.2925L10.4393 15.5963C10.0488 15.9868 9.41565 15.9868 9.02512 15.5963C8.66249 15.2337 8.63659 14.6618 8.94742 14.2693L9.02512 14.1821L14.3289 8.87832C14.5239 8.68332 14.5239 8.36717 14.3289 8.17217C14.1556 7.99883 13.8865 7.97958 13.6919 8.11439L13.6227 8.17217L7.61138 14.1835C6.8306 14.9643 6.8306 16.2302 7.61138 17.011C8.35668 17.7563 9.54399 17.7902 10.3295 17.1126L10.4389 17.011L16.4497 11.0001C17.8166 9.63328 17.8166 7.41721 16.4497 6.05037C15.1285 4.7291 13.0136 4.68505 11.6394 5.91824L11.5 6.05037L6.19669 11.3537C5.80617 11.7442 5.17301 11.7442 4.78248 11.3537C4.41985 10.991 4.39395 10.4192 4.70477 10.0267L4.78248 9.93946L10.0858 4.63616C12.2337 2.48827 15.7161 2.48827 17.864 4.63616Z"
                    fill="#C1C9D4"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.90383 12.3536L13.5607 6.6967C14.7323 5.52513 16.6318 5.52513 17.8033 6.6967C18.9749 7.86827 18.9749 9.76777 17.8033 10.9393L10.7318 18.0109C9.951 18.7917 8.68509 18.7917 7.90431 18.0109C7.12352 17.2301 7.12352 15.9642 7.90431 15.1834L14.9763 8.11139C15.3666 7.72113 15.9993 7.72113 16.3896 8.11139C16.7799 8.50165 16.7799 9.13439 16.3896 9.52465L10.7323 15.182"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
