import React, { useEffect, useRef, useState } from 'react';
import IconCopy from 'assets/icon/IconCopy';

const ButtonCopy = ({
    content = null,
    timeout = 1500,
    className = 'v2-btn-default --icon-lg --transparent tooltip',
    tooltipText = 'Copy',
    title = null,
    isShowOnlyCopied = false,
    isStopPropagation = false
}) => {
    const refTimer = useRef(null);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    const _handleCopy = async (e) => {
        isStopPropagation && e.stopPropagation();
        await navigator.clipboard.writeText(content);
        clearTimeout(refTimer.current);
        !copied && setCopied(true);
        refTimer.current = setTimeout(() => {
            setCopied(false);
        }, timeout);
    };

    return (
        <div className={className} onClick={_handleCopy}>
            <IconCopy />
            {title}
            {!isShowOnlyCopied || copied ? (
                <span className="tooltiptext top">{copied ? 'Copied' : tooltipText}</span>
            ) : null}
        </div>
    );
};

export default ButtonCopy;
