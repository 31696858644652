/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React from 'react';

import { WithTitle } from 'app/components/routes/RouteWithSubRoutes';
import BookMark from 'app/modules/calendar/components/bookmark';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import CustomerDetailProvider from '../context/CustomerDetailContext';
import HeaderNavigation from './components/HeaderNavigation';
import SideMenu from './components/sideMenu';

import 'app/modules/customer/css/customer.scss';
import { Route, Switch } from 'react-router-dom';

function CustomerDetailLayout({ routes = [], permissionKey, ...props }) {
    return (
        <div
            id="customer_detail_layout"
            className={classNames('container-wrap custom-grid-customer', {
                'has-collapsed': !getToggleLocal('toggledCustomerDetail'),
                'move-sidebar': getLocalStorage('sideMenuCustomerDetail') === 'left' ? true : false
            })}
        >
            <BookMark />
            <CustomerDetailProvider>
                <HeaderNavigation />
                <Switch>
                    {routes.map((route, i) => {
                        const result = { ...route };
                        if (route.path.split(',').length > 1) {
                            result.path = route.path.split(',');
                        }

                        return (
                            <Route
                                key={i}
                                path={result.path}
                                exact
                                render={(props) => (
                                    <WithTitle title={route.title}>
                                        <route.component {...props} />
                                    </WithTitle>
                                )}
                            />
                        );
                    })}
                </Switch>
                <SideMenu />
            </CustomerDetailProvider>
        </div>
    );
}

export default CustomerDetailLayout;
