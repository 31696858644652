import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { ADDONS_VOIP } from 'app/config/routes';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import AddonBack from 'app/modules/addons/components/AddonBack';
import ItemVoicemailDrop from './ItemVoicemailDrop';
import { clientQuery } from 'common/utils/ApiUtils';
import { LoadingVoicemailDrop } from './LoadingVoicemailDrop';
import { actionsVoipVoicemailDrop, GET_LIST_VOICEMAIL_DROP } from 'app/const/api/Voip';
import GDStatusBar from 'app/components/status/statusbar';
import { showStatusBar } from 'common/utils/FunctionUtils';
import classNames from 'classnames';
import { createUUID, messageActionsAlert } from 'common/utils/StringUtils';
import { LIST_ACTION_MESSAGE } from 'app/modules/addons/constants';
import { VOIPContext } from 'app/services/voip/VOIPProvider';

const VoicemailDrop = () => {
    const { t } = useTranslation('addons');
    const { onUpdateState } = useContext(VOIPContext);

    const refConfirm = useRef(null);
    const refAlert = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        refresh: 0
    });

    const { isLoading: finalIsLoading, data: finalData, refresh } = state;

    useEffect(() => {
        _getListData();
    }, []);

    useEffect(() => {
        if (!refresh) return;
        onUpdateState((prev) => ({ ...prev, voicemailDrop: finalData.filter((item) => !item.isAddNew) }));
    }, [refresh]);

    const _getListData = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, data, isLoading: false, refresh: Date.now() }));
        };

        const _handleFail = (err) => {
            _handleStatus(err);
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(GET_LIST_VOICEMAIL_DROP, { method: 'GET', data: {} }, _handleSuccess, _handleFail);
    };

    const _renderData = () => {
        if (finalIsLoading) return <LoadingVoicemailDrop />;
        if (!finalData.length) return <GridEmpty />;

        return finalData.map((item) => {
            return (
                <ItemVoicemailDrop
                    key={item.id}
                    data={item}
                    onSaveSuccess={_handleUpdate}
                    onDeleteItem={_handleConfirmDelete}
                    onShowStatus={_handleStatus}
                />
            );
        });
    };

    const _handleConfirmDelete = ({ id, isAddNew }) => {
        if (isAddNew) {
            _handleRemove(id);
            return;
        }
        refConfirm.current._open({
            title: t('delete_voicemail_drop'),
            description: t('desc_delete_voicemail_drop'),
            dataConfirm: { id }
        });
    };

    const _handleDelete = (id, dataConfirm) => {
        const finalId = dataConfirm.id;
        const _handleSuccess = ({ message }) => {
            _handleRemove(finalId);
            refConfirm.current._close();
            _handleStatus({
                message: messageActionsAlert({
                    message,
                    type: t('voicemail_drop'),
                    action: LIST_ACTION_MESSAGE.DELETED
                }),
                success: true
            });
        };

        const _handleFail = ({ message }) => {
            refConfirm.current._setStatusAlert(message, LIST_STATUS.ERROR);
        };

        clientQuery(
            actionsVoipVoicemailDrop(finalId),
            { method: 'DELETE', data: { id: finalId }, toFormData: false },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleRemove = (id) => {
        dispatchState((prev) => ({ ...prev, data: prev.data.filter((item) => item.id !== id), refresh: Date.now() }));
    };

    const _handleAddNew = () => {
        dispatchState((prev) => {
            return { ...prev, data: [{ id: createUUID(), label: '', voicemail: '', isAddNew: true }, ...prev.data] };
        });
    };

    const _handleUpdate = (data = {}) => {
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.id === data.id) {
                    return { ...item, ...data, isAddNew: false };
                }
                return item;
            }),
            refresh: Date.now()
        }));
    };

    const _handleStatus = ({ message, success = false }) => {
        showStatusBar({ id: 'voicemail_drop', message, success, refAlert });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header flex-betweenitems">
                <AddonBack path={ADDONS_VOIP} title={t('back')} />
                <div className={classNames('v2-btn-main', { 'is-disable': finalIsLoading })} onClick={_handleAddNew}>
                    {t('add_new')}
                </div>
            </div>
            <GDStatusBar ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    <div className="wrap-manager">{_renderData()}</div>
                </div>
            </div>
            <GDModalWarning ref={refConfirm} buttonSave onConfirm={_handleDelete} />
        </div>
    );
};

export default VoicemailDrop;
