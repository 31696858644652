import { reducer } from 'app/const/Reducer';
import { ID_CONTENT_PREVIEW } from 'app/const/Settings';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';

const LivePreview = (props, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState((prevState) => ({ ...prevState, isOpen: true }));
    };

    const _close = () => {
        dispatchState((prevState) => ({ ...prevState, isOpen: false }));
    };

    return (
        <div
            className={classNames('wrap-container__preview flex-column wrap-preview-sms', {
                'is-open': isOpen
            })}
        >
            <div className="wrap-header flex-betweenitems">
                <h3 className="flex-1 fs-17 mr-2">Live Preview</h3>
                <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                    <IconClose />
                </div>
            </div>
            <div className="wrap-body">
                <div className="wrap-body__inner container-content-email is-sms">
                    <div className="wrap-content-email">
                        <div className="sms-message flextop">
                            <div className="sms-message__info mr-1 w-100">
                                <div id={ID_CONTENT_PREVIEW} className="message-desc" />
                                <div className="text-right fs-11">
                                    <span className="mr-1">Sms Bot</span>
                                    <span className="granite-gray">
                                        {moment().format('DD MMM yy')}, {moment().format('HH:MM A')}
                                    </span>
                                </div>
                            </div>
                            <div className="images">
                                <div className="avt-img ">
                                    <img
                                        loading="lazy"
                                        src="https://d2e627ktfmb6xb.cloudfront.net/avatars/static/bot.png"
                                        width={32}
                                        height={32}
                                        alt="avatar"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(LivePreview);
