import GdButton from 'app/components/button';
import { reducer } from 'app/const/Reducer';
import Add from 'app/modules/jobdetail/tabs/timeline/todo/checklist/Add';
import IconPen from 'assets/icon/IconPen';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { useReducer, useRef } from 'react';

const BoxItem = ({ item, onEdit = () => {}, onDelete = () => {} }) => {
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false
    });

    const refAdd = useRef(null);

    const _onSave = (text) => {
        onEdit({ id: item.id, description: text });
    };

    const _openFormEdit = () => {
        dispatchState({ isOpen: true });
        refAdd.current._open();
    };

    const _closeFormEdit = () => {
        dispatchState({ isOpen: false });
    };

    return (
        <div className={classNames('todo-items', { 'has-edit': state.isOpen })}>
            <div className="todo-items__action flextop">
                <div className="col-drag v2-btn-default --icon-sm --transparent">
                    <IconThreeDots isDouble />
                </div>
                <div className="col-desc flex-1">
                    <span className="col-desc__label word-break mr-1">{item.description}</span>
                </div>
                <div className="col-icon flexcenter">
                    <GdButton className="v2-btn-default --icon-lg ml-1" iconSvg={<IconPen />} onClick={_openFormEdit} />
                    <GdButton
                        className="v2-btn-default --icon-lg svg-delete-grey ml-1"
                        iconSvg={<IconTrash />}
                        onClick={() => onDelete(item.id)}
                    />
                </div>
            </div>
            <Add ref={refAdd} onSave={_onSave} defaultValue={item.description} onClose={_closeFormEdit} />
        </div>
    );
};
export default BoxItem;
