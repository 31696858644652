import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import FilterReport from 'app/modules/report/components/FilterReport';
import { reducer } from 'app/const/Reducer';

const FilterOpportunity = ({ reportType = '', filters = [], onSelect = () => {} }, ref) => {
    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const refCount = useRef(0);
    const refUserDeleted = useRef(false);

    const [state, dispatchState] = useReducer(reducer, {
        isShowUserDeleted: false
    });
    const { isShowUserDeleted } = state;

    useImperativeHandle(ref, () => ({
        setShowUserDeleted: _handleSetShowUserDeleted
    }));

    const _handleSetShowUserDeleted = (value = false) => {
        if (refUserDeleted.current) return;
        dispatchState({ isShowUserDeleted: value });
        refUserDeleted.current = value;
        refCount.current -= 1;
    };

    const _handleSelect = (value) => {
        if (refCount.current < 2) {
            refCount.current += 1;
            return;
        }
        onSelect(value);
    };

    return (
        <FilterReport
            reportType={reportType}
            filters={filters}
            onSelect={_handleSelect}
            companyUsers={companyUsers}
            forceRerender={isShowUserDeleted}
            isShowAvatar
            isShowUserDeleted={isShowUserDeleted}
        />
    );
};

export default forwardRef(FilterOpportunity);
