import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGridColumns = (columns = [], actionsStatus) => {
    const HEADER_RECURRING_INVOICE = {
        account_number: {
            title: 'account_number_#',
            style: 'col'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl'
        },
        job_name: {
            title: 'job_name',
            style: 'col col-xl'
        },
        repeat_text: {
            title: 'repeats',
            style: 'col col-xl'
        },
        action: {
            title: 'action',
            style: 'col --card'
        },
        status: {
            title: 'status',
            style: 'col col-xs'
        },
        total_value: {
            title: 'total_value',
            style: 'col col-total'
        },
        started: {
            title: 'started',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        end_date: {
            title: 'end_date',
            style: 'col col-xs'
        },
        next_invoice: {
            title: 'next_invoice',
            style: 'col col-invoice'
        }
    };

    const REPORT_COLUMNS_RECURRING_INVOICE = [
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'job_name',
            title: 'job_name',
            status: true
        },
        {
            id: 'repeat_text',
            title: 'repeat_text',
            status: true
        },
        {
            id: 'action',
            title: 'action',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'total_value',
            title: 'total_value',
            status: true
        },
        {
            id: 'started',
            title: 'started',
            status: true
        },
        {
            id: 'end_date',
            title: 'end_date',
            status: true
        },
        {
            id: 'next_invoice',
            title: 'next_invoice',
            status: true
        }
    ];

    const REPORT_CONTENT_RECURRING_INVOICE = {
        account_number: {
            id: 'account_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',

            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        job_name: {
            id: 'job_name',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK,
            defaultValue: COMMON.N_A
        },
        repeat_text: {
            id: 'repeat_text',
            style: 'col col-xl'
        },
        action: {
            id: 'action',
            style: 'col --card',
            type: LIST_TABLE_ITEM_TYPE.PAYMENT_CARD_WITH_ACTION
        },
        status: {
            id: 'status',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        total_value: {
            id: 'total_value',
            style: 'col col-total',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        started: {
            id: 'started',
            style: 'col col-md'
        },
        end_date: {
            id: 'end_date',
            style: 'col col-xs',
            defaultValue: COMMON.N_A
        },
        next_invoice: {
            id: 'next_invoice',
            style: 'col col-invoice',
            type: LIST_TABLE_ITEM_TYPE.CUSTOM_NEXT_INVOICE
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, HEADER_RECURRING_INVOICE);
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_RECURRING_INVOICE.forEach((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_RECURRING_INVOICE[item];
            newContent[item] = REPORT_CONTENT_RECURRING_INVOICE[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
