import GridEmpty from 'app/components/grid/components/GridEmpty';
import { ADDONS_DOCUMENT_LIST } from 'app/const/Api';
import IconPen from 'assets/icon/IconPen';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { Fragment, useImperativeHandle, useReducer, useRef } from 'react';
import { useEffect } from 'react';
import { forwardRef } from 'react';
import EditDocumentAvailable from './EditDocumentAvailable';
import ListDocsAvailableLoading from './ListDocsAvailableLoading';
import { reducer } from 'app/const/Reducer';
import NameTagPdf from './manage/NameTagPdf';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';

const AvailableDocumentList = forwardRef(({ onSelect = () => {}, onGetFail = () => {} }, ref) => {
    const { t } = useTranslation(['header']);
    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        keyword: ''
    });
    const refActiveIds = useRef(new Set());
    const refEditModal = useRef([]);
    const refData = useRef([]);
    const parentRef = React.useRef();
    const { data, isLoading, keyword: finalKeyword } = state;

    const rowVirtualizer = useVirtual({
        size: data.length,
        parentRef,
        overscan: 10
    });

    useImperativeHandle(ref, () => ({ _getSelected }));

    useEffect(() => {
        _getListDocumentAvailable();
    }, []);

    const getKeyItem = (id, onlyUser) => `${id}_${onlyUser}`;

    const _getSelected = () => {
        const result = [];
        refData.current.forEach(({ id, name, only_user }) => {
            if (refActiveIds.current.has(getKeyItem(id, only_user))) {
                result.push({ id, name, only_user });
            }
        });
        return result;
    };

    const _getListDocumentAvailable = () => {
        const _getDocumentsSuccess = ({ data }) => {
            dispatchState({ data, isLoading: false });
            const findDocActive = data.find((item) => !!item.active) || data[0];
            onSelect(findDocActive?.id || data?.[0]?.id, findDocActive?.only_user || 0);
            data.forEach(({ id, only_user, active }) => {
                if (!active) return;
                refActiveIds.current.add(getKeyItem(id, only_user));
            });
            refData.current = data;
        };

        const _getDocumentsFailed = (err) => {
            dispatchState({ data: [], isLoading: false });
            onGetFail(err);
        };
        clientQuery(ADDONS_DOCUMENT_LIST, { data: {}, method: 'GET' }, _getDocumentsSuccess, _getDocumentsFailed);
    };

    const _handleSelectDocument = ({ id, onlyUser }, isChecked) => {
        const key = getKeyItem(id, onlyUser);
        if (isChecked) {
            refActiveIds.current.add(key);
            return;
        }
        refActiveIds.current.delete(key);
    };

    const _renderListDocument = () => {
        if (!data.length)
            return (
                <GridEmpty
                    msgEmpty={
                        finalKeyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:documents') })
                    }
                    isFlat
                />
            );
        return rowVirtualizer.virtualItems.map((virtualRow) => {
            const virtualRowIndex = virtualRow.index;
            const dataItem = data[virtualRowIndex];
            const key = getKeyItem(dataItem.id, dataItem.only_user);

            return (
                <AvailableDocumentItem
                    key={key}
                    onEdit={_handleEdit}
                    onSelectName={onSelect}
                    onSelect={_handleSelectDocument}
                    {...dataItem}
                    virtualRow={virtualRow}
                    active={refActiveIds.current.has(key)}
                />
            );
        });
    };

    const _handleUpdateDocument = (document) => {
        refData.current = refData.current.map((item) => {
            if (item.id === document.id && item.only_user === document.only_user) return { ...item, ...document };
            return item;
        });

        _handleSearch(finalKeyword);
    };

    const _handleEdit = (data) => {
        refEditModal.current._open(data);
    };

    const _handleSearch = (keyword = '') => {
        dispatchState((prev) => {
            const keySearch = keyword.toLowerCase();
            return {
                ...prev,
                data: refData.current.filter(({ name: docName }) => docName.toLowerCase().includes(keySearch)),
                keyword: keySearch
            };
        });
    };

    return (
        <Fragment>
            <div className="list__document scrolls flex-column">
                {isLoading ? (
                    <ListDocsAvailableLoading />
                ) : (
                    <Fragment>
                        <SearchOption
                            style="no-effect"
                            isSearchIcon
                            placeholder={t('search')}
                            onSearch={_handleSearch}
                        />
                        <div ref={parentRef} className="scrolls flex-1">
                            <div
                                style={{
                                    height: `${rowVirtualizer.totalSize}px`,
                                    width: '100%',
                                    position: 'relative'
                                }}
                            >
                                {_renderListDocument()}
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
            <EditDocumentAvailable ref={refEditModal} onUpdateDocument={_handleUpdateDocument} />
        </Fragment>
    );
});

const AvailableDocumentItem = ({
    id,
    name,
    active = 0,
    type,
    onSelectName = () => {},
    onSelect = () => {},
    onEdit = () => {},
    only_user: onlyUser = 0,
    virtualRow = {},
    original_name = ''
}) => {
    const idElm = `document_available_${id}_${onlyUser}`;

    return (
        <div
            className="rows"
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`
            }}
        >
            <div className="rows__icon">
                <div className="check-items">
                    <input
                        id={idElm}
                        type="checkbox"
                        defaultChecked={!!active}
                        onChange={(event) => onSelect({ id, onlyUser }, event.target.checked)}
                    />
                    <div className="item-checkbox">
                        <label htmlFor={idElm} />
                    </div>
                </div>
            </div>
            <div onClick={() => onSelectName(id, onlyUser)} className="rows__name">
                <NameTagPdf type={type} name={name} originalName={original_name} />
            </div>
            <div className="rows__action">
                <div
                    className="btn-modal v2-btn-default --icon-sm --transparent"
                    onClick={() => onEdit({ id, name, only_user: onlyUser, type })}
                >
                    <IconPen />
                </div>
            </div>
        </div>
    );
};

export default AvailableDocumentList;
