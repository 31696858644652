export default function IconQuantity() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 4C18.1046 4 19 4.89543 19 6V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V6C5 4.89543 5.89543 4 7 4H17ZM16 10H8C7.44772 10 7 10.4477 7 11V18C7 18.5523 7.44772 19 8 19H16C16.5523 19 17 18.5523 17 18V11C17 10.4477 16.5523 10 16 10ZM16 5H8C7.44772 5 7 5.44772 7 6V8C7 8.55228 7.44772 9 8 9H16C16.5523 9 17 8.55228 17 8V6C17 5.44772 16.5523 5 16 5Z"
                fill="#C1C9D4"
            ></path>
            <path
                d="M10 11H9C8.44772 11 8 11.4477 8 12V13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13V12C11 11.4477 10.5523 11 10 11Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M10 15H9C8.44772 15 8 15.4477 8 16V17C8 17.5523 8.44772 18 9 18H10C10.5523 18 11 17.5523 11 17V16C11 15.4477 10.5523 15 10 15Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M15 11H14C13.4477 11 13 11.4477 13 12V17C13 17.5523 13.4477 18 14 18H15C15.5523 18 16 17.5523 16 17V12C16 11.4477 15.5523 11 15 11Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M15 6H9C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H15C15.5523 8 16 7.55228 16 7C16 6.44772 15.5523 6 15 6Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}
