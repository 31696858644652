import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PreviewTemplateButton from './PreviewTemplateButton';
import SelectTemplate from './SelectTemplate';

const BoxContentInfo = (
    {
        id,
        keyValue,
        action,
        titleInfo,
        titleDescription,
        titleChoiceTemplate,
        descriptionChoiceTemplate,
        defaultTitle = '',
        defaultSelect = {},
        autoFocus = false,
        spellCheck = true
    },
    ref
) => {
    const refTitle = useRef(null);
    const refSelectTemplate = useRef(null);
    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => ({
        [`${keyValue}_id`]: refSelectTemplate.current._getValue(),
        [`${keyValue}_title`]: refTitle.current.value
    });

    return (
        <>
            <div className="rows">
                <div className="txt">{titleChoiceTemplate}</div>
                <div className="rows__view">
                    <SelectTemplate ref={refSelectTemplate} id={id} defaultSelect={defaultSelect} />
                    <PreviewTemplateButton action={action} />
                </div>
                <p className="rows__info">{descriptionChoiceTemplate}</p>
            </div>
            <div className="rows">
                <div className="txt">{titleInfo}</div>
                <input
                    ref={refTitle}
                    className="field-input"
                    type="text"
                    placeholder={titleInfo}
                    defaultValue={defaultTitle}
                    autoFocus={autoFocus}
                    spellCheck={spellCheck}
                />
                <p className="rows__info">{titleDescription}</p>
            </div>
        </>
    );
};

BoxContentInfo.displayName = 'BoxContentInfo';
export default forwardRef(BoxContentInfo);
