import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

const LocationRealtime = ({ onUpdate = () => {} }) => {
    const { customerLocationUpdate } = useContext(SocketContext);

    useEffect(() => {
        if (customerLocationUpdate) {
            onUpdate(customerLocationUpdate.locations);
        }
    }, [customerLocationUpdate]);
};
export default LocationRealtime;
