import { useTranslation } from 'react-i18next';

import GdButton from 'app/components/button';
import { getTitleTemplate } from 'app/modules/settings/utils';
import IconPlus from 'assets/icon/IconPlus';

const Header = ({ type = '', titleTemplate = '', onAdd = () => {} }) => {
    const { t } = useTranslation(['setting']);

    return (
        <div className="header-title">
            <div className="header-title__label fs-14">
                {t(`setting:${getTitleTemplate({ title: titleTemplate, value: type })}`)}
            </div>
            {type !== '-1' && (
                <GdButton
                    className="v2-btn-main has-icon svg-white"
                    title={t('add_new')}
                    iconSvg={<IconPlus />}
                    onClick={onAdd}
                />
            )}
        </div>
    );
};
export default Header;
