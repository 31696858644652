import React from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateNoteSticky } from 'app/const/Api';
import { NOTE_PIN } from 'app/modules/customer/const/Note';
import { useTranslation } from 'react-i18next';
import IconStick from 'assets/icon/IconStick';

const Actions = ({ noteDetail, onUpdateSticky }) => {

    if (!noteDetail.is_customer_note) {
        return false;
    }

    const { t } = useTranslation(['customers']);
    const isSticky = noteDetail.is_sticky;

    function _handleEdit() {

        const newSticky = isSticky === NOTE_PIN.STICKY ? NOTE_PIN.NONE_STICKY : NOTE_PIN.STICKY;

        const optionsQuery = {
            method: 'PUT',
            data: {
                active: newSticky,
            }
        };

        clientQuery(updateNoteSticky(noteDetail.id), optionsQuery);

        onUpdateSticky(newSticky);
    }

    return (
        <div className={`v2-btn-default --icon-lg --transparent tooltip click-icon ${isSticky ? 'active btn-bg-blue' : ''}`}>
            {
                isSticky ? (
                    <div
                        onClick={_handleEdit}
                        className="click-icon__btn unstick"
                    >
                        <IconStick isBlue />
                        <span className="tooltiptext top">{t('customers:unstick_to_top')}</span>
                    </div>
                ) : (
                    <div
                        className="click-icon__btn stick"
                        onClick={_handleEdit}
                    >
                        <IconStick />
                        <span className="tooltiptext top">{t('customers:stick_to_top')}</span>
                    </div>
                )
            }
        </div>
    );
};

export default Actions;