export const TYPE_REPORT_ESTIMATE = {
    GET_LIST_REPORT_ESTIMATE_REQUEST: 'GET_LIST_REPORT_ESTIMATE_REQUEST',
    DELETE_REPORT_ESTIMATE_REQUEST: 'DELETE_REPORT_ESTIMATE_REQUEST',
    ACTIVE_REPORT_ESTIMATE_REQUEST: 'ACTIVE_REPORT_ESTIMATE_REQUEST',
    ARCHIVE_REPORT_ESTIMATE_REQUEST: 'ARCHIVE_REPORT_ESTIMATE_REQUEST',
    UNDELETE_REPORT_ESTIMATE_REQUEST: 'UNDELETE_REPORT_ESTIMATE_REQUEST',
    TRASH_REPORT_ESTIMATE_REQUEST: 'TRASH_REPORT_ESTIMATE_REQUEST'
};

// actions
export const getListReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.GET_LIST_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.DELETE_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const activeReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.ACTIVE_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const archiveReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.ARCHIVE_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const unDeleteReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.UNDELETE_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const trashReportEstimatesRequest = (
    params,
    actionSuccess,
    actionFailed
) => {
    return {
        type: TYPE_REPORT_ESTIMATE.TRASH_REPORT_ESTIMATE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
