import {
    COMMISSIONS_GET_LIST_DATA,
    COMMISSIONS_GET_LIST_USER,
    LIST_METHOD_FILTER_COMMISSIONS,
    LIST_STAFF_FILTER_COMMISSIONS
} from 'app/const/Api';
import { LIST_COMMISSION_ITEMS } from 'app/const/api/V2';
import { COMMISSION_ACTIONS } from 'common/redux/actions/commission';
import { clientQuery, fetchWithToken, getFetch } from 'common/utils/ApiUtils';
import { call, put } from 'redux-saga/effects';

export function* getListDataCommission({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, COMMISSIONS_GET_LIST_DATA, params);
        if (response && response.success) {
            actionSuccess(response, 'total_commissions');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListStaffsFilterCommissions({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, LIST_STAFF_FILTER_COMMISSIONS);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterCommissions({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, LIST_METHOD_FILTER_COMMISSIONS);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// Commission sagas for job detail and invoice detail
export function* getCommissionItems() {
    try {
        const response = yield call(getFetch, LIST_COMMISSION_ITEMS);
        if (response && response.success) {
            yield put({
                type: COMMISSION_ACTIONS.GET_COMMISSION_ITEMS_SUCCESS,
                payload: response.data
            });
        }
    } catch (error) {
        console.error(error);
    }
}

export function* getCommissionUsers() {
    try {
        const response = yield call(clientQuery, COMMISSIONS_GET_LIST_USER, { data: { status: 1 } });
        if (response && response.success) {
            yield put({
                type: COMMISSION_ACTIONS.GET_COMMISSION_USERS_SUCCESS,
                payload: response.data
            });
        }
    } catch (error) {
        console.error(error);
    }
}
