import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';
import { VALUE_1, VALUE_2, VALUE_3, VALUE_4 } from 'app/const/report/ReportFilter';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const KEY_DAYS_1 = '0_30_days';
export const KEY_DAYS_2 = '31_60_days';
export const KEY_DAYS_3 = '61_90_days';
export const KEY_DAYS_4 = 'over_90_days';

export const KEY_DAYS_MAP = {
    [KEY_DAYS_1]: VALUE_1,
    [KEY_DAYS_2]: VALUE_2,
    [KEY_DAYS_3]: VALUE_3,
    [KEY_DAYS_4]: VALUE_4
};

export const getGridColumns = (actionsStatus, columns = []) => {
    const HEADER_ACCOUNTS_AGING = {
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        phone: {
            title: 'phone',
            style: 'col col-phone'
        },
        billing_email: {
            title: 'billing_email',
            style: 'col col-email'
        },
        payment_card: {
            title: 'payment_card',
            style: 'col --card'
        },
        '0_30_days': {
            title: '0_30_days',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        '31_60_days': {
            title: '31_60_days',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        '61_90_days': {
            title: '61_90_days',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        over_90_days: {
            title: 'over_90_days',
            style: 'col --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        total: {
            title: 'total',
            style: 'col col-total --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        available_credit: {
            title: 'available_credit',
            style: 'col col-md --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_ACCOUNTS_AGING = [
        {
            id: 'customer',
            status: true
        },
        {
            id: 'phone',
            status: true
        },
        {
            id: 'billing_email',
            status: true
        },
        {
            id: 'payment_card',
            status: true
        },
        {
            id: KEY_DAYS_1,
            status: true
        },
        {
            id: KEY_DAYS_2,
            status: true
        },
        {
            id: KEY_DAYS_3,
            status: true
        },
        {
            id: KEY_DAYS_4,
            status: true
        },
        { id: 'total', status: true },
        { id: 'available_credit', status: true }
    ];

    const REPORT_CONTENT_ACCOUNTS_AGING = {
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        phone: {
            id: 'phone',
            style: 'col col-phone',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        billing_email: {
            id: 'billing_email',
            style: 'col col-email',
            isEmail: true,
            type: LIST_TABLE_ITEM_TYPE.EMAIL
        },
        payment_card: {
            id: 'payment_card',
            style: 'col --card',
            isPaymentCard: true,
            type: LIST_TABLE_ITEM_TYPE.PAYMENT_CARD
        },
        '0_30_days': {
            id: KEY_DAYS_1,
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY
        },
        '31_60_days': {
            id: KEY_DAYS_2,
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY
        },
        '61_90_days': {
            id: KEY_DAYS_3,
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY
        },
        over_90_days: {
            id: KEY_DAYS_4,
            style: 'col --label-r',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY
        },
        total: {
            id: 'total',
            style: 'col col-total --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        available_credit: {
            id: 'available_credit',
            style: 'col col-md --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            styleText: 'col-label available'
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_ACCOUNTS_AGING });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_ACCOUNTS_AGING.map((col) => {
        const item = col.id;
        if (columns.includes(item)) {
            newContent[item] = REPORT_CONTENT_ACCOUNTS_AGING[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
