/**
 * Get value of object by string and separator.
 * @param {string} path - Path to get value
 * @param {object} obj - Data to get value
 * @param {*} separator - Separator
 * @example resolve("document.body.style.width")
 */
export const resolveValueObject = (path, obj = self, separator = '.') => {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

export const checkEmptyObject = (objectValue) => {
    return (
        typeof objectValue === 'object' &&
        !Array.isArray(objectValue) &&
        objectValue !== null &&
        JSON.stringify(objectValue) === '{}'
    );
};

export const companyCountryCode = {
    value: '',
    get countryCode() {
        return this.value;
    },
    set countryCode(value) {
        this.value = value;
    }
};

export const companySettingCurrencySymbol = {
    value: '',
    get settingCurrencySymbol() {
        return this.value;
    },
    set settingCurrencySymbol(value) {
        this.value = value;
    }
};

export const getValueElements = (elements = []) => {
    const dataValues = {};
    for (let index = 0; index < elements.length; index++) {
        const { name, value } = elements[index];
        dataValues[name] = value;
    }
    return dataValues;
};

export const convertValuesObjectToString = (object) => {
    return Object.entries(object).reduce((newObj, [key, value]) => {
        newObj[key] = Number.isInteger(value) || Array.isArray(value) ? value.toString() : value;
        return newObj;
    }, {});
};
