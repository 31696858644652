export const ABILITY_PHOTO_ACTION = {
    RESET_ABILITY_PHOTO: 'RESET_ABILITY_PHOTO',
    TOGGLE_IMPORT_JOB_IMAGES: 'TOGGLE_IMPORT_JOB_IMAGES'
};

export const actionToggleInsertJobImages = (params) => {
    return {
        params,
        type: ABILITY_PHOTO_ACTION.TOGGLE_IMPORT_JOB_IMAGES
    };
};
