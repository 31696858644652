import GdConfirm from 'app/components/confirm';
import { UPDATE_ADDONS_INBOX_EMAIL_OPTIONS } from 'app/const/Api';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { CONST_INBOX_PAGE } from 'app/modules/inbox/const';
import { getInboxCounterRequest } from 'common/redux/actions/inboxAction';
import { removeLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';
import AddEmail from './AddEmail';
import EmailInfo from './EmailInfo';
import InboxEmailLoading from './EmailLoading';
import EmailSignature from './EmailSignature';

const EmailContent = ({
    name,
    upgrade,
    keyword,
    status,
    level = 1,
    account,
    signature,
    isLoading = true,
    onAddAccountSuccess = () => {},
    onAddAccountFail = () => {},
    onUpdateAccountSuccess = () => {},
    onUpdateSyncSuccess = () => {},
    onUpdateSyncFail = () => {},
    onUpdateSMTPSuccess = () => {},
    onDeleteSuccess = () => {},
    onSuccess = () => {}
}) => {
    const { t } = useTranslation(['common', 'addons']);
    const dispatch = useDispatch();
    const refConfirm = useRef(null);
    const refHeader = useRef(null);
    const refAddEmail = useRef(null);

    const _handleConfirmChange = (status) => {
        if (account) {
            refHeader.current._handleChangeStatus(!status ? 1 : 0, true);
        } else {
            if (!status) {
                refConfirm.current.open(
                    null,
                    t('addons:turn_on_email_warning_desc'),
                    t('addons:connect_to_email_account')
                );
            } else {
                refHeader.current._handleChangeStatus(0, true);
            }
        }
    };

    const _handleChangeStatusSuccess = () => {
        dispatch(getInboxCounterRequest());
        removeLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX));
    };

    const _handleOpenAddEmail = () => {
        refAddEmail.current._handleOpenSetupModal();
    };

    const _handleSetupSuccess = (data, message = '') => {
        onAddAccountSuccess(data, message);
        refHeader.current._handleChangeStatus(1, true);
    };

    const _handleDeleteSuccess = (type, message) => {
        onDeleteSuccess(type, message);
        refHeader.current._handleChangeStatus(0, true);
    };

    if (isLoading) return <InboxEmailLoading />;
    return (
        <>
            <div className="boxs boxs--no-border">
                <AddonsBoxHeader
                    ref={refHeader}
                    keyword={keyword}
                    name={name}
                    upgrade={upgrade}
                    level={level}
                    status={status}
                    urlUpdateStatus={UPDATE_ADDONS_INBOX_EMAIL_OPTIONS}
                    onUpdateStatusSuccess={_handleChangeStatusSuccess}
                    onConfirmChange={_handleConfirmChange}
                    shouldConfirm
                />
                <div className="boxs__contents mt-4">
                    {!!account?.sync_failed && (
                        <div className="alert --error">
                            <p className="alert__description">{t('addons:sync_email_inbox_msg')}</p>
                        </div>
                    )}
                    <div className="email-description">{t('addons:description_inbox_email')}</div>
                    <div className="title mt-3">Email Accounts</div>
                    <div className="description mt-2">
                        Set up your email accounts with SMTP for better email deliverability. Email accounts with IMAP
                        set up will have 2-way sync which automatically attaches incoming emails (and emails you send
                        outside of GorillaDesk) to your customers.
                    </div>
                </div>
            </div>

            {!upgrade && (
                <>
                    {account ? (
                        <EmailInfo
                            {...account}
                            onUpdateAccountSuccess={onUpdateAccountSuccess}
                            onUpdateSMTPSuccess={onUpdateSMTPSuccess}
                            onUpdateSyncSuccess={onUpdateSyncSuccess}
                            onUpdateSyncFail={onUpdateSyncFail}
                            onDeleteSuccess={_handleDeleteSuccess}
                        />
                    ) : (
                        <AddEmail
                            ref={refAddEmail}
                            onAddAccountSuccess={_handleSetupSuccess}
                            onAddAccountFail={onAddAccountFail}
                        />
                    )}
                    <span className="--hasline" />
                    <EmailSignature defaultContent={signature} onUpdateSuccess={onSuccess} />
                    <GdConfirm
                        ref={refConfirm}
                        listButton={{ confirm: true, cancel: true }}
                        titleConfirm={t('addons:confirm')}
                        onConfirm={_handleOpenAddEmail}
                    />
                </>
            )}
        </>
    );
};

export default EmailContent;
