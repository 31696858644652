import IconLoadingChart from 'assets/icon/IconLoadingChart';

const LoadingLineChart = ({ isLineChart = false }) => {
    if (isLineChart) {
        return (
            <div className="boxs__content wrap-loading">
                <div className="chart">
                    <div className="chart__content">
                        <div className="flex-column gap-16">
                            <div className="is-summary">
                                <div className="rows">
                                    <div className="col">
                                        <div className="loading --animation --line --twothird"></div>
                                        <div className="loading --animation --line --half mt-1"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="chart-area loading --animation">
                                <IconLoadingChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="boxs__content wrap-loading">
            <div className="boxs__content">
                <div className="chart">
                    <div className="chart__content">
                        <div className="flex-column gap-24">
                            <div className="is-summary">
                                <div className="rows__label flexcenter gap-16">
                                    <div className="loading --animation --line --onetenth"></div>
                                    <div className="loading --animation --line mt-0 --onetenth"></div>
                                    <div className="loading --animation --line mt-0 --onetenth"></div>
                                </div>
                            </div>
                            <div className="chart-area-line loading --animation">
                                <IconLoadingChart isLineChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingLineChart;
