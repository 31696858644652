import IconDoubleStep from 'assets/icon/IconDoubleStep';
import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { reducer } from 'app/const/Reducer';
import { ADDONS_VOIP_PLAN } from 'app/config/routes';
import ModalManagePlan from './plan/ModalManagePlan';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import classNames from 'classnames';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { useDispatch } from 'react-redux';
import { addBranchPath } from 'app/const/Branch';

const VOIPAddonsGetStarted = ({ voip, onChangePlan = () => {} }, ref) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const refAlert = useRef(null);

    const { openManage } = location.state || {};
    const { account, upgrade, status } = voip;

    const [state, dispatchState] = useReducer(reducer, {
        isVisibleModal: !!openManage,
        account
    });

    const { isVisibleModal, account: finalAccount } = state;
    const { balance, user_quantity, credit, usage_this_month } = finalAccount || {};
    const isActivePlan = !!user_quantity;

    useEffect(() => {
        if (location.state?.isActiveSuccess)
            refAlert.current.showStatusBar({
                id: 'index_addon_voip',
                message: t('addons:your_voip_plan_has_been_activated'),
                type: LIST_STATUS.SUCCESS
            });
    }, []);

    useImperativeHandle(ref, () => ({
        _openManage: _handleClickManage
    }));

    const _renderCreditBalance = () => {
        if (!isActivePlan) return null;
        return (
            <Fragment>
                <div className="is-divider --horizontal" />
                <div className="flexcenter blue-default fw-600">
                    <span>
                        {t('addons:credit_balance_money', {
                            balance: Number(
                                balance - (credit >= usage_this_month ? 0 : usage_this_month - credit)
                            ).toFixed(2)
                        })}
                    </span>
                    <span className="dots">{t('addons:activated_users', { count: user_quantity })}</span>
                </div>
            </Fragment>
        );
    };

    const _handleClickActivated = () => {
        history.push(addBranchPath(ADDONS_VOIP_PLAN));
    };

    const _handleClickManage = () => {
        dispatchState({ isVisibleModal: true });
    };

    const _renderButtonPlan = () => {
        const _handleClick = () => {
            isActivePlan ? _handleClickManage() : _handleClickActivated();
        };
        return (
            <div className="v2-btn-default btn-bg-purple --icon-r --sm" onClick={_handleClick}>
                {t(`addons:${isActivePlan ? 'manage_plan' : 'activate_a_plan'}`)}
                <IconDoubleStep isPurple />
            </div>
        );
    };

    const _handleCloseModal = () => {
        dispatchState({ isVisibleModal: false });
    };

    const _handleUpdatePlan = ({ account }) => {
        dispatchState({ account });
    };

    const _handleChangePlan = () => {
        onChangePlan();
        !status && dispatch(updateAddonStatus({ keyword: 'voip', data: {} }));
    };

    return (
        <Fragment>
            <AlertCustomer ref={refAlert} />

            <div className={classNames('boxs boxs-has-footer active', { 'is-disable': !!upgrade })}>
                <div className="flex-column gap-8 p-4">
                    <h4 className="fw-500">{t('addons:getting_started_with_voip')}</h4>
                    <p className="mb-1">
                        {!isActivePlan
                            ? t('addons:desc_getting_started_voip')
                            : t('addons:desc_getting_started_voip_active')}
                    </p>
                    <a
                        href="https://intercom.help/gorilladesk/en/articles/8075419-make-and-receive-calls-inside-gorilladesk-using-voip"
                        target="_blank"
                        rel="noreferrer"
                        className="is-link fw-500"
                    >
                        {t('addons:learn_more_about_voip')}
                        <IconDoubleStep isPurple />
                    </a>
                    {_renderCreditBalance()}
                </div>
                <div className="boxs__footer">{_renderButtonPlan()}</div>
            </div>
            <div className="is-divider --horizontal my-3" />
            {isVisibleModal && (
                <ModalManagePlan
                    onClose={_handleCloseModal}
                    onUpdateSuccess={_handleUpdatePlan}
                    onChangePlan={_handleChangePlan}
                    isCanceled={!status}
                />
            )}
        </Fragment>
    );
};

export default forwardRef(VOIPAddonsGetStarted);
