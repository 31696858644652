import CustomAttachment from '../components/CustomAttachment';
import CustomBoldOption from '../components/CustomBoldOption';
import CustomCodeOption from '../components/CustomCodeOption';
import CustomEmojiOption from '../components/CustomEmojiOption';
import CustomImageOption from '../components/CustomImageOption';
import CustomItalicOption from '../components/CustomItalicOption';
import CustomLinkOption from '../components/CustomLinkOption';
import CustomOLOption from '../components/CustomOLOption';
import CustomRestoreOption from '../components/CustomRestore';
import CustomSaveButton from '../components/CustomSaveButton';
import CustomUlOption from '../components/CustomUlOption';
import CustomUnderlineOption from '../components/CustomUnderlineOption';
import CustomVariablesOption from '../components/CustomVariablesOption';

export const TYPE_FUNCTION_RICHTEXT = {
    LINK: 'link',
    IMAGE: 'image',
    EMOJI: 'emoji',
    RESTORE: 'restore',
    VARIABLES: 'variables',
    CODE: 'code',
    BOLD: 'bold',
    UL: 'ul',
    OL: 'ol',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    SAVE_BUTTON: 'save_button',
    ATTACHMENT: 'attachment'
};

export const RICHTEXT_FUNCTION = {
    [TYPE_FUNCTION_RICHTEXT.LINK]: { render: (props) => <CustomLinkOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.IMAGE]: { render: (props) => <CustomImageOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.EMOJI]: { render: (props) => <CustomEmojiOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.RESTORE]: { render: (props) => <CustomRestoreOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.VARIABLES]: { render: (props) => <CustomVariablesOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.CODE]: { render: (props) => <CustomCodeOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.BOLD]: { render: (props) => <CustomBoldOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.UL]: { render: (props) => <CustomUlOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.OL]: { render: (props) => <CustomOLOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.ITALIC]: { render: (props) => <CustomItalicOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.UNDERLINE]: { render: (props) => <CustomUnderlineOption {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.SAVE_BUTTON]: { render: (props) => <CustomSaveButton {...props} /> },
    [TYPE_FUNCTION_RICHTEXT.ATTACHMENT]: { render: (props) => <CustomAttachment {...props} /> }
};

export const LINK_REGEX =
    /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}(?:\/[^'"\s]*)?(?:\?[^'"\s]*)?['\x22<>\\^`{|}\[\]]?[^'\s"]*/g;
