import React, { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createPopper } from '@popperjs/core';
import { TYPE_SELECT_PHONE } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import CallWithVoip from 'app/services/voip/CallWithVoip';
import { openSmsDetail } from 'common/redux/actions/sms/smsAction';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { getAvatarCustomer } from 'common/utils/StringUtils';
import { detectMobile } from 'common/utils/FunctionUtils';
import { LIST_ACTION_DROPDOWN_PHONE } from 'app/const/Phone';

const PhoneDropdown = () => {
    const { t } = useTranslation('customers');
    const dispatch = useDispatch();
    const settings = useSelector(({ auth }) => auth.user.settings);
    const isActiveSMS = useSelector(({ auth }) => auth.user?.settings?.addons?.smsplivo);
    const refCallWithVoip = useRef(null);
    const refPopper = useRef(null);
    const refInstance = useRef(null);
    const refOpen = useRef(false);
    const refFirstTime = useRef(true);

    const { CALL, COPY, SMS } = TYPE_SELECT_PHONE;
    const isHaveVoip = checkIsEnableVoip(settings);
    const isMobile = !!detectMobile();

    const PERMISSION_ACTION = {
        [CALL]: isHaveVoip,
        [SMS]: isActiveSMS,
        [COPY]: true
    };

    const [state, dispatchState] = useReducer(reducer, {
        isCopied: false,
        isUsePopup: false,
        customer: null,
        typeOpen: null,
        phoneNumber: null,
        visible: false,
        target: null
    });
    const { isUsePopup, customer, typeOpen, phoneNumber, visible, target, customerId, contactId } = state;

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_PHONE_POPPER, _openPopper);

        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.OPEN_PHONE_POPPER, _openPopper);
        };
    }, []);

    useEffect(() => {
        if (visible) {
            refOpen.current = true;
            _createInstance();
            addEventListener('click', _handleClickOutside, true);
            addEventListener('scroll', _closeInstance, true);
            return;
        }
        if (!refFirstTime.current) {
            removeEventListener('click', _handleClickOutside, true);
            removeEventListener('scroll', _closeInstance, true);
            refInstance.current?.destroy();
            refOpen.current = false;
            return;
        }
        refFirstTime.current = false;
    }, [visible]);

    const _createInstance = (newTarget) => {
        refInstance.current = createPopper(newTarget || target.target, refPopper.current, {
            placement: 'bottom-start',
            modifiers: [{ name: 'offset', options: { offset: [0, 5], zIndex: 999 } }],
            strategy: 'fixed'
        });
    };

    const _closeInstance = useCallback(() => {
        dispatchState((prev) => ({ ...prev, visible: false }));
    }, []);

    const _openPopper = ({ detail }) => {
        const { target, isHidden } = detail || {};
        if (isHidden) {
            dispatchState((prev) => ({
                ...prev,
                visible: false
            }));
            refOpen.current = false;
            return;
        }

        dispatchState((prev) => ({
            ...prev,
            ...detail,
            visible: true
        }));

        if (refOpen.current) {
            refInstance.current?.destroy();
            _createInstance(target.target);
        }
    };

    const _handleClickOutside = useCallback((e) => {
        if (!refOpen.current || refPopper.current.contains(e.target)) return;
        if (['log-call-items items', 'txt-ellipsis txt-phone-number'].includes(e.target.className)) {
            refInstance.current.update();
            return;
        }
        dispatchState({ visible: false });
        refOpen.current = false;
    }, []);

    const _handleCopy = async () => {
        await navigator.clipboard.writeText(phoneNumber);
        const tooltip = document.createElement('span');
        tooltip.className = 'tooltiptext top';
        tooltip.innerText = t('copied');
        target?.target?.closest('.cursor-pointer.tooltip').appendChild(tooltip);
        setTimeout(() => {
            const element = target?.target?.closest('.cursor-pointer.tooltip');
            if (element) element.removeChild(tooltip);
        }, 1000);
    };

    const _handleDispatchEventClose = ({ event }) => {
        dispatchEvent(new CustomEvent(CUSTOMER_CUSTOM_EVENTS.CLOSE_PHONE_POPPER, { detail: { event } }));
    };

    const _handleMakeCall = () => {
        isHaveVoip
            ? refCallWithVoip.current.makeCall({
                  number: phoneNumber,
                  customerId: customerId || customer?.id,
                  contactId
              })
            : (window.location.href = `tel:${phoneNumber}`);
        _handleDispatchEventClose({ event: CALL });
    };

    const _handleSendSMS = () => {
        if (!isActiveSMS || (!customer?.id && !isUsePopup)) {
            window.location.href = `sms:${phoneNumber}`;
            return;
        }
        _handleDispatchEventClose({ event: SMS });

        if (isUsePopup) {
            const event = new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_SEND_SMS, {
                detail: { default_phone: phoneNumber, typeOpen, isPhoneAction: true }
            });
            dispatchEvent(event);
            return;
        }

        const full_name = customer?.full_name || customer?.name;
        dispatch(
            openSmsDetail({
                ...customer,
                full_name,
                avatar: customer.avatar || getAvatarCustomer(full_name),
                default_phone: phoneNumber,
                isPhoneAction: true
            })
        );
    };

    const _handleClick = (e, type) => {
        e.stopPropagation();

        switch (type) {
            case CALL:
                _handleMakeCall();
                break;

            case SMS:
                _handleSendSMS();
                break;

            case COPY:
                _handleCopy();
                break;

            default:
                break;
        }

        _closeInstance();
    };

    const _renderOptions = () => {
        let flagDivider = 0;
        return LIST_ACTION_DROPDOWN_PHONE.map(({ id, title, icon, isCheckMobile, isCheckCusId }) => {
            const isHideWithoutCus = isCheckCusId && !typeOpen && !customer?.id;
            if ((!PERMISSION_ACTION[id] || isHideWithoutCus) && isCheckMobile && !isMobile) return null;
            flagDivider += 1;

            return (
                <Fragment key={id}>
                    {flagDivider > 1 ? <div className="is-divider" /> : null}
                    <li className="items has-icon" onClick={(e) => _handleClick(e, id)}>
                        {icon}
                        <div className="txt-ellipsis">{t(title)}</div>
                    </li>
                </Fragment>
            );
        });
    };

    return (
        <Fragment>
            {visible && (
                <div
                    ref={refPopper}
                    className="v2-dropdown dropdown-phone active"
                    style={{ zIndex: 999999, width: '240px', height: '44px' }}
                >
                    <div className="v2-dropdown__menu">
                        <ul className="flexcenter">{_renderOptions()}</ul>
                    </div>
                </div>
            )}
            <CallWithVoip ref={refCallWithVoip} />
        </Fragment>
    );
};

export default PhoneDropdown;

export const handleClosePhoneDropdown = () => {
    dispatchEvent(
        new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_PHONE_POPPER, {
            detail: { isHidden: true }
        })
    );
};
