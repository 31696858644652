import IconSync from 'assets/icon/IconSync';
import React, { useState } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionSetRepeatWithJob } from 'app/const/api/Todo';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

let timmer;
export default function RepeatTodo({ repeatWithJob, jobId, activeRepeat = false, isJobRepeat = false }) {
    const { t } = useTranslation(['jobDetail']);
    const [isActive, setIsActive] = useState(!!repeatWithJob);

    function _toggle() {
        if (!activeRepeat || !isJobRepeat) {
            return false;
        }
        setIsActive(() => {
            return !isActive;
        });

        if (timmer) {
            clearTimeout(timmer);
        }

        timmer = setTimeout(() => {
            clientQuery(actionSetRepeatWithJob(jobId), {
                method: 'PUT',
                // eslint-disable-next-line no-undef
                socket_id: global.socketIdJobDetail,
                data: { active: isActive ? 0 : 1 }
            });
        }, 500);
    }

    return (
        <div
            onClick={_toggle}
            className={classNames('v2-btn-default --icon-lg --transparent tooltip btn-repeat', {
                'is-disable': !activeRepeat || !isJobRepeat,
                disable: !isActive
            })}
        >
            <IconSync />
            <p className="tooltiptext top">{t('jobDetail:repeat_with_every_job')}</p>
        </div>
    );
}
