import classNames from 'classnames';
import React from 'react';

const Switch = ({ checked = false, value = 0, disabled = false, handleToggle = () => {} }) => {
    return (
        <div className={classNames('switch large', { 'is-disable': disabled })}>
            <input
                checked={checked}
                onChange={handleToggle}
                id={`toggle-switch-${value}`}
                className="toggle toggle-round"
                type="checkbox"
            />
            <label htmlFor={`toggle-switch-${value}`} />
        </div>
    );
};

export default Switch;
