import { forwardRef, useReducer, useImperativeHandle, useRef } from 'react';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB } from 'app/const/App';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { JOB_COMPLETED } from 'app/const/Job';

const ModalJobRecuring = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        checked: false
    });
    const refEvents = useRef(null);
    const refCheckbox = useRef(null);

    function _showOptions(infoEvent, status, functionCallBack) {
        refEvents.current = {
            infoEvent,
            status,
            functionCallBack
        };

        dispatchState({ isVisible: true });
    }

    function _closeOptions(e) {
        e.stopPropagation();
        refEvents.current.infoEvent.revert();
        dispatchState({ isVisible: false });
    }

    function _onChangeSession(e) {
        e.stopPropagation();
        dispatchState({ checked: !state.checked });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _handleClickOption(e) {
        e.stopPropagation();

        if (state.checked) {
            setLocalStorage(KEY_OPTIONS_CHECK_CHANGE_STATUS_JOB, 1);
        }

        refEvents.current && refEvents.current.functionCallBack(refEvents.current.status);

        dispatchState({ isVisible: false });
    }

    function _renderRecuring() {
        if (!state.isVisible) {
            return false;
        }

        const finalTextStatus = refEvents.current.status.type === JOB_COMPLETED ? 'complete' : 'cancel';

        return (
            <ReactModal
                isOpen
                onRequestClose={_closeOptions}
                className="modal container-modal batch-job --recurring open"
            >
                <div className="modal__overlay bg-fixed" onClick={(e) => _closeOptions(e)} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('calendar:job_status')}</h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={(e) => _closeOptions(e)}>
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal">
                        <div className="rows">
                            <div className="check-items">
                                <input
                                    ref={refCheckbox}
                                    id="auto_apply"
                                    type="checkbox"
                                    onChange={(value) => _onChangeSession(value)}
                                />
                                <div className="item-checkbox">
                                    <label htmlFor="auto_apply">
                                        <span>{t('calendar:auto_apply_this_option_for_the_rest_of_my_session')}</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="rows">
                            <p className="txt-ellipsis fs-14">{`Are you sure you want to ${finalTextStatus} this job?`}</p>
                        </div>
                        <div className="rows" onClick={(e) => _handleClickOption(e)}>
                            <div className="v2-btn-default">{`Change Status to ${finalTextStatus}`}</div>
                        </div>
                    </div>
                    <div className="footer-modal">
                        <div className="v2-btn-default --transparent w-100" onClick={(e) => _closeOptions(e)}>
                            {t('common:cancel')}
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }

    return _renderRecuring();
});

export default ModalJobRecuring;
