import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

let timer;

const GdTextArea = ({ withClassName, placeholder, name, type, value, delay, handleKeypress, handleOnChange }) => {
    const [keyword, setKeyword] = useState(value);

    useEffect(() => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (keyword !== value) {
                handleOnChange({ keyword: keyword });
            }
        }, delay);

        return () => timer && clearTimeout(timer);
    }, [keyword]);

    useEffect(() => {
        setKeyword(value);
    }, [value]);

    const onchange = (e) => {
        setKeyword(e.target.value);
    };

    return (
        <textarea
            className={withClassName}
            placeholder={placeholder}
            type={type}
            name={name}
            value={keyword}
            onKeyPress={handleKeypress}
            onChange={onchange}
        ></textarea>
    );
};

GdTextArea.defaultProps = {
    name: '',
    type: '' || 0,
    placeholder: '',
    value: '',
    delay: 0,
    handleOnChange: () => {}
};

GdTextArea.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string || PropTypes.number,
    delay: PropTypes.number,
    handleOnChange: PropTypes.func
};

export default GdTextArea;
