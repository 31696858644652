import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { TYPE_ACTION_CALL_ACTIVITY } from '../const';

export const FOOTER_COMPARISON = {
    total: {
        title: 'total',
        style: 'col-fixed',
        isFixed: true
    },
    outbound_call: {
        title: 'outbound_call',
        style: 'col col-lg --label-r'
    },
    inbound_call: {
        title: 'inbound_call',
        style: 'col col-lg --label-r'
    },
    total_duration_call: {
        title: 'total_duration_call',
        style: 'col col-lg --label-r',
        type: LIST_TABLE_ITEM_TYPE.TIME_HMS
    },
    avg_duration_call: {
        title: 'avg_duration_call',
        style: 'col col-lg --label-r',
        type: LIST_TABLE_ITEM_TYPE.TIME_HMS
    }
};

export const getGridColumns = (columns = [], actionsStatus = '') => {
    const HEADER_COMPARISON = {
        user: {
            title: 'user',
            subTitle: '',
            style: 'col-fixed',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC,
            isFixed: true
        },
        outbound_call: {
            title: 'outbound_call',
            subTitle: 'all_types',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        inbound_call: {
            title: 'inbound_call',
            subTitle: 'all_types',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        total_duration_call: {
            title: 'total_duration_call',
            subTitle: 'all_types',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        avg_duration_call: {
            title: 'avg_duration_call',
            subTitle: 'avg_duration',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_COMPARISON = [
        {
            id: 'outbound_call',
            title: 'outbound_call',
            status: true
        },
        {
            id: 'inbound_call',
            title: 'inbound_call',
            status: true
        },
        {
            id: 'total_duration_call',
            title: 'total_duration',
            status: true
        },
        {
            id: 'avg_duration_call',
            title: 'avg_duration',
            status: true
        }
    ];

    const REPORT_CONTENT_COMPARISON = {
        outbound_call: {
            id: 'outbound_call',
            style: 'col col-lg --label-r',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CALL_ACTIVITY,
            action: TYPE_ACTION_CALL_ACTIVITY.OUTBOUND_CALL
        },
        inbound_call: {
            id: 'inbound_call',
            style: 'col col-lg --label-r',
            type: LIST_TABLE_ITEM_TYPE.CALL_ACTIVITY,
            action: TYPE_ACTION_CALL_ACTIVITY.INCOMING_CALL
        },
        total_duration_call: {
            id: 'total_duration_call',
            style: 'col col-lg --label-r',
            type: LIST_TABLE_ITEM_TYPE.TIME_HMS
        },
        avg_duration_call: {
            id: 'avg_duration_call',
            style: 'col col-lg --label-r',
            type: LIST_TABLE_ITEM_TYPE.TIME_HMS
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_COMPARISON });
    const newColumns = [];
    const newContent = {};

    REPORT_COLUMNS_COMPARISON.map((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_COMPARISON[item];
            newContent[item] = REPORT_CONTENT_COMPARISON[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
