import { call, put } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { getListTaxes } from 'app/const/Api';
import { TYPE_TAXES } from 'common/redux/actions/taxesAction';

export function* actionGetListTaxes({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            fetchWithToken,
            getListTaxes,
            params
        );
        if (response && response.success) {
            yield put({
                type: TYPE_TAXES.ACTION_GET_LIST_TAXES_SUCCESS,
                payload: response.data
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
