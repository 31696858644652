import { TYPE_ROUTE_OPTIMIZER } from 'common/redux/actions/routeOptimizerAction';

const driveMatrixReducer = (state = {
    isFirstTime: true,
    data: {}
}, action) => {
    switch (action.type) {
        case TYPE_ROUTE_OPTIMIZER.GET_DRIVE_MATRIX_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: action.payload?.data || {}
            };
        default:
            return state;
    }
};

export default driveMatrixReducer;
