import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';

const FormSearchExisting = ({ title = '', selected = {}, ...props }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isLoading: true });
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ removeLoading: _handleRemoveLoading }));

    const _handleRemoveLoading = () => {
        dispatchState({ isLoading: false });
    };

    return (
        <div className="form-search has-form px-3 pb-2">
            <p className="txt">{title}</p>
            <CalendarDropdown
                ref={refDropdown}
                isLoading={state.isLoading}
                selected={selected.email || null}
                selectedOption={selected}
                keyGetName="email"
                keyGetValue="id"
                keyGetKey="id"
                customDropIcon={<IconDropDown />}
                {...props}
            />
        </div>
    );
};

export default forwardRef(FormSearchExisting);
