import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import moment from 'moment';
import React from 'react';

const SMSAddonsInformation = ({ data }) => {
    const lastMonthName = moment().subtract(1, 'month').startOf('month').format('MMM');
    return (
        <div className="wrap-process --hasline">
            <div className="rows">
                <div className="rows__label flex-1">Active SMS phone number:</div>
                <div className="rows__number phone">
                    {data?.phone_number && formatPhoneNumberVoip(data.phone_number)}
                </div>
            </div>
            <div className="rows">
                <div className="rows__label">SMS remaining</div>
                <div className="rows__number">{data?.sms_amount || 0}</div>
            </div>
            <div className="rows">
                <div className="rows__label">Usage this month</div>
                <div className="rows__number">{data?.sms_usage_this_month || 0}</div>
            </div>
            <div className="rows">
                <div className="rows__label">Usage last month ({lastMonthName})</div>
                <div className="rows__number">{data?.sms_usage_last_month || 0}</div>
            </div>
        </div>
    );
};

export default SMSAddonsInformation;
