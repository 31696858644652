import React from 'react';
import GdPhoneItem from './ItemPhone';

const GdListPhone = ({ data = [], isPreview = false, isUsePopup = true, customer, typeOpen }) => {
    if (!data.length) return null;
    return data.map((item, index) => {
        const phoneType = item.type?.name || item.type || item.phone_type;
        const phone = item.phone || item.number || '';
        return (
            <GdPhoneItem
                key={item.id || index.toString()}
                isValid={item.is_valid}
                phoneType={phoneType}
                phone={phone}
                isPreview={isPreview}
                typeOpen={typeOpen}
                {...item}
                isUsePopup={isUsePopup}
                customer={customer}
                contactId={item.contact_id}
            />
        );
    });
};

export default GdListPhone;
