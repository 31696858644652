import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getListButtonEstimate, MODE_VIEW_REPORT } from 'app/const/report/ReportTypeContent';
import GdButton from 'app/components/button';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';

const ActionHeader = ({
    totalSelected = 0,
    totalItems = 0,
    onClickAction = () => {},
    onAddEstimate = () => {},
    params = {},
    isLoadCustomerSuccess,
    checkedItems = {}
}) => {
    const { t } = useTranslation(['customers']);
    const renderListActionButton = () => {
        if (!params) {
            return false;
        }

        let finalMode = MODE_VIEW_REPORT.ACTIVE;

        switch (params.filterTab) {
            case 1:
                finalMode = MODE_VIEW_REPORT.ACTIVE;
                break;
            case 2:
                finalMode = MODE_VIEW_REPORT.ARCHIVE;
                break;
            case 3:
                finalMode = MODE_VIEW_REPORT.DELETE;
                break;
            default:
                break;
        }

        return getListButtonEstimate(finalMode).map((item) => {
            return (
                <GdButton
                    disabled={!totalSelected}
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        onClickAction(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };
    return (
        <div className="header --filter">
            <div className={classNames('header__left flex-1', { 'is-disable': !totalSelected })}>
                <CheckBoxHeader isShowTotal total={totalItems} checkedItems={checkedItems} />
                {renderListActionButton()}
            </div>

            <div
                onClick={onAddEstimate}
                className={classNames('v2-btn-main btn-modal --bg-green', {
                    'is-disable': isLoadCustomerSuccess
                })}
            >
                {t('customers:new_estimate')}
            </div>
        </div>
    );
};

export default ActionHeader;
