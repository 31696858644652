import classNames from 'classnames';
import React from 'react';

const JobSelected = ({ indexJob, currentEvent, headerTitle }) => {
    const { colorEvent } = currentEvent;
    const { background: backgroundColor, border: borderColor, text: textColor } = colorEvent || {};

    return (
        <div
            data-id-selected={currentEvent?.id}
            data-index={indexJob}
            className={classNames('drag-tile-main relative', { 'isnot-job': currentEvent.type !== 'job' })}
        >
            <div
                className="drag-tile fc-event-block jobslist"
                style={{ border: borderColor, backgroundColor, color: textColor }}
            >
                <div className="drag-tile__info tile-header">
                    <span className="fw-600">{headerTitle}</span>
                </div>

                {currentEvent?.tile?.content?.map((item, index) => (
                    <div key={index.toString()} className="drag-tile__info word-break">
                        {item}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobSelected;
