import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useReducer, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { TRACKER } from 'app/config/routes';
import { ERROR_CODE } from 'app/const/App';
import { addPrefixPath } from 'app/const/Branch';
import { LIST_STATUS } from 'app/const/Status';
import { WORK_LOGS, workLogsUpdate } from 'app/const/api/V2';
import IconPlay from 'assets/icon/IconPlay';
import { updateAddonStatus, userLogOutRequest } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import {
    START_TRACKING_EVENT,
    STOP_TRACKING_EVENT,
    TRACKING_EVENTS,
    TRACKING_EVENTS_DETAIL_PAGE,
    TRACKING_OFFLINE,
    TRACKING_ONLINE,
    TRACKING_SHORTCUTS
} from '../constant';
import RealtimeWorkLog from '../services/RealtimeWorkLog';
import {
    clearTracking,
    getTracking,
    isHaveLoggingInAnother,
    reducerTrackingTime,
    setTracking,
    trackerAlert
} from '../utils';
import TimerCounter from './TimerCounter';
import TrackingConfirm from './TrackingConfirm';

const ButtonTracking = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const isTrackingPage = matchPath(pathname, { path: addPrefixPath(TRACKER), exact: true });

    const timezone = useSelector(({ auth }) => auth.user.settings.timezone);
    const worklogAddon = useSelector(({ auth }) => auth.user.settings?.addons?.work_log);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const activeWorklogID = profileData?.progress?.active_work_log;
    const [state, dispatchState] = useReducer(reducerTrackingTime, {
        isCounting: false,
        isDisabledButton: !!activeWorklogID
    });
    const { isCounting, isDisabledButton } = state;
    const { isProPlan, isGrowthPlan, isTrial } = getPlanUser(profileData);
    const hasPermission = (isProPlan || isGrowthPlan || isTrial) && worklogAddon;

    const refCounter = useRef(null);
    const refConfirm = useRef(null);

    useEffect(() => {
        clearTracking();
        // eslint-disable-next-line no-undef
        global.tracker_socket_id = new Date().getTime().toString();
        if (activeWorklogID) {
            dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.SETTING_TRACKING_ID));
            const _handleSuccess = ({ data }) => {
                setTracking(data);
                refCounter.current?.start();
                dispatchState({ type: TRACKING_EVENTS.START_WITH_ID });
                dispatchEvent(new CustomEvent(START_TRACKING_EVENT));
                dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.SETTED_TRACKING_ID));
            };
            const _handleFail = ({ message }) => {
                clearTracking();
                trackerAlert(message);
            };
            clientQuery(workLogsUpdate(activeWorklogID), { data: {}, method: 'GET' }, _handleSuccess, _handleFail);
        }
    }, []);

    const _handleStartAPI = (callbackSuccess = () => {}) => {
        // eslint-disable-next-line no-undef
        const data = { start: moment().tz(timezone).unix(), socket_id: global.tracker_socket_id };
        const _handleSuccess = ({ data }) => {
            // Set local start_time and work_log_id
            setTracking(data);
            callbackSuccess();
            dispatchState({ type: TRACKING_EVENTS.SETTED_TRACKING_ID });
            dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.SETTED_TRACKING_ID));
        };
        const _handleFail = ({ message, statusCode }) => {
            clearTracking();
            trackerAlert(message);
            if (statusCode === ERROR_CODE.PERMISSION_DENIED)
                dispatch(updateAddonStatus({ keyword: 'WORK_LOG', data: 0 }));
        };

        // Call API and set local start_time and work_log_id
        clientQuery(WORK_LOGS, { data, method: 'POST' }, _handleSuccess, _handleFail);
    };

    const _handleEndAPI = (callbackSuccess = () => {}, callbackFinally = () => {}) => {
        const { id, start } = getTracking(); // eslint-disable-next-line no-undef
        const data = { id, start, end: moment().tz(timezone).unix(), socket_id: global.tracker_socket_id };

        const _handleSuccess = ({ data, message }) => {
            callbackSuccess(data);
            trackerAlert(message, LIST_STATUS.SUCCESS);
        };
        const _handleFail = ({ message, statusCode }) => {
            trackerAlert(message);
            if (statusCode === ERROR_CODE.PERMISSION_DENIED) {
                if (isTrackingPage) history.replace(addPrefixPath('/'));
                dispatch(updateAddonStatus({ keyword: 'WORK_LOG', data: 0 }));
            }
        };
        const _handleFinally = () => {
            // Clear local start_time and work_log_id
            clearTracking();
            callbackFinally();
        };

        // Call API stop for update end_time
        clientQuery(WORK_LOGS, { data, method: 'POST' }, _handleSuccess, _handleFail, _handleFinally);
    };

    const _handleStart = (e, startTime) => {
        refCounter.current?.start();
        dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.SETTING_TRACKING_ID));
        if (startTime) {
            dispatchState({ type: TRACKING_EVENTS.START_WITH_ID_DETAIL });
            dispatchEvent(new CustomEvent(START_TRACKING_EVENT));
            dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.SETTED_TRACKING_ID));
        } else {
            dispatchState({ type: TRACKING_EVENTS.START });
            // Call API and set local start_time and work_log_id
            _handleStartAPI(() => {
                dispatchEvent(new CustomEvent(START_TRACKING_EVENT));
            });
        }
    };

    const _handleStop = (event) => {
        const isLogout = event?.detail?.isLogout || false;
        refConfirm.current?.open({ isLogout });
    };

    const _handleStopConfirm = (e, dataEnd, isSameSocket, isLogout = false) => {
        refCounter.current?.pause();
        dispatchState({ type: TRACKING_EVENTS.STOP });
        if (dataEnd) {
            dispatchEvent(new CustomEvent(STOP_TRACKING_EVENT));
            if (!isSameSocket)
                dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.ADD_NEW_WORK_LOG, { detail: dataEnd }));
        } else {
            dispatchEvent(new CustomEvent(STOP_TRACKING_EVENT));
            const _handleFinally = () => {
                if (!isLogout) return;
                if (isHaveLoggingInAnother()) return alert(t('header:alert_tracking_logout'));
                dispatch(userLogOutRequest());
            };

            // Call API stop for update end_time
            _handleEndAPI((data) => {
                dispatchEvent(new CustomEvent(STOP_TRACKING_EVENT));
                dispatchEvent(new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.ADD_NEW_WORK_LOG, { detail: data }));
                refConfirm.current?.close();
            }, _handleFinally);
        }
    };

    useEffect(() => {
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.START, _handleStart);
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.STOP, _handleStopConfirm);
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.CONFIRM_STOP_TRACKING, _handleStop);
        return () => {
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.START, _handleStart);
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.STOP, _handleStopConfirm);
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.CONFIRM_STOP_TRACKING, _handleStop);
        };
    }, []);

    const _handleClick = () => {
        if (!navigator.onLine) return;
        if (isCounting) _handleStop();
        else _handleStart();
    };

    useHotkeys(TRACKING_SHORTCUTS.START, _handleClick, [isCounting, navigator.onLine]);
    useEffect(() => {
        const _handleOnline = () => {
            dispatchState({ type: TRACKING_ONLINE });
        };
        const _handleOffline = () => {
            dispatchState({ type: TRACKING_OFFLINE });
        };

        addEventListener('online', _handleOnline);
        addEventListener('offline', _handleOffline);
        return () => {
            removeEventListener('online', _handleOnline);
            removeEventListener('offline', _handleOffline);
        };
    }, []);

    if (!hasPermission) return null;
    return (
        <>
            <div
                className={classNames('nav-clock flexcenter gap-2', {
                    '--stop': isCounting,
                    'is-disable': isDisabledButton
                })}
                onClick={_handleClick}
            >
                <div className="icon">{isCounting ? <IconPlay isStopTracking /> : <IconPlay isTracking />}</div>
                <TimerCounter ref={refCounter} isHide={!isCounting} className="white fw-500" />
                {!isCounting ? <div className="white fw-500">{t('clock_in')}</div> : null}
            </div>
            <RealtimeWorkLog onStart={_handleStart} onStop={_handleStopConfirm} />
            {/* Confirm modal - using for whole app */}
            <TrackingConfirm ref={refConfirm} onConfirm={_handleStopConfirm} />
        </>
    );
};

export default ButtonTracking;
