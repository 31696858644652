import React, { Fragment, forwardRef, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Prompt } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import GDModalWarning from 'app/components/modal/ModalWarning';
import RichTextDropzone from 'app/components/richtext/components/RichTextDropzone';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_DOCUMENTS } from 'app/config/routes';
import { ADDONS_DOCUMENT_LIST } from 'app/const/Api';
import { ACCEPTED_ATTACHMENTS_PDF } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { ADDONS_MANAGE_DOCUMENTS_LIBRARY } from 'app/const/addons';
import ErrorPage from 'app/modules/error';
import SearchOption from 'app/modules/report/filter/SearchOption';
import IconUpload from 'assets/icon/IconUpload';
import useDragAndDrop from 'common/hooks/useDragAndDrop';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import AvailableDocumentPreview from './components/AvailableDocumentPreview';
import LoadingManageDocument from './components/manage/LoadingManageDocument';
import RowItemPdf from './components/manage/RowItemPdf';
import ServiceManageDocument from './components/manage/ServiceManageDocument';
import { DOCUMENT_LIBRARY } from './constants';
import { limitSizeAttachment } from 'app/modules/customer/utils';
import { LIMIT_ATTACHMENT } from 'app/const/Customers';
import { bytesToSize } from 'common/utils/FileUtils';

const ManageDocuments = () => {
    const { t } = useTranslation('addons');
    const refUpload = useRef(null);
    const refDropWarning = useRef(null);
    const refService = useRef(null);
    const refPreview = useRef(null);
    const refBtnSave = useRef(null);
    const refDocs = useRef({});
    const refUploading = useRef({});
    const refConfirm = useRef(null);
    const refFooter = useRef(null);
    const refAlert = useRef(null);
    const refData = useRef([]);

    const documents = useSelector(({ auth }) => auth.user.settings.addons.documents);
    const isHaveAccessPdf = !!documents?.[ADDONS_MANAGE_DOCUMENTS_LIBRARY];

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        keyword: '',
        uploading: [],
        isLoading: true,
        selected: null
    });
    const { data: finalData, isLoading: finalIsLoading, selected: finalSelected, uploading: finalUploading } = state;
    const docIdSelected = finalSelected?.id;
    const lengthUploading = finalUploading.length;

    useDragAndDrop({ refDropWarning });

    useEffect(() => {
        if (!isHaveAccessPdf) return;
        getListPdf();
    }, [isHaveAccessPdf]);

    useEffect(() => {
        window.addEventListener('beforeunload', checkReload);
        return () => {
            window.removeEventListener('beforeunload', checkReload);
        };
    }, [lengthUploading]);

    useEffect(() => {
        if (finalIsLoading || !refPreview.current) return;

        if (!docIdSelected) {
            refPreview.current._setLoading(false);
        } else {
            refPreview.current._preview(docIdSelected, finalSelected.only_user);
        }
    }, [docIdSelected, finalIsLoading]);

    const checkReload = (e) => {
        if (!lengthUploading) return;
        const confirmationMessage = t('desc_confirm_reload');
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    };

    const getListPdf = () => {
        const _handleSuccess = ({ data }) => {
            data.forEach((item) => {
                if (item.active) refDocs.current[item.id] = item;
            });
            refData.current = data;
            dispatchState((prev) => ({ ...prev, data, isLoading: false, selected: data?.[0] }));
        };

        const _handleFail = (err) => {
            _handleShowStatus(err);
            dispatchState((prev) => ({ ...prev, isLoading: false }));
        };

        clientQuery(
            ADDONS_DOCUMENT_LIST,
            { method: 'GET', data: { type: DOCUMENT_LIBRARY.PDF } },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleUploadFile = (file = []) => {
        const error = new Set();
        Array.from(file).forEach((item) => {
            const { type, size } = item;
            if (type !== ACCEPTED_ATTACHMENTS_PDF) {
                error.add(t('pls_upload_only_pdf_files'));
                return;
            }
            if (limitSizeAttachment(size)) {
                error.add(t('setting:maximum_file_size', { size: bytesToSize(LIMIT_ATTACHMENT * 1000) }));
                return;
            }

            const _handleAddFileUpload = (fileData) => {
                dispatchState((prev) => ({ ...prev, uploading: [fileData, ...prev.uploading] }));
            };
            refService.current.upload({ file: item, readFile: _handleAddFileUpload });
        });

        if (error.size) _handleShowStatus({ message: [...error], success: false });
    };

    const _handleUploadSuccess = (res) => {
        refUploading.current[res.uploadId]?.uploadSuccess(res);
    };

    const _handleUploadFail = ({ id, error }) => {
        refUploading.current[id]?.uploadFail(error);
    };

    const _handleUploadDone = (res) => {
        const { data, uploadId } = res || {};
        if (!data) return;
        delete refUploading.current[uploadId];

        refData.current.unshift(data);
        dispatchState((prev) => ({
            ...prev,
            data: _convertDataSearch({ keyword: prev.keyword }),
            uploading: prev.uploading.filter((item) => item.id !== uploadId),
            selected: refData.current.length <= 1 ? data : prev.selected
        }));
        if (data.active) refDocs.current[data.id] = data;
    };

    const _handleOnUpload = (event) => {
        const files = event.target.files;
        if (!files.length) return false;
        _handleUploadFile(files);
        refUpload.current.value = '';
    };

    const _handleRemoveError = (id) => {
        dispatchState((prev) => ({ ...prev, uploading: prev.uploading.filter((item) => item.id !== id) }));
    };

    const _handleSelectDoc = (selected) => {
        dispatchState((prev) => ({ ...prev, selected }));
    };

    const _convertDataSearch = ({ keyword = '' }) => {
        return refData.current.filter(({ name: docName }) => docName.toLowerCase().includes(keyword.toLowerCase()));
    };

    const _handleSearch = (result) => {
        dispatchState((prev) => ({
            ...prev,
            keyword: result.toLowerCase(),
            data: _convertDataSearch({ keyword: result })
        }));
    };

    const _handleSave = () => {
        clientQuery(
            ADDONS_DOCUMENT_LIST,
            {
                data: {
                    documents: Object.values(refDocs.current).filter((item) => item.active),
                    type: DOCUMENT_LIBRARY.PDF
                },
                method: 'PUT',
                toFormData: false
            },
            _handleShowStatus,
            _handleShowStatus,
            () => refBtnSave.current.removeLoading()
        );
    };

    const _handleConfirm = ({ id, name }) => {
        refConfirm.current._open({
            dataConfirm: id,
            description: t('addons:delete_document_description', { name }),
            title: t('addons:delete_document')
        });
    };

    const _handleRemove = (id) => {
        const _handleSuccess = (res) => {
            _handleShowStatus(res);
            refConfirm.current._close();
            refData.current = refData.current.filter((item) => item.id !== id);
            delete refDocs.current[id];
            dispatchState((prev) => {
                const newData = _convertDataSearch({ keyword: prev.keyword });
                return {
                    ...prev,
                    data: newData,
                    selected: id === prev.selected.id ? newData?.[0] : prev.selected
                };
            });
        };

        const _handleFail = (err) => {
            _handleShowStatus(err);
            refConfirm.current._close();
        };

        refService.current.remove({ id, success: _handleSuccess, fail: _handleFail });
    };

    const _handleUpdateItem = ({ data }) => {
        const docId = data.id;
        refDocs.current[docId] = data;
        refData.current = refData.current.map((item) => {
            if (item.id === docId) return data;
            return item;
        });
    };

    const _renderListDocs = () => {
        if (finalIsLoading) return <LoadingManageDocument />;

        return (
            <div className="list__document relative flex-column">
                <div className="list__document-upload flex-column gap-10">
                    <div className="upload-file">
                        <label
                            htmlFor="choose_afile"
                            className="v2-btn-main has-icon svg-white-stroke justify-center w-100"
                        >
                            <IconUpload />
                            {t('upload_pdf_docs')}
                        </label>
                        <input
                            ref={refUpload}
                            id="choose_afile"
                            type="file"
                            accept={ACCEPTED_ATTACHMENTS_PDF}
                            multiple
                            onChange={_handleOnUpload}
                        />
                    </div>
                    <div className="v2-btn-default --has-blue">{t('drag_and_drop_files_here')}</div>
                </div>
                <SearchOption style="no-effect" isSearchIcon placeholder={t('search')} onSearch={_handleSearch} />
                <div className="scrolls flex-1">{_renderListItem()}</div>

                <RichTextDropzone
                    ref={refDropWarning}
                    pointerEventNone={false}
                    text={t('drag_files_here')}
                    onDrop={_handleUploadFile}
                />
            </div>
        );
    };

    const _renderListItem = () => {
        const lengthData = finalData.length;
        if (!lengthData && !lengthUploading) return <GridEmpty isFlat />;

        return (
            <Fragment>
                {finalUploading.map((item) => (
                    <RowItemPdf
                        ref={(ref) => (refUploading.current[item.id] = ref)}
                        key={item.id}
                        item={item}
                        isUploading
                        onUploadSuccess={_handleUploadDone}
                        onRemoveError={_handleRemoveError}
                    />
                ))}
                {!!lengthData ? (
                    finalData.map((item) => {
                        return (
                            <RowItemPdf
                                key={item.id}
                                item={item}
                                idSelected={finalSelected?.id}
                                onSelectDoc={_handleSelectDoc}
                                onRemoveDoc={_handleConfirm}
                                onUpdateItem={_handleUpdateItem}
                            />
                        );
                    })
                ) : (
                    <GridEmpty isFlat />
                )}
            </Fragment>
        );
    };

    const _handleShowStatus = ({ message, success }) => {
        showStatusBar({ id: 'manage_pdf', refAlert, message, success });
    };

    if (!isHaveAccessPdf) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__documents">
                <div className="title-header">
                    <h3 className="title-header__label">{t('my_pdf_docs')}</h3>
                    <div className="btn-action">
                        <Link to={addBranchPath(ADDONS_DOCUMENTS)} className="v2-btn-default --transparent">
                            {t('cancel')}
                        </Link>
                        <ButtonSave ref={refBtnSave} onSave={_handleSave} />
                    </div>
                </div>
                <GDStatusBar ref={refAlert} />
                <div className="list --adddocs">
                    {_renderListDocs()}
                    <AvailableDocumentPreview ref={refPreview} />
                </div>
            </div>
            <GDModalWarning
                ref={refConfirm}
                isLargeContent={false}
                footer={<FooterConfirm ref={refFooter} onSave={_handleRemove} />}
            />
            <ServiceManageDocument
                ref={refService}
                onUploadSuccess={_handleUploadSuccess}
                onUploadFail={_handleUploadFail}
            />
            <Prompt when={!!lengthUploading} message={t('desc_confirm_reload')} />
        </div>
    );
};

export default ManageDocuments;

export const FooterConfirm = forwardRef(({ dataConfirm = {}, onClose = () => {}, onSave = () => {} }, ref) => {
    const { t } = useTranslation();

    const _handleSave = () => {
        onSave(dataConfirm);
    };

    return (
        <div className="footer-modal btn-close justify-end">
            <div className="v2-btn-default --transparent" onClick={onClose}>
                {t('cancel')}
            </div>
            <ButtonSave ref={ref} title={t('yes')} wrapClass="v2-btn-main ml-2" onSave={_handleSave} />
        </div>
    );
});
