import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { getListButtonDocument, MODE_VIEW_REPORT, REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import ReportSearch from 'app/modules/report/components/ReportSearch';
import GdButton from 'app/components/button';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';

const ActionHeader = ({
    onClickAction = () => {},
    onAddDocument = () => {},
    handleUpdate = () => {},
    checkedItems = {},
    params = {},
    totalItems = 0,
    keyLocalStore = ''
}) => {
    const { t } = useTranslation(['common', 'report']);

    const renderListActionButton = () => {
        if (!params) {
            return false;
        }

        let finalMode = MODE_VIEW_REPORT.ACTIVE;

        switch (params.filterTab) {
            case 2:
                finalMode = MODE_VIEW_REPORT.DELETE;
                break;
            case 1:
                finalMode = MODE_VIEW_REPORT.ACTIVE;
                break;
            default:
                break;
        }

        return getListButtonDocument(finalMode).map((item) => {
            return (
                <GdButton
                    disabled={!checkedItems.ids.length}
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        onClickAction(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    const _handleNewUpdate = () => {
        setLocalStorage(keyLocalStore, { ...getLocalStorage(keyLocalStore), offset: 0, currentPage: 1 });
        handleUpdate();
    };

    return (
        <div className="header --filter">
            <div className="header__left flex-1">
                <CheckBoxHeader isShowTotal total={totalItems} checkedItems={checkedItems} />
                {renderListActionButton()}
                <ReportDateRangePicker
                    reportType={REPORT_TYPE.DOCUMENT_CUSTOMER_LIST}
                    onSelect={_handleNewUpdate}
                    isNotSetDefault
                    wrapperClassName="header-items"
                />
                <ReportSearch
                    reportType={REPORT_TYPE.DOCUMENT_CUSTOMER_LIST}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleNewUpdate}
                />
            </div>

            <div className="v2-btn-main btn-modal --bg-green" onClick={onAddDocument}>
                {t('customers:new_document')}
            </div>
        </div>
    );
};

export default ActionHeader;
