import React from 'react';
import { useSelector } from 'react-redux';

import { OBJECT_TYPES } from '../../constants/types';
import SubCategory from './SubCategory';

const Subcategories = ({ subcategories }) => {
    return (
        <div className="filter-result__detail">
            {subcategories.map((subcategory, index) => (
                <SubcategorySection subcategory={subcategory} key={`${subcategory.objectType}_${index}`} />
            ))}
        </div>
    );
};

const SubcategorySection = ({ subcategory }) => {
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);
    if (subcategory.objectType === OBJECT_TYPES.CUSTOM_FIELD && !customFieldAddon) return null;
    return (
        <section>
            <h3 className="txt-ellipsis">{subcategory.title}</h3>
            <div className="wrap-result">
                {subcategory.fields.map((field) => {
                    return <SubCategory key={`${field.objectType}_${field.id}`} {...field} />;
                })}
            </div>
        </section>
    );
};

export default Subcategories;
