import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_PORTAL_SETTING } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { showStatusBar } from 'common/utils/FunctionUtils';
import { ITEMS_SETTING_PORTAL } from '../../constants';
import LoadingSettings from './LoadingSettings';

const SettingPortalAddons = (props, ref) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        settings: {},
        isDisable: false
    });
    const { isOpen, settings, isLoading, isDisable } = state;
    const refForm = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState({ isOpen: true });
        _handleGetDetail();
    };

    const _close = () => {
        dispatchState({ isOpen: false, isLoading: true, settings: {}, isDisable: false });
    };

    const _handleGetDetail = () => {
        const _handleGetSuccess = ({ data }) => dispatchState({ settings: data, isLoading: false });

        const _handleGetFail = ({ message }) => {
            showStatusBar({ id: 'get_detail_error', message, refAlert });
            dispatchState({ isLoading: false, isDisable: true });
        };

        clientQuery(ADDONS_PORTAL_SETTING, { method: 'GET' }, _handleGetSuccess, _handleGetFail);
    };

    const _handleSave = () => {
        const data = {};
        const elements = refForm.current.elements;
        for (let index = 0; index < elements.length; index++) {
            const { type, name, checked } = elements[index];
            if (type === 'checkbox') data[name] = checked ? 1 : 0;
        }

        const _handleSaveSuccess = () => _close();
        const _handleSaveFail = ({ message }) => {
            showStatusBar({ id: 'save_error', message, refAlert });
        };
        const _finally = () => {
            refButtonSave.current?.removeLoading();
        };
        clientQuery(
            ADDONS_PORTAL_SETTING,
            { data: { ...data, profile: 0, location: 0 }, toFormData: false, method: 'PUT' },
            _handleSaveSuccess,
            _handleSaveFail,
            _finally
        );
    };

    const _renderListItem = () => {
        return ITEMS_SETTING_PORTAL.map(({ id, title }) => (
            <ItemCheckbox key={id} id={id} title={t(title)} defaultValue={settings[id]} />
        ));
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="customerPortalSettings"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close}></div>
            <div className="modal__container modal-customer-portal-settings">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('customer_portal')}</h3>
                </div>
                <GDStatusBar ref={refAlert} />
                {isLoading ? (
                    <LoadingSettings />
                ) : (
                    <form ref={refForm} className={classNames('body-modal scrolls', { 'is-disable': isDisable })}>
                        <div className="portal-settings__description">{t('desc_modal_portal')}</div>
                        <ul className="portal-settings__list">{_renderListItem()}</ul>
                    </form>
                )}
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleSave} disabled={isDisable} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(SettingPortalAddons);

const ItemCheckbox = ({ id = '', defaultValue = '', title = '' }) => {
    return (
        <li className="check-items">
            <input id={id} type="checkbox" name={id} defaultChecked={defaultValue} />
            <div className="item-checkbox">
                <label className="label" htmlFor={id}>
                    {title}
                </label>
            </div>
        </li>
    );
};
