import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconDialpad from 'assets/icon/IconDialpad';
import React, { Fragment, useId, useRef } from 'react';

export default function Dialpad({ onCallDial, callId }) {
    const refDropDown = useRef(null);
    const dropdownId = useId();

    const _handleClickNumber = (value) => {
        onCallDial(callId, value);
    };

    return (
        <DropdownPopper
            ref={refDropDown}
            isUseToggle
            placement="bottom"
            wrapperClassName="v2-dropdown"
            wrapperListClass="v2-dropdown__menu wrap-dialpad-main --center scrolls"
            buttonClassName="dropbtn v2-btn-default --icon-lg --transparent btn-bg-grey tooltip"
            customButton={
                <Fragment>
                    <IconDialpad />
                    <span className="tooltiptext bottom">Dialpad</span>
                </Fragment>
            }
        >
            <GdDialPad id={dropdownId} onClickNumber={_handleClickNumber} />
        </DropdownPopper>
    );
}

export const GdDialPad = ({
    id = 'dial_pad',
    wrapperClass = 'wrap-dialpad d-flex flex-wrap',
    onClickNumber = () => {}
}) => {
    const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'];
    const alphabets = ['', 'ABC', 'DEF', 'GHI', 'JKL', 'MNO', 'PQRS', 'TUV', 'WXYZ', '', '+', ''];

    const _renderDialPad = () => {
        return numbers.map((item, index) => {
            return (
                <span key={index.toString()} className="wrap-dialpad__item" onClick={() => onClickNumber(item)}>
                    <b className="dialpad-number black fs-18">{item}</b>
                    <span className="dialpad-label fs-10 grey-generic mt-1">{alphabets[index]}</span>
                </span>
            );
        });
    };

    return (
        <div id={id} className={wrapperClass}>
            {_renderDialPad()}
        </div>
    );
};
