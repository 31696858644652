import { call } from 'redux-saga/effects';
import { postWithToken, putWithToken } from 'common/utils/ApiUtils';
import {
    REPORT_INVOICE_SEND_MAIL,
    REPORT_INVOICE_GENERATE_PDF,
    REPORT_ESTIMATE_SEND_MAIL,
    REPORT_SERVICE_LOOKUP_REASSIGN_JOBS
} from 'app/const/Api';

export function* sendMailInvoices({ params, actionSuccess, actionFailed }) {
    const response = yield call(postWithToken, REPORT_INVOICE_SEND_MAIL, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* generatePdfInvoices({ params, actionSuccess, actionFailed }) {
    const response = yield call(postWithToken, REPORT_INVOICE_GENERATE_PDF, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* sendMailEstimates({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ESTIMATE_SEND_MAIL, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* reassignJobsServiceLookup({ params, actionSuccess, actionFailed }) {
    delete params.value;
    const response = yield call(putWithToken, REPORT_SERVICE_LOOKUP_REASSIGN_JOBS, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}
