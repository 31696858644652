import { TYPE_BUTTON_ACTIONS } from 'app/const/App';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomerJobHeader from './CustomerJobHeader';

const CustomerJobActions = ({
    reportType,
    jobStatusView,
    totalSelected = 0,
    isDeleted = false,
    onUpdate = () => {},
    onClick = () => {},
    onAddJob = () => {},
    checkedItems = {},
    totalItems = 0
}) => {
    const { t } = useTranslation('customers');
    const _handleClick = () => {
        onClick(isDeleted ? TYPE_BUTTON_ACTIONS.UNDELETE : TYPE_BUTTON_ACTIONS.DELETE);
    };
    return (
        <div className="header --filter">
            <CheckBoxHeader checkedItems={checkedItems} isShowTotal total={totalItems} />
            <div
                className={classNames(
                    'v2-btn-default --grey has-icon svg-delete-grey btn-modal btn-delete header-items',
                    { 'is-disable': !totalSelected }
                )}
                onClick={_handleClick}
            >
                <IconTrash />
                {isDeleted ? t('un_delete') : t('delete')}
            </div>

            <CustomerJobHeader
                reportType={reportType}
                isDeleted={isDeleted}
                activeStatus={jobStatusView}
                onChangeStatusView={onUpdate}
            />

            <div className="v2-btn-main btn-modal --bg-green" onClick={onAddJob}>
                {t('new_job')}
            </div>
        </div>
    );
};

export default CustomerJobActions;
