import { forwardRef, useContext, useImperativeHandle } from 'react';
import { VOIPContext } from 'app/services/voip/VOIPProvider';

const CallWithVoip = forwardRef((props, ref) => {
    const { onOutGoingCall } = useContext(VOIPContext);

    useImperativeHandle(ref, () => ({
        makeCall: ({ number, contactId, customerId }) => {
            onOutGoingCall({
                fromNumber: null,
                toPhoneNumber: number,
                contactId,
                customerId
            });
        }
    }));

    return false;
});

export default CallWithVoip;
