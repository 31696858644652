import React from 'react';
import classNames from 'classnames';

export default function Progress({ percent = 0, numberDone = 0 }) {
    return (
        <div className="flex-1 mx-2">
            <div
                className={classNames('progress-bar', {
                    '--orange': !!numberDone && percent !== 1,
                    '--green': percent === 1
                })}
            >
                <span style={{ width: `${percent * 100}%` }} />
            </div>
        </div>
    );
}
