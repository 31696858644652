import React from 'react';

const IconOpps = ({ isGrey = false }) => {
    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={32} height={32} rx={4} fill="#F5F5F5" />
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.28569 14H22.7143L24.145 11.4961C24.526 10.8295 24.0446 10 23.2768 10H8.72316C7.95533 10 7.47397 10.8295 7.85491 11.4961L9.28569 14ZM9.85718 15L12.1429 19H19.8572L22.1429 15H9.85718Z"
                    fill="#5F6164"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.9982 24C15.3805 23.9994 14.8104 23.6681 14.5039 23.1318L12.7143 20H19.2857L17.4961 23.1318C17.1896 23.6681 16.6195 23.9994 16.0018 24H15.9982Z"
                    fill="#5F6164"
                />
            </svg>
        );

    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FFF"
            />
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.28569 14H22.7143L24.145 11.4961C24.526 10.8295 24.0446 10 23.2768 10H8.72316C7.95533 10 7.47397 10.8295 7.85491 11.4961L9.28569 14ZM9.85718 15L12.1429 19H19.8572L22.1429 15H9.85718Z"
                fill="#1EAA5C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9982 24C15.3805 23.9994 14.8104 23.6681 14.5039 23.1318L12.7143 20H19.2857L17.4961 23.1318C17.1896 23.6681 16.6195 23.9994 16.0018 24H15.9982Z"
                fill="#1EAA5C"
            />
        </svg>
    );
};

export default IconOpps;
