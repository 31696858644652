import React, { useContext, useReducer, useEffect } from 'react';
import IconUser from 'assets/icon/IconUser';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import { reducer } from 'app/const/Reducer';

export default function LastName() {
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentUnitLastName = unitDetailContextData.last_name;

    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        value: parentUnitLastName
    });

    const finalLastName = state.value;

    useEffect(() => {
        parentUnitLastName !== finalLastName && setState({ value: parentUnitLastName });
    }, [parentUnitLastName]);

    function _handleSubmitLastName(e) {
        updateUnitDetailContextData({
            last_name: e.target.value
        });
    }

    function _handleChangeValue(e) {
        setState({
            value: e.target.value
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconUser />
                Last Name
            </div>
            <div className="rows__form">
                <input
                    className="field-input"
                    type="text"
                    placeholder="Last Name"
                    value={finalLastName}
                    onChange={(e) => _handleChangeValue(e)}
                    onBlur={(e) => _handleSubmitLastName(e)}
                />
            </div>
        </div>
    );
}
