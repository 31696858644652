import React, { useId, useRef } from 'react';

export const GdAudioPlayer = ({ src = '', controls = true, classWrapper = 'audio-player', classAudio = 'w-100' }) => {
    const refAudio = useRef(null);
    const idAudio = useId();

    const handleLoadDuration = () => {
        const duration = refAudio.current?.duration;
        if (isNaN(duration) || duration === Infinity) {
            refAudio.current.currentTime = 10000000;
            setTimeout(() => {
                refAudio.current.currentTime = 0;
            }, 10);
        }
    };

    return (
        <div className={classWrapper}>
            <audio
                ref={refAudio}
                id={idAudio}
                src={src}
                controls={controls}
                className={classAudio}
                preload="auto"
                onLoadedMetadata={handleLoadDuration}
            />
        </div>
    );
};
