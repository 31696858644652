import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CONVERSATION_TYPE, TABS_SMS } from 'app/const/Sms';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { TYPE_SHOW_SMS } from 'app/modules/inbox/const';

const ConversationUnActive = loadable(() => import('./components/ConversationUnActive'));
const ConversationDetail = loadable(() => import('app/modules/sms/conversation/Detail'));
const ConversationList = loadable(() => import('app/modules/sms/customer/components/ConversationList'));
const ConversationNew = loadable(() => import('app/modules/sms/customer/components/ConversationNew'));

const Sms = forwardRef(
    ({ socketReceiver = null, socketConversation = null, onUpdateTotal = () => {}, onCloseSms = () => {} }, ref) => {
        const [state, dispatchState] = useReducer(reducer, {
            step: 0,
            customer: null,
            isVisible: false,
            refresh: 0,
            isUnknow: false,
            conversationId: ''
        });
        const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || 0);
        const refConversationDetail = useRef(null);
        const refConversationList = useRef(null);

        const {
            isVisible: finalIsVisible,
            step: finalStep,
            isUnknow,
            customer: finalCustomer,
            refresh: finalRefresh,
            conversationId
        } = state;

        useImperativeHandle(ref, () => ({ _openModalSMS, _handleClose }));

        useEffect(() => {
            const elm = document.getElementById('button_show_number_sms');
            if (finalIsVisible) {
                elm && elm.classList.add('active');
            } else {
                elm && elm.classList.remove('active');
            }
        }, [finalIsVisible]);

        function _handleClose() {
            finalIsVisible && dispatchState({ isVisible: false, step: 0, customer: null });
            onCloseSms();
            refConversationDetail.current?.updateBookMarkOnCLose();
        }

        const _openModalSMS = (e, item, phone_location = null, type = '') => {
            e && e.stopPropagation();
            switch (type) {
                case TABS_SMS.ITEM:
                    const customerPhone = item?.phone?.phone;
                    dispatchState({
                        step: 2,
                        customer: {
                            ...item.customer,
                            default_phone: customerPhone || '',
                            phone_location: [customerPhone || null],
                            filter_phone: item.filter_phone
                        },
                        isVisible: true,
                        isUnknow: item.key === CONVERSATION_TYPE.UNKNOW,
                        conversationId: item.id
                    });
                    break;
                case TABS_SMS.CUSTOMER:
                    if (finalIsVisible) {
                        refConversationDetail.current && refConversationDetail.current.reset();
                    }
                    dispatchState({
                        step: !!activateSms ? 2 : 4,
                        customer: { ...item, phone_location },
                        isVisible: true,
                        isUnknow: !parseInt(item.id),
                        refresh: Date.now(),
                        conversationId: item.conversationId
                    });
                    break;
                default:
                    dispatchState({ step: 1, isVisible: true });
                    break;
            }
        };

        const _setStep = (step) => {
            dispatchState({ step });
        };

        const _handleAssignCustomer = (newDataCustomer, phone) => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isUnknow: false,
                    customer: {
                        ...prev.customer,
                        ...newDataCustomer,
                        phone
                    }
                };
            });
        };

        const _handleCreateLead = (phone) => {
            refConversationList.current.createLead(conversationId, phone, _handleAssignCustomer);
        };

        const _handleAssignLead = (phone) => {
            refConversationList.current.assignLead(conversationId, phone, _handleAssignCustomer);
        };

        if (!finalIsVisible) {
            return false;
        }

        return (
            <div id={'show_list_dropdown_sms_header'}>
                <ConversationList
                    ref={refConversationList}
                    socketReceiver={socketReceiver}
                    socketConversation={socketConversation}
                    onClose={_handleClose}
                    setStep={_setStep}
                    handleOnClickItem={_openModalSMS}
                    isMenuVisible={finalIsVisible && finalStep === 1}
                />
                {finalIsVisible && finalStep === 2 && (
                    <ConversationDetail
                        ref={refConversationDetail}
                        socketReceiver={socketReceiver}
                        socketConversation={socketConversation}
                        customer={finalCustomer || {}}
                        setStep={_setStep}
                        onClose={_handleClose}
                        onCreateLead={_handleCreateLead}
                        onAssignLead={_handleAssignLead}
                        onUpdateTotal={onUpdateTotal}
                        shouldRefresh={finalRefresh}
                        isUnknow={isUnknow}
                        conversationId={conversationId}
                        type={TYPE_SHOW_SMS.HEADER}
                        isPhoneAction={finalCustomer?.isPhoneAction}
                    />
                )}
                {finalIsVisible && finalStep === 3 && (
                    <ConversationNew onClose={_handleClose} setStep={_setStep} handleOnClickCustomer={_openModalSMS} />
                )}
                {finalIsVisible && finalStep === 4 && (
                    <ConversationUnActive customer={finalCustomer} onClose={_handleClose} />
                )}
            </div>
        );
    }
);

export default Sms;
