import React, { useEffect, useReducer, useRef } from 'react';

import { reducer } from 'app/const/Reducer';
import BodyConversation from '../components/BodyConversation';
import FooterConversation from '../components/FooterConversation';
import HeaderConversation from '../components/HeaderConversation';

const AIConversationDetail = ({ onClose = () => {} }) => {
    const [state, dispatchState] = useReducer(reducer, { data: [] });
    const { data: finalData } = state;

    const refConversationDetail = useRef(null);

    useEffect(() => {
        document.addEventListener('click', _handleClickOutside, true);

        return () => {
            document.removeEventListener('click', _handleClickOutside, true);
        };
    }, []);

    const _handleClickOutside = (event) => {
        if (refConversationDetail.current && !refConversationDetail.current.contains(event.target)) {
            onClose();
        }
    };

    const _handleAddData = ({ data = {} }) => {
        dispatchState((prev) => ({ ...prev, data: [...prev.data, data] }));
    };

    return (
        <div ref={refConversationDetail} className="conversation-sms notification-dropdown active">
            <HeaderConversation onClose={onClose} />
            <BodyConversation data={finalData} />
            <FooterConversation onAddData={_handleAddData} />
        </div>
    );
};

export default AIConversationDetail;
