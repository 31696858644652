import { TYPE_CUSTOMER } from "common/redux/actions/customers/customerAction";

const customerReportReducer = (state = {
    list_customer: [],
    first_time: true,
    total_customer: 0,
}, action) => {
    switch (action.type) {
        case TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER_REPORT_SUCCESS:
            const newListCustomer = action.resetData ? action.data.data : [...state.list_customer, ...action.data.data]
            const finalTotal = action.resetData ? action.data.total : state.total_customer

            return { ...state, list_customer: newListCustomer, first_time: false, total_customer: finalTotal }
        default:
            return state;
    }
};

export default customerReportReducer;
