import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconPlus from 'assets/icon/IconPlus';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import React, { useContext } from 'react';
import { INVOICE_ITEM_SIMPLE } from 'app/modules/jobdetail/const/Invoice';
import { v4 as uuidv4 } from 'uuid';
import PackageSummary from './Summary';
import PackageColor from '../../../addestimate/form/Color';
import IconAlert from 'assets/icon/IconAlert';
import DeleteItems from '../items/detail/Delete';

const EstimatePackage = ({ packageData, renderItems, listItems, index }) => {
    const { id: packageId, color, name, is_check, deposit } = packageData;
    const { invoiceData, onChangeData, onChangeEstimatePackage } = useContext(AddInvoiceContext);
    const isPayDeposit = !!deposit.invoices?.length;

    function _handleAddNewItem() {
        document.getElementById(`package_item_detail_${packageId}`).classList.add('dp-hide');
        onChangeData((prev) => {
            return {
                ...prev,
                items: invoiceData.items.concat({ ...INVOICE_ITEM_SIMPLE, id: uuidv4(), package_id: packageId })
            };
        });
    }

    const _onChangePropPackage = (newValue = {}) => {
        onChangeData((prev) => {
            return {
                ...prev,
                packages: prev.packages.map((item) => {
                    if (item.id === packageId) {
                        return {
                            ...item,
                            ...newValue
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _handleChangName = (e) => {
        document.getElementById(`package_item_name_${packageId}`).classList.remove('field-error');
        _onChangePropPackage({ name: e.target.value });
    };

    const _handleChangeColor = (newColor) => {
        _onChangePropPackage({ color: newColor });
    };

    const _handleDelete = () => {
        onChangeEstimatePackage((prev) => {
            return {
                ...prev,
                packages: prev.packages.filter((item) => item.id !== packageId),
                items: prev.items.filter((item) => item.package_id !== packageId)
            };
        });
    };

    const _getFalgDelete = () => {
        if (!index || (is_check && isPayDeposit)) {
            return false;
        }
        return true;
    };

    return (
        <div className="items-package" style={{ '--bg-estimate': color }}>
            <div className="attention --danger flexcenter gap-6 dp-hide" id={`package_item_detail_${packageId}`}>
                <IconAlert />
                <p className="red-default fs-13">This package needs to have at least 1 item.</p>
            </div>
            <div className="items-package__header">
                <div className="flexcenter">
                    <IconWorkOrder isHasColor />
                    <input
                        id={`package_item_name_${packageId}`}
                        className="field-input"
                        defaultValue={name}
                        type="text"
                        placeholder="Package Name"
                        onBlur={_handleChangName}
                    />
                    <PackageColor onChange={_handleChangeColor} parentColor={color} />
                </div>
                {_getFalgDelete() && <DeleteItems onDelete={_handleDelete} isPackage />}
            </div>
            {renderItems(
                listItems.filter((item) => item.package_id === packageId),
                packageId
            )}
            <div className="rows items-payment">
                <div className="action-additems pl-0">
                    <div onClick={_handleAddNewItem} className="v2-btn-default has-icon btn-package">
                        <IconPlus />
                        Add Item
                    </div>
                </div>
                <PackageSummary packageData={packageData} />
            </div>
        </div>
    );
};

export default EstimatePackage;
