export const STATUS_REFERRAL = {
    APPLIED: 'applied',
    ACTIVE: 'active',
    TRIAL: 'trial',
    EXPIRED: 'expired',
    CANCELED: 'canceled'
};

export const LABEL_STATUS_REFERRAL = {
    [STATUS_REFERRAL.APPLIED]: {
        style: '--applied',
        label: 'applied',
        title: 'applied'
    },
    [STATUS_REFERRAL.ACTIVE]: {
        style: '--active',
        label: 'active',
        title: 'active'
    },
    [STATUS_REFERRAL.TRIAL]: {
        style: '--trial',
        label: 'in_trial',
        title: 'in_trial'
    },
    [STATUS_REFERRAL.EXPIRED]: {
        style: '--expired',
        label: 'expired_trial',
        title: 'expired'
    },
    [STATUS_REFERRAL.CANCELED]: {
        style: '--expired',
        label: 'canceled'
    }
};

export const REFERRAL_QUERY = 'referral';
