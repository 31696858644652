import React, { useReducer, useEffect, useRef, useId } from 'react';
import moment from 'moment';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { reducer } from 'app/const/Reducer';
import IconDropDown from 'assets/icon/IconDropDown';
import TimePicker from 'app/modules/jobdetail/components/time/picker';
import { checkPermission } from 'common/utils/PermissionUtils';
import { useSelector } from 'react-redux';
import { PERMISSIONS } from 'app/const/Permissions';
import IconClose from 'assets/icon/IconClose';

const CheckInOutJobDetail = ({
    classBtn,
    isCheckOut = false,
    finalText,
    timeCheck,
    disableBtn,
    title = '',
    activeCheckOut = true,
    refresh = false,
    onSelect = () => {}
}) => {
    const checked = timeCheck.length !== 0;
    const dropdownId = useId();

    const MODES = {
        RESET: 'reset-time-active',
        SAVE: 'save-time-active',
        CUSTOM: 'set-custom-time-active'
    };

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isChecked: checked,
        timeSelected: checked ? moment(timeCheck).utc() : moment(),
        timeCurrent: moment(),
        classActive: checked ? MODES.RESET : MODES.SAVE
    });

    useEffect(() => {
        if (timeCheck && refresh) {
            dispatchState({
                timeSelected: checked ? moment(timeCheck).utc() : moment(),
                classActive: checked ? MODES.RESET : MODES.SAVE,
                isChecked: checked
            });
        }
    }, [timeCheck]);

    /**
     * This case reset checkout when reset time checkin
     */
    useEffect(() => {
        if (isCheckOut && !activeCheckOut && finalClassActive === MODES.RESET) {
            dispatchState({
                classActive: MODES.SAVE,
                isChecked: false,
                isVisible: false,
                timeCurrent: moment()
            });
        }
    }, [activeCheckOut]);

    const {
        isVisible: finalIsVisible,
        isChecked: finalIsChecked,
        classActive: finalClassActive,
        timeSelected: finalTimeSelected
    } = state;

    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled) || [];

    const refForm = useRef(null);
    const refTimePicker = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(dropdownId);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _handleCloseForm();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById(dropdownId);

        if (
            refForm.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refForm.current.contains(event.target)
        ) {
            _handleCloseForm();
        }
    }

    function _handleCloseForm() {
        dispatchState({
            isVisible: false
        });
    }

    function _openForm() {
        if (disableBtn) return false;

        dispatchState({
            isVisible: !finalIsVisible,
            classActive: finalIsChecked ? MODES.RESET : MODES.SAVE,
            timeCurrent: moment(),
            timeSelected: checked ? moment(timeCheck).utc() : moment()
        });
    }

    function _saveCurrentTime() {
        onSelect(convertTimeToISO(state.timeCurrent));
        dispatchState({
            isVisible: false,
            isChecked: true,
            classActive: MODES.RESET,
            timeSelected: state.timeCurrent
        });
    }

    function _resetTime() {
        onSelect('');
        dispatchState({
            isChecked: false,
            classActive: MODES.SAVE,
            timeCurrent: moment(),
            timeSelected: moment()
        });
    }

    function _handleChangeTime() {
        const time = refTimePicker.current._getTime();
        const finalTime = moment(`${time.hour}:${time.minute} ${time.midDay}`, 'HH:mm A');

        dispatchState({
            isVisible: false,
            isChecked: true,
            classActive: MODES.RESET,
            timeSelected: finalTime
        });

        onSelect(convertTimeToISO(finalTime));
    }

    return (
        <div ref={refForm} className={`v2-dropdown ${classBtn} ${finalClassActive} ${finalIsVisible ? 'active' : ''}`}>
            <div
                className={`dropbtn items time-checked ${isCheckOut ? 'time-checkout' : 'time-checkin'} ${
                    !activeCheckOut || disableBtn ? 'btn-disabled' : ''
                }`}
                title={title}
                onClick={_openForm}
                tabIndex="0"
            >
                <span className="text text-center w-100">{finalText}</span>
                <div className="current-time">
                    <div>{finalTimeSelected.format('hh:mm A')}</div>
                    <IconDropDown />
                </div>
            </div>
            <div className="v2-dropdown__menu settime-box" id={dropdownId}>
                <div className="flex-betweenitems settime-box-header">
                    <h4 className="black">{finalText}</h4>
                    <div onClick={_handleCloseForm} className="btn-action js-btn-close">
                        <IconClose />
                    </div>
                </div>

                {!finalIsChecked && (
                    <div className="save-time">
                        <div className="time">
                            <div className="time-title">Current Time</div>
                            <div className="time__number">{state.timeCurrent.format('hh:mm A')}</div>
                        </div>
                        <div
                            onClick={() => _saveCurrentTime()}
                            className="v2-btn-main btn-set-time justify-center"
                            tabIndex="0"
                        >
                            Save
                        </div>
                    </div>
                )}

                {finalIsChecked && (
                    <div className="btn-reset-time">
                        <div onClick={(e) => _resetTime(e)} className="v2-btn-default" tabIndex="0">Reset This Time</div>
                    </div>
                )}

                {checkPermission(permissionsList, PERMISSIONS.editCheckTime) && finalIsVisible && (
                    <div className="set-custom-time">
                        <div className="time-title mb-1">Custom time</div>
                        <TimePicker ref={refTimePicker} timeSelected={finalTimeSelected} />
                        <div onClick={_handleChangeTime} className="v2-btn-default btn-set-time" tabIndex="0">
                            Set Custom Time
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CheckInOutJobDetail;
