import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ManageUnitContext } from 'app/modules/jobdetail/contexts/ManageUnitContext';
import AddUnit from './Add';
import ItemUnit from './Item';

export default function ManageUnit({ onUpdate }) {
    const { jobData, updateUnit } = useSelector(({ createJobReducer }) => createJobReducer);
    const onUpdateUnitSuccess = useSelector(({ locationReducer }) => locationReducer.locationData?.onUpdateUnitSuccess);
    const onUpdateUnits = useSelector(({ jobDetailReducer }) => jobDetailReducer?.jobDetail?.onUpdateUnits);
    const { manageUnitContextData, updateManageUnitContextData } = useContext(ManageUnitContext);
    const buildingSelectedId = manageUnitContextData.buildingSelected;
    const unitSelected = manageUnitContextData.unitSelected;

    function _handleSelectUnit(e, unitId, isActive) {
        e && e.stopPropagation();

        if (isActive) {
            return false;
        }

        updateManageUnitContextData({
            unitSelected: unitId
        });
    }

    function _handleDeleteUnit(e, unitId) {
        e && e.stopPropagation();

        let tempBuilding = [...manageUnitContextData.buildingData];
        let tempUnitData = [...manageUnitContextData.unitData];

        tempBuilding = tempBuilding.map((item) => {
            if (item.id === buildingSelectedId) {
                return {
                    ...item,
                    units: item.units.map((itemUnit) => {
                        if (itemUnit.id === unitId) {
                            return {
                                ...itemUnit,
                                deleted: -1
                            };
                        }
                        return itemUnit;
                    })
                };
            }
            return item;
        });

        tempUnitData = tempUnitData.filter((item) => item.unit_id !== unitId);

        onUpdate(tempBuilding, tempUnitData);

        if (onUpdateUnitSuccess) onUpdateUnitSuccess({ unit_id: unitId }, 'delete');
        if (updateUnit && jobData) updateUnit({ unit_id: unitId }, 'delete', jobData.customer_id);
        if (onUpdateUnits) onUpdateUnits({ unit_id: unitId }, 'delete');
        updateManageUnitContextData({
            buildingData: tempBuilding,
            unitData: tempUnitData,
            unitSelected: unitSelected === unitId ? '' : unitSelected
        });
    }

    function _renderListUnit() {
        const buildingSelected =
            manageUnitContextData.buildingData.find((item) => item.id === manageUnitContextData.buildingSelected) || {};
        const finalListUnit = Array.isArray(buildingSelected.units) ? buildingSelected.units : [];

        return finalListUnit.map((item) => {
            const { id: itemId, deleted: flagDeleted } = item;

            const isActive = itemId === unitSelected;

            if (parseFloat(flagDeleted) === -1) {
                return false;
            }

            return (
                <ItemUnit
                    onSelect={_handleSelectUnit}
                    onDelete={_handleDeleteUnit}
                    withoutFetch={manageUnitContextData.withoutFetch}
                    key={itemId}
                    isActive={isActive}
                    itemUnit={item}
                />
            );
        });
    }

    if (!buildingSelectedId) {
        return <div className="wrap-mdu__boxs flex-column" />;
    }

    return (
        <div className="wrap-mdu__boxs flex-column">
            <AddUnit onUpdate={onUpdate} />
            <div className="flex-column__content content scrolls">{_renderListUnit()}</div>
        </div>
    );
}
