const IconRepeatOff = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.4514 5.91024C16.792 6.67003 17.8444 7.85094 18.4455 9.26983C19.0465 10.6887 19.1626 12.2663 18.7756 13.7578C18.3887 15.2494 17.5204 16.5716 16.3055 17.5194C15.0905 18.4672 13.5967 18.9877 12.0558 19C10.5149 19.0123 9.01304 18.5158 7.78307 17.5875C6.55311 16.6593 5.66381 15.3511 5.25311 13.8659C4.84241 12.3807 4.93325 10.8015 5.51155 9.37315C6.08985 7.94485 7.12329 6.74727 8.45158 5.96616"
                stroke="#EE4646"
                strokeWidth="1.3"
                strokeLinecap="round"
            ></path>
            <path d="M12 3V10" stroke="#EE4646" strokeWidth="1.3" strokeLinecap="round"></path>
        </svg>
    );
};
export default IconRepeatOff;
