import ButtonSave from 'app/components/button/ButtonSave';
import { CUSTOMERS, CUSTOMERS_IMPORTED } from 'app/config/routes';
import { CUSTOMER_IMPORT } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import {
    CONTACT_PHONES_EXCEPT_ATTRIBUTE,
    KEY_IMPORTER_LOCAL_STORAGE,
    LIMIT_SELECTED_CONTACT_PHONES_ATTRIBUTE,
    LIMIT_SELECTED_PHONES_ATTRIBUTE,
    PHONES_EXCEPT_ATTRIBUTE
} from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AlertCustomer from '../components/AlertCustomer';
import { generateImportNumberKey } from '../utils';
import ColumnPreviewImport from './components/ColumnPreviewImport';
import GdConfirm from 'app/components/confirm';
import { addBranchPath } from 'app/const/Branch';

const CustomerImport = () => {
    const { t } = useTranslation(['common', 'customers']);
    const refAlert = useRef(null);
    const refButtonImport = useRef(null);
    const history = useHistory();
    const [state, dispatchState] = useReducer(reducer, {
        selectedColumns: [],
        dataFile: [],
        isLimitPhone: false,
        isLimitContactPhone: false
    });
    const { selectedColumns, dataFile } = state;
    const userId = useSelector(({ auth }) => auth.user.profile.id);
    const { state: stateLocation } = useLocation();
    const dataLocalStorage = getLocalStorage(KEY_IMPORTER_LOCAL_STORAGE);
    const refConfirm = useRef(null);

    useEffect(() => {
        if (
            (!!stateLocation && typeof stateLocation === 'object') ||
            (!!dataLocalStorage && typeof dataLocalStorage === 'object')
        ) {
            const { dataFile: dataFileLocation, count: countLocation } = stateLocation || {};
            const { stateColumn: dataFileStorage, count: countStorage } = dataLocalStorage || {};

            if (dataLocalStorage && !dataFileLocation)
                dispatchState({ dataFile: Array.isArray(dataFileStorage) ? dataFileStorage : [], count: countStorage });
            if (dataFileLocation)
                dispatchState({
                    dataFile: Array.isArray(dataFileLocation) ? dataFileLocation : [],
                    count: countLocation
                });
        } else {
            history.goBack();
        }
    }, [stateLocation]);

    const _handleSelectOption = (data) => {
        let newSelectedColumns = [...selectedColumns];
        const indexExisted = newSelectedColumns.findIndex((item) => item.column === data.column);
        if (indexExisted > -1) newSelectedColumns.splice(indexExisted, 1);
        newSelectedColumns = [...newSelectedColumns, data];

        const phonesExcept = newSelectedColumns.filter((item) => PHONES_EXCEPT_ATTRIBUTE.includes(item.field));
        const contactPhonesExcept = newSelectedColumns.filter((item) =>
            CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(item.field)
        );

        dispatchState({
            selectedColumns: newSelectedColumns,
            isLimitPhone: phonesExcept.length >= LIMIT_SELECTED_PHONES_ATTRIBUTE,
            isLimitContactPhone: contactPhonesExcept.length >= LIMIT_SELECTED_CONTACT_PHONES_ATTRIBUTE
        });
    };

    const _handleClearLocalStorage = () => setLocalStorage(KEY_IMPORTER_LOCAL_STORAGE, null);

    const _generateImportSuccess = () => {
        refButtonImport.current?.removeLoading();
        removeLocalStorage(KEY_IMPORTER_LOCAL_STORAGE);
        history.replace({
            pathname: addBranchPath(CUSTOMERS_IMPORTED),
            state: {
                totalImport: state.count || 0
            }
        });
    };

    const _generateImportFail = (response) => {
        refAlert.current?.showStatusBar({
            id: 'can_not_import_customer',
            message: response.message,
            type: LIST_STATUS.ERROR
        });
        refButtonImport.current?.removeLoading();
    };

    const _handleImport = () => {
        const object_key = getLocalStorage(KEY_IMPORTER_LOCAL_STORAGE)?.url?.object_key;
        if (object_key) {
            if (!!selectedColumns.length) {
                clientQuery(
                    CUSTOMER_IMPORT,
                    {
                        data: {
                            object_key,
                            columns_import: selectedColumns,
                            import_number_key: generateImportNumberKey(userId)
                        },
                        method: 'POST',
                        toFormData: false
                    },
                    _generateImportSuccess,
                    _generateImportFail
                );
            } else {
                refConfirm.current.open(null, t('customers:please_review_map_your_column_attributes'));
                refButtonImport.current.removeLoading();
            }
        }
    };

    return (
        <>
            <AlertCustomer ref={refAlert} />
            <div className="title-header">
                <div className="step-import-customer">
                    <div className="step-number">2</div>
                    <div className="step-details">
                        <div className="step-title">{t('customers:step')}</div>
                        <div className="step-description">{t('customers:desc_import_step_2')}</div>
                    </div>
                </div>
                <div className="right-conts btn-close">
                    <Link
                        to={addBranchPath(CUSTOMERS)}
                        className="v2-btn-default --transparent"
                        onClick={_handleClearLocalStorage}
                    >
                        {t('cancel')}
                    </Link>
                    <ButtonSave
                        ref={refButtonImport}
                        title={t('customers:import')}
                        wrapClass="v2-btn-main ml-2"
                        onSave={_handleImport}
                    />
                </div>
            </div>

            <div className="list-import scrolls">
                {dataFile.map((item, index) => {
                    return (
                        <ColumnPreviewImport
                            key={index.toString()}
                            indexColumn={index}
                            optionsSelected={selectedColumns}
                            onSelect={_handleSelectOption}
                            isLimitPhone={state.isLimitPhone}
                            isLimitContactPhone={state.isLimitContactPhone}
                            {...item}
                        />
                    );
                })}
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('customers:confirm')}
                listButton={{ confirm: true, cancel: false }}
                titleConfirm={t('customers:confirm')}
            />
        </>
    );
};

export default CustomerImport;
