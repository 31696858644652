import { DEFAULT_ALL } from 'app/const/App';

export const DEFAULT_SELECT_LOCATION = { id: DEFAULT_ALL, name: 'All Locations' };
export const DEFAULT_SELECT_UNIT = { id: DEFAULT_ALL, name: 'All Units' };

// List pages can display units_filters
export const PAGES_DISPLAY_UNIT_FILTER = ['documents', 'payments', 'invoices', 'jobs'];
export const PAGES_HIDE_LOCATION_FILTER = ['portal', 'account', 'contacts', 'credits'];

export const OPPORTUNITY_ACTIONS = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete'
};
export const KEY_EXPAND_OPPORTUNITY_BLOCK = 'expand_opportunity_block';
export const TYPE_OPEN_MODAL = {
    OPPORTUNITY: 'OPPORTUNITY',
    TASK: 'TASK'
};

// Custom fields
export const CUSTOM_FIELDS_ACTIONS_UPDATE = {
    ADD: 'add',
    UPDATE: 'update',
    DELETE: 'delete'
};
