import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { reducer } from 'app/const/Reducer';
import { SHARING_OPTIONS_SMART_VIEW, SHARING_VALUES_SMART_VIEW } from '../../constants';

const ShareOptions = forwardRef(({ defaultValue, createdBy = '', isGraphqlForm = false, isEditing = false }, ref) => {
    const { t } = useTranslation('smartView');
    const companyName = useSelector(({ auth }) => auth.user.company.name);
    const currentUser = useSelector(({ auth }) => auth.user.profile);
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    const refListUser = useRef(null);
    const refForm = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            let result = {
                share_option: refForm.current['share_option'].value,
                shared_with: refListUser.current?.getSharedUsers() || ''
            };

            // Remove duplicate strings from shared_with array
            const uniqueSharedWith = [...new Set(result.shared_with.split(','))];
            result.shared_with = uniqueSharedWith.join(',');
            if (isGraphqlForm) result = { shareOption: +result.share_option, sharedWith: uniqueSharedWith };
            if (
                !uniqueSharedWith[0] &&
                (+result.share_option === SHARING_VALUES_SMART_VIEW.SPECIFIC_USERS ||
                    +result.shareOption === SHARING_VALUES_SMART_VIEW.SPECIFIC_USERS)
            )
                isGraphqlForm
                    ? (result.shareOption = SHARING_VALUES_SMART_VIEW.PRIVATE)
                    : (result.share_option = SHARING_VALUES_SMART_VIEW.PRIVATE);

            return result;
        }
    }));

    useEffect(() => {
        refListUser.current?.setVisible(+defaultValue?.shareOption === SHARING_VALUES_SMART_VIEW.SPECIFIC_USERS);
    }, [defaultValue]);

    const _handleChange = (event) => {
        refListUser.current?.setVisible(+event.target.value === SHARING_VALUES_SMART_VIEW.SPECIFIC_USERS);
    };

    return (
        <>
            <form ref={refForm} className="row is-shared">
                <p className="txt">{t('sharing_options')}</p>
                <ul className="flex-column">
                    {SHARING_OPTIONS_SMART_VIEW.map((item) => {
                        let createdByName = 'you';
                        const idLabel = item.id + '_label_sharing';
                        const defaultShareOption = defaultValue?.shareOption || null;
                        const isDefaultCheck = defaultShareOption
                            ? defaultShareOption === item.value
                            : item.value === SHARING_VALUES_SMART_VIEW.PRIVATE;
                        if (!!createdBy && currentUser?.id?.toString() !== createdBy)
                            createdByName = users.find((user) => user.id === createdBy)?.full_name;
                        return (
                            <li key={item.id} className="items check-radio">
                                <input
                                    id={idLabel}
                                    type="radio"
                                    value={item.value}
                                    name="share_option"
                                    defaultChecked={isDefaultCheck}
                                    onChange={_handleChange}
                                />
                                <label htmlFor={idLabel}>
                                    {t(item.label, { companyName, created_by: createdByName })}
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </form>
            <UserList
                ref={refListUser}
                defaultChecked={defaultValue?.sharedWith || []}
                isEditing={isEditing}
                createdBy={createdBy}
            />
        </>
    );
});

const UserList = forwardRef(({ defaultChecked = [], isEditing = false, createdBy = '' }, ref) => {
    const { t } = useTranslation('smartView');
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    const currentUser = useSelector(({ auth }) => auth.user.profile);

    const [state, dispatchState] = useReducer(reducer, { isVisible: false });
    const { isVisible } = state;
    const refForm = useRef(null);

    useImperativeHandle(ref, () => ({
        setVisible: (isVisible) => dispatchState((prevState) => ({ ...prevState, isVisible })),
        getSharedUsers: _getSharedUsers
    }));

    const _getSharedUsers = () => {
        const sharedUsers = [];
        const items = refForm.current.querySelectorAll('input[type="checkbox"]');
        items.forEach((user) => {
            if (user.checked) sharedUsers.push(+user.value);
        });
        return sharedUsers.join(',');
    };

    return (
        <form ref={refForm} className={classNames('row is-shared-with', { 'dp-hide': !isVisible })}>
            <p className="txt">{t('share_specific_users_only')}</p>
            <ul className="flex-column">
                {users.map((user) => {
                    let isChecked = false;
                    const userId = user.id;
                    if (userId === currentUser?.id?.toString() && !isEditing) return null;
                    if (userId === createdBy?.toString() && isEditing) return null;
                    if (Array.isArray(defaultChecked)) isChecked = defaultChecked?.some((item) => +item === +userId);
                    return (
                        <li key={userId} className="items check-items">
                            <input id={userId} type="checkbox" value={userId} defaultChecked={isChecked} />
                            <div className="item-checkbox">
                                <label htmlFor={userId}>
                                    <span className="txt-ellipsis">{user.full_name}</span>
                                </label>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </form>
    );
});

export default ShareOptions;
