import React, { useRef } from 'react';

import GDInputNumber from 'app/components/input/InputNumber';
import { OPERATOR_IDS } from '../../constants/types';
import NumbersBetween from './NumbersBetween';

const CountCondition = ({ operatorId, selected, onChange = () => {} }) => {
    const refTimeout = useRef(null);

    const _handleChange = (value) => {
        if (refTimeout.current) clearTimeout(refTimeout.current);
        refTimeout.current = setTimeout(() => {
            onChange(value);
        }, 300);
    };

    if (operatorId === OPERATOR_IDS.SOME || operatorId === OPERATOR_IDS.NONE) return null;
    return operatorId === OPERATOR_IDS.BETWEEN ? (
        <NumbersBetween selectedValue={selected} onChange={_handleChange} />
    ) : (
        <div className="field">
            <GDInputNumber
                className="field-input"
                placeholder="5"
                autoFocus
                defaultValue={selected ?? ''}
                onChange={_handleChange}
            />
        </div>
    );
};

export default CountCondition;
