import EmailTo from 'app/modules/jobdetail/tabs/settingmessaging/email';
import SMSTo from 'app/modules/jobdetail/tabs/settingmessaging/sms';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';

const PreferencesContact = ({ listSMS = [], listEmail = [], selectedEmail = [], selectedSMS = [] }, ref) => {
    const isActiveSMS = useSelector(({ auth }) => auth.user?.settings?.addons?.activate_sms || false);

    const refSmsTo = useRef(null);
    const refMailTo = useRef(null);

    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => {
        const result = { email_to: refMailTo.current.getValue() };
        if (isActiveSMS) result['sms_to'] = refSmsTo.current.getValue();
        return result;
    };

    return (
        <div className="boxs boxs-modified">
            {isActiveSMS && (
                <SMSTo
                    ref={refSmsTo}
                    selected={selectedSMS}
                    data={[{ id: '', phone: 'None' }, ...listSMS]}
                    isFirstTimeFetch={false}
                />
            )}
            <EmailTo
                ref={refMailTo}
                selected={selectedEmail}
                data={[{ id: '', email: 'None' }, ...listEmail]}
                isFirstTimeFetch={false}
            />
        </div>
    );
};

export default forwardRef(PreferencesContact);
