import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import DripEditContent from 'app/modules/drip/components/DripEditContent';
import PreviewSetting from 'app/modules/settings/components/ComponentPreview';
import IconAttachment from 'assets/icon/IconAttachment';
import IconTrash from 'assets/icon/IconTrash';
import IconDocument from 'assets/icon/IconDocument';

import {
    createEmailInbox,
    getDetailEmailInbox,
    updateEmailInbox
} from 'common/redux/actions/settings/emailInboxAction';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_EMAIL_INBOX } from 'app/config/routes';

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const Attachment = ({ attachment, onClickRemoveAttachment }) => {
    const [fileName, setFileName] = useState('');
    /** @type {React.RefObject<HTMLInputElement>} formRef */
    const inputUploadRef = useRef(null);
    const uploadedFile = useRef(false);
    const tickRef = useRef(null);

    useEffect(() => {
        if (!inputUploadRef.current) return;
        const input = inputUploadRef.current;
        if (typeof input.click !== 'function') return;
        input.click();
        let tick;
        const focusEv = () => {
            tick = handleFocusBack();
            tickRef.current = tick;
        };
        window.addEventListener('focus', focusEv);
        return () => {
            window.removeEventListener('focus', focusEv);
            if (typeof tick !== 'undefined') clearTimeout(tick);
        };
    }, []);

    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * */
    const handleOnChangeInputUploadFile = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setFileName(file.name);
        uploadedFile.current = true;
        window.removeEventListener('focus', handleFocusBack);
        if (tickRef.current) {
            clearTimeout(tickRef.current);
        }
    };

    /**
     * wait a little bit to handle event after file loaded event.
     * */
    const handleFocusBack = () => {
        window.removeEventListener('focus', handleFocusBack);
        const tick = setTimeout(() => {
            if (!uploadedFile.current) {
                onClickRemoveAttachment({ preventDefault: () => {} }, attachment);
            }
        }, [300]);
        return tick;
    };

    return (
        <div className="file">
            <div>
                <span className="mr-1">
                    <IconDocument />
                </span>
                {fileName}
            </div>
            <input ref={inputUploadRef} type="file" hidden name={attachment} onChange={handleOnChangeInputUploadFile} />
            <span className="svg-outline-delete" onClick={(e) => onClickRemoveAttachment(e, attachment)}>
                <IconTrash />
            </span>
        </div>
    );
};

const SettingsEmailInboxDetails = () => {
    const { t } = useTranslation(['setting']);
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const refTemplateName = useRef(null);
    const [state, setState] = useState({
        dataEditor: null,
        files: []
    });
    const [previewData, setPreviewData] = useState(null);

    /** @type {React.RefObject<HTMLFormElement>} formRef */
    const formRef = useRef(null);

    useEffect(() => {
        if (id) {
            _handleGetDetailEmailInbox(id);
        }
    }, [id]);

    const _handleGetDetailEmailInbox = (id) => {
        dispatch(getDetailEmailInbox({ id }, getDetailSuccess, () => {}));
    };

    const getDetailSuccess = (response) => {
        const data = response.data;

        refTemplateName.current.value = data.template_name;

        setState((prev) => ({
            ...prev,
            dataEditor: { email: data.email }
        }));
    };

    const dispatchCreateEmailInbox = (params) => {
        dispatch(createEmailInbox(params, _goBackEmailInboxList, () => {}));
    };

    const dispatchUpdateEmailInbox = (params) => {
        dispatch(updateEmailInbox(params, _goBackEmailInboxList, () => {}));
    };

    const _goBackEmailInboxList = () => {
        history.push(addBranchPath(SETTINGS_EMAIL_INBOX));
    };

    /**
     * @param {React.MouseEvent<HTMLDivElement>} e
     * */
    const handleOnClickCancelButton = (e) => {
        e.preventDefault();
        history.push(addBranchPath(SETTINGS_EMAIL_INBOX));
    };

    /**
     * @param {React.MouseEvent<HTMLDivElement>} e
     * */
    const _handleSave = (e) => {
        e.preventDefault();
        if (!formRef.current) return;
        const form = formRef.current;
        const submitEvent = new Event('submit', {
            cancelable: true,
            bubbles: true
        });
        form.dispatchEvent(submitEvent);
    };

    const handleOnClickUploadAttachmentButton = (e) => {
        e.preventDefault();
        const index = state.files.length;
        const files = Array.isArray(state.files) ? state.files : [];
        files[index] = `attachment_#${index}`;
        setState((prev) => ({ ...prev, files: files }));
    };

    const handleOnClickRemoveAttachment = (e, attachment) => {
        e.preventDefault();
        setState((prev) => ({
            ...prev,
            files: prev.files.filter((a) => a !== attachment)
        }));
    };

    const handleOnSubmitForm = async (e) => {
        e.preventDefault();

        const params = {
            template_name: refTemplateName.current.value,
            email: previewData?.email
        };

        const attachments = await Promise.all(
            state.files.map(async (_, index) => {
                const file = await toBase64(e.target[`attachment_#${index}`].files[0]);
                return file;
            })
        );
        params.attachments = attachments;

        if (id) {
            params.id = id;
            dispatchUpdateEmailInbox(params);
            return false;
        }
        dispatchCreateEmailInbox(params);
    };

    const _handleGetData = useCallback((value) => {
        setPreviewData(value);
    }, []);

    const renderAttachments = () => {
        if (!Array.isArray(state.files)) return null;
        return state.files.map((attachment, index) => (
            <Attachment
                key={index.toString()}
                attachment={attachment}
                onClickRemoveAttachment={handleOnClickRemoveAttachment}
            />
        ));
    };

    const renderLeftContent = () => {
        return (
            <div className="container-setting-left">
                <div className="content-header has-input form-newnote">
                    <div className="col">
                        <span className="txt">{t('setting:template_name')}</span>
                        <input ref={refTemplateName} className="field-input" type="text" placeholder="" />
                    </div>
                    <DripEditContent
                        dataReset={state.dataEditor}
                        onGetData={_handleGetData}
                        moduleSetting
                        hideSms
                        hideSwitch
                    />
                </div>
                <div className="attachment-file">
                    <div className="v2-btn-default has-icon" onClick={handleOnClickUploadAttachmentButton}>
                        <IconAttachment />
                        {t('setting:attach_file')}
                    </div>
                    {renderAttachments()}
                </div>
            </div>
        );
    };

    return (
        <div className="contents-pages d-flex system-email-template">
            <div className="container-content-email-sms content-emailinbox">
                <div className="header-title">
                    <h2 className="header-title__label">{t('setting:email_inbox')}</h2>
                    <div className="w-button">
                        <div className="v2-btn-default --noborder --label" onClick={handleOnClickCancelButton}>
                            {t('setting:cancel')}
                        </div>
                        <div className="v2-btn-main" onClick={_handleSave}>
                            <span>{t('setting:save')}</span>
                        </div>
                    </div>
                </div>
                <form
                    ref={formRef}
                    className="d-flex container-setting-email-sms scrolls scrolls-x"
                    onSubmit={handleOnSubmitForm}
                    encType="multipart/form-data"
                >
                    {renderLeftContent()}
                    <PreviewSetting email={previewData?.email} />
                </form>
            </div>
        </div>
    );
};

export default SettingsEmailInboxDetails;
