/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useMemo, useRef } from 'react';
import ServiceItem from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail/ServiceItem';
import TaxDetail from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail/TaxDetail';
import { useSelector } from 'react-redux';
import Delete from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail/Delete';
import ServiceAddForm from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail/ServiceAddForm';
import TaxAddForm from 'app/modules/jobdetail/tabs/addinvoice/form/items/detail/TaxAddForm';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTime from 'assets/icon/IconTime';
import { roundingNumber, onKeyDownOnlyNumber } from 'common/utils/NumberUtils';
import Commission from 'app/modules/jobdetail/tabs/invoice/item/Commission';
import { checkPermission, getPermissionCommission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import AddSoldBy from './AddSoldBy';
import GdRichtext from 'app/components/richtext';
import EstimateDynamic from 'app/modules/jobdetail/tabs/addestimate/form/Dynamic';
import { LIST_ESTIMATE_DYNAMIC_TYPE } from 'app/modules/jobdetail/const/Estimate';

const ServiceDetail = ({
    itemDetail,
    onChange,
    showOnTime,
    onDelete,
    index,
    isInvoice,
    isEditInvoice = false,
    isJobInvoice = false,
    isInvoiceRecurring = false,
    locationTax = {},
    onTriggerImages = () => {},
    isEstimateDynamic = false
}) => {
    const refAddServiceForm = useRef(null);
    const refAddTaxForm = useRef(null);
    const refInputCost = useRef(null);
    const refContent = useRef(null);

    const { permissions, settings } = useSelector(({ auth }) => auth.user);
    const { canDisplaySoldBy, canDisplayCommission, canModify } = getPermissionCommission(
        settings.addons.commission_tracking,
        checkPermission(permissions?.enabled || [], PERMISSIONS.salesCommission)
    );

    const showCommission = canDisplayCommission && !isEditInvoice && !isJobInvoice && isInvoice;
    const showEditSoldBy = !isJobInvoice && isInvoice && !isInvoiceRecurring;

    const { list_taxes } = useSelector((store) => store.taxesReducer);
    const itemCostVaue = itemDetail.cost?.value;
    const { description: finalDescription, id: itemId, item_id: finalItemId, type: estimateType } = itemDetail;
    const { tax1: locationTax1, tax2: locationTax2 } = locationTax;
    const isActiveLocationTax = !!locationTax1 || !!locationTax2;

    useEffect(() => {
        refContent.current && refContent.current.setValue(finalDescription || '');
    }, [finalDescription]);

    useEffect(() => {
        refInputCost.current.value = itemCostVaue;
    }, [finalItemId]);

    const dynamicSelected = useMemo(() => {
        if (estimateType && estimateType) {
            return LIST_ESTIMATE_DYNAMIC_TYPE.find((item) => item.id === estimateType) || {};
        }
        return {};
    }, [isEstimateDynamic, estimateType]);

    function _handleSelectItem(item) {
        const newTax1 =
            list_taxes.find((taxItem) => taxItem.id === (isActiveLocationTax ? locationTax1 : item.tax1)) || null;
        const newTax2 =
            list_taxes.find((taxItem) => taxItem.id === (isActiveLocationTax ? locationTax2 : item.tax2)) || null;

        onChange({
            ...item,
            item_id: item.id,
            old_tax1: !isActiveLocationTax ? newTax1 : list_taxes.find((taxItem) => taxItem.id === item.tax1) || null,
            old_tax2: !isActiveLocationTax ? newTax2 : list_taxes.find((taxItem) => taxItem.id === item.tax1) || null,
            changedTax: isActiveLocationTax,
            tax1: newTax1,
            tax2: newTax2,
            cost: {
                value: item.cost,
                format: item.cost
            },
            id: itemId,
            quantity: 1,
            sold_by_ids: itemDetail.sold_by_ids || [],
            type: itemDetail.type
        });
    }

    function _handleSelectTax(taxSelect, tax1 = false) {
        onChange({
            ...itemDetail,
            tax1: tax1 ? taxSelect : itemDetail.tax1,
            tax2: tax1 ? itemDetail.tax2 : taxSelect
        });
    }

    function _handleChangeTextQuantity(e) {
        onChange({
            ...itemDetail,
            quantity: e.target.value
        });
    }

    function _handleChangeCost(e) {
        document.getElementById(`service_item_cost_${itemId}`).classList.remove('field-error');

        const finalValue = roundingNumber(e.target.value);

        onChange({
            ...itemDetail,
            cost: {
                value: finalValue,
                format: finalValue
            }
        });
    }

    function _handleChangeOntime(e) {
        e.stopPropagation();
        const newDataItem = {
            ...itemDetail,
            one_time: itemDetail.one_time === 1 ? 0 : 1
        };
        onChange(newDataItem);
    }

    function _handleDeleItem() {
        onDelete(itemId);
    }

    function _handleChangeDescription(content) {
        if (!!refContent.current?.getUploadingImages()?.length) {
            return false;
        }
        if (content !== finalDescription) {
            onChange({
                ...itemDetail,
                description: content
            });
        }
    }

    const _handleChangeSoldBy = (sold_by_ids) => {
        onChange({ ...itemDetail, sold_by_ids });
    };

    const _handleOpenAddServiceForm = () => {
        refAddServiceForm.current._handleOpenForm();
    };

    const _handleAddService = (params) => {
        const newTax1 =
            list_taxes.find((taxItem) => taxItem.id === (isActiveLocationTax ? locationTax1 : params.tax1)) || null;
        const newTax2 =
            list_taxes.find((taxItem) => taxItem.id === (isActiveLocationTax ? locationTax2 : params.tax2)) || null;

        onChange({
            ...itemDetail,
            id: itemId,
            item_id: params.id,
            name: params.name,
            description: params.description,
            old_tax1: !isActiveLocationTax ? newTax1 : list_taxes.find((taxItem) => taxItem.id === params.tax1) || null,
            old_tax2: !isActiveLocationTax ? newTax2 : list_taxes.find((taxItem) => taxItem.id === params.tax1) || null,
            changedTax: isActiveLocationTax,
            tax1: newTax1,
            tax2: newTax2,
            quantity: 1,
            cost: {
                value: params.cost,
                format: params.cost
            }
        });
    };

    const _handleOpenAddTax1Form = () => {
        refAddTaxForm.current._handleOpenForm(1);
    };

    const _handleOpenAddTax2Form = () => {
        refAddTaxForm.current._handleOpenForm(2);
    };

    const _handleAddTax = (params) => {
        const tax = {
            id: params.id,
            name: params.name,
            rate: params.rate
        };
        onChange({
            ...itemDetail,
            tax1: params.type === 1 ? tax : itemDetail.tax1,
            tax2: params.type === 2 ? tax : itemDetail.tax2
        });
    };

    const _onKeyDown = (event) => {
        const finalKey = event.key;
        const targetValue = event.target.value;

        if (targetValue === '' && finalKey === '.') {
            event.target.value = '0.';
            event.preventDefault();
        }

        const finalValue =
            onKeyDownOnlyNumber(event) || (finalKey === '.' && !isNaN(`${event.target.value}${finalKey}`));
        !finalValue && event.preventDefault();
    };

    const _onKeyDownCost = (event) => {
        const finalKey = event.key;
        const currentValue = event.target.value;

        if (finalKey === '-' && currentValue !== '') {
            const convertToNegative = parseFloat(currentValue) < 0 ? Math.abs(currentValue) : `-${currentValue}`;
            event.target.value = isNaN(convertToNegative) ? 0 : convertToNegative;
            event.preventDefault();
            _handleChangeCost(event);
        }

        const isKeyAccept =
            onKeyDownOnlyNumber(event) || (finalKey === '-' && event.target.value === '') || finalKey === '.';
        !isKeyAccept && event.preventDefault();
    };

    function _renderOneTime() {
        if (!isInvoice) {
            return false;
        }
        if (showOnTime) {
            const isOneTime = !!itemDetail.one_time;
            return (
                <div className="col col-onetime tooltip" onClick={(e) => _handleChangeOntime(e)}>
                    <div className={`js-active-onetime ${isOneTime ? 'svg-note-orange' : ''}`}>
                        <IconTime />
                        {isOneTime && <p className="tooltiptext top">This item is set for 1 Time use</p>}
                    </div>
                </div>
            );
        }
        return <div className="col col-onetime" />;
    }

    const finalPrice = roundingNumber(parseFloat(itemDetail.quantity) * parseFloat(itemCostVaue || 0));

    const _triggerUploadImages = (data) => {
        const shouldUpdate = !!data.length;

        onTriggerImages(itemId, shouldUpdate);

        if (!shouldUpdate) {
            const content = refContent.current.getValue();
            if (content !== finalDescription) {
                onChange({
                    ...itemDetail,
                    description: content
                });
            }
        }
    };

    const _handleSelectDynamic = (newType) => {
        onChange({
            ...itemDetail,
            type: newType
        });
    };

    return (
        <Fragment>
            <div className="content-elm-edit rows tabs-items-body" style={{ '--bg-estimate': dynamicSelected.color }}>
                <div className="items-action-btn">
                    <div className="button drag-items">
                        <IconThreeDots isDouble />
                    </div>
                    {index > 0 && <Delete onDelete={_handleDeleItem} />}
                </div>
                <div className="items-service tb-content">
                    <ServiceItem
                        onSelect={_handleSelectItem}
                        itemId={itemId}
                        finalItemId={finalItemId}
                        itemName={itemDetail.name}
                        handleOpenForm={_handleOpenAddServiceForm}
                    />
                    <div className="col col-cost">
                        <input
                            id={`service_item_cost_${itemId}`}
                            className="field-input"
                            type="text"
                            ref={refInputCost}
                            defaultValue={itemCostVaue}
                            onBlur={_handleChangeCost}
                            onKeyDown={_onKeyDownCost}
                        />
                    </div>
                    <TaxDetail
                        taxDetail={itemDetail.tax1}
                        classParent={'first-field'}
                        onSelect={(tax) => _handleSelectTax(tax, true)}
                        handleOpenForm={_handleOpenAddTax1Form}
                    />
                    <TaxDetail
                        taxDetail={itemDetail.tax2}
                        classParent={'second-field'}
                        onSelect={(tax) => _handleSelectTax(tax, false)}
                        handleOpenForm={_handleOpenAddTax2Form}
                    />
                    <div className="col col-qty">
                        <input
                            type="text"
                            onKeyDown={_onKeyDown}
                            className="field-input"
                            value={itemDetail.quantity}
                            onChange={_handleChangeTextQuantity}
                        />
                    </div>
                    <div className="col col-price fw-500">
                        <span>{isNaN(finalPrice) ? '0.00' : finalPrice}</span>
                    </div>
                    {_renderOneTime()}
                </div>

                <div className="item-note boxs dashboard-wrapper">
                    <GdRichtext
                        ref={refContent}
                        defaultContent={finalDescription}
                        wrapperClass={'field-input p-0'}
                        isShowAction={false}
                        onBlur={_handleChangeDescription}
                        onTriggerUploadImage={_triggerUploadImages}
                        isLink={false}
                        placeholder="Add a description"
                    />
                </div>
                {isEstimateDynamic && <EstimateDynamic onSelect={_handleSelectDynamic} parentType={itemDetail.type} />}
                {showCommission && (
                    <Commission commission={itemDetail.commission} canDisplaySoldBy={canDisplaySoldBy} />
                )}

                {showEditSoldBy && canModify && (
                    <AddSoldBy
                        defaultSelected={itemDetail.commission?.sales?.map((sale) => sale.user) || []}
                        onChange={_handleChangeSoldBy}
                    />
                )}
            </div>
            <ServiceAddForm ref={refAddServiceForm} handleSubmit={_handleAddService} />
            <TaxAddForm ref={refAddTaxForm} handleSubmit={_handleAddTax} />
        </Fragment>
    );
};

export default ServiceDetail;
