import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import GdGridView from 'app/components/grid/GdGridView';
import { ADDONS_OPPORTUNITY } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import ErrorPage from 'app/modules/error';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import IconArrow from 'assets/icon/IconArrow';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { getGridColumns } from './const/PermissionTable';
import { getLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_OPPORTUNITIES_PERMISSIONS, actionUpdatePermissionPipelines } from 'app/const/api/V2';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import { checkAccessManageFail } from 'common/utils/AddonUtils';

const AddonsOpportunityPermissions = () => {
    const history = useHistory();
    const { t } = useTranslation('addons');
    const refTimer = useRef({});

    const { profile: userProfile, settings } = useSelector(({ auth }) => auth.user);
    const isActivated = settings.addons.opportunity;
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);

    const reportType = REPORT_TYPE.OPPORTUNITIES_PERMISSIONS;
    const keyOppsPermissionsLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    getLocalParamsReport(keyOppsPermissionsLocal, reportType);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        totalPage: 1
    });

    const { isLoading, data: finalData, totalPage } = state;

    useEffect(() => {
        _fetchDataPermissions();

        return () => {
            removeLocalStorage(keyOppsPermissionsLocal);
        };
    }, []);

    const _fetchDataPermissions = () => {
        const params = getLocalStorage(keyOppsPermissionsLocal);

        const _handleSuccess = ({ data, total }) => {
            dispatchState((prev) => ({
                ...prev,
                data: data.map((item) => ({ ...item, id: item.user.id })),
                total,
                isLoading: false,
                totalPage: Math.ceil(total / params.limit)
            }));
        };

        clientQuery(ADDONS_OPPORTUNITIES_PERMISSIONS, { data: { ...params }, method: 'GET' }, _handleSuccess, () =>
            checkAccessManageFail(history)
        );
    };

    const _handleChangeOption = ({ id, value, key: keyPermission }) => {
        clearTimeout(refTimer.current[id]);
        let newPermission = [];

        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => {
                if (item.user.id === id) {
                    const permission = item.permission;
                    newPermission = value
                        ? [...permission, keyPermission]
                        : permission.filter((key) => key !== keyPermission);

                    return { ...item, permission: newPermission };
                }
                return item;
            })
        }));

        refTimer.current[id] = setTimeout(() => {
            clientQuery(actionUpdatePermissionPipelines(id), {
                method: 'PUT',
                toFormData: false,
                data: { permissions: newPermission }
            });
        }, 300);
    };

    const _handleUpdate = () => {
        dispatchState({ isLoading: true });
        _fetchDataPermissions();
    };

    if ((!isGrowthPlan && !isTrial) || !isActivated)
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADDONS_OPPORTUNITY)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('back')}
                </Link>
            </div>
            <div className="header mt-0">
                <h3 className="black">{t('opportunities_permissions')}</h3>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline container-print">
                    <div className="wrap-tables flex-column relative">
                        <GdGridView
                            isEmptyFlat
                            isLoading={isLoading}
                            content={finalData}
                            classTable="tables table-multi-column"
                            fileTranslation={'report'}
                            {...getGridColumns()}
                            isScroll
                            onChangeSwitchOppPermission={_handleChangeOption}
                        />

                        <ReportPagination
                            reportType={REPORT_TYPE.OPPORTUNITIES_PERMISSIONS}
                            totalPage={totalPage || 1}
                            onSelect={_handleUpdate}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddonsOpportunityPermissions;
