export const TYPE_SETTING_TAX = {
  GET_LIST: "SETTING_GET_LIST_TAXES",
  CREATE: "SETTING_CREATE_TAX",
  UPDATE: "SETTING_UPDATE_TAX",
  UPDATE_STATUS: "SETTING_UPDATE_STATUS_TAXES"
}

// actions
export const getListSettingTaxes = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_TAX.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}


export const createTax = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_TAX.CREATE,
    params,
    actionSuccess,
    actionFailed
  }
}


export const updateTax = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_TAX.UPDATE,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateStatusSettingTaxes = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_TAX.UPDATE_STATUS,
    params,
    actionSuccess,
    actionFailed
  }
}