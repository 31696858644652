import { useSelector } from 'react-redux';
import { settingGroupSchedule } from 'app/const/api/V2';
import { KEY_CODE_ENTER, KEY_CODE_ESCAPE, KEY_CODE_SPACE } from 'app/const/Keyboard';
import { SettingScheduleContext } from 'app/modules/settings/context/SettingScheduleContext';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useCallback, useContext, useEffect, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { Manager, Popper, Reference } from 'react-popper';
import { changeStyleRowsDropdown } from 'common/utils/GridViewUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddGroupSchedule } from 'app/modules/mixpanel/MixpanelAddGroupSchedule';

const DropdownGroupSchedule = ({ id, groups = [], onHandleActionGroups = () => {}, idRow }) => {
    const refDropdown = useRef(null);
    const refDropMenu = useRef(null);
    const refInput = useRef(null);
    const refVisible = useRef(false);
    const refInstance = useRef({});
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const { scheduleGroups, handleAddNewTag, handleSelectTag } = useContext(SettingScheduleContext);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: refVisible.current,
        selectedTags: groups || [],
        options: scheduleGroups || []
    });
    const { isVisible, options, selectedTags } = state;
    const refSearch = useRef(options);

    useEffect(() => {
        dispatchState({ options: scheduleGroups || [] });
        refSearch.current = scheduleGroups || [];
    }, [scheduleGroups]);

    useEffect(() => {
        handleSelectTag(selectedTags);
        dispatchState({ selectedTags: groups || [] });
    }, []);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
            refInput.current.focus();
            _handleOpenDropMenu();
            changeStyleRowsDropdown(idRow, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
            changeStyleRowsDropdown(idRow, false);
        }
        refVisible.current = isVisible;
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function handleClickOutside(event) {
        if (refDropdown.current && !refDropdown.current.contains(event.target)) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        isVisible && dispatchState({ isVisible: false, options: refSearch.current });
        if (refDropMenu.current) refDropMenu.current.style.display = 'none';
    }

    function _openDropdown() {
        !isVisible && dispatchState({ isVisible: true });
    }

    const _handleInputChange = (event) => {
        _handleOpenDropMenu();

        /* This is to prevent the spacebar from triggering the `onClick` event on the `<li>` element. */
        if (event.keyCode === KEY_CODE_SPACE) return;
        const valueSearch = event.target.value;
        let result = [];

        if (valueSearch.length) {
            refSearch.current.forEach((item) => {
                if (item.toLocaleLowerCase().search(valueSearch.toLocaleLowerCase()) > -1) {
                    result.push(item);
                }
            });
        } else {
            result = refSearch.current;
        }

        dispatchState({ options: result });
    };

    const _handleKeyDown = (event) => {
        if (event.keyCode === KEY_CODE_ENTER || event.keyCode === KEY_CODE_SPACE) {
            const value = event.target.value;
            if (!value.trim().length) return;

            /* This is to prevent the value is select before, then the value will be add to list selected. */
            if (!selectedTags.includes(value)) {
                const newOptions = [...refSearch.current, value];
                dispatchState({
                    selectedTags: [...selectedTags, refInput.current.value],
                    options: newOptions
                });
                refSearch.current = newOptions;
                refInput.current.value = '';
                _handleCloseDropMenu();
                _handleUpdateTag(value);
            } else {
                _handleNotifyTagAdded(value);
            }

            // If value is not exist, then add new tag to context
            if (!options.includes(value)) {
                handleAddNewTag(value);
            }
            event.preventDefault();
            onHandleActionGroups(id, value);
        }
    };

    const _handleSelectTag = (item) => {
        const isExistItem = selectedTags.includes(item);
        if (isExistItem) {
            _handleNotifyTagAdded(item);
        } else {
            dispatchState({ selectedTags: [...selectedTags, item] });
            _handleUpdateTag(item);
            refInput.current.value = '';
        }
        refInput.current.focus();

        if (!isExistItem) {
            _handleCloseDropMenu();
            onHandleActionGroups(id, item);
        }
    };

    const _handleDeleteTag = (itemDelete) => {
        dispatchState({ selectedTags: selectedTags.filter((item) => item !== itemDelete) });

        _handleUpdateTag(itemDelete, true);
        /**
         *  remove tag when don't have any schedule using this tag
         */
        // if (handleGetSelectedTags().filter((item) => item === itemDelete).length <= 1) {
        //     handleDeleteTag(itemDelete);
        // }
        onHandleActionGroups(id, itemDelete, true);
        _handleUpdatePopper();
    };

    const _handleCloseDropMenu = () => {
        refDropMenu.current.style.display = 'none';
    };

    const _handleOpenDropMenu = () => {
        refDropMenu.current.style.display = 'block';
        _handleUpdatePopper();
    };

    /* This is a function to notify when a tag is added. */
    const _handleNotifyTagAdded = (tag) => {
        const divExist = document.getElementById(`${id}-${tag}`);
        if (divExist && !divExist.classList.contains('is-available')) divExist.classList.add('is-available');
        setTimeout(() => {
            divExist.classList.remove('is-available');
        }, 300);
    };

    // It updates the tag name in the database.
    const _handleUpdateTag = (name, isDelete = false) => {
        const _handleSuccess = ({ success }) => {
            if (isDelete || !success) return;
            handleTrackingEvent(mixpanelAddGroupSchedule({ id: profileId }));
        };

        clientQuery(settingGroupSchedule(id), { data: { name }, method: isDelete ? 'DELETE' : 'PUT' }, _handleSuccess);
    };

    const renderListSelect = () => {
        return options.map((item) => {
            return (
                <li
                    key={item}
                    className={classNames('items', { active: selectedTags.includes(item) })}
                    onClick={() => _handleSelectTag(item)}
                >
                    <div className="txt-ellipsis">{item}</div>
                </li>
            );
        });
    };

    const setListRef = useCallback((node, ref) => {
        refDropMenu.current = node;
        return ref(node);
    }, []);

    const _handleUpdatePopper = () => {
        refInstance.current?.update();
    };

    return (
        <Manager>
            <div ref={refDropdown} id={id} className={classNames('tags-group v2-dropdown', { active: isVisible })}>
                {!isVisible && !selectedTags.length && (
                    <div className="btn-add-tags" onClick={_openDropdown}>
                        Add +
                    </div>
                )}
                {!isVisible && !!selectedTags.length && (
                    <div className="tags-group__wrap" onClick={_openDropdown}>
                        <ListTagSelected data={selectedTags} />
                    </div>
                )}
                {isVisible && (
                    <div className="container-column">
                        <Reference>
                            {({ ref }) => (
                                <div className="tag-list" ref={ref}>
                                    <div className="scrolls">
                                        <ListTagSelected
                                            data={selectedTags}
                                            idSchedule={id}
                                            isInInput
                                            onDelete={_handleDeleteTag}
                                        />
                                        <input
                                            ref={refInput}
                                            className="add-tags flex-1"
                                            type="input"
                                            placeholder="+ Tag"
                                            onClick={_handleOpenDropMenu}
                                            onChange={_handleInputChange}
                                            onFocus={_handleOpenDropMenu}
                                            onKeyDown={_handleKeyDown}
                                            spellCheck
                                        />
                                    </div>
                                </div>
                            )}
                        </Reference>
                        <Popper
                            placement={'bottom-end'}
                            strategy={'absolute'}
                            modifiers={[{ name: 'offset', options: { offset: [0, 5] } }]}
                        >
                            {({ style, placement, ref, update }) => {
                                refInstance.current.update = update;
                                return (
                                    <div
                                        style={style}
                                        data-placement={placement}
                                        ref={(node) => setListRef(node, ref)}
                                        className="v2-dropdown__menu scrolls"
                                    >
                                        {!!options.length && <ul>{renderListSelect()}</ul>}
                                    </div>
                                );
                            }}
                        </Popper>
                    </div>
                )}
            </div>
        </Manager>
    );
};

const ListTagSelected = ({ data = [], idSchedule, isInInput = false, onDelete = () => {} }) => {
    return data.map((item) =>
        isInInput ? (
            <div key={item} id={`${idSchedule}-${item}`} className="tag-items">
                {item}
                <span className="blue-close" onClick={() => onDelete(item)}>
                    <IconClose isSmall />
                </span>
            </div>
        ) : (
            <span key={item} className="btn-tags tag-items cursor-pointer">
                {item}
            </span>
        )
    );
};

export default DropdownGroupSchedule;
