import React from 'react';
import IconArrowDown from 'assets/icon/IconArrowDown';

export default function ConversationTimeLine({ date }) {
    return (
        <div className="time-line">
            <div className="datetime">
                {date}
                <IconArrowDown />
            </div>
        </div>
    );
}
