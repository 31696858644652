import React, { useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { ADDONS_QUICKBOOKS_SYNC } from 'app/config/routes';
import IconArrow from 'assets/icon/IconArrow';
import Loading from './components/Loading';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_QUICKBOOKS_LOG } from 'app/const/api/V2';
import { TABS_QUICKBOOKS_LOG } from 'app/const/addons/Quickbooks';
import TabWaitingSync from './tabs/Wating';
import TabHistorySync from './tabs/History';
import TabConsoleSync from './tabs/Console';
import { reducer } from 'app/const/Reducer';
import ServiceRealTimeQuickBook from './Service';
import { useSelector } from 'react-redux';
import { addBranchPath } from 'app/const/Branch';

export default function AddonQuickbooksLog() {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false,
        tab: TABS_QUICKBOOKS_LOG.CONSOLE,
        console: [],
        history: {},
        progress: [],
        waiting: []
    });

    const { tab: finalTab, isLoading, progress: finalProgress, waiting, history, console: finalConsole } = state;

    const userProfile = useSelector(({ auth }) => auth.user.profile);

    useEffect(() => {
        _getQuicbookSyncLog();
    }, []);

    function _getQuicbookSyncLog() {
        clientQuery(GET_QUICKBOOKS_LOG, { method: 'GET' }, _getQuicbookSyncLogSuccess);
    }

    function _getQuicbookSyncLogSuccess(response) {
        dispatchState({
            isLoading: false,
            ...response.data
        });
    }

    function _handleChangeTab(e, selectedTab) {
        e.stopPropagation();
        dispatchState({
            tab: selectedTab
        });
    }

    function _handleAddNewProgress(newData) {
        const dataInsert = { ...newData.data };

        const finalData = {
            ...dataInsert,
            name: userProfile.first_name
        };

        dispatchState((prev) => {
            let find = false;
            const preProgress = [...prev.progress];

            const findProgress = preProgress.map((item) => {
                if (item.ticket === dataInsert.ticket) {
                    find = true;
                    return {
                        ...item,
                        status: dataInsert.status || '',
                        success: dataInsert.success ?? ''
                    };
                }
                return item;
            });

            return {
                ...prev,
                progress: !find ? [finalData, ...preProgress] : findProgress,
                console: [...prev.console, { entity_id: finalData.id, log: finalData.message }],
                tab: TABS_QUICKBOOKS_LOG.CONSOLE
            };
        });
    }

    function _renderTabDetail() {
        switch (finalTab) {
            case TABS_QUICKBOOKS_LOG.WAITING:
                return <TabWaitingSync data={waiting} />;
            case TABS_QUICKBOOKS_LOG.HISTORY:
                return <TabHistorySync data={history} />;
            case TABS_QUICKBOOKS_LOG.CONSOLE:
                return <TabConsoleSync data={finalConsole} />;
            default:
                return false;
        }
    }

    function _renderProgess() {
        return finalProgress.map((item, index) => {
            return (
                <div key={index.toString()} className="rows">
                    <div className="rows__records">
                        {`${item.success}/${item.total}`} {item.type} records batched by
                    </div>
                    <div className="rows__user">{item.name}</div>
                    <div className={`status-btn --sm ${item.status}`}>{item.status}</div>
                </div>
            );
        });
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_QUICKBOOKS_SYNC)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        QuickBooks Sync
                    </Link>
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper">
                        <div className="accessible-tabs-container tabs-sync">
                            <div className="tabs">
                                <div role="tablist" className="tab-selectors js-tab-selectors btn-item bg-white">
                                    <button
                                        onClick={(e) => _handleChangeTab(e, TABS_QUICKBOOKS_LOG.WAITING)}
                                        className={`tab-items ${
                                            finalTab === TABS_QUICKBOOKS_LOG.WAITING ? 'active-tab-selector' : ''
                                        }`}
                                    >
                                        Waiting to Sync
                                    </button>
                                    <button
                                        onClick={(e) => _handleChangeTab(e, TABS_QUICKBOOKS_LOG.HISTORY)}
                                        className={`tab-items ${
                                            finalTab === TABS_QUICKBOOKS_LOG.HISTORY ? 'active-tab-selector' : ''
                                        }`}
                                    >
                                        Sync History
                                    </button>
                                    <button
                                        onClick={(e) => _handleChangeTab(e, TABS_QUICKBOOKS_LOG.CONSOLE)}
                                        className={`tab-items ${
                                            finalTab === TABS_QUICKBOOKS_LOG.CONSOLE ? 'active-tab-selector' : ''
                                        }`}
                                    >
                                        Sync Console
                                    </button>
                                </div>
                            </div>
                            <div className="tab-contents mt-5">
                                {_renderTabDetail()}
                                {_renderProgess()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ServiceRealTimeQuickBook onAdd={_handleAddNewProgress} />
        </div>
    );
}
