import { useContext, useEffect } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import { useSelector } from 'react-redux';

export default function RealTimeSMS({ onUpdateTotalSms, customerId, currentTotal = 0 }) {
    const { smsReceiver: smsReceiverData, smsConversation: smsConversationData } = useContext(SocketContext);
    const user_id = useSelector(({ auth }) => auth.user?.profile?.id);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);

    useEffect(() => {
        _setSocketId();
    }, []);

    useEffect(() => {
        if (smsReceiverData) {
            if (
                smsReceiverData?.customer?.customer_id?.toString() === customerId?.toString() &&
                (!checkPermission(permissionsList, PERMISSIONS.smsFilterByUser) ||
                    smsReceiverData.unAssignSmsUsers?.includes(user_id))
            ) {
                onUpdateTotalSms(parseInt(currentTotal) + 1, smsReceiverData || null, smsConversationData || null);
            }
        }
    }, [smsReceiverData]);

    useEffect(() => {
        if (smsConversationData) {
            const conversations = smsConversationData.conversations || [];
            const currentConversation = conversations.find(
                (item) => item?.customer_id?.toString() === customerId?.toString()
            );

            const numberMessage = currentConversation?.message_ids?.length || 0;

            if (currentConversation) {
                onUpdateTotalSms(
                    parseInt(currentTotal) +
                        (parseInt(smsConversationData.check) ? parseInt(numberMessage) : -parseInt(numberMessage)),
                    smsReceiverData || null,
                    smsConversationData || null
                );
            }
        }
    }, [smsConversationData]);

    const _setSocketId = () => {
        // eslint-disable-next-line no-undef
        global.conversationJobSocketId = `${Date.now()}_sms_job`;
    };

    return null;
}
