import React, { useEffect, useContext } from 'react';
import { convertWsSendToSms } from '../utils';
import { SocketContext } from 'app/services/socket/SocketProvider';

const RealTimeConverDetail = ({ customerId = '', onAddMessage, customer, finalSocketId = '' }) => {
    const { sendMessage: responseSocketSend } = useContext(SocketContext);

    useEffect(() => {
        if (responseSocketSend) {
            if (
                (responseSocketSend?.customer?.customer_id?.toString() === customerId.toString() ||
                    responseSocketSend?.sms_data?.filter?.receiver_phones?.includes(customer.filter_phone)) &&
                responseSocketSend?.socket_id !== finalSocketId
            ) {
                onAddMessage(convertWsSendToSms(responseSocketSend));
            }
        }
    }, [responseSocketSend]);

    return null;
};

export default React.memo(RealTimeConverDetail);
