export const TYPE_CUSTOMER_TAG = {
	GET_LIST: "GET_LIST_CUSTOMER_TAG"
};

export const getListCustomerTag = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_CUSTOMER_TAG.GET_LIST,
		params,
		actionSuccess,
		actionFailed,
	};
};
