import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

//GirdView Content MONTH_TAB

export const getGidColumnsMonthTab = (actionsStatus) => {
    const HEADER_MONTH_TAB = {
        account_number: {
            title: 'account_number_#',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        jan: {
            title: 'jan',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        feb: {
            title: 'feb',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        mar: {
            title: 'mar',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        apr: {
            title: 'apr',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        may: {
            title: 'may',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        jun: {
            title: 'jun',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        jul: {
            title: 'jul',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        aug: {
            title: 'aug',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        sep: {
            title: 'sep',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        oct: {
            title: 'oct',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        nov: {
            title: 'nov',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        dec: {
            title: 'dec',
            style: 'col col-sm --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        total: {
            title: 'total',
            style: 'col col-total --label-r',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_MONTH_TAB = [
        {
            id: 'account_number',
            status: true
        },
        {
            id: 'customer',
            status: true
        },
        {
            id: 'jan',
            status: true
        },
        {
            id: 'feb',
            status: true
        },
        {
            id: 'mar',
            status: true
        },
        {
            id: 'apr',
            status: true
        },
        {
            id: 'may',
            status: true
        },
        {
            id: 'jun',
            status: true
        },
        {
            id: 'jul',
            status: true
        },
        {
            id: 'aug',
            status: true
        },
        {
            id: 'sep',
            status: true
        },
        {
            id: 'oct',
            status: true
        },
        {
            id: 'nov',
            status: true
        },
        {
            id: 'dec',
            status: true
        },
        {
            id: 'total',
            status: true
        }
    ];

    const REPORT_CONTENT_MONTH_TAB = {
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        customer: {
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        jan: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        feb: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        mar: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        apr: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        may: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        jun: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        jul: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        aug: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        sep: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        oct: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        nov: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        dec: {
            style: 'col col-sm --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        },
        total: {
            style: 'col col-total --label-r',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isLink: true
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_MONTH_TAB }),
        columns: REPORT_COLUMNS_MONTH_TAB,
        contentConfig: REPORT_CONTENT_MONTH_TAB
    };
};

//GirdView Content DETAIL_TAB

export const getGidColumnsDetailTab = (actionsStatus) => {
    const HEADER_DETAIL_TAB = {
        account_number: {
            title: 'account_number_#',
            style: 'col'
        },
        invoice_number: {
            title: 'payment_for',
            style: 'col'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl'
        },
        method: {
            title: 'method',
            style: 'col col-sm-plus'
        },
        service_state: {
            title: 'service_state',
            style: 'col col-sm'
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        amount: {
            title: 'amount',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        w_o_tax: {
            title: 'w_o_tax',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const REPORT_COLUMNS_DETAIL_TAB = [
        {
            id: 'account_number',
            status: true
        },
        {
            id: 'invoice_number',
            status: true
        },
        {
            id: 'customer',
            status: true
        },
        {
            id: 'method',
            status: true
        },
        {
            id: 'service_state',
            status: true
        },
        {
            id: 'date',
            status: true
        },
        {
            id: 'amount',
            status: true
        },
        {
            id: 'w_o_tax',
            status: true
        }
    ];
    const REPORT_CONTENT_DETAIL_TAB = {
        account_number: {
            id: 'account_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            isAccountNumber: true
        },
        invoice_number: {
            style: 'col',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.PAYMENT_FOR
        },
        customer: {
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        method: {
            style: 'col col-sm-plus'
        },
        service_state: {
            style: 'col col-sm'
        },
        date: {
            style: 'col col-md'
        },
        amount: {
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        w_o_tax: {
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_DETAIL_TAB }),
        columns: REPORT_COLUMNS_DETAIL_TAB,
        contentConfig: REPORT_CONTENT_DETAIL_TAB
    };
};
