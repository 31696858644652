import { React } from 'react';
import { useTranslation } from 'react-i18next';

const LocationHeader = ({ onAddLocation = () => {} }) => {
    const { t } = useTranslation(['customers']);

    return (
        <div className="v2-btn-main btn-modal --bg-green" onClick={onAddLocation}>
            {t('customers:new_location')}
        </div>
    );
};

export default LocationHeader;
