import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_SETTINGS_TEMPLATES_OPTIONS } from 'app/const/api/V2';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const SelectTemplate = ({ id, defaultSelect = {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { selected: defaultSelect, options: [], isFetched: false });
    const { isFetched, selected, options } = state;
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ _getValue: () => selected.id }));

    const _handleSelect = (id) => {
        dispatchState({ selected: options.find((option) => option.id === id) });
    };

    const _handleVisible = () => {
        if (!isFetched) {
            const _handleFetchSuccess = ({ data }) => {
                dispatchState({ options: data, isFetched: true });
            };
            clientQuery(GET_SETTINGS_TEMPLATES_OPTIONS, { data: { type: id }, method: 'GET' }).then(
                _handleFetchSuccess
            );
        }
    };

    return (
        <CalendarDropdown
            id={id}
            ref={refDropdown}
            isLoading={!isFetched}
            options={options}
            selected={selected.name}
            selectedOption={selected}
            keyGetValue="id"
            keyGetKey="name"
            customDropIcon={<IconDropDown />}
            onVisible={_handleVisible}
            onSelect={_handleSelect}
        />
    );
};

SelectTemplate.displayName = 'SelectTemplate';
export default forwardRef(SelectTemplate);
