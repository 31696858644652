import { PERMISSIONS } from 'app/const/Permissions';
import IconCalendar from 'assets/icon/IconCalendar';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import IconTime from 'assets/icon/IconTime';
import { checkPermission } from 'common/utils/PermissionUtils';
import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ID_POPPER_ADD_JOB } from '../const';
import { useLocation } from 'react-router-dom';

const TooltipAddJob = ({ onCreateTimeoff = () => {}, onCreateJob = () => {}, onClearData = () => {} }) => {
    const { pathname } = useLocation();
    const permissionsDisabled = useSelector(({ auth }) => auth.user.permissions.disabled || []);
    const refTooltip = useRef(null);
    const refBackground = useRef(null);

    useEffect(() => {
        _handleClose();

        return () => {
            _handleClose();
        };
    }, [pathname]);

    const _handleClose = () => {
        const divPopper = document.getElementById(ID_POPPER_ADD_JOB);
        if (refTooltip.current) refTooltip.current.style.display = 'none';
        if (refBackground.current) refBackground.current.style.display = 'none';
        if (divPopper) divPopper.remove();

        onClearData();
    };

    const _handleSelect = (type) => {
        switch (type) {
            case 'job':
                onCreateJob();
                break;
            case 'timeoff':
            case 'event':
                onCreateTimeoff(type);
                break;
        }

        _handleClose();
    };

    return (
        <Fragment>
            <div
                ref={refTooltip}
                id="tooltip-add-job"
                style={{ display: 'none', zIndex: 100008 }}
                className="wrap-addjob-menu"
            >
                <div className="wrap-addjob-menu__items" onClick={() => _handleSelect('job')}>
                    <IconServiceTemplate isHasColor />
                    <span className="txt-ellipsis">New Job</span>
                </div>
                {!checkPermission(permissionsDisabled, PERMISSIONS.addTimeOff) && (
                    <div className="wrap-addjob-menu__items" onClick={() => _handleSelect('timeoff')}>
                        <IconTime isHasColor />
                        <span className="txt-ellipsis">Add Time Off</span>
                    </div>
                )}
                {!checkPermission(permissionsDisabled, PERMISSIONS.addEvent) && (
                    <div className="wrap-addjob-menu__items" onClick={() => _handleSelect('event')}>
                        <IconCalendar isAddEvent />
                        <span className="txt-ellipsis">Add Custom Event</span>
                    </div>
                )}
            </div>
            <div
                id="tooltip-add-job-bg"
                ref={refBackground}
                style={{ zIndex: 100007, display: 'none', background: 'transparent' }}
                className="modal__overlay bg-fixed"
                onClick={_handleClose}
            />
        </Fragment>
    );
};

export default TooltipAddJob;
