import React, { useReducer, useEffect, useRef } from 'react';
import { DEFAULT_ALL, KEY_CODE_ESCAPE } from 'app/const/App';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCheck from 'assets/icon/IconCheck';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import ItemJobStatus from 'app/components/job/ItemJobStatus';
import useJobStatus from 'common/hooks/useJobStatus';
import { JOB_EXCLUDE } from 'app/const/Job';

const FilterJobStatus = ({ onSelect = () => {} }) => {
    const { t } = useTranslation(['calendar, report']);
    const listJobStatus = useJobStatus({ filter: JOB_EXCLUDE.WORKPOOL, isIncludeDeleted: true });

    const [state, setState] = useReducer(reducer, {
        selected: DEFAULT_ALL,
        isVisible: false
    });

    const refSearch = useRef(null);

    const { isVisible: finalIsVisible, selected: finalSelected } = state;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('wookppol_job_status');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeListOptions();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('wookppol_job_status');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeListOptions();
        }
    }

    function _closeListOptions() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    }

    function _handleSelect(e, value) {
        e.stopPropagation();

        let newSelected = finalSelected === DEFAULT_ALL ? listJobStatus.map((i) => i.id) : [...finalSelected];

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((item) => item !== value);
        } else {
            newSelected.push(value);
        }

        if (newSelected.length === listJobStatus.length) {
            newSelected = DEFAULT_ALL;
        }

        setState({ selected: newSelected });
        onSelect(newSelected);
    }

    function _handleSelectAll(e) {
        e.stopPropagation();

        const value = DEFAULT_ALL === finalSelected ? [] : DEFAULT_ALL;

        setState({ selected: value });
        onSelect(value);
    }

    function _renderStatus() {
        return listJobStatus.map((item) => {
            const statusId = item.id;
            const classActive = finalSelected === DEFAULT_ALL || finalSelected.includes(statusId) ? 'active' : '';
            return (
                <li
                    key={statusId}
                    className={`items has-icon ${classActive}`}
                    onClick={(e) => {
                        _handleSelect(e, statusId);
                    }}
                >
                    <IconCheck />
                    <ItemJobStatus defaultStatus={item} />
                </li>
            );
        });
    }

    function _renderSelectAll() {
        const classActive = DEFAULT_ALL === finalSelected ? 'active' : '';

        return (
            <div className="content-checked__all">
                <div className={`items has-icon ${classActive}`} onClick={(e) => _handleSelectAll(e)}>
                    <IconCheck />
                    {t('report:all_status')}
                </div>
            </div>
        );
    }

    function _renderListOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu content-checked" id="wookppol_job_status">
                {_renderSelectAll()}
                <ul className="scrolls">{_renderStatus()}</ul>
            </div>
        );
    }

    return (
        <div
            ref={refSearch}
            className={`v2-dropdown select-list list-job-status --no-bg ${finalIsVisible ? 'active' : ''}`}
        >
            <div className="dropbtn items has-icon" onClick={(e) => _handleOpen(e)}>
                <IconWorkOrder />
                <p className="txt-ellipsis flex-1">{t('calendar:job_status')}</p>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </div>
            {_renderListOptions()}
        </div>
    );
};

export default FilterJobStatus;
