import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

export const ServiceVerifyNumber = ({ onSuccess = () => {}, onFail = () => {} }) => {
    const { verifyNumber } = useContext(SocketContext);

    useEffect(() => {
        if (!verifyNumber) return;
        verifyNumber.success ? onSuccess(verifyNumber) : onFail(verifyNumber);
    }, [verifyNumber]);

    return null;
};

export default ServiceVerifyNumber;
