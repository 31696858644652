import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GdRichText from 'app/components/richtext';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import { GET_EMAIL_INBOX_SIGNATURE } from 'app/const/Api';
import { PRESIGN_TYPES } from 'app/const/App';
import { LIST_STATUS } from 'app/const/Status';
import { updateEmailSignature } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';

const EmailSignature = ({ defaultContent = '', onUpdateSuccess = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isFetchSignature = useSelector(({ auth }) => auth?.user?.isFetchSignature);

    const refRichtext = useRef(null);
    const refButtonSave = useRef(null);

    useEffect(() => {
        refRichtext.current && refRichtext.current.focus();
    }, []);

    const _handleSave = () => {
        const content = refRichtext.current.getValue();
        const _handleSuccess = () => {
            onUpdateSuccess({
                id: 'update_signature_success',
                message: t('update_successfully'),
                type: LIST_STATUS.SUCCESS
            });

            isFetchSignature && dispatch(updateEmailSignature(content));
        };

        const _handleFail = ({ message }) => {
            onUpdateSuccess({
                id: 'update_signature_success',
                message: message || t('action_failure'),
                type: LIST_STATUS.ERROR
            });
        };

        const _handleFinally = () => {
            refButtonSave.current.removeLoading();
        };
        clientQuery(
            GET_EMAIL_INBOX_SIGNATURE,
            { data: refRichtext.current.getValueWithAttach(), toFormData: false, method: 'PUT' },
            _handleSuccess,
            _handleFail,
            _handleFinally
        );
    };

    return (
        <div className="dashboard-wrapper email-sign-form">
            <span className="txt">Email Signature</span>
            <GdRichText
                ref={refRichtext}
                defaultContent={defaultContent || ' '}
                typePresign={PRESIGN_TYPES.EMAIL_INBOX}
                toolbarConfig={{
                    left: [
                        {
                            group: 'emoji',
                            children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }]
                        },
                        {
                            group: 'style',
                            children: [
                                { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                { type: TYPE_FUNCTION_RICHTEXT.LINK }
                            ]
                        },
                        {
                            group: 'list',
                            children: [{ type: TYPE_FUNCTION_RICHTEXT.UL }, { type: TYPE_FUNCTION_RICHTEXT.OL }]
                        },
                        {
                            group: 'image',
                            children: [{ type: TYPE_FUNCTION_RICHTEXT.IMAGE, props: { canUpload: false } }]
                        }
                    ],
                    right: [{ group: 'code', children: [{ type: TYPE_FUNCTION_RICHTEXT.CODE }] }]
                }}
            />
            <div className="text-right mt-3">
                <ButtonSave ref={refButtonSave} title="Save" onSave={_handleSave} />
            </div>
        </div>
    );
};

export default EmailSignature;
