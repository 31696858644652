import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ContentSettingTemplateAddress from 'app/modules/settings/components/ContentSettingTemplateAddress';
import SettingTemplateHeader from 'app/modules/settings/components/SettingTemplateHeader';

import Tooltip from 'app/components/tooltip';

import {
    LIST_OPTIONS_SERVICE_ADDRESS,
    TEMPLATE_KEYS,
    getTemplateOptions,
    getTitleDescription
} from 'app/const/Settings';
import SingleSelect from 'app/components/select/singleSelect';

const OptionSwitch = ({ title, tooltip, name, value, checked, onChange }) => {
    const { t } = useTranslation(['setting']);

    const handleChange = () => {
        onChange(name);
    };
    return (
        <div className="rows">
            <div className="name">
                <span className="txt-ellipsis">{t(`setting:${title || name}`)}</span>
                <Tooltip tooltip={tooltip} />
            </div>

            <div className="switch large">
                <input
                    id={name}
                    className="toggle toggle-round"
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                />
                <label onClick={handleChange} htmlFor={name}>
                    <span className="onoff hideshow" />
                </label>
            </div>
        </div>
    );
};

const SettingTemplateForm = ({ data, templates, type, onSubmit, onEdit, onPreview, onCancel }) => {
    const { t } = useTranslation(['setting']);

    const [state, setState] = useState({
        data: null,
        templates
    });

    useEffect(() => {
        if (data) {
            setState({
                ...state,
                data
            });
        }
    }, [data]);

    const handleGetParams = () => {
        return {
            ...state.data,
            [TEMPLATE_KEYS.SERVICE_ADDRESS]: parseInt(state.data[TEMPLATE_KEYS.SERVICE_ADDRESS])
        };
    };

    const handleSubmit = () => {
        const params = handleGetParams();
        onSubmit(params);
    };

    const handleEdit = () => {
        onEdit();
    };

    const handlePreview = () => {
        const params = handleGetParams();
        onPreview(params);
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleKeypress = (e) => {
        if (e.code === 'Enter' || e.code === 'NumpadEnter') {
            handleSubmit();
        }
    };

    const handleSwitch = (name) => {
        const tmp = state.data[name] === 0 ? 1 : 0;
        setState((prev) => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: tmp
                }
            };
        });
    };

    const handleChangeValue = (name, value) => {
        setState((prev) => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value
                }
            };
        });
    };

    const renderContentTop = () => {
        if (!state.data) return null;

        return (
            <ContentSettingTemplateAddress
                onEdit={handleEdit}
                onPreview={handlePreview}
                address={state.data?.company_address}
            />
        );
    };

    const renderContentMiddle = () => {
        if (!state.data) return null;

        const titleDescription = getTitleDescription(type);

        return (
            <div className="boxs__content --has-form">
                <div className="rows">
                    <span className="txt">{t(`setting:${type}_template`)}</span>
                    <SingleSelect
                        options={templates}
                        name={TEMPLATE_KEYS.TEMPLATE_ID}
                        onSelect={handleChangeValue}
                        selected={state.data[TEMPLATE_KEYS.TEMPLATE_ID]}
                        title={templates[0].name}
                    />
                </div>

                <div className="rows">
                    <span className="txt">{t(`setting:${type}_title`)}</span>
                    <input
                        className="field-input"
                        type="text"
                        onKeyPress={handleKeypress}
                        placeholder=""
                        value={state?.data[`${type}_title`]}
                        onChange={(e) => {
                            handleChangeValue(`${type}_title`, e.target.value);
                        }}
                    />
                    <p className="txt-down">{t(`setting:${titleDescription}`)}</p>
                </div>

                <div className="rows">
                    <span className="txt">{t('setting:text_below_your_logo')}</span>
                    <input
                        className="field-input"
                        type="text"
                        onKeyPress={handleKeypress}
                        value={state?.data[TEMPLATE_KEYS.TEXT_BELOW]}
                        onChange={(e) => {
                            handleChangeValue(TEMPLATE_KEYS.TEXT_BELOW, e.target.value);
                        }}
                    />
                    <p className="txt-down">{t('setting:adds_text_to_all_your_new_and_existing_documents')}</p>
                </div>
            </div>
        );
    };

    const renderListOptionsBottom = () => {
        if (!state.data) return null;

        const optionsTemplate = getTemplateOptions(type);
        const listKeys = Object.keys(optionsTemplate);

        if (!Array.isArray(listKeys)) return null;

        return listKeys.map((item) => {
            const tooltip = t(`setting:${optionsTemplate[item].tooltip}`);

            if (optionsTemplate[item].type === 1) {
                return (
                    <OptionSwitch
                        title={item}
                        key={item}
                        tooltip={tooltip}
                        value={state.data[item]}
                        checked={state.data[item] === 1}
                        onChange={() => {
                            handleSwitch(item);
                        }}
                    />
                );
            }

            return (
                <div key={item} className="rows">
                    <div className="name">
                        {t(`setting:${item}`)}
                        <Tooltip tooltip={tooltip} />
                    </div>
                    <SingleSelect
                        options={LIST_OPTIONS_SERVICE_ADDRESS}
                        onSelect={handleChangeValue}
                        selected={state.data[item]}
                        fileTranslation="setting"
                        classWrapper="v2-dropup"
                        name={item}
                    />
                </div>
            );
        });
    };

    return (
        <>
            <SettingTemplateHeader title={type} onCancel={handleCancel} onSubmit={handleSubmit} />
            <div className="scrolls box-auto">
                <div className="boxs-wrapper">
                    <div className="boxs">
                        {renderContentTop()}

                        {renderContentMiddle()}

                        <div className="boxs__content">{renderListOptionsBottom()}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

SettingTemplateForm.propTypes = {
    data: PropTypes.object,
    templates: PropTypes.array,
    type: PropTypes.string,
    onSubmit: PropTypes.func,
    onEdit: PropTypes.func,
    onPreview: PropTypes.func,
    onCancel: PropTypes.func
};

export default SettingTemplateForm;
