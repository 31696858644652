import React, { useReducer, useEffect, useRef, useContext } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import TagsDisplay from '../accounts/components/TagsDisplay';
import GdTags from 'app/components/tags';
import { updateTagsLocation, updateTagsSideBar } from 'app/const/api/V2';
import { useParams } from 'react-router-dom';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { useTranslation } from 'react-i18next';
import { TYPE_TAGS } from 'app/const/Customers';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { DEFAULT_ALL } from 'app/const/App';
import { _handleConvertTags } from '../context/utils';
import classNames from 'classnames';

const CustomerTags = ({
    id = 'show_customer_tags',
    idDropdown = 'list_tags_sidebar',
    isDisable = false,
    isLocation = false,
    isEditAccount = false,
    classTitle = 'title flexcenter fs-14 fw-500',
    classWrapper = 'v2-dropdown tags list-add-tags',
    classRowTagsDisplay = 'wrap-existing-tag flexcenter flex-wrap',
    classWrapperDropdown = 'box-tags flex-1',
    classLabel = 'tags__label flex-betweenitems'
}) => {
    const { t } = useTranslation(['customers']);
    const { id: idCustomer } = useParams();
    const { customer, locationSelected, locations, _handleUpdateCustomer, _handleUpdateLocation } =
        useContext(CustomerDetailContext);
    const [state, dispatchState] = useReducer(reducer, { isEdit: false });
    const refTags = useRef(null);
    const isEdit = state.isEdit;
    const finalSelected = locationSelected?.id === DEFAULT_ALL ? locations[0] : locationSelected;
    const tags = isLocation ? _handleConvertTags(finalSelected?.tags || []) : customer.tags;

    useEffect(() => {
        if (isEdit) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleKeydown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeydown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleKeydown, true);
        };
    }, [isEdit]);

    const handleKeydown = (event) => {
        const elPrevent = document.getElementById(id);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            handleHideDropdown();
        }
    };

    const _handleOpen = () => {
        dispatchState({ isEdit: true });
    };

    const handleHideDropdown = () => {
        isEdit && dispatchState({ isEdit: false });
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(id);
        if (elPrevent && !elPrevent.contains(event.target)) {
            handleHideDropdown();
            _handleSaveTags();
        }
    };

    const _handleSaveTags = () => {
        const tags = refTags.current?.getValue().map((item) => item.id);
        clientQuery(
            isLocation
                ? updateTagsLocation(locationSelected.id === DEFAULT_ALL ? locations?.[0]?.id : locationSelected?.id)
                : updateTagsSideBar(idCustomer),
            { data: { tags: tags }, toFormData: false, method: 'PUT' },
            _handleSuccess(tags)
        );
    };

    const _handleSuccess = (data) => {
        isLocation ? _handleUpdateLocation({ tags: data }, true) : _handleUpdateCustomer({ tags: data });
    };
    const _renderListTags = () => {
        return isEdit ? (
            <div id={id} className={classNames({ 'rows__info flex-1': isEditAccount })}>
                <GdTags
                    ref={refTags}
                    id={idDropdown}
                    type={isLocation ? TYPE_TAGS.TAG_CUSTOMER : TYPE_TAGS.TAG_DEFAULT}
                    defaultTags={tags.map((item) => ({ id: item, name: item }))}
                    isTagsSideMenu={!isEditAccount}
                    isTagsAccount={isEditAccount}
                    classWrapper={classWrapperDropdown}
                    isCheckHidden
                    autoFocus
                />
            </div>
        ) : (
            <TagsDisplay
                data={tags}
                isTagsSideMenu
                isEditAccount={isEditAccount}
                classRow={classRowTagsDisplay}
                classWrapper="rows__info flex-1"
                btnAdd={isEditAccount ? _renderBtnAdd : () => {}}
            />
        );
    };

    const _renderLabel = () => {
        return isEditAccount ? (
            <div className="rows__label">{t('customers:tags')}</div>
        ) : (
            <div className={classLabel}>
                <div className={classTitle}>
                    {t('customers:tags')}
                    {!isEdit && _renderBtnAdd()}
                </div>
                {isEdit && (
                    <span onClick={handleHideDropdown} className="btn-close fs-13 cursor-pointer">
                        {t('customers:close')}
                    </span>
                )}
            </div>
        );
    };

    const _renderBtnAdd = () => {
        return (
            <span
                className={classNames('v2-btn-default --icon-sm btn-edit-tag', { 'is-disable': isDisable })}
                onClick={_handleOpen}
            >
                <IconPlus />
            </span>
        );
    };

    return (
        <div className={classWrapper}>
            {_renderLabel()}
            {_renderListTags()}
        </div>
    );
};

export default CustomerTags;
