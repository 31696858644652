import React from 'react';

export default function IconLoadingChart({ isLineChart = false }) {
    if (isLineChart) {
        return (
            <svg viewBox="0 0 360 50">
                <path d="M161.49 0H27.5059C27.5181 0 27.5298 0.000732422 27.542 0.00170898C27.6797 0.0134277 27.8062 0.0812988 27.8921 0.189697L65.689 47.9331L98.0547 39.4155C98.1377 39.3936 98.2251 39.3936 98.3086 39.4153L130.774 47.9238L161.078 0.231934C161.156 0.108887 161.284 0.026123 161.428 0.00512695C161.449 0.00219727 161.469 0.000488281 161.49 0ZM27.4946 0H0V24.5798L27.1655 0.128418C27.2563 0.0463867 27.3735 0.0012207 27.4946 0ZM0 25.2524V48.75H65.0601L27.4385 1.22803L0.334473 25.6243L0 25.2524ZM66.5151 48.75H129.981L98.1821 40.416L66.5151 48.75ZM131.434 48.75H360V25.7625L327.371 32.3083C327.282 32.3262 327.19 32.3196 327.105 32.2893L294.378 20.6733C294.346 20.6619 294.315 20.6472 294.287 20.6296L261.893 1.01953L229.293 5.52734L196.673 31.2009C196.484 31.3494 196.217 31.343 196.036 31.1855L161.61 1.25854L131.434 48.75ZM360 25.2524V0H262.004C262.094 0.000976562 262.182 0.0256348 262.259 0.0722656L294.761 19.7478L327.31 31.3008L359.902 24.7622L360 25.2524ZM261.995 0H161.51C161.626 0.00219727 161.739 0.045166 161.828 0.122559L196.379 30.1594L228.782 4.65771C228.852 4.60278 228.935 4.56738 229.022 4.55518L261.932 0.00463867C261.953 0.00170898 261.974 0 261.995 0ZM0 49.25H360V50H0V49.25Z"></path>
            </svg>
        );
    }
    return (
        <svg viewBox="0 0 360 100">
            <path d="M0 21.93L33.4351 31.39L65.4962 38.055L98.4733 34.185L130.992 43L196.364 26.23L229.466 4.08497L260.611 -5.97429e-05L294.504 17.415L327.939 27.52L360 21.5V-3.34978e-05H0V21.93Z"></path>
        </svg>
    );
}
