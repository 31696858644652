import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGidColumns = (actionsStatus) => {
    const HEADER_NEW_CUSTOMERS = {
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date_added: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        source: {
            title: 'source',
            style: 'col col-xs'
        },
        phones: {
            title: 'phones',
            style: 'col col-phone',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        email: {
            title: 'email',
            style: 'col col-email',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address: {
            title: 'service_address',
            style: 'col col-note',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        top_note: {
            title: 'top_note',
            style: 'col col-note word-break'
        },
        active_jobs: {
            title: 'active_job_schedules',
            style: 'col col-lg'
        },
        completed_jobs: {
            title: 'completed_jobs',
            style: 'col col-lg'
        }
    };

    const COLUMNS_NEW_CUSTOMERS = [
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'date_added',
            title: 'date_added',
            status: true
        },
        {
            id: 'source',
            title: 'source',
            status: true
        },
        {
            id: 'phones',
            title: 'phones',
            status: true
        },
        {
            id: 'email',
            title: 'email',
            status: true
        },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'top_note',
            title: 'top_note',
            status: true
        },
        {
            id: 'active_jobs',
            title: 'active_jobs',
            status: true
        },
        {
            id: 'completed_jobs',
            title: 'completed_jobs',
            status: true
        }
    ];

    const CONTENT_NEW_CUSTOMERS = {
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        date_added: {
            id: 'date_added',
            style: 'col col-md'
        },
        source: {
            id: 'source',
            style: 'col col-xs',
            defaultValue: COMMON.N_A
        },
        phones: {
            id: 'phones',
            style: 'col col-phone',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        email: {
            id: 'email',
            style: 'col col-email',
            type: LIST_TABLE_ITEM_TYPE.EMAIL
        },
        service_address: {
            id: 'service_address',
            style: 'col col-note',
            type: LIST_TABLE_ITEM_TYPE.WORD_BREAK
        },
        top_note: {
            id: 'top_note',
            style: 'col col-note word-break',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        active_jobs: {
            id: 'active_jobs',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.LIST_ACTIVE_JOBS
        },
        completed_jobs: {
            id: 'completed_jobs',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.LIST_ACTIVE_JOBS
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_NEW_CUSTOMERS });

    return {
        header: newHeader,
        columns: COLUMNS_NEW_CUSTOMERS,
        contentConfig: CONTENT_NEW_CUSTOMERS
    };
};
