import React, { createContext, useEffect, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';

export const TabDetailContext = createContext();

const TabDetailContextProvider = ({ jobData, children, onUpdate }) => {
    const [state, dispatchState] = useReducer(reducer, _preData());
    const commissionReducer = useSelector(({ commissionReducer }) => commissionReducer);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    // eslint-disable-next-line no-undef
    global.currentJobData = state;

    useEffect(() => {
        jobData && dispatchState({ ...jobData });
    }, [jobData]);

    function _preData() {
        return { ...jobData };
    }

    function _handleChangeData(newData) {
        onUpdate(newData);
    }

    const _handleUpdateCommission = (payload) => {
        onUpdate({
            reloadSoldBy: Date.now(),
            commission: {
                productions: _handleGetDataFromCommission(jobData.schedule),
                sales: _handleGetDataFromCommission(payload)
            },
            sold_by: payload
        });
    };

    // Get commission data from commission reducer with conditional
    const _handleGetDataFromCommission = (data) => {
        const result = [];

        commissionReducer.items.forEach((item) => {
            if (data.some((el) => +item.user_id === +el.id)) {
                item['user'] = companyUsers.find((user) => user.id === item.user_id);
                result.push(item);
            }
        });

        return result;
    };

    const TabDetailContextData = {
        jobData: state,
        updateJobDataContext: _handleChangeData,
        updateCommissionJobContext: _handleUpdateCommission
    };

    return <TabDetailContext.Provider value={TabDetailContextData}>{children}</TabDetailContext.Provider>;
};

export default TabDetailContextProvider;
