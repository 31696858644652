import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { reducer } from 'app/const/Reducer';

const ForwardingSettings = ({ forwarding = {}, isNotUseSwitch = false }, ref) => {
    const { t } = useTranslation();
    const refCurrentData = useRef({ ...forwarding });

    const { enable, number } = forwarding;
    const [state, dispatchState] = useReducer(reducer, {
        isActive: !!enable
    });
    const { isActive } = state;

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _handleChangeActive = (value) => {
        refCurrentData.current.enable = value;
        dispatchState({ isActive: value });
        if (!value && !number) refCurrentData.current.number = '';
    };

    const _hangdleChangeInput = (e) => {
        refCurrentData.current.number = e.target.value;
    };

    const _getValue = () => {
        const { enable, number } = refCurrentData.current;
        return {
            forwarding: enable ? 1 : 0,
            forwarding_number: number
        };
    };

    return (
        <div className="box-voip__rows flexcenter flex-wrap is-enabled">
            <p className="flex-1 fw-500">{t('addons:forwarding_number')}</p>
            {!isNotUseSwitch && (
                <SwitchCheckbox
                    id="call_forward_setting"
                    textChecked={t('addons:enabled')}
                    textUnChecked={t('addons:disabled')}
                    defaultChecked={!!enable}
                    onChange={_handleChangeActive}
                    timeOut={0}
                />
            )}
            <div className="detail-enable flex-column gap-8 w-100 mt-2">
                <p className="word-break">{t('addons:desc_forwarding_settings')}</p>
                {(isActive || isNotUseSwitch) && (
                    <input type="text" className="field-input" onChange={_hangdleChangeInput} defaultValue={number} />
                )}
            </div>
        </div>
    );
};

export default forwardRef(ForwardingSettings);
