import { forwardRef, useReducer, useImperativeHandle, useRef } from 'react';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const ModalJobRecuring = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar, common']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });

    const refEvents = useRef(null);

    function _showOptions(infoEvent, callBackCheck, callBackHandle, customCallBack) {
        refEvents.current = {
            infoEvent,
            callBackCheck,
            callBackHandle,
            customCallBack
        };
        dispatchState({ isVisible: true });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _closeOptions(e) {
        e.stopPropagation();
        dispatchState({ isVisible: false });
        if (refEvents.current) {
            refEvents.current.infoEvent.revert();
            if (refEvents.current.customCallBack) refEvents.current.customCallBack();
        }
    }

    function _handleClickOption(e) {
        e.stopPropagation();

        refEvents.current &&
            refEvents.current.callBackCheck(refEvents.current.infoEvent, false, refEvents.current.callBackHandle);

        dispatchState({ isVisible: false });
    }

    function _renderReSchedule() {
        if (!state.isVisible) {
            return false;
        }

        return (
            <div className="modal container-modal batch-job --reschedule open">
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('calendar:reschedule_job')}</h3>
                        <div onClick={(e) => _closeOptions(e)} className="v2-btn-default --icon-lg --transparent">
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <div className="rows">
                            <p className="desc">
                                {t('calendar:reschedule_job_popup_description')}
                            </p>
                        </div>
                    </div>
                    <div className="footer-modal btn-close">
                        <span onClick={(e) => _closeOptions(e)} className="v2-btn-default --transparent">
                            {t('common:no')}
                        </span>
                        <div onClick={(e) => _handleClickOption(e)} className="v2-btn-main">
                            {t('common:yes')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return _renderReSchedule();
});

export default ModalJobRecuring;
