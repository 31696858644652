export default function IconArea({ isNormal = false }) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5 16.5H11.5C10.9477 16.5 10.5 16.0523 10.5 15.5V11.5C10.5 10.9477 10.9477 10.5 11.5 10.5H12.5C13.0523 10.5 13.5 10.0523 13.5 9.5V8.5C13.5 7.94772 13.9477 7.5 14.5 7.5H18.5C19.0523 7.5 19.5 7.94772 19.5 8.5V15.5C19.5 16.0523 19.0523 16.5 18.5 16.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.5 8.5V4.5H16.5V5.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5 10.5H4.5V19.5H16.5V18.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19 15.9975L11 15.9975C10.4477 15.9975 10 15.5498 10 14.9975V11C10 10.4477 10.4477 10 11 10H12C12.5523 10 13 9.55229 13 9V8C13 7.44772 13.4477 7 14 7H19C19.5523 7 20 7.44772 20 8V14.9975C20 15.5498 19.5523 15.9975 19 15.9975Z"
                fill="#7A83A6"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 7V5C6 3.89543 6.89543 3 8 3H16.5C17.3284 3 18 3.67157 18 4.5V5C18 5.55228 17.5523 6 17 6H13C12.4477 6 12 6.44772 12 7V8C12 8.55228 11.5523 9 11 9H8C6.89543 9 6 8.10457 6 7Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 10H6C4.89543 10 4 10.8954 4 12V18C4 19.1046 4.89543 20 6 20H15.5022C16.3294 20 17 19.3294 17 18.5022V18.0044C17 17.4521 16.5523 17.0044 16 17.0044H10C9.44772 17.0044 9 16.5567 9 16.0044V11C9 10.4477 8.55228 10 8 10Z"
                fill="#C1C9D4"
            ></path>
        </svg>
    );
}
