import React from 'react';
import PropTypes from 'prop-types';
import useClickOutside from 'common/hooks/useClickOutside';
import EmojiMart from 'app/components/emoji';
import IconEmoji from 'assets/icon/IconEmoji';
function MessageEmoji({ onAddEmoji = () => {} }) {
    const [refOutside, isVisible, setIsVisible] = useClickOutside(false);

    const _toggleActive = () => setIsVisible(!isVisible);

    const _addEmoji = (value) => {
        _toggleActive();
        onAddEmoji(value);
    };

    return (
        <>
            <div ref={refOutside} className="v2-dropdown">
                <div className="dropbtn" onClick={_toggleActive}>
                    <IconEmoji />
                </div>

                <div style={{ display: isVisible ? 'block' : 'none' }}>
                    <EmojiMart handleOnClick={_addEmoji} />
                </div>
            </div>
        </>
    );
}

MessageEmoji.propTypes = {
    onAddEmoji: PropTypes.func
};

export default React.memo(MessageEmoji);
