export default function IconUnOrderList() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.5 16.5H19.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M5.5 17.5C6.05228 17.5 6.5 17.0523 6.5 16.5C6.5 15.9477 6.05228 15.5 5.5 15.5C4.94772 15.5 4.5 15.9477 4.5 16.5C4.5 17.0523 4.94772 17.5 5.5 17.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M9.5 11.5H19.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M5.5 12.5C6.05228 12.5 6.5 12.0523 6.5 11.5C6.5 10.9477 6.05228 10.5 5.5 10.5C4.94772 10.5 4.5 10.9477 4.5 11.5C4.5 12.0523 4.94772 12.5 5.5 12.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M9.5 6.5H19.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M5.5 7.5C6.05228 7.5 6.5 7.05228 6.5 6.5C6.5 5.94772 6.05228 5.5 5.5 5.5C4.94772 5.5 4.5 5.94772 4.5 6.5C4.5 7.05228 4.94772 7.5 5.5 7.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
