export default function IconClose({ isHasColor = false, isSmall = false }) {
    if (isSmall) {
        return (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M7.5 7.5L12.5 12.5M12.5 7.5L7.5 12.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                />{' '}
            </svg>
        );
    }

    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 3.99993C16.4183 3.99993 20 7.58165 20 11.9999C20 16.4182 16.4183 19.9999 12 19.9999C7.58172 19.9999 4 16.4182 4 11.9999C4 7.58165 7.58172 3.99993 12 3.99993ZM9.87868 7.05018C9.09763 6.26913 7.8313 6.26913 7.05025 7.05018C6.2692 7.83123 6.2692 9.09756 7.05025 9.87861L9.17157 11.9999L7.05025 14.1213C6.2692 14.9023 6.2692 16.1686 7.05025 16.9497C7.8313 17.7307 9.09763 17.7307 9.87868 16.9497L12 14.8284L14.1213 16.9497C14.9024 17.7307 16.1687 17.7307 16.9497 16.9497C17.7308 16.1686 17.7308 14.9023 16.9497 14.1213L14.8284 11.9999L16.9497 9.87861C17.7308 9.09756 17.7308 7.83123 16.9497 7.05018C16.1687 6.26913 14.9024 6.26913 14.1213 7.05018L12 9.1715L9.87868 7.05018Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.75735 7.75729C8.14787 7.36676 8.78104 7.36676 9.17156 7.75729L12 10.5857L14.8284 7.75729C15.2189 7.36676 15.8521 7.36676 16.2426 7.75729C16.6332 8.14781 16.6332 8.78098 16.2426 9.1715L13.4142 11.9999L16.2426 14.8284C16.6332 15.2189 16.6332 15.852 16.2426 16.2426C15.8521 16.6331 15.2189 16.6331 14.8284 16.2426L12 13.4141L9.17156 16.2426C8.78104 16.6331 8.14787 16.6331 7.75735 16.2426C7.36683 15.852 7.36683 15.2189 7.75735 14.8284L10.5858 11.9999L7.75735 9.1715C7.36683 8.78098 7.36683 8.14781 7.75735 7.75729Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 6.5L6.5 17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.5 6.5L17.5 17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
