export default function IconFlag({ isStart = false, isFinish = false }) {
    if (isStart) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.5 17.5L15.5 5.5L20.0858 10.0858C20.8668 10.8668 20.8668 12.1332 20.0858 12.9142L16.9142 16.0858C16.1332 16.8668 14.8668 16.8668 14.0858 16.0858L9.5 11.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.5 14.5L15.5 11.5L12.5 8.5L9.5 11.5L12.5 14.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.5 14.5L20.0858 12.9142C20.8668 12.1332 20.8668 10.8668 20.0858 10.0858L18.5 8.5L15.5 11.5L18.5 14.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isFinish) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.5 17.5L18.5 5.5L22.5 9.5L18.5 13.5L14.5 9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.5 17.5L6.5 5.5L2.5 9.5L6.5 13.5L10.5 9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5547 5.03647L16.7519 9.16795C17.2115 9.4743 17.3356 10.0952 17.0293 10.5547C16.956 10.6646 16.8618 10.7588 16.7519 10.8321L10.5547 14.9635C10.0952 15.2699 9.4743 15.1457 9.16795 14.6862C9.05844 14.5219 9 14.3289 9 14.1315V5.86852C9 5.31623 9.44772 4.86852 10 4.86852C10.1974 4.86852 10.3904 4.92695 10.5547 5.03647Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M8 5C8 4.44772 7.55228 4 7 4C6.44772 4 6 4.44772 6 5V19C6 19.5523 6.44772 20 7 20C7.55228 20 8 19.5523 8 19V5Z"
                fill="#C1C9D4"
            ></path>
        </svg>
    );
}
