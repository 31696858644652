import { forwardRef, useImperativeHandle } from 'react';

import { EVENT_MESSAGE_RECEIVED, LIST_EVENT_EXCLUDE_PARTICIPANT, TYPE_CALL } from 'app/const/Voip';
import { ACTION_INVITE_CALL } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import { getClientIdVoip } from 'common/utils/FunctionUtils';

const ServiceParticipant = ({ dispatchState, calls }, ref) => {
    useImperativeHandle(ref, () => ({
        onEvent: _handleEventParticipant,
        invite: _handleInvite
    }));

    const _handleEventParticipant = ({ content, callSid }) => {
        const { event: eventName, data: dataEvent } = content;
        if (LIST_EVENT_EXCLUDE_PARTICIPANT.includes(eventName)) return;
        const client_id = getClientIdVoip(dataEvent.ClientId);
        if (!client_id) return;

        dispatchState((prev) => {
            return {
                ...prev,
                calls: prev.calls.map((item) => {
                    if (item.id === callSid) {
                        const prevInviteData = item.inviteData;
                        const isMute = convertBoolean(dataEvent.Muted);
                        const isCoaching = convertBoolean(dataEvent.Coaching);

                        let newMembers = [...prevInviteData.members];
                        switch (eventName) {
                            case EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT_JOIN:
                            case EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT_LEAVE:
                                newMembers = dataEvent.InvitedClients.map(({ ClientId, Coaching, Muted }) => ({
                                    id: getClientIdVoip(ClientId),
                                    isCoaching: Coaching,
                                    isMute: Muted
                                }));
                                break;

                            case EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT_MODIFY:
                                newMembers = newMembers.map((member) => {
                                    if (member.id === client_id) {
                                        return { ...member, isMute, isCoaching };
                                    }
                                    return member;
                                });
                                break;

                            default:
                                break;
                        }
                        return {
                            ...item,
                            inviteData: {
                                ...prevInviteData,
                                members: newMembers
                            }
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _handleInvite = (callData, inviteData) => {
        const {
            company_name,
            company_title,
            customer_name,
            customer_title,
            customer_id,
            phone_type,
            type,
            to,
            from,
            startTime
        } = calls.find(({ id }) => id === callData.id) || {};

        const _handleSuccess = () => {
            dispatchState((prev) => ({
                ...prev,
                calls: prev.calls.map((item) => {
                    if (item.id === callData.id) {
                        return { inviteData: { members: [] }, ...item };
                    }
                    return item;
                })
            }));
        };

        clientQuery(
            ACTION_INVITE_CALL,
            {
                method: 'POST',
                toFormData: false,
                data: {
                    call_id: callData.callSId,
                    parent_call_id: callData.parentId,
                    invite_call_id: callData.inviteCallId,
                    user_id: inviteData.id,
                    company_name: company_name || '',
                    company_title: company_title || '',
                    customer_id: customer_id || '',
                    customer_name: customer_name || '',
                    customer_title: customer_title || '',
                    phone_type: phone_type || '',
                    phone_number: type === TYPE_CALL.OUT ? to : from,
                    start_time: startTime
                }
            },
            _handleSuccess
        );
    };

    const convertBoolean = (value) => {
        return /true/i.test(value?.toString());
    };
};

export default forwardRef(ServiceParticipant);
