import React, { Fragment, useRef } from 'react';
import Options from 'app/modules/customer/note/detail/Options';
import Header from 'app/modules/customer/note/detail/Header';
import Actions from 'app/modules/customer/note/detail/Actions';
import Content from 'app/modules/customer/note/detail/Content';
import NotifySelected from 'app/modules/customer/note/detail/NotifySelected';
import EditNote from 'app/modules/customer/note/edit';
import CommentDetail from 'app/modules/customer/detail/comment/detail';
import AddComment from 'app/modules/customer/detail/comment/Add';
import MarkAsRead from 'app/modules/customer/note/detail/MarkAsRead';
import Repeat from 'app/modules/customer/note/detail/Repeat';
import ShowMoreComment from 'app/modules/customer/note/detail/ShowMoreComment';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import { clientQuery } from 'common/utils/ApiUtils';
import { commentOfNote, actionNotes } from 'app/const/Api';
import IconNote from 'assets/icon/IconNote';
import classNames from 'classnames';
import GdConfirm from 'app/components/confirm';
import { useTranslation } from 'react-i18next';
import TemplateOptions from 'app/components/templatenotes';

const NoteDetail = ({
    noteDetail,
    onUpdate,
    onUpdateCallback,
    type = TYPE_NOTE.CUSTOMER,
    jobId = '',
    unitId = '',
    onDelete,
    isShowRepeat = true,
    addCommment = false,
    isHavePermission = false,
    isHaveDeleteComment = false,
    onHandleDeleteAttachment,
    filterTemplate = 6
}) => {
    const { t } = useTranslation();
    const refEditNote = useRef(null);
    const refShowMoreComment = useRef(null);
    const refAddComment = useRef(null);
    const refConfirm = useRef(null);
    const { id: noteId, is_recurring, comment } = noteDetail || {};
    const finalListComment = comment?.items || [];

    function _openEditNote() {
        document.getElementById(`form_detail_and_edit_note_${noteId}`).classList.toggle('has-edited');
        refEditNote.current.toggleShowHide();
    }

    function _openAddComment() {
        document.getElementById(`form_time_line_note_detail_${noteId}`).classList.add('has-add-comments');
        refAddComment.current.toggleShowHide();
    }

    function _handleDeleteNote() {
        clientQuery(actionNotes(noteId), { method: 'DELETE' });
        onDelete(noteId);
    }

    function _openConfirmDelete() {
        refConfirm.current.open();
    }

    function _handleUpdateComment(newComment) {
        let listComment = [...finalListComment];

        listComment = listComment.map((item) => {
            if (item.id === newComment.id) {
                return {
                    ...item,
                    ...newComment
                };
            }
            return item;
        });

        onUpdate({
            ...noteDetail,
            comment: {
                ...noteDetail.comment,
                items: listComment
            }
        });
    }

    function _handleDeleteAttachment(idAttach, commentId) {
        let listComment = [...finalListComment];

        listComment = listComment.map((item) => {
            if (item.id === commentId) {
                return {
                    ...item,
                    attachments: [...item.attachments].filter((item) => item.id !== idAttach)
                };
            }
            return item;
        });

        onUpdate({
            ...noteDetail,
            comment: {
                ...noteDetail.comment,
                items: listComment
            }
        });
    }

    function _handleRemoveComment(commentId) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    let newComments = [...item.comment.items];
                    newComments = newComments.filter((commenetRemove) => commentId !== commenetRemove.id);
                    return {
                        ...item,
                        comment: {
                            ...item.comment,
                            items: newComments,
                            total: Number(item.comment.total) - 1
                        }
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleCleanQuickAdd(oldId, NewId) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    let newComments = [...item.comment.items];
                    newComments = newComments.map((commentUpdate) => {
                        if (commentUpdate.id === oldId) {
                            return {
                                ...commentUpdate,
                                id: NewId,
                                quick_add: false
                            };
                        }
                        return commentUpdate;
                    });

                    return {
                        ...item,
                        comment: {
                            ...item.comment,
                            items: newComments
                        }
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleAddNewCommment(newComment) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    return {
                        ...item,
                        comment: {
                            ...item.comment,
                            items: [newComment].concat(item.comment.items || []),
                            total: Number(item.comment.total) + 1
                        }
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleShowMoreComment() {
        const optionsQuery = {
            method: 'GET',
            data: {
                offset: finalListComment.length,
                total: 1
            }
        };

        clientQuery(commentOfNote(noteId), optionsQuery, _handleShowMoreCommentSuccess);
    }

    function _handleShowMoreCommentSuccess(response) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    const newComments = [...item.comment.items, ...response.data];
                    return {
                        ...item,
                        comment: {
                            ...item.comment,
                            items: newComments,
                            total: response.total
                        }
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
        refShowMoreComment.current.removeLoading();
    }

    function _handleUpdateSticky(newSticky) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    return {
                        ...item,
                        is_sticky: newSticky
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleUpdateRepeat(newRepeat) {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    return {
                        ...item,
                        is_recurring: newRepeat
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleUpdateMarkAsRead() {
        onUpdateCallback((preData) => {
            let newDataTimeLine = [...preData.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === noteDetail.id) {
                    return {
                        ...item,
                        total_notifications: 0
                    };
                }
                return item;
            });
            return {
                ...preData,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleSelectTemplate({ content: newContent }) {
        refEditNote.current.setContent(newContent, true);
    }

    function _renderListComment() {
        let listComment = [...finalListComment];
        listComment = listComment.reverse();
        return listComment.map((item) => {
            return (
                <CommentDetail
                    onUpdate={_handleUpdateComment}
                    onnRemove={_handleRemoveComment}
                    commentDetail={item}
                    key={item.id}
                    noteId={noteId}
                    type={type}
                    jobId={jobId}
                    unitId={unitId}
                    isHaveDeleteComment={isHaveDeleteComment}
                    onHandleDeleteAttachment={_handleDeleteAttachment}
                />
            );
        });
    }

    return (
        <div
            className={classNames('boxs is-note', { 'has-recursjob': is_recurring })}
            id={`form_time_line_note_detail_${noteId}`}
        >
            <div className="left-icons">
                <IconNote isActiveNoteRecurring={is_recurring} />
            </div>
            <div className="content elm-parent" id={`form_detail_and_edit_note_${noteId}`}>
                <div className="header-box">
                    <Header noteDetail={noteDetail} />
                    <div className="header-box__action">
                        {isShowRepeat && (
                            <Fragment>
                                <MarkAsRead noteDetail={noteDetail} onUpdateMarkAsRead={_handleUpdateMarkAsRead} />
                                <Repeat noteDetail={noteDetail} onUpdateRepeat={_handleUpdateRepeat} />
                                <Actions noteDetail={noteDetail} onUpdateSticky={_handleUpdateSticky} />
                            </Fragment>
                        )}

                        {!noteDetail.quick_add && (
                            <Options
                                openFormEdit={_openEditNote}
                                openFormAddComment={_openAddComment}
                                onDelete={_openConfirmDelete}
                                addCommment={addCommment}
                                isHavePermission={isHavePermission}
                                typeTemplate={filterTemplate}
                                content={noteDetail.content}
                            />
                        )}
                    </div>
                    <TemplateOptions isShow typeFilter={filterTemplate} onSelectTemplate={_handleSelectTemplate} />
                </div>
                <div className="wrap-content is-complete">
                    <Content
                        noteDetail={noteDetail}
                        onHandleDeleteAttachment={onHandleDeleteAttachment}
                        noteId={noteId}
                    />
                    <NotifySelected selected={noteDetail.notified_users} />
                </div>
                <EditNote
                    onUpdate={onUpdate}
                    noteDetail={noteDetail}
                    ref={refEditNote}
                    type={type}
                    jobId={jobId}
                    unitId={unitId}
                />
            </div>
            <ShowMoreComment
                ref={refShowMoreComment}
                onPress={_handleShowMoreComment}
                number={finalListComment.length}
                total={noteDetail.comment.total}
            />
            <div className="content --comment form-comments">{_renderListComment()}</div>
            <div className="content --comment new-comment" id={`add_comment_${noteId}`}>
                <AddComment
                    cleanQuickAdd={_handleCleanQuickAdd}
                    onAdd={_handleAddNewCommment}
                    noteId={noteId}
                    ref={refAddComment}
                    type={type}
                    jobId={jobId}
                    unitId={unitId}
                />
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('Delete Note')}
                titleConfirm={t('common:confirm')}
                message={t('customers:are_you_sure_delete_note')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleDeleteNote}
            />
        </div>
    );
};

export default NoteDetail;
