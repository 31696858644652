import i18n from 'assets/i18n';
import { parseToListOfFilters } from '../components/utils';
import { OBJECT_TYPES } from './types';

export const SMART_VIEW_CUSTOM_EVENTS = {
    DISPLAY_ALERT: 'display_alert'
};
export const LIMIT_QUOTES_LENGTH = 3;
export const LIMIT_SORT_LENGTH = 3;
export const LIMIT_SMART_VIEW_DATA = 50;
export const LIMIT_SMART_VIEW_LIST = 15;
export const SMART_VIEW_CUSTOM_ACTIONS = {
    UPDATE_SMART_VIEW_QUERY: 'update_smart_view_query',
    CREATE_SMART_VIEW_QUERY: 'create_smart_view_query'
};
export const SHARING_VALUES_SMART_VIEW = {
    EVERYONE_IN_COMPANY: 1,
    SPECIFIC_USERS: 2,
    PRIVATE: 3
};
export const SHARING_OPTIONS_SMART_VIEW = [
    { id: 1, value: SHARING_VALUES_SMART_VIEW.EVERYONE_IN_COMPANY, label: 'share_with_everyone_in' },
    { id: 2, value: SHARING_VALUES_SMART_VIEW.SPECIFIC_USERS, label: 'share_specific_users' },
    { id: 3, value: SHARING_VALUES_SMART_VIEW.PRIVATE, label: 'keep_it_private_only_to_created_by' }
];
export const OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER = [
    { id: 1, value: 1, label: i18n.t('smartView:only_the_first_contact') },
    { id: 2, value: 2, label: i18n.t('smartView:all_contacts') }
];

export const DEFAULT_SEND_BULK_EMAIL_OPTIONS = {
    id: 'null',
    value: 'null',
    label: i18n.t('smartView:choose_a_template')
};

export const DEFAULT_SELECT_OPTIONS = {
    id: 'null',
    value: 'null',
    label: i18n.t('smartView:choose_an_option')
};

export const SMART_VIEW_ACTIONS = {
    SORT_SET_SINGLE: 'SORT_SET_SINGLE'
};

export const EXCEPT_OPTIONS_CALL_PERMISSION = ['latest_outgoing_call_date', 'latest_incoming_call_date'];
export const EXCEPT_OPTIONS_SMS_PERMISSION = ['latest_sms_message', 'latest_sms_date', '#_sms_messages'];

export const SORT_OPTIONS = [
    {
        objectType: OBJECT_TYPES.CUSTOMER,
        title: i18n.t('smartView:customers'),
        options: [
            { value: 'name', label: i18n.t('smartView:name') },
            { value: 'account_no', label: i18n.t('smartView:account_no') },
            { value: 'company', label: i18n.t('smartView:company') },
            { value: 'created_at', label: i18n.t('smartView:created_date') },
            { value: 'balance', label: i18n.t('smartView:balance') }
        ]
    }
];

export const FILTERS_LIST = [
    {
        id: OBJECT_TYPES.CUSTOMER,
        title: i18n.t('smartView:customers'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.CUSTOMER,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'status' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'created_date' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'source_id' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'count_phone_number' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'count_email_address' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'customer_tag' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'location_tag' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'city' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'zip' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'items' },
                    { objectType: OBJECT_TYPES.CUSTOMER, fieldName: 'item_date' }
                ])
            },
            {
                title: i18n.t('smartView:custom_fields'),
                objectType: OBJECT_TYPES.CUSTOM_FIELD,
                fields: []
            }
        ]
    },
    {
        id: OBJECT_TYPES.OPPORTUNITY,
        title: i18n.t('smartView:opportunities'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.OPPORTUNITY,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'count_opportunity' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'count_active_opportunity' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'count_won_opportunity' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'count_lost_opportunity' }
                ])
            },
            {
                title: i18n.t('smartView:find_opportunities_where...'),
                objectType: OBJECT_TYPES.OPPORTUNITY,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'opportunity_status' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'status' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'user_id' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'created_by' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'close_date' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'confidence' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'value' },
                    { objectType: OBJECT_TYPES.OPPORTUNITY, fieldName: 'period' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.TASK,
        title: i18n.t('smartView:tasks'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.TASK,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.TASK, fieldName: 'count_open_task' },
                    { objectType: OBJECT_TYPES.TASK, fieldName: 'count_completed_task' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.EMAIL,
        title: i18n.t('smartView:emails'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.EMAIL,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.EMAIL, fieldName: 'count_email' },
                    { objectType: OBJECT_TYPES.EMAIL, fieldName: 'count_sent_email' },
                    { objectType: OBJECT_TYPES.EMAIL, fieldName: 'count_received_email' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.SMS,
        title: i18n.t('smartView:sms'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.SMS,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.SMS, fieldName: 'count_sms' },
                    { objectType: OBJECT_TYPES.SMS, fieldName: 'count_sent_sms' },
                    { objectType: OBJECT_TYPES.SMS, fieldName: 'count_received_sms' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.CALL,
        title: i18n.t('smartView:calls'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.CALL,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'count_call' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'count_outgoing_call' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'count_incoming_call' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'count_missed_call' }
                ])
            },
            {
                title: 'Find Calls where…',
                objectType: OBJECT_TYPES.CALL,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'created_date' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'direction' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'duration' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'status' }
                ])
            },
            {
                title: 'Find the latest Call where…',
                objectType: OBJECT_TYPES.CALL,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'latest_call_created_date' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'incoming_latest_call_date' },
                    { objectType: OBJECT_TYPES.CALL, fieldName: 'outgoing_latest_call_date' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.JOB,
        title: i18n.t('smartView:jobs'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.JOB,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_active_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_unconfirmed_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_confirmed_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_reschedule_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_pending_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_completed_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'count_canceled_job' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'service_id' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'status' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'date' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'created_date' },
                    { objectType: OBJECT_TYPES.JOB, fieldName: 'completed_job_by_tech' }
                ])
            }
        ]
    },
    {
        id: OBJECT_TYPES.ESTIMATE,
        title: i18n.t('smartView:estimates'),
        subcategories: [
            {
                title: i18n.t('smartView:find_customer_where...'),
                objectType: OBJECT_TYPES.ESTIMATE,
                fields: parseToListOfFilters([
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_draft_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_pending_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_won_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_invoiced_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'count_lost_estimate' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'status' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'value' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'created_by' },
                    { objectType: OBJECT_TYPES.ESTIMATE, fieldName: 'created_date' }
                ])
            }
        ]
    }
];
