import React, { useEffect, useRef, useReducer, useContext } from 'react';
import { SketchPicker } from 'react-color';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import 'app/modules/settings/service/sketchPicker.css';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import { reducer } from 'app/const/Reducer';

export default function ServiceColor() {
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);
    const finalColor = repeatSettings.color_code;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_select_email_to');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_select_email_to');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleChangeColor(colorResult) {
        onChangeDataRepeatContext({
            color_code: colorResult.hex
        });
    }

    return (
        <div className="rows" ref={refDropdown}>
            <div className="txt">
                <div className="color-code">
                    <div className="color" style={{ backgroundColor: finalColor }} />
                </div>
                <span className="txt-ellipsis">Color</span>
            </div>
            <div className="details details-color" id="show_list_select_email_to">
                <input
                    readOnly
                    className="field-input"
                    type="text"
                    value={finalColor}
                    onClick={_handleOpen}
                    style={{ color: finalColor }}
                />
                {finalIsVisible && (
                    <div className="choose-color">
                        <div className={`color-picker ${finalIsVisible && 'color-picker__inactive'}`}>
                            <SketchPicker disableAlpha color={finalColor} onChange={_handleChangeColor} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
