import React, { useReducer, useRef, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_SMS_ACTIVE } from 'app/const/Api';
import { activeSmsCharge } from 'common/redux/actions/authAction';
import ButtonSave from 'app/components/button/ButtonSave';
import classNames from 'classnames';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { SETTINGS_PLANS } from 'app/const/Route';
import { useHistory } from 'react-router-dom';
import { ADDONS_VOIP } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';

function SmsChargeFailed() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation(['notifications']);
    const auth = useSelector((state) => state.auth);
    const user = auth?.user;
    const { sms, payment_failed: paymentFailed, voip_payment_failed: voipFailed } = user.notifications;
    const smsNumber = sms?.sms_numbers;

    const LIST_FAILED = [
        {
            id: 'sms',
            title: t('notifications:sms_charge_failed', { sms_numbers: smsNumber }),
            desc: t('notifications:uh_oh_your_card_on_file_failed_to_renew_your_sms_pack'),
            textBtn: t('notifications:use_current_card', {
                account_number: user?.profile?.plan?.account_number
            }),
            onUpdate: () => {
                clientQuery(ADDONS_SMS_ACTIVE, { method: 'PUT' }, _success, _failed);
            }
        },
        {
            id: 'payment',
            title: t('notifications:gorillaDesk_subscription_failed'),
            desc: t('notifications:message_payment_charge_failed'),
            textBtn: t('notifications:update_subscription'),
            onUpdate: () => {
                _handleClose(true);
                history.replace(addBranchPath(SETTINGS_PLANS));
            }
        },
        {
            id: 'voip',
            title: t('notifications:voip_payment_failed'),
            desc: t('notifications:uh_oh_failed_voip'),
            textBtn: t('notifications:update_subscription'),
            onUpdate: () => {
                _handleClose(true);
                history.replace({ pathname: addBranchPath(ADDONS_VOIP), state: { openManage: true } });
            }
        }
    ];

    const [state, dispatchState] = useReducer(reducer, {
        isActive: false,
        showSmsChargeFail: false,
        smsAmount: 0,
        messageChargeFail: '',
        isSmsFailed: !!sms,
        listFailed: [!!sms, paymentFailed, voipFailed]
    });
    const { isActive, showSmsChargeFail, messageChargeFail, isSmsFailed, listFailed } = state;
    const failedIndex = listFailed?.findIndex((item) => !!item);

    const refButtonSave = useRef(null);

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, showSmsChargeFail: prev.listFailed.some((item) => !!item) }));
    }, []);

    const _handleClose = (isCloseAll = false) => {
        dispatchState((prev) => {
            const newList = [...prev.listFailed];
            newList[failedIndex] = false;

            return {
                ...prev,
                isSmsFailed: false,
                showSmsChargeFail: isCloseAll ? false : newList.some((item) => !!item),
                listFailed: newList
            };
        });
        return;
    };

    const _success = (res) => {
        dispatch(activeSmsCharge());
        dispatchState({ isActive: true, smsAmount: res.data.sms_amount });
    };

    const _failed = (res) => {
        refButtonSave.current.removeLoading();
        dispatchState({ messageChargeFail: res.message });
    };

    const _renderModalFailed = () => {
        if (failedIndex < 0 && !messageChargeFail) return null;

        const { title, desc, textBtn, onUpdate = () => {} } = LIST_FAILED[failedIndex] || {};

        return (
            <Fragment>
                <div className="header-modal">
                    <div className="header-modal__label">{!!messageChargeFail ? t('notifications:uh_oh') : title}</div>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={() => _handleClose()}>
                        <IconClose />
                    </div>
                </div>

                <div
                    className={classNames('body-modal elm-parent-box', {
                        'has-message': isActive
                    })}
                >
                    <div className="content-form --info">
                        <div className="inform">{messageChargeFail || desc}</div>
                        {!messageChargeFail && (
                            <div className="retry">{t('notifications:please_retry_processing')}:</div>
                        )}
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main current-card js-charge"
                            onSave={onUpdate}
                            title={textBtn}
                            isNotLoading={!isSmsFailed}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };

    return (
        showSmsChargeFail && (
            <div id="smsChargeFailed" className="modal container-modal modal-sms-charge-failed open">
                <div className="modal__container">
                    {!isActive ? (
                        _renderModalFailed()
                    ) : (
                        <>
                            <div className="header-modal">
                                <h3 className="header-modal__label">{t('notifications:success')}</h3>
                                <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                                    <IconClose />
                                </div>
                            </div>

                            <div
                                className={classNames('body-modal elm-parent-box', {
                                    'has-message': isActive
                                })}
                            >
                                <div className="content-form --success">
                                    <div className="retry black pb-6">
                                        {t('notifications:your_pack_has_been_successfully_renewed')} <br />
                                        {t('notifications:sms_locked_and_loaded', { sms_numbers: state.smsAmount })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        )
    );
}

export default SmsChargeFailed;
