import store from 'common/redux/store/configureStore';
import { TYPE_USER_SETTING } from '../users/const';

export const checkCrewUser = (type) => {
    return type === TYPE_USER_SETTING.CREW.id;
};

export const getCrewUser = ({ crewId = '' }) => {
    const crew = store.getState().companyUsers.crew;
    return crew.users[crewId] || null;
};

export const convertCrewUser = ({ group = [], data = [] }) => {
    const unique = new Set();
    const users = data.reduce((prev, cur) => {
        const crewId = cur.id;
        prev[crewId] = { ...cur, groups: [] };
        unique.add(crewId);
        return prev;
    }, {});

    const finalGroup = group.reduce((prev, cur) => {
        const { name: groupName, crew_ids } = cur;
        crew_ids.forEach((id) => {
            unique.delete(id.toString());
            users[id].groups.push(groupName);
        });
        prev[groupName] = cur;
        return prev;
    }, {});

    return {
        group: finalGroup,
        users,
        withOutGroup: Array.from(unique),
        refresh: 0
    };
};

export const convertUpdateCrewUser = ({ crew, data }) => {
    const { group: groupState, users: crewState, withOutGroup } = crew;
    const userData = data.user;
    const userGroupData = userData.groups;
    const lengthCurGroup = userGroupData.length;
    const crewId = userData.id;
    const prevCrewGroup = crewState[crewId]?.groups || [];
    const groupExist = new Set(prevCrewGroup);

    // Add crew to group
    for (let i = 0; i < lengthCurGroup; i++) {
        const tag = userGroupData[i];
        // refetch users when doesn't exist
        if (!groupState[tag]) return { ...crew, refresh: Date.now() };
        // continue on available
        if (groupExist.has(tag)) {
            groupExist.delete(tag);
            continue;
        }
        groupState[tag].crew_ids.push(crewId);
    }

    // delete crew when removed from group
    Array.from(groupExist).forEach((item) => {
        groupState[item].crew_ids = groupState[item].crew_ids.filter((user) => user !== crewId);
        if (!groupState[item].crew_ids.length) delete groupState[item];
    });

    // check crew with out group
    let finalWithOut = [...withOutGroup];
    if (!!lengthCurGroup !== !!prevCrewGroup.length || !crewState[crewId]) {
        finalWithOut = !!lengthCurGroup ? finalWithOut.filter((item) => item !== crewId) : [...finalWithOut, crewId];
    }

    crewState[crewId] = userData;

    return {
        ...crew,
        group: groupState,
        users: crewState,
        withOutGroup: finalWithOut
    };
};
