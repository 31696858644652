/* eslint-disable no-unused-vars */
import React, { useReducer, useRef } from 'react';
import ListAccount from 'app/modules/addons/quickbooks/tabs/discount/Account';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTIONN_UPDATE_QUICKBOOKS_ADDON } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';

export default function SalesProductIncome({ acc_income, onUpdate, account, isFetched }) {
    const parentAccountIncome = acc_income.toString();

    const [state, dispatchState] = useReducer(reducer, {
        incomeSelected: parentAccountIncome
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);

    const finalIncomeSelected = state.incomeSelected;

    function _handleSelectAccountBank(accountId) {
        dispatchState({
            incomeSelected: accountId
        });
    }

    function _checkDisable() {
        if (finalIncomeSelected && finalIncomeSelected !== parentAccountIncome) {
            return false;
        }
        return true;
    }

    function _handleSave() {
        clientQuery(
            ACTIONN_UPDATE_QUICKBOOKS_ADDON,
            {
                method: 'PUT',
                data: {
                    is_income_account: 1,
                    acc_income: finalIncomeSelected
                }
            },
            _handleSaveSuccess,
            _handleSaveFailed
        );
    }

    function _handleSaveSuccess() {
        onUpdate(finalIncomeSelected);
        _removeButtonLoading();
        refStatusBar.current.showStatusBar('show_error', 'Update successfully', LIST_STATUS.SUCCESS);
    }

    function _handleSaveFailed(response) {
        _removeButtonLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || 'Please try again',
            LIST_STATUS.ERROR
        );
    }

    function _removeButtonLoading() {
        refButtonSave.current.removeLoading();
    }

    return (
        <div className={`boxs boxs-has-footer ${finalIncomeSelected ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="title">Sales of Product Income</div>
            </div>
            <div className="boxs__contents ">
                <StatusBar ref={refStatusBar} />
                <span className="required txt-ellipsis">QuickBooks account: Income</span>
                <ListAccount
                    onSelect={_handleSelectAccountBank}
                    accountSelected={finalIncomeSelected}
                    account={account}
                    isFetched={isFetched}
                />
            </div>
            <div className="boxs__footer mt-5">
                <ButtonSave disabled={_checkDisable()} ref={refButtonSave} onSave={_handleSave} />
            </div>
        </div>
    );
}
