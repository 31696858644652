import { LIST_ACTION_BASIC, KEY_NAME_EDIT_CONTENT } from './index';

import IconBold from 'assets/icon/IconBold';
import IconItalic from 'assets/icon/IconItalic';
import IconUnOrderList from 'assets/icon/IconUnOrderList';
import IconOrderList from 'assets/icon/IconOrderList';
import CustomInlineOptions from 'app/components/richtext/components/CustomInlineOptions';
import CustomLinkOption from 'app/components/richtext/components/CustomLinkOption';
import CustomListOptions from 'app/components/richtext/components/CustomListOptions';
import CustomImageOption from 'app/components/richtext/components/CustomImageOption';

export const LIST_ACTIONS = [
    {
        id: 2,
        name: 'duplicate',
        value: LIST_ACTION_BASIC.DUPLICATE
    },
    {
        id: 3,
        name: 'remove',
        value: LIST_ACTION_BASIC.DELETE
    }
];

const TITLE_EMAIL_DEFAULT = '';

const CONTENT_EMAIL_DEFAULT = '<p></p>';

const CONTENT_SMS_DEFAULT = '<p></p>';

export const DEFAULT_CONTENT = {
    [KEY_NAME_EDIT_CONTENT.SUBJECT]: TITLE_EMAIL_DEFAULT,
    [KEY_NAME_EDIT_CONTENT.EMAIL]: CONTENT_EMAIL_DEFAULT,
    [KEY_NAME_EDIT_CONTENT.SMS]: CONTENT_SMS_DEFAULT
};

export const LIST_INLINE_STYLE = [
    { id: 1, name: 'bold', value: 'BOLD', component: IconBold(24, 24, '#595F6B'), tooltip: 'bold' },
    { id: 2, name: 'italic', value: 'ITALIC', component: IconItalic(24, 24, '#595F6B'), tooltip: 'italic' }
];

export const LIST_ORDER_STYLE = [
    { id: 1, name: 'unordered', value: 'unordered', component: IconUnOrderList(), tooltip: 'unordered' },
    { id: 2, name: 'ordered', value: 'ordered', component: IconOrderList(), tooltip: 'ordered' }
];

export const CONFIG_TOOLBAR_MESSAGE_TEMPLATE = {
    options: ['inline', 'link', 'list', 'image'],
    inline: {
        component: (props) => <CustomInlineOptions {...props} options={LIST_INLINE_STYLE} />
    },
    link: {
        component: (props) => <CustomLinkOption {...props} />
    },
    list: {
        component: (props) => <CustomListOptions {...props} options={LIST_ORDER_STYLE} />
    },
    image: {
        component: (props) => <CustomImageOption {...props} />,
        alignmentEnabled: false
    }
};

export const STATUS_CONTENT_TEMPLATE = {
    ENABLED: 1,
    DISABLED: 0
};
