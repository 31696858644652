import { call, put } from 'redux-saga/effects';
import { CALENDAR_GET_SCHEDULES } from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { TYPE_SCHEDULE_ACTION } from 'common/redux/actions/calendar/scheduleAction';
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';

export function* actionGetListSchedule({ actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, CALENDAR_GET_SCHEDULES);
        if (response && response.success) {
            const searchParams = new URLSearchParams(window.location.search);
            const schedulesQuery = searchParams.get('schedules');
            const schedulesUrl = !!schedulesQuery ? schedulesQuery.split(',') : [];

            const {
                data: { schedules: dataSchedule }
            } = response;

            yield put({
                type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_SUCCESS,
                payload: response
            });

            yield put({
                type: CALENDAR_ACTIONS.UPDATE_SCHEDULES,
                payload: !!schedulesUrl.length ? dataSchedule.filter((item) => schedulesUrl.includes(item.id)) : []
            });

            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
