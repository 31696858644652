import loadable from '@loadable/component';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import GDStatusBar from 'app/components/status/statusbar';
import { REPORT_NEW_JOB_GET_LIST } from 'app/const/Api';
import { COMMON, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { INVOICE_NUMBER, SERVICE_TYPE } from 'app/const/Reports';
import { URL_EXPORT_NEW_JOB } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/NewJob';
import { NEW_JOB_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { convertParamsJobStatus } from 'common/utils/JobStatusUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { createRowTotal, getLocalParamsReport, getTitleRowTotal } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportNewJob() {
    const { t } = useTranslation('report');
    const dispatch = useDispatch();

    const finalTypeReport = REPORT_TYPE.NEW_JOB;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', finalTypeReport);
    const paramsReport = getLocalParamsReport(keyLocal, finalTypeReport);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        totalNewJob: null,
        rowTotal: [],
        isLoading: true,
        refreshScreen: 0
    });
    const {
        isLoading: finalIsLoading,
        data: finalData,
        totalNewJob: finalTotalNewJob,
        rowTotal: finalRowTotal,
        refreshScreen: finalRefreshScreen
    } = dataReport;
    const finalTotalNewJobCount = finalTotalNewJob?.count || 0;
    const gridColumns = getGridColumns(paramsReport.columns, paramsReport.order);

    const refStatusBar = useRef(null);
    const abortController = useRef(null);

    useEffect(() => {
        getListReport(paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const getListReport = (params) => {
        dispatchActionReport((prev) => ({ ...prev, isLoading: true }));
        refStatusBar.current?.clearAllStatusBar();

        handleAbortController(abortController);
        abortController.current = new AbortController();

        const columns = params.columns;
        params.service = params.service.toString();
        params.job_status = convertParamsJobStatus(params.job_status).toString();
        params.assigned_to = params.assigned_to.toString();

        delete params.columns;
        delete params.currentPage;
        delete params.sign_status;

        clientQuery(
            REPORT_NEW_JOB_GET_LIST,
            { data: params, method: 'GET', abortController: abortController.current },
            (response) => _getListSuccess({ ...response, columns }),
            _getListFailed
        );
    };

    const _getListSuccess = ({ data = [], total = {}, columns }) => {
        const totalNewJobCount = total?.count || 0;
        dispatchActionReport((prev) => ({
            ...prev,
            data,
            isLoading: false,
            totalNewJob: total,
            rowTotal: _handleCreateRowTotal({ data: total, columns, total: totalNewJobCount }),
            refreshScreen: prev.refreshScreen + 1
        }));
    };

    const _getListFailed = ({ isAborted = false }) => {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false }));
    };

    const _handleChangeFilter = ({ columns = [] }, mode) => {
        if (mode && mode === COMMON.COLUMNS) {
            dispatchActionReport((prev) => {
                const totalNewJob = prev.totalNewJob;
                return {
                    ...prev,
                    rowTotal: _handleCreateRowTotal({ data: totalNewJob, columns, total: totalNewJob.count })
                };
            });
        }
    };

    const _handleCreateRowTotal = ({ data = {}, columns = [], total = 0 }) => {
        return createRowTotal({
            data,
            columns: ['customer', ...columns],
            title: getTitleRowTotal({ title: 'new_job', total }),
            typeReport: finalTypeReport
        });
    };

    const _handleUpdate = () => {
        getListReport(getLocalStorage(keyLocal));
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: finalTypeReport,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _renderHeaderRight = () => {
        return (
            <Export
                title={t('records', { count: finalTotalNewJobCount })}
                activePrint
                url={URL_EXPORT_NEW_JOB}
                params={paramsReport}
                pageExport={LIST_EXPORT.EXPORT_NEW_JOB}
                isDisable={finalIsLoading}
                refresh={finalRefreshScreen}
            />
        );
    };

    const _handleOpenDetail = ({ key, row }) => {
        switch (key) {
            case SERVICE_TYPE:
                dispatch(actionOpenJobDetail({ id: row.id }));
                break;
            case INVOICE_NUMBER:
                dispatch(actionOpenInvoice({ id: row.invoice_id, status: '', total: '', isRecurring: false }));
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <MainHeaderReport contentRight={_renderHeaderRight} reportType={finalTypeReport} />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refStatusBar} />
                    <HeaderBottom
                        typeReport={finalTypeReport}
                        filters={NEW_JOB_LIST_FILTER}
                        handleChangeFilter={_handleChangeFilter}
                        handleUpdate={_handleUpdate}
                        isLoading={finalIsLoading}
                    />
                    <div className="wrap-tables flex-column relative">
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData}
                            fileTranslation="report"
                            {...gridColumns}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={finalRowTotal}
                                    isLoading={finalIsLoading}
                                    contentConfig={gridColumns.contentConfig}
                                    {...props}
                                />
                            )}
                            handleClick={_handleOpenDetail}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ReportNewJob;
