import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-item__content flex-column gap-6 tasks-list">
            <div className="box-task box-opportunity border-none pl-1">
                <div className="title mb-2">
                    <div className="avt-img ml-0" />
                    <div className="title__info">
                        <div className="name">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="time">
                            <div className="loading --animation --line --threefourth mt-1" />
                        </div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                </div>
                <div className="ml-2">
                    <div className="valid-phone">
                        <div className="valid-phone__btn loading --twothird" />
                    </div>
                </div>
                <div className="contact flex-betweenitems my-3">
                    <div className="loading --animation --line --twothird" />
                    <div className="d-flex gap-4">
                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                        <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                    </div>
                </div>
                <div className="flex-column gap-4">
                    <div className="loading --animation --line --full" />
                    <div className="loading --animation --line --half" />
                </div>
            </div>
        </div>
    );
};

export default Loading;
