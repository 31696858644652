export default ({ isHasColor = false }) => {
    if (isHasColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.33361 2.91871C9.53987 3.18578 10.5034 4.18682 10.6626 5.48297C10.8217 6.77911 10.129 7.98352 9.02314 8.53448L9.02314 10L7 10L7.03728 8.77867C5.83054 8.51193 4.86649 7.5107 4.7073 6.21418C4.5481 4.91767 5.2413 3.71294 6.34767 3.1622L6.57052 4.9779C6.63783 5.52606 7.13677 5.91588 7.68493 5.84857C8.19395 5.78607 8.56642 5.3514 8.56315 4.85073L8.55561 4.73416L8.33361 2.91871Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M19 10H5C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H19C19.5523 12 20 11.5523 20 11C20 10.4477 19.5523 10 19 10Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 12H19V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V12Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 9V9.772L16.2441 9.59578L18.3013 12.4273C18.7882 13.0976 18.6397 14.0356 17.9695 14.5225C17.353 14.9704 16.5099 14.8806 15.9997 14.3418L16 14.5C16 15.8807 14.8807 17 13.5 17C12.1193 17 11 15.8807 11 14.5V9H16Z"
                    fill="#8D4AFC"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33361 2.91871C9.53987 3.18578 10.5034 4.18682 10.6626 5.48297C10.8217 6.77911 10.129 7.98352 9.02314 8.53448L9.02314 10L7 10L7.03728 8.77867C5.83054 8.51193 4.86649 7.5107 4.7073 6.21418C4.5481 4.91767 5.2413 3.71294 6.34767 3.1622L6.57052 4.9779C6.63783 5.52606 7.13677 5.91588 7.68493 5.84857C8.19395 5.78607 8.56642 5.3514 8.56315 4.85073L8.55561 4.73416L8.33361 2.91871Z"
                fill="#7A83A6"
            ></path>
            <path
                d="M19 10H5C4.44772 10 4 10.4477 4 11C4 11.5523 4.44772 12 5 12H19C19.5523 12 20 11.5523 20 11C20 10.4477 19.5523 10 19 10Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 12H19V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V12Z"
                fill="#C1C9D4"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 9V9.772L16.2441 9.59578L18.3013 12.4273C18.7882 13.0976 18.6397 14.0356 17.9695 14.5225C17.353 14.9704 16.5099 14.8806 15.9997 14.3418L16 14.5C16 15.8807 14.8807 17 13.5 17C12.1193 17 11 15.8807 11 14.5V9H16Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
};
