export default function IconCalendar({
    isGoogle = false,
    isHasDollar = false,
    isDollarActive = false,
    isMenu = false,
    isMenuActive = false,
    isActiveColor = false,
    isHasCard = false,
    isAddEvent = false,
    isReSchedule = false
}) {
    if (isReSchedule)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
                    fill="#FF6E26"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 10H18C18.5523 10 19 10.4477 19 11V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10Z"
                    fill="#FF6E26"
                    fillOpacity="0.6"
                />
            </svg>
        );
    if (isAddEvent)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 10H18C18.5523 10 19 10.4477 19 11V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10Z"
                    fill="#DAC2F2"
                />
            </svg>
        );
    if (isGoogle) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 4.5H17.5C18.6046 4.5 19.5 5.39543 19.5 6.5V17.5C19.5 18.6046 18.6046 19.5 17.5 19.5H6.5C5.39543 19.5 4.5 18.6046 4.5 17.5V6.5C4.5 5.39543 5.39543 4.5 6.5 4.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.68045 7.5H19.3195C19.8718 7.5 20.3195 7.94772 20.3195 8.5C20.3195 8.55508 20.315 8.61007 20.3059 8.6644L19.5 13.5V17.5C19.5 18.6046 18.6046 19.5 17.5 19.5H6.49999C5.39542 19.5 4.49999 18.6046 4.49999 17.5V13.5L3.69406 8.6644C3.60326 8.11963 3.97128 7.6044 4.51605 7.51361C4.57039 7.50455 4.62537 7.5 4.68045 7.5Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.68045 7.5H19.3195C19.8718 7.5 20.3195 7.94772 20.3195 8.5C20.3195 8.55508 20.315 8.61007 20.3059 8.6644L19.5 13.5V17.5C19.5 18.6046 18.6046 19.5 17.5 19.5H6.49999C5.39542 19.5 4.49999 18.6046 4.49999 17.5V13.5L3.69406 8.6644C3.60326 8.11963 3.97128 7.6044 4.51605 7.51361C4.57039 7.50455 4.62537 7.5 4.68045 7.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 12.5H19.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.5 16.5V9.5L12.5 10.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5 16.5V9.5L8.5 10.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isHasDollar) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 3C14.5523 3 15 3.33579 15 3.75V5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H7V3.75C7 3.36765 7.38149 3.05212 7.87456 3.00584L8 3C8.55228 3 9 3.33579 9 3.75V5H13V3.75C13 3.36765 13.3815 3.05212 13.8746 3.00584L14 3Z"
                    fill="#7A83A6"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 8C17.5523 8 18 8.44772 18 9L18.0013 9.25562C16.8672 8.46418 15.4878 8 14 8C10.134 8 7 11.134 7 15C7 15.3399 7.02422 15.6741 7.07103 16.001L6 16C4.89543 16 4 15.1046 4 14V9C4 8.44772 4.44772 8 5 8H17Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 9C17.3137 9 20 11.6863 20 15C20 18.3137 17.3137 21 14 21C10.6863 21 8 18.3137 8 15C8 11.6863 10.6863 9 14 9ZM15.1136 10.5H13.206L13.191 11.314L13.081 11.3379L12.8978 11.3862C11.697 11.7361 10.912 12.6352 10.9219 13.8167L10.927 13.9907L10.9484 14.1948C11.0277 14.7266 11.2826 15.1578 11.6898 15.488L11.787 15.562L10.7535 15.5625L10.7557 16.0647L10.7611 16.2565L10.7775 16.4603C10.9155 17.7276 11.7023 18.5411 12.9635 18.826L13.075 18.848L13.0612 19.6818H14.9689L14.982 18.865L15.1012 18.8436L15.2875 18.8005C16.5055 18.4874 17.2335 17.6551 17.24 16.4359L17.2338 16.261L17.2158 16.0788C17.12 15.3635 16.7424 14.8061 16.0928 14.4178L15.996 14.363L17.1534 14.3636L17.1506 13.8608L17.1446 13.6878L17.1264 13.5008C16.9894 12.4568 16.2786 11.6859 15.1993 11.3766L15.099 11.35L15.1136 10.5Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4773 19.1818L14.49 18.4244C15.9027 18.3157 16.7337 17.6158 16.74 16.4332C16.7337 15.4361 16.1392 14.7361 14.6946 14.4261L14.5508 14.3942L14.5699 13.2788C14.8065 13.3874 14.9279 13.5888 14.9503 13.8636H16.6506C16.6442 12.7259 15.8707 11.93 14.5923 11.7607L14.6051 11H13.6974L13.6847 11.7447C12.3359 11.8725 11.4123 12.6715 11.4219 13.8125C11.4187 14.8352 12.1282 15.4041 13.2883 15.6534L13.6239 15.7269L13.6048 16.9254C13.1989 16.8072 12.9655 16.5163 12.9432 16.0625H11.2557C11.2621 17.5391 12.1601 18.2837 13.5824 18.418L13.5696 19.1818H14.4773ZM13.6495 14.1577C13.3842 14.0426 13.266 13.8924 13.2756 13.6847C13.2788 13.4801 13.397 13.3171 13.6623 13.2372L13.6495 14.1577ZM14.5124 16.919L14.5284 15.9698C14.8097 16.0913 14.9215 16.2383 14.9247 16.4332C14.9215 16.6538 14.7809 16.8295 14.5124 16.919Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    }
    if (isDollarActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 3C14.5523 3 15 3.33579 15 3.75V5H17C17.5523 5 18 5.44772 18 6C18 6.55228 17.5523 7 17 7H5C4.44772 7 4 6.55228 4 6C4 5.44772 4.44772 5 5 5H7V3.75C7 3.36765 7.38149 3.05212 7.87456 3.00584L8 3C8.55228 3 9 3.33579 9 3.75V5H13V3.75C13 3.36765 13.3815 3.05212 13.8746 3.00584L14 3Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 8C17.5523 8 18 8.44772 18 9L18.0013 9.25562C16.8672 8.46418 15.4878 8 14 8C10.134 8 7 11.134 7 15C7 15.3399 7.02422 15.6741 7.07103 16.001L6 16C4.89543 16 4 15.1046 4 14V9C4 8.44772 4.44772 8 5 8H17Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 9C17.3137 9 20 11.6863 20 15C20 18.3137 17.3137 21 14 21C10.6863 21 8 18.3137 8 15C8 11.6863 10.6863 9 14 9ZM15.1136 10.5H13.206L13.191 11.314L13.081 11.3379L12.8978 11.3862C11.697 11.7361 10.912 12.6352 10.9219 13.8167L10.927 13.9907L10.9484 14.1948C11.0277 14.7266 11.2826 15.1578 11.6898 15.488L11.787 15.562L10.7535 15.5625L10.7557 16.0647L10.7611 16.2565L10.7775 16.4603C10.9155 17.7276 11.7023 18.5411 12.9635 18.826L13.075 18.848L13.0612 19.6818H14.9689L14.982 18.865L15.1012 18.8436L15.2875 18.8005C16.5055 18.4874 17.2335 17.6551 17.24 16.4359L17.2338 16.261L17.2158 16.0788C17.12 15.3635 16.7424 14.8061 16.0928 14.4178L15.996 14.363L17.1534 14.3636L17.1506 13.8608L17.1446 13.6878L17.1264 13.5008C16.9894 12.4568 16.2786 11.6859 15.1993 11.3766L15.099 11.35L15.1136 10.5Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4773 19.1818L14.49 18.4244C15.9027 18.3157 16.7337 17.6158 16.74 16.4332C16.7337 15.4361 16.1392 14.7361 14.6946 14.4261L14.5508 14.3942L14.5699 13.2788C14.8065 13.3874 14.9279 13.5888 14.9503 13.8636H16.6506C16.6442 12.7259 15.8707 11.93 14.5923 11.7607L14.6051 11H13.6974L13.6847 11.7447C12.3359 11.8725 11.4123 12.6715 11.4219 13.8125C11.4187 14.8352 12.1282 15.4041 13.2883 15.6534L13.6239 15.7269L13.6048 16.9254C13.1989 16.8072 12.9655 16.5163 12.9432 16.0625H11.2557C11.2621 17.5391 12.1601 18.2837 13.5824 18.418L13.5696 19.1818H14.4773ZM13.6495 14.1577C13.3842 14.0426 13.266 13.8924 13.2756 13.6847C13.2788 13.4801 13.397 13.3171 13.6623 13.2372L13.6495 14.1577ZM14.5124 16.919L14.5284 15.9698C14.8097 16.0913 14.9215 16.2383 14.9247 16.4332C14.9215 16.6538 14.7809 16.8295 14.5124 16.919Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    }
    if (isMenu) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#EEF4FE"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15H24V21C24 22.6569 22.6569 24 21 24H11C9.34315 24 8 22.6569 8 21V15Z"
                    fill="#2477F1"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 7C13.5523 7 14 7.44772 14 8V9H18V8C18 7.44772 18.4477 7 19 7H20C20.5523 7 21 7.44772 21 8V9C22.6569 9 24 10.3431 24 12V13H8V12C8 10.3431 9.34315 9 11 9V8C11 7.44772 11.4477 7 12 7H13Z"
                    fill="#2477F1"
                />
            </svg>
        );
    }
    if (isMenuActive)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#2477F1"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15H24V21C24 22.6569 22.6569 24 21 24H11C9.34315 24 8 22.6569 8 21V15Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 7C13.5523 7 14 7.44772 14 8V9H18V8C18 7.44772 18.4477 7 19 7H20C20.5523 7 21 7.44772 21 8V9C22.6569 9 24 10.3431 24 12V13H8V12C8 10.3431 9.34315 9 11 9V8C11 7.44772 11.4477 7 12 7H13Z"
                    fill="white"
                />
            </svg>
        );
    if (isActiveColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
                    fill="#8D4AFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 10H18C18.5523 10 19 10.4477 19 11V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10Z"
                    fill="#DAC2F2"
                />
            </svg>
        );
    if (isHasCard)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
                    fill="#7A83A6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 10C18.5523 10 19 10.4477 19 11L19.0011 12.2682C18.7067 12.0976 18.3647 12 18 12H12C10.8954 12 10 12.8954 10 14V17C10 17.3647 10.0976 17.7067 10.2682 18.0011L7 18C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10H18Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21 18V19C21 19.5523 20.5523 20 20 20H12C11.4477 20 11 19.5523 11 19V18H21ZM20.5 16C20.7761 16 21 16.2239 21 16.5C21 16.7761 20.7761 17 20.5 17H11.5C11.2239 17 11 16.7761 11 16.5C11 16.2239 11.2239 16 11.5 16H20.5ZM20 13C20.5523 13 21 13.4477 21 14V15H11V14C11 13.4477 11.4477 13 12 13H20Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 5C15.5523 5 16 5.33579 16 5.75V7H18C18.5523 7 19 7.44772 19 8C19 8.55228 18.5523 9 18 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7H8V5.75C8 5.36765 8.38149 5.05212 8.87456 5.00584L9 5C9.55228 5 10 5.33579 10 5.75V7H14V5.75C14 5.36765 14.3815 5.05212 14.8746 5.00584L15 5Z"
                fill="#7A83A6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 10H18C18.5523 10 19 10.4477 19 11V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V11C5 10.4477 5.44772 10 6 10Z"
                fill="#C1C9D4"
            />
        </svg>
    );
}
