import { ADDONS_VOIP, ADDONS_VOIP_MANAGE_GROUP, ADDONS_VOIP_MANAGE_PERSONAL } from 'app/config/routes';
import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { VOIPItemNumber } from './VOIPItemNumber';
import { reducer } from 'app/const/Reducer';
import {
    ADDONS_VOIP_GROUP,
    ADDONS_VOIP_PERSONAL,
    SOURCE_PHONE_NUMBER_VOIP,
    TYPE_MANAGE_ADDONS_VOIP,
    TYPE_PHONE_VOIP,
    KEY_VOIP
} from 'app/const/addons';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_PHONE_NUMBER, setPrimaryNumberVoip, updateSettingsNumberVoip } from 'app/const/api/Voip';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { ACCOUNT_ROLE, LIST_STATUS } from 'app/const/App';
import ModalWarningDelete from './settings/ModalWarningDelete';
import { VOIPAddonsSettings } from './VOIPAddonsSettings';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import VOIPAddonsVerifyNumber from './VOIPAddonsVerifyNumber';
import { VOIPAddonsLoadingManage } from './loadings/VOIPAddonsLoadingManage';
import { VOIPAddonsAddNumber } from './VOIPAddonsAddNumber';
import VOIPAddonsModalEdit from './VOIPAddonsModalEdit';
import { VOIPAddonsManageProvider } from './VOIPAddonsManageProvider';
import { useSelector } from 'react-redux';
import { actionTriggerLoadNumbers } from 'common/redux/actions/voipAction';
import { useDispatch } from 'react-redux';
import ErrorPage from 'app/modules/error';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import { CALENDAR } from 'app/const/Route';
import queryString from 'query-string';
import { addBranchPath } from 'app/const/Branch';

export const VOIPAddonsManage = () => {
    const DEFAULT_PARAMS = {
        [ADDONS_VOIP_PERSONAL]: {
            title: 'personal_numbers',
            title_modal: 'add_personal_number',
            type: TYPE_MANAGE_ADDONS_VOIP[ADDONS_VOIP_PERSONAL],
            ulrBack: ADDONS_VOIP_MANAGE_PERSONAL
        },
        [ADDONS_VOIP_GROUP]: {
            title: 'group_numbers',
            title_modal: 'add_group_number',
            type: TYPE_MANAGE_ADDONS_VOIP[ADDONS_VOIP_GROUP],
            ulrBack: ADDONS_VOIP_MANAGE_GROUP
        }
    };

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const keyword = location.pathname.split('/')[4] ?? null;
    const { id: idDetail } = queryString.parse(location.search);
    const dispatch = useDispatch();
    const refContent = useRef(null);
    const refAlert = useRef(null);
    const refDelete = useRef(null);
    const refVerifyNumber = useRef(null);
    const refWrapper = useRef(null);

    const { id: userId, role: userRole } = useSelector(({ auth }) => auth.user.profile);
    const isActiveVoip = useSelector(({ auth }) => !!auth?.user?.settings?.voip);
    const addons = useSelector(({ auth }) => auth?.user?.settings?.addons);
    const isHaveVoip = !!addons?.voip;
    const isEnableAddons = isActiveVoip && isHaveVoip;
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    if ((!isEnableAddons && !isSupperAdmin) || !(KEY_VOIP in addons))
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    const permissionsList = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const urlBack = idDetail
        ? DEFAULT_PARAMS[keyword].ulrBack
        : checkPermission(permissionsList, PERMISSIONS.accessSettingsTab)
          ? ADDONS_VOIP
          : CALENDAR;

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isAddNumber: false,
        data: [],
        isActive: true
    });
    const { isLoading, isAddNumber, data, isActive } = state;
    const params = DEFAULT_PARAMS[keyword];
    const { title, title_modal, type } = params;

    useEffect(() => {
        _getManageDetail();
    }, []);

    const _handleClickAddNumber = () => {
        dispatchState({ isAddNumber: true });
    };

    const _renderListItem = () => {
        if (!data.length)
            return (
                <div className="pt-3">
                    <div className="tables-empty m-0 bg-white">{t(`addons:no_${keyword}_number`)}</div>
                </div>
            );

        return data.map((item) => (
            <VOIPItemNumber
                key={item.id}
                item={item}
                keyword={keyword}
                type={type}
                onUpdateOptions={_handleUpdateOptions}
                onChangeNumber={_handleChangeNumber}
                onDeleteNumber={_handleWarningDelete}
                onSetPrimary={_handleSetPrimary}
                onUpdateMember={_handleUpdateMember}
            />
        ));
    };

    const _handleUpdateOptions = ({ id, type, value, callbackOnFail = () => {} }) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === id);
        if (index > -1) {
            const data = { [type]: value ? 1 : 0 };

            const _handleSuccess = (res) => {
                newData[index] = res.data;
                dispatchState((prev) => ({ ...prev, data: newData }));
            };

            const _handleUpdateFail = (err) => {
                _handleFail(err);
                callbackOnFail();
            };

            clientQuery(
                updateSettingsNumberVoip(id),
                { method: 'PUT', toFormData: false, data },
                _handleSuccess,
                _handleUpdateFail
            );
        }
    };

    const _handleUpdateListNumber = (dataItem) => {
        const newData = [...data];
        const index = newData.findIndex((item) => item.id === dataItem.id);
        const status = { message: '', isSuccess: true };
        if (index > -1) {
            newData[index] = dataItem;
            status.message = t('addons:change_number_successfully');
        } else {
            newData.push(dataItem);
            status.message = t('addons:add_new_number_successfully');
        }
        showStatus(status);
        dispatchState((prev) => ({ ...prev, data: newData }));

        dispatch(actionTriggerLoadNumbers());
    };

    const _handleChangeNumber = (item) => {
        refContent.current._handleChangeNumber(item);
    };

    const _handleSetPrimary = (id) => {
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => ({ ...item, is_primary: id === item.id ? true : false }))
        }));

        clientQuery(setPrimaryNumberVoip(id), { method: 'PUT', toFormData: false }, () => {}, _handleFail);
    };

    const _handleWarningDelete = (item) => {
        refDelete.current.onDelete(item);
    };

    const _handleDeleteSuccess = (id) => {
        dispatchState((prev) => ({
            ...prev,
            data: data.filter((item) => item.id !== id)
        }));
    };

    const _handleUpdateMember = (id, assignees) => {
        const _handleSuccess = (res) => {
            dispatchState((prev) => ({
                ...prev,
                data: prev.data.map((item) => (item.id === res.data.id ? res.data : item))
            }));
        };
        clientQuery(
            updateSettingsNumberVoip(id),
            { method: 'PUT', toFormData: false, data: { assignees } },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleUpdateSettings = ({ data }) => {
        const { id: finalId } = data;
        dispatchState((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id === finalId) return data;
                    return item;
                })
            };
        });
        dispatch(actionTriggerLoadNumbers());
    };

    const _handleFail = (err) => {
        showStatus({ message: err.message });
        return false;
    };

    const showStatus = ({ message = '', isSuccess = false }) => {
        refAlert.current.showStatusBar({
            id: 'manage_number',
            message,
            type: isSuccess ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        });
    };

    const _getManageDetail = () => {
        const _handleSuccess = (res) => {
            const resData = [...res.data];
            dispatchState({
                data:
                    isSupperAdmin || type === TYPE_PHONE_VOIP.GROUP
                        ? resData
                        : resData.filter(
                              (item) =>
                                  item.assignees[0] === userId || item.source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL
                          ),
                isLoading: false
            });
        };

        const _handleFailure = (res) => {
            _handleFail(res);
            dispatchState({ isLoading: false, isActive: false });
        };

        clientQuery(ACTION_PHONE_NUMBER, { method: 'GET', data: { type } }, _handleSuccess, _handleFailure);
    };

    const _renderContent = () => {
        if (!!data.length && !!idDetail) {
            const dataItem = data.find((item) => item.id === idDetail) || {};
            const isAccessGroup =
                isSupperAdmin ||
                dataItem.type !== TYPE_PHONE_VOIP.GROUP ||
                (dataItem?.assignees?.includes(userId) && userRole !== ACCOUNT_ROLE.TECHNICIAN) ||
                dataItem.source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL;
            if (isAccessGroup)
                return (
                    <VOIPAddonsSettings
                        item={{ ...dataItem, role: userRole }}
                        refAlert={refAlert}
                        onGoBack={_handleBack}
                        onDeleteSuccess={_handleDeleteSuccess}
                        onUpdateSuccess={_handleUpdateSettings}
                    />
                );
        }

        if (isAddNumber) {
            return (
                <VOIPAddonsAddNumber
                    keyword={keyword}
                    onUpdateListNumber={_handleUpdateListNumber}
                    onShowVerifyNumber={_handleShowVerifyNumber}
                    onClose={_handleBack}
                    listNumber={data}
                />
            );
        }

        return (
            <Fragment>
                <div className="flex-betweenitems">
                    <b className="fw-500">{t(`addons:${title}`)}</b>
                    {isSupperAdmin && isActive ? (
                        <div
                            className="v2-btn-main has-icon svg-white btn-modal header-items"
                            onClick={_handleClickAddNumber}
                        >
                            <IconPlus /> {t('addons:add_number')}
                        </div>
                    ) : null}
                </div>
                {_renderListItem()}
            </Fragment>
        );
    };

    const _handleBack = () => {
        if (isAddNumber) {
            refWrapper.current.scrollTo(0, 0);
            dispatchState({ isAddNumber: false });
            return;
        }
        history.replace(addBranchPath(urlBack));
    };

    const _handleShowVerifyNumber = (data) => {
        refVerifyNumber.current._setData({ ...data, isOpen: true });
    };

    const _handleCloseVerify = () => {
        _handleBack();
    };

    return (
        <Fragment>
            <div className="addons-wrap__container">
                <div className="header">
                    <div className="v2-btn-default has-icon --grey" onClick={_handleBack}>
                        <IconArrow isPrev />
                        {t('back')}
                    </div>
                </div>
                <div ref={refWrapper} className="scrolls">
                    <VOIPAddonsManageProvider>
                        <div className="boxs-wrapper">
                            <AlertCustomer ref={refAlert} />
                            {isLoading ? <VOIPAddonsLoadingManage /> : _renderContent()}
                        </div>
                        <ModalWarningDelete ref={refDelete} onSuccess={_handleDeleteSuccess} onFail={_handleFail} />
                    </VOIPAddonsManageProvider>
                </div>
            </div>
            <VOIPAddonsModalEdit
                id="addnew_number_content"
                ref={refContent}
                typeModal={type}
                titleModal={title_modal}
                onUpdateListNumber={_handleUpdateListNumber}
            />
            <VOIPAddonsVerifyNumber
                ref={refVerifyNumber}
                typeModal={type}
                onAddSuccess={_handleUpdateListNumber}
                onClose={_handleCloseVerify}
            />
        </Fragment>
    );
};
