import loadable from '@loadable/component';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import GDCollapse from 'app/components/collapse';
import { CUSTOMER_LIST_TASKS, CUSTOMER_UPDATE_TASK_STATUS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { TYPE_OPEN_MODAL } from 'app/modules/customer/detail/constant';
import { clientQuery } from 'common/utils/ApiUtils';
import Loading from './Loading';
import Task from './Task';
import TaskItem from './TaskItem';

const ServiceRealtime = loadable(() => import('./ServiceRealtime'));

const BlockTask = forwardRef(
    ({ onConfirm = () => {}, onCloseConfirm = () => {}, onCloseOtherModal = () => {} }, ref) => {
        const { t } = useTranslation('customers');
        const { id: customerId } = useParams();
        const [state, dispatchState] = useReducer(reducer, { tasks: [], isLoading: true });
        const { tasks, isLoading } = state;
        const refPrevIdCus = useRef(null);
        const refCollapse = useRef(null);
        const refTask = useRef(null);
        const refWrapper = useRef(null);
        const refPrevIdButton = useRef(null);

        useImperativeHandle(ref, () => ({ closeModal: () => refTask.current?.close() }));

        useEffect(() => {
            _loadTasks(customerId !== refPrevIdCus.current);
            refPrevIdCus.current = customerId;
        }, [customerId]);

        const _loadTasks = () => {
            const _loadTasksSuccess = ({ data }) => {
                dispatchState((prevState) => ({ ...prevState, tasks: data, isLoading: false }));
            };
            const _loadTasksFail = ({ error }) => {
                console.error('🚀 ~ file: index.js:36 ~ BlockTask ~ error:', error);
                dispatchState((prevState) => ({ ...prevState, isLoading: false }));
            };

            clientQuery(
                CUSTOMER_LIST_TASKS,
                { data: { customer_id: customerId, active: 1, offset: 0, limit: 0 }, method: 'GET' },
                _loadTasksSuccess,
                _loadTasksFail
            );
        };

        const _handleModalTask = (data) => {
            onCloseOtherModal(TYPE_OPEN_MODAL.TASK);
            refTask.current?.open(data);

            if (!!data) {
                _handleSetButtonAdd(false);
                refWrapper.current
                    ?.querySelector(`#button-task-${refPrevIdButton.current}`)
                    ?.classList.remove('is-disable');
                refWrapper.current?.querySelector(`#button-task-${data.id}`)?.classList.add('is-disable');
                refPrevIdButton.current = data.id;
            } else {
                if (refPrevIdButton.current) {
                    refWrapper.current
                        ?.querySelector(`#button-task-${refPrevIdButton.current}`)
                        ?.classList.remove('is-disable');
                }
                _handleSetButtonAdd(true);
            }
        };

        const _handleUpdateRealtime = (tasks = []) => {
            dispatchState((prevState) => ({ ...prevState, tasks }));
        };

        const _handleChangeStatus = (task) => {
            const idTask = task.id;
            dispatchState((prevState) => ({
                ...prevState,
                tasks: prevState.tasks.filter((item) => item.id !== idTask)
            }));
            /* Updating the task status to done. */
            clientQuery(CUSTOMER_UPDATE_TASK_STATUS, {
                method: 'PUT',
                // eslint-disable-next-line no-undef
                data: { active: 0, ids: [idTask], socket_id: global.socket_id_task_service }
            });
        };

        const _handleSetButtonAdd = (isDisable) => {
            if (refPrevIdButton.current) {
                refWrapper.current
                    ?.querySelector(`#button-task-${refPrevIdButton.current}`)
                    ?.classList.remove('is-disable');
            }
            refCollapse.current?.setDisableAdd(isDisable);
        };

        return (
            <>
                <GDCollapse
                    ref={refCollapse}
                    isUp
                    isArrowRight
                    defaultCollapsed
                    title={t('tasks')}
                    className="details-info wrap-item wrap-collapse"
                    classWrapTitle="wrap-item__header flex-betweenitems"
                    classTitle="txt-ellipsis flex-1"
                    onClickAdd={() => _handleModalTask()}
                >
                    {isLoading ? <Loading /> : null}
                    {!isLoading && tasks?.length ? (
                        <div ref={refWrapper} className="wrap-item__content tasks-list">
                            {tasks.map((task) => (
                                <TaskItem
                                    key={task.id}
                                    {...task}
                                    onChangeStatus={() => _handleChangeStatus(task)}
                                    onUpdate={() => _handleModalTask(task)}
                                />
                            ))}
                        </div>
                    ) : null}
                </GDCollapse>

                <Task
                    ref={refTask}
                    onSetButtonAdd={_handleSetButtonAdd}
                    onConfirm={onConfirm}
                    onCloseConfirm={onCloseConfirm}
                />
                {/* Handle realtime is below */}
                <ServiceRealtime customerId={customerId} tasks={tasks} onUpdate={_handleUpdateRealtime} />
            </>
        );
    }
);

export default BlockTask;
