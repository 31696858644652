import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_VOIP } from 'app/config/routes';
import { SETTINGS_PLANS } from 'app/const/Route';
import { LIST_COUNTRY_SHOW_PROMOTION } from 'app/const/addons';
import IconClose from 'assets/icon/IconClose';
import IconUpgrade from 'assets/icon/IconUpgrade';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { DAYS_SHOW_AGAIN_BANNER, KEY_LOCAL_STORAGE_BANNER } from 'app/const/Header';
import { addBranchPath } from 'app/const/Branch';

const AddonHeaderExpand = ({ isProPlan = false, isGrowthPlan = false, onCloseDropdown = () => {} }) => {
    const { t } = useTranslation('addons');
    const {
        settings,
        company: { country }
    } = useSelector(({ auth }) => auth.user);

    const planShowPromotion = isGrowthPlan;
    const isHaveVoipPlan = checkIsEnableVoip(settings, false);
    const isShowPromotion = planShowPromotion && LIST_COUNTRY_SHOW_PROMOTION.includes(country) && !isHaveVoipPlan;
    const params = getLocalStorage(KEY_LOCAL_STORAGE_BANNER) || {};

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const { isPro, isGrowth, isHaveVoip, date } = params;

        const isRemoveLocalWithPlan = isPro !== isProPlan || isGrowth !== isGrowthPlan;
        const isShowAgain = new Date().getTime() - date > DAYS_SHOW_AGAIN_BANNER;
        if (isRemoveLocalWithPlan || isHaveVoip !== isHaveVoipPlan || isShowAgain) {
            removeLocalStorage(KEY_LOCAL_STORAGE_BANNER);
            setIsVisible(true);
            return;
        }
        setIsVisible(isShowAgain);
    }, []);

    const _handleCloseExpandPlan = () => {
        setIsVisible(false);
        setLocalStorage(KEY_LOCAL_STORAGE_BANNER, {
            isPro: isProPlan,
            isGrowth: isGrowthPlan,
            isHaveVoip: isHaveVoipPlan,
            date: new Date().getTime()
        });
    };

    const _renderButtonExpand = ({ url = '' }) => {
        return (
            <Fragment>
                <Link to={addBranchPath(url)} className="v2-btn-main has-icon" onClick={onCloseDropdown}>
                    <IconUpgrade isFillWhite /> {t('expand_your_plan')}
                </Link>
                <button className="close-tab" onClick={_handleCloseExpandPlan}>
                    <IconClose />
                </button>
            </Fragment>
        );
    };

    /* If the user is on the free plan and if the question is visible. If both are true not display. */
    if (!isVisible || (planShowPromotion && !isShowPromotion)) return null;

    if (isShowPromotion)
        return (
            <div className="bg-feauture --promotion">
                <div className="relative p-4 h-100">
                    <div className="title-h1 fs-26">
                        {t('faster_call_handling_with_integrated')}
                        &nbsp;
                        <span className="orange-default">{t('voip_upper')}</span>
                    </div>
                    <p className="mt-2 grey-dark">{t('desc_promotion_voip')}</p>
                    {_renderButtonExpand({ url: ADDONS_VOIP })}
                </div>
            </div>
        );

    if (isProPlan)
        return (
            <div className="bg-feauture --growth">
                <div className="relative p-4 h-100">
                    <div className="title-h1 fs-26">
                        {t('supercharge_sales_with_the')}&nbsp;
                        <span className="green-default">{t('growth_plan')}</span>
                    </div>
                    <p className="mt-2 grey-dark">{t('a_smarter_way_to_manage_your_sales_pipeline_right_inside_gd')}</p>
                    {_renderButtonExpand({ url: SETTINGS_PLANS })}
                </div>
            </div>
        );

    return (
        <div className="bg-feauture">
            <div className="relative p-4 h-100">
                <div className="title-h1 fs-26 white">
                    {t('more_amentities_with')}
                    &nbsp;
                    <span className="orange-default">{t('pro')}</span> {t('features')}
                </div>
                <p className="mt-2 white">{t('unlock_the_potential_your_business')}</p>
                {_renderButtonExpand({ url: SETTINGS_PLANS })}
            </div>
        </div>
    );
};

export default AddonHeaderExpand;
