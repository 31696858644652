import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LIST_STATUS } from 'app/const/App';
import DropdownStatusPipelines from './DropdownStatusPipelines';
import { useDispatch, useSelector } from 'react-redux';
import { createPipelines } from 'common/redux/actions/settings/pipelinesAction';
import ButtonSave from 'app/components/button/ButtonSave';
import { ENTER_KEY_CODE } from 'app/const/Keyboard';
import { detectKeyPress } from 'common/utils/FunctionUtils';

const AddNewPipeline = ({ pipelines = [], handleShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data = [] } = useSelector(({ pipelines }) => pipelines);
    // NOTE: idGroup is id of first group
    const idGroup = data?.[0]?.id || '';
    const refInput = useRef(null);
    const refDropdown = useRef(null);
    const refSave = useRef(null);

    useEffect(() => {
        refSave.current?.setDisable(true);
    }, []);

    const _handleAddPipeline = () => {
        const valueInput = refInput.current.value;
        const valueStatus = refDropdown.current.getValue();

        const _handleSuccess = ({ message }) => {
            refInput.current.value = '';
            handleShowAlert({ message, type: LIST_STATUS.SUCCESS });
            refSave.current?.removeLoading();
            refSave.current?.setDisable(true);
        };

        const _handleFail = ({ message }) => {
            handleShowAlert({ message });
            refSave.current?.removeLoading();
            refInput.current.focus();
        };

        dispatch(createPipelines({ idGroup, name: valueInput, status: valueStatus }, _handleSuccess, _handleFail));
    };

    const _handleChange = (event) => {
        refSave.current?.setDisable(!!!event.target.value);
    };

    const _handleKeydown = (event) => {
        if (detectKeyPress(event, ENTER_KEY_CODE) && !!refInput.current.value) {
            refSave.current.setLoading(true);
            _handleAddPipeline();
        }
    };

    return (
        <div className="boxs__search flexcenter gap-8">
            <input
                ref={refInput}
                type="text"
                className="field-input"
                placeholder={t('status_name')}
                tabIndex={0}
                autoFocus
                onChange={_handleChange}
                onKeyPress={_handleKeydown}
            />
            <DropdownStatusPipelines ref={refDropdown} pipelines={pipelines} />
            <ButtonSave ref={refSave} wrapClass="v2-btn-main" title={t('add')} onSave={_handleAddPipeline} />
        </div>
    );
};

export default AddNewPipeline;
