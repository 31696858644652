/* eslint-disable no-unused-vars */
import DripPreviewEmail from 'app/modules/drip/components/DripPreviewEmail';
import DripPreviewSms from 'app/modules/drip/components/DripPreviewSms';
import DripPreviewSubject from 'app/modules/drip/components/DripPreviewSubject';
import IconStar from 'assets/icon/IconStar';

export const ButtonPreview = ({ text, onTranslate = () => {} }) => {
    return (
        <div className="v2-btn-main btn-pay-online">
            <span>{onTranslate(text)}</span>
        </div>
    );
};

export const RatingStarPreview = ({ onTranslate = () => {} }) => {
    const listRating = [
        { id: 1, name: onTranslate('drip:terrible') },
        { id: 2, name: onTranslate('drip:poor') },
        { id: 3, name: onTranslate('drip:average') },
        { id: 4, name: onTranslate('drip:good') },
        { id: 5, name: onTranslate('drip:great') }
    ];

    const renderStars = () => {
        return listRating.map((item) => {
            return (
                <li key={item.id}>
                    <IconStar />
                    <p>{item.name}</p>
                </li>
            );
        });
    };

    return (
        <div className="txt-feedback">
            <h5> {onTranslate('drip:tell_us_what_you_think')} </h5>
            <p>
                {' '}
                {onTranslate('drip:thanks_for_your_business')}
                {onTranslate('drip:how_would_you_rate_your_experience')}
            </p>
            <ul className="rate">{renderStars()}</ul>
        </div>
    );
};

export const LinkPreview = ({ text, onTranslate = () => {} }) => {
    return <span className="cursor-pointer manage-link">{onTranslate(text)}</span>;
};

const PreviewSetting = ({ email, sms }) => {
    return (
        <div className="container-setting-right">
            <div className="container-content-email">
                <div className="title">
                    <h3>
                        <DripPreviewSubject subject={email?.subject || ''} />
                    </h3>
                </div>

                <div className="wrap-content-email pb-8">
                    {email?.status != 0 && <DripPreviewEmail content={email?.content} />}
                </div>
            </div>
            {sms && (
                <div className="ml-5">
                    <DripPreviewSms content={sms?.content} />
                </div>
            )}
        </div>
    );
};

export default PreviewSetting;
