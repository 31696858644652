import React, { useContext, useReducer, useRef, useEffect } from 'react';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { TABS_ENDS } from 'app/modules/jobdetail/const';
import { convertTimeToISO, formartDateWithString } from 'common/utils/DateUtils';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import IconFlag from 'assets/icon/IconFlag';
import { reducer } from 'app/const/Reducer';

const Ends = () => {
    const refDropDown = useRef(null);
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);

    const invoiceRepeatCurrent = invoiceRepeatData.offset || {};

    const [state, dispatchState] = useReducer(reducer, {
        tabEndsActive: invoiceRepeatCurrent.ends || TABS_ENDS.MODE_NEVER,
        showCalendar: false
    });

    const finalTabEndsActive = state.tabEndsActive;

    const isTabNever = finalTabEndsActive === TABS_ENDS.MODE_NEVER;
    const isTabAfter = finalTabEndsActive === TABS_ENDS.MODE_AFTER;
    const isTabOndate = finalTabEndsActive === TABS_ENDS.MODE_ON_DATE;
    const isShowCalendar = state.showCalendar;

    useEffect(() => {
        if (isShowCalendar) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isShowCalendar]);

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_date_picker');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    function _closeDropdown() {
        dispatchState({ showCalendar: false });
    }

    function _handleChangeTab(e, value) {
        let refValue = { ...invoiceRepeatData };

        switch (value) {
            case TABS_ENDS.MODE_ON_DATE:
                dispatchState({ tabEndsActive: value, showCalendar: !isShowCalendar });

                const finalString = moment().format('YYYY-MM-DD HH:mm:ss');
                const newOption = {
                    ...invoiceRepeatCurrent,
                    ends: TABS_ENDS.MODE_ON_DATE,
                    endDate:
                        invoiceRepeatCurrent.endDate === ''
                            ? moment.tz(finalString, 'YYYY-MM-DD HH:mm:ss', 'UTC').toISOString()
                            : invoiceRepeatCurrent.endDate
                };

                refValue = {
                    ...refValue,
                    offset: newOption
                };

                break;
            case TABS_ENDS.MODE_AFTER:
                dispatchState({ tabEndsActive: value, showCalendar: false });

                const newOptionAfter = {
                    ...invoiceRepeatCurrent,
                    ends: TABS_ENDS.MODE_AFTER,
                    occurances: 1
                };

                refValue = {
                    ...refValue,
                    offset: newOptionAfter
                };

                break;
            case TABS_ENDS.MODE_NEVER:
                dispatchState({ tabEndsActive: value, showCalendar: false });

                const newOptionNever = {
                    ...invoiceRepeatCurrent,
                    ends: TABS_ENDS.MODE_NEVER,
                    occurances: 1
                };

                refValue = {
                    ...refValue,
                    offset: newOptionNever
                };

                break;
            default:
                break;
        }

        updateInvoiRepeatContext(refValue);
    }

    function _onChangeDate(date) {
        dispatchState({ showCalendar: false });

        const newOption = {
            ...invoiceRepeatCurrent,
            endDate: convertTimeToISO(moment(date))
        };

        const refValue = {
            ...invoiceRepeatData,
            offset: newOption
        };
        updateInvoiRepeatContext(refValue);
    }

    function _handleChangeJobs(interval) {
        const refValue = {
            ...invoiceRepeatData,
            offset: {
                ...invoiceRepeatCurrent,
                occurances: interval
            }
        };
        updateInvoiRepeatContext(refValue);
    }

    if (!invoiceRepeatData.repeat) return false;

    return (
        <div className="rows row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconFlag />
                Ends
            </div>
            <div
                ref={refDropDown}
                className={`details details-repeat-end tabs ${isShowCalendar ? 'active-ondate' : ''}`}
            >
                <div className="tabs-daily btn-item m-0">
                    <button
                        className={`tab-items ${isTabNever ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_NEVER)}
                        type="button"
                    >
                        Never
                    </button>
                    <button
                        className={`tab-items js-toggle-after ${isTabAfter ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_AFTER)}
                        type="button"
                    >
                        After
                    </button>
                    <button
                        className={`tab-items js-toggle-ondate ${isTabOndate ? 'active-tab-selector' : ''}`}
                        onClick={(e) => _handleChangeTab(e, TABS_ENDS.MODE_ON_DATE)}
                        type="button"
                    >
                        On date
                    </button>
                </div>
                {isTabAfter && (
                    <div className="field-every">
                        <FormInterval interval={invoiceRepeatCurrent.occurances} onChange={_handleChangeJobs} />
                        <span className="text-last">invoices</span>
                    </div>
                )}
                {isShowCalendar && (
                    <div id="show_date_picker" className="monthly-calendar">
                        <DatePicker
                            inline
                            minDate={new Date()}
                            dateFormat="MM-DD-YYYY"
                            selected={formartDateWithString(
                                moment(invoiceRepeatCurrent.endDate).utc().format('YYYY-MM-DD HH:mm')
                            )}
                            onSelect={(date) => _onChangeDate(date)}
                            renderCustomHeader={HeaderCustom}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Ends;
