export const TYPE_SETTING_BROADCAST = {
    GET_LIST: 'SETTING_GET_LIST_BROADCAST',
    CREATE: 'SETTING_CREATE_BROADCAST',
    UPDATE: 'SETTING_UPDATE_BROADCAST',
    UPDATE_STATUS: 'SETTING_UPDATE_STATUS_BROADCAST',
    DETAIL: 'SETTINGS_DETAIL_BROADCAST'
};

// actions
export const getListSettingBroadcastTemplate = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_BROADCAST.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createBroadcast = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_BROADCAST.CREATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateBroadcast = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_BROADCAST.UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateStatusSettingBroadcast = (
    params,
    actionSuccess,
    actionFailed = () => {},
    actionFinally = () => {}
) => {
    return {
        type: TYPE_SETTING_BROADCAST.UPDATE_STATUS,
        params,
        actionSuccess,
        actionFailed,
        actionFinally
    };
};

export const getDetailBroadcast = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_BROADCAST.DETAIL,
        params,
        actionSuccess,
        actionFailed
    };
};
