import React from 'react';

export default function IconActivity({ isActive = false }) {
    if (isActive) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path
                    stroke="#4D5054"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.3"
                    d="M7.5 6.5a1 1 0 011 1v11h-2v-11a1 1 0 011-1zM12.5 12.5a1 1 0 011 1v5h-2v-5a1 1 0 011-1zM17.5 9.5a1 1 0 011 1v8h-2v-8a1 1 0 011-1z"
                    clipRule="evenodd"
                ></path>
                <path
                    stroke="#4D5054"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.3"
                    d="M3.5 18.5h18"
                ></path>
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                fill="#1C1F22"
                fillRule="evenodd"
                d="M4 19a1 1 0 110-2h2V5a1 1 0 012 0v12h8V7a1 1 0 112 0v10h2a1 1 0 110 2H4z"
                clipRule="evenodd"
                fillOpacity="0.3"
            ></path>
            <path fill="#1C1F22" d="M13 10a1 1 0 10-2 0v5a1 1 0 102 0v-5z" fillOpacity="0.6"></path>
        </svg>
    );
}
