import { REGEX_FORMAT_NUMBER } from 'app/const/Currency';

/**
 * Calculate the difference in price between the selected package and the yearly plan.
 *
 * @param {object} options - The options object.
 * @param {string} options.selectedPackage - The selected package.
 * @param {object} options.listPlans - The list of plans.
 * @param {number} options.numUser - The number of users.
 * @returns {number} The difference in price.
 */
export const countSavedFee = ({ selectedPackage = 'basic', listPlans = {}, numUser = 1 }) => {
    if (!Object.keys(listPlans).length || !numUser) return 0;

    const yearTerm = listPlans['annual'][selectedPackage];
    const monthTerm = listPlans['month'][selectedPackage];
    if (!yearTerm || !monthTerm) return 0;

    const yearPlan = yearTerm.find((item) => item.schedule_num === numUser);
    const monthPlan = monthTerm.find((item) => item.schedule_num === numUser);
    if (!yearPlan || !monthPlan) return 0;

    const diffPrice = monthPlan.price * 12 - yearPlan.price;

    return diffPrice > 0 ? diffPrice : 0;
};

/**
 * Formats a price to display with commas as thousands separators.
 *
 * @param {number} price - The price to format.
 * @returns {string} The formatted price.
 */
export const formatPrice = (price) => {
    if (!price) return 0;
    return price.toString().replace(REGEX_FORMAT_NUMBER, '$1,');
};
