import IconSync from 'assets/icon/IconSync';
import IconAlert from 'assets/icon/IconAlert';
import {
    QB_SYNC_STATUS,
    QUICK_BOOK_CUSTOMER,
    QUICK_BOOK_CUS_INVOICE,
    QUICK_BOOK_PAYMENT,
    URL_QUICK_BOOK_COLUMN
} from 'app/const/quickbook';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useReducer } from 'react';
import IconDone from 'assets/icon/IconDone';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import IconSearch from 'assets/icon/IconSearch';
import { INVOICE_STATUS } from 'app/const/Filters';

const ColumnQuickBookSync = ({
    style,
    item,
    isEmpty = false,
    typeQuickBook = QUICK_BOOK_CUSTOMER,
    onClick = () => {},
    isDuplicateInvoiceQB = false,
    handleMouseEnter = () => {},
    handleMouseLeave = () => {}
}) => {
    const { quickbooks = {}, invoice_id, payment_status } = item || {};
    const { t } = useTranslation(['common', 'customers']);
    const [state, dispatchState] = useReducer(reducer, {
        status: quickbooks.status || QB_SYNC_STATUS.DEFAULT,
        title: quickbooks.msg || t('sync_to_quickbooks'),
        isErrorInvoice: false
    });
    const statusSync = state.status;

    const _handleSync = () => {
        if (statusSync === QB_SYNC_STATUS.DONE) {
            if (confirm(t('this_record_was_already_synced_to_quickbooks'))) {
                _syncToQB();
            }
        } else {
            _syncToQB();
        }
    };

    const _syncToQB = () => {
        const data = { ids: [item.id] };
        dispatchState({ status: QB_SYNC_STATUS.LOADING });
        switch (typeQuickBook) {
            case QUICK_BOOK_PAYMENT:
                data['invoice_id'] = item.invoice_id;
                break;
            default:
                break;
        }
        clientQuery(URL_QUICK_BOOK_COLUMN[typeQuickBook], { data, method: 'POST' }, _success, _failed);
    };

    const _success = () => {
        dispatchState({ status: QB_SYNC_STATUS.DONE });
    };

    const _failed = (error) => {
        dispatchState({
            status: QB_SYNC_STATUS.FAIL,
            title: error?.message[0] || '',
            isErrorInvoice: !error.is_duplicate
        });
    };

    const _handleClick = () => {
        onClick(item);
    };

    const renderIcon = (status) => {
        switch (status) {
            case QB_SYNC_STATUS.DONE:
                return <IconDone isSuccess />;
            case QB_SYNC_STATUS.LOADING:
                return <IconLoading />;
            case QB_SYNC_STATUS.FAIL:
                return <IconAlert />;
            default:
                return <IconSync isContent />;
        }
    };

    const _renderQuickbookSync = () => {
        if (typeQuickBook === QUICK_BOOK_PAYMENT) {
            if (payment_status === -1 || !invoice_id) return null;
        }

        if (typeQuickBook === QUICK_BOOK_CUS_INVOICE) {
            const { total, status } = item;
            const totalFinal = typeof total === 'object' ? total.value : total;

            if (status === INVOICE_STATUS.STATUS_VOID || status === INVOICE_STATUS.STATUS_WRITE_OFF || totalFinal < 0)
                return null;
        }
        return (
            <>
                <div
                    className={classNames('v2-btn-default btn-syned --icon-sm', {
                        '--transparent has-sync': statusSync === QB_SYNC_STATUS.DONE,
                        '--transparent': statusSync !== QB_SYNC_STATUS.DONE && statusSync !== QB_SYNC_STATUS.FAIL,
                        '--delete has-sync': statusSync === QB_SYNC_STATUS.FAIL,
                        '--loadmore': statusSync === QB_SYNC_STATUS.LOADING
                    })}
                    onClick={_handleSync}
                    data-tooltip={state.title}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {renderIcon(state.status)}
                </div>
                {statusSync === QB_SYNC_STATUS.FAIL && state.isErrorInvoice && isDuplicateInvoiceQB && (
                    <div
                        className="v2-btn-default --transparent --icon-lg --loadmore"
                        title={t('customers:link_a_item_in_quickbooks')}
                        onClick={_handleClick}
                    >
                        <IconSearch />
                    </div>
                )}
            </>
        );
    };

    if (isEmpty) return <div className={style}></div>;
    return <div className={classNames(style, { 'd-flex': state.isErrorInvoice })}>{_renderQuickbookSync()}</div>;
};

export default ColumnQuickBookSync;
