export default function LoadingStats() {
    return (
        <div className="boxs__content wrap-loading is-summary">
            <div className="rows">
                <div className="col">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth mt-1"></div>
                </div>
                <div className="col">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth mt-1"></div>
                </div>
                <div className="col">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth mt-1"></div>
                </div>
                <div className="col">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth mt-1"></div>
                </div>
                <div className="col">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth mt-1"></div>
                </div>
            </div>
        </div>
    );
}
