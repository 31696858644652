import { forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { JOB_UNCONFIRMED, LIST_JOB_STATUS_SEND_CONFIRMATIONS } from 'app/const/Job';
import { calendarSendAction } from 'common/redux/actions/calendar/broadcastAction';
import { getJobStatus } from 'common/utils/JobStatusUtils';
import { CALENDAR_MESSAGE_TYPE, SEND_APPOINTMENT } from '../calendar/const/Header';
import { getListTemplateConfirmation, getListTemplateReminder } from 'common/redux/actions/settings/templateAction';

const ServiceNotifyCustomer = (props, ref) => {
    const dispatch = useDispatch();
    const templatesReducer = useSelector((state) => state.templates);

    useImperativeHandle(ref, () => ({ onNotifyCustomer: _handleNotifyCustomer, getListTemplate: _getListTemplate }));

    const _getListTemplate = (jobStatus = JOB_UNCONFIRMED, onSuccess = () => {}, onFail = () => {}) => {
        const finalType = LIST_JOB_STATUS_SEND_CONFIRMATIONS.includes(getJobStatus(jobStatus)?.type)
            ? CALENDAR_MESSAGE_TYPE.CONFIRMATIONS
            : CALENDAR_MESSAGE_TYPE.REMINDERS;
        const { isFirstTime = false, data = [] } = templatesReducer[finalType] || {};
        if (!isFirstTime) return onSuccess({ data });
        if (finalType === CALENDAR_MESSAGE_TYPE.REMINDERS) {
            dispatch(getListTemplateReminder({}, onSuccess, onFail));
        } else {
            dispatch(getListTemplateConfirmation({}, onSuccess, onFail));
        }
    };

    const _handleNotifyCustomer = (params = {}, onSuccess = () => {}, onFailed = () => {}) => {
        params.type = LIST_JOB_STATUS_SEND_CONFIRMATIONS.includes(getJobStatus(params?.jobStatus)?.type)
            ? SEND_APPOINTMENT.CONFIRMATIONS
            : SEND_APPOINTMENT.REMINDERS;
        delete params.jobStatus;
        dispatch(calendarSendAction(params, onSuccess, onFailed));
    };
};

export default forwardRef(ServiceNotifyCustomer);
