import React, { useContext, useReducer, useEffect } from 'react';
import IconBuilding from 'assets/icon/IconBuilding';
import { UnitDetailContext } from 'app/modules/jobdetail/contexts/UnitDetailContext';
import IconError from 'assets/icon/IconError';
import { reducer } from 'app/const/Reducer';

export default function Building() {
    const { unitDetailContextData, updateUnitDetailContextData } = useContext(UnitDetailContext);
    const parentBuildingName = unitDetailContextData.buildingName;

    const [state, dispatchState] = useReducer(reducer, {
        isWarning: false,
        value: parentBuildingName,
        isEdit: false
    });

    const finalIsWarning = state.isWarning;

    useEffect(() => {
        finalIsWarning !== parentBuildingName && dispatchState({ value: parentBuildingName, isEdit: false });
    }, [parentBuildingName]);

    function _handleSubmitBuilding(e) {
        const finalValue = e.target.value;
        let isExist = false;

        if (!state.isEdit) {
            return false;
        }

        if (finalValue.length === 0) {
            dispatchState({
                isWarning: 'Building name can not blank'
            });
            updateUnitDetailContextData({
                isCheckBilding: false
            });
            return false;
        }

        unitDetailContextData.buildingData.forEach((element) => {
            if (element.name === finalValue && unitDetailContextData.buildingSelected?.id !== element.id) {
                isExist = true;
            }
        });

        if (isExist) {
            dispatchState({
                isWarning: 'Building name is exits'
            });
            updateUnitDetailContextData({
                isCheckBilding: false
            });
            return false;
        }

        updateUnitDetailContextData({
            isCheckBilding: true,
            buildingName: finalValue
        });
    }

    function _handleChangeValue(e) {
        dispatchState({
            value: e.target.value,
            isWarning: null,
            isEdit: true
        });
    }

    return (
        <div className="rows">
            <div className="rows__label">
                <IconBuilding isHasColor />
                Building
            </div>
            <div className="rows__form">
                <input
                    onBlur={(e) => _handleSubmitBuilding(e)}
                    onChange={(e) => _handleChangeValue(e)}
                    className="field-input"
                    type="text"
                    placeholder="Building name"
                    value={state.value}
                />
                {finalIsWarning && (
                    <p className="txt-incorrect">
                        <IconError />
                        {finalIsWarning}
                    </p>
                )}
            </div>
        </div>
    );
}
