import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import StatusBar from 'app/components/status/statusbar';
import AddonsItem from './AddonsItem';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { ADDONS_MULTIPLE_BRANCH } from 'app/const/addons';

const AddonsList = ({ data = [], msgEmpty = '', ...props }) => {
    const refStatusbar = useRef(null);
    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(profileData);

    const _handleShowStatusbar = ({ id, message, type }) => {
        refStatusbar.current.showStatusBar(id, message, type);
    };

    const _renderList = () => {
        if (!data.length) return <GridEmpty msgEmpty={msgEmpty} />;
        return data.map((item) => {
            if (item.keyword === ADDONS_MULTIPLE_BRANCH && isTrial) item.status = 0;
            return <AddonsItem key={item.id} {...item} {...props} onShowStatus={_handleShowStatusbar} />;
        });
    };

    return (
        <>
            <StatusBar ref={refStatusbar} />
            <div className="scrolls">
                <div className="tab-contents wrapper-addons">
                    <div className="wrapper-addons__content tab-content-active">{_renderList()}</div>
                </div>
            </div>
        </>
    );
};

export default AddonsList;
