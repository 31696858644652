import StatusBar from 'app/components/status';
import { LIST_STATUS } from 'app/const/Status';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';

const AddonsAlert = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        id: null,
        message: '',
        type: LIST_STATUS.SUCCESS
    });

    useImperativeHandle(ref, () => ({
        showStatusBar: _handleShowStatus,
        closeStatusBar: _closeStatusBar
    }));

    const _handleShowStatus = (data) => {
        dispatchState(data);
    };

    const _closeStatusBar = () => {
        dispatchState({ id: null });
    };

    if (!state.id) return null;

    return (
        <StatusBar
            key={state.id}
            message={state.message}
            type={state.type}
            id={state.id}
            closeStatusBar={_closeStatusBar}
        />
    );
});

export default AddonsAlert;
