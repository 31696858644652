import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import useClickOutside from 'common/hooks/useClickOutside';
import IconArrowDown from 'assets/icon/IconArrowDown';
import classNames from 'classnames';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import ReportListDropdown from './ReportListDropdown';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';

function ReportSort({
    name,
    options,
    selected,
    onSelect,
    onGetAsyncOptions,
    fileTranslation,
    isSortCharacter = false,
    forceRerender,
    classWrapper = '',
    isPopper,
    isUseToggle = false
}) {
    const { t } = useTranslation([`${fileTranslation}`]);

    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);
    const isFirstTime = useRef(true);

    useEffect(() => {
        return () => {
            isFirstTime.current = true;
        };
    }, [forceRerender]);

    const handleOpen = () => {
        if (isFirstTime.current) {
            onGetAsyncOptions &&
                onGetAsyncOptions({
                    item: name,
                    callBack: () => {
                        isFirstTime.current = false;
                    }
                });
        }
        !isPopper && setIsOpen(!isOpen);
    };

    const handleSelectOption = (value, item) => {
        isSortCharacter ? onSelect(name, item.name === 'A-Z' ? '' : item.name) : onSelect(name, value);
    };

    const _renderButton = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis">
                    {isSortCharacter && !selected ? 'A-Z' : t(`${fileTranslation}:${selected}`)}
                </span>
                <span className="arrow">
                    <IconArrowDown />
                </span>
            </Fragment>
        );
    };

    const _renderReportListDropdown = () => {
        return (
            <ReportListDropdown
                refSelect={refSelect}
                options={options}
                fileTranslation={fileTranslation}
                selected={selected}
                isFirstTime={!!isFirstTime.current}
                isPopper={isPopper}
                typeDropdown={TYPE_SELECT.SORT}
                handleSelectOption={handleSelectOption}
            />
        );
    };

    if (isPopper) {
        return (
            <DropdownPopper
                ref={refSelect}
                isCalculateWidth
                customStyle={{ maxWidth: 'auto' }}
                buttonClassName="dropbtn v2-btn-default"
                placement="bottom-start"
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                wrapperClassName={classNames(classWrapper, 'v2-dropdown')}
                isUseToggle={isUseToggle}
                customButton={_renderButton()}
                onOpen={handleOpen}
            >
                {_renderReportListDropdown()}
            </DropdownPopper>
        );
    }

    return (
        <div className={classNames('v2-dropdown', classWrapper, { active: isOpen })}>
            <div ref={refSelect} className="dropbtn items" onClick={handleOpen}>
                {_renderButton()}
            </div>
            <div className="v2-dropdown__menu content-full scrolls">{_renderReportListDropdown()}</div>
        </div>
    );
}

ReportSort.propTypes = {
    options: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fileTranslation: PropTypes.string,
    onSelect: PropTypes.func,
    onGetAsyncOptions: PropTypes.func,
    title: PropTypes.string
};

export default ReportSort;
