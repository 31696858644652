import IconTrash from 'assets/icon/IconTrash';
import React, { useContext } from 'react';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import InfoCard from './InfoCard';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconThreeDots from 'assets/icon/IconThreeDots';

const CardPaymentInfo = ({
    data = [],
    onWarning = () => {},
    // when onEdit is set to null then the cursor will not be pointer
    onEdit = null,
    classWrapper = 'rows mt-0',
    isShowDelete = true,
    isShowMore = false
}) => {
    const { t } = useTranslation();
    const { idsDefault, _handleUpdateDefault } = useContext(CustomerDetailContext);

    const _handleDelete = (dataItem) => {
        onWarning('payment', dataItem);
    };

    const _handleSetDefault = (e, item, isDefault) => {
        e.target.checked && _handleUpdateDefault(item, isDefault);
    };

    const renderOptions = ({ isDefault, item }) => {
        return [
            {
                id: `default_${item.id}`,
                name: t('customers:set_as_default'),
                values: { type: 'default', item, isDefault }
            },
            {
                id: `delete_${item.id}`,
                name: t('customers:delete'),
                values: { type: 'delete', item, isDefault }
            }
        ];
    };

    const handleClick = ({ type, item, isDefault }) => {
        switch (type) {
            case 'default':
                _handleUpdateDefault(item, isDefault);
                break;
            case 'delete':
                _handleDelete(item);
                break;
            default:
                return null;
        }
    };

    if (!data.length) return null;

    return data.map((item) => {
        const name = item.name;
        const isDefault = idsDefault[name] === item.account_id;
        const idElement = `stripe_${item.id}`;
        const elm = document.getElementById(idElement);
        if (isDefault && elm) elm.checked = true;
        const disableOption = [];
        if (isDefault) disableOption.push(`default_${item.id}`);

        return (
            <div key={item.id} className={classNames(classWrapper, { 'has-default': isDefault })}>
                <InfoCard
                    {...item}
                    is_default={isDefault}
                    isPaymentDefault={isDefault}
                    idElement={idElement}
                    onEdit={onEdit}
                    onSetDefault={(e) => _handleSetDefault(e, item, isDefault)}
                />
                {isShowDelete && (
                    <div
                        className="v2-btn-default has-bg-red has-icon --sm btn-modal"
                        onClick={() => _handleDelete(item)}
                    >
                        <IconTrash /> {t(`customers:delete`)}
                    </div>
                )}
                {isShowMore && (
                    <CalendarDropdown
                        id={item.id}
                        buttonClassName="dropbtn v2-btn-default --icon-lg --transparent"
                        wrapperListClass="v2-dropdown__menu"
                        wrapperClassName="v2-dropdown v2-dropdown--more"
                        wrapperItemClass="items fs-13 fw-500"
                        customDropButton={() => <IconThreeDots />}
                        options={renderOptions({ isDefault, item })}
                        keyGetKey="id"
                        keyGetValue="values"
                        disableOption={disableOption}
                        onSelect={handleClick}
                    />
                )}
            </div>
        );
    });
};

export default CardPaymentInfo;
