import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { REPORT_SUBSCRIPTION_GET_LIST, REPORT_FILTER_PLAN_GET_LIST } from 'app/const/Api';

export function* getListReportSubscription({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_SUBSCRIPTION_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListFlansSubscriptionFilter({ actionSuccess, actionFailed, params }) {
    try {
        const response = yield call(fetchWithToken, REPORT_FILTER_PLAN_GET_LIST, params);
        if (response && response.success) {
            const data = response?.data.map(({ id, nickname }) => ({
                id: id,
                name: nickname,
                value: id
            }));
            const newData = { ...response, data: data };
            actionSuccess(newData);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
