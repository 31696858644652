import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import Loading from 'app/modules/jobdetail/tabs/documents/preview/Loading';
import { clientQuery } from 'common/utils/ApiUtils';
import { getDocumentContent } from 'app/const/Api';
import PreviewDocStatic from './PreviewDocStatic';
import { handleAbortController } from 'app/modules/customer/utils';

const AvailableDocumentPreview = forwardRef((props, ref) => {
    const abortController = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        documentId: null,
        content: '',
        onlyUser: 0,
        isPdf: false
    });

    useImperativeHandle(ref, () => ({ _preview: _handlePreView, _setLoading: _handleSetLoading }));
    const { documentId, isPdf, content: finalContent } = state;

    const _clearScript = () => {
        const scripts = Array.from(document.getElementsByClassName('preview-document-css'));
        scripts.forEach((element) => {
            element.remove();
        });
    };

    useEffect(() => {
        if (documentId) {
            _clearScript();
            _getDocumentContent(documentId);
        }

        return () => {
            _clearScript();
        };
    }, [documentId]);

    function _handlePreView(newId, onlyUser) {
        if (documentId === newId) return;

        dispatchState({
            isLoading: true,
            documentId: newId,
            onlyUser
        });
    }

    function _getDocumentContent(id) {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _getDocumentContentFail = ({ isAborted = false }) => {
            if (isAborted) return;
            dispatchState((prev) => ({ ...prev, isLoading: false, content: '', isPdf: false }));
        };

        clientQuery(
            getDocumentContent(id),
            { method: 'GET', data: { only_user: state.onlyUser }, abortController: abortController.current },
            _getDocumentContentSuccess,
            _getDocumentContentFail
        );
    }

    function _getDocumentContentSuccess(response) {
        const regex = new RegExp(`\\{{.*.*}}`, 'g');
        const { html, css, pdf } = response.data;

        if (!!pdf) {
            dispatchState((prev) => ({ ...prev, isLoading: false, isPdf: true, content: pdf.url }));
            return;
        }

        css.forEach((item) => {
            const sc = document.createElement('link');
            sc.setAttribute('href', item);
            sc.setAttribute('rel', 'stylesheet');
            sc.setAttribute('type', 'text/css');
            sc.setAttribute('class', 'preview-document-css');
            document.head.appendChild(sc);
        });

        setTimeout(() => {
            dispatchState({
                isLoading: false,
                content: html.replace(regex, ''),
                isPdf: false
            });
        }, 500);
    }

    const _handleSetLoading = (value = false) => {
        dispatchState((prev) => ({ ...prev, isLoading: value, isPdf: false, content: '' }));
    };

    if (state.isLoading) {
        return (
            <div className="list__preview scrolls">
                <Loading />
            </div>
        );
    }

    if (isPdf) return <PreviewDocStatic wrapClassName="list__preview scrolls" url={finalContent} />;

    return (
        <div
            className="list__preview scrolls"
            dangerouslySetInnerHTML={{
                __html: !documentId
                    ? '<p class="p-5">Preview docs here, default is the first selected document.</p>'
                    : finalContent
            }}
        />
    );
});

export default AvailableDocumentPreview;
