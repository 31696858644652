import { ADDONS_TRIGGER_DETAIL, UPDATE_ADDONS_TRIGGER_OPTIONS } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer } from 'react';
import AddonsOptions from '../components/AddonsOptions';
import TriggerAddonsContent from './components/TriggerAddonsContent';
import TriggerAddonsLoading from './components/TriggerAddonsLoading';
import { reducer } from 'app/const/Reducer';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsContent = () => {
    const [state, dispatchState] = useReducer(reducer, {
        trigger: {},
        isLoading: true
    });
    const { isLoading, trigger } = state;

    useEffect(() => {
        _getDetailReviewAddons();
    }, []);

    const _getDetailReviewAddons = () => {
        clientQuery(ADDONS_TRIGGER_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        const options = [...data.options];
        const completionist = options[0];
        const { items, description, value, keyword } = completionist;
        completionist.items.unshift({ ...items[0], description, value, keyword });
        delete completionist.description;

        dispatchState({ trigger: data, isLoading: false });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <TriggerAddonsLoading />
                    ) : (
                        <>
                            <TriggerAddonsContent {...trigger} />
                            <AddonsOptions data={trigger.options} urlUpdate={UPDATE_ADDONS_TRIGGER_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsContent;
