import GdButton from 'app/components/button';
import { ADDONS_SMS_NUMBERS } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';

const SMSAddonsSearchPhones = ({ options = [], onSearchSuccess = () => {}, onShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const country = useSelector(({ auth }) => auth.user.company.country);
    const [state, dispatchState] = useReducer(reducer, {
        selectedCountry: options.find((item) => item.country_code.toLowerCase() === country) || options[0],
        isLoading: false
    });
    const refInput = useRef(null);
    const { selectedCountry, isLoading } = state;

    const _handleSelectCountry = (country) => {
        dispatchState({ selectedCountry: options.find((item) => item.id === country) });
    };

    const _handleSearch = () => {
        const prefix = refInput.current.value || '';
        dispatchState({ isLoading: true });

        const _handleSuccess = ({ data }) => {
            dispatchState({ isLoading: false });
            onSearchSuccess(data, prefix);
        };

        const _handleFinally = () => dispatchState({ isLoading: false });
        const _handleFailed = ({ message }) => {
            onShowAlert({
                id: LIST_STATUS.ERROR,
                message,
                type: LIST_STATUS.ERROR
            });
            dispatchState({ isLoading: false });
        };

        clientQuery(
            ADDONS_SMS_NUMBERS,
            { data: { prefix, country: selectedCountry.country_code }, method: 'GET' },
            _handleSuccess,
            _handleFailed,
            _handleFinally
        );
    };

    return (
        <>
            <div className="row row-multi align-end">
                <div className="col --sm">
                    <div className="txt">{t('search')}</div>
                    <input ref={refInput} className="field-input" type="text" placeholder="Prefix or area" />
                </div>
                <div className="col flex-1">
                    <div className="txt">{t('country')}</div>
                    <CalendarDropdown
                        id="country_addons_sms"
                        selectedOption={selectedCountry}
                        selected={selectedCountry.label}
                        keyGetName="label"
                        keyGetValue="id"
                        onSelect={_handleSelectCountry}
                        options={options}
                    />
                </div>
                <div className="col">
                    <GdButton isWhiteLoading className="v2-btn-default" onClick={_handleSearch} isLoading={isLoading}>
                        {t('search')}
                    </GdButton>
                </div>
            </div>
        </>
    );
};

export default SMSAddonsSearchPhones;
