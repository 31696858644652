/* eslint-disable require-yield */
/* eslint-disable no-unused-vars */
import { call } from 'redux-saga/effects';
import {
    fetchWithToken,
    postWithToken,
    putWithToken
} from 'common/utils/ApiUtils';
import {
    SETTINGS_GET_LIST_SYSTEMS,
    SETTINGS_UPDATE_STATUS_SYSTEMS,
    SETTINGS_UPDATE_SYSTEMS,
    SETTINGS_DETAIL_SYSTEMS
} from 'app/const/Api';

export function* getListSystems({ params, actionSuccess, actionFailed }) {
    try {
        const items = 24;

        const genFakeData = () => {
            const fake = [];
            let count = 0;
            for (let i = 0; i + params.offset < items; i++) {
                if (count === params.limit) {
                    break;
                }
                count++;
                fake.push({
                    id: i + params.offset,
                    type: `${i + params.offset} reminder`,
                    name: 'Ant Service Reminder',
                    status: i % 2
                });
            }
            return fake;
        };
        const response = {
            success: true,
            data: genFakeData(),
            page: Math.ceil(items / params.limit),
            total: items
        };
        // const response = yield call(
        //   fetchWithToken,
        //   SETTINGS_GET_LIST_SYSTEMS,
        //   params
        // );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// eslint-disable-next-line
export function* getSystemsDetail({ params, actionSuccess, actionFailed }) {
    const fakeData = {
        id: params.id,
        template_name: 'Nam Long Soft',
        reminder_1: {
            status: 1,
            days: 5,
            email: {
                subject: 'reminder 1',
                status: 1,
                content: '<p>Content Reminder 1</p>'
            },
            sms: {
                status: 1,
                content: '<p>Sms Reminder 1</p>'
            }
        },
        reminder_2: {
            status: 1,
            days: 7,
            email: {
                subject: 'reminder 2',
                status: 2,
                content: '<p>Content Reminder 2</p>'
            },
            sms: {
                status: 1,
                content: '<p>Sms Reminder 2</p>'
            }
        },
        reminder_3: {
            status: 0,
            days: 5,
            email: {
                subject: '',
                status: 1,
                content: '<p></p>'
            },
            sms: {
                status: 0,
                content: '<p></p>'
            }
        },
        services: [
            { id: 1, name: 'service 1', value: 1 },
            { id: 2, name: 'service 2', value: 2 }
        ]
    };
    try {
        // const response = yield call(
        //   fetchWithToken,
        //   SETTINGS_DETAIL_SYSTEMS,
        //   params
        // );
        const response = {
            success: true,
            data: fakeData
        };

        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}

export function* updateSystems({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            putWithToken,
            SETTINGS_UPDATE_SYSTEMS,
            params
        );
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusSystems({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            putWithToken,
            SETTINGS_UPDATE_STATUS_SYSTEMS,
            params
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
