import React from 'react';
import PropTypes from 'prop-types';

function AvatarEvent({ avatar = null, name = '', width = 32, height = 32 }) {
    return avatar ? (
        <img src={avatar} alt="" width={width} height={height} style={{borderRadius: '50%', objectFit: "cover"}} />
    ) : (
        <div
            style={{
                fontSize: '11px',
                backgroundColor: '#3075ea',
                textTransform: 'uppercase',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                color: '#FFF',
                lineHeight: '24px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {name?.substring(0, 2)}
        </div>
    );
}

AvatarEvent.propTypes = {
    avatar: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default AvatarEvent;
