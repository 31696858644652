import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconPhone from 'assets/icon/IconPhone';
import React, { Fragment, useContext, useRef } from 'react';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import GdPhoneItem from 'app/components/phone/ItemPhone';
import { useSelector } from 'react-redux';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import { handleClosePhoneDropdown } from 'app/components/phone/PhoneDropdown';

export default function CallHeader({ onNewCallNote }) {
    const refDropdown = useRef(null);

    const isEnableVoip = useSelector(({ auth }) => auth?.user?.settings?.addons?.voip);

    const _handleAddCallNote = () => {
        onNewCallNote({
            call_id: '',
            call_type: 2,
            caller: '',
            receiver: '',
            call_status: 1,
            isNew: true,
            duration: 0,
            recording: null,
            type: 'call'
        });
        refDropdown.current._close();
    };

    if (!!!isEnableVoip) {
        return false;
    }

    return (
        <DropdownPopper
            ref={refDropdown}
            isCheckHidden
            wrapperClassName="v2-dropdown wrap-log-call"
            wrapperListClass="v2-dropdown__menu scrolls"
            placement="bottom"
            modifiers={[{ name: 'offset', options: { offset: [0, 4] } }]}
            dropdownIcon={
                <Fragment>
                    <div className="dropbtn__label">
                        <IconPhone />
                        <p className="txt-ellipsis">Call</p>
                    </div>
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </Fragment>
            }
            buttonClassName="dropbtn items --has-icon"
            isUseToggle
            onClose={handleClosePhoneDropdown}
        >
            <Fragment>
                <div className="log-call-items --header">
                    <div onClick={_handleAddCallNote} className="v2-btn-default btn-bg-purple w-100">
                        Log a Call
                    </div>
                </div>
                <ListPhone />
            </Fragment>
        </DropdownPopper>
    );
}

export const ListPhone = ({ onlySelect = false, onChange = () => {} }) => {
    const refCustomerPhones = useRef([]);
    const refCustomerContact = useRef([]);

    const {
        customer: { phones = [], id: customerId },
        contacts = []
    } = useContext(CustomerDetailContext);

    const _handleSelectPhone = (newPhone) => {
        onChange(newPhone);
    };

    const _handleOnClick = (index) => {
        refCustomerPhones.current[index].onMakeCall();
    };

    const _handleOnClickContact = (index) => {
        refCustomerContact.current[index].onMakeCall();
    };

    const _renderCustomerPhones = () => {
        return phones.map((item, index) => {
            const { id, type, phone, is_valid } = item;

            if (!phone) {
                return null;
            }

            return (
                <div className="log-call-items items" data={'sss'} key={id} onClick={() => _handleOnClick(index)}>
                    <p className="txt-ellipsis" />
                    <div className="wrap-phone">
                        <GdPhoneItem
                            ref={(ref) => (refCustomerPhones.current[index] = ref)}
                            isValid={is_valid}
                            phoneType={type}
                            phone={phone}
                            onlySelect={onlySelect}
                            onClick={_handleSelectPhone}
                            isStopPropagationOnClick
                            isUsePopup
                            typeOpen={TYPE_OPEN_SMS.CUSTOMER}
                            customerId={customerId}
                        />
                    </div>
                </div>
            );
        });
    };

    const _renderCustomerContact = () => {
        return contacts.map((item) => {
            const { id, phones, customer = {} } = item;

            // NOTE: ID = 0 is primary contact
            if (!phones.length || +id === 0) return null;
            return (
                <Fragment key={id}>
                    <div className="lines"></div>

                    {phones.map((itemPhone, index) => {
                        const { phone } = itemPhone;
                        const keyPhone = `${id}${index}`;
                        if (!phone) {
                            return null;
                        }
                        return (
                            <div
                                key={index.toString()}
                                className="log-call-items items"
                                onClick={() => _handleOnClickContact(keyPhone)}
                            >
                                <p className="txt-ellipsis">
                                    {!index ? `${customer.first_name} ${customer.last_name}` : ''}
                                </p>

                                <div className="wrap-phone">
                                    <GdPhoneItem
                                        ref={(ref) => (refCustomerContact.current[keyPhone] = ref)}
                                        isValid={itemPhone.is_valid}
                                        phoneType={itemPhone.phone_type}
                                        phone={itemPhone.phone}
                                        onlySelect={onlySelect}
                                        onClick={_handleSelectPhone}
                                        isStopPropagationOnClick
                                        isUsePopup
                                        typeOpen={TYPE_OPEN_SMS.CUSTOMER}
                                        customerId={customerId}
                                        contactId={id}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </Fragment>
            );
        });
    };

    return (
        <Fragment>
            {_renderCustomerPhones()}
            {_renderCustomerContact()}
        </Fragment>
    );
};
