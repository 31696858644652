export const TYPE_DRIP_UNSUBSCRIBED = {
  GET_LIST: "DRIP_GET_LIST_UNSUBSCRIBED"
};

// actions
export const getListDripUnsubscribed = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_UNSUBSCRIBED.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

