import i18n from 'assets/i18n';
import { Fragment } from 'react';

export const replaceSpaceByToken = (string, token) => {
    // old browser's versions:
    return string.replace(/\s/g, token);

    // new browser's versions
    // return string.replaceAll(" ", token);
};

export const isValidUrl = (str) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); //check protocol
    return !!pattern.test(str);
};

export const iaVAlidUnicode = (url) => {
    try {
        url = new URL(url);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isArray = (value, isEmpty) => {
    if (Array.isArray(value)) {
        if (isEmpty) return value.length;
        else return true;
    } else {
        return false;
    }
};

export const dynamicColors = () => {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
};

export const overCountString = (count) => (count > 99 ? '99+' : count);
// export const capitalizeFirstLetter = (str) => (!!str ? str.charAt(0).toUpperCase() + str.slice(1) : str);
export const capitalizeFirstLetter = (str) => {
    str = str?.toString();
    return !!str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};
export const createUUID = () => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
};

export const getAvatarCustomer = (full_name = '') => {
    const string = full_name
        .replace(/[^a-zA-Z0-9\s]/g, '')
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '')
        .slice(0, 2);

    return string.length === 2 ? string : full_name.slice(0, 2);
};

export const capitalizeEachWord = (string = '') => {
    return string
        .trim()
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const convertLinkInString = (text) => {
    const urlRegex =
        /(?:<a[^>]*>.*?<\/a>)|(?:<img[^>]*src=["'][^"']*["'][^>]*>)|((?:https?:\/\/(?:www\.)?[^\s]+)|(?:www\.[^\s]+)|(?:[^\s]+\.(?:com|net|org|io|edu|vn|info)))/g;

    return text.replace(urlRegex, function (match, group1) {
        if (group1 === undefined) {
            return match;
        }

        let href = group1;
        if (!/^https?:\/\//i.test(group1)) {
            href = `http://${group1}`;
        }
        return `<a target="_blank" href="${href}">${group1}</a>`;
    });
};

export const convertLineBreaks = (text) => {
    return text.split(/\r\n|\n/).map((line, index) => {
        if (!line) return '';
        return (
            <Fragment key={index.toString()}>
                {line}
                <br />
            </Fragment>
        );
    });
};

export const messageActionsAlert = ({ type = '', action = '', message = [] }) => {
    return !!message?.length
        ? message
        : i18n.t('addons:msg_action_item_successfully', { type, action: i18n.t(`addons:${action}`).toLowerCase() });
};
