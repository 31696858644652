import {
    REPORT_INVOICE,
    REPORT_INVOICE_GENERATE_PDF,
    REPORT_INVOICE_GET_LIST,
    REPORT_INVOICE_SEARCH,
    REPORT_INVOICE_SEND_MAIL,
    REPORT_INVOICE_SERVICE_FILTER
} from 'app/const/Api';
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportInvoice({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_INVOICE_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* searchReportInvoice({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_INVOICE_SEARCH, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteInvoices({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, REPORT_INVOICE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* archiveInvoices({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, REPORT_INVOICE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* sendMailInvoices({ params, actionSuccess, actionFailed }) {
    const response = yield call(postWithToken, REPORT_INVOICE_SEND_MAIL, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* generatePdfInvoices({ params, actionSuccess, actionFailed }) {
    const response = yield call(postWithToken, REPORT_INVOICE_GENERATE_PDF, params);
    if (response && response.success) {
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}

export function* getInvoiceServiceFilter({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_INVOICE_SERVICE_FILTER);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
