import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from '../../context/SubscriptionContext';
import PlanListAdded from './PlanListAdded';
import PlanListModal from './PlanListModal';

const PlansContent = ({ customerId, isEdit = false }) => {
    const { t } = useTranslation(['common', 'customers']);
    const { selectedPlans, isSquare } = useContext(SubscriptionContext);

    const refPlanList = useRef(null);

    const _handleAddPlan = () => {
        refPlanList.current._open();
    };

    return (
        <div className="plans-content">
            <div className="header">
                <div className="title-x-sm">{t('plans')}</div>
                <div className="btn-action">
                    <button
                        className={classNames('v2-btn-default has-icon btn-modal', {
                            'is-disable': selectedPlans.length && isSquare
                        })}
                        onClick={_handleAddPlan}
                    >
                        <IconPlus />
                        {t('customers:add_plan')}
                    </button>
                </div>
            </div>
            <PlanListAdded isSquare={isSquare} isEdit={isEdit} />
            {/* Modals */}
            <PlanListModal isSquare={isSquare} ref={refPlanList} customerId={customerId} />
        </div>
    );
};

export default PlansContent;
