export const convertWebMToWAV = (webmData) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const createWAV = (audioBuffer) => {
        const { numberOfChannels, sampleRate, length } = audioBuffer;
        const interleaved = new Float32Array(length * numberOfChannels);
        const channelData = [];

        for (let channel = 0; channel < numberOfChannels; channel++) {
            channelData.push(audioBuffer.getChannelData(channel));
        }

        for (let i = 0; i < length; i++) {
            for (let channel = 0; channel < numberOfChannels; channel++) {
                interleaved[i * numberOfChannels + channel] = channelData[channel][i];
            }
        }

        return createWAVBlob(interleaved, sampleRate, numberOfChannels);
    };

    const createWAVBlob = (interleavedData, sampleRate, numberOfChannels) => {
        const writeString = (dataView, offset, string) => {
            for (let i = 0; i < string.length; i++) {
                dataView.setUint8(offset + i, string.charCodeAt(i));
            }
        };

        const blockAlign = numberOfChannels * 2;
        const bytesPerSample = 2;
        const dataSize = interleavedData.length * bytesPerSample;
        const buffer = new ArrayBuffer(44 + dataSize);
        const view = new DataView(buffer);

        writeString(view, 0, 'RIFF');
        view.setUint32(4, 36 + dataSize, true);
        writeString(view, 8, 'WAVE');
        writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, 1, true);
        view.setUint16(22, numberOfChannels, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * blockAlign, true);
        view.setUint16(32, blockAlign, true);
        view.setUint16(34, 16, true);
        writeString(view, 36, 'data');
        view.setUint32(40, dataSize, true);

        let offset = 44;
        for (let i = 0; i < interleavedData.length; i++, offset += 2) {
            const value = interleavedData[i] * 32767;
            view.setInt16(offset, value, true);
        }
        return new Blob([view], { type: 'audio/wav' });
    };

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
            const arrayBuffer = event.target.result;

            audioContext.decodeAudioData(
                arrayBuffer,
                function (buffer) {
                    resolve(createWAV(buffer));
                },
                function (error) {
                    reject(error);
                }
            );
        };

        reader.readAsArrayBuffer(webmData);
    });
};
