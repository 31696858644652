import { INBOX } from 'app/const/Api';
import { TYPE_INBOX } from 'common/redux/actions/inboxAction';
import { getFetch } from 'common/utils/ApiUtils';
import { call, put } from 'redux-saga/effects';

export function* getCounterInbox({ actionSuccess = () => {}, actionFailed = () => {} }) {
    const response = yield call(getFetch, INBOX, put);
    if (response && response.success) {
        yield put({
            type: TYPE_INBOX.GET_INBOX_COUNTER_SUCCESS,
            payload: response.total
        });
        actionSuccess(response);
    } else {
        actionFailed(response);
    }
}
