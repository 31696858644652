import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, matchPath, useLocation, Route, Switch } from 'react-router-dom';
import classNames from 'classnames';

import { PERMISSIONS } from 'app/const/Permissions';
import ErrorPage from 'app/modules/error';
import { checkPermission } from 'common/utils/PermissionUtils';
import SideMenu from './SideMenu';

import { WithTitle } from 'app/components/routes/RouteWithSubRoutes';
import 'app/modules/report/css/report.scss';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { REPORT_INVOICE, REPORT_MAIN_PATH } from 'app/config/routes';

function ReportLayout({ routes = [], ...props }) {
    const { t } = useTranslation(['auth']);
    const { permissions } = useSelector(({ auth }) => auth.user);
    const { pathname } = useLocation();

    // Add class for page when mount ->> remove when unmount
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('report-page', 'container-main');
        return () => {
            mainPageDiv.classList.remove('report-page', 'container-main');
        };
    }, []);

    if (!checkPermission(permissions?.enabled || [], PERMISSIONS.accessReportsTab)) {
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
    }

    if (matchPath(pathname, { path: addPrefixPath(REPORT_MAIN_PATH), exact: true }))
        return <Redirect to={addBranchPath(REPORT_INVOICE)} />;

    return (
        <div
            id="report_wrap"
            className={classNames('container-wrap container-table container-report custom-grid-sidebar', {
                'is-hide-panel': !getToggleLocal('toggledReport')
            })}
        >
            <SideMenu />
            <Switch>
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    return (
                        <Route
                            key={i}
                            path={result.path}
                            exact
                            render={() => (
                                <WithTitle title={route.title}>
                                    <route.component {...props} />
                                </WithTitle>
                            )}
                        />
                    );
                })}
            </Switch>
        </div>
    );
}

export default ReportLayout;
