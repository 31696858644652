import { ENTER_KEY_CODE } from 'app/const/App';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconPhone from 'assets/icon/IconPhone';
import { useContext, useRef } from 'react';

export const MakeCall = ({ onClose = () => {}, onGetPhone = () => {} }) => {
    const { onOutGoingCall } = useContext(VOIPContext);

    const refInputPhone = useRef(null);

    const handleMakeCall = () => {
        onOutGoingCall({
            fromNumber: onGetPhone(),
            toPhoneNumber: refInputPhone.current.value
        });
        refInputPhone.current.value = '';
        onClose();
    };

    const handlePressKeyUp = (e) => {
        if (e.keyCode === ENTER_KEY_CODE) handleMakeCall();
    };

    return (
        <div className="wrap-call-module__footer gap-module">
            <div className="flexcenter w-100 gap-8">
                <div className="phone-fied flex-1 relative">
                    <span className="phone-fied__icon absolute">
                        <IconPhone />
                    </span>
                    <input
                        ref={refInputPhone}
                        className="field-input pl-6"
                        type="text"
                        placeholder="Dial a number..."
                        onKeyUp={handlePressKeyUp}
                    />
                </div>
                <div className="v2-btn-main" onClick={handleMakeCall}>
                    Call
                </div>
            </div>
        </div>
    );
};
