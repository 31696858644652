import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import GdGridView from 'app/components/grid/GdGridView';
import GDStatusBar from 'app/components/status/statusbar';
import { PERMISSIONS_CUSTOM_FIELDS } from 'app/const/addons';
import { ACCOUNT_ROLE, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import ErrorPage from 'app/modules/error';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import { MANAGE_PERMISSIONS_ADDONS } from '../../constants';

const CustomFieldsPermissions = ({ addonsPermissionType = PERMISSIONS_CUSTOM_FIELDS }) => {
    const { t } = useTranslation(['pageTitle', 'common', 'addons']);
    const { reportType, getListEndPoint, historyPath, updateEndPoint, transPrevBtn, gridColumn, keyAddon } =
        MANAGE_PERMISSIONS_ADDONS[addonsPermissionType];
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const activeFieldAddon = useSelector(({ auth }) => auth.user.settings.addons[keyAddon]);
    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const havePermission =
        (isTrial || isGrowthPlan) && activeFieldAddon && userProfile.role === ACCOUNT_ROLE.SUPERADMIN;

    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, totalPage, isLoading } = state;
    const refAlert = useRef(null);
    const refTimer = useRef({});

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);
    getLocalParamsReport(keyLocal, reportType);

    const _handleSwitch = ({ id, value, key: keyPermission }) => {
        clearTimeout(refTimer.current[id]);
        let newPermission = [];

        dispatchState((prevState) => ({
            ...prevState,
            data: prevState.data.map((item) => {
                if (item.user.id === id) {
                    const permission = item.permission;
                    newPermission = value
                        ? [...permission, keyPermission]
                        : permission.filter((key) => key !== keyPermission);
                    return { ...item, permission: newPermission };
                }
                return item;
            })
        }));

        refTimer.current[id] = setTimeout(() => {
            clientQuery(updateEndPoint(id), {
                method: 'PUT',
                toFormData: false,
                data: { permissions: newPermission }
            });
        }, 500);
    };
    const _getPermissionList = () => {
        const params = getLocalStorage(keyLocal);
        const _handleGetSuccess = ({ data, total = 0 }) => {
            dispatchState((prevState) => ({
                ...prevState,
                data,
                total,
                isLoading: false,
                totalPage: Math.ceil(total / params.limit)
            }));
        };

        const _handleGetFail = ({ message }) => {
            _showStatus({ message });
            dispatchState((prevState) => {
                return { ...prevState, data: [], isLoading: false };
            });
        };

        clientQuery(getListEndPoint, { data: { ...params }, method: 'GET' }, _handleGetSuccess, _handleGetFail);
    };

    const _handleUpdate = () => {
        if (!isLoading) dispatchState({ isLoading: true });
        _getPermissionList();
    };

    const _showStatus = ({ message, type = LIST_STATUS.ERROR }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('permission', message, type);
    };

    useEffect(() => {
        if (!havePermission) return;
        _getPermissionList();
        return () => removeLocalStorage(keyLocal);
    }, []);

    if (!havePermission) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <div className="flexcenter gap-8">
                    <Link to={addBranchPath(historyPath)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t(transPrevBtn)}
                    </Link>
                </div>
            </div>
            <p className="description black-darklight fw-500">
                {t(
                    addonsPermissionType === PERMISSIONS_CUSTOM_FIELDS
                        ? 'addons:desc_custom_fields_manage'
                        : 'addons:desc_job_status_manage'
                )}
            </p>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline --custom-field container-print">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        <GdGridView
                            isScroll
                            isEmptyFlat
                            isLoading={isLoading}
                            content={data}
                            classTable="tables table-multi-column"
                            fileTranslation="report"
                            keyGetId="user.id"
                            {...gridColumn}
                            onChangeSwitchOppPermission={_handleSwitch}
                        />

                        <ReportPagination reportType={reportType} totalPage={totalPage || 1} onSelect={_handleUpdate} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomFieldsPermissions;
