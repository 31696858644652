import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ButtonLoadMore = forwardRef(({ onLoadMore, text }, ref) => {
    const [isLoading, setIsLoading] = useState(false);

    function _handleShow() {
        setIsLoading(true);
    }

    function _removeLoading() {
        isLoading && setIsLoading(false);
    }

    useImperativeHandle(ref, () => ({
        showLoading: _handleShow,
        removeLoading: _removeLoading
    }));

    function _handleClickLoadMore() {
        if (isLoading) {
            return false;
        }
        setIsLoading(true);
        onLoadMore();
    }

    return (
        <div className="rows-load-more" onClick={_handleClickLoadMore}>
            <div className="rows-load-more__btn flex-centeritem">
                {text || 'Load Notes'}
                {isLoading && (
                    <div className="preloader-small">
                        <span className="preloader-small__items" />
                    </div>
                )}
            </div>
        </div>
    );
});

export default ButtonLoadMore;
