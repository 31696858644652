import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TYPE_IN_COMMING } from 'app/const/Voip';
import IconPhone from 'assets/icon/IconPhone';
import IconMicrophone from 'assets/icon/IconMicrophone';
import IconClose from 'assets/icon/IconClose';
import TimeCounter from './Time';
import Dialpad from './Dialpad';
import Transfer from './Tranfer';
import IconPlus from 'assets/icon/IconPlus';
import IconAssign from 'assets/icon/IconAssign';
import { CUSTOMERS } from 'app/const/Route';
import IconCall from 'assets/icon/IconCall';
import ButtonSave from 'app/components/button/ButtonSave';
import ListBarge from './ListBarge';
import BargeBanner from './BargeBanner';
import { addBranchPath } from 'app/const/Branch';
import { calculateSecondVoip } from 'common/utils/TimeUtils';
import { AVATAR_DEFAULT } from 'app/const/App';
import ButtonHoldCall from './components/ButtonHoldCall';
import { reducer } from 'app/const/Reducer';
import IconSwitch from 'assets/icon/IconSwitch';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import SelectMultiContact from './SelectMultiContact';

export default function Incomming({
    item,
    onSwitchCall,
    onAcceptCall,
    onDisconnect,
    onHangUp,
    onCallBack,
    onMuteCall,
    onCallDial,
    onTransfer,
    onDelete,
    onCreateLead,
    onAssignLead,
    onInvite,
    features
}) {
    const { t } = useTranslation('header');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const {
        status: statusIncomming,
        id: callId,
        from: fromPhoneNumber,
        company_name: companyName,
        customer_name: customerName,
        customer_id: customerId,
        is_mute: isMuteCall,
        transferData,
        inviteData,
        connection,
        startTime,
        transfer_call_id: transferCallId,
        customer_title = ''
    } = item;

    const history = useHistory();
    const refInvite = useRef(null);

    const isUnknow = !!!customerId;
    const { invite_call_id: inviteId, from: fromUser, members } = inviteData || {};
    const fromPhone = !!inviteId ? connection.customParameters?.get('PhoneNumber') : fromPhoneNumber;

    const [state, dispatchState] = useReducer(reducer, {
        isShowHoldCall: true
    });
    const { isShowHoldCall } = state;

    useEffect(() => {
        if (statusIncomming === TYPE_IN_COMMING.END) {
            const timeout = !!inviteId ? 0 : 6000;
            setTimeout(() => {
                onDisconnect(callId);
            }, timeout);
        }

        if (statusIncomming === TYPE_IN_COMMING.TRANSFER) {
            setTimeout(() => {
                onDelete(callId);
            }, 2000);
        }
    }, [statusIncomming]);

    const _navigateCustomer = () => {
        !isUnknow &&
            customerId &&
            history.push(addBranchPath(`${CUSTOMERS}/${customerId}`), {
                isFromCall: true
            });
    };

    const _handleInviteCall = (callData, item) => {
        onInvite(callData, item);
        dispatchState({ isShowHoldCall: false });
    };

    const _handleHoldCall = (value) => {
        refInvite.current?._setDisable(value);
    };

    const _renderActions = () => {
        switch (statusIncomming) {
            case TYPE_IN_COMMING.CALLING:
                const _handleHangup = () => onHangUp(callId);

                if (!!inviteId) return <BargeBanner callData={item} onLeaveCall={_handleHangup} />;
                const callInfo = {
                    parentId: transferCallId,
                    callSId: callId,
                    inviteCallId: callId
                };

                return (
                    <Fragment>
                        <div className="call-bar__controll gap-md flexcenter">
                            <ListBarge listUser={members} />
                            {!!features['call_coaching'] ? (
                                <Fragment>
                                    <Transfer
                                        ref={refInvite}
                                        callData={{ ...item, ...callInfo }}
                                        isInvite
                                        isCloseOnSelect
                                        buttonClassName="btn-invite"
                                        onTransfer={_handleInviteCall}
                                    />
                                    <span className="is-divider" />
                                </Fragment>
                            ) : null}

                            <div
                                onClick={() => onMuteCall(callId, !isMuteCall)}
                                className={`v2-btn-default --icon-lg --transparent btn-bg-grey tooltip ${
                                    isMuteCall ? 'microphone-muted' : ''
                                }`}
                            >
                                <IconMicrophone />
                                <span className="tooltiptext bottom" id="tooltip_mute">
                                    Mute Microphone
                                </span>
                            </div>
                            <Transfer callData={item} onTransfer={onTransfer} isDisable={!!members?.length} />
                            <Dialpad onCallDial={onCallDial} callId={callId} />
                        </div>
                        <span className="is-divider" />
                        <div className="call-bar__action flexcenter pr-2 gap-none">
                            <TimeCounter isUseTime startAt={calculateSecondVoip(startTime)} />
                            <span
                                onClick={_handleHangup}
                                className="action-call is-icon bg-red-default flex-centeritem"
                            >
                                <IconPhone isClose />
                            </span>
                            <ButtonHoldCall
                                callInfo={callInfo}
                                onHoldCall={_handleHoldCall}
                                isHidden={!isShowHoldCall}
                            />
                        </div>
                        <span className="call-bar__status w-100" />
                    </Fragment>
                );
            case TYPE_IN_COMMING.BUSY:
                return (
                    <Fragment>
                        <div className="call-bar__action flexcenter pr-2">
                            <span
                                onClick={() => onSwitchCall(callId)}
                                className="action-call has-label bg-green-default flex-centeritem svg-white"
                            >
                                <IconPhone isBlack />
                                Switch to this call
                            </span>
                            <span
                                onClick={() => onDisconnect(callId, { isIgnore: true })}
                                className="v2-btn-default --icon-lg --transparent btn-bg-grey"
                            >
                                <IconClose />
                            </span>
                        </div>
                        <span className="call-bar__status w-100 is-calling" />
                    </Fragment>
                );
            case TYPE_IN_COMMING.RINGING:
                return (
                    <Fragment>
                        <div className="call-bar__action flexcenter pr-2">
                            <ButtonSave
                                wrapClass="action-call has-label bg-green-default flex-centeritem svg-white v2-btn-main pl-2"
                                onSave={() => onAcceptCall(callId, item)}
                                title={
                                    <Fragment>
                                        <IconPhone isBlack />
                                        Accept
                                    </Fragment>
                                }
                            />
                            <span
                                onClick={() => onDisconnect(callId, { isReject: true })}
                                className="action-call is-icon bg-red-default flex-centeritem tooltip"
                            >
                                <IconPhone isClose />
                                <p className="tooltiptext bottom">Drop Call</p>
                            </span>
                            <span className="is-divider m-0"></span>
                            <span
                                onClick={() => onDisconnect(callId, { isIgnore: true })}
                                className="v2-btn-default --icon-lg --transparent btn-bg-grey tooltip"
                            >
                                <IconClose />
                                <p className="tooltiptext bottom ml-n3">Ignore Call</p>
                            </span>
                        </div>
                        <span className="call-bar__status w-100 is-calling" />
                    </Fragment>
                );
            case TYPE_IN_COMMING.END:
                return (
                    <Fragment>
                        <div className="call-bar__controll gap-md flexcenter">
                            <span className="status-label">Call Ended</span>
                        </div>
                        <span className="is-divider" />
                        <div className="call-bar__action flexcenter pr-2">
                            <span
                                onClick={() =>
                                    onCallBack({
                                        fromNumber: null,
                                        toPhoneNumber: fromPhone,
                                        isCallBack: true
                                    })
                                }
                                className="action-call has-label flex-centeritem border-grey-verylight grey-very-dark"
                            >
                                <IconPhone isBlack />
                                Call back
                            </span>
                        </div>

                        <span className="call-bar__status w-100 is-end" />
                    </Fragment>
                );

            case TYPE_IN_COMMING.TRANSFER:
            case TYPE_IN_COMMING.TRANSFERRING:
                const isTranferToUser = transferData.full_name;

                return (
                    <div className="call-bar__action flexcenter pr-2">
                        <span className="flex-auto">{t(`${statusIncomming}_to`)}</span>
                        <span className="schedule-user">
                            <span className="tech-name m-0">
                                {!!isTranferToUser && (
                                    <img className="avt-img" src={transferData.avatar} width={24} height={24} />
                                )}
                                <span className="tech-name__label">
                                    {!!isTranferToUser ? transferData.full_name : transferData.phone_number}
                                </span>
                            </span>
                        </span>
                    </div>
                );

            default:
                return false;
        }
    };

    const _renderIconType = () => {
        if (statusIncomming === TYPE_IN_COMMING.CALLING && !!inviteId) {
            const avatar = companyUsers.find(({ id }) => id === fromUser)?.avatar;
            return (
                <div className="avt-has-micro relative">
                    <img className="avt-img --lg" src={avatar || AVATAR_DEFAULT} width={32} height={32} />
                    <span className="avt-has-micro__icon absolute">
                        <IconCall isOnCall />
                    </span>
                </div>
            );
        }

        return (
            <Fragment>
                <IconPhone isCallIn />
                IN
            </Fragment>
        );
    };

    const _renderTransferDetail = () => {
        const userInfo = getUserInfo(connection.customParameters.get('UserId'));

        if (!transferCallId || !userInfo) return null;
        return (
            <div className="flexcenter gap-8 flex-1">
                <IconSwitch isTransfer />
                <div className="schedule-user">
                    <div className="tech-name mt-0">
                        <div className="avt-img">
                            <img src={userInfo.avatar} width={24} height={24} />
                        </div>
                        <span className="txt-ellipsis">{userInfo.full_name}</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="call-bar flexcenter">
            <span className="call-bar__btn flex-column align-center fw-500">{_renderIconType()}</span>
            <div className="call-bar__info flex-1">
                <div className="gap-md flexcenter">
                    <div className="valid-name">
                        <span
                            onClick={_navigateCustomer}
                            className={`valid-name__title --purple cursor-pointer ${isUnknow ? 'black-6' : 'is-link'}`}
                        >
                            {customerName || 'Unknown'}
                        </span>
                        {!isUnknow && customer_title ? <div className="label-content">{customer_title}</div> : null}
                    </div>
                    {isUnknow && (
                        <Fragment>
                            <div
                                onClick={() => onCreateLead(callId, fromPhone)}
                                className="v2-btn-default has-icon btn-bg-purple --sm svg-purple js-create-lead"
                            >
                                <IconPlus isCircle />
                                Create Lead
                            </div>
                            <div
                                onClick={() => onAssignLead(callId, fromPhone)}
                                className="v2-btn-default has-icon btn-bg-purple --sm js-assign-lead"
                            >
                                <IconAssign />
                                Assign Lead
                            </div>
                        </Fragment>
                    )}
                    {_renderTransferDetail()}
                </div>

                <div className="flexcenter mt-2">
                    {!!companyName && (
                        <span
                            onClick={_navigateCustomer}
                            className="status-btn --sm bg-black-light fs-14 purple-default txt-ellipsis"
                        >
                            {companyName}
                        </span>
                    )}
                    <SelectMultiContact item={item} phoneNumber={fromPhone} />
                </div>
            </div>

            {_renderActions()}
        </div>
    );
}
