import React, { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconViewPdf from 'assets/icon/IconViewPdf';
import IconPrint from 'assets/icon/IconPrint';
import IconEmail from 'assets/icon/IconEmail';
import IconSentSms from 'assets/icon/IconSentSms';
import IconSent from 'assets/icon/IconSent';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SendByEmail from 'app/modules/jobdetail/tabs/invoice/actions/SendByEmail';
import SendBySms from 'app/modules/jobdetail/tabs/invoice/actions/SendBySms';
import SendByEmailAndSms from 'app/modules/jobdetail/tabs/invoice/actions/SendByEmailAndSms';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    GET_TEMPLATE_EMAIL_STATEMENT,
    GET_TEMPLATE_SMS_STATEMENT,
    sendMailStateMentCustomer,
    sendSmsStateMentCustomer
} from 'app/const/api/V2';
import { LIST_ACTION_HEADER_IN_DETAIL_INVOICE } from 'app/modules/jobdetail/const';
import { COMMON } from 'app/const/App';
import { batchActionStatement } from 'app/modules/report/accountsaging/components/BatchActionFunction';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';

const MoreOptions = ({ paramsStatement = {}, idCustomer }) => {
    const { t } = useTranslation('customers');
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);
    const refSendByEmail = useRef(null);
    const refSendBySms = useRef(null);
    const refSendByEmailAndSms = useRef(null);
    const activateSms = useSelector((state) => state.auth.user?.settings?.addons?.activate_sms || false);
    const {
        type = 2,
        location_ids = -1,
        start = '',
        end = '',
        columns = [],
        statement_type = 2
    } = paramsStatement || {};
    const listColumns = columns.map((column) => {
        return `statement_${column}`;
    });
    const paramsGetTemplateEmail = { customer_id: idCustomer };
    const paramsGetTemplateSms = {
        customer_id: idCustomer,
        type: type,
        location_id: location_ids,
        start: start,
        end: end,
        list_columns: listColumns.toString()
    };
    const urlGetTemplateEmail = GET_TEMPLATE_EMAIL_STATEMENT;
    const urlGetTemplateSms = GET_TEMPLATE_SMS_STATEMENT;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_options_send');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_options_send');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOnClick = (value) => {
        switch (value) {
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.GENERATE_PDF:
                batchActionStatement({
                    typeExport: COMMON.PDF,
                    ids: idCustomer,
                    paramsExport: paramsStatement,
                    typeBatchAction: LIST_EXPORT.EXPORT_CUSTOMER_STATEMENT_BATCH_ACTION
                });
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.GENERATE_PRINT:
                batchActionStatement({
                    typeExport: COMMON.PRINT,
                    ids: idCustomer,
                    paramsExport: paramsStatement,
                    typeBatchAction: LIST_EXPORT.EXPORT_CUSTOMER_STATEMENT_BATCH_ACTION
                });
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL:
                refSendByEmail.current._handleOpenForm();
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_SMS:
                refSendBySms.current._handleOpenForm(type);
                break;
            case LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL_AND_SMS:
                refSendByEmailAndSms.current._handleOpenForm();
                break;
            default:
                break;
        }
        _closeSearchResult();
    };

    function _handleSendEmail(params, callback) {
        const optionQuery = {
            method: 'POST',
            toFormData: false,
            data: {
                ...params,
                address: params.receiver,
                content: params.message,
                type: type,
                location_id: location_ids,
                start: start,
                end: end,
                list_columns: listColumns.toString(),
                statement_type: statement_type
            }
        };

        clientQuery(
            sendMailStateMentCustomer(idCustomer),
            optionQuery,
            (response) => _handleSendEmailSuccess(response, callback),
            (response) => _handleSendEmailFail(response, callback)
        );
    }

    function _handleSendEmailSuccess(response, callback) {
        if (typeof callback === 'function') {
            callback(true, response.message.toString() || t('customers:send_statement_success'));
        }
    }

    function _handleSendEmailFail(response, callback) {
        if (typeof callback === 'function') {
            callback(false, response.message.toString() || t('customers:send_statement_fail'));
        }
    }

    function _handleSendSms(params, callback) {
        const optionQuery = {
            method: 'POST',
            toFormData: false,
            data: {
                address: params.sms_address,
                content: params.sms_content
            }
        };
        clientQuery(
            sendSmsStateMentCustomer(idCustomer),
            optionQuery,
            (response) => _handleSendSMSSuccess(response, callback),
            (response) => _handleSendSMSFail(response, callback)
        );
    }

    function _handleSendSMSSuccess(response, callback) {
        if (typeof callback === 'function') {
            callback(true, response.message.toString() || t('customers:send_statement_success'));
        }
    }

    function _handleSendSMSFail(response, callback) {
        if (typeof callback === 'function') {
            callback(false, response.message.toString() || t('customers:send_statement_fail'));
        }
    }

    return (
        <>
            <div className="flexcenter flex-wrap gap-4">
                <div className="wrap-btn-default flexcenter">
                    <div
                        className="v2-btn-default has-icon --transparent"
                        onClick={() => _handleOnClick(LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL)}
                    >
                        <IconEmail /> {t('sent_by_email')}
                    </div>

                    {activateSms && (
                        <>
                            <span className="is-divider mx-0"></span>
                            <div
                                className="v2-btn-default has-icon --transparent"
                                onClick={() => _handleOnClick(LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_SMS)}
                            >
                                <IconSentSms /> {t('customers:send_by_sms')}
                            </div>
                            <span className="is-divider mx-0"></span>
                            <div
                                className="v2-btn-default has-icon --transparent"
                                onClick={() =>
                                    _handleOnClick(LIST_ACTION_HEADER_IN_DETAIL_INVOICE.SEND_BY_EMAIL_AND_SMS)
                                }
                            >
                                <IconSent /> {t('customers:send_by_email_sms')}
                            </div>
                        </>
                    )}
                </div>
                <span className="is-divider mx-1"></span>
                <div
                    className="v2-btn-default has-icon"
                    onClick={() => _handleOnClick(LIST_ACTION_HEADER_IN_DETAIL_INVOICE.GENERATE_PDF)}
                >
                    <IconViewPdf />
                    {t('view_pdf')}
                </div>
                <div
                    className="v2-btn-default has-icon"
                    onClick={() => _handleOnClick(LIST_ACTION_HEADER_IN_DETAIL_INVOICE.GENERATE_PRINT)}
                >
                    <IconPrint /> {t('print')}
                </div>
            </div>
            <SendByEmail
                customerId={idCustomer}
                ref={refSendByEmail}
                onSend={_handleSendEmail}
                urlGetEmail={urlGetTemplateEmail}
                paramsEmail={paramsGetTemplateEmail}
            />
            {activateSms && (
                <SendBySms
                    ref={refSendBySms}
                    customerId={idCustomer}
                    onSend={_handleSendSms}
                    params={paramsGetTemplateSms}
                    urlGetSMS={urlGetTemplateSms}
                />
            )}
            {activateSms && (
                <SendByEmailAndSms
                    ref={refSendByEmailAndSms}
                    customerId={idCustomer}
                    onSendEmail={_handleSendEmail}
                    onSendSMS={_handleSendSms}
                    urlGetSMS={urlGetTemplateSms}
                    params={paramsGetTemplateSms}
                    urlGetEmail={urlGetTemplateEmail}
                    paramsEmail={paramsGetTemplateEmail}
                />
            )}
        </>
    );
};

export default MoreOptions;
