import { getListTaxes } from 'common/redux/actions/taxesAction';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';
import LocationTax from './LocationTax';

const LocationTaxes = ({ defaultValue = [] }, ref) => {
    const refFirstTax = useRef(null);
    const refSecondTax = useRef(null);
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({ _getValue }));

    useEffect(() => {
        // eslint-disable-next-line no-undef
        !global.fetchedListTax && _handleGetListTaxes();
    }, []);

    const _getValue = () => ({ tax1_id: refFirstTax.current._getValue(), tax2_id: refSecondTax.current._getValue() });

    const _handleGetListTaxes = () => {
        dispatch(getListTaxes({ fields: 'rate' }, () => {}));
    };

    return (
        <div className="column-content column-tax">
            <div className="tax-rate">
                <div className="col-2 text-info">
                    When adding invoice items for this location automatically assign these tax rates.
                </div>
                <div className="col-2">
                    <div className="rows --multi">
                        <div className="col">
                            <div className="txt txt-ellipsis">Tax 1</div>
                            <LocationTax id="item_tax1" ref={refFirstTax} defaultSelected={defaultValue[0]} />
                        </div>
                        <div className="col">
                            <div className="txt txt-ellipsis">Tax 2</div>
                            <LocationTax id="item_tax2" ref={refSecondTax} defaultSelected={defaultValue?.[1] || {}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default forwardRef(LocationTaxes);
