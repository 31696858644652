import moment from 'moment';

/**
 * Sort the tasks by date, and if the dates are the same, sort by created_at
 * @param [tasks] - The array of tasks to be sorted.
 * @returns An array of tasks sorted by date and created_at.
 */
export const sortTaskList = (tasks = []) => {
    return tasks.sort(function (a, b) {
        const dateA = moment(a.date).unix(),
            dateB = moment(b.date).unix(),
            createdA = a.created_at,
            createdB = b.created_at;

        if (dateA > dateB) return -1;
        if (dateA < dateB) return 1;
        else {
            if (createdA > createdB) return -1;
            if (createdA < createdB) return 1;
            return 0;
        }
    });
};
