import GdButton from 'app/components/button';
import GdConfirm from 'app/components/confirm';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { useRef } from 'react';

import { useTranslation } from 'react-i18next';
import Loadmore from './Loadmore';
import { getTitleTemplate } from 'app/modules/settings/utils';

const Items = ({
    onSelect = () => {},
    onDelete = () => {},
    items = [],
    selected = null,
    onScroll = () => {},
    isLoadmore = false,
    isLoadSearch = false,
    tagTemplate = ''
}) => {
    const { t } = useTranslation(['setting']);

    const refConfirm = useRef(null);

    const _openConfirmDelete = (e, id) => {
        e.stopPropagation();
        refConfirm.current.open(id);
    };

    const _renderList = () => {
        return items.map((item) => {
            const { id, name, type } = item;

            return (
                <div
                    className={classNames('list-details__items', { active: selected === id })}
                    key={id}
                    onClick={() => onSelect(item)}
                >
                    <div className="template-name flex-1">
                        <p className="word-break">{name}</p>
                        <span className="status-btn --sm bg-granite-gray">
                            {t(getTitleTemplate({ title: tagTemplate, value: type }))}
                        </span>
                    </div>
                    <GdButton
                        className="v2-btn-default --icon-lg --transparent svg-delete-grey"
                        iconSvg={<IconTrash />}
                        onClick={(e) => _openConfirmDelete(e, id)}
                    />
                </div>
            );
        });
    };

    return (
        <div className="list-details is-active" onScroll={onScroll}>
            {isLoadSearch ? <Loadmore /> : _renderList()}
            {isLoadmore && <Loadmore />}
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('setting:confirm_delete_template')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={onDelete}
            />
        </div>
    );
};
export default Items;
