import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import IconForward from 'assets/icon/IconForward';
import { LIST_SIDE_BAR_OPPORTUNITY } from 'app/const/opportunity';
import { checkPermission } from 'common/utils/PermissionUtils';
import { addBranchPath } from 'app/const/Branch';
import { ROUTE_OPPORTUNITY_REPORT } from '../const';

const OpportunitySideBar = () => {
    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const isAccessOpportunity =
        !!opportunityAddon &&
        permissionsList.some((permission) => Object.keys(ROUTE_OPPORTUNITY_REPORT).includes(permission));

    return LIST_SIDE_BAR_OPPORTUNITY.map((item) => {
        if (!isAccessOpportunity) return null;

        const { id, isSub, permission } = item;
        const isHavePermission = checkPermission(permissionsList, permission);

        if (isSub) {
            if (!isHavePermission) return null;

            return (
                <div key={id} className="flexcenter gap-8">
                    <IconForward isBottom />
                    <ItemSideBar item={item} />
                </div>
            );
        }

        return <ItemSideBar key={id} item={item} isDisable={!isHavePermission} />;
    });
};

export default OpportunitySideBar;

const ItemSideBar = ({ item = {}, isDisable = false }) => {
    const { t } = useTranslation('report');
    const { id, path, title, icon } = item;

    return (
        <NavLink
            key={id}
            to={addBranchPath(path)}
            className={classNames('sidebar-items flexcenter gap-4', { 'is-disable': isDisable })}
            activeClassName="active text-capitalize fw-600"
        >
            {icon}
            <span className="txt-ellipsis text-capitalize">{t(title)}</span>
        </NavLink>
    );
};
