const Loadmore = () => {
    return (
        <div className="wrap-loading">
            <div className="list-details__items pointer-events-none">
                <div className="template-name flex-1 ">
                    <div className="loading --animation --line --threefourth"></div>
                    <div className="status-btn --sm loading --onefifth mt-1 bg-white-cultured"></div>
                </div>
                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured"></div>
            </div>
        </div>
    );
};
export default Loadmore;
