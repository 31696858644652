import classNames from 'classnames';
import React, { useId } from 'react';
import { useSelector } from 'react-redux';

import ItemJobStatus from 'app/components/job/ItemJobStatus';
import { REFERENCE_TYPES } from 'app/modules/smartview/constants/types';

const ReferenceItem = ({
    id = '',
    user_id = '',
    avatar = '',
    label = '',
    color = '',
    referencedType = '',
    status = '',
    full_name,
    name,
    isJobStatus = false,
    isActive = false,
    onChange = () => {}
}) => {
    const idCheckbox = useId();
    const isStatusOption = referencedType === REFERENCE_TYPES.STATUS_OPPORTUNITY;
    const isScheduleOption = referencedType === REFERENCE_TYPES.SCHEDULES;

    return (
        <li className={classNames('items', { active: isActive })} onClick={onChange}>
            <div className="check-items">
                <input
                    disabled
                    id={idCheckbox}
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => e.preventDefault()}
                />
                <div className="item-checkbox">
                    {!!isScheduleOption || !!avatar ? (
                        <LabelWithAvatar idCheckbox={idCheckbox} label={full_name || name} userId={user_id || id} />
                    ) : isJobStatus ? (
                        <label htmlFor={idCheckbox}>
                            <ItemJobStatus
                                classTextItem="status-btn --job-status"
                                defaultStatus={{ name, label, color }}
                                excludeWrapper
                            />
                        </label>
                    ) : (
                        <label htmlFor={idCheckbox}>
                            {isStatusOption ? (
                                <span className={classNames('dots', { [`--${status?.toLowerCase()}`]: !!status })} />
                            ) : null}
                            {label}
                        </label>
                    )}
                </div>
            </div>
        </li>
    );
};

const LabelWithAvatar = ({ idCheckbox, label, userId }) => {
    const users = useSelector(({ companyUsers }) => companyUsers.users || []);
    const user = users.find((user) => user?.id?.toString() === userId?.toString()) || {};
    return (
        <label htmlFor={idCheckbox} className="d-flex align-center gap-4">
            {user.avatar ? (
                <div className="avt-img">
                    <img loading="lazy" src={user?.avatar} width="100%" height="100%" alt="" />
                </div>
            ) : (
                <div className="avt fs-11">{label.substring(0, 2)}</div>
            )}
            <span className="txt-ellipsis">{label}</span>
        </label>
    );
};

export default ReferenceItem;
