import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useLayoutEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { OPTIONS_AUTOMATED_MESSAGE_TYPE, TYPE_TEMPLATE } from 'app/const/Settings';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconClose from 'assets/icon/IconClose';
import IconDropDown from 'assets/icon/IconDropDown';
import { useSettingTemplate } from '../SettingTemplateContext';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';

const HeaderTemplate = (props, ref) => {
    const refAlert = useRef(null);
    const { template, typeTemplate, onCloseTemplate } = useSettingTemplate();

    useImperativeHandle(ref, () => ({
        displayAlert: (dataAlert) => {
            refAlert.current.showStatusBar(dataAlert);
        }
    }));

    const getHeaderTemplate = () => {
        switch (typeTemplate) {
            case TYPE_TEMPLATE.BROADCAST:
            case TYPE_TEMPLATE.EMAIL_INBOX:
                return <HeaderWithDescription title="Template Name" />;
            case TYPE_TEMPLATE.AUTO_MESSAGE:
                return <HeaderWithDropdown />;
            default:
                return <HeaderWithDescription />;
        }
    };

    return (
        <div className="wrap-header">
            <div className="flex-betweenitems">
                <h3 className="flex-1 fs-17 mr-2 txt-ellipsis">{template.title}</h3>
                <div className="v2-btn-default --icon-lg --transparent" onClick={onCloseTemplate}>
                    <IconClose />
                </div>
            </div>
            <AlertCustomer ref={refAlert} />
            {getHeaderTemplate()}
            <div className="tabs-content flexcenter mt-3">
                <TabListButton />
            </div>
        </div>
    );
};

const HeaderWithDropdown = () => {
    const { template, onUpdateTypeReminder, onChangeName, onUpdateFetchedVariables } = useSettingTemplate();
    const defaultReminder = OPTIONS_AUTOMATED_MESSAGE_TYPE[0];
    const [state, dispatchState] = useReducer(reducer, { selected: defaultReminder });
    const { selected } = state;
    const refDropdown = useRef(null);

    useLayoutEffect(() => {
        dispatchState({
            selected:
                OPTIONS_AUTOMATED_MESSAGE_TYPE.find((item) => item.value === parseInt(template.type)) || defaultReminder
        });
    }, [template?.type]);

    const _handleSelect = (value) => {
        if (selected?.id === value) return;
        onUpdateFetchedVariables(false);
        const newSelect = OPTIONS_AUTOMATED_MESSAGE_TYPE.find((item) => item.value === value) || defaultReminder;
        dispatchState({ selected: newSelect });
        onUpdateTypeReminder(newSelect);
    };

    const _handleChangeName = (e) => {
        onChangeName(e.target.value);
    };

    return (
        <div className="flex-betweenitems mt-3">
            <div className="col flex-1 mr-1">
                <p className="title-sm">Message Name</p>
                <input
                    className="field-input"
                    type="text"
                    placeholder="Message Name"
                    defaultValue={template.name}
                    onChange={_handleChangeName}
                />
            </div>
            <div className="col flex-1 mr-1">
                <p className="title-sm">Type</p>
                <CalendarDropdown
                    id="dropdown_template_type"
                    ref={refDropdown}
                    selected={selected?.name}
                    selectedOption={selected}
                    options={OPTIONS_AUTOMATED_MESSAGE_TYPE}
                    customDropIcon={<IconDropDown />}
                    onSelect={_handleSelect}
                    buttonClassName="dropbtn items bg-white"
                />
            </div>
        </div>
    );
};

const HeaderWithDescription = ({ title = '' }) => {
    const { template, onChangeName } = useSettingTemplate();
    const userInfo = useSelector(({ auth }) => auth.user);
    const emailCompany = userInfo.company.email || '';

    const _handleChangeName = (e) => {
        onChangeName(e.target.value);
    };

    return (
        <>
            {!!title ? (
                <div className="flex-betweenitems mt-3">
                    <div className="col flex-1">
                        <p className="title-sm">{title}</p>
                        <input
                            className="field-input"
                            type="text"
                            placeholder={title}
                            defaultValue={template.name}
                            onChange={_handleChangeName}
                        />
                    </div>
                </div>
            ) : null}
            {!!template.description ? (
                <div className="description fs-13 mt-1">
                    {template.description}{' '}
                    <a href={`mail:${emailCompany}`} className="purple-default">
                        {emailCompany}
                    </a>
                    .
                </div>
            ) : null}
        </>
    );
};

const TabListButton = ({ countTabOver = 3 }) => {
    const { template, activeIndex, onChangeReminder } = useSettingTemplate();
    const reminderLength = template?.reminders?.length || 0;

    if (reminderLength <= 1) return null;
    const isOverTabs = reminderLength > countTabOver;
    return new Array(reminderLength).fill(null).map((_, index) => {
        // Should plus 1 because index start from 0
        const id = parseInt(index + 1);

        const renderTitleTab = () => {
            if (isOverTabs && index >= 1) return `#${id}`;
            return `${template.tab_title} ${id}`;
        };

        return (
            <div
                key={id}
                className={classNames('tabs-content__item', { active: activeIndex === id })}
                onClick={() => onChangeReminder(id)}
            >
                {renderTitleTab()}
            </div>
        );
    });
};

HeaderTemplate.displayName = 'HeaderTemplate';
export default forwardRef(HeaderTemplate);
