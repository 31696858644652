import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-loading">
            <div className="box-has-rows pt-6">
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field has-many-field">
                        <div className="has-many-field__items">
                            <div className="first-field">
                                <div className="v2-btn-default loading --grey btn-sm">
                                    <div className="loading --animation --line --full" />
                                </div>
                            </div>
                            <div className="second-field flex-1">
                                <div className="v2-btn-default loading --grey --full">
                                    <div className="loading --animation --line --fourth" />
                                </div>
                            </div>
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="rows__field has-many-field">
                        <div className="has-many-field__items contact-field" />
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-has-rows">
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field has-many-address">
                        <div className="v2-btn-default loading --grey field-input field-street --first pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-street --second pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-city pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-state pointer-events-none">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-zip pointer-events-none">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field field-h32">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field has-many-address">
                        <div className="v2-btn-default loading --grey field-input field-street --first pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-street --second pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-city pointer-events-none">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-state pointer-events-none">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey field-input field-zip pointer-events-none">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field has-many-field">
                        <div className="has-many-field__items v2-btn-default loading --grey --full">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="rows__field">
                        <div className="v2-btn-default loading --grey field-input --location-note pointer-events-none" />
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="rows__field">
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="rows__field v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                </div>
            </div>
            <div className="box-has-rows">
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="rows__field">
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="rows">
                    <div className="rows__label field-h32">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="rows__field has-many-field">
                        <div className="has-many-field__items">
                            <div className="info-card flex-1">
                                <div className="card-number bg-white-grey">
                                    <div className="card-number__label">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="number">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                    <div className="date bg-background-grey ml-2" />
                                </div>
                            </div>
                        </div>
                        <div className="has-many-field__items">
                            <div className="info-card flex-1">
                                <div className="card-number bg-white-grey">
                                    <div className="card-number__label">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="number">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                    <div className="date bg-background-grey ml-2" />
                                </div>
                            </div>
                        </div>
                        <div className="v2-btn-default loading --grey btn-lg">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --grey btn-lg mt-1">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LoadingFastForm = () => (
    <div className="wrap-loading" style={{ display: 'none' }}>
        <div className="box-has-rows">
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field v2-btn-default loading --grey">
                    <div className="loading --animation --line --onefifth" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field v2-btn-default loading --grey">
                    <div className="loading --animation --line --onefifth" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows__field v2-btn-default loading --grey">
                    <div className="loading --animation --line --onefifth" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field has-many-field">
                    <div className="has-many-field__items">
                        <div className="first-field">
                            <div className="v2-btn-default loading --grey btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="second-field flex-1">
                            <div className="v2-btn-default loading --grey --full">
                                <div className="loading --animation --line --fourth" />
                            </div>
                        </div>
                    </div>
                    <div className="v2-btn-default loading --grey btn-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
        <div className="box-has-rows">
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field v2-btn-default loading --grey">
                    <div className="loading --animation --line --onefifth" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field v2-btn-default loading --grey">
                    <div className="loading --animation --line --onefifth" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field has-many-address">
                    <div className="v2-btn-default loading --grey field-input field-street --first pointer-events-none">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default loading --grey field-input field-street --second pointer-events-none">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default loading --grey field-input field-city pointer-events-none">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="v2-btn-default loading --grey field-input field-state pointer-events-none">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey field-input field-zip pointer-events-none">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="rows__label field-h32">
                    <div className="loading --animation --line --fourth" />
                </div>
                <div className="rows__field field-h32">
                    <div className="loading --animation --line --onefourth" />
                </div>
            </div>
        </div>
    </div>
);

Loading.displayName = 'AddNewCustomerLoading';
export default Loading;
