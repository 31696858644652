import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import GdAvatar from 'app/components/avatar';
import { MODULE_SMS } from 'app/const/Sms';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';

const ItemConversation = ({ item = {} }) => {
    const { company, settings } = useSelector(({ auth }) => auth.user);
    const { message, sender = {}, type, date } = item || {};
    const { full_name, avatar } = sender || {};
    const isSendTo = type === MODULE_SMS.TYPE_SMS.TO;
    const className = isSendTo ? 'box-message-to' : 'box-message-send';

    const _renderAvatar = () => {
        return (
            <div className="images">
                <div className="avt-img">
                    <GdAvatar src={avatar} alt={message} width={24} height={24} />
                </div>
            </div>
        );
    };

    const _renderSms = () => {
        const dateString = moment
            .unix(date)
            .tz(settings.timezone)
            .format(`${convertPhpFormatToMoment(company.date_format)}, hh:mmA`);
        return (
            <Fragment>
                {isSendTo ? _renderAvatar() : null}
                <div className="wrap-items">
                    <div className="description">{message}</div>
                    <div className="name-content">
                        {isSendTo ? (
                            <Fragment>
                                <div className="name">{full_name}</div>
                                <div className="time">{dateString}</div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <span className="name">{full_name}, </span>
                                <span className="time">{dateString}</span>
                            </Fragment>
                        )}
                    </div>
                </div>
                {isSendTo ? null : _renderAvatar()}
            </Fragment>
        );
    };

    return <div className={classNames('wrapper-message', className)}>{_renderSms()}</div>;
};

export default ItemConversation;
