import { LOGIN, LOGIN_CLASSIC, LOGIN_SOCIAL, SIGNUP_SOCIAL } from 'app/const/Api';
import i18n from 'assets/i18n';
import IconBackupCodes from 'assets/icon/IconBackupCodes';
import IconEmail from 'assets/icon/IconEmail';
import IconViewRow from 'assets/icon/IconViewRow';

export const ID_INPUT_AUTH_VERIFY = 'input_auth_verify';
export const ID_PRINT_BACKUP_CODES = 'print_backup_codes';

export const TWO_AUTHENTICATION_TYPES = { GOOGLE: 'auth_code', EMAIL: 'email', BACKUP: 'backup_code' };

export const METHOD_2FA = {
    [TWO_AUTHENTICATION_TYPES.EMAIL]: {
        icon: <IconEmail />,
        title: i18n.t(`auth:${TWO_AUTHENTICATION_TYPES.EMAIL}`),
        description: 'email_description',
        lengthCode: 6
    },
    [TWO_AUTHENTICATION_TYPES.GOOGLE]: {
        icon: <IconViewRow isGrid />,
        title: i18n.t(`auth:${TWO_AUTHENTICATION_TYPES.GOOGLE}`),
        description: 'auth_code_description',
        lengthCode: 6
    },
    [TWO_AUTHENTICATION_TYPES.BACKUP]: {
        icon: <IconBackupCodes />,
        title: i18n.t(`auth:${TWO_AUTHENTICATION_TYPES.BACKUP}`),
        description: 'backup_code_description',
        lengthCode: 12
    }
};

export const TWO_AUTHENTICATION_OPTIONS = [
    {
        id: TWO_AUTHENTICATION_TYPES.GOOGLE,
        name: i18n.t('setting:authentication_app')
    },
    {
        id: TWO_AUTHENTICATION_TYPES.EMAIL,
        name: i18n.t('setting:email')
    },
    {
        id: TWO_AUTHENTICATION_TYPES.BACKUP,
        name: i18n.t('setting:backup_codes')
    }
];

export const TWO_AUTHENTICATION_EDIT_TYPES = { DEFAULT: 'default', UPDATE: 'update', VIEW: 'view', DELETE: 'delete' };

export const TWO_AUTHENTICATION_EDIT_OPTIONS = [
    {
        id: TWO_AUTHENTICATION_EDIT_TYPES.DEFAULT,
        name: i18n.t('set_as_defaults')
    },
    {
        id: TWO_AUTHENTICATION_EDIT_TYPES.UPDATE,
        name: i18n.t('update')
    },
    {
        id: TWO_AUTHENTICATION_EDIT_TYPES.VIEW,
        name: i18n.t('view')
    },
    {
        id: TWO_AUTHENTICATION_EDIT_TYPES.DELETE,
        name: i18n.t('delete')
    }
];

export const TYPE_VERIFY_MODAL_AUTH = { ADD: 'add', UPDATE: 'update' };

export const TITLE_MODAL_AUTH = {
    [TYPE_VERIFY_MODAL_AUTH.ADD]: {
        [TWO_AUTHENTICATION_TYPES.GOOGLE]: i18n.t('setting:add_authentication_app'),
        [TWO_AUTHENTICATION_TYPES.EMAIL]: i18n.t('setting:add_authentication_email'),
        [TWO_AUTHENTICATION_TYPES.BACKUP]: i18n.t('setting:backup_codes_generated')
    },
    [TYPE_VERIFY_MODAL_AUTH.UPDATE]: {
        [TWO_AUTHENTICATION_TYPES.GOOGLE]: i18n.t('setting:update_authentication_app'),
        [TWO_AUTHENTICATION_TYPES.EMAIL]: i18n.t('setting:update_authentication_email'),
        [TWO_AUTHENTICATION_TYPES.BACKUP]: i18n.t('setting:backup_codes_generated')
    }
};
export const DEFAULT_STEP_VERIFY = 1;
export const SYMBOL_PLUS = '+';
export const LINK_DOWNLOAD_GOOGLE_AUTHENTICATOR_APP = {
    ANDROID: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
    IOS: 'https://apps.apple.com/us/app/google-authenticator/id388497605'
};
export const DEFAULT_TIMEOUT = 1000;
export const LIST_API_ACCESS_REDIRECT_2FA = [LOGIN, SIGNUP_SOCIAL, LOGIN_SOCIAL, LOGIN_CLASSIC];
