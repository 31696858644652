import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { JOB_MAGNET } from 'app/const/Permissions';

export const getGridColumns = ({ permissionId = JOB_MAGNET }) => {
    const HEADER_GRID = {
        user: { title: 'user', style: 'col col-lg' },
        role: { title: 'role', style: 'col col-role' },
        permission: { title: 'permission', style: 'col col-sm' }
    };
    const COLUMNS_GRID = [
        { id: 'user', title: 'user', status: true },
        { id: 'role', title: 'account', status: true },
        { id: 'permission', title: 'permission', status: true, keyGetValue: 'permission' }
    ];
    const CONTENT_GRID = {
        user: { id: 'user', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.USER_HAS_AVT },
        role: { id: 'role', style: 'col col-role', type: LIST_TABLE_ITEM_TYPE.ROLE_USER },
        permission: {
            id: permissionId,
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
        }
    };

    return { header: HEADER_GRID, columns: COLUMNS_GRID, contentConfig: CONTENT_GRID };
};
