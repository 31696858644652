import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnterKeydownClick } from 'common/hooks';

const Add = forwardRef(
    ({ defaultValue = '', onSave = () => {}, onClose = () => {}, autoOpen = 0, isAddNew = false }, ref) => {
        const { t } = useTranslation(['common', 'jobDetail']);
        const refTextArea = useRef(null);
        const refButtonSave = useRef(null);
        const refButtonCancel = useRef(null);

        const [state, dispatchState] = useReducer(reducer, {
            isOpen: !!autoOpen
        });

        useImperativeHandle(ref, () => ({ _open, _close }));

        const { isOpen } = state;
        const valueLength = defaultValue.length;

        useEnterKeydownClick(isOpen);

        function _open() {
            dispatchState({
                isOpen: true
            });
        }

        function _close(isSave = false) {
            dispatchState({
                isOpen: false
            });
            onClose(isSave);
        }

        useEffect(() => {
            if (isOpen) {
                _handleCheckActive(defaultValue);
                refTextArea.current.focus();
                isAddNew && refTextArea.current.scrollIntoView();
                refTextArea.current.setSelectionRange(valueLength, valueLength);
            }
        }, [isOpen]);

        if (!isOpen) {
            return false;
        }

        function _handleCheckActive(value) {
            if (!value.trim().length) {
                refButtonSave.current.className = 'v2-btn-main ml-2 is-disable';
            } else {
                refButtonSave.current.className = 'v2-btn-main ml-2';
            }
        }

        const _onChangeText = (e) => {
            _handleCheckActive(e.target.value);
        };

        const _handleSave = () => {
            onSave(refTextArea.current.value);
            _close(true);
        };

        const _onBlurButtonSave = () => {
            refButtonCancel.current.focus();
            refButtonSave.current.tabIndex = '-1';
        };

        const _onFocusTextArea = () => {
            refButtonSave.current.tabIndex = '0';
        };

        return (
            <div className="wrap-editor">
                <div className="wrap-editor__field d-flex">
                    <textarea
                        className="field-textarea --no-border --no-resize --px-12"
                        id={'form_textarea_value_desciption'}
                        placeholder={t('jobDetail:add_a_todo')}
                        ref={refTextArea}
                        onChange={_onChangeText}
                        defaultValue={defaultValue}
                        onFocus={_onFocusTextArea}
                        spellCheck
                    />
                </div>
                <div className="wrap-editor__action">
                    <div
                        onClick={() => _close(false)}
                        tabIndex="-1"
                        className="v2-btn-default --transparent"
                        ref={refButtonCancel}
                    >
                        {t('common:cancel')}
                    </div>
                    <div
                        onClick={_handleSave}
                        ref={refButtonSave}
                        tabIndex="0"
                        className="v2-btn-main ml-2"
                        onBlur={_onBlurButtonSave}
                    >
                        {t('common:save')}
                    </div>
                </div>
            </div>
        );
    }
);

export default Add;
