export const TYPE_SETTING_SOURCE = {
  GET_LIST: "SETTING_GET_LIST_SOURCES",
  CREATE: "SETTING_CREATE_SOURCES",
  UPDATE: "SETTING_UPDATE_SOURCES",
  UPDATE_STATUS: "SETTING_UPDATE_STATUS_SOURCES"
}

// actions
export const getListSettingSources = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_SOURCE.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const createSource = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_SOURCE.CREATE,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateSource = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_SOURCE.UPDATE,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateStatusSettingSources = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_SOURCE.UPDATE_STATUS,
    params,
    actionSuccess,
    actionFailed
  }
}
