import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ALL } from 'app/const/App';
import useClickOutside from 'common/hooks/useClickOutside';
import SelectRatingOption from './components/SelectRatingOption';
import IconDropDown from 'assets/icon/IconDropDown';
import classNames from 'classnames';
import { Fragment } from 'react';
import DropdownPopper from '../dropdown/DropdownPopper';
import ReportListDropdown from 'app/modules/report/components/ReportListDropdown';
import { TYPE_SELECT } from 'app/const/report/ReportFilter';

const MultiSelect = ({
    name,
    options,
    selected,
    title,
    onSelect,
    fileTranslation,
    titleSelectAllOption,
    onGetAsyncOptions = () => {},
    classWrapper = '',
    isPopper = false,
    isUseToggle = false
}) => {
    const { t } = useTranslation([`${fileTranslation}`]);

    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);

    // case all not equal -1 --> this statement below is wrong
    if (!Array.isArray(options) || (!Array.isArray(selected) && selected !== DEFAULT_ALL)) return null;

    const _openSelect = () => {
        if (!isOpen) onGetAsyncOptions(name);
        !isPopper && setIsOpen(!isOpen);
    };

    const handleSelectOption = (e, value) => {
        e.preventDefault();
        let newSelected = selected === DEFAULT_ALL ? options.map((i) => i.value) : [...selected];

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((item) => item !== value);
        } else {
            newSelected.push(value);
        }

        if (newSelected.length === options.length) {
            newSelected = DEFAULT_ALL;
        }

        onSelect(name, newSelected);
    };

    const handleSelectAll = (e) => {
        e.preventDefault();
        const newSelected = selected === DEFAULT_ALL || selected.length === options.length ? [] : DEFAULT_ALL;
        onSelect(name, newSelected);
    };

    const renderSelectAllOption = () => {
        if (!titleSelectAllOption || !options.length) return null;
        const isCheckAll = selected === DEFAULT_ALL || options.length === selected.length;

        return (
            <li className={`items pr-2${isCheckAll ? ' active' : ''}`} onClick={(e) => handleSelectAll(e)}>
                <span className="txt-ellipsis">{titleSelectAllOption}</span>
            </li>
        );
    };

    const renderList = (list) => {
        return list.map((item) => {
            const isCheck = selected === DEFAULT_ALL || selected.includes(item.value);

            return (
                <SelectRatingOption
                    key={item.id}
                    isCheck={isCheck}
                    id={`check-box-${title}-${item.name}`}
                    label={t(`${fileTranslation}:${item.name}`)}
                    value={item.value}
                    onSelect={handleSelectOption}
                />
            );
        });
    };

    const _renderButton = () => {
        return (
            <Fragment>
                {title}
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </Fragment>
        );
    };

    const _renderReportListDropdown = () => {
        return (
            <ReportListDropdown
                options={options}
                title={title}
                fileTranslation={fileTranslation}
                typeDropdown={TYPE_SELECT.MULTI_SELECT_RATING}
                selected={selected}
                renderSelectAllOption={renderSelectAllOption}
                handleSelectOption={handleSelectOption}
                renderList={renderList}
            />
        );
    };

    if (isPopper)
        return (
            <DropdownPopper
                ref={refSelect}
                placement="bottom-start"
                customStyle={{ width: 'auto', minWidth: 'auto' }}
                buttonClassName="dropbtn v2-btn-default selection"
                wrapperListClass="v2-dropdown__menu scrolls content-full"
                wrapperClassName={classNames(classWrapper, 'v2-dropdown')}
                isUseToggle={isUseToggle}
                isCalculateWidth
                customButton={_renderButton()}
                onOpen={_openSelect}
            >
                {_renderReportListDropdown()}
            </DropdownPopper>
        );

    return (
        <div
            ref={refSelect}
            className={classNames('v2-dropdown', classWrapper, {
                active: isOpen
            })}
        >
            <div className="dropbtn v2-btn-default selection" onClick={_openSelect}>
                {_renderButton()}
            </div>
            <div className="v2-dropdown__menu scrolls content-full">{_renderReportListDropdown()}</div>
        </div>
    );
};

MultiSelect.propTypes = {
    options: PropTypes.array,
    selected: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
    title: PropTypes.string,
    onSelect: PropTypes.func,
    fileTranslation: PropTypes.string,
    selectAllOption: PropTypes.bool,
    onGetAsyncOptions: PropTypes.func
};

MultiSelect.defaultProps = {
    title: '',
    onSelect: () => {}
};

export default MultiSelect;
