import loadable from '@loadable/component';
import { URL_EXPORT_PAYMENT_REVENUES_SOURCE, URL_EXPORT_REVENUES_SOURCE } from 'app/const/api/Export';
import { COMMON, CURRENT_MONTH_SHORT_NAME, DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_EXPORT } from 'app/const/report/Common';
import {
    REVENUE_BY_SOURCE_LIST_FILER,
    REVENUE_BY_SOURCE_TABS,
    SALE,
    TOTAL_COLLECTED
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { DetailTabGridView, handleExportDetailTab } from 'app/modules/report/revenuebysource/components/DetailsTab';
import { handleExportMonthTab, MonthTabGridView } from 'app/modules/report/revenuebysource/components/MouthTab';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MainHeaderReport from 'app/modules/report/components/MainHeader';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import {
    convertParamFields,
    getListRevenueSuccess,
    getLocalParamsReport,
    getNewListFilterRevenue,
    initStateRevenueReport,
    removeObjParams
} from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { handleAbortController } from 'app/modules/customer/utils';
import { REPORT_REVENUE_BY_SOURCE, REPORT_REVENUE_BY_SOURCE_PAYMENT } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import GDStatusBar from 'app/components/status/statusbar';

const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));

function ReportRevenueBySource() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();

    const MONTH_TAB = REPORT_TYPE.REVENUE_BY_SOURCE_MONTH_TAB;
    const DETAIL_TAB = REPORT_TYPE.REVENUE_BY_SOURCE_DETAIL_TAB;

    const keyTotalRow = {
        [MONTH_TAB]: 'total_customer',
        [DETAIL_TAB]: 'total_payments_received'
    };

    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.REVENUE_BY_SOURCE);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVENUE_BY_SOURCE);

    const initialState = initStateRevenueReport({ keyCurrentTab, monthTab: MONTH_TAB, detailTab: DETAIL_TAB });
    const [dataReport, dispatchActionReport] = useReducer(reducer, initialState);

    const paramsReport = getLocalParamsReport(keyLocalStore, REPORT_TYPE.REVENUE_BY_SOURCE);

    const { currentTab, rerender, refreshScreen, isLoadMore: finalIsLoadMore } = dataReport;
    const { data: finalData, isLoading: finalIsLoading, total: finalTotal = 0 } = dataReport[currentTab];
    const abortController = useRef(null);
    const refAlert = useRef(null);

    useEffect(() => {
        getListReport({ ...paramsReport, offset: 0, changeTab: Date.now() });
        setLocalStorage(keyCurrentTab, currentTab);

        return () => {
            handleAbortController(abortController);
        };
    }, [currentTab]);

    useEffect(() => {
        let timeoutId = null;
        if (finalIsLoadMore) {
            timeoutId = setTimeout(() => {
                getListReport({ ...paramsReport, offset: finalData.length });
            }, DELAY_TIME);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [finalIsLoadMore]);

    useEffect(() => {
        return () => {
            removeLocalStorage(keyCurrentTab);
        };
    }, []);

    const getListReport = (params) => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        if (!finalIsLoadMore && !finalIsLoading) dispatchActionReport({ [currentTab]: { isLoading: true } });
        let objectParams = convertParamFields({ params, isEmptyFields: finalIsLoadMore, isPaymentMethods: true });
        delete objectParams?.currentPage;
        delete objectParams?.changeTab;

        switch (currentTab) {
            case MONTH_TAB:
                objectParams.order = objectParams['orderMonth'];
                objectParams = removeObjParams(objectParams, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);

                return clientQuery(
                    REPORT_REVENUE_BY_SOURCE,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );

            case DETAIL_TAB:
                objectParams.order = objectParams['orderDetail'];
                objectParams = removeObjParams(objectParams, ['orderMonth', 'orderDetail', 'year', 'customer_id']);

                return clientQuery(
                    REPORT_REVENUE_BY_SOURCE_PAYMENT,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );

            default:
                break;
        }
    };

    const getListSuccess = (response, { changeTab }) => {
        dispatchActionReport((prev) =>
            getListRevenueSuccess({
                prev,
                response,
                changeTab,
                keyTotalRow,
                monthTab: MONTH_TAB,
                titleTotalRow: 'source'
            })
        );
    };

    function getListFailed({ isAborted = false, message }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({
            ...prev,
            [currentTab]: { ...prev[currentTab], isLoading: false },
            isLoadMore: false
        }));
        refAlert.current?.handeAddStatus({ id: 'revenue_by_source_error', message, type: LIST_STATUS.ERROR });
    }

    const handleChangeFilter = (params, type) => {
        if (type === 'year') {
            const newParamsReport = { ...params };
            const selectDate = `${CURRENT_MONTH_SHORT_NAME} ${newParamsReport.year}`;
            newParamsReport.start = moment(selectDate).clone().startOf('month').format();
            newParamsReport.end = moment(selectDate).clone().endOf('month').format();
            setLocalStorage(keyLocalStore, newParamsReport);
        }

        if (!type) {
            dispatchActionReport({ rerender: false });
        } else if (type === SALE) {
            dispatchActionReport({ rerender: params.sale === TOTAL_COLLECTED });
        }
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocalStore) || paramsReport;
        dispatchActionReport({ [currentTab]: { ...initialState[currentTab] } });
        params.clients = params?.customer_id;
        getListReport(params);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.REVENUE_BY_SOURCE,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate,
            keyOrder: currentTab === DETAIL_TAB ? 'orderDetail' : 'orderMonth'
        });
    };

    const _getNewListFilter = () => {
        return getNewListFilterRevenue({
            listFilter: REVENUE_BY_SOURCE_LIST_FILER,
            keyLocalStore,
            currentTab,
            keyCurrentTab,
            detailTab: DETAIL_TAB
        });
    };

    function _renderHeaderLeft() {
        return (
            <>
                <ReportTabs list={REVENUE_BY_SOURCE_TABS} onChange={handleChangeTab} tabActive={currentTab} />
            </>
        );
    }

    function _renderHeaderBottom() {
        return (
            <HeaderBottom
                classNameHeader="header --filter"
                forceRerenderFilter={rerender}
                typeReport={REPORT_TYPE.REVENUE_BY_SOURCE}
                filters={_getNewListFilter()}
                handleChangeFilter={handleChangeFilter}
                handleUpdate={_handleUpdate}
                isNotShowDateRange={currentTab === MONTH_TAB}
                isLoading={finalIsLoading}
            />
        );
    }

    function handleOnClickExport(e, isCsv, actionPrint) {
        e && e.preventDefault();

        switch (currentTab) {
            case MONTH_TAB:
                return handleExportMonthTab(isCsv, actionPrint, paramsReport);
            case DETAIL_TAB:
                return handleExportDetailTab(isCsv, actionPrint, paramsReport);
            default:
                break;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab, rerender: tab === MONTH_TAB });
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    function _getDataExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case MONTH_TAB:
                paramsExport.order = paramsExport['orderMonth'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'start', 'end']);
                return {
                    urlExport: URL_EXPORT_REVENUES_SOURCE,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_SOURCE,
                    paramsExport
                };
            case DETAIL_TAB:
                paramsExport.order = paramsExport['orderDetail'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'year']);
                return {
                    urlExport: URL_EXPORT_PAYMENT_REVENUES_SOURCE,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_SOURCE_DETAIL,
                    paramsExport
                };
            default:
                return {};
        }
    }

    const _handleLoadMore = () => {
        const numberData = finalData?.length;

        if (!!numberData && numberData < finalTotal && !finalIsLoadMore) {
            dispatchActionReport((prev) => ({ ...prev, isLoadMore: true }));
        }
    };

    const _renderGirdView = () => {
        const commonProps = {
            dataReport: dataReport[currentTab],
            paramsReport,
            handleActionHeader: _handleActionHeader,
            handleOpenInvoice: _handleOpenInvoice,
            isLoadMore: finalIsLoadMore,
            onScrollToEnd: _handleLoadMore
        };
        switch (currentTab) {
            case MONTH_TAB:
                return <MonthTabGridView monthTab={currentTab} {...commonProps} />;
            case DETAIL_TAB:
                return <DetailTabGridView {...commonProps} />;
            default:
                break;
        }
    };

    function _renderHeaderRight() {
        const dataExport = _getDataExport();
        return (
            <Export
                title={t('report:records', { count: finalTotal })}
                activePrint
                onClick={handleOnClickExport}
                url={dataExport.urlExport}
                params={dataExport.paramsExport}
                pageExport={dataExport.pageExport}
                isDisable={finalIsLoading}
                refresh={refreshScreen}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVENUE_BY_SOURCE}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <div className="tab-contents">{_renderGirdView()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRevenueBySource;
