import React from 'react';
import { OPERATOR_IDS } from '../../constants/types';

const TextCondition = ({ value, operatorId, onChange = () => {} }) => {
    if (operatorId === OPERATOR_IDS.PRESENT || operatorId === OPERATOR_IDS.NOT_PRESENT) return null;
    return (
        <div className="field">
            <input
                value={value}
                type="text"
                className="field-input"
                autoFocus
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default TextCondition;
