import React from 'react';
import PropTypes from 'prop-types';
import IconTooltip from 'assets/icon/IconTooltip';

function Tooltip({ tooltip = '' }) {
    return (
        <div className="tooltip">
            <IconTooltip />
            {!!tooltip && <span className="tooltiptext top"> {tooltip} </span>}
        </div>
    );
}

Tooltip.propTypes = {
    tooltip: PropTypes.string
};

const compare = (prevProps, nextProps) => {
    return prevProps.tooltip === nextProps.tooltip;
};

export default React.memo(Tooltip, compare);
