import mixpanel from 'mixpanel-browser';
import queryStrings from 'query-string';
import moment from 'moment';
import {
    CAMPAIGN_KEYWORDS,
    MIXPANEL_EVENT_NAME,
    MIXPANEL_EXCLUDE_TRACK,
    MIXPANEL_FORMAT_DATE,
    MIXPANEL_KEY_LOCAL_STORAGE_UTM_PARAMS,
    MIXPANEL_METHOD
} from 'app/const/Mixpanel';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatTime } from './DateUtils';

const handleSetUTMUrl = () => {
    const utmParams = queryStrings.parse(getLocalStorage(MIXPANEL_KEY_LOCAL_STORAGE_UTM_PARAMS));
    const params = {};
    const first_params = {};

    if (!Object.keys(utmParams).length) return;
    Object.values(CAMPAIGN_KEYWORDS).forEach((keyword) => {
        const campaignItem = utmParams[keyword];

        if (!!campaignItem) {
            params[`${keyword} [last touch]`] = campaignItem;
            first_params[`${keyword} [first touch]`] = campaignItem;
        }
    });

    mixpanel.people.set(params);
    mixpanel.people.set_once(first_params);
};

export const handleTrackingEvent = ({ type, data }) => {
    handleSetUTMUrl();
    Object.entries(data).forEach(([key, value]) => {
        switch (key) {
            case MIXPANEL_METHOD.IDENTIFY:
                mixpanel.identify(value);
                break;
            case MIXPANEL_METHOD.SET:
                mixpanel.people.set(value);
                break;
            case MIXPANEL_METHOD.REGISTER:
                mixpanel.register(value);
                break;
            case MIXPANEL_METHOD.INCREMENT:
                mixpanel.people.increment(value);
                break;
            case MIXPANEL_METHOD.SET_ONCE:
                mixpanel.people.set_once(value);
                break;
            case MIXPANEL_METHOD.REGISTER_ONCE:
                if (type !== MIXPANEL_EVENT_NAME.LOGIN) break;
                mixpanel.register_once(value);
                break;
            default:
                break;
        }
    });

    if (MIXPANEL_EXCLUDE_TRACK.includes(type)) return;

    return mixpanel.track(type);
};

export const getTimeEventMixpanel = (value, isUseMomentFormat = false) => {
    return formatTime(value ? (isUseMomentFormat ? moment.utc(value) : value) : new Date(), MIXPANEL_FORMAT_DATE);
};
