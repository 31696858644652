import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GdConfirm from 'app/components/confirm';
import { PROFILE_AUTHENTICATION } from 'app/config/routes';
import { getUrlDeleteSession } from 'app/const/Api';
import { addBranchPath } from 'app/const/Branch';
import SessionList from 'app/modules/settings/users/components/userform/components/SessionList';
import { clientQuery } from 'common/utils/ApiUtils';

const ProfileActiveDevices = () => {
    const history = useHistory();
    const { t } = useTranslation('setting');
    const { id: userId } = useSelector(({ auth }) => auth.user.profile);

    const refSessionList = useRef(null);
    const refConfirm = useRef(null);

    const _handleChangeMode = () => {
        history.push(addBranchPath(PROFILE_AUTHENTICATION));
    };

    const _openConfirmSignOut = (item) => {
        if (!item) return null;
        const { id, device_id, user_agent = {} } = item || {};
        const { platform } = user_agent;
        refConfirm.current?.open({ id, device_id }, null, t('sign_out_confirm_notification', { device: platform }));
    };

    const _handleSignOut = (value) => {
        const { id: sessionId, device_id: deviceId } = value;
        const _handleSuccess = () => {
            refSessionList.current?._updateStatusActive(sessionId);
        };
        const _handleFail = ({ message }) => {
            refConfirm.current?.open(null, message);
        };

        clientQuery(
            getUrlDeleteSession({ userId, sessionId }),
            { method: 'DELETE', data: { device_id: deviceId } },
            _handleSuccess,
            _handleFail
        );
    };

    return (
        <div className="set-user-right wrapper-box-edit has-session-history">
            <SessionList
                ref={refSessionList}
                onChangeMode={_handleChangeMode}
                userId={userId}
                onSignOut={_openConfirmSignOut}
            />
            <GdConfirm
                ref={refConfirm}
                title={t('setting:sign_out_confirm_notification')}
                message={t('setting:sign_out_confirm_message')}
                titleConfirm={t('setting:sign_out')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleSignOut}
            />
        </div>
    );
};

export default ProfileActiveDevices;
