import classNames from 'classnames';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GDButtonTooltip from 'app/components/button/ButtonTooltip';
import { CUSTOMERS } from 'app/const/Route';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import { TYPE_LOAD_TIME_LINE } from 'app/modules/customer/const/Email';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import IconEmail from 'assets/icon/IconEmail';
import IconPhone from 'assets/icon/IconPhone';
import IconSMS from 'assets/icon/IconSMS';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';
import { TYPE_OPEN_SMS } from 'app/const/Sms';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { addBranchPath } from 'app/const/Branch';
import { CONTACT_ID_DEFAULT } from 'app/const/opportunity';

const OpportunityContact = ({ contactId = '' }) => {
    const history = useHistory();
    const {
        contacts,
        customer: { id: customerId }
    } = useContext(CustomerDetailContext);
    const contact = contacts?.find((contact) => contact.id === contactId) || null;
    const phoneNumber = contact?.phones?.[0]?.phone || '';

    const _handleEmailClick = () => {
        const url = addBranchPath(`${CUSTOMERS}/${customerId}`);
        if (history.location.pathname === url) {
            const event = new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_ADD_EMAIL, { detail: { email: contact.email } });
            dispatchEvent(event);
        } else {
            history.push({
                pathname: url,
                state: { type: TYPE_LOAD_TIME_LINE.ADD_EMAIL, email: contact.email }
            });
        }
    };

    const _handleSMSClick = () => {
        const event = new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_SEND_SMS, {
            detail: { default_phone: phoneNumber, typeOpen: TYPE_OPEN_SMS.CUSTOMER }
        });
        dispatchEvent(event);
    };

    if (!contact) return null;
    const { customer, email, id: finalContactId } = contact;
    return (
        <div className="contact flex-betweenitems gap-8">
            <span className="txt-ellipsis flex-1 fw-600">{customer?.first_name.concat(' ', customer?.last_name)}</span>
            <div className="flexcenter">
                <ButtonEmail email={email} onClick={_handleEmailClick} />
                <ButtonSMS phoneNumber={formatPhoneNumberVoip(phoneNumber)} onClick={_handleSMSClick} />
                <ButtonPhone
                    phoneNumber={formatPhoneNumberVoip(phoneNumber)}
                    customerId={customerId}
                    contactId={finalContactId}
                />
            </div>
        </div>
    );
};

const ButtonEmail = ({ email = '', onClick = () => {} }) => {
    const isActiveEmail = useSelector(({ auth }) => !!auth.user.settings.addons.inbox_email);
    const isDisable = !email;
    const textTooltip = isDisable ? 'You can email this Contact after adding an email address' : `Email ${email}`;

    const _handleClick = (e) => {
        if (isDisable) {
            e && e.preventDefault();
            return;
        }
        isActiveEmail && onClick();
    };
    return (
        <GDButtonTooltip
            className="tooltip ml-1"
            tooltips={[{ className: 'tooltiptext top', text: textTooltip }]}
            onClick={_handleClick}
        >
            {isActiveEmail ? (
                <div className={classNames('v2-btn-default --icon-sm --transparent', { 'is-disable': isDisable })}>
                    <IconEmail />
                </div>
            ) : (
                <a
                    href={`mailto:${email}`}
                    className={classNames('v2-btn-default --icon-sm --transparent', { 'is-disable': isDisable })}
                >
                    <IconEmail />
                </a>
            )}
        </GDButtonTooltip>
    );
};

const ButtonSMS = ({ phoneNumber = '', onClick = () => {} }) => {
    const isActiveSMS = useSelector(({ auth }) => auth.user?.settings?.addons?.smsplivo);
    const isDisable = !isActiveSMS || !phoneNumber;
    let textTooltip = !isActiveSMS ? 'You can send a message to this number after activating SMS' : '';
    if (!textTooltip) {
        textTooltip = !phoneNumber
            ? 'You can send SMS to this Contact after adding a phone number.'
            : `Send SMS to ${phoneNumber}`;
    }

    return (
        <GDButtonTooltip
            className="tooltip ml-1"
            tooltips={[{ className: 'tooltiptext top', text: textTooltip }]}
            onClick={isDisable ? () => {} : onClick}
        >
            <div className={classNames('v2-btn-default --icon-sm --transparent', { 'is-disable': isDisable })}>
                <IconSMS isOpportunity />
            </div>
        </GDButtonTooltip>
    );
};

const ButtonPhone = ({ phoneNumber = '', customerId = '', contactId = '' }) => {
    const { onOutGoingCall } = useContext(VOIPContext);
    const settings = useSelector(({ auth }) => auth.user?.settings);
    const isVoipActive = checkIsEnableVoip(settings, false);

    const isDisable = !phoneNumber;
    let textTooltip = !phoneNumber ? 'You can call this Contact after adding a phone number.' : '';
    if (!textTooltip) {
        textTooltip = !isVoipActive ? 'You can call this number after activating VoIP' : `Call ${phoneNumber}`;
    }

    const _handleClick = (e) => {
        if (isDisable) {
            e && e.preventDefault();
            return;
        }
        isVoipActive &&
            onOutGoingCall({
                toPhoneNumber: phoneNumber,
                customerId,
                contactId: contactId === CONTACT_ID_DEFAULT ? null : contactId
            });
    };

    return (
        <GDButtonTooltip
            className="tooltip ml-1"
            tooltips={[{ className: 'tooltiptext top', text: textTooltip }]}
            onClick={_handleClick}
        >
            {isVoipActive ? (
                <div className={classNames('v2-btn-default --icon-sm --transparent', { 'is-disable': isDisable })}>
                    <IconPhone />
                </div>
            ) : (
                <a
                    href={`tel:${phoneNumber}`}
                    className={classNames('v2-btn-default --icon-sm --transparent', { 'is-disable': isDisable })}
                >
                    <IconPhone />
                </a>
            )}
        </GDButtonTooltip>
    );
};
export default OpportunityContact;
