import { CUSTOMER_UPDATE_STATUS_CREDIT } from 'app/const/Api';
import { postWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* updateStatusCustomerDetailCredit({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, CUSTOMER_UPDATE_STATUS_CREDIT, params);
        if (response && response.success) {
            actionSuccess();
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
