import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TAB_NOTIFICATIONS } from 'app/const/Header';
import moment from 'moment';
import { SocketContext } from 'app/services/socket/SocketProvider';

export default function NotificationService({
    state,
    totalUnread = 0,
    totalRead = 0,
    fetchFirstTime,
    onUpdateState = () => {},
    onChangeTotalUnread = () => {}
}) {
    const authReducer = useSelector((state) => state.auth);
    const { notificationCount: responseSocket } = useContext(SocketContext);

    const { listNotifications, currentTab: currentTabState } = state;

    useEffect(() => {
        // eslint-disable-next-line no-undef
        global.notificationSocketId = Date.now().toString();
    }, []);

    useEffect(() => {
        if (responseSocket && typeof responseSocket === 'object') {
            const userId = authReducer?.user?.profile?.id;

            const { status, data, socket_id = '', user_id = [] } = responseSocket;

            // eslint-disable-next-line no-undef
            if (socket_id === global.notificationSocketId || !data || user_id.toString() !== userId.toString()) {
                return;
            }

            const statusSocket = parseInt(status) === 1 ? TAB_NOTIFICATIONS.UNREAD : TAB_NOTIFICATIONS.READ;

            let newListNotifications = [...listNotifications];
            let dataHandleNotification = [...data];
            const currentNotificationLength = newListNotifications.length;
            const finalTotalTab = TAB_NOTIFICATIONS.UNREAD === currentTabState ? totalUnread : totalRead;

            if (!fetchFirstTime) {
                //This case check remove notification via socket
                if (statusSocket === currentTabState) {
                    newListNotifications = newListNotifications.filter((item) => {
                        if (!dataHandleNotification.some((noti) => noti.id === item.id)) {
                            return item;
                        }
                    });

                    onUpdateState({
                        listNotifications: newListNotifications
                    });

                    onChangeTotalUnread(data.length, statusSocket);
                } else {
                    newListNotifications.forEach((item) => {
                        item.created_item = _convertTimeStringtoInt(item.created);
                    });

                    dataHandleNotification.forEach((item) => {
                        item.created_item = _convertTimeStringtoInt(item.created);
                    });

                    const lastItem =
                        currentNotificationLength === 0 ? null : newListNotifications[currentNotificationLength - 1];

                    //This is will check if total notification > limit we will check new notification mapping with list notification current
                    if (finalTotalTab > 20 && finalTotalTab !== newListNotifications.length && lastItem) {
                        dataHandleNotification = dataHandleNotification.filter((item) => {
                            if (item.created_item >= lastItem.created_item) {
                                return item;
                            }
                        });
                    }

                    //If not mapping any notification we will skip this step
                    if (dataHandleNotification.length !== 0) {
                        newListNotifications = _sortNotificationByCreated([
                            ...newListNotifications,
                            ...(dataHandleNotification || [])
                        ]);
                        onUpdateState({
                            listNotifications: newListNotifications
                        });
                        onChangeTotalUnread(data.length, statusSocket);
                    }
                }
            } else {
                onChangeTotalUnread(data.length, statusSocket);
            }
        }
    }, [responseSocket]);

    function _convertTimeStringtoInt(time) {
        return moment(time, 'MM-DD-YYYY hh:mm').format('X');
    }

    // /**
    //  *
    //  * @param {*} array
    //  * @returns array after sort. We will sort with time get smaller and id same
    //  */
    function _sortNotificationByCreated(array) {
        return array.sort((a, b) => {
            return parseInt(b.id) - parseInt(a.id) || parseInt(a.created_item) - parseInt(b.created_item);
        });
    }

    return true;
}
