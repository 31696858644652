import BoxAddress from 'app/modules/customer/add/components/address';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';
import AddCustomerPhone from '../AddCustomerPhone';
import BoxRows, { BoxRowsInput } from '../BoxRows';

const FastForm = ({ defaultStatus = 1 }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { profile, service_location, updateContext } = useContext(AddCustomerContext);
    const refAddress = useRef(null);
    const isSame = service_location.same_billing_location;

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        return {
            profile: { ...profile, phones: profile.phones, account_no: profile.account_no },
            service_location: { ...refAddress.current.getValue(), same_billing_location: isSame }
        };
    };

    const _handleUpdateInformation = (event) => {
        const { value, name } = event.target;
        if (profile[name] !== value) updateContext({ [name]: value }, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_PROFILE);
    };

    return (
        <div className="form-fast">
            <form className="box-has-rows">
                <BoxRowsInput
                    label={t('customers:first_name')}
                    labelClass="flexcenter"
                    inputProps={{
                        defaultValue: profile.first_name,
                        placeholder: t('customers:first_name'),
                        name: 'first_name',
                        autoFocus: true,
                        onBlur: _handleUpdateInformation
                    }}
                    isRequired
                />
                <BoxRowsInput
                    label={t('customers:last_name')}
                    inputProps={{
                        defaultValue: profile.last_name,
                        placeholder: t('customers:last_name'),
                        name: 'last_name',
                        onBlur: _handleUpdateInformation
                    }}
                />
                <BoxRowsInput
                    label={t('customers:email')}
                    inputProps={{
                        defaultValue: profile.email,
                        placeholder: t('customers:email'),
                        name: 'email',
                        onBlur: _handleUpdateInformation
                    }}
                />
                <BoxRows label={t('customers:phone')} fieldClass="has-many-field has-new-field">
                    <AddCustomerPhone />
                </BoxRows>
            </form>
            <BoxAddress ref={refAddress} isFastForm defaultStatus={defaultStatus} />
        </div>
    );
};

export default forwardRef(FastForm);
