import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import DropdownTime from 'app/components/dropdown/DropdownTime';
import GDStatusBar from 'app/components/status/statusbar';
import { ADDONS_SMS_AFTER_HOURS } from 'app/const/Api';
import { ADDONS_HOURS, TIMEOFF_HOURS, TIMEOFF_MINUTES } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeNumber } from 'common/utils/TimeUtils';
import { OPTIONS_BUFFER } from '../../constants';
import SMSContent from './SMSContent';
import WeeklyRepeat from 'app/modules/jobdetail/tabs/settingschedule/repeat/WeeklyRepeat';
import { DEFAULT_WEEKS } from 'app/const/addons';

const SettingsContent = ({ data = {}, onClose = () => {}, onPreview = () => {} }) => {
    const { t } = useTranslation(['addons']);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);
    const refCheckbox = useRef(null);
    const refBuffer = useRef(null);
    const refFromTime = useRef(null);
    const refToTime = useRef(null);
    const refSmsContent = useRef(null);

    const refDataRepeat = useRef(data?.all_day || DEFAULT_WEEKS);

    const _handleSave = () => {
        if (refSmsContent.current?.isOver()) {
            refAlert.current?.showStatusBar(
                'fail',
                'Sms Content should contain at most 600 characters.',
                LIST_STATUS.ERROR
            );
            refButtonSave.current?.removeLoading();
            return;
        } else {
            refAlert.current?.closeStatusBar('fail');
        }

        const dataSubmit = {
            data: {
                appointment: refCheckbox.current.getIsActive() ? 1 : 0,
                from_time: refFromTime.current.getValue() || 0,
                to_time: refToTime.current.getValue() || 0,
                reply_every: refBuffer.current.getValue() || 5,
                sms_content: refSmsContent.current.getValue() || '',
                all_day: refDataRepeat.current || DEFAULT_WEEKS
            },
            method: 'PUT'
        };

        const _saveSuccess = ({ message }) => {
            refAlert.current?.showStatusBar(
                'success',
                message.length ? message : t('addons:update_successfully'),
                LIST_STATUS.SUCCESS
            );
        };
        const _saveFail = ({ message }) => {
            refAlert.current?.showStatusBar(
                'fail',
                message.length ? message : t('addons:action_failure'),
                LIST_STATUS.ERROR
            );
        };
        const _saveFinally = () => {
            refButtonSave.current && refButtonSave.current.setLoading(false);
        };
        clientQuery(ADDONS_SMS_AFTER_HOURS, dataSubmit, _saveSuccess, _saveFail, _saveFinally);
    };

    const _handleSelectRepeat = (value) => {
        refDataRepeat.current = value;
    };

    return (
        <div className="wrap-container__form flex-column wrap-document-edit">
            <div className="wrap-header">
                <div className="flex-betweenitems">
                    <h3 className="flex-1 fs-17 mr-2">After Hours Message Settings</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={onClose}>
                        <IconClose />
                    </div>
                </div>
            </div>
            <div className="wrap-body">
                <GDStatusBar ref={refAlert} />
                <div className="content-insert-variable">
                    <div className="px-4 py-2">
                        <div className="flex-betweenitems">
                            <div className="mr-2">
                                Do you want your after hours message to also apply to Job Confirmations and Reschedule
                                requests.
                            </div>
                            <GdSwitchCheckbox
                                ref={refCheckbox}
                                id="appointment_after_hours"
                                textChecked={'Yes'}
                                textUnChecked={'No'}
                                inputClassName="toggle toggle-round"
                                defaultChecked={!!data.appointment}
                            />
                        </div>
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable">
                        <div className="mb-3">
                            Setup the time frame when you want the after hours message to auto-reply to incoming SMS
                            messages.
                        </div>
                        <div className="flex-betweenitems">
                            <TimeSelect
                                ref={refFromTime}
                                title="From"
                                defaultValue={convertTimeNumber(data.from_time)}
                            />
                            <TimeSelect ref={refToTime} title="To" defaultValue={convertTimeNumber(data.to_time)} />
                        </div>

                        <WeeklyRepeat
                            shortDate={false}
                            shouldCheckHaveSelect={false}
                            title={{ className: 'fw-500', title: 'All Day on' }}
                            className="flexcenter gap-16 mt-3"
                            classWrapper="btn-item m-0 border-none"
                            {...(refDataRepeat.current || DEFAULT_WEEKS)}
                            onChange={_handleSelectRepeat}
                        />
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable">
                        <div className="fw-600 mb-1">Auto-reply Buffer</div>
                        <div className="mb-3">
                            If a customer is sending multiple SMS messages within the same after hours period, to
                            preserve SMS credits the system will only send an auto-reply outside of your set buffer
                            time.
                        </div>
                        <div className="mb-1">Send 1 auto-reply every:</div>
                        <SelectBuffer ref={refBuffer} defaultValue={data.reply_every} />
                    </div>
                </div>
                <div className="content-insert-variable">
                    <div className="box-use-variable">
                        <SMSContent
                            ref={refSmsContent}
                            defaultContent={data.sms_content}
                            valueRestore={data.default_sms_content}
                            onPreview={onPreview}
                        />
                    </div>
                </div>
            </div>
            <div className="wrap-footer">
                <div className="v2-btn-default --transparent" onClick={onClose}>
                    Cancel
                </div>
                <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave} />
            </div>
        </div>
    );
};

const TimeSelect = forwardRef(({ title = '', defaultValue }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        h: TIMEOFF_HOURS.find((item) => item.value === +defaultValue.hour),
        m: TIMEOFF_MINUTES.find((item) => item.value === +defaultValue.minute),
        midDay: defaultValue.midDay
    });

    useImperativeHandle(ref, () => ({
        getValue: () => {
            let totalMinutes = state.h.value * 60 + state.m.value;
            if (state.midDay === 'PM' && state.h.value < 12) totalMinutes += 720;
            return totalMinutes;
        }
    }));

    const _handleSelect = (unit, value) => {
        dispatchState((prevState) => ({ ...prevState, [unit]: value }));
    };

    const _handleChangeMidDay = (midDay) => {
        dispatchState((prevState) => ({ ...prevState, midDay }));
    };

    return (
        <div>
            <div className="mb-1">{title}</div>
            <div className="field-input wrapper-choosetime">
                <DropdownTime
                    id="minutes-length-timeoff"
                    unit="h"
                    subText="hrs"
                    options={ADDONS_HOURS}
                    defaultValue={state.h}
                    onSelect={_handleSelect}
                />
                <DropdownTime
                    id="minutes-length-timeoff"
                    unit="m"
                    subText="mins"
                    options={TIMEOFF_MINUTES}
                    defaultValue={state.m}
                    onSelect={_handleSelect}
                />
                <div className="tabs">
                    <div className="btn-item m-0">
                        <button
                            className={classNames('tab-items', { 'active-tab-selector': state.midDay === 'AM' })}
                            onClick={() => _handleChangeMidDay('AM')}
                            type="button"
                        >
                            AM
                        </button>
                        <button
                            className={classNames('tab-items', { 'active-tab-selector': state.midDay === 'PM' })}
                            onClick={() => _handleChangeMidDay('PM')}
                            type="button"
                        >
                            PM
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
});

const SelectBuffer = forwardRef(({ defaultValue = 5 }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        selected: OPTIONS_BUFFER.find((item) => item.value === defaultValue)
    });
    const { selected } = state;

    useImperativeHandle(ref, () => ({ getValue: () => selected.value }));

    const _handleSelect = (item) => {
        dispatchState((prevState) => ({ ...prevState, selected: item }));
    };

    return (
        <div className="tabs">
            <div className="btn-item ml-0">
                {OPTIONS_BUFFER.map((item) => {
                    return (
                        <button
                            key={item.value}
                            className={classNames('tab-items', {
                                'active-tab-selector': item.value === selected.value
                            })}
                            onClick={() => _handleSelect(item)}
                            type="button"
                        >
                            {item.label}
                        </button>
                    );
                })}
            </div>
        </div>
    );
});

export default SettingsContent;
