import { ADDONS_SQUARE } from 'app/config/routes';
import { REPORT_FILTER_PLAN_GET_LIST } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { debounce, showStatusBar } from 'common/utils/FunctionUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { Link } from 'react-router-dom';
import { SquareSubscriptionLoading } from './components/SquareAddonsLoading';
import { addBranchPath } from 'app/const/Branch';
import GDStatusBar from 'app/components/status/statusbar';

const SquareSubscription = () => {
    const [state, dispatchState] = useReducer(reducer, { isLoading: true, data: [] });

    const refStatusBar = useRef(null);

    useEffect(() => {
        _getPlans();
    }, []);

    const _getPlans = () => {
        /**
         * get square subscription plans
         * 1: stripe, 2: square
         */
        clientQuery(
            REPORT_FILTER_PLAN_GET_LIST,
            { data: { type: 2, merchant: 2 }, method: 'GET' },
            _handleGetPlansSuccess,
            _handleGetPlansError
        );
    };

    const _handleGetPlansSuccess = ({ data }) => {
        dispatchState({ data, isLoading: false });
    };

    const _handleGetPlansError = ({ message }) => {
        showStatusBar({ id: 'error', message, success: false, refAlert: refStatusBar });
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _handleUpdatePlan = debounce((e, plan_id) => {
        clientQuery(REPORT_FILTER_PLAN_GET_LIST, {
            data: { plan_id, value: e.target.checked ? 1 : 0, merchant: 2 },
            method: 'PUT'
        });
    }, 300);

    const _renderListPlans = () => {
        return state.data.map((item) => (
            <div key={item.id} className="rows flex-betweentop py-2">
                <p className="word-break flex-1 mr-10">
                    {item.nickname} {item.amount}
                </p>
                <div className="switch large">
                    <input
                        id={item.id}
                        className="toggle toggle-round"
                        type="checkbox"
                        defaultChecked={!!item.value}
                        onChange={(e) => _handleUpdatePlan(e, item.id)}
                    />
                    <label htmlFor={item.id}>
                        <span className="onoff showhidden" />
                    </label>
                </div>
            </div>
        ));
    };

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="header">
                    <Link to={addBranchPath(ADDONS_SQUARE)} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        Connect with Square
                    </Link>
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper --stripe">
                        <GDStatusBar ref={refStatusBar} />
                        {state.isLoading ? (
                            <SquareSubscriptionLoading />
                        ) : (
                            <div className="boxs active">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title fs-13">Manage&nbsp;Subscriptions</div>
                                    </div>
                                </div>
                                <div className="boxs__contents">
                                    <p className="description --hasline --line-sm">
                                        Choose the Square Plans you want to use inside GorillaDesk.
                                    </p>
                                    {_renderListPlans()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SquareSubscription;
