import {
    REPORT_REVENUE_BY_TAGS_DETAIL_GET_LIST,
    REPORT_REVENUE_BY_TAGS_MONTH_GET_LIST,
    REPORT_REVENUE_TAGS_METHOD_FILTER
} from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportRevenueTags({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_TAGS_MONTH_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_customer');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportRevenueTagsPayment({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_BY_TAGS_DETAIL_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response, 'total_payment');
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListMethodFilterRevenueByTags({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVENUE_TAGS_METHOD_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
