import { COMMON, DATE_FORMAT, DEFAULT_ALL, END_DATE_OF_MONTH, START_DATE_OF_MONTH, YEAR_CURRENT } from 'app/const/App';
import { OPTION_SELECT_DATE_RANGE_PICKER } from 'app/const/drip/Common';
import moment from 'moment';

export const LIST_WIDGETS = {
    STATS: 'stats',
    JOBS: 'job',
    REVENUE: 'revenue',
    SALES_FORECAST: 'sales_forecast',
    INVOICES_TOTAL: 'invoice',
    INVOICES_AGING: 'invoice_aging',
    ESTIMATES: 'estimate',
    NEW_CUSTOMER_LEAD: 'new_customer_lead_by_source',
    ACTIVE_SERVICE: 'active_service',
    TAGS: 'tags',
    REVENUE_BY_STAFF: 'revenue_by_staff',
    PAYMENTS: 'payment',
    REVENUE_PER_MONTH: 'monthly_revenue'
};

export const TYPE_PICKER = {
    RANGE: 'range',
    YEAR: 'year',
    YEARS: 'years'
};

export const DEFAULT_SELECT_DATE_WIDGET = {
    [TYPE_PICKER.RANGE]: {
        start: moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
        end: moment(END_DATE_OF_MONTH).format(DATE_FORMAT)
    },
    [TYPE_PICKER.YEAR]: YEAR_CURRENT
};

export const getAllParamsWidget = () => {
    return {
        [LIST_WIDGETS.STATS]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.JOBS]: {
            schedules: DEFAULT_ALL,
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.INVOICES_TOTAL]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.ESTIMATES]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.NEW_CUSTOMER_LEAD]: {
            year: DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.YEAR]
        },
        [LIST_WIDGETS.SALES_FORECAST]: {
            year: DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.YEAR]
        },
        [LIST_WIDGETS.REVENUE_PER_MONTH]: {
            years: [YEAR_CURRENT]
        },
        [LIST_WIDGETS.TAGS]: {
            type: TAGS_TAB.LOCATION.id
        },
        [LIST_WIDGETS.PAYMENTS]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.REVENUE_BY_STAFF]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        },
        [LIST_WIDGETS.ACTIVE_SERVICE]: {
            ...DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.RANGE]
        }
    };
};

export const getDefaultParamsWidget = (keyWidget = LIST_WIDGETS.STATS) => {
    return keyWidget ? getAllParamsWidget()[keyWidget] : getAllParamsWidget();
};

export const KEY_WIDGET_LOCAL_STORAGE = 'filter_widgets';

export const LIST_DATA_STATS = [
    {
        id: 'revenue',
        label: 'revenue',
        isBgBlue: true,
        isTransform: true
    },
    {
        id: 'collected',
        label: 'collected',
        isBgBlue: true,
        isTransform: true
    },
    {
        id: 'job_completed',
        label: 'job_completed',
        isBgBlue: true,
        isTransform: false
    },
    {
        id: 'new_job_booked_online',
        label: 'new_job_booked_online',
        isBgBlue: false,
        isTransform: true
    },
    {
        id: 'total_new_job',
        label: 'total_new_job',
        isBgBlue: false,
        isTransform: true
    }
];

export const LIST_RENDER_LINE_CHART_JOBS = ['incomplete', 'completed'];

export const COLOR_TITLE = 'rgba(0, 0, 0, 0.1)';

export const SIZE_CHART = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
};

export const LIST_OPTIONS_SELECT_DATE_WIDGET = [
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_DATE,
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_WEEK,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_7_DAYS,
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_30_DAYS,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_3_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_6_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_YEAR,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_YEAR,
    OPTION_SELECT_DATE_RANGE_PICKER.ALL_TIME
];

export const TAGS_TAB = {
    LOCATION: {
        id: '4',
        label: COMMON.LOCATION
    },
    CUSTOMER: {
        id: '3',
        label: COMMON.CUSTOMER
    }
};
