const IconStartOver = () => {
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2932 7.98679C15.3003 11.8436 12.1608 14.9973 8.31127 15C6.50781 15.0013 4.86366 14.318 3.62346 13.1952C3.47986 13.0652 3.47414 12.8411 3.61097 12.7041L4.08879 12.2253C4.21638 12.0975 4.42055 12.0938 4.55519 12.2142C5.55116 13.1044 6.86515 13.6452 8.30626 13.6452C11.4208 13.6452 13.9409 11.1199 13.9409 8C13.9409 4.87966 11.4203 2.35484 8.30626 2.35484C6.74209 2.35484 5.32815 2.99201 4.30786 4.02137L5.6909 5.38787C5.90617 5.60055 5.75584 5.96774 5.45352 5.96774H1.83808C1.65137 5.96774 1.5 5.81608 1.5 5.62903V2.05686C1.5 1.75623 1.86183 1.6046 2.07546 1.8157L3.34598 3.07101C4.61182 1.79221 6.36655 1 8.30626 1C12.1606 1 15.286 4.12688 15.2932 7.98679ZM10.5866 10.532L10.9843 9.98416C11.0941 9.83287 11.0608 9.62103 10.9098 9.51101L8.98241 8.10664V4.04839C8.98241 3.86133 8.83104 3.70968 8.64433 3.70968H7.96818C7.78148 3.70968 7.63011 3.86133 7.63011 4.04839V8.79659L10.1144 10.6067C10.2654 10.7167 10.4768 10.6833 10.5866 10.532Z"
                fill="#464A55"
            />
        </svg>
    );
};

export default IconStartOver;
