import React, { useReducer, useEffect, useRef } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { useTranslation } from 'react-i18next';
import ZapierAddonsContent from './components/ZapierAddonsContent';
import { ZAPIER_ADDONS_DETAIL, UPDATE_ADDONS_RESET_KEY_ZAPIER } from 'app/const/Api';
import ZapierAddonsLoading from './components/ZapierAddonsLoading';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconEye from 'assets/icon/IconEye';
import IconSync from 'assets/icon/IconSync';
import IconCopy from 'assets/icon/IconCopy';
import IconHide from 'assets/icon/IconHide';
import GDModalWarning from 'app/components/modal/ModalWarning';
import ButtonSave from 'app/components/button/ButtonSave';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import GdConfirm from 'app/components/confirm';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsZapier = () => {
    const [state, dispatchState] = useReducer(reducer, {
        zapier: {},
        isLoading: true,
        isHideKey: true
    });
    const { t } = useTranslation(['addons', 'common']);
    const refAlert = useRef(null);
    const refInput = useRef(null);
    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);
    const refConfirmCopyKey = useRef(null);
    const { zapier, isLoading, isHideKey } = state;

    const compileKey = (key) => key.replace(/[a-zA-Z0-9-&\/\\#,+()$~%.'":*?<>{}=@!^]/g, '*');

    useEffect(() => {
        _getDetailZapierAddons();
    }, []);

    const _getDetailZapierAddons = () => {
        clientQuery(ZAPIER_ADDONS_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        const zapier = data;
        dispatchState({ zapier, isLoading: false });
    };

    const _handleResetKey = () => refConfirm.current._open();

    const _handleConfirmResetKey = () => {
        clientQuery(
            UPDATE_ADDONS_RESET_KEY_ZAPIER,
            { method: 'PUT' },
            _handleResetKeySuccess,
            null,
            _handleResetKeyFinally
        );
    };

    const _handleResetKeySuccess = ({ data }) => {
        dispatchState({ zapier: { ...zapier, key: data.key } });
        refInput.current.value = compileKey(zapier.key);
        _handleCloseConfirmReset();
    };

    const _handleResetKeyFinally = (res) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: res.message,
            type: LIST_STATUS.SUCCESS
        });
        dispatchState({ isHideKey: true });
        refButtonSave.current.removeLoading();
    };

    const _handleCopyKey = async () => {
        await navigator.clipboard.writeText(zapier.key);
        refConfirmCopyKey.current.open(null, t('addons:confirm_text'));
    };

    const _handleHideKey = () => {
        if (isHideKey) {
            dispatchState({ isHideKey: false });
            refInput.current.value = zapier.key;
        } else {
            dispatchState({ isHideKey: true });
            refInput.current.value = compileKey(refInput.current.value);
        }
    };

    const _handleCloseConfirmReset = () => refConfirm.current._close();

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <AlertCustomer ref={refAlert} />
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <ZapierAddonsLoading />
                    ) : (
                        <>
                            <ZapierAddonsContent {...zapier} />
                            {!zapier.upgrade && (
                                <>
                                    <div className="has-form form-zaipier">
                                        <span className="txt mb-1">{t('addons:secret_key')}</span>
                                        <div className="flex-betweenitems">
                                            <div className="password-input">
                                                <input
                                                    ref={refInput}
                                                    defaultValue={compileKey(zapier.key)}
                                                    className="field-input"
                                                    type="text"
                                                />
                                                <span
                                                    className="svg-search-absolute cursor-pointer"
                                                    onClick={_handleHideKey}
                                                >
                                                    {isHideKey ? <IconEye /> : <IconHide />}
                                                </span>
                                            </div>
                                            <div className="v2-btn-default has-icon" onClick={_handleResetKey}>
                                                <IconSync /> {t('addons:reset')}
                                            </div>
                                            <div
                                                className="v2-btn-main has-icon svg-white-stroke"
                                                onClick={_handleCopyKey}
                                            >
                                                <IconCopy /> {t('addons:copy')}
                                            </div>
                                        </div>
                                    </div>
                                    <GDModalWarning
                                        ref={refConfirm}
                                        title={t('addons:title_confirm_reset_key_zapier')}
                                        description={t('addons:description_confirm_reset_key_zapier')}
                                        footer={
                                            <div className="footer-modal">
                                                <div
                                                    className="v2-btn-default --transparent"
                                                    onClick={_handleCloseConfirmReset}
                                                >
                                                    {t('addons:cancel')}
                                                </div>
                                                <ButtonSave
                                                    title={t('addons:confirm')}
                                                    ref={refButtonSave}
                                                    onSave={_handleConfirmResetKey}
                                                />
                                            </div>
                                        }
                                    />

                                    <GdConfirm
                                        ref={refConfirmCopyKey}
                                        title={t('addons:confirm')}
                                        listButton={{ confirm: true, cancel: false }}
                                        titleConfirm={t('common:confirm')}
                                    />
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsZapier;
