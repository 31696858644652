import React, { useReducer, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { SALES_FORECAST_LIST_FILTER, getColumnsParams, SORT_BY } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { URL_REPORT_SALES_FORECAST } from 'app/const/api/Export';

import { getGidColumns } from 'app/const/report/SalesForecast';
import { getListReportSalesForecast } from 'common/redux/actions/reports/salesForecastAction';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { reducer } from 'app/const/Reducer';

import { LIST_EXPORT } from 'app/const/report/Common';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import loadable from '@loadable/component';
import { removeObjParams, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));

function ReportSalesForecast() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refStatusBar = useRef();

    const initState = {
        data: [],
        isLoading: true,
        total: 0,
        rowTotal: [],
        refreshScreen: 0
    };

    const [dataReport, dispatchActionReport] = useReducer(reducer, initState);
    const { total, isLoading, refreshScreen } = dataReport;

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SALES_FORECAST);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.SALES_FORECAST);

    useEffect(() => {
        getListReport(paramsReport);
    }, []);

    const getListReport = (params) => {
        const newParams = removeObjParams(params, ['columns', 'currentPage']);

        dispatch(getListReportSalesForecast(newParams, getListSuccess, getListFailed));
    };

    const getListSuccess = (response) => {
        const totalCustomers = response.data.length;

        const dataReducer = {
            isLoading: false,
            data: response.data,
            rowTotal: createRowTotal(response['total_customer'], totalCustomers),
            total: totalCustomers,
            refreshScreen: refreshScreen + 1
        };
        dispatchActionReport(dataReducer);
    };

    const createRowTotal = (data, totalCustomers) => {
        const columns = getColumnsParams([REPORT_TYPE.SALES_FORECAST]);
        const totalColumns = columns.map((column) => {
            if (typeof data?.[column] !== 'undefined') {
                return {
                    id: column,
                    isShow: true,
                    totalAmount: data[column],
                    isCurrency: true
                };
            }
            return { id: column, isShow: true };
        });
        totalColumns[0].title = t('report:total_n_customers', { count: totalCustomers });
        return totalColumns;
    };

    function getListFailed() {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
        dispatchActionReport({ ...initState, isLoading: false });
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocal);
        dispatchActionReport({ isLoading: true, total: 0 });
        getListReport(params);
    };

    const _handleChangeFilter = (params, type) => {
        if (type === SORT_BY) {
            _handleUpdate();
        }
    };

    function _renderHeaderLeft() {
        return (
            <HeaderBottom
                typeReport={REPORT_TYPE.SALES_FORECAST}
                filters={SALES_FORECAST_LIST_FILTER}
                isNotShowDateRange
                isLoading={isLoading}
                handleUpdate={_handleUpdate}
                handleChangeFilter={_handleChangeFilter}
            />
        );
    }

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: total })}
                    isDisable={!total}
                    activePrint
                    params={paramsReport}
                    url={URL_REPORT_SALES_FORECAST}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_SALES_FORECAST}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.SALES_FORECAST}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.SALES_FORECAST,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.SALES_FORECAST}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <StatusBar ref={refStatusBar} />
                    <div className="wrap-tables flex-column">
                        <GdGridView
                            isEmptyFlat
                            isLoading={isLoading}
                            classTable="scrolls-x --forecast table-multi-column has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={dataReport.data}
                            fileTranslation={'report'}
                            handleClickHeader={handleActionHeader}
                            {...getGidColumns(paramsReport?.order)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGidColumns()?.contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                            showRowTotalOnTop
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportSalesForecast;
