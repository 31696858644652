import { ONE_HUNDRED_PERCENT, USAGE_MAXIMUM } from 'app/const/addons';
import { POSITION_AFTER_CODE } from 'app/const/Currency';

const ranges = [
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' }
];

function reduceNumberLengthByDigit(n, fixedNumber = null) {
    for (var i = 0; i < ranges.length; i++) {
        if (n >= ranges[i].divider) {
            const value = n / ranges[i].divider;
            const finalValue = fixedNumber !== null ? value.toFixed(fixedNumber) : value;

            return finalValue.toString().concat(ranges[i].suffix);
        }
    }
    return n;
}

// function formatNumber(number, fractionDigits = 0) {
//     const getFractionDigits = (strNumber) => {
//         if (fractionDigits < 1) return '';

//         let result = '.';

//         for (let i = 0; i < fractionDigits; i++) {
//             result = result.concat(strNumber?.[i] || '0');
//         }

//         return result;
//     };

//     let [beforeDot, afterDot] = number.toString().split('.');
//     beforeDot = beforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
//     afterDot = getFractionDigits(afterDot);

//     return beforeDot.concat(afterDot);
// }

const roundingMoney = (number) => {
    const finalNumber = Number(number);
    const value = isNaN(finalNumber) ? '0' : (Math.round((finalNumber + Number.EPSILON) * 100) / 100).toFixed(2);
    return value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

function transformToCurrency(value = 0, currency = { symbol: '', position: 0 }) {
    const { symbol, position } = currency;
    if (isNaN(value)) {
        return null;
    }

    switch (position) {
        case POSITION_AFTER_CODE:
            return `${roundingMoney(value)}${symbol}`;
        default:
            return `${symbol}${roundingMoney(value)}`;
    }
}

const onKeyDownNumber = (event) => {
    (/^[eE]{1}$/.test(event.key) || event.key === '+' || event.key === ',' || event.key === '-') &&
        event.preventDefault();
};

const onKeyDownOnlyNumber = (event) => {
    const finalKeyCode = event.keyCode;
    return (
        event.ctrlKey ||
        event.altKey ||
        (47 < finalKeyCode && finalKeyCode < 58 && event.shiftKey === false) ||
        (95 < finalKeyCode && finalKeyCode < 106) ||
        finalKeyCode === 8 ||
        finalKeyCode === 9 ||
        (finalKeyCode > 34 && finalKeyCode < 40) ||
        finalKeyCode === 46
    );
};

const roundingNumber = (number) => {
    const finalNumber = Number(number);
    if (isNaN(finalNumber)) return '0';

    const finalValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false
    }).format(finalNumber);
    return isNaN(Number(finalValue)) ? '0' : finalValue;

    // const finalNumber = Number(number);
    // return isNaN(finalNumber) ? '0' : (+(Math.round(finalNumber + 'e+2') + 'e-2')).toFixed(2);

    // const finalNumber = Number(number);
    // return isNaN(finalNumber) ? '0' : (Math.round((finalNumber + Number.EPSILON) * 100) / 100).toFixed(2);
};

const onHandlePercent = (value = 0) => {
    return ((value / USAGE_MAXIMUM) * ONE_HUNDRED_PERCENT).toFixed(0);
};

/**
 * Return a random number between min and max.
 * @param min - The minimum number that can be returned.
 * @param max - The maximum number to be returned.
 */
const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const addLeadingZero = (number) => {
    if (number < 10) {
        return '0'.concat(number);
    }
    return number.toString();
};

export function formatNumberWithCommas(number) {
    if (isNaN(number)) return 0;
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
}

export const roundingNumberDecimal = ({ number = 0, fixed = 0 }) => {
    const calcFixed = Math.pow(10, fixed);
    return Math.round(number * calcFixed) / calcFixed;
};

export {
    reduceNumberLengthByDigit,
    transformToCurrency,
    onKeyDownNumber,
    roundingNumber,
    roundingMoney,
    onKeyDownOnlyNumber,
    onHandlePercent,
    randomNumber,
    addLeadingZero
};
