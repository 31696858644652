import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AUTH_LOGIN } from 'app/const/Route';
import { removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { ACCESS_TOKEN } from 'app/const/App';

function FooterBackLogin() {
    const { t } = useTranslation(['auth', 'common']);

    return (
        <div className="gd-login-confirm border-none">
            {t('common:you_have_account')}
            <Link className="gd-login-label" to={AUTH_LOGIN} onClick={() => removeLocalStorage(ACCESS_TOKEN)}>
                {t('common:login')}
            </Link>
        </div>
    );
}

export default FooterBackLogin;
