export const TYPE_SETTING_TILES = {
    GET_LIST_SETTING: 'GET_LIST_SETTING',
    GET_LIST_OPTIONS: 'GET_LIST_OPTIONS',
    UPDATE_LIST_SETTING: 'UPDATE_LIST_SETTING'
};

export const getListSettingsTiles = (actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_TILES.GET_LIST_SETTING,
        actionSuccess,
        actionFailed
    };
};

export const getListOptionsTiles = (actionSuccess, actionFailed, initDataList = []) => {
    return {
        type: TYPE_SETTING_TILES.GET_LIST_OPTIONS,
        actionSuccess,
        actionFailed,
        initDataList
    };
};

export const updateListSettingsTiles = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_TILES.UPDATE_LIST_SETTING,
        params,
        actionFailed,
        actionSuccess
    };
};
