import { generateDataSet, hideDot, tooltip } from 'app/components/chart/line/chart';
import { LABEL_CHART_THE_MONTHS_OF_YEAR } from 'app/const/App';
import { COLOR_TITLE } from 'app/modules/dashboard/const';
import { Tooltip } from 'chart.js';

Tooltip.positioners.bottom = function (items) {
    const pos = Tooltip.positioners.average(items);

    if (pos === false) {
        return false;
    }

    const chart = this.chart;

    return {
        x: pos.x,
        y: chart.chartArea.bottom,
        xAlign: 'center',
        yAlign: 'bottom'
    };
};

let tooltipEl;

function hideTooltip(event) {
    if (!!(event?.target?.id === '' && event?.target?.className !== 'scrolls' && tooltipEl)) {
        const { offsetTop, offsetLeft, clientHeight, clientWidth } = tooltipEl;
        const { clientX, clientY } = event;
        if (
            clientY > offsetTop &&
            clientY < offsetTop + clientHeight &&
            clientX > offsetLeft &&
            clientX < offsetLeft + clientWidth
        )
            return;

        tooltipEl.style.transition = 'opacity 0.5s ease-in-out';
        tooltipEl.style.opacity = 0;
        tooltipEl.style.pointerEvents = 'none';
    }
}

document.body.addEventListener('mouseout', hideTooltip);

export const configNewCustomerAndLead = ({ data }) => {
    return {
        data: {
            labels: LABEL_CHART_THE_MONTHS_OF_YEAR,
            datasets: generateDataSet({
                datasets: data,
                isFillColor: false,
                isShowLabel: true
            })
        },
        options: {
            layout: {
                padding: {
                    left: -23,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        color: COLOR_TITLE
                    },
                    display: true,
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        stepSize: 2
                    },
                    title: {
                        display: true,
                        color: COLOR_TITLE
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                htmlLegendPlugin: true,
                display: true,
                grid: {
                    drawBorder: false
                },
                tooltip: {
                    ...tooltip(),
                    enabled: false,
                    position: 'bottom',
                    mode: 'index',
                    external: function ({ tooltip, chart }) {
                        tooltipEl = document.getElementById('chartjs-tooltip');

                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        if (tooltip.opacity === 0) {
                            return;
                        }

                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltip.yAlign) {
                            tooltipEl.classList.add(tooltip.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        if (tooltip.body) {
                            const titleLines = tooltip.title || [];
                            const bodyLines = tooltip.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function (title) {
                                innerHtml +=
                                    '<tr><th style="color: #fff; padding-bottom: 5px; font-size: "16px">' +
                                    title +
                                    '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                                const parts = body[0]?.split(':');
                                const labelText = parts.slice(0, -1).join(':');
                                const valueText = parts[parts.length - 1];
                                const colors = tooltip.labelColors[i];

                                let style = 'background:' + colors.backgroundColor.replace('0.5', '0.8');
                                style += '; border-color:' + colors.borderColor;
                                style += '; border-width: 2px';

                                const styleSquare = `; color: #fff; min-width: 12px; min-height:12px;
                                    border: 1.8px solid; display: inline-block"></span>`;
                                const styleLabel = `color: #fff; white-space: nowrap;
                                    max-width: 100px; overflow: hidden; text-overflow: ellipsis; font-size: 11px;`;
                                const styleTd = 'display: flex; align-items: center; margin-bottom: 4px;';
                                const spanQuare = '<span style="' + style + styleSquare;

                                innerHtml +=
                                    '<tr><td style="' +
                                    styleTd +
                                    '">' +
                                    spanQuare +
                                    '<span style="' +
                                    styleLabel +
                                    'margin-left: 5px;' +
                                    '">' +
                                    labelText +
                                    '</span>' +
                                    '<span style="' +
                                    styleLabel +
                                    '">: ' +
                                    valueText +
                                    '</span>' +
                                    '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        const position = chart.canvas.getBoundingClientRect();

                        tooltipEl.style.transition = 'opacity 0.5s ease-in-out';
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'fixed';

                        const horizontalThreshold = chart.width / 2;

                        if (tooltip.caretX <= horizontalThreshold) {
                            tooltipEl.style.left = position.left + window.pageXOffset + tooltip.caretX + 'px';
                        } else {
                            tooltipEl.style.left =
                                position.left + window.pageXOffset + tooltip.caretX - tooltipEl.offsetWidth + 'px';
                        }

                        const maxHeightTooltip = 350;
                        tooltipEl.style.top =
                            position.top +
                            window.pageYOffset +
                            tooltip.caretY -
                            (tooltipEl.offsetHeight > maxHeightTooltip ? maxHeightTooltip : tooltipEl.offsetHeight) +
                            'px';

                        tooltipEl.style.padding = '5px';
                        tooltipEl.style.overflow = 'auto';
                        tooltipEl.style.maxHeight = `${maxHeightTooltip}px`;
                        tooltipEl.style.pointerEvents = 'auto';
                        tooltipEl.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
                        tooltipEl.style.borderRadius = '5px';
                        tooltipEl.style.zIndex = 999;
                    }
                }
            },
            elements: hideDot
        }
    };
};
