import React from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_VERIFY_MODAL_AUTH } from '../constants';
import RowTwoAuth from './RowTwoAuth';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const TableTwoAuth = ({ data = [], onEdit = () => {} }) => {
    const { t } = useTranslation('setting');

    const _handleClickAction = (data) => {
        onEdit({ ...data, typeVerify: TYPE_VERIFY_MODAL_AUTH.UPDATE });
    };

    const _renderRows = () => {
        if (!data.length) return <GridEmpty isFlat />;

        return data.map((item) => <RowTwoAuth key={item.id} item={item} onClickAction={_handleClickAction} />);
    };

    return (
        <div className="tables table-multi-column has-text-ellipsis">
            <div className="rows --fixed --header">
                <div className="col col-lg">
                    <p className="col-label">{t('method')}</p>
                </div>
                <div className="col col-md">
                    <p className="col-label">{t('date_added')}</p>
                </div>
                <div className="col --menu" />
            </div>
            <div className="tables-list">{_renderRows()}</div>
        </div>
    );
};

export default TableTwoAuth;
