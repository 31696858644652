import GdGridView from 'app/components/grid/GdGridView';
import { actionDeleteInvoiceRecurrence, CUSTOMER_INVOICE_RECURRING } from 'app/const/Api';
import { GRID_RECURRING_TABLE } from 'app/const/customer/CustomerInvoices';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { LIST_STATUS } from 'app/const/Status';
import { useTranslation } from 'react-i18next';
import { reducer } from 'app/const/Reducer';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { useContext } from 'react';
import { DEFAULT_ALL } from 'app/const/App';

const CustomerInvoiceTableRecurring = ({ onShowAlert = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { id: customer_id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const { location_ids, unitSelected, reloadCustomerPage } = useContext(CustomerDetailContext);
    const { data: dataRecurring, isLoading } = state;
    const refOldLocation = useRef(null);
    const refOldUnit = useRef(null);

    useEffect(() => {
        if (reloadCustomerPage || checkReload(location_ids, unitSelected, customer_id)) {
            if (!isLoading) dispatchState({ isLoading: true });
            refOldLocation.current = location_ids;
            _getListInvoiceRecurring();
        }
    }, [location_ids, unitSelected, reloadCustomerPage, customer_id]);

    const checkReload = (location_ids, unitSelected, customer_id) => {
        if (!location_ids || !unitSelected || !customer_id) return false;
        return location_ids !== refOldLocation.current || unitSelected?.id !== refOldUnit.current || !!customer_id;
    };

    const _getListInvoiceRecurring = (data) => {
        const params = data || {};
        delete params?.filterTab;

        const _success = ({ data, total }) => {
            dispatchState((prev) => ({
                ...prev,
                data,
                isLoading: false,
                totalPage: Math.ceil(total / params.limit),
                total
            }));
        };
        const _failed = () => {
            dispatchState((prev) => ({ ...prev, data: [], isLoading: false }));
        };
        clientQuery(
            CUSTOMER_INVOICE_RECURRING,
            {
                data: { ...params, customer_id, location_ids, unit_ids: unitSelected?.id || DEFAULT_ALL },
                method: 'GET'
            },
            _success,
            _failed
        );
    };

    useImperativeHandle(ref, () => ({ _getListInvoiceRecurring }));

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data.id,
                status: data.status,
                total: '',
                isRecurring: true
            })
        );
    };

    const _deleteRecurrenceInvoice = (item) => {
        const _success = () => {
            onShowAlert(LIST_STATUS.SUCCESS, {
                id: 'success',
                message: t('delete_invoice_success'),
                type: LIST_STATUS.SUCCESS
            });
            dispatchState((prev) => ({ ...prev, data: dataRecurring.filter((invoice) => invoice.id !== item.id) }));
        };

        const _failed = ({ message }) =>
            onShowAlert(LIST_STATUS.ERROR, {
                id: 'error',
                message: message || t('delete_invoice_error'),
                type: LIST_STATUS.ERROR
            });

        clientQuery(
            actionDeleteInvoiceRecurrence(item.recurrence_id),
            { data: {}, method: 'DELETE' },
            _success,
            _failed
        );
    };

    const _handleClick = ({ mode, item }) => {
        switch (mode) {
            case 'view':
            case 'edit':
                _handleOpenInvoice(item);
                break;
            case 'delete':
                _deleteRecurrenceInvoice(item);
                break;
            default:
                break;
        }
    };

    if (!dataRecurring.length) return null;

    return (
        <GdGridView
            content={dataRecurring}
            isLoading={isLoading}
            classTable="tables table-recurring wrap-tables"
            isHasDropdown
            fileTranslation="report"
            {...GRID_RECURRING_TABLE}
            handleClick={_handleClick}
            onOpenInvoice={_handleOpenInvoice}
            isScroll
        />
    );
};

export default forwardRef(CustomerInvoiceTableRecurring);
