import GdButton from 'app/components/button';
import { STATEMENT_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import FilterReport from 'app/modules/report/components/FilterReport';
import ReportDatePicker from 'app/modules/report/components/ReportDatePicker';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { useTranslation } from 'react-i18next';

const StatementActions = ({ handleChangeFilter = () => {}, handleUpdate = () => {}, statementType = 2 }) => {
    const { t } = useTranslation(['report', 'common']);
    const renderDateRangePicker = () => {
        switch (statementType) {
            case 1:
                return (
                    <ReportDatePicker
                        reportType={REPORT_TYPE.STATEMENT_CUSTOMER_LIST}
                        keyLocal="end"
                        onSelect={handleUpdate}
                    />
                );
            default:
                return (
                    <ReportDateRangePicker
                        reportType={REPORT_TYPE.STATEMENT_CUSTOMER_LIST}
                        onSelect={handleUpdate}
                        wrapperClassName="header-items"
                    />
                );
        }
    };

    return (
        <>
            <div className="header shadow-none">
                {renderDateRangePicker()}
                <FilterReport
                    onSelect={handleChangeFilter}
                    reportType={REPORT_TYPE.STATEMENT_CUSTOMER_LIST}
                    filters={STATEMENT_LIST_FILTER}
                />
                <GdButton onClick={handleUpdate} className="v2-btn-main header-items">
                    {t('common:update')}
                </GdButton>
            </div>
        </>
    );
};

export default StatementActions;
