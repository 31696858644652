import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { formatTimeToHMS } from 'common/utils/TimeUtils';
import SortOrderTable from 'app/components/grid/components/SortOrderTable';

export const RowFixed = ({
    header = {},
    fileTranslation = 'report',
    classNameRow = '',
    classHeaderFixed = 'list-user ',
    classHeaderTable = 'tables table-fixed',
    onClickHeader = () => {},
    isFooter = false,
    data = {},
    columns
}) => {
    const { t } = useTranslation(fileTranslation);

    const newArray = useMemo(() => {
        const arrayTemp = [];
        Object.keys(header).forEach((key) => {
            arrayTemp.push({ ...header[key], key });
        });
        return arrayTemp;
    }, [header]);

    const _renderHeaderItem = (item, isFixed) => {
        const { key, title, subTitle, style, actionType, currentValue, isFixed: itemFixed, type: typeValue } = item;

        if (!itemFixed && !columns.find((col) => col.id === key)) {
            return false;
        }

        if ((itemFixed && !isFixed) || (!itemFixed && isFixed)) {
            return false;
        }

        const _handleClickHeader = ({ nextOrder = '' }) => {
            onClickHeader({
                actionType,
                columnsTarget: key,
                currentValue: nextOrder
            });
        };

        const _renderValueTotal = () => {
            const valueTotal = data[key];
            return typeValue === LIST_TABLE_ITEM_TYPE.TIME_HMS ? formatTimeToHMS(valueTotal) : valueTotal;
        };

        return (
            <div key={key} className={style}>
                <div className={classNames('has-orderby', currentValue)}>
                    <span className={classNames('txt-ellipsis flexcenter gap-4', { 'col-label': isFooter })}>
                        {isFooter && !itemFixed ? _renderValueTotal() : t(title)}
                        {actionType === COMMON.ORDER && (
                            <SortOrderTable value={key} currentSort={currentValue} onClickOrder={_handleClickHeader} />
                        )}
                    </span>
                    {!!subTitle && <span className="black-darklight fw-normal">{t(subTitle)}</span>}
                </div>
            </div>
        );
    };

    const _renderHeader = () => {
        const _renderHeaderFixed = () => {
            return <div className={classHeaderFixed}>{newArray.map((item) => _renderHeaderItem(item, true))}</div>;
        };

        const _renderHeaderTable = () => {
            return (
                <div className={classHeaderTable}>
                    <div className={classNames('rows', { '--total': isFooter })}>
                        {newArray.map((item) => _renderHeaderItem(item, false))}
                    </div>
                </div>
            );
        };
        return (
            <div className={classNameRow}>
                {_renderHeaderFixed()}
                {_renderHeaderTable()}
            </div>
        );
    };

    return _renderHeader();
};
