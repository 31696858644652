import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGidColumns = (actionsStatus) => {
    const REPORT_HEADER_TOTAL_SALES = {
        invoice_number: {
            title: 'invoice',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        status: {
            title: 'status',
            style: 'col col-xs',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        amount: {
            title: 'amount',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        w_o_tax: {
            title: 'w_o_tax',
            style: 'col col-total',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_TOTAL_SALES = [
        {
            id: 'invoice_number',
            title: 'invoice_number',
            status: true
        },
        { id: 'customer', title: 'customer', status: true },
        { id: 'date', title: 'date', status: true },
        {
            id: 'status',
            title: 'status',
            status: true
        },

        { id: 'amount', title: 'amount', status: true },
        { id: 'w_o_tax', title: 'w_o_tax', status: true }
    ];

    const REPORT_CONTENT_TOTAL_SALES = {
        invoice_number: {
            id: 'invoice_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LINK,
            styleText: 'name'
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        date: { id: 'date', style: 'col col-md' },
        status: {
            id: 'status',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        amount: { id: 'amount', style: 'col col-total', type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL },
        w_o_tax: { id: 'w_o_tax', style: 'col col-total', type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...REPORT_HEADER_TOTAL_SALES });

    return {
        header: newHeader,
        columns: REPORT_COLUMNS_TOTAL_SALES,
        contentConfig: REPORT_CONTENT_TOTAL_SALES
    };
};
