import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import ItemCard from './ItemCard';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { LoadingHeaderOppCol, LoadingMoreItem } from './LoadingPipeline';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_OPPORTUNITY_PIPELINE } from 'app/const/api/V2';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { REPORT_TYPE } from 'app/const/Reports';
import { calculatePipeline } from 'common/utils/OpportunityUtils';
import { DEFAULT_VALUE_OPPORTUNITY_REPORT, KEY_PERIOD, TYPE_MATH } from 'app/const/opportunity';
import StatusBar from 'app/components/status/statusbar';
import { ACCESS_CUSTOMER_OPP } from 'app/const/Permissions';
import { checkPermission } from 'common/utils/PermissionUtils';

const ColumnDraggable = (
    {
        initialState = {},
        index,
        reload,
        isDisable = false,
        onEditItem = () => {},
        onContact = () => {},
        onShowModalConfirm = () => {},
        onShowUserDeleted = () => {}
    },
    ref
) => {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);

    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.OPPORTUNITIES_PIPELINE);
    const isHaveCustomerOpp = checkPermission(permissionsList, ACCESS_CUSTOMER_OPP);
    const abortController = useRef(null);
    const refAlert = useRef(null);

    const [state, dispatchState] = useReducer(reducer, { ...initialState, isLoading: true, isDropDisabled: false });
    const { id, count, name, status, opportunities, isLoading, isDropDisabled } = state;

    useImperativeHandle(ref, () => ({
        onAddItem: _handleAddItem,
        onRemoveItem: _handleRemoveItem,
        getItem: _handleGetItem,
        setItem: _handleSetItem,
        getPipeline: () => state,
        setDropDisable: _handleSetDropDisable,
        showAlert: _handleShowAlert
    }));

    useEffect(() => {
        !!reload && _handleFetchData();
    }, [reload]);

    const _handleFetchData = () => {
        if (abortController.current) abortController.current.abort();
        abortController.current = new AbortController();

        const params = getLocalStorage(keyLocalStorage);
        const staffs = params.staffs;
        if (Array.isArray(staffs)) params.staffs = staffs.join(',');
        else if (+staffs === -1) delete params.staffs;

        dispatchState((prev) => {
            return { ...prev, opportunities: [], isLoading: true, count: 0, ...DEFAULT_VALUE_OPPORTUNITY_REPORT };
        });

        const _handleSuccess = ({ data }) => {
            if (data.pipelines.existed_user_deleted) onShowUserDeleted(true);
            dispatchState((prev) => {
                return { ...prev, ...{ ...data.pipelines, bi_monthly: data.pipelines.bimonthly }, isLoading: false };
            });
        };

        const _handleFail = ({ isAborted, message, statusCode }) => {
            !isAborted && onShowModalConfirm({ message, statusCode });
            dispatchState((prev) => {
                return { ...prev, opportunities: [], isLoading: isAborted ? prev.isLoading : false };
            });
        };

        clientQuery(
            REPORT_OPPORTUNITY_PIPELINE,
            { method: 'GET', data: { ...params, pipeline_id: id }, abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderLoading = () => {
        if (!!opportunities?.length) return null;

        if (!Array.isArray(opportunities) || isLoading) return <LoadingMoreItem />;

        return <p className="pt-5 text-center">{t('no_matching_opportunities')}</p>;
    };

    const _handleRemoveItem = ({ indexItem, id }) => {
        dispatchState((prev) => {
            const prevOpp = [...prev.opportunities];
            const finalIndex = indexItem ?? prevOpp.findIndex((item) => item.id === id);
            const [itemRemove] = prevOpp.splice(finalIndex, 1);

            return {
                ...calculatePipeline({ type: TYPE_MATH.SUB, pipeline: prev, item: itemRemove }),
                opportunities: prevOpp
            };
        });
    };

    const _handleAddItem = ({ item }) => {
        dispatchState((prev) => {
            return {
                ...calculatePipeline({ type: TYPE_MATH.ADD, pipeline: prev, item: item }),
                opportunities: [...prev.opportunities, item].sort(
                    (a, b) => new Date(a.created_date) - new Date(b.created_date)
                )
            };
        });
    };

    const _handleGetItem = ({ indexItem, id }) => {
        return !!id ? opportunities.find((item) => item.id === id) : opportunities[indexItem];
    };

    const _handleSetItem = (data) => {
        dispatchState((prev) => {
            return {
                ...prev,
                opportunities: prev.opportunities.map((item) => {
                    if (item.id === data.id) {
                        return { ...item, ...data };
                    }
                    return item;
                })
            };
        });
    };

    const _handleSetDropDisable = (value = false) => {
        dispatchState((prev) => ({ ...prev, isDropDisabled: value }));
    };

    const _renderHeader = () => {
        return Object.values(KEY_PERIOD).map((item) => {
            const value = state[item];
            const text = transformToCurrency(value, currency);
            return (
                <div key={item} className="flex-betweenitems items">
                    <span>{t(`${item}_value`)}</span>
                    <span
                        className={classNames('txt-ellipsis flex-1 text-right', { 'is-disable': value <= 0 })}
                        title={text}
                    >
                        {text}
                    </span>
                </div>
            );
        });
    };

    const _handleTouch = ({ currentTarget, type }) => {
        currentTarget.style.overflowY = type === 'touchend' ? 'clip' : 'auto';
    };

    const _handleShowAlert = ({ message, success }) => {
        refAlert.current.showStatusBar(`col_${id}`, message, success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
    };

    return (
        <div className={classNames(`opportunity-column --${status.toLowerCase()}`, { 'wrap-loading': isLoading })}>
            <StatusBar ref={refAlert} />

            {isLoading ? (
                <LoadingHeaderOppCol />
            ) : (
                <div className="opportunity-column__header">
                    <div className="flex-betweenitems border-bottom-border-color-grey header-status">
                        <div className="status-btn txt-ellipsis">{name}</div>
                        <span className="grey-dark fw-600">{count}</span>
                    </div>
                    <div className="header-info">{_renderHeader()}</div>
                </div>
            )}

            <Droppable droppableId={index.toString()} index={index} isDropDisabled={isDropDisabled}>
                {(provided, snapshot) => {
                    const { isDraggingOver, draggingFromThisWith, draggingOverWith } = snapshot;

                    return (
                        <div
                            ref={provided.innerRef}
                            className={classNames('opportunity-column__list', {
                                'is-drag': isDraggingOver && draggingFromThisWith !== draggingOverWith
                            })}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onTouchStartCapture={_handleTouch}
                            onTouchEndCapture={_handleTouch}
                        >
                            {_renderLoading()}
                            {opportunities?.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    isDragDisabled={!isHaveCustomerOpp || isDisable}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            className="opportunity-column__item"
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <ItemCard
                                                key={item.id}
                                                data={item}
                                                onEditItem={onEditItem}
                                                onContact={onContact}
                                                isDisable={isDisable}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            <div className="box-drop-file">
                                <div className="box-drop-file__conts">
                                    <p className="title">{t('drop_and_change_status_to')}</p>
                                    <p className="description">{name}</p>
                                </div>
                            </div>
                            {provided.placeholder}
                        </div>
                    );
                }}
            </Droppable>
        </div>
    );
};

export default forwardRef(ColumnDraggable);
