import React, { useReducer, useRef } from 'react';
import IconUpgrade from 'assets/icon/IconUpgrade';
import GdConfirm from 'app/components/confirm';
import { reducer } from 'app/const/Reducer';

export default function TabCreditSync({ parentData, onUpdateOption, keyword }) {
    const [state, dispatchState] = useReducer(reducer, {
        ...parentData
    });
    const refConfirm = useRef(null);
    const finalIsActive = !!state.value;

    function _handleChangeValue() {
        const nextActive = !finalIsActive;

        if (nextActive) {
            refConfirm.current.open();
            return false;
        } else {
            dispatchState({
                value: 0
            });
            onUpdateOption(keyword, 0);
        }
    }

    function _handleConfirm() {
        dispatchState({
            value: 1
        });
        onUpdateOption(keyword, 1);
    }

    function _renderContent() {
        return (
            <>
                <p>- You have to Turn Off Automatically Apply Credit on QuickBook.</p>
                <p>- We will not support when you Turn On Automatically Apply Credit on QuickBook</p>
            </>
        );
    }

    return (
        <div className={`boxs boxs--power-up ${finalIsActive ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">Credit Sync</div>
                    <div className="dots powerups">
                        <IconUpgrade isFillOrange />
                        Power Up
                    </div>
                </div>
                <div className="switch large">
                    <input
                        id={'toggle_credit'}
                        className="toggle toggle-round"
                        checked={finalIsActive}
                        onChange={_handleChangeValue}
                        type="checkbox"
                    />
                    <label htmlFor={'toggle_credit'}>
                        <span className="enable-disable" />
                    </label>
                </div>
            </div>
            <div className="boxs__contents">
                <p className="description">Automatically Sync credits created in GorillaDesk into QuickBooks.</p>
            </div>
            <GdConfirm
                ref={refConfirm}
                title="Confirm Credit Sync"
                renderContent={_renderContent()}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleConfirm}
            />
        </div>
    );
}
