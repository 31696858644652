import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { LIST_ACTION_INVITE, STATE_ACTION_PARTICIPANT, TYPE_ACTION_INVITE } from 'app/const/Voip';
import IconClose from 'assets/icon/IconClose';
import TimeCounter from './Time';
import ListBarge from './ListBarge';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_JOIN_A_CALL } from 'app/const/api/Voip';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';
import { calculateSecondVoip } from 'common/utils/TimeUtils';
import LoadingBarLittle from 'app/components/loading/LoadingBarLittle';

const BargeBanner = ({ callData = {}, onLeaveCall = () => {} }) => {
    const { t } = useTranslation('header');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const refChangeAction = useRef(false);

    const { invite_call_id: inviteCallId, members, from: fromIdUser } = callData.inviteData || {};

    const [state, dispatchState] = useReducer(reducer, {
        type: TYPE_ACTION_INVITE.LISTEN,
        listMember: members,
        isLoading: true
    });

    const { type: finalType, listMember: finalListMember, isLoading } = state;

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, listMember: members, isLoading: false }));
        refChangeAction.current = false;
    }, [members]);

    const _handleChangeMode = (id) => {
        if (refChangeAction.current) return;

        refChangeAction.current = true;
        dispatchState((prev) => ({
            ...prev,
            type: id,
            listMember: prev.listMember.map((item) => {
                if (+item.id === profileId) {
                    return { ...item, ...STATE_ACTION_PARTICIPANT[id] };
                }
                return item;
            }),
            isLoading: true
        }));

        clientQuery(ACTION_JOIN_A_CALL, {
            method: 'POST',
            toFormData: false,
            data: { invite_call_id: inviteCallId, type: id }
        });
    };

    const _renderButtons = () => {
        const { full_name: ownerName } = companyUsers.find((item) => item.id === fromIdUser);
        return LIST_ACTION_INVITE.map(({ id, icon, text, tooltipText }) => {
            const isActive = id === finalType;
            return (
                <TooltipPopper
                    key={id}
                    className={classNames('tab-items flexcenter gap-2 svg-noeffect tooltip', {
                        'active-tab-selector': isActive
                    })}
                    tooltipText={t(tooltipText, { owner: ownerName })}
                    onClick={() => _handleChangeMode(id)}
                    popperConfig={{
                        placement: 'bottom',
                        modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
                        strategy: 'fixed'
                    }}
                >
                    {icon}
                    {isActive && isLoading ? <LoadingBarLittle /> : <span>{t(text)}</span>}
                </TooltipPopper>
            );
        });
    };

    return (
        <Fragment>
            <div className="call-bar__controll gap-md flexcenter">
                <ListBarge listUser={finalListMember} />
                <div className="tabs">
                    <div className="btn-item bg-white ml-0">{_renderButtons()}</div>
                </div>
            </div>
            <span className="is-divider" />
            <div className="call-bar__action flexcenter pr-2">
                <TimeCounter
                    wrapperClassName="action-label"
                    isUseTime
                    startAt={calculateSecondVoip(callData.connection.customParameters.get('StartTime'))}
                />
                <span className="v2-btn-default --icon-lg --transparent btn-bg-grey" onClick={onLeaveCall}>
                    <IconClose />
                </span>
            </div>
            <span className="call-bar__status w-100" />
        </Fragment>
    );
};

export default BargeBanner;
