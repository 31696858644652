import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'common/hooks/useClickOutside';
import IconDropDown from 'assets/icon/IconDropDown';
import IconPhone from 'app/components/phone/IconPhone';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const MessageTitleBox = forwardRef(({ receivers = [], customerName }, ref) => {
    const { t } = useTranslation(['header']);
    const [phoneSend, setPhoneSend] = useState(null);
    const [refPhone, phoneActive, setPhoneActive] = useClickOutside(false);

    useImperativeHandle(ref, () => ({
        getPhoneSend: () => (phoneSend?.value ? [phoneSend?.value] : null)
    }));

    useEffect(() => {
        setPhoneSend(receivers.filter((item) => item.is_default)[0]);
    }, [receivers]);

    const _toggleDropdown = (e) => {
        e.stopPropagation();
        setPhoneActive(!phoneActive);
    };

    const _setPhoneValue = useCallback((value) => {
        setPhoneSend(value);
        setPhoneActive(false);
    }, []);

    return (
        <div
            ref={refPhone}
            className={classNames('v2-dropdown title-message-chat', {
                active: phoneActive
            })}
        >
            <div className="wrap-action" onClick={(e) => _toggleDropdown(e)}>
                <div className="message-name">
                    {t('report:message')}
                    {!receivers.length && !phoneSend && <span className="name"> {customerName} </span>}

                    {phoneSend && (
                        <>
                            <span className="name"> {phoneSend.name} </span>
                            <span className="phone">{phoneSend.value}</span>
                            <span className="ml-1">
                                <IconPhone isValid={phoneSend.is_valid} />
                            </span>
                        </>
                    )}
                </div>
                <div className="arrow">
                    <IconDropDown />
                </div>
            </div>

            <div className="v2-dropdown__menu scrolls">
                <ListPhones data={receivers} onSetPhoneValue={_setPhoneValue} />
            </div>
        </div>
    );
});

const ListPhones = ({ data = [], onSetPhoneValue = () => {} }) => {
    const _renderListPhones = () => {
        if (!Array.isArray(data) || !data.length) {
            return (
                <li className="items">
                    <div className="name">Not found</div>
                </li>
            );
        }
        return data.map((item) => {
            const { key, phone, is_valid, title, name: phoneName } = item || {};
            return (
                <li key={key} className="items" onClick={() => onSetPhoneValue(item)}>
                    <div className="flexcenter">
                        <div className="name">{phoneName}</div>
                        <p className="phone">{formatPhoneNumberVoip(phone)}</p>
                        <span className="ml-1">
                            <IconPhone isValid={is_valid} />
                        </span>
                    </div>
                    {title ? (
                        <div className="label-content ml-1" title={title}>
                            {title}
                        </div>
                    ) : null}
                </li>
            );
        });
    };

    return <ul>{_renderListPhones()}</ul>;
};

MessageTitleBox.propTypes = {
    receivers: PropTypes.array,
    onClick: PropTypes.func
};

export default MessageTitleBox;
