import React from 'react';

export default function IconSave() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                d="M4.5 6.5C4.5 5.39543 5.39543 4.5 6.5 4.5H14.6716C15.202 4.5 15.7107 4.71071 16.0858 5.08579L18.9142 7.91421C19.2893 8.28929 19.5 8.79799 19.5 9.32843V17.5C19.5 18.6046 18.6046 19.5 17.5 19.5H6.5C5.39543 19.5 4.5 18.6046 4.5 17.5V6.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M7.5 15.5C7.5 14.9477 7.94772 14.5 8.5 14.5H15.5C16.0523 14.5 16.5 14.9477 16.5 15.5V19.5H7.5V15.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            />{' '}
            <path
                d="M7.5 7.5C7.5 8.05228 7.94772 8.5 8.5 8.5H12.5C13.0523 8.5 13.5 8.05228 13.5 7.5L13.5 4.5H7.5L7.5 7.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            />{' '}
            <path d="M10.5 4.5V8.5" stroke="#4D5054" strokeWidth="1.3" />{' '}
        </svg>
    );
}
