import React from 'react';
import PropTypes from 'prop-types';
import { isFileImage, isFileVideo } from 'common/utils/FileUtils';
import { getIconTypeFile } from 'app/const/icon/IconsType';
import { LINK_CDN_IMG } from 'app/const/URL';

function ItemAttachPreview({ type = 'image', url = '', name = '', onClosePreview = () => {} }) {
    const _handleClickClose = (e) => {
        const shouldClose = e.target.getAttribute('data-close-preview');
        if (shouldClose === 'not-close') return;
        onClosePreview();
    };

    const renderPreview = () => {
        switch (type) {
            case 'application/pdf':
                return (
                    <iframe
                        src={url}
                        data-close-preview="not-close"
                        frameBorder="0"
                        width="100%"
                        height="100%"
                        title="preview"
                    />
                );
            default:
                return getIconTypeFile(type, 100, 100);
        }
    };

    return (
        <div draggable="false" className="attachment" onClick={(e) => _handleClickClose(e)}>
            {isFileImage(type) ? (
                <img
                    draggable="false"
                    className="slick-slide-image"
                    data-close-preview="not-close"
                    loading="lazy"
                    src={url}
                    alt={name}
                    onError={(e) => {
                        e.target.src = `${LINK_CDN_IMG}file-broken.png`;
                    }}
                />
            ) : isFileVideo(type) ? (
                <video controls>
                    <source src={url} type={type} />
                </video>
            ) : (
                renderPreview()
            )}
        </div>
    );
}

ItemAttachPreview.propTypes = { type: PropTypes.string, url: PropTypes.string };

export default ItemAttachPreview;
