import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonAddBankACH from './ButtonAddBankACH';
import InfoCardACH from './InfoCardACH';
import IconACH from 'assets/icon/IconACH';
import VerifyBankACH, { ElementWrapper } from './VerifyBankACH';
import EmailACH from './EmailACH';
import { TYPE_REMINDER } from 'app/const/Customers';

const PaymentACH = ({ data = [], onAddSuccess = () => {}, onWarning = () => {}, onVerifySuccess = () => {} }) => {
    const { t } = useTranslation('customers');
    const refVerify = useRef(null);
    const refEmail = useRef(null);

    const _handleConfirmDelete = (item) => {
        onWarning('payment', item);
    };

    const _handleVerify = (item) => {
        refVerify.current._open({ item });
    };

    const _handleSendEmail = ({ isReminder, typeReminder }) => {
        refEmail.current?._open({ isReminder, id: TYPE_REMINDER[typeReminder] });
    };

    const _renderCard = () => {
        return data.map((item) => (
            <InfoCardACH
                key={item.id}
                item={item}
                onDelete={_handleConfirmDelete}
                onVerify={_handleVerify}
                onReminder={_handleSendEmail}
            />
        ));
    };

    return (
        <div className="rows mt-0">
            <div className="method-header w-100 flex-betweenitems">
                <div className="method-info flexcenter">
                    <span className="method-info__icon">
                        <IconACH />
                    </span>
                    <p className="method-info__name flex-column">
                        <b className="black-eerie-dark">{t('ach')}</b>
                    </p>
                </div>
                <EmailACH ref={refEmail} isShowInvitation={!data.length} />
            </div>
            {_renderCard()}

            <ElementWrapper>
                <ButtonAddBankACH onAddSuccess={onAddSuccess} />
                <VerifyBankACH ref={refVerify} onVerifySuccess={onVerifySuccess} />
            </ElementWrapper>
        </div>
    );
};

export default PaymentACH;
