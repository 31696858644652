export default function IconDropDown({ isSortOrder = false }) {
    if (isSortOrder) {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.46967 5.21967C7.76256 4.92678 8.23744 4.92678 8.53033 5.21967L12.0303 8.71967C12.3232 9.01256 12.3232 9.48744 12.0303 9.78033C11.7374 10.0732 11.2626 10.0732 10.9697 9.78033L8 6.81066L5.03033 9.78033C4.73744 10.0732 4.26256 10.0732 3.96967 9.78033C3.67678 9.48744 3.67678 9.01256 3.96967 8.71967L7.46967 5.21967Z"
                    fill="black"
                ></path>
            </svg>
        );
    }

    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.5 6.5L8 3L11.5 6.5"
                stroke="#595F6B"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M4.5 9.5L8 13L11.5 9.5"
                stroke="#595F6B"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
