import React from 'react';

const IconACH = ({ isActive = false, isInactive = false }) => {
    if (isActive)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 10H12H15C15.5523 10 16 10.4477 16 11V15C16 15.5523 15.5523 16 15 16H9C8.44772 16 8 15.5523 8 15V11C8 10.4477 8.44772 10 9 10ZM12 12C13.1046 12 14 12.8954 14 14V16H10V14C10 12.8954 10.8954 12 12 12ZM18 10C18.5523 10 19 10.4477 19 11V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V11C17 10.4477 17.4477 10 18 10ZM6 10C6.55228 10 7 10.4477 7 11V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V11C5 10.4477 5.44772 10 6 10Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.47 4.152C11.7583 3.97185 12.1154 3.95183 12.4189 4.09195L12.53 4.152L19.53 7.152C19.9983 7.44471 20.1407 8.06166 19.848 8.53C19.5762 8.96488 19.0248 9.11871 18.5725 8.9041L18.47 8.848H5.53001C5.09513 9.1198 4.5321 9.01645 4.21953 8.62533L4.15201 8.53C3.88021 8.09511 3.98356 7.53209 4.37468 7.21952L4.47001 7.152L11.47 4.152ZM5.00001 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5.00001C4.44773 19 4.00001 18.5523 4.00001 18C4.00001 17.4477 4.44773 17 5.00001 17Z"
                    fill="#8D4AFC"
                />
            </svg>
        );

    if (isInactive)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 10H12H15C15.5523 10 16 10.4477 16 11V15C16 15.5523 15.5523 16 15 16H9C8.44772 16 8 15.5523 8 15V11C8 10.4477 8.44772 10 9 10ZM12 12C13.1046 12 14 12.8954 14 14V16H10V14C10 12.8954 10.8954 12 12 12ZM18 10C18.5523 10 19 10.4477 19 11V15C19 15.5523 18.5523 16 18 16C17.4477 16 17 15.5523 17 15V11C17 10.4477 17.4477 10 18 10ZM6 10C6.55228 10 7 10.4477 7 11V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V11C5 10.4477 5.44772 10 6 10Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.47 4.152C11.7583 3.97185 12.1154 3.95183 12.4189 4.09195L12.53 4.152L19.53 7.152C19.9983 7.44471 20.1407 8.06166 19.848 8.53C19.5762 8.96488 19.0248 9.11871 18.5725 8.9041L18.47 8.848H5.53001C5.09513 9.1198 4.5321 9.01645 4.21953 8.62533L4.15201 8.53C3.88021 8.09511 3.98356 7.53209 4.37468 7.21952L4.47001 7.152L11.47 4.152ZM5.00001 17H19C19.5523 17 20 17.4477 20 18C20 18.5523 19.5523 19 19 19H5.00001C4.44773 19 4.00001 18.5523 4.00001 18C4.00001 17.4477 4.44773 17 5.00001 17Z"
                    fill="#7A83A6"
                />
            </svg>
        );

    return (
        <svg width={33} height={32} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width={32} height={32} rx={3} fill="#F3F3F3" />
            <path
                opacity="0.35"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5 14H16.5H19.5C20.0523 14 20.5 14.4477 20.5 15V19C20.5 19.5523 20.0523 20 19.5 20H13.5C12.9477 20 12.5 19.5523 12.5 19V15C12.5 14.4477 12.9477 14 13.5 14ZM16.5 16C17.6046 16 18.5 16.8954 18.5 18V20H14.5V18C14.5 16.8954 15.3954 16 16.5 16ZM22.5 14C23.0523 14 23.5 14.4477 23.5 15V19C23.5 19.5523 23.0523 20 22.5 20C21.9477 20 21.5 19.5523 21.5 19V15C21.5 14.4477 21.9477 14 22.5 14ZM10.5 14C11.0523 14 11.5 14.4477 11.5 15V19C11.5 19.5523 11.0523 20 10.5 20C9.94772 20 9.5 19.5523 9.5 19V15C9.5 14.4477 9.94772 14 10.5 14Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9701 8.152C16.2584 7.97185 16.6155 7.95183 16.9191 8.09195L17.0301 8.152L24.0301 11.152C24.4985 11.4447 24.6408 12.0617 24.3481 12.53C24.0763 12.9649 23.525 13.1187 23.0726 12.9041L22.9701 12.848H10.0301C9.59525 13.1198 9.03222 13.0165 8.71965 12.6253L8.65214 12.53C8.38033 12.0951 8.48368 11.5321 8.87481 11.2195L8.97013 11.152L15.9701 8.152ZM9.50013 21H23.5001C24.0524 21 24.5001 21.4477 24.5001 22C24.5001 22.5523 24.0524 23 23.5001 23H9.50013C8.94785 23 8.50013 22.5523 8.50013 22C8.50013 21.4477 8.94785 21 9.50013 21Z"
                fill="black"
            />
        </svg>
    );
};

export default IconACH;
