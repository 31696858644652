import React, { useRef } from 'react';
import GridLoading from 'app/components/grid/components/GridLoading';
import JobDetail from 'app/modules/calendar/components/sidebar/components/joblist/Job';
import { ACTIONS_JOB } from 'app/modules/calendar/const';
import { updateGetJobActive } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import ModalRecurringJob from 'app/modules/calendar/components/modal/RecurringJob';
import { useDispatch } from 'react-redux';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { useTranslation } from 'react-i18next';

const JobListActive = ({
    jobList = [],
    textEmpty = 'No active jobs',
    isLoading = false,
    showMore = false,
    loadMore,
    type,
    activeDragable = false
}) => {
    const { t } = useTranslation(['header']);
    const dispatch = useDispatch();
    const refModalRecurringJob = useRef(null);

    function _handleLoadMore() {
        loadMore(type);
    }

    function _handleDBClick(jobData) {
        const jobId = jobData.jobId || jobData.id;
        if (jobData.recurrence.length !== 0) {
            clientQuery(updateGetJobActive(jobId), { method: 'GET' }, _openModalRecurringJob);
            return false;
        }

        _openJobDetail(jobId);
    }

    function _openModalRecurringJob(finalJobData) {
        refModalRecurringJob.current &&
            refModalRecurringJob.current._showOptions(finalJobData.data, () => _openJobDetail(finalJobData.data.id));
    }

    function _openJobDetail(jobId) {
        dispatch(actionOpenJobDetail({ id: jobId }));
    }

    function _renderLoadingList() {
        if (isLoading) {
            return <GridLoading />;
        }

        return null;
    }

    function _renderShowMore() {
        if (!showMore || isLoading || !jobList.length) {
            return null;
        }

        return (
            <div className="btn-loadmore has-loading" onClick={_handleLoadMore}>
                <p className="btn-loadmore__label">{t('header:load_more')}</p>
            </div>
        );
    }

    function _renderJobList() {
        if (!jobList.length && !isLoading) {
            return (
                <div className="btn-loadmore">
                    <p className="btn-loadmore__label">{textEmpty}</p>
                </div>
            );
        }
        return jobList.map((item) => {
            return (
                <JobDetail
                    key={item.id}
                    dataJob={{ ...item, typeMove: ACTIONS_JOB.MOVE_JL }}
                    activeDragable={activeDragable}
                    onDBClick={_handleDBClick}
                />
            );
        });
    }

    return (
        <>
            {_renderJobList()}
            {_renderLoadingList()}
            {_renderShowMore()}
            <ModalRecurringJob ref={refModalRecurringJob} />
        </>
    );
};

export default JobListActive;
