export const TYPE_LOCATION = {
	GET_LIST_LOCATION: "GET_LIST_LOCATION",
	CREATE_LOCATION: "CREATE_LOCATION",
	UPDATE_LOCATION: "UPDATE_LOCATION",

	GET_DETAIL_LOCATION: "GET_DETAIL_LOCATION",
	GET_LIST_EMAIL_MOBILE_ADDRESS: "GET_LIST_EMAIL_MOBILE_ADDRESS",
	GET_LIST_TAX_RATE: "GET_LIST_TAX_RATE",

	SEARCH_TAGS: "SEARCH_TAGS"
};

export const getListLocation = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.GET_LIST_LOCATION,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const createLocation = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.CREATE_LOCATION,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const updateLocation = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.U,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const getDetailLocation = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.GET_DETAIL_LOCATION,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const getListEmailMobileAddress = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.GET_LIST_EMAIL_MOBILE_ADDRESS,
		params,
		actionSuccess,
		actionFailed,
	};
};


export const getListTaxRate = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.GET_LIST_TAX_RATE,
		params,
		actionSuccess,
		actionFailed,
	};
};

export const searchTags = (
	params,
	actionSuccess,
	actionFailed = () => { }
) => {
	return {
		type: TYPE_LOCATION.SEARCH_TAGS,
		params,
		actionSuccess,
		actionFailed,
	};
};

