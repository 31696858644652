import IconSplash from 'assets/icon/IconSplash';
import React from 'react';

export default function GridLoading() {
    return (
        <div className="py-8 w-100">
            <div className="splash">
                <div className="wrapper">
                    <div className="wrapper__loader">
                        <IconSplash />
                    </div>
                </div>
            </div>
        </div>
    );
}
