import "regenerator-runtime/runtime";
import { call, put, takeLatest, all } from "redux-saga/effects";
import { getFetch } from "common/utils/ApiUtils";
import { FETCH_APP_DATA } from "common/redux/actions/appAction";
import { USER_RETRIEVE } from "app/const/Api";
import { AUTH_TYPE } from "common/redux/actions/authAction";
import store from 'common/redux/store/configureStore';

export function* fetAllData() {
  const [users] = yield all([call(getFetch, USER_RETRIEVE, {}, store.dispatch)]);
  if (users && users.success) {
    yield all([put({ type: AUTH_TYPE.USER_RETRIEVE_SUCCESS, payload: users })]);
  }
}

export function* appWatcher() {
  yield takeLatest(FETCH_APP_DATA, fetAllData);
}
