import loadable from '@loadable/component';
import GdGridView from 'app/components/grid/GdGridView';
import { REPORT_ACTIVITY_COMPARISON } from 'app/const/Api';
import { DEFAULT_ALL, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { URL_EXPORT_ACTIVITY_COMPARISON } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { ACTIVITY_COMPARISON_LIST_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { Fragment, useEffect, useMemo, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FOOTER_COMPARISON, getGridColumns } from './ColumnComparison';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { RowFixed } from './RowFixed';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import ServiceSettingVoip from 'app/services/voip/ServiceSettingVoip';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { INBOX } from 'app/config/routes';
import { useLocation } from 'react-router-dom';
import { addBranchPath } from 'app/const/Branch';
import PopupReportCallActivity from './PopupReportCallActivity';

const FilterReport = loadable(() => import('app/modules/report/components/FilterReport'));
const Export = loadable(() => import('app/modules/report/components/Export'));

export default function ActivityComparison() {
    const { t } = useTranslation();
    const history = useHistory();
    const { state: stateLocation } = useLocation();

    const abortController = useRef(null);
    const isFirstTime = useRef(true);
    const refAlert = useRef(null);
    const refPopupReport = useRef(null);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refresh: false,
        data: [],
        isLoading: true,
        total: {},
        refreshScreen: 0,
        outStanding: 0,
        reloadFilter: 0,
        actionSuccess: 0,
        isFetched: false,
        isLoadingPage: stateLocation?.isLoadingSideBar ?? true
    });

    const {
        refreshScreen,
        data: finalData,
        total: finalTotal = {},
        isFetched: finalIsFetched,
        isLoading: finalIsLoading,
        isLoadingPage: finalIsLoadingPage
    } = dataReport;

    const lengthData = finalData?.length ?? 0;

    const reportKeyLocal = useMemo(() => {
        return KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.ACTIVITY_COMPARISON);
    }, []);

    const paramsReport = getLocalStorage(reportKeyLocal);
    const finalParamsReport = getGridColumns(paramsReport?.columns, paramsReport?.order);

    !paramsReport && setLocalStorage(reportKeyLocal, getDefaultParams(REPORT_TYPE.ACTIVITY_COMPARISON));

    useEffect(() => {
        if (!finalIsLoadingPage && isFirstTime.current) {
            getListReport(true, getLocalStorage(reportKeyLocal));
            isFirstTime.current = false;
        }

        return () => {
            !isFirstTime.current && window.removeEventListener('resize', scrollVirtual);
        };
    }, [finalIsLoadingPage]);

    useEffect(() => {
        if (finalIsFetched) {
            scrollVirtual();
            window.addEventListener('resize', scrollVirtual);
        }
    }, [finalIsFetched]);

    useEffect(() => {
        if (finalIsFetched && !!lengthData && !!finalParamsReport?.columns?.length) scrollVirtual(true);
    }, [finalData, finalParamsReport?.columns?.length]);

    const _handleTrigger = (value) => {
        if (!!value) {
            dispatchActionReport({ isLoadingPage: false });
            return;
        }

        history.replace(addBranchPath(INBOX));
    };

    const _renderHeader = () => {
        return (
            <div className="nav-top header flex-betweenitems">
                <div className="title-header p-0">
                    <h1 className="title-header__label">{t('report:activity_comparison')}</h1>
                </div>
                <Export
                    title={t(`report:record${lengthData !== 1 ? 's' : ''}`, { count: lengthData })}
                    activePrint
                    url={URL_EXPORT_ACTIVITY_COMPARISON}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_ACTIVITY_COMPARISON}
                    refresh={refreshScreen}
                    isDisable={finalIsLoading}
                />
            </div>
        );
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReport(true, getLocalStorage(reportKeyLocal));
    };

    const _handleUpdateColumn = (value, mode) => {
        if (mode === 'columns') return dispatchActionReport({ refresh: refreshScreen });
    };

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        abortController.current = new AbortController();

        params = getNewAPIRequest(params);
        const checkRefresh = dataReport.reloadFilter;
        const checkShouldLoading = !notShowLoading && !finalIsLoading;

        if (checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading
                };
            });
        }
        params.users = params?.staffs?.toString() || '';

        delete params?.columns;
        delete params?.filterTab;
        delete params?.currentPage;

        const getListFailed = (err) => {
            if (err?.isAborted) return;

            refAlert.current.showStatusBar({
                id: `${Date.now()}_error`,
                message: err.message,
                type: LIST_STATUS.ERROR
            });
            dispatchActionReport({ isLoading: false });
        };

        const getListSuccess = ({ data, total }) => {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoading: false,
                    data: data.map((item) => ({ ...item, id: item.user.id })),
                    total,
                    refreshScreen: refreshScreen + 1,
                    isFetched: true
                };
            });
        };

        clientQuery(
            REPORT_ACTIVITY_COMPARISON,
            { data: params, method: 'GET', abortController: abortController.current },
            (res) => getListSuccess({ ...res, isReset }, checkRefresh),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        const paramUsers = params?.users || DEFAULT_ALL;
        if (Array.isArray(paramUsers)) {
            params.users = paramUsers.toString();
        }
        return params;
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        if (finalIsLoading) return;

        handleActionHeaderReport({
            actionType: actionType,
            reportType: REPORT_TYPE.ACTIVITY_COMPARISON,
            columnsTarget,
            currentValue,
            paramsReport: getLocalStorage(reportKeyLocal),
            callBack: _handleUpdate
        });
    };

    const _renderColUser = () => {
        return finalData.map((item) => {
            const { id: user_id, full_name, avatar } = item.user;
            return (
                <div key={user_id} className="rows">
                    <div className="col col-lg col-fixed">
                        <div className="user-name">
                            <span className="avt-img">
                                <img src={avatar} alt={full_name} width={24} height={24} />
                            </span>
                            <span className="txt-ellipsis">{full_name}</span>
                        </div>
                    </div>
                </div>
            );
        });
    };

    const _renderTable = () => {
        return (
            <GdGridView
                isEmptyFlat
                isShowHeader={false}
                isLoading={finalIsLoading}
                classTable="table-fixed"
                classTableContent=""
                content={finalData}
                fileTranslation={'report'}
                {...finalParamsReport}
                tableId={'container_div_id'}
                isScroll
                isNotShowMsgColEmpty
                handleClick={_handleOpenPopup}
            />
        );
    };

    const scrollVirtual = (isRefresh = false) => {
        const parent = document.querySelector('.activity-comparison .wrap-tables-fixed');
        const div = document.getElementById('container_div_id');
        if (!parent || !div) {
            return false;
        }
        const widthHeader = parent.querySelector('.tables-header .tables');
        const widthTotal = parent.querySelector('.tables-total .tables');
        const tableContent = parent.querySelector('.tables-content .tables');
        const listUser = parent.querySelector('.tables-content .list-user');

        if (!tableContent || !listUser) return;

        const hasHorizontalScrollbar = div.scrollWidth > div.clientWidth;
        const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;

        if (hasHorizontalScrollbar) {
            listUser?.classList?.add('list-user-scroll');
        } else {
            listUser?.classList?.remove('list-user-scroll');
        }

        if (hasVerticalScrollbar) {
            const paddingValue = div.offsetWidth - div.clientWidth;
            widthHeader.style.paddingRight = widthTotal.style.paddingRight = paddingValue - 1 + 'px';

            if (typeof isRefresh === 'boolean' && !!isRefresh) {
                listUser.scrollTop = 0;
                tableContent.scrollTop = 0;
            }
        } else {
            widthHeader.style.paddingRight = 0;
        }

        listUser.onscroll = function (e) {
            tableContent.scrollTop = e.target.scrollTop;
        };

        tableContent.onscroll = function (e) {
            listUser.scrollTop = e.target.scrollTop;
            widthHeader.scrollLeft = e.target.scrollLeft;
            widthTotal.scrollLeft = e.target.scrollLeft;
        };
    };

    const _renderContent = () => {
        if (finalIsLoadingPage)
            return (
                <div className="wrap-loading nav-top header flex-betweenitems">
                    <div className="title-header p-0 loading --onefifth">
                        <div className="loading --animation --line --threefourth --light" />
                    </div>
                    <div className="export flexcenter gap-4">
                        <div className="loading --animation --line --onefourth btn-sm --light" />
                        <div className="flexcenter">
                            <div className="export__option --left field-h24">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="export__option --right field-h24">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="v2-btn-default has-icon btn-x-sm field-h24 loading --grey">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            );

        return (
            <Fragment>
                {_renderHeader()}
                <div className="activity-comparison wrapper-columns">
                    <div className="container-print contents-pages">
                        <div className="wrap-tables flex-column">
                            <div className="header --filter">
                                <ReportDateRangePicker
                                    reportType={REPORT_TYPE.ACTIVITY_COMPARISON}
                                    onSelect={_handleUpdate}
                                    wrapperClassName="header__left flex-1"
                                />
                                <RenderFilter onSelect={_handleUpdateColumn} />
                                <div className="header-items v2-btn-main" onClick={_handleUpdate}>
                                    {t('common:update')}
                                </div>
                            </div>
                            <div className="wrap-tables-fixed">
                                <AlertCustomer ref={refAlert} />

                                <RowFixed
                                    classNameRow="tables-header"
                                    {...finalParamsReport}
                                    onClickHeader={_handleActionHeader}
                                />
                                <div className="tables-content">
                                    {finalIsLoading || !lengthData ? (
                                        <div className="rows"></div>
                                    ) : (
                                        <div className="list-user">{_renderColUser()}</div>
                                    )}
                                    {_renderTable()}
                                </div>
                                {!!lengthData && (
                                    <RowFixed
                                        classNameRow="tables-total"
                                        data={finalTotal}
                                        {...finalParamsReport}
                                        header={FOOTER_COMPARISON}
                                        isFooter
                                    />
                                )}
                            </div>
                            <PopupReportCallActivity ref={refPopupReport} paramsReport={paramsReport} />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };

    const _handleOpenPopup = (params) => {
        refPopupReport.current.open({ ...params, start: paramsReport.start, end: paramsReport.end });
    };

    return (
        <Fragment>
            {_renderContent()}
            <ServiceSettingVoip keyFeature={'call_activity_report'} onTrigger={_handleTrigger} />
        </Fragment>
    );
}

const RenderFilter = ({ onSelect }) => {
    const companyUsers = useSelector((state) => state.companyUsers.users || []);

    return (
        <FilterReport
            reportType={REPORT_TYPE.ACTIVITY_COMPARISON}
            filters={ACTIVITY_COMPARISON_LIST_FILTER}
            onSelect={onSelect}
            companyUsers={companyUsers}
            isShowAvatar
        />
    );
};
