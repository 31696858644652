import { call, put, takeLatest } from 'redux-saga/effects';

import { REPORT_SERVICE_LOOKUP_SCHEDULE_LIST } from 'app/const/Api';
import { SCHEDULES_LIST_ACTIONS, actionGetListScheduleSuccess } from 'common/redux/actions/schedulesList';
import { clientQuery } from 'common/utils/ApiUtils';

function* getSchedulesList() {
    try {
        const result = yield call(clientQuery, REPORT_SERVICE_LOOKUP_SCHEDULE_LIST, { data: {}, method: 'GET' });
        yield put(actionGetListScheduleSuccess(result.data));
    } catch (error) {
        console.error(error);
    }
}

export function* schedulesListWatcher() {
    yield takeLatest(SCHEDULES_LIST_ACTIONS.GET_LIST, getSchedulesList);
}
