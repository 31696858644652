import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Service = ({ onOpenCreateJob, onCloseCreateJob }) => {
    const { jobData } = useSelector(({ createJobReducer }) => createJobReducer);

    useEffect(() => {
        if (jobData) {
            onOpenCreateJob(jobData);
        } else {
            onCloseCreateJob();
        }
    }, [jobData]);

    return false;
};

export default Service;
