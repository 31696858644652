import { call } from 'redux-saga/effects';
import { CUSTOMER_CREATE_LOCATION, CUSTOMER_UPDATE_LOCATION, getUrlCustomerLocation } from 'app/const/Api';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { DEFAULT_ALL } from 'app/const/App';

export function* getListLocation({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, getUrlCustomerLocation(params.customer_id), params);

        if (response && response.success) {
            if (params.isMergedId) {
                const newData = response.data.reduce((prev, current) => {
                    const index = prev.findIndex((item) => item.address === current.address);
                    if (index === DEFAULT_ALL) prev.push(current);
                    else {
                        const itemExisted = prev[index];
                        prev.splice(index, 1, { ...itemExisted, id: `${itemExisted.id}, ${current.id}` });
                    }
                    return prev;
                }, []);
                actionSuccess({ ...response, data: newData });
            } else {
                actionSuccess(response);
            }
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createLocation({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, CUSTOMER_CREATE_LOCATION, params);
        if (response && response.success) {
            actionSuccess();
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateLocation({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, CUSTOMER_UPDATE_LOCATION, params);
        if (response && response.success) {
            actionSuccess();
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
