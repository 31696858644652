import React from 'react';
import { findAll } from './utils';

const TextHighlight = ({ content = '', searchWords = '', tagName = 'p', ...props }) => {
    // If don't using the findAll function, will render multiple <span> tags
    const chunks = findAll({ searchWords, textToHighlight: content });
    return React.createElement(tagName, { ...props }, <Children chunks={chunks} textToHighlight={content} />);
};

const Children = ({ chunks, textToHighlight = '' }) => {
    return chunks.map((chunk, index) => {
        const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);
        if (chunk.highlight) {
            const props = { children: text, className: 'txt-highlight', key: index };
            return React.createElement('mark', props);
        } else {
            return React.createElement('span', null, text);
        }
    });
};

export default TextHighlight;
