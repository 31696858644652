import SideMenu from 'app/modules/settings/templatesManager/components/SideMenu';
import MainHeaderSettings from 'app/modules/settings/components/MainHeaderSettings';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { SETTINGS_TEMPLATES_MANAGER, SETTINGS_TEMPLATES_NOTES, SETTINGS_TEMPLATES_TODO } from 'app/config/routes';
import { useSelector } from 'react-redux';
import { getPermissionTemplateManager, isNotPermissionValue } from 'common/utils/PermissionUtils';
import ErrorPage from 'app/modules/error';
import { useTranslation } from 'react-i18next';
import { NOTES_TEMPLATE_OPTIONS } from 'app/const/Notes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';

const SettingsTemplatesManager = ({ routes = [] }) => {
    const { t } = useTranslation();
    const permissions = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const templatePermissions = getPermissionTemplateManager(permissions);
    const location = useLocation();
    if (
        isNotPermissionValue(
            templatePermissions,
            NOTES_TEMPLATE_OPTIONS.find((item) =>
                matchPath(location.pathname, {
                    path: addPrefixPath(item.link),
                    exact: true
                })
            )?.name
        )
    )
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <div className="contents-pages container-column wrapper-template">
                    <div className="wrapper-template__main">
                        <SideMenu />
                        <Switch>
                            <Route path={addPrefixPath(SETTINGS_TEMPLATES_MANAGER)} exact>
                                <Redirect
                                    to={addBranchPath(
                                        isNotPermissionValue(templatePermissions, 'all_templates')
                                            ? SETTINGS_TEMPLATES_TODO
                                            : SETTINGS_TEMPLATES_NOTES.replace(':id', -1)
                                    )}
                                />
                            </Route>
                            {routes.map((route, i) => (
                                <Route key={i} path={route.path} component={route.component} />
                            ))}
                        </Switch>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SettingsTemplatesManager;
