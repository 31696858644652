import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ServiceEvent = ({ onRefresh = () => {} }) => {
    const { newEventData } = useSelector(({ eventReducer }) => eventReducer);

    useEffect(() => {
        if (newEventData) onRefresh();
    }, [newEventData]);

    return null;
};

export default ServiceEvent;
