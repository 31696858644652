import React, { useRef } from 'react';
import NotifySelected from 'app/modules/customer/note/detail/NotifySelected';
import Options from 'app/modules/customer/detail/comment/Options';
import EditComment from 'app/modules/customer/detail/comment/edit';
import Header from 'app/modules/customer/detail/comment/Header';
import RenderAttachment from 'app/modules/customer/components/RenderAttachment';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionComment } from 'app/const/Api';

const CommentDetail = ({
    commentDetail,
    noteId,
    onUpdate,
    onnRemove,
    type,
    jobId,
    unitId,
    isHaveDeleteComment = true,
    onHandleDeleteAttachment = () => {}
}) => {
    const refEditComment = useRef(null);
    const commentId = commentDetail.id;

    function _openEditComment() {
        const elm = document.getElementById(`form_time_line_note_detail_${noteId}`);
        const elmComment = document.getElementById(`comment_item_${commentId}`);
        if (elm && elmComment) {
            !elm.classList.contains('has-edit-comments') && elm.classList.add('has-edit-comments');
            refEditComment.current.toggleShowHide();
            elmComment.classList.add('is-editing-cmt');
        }
    }

    function _handleUpdateComment(newComment) {
        onUpdate(newComment);
    }

    function _handleRemoveComment() {
        clientQuery(actionComment(commentId), { method: 'DELETE' });

        onnRemove(commentId);
    }

    return (
        <div
            id={`comment_item_${commentId}`}
            className={`form-comments__conts ${commentDetail.quick_add ? 'is-disable' : ''}`}
        >
            <div className="header-box">
                <Header commentDetail={commentDetail} />
                {isHaveDeleteComment && <Options openFormEdit={_openEditComment} onRemove={_handleRemoveComment} />}
            </div>
            <div className="wrap-content is-complete">
                <div className="wrap-content-box">
                    <div className="description" dangerouslySetInnerHTML={{ __html: commentDetail.content }} />
                    <div className="attachment-image scrolls-x">
                        <RenderAttachment
                            attachments={commentDetail.attachments}
                            commentId={commentId}
                            onDeleteAttachment={onHandleDeleteAttachment}
                        />
                    </div>
                </div>
                <NotifySelected selected={commentDetail.notified_users} />
            </div>
            <EditComment
                commentDetail={commentDetail}
                noteId={noteId}
                ref={refEditComment}
                onUpdate={_handleUpdateComment}
                type={type}
                jobId={jobId}
                unitId={unitId}
            />
        </div>
    );
};

export default CommentDetail;
