import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function TaskItemLogs({ doneBy, time, isVisible = false }) {
    const { t } = useTranslation();

    if (!isVisible) return null;
    return (
        <div className="box-task__logs">
            {t('done_by')}
            <div className="tag-label flex-1">{doneBy}</div>
            {!!time.trim().length && (
                <>
                    {t('on')}
                    <div className="tag-label">{time}</div>{' '}
                </>
            )}
        </div>
    );
}

TaskItemLogs.propTypes = {
    doneBy: PropTypes.string,
    time: PropTypes.string,
    isVisible: PropTypes.bool
};

export default TaskItemLogs;
