/* eslint-disable no-unused-vars */
import { ACCOUNT_ROLE } from 'app/const/App';
import 'app/modules/settings/css/settings.scss';
import SideMenu from 'app/modules/settings/layouts/SideMenu';
import ErrorPage from 'app/modules/error';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, withRouter } from 'react-router-dom';
import { SETTINGS_ACCOUNT, SETTINGS_PLANS, SETTINGS_COMPANY } from 'app/const/Route';
import { WithTitle } from 'app/components/routes/RouteWithSubRoutes';
import { SETTINGS_PAPERWORK, SETTINGS_TEMPLATES_MANAGER, SETTINGS_TILES, SETTINGS_USERS } from 'app/config/routes';
import { SETTING_MENU_OPTIONS } from 'app/const/StaticLinks';
import { getPermissionSetings, isNotPermissionValue } from 'common/utils/PermissionUtils';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import { addPrefixPath } from 'app/const/Branch';

function SettingLayout({ routes = [], permissionKey, ...props }) {
    const { t } = useTranslation(['auth']);
    const { permissions, profile } = useSelector(({ auth }) => auth.user);
    const location = useLocation() || {};
    const locationPath = location.pathname;

    const permissionsFinal = getPermissionSetings(permissions?.enabled);
    const currentMenu =
        SETTING_MENU_OPTIONS.find((item) =>
            matchPath(locationPath, {
                path: addPrefixPath(item.link),
                exact: true
            })
        ) || {};
    // Add class for page when mount ->> remove when unmount
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('settings-page');
        return () => {
            mainPageDiv.classList.remove('settings-page');
        };
    }, []);

    if (isNotPermissionValue(permissionsFinal, currentMenu.value))
        return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div
            className={classNames('container-wrap container-setting custom-grid-sidebar', {
                'account-expired': profile?.role !== ACCOUNT_ROLE.SUPERADMIN,
                'container-table': ![
                    SETTINGS_ACCOUNT,
                    SETTINGS_PLANS,
                    SETTINGS_COMPANY,
                    SETTINGS_USERS,
                    SETTINGS_PAPERWORK,
                    SETTINGS_TILES,
                    SETTINGS_TEMPLATES_MANAGER
                ].includes(locationPath),
                'is-hide-panel': !getToggleLocal('toggledSettings')
            })}
        >
            <SideMenu permissions={permissionsFinal} />

            <Switch>
                {routes.map((route, i) => {
                    const result = { ...route };
                    if (route.path.split(',').length > 1) {
                        result.path = route.path.split(',');
                    }

                    if (result.routes?.length) {
                        const childPath = [result.path[0]];
                        const newRoutes =
                            result?.routes?.map((item) => {
                                const path = addPrefixPath(item.path);
                                childPath.push(path);
                                return { ...item, path };
                            }) || [];

                        return (
                            <Route
                                exact
                                key={i}
                                path={childPath}
                                render={(props) => {
                                    return (
                                        <WithTitle title={result.title}>
                                            <route.component routes={newRoutes} {...props} />
                                        </WithTitle>
                                    );
                                }}
                            />
                        );
                    }

                    return (
                        // <WithTitle
                        //     title={route.title}
                        //     permissionKey={route.permissionKey}
                        //     excludePermissionRoute={route.excludePermissionRoute}
                        //     key={i}
                        // >
                        <Route
                            key={i}
                            path={result.path}
                            exact
                            render={(props) => (
                                <WithTitle title={result.title}>
                                    <route.component {...props} />
                                </WithTitle>
                            )}
                        />

                        // </WithTitle>
                    );
                    // return <RouteCustom key={i} route={{ ...result, permissionKey: permissionKey }} {...props} />;
                })}
            </Switch>
        </div>
    );
}

export default withRouter(SettingLayout);
