import React, { Fragment } from 'react';
import Checkbox from 'app/components/checkbox';
import { SOURCE_PHONE_NUMBER_VOIP, TYPE_PHONE_VOIP } from 'app/const/addons';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconPlus from 'assets/icon/IconPlus';
import IconTelephone from 'assets/icon/IconTelephone';
import classNames from 'classnames';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconTypes from 'assets/icon/IconTypes';

export const EnabledNumbersAttendant = ({ number_ids, onChange = () => {}, onDelete = () => {} }) => {
    const { t } = useTranslation('addons');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    // eslint-disable-next-line no-undef
    const listNumber = global.listNumberVoip;

    const _renderListEnable = () => {
        return number_ids.map((item) => {
            const { id, assignees, phone_number, type } = listNumber.find((number) => number.id === item) || {};
            if (!id) return null;
            const { full_name, avatar } = companyUsers.find((user) => parseInt(user.id) === assignees[0]) || {};
            const isGroup = type === TYPE_PHONE_VOIP.GROUP;
            const nameNumber = isGroup ? t('group_number') : full_name;

            const _handleDelete = () => {
                onDelete(id);
            };

            const _renderAvatar = () => {
                if (isGroup) return <IconTypes isGroup />;
                return <img className="avt-img mr-0" src={avatar} width={24} height={24} />;
            };

            return (
                <div key={item} className="flexcenter gap-8 fw-500">
                    <span className="txt-ellipsis nowrap">{formatPhoneNumberVoip(phone_number)}</span>
                    {(!!assignees[0] || isGroup) && (
                        <span className="schedule-user">
                            <span className="tech-name m-0">
                                {_renderAvatar()}
                                <span className="txt-ellipsis nowrap black">{nameNumber}</span>
                            </span>
                        </span>
                    )}
                    <div className="svg-delete-grey cursor-pointer" onClick={_handleDelete}>
                        <IconCircleClose />
                    </div>
                </div>
            );
        });
    };

    const _renderListOption = () => {
        return listNumber.map((item) => {
            const { id, assignees, phone_number, type, source } = item;
            const isGroup = type === TYPE_PHONE_VOIP.GROUP;
            if ((!assignees[0] && !isGroup) || source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) return false;

            const { full_name, avatar } = companyUsers.find((user) => parseInt(user.id) === assignees[0]) || {};
            const nameNumber = isGroup ? t('group_number') : full_name;
            const isActive = number_ids.includes(id);

            const _handleChange = (e) => {
                onChange({ id, value: e.target.checked });
            };

            const _renderAvatar = () => {
                if (isGroup) return <IconTypes isGroup />;
                return <img className="avt-img mr-0" src={avatar} width={24} height={24} />;
            };

            return (
                <div key={id} className="items-container">
                    <div className={classNames('items', { active: isActive })}>
                        <Checkbox id={id} checked={isActive} onChangeValue={_handleChange}>
                            <div className="flex-betweenitems gap-8 flex-1 fw-500">
                                <span className="schedule-user">
                                    <span className="tech-name m-0">
                                        {_renderAvatar()}
                                        <span className="txt-ellipsis nowrap black">{nameNumber}</span>
                                    </span>
                                </span>
                                <span className="txt-ellipsis nowrap flex-1 text-right">
                                    {formatPhoneNumberVoip(phone_number)}
                                </span>
                            </div>
                        </Checkbox>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="box-st-hour border-bottom-grey-verylight-brown flex-column gap-8 enable-number">
            <h3 className="flexcenter gap-8 black fs-14 svg-green">
                <IconTelephone isNormal />
                {t('enabled_numbers')}
            </h3>

            <p>{t('select_which_numbers_will_forward_to_the_auto_attendant')}</p>

            {_renderListEnable()}

            <CalendarDropdown
                id="list_number_enable"
                buttonClassName="dropbtn btn-add v2-btn-default has-icon --sm"
                customDropButton={() => (
                    <Fragment>
                        <IconPlus />
                        {t('number')}
                    </Fragment>
                )}
            >
                {_renderListOption()}
            </CalendarDropdown>
        </div>
    );
};
