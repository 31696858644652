import React, { useEffect, useReducer, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import { reducer } from 'app/const/Reducer';
import { actionOrdersPipelines } from 'app/const/api/V2';
import { TYPE_SETTING_PIPELINES } from 'common/redux/actions/settings/pipelinesAction';
import { clientQuery } from 'common/utils/ApiUtils';
import ItemPipeline from './ItemPipeline';

const GroupPipeline = ({ data = [], idGroup = '', onShowAlert = () => {}, onWarningDelete = () => {} }) => {
    const dispatch = useDispatch();
    const refFirstTime = useRef(true);
    const refListItem = useRef([]);
    const refIdEditing = useRef('');

    const [state, dispatchState] = useReducer(reducer, { pipelines: data });
    const { pipelines } = state;

    useEffect(() => {
        if (refFirstTime.current) {
            refFirstTime.current = false;
            return;
        }
        dispatchState({ pipelines: data });
    }, [data]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const _handleDragEnd = (result) => {
        if (!result.destination) return false;
        const _orderSuccess = () => {
            dispatch({
                type: TYPE_SETTING_PIPELINES.ORDER_SUCCESS,
                payload: { idGroup, sourceIndex: result.source.index, destinationIndex: result.destination.index }
            });
        };

        const _oderFail = ({ message }) => {
            onShowAlert({ message });
        };

        dispatchState((prev) => {
            const newPipelines = reorder(prev.pipelines, result.source.index, result.destination.index);
            clientQuery(
                actionOrdersPipelines(idGroup, newPipelines[result.destination.index]?.id || ''),
                { data: { after_id: newPipelines[result.destination.index - 1]?.id || '' }, method: 'PUT' },
                _orderSuccess,
                _oderFail
            );
            return { ...prev, pipelines: newPipelines };
        });
    };

    const _handleItemOpenEdit = (id) => {
        pipelines.forEach((item, index) => {
            if (item.id === refIdEditing.current) {
                refListItem.current[index]?.visibleEdit(false);
            }
        });
        refIdEditing.current = id;
    };

    if (!pipelines.length) return <GridEmpty isFlat />;
    return (
        <DragDropContext onDragEnd={_handleDragEnd}>
            <Droppable droppableId="pipelines-droppable">
                {(provided) => (
                    <div ref={provided.innerRef} className="boxs__contents" {...provided.droppableProps}>
                        {pipelines.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                    <ItemPipeline
                                        ref={(ref) => (refListItem.current[index] = ref)}
                                        key={item.id}
                                        index={index + 1}
                                        idGroup={idGroup}
                                        pipeline={item}
                                        refProvide={provided.innerRef}
                                        draggableProps={provided.draggableProps}
                                        draggableHandleProps={provided.dragHandleProps}
                                        handleShowAlert={onShowAlert}
                                        onWarningDelete={onWarningDelete}
                                        onOpenEdit={_handleItemOpenEdit}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default GroupPipeline;
