import React from 'react';

const EmailLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --onefourth" />
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="email-description loading-lines loading">
                            <div className="loading --animation --line --ninety mt-1" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="title mt-4">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="description loading-lines mt-4">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="btn-action mt-4">
                            <div className="v2-btn-default --grey loading btn-lg">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="wrap-content">
                    <div className="txt loading --full mb-2">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                    <div className="wrapbox-editor">
                        <div className="wrapbox-editor__form content-editable">
                            <div className="loading --animation --line --full mt-1" />
                            <div className="loading --animation --line --full mt-2" />
                            <div className="loading --animation --line --half mt-2" />
                        </div>
                        <div className="wrapbox-editor__controls justify-start">
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-2" />
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-2" />
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-2" />
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-2" />
                            <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured ml-2" />
                        </div>
                    </div>
                    <div className="text-right mt-3">
                        <div className="v2-btn-default --grey loading btn-x-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmailLoading;
