import { convertCrewUser } from 'app/modules/settings/utils/userCompany';
import { TYPE_ACTION_COMPANY_USERS } from '../actions/companyUsersAction';

const setCompanyUserObject = (users) => {
    // eslint-disable-next-line no-undef
    global.companyUserObject = users.reduce((accumulator, currentItem) => {
        accumulator[currentItem.id] = currentItem;
        return accumulator;
    }, {});
};

export default function companyUsersReducer(
    state = {
        getCompanyUsersSuccess: false,
        users: [],
        crew: {
            group: {},
            users: {},
            withOutGroup: [],
            refresh: 0
        }
    },
    action
) {
    const payload = action.payload || {};
    switch (action.type) {
        case TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_SUCCESS:
            const { data: payloadUsers = [], crew: payloadCrew = {} } = payload;
            setCompanyUserObject(payloadUsers);
            return {
                ...state,
                users: payload.data || [],
                getCompanyUsersSuccess: true,
                crew: convertCrewUser(payloadCrew)
            };
        case TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_FAILURE:
            return {
                ...state,
                getCompanyUsersSuccess: false
            };
        case TYPE_ACTION_COMPANY_USERS.UPDATE_USER_COMPANY:
            const newUsersUpdate = state.users.map((user) => {
                if (user.id === payload.id) return payload;
                return user;
            });
            setCompanyUserObject(newUsersUpdate);
            return {
                ...state,
                users: newUsersUpdate
            };

        case TYPE_ACTION_COMPANY_USERS.DELETE_USER_COMPANY:
            const newUsersDeleted = state.users.filter((user) => user.id !== payload.id);
            setCompanyUserObject(newUsersDeleted);
            return { ...state, users: newUsersDeleted };

        case TYPE_ACTION_COMPANY_USERS.ADD_USER_COMPANY:
            const newUsersAdded = [...state.users, payload];
            setCompanyUserObject(newUsersAdded);
            return { ...state, users: newUsersAdded };

        case TYPE_ACTION_COMPANY_USERS.UPDATE_CREW_USER:
            return { ...state, crew: payload.data };

        default:
            return state;
    }
}
