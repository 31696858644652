import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useBlocker = (blocker = () => {}, when = true) => {
    const history = useHistory();
    useEffect(() => {
        if (!when) return;

        const unblock = history.block((transition) => {
            blocker(transition);
            return false;
        });

        return () => unblock();
    }, [blocker, when]);
};

export default useBlocker;
