import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT, KEY_REPORT_LOCAL_STORAGE, START_DATE_ALL_TIME, YESTERDAY } from 'app/const/App';
import {
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE,
    SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import ListLevel from './components/ListLevel';
import LinkKnowledgeBase from './components/LinkKnowledgeBase';
import { REPORT_SERVICES_LOOKUP } from 'app/config/routes';
import { getListJobStatus } from 'common/utils/JobStatusUtils';

const Questlog = ({ info, isLoading, onClose = () => {} }) => {
    const { t } = useTranslation(['header']);
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SERVICE_LOOKUP);

    const { incomplete_job, invoice_pending, efficiency, level: allLevels, first_job_date } = info?.data || {};

    const _handleClickLinkServiceLookup = (inCompleteJob = false) => {
        let startDate = moment(first_job_date || START_DATE_ALL_TIME._d).utc();
        const endDate = moment(YESTERDAY).utc();

        if (startDate.isAfter(endDate)) startDate = endDate.clone();

        setLocalStorage(keyLocal, {
            ...getLocalParamsReport(keyLocal, REPORT_TYPE.SERVICE_LOOKUP),
            filterTab: '{"archived":0,"deleted":0}',
            deleted: 0,
            start: startDate.format(DATE_FORMAT),
            end: endDate.format(DATE_FORMAT),
            job_status: getListJobStatus({
                filter: inCompleteJob
                    ? SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_INCOMPLETE
                    : SERVICE_LOOKUP_QUEST_LOG_JOB_STATUS_COMPLETE,
                isInclude: true
            }).map((status) => status.id)
        });
        onClose();
    };

    const renderQuestLogs = () => {
        return (
            <>
                <div className="title p-2">
                    <div className="title-font15 green-default px-1">
                        {efficiency}% {t('header:efficiency')}
                    </div>
                    <span className="dp-block orange-default fw-600 my-2 px-1">{t('header:alerts')}</span>
                    <a
                        rel="noreferrer"
                        href="https://intercom.help/gorilladesk/en/articles/4391968-incomplete-jobs-in-the-past"
                        target="_blank"
                        className={classNames('items orange-default', { ' disable': !incomplete_job })}
                    >
                        <span className="txt">
                            {incomplete_job} {t('header:incomplete_job_in_the_past')}
                        </span>
                        <LinkKnowledgeBase
                            pathname={REPORT_SERVICES_LOOKUP}
                            onClick={() => _handleClickLinkServiceLookup(true)}
                        />
                    </a>
                    <a
                        rel="noreferrer"
                        href="https://intercom.help/gorilladesk/en/articles/4391971-invoices-pending-on-completed-jobs-red-triangle"
                        target="_blank"
                        className={classNames('items orange-default', { ' disable': !invoice_pending })}
                    >
                        <span className="txt">
                            {invoice_pending} {t('header:invoices_pending_on_completed_jobs')}
                        </span>
                        <LinkKnowledgeBase
                            pathname={REPORT_SERVICES_LOOKUP}
                            onClick={() => _handleClickLinkServiceLookup(false)}
                        />
                    </a>
                </div>
                <ListLevel allLevels={allLevels || []} />
            </>
        );
    };

    const renderLoading = () => {
        return (
            <div className="l-loading --questlog py-3">
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --onefourth"></div>
                <div className="loading --animation --line --full"></div>
                <div className="loading --animation --line --full"></div>
                <div className="divider"></div>
                <div className="loading --animation --line --full"></div>
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --half"></div>
                <div className="loading --animation --line --half"></div>
            </div>
        );
    };

    return (
        <div className="v2-dropdown__menu --center scrolls pb-1">{isLoading ? renderLoading() : renderQuestLogs()}</div>
    );
};

Questlog.propTypes = {};

export default Questlog;
