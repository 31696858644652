import React from 'react';

const Loading = () => {
    return (
        <div className="wrap-item__content flex-column gap-6 tasks-list">
            <div className="box-task">
                <div className="title mb-2">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                    <div className="avt-img" />
                    <div className="title__info">
                        <div className="name">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="time">
                            <div className="loading --animation --line --threefourth mt-1" />
                        </div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm" />
                </div>
                <div className="time">
                    <div className="loading --animation --line --twothird my-1" />
                </div>
                <div className="v2-dropdown tags list-add-tags">
                    <div className="tags__info d-flex flex-wrap">
                        <div className="tag-label loading --twothird" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loading;
