import React from 'react';

const LoadingBody = () => {
    return (
        <div className="wrap-loading --stripe-subscription body-modal">
            <div className="user-info d-flex align-center">
                <div className="flexinline-center loading --onefifth">
                    <div className="avt-img mr-1" />
                    <div className="loading --animation --line --full" />
                </div>
                <div className="info dots d-flex align-center loading --fourth">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="info dots d-flex align-center loading --twothird">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
            <div className="plans-content">
                <div className="header">
                    <div className="title-x-sm loading --onetenth">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="btn-action">
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="rows --modify --header">
                        <div className="col">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col --sm">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col --remove"></div>
                    </div>
                    <div className="rows">
                        <div className="value-empty loading --threefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingBody;
