import { useImperativeHandle, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { getTitleTemplate } from 'app/modules/settings/utils';

const Header = (
    { isEdit = false, defaultValue = '', type = '-1', titleTemplate = '', onChangeName = () => {} },
    ref
) => {
    const { t } = useTranslation(['setting']);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({
        _setInput,
        _getValueInput,
        _autoFocus
    }));

    const _setInput = (value) => (refInput.current.value = value);

    const _getValueInput = () => refInput.current.value;

    const _autoFocus = () => refInput.current.focus();

    return (
        <div className="template-header">
            <h5 className="title --sm">
                {t(isEdit ? 'edit' : 'add').concat(' ', t(getTitleTemplate({ title: titleTemplate, value: type })))}
            </h5>
            <div className="template-header__name mt-2">
                <p className="title">{t('template_name')}</p>
                <input
                    ref={refInput}
                    type="text"
                    className="field-input"
                    placeholder={t('template_name')}
                    defaultValue={defaultValue}
                    onChange={onChangeName}
                />
            </div>
        </div>
    );
};
export default forwardRef(Header);
