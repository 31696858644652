import classNames from 'classnames';
import { forwardRef, Fragment, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconClose from 'assets/icon/IconClose';
import { showStatusBar } from 'common/utils/FunctionUtils';
import ServiceNotifyCustomer from 'app/modules/addjob/ServiceNotifyCustomer';
import { DEFAULT_TIME_TIMEOUT } from 'app/const/App';
import { getTypeConfirmation } from 'common/utils/JobStatusUtils';

const MessagingCustomer = ({ jobData = {} }, ref) => {
    const { t } = useTranslation('jobDetail');

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: false,
        listTemplate: [],
        selected: {},
        fetched: null
    });
    const {
        isOpen: finalIsOpen,
        isLoading: finalIsLoading,
        selected: finalSelected,
        listTemplate: finalListTemplate,
        fetched: finalFetched
    } = state;

    const refButtonSave = useRef(null);
    const refStatus = useRef(null);
    const refDropdown = useRef(null);
    const refServiceNotifyCustomer = useRef(null);

    const jobStatus = jobData?.job?.status;
    const nameMessaging = t(getTypeConfirmation(jobStatus));

    useImperativeHandle(ref, () => ({ _handleOpen, _handleClose }));

    const _handleOpen = () => {
        _handleVisible();
    };

    const _handleClose = () => {
        dispatchState((prev) => ({ ...prev, isOpen: false }));
        refStatus.current?.clearAllStatusBar();
    };

    const _handleSave = () => {
        if (!Object.keys(finalSelected).length) {
            showStatusBar({
                id: 'validate_error',
                message: t('validate_template'),
                success: false,
                refAlert: refStatus
            });
            _removeLoading();
            return;
        } else {
            const { id, type } = finalSelected || {};
            const { event, schedule = [], job = {} } = jobData || {};
            const _success = ({ message }) => {
                _removeLoading();
                showStatusBar({ id: 'error', message, success: true, refAlert: refStatus });
                setTimeout(() => {
                    _handleClose();
                }, DEFAULT_TIME_TIMEOUT);
            };
            const _fail = ({ message }) => {
                _removeLoading();
                showStatusBar({ id: 'error', message, success: false, refAlert: refStatus });
            };
            refServiceNotifyCustomer.current?.onNotifyCustomer(
                {
                    job_ids: [job?.id],
                    jobStatus: jobStatus,
                    template_id: id,
                    template_type: type,
                    start: event?.start,
                    end: event?.end,
                    schedule: schedule?.map((item) => item.id).toString()
                },
                _success,
                _fail
            );
        }
    };

    const _renderOptions = () => {
        if (!finalListTemplate.length) return null;

        const _handleChangeTemplateSelected = (item) => {
            dispatchState((prev) => ({ ...prev, selected: item }));
            refDropdown.current?._closeDropdown();
        };

        return finalListTemplate.map((item) => {
            const { id, name } = item;

            return (
                <li
                    key={id}
                    className={classNames('items', { active: id === finalSelected?.id })}
                    onClick={() => _handleChangeTemplateSelected(item)}
                >
                    <span className="txt-ellipsis">{name}</span>
                </li>
            );
        });
    };

    const _renderButton = () => {
        return (
            <Fragment>
                <span className="txt-ellipsis">{finalSelected?.name || t('select_template')}</span>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleVisible = () => {
        const isFetched = finalFetched === jobStatus;
        dispatchState((prev) => ({ ...prev, isLoading: !isFetched, isOpen: true }));
        const _success = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                listTemplate: data,
                selected: data?.[0] || {},
                isLoading: false,
                isFetched: jobStatus
            }));
        };
        const _fail = () => {
            dispatchState((prev) => ({ ...prev, listTemplate: [], selected: {}, isLoading: false }));
        };
        if (!isFetched) {
            refServiceNotifyCustomer.current?.getListTemplate(jobStatus, _success, _fail);
        }
    };

    return (
        <Fragment>
            <ReactModal
                isOpen={finalIsOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal --form-no-border modal-messaging open"
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label text-capitalize">
                            {t('title_messaging_customer', { name: nameMessaging })}
                        </h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="body-modal has-form">
                        <GDStatusBar ref={refStatus} />
                        <p className="mb-2 black">{t('desc_messaging_customer', { name: nameMessaging })}</p>
                        <CalendarDropdown
                            ref={refDropdown}
                            id="dropdown-modal-notify-customer"
                            buttonClassName="dropbtn items bg-white"
                            isLoading={finalIsLoading}
                            customDropButton={_renderButton}
                        >
                            <ul>{_renderOptions()}</ul>
                        </CalendarDropdown>
                    </div>
                    <div className="footer-modal justify-end">
                        <span className="v2-btn-default --transparent" onClick={_handleClose}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            className="v2-btn-main"
                            disabled={finalIsLoading}
                            title={t('send')}
                            onSave={_handleSave}
                        />
                    </div>
                </div>
            </ReactModal>
            <ServiceNotifyCustomer ref={refServiceNotifyCustomer} />
        </Fragment>
    );
};

export default forwardRef(MessagingCustomer);
