import { OVERRIDE_KEYWORD, OVERRIDE_PERCENT_ID } from 'app/const/setting/SettingGlobalOverride';
import { roundingNumberDecimal } from 'common/utils/NumberUtils';

const _handleCalcNewCost = ({ prevValue, newValue, typeValue }) => {
    return prevValue + (typeValue === OVERRIDE_PERCENT_ID ? (prevValue * newValue) / 100 : newValue);
};

export const previewNewRate = ({ type = OVERRIDE_KEYWORD.TAX, prevValue = 0, newValue = 0 }) => {
    const { value, type: typeValue } = newValue;

    const getValue = {
        [OVERRIDE_KEYWORD.TAX]: roundingNumberDecimal({ number: +value, fixed: 3 }).toString(),
        [OVERRIDE_KEYWORD.ITEM]: roundingNumberDecimal({
            number: _handleCalcNewCost({
                prevValue: parseFloat(prevValue),
                newValue: parseFloat(value),
                typeValue
            }),
            fixed: 2
        }).toString()
    };

    return !!value ? getValue[type] : prevValue;
};
