import { ASSIGN_LEAD } from '../actions/assignLead';

const assignLeadReducer = (
    state = {
        assignLeadData: null
    },
    action
) => {
    switch (action.type) {
        case ASSIGN_LEAD.ACTION_ASSIGN_LEAD:
            return { ...state, assignLeadData: action.payload || null };
        default:
            return state;
    }
};

export default assignLeadReducer;
