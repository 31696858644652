import { DEFAULT_ALL, LIST_TABLE_ITEM_TYPE, PAGE_SIZE_DEFAULT } from './App';

export const CURRENT_TAB = 'currentTab';

export const COMMISSION_TYPE = {
    production: 'production',
    sold_by: 'sold_by'
};

export const COMMISSIONS_HEADER = {
    production: ['com_value', 'excluding_tax'],
    sold_by: []
};

export const COMMISSIONS_HEADER_STYLE = {
    line_item: 'col-line-item',
    rate: 'col-rate',
    commission_type: 'col-type',
    commission_value: 'col-value'
};

export const COMMISSION_CONTENT = {
    production: {
        staff: { id: 'user_name', style: '' },
        line_item: {
            id: 'item_name',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        item_value: { id: 'item_value', style: 'col' },
        customer_name: {
            id: 'customer_name',
            style: 'd-flex align-center ',
            type: LIST_TABLE_ITEM_TYPE.AVATAR
        },
        service: {
            id: 'service_name',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        invoice_number: {
            id: 'invoice_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        invoice_date: { id: 'invoice_date', style: 'col' },
        status: {
            id: 'invoice_status',
            style: 'd-flex align-left',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        method: { id: 'method', style: 'col' },
        type: { id: 'type', style: 'col' },
        com_value: { id: 'com_value', style: 'text-right' },
        excluding_tax: { id: 'excluding_tax', style: 'text-right' }
    },
    sold_by: {
        line_item: { id: 'item_name', style: '' },
        rate: { id: 'cost', style: '' },
        commission_type: {
            id: 'commission_type',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.SWITCH
        },
        commission_value: {
            id: 'commission_value',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.EDITABLE
        }
    }
};

export const COMMISSTION_COLUMN = {
    production: [
        'uid',
        'item_name',
        'item_value',
        'customer_name',
        'service_name',
        'invoice_number',
        'job_status',
        'invoice_date',
        'invoice_status',
        'method',
        'type',
        'com_value',
        'excluding_tax',
        'split_value',
        'split_tax'
    ],
    sold_by: ['line_item', 'rate', 'commission_type', 'commission_value']
};

export const COMMISSION_STATUS_STYLE = {
    draft: 'draft',
    void: 'void',
    partial: 'partial',
    partial_paid: 'partial-paid',
    sent: 'sent',
    paid: 'paid',
    write_off: 'write-off',
    failed: 'failed',
    won: 'sent',
    invoiced: 'sent', // won & invoiced
    lost: 'sent',
    emailed: 'sent',
    pending: 'sent',
    signed: 'sent',
    pending_e_sig: 'sent',
    unconfirm: 'sent',
    confirm: 'sent',
    reschedule: 'sent',
    pending_confirmation: 'sent',
    completed: 'sent',
    canceled: 'sent',
    recurrence: 'sent',
    complete: 'sent'
};

export const COMMISSION_COLOR_STYLE = {
    active: '#1eaa5c',
    inactive: ' #ee4646'
};

export const COMMISSIONS_TABS_BUTTON = [
    { id: 'production', name: 'production' },
    { id: 'sold_by', name: 'sold_by' }
];

export const COMMISSIONS_DEFAULT_PARAMS = {
    production: {
        start: '',
        end: '',
        offset: 0,
        order: '',
        staffs: DEFAULT_ALL,
        commission: 'standard',
        display_by: 'invoice_date',
        invoice_status: DEFAULT_ALL,
        payment_methods: DEFAULT_ALL,
        limit: PAGE_SIZE_DEFAULT
    },
    sold_by: {
        id: null,
        source: null
    }
};

export const COMMISSIONS_FILTER = {
    date_range: {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    display_by: {
        id: 'display_by',
        name: 'display_by',
        i18Text: 'commission',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        isMultiSelect: false,
        options: [
            {
                id: 'invoice_date',
                name: 'invoice_date_filter',
                isShow: false,
                isI18: true
            },
            {
                id: 'payment_date',
                name: 'payment_date',
                isShow: false,
                isI18: true
            }
        ]
    },
    commission: {
        id: 'commission',
        name: 'commission',
        i18Text: 'commission',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        isMultiSelect: false,
        options: [
            { id: 'standard', name: 'standard', isShow: false, isI18: true },
            {
                id: 'excluding_tax',
                name: 'excluding_tax_filter',
                isShow: false,
                isI18: true
            },
            {
                id: 'excluding_discounts',
                name: 'excluding_discounts',
                isShow: false,
                isI18: true
            },
            {
                id: 'excluding_tax_discounts',
                name: 'excluding_tax_discounts',
                isShow: false,
                isI18: true
            }
        ]
    },
    staffs: {
        id: 'staffs',
        name: 'staffs',
        i18Text: 'commission',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        isMultiSelect: true,
        options: []
    },
    invoice_status: {
        id: 'invoice_status',
        name: 'invoice_status',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        isMultiSelect: true,
        options: [
            { id: 'all', name: 'all_statuses', isShow: false, isI18: true },
            { id: 'partial', name: 'partial', isShow: false, isI18: true },
            {
                id: 'partial_paid',
                name: 'partial_paid',
                isShow: false,
                isI18: true
            },
            { id: 'sent', name: 'sent', isShow: false, isI18: true },
            { id: 'paid', name: 'paid', isShow: false, isI18: true },
            { id: 'failed', name: 'failed', isShow: false, isI18: true }
        ]
    },
    method: {
        id: 'method',
        name: 'method',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        isMultiSelect: true,
        options: []
    },
    source: {
        id: 'source',
        name: 'source',
        i18Text: 'commission',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        isMultiSelect: false,
        options: []
    }
};

export const LIST_COMMISSION_TYPE = ['$', '%'];
export const LIST_COMMISSION_SETTING = {
    user: { id: 1, value: 'commission_user' },
    type: { id: 2, value: 'commission_type' },
    value: { id: 3, value: 'commission_value' }
};
export const COMMISSION_USER_STATUS = {
    inactive: 0,
    active: 1
};

export const FILTER_DISPLAY_BY = { invoice_date: 0, payment_date: 1 };
export const FILTER_COMMISSIONS = {
    standard: 1,
    excluding_tax: 2,
    excluding_discounts: 3,
    excluding_tax_discounts: 4
};

export const COMMISSION_TYPE_VALUE = {
    NUMBER: 1,
    PERCENT: 2
};
