import PropTypes from 'prop-types';
import { useEffect, useContext } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';

function RealtimeSMSServices({ events = [], onUpdateRealtime = () => {} }) {
    const { smsReceiver: smsReceiverData, smsConversation: smsConversationData } = useContext(SocketContext);
    /**
     * Update SMS status when receive and mark read.
     */
    useEffect(() => {
        smsReceiverData && _updateRealtimeReceive(smsReceiverData);
    }, [smsReceiverData]);

    // Hide icon when data unread is === 0
    useEffect(() => {
        smsConversationData && _updateRealtimeStatus(smsConversationData);
    }, [smsConversationData]);

    const _updateRealtimeReceive = (smsReceiverData) => {
        const customerId = smsReceiverData?.customer?.customer_id;
        let checkUpdate = false;

        if (!customerId) {
            return false;
        }

        const eventsUpdate = [...events].map((item) => {
            if (
                item.type === 'job' &&
                customerId.toString() === item?.customer?.id?.toString() &&
                parseInt(smsReceiverData.total) &&
                !item.circle_notify
            ) {
                checkUpdate = true;
                return { ...item, circle_notify: 1 };
            }
            return item;
        });
        checkUpdate && onUpdateRealtime(eventsUpdate);
    };

    const _updateRealtimeStatus = (smsConversationData) => {
        const { conversations = [] } = smsConversationData || {};

        if (!Array.isArray(conversations)) {
            return false;
        }

        let checkUpdate = false;

        const eventsUpdate = [...events].map((item) => {
            const findIndex = conversations.findIndex(
                (itemConver) =>
                    item.type === 'job' && itemConver.customer_id?.toString() === item?.customer?.id?.toString()
            );
            const objectConver = findIndex < 0 ? null : { ...conversations[findIndex] };
            const numberUnread = findIndex < 0 ? 0 : !!parseInt(objectConver.unread);

            if (objectConver && ((numberUnread && !item.circle_notify) || (!numberUnread && item.circle_notify))) {
                checkUpdate = true;
                return { ...item, circle_notify: !!numberUnread ? 1 : 0 };
            }
            return item;
        });
        checkUpdate && onUpdateRealtime(eventsUpdate);
    };

    return null;
}

RealtimeSMSServices.propTypes = { events: PropTypes.arrayOf(PropTypes.shape({})), onUpdateRealtime: PropTypes.func };

export default RealtimeSMSServices;
