import React, { useReducer, forwardRef, useImperativeHandle, useRef } from 'react';
import { INVOICE_PAYMENT_STATUS } from 'app/modules/jobdetail/const/Invoice';
import { reducer } from 'app/const/Reducer';

import PaymentSummary from 'app/modules/jobdetail/tabs/payment/components/PaymentSummary';
import PaymentInvoice from 'app/modules/jobdetail/tabs/payment/components/PaymentInvoice';
import PaymentFooter from 'app/modules/jobdetail/tabs/payment/components/PaymentFooter';
import Balance from 'app/modules/jobdetail/tabs/payment/components/Balance';
import SelectCustomer from 'app/modules/jobdetail/tabs/payment/components/SelectCustomer';
import Amount from 'app/modules/jobdetail/tabs/payment/components/Amount';
import SelectDate from 'app/modules/jobdetail/tabs/payment/components/Date';
import PaymentMethod from 'app/modules/jobdetail/tabs/payment/components/payment';
import InvoiceStatus from 'app/modules/jobdetail/tabs/payment/components/InvoiceStatus';
import Header from 'app/modules/quickadd/payment/Header';
import AddPaymentContextProvider from 'app/modules/jobdetail/contexts/AddPaymentContext';
import StatusBar from 'app/components/status/statusbar';
import ReactModal from 'react-modal';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { mixpanelAddPayment } from 'app/modules/mixpanel/MixpanelAddPayment';
import { useSelector } from 'react-redux';
import { LIST_STATUS } from 'app/const/Status';

const AddPayment = forwardRef((proos, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const refStatusBar = useRef(null);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);
    const invoiceDetail = {};
    const finalIsvisible = state.isVisible;
    // eslint-disable-next-line no-undef
    const customerDefault = global.customerDetail;

    useImperativeHandle(ref, () => ({
        openForm: _handleOpenForm
    }));

    const _handleOpenForm = () => {
        dispatchState({
            isVisible: true
        });
    };

    const _handleCloseForm = (e) => {
        e && e.stopPropagation();
        dispatchState({
            isVisible: false
        });
    };

    function _addPaymentSuccess(message, type) {
        if (type === LIST_STATUS.SUCCESS) handleTrackingEvent(mixpanelAddPayment({ id: profileId }));
        refStatusBar.current.showStatusBar('show_message', message, type);
    }

    if (!finalIsvisible) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal --new-job --new-payment container-modal open"
            onRequestClose={_handleCloseForm}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleCloseForm} />
            <AddPaymentContextProvider
                paymentData={{
                    customerId: customerDefault?.id || '',
                    filterInvoice: INVOICE_PAYMENT_STATUS[0].value,
                    customerInfo: customerDefault || null,
                    isDefaultCustomer: !!customerDefault
                }}
            >
                <div className="modal__container">
                    <Header onClose={_handleCloseForm} />
                    <StatusBar ref={refStatusBar} />
                    <div className="body-modal form-tabpane-details scrolls">
                        <div className="container-column form-add-payment">
                            <div className="rows --header-info">
                                <div className="customer-info">
                                    <div className="customer-info-number">
                                        <Balance />
                                        <SelectCustomer />
                                        <Amount />
                                        <SelectDate />
                                        <PaymentMethod />
                                        <InvoiceStatus />
                                    </div>
                                </div>
                            </div>
                            <PaymentInvoice onClose={_handleCloseForm} invoiceData={invoiceDetail} />
                            <PaymentSummary />
                        </div>
                    </div>
                    <div className="footer-modal">
                        <PaymentFooter onCallback={_addPaymentSuccess} />
                    </div>
                </div>
            </AddPaymentContextProvider>
        </ReactModal>
    );
});

export default AddPayment;
