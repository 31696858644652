import { TYPE_SMS } from 'common/redux/actions/sms/smsAction';

const smsReducer = (state = { detail_sms: null, list_variables: [], fetched: false }, action) => {
    switch (action.type) {
        case TYPE_SMS.OPEN_SMS_MODULE_DETAIL:
            return { ...state, detail_sms: action.payload };
        case TYPE_SMS.SMS_SET_LIST_VARIABLE:
            return { ...state, list_variables: action.payload, fetched: true };
        default:
            return state;
    }
};

export default smsReducer;
