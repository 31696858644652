import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BannerReferral from './components/BannerReferral';
import './css/referral.scss';
import TableReferral from './components/TableReferral';
import { getPlanUser } from 'common/utils/PermissionUtils';
import ErrorPage from '../error';

const Referral = () => {
    const { t } = useTranslation();
    const profileUser = useSelector(({ auth }) => auth.user.profile);
    const { isTrial } = getPlanUser(profileUser);

    if (isTrial) return <ErrorPage errorMessage={t('page_is_unavailable')} />;

    return (
        <div className="container-wrap container-referral">
            <BannerReferral />
            <TableReferral />
        </div>
    );
};

export default Referral;
