import GdListPhone from 'app/components/phone/ListPhone';
import React, { useContext } from 'react';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { TYPE_OPEN_SMS } from 'app/const/Sms';

const AltContacts = () => {
    const { contacts, customer } = useContext(CustomerDetailContext);
    if (!contacts || !Array.isArray(contacts) || !contacts.length) return null;
    return (
        <div className="details-info flex-column gap-8">
            {contacts.map((contact) => {
                // ID = 0 is primary contact
                if (+contact?.id === 0) return null;
                return (
                    <div key={contact.id} className="flex-column gap-2">
                        <span className="title-sx">Alt contact</span>
                        <div className="valid-name">
                            <p className="valid-name__title">{contact.customer.full_name}</p>
                            {contact.title ? <div className="label-content">{contact.title}</div> : null}
                        </div>
                        <GdListPhone
                            data={contact.phones || []}
                            typeOpen={TYPE_OPEN_SMS.CUSTOMER}
                            customer={customer}
                        />
                        <a href={`mailto:${contact.email}`} className="is-link">
                            {contact.email}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default AltContacts;
