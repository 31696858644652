export const canAddCustomField = (listFields = [], id = '') => {
    return !listFields.some((item) => item.id === id);
};

export const convertToCustomerField = ({ field = {}, value }) => {
    if (!field || !value) throw new Error('Missing field or value to convert to customer field');
    return { id: field.id, label: value.label, value: value.value, field };
};
export const convertOptions = (options = []) => {
    return options.map((item, index) => ({ id: index, label: item, isEditField: true }));
};
export const duplicatedOptions = (options = []) => {
    let result = [];
    const objStore = {};

    options.forEach((item) => {
        objStore[item] = [...(objStore[item] || []), item];
        if (objStore[item].length > 1) result = [...new Set([...result, item])];
    });
    return result;
};
