import moment from 'moment';
import React, { useEffect, useReducer } from 'react';

import { CURRENT_MONTH_SHORT_NAME } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { SALE, YEAR } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import FilterReport from 'app/modules/report/components/FilterReport';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';

export default function RevenuePagesFilter({
    initListFilter,
    initSale,
    currentTab,
    keyReport,
    handleChangeFilter = () => {}
}) {
    const [state, dispatchState] = useReducer(reducer, { listFilter: [...initListFilter], forceRerender: false });

    const { listFilter, forceRerender } = state;

    const listDetailTabs = [
        REPORT_TYPE.REVENUE_BY_CLIENT_DETAIL_TAB,
        REPORT_TYPE.REVENUE_BY_ITEM_DETAIL_TAB,
        REPORT_TYPE.REVENUE_BY_SERVICE_DETAIL_TAB,
        REPORT_TYPE.REVENUE_BY_SOURCE_DETAIL_TAB,
        REPORT_TYPE.REVENUE_BY_STAFF_DETAIL_TAB,
        REPORT_TYPE.REVENUE_BY_TAGS_DETAIL_TAB
    ];

    const createListFilter = (saleValue) => {
        const result = [...initListFilter];
        if (saleValue === 'total_billed') result.pop();
        if (listDetailTabs.includes(currentTab)) result.shift();
        return result;
    };

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, listFilter: createListFilter(initSale), forceRerender: false }));
    }, [currentTab]);

    useEffect(() => {
        dispatchState((prev) => ({ ...prev, forceRerender: true }));
    }, [listFilter]);

    const _handleChangeFilter = (params, type) => {
        switch (type) {
            case YEAR:
                const newParamsReport = { ...params };
                const selectDate = `${CURRENT_MONTH_SHORT_NAME}-${newParamsReport.year}`;
                newParamsReport.start = moment(selectDate, 'MMM-YYYY').clone().startOf('month').format('YYYY-MM-DD');
                newParamsReport.end = moment(selectDate, 'MMM-YYYY').clone().startOf('month').format('YYYY-MM-DD');
                setLocalStorage(keyReport, newParamsReport);
                break;
            case SALE:
                dispatchState({ listFilter: createListFilter(params.sale) });
                break;
            default:
                dispatchState((prev) => ({ ...prev, forceRerender: false }));
                break;
        }
        handleChangeFilter(params, type);
    };

    return (
        <FilterReport
            reportType={REPORT_TYPE.REVENUE_BY_CLIENT}
            filters={listFilter}
            onSelect={_handleChangeFilter}
            forceRerender={forceRerender}
        />
    );
}
