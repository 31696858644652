export default function IconAlert({ isHasColor, ...props }) {
    if (isHasColor) {
        return (
            <svg width={25} height={25} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0339 3.32031C11.6854 2.19184 13.3142 2.19184 13.9658 3.32031L22.3103 17.7734C22.9618 18.9019 22.1474 20.3125 20.8444 20.3125H4.15532C2.85228 20.3125 2.03787 18.9019 2.68939 17.7734L11.0339 3.32031Z"
                    fill="#FF8819"
                />
                <path
                    d="M11.0339 3.32031C11.6854 2.19184 13.3142 2.19184 13.9658 3.32031L22.3103 17.7734C22.9618 18.9019 22.1474 20.3125 20.8444 20.3125H4.15532C2.85228 20.3125 2.03787 18.9019 2.68939 17.7734L11.0339 3.32031Z"
                    fill="#FF6E26"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.397 14.4863C13.4757 14.4863 14.3501 15.3608 14.3501 16.4395C14.3501 17.5181 13.4757 18.3926 12.397 18.3926C11.3183 18.3926 10.4438 17.5181 10.4438 16.4395C10.4438 15.3608 11.3183 14.4863 12.397 14.4863Z"
                    fill="white"
                />
                <rect x="11.1975" y="5.98926" width="2.60417" height="7.8125" rx="1.30208" fill="white" />
            </svg>
        );
    }
    return (
        <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.1651 1.75C10.2028 0.0833318 7.79718 0.083336 6.83494 1.75L1.63878 10.75C0.676533 12.4167 1.87935 14.5 3.80385 14.5H14.1962C16.1207 14.5 17.3235 12.4167 16.3612 10.75L11.1651 1.75Z"
                fill="#EE4646"
                stroke="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 11C9.55228 11 10 11.4477 10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11Z"
                fill="white"
            />
            <rect x={8} y={3} width={2} height={6} rx={1} fill="white" />
        </svg>
    );
}
