import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GDCollapse from 'app/components/collapse';
import { reducer } from 'app/const/Reducer';
import { customerOpportunity } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { OPPORTUNITY_ACTIONS, TYPE_OPEN_MODAL } from '../../../constant';
import Opportunity from './opportunity';
import Loading from './opportunity/Loading';
import OpportunityItem from './opportunity/OpportunityItem';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import { ACCESS_CUSTOMER_OPP } from 'app/const/Permissions';

const BlockOpportunity = forwardRef(({ onCloseOtherModal = () => {} }, ref) => {
    const { t } = useTranslation('customers');
    const { id } = useParams();
    const { contacts } = useContext(CustomerDetailContext);
    const userProfile = useSelector(({ auth }) => auth.user.profile);
    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);

    const [state, dispatchState] = useReducer(reducer, { opportunities: [], isLoading: true });
    const { opportunities, isLoading } = state;
    const isFirstTime = useRef(true);
    const refOpportunity = useRef(null);
    const refCollapse = useRef(null);
    const refWrapper = useRef(null);
    const refPrevIdButton = useRef(null);
    const refPrevIdCus = useRef(null);

    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);
    const isHaveCustomerOpp = checkPermission(permissionsList, ACCESS_CUSTOMER_OPP);

    useImperativeHandle(ref, () => ({ closeModal: () => refOpportunity.current?.close() }));

    useEffect(() => {
        _loadOpportunity(id !== refPrevIdCus.current);
        refPrevIdCus.current = id;
    }, [id]);

    const _loadOpportunity = (hardLoad = false) => {
        if ((!isFirstTime.current && !hardLoad) || (!isGrowthPlan && !isTrial) || !!!opportunityAddon) return;
        isFirstTime.current = false;

        const _loadSuccess = ({ data }) => {
            dispatchState({ opportunities: data || [], isLoading: false });
        };

        const _loadFail = () => {
            dispatchState({ opportunities: [], isLoading: false });
        };

        clientQuery(customerOpportunity(id), { data: {}, method: 'GET' }, _loadSuccess, _loadFail);
    };

    const _handleOpenOpportunity = () => {
        _loadOpportunity();
    };

    const _handleModalOpportunity = (data) => {
        onCloseOtherModal(TYPE_OPEN_MODAL.OPPORTUNITY);
        refOpportunity.current?.open(data, contacts);

        if (!!data) {
            _handleSetButtonAdd(false);
            refWrapper.current
                ?.querySelector(`#button-opportunity-${refPrevIdButton.current}`)
                ?.classList.remove('is-disable');
            refWrapper.current?.querySelector(`#button-opportunity-${data.id}`)?.classList.add('is-disable');
            refPrevIdButton.current = data.id;
        } else {
            if (refPrevIdButton.current) {
                refWrapper.current
                    ?.querySelector(`#button-opportunity-${refPrevIdButton.current}`)
                    ?.classList.remove('is-disable');
            }
            _handleSetButtonAdd(true);
        }
    };

    const _handleSetButtonAdd = (isDisable) => {
        if (refPrevIdButton.current) {
            refWrapper.current
                ?.querySelector(`#button-opportunity-${refPrevIdButton.current}`)
                ?.classList.remove('is-disable');
        }
        refCollapse.current?.setDisableAdd(isDisable);
    };

    const _handleSubmitSuccess = (data, type) => {
        switch (type) {
            case OPPORTUNITY_ACTIONS.ADD:
                dispatchState((prevState) => ({
                    ...prevState,
                    opportunities: [data, ...prevState.opportunities]
                }));
                break;
            case OPPORTUNITY_ACTIONS.EDIT:
                dispatchState((prevState) => ({
                    ...prevState,
                    opportunities: prevState.opportunities.map((opportunity) => {
                        if (opportunity.id === data.id) return data;
                        return opportunity;
                    })
                }));
                break;
            default:
                break;
        }
    };

    const _handleDeleteSuccess = (id) => {
        dispatchState((prevState) => ({
            ...prevState,
            opportunities: prevState.opportunities.filter((opportunity) => opportunity.id !== id)
        }));
    };

    if ((!isGrowthPlan && !isTrial) || !!!opportunityAddon) return null;
    return (
        <>
            <GDCollapse
                ref={refCollapse}
                isUp
                isArrowRight
                defaultCollapsed
                title={t('opportunities')}
                className="details-info wrap-item wrap-collapse"
                classWrapTitle="wrap-item__header flex-betweenitems"
                classTitle="txt-ellipsis flex-1"
                onToggle={_handleOpenOpportunity}
                onClickAdd={isHaveCustomerOpp ? () => _handleModalOpportunity() : null}
            >
                {isLoading ? <Loading /> : null}
                {!isLoading && opportunities?.length ? (
                    <div ref={refWrapper} className="wrap-item__content tasks-list">
                        {opportunities.map((opportunity) => (
                            <OpportunityItem
                                key={opportunity.id}
                                {...opportunity}
                                onUpdate={() => _handleModalOpportunity(opportunity)}
                            />
                        ))}
                    </div>
                ) : null}
            </GDCollapse>
            <Opportunity
                ref={refOpportunity}
                onSetButtonAdd={_handleSetButtonAdd}
                onSubmitSuccess={_handleSubmitSuccess}
                onDeleteSuccess={_handleDeleteSuccess}
            />
        </>
    );
});

export default BlockOpportunity;
