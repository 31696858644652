import React, { forwardRef, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { actionDeleteBuilding } from 'app/const/Api';
import { ManageUnitContext } from 'app/modules/jobdetail/contexts/ManageUnitContext';
import AddBuilding from 'app/modules/jobdetail/tabs/unit/manage/building/Add';
import { clientQuery } from 'common/utils/ApiUtils';
import ItemBuilding from './Item';

export default function ManageBuilding({ onUpdate }) {
    const { t } = useTranslation('jobDetail');
    const { jobData, updateUnit } = useSelector(({ createJobReducer }) => createJobReducer);
    const onUpdateUnitSuccess = useSelector(({ locationReducer }) => locationReducer.locationData?.onUpdateUnitSuccess);
    const onUpdateUnits = useSelector(({ jobDetailReducer }) => jobDetailReducer?.jobDetail?.onUpdateUnits);
    const { manageUnitContextData, updateManageUnitContextData } = useContext(ManageUnitContext);
    const refWarning = useRef(null);
    const refIdDelete = useRef(null);
    const refButtonSave = useRef(null);
    const refWithoutFetch = useRef(false);

    function _handleSelectBuilding(e, buildingId, isActive) {
        e && e.stopPropagation();

        if (isActive) {
            return false;
        }

        const newBuildingSelected = manageUnitContextData.buildingData.find((item) => item.id === buildingId) || {};
        const listUnit = newBuildingSelected.units || [];

        updateManageUnitContextData({
            buildingSelected: buildingId,
            unitSelected: Array.isArray(listUnit) && listUnit.length > 0 ? listUnit[0].id : ''
        });
    }

    const _handleDelete = (e, buildingId, isWithoutFetch = false) => {
        e && e.stopPropagation();
        refIdDelete.current = buildingId;
        refWithoutFetch.current = isWithoutFetch;
        refWarning.current?._open({
            title: t('deleting_building'),
            description: t('delete_desc', { name: t('building') })
        });
    };

    const _removeLoading = () => {
        refButtonSave.current?.removeLoading();
    };

    const _handleCloseConfirm = () => {
        refWarning.current?._close();
        _removeLoading();
        refIdDelete.current = null;
    };

    const _handleUpdateBuildingFail = ({ message }) => {
        refWarning.current?._setStatusAlert(message);
        _removeLoading();
    };

    function _handleConfirm() {
        if (!refIdDelete.current) return;
        const buildingId = refIdDelete.current;

        if (!refWithoutFetch.current) {
            clientQuery(
                actionDeleteBuilding(buildingId),
                { data: {}, method: 'delete' },
                _handleUpdateBuilding,
                _handleUpdateBuildingFail
            );
        } else {
            _handleUpdateBuilding();
        }
    }

    const _handleUpdateBuilding = () => {
        const buildingId = refIdDelete.current;
        const idsUnitDeleted = [];

        const newListBuilding = manageUnitContextData.buildingData.map((item) => {
            if (item.id === buildingId) {
                const newUnits = [];
                // Get list unit deleted
                if (Array.isArray(item.units)) {
                    item.units.forEach((item) => {
                        idsUnitDeleted.push(item.id);
                        newUnits.push({ ...item, deleted: -1 });
                    });
                }
                return { ...item, units: newUnits, deleted: -1 };
            }
            return item;
        });

        const newBuildingSelected =
            newListBuilding.length > 0 && newListBuilding[0]?.deleted !== -1 ? newListBuilding[0] : {};
        const listUnit = newBuildingSelected.units || [];

        onUpdate(newListBuilding, manageUnitContextData.unitData);
        // This handle for update unit in unit filter customer detail
        if (onUpdateUnitSuccess) onUpdateUnitSuccess(idsUnitDeleted, 'delete_multiple');
        if (updateUnit && jobData) updateUnit(idsUnitDeleted, 'delete_multiple', jobData.customer_id);
        if (onUpdateUnits) onUpdateUnits(idsUnitDeleted, 'delete_multiple');
        updateManageUnitContextData({
            buildingData: newListBuilding,
            buildingSelected: newBuildingSelected.id || '',
            unitSelected: Array.isArray(listUnit) && listUnit.length > 0 ? listUnit[0].id : ''
        });

        _handleCloseConfirm();
    };

    function _renderListBuilding() {
        return manageUnitContextData.buildingData.map((item) => {
            const { id: itemId, deleted: flagDeleted } = item;
            const isActive = itemId === manageUnitContextData.buildingSelected;
            const numberUnit = item.units.filter((item) => item.deleted !== -1).length || 0;

            if (parseFloat(flagDeleted) === -1) {
                return false;
            }

            return (
                <ItemBuilding
                    key={itemId}
                    itemBuilding={item}
                    onSelect={_handleSelectBuilding}
                    withoutFetch={manageUnitContextData.withoutFetch}
                    onDelete={_handleDelete}
                    isActive={isActive}
                    numberUnit={numberUnit}
                />
            );
        });
    }

    return (
        <div className="wrap-mdu__boxs flex-column">
            <AddBuilding onUpdate={onUpdate} />
            <div className="flex-column__content content scrolls">{_renderListBuilding()}</div>
            <GDModalWarning
                ref={refWarning}
                footer={
                    <Footer
                        ref={refButtonSave}
                        handleCloseConfirm={_handleCloseConfirm}
                        handleConfirm={_handleConfirm}
                    />
                }
            />
        </div>
    );
}

export const Footer = forwardRef(
    ({ titleButton = 'delete', handleCloseConfirm = () => {}, handleConfirm = () => {} }, ref) => {
        const { t } = useTranslation('jobDetail');

        return (
            <div className="footer-modal footer-hasbtn btn-close">
                <div className="v2-btn-default --transparent" onClick={handleCloseConfirm}>
                    {t('cancel')}
                </div>
                <ButtonSave ref={ref} title={t(titleButton)} wrapClass="v2-btn-main ml-2" onSave={handleConfirm} />
            </div>
        );
    }
);
