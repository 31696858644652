import {
    DRIP_CREATE_SMART_LIST,
    DRIP_DELETE_ITEM_SMART_LIST,
    DRIP_DUPLICATE_ITEM_SMART_LIST,
    DRIP_GET_DETAIL_FILTER_ITEM_SMART_LIST,
    DRIP_GET_LIST_CUSTOMER,
    DRIP_GET_LIST_FILTERS_SMART_LIST,
    DRIP_GET_LIST_SMART_LISTS,
    DRIP_GET_LIST_SMART_LIST_WITH_BASIC_INFORMATION,
    DRIP_SMART_LIST_UPDATE_STATUS,
    DRIP_UPDATE_SMART_LIST
} from 'app/const/Api';
import { deleteWithToken, fetchWithToken, formApi, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

// smart list
export function* getListSmartLists({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_SMART_LISTS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteItemSmartLists({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, DRIP_DELETE_ITEM_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* duplicateItemSmartLists({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(formApi, DRIP_DUPLICATE_ITEM_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createSmartLists({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, DRIP_CREATE_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateSmartLists({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIP_UPDATE_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// customer
export function* getListCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, DRIP_GET_LIST_CUSTOMER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusCustomer({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIP_SMART_LIST_UPDATE_STATUS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDetailFiltersItemSmartList({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_DETAIL_FILTER_ITEM_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// filters

export function* getListFiltersSmartList({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_FILTERS_SMART_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

/***/
export function* getListFilterOptionsByURI() {
    // try {
    //     const response = yield call(fetchWithToken, `${API_VERSION}${params.url}`);
    //     if (response && response.success) {
    //         actionSuccess(response);
    //         return;
    //     }
    //     actionFailed(response);
    // } catch (error) {
    //     actionFailed(error);
    // }
}

export function* getListSmartListWithBasicInfo({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_SMART_LIST_WITH_BASIC_INFORMATION, params);
        if (response && response.success) {
            const newData = [...response.data];
            newData.map((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
