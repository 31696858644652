import { takeLatest } from 'redux-saga/effects';
import { TYPE_ACTION_COMPANY_USERS } from 'common/redux/actions/companyUsersAction';

import { getCompanyUsers, editCompanyUser } from './list';

export function* companyUsersWatcher() {
    yield takeLatest(TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING, getCompanyUsers);
}

export function* companyUsersEditWatcher() {
    yield takeLatest(TYPE_ACTION_COMPANY_USERS.EDIT_USER_COMPANY, editCompanyUser);
}
