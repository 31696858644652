import { ADDONS_PORTAL_DETAIL, UPDATE_ADDONS_PORTAL_OPTIONS } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer } from 'react';
import BookingShowCode from '../booking/components/BookingShowCode';
import SaveSubdomain from '../booking/components/SaveSubdomain';
import AddonsBoxHeader from '../components/AddonsBoxHeader';
import AddonsOptions from '../components/AddonsOptions';
import Loading from './components/Loading';
import { reducer } from 'app/const/Reducer';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsPortal = () => {
    const [state, dispatchState] = useReducer(reducer, { portal: {}, isLoading: true });
    const { portal, isLoading } = state;

    useEffect(() => {
        _getSettingDetail();
    }, []);

    const _getSettingDetail = () => {
        const _handleSuccess = ({ data }) => dispatchState({ portal: data, isLoading: false });
        clientQuery(ADDONS_PORTAL_DETAIL, { method: 'GET' }, _handleSuccess, checkAccessFail);
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper boxs-wrapper--portal">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <>
                            <div className="boxs boxs--no-border --hasline --mr-line">
                                <AddonsBoxHeader
                                    upgrade={portal.upgrade}
                                    keyword={portal.keyword}
                                    name={portal.name}
                                    status={portal.status}
                                    level={portal.level}
                                    urlUpdateStatus={UPDATE_ADDONS_PORTAL_OPTIONS}
                                />
                                <div className="boxs__contents mt-3">
                                    <p className="description">
                                        Turn on the portal to allow your customers to manage their account online. Grab
                                        your installation code below and install it just before the closing body tag of
                                        your website. <br />
                                        [This feature also unlocks email short-codes for your portal features.]
                                    </p>
                                    <BookingShowCode scriptInfo={portal.script_info} />
                                </div>
                            </div>
                            <div className="boxs boxs--no-border --hasline">
                                <SaveSubdomain
                                    subdomain={portal.portal_url}
                                    domain={portal.portal_domain}
                                    defaultValue={portal.subdomain}
                                    description="Add your unique customer portal link above on your own website. Link any button or design element to your portal."
                                />
                            </div>
                            <AddonsOptions data={portal.options} urlUpdate={UPDATE_ADDONS_PORTAL_OPTIONS} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsPortal;
