import ButtonSave from 'app/components/button/ButtonSave';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { actionActiveUserVoip } from 'app/const/api/Voip';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconClose from 'assets/icon/IconClose';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveUsersContext } from './ActiveUsersProvider';

export const ActiveVoipUsersEdit = ({ item }) => {
    const { onDeleteNumber = () => {} } = useContext(ActiveUsersContext);
    const { id, onDelete = () => {}, onShowStatus = () => {} } = item;
    const { t } = useTranslation();
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);
    const refData = useRef(null);

    const options = [
        {
            id,
            label: (
                <div className="items svg-red justify-center">
                    <div className="red-default fw-500 mr-1">{t('addons:remove_user')}</div>
                    <IconClose />
                </div>
            )
        }
    ];

    const _handleSelectItem = (id) => {
        refWarning.current._open({ title: t('common:delete'), description: t('addons:desc_delete_this_item') });
        refData.current = { id };
    };

    const _handleCloseWarning = () => {
        refWarning.current._close();
    };

    const _handleConfirmWarning = () => {
        const { id } = refData.current;

        const _handleSuccess = () => {
            onDelete(id);
            onDeleteNumber({ userId: id });
        };

        const _handleFail = (err) => {
            _handleCloseWarning();
            onShowStatus(err);
        };

        clientQuery(actionActiveUserVoip(id), { method: 'DELETE' }, _handleSuccess, _handleFail);
    };

    return (
        <div>
            <CalendarDropdown
                id={id}
                buttonClassName="v2-btn-default --transparent --icon-sm"
                customDropButton={() => <IconThreeDots />}
                wrapperClassName="flexcenter"
                wrapperListClass="v2-dropdown__menu dropdown-option"
                wrapperItemClass=""
                options={options}
                keyGetName="label"
                keyGetValue="id"
                keyGetKey="id"
                onSelect={_handleSelectItem}
            />
            <GDModalWarning
                ref={refWarning}
                isLargeContent={false}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_handleCloseWarning}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('yes')}
                            className="v2-btn-main"
                            onSave={_handleConfirmWarning}
                        />
                    </div>
                }
            />
        </div>
    );
};
