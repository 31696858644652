import React, { useContext } from 'react';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import { MODES_MONTH } from 'app/modules/jobdetail/const';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';

const RepeatMonth = () => {
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);

    const invoiceRepeatCurrent = invoiceRepeatData.offset;

    const finalModeMonth = invoiceRepeatCurrent.bymonthday;

    function _handleChangeMode(e, value) {
        _sendDataToContext(invoiceRepeatCurrent.interval, value);
    }

    function _handleChangeValue(interval) {
        _sendDataToContext(interval, finalModeMonth);
    }

    function _sendDataToContext(interval, mode) {
        const refValue = {
            ...invoiceRepeatData,
            offset: {
                ...invoiceRepeatCurrent,
                bymonthday: mode,
                interval: interval
            }
        };

        updateInvoiRepeatContext(refValue);
    }

    return (
        <div className={'tab-panel active'}>
            <div className="rows mt-3">
                <div className="txt pl-6">Every</div>
                <div className="details field-every">
                    <FormInterval interval={invoiceRepeatCurrent.interval} onChange={_handleChangeValue} />
                    <span className="text-last">month</span>
                </div>
            </div>
            <div className="rows mt-3">
                <div className="txt pl-6"> Repeat By </div>
                <div className="details d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button
                                className={`tab-items ${
                                    finalModeMonth === MODES_MONTH.DAY_WEEK ? 'active-tab-selector' : ''
                                }`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES_MONTH.DAY_WEEK)}
                            >
                                Day Of The Week
                            </button>
                            <button
                                className={`tab-items ${
                                    finalModeMonth === MODES_MONTH.DAY_MONTH ? 'active-tab-selector' : ''
                                }`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES_MONTH.DAY_MONTH)}
                            >
                                Day Of The Month
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RepeatMonth;
