import { ADDONS, ADDONS_SMS } from 'app/config/routes';
import {
    ADDONS_ACTIVE_WITH_CONDITION_DATA,
    ADDONS_ACTIVE_WITH_CONDITION_KEY,
    ADDONS_ZAPIER,
    INBOX_EMAIL,
    LIST_API_UPDATE_ADDONS_OPTIONS,
    LIST_ICONS_ADDONS,
    LIST_ICONS_ADDONS_INACTIVE,
    LIST_MERCHANT_ADDONS,
    SQUARE,
    STRIPE,
    VOIP,
    LIST_ADDONS_BETA,
    ADDONS_MULTIPLE_BRANCH
} from 'app/const/addons';
import { ADDONS_MERCHANT_DEFAULT } from 'app/const/Api';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { CONST_INBOX_PAGE } from 'app/modules/inbox/const';
import IconTooltip from 'assets/icon/IconTooltip';
import IconUpgrade from 'assets/icon/IconUpgrade';
import classNames from 'classnames';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { getInboxCounterRequest } from 'common/redux/actions/inboxAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { removeLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import SquareAddonsDisconnect from '../square/components/SquareAddonsDisconnect';
import StripeAddonsDisconnect from '../stripe/components/StripeAddonsDisconnect';
import { mixpanelManageAddons } from 'app/modules/mixpanel/MixpanelManageAddons';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import { addBranchPath } from 'app/const/Branch';
import { ADDON_LEVEL, URL_LINK_ADDONS } from '../constants';
import IconGrowth from 'assets/icon/IconGrowth';
import { checkAccessFail } from 'common/utils/PermissionUtils';

let timer;

const AddonsItem = ({
    id,
    name,
    sub_title,
    status,
    upgrade = 0,
    locked = 0,
    merchant_default = 0,
    level = 0,
    keyword = '',
    onChange = () => {},
    onShowStatus = () => {}
}) => {
    const { t } = useTranslation('addons');
    const oldPathSMS = '/addons/smsplivo';
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { active: !!status });
    const { active } = state;
    const refBox = useRef(null);
    const refCheckbox = useRef(null);
    const refDisconnectStripe = useRef(null);
    const refDisconnectSquare = useRef(null);
    const profileId = useSelector(({ auth }) => auth.user.profile.id);

    const keywordLowerCased = keyword.toLowerCase();

    const isMerchant = LIST_MERCHANT_ADDONS.includes(keyword);
    let linkToAddonsDetail = URL_LINK_ADDONS[keyword] ?? `${ADDONS}/${keywordLowerCased}`;

    if (oldPathSMS === linkToAddonsDetail) linkToAddonsDetail = ADDONS_SMS;
    const isZapier = keyword === ADDONS_ZAPIER;
    const isMultiBranch = keyword === ADDONS_MULTIPLE_BRANCH;
    const isStripe = keyword === STRIPE;

    const isProAddon = level === ADDON_LEVEL.PRO;
    const isGrowthAddon = level === ADDON_LEVEL.GROWTH;
    const isProUpgrade = !!(isProAddon && upgrade);
    const isGrowthUpgrade = !!(!!isGrowthAddon && upgrade);

    const isBeta = LIST_ADDONS_BETA.includes(keyword);

    const _handleChange = (e) => {
        if (isProUpgrade || isGrowthUpgrade) {
            e.preventDefault();
            history.push(addBranchPath(linkToAddonsDetail));
            return;
        }

        if (isMerchant) {
            e.preventDefault();

            if (!e.target.checked) {
                _handleConfirm();
            } else {
                onShowStatus({
                    id: 'message_status',
                    message: isStripe
                        ? 'You have to connect to Stripe to activate Stripe addons.'
                        : 'You have to connect to Square to activate Square addons.',
                    type: LIST_STATUS.ERROR
                });
            }

            return;
        }

        if (ADDONS_ACTIVE_WITH_CONDITION_KEY.includes(keyword) || (keyword === INBOX_EMAIL && !!locked)) {
            e.preventDefault();
            history.push(addBranchPath(ADDONS_ACTIVE_WITH_CONDITION_DATA[keyword].url));
            return;
        }

        if (keyword === VOIP) {
            e.preventDefault();
            return;
        }

        const value = e.target.checked ? 1 : 0;
        dispatchState({ active: !!value });

        const _handleUpdateSuccess = ({ data }) => {
            handleTrackingEvent(mixpanelManageAddons({ id: profileId }));
            dispatch(updateAddonStatus({ keyword, data: value ? data[keywordLowerCased] : 0 }));
            if (keyword === INBOX_EMAIL) {
                dispatch(getInboxCounterRequest());
                removeLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX));
            }
        };

        const _handleUpdateFail = (err) => {
            dispatchState({ active: !value });
            checkAccessFail(err);
        };

        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            onChange(id, value);
            clientQuery(
                LIST_API_UPDATE_ADDONS_OPTIONS[keyword],
                { data: { value }, method: 'PUT' },
                _handleUpdateSuccess,
                _handleUpdateFail
            );
        }, 300);
    };

    const _handleConfirm = () => {
        isStripe ? refDisconnectStripe.current._open() : refDisconnectSquare.current._open();
    };

    const _handleConfirmDisconnect = () => {
        // 0 is off addons
        const value = 0;
        dispatchState({ active: false });

        const _handleUpdateSuccess = ({ data }) => {
            handleTrackingEvent(mixpanelManageAddons({ id: profileId }));
            dispatch(updateAddonStatus({ keyword, data: value ? data[keywordLowerCased] : 0 }));
        };

        onChange(id, value);
        clientQuery(LIST_API_UPDATE_ADDONS_OPTIONS[keyword], { data: { value }, method: 'PUT' }, _handleUpdateSuccess);
    };

    /** It sets the default merchant for the user. */
    const _handleSetDefaultMerchant = () => clientQuery(ADDONS_MERCHANT_DEFAULT, { data: { keyword }, method: 'PUT' });

    return (
        <>
            <div ref={refBox} className={classNames('boxs', { 'is-active': active })}>
                <div className="boxs__header">
                    {!isZapier && !isMultiBranch && (
                        <div className="switch large">
                            <input
                                ref={refCheckbox}
                                id={`toggle_${keyword}`}
                                className="toggle toggle-round"
                                type="checkbox"
                                onChange={_handleChange}
                                checked={!!active}
                            />
                            <label htmlFor={`toggle_${keyword}`} />
                            <p className="switch__label is-label-off">Off</p>
                            <p className="switch__label is-label-on">On</p>
                        </div>
                    )}
                    <div className="icon">
                        <div className="icon__main icon-active">{LIST_ICONS_ADDONS[keyword]}</div>
                        <div className="icon__main icon-inactive">{LIST_ICONS_ADDONS_INACTIVE[keyword]}</div>
                    </div>
                </div>

                <div className="boxs__contents">
                    <div className="title">
                        <Fragment>
                            <Link to={addBranchPath(linkToAddonsDetail)}>{name}</Link>
                            {isBeta && <div className="status-btn --beta ml-1">{t('addons:beta')}</div>}
                        </Fragment>
                    </div>

                    <div className="description mt-1">{sub_title}</div>
                </div>

                <div className={classNames('boxs__last-items', { 'has-pro-btn': isProAddon || isGrowthAddon })}>
                    {isMerchant && (
                        <>
                            <div className={classNames('check-radio', { 'is-disable': !status })}>
                                <input
                                    id={`check-radio-default-${keyword}`}
                                    type="radio"
                                    defaultChecked={!!merchant_default}
                                    name="check-radio-default"
                                    onChange={_handleSetDefaultMerchant}
                                />
                                <label htmlFor={`check-radio-default-${keyword}`}>
                                    {t('addons:set_as_default_merchant')}
                                </label>
                            </div>
                            <div className="tooltip">
                                <IconTooltip />
                                <span className="tooltiptext top">{t('tool_tip_merchant')}</span>
                            </div>
                        </>
                    )}

                    {isProAddon && (
                        <div className="status-btn --has-icon --pro">
                            <IconUpgrade isAddonUpgrade />
                            {t(!!upgrade ? 'pro_upgrade' : 'pro_addon')}
                        </div>
                    )}
                    {isGrowthAddon && (
                        <div className="status-btn --has-icon growth-plan">
                            <IconGrowth />
                            {t(!!upgrade ? 'growth_upgrade' : 'growth_addon')}
                        </div>
                    )}
                </div>
            </div>

            {isStripe && (
                <StripeAddonsDisconnect ref={refDisconnectStripe} onDisconnectSuccess={_handleConfirmDisconnect} />
            )}

            {keyword === SQUARE && (
                <SquareAddonsDisconnect ref={refDisconnectSquare} onDisconnectSuccess={_handleConfirmDisconnect} />
            )}
        </>
    );
};

export default AddonsItem;
