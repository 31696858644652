import React from 'react';

export default function Loading() {
    return (
        <div className="contents-pages container-column container-print invoices-details">
            <div className="wrap-loading scrolls">
                <div className="title-header">
                    <div className="loading --line --animation --onetenth" />
                </div>
                <div className="boxs-wrapper">
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="loading --line --animation --half" />
                            <div className="btn-action">
                                <div className="loading v2-btn-default --icon-lg">
                                    <div className="loading --line --animation --half" />
                                </div>
                                <div className="loading v2-btn-default --icon-lg">
                                    <div className="loading --line --animation --half" />
                                </div>
                            </div>
                        </div>
                        <div className="boxs__content">
                            <div className="rows">
                                <div className="loading --line --animation --half" />
                                <div className="loading --line --animation --threefourth" />
                            </div>
                        </div>
                        <div className="boxs__content pt-2 pb-2">
                            <div className="rows --multi">
                                <div className="txt">
                                    <div className="loading --line --animation --half" />
                                </div>
                                <div className="details">
                                    <div className="loading --line --animation --full" />
                                    <div className="loading --line --animation --twothird mt-1" />
                                    <div className="loading --line --animation --full mt-4" />
                                    <div className="loading --line --animation --full mt-1" />
                                    <div className="loading --line --animation --half mt-1" />
                                </div>
                            </div>
                            <div className="rows --multi">
                                <div className="txt">
                                    <div className="loading --line --animation --half" />
                                </div>
                                <div className="details">
                                    <div className="loading --line --animation --twothird" />
                                </div>
                            </div>
                            <div className="rows --multi">
                                <div className="txt">
                                    <div className="loading --line --animation --fourth" />
                                </div>
                                <div className="details">
                                    <div className="loading --line --animation --half" />
                                </div>
                            </div>
                            <div className="rows --multi">
                                <div className="txt">
                                    <div className="loading --line --animation --threefourth" />
                                </div>
                                <div className="details">
                                    <div className="loading --line --animation --full" />
                                    <div className="loading --line --animation --onefourth mt-1" />
                                    <div className="loading --line --animation --onefifth mt-4" />
                                    <div className="loading --line --animation --threefourth mt-4" />
                                </div>
                            </div>
                            <div className="rows --multi">
                                <div className="txt">
                                    <div className="loading --line --animation --half" />
                                </div>
                                <div className="details">
                                    <div className="loading --line --animation --twothird" />
                                    <div className="loading --line --animation --half mt-2" />
                                </div>
                            </div>
                        </div>
                        <div className="boxs__footer">
                            <div className="tables">
                                <div className="rows --fixed --header">
                                    <div className="col --description">
                                        <div className="loading --line --animation --twothird" />
                                    </div>
                                    <div className="col --qty">
                                        <div className="loading --line --animation --threefourth" />
                                    </div>
                                    <div className="col --amount --label-r">
                                        <div className="loading --line --animation btn-x-sm" />
                                    </div>
                                </div>
                                <div className="tables-list">
                                    <div className="rows">
                                        <div className="col --description">
                                            <div className="loading --line --animation --onefourth" />
                                        </div>
                                        <div className="col --qty">
                                            <div className="loading --line --animation --onefourth" />
                                        </div>
                                        <div className="col --amount --label-r">
                                            <div className="loading --line --animation btn-sm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="wrap-rows --amount">
                                <div className="rows">
                                    <div className="txt">
                                        <div className="loading --line --animation --threefourth" />
                                    </div>
                                    <div className="details d-flex justify-end">
                                        <div className="loading --line --animation --half" />
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt">
                                        <div className="loading --line --animation --twothird" />
                                    </div>
                                    <div className="details">
                                        <div className="info-card">
                                            <div className="card-number">
                                                <p className="card-number__label" />
                                                <span className="number" />
                                                <span className="date" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
