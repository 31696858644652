import { call, put } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { GET_LIST_PHONE_TYPES } from 'app/const/Api';
import { PHONE_TYPE_ACTIONS } from 'common/redux/actions/phones';

export function* getListPhoneTypes({ defaultSelected, actionSuccess = () => {}, actionFailed = () => {} }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_PHONE_TYPES);
        const phoneSelect = defaultSelected || [{ id: new Date().getTime(), phone: '', type: response.data[0] }];

        yield put({
            type: PHONE_TYPE_ACTIONS.SET_STORE_LIST_PHONE_TYPES,
            payload: { phoneSelect: phoneSelect, phoneTypes: response.data }
        });
        actionSuccess(phoneSelect);
    } catch (error) {
        yield put({ type: PHONE_TYPE_ACTIONS.CALL_API_FAILURE });
        actionFailed(error);
    }
}
