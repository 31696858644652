import { customerSaveQB, customerSearchQB } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import GdGridView from '../grid/GdGridView';
import { getGridColumns } from './const/QuickBooksTable';
import IconClose from 'assets/icon/IconClose';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/Status';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { reducer } from 'app/const/Reducer';
import { DELETE_INVOICE_DUPLICATE, SEARCH_INVOICE_DUPLICATE } from 'app/const/api/V2';
import { getGridQuickBookInvoiceColumns } from './const/QuickBooksInvoiceTable';
import { LINK_CDN_IMG } from 'app/const/URL';

const SearchQBModal = forwardRef(
    ({ onSaveId = () => {}, onDeleteSuccess = () => {}, onHandleDeleteInvoiceSuccessQB = () => {} }, ref) => {
        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            isLoading: false,
            data: [],
            checkedItems: null,
            isSearchInvoiceQB: false,
            idInvoice: null,
            invoiceNumber: null
        });
        const refCustomerId = useRef(null);
        const refQuickBookId = useRef(null);
        const refInvoiceId = useRef(null);
        const refAlert = useRef(null);
        const { t } = useTranslation(['customers']);
        const { data: dataQB = [], checkedItems, isSearchInvoiceQB } = state || {};
        const isMatching = !!dataQB.length;

        useImperativeHandle(ref, () => ({ _open, _close }));

        const getInvoiceDuplicate = (idInvoice, invoiceNumber) => {
            dispatchState((prevState) => ({
                ...prevState,
                isOpen: true,
                isLoading: true,
                isSearchInvoiceQB: !!idInvoice,
                idInvoice,
                invoiceNumber
            }));
            clientQuery(
                SEARCH_INVOICE_DUPLICATE,
                { data: { id: [idInvoice] }, method: 'GET' },
                (response) => _success(response, null, null, idInvoice),
                _failed
            );
        };

        const _handleOpenInvoice = (idInvoice, invoiceNumber) => {
            if (idInvoice !== refInvoiceId.current) {
                getInvoiceDuplicate(idInvoice, invoiceNumber);
                return;
            }
            dispatchState({ isOpen: true, isLoading: false, isSearchInvoiceQB: !!idInvoice, idInvoice, invoiceNumber });
        };

        const _open = (qbId, idCustomer, idInvoice, invoiceNumber = null) => {
            if (!idCustomer && !idInvoice) return;

            if (idCustomer) {
                if (idCustomer !== refCustomerId.current || refQuickBookId.current !== qbId || !qbId) {
                    clientQuery(
                        customerSearchQB(idCustomer),
                        { data: qbId ? { keyword: qbId } : {}, method: 'GET' },
                        (response) => _success(response, idCustomer, qbId),
                        _failed
                    );
                }
                dispatchState({ isOpen: true, isLoading: true });
            } else {
                _handleOpenInvoice(idInvoice, invoiceNumber);
            }
        };

        const _success = (response, idCustomer = null, qbId = null, idInvoice = null) => {
            if (idCustomer) {
                refCustomerId.current = idCustomer;
                refQuickBookId.current = qbId;
            }
            if (idInvoice) refInvoiceId.current = idInvoice;
            const responseData = response.data || [];

            dispatchState((prevState) => ({
                ...prevState,
                data: idInvoice
                    ? responseData.map((item) => ({ ...item, name: item.customer.name || '' }))
                    : responseData,
                isLoading: false,
                checkedItems: null
            }));
        };

        const _failed = () => {
            dispatchState({ data: [], isLoading: false });
        };

        const _close = () => {
            dispatchState({ isOpen: false });
        };

        const _handleRowClick = (checkedItems) => {
            dispatchState({ checkedItems });
        };

        const _handleSaveId = () => {
            const { checkedItems } = state;

            if (checkedItems) {
                onSaveId(checkedItems);
                clientQuery(
                    customerSaveQB(refCustomerId.current),
                    { data: { id: checkedItems }, method: 'PUT' },
                    _saveSuccess,
                    _saveFail
                );
            } else {
                dispatchState({ isOpen: false });
            }
        };

        const _handleDeleteInvoice = (callback) => {
            clientQuery(
                DELETE_INVOICE_DUPLICATE,
                {
                    data: { id: state.idInvoice, qb_id: checkedItems },
                    method: 'PUT'
                },
                (response) => _deleteSuccess(response, callback)
            );
        };

        const _saveSuccess = () => {
            refCustomerId.current = null;
            dispatchState({ isOpen: false, checkedItems: null });
        };

        const _saveFail = ({ message }) => {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: message || '',
                type: LIST_STATUS.ERROR
            });
        };

        const _deleteSuccess = ({ message }, callback = () => {}) => {
            callback();
            const itemChecked = dataQB.find((item) => item.id === checkedItems);
            const newData = [...dataQB].filter((item) => !checkedItems.includes(item.id));

            dispatchState({
                data: newData,
                checkedItems: null
            });

            refAlert.current.showStatusBar({
                id: LIST_STATUS.SUCCESS,
                message: message || '',
                type: LIST_STATUS.SUCCESS
            });

            onDeleteSuccess(itemChecked, false);
            !newData.length && onHandleDeleteInvoiceSuccessQB(itemChecked, false);
        };

        if (!state.isOpen) return null;
        return (
            <ReactModal
                isOpen
                id="modal_findQB"
                style={{ overlay: { background: 'transparent' } }}
                onRequestClose={_close}
                className="modal container-modal modal-find-qb selected-qb-id open"
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className={classNames('modal__container', { '--sm': !isMatching })}>
                    <div className="header-modal btn-close">
                        <div className="logo">
                            <img src={`${LINK_CDN_IMG}quickbooks_logo.png`} width={195} height={37} alt="logoQB" />
                        </div>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </span>
                    </div>
                    <AlertCustomer ref={refAlert} />
                    <div className="body-modal container-print">
                        {state.isLoading ? (
                            <div className="preloader">
                                <div className="loader-wave">
                                    <span className="loader-wave__items" />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="label-select-qb">
                                    {!isSearchInvoiceQB && (
                                        <>
                                            <p className="label-select-qb__txt">
                                                {isMatching
                                                    ? t('select_the_matching_id')
                                                    : t('msg_not_found_quickbook')}
                                            </p>
                                            {!!checkedItems && (
                                                <div className="btn-save-id v2-btn-main" onClick={_handleSaveId}>
                                                    {t('customers:save_id')}
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {isSearchInvoiceQB && !isMatching ? (
                                        <p className="label-select-qb__txt">{t('msg_not_found_quickbook')}</p>
                                    ) : null}
                                </div>

                                {isMatching ? (
                                    isSearchInvoiceQB ? (
                                        <GdGridView
                                            content={dataQB}
                                            isLoading={state.isLoading}
                                            classTable="scrolls-x fs-13"
                                            fileTranslation="report"
                                            checkedItems={checkedItems}
                                            onRowClick={_handleRowClick}
                                            handleClick={_handleDeleteInvoice}
                                            isSelectRow
                                            {...getGridQuickBookInvoiceColumns()}
                                        />
                                    ) : (
                                        <GdGridView
                                            content={dataQB}
                                            classTable="scrolls-x fs-13"
                                            fileTranslation="report"
                                            checkedItems={checkedItems}
                                            onRowClick={_handleRowClick}
                                            isSelectRow
                                            {...getGridColumns()}
                                        />
                                    )
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default SearchQBModal;
