import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';
import { TABS_FILTER } from '../report/ReportFilter';

export const getGridColumnsCustomerLocation = (columns = [], isShowColumns = {}, actionsStatus, filterTab = 1) => {
    const isTabDeleted = filterTab === TABS_FILTER.DELETE_CUSTOMER_LOCATION.value;

    const HEADER_LOCATION = {
        location_name: {
            title: isTabDeleted ? 'deleted_address' : 'location',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        address_to: {
            title: 'address_to',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address: {
            title: 'service_address',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        bill_to: {
            title: 'bill_to',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_email: {
            title: 'billing_email',
            style: 'col col-md'
        },
        billing_email_cc: {
            title: 'billing_email_cc',
            style: 'col col-md'
        },
        notes: {
            title: 'notes',
            style: 'col'
        },
        units: {
            title: 'units',
            style: 'col col-xs --units'
        },
        sms: {
            title: 'sms',
            style: 'col'
        },
        email: {
            title: 'email',
            style: 'col col-md-plus'
        },
        tags: {
            title: 'tags',
            style: 'col --tags'
        },
        taxes: {
            title: 'tax',
            style: 'col'
        },
        active_services: {
            title: 'active_services',
            style: 'col col-lg --services'
        }
    };
    const LOCATION_COLUMNS = [
        {
            id: 'location_name',
            title: 'location_name',
            status: true
        },
        {
            id: 'address_to',
            title: 'address_to',
            status: true
        },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'bill_to',
            title: 'bill_to',
            status: true
        },
        {
            id: 'billing_email',
            title: 'billing_email',
            status: true
        },
        {
            id: 'billing_email_cc',
            title: 'billing_email_cc',
            status: true
        },
        {
            id: 'notes',
            title: 'notes',
            status: true
        },
        {
            id: 'units',
            title: 'units',
            status: true
        },
        {
            id: 'sms',
            title: 'sms',
            status: true
        },
        {
            id: 'email',
            title: 'email',
            status: true
        },
        {
            id: 'tags',
            title: 'tags',
            status: true
        },
        {
            id: 'taxes',
            title: 'taxes',
            status: true
        },
        {
            id: 'active_services',
            title: 'active_services',
            status: true
        }
    ];
    const LOCATION_COLUMNS_CONTENT = {
        location_name: {
            title: 'location_name',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.LOCATION_ADDRESS,
            isDelete: isTabDeleted
        },
        address_to: {
            title: 'address_to',
            style: 'col col-md'
        },
        service_address: {
            title: 'service_address',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS
        },
        bill_to: {
            title: 'bill_to',
            style: 'col'
        },
        billing_email: {
            title: 'billing_email',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.BILLING_EMAIL
        },
        billing_email_cc: {
            title: 'billing_email_cc',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.BILLING_EMAIL
        },
        notes: {
            title: 'notes',
            style: 'col ',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        units: {
            title: 'units',
            style: 'col col-xs --units',
            type: LIST_TABLE_ITEM_TYPE.LOCATION_UNITS
        },
        sms: {
            title: 'sms',
            style: 'col'
        },
        email: {
            title: 'email',
            style: 'col col-md-plus'
        },
        tags: {
            title: 'tags',
            style: 'col --tags',
            type: LIST_TABLE_ITEM_TYPE.LOCATION_TAGS
        },
        taxes: {
            title: 'taxes',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LOCATION_TAXES
        },
        active_services: {
            title: 'active_services',
            style: 'col col-lg --services',
            type: LIST_TABLE_ITEM_TYPE.LOCATION_ACTIVE_SERVICE
        }
    };

    const { isShowUnit, isShowSms } = isShowColumns;
    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_LOCATION });
    const newContent = {};
    const newColumns = [];

    LOCATION_COLUMNS.forEach((col) => {
        const item = col.id;
        if (item === LIST_TABLE_ITEM_TYPE.UNITS) {
            col.status = !!isShowUnit;
        }

        if (item === LIST_TABLE_ITEM_TYPE.SMS) {
            col.status = !!isShowSms;
        }

        if (columns.includes(item)) {
            newHeader[item] = HEADER_LOCATION[item];
            newContent[item] = LOCATION_COLUMNS_CONTENT[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
