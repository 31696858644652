import i18n from 'assets/i18n';

export const CUSTOM_FIELDS_VIEWS = { ACTIVE: 'active', ARCHIVED: 'archived', DELETED: 'deleted' };
export const CUSTOM_FIELDS_VIEWS_VALUES = {
    [CUSTOM_FIELDS_VIEWS.ACTIVE]: 1,
    [CUSTOM_FIELDS_VIEWS.ARCHIVED]: 2,
    [CUSTOM_FIELDS_VIEWS.DELETED]: 3
};
export const DEFAULT_TOTAL_CUSTOM_FIELDS = {
    [CUSTOM_FIELDS_VIEWS.ACTIVE]: 0,
    [CUSTOM_FIELDS_VIEWS.ARCHIVED]: 0,
    [CUSTOM_FIELDS_VIEWS.DELETED]: 0
};
export const CUSTOM_FIELDS_TABS = {
    [CUSTOM_FIELDS_VIEWS.ACTIVE]: {
        label: i18n.t('active_with_ct', { count: 0 }),
        value: CUSTOM_FIELDS_VIEWS.ACTIVE
    },
    [CUSTOM_FIELDS_VIEWS.ARCHIVED]: {
        label: i18n.t('archived_with_ct', { count: 0 }),
        value: CUSTOM_FIELDS_VIEWS.ARCHIVED
    },
    [CUSTOM_FIELDS_VIEWS.DELETED]: {
        label: i18n.t('deleted_with_ct', { count: 0 }),
        value: CUSTOM_FIELDS_VIEWS.DELETED
    }
};
export const CUSTOM_FIELDS_TYPES = {
    TEXT: 'text',
    NUMBER: 'number',
    CHOICES: 'choices',
    DATE: 'date',
    DATE_TIME: 'date_time'
};
export const KEY_CUSTOM_FIELDS_MULTIPLE = 'accepts_multiple_values';
export const CUSTOM_FIELDS_LIST = [
    {
        id: 'text',
        label: i18n.t(CUSTOM_FIELDS_TYPES.TEXT),
        value: CUSTOM_FIELDS_TYPES.TEXT,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: false
    },
    {
        id: 'number',
        label: i18n.t(CUSTOM_FIELDS_TYPES.NUMBER),
        value: CUSTOM_FIELDS_TYPES.NUMBER,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: false
    },
    {
        id: 'choices',
        label: i18n.t(CUSTOM_FIELDS_TYPES.CHOICES),
        value: CUSTOM_FIELDS_TYPES.CHOICES,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: false
    },
    {
        id: 'choices_multiple',
        label: i18n.t('choices_multiple'),
        value: CUSTOM_FIELDS_TYPES.CHOICES,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: true
    },
    {
        id: 'date',
        label: i18n.t(CUSTOM_FIELDS_TYPES.DATE),
        value: CUSTOM_FIELDS_TYPES.DATE,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: false
    },
    {
        id: 'date_time',
        label: i18n.t(CUSTOM_FIELDS_TYPES.DATE_TIME),
        value: CUSTOM_FIELDS_TYPES.DATE_TIME,
        [KEY_CUSTOM_FIELDS_MULTIPLE]: false
    }
];

export const CUSTOM_FIELDS_ACTIONS = {
    MULTIPLE_ADD: 'multiple_add',
    MULTIPLE_HANDLE: 'multiple_handle',
    ADD: 'add',
    EDIT: 'edit',
    // using in manage custom fields page
    DELETE: 'delete',
    UN_DELETE: 'un_delete',
    ARCHIVE: 'archive',
    ACTIVE: 'active',
    PERMANENTLY_DELETE: 'permanently_delete'
};

export const CUSTOM_FIELDS_ACTION_VALUES = {
    [CUSTOM_FIELDS_ACTIONS.ACTIVE]: 1,
    [CUSTOM_FIELDS_ACTIONS.ARCHIVE]: 2,
    [CUSTOM_FIELDS_ACTIONS.UN_DELETE]: 3,
    [CUSTOM_FIELDS_ACTIONS.DELETE]: 4,
    [CUSTOM_FIELDS_ACTIONS.PERMANENTLY_DELETE]: 5
};
