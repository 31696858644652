import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import Checkbox from 'app/components/checkbox';
import GridLoading from 'app/components/grid/components/GridLoading';
import GDModalWarning from 'app/components/modal/ModalWarning';
import StatusBar from 'app/components/status/statusbar';
import { ADDONS_SMS, ADDONS_VOIP } from 'app/config/routes';
import { BLOCK_PHONE_NUMBER } from 'app/const/Api';
import { AVATAR_DEFAULT, DEFAULT_PAGE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { LIST_STATUS } from 'app/const/Status';
import { VOIP } from 'app/const/addons';
import { getDefaultParams } from 'app/const/report/ReportParams';
import ErrorPage from 'app/modules/error';
import ReportPagination from 'app/modules/report/components/ReportPagination';
import ReportSearch from 'app/modules/report/components/ReportSearch';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatNumberWithCommas } from 'common/utils/NumberUtils';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';

const BlockNumbers = () => {
    const { t } = useTranslation(['addons', 'common']);

    const keyBlockNumberLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.BLOCK_NUMBERS);
    const initState = {
        data: [],
        checkedItems: { ids: [] },
        total: 0,
        totalPage: 1,
        keyword: '',
        isLoading: true,
        currentPage: DEFAULT_PAGE
    };

    const { state: stateLocation } = useLocation();
    const [state, dispatchState] = useReducer(reducer, initState);
    const settings = useSelector(({ auth }) => auth.user.settings);
    const isHaveVoip = checkIsEnableVoip(settings);
    const isActiveSMS = !!settings?.addons?.smsplivo;
    const isPermissionBlockedNumbers = isHaveVoip || isActiveSMS;

    const { data: finalData, checkedItems: finalCheckedItems, total, totalPage, isLoading: finalIsLoading } = state;
    const listCheckedIds = finalCheckedItems.ids || [];
    const refWarning = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef();
    const refIdBlockNumber = useRef(null);

    useEffect(() => {
        if (!isPermissionBlockedNumbers) return;
        _fetchBlockedNumbers();

        return () => removeLocalStorage(keyBlockNumberLocal);
    }, []);

    const _showAlert = ({ message, success }) => {
        refStatusBar.current?.showStatusBar(
            'block_number',
            message,
            !!success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR
        );
    };

    const _fetchBlockedNumbers = () => {
        let params = getLocalStorage(keyBlockNumberLocal);
        if (!params) {
            params = getDefaultParams(REPORT_TYPE.BLOCK_NUMBERS);
            setLocalStorage(keyBlockNumberLocal, params);
        }

        const _handleSuccess = ({ data, total }) => {
            dispatchState((prev) => ({
                ...prev,
                data: data || prev.data,
                total,
                isLoading: false,
                checkedItems: { ids: [] },
                totalPage: Math.ceil(total / params.limit)
            }));
        };

        const _handleFail = ({ message, success }) => {
            _showAlert({ message: !!message.length ? message : t('addons:action_failure'), success });
            dispatchState((prev) => ({
                ...prev,
                isLoading: false
            }));
        };

        clientQuery(BLOCK_PHONE_NUMBER, { data: { ...params }, method: 'GET' }, _handleSuccess, _handleFail);
    };

    const _handleUpdate = () => {
        dispatchState((prev) => ({ ...prev, isLoading: true }));
        _fetchBlockedNumbers();
    };

    const _handleOnClickCheckItem = (isCheckAll = false, e) => {
        const { checked, id } = e.target;
        dispatchState((prev) => ({
            ...prev,
            checkedItems: {
                ids: isCheckAll
                    ? []
                    : checked
                      ? [...prev.checkedItems.ids, id]
                      : prev.checkedItems.ids.filter((option) => option !== id)
            }
        }));
    };

    const _handleCloseConfirm = () => refWarning.current._close();

    const _handleConfirm = () => {
        const listIds = refIdBlockNumber.current?.value ? [refIdBlockNumber.current?.value] : listCheckedIds;
        const isMultiIds = listIds.length > 1;

        const _handleSuccess = ({ message }) => {
            _showAlert({
                message: !!message.length
                    ? message
                    : isMultiIds
                      ? t(`addons:numbers_unblock_successfully`)
                      : t(`addons:number_unblock_successfully`),
                success: true
            });
            _handleCloseConfirm();
            refIdBlockNumber.current = null;
            _fetchBlockedNumbers();
        };

        const _handleFail = ({ message }) => {
            refButtonSave.current.removeLoading();
            refWarning.current._setStatusAlert(
                !!message.length
                    ? message
                    : isMultiIds
                      ? t(`addons:numbers_unblock_failed`)
                      : t(`addons:number_unblock_failed`)
            );
        };

        clientQuery(BLOCK_PHONE_NUMBER, { data: { ids: listIds }, method: 'DELETE' }, _handleSuccess, _handleFail);
    };

    const _handleUnBlockMultiNumber = (id) => {
        if (!!id) refIdBlockNumber.current = { value: id };

        const isMultiCheckedItems = listCheckedIds.length > 1 && !id;
        refWarning.current._open({
            title: isMultiCheckedItems ? t('unblock_numbers') : t('unblock_number'),
            description: isMultiCheckedItems
                ? t('description_unblock_phone_numbers')
                : t('description_unblock_phone_number')
        });
    };

    const _renderData = () => {
        if (finalIsLoading) return <GridLoading />;
        if (!finalData.length) return <div className="content-empty">{t('common:there_is_no_data')}</div>;

        return (
            <div className="tables__contents">
                {finalData.map((data) => (
                    <RenderItem
                        key={data.id}
                        item={data}
                        user={getUserInfo(data?.user_id)}
                        checked={listCheckedIds.includes(data.id)}
                        onClickCheckItem={_handleOnClickCheckItem}
                        handleUnBlockMultiNumber={_handleUnBlockMultiNumber}
                    />
                ))}
            </div>
        );
    };

    const _handleBackAddons = () => {
        let path = isActiveSMS ? ADDONS_SMS : ADDONS_VOIP;
        if (stateLocation?.key) path = stateLocation.key === VOIP ? ADDONS_VOIP : ADDONS_SMS;
        return addBranchPath(path);
    };

    if (!isPermissionBlockedNumbers) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;

    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container addons-wrap__blocked">
                <div className="header">
                    <Link to={_handleBackAddons()} className="v2-btn-default has-icon --grey">
                        <IconArrow isPrev />
                        {t('back')}
                    </Link>
                    <ReportSearch
                        reportType={REPORT_TYPE.BLOCK_NUMBERS}
                        placeholder={t('search')}
                        onKeyEnter={_handleUpdate}
                    />
                </div>
                <StatusBar ref={refStatusBar} />
                <div className="scrolls">
                    <div className="boxs-wrapper">
                        <div className="wrap-tables flex-column relative">
                            <div className="tables">
                                <div
                                    className={classNames('tables__header flexcenter gap-6 flex-wrap', {
                                        'is-disable': listCheckedIds.length === 0
                                    })}
                                >
                                    <div className="header-items check-items --uncheck flexcenter">
                                        <input
                                            id="block_number_list_cb"
                                            type="checkbox"
                                            checked={!!listCheckedIds.length}
                                            onChange={(e) => _handleOnClickCheckItem(true, e)}
                                        />
                                        <div className="item-checkbox">
                                            <label htmlFor="block_number_list_cb">
                                                <span />
                                            </label>
                                        </div>
                                        <span className="flexcenter black-jungle-green fw-600">
                                            <span className="black-darker">{listCheckedIds.length || 0}</span>
                                            <span>/{formatNumberWithCommas(total)}</span>
                                        </span>
                                    </div>
                                    <div
                                        className={classNames('v2-btn-default', {
                                            'is-disable': !listCheckedIds.length
                                        })}
                                        onClick={() => _handleUnBlockMultiNumber()}
                                    >
                                        {t('unblock')}
                                    </div>
                                </div>
                                {_renderData()}
                            </div>
                            <ReportPagination
                                reportType={REPORT_TYPE.BLOCK_NUMBERS}
                                totalPage={totalPage || 1}
                                onSelect={_handleUpdate}
                            />
                        </div>
                    </div>
                    <GDModalWarning
                        ref={refWarning}
                        footer={
                            <div className="footer-modal btn-close justify-end">
                                <div className="v2-btn-default --transparent" onClick={_handleCloseConfirm}>
                                    {t('cancel')}
                                </div>
                                <ButtonSave
                                    ref={refButtonSave}
                                    title={t('yes')}
                                    wrapClass="v2-btn-main ml-2"
                                    onSave={_handleConfirm}
                                />
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default BlockNumbers;

const RenderItem = ({
    item = {},
    user = {},
    checked = false,
    onClickCheckItem = () => {},
    handleUnBlockMultiNumber = () => {}
}) => {
    const { t } = useTranslation(['addons']);
    const { id, phone, location, created_date } = item;

    return (
        <div className="rows">
            <div className="col-checkbox">
                <Checkbox checked={checked} value={id} id={id} onChangeValue={(e) => onClickCheckItem(false, e)} />
            </div>
            <div className="col-phone">
                <div className="flexcenter">
                    <span className="txt-ellipsis mr-1">{phone}</span>
                    {location?.state ? (
                        <span className="status-btn --sm bg-black-verydark black">{location?.state}</span>
                    ) : null}
                </div>
            </div>
            <div className="col-customer">
                <div className="flexcenter gap-6">
                    <div className="avt-img">
                        <img src={user?.avatar || AVATAR_DEFAULT} alt="" width="24" height="24" />
                    </div>
                    <span className="col-label txt-ellipsis">{user?.full_name}</span>
                </div>
            </div>
            <div className="col-time">
                <p className="col-label txt-ellipsis" title={created_date}>
                    {created_date}
                </p>
            </div>
            <div className="col-icon">
                <div className="v2-btn-default --sm" onClick={() => handleUnBlockMultiNumber(id)}>
                    {t('unblock')}
                </div>
            </div>
        </div>
    );
};
