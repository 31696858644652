import { TYPE_PICKER_CALENDAR_ACTION } from 'common/redux/actions/calendar/inlineCalendarAction';
import { getRange } from 'common/utils/DateUtils';

const inlineCalendar = (state = {
    start: null,
    end: null,
    rangeTime: [],
    rangeTimeOptimize: null
}, action) => {
    switch (action.type) {
        case TYPE_PICKER_CALENDAR_ACTION.ACTION_SET_START_END_PICKER_CALENDAR:
            if (action.params.start && action.params.end)
                return { ...state, ...action.params, rangeTime: getRange(action.params.start, action.params.end) };
            return {
                ...action.params
            };
        default:
            return state;
    }
};

export default inlineCalendar;
