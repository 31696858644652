import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionCheckPipelines } from 'app/const/api/V2';
import { LIST_STATUS } from 'app/const/App';
import { deletePipelines } from 'common/redux/actions/settings/pipelinesAction';
import ButtonSave from 'app/components/button/ButtonSave';
import { LoadingModalDelete } from './Loading';

const ModalDeletePipeline = ({ onShowAlert = () => {}, onFail = () => {} }, ref) => {
    const { t } = useTranslation('setting');
    const dispatch = useDispatch();

    const pipelines = useSelector(({ pipelines }) => pipelines.data);

    const initialState = {
        idPipeline: '',
        idGroup: '',
        isOpen: false,
        number: 0,
        selected: null,
        data: [],
        isLoading: true
    };

    const [state, dispatchState] = useReducer(reducer, initialState);

    const {
        idPipeline: finalIdPipeline,
        idGroup: finalIdGroup,
        isOpen: finalIsOpen,
        number: finalNumber,
        selected: finalSelected,
        data: finalData,
        isLoading: finalIsLoading
    } = state;

    useImperativeHandle(ref, () => ({
        _openModal,
        _closeModal
    }));

    const checkPipeline = ({ id, idGroup }) => {
        const _handleCheckSuccess = ({ data: { total } }) => {
            dispatchState({ number: total, isLoading: false });
        };

        clientQuery(actionCheckPipelines(idGroup, id), { method: 'PUT' }, _handleCheckSuccess, onFail);
    };

    const _openModal = ({ id, idGroup }) => {
        dispatchState({
            isOpen: true,
            number: 0,
            idPipeline: id,
            idGroup,
            data: pipelines.find((item) => item.id === idGroup)?.pipelines,
            isLoading: true
        });

        checkPipeline({ id, idGroup });
    };

    const _closeModal = () => {
        dispatchState(initialState);
    };

    const _handleSelected = (value) => {
        dispatchState({ selected: finalData.find((item) => item.id === value) });
    };

    const _handleDelete = () => {
        const _handleSuccess = ({ message }) => {
            onShowAlert({ message, type: LIST_STATUS.SUCCESS });
            _closeModal();
        };

        const _handleFail = ({ message }) => {
            onShowAlert({ message });
            _closeModal();
        };

        const params = { id: finalIdPipeline, idGroup: finalIdGroup };
        if (!!finalNumber) params['replace_id'] = finalSelected?.id;

        dispatch(deletePipelines(params, _handleSuccess, _handleFail));
    };

    if (!finalIsOpen) return null;

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            id={'modal-delete-pipeline'}
            className={'modal container-modal modal-delete-opportunity open'}
        >
            <div className="modal__overlay bg-fixed" onClick={_closeModal} />
            {finalIsLoading ? (
                <LoadingModalDelete />
            ) : (
                <div className="modal__container large">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('delete_opportunity_status')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_closeModal}>
                            <IconClose />
                        </span>
                    </div>

                    <div className="body-modal">
                        <div className="rows --alert">
                            <p className="fw-600 word-breakall">
                                {t('delete_the_name_status', {
                                    name: finalData.find((item) => item.id === finalIdPipeline)?.name
                                })}
                            </p>
                            {!!finalNumber && (
                                <p>
                                    {finalNumber > 1
                                        ? t('warning_delete_opportunities_status', { number: finalNumber })
                                        : t('warning_delete_opportunity_status', { number: finalNumber })}
                                </p>
                            )}
                        </div>
                        {!!finalNumber && (
                            <div className="rows">
                                <p className="txt-ellipsis fw-600">{t('choose_a_replacement')}:</p>
                                <CalendarDropdown
                                    id="dropdown_replacement"
                                    buttonClassName="dropbtn items selection"
                                    selected={finalSelected?.name || t('select_a_status')}
                                    selectedOption={finalSelected}
                                    options={finalData.filter((pipeline) => pipeline.id !== finalIdPipeline)}
                                    keyGetId="id"
                                    keyGetKey="id"
                                    keyGetName="name"
                                    keyGetValue="id"
                                    wrapperListClass="v2-dropdown__menu scrolls content-full"
                                    onSelect={_handleSelected}
                                />
                            </div>
                        )}
                    </div>

                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_closeModal}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            wrapClass="v2-btn-default has-bg-red"
                            title={t('delete')}
                            onSave={_handleDelete}
                            disabled={(!!finalNumber && !finalSelected) || finalIsLoading}
                            isWhiteLoading
                        />
                    </div>
                </div>
            )}
        </ReactModal>
    );
};

export default forwardRef(ModalDeletePipeline);
