import { takeLatest } from "redux-saga/effects";

// sagas
import {
  getListMessageTemplates,
  getDetailTemplate,
  createMessageTemplate,
  updateMessageTemplate,
  sendTestEmail,
  deleteMessageTemplate,
  duplicateMessageTemplate,
  getListVariableTemplate
} from "./messagetemplates";

import { getListUnsubscribed } from "./unsubscribed";

import {
  getListSmartLists,
  deleteItemSmartLists,
  duplicateItemSmartLists,
  createSmartLists,
  updateSmartLists,

  updateStatusCustomer,
  getListCustomer,
  getDetailFiltersItemSmartList,

  getListFiltersSmartList,
  getListFilterOptionsByURI,

  getListSmartListWithBasicInfo
} from "./smartlists";

import {
  getListCampaigns,
  getListCategories,
  updateStatusCampaign,
  deleteCampaign,
  getDetailCampaign,
  createCampaign,
  updateDetailCampaign,
  duplicateCampaign
} from "./campaigns";

import {
  getReportActivity,
  getReportSummary,
  getReportLink,
  getReportDetail
} from "./report";

// TYPE
import { TYPE_DRIP_MESSAGE_TEMPLATES } from "common/redux/actions/drip/messageTemplatesAction";
import { TYPE_DRIP_UNSUBSCRIBED } from "common/redux/actions/drip/unsubscribedAction";
import { TYPE_DRIP_SMART_LISTS } from "common/redux/actions/drip/smartListsAction";
import { TYPE_DRIP_CAMPAIGNS } from "common/redux/actions/drip/campaignsAction";
import { TYPE_DRIP_REPORT } from "common/redux/actions/drip/reportAction";

// Broadcast
export function* dripGetListMessageTemplatesWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.GET_LIST, getListMessageTemplates);
}
export function* dripGetDetailTemplatesWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.GET_DETAIL_TEMPLATE, getDetailTemplate);
}
export function* dripCreateMessageTemplateWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.CREATE_MESSAGE_TEMPLATE, createMessageTemplate)
}
export function* dripUpdateMessageTemplateWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.UPDATE_MESSAGE_TEMPLATE, updateMessageTemplate)
}
export function* dripSendTestEmailWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.SEND_TEST_EMAIL, sendTestEmail)
}
export function* dripDeleteMessageTemplateWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.DELETE_MESSAGE_TEMPLATE, deleteMessageTemplate)
}
export function* dripDuplicateMessageTemplateWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.DUPLICATE, duplicateMessageTemplate)
}
export function* dripGetListVariableWatcher() {
  yield takeLatest(TYPE_DRIP_MESSAGE_TEMPLATES.GET_LIST_VARIABLE, getListVariableTemplate)
}




// unsubscribed
export function* dripGetListUnsubscribedWatcher() {
  yield takeLatest(TYPE_DRIP_UNSUBSCRIBED.GET_LIST, getListUnsubscribed);
}

// smartList
export function* dripGetListSmartListsWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.GET_LIST, getListSmartLists);
}
export function* dripDeleteItemSmartListsWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.DELETE_ITEM_SMART_LIST, deleteItemSmartLists);
}
export function* dripDuplicateItemSmartListsWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.DUPLICATE_ITEM_SMART_LIST, duplicateItemSmartLists);
}
export function* dripCreateSmartListsWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.CREATE_SMART_LIST, createSmartLists);
}
export function* dripUpdateSmartListsWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.UPDATE_SMART_LIST, updateSmartLists);
}

export function* dripUpdateStatusCustomerWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.UPDATE_TYPE_OF_CUSTOMER, updateStatusCustomer);
}
export function* dripGetListCustomerWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.DRIP_GET_LIST_CUSTOMER, getListCustomer);
}
export function* dripGetDetailFiltersItemSmartListWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.GET_DETAIL_FILTERS, getDetailFiltersItemSmartList);
}

export function* dripGetListFiltersSmartListWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.GET_LIST_FILTERS, getListFiltersSmartList);
}

export function* dripGetListSmartListBasicInfoWatcher() {
  yield takeLatest(TYPE_DRIP_SMART_LISTS.GET_LIST_BASIC, getListSmartListWithBasicInfo);
}


// campaigns
export function* dripGetListCampaignsWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.GET_LIST, getListCampaigns);
}
export function* dripGetListCategoriesWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.GET_LIST_CATEGORIES, getListCategories);
}
export function* dripUpdateStatusCampaignsWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.UPDATE_STATUS_CAMPAIGN, updateStatusCampaign);
}
export function* dripDeleteCampaignsWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.DELETE_CAMPAIGN, deleteCampaign);
}
export function* dripGetDetailCampaignWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.GET_DETAIL_CAMPAIGN, getDetailCampaign);
}

export function* dripCreateNewCampaignWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.CREATE_NEW_CAMPAIGN, createCampaign);
}
export function* dripUpdateDetailCampaignWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.UPDATE_DETAIL_CAMPAIGN, updateDetailCampaign);
}
export function* dripDuplicateCampaignWatcher() {
  yield takeLatest(TYPE_DRIP_CAMPAIGNS.DUPLICATE_CAMPAIGN, duplicateCampaign);
}


// report
export function* dripGetReportActivity() {
  yield takeLatest(TYPE_DRIP_REPORT.GET_REPORT_ACTIVITY, getReportActivity)
}

export function* dripGetReportSummary() {
  yield takeLatest(TYPE_DRIP_REPORT.GET_REPORT_SUMMARY, getReportSummary)
}

export function* dripGetReportLink() {
  yield takeLatest(TYPE_DRIP_REPORT.GET_REPORT_LINK, getReportLink)
}

export function* dripGetReportDetail() {
  yield takeLatest(TYPE_DRIP_REPORT.GET_REPORT_DETAIL, getReportDetail)
}

export function* dripGetListFilterOptionsByURLWatcher() {
  yield takeLatest(
    TYPE_DRIP_SMART_LISTS.GET_LIST_FILTER_OPTIONS_BY_URI,
    getListFilterOptionsByURI
  );
}
