import { takeEvery, takeLatest } from 'redux-saga/effects';
import { TYPE_SMS } from 'common/redux/actions/sms/smsAction';
import { TYPE_CONVERSATION } from 'common/redux/actions/sms/conversationAction';
import { smsGetListPhones, smsGetListByCustomer, smsGetListVariable, smsUpdateStatus } from './sms';
import { smsConversationGetTotal } from './conversation';

export function* smsConversationGetTotalWatcher() {
    yield takeLatest(TYPE_CONVERSATION.SMS_CONVERSATION_GET_TOTAL_REQUEST, smsConversationGetTotal);
}

export function* smsGetListPhonesWatcher() {
    yield takeEvery(TYPE_SMS.SMS_GET_LIST_PHONE_REQUEST, smsGetListPhones);
}

export function* smsGetListByCustomerWatcher() {
    yield takeEvery(TYPE_SMS.SMS_GET_LIST_BY_CUSTOMER_REQUEST, smsGetListByCustomer);
}

export function* smsGetListVariableWatcher() {
    yield takeLatest(TYPE_SMS.SMS_GET_LIST_VARIABLE_REQUEST, smsGetListVariable);
}

export function* smsUpdateStatusWatcher() {
    yield takeLatest(TYPE_SMS.SMS_UPDATE_STATUS_REQUEST, smsUpdateStatus);
}
