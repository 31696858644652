import React from 'react';

export default function Loading() {
    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="wrap-loading header --addon">
                    <div className="header__back v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper">
                        <div className="accessible-tabs-container tabs-sync">
                            <div className="wrap-loading --addon">
                                <div className="tabs --qb mr-0">
                                    <div className="tab-selectors btn-item w-100 mr-0">
                                        <div className="item flex-1">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                        <div className="item btn-sm">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                        <div className="item btn-sm">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-contents mt-5">
                                <div className="tab-content-active">
                                    <div className="wrap-loading --addon">
                                        <div className="screen-sync">
                                            <div className="screen-sync">
                                                <div className="screen-sync__history mb-3">
                                                    <div className="loading --animation --line --threefourth" />
                                                </div>
                                                <div className="screen-sync__history mb-3">
                                                    <div className="loading --animation --line --half" />
                                                </div>
                                                <div className="screen-sync__history mb-3">
                                                    <div className="loading --animation --line --threefourth" />
                                                </div>
                                                <div className="screen-sync__history mb-3">
                                                    <div className="loading --animation --line --fourth" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="wrap-loading --addon mb-3">
                                        <div className="rows">
                                            <div className="rows__status loading --fourth mr-2">
                                                <div className="loading --animation --line --full" />
                                            </div>
                                            <div className="loading --twothird">
                                                <div className="loading --animation --line --full" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="wrap-loading --addon mb-3">
                                        <div className="rows loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="wrap-loading --addon mb-3">
                                        <div className="screen-sync">
                                            <div className="screen-sync__history mb-3">
                                                <div className="loading --animation --line --threefourth" />
                                            </div>
                                            <div className="screen-sync__history mb-3">
                                                <div className="loading --animation --line --half" />
                                            </div>
                                            <div className="screen-sync__history mb-3">
                                                <div className="loading --animation --line --threefourth" />
                                            </div>
                                            <div className="screen-sync__history mb-3">
                                                <div className="loading --animation --line --fourth" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="rows__records loading --half">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                    <div className="rows__user flex-1">
                                        <div className="avt-img loading" />
                                        <div className="flex-1 loading --animation --line --full" />
                                    </div>
                                    <div className="status-btn loading" />
                                </div>
                                <div className="wrap-loading --addon rows">
                                    <div className="rows__records loading --fourth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                    <div className="rows__user flex-1">
                                        <div className="avt-img loading" />
                                        <div className="flex-1 loading --animation --line --full" />
                                    </div>
                                    <div className="status-btn loading" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
