import React, { useEffect, useState } from 'react';
import IconMinus from 'assets/icon/IconMinus';
import IconPlus from 'assets/icon/IconPlus';
import { onKeyDownNumber } from 'common/utils/NumberUtils';
import { JOB_CYCLE_LIMIT } from 'app/const/Job';

const FormInterval = ({ interval, onChange = () => {} }) => {
    const [numberInterVal, setNumberInterVal] = useState(parseInt(interval || 1));

    useEffect(() => {
        if (parseInt(interval) !== numberInterVal) {
            setNumberInterVal(parseInt(interval));
        }
    }, [interval]);

    useEffect(() => {
        if (parseInt(interval) !== numberInterVal) {
            onChange(numberInterVal);
        }
    }, [numberInterVal]);

    function _handleDecrease() {
        const value = numberInterVal - 1;
        _setNumberInterVal(value < 1 ? 1 : value);
    }

    function _handleincrease() {
        _setNumberInterVal(numberInterVal + 1);
    }

    function _handleChangeNumber(e) {
        const value = parseInt(e.target.value || 1);
        if (value > JOB_CYCLE_LIMIT) return;
        _setNumberInterVal(value < 1 ? 1 : value);
    }

    function _setNumberInterVal(value) {
        setNumberInterVal(value);
    }

    return (
        <div className="field-input">
            <span className="v2-btn-default just-icon" onClick={(e) => _handleDecrease(e)}>
                <IconMinus />
            </span>
            <input onKeyDown={onKeyDownNumber} type="number" value={numberInterVal} onChange={_handleChangeNumber} />
            <span className="v2-btn-default just-icon" onClick={(e) => _handleincrease(e)}>
                <IconPlus />
            </span>
        </div>
    );
};

export default FormInterval;
