import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { TYPE_OUT_GOING } from 'app/const/Voip';
import { getSmartViewUrl } from 'app/modules/smartview/components/utils';
import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import IconLoading from 'assets/icon/IconLoading';
import IconMicrophone from 'assets/icon/IconMicrophone';
import IconPhone from 'assets/icon/IconPhone';
import IconPlay from 'assets/icon/IconPlay';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import Dialpad from '../../Dialpad';
import TimeCounter from '../../Time';
import Transfer from '../../Tranfer';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';

const StatusCallDialer = ({
    dialer,
    type,
    count,
    total,
    customer,
    isMuteCall = false,
    callData = {},
    onNavigate = () => {},
    onHangUp = () => {},
    onPauseCall = () => {},
    onLeaveCall = () => {},
    onMuteCall = () => {},
    onTransfer = () => {},
    onCallDial = () => {}
}) => {
    const { t } = useTranslation('header');
    const { pathname } = useLocation();

    const { ACCEPTING, ACTIVE, CALLING, REJECT, BUSY, NEXT_LEAD, PAUSED, PAUSED_EDIT, EMPTY, PREPARE, END, TRANSFER } =
        TYPE_OUT_GOING;
    const { id, name: nameDialer } = dialer;
    const finalCount = count + 1;

    const pathSmartView = getSmartViewUrl(id);

    const _renderButtonHangUp = (isActive = true) => {
        return (
            <div className={classNames('call-bar__action flexcenter pr-2', { 'is-disable': !isActive })}>
                <span className="action-call is-icon bg-red-default flex-centeritem tooltip" onClick={onHangUp}>
                    <IconPhone isHangUp />
                    <span className="tooltiptext bottom">{t('hang_up')} - Ctrl + .</span>
                </span>
            </div>
        );
    };

    const _renderCustomerName = () => {
        if (!customer) return;
        const { company, first_name, last_name } = customer;

        return (
            <div className="call-bar__info flex-1">
                <div className="flexcenter">
                    <p className="fs-16 fw-500 cursor-pointer" onClick={onNavigate}>
                        {`${first_name} ${last_name}`}
                    </p>
                </div>
                {!!company && (
                    <div className="flexcenter mt-2">
                        <span className="txt-ellipsis">{company}</span>
                    </div>
                )}
            </div>
        );
    };

    switch (type) {
        case ACCEPTING:
            return (
                <Fragment>
                    <IconDialer isBlue>
                        <IconPhone isCallOut />
                        {t('out')}
                    </IconDialer>
                    {_renderCustomerName()}
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label">
                            {t('calling_lead_of', { count: finalCount, number: total })}
                        </span>
                    </div>
                    <span className="is-divider" />
                    {_renderButtonHangUp()}
                </Fragment>
            );

        case ACTIVE:
        case CALLING:
            return (
                <Fragment>
                    <IconDialer isBlue>
                        <IconPhone isCallOut />
                        {t('out')}
                    </IconDialer>
                    {_renderCustomerName()}

                    <div className="call-bar__controll gap-md flexcenter">
                        <div
                            className={`v2-btn-default --icon-lg --transparent btn-bg-grey tooltip ${
                                isMuteCall ? 'microphone-muted' : ''
                            }`}
                            onClick={() => onMuteCall(callData.id, !isMuteCall)}
                        >
                            <IconMicrophone />
                            <span className="tooltiptext bottom" id="tooltip_mute">
                                {t('mute_microphone')}
                            </span>
                        </div>
                        <Transfer onTransfer={onTransfer} callData={callData} />
                        <Dialpad onCallDial={onCallDial} callId={callData.id} />
                    </div>
                    <span className="is-divider" />
                    <div className="call-bar__action flexcenter pr-2">
                        <TimeCounter isUseTime />
                        {_renderButtonHangUp()}
                    </div>
                </Fragment>
            );

        case END:
        case PAUSED_EDIT:
            return (
                <Fragment>
                    <IconDialer>
                        <IconPhone />
                    </IconDialer>
                    {_renderCustomerName()}
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label">{t('paused_while_editing')}</span>
                    </div>
                    <span className="is-divider" />
                    <div className="call-bar__action flexcenter pr-2">
                        <span className="action-call is-icon flex-centeritem tooltip" onClick={onPauseCall}>
                            <IconPlay isPaused />
                            <span className="tooltiptext bottom">{t('paused_calling_leads')} - Ctrl + .</span>
                        </span>
                    </div>
                </Fragment>
            );

        case PAUSED:
            return (
                <Fragment>
                    <IconDialer>
                        <IconPlay isPaused />
                    </IconDialer>
                    <div className="call-bar__info flex-1">
                        <div className="flexcenter">
                            <p className="fs-16 fw-500">
                                {t(`lead${finalCount > 1 ? 's' : ''}_have_been_called`, {
                                    count: finalCount,
                                    number: total
                                })}
                            </p>
                        </div>
                        <div className="flexcenter mt-2">
                            <NavLink
                                to={{
                                    pathname: addBranchPath(pathSmartView),
                                    state: {
                                        hardReload: !matchPath(pathname, {
                                            path: addPrefixPath(pathSmartView),
                                            exact: true
                                        })
                                    }
                                }}
                                className="v2-btn-default --grey has-icon"
                            >
                                <IconArrow isPrev />
                                {t('back_to_smart_view', { name: nameDialer })}
                            </NavLink>
                        </div>
                    </div>

                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label">{t('paused')}</span>
                    </div>
                    <span className="is-divider" />
                    <div className="call-bar__action flexcenter pr-2">
                        <span className="action-call is-icon flex-centeritem tooltip" onClick={onLeaveCall}>
                            <IconClose />
                            <span className="tooltiptext bottom">{t('leave_the_dialer')}</span>
                        </span>
                    </div>
                </Fragment>
            );

        case BUSY:
        case REJECT:
            return (
                <Fragment>
                    <IconDialer isRed>
                        <IconPhone isCallOut />
                        {t('out')}
                    </IconDialer>
                    {_renderCustomerName()}
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label red-default">
                            {t('busy_or_rejected', {
                                name: formatPhoneNumberVoip(customer?.phone)
                            })}
                        </span>
                    </div>
                    <span className="is-divider" />
                    <div className="call-bar__action flexcenter pr-2">
                        <span className="action-call is-icon flex-centeritem tooltip" onClick={onPauseCall}>
                            <IconPlay isPaused />
                            <span className="tooltiptext bottom">{t('paused_calling_leads')} - Ctrl + .</span>
                        </span>
                    </div>
                </Fragment>
            );

        case NEXT_LEAD:
            return (
                <Fragment>
                    <IconDialer>
                        <IconLoading isPurple />
                    </IconDialer>
                    <div className="call-bar__info flex-1" />
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label mr-2">{t('calling_next_lead')}</span>
                    </div>
                </Fragment>
            );

        case EMPTY:
            return (
                <Fragment>
                    <IconDialer>
                        <IconPhone />
                    </IconDialer>
                    <div className="call-bar__info flex-1" />
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label mr-2">{t('no_results_left_to_call')}</span>
                    </div>
                </Fragment>
            );

        case PREPARE:
            return (
                <Fragment>
                    <IconDialer>
                        <IconLoading isPurple />
                    </IconDialer>
                    <div className="call-bar__info flex-1" />
                    <div className="call-bar__controll gap-md flexcenter">
                        <span className="status-label mr-2">{t('preparing_to_call')}</span>
                    </div>
                    <span className="is-divider" />
                    {_renderButtonHangUp(false)}
                </Fragment>
            );

        case TRANSFER:
            const { full_name, avatar, phone_number } = callData?.transferData || {};

            return (
                <Fragment>
                    <IconDialer isGrey>
                        <IconPhone isCallOut />
                        {t('out')}
                    </IconDialer>
                    {_renderCustomerName()}

                    <div className="call-bar__action flexcenter pr-2">
                        <span className="flex-auto">{t('transferred_to')}</span>
                        <span className="schedule-user">
                            <span className="tech-name m-0">
                                {!!full_name && <img className="avt-img" src={avatar} width={24} height={24} />}
                                <span className="tech-name__label">{!!full_name ? full_name : phone_number}</span>
                            </span>
                        </span>
                    </div>
                </Fragment>
            );
        default:
            return null;
    }
};

export default StatusCallDialer;

const IconDialer = ({ isRed = false, isBlue = false, isGrey = false, children }) => {
    return (
        <span
            className={classNames(`call-bar__btn flex-column align-center fw-500 text-uppercase`, {
                'is-blue': !!isBlue,
                'is-red': !!isRed,
                'is-grey': !!isGrey
            })}
        >
            {children}
        </span>
    );
};
