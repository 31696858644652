import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import { reducer } from 'app/const/Reducer';
import { SocketContext } from 'app/services/socket/SocketProvider';

const TrackingConfirm = forwardRef(({ onConfirm = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isLogout: false, isOpen: false });
    const { workLogStop } = useContext(SocketContext);
    const { isLogout, isOpen } = state;

    const refConfirm = useRef(null);
    const refButtonSave = useRef(null);

    const _handleClose = () => {
        if (isOpen) dispatchState(() => ({ isOpen: false }));
    };

    useImperativeHandle(ref, () => ({
        open: ({ isLogout }) => {
            dispatchState(() => ({ isLogout, isOpen: true }));
        },
        close: _handleClose
    }));

    useEffect(() => {
        if (workLogStop) _handleClose();
    }, [workLogStop]);

    useEffect(() => {
        if (isOpen) refConfirm.current?.open();
    }, [isOpen]);

    if (!isOpen) return null;
    return (
        <GdConfirm
            ref={refConfirm}
            title={t('stop_time_clocking')}
            message={isLogout ? t('stop_tracker_logout_warning') : t('stop_tracker_stop_warning')}
            onClose={_handleClose}
            footer={
                <div className="footer-modal footer-hasbtn btn-close">
                    <div className="v2-btn-default" onClick={_handleClose}>
                        {isLogout ? t('stop_tracker_logout_confirm_no') : t('stop_tracker_stop_confirm_no')}
                    </div>
                    <ButtonSave
                        ref={refButtonSave}
                        title={t('yes')}
                        disabled={!navigator.onLine}
                        wrapClass="v2-btn-main ml-2"
                        onSave={() => onConfirm(null, null, null, isLogout)}
                    />
                </div>
            }
        />
    );
});

export default TrackingConfirm;
