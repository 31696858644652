import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'app/modules/error/css/oops.scss';
import GdLazyImage from 'app/components/lazyimage';
import { AUTH_LOGIN, CALENDAR } from 'app/const/Route';
import { ACCESS_TOKEN } from 'app/const/App';
import IconArrow from 'assets/icon/IconArrow';
import IconHelp from 'assets/icon/IconHelp';
import { LINK_CDN_IMG } from 'app/const/URL';
import { addBranchPath } from 'app/const/Branch';

function ErrorPage({
    isBack = false,
    onBack,
    errorMessage = '',
    companyInfo = {},
    hideButtons = false,
    showContactSupport = true
}) {
    const { t } = useTranslation(['common', 'auth']);
    const history = useHistory();

    const _handleBack = (e) => {
        e && e.stopPropagation();

        if (onBack) onBack();
        if (isBack) {
            localStorage.removeItem(ACCESS_TOKEN);
            history.push(AUTH_LOGIN);
        } else {
            const oldPath = history?.location?.state?.from;
            oldPath ? history.goBack() : history.push(addBranchPath(CALENDAR));
        }
    };

    const _handleOpenIntercom = () => {
        window.Intercom('boot');
        window.Intercom('show');
    };

    return (
        <div className="c-error">
            <div className="container">
                <h2 className="title">{t('common:oops')}!</h2>
                <p className="description">
                    {errorMessage ||
                        (!!companyInfo.name
                            ? t('auth:text_profile_tech_expired', { techName: companyInfo.name || '' })
                            : t('common:something_went_wrong'))}
                </p>
                {!hideButtons ? (
                    <div className="rows">
                        <div
                            onClick={(e) => {
                                _handleBack(e);
                            }}
                            className="v2-btn-default --noborder"
                        >
                            <IconArrow isPrev />
                            {t('common:back')}
                        </div>
                        {showContactSupport ? (
                            <div onClick={_handleOpenIntercom} className="v2-btn-default has-icon btn-contact">
                                <IconHelp />
                                {t('common:contact_support')}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div className="logo">
                <GdLazyImage
                    src={`${LINK_CDN_IMG}bottom_logo.png`}
                    alt={t('common:gorilladesk')}
                    width="143"
                    height="134"
                />
            </div>
        </div>
    );
}

export default ErrorPage;
