import React from 'react';

const Loading = () => {
    return (
        <div className="clock-view wrap-loading --devices ">
            <div className="clock-table">
                <div className="col-right boxs">
                    <div className="header-box">
                        <div className="col col-date" />
                        <div className="col col-clock" />
                        <div className="col col-duration" />
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-date pl-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col col-clock flexcenter gap-8">
                            <div className="loading --animation --line --half" />
                            <span className="black-dark-jungle">-</span>
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col col-duration">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
                <div className="col-right flex-1 boxs border-left-gray-brown border-right-gray-brown">
                    <div className="header-box" />
                    <div className="rows --content" />
                    <div className="rows --content" />
                    <div className="rows --content" />
                    <div className="rows --content" />
                    <div className="rows --content" />
                    <div className="rows --content" />
                </div>
                <div className="col-right boxs">
                    <div className="header-box">
                        <div className="col col-amount" />
                        <div className="col --menu" />
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                    <div className="rows --content">
                        <div className="col col-amount">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --menu">
                            <div className="v2-btn-default loading --grey --icon-lg bg-white-cultured" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rows --total">
                <div className="col col-date field-h20">
                    <div className="loading --animation --line --half"></div>
                </div>
                <div className="col col-clock"></div>
                <div className="col col-duration field-h20">
                    <div className="loading --animation --line --threefourth"></div>
                </div>
                <div className="col-center"></div>
                <div className="col col-amount field-h20">
                    <div className="loading --animation --line --twothird"></div>
                </div>
            </div>
        </div>
    );
};

export const LoadingHeaderTracking = () => (
    <div className="header --main pb-0 pl-2 justify-start align-center wrap-loading">
        <div className="header__left flex-1 align-center">
            <div className="header__back v2-btn-default loading --transparent btn-x-sm pr-0">
                <div className="loading --animation --line --full" />
            </div>
            <div className="flexcenter gap-4 mx-2 pointer-events-none">
                <div className="avt-img --large" />
                <div className="v2-btn-default --transparent button-email bg-transparent px-0 flex-column justify-center align-top">
                    <div className="loading --animation --line --full" />
                    <div className="loading --animation --line --half" />
                </div>
            </div>
            <div className="v2-btn-default --grey loading btn-md px-0">
                <div className="v2-btn-default loading --twothird">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --twothird">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --twothird">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
            <div className="v2-btn-default --grey loading btn-md">
                <div className="loading --animation --line --full" />
            </div>
        </div>
        <div className="flexcenter gap-4">
            <div className="v2-btn-default loading --grey btn-sm">
                <div className="loading --animation --line --full" />
            </div>
            <div className="v2-btn-default loading --grey btn-sm">
                <div className="loading --animation --line --full" />
            </div>
        </div>
    </div>
);

export default Loading;
