import BoxControl from 'app/modules/settings/templatesManager/components/boxControl';
import GdButton from 'app/components/button';
import { CREATE_TEMPLATE, getCheckListImTemplate, updateTodoTemplate } from 'app/const/api/Todo';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { useReducer, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { LoadingDetailItems } from 'app/modules/settings/templatesManager/components/todoList/Loading';
import ButtonSave from 'app/components/button/ButtonSave';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';

import GdConfirm from 'app/components/confirm';
import { useEnterKeydownClick } from 'common/hooks';

const DetailItems = ({ onAddTemplate = () => {}, onUpdateTemplate = () => {}, onHasBeenChange = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        items: [],
        isLoading: true,
        name: '',
        templateId: '',
        isEdit: true,
        isOpen: false,
        isFetched: 0
    });

    const refInput = useRef(null);
    const refButtonSave = useRef(null);
    const refBoxControl = useRef(null);
    const refStatusBar = useRef(null);
    const refConfirm = useRef(null);

    const { t } = useTranslation(['setting']);

    const { items, isLoading, templateId, name, isEdit, isOpen, isFetched } = state;

    useImperativeHandle(ref, () => ({
        _getDetail: _getDetailItems,
        _openAddTemplate,
        _closeForm,
        _showStatusBar
    }));

    useEffect(() => {
        if (isFetched) {
            _setDisableSave(true);
            refInput.current.value = name;
        }
    }, [isFetched]);

    useEnterKeydownClick(isOpen);

    const _getDetailItems = ({ id, name }) => {
        if (!id) {
            isLoading && dispatchState({ isLoading: false });
            return;
        }

        !isLoading && dispatchState({ isLoading: true });

        const _success = ({ data }) => {
            dispatchState({
                items: data,
                isLoading: false,
                name,
                templateId: id,
                isEdit: true,
                isOpen: true,
                isFetched: Date.now()
            });
        };

        const _fail = () => {
            dispatchState({ isLoading: false });
        };

        clientQuery(getCheckListImTemplate(id), { method: 'GET', data: {} }, _success, _fail);
    };

    const _openAddTemplate = () => {
        if (isOpen && !refButtonSave.current.getStatusBtn()) {
            refConfirm.current.open(_openAddTemplateInternal);
        } else {
            _openAddTemplateInternal();
        }
    };

    const _openAddTemplateInternal = () => {
        refInput.current.focus();
        refBoxControl.current._setItems([]);
        dispatchState({
            items: [],
            isEdit: false,
            templateId: '',
            name: '',
            isOpen: true,
            isFetched: Date.now()
        });
    };

    const _addNewTemplate = (items) => {
        const name = refInput.current.value;
        const _success = ({ data }) => {
            onAddTemplate({ id: data.id, name });
            _removeSaveLoading();
            _showStatusBar('show_success', t('create_template_successfully'), LIST_STATUS.SUCCESS);
            _openAddTemplateInternal();
        };

        const _fail = () => {
            _removeSaveLoading();
            _showStatusBar('show_error', t('create_template_error'), LIST_STATUS.ERROR);
        };

        clientQuery(
            CREATE_TEMPLATE,
            {
                method: 'POST',
                toFormData: false,
                data: {
                    name,
                    todos: items.map((item) => {
                        return { description: item.description };
                    })
                }
            },
            _success,
            _fail
        );
    };

    const _updateTemplate = (items) => {
        const name = refInput.current.value;

        const _success = ({ data }) => {
            onUpdateTemplate({ id: data.id, name });
            _removeSaveLoading();
            _showStatusBar('show_success', t('changes_saved_successfully'), LIST_STATUS.SUCCESS);
            _setDisableSave(true);
        };

        const _fail = () => {
            _removeSaveLoading();
            _showStatusBar('show_error', t('changes_save_error'), LIST_STATUS.ERROR);
        };

        clientQuery(
            updateTodoTemplate(templateId),
            {
                method: 'PUT',
                toFormData: false,
                data: {
                    name,
                    todos: items.map((item) => {
                        return { description: item.description };
                    })
                }
            },
            _success,
            _fail
        );
    };

    const _removeSaveLoading = () => refButtonSave.current.removeLoading();

    const _saveTemplate = () => {
        const newItems = refBoxControl.current._getFinalItems();

        if (isEdit) {
            _updateTemplate(newItems);
        } else {
            _addNewTemplate(newItems);
        }
    };

    const _showStatusBar = (id, message, status) => {
        refStatusBar.current.showStatusBar(id, message, status);
    };

    const _changeTemplateName = (e) => {
        _setDisableSave(!!!e.target.value);
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _closeForm = () => {
        if (!refButtonSave.current.getStatusBtn()) {
            refConfirm.current.open(_close);
        } else {
            _close();
        }
    };

    const _setDisableSave = (disabled) => {
        refButtonSave.current.setDisable(disabled);
        onHasBeenChange(!disabled);
    };

    const _checkAllowSave = () => {
        refInput.current.value && _setDisableSave(false);
    };

    const _nextAction = (callback = () => {}) => {
        callback();
    };

    if (isLoading) return <LoadingDetailItems />;

    return (
        <div className="wrap-note-template flex-column flex-1">
            <StatusBar ref={refStatusBar} />
            <div className={classNames('wrap-note-template__content flex-column', { 'is-show': isOpen })}>
                <div className="template-header">
                    <h5 className="title --sm">{isEdit ? t('edit_template') : t('create_template')}</h5>
                    <div className="template-header__name mt-2">
                        <p className="title">{t('template_name')}</p>
                        <input
                            ref={refInput}
                            type="text"
                            className="field-input"
                            placeholder={t('template_name')}
                            defaultValue={name}
                            onChange={_changeTemplateName}
                            spellCheck
                        />
                    </div>
                </div>
                <div className="template-content flex-column flex-1">
                    <BoxControl
                        items={items}
                        templateId={templateId}
                        ref={refBoxControl}
                        onHasBeenChange={_checkAllowSave}
                    />
                    <div className="template-content__footer shadow-top-brown flex-betweenitems">
                        <GdButton className="v2-btn-default" title={t('close')} onClick={_closeForm} />
                        <ButtonSave
                            ref={refButtonSave}
                            wrapClass="v2-btn-main"
                            title={isEdit ? t('save_changes') : t('save')}
                            onSave={_saveTemplate}
                        />
                    </div>
                </div>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('confirm_unsaved_changes')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_nextAction}
            />
        </div>
    );
};
export default forwardRef(DetailItems);
