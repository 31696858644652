import React from 'react';

const IconCustomFields = ({ type = 'default' }) => {
    switch (type) {
        case 'addons':
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                        fill="#FFF"
                    />
                    <path
                        opacity="0.35"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 10C8.44772 10 8 10.4477 8 11V13C8 13.5523 8.44772 14 9 14H23C23.5523 14 24 13.5523 24 13V11C24 10.4477 23.5523 10 23 10H9ZM9 20C8.44772 20 8 20.4477 8 21V23C8 23.5523 8.44772 24 9 24H23C23.5523 24 24 23.5523 24 23V21C24 20.4477 23.5523 20 23 20H9Z"
                        fill="#1EAA5C"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H9ZM9 17C8.44772 17 8 17.4477 8 18C8 18.5523 8.44772 19 9 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 13.5523 17 13 17H9Z"
                        fill="#1EAA5C"
                    />
                </svg>
            );
        default:
            return (
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width={32} height={32} rx={4} fill="#1C1F22" fillOpacity="0.06" />
                    <path
                        opacity="0.35"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 10C8.44772 10 8 10.4477 8 11V13C8 13.5523 8.44772 14 9 14H23C23.5523 14 24 13.5523 24 13V11C24 10.4477 23.5523 10 23 10H9ZM9 20C8.44772 20 8 20.4477 8 21V23C8 23.5523 8.44772 24 9 24H23C23.5523 24 24 23.5523 24 23V21C24 20.4477 23.5523 20 23 20H9Z"
                        fill="#77787A"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9H13C13.5523 9 14 8.55228 14 8C14 7.44772 13.5523 7 13 7H9ZM9 17C8.44772 17 8 17.4477 8 18C8 18.5523 8.44772 19 9 19H13C13.5523 19 14 18.5523 14 18C14 17.4477 13.5523 17 13 17H9Z"
                        fill="#77787A"
                    />
                </svg>
            );
    }
};

export default IconCustomFields;
