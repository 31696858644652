import React from 'react';
import { formatNumberWithCommas, transformToCurrency } from 'common/utils/NumberUtils';

const RowItemWidget = ({ label = '', percent = 0, value = 0, total = null, icon = null, avatar = '', currency }) => {
    const finalValue = currency ? transformToCurrency(value, currency) : value;

    const _renderTotal = () => {
        if (!total?.toString()) return null;

        const finalTotal = formatNumberWithCommas(total);
        return (
            <td className="fs-11 col-number" title={finalTotal}>
                {finalTotal}
            </td>
        );
    };

    return (
        <tr>
            <td className="fs-13 col-name">
                <div className="col__title">
                    {!!icon ? (
                        icon
                    ) : (
                        <div className="avt-img">
                            {!!avatar ? (
                                <img src={avatar} alt={label} width="24" height="24" />
                            ) : (
                                <div className="avt">{label?.substring(0, 2)}</div>
                            )}
                        </div>
                    )}
                    <span className="col-label" title={label}>
                        {label}
                    </span>
                </div>
            </td>

            <td>
                <div className={`progress-bar --blue`}>
                    <span style={{ width: `${Math.max(0, percent)}%` }} />
                </div>
            </td>
            {_renderTotal()}
            <td className="fs-11">{percent}%</td>
            <td className="fs-13 --label-r" title={finalValue}>
                {finalValue}
            </td>
        </tr>
    );
};

export default RowItemWidget;
