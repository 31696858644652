import classNames from 'classnames';
import React, { useContext, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { LIST_STATUS } from 'app/const/App';
import { STATUS_CUSTOMER } from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import { changeBalanceTotal } from 'app/const/api/V2';
import IconEdit from 'assets/icon/IconEdit';
import IconTooltip from 'assets/icon/IconTooltip';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import AlertCustomer from '../../components/AlertCustomer';
import { transformToCustomerType } from '../../utils';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import CustomerTags from '../layouts/CustomerTags';
import EditInformation from './EditInformation';
import LoadingDefault from './components/Loading';
import PhonesDisplay from './components/PhonesDisplay';

const CustomerInfo = () => {
    const { t } = useTranslation();
    const refAlert = useRef(null);
    const permissionsList = useSelector((state) => state.auth.user?.permissions?.enabled || []);
    const isPermissionAddCustomer = checkPermission(permissionsList, 'appAddCustomer');

    const { customer, isLoading, _handleUpdateContact } = useContext(CustomerDetailContext);
    const {
        id: customerId,
        email,
        phones,
        account_number,
        source,
        company,
        first_name,
        last_name,
        qb_id,
        title = ''
    } = customer;
    const status = STATUS_CUSTOMER[customer.status];

    const [state, dispatchState] = useReducer(reducer, { isEditing: false });
    const { isEditing } = state;

    const _handleEdit = () => dispatchState((prev) => ({ ...prev, isEditing: !prev.isEditing }));

    const _convertPhoneData = (phones = []) => {
        if (!Array.isArray(phones) || !phones?.length) return [];
        return phones.map((phone) => ({
            phone: phone.phone,
            phone_type: phone?.type?.name,
            phone_type_id: phone?.type?.id,
            is_valid: phone.is_valid
        }));
    };

    const _handleEditSuccess = ({ message, data }) => {
        const { first_name, last_name, email, phones } = data;
        const name = first_name.concat(' ', last_name).trim();
        const newMessage = message.length ? message : t('customers:save_customer_success', { name });
        refAlert.current?.showStatusBar({ id: 'edit_success', message: newMessage, type: LIST_STATUS.SUCCESS });
        const dataPrimaryContact = {
            id: '0',
            email,
            customer: transformToCustomerType({ id: '0', first_name, last_name }),
            phones: _convertPhoneData(phones)
        };
        _handleUpdateContact(dataPrimaryContact, 'update');
        dispatchState((prev) => ({ ...prev, isEditing: false }));
    };

    const _handleEditFailed = (message) => {
        refAlert.current?.showStatusBar({ id: 'edit_failed', message, type: LIST_STATUS.ERROR });
    };

    const _showAlert = (dataAlert) => {
        refAlert.current?.showStatusBar(dataAlert);
    };

    const renderDefault = () => {
        return (
            <div className="form-default">
                <div className="rows align-center">
                    <div className="rows__label">
                        {t('customers:account_#')}
                        <span className="tooltip ml-1">
                            <IconTooltip />
                            <span className="tooltiptext top ml-3">
                                {t('customers:account_customer_profile_tooltip')}
                            </span>
                        </span>
                    </div>
                    <div className="rows__info">{account_number}</div>
                </div>

                <RowInfo
                    label={t('customers:status')}
                    value={qb_id}
                    customRowInfo={
                        <div className={classNames('status-btn --sm', status)}>{t(`customers:${status}`)}</div>
                    }
                    isCheckValue={false}
                />
                <RowInfo label={t('customers:quickbooks_id')} value={qb_id} />
                <RowInfo label={t('customers:first_name')} value={first_name} />
                <RowInfo label={t('customers:last_name')} value={last_name} />
                <RowInfo label={t('customers:title')} value={title} isTitle />
                <RowInfo
                    label={t('customers:email')}
                    value={email}
                    customRowInfo={<a href={`mailto:${email}`}>{email}</a>}
                    classNameInfo={'rows__info --link'}
                />
                <RowInfo label={t('customers:company')} value={company} />
                {!!phones.length && <PhonesDisplay data={phones} classWrapper={'rows mt-0'} customerId={customerId} />}
                <RowInfo label={t('customers:source')} value={source?.name} />

                <CustomerTags
                    isEditAccount
                    classWrapper="rows mt-0"
                    classLabel="rows__label"
                    classInfo="rows__info"
                    classRowTagsDisplay="wrap-existing-tag flexcenter flex-wrap"
                    classWrapperDropdown="box-tags flex-1"
                />

                <BalanceCheckbox onShowAlert={_showAlert} />
            </div>
        );
    };

    const renderEdit = () => {
        return (
            <EditInformation
                refAlert={refAlert}
                onEdit={_handleEdit}
                onEditFailed={_handleEditFailed}
                onEditSuccess={_handleEditSuccess}
                renderHideBalance={() => <BalanceCheckbox />}
            />
        );
    };

    if (isLoading) return <LoadingDefault />;
    return (
        <div className={classNames('page-wrapper', 'flex-auto', 'form-account', { 'has-editing': isEditing })}>
            <div className="head-main flex-betweenitems mb-2">
                <h3 className="title-font15">{t('customers:account')}</h3>
                {!isEditing && isPermissionAddCustomer ? (
                    <div className="v2-btn-default has-icon js-edit-customer" onClick={_handleEdit}>
                        <IconEdit />
                        {t('customers:edit')}
                    </div>
                ) : null}
            </div>
            <AlertCustomer ref={refAlert} />
            {isEditing ? renderEdit() : renderDefault()}
        </div>
    );
};

const BalanceCheckbox = ({ onShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const { id: customer_id } = useParams();
    const {
        _handleUpdateCustomer,
        customer: { hide_total_balance }
    } = useContext(CustomerDetailContext);
    const refCheckbox = useRef(null);

    useEffect(() => {
        refCheckbox.current?.setIsActive(!!hide_total_balance);
    }, [hide_total_balance]);

    const _handleChangeHideTotalBalance = (newValue) => {
        const hideTotalBalance = newValue ? 1 : 0;

        const handleSuccess = (res) => {
            _handleUpdateCustomer({ hide_total_balance: hideTotalBalance });
            onShowAlert({
                id: 'edit_success',
                message: res.message,
                type: LIST_STATUS.SUCCESS
            });
        };

        clientQuery(
            changeBalanceTotal(customer_id),
            { data: { value: hideTotalBalance }, method: 'PUT' },
            handleSuccess
        );
    };

    return (
        <div className="rows mt-1 rows-balance align-center">
            <span className="tooltip">
                <IconTooltip />
                <span className="tooltiptext top">{t('customers:account_customer_hidden_tooltip')}</span>
            </span>
            <span className="rows-balance__label flex-1">
                {t('customers:hide_the_total_account_balance_on_invoices')}
            </span>

            <GdSwitchCheckbox
                id="hidden_balance"
                ref={refCheckbox}
                textChecked={t('common:hidden')}
                textUnChecked={t('common:no')}
                inputClassName="toggle toggle-round"
                onChange={_handleChangeHideTotalBalance}
            />
        </div>
    );
};

const RowInfo = ({
    value = '',
    label = '',
    customRowInfo = '',
    isCheckValue = true,
    isTitle = false,
    classNameWrapper = 'rows align-center mt-0',
    classNameLabel = 'rows__label',
    classNameInfo = 'rows__info'
}) => {
    const valueDisplay = customRowInfo || value;
    return (
        (!isCheckValue || value) && (
            <div className={classNameWrapper}>
                <div className={classNameLabel}>{label}</div>
                <div className={classNameInfo}>
                    {isTitle ? <div className="label-content">{valueDisplay}</div> : valueDisplay}
                </div>
            </div>
        )
    );
};

export default CustomerInfo;
