import mapboxgl from 'mapbox-gl';
import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { RouteWithSubRoutesPrivate } from 'app/components/routes/RouteWithSubRoutes';
import { ROUTES_CONFIG } from 'app/config/router';
import { ACCESS_TOKEN, STATUS_ACCOUNT } from 'app/const/App';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { CALENDAR } from 'app/const/Route';
import GDVoip from 'app/modules/layouts/GDVoip/index.js';
import Header from 'app/modules/layouts/Header';
import ServiceLayout from 'app/modules/layouts/Service';
import Sidebar from 'app/modules/layouts/Sidebar';
import HelloBar from 'app/modules/layouts/components/HelloBar';
import NetWorkInfo from 'app/modules/layouts/components/NetWork';
import SmsChargeFailed from 'app/modules/layouts/components/SmsChargeFailed';
import TrialBar from 'app/modules/layouts/components/TrialBar';
import NotFoundPage from 'app/modules/notFoundPage';
import SocketProvider from 'app/services/socket/SocketProvider';
import VOIPProvider from 'app/services/voip/VOIPProvider';

import * as ListRoute from 'app/config/routes';

const AuthenticatedApp = () => {
    const { settings, profile, company } = useSelector(({ auth }) => auth?.user || {});
    const mbToken = settings?.mapbox_token;
    let intercomAppId = null;

    const isExpired = profile?.plan?.status === STATUS_ACCOUNT.EXPIRED;
    // eslint-disable-next-line no-undef
    const isEnvDev = process.env.REACT_APP_ENV === 'dev';

    useLayoutEffect(() => {
        if (typeof mbToken !== 'string') return;
        mapboxgl.accessToken = mbToken;
    }, [mbToken]);

    useLayoutEffect(() => {
        const w = window;
        const ic = w.Intercom;

        const intercom = settings?.io?.intercom || {};
        intercomAppId = intercom.app_id;

        // Script to run intercom
        window.intercomSettings = {
            email: profile.email || '',
            name: `${profile.first_name} ${profile.last_name}`,
            signed_up_at: profile.signed_up_at,
            Role: profile.role,
            user_hash: intercom.hash,
            app_id: intercomAppId,
            user_id: `${isEnvDev ? 'dev_' : ''}${profile.id}`,
            company: {
                company_id: `${isEnvDev ? 'dev_' : ''}${company.id}`,
                name: company.name,
                email: company.email || '',
                phone: company.phone,
                Website: company.website
            }
        };

        if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
        } else {
            const d = document;
            const i = function () {
                i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
                i.q.push(args);
            };
            w.Intercom = i;
            const l = function () {
                const s = d.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://widget.intercom.io/widget/' + intercomAppId;
                const x = d.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            };
            if (document.readyState === 'complete') {
                l();
            } else if (w.attachEvent) {
                w.attachEvent('onload', l);
            } else {
                w.addEventListener('load', l, false);
            }
            // End script to run intercom
        }

        window.Intercom('shutdown', { app_id: intercomAppId });
        return () => {
            if (ic) window.Intercom('shutdown', { app_id: intercomAppId });
        };
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleStorageChange = ({ key, newValue }) => {
        if ((!key || key === ACCESS_TOKEN) && !newValue) window.location.reload();
    };

    return (
        <SocketProvider>
            <VOIPProvider>
                <div id="main-page" className="gorilladesk">
                    <NetWorkInfo />
                    <HelloBar />
                    <TrialBar />
                    <SmsChargeFailed />
                    {!isExpired && <GDVoip />}
                    <Header />
                    <Sidebar />

                    <Switch>
                        {/* Main routes */}
                        <Route
                            path={['/:branchid', ...Object.keys(ListRoute).map((key) => addPrefixPath(ListRoute[key]))]}
                            exact
                        >
                            <Switch>
                                <Route exact path={addPrefixPath('')}>
                                    <Redirect to={addBranchPath(CALENDAR)} />
                                </Route>
                                <RenderList />
                            </Switch>
                        </Route>

                        <Route path="*" component={NotFoundPage} />
                    </Switch>
                    {/* End main routes */}
                    <ServiceLayout />
                </div>
            </VOIPProvider>
        </SocketProvider>
    );
};

const RenderList = () => {
    return ROUTES_CONFIG.map((route, i) => {
        return <RouteWithSubRoutesPrivate key={i} {...route} />;
    });
};

export default AuthenticatedApp;
