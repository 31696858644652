import React from 'react';
import { useTranslation } from 'react-i18next';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const SMSAddonsContent = ({ keyword, name, status, upgrade, level, subscriptionFee = 5 }) => {
    const { t } = useTranslation('addons');
    return (
        <div className="boxs boxs--no-border">
            <AddonsBoxHeader
                keyword={keyword}
                name={name}
                status={status}
                upgrade={upgrade}
                level={level}
                isHideCheckbox
            />
            <div className="boxs__contents">
                <div className="description">
                    <span className="fw-600">
                        {t('addons_sms_description_1', { subscription_fee: subscriptionFee })}
                    </span>
                    {t('addons_sms_description_3')}
                </div>
                <div className="description mt-3">
                    SMS packs <span className="fw-600">automatically renew</span> {t('addons_sms_description_2')}
                </div>
            </div>
        </div>
    );
};

export default SMSAddonsContent;
