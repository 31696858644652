import React, { Fragment } from 'react';

const LoadingModalSelectOptions = () => {
    return (
        <Fragment>
            <div className="option-items">
                <div className="loading --animation --line --half mb-2"></div>
                <div className="loading --animation --line --full"></div>
                <div className="loading --animation --line --threefourth"></div>
            </div>
            <div className="option-items">
                <div className="loading --animation --line --half mb-2"></div>
                <div className="loading --animation --line --full"></div>
                <div className="loading --animation --line --threefourth"></div>
            </div>
        </Fragment>
    );
};

export default LoadingModalSelectOptions;
