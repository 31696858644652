import { METHOD_CONVERT } from 'app/const/App';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import CardDate from 'app/components/payment/components/CardDate';

const InfoCard = ({
    name,
    id,
    isSetDefault = false,
    is_default = 0,
    meta = {},
    account_number,
    account_type,
    account_name,
    exp_month,
    exp_year,
    onSetDefault = () => {},
    // do not use onEdit = () => {} because it will be called when onEdit is null
    onEdit = null,
    isPaymentSideBar = false,
    idElement,
    isSetNewDefault = false,
    isPaymentDefault = false,
    currency = '',
    isACH = false
}) => {
    const { t } = useTranslation();
    const finalName = isACH ? account_name : name;

    function _handleEditCard() {
        if (onEdit)
            onEdit({
                id,
                account_number,
                account_type,
                exp_month,
                exp_year: `${exp_year}`.substring(2),
                meta
            });
    }

    return (
        <Fragment>
            <div className={classNames('info-card', { 'cursor-pointer': onEdit })} onClick={_handleEditCard}>
                <div className="card-number">
                    <p className="card-number__label txt-ellipsis" title={finalName}>
                        {finalName}
                    </p>
                    <div className="number">
                        {!isPaymentSideBar && <p className="branch txt-ellipsis">{account_type}</p>}
                        <p className="number__dots">{METHOD_CONVERT}</p>
                        <p className="number__digit txt-ellipsis">{account_number}</p>
                    </div>
                    <CardDate currency={currency} isACH={isACH} exp_month={exp_month} exp_year={exp_year} />
                </div>
            </div>

            {isSetDefault && (
                <div className={classNames('set-card', { selected: is_default })}>
                    <div className="default-btn is-default" onClick={onSetDefault}>
                        {t('default')}
                    </div>
                    <div className="default-btn" onClick={onSetDefault}>
                        {t('make_default')}
                    </div>
                </div>
            )}

            {isSetNewDefault && (
                <div className="check-radio flex-1">
                    <input
                        id={idElement}
                        type="radio"
                        defaultValue="default-stripe"
                        name={name}
                        defaultChecked={is_default}
                        onChange={onSetDefault}
                    />
                    <label htmlFor={idElement}>{t(`common:default`)}</label>
                </div>
            )}

            {isPaymentDefault && (
                <span className="default-label blue-default fw-600 flex-1">{t('customers:default')}</span>
            )}
        </Fragment>
    );
};

export default InfoCard;
