import { call, put } from 'redux-saga/effects';
import { putWithToken } from 'common/utils/ApiUtils';
import { updateJobStatus } from 'app/const/Api';
import { JOB_PREVIEW_ACTIONS } from 'common/redux/actions/job';

export function* actionUpdateJobStatus({ params, actionSuccess, actionFailed }) {
    try {
        const jobId = params.jobId;
        delete params.jobId;

        const response = yield call(
            putWithToken,
            updateJobStatus(jobId),
            params
        );
        if (response && response.success) {
            yield put({ type: JOB_PREVIEW_ACTIONS.UPDATE_JOB_STATUS_SUCCESS, payload: response.data });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
