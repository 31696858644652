import React, { useContext, useEffect, useRef } from 'react';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { PAYMENT_METHODS, LIST_PAYMENT_METHODS } from 'app/modules/jobdetail/const/Invoice';
import IconAmount from 'assets/icon/IconAmount';
import { roundingNumber } from 'common/utils/NumberUtils';

const Amount = ({ autoFocus = false }) => {
    const { addPayment, updateNumberPaymentContext } = useContext(AddPaymentContext);
    const { activeCredit, amountValue, customerCredit: finalCustomerCredit = {} } = addPayment;

    const refAmountInvoice = useRef(null);

    const _onKeyDown = (event) => {
        /^[eE]{1}$/.test(event.key) && event.preventDefault();
    };

    useEffect(() => {
        refAmountInvoice.current.value = roundingNumber(amountValue);
    }, [addPayment]);

    function _renderCustomerCredit() {
        const listPaymentMethod = addPayment?.listPaymentMethod || [];
        const checkShow = listPaymentMethod.some((item) => item.id === PAYMENT_METHODS.CREDIT);

        if (!checkShow || !addPayment.customerId || parseFloat(finalCustomerCredit.value) <= 0) {
            return false;
        }

        return (
            <div className="check-items">
                <input onChange={_handleChangeValueCredit} id="check_amount" type="checkbox" checked={activeCredit} />
                <div className="item-checkbox">
                    <label htmlFor="check_amount">
                        <span className="txt-ellipsis">
                            Apply as credit {addPayment?.customerCredit?.format} available
                        </span>
                    </label>
                </div>
            </div>
        );
    }

    function _handleChangeValueCredit(e) {
        e && e.stopPropagation();

        updateNumberPaymentContext({
            activeCredit: !activeCredit,
            isEditAmount: !activeCredit,
            amountValue: addPayment?.customerCredit?.value,
            paymentSelected: LIST_PAYMENT_METHODS[activeCredit ? 1 : 2]
        });
    }

    function _onChangeValue(e) {
        const finalValue = e.target.value;

        updateNumberPaymentContext({
            isEditAmount: finalValue && parseFloat(finalValue) > 0,
            amountValue: finalValue,
            invoiceSelected: finalValue ? addPayment.invoiceSelected : [],
            checkAll: finalValue ? addPayment.checkAll : false
        });
    }

    return (
        <div className="sm-row">
            <div className="txt">
                <IconAmount />
                Amount
            </div>
            <div className="detail">
                <input
                    ref={refAmountInvoice}
                    className="field-input"
                    type="number"
                    onKeyDown={_onKeyDown}
                    onBlur={_onChangeValue}
                    autoFocus={autoFocus}
                    defaultValue={roundingNumber(addPayment.amountValue)}
                    disabled={activeCredit}
                />
                {_renderCustomerCredit()}
            </div>
        </div>
    );
};

export default Amount;
