import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import classNames from 'classnames';
import { CAPTCHA_SITE_KEY } from 'app/const/Keys';
import { AUTH_LOGIN } from 'app/const/Route';
import IconError from 'assets/icon/IconError';
import AuthInputField from 'app/modules/auth/components/AuthInputField';
import ButtonLoading from '../../components/ButtonLoading';

let timer;

const FormIdentify = ({ onSubmitForm, errorsForm, onCheckCaptcha, formDisable, onKeyPress, onFocusField, t }) => {
    const userNameRef = useRef(null);
    const onSubmit = () => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            onSubmitForm && onSubmitForm();
        }, 300);
    };

    const recaptchaLoaded = () => {};

    const _handleKeyPress = (e) => {
        onKeyPress && onKeyPress(e);
    };
    const _handleFocus = (e) => {
        onFocusField && onFocusField(e);
    };

    const verifyCallback = (response) => {
        if (response) {
            onCheckCaptcha && onCheckCaptcha();
        }
    };

    const _renderCaptcha = () => {
        const isErrorCaptcha = errorsForm && errorsForm['g-recaptcha-response'];

        return (
            <div className="g-recaptcha">
                <Recaptcha
                    sitekey={CAPTCHA_SITE_KEY}
                    render="explicit"
                    onloadCallback={recaptchaLoaded}
                    verifyCallback={verifyCallback}
                />

                <input type="text" hidden className={`field-input ${isErrorCaptcha && 'error'}`} />
                {isErrorCaptcha && (
                    <span className="flex-centeritem txt-incorrect">
                        <IconError />
                        &nbsp;{t('auth:please_verify_you_are_not_a_robot')}
                    </span>
                )}
            </div>
        );
    };

    const _handleChange = (e) => {
        if (e.target.name === 'username') userNameRef.current?.setValue(e.target.value.toLowerCase());
    };

    return (
        <React.Fragment>
            <div className="user-box">
                <AuthInputField
                    ref={userNameRef}
                    label={t('common:username_or_email')}
                    placeholder={t('common:email')}
                    type="text"
                    name="username"
                    error={errorsForm?.username}
                    onKeyPress={_handleKeyPress}
                    onFocus={_handleFocus}
                    onBlur={_handleChange}
                    onChange={_handleChange}
                    isShowIconError={false}
                    isShowLabel={false}
                    labelField="email"
                />
            </div>
            <div>
                <AuthInputField
                    label={t('auth:password')}
                    placeholder={t('auth:password')}
                    type="password"
                    name="password"
                    error={errorsForm?.password}
                    onKeyPress={_handleKeyPress}
                    onFocus={_handleFocus}
                    isShowIconError={false}
                    isShowLabel={false}
                />
            </div>

            <div id="recaptcha_wrapper">{_renderCaptcha()}</div>

            <ButtonLoading
                classWrapper={classNames(`gd-getstarted v2-btn-main gd-btn-getstarted mt-2`, {
                    disable: formDisable
                })}
                text={t('auth:start_your_free_trial')}
                isLoading={formDisable}
                onSubmit={onSubmit}
            />

            <div className="line mt-2 mb-4" />
            <div className="gd-login-confirm black-3 fw-600 py-5">
                {t('common:you_have_account')}
                <Link className="gd-login-label" to={AUTH_LOGIN}>
                    {t('auth:login')}
                </Link>
            </div>
        </React.Fragment>
    );
};

FormIdentify.propTypes = {
    onSubmitForm: PropTypes.func,
    errorsForm: PropTypes.object,
    onCheckCaptcha: PropTypes.func,
    onKeyPress: PropTypes.func,
    onFocusField: PropTypes.func,
    formDisable: PropTypes.bool
};

export default FormIdentify;
