import IconTask from 'assets/icon/IconTask';
import React from 'react';

const DayCellContent = ({ dayNumberText, total = 0, onOpenList = () => {} }) => {
    const _handleClick = (e) => {
        onOpenList(e.currentTarget);
    };

    return (
        <div className="flexcenter" style={{ padding: '1px 4px' }}>
            <span className="day-number" style={{ lineHeight: '20px' }}>
                {dayNumberText}
            </span>
            {total ? (
                <div
                    className="total-task"
                    style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        background: '#0000000f',
                        borderRadius: '30px',
                        color: '#666',
                        cursor: 'pointer',
                        fontWeight: '500',
                        fontSize: '13px',
                        padding: '0 6px 0 4px',
                        marginLeft: '4px'
                    }}
                    onClick={_handleClick}
                >
                    <IconTask width={20} height={20} /> {total}
                </div>
            ) : null}
        </div>
    );
};

export default DayCellContent;
