import React from 'react';

const CalendarSyncLoading = () => {
    return (
        <div className="wrap-loading">
            <div className="header-modal">
                <div className="loading --animation --line --twothird" />
            </div>
            <div className="body-modal">
                <div className="loading --animation --line --threefourth" />
                <div className="loading --animation --line --half mt-2 mb-5" />
                <div className="boxs align-center">
                    <div className="loading --animation --line --twothird" />
                    <div className="loading v2-btn-default --grey btn-sm justify-center">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="boxs align-center">
                    <div className="loading --animation --line --onefifth" />
                    <div className="boxs-selection flex-1 pl-6">
                        <div className="loading --animation --line --onefourth mr-2" />
                        <div className="loading v2-btn-default --grey btn-lg justify-center">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="loading v2-btn-default --grey btn-x-sm justify-center">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="boxs">
                    <div className="flex-1">
                        <div className="boxs-selection">
                            <div className="loading --animation --line --onefourth mr-2" />
                            <div className="loading v2-btn-default --grey btn-lg justify-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="boxs-selection">
                            <div className="loading --animation --line --onefourth mr-2" />
                            <div className="loading v2-btn-default --grey btn-lg justify-center">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                    </div>
                    <div className="loading v2-btn-default --grey btn-x-sm justify-center">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CalendarSyncLoading;
