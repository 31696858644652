import { DEFAULT_ALL } from 'app/const/App';
import IconRepeatOff from 'assets/icon/IconRepeatOff';
import IconInfinity from 'assets/icon/IconInfinity';
import i18n from 'assets/i18n';

export const MENU_REPEAT_ITEMS = [
    {
        id: 1,
        name: i18n.t('commission:repeats_once'),
        icon: <span className="fw-500 pr-3">{i18n.t('commission:1x')}</span>,
        style: 'items',
        selected: i18n.t('commission:1x')
    },
    { id: DEFAULT_ALL, name: i18n.t('commission:repeats_indefinitely'), icon: <IconInfinity />, style: 'items has-icon' },
    { id: 0, name: i18n.t('common:off'), icon: <IconRepeatOff />, style: 'items has-icon red-default' }
];

export const TYPES_VALUE_COMMISSION = {
    NUMBER: 1,
    PERCENT: 2
};

export const LIST_TYPE_COMMISSION = [
    {
        id: TYPES_VALUE_COMMISSION.PERCENT,
        name: '%'
    },
    {
        id: TYPES_VALUE_COMMISSION.NUMBER,
        name: '$'
    }
];

export const TYPES_OPTION_ACTIVE = {
    USER: 'user',
    SALE: 'sale',
    PRODUCTION: 'production'
};
