import { call, put, takeLatest } from 'redux-saga/effects';

import { CUSTOM_FIELDS } from 'app/const/api/V2';
import { CUSTOM_FIELDS_ACTIONS } from 'common/redux/actions/customFields';
import { clientQuery } from 'common/utils/ApiUtils';

function* getCustomFields({ payload: { actionSuccess = () => {}, actionFail = () => {} } }) {
    const response = yield call(clientQuery, CUSTOM_FIELDS, { data: {}, method: 'GET' });
    if (response && response.success) {
        yield put({ type: CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS_SUCCESS, payload: response.data });
        actionSuccess(response.data);
    } else {
        yield put({ type: CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS_FAILED, payload: [] });
        actionFail(response);
    }
}

function* editCustomFields({ payload: { data: dataSubmit, callbackSuccess = () => {}, callbackFail = () => {} } }) {
    try {
        const { data, message } = yield call(clientQuery, CUSTOM_FIELDS + '/' + dataSubmit.id, {
            data: dataSubmit,
            method: 'PUT',
            toFormData: false
        });
        if (!data) throw new Error(message);
        yield put({ type: CUSTOM_FIELDS_ACTIONS.EDIT_CUSTOM_FIELD_SUCCESS, payload: data });
        callbackSuccess({ data, message });
    } catch (error) {
        callbackFail(error);
    }
}

function* addCustomFields({ payload: { data: dataSubmit, callbackSuccess = () => {}, callbackFail = () => {} } }) {
    try {
        const { data, message } = yield call(clientQuery, CUSTOM_FIELDS, {
            data: dataSubmit,
            method: 'POST',
            toFormData: false
        });

        if (!data) throw new Error(message);
        yield put({ type: CUSTOM_FIELDS_ACTIONS.ADD_CUSTOM_FIELD_SUCCESS, payload: data });
        callbackSuccess({ data, message });
    } catch (error) {
        callbackFail(error);
    }
}

export function* customFieldsWatcher() {
    yield takeLatest(CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS, getCustomFields);
    yield takeLatest(CUSTOM_FIELDS_ACTIONS.ADD_CUSTOM_FIELD, addCustomFields);
    yield takeLatest(CUSTOM_FIELDS_ACTIONS.EDIT_CUSTOM_FIELD, editCustomFields);
}
