import ButtonSave from 'app/components/button/ButtonSave';
import { getUpdateBookingServiceNote } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';

const ServiceEditLearnMore = ({ onEditSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen, learnMore, idUpdate } = state;
    const refButtonSave = useRef(null);
    const refLearnMore = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (idUpdate, learnMore) => {
        dispatchState({ isOpen: true, idUpdate, learnMore });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSave = () => {
        const content = refLearnMore.current.value;
        const _handleSaveSuccess = ({ message }) => {
            onEditSuccess({ id: idUpdate, learn_more: content }, message);
            _close();
        };

        clientQuery(getUpdateBookingServiceNote(idUpdate), { data: { content }, method: 'PUT' }, _handleSaveSuccess);
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="add_service_booking"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container large">
                <div className="header-modal">
                    <h3 className="header-modal__label">Learn more for Service Name</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal scrolls">
                    <div className="has-form --learn-more-notes">
                        <div className="has-form__label">Note</div>
                        <textarea ref={refLearnMore} className="field-textarea --no-resize --h-120" defaultValue={learnMore} />
                    </div>
                </div>

                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ServiceEditLearnMore);
