import IconArrow from 'assets/icon/IconArrow';
import React, { useContext } from 'react';
import { SetupEmailContext } from '../../context/SetupEmailContext';
import ConnectWithGG from './ConnectWithGG';
import ConnectWithMS from './ConnectWithMS';

const StepChooseSetup = ({ onSaveWithMS = () => {}, onSaveWithGG = () => {} }) => {
    const { nextStep } = useContext(SetupEmailContext);
    return (
        <div className="step scrolls show">
            <div className="row --multi --hasline">
                <div className="col">
                    <p>
                        Sign in with your Google account using the button below. Once you do, this page will change
                        automatically.
                    </p>

                    <ConnectWithGG onClick={onSaveWithGG} />
                    <ConnectWithMS onClick={onSaveWithMS} />
                </div>
                <div className="col">
                    <p>if this is not a Gmail / Google Apps account, you can enter custom IMAP/SMTP settings.</p>
                    <p className="fs-12 btn-hover-black">
                        We take the
                        <a href="#" className="purple-default">
                            Security and Privacy
                        </a>
                        of your data very seriously.
                    </p>
                    <div className="v2-btn-default --icon-r" onClick={nextStep}>
                        Custom Settings
                        <IconArrow />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepChooseSetup;
