export const EVENT_ACTIONS = {
    OPEN_ADD_EVENT_MODAL: 'OPEN_ADD_EVENT_MODAL',
    OPEN_EDIT_EVENT_MODAL: 'OPEN_EDIT_EVENT_MODAL',
    CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
    CLOSE_EVENT_MODAL: 'CLOSE_EVENT_MODAL'
};

export const closeEvent = () => {
    return { type: EVENT_ACTIONS.CLOSE_EVENT_MODAL };
};

export const addEvent = (payload) => {
    return {
        type: EVENT_ACTIONS.OPEN_ADD_EVENT_MODAL,
        payload
    };
};

export const editEvent = (payload) => {
    return {
        type: EVENT_ACTIONS.OPEN_EDIT_EVENT_MODAL,
        payload
    };
};

export const createEventSuccess = (payload) => {
    return {
        type: EVENT_ACTIONS.CREATE_EVENT_SUCCESS,
        payload
    };
};
