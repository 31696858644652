import React from 'react';
import InvoiceNumber from 'app/modules/jobdetail/tabs/addinvoice/form/info/InvoiceNumber';
import PO from 'app/modules/jobdetail/tabs/addinvoice/form/info/PO';
import DateIssue from 'app/modules/jobdetail/tabs/addinvoice/form/info/DateIssue';
import EstimateType from 'app/modules/jobdetail/tabs/addestimate/form/Type';

const InvoiceInfo = ({ isInvoice = true, isFetchInit = true }) => {
    return (
        <div className="customer-info-number">
            {!isInvoice && <EstimateType />}
            <InvoiceNumber isInvoice={isInvoice} isFetchInit={isFetchInit} />
            <PO />
            <DateIssue isInvoice={isInvoice} />
        </div>
    );
};
export default InvoiceInfo;
