import React from 'react';
import { NavLink } from 'react-router-dom';

import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { addBranchPath } from 'app/const/Branch';

const ItemSmartView = ({
    pathTo,
    name = '',
    definition,
    isSearching = false,
    dragHandleProps = {},
    onEdit = () => {}
}) => {
    const history = useHistory();
    const _handleClick = () => {
        history.push({ pathname: addBranchPath(pathTo), state: { isLoadingSideBar: false, definition, name } });
    };
    return (
        <>
            {!isSearching ? (
                <div className="v2-btn-default --icon-sm --transparent --drag" {...dragHandleProps}>
                    <IconThreeDots isDouble />
                </div>
            ) : null}
            <NavLink
                to={{ pathname: addBranchPath(pathTo), state: { isLoadingSideBar: false, definition, name } }}
                onTouchEnd={_handleClick}
                activeClassName="fw-600"
                className="txt-ellipsis flex-1"
            >
                {name}
            </NavLink>
            <div className="v2-btn-default --icon-sm --transparent btn-modal" onClick={onEdit}>
                <IconEdit />
            </div>
        </>
    );
};

export default ItemSmartView;
