import React, { useReducer, useEffect, useRef } from 'react';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { SQUARE_ADDONS_LOCATIONS } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { DEFAULT_ITEM_CODE_API_SQUARE } from 'app/const/addons';
import { reducer } from 'app/const/Reducer';

const SquareCodeDropdown = ({ isNotConnect = false }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        locations: [],
        selectedLocation: DEFAULT_ITEM_CODE_API_SQUARE,
        isEditing: false
    });
    const refDropdown = useRef(null);
    const refInput = useRef(null);
    const refLocationID = useRef('');
    const { isEditing, locations, selectedLocation, oldLocationId } = state;

    useEffect(() => {
        _handleGetListLocations();
    }, []);

    const _handleGetListLocations = () => {
        const _getListLocationsSuccess = ({ data, location_id }) => {
            dispatchState({
                locations: [DEFAULT_ITEM_CODE_API_SQUARE, ...data],
                selectedLocation: data.find((item) => item.id === location_id) || DEFAULT_ITEM_CODE_API_SQUARE,
                oldLocationId: location_id
            });
            refLocationID.current = location_id;
        };

        /* This is a client side query that is fetching the list of locations from the server. */
        clientQuery(SQUARE_ADDONS_LOCATIONS, { data: {}, method: 'GET' }, _getListLocationsSuccess);
    };

    /**
     * This function is called when the user clicks the edit button.
     * It changes the state of the application to indicate that the user is editing the text.
     */
    const _handleToggleEdit = () => {
        dispatchState({ isEditing: !isEditing });
    };

    /**
     * When the user selects a location, the selected location is updated in the state.
     */
    const _handleSelect = (id) => {
        dispatchState({
            selectedLocation: locations.find((item) => item.id === id) || DEFAULT_ITEM_CODE_API_SQUARE
        });
        refDropdown.current._closeDropdown();
    };

    const _handleUpdate = () => {
        /* If the user is not connected, then we need to make a request to the server to add the
        location to the user's account. */
        if (isNotConnect) {
            const locationId = selectedLocation.id;
            if (refLocationID.current === locationId) return dispatchState({ isEditing: false });
            refLocationID.current = locationId;
            clientQuery(SQUARE_ADDONS_LOCATIONS, {
                data: { location_id: locationId },
                method: 'PUT'
            });
        } else {
            clientQuery(SQUARE_ADDONS_LOCATIONS, {
                data: { location_id: refInput.current.value },
                method: 'PUT'
            });
        }

        _handleToggleEdit();
    };

    const _renderListOptions = () => {
        return locations.map((item) => {
            return (
                <li
                    key={item.id}
                    className={classNames('items', { active: selectedLocation.id === item.id })}
                    onClick={() => _handleSelect(item.id)}
                >
                    <p className="txt-ellipsis">{item.address}</p>
                </li>
            );
        });
    };

    return (
        <div className="has-form">
            <span className="txt mb-1">Square API Code</span>
            <div className="d-flex">
                {!isNotConnect ? (
                    <input
                        ref={refInput}
                        defaultValue={selectedLocation.id || oldLocationId || ''}
                        className={classNames('field-input', { 'is-disable': !isEditing })}
                        type="text"
                        placeholder="Paste your api code here..."
                    />
                ) : (
                    <CalendarDropdown
                        ref={refDropdown}
                        id="dropdown-api-code-square"
                        wrapperClassName={classNames('v2-dropdown square-api-code', { disable: !isEditing })}
                        buttonClassName="dropbtn items --grey-light"
                        customDropButton={() => (
                            <>
                                <span className="txt-ellipsis">{selectedLocation.address}</span>
                                <div className="arrow">
                                    <IconArrowDown />
                                </div>
                            </>
                        )}
                    >
                        <ul>{_renderListOptions()}</ul>
                    </CalendarDropdown>
                )}

                {isEditing ? (
                    <div className="v2-btn-main ml-2 js-show-btn" onClick={_handleUpdate}>
                        {t('save')}
                    </div>
                ) : (
                    <div className="v2-btn-default --grey ml-2 js-hide-btn" onClick={_handleToggleEdit}>
                        {t('edit')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SquareCodeDropdown;
