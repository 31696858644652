import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconPlay from 'assets/icon/IconPlay';
import AIConversationDetail from '../testSmsChatbot/AIConversationDetail';

const ButtonAIChatBot = () => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, { isVisibleConversation: false });
    const { isVisibleConversation } = state;

    const _onOpenSMSChatBot = () => {
        dispatchState({ isVisibleConversation: true });
    };

    const _onCloseSMSChatBot = () => {
        dispatchState({ isVisibleConversation: false });
    };

    return (
        <div className="box-test-sms boxs-notification w-100">
            <div
                className={classNames('v2-btn-main has-icon --bg-green w-100 justify-center', {
                    'is-disable': isVisibleConversation
                })}
                onClick={_onOpenSMSChatBot}
            >
                <IconPlay isWhite />
                {t('test_chatbot')}
            </div>
            {isVisibleConversation ? <AIConversationDetail onClose={_onCloseSMSChatBot} /> : null}
        </div>
    );
};

export default ButtonAIChatBot;
