import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import IconSync from 'assets/icon/IconSync';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CustomerPaymentActions = ({
    totalChecked = 0,
    totalItems = 0,
    isLoadCustomerSuccess = false,
    onDelete = () => {},
    onHandleQuickBook = () => {},
    onAddPayment = () => {},
    checkedItems = {}
}) => {
    const { t } = useTranslation(['common', 'customers']);
    const { quickbooks } = useSelector(({ auth }) => auth.user.settings.addons);
    return (
        <div className="header --filter">
            <div className={classNames('header__left flex-1', { 'is-disable': !totalChecked })}>
                <CheckBoxHeader isShowTotal total={totalItems} checkedItems={checkedItems} />
                <div className="v2-btn-default has-icon svg-delete-grey header-items" onClick={() => onDelete()}>
                    <IconTrash />
                    {t('customers:delete')}
                </div>
                {!!quickbooks && (
                    <div className="v2-btn-default has-icon header-items" onClick={onHandleQuickBook}>
                        <IconSync />
                        {t('customers:sync_to_qb')}
                    </div>
                )}
            </div>

            <div
                className={classNames('v2-btn-main btn-modal --bg-green', { 'is-disable': isLoadCustomerSuccess })}
                onClick={onAddPayment}
            >
                {t('customers:new_payment')}
            </div>
        </div>
    );
};

export default CustomerPaymentActions;
