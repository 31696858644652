import classNames from 'classnames';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Input from 'app/components/input/Input';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { ESTIMATE, LIST_ESTIMATE_ANNUAL_REVENUE, LIST_TEAM_SIZE, TEAMSIZE } from './const';

const TrialStepTwo = ({
    fileTranslation = 'auth',
    infoTrial = {},
    error = {},
    handleFocus = () => {},
    handleSetValue = () => {},
    onChangeInput = () => {},
    onKeyPress = () => {}
}) => {
    const { t } = useTranslation(fileTranslation);

    const [state, dispatchState] = useReducer(reducer, {
        team_size: infoTrial?.team_size || '',
        estimate: infoTrial?.estimate || ''
    });

    const { team_size, estimate } = state;

    const refDropdownTeamSize = useRef(null);
    const refDropdownEstimateAnnual = useRef(null);

    const _renderListTeamSize = () => {
        return (
            <ul>
                {LIST_TEAM_SIZE.map(({ id, value }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: TEAMSIZE, value });
                        dispatchState((prev) => ({ ...prev, [TEAMSIZE]: value }));
                        refDropdownTeamSize.current?._close();
                        handleFocus();
                    };

                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: team_size === value })}
                            onClick={onClickItem}
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownTeamSize = () => {
        return (
            <Fragment>
                <p className="txt-ellipsis">{team_size || t('what_your_team_size')}</p>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _renderListEstimateAnnual = () => {
        return (
            <ul>
                {LIST_ESTIMATE_ANNUAL_REVENUE.map(({ id, value }) => {
                    const onClickItem = () => {
                        handleSetValue({ key: ESTIMATE, value });
                        dispatchState((prev) => ({ ...prev, [ESTIMATE]: value }));
                        refDropdownEstimateAnnual.current?._close();
                        handleFocus();
                    };

                    return (
                        <li
                            key={id}
                            className={classNames('items', { active: estimate === value })}
                            onClick={onClickItem}
                        >
                            {value}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderButtonDropdownEstimateAnnual = () => {
        return (
            <Fragment>
                <p className="txt-ellipsis">{estimate || t('what_your_estimated_annual_revenue')}</p>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="box-input">
                <Input
                    placeholder={t('auth:company_name')}
                    type="text"
                    name="company_name"
                    initValue={infoTrial?.company_name}
                    classWrapper="user-box rows"
                    error={error?.company_name}
                    isShowIconError={false}
                    onFocus={handleFocus}
                    onChange={onChangeInput}
                    handleKeypress={onKeyPress}
                />
            </div>
            <DropdownPopper
                ref={refDropdownTeamSize}
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls"
                buttonClassName="dropbtn items"
                isUseToggle
                isCalculateWidth
                customButton={_renderButtonDropdownTeamSize()}
                error={error?.team_size}
                isShowIconError={false}
            >
                {_renderListTeamSize()}
            </DropdownPopper>
            <DropdownPopper
                ref={refDropdownEstimateAnnual}
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls"
                buttonClassName="dropbtn items"
                isUseToggle
                isCalculateWidth
                customButton={_renderButtonDropdownEstimateAnnual()}
                error={error?.estimate}
                isShowIconError={false}
            >
                {_renderListEstimateAnnual()}
            </DropdownPopper>
        </Fragment>
    );
};

export default TrialStepTwo;
