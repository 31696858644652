import ButtonSave from 'app/components/button/ButtonSave';
import React, { forwardRef, useContext, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SetupEmailContext } from '../../context/SetupEmailContext';

const FooterSetupEmail = ({ onClose = () => {}, onNextStep = () => {} }, ref) => {
    const { t } = useTranslation();
    const { step } = useContext(SetupEmailContext);
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ removeLoading: () => refButtonSave.current.removeLoading() }));

    return (
        <div className="footer-modal btn-close">
            <span className="v2-btn-default --transparent" onClick={onClose}>
                {t('cancel')}
            </span>
            {step !== 3 && step !== 4 && (
                <div className="v2-btn-main" onClick={onNextStep}>
                    {t('next')}
                </div>
            )}
            {step === 4 && (
                <ButtonSave ref={refButtonSave} className="v2-btn-main" onSave={onNextStep}>
                    {t('save')}
                </ButtonSave>
            )}
        </div>
    );
};

export default forwardRef(FooterSetupEmail);
