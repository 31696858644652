import React from 'react';

import IconClose from 'assets/icon/IconClose';

const ModalHeader = ({ title = '', onClose = () => {} }) => {
    return (
        <div className="header-modal">
            <h3 className="header-modal__label">{title}</h3>
            <div className="v2-btn-default --icon-lg --transparent" onClick={onClose}>
                <IconClose />
            </div>
        </div>
    );
};

export default ModalHeader;
