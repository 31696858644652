import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumns = (columns = [], { order, deleted, isHaveACH = false }) => {
    const HEADER_CUSTOMER = {
        status: {
            title: 'status',
            style: 'col col-xs',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        account_number: {
            title: 'account_number_#',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        customer: {
            title: 'customer',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        title: {
            title: 'title',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        first_name: {
            title: 'first_name',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        last_name: {
            title: 'last_name',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phones: {
            title: 'phones',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phones_office: {
            title: 'phones_office',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phones_mobile: {
            title: 'phones_mobile',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phones_home: {
            title: 'phones_home',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        emails: {
            title: 'email',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        company: {
            title: 'company',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address: {
            title: 'service_address',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_1: {
            title: 'service_address_1',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_2: {
            title: 'service_address_2',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_city: {
            title: 'service_address_city',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_state: {
            title: 'service_address_state',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_zip: {
            title: 'service_address_zip',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_address: {
            title: 'billing_address',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_address_1: {
            title: 'billing_address_1',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_address_city: {
            title: 'billing_address_city',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_address_state: {
            title: 'billing_address_state',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        billing_address_zip: {
            title: 'billing_address_zip',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        credit: {
            title: 'credit_card',
            style: 'col --card',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        bank_accounts: {
            title: 'bank_ach',
            style: 'col --card',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        balance: {
            title: 'balance',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        added_by: {
            title: 'added_by',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        created_at: {
            title: 'date_added',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        source: {
            title: 'source',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col col-xs --qb flex-none'
        },
        qb_id: {
            title: 'qb_id',
            style: 'col col-xs --qb flex-none'
        },
        edit: { title: '', style: 'col col-xs flex-none' }
    };

    const CUSTOMER_COLUMN = [
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'account_number',
            title: 'account_number',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'title',
            title: 'title',
            status: true
        },
        {
            id: 'first_name',
            title: 'first_name',
            status: true
        },
        {
            id: 'last_name',
            title: 'last_name',
            status: true
        },
        {
            id: 'phones',
            title: 'phones',
            status: true
        },
        {
            id: 'phones_office',
            title: 'phones_office',
            status: true
        },
        {
            id: 'phones_mobile',
            title: 'phones_mobile',
            status: true
        },
        {
            id: 'phones_home',
            title: 'phones_home',
            status: true
        },
        {
            id: 'emails',
            title: 'email',
            status: true
        },
        {
            id: 'company',
            title: 'company',
            status: true
        },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'service_address_1',
            title: 'service_address_1',
            status: true
        },
        {
            id: 'service_address_2',
            title: 'service_address_2',
            status: true
        },
        {
            id: 'service_address_city',
            title: 'service_address_city',
            status: true
        },
        {
            id: 'service_address_state',
            title: 'service_address_state',
            status: true
        },
        {
            id: 'service_address_zip',
            title: 'service_address_zip',
            status: true
        },
        {
            id: 'billing_address',
            title: 'billing_address',
            status: true
        },
        {
            id: 'billing_address_1',
            title: 'billing_address_1',
            status: true
        },
        {
            id: 'billing_address_city',
            title: 'billing_address_city',
            status: true
        },
        {
            id: 'billing_address_state',
            title: 'billing_address_state',
            status: true
        },
        {
            id: 'billing_address_zip',
            title: 'billing_address_zip',
            status: true
        },
        {
            id: 'credit',
            title: 'credit_card',
            status: true
        },
        {
            id: 'bank_accounts',
            title: 'bank_ach',
            status: true
        },
        {
            id: 'balance',
            title: 'balance',
            status: true
        },
        {
            id: 'added_by',
            title: 'added_by',
            status: true
        },
        {
            id: 'created_at',
            title: 'date_added',
            status: true
        },
        {
            id: 'source',
            title: 'source',
            status: true
        },
        {
            id: 'qb_sync',
            title: 'qb_sync',
            status: true
        },
        {
            id: 'qb_id',
            title: 'qb_id',
            status: true
        },
        { id: 'edit', title: '', status: true }
    ];

    const CUSTOMER_COLUMN_CONTENT = {
        status: {
            title: 'status',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_STATUS
        },
        account_number: {
            title: 'account_number',
            style: 'col col-sm'
        },
        customer: {
            title: 'customer',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            urlConfig: {
                isOpenNewTab: false
            }
        },
        title: {
            title: 'title',
            style: 'col col-sm'
        },
        first_name: {
            title: 'first_name',
            style: 'col col-sm'
        },
        last_name: {
            title: 'last_name',
            style: 'col col-sm'
        },
        phones: {
            title: 'phones',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        phones_office: {
            title: 'phones_office',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        phones_mobile: {
            title: 'phones_mobile',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        phones_home: {
            title: 'phones_home',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        emails: {
            title: 'email',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_EMAIL_LIST
        },
        company: {
            title: 'company',
            style: 'col'
        },
        service_address: {
            title: 'service_address',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMERS_ADDRESS
        },
        service_address_1: {
            title: 'service_address_1',
            style: 'col col-lg'
        },
        service_address_2: {
            title: 'service_address_2',
            style: 'col col-lg'
        },
        service_address_city: {
            title: 'service_address_city',
            style: 'col col-lg'
        },
        service_address_state: {
            title: 'service_address_state',
            style: 'col col-xl'
        },
        service_address_zip: {
            title: 'service_address_zip',
            style: 'col col-lg'
        },
        billing_address: {
            title: 'billing_address',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMERS_ADDRESS
        },
        billing_address_1: {
            title: 'billing_address_1',
            style: 'col col-lg'
        },
        billing_address_city: {
            title: 'billing_address_city',
            style: 'col col-lg'
        },
        billing_address_state: {
            title: 'billing_address_state',
            style: 'col col-lg'
        },
        billing_address_zip: {
            title: 'billing_address_zip',
            style: 'col col-lg'
        },
        credit: {
            title: 'credit_card',
            style: 'col --card',
            type: LIST_TABLE_ITEM_TYPE.CREDIT_CARD
        },
        bank_accounts: {
            title: 'bank_ach',
            style: 'col --card',
            type: LIST_TABLE_ITEM_TYPE.BANK_ACH
        },
        balance: {
            title: 'balance',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        added_by: {
            title: 'added_by',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CREATED_BY
        },
        created_at: {
            title: 'date_added',
            style: 'col'
        },
        source: {
            title: 'source',
            style: 'col'
        },
        qb_sync: {
            title: 'qb_sync',
            style: 'col --qb col-xs flex-none',
            type: LIST_TABLE_ITEM_TYPE.QB_SYNC
        },
        qb_id: {
            title: 'qb_id',
            style: 'col col-xs --qb flex-none',
            type: LIST_TABLE_ITEM_TYPE.QB_ID
        },
        edit: { title: '', style: 'col col-xs flex-none', type: LIST_TABLE_ITEM_TYPE.CUSTOMER_EDIT }
    };

    const newHeader = mapCurrentValueHeader(order, { ...HEADER_CUSTOMER });
    const newContent = {};
    const newColumns = [];

    if (columns.length) {
        CUSTOMER_COLUMN.forEach((col) => {
            const item = col.id;
            if (columns.includes(item) || (item === 'edit' && !deleted)) {
                newContent[item] = CUSTOMER_COLUMN_CONTENT[item];
                if (item === COMMON.CUSTOMER) {
                    newContent[item].isLink = !deleted;
                }
                if (item === COMMON.BANK_ACH && !isHaveACH) return;
                newColumns.push(col);
            }
        });
    }

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
