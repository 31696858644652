import { KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE, DEFAULT_OFFSET, PAGE_SIZE_DEFAULT } from 'app/const/App';

export const getDefaultCustomerDetailParams = (customerDetailPageName) => {
    const CUSTOMER_DETAIL_DEFAULT_PARAMS = {
        [KEY_CUSTOMER_DETAIL_CONTACT_LOCAL_STORAGE]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: DEFAULT_OFFSET,
            total: 1
        }
    };

    return CUSTOMER_DETAIL_DEFAULT_PARAMS[customerDetailPageName];
};
