import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const EstimateService = ({ onOpenEstimateDetail, onCloseEstimate }) => {
    const { estimateData, closeEstimate } = useSelector(({ estimateReducer }) => estimateReducer);
    const { openTime } = useSelector(({ notesReducer }) => notesReducer.toggleInsert || {});
    const openTimeInsertImages = useSelector(({ photosReducer }) => photosReducer.toggleInsert?.openTime);

    const dispatch = useDispatch();

    useEffect(() => {
        onOpenEstimateDetail(estimateData);

        if (!estimateData) {
            if (openTime) {
                dispatch(actionToggleInsertJobNotes(null));
            }
            if (openTimeInsertImages) {
                dispatch(actionToggleInsertJobImages(null));
            }
        }
    }, [estimateData]);

    useEffect(() => {
        closeEstimate && onCloseEstimate();
    }, [closeEstimate]);

    return false;
};

export default EstimateService;
