export default function IconEmoji() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 3.5H9.5C6.18629 3.5 3.5 6.18629 3.5 9.5V14.5C3.5 17.8137 6.18629 20.5 9.5 20.5H14.5C17.8137 20.5 20.5 17.8137 20.5 14.5V9.5C20.5 6.18629 17.8137 3.5 14.5 3.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M3.5 9.5L5.58787 7.41213C6.69969 6.30031 8.44172 6.12781 9.75 7L10.8906 7.7604C11.5624 8.20827 12.4376 8.20827 13.1094 7.7604L14.25 7C15.5583 6.12781 17.3003 6.30031 18.4121 7.41213L20.5 9.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M3.5 12.5L6.5 15.5V17.5C6.5 19.1569 7.84315 20.5 9.5 20.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M20.5 12.5L17.5 15.5V17.5C17.5 19.1569 16.1569 20.5 14.5 20.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 17.5H12.5C13.0523 17.5 13.5 17.0523 13.5 16.5V16"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 9.5C8.82843 9.5 9.5 10.1716 9.5 11V11.5C9.5 12.0523 9.05228 12.5 8.5 12.5H7.5C6.94772 12.5 6.5 12.0523 6.5 11.5V11C6.5 10.1716 7.17157 9.5 8 9.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 11.5C8.27614 11.5 8.5 11.7239 8.5 12C8.5 12.2761 8.27614 12.5 8 12.5C7.72386 12.5 7.5 12.2761 7.5 12C7.5 11.7239 7.72386 11.5 8 11.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 9.5C16.8284 9.5 17.5 10.1716 17.5 11V11.5C17.5 12.0523 17.0523 12.5 16.5 12.5H15.5C14.9477 12.5 14.5 12.0523 14.5 11.5V11C14.5 10.1716 15.1716 9.5 16 9.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 11.5C16.2761 11.5 16.5 11.7239 16.5 12C16.5 12.2761 16.2761 12.5 16 12.5C15.7239 12.5 15.5 12.2761 15.5 12C15.5 11.7239 15.7239 11.5 16 11.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M11.5 13.5H12.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
