import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import GridLoading from 'app/components/grid/components/GridLoading';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import ItemJobStatus from 'app/components/job/ItemJobStatus';

const JobStatusManageDelete = ({
    loading = true,
    isAllowUndelete = true,
    jobStatusList = [],
    checkedItems = [],
    onCheckedItems = () => {}
}) => {
    const { t } = useTranslation(['calendar', 'addons']);

    const _renderTableJobStatus = () => {
        if (loading) return <GridLoading />;
        if (!jobStatusList.length) return <GridEmpty isFlat />;

        return (
            <div className="tables-list">
                {jobStatusList.map((item) => (
                    <div className="rows" key={item.id}>
                        <div className={classNames('col --checkbox', { 'is-disable': !isAllowUndelete })}>
                            <div className="check-items">
                                <input
                                    id="check_inv1"
                                    type="checkbox"
                                    checked={checkedItems.some((checkedItems) => checkedItems.id === item.id)}
                                    onChange={() => {}}
                                />
                                <div className="item-checkbox" onClick={() => onCheckedItems(item)}>
                                    <label htmlFor="check_inv1"></label>
                                </div>
                            </div>
                        </div>
                        <div className="col col-job-status flexcenter gap-4">
                            <ItemJobStatus defaultStatus={item} excludeWrapper />
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="tables table-multi-column has-checkbox has-footer">
            <div className="rows --fixed --header">
                <div className="col --checkbox"></div>
                <div className="col col-job-status">
                    <p className="col-label">{t('calendar:job_status')}</p>
                </div>
            </div>
            {_renderTableJobStatus()}
        </div>
    );
};

export default JobStatusManageDelete;
