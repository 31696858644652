import classNames from 'classnames';
import { React } from 'react';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const ZapierAddonsContent = ({ keyword, name, upgrade, status, level, sub_title }) => {
    return (
        <>
            <div className={classNames('boxs boxs--no-border', { '--hasline': !upgrade })}>
                <AddonsBoxHeader
                    keyword={keyword}
                    name={name}
                    upgrade={upgrade}
                    level={level}
                    status={status}
                    isHideCheckbox
                />
                <div className="boxs__contents mt-3">
                    <p className="description mt-5">{sub_title}</p>
                </div>
            </div>
        </>
    );
};

export default ZapierAddonsContent;
