import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { ERROR_CODE, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { REPORT_TYPE } from 'app/const/Reports';
import { TYPE_OPTIONS_PIPELINE } from 'app/const/opportunity';
import { OPPORTUNITIES_PIPELINE_LIST_FILTER } from 'app/const/report/ReportFilter';
import { getDefaultParams } from 'app/const/report/ReportParams';
import Opportunity from 'app/modules/customer/detail/layouts/components/sideMenu/opportunity';
import ErrorPage from 'app/modules/error';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import { getListPipelines } from 'common/redux/actions/settings/pipelinesAction';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import FilterOpportunity from '../FilterOpportunity';
import ColumnDraggable from './ColumnDraggable';
import { LoadingPipeline } from './LoadingPipeline';
import ServicePipeline from './ServicePipeline';
import { LIST_OPTIONS_SELECT_DATE_DEFAULT, OPTION_SELECT_DATE_RANGE_PICKER } from 'app/const/drip/Common';
import GdConfirm from 'app/components/confirm';
import { addBranchPath } from 'app/const/Branch';
import { ACCESS_PIPELINE_REPORT } from 'app/const/Permissions';
import { ROUTE_OPPORTUNITY_REPORT } from '../../const';

let timer = null;

const OpportunitiesPipeline = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled) || [];

    const refOpp = useRef(null);
    const refModal = useRef(null);
    const refModalAlert = useRef(null);
    const refService = useRef(null);
    const refDataEdit = useRef(null);
    const refCols = useRef([]);
    const refButtonUpdate = useRef(null);
    const refVisibleModal = useRef(false);
    const refFilter = useRef(false);

    const isAccessOpportunity = !!opportunityAddon && checkPermission(permissionsList, ACCESS_PIPELINE_REPORT);
    const permissionKey = permissionsList.find((permission) => !!ROUTE_OPPORTUNITY_REPORT[permission]);
    const keyLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.OPPORTUNITIES_PIPELINE);

    const {
        isFirstTime,
        data: dataPipelines,
        isLoading: isLoadingPipelines
    } = useSelector(({ pipelines }) => pipelines);

    const [state, dispatchState] = useReducer(reducer, {
        pipelines: [],
        isLoading: true,
        reload: 0,
        isDisable: false
    });

    const { pipelines: finalPipelines, reload, isDisable } = state;

    useEffect(() => {
        const paramsReport = getLocalStorage(keyLocalStorage);
        if (!paramsReport) setLocalStorage(keyLocalStorage, getDefaultParams(REPORT_TYPE.OPPORTUNITIES_PIPELINE));
    }, []);

    useEffect(() => {
        if (!isAccessOpportunity) {
            if (!!opportunityAddon && !!permissionKey)
                history.push(addBranchPath(ROUTE_OPPORTUNITY_REPORT[permissionKey]));
            return;
        }

        if (isFirstTime) {
            dispatch(getListPipelines());
            return;
        }

        if (!!dataPipelines[0]?.pipelines) {
            dispatchState((prev) => ({
                ...prev,
                pipelines: [...dataPipelines[0].pipelines],
                isLoading: true,
                reload: Date.now().toString()
            }));
        }
    }, [isFirstTime]);

    const _handleUpdate = () => {
        _toggleBtnUpdate(true);
        dispatchState({ reload: Date.now().toString() });
    };

    const _handleEditItem = ({ type = TYPE_OPTIONS_PIPELINE.EDIT, data, contacts }) => {
        switch (type) {
            case TYPE_OPTIONS_PIPELINE.EDIT:
                refModal.current.classList.add('open');
                refDataEdit.current = data;
                return refOpp.current.open(data, contacts);
            case TYPE_OPTIONS_PIPELINE.DELETE:
                return refOpp.current.delete(data);
            default:
                return;
        }
    };

    const _handleCloseModal = (isOverlay = false) => {
        isOverlay && refOpp.current.close();
        refDataEdit.current = null;
        refModal.current.classList.remove('open');
    };

    const _handleDeleteSuccess = (id, pipelineId) => {
        refService.current.delete({ id, pipelineIndex: finalPipelines.findIndex((item) => item.id === pipelineId) });
    };

    const _handleDeleteFail = ({ pipelineId, message, success }) => {
        refCols.current[finalPipelines.findIndex((item) => item.id === pipelineId)].showAlert({ message, success });
    };

    const _handleSubmitSuccess = (data) => {
        refService.current.update(data, refDataEdit.current);
        refDataEdit.current = null;
    };

    const _handleContact = (data) => {
        refService.current.contact(data);
    };

    const _handleUpdateStatus = ({ destination, source }) => {
        refCols.current[source.droppableId].setDropDisable(false);
        refService.current.moveOpp({ destination, source });
    };

    const _toggleBtnUpdate = (value = true) => {
        refButtonUpdate.current && refButtonUpdate.current.classList[value ? 'add' : 'remove']('is-disable');
    };

    const _handleBeforeDragStart = ({ source }) => {
        refCols.current[source.droppableId].setDropDisable(true);
    };

    const _handleCloseModalConfirm = () => {
        refModalAlert.current.close();
        if (refVisibleModal.current === ERROR_CODE.UNPROCESSABLE) {
            dispatchState({ isDisable: true });
            return;
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            refVisibleModal.current = false;
        }, 3000);
    };

    const _handleShowModalConfirm = ({ message, statusCode }) => {
        if (!!refVisibleModal.current) return;
        refVisibleModal.current = statusCode;
        refModalAlert.current?.open(null, message);
    };

    const _handleSetUserDeleted = (value = true) => {
        refFilter.current?.setShowUserDeleted(value);
    };

    if (!opportunityAddon || (!isAccessOpportunity && !permissionKey))
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;

    return (
        <Fragment>
            <div className={classNames('nav-top header flexcenter', { 'is-disable': isDisable })}>
                <ReportDateRangePicker
                    reportType={REPORT_TYPE.OPPORTUNITIES_PIPELINE}
                    onSelect={_handleUpdate}
                    customInputClassName="header-items field-input field-date"
                    listOptionsDateDefault={[
                        OPTION_SELECT_DATE_RANGE_PICKER.ALL_TIME,
                        ...LIST_OPTIONS_SELECT_DATE_DEFAULT
                    ]}
                />
                <FilterOpportunity
                    ref={refFilter}
                    reportType={REPORT_TYPE.OPPORTUNITIES_PIPELINE}
                    filters={OPPORTUNITIES_PIPELINE_LIST_FILTER}
                    onSelect={() => _toggleBtnUpdate(false)}
                />
                <div ref={refButtonUpdate} className="header-items v2-btn-main is-disable" onClick={_handleUpdate}>
                    {t('common:update')}
                </div>
            </div>
            {isLoadingPipelines ? (
                <LoadingPipeline />
            ) : (
                <div className="opportunity-container">
                    <DragDropContext onDragEnd={_handleUpdateStatus} onBeforeDragStart={_handleBeforeDragStart}>
                        {finalPipelines?.map((item, index) => (
                            <ColumnDraggable
                                ref={(ref) => (refCols.current[index] = ref)}
                                key={item.id}
                                index={index}
                                initialState={item}
                                onEditItem={_handleEditItem}
                                onContact={_handleContact}
                                reload={reload}
                                onShowModalConfirm={_handleShowModalConfirm}
                                onShowUserDeleted={_handleSetUserDeleted}
                                isDisable={isDisable}
                            />
                        ))}
                    </DragDropContext>
                </div>
            )}

            <div ref={refModal} id="edit_opportunity" className={'modal container-modal edit-opportunity'}>
                <div className="modal__overlay bg-fixed" onClick={() => _handleCloseModal(true)} />
                <Opportunity
                    ref={refOpp}
                    wrapperClassName="modal__container"
                    onClose={() => _handleCloseModal()}
                    onDeleteSuccess={_handleDeleteSuccess}
                    onDeleteFail={_handleDeleteFail}
                    onSubmitSuccess={_handleSubmitSuccess}
                />
            </div>
            <GdConfirm
                ref={refModalAlert}
                title={t('common:notification')}
                onClose={_handleCloseModalConfirm}
                footer={
                    <div className="footer-modal footer-hasbtn btn-close">
                        <div className={'v2-btn-main text-uppercase'} onClick={_handleCloseModalConfirm} tabIndex="0">
                            {t('confirm')}
                        </div>
                    </div>
                }
            />
            <ServicePipeline ref={refService} refCols={() => refCols} onShowModalConfirm={_handleShowModalConfirm} />
        </Fragment>
    );
};

export default OpportunitiesPipeline;
