import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getListNotificationRequest, updateMarkReadAndUnReadRequest } from 'common/redux/actions/notificationAction';
import { GET_NOTIFICATION_TIMEOUT, NOTIFICATION_SELECTALL_STATE, TAB_NOTIFICATIONS } from 'app/const/Header';
import NotificationService from './Service';
import loadable from '@loadable/component';

const NotiDropdownHeader = loadable(() => import('app/modules/notifications/components/NotiDropdownHeader'));
const NotificationList = loadable(() => import('app/modules/notifications/components/NotificationList'));

const getSelectAllState = (markedList, listNotifications) => {
    let selectAll = NOTIFICATION_SELECTALL_STATE.UNCHECK;
    if (markedList.length === listNotifications.length) {
        selectAll = NOTIFICATION_SELECTALL_STATE.CHECK;
    } else {
        if (markedList.length > 0) {
            selectAll = NOTIFICATION_SELECTALL_STATE.SOME;
        }
    }
    if (markedList.length === 0 && listNotifications.length === 0) {
        selectAll = NOTIFICATION_SELECTALL_STATE.UNCHECK;
    }
    return selectAll;
};

const reducer = (state, action) => {
    if (typeof action !== 'object') {
        return false;
    }

    const next = { ...state, ...action };
    const currentTabState = action?.currentTab;

    if (typeof currentTabState !== 'undefined' && currentTabState !== state.currentTab) {
        next.status = currentTabState === TAB_NOTIFICATIONS.UNREAD ? 0 : 1;
        next.offset = 0;
        next.markedList = [];
        next.listNotifications = [];
        next.selectAll = NOTIFICATION_SELECTALL_STATE.UNCHECK;
        return next;
    }

    if (next.listNotifications.length === 0) {
        next.offset = 0;
    }

    next.refesh = !state.refesh;
    next.selectAll = getSelectAllState(next.markedList, next.listNotifications);
    return next;
};

const Notification = ({ clsActive, onClose, totalUnread = 0, totalRead = 0, onChangeTotalUnread }) => {
    const [state, dispatchAction] = useReducer(reducer, {
        status: 0,
        offset: 0,
        limit: 20,
        markedList: [],
        listNotifications: [],
        loading: true,
        selectAll: NOTIFICATION_SELECTALL_STATE.UNCHECK,
        currentTab: TAB_NOTIFICATIONS.UNREAD,
        marking: false,
        refesh: false
    });

    const fetchFirstTime = useRef(true);
    const lastTimeFetch = useRef(0);
    const dispatch = useDispatch();

    const { listNotifications, loading, markedList, marking, selectAll, currentTab, offset, limit, status } = state;

    /**
     * Detect first time init notification
     * If close modal list we will check over lastime fetch call api. If over need reset current lists.
     */
    useEffect(() => {
        const lastTime = lastTimeFetch.current || 0;

        if (!clsActive && Date.now() - lastTime > GET_NOTIFICATION_TIMEOUT) {
            fetchFirstTime.current = true;
            dispatchAction({ listNotifications: [], markedList: [], offset: 0 });
        }

        if (clsActive && fetchFirstTime.current) {
            handleGetListNotification();
        }
    }, [clsActive]);

    /**
     * Detect if curreent list < 10 we will add more. Avoid blank not lost notification.
     * We will have 2 case, Read & unread
     */
    useEffect(() => {
        const finalNumber = currentTab === TAB_NOTIFICATIONS.READ ? totalRead : totalUnread;

        if (listNotifications.length !== finalNumber && listNotifications.length < 10 && !fetchFirstTime.current) {
            handleOnScrollToEnd();
        }
    }, [totalUnread, totalRead]);

    /**
     * Change Tab will call api again with offet 0
     */
    useEffect(() => {
        clsActive && !fetchFirstTime.current && handleGetListNotification();
    }, [currentTab]);

    const handleGetListNotificationSuccess = (currentTab, resp) => {
        if (currentTab !== currentTab) {
            return false;
        }

        const newListNotifications = [...listNotifications, ...(resp?.data || [])];

        lastTimeFetch.current = Date.now();

        fetchFirstTime.current = false;

        dispatchAction({
            listNotifications: newListNotifications,
            offset: newListNotifications.length,
            loading: false
        });
    };

    const handleOnGetListNotificationFailed = () => {
        dispatchAction({ loading: false });
    };

    const handleGetListNotification = (opts = {}) => {
        dispatchAction({ loading: true });

        dispatch(
            getListNotificationRequest(
                {
                    status,
                    offset,
                    limit,
                    ...opts
                },
                (response) => handleGetListNotificationSuccess(currentTab, response),
                handleOnGetListNotificationFailed
            )
        );
    };

    const handleOnChangeMarkedList = (id, e) => {
        e.stopPropagation();

        let newMarkedList = [...markedList];
        let findNotiId = false;

        newMarkedList = newMarkedList.filter((item) => {
            if (item === id) {
                findNotiId = true;
            } else {
                return item;
            }
        });

        !findNotiId && newMarkedList.push(id);

        dispatchAction({ markedList: newMarkedList });
    };

    const handleUpdateMarkReadOrUnreadSuccess = (currentTab, resp) => {
        if (currentTab !== currentTab || !resp) {
            return false;
        }
        dispatchAction({
            listNotifications: listNotifications.filter((v) => !markedList.includes(v.id)),
            markedList: [],
            marking: false,
            selectAll: NOTIFICATION_SELECTALL_STATE.UNCHECK
        });

        onChangeTotalUnread(markedList.length, currentTab);
    };

    const handleUpdateMarkReadOrUnreadFailed = () => {
        dispatchAction({ marking: false });
    };

    const handleUpdateMarkReadOrUnread = () => {
        dispatchAction({ marking: true });
        dispatch(
            updateMarkReadAndUnReadRequest(
                // eslint-disable-next-line no-undef
                { status: status, ids: markedList, socket_id: global.notificationSocketId || '' },
                (resp) => handleUpdateMarkReadOrUnreadSuccess(currentTab, resp),
                handleUpdateMarkReadOrUnreadFailed
            )
        );
    };

    const handleOnScrollToEnd = () => {
        const numberNoti = currentTab === TAB_NOTIFICATIONS.UNREAD ? totalUnread : totalRead;
        if (listNotifications.length < numberNoti && !loading) {
            handleGetListNotification({ offset: listNotifications.length });
        }
    };

    const _handleClose = () => {
        onClose();
    };

    const handleOnChangeTab = (tab) => {
        dispatchAction({
            currentTab: tab,
            loading: true
        });
    };

    const handleOnClickSelectAll = () => {
        let newMarkedList = [];
        let selectAll = NOTIFICATION_SELECTALL_STATE.UNCHECK;

        if (markedList.length >= 0 && markedList.length !== listNotifications.length) {
            newMarkedList = listNotifications.map((v) => v.id);
            selectAll = NOTIFICATION_SELECTALL_STATE.CHECK;
        }

        dispatchAction({
            markedList: newMarkedList,
            selectAll: selectAll
        });
    };

    const renderNotificationContent = () => {
        return (
            <div
                className={'dropdown notification-dropdown notification-news accessible-tabs-container active'}
                id={'show_list_dropdown_notification_list'}
            >
                <div className="sms-messagebox-content">
                    <NotiDropdownHeader
                        onChangeTab={handleOnChangeTab}
                        selectedTab={currentTab}
                        onClose={_handleClose}
                        total={totalUnread}
                        totalRead={totalRead}
                        hasItems={listNotifications.length > 0}
                        handleUpdateMarkReadOrUnread={handleUpdateMarkReadOrUnread}
                        onClickSelectAll={handleOnClickSelectAll}
                        selectAll={selectAll}
                        marking={marking}
                        loading={loading}
                        markedList={markedList}
                    />
                    <NotificationList
                        tab={currentTab}
                        onClose={_handleClose}
                        contents={listNotifications}
                        onScrollToEnd={handleOnScrollToEnd}
                        loading={loading}
                        onChangeMarkedList={handleOnChangeMarkedList}
                        markedList={markedList}
                        marking={marking}
                    />
                </div>
            </div>
        );
    };

    const _handleUpdateNewData = (newData) => {
        dispatchAction({
            ...newData
        });
    };

    return (
        <>
            {clsActive && renderNotificationContent()}
            <NotificationService
                state={state}
                totalUnread={totalUnread}
                totalRead={totalRead}
                fetchFirstTime={fetchFirstTime.current}
                onUpdateState={_handleUpdateNewData}
                onChangeTotalUnread={onChangeTotalUnread}
            />
        </>
    );
};

Notification.propTypes = {
    classActive: PropTypes.bool,
    onClose: PropTypes.func
};

export default Notification;
