export const TYPE_SETTING_CUSTOM = {
  GET_LIST: "SETTING_GET_LIST_CUSTOMS",
  CREATE: "SETTING_CREATE_CUSTOMS",
  UPDATE: "SETTING_UPDATE_CUSTOMS",
  UPDATE_STATUS: "SETTING_UPDATE_STATUS_CUSTOMS",
  DETAIL: "SETTINGS_DETAIL_CUSTOMS",
};

// actions
export const getListSettingCustoms = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_CUSTOM.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const createCustom = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_CUSTOM.CREATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateCustom = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_CUSTOM.UPDATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateStatusSettingCustoms = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_CUSTOM.UPDATE_STATUS,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const getDetailCustoms = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_CUSTOM.DETAIL,
    params,
    actionSuccess,
    actionFailed,
  };
};

