import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer } from 'app/const/Reducer';

import GdRichText from 'app/components/richtext';
import { CONFIG_TOOLBAR_SMS_TEMPLATE } from 'app/modules/settings/templates/constant';
import IconSentSms from 'assets/icon/IconSentSms';
import IconTooltip from 'assets/icon/IconTooltip';
import PreviewSMSTemplate from './PreviewSMSTemplate';
import { LIMIT_SMS_COUNT } from 'app/const/Settings';

const DetailSMSTemplate = ({ onChange = () => {} }, ref) => {
    const { t } = useTranslation('setting');
    const refPreview = useRef(null);
    const refRichtext = useRef(null);
    const refLength = useRef(null);
    const refData = useRef({});
    const refWordCount = useRef(0);

    const [state, dispatchState] = useReducer(reducer, {
        content: '',
        attachments: []
    });
    const { content: finalContent, attachments: finalAttachments } = state;

    useImperativeHandle(ref, () => ({
        setValue: _handleSetValue,
        getValue: _handleGetValue
    }));

    const _handleSetValue = ({ message, attachments = [] }) => {
        refRichtext.current.setValue(message);
        refRichtext.current.setAttachments(attachments);
        refData.current.attachments = [...attachments];
        _handleChangeAttachments();
        dispatchState({ content: message, attachments });
    };

    const _handleGetValue = () => {
        const { message: content, attachments } = refData.current;
        return { content, attachments, count: refWordCount.current };
    };

    const _handleChangeMsg = (message) => {
        refData.current.message = message;
        refPreview.current.changeMessage({ message });
        setTimeout(() => {
            const wordCount = refRichtext.current.getWordCount();
            refWordCount.current = wordCount;
            refLength.current.innerText = `${wordCount}/${LIMIT_SMS_COUNT}`;
        }, 0);
        onChange();
    };

    const _handleUploadSuccess = (attachment = {}) => {
        if (!refRichtext.current?.getAttachments()?.some((item) => item.object_key === attachment.object_key)) return;

        refData.current.attachments.push({ ...attachment, url: attachment.public_url });
        _handleChangeAttachments();
    };

    const _handleRemove = (idAttach) => {
        const index = refData.current.attachments.findIndex((item) => item.object_key === idAttach);
        if (index === -1) return;
        refData.current.attachments.splice(index, 1);
        _handleChangeAttachments();
    };

    const _handleChangeAttachments = () => {
        refPreview.current.changeAttachments([...refData.current.attachments]);
        onChange();
    };

    const _handleRestore = () => {
        const attachmentsDefault = [...finalAttachments];
        refPreview.current.changeAttachments(attachmentsDefault);
        refData.current.attachments = [...attachmentsDefault];
        refRichtext.current.setValue(finalContent);
    };

    return (
        <div className="template-content__body">
            <PreviewSMSTemplate ref={refPreview} />
            <div className="flex-column gap-6">
                <div className="flexcenter flex-1">
                    <div className="flexcenter svg-sms-yellow">
                        <IconSentSms isFillOrange />
                        <span className="fw-600">{t('sms')}</span>
                    </div>
                    <div className="dots flexcenter">
                        <div className="tooltip">
                            <IconTooltip />
                            <span className="tooltiptext bottom">{t('tooltip_sms_template')}</span>
                        </div>
                        <div ref={refLength} className="ml-1 grey-generic">
                            {`0/${LIMIT_SMS_COUNT}`}
                        </div>
                    </div>
                </div>

                <GdRichText
                    ref={refRichtext}
                    defaultAttachments={finalAttachments}
                    defaultContent={''}
                    isDisableSMS={false}
                    onChange={_handleChangeMsg}
                    toolbarConfig={CONFIG_TOOLBAR_SMS_TEMPLATE}
                    maxLength={LIMIT_SMS_COUNT}
                    isShowListAttachment
                    onUploadAttachmentSuccess={_handleUploadSuccess}
                    onRemoveAttachment={_handleRemove}
                    onRestore={_handleRestore}
                />
            </div>
        </div>
    );
};

export default forwardRef(DetailSMSTemplate);
