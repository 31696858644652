import { gql } from '@apollo/client';

export const UPDATE_SMART_VIEW_MUTATION = gql`
    mutation updateSmartView($id: String!, $data: UpdateSmartView!) {
        updateSmartView(id: $id, data: $data) {
            id
            name
            definition {
                query
                sort
                limit
                columns {
                    field_id
                    type_id
                }
            }
        }
    }
`;
