export const TYPE_COMMISSION = {
    GET_LIST_DATA: 'GET_LIST_DATA',
    GET_LIST_USER: 'GET_LIST_USER',
    GET_SETTING_USER: 'GET_SETTING_USER',
    APPLY_SETTING_USER: 'APPLY_SETTING_FROM_ANOTHER_USER',
    UPDATE_SETTING_USER: 'UPDATE_SETTING_USER',
    GET_LIST_STAFF_FILTER_COMMISSIONS: 'GET_LIST_STAFF_FILTER_COMMISSIONS',
    GET_LIST_METHOD_FILTER_COMMISSIONS: 'GET_LIST_METHOD_FILTER_COMMISSIONS'
};

export const getListDataCommission = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.GET_LIST_DATA,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListUserCommission = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.GET_LIST_USER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getSettingByUserCommission = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.GET_SETTING_USER,
        params,
        actionSuccess,
        actionFailed
    };
};
export const applySettingFromAnotherUserCommission = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.APPLY_SETTING_USER,
        params,
        actionSuccess,
        actionFailed
    };
};
export const updateSettingUserCommission = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.UPDATE_SETTING_USER,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListStaffsFilterCommissionsRequest = (
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.GET_LIST_STAFF_FILTER_COMMISSIONS,
        actionSuccess,
        actionFailed
    };
};
export const getListMethodFilterCommissionsRequest = (
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_COMMISSION.GET_LIST_METHOD_FILTER_COMMISSIONS,
        actionSuccess,
        actionFailed
    };
};
