import React from 'react';
import EmailChoose from './EmailChoose';

const StepConfirmEmail = () => {
    return (
        <div className="step scrolls show">
            <div className="row">
                <p>Connect your email account so you can send emails from GorillaDesk.</p>
            </div>
            <EmailChoose />
        </div>
    );
};

export default StepConfirmEmail;
