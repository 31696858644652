import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { CUSTOMER_LIST_TASKS, updateTask } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import { combineDateTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import AssignTo from './AssignTo';
import Date from './Date';
import Name from './Name';
import Time from './Time';

const initialState = { isOpen: false, isEdit: false, dataTask: {} };
const Task = forwardRef(({ onConfirm = () => {}, onCloseConfirm = () => {}, onSetButtonAdd = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'customers']);
    const { id: customerId } = useParams();
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isOpen, isEdit, dataTask } = state;
    const refBoxContainer = useRef(null);
    const refAssignTo = useRef(null);
    const refTaskTime = useRef(null);
    const refTaskDate = useRef(null);
    const refTaskName = useRef(null);
    const refButtonSave = useRef(null);
    const refPrevIdEditing = useRef(null);
    const refStatusBar = useRef(null);

    useImperativeHandle(ref, () => ({ open: _open, close: _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, isEdit: !!data, dataTask: data || {} });
        refPrevIdEditing.current = !!data ? data?.id : null;
    };

    const _close = () => {
        onSetButtonAdd(false);
        dispatchState(initialState);
    };

    const _handleSubmit = () => {
        const url = isEdit ? updateTask(dataTask.id) : CUSTOMER_LIST_TASKS;
        const taskDate = refTaskDate.current?.getValue();
        const taskTime = refTaskTime.current?.getValue();
        const title = refTaskName.current?.getValue();
        const assignee = refAssignTo.current?.getValue()?.id || '';
        const dataSubmit = {
            date: combineDateTimeToISO(taskDate, taskTime),
            type_id: customerId,
            type: 1,
            title,
            assignee
        };

        if (!title?.trim()?.length) {
            refTaskName.current.checkVerifyError();
            refButtonSave.current?.removeLoading();
            refStatusBar.current?.showStatusBar('error_task', 'Task description is required.', LIST_STATUS.ERROR);
            return;
        }

        const _handleSuccess = () => {
            _close();
        };

        const _handleFail = ({ error, message }) => {
            console.error('🚀 ~ file: Task.js:67 ~ error:', error);
            refStatusBar.current?.showStatusBar(
                'error_task',
                message || 'Task description is required.',
                LIST_STATUS.ERROR
            );
            refButtonSave.current?.removeLoading();
        };

        clientQuery(url, { data: dataSubmit, method: isEdit ? 'PUT' : 'POST' }, _handleSuccess, _handleFail);
    };

    const _openConfirm = () => {
        onConfirm({
            title: t('customers:delete_task'),
            description: t('customers:are_you_sure_delete_task'),
            onConfirm: _handleDelete
        });
    };

    const _handleDelete = () => {
        const _deleteSuccess = () => {
            _close();
            onCloseConfirm();
        };
        const _deleteFail = () => {};
        clientQuery(updateTask(dataTask.id), { data: {}, method: 'DELETE' }, _deleteSuccess, _deleteFail);
    };

    const modifiers = [
        { name: 'offset', options: { offset: [0, 5] } },
        { name: 'preventOverflow', options: { boundary: refBoxContainer.current } }
    ];

    if (!isOpen) return null;
    const defaultTime = moment(dataTask?.date || '').isValid()
        ? moment(dataTask?.date || '')
              .utc()
              .format('h:mm a')
        : '';
    return (
        <div ref={refBoxContainer} className="box-custom-sidebar open">
            <div className="box__container">
                <div className="wrap-header flex-betweenitems btn-close">
                    <h4 className="fs-18">{isEdit ? t('customers:edit_task') : t('customers:new_task')}</h4>
                    <span onClick={_close} className="v2-btn-default --noborder just-icon">
                        <IconClose />
                    </span>
                </div>

                <div className="box-form flex-column gap-8 px-3 pb-2">
                    <StatusBar ref={refStatusBar} />
                    <AssignTo ref={refAssignTo} defaultValue={dataTask?.assignee?.id || ''} modifiers={modifiers} />
                    <div className="flex-betweenitems gap-8">
                        <Date
                            ref={refTaskDate}
                            defaultValue={dataTask?.date || ''}
                            className="flex-column gap-4 flex-1"
                        />
                        <Time ref={refTaskTime} defaultValue={defaultTime} modifiers={modifiers} />
                    </div>
                    <Name ref={refTaskName} title={t('customers:task')} defaultValue={dataTask?.title || ''} />
                </div>

                <div className="flex-betweenitems border-top-grey-verylight-brown p-3">
                    {isEdit ? (
                        <span className="v2-btn-default has-bg-red has-icon" onClick={_openConfirm}>
                            <IconTrash />
                            {t('common:delete')}
                        </span>
                    ) : null}
                    <div className="flexcenter justify-end gap-8 flex-1 btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            {t('common:cancel')}
                        </span>
                        <ButtonSave ref={refButtonSave} onSave={_handleSubmit} />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Task;
