export const TYPE_CREDIT = {
  GET_LIST_CREDIT: "GET_LIST_CREDIT",
  UPDATE_STATUS_CREDIT: "UPDATE_STATUS_CREDIT",
  CREATE_CREDIT: "CREATE_CREDIT",
  UPDATE_CREDIT: "UPDATE_CREDIT",
  GET_LIST_METHOD: "GET_LIST_METHOD"
};

export const getListCredit = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_CREDIT.GET_LIST_CREDIT,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const createCredit = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_CREDIT.CREATE_CREDIT,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateCredit = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_CREDIT.U,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const getListMethod = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_CREDIT.GET_LIST_METHOD,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateStatusCustomerDetailCredit = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_CREDIT.UPDATE_STATUS_CREDIT,
    params,
    actionSuccess,
    actionFailed
  }
};
