export const TYPE_REPORT_REVENUE_ITEM = {
    GET_LIST_MONTH_TAB: 'GET_LIST_REPORT_REVENUE_ITEM',
    GET_LIST_DETAIL_TAB: 'GET_LIST_REPORT_REVENUE_ITEM_PAYMENT',
    GET_LIST_METHOD_FILTER_REVENUE_BY_ITEM: 'GET_LIST_METHOD_FILTER_REVENUE_BY_ITEM',
    GET_LIST_SERVICE_FILTER_REVENUE_BY_ITEM: 'GET_LIST_SERVICE_FILTER_REVENUE_BY_ITEM'
};

// actions
export const getListReportRevenueByItemMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_ITEM.GET_LIST_MONTH_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByItemDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_ITEM.GET_LIST_DETAIL_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterRevenueByItemRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_ITEM.GET_LIST_METHOD_FILTER_REVENUE_BY_ITEM,
        actionSuccess,
        actionFailed
    };
};

export const getListServiceFilterRevenueByItemRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_ITEM.GET_LIST_SERVICE_FILTER_REVENUE_BY_ITEM,
        params,
        actionSuccess,
        actionFailed
    };
};
