import Loading from 'app/components/loading';

const GridLoadmore = () => {
    return (
        <div className="py-8">
            <Loading />
        </div>
    );
};
export default GridLoadmore;
