import IconCogWheel from 'assets/icon/IconCogWheel';
import { React } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ADDONS_MULTI_UNIT_MANAGER } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';

const AddonsOptionsFooterMultiTracking = ({ upgrade = 0, isEnabled, status }) => {
    const { t } = useTranslation(['addons']);

    if (!!upgrade) return null;

    return (
        <div className="boxs__footer">
            <Link
                to={addBranchPath(ADDONS_MULTI_UNIT_MANAGER)}
                className={classNames('icon icon--setting ', {
                    'is-disable': !status || !isEnabled
                })}
            >
                <div
                    className={classNames('v2-btn-default svg-purple --purple --icon-r btn-modal ', {
                        'is-disable': !isEnabled
                    })}
                >
                    {t('addons:settings')}
                    <IconCogWheel />
                </div>
            </Link>
        </div>
    );
};

export default AddonsOptionsFooterMultiTracking;
