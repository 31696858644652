export default function IconInvoice({
    isNormal = false,
    isActive = false,
    isActiveColor = false,
    isPurple = false,
    isWhite = false,
    isGray = false
}) {
    if (isNormal) {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.5 5.5H20.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 5.5H17.5V18.5L14.8057 15.5L12 18.5L9.2102 15.5L6.5 18.5V5.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M9.5 12.5H11.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
                <path
                    d="M9.5 9.5H14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                ></path>
            </svg>
        );
    }
    if (isActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 6H13C14.1046 6 15 6.89543 15 8V16.2068C15 16.6089 14.6741 16.9348 14.272 16.9348C14.0753 16.9348 13.8871 16.8553 13.75 16.7143L13.2168 16.1659C12.8318 15.7699 12.1987 15.7609 11.8027 16.1459C11.7961 16.1524 11.7895 16.159 11.783 16.1657L11.2487 16.715C10.5775 17.405 9.47402 17.4202 8.78403 16.749C8.77229 16.7376 8.76073 16.726 8.74932 16.7143L8.21699 16.1664C7.83211 15.7704 7.19901 15.7613 6.80292 16.1461C6.79612 16.1528 6.78942 16.1595 6.78281 16.1663L6.25 16.7143C5.96972 17.0026 5.50882 17.0091 5.22053 16.7288C5.07954 16.5917 5 16.4034 5 16.2068V8C5 6.89543 5.89543 6 7 6Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.50007 3H15.4999C16.8807 3 18 4.11932 18 5.50007V7.00013C18.0001 7.55242 17.5524 8.00013 17.0001 8.00013C17 8.00013 17 8.00013 17 8.00007C16.4624 8.00003 16.0266 7.56417 16.0266 7.02656C16.0266 7.01782 16.0267 7.00909 16.027 7.00036L16.0405 6.5C16.0622 5.69362 15.4262 5.02229 14.6198 5.00053C14.6067 5.00018 14.5935 5 14.5804 5H5.50007C4.6716 5 4 5.6716 4 6.50007V7.00013C4 7.55242 3.55228 8.00013 3 8.00013C2.44772 8.00013 2 7.55242 2 7.00013V5.50007C2 4.11932 3.11932 3 4.50007 3Z"
                    fill="#7A83A6"
                />
                <path
                    d="M9.43414 17.5487C9.43414 15.9551 11.3012 15 13.2306 17C15.1599 19 17.3035 16.3602 17.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />
                <path
                    d="M9.43414 17.5487C9.43414 15.9551 11.3012 15 13.2306 17C15.1599 19 17.3035 16.3602 17.8247 12.628"
                    stroke="#7A83A6"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </svg>
        );
    }
    if (isActiveColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7 6H13C14.1046 6 15 6.89543 15 8V16.2068C15 16.6089 14.6741 16.9348 14.272 16.9348C14.0753 16.9348 13.8871 16.8553 13.75 16.7143L13.2168 16.1659C12.8318 15.7699 12.1987 15.7609 11.8027 16.1459C11.7961 16.1524 11.7895 16.159 11.783 16.1657L11.2487 16.715C10.5775 17.405 9.47402 17.4202 8.78403 16.749C8.77229 16.7376 8.76073 16.726 8.74932 16.7143L8.21699 16.1664C7.83211 15.7704 7.19901 15.7613 6.80292 16.1461C6.79612 16.1528 6.78942 16.1595 6.78281 16.1663L6.25 16.7143C5.96972 17.0026 5.50882 17.0091 5.22053 16.7288C5.07954 16.5917 5 16.4034 5 16.2068V8C5 6.89543 5.89543 6 7 6Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.50007 3H15.4999C16.8807 3 18 4.11932 18 5.50007V7.00013C18.0001 7.55242 17.5524 8.00013 17.0001 8.00013C17 8.00013 17 8.00013 17 8.00007C16.4624 8.00003 16.0266 7.56417 16.0266 7.02656C16.0266 7.01782 16.0267 7.00909 16.027 7.00036L16.0405 6.5C16.0622 5.69362 15.4262 5.02229 14.6198 5.00053C14.6067 5.00018 14.5935 5 14.5804 5H5.50007C4.6716 5 4 5.6716 4 6.50007V7.00013C4 7.55242 3.55228 8.00013 3 8.00013C2.44772 8.00013 2 7.55242 2 7.00013V5.50007C2 4.11932 3.11932 3 4.50007 3Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M9.43414 17.5487C9.43414 15.9551 11.3012 15 13.2306 17C15.1599 19 17.3035 16.3602 17.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />
                <path
                    d="M9.43414 17.5487C9.43414 15.9551 11.3012 15 13.2306 17C15.1599 19 17.3035 16.3602 17.8247 12.628"
                    stroke="#8D4AFC"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </svg>
        );
    }
    if (isPurple) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 8H15C16.1046 8 17 8.89543 17 10V18.2068C17 18.6089 16.6741 18.9348 16.272 18.9348C16.0753 18.9348 15.8871 18.8553 15.75 18.7143L15.2168 18.1659C14.8318 17.7699 14.1987 17.7609 13.8027 18.1459C13.7961 18.1524 13.7895 18.159 13.783 18.1657L13.2487 18.715C12.5775 19.405 11.474 19.4202 10.784 18.749C10.7723 18.7376 10.7607 18.726 10.7493 18.7143L10.217 18.1664C9.83211 17.7704 9.19901 17.7613 8.80292 18.1461C8.79612 18.1528 8.78942 18.1595 8.78281 18.1663L8.25 18.7143C7.96972 19.0026 7.50882 19.0091 7.22053 18.7288C7.07954 18.5917 7 18.4034 7 18.2068V10C7 8.89543 7.89543 8 9 8Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.50007 5H17.4999C18.8807 5 20 6.11932 20 7.50007V9.00013C20.0001 9.55242 19.5524 10.0001 19.0001 10.0001C19 10.0001 19 10.0001 19 10.0001C18.4624 10 18.0266 9.56417 18.0266 9.02656C18.0266 9.01782 18.0267 9.00909 18.027 9.00036L18.0405 8.5C18.0622 7.69362 17.4262 7.02229 16.6198 7.00053C16.6067 7.00018 16.5935 7 16.5804 7H7.50007C6.6716 7 6 7.6716 6 8.50007V9.00013C6 9.55242 5.55228 10.0001 5 10.0001C4.44772 10.0001 4 9.55242 4 9.00013V7.50007C4 6.11932 5.11932 5 6.50007 5Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    }
    if (isWhite) {
        return (
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 5.5H15.5C16.6046 5.5 17.5 6.39543 17.5 7.5V18.5L14.8057 15.5L12 18.5L9.2102 15.5L6.5 18.5V7.5C6.5 6.39543 7.39543 5.5 8.5 5.5Z"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 12.5H11.5"
                    stroke="white"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M9.5 9.5H14.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    if (isGray) {
        return (
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 5.5H15.5C16.6046 5.5 17.5 6.39543 17.5 7.5V18.5L14.8057 15.5L12 18.5L9.2102 15.5L6.5 18.5V7.5C6.5 6.39543 7.39543 5.5 8.5 5.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 12.5H11.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M9.5 9.5H14.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 8H15C16.1046 8 17 8.89543 17 10V18.2068C17 18.6089 16.6741 18.9348 16.272 18.9348C16.0753 18.9348 15.8871 18.8553 15.75 18.7143L15.2168 18.1659C14.8318 17.7699 14.1987 17.7609 13.8027 18.1459C13.7961 18.1524 13.7895 18.159 13.783 18.1657L13.2487 18.715C12.5775 19.405 11.474 19.4202 10.784 18.749C10.7723 18.7376 10.7607 18.726 10.7493 18.7143L10.217 18.1664C9.83211 17.7704 9.19901 17.7613 8.80292 18.1461C8.79612 18.1528 8.78942 18.1595 8.78281 18.1663L8.25 18.7143C7.96972 19.0026 7.50882 19.0091 7.22053 18.7288C7.07954 18.5917 7 18.4034 7 18.2068V10C7 8.89543 7.89543 8 9 8Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50007 5H17.4999C18.8807 5 20 6.11932 20 7.50007V9.00013C20.0001 9.55242 19.5524 10.0001 19.0001 10.0001C19 10.0001 19 10.0001 19 10.0001C18.4624 10 18.0266 9.56417 18.0266 9.02656C18.0266 9.01782 18.0267 9.00909 18.027 9.00036L18.0405 8.5C18.0622 7.69362 17.4262 7.02229 16.6198 7.00053C16.6067 7.00018 16.5935 7 16.5804 7H7.50007C6.6716 7 6 7.6716 6 8.50007V9.00013C6 9.55242 5.55228 10.0001 5 10.0001C4.44772 10.0001 4 9.55242 4 9.00013V7.50007C4 6.11932 5.11932 5 6.50007 5Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
