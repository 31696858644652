import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import IconArrow from 'assets/icon/IconArrow';
import IconPlus from 'assets/icon/IconPlus';
import { useSmartView } from '../context';
import AddFilterModal from './addFilter';
import FilterColumns from './filterColumns';
import { LoadingHeaderTable } from './header/Loading';

const HeaderTable = ({ isLoading = true, onExpandFilter = () => {} }) => {
    const { t } = useTranslation('smartView');
    const { filtered } = useSmartView();
    const refAddFilter = useRef(null);

    const _handleAddFilter = () => {
        refAddFilter.current?.open();
    };

    if (isLoading) return <LoadingHeaderTable />;
    return (
        <>
            <div className="header --filter align-center gap-8">
                <div className="flexcenter w-100 flex-wrap gap-4">
                    <div className="flex-1 flexcenter">
                        <div
                            className="v2-btn-default --transparent btn-filter"
                            onClick={() => {
                                filtered.length && onExpandFilter();
                            }}
                        >
                            <IconArrow isLeft />
                            <span>{t('filters')}</span>
                            {filtered.length ? <span className="budget --blue">{filtered.length}</span> : null}
                        </div>
                        <div className="v2-btn-default --sm has-icon add-filter btn-modal" onClick={_handleAddFilter}>
                            <IconPlus isCircle />
                            <span className="fs-13">{t('add_filter')}</span>
                        </div>
                    </div>
                    <FilterColumns />
                </div>
            </div>
            <AddFilterModal ref={refAddFilter} />
        </>
    );
};

HeaderTable.displayName = 'HeaderTableSmartView';
export default HeaderTable;
