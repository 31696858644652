import { JOB_PREVIEW_ACTIONS } from 'common/redux/actions/job';

const jobReducer = (
    state = {
        jobPreView: null,
        updateJobStatus: null,
        updateJobTimeWindow: null,
        jobReAssign: null,
        timeOff: null,
        jobDelete: null,
        updateJobEmoji: null
    },
    action
) => {
    switch (action.type) {
        case JOB_PREVIEW_ACTIONS.JOB_PREVIEW:
            return { ...state, jobPreView: action.payload };
        case JOB_PREVIEW_ACTIONS.UPDATE_JOB_STATUS_SUCCESS:
            return { ...state, updateJobStatus: action.payload };
        case JOB_PREVIEW_ACTIONS.UPDATE_JOB_TIME_WINDOW:
            return { ...state, updateJobTimeWindow: action.payload };
        case JOB_PREVIEW_ACTIONS.JOB_ASSIGN:
            return { ...state, jobReAssign: action.payload };
        case JOB_PREVIEW_ACTIONS.DELETE_JOB:
            return { ...state, jobDelete: action.payload };
        case JOB_PREVIEW_ACTIONS.OPEN_TIME_OFF:
            return { ...state, timeOff: action.payload };
        case JOB_PREVIEW_ACTIONS.UPDATE_JOB_EMOJI:
            const { jobPreView } = state;
            const { id, emoji } = action.payload;
            return {
                ...state,
                updateJobEmoji: action.payload,
                jobPreView: !!jobPreView && jobPreView.parent_job_id === id ? { ...jobPreView, emoji } : jobPreView
            };
        default:
            return state;
    }
};

export default jobReducer;
