import React, { useRef } from 'react';

import Chart from '../index';
import { TYPE_CHARTS } from 'app/const/App';
import { renderHtmlLegendPlugin } from './chart';

const GDLineChart = ({ configLineChart, size = 'medium', id = 'line-chart', isUseCustomLegend = false }) => {
    const refChart = useRef(null);

    const renderSize = (size) => {
        return size;
    };

    const legendMargin = {
        id: 'legendMargin',
        beforeInit(chart) {
            if (chart && chart.legend) {
                const fitValue = chart.legend.fit;
                chart.legend.fit = function fit() {
                    fitValue.bind(chart.legend)();
                    return (this.height += 16);
                };
            }
        }
    };

    const arrPlugin = [legendMargin];
    isUseCustomLegend && arrPlugin.push(renderHtmlLegendPlugin(id));

    return (
        <Chart
            id={id}
            ref={refChart}
            chartConfig={{
                type: TYPE_CHARTS.line,
                ...configLineChart
            }}
            classCanvas={`line-chart-${renderSize(size)}`}
            plugins={arrPlugin}
        />
    );
};

export default GDLineChart;
