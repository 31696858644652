import i18n from 'assets/i18n';
import { COMMON, REGEX_EMAIL, REGEX_PASSWORD } from 'app/const/App';
export const monoStringValidate = ({ value, fieldName = '', regexTest, regexTestMessage }) => {
    const result = {
        isPass: true,
        errorMsg: ''
    };
    if (typeof value !== 'string') {
        result.isPass = false;
        result.errorMsg = i18n.t('common:must_be_string', { name: fieldName });
    }
    if (regexTest && !regexTest.test(value)) {
        result.isPass = false;
        if (regexTestMessage) {
            result.errorMsg = fieldName.concat(' ', regexTestMessage);
        } else {
            result.errorMsg = i18n.t('common:has_invalid_characters', { name: fieldName });
        }
    }
    if (value.trim().length === 0) {
        result.isPass = false;
        result.errorMsg = i18n.t('common:can_not_be_blank', { name: fieldName });
    }
    return result;
};

export const monoNumberValidate = ({ value, fieldName = '', regexTest, regexTestMessage }) => {
    const result = {
        isPass: true,
        errorMsg: ''
    };
    if (isNaN(value)) {
        result.isPass = false;
        result.errorMsg = i18n.t('common:must_be_number', { name: fieldName });
    }
    if (regexTest && !regexTest.test(value)) {
        result.isPass = false;
        if (regexTestMessage) {
            result.errorMsg = fieldName.concat(' ', regexTestMessage);
        } else {
            result.errorMsg = i18n.t('common:is_an_invalid_number', { name: fieldName });
        }
    }
    if (value.toString().trim().length === 0) {
        result.isPass = false;
        result.errorMsg = i18n.t('common:can_not_be_blank', { name: fieldName });
    }
    return result;
};

const testTypeMap = {
    [COMMON.STRING]: monoStringValidate,
    [COMMON.NUMBER]: monoNumberValidate
};

export const multiValidate = ({ arrayValues = [] }) => {
    const result = {
        isPass: true
    };
    arrayValues.forEach((item) => {
        const validationObject = testTypeMap[item.type]({
            value: item.value,
            fieldName: item?.fieldName,
            regexTest: item?.regexTest,
            regexTestMessage: item?.regexTestMessage
        });
        if (!validationObject.isPass) {
            result.isPass = false;
            item.callBackRef.current._setError(validationObject.errorMsg);
        } else {
            item.callBackRef.current._setError(null);
        }
        return item;
    });
    return result;
};

export const validPassword = (value) => {
    return new RegExp(REGEX_PASSWORD).test(value);
};

export const validEmail = (value) => {
    return new RegExp(REGEX_EMAIL).test(value);
};

/*** Example input arrayValues
arrayValues: [
    {
        value: name,
        type: COMMON.STRING,
        fieldName: t(`setting:tax_name`),
        callBackRef: refName
    },
    {
        value: rate,
        type: COMMON.NUMBER,
        fieldName: t(`setting:tax_rate`),
        regexTest: /^(\d*\.)?\d+$/gim,
        callBackRef: refRate
    }
]
***/
