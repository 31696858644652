import React from 'react';

const BookingLoading = () => {
    return (
        <div className="wrap-loading --addon">
            <div className="boxs boxs--no-border">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                        <div className="title">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="flexcenter loading --fourth">
                        <div className="loading flex-1 switch ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default --grey loading btn-sm ml-3">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="boxs__contents mt-4">
                    <div className="description loading-lines">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --fourth" />
                        <div className="loading --animation --line --full mt-5" />
                    </div>
                    <div className="d-flex justify-space-between align-center mt-3">
                        <div className="v2-btn-default --transparent loading btn-sm ml-n2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="link-portal loading --fourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <span className="--hasline loading" />
                <div className="link-portal loading --threefourth">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="boxs__contents mt-3">
                    <div className="description loading-lines">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --ninety" />
                    </div>
                </div>
                <span className="--hasline loading" />
            </div>
        </div>
    );
};

export default BookingLoading;
