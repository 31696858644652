import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDLineChart from 'app/components/chart/line';
import { configRevenuePerMonth } from './config';
import { useSelector } from 'react-redux';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, SIZE_CHART, TYPE_PICKER, getDefaultParamsWidget } from '../../const';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { reducer } from 'app/const/Reducer';
import ServiceDashboard from '../../ServiceDashboard';
import LoadingChart from '../loading/LoadingChart';
import WidgetDatePicker from '../../components/datepicker';
import { LINE_CHART_COLORS } from 'app/const/App';
import { convertColorHexToRGBA } from 'app/components/chart/line/chart';

export default function WidgetRevenuePerMonth() {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);

    const refService = useRef(null);
    const refWidgetDatePicker = useRef(null);
    const refListYear = useRef(null);

    const keyWidget = LIST_WIDGETS.REVENUE_PER_MONTH;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: [],
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData } = state;

    useEffect(() => {
        fetchRevenueData(defaultParams);
    }, []);

    const fetchRevenueData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchRevenueData(newParams);
    };

    const _handleGetDataSuccess = ({ data }) => {
        refListYear.current = data.map((item) => +item.label);
        dispatchState((prev) => ({
            ...prev,
            data: data.map((dataRevenue, index) => {
                const renderColor = (alpha = 1) => convertColorHexToRGBA(LINE_CHART_COLORS[index], alpha);
                return {
                    ...dataRevenue,
                    color: renderColor(0.4),
                    borderColor: renderColor(0.8)
                };
            }),
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _onToggleLegendChart = (legend, isHidden) => {
        refWidgetDatePicker.current._setToggleYear({
            year: legend,
            isHidden,
            listLabelYear: refListYear.current
        });
    };

    const _renderLineChart = () => {
        if (finalIsLoading) return <LoadingChart isLineChart />;

        return (
            <div className="boxs__content">
                <div className="chart">
                    <div className="chart__content">
                        <div className="flex-column gap-16">
                            <GDLineChart
                                id={keyWidget}
                                configLineChart={configRevenuePerMonth({
                                    currency,
                                    data: finalData,
                                    onToggleLegendChart: _onToggleLegendChart
                                })}
                                size={SIZE_CHART.MEDIUM}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <Fragment>
            <div className="boxs">
                <div className="boxs__header">
                    <div className="header-label flex-1">{t('revenue_per_month')}</div>
                    <WidgetDatePicker
                        ref={refWidgetDatePicker}
                        keyWidget={keyWidget}
                        typePicker={TYPE_PICKER.YEARS}
                        selectDefault={defaultParams.years}
                        onSelect={(values) => _handleChangeFilterWidget({ years: values })}
                    />
                    <div />
                </div>
                {_renderLineChart()}
            </div>
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </Fragment>
    );
}
