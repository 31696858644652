import classNames from 'classnames';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconThreeDots from 'assets/icon/IconThreeDots';
import { STATUS_ACH, TYPE_ACTION_ACCOUNT_ACH } from 'app/const/Customers';
import InfoCard from '../InfoCard';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';

const InfoCardACH = ({ item = {}, onDelete = () => {}, onVerify = () => {}, onReminder = () => {} }) => {
    const { idsDefault, _handleUpdateDefault } = useContext(CustomerDetailContext);
    const activateSms = useSelector(({ auth }) => auth.user.settings.addons.activate_sms);

    const { t } = useTranslation('customers');
    const refDropdown = useRef(null);

    const { DELETE, VERIFY, SET_DEFAULT, REMINDER, EMAIL_REMINDER, TEXT_REMINDER } = TYPE_ACTION_ACCOUNT_ACH;
    const { account_name, account_number, status } = item;
    const isVerify = status === STATUS_ACH.VERIFY;
    const isDefault = idsDefault[PAYMENT_MERCHANT_KEYWORD.STRIPE] === item.account_id;

    const _handleAction = (type) => {
        switch (type) {
            case DELETE:
                onDelete(item);
                break;
            case VERIFY:
                onVerify(item);
                break;
            case SET_DEFAULT:
                _handleUpdateDefault({ ...item, name: PAYMENT_MERCHANT_KEYWORD.STRIPE }, !!isDefault);
                break;
            case REMINDER:
            case TEXT_REMINDER:
            case EMAIL_REMINDER:
                onReminder({ isReminder: true, typeReminder: type });
                break;
        }
        refDropdown.current._close();
    };

    const _renderActions = () => {
        return Object.entries(TYPE_ACTION_ACCOUNT_ACH).map(([key, value]) => {
            if (!activateSms && [TEXT_REMINDER, REMINDER].includes(value)) return null;
            const isShowOnPending = [VERIFY, REMINDER, TEXT_REMINDER, EMAIL_REMINDER].includes(value);
            if (value !== DELETE && ((!isShowOnPending && !isVerify) || (isShowOnPending && isVerify))) return null;
            return (
                <li
                    key={key}
                    className={classNames('items fs-13 fw-500', { 'is-disable': value === SET_DEFAULT && !!isDefault })}
                    onClick={() => _handleAction(value)}
                >
                    {t(value)}
                </li>
            );
        });
    };

    return (
        <div className={classNames('method-items w-100 flex-betweenitems method-bank', { 'has-default': !!isDefault })}>
            <InfoCard
                isPaymentDefault={!!isDefault}
                account_number={account_number}
                account_name={account_name}
                isPaymentSideBar
                isACH
            />
            <span className={classNames('default-label fw-600 flex-1', isVerify ? 'green-default ' : 'orange-default')}>
                {t(isVerify ? 'verified' : 'pending_verification')}
            </span>
            <DropdownPopper
                ref={refDropdown}
                id="card_ach_id"
                wrapperClassName="v2-dropdown v2-dropdown--more"
                wrapperListClass="v2-dropdown__menu content-full popper-content"
                buttonClassName="dropbtn v2-btn-default --icon-lg --transparent"
                customButton={<IconThreeDots />}
                isUseToggle
            >
                <ul>{_renderActions()}</ul>
            </DropdownPopper>
        </div>
    );
};

export default InfoCardACH;
