import { TYPE_SCHEDULE_ACTION } from 'common/redux/actions/calendar/scheduleAction';
import { TYPE_SETTING_SCHEDULES } from 'common/redux/actions/settings/schedulesAction';

const schedulesReducer = (
    state = {
        isFirstTime: true,
        data: { groups: [], schedules: [] }
    },
    action
) => {
    switch (action.type) {
        case TYPE_SCHEDULE_ACTION.ADD_SCHEDULE:
            return { ...state, data: { groups: [], schedules: action.payload } };
        case TYPE_SCHEDULE_ACTION.UPDATE_SCHEDULE_COLOR:
            return {
                ...state,
                data: {
                    ...state.data,
                    schedules: state.data.schedules.map((schedule) => {
                        if (schedule.id === action.payload.id) return { ...schedule, colors: action.payload.colors };
                        return schedule;
                    })
                }
            };
        case TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: action.payload?.data || []
            };
        case TYPE_SETTING_SCHEDULES.UPDATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default schedulesReducer;
