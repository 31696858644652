import { takeLatest } from 'redux-saga/effects';

import { TYPE_COMMISSION } from 'common/redux/actions/commissionAction';

import {
    getListDataCommission,
    getListStaffsFilterCommissions,
    getListMethodFilterCommissions,
    getCommissionItems,
    getCommissionUsers
} from 'common/redux/sagas/commission/data';
import {
    getListUserCommission,
    getSettingUserCommission,
    applySettingFromAnotherUserCommission,
    updateSettingUserCommission
} from 'common/redux/sagas/commission/setup';
import { COMMISSION_ACTIONS } from 'common/redux/actions/commission';

export function* commissionGetListDataWatcher() {
    yield takeLatest(TYPE_COMMISSION.GET_LIST_DATA, getListDataCommission);
}

export function* commissionGetListUserWatcher() {
    yield takeLatest(TYPE_COMMISSION.GET_LIST_USER, getListUserCommission);
}

export function* commissionGetSettingUserWatcher() {
    yield takeLatest(TYPE_COMMISSION.GET_SETTING_USER, getSettingUserCommission);
}

export function* commissionApplySettingFromAnotherUserWatcher() {
    yield takeLatest(TYPE_COMMISSION.APPLY_SETTING_USER, applySettingFromAnotherUserCommission);
}

export function* commissionUpdateSettingUserWatcher() {
    yield takeLatest(TYPE_COMMISSION.UPDATE_SETTING_USER, updateSettingUserCommission);
}

export function* commissionGetListStaffsFilterWatcher() {
    yield takeLatest(TYPE_COMMISSION.GET_LIST_STAFF_FILTER_COMMISSIONS, getListStaffsFilterCommissions);
}

export function* commissionGetListMethodFilterWatcher() {
    yield takeLatest(TYPE_COMMISSION.GET_LIST_METHOD_FILTER_COMMISSIONS, getListMethodFilterCommissions);
}

export function* commissionGetListItemsWatcher() {
    yield takeLatest(COMMISSION_ACTIONS.GET_COMMISSION_ITEMS, getCommissionItems);
}

export function* commissionGetListUsersWatcher() {
    yield takeLatest(COMMISSION_ACTIONS.GET_COMMISSION_USERS, getCommissionUsers);
}
