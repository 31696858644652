import { reducer } from 'app/const/Reducer';
import CommissionDropdown from 'app/modules/addjob/commission/components/CommissionDropdown';
import React, { useReducer } from 'react';

const AddSoldBy = ({ defaultSelected = [], onChange = () => {} }) => {
    const [state, dispatchState] = useReducer(reducer, { selected: defaultSelected });
    const { selected } = state;

    const _handleSelect = (userInfo) => {
        const userIsSelected = selected.some((item) => item.id === userInfo.id);
        const newSelected = userIsSelected
            ? selected.filter((item) => item.id !== userInfo.id)
            : selected.concat(userInfo);

        dispatchState((prevState) => ({
            ...prevState,
            selected: newSelected
        }));
        onChange(newSelected.map((item) => item.id));
    };

    const _handleUnselect = (userId) => {
        const newSelected = selected.filter((item) => item.id !== userId);

        dispatchState((prevState) => ({
            ...prevState,
            selected: newSelected
        }));
        onChange(newSelected.map((item) => item.id));
    };

    return (
        <div className="schedule-wrapper --sold-by --no-job">
            <div className="label">Sold by</div>
            <div className="detail-schedule">
                <CommissionDropdown selected={selected} onSelect={_handleSelect} onUnselect={_handleUnselect} />
            </div>
        </div>
    );
};

export default AddSoldBy;
