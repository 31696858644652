import classNames from 'classnames';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { SMART_VIEWS_PREVIEW_EMAIL } from 'app/const/api/V2';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import { useSmartView } from '../../context';
import { convertToQuery } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';
import LoadingPreviewEmail from './LoadingPreviewEmail';

const BulkEmailContent = ({ count, dataEmail = {}, emailId = '', emailName = '', onSetLoading = () => {} }) => {
    const { t } = useTranslation('smartView');
    const { filtered, sort, limit } = useSmartView();
    const [state, dispatchState] = useReducer(reducer, {
        currentIndex: 0,
        dataPreview: {},
        isLoading: true
    });
    const { currentIndex, dataPreview, isLoading } = state;

    useEffect(() => {
        _handleLoadData();
    }, []);

    const _handleLoadData = (newIndex = 0) => {
        onSetLoading(true);
        const dataSubmit = {
            template_id: emailId,
            index: newIndex,
            query: JSON.stringify({
                q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
                sort: sortToQuery(sort),
                limit
            })
        };
        clientQuery(
            SMART_VIEWS_PREVIEW_EMAIL,
            { data: dataSubmit, method: 'POST', toFormData: false },
            _loadDataSuccess,
            _loadDataFail,
            _loadDataFinally
        );
    };

    const _loadDataSuccess = ({ data }) => {
        dispatchState({ dataPreview: data, isLoading: false });
    };
    const _loadDataFail = () => {};
    const _loadDataFinally = () => {
        onSetLoading(false);
    };

    const _handleClick = (type) => {
        dispatchState((prevState) => {
            let newIndex = prevState.currentIndex;
            switch (type) {
                case 'increment':
                    newIndex += 1;
                    break;
                case 'decrement':
                    newIndex -= 1;
                    break;
                default:
                    break;
            }
            _handleLoadData(newIndex);
            return { ...prevState, currentIndex: newIndex, isLoading: true };
        });
    };

    return (
        <div className="body-modal">
            <div className="rows">
                <p className="txt-ellipsis fw-600 flex-1">{emailName}</p>
                <p className="txt-ellipsis grey-faux-granite">
                    {count > 1 ? t('send_email_to_leads', { count }) : t('send_email_to_lead', { count })}
                </p>
                <div className={classNames('col-action', { 'is-disable': isLoading })}>
                    <div
                        className={classNames('v2-btn-default --icon-lg', { 'is-disable': currentIndex <= 0 })}
                        onClick={() => _handleClick('decrement')}
                    >
                        <IconArrow isPrev />
                    </div>
                    <div
                        className={classNames('v2-btn-default --icon-lg', {
                            'is-disable': currentIndex + 1 >= count
                        })}
                        onClick={() => _handleClick('increment')}
                    >
                        <IconArrow />
                    </div>
                </div>
            </div>

            {isLoading ? (
                <LoadingPreviewEmail />
            ) : (
                <>
                    <div className="wrap-form">
                        <div className="form">
                            <div className="form__label">To:</div>
                            <div className="form__field">
                                <span className="purple-default fw-600 txt-ellipsis name">
                                    {dataPreview?.to?.name || 'Not Found Name'}
                                </span>
                                <span className="grey-faux-granite txt-ellipsis txt">{dataPreview?.to?.email}</span>
                            </div>
                        </div>
                        <div className="line" />
                        <div className="form">
                            <div className="form__label">From:</div>
                            <div className="form__field">
                                <span className="purple-default fw-600 txt-ellipsis name">{dataEmail?.name}</span>
                                <span className="grey-faux-granite txt-ellipsis txt">{dataEmail?.email}</span>
                            </div>
                        </div>
                    </div>

                    <div className="scrolls container-content-email">
                        <div className="rows border-none to-email">
                            <p className="fs-14 fw-600">{dataPreview.subject}</p>
                        </div>
                        <div
                            className="wrap-content-email"
                            dangerouslySetInnerHTML={{ __html: dataPreview?.content }}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default BulkEmailContent;
