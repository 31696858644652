export const TYPE_REPORT_SALES_TAX = {
  GET_LIST: "GET_LIST_REPORT_SALES_TAX",
  GET_LIST_TAX_SUMMARY: "GET_LIST_REPORT_TAX_SUMMARY",
  GET_LIST_METHOD_TAX_SUMMARY: "GET_LIST_METHOD_TAX_SUMMARY"
}


// actions
export const getListReportSalesTax = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_SALES_TAX.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListReportTaxSummary = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_SALES_TAX.GET_LIST_TAX_SUMMARY,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListMethodTaxSummaryRequest = (actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_SALES_TAX.GET_LIST_METHOD_TAX_SUMMARY,
    actionSuccess,
    actionFailed
  }
}
