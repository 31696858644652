import classNames from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useReducer, useRef } from 'react';

import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const YearPicker = ({ monthDate, changeYear }) => {
    const refDropDown = useRef(null);
    const refWrapper = useRef(null);
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });
    const finalIsVisible = state.isVisible;
    const currentYear = moment(monthDate).format('YYYY');

    const year = +currentYear || new Date().getFullYear();
    const years = [
        ...Array.from(new Array(11), (val, index) => year - index).reverse(),
        ...Array.from(new Array(11), (val, index) => year + index)
    ];

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);

            refWrapper.current?.querySelector('.active')?.scrollIntoView({ behavior: 'auto', block: 'start' });
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        if (event.keyCode === KEY_CODE_ESCAPE && refWrapper.current) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        if (
            refDropDown.current &&
            refWrapper.current &&
            !refWrapper.current.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleClickYear(year) {
        changeYear(year);
        _closeSearchResult();
    }

    function _renderLisrYear() {
        const finalYear = [...new Set(years)];
        return finalYear.map((item) => {
            const finalItem = item.toString();
            const isActive = currentYear === finalItem;

            return (
                <li
                    className={`items ${isActive ? 'active' : ''}`}
                    key={finalItem}
                    onClick={() => _handleClickYear(finalItem)}
                >
                    <p className="txt-ellipsis">{finalItem}</p>
                </li>
            );
        });
    }

    return (
        <div className="datepicker-header-custom__option --year">
            <div
                ref={refDropDown}
                className={classNames('v2-dropdown datepicker-selected', { active: finalIsVisible })}
            >
                <div onClick={_handleOpen} className="dropbtn v2-btn-default --sm --icon-r fs-13">
                    {currentYear}
                    <div className="arrow">
                        <IconArrowDown />
                    </div>
                </div>
                <div ref={refWrapper} className="v2-dropdown__menu scrolls">
                    <ul>{_renderLisrYear()}</ul>
                </div>
            </div>
        </div>
    );
};

export default YearPicker;
