import React, { useContext } from 'react';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import { MODES_MONTH } from 'app/modules/jobdetail/const';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';

const RepeatMonth = ({ isEvent = false }) => {
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCurrentceDetail = repeatSettings.recurrence.offset || {};

    const finalModeMonth = jobCurrentceDetail.bymonthday;

    function _handleChangeMode(e, value) {
        _sendDataToContext(jobCurrentceDetail.interval, value);
    }

    function _handleChangeValue(interval) {
        _sendDataToContext(interval, finalModeMonth);
    }

    function _sendDataToContext(interval, mode) {
        const refValue = {
            ...repeatSettings,
            recurrence: {
                ...repeatSettings.recurrence,
                offset: {
                    ...jobCurrentceDetail,
                    bymonthday: mode,
                    interval: interval
                }
            }
        };

        onChangeDataContext(refValue);
    }

    return (
        <div className={'tab-panel active'}>
            <div className="rows">
                {!isEvent ? <div className="txt no-icon">Every</div> : null}
                <div className="details field-every">
                    <FormInterval interval={jobCurrentceDetail.interval} onChange={_handleChangeValue} />
                    <span className="text-last">month</span>
                </div>
            </div>
            <div className="rows">
                {!isEvent ? <div className="txt no-icon"> Repeat By </div> : null}
                <div className="details d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button
                                className={`tab-items ${
                                    finalModeMonth === MODES_MONTH.DAY_WEEK ? 'active-tab-selector' : ''
                                }`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES_MONTH.DAY_WEEK)}
                            >
                                Day Of The Week
                            </button>
                            <button
                                className={`tab-items ${
                                    finalModeMonth === MODES_MONTH.DAY_MONTH ? 'active-tab-selector' : ''
                                }`}
                                type="button"
                                onClick={(e) => _handleChangeMode(e, MODES_MONTH.DAY_MONTH)}
                            >
                                Day Of The Month
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RepeatMonth;
