import { call, put, select } from 'redux-saga/effects';
import { TYPE_ACTION_COMPANY_USERS } from 'common/redux/actions/companyUsersAction';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { COMPANY_USERS } from 'app/const/Api';
import { checkCrewUser, convertUpdateCrewUser } from 'app/modules/settings/utils/userCompany';

export function* getCompanyUsers({ actionSuccess = () => {}, actionFailed = () => {} }) {
    const response = yield call(fetchWithToken, COMPANY_USERS);

    if (response?.success) {
        actionSuccess(response);
        yield put({
            type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_SUCCESS,
            payload: response
        });
    } else {
        actionFailed();
        yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_FAILURE });
    }
}

export function* editCompanyUser({ payload, actionType }) {
    const typeUser = payload.type;
    if (!checkCrewUser(typeUser)) {
        yield put({ type: actionType, payload });
        return;
    }

    const crew = yield select(({ companyUsers }) => companyUsers.crew);
    const finalData = convertUpdateCrewUser({ crew, data: payload });
    if (!!finalData.refresh) {
        yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
        return;
    }

    yield put({ type: TYPE_ACTION_COMPANY_USERS.UPDATE_CREW_USER, payload: { type: typeUser, data: finalData } });
}
