import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const ItemPhoneTo = ({ color = '', phone = '', name: label = '' }) => {
    const { t } = useTranslation('report');
    const finalPhone = formatPhoneNumberVoip(phone);

    return (
        <div className="phone-to" style={{ '--color-custom-sms': color }}>
            <span className="gray-color fs-11">{t('to')}</span>
            {!!finalPhone ? (
                <span className="phone" title={finalPhone}>
                    {finalPhone}
                </span>
            ) : null}
            {!!label ? (
                <div className="status-btn" title={label}>
                    {label}
                </div>
            ) : null}
        </div>
    );
};

export default ItemPhoneTo;
