import moment from 'moment';

import { SMART_VIEW } from 'app/config/routes';
import { CUSTOM_ID, DATE_OPTIONS, FUTURE_ID, PAST_ID } from '../../constants/dateOptions';
import { BUILT_IN_TYPE_DEFINITION } from '../../constants/definitions';
import { FIELDS_TYPE, OBJECT_TYPES, OPERATORS_EXCEPT_VALUE, OPERATOR_IDS } from '../../constants/types';
import { CUSTOM_FIELDS_TYPES } from 'app/modules/customfields/constant';
import i18n from 'assets/i18n';

export const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    ...draggableStyle
});

export const getListStyle = () => ({
    position: 'relative'
});

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
// Above just test drag n drop

export const getSmartViewUrl = (id) => SMART_VIEW.replace(':id?', id);
export const isValidFilterDate = ({ value, offset, offset2, value2, customDate, customDate2 }, operatorId) => {
    const isBetween = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN].includes(operatorId);

    // Check value is custom date
    const valueIsCustomDate = value === CUSTOM_ID;
    const value2IsCustomDate = value2 === CUSTOM_ID;
    // Check value is type offset {months,weeks,days,hours,minutes}
    const valueIsOffset = [PAST_ID, FUTURE_ID].includes(value);
    const value2IsOffset = [PAST_ID, FUTURE_ID].includes(value2);

    if (valueIsOffset && !offset && !isBetween) return false;
    if (valueIsCustomDate && !customDate && !isBetween) return false;

    // Check case between
    if (isBetween) {
        if (!value && !value2) return false;

        // Both is custom date and don't have value
        if (valueIsCustomDate && !customDate && value2IsCustomDate && !customDate2) return false;
        // One is custom date and one is not custom date
        if (valueIsCustomDate || value2IsCustomDate) {
            if (valueIsCustomDate && !customDate && !value2) return false;
            if (value2IsCustomDate && !customDate2 && !value) return false;
        }

        // Both is offset and don't have value
        if (valueIsOffset && !offset && value2IsOffset && !offset2) return false;
        // One is offset and one is not offset
        if (valueIsOffset || value2IsOffset) {
            if (valueIsOffset && !offset && !value2) return false;
            if (value2IsOffset && !offset2 && !value) return false;
        }
    }

    return true;
};

const isValidFilterDuration = ({ durationValue = {} }, operatorId, isEditing = false) => {
    if (!Object.keys(durationValue).length && !isEditing) return false;
    return true;
};

export const convertFilterToFiltered = (filters, isEditing = false) => {
    return Object.values(filters).flatMap((filter) => {
        const filterValue = filter.value;
        const operatorId = filter.operatorId;

        // This operator doesn't have value
        if (OPERATORS_EXCEPT_VALUE.includes(operatorId)) return filter;
        // If value is empty or undefined then don't add to snapshot
        if (filterValue === undefined) return [];

        // This operator must have value
        switch (filter.fieldType) {
            case FIELDS_TYPE.STATUS:
            case FIELDS_TYPE.STATUS_OPPORTUNITY:
            case FIELDS_TYPE.REFERENCE_USER:
            case FIELDS_TYPE.CREATED_BY:
            case FIELDS_TYPE.SOURCE_ID:
            case FIELDS_TYPE.REFERENCE_TYPE:
                if (!filterValue?.set?.values?.length) return [];
                break;
            case FIELDS_TYPE.CREATED_DATE:
            case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
            case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
            case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
                if (!isValidFilterDate(filterValue, operatorId)) return [];
                break;
            case FIELDS_TYPE.DURATION:
                if (!isValidFilterDuration(filterValue, operatorId, isEditing)) return [];
                break;
            case FIELDS_TYPE.TEXT:
                if (!filterValue.trim()) return [];
                break;
            default:
                break;
        }
        return filter;
    });
};

export const parseToListOfFilters = (fields) => {
    const results = fields.map((field) => {
        const { objectType, fieldName } = field;
        const filterableField = getField(objectType, fieldName);
        return { ...field, ...filterableField, operatorIds: getOperatorIds(filterableField?.type) };
    });
    return results;
};

export function filterSortOptions(sortOptionsArray, searchText) {
    const results = [];
    sortOptionsArray.forEach(({ title, objectType, options }) => {
        const filteredOptions = options.filter(({ label }) => label.toLowerCase().includes(searchText.toLowerCase()));
        if (filteredOptions.length > 0) results.push({ title, objectType, options: filteredOptions });
    });
    return results;
}

export function getOperatorIds(type) {
    let operatorIds = [];
    switch (type) {
        case FIELDS_TYPE.BOOLEAN:
        case FIELDS_TYPE.LIMIT:
        case FIELDS_TYPE.SLICE:
            operatorIds = [[]];
            break;
        case FIELDS_TYPE.COUNT_TASK:
        case FIELDS_TYPE.COUNT_CALL:
        case FIELDS_TYPE.COUNT_OUTGOING_CALL:
        case FIELDS_TYPE.COUNT_SMS:
        case FIELDS_TYPE.COUNT_JOB:
        case FIELDS_TYPE.INTEGER:
        case FIELDS_TYPE.FLOAT:
            operatorIds = [
                [
                    OPERATOR_IDS.MORE,
                    OPERATOR_IDS.LESS,
                    OPERATOR_IDS.BETWEEN,
                    OPERATOR_IDS.EXACTLY,
                    OPERATOR_IDS.NOT_EXACTLY
                ],
                [OPERATOR_IDS.SOME, OPERATOR_IDS.NONE]
            ];
            break;
        case FIELDS_TYPE.DURATION:
            operatorIds = [[OPERATOR_IDS.MORE, OPERATOR_IDS.LESS, OPERATOR_IDS.EXACTLY, OPERATOR_IDS.NOT_EXACTLY]];
            break;
        case FIELDS_TYPE.DATE:
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
        case FIELDS_TYPE.DATETIME:
            operatorIds = [
                [OPERATOR_IDS.IS, OPERATOR_IDS.NOT],
                [OPERATOR_IDS.WITHIN, OPERATOR_IDS.NOT_WITHIN],
                [OPERATOR_IDS.BEFORE, OPERATOR_IDS.ON_OR_BEFORE, OPERATOR_IDS.ON_OR_AFTER, OPERATOR_IDS.AFTER],
                [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN],
                [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT]
            ];
            break;
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
            operatorIds = [
                [OPERATOR_IDS.IS, OPERATOR_IDS.NOT],
                [OPERATOR_IDS.WITHIN, OPERATOR_IDS.NOT_WITHIN],
                [OPERATOR_IDS.BEFORE, OPERATOR_IDS.ON_OR_BEFORE, OPERATOR_IDS.ON_OR_AFTER, OPERATOR_IDS.AFTER],
                [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN]
            ];
            break;
        case FIELDS_TYPE.STATUS:
        case FIELDS_TYPE.STATUS_OPPORTUNITY:
        case FIELDS_TYPE.REFERENCE_USER:
        case FIELDS_TYPE.SOURCE_ID:
            operatorIds = [[OPERATOR_IDS.IN, OPERATOR_IDS.NOT_IN]];
            break;
        case FIELDS_TYPE.CREATED_BY:
            operatorIds = [
                [OPERATOR_IDS.IN, OPERATOR_IDS.NOT_IN],
                [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT]
            ];
            break;
        case FIELDS_TYPE.REFERENCE_TYPE:
            operatorIds = [[OPERATOR_IDS.IN, OPERATOR_IDS.NOT_IN]];
            break;
        case FIELDS_TYPE.TEXT:
            operatorIds = [
                [OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH, OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH],
                [OPERATOR_IDS.EXACT_VALUE, OPERATOR_IDS.NOT_EXACT_VALUE],
                [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT]
            ];
            break;
        default:
            operatorIds = [
                [OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH, OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH],
                [OPERATOR_IDS.EXACT_VALUE, OPERATOR_IDS.NOT_EXACT_VALUE]
            ];
    }
    return operatorIds;
}

export const getField = (objectType, fieldName) => {
    const filterableType = BUILT_IN_TYPE_DEFINITION.find((type) => type.filterableType === objectType);
    const filterableField = filterableType['fields'].find((type) => type.id === fieldName) || null;
    return filterableField;
};

const BETWEEN_OPERATORS = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN];
const PRESENT_OPERATORS = [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT];
export const getNewValueOperator = ({ fieldType, oldValue, oldOperatorId, newOperatorId }) => {
    let value = oldValue;
    const isBetween = BETWEEN_OPERATORS.includes(oldOperatorId) || BETWEEN_OPERATORS.includes(newOperatorId);
    const isPresent = PRESENT_OPERATORS.includes(oldOperatorId) || PRESENT_OPERATORS.includes(newOperatorId);
    switch (fieldType) {
        case FIELDS_TYPE.COUNT_TASK:
        case FIELDS_TYPE.COUNT_JOB:
        case FIELDS_TYPE.COUNT_CALL:
        case FIELDS_TYPE.COUNT_OUTGOING_CALL:
        case FIELDS_TYPE.COUNT_SMS:
        case FIELDS_TYPE.INTEGER:
            if (isBetween) value = undefined;
            break;
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
            const newOptions = DATE_OPTIONS[newOperatorId];
            if (newOptions && !newOptions.some((item) => item.value === oldValue?.value)) value = undefined;
            if (isBetween || isPresent) value = undefined;
            break;
        default:
            break;
    }
    return value;
};

export const checkRevertValue = ({ fieldType, newValue }) => {
    let result = false;
    switch (fieldType) {
        case FIELDS_TYPE.STATUS:
        case FIELDS_TYPE.SOURCE_ID:
        case FIELDS_TYPE.REFERENCE_USER:
        case FIELDS_TYPE.CREATED_BY:
        case FIELDS_TYPE.STATUS_OPPORTUNITY:
        case FIELDS_TYPE.REFERENCE_TYPE:
            if (newValue?.set?.values?.length === 0) result = true;
            break;
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
            if ([PAST_ID, FUTURE_ID].includes(newValue?.value) && !newValue?.offset) result = true;
            if (newValue?.value === CUSTOM_ID) {
                if (!newValue?.customDate || !moment(newValue?.customDate).isValid()) result = true;
            }
            break;
        case FIELDS_TYPE.TEXT:
            if (!newValue.trim()) result = true;
            break;
        default:
            break;
    }
    return result;
};

/****************************************************
 * This is for custom fields
 ****************************************************/
const getCustomFieldValue = (field = {}) => {
    const result = {};
    if (field.type === CUSTOM_FIELDS_TYPES.CHOICES) {
        result['type'] = FIELDS_TYPE.REFERENCE_TYPE;
        result['options'] = field.value.map((item) => ({ id: item, name: item, value: item }));
        result['operatorIds'] = getOperatorIds(result['type']);
        result['shouldFetch'] = false;
        result['keyChoose'] = 'value';
    } else {
        switch (field.type) {
            case CUSTOM_FIELDS_TYPES.TEXT:
                result['type'] = FIELDS_TYPE.TEXT;
                break;
            case CUSTOM_FIELDS_TYPES.NUMBER:
                result['type'] = FIELDS_TYPE.INTEGER;
                break;
            case CUSTOM_FIELDS_TYPES.DATE:
            case CUSTOM_FIELDS_TYPES.DATE_TIME:
                result['type'] = FIELDS_TYPE.CREATED_DATE;
                break;
            default:
                break;
        }
        result['operatorIds'] = getOperatorIds(result['type']);
    }
    return result;
};

export const convertCustomFieldsToFilters = (data) => {
    return data.map((item) => {
        return {
            id: item.id,
            label: item.label,
            objectType: OBJECT_TYPES.CUSTOM_FIELD,
            fieldName: item.id,
            ...getCustomFieldValue(item)
        };
    });
};

export const convertCustomFieldsToColumns = (data) => {
    const options = data.map((item) => ({ value: item.id, label: item.label }));
    return { title: i18n.t('smartView:custom_fields'), objectType: OBJECT_TYPES.CUSTOM_FIELD, options };
};
/****************************************************
 * This is end for custom fields
 ****************************************************/

/****************************************************
 * This is for custom job status
 ****************************************************/
export const convertCustomStatusToColumns = (data) => {
    const options = data.map((item) => ({
        value: item.id,
        label: item.label,
        color: item.color,
        name: item.name,
        objectType: OBJECT_TYPES.JOB
    }));
    return {
        title: i18n.t('smartView:custom_job_statuses'),
        objectType: OBJECT_TYPES.JOB,
        isCustomJobStatus: true,
        options
    };
};
/****************************************************
 * This is end for custom job status
 ****************************************************/
