import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconWorkOrder from 'assets/icon/IconWorkOrder';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { transformToCurrency } from 'common/utils/NumberUtils';
import ServiceDashboard from '../../ServiceDashboard';
import RowItemWidget from '../../components/RowItemWidget';
import WidgetDatePicker from '../../components/datepicker';
import { KEY_WIDGET_LOCAL_STORAGE, LIST_WIDGETS, TYPE_PICKER, getDefaultParamsWidget } from '../../const';
import LoadingTable from '../loading/LoadingTable';
import GridEmpty from 'app/components/grid/components/GridEmpty';

const WidgetPayments = () => {
    const { t } = useTranslation('report');
    const currency = useSelector(({ auth }) => auth.user.settings.currency);
    const refService = useRef(null);

    const keyWidget = LIST_WIDGETS.PAYMENTS;
    const defaultParams = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget] || getDefaultParamsWidget(keyWidget);

    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true,
        params: defaultParams
    });

    const { isLoading: finalIsLoading, data: finalData } = state;

    useEffect(() => {
        fetchWidgetData(defaultParams);
    }, []);

    const fetchWidgetData = (params) => {
        refService.current._handleGetData(params);
    };

    const _handleGetDataSuccess = (data) => {
        dispatchState((prev) => ({
            ...prev,
            data,
            isLoading: false
        }));
    };

    const _handleGetDataFailed = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _renderItem = ({ label, percent, value, total }, key) => {
        return (
            <RowItemWidget
                key={key}
                icon={<IconWorkOrder />}
                label={label}
                percent={percent}
                value={value}
                total={total}
                currency={currency}
            />
        );
    };

    const _handleChangeFilterWidget = (params) => {
        dispatchState({
            isLoading: true
        });
        const newParams = refService.current._handleChangeFilter({ ...params });
        fetchWidgetData(newParams);
    };

    const _renderPayments = () => {
        if (finalIsLoading) return <LoadingTable isShowDescriptionLoading />;

        const { total, description, data } = finalData;
        return (
            <div className="boxs__content --progress-bar">
                <div className="is-summary">
                    <div className="rows">
                        <div className="col">
                            <p className="fs-26 txt-ellipsis price">{transformToCurrency(total, currency)}</p>
                            <p className="fs-13 txt-ellipsis">{description}</p>
                        </div>
                    </div>
                </div>
                {data?.length > 0 ? (
                    <table className="dashboard-table">
                        <tbody>{data.map((item, index) => _renderItem(item, index.toString()))}</tbody>
                    </table>
                ) : (
                    <GridEmpty isFlat />
                )}
            </div>
        );
    };

    return (
        <div className="boxs">
            <div className="boxs__header">
                <div className="header-label flex-1">{t('payments')}</div>
                <WidgetDatePicker
                    key={keyWidget}
                    keyWidget={keyWidget}
                    typePicker={TYPE_PICKER.RANGE}
                    selectDefault={defaultParams}
                    onSelect={(values) => _handleChangeFilterWidget({ ...values })}
                />
            </div>
            {_renderPayments()}
            <ServiceDashboard
                ref={refService}
                keyWidget={keyWidget}
                onGetSuccess={_handleGetDataSuccess}
                onGetFailed={_handleGetDataFailed}
            />
        </div>
    );
};

export default WidgetPayments;
