import { JOB_CUSTOM } from 'app/const/Job';
import { getListJobStatus } from 'common/utils/JobStatusUtils';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';
import { COLUMNS_BUILT_IN } from '../../constants/columns';
import { OBJECT_TYPES } from '../../constants/types';
import { DEFAULT_COLUMN_NAME, convertColumnsName, revertColumnsName } from '../table/utils';

const exceptCheckFields = ['info', 'serviceAddress', 'billingAddress', 'nextTask', 'nextTaskDate'];
export const convertColumnToQuery = (columns, customFields = []) => {
    try {
        const result = [];
        const jobStatuses = getListJobStatus({ filter: [JOB_CUSTOM], isInclude: true, isIncludeDeleted: true });
        [...columns].forEach((column) => {
            const fieldId = column.field_id;
            const colName = revertColumnsName(fieldId);

            if (column.type_id === OBJECT_TYPES.JOB && jobStatuses.some((status) => status.id === fieldId)) {
                result.push({ field_id: fieldId, type_id: OBJECT_TYPES.JOB });
            } else if (COLUMNS_BUILT_IN[fieldId]) {
                result.push({
                    field_id: COLUMNS_BUILT_IN[colName]['value'],
                    type_id: COLUMNS_BUILT_IN[colName]['objectType']
                });
            } else if (customFields.some((customField) => customField.id === fieldId)) {
                result.push({ field_id: fieldId, type_id: OBJECT_TYPES.CUSTOM_FIELD });
            }
        });
        return result;
    } catch (error) {
        console.error('Error in convertColumnToQuery', error);
        return DEFAULT_COLUMN_NAME;
    }
};

export const getColumnIncludes = (columns, customFields = []) => {
    const jobStatuses = getListJobStatus({ filter: [JOB_CUSTOM], isInclude: true, isIncludeDeleted: true });
    const includeFieldBuiltInField = {};
    const customFieldsIncluded = [];
    const customJobStatusIncluded = [];

    columns.forEach((column) => {
        if (exceptCheckFields.includes(column.field_id)) return;
        const fieldId = column.field_id;

        if (column.type_id === OBJECT_TYPES.JOB && jobStatuses.some((status) => status.id === fieldId)) {
            customJobStatusIncluded.push(fieldId);
        } else if (COLUMNS_BUILT_IN[fieldId]) {
            const nameCapitalize = capitalizeFirstLetter(convertColumnsName(COLUMNS_BUILT_IN[fieldId]['value']));
            const key = 'include' + nameCapitalize;
            includeFieldBuiltInField[key] = true;
        } else if (customFields.some((customField) => customField.id === fieldId)) {
            customFieldsIncluded.push(fieldId);
        }
    });

    return { includeFieldBuiltInField, customFieldsIncluded, customJobStatusIncluded };
};
