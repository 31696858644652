import { TYPE_SETTING_PIPELINES } from 'common/redux/actions/settings/pipelinesAction';

const initialState = {
    isFirstTime: true,
    isLoading: true,
    data: [],
    isError: false
};

const pipelinesReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case TYPE_SETTING_PIPELINES.GET_LIST_SUCCESS:
            return {
                ...state,
                isFirstTime: false,
                data: payload,
                isLoading: false,
                isError: false
            };

        case TYPE_SETTING_PIPELINES.GET_LIST_FAIL:
            return {
                ...state,
                isFirstTime: false,
                data: [],
                isLoading: false,
                isError: true
            };

        case TYPE_SETTING_PIPELINES.CREATE_SUCCESS:
            return {
                ...state,
                data: [...state.data].map((item) => {
                    if (item.id === payload.id) {
                        item.pipelines && Array.isArray(item.pipelines)
                            ? item.pipelines.push(payload.data)
                            : (item['pipelines'] = [payload.data]);
                    }
                    return item;
                })
            };

        case TYPE_SETTING_PIPELINES.UPDATE_SUCCESS:
            return {
                ...state,
                data: [...state.data].map((item) => {
                    if (item.id === payload.id) {
                        item.pipelines = item.pipelines.map((pipeline) =>
                            pipeline.id === payload.data.id ? payload.data : pipeline
                        );
                    }
                    return item;
                })
            };

        case TYPE_SETTING_PIPELINES.DELETE_SUCCESS:
            return {
                ...state,
                data: [...state.data].map((item) => {
                    if (item.id === payload.idGroup) {
                        item.pipelines = item.pipelines.filter((pipeline) => pipeline.id !== payload.id);
                    }
                    return item;
                })
            };

        case TYPE_SETTING_PIPELINES.ORDER_SUCCESS:
            return {
                ...state,
                data: [...state.data].map((item) => {
                    if (item.id === payload.idGroup)
                        item.pipelines = reorder(item.pipelines, payload.sourceIndex, payload.destinationIndex);
                    return item;
                })
            };

        default:
            return state;
    }
};

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
export default pipelinesReducer;
