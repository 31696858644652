import React, { useContext } from 'react';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import IconPen from 'assets/icon/IconPen';

const Summary = () => {
    const { invoiceRepeatData } = useContext(InvoiceRepeatContext);

    return (
        <div className="rows --summary row-has-line border-top-border-color-grey">
            <div className="txt">
                <IconPen isHasColor />
                Summary
            </div>
            <div className="detail">
                {invoiceRepeatData.summary || 'This invoice repeats with the job.'}
                <br />
            </div>
        </div>
    );
};

export default Summary;
