import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import { ADDONS_AI } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import ButtonAIChatBot from '../components/ButtonAIChatBot';
import { ADDONS_CHAT_BOT_MENU_OPTIONS } from '../constants';

const SideMenu = () => {
    const { t } = useTranslation(['addons']);

    const ItemMenu = ({ item }) => {
        const { id, value, link } = item || {};
        return (
            <li key={id}>
                <NavLink className="sidebar-items flexcenter" to={{ pathname: addBranchPath(link) }}>
                    <p className="txt-ellipsis">{value}</p>
                </NavLink>
            </li>
        );
    };

    const renderMenu = () => {
        return ADDONS_CHAT_BOT_MENU_OPTIONS.map((item) => <ItemMenu key={item.id} item={item} />);
    };

    return (
        <div className="sidebar-chatbot flex-column gap-16">
            <Link to={addBranchPath(ADDONS_AI)} className="v2-btn-default has-icon --grey">
                <IconArrow isPrev />
                {t('back')}
            </Link>
            <ButtonAIChatBot />
            <div className="is-divider --horizontal w-100" />
            <ul className="sidebar-left sidebar-menu">{renderMenu()}</ul>
        </div>
    );
};

export default SideMenu;
