import React, { useRef, useReducer, useEffect, useMemo } from 'react';
import Loading from 'app/modules/jobdetail/tabs/timeline/Loading';
import TopNote from 'app/modules/customer/topnote/detail';
import AddNote from 'app/modules/customer/note/add';
import AddTask from 'app/modules/customer/task/add';
import NoteDetail from 'app/modules/customer/note/detail';
import TaskDetail from 'app/modules/customer/task/detail';
import { jobTimeLine } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { CUSTOMER_TYPE_IME_TILE } from 'app/modules/customer/const';
import Log from 'app/modules/customer/log';
import Header from 'app/modules/jobdetail/tabs/timeline/Header';
import ButtonLoadMore from 'app/modules/jobdetail/tabs/timeline/ButtonLoadMore';
import Created from 'app/modules/jobdetail/tabs/timeline/Created';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import { TYPE_TASK } from 'app/modules/customer/const/Task';
import { reducer } from 'app/const/Reducer';
import RealtimeTasksServices from 'app/modules/customer/detail/timeline/service/RealtimeTask';
import { useSelector } from 'react-redux';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import JobTodo from './todo';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import ReSchedule from './reschedule';

const JobDetailTimeLine = ({
    jobCreated,
    jobId,
    serviceName,
    customerDetail,
    locationName = '',
    jobTodo = {},
    jobEvent = {},
    messaging = {},
    contacts = []
}) => {
    const { permissions: userPermissions, settings: userSettings } = useSelector(({ auth }) => auth.user);

    const [state, dispatchAction] = useReducer(reducer, {
        isLoading: true,
        dataTimeLine: [],
        showMore: false,
        filters: userSettings.timeline_filters || '-1',
        topNote: null
    });

    const refAddNote = useRef(null);
    const refAddTask = useRef(null);
    const refShowLoading = useRef(null);
    const isFetching = useRef(false);
    const refJobTodo = useRef(null);

    const { isLoading: finalIsLoading, dataTimeLine: finalDataTimeLine, filters: finalFilters } = state;
    const { phones: customerPhones } = customerDetail;
    const permissionsList = userPermissions?.enabled || [];

    const isHavePermissionNote = checkPermission(permissionsList, PERMISSIONS.editOrDeleteNotes);
    const isHavePermissionComment = checkPermission(permissionsList, PERMISSIONS.deleteComments);
    const isHavePermissionInbox = checkPermission(permissionsList, PERMISSIONS.viewEmailInbox);

    useEffect(() => {
        _getCustomerTimeLine({
            limit: 20,
            offset: 0,
            filters: userSettings.timeline_filters || '-1',
            fields: 'top_note,open_tasks'
        });
    }, []);

    function _getCustomerTimeLine(params = null) {
        clientQuery(jobTimeLine(jobId), { method: 'GET', data: params }, _getCustomerTimeLineSucess);
    }

    function _getCustomerTimeLineSucess(response) {
        dispatchAction({
            isLoading: false,
            dataTimeLine: [...finalDataTimeLine, ...response.data],
            showMore: response.show_more,
            topNote: response.top_note || state.topNote
        });

        isFetching.current = false;

        refShowLoading.current && refShowLoading.current.removeLoading();
    }

    function _showAddNote() {
        refAddTask.current.checkIsOpen();
        refAddNote.current.toggleShowHide();
    }

    function _showAddTask() {
        refAddNote.current.checkIsOpen();
        refAddTask.current.toggleShowHide();
    }

    function _showJobTodo() {
        document.getElementById('js-add-todolist').classList.add('is-disable');
        refJobTodo.current._open();
    }

    function _handleLoadMore() {
        if (!isFetching.current && state.showMore) {
            refShowLoading.current.showLoading();
            isFetching.current = true;
            const params = {
                limit: 20,
                offset: finalDataTimeLine.length,
                filters: finalFilters.toString()
            };

            _getCustomerTimeLine(params);
        }
    }

    function _handleChangeFilter(filter) {
        dispatchAction({
            dataTimeLine: (finalDataTimeLine.length = 0),
            filters: filter,
            isLoading: true
        });

        const params = {
            limit: 20,
            offset: 0,
            filters: filter.toString()
        };

        setTimeout(() => {
            _getCustomerTimeLine(params);
        }, 1000);
    }

    function _handleUpdateItem(newData) {
        dispatchAction((prevState) => {
            let newDataTimeLine = [...prevState.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === newData.id) {
                    return {
                        ...item,
                        ...newData
                    };
                }
                return item;
            });

            return {
                ...prevState,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleDeleteAttachment(idAttach, idNote) {
        dispatchAction((prevState) => {
            let newDataTimeLine = [...prevState.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === idNote) {
                    return {
                        ...item,
                        attachments: [...item.attachments].filter((item) => item.id !== idAttach)
                    };
                }
                return item;
            });

            return {
                ...prevState,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleUpdateCallback(callBack) {
        dispatchAction((prevState) => {
            return callBack(prevState);
        });
    }

    function _handleAddNewItem(newData) {
        dispatchAction((prevState) => {
            return {
                ...prevState,
                dataTimeLine: [{ ...newData, location_name: locationName }, ...prevState.dataTimeLine]
            };
        });
    }

    function _handleDeleteItem(id) {
        dispatchAction((prevState) => {
            return {
                ...prevState,
                dataTimeLine: [...prevState.dataTimeLine].filter((item) => item.id !== id)
            };
        });
    }

    function _handleUpdateTopNote(newData) {
        dispatchAction({
            topNote: newData
        });
    }

    function _handleCleanQuickAdd(newId, oldId) {
        dispatchAction((prevState) => {
            let newDataTimeLine = [...prevState.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === oldId) {
                    return {
                        ...item,
                        id: newId,
                        quick_add: false
                    };
                }
                return item;
            });

            return {
                ...prevState,
                dataTimeLine: newDataTimeLine
            };
        });
    }
    const getContacts = useMemo(() => {
        if (contacts.length > 0) {
            const allEmails = contacts.map((user) => ({ email: user.email }));

            const allPhones = contacts.flatMap((user) =>
                user.phones.map((phone, index) => ({
                    id: `${user.id}${index.toString()}`,
                    phone: phone.number,
                    contact_id: user.id,
                    is_valid: phone.is_valid,
                    name: `${user.full_name} ${phone.number}`,
                    value: phone.number
                }))
            );
            return {
                phones: allPhones,
                email: allEmails
            };
        }

        return {};
    }, [contacts]);

    const getCustomerPhones = useMemo(() => {
        if (customerPhones.length > 0) {
            return customerPhones.map((item) => ({
                ...item,
                name: `${customerDetail.full_name} ${item.phone}`,
                value: item.phone
            }));
        }
        return [];
    }, [customerPhones]);

    function _renderTimeLine() {
        return finalDataTimeLine.map((item) => {
            switch (item.type) {
                case CUSTOMER_TYPE_IME_TILE.NOTE:
                    return (
                        <NoteDetail
                            onUpdate={_handleUpdateItem}
                            onUpdateCallback={_handleUpdateCallback}
                            noteDetail={item}
                            key={`${item.id}_${item.type}`}
                            type={TYPE_NOTE.JOB}
                            jobId={jobId}
                            onDelete={_handleDeleteItem}
                            isHavePermission={isHavePermissionNote}
                            isHaveDeleteComment={isHavePermissionComment}
                            onHandleDeleteAttachment={_handleDeleteAttachment}
                            filterTemplate={NOTES_TEMPLATE_TYPE.JOB}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.TASK:
                    return (
                        <TaskDetail
                            key={`${item.id}_${item.type}`}
                            onUpdate={_handleUpdateItem}
                            taskDetail={item}
                            onDelete={_handleDeleteItem}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.LOG:
                    return <Log logDetail={item} key={`${item.id}_${item.type}`} />;
                case CUSTOMER_TYPE_IME_TILE.RESCHEDULE:
                    return (
                        <ReSchedule
                            customerId={customerDetail.id}
                            jobId={jobId}
                            logDetail={item}
                            key={`${item.id}_${item.type}`}
                            messaging={messaging}
                            contacts={getContacts}
                            customerPhones={getCustomerPhones}
                        />
                    );

                default:
                    return false;
            }
        });
    }

    if (finalIsLoading) {
        return <Loading />;
    }

    return (
        <>
            <div className="details-note">
                <div className="dashboard-wrapper --main">
                    <div className="content-top-notes">
                        <TopNote
                            topNoteDetail={state.topNote}
                            type={TYPE_NOTE.JOB}
                            jobId={jobId}
                            onUpdate={_handleUpdateTopNote}
                            customerDetail={customerDetail}
                            filterTemplate={NOTES_TEMPLATE_TYPE.TOP}
                        />
                    </div>
                    <div className="lines" />
                    <div className="content-top-notes">
                        <Header
                            onShowAddNote={_showAddNote}
                            onShowAddTask={_showAddTask}
                            onShowJobTodo={_showJobTodo}
                            onChangeFilter={_handleChangeFilter}
                            filters={finalFilters}
                            isHavePermissionInbox={isHavePermissionInbox}
                        />
                        <JobTodo ref={refJobTodo} jobId={jobId} jobTodo={jobTodo} jobEvent={jobEvent} />
                        <AddNote
                            ref={refAddNote}
                            onAddNew={_handleAddNewItem}
                            cleanQuickAdd={_handleCleanQuickAdd}
                            type={TYPE_NOTE.JOB}
                            jobId={jobId}
                            filterTemplate={NOTES_TEMPLATE_TYPE.JOB}
                        />
                        <AddTask
                            customerDetail={state?.topNote?.customer || {}}
                            jobDetail={{
                                id: jobId,
                                name: serviceName
                            }}
                            socketIdKey="customer_time_line_task_service"
                            ref={refAddTask}
                            onAddNew={_handleAddNewItem}
                            cleanQuickAdd={_handleCleanQuickAdd}
                            onDelete={_handleDeleteItem}
                            type={TYPE_TASK.JOB}
                            jobId={jobId}
                        />
                        {_renderTimeLine()}
                        {state.showMore && <ButtonLoadMore onLoadMore={_handleLoadMore} ref={refShowLoading} />}
                        {jobCreated && <Created logDetail={jobCreated} />}
                    </div>
                </div>
            </div>

            <RealtimeTasksServices jobId={jobId} onUpdateCallback={_handleUpdateCallback} />
        </>
    );
};

export default JobDetailTimeLine;
