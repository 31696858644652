import classNames from 'classnames';
import React from 'react';

import { CUSTOMER_INACTIVE } from 'app/modules/customer/const';
import { getNestedValue } from 'common/utils/FunctionUtils';
import { GdGridRowContent } from './GdGridRow';
import { ColumnCheckBox } from './GridColumn';

const GdTableWithSubRows = ({
    data = [],
    keyGetId = '',
    keyGetSub = 'sub_locations',
    currency = {},
    showCheckBox = false,
    shouldCheckDefaultRow,
    totalItems = 0,
    checkedItems = [],
    columns = [],
    locations = [],
    contentConfig,
    companyUsers = [],
    typeQuickBook,
    fileTranslation = 'report',
    onEdit = null,
    handleClick = () => {},
    onOpenJob = () => {},
    onOpenInvoice = () => {},
    onOpenLocation = () => {},
    onOpenSubscription = () => {},
    onCancelSubscription = () => {},
    onEditSubscription = () => {},
    onSearchQB = () => {},
    onClickItem = () => {},
    onNameClick = () => {},
    onChangeInput = () => {},
    onSelectAssign = () => {},
    onSelectAddress = () => {},
    assignBookingPopup = () => {},
    onHandleActionGroups = () => {},
    onHandleSaveTags = () => {},
    onChangeSwitchOppPermission = () => {},
    onClickCheckItem = () => {}
}) => {
    const actionHandlers = {
        onEdit,
        handleClick,
        onOpenJob,
        onOpenInvoice,
        onOpenLocation,
        onOpenSubscription,
        onCancelSubscription,
        onEditSubscription,
        onSearchQB,
        onClickItem,
        onNameClick,
        onChangeInput,
        onSelectAssign,
        onSelectAddress,
        assignBookingPopup,
        onHandleActionGroups,
        onHandleSaveTags,
        onChangeSwitchOppPermission
    };

    return data.map((item) => {
        let isShowCheckBox = true;
        if (shouldCheckDefaultRow) isShowCheckBox = !!item.is_default;

        const rowId = keyGetId ? getNestedValue(item, keyGetId) : item.id;
        const itemChecked = checkedItems?.ids?.includes(rowId);
        const isDisableCheckBox = !!item.disabled;
        const isDisableRow = item?.status === CUSTOMER_INACTIVE;
        const subRowData = item[keyGetSub] || [];

        return (
            <div key={rowId} className={classNames('wrap-rows', { 'is-inactive': isDisableRow })}>
                <div className="rows">
                    {showCheckBox
                        ? ColumnCheckBox(
                              'col --checkbox',
                              rowId,
                              itemChecked,
                              onClickCheckItem,
                              isDisableCheckBox,
                              isShowCheckBox
                          )
                        : null}
                    <div className="line" />
                    <GdGridRowContent
                        row={item}
                        idRow={rowId}
                        totalItems={totalItems}
                        columns={columns}
                        locations={locations}
                        contentConfig={contentConfig}
                        companyUsers={companyUsers}
                        typeQuickBook={typeQuickBook}
                        fileTranslation={fileTranslation}
                        currency={currency}
                        // Actions handlers
                        {...actionHandlers}
                    />
                </div>

                {subRowData?.length ? (
                    <ListRowsSub
                        data={subRowData}
                        columns={columns}
                        contentConfig={contentConfig}
                        actionHandlers={actionHandlers}
                    />
                ) : null}
            </div>
        );
    });
};

const ListRowsSub = ({
    data = [],
    columns = [],
    locations = [],
    contentConfig,
    companyUsers = [],
    typeQuickBook = '',
    fileTranslation = '',
    actionHandlers = {}
}) => {
    const configureColumns = React.useMemo(() => {
        const result = { ...contentConfig };
        const columnsHide = ['qb_sync', 'qb_id', 'edit', 'added_by'];
        columnsHide.forEach((column) => {
            result[column] = { ...result[column], isEmpty: true };
        });
        return result;
    }, [contentConfig]);

    return data.map((item) => {
        return (
            <div id={item.id} key={item.id} className="rows">
                <div className="col --checkbox">
                    <div className="line" />
                </div>
                <div className="line" />
                <GdGridRowContent
                    row={item}
                    idRow={item.id}
                    columns={columns}
                    locations={locations}
                    contentConfig={configureColumns}
                    companyUsers={companyUsers}
                    typeQuickBook={typeQuickBook}
                    fileTranslation={fileTranslation}
                    {...actionHandlers}
                />
            </div>
        );
    });
};

export default GdTableWithSubRows;
