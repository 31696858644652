import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getListReportServiceLookupRequest } from 'common/redux/actions/reports/serviceLookupAction';
import { batchActionsRequest } from 'common/redux/actions/reports/batchAction';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { REPORT_TYPE, REPORT_LIST_BATCH_ACTIONS, REPORT_BATCH_ACTION_ITEMS } from 'app/const/report/ReportTypeContent';
import {
    SERVICE_LOOKUP_COLUMNS_SELECT_ALL,
    SERVICE_LOOKUP_LIST_FILTER,
    TABS_FILTER
} from 'app/const/report/ReportFilter';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';

import { getGridColumns } from 'app/const/report/ServiceLookup';
import { URL_EXPORT_SERVICE_LOOKUP } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { reducer } from 'app/const/Reducer';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { CUSTOMER_JOBS_UNDELETE } from 'app/const/Api';
import loadable from '@loadable/component';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { convertParamsJobStatus } from 'common/utils/JobStatusUtils';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const SingleSelect = loadable(() => import('app/components/select/singleSelect'));
const GdModal = loadable(() => import('app/components/modal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const IconClose = loadable(() => import('assets/icon/IconClose'));
const IconLoading = loadable(() => import('assets/icon/IconLoading'));
const GdButton = loadable(() => import('app/components/button'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function ReportServiceLookup() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const { state } = useLocation();

    const isFirstTime = useRef(true);
    const refModal = useRef(null);
    const refStatusBar = useRef(null);

    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SERVICE_LOOKUP);

    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.SERVICE_LOOKUP);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        total: 0,
        totalServiceLookup: null,
        rowTotal: [],
        schedules: {
            data: [],
            isLoading: true
        },
        reassignJobs: {
            ids: [],
            all: 0,
            schedule: 0
        },
        isLoading: true,
        refreshScreen: 0,
        currentTab: paramsReport?.filterTab || TABS_FILTER.ACTIVE.value
    });

    const {
        refreshScreen: finalRefreshScreen,
        currentTab: finalCurrentTab,
        isLoading: finalIsLoading,
        schedules: finalSchedules,
        reassignJobs: finalReassignJobs,
        checkedItems: finalCheckedItems,
        data: finalData,
        rowTotal: finalRowTotal,
        total: finalTotal
    } = dataReport;
    const { ids: checkedIds } = finalCheckedItems;
    const { data: dataSchedules, isLoading: schedulesIsLoading } = finalSchedules;
    const { ids: reaasignJobIds, schedule: reassignJobSchedule } = finalReassignJobs;
    const isActiveTab = finalCurrentTab === TABS_FILTER.ACTIVE.value;
    const reloadScreen = state?.reloadScreen;

    useEffect(() => {
        getListReport(paramsReport);
    }, [finalCurrentTab, reloadScreen]);

    const hideModal = () => {
        refModal.current.hideModal();
    };

    const showModal = () => {
        if (isFirstTime.current) {
            dispatchActionReport({ schedules: { ...finalSchedules, isLoading: true } });
            dispatch(actionGetListSchedule({}, _getListSuccess, _getListFailed));
        } else {
            dispatchActionReport({
                reassignJobs: { ...finalReassignJobs, ids: checkedIds }
            });
        }
        refModal.current.showModal();
    };

    function _getListSuccess(response) {
        isFirstTime.current = false;

        const schedulesData = response.data?.schedules || [];
        const newSchedulesData = schedulesData.map((item) => {
            return { id: item.id, name: item.name, value: item.id };
        });

        const dataReducer = { ...finalSchedules };
        dataReducer.isLoading = false;
        dataReducer.data = newSchedulesData;

        const reassignJobsReducer = { ...finalReassignJobs };
        reassignJobsReducer.ids = checkedIds;
        reassignJobsReducer.schedule = newSchedulesData[0].id;

        dispatchActionReport({
            schedules: dataReducer,
            reassignJobs: reassignJobsReducer
        });
    }

    function _getListFailed() {}

    const getListReport = (params, notShowLoading = false) => {
        params = getNewAPIRequest(params || {});
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const columns = params.columns;

        if (checkedIds.length || checkShouldLoading) {
            const paramReducer = {};

            if (checkShouldLoading) {
                paramReducer.checkedItems = { is_check_all: false, ids: [] };
            }

            if (checkShouldLoading) {
                paramReducer.isLoading = true;
            }

            dispatchActionReport(paramReducer);
        }

        delete params.columns;
        delete params.filterTab;
        delete params.currentPage;
        delete params.currentTab;

        dispatch(
            getListReportServiceLookupRequest(
                params,
                (response) => getListSuccess({ ...response, columns }),
                getListFailed
            )
        );
    };

    const getNewAPIRequest = (params) => {
        params.service = params.service?.toString();
        params.job_status = convertParamsJobStatus(params?.job_status)?.toString();
        params.assigned_to = params.assigned_to?.toString();
        params.city = params.city?.toString();
        params.zipcode = params.zipcode?.toString();
        params.tags = params.tags?.toString();
        return params;
    };

    const getListSuccess = ({ data, total_service_lookup, columns }) => {
        const dataReducer = {};

        dataReducer.isLoading = false;
        dataReducer.total = total_service_lookup?.count || 0;
        dataReducer.data = isActiveTab
            ? data
            : data.map((item) => {
                  return { ...item, disabled: false };
              });
        dataReducer.totalServiceLookup = total_service_lookup;
        dataReducer.refreshScreen = finalRefreshScreen + 1;

        handleCreateRowTotal(total_service_lookup, columns, dataReducer);
    };

    function getListFailed() {
        dispatchActionReport({ isLoading: false });
    }

    const _handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') {
            handleCreateRowTotal(dataReport.totalServiceLookup, params.columns);
        }
    };

    const _handleUpdate = () => {
        getListReport(getLocalStorage(keyLocal));
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport({ checkedItems: stateCheckedItems });
    };

    const handleBatchActions = (name, value) => {
        if (!checkedIds.length) {
            return false;
        }

        switch (value) {
            case REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS:
                showModal();
                break;
            default:
                break;
        }
    };

    const _handleSelectReassignJobs = (name, value) => {
        dispatchActionReport({ reassignJobs: { ...finalReassignJobs, schedule: value } });
    };

    const _handleReassignJobsAction = (value) => {
        hideModal();
        dispatchActionReport({ reassignJobs: { ...finalReassignJobs, all: value } });
        dispatch(
            batchActionsRequest(
                {
                    value: REPORT_BATCH_ACTION_ITEMS.SERVICE_LOOKUP_REASSIGN_JOBS,
                    ids: reaasignJobIds?.toString(),
                    all: value,
                    schedule: reassignJobSchedule
                },
                (response) => _handleReassignJobsActionSuccess(response),
                (response) => _handleReassignJobsActionFailed(response)
            )
        );
    };

    function _handleReassignJobsActionSuccess(response) {
        const dataReducer = {};

        dataReducer.checkedItems = { is_check_all: false, ids: [] };

        const scheduleSelected = dataSchedules.find((schedule) => schedule.id === reassignJobSchedule);

        if (scheduleSelected) {
            dataReducer.data = finalData.map((item) => {
                if (reaasignJobIds?.includes(item.id)) {
                    return {
                        ...item,
                        assigned_to: scheduleSelected.name
                    };
                }
                return item;
            });
        }

        dispatchActionReport(dataReducer);

        refStatusBar.current.showStatusBar(
            'show_success',
            response.message || t(`report:success`),
            LIST_STATUS.SUCCESS
        );
    }

    function _handleReassignJobsActionFailed(response) {
        const dataReducer = {};
        dataReducer.checkedItems = { is_check_all: false, ids: [] };
        dispatchActionReport(dataReducer);

        refStatusBar.current.showStatusBar('show_error', response.message || t(`report:failed`), LIST_STATUS.ERROR);
    }

    const handleCreateRowTotal = (data, columns, actionReport = {}) => {
        data = !!data && typeof data === 'object' ? data : {};
        let totalColumns = ['customer', ...columns];
        totalColumns = SERVICE_LOOKUP_COLUMNS_SELECT_ALL.filter((col) => totalColumns.includes(col));

        const titleTotalServices = `${t('report:total')} ${data?.count} ${t('report:services')}`;
        if (columns.length) {
            totalColumns = totalColumns.map((column) => {
                if (data[column]) {
                    return { id: column, isShow: true, totalAmount: data[column], isCurrency: true };
                }
                return { id: column, isShow: true };
            });
            totalColumns[0].title = titleTotalServices;
        } else {
            totalColumns = [
                {
                    id: 'customer',
                    isShow: true,
                    title: titleTotalServices
                }
            ];
        }
        dispatchActionReport({ rowTotal: totalColumns, ...actionReport });
    };

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.SERVICE_LOOKUP,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _changeTab = ({ filterTab }) => {
        dispatchActionReport({ currentTab: filterTab });
    };

    const _handleUndelete = () => {
        dispatchActionReport({
            data: finalData.filter((item) => !checkedIds?.includes(item.id)),
            checkedItems: { is_check_all: false, ids: [] }
        });

        const _success = ({ message, error }) => {
            refStatusBar.current.showStatusBar('show_success', message || t(`report:success`), LIST_STATUS.SUCCESS);
            if (error) {
                refStatusBar.current.showStatusBar(
                    'show_error',
                    error.message || t(`report:failed`),
                    LIST_STATUS.ERROR
                );
            }
            getListReport(getLocalStorage(keyLocal), true);
        };

        const _fail = ({ message }) => {
            refStatusBar.current.showStatusBar('show_error', message || t(`report:failed`), LIST_STATUS.ERROR);
        };

        clientQuery(CUSTOMER_JOBS_UNDELETE, { data: { ids: checkedIds }, method: 'PUT' }, _success, _fail);
    };

    const _handleOpenDetail = ({ key, row }) => {
        switch (key) {
            case 'service_type':
                dispatch(actionOpenJobDetail({ id: row.id }));
                break;
            case 'invoice_number':
                dispatch(
                    actionOpenInvoice({
                        id: row.invoice_id,
                        status: '',
                        total: '',
                        isRecurring: false
                    })
                );
                break;
            default:
                break;
        }
    };

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: finalTotal })}
                activePrint
                url={URL_EXPORT_SERVICE_LOOKUP}
                params={paramsReport}
                pageExport={LIST_EXPORT.EXPORT_REPORT_SERVICE_LOOKUP}
                isDisable={finalIsLoading}
                refresh={finalRefreshScreen}
            />
        );
    }

    function _renderHeaderBootom() {
        const idsSelected = checkedIds.length;

        return (
            <div
                className={classNames('header --filter', {
                    'is-disable': !idsSelected
                })}
            >
                <>
                    <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={finalCheckedItems} />
                    {!isActiveTab ? (
                        <GdButton
                            title={t('report:un_delete')}
                            onClick={_handleUndelete}
                            className="v2-btn-default has-icon header-items"
                            iconClassName="mr-1"
                            iconSvg={<IconDoubleCheck />}
                        />
                    ) : (
                        <SingleSelect
                            options={REPORT_LIST_BATCH_ACTIONS.SERVICE_LOOKUP}
                            title="batch_actions"
                            fileTranslation="report"
                            name="batch_actions"
                            onSelect={handleBatchActions}
                            classWrapper={'v2-dropdown header-items'}
                        />
                    )}
                </>
            </div>
        );
    }

    const _renderHeaderModal = () => {
        return (
            <div className="header-modal">
                <h3 className="header-modal__label">{t('report:batch_reassign_jobs')}</h3>
                <div onClick={hideModal} className="v2-btn-default --icon-lg --transparent">
                    <IconClose />
                </div>
            </div>
        );
    };

    const _renderBodyModal = () => {
        return (
            <div className="body-modal">
                <div className="rows --multi">
                    <div className="txt">{`${t('report:reassign')} ${checkedIds.length} ${t('report:jobs_to')}`}:</div>
                    {schedulesIsLoading ? (
                        <div className="v2-dropdown">
                            <div className="dropbtn items --large">
                                <div className="items justify-center">
                                    <div className="loading -ajaxbar">
                                        <IconLoading />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SingleSelect
                            options={dataSchedules}
                            selected={reassignJobSchedule}
                            name="reassign_jobs"
                            fileTranslation="report"
                            onSelect={_handleSelectReassignJobs}
                            classWrapper={'v2-dropdown'}
                        />
                    )}
                </div>
                <div className="rows">
                    <div
                        onClick={() => {
                            _handleReassignJobsAction(0);
                        }}
                        className="v2-btn-default"
                    >
                        {t('report:reassign_this_job_only')}
                    </div>
                </div>
                <div
                    onClick={() => {
                        _handleReassignJobsAction(1);
                    }}
                    className="rows"
                >
                    <div className="v2-btn-default">{t('report:reassign_this_job_and_all_recurring')}</div>
                </div>
            </div>
        );
    };

    const _renderFooterModal = () => {
        return (
            <div className="footer-modal btn-close">
                <span className="v2-btn-default --transparent" onClick={hideModal}>
                    {t('common:cancel')}
                </span>
            </div>
        );
    };

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                onSelectTab={_changeTab}
                reportType={REPORT_TYPE.SERVICE_LOOKUP}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <StatusBar ref={refStatusBar} />
                    <GdModal
                        ref={refModal}
                        id="reassign_jobs"
                        header={_renderHeaderModal()}
                        footer={_renderFooterModal()}
                        classWrapper="batch-job"
                    >
                        {_renderBodyModal()}
                    </GdModal>

                    <HeaderBottom
                        typeReport={REPORT_TYPE.SERVICE_LOOKUP}
                        filters={SERVICE_LOOKUP_LIST_FILTER}
                        handleChangeFilter={_handleChangeFilter}
                        handleUpdate={_handleUpdate}
                        isLoading={finalIsLoading}
                        companyUsers={companyUsers}
                        forceRerenderFilter={reloadScreen}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column has-checkbox scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData}
                            showCheckBox
                            fileTranslation={'report'}
                            handleClickHeader={handleActionHeader}
                            handleClick={_handleOpenDetail}
                            checkedItems={finalCheckedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGridColumns(paramsReport?.columns, paramsReport?.order, isActiveTab)}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={finalRowTotal}
                                    isLoading={finalIsLoading}
                                    contentConfig={getGridColumns(paramsReport?.columns, null)?.contentConfig}
                                    showCheckBox
                                    {...props}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportServiceLookup;
