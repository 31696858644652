import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const DocumentService = ({ onOpen }) => {
    const { documentData } = useSelector(({ documentReducer }) => documentReducer);
    useEffect(() => {
        documentData && onOpen(documentData);
    }, [documentData]);

    return false;
};

export default DocumentService;
