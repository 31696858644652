import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconTooltip from 'assets/icon/IconTooltip';
import IconEye from 'assets/icon/IconEye';

function ContentSettingTemplateAddress({ onEdit, onPreview, address }) {
    const { t } = useTranslation(['setting']);

    const _handleEdit = () => {
        onEdit();
    };

    const _handlePreview = () => {
        onPreview();
    };

    return (
        <div className="boxs__header">
            <div className="d-flex align-center justify-space-between">
                <div className="txt d-flex">
                    <span className="txt-ellipsis">{t('setting:choose_your_template')}</span>

                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext">{t('setting:tooltip')} </span>
                    </div>
                </div>
                <div className="v2-btn-default --grey has-icon" onClick={_handlePreview}>
                    <IconEye /> {t('setting:preview')}
                </div>
            </div>
            <p className="desc">{address}</p>
            <a className="v2-btn-default --grey btn-edit" onClick={_handleEdit}>
                {t('setting:edit')}{' '}
            </a>
        </div>
    );
}

ContentSettingTemplateAddress.propTypes = {
    onEdit: PropTypes.func,
    onPreview: PropTypes.func,
    address: PropTypes.string
};

export default ContentSettingTemplateAddress;
