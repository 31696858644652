import React from "react";
import IconSplash from 'assets/icon/IconSplash';
import "assets/css/common/_splash.scss";

function Loading() {
  return (
    <div className="splash">
      <div className="wrapper">
        <div className="wrapper__loader">
          <IconSplash />
        </div>
      </div>
    </div>
  );
}

export default Loading;
