import IconArrow from 'assets/icon/IconArrow';
import IconSingleStep from 'assets/icon/IconSingleStep';

export const NET_CHANGE = 'net_change';

export const getGridColumnStatusChange = () => {
    const COLUMN_HEADER_STATUS_CHANGE = [
        {
            id: 'status',
            title: 'status',
            style: 'col col-lg',
            tooltip: ''
        },
        {
            id: 'entered',
            title: 'entered',
            style: 'col col-sm',
            tooltip: 'tooltip_entered_column'
        },
        {
            id: 'left',
            title: 'left',
            style: 'col col-sm',
            tooltip: 'tooltip_left_column'
        },
        {
            id: 'started',
            title: 'started',
            style: 'col col-sm --label-r',
            tooltip: 'tooltip_started_column'
        },
        {
            id: 'icon',
            title: '',
            style: 'col col-icon',
            tooltip: ''
        },
        {
            id: 'ended',
            title: 'ended',
            style: 'col col-sm',
            tooltip: 'tooltip_ended_column'
        },
        {
            id: 'net_change',
            title: 'net_change',
            style: 'col col-net',
            tooltip: 'tooltip_net_change_column'
        }
    ];

    const COLUMN_STATUS_CHANGES = ['name', 'entered', 'left', 'started', 'icon', 'ended', 'net_change'];

    const CONTENT_STATUS_CHANGES = {
        name: {
            id: 'name',
            style: 'col col-lg flexcenter fw-500',
            icon: <IconSingleStep />,
            className: 'col-label ml-1',
            isShowTooltip: false
        },
        entered: {
            id: 'entered',
            style: 'col col-sm fw-500',
            icon: null,
            className: 'col-label',
            isShowTooltip: true
        },
        left: {
            id: 'left',
            style: 'col col-sm fw-500',
            icon: null,
            className: 'col-label',
            isShowTooltip: true
        },
        started: {
            id: 'started',
            style: 'col col-sm --label-r fw-500',
            icon: null,
            className: 'col-label',
            isShowTooltip: true
        },
        icon: {
            id: '',
            style: 'col col-icon',
            icon: <IconArrow />,
            className: '',
            isShowTooltip: false
        },
        ended: {
            id: 'ended',
            style: 'col col-sm fw-500',
            icon: null,
            className: 'col-label',
            isShowTooltip: true
        },
        net_change: {
            id: 'net_change',
            style: 'col col-net flexcenter gap-6',
            icon: null,
            className: '',
            isShowTooltip: true
        }
    };

    return {
        headers: COLUMN_HEADER_STATUS_CHANGE,
        columns: COLUMN_STATUS_CHANGES,
        contentConfig: CONTENT_STATUS_CHANGES
    };
};
