import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_NEW_CUSTOMERS_GET_LIST,
    REPORT_NEW_CUSTOMERS_GET_LIST_YEAR,
    REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_LINE,
    REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_PIE,
    REPORT_NEW_CUSTOMERS_GET_LIST_SOURCE_FILTER
} from 'app/const/Api';

export function* getListReportNewCustomers({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_NEW_CUSTOMERS_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListDataChartNewCustomers({ params, actionSuccess, actionFailed }) {
    try {
        const responseLineChart = yield call(fetchWithToken, REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_LINE, params);
        const responsePieChart = yield call(fetchWithToken, REPORT_NEW_CUSTOMERS_GET_LIST_DATA_CHART_PIE, params);
        const response = {
            dataLineChart: responseLineChart?.data,
            dataPieChart: responsePieChart?.data,
            year: params.year,
            success: responseLineChart.success || responsePieChart.success
        };

        if (response.success) {
            const totalPieChart = response.dataPieChart.datasets.reduce((a, b) => a + b, 0);
            response.dataPieChart.datasets = response.dataPieChart.datasets.map((value) => {
                const percent = value / (totalPieChart / 100);
                return percent.toFixed(1);
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListYearNewCustomers({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_NEW_CUSTOMERS_GET_LIST_YEAR);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListSourceNewCustomerFilter({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_NEW_CUSTOMERS_GET_LIST_SOURCE_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
