import React from 'react';
import PreviewNoteCollapse from '../collapse';

const JobNote = ({ notes = [] }) => {
    if (!notes.length) return null;
    return notes.map((note) => (
        <PreviewNoteCollapse key={note.id} isInnerHTML note={note.note} jobNote={note} extraClass="is-jobnote" />
    ));
};

export default JobNote;
