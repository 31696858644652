import React from 'react';
import IconNote from 'assets/icon/IconNote';
import IconEmail from 'assets/icon/IconEmail';
import IconDone from 'assets/icon/IconDone';
import IconClock from 'assets/icon/IconClock';
import { FILTER_CUSTOMER_TIMELINE_ICON } from 'app/modules/customer/const';
import classNames from 'classnames';
import IconTelephone from 'assets/icon/IconTelephone';

const Checkbox = ({
    wrapClass = '',
    label = '',
    checked = false,
    onChangeValue = () => {},
    value = '',
    classLabel = '',
    classSpan = '',
    disabled = false,
    typeIcon = '',
    children = null,
    id = '',
    classLabelChild = ''
}) => {
    const handleOnChangeValue = (e) => {
        e && e.stopPropagation();
        onChangeValue(e, value);
    };

    const renderIcons = () => {
        switch (typeIcon) {
            case FILTER_CUSTOMER_TIMELINE_ICON.NOTES:
                return <IconNote />;
            case FILTER_CUSTOMER_TIMELINE_ICON.EMAILS:
                return <IconEmail isEmailAddress />;
            case FILTER_CUSTOMER_TIMELINE_ICON.ALL_OPEN_TASK:
                return <IconDone isHasColor />;
            case FILTER_CUSTOMER_TIMELINE_ICON.MY_OPEN_TASK:
                return <IconDone isHasColor />;
            case FILTER_CUSTOMER_TIMELINE_ICON.ALL_COMPLETED_TASK:
                return <IconDone isHasColor />;
            case FILTER_CUSTOMER_TIMELINE_ICON.ACTIVITIES:
                return <IconClock isHasColor />;
            case FILTER_CUSTOMER_TIMELINE_ICON.CALLS:
                return <IconTelephone isNoline />;
            default:
                return null;
        }
    };

    const idCheckbox = id || `${label}-${value}-check-box`;

    return (
        <div className={classNames(wrapClass, { 'is-disable': disabled, 'check-items': !wrapClass })}>
            <input
                id={idCheckbox}
                type="checkbox"
                checked={checked}
                onChange={handleOnChangeValue}
                disabled={disabled}
            />
            <div className={`${classLabel ? classLabel : 'item-checkbox '}`}>
                <label className={classLabelChild} htmlFor={idCheckbox}>
                    {children}
                    {label && (
                        <span className={`${classSpan} txt-ellipsis`}>
                            {!!typeIcon && renderIcons()}
                            {label}
                        </span>
                    )}
                </label>
            </div>
        </div>
    );
};

export default Checkbox;
