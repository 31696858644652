/* eslint-disable no-unused-vars */
import React, { useLayoutEffect, useReducer, useRef } from 'react';
import IconAlert from 'assets/icon/IconAlert';
import { JOB_COMPLETED } from 'app/const/Job';
import { INVOICE_STATUS } from 'app/const/Filters';
import IconClock from 'assets/icon/IconClock';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reducer } from 'app/const/Reducer';
import moment from 'moment';
import { COLOR_SCHEDULES } from 'app/modules/calendar/const';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { getColorWorkPool } from 'app/modules/calendar/ultil/Map';
import { getJobStatus } from 'common/utils/JobStatusUtils';

const JobItem = ({ dataJob: defaultData, activeDragable, onDBClick }) => {
    const { t } = useTranslation();
    const setHandleRef = useRef(null);
    const [state, dispatchState] = useReducer(reducer, { dataJob: defaultData });
    const scheduleList = useSelector(({ schedules }) => schedules.data.schedules);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    // const { dataJob } = state;
    const dataJob = defaultData || {};
    const { colorEvent: dataJobColor = {}, tile: finalJobTitle = {} } = dataJob;

    const dataJobTextColor = dataJobColor.text;

    let timmer = null;
    let clicks = 0;

    const defaultTile = defaultData.tile || {};

    useLayoutEffect(() => {
        dispatchState((prevState) => ({
            ...prevState,
            colorBorder: getColorWorkPool({
                start,
                end,
                jobStart: dataJob.start,
                scheduleId: defaultData?.schedule?.id
            })
        }));

        // dispatchState((prevState) => {
        //     const user = scheduleList.find((item) => item.id === defaultData.schedule?.id) || null;
        //     return {
        //         ...prevState,
        //         dataJob: {
        //             ...prevState.dataJob,
        //             tile: { ...defaultTile, header: defaultTile.header_long || defaultTile.header }
        //         },
        //         start: defaultData.start,
        //         borderColorLeft: user
        //             ? user.colors[+moment.utc(defaultData.start).format('DD') - 1]
        //             : prevState.dataJob.schedule_color
        //     };
        // });
    }, [scheduleList, defaultData.resourceId, defaultData.start, defaultData.schedule, defaultTile]);

    function initialiseDrag() {
        setHandleRef.current.setAttribute(
            'data',
            JSON.stringify({ ...dataJob, resourceId: `GD_fake_id_${Date.now()}` })
        );
        window.addEventListener('mouseup', _stopDragging, false);
        window.addEventListener('touchend', _stopDragging, false);
    }

    function _stopDragging() {
        setHandleRef.current.classList.remove('has-drag');
        setHandleRef.current.setAttribute('data', false);
        window.removeEventListener('mouseup', _stopDragging, false);
        window.removeEventListener('touchend', _stopDragging, false);
    }

    function _resetClick() {
        clicks = 0;
        clearTimeout(timmer);
    }

    function _eventClick() {
        clicks++;
        if (clicks === 1) {
            timmer = setTimeout(() => {
                _resetClick();
            }, 1000);
        } else {
            onDBClick(dataJob);
            _resetClick();
        }
    }

    function _renderTitleContent(content = [], color) {
        return content.map((item, index) => {
            if (!item.length) {
                return <div key={index.toString()}></div>;
            } else {
                return (
                    <div key={index.toString()} className="info" style={{ color: color }}>
                        {item}
                    </div>
                );
            }
        });
    }

    return (
        <div
            key={dataJob.id}
            onClick={_eventClick}
            onMouseDown={initialiseDrag}
            onTouchStart={initialiseDrag}
            ref={setHandleRef}
            className={`${activeDragable && !!!dataJob.locked ? 'fc-event' : 'fc-event-block'} jobslist active-jobs`}
            // style={{ backgroundColor: dataJobColor.background }}
            style={{ backgroundColor: dataJobColor.background, borderLeft: `solid 4px ${state.colorBorder}` }}
        >
            <div className="name" style={{ color: dataJobTextColor }}>
                {!!dataJob.locked && (
                    <div className="icon tooltip">
                        <IconClock clockActive />
                    </div>
                )}
                {getJobStatus(dataJob.status)?.type === JOB_COMPLETED &&
                    dataJob?.invoice?.status === INVOICE_STATUS.STATUS_DRAFT && (
                        <div className="icon tooltip">
                            <IconAlert />
                            <p className="tooltiptext top">
                                {t('the_completeds_job_invoice_is_still_in_draft_status')}
                            </p>
                        </div>
                    )}
                <span className="word-break">{finalJobTitle.header_long || finalJobTitle.header || ''}</span>
            </div>

            {_renderTitleContent(finalJobTitle.content || [], dataJobTextColor)}
        </div>
    );
};

export default JobItem;
