import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import IconLoading from 'assets/icon/IconLoading';
import { useTranslation } from 'react-i18next';
import { DEFAULT_ALL, CUSTOMER_SEARCH_LIMIT, KEY_CODE_ESCAPE } from 'app/const/App';
import { getListLocation } from 'common/redux/actions/customers/locationAction';
import IconLocation from 'assets/icon/IconLocation';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

const FilterLocations = ({ customerId = '', selected = DEFAULT_ALL, onSelect, isDocCustomer = false }) => {
    const { t } = useTranslation(['common']);
    const finalTitle = useRef('Select location...');
    const refSearch = useRef(null);

    const dispatch = useDispatch();

    const [state, setState] = useReducer(reducer, {
        isLoading: true,
        options: [],
        showMore: false,
        isVisible: false
    });

    const { isVisible: finalIsVisible, options: finalOptions, isLoading } = state;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_customer_location');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_customer_location');

        if (
            refSearch.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refSearch.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    useEffect(() => {
        _getListCustomerLocation({ limit: CUSTOMER_SEARCH_LIMIT, offset: 0, customer_id: customerId });
    }, [customerId]);

    function _getListCustomerLocation(params) {
        dispatch(
            getListLocation(
                params,
                (res) => _getListCustomerLocationSuccess(res, params.offset),
                _getListCustomerLocationFailed
            )
        );
    }

    function _getListCustomerLocationSuccess(response, offset) {
        const listResponse = [...response.data];

        setState({
            isLoading: false,
            options: !offset ? listResponse : [...finalOptions, ...listResponse],
            showMore: response.show_more
        });

        if (listResponse.length === 1) {
            handleSelectOption(null, listResponse[0]);
        }
    }

    function _getListCustomerLocationFailed() {
        setState({
            isLoading: false
        });
    }

    const handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    const handleSelectOption = (e, value) => {
        e && e.stopPropagation();

        if (value.id !== selected) {
            finalTitle.current = isDocCustomer ? `${value.name}` : value.address;

            finalIsVisible && setState({ isVisible: false });

            onSelect(value.id);
        }
        return false;
    };

    function handleOnScrollContent(e) {
        if (!isLoading && isScrollToEndBottom(e.target) && state.showMore) {
            setState({ isLoading: true });

            _getListCustomerLocation({
                limit: CUSTOMER_SEARCH_LIMIT,
                offset: finalOptions.length,
                customer_id: customerId
            });
        }
    }

    const renderList = (list) => {
        return list.map((item) => {
            const idDocs = item?.id;
            const isCheck = idDocs === selected;
            const finalTitle = isDocCustomer ? `${item.name}` : item.address;

            return (
                <li
                    key={idDocs}
                    onClick={(e) => {
                        handleSelectOption(e, item);
                    }}
                    title={finalTitle}
                    className={`items  ${isCheck ? 'active' : ''}`}
                    tabIndex="0"
                >
                    <p className="txt-ellipsis">{finalTitle}</p>
                </li>
            );
        });
    };

    function _renderListOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu content-checked">
                <ul id="show_result_customer_location" className="scrolls" onScroll={handleOnScrollContent}>
                    {renderList(finalOptions)}
                    {!finalOptions.length && !isLoading && (
                        <li className="items justify-center pointer-events-none">
                            <div className="loading -ajaxbar">
                                {t('common:no_data_to_display', { title: t('common:locations') })}
                            </div>
                        </li>
                    )}
                    {isLoading && (
                        <li className="items justify-center">
                            <div className="loading -ajaxbar">
                                <IconLoading />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    return (
        <>
            <div ref={refSearch} className={`v2-dropdown select-list active`}>
                <div className="dropbtn items has-icon" onClick={(e) => handleOpen(e)} tabIndex="0">
                    <IconLocation />
                    <div className="txt-ellipsis flex-1">
                        {!!selected ? (
                            finalTitle.current
                        ) : (
                            <span className="grey-verylight">{t('common:select_location')}</span>
                        )}
                    </div>
                    <div>
                        <IconDropDown />
                    </div>
                </div>
                {_renderListOptions()}
            </div>
            {!!selected ? (
                <p className="field-has-tips is-success">
                    <span className="is-dot"></span>
                    {t('common:document_will_be_added_to_that_location')}
                </p>
            ) : (
                <p className="field-has-tips">{t('common:select_location_to_add_documents')}</p>
            )}
        </>
    );
};

export default FilterLocations;
