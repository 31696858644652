import { COMMON } from 'app/const/App';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import IconSync from 'assets/icon/IconSync';
import IconTime from 'assets/icon/IconTime';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CustomerInvoiceActions = ({
    totalItems = 0,
    totalSelected = 0,
    isArchived = 0,
    isDeleted = 0,
    onActionClick = () => {},
    onAddInvoice = () => {},
    quickBooksAddons = null,
    isLoadCustomerSuccess = false,
    checkedItems = {}
}) => {
    const { t } = useTranslation('customers');
    const isDisable = !totalSelected || isLoadCustomerSuccess;

    return (
        <div className="header --filter">
            <div className={classNames('header__left flex-1')}>
                <CheckBoxHeader isShowTotal total={totalItems} checkedItems={checkedItems} />
                {!!isDeleted && <ListActionsDeleted isDisable={isDisable} onActionClick={onActionClick} />}
                {!!isArchived && (
                    <ListActionArchived
                        isDisable={isDisable}
                        onActionClick={onActionClick}
                        quickBooksAddons={quickBooksAddons}
                    />
                )}
                {!isDeleted && !isArchived && (
                    <ListActionActive
                        isDisable={isDisable}
                        onActionClick={onActionClick}
                        quickBooksAddons={quickBooksAddons}
                    />
                )}
            </div>

            <div
                className={classNames('v2-btn-main btn-modal --bg-green', {
                    'is-disable': isLoadCustomerSuccess
                })}
                onClick={onAddInvoice}
            >
                {t('new_invoice')}
            </div>
        </div>
    );
};

const ListActionArchived = ({ onActionClick = () => {}, isDisable = false, quickBooksAddons = null }) => {
    const { t } = useTranslation('customers');

    return (
        <>
            <div
                className={classNames(
                    'v2-btn-default --grey has-icon svg-delete-grey btn-modal btn-delete header-items',
                    { 'is-disable': isDisable }
                )}
                onClick={() => onActionClick(COMMON.DELETE)}
            >
                <IconTrash />
                {t('delete')}
            </div>
            <div
                className={classNames('v2-btn-default --grey has-icon btn-modal btn-activate header-items', {
                    'is-disable': isDisable
                })}
                onClick={() => onActionClick(COMMON.ACTIVATE)}
            >
                <IconDoubleCheck />
                {t('activate')}
            </div>
            {!!quickBooksAddons && (
                <div
                    className={classNames('v2-btn-default --grey has-icon header-items', { 'is-disable': isDisable })}
                    onClick={() => onActionClick(COMMON.SYNC_TO_QB)}
                >
                    <IconSync />
                    {t('sync_to_qb')}
                </div>
            )}
        </>
    );
};

const ListActionsDeleted = ({ onActionClick = () => {}, isDisable = false }) => {
    const { t } = useTranslation('customers');

    return (
        <>
            <div
                className={classNames(
                    'v2-btn-default --grey has-icon svg-delete-grey btn-modal btn-delete header-items',
                    { 'is-disable': isDisable }
                )}
                onClick={() => onActionClick(COMMON.UNDELETE)}
            >
                <IconDoubleCheck />
                {t('un_delete')}
            </div>
            <div
                className={classNames('v2-btn-default --grey has-icon btn-modal btn-archived header-items', {
                    'is-disable': isDisable
                })}
                onClick={() => onActionClick(COMMON.ARCHIVED)}
            >
                <IconTime />
                {t('archive')}
            </div>
            <div
                className={classNames(
                    'v2-btn-default --grey has-icon svg-delete-grey btn-modal btn-permdelete header-items',
                    { 'is-disable': isDisable }
                )}
                onClick={() => onActionClick(COMMON.PERMANENTLY_DELETE)}
            >
                <IconTrash />
                {t('permanently_delete')}
            </div>
        </>
    );
};

const ListActionActive = ({ onActionClick = () => {}, isDisable = false, quickBooksAddons = null }) => {
    const { t } = useTranslation('customers');
    return (
        <>
            <div
                className={classNames(
                    'v2-btn-default --grey has-icon svg-delete-grey btn-modal btn-delete header-items',
                    { 'is-disable': isDisable }
                )}
                onClick={() => onActionClick(COMMON.DELETE)}
            >
                <IconTrash />
                {t('delete')}
            </div>
            <div
                className={classNames('v2-btn-default --grey has-icon btn-modal btn-archived header-items', {
                    'is-disable': isDisable
                })}
                onClick={() => onActionClick(COMMON.ARCHIVED)}
            >
                <IconTime />
                {t('archive')}
            </div>
            {!!quickBooksAddons && (
                <div
                    className={classNames('v2-btn-default --grey has-icon header-items', { 'is-disable': isDisable })}
                    onClick={() => onActionClick(COMMON.SYNC_TO_QB)}
                >
                    <IconSync />
                    {t('sync_to_qb')}
                </div>
            )}
        </>
    );
};

export default CustomerInvoiceActions;
