import IconCodeView from 'assets/icon/IconCodeView';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const CustomCodeOption = ({ onInsert = () => {}, resetActions = 0 }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        if (resetActions) {
            setIsActive(false);
        }
    }, [resetActions]);

    const _handleToggle = () => {
        onInsert(!isActive);
        setIsActive(!isActive);
    };

    return (
        <span
            className={classNames('editor-controls__btn tooltip btn-viewcode', { active: isActive })}
            onClick={_handleToggle}
        >
            <IconCodeView />
            <span className="tooltiptext top">Code View</span>
        </span>
    );
};

export default CustomCodeOption;
