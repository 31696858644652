import React from 'react';

import { getJobStatus } from 'common/utils/JobStatusUtils';
import ItemJobStatus from 'app/components/job/ItemJobStatus';

const TitleJobStatus = ({ data = [], ...props }) => {
    return data.map((item) => {
        const status = getJobStatus(item);
        if (!status) return null;
        return <ItemJobStatus key={item} defaultStatus={status} {...props} />;
    });
};

export default TitleJobStatus;
