import { STATUS_BAR_ACTIONS } from 'common/redux/actions/common/statusbarAction';
import { MAX_NUMBER_STATUS } from 'app/const/App';

const statusBarReducer = (state = {
    data: []
}, action) => {
    switch (action.type) {
        case STATUS_BAR_ACTIONS.ADD_ITEM_TO_LIST_STATUS:
            const newData = [...state.data, action.payload];
            if (newData.length > MAX_NUMBER_STATUS) newData.shift();
            return { ...state, data: newData };
        case STATUS_BAR_ACTIONS.ADD_LIST_TO_LIST_STATUS:
            return { ...state, data: action.payload };
        case STATUS_BAR_ACTIONS.REMOVE_ITEM_FROM_LIST_STATUS:
            return { ...state, data: state.data.filter((item) => item.id !== action.payload) };
        case STATUS_BAR_ACTIONS.CLEAR_LIST_STATUS:
            return { ...state, data: [] };
        default:
            return state;
    }
};

export default statusBarReducer;
