export default function IconBold() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 18.5C14.7091 18.5 16.5 17.9672 16.5 15C16.5 12.0346 14.7091 11.5 12.5 11.5C8.80101 11.5 8.5 10.5 8.5 15C8.5 18.5699 8.50456 18.5 12.5 18.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 11.5C13.1568 11.5 14.4999 11.0433 14.4999 8.5C14.4999 5.9582 13.1568 5.5 11.5 5.5C8.38294 5.5 8.49996 5.25994 8.49996 8.5C8.49996 12.4196 8.45647 11.5 11.5 11.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49854 5.5V18.5L11.5 18.4994H8.49854V5.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
