import IconAlert from 'assets/icon/IconAlert';
import IconDone from 'assets/icon/IconDone';
import React from 'react';

const IconPhone = ({ isValid = null, ...props }) => {
    switch (isValid) {
        case 0:
            return (
                <span className="phone-icon" title="This is not a mobile number, messages will not be delivered">
                    <IconAlert {...props} />
                </span>
            );
        case 1:
            return (
                <span className="phone-icon" title="This number is a valid mobile number">
                    <IconDone isSuccess {...props} />
                </span>
            );
        default:
            return null;
    }
};

export default IconPhone;
