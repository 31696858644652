import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';

const Loading = forwardRef((props, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true
    });

    useImperativeHandle(ref, () => ({
        removeLoading: _handleRemoveLoading
    }));

    function _handleRemoveLoading() {
        dispatchState({
            isLoading: false
        });
    }

    if (!state.isLoading) {
        return false;
    }

    return (
        <div className="wrap-body">
            <div className="wrap-loading --preview-document py-4">
                <div className="company-info align-center mb-8">
                    <div className="company-info-logo bg-transparent border-grey-softlight" />
                    <div className="loading --animation --line --half mt-4 mb-2" />
                </div>
                <div className="d-flex my-3">
                    <div className="loading --animation --line --onetenth mr-2" />
                    <div className="loading --animation --line --threefourth mt-0" />
                </div>
                <div className="d-flex my-3">
                    <div className="loading --animation --line --onefifth mr-2" />
                    <div className="loading --animation --line --half mt-0" />
                </div>
                <div className="d-flex my-3">
                    <div className="loading --animation --line --onefifth mr-2" />
                    <div className="loading --animation --line --fourth mt-0" />
                </div>
                <div className="d-flex my-3">
                    <div className="loading --animation --line --onetenth mr-2" />
                    <div className="loading --animation --line --half mt-0" />
                </div>
                <div className="loading --animation --line --full mt-8" />
                <div className="loading --animation --line --full mt-2" />
                <div className="loading --animation --line --full mt-2" />
                <div className="loading --animation --line --half mt-2" />
                <div className="my-4">
                    <div className="d-flex">
                        <div className="checkbox" />
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="d-flex mt-1">
                        <div className="checkbox" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="d-flex mt-1">
                        <div className="checkbox" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="loading --animation --line --full mt-2" />
                <div className="loading --animation --line --full mt-2" />
                <div className="loading --animation --line --half mt-2" />
            </div>
        </div>
    );
});

export default Loading;
