export const DecoratorLinkEntity = (props) => {
    const { contentState, entityKey } = props;
    if (!entityKey) return null;
    const { url, isBlank } = contentState.getEntity(entityKey).getData();
    return (
        <a href={url || props.decoratedText} target={isBlank ? '_blank' : '_self'} rel="noreferrer">
            {props.children}
        </a>
    );
};

export const DecoratorLink = (props) => {
    return <a href={props.decoratedText}>{props.children}</a>;
};
