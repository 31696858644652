import { COMMON, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { LEADS_LIST_FILTER, LEADS_TABS } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getListReportBookingJobs, getListReportLeads } from 'common/redux/actions/reports/leadsAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import { URL_EXPORT_LEAD, URL_EXPORT_LEAD_ONLINE_BOOKING } from 'app/const/api/Export';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import MainHeaderReport from 'app/modules/report/components/MainHeader';
import { REPORT_LEADS_BOOKING, REPORT_LEADS_INBOUND } from 'app/config/routes';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { HEIGHT_TO_SCROLL } from 'app/const/Reports';
import { REPORT_LIMIT } from 'app/const/Reports';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../report/components/HeaderBottom';
import { addBranchPath } from 'app/const/Branch';

const DropdownAssignBooking = loadable(() => import('app/modules/lead/components/DropdownAssignBooking'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));
const InboundLeadTabGirdView = loadable(() => import('./components/InboundLeadTab'));
const OnlineBookingsTabGirdView = loadable(() => import('./components/OnlineBookingsTab'));
const ListStatusBar = loadable(() => import('app/components/status/ListStatusBar'));
const ChartLeads = loadable(() => import('./components/ChartLeads'));
const AlertCustomer = loadable(() => import('../customer/components/AlertCustomer'));

const INBOUND_LEAD_TAB = REPORT_TYPE.LEADS.inbound_leads;
const ONLINE_BOOKINGS_TAB = REPORT_TYPE.LEADS.online_bookings;

function Lead() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        [INBOUND_LEAD_TAB]: {
            data: []
        },
        [ONLINE_BOOKINGS_TAB]: {
            data: [],
            isLoading: true,
            isLoadingMore: false,
            total: 0
        },
        reload: false,
        isLoading: false,
        currentTab: getLocalStorage(COMMON.CURRENT_TAB) || location?.state?.newTab || INBOUND_LEAD_TAB,
        refreshScreen: 0,
        total: 0
    });

    const {
        isLoadingMore,
        total: finalTotal,
        data: finalData,
        isLoading: finalIsLoading
    } = dataReport[ONLINE_BOOKINGS_TAB];

    const reloadScreen = location?.state?.reload;
    const { currentTab, refreshScreen } = dataReport;
    const keyLocalStorageInbound = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.LEADS[INBOUND_LEAD_TAB]);
    const keyLocalStorageOnlineBooking = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.LEADS[ONLINE_BOOKINGS_TAB]);

    const paramsInbound = getLocalParamsReport(keyLocalStorageInbound, REPORT_TYPE.LEADS[INBOUND_LEAD_TAB]);

    const paramsOnlineBooking = getLocalParamsReport(
        keyLocalStorageOnlineBooking,
        REPORT_TYPE.LEADS[ONLINE_BOOKINGS_TAB]
    );

    const keyTabLocalStorage = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.LEADS[currentTab]);

    const parentChartRef = useRef();
    const childRef = useRef();
    const offsetHeightChild = childRef.current?.offsetHeight;
    const refAlert = useRef(null);
    const onlineBookingRef = useRef();

    useEffect(() => {
        if (reloadScreen) {
            dispatchActionReport({
                currentTab: location?.state?.newTab
            });
        }
    }, [reloadScreen]);

    useEffect(() => {
        return () => {
            removeLocalStorage(COMMON.CURRENT_TAB);
            removeLocalStorage(keyLocalStorageInbound);
            removeLocalStorage(keyLocalStorageOnlineBooking);
        };
    }, []);

    useEffect(() => {
        getListReport(true, getLocalParamsReport(keyTabLocalStorage, REPORT_TYPE.LEADS[currentTab]));
        setLocalStorage(COMMON.CURRENT_TAB, currentTab);
    }, [currentTab]);

    useEffect(() => {
        const parentHeight = parentChartRef.current.clientHeight || 0;
        const contentHeight = onlineBookingRef.current?.clientHeight || 0;
        if (contentHeight <= parentHeight && !finalIsLoading) {
            _handleScroll();
        }
    }, [finalData]);

    useEffect(() => {
        if (isLoadingMore) {
            setTimeout(() => {
                getListReport(false, { ...paramsOnlineBooking, offset: finalData.length }, true);
            }, 200);
        }
    }, [isLoadingMore]);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        delete params?.columns;

        if (currentTab === INBOUND_LEAD_TAB) {
            dispatchActionReport((prevState) => {
                const prevDataOnline = { ...prevState[INBOUND_LEAD_TAB] };
                return {
                    ...prevState,
                    [INBOUND_LEAD_TAB]: {
                        ...prevDataOnline,
                        data: []
                    },
                    isLoading: true
                };
            });
            params.source = params.source_inbound;
            delete params.source_inbound;
            dispatch(getListReportLeads(params, getListSuccess, getListFail));
            return;
        }

        if (currentTab === ONLINE_BOOKINGS_TAB) {
            const checkShouldLoading = !notShowLoading && !finalIsLoading;

            checkShouldLoading &&
                dispatchActionReport((prev) => {
                    const prevDataOnline = { ...prev[ONLINE_BOOKINGS_TAB] };
                    return {
                        ...prev,
                        [ONLINE_BOOKINGS_TAB]: {
                            ...prevDataOnline,
                            data: isReset ? [] : prevDataOnline.data,
                            isLoading: !!checkShouldLoading || prevDataOnline.isLoading
                        }
                    };
                });
            params.source = params.source_online_booking;
            params.limit = REPORT_LIMIT;
            delete params.source_online_booking;

            dispatch(getListReportBookingJobs(params, getListSuccess, getListFail));
        }
    };

    const getListSuccess = (response) => {
        const newData = [...response.data];

        if (Array.isArray(newData)) {
            newData.forEach((item) => {
                item.component = renderCustomItem();
            });
        }

        dispatchActionReport((prev) => {
            const dataReducer = {
                ...prev,
                [currentTab]: {
                    data: [...newData]
                },
                isLoading: false,
                total: response.data.length,
                refreshScreen: refreshScreen + 1
            };
            if (currentTab === ONLINE_BOOKINGS_TAB) {
                dataReducer.total = response.total;
                dataReducer[ONLINE_BOOKINGS_TAB] = {
                    data: [...prev[ONLINE_BOOKINGS_TAB].data, ...newData],
                    isLoadingMore: false,
                    isLoading: false,
                    total: response.total
                };
            }

            return dataReducer;
        });
    };

    const getListFail = () => {
        dispatchActionReport({ isLoading: false });
    };

    const handleChangeTab = (tab) => {
        history.push({
            pathname: addBranchPath(tab === INBOUND_LEAD_TAB ? REPORT_LEADS_INBOUND : REPORT_LEADS_BOOKING),
            state: { reload: Date.now(), newTab: tab }
        });
    };

    const _handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') return dispatchActionReport({ reload: !dataReport.reload });
    };

    const _handleUpdate = () => {
        const params = getLocalStorage(keyTabLocalStorage);
        getListReport(true, params);
    };

    const _handleScroll = () => {
        const numberData = finalData?.length || 0;
        if (!!numberData && !isLoadingMore && finalTotal > numberData) {
            dispatchActionReport((prev) => ({
                ...prev,
                [ONLINE_BOOKINGS_TAB]: { ...prev[ONLINE_BOOKINGS_TAB], isLoadingMore: true }
            }));
        }
    };

    const onScroll = (e) => {
        isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && !isLoadingMore && _handleScroll();
    };

    function _getDataExport() {
        const params = { ...getLocalStorage(keyTabLocalStorage) };
        switch (currentTab) {
            case INBOUND_LEAD_TAB:
                return {
                    urlExport: URL_EXPORT_LEAD,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_LEAD,
                    paramsExport: params
                };
            case ONLINE_BOOKINGS_TAB:
                return {
                    urlExport: URL_EXPORT_LEAD_ONLINE_BOOKING,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_LEAD_ONLINE_BOOKING,
                    paramsExport: params
                };
            default:
                return {};
        }
    }
    const dataExport = _getDataExport();

    const renderCustomItem = () => {
        return <DropdownAssignBooking />;
    };

    const _handleAssignSuccess = ({ message }) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.SUCCESS,
            message: message || t('report:assign_success'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleAssignFailed = ({ message }) => {
        refAlert.current.showStatusBar({
            id: LIST_STATUS.ERROR,
            message: message || t('report:assign_failed'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleUpdateDataOnlineBooking = ({ rowIndex, data, isJobStatus = false }) => {
        dispatchActionReport((prev) => {
            const newDataGridView = prev[ONLINE_BOOKINGS_TAB].data.map((item) => {
                if (item.id === rowIndex) {
                    if (isJobStatus) return { ...item, status: data };
                    return {
                        ...item,
                        assignable: 0,
                        customer: { ...(item.customer || {}), ...(data.customer || {}) },
                        phone: data.phone || '',
                        address: data.address || '',
                        email: data.email || ''
                    };
                }
                return item;
            });

            return {
                ...prev,
                [ONLINE_BOOKINGS_TAB]: { ...prev[ONLINE_BOOKINGS_TAB], data: newDataGridView }
            };
        });
    };

    const _renderGirdView = () => {
        switch (currentTab) {
            case INBOUND_LEAD_TAB:
                return (
                    <InboundLeadTabGirdView
                        dataReport={dataReport[INBOUND_LEAD_TAB].data}
                        isLoading={dataReport.isLoading}
                        offsetHeightChild={offsetHeightChild}
                        parentChartRef={parentChartRef}
                        paramsReport={paramsInbound}
                        tab={INBOUND_LEAD_TAB}
                        handleUpdate={_handleUpdate}
                        onHandleAssignSuccess={_handleAssignSuccess}
                        onHandleAssignFailed={_handleAssignFailed}
                    />
                );
            case ONLINE_BOOKINGS_TAB:
                return (
                    <OnlineBookingsTabGirdView
                        dataReport={finalData}
                        isLoading={finalIsLoading}
                        paramsReport={paramsOnlineBooking}
                        tab={ONLINE_BOOKINGS_TAB}
                        handleUpdate={_handleUpdate}
                        onHandleAssignSuccess={_handleAssignSuccess}
                        onHandleAssignFailed={_handleAssignFailed}
                        handleScroll={_handleScroll}
                        isLoadingMore={isLoadingMore}
                        updateDataReport={_handleUpdateDataOnlineBooking}
                    />
                );
            default:
                return;
        }
    };

    function _renderHeaderLeft() {
        return <ReportTabs list={LEADS_TABS} onChange={handleChangeTab} tabActive={currentTab} />;
    }

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: dataReport.total })}
                url={dataExport.urlExport}
                params={dataExport.paramsExport}
                pageExport={dataExport.pageExport}
                refresh={refreshScreen}
                isDisable={dataReport.isLoading || dataReport[currentTab].isLoading}
                activePrint
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.LEADS}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns has-chart pr-0">
                <div
                    ref={parentChartRef}
                    className={classNames('container-print contents-pages gap-8')}
                    onWheel={onScroll}
                >
                    <ListStatusBar />
                    <AlertCustomer ref={refAlert} />

                    <div className="wrap-border" ref={childRef}>
                        <ChartLeads currentTab={currentTab} reportType={REPORT_TYPE.LEADS[currentTab]} />
                    </div>
                    <div ref={onlineBookingRef} className="wrap-tables flex-column relative tab-contents scroll-x">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={REPORT_TYPE.LEADS[currentTab]}
                            filters={LEADS_LIST_FILTER[currentTab]}
                            handleChangeFilter={_handleChangeFilter}
                            handleUpdate={_handleUpdate}
                            currentTab={currentTab}
                            forceRerender={currentTab}
                            isLoading={dataReport.isLoading || dataReport[currentTab].isLoading}
                        />
                        {_renderGirdView()}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lead;
