import { useRef, useReducer, createContext, useEffect } from 'react';
import { reducer } from 'app/const/Reducer';
import { Device } from '@twilio/voice-sdk';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_TRANSFER_CALL, GET_LIST_VOICEMAIL_DROP, GET_VOIP_ACCESS_TOKEN } from 'app/const/api/Voip';
import {
    EVENT_MESSAGE_RECEIVED,
    KEY_PHONE_SETTINGS,
    MESSAGE_ERROR_CODE_VOIP,
    TYPE_CALL,
    TYPE_DEVICE_AUDIO,
    TYPE_IN_COMMING,
    TYPE_OUT_GOING,
    TYPE_SOUND,
    VOIP_CONFIRM
} from 'app/const/Voip';
import GdConfirm from 'app/components/confirm';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import SocketVOIP from 'app/services/socket/SocketVOIP';
import { useSelector } from 'react-redux';
import { formatPhoneNumberVoip, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import { useTranslation } from 'react-i18next';
import { getClientIdVoip } from 'common/utils/FunctionUtils';
import ServiceParticipant from './ServiceParticipant';
import ServiceMultiContact from './ServiceMultiContact';
import { CHANNEL_BROADCAST } from 'app/const/Broadcast';

export const VOIPContext = createContext();

const VOIPProvider = ({ children }) => {
    const { t } = useTranslation('addons');
    const deviceData = useRef(null);
    const refConfirm = useRef(null);
    const refSocketVOIP = useRef(null);
    const refEventDisconnect = useRef({});
    const refParticipant = useRef(null);
    const refMultiContact = useRef(null);
    const refFetchedVMDrop = useRef(false);

    const { INPUT: typeInput, OUTPUT: typeOutput, RINGING: typeRinging } = TYPE_DEVICE_AUDIO;

    const addonsVoip = useSelector(({ auth }) => auth?.user?.settings?.addons?.voip);
    const isEnableAddons = !!addonsVoip;

    const [state, dispatchState] = useReducer(reducer, {
        calls: [],
        acceptingCall: null,
        isFetched: false,
        voicemailDrop: [],
        ignore: 0
    });

    const { calls, acceptingCall, client_id, token, features, isFetched, voicemailDrop } = state;
    const channel = new BroadcastChannel(CHANNEL_BROADCAST.IGNORE_CALL);

    useEffect(() => {
        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);

        channel.onmessage = (event) => {
            _handleIgnoreCall(event.data.key);
        };

        return () => {
            window.addEventListener('online', handleNetworkChange);
            window.addEventListener('offline', handleNetworkChange);
            channel && channel.close();
        };
    }, []);

    function _handleIgnoreCall(callId) {
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].filter((item) => {
                    if (item.id === callId) {
                        item.connection?.ignore();
                        return false;
                    } else {
                        return item;
                    }
                })
            };
        });
    }

    function handleNetworkChange() {
        if (navigator.onLine && isEnableAddons) {
            _setupDevice();
        }
    }

    useEffect(() => {
        if (isEnableAddons) fetchSettingVoip();
        else _handleDestroy();
    }, [isEnableAddons]);

    useEffect(() => {
        if (deviceData.current && calls.length === 0) {
            _handleDeviceAudio(typeInput, null);
        }
    }, [calls]);

    useEffect(() => {
        _setupDevice();
    }, [token]);

    useEffect(() => {
        if (!!addonsVoip?.voicemail_drop && !refFetchedVMDrop.current && !voicemailDrop.length) {
            refFetchedVMDrop.current = true;
            _getVoicemailDrop();
        }
    }, [addonsVoip?.voicemail_drop]);

    const _updateToken = (newToken) => {
        deviceData.current.updateToken(newToken);
    };

    const _handleAddNewCall = async ({
        fromNumber,
        toPhoneNumber: callTo,
        isCallBack,
        type = TYPE_CALL.OUT,
        customer = {},
        smartViewId = '',
        callIndex = 0,
        customerId = null,
        contactId = null
    }) => {
        try {
            const isCallSmart = type === TYPE_CALL.SMART;
            const toPhoneNumber = callTo?.toString()?.trim() || '';

            if (!checkAssignNumber()) return false;

            if (acceptingCall || deviceData.current.isBusy) {
                !isCallSmart &&
                    refConfirm.current.open(
                        {
                            type: VOIP_CONFIRM.SWITCH_CALL,
                            data: {
                                fromNumber,
                                toPhoneNumber,
                                isCallBack,
                                type,
                                customer
                            }
                        },
                        t('warning_make_new_call'),
                        t('confirm_call_switch')
                    );

                return false;
            }

            if (!toPhoneNumber) {
                !isCallSmart &&
                    refConfirm.current.open('', t('phone_number_cannot_be_blank'), t('invalid_phone_number'));
                return false;
            }

            if (!validatePhoneNumberVoip(toPhoneNumber)) {
                !isCallSmart &&
                    refConfirm.current.open('', t('this_phone_number_is_invalid'), t('invalid_phone_number'));
                return false;
            }

            const objectPhone = fromNumber
                ? { ClientNumber: fromNumber }
                : { CustomerId: customerId || '', ContactId: contactId || '' };

            await _handleSetMic();

            const parameters = {
                To: toPhoneNumber,
                statusCallbackEvent: ['initiated', 'ringing', 'answered', 'completed', 'failed', 'out-of-service'],
                ...objectPhone
            };

            if (isCallSmart) {
                parameters.SmartViewId = smartViewId;
                parameters.CallIndex = callIndex;
            }

            const connection = await deviceData.current.connect({
                params: parameters
            });

            if (isCallBack) {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: prev.calls.filter((item) => item.status !== TYPE_OUT_GOING.END)
                    };
                });
            }

            connection.on('ringing', () => {
                const acceptingCall = {
                    type,
                    status: TYPE_OUT_GOING.ACCEPTING,
                    to: formatPhoneNumberVoip(toPhoneNumber.replace(/\D+/g, '')),
                    id: connection.parameters.CallSid,
                    connection,
                    company_name: '',
                    customer_name: '',
                    type_out: type
                };

                dispatchState((prev) => {
                    if (isCallSmart) {
                        delete acceptingCall.status;
                        return {
                            ...prev,
                            calls: prev.calls.map((item) => {
                                if (item.type === TYPE_CALL.SMART) {
                                    return {
                                        ...item,
                                        ...acceptingCall,
                                        isPaused: false
                                    };
                                }
                                return item;
                            })
                        };
                    }

                    return {
                        ...prev,
                        acceptingCall
                    };
                });
            });

            connection.on('messageReceived', ({ content }) => {
                if (content?.event === EVENT_MESSAGE_RECEIVED.EVENT_OUT_OF_SERVICE) {
                    refConfirm.current.open('', t('warning_run_out_of_balance'), t('warning'));
                    isCallSmart && _handleDisconnect(null, {}, true);
                    return;
                }

                if (content.event.includes(EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT)) {
                    _handleJoinParticipant({ callSid: connection.parameters.CallSid, content });
                    return;
                }

                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: prev.calls.map((item) => {
                            if (item.id === connection.parameters.CallSid) {
                                return {
                                    ...item,
                                    status: TYPE_OUT_GOING.CALLING,
                                    transfer_call_id: content.data.CallSid,
                                    startTime: Date.now()
                                };
                            } else {
                                item.connection.mute(true);
                                return {
                                    ...item,
                                    status: TYPE_IN_COMMING.BUSY
                                };
                            }
                        })
                    };
                });
            });

            connection.on('accept', () => {
                const callId = connection.parameters.CallSid;

                if (isCallSmart) {
                    dispatchState((prev) => {
                        return {
                            ...prev,
                            calls: prev.calls.map((item) => {
                                if (item.type === TYPE_CALL.SMART) {
                                    return {
                                        ...item,
                                        status: TYPE_OUT_GOING.ACCEPTING,
                                        id: callId,
                                        call_id: callId
                                    };
                                }
                                return item;
                            })
                        };
                    });
                    return;
                }

                if (!isCallSmart) {
                    refMultiContact.current.getContacts({
                        phone: toPhoneNumber,
                        callId,
                        customerId,
                        contactId
                    });
                }

                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: [
                            {
                                type: TYPE_CALL.OUT,
                                status: TYPE_OUT_GOING.ACTIVE,
                                to: formatPhoneNumberVoip(toPhoneNumber.replace(/\D+/g, '')),
                                id: callId,
                                connection,
                                isSyncCustomer: true,
                                call_id: callId,
                                ...customer
                            },
                            ...prev.calls
                        ],
                        acceptingCall: null
                    };
                });
            });

            connection.on('error', ({ message, code }) => {
                refConfirm.current.open('', t(MESSAGE_ERROR_CODE_VOIP[code]) || message, t('warning'));
            });

            connection.on('disconnect', () => {
                const callId = connection.parameters.CallSid;
                refEventDisconnect.current[callId] = setTimeout(() => {
                    _handleRemoveCall(callId);
                    _handleClearDisconnect(callId);
                }, 5000);
            });

            return true;
        } catch {
            return false;
        }
    };

    const _handleClearDisconnect = (callId) => {
        clearTimeout(refEventDisconnect.current[callId]);
    };

    const _disconnectIncommingCall = (connection) => {
        _handleRemoveCall(connection.parameters.CallSid);
    };

    const _addDeviceListeners = () => {
        deviceData.current.on('incoming', (connection) => {
            const inviteCallId = connection.customParameters.get('InviteCallId');
            dispatchState((prev) => {
                const prevData = [...prev.calls];
                const isHaveCall = prevData.some((item) => item.status === TYPE_IN_COMMING.CALLING);
                const parameters = connection.parameters;
                const customerData = Object.fromEntries(new URLSearchParams(parameters.Params));
                if (prevData.some((item) => item.id === parameters.CallSid)) return prev;
                const newData = {
                    type: TYPE_CALL.IN,
                    status: isHaveCall ? TYPE_IN_COMMING.BUSY : TYPE_IN_COMMING.RINGING,
                    from: parameters.From,
                    id: parameters.CallSid,
                    connection,
                    company_name: customerData?.CompanyName || '',
                    customer_name: customerData?.CustomerName || '',
                    phone_type: customerData?.PhoneType || '',
                    customer_id: customerData?.Id || '',
                    customer_title: customerData?.Title || '',
                    transfer_call_id: customerData?.TransferCallId,
                    contact_id: customerData?.ContactId || null
                };
                if (!!inviteCallId) {
                    newData.inviteData = {
                        invite_call_id: inviteCallId,
                        from: getClientIdVoip(parameters.From),
                        members: []
                    };
                }

                return {
                    ...prev,
                    calls: [...prevData, newData]
                };
            });

            // setTimeout(() => {
            //     dispatch(
            //         actionNewCall({
            //             ...callData,
            //             call_type: TYPE_CALL_TIME_LINE.INCOMMING,
            //             call_id: callData.id,
            //             call_status: TYPE_OUT_GOING.ACTIVE,
            //             id: ''
            //         })
            //     );
            // }, 0);

            connection.on('disconnect', () => {
                _disconnectIncommingCall(connection);
            });

            connection.on('cancel', () => {
                _disconnectIncommingCall(connection);
            });

            connection.on('messageReceived', ({ content }) => {
                if (content.event.includes(EVENT_MESSAGE_RECEIVED.EVENT_PARTICIPANT)) {
                    _handleJoinParticipant({ callSid: connection.parameters.CallSid, content });
                    return;
                }
            });
        });

        deviceData.current.on('tokenWillExpire', () => {
            const _handleSuccess = (res) => {
                _updateToken(res.data.token);
            };
            clientQuery(GET_VOIP_ACCESS_TOKEN, {}, _handleSuccess);
        });
    };

    const _handleAcceptCall = async (callId) => {
        await _handleSetMic();

        let callAccept = null;
        dispatchState((prev) => {
            return {
                ...prev,
                acceptingCall: null,
                calls: [...prev.calls]
                    .filter((item) => {
                        const isCallSmart = item.type === TYPE_CALL.SMART;

                        if (item.type !== TYPE_CALL.OUT) {
                            isCallSmart && item.connection?.disconnect();
                            return item;
                        } else {
                            item.connection.disconnect();
                        }
                    })
                    .map((item) => {
                        if (item.id === callId) {
                            callAccept = item;
                            item.connection.accept();
                            return {
                                ...item,
                                status: TYPE_IN_COMMING.CALLING,
                                startTime: Date.now()
                            };
                        } else {
                            item.connection.mute(true);

                            return {
                                ...item,
                                status: TYPE_IN_COMMING.BUSY,
                                isPaused: true
                            };
                        }
                    })
            };
        });

        setTimeout(() => {
            if (!callAccept) return;
            const {
                inviteData,
                transfer_call_id,
                connection,
                from,
                contact_id: contactId,
                customer_id: customerId
            } = callAccept;
            if (inviteData || (transfer_call_id && connection?.customParameters?.get('UserId'))) return;
            refMultiContact.current?.getContacts({ phone: from, callId, contactId, customerId });
        }, 0);
    };

    const _handleSwitchCall = async (callId) => {
        await _handleSetMic();

        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].map((item) => {
                    if (item.id === callId) {
                        item.connection.accept();
                        return {
                            ...item,
                            status: TYPE_IN_COMMING.CALLING
                        };
                    } else {
                        if (item.status === TYPE_IN_COMMING.CALLING) {
                            item.connection.disconnect();
                        } else {
                            item.connection.mute(true);
                        }
                        return {
                            ...item,
                            status: TYPE_IN_COMMING.BUSY,
                            isPaused: true
                        };
                    }
                })
            };
        });
    };

    const _handleHangUp = (callId, options = {}) => {
        const { isSmartDialer = false, status = TYPE_IN_COMMING.END, excludeDisconnect = false } = options || {};
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].map((item) => {
                    if (item.id === callId || (isSmartDialer && item.type === TYPE_CALL.SMART)) {
                        if (!excludeDisconnect) item.connection?.disconnect();
                        return { ...item, status };
                    }
                    return item;
                })
            };
        });
    };

    const _handleMuteCall = (callId, value) => {
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].map((item) => {
                    if (item.id === callId) {
                        item.connection.mute(value);
                        return {
                            ...item,
                            is_mute: value
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _handleDisconnect = (callId, options = {}, isSmartDialer = false) => {
        const { isReject = false, isIgnore = false } = options || {};
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].filter((item) => {
                    if (item.id === callId || (isSmartDialer && item.type === TYPE_CALL.SMART)) {
                        if (isReject) {
                            item.connection?.reject();
                        } else if (isIgnore) {
                            channel && channel.postMessage({ key: callId });
                            item.connection?.ignore();
                            return false;
                        }
                        item.connection?.disconnect();
                        return false;
                    } else {
                        return item;
                    }
                })
            };
        });
    };

    const _handleRemoveCall = (callId) => {
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].map((item) => {
                    if (item.id === callId) {
                        return {
                            ...item,
                            status: TYPE_IN_COMMING.END
                        };
                    }
                    return item;
                }),
                acceptingCall: null
            };
        });
    };

    const _handleDeleteCall = (callId) => {
        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].filter((item) => {
                    if (item.id !== callId) {
                        return item;
                    }
                })
            };
        });
    };

    const _handleTransfer = (callData, transferData) => {
        let callTransfer = {};

        dispatchState((prev) => {
            return {
                ...prev,
                calls: [...prev.calls].map((item) => {
                    if (item.id === callData.id) {
                        callTransfer = item;

                        item.connection.ignore();
                        return {
                            ...item,
                            status: TYPE_IN_COMMING.TRANSFERRING,
                            transferData
                        };
                    }
                    return item;
                })
            };
        });

        setTimeout(() => {
            const isTranferToUser = !!transferData.full_name;
            const objectParams = isTranferToUser ? { user_id: transferData.id } : { number_id: transferData.id };

            const _handleSuccess = () => {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: [...prev.calls].map((item) => {
                            if (item.id === callData.id) return { ...item, status: TYPE_IN_COMMING.TRANSFER };
                            return item;
                        })
                    };
                });
            };

            const _handleFail = () => {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: [...prev.calls].map((item) => {
                            if (item.id === callData.id) return { ...item, status: callTransfer.status };
                            return item;
                        })
                    };
                });
            };

            clientQuery(
                ACTION_TRANSFER_CALL,
                {
                    method: 'POST',
                    data: {
                        call_id: callData.transfer_call_id,
                        ...objectParams,
                        company_name: callTransfer.company_name || '',
                        customer_id: callTransfer.customer_id || '',
                        customer_name: callTransfer.customer_name || '',
                        phone_type: callTransfer.phone_type || '',
                        customer_title: callTransfer.customer_title || ''
                    }
                },
                _handleSuccess,
                _handleFail
            );
        }, 0);
    };

    function _handleCallDial(callId, number) {
        calls.forEach((item) => {
            if (item.id === callId) {
                item.connection.sendDigits(number.toString());
            }
        });
    }

    const _handleDeviceAudio = async (type, item) => {
        const { isBusy, audio: deviceAudio } = deviceData.current || {};

        if (isBusy || calls.length || !deviceAudio) {
            return false;
        }
        switch (type) {
            case typeInput:
                if (item) await deviceAudio.setInputDevice(item.deviceId);
                else await deviceAudio.unsetInputDevice();
                break;
            case typeOutput:
                await deviceAudio.speakerDevices.set(item.deviceId);
                break;
            case typeRinging:
                await deviceAudio.ringtoneDevices.set(item.deviceId);
                break;
        }
        return true;
    };

    const _handleSetMic = async () => {
        const deviceAudio = deviceData.current?.audio;
        if (!deviceAudio) return;

        const { inputDevice, availableInputDevices } = deviceAudio;
        const deviceSelected = getLocalStorage(KEY_PHONE_SETTINGS)?.deviceSelected;
        const idSelected = deviceSelected ? deviceSelected[typeInput]['id'] : null;
        if (!idSelected) return;

        let checkInput = null;
        availableInputDevices.forEach((item) => {
            if (item.deviceId === idSelected) checkInput = true;
        });
        if (!inputDevice && checkInput) {
            await deviceAudio.setInputDevice(idSelected);
        }
    };

    const _handleSetVolume = (callback) => {
        const deviceAudio = deviceData.current?.audio;
        if (!deviceAudio || !deviceAudio.isVolumeSupported) return;

        deviceAudio.removeAllListeners();
        deviceAudio.on('inputVolume', (volume) => {
            typeof callback === 'function' && callback(volume);
        });
    };

    const _handleTestSound = (type) => {
        const deviceAudio = deviceData.current?.audio;
        if (!deviceAudio || !deviceAudio.isOutputSelectionSupported) return;

        switch (type) {
            case TYPE_SOUND.SPEAKER:
                deviceAudio.speakerDevices.test('https://sdk.twilio.com/js/client/sounds/releases/1.0.0/outgoing.mp3');
                break;
            case TYPE_SOUND.RINGING:
                deviceAudio.ringtoneDevices.test('https://sdk.twilio.com/js/client/sounds/releases/1.0.0/incoming.mp3');
                break;
            default:
                break;
        }
    };

    const _handleResumeAudioContext = () => {
        deviceData.current?.audio?._audioContext?.resume();
    };

    const _handleDestroy = async () => {
        if (!deviceData.current) return;
        calls.forEach((item) => {
            const { connection, type } = item;
            if (type === TYPE_CALL.IN) connection.ignore();
            connection.disconnect();
        });
        await deviceData.current.audio?.unsetInputDevice();
        dispatchState({ calls: [] });
        deviceData.current.destroy();
    };

    const _setupDevice = async () => {
        if (!token) return;

        deviceData.current = await new Device(token, {
            allowIncomingWhileBusy: true,
            codecPreferences: ['opus', 'pcmu'],
            tokenRefreshMs: 10 * 1000,
            rtcConstraints: {
                audio: true
            }
        });

        refSocketVOIP.current.joinRoom(client_id);
        deviceData.current.register();

        _addDeviceListeners();
    };

    const fetchSettingVoip = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                ...data,
                isFetched: Date.now()
            }));
        };
        const _handleFail = () => {
            dispatchState((prev) => ({
                ...prev,
                features: { call_activity_report: false, smart_dialer: false, call_coaching: false },
                isFetched: Date.now()
            }));
        };

        clientQuery(GET_VOIP_ACCESS_TOKEN, { method: 'GET', data: {} }, _handleSuccess, _handleFail);
    };

    const checkAssignNumber = () => {
        // eslint-disable-next-line no-undef
        if (!deviceData.current || !navigator.onLine || !global.isHavePhoneNumber) {
            refConfirm.current.open('', t('your_account_hasnt_been_assigned_a_phone_number'), t('warning'));
            return false;
        }
        return true;
    };

    const checkAudio = () => {
        if (!!deviceData.current && !!deviceData.current.audio.availableInputDevices.get('default')) return true;
        refConfirm.current.open('', t('warning_audio_voip'), t('warning'));
        return false;
    };

    const _handleCreateSmartDialer = (dataDialer) => {
        if (!checkAssignNumber() || !checkAudio()) return;

        if (calls.some((item) => item.type === TYPE_CALL.SMART)) return;

        if (deviceData.current.isBusy) {
            refConfirm.current.open(
                {
                    type: VOIP_CONFIRM.SWITCH_CALL,
                    data: dataDialer,
                    isSmartDialer: true
                },
                t('warning_make_new_call'),
                t('confirm_call_switch')
            );
            return;
        }

        dispatchState((prev) => {
            return {
                ...prev,
                calls: [
                    ...prev.calls,
                    {
                        id: 'ID_SMART_DIALER',
                        status: TYPE_OUT_GOING.PREPARE,
                        type: TYPE_CALL.SMART,
                        smartDialer: {
                            ...dataDialer,
                            isReset: false
                        }
                    }
                ]
            };
        });
    };

    const _handleResetListDialer = () => {
        dispatchState((prev) => {
            return {
                ...prev,
                calls: prev.calls.map((item) => {
                    if (item.type === TYPE_CALL.SMART) {
                        return { ...item, smartDialer: { ...item.smartDialer, isReset: Date.now() } };
                    }

                    return item;
                })
            };
        });
    };

    const _handleConfirm = ({ type, data, isSmartDialer = false }) => {
        switch (type) {
            case VOIP_CONFIRM.SWITCH_CALL:
                dispatchState((prev) => {
                    return {
                        ...prev,
                        calls: [...prev.calls].map((item) => {
                            if (
                                item.status === TYPE_IN_COMMING.CALLING ||
                                [TYPE_CALL.SMART, TYPE_CALL.OUT].includes(item.type)
                            ) {
                                item.connection.disconnect();
                            }
                            return {
                                ...item,
                                status: TYPE_IN_COMMING.BUSY,
                                isPaused: true
                            };
                        })
                    };
                });
                setTimeout(async () => {
                    if (isSmartDialer) {
                        _handleCreateSmartDialer(data);
                        return;
                    }
                    _handleAddNewCall(data);
                }, 0);
        }

        refConfirm.current;
    };

    const _handleSetIndexDialer = async (index = 0) => {
        try {
            await deviceData.current.connect({
                params: {
                    SmartViewId: calls.find((item) => item.type === TYPE_CALL.SMART)?.smartDialer?.id,
                    CallIndex: index
                }
            });
        } catch {
            return false;
        }
    };

    const _handleInvite = (callData, inviteData) => {
        refParticipant.current.invite(callData, inviteData);
    };

    const _handleJoinParticipant = ({ content, callSid }) => {
        refParticipant.current.onEvent({ content, callSid });
    };

    const _handleSelectContact = (data = {}) => {
        refMultiContact.current.selectContact(data);
    };

    const _handleUpdateState = (callback) => {
        if (typeof callback !== 'function') return;
        dispatchState((prev) => {
            return callback(prev);
        });
    };

    const _getVoicemailDrop = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, voicemailDrop: data }));
        };

        clientQuery(GET_LIST_VOICEMAIL_DROP, { method: 'GET', data: {} }, _handleSuccess);
    };

    return (
        <VOIPContext.Provider
            value={{
                calls: calls,
                acceptingCall: acceptingCall,
                voicemailDrop: voicemailDrop,
                onOutGoingCall: _handleAddNewCall,
                onAcceptCall: _handleAcceptCall,
                onDisconnect: _handleDisconnect,
                onHangUp: _handleHangUp,
                onTransfer: _handleTransfer,
                onInvite: _handleInvite,
                onSetDeviceAudio: _handleDeviceAudio,
                onSetVolume: _handleSetVolume,
                onTestSound: _handleTestSound,
                onCallBack: _handleAddNewCall,
                onSwitchCall: _handleSwitchCall,
                onMuteCall: _handleMuteCall,
                onCallDial: _handleCallDial,
                onResumeAudioContext: _handleResumeAudioContext,
                onDelete: _handleDeleteCall,
                onUpdateState: _handleUpdateState,
                features,
                isFetched,
                onCreateSmartDialer: _handleCreateSmartDialer,
                onResetListDialer: _handleResetListDialer,
                onFetchSetting: fetchSettingVoip,
                onSetIndexDialer: _handleSetIndexDialer,
                onSelectContact: _handleSelectContact
            }}
        >
            {children}
            <GdConfirm
                ref={refConfirm}
                title={'Notification'}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_handleConfirm}
            />
            <SocketVOIP ref={refSocketVOIP} dispatchState={dispatchState} onDisconnect={_handleClearDisconnect} />
            <ServiceParticipant ref={refParticipant} dispatchState={dispatchState} calls={calls} />
            <ServiceMultiContact ref={refMultiContact} dispatchState={dispatchState} />
        </VOIPContext.Provider>
    );
};

export default VOIPProvider;
