import { PAYMENT_CARD_CUSTOMER, MERCHANT_ID } from 'app/const/Customers';

export const TYPE_REPORT_SUBSCRIPTION = {
    GET_LIST: 'GET_LIST_REPORT_SUBSCRIPTION',
    SEARCH: 'SEARCH_REPORT_SUBSCRIPTION',
    PLANS: 'GET_LIST_PLANS_SUBSCRIPTION_FILTER'
};

// actions
export const getListReportSubscriptionRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SUBSCRIPTION.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListPlansSubscriptionStripeFilter = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SUBSCRIPTION.PLANS,
        params: { ...params, merchant: MERCHANT_ID[PAYMENT_CARD_CUSTOMER.STRIPE] },
        actionSuccess,
        actionFailed
    };
};

export const getListPlansSubscriptionSquareFilter = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_SUBSCRIPTION.PLANS,
        params: { ...params, merchant: MERCHANT_ID[PAYMENT_CARD_CUSTOMER.SQUARE] },
        actionSuccess,
        actionFailed
    };
};
