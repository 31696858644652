import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';

import { ACTION_REASSIGN_CALL, GET_CUSTOMER_BY_PHONE } from 'app/const/api/Voip';
import { SocketContext } from '../socket/SocketProvider';
import { clientQuery } from 'common/utils/ApiUtils';

const ServiceMultiContact = ({ dispatchState }, ref) => {
    const { timelineCall } = useContext(SocketContext);
    const refCallLog = useRef({});

    useImperativeHandle(ref, () => ({
        getContacts: _handleGetContacts,
        selectContact: _handleSelectContact
    }));

    useEffect(() => {
        if (!timelineCall) return;
        const { call_id, id: callLog, call_status } = timelineCall;
        if (call_status < 0) {
            refCallLog.current[call_id] = callLog;
        } else {
            delete refCallLog.current[call_id];
        }
    }, [timelineCall]);

    const _handleGetContacts = ({ phone = '', callId, contactId = null, customerId = '' }) => {
        const _handleSuccess = ({ data }) => {
            let index = 0;
            if ((!!contactId || !!customerId) && !!data?.length)
                index = data.findIndex(({ id, contact_id }) => id === customerId && contact_id === contactId);

            _handleSelectContact({
                listContact: data,
                contact: data?.[Math.max(0, index)] || {},
                callId,
                isReassign: false
            });
        };

        clientQuery(GET_CUSTOMER_BY_PHONE, { method: 'GET', data: { phone } }, _handleSuccess);
    };

    const _handleSelectContact = ({ listContact, contact = {}, callId, isReassign = true, callIdAssign }) => {
        const { company, id: customerId, first_name, last_name, title, phone_type, contact_id, phone_valid } = contact;

        dispatchState((prev) => ({
            ...prev,
            calls: prev.calls.map((item) => {
                if (item.id !== callId) return item;
                const finalItem = {
                    ...item,
                    company_name: company || '',
                    customer_name: customerId ? first_name.concat(' ', last_name) : '',
                    customer_title: title,
                    customer_id: customerId,
                    contact_id,
                    phone_type: phone_type || '',
                    isSyncCustomer: false,
                    phone_valid: phone_valid || ''
                };
                Array.isArray(listContact) && (finalItem.contacts = listContact);
                return finalItem;
            })
        }));

        if (!isReassign) return;
        clientQuery(ACTION_REASSIGN_CALL, {
            method: 'PUT',
            toFormData: false,
            data: {
                id: refCallLog.current[callIdAssign || callId],
                customer_id: customerId,
                contact_id: contact_id || null
            }
        });
    };
};

export default forwardRef(ServiceMultiContact);
