import { TEXT_MODES } from '../../constants/textOptions';
import { OPERATOR_IDS } from '../../constants/types';

export const getOperationText = (condition, negate = false) => {
    let operatorId = null;
    const value = condition.value;

    switch (condition.mode) {
        case TEXT_MODES.EXACT_VALUE:
            operatorId = negate ? OPERATOR_IDS.NOT_EXACT_VALUE : OPERATOR_IDS.EXACT_VALUE;
            break;
        case TEXT_MODES.BEGINNING_OF_WORDS:
            operatorId = negate
                ? OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH
                : OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH;
            break;
        default:
            break;
    }
    return { value, operatorId };
};
