import React from 'react';
import { OPERATOR_TYPES } from '../../constants/types';
import IconClose from 'assets/icon/IconClose';

const ItemFilteredText = ({ field, filter, onUpdate = () => {}, onRemove = () => {} }) => {
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">{field.label}:</span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{prefixToken}</span> : null}
                <span className="fw-600 ml-1">{filter.value}</span>
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredText;
