import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';

const AddressDropdown = ({ id, isSame = false }, ref) => {
    const isBillingDropdown = id === 'add_customer_billing_to';
    const { profile, additional_contacts, address_to, updateContext } = useContext(AddCustomerContext);
    const [state, dispatchState] = useReducer(reducer, { options: [], selected: '' });
    const { selected, options } = state;

    const refOptionFromProfile = useRef();
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    useEffect(() => {
        const newState = { ...state };
        const currentOptions = newState.options;

        if (profile.first_name || profile.last_name) {
            const options = [...currentOptions];
            const indexUpdate = options.findIndex((item) => item.isFromProfile);
            const fullName = `${profile.first_name} ${profile.last_name}`;

            if (indexUpdate >= 0) {
                const dataUpdate = { ...options[indexUpdate], full_name: fullName };
                options[indexUpdate] = dataUpdate;
                if (selected.isFromProfile) newState['selected'] = dataUpdate;
                newState['options'] = options;
            } else {
                const newOption = {
                    id: `${new Date().getTime()}`,
                    full_name: fullName,
                    isFromProfile: true
                };

                if (!Object.keys(selected).length) newState['selected'] = newOption;
                newState['options'] = [newOption, ...currentOptions];
            }

            refOptionFromProfile.current = newState['selected'];
        }

        if (!profile.first_name && !profile.last_name) {
            newState['selected'] = '';
            newState['options'] = [];
        }

        dispatchState(newState);
    }, [profile]);

    useEffect(() => {
        const isHadSelect = !!Object.keys(selected).length;
        const isSelectedFromProfile = selected.isFromProfile;
        const newState = { ...state };

        // In case remove all additional contact
        if (!additional_contacts.length) {
            if (isHadSelect) {
                if (!isSelectedFromProfile) newState['selected'] = options.find((item) => item.isFromProfile) || {};
                newState['options'] = options.filter((item) => item.isFromProfile);
            }

            dispatchState(newState);
            return;
        }

        if (isHadSelect) {
            const newOptions = [];

            additional_contacts.forEach((item) => {
                const fullName = `${item.first_name} ${item.last_name}`;
                const itemUpdate = { ...item, full_name: fullName };

                /* Checking if the selected item is the same as the item in the additional_contacts
                array. If it is, it will update the selected item. */
                if (item.id === selected.id && !isSelectedFromProfile) newState['selected'] = itemUpdate;

                newOptions.push({ ...item, full_name: fullName });
            });

            refOptionFromProfile.current && newOptions.unshift(refOptionFromProfile.current);

            const removeSelected = !Boolean(newOptions.find((item) => item.id === selected.id));
            if (removeSelected) newState['selected'] = {};
            newState['options'] = newOptions;
        } else {
            const firstItem = additional_contacts[0];
            if (!firstItem) return;
            const fullName = `${firstItem.first_name} ${firstItem.last_name}`;
            newState['selected'] = { ...firstItem, full_name: fullName };
            newState['options'] = additional_contacts.map((item) => ({ ...item, full_name: fullName }));
        }

        dispatchState(newState);
    }, [additional_contacts]);

    useEffect(() => {
        if (isBillingDropdown && address_to && isSame) {
            dispatchState({ selected: address_to });
        }
        if (!isBillingDropdown && address_to) {
            dispatchState({ selected: address_to });
        }
    }, [address_to]);

    useEffect(() => {
        _handleSetSelectedContext(selected);
    }, [selected]);

    const _handleGetValue = () => selected;

    const _handleSelect = (id) => {
        const addressService = options.find((item) => item.id === id);
        dispatchState({ selected: addressService });
    };

    const _handleSetSelectedContext = (selected) => {
        updateContext(
            selected,
            isBillingDropdown
                ? ADD_CUSTOMER_CONTEXT_TYPES.SELECT_BILLING_SERVICE
                : ADD_CUSTOMER_CONTEXT_TYPES.SELECT_ADDRESS_SERVICE
        );
    };

    return (
        <CalendarDropdown
            id={id}
            ref={refDropdown}
            selected={selected.full_name}
            selectedOption={selected}
            options={options}
            keyGetKey="id"
            keyGetValue="id"
            keyGetName="full_name"
            onSelect={_handleSelect}
        />
    );
};

export default forwardRef(AddressDropdown);
