import React, { Fragment } from 'react';

const Loading = () => {
    return (
        <Fragment>
            <div className="wrap-loading boxs boxs--no-border">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="v2-btn-default loading bg-white-cultured --icon-lg mr-1"></div>
                        <div className="loading --animation --line --half"></div>
                    </div>
                    <div className="flexcenter loading --fourth">
                        <div className="v2-btn-default --grey loading btn-sm mr-2">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="switch ml-0 flex-1">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                </div>
                <div className="boxs__contents my-3">
                    <div className="loading --animation --line --full"></div>
                    <div className="loading --animation --line --full mt-1"></div>
                    <div className="loading --animation --line --fourth mt-1"></div>
                </div>
            </div>
            <div className="boxs boxs--api wrap-loading">
                <div className="rows">
                    <div className="col-desc">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --onefifth"></div>
                </div>
                <div className="rows">
                    <div className="col-desc">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --onefifth"></div>
                </div>
                <div className="rows">
                    <div className="col-desc">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --onefifth"></div>
                </div>
                <div className="rows">
                    <div className="col-desc">
                        <div className="loading --animation --line --threefourth"></div>
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg"></div>
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --onefifth"></div>
                </div>
            </div>
            <div className="boxs boxs--no-border mt-4">
                <div className="loading --animation --line --onefourth"></div>
                <div className="loading --animation --line --threefourth mt-2"></div>
            </div>
        </Fragment>
    );
};

export default Loading;
