import React, { useReducer, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { reducer } from 'app/const/Reducer';
import ButtonSave from 'app/components/button/ButtonSave';

const Confirm = forwardRef(({ title, handleConfirm }, ref) => {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isLoadingSubmit: false,
        id: 0,
        name: null
    });

    const { isVisible, isLoadingSubmit, id: finalId, name: finalName } = state;

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm,
        _changeLoadingSubmit
    }));

    const _handleOpenForm = (id, name) => {
        dispatchState({
            isVisible: true,
            id: id,
            name: name
        });
    };

    const _handleCloseForm = () => {
        dispatchState({
            isVisible: false,
            isLoadingSubmit: false
        });
    };

    const _changeLoadingSubmit = () => {
        dispatchState({ isLoadingSubmit: !isLoadingSubmit });
    };

    const _handleConfirm = () => {
        handleConfirm(finalId);
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal modal-confirm-delete open">
            <div className="modal__overlay bg-fixed"></div>
            <div className="modal__container x-lg">
                <div className="header-modal">
                    <h2>{title}</h2>
                </div>
                <div className="body-modal">
                    <div className="text">{t('addons:delete_document_description', { name: finalName })}</div>
                </div>

                <div className="footer-modal btn-close">
                    <span onClick={_handleCloseForm} className="v2-btn-default --transparent" tabIndex="0">
                        {t('jobDetail:cancel')}
                    </span>
                    <ButtonSave onSave={_handleConfirm} title={t('jobDetail:confirm')} />
                </div>
            </div>
        </div>
    );
});

export default Confirm;
