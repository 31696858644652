export const TYPE_SETTING_TAGS = {
    GET_LIST_SIMPLE: 'SETTING_GET_LIST_SIMPLE_TAGS',
    GET_LIST_SUCCESS: 'SETTING_GET_LIST_TAGS_SUCCESS',
    GET_LIST: 'SETTING_GET_LIST_TAGS',
    GET_LIST_CONVERSATION_TAGS: 'GET_LIST_CONVERSATION_TAGS',
    UPDATE_TAG_SIMPLE: 'UPDATE_TAG_SIMPLE',
    CREATE: 'SETTING_CREATE_TAGS',
    UPDATE: 'SETTING_UPDATE_TAGS',
    DELETE: 'SETTING_DELETE_TAGS',
    UPDATE_TAGS_SUCCESS: 'UPDATE_TAGS_SUCCESS',
    DELETE_TAGS_SUCCESS: 'DELETE_TAGS_SUCCESS'
};

// actions
export const getListSimpleTags = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.GET_LIST_SIMPLE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListConversationTags = (params = {}, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.GET_LIST_CONVERSATION_TAGS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateSimpleTag = (payload) => {
    return { type: TYPE_SETTING_TAGS.UPDATE_TAG_SIMPLE, payload };
};

export const getListSettingTags = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const createTag = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.CREATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateTag = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteTags = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_TAGS.DELETE,
        params,
        actionSuccess,
        actionFailed
    };
};
