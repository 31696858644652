export default (props) => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5627 0C14.0365 0 15.8833 0.444418 17.4437 1.27894C19.0041 2.11347 20.2288 3.3381 21.0633 4.89852C21.8978 6.45895 22.3422 8.30582 22.3422 10.7796V11.5629C22.3422 11.9837 22.3293 12.3865 22.3039 12.7728L22.3422 12.733L22.285 13.0289C22.2083 13.9645 22.0552 14.802 21.8297 15.5666C21.6285 17.524 21.9879 19.506 22.8742 21.2785C23.168 21.8661 22.9298 22.5806 22.3422 22.8744C22.0073 23.0419 21.6132 23.0419 21.2783 22.8744C19.5002 21.9853 17.5109 21.6275 15.547 21.8326C14.8017 22.054 13.9855 22.2039 13.0765 22.2812L12.767 22.3425L12.8073 22.3018C12.4105 22.3288 11.9962 22.3425 11.5627 22.3425H10.7795C8.30573 22.3425 6.45888 21.898 4.89847 21.0635C3.33806 20.229 2.11344 19.0044 1.27893 17.4439C0.444413 15.8835 0 14.0366 0 11.5629L0 10.7796C0 8.30582 0.444413 6.45895 1.27893 4.89852C2.11344 3.3381 3.33806 2.11347 4.89847 1.27894C6.45888 0.444418 8.30573 0 10.7795 0L11.5627 0ZM5.85153 9.57534C4.97015 9.57534 4.25566 10.2898 4.25566 11.1712C4.25566 12.0526 4.97015 12.7671 5.85153 12.7671C6.73291 12.7671 7.4474 12.0526 7.4474 11.1712C7.4474 10.2898 6.73291 9.57534 5.85153 9.57534ZM11.1711 9.57534C10.2897 9.57534 9.57523 10.2898 9.57523 11.1712C9.57523 12.0526 10.2897 12.7671 11.1711 12.7671C12.0525 12.7671 12.767 12.0526 12.767 11.1712C12.767 10.2898 12.0525 9.57534 11.1711 9.57534ZM16.4907 9.57534C15.6093 9.57534 14.8948 10.2898 14.8948 11.1712C14.8948 12.0526 15.6093 12.7671 16.4907 12.7671C17.3721 12.7671 18.0865 12.0526 18.0865 11.1712C18.0865 10.2898 17.3721 9.57534 16.4907 9.57534Z"
            fill="white"
        />
    </svg>
);
