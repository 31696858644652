import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { imageType } from 'common/utils/FileUtils';
import IconTrash from 'assets/icon/IconTrash';
import IconError from 'assets/icon/IconError';
import { ColAvatarMultipleBranch } from 'app/components/grid/GridColumn';
import ServiceUploadLogo from './ServiceUploadLogo';
import GdConfirm from 'app/components/confirm';

const FieldUploadAvatar = (
    {
        id = 'choose_a_file',
        urlImage = null,
        nameBranch = 'BA',
        isNotDivider = false,
        color = '',
        titleButton = '',
        typePresign = '',
        avatarDefault = '',
        onUpFile = () => {},
        onDelete = () => {},
        onUpFileSuccess = () => {}
    },
    ref
) => {
    const { t } = useTranslation('addons');
    const refInput = useRef(null);
    const refTimer = useRef(null);
    const refServiceUpload = useRef(null);
    const refConfirmDeleteAvatar = useRef(null);

    const initialState = {
        isLoading: false,
        file: null,
        msgError: '',
        isError: false,
        isUploaded: false,
        avtObject: null,
        nameBranch
    };

    const [state, dispatchState] = useReducer(reducer, { ...initialState, urlImage });

    const {
        file,
        msgError,
        isError,
        urlImage: finalUrl,
        isUploaded: finalIsUploaded,
        nameBranch: finalNameBranch,
        isLoading: finalIsLoading,
        avtObject: finalAvtObject
    } = state;
    const urlSrc = file?.url || finalUrl;

    useImperativeHandle(ref, () => ({
        getValue: _handleGetValue,
        setValue: _handleSetValue
    }));

    useEffect(() => {
        return () => {
            clearTimeout(refTimer.current);
        };
    }, []);

    const _handleGetValue = () => {
        return { file, url: finalUrl, isUploaded: finalIsUploaded, avtObject: finalAvtObject };
    };

    const _handleSetValue = (data) => {
        dispatchState((prev) => ({ ...prev, ...data }));
    };

    const _handleUploadFile = async (e) => {
        onUpFile();
        dispatchState({ isLoading: true });
        const { success, message, data } = await refServiceUpload.current.onUpFile(e);
        if (!success) {
            refInput.current.value = '';
            _handleShowError({ message });
            onUpFileSuccess();
            return;
        }

        const { thumbnail_url, object_tag, object_key } = await refServiceUpload.current.onUploadS3({ file: data });
        dispatchState((prev) => ({
            ...prev,
            avtObject: { object_key, object_tag },
            urlImage: thumbnail_url,
            isUploaded: true,
            file: data,
            isLoading: false
        }));
        onUpFileSuccess();
    };

    const _handleShowError = ({ message }) => {
        dispatchState({ isError: true, msgError: message, isLoading: false });
        refTimer.current = setTimeout(() => {
            dispatchState({ isError: false, msgError: '' });
        }, 5000);
    };

    const _handleDelete = (e) => {
        e && e.preventDefault();
        refConfirmDeleteAvatar.current.open();
    };

    const _onConfirmDeleteAvatar = () => {
        refInput.current.value = '';
        onDelete();
        dispatchState((prev) => ({ ...prev, ...initialState, urlImage: avatarDefault || null }));
    };

    return (
        <Fragment>
            <div className="flex-betweenitems">
                {finalIsLoading ? (
                    <div className="preview-avt">
                        <div className="is-loading-box ">
                            <div className="preloader-small">
                                <span className="preloader-small__items"></span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <ColAvatarMultipleBranch
                        style={'preview-avt'}
                        item={{ avatar: urlSrc, name: finalNameBranch, color }}
                    />
                )}

                <div className="d-flex gap-6">
                    <div className="upload-file">
                        <label htmlFor={id} className="v2-btn-default">
                            {titleButton || t('upload_avatar_branch')}
                        </label>
                        <input ref={refInput} id={id} type="file" onChange={_handleUploadFile} accept={imageType} />
                    </div>
                    {!!urlSrc && urlSrc !== avatarDefault ? (
                        <div className="v2-btn-default --icon-lg --delete" onClick={_handleDelete}>
                            <IconTrash />
                        </div>
                    ) : null}
                </div>
            </div>
            {isError ? (
                <p className="txt-incorrect">
                    <IconError />
                    {msgError}
                </p>
            ) : null}
            {!isNotDivider && <div className="is-divider --horizontal" />}
            <ServiceUploadLogo ref={refServiceUpload} typePresign={typePresign} />
            <GdConfirm
                ref={refConfirmDeleteAvatar}
                title={t('confirm')}
                message={t('are_you_sure_delete_this_avatar')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_onConfirmDeleteAvatar}
            />
        </Fragment>
    );
};

export default forwardRef(FieldUploadAvatar);
