export default function IconLogout() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5 19.5H17.5C18.6046 19.5 19.5 18.6046 19.5 17.5V7.5C19.5 6.39543 18.6046 5.5 17.5 5.5H14.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
            />
            <path d="M14.5 12.5H5.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="round" />
            <path
                d="M9 17L5.5 12.5L9 8"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
