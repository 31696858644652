import { call } from 'redux-saga/effects';
import {
    CALENDAR_GET_LIST_JOB,
    resizeTimeJob,
    moveJob,
    moveJobToWorkPool,
    CALENDAR_GET_LIST_JOB_EVENTS,
    CALENDAR_BATCH_ACTION_MOVE_JOB,
    moveTimeoff,
    resizeTimeoff,
    resizeEvent,
    moveEvent
} from 'app/const/Api';
import { fetchWithToken, putWithToken } from 'common/utils/ApiUtils';

export function* actionGetListJob({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CALENDAR_GET_LIST_JOB, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionResizeTimeJob({ params, actionSuccess, actionFailed }) {
    const eventId = params.eventId;
    let url = resizeTimeJob(eventId);
    delete params.eventId;

    switch (params.typeEvent) {
        case 'timeoff':
            url = resizeTimeoff(eventId);
            break;
        case 'event':
            url = resizeEvent(eventId);
            break;
        default:
            break;
    }

    try {
        const response = yield call(putWithToken, url, params);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionMoveJob({ params, actionSuccess, actionFailed }) {
    const eventId = params.eventId;
    let url = moveJob(eventId);
    delete params.eventId;

    switch (params.typeEvent) {
        case 'timeoff':
            url = moveTimeoff(eventId);
            break;
        case 'event':
            url = moveEvent(eventId);
            break;
        default:
            break;
    }

    try {
        const response = yield call(putWithToken, url, params);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* actionMoveJobToWorkPool({ params, actionSuccess, actionFailed }) {
    const jobId = params.jobId;
    delete params.jobId;

    try {
        const response = yield call(putWithToken, moveJobToWorkPool(jobId), params);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* calendarGetListJobEvents({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CALENDAR_GET_LIST_JOB_EVENTS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* calendarBatchActionMoveJobs({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, CALENDAR_BATCH_ACTION_MOVE_JOB, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
