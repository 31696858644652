import React, { useReducer, forwardRef, useImperativeHandle } from 'react';
import { reducer } from 'app/const/Reducer';
import { ACTION_VALUE } from '../const/Invoice';

const ConfirmAddInvoice = forwardRef(({ handleConfirm, onClose = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        isCard: false,
        isChargeAndAction: false,
        text: 'Email'
    });

    const { isVisible, isCard, isChargeAndAction, text: actionText } = state;

    useImperativeHandle(ref, () => ({
        _handleOpenForm,
        _handleCloseForm
    }));

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const getText = (type) => {
        switch (type) {
            case ACTION_VALUE.SEND_SMS:
                return 'SMS';
            case ACTION_VALUE.SEND_EMAIL_AND_SMS:
                return 'Email and SMS';
            default:
                return 'Email';
        }
    };

    const _handleOpenForm = (type) => {
        dispatchState({
            isVisible: true,
            isCard: type === ACTION_VALUE.CHARGE || type === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT,
            isChargeAndAction: type === ACTION_VALUE.CHARGE_AND_SEND_RECEIPT,
            text: getText(type)
        });
    };

    const _handleCloseForm = (trigger) => {
        dispatchState({
            isVisible: false
        });
        trigger && onClose();
    };

    const _handleConfirm = (e, id) => {
        _stopPropagation(e);
        handleConfirm(id);
    };

    if (!isVisible) return null;

    return (
        <div className="modal container-modal --more-button open">
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h2 className="header-modal__label">Invoice Action</h2>
                </div>
                <div className="body-modal">
                    <p>This invoice's Action is set to trigger today.</p>
                    <p>{`Would you like GorillaDesk to ${
                        isCard ? (isChargeAndAction ? `Charge and ${actionText}` : 'Charge') : actionText
                    } this invoice now?`}</p>
                    <div
                        onClick={(e) => {
                            _handleConfirm(e, 1);
                        }}
                        className="v2-btn-default"
                    >
                        {isCard ? 'Yes, Charge the card now' : `Yes, ${actionText} this invoice now`}
                    </div>
                    <div
                        onClick={(e) => {
                            _handleConfirm(e, 0);
                        }}
                        className="v2-btn-default"
                    >
                        No, I'll complete this later
                    </div>
                    <div
                        onClick={() => {
                            _handleCloseForm(true);
                        }}
                        className="v2-btn-default --transparent"
                    >
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ConfirmAddInvoice;
