import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import Input from 'app/components/input/Input';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconCheck from 'assets/icon/IconCheck';
import IconClose from 'assets/icon/IconClose';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { updatePipelines } from 'common/redux/actions/settings/pipelinesAction';
import DropdownStatusPipelines from './DropdownStatusPipelines';

const ItemPipeline = (
    {
        refProvide,
        idGroup = '',
        pipeline = {},
        draggableProps = {},
        draggableHandleProps = {},
        index,
        handleShowAlert = () => {},
        onWarningDelete = () => {},
        onOpenEdit = () => {}
    },
    ref
) => {
    const { t } = useTranslation('setting');
    const dispatch = useDispatch();
    const refStatus = useRef(null);
    const refInput = useRef(null);
    const refSave = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isEdit: false
    });

    const { isEdit: finalIsEdit } = state;
    const { id, name, status } = pipeline;

    useImperativeHandle(ref, () => ({
        visibleEdit: _handleVisibleEdit
    }));

    const _handleDelete = () => {
        onWarningDelete({ id, idGroup });
    };

    const _handleVisibleEdit = (value) => {
        if (!!value) onOpenEdit(id);
        dispatchState({ isEdit: !!value });
    };

    const _handleSave = () => {
        const valueInput = refInput.current._getValue();
        const valueStatus = refStatus.current.getValue();

        if (!valueInput?.trim()) {
            _handleValidInput(false);
            refSave.current?.removeLoading();
            return;
        }
        _handleValidInput(true);

        const _handleSaveSuccess = ({ message }) => {
            _handleVisibleEdit(false);
            handleShowAlert({ message, type: LIST_STATUS.SUCCESS });
        };

        const _handleFail = ({ message }) => {
            handleShowAlert({ message });
            refSave.current?.removeLoading();
        };

        dispatch(
            updatePipelines({ id, name: valueInput, status: valueStatus, idGroup }, _handleSaveSuccess, _handleFail)
        );
    };

    const _handleValidInput = (value) => {
        refInput.current._setError(!value ? t('value_cannot_be_blank') : '');
    };

    const _renderName = () => {
        if (!finalIsEdit) return <p className="col-desc --value flex-1">{name}</p>;

        return (
            <Input
                ref={refInput}
                classWrapper="col-desc --edit flex-1"
                initValue={name}
                autoFocus
                placeholder={t('status_name')}
            />
        );
    };

    const _renderButton = () => {
        if (!finalIsEdit)
            return (
                <Fragment>
                    <div className="v2-btn-default --icon-lg tooltip" onClick={() => _handleVisibleEdit(true)}>
                        <IconEdit />
                        <span className="tooltiptext top">{t('edit')}</span>
                    </div>
                    <div className="v2-btn-default --icon-lg has-bg-red tooltip btn-modal" onClick={_handleDelete}>
                        <IconTrash />
                        <span className="tooltiptext top">{t('delete')}</span>
                    </div>
                </Fragment>
            );

        return (
            <Fragment>
                <ButtonSave
                    ref={refSave}
                    wrapClass="v2-btn-main --icon-lg svg-white-stroke tooltip"
                    onSave={_handleSave}
                >
                    <IconCheck />
                    <span className="tooltiptext top">{t('save')}</span>
                </ButtonSave>
                <div className="v2-btn-default --icon-lg tooltip" onClick={() => _handleVisibleEdit(false)}>
                    <IconClose />
                    <span className="tooltiptext top">{t('cancel')}</span>
                </div>
            </Fragment>
        );
    };

    const _renderStatus = () => {
        if (!finalIsEdit) return <span className={`status-btn ${status?.toLowerCase()}`}>{status}</span>;
        return <DropdownStatusPipelines ref={refStatus} defaultValue={status} isDisable isButton />;
    };

    return (
        <div key={id} ref={refProvide} className="rows" {...draggableProps}>
            <div className="col-drag v2-btn-default --icon-sm --transparent" {...draggableHandleProps}>
                <IconThreeDots isDouble />
            </div>
            <p className="col-number txt-ellipsis">{index.toString()}</p>
            {_renderName()}
            {_renderStatus()}

            <div className="col-icon flexcenter gap-4">{_renderButton()}</div>
        </div>
    );
};

export default forwardRef(ItemPipeline);
