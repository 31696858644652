import React from 'react';
import PropTypes from 'prop-types';
import FooterBackLogin from 'app/modules/auth/components/FooterBackLogin';
import GdLazyImage from 'app/components/lazyimage';
import { LINK_CDN_IMG } from 'app/const/URL';
import 'app/modules/auth/signupActivate/css/index.scss';
import { useTranslation } from 'react-i18next';
import { SRC_LOGO_GORILLADESK } from 'app/const/App';
function AccountHasActivated({ errorMessage }) {
    const { t } = useTranslation(['common']);

    return (
        <div className="gorilladesk auth-page">
            <div className="wrapper">
                <h1 className="logo-text-gorilla">
                    <GdLazyImage src={SRC_LOGO_GORILLADESK} alt={t('common:gorilladesk')} width={335} height={80} />
                </h1>
                <div className="main-login">
                    <div className="gd-form-v2">
                        <h2 className="pt-8 fs-20 text-center">{errorMessage}</h2>
                        <FooterBackLogin />
                    </div>
                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}

AccountHasActivated.propTypes = {
    errorMessage: PropTypes.string
};

export default AccountHasActivated;
