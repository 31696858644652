import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CALENDAR_STORE } from 'app/const/Api';
import { updateColumnsAction } from 'common/redux/actions/calendar';
import { clientQuery } from 'common/utils/ApiUtils';
import { COLUMNS_LIST_VIEW, DEFAULT_COLUMNS_LIST_VIEW } from '../../const/Columns';
import CalendarDropdown from '../CalendarDropdown';

const DropdownColumns = () => {
    const { t } = useTranslation('calendar');
    const dispatch = useDispatch();
    const additionalColumns = useSelector(({ calendar }) => calendar.columns || '');
    const refForm = useRef(null);
    const refDropdown = useRef(null);

    const _handleApply = () => {
        const form = refForm.current;
        const listCheckbox = form.querySelectorAll('input[type="checkbox"]');
        const listChecked = [];
        const listCheckedWithoutDefault = [];

        listCheckbox.forEach((item) => {
            if (item.checked) {
                if (item.dataset.columnDefault === 'false') listCheckedWithoutDefault.push(item.id);
                listChecked.push(item.id);
            }
        });

        // Call API to update columns list
        dispatch(updateColumnsAction(listChecked.join(',')));
        clientQuery(CALENDAR_STORE, { data: { type: 16, value: listCheckedWithoutDefault.join(',') }, method: 'PUT' });
        refDropdown.current._closeDropdown();
    };
    return (
        <form ref={refForm}>
            <CalendarDropdown
                ref={refDropdown}
                id="dropdown-calendar-columns"
                buttonClassName="dropbtn v2-btn-default --has-blue has-icon"
                selectedClassName="txt-ellipsis fs-14"
                wrapperListClass="v2-dropdown__menu content-checked scrolls"
                selected={t('columns')}
            >
                <>
                    <ul>
                        {[...DEFAULT_COLUMNS_LIST_VIEW, ...COLUMNS_LIST_VIEW].map((item) => {
                            if (item.disableFilter) return null;
                            const isDefaultChecked = item.isDefault || additionalColumns.includes(item.key);
                            return (
                                <li key={item.key} className={classNames('items', { 'is-disable': item.isDefault })}>
                                    <div className="check-items">
                                        <input
                                            id={item.key}
                                            type="checkbox"
                                            defaultChecked={isDefaultChecked}
                                            data-column-default={item.isDefault || false}
                                        />
                                        <div className="item-checkbox">
                                            <label htmlFor={item.key}>
                                                <span className="txt-ellipsis">{item.label}</span>
                                            </label>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="py-2 px-3 border-top-grey-light-silver" onClick={_handleApply}>
                        <div className="v2-btn-main --no-background w-100 justify-center fw-500">{t('apply')}</div>
                    </div>
                </>
            </CalendarDropdown>
        </form>
    );
};

export default DropdownColumns;
