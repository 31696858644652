import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import {
  LIST_VALUE_VARIABLE_CONTENT,
  replaceInfoUserInfo,
  replaceInfoVariables
} from "app/const/drip/InsertVariables";

import {
  getLogoCompanyElementPreview,
} from "app/const/drip";

const logoCompanyElement = getLogoCompanyElementPreview();
function DripPreviewEmail({
  content
}) {
  
  const { t } = useTranslation(["drip"]);

  let previewContent = content || "<p></p>";

  previewContent = replaceInfoVariables(LIST_VALUE_VARIABLE_CONTENT, previewContent, t)

  previewContent = replaceInfoUserInfo(previewContent);

  previewContent = logoCompanyElement.concat(previewContent);

  return (
    <div className="preview-email" dangerouslySetInnerHTML={{ __html: previewContent }} />
  )
}

DripPreviewEmail.propTypes = {
  content: PropTypes.string
}

DripPreviewEmail.defaultProps = {
  content: "<p></p>"
}

const compare = (prevProps, nextProps) => {
  return prevProps.content == nextProps.content
}

export default React.memo(DripPreviewEmail, compare);

