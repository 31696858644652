import React from 'react';

const SMSAddonsLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="loading --onefifth switch ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --full mt-4" />
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="wrap-content --hasline pt-4 loading">
                    <div className="boxs loading">
                        <div className="title">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="info">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="boxs loading">
                        <div className="title">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="info">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="switch flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="boxs loading">
                        <div className="title">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="info">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="boxs loading">
                        <div className="title">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="info">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="switch flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon">
                <div className="wrap-process --hasline loading">
                    <div className="rows">
                        <div className="rows__label flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="rows__number phone loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows mt-4">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="progress flex-1 loading mx-2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="rows__number">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows mt-4">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="progress flex-1 loading mx-2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="rows__number">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="rows mt-4">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="progress flex-1 loading mx-2">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="rows__number">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents mt-4">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const LoadingRentMobileModal = () => {
    return (
        <>
            <div className="wrap-loading">
                <div className="header-modal">
                    <div className="header-modal__label">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
            <div className="wrap-loading body-modal">
                <div className="row row-multi align-end">
                    <div className="col --sm">
                        <div className="loading --full mb-2">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey --full">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="col flex-1">
                        <div className="loading --full mb-2">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="v2-btn-default loading --grey --full">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="col">
                        <div className="v2-btn-default loading --grey --full">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="tables scrolls-x">
                    <div className="rows --fixed --header">
                        <div className="col --checkbox flex-none" />
                        <div className="col --info">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --info">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="tables-list">
                        <div className="rows align-center bg-white">
                            <div className="col --checkbox flex-none">
                                <div className="check-radio">
                                    <div className="checkbox" />
                                    <div className="item-checkbox" />
                                </div>
                            </div>
                            <div className="col --info">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="col --info">
                                <div className="loading --animation --line --twothird" />
                            </div>
                        </div>
                        <div className="rows align-center bg-white">
                            <div className="col --checkbox flex-none">
                                <div className="check-radio">
                                    <div className="checkbox" />
                                    <div className="item-checkbox" />
                                </div>
                            </div>
                            <div className="col --info">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="col --info">
                                <div className="loading --animation --line --twothird" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading">
                <div className="footer-modal justify-end">
                    <div className="v2-btn-default loading --grey --onefourth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SMSAddonsLoading;
