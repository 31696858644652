import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import TooltipPopper from 'app/components/tooltip/TooltipPopper';
import IconViewRow from 'assets/icon/IconViewRow';
import { CALENDAR_LIST_VIEWS_MODE } from '../../const';

const ModeView = ({ onChangeView }) => {
    const { t } = useTranslation('calendar');
    const viewCalendar = useSelector(({ calendar }) => calendar.view);
    const isMapOpen = useSelector(({ map }) => map.openmap);
    const isListView = CALENDAR_LIST_VIEWS_MODE.includes(viewCalendar);

    return (
        <div
            className={classNames('btn-viewtask tooltip', { 'is-disable': isListView && !isMapOpen })}
            onClick={onChangeView}
        >
            <TooltipPopper
                className={`v2-btn-default --icon-lg icons-viewrows`}
                tooltipText={t('calendar:view_horizontal')}
            >
                <IconViewRow isHorizontal />
            </TooltipPopper>
            <TooltipPopper
                className={`v2-btn-default --icon-lg active icons-viewcolumns`}
                tooltipText={t('calendar:view_vertical')}
            >
                <IconViewRow />
            </TooltipPopper>
        </div>
    );
};

export default ModeView;
