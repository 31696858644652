export const ADD_CUSTOMER_CONTEXT_TYPES = {
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    ADD_ADDITIONAL_CONTACT: 'ADD_ADDITIONAL_CONTACT',
    UPDATE_ADDITIONAL_CONTACT: 'UPDATE_ADDITIONAL_CONTACT',
    DELETE_ADDITIONAL_CONTACT: 'DELETE_ADDITIONAL_CONTACT',
    SELECT_ADDRESS_SERVICE: 'SELECT_ADDRESS_SERVICE',
    UPDATE_BILLING_ADDRESS: 'UPDATE_BILLING_ADDRESS',
    UPDATE_SERVICE_ADDRESS: 'UPDATE_SERVICE_ADDRESS',
    UPDATE_SERVICE_LOCATION: 'UPDATE_SERVICE_LOCATION',
    UPDATE_PROFILE_PHONES: 'UPDATE_PROFILE_PHONES',
    SELECT_BILLING_SERVICE: 'SELECT_BILLING_SERVICE'
};
