import { call } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';

import {
    COMMISSIONS_GET_LIST_USER,
    COMMISSIONS_APPLY_SETTING_USER,
    COMMISSIONS_UPDATE_SETTING_USER,
    getUrlManageCommissionUser
} from 'app/const/Api';

export function* getListUserCommission({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, COMMISSIONS_GET_LIST_USER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getSettingUserCommission({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, getUrlManageCommissionUser(params.id), params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* applySettingFromAnotherUserCommission({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, COMMISSIONS_APPLY_SETTING_USER, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateSettingUserCommission({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, COMMISSIONS_UPDATE_SETTING_USER, params);
        if (response && response.success) {
            actionSuccess(params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
