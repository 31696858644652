export const TYPE_SETTING_SERVICE = {
    GET_LIST: 'SETTING_GET_LIST_SERVICE',
    GET_LIST_SUCCESS: 'SETTING_GET_LIST_SERVICE_SUCCESS',
    ADD_AND_UPDATE: 'SETTING_ADD_AND_UPDATE_SERVICE',
    UPDATE_STATUS: 'SETTING_UPDATE_STATUS_SERVICE',
    GET_DETAIL_SERVICE: 'GET_DETAIL_SERVICE',
    UPDATE_SERVICE_COLOR: 'UPDATE_SERVICE_COLOR',
    RESET_SERVICE_LIST: 'RESET_SERVICE_LIST'
};

export const updateServiceColor = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.UPDATE_SERVICE_COLOR,
        params,
        actionSuccess,
        actionFailed
    };
};

// actions
export const getListSettingService = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const addAndUpdateService = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.ADD_AND_UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateStatusSettingService = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SERVICE.UPDATE_STATUS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getDetailService = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_SERVICE.GET_DETAIL_SERVICE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const resetServiceList = () => {
    return {
        type: TYPE_SETTING_SERVICE.RESET_SERVICE_LIST
    };
};
