export default function LoadingJobs() {
    return (
        <div className="boxs__content">
            <div className="chart">
                <div className="chart__content">
                    <div className="content-l">
                        <div className="is-summary">
                            <div className="rows">
                                <div className="col">
                                    <div className="loading --animation --line --twothird"></div>
                                    <div className="loading --animation --line --half mt-1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-rows gap-10">
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-r">
                        <div className="chart-circle"></div>
                    </div>
                </div>
                <div className="chart__footer">
                    <div className="flex-column gap-16">
                        <div className="rows">
                            <div className="col">
                                <div className="loading --animation --line --twothird"></div>
                            </div>
                        </div>
                        <div className="flex-column gap-10 h-100">
                            <div className="chart-line loading --animation"></div>
                        </div>
                        <div className="rows__label flexcenter gap-16">
                            <div className="loading --animation --line mt-0 --twothird"></div>
                            <div className="loading --animation --line mt-0 --twothird"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
