export const SMART_VIEWS_TYPES = {
    SV_GET_ZIP: 'SV_GET_ZIP',
    SV_GET_ZIP_SUCCESS: 'SV_GET_ZIP_SUCCESS',
    SV_GET_CITY: 'SV_GET_CITY',
    SV_GET_CITY_SUCCESS: 'SV_GET_CITY_SUCCESS',
    SV_GET_ITEMS: 'SV_GET_ITEMS',
    SV_GET_ITEMS_SUCCESS: 'SV_GET_ITEMS_SUCCESS'
};

export const smartViewsGetZip = () => {
    return { type: SMART_VIEWS_TYPES.SV_GET_ZIP };
};
export const smartViewsGetCity = () => {
    return { type: SMART_VIEWS_TYPES.SV_GET_CITY };
};
export const smartViewsGetItems = () => {
    return { type: SMART_VIEWS_TYPES.SV_GET_ITEMS };
};
