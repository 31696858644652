import { GET_LIST_GROUP_SCHEDULES } from 'app/const/api/V2';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';

export const SettingScheduleContext = createContext();

const SettingScheduleProvider = ({ children, startFetch }) => {
    const [state, dispatchState] = useReducer(reducer, { scheduleGroups: [], isLoading: true });
    const { scheduleGroups } = state;

    const refSelectedTags = useRef([]);

    useEffect(() => {
        const _handleGetSuccess = ({ data }) => {
            dispatchState({ scheduleGroups: data });
        };
        startFetch && clientQuery(GET_LIST_GROUP_SCHEDULES, { method: 'GET' }, _handleGetSuccess);
    }, [startFetch]);

    const handleAddNewTag = (tag) => {
        dispatchState({ scheduleGroups: [...new Set([...scheduleGroups, tag])] });
    };

    const handleDeleteTag = (tag) => dispatchState({ scheduleGroups: scheduleGroups.filter((item) => item !== tag) });

    const handleSelectTag = (tag) => {
        refSelectedTags.current = [...new Set([...refSelectedTags.current, ...tag])];
    };

    const handleGetSelectedTags = () => refSelectedTags.current;

    return (
        <SettingScheduleContext.Provider
            value={{ ...state, handleAddNewTag, handleDeleteTag, handleSelectTag, handleGetSelectedTags }}
        >
            {children}
        </SettingScheduleContext.Provider>
    );
};

export default SettingScheduleProvider;
