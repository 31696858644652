import React, { useEffect, useReducer, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { LIST_MINUTE } from 'app/modules/jobdetail/const/JobLength';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const SelectMinute = ({ selected, onSelect }) => {
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_option_select_hour');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_option_select_hour');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    function _handleSelectMinute(value) {
        onSelect(value);
        _closeSearchResult();
    }

    function _renderMinute() {
        return LIST_MINUTE.map((item) => {
            return (
                <li onClick={() => _handleSelectMinute(item.value)} className="items" key={item.value}>
                    {item.name}
                </li>
            );
        });
    }

    return (
        <div className="from-time">
            <div ref={refDropdown} className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
                <div className="dropbtn v2-btn-default" onClick={_handleOpen}>
                    <span>{selected < 10 ? `0${selected}` : selected}</span>
                    <span className="time-text">m</span>
                    <span className="ml-1">
                        <IconDropDown />
                    </span>
                </div>
                <div id={'show_list_option_select_hour'} className="v2-dropdown__menu scrolls">
                    <ul>{_renderMinute()}</ul>
                </div>
            </div>
        </div>
    );
};

export default SelectMinute;
