import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import IconWarning from 'assets/icon/IconWarning';
import IconApiLock from 'assets/icon/IconApiLock';
import ButtonCopy from 'app/components/button/ButtonCopy';
import { ADDONS } from 'app/config/routes';
import { useTranslation } from 'react-i18next';
import ApiKeysAddonsContent from './components/ApiKeysAddonsContent';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_API_KEYS, ADDONS_API_KEYS_LIST, getUpdateAndDeleteAPIKey } from 'app/const/Api';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import Loading from './components/Loading';
import ApiKeyModal from './components/ApiKeyModal';
import ApiKeyModalSuccess from './components/ApiKeyModalSuccess';
import GDModalWarning from 'app/components/modal/ModalWarning.js';
import classNames from 'classnames';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import { useHistory } from 'react-router-dom';
import { _handelSortApiName } from 'common/utils/FunctionUtils.js';
import { caculateTimeAgo } from 'common/utils/TimeUtils.js';
import { LINK_OPEN_API_DOC } from 'app/const/URL';
import AddonBack from '../components/AddonBack';
import { addBranchPath } from 'app/const/Branch';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import IconCogWheel from 'assets/icon/IconCogWheel';
import ApiKeySettingsModal from './components/ApiKeySettingsModal';

const AddonsAPIKeys = () => {
    const [state, dispatchState] = useReducer(reducer, {
        data: {},
        isLoading: true,
        listApi: []
    });

    const { data, isLoading, listApi } = state;

    const { t } = useTranslation();
    const history = useHistory();

    const refDropdown = useRef(null);
    const refModal = useRef(null);
    const refModalSuccess = useRef(null);
    const refConfirmDelete = useRef(null);
    const refSettings = useRef(null);

    const refAlert = useRef(null);

    useEffect(() => {
        _handleGetDetailAPIKey();
    }, []);

    const _handleGetDetailAPIKey = () => {
        clientQuery(ADDONS_API_KEYS, { data: {}, method: 'GET' }, _handleGetDetailSuccess, checkAccessFail);
    };

    const _handleGetDetailSuccess = ({ data }) => {
        if (!!data.upgrade) {
            history.push(addBranchPath(ADDONS));
        } else {
            dispatchState((prev) => ({ ...prev, data, isLoading: false }));
            clientQuery(ADDONS_API_KEYS_LIST, { data: {}, method: 'GET' }, _handleGetListSuccess);
        }
    };

    const _handleGetListSuccess = ({ data }) => {
        dispatchState((prev) => ({ ...prev, listApi: data, isLoading: false }));
    };

    const _handleCreateAPIKey = () => {
        refModal.current._open({ type: 'create' });
    };

    const _handleEditAPIKey = (data) => {
        refModal.current?._open({ data, type: 'update' });
        _handleCloseDropdown();
    };

    const _handleCreateOrEditSuccess = ({ data, message }, type) => {
        const id = data?.id;
        const isCreate = type === 'create';
        refModal.current._close();
        isCreate && refModalSuccess.current._open({ value: data?.token });
        _handleShowMessage(message, t(`addons:${type}_api_key_success`), LIST_STATUS.SUCCESS);

        dispatchState((prev) => {
            return {
                ...prev,
                listApi: _handelSortApiName(
                    isCreate
                        ? [...prev.listApi, data]
                        : listApi.map((item) => {
                              if (item.id === id) {
                                  return data;
                              } else return item;
                          })
                )
            };
        });
    };

    const _handleCreateOrEditFail = ({ message }, type) => {
        refModal.current._close();
        _handleShowMessage(message, t(`addons:${type}_api_key_fail`), LIST_STATUS.ERROR);
    };

    const _handleDeleteAPIKey = (data) => {
        refConfirmDelete.current?._open({
            id: data?.id,
            subDescription: t('addons:api_key_confirm_delete_content', { name: data?.name })
        });
        _handleCloseDropdown();
    };

    const _handleConfirm = (id) => {
        clientQuery(
            getUpdateAndDeleteAPIKey(id),
            { data: {}, method: 'DELETE' },
            (res) => _handleDeleteSuccess(res, id),
            _handleDeleteFail
        );
    };

    const _handleDeleteSuccess = ({ message }, id) => {
        refConfirmDelete.current._close();

        _handleShowMessage(message, t('addons:delete_api_key_success'), LIST_STATUS.SUCCESS);

        dispatchState((prev) => {
            return { ...prev, listApi: prev.listApi.filter((item) => item.id !== id) };
        });
    };

    const _handleDeleteFail = ({ message }) => {
        refConfirmDelete.current._close();
        _handleShowMessage(message, t('addons:delete_api_key_fail'), LIST_STATUS.ERROR);
    };

    const _handleShowMessage = (message, defaultMes, status) => {
        refAlert.current &&
            refAlert.current.showStatusBar({
                id: status,
                message: message.length ? message : defaultMes,
                type: status
            });
    };

    const queryBoundary = document.querySelector('.tables-list.--hastotal');

    const _renderListOptions = (data) => {
        const defaultClassNames = 'items has-icon btn-modal';

        return (
            <ul>
                <li>
                    <div
                        onClick={() => _handleEditAPIKey(data)}
                        className={classNames(defaultClassNames, { 'is-disable': !data?.status })}
                    >
                        <IconEdit />
                        <div className="txt-ellipsis">{t('addons:edit_api_key')}</div>
                    </div>
                </li>
                <li>
                    <div
                        onClick={() => _handleOpenSettingModal(data)}
                        className={classNames(defaultClassNames, { 'is-disable': !data?.status })}
                    >
                        <IconCogWheel />
                        <div className="txt-ellipsis">{t('addons:settings')}</div>
                    </div>
                </li>
                <li>
                    <div onClick={() => _handleDeleteAPIKey(data)} className={defaultClassNames}>
                        <IconTrash />
                        <div className="txt-ellipsis red-delete">{t('addons:delete_api_key')}</div>
                    </div>
                </li>
            </ul>
        );
    };

    const renderApiKeyName = (token) => `${token?.substring(0, 6)}••••••••••••••••`;

    const _renderListAPIKey = () => {
        return listApi.map((item) => {
            const { id, token, last_used, status, name } = item;
            return (
                <div className="rows" key={id}>
                    <p title={name} className="col-desc txt-ellipsis">
                        {name}
                    </p>
                    <ButtonCopy
                        className="v2-btn-default --icon-lg --blue is-copy tooltip"
                        timeout={3000}
                        content={token}
                    />
                    <DropdownPopper
                        ref={refDropdown}
                        wrapperClassName="v2-dropdown"
                        wrapperListClass="v2-dropdown__menu scrolls"
                        dropdownIcon={<IconThreeDots />}
                        strategy={'absolute'}
                        modifiers={[
                            { name: 'offset', options: { offset: [0, 5] } },
                            {
                                name: 'preventOverflow',
                                options: {
                                    mainAxis: false,
                                    boundary: queryBoundary
                                }
                            }
                        ]}
                        buttonClassName="dropbtn v2-btn-default --icon-lg"
                        isUseToggle
                    >
                        {_renderListOptions(item)}
                    </DropdownPopper>
                    <div className={classNames('col-apikey', { 'red-default': !status })}>
                        {status ? <IconApiLock /> : <IconWarning isDanger={true} />}
                        {renderApiKeyName(token)}
                    </div>
                    <p className="grey-quick-silver">
                        {last_used ? caculateTimeAgo(new Date(last_used) * 1000) : t('addons:not_used_yet')}
                    </p>
                </div>
            );
        });
    };

    const _handleOpenSettingModal = ({ id = '', settings = {} }) => {
        refSettings.current?._open({ id, settings: settings || {} });
        _handleCloseDropdown();
    };

    const _handleCloseDropdown = () => {
        refDropdown.current?._close();
    };

    const _handleUpdateSettingApi = ({ id = '', settings = {} }) => {
        dispatchState((prev) => {
            return {
                ...prev,
                listApi: prev.listApi.map((item) => {
                    if (item.id !== id) return item;

                    return { ...item, settings: { ...(item.settings || {}), ...settings } };
                })
            };
        });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Fragment>
                            <ApiKeysAddonsContent {...data} handleCreateAPI={_handleCreateAPIKey} />
                            <AlertCustomer ref={refAlert} />
                            {listApi.length ? (
                                <div className="boxs boxs--api">{_renderListAPIKey()}</div>
                            ) : (
                                <div className="tables-empty">{t('addons:add_your_api_key')}</div>
                            )}
                            <div className="boxs boxs--no-border mt-3">
                                <div className="title">{t('addons:api_docmentation')}</div>
                                <p className="description">
                                    {t('addons:documentation_for_our_api_exist_at')}
                                    <a
                                        href={LINK_OPEN_API_DOC}
                                        rel="noreferrer"
                                        target="_blank"
                                        className="purple-default"
                                    >
                                        {LINK_OPEN_API_DOC}
                                    </a>
                                </p>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
            <ApiKeyModal ref={refModal} onSuccess={_handleCreateOrEditSuccess} onFailure={_handleCreateOrEditFail} />
            <ApiKeyModalSuccess ref={refModalSuccess} />
            <ApiKeySettingsModal ref={refSettings} onUpdateSuccess={_handleUpdateSettingApi} />
            <GDModalWarning
                modalClassName="modal container-modal modal-confirm-delete open"
                headerClassName="header-modal"
                title={t('addons:api_key_confirm_delete_tilte')}
                descriptionClassName="text"
                buttonConfirm
                ref={refConfirmDelete}
                onConfirm={_handleConfirm}
            />
        </div>
    );
};

export default AddonsAPIKeys;
