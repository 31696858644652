import { JOB_ACTIVE } from '../const/Job';

export const actionJobMove = ({ data }, jobList) => {
    return jobList.map((item) => {
        if (item.id === data.id) return { ...item, start: data.start, schedule: data.schedule, tile: data.tile };
        return item;
    });
};

export const actionJobStatus = (jobData, jobList) => {
    // eslint-disable-next-line no-undef
    const jobPreviewCurrentId = global.jobPreviewId;
    const currentJob = jobData.current_job;
    const currentJobData = currentJob.job;
    const jobId = parseInt(currentJobData.id);
    const newJob = jobData.new_job;
    const newJobData = newJob?.job;
    let jobPreviewUpdate = null;

    const newJobs = jobList.map((item) => {
        const currentJobId = parseInt(item.id);
        if (currentJobId === jobId) {
            item.status = currentJobData.status;
            item.previously_completed = currentJobData.previously_completed;
            item.editable = currentJob.editable;
            item.draft_invoice = currentJob.draft_invoice;
            item.colorEvent = currentJob.color || item.colorEvent;
        }

        if (jobId === currentJobId && currentJobId === parseInt(jobPreviewCurrentId)) {
            jobPreviewUpdate = item;
        }

        if (newJob && newJobData) {
            if (parseInt(newJob.job.id) === currentJobId) {
                item.status = newJobData.status;
                item.previously_completed = newJobData.previously_completed || 0;
                item.recurrence = '';
                item.editable = newJob.editable;
                item.draft_invoice = newJob.draft_invoice;
                item.colorEvent = newJob.color || item.colorEvent;
                item.active_job = JOB_ACTIVE.ACTIVE;
                if (currentJobId === parseInt(jobPreviewCurrentId)) {
                    jobPreviewUpdate = item;
                }
            }
        }
        return item;
    });

    return { list: newJobs, jobPreview: jobPreviewUpdate };
};

export const actionDeleteJob = (jobData, events) => {
    /* eslint-disable no-undef */
    const jobPreviewCurrentId = global.jobPreviewId;
    const jobDeleted = jobData.jobs || [];
    let isJobPreview = false;
    let newEvents = [...events];
    jobDeleted.forEach((item) => {
        const currentParentJob = item.parent.toString();
        const jobComplete = currentParentJob === '0' ? 1 : 0;
        const jobId = item.id.toString();

        if (jobComplete === 1) {
            newEvents = newEvents.filter((eventDetail) => {
                if (eventDetail.id !== jobId) {
                    if (jobPreviewCurrentId === jobId) {
                        isJobPreview = true;
                    }
                    return eventDetail;
                }
            });
        } else {
            newEvents = newEvents.filter((eventDetail) => {
                if (currentParentJob === eventDetail.parent_job_id && eventDetail.previously_completed === 0) {
                    if (jobPreviewCurrentId === eventDetail.id) {
                        isJobPreview = true;
                    }
                } else {
                    return eventDetail;
                }
            });
        }
    });

    return {
        list: newEvents,
        isJobPreview
    };
};
