import { getUrlOptionsCommmissionUser } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { resetCommissionData } from 'common/redux/actions/commission';
import { getListUserCommission } from 'common/redux/actions/commissionAction';

import { clientQuery } from 'common/utils/ApiUtils';
import { forwardRef, useEffect, useReducer, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPES_OPTION_ACTIVE } from '../const';
import LineItemsCommission from './LineItems';
import UserListCommission from './UserList';

const ManageCommission = ({ setDisableButtonSave = () => {} }, ref) => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, {
        users: [],
        userSelected: null,
        isLoading: true
    });
    const { isFirstTime, isFirstTimeUsers } = useSelector((state) => state.commissionReducer);
    const refLineItems = useRef(null);

    const { userSelected, users } = state;

    useEffect(() => {
        _getUsers();
    }, []);

    useEffect(() => {
        userSelected && _getLineItems();
    }, [userSelected]);

    const _getUsers = () => {
        const _success = ({ data }) => {
            dispatchState({ users: data, userSelected: data[0] || null, isLoading: false });
        };
        const _fail = () => {
            dispatchState({ isLoading: false });
            refLineItems.current?._setLoading(false);
        };
        dispatch(getListUserCommission({}, _success, _fail));
    };

    const _getLineItems = () => {
        setDisableButtonSave(true);
        refLineItems.current._getLineItems(true);
    };

    useImperativeHandle(ref, () => ({
        _onSaveSetup: (callback) => refLineItems.current._onSaveSetup(callback)
    }));

    const _updateOptions = ({ userId, value, keyword }) => {
        const newState = {};
        if (userId === userSelected.id) {
            newState.userSelected = _getStatusActive({ user: userSelected, value, keyword });
        }
        newState.users = users.map((item) => {
            if (item.id === userId) return _getStatusActive({ user: item, value, keyword });
            return item;
        });

        dispatchState(newState);

        (!isFirstTime || !isFirstTimeUsers) && dispatch(resetCommissionData());

        clientQuery(getUrlOptionsCommmissionUser(userId), { method: 'PUT', data: { value, keyword } });
    };

    const _getStatusActive = ({ user, value, keyword }) => {
        switch (keyword) {
            case TYPES_OPTION_ACTIVE.PRODUCTION:
                user.production_active = value;
                break;
            case TYPES_OPTION_ACTIVE.SALE:
                user.sale_active = value;
                break;
            case TYPES_OPTION_ACTIVE.USER:
                user.active = value;
                break;
            default:
                break;
        }
        return user;
    };

    const _selectUser = (userSelected) => {
        dispatchState({ userSelected });
    };

    return (
        <div className="wrap-commission__content content-setup">
            <div className="setup-tracking">
                <UserListCommission
                    getLineItems={_getLineItems}
                    updateOptions={_updateOptions}
                    selectUser={_selectUser}
                    {...state}
                />
                <LineItemsCommission
                    ref={refLineItems}
                    updateOptions={_updateOptions}
                    userSelected={userSelected}
                    setDisableButtonSave={setDisableButtonSave}
                />
            </div>
        </div>
    );
};
export default forwardRef(ManageCommission);
