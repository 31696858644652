export default function IconCommission({ isMenuActive = false, inactive }) {
    if (inactive)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M14.282 0h3.436c4.028 0 6.188.557 8.143 1.603 1.956 1.046 3.49 2.58 4.536 4.536C31.443 8.094 32 10.254 32 14.282v3.436c0 4.028-.557 6.188-1.603 8.143-1.046 1.956-2.58 3.49-4.536 4.536C23.906 31.443 21.746 32 17.718 32h-3.436c-4.028 0-6.188-.557-8.143-1.603-1.956-1.046-3.49-2.58-4.536-4.536C.557 23.906 0 21.746 0 17.718v-3.436c0-4.028.557-6.188 1.603-8.143 1.046-1.956 2.58-3.49 4.536-4.536C8.094.557 10.254 0 14.282 0z"
                                        transform="translate(-914 -289) translate(818 120) translate(0 169) translate(96)"
                                    />
                                    <path
                                        fill="#C1C9D4"
                                        d="M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4zm-4 4c-2.21 0-4 1.79-4 4s1.79 4 4 4c.217 0 .43-.017.637-.05l-4.001 4c-.781.78-.781 2.047 0 2.828l.707.707c.781.781 2.048.781 2.829 0l3.844-3.845c-.01.119-.016.239-.016.36 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4c-.121 0-.241.005-.36.016l3.138-3.137c.781-.781.781-2.048 0-2.829l-.707-.707c-.781-.78-2.047-.78-2.828 0l-3.294 3.294c.034-.207.051-.42.051-.637 0-2.21-1.79-4-4-4z"
                                        transform="translate(-914 -289) translate(818 120) translate(0 169) translate(96)"
                                    />
                                    <path
                                        fill="#929BA9"
                                        d="M20 17c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm1.364-6.364c.39.39.39 1.024 0 1.414l-9.9 9.9c-.39.39-1.023.39-1.414 0-.39-.39-.39-1.024 0-1.414l9.9-9.9c.39-.39 1.023-.39 1.414 0zM20 18.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5zM12 9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 1.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"
                                        transform="translate(-914 -289) translate(818 120) translate(0 169) translate(96)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );

    if (isMenuActive) {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 16C0 12.2801 0 10.4202 0.40889 8.89417C1.5185 4.75307 4.75307 1.5185 8.89417 0.40889C10.4202 0 12.2801 0 16 0V0C19.7199 0 21.5798 0 23.1058 0.40889C27.2469 1.5185 30.4815 4.75307 31.5911 8.89417C32 10.4202 32 12.2801 32 16V16C32 19.7199 32 21.5798 31.5911 23.1058C30.4815 27.2469 27.2469 30.4815 23.1058 31.5911C21.5798 32 19.7199 32 16 32V32C12.2801 32 10.4202 32 8.89417 31.5911C4.75307 30.4815 1.5185 27.2469 0.40889 23.1058C0 21.5798 0 19.7199 0 16V16Z"
                    fill="#2BA062"
                />
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 5C22.0751 5 27 9.92487 27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5ZM12.3333 8.66667C10.3083 8.66667 8.66667 10.3083 8.66667 12.3333C8.66667 14.3584 10.3083 16 12.3333 16C12.5322 16 12.7274 15.9842 12.9177 15.9537L9.2497 19.6206C8.53374 20.3366 8.53374 21.4974 9.2497 22.2133L9.89788 22.8615C10.6138 23.5775 11.7746 23.5775 12.4906 22.8615L16.0146 19.3367C16.005 19.4454 16 19.5555 16 19.6667C16 21.6917 17.6416 23.3333 19.6667 23.3333C21.6917 23.3333 23.3333 21.6917 23.3333 19.6667C23.3333 17.6416 21.6917 16 19.6667 16C19.5555 16 19.4454 16.005 19.3367 16.0146L22.2133 13.1388C22.9293 12.4228 22.9293 11.262 22.2133 10.5461L21.5651 9.89788C20.8492 9.18192 19.6884 9.18192 18.9724 9.89788L15.9537 12.9177C15.9842 12.7274 16 12.5322 16 12.3333C16 10.3083 14.3584 8.66667 12.3333 8.66667Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.4286 16.8571C20.8487 16.8571 22 18.0084 22 19.4286C22 20.8487 20.8487 22 19.4286 22C18.0084 22 16.8571 20.8487 16.8571 19.4286C16.8571 18.0084 18.0084 16.8571 19.4286 16.8571ZM20.5977 11.4023C20.9324 11.7371 20.9324 12.2798 20.5977 12.6145L12.1124 21.0998C11.7777 21.4345 11.235 21.4345 10.9002 21.0998C10.5655 20.765 10.5655 20.2223 10.9002 19.8876L19.3855 11.4023C19.7202 11.0676 20.2629 11.0676 20.5977 11.4023ZM19.4286 18.1429C18.7185 18.1429 18.1429 18.7185 18.1429 19.4286C18.1429 20.1387 18.7185 20.7143 19.4286 20.7143C20.1387 20.7143 20.7143 20.1387 20.7143 19.4286C20.7143 18.7185 20.1387 18.1429 19.4286 18.1429ZM12.5714 10C13.9916 10 15.1429 11.1513 15.1429 12.5714C15.1429 13.9916 13.9916 15.1429 12.5714 15.1429C11.1513 15.1429 10 13.9916 10 12.5714C10 11.1513 11.1513 10 12.5714 10ZM12.5714 11.2857C11.8613 11.2857 11.2857 11.8613 11.2857 12.5714C11.2857 13.2815 11.8613 13.8571 12.5714 13.8571C13.2815 13.8571 13.8571 13.2815 13.8571 12.5714C13.8571 11.8613 13.2815 11.2857 12.5714 11.2857Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="white"
            />
            <path
                opacity="0.6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C12.217 16 12.4299 15.9827 12.6375 15.9495L8.63604 19.9497C7.85499 20.7308 7.85499 21.9971 8.63604 22.7782L9.34315 23.4853C10.1242 24.2663 11.3905 24.2663 12.1716 23.4853L16.016 19.6401C16.0054 19.7586 16 19.8787 16 20C16 22.2091 17.7909 24 20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C19.8787 16 19.7586 16.0054 19.6401 16.016L22.7782 12.8787C23.5592 12.0976 23.5592 10.8313 22.7782 10.0503L22.0711 9.34315C21.29 8.5621 20.0237 8.5621 19.2426 9.34315L15.9495 12.6375C15.9827 12.4299 16 12.217 16 12C16 9.79086 14.2091 8 12 8Z"
                fill="#2BA062"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 17C21.6569 17 23 18.3431 23 20C23 21.6569 21.6569 23 20 23C18.3431 23 17 21.6569 17 20C17 18.3431 18.3431 17 20 17ZM21.364 10.636C21.7545 11.0266 21.7545 11.6597 21.364 12.0503L11.4645 21.9497C11.0739 22.3403 10.4408 22.3403 10.0503 21.9497C9.65973 21.5592 9.65973 20.9261 10.0503 20.5355L19.9497 10.636C20.3403 10.2455 20.9734 10.2455 21.364 10.636ZM20 18.5C19.1716 18.5 18.5 19.1716 18.5 20C18.5 20.8284 19.1716 21.5 20 21.5C20.8284 21.5 21.5 20.8284 21.5 20C21.5 19.1716 20.8284 18.5 20 18.5ZM12 9C13.6569 9 15 10.3431 15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9ZM12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5Z"
                fill="#2BA062"
            />
        </svg>
    );
}
