import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { addBranchPath } from 'app/const/Branch';
import { ADDONS_MULTIPLE_BRANCH_PLAN } from 'app/config/routes';
import IconPlus from 'assets/icon/IconPlus';
import { reducer } from 'app/const/Reducer';

const ManageMultipleBranchHeader = (props, ref) => {
    const { t } = useTranslation('addons');

    const [state, dispatchState] = useReducer(reducer, {
        isDisableButton: true
    });
    const { isDisableButton } = state;

    useImperativeHandle(ref, () => ({
        setDisableBtn: _handleSetDisableButton
    }));

    const _handleSetDisableButton = (value = true) => {
        dispatchState({ isDisableButton: value });
    };

    return (
        <div className="header flex-betweenitems align-center black pt-0">
            <p className="fw-600">{t('manage_multiple_branch')}</p>
            <div className="flexcenter gap-8">
                <Link
                    to={addBranchPath(ADDONS_MULTIPLE_BRANCH_PLAN)}
                    className={classNames('v2-btn-main has-icon svg-white', { 'is-disable': isDisableButton })}
                >
                    <IconPlus /> {t('add_branch')}
                </Link>
            </div>
        </div>
    );
};

export default forwardRef(ManageMultipleBranchHeader);
