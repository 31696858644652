import React, { useContext, useRef } from 'react';
import IconPlus from 'assets/icon/IconPlus';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ContactForm from 'app/modules/customer/detail/contacts/components/ContactForm';
import LoadingContacts from './components/LoadingContacts';
import { createContact, updateContact } from 'common/redux/actions/customers/contactAction';
import { useDispatch } from 'react-redux';
import AlertCustomer from '../../components/AlertCustomer';
import { LIST_STATUS } from 'app/const/App';
import GdListPhone from 'app/components/phone/ListPhone';
import { transformToCustomerType } from 'app/modules/customer/utils';
import { _handelSortContacts } from '../context/utils';
import { _getDefaultListPhones } from '../../utils';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { TYPE_OPEN_SMS } from 'app/const/Sms';

const CustomerContact = () => {
    const { id: customerId } = useParams();
    const { contacts: contactContext, _handleUpdateContacts } = useContext(CustomerDetailContext);
    const isLoading = !contactContext;
    const refContactForm = useRef(null);
    const refAlert = useRef(null);
    const { t } = useTranslation(['customers']);
    const dispatch = useDispatch();

    const handleAddContact = () => {
        refContactForm.current.showModal();
    };

    const handleEditContact = (contact) => {
        const contactId = contact.customer?.id;
        const contactSelected = contactContext.find((item) => item.customer?.id === contactId);
        if (contactSelected) {
            const newObjectContact = {
                ...contactSelected,
                phones: _getDefaultListPhones(contactSelected.phones)
            };
            refContactForm.current.showModal(newObjectContact);
        }
    };

    const convertToNewContact = (data) => {
        const { id, phones, email } = data;
        return {
            id: id,
            customer: transformToCustomerType(data),
            email: email,
            title: data.title || '',
            phones: phones.map((phone, index) => {
                return {
                    contact_id: id,
                    id: index.toString(),
                    ...phone
                };
            })
        };
    };

    const _handleFail = ({ message }) => {
        refContactForm.current?.closeModal();
        refAlert.current?.showStatusBar({
            id: 'fail',
            message: message.length ? message : t('customers:action_failure'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleSuccess = ({ data, message }, type = 'create') => {
        const { first_name, last_name } = data;
        const newValue = convertToNewContact(data);
        if (type === 'create') {
            _handleUpdateContacts(_handelSortContacts([...(contactContext || []), newValue]));
        } else {
            const newListContact = contactContext.map((contact) => {
                if (contact.id === data.id) return newValue;
                return contact;
            });
            _handleUpdateContacts(_handelSortContacts(newListContact));
        }

        refContactForm.current.closeModal();
        refAlert.current.showStatusBar({
            id: 'success',
            message: message.length
                ? message
                : t('customers:save_customer_success', { name: first_name.concat(' ', last_name).trim() }),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleSaveContact = () => {
        const paramsContact = refContactForm.current.getDataFrom();
        if (!paramsContact) return false;
        paramsContact['customer_id'] = customerId;
        if (paramsContact.contact_id) {
            dispatch(updateContact(paramsContact, (res) => _handleSuccess(res, 'update'), _handleFail));
        } else {
            dispatch(createContact(paramsContact, (res) => _handleSuccess(res, 'create'), _handleFail));
        }
    };

    const _renderContactsList = () => {
        return contactContext.map((contact) => {
            const { id, customer, phones, title = '' } = contact;
            // ID = 0 is primary contact
            if (+contact?.id === 0) return null;
            return (
                <div className="rows mt-0" key={id}>
                    <div className="contact-name w-100 btn-modal" onClick={() => handleEditContact(contact)}>
                        <div className="valid-name">
                            <p className="valid-name__title --purple">{customer.full_name}</p>
                            {title ? <div className="label-content">{title}</div> : null}
                        </div>
                        <div className="contact-name__detail flex-column">
                            <GdListPhone data={phones} isPreview typeOpen={TYPE_OPEN_SMS.CUSTOMER} />
                        </div>
                    </div>
                </div>
            );
        });
    };

    if (isLoading) return <LoadingContacts />;
    return (
        <div className="page-wrapper flex-auto form-contact form-inner">
            <div className="head-main flex-betweenitems">
                <h3 className="title-font15">{t('customers:additional_contacts')}</h3>
                <div className="v2-btn-main --bg-green --icon-lg svg-white btn-modal" onClick={handleAddContact}>
                    <IconPlus />
                </div>
            </div>
            <AlertCustomer ref={refAlert} />
            {_renderContactsList()}

            <ContactForm ref={refContactForm} onSaveContact={_handleSaveContact} customerId={customerId} isPreview />
        </div>
    );
};

export default CustomerContact;
