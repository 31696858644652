export const TYPE_SETTING_SYSTEMS = {
  GET_LIST: "SETTING_GET_LIST_SYSTEMS",
  UPDATE: "SETTING_UPDATE_SYSTEMS",
  UPDATE_STATUS: "SETTING_UPDATE_STATUS_SYSTEMS",
  DETAIL: "SETTINGS_DETAIL_SYSTEMS",
};

// actions
export const getListSettingSystems = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_SYSTEMS.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateSystems = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_SYSTEMS.UPDATE,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const updateStatusSettingSystems = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_SYSTEMS.UPDATE_STATUS,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const getDetailSystems = (
  params,
  actionSuccess,
  actionFailed = () => {}
) => {
  return {
    type: TYPE_SETTING_SYSTEMS.DETAIL,
    params,
    actionSuccess,
    actionFailed,
  };
};

