import React, { useReducer, useRef, useEffect } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { STATUS } from 'app/modules/calendar/const/SideBar';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { reducer } from 'app/const/Reducer';

const Options = ({ openFormEdit, finalStatus, onDelete, taskId, textTarget = 'Task' }) => {
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;

    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_task_options');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_task_options');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    }

    function _handleEditTask(e) {
        e.stopPropagation();
        openFormEdit();
        setState({ isVisible: false });
    }

    function _handleDelte(e) {
        e.stopPropagation();
        onDelete(taskId);
    }

    function _renderOptions() {
        if (!finalIsVisible) {
            return false;
        }

        return (
            <div className="v2-dropdown__menu scrolls" id={'show_list_task_options'}>
                <ul>
                    {finalStatus !== STATUS.DONE && (
                        <li className="items has-icon js-task-edit" onClick={_handleEditTask}>
                            <IconEdit />
                            <p className="txt-ellipsis">Edit {textTarget}</p>
                        </li>
                    )}
                    <li className="items has-icon red-default" onClick={_handleDelte}>
                        <IconTrash />
                        <p className="txt-ellipsis">Delete {textTarget}</p>
                    </li>
                </ul>
            </div>
        );
    }

    return (
        <div className={`v2-dropdown v2-dropdown--more ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
            <div onClick={_handleOpen} className="dropbtn v2-btn-default --transparent --icon-lg">
                <IconThreeDots />
            </div>
            {finalIsVisible && _renderOptions()}
        </div>
    );
};

export default Options;
