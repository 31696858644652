import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken, deleteWithToken, formApi } from 'common/utils/ApiUtils';
import {
    DRIP_GET_LIST_MESSAGE_TEMPLATES,
    DRIP_GET_DETAIL_TEMPLATES,
    DRIP_UPDATE_MESSAGE_TEMPLATE,
    DRIP_CREATE_MESSAGE_TEMPLATE,
    DRIP_SEND_TEST_EMAIL,
    DRIP_DELETE_MESSAGE_TEMPLATE,
    DRIP_DUPLICATE_MESSAGE_TEMPLATE,
    DRIP_GET_LIST_VARIABLE
} from 'app/const/Api';

import { TYPE_DRIP_MESSAGE_TEMPLATES } from 'common/redux/actions/drip/messageTemplatesAction';

export function* getListMessageTemplates({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_MESSAGE_TEMPLATES, params);
        if (response && response.success) {
            const newData = [...response.data];
            newData.forEach((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDetailTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_DETAIL_TEMPLATES, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateMessageTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIP_UPDATE_MESSAGE_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createMessageTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, DRIP_CREATE_MESSAGE_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* sendTestEmail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, DRIP_SEND_TEST_EMAIL, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteMessageTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, DRIP_DELETE_MESSAGE_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* duplicateMessageTemplate({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(formApi, DRIP_DUPLICATE_MESSAGE_TEMPLATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListVariableTemplate({ params, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_VARIABLE, params);
        if (response && response.success) {
            const newData = [...response.data];
            newData.forEach((item) => {
                item.value = item.variable;
            });

            yield put({ type: TYPE_DRIP_MESSAGE_TEMPLATES.GET_LIST_VARIABLE_SUCCESS, payload: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
