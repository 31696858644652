import React, { useState, useImperativeHandle, forwardRef } from 'react';
import CalendarDatePicker from 'app/modules/calendar/header/components/date/DatePicker';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';

const Date = forwardRef(({ defaultValue }, ref) => {
    const [selected, setSelected] = useState(defaultValue || convertTimeToISO(moment()));

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return selected;
        },
        resetValue: () => {
            setSelected(convertTimeToISO(moment()));
        }
    }));

    function _handleChangeDate(dateSelect) {
        setSelected(convertTimeToISO(moment(dateSelect).startOf('day')) || null);
    }

    return (
        <div className="col">
            <p className="txt">Date</p>
            <CalendarDatePicker onChange={_handleChangeDate} selectDefault={selected} />
        </div>
    );
});

export default Date;
