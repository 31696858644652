import {
    REPORT_BOOKING_JOBS_GET_LIST,
    REPORT_CUSTOMER_LEADS_GET_LIST,
    REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_LINE,
    REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_PIE,
    REPORT_INBOUND_LEADS_GET_LIST_YEARS_CHART,
    REPORT_ONLINE_BOOKING_GET_LIST_DATA_CHART_LINE,
    REPORT_ONLINE_BOOKING_GET_LIST_YEARS_CHART
} from 'app/const/Api';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportCustomerLeads({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_CUSTOMER_LEADS_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListReportBookingJobs({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_BOOKING_JOBS_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListDataOnlineBookingsChart({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_ONLINE_BOOKING_GET_LIST_DATA_CHART_LINE, params);
        if (response && response.success) {
            response.year = params.year;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListDataInboundLeadsChart({ params, actionSuccess, actionFailed }) {
    try {
        const responseLineChart = yield call(fetchWithToken, REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_LINE, params);
        const responsePieChart = yield call(fetchWithToken, REPORT_INBOUND_LEADS_GET_LIST_DATA_CHART_PIE, params);
        const response = {
            dataLineChart: responseLineChart?.data,
            dataPieChart: responsePieChart?.data,
            year: params.year,
            success: responseLineChart.success || responsePieChart.success
        };

        if (response.success) {
            const totalPieChart = response.dataPieChart.datasets.reduce((a, b) => a + b, 0);
            response.dataPieChart.datasets = response.dataPieChart.datasets.map((value) => {
                const percent = value / (totalPieChart / 100);
                return percent.toFixed(1);
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListYearsInboundLeadsChart({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_INBOUND_LEADS_GET_LIST_YEARS_CHART);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
export function* getListYearsOnlineBookingsChart({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_ONLINE_BOOKING_GET_LIST_YEARS_CHART);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
