import classNames from 'classnames';
import React, { useContext } from 'react';

import GDCollapse from 'app/components/collapse';
import { CLASS_NAME_OPEN_LOCATION } from 'app/components/dropdown/constant';
import { convertLinkInString } from 'common/utils/StringUtils';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { displayLocation, eventLocationDropdown, getQueryLocationMap } from '../../locations/utils';
import LocationTags from './LocationTags';

const LocationsCollapse = () => {
    const { locations } = useContext(CustomerDetailContext);
    if (!Array.isArray(locations) || !locations.length) return;
    const shouldExpandFirst = locations.length === 1;
    // Note: If there is only one location, it will be expanded by default
    return locations.map((location, index) => (
        <CollapseItem key={location.id} shouldExpandFirst={shouldExpandFirst && index === 0} {...location} />
    ));
};

const CollapseItem = ({
    shouldExpandFirst = false,
    name = '',
    id = '',
    location_note = '',
    street1 = '',
    street2 = '',
    state = '',
    zip = '',
    city = '',
    billing_address_1 = '',
    billing_address_2 = '',
    billing_state = '',
    billing_zip = '',
    billing_city = '',
    lat = '',
    lng = ''
}) => {
    const _handleLocationClick = (target) => {
        eventLocationDropdown({
            target,
            address: getQueryLocationMap({ street1, street2, state, zip, city }),
            dataLatLng: { lat, lng }
        });
    };

    return (
        <GDCollapse
            title={name}
            isUp
            isArrowRight
            defaultCollapsed={shouldExpandFirst}
            className="details-info wrap-location wrap-collapse"
            classWrapTitle="wrap-item__header flex-betweenitems"
            classTitle="txt-ellipsis flex-1"
        >
            <div className="wrap-collapse__details flex-column gap-10">
                {street1 || billing_address_1 ? (
                    <div className="address word-break flex-column gap-6">
                        {street1 ? (
                            <LocationDisplay
                                title="Service Address"
                                isService
                                location={{ street1, street2, state, zip, city }}
                                onClick={_handleLocationClick}
                            />
                        ) : null}
                        {billing_address_1 ? (
                            <LocationDisplay
                                title="Billing Address"
                                location={{
                                    street1: billing_address_1,
                                    street2: billing_address_2,
                                    state: billing_state,
                                    zip: billing_zip,
                                    city: billing_city
                                }}
                            />
                        ) : null}
                    </div>
                ) : null}
                {!!location_note ? (
                    <GDCollapse title="Location Note" isArrowRight defaultCollapsed>
                        <div
                            className="note-details wrap-collapse__details p-0"
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{ __html: convertLinkInString(location_note) }}
                        />
                    </GDCollapse>
                ) : null}
                <LocationTags idLocation={id} />
            </div>
        </GDCollapse>
    );
};

const LocationDisplay = ({ title = '', isService = false, location = {}, onClick = () => {} }) => {
    return (
        <>
            <h4 className="title-sx">{title}</h4>
            <p
                className={classNames('address__info', CLASS_NAME_OPEN_LOCATION, {
                    'purple-default cursor-pointer': isService
                })}
                onClick={onClick}
            >
                {displayLocation({
                    street1: location.street1 || '',
                    street2: location.street2 || '',
                    city: location.city || '',
                    state: location.state || '',
                    zip: location.zip || ''
                })}
            </p>
        </>
    );
};

export default LocationsCollapse;
