import React from 'react';

import IconSingleStep from 'assets/icon/IconSingleStep';
import { useFilters } from '../../context/addFilterContext';
import Subcategories from './Subcategories';

const WrapperSubcategories = ({ title, subcategories = [] }) => {
    const { handleCategoryId, isEditing } = useFilters();
    return (
        <div className="filter-result scrolls">
            <div className="filter-result__title">
                {isEditing ? null : (
                    <div className="v2-btn-default --transparent --icon-sm" onClick={() => handleCategoryId(null)}>
                        <IconSingleStep isPrev />
                    </div>
                )}
                <span className="txt-ellipsis">{title}</span>
            </div>
            <Subcategories subcategories={subcategories} />
        </div>
    );
};

export default WrapperSubcategories;
