/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useContext, useEffect } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';

const RealtimeTasksServices = ({ customerId, jobId, onUpdateCallback = () => {} }) => {
    const { taskCreate, taskUpdate, taskDelete, taskStatus } = useContext(SocketContext);

    useEffect(() => {
        global.customer_time_line_task_service = `customer_time_line_task_${customerId || jobId}_${Date.now()}`;

        return () => {
            global.customer_time_line_task_service = '';
        };
    }, [customerId, jobId]);

    useEffect(() => {
        if (!!!taskCreate && !!!taskDelete && !!!taskUpdate && !!!taskStatus) return;

        const globalSocketId = global.customer_time_line_task_service;

        /* Checking if the socket id is the same as the global socket id. Then break */
        if (
            globalSocketId === taskCreate?.socket_id ||
            globalSocketId === taskDelete?.socket_id ||
            globalSocketId === taskUpdate?.socket_id ||
            globalSocketId === taskStatus?.socket_id
        ) {
            return;
        }

        if (taskCreate && _handleCheckCorrectId(taskCreate.data)) {
            onUpdateCallback((prevState) => ({
                ...prevState,
                dataTimeLine: [...[taskCreate.data], ...prevState.dataTimeLine]
            }));
        }
        if (taskUpdate && _handleCheckCorrectId(taskUpdate.data)) _handleUpdate(taskUpdate.data);
        if (taskStatus) _handleUpdate(taskStatus.data);

        if (taskDelete) {
            onUpdateCallback((prevState) => ({
                ...prevState,
                dataTimeLine: [...prevState.dataTimeLine].filter((item) => !taskDelete.data.includes(item.id))
            }));
        }
    }, [taskCreate, taskUpdate, taskDelete, taskStatus]);

    /**
     * If the jobId is defined, check if the job id matches the jobId, otherwise check if the customer id
     * matches the customerId
     * @param data - The data object that is returned from the API.
     * @returns a boolean value.
     */
    const _handleCheckCorrectId = (data) => {
        if (jobId) return data?.job?.id === jobId;
        return data?.customer?.id === customerId;
    };

    /**
     * _handleUpdate is a function that takes in a newData parameter and if newData is an array, it will
     * call onUpdateCallback with a function that takes in a prevState parameter and returns an object
     * with a dataTimeLine property that is an array of objects that are the result of mapping over the
     * prevState.dataTimeLine array and returning an object that is the result of spreading the item and
     * the newData object
     * @param newData - The new data to be added to the timeline.
     */
    const _handleUpdate = (newData) => {
        if (Array.isArray(newData)) {
            onUpdateCallback((prevState) => {
                const result = [...prevState.dataTimeLine];

                newData.forEach((item) => {
                    const index = result.findIndex((data) => data.id === item.id);

                    if (index !== -1) {
                        result[index] = {
                            ...result[index],
                            ...item
                        };
                    }
                });

                return { ...prevState, dataTimeLine: result };
            });
        } else {
            onUpdateCallback((prevState) => ({
                ...prevState,
                dataTimeLine: [...prevState.dataTimeLine].map((item) => {
                    if (item.id === newData.id) return { ...item, ...newData };
                    return item;
                })
            }));
        }
    };

    return null;
};

export default RealtimeTasksServices;
