import loadable from '@loadable/component';
import React, { Fragment, Suspense, useEffect, useReducer, useRef } from 'react';

const Header = loadable(() => import('app/modules/customer/detail/timeline/header'));
const TopNote = loadable(() => import('app/modules/customer/topnote/detail'));
const AddNote = loadable(() => import('app/modules/customer/note/add'));
const AddTask = loadable(() => import('app/modules/customer/task/add'));
const AddEmail = loadable(() => import('app/modules/customer/email/add'));
const NoteDetail = loadable(() => import('app/modules/customer/note/detail'));
const TaskDetail = loadable(() => import('app/modules/customer/task/detail'));
const Log = loadable(() => import('app/modules/customer/log'));
const EmailInBox = loadable(() => import('app/modules/customer/email/detail'));
const RealtimeServices = loadable(() => import('app/modules/customer/detail/timeline/service/Realtime'));
const CustomerLog = loadable(() => import('../../log/Customer'));
const RealtimeTasksServices = loadable(() => import('./service/RealtimeTask'));
const Calls = loadable(() => import('app/modules/customer/calls'));

import { getCustomerTimeLine } from 'app/const/Api';
import { AVATAR_DEFAULT, DEFAULT_ALL } from 'app/const/App';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { CUSTOMER_TYPE_IME_TILE, KEY_LOCATION_FILTER_SELECTED } from 'app/modules/customer/const';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import { TYPE_TASK } from 'app/modules/customer/const/Task';
import LoadingTimeLine from 'app/modules/customer/detail/timeline/Loading';
import CallWithVoip from 'app/services/voip/CallWithVoip';
import { updateSettings } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CallNote from '../../callnote';
import AddCallLog from '../../callnote/Add';
import { TYPE_CALL_TIME_LINE } from '../../const/Call';
import ServiceCall from './service/ServiceCall';
import ServiceCustomer from './service/ServiceCustomer';
import IconACH from 'assets/icon/IconACH';
import { handleAbortController } from '../../utils';
import ModalDeleteRecording from '../../calls/ModalDeleteRecording';

const LIMIT_LOAD_ITEMS = 20;

const CustomerTimeLine = () => {
    const { permissions: userPermissions, settings: userSettings, profile } = useSelector(({ auth }) => auth.user);

    const [state, dispatchAction] = useReducer(reducer, {
        isLoading: true,
        isLoadingMore: false,
        dataTimeLine: [],
        showMore: false,
        filters: userSettings.timeline_filters || '-1',
        topNote: null,
        dateFilter: null
    });

    const dispatch = useDispatch();

    const permissionsList = userPermissions?.enabled || [];
    const isHavePermissionNote = checkPermission(permissionsList, PERMISSIONS.editOrDeleteNotes);
    const isHavePermissionComment = checkPermission(permissionsList, PERMISSIONS.deleteComments);
    const isHavePermissionInbox = checkPermission(permissionsList, PERMISSIONS.viewEmailInbox);

    const { id: customerId } = useParams();

    const location = useLocation();
    const { noteId, isOnlyNote, isFromCall } = location.state || {};

    const refAddNote = useRef(null);
    const refAddTask = useRef(null);
    const refAddEmail = useRef(null);
    const refShowLoading = useRef(null);
    const refServiceCustomer = useRef(null);
    const refOldLocation = useRef(getLocalStorage(KEY_LOCATION_FILTER_SELECTED)?.location_ids || -1);
    const refCallWithVoip = useRef(null);
    const refFormAddCallLog = useRef(null);
    const refOffset = useRef(0);
    const abortController = useRef(null);
    const refDeleteRecording = useRef(null);

    const {
        isLoadingMore,
        showMore: isShowMore,
        isLoading: finalIsLoading,
        dataTimeLine: finalDataTimeLine,
        filters: finalFilters,
        dateFilter,
        topNote: finalTopNote
    } = state;

    const customerDetail = finalTopNote?.customer || {};

    const defaultParams = {
        limit: LIMIT_LOAD_ITEMS,
        offset: 0,
        filters: finalFilters.toString(),
        // location_ids,
        ...dateFilter
    };

    useEffect(() => {
        if ((noteId || customerId) && !finalIsLoading) {
            dispatchAction({ isLoading: true, dataTimeLine: [] });
        }

        const paramsLocal = getLocalStorage(KEY_LOCATION_FILTER_SELECTED) || {};
        const params = {
            limit: LIMIT_LOAD_ITEMS,
            offset: 0,
            filters: userSettings.timeline_filters || '-1',
            fields: 'top_note,open_tasks',
            notify_id: noteId
        };

        if (paramsLocal?.customer_id === customerId) params['location_ids'] = paramsLocal?.location_ids || DEFAULT_ALL;
        _getCustomerTimeLine(params);

        return () => {
            handleAbortController(abortController);
        };
    }, [noteId, customerId]);

    const _handleTriggerReload = (newLocationIds, flagReload) => {
        const canReload = newLocationIds && newLocationIds !== refOldLocation.current && !finalIsLoading;
        if (canReload || flagReload) {
            dispatchAction({ isLoading: true, isLoadingMore: false, dataTimeLine: [] });
            refOldLocation.current = newLocationIds;
            _getCustomerTimeLine({ ...defaultParams, location_ids: refServiceCustomer.current?.getLocationIds });
        }
    };

    function _getCustomerTimeLine(params = null, isLoadMore = false) {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        if (isLoadMore) dispatchAction({ isLoadingMore: isLoadMore });
        refOffset.current = params?.offset || 0;
        if (isLoadingMore) return;
        clientQuery(
            getCustomerTimeLine(customerId),
            { method: 'GET', data: params, toFormData: false, abortController: abortController.current },
            _getCustomerTimeLineSuccess
        );
    }

    function _getCustomerTimeLineSuccess(response) {
        dispatchAction((prev) => {
            return {
                ...prev,
                isLoading: false,
                isLoadingMore: false,
                dataTimeLine: [...prev.dataTimeLine, ...response.data],
                showMore: response.show_more,
                topNote: response.top_note || prev.topNote
            };
        });

        refShowLoading.current && refShowLoading.current.removeLoading();
    }

    function _handleShow(type) {
        switch (type) {
            case CUSTOMER_TYPE_IME_TILE.NOTE:
                refAddTask.current.checkIsOpen();
                refAddNote.current.toggleShowHide();
                break;
            case CUSTOMER_TYPE_IME_TILE.TASK:
                refAddNote.current.checkIsOpen();
                refAddTask.current.toggleShowHide();
                break;
            case CUSTOMER_TYPE_IME_TILE.EMAIL_INBOX:
                refAddEmail.current.toggleShowHide();
                break;

            default:
                break;
        }
    }

    function _handleOnScrollContent(e) {
        if (isScrollToEndBottom(e.target, 50)) _handleLoadMore();
    }

    const _handleLoadMore = () => {
        if (finalIsLoading || isLoadingMore || !isShowMore) return;
        const dataFetch = {
            ...defaultParams,
            offset: refOffset.current + LIMIT_LOAD_ITEMS,
            location_ids: refServiceCustomer.current?.getLocationIds
        };
        _getCustomerTimeLine(dataFetch, true);
    };

    const _handleClearAllFilter = () => {
        dispatchAction({
            dataTimeLine: [],
            filters: DEFAULT_ALL.toString(),
            dateFilter: null,
            isLoading: true,
            isLoadingMore: false
        });

        const newParams = { ...defaultParams, filters: DEFAULT_ALL.toString() };
        delete newParams.start;
        delete newParams.end;
        dispatch(updateSettings({ timeline_filters: DEFAULT_ALL.toString() }));
        _getCustomerTimeLine(newParams);
    };

    function _handleChangeFilter(filter) {
        dispatchAction({
            dataTimeLine: [],
            filters: filter,
            isLoading: true,
            isLoadingMore: false
        });

        dispatch(updateSettings({ timeline_filters: filter.toString() }));

        _getCustomerTimeLine({
            ...defaultParams,
            filters: filter.toString(),
            location_ids: refServiceCustomer.current?.getLocationIds
        });
    }

    function _handleUpdateItem(newData) {
        dispatchAction((prev) => {
            return {
                ...prev,
                dataTimeLine: prev.dataTimeLine.map((item) => {
                    if (item.id === newData.id) {
                        return {
                            ...item,
                            ...newData
                        };
                    }
                    return item;
                })
            };
        });
    }

    function _handleUpdateCallback(callBack) {
        dispatchAction((prevState) => {
            return callBack(prevState);
        });
    }

    function _handleAddNewItem(newData) {
        dispatchAction((prevState) => {
            return {
                ...prevState,
                dataTimeLine: [...[newData], ...prevState.dataTimeLine]
            };
        });
    }

    function _handleDeleteItem(id) {
        dispatchAction((prevState) => {
            const newValue = [...prevState.dataTimeLine];
            return {
                ...prevState,
                dataTimeLine: newValue.filter((item) => item.id !== id)
            };
        });
    }

    function _handleDeleteAttachment(idAttach, idNote) {
        dispatchAction((prevState) => {
            let newDataTimeLine = [...prevState.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === idNote) {
                    return {
                        ...item,
                        attachments: [...item.attachments].filter((item) => item.id !== idAttach)
                    };
                }
                return item;
            });

            return {
                ...prevState,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleCleanQuickAdd(newId, oldId) {
        dispatchAction((prevState) => {
            let newDataTimeLine = [...prevState.dataTimeLine];
            newDataTimeLine = newDataTimeLine.map((item) => {
                if (item.id === oldId) {
                    return {
                        ...item,
                        id: newId,
                        quick_add: false
                    };
                }
                return item;
            });

            return {
                ...prevState,
                dataTimeLine: newDataTimeLine
            };
        });
    }

    function _handleUpdateTopNote(newData) {
        dispatchAction({
            topNote: newData
        });
    }

    function _handleSelectDate(date) {
        dispatchAction({
            dataTimeLine: [],
            dateFilter: date,
            isLoading: true,
            isLoadingMore: false
        });

        let newParams = { ...defaultParams, location_ids: refServiceCustomer.current?.getLocationIds };
        if (!date) {
            delete newParams.start;
            delete newParams.end;
        } else {
            newParams = { ...newParams, ...date };
        }

        _getCustomerTimeLine(newParams);
    }

    const _convertCallLog = (newCall) => {
        return {
            id: newCall.id || uuidv4(),
            assignee: !newCall.user_id
                ? {
                      avatar: AVATAR_DEFAULT,
                      full_name: 'Group Number'
                  }
                : {
                      id: profile.id,
                      first_name: profile.first_name,
                      last_name: profile.last_name,
                      full_name: profile.first_name.concat(' ', profile.last_name),
                      avatar: profile.avatar
                  },
            customer_id: customerId,
            call_id: newCall.call_id,
            call_type: newCall.call_type,
            caller: newCall.from,
            receiver: newCall.to,
            call_status: newCall.call_status,
            isNew: newCall.isNew,
            duration: newCall.duration || 0,
            recording: null,
            created_date: {
                value: newCall.created_date || moment().toISOString()
            },
            type: 'call',
            note: newCall.note || ''
        };
    };

    /**
     * Only outgoing call is open form edit note
     */
    const _handleAddCallLog = (data) => {
        const { id: serviceCallId, call_type: callType } = data;

        const findNoteCurrent = finalDataTimeLine.some(
            (item) => item.type === CUSTOMER_TYPE_IME_TILE.CALL && item.id === serviceCallId
        );

        if (findNoteCurrent) {
            if (isFromCall) {
                const elm = document.getElementById(`button_edit_note_${serviceCallId}`);
                if (!elm) {
                    setTimeout(() => {
                        document.getElementById(`button_edit_note_${serviceCallId}`)?.click();
                    }, 1000);
                } else {
                    elm?.click();
                }
            }
        } else {
            _handleAddNewCallLog({
                ...data,
                isNew: callType === TYPE_CALL_TIME_LINE.OUTGOING
            });
        }
    };

    const _handleTriggerCall = (newCall) => {
        dispatchAction((prevState) => {
            return {
                ...prevState,
                dataTimeLine: prevState.dataTimeLine.map((item) => {
                    if (item.id === newCall.id) {
                        return {
                            ...item,
                            ...newCall,
                            isNew: false,
                            created_date: item.created_date
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _handleAddNewCallLog = (newCall) => {
        dispatchAction((prevState) => {
            return {
                ...prevState,
                dataTimeLine: [_convertCallLog(newCall), ...prevState.dataTimeLine]
            };
        });
    };

    const _handleAddCallNote = () => {
        refFormAddCallLog.current.toggleShowHide();
    };

    function _renderTimeLine() {
        return [...finalDataTimeLine].map((item) => {
            const { type, id } = item;
            const keyRender = `${type}_${id}`;
            switch (type) {
                case CUSTOMER_TYPE_IME_TILE.NOTE:
                    return (
                        <NoteDetail
                            onUpdate={_handleUpdateItem}
                            onUpdateCallback={_handleUpdateCallback}
                            onDelete={_handleDeleteItem}
                            noteDetail={item}
                            key={keyRender}
                            type={TYPE_NOTE.CUSTOMER}
                            isHavePermission={isHavePermissionNote}
                            isHaveDeleteComment={isHavePermissionComment}
                            onHandleDeleteAttachment={_handleDeleteAttachment}
                            filterTemplate={NOTES_TEMPLATE_TYPE.CUSTOMER}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.TASK:
                    return (
                        <TaskDetail
                            key={keyRender}
                            onUpdate={_handleUpdateItem}
                            taskDetail={item}
                            onDelete={_handleDeleteItem}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.LOG:
                    return <Log logDetail={item} key={keyRender} isLogTimeLine />;
                case CUSTOMER_TYPE_IME_TILE.CUSTOMER_LOG:
                    return <CustomerLog logDetail={item} key={keyRender} />;
                case CUSTOMER_TYPE_IME_TILE.EMAIL_INBOX:
                    return (
                        <EmailInBox
                            onUpdateCallback={_handleUpdateCallback}
                            onDelete={_handleDeleteItem}
                            emailInBoxDetail={item}
                            key={keyRender}
                            customerId={customerId}
                            updated={item.updated || 0}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.CALL:
                    if (!item.call_id) {
                        return (
                            <CallNote
                                customerDetail={customerDetail}
                                key={keyRender}
                                callNoteData={item}
                                onUpdateCallback={_handleUpdateCallback}
                                onDelete={_handleDeleteItem}
                                onMakeCall={_handleMakeCall}
                            />
                        );
                    }
                    return (
                        <Calls
                            key={keyRender}
                            callData={item}
                            onUpdateCallback={_handleUpdateCallback}
                            onMakeCall={_handleMakeCall}
                            onDeleteRecording={_handleConfirmDeleteRecording}
                        />
                    );
                case CUSTOMER_TYPE_IME_TILE.BANK_LOG:
                    return <CustomerLog logDetail={item} key={keyRender} icon={<IconACH isInactive />} />;
                default:
                    return false;
            }
        });
    }

    const _handleMakeCall = (number) => {
        refCallWithVoip.current.makeCall({ number });
    };

    const _handleConfirmDeleteRecording = (id) => {
        refDeleteRecording.current?._open({ dataConfirm: id });
    };

    const _handleDeleteRecordingSuccess = (id) => {
        dispatchAction((prev) => ({
            ...prev,
            dataTimeLine: prev.dataTimeLine.map((item) => {
                if (item.id === id) {
                    return { ...item, recording: null };
                }
                return item;
            })
        }));
    };

    return (
        <Fragment>
            <div className="wrapper-columns" onScroll={_handleOnScrollContent}>
                <Suspense fallback={<LoadingTimeLine />}>
                    {finalIsLoading ? (
                        <LoadingTimeLine />
                    ) : (
                        <div className="contents-pages dashboard-page container-column">
                            {!isOnlyNote && (
                                <Header
                                    isHavePermissionInbox={isHavePermissionInbox}
                                    onShow={_handleShow}
                                    onChangeFilter={_handleChangeFilter}
                                    onClearFilter={_handleClearAllFilter}
                                    filters={finalFilters}
                                    handleSelectDate={_handleSelectDate}
                                    onNewCallNote={_handleAddCallNote}
                                    dateFilter={dateFilter}
                                />
                            )}
                            <div className="dashboard-page__wrap">
                                <div className="dashboard-wrapper --main">
                                    {!isOnlyNote && (
                                        <Fragment>
                                            <TopNote
                                                customerDetail={customerDetail}
                                                topNoteDetail={finalTopNote || {}}
                                                onUpdate={_handleUpdateTopNote}
                                                type={TYPE_TASK.CUSTOMER}
                                                filterTemplate={NOTES_TEMPLATE_TYPE.TOP}
                                            />
                                            <AddNote
                                                ref={refAddNote}
                                                onAddNew={_handleAddNewItem}
                                                cleanQuickAdd={_handleCleanQuickAdd}
                                                type={TYPE_NOTE.CUSTOMER}
                                                filterTemplate={NOTES_TEMPLATE_TYPE.CUSTOMER}
                                            />
                                            <AddTask
                                                customerDetail={customerDetail}
                                                ref={refAddTask}
                                                socketIdKey="customer_time_line_task_service"
                                                onAddNew={_handleAddNewItem}
                                                cleanQuickAdd={_handleCleanQuickAdd}
                                                onDelete={_handleDeleteItem}
                                                type={TYPE_TASK.CUSTOMER}
                                            />
                                        </Fragment>
                                    )}
                                    <AddCallLog
                                        ref={refFormAddCallLog}
                                        onAddNew={_handleAddNewItem}
                                        customerDetail={customerDetail}
                                    />
                                    <AddEmail
                                        ref={refAddEmail}
                                        customerDetail={customerDetail}
                                        onAddNew={_handleAddNewItem}
                                    />

                                    {_renderTimeLine()}

                                    {isLoadingMore ? (
                                        <div className="preloader">
                                            <div className="loader-wave">
                                                <span className="loader-wave__items" />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <RealtimeServices customerId={customerId} onUpdateCallback={_handleUpdateCallback} />
                            <RealtimeTasksServices customerId={customerId} onUpdateCallback={_handleUpdateCallback} />
                            <ServiceCall
                                customerId={customerId}
                                onTriggerCall={_handleTriggerCall}
                                onAddCallLog={_handleAddCallLog}
                            />
                            <CallWithVoip ref={refCallWithVoip} />
                            <ModalDeleteRecording
                                ref={refDeleteRecording}
                                onDeleteSuccess={_handleDeleteRecordingSuccess}
                            />
                        </div>
                    )}
                </Suspense>
            </div>
            <ServiceCustomer ref={refServiceCustomer} onTrigger={_handleTriggerReload} />
        </Fragment>
    );
};

export default CustomerTimeLine;
