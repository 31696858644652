export const responseToDataTableSticky = ({
    data = [],
    title = null,
    key,
    config = {},
    isLoading = true,
    handleClick = () => {},
    actionsHeader = {}
}) => {
    const groups = {};
    const headerContent = { isHeader: true, group_name: key, ...actionsHeader };
    groups[key] = {
        title,
        config,
        isLoading,
        handleClick,
        data: [headerContent, ...data.map((item) => ({ ...item, group_name: key }))]
    };
    return groups;
};

export const getStyleHeader = ({ isLoading, startTransform, isSticky, isActiveSticky }) => {
    const transform = isLoading ? startTransform + 110 : startTransform;
    return {
        ...(isSticky ? { zIndex: 1, background: 'white' } : {}),
        ...(isActiveSticky
            ? { position: 'sticky' }
            : { position: 'absolute', transform: `translateY(${transform}px)` }),
        top: 0,
        left: 0,
        width: '100%'
    };
};

export const getStyleGridEmpty = ({ isFistRow = false, startTransform }) => {
    return isFistRow
        ? {
              width: '100%',
              position: 'absolute',
              transform: `translateY(${startTransform}px)`
          }
        : {};
};
