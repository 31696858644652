import React, { useReducer, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconDropDown from 'assets/icon/IconDropDown';
import { useDispatch, useSelector } from 'react-redux';
import IconLoading from 'assets/icon/IconLoading';
import IconClose from 'assets/icon/IconClose';
import SearchInput from './Search';
import { getListTaxes } from 'common/redux/actions/taxesAction';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const SelectTax = forwardRef((props, ref) => {
    const { t } = useTranslation('common');
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));
    const { list_taxes, first_time } = useSelector((store) => store.taxesReducer);

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        selectedTaxes: [],
        showMore: false,
        listTaxes: []
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);
    const finalSelectedTaxes = state.selectedTaxes || [];
    const finalListTaxes = state.listTaxes || [];

    useEffect(() => {
        if (!first_time) {
            dispatchState({
                listTaxes: list_taxes
            });
        }
    }, [list_taxes]);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_quickbook_tags');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_quickbook_tags');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        dispatchState({ isVisible: !finalIsVisible });

        // eslint-disable-next-line no-undef
        !global.fetchedListTax && _getListTaxesRedducer();
    }

    function _handleGetValue() {
        return finalSelectedTaxes;
    }

    function _getListTaxesRedducer() {
        dispatch(getListTaxes({ fields: 'rate' }, () => {}));
    }

    function _handleSelectTax(e, item, active) {
        e.stopPropagation();

        if (!active) {
            dispatchState({
                selectedTaxes: [...state.selectedTaxes, item],
                isVisible: false
            });
        } else {
            _handleRemoveTaxes(e, item.id);
        }
    }

    function _handleRemoveTaxes(e, tagId) {
        e.stopPropagation();

        dispatchState((prev) => {
            let tempTaxes = [...prev.selectedTaxes];
            tempTaxes = tempTaxes.filter((select) => select.id !== tagId);
            return {
                ...prev,
                selectedTaxes: tempTaxes
            };
        });
    }

    function _handleSearchKeyWord(keyword) {
        dispatchState((prev) => {
            let tempTaxes = [...prev.listTaxes];
            tempTaxes = tempTaxes.map((select) => {
                if (select.name.search(keyword) >= 0) {
                    return {
                        ...select,
                        isHide: false
                    };
                }
                return {
                    ...select,
                    isHide: true
                };
            });
            return {
                ...prev,
                listTaxes: tempTaxes
            };
        });
    }

    function _renderListTaxes() {
        return finalListTaxes.map((item) => {
            if (item.isHide) {
                return null;
            }
            const isActive = finalSelectedTaxes.some((selected) => selected.id === item.id);
            return (
                <li
                    onClick={(e) => _handleSelectTax(e, item, isActive)}
                    key={item.id}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <div className="txt-ellipsis tag-label">{item.name}</div>
                </li>
            );
        });
    }

    function _renderListTagSelected() {
        return finalSelectedTaxes.map((tag) => {
            return (
                <div key={tag.id} className="tag-label">
                    <span className="tag-label__ellipsis">{tag.name}</span>
                    <div onClick={(e) => _handleRemoveTaxes(e, tag.id)} className="svg-remove black">
                        <IconClose isSmall />
                    </div>
                </div>
            );
        });
    }

    return (
        <div ref={refDropdown} className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
            <div onClick={_handleOpen} className="dropbtn items group-tags has-search">
                <div className="box-tags">
                    {_renderListTagSelected()}
                    <SearchInput
                        onSearch={_handleSearchKeyWord}
                        className="select-input-tag"
                        placeholder="Select Tax ..."
                    />
                </div>
                <IconDropDown />
            </div>
            <div id={'show_list_quickbook_tags'} className="v2-dropdown__menu scrolls">
                <ul>{_renderListTaxes()}</ul>
                {first_time && (
                    <div className="items justify-center">
                        <div className="loading -ajaxbar">
                            <IconLoading />
                        </div>
                    </div>
                )}
                {!first_time && !finalListTaxes.length && (
                    <li className="items pointer-events-none">
                        <div className="txt-ellipsis">{t('no_data_to_display', { title: t('taxes') })}</div>
                    </li>
                )}
            </div>
        </div>
    );
});
export default SelectTax;
