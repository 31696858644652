export default function IconAmount({ isNormal = false, isActiveColor = false }) {
    if (isNormal) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5 6.5H17.5C18.6046 6.5 19.5 7.39543 19.5 8.5V15.5C19.5 16.6046 18.6046 17.5 17.5 17.5H6.5C5.39543 17.5 4.5 16.6046 4.5 15.5V8.5C4.5 7.39543 5.39543 6.5 6.5 6.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 9.5H19.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.5 12.5H19.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 14.5H9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    if (isActiveColor) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.5 6H18.5C19.3284 6 20 6.67157 20 7.5V8C20 8.55228 19.5523 9 19 9H5C4.44772 9 4 8.55228 4 8V7.5C4 6.67157 4.67157 6 5.5 6Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 13H19C19.5523 13 20 13.4477 20 14V17C20 18.1046 19.1046 19 18 19H6C4.89543 19 4 18.1046 4 17V14C4 13.4477 4.44772 13 5 13Z"
                    fill="#DAC2F2"
                />
                <path
                    d="M5 10H19C19.5523 10 20 10.4477 20 11C20 11.5523 19.5523 12 19 12H5C4.44772 12 4 11.5523 4 11C4 10.4477 4.44772 10 5 10Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    }
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 5H18.5C19.3284 5 20 5.67157 20 6.5V7C20 7.55228 19.5523 8 19 8H5C4.44772 8 4 7.55228 4 7V6.5C4 5.67157 4.67157 5 5.5 5Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 12H19C19.5523 12 20 12.4477 20 13V16C20 17.1046 19.1046 18 18 18H6C4.89543 18 4 17.1046 4 16V13C4 12.4477 4.44772 12 5 12Z"
                fill="#C1C9D4"
            />
            <path
                d="M5 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H5C4.44772 11 4 10.5523 4 10C4 9.44772 4.44772 9 5 9Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
