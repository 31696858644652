import React, { useContext } from 'react';
import InsertImage from '../jobdetail/tabs/invoice/images/Insert';
import { InvoiceDetailContext } from 'app/modules/jobdetail/contexts/InvoiceDetailContext';

export default function InsertImageInvoice() {
    const { invoiceDetail } = useContext(InvoiceDetailContext);
    const customerJobId = invoiceDetail?.job?.id;

    if (!!!customerJobId) {
        return false;
    }

    return <InsertImage jobId={customerJobId} />;
}
