import React from 'react';

const IconBackupCodes = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 10C18.1046 10 19 10.8954 19 12V18C19 19.1046 18.1046 20 17 20H7C5.89543 20 5 19.1046 5 18V12C5 10.8954 5.89543 10 7 10H17ZM12 12C10.8954 12 10 12.8954 10 14C10 14.7403 10.4022 15.3866 10.9999 15.7324L11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17L13.0011 15.7318C13.5983 15.3858 14 14.7398 14 14C14 12.8954 13.1046 12 12 12Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C14.2091 4 16 5.79086 16 8V9H14V8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8V9H8V8C8 5.79086 9.79086 4 12 4Z"
                stroke="#4D5054"
                strokeWidth="1.3"
            ></path>
        </svg>
    );
};

export default IconBackupCodes;
