import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import { CUSTOMERS_ACCOUNT, CUSTOMERS_PORTAL, CUSTOMERS_STATEMENTS } from 'app/config/routes';
import IconPen from 'assets/icon/IconPen';
import IconProfile from 'assets/icon/IconProfile';
import IconWallet from 'assets/icon/IconWallet';
import { getPermissionCustomerDetail, isNotPermissionValue } from 'common/utils/PermissionUtils';
import PaymentsDisplay from '../../../accounts/components/PaymentsDisplay';
import CustomerTags from '../../CustomerTags';
import { addBranchPath } from 'app/const/Branch';

const BlockInfo = ({ customer, balance, credits }) => {
    const { id } = useParams();
    const { t } = useTranslation('customers');

    const addons = useSelector(({ auth }) => auth.user.settings.addons);
    const permissions = getPermissionCustomerDetail(addons);

    return (
        <div className="details-info flexcenter flex-wrap gap-8">
            <div className="flex-1 word-break">
                <b className="fs-16">{customer.first_name.concat(' ', customer?.last_name)}</b>
                <p className="flexcenter gap-4">
                    <span className="grey-generic">{t('account')}</span>
                    <span className="black-2">#{customer.account_number}</span>
                </p>
            </div>

            <NavLink
                to={addBranchPath(CUSTOMERS_ACCOUNT.replace(':id', id))}
                className="v2-btn-default --icon-lg"
                activeClassName="is-disable"
            >
                <IconPen />
            </NavLink>

            <div className="d-flex gap-4 w-100">
                {!isNotPermissionValue(permissions, 'customer_portal') ? (
                    <NavLink
                        to={addBranchPath(CUSTOMERS_PORTAL.replace(':id', id))}
                        className="v2-btn-default has-icon btn-bg-purple svg-purple --sm fs-13"
                        activeClassName="is-disable"
                    >
                        <IconProfile /> {t('portal_access')}
                    </NavLink>
                ) : null}
                {!isNotPermissionValue(permissions, 'statement') ? (
                    <NavLink
                        to={addBranchPath(CUSTOMERS_STATEMENTS.replace(':id', id))}
                        className="v2-btn-default has-icon btn-bg-purple svg-purple --sm fs-13"
                        activeClassName="is-disable"
                    >
                        <IconWallet /> {t('view_statement')}
                    </NavLink>
                ) : null}
            </div>

            <CustomerTags classLabel="tags__label flex-betweenitems" classTitle="title-sx flexcenter gap-4" />
            <PaymentsDisplay isPaymentSideBar />

            <div className="price">
                <div className="flex-betweenitems">
                    <span className="price-label">{t('balance')}</span>
                    <b className="cost fs-16 black-eerie-dark" title={balance}>
                        {balance}
                    </b>
                </div>
                <div className="flex-betweenitems mt-1">
                    <span className="price-label">{t('credits')}</span>
                    <b className="cost fs-16 green-default" title={credits}>
                        {credits}
                    </b>
                </div>
            </div>
        </div>
    );
};

export default BlockInfo;
