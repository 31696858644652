export default function LoadingDoughnutChart() {
    return (
        <div className="boxs__content wrap-loading">
            <div className="chart">
                <div className="chart__content">
                    <div className="content-l">
                        <div className="is-summary">
                            <div className="rows">
                                <div className="col">
                                    <div className="loading --animation --line --twothird"></div>
                                    <div className="loading --animation --line --half mt-1"></div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-rows gap-10">
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                            <div className="rows">
                                <div className="rows__label">
                                    <div className="loading --animation --line --threefourth"></div>
                                </div>
                                <div className="rows__percent d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__job d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                                <div className="rows__price d-flex justify-end">
                                    <div className="loading --animation --line --half"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-r">
                        <div className="chart-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
