import React, { useContext } from 'react';
import WeeklyRepeat from 'app/modules/jobdetail/tabs/settingschedule/repeat/WeeklyRepeat';
import FormInterval from 'app/modules/jobdetail/tabs/settingschedule/repeat/FormInterval';
import { TABS_PERIOD } from 'app/modules/jobdetail/const';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';

const RepeatWeekly = ({ isEvent }) => {
    const { repeatSettings, onChangeDataContext } = useContext(DataRepeatContext);
    const jobCurrentceDetail = repeatSettings.recurrence.offset;
    const weeklyInterval = jobCurrentceDetail.interval;
    const finalTabActive =
        weeklyInterval === TABS_PERIOD.MODE_1_3 || weeklyInterval === TABS_PERIOD.MODE_2_4
            ? weeklyInterval
            : TABS_PERIOD.MODE_EVERY;
    const isTab_1_3 = finalTabActive === TABS_PERIOD.MODE_1_3;
    const isTab_2_3 = finalTabActive === TABS_PERIOD.MODE_2_4;
    const isTabWeekly = finalTabActive === TABS_PERIOD.MODE_EVERY;

    const days = {
        MO: jobCurrentceDetail.MO,
        TU: jobCurrentceDetail.TU,
        WE: jobCurrentceDetail.WE,
        TH: jobCurrentceDetail.TH,
        FR: jobCurrentceDetail.FR,
        SA: jobCurrentceDetail.SA,
        SU: jobCurrentceDetail.SU
    };

    function _changeTab(e, value) {
        e.stopPropagation();

        _handleChangeValue(value === TABS_PERIOD.MODE_EVERY ? 1 : value);
    }

    function _handleChangeValue(interval) {
        _prepareData(interval, days);
    }

    function _prepareData(interval, days) {
        const refValue = {
            ...repeatSettings,
            recurrence: {
                ...repeatSettings.recurrence,
                offset: {
                    ...jobCurrentceDetail,
                    ...days,
                    interval: interval
                }
            }
        };

        _sendDataToContext(refValue);
    }

    function _sendDataToContext(data) {
        onChangeDataContext(data);
    }

    function _handleChangeWeekDay(days) {
        _prepareData(weeklyInterval, days);
    }

    return (
        <div className="tab-panel active">
            <div className="rows">
                {!isEvent ? <div className="txt no-icon"> Period </div> : null}
                <div className="details d-flex">
                    <div className="tabs">
                        <div className="btn-item m-0">
                            <button
                                onClick={(e) => _changeTab(e, TABS_PERIOD.MODE_1_3)}
                                className={`tab-items ${isTab_1_3 ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                1st &amp; 3rd
                            </button>
                            <button
                                onClick={(e) => _changeTab(e, TABS_PERIOD.MODE_2_4)}
                                className={`tab-items ${isTab_2_3 ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                2nd &amp; 4th
                            </button>
                            <button
                                onClick={(e) => _changeTab(e, TABS_PERIOD.MODE_EVERY)}
                                className={`tab-items ${isTabWeekly ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                Every
                            </button>
                        </div>
                    </div>

                    {isTabWeekly && (
                        <div className="field-every ml-1">
                            <div className="tab-conts active">
                                <div className="d-flex align-center">
                                    <FormInterval interval={weeklyInterval} onChange={_handleChangeValue} />
                                    <span className="text-last">weeks</span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <WeeklyRepeat
                isAddService={repeatSettings.isAddService}
                isEvent={isEvent}
                data={jobCurrentceDetail}
                {...days}
                onChange={_handleChangeWeekDay}
            />
        </div>
    );
};

export default RepeatWeekly;
