import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import FieldInputPlan from '../activePlan/FieldInputPlan';
import FieldUploadAvatar from '../activePlan/FieldUploadAvatar';
import { actionUpdateBranch } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/App';
import { isFetchedBranch } from 'app/const/Branch';

const ModalEditBranch = ({ onUpdateSuccess = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const refAvatar = useRef(null);
    const refBranchName = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        id: '',
        isVisible: false,
        avatar: null,
        branchName: null,
        color: ''
    });

    const { id: finalId, isVisible, avatar: finalAvatar, branchName: finalBranchName, color } = state;

    useImperativeHandle(ref, () => ({
        open: _handleOpenModal,
        setVisible: _handleSetVisible
    }));

    const _handleOpenModal = ({ id, avatar, name, color }) => {
        dispatchState((prev) => ({ ...prev, isVisible: true, avatar, branchName: name, id, color }));
    };

    const _handleSetVisible = (value = false) => {
        dispatchState((prev) => ({ ...prev, isVisible: value }));
    };

    const _handleClose = () => {
        _handleSetVisible(false);
    };

    const _handleSave = async () => {
        const branch_name = refBranchName.current._getValue();
        if (!branch_name) {
            refButtonSave.current.removeLoading();
            return;
        }

        const { url, avtObject } = await refAvatar.current.getValue();
        const newData = { name: branch_name, avatar: avtObject || url, color };

        const _handleSuccess = () => {
            isFetchedBranch.isFetched = false;
            onUpdateSuccess({ ...newData, avatar: url, id: finalId });
            refButtonSave.current.removeLoading();
            _handleSetVisible(false);
        };

        const _handleFail = ({ message }) => {
            refAlert.current.showStatusBar('edit_branch', message, LIST_STATUS.ERROR);
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            actionUpdateBranch(finalId),
            { method: 'PUT', data: newData, toFormData: false },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleDisableBtnSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    if (!isVisible) return null;

    return (
        <ReactModal isOpen className={'modal container-modal modal-edit-branch open'}>
            <div className="modal__overlay bg-fixed" />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('edit_branch')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <GDStatusBar ref={refAlert} />
                    <div className="row">
                        <FieldUploadAvatar
                            ref={refAvatar}
                            isNotDivider
                            onUpFile={() => _handleDisableBtnSave(true)}
                            urlImage={finalAvatar}
                            nameBranch={finalBranchName}
                            color={color}
                            onUpFileSuccess={() => _handleDisableBtnSave(false)}
                        />
                    </div>
                    <FieldInputPlan
                        ref={refBranchName}
                        name="name"
                        label={t('branch_name')}
                        wrapperClassName="row"
                        defaultValue={finalBranchName}
                    />
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_handleClose}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleSave}>
                        {t('save')}
                    </ButtonSave>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ModalEditBranch);
