import IconBold from 'assets/icon/IconBold';
import classNames from 'classnames';
import React from 'react';
import { handleGetActiveInlineStyle } from '../utils';

const CustomBoldOption = ({ editorState, onInsert = () => {} }) => {
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'BOLD')
            })}
            onMouseDown={(e) => onInsert(e, 'BOLD')}
        >
            <IconBold />
            <span className="tooltiptext top">Bold (CTRL+B)</span>
        </span>
    );
};

export default CustomBoldOption;
