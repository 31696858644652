import { call, put, takeLatest } from 'redux-saga/effects';

import { LOCATION_GET_LIST_CITY, LOCATION_GET_LIST_ZIP, REPORT_REVENUE_ITEM_ITEMS_FILTER } from 'app/const/Api';
import { SMART_VIEWS_TYPES } from 'common/redux/actions/smartviews';
import { clientQuery } from 'common/utils/ApiUtils';

function* getZip() {
    const response = yield call(clientQuery, LOCATION_GET_LIST_ZIP, { data: {}, method: 'GET' });
    if (response && response.success) yield put({ type: SMART_VIEWS_TYPES.SV_GET_ZIP_SUCCESS, payload: response.data });
}

function* getCity() {
    const response = yield call(clientQuery, LOCATION_GET_LIST_CITY, { data: {}, method: 'GET' });
    if (response && response.success)
        yield put({ type: SMART_VIEWS_TYPES.SV_GET_CITY_SUCCESS, payload: response.data });
}

function* getItems() {
    const response = yield call(clientQuery, REPORT_REVENUE_ITEM_ITEMS_FILTER, { data: { active: 1 }, method: 'GET' });
    if (response && response.success)
        yield put({ type: SMART_VIEWS_TYPES.SV_GET_ITEMS_SUCCESS, payload: response.data });
}

export function* smartViewsWatcher() {
    yield takeLatest(SMART_VIEWS_TYPES.SV_GET_ZIP, getZip);
    yield takeLatest(SMART_VIEWS_TYPES.SV_GET_CITY, getCity);
    yield takeLatest(SMART_VIEWS_TYPES.SV_GET_ITEMS, getItems);
}
