import React from 'react';

const LoadingSmartview = () => {
    return (
        <>
            <div className="sidebar --smart-views flex-column scrolls border-top-black-verydark">
                <div className="sidebar-header wrap-loading">
                    <div className="flex-1 loading">
                        <div className="loading --animation --light --line --threefourth" />
                    </div>
                    <div className="v2-btn-default loading --icon-lg bg-grey-extralight" />
                    <div className="v2-btn-default loading --icon-lg bg-grey-extralight" />
                </div>
                <div className="wrap-loading sidebar-list flex-column scrolls">
                    <div className="rows sidebar-items field-h32 gap-2 pointer-events-none">
                        <div className="v2-btn-default loading --icon-sm bg-white-cultured --drag" />
                        <div className="flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows sidebar-items field-h32 gap-2 pointer-events-none">
                        <div className="v2-btn-default loading --icon-sm bg-white-cultured --drag" />
                        <div className="flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows sidebar-items field-h32 gap-2 pointer-events-none">
                        <div className="v2-btn-default loading --icon-sm bg-white-cultured --drag" />
                        <div className="flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows sidebar-items field-h32 gap-2 pointer-events-none">
                        <div className="v2-btn-default loading --icon-sm bg-white-cultured --drag" />
                        <div className="flex-1">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoadingSmartview;
