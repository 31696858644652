import React from 'react';

const CardDate = ({ className = 'date', exp_month = '', exp_year = '', isACH = false, currency = null }) => {
    const isError = !exp_month || !exp_year;

    let string = '';
    if (isACH && currency) string = currency;
    if (!isACH && !isError) {
        string = `${+exp_month < 10 ? '0' : ''}${+exp_month}/${exp_year?.toString()?.slice(-2)}`;
    }

    if (!string) return null;

    return <span className={className}>{string}</span>;
};

export default CardDate;
