export default function IconCircleClose({ isWhite }) {
    if (isWhite) {
        return (
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M7.5 7.5L12.5 12.5M12.5 7.5L7.5 12.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                />{' '}
            </svg>
        );
    }
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="2" width="16" height="16" rx="8" fill="#C1C9D4"></rect>
            <path
                d="M7.5 7.5L12.5 12.5M12.5 7.5L7.5 12.5"
                stroke="white"
                strokeWidth="1.3"
                strokeLinecap="round"
            ></path>
        </svg>
    );
}
