import React, { Fragment, forwardRef } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import IconFuture from 'assets/icon/IconFuture';
import { formatDateDashboard, formatDateLocal } from 'common/utils/DateUtils';
import { useTranslation } from 'react-i18next';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { LIST_OPTIONS_SELECT_DATE_DEFAULT } from 'app/const/drip/Common';
import classNames from 'classnames';

const InputCustom = forwardRef(
    (
        { apply, handleOnClick = () => {}, className, isOpen, inputId, isShowLabel = false, listOptionsDate = [] },
        ref
    ) => {
        const { t } = useTranslation();
        const date_format = useSelector(({ auth }) => auth?.user?.company?.date_format);
        const startDate = moment(apply.startDate);
        const endDate = moment(apply.endDate);

        const _renderContent = () => {
            if (isShowLabel) {
                const finalLabel =
                    (listOptionsDate || LIST_OPTIONS_SELECT_DATE_DEFAULT).find((item) => {
                        const { start, end } = item.date_range;
                        return moment(start).isSame(startDate._d, 'day') && moment(end).isSame(endDate._d, 'day');
                    })?.name || formatDateDashboard(startDate, endDate);

                return (
                    <Fragment>
                        <IconFuture />
                        <div className="txt-ellipsis">{t(`report:${finalLabel}`)}</div>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </Fragment>
                );
            }

            return (
                <Fragment>
                    <IconFuture /> &nbsp;
                    {startDate.isValid() && (
                        <p className="field-date__select">{formatDateLocal(startDate, date_format)}</p>
                    )}
                    {endDate.isValid() && (
                        <>
                            <span className="field-date__label">&nbsp;to&nbsp;</span>
                            <p className="field-date__select">{formatDateLocal(endDate, date_format)}</p>
                        </>
                    )}
                    {!startDate.isValid() && !endDate.isValid() && (
                        <p className="field-date__select">{t('select_date_range')}</p>
                    )}
                </Fragment>
            );
        };

        return (
            <div
                ref={ref}
                id={inputId}
                className={classNames(className || `field-input field-date`, { 'auto-focus active': isOpen })}
                onClick={handleOnClick}
            >
                {_renderContent()}
            </div>
        );
    }
);

export default InputCustom;
