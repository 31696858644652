import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';

const AdjustRingDuration = ({ ring = 5 }, ref) => {
    const { t } = useTranslation('addons');
    const refInput = useRef(null);

    const OPTIONS_DEFAULT = [10, 15, 20, 25, 30];

    const [state, dispatchState] = useReducer(reducer, {
        ring
    });

    const { ring: finalRing } = state;

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _options = () => {
        return OPTIONS_DEFAULT.map((item) => ({ id: item.toString(), label: item, value: item }));
    };

    const _handleSelect = (value) => {
        dispatchState({ ring: value.toString() });
        refInput.current.value = value;
    };

    const _getValue = () => {
        const timeout = parseInt(refInput.current.value);
        dispatchState({ ring: timeout });
        return { timeout };
    };

    const _renderButton = () => {
        return (
            <Fragment>
                <input
                    ref={refInput}
                    className="border-none fw-500"
                    type="number"
                    placeholder={finalRing}
                    defaultValue={finalRing}
                />
                <div className="arrow">
                    <IconArrowDown width={16} height={16} />
                </div>
            </Fragment>
        );
    };

    return (
        <div className="box-voip__rows flex-betweenitems gap-8">
            <p className="flex-1">{t('desc_ring_duration')}</p>

            <div className="flexcenter gap-5">
                <CalendarDropdown
                    id={'ring_duration'}
                    options={_options()}
                    selectedOption={{ id: finalRing?.toString() }}
                    keyGetName="label"
                    keyGetValue="value"
                    keyGetKey="id"
                    onSelect={_handleSelect}
                    wrapperClassName="fw-500 dropdown-duration"
                    wrapperListClass="v2-dropdown__menu content-full scrolls"
                    buttonClassName="dropbtn v2-btn-default has-icon"
                    customDropButton={_renderButton}
                />
                <span className="fw-500">{t(finalRing > 1 ? 'secs' : 'sec')}</span>
            </div>
        </div>
    );
};

export default forwardRef(AdjustRingDuration);
