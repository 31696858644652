import { useEffect } from 'react';

let dragTimer;
const useDragAndDrop = ({ refDropWarning }) => {
    useEffect(() => {
        document.addEventListener('dragover', _handleDragOver);
        document.addEventListener('dragleave', _handleDragEnd);

        return () => {
            document.removeEventListener('dragover', _handleDragOver);
            document.removeEventListener('dragleave', _handleDragEnd);
        };
    }, []);

    const _handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (refDropWarning.current) refDropWarning.current.handleDisplay();
        window.clearTimeout(dragTimer);
    };

    const _handleDragEnd = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dragTimer = window.setTimeout(() => {
            if (refDropWarning.current) refDropWarning.current.handleHidden();
        }, 25);
    };
    return null;
};

export default useDragAndDrop;
