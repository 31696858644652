import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

const EMPTY_TAX = { id: '', name: '' };

const SurchargeTaxDropdown = forwardRef(({ id, defaultSelected }, ref) => {
    const [selected, setSelected] = useState(EMPTY_TAX);
    const { list_taxes } = useSelector((store) => store.taxesReducer);

    useImperativeHandle(ref, () => ({ _getValue: () => selected.id }));

    useEffect(() => {
        setSelected(list_taxes.find((item) => item.id === defaultSelected) || EMPTY_TAX);
    }, [defaultSelected]);

    const _handleSelect = (id) => {
        setSelected(list_taxes.find((item) => item.id === id) || EMPTY_TAX);
    };

    return (
        <>
            <CalendarDropdown
                id={id}
                isDropUp
                options={[EMPTY_TAX, ...list_taxes]}
                buttonClassName="dropbtn items"
                keyGetValue="id"
                selectedOption={selected}
                onSelect={_handleSelect}
                customDropButton={() => (
                    <>
                        <span className="txt-ellipsis">{selected.name || ''}</span>
                        <IconDropDown />
                    </>
                )}
            />
        </>
    );
});

export default SurchargeTaxDropdown;
