import { takeLatest } from 'redux-saga/effects';

// sagas
import { getListLocationCity } from './city';
import { getListLocationZip } from './zip';

// TYPE
import { TYPE_LOCATION } from 'common/redux/actions/locationAction';

// City
export function* getListLocationCityWatcher() {
    yield takeLatest(TYPE_LOCATION.LOCATION_GET_LIST_CITY, getListLocationCity);
}

// Zip
export function* getListLocationZipWatcher() {
    yield takeLatest(TYPE_LOCATION.LOCATION_GET_LIST_ZIP, getListLocationZip);
}
