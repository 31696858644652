import React, { Fragment, useContext } from 'react';
import OutGoing from './OutGoing';
import Incomming from './Incomming';
import { VOIPContext } from 'app/services/voip/VOIPProvider';
import { TYPE_CALL, TYPE_IN_COMMING } from 'app/const/Voip';
import Accepting from './Accepting';
import { createCustomer } from 'common/redux/actions/customer';
import { useDispatch } from 'react-redux';
import { CallCollapse } from '../CallCollapse';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { actionAssignLead } from 'common/redux/actions/assignLead';
import SmartDialer from './SmartDialer';
import InviteCall from './InviteCall';

export default function GDVoip() {
    const dispatch = useDispatch();
    const {
        calls,
        onSwitchCall,
        onOutGoingCall,
        onAcceptCall,
        onDisconnect,
        acceptingCall,
        onTransfer,
        onHangUp,
        onCallBack,
        onMuteCall,
        onCallDial,
        onDelete,
        onUpdateState,
        onSetIndexDialer,
        onInvite,
        features
    } = useContext(VOIPContext);

    const _handleCreateLead = (id, phone) => {
        const dataCreate = {
            isGetAllDataSuccess: true,
            onSubmit: onAddCustomer,
            isVisibleModal: true,
            // Above for open modal
            defaultStatus: 2,
            defaultPhones: [
                {
                    id: Date.now(),
                    phone: formatPhoneNumberVoip(phone),
                    type: {
                        id: '1',
                        name: 'Mobile',
                        is_default: 1
                    },
                    isDisable: true
                }
            ],
            call_log_id: id
        };
        dispatch(createCustomer(dataCreate));
    };

    const _handleAssignLead = (id, phone) => {
        dispatch(
            actionAssignLead({
                id,
                phone,
                assignSuccess: (conversationId, response) => {
                    _handleAssignSuccess(conversationId, response);
                }
            })
        );
    };

    const onAddCustomer = (response) => {
        const { customer: customerData, call_log_id, profile, id: customerId } = response;

        onUpdateState((prev) => {
            return {
                ...prev,
                calls: prev.calls.map((item) => {
                    if (item.id === call_log_id) {
                        return {
                            ...item,
                            customer_name: customerData.full_name,
                            company_name: profile.company,
                            customer_id: customerId
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _handleAssignSuccess = (callLogId, response) => {
        const { customer: customerData, company } = response;
        onUpdateState((prev) => {
            return {
                ...prev,
                calls: prev.calls.map((item) => {
                    if (item.id === callLogId) {
                        return {
                            ...item,
                            customer_name: customerData.full_name,
                            company_name: company,
                            customer_id: customerData.id
                        };
                    }
                    return item;
                })
            };
        });
    };

    const _renderCalls = () => {
        return calls.map((item) => {
            switch (item.type) {
                case TYPE_CALL.IN:
                    if (item.status === TYPE_IN_COMMING.RINGING && !!item.inviteData)
                        return (
                            <InviteCall
                                key={item.id}
                                item={item}
                                onAcceptCall={onAcceptCall}
                                onDisconnect={onDisconnect}
                            />
                        );

                    return (
                        <Incomming
                            onHangUp={onHangUp}
                            item={item}
                            key={item.id}
                            onAcceptCall={onAcceptCall}
                            onDisconnect={onDisconnect}
                            onCallBack={onCallBack}
                            onSwitchCall={onSwitchCall}
                            onMuteCall={onMuteCall}
                            onCallDial={onCallDial}
                            onDelete={onDelete}
                            onTransfer={onTransfer}
                            onCreateLead={_handleCreateLead}
                            onAssignLead={_handleAssignLead}
                            onInvite={onInvite}
                            features={features}
                        />
                    );
                case TYPE_CALL.OUT:
                    return (
                        <OutGoing
                            item={item}
                            key={item.id}
                            onHangUp={onHangUp}
                            onDisconnect={onDisconnect}
                            onTransfer={onTransfer}
                            onCallBack={onCallBack}
                            onMuteCall={onMuteCall}
                            onCallDial={onCallDial}
                            onDelete={onDelete}
                            onCreateLead={_handleCreateLead}
                            onAssignLead={_handleAssignLead}
                            onInvite={onInvite}
                            features={features}
                        />
                    );
                case TYPE_CALL.SMART:
                    return (
                        <SmartDialer
                            key="smart_dialer"
                            item={item}
                            calls={calls}
                            onHangUp={onHangUp}
                            onDisconnect={onDisconnect}
                            onTransfer={onTransfer}
                            onCallBack={onCallBack}
                            onMuteCall={onMuteCall}
                            onCallDial={onCallDial}
                            onDelete={onDelete}
                            onOutGoingCall={onOutGoingCall}
                            acceptingCall={acceptingCall}
                            onSetIndexDialer={onSetIndexDialer}
                        />
                    );
                default:
                    return null;
            }
        });
    };

    return (
        <Fragment>
            <div className="wrapper-call relative">
                <div className="wrapper-call__container">
                    {!!acceptingCall && <Accepting item={acceptingCall} />}
                    {_renderCalls()}
                </div>
                <CallCollapse />
            </div>
        </Fragment>
    );
}
