import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { defaultRangeExtractor, useVirtual } from 'react-virtual';

import GDRowEmpty from './components/RowEmptySticky';
import GDRowHeader from './components/RowHeaderSticky';
import GDRow from './components/RowSticky';

const GDTableSticky = ({ classTable = '', groups = [] }) => {
    const parentRef = useRef(null);
    const activeStickyIndexRef = useRef(0);

    const [rows, stickyIndexes] = useMemo(() => {
        const results = [];
        const resultsIndex = [];

        for (const key in groups) {
            if (Object.hasOwnProperty.call(groups, key)) {
                results.push(...groups[key].data);
                // Check equal to 1 because the header is always there
                if (groups[key].data.length === 1) results.push({ isEmptyRow: true, group_name: key });
            }
        }

        // TODO: Should improve this logic to get indexes sticky
        results.forEach((item, index) => {
            if (item.isHeader) resultsIndex.push(index);
        });

        return [results, resultsIndex];
    }, [groups]);

    // Functions below are used to determine if a row is sticky or active sticky
    const isSticky = (index) => stickyIndexes.includes(index);
    const isActiveSticky = (index) => activeStickyIndexRef.current === index;

    const rowVirtualizer = useVirtual({
        // estimateSize: useCallback(() => 35, []),
        // estimateSize - The estimated size of each item in the list (it defaults to 50px).
        // it required to calculate the total size of the list -> don't remove it
        size: rows.length,
        overscan: 5,
        parentRef,
        rangeExtractor: useCallback(
            (range) => {
                activeStickyIndexRef.current = [...stickyIndexes].reverse().find((index) => range.start >= index);
                const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);
                return [...next].sort((a, b) => a - b);
            },
            [stickyIndexes]
        )
    });

    return (
        <>
            <div
                ref={parentRef}
                className={classNames('tables table-multi-column table-virtual has-text-ellipsis w-100 h-100', classTable)}
                style={{ overflow: 'auto' }}
            >
                <div style={{ height: `${rowVirtualizer.totalSize}px`, width: '100%', position: 'relative' }}>
                    {rowVirtualizer.virtualItems.map((virtualRow, indexItem) => {
                        const rowData = rows[virtualRow.index];
                        if (!rowData) return null;

                        const rowProps = { startTransform: virtualRow.start };
                        const {
                            config = {},
                            isLoading,
                            handleClick,
                            data = [],
                            title
                        } = groups[rowData.group_name] || {};

                        // TODO: Need improve this handle for check transform of header
                        // This for check above header is loading or empty
                        const keys = Object.keys(groups);
                        const currentIndex = keys.indexOf(rowData.group_name);
                        const { isLoading: aboveHeaderLoading } = groups[keys[currentIndex - 1]] || {};

                        // NOTE: Don't move this line to any where.
                        if (rowData.isHeader) {
                            return (
                                <GDRowHeader
                                    key={virtualRow.index}
                                    measureRef={virtualRow.measureRef}
                                    row={config['header']}
                                    columns={config['columns']}
                                    fileTranslation={config['fileTranslation']}
                                    handleClickHeader={rowData.handleClickHeader}
                                    title={title}
                                    isFistRow={indexItem === 0}
                                    isLoading={isLoading}
                                    // NOTE: Check length with 1 because the first row is header.
                                    isEmpty={data.length === 1}
                                    isSticky={isSticky(virtualRow.index)}
                                    isActiveSticky={isActiveSticky(virtualRow.index)}
                                    {...rowProps}
                                    aboveHeaderLoading={aboveHeaderLoading}
                                />
                            );
                        }

                        if (rowData.isEmptyRow && !isLoading)
                            return (
                                <GDRowEmpty
                                    key={`${rowData.group_name}_empty`}
                                    measureRef={virtualRow.measureRef}
                                    {...rowProps}
                                />
                            );
                        if (isLoading) return null;
                        return (
                            <GDRow
                                key={virtualRow.index}
                                row={rowData}
                                isOddItem={virtualRow.index % 2}
                                measureRef={virtualRow.measureRef}
                                contentConfig={config['contentConfig']}
                                columns={config['columns']}
                                handleClick={handleClick}
                                {...rowProps}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default GDTableSticky;
