export const TASK_LIST_ACTIONS = {
    UPDATE_TASK_STATUS: 'UPDATE_TASK_CALENDAR_STATUS',
    UPDATE_TASK_STATUS_REALTIME: 'UPDATE_TASK_STATUS_REALTIME'
};

export const updateTaskStatus = (payload) => {
    return {
        type: TASK_LIST_ACTIONS.UPDATE_TASK_STATUS,
        payload
    };
};
export const updateTaskStatusRealtime = (payload) => {
    return {
        type: TASK_LIST_ACTIONS.UPDATE_TASK_STATUS_REALTIME,
        payload
    };
};
