import IconCircleClose from 'assets/icon/IconCircleClose';
import IconSearch from 'assets/icon/IconSearch';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const HeaderSearch = ({ isSearching = false, onSearch = () => {}, onClearSearch = () => {} }) => {
    const { t } = useTranslation();
    const refInput = useRef(null);

    const _handleClear = () => {
        refInput.current.value = '';
        onClearSearch();
    };

    return (
        <div className="search-input">
            <span className="svg-search-absolute">
                <IconSearch />
            </span>
            <input ref={refInput} type="text" placeholder={t('search')} onChange={onSearch} spellCheck />
            {!!isSearching && (
                <span className="close-icon" onClick={_handleClear}>
                    <IconCircleClose />
                </span>
            )}
        </div>
    );
};
HeaderSearch.displayName = 'SearchAddonsDashboard';
export default HeaderSearch;
