import IconItalic from 'assets/icon/IconItalic';
import classNames from 'classnames';
import React from 'react';
import { handleGetActiveInlineStyle } from '../utils';

const CustomItalicOption = ({ editorState, onInsert = () => {} }) => {
    return (
        <span
            className={classNames('editor-controls__btn tooltip', {
                active: handleGetActiveInlineStyle(editorState, 'ITALIC')
            })}
            onMouseDown={(e) => onInsert(e, 'ITALIC')}
        >
            <IconItalic />
            <span className="tooltiptext top">Italic (CTRL+I)</span>
        </span>
    );
};

export default CustomItalicOption;
