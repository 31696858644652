import IconCogWheel from 'assets/icon/IconCogWheel';
import { React, useRef } from 'react';
import ModalReviewManager from './ModalReviewManager';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const AddonsOptionsFooterReview = ({ upgrade = 0, keyword, isEnabled, handleManagerSuccess }) => {
    const refManager = useRef(null);
    const { t } = useTranslation(['addons']);
    const isDisplayManage = !!!upgrade;

    const _handleOpenManager = () => {
        refManager.current._open();
    };

    if (!isDisplayManage) return null;

    return (
        <>
            <div className="boxs__footer">
                <div
                    onClick={_handleOpenManager}
                    className={classNames('v2-btn-default svg-purple --purple --icon-r btn-modal', {
                        'is-disable': !isEnabled
                    })}
                >
                    {t('addons:manager')}
                    <IconCogWheel />
                </div>
            </div>
            <ModalReviewManager ref={refManager} handleManagerSuccess={handleManagerSuccess} keyword={keyword} />
        </>
    );
};

export default AddonsOptionsFooterReview;
