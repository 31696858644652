import React, { Fragment, useContext, useEffect, useReducer, useRef } from 'react';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconAlert from 'assets/icon/IconAlert';
import IconSingleStep from 'assets/icon/IconSingleStep';
import IconSync from 'assets/icon/IconSync';
import { useTranslation } from 'react-i18next';
import { ActiveUsersContext } from './ActiveUsersProvider';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { useSelector } from 'react-redux';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { clientQuery } from 'common/utils/ApiUtils';
import { assignVoipNumber, swapVoipNumber } from 'app/const/api/Voip';
import { reducer } from 'app/const/Reducer';
import { SOURCE_PHONE_NUMBER_VOIP } from 'app/const/addons';
import { getUserInfo } from 'common/utils/CompanyUserUtils';

export const DropdownPersonalNumber = ({ item }) => {
    const { t } = useTranslation('addons');
    const refDropDown = useRef(null);
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { data, onAssignPersonal, onUnassignPersonal, onSwapNumber } = useContext(ActiveUsersContext);

    const [state, dispatchState] = useReducer(reducer, {
        availables: [],
        assinged: []
    });

    const { availables, assinged } = state;
    const { id: userId, personal_number = [], onAssign = () => {}, onSwap = () => {} } = item || {};
    const countNumber = personal_number?.length || 0;

    useEffect(() => {
        if (!data.length) return;

        const availables = [];
        const assinged = [];
        data.forEach((item) => {
            const assignee = item.assignees[0];
            !!assignee
                ? assignee !== parseInt(userId) && assinged.push(item)
                : item.source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL && availables.push(item);
        });

        dispatchState({ availables, assinged });
    }, [data]);

    const _unassignNumber = ({ id, phone_number }) => {
        return (
            <div key={id} className="border-bottom-border-color-grey">
                <div className="number-items --unassign-item flex-betweenitems cursor-default">
                    <p className="txt-ellipsis flex-1">{formatPhoneNumberVoip(phone_number)}</p>
                    <div className="v2-btn-default" onClick={() => _handleUnassign(id)}>
                        {t('unassign_number')}
                    </div>
                </div>
            </div>
        );
    };

    const _swapAnotherUser = () => {
        if (!countNumber || !assinged.length) return null;

        const _customButton = () => {
            return (
                <div className="dropbtn number-items flex-betweenitems">
                    <div className="flexcenter gap-8">
                        <IconSync />
                        <span>{t('swap_with_another_user')}</span>
                    </div>
                    <div>
                        <IconSingleStep />
                    </div>
                </div>
            );
        };

        const _renderNumber = () => {
            const groupedByUser = data.reduce((obj, item) => {
                const assignee = item.assignees[0];
                if (!assignee || assignee === +userId) return obj;
                if (!obj[assignee]) obj[assignee] = [];
                obj[assignee].push(item);
                return obj;
            }, {});

            return Object.entries(groupedByUser).map(([key, array]) => {
                if (!key) return null;
                const { avatar, full_name } = getUserInfo(key) || {};

                const _handleClick = () => {
                    _handleSwapNumber({ newUserId: key });
                };

                return (
                    <div key={`swap_${key}`} className="border-bottom-border-color-grey" onClick={_handleClick}>
                        <div className="number-items flex-betweenitems gap-8 align-top">
                            <span className="schedule-user">
                                <span className="tech-name m-0">
                                    <img className="avt-img mr-0" src={avatar} width={24} height={24} />
                                    <span className="txt-ellipsis black">{full_name}</span>
                                </span>
                            </span>
                            <div className="flex-column justify-end">
                                {array.map((item) => (
                                    <span key={item.id} className="txt-ellipsis flex-1 text-right">
                                        {formatPhoneNumberVoip(item.phone_number)}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                );
            });
        };

        return (
            <DropdownPopper
                wrapperClassName="v2-dropdown swap-user"
                wrapperListClass="v2-dropdown__menu scrolls"
                buttonClassName="border-bottom-border-color-grey"
                customButton={_customButton()}
                isUseToggle
            >
                <Fragment>{_renderNumber()}</Fragment>
            </DropdownPopper>
        );
    };

    const _availableNumbers = () => {
        if (!availables.length) return null;

        const _renderNumber = () => {
            return availables.map((item) => {
                const { id, phone_number, source } = item;

                const _handleClick = () => {
                    _handleAssignAvailable({ numberId: id, phone_number });
                };

                return (
                    <div className="border-bottom-border-color-grey" key={id} onClick={_handleClick}>
                        <div className="number-items flexcenter gap-8">
                            <span className="txt-ellipsis phone-number">{formatPhoneNumberVoip(phone_number)}</span>
                            {source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL && (
                                <div className="tooltip">
                                    <IconAlert />
                                    <p className="tooltiptext top">
                                        {t('number_will_be_retired_soon_if_not_assigned')}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                );
            });
        };

        return (
            <Fragment>
                <div className="number-title">{t('available_numbers')}</div>
                {_renderNumber()}
            </Fragment>
        );
    };

    const _assignedNumbers = () => {
        if (!assinged.length) return null;

        const _renderNumber = () => {
            return assinged.map((item) => {
                const { id, phone_number, assignees } = item;
                const findUser = companyUsers.find((user) => parseInt(user.id) === assignees[0]);
                const { avatar, full_name } = findUser || {};
                return (
                    <div key={id} className="border-bottom-border-color-grey">
                        <div className="number-items flex-betweenitems gap-8">
                            <span className="txt-ellipsis flex-1">{formatPhoneNumberVoip(phone_number)}</span>
                            <span className="schedule-user">
                                <span className="tech-name m-0">
                                    <img className="avt-img" src={avatar} width={24} height={24} />
                                    <span className="black pr-2 txt-ellipsis">{full_name}</span>
                                </span>
                            </span>
                        </div>
                    </div>
                );
            });
        };

        return (
            <Fragment>
                <div className="number-title">{t('numbers_assigned')}</div>
                {_renderNumber()}
            </Fragment>
        );
    };

    const buttonDropdown = () => {
        if (!countNumber) return null;

        return {
            buttonClassName: 'dropbtn items',
            customButton: (
                <Fragment>
                    <p className="txt-ellipsis">{formatPhoneNumberVoip(personal_number[0].phone_number)}</p>
                    {countNumber > 1 ? (
                        <span className="budget ml-1 bg-black-extradark black-jungle-green">+{countNumber - 1}</span>
                    ) : null}
                    <IconArrowDown />
                </Fragment>
            )
        };
    };

    const _handleUnassign = (numberId) => {
        const _handleSuccess = () => {
            onAssign({ id: userId, isUnassign: true, dataNumber: { id: numberId } });
            onUnassignPersonal({ numberId, id: userId });
        };

        clientQuery(
            assignVoipNumber(userId),
            {
                method: 'PUT',
                toFormData: false,
                data: { number_id: numberId, unassign: 1 }
            },
            _handleSuccess
        );
    };

    const _handleAssignAvailable = ({ numberId, phone_number }) => {
        const _handleSuccess = () => {
            onAssignPersonal({ numberId, userId });
            onAssign({ id: userId, dataNumber: { id: numberId, phone_number } });
        };

        clientQuery(
            assignVoipNumber(userId),
            {
                method: 'PUT',
                toFormData: false,
                data: { number_id: numberId, unassign: 0 }
            },
            _handleSuccess
        );
    };

    const _handleSwapNumber = ({ newUserId }) => {
        const _handleSuccess = () => {
            onSwapNumber({ oldUserId: userId, newUserId });
            onSwap({ userId, newUserId, dataOld: personal_number });
        };

        clientQuery(
            swapVoipNumber(userId),
            {
                method: 'PUT',
                toFormData: false,
                data: { user_id: +newUserId }
            },
            _handleSuccess
        );
    };

    return (
        <DropdownPopper
            ref={refDropDown}
            wrapperClassName="v2-dropdown"
            buttonClassName="dropbtn items --without-number"
            wrapperListClass="v2-dropdown__menu select-phone-number"
            placement="bottom"
            isUseToggle
            {...buttonDropdown()}
        >
            <div className="scrolls select-phone-number__container">
                {personal_number?.map(_unassignNumber)}
                {_swapAnotherUser()}
                {_availableNumbers()}
                {_assignedNumbers()}
            </div>
        </DropdownPopper>
    );
};
