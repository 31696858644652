import React, { useEffect, useReducer, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { useSelector } from 'react-redux';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const TaxDetail = ({ classParent, taxDetail, onSelect, handleOpenForm }) => {
    const EMPTY_TAX = { id: '', name: '' };
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const { list_taxes } = useSelector((store) => store.taxesReducer);

    const refDropdown = useRef(null);
    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_list_invoice_item');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_list_invoice_item');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    };

    function _handleSelectTax(e, taxItem, isActive) {
        e.stopPropagation();

        onSelect(isActive ? null : taxItem);
        _closeSearchResult();
    }

    const _handleOpenForm = (e) => {
        e.stopPropagation();
        handleOpenForm();
    };

    function _renderListTaxes() {
        const selectedTaxId = taxDetail?.id;
        const finallist = selectedTaxId ? [EMPTY_TAX, ...list_taxes] : list_taxes;

        return finallist.map((item) => {
            const taxId = item.id;
            const isActive = taxId === selectedTaxId;

            return (
                <li
                    onClick={(e) => _handleSelectTax(e, item, isActive)}
                    className={`items ${isActive ? 'active' : ''}`}
                    key={taxId}
                    tabIndex="0"
                >
                    {item.name}
                </li>
            );
        });
    }

    return (
        <div className={`col col-tax col-10 ${classParent}`}>
            <div className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
                <div className="dropbtn items justify-space-between" onClick={(e) => _handleOpen(e)} tabIndex="0">
                    <p className="txt-ellipsis">{taxDetail?.name || ''}</p>
                    <span className="svg-selectbox">
                        <IconDropDown />
                    </span>
                </div>
                <div className="v2-dropdown__menu scrolls content-full" id="show_result_list_invoice_item">
                    <ul>
                        {_renderListTaxes()}
                        <div className="line" />
                        <li onClick={_handleOpenForm} tabIndex="0" className="items">
                            New
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default TaxDetail;
