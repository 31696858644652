import React from 'react';

export default function IconFille({ isBroken }) {
    if (isBroken) {
        return (
            <svg width={24} height={24} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.6665 3.33325H9.99984C9.11578 3.33325 8.26794 3.68444 7.64281 4.30956C7.01769 4.93468 6.6665 5.78253 6.6665 6.66659V33.3333C6.6665 34.2173 7.01769 35.0652 7.64281 35.6903C8.26794 36.3154 9.11578 36.6666 9.99984 36.6666H29.9998C30.8839 36.6666 31.7317 36.3154 32.3569 35.6903C32.982 35.0652 33.3332 34.2173 33.3332 33.3333V14.9999L21.6665 3.33325Z"
                    stroke="#FF8D28"
                    strokeWidth="1.81818"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.6665 3.33325V14.9999H33.3332"
                    stroke="#FF8D28"
                    strokeWidth="1.81818"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path d="M15 20.4546L25 30.4546" stroke="#FF8D28" strokeWidth="1.81818" strokeLinecap="round" />
                <path d="M24.7727 20.4546L15 30.2273" stroke="#FF8D28" strokeWidth="1.81818" strokeLinecap="round" />
            </svg>
        );
    }
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                stroke="#4D5054"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M16.5 4.5h-10a2 2 0 00-2 2v8.879A5.121 5.121 0 009.621 20.5H16.5a2 2 0 002-2v-12a2 2 0 00-2-2z"
                clipRule="evenodd"
            ></path>
            <path
                stroke="#4D5054"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.3"
                d="M4.5 13.5v1a2 2 0 002 2 2 2 0 012 2 2 2 0 002 2h1.008M8.504 8.5H14.5M8.504 12.5H14.5M11.504 16.5H14.5"
            ></path>
        </svg>
    );
}
