import React from 'react';
import { useTranslation } from 'react-i18next';

import IconChevron from 'assets/icon/IconChevron';
import IconClose from 'assets/icon/IconClose';

const HeaderConversation = ({ onClose = () => {} }) => {
    const { t } = useTranslation('addons');

    return (
        <div className="message-header">
            <div className="flexcenter gap-8">
                <span className="svg-green-fill">
                    <IconChevron type="right_green" />
                </span>
                <span className="green-default fw-700 fs-15">{t('test_chatbot')}</span>
            </div>
            <div className="v2-btn-default --icon-lg --transparent" onClick={onClose}>
                <IconClose />
            </div>
        </div>
    );
};

export default HeaderConversation;
