import React from 'react';

const LoadingDocument = () => {
    return (
        <div className="wrapper-dashboard">
            <div className="container-column">
                {/* Loading Document Header */}
                <div className="wrap-loading">
                    <div className="header-modal flex-auto mb-2">
                        <div className="name-tabs">
                            <div className="ml-2 w-100 d-flex align-center">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="v2-btn-default loading --grey btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                {/* Loading Document - Item */}
                <div className="wrap-loading box-auto">
                    <div className="rows rows-menu">
                        <div className="rows__name ml-5">
                            <div className="loading --animation v2-btn-default --transparent --icon-sm" />
                            <div className="name w-100">
                                <div className="loading --animation --line --twothird" />
                            </div>
                        </div>
                        <div className="rows__status">
                            <div className="v2-btn-default loading --grey btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="loading --animation v2-btn-default --transparent --icon-sm" />
                    </div>
                </div>
            </div>
        </div>
    );
};

const LoadingDocumentType = () => {
    return (
        <div className="wrap-loading">
            <div className="document-list">
                <div className="document-rows">
                    <div className="action-document">
                        <div className="add-document">
                            <div className="v2-btn-default loading --grey btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="docs-name">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
                <div className="document-rows">
                    <div className="action-document">
                        <div className="add-document">
                            <div className="v2-btn-default loading --grey btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="docs-name">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
                <div className="document-rows">
                    <div className="action-document">
                        <div className="add-document">
                            <div className="v2-btn-default loading --grey btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="docs-name">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
                <div className="document-rows">
                    <div className="action-document">
                        <div className="add-document">
                            <div className="v2-btn-default loading --grey btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="docs-name">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export { LoadingDocument, LoadingDocumentType };
