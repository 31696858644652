import { REPORT_PAYMENT_DELETE, REPORT_PAYMENT_GET_LIST, REPORT_PAYMENT_SEARCH } from 'app/const/Api';
import { deleteWithToken, fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListReportPayment({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENT_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* searchReportPayment({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_PAYMENT_SEARCH, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteReportPayment({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, REPORT_PAYMENT_DELETE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// eslint-disable-next-line require-yield
export function* syncQbReport({ actionSuccess, actionFailed }) {
    try {
        //const response = yield call(postWithToken, REPORT_PAYMENT_SYNCQB, params);
        const response = {
            success: true
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

// eslint-disable-next-line require-yield
export function* getListMethodFilter({ actionSuccess, actionFailed }) {
    try {
        // const response = yield call(postWithToken, REPORT_ESTIMATE_SEND_MAIL, params);
        const response = {
            data: [
                { id: 1, value: 'cash', name: 'Cash' },
                { id: 2, value: 'check', name: 'Check' },
                { id: 3, value: 'credit', name: 'Credit' },
                { id: 4, value: 'credit_card', name: 'Credit Card' },
                { id: 5, value: 'stripe', name: 'Stripe' },
                { id: 6, value: 'square', name: 'Square' },
                { id: 7, value: 'bank_account_ach', name: 'Bank Account ACH' }
            ],
            success: true
        };
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
