export default function IconAddonDocument({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    d="M12 8H20L24 12V23C24 24.1046 23.1046 25 22 25H12C10.8954 25 10 24.1046 10 23V10C10 8.89543 10.8954 8 12 8Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    d="M10 6H18L22 10V21C22 22.1046 21.1046 23 20 23H10C8.89543 23 8 22.1046 8 21V8C8 6.89543 8.89543 6 10 6Z"
                    fill="#C0E5CF "
                />{' '}
                <path d="M14 8H10V9H14V8Z" fill="#1EAA5C" /> <path d="M14 10H10V11H14V10Z" fill="#1EAA5C" />{' '}
                <path d="M20 13H10V14H20V13Z" fill="#1EAA5C" /> <path d="M20 18H10V19H20V18Z" fill="#1EAA5C" />{' '}
                <path d="M18 15H10V16H18V15Z" fill="#1EAA5C" /> <path d="M19 20H10V21H19V20Z" fill="#1EAA5C" />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 6H18L22 10V11H18C17.4477 11 17 10.5523 17 10V6Z"
                    fill="#1EAA5C"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M12 8H20L24 12V23C24 24.1046 23.1046 25 22 25H12C10.8954 25 10 24.1046 10 23V10C10 8.89543 10.8954 8 12 8Z"
                    fill="#4D5054"
                />
                <path
                    d="M10 6H18L22 10V21C22 22.1046 21.1046 23 20 23H10C8.89543 23 8 22.1046 8 21V8C8 6.89543 8.89543 6 10 6Z"
                    fill="#C1C9D4"
                />
                <path d="M14 8H10V9H14V8Z" fill="#4D5054" /> <path d="M14 10H10V11H14V10Z" fill="#4D5054" />
                <path d="M20 13H10V14H20V13Z" fill="#4D5054" /> <path d="M20 18H10V19H20V18Z" fill="#4D5054" />
                <path d="M18 15H10V16H18V15Z" fill="#4D5054" /> <path d="M19 20H10V21H19V20Z" fill="#4D5054" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 6H18L22 10V11H18C17.4477 11 17 10.5523 17 10V6Z"
                    fill="#4D5054"
                />
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                d="M12 8H20L24 12V23C24 24.1046 23.1046 25 22 25H12C10.8954 25 10 24.1046 10 23V10C10 8.89543 10.8954 8 12 8Z"
                fill="#8d4afc"
            />
            <path
                d="M10 6H18L22 10V21C22 22.1046 21.1046 23 20 23H10C8.89543 23 8 22.1046 8 21V8C8 6.89543 8.89543 6 10 6Z"
                fill="#dbcbff"
            />
            <path d="M14 8H10V9H14V8Z" fill="#8d4afc" /> <path d="M14 10H10V11H14V10Z" fill="#8d4afc" />
            <path d="M20 13H10V14H20V13Z" fill="#8d4afc" /> <path d="M20 18H10V19H20V18Z" fill="#8d4afc" />
            <path d="M18 15H10V16H18V15Z" fill="#8d4afc" /> <path d="M19 20H10V21H19V20Z" fill="#8d4afc" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 6H18L22 10V11H18C17.4477 11 17 10.5523 17 10V6Z"
                fill="#8d4afc"
            />
        </svg>
    );
}
