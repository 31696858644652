import React, { useImperativeHandle, useReducer, forwardRef, useEffect } from 'react';
import SelectHour from 'app/modules/jobdetail/tabs/settingschedule/time/SelectHour';
import SelectMinute from 'app/modules/jobdetail/tabs/settingschedule/time/SelectMinute';
import { reducer } from 'app/const/Reducer';

const JobLength = forwardRef(({ minute, hour, isShowTitle = true, onChange = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        minute,
        hour
    });

    const { hour: finalHour, minute: finalMinute } = state;

    useEffect(() => {
        if (finalHour !== hour || finalMinute !== minute) {
            dispatchState({
                minute,
                hour
            });
        }
    }, [minute, hour]);

    useEffect(() => {
        if (finalHour !== hour || minute !== finalMinute) {
            onChange();
        }
    }, [finalHour, finalMinute]);

    useImperativeHandle(ref, () => ({
        _getTime: () => {
            return state;
        }
    }));

    function _handleChangeMinute(value) {
        dispatchState({ minute: value });
    }

    function _handleChangeHour(value) {
        dispatchState({ hour: value });
    }

    return (
        <div className="field-length">
            {isShowTitle ? 'Length' : ''}
            <div className="field-input wrapper-choosetime">
                <SelectHour selected={finalHour} onSelect={_handleChangeHour} />
                <SelectMinute selected={finalMinute} onSelect={_handleChangeMinute} />
            </div>
        </div>
    );
});

export default JobLength;
