export const TYPE_SETTING_LINE_ITEM = {
  GET_LIST: "SETTING_GET_LIST_LINE_ITEMS",
  CREATE: "SETTING_CREATE_LINE_ITEMS",
  UPDATE: "SETTING_UPDATE_LINE_ITEMS",
  UPDATE_STATUS: "SETTING_UPDATE_STATUS_LINE_ITEMS",
  GET_LIST_TAXES: "SETTING_GET_LIST_TAXES",
}

// actions
export const getListSettingLineItems = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_LINE_ITEM.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}


export const createLineItem = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_LINE_ITEM.CREATE,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateLineItem = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_LINE_ITEM.UPDATE,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateStatusSettingLineItems = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_LINE_ITEM.UPDATE_STATUS,
    params,
    actionSuccess,
    actionFailed
  }
}

// actions
export const getListTaxesSetting = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_SETTING_LINE_ITEM.GET_LIST_TAXES,
    params,
    actionSuccess,
    actionFailed
  }
}