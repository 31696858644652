import React, { useRef } from 'react';
import IconTooltip from 'assets/icon/IconTooltip';
import EditCompanyAddress from './EditCompanyAddress';

const BoxHeader = ({ companyAddress = '', onEditAddress = () => {} }) => {
    const refEditAddress = useRef(null);

    const _handleEditAddress = () => refEditAddress.current._open(companyAddress);

    return (
        <>
            <div className="boxs__header has-form">
                <h5 className="has-form__label fs-13 mb-1">
                    Company Address
                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext top">
                            Changes the address of all your new and existing documents.
                        </span>
                    </div>
                </h5>
                <p className="desc" dangerouslySetInnerHTML={{ __html: companyAddress }} />
                <div className="v2-btn-default btn-modal" onClick={_handleEditAddress}>
                    Edit
                </div>
            </div>
            <EditCompanyAddress ref={refEditAddress} onEditAddress={onEditAddress} />
        </>
    );
};

export default BoxHeader;
