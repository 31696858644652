import { STATUS } from 'app/modules/calendar/const/SideBar';
import React from 'react';

const StatusBars = ({ tab, onSelect }) => {
    function _selectTab(selected) {
        selected !== tab && onSelect(selected);
    }

    function _renderTabs() {
        return Object.keys(STATUS).map((item) => {
            const itemTab = STATUS[item];
            return (
                <div
                    key={item.toString()}
                    className={`tab-items text-capitalize ${tab === itemTab ? 'active-tab-selector' : ''} ? `}
                    onClick={() => _selectTab(STATUS[item])}
                >
                    {item.toLowerCase()}
                </div>
            );
        });
    }

    return <div className="btn-item ml-0">{_renderTabs()}</div>;
};

export default StatusBars;
