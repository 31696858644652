import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";

import {
  LIST_VALUE_VARIABLE_TITLE,
  replaceInfoUserInfo,
  replaceInfoVariables
} from "app/const/drip/InsertVariables";

function DripPreviewSms({
  content,
  classFooter
}) {
  const { t } = useTranslation(["drip"]);

  let previewSms = content || "<p></p>";

  previewSms = replaceInfoUserInfo(previewSms);
  previewSms = replaceInfoVariables(LIST_VALUE_VARIABLE_TITLE, previewSms, t);

  if (!previewSms) return null;

  return (
    <div className={`container-footer-email ${classFooter || ""}`} dangerouslySetInnerHTML={{ __html: previewSms }} />
  )
}

DripPreviewSms.propTypes = {
  content: PropTypes.string
}

const compare = (prevProps, nextProps) => {
  return prevProps.content == nextProps.content && prevProps.classFooter == nextProps.classFooter
}

export default React.memo(DripPreviewSms, compare);

