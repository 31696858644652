import { TYPE_INBOX } from 'common/redux/actions/inboxAction';

const inboxReducer = (state = {
    total: 0
}, action) => {
    switch (action.type) {
        case TYPE_INBOX.GET_INBOX_COUNTER_SUCCESS:
            return { ...state, total: action.payload };
        case TYPE_INBOX.SET_INBOX_COUNTER:
            return { ...state, total: action.payload };
        default:
            return state;
    }
};

export default inboxReducer;
