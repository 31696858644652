import { LIMIT_SMART_VIEW_DATA } from '../../constants';
import {
    CUSTOM_ID,
    FUTURE_ID,
    NOW_ID,
    PAST_ID,
    RANGE_BEFORE_AFTER,
    RANGE_PAST_FUTURE,
    RANGE_START_END
} from '../../constants/dateOptions';
import { TEXT_MODES } from '../../constants/textOptions';
import { FIELDS_TYPE, OBJECT_TYPES, OPERATOR_IDS, OPERATOR_IDS_DROPDOWN } from '../../constants/types';
import { getValueString } from '../listFiltered/utils';
import { getColumnIncludes } from './columns';
import { getField } from './index';
import { getNegative } from './operator';
import { sortToQuery } from './sortQuery';
import { DEFAULT_COLUMN_NAME, convertColumnsName } from '../table/utils';
import { COLUMNS_BUILT_IN } from '../../constants/columns';

const getDate = (operatorId, isAfter, valueDate, customDate, offset) => {
    if (!valueDate) return {};

    const whichDay = () => {
        switch (operatorId) {
            case OPERATOR_IDS.ON_OR_BEFORE:
            case OPERATOR_IDS.ON_OR_AFTER:
            case OPERATOR_IDS.BETWEEN:
            case OPERATOR_IDS.NOT_BETWEEN:
            case OPERATOR_IDS.IS:
            case OPERATOR_IDS.NOT:
                return isAfter ? RANGE_START_END.START : RANGE_START_END.END;
            case OPERATOR_IDS.BEFORE:
            case OPERATOR_IDS.AFTER:
            default:
                return isAfter ? RANGE_START_END.END : RANGE_START_END.START;
        }
    };

    const transformString = (string) => (string ? string.toUpperCase().split(' ').join('_') : undefined);

    let timeValue;
    const keyObjectMoment = isAfter ? RANGE_BEFORE_AFTER.ON_OR_AFTER : RANGE_BEFORE_AFTER.BEFORE;

    switch (valueDate) {
        case CUSTOM_ID:
            if (!customDate) return {};

            timeValue = {
                fixedLocalDate: {
                    value: customDate,
                    which: whichDay()
                }
            };
            break;
        case NOW_ID:
            timeValue = { now: { _: !0 } };
            break;
        case PAST_ID:
        case FUTURE_ID:
            if (!offset || !Object.values(offset).filter(Boolean).length) return {};
            timeValue = {
                offset: {
                    offset,
                    direction: valueDate === PAST_ID ? RANGE_PAST_FUTURE.PAST : RANGE_PAST_FUTURE.FUTURE,
                    whichDayEnd: whichDay()
                }
            };
            break;
        default:
            timeValue = { startEndOfPredefinedRange: { range: transformString(valueDate), which: whichDay() } };
    }
    return { [keyObjectMoment]: timeValue };
};

const getRange = ({ direction, offset }) => {
    return {
        momentRange: {
            onOrAfterMoment:
                direction === RANGE_PAST_FUTURE.FUTURE
                    ? { now: {} }
                    : { offset: { offset: offset, direction: direction } },
            beforeMoment:
                direction === RANGE_PAST_FUTURE.FUTURE
                    ? { offset: { offset: offset, direction: direction } }
                    : { now: {} }
        }
    };
};

const getMomentRange = (operatorId, value) => {
    const isPresent = [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT].includes(operatorId);
    const isBetween = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN].includes(operatorId);
    const isWithin = [OPERATOR_IDS.WITHIN, OPERATOR_IDS.NOT_WITHIN].includes(operatorId);

    if (isPresent) return { exists: {} };
    if (isWithin) {
        if ([PAST_ID, FUTURE_ID].includes(value.value)) {
            const [e, a, ...o] = getValueString(value, operatorId).split(' ');
            const keyName = Number(e) <= 1 ? `${a}s` : a;
            return getRange({
                direction: 'from now' === o.join(' ') ? RANGE_PAST_FUTURE.FUTURE : RANGE_PAST_FUTURE.PAST,
                offset: { ...value.offset, [keyName]: Number(e) }
            });
        }

        if (value?.value?.includes(PAST_ID) || value?.value?.includes(FUTURE_ID)) {
            const stringSplit = getValueString(value, operatorId).split(' ');
            const keyName = Number(stringSplit[2]) <= 1 ? `${stringSplit[3]}s` : stringSplit[3];
            return getRange({
                direction: value.value.includes(FUTURE_ID) ? RANGE_PAST_FUTURE.FUTURE : RANGE_PAST_FUTURE.PAST,
                offset: { [keyName]: Number(stringSplit[2]) }
            });
        }
    }

    if (isBetween) {
        if (!value) return undefined;
        const { value: valueDate, customDate, offset, value2, customDate2, offset2 } = value;
        return {
            momentRange: {
                ...getDate(operatorId, !0, valueDate, customDate, offset),
                ...getDate(operatorId, !1, value2, customDate2, offset2)
            }
        };
    }

    const { value: valueDate, customDate, offset } = value || {};
    return {
        momentRange: {
            ...([OPERATOR_IDS.BEFORE, OPERATOR_IDS.ON_OR_BEFORE].includes(operatorId)
                ? {}
                : getDate(operatorId, !0, valueDate, customDate, offset)),
            ...([OPERATOR_IDS.AFTER, OPERATOR_IDS.ON_OR_AFTER].includes(operatorId)
                ? {}
                : getDate(operatorId, !1, valueDate, customDate, offset))
        }
    };
};

const getReference = (operatorId, value) => {
    const isPresent = [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT].includes(operatorId);
    if (isPresent) return { exists: {} };
    const values = value?.set?.values?.map((item) => item?.toString());
    return { set: { values } };
};

export const getCondition = (type, operatorId, value) => {
    switch (type) {
        case FIELDS_TYPE.STATUS:
            return value;
        case FIELDS_TYPE.CREATED_BY:
        case FIELDS_TYPE.SOURCE_ID:
        case FIELDS_TYPE.STATUS_OPPORTUNITY:
        case FIELDS_TYPE.REFERENCE_TYPE:
        case FIELDS_TYPE.REFERENCE_USER:
            return getReference(operatorId, value);
        case FIELDS_TYPE.DATE:
        case FIELDS_TYPE.DATETIME:
        case FIELDS_TYPE.CREATED_DATE:
        case FIELDS_TYPE.DATE_WITHOUT_PRESENT:
        case FIELDS_TYPE.LATEST_CALL_CREATED_DATE:
        case FIELDS_TYPE.CUSTOMER_CREATED_DATE:
            const momentRange = getMomentRange(OPERATOR_IDS_DROPDOWN[operatorId].id, value);
            if (!momentRange) return null;
            return { ...momentRange };
        case FIELDS_TYPE.INTEGER:
        case FIELDS_TYPE.FLOAT:
        case FIELDS_TYPE.COUNT_SMS:
        case FIELDS_TYPE.COUNT_TASK:
        case FIELDS_TYPE.COUNT_CALL:
        case FIELDS_TYPE.COUNT_JOB:
        case FIELDS_TYPE.COUNT_OUTGOING_CALL:
            switch (operatorId) {
                case OPERATOR_IDS.NONE:
                    return {
                        numberRange: {
                            moreThan: { floatValue: 0, includeEquals: !0 },
                            lessThan: { floatValue: 0, includeEquals: !0 }
                        }
                    };
                case OPERATOR_IDS.SOME:
                    return { numberRange: { moreThan: { floatValue: 0, includeEquals: !1 } } };
                case OPERATOR_IDS.MORE:
                    return { numberRange: { moreThan: { floatValue: Number(value), includeEquals: !1 } } };
                case OPERATOR_IDS.LESS:
                    return { numberRange: { lessThan: { floatValue: Number(value), includeEquals: !1 } } };
                case OPERATOR_IDS.EXACTLY:
                case OPERATOR_IDS.NOT_EXACTLY:
                    return {
                        numberRange: {
                            moreThan: { floatValue: Number(value), includeEquals: !0 },
                            lessThan: { floatValue: Number(value), includeEquals: !0 }
                        }
                    };
                default:
                    return {
                        numberRange: {
                            moreThan: {
                                floatValue: Number(value?.moreThan?.floatValue),
                                includeEquals: value?.moreThan?.includeEquals
                            },
                            lessThan: {
                                floatValue: Number(value?.lessThan?.floatValue),
                                includeEquals: value?.lessThan?.includeEquals
                            }
                        }
                    };
            }
        case FIELDS_TYPE.DURATION:
            switch (operatorId) {
                case OPERATOR_IDS.MORE:
                    return {
                        durationRange: { moreThan: { durationValue: value?.durationValue || {}, includeEquals: !1 } }
                    };
                case OPERATOR_IDS.LESS:
                    return {
                        durationRange: { lessThan: { durationValue: value?.durationValue || {}, includeEquals: !1 } }
                    };
                case OPERATOR_IDS.EXACTLY:
                case OPERATOR_IDS.NOT_EXACTLY:
                    return {
                        durationRange: {
                            moreThan: { durationValue: value?.durationValue || {}, includeEquals: true },
                            lessThan: { durationValue: value?.durationValue || {}, includeEquals: true }
                        }
                    };
                default:
                    return {};
            }
        case FIELDS_TYPE.TEXT:
            switch (operatorId) {
                case OPERATOR_IDS.CONTAINS_WORDS_STARTING_WITH:
                case OPERATOR_IDS.NOT_CONTAINS_WORDS_STARTING_WITH:
                    return { text: { mode: TEXT_MODES.BEGINNING_OF_WORDS, value } };
                case OPERATOR_IDS.EXACT_VALUE:
                case OPERATOR_IDS.NOT_EXACT_VALUE:
                    return { text: { mode: TEXT_MODES.EXACT_VALUE, value } };
                case OPERATOR_IDS.PRESENT:
                case OPERATOR_IDS.NOT_PRESENT:
                    return { exists: {} };
                default:
                    return {};
            }
        default:
            return {};
    }
};

export const convertToQuery = (filters) => {
    return filters.flatMap((filter) => {
        const { objectType, fieldName, operatorId, value } = filter;
        const field = getField(objectType, fieldName);
        if (!field) return [];
        const condition = getCondition(field.type, operatorId, value);
        const fieldData =
            field.objectType === OBJECT_TYPES.CUSTOM_FIELD
                ? { customField: { customFieldId: fieldName } }
                : { fieldName, objectType };
        return { fieldCondition: { field: fieldData, condition, negate: getNegative(operatorId) } };
    });
};
const checkIncludeColumns = (columns, listIds = []) => {
    return !!columns.some((item) => listIds.includes(item.field_id));
};
export const getVariablesQuery = ({
    filters,
    sort,
    limit,
    columns = DEFAULT_COLUMN_NAME,
    hardReload = false,
    customFields = []
}) => {
    const { includeFieldBuiltInField, customFieldsIncluded, customJobStatusIncluded } = getColumnIncludes(
        columns,
        customFields
    );

    return {
        q: { bool: { queries: [{ bool: { queries: convertToQuery(filters) } }] } },
        sort: sortToQuery(sort),
        limit: hardReload && !limit ? LIMIT_SMART_VIEW_DATA : limit,
        customFieldsIncluded: customFieldsIncluded || [],
        includeCustomFields: !!customFieldsIncluded.length,

        customJobStatusIncluded: customJobStatusIncluded || [],
        includeCustomJobStatus: !!customJobStatusIncluded.length,
        // *** This is for check include fields ***
        ...includeFieldBuiltInField,
        includeLocation: checkIncludeColumns(columns, ['service_address', 'billing_address']),
        includeNextTask: checkIncludeColumns(columns, ['next_task', 'next_task_date'])
    };
};

export const getColumnPrint = (columns) => {
    let result = '';
    columns.forEach((column, index) => {
        if (COLUMNS_BUILT_IN[column.field_id]) {
            result += convertColumnsName(column.field_id) + (index + 1 >= columns.length ? '' : ',');
        } else {
            if (column.type_id === OBJECT_TYPES.JOB) {
                result += 'countJobStatus' + column.field_id + (index + 1 >= columns.length ? '' : ',');
            } else {
                result += column.field_id + (index + 1 >= columns.length ? '' : ',');
            }
        }
    });
    return result;
};
