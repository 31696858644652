import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { ADDONS_EMAIL_DETAIL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { SMART_VIEWS_SEND_EMAIL } from 'app/const/api/V2';
import { SETTING_TEMPLATE_TYPES } from 'app/modules/settings/emailinbox/const';
import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import { getListTemplateEmailInbox } from 'common/redux/actions/settings/templateAction';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    DEFAULT_SEND_BULK_EMAIL_OPTIONS,
    OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER,
    SMART_VIEW_CUSTOM_EVENTS
} from '../../constants';
import { useSmartView } from '../../context';
import { convertToQuery } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';
import AlertBulkEmail from './AlertBulkEmail';
import BulkEmailContent from './BulkEmailContent';
import IconLoading from 'assets/icon/IconLoading';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { SETTINGS_EMAIL_INBOX } from 'app/config/routes';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { addBranchPath } from 'app/const/Branch';

const BulkEmail = forwardRef((props, ref) => {
    const initialState = {
        isOpen: false,
        canSend: false,
        isPreviewing: false,
        type: OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER[0],
        dataSmartView: {},
        dataPreview: null
    };
    const { t } = useTranslation('smartView');
    const { count, filtered, sort, limit } = useSmartView();
    const totalSend = limit ? (limit >= count ? count : limit) : count || 0;
    const emailAddon = useSelector(({ auth }) => auth.user.settings.addons.inbox_email);
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isOpen, isPreviewing, isLoading, emailAccount, dataPreview, canSend, type } = state;

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));
    const refButtonSave = useRef(null);
    const refWarningSending = useRef(null);

    const _handleOpen = (dataSmartView = {}) => {
        dispatchState((prevState) => ({
            ...prevState,
            isOpen: true,
            isLoading: !!emailAddon,
            canSend: !!emailAddon,
            dataSmartView,
            emailAccount: null
        }));

        if (emailAddon) {
            clientQuery(ADDONS_EMAIL_DETAIL, {}, ({ data }) => {
                dispatchState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                    emailAccount: data?.account,
                    canSend: !!data?.account?.smtp?.status
                }));
            });
        }
    };

    const _handleClose = () => {
        dispatchState(initialState);
    };

    const _handleBack = () => {
        dispatchState({ isPreviewing: false });
    };

    const _handlePreview = () => {
        dispatchState({ isPreviewing: true });
    };

    const _handleSend = () => {
        const dataSend = {
            template_id: dataPreview.id,
            send_type: type.value,
            query: JSON.stringify({
                q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
                sort: sortToQuery(sort),
                limit
            })
        };
        clientQuery(
            SMART_VIEWS_SEND_EMAIL,
            { data: dataSend, method: 'POST', toFormData: false },
            _handleSendSuccess,
            _handleSendFail,
            _handleSendFinally
        );
    };

    const _handleSendSuccess = () => {
        refWarningSending.current.display();
    };

    const _handleSendFail = ({ message }) => {
        const customEvent = new CustomEvent(SMART_VIEW_CUSTOM_EVENTS.DISPLAY_ALERT, {
            detail: { notification: message[0], timeout: 3000 }
        });
        dispatchEvent(customEvent);
    };

    const _handleSendFinally = () => {
        _handleClose();
    };

    const _handleSelectTemplate = (template) => {
        dispatchState({ dataPreview: template });
    };

    const _handleSelectType = (type) => {
        dispatchState({ type });
    };

    const _handleSetLoading = (loading = false) => {
        refButtonSave.current?.setDisable(loading);
    };

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                ariaHideApp={false}
                className={classNames('modal container-modal --email-smartview open', { '--preview': isPreviewing })}
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container show-contact">
                    <div className="header-modal">
                        <h2 className="header-modal__label fs-17 fw-600">{t('send_bulk_email')}</h2>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                            <IconClose />
                        </div>
                    </div>

                    {isPreviewing ? (
                        <BulkEmailContent
                            dataEmail={emailAccount || {}}
                            count={totalSend}
                            emailId={dataPreview?.id}
                            emailName={dataPreview?.label}
                            onSetLoading={_handleSetLoading}
                        />
                    ) : (
                        <div className="body-modal">
                            {isLoading ? (
                                <div className="wrap-loading rows --lead bg-white">
                                    <div className="field-h20">
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="field-h20">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                </div>
                            ) : (
                                <WarningDisplay emailAccount={emailAccount} count={totalSend} />
                            )}

                            <div className="has-form">
                                <div className="rows">
                                    <div className="txt">{t('from')}</div>
                                    <input
                                        className="field-input is-disable"
                                        type="text"
                                        readOnly
                                        value={
                                            !emailAddon || isLoading
                                                ? t('no_email_configured')
                                                : `${emailAccount?.email}`
                                        }
                                    />
                                </div>
                                <RowWithDropdown
                                    title={t('template')}
                                    isTemplate
                                    defaultSelect={dataPreview || null}
                                    onSelect={_handleSelectTemplate}
                                />
                                <RowWithDropdown
                                    title={t('for_each_customer_email')}
                                    onSelect={_handleSelectType}
                                    defaultSelect={type}
                                    options={OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER}
                                />
                            </div>

                            {type?.value === OPTIONS_SEND_BULK_EMAIL_EACH_CUSTOMER[0].value ? (
                                <div className="rows border-top-grey-verylight-brown">
                                    <p className="txt-ellipsis">{t('smart_view_warning_send_bulk_email')}</p>
                                </div>
                            ) : null}
                        </div>
                    )}

                    <div className="footer-modal btn-close">
                        {isPreviewing ? (
                            <div className="flex-1">
                                <div className="v2-btn-default has-icon --grey" onClick={_handleBack}>
                                    <IconArrow isPrev />
                                    {t('back')}
                                </div>
                            </div>
                        ) : null}

                        <span className="v2-btn-default --transparent" onClick={_handleClose}>
                            {t('cancel')}
                        </span>

                        <ButtonSave
                            ref={refButtonSave}
                            disabled={!canSend || isLoading || !dataPreview}
                            isNotLoading={!isPreviewing}
                            title={
                                isPreviewing
                                    ? totalSend > 1
                                        ? t('send_email_to_leads', { count: totalSend })
                                        : t('send_email_to_lead', { count: totalSend })
                                    : t('preview')
                            }
                            onSave={isPreviewing ? _handleSend : _handlePreview}
                        />
                    </div>
                </div>
            </ReactModal>
            <WarningSending ref={refWarningSending} />
        </>
    );
});

const WarningSending = forwardRef((props, ref) => {
    const [isDisplay, setIsDisplay] = useState(false);

    useImperativeHandle(ref, () => ({ display: _handleOpen, hide: _handleClose }));

    useEffect(() => {
        const timer = setTimeout(() => {
            _handleClose();
        }, 3000);
        return () => clearTimeout(timer);
    }, [isDisplay]);

    const _handleClose = () => {
        setIsDisplay(false);
    };

    const _handleOpen = () => {
        setIsDisplay(true);
    };

    if (!isDisplay) return null;
    return (
        <div className="alert --sending-email">
            <p className="alert__description">Sending email...</p>
            <div className="alert__btn svg-white-stroke">
                <IconClose />
            </div>
        </div>
    );
});

const WarningDisplay = ({ count, emailAccount }) => {
    const { t } = useTranslation('smartView');
    const getMailName = (typeEmail) => {
        switch (typeEmail) {
            case 'google':
                return 'Gmail';
            case 'microsoft':
                return 'Outlook';
            case 'imap':
                return 'SMTP/IMAP';
            default:
                return typeEmail;
        }
    };

    const typeEmail = emailAccount?.type || '';
    const nameEmail = getMailName(typeEmail);
    if (!emailAccount) {
        return (
            <AlertBulkEmail
                title="Connect to an email account is required for Bulk Email."
                content="Please have an authorized admin to update your settings under Add Ons > Email Inbox"
            />
        );
    } else if (!!!emailAccount?.smtp?.status) {
        return (
            <AlertBulkEmail
                title="You haven't enabled the option to send emails using this account."
                content="Please have an authorized admin to update your settings under Add Ons > Email Inbox"
            />
        );
    }

    return (
        <>
            <div className="rows --lead">
                <p className="txt-ellipsis fw-600">
                    {count > 1 ? t('send_email_to_leads', { count }) : t('send_email_to_lead', { count })}
                </p>
                <p className="txt-ellipsis grey-very-dark">{t('configure_email_below')}</p>
            </div>
            {count > 500 ? (
                <AlertBulkEmail
                    title={`Are you sure you want to use ${nameEmail}?`}
                    desc={`You may be at risk of exceeding ${nameEmail}'s daily sending limit. We recommend checking your limit or using a bulk email provider to avoid deliverability issues.`}
                />
            ) : null}
        </>
    );
};

const RowWithDropdown = ({
    title = '',
    defaultSelect = null,
    options: defaultOptions,
    isTemplate = false,
    onSelect = () => {}
}) => {
    const convertData = (item) => {
        if (!item) return null;
        return { id: item.id, value: item.id, label: item.name };
    };
    const refDropdown = useRef(null);
    const dispatch = useDispatch();
    const { isFirstTime, data: dataTemplate } = useSelector(
        ({ templates }) => templates[SETTING_TEMPLATE_TYPES.EMAIL_INBOX]
    );

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: isFirstTime && isTemplate,
        selected: !!!defaultSelect
            ? DEFAULT_SEND_BULK_EMAIL_OPTIONS
            : defaultSelect || convertData(dataTemplate) || defaultOptions?.[0] || {},
        options: defaultOptions || dataTemplate?.map((item) => convertData(item)) || []
    });
    const { options, selected, isLoading } = state;

    const _handleVisible = () => {
        if (!isFirstTime || !isTemplate) return;
        const _handleGetSuccess = ({ data }) => {
            dispatchState((prevState) => ({
                ...prevState,
                isLoading: false,
                options: data.map((item) => convertData(item))
            }));
        };
        dispatch(getListTemplateEmailInbox({}, _handleGetSuccess));
    };

    const _handleSelect = (id) => {
        dispatchState((prevState) => {
            const selected = prevState.options.find((item) => item.id === id);
            onSelect(selected);
            return { ...prevState, selected };
        });
        refDropdown.current?._close();
    };

    return (
        <div className="rows mt-0">
            <div className="txt">{title}</div>
            <DropdownPopper
                id={title}
                ref={refDropdown}
                isCalculateWidth
                isUseToggle
                wrapperClassName="v2-dropdown"
                buttonClassName="dropbtn items method-select"
                customButton={<ButtonCustom selected={selected?.label || ''} />}
                onOpen={_handleVisible}
                onSelect={_handleSelect}
            >
                <ListData
                    data={options}
                    selected={selected}
                    isLoading={isLoading}
                    isTemplate={isTemplate}
                    onSelect={_handleSelect}
                />
            </DropdownPopper>
        </div>
    );
};

const ListData = ({ data = [], selected = {}, isLoading = true, isTemplate = false, onSelect = () => {} }) => {
    if (isLoading) {
        return (
            <div className="items justify-center">
                <div className="loading -ajaxbar">
                    <IconLoading />
                </div>
            </div>
        );
    }
    return (
        <ul>
            {data.map((item) => (
                <li
                    key={item.id}
                    className={classNames('items', { active: selected.id === item.id })}
                    onClick={() => onSelect(item.id)}
                >
                    <span className="txt-ellipsis">{item.label}</span>
                </li>
            ))}
            {isTemplate && !data.length ? (
                <Link to={addBranchPath(SETTINGS_EMAIL_INBOX)} className="items">
                    <div className="mx-1">
                        <IconCogWheel />
                    </div>
                    <span className="txt-ellipsis">Manage Email Templates</span>
                </Link>
            ) : null}
        </ul>
    );
};

const ButtonCustom = ({ selected = '' }) => {
    return (
        <>
            <span className="txt-ellipsis">{selected}</span>
            <IconArrowDown />
        </>
    );
};

export default BulkEmail;
