export const TYPE_REPORT_INVOICE = {
  GET_LIST: "GET_LIST_REPORT_INVOICE",
  GET_LIST_SUCCESS: "GET_LIST_REPORT_INVOICE_SUCCESS",
  GET_LIST_ERROR: "GET_LIST_REPORT_INVOICE_ERROR",

  SEARCH: "SEARCH_REPORT_INVOICE",
  SEARCH_SUCCESS: "SEARCH_REPORT_INVOICE_SUCCESS",
  SEARCH_ERROR: "SEARCH_REPORT_INVOICE_ERROR",

  DELETE_INVOICE_REQUEST: "DELETE_INVOICE_REQUEST",
  DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
  DELETE_INVOICE_ERROR: "DELETE_INVOICE_ERROR",
  ARCHIVE_INVOICE_REQUEST: "ARCHIVE_INVOICE_REQUEST",
  ARCHIVE_INVOICE_SUCCESS: "ARCHIVE_INVOICE_SUCCESS",
  ARCHIVE_INVOICE_ERROR: "ARCHIVE_INVOICE_ERROR",
  GET_INVOICE_SERVICE_FILTER: "GET_INVOICE_SERVICE_FILTER"
}


// actions
export const getListReportInvoice = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_INVOICE.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const searchReportInvoice = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_INVOICE.SEARCH,
    params,
    actionSuccess,
    actionFailed
  }
}

export const deleteInvoicesRequest = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_INVOICE.DELETE_INVOICE_REQUEST,
    params,
    actionSuccess,
    actionFailed
  };
};

export const archiveInvoicesRequest = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_INVOICE.ARCHIVE_INVOICE_REQUEST,
    params,
    actionSuccess,
    actionFailed
  };
};

export const getInvoiceServiceFilterRequest = (actionSuccess, actionFailed) => {
  return {
    type: TYPE_REPORT_INVOICE.GET_INVOICE_SERVICE_FILTER,
    actionSuccess,
    actionFailed
  };
}