export const JOB_PREVIEW_ACTIONS = {
    JOB_PREVIEW: 'ACTION_OPEN_JOB_PREVIEW',
    JOB_ASSIGN: 'ACTION_JOB_REASSIGN',
    DELETE_JOB: 'ACTION_DELETE_JOB',
    GET_JOB_SUMMARY: 'GET_JOB_SUMMARY',
    UPDATE_JOB_STATUS: 'ACTION_UPDATE_JOB_STATUS',
    UPDATE_JOB_STATUS_SUCCESS: 'UPDATE_JOB_STATUS_SUCCESS',
    OPEN_TIME_OFF: 'OPEN_TIME_OFF',
    UPDATE_JOB_TIME_WINDOW: 'UPDATE_JOB_TIME_WINDOW',
    UPDATE_JOB_EMOJI: 'UPDATE_JOB_EMOJI'
};

export const actionUpdateJobTimeWindow = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.UPDATE_JOB_TIME_WINDOW,
        payload
    };
};

export const actionOpenTimeOff = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.OPEN_TIME_OFF,
        payload
    };
};

export const actionOpenJobPreview = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.JOB_PREVIEW,
        payload
    };
};

export const actionJobReassign = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.JOB_ASSIGN,
        payload
    };
};

export const actionDeleteJob = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.DELETE_JOB,
        payload
    };
};

export const actionGetJobSummary = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: JOB_PREVIEW_ACTIONS.GET_JOB_SUMMARY,
        params,
        actionSuccess,
        actionFailed
    };
};

export const actionUpdateJobStatus = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: JOB_PREVIEW_ACTIONS.UPDATE_JOB_STATUS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const actionUpdateEmoji = (payload) => {
    return {
        type: JOB_PREVIEW_ACTIONS.UPDATE_JOB_EMOJI,
        payload
    };
};
