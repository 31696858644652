import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { validateEmail } from 'common/utils/EmailUtils';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { SetupEmailContext } from '../../context/SetupEmailContext';
import { reducer } from 'app/const/Reducer';

const StepSelectCustomer = (props, ref) => {
    const { email } = useContext(SetupEmailContext);
    const users = useSelector(({ companyUsers }) => companyUsers.users);
    const idCurrentUser = useSelector(({ auth }) => auth.user.profile.id);
    const [state, dispatchState] = useReducer(reducer, { options: [], selected: {}, isAutomatically: true });
    const { options, selected } = state;
    const refDropdown = useRef(null);
    const refInput = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: _getValue }));

    useEffect(() => {
        let selected = {};
        const newOptions = [];

        users.forEach((item) => {
            const newItem = { ...item, full_name: `${item.first_name} ${item.last_name}` };
            if (parseInt(item.id) === idCurrentUser) selected = newItem;
            newOptions.push(newItem);
        });

        dispatchState({
            options: newOptions,
            selected
        });
    }, []);

    const _handleSelect = (value) => {
        const selected = options.find((item) => item.id === value);
        dispatchState({ selected });
    };

    const _getValue = () => {
        let errors = [];
        const email = refInput.current.value;
        if (!validateEmail(email)) errors = ['Email is not a valid email address.'];
        return { customer: selected, email, error: errors };
    };

    return (
        <div className="step show">
            <div className="row">
                <p>Enter the name and email address you would like to send from.</p>
            </div>
            <div className="row">
                <div className="txt">Name</div>
                <CalendarDropdown
                    ref={refDropdown}
                    id="setup_email_dropdown_customer"
                    selected={selected.full_name}
                    options={options}
                    keyGetKey="id"
                    keyGetName="full_name"
                    keyGetValue="id"
                    onSelect={_handleSelect}
                />
            </div>
            <div className="row">
                <div className="txt">Email Address</div>
                <input
                    ref={refInput}
                    className="field-input"
                    placeholder="name@yourcompany.com"
                    type="text"
                    defaultValue={email}
                />
            </div>
        </div>
    );
};

export default forwardRef(StepSelectCustomer);
