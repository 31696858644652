import ButtonSave from 'app/components/button/ButtonSave';
import { GET_LIST_SETTING_COMPANY_PHONE_TYPE } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

const CreatePhoneLabel = forwardRef(({ onAddSuccess = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { isOpen, idPhone } = state;

    const refInput = useRef(null);
    const refAlert = useRef(null);
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (idPhone) => {
        dispatchState({ isOpen: true, idPhone });
    };

    const _close = () => dispatchState({ isOpen: false });

    const _handleAddNewPhone = () => {
        refAlert.current.closeStatusBar();

        const _handleSuccess = ({ data }) => {
            onAddSuccess({ ...data, idPhone });
            _close();
        };

        const _handleFailed = ({ message }) => {
            refAlert.current.showStatusBar({ id: 'create_phone_type_error', message, type: LIST_STATUS.ERROR });
            refButtonSave.current.removeLoading();
        };

        clientQuery(
            GET_LIST_SETTING_COMPANY_PHONE_TYPE,
            { data: { name: refInput.current.value }, toFormData: false, method: 'POST' },
            _handleSuccess,
            _handleFailed
        );
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="new_phone_label"
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            portalClassName="ReactModalPortal_customer"
            onRequestClose={_close}
            className="modal --has-contain container-modal --form-no-border open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --md">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('new_phone_label')}</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal has-form">
                    <AlertCustomer ref={refAlert} />
                    <p className="has-form__label">{t('phone_label')}</p>
                    <input
                        ref={refInput}
                        className="field-input"
                        type="text"
                        placeholder="Phone Label"
                        autoFocus
                        spellCheck
                    />
                </div>
                <div className="footer-modal btn-close justify-end">
                    <span className="v2-btn-default --transparent" onClick={_close} tabIndex="0">
                        {t('cancel')}
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        className="v2-btn-main"
                        title={t('add')}
                        onSave={_handleAddNewPhone}
                    />
                </div>
            </div>
        </ReactModal>
    );
});

export default CreatePhoneLabel;
