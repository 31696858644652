import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { CUSTOMERS_DETAIL } from 'app/config/routes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { CUSTOMERS } from 'app/const/Route';
import IconClose from 'assets/icon/IconClose';
import { createCustomer } from 'common/redux/actions/customer';

const AlertExistCustomer = ({ onClose = () => {} }, ref) => {
    const { t } = useTranslation(['customers']);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const dataCreateCustomer = useSelector(({ createCustomer }) => createCustomer.createCustomerData);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, dataCustomer: {} });
    const { isOpen, dataCustomer } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, dataCustomer: data });
    };

    const _close = () => {
        onClose();
        dispatchState({ isOpen: false });
    };

    const _handleNavigate = () => {
        if (!matchPath(pathname, { path: addPrefixPath(CUSTOMERS_DETAIL), exact: true }))
            dispatch(createCustomer({ ...(dataCreateCustomer || {}), onSubmit: () => {} }));
        _close();
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            id="modal-exist-customer"
            portalClassName="ReactModalPortal_alert_customer"
            style={{ overlay: { background: 'transparent' } }}
            onRequestClose={_close}
            className="modal container-modal batch-job open"
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container --lg">
                <div className="header-modal">
                    <h3 className="header-modal__label">
                        {t('customers:a_customer_exists_with_this_service_address')}
                    </h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <div className="rows text-center">
                        <h3 className="customer-info">{dataCustomer.full_name}</h3>
                        <h3 className="customer-info">{dataCustomer.street1}</h3>
                        <h3 className="customer-info">{dataCustomer.street2}</h3>
                        <h3 className="customer-info">
                            {dataCustomer.city}
                            {dataCustomer.state && `, ${dataCustomer.state}`}
                            &nbsp; {dataCustomer.zip}
                        </h3>
                    </div>
                    <div className="rows">
                        <Link
                            to={addBranchPath(`${CUSTOMERS}/${dataCustomer.id}`)}
                            onClick={_handleNavigate}
                            className="v2-btn-default"
                        >
                            {t('customers:take_me_to_this_account')}
                        </Link>
                    </div>
                </div>
                <div className="footer-modal btn-close" onClick={_close}>
                    <span className="v2-btn-default --transparent">{t('customers:cancel')}</span>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(AlertExistCustomer);
