import { forwardRef, useReducer, useImperativeHandle, useRef } from 'react';
import IconClose from 'assets/icon/IconClose';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const ModalScheduleException = forwardRef((props, ref) => {
    const { t } = useTranslation(['calendar, common']);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const msgFailed = useRef('');

    function _showOptions(message) {
        msgFailed.current = message;
        dispatchState({ isVisible: true });
    }

    useImperativeHandle(ref, () => ({
        _showOptions
    }));

    function _closeOptions(e) {
        e.stopPropagation();
        dispatchState({ isVisible: false });
    }

    function _renderReSchedule() {
        if (!state.isVisible) {
            return false;
        }

        return (
            <div id="reschedule" className="modal container-modal batch-job --reschedule open">
                <div className="modal__overlay bg-fixed" />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('calendar:scheduling_exception')}</h3>
                        <div onClick={(e) => _closeOptions(e)} className="v2-btn-default --icon-lg --transparent">
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal scrolls">
                        <div className="rows">
                            <p className="desc">{msgFailed.current}</p>
                        </div>
                    </div>
                    <div className="footer-modal btn-close">
                        <span onClick={(e) => _closeOptions(e)} className="v2-btn-default --transparent">
                            {t('common:cancel')}
                        </span>
                    </div>
                </div>
            </div>
        );
    }

    return _renderReSchedule();
});

export default ModalScheduleException;
