import { GET_LIST_ACCOUNT_CARD } from 'app/const/api/V2';
import ConfirmCharge from 'app/modules/settings/plans/components/ConfirmCharge';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';

const PlanChooseCard = ({ onChooseCardSuccess = () => {} }, ref) => {
    const refConfirmCharge = useRef(null);
    const listCard = useRef({
        isFetch: false,
        cards: []
    });

    useImperativeHandle(ref, () => ({
        _open,
        _close,
        _removeLoading,
        _setDisable
    }));

    const _handleSubmit = () => {
        const listCardTemp = [...listCard.current.cards];
        const cardDefault = listCardTemp.find((item) => item.is_default) || listCardTemp?.[0] || null;
        refConfirmCharge.current._open(cardDefault);
    };

    const _open = () => {
        if (!listCard.current.isFetch) {
            refConfirmCharge.current._showLoading();
            fetchListCard();
        } else {
            _handleSubmit();
        }
    };

    const _close = () => {
        refConfirmCharge.current._close();
    };

    const _removeLoading = (message) => {
        refConfirmCharge.current._removeLoading(message);
    };

    const _setDisable = (value = false) => {
        refConfirmCharge.current?._setDisable(value);
    };

    const fetchListCard = () => {
        const _getListCardSuccess = (res) => {
            listCard.current = {
                isFetch: true,
                cards: res.data
            };
            _handleSubmit();
        };

        const _handleFail = ({ message }) => {
            refConfirmCharge.current._removeLoading(message);
            _handleSubmit();
        };

        clientQuery(GET_LIST_ACCOUNT_CARD, { method: 'GET' }, _getListCardSuccess, _handleFail);
    };

    const _handleSubmitCard = (e) => {
        onChooseCardSuccess({ cardDetail: e, isNewCard: !!e });
    };

    return (
        <ConfirmCharge
            ref={refConfirmCharge}
            onSubmit={_handleSubmitCard}
            onSubmitNewCard={_handleSubmitCard}
            isDisableOnCharge
            isNotCloseClickOutside
        />
    );
};

export default forwardRef(PlanChooseCard);
