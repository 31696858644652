import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';

const AdditionalOptions = ({ defaultValue = {}, onExpand = () => {} }, ref) => {
    const { t } = useTranslation('customers');
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, cardDataMeta: defaultValue });
    const { isOpen, cardDataMeta } = state;
    const refForm = useRef(null);

    useImperativeHandle(ref, () => ({ getValue }));

    function _handleExpand() {
        dispatchState({ isOpen: !isOpen });
        onExpand(!isOpen);
    }

    function getValue() {
        if (!refForm.current) return {};
        const formData = new FormData(refForm.current);
        const data = {};
        for (const [key, value] of formData.entries()) {
            data[key] = value;
        }
        return data;
    }

    return (
        <Fragment>
            {isOpen ? (
                <form ref={refForm} className="detail-additional">
                    <div className="rows --inmodal">
                        <div className="txt">Cardholder name</div>
                        <input
                            className="field-input"
                            type="text"
                            name="account_name"
                            defaultValue={cardDataMeta.name || ''}
                            placeholder={t('cardholder_name')}
                            spellCheck
                        />
                    </div>
                    <div className="rows --inmodal">
                        <div className="txt">Street</div>
                        <input
                            className="field-input"
                            type="text"
                            name="address_line1"
                            defaultValue={cardDataMeta.address_line1 || ''}
                            placeholder={t('street')}
                            spellCheck
                        />
                    </div>
                    <div className="rows row-haft --inmodal">
                        <div className="col">
                            <div className="txt">City</div>
                            <input
                                className="field-input"
                                type="text"
                                name="address_city"
                                defaultValue={cardDataMeta.address_city || ''}
                                placeholder={t('city')}
                            />
                        </div>
                        <div className="col">
                            <div className="txt">Zip</div>
                            <input
                                className="field-input"
                                type="text"
                                name="address_zip"
                                placeholder={t('zip')}
                                defaultValue={cardDataMeta.address_zip || ''}
                                spellCheck
                            />
                        </div>
                    </div>
                    <div className="rows row-haft --inmodal">
                        <div className="col">
                            <div className="txt">State</div>
                            <input
                                name="address_state"
                                className="field-input"
                                type="text"
                                defaultValue={cardDataMeta.address_state || ''}
                                placeholder={t('state')}
                                spellCheck
                            />
                        </div>
                        <div className="col">
                            <div className="txt">Country</div>
                            <input
                                className="field-input"
                                type="text"
                                name="address_country"
                                defaultValue={cardDataMeta.address_country || ''}
                                placeholder={t('country')}
                                spellCheck
                            />
                        </div>
                    </div>
                </form>
            ) : null}

            <div className="more-option">
                <div className="v2-btn-default --icon-r --transparent" onClick={_handleExpand} tabIndex="0">
                    {t('additional_options')}
                    <IconArrowDown />
                </div>
            </div>
        </Fragment>
    );
};

export default forwardRef(AdditionalOptions);
