import { call, put } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { getListItems } from 'app/const/Api';
import { TYPE_ITEMS } from 'common/redux/actions/itemsAction';

export function* actionGetListItems({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            fetchWithToken,
            getListItems,
            params
        );
        if (response && response.success) {
            yield put({
                type: TYPE_ITEMS.ACTION_GET_LIST_ITEMS_SUCCESS,
                payload: response.data
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
