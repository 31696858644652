import React from 'react';

export default function Loading() {
    return (
        <div className="addons-wrap container-wrap">
            <div className="addons-wrap__container">
                <div className="wrap-loading header --addon">
                    <div className="header__back v2-btn-default loading --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="scrolls">
                    <div className="boxs-wrapper --quickbooks">
                        <div className="wrap-loading --addon">
                            <div className="boxs boxs--no-border --hasline">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                                        <div className="title">
                                            <div className="loading --animation --line --half" />
                                        </div>
                                    </div>
                                    <div className="flexcenter loading --fourth">
                                        <div className="v2-btn-default --grey loading btn-sm mr-2">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="switch ml-0 flex-1">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="boxs__contents mt-4">
                                    <div className="description loading-lines">
                                        <div className="loading --animation --line --ninety" />
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="description loading-lines mt-5">
                                        <div className="loading --animation --line --full" />
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="mt-3 v2-btn-default --grey loading btn-md">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon">
                            <div className="boxs boxs--power-up loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="dots powerups loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="switch loading --onefifth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                                <div className="boxs__contents mt-5">
                                    <div className="description loading-lines --hasline loading">
                                        <div className="loading --animation --line --full" />
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="check-items">
                                        <div className="checkbox" />
                                        <div className="item-checkbox flex-1">
                                            <div className="loading --animation --line --threefourth" />
                                        </div>
                                    </div>
                                    <div className="flex-1 ml-5 pt-2">
                                        <div className="v2-btn-default --grey loading --full">
                                            <div className="loading --animation --line --twothird" />
                                        </div>
                                    </div>
                                    <div className="check-items mt-3">
                                        <div className="checkbox" />
                                        <div className="item-checkbox flex-1 loading-lines">
                                            <div className="loading --animation --line --ninety" />
                                        </div>
                                    </div>
                                    <div className="wrap-tooltip flex-1 loading-lines mt-1">
                                        <div className="loading --animation --line --ninety" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon mt-2">
                            <div className="boxs boxs-has-footer loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="dots powerups loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="switch loading --onefifth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                                <div className="boxs__contents">
                                    <div className="description loading-lines --hasline">
                                        <div className="loading --animation --line --full" />
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="required loading --full mb-2">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                    <div className="required loading --full mb-2 mt-3">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                </div>
                                <div className="boxs__footer --multi loading">
                                    <div className="loading" />
                                    <div className="v2-btn-default --grey loading btn-x-sm">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon mt-2">
                            <div className="boxs boxs-has-footer loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="dots powerups loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="switch loading --onefifth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                                <div className="boxs__contents">
                                    <div className="description loading-lines --hasline">
                                        <div className="loading --animation --line --full" />
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                    <div className="required loading --full mb-2">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                </div>
                                <div className="boxs__footer --multi loading">
                                    <div className="loading" />
                                    <div className="v2-btn-default --grey loading btn-x-sm">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon mt-2">
                            <div className="boxs boxs--power-up loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="dots powerups loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="switch loading --onefifth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                                <div className="boxs__contents mt-4">
                                    <div className="description loading-lines">
                                        <div className="loading --animation --line --ninety" />
                                        <div className="loading --animation --line --onefifth" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon mt-2">
                            <div className="boxs boxs--power-up loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="dots powerups loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                    <div className="switch loading --onefifth">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                                <div className="boxs__contents mt-4">
                                    <div className="description loading-lines">
                                        <div className="loading --animation --line --ninety" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-loading --addon mt-2">
                            <div className="boxs boxs-has-footer loading">
                                <div className="boxs__header">
                                    <div className="header-title">
                                        <div className="title loading --twothird">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="boxs__contents">
                                    <div className="required loading --full mb-2">
                                        <div className="loading --animation --line --fourth" />
                                    </div>
                                    <div className="v2-btn-default --grey loading --full">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                </div>
                                <div className="boxs__footer --multi loading">
                                    <div className="loading" />
                                    <div className="v2-btn-default --grey loading btn-x-sm">
                                        <div className="loading --animation --line --full" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
