import { getLocationSMSLogs } from 'app/const/Api';
import IconClose from 'assets/icon/IconClose';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { groupByKey } from 'common/utils/FunctionUtils';
import React, { useReducer } from 'react';
import GridEmpty from '../grid/components/GridEmpty';
import ItemLog from './components/ItemLog';
import LoadingChangeLogs from './components/LoadingChangeLogs';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const GdSMSChangeLogs = ({ locationId = null }) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isFetched: false, isLoading: true, data: [] });
    const { isOpen, isFetched, data, isLoading } = state;

    const _handleOpenChangeLogs = () => {
        dispatchState({ isOpen: true });
        const _handleGetSuccess = ({ data }) =>
            dispatchState({ data: _handleConvertToData(data), isFetched: true, isLoading: false });
        if (!isFetched && locationId) clientQuery(getLocationSMSLogs(locationId), { method: 'GET' }, _handleGetSuccess);
    };

    const _handleCloseChangeLogs = () => {
        dispatchState({ isOpen: false });
    };

    const _renderListLogs = () => {
        if (!data.length) return <GridEmpty className="tables-empty m-0" />;
        return data.map((item, index) => {
            return <GroupLogs key={item.id || index.toString()} {...item} />;
        });
    };

    const _handleConvertToData = (data) => {
        const result = [];
        const objectGrouped = groupByKey(data, 'date');
        for (const key in objectGrouped) {
            if (Object.hasOwnProperty.call(objectGrouped, key)) {
                const element = objectGrouped[key];
                result.push({ id: key, logItems: element });
            }
        }
        return result;
    };

    return (
        <>
            <div className={classNames('field changelog', { active: isOpen })}>
                <div className="changelog-label" onClick={_handleOpenChangeLogs}>
                    {t('change_log')}
                </div>
                <div className="changelog-box">
                    <div className="changelog-box__header">
                        <h3 className="title mb-0">{t('change_log')}</h3>
                        <span className="v2-btn-default --icon-lg --transparent" onClick={_handleCloseChangeLogs}>
                            <IconClose />
                        </span>
                    </div>
                    <div className="changelog-box__content scrolls">
                        {isLoading ? <LoadingChangeLogs /> : _renderListLogs()}
                    </div>
                </div>
            </div>
        </>
    );
};

const GroupLogs = ({ id, logItems = [] }) => {
    if (!logItems.length) return null;
    return (
        <div key={id} className="rows">
            <div className="log-date">{id}</div>
            {logItems.map((item, index) => {
                return <ItemLog key={item.id || index.toString()} {...item} />;
            })}
        </div>
    );
};

export default GdSMSChangeLogs;
