/* eslint-disable no-unused-vars */
import { call } from 'redux-saga/effects';
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken, formApi } from 'common/utils/ApiUtils';
import {
    DRIP_GET_LIST_CAMPAIGNS,
    DRIP_UPDATE_STATUS_CAMPAIGN,
    DRIP_DELETE_CAMPAIGN,
    DRIP_GET_DETAIL_CAMPAIGN,
    DRIP_CREATE_CAMPAIGN,
    DRIP_UPDATE_DETAIL_CAMPAIGN,
    DRIP_DUPLICATE_CAMPAIGN,
    DRIP_GET_LIST_CATEGORIES
} from 'app/const/Api';

export function* getListCampaigns({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_CAMPAIGNS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListCategories({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_LIST_CATEGORIES);
        if (response && response.success) {
            const newData = response.data;
            newData.forEach((item) => {
                item.value = item.id;
            });
            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIP_UPDATE_STATUS_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, DRIP_DELETE_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getDetailCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIP_GET_DETAIL_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, DRIP_CREATE_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateDetailCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIP_UPDATE_DETAIL_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* duplicateCampaign({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(formApi, DRIP_DUPLICATE_CAMPAIGN, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
