import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { useSelector } from 'react-redux';
import { updateUserSettings } from 'common/redux/actions/authAction';
import { useDispatch } from 'react-redux';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTION_USER_SETTING_CALL } from 'app/const/api/Voip';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_PHONE_SETTINGS } from 'app/const/Voip';
import { Fragment, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';

export const RecordingCall = () => {
    const voip = useSelector(({ auth }) => auth?.user?.settings?.voip || {});
    const dispatch = useDispatch();
    const refToggle = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isShowPolicy: false
    });

    const { isShowPolicy } = state;

    const _toggleRecording = (value) => {
        const finalValue = value ? 1 : 0;
        const showConfirmRecording = getLocalStorage(KEY_PHONE_SETTINGS)?.showConfirmRecording;
        if (value) {
            if (showConfirmRecording !== '1') {
                dispatchState({
                    isShowPolicy: true
                });
                return false;
            }
        } else if (isShowPolicy) {
            dispatchState({
                isShowPolicy: false
            });
            return false;
        }
        setLocalStorage(KEY_PHONE_SETTINGS, { ...getLocalStorage(KEY_PHONE_SETTINGS), showConfirmRecording: '1' });
        _handleUpdateRecord(finalValue);
    };

    const _handleUpdateRecord = (finalValue) => {
        dispatch(updateUserSettings({ voip: { ...voip, recording: finalValue } }));

        clientQuery(ACTION_USER_SETTING_CALL, { data: { recording: finalValue }, method: 'PUT' });
    };

    const _handleAccept = (value = true) => {
        if (value) {
            _handleUpdateRecord(1);
        } else {
            refToggle.current.setIsActive(false);
        }

        setTimeout(() => {
            dispatchState({
                isShowPolicy: false
            });
        }, 0);
    };

    return (
        <Fragment>
            <div className="line-bottom-grey js-recording">
                <div className="flex-betweenitems gap-module">
                    <span className="granite-gray">Call Recording</span>
                    <SwitchCheckbox
                        ref={refToggle}
                        disabled
                        defaultChecked={voip.recording}
                        textChecked="On"
                        textUnChecked="Off"
                        onChange={_toggleRecording}
                        isEffectDefaultChecked
                    />
                </div>
                {isShowPolicy && (
                    <div className="gap-module pt-0">
                        <div className="recording-policy">
                            <p>
                                If you enable the call recording feature of this application, please note that your
                                state or country may have specific rules regarding the privacy of communication for all
                                parties to a conversation. Including, a certain situation, advising all participants in
                                a call that the call is being recorded. You are fully responsible for complying with all
                                applicable federal, state, local or international laws related to your use of this
                                feature.
                            </p>
                            <div className="recording-policy-button">
                                <div onClick={() => _handleAccept(false)} className="v2-btn-default --transparent">
                                    Cancel
                                </div>
                                <div onClick={_handleAccept} className="v2-btn-main ml-2">
                                    I Accept
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
};
