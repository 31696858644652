import { ENTER_KEY_CODE } from 'app/const/Keyboard';
import IconSearch from 'assets/icon/IconSearch';
import IconCircleClose from 'assets/icon/IconCircleClose';
import React, { useEffect, useRef, useState } from 'react';
import { detectKeyPress } from 'common/utils/FunctionUtils';
let timer;
const GdSearch = ({
    placeholder = '',
    onSearch = () => {},
    style = '',
    defaultValue = '',
    onKeyEnter,
    spellCheck = true,
    isSearchOnClear = false,
    inputClassName = '',
    iconClassName = 'svg-search-absolute',
    isShowClearIcon = true,
    onChangeImmediately = () => {},
    onPressEnter = () => {}
}) => {
    const searchInputRef = useRef(null);
    const onKeywordRef = useRef(defaultValue);
    const [state, setState] = useState({
        term: ''
    });

    const { term: finalTerm } = state;

    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        defaultValue !== finalTerm && setState({ ...state, term: defaultValue });
    }, [defaultValue]);

    const _handleSearchTerm = (e) => {
        const value = e.target.value;
        onChangeImmediately(value);
        setState((prev) => {
            return {
                ...prev,
                term: value
            };
        });

        if (onKeyEnter) {
            onSearch(value);
        } else {
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                onSearch(value);
                clearTimeout(timer);
            }, 500);
        }
    };

    const _handleOnClickWrapperSearchInput = (e) => {
        e.preventDefault();
        if (!searchInputRef.current) return;
        searchInputRef.current.focus();
    };

    const _handleRemoveInput = async (e) => {
        e && e.stopPropagation();
        setState({ term: '' });
        if (onKeywordRef.current !== '' && onKeyEnter) {
            onKeywordRef.current = '';
            onKeyEnter('');
        } else if (isSearchOnClear) {
            onSearch('');
        }
    };
    const _handleOnKeyPress = (e) => {
        if (!detectKeyPress(e, ENTER_KEY_CODE)) return;
        if (onKeyEnter && onKeywordRef.current !== finalTerm) {
            onKeywordRef.current = finalTerm;
            onKeyEnter(finalTerm);
        }
        onPressEnter();
    };

    return (
        <div className={`search-input has-closeicon ${style || ''}`} onClick={_handleOnClickWrapperSearchInput}>
            <span className={iconClassName}>
                <IconSearch />
            </span>
            <input
                ref={searchInputRef}
                type="text"
                value={finalTerm}
                placeholder={placeholder}
                onChange={_handleSearchTerm}
                onKeyUp={_handleOnKeyPress}
                spellCheck={spellCheck}
                className={inputClassName}
            />
            {finalTerm && isShowClearIcon ? (
                <div className="close-icon" onClick={_handleRemoveInput}>
                    <IconCircleClose />
                </div>
            ) : null}
        </div>
    );
};

export default GdSearch;
