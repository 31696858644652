import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import MessageConfig from 'app/modules/calendar/job/components/messaging/MessageConfig';
import { updateLocationCustomerMessagingPreferences, actionLocationMessagingPreferences } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { useTranslation } from 'react-i18next';
import IconSpeaker from 'assets/icon/IconSpeaker';
import { reducer } from 'app/const/Reducer';
import IconLoading from 'assets/icon/IconLoading';

const Messaging = ({ locationId, customerId, jobId }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        message: null,
        isFetched: false,
        isLoading: false
    });

    const { t } = useTranslation('customers');
    const { isVisible: finalIsVisible, message, isFetched, isLoading } = state;

    useImperativeHandle(ref, () => ({
        updateMessage: (message) => {
            isFetched && dispatchState({ message });
        }
    }));

    useEffect(() => {
        if (jobId && isFetched) {
            dispatchState({ isFetched: false });
        }
    }, [jobId]);

    function _openModalSetting(e) {
        e.stopPropagation();
        if (isFetched) {
            dispatchState({ isVisible: true });
        } else {
            dispatchState({ isLoading: true });

            clientQuery(
                actionLocationMessagingPreferences(customerId, locationId),
                { method: 'GET', data: { fields: 'name' } },
                _getMessageSucess,
                _getMessageFailed
            );
        }
    }

    function _getMessageSucess(response) {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                isVisible: true,
                isFetched: true,
                message: response.data
            };
        });
    }

    function _getMessageFailed() {
        dispatchState({ isLoading: false });
    }

    function _closeModalSetting() {
        dispatchState({ isVisible: false });
    }

    function _handleSave(confirmations, reminders, followups, broadcasts, payment, sms, email, callback) {
        const params = {
            appointment_confirmations: confirmations,
            appointment_reminders: reminders,
            appointment_followups: followups,
            broadcasts: broadcasts,
            late_payment_reminders: payment,
            location_ids: [locationId],
            sms,
            email
        };

        const optionsQuery = {
            method: 'PUT',
            data: params,
            toFormData: false
        };

        clientQuery(updateLocationCustomerMessagingPreferences(customerId), optionsQuery, () =>
            _updateSuccess(params, callback)
        );
    }

    function _updateSuccess(params, callback) {
        callback();

        setTimeout(() => {
            dispatchState({
                message: { ...params, sms_to: params.sms, email_to: params.email },
                isVisible: false
            });
        }, 1500);
    }

    return (
        <>
            <div className="v2-btn-default --icon-sm tooltip" onClick={(e) => _openModalSetting(e)}>
                {isLoading ? <IconLoading /> : <IconSpeaker />}
                <p className="tooltiptext top ml-n7">{t('customers:edit_messaging')}</p>
            </div>

            {finalIsVisible && (
                <MessageConfig
                    customerId={customerId}
                    message={message}
                    onSave={_handleSave}
                    onClose={_closeModalSetting}
                />
            )}
        </>
    );
};

export default forwardRef(Messaging);
