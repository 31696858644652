export default function IconGoogleMaps() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.10777 5.17845L8.71554 4.6569C9.23338 4.55333 9.76662 4.55333 10.2845 4.6569L13.7155 5.34312C14.2334 5.44669 14.7666 5.44669 15.2845 5.34312L17.1078 4.97845C18.1909 4.76183 19.2445 5.46426 19.4612 6.54738C19.487 6.67653 19.5 6.80791 19.5 6.93962V16.8605C19.5 17.8138 18.8271 18.6346 17.8924 18.8216L15.2862 19.3431C14.7683 19.4467 14.2351 19.4467 13.7172 19.3432L10.2843 18.6568C9.76657 18.5533 9.23343 18.5533 8.71567 18.6569L6.89223 19.0216C5.80911 19.2382 4.75546 18.5358 4.53884 17.4526C4.51301 17.3235 4.5 17.1921 4.5 17.0604V7.13962C4.5 6.18625 5.17292 5.36542 6.10777 5.17845Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path d="M9.5 5.5V18.5" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="square"></path>
            <path d="M14.5 6.46429V18.5357" stroke="#4D5054" strokeWidth="1.3" strokeLinecap="square"></path>
        </svg>
    );
}
