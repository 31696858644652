import { useRef, useReducer, createContext, useLayoutEffect } from 'react';
import { io } from 'socket.io-client';
import { SOCKET_EVENTS } from 'app/const/App';
import { WS_ENDPOINT } from 'app/const/URL';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';
import {
    WS_NAMESPACES,
    WS_EVENTS_ADDON,
    WS_EVENTS_JOB,
    WS_EVENTS_CUSTOMER,
    WS_EVENTS_WORK_LOG,
    WS_EVENTS_SETTING
} from 'app/const/Socket';
import { flushSync } from 'react-dom';

export const SocketContext = createContext({ socket: null });

const SocketProvider = ({ children }) => {
    const { CONNECT, CONNECTED, RECONNECTING, CONNECT_ERROR, CONNECT_FAILED } = SOCKET_EVENTS;
    const authReducer = useSelector(({ auth }) => auth);
    const roomId = authReducer?.user?.profile?.ws_room;
    const userId = authReducer?.user?.profile?.id;

    const [state, dispatchState] = useReducer(reducer, {});

    const socketAddon = useRef();
    const socketJob = useRef();
    const socketCustomer = useRef();
    const socketWorklog = useRef();
    const socketSetting = useRef();
    const timer = useRef({});
    const timeOut = 200;

    useLayoutEffect(() => {
        if (parseInt(roomId)) {
            document.addEventListener('visibilitychange', _handleCheckConnection);

            const options = {
                reconnection: true,
                transports: ['websocket', 'polling'], // avoid blocking
                forceNew: true,
                pingInterval: 25000
            };

            socketWorklog.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.WORK_LOG}`, options);
            socketWorklog.current.connect();

            socketAddon.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.ADDON}`, options);
            socketAddon.current.connect();

            socketJob.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.JOB}`, options);
            socketJob.current.connect();

            socketCustomer.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.CUSTOMER}`, options);
            socketCustomer.current.connect();

            socketSetting.current = io(`${WS_ENDPOINT}/${WS_NAMESPACES.SETTING}`, options);
            socketSetting.current.connect();

            socketWorklog.current.on(CONNECT, () => {
                socketWorklog.current.emit(CONNECTED, parseInt(userId));
                WS_EVENTS_WORK_LOG.forEach((item) => {
                    const itemKey = item.key;
                    socketWorklog.current.on(item.event, (response) => {
                        clearTimeout(timer.current[itemKey]);

                        flushSync(() => {
                            dispatchState((prev) => {
                                return { ...prev, [itemKey]: response };
                            });
                        });

                        timer.current[itemKey] = setTimeout(() => {
                            flushSync(() => {
                                dispatchState((prev) => {
                                    return { ...prev, [itemKey]: null };
                                });
                            });
                        }, timeOut);
                    });
                });
            });

            socketAddon.current.on(CONNECT, () => {
                socketAddon.current.emit(CONNECTED, parseInt(roomId));

                WS_EVENTS_ADDON.forEach((item) => {
                    const itemKey = item.key;
                    socketAddon.current.on(item.event, (response) => {
                        clearTimeout(timer.current[itemKey]);

                        flushSync(() => {
                            dispatchState((prev) => {
                                return { ...prev, [itemKey]: response };
                            });
                        });

                        timer.current[itemKey] = setTimeout(() => {
                            flushSync(() => {
                                dispatchState((prev) => {
                                    return { ...prev, [itemKey]: null };
                                });
                            });
                        }, timeOut);
                    });
                });
            });

            socketCustomer.current.on(CONNECT, () => {
                socketCustomer.current.emit(CONNECTED, parseInt(roomId));

                WS_EVENTS_CUSTOMER.forEach((item) => {
                    const itemKey = item.key;
                    socketCustomer.current.on(item.event, (response) => {
                        clearTimeout(timer.current[itemKey]);

                        flushSync(() => {
                            dispatchState((prev) => {
                                return { ...prev, [itemKey]: response };
                            });
                        });

                        timer.current[itemKey] = setTimeout(() => {
                            flushSync(() => {
                                dispatchState((prev) => {
                                    return { ...prev, [itemKey]: null };
                                });
                            });
                        }, timeOut);
                    });
                });
            });

            socketJob.current.on(CONNECT, () => {
                socketJob.current.emit(CONNECTED, parseInt(roomId));

                WS_EVENTS_JOB.forEach((item) => {
                    const itemKey = item.key;
                    socketJob.current.on(item.event, (response) => {
                        clearTimeout(timer.current[itemKey]);

                        flushSync(() => {
                            dispatchState((prev) => {
                                return { ...prev, [itemKey]: response };
                            });
                        });

                        timer.current[itemKey] = setTimeout(() => {
                            flushSync(() => {
                                dispatchState((prev) => {
                                    return { ...prev, [itemKey]: null };
                                });
                            });
                        }, timeOut);
                    });
                });
            });

            socketSetting.current.on(CONNECT, () => {
                socketSetting.current.emit(CONNECTED, parseInt(roomId));

                WS_EVENTS_SETTING.forEach((item) => {
                    const itemKey = item.key;
                    socketSetting.current.on(item.event, (response) => {
                        clearTimeout(timer.current[itemKey]);

                        flushSync(() => {
                            dispatchState((prev) => {
                                return { ...prev, [itemKey]: response };
                            });
                        });

                        timer.current[itemKey] = setTimeout(() => {
                            flushSync(() => {
                                dispatchState((prev) => {
                                    return { ...prev, [itemKey]: null };
                                });
                            });
                        }, timeOut);
                    });
                });
            });

            socketAddon.current.on(CONNECT_ERROR, () => {
                socketAddon.current.disconnect();
            });
            socketAddon.current.on(CONNECT_FAILED, () => {
                socketAddon.current.disconnect();
            });

            socketJob.current.on(CONNECT_ERROR, () => {
                socketJob.current.disconnect();
            });
            socketJob.current.on(CONNECT_FAILED, () => {
                socketJob.current.disconnect();
            });

            socketCustomer.current.on(CONNECT_ERROR, () => {
                socketCustomer.current.disconnect();
            });
            socketCustomer.current.on(CONNECT_FAILED, () => {
                socketCustomer.current.disconnect();
            });

            socketSetting.current.on(CONNECT_ERROR, () => {
                socketSetting.current.disconnect();
            });
            socketSetting.current.on(CONNECT_FAILED, () => {
                socketSetting.current.disconnect();
            });
        }

        window.addEventListener('beforeunload', disconnectSocket);

        return () => {
            window.removeEventListener('beforeunload', disconnectSocket);
            window.removeEventListener('visibilitychange', _handleCheckConnection);
            disconnectSocket();
        };
    }, []);

    const _handleCheckConnection = () => {
        if (document.visibilityState === 'visible') {
            if (socketWorklog.current) {
                !socketWorklog.current.connected && socketWorklog.current?.connect();
            }
            if (socketAddon.current) {
                !socketAddon.current.connected && socketAddon.current?.connect();
            }
            if (socketJob.current) {
                !socketJob.current.connected && socketJob.current?.connect();
            }
            if (socketCustomer.current) {
                !socketCustomer.current.connected && socketCustomer.current?.connect();
            }
            if (socketSetting.current) {
                !socketSetting.current.connected && socketSetting.current?.connect();
            }
        }
    };

    const disconnectSocket = () => {
        if (socketWorklog.current) {
            socketWorklog.current.close();
            socketWorklog.current.removeAllListeners();
            socketWorklog.current.off(RECONNECTING);
            socketWorklog.current.disconnect();
        }

        if (socketAddon.current) {
            socketAddon.current.close();
            socketAddon.current.removeAllListeners();
            socketAddon.current.off(RECONNECTING);
            socketAddon.current.disconnect();
        }

        if (socketJob.current) {
            socketJob.current.close();
            socketJob.current.removeAllListeners();
            socketJob.current.off(RECONNECTING);
            socketJob.current.disconnect();
        }

        if (socketCustomer.current) {
            socketCustomer.current.close();
            socketCustomer.current.removeAllListeners();
            socketCustomer.current.off(RECONNECTING);
            socketCustomer.current.disconnect();
        }

        if (socketSetting.current) {
            socketSetting.current.close();
            socketSetting.current.removeAllListeners();
            socketSetting.current.off(RECONNECTING);
            socketSetting.current.disconnect();
        }
    };

    return <SocketContext.Provider value={{ ...state }}>{children}</SocketContext.Provider>;
};

export default SocketProvider;
