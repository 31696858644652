import React from 'react';

const LoadingContacts = () => {
    return (
        <div className="page-wrapper flex-auto form-contact form-inner">
            <div className="head-main flex-betweenitems">
                <div className="loading --animation --line --twothird" />
                <div className="v2-btn-default loading --icon-lg bg-white-cultured" />
            </div>
            <div className="rows mt-0 p-3">
                <div className="loading --animation --line --half" />
            </div>
            <div className="rows mt-0 p-3">
                <div className="loading --animation --line --half" />
            </div>
        </div>
    );
};

export default LoadingContacts;
