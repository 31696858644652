import React, { lazy, Suspense, useEffect, useReducer } from 'react';

import TabSwitcher from 'app/components/tabs/TabSwitcher';
import { reducer } from 'app/const/Reducer';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    START_TRACKING_EVENT,
    STOP_TRACKING_EVENT,
    TABS_TRACKERS,
    TRACKING_EVENTS_DETAIL_PAGE,
    TRACKING_SHORTCUTS,
    TRACKING_TYPES
} from '../../constant';
import { getTracking } from '../../utils';
import TimerContent from './TimerContent';

const ManualContent = lazy(() => import('./ManualContent'));
const TrackerBar = (props) => {
    const { id } = getTracking();
    const [state, dispatchState] = useReducer(reducer, { activeTab: TRACKING_TYPES.TIMER, isDisableTab: !!id });
    const { activeTab, isDisableTab } = state;

    const _handleStart = () => {
        dispatchState({ isDisableTab: true, activeTab: TRACKING_TYPES.TIMER });
    };

    const _handleStop = () => {
        dispatchState({ isDisableTab: false });
    };

    useEffect(() => {
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.START, _handleStart);
        addEventListener(START_TRACKING_EVENT, _handleStart);
        addEventListener(STOP_TRACKING_EVENT, _handleStop);
        return () => {
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.START, _handleStart);
            removeEventListener(START_TRACKING_EVENT, _handleStart);
            removeEventListener(STOP_TRACKING_EVENT, _handleStop);
        };
    }, []);

    const _handleChangeTab = (tab) => {
        if (activeTab === tab || isDisableTab) return;
        dispatchState({ activeTab: tab });
    };

    const _handleHotkey = () => {
        if (!navigator.onLine) return;
        _handleChangeTab(TRACKING_TYPES.MANUAL);
    };
    useHotkeys(TRACKING_SHORTCUTS.STOP, _handleHotkey, [activeTab, isDisableTab, navigator.onLine]);

    return (
        <div className="wrapper-clock__header">
            <div className="wrap-time-tracker">
                <TabSwitcher
                    listTabs={TABS_TRACKERS}
                    activeTab={activeTab}
                    isDisable={isDisableTab}
                    onChange={_handleChangeTab}
                />
                {activeTab === TRACKING_TYPES.TIMER ? <TimerContent {...props} /> : null}
                <Suspense fallback={<div>Loading...</div>}>
                    {activeTab === TRACKING_TYPES.MANUAL ? <ManualContent {...props} /> : null}
                </Suspense>
            </div>
        </div>
    );
};

export default TrackerBar;
