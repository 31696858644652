import { TYPE_DEVICES_ACTIONS } from 'common/redux/actions/job/devices';

const devicesReducer = (state = {
    area: {
        isFirstTime: true,
        location: [],
        area: []
    },
    deviceType: {
        isFirstTime: true,
        data: []
    },
    deviceStatus: {
        isFirstTime: true,
        data: []
    },
    activityLevel: {
        isFirstTime: true,
        data: []
    }
}, action) => {
    switch (action.type) {
        case TYPE_DEVICES_ACTIONS.GET_LIST_AREA_SUCCESS:
            return {
                ...state,
                area: {
                    isFirstTime: false,
                    location: action.payload?.location || [],
                    area: action.payload?.data || []
                }
            };
        case TYPE_DEVICES_ACTIONS.GET_LIST_DEVICES_TYPE_SUCCESS:
            return {
                ...state,
                deviceType: { isFirstTime: false, data: action.payload?.data || [] }
            };
        case TYPE_DEVICES_ACTIONS.GET_LIST_DEVICES_STATUS_SUCCESS:
            return {
                ...state,
                deviceStatus: { isFirstTime: false, data: action.payload?.data || [] }
            };
        case TYPE_DEVICES_ACTIONS.GET_LIST_ACTIVITY_LEVEL_SUCCESS:
            return {
                ...state,
                activityLevel: { isFirstTime: false, data: action.payload?.data || [] }
            };
        default:
            return state;
    }
};

export default devicesReducer;
