import React from 'react';

export default function IconUnit() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 11C18.5523 11 19 11.4477 19 12V17C19 18.1046 18.1046 19 17 19H7C5.89543 19 5 18.1046 5 17V12C5 11.4477 5.44772 11 6 11H18ZM9 13C7.89543 13 7 13.8954 7 15V19H11V15C11 13.8954 10.1046 13 9 13ZM15.5 13H14.5C13.6716 13 13 13.6716 13 14.5V15.5C13 16.3284 13.6716 17 14.5 17H15.5C16.3284 17 17 16.3284 17 15.5V14.5C17 13.6716 16.3284 13 15.5 13Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.87861 6.12133L6.41401 4.58583C6.78909 4.21073 7.29782 4 7.82827 4H16.1716C16.702 4 17.2107 4.21071 17.5858 4.58579L19.1213 6.12132C19.6839 6.68393 20 7.44699 20 8.24264V9C20 9.55228 19.5523 10 19 10H5C4.44772 10 4 9.55228 4 9V8.24258C4 7.44697 4.31604 6.68394 4.87861 6.12133Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
