import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { addBranchPath } from 'app/const/Branch';
import IconBachelorsHat from 'assets/icon/IconBachelorsHat';

const LinkKnowledgeBase = ({ pathname = '', onClick = () => {} }) => {
    const { t } = useTranslation(['header']);
    const history = useHistory();

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
        history.push({
            pathname: addBranchPath(pathname),
            state: { reloadScreen: Date.now() }
        });
    };

    return (
        <div className="tooltip" onClick={handleClick}>
            <IconBachelorsHat isNormal />
            <p className="tooltiptext top ml-n5">{t('knowledge_base')}</p>
        </div>
    );
};

export default LinkKnowledgeBase;
