export const ADD_PAYMENT_IN_STRIPE = 'ADD_PAYMENT_IN_STRIPE';
export const ADD_PAYMENT_IN_SQUARE = 'ADD_PAYMENT_IN_SQUARE';
export const ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT';
export const EMAIL_CREDIT_INVITATION = 'EMAIL_CREDIT_INVITATION';
export const EMAIL_ACH_INVITATION = 'EMAIL_ACH_INVITATION';
export const PAYMENT = 'payment';

export const CUSTOMER_ADD_PAYMENT_METHODS = {
    [ADD_PAYMENT_IN_STRIPE]: ADD_PAYMENT_IN_STRIPE,
    [ADD_PAYMENT_IN_SQUARE]: ADD_PAYMENT_IN_SQUARE,
    [ADD_BANK_ACCOUNT]: ADD_BANK_ACCOUNT,
    [EMAIL_CREDIT_INVITATION]: EMAIL_CREDIT_INVITATION,
    [EMAIL_ACH_INVITATION]: EMAIL_ACH_INVITATION
};

export const PAYMENT_MERCHANT = {
    STRIPE_GATE_WAY: 'StripeGateWay',
    SQUARE_GATE_WAY: 'SquareGateWay'
};

export const PAYMENT_MERCHANT_KEYWORD = {
    SQUARE: 'Square',
    STRIPE: 'Stripe',
    BANK_ACCOUNT: 'Bank Account',
    BANK: 'Bank'
};
