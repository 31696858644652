import React, { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';
import PickerMinute from 'app/modules/jobdetail/components/time/custom/Minute';
import PickerHour from 'app/modules/jobdetail/components/time/custom/Hour';

const TimePicker = forwardRef(({ timeSelected, onChange = () => {}, reset = '', isCloseOnSelected = false }, ref) => {
    const MODE_MIDDAY = {
        AM: 'AM',
        PM: 'PM'
    };

    const parentMidDay = timeSelected.format('A');

    const refHour = useRef(null);
    const refMinute = useRef(null);
    const [midDay, setMidDay] = useState(parentMidDay);

    useImperativeHandle(ref, () => ({
        _getTime: _getTimeSelected
    }));

    useEffect(() => {
        if (reset && parentMidDay !== midDay) {
            setMidDay(parentMidDay);
        }
    }, [reset]);

    useEffect(() => {
        if (midDay !== parentMidDay) {
            onChange();
        }
    }, [midDay]);

    function _getTimeSelected() {
        const finalHour = refHour.current._getValue();

        return {
            hour: finalHour === '12' && MODE_MIDDAY.AM === midDay ? 0 : finalHour,
            minute: refMinute.current._getValue(),
            midDay
        };
    }

    function _onChangeMidDay(e, value) {
        setMidDay(value);
    }

    return (
        <div className="field-input wrapper-choosetime">
            <PickerHour
                reset={reset}
                onChange={onChange}
                ref={refHour}
                value={timeSelected.format('hh')}
                isCloseOnSelected={isCloseOnSelected}
            />
            <PickerMinute
                reset={reset}
                onChange={onChange}
                ref={refMinute}
                value={timeSelected.format('mm')}
                isCloseOnSelected={isCloseOnSelected}
            />

            <div className="tabs">
                <div className="btn-item m-0">
                    <button
                        onClick={(e) => _onChangeMidDay(e, MODE_MIDDAY.AM)}
                        className={`tab-items ${midDay === MODE_MIDDAY.AM ? 'active-tab-selector ' : ''}`}
                        type="button"
                    >
                        AM
                    </button>
                    <button
                        onClick={(e) => _onChangeMidDay(e, MODE_MIDDAY.PM)}
                        className={`tab-items ${midDay === MODE_MIDDAY.PM ? 'active-tab-selector ' : ''}`}
                        type="button"
                    >
                        PM
                    </button>
                </div>
            </div>
        </div>
    );
});

export default TimePicker;
