import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
const VOIPAddonsFormNumber = (
    {
        id = '',
        classWrapper = 'rows row-haft',
        phoneCountries = [],
        textNumber = 'Prefix',
        maxLength = null,
        onPressEnter = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const refPrefix = useRef();

    const [state, dispatchState] = useReducer(reducer, {
        country: phoneCountries.find((item) => item.id === 217)
    });
    const { country } = state;

    useImperativeHandle(ref, () => ({
        _getValue,
        _reset
    }));

    const _getValue = () => {
        return {
            country,
            number: refPrefix.current.value ?? null
        };
    };

    const _reset = () => {
        refPrefix.current.value = null;
    };

    const _handleBlur = (e) => {
        const value = e.target.value;
        refPrefix.current.value = formatPhoneNumberVoip(value) || value;
    };

    const _onPress = (e) => {
        if (e.key === 'Enter') {
            onPressEnter();
        }
    };

    const _handleSelect = (value) => {
        dispatchState((prev) => ({ ...prev, country: phoneCountries.find((item) => item.id === value) }));
    };
    return (
        <div className={classWrapper}>
            <div className="col">
                <span className="txt">{t('addons:country')}</span>
                <CalendarDropdown
                    id={id}
                    options={phoneCountries}
                    selectedOption={country}
                    selected={country.label}
                    keyGetName="label"
                    keyGetValue="id"
                    keyGetKey="id"
                    onSelect={_handleSelect}
                />
            </div>
            <div className="col">
                <span className="txt">{t(`addons:${textNumber}`)}</span>
                <input
                    ref={refPrefix}
                    type="text"
                    className="field-input black"
                    placeholder={textNumber}
                    onBlur={_handleBlur}
                    onKeyUp={_onPress}
                    maxLength={maxLength}
                />
            </div>
        </div>
    );
};

export default forwardRef(VOIPAddonsFormNumber);
