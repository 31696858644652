import moment from 'moment-timezone';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import DatePickerInput from 'app/components/date/DateInput';
import DropdownUsers from 'app/components/dropdown/DropdownUsers';
import GDStatusBar from 'app/components/status/statusbar';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import { WORK_LOGS } from 'app/const/api/V2';
import IconClose from 'assets/icon/IconClose';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import { trackerAlert } from '../../utils';
import TimerTotal from '../TimerTotal';

const TrackerTimeModal = ({ onAddWorkLog = () => {} }) => {
    const { t } = useTranslation();
    const tz = useSelector(({ auth }) => auth.user.settings.timezone);
    const users = useSelector(({ companyUsers }) => companyUsers.users || []);

    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        date: moment.tz(tz).format('YYYY/MM/DD HH:mm:ss')
    });
    const { isOpen, date } = state;

    const refAlert = useRef(null);
    const refTimer = useRef(null);
    const refUser = useRef(null);
    const refStore = useRef(null);
    const refButtonSave = useRef(null);

    const _open = () => {
        dispatchState({ isOpen: true });
    };
    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleChangeTime = ({ inTime, outTime, durationTime, dayOver }) => {
        refStore.current = {
            inTime: inTime || 0,
            outTime: outTime || 0,
            durationTime: durationTime || 0,
            dayOver: dayOver || 0
        };
    };

    const _handleDateChange = (date) => {
        dispatchState((prevState) => ({ ...prevState, date: moment(date).format('YYYY/MM/DD HH:mm:ss') }));
    };

    const getObjectTime = (time, isStart = false) => ({
        hours: time.get('hours'),
        minutes: time.get('minutes'),
        second: isStart ? 0 : time.get('seconds')
    });

    const _handleFail = ({ message = '' }) => {
        refAlert.current?.clearAllStatusBar();
        refButtonSave.current?.removeLoading();
        refAlert.current?.showStatusBar('error', message, LIST_STATUS.ERROR);
    };

    const _handleSave = () => {
        const userId = refUser.current?.getValue()?.id || '';
        const payload = { user_id: userId };
        const { inTime, outTime, dayOver } = refTimer.current.getValue();
        const inTimeMoment = moment.unix(inTime).tz(tz);
        const outTimeMoment = moment.unix(outTime).tz(tz);
        const dateTz = moment(date).clone().tz(tz, true);

        payload['start'] = dateTz.clone().set(getObjectTime(inTimeMoment, true)).unix();
        payload['end'] = !!dayOver
            ? dateTz.clone().add('day', dayOver).set(getObjectTime(outTimeMoment)).unix()
            : dateTz.clone().set(getObjectTime(outTimeMoment)).unix();

        const _handleAddSuccess = ({ data = {}, message = '' }) => {
            onAddWorkLog({ ...data, user_id: userId, date: data.title, has_overlap: !!data?.overlap?.length });
            refButtonSave.current.removeLoading();
            trackerAlert(message, LIST_STATUS.SUCCESS);
            _close();
        };

        // eslint-disable-next-line no-undef
        payload.socket_id = global.tracker_socket_id;
        if (payload.start >= payload.end) return _handleFail({ message: t('end_time_must_greater_than_start_time') });
        clientQuery(WORK_LOGS, { data: payload, toFormData: false, method: 'POST' }, _handleAddSuccess, _handleFail);
    };

    return (
        <>
            <div className="v2-btn-main has-icon --bg-green svg-white btn-modal btn-add-time" onClick={_open}>
                <IconPlus /> {t('add_time')}
            </div>
            <ReactModal
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal modal-add-time open"
                portalClassName="ReactModalPortal_customer"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container --sm">
                    <div className="header-modal">
                        <h3 className="header-modal__label">{t('add_time')}</h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                            <IconClose />
                        </div>
                    </div>
                    <div className="body-modal flex-column gap-10">
                        <GDStatusBar ref={refAlert} />
                        <div className="row">
                            <span className="txt">{t('user')}</span>
                            <DropdownUsers ref={refUser} defaultUser={users[0]} isHasCrews />
                        </div>
                        <div className="row">
                            <span className="txt">{t('date')}</span>
                            <DatePickerInput
                                disableConvert
                                selectDefault={new Date(date)}
                                onChange={_handleDateChange}
                            />
                        </div>
                        <div className="row">
                            <div className="d-flex flex-wrap gap-10">
                                <TimerTotal
                                    ref={refTimer}
                                    implementWithModal
                                    onBlur={_handleChangeTime}
                                    onError={_handleFail}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="footer-modal footer-hasbtn btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            {t('cancel')}
                        </span>
                        <ButtonSave
                            ref={refButtonSave}
                            title={t('add')}
                            wrapClass="v2-btn v2-btn-main"
                            onSave={_handleSave}
                        />
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export default TrackerTimeModal;
