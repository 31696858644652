export const TYPE_REPORT_RECURRING_INVOICE = {
    GET_LIST: 'GET_LIST_REPORT_RECURRING_INVOICE',
    SEARCH: 'SEARCH_REPORT_RECURRING_INVOICE'
};

// actions
export const getListReportRecurringInvoice = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_RECURRING_INVOICE.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const searchReportRecurringInvoice = (
    params,
    actionSuccess,
    actionFailed = () => {}
) => {
    return {
        type: TYPE_REPORT_RECURRING_INVOICE.SEARCH,
        params,
        actionSuccess,
        actionFailed
    };
};
