import loadable from '@loadable/component';
import moment from 'moment-timezone';
import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import GdConfirm from 'app/components/confirm';
import { CALENDAR_STORE, GEO_USER_LOCATION, GEO_USER_UN_LOCATION } from 'app/const/Api';
import { GET_MAP_MARKER, MAPS_GEO_TRACKING } from 'app/const/api/V2';
import { IS_WEBGL_SUPPORT, KEY_MAP_CALENDAR_GEO_LOCATION } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { WS_NAMESPACES } from 'app/const/Socket';
import { actionOpenJobPreview } from 'common/redux/actions/job';
import { updateMapVisible, updateSizeMap } from 'common/redux/actions/map';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertTimeToISO } from 'common/utils/DateUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { useConnect } from 'common/utils/SocketUtils';
import { ACTIONS_JOB, CALENDAR_MODES, MAP_FILTER_DEFAULT } from '../const';
import RealtimeJobServices from '../RealtimeJobServices';
import Services from '../Services';
import { checkFilterMapCalendar, convertEvents, eventsToMarker, getFilterMapData } from '../ultil/Calendar';
import { actionJobStatus } from '../ultil/JobAction';
import { deletedJobMapData, statusJobMapData } from '../ultil/Map';
import MapServiceEvent from './MapServiceEvent';

const MapView = loadable(() => import('app/modules/calendar/map/MapView'));

const Map = (
    {
        onGetRangeDate = () => {},
        onResizeCalendar = () => {},
        onRefetchCalendar = () => {},
        onServiceClick = () => {},
        onSelectedMarker = () => {}
    },
    ref
) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const { profile, settings } = useSelector(({ auth }) => auth.user);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const { schedules, view: viewCalendar, mode: calendarMode } = useSelector(({ calendar }) => calendar);
    const { statusofmap, typeofmap, dayofmap, widthofmap, heightofmap, openmap } = useSelector(({ map }) => map);
    const isMonthView = viewCalendar === CALENDAR_MODES.DAY_GRID_MONTH;

    const [state, dispatchState] = useReducer(reducer, {
        events: [],
        geoLocation: [],
        geosTracking: [],
        initMap: !!openmap
    });
    const { events, geoLocation, geosTracking } = state;
    const { response } = useConnect(WS_NAMESPACES.MAP, profile?.ws_room, WS_NAMESPACES.MAP_GEO);
    const refMapView = useRef(null);
    const refJobs = useRef([]);
    const refConfirm = useRef(null);

    useImperativeHandle(ref, () => ({
        _toggleMap,
        _resizeMapView,
        _closeMap,
        _openMap,
        _handleUpdateEvents,
        _flyOnMap: (lng, lat) => {
            refMapView.current && refMapView.current._flyOnMap(lng, lat);
        },
        _displayPopup: _handleDisplayPopup
    }));

    const _handleGetGeoLocationFailed = ({ message }) => {
        refConfirm.current?.open(null, message);
    };

    const _geoLocation = useCallback(
        (isGeoLocation) => {
            if (!settings?.addons?.gps_tracking) return;
            const scheduleIds = schedules.map((item) => item.id).join(',');
            const URL_CALL = isGeoLocation ? GEO_USER_LOCATION : GEO_USER_UN_LOCATION;
            const { activeStart, activeEnd } = onGetRangeDate() || {};
            const dataSubmit = {
                start: convertTimeToISO(moment(activeStart).utc().startOf('day')),
                end: convertTimeToISO(moment(activeEnd).utc().subtract(1, 'day').endOf('day'))
            };

            if (!isGeoLocation) dataSubmit['schedules_id'] = scheduleIds;
            // Get geos tracking location clock in/out in jobs
            if (isGeoLocation) {
                clientQuery(
                    MAPS_GEO_TRACKING,
                    { data: { ...dataSubmit, schedule_ids: scheduleIds }, method: 'GET' },
                    ({ geos = [] }) => {
                        dispatchState((prev) => ({ ...prev, geosTracking: geos }));
                    },
                    _handleGetGeoLocationFailed
                );
            } else {
                dispatchState((prev) => ({ ...prev, geosTracking: [] }));
            }
            clientQuery(
                URL_CALL,
                { data: dataSubmit, method: 'GET' },
                _getGeoLocationSuccess,
                _handleGetGeoLocationFailed
            );
        },
        [schedules]
    );

    // This effect for open map when create customer and choose option "Open map"
    useEffect(() => {
        if (Object.keys(location?.state || {}).length) _openMap(location?.state?.location);
    }, [location]);

    // Realtime geo location
    useEffect(() => {
        if (!IS_WEBGL_SUPPORT) return;
        // Don't do anything if addons gps_tracking is off
        if (!settings?.addons?.gps_tracking) return;

        if (response && response.lat && response.lng) {
            const isInScheduleList = schedules.find((item) => +item.user_id === +response.employee_id);
            // Don't do anything if geo location is off and user is technical
            if (
                !getLocalStorage(KEY_MAP_CALENDAR_GEO_LOCATION) &&
                !isInScheduleList &&
                response.room !== +response.employee_id
            )
                return;

            const newGeoLocation = [...(state.geoLocation || [])];
            const geoObject = {
                lat: response.lat,
                lng: response.lng,
                created_at: response.created_at
            };
            const indexGeo = newGeoLocation.findIndex(
                (item) => item.id === response.id && moment(response.created_at).isSame(item.created_at, 'day')
            );

            if (response.lat === '-' && response.lng === '-' && indexGeo !== -1) {
                newGeoLocation[indexGeo]['sleep'] = 1;
            } else {
                if (indexGeo < 0) {
                    const newLocation = {
                        ...response,
                        geos: [{ created_at: response.created_at, lat: response.lat, lng: response.lng }],
                        user_id: `${response.employee_id}`,
                        sleep: 0,
                        meta: { ...response }
                    };
                    newGeoLocation.push(newLocation);
                } else {
                    const hasLocationInList = newGeoLocation[indexGeo].geos.some(
                        (geo) => geo.lat === geoObject.lat && geo.lng === geoObject.lng
                    );

                    if (!hasLocationInList) {
                        newGeoLocation[indexGeo].geos = [...newGeoLocation[indexGeo].geos, geoObject];
                        newGeoLocation[indexGeo].sleep = 0;
                    }
                }
            }
            dispatchState((prev) => ({ ...prev, geoLocation: newGeoLocation }));
        }
    }, [response]);

    useEffect(() => {
        // This effect run when init map
        if (!openmap || !start) return;
        _geoLocation(getLocalStorage(KEY_MAP_CALENDAR_GEO_LOCATION));
    }, [_geoLocation, openmap, schedules, start]);

    useEffect(() => {
        if (!openmap || !start || !end) return;
        _handleGetData();
    }, [schedules, openmap, end, start, calendarMode]);

    const _handleGetData = async () => {
        try {
            const { data } = await clientQuery(GET_MAP_MARKER, {
                data: {
                    start,
                    end: convertTimeToISO(moment(end).utc().subtract(1, 'day').endOf('day')),
                    agenda: viewCalendar,
                    schedule: schedules.map((item) => item.id).toString()
                },
                toFormData: false,
                method: 'GET'
            });

            const jobsState = [];
            const newJobs = eventsToMarker([...(convertEvents(data || [], 'move') || []), ...schedules]) || [];
            const currentFilter = getFilterMapData({ dayofmap, statusofmap, typeofmap });
            refJobs.current = newJobs;

            newJobs.forEach((item) => {
                if (item.isSchedule) {
                    jobsState.push(item);
                } else {
                    checkFilterMapCalendar(item, currentFilter) && jobsState.push(item);
                }
            });

            dispatchState((prevState) => ({ ...prevState, events: jobsState }));
        } catch (error) {
            console.error('@Pham_Tinh_Console:', error);
            dispatchState((prevState) => ({ ...prevState, events: [...schedules] }));
        }
    };

    const _handleDisplayPopup = (latLng, dataMarker, shouldFly) => {
        // Handle active marker on the top
        const divMap = document.getElementById('map');
        if (divMap) {
            divMap.querySelectorAll(`[data-id-marker]`).forEach((item) => {
                if (item.dataset.idMarker === `${dataMarker.id}_${dataMarker?.schedule?.id}`) item.style.zIndex = 1;
                else item.style.zIndex = 0;
            });
        }

        if (!refMapView.current) return;
        if (checkFilterMapCalendar(dataMarker, getFilterMapData({ dayofmap, statusofmap, typeofmap }))) {
            let latLngDisplay = latLng;
            if (dataMarker.idMarker)
                latLngDisplay = events.find((item) => item.idMarker === dataMarker.idMarker)?.coordinates || null;
            refMapView.current._displayPopup(latLngDisplay, dataMarker, shouldFly);
        }
    };

    const _getGeoLocationSuccess = ({ data = [] }) => {
        dispatchState((prev) => ({ ...prev, geoLocation: data }));
    };

    const _handleUpdateEvents = (data, type) => {
        switch (type) {
            case ACTIONS_JOB.MOVE:
                _handleUpdateEventsMove(data);
                break;
            case ACTIONS_JOB.MOVE_WP:
                _handleUpdateEventsMoveWP(data);
                break;
            default:
                break;
        }
    };

    const _handleUpdateEventsMoveWP = (ids) => {
        dispatchState((prev) => {
            const newEvents = [];
            const currentFilter = getFilterMapData({ dayofmap, statusofmap, typeofmap });

            prev.events.forEach((item) => {
                if (ids.includes(item.id)) {
                    const currentItem = {
                        ...item,
                        event: { ...item, is_workpool: true },
                        is_workpool: true,
                        isWorkPool: true
                    };

                    if (checkFilterMapCalendar(currentItem, currentFilter)) {
                        newEvents.push(currentItem);
                    }
                } else {
                    newEvents.push(item);
                }
            });

            refJobs.current = [...refJobs.current].map((item) => {
                return ids.includes(item.id)
                    ? { ...item, event: { ...item, is_workpool: true }, is_workpool: true, isWorkPool: true }
                    : item;
            });

            return { ...prev, events: newEvents };
        });
    };

    const _handleUpdateEventsMove = ({
        previouslyCompleted,
        currentParentJob,
        finalEventId,
        addEventLost,
        currentJobNo,
        eventIds,
        all
    }) => {
        dispatchState((prev) => {
            let eventTemp = [...prev.events];

            if (parseInt(previouslyCompleted) === 1) {
                eventTemp = eventTemp.filter((eventDetail) => eventDetail.eventId !== finalEventId);
            } else {
                eventTemp = eventTemp.filter((eventDetail) => {
                    if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                        if (!eventIds.includes(eventDetail.eventId) && all === 0) {
                            return eventDetail;
                        }
                    } else {
                        return eventDetail;
                    }
                });
            }

            eventTemp = [...eventTemp, ...addEventLost];
            return { ...prev, events: eventsToMarker([...eventTemp, ...schedules]) };
        });
    };

    const _toggleMap = () => {
        !openmap ? _openMap() : _closeMap();
    };

    const _openMap = (searchLocation = null) => {
        if (searchLocation) {
            const { lng, lat, address } = searchLocation;
            if (lng && lat && refMapView.current) refMapView.current._flyWithSearch(lng, lat, address);
        }

        clientQuery(CALENDAR_STORE, {
            data: { type: 13, value: 1 },
            method: 'PUT'
        });
        dispatch(updateMapVisible({ openmap: 1 }));
        _resizeMapView();
    };

    const _closeMap = () => {
        clientQuery(CALENDAR_STORE, { data: { type: 13, value: 0 }, method: 'PUT' });
        dispatch(updateMapVisible({ openmap: 0 }));
        _resizeMapView();
    };

    function _resizeMapView() {
        refMapView.current && refMapView.current._resizeMap();
    }

    const _filterMap = (type, valueFilter) => {
        const newJobs = [];
        const newFilter = { ...getFilterMapData({ dayofmap, statusofmap, typeofmap }), [type]: valueFilter };

        [...refJobs.current].forEach((item) => {
            if (item.isSchedule) {
                newJobs.push(item);
            } else {
                checkFilterMapCalendar(item, newFilter) && newJobs.push(item);
            }
        });

        refMapView.current && refMapView.current._hidePopup();
        dispatch(
            updateSizeMap({
                statusofmap: newFilter.job_status,
                dayofmap: newFilter.week_days,
                typeofmap: newFilter.map_types
            })
        );
        dispatchState((prev) => ({ ...prev, events: newJobs }));
    };

    function _handleReloadEvents(eventTemp, infoEvent) {
        // For action delete job
        if (infoEvent) refJobs.current = deletedJobMapData(refJobs.current, infoEvent);
        dispatchState((prev) => ({ ...prev, events: eventsToMarker([...eventTemp, ...schedules]) }));
    }

    const _handleUpdateStatus = (jobData) => {
        const { list: newEvents, jobPreview } = actionJobStatus(jobData, eventsToMarker(refJobs.current));
        const jobPreviewUpdate = jobPreview;

        // Handle update job preview
        setTimeout(() => {
            if (jobPreviewUpdate)
                dispatch(actionOpenJobPreview({ ...jobPreviewUpdate, schedule: { ...jobPreviewUpdate.schedule } }));
        }, 1000);

        refJobs.current = statusJobMapData(refJobs.current, jobData);
        dispatchState((prev) => {
            const result = [];
            const currentFilter = getFilterMapData({ dayofmap, statusofmap, typeofmap });
            newEvents.forEach((item) => {
                if (checkFilterMapCalendar(item, currentFilter)) result.push(item);
            });
            return { ...prev, events: [...result, ...eventsToMarker(schedules)] };
        });
    };

    const _handleRefetch = () => {
        onRefetchCalendar();
        _handleGetData();
    };

    return (
        <Fragment>
            <MapView
                ref={refMapView}
                width={widthofmap}
                height={heightofmap}
                defaultFilter={getFilterMapData({ dayofmap, statusofmap, typeofmap }) || MAP_FILTER_DEFAULT}
                containerClassName="container-map"
                markers={events}
                markerGeoLocation={geoLocation || []}
                geosTracking={geosTracking || []}
                isMonthView={isMonthView}
                onGeoLocation={_geoLocation}
                onFilter={_filterMap}
                onServiceClick={onServiceClick}
                onRefetchCalendar={_handleRefetch}
                onSelectedMarker={onSelectedMarker}
                onResize={onResizeCalendar}
            />
            {!!openmap ? (
                <Fragment>
                    <Services
                        events={events || []}
                        onReloadEvents={_handleReloadEvents}
                        onRefesh={_handleGetData}
                        isMap
                    />
                    <RealtimeJobServices
                        onRefesh={_handleGetData}
                        events={events || []}
                        onReloadEvents={_handleReloadEvents}
                        handleUpdateState={dispatchState}
                        handleStatusMap={_handleUpdateStatus}
                        schedules={schedules}
                        isMap
                    />
                    <MapServiceEvent onRefresh={_handleGetData} />
                    <GdConfirm
                        ref={refConfirm}
                        title={t('confirm')}
                        listButton={{ confirm: true, cancel: false }}
                        titleConfirm={t('ok')}
                    />
                </Fragment>
            ) : null}
        </Fragment>
    );
};

export default forwardRef(Map);
