export default function IconInfinity(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.75 12.5C3.75 10.9812 4.98122 9.75 6.5 9.75C7.72262 9.75 8.92258 10.4757 9.88112 11.3144C10.3475 11.7225 10.7286 12.1327 10.9931 12.4412L11.0431 12.5L10.9931 12.5588C10.7286 12.8673 10.3475 13.2775 9.88112 13.6856C8.92258 14.5243 7.72262 15.25 6.5 15.25C4.98122 15.25 3.75 14.0188 3.75 12.5ZM12 11.3135C11.712 10.988 11.3283 10.5876 10.8689 10.1856C9.82742 9.27429 8.27738 8.25 6.5 8.25C4.15279 8.25 2.25 10.1528 2.25 12.5C2.25 14.8472 4.15279 16.75 6.5 16.75C8.27738 16.75 9.82742 15.7257 10.8689 14.8144C11.3283 14.4124 11.712 14.012 12 13.6865C12.288 14.012 12.6717 14.4124 13.1311 14.8144C14.1726 15.7257 15.7226 16.75 17.5 16.75C19.8472 16.75 21.75 14.8472 21.75 12.5C21.75 10.1528 19.8472 8.25 17.5 8.25C15.7226 8.25 14.1726 9.27429 13.1311 10.1856C12.6717 10.5876 12.288 10.988 12 11.3135ZM14.1189 13.6856C13.6525 13.2775 13.2714 12.8673 13.0069 12.5588L12.9569 12.5L13.0069 12.4412C13.2714 12.1327 13.6525 11.7225 14.1189 11.3144C15.0774 10.4757 16.2774 9.75 17.5 9.75C19.0188 9.75 20.25 10.9812 20.25 12.5C20.25 14.0188 19.0188 15.25 17.5 15.25C16.2774 15.25 15.0774 14.5243 14.1189 13.6856Z"
                fill="#4C4C4C"
            />
        </svg>
    );
}
