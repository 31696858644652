import { CONVERSATION_COUNT } from 'app/const/api/Sms';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* smsConversationGetTotal({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, CONVERSATION_COUNT, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
