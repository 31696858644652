import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumns = (actionsStatus) => {
    const HEADER_CUSTOMERS_WO_ACTIVE_JOBS = {
        status: {
            title: 'status',
            style: 'col col-sm'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl'
        },
        service_address: {
            title: 'service_address',
            style: 'col col-address'
        },
        last_service_type: {
            title: 'last_service_type',
            style: 'col col-md'
        },
        job_status: {
            title: 'job_status',
            style: 'col col-job-status'
        },
        last_service_date: {
            title: 'last_service_date',
            style: 'col col-md'
        },
        left_time: {
            title: '',
            style: 'col col-sm'
        }
    };

    const REPORT_COLUMNS_CUSTOMERS_WO_ACTIVE_JOBS = [
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'last_service_type',
            title: 'last_service_type',
            status: true
        },
        {
            id: 'job_status',
            title: 'job_status',
            status: true
        },
        {
            id: 'last_service_date',
            title: 'last_service_date',
            status: true
        },
        {
            id: 'left_time',
            title: '',
            status: true
        }
    ];

    const REPORT_CONTENT_CUSTOMERS_WO_ACTIVE_JOBS = {
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        service_address: {
            id: 'service_address',
            style: 'col col-address',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS
        },
        last_service_type: {
            id: 'last_service_type',
            style: 'col col-md'
        },
        job_status: {
            id: 'job_status',
            style: 'col col-job-status d-flex gap-4',
            type: LIST_TABLE_ITEM_TYPE.JOBS_STATUS
        },
        last_service_date: {
            id: 'last_service_date',
            style: 'col col-md'
        },
        left_time: {
            id: 'left_time',
            style: 'col col-sm'
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, HEADER_CUSTOMERS_WO_ACTIVE_JOBS),
        columns: REPORT_COLUMNS_CUSTOMERS_WO_ACTIVE_JOBS,
        contentConfig: REPORT_CONTENT_CUSTOMERS_WO_ACTIVE_JOBS
    };
};
