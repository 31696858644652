import { COMMON } from 'app/const/App';

const getNewParamsOrderStatus = ({ params, actionEvent }) => {
    const newParamsReport = params;
    const { actionType, columnsTarget, currentValue } = actionEvent;
    const { ORDER } = COMMON;
    switch (actionType) {
        case ORDER:
            newParamsReport[ORDER] = columnsTarget.concat(' ', currentValue);
            break;
        default:
            break;
    }
    return newParamsReport;
};

const updateOrderStatusOnTable = ({ headerTable, actionStatus }) => {
    if (!actionStatus) return headerTable;
    const newHeader = headerTable;
    const orderName = actionStatus.split(' ')[0];
    const value = actionStatus.split(' ')[1];
    for (const keyHeader in newHeader) {
        newHeader[keyHeader].currentValue = COMMON.NOT_SET;
    }
    if (newHeader[orderName]) newHeader[orderName].currentValue = value;
    return headerTable;
};

export { getNewParamsOrderStatus, updateOrderStatusOnTable };
