import GridEmpty from 'app/components/grid/components/GridEmpty';
import React, { Fragment } from 'react';
import ResultStripeExisted from './ResultStripeExisted';
import { useTranslation } from 'react-i18next';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';

const ListResultSearch = ({ data = [], type = PAYMENT_MERCHANT_KEYWORD.STRIPE, ...props }) => {
    const { t } = useTranslation();
    if (!data.length)
        return <GridEmpty className="tables-empty m-0" msgEmpty={t('customers:no_type_id_found', { type })} />;
    return (
        <Fragment>
            {!!data.length && (
                <div className="form-results__title">
                    {t('customers:result_type_id_found', { count: data.length, type })}
                </div>
            )}
            {data.map((item) => (
                <ResultStripeExisted key={item.id} type={type} {...item} {...props} />
            ))}
        </Fragment>
    );
};

export default ListResultSearch;
