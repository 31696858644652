import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getGridColumnsSalesFunnel = () => {
    const header = {
        stage: {
            title: 'stage',
            style: 'col col-xl'
        },
        count: {
            title: 'count',
            style: 'col col-count'
        },
        value: {
            title: 'value',
            style: 'col col-value'
        },
        weighted_value: {
            title: 'Weighted Value',
            style: 'col col-weighted'
        },
        avg_time: {
            title: 'Avg Time To Advance',
            style: 'col col-lg'
        },
        conversion_rate: {
            title: 'Conversion Rate',
            style: 'col col-md'
        },
        lost: {
            title: 'Lost',
            style: 'col col-xs'
        }
    };
    const columns = [
        {
            id: 'stage',
            title: 'name',
            status: true
        },
        {
            id: 'count',
            title: 'count',
            status: true
        },
        {
            id: 'value',
            title: 'value',
            status: true
        },
        {
            id: 'weighted_value',
            title: 'weighted_value',
            status: true
        },
        {
            id: 'avg_time',
            title: 'avg_time',
            status: true
        },
        {
            id: 'conversion_rate',
            title: 'conversion_rate',
            status: true
        },
        {
            id: 'lost',
            title: 'lost',
            status: true
        }
    ];
    const contentConfig = {
        stage: {
            title: 'name',
            style: 'col col-xl d-flex',
            type: LIST_TABLE_ITEM_TYPE.STAGE_SALE_FUNNEL
        },
        count: {
            title: 'count',
            style: 'col col-count'
        },
        value: {
            title: 'value',
            style: 'col col-value'
        },
        weighted_value: {
            title: 'weighted_value',
            style: 'col col-weighted'
        },
        avg_time: {
            title: 'avg_time',
            style: 'col col-lg'
        },
        conversion_rate: {
            title: 'conversion_rate',
            style: 'col col-md'
        },
        lost: {
            title: 'lost',
            style: 'col col-xs'
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
