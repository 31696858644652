import React, { useContext } from 'react';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';
import IconDone from 'assets/icon/IconDone';

const Confirmed = () => {
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    function _onChangeConfirm() {
        onChangeDataRepeatContext({ always_confirmed: repeatSettings.always_confirmed ? 0 : 1 });
    }

    return (
        <div className="rows detail-toggle">
            <div className="txt">
                <IconDone isHasColor />
                Set to Confirmed
            </div>
            <div className="details">
                <div className="switch large">
                    <input
                        id="toggle-confirmed"
                        className="toggle toggle-round"
                        type="checkbox"
                        checked={!!repeatSettings.always_confirmed}
                        onChange={_onChangeConfirm}
                    />
                    <label htmlFor="toggle-confirmed" />
                </div>
                <span className="txt-ellipsis">Each recurring job will have its status set to confirmed</span>
            </div>
        </div>
    );
};

export default Confirmed;
