import * as SETTING from 'app/const/setting/SettingPageName';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { getDefaultParams } from 'app/const/setting/SettingParams';
import { COMMON } from 'app/const/App';

const getLocalStorageSettingPage = ({ namePage = null, key = '', defaultCurrentTab }) => {
    const listNamesPage = Object.values(SETTING);

    if (!listNamesPage.includes(namePage)) return false;

    //get data saved in local
    let result = getLocalStorage(namePage);

    //if data is not exist we will get default
    if (!result) {
        result = {
            [COMMON.PARAMS]: getDefaultParams(namePage)
        };
        if (typeof defaultCurrentTab === 'string') {
            result[COMMON.CURRENT_TAB] = defaultCurrentTab;
        }
    }

    //return data base on parameter "key"
    switch (key) {
        case '':
            return result;
        case COMMON.PARAMS:
            return result[COMMON.PARAMS];
        case COMMON.CURRENT_TAB:
            return result[COMMON.CURRENT_TAB];
        default:
            return false;
    }
};

const updateLocalStorageSettingPage = ({ namePage = null, value }) => {
    const listNamesPage = Object.values(SETTING);

    if (!listNamesPage.includes(namePage)) return false;

    const temp = getLocalStorage(namePage);
    const newLocalStorage = { ...temp, ...value };
    return setLocalStorage(namePage, newLocalStorage);
};

const removeLocalStorageSettingPage = ({ namePage = null }) => {
    const listNamesPage = Object.values(SETTING);

    if (!listNamesPage.includes(namePage)) return false;
    return removeLocalStorage(namePage);
};

export { getLocalStorageSettingPage, updateLocalStorageSettingPage, removeLocalStorageSettingPage };
