export const TYPE_SETTING_SCHEDULES = {
    GET_LIST: 'SETTING_GET_LIST_SCHEDULES',
    REASSIGN: 'SETTING_REASSIGN_SCHEDULES',
    ADD_AND_UPDATE: 'SETTING_ADD_AND_UPDATE_SCHEDULES',
    UPDATE: 'SETTING_UPDATE_SCHEDULES',
    DELETE: 'SETTING_DELETE_SCHEDULES'
};

export const getListSchedules = (actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_SCHEDULES.GET_LIST,
        actionSuccess,
        actionFailed
    };
};

export const reassignSchedules = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SCHEDULES.REASSIGN,
        params,
        actionSuccess,
        actionFailed
    };
};

export const addAndUpdateSchedules = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SCHEDULES.ADD_AND_UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateSchedules = (payload) => {
    return {
        type: TYPE_SETTING_SCHEDULES.UPDATE,
        payload
    };
};

export const deleteSchedules = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_SCHEDULES.DELETE,
        params,
        actionSuccess,
        actionFailed
    };
};
