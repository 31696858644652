export default ({ hasColor = false, isMenuActive = false, isGrey, isGreen }) => {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8289 9H20.171C20.7018 9 21.2108 9.21099 21.5859 9.58649L24.648 12.6517C24.8734 12.8773 25 13.1831 25 13.502C25 13.777 24.777 14 24.502 14H21.0704C20.4017 14 19.7772 14.3342 19.4063 14.8906L18.5937 16.1094C18.2228 16.6658 17.5983 17 16.9296 17H15.0722C14.4039 17 13.7798 16.6662 13.4087 16.1104L12.5938 14.8896C12.2228 14.3338 11.5987 14 10.9304 14H7.49827C7.22308 14 7 13.7769 7 13.5017C7 13.1827 7.12667 12.8767 7.35218 12.651L10.4141 9.5864C10.7892 9.21095 11.2982 9 11.8289 9Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15H9.92963C10.5983 15 11.2228 15.3342 11.5937 15.8906L12.4063 17.1094C12.7772 17.6658 13.4017 18 14.0704 18H17.9296C18.5983 18 19.2228 17.6658 19.5937 17.1094L20.4063 15.8906C20.7772 15.3342 21.4017 15 22.0704 15H24C24.5523 15 25 15.4477 25 16V20C25 21.6569 23.6569 23 22 23H10C8.34315 23 7 21.6569 7 20V16C7 15.4477 7.44772 15 8 15Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isGrey)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32">
                <g fill="none" fillRule="evenodd">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="M14.282 0h3.436c4.028 0 6.188.557 8.143 1.603 1.956 1.046 3.49 2.58 4.536 4.536C31.443 8.094 32 10.254 32 14.282v3.436c0 4.028-.557 6.188-1.603 8.143-1.046 1.956-2.58 3.49-4.536 4.536C23.906 31.443 21.746 32 17.718 32h-3.436c-4.028 0-6.188-.557-8.143-1.603-1.956-1.046-3.49-2.58-4.536-4.536C.557 23.906 0 21.746 0 17.718v-3.436c0-4.028.557-6.188 1.603-8.143 1.046-1.956 2.58-3.49 4.536-4.536C8.094.557 10.254 0 14.282 0z"
                                        transform="translate(-914 -401) translate(818 120) translate(0 281) translate(96)"
                                    />
                                    <path
                                        fill="#C1C9D4"
                                        d="M11.829 9h8.342c.53 0 1.04.211 1.415.586l3.062 3.066c.225.225.352.531.352.85 0 .275-.223.498-.498.498H21.07c-.668 0-1.293.334-1.664.89l-.812 1.22c-.371.556-.996.89-1.664.89h-1.858c-.668 0-1.292-.334-1.663-.89l-.815-1.22c-.371-.556-.995-.89-1.664-.89H7.498C7.223 14 7 13.777 7 13.502c0-.32.127-.625.352-.851l3.062-3.065C10.79 9.211 11.298 9 11.83 9z"
                                        transform="translate(-914 -401) translate(818 120) translate(0 281) translate(96)"
                                    />
                                    <path
                                        fill="#929BA9"
                                        d="M8 15h1.93c.668 0 1.293.334 1.664.89l.812 1.22c.371.556.996.89 1.664.89h3.86c.668 0 1.293-.334 1.664-.89l.812-1.22c.371-.556.996-.89 1.664-.89H24c.552 0 1 .448 1 1v4c0 1.657-1.343 3-3 3H10c-1.657 0-3-1.343-3-3v-4c0-.552.448-1 1-1z"
                                        transform="translate(-914 -401) translate(818 120) translate(0 281) translate(96)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );

    if (hasColor) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#EDF9FB"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8289 9H20.171C20.7018 9 21.2108 9.21099 21.5859 9.58649L24.648 12.6517C24.8734 12.8773 25 13.1831 25 13.502C25 13.777 24.777 14 24.502 14H21.0704C20.4017 14 19.7772 14.3342 19.4063 14.8906L18.5937 16.1094C18.2228 16.6658 17.5983 17 16.9296 17H15.0722C14.4039 17 13.7798 16.6662 13.4087 16.1104L12.5938 14.8896C12.2228 14.3338 11.5987 14 10.9304 14H7.49827C7.22308 14 7 13.7769 7 13.5017C7 13.1827 7.12667 12.8767 7.35218 12.651L10.4141 9.5864C10.7892 9.21095 11.2982 9 11.8289 9Z"
                    fill="#1AB6D2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15H9.92963C10.5983 15 11.2228 15.3342 11.5937 15.8906L12.4063 17.1094C12.7772 17.6658 13.4017 18 14.0704 18H17.9296C18.5983 18 19.2228 17.6658 19.5937 17.1094L20.4063 15.8906C20.7772 15.3342 21.4017 15 22.0704 15H24C24.5523 15 25 15.4477 25 16V20C25 21.6569 23.6569 23 22 23H10C8.34315 23 7 21.6569 7 20V16C7 15.4477 7.44772 15 8 15Z"
                    fill="#1AB6D2"
                />
            </svg>
        );
    }
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#1AB6D2"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8289 9H20.171C20.7018 9 21.2108 9.21099 21.5859 9.58649L24.648 12.6517C24.8734 12.8773 25 13.1831 25 13.502C25 13.777 24.777 14 24.502 14H21.0704C20.4017 14 19.7772 14.3342 19.4063 14.8906L18.5937 16.1094C18.2228 16.6658 17.5983 17 16.9296 17H15.0722C14.4039 17 13.7798 16.6662 13.4087 16.1104L12.5938 14.8896C12.2228 14.3338 11.5987 14 10.9304 14H7.49827C7.22308 14 7 13.7769 7 13.5017C7 13.1827 7.12667 12.8767 7.35218 12.651L10.4141 9.5864C10.7892 9.21095 11.2982 9 11.8289 9Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 15H9.92963C10.5983 15 11.2228 15.3342 11.5937 15.8906L12.4063 17.1094C12.7772 17.6658 13.4017 18 14.0704 18H17.9296C18.5983 18 19.2228 17.6658 19.5937 17.1094L20.4063 15.8906C20.7772 15.3342 21.4017 15 22.0704 15H24C24.5523 15 25 15.4477 25 16V20C25 21.6569 23.6569 23 22 23H10C8.34315 23 7 21.6569 7 20V16C7 15.4477 7.44772 15 8 15Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7639 6C16.5215 6 17.214 6.428 17.5528 7.10557L19 10L19.0003 10.2678C18.7061 10.0975 18.3644 10 18 10H17C15.9456 10 15.0818 10.8159 15.0055 11.8507L15 12H8.99999C8.99999 10.8954 8.10456 10 6.99999 10H5.99999C5.63545 10 5.2937 10.0975 4.99939 10.2679L4.99999 10L6.4472 7.10557C6.78598 6.428 7.47851 6 8.23605 6H15.7639Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 11H7C7.55228 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H15C15.5523 13 16 12.5523 16 12C16 11.4477 16.4477 11 17 11H18C18.5523 11 19 11.4477 19 12V16C19 17.1046 18.1046 18 17 18H7C5.89543 18 5 17.1046 5 16V12C5 11.4477 5.44772 11 6 11Z"
                fill="#464B55"
            />
        </svg>
    );
};
