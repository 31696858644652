import { COMMON, LIST_TABLE_ITEM_TYPE, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumnsCustomerEstimates = (actionsStatus, deleted) => {
    const header = {
        estimates: {
            title: 'estimates',
            style: 'col col-sm'
        },
        job: {
            title: 'job',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        total: {
            title: 'total',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        view_estimate: {
            title: '',
            style: 'col'
        }
    };
    const columns = [
        {
            id: 'estimates',
            status: true
        },
        {
            id: 'job',
            status: true
        },
        {
            id: 'date',
            status: true
        },
        {
            id: 'total',
            status: true
        },
        {
            id: 'status',
            status: true
        },
        {
            id: 'view_estimate',
            status: true
        }
    ];
    const contentConfig = {
        estimates: {
            style: 'col col-sm --name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_ESTIMATE,
            styleText: 'name'
        },
        job: {
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_ESTIMATE_JOB
        },
        date: {
            style: 'col'
        },
        total: {
            style: 'col'
        },
        status: {
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.ESTIMATE_STATUS
        },
        view_estimate: {
            style: 'col --name',
            type: LIST_TABLE_ITEM_TYPE.VIEW_ESTIMATE
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...header });
    const newContent = { ...contentConfig };
    newContent['estimates'].styleText = deleted === TYPE_BUTTON_ACTIONS.UNDELETE ? '' : 'name';
    newContent['estimates'].isDeleted = deleted === TYPE_BUTTON_ACTIONS.UNDELETE;

    columns.forEach((col) => {
        const item = col.id;
        if (item === LIST_TABLE_ITEM_TYPE.VIEW_ESTIMATE) {
            col.status = deleted === TYPE_BUTTON_ACTIONS.UNDELETE;
        }
    });

    return {
        header: newHeader,
        columns,
        contentConfig: newContent
    };
};
