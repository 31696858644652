export const TYPE_REPORT_REVENUE_STAFF = {
    GET_LIST_MONTH_TAB: 'GET_LIST_REPORT_REVENUE_STAFF',
    GET_LIST_DETAIL_TAB: 'GET_LIST_REPORT_REVENUE_STAFF_PAYMENT',
    GET_LIST_METHOD_FILTER_REVENUE_BY_STAFF: 'GET_LIST_METHOD_FILTER_REVENUE_BY_STAFF',
    GET_LIST_SERVICE_FILTER_REVENUE_BY_STAFF: 'GET_LIST_SERVICE_FILTER_REVENUE_BY_STAFF'
};

// actions
export const getListReportRevenueByStaffMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_STAFF.GET_LIST_MONTH_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByStaffDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_STAFF.GET_LIST_DETAIL_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterRevenueByStaffRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_STAFF.GET_LIST_METHOD_FILTER_REVENUE_BY_STAFF,
        actionSuccess,
        actionFailed
    };
};

export const getListStaffFilter = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_STAFF.GET_LIST_SERVICE_FILTER_REVENUE_BY_STAFF,
        params,
        actionSuccess,
        actionFailed
    };
};
