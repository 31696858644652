import React, { useReducer, useRef, useEffect, forwardRef, useImperativeHandle, useId } from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { GLOBAL_SEARCH } from 'app/const/Api';
import { useTranslation } from 'react-i18next';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';

const SearchCustomer = forwardRef(
    (
        {
            selected,
            onSelect,
            autoFocus = false,
            typeSearch = '',
            customerName = '',
            isDisable = false,
            triggerFocus = 0
        },
        ref
    ) => {
        const { t } = useTranslation(['report']);

        const [state, dispatchState] = useReducer(reducer, {
            isVisible: autoFocus,
            isLoading: true,
            keyword: customerName,
            data: [],
            loadMore: false,
            position: 0
        });

        const isFirstTime = useRef(true);
        const refDropDown = useRef(null);
        const dropdownId = useId(null);

        const { isVisible, isLoading, keyword, data, loadMore, position } = state;

        useImperativeHandle(ref, () => ({ _setValue: _handleSetKeyWord }));

        function _handleSetKeyWord(value) {
            dispatchState({
                keyword: value
            });
        }

        const defaultParams = {
            term: keyword,
            offset: data.length,
            limit: 20
        };

        useEffect(() => {
            if (isVisible && isFirstTime.current) {
                _getListCustomer(defaultParams, true);
            }
        }, [isVisible]);

        useEffect(() => {
            if (!selected && !isFirstTime.current) {
                dispatchState({ keyword: '' });
            }
        }, [selected]);

        useEffect(() => {
            if (isVisible) {
                document.addEventListener('click', handleClickOutside, true);
                document.addEventListener('keydown', handleHideDropdown, true);
            } else {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            }
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
                document.removeEventListener('keydown', handleHideDropdown, true);
            };
        }, [isVisible]);

        const handleHideDropdown = (event) => {
            const elPrevent = document.getElementById(dropdownId);
            if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
                _closeDropdown();
            }
        };

        const handleClickOutside = (event) => {
            const elPrevent = document.getElementById(dropdownId);

            if (
                refDropDown.current &&
                elPrevent &&
                !elPrevent.contains(event.target) &&
                !refDropDown.current.contains(event.target)
            ) {
                _closeDropdown();
            }
        };

        const _closeDropdown = () => {
            isVisible && dispatchState({ isVisible: false });
        };

        const _getListCustomer = (params, resetResult) => {
            const _getListCustomerSuccess = (response, resetResult) => {
                isFirstTime.current = false;
                const newData = response.customers || [];
                dispatchState({
                    isLoading: false,
                    data: resetResult ? newData : [...data, ...newData],
                    fistTime: false,
                    loadMore: response.show_more
                });
            };

            if (typeSearch) params.type = typeSearch;

            clientQuery(GLOBAL_SEARCH, { data: params, method: 'GET' }, (response) =>
                _getListCustomerSuccess(response, resetResult)
            );
        };

        const _handleSelectOption = (e, dataSelected) => {
            e.preventDefault();

            dispatchState({
                isVisible: false,
                keyword: `${dataSelected?.first_name} ${dataSelected?.last_name}`,
                position: dataSelected.index
            });
            onSelect(dataSelected?.id, dataSelected);

            const elm = document.getElementById('com_select_customer_info');
            elm && elm.classList.remove('field-error');
        };

        const _handleChangeSearch = (keyword) => {
            dispatchState({
                isLoading: true,
                data: [],
                keyword: keyword,
                isVisible: true
            });

            if (!keyword) {
                onSelect('', null);
            }

            _getListCustomer({ ...defaultParams, term: keyword, offset: 0 }, true);
        };

        const _handleOnScrollContent = (e) => {
            if (!isLoading && isScrollToEndBottom(e.target) && loadMore) {
                dispatchState({
                    isLoading: true
                });

                _getListCustomer(defaultParams, false);
            }
        };

        const renderList = (list) => {
            return list.map((item) => {
                const isCheck = item?.id === selected && position === item.index;
                return (
                    <li
                        key={item?.id}
                        className={`items ${isCheck ? 'active' : ''}`}
                        onClick={(e) => _handleSelectOption(e, item)}
                        tabIndex="0"
                    >
                        <div className="user-name">
                            <div className="avt --lg">{item.avatar.toUpperCase()}</div>
                            <div className="user-name-info">
                                <p className="txt-ellipsis fw-500">{`${item?.first_name} ${item?.last_name}`}</p>
                                <span className="txt-ellipsis user-name-info__sub">{item.desc}</span>
                            </div>
                        </div>
                    </li>
                );
            });
        };

        return (
            <div ref={refDropDown} id={dropdownId} className={`v2-dropdown --large ${isVisible ? 'active' : ''}`}>
                <SearchOption
                    defaultValue={keyword}
                    autoFocus={autoFocus}
                    placeholder={t('header:search_customers')}
                    onSearch={_handleChangeSearch}
                    style="search-input"
                    parentStyle="dropbtn items"
                    isDisable={isDisable}
                    triggerFocus={triggerFocus}
                />
                {isVisible && (
                    <div
                        className="v2-dropdown__menu content-user content-full scrolls"
                        onScroll={_handleOnScrollContent}
                    >
                        <ul>{renderList(data)}</ul>
                        {data.length === 0 && !isLoading && (
                            <div className="items justify-center pointer-events-none">
                                <div className="loading -ajaxbar">{t('header:search_not_match')}</div>
                            </div>
                        )}
                        {isLoading && (
                            <div className="items justify-center">
                                <div className="loading -ajaxbar">
                                    <IconLoading />
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
);

export default SearchCustomer;
