import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

const ServicesBalance = ({ customerId, onUpdate = () => {} }) => {
    const { customerBalance, customerCredit } = useContext(SocketContext);

    useEffect(() => {
        if (customerBalance) {
            if (customerBalance.customer_id === customerId) onUpdate({ balance: customerBalance.balance_currency });
        }
    }, [customerBalance]);

    useEffect(() => {
        if (customerCredit) {
            if (customerCredit.customer_id === customerId) onUpdate({ credits: customerCredit.credits_currency });
        }
    }, [customerCredit]);
};

export default ServicesBalance;
