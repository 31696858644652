export default ({ isHasColor = false }) => {
    if (isHasColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6 4H15C16.1046 4 17 4.89543 17 6V14C17 15.1046 16.1046 16 15 16H6C4.89543 16 4 15.1046 4 14V6C4 4.89543 4.89543 4 6 4Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.41005 6.10847C6.50015 6.03848 5.6453 5.6453 5 5C4.89014 4.89015 4.74115 4.82843 4.58579 4.82843C4.26227 4.82843 4 5.0907 4 5.41422V15.1727C4 15.7024 4.21021 16.2106 4.58449 16.5856L4.99817 17C5.64222 17.6452 6.49611 18.0384 7.40509 18.1083L16.8466 18.8344C16.8977 18.8383 16.9488 18.8403 17 18.8403C18.1046 18.8403 19 17.9449 19 16.8403V8.85207C19 7.80699 18.1954 6.93811 17.1534 6.85796L7.41005 6.10847ZM16 13C16 13.5523 15.5523 14 15 14C14.4477 14 14 13.5523 14 13C14 12.4477 14.4477 12 15 12C15.5523 12 16 12.4477 16 13Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.5 9.12712V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5H15.5C16.6046 5.5 17.5 6.39543 17.5 7.5V8.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 7.5V7.5C4.5 8.05228 4.94772 8.5 5.5 8.5H17.5C18.6046 8.5 19.5 9.39543 19.5 10.5V16.5C19.5 17.6046 18.6046 18.5 17.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V7.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M15.5 14.5C16.0523 14.5 16.5 14.0523 16.5 13.5C16.5 12.9477 16.0523 12.5 15.5 12.5C14.9477 12.5 14.5 12.9477 14.5 13.5C14.5 14.0523 14.9477 14.5 15.5 14.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
};
