import { USERS } from 'app/const/setting/SettingPageName';
import React, { useEffect, useReducer, useRef } from 'react';
import { updateLocalStorageSettingPage } from '../utils/localStorage';
import { LIST_TOOLTIP } from 'app/const/Settings';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUserSettings } from 'common/redux/actions/settings/usersAction';
import { LIST_STATUS } from 'app/const/Status';
import loadable from '@loadable/component';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_BRANCHES } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { TYPE_LIST_BRANCH } from 'app/const/Branch';
import UsersProvider from './components/UsersProvider';

const EditUserForm = loadable(() => import('app/modules/settings/users/components/userform'));
const ListUsers = loadable(() => import('app/modules/settings/users/components/ListUsers'));
const BoxTipsSetting = loadable(() => import('../components/BoxTipsSetting'));
const GdConfirm = loadable(() => import('app/components/confirm'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const MainHeaderSettings = loadable(() => import('app/modules/settings/components/MainHeaderSettings'));

export default function SettingsUsers() {
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();
    const refListUser = useRef(null);
    const refEditUserForm = useRef(null);
    const refConfirm = useRef(null);
    const refStatusBar = useRef(null);

    const profileData = useSelector(({ auth }) => auth.user.profile);
    const { isGrowthPlan } = getPlanUser(profileData);

    const [state, dispatchState] = useReducer(reducer, {
        listBranch: []
    });
    const { listBranch } = state;

    useEffect(() => {
        isGrowthPlan && _getListBranch();
    }, []);

    const _onOpenEditUser = (idUserSelected) => {
        updateLocalStorageSettingPage({
            namePage: USERS,
            value: { params: { id: idUserSelected } }
        });
        refEditUserForm.current.selectUser(idUserSelected);
    };

    const _onConfirmDelete = (userId) =>
        dispatch(
            deleteUserSettings({ id: userId }, (response) => _deleteUsersSuccess(userId, response), _deleteUsersFailure)
        );

    const _deleteUsersSuccess = (userId, response) => {
        refListUser.current.deleteUser(userId);
        _onShowAlert('show_success', response.message, LIST_STATUS.SUCCESS);
    };

    const _deleteUsersFailure = (response) => {
        _onShowAlert('show_error', response.message, LIST_STATUS.ERROR);
    };

    const _onOpenAddUser = () => {
        refEditUserForm.current.selectUser(null);
    };

    const _onDeleteUser = (idUserDeleted) => refConfirm.current.open(idUserDeleted);

    const _onAddNewUser = (user) => refListUser.current.addNewUser(user);

    const _onUpdatedUser = (updatedUser) => refListUser.current.updateUser(updatedUser);

    const _onShowAlert = (id, message, status) => refStatusBar.current.showStatusBar(id, message, status);

    const _getListBranch = () => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({ ...prev, listBranch: data }));
        };

        clientQuery(
            GET_BRANCHES,
            { method: 'GET', data: { type: TYPE_LIST_BRANCH.ALL_ACTIVE_BRANCHES } },
            _handleSuccess
        );
    };

    return (
        <>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <UsersProvider>
                    <StatusBar ref={refStatusBar} />
                    <BoxTipsSetting typeId={LIST_TOOLTIP.USERS} onAddNew={_onOpenAddUser} />
                    <div className="contents-user__details scrolls-x">
                        <ListUsers
                            ref={refListUser}
                            handleOpenEditUser={_onOpenEditUser}
                            handleOpenAddUser={_onOpenAddUser}
                        />
                        <EditUserForm
                            ref={refEditUserForm}
                            handleAddedNewUser={_onAddNewUser}
                            handleUpdatedUser={_onUpdatedUser}
                            handleOpenAddUser={_onOpenAddUser}
                            handleDeleteUser={_onDeleteUser}
                            onShowAlert={_onShowAlert}
                            listBranch={listBranch}
                        />
                    </div>
                    <GdConfirm
                        ref={refConfirm}
                        title={t('notification')}
                        message={t('setting:are_you_sure_delete_this_item')}
                        listButton={{ confirm: true, cancel: true }}
                        onConfirm={_onConfirmDelete}
                    />
                </UsersProvider>
            </div>
        </>
    );
}
