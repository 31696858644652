import React, { useContext, useRef, useReducer, useEffect } from 'react';
import { ManageUnitContext } from 'app/modules/jobdetail/contexts/ManageUnitContext';
import IconError from 'assets/icon/IconError';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionCreateUnit } from 'app/const/Api';
import GdButton from 'app/components/button';
import { LIST_STATUS } from 'app/const/Status';
import IconDone from 'assets/icon/IconDone';
import { reducer } from 'app/const/Reducer';
import { ENTER_KEY_CODE } from 'app/const/Keyboard';
import { useSelector } from 'react-redux';

let timmer = null;

export default function AddUnit({ onUpdate }) {
    const { jobData, updateUnit } = useSelector(({ createJobReducer }) => createJobReducer);
    const onUpdateUnitSuccess = useSelector(({ locationReducer }) => locationReducer.locationData?.onUpdateUnitSuccess);
    const onUpdateUnits = useSelector(({ jobDetailReducer }) => jobDetailReducer?.jobDetail?.onUpdateUnits);
    const [state, dispatchState] = useReducer(reducer, {
        unitName: '',
        isLoading: false,
        isWarning: null
    });

    const { manageUnitContextData, updateManageUnitContextData } = useContext(ManageUnitContext);
    const refInput = useRef(null);
    const finalUnitName = state.unitName.trim();
    const finalWarning = state.isWarning;
    const buildingSelectedId = manageUnitContextData.buildingSelected;

    useEffect(() => {
        if (finalWarning) {
            timmer && clearTimeout(timmer);
            timmer = setTimeout(() => {
                dispatchState({
                    isWarning: null
                });
            }, 2000);
        }
    }, [finalWarning]);

    function _handleClickAdd(e) {
        e && e.stopPropagation();

        const buildingSelected =
            manageUnitContextData.buildingData.find((item) => item.id === buildingSelectedId) || {};
        const finalListUnit = Array.isArray(buildingSelected.units) ? buildingSelected.units : [];

        if (finalListUnit.some((item) => item.name === finalUnitName)) {
            dispatchState({
                isWarning: {
                    type: LIST_STATUS.ERROR,
                    message: 'Oops! This unit has existed'
                }
            });
            return false;
        }

        const idTemp = Date.now();

        const newData = {
            id: idTemp,
            name: finalUnitName,
            is_check: false,
            parent_id: buildingSelectedId,
            created: idTemp
        };

        if (manageUnitContextData.withoutFetch) {
            _handleCreateUnitSuccess(
                { data: { unit_id: idTemp }, message: `Save building "${finalUnitName}" successfully.` },
                newData
            );
            return false;
        }

        dispatchState({ isLoading: true });

        const optionsQuery = {
            method: 'POST',
            data: {
                name: finalUnitName,
                job_id: manageUnitContextData.jobId,
                id_building: buildingSelectedId
            }
        };

        clientQuery(
            actionCreateUnit(manageUnitContextData.locationId),
            optionsQuery,
            (res) => _handleCreateUnitSuccess(res, newData),
            _handleCreateUnitFailed
        );
    }

    function _handleCreateUnitSuccess(response, newData) {
        const reponseData = response.data;
        const unitId = reponseData.unit_id;
        const dataCreate = { id: unitId, name: newData.name, location_id: manageUnitContextData.locationId };
        newData = { ...newData, id: unitId };

        // Handle for customer detail unitFilter
        if (onUpdateUnitSuccess) onUpdateUnitSuccess(dataCreate, 'create');
        if (updateUnit && jobData) updateUnit(dataCreate, 'create', jobData.customer_id);
        if (onUpdateUnits) onUpdateUnits(dataCreate, 'create');

        dispatchState({
            isLoading: false,
            unitName: '',
            isWarning: {
                type: LIST_STATUS.SUCCESS,
                message: response.message.toString()
            }
        });

        let tempBuilding = [...manageUnitContextData.buildingData];
        const tempUnitData = [...manageUnitContextData.unitData, { ...reponseData, top_note: '' }];

        tempBuilding = tempBuilding.map((item) => {
            if (item.id === buildingSelectedId) {
                return {
                    ...item,
                    units: [...item.units, newData].sort((a, b) =>
                        a.name.localeCompare(b.name, undefined, { numeric: true })
                    )
                };
            }
            return item;
        });

        onUpdate(tempBuilding, [...tempUnitData]);

        updateManageUnitContextData({
            buildingData: tempBuilding,
            unitSelected: unitId,
            unitData: [...tempUnitData]
        });
    }

    function _handleCreateUnitFailed(response) {
        dispatchState({
            isLoading: false,
            isWarning: {
                type: LIST_STATUS.ERROR,
                message: response.message.toString()
            }
        });
    }

    function _handleChangeUnitName(e) {
        dispatchState({
            unitName: e.target.value,
            isWarning: null
        });
    }

    function _handleKeyPress(e) {
        if (e.charCode === ENTER_KEY_CODE || e.key === 'Enter' || e.which === ENTER_KEY_CODE) {
            _handleClickAdd(e);
        }
    }

    function _renderWarning() {
        if (!finalWarning) {
            return false;
        }

        switch (finalWarning.type) {
            case LIST_STATUS.ERROR:
                return (
                    <p className="txt-incorrect">
                        <IconError />
                        {finalWarning.message}
                    </p>
                );
            case LIST_STATUS.SUCCESS:
                return (
                    <p className="txt-incorrect --is-correct">
                        <IconDone isSuccess />
                        {finalWarning.message}
                    </p>
                );
            default:
                false;
        }
        return (
            <p className="txt-incorrect">
                <IconError />
                {finalWarning.message}
            </p>
        );
    }

    return (
        <div className="flex-column__head header elm-parent-focus">
            <div className="mdu-label">Units #</div>
            <div className={`header__form ${buildingSelectedId ? '' : 'is-disable'}`}>
                <input
                    ref={refInput}
                    className="field-input"
                    value={state.unitName}
                    type="text"
                    placeholder="Unit Name"
                    onKeyPress={_handleKeyPress}
                    onChange={(e) => _handleChangeUnitName(e)}
                />

                <GdButton
                    isLoading={state.isLoading}
                    className={`v2-btn-main ${finalUnitName.length === 0 ? 'is-disable' : ''}`}
                    onClick={_handleClickAdd}
                >
                    Add
                </GdButton>
                {_renderWarning()}
            </div>
        </div>
    );
}
