import React from 'react';
import { useTranslation } from 'react-i18next';

const GDRowEmpty = ({ measureRef, startTransform }) => {
    const { t } = useTranslation();
    return (
        <div
            ref={measureRef}
            style={{
                position: 'absolute',
                transform: `translateY(${startTransform}px)`,
                top: 0,
                left: 0,
                width: '100%'
            }}
        >
            <div className="content-empty">{t('empty_table_text')}</div>
        </div>
    );
};

export default GDRowEmpty;
