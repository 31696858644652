import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { DELAY_CLICK, ENTER_KEY_CODE, SRC_LOGO_GORILLADESK } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_CODE_BY_COUNTRY } from 'app/const/Settings';
import { ADD_COMPANY_DETAILS, ADD_PERSONAL_DETAILS, SIGN_UP_TRACKING_INFO } from 'app/const/Mixpanel';
import AuthError from 'app/modules/auth/components/AuthError';
import ButtonSave from 'app/components/button/ButtonSave';
import { mixpanelSignup } from 'app/modules/mixpanel/MixpanelSignup';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleTrackingEvent } from 'common/utils/MixpanelUtils';
import TrialStepOne from './TrialStepOne';
import TrialStepThree from './TrialStepThree';
import TrialStepTwo from './TrialStepTwo';
import {
    ATTRIBUTION,
    COMPANY_NAME,
    COMPANY_PHONE,
    COUNTRY_CODE,
    PHONE_NUMBER_LENGTH,
    ESTIMATE,
    FIRST_NAME,
    INDUSTRY,
    LAST_NAME,
    NAME,
    ONE,
    PHONE,
    REVENUE,
    SOURCE,
    STEP,
    TEAMSIZE,
    THREE,
    TWO,
    VALIDATE_PHONE_BY_CODE
} from './const';
import { detectKeyPress } from 'common/utils/FunctionUtils';
import { capitalizeEachWord } from 'common/utils/StringUtils';

let timer;

const FreeTrial = ({ submitSignup = () => {}, errorSignupForm, userId = '' }) => {
    const { t } = useTranslation(['common', 'auth']);
    const location = useLocation();
    const params = location?.state?.params;

    const initialState = {
        stepsActive: [STEP[ONE].value],
        stepActive: STEP[ONE].value,
        error: null,
        info: {
            [COUNTRY_CODE]: LIST_CODE_BY_COUNTRY[0].code,
            user_id: userId,
            [FIRST_NAME]: params?.first_name || '',
            [LAST_NAME]: params?.last_name || '',
            [COMPANY_PHONE]: '',
            [INDUSTRY]: '',
            [COMPANY_NAME]: '',
            [TEAMSIZE]: '',
            [ESTIMATE]: '',
            [SOURCE]: ''
        },
        disableForm: false,
        errorSignup: null
    };

    const [state, dispatchState] = useReducer(reducer, initialState);
    const infoTrialRef = useRef(initialState.info);

    const { stepsActive, stepActive, error: finalError, disableForm, errorSignup } = state;
    const refChangeInfo = useRef(false);
    const refButtonSave = useRef(null);

    useEffect(() => {
        if (errorSignupForm) {
            dispatchState((prevState) => {
                return {
                    ...prevState,
                    disableForm: false,
                    errorSignup: errorSignupForm
                };
            });
            refButtonSave.current?.removeLoading();
        }
    }, [errorSignupForm]);

    const _handleChangeInfo = (value) => {
        refChangeInfo.current = value;
    };

    const _updateSteps = (value) => {
        const stepsOrder = Object.values(STEP);
        const index = stepsOrder.findIndex((step) => step.value === value);

        if (index === -1) return { stepsActive: [], stepActive: null };

        const updatedSteps = stepsOrder.slice(0, index + 1).map((step) => step.value);
        const stepActive = updatedSteps[updatedSteps.length - 1];
        _handleChangeInfo(false);
        return { stepsActive: updatedSteps, stepActive };
    };

    const _handleFocus = () => {
        dispatchState((prev) => ({ ...prev, error: null }));
    };

    const _validateStepFields = (step, infoTrial) => {
        const msgError = {};
        const stepFieldMap = {
            [STEP[ONE].value]: [FIRST_NAME, LAST_NAME, COMPANY_PHONE, INDUSTRY],
            [STEP[TWO].value]: [COMPANY_NAME, TEAMSIZE, ESTIMATE],
            [STEP[THREE].value]: [SOURCE]
        };

        const requiredFields = stepFieldMap[step] || [];

        requiredFields.forEach((field) => {
            const valueField = !!infoTrial?.[field] ? infoTrial[field].trim().toLowerCase() : '';

            const fieldTransform = { [ESTIMATE]: REVENUE, [SOURCE]: ATTRIBUTION };
            if (!valueField)
                msgError[field] = t(`auth:${fieldTransform[field] || field}`).concat(' ', t('auth:can_not_be_blank'));
            if (
                field === COMPANY_PHONE &&
                !!VALIDATE_PHONE_BY_CODE[infoTrial?.country_code] &&
                !!valueField &&
                valueField.length < PHONE_NUMBER_LENGTH
            )
                msgError[field] = t(`auth:${field}`).concat(' ', t('auth:must_contain_10_digits'));
        });

        return msgError;
    };

    const _handleUpdateSteps = (value) => {
        dispatchState((prev) => ({ ...prev, ..._updateSteps(value), error: {} }));
    };

    const _onChangeStep = (value) => {
        if (value <= STEP[THREE].value) {
            const msgError = _validateStepFields(stepActive, infoTrialRef.current);

            if (!!Object.keys(msgError).length) {
                dispatchState((prev) => ({ ...prev, error: msgError }));
                return;
            }
        }

        if (refChangeInfo.current) {
            const stepFieldMap = {
                [STEP[TWO].value]: [FIRST_NAME, LAST_NAME, PHONE, INDUSTRY],
                [STEP[THREE].value]: [COMPANY_NAME, TEAMSIZE, ESTIMATE]
            };
            const { country_code, company_phone, user_id } = infoTrialRef.current;
            const fields = stepFieldMap[value] || [];
            const postData = { user_id };

            const fieldTransformations = {
                [PHONE]: `${
                    LIST_CODE_BY_COUNTRY.find((country) => country.code === country_code)?.dial_code || ''
                }${company_phone}`
            };

            fields.forEach((field) => {
                const transformedValue = fieldTransformations[field] || infoTrialRef.current[field];
                postData[field] = transformedValue;
            });

            handleTrackingEvent(
                mixpanelSignup({
                    profile: {
                        id: user_id,
                        name:
                            value === STEP[TWO].value
                                ? capitalizeEachWord(postData[FIRST_NAME] + ' ' + postData[LAST_NAME])
                                : undefined
                    },
                    company: { ...postData, [NAME]: capitalizeEachWord(postData[COMPANY_NAME]) },
                    event: value === STEP[TWO].value ? ADD_PERSONAL_DETAILS : ADD_COMPANY_DETAILS
                })
            );

            clientQuery(SIGN_UP_TRACKING_INFO, {
                data: postData,
                toFormData: false,
                useGdBranchID: false,
                method: 'POST'
            });
        }

        _handleUpdateSteps(value);
    };

    const _handleSetInfoValue = ({ key, value }) => {
        _handleChangeInfo(true);
        infoTrialRef.current = { ...infoTrialRef.current, [key]: value };
    };

    const _onChangeInput = (e) => {
        _handleChangeInfo(true);
        const { name, value } = e.target;
        _handleSetInfoValue({ key: name, value });
    };

    const _handleSubmit = () => {
        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            _submitFormTrial();
        }, DELAY_CLICK);
    };

    const _handleKeypress = (e) => {
        if (detectKeyPress(e, ENTER_KEY_CODE)) _submitFormTrial();
    };

    const renderStepContent = () => {
        const getCommonProps = () => ({
            error: finalError,
            fileTranslation: 'auth',
            infoTrial: infoTrialRef.current || {},
            handleFocus: _handleFocus,
            handleSetValue: _handleSetInfoValue,
            onChangeInput: _onChangeInput,
            onKeyPress: _handleKeypress
        });

        switch (stepActive) {
            case STEP[ONE].value:
                return <TrialStepOne {...getCommonProps()} />;
            case STEP[TWO].value:
                return <TrialStepTwo {...getCommonProps()} />;
            case STEP[THREE].value:
                return <TrialStepThree {...getCommonProps()} />;
            default:
                return null;
        }
    };

    const _renderStepTitle = () => {
        const activeStep = Object.values(STEP).find((step) => step.value === stepActive) || {};

        return (
            <Fragment>
                <h3 className="fs-20 black mb-2">{t(`auth:${activeStep.title}`)}</h3>
                <p className="fs-14 grey-very-dark mb-5">{t(`auth:${activeStep.subTitle}`)}</p>
            </Fragment>
        );
    };

    const _submitFormTrial = () => {
        const onSubmitForm = () => {
            const msgError = _validateStepFields(stepActive, infoTrialRef.current);

            if (!!Object.keys(msgError).length) {
                dispatchState((prev) => ({ ...prev, error: msgError }));
                return;
            }
            const {
                first_name,
                last_name,
                country_code,
                company_phone,
                industry,
                company_name,
                team_size,
                estimate,
                source,
                user_id: finalUserId
            } = infoTrialRef.current;

            const postData = {
                company: {
                    phone: `${
                        LIST_CODE_BY_COUNTRY.find((country) => country.code === country_code)?.dial_code || ''
                    }${company_phone}`,
                    address: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    industry,
                    team_size,
                    estimate,
                    source,
                    email: ''
                },
                profile: {
                    user_id: finalUserId,
                    company_name: company_name,
                    email: '',
                    first_name,
                    last_name,
                    password: '',
                    username: ''
                }
            };
            refButtonSave.current?.setLoading(true);
            dispatchState((prev) => ({ ...prev, disableForm: true }));
            submitSignup(postData);
        };

        return stepActive < STEP[THREE].value ? _onChangeStep(stepActive + 1) : onSubmitForm();
    };

    const _renderButton = () => {
        return (
            <ButtonSave
                ref={refButtonSave}
                wrapClass={classNames('gd-getstarted v2-btn-main gd-btn-getstarted fw-500 mb-7', {
                    disable: disableForm
                })}
                title={t(`auth:${stepActive === STEP[THREE].value ? 'get_started' : 'next'}`)}
                disable={disableForm}
                isNotLoading={!disableForm}
                onSave={_handleSubmit}
            />
        );
    };

    const _handleChangeStepBar = (value) => {
        if (stepActive === value) return;

        if (stepActive > value) {
            _handleUpdateSteps(value);
            return;
        }

        _onChangeStep(stepActive + 1);
    };
    const _renderStepBar = () => {
        return Object.values(STEP).map(({ id, value }) => (
            <div
                key={id}
                className={classNames('step', { active: stepsActive.includes(value) })}
                onClick={() => _handleChangeStepBar(value)}
            />
        ));
    };

    return (
        <div className="gd-form-v2 px-6">
            <AuthError text={errorSignup} />
            <h1 className="logo-text-gorilla">
                <img src={SRC_LOGO_GORILLADESK} width={377} height={90} />
            </h1>
            <div className="multi-step">{_renderStepBar()}</div>
            <div className="container-tabs">
                {_renderStepTitle()}
                <div className="has-form flex-column gap-16">{renderStepContent()}</div>
            </div>
            {_renderButton()}
        </div>
    );
};

export default FreeTrial;
