export const TYPE_DRIP_REPORT = {
  GET_REPORT_ACTIVITY: "GET_REPORT_ACTIVITY",
  GET_REPORT_SUMMARY: "GET_REPORT_SUMMARY",
  GET_REPORT_LINK: "GET_REPORT_LINK",
  GET_REPORT_DETAIL: "GET_REPORT_DETAIL",
}

export const fetchReportActivity = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_REPORT.GET_REPORT_ACTIVITY,
    params,
    actionSuccess,
    actionFailed
  }
}

export const fetchReporSummary = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_REPORT.GET_REPORT_SUMMARY,
    params,
    actionSuccess,
    actionFailed
  }
}

export const fetchReporLink = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_REPORT.GET_REPORT_LINK,
    params,
    actionSuccess,
    actionFailed
  }
}


export const fetchReporDetail = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_REPORT.GET_REPORT_DETAIL,
    params,
    actionSuccess,
    actionFailed
  }
}