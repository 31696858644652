import { createPopper } from '@popperjs/core';
import React, { useRef, useState } from 'react';
import { Popper } from 'react-popper';

const TooltipPopper = ({
    children,
    className = 'tooltip',
    tooltipClass = 'tooltiptext',
    tooltipText = '',
    popperConfig = {
        placement: 'top',
        modifiers: [{ name: 'offset', options: { offset: [0, 0] } }],
        strategy: 'fixed'
    },
    ...props
}) => {
    const triggerRef = useRef(null);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleTooltipVisible = () => {
        setTooltipVisible(!tooltipVisible);
    };

    return (
        <div
            ref={triggerRef}
            className={className}
            onMouseEnter={handleTooltipVisible}
            onMouseLeave={handleTooltipVisible}
            {...props}
        >
            {children}
            <Popper>
                {({ ref, style }) => (
                    <div ref={ref} style={style}>
                        <Tooltip
                            refContent={triggerRef.current}
                            tooltipClass={tooltipClass}
                            tooltipText={tooltipText}
                            popperConfig={popperConfig}
                        />
                    </div>
                )}
            </Popper>
        </div>
    );
};

const Tooltip = ({ refContent, tooltipClass, tooltipText, popperConfig = {} }) => {
    if (!refContent) return null;
    return (
        <div
            ref={(node) => {
                if (node) createPopper(refContent, node, popperConfig);
            }}
            className={tooltipClass}
        >
            {tooltipText}
        </div>
    );
};

export default TooltipPopper;
