import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';
import { JOB_STATUS_END_POINT } from 'app/const/api/V2';
import { JOB_CUSTOM, JOB_EXCLUDE } from 'app/const/Job';
import ButtonSave from 'app/components/button/ButtonSave';
import InputColor from 'app/components/input/InputColor';
import { DEFAULT_COLOR } from '../utils';
import { ACCOUNT_ROLE } from 'app/const/App';
import { checkPermission } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';

const ManageActiveJobStatusCreate = ({ onCreate, isCustom = false, options = [], groups }) => {
    const { t } = useTranslation(['common', 'addons']);
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const userRole = useSelector(({ auth }) => auth?.user?.profile?.role);
    const isSuperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;
    const permissionAdd = checkPermission(permissionList, PERMISSIONS.addCustomJobStatus) || isSuperAdmin;

    const refLabel = useRef(null);
    const refButtonSave = useRef(null);
    const refJobStatus = useRef(null);
    const refOnChange = useRef(null);
    const refColor = useRef(null);
    const refAlert = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        selectedName: options[0] || {},
        isLoading: true
    });

    const { selectedName } = state;

    useEffect(() => {
        refButtonSave.current?.setDisable(true);
    }, []);

    const _handleSelect = (item) =>
        dispatchState((prevState) => ({
            ...prevState,
            selectedName: options.find((option) => option.id === item)
        }));

    const _handleFinally = () => refButtonSave.current.removeLoading();

    const _handleCreate = () => {
        const isExistStatus = checkExistJobStatus();
        if (isExistStatus) return _handleDisplayAlert({ message: t('addons:exist_job_status'), success: false });

        const data = {
            label: refLabel.current.value,
            type: isCustom ? JOB_CUSTOM : selectedName.type,
            color: isCustom ? refColor.current?.getValue() : selectedName.color,
            name: isCustom ? refJobStatus?.current?.value : selectedName.name
        };

        const _handleCreateSuccess = ({ data }) => {
            onCreate(data);
            _handleDisplayAlert({ message: t('addons:job_status_create_success'), success: true });
            if (isCustom) refJobStatus.current.value = '';
            refLabel.current.value = '';
            refButtonSave.current?.setDisable(true);
            refOnChange.current = null;
        };

        const _handleCreateFail = (err) => _handleDisplayAlert(err);

        clientQuery(
            JOB_STATUS_END_POINT,
            { method: 'POST', toFormData: false, data },
            _handleCreateSuccess,
            _handleCreateFail,
            _handleFinally
        );
    };

    const _handleOnChange = () => {
        const labelInputValid = !!isCustom || refLabel.current.value;
        const customInputValid = !isCustom || refJobStatus.current.value;
        const buttonDisabled = !(labelInputValid && customInputValid);
        refButtonSave.current?.setDisable(buttonDisabled);
    };

    const checkExistJobStatus = () => {
        const groupType = isCustom ? groups[JOB_CUSTOM] || [] : groups[selectedName.type] || [];
        const name = isCustom ? refJobStatus.current.value : selectedName.name;
        const item = groupType.find(
            (item) =>
                item.name.trim().toLowerCase() === name.trim().toLowerCase() &&
                item.label.trim().toLowerCase() === refLabel.current?.value?.trim()?.toLowerCase()
        );
        return !!item;
    };

    const _handleDisplayAlert = ({ message, success = true }) => {
        if (!refAlert.current) return;
        refAlert.current.clearAllStatusBar();
        refAlert.current.showStatusBar('alert_create', message, success ? LIST_STATUS.SUCCESS : LIST_STATUS.ERROR);
        _handleFinally();
    };

    if (!permissionAdd) return null;

    return (
        <Fragment>
            <GDStatusBar ref={refAlert} />
            <div className="boxs">
                <div className="boxs__header flex-column gap-4">
                    <p className="fs-16 fw-600">
                        {t(`addons:${isCustom ? 'create_custom_job_status' : 'customize_current_job_status'}`)}
                    </p>
                    <p className="black-darker3">
                        {t(`addons:${isCustom ? 'create_job_status_description' : 'customize_job_status_description'}`)}
                    </p>
                </div>
                <div className="rows --custom-status">
                    {isCustom ? (
                        <div className="col-select-color">
                            <InputColor ref={refColor} className="field-input" defaultColor={DEFAULT_COLOR} />
                        </div>
                    ) : null}
                    <div className="col-status flex-1">
                        {isCustom ? (
                            <input
                                ref={refJobStatus}
                                type="text"
                                className="field-input"
                                placeholder={t('addons:job_status')}
                                onChange={_handleOnChange}
                            />
                        ) : (
                            <CalendarDropdown
                                id="dropdown-select-form-user"
                                options={options.filter((item) => !JOB_EXCLUDE.MANAGE_CUSTOM_JOB.includes(item.type))}
                                selected={selectedName?.name}
                                selectedOption={selectedName}
                                keyGetKey="type"
                                keyGetName="name"
                                keyGetValue="type"
                                onSelect={_handleSelect}
                            />
                        )}
                        <input
                            ref={refLabel}
                            type="text"
                            className="field-input"
                            placeholder={t('addons:job_status_label')}
                            onChange={_handleOnChange}
                        />
                    </div>
                    <ButtonSave ref={refButtonSave} wrapClass={`v2-btn-main btn-add-job `} onSave={_handleCreate}>
                        {t('common:add')}
                    </ButtonSave>
                </div>
            </div>
        </Fragment>
    );
};

export default ManageActiveJobStatusCreate;
