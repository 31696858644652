import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { SettingTemplateProvider } from './SettingTemplateContext';
import SettingTemplateContent from './components/SettingTemplateContent';
import { TYPE_SETTING_TEMPLATE } from 'common/redux/actions/settings/templateAction';
import { SETTING_TEMPLATE_TYPES } from '../emailinbox/const';

const SettingEmailTemplate = (
    { type = '', templateType = 1, messageCreateSuccess = '', messageUpdateSuccess = '', onClose = () => {} },
    ref
) => {
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, template: {} });
    const { isOpen, template } = state;

    useImperativeHandle(ref, () => ({ displayTemplate: _handleDisplayTemplate }));

    const _handleDisplayTemplate = (idTemplate, typeAppointment = null, isCreateTemplate = false) => {
        dispatchState({ isOpen: true, template: { idTemplate, typeAppointment, isCreateTemplate } });
    };

    const _handleHideTemplate = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSuccess = (dataTemplate, isCreateTemplate, message = '') => {
        onClose(dataTemplate, isCreateTemplate, message);
        dispatch({ type: TYPE_SETTING_TEMPLATE.RESET_LOAD_TEMPLATES, payload: { key: SETTING_TEMPLATE_TYPES[type] } });
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal wrapper-has-preview is-preview-template open"
            onRequestClose={_handleHideTemplate}
        >
            <SettingTemplateProvider
                initialData={{ ...template, typeTemplate: type, idTypeTemplate: templateType }}
                messageCreateSuccess={messageCreateSuccess}
                messageUpdateSuccess={messageUpdateSuccess}
                onCloseTemplate={_handleHideTemplate}
                onUpdateSuccess={_handleSuccess}
            >
                <SettingTemplateContent />
            </SettingTemplateProvider>
        </ReactModal>
    );
};

export default forwardRef(SettingEmailTemplate);
