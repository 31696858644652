import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumnsCustomerStatement = (columns = []) => {
    const HEADER_STATEMENT = {
        date: {
            title: 'date',
            style: 'col'
        },
        invoice: {
            title: 'invoice',
            style: 'col'
        },
        number: {
            title: 'po_number',
            style: 'col'
        },
        service: {
            title: 'service',
            style: 'col'
        },
        payment_date: {
            title: 'payment_date',
            style: 'col'
        },
        amount: {
            title: 'amount',
            style: 'col'
        },
        paid: {
            title: 'paid',
            style: 'col'
        },
        balance: {
            title: 'balance',
            style: 'col'
        }
    };
    const STATEMENT_COLUMNS = [
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'invoice',
            title: 'invoice',
            status: true
        },
        {
            id: 'number',
            title: 'po_number',
            status: true
        },
        {
            id: 'service',
            title: 'service',
            status: true
        },
        {
            id: 'payment_date',
            title: 'payment_date',
            status: true
        },
        {
            id: 'amount',
            title: 'amount',
            status: true
        },
        {
            id: 'paid',
            title: 'paid',
            status: true
        },
        {
            id: 'balance',
            title: 'balance',
            status: true
        }
    ];
    const STATEMENT_COLUMNS_CONTENT = {
        date: {
            title: 'date',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        invoice: {
            title: 'invoice',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        number: {
            title: 'po_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        service: {
            title: 'service',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.STATEMENT_SERVICE
        },
        payment_date: {
            title: 'payment_date',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        amount: {
            title: 'amount',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        },
        paid: {
            title: 'paid',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.BALANCE,
            isMethod: true
        },
        balance: {
            title: 'balance',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.BALANCE
        }
    };

    const newHeader = {};
    const newContent = {};
    const newColumns = [];

    STATEMENT_COLUMNS.forEach((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_STATEMENT[item];
            newContent[item] = STATEMENT_COLUMNS_CONTENT[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
