import React, { Fragment, forwardRef, useImperativeHandle, useRef } from 'react';
import IconPhone from './IconPhone';
import { formatPhoneNumberVoip, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';

const GdPhoneItem = (
    {
        isValid = null,
        phoneType,
        phone,
        isPreview = false,
        onlySelect = false,
        onClick = () => {},
        isStopPropagationOnClick = false,
        isUsePopup = false,
        customer = null,
        typeOpen,
        customerId = '',
        contactId = null
    },
    ref
) => {
    const refPhoneDropdown = useRef(null);

    const finalPhoneType = typeof phoneType === 'string' ? phoneType : phoneType?.name || '';
    const finalPhoneNumber = validatePhoneNumberVoip(phone) ? formatPhoneNumberVoip(phone) : phone;

    useImperativeHandle(ref, () => ({
        onMakeCall: _handleMakeCall
    }));

    const _handleMakeCall = () => {
        if (isPreview) return;

        if (onlySelect) {
            onClick(formatPhoneNumberVoip(phone));
            return;
        }
        refPhoneDropdown.current?.click();
    };

    const _handleClickPhone = (target) => {
        isStopPropagationOnClick && target.stopPropagation();
        const event = new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_PHONE_POPPER, {
            detail: {
                phoneNumber: finalPhoneNumber,
                isUsePopup,
                customer,
                typeOpen,
                target,
                contactId,
                customerId
            }
        });
        dispatchEvent(event);
        return;
    };

    return (
        <Fragment>
            <div className="valid-phone tooltip">
                {isPreview ? (
                    <p className="valid-phone__number">{finalPhoneNumber}</p>
                ) : (
                    <div className="valid-phone__number active">
                        <div className="cursor-pointer tooltip">
                            <p
                                ref={refPhoneDropdown}
                                className="txt-ellipsis txt-phone-number"
                                onClick={_handleClickPhone}
                            >
                                {finalPhoneNumber}
                            </p>
                        </div>
                    </div>
                )}

                {!!finalPhoneType ? (
                    <div className="valid-phone__btn" title={finalPhoneType}>
                        <IconPhone isValid={isValid} />
                        <span className="phone-type">{finalPhoneType}</span>
                    </div>
                ) : null}
            </div>
        </Fragment>
    );
};

export default forwardRef(GdPhoneItem);
