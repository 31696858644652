import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import VOIPVoicemailDrop from '../VOIPVoicemailDrop';
import { reducer } from 'app/const/Reducer';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import IconFileType from 'assets/icon/IconFileType';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconTrash from 'assets/icon/IconTrash';
import ButtonSave from 'app/components/button/ButtonSave';
import { VOIP_PRESIGNED_TYPE } from 'app/const/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_DETAIL_VOIP_MUSIC } from 'app/const/api/Voip';
import GdConfirm from 'app/components/confirm';

const ID_CUSTOM = '0';

const BoxMusic = ({ title = '', description = '', initialData = {}, musics = [], onShowStatus = () => {} }) => {
    const { t } = useTranslation('addons');
    const refVoicemail = useRef(null);
    const refDropdown = useRef(null);
    const refButtonSave = useRef(null);
    const refSwitch = useRef(null);
    const refConfirm = useRef(null);

    const { key: keyMusic, status, custom_music, music_id } = initialData;

    const [state, dispatchState] = useReducer(reducer, {
        isEnable: false,
        listMusic: musics,
        urlMusic: musics.find((item) => item.id === music_id)?.url || null,
        music_id,
        custom_music
    });

    const {
        urlMusic: finalUrlMusic,
        listMusic: finalListMusic,
        music_id: finalMusicId,
        custom_music: finalCustomMusic
    } = state;

    const isDisable = finalListMusic.length >= 5;

    useEffect(() => {
        if (!!custom_music) {
            dispatchState((prev) => {
                const newListMusic = [{ id: ID_CUSTOM, name: 'Custom Music', url: custom_music }, ...prev.listMusic];
                const music = newListMusic.find((item) => item.id === finalMusicId);
                refVoicemail.current.setGreeting({ url: music?.url });

                return {
                    ...prev,
                    listMusic: newListMusic,
                    urlMusic: music?.name || t('file_name')
                };
            });
        }
    }, []);

    const _handleSwitch = (value) => {
        dispatchState((prev) => ({ ...prev, isEnable: value }));
    };

    const _handleDisableButton = (value = false) => {
        refButtonSave.current.setDisable(value);
    };

    const _handleUploadFileSuccess = ({ url }) => {
        dispatchState((prev) => ({
            ...prev,
            music_id: ID_CUSTOM,
            custom_music: url,
            listMusic: [{ id: ID_CUSTOM, name: 'Custom Music', url }, ...prev.listMusic]
        }));
        _handleDisableButton(false);
    };

    const _handleConfirmDelete = (e) => {
        e.preventDefault();
        e.stopPropagation();
        refDropdown.current._close();
        refConfirm.current.open('', t('desc_delete_this_music'), t('delete_music'));
    };

    const _handleDelete = () => {
        const musicDefault = musics[0];
        dispatchState((prev) => ({ ...prev, listMusic: musics, music_id: musicDefault.id, custom_music: '' }));
        refVoicemail.current.setGreeting({ url: musicDefault.url });
        _handleCloseConfirm;
    };

    const _handleCloseConfirm = () => {
        refConfirm.current.close();
    };

    const _handleSave = () => {
        const _handleSuccess = () => {
            refButtonSave.current.setLoading(false);
            onShowStatus({ message: t(`item_update_successfully`, { item: title }), success: true });
        };

        const _handleFail = (err) => {
            refButtonSave.current.setLoading(false);
            onShowStatus(err);
        };

        clientQuery(
            GET_DETAIL_VOIP_MUSIC,
            {
                method: 'PUT',
                data: {
                    keyword: keyMusic,
                    status: !!refSwitch.current.getIsActive() ? 1 : 0,
                    music_id: finalMusicId,
                    music: finalCustomMusic
                },
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderListMusic = () => {
        return finalListMusic.map(({ id, name, url }) => {
            const _handleSelect = () => {
                dispatchState((prev) => ({ ...prev, music_id: id, urlMusic: url }));
                refVoicemail.current.setGreeting({ url });
                refDropdown.current._close();
            };

            return (
                <li
                    key={id}
                    className={classNames('items flex-betweenitems', { active: id === finalMusicId })}
                    onClick={_handleSelect}
                >
                    <div className="flexcenter gap-4 item-name">
                        <IconFileType type={'audio/mpeg'} />
                        <p className="txt-ellipsis flex-1">{name}</p>
                    </div>
                    {id === ID_CUSTOM ? (
                        <a
                            className="btn-delete v2-btn-default --icon-sm --delete btn-modal"
                            onClick={_handleConfirmDelete}
                        >
                            <IconTrash />
                        </a>
                    ) : null}
                </li>
            );
        });
    };

    const _renderButtonDropdown = () => {
        const fileName = finalListMusic.find(({ id }) => id === finalMusicId)?.name || t('file_name');

        return (
            <Fragment>
                <div className="flexcenter gap-4 item-name">
                    <IconFileType type={'audio/mpeg'} />
                    <p className="txt-ellipsis flex-1">{fileName}</p>
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </Fragment>
        );
    };

    const _renderButtonConfirm = () => {
        return (
            <div className="footer-modal footer-hasbtn btn-close" onClick={_handleCloseConfirm}>
                <span className="v2-btn-default --transparent">{t('cancel')}</span>
                <div id className="v2-btn-main has-bg-red" tabIndex={0} onClick={_handleDelete}>
                    {t('delete')}
                </div>
            </div>
        );
    };

    return (
        <div className="box-voip bg-white">
            <div className="box-voip__rows flexcenter flex-wrap is-enabled">
                <p className="flex-1 fw-500">{title}</p>
                <SwitchCheckbox
                    id={keyMusic}
                    ref={refSwitch}
                    textChecked={t('enabled')}
                    textUnChecked={t('disabled')}
                    onChangeImmediately={_handleSwitch}
                    defaultChecked={!!status}
                />
                <p className="mt-2">{description}</p>
                <div className="detail-enable flex-column gap-8 w-100 mt-2">
                    <VOIPVoicemailDrop
                        ref={refVoicemail}
                        greeting={finalUrlMusic}
                        presignedType={VOIP_PRESIGNED_TYPE.MUSIC}
                        isDisable={isDisable}
                        tooltipDisable={t('list_music_maximum_5_file')}
                        isHideDelete
                        isUploadS3OnSuccess
                        onUploadFile={() => _handleDisableButton(true)}
                        onUploadSuccess={_handleUploadFileSuccess}
                    />
                    <DropdownPopper
                        ref={refDropdown}
                        wrapperClassName="v2-dropdown list-file-music"
                        wrapperListClass="v2-dropdown__menu scrolls"
                        buttonClassName="dropbtn items"
                        customButton={_renderButtonDropdown()}
                        isUseToggle
                    >
                        <ul>{_renderListMusic()}</ul>
                    </DropdownPopper>
                </div>
                <div className="d-flex justify-end flex-1 mt-2">
                    <ButtonSave ref={refButtonSave} onSave={_handleSave} />
                </div>
            </div>
            <GdConfirm ref={refConfirm} footer={_renderButtonConfirm()} onConfirm={_handleDelete} />
        </div>
    );
};

export default BoxMusic;
