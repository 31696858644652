import DateRangePicker from 'app/components/daterange';
import { END_DATE_OF_MONTH, KEY_REPORT_LOCAL_STORAGE, START_DATE_OF_MONTH, DATE_FORMAT } from 'app/const/App';
import { getDefaultParams } from 'app/const/report/ReportParams';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useReducer, useEffect, useRef } from 'react';
import moment from 'moment';
import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';

function ReportDateRangePicker({
    reportType = '',
    onSelect = () => {},
    wrapperClassName = '',
    customInputClassName = '',
    selectedStart = null,
    selectedEnd = null,
    isNotSetDefault = false,
    forceRender = false,
    listOptionsDateDefault,
    isAddMonthWhenOpen
}) {
    const refDatePicker = useRef(null);
    let defaultDate;

    if (isNotSetDefault) {
        defaultDate = {};
    } else {
        defaultDate = {
            start: moment(selectedStart).isValid() ? selectedStart : moment(START_DATE_OF_MONTH).format(DATE_FORMAT),
            end: moment(selectedEnd).isValid() ? selectedEnd : moment(END_DATE_OF_MONTH).format(DATE_FORMAT)
        };
    }

    const [state, dispatchState] = useReducer(reducer, defaultDate);
    const paramsLocal =
        getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType)) || getDefaultParams(reportType);

    useEffect(() => {
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), { ...paramsLocal });
        if (moment(paramsLocal.start).isValid() && moment(paramsLocal.end).isValid()) {
            dispatchState({ start: paramsLocal.start, end: paramsLocal.end });
        } else {
            setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), {
                ...(getDefaultParams(reportType) || {})
            });
        }
    }, []);

    useEffect(() => {
        refDatePicker.current?._closeDropdown();
        dispatchState({ start: paramsLocal.start, end: paramsLocal.end });
    }, [forceRender]);

    const handleSelectDate = (value) => {
        let newParamsLocal = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType));
        const start = moment(value.start).format(DATE_FORMAT);
        const end = moment(value.end).format(DATE_FORMAT);
        dispatchState({ start, end });
        newParamsLocal = { ...newParamsLocal, start, end };
        setLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', reportType), { ...newParamsLocal });
        onSelect(newParamsLocal);
    };

    const renderDatePicker = () => {
        let date = { startDate: state?.start || defaultDate.start, endDate: state?.end || defaultDate.end };
        const defaultParams = getDefaultParams(reportType) || {};

        if (!moment(date.startDate).isValid() || !moment(date.endDate).isValid())
            date = { startDate: defaultParams.start, endDate: defaultParams.end };

        return (
            <DateRangePicker
                ref={refDatePicker}
                i18Text="report"
                handleSelect={handleSelectDate}
                selectDefault={date}
                wrapperClassName={classNames(wrapperClassName, 'has-bg-blue')}
                customInputClassName={customInputClassName}
                reportType={reportType}
                listOptionsDateDefault={listOptionsDateDefault}
                isAddMonthWhenOpen={isAddMonthWhenOpen}
            />
        );
    };

    return renderDatePicker();
}

export default ReportDateRangePicker;
