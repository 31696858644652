import ButtonSave from 'app/components/button/ButtonSave';
import CustomerPhone from 'app/components/phoneLabel';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconClose from 'assets/icon/IconClose';
import IconTrash from 'assets/icon/IconTrash';
import { validateEmail } from 'common/utils/EmailUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

const AdditionalContactsModal = ({ onDelete = () => {}, onAdd = () => {}, onUpdate = () => {} }, ref) => {
    const { t } = useTranslation(['common, customer']);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, isUpdate: false, dataUpdate: {} });
    const { isUpdate, dataUpdate } = state;

    const refButtonSave = useRef(null);
    const refCustomerPhone = useRef(null);
    const refAlert = useRef(null);
    const refForm = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (dataUpdate) => {
        dispatchState({ isOpen: true, isUpdate: !!dataUpdate, dataUpdate: dataUpdate || {} });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleSubmit = () => {
        const result = {};
        const elements = refForm.current.elements;
        const phones = refCustomerPhone.current._getValue();

        /* Close alert when submit data */
        refAlert.current.closeStatusBar();

        if (refForm.current['email'].value.length && !validateEmail(refForm.current['email'].value)) {
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('common:email_is_not_a_valid_email_address'),
                type: LIST_STATUS.ERROR
            });

            refButtonSave.current.removeLoading();
            return;
        }

        if (!refForm.current['first_name'].value.trim().length) {
            /* Checking if the first name is empty. If it is empty, it will return. */
            refButtonSave.current.removeLoading();
            refAlert.current.showStatusBar({
                id: LIST_STATUS.ERROR,
                message: t('customers:first_name_cannot_be_blank'),
                type: LIST_STATUS.ERROR
            });
            return;
        }

        for (let index = 0; index < elements.length; index++) {
            const { type, name, value } = elements[index];
            if (type === 'text') result[name] = value;
            if (type === 'text' && name === 'title') result[name] = value.trim() || '';
        }

        result['phones'] = phones;

        _close();
        isUpdate ? onUpdate({ ...dataUpdate, ...result }) : onAdd({ ...result, id: `${new Date().getTime()}` });
    };

    /**
     * A function that is called when the delete button is clicked. It calls the onDelete function and
     * passes the id of the dataUpdate. It then closes the modal.
     */
    const _handleDelete = () => {
        onDelete(dataUpdate.id);
        _close();
    };

    if (!state.isOpen) return null;
    return (
        <ReactModal
            id="add_contact"
            isOpen={state.isOpen}
            portalClassName="ReactModalPortal_customer"
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal new-contact open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal">
                    <h3 className="header-modal__label">
                        {isUpdate ? t('customers:edit_contact') : t('customers:add_contact')}
                    </h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal has-form scrolls">
                    <AlertCustomer ref={refAlert} />
                    <form ref={refForm}>
                        <div className="rows row-haft --inmodal">
                            <div className="col">
                                <div className="txt">
                                    {t('customers:first_name')}&nbsp;<span className="red-default">*</span>
                                </div>
                                <input
                                    defaultValue={dataUpdate.first_name}
                                    className="field-input"
                                    type="text"
                                    placeholder={t('customers:first_name')}
                                    name="first_name"
                                    autoFocus
                                />
                            </div>
                            <div className="col">
                                <div className="txt">{t('customers:last_name')}</div>
                                <input
                                    defaultValue={dataUpdate.last_name}
                                    className="field-input"
                                    type="text"
                                    placeholder={t('customers:last_name')}
                                    name="last_name"
                                />
                            </div>
                        </div>
                        <div className="rows mt-3">
                            <div className="txt relative">{t('customers:title')}</div>
                            <input
                                defaultValue={dataUpdate.title || ''}
                                className="field-input"
                                type="text"
                                placeholder={t('customers:title')}
                                name="title"
                            />
                        </div>
                        <div className="rows mt-3">
                            <div className="txt relative">{t('customers:email')}</div>
                            <input
                                defaultValue={dataUpdate.email}
                                className="field-input"
                                type="text"
                                placeholder={t('customers:email')}
                                name="email"
                            />
                        </div>
                    </form>
                    <div className="rows mt-3">
                        <div className="phone-field d-flex">
                            <div className="col-phone">
                                <div className="txt">{t('customers:phone')}</div>
                            </div>
                            <div className="col-phonetype">
                                <div className="txt">{t('customers:phone_type')}</div>
                            </div>
                        </div>
                        <div className="rows__field field-has-phone has-many-field has-new-field">
                            <CustomerPhone
                                ref={refCustomerPhone}
                                defaultSelected={dataUpdate.phones}
                                isGetAllType
                                limit={2}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-modal">
                    {isUpdate && (
                        <div className="v2-btn-default --delete has-icon" onClick={_handleDelete} tabIndex="0">
                            <IconTrash /> {t('customers:delete')}
                        </div>
                    )}
                    <div className="flexcenter justify-end flex-1">
                        <div className="v2-btn-default --transparent" tabIndex="0" onClick={_close}>
                            {t('customers:cancel')}
                        </div>
                        <ButtonSave
                            ref={refButtonSave}
                            className="v2-btn-main ml-2"
                            isNotLoading={!isUpdate}
                            onSave={_handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(AdditionalContactsModal);
