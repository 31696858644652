import React, { createContext, useEffect, useReducer, useRef } from 'react';
import { validateEmail } from 'common/utils/EmailUtils';
import { reducer } from 'app/const/Reducer';

export const UnitDetailContext = createContext();

const UnitDetailContextProvider = ({ manageUnitData, children, idButtonSave, finalUnitSelectedId }) => {
    const [state, dispatchState] = useReducer(reducer, {
        ...manageUnitData,
        refreshUinitDetail: 0
    });

    const isFirstTime = useRef(true);

    useEffect(() => {
        const elm = document.getElementById(idButtonSave || 'button_save_detail_unit');

        const checkIsEmail = state.email.length > 0 ? validateEmail(state.email) : true;

        const isCheck =
            state.buildingName.length !== 0 &&
            state.unitName.length !== 0 &&
            checkIsEmail &&
            state.isCheckunit &&
            state.isCheckBilding;

        if (elm) {
            isCheck ? elm.classList.remove('is-disable') : elm.classList.add('is-disable');
        }
    }, [state]);

    useEffect(() => {
        if (!isFirstTime.current) {
            dispatchState({ ...manageUnitData });
        }

        isFirstTime.current = false;

        return () => {
            const elm = document.getElementById(idButtonSave || 'button_save_detail_unit');
            elm && elm.classList.add('is-disable');
        };
    }, [finalUnitSelectedId]);

    function _handleUpdateUnitDetailContext(newData) {
        dispatchState(newData);
    }

    const unitDetailContextData = {
        unitDetailContextData: state,
        updateUnitDetailContextData: _handleUpdateUnitDetailContext
    };

    return <UnitDetailContext.Provider value={unitDetailContextData}>{children}</UnitDetailContext.Provider>;
};

export default UnitDetailContextProvider;
