import React from 'react';

const IconJobStatus = ({ isGrey = false }) => {
    if (isGrey)
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="4" fill="#1C1F22" fillOpacity="0.06"></rect>
                <path
                    opacity="0.5"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13H20C21.1046 13 22 12.1046 22 11C22 9.89543 21.1046 9 20 9H12ZM12 19C10.8954 19 10 19.8954 10 21C10 22.1046 10.8954 23 12 23H20C21.1046 23 22 22.1046 22 21C22 19.8954 21.1046 19 20 19H12Z"
                    fill="#494C4E"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.5 11C22.5 10.7239 22.7239 10.5 23 10.5C24.107 10.5 24.9857 10.8481 25.592 11.4165C26.1957 11.9825 26.5 12.7408 26.5 13.5C26.5 14.2592 26.1957 15.0175 25.592 15.5835C24.9857 16.1519 24.107 16.5 23 16.5C22.7239 16.5 22.5 16.2761 22.5 16C22.5 15.7239 22.7239 15.5 23 15.5C23.893 15.5 24.5143 15.2231 24.908 14.854C25.3043 14.4825 25.5 13.9908 25.5 13.5C25.5 13.0092 25.3043 12.5175 24.908 12.146C24.5143 11.7769 23.893 11.5 23 11.5C22.7239 11.5 22.5 11.2761 22.5 11ZM10 16C10 14.8954 10.8954 14 12 14H20C21.1046 14 22 14.8954 22 16C22 17.1046 21.1046 18 20 18H12C10.8954 18 10 17.1046 10 16ZM9 15.5C9.27614 15.5 9.5 15.7239 9.5 16C9.5 16.2761 9.27614 16.5 9 16.5C8.10704 16.5 7.48568 16.7769 7.09197 17.146C6.69571 17.5175 6.5 18.0092 6.5 18.5C6.5 18.9908 6.69571 19.4825 7.09197 19.854C7.48568 20.2231 8.10704 20.5 9 20.5C9.27614 20.5 9.5 20.7239 9.5 21C9.5 21.2761 9.27614 21.5 9 21.5C7.89296 21.5 7.01432 21.1519 6.40803 20.5835C5.80429 20.0175 5.5 19.2592 5.5 18.5C5.5 17.7408 5.80429 16.9825 6.40803 16.4165C7.01432 15.8481 7.89296 15.5 9 15.5Z"
                    fill="#494C4E"
                ></path>
            </svg>
        );

    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FFF"
            ></path>
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13H20C21.1046 13 22 12.1046 22 11C22 9.89543 21.1046 9 20 9H12ZM12 19C10.8954 19 10 19.8954 10 21C10 22.1046 10.8954 23 12 23H20C21.1046 23 22 22.1046 22 21C22 19.8954 21.1046 19 20 19H12Z"
                fill="#1EAA5C"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.5 11C22.5 10.7239 22.7239 10.5 23 10.5C24.107 10.5 24.9857 10.8481 25.592 11.4165C26.1957 11.9825 26.5 12.7408 26.5 13.5C26.5 14.2592 26.1957 15.0175 25.592 15.5835C24.9857 16.1519 24.107 16.5 23 16.5C22.7239 16.5 22.5 16.2761 22.5 16C22.5 15.7239 22.7239 15.5 23 15.5C23.893 15.5 24.5143 15.2231 24.908 14.854C25.3043 14.4825 25.5 13.9908 25.5 13.5C25.5 13.0092 25.3043 12.5175 24.908 12.146C24.5143 11.7769 23.893 11.5 23 11.5C22.7239 11.5 22.5 11.2761 22.5 11ZM10 16C10 14.8954 10.8954 14 12 14H20C21.1046 14 22 14.8954 22 16C22 17.1046 21.1046 18 20 18H12C10.8954 18 10 17.1046 10 16ZM9 15.5C9.27614 15.5 9.5 15.7239 9.5 16C9.5 16.2761 9.27614 16.5 9 16.5C8.10704 16.5 7.48568 16.7769 7.09197 17.146C6.69571 17.5175 6.5 18.0092 6.5 18.5C6.5 18.9908 6.69571 19.4825 7.09197 19.854C7.48568 20.2231 8.10704 20.5 9 20.5C9.27614 20.5 9.5 20.7239 9.5 21C9.5 21.2761 9.27614 21.5 9 21.5C7.89296 21.5 7.01432 21.1519 6.40803 20.5835C5.80429 20.0175 5.5 19.2592 5.5 18.5C5.5 17.7408 5.80429 16.9825 6.40803 16.4165C7.01432 15.8481 7.89296 15.5 9 15.5Z"
                fill="#1EAA5C"
            ></path>
        </svg>
    );
};

export default IconJobStatus;
