import { getUrlCustomerSubscription } from 'app/const/Api';
import { COMMON, TODAY } from 'app/const/App';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { createContext, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { roundingMoney } from 'common/utils/NumberUtils';
import moment from 'moment';
import { reducer } from 'app/const/Reducer';
import { PAYMENT_CARD_CUSTOMER } from 'app/const/Customers';
import { convertTimeToISO } from 'common/utils/DateUtils';

export const SubscriptionContext = createContext();

export const initialStateSubscription = {
    selectedPlans: [],
    errors: [],
    typePlanSelect: { currency: 'USD', interval: 'day', interval_count: null, symbol: '$' },
    isLoadingAdd: false,
    isCustomInterval: false,
    isEditing: false,
    tax: null,
    price: 0,
    trial_end: TODAY,
    end_date: null,
    billingNumber: 30,
    billing: 0
};

const SubscriptionProvider = ({
    children,
    defaultCardId = '',
    typeSubscription = PAYMENT_CARD_CUSTOMER.STRIPE,
    onAddSuccess = () => {},
    onEditSuccess = () => {}
}) => {
    const { t } = useTranslation('customers');
    const { id } = useParams();
    const initState = {
        ...initialStateSubscription,
        cardId: defaultCardId,
        typeSubscription,
        isSquare: typeSubscription === PAYMENT_CARD_CUSTOMER.SQUARE
    };
    const [state, dispatchState] = useReducer(reducer, initState);
    const customer_currency = useSelector(({ auth }) => auth.user.company.currency);
    const {
        cardId,
        isSquare,
        selectedPlans,
        typePlanSelect,
        isEditing,
        customerId,
        end_date,
        trial_end,
        billingNumber
    } = state;
    const idSubScription = customerId || id;

    const _handleSelectPlan = (plan) => {
        const newSelectedPlans = [...selectedPlans, { ...plan, qty: 1 }];
        dispatchState({
            selectedPlans: newSelectedPlans,
            typePlanSelect: {
                currency: plan.currency,
                interval: plan.interval,
                interval_count: plan.interval_count,
                symbol: plan.symbol
            },
            isCustomInterval: plan.interval_count !== 1,
            price: _handleCalculateBill(newSelectedPlans)
        });
    };

    const _handleDeletePlan = (id) => {
        let newSelectedPlan = [];

        if (isEditing && !isSquare) {
            newSelectedPlan = [...selectedPlans].map((item) => {
                if (item.id === id) return { ...item, deleted: 1 };
                return item;
            });
        } else {
            newSelectedPlan = [...selectedPlans].filter((item) => item.id !== id);
        }

        const newState = { selectedPlans: newSelectedPlan };
        if (!newSelectedPlan.length) newState['isCustomInterval'] = false;
        dispatchState({
            ...newState,
            price: _handleCalculateBill(newSelectedPlan)
        });
    };

    const _handleRestorePlan = (id) => {
        setTimeout(() => {
            dispatchState((prevState) => {
                const result = [...(prevState.selectedPlans || [])].map((item) => {
                    if (`${item.id}` === `${id}`) return { ...item, deleted: 0 };
                    return { ...item, deleted: 0 };
                });

                return {
                    ...prevState,
                    selectedPlans: result,
                    price: _handleCalculateBill(result)
                };
            });
        }, 0);
    };

    const _handleEditPlan = (id, newData) => {
        const newSelectedPlans = [...selectedPlans].map((item) => {
            if (item.id === id) return { ...item, ...newData };
            return item;
        });
        dispatchState({ selectedPlans: newSelectedPlans, price: _handleCalculateBill(newSelectedPlans) });
    };

    const _handleEditTax = (tax) => {
        dispatchState({ tax });
    };

    const _handleChangeDate = (date, type) => {
        switch (type) {
            case COMMON.START:
                dispatchState({ trial_end: date ? convertTimeToISO(moment(date).startOf('day')) : null });
                break;
            case COMMON.END:
                dispatchState({ end_date: date ? convertTimeToISO(moment(date).endOf('day')) : null });
                break;
            default:
                break;
        }
    };

    const _handleCalculateBill = (selectedPlans) => {
        const totalBill = selectedPlans.reduce((acc, current) => {
            return acc + current.amount * current.qty;
        }, 0);

        return `${typePlanSelect.symbol}${roundingMoney(totalBill)}`;
    };

    const _handleChangeBilling = (data) => {
        dispatchState({ billing: data.isAutomatically ? 0 : 1 });
    };

    const _handleChangeCard = (cardId) => {
        dispatchState({ cardId });
    };

    const _handleChangeBillingNumber = (number) => {
        dispatchState({ billingNumber: number });
    };

    const _handleSetContext = (data) => {
        dispatchState({ ...data, price: _handleCalculateBill(data.selectedPlans) });
    };

    const _handleSubmitUpdate = (cardId) => {
        dispatchState({ isLoadingAdd: true });
        const dataSubmit = {
            subscription: {
                id: state.subscription_id,
                plan: selectedPlans.map((item) => ({
                    deleted: item.deleted,
                    id: item.id,
                    name: item.name,
                    amount: item.amount,
                    qty: item.qty,
                    item_id: item.item_id
                })),
                tax: state.tax,
                end_date: end_date,
                billing: state.billing,
                billing_number: billingNumber,
                customer_currency,
                trial_end,
                ...state.typePlanSelect
            },
            merchant: isSquare ? 2 : 1
        };
        if (isSquare) dataSubmit['subscription']['card_id'] = cardId;
        clientQuery(
            getUrlCustomerSubscription(idSubScription),
            {
                data: dataSubmit,
                method: 'PUT',
                toFormData: false
            },
            ({ data, message }) => {
                dispatchState({ ...initState, isLoadingAdd: false });
                onEditSuccess(data, message, selectedPlans);
            },
            ({ message }) => {
                dispatchState({ isLoadingAdd: false, errors: message });
            }
        );
    };

    const _handleSubmitAdd = () => {
        const errors = [];
        dispatchState({ isLoadingAdd: true });

        if (typePlanSelect.currency !== customer_currency)
            return dispatchState({
                errors: [t('error_add_subscription_currency', { currency: customer_currency })],
                isLoadingAdd: false
            });
        if (!selectedPlans.length) errors.push('Oops! No plans added.');
        if (errors.length) return dispatchState({ errors, isLoadingAdd: false });
        const dataSubmit = {
            subscription: {
                plan: selectedPlans.map((item) => ({
                    deleted: 0,
                    id: item.id,
                    name: item.name,
                    amount: item.amount,
                    qty: item.qty
                })),
                tax: state.tax,
                trial_end: trial_end,
                end_date: end_date,
                billing: state.billing,
                billing_number: billingNumber,
                customer_currency,
                ...state.typePlanSelect
            },
            merchant: isSquare ? 2 : 1
        };
        if (isSquare) dataSubmit['subscription']['card_id'] = cardId;

        clientQuery(
            getUrlCustomerSubscription(idSubScription),
            { data: dataSubmit, method: 'POST', toFormData: false },
            ({ data, message }) => onAddSuccess(data, message),
            ({ message }) => dispatchState({ isLoadingAdd: false, errors: message })
        );
    };

    const contextValue = {
        ...state,
        _handleSelectPlan,
        _handleDeletePlan,
        _handleEditPlan,
        _handleEditTax,
        _handleChangeDate,
        _handleSubmitAdd,
        _handleChangeBilling,
        _handleChangeCard,
        _handleChangeBillingNumber,
        _handleSetContext,
        _handleSubmitUpdate,
        _handleRestorePlan
    };

    return <SubscriptionContext.Provider value={contextValue}>{children}</SubscriptionContext.Provider>;
};

export default SubscriptionProvider;
