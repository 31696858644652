import React from 'react';

const IconWhisper = () => {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.6501C9.13564 4.6501 6.64998 7.39548 6.64998 11.0001V16.0001C6.64998 16.0504 6.64415 16.1 6.63294 16.148L7.0767 17.0355C7.25462 17.3914 7.56659 17.6619 7.944 17.7877L9.50212 18.3071C9.76399 17.742 10.3362 17.3501 11 17.3501H13C13.9112 17.3501 14.65 18.0888 14.65 19.0001C14.65 19.9114 13.9112 20.6501 13 20.6501H11C10.1985 20.6501 9.53042 20.0786 9.38113 19.3209L7.62778 18.7364C6.99876 18.5267 6.4788 18.0758 6.18228 17.4827L5.74025 16.5987L3.4782 15.8447C2.80444 15.6201 2.34998 14.9895 2.34998 14.2793V11.7209C2.34998 11.0106 2.80444 10.3801 3.4782 10.1555L5.48111 9.48789C6.08463 6.04791 8.70679 3.3501 12 3.3501C15.2932 3.3501 17.9153 6.04791 18.5188 9.48789L20.5217 10.1555C21.1955 10.3801 21.65 11.0106 21.65 11.7209V14.2793C21.65 14.9895 21.1955 15.6201 20.5217 15.8447L18.2055 16.6167C18.0073 16.6828 17.7894 16.6496 17.6199 16.5274C17.4504 16.4052 17.35 16.209 17.35 16.0001V10.9946C17.3475 7.3927 14.8629 4.6501 12 4.6501ZM18.65 11.0017V15.0983L20.1107 14.6114C20.2536 14.5637 20.35 14.43 20.35 14.2793V11.7209C20.35 11.5702 20.2536 11.4365 20.1107 11.3888L18.65 10.9019V10.9943L18.65 11.0001L18.65 11.0017ZM5.34998 10.9019V11.0001V15.0983L3.8893 14.6114C3.74638 14.5637 3.64998 14.43 3.64998 14.2793V11.7209C3.64998 11.5702 3.74638 11.4365 3.8893 11.3888L5.34998 10.9019ZM10.65 19.0001C10.65 18.8068 10.8067 18.6501 11 18.6501H13C13.1933 18.6501 13.35 18.8068 13.35 19.0001C13.35 19.1934 13.1933 19.3501 13 19.3501H11C10.8067 19.3501 10.65 19.1934 10.65 19.0001Z"
                fill="#4C4C4C"
            />
        </svg>
    );
};

export default IconWhisper;
