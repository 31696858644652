import React from 'react';

export default function TabHistorySync({ data }) {
    const historyStatus = data?.status;

    function _renderListData() {
        return [...(data?.log || [])].map((item, index) => {
            return (
                <p key={index.toString()} className="screen-sync__history">
                    {item}
                </p>
            );
        });
    }

    if (data) {
        return (
            <div className="tab-content-active">
                <div className="rows">
                    <span className="mr-1 txt-ellipsis rows__status">Last synchronization status:</span>
                    {!!historyStatus && (
                        <div className={`status-btn --sm mr-1 ${historyStatus.toLowerCase()}`}>{historyStatus}</div>
                    )}
                    {data.write_datetime || ''}
                </div>
                <div className="rows">Synchronization log:</div>
                <div className="screen-sync">{_renderListData()}</div>
            </div>
        );
    }
    return null;
}
