import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ButtonSave from 'app/components/button/ButtonSave';
import { actionsVoipVoicemailDrop } from 'app/const/api/Voip';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import { clientQuery } from 'common/utils/ApiUtils';
import VOIPVoicemailDrop from '../VOIPVoicemailDrop';
import { messageActionsAlert } from 'common/utils/StringUtils';
import { LIST_ACTION_MESSAGE } from 'app/modules/addons/constants';

const ItemVoicemailDrop = ({ data = {}, onSaveSuccess = {}, onDeleteItem = () => {}, onShowStatus = () => {} }) => {
    const { t } = useTranslation('addons');
    const { id: finalId, label = '', voicemail = '', isAddNew = false } = data || {};

    const refDropdown = useRef(null);
    const refVoicemail = useRef(null);
    const refData = useRef({ label, voicemail });
    const refButtonSave = useRef(null);
    const refIsUploading = useRef(false);

    const [state, dispatchState] = useReducer(reducer, {
        isEdit: isAddNew,
        isDisableActions: false,
        requireVoicemail: false
    });
    const { isEdit, isDisableActions, requireVoicemail } = state;

    const _handleSave = () => {
        const finalData = refData.current;
        if (!finalData.voicemail) {
            !requireVoicemail && dispatchState({ requireVoicemail: true });
            onShowStatus({ message: t('voicemail_is_required'), success: false });
            refButtonSave.current?.removeLoading();
            return;
        }

        const _handleSuccess = ({ message }) => {
            onShowStatus({
                message: messageActionsAlert({
                    message,
                    type: t('voicemail_drop'),
                    action: isAddNew ? LIST_ACTION_MESSAGE.CREATED : LIST_ACTION_MESSAGE.UPDATED
                }),
                success: true
            });
            _handleVisibleEdit(false);
            onSaveSuccess({ ...finalData, id: finalId });
        };

        const _handleFail = (err) => {
            refButtonSave.current?.removeLoading();
            onShowStatus(err);
        };

        clientQuery(
            actionsVoipVoicemailDrop(finalId),
            {
                method: 'PUT',
                data: finalData,
                toFormData: false
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderLabel = () => {
        if (!isEdit) return <p className="">{label}</p>;
        return (
            <input
                className="field-input"
                type="text"
                placeholder={t('voicemail_label')}
                defaultValue={label}
                autoFocus
                onChange={(e) => (refData.current.label = e.target.value)}
            />
        );
    };

    const _renderOptions = () => {
        if (isEdit) return null;
        return (
            <ul>
                <li className="items has-icon" onClick={() => _handleVisibleEdit(true)}>
                    <IconEdit />
                    <span className="txt-ellipsis">{t('edit')}</span>
                </li>
                <li className="items has-icon red-default btn-modal" onClick={_handleDeleteItem}>
                    <IconTrash />
                    <div className="txt-ellipsis red-delete">{t('delete')}</div>
                </li>
            </ul>
        );
    };

    const _handleVisibleEdit = (value = false) => {
        if (!value && refIsUploading.current) {
            refVoicemail.current.setGreeting(voicemail);
        } else {
            refData.current = { label, voicemail };
        }
        dispatchState({ isEdit: !!value });
    };

    const _handleDeleteItem = () => {
        onDeleteItem({ id: finalId, isAddNew });
    };

    const _handleCancelEdit = () => {
        isAddNew ? _handleDeleteItem() : _handleVisibleEdit(false);
    };

    const _handleDisableActions = (value = false) => {
        dispatchState({ isDisableActions: value });
    };

    const _handleUploadSuccess = ({ url = '' }) => {
        refData.current.voicemail = url;
        refIsUploading.current = false;
        _handleDisableActions(false);
    };

    const _handleUploadFile = () => {
        _handleDisableActions(true);
        refIsUploading.current = true;
    };

    const _renderFooter = () => {
        if (!isEdit) return null;
        return (
            <div
                className={classNames('flex-betweenitems flex-1 mt-2', {
                    'justify-end ': isAddNew,
                    'is-disable': isDisableActions
                })}
            >
                {!isAddNew ? (
                    <div className="v2-btn-default has-icon has-bg-red" onClick={_handleDeleteItem}>
                        <IconTrash />
                        {t('delete')}
                    </div>
                ) : null}
                <div className="d-flex gap-8">
                    <div className="v2-btn-default --transparent" onClick={_handleCancelEdit}>
                        {t('cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} className="v2-btn-main" onSave={_handleSave}>
                        {t('save')}
                    </ButtonSave>
                </div>
            </div>
        );
    };

    return (
        <div className="box-voip bg-white">
            <div className="box-voip__rows flexcenter flex-wrap is-enabled">
                <div className="flexcenter gap-4 w-100 mb-2">
                    <p className="txt-voicemail flex-1 fw-600">{t('voicemail_drop')}</p>
                    {!isEdit ? (
                        <CalendarDropdown
                            ref={refDropdown}
                            wrapperClassName="v2-dropdown d-flex"
                            buttonClassName="dropbtn v2-btn-default --icon-lg"
                            wrapperListClass="v2-dropdown__menu --right content-full scrolls"
                            customDropButton={() => <IconThreeDots />}
                        >
                            {_renderOptions()}
                        </CalendarDropdown>
                    ) : null}
                </div>
                {_renderLabel()}
                <VOIPVoicemailDrop
                    ref={refVoicemail}
                    greeting={voicemail}
                    isHideActions={!isEdit}
                    wrapperClassName="detail-enable flex-column gap-8 w-100 mt-2"
                    isHideDelete
                    onUploadFile={_handleUploadFile}
                    onUploadSuccess={_handleUploadSuccess}
                    required={requireVoicemail}
                />
                {_renderFooter()}
            </div>
        </div>
    );
};

export default ItemVoicemailDrop;
