// Internal
//// auth
export const AUTH = '/auth';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_PASSWORD_FORGOT = '/auth/password/forgot';
export const AUTH_PASSWORD_RESET = '/password/reset';
export const AUTH_SIGN_UP = '/auth/signup';
export const AUTH_SIGN_UP_SOCIAL = '/signup/social';
export const AUTH_SIGN_UP_ACTIVATE = '/signup/activate';
export const AUTH_LOGIN_BY_TOKEN = '/admin/auth/:type/token/:token';
export const AUTH_CONFIRM_TOKEN = '/auth/social';
export const SIGN_UP_SUCCESS = '/auth/signup/trial-success';
export const ACTIVE_FIRST_TIME_SUCCESS = '/account/plans/activation';

//// home
export const HOME = '/';
export const DASHBOARD = '/dashboard';

/// commission
export const COMMISSION = '/commissions';

//// calendar
export const CALENDAR = '/calendar';

//// inbox
export const INBOX = '/inbox';

//// reports
export const REPORT = '/reports';
export const REPORT_INVOICES = '/reports/invoices';
export const REPORT_RATING = '/reviews';
export const LEADS = '/reports/leads';
export const REFERRAL = '/referral';

//// settings
export const SETTINGS = '/settings';
export const SETTINGS_ADDON = '/settings/addons';
export const SETTINGS_PLANS = '/settings/plans';
export const SETTINGS_COMPANY = '/settings/company';
export const SETTINGS_ACCOUNT = '/settings/account';
export const SETTINGS_INVOICES = '/settings/invoices';

// customer
export const CUSTOMERS = '/customers';
export const CUSTOMERS_ACCOUNT = '/customers/account';

// addon
export const ADD_ONS = '/addons';

//// drip
export const DRIP_CAMPAIGN = '/drip/campaign';

// External
export const GORILLA_DESK_TYPEFORM_LINK = 'https://gorilladesk1.typeform.com/feature-request';
export const GORILLA_DESK_ACADEMY_LINK = 'https://gorilladesk.com/academy/';
export const GORILLA_DESK_GETTING_STARTED_LINK = 'https://gorilladesk.com/academy/getting-started/';
export const GORILLA_DESK_TRAINING_LINK = 'https://gorilladesk.com/academy/gorilladesk-training/';
export const GORILLA_DESK_INTERCOM_HELP_LINK = 'https://intercom.help/gorilladesk/en/';
export const GORILLA_DESK_YOUTUBE_LINK = 'https://www.youtube.com/channel/UCGV3z2MuPSvRtV9xzbNaqvg/playlists';
export const GORILLA_DESK_GROUP_FACEBOOK_LINK = 'https://www.facebook.com/groups/gorilladeskforum';
export const GORILLA_DESK_COACHING_CALL_LINK = 'https://gorilladesk.com/academy/getting-started/coaching-call/';
export const GORILLA_DESK_TRAINING_CALL_LINK = 'https://calendly.com/gorilladesk-support/training-call/';
export const GORILLA_DESK_MY_SUBSCRIPTION = 'https://gorilladesk.com/my-subscription/';
// eslint-disable-next-line no-undef
export const GORILLA_CURRENT_DESKTOP = process.env.REACT_APP_GORILLA_CURRENT_DESKTOP;
