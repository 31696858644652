import React, { Fragment, Suspense, useContext } from 'react';
import { useSelector } from 'react-redux';

import { CustomerDetailContext } from '../context/CustomerDetailContext';
import CustomerContact from './CustomerContact';
import CustomerInfo from './CustomerInfo';
import CustomerLocation from './CustomerLocation';
import AccountPayment from './components/AccountPayment';
import LoadingDefault from './components/Loading';

const CustomerCustomFields = React.lazy(() => import('./CustomerCustomFields'));
const CustomerAccount = () => {
    const { isLoading } = useContext(CustomerDetailContext);
    const customFieldAddon = useSelector(({ auth }) => auth.user.settings.addons.custom_fields);

    return (
        <Fragment>
            <div className="wrapper-columns wrapper-profile scrolls">
                <div className={'contents-pages wrapper-box-edit'}>
                    {isLoading ? (
                        <LoadingDefault />
                    ) : (
                        <div className="wrapper-box-edit__inner">
                            <CustomerInfo />
                            <Suspense fallback={null}>{customFieldAddon ? <CustomerCustomFields /> : null}</Suspense>
                            <AccountPayment />
                            <CustomerContact />
                            <CustomerLocation />
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default CustomerAccount;
