import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDStatusBar from 'app/components/status/statusbar';
import { REPORT_TYPE } from 'app/const/Reports';
import { CHATBOT_CUSTOM_ANSWER } from 'app/const/api/V2';
import { CHATBOT_CUSTOM_ANSWER_FILTER } from 'app/const/report/ReportFilter';
import IconPlus from 'assets/icon/IconPlus';
import { showStatusBar } from 'common/utils/FunctionUtils';
import ModalAddCustomAnswer from '../components/ModalAddCustomAnswer';
import TableChatbot from '../components/TableChatbot';
import { gridColumnCustomAnswer } from '../constants/gridColumnCustomAnswer';
import { DATA_WARNING_TYPE } from '../constants';

const ChatbotCustomAnswer = () => {
    const { t } = useTranslation('addons');

    const typeReport = REPORT_TYPE.CHATBOT_CUSTOM_ANSWER;

    const refAlert = useRef(null);
    const refTable = useRef(null);
    const refModalAddCustomAnswer = useRef(null);

    const _handleGetData = () => {
        refTable.current?.getData();
    };

    const _handleOpenCustomAnswerModal = ({ id = null }) => {
        refModalAddCustomAnswer.current?._open({ id });
    };

    const _handleClickRow = ({ row = {} }) => {
        _handleOpenCustomAnswerModal({ id: row?.id });
    };

    const _handleUpdateData = (info = {}) => {
        refTable.current?.updateDataByType(info);
    };

    const _handleShowAlert = (alert = {}) => {
        showStatusBar({ id: 'chat_bot_custom_answer', ...alert, refAlert });
    };

    return (
        <div className="wrapper-chatbot flex-column">
            <div className="flex-betweenitems">
                <h3 className="wrapper-chatbot__title fs-600 fs-18 black">{t('custom_answer')}</h3>
                <div className="v2-btn-main has-icon svg-white" onClick={_handleOpenCustomAnswerModal}>
                    <IconPlus />
                    {t('add_answer')}
                </div>
            </div>
            <GDStatusBar ref={refAlert} />
            <div className="container-print container-wrap">
                <TableChatbot
                    ref={refTable}
                    reportType={typeReport}
                    endpoint={CHATBOT_CUSTOM_ANSWER}
                    gridColumnReport={gridColumnCustomAnswer}
                    filters={CHATBOT_CUSTOM_ANSWER_FILTER}
                    batchActionKey={DATA_WARNING_TYPE.CUSTOM_ANSWER}
                    onClickRow={_handleClickRow}
                    onGetData={_handleGetData}
                    onShowAlert={_handleShowAlert}
                />
            </div>
            <ModalAddCustomAnswer ref={refModalAddCustomAnswer} onUpdateData={_handleUpdateData} />
        </div>
    );
};
export default ChatbotCustomAnswer;
