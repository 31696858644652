import { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';

const ServiceCustomer = forwardRef(({ onTrigger = () => {} }, ref) => {
    const { location_ids, reloadCustomerPage } = useContext(CustomerDetailContext);

    useImperativeHandle(ref, () => ({
        getLocationIds: location_ids
    }));

    useEffect(() => {
        onTrigger(location_ids);
    }, [location_ids]);

    useEffect(() => {
        if (reloadCustomerPage) onTrigger(location_ids, reloadCustomerPage);
    }, [reloadCustomerPage]);

    return null;
});

export default ServiceCustomer;
