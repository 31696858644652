import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import IconClose from 'assets/icon/IconClose';
import BoxControl from 'app/modules/settings/templatesManager/components/boxControl';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { CREATE_TEMPLATE } from 'app/const/api/Todo';

import { useTranslation } from 'react-i18next';
import { useEnterKeydownClick } from 'common/hooks';

const SaveAsTemplate = forwardRef((props, ref) => {
    const { t } = useTranslation(['jobDetail']);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, templateId: 0, items: [] });
    const { isOpen, templateId, items } = state;
    const refBoxControl = useRef(null);
    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refDescription = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    useEnterKeydownClick(isOpen);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                _setDisableSave(true);
            }, 0);
        }
    }, [isOpen]);

    const _open = (newItems) => {
        dispatchState({
            isOpen: true,
            items: newItems
        });
    };

    const _close = () => dispatchState({ isOpen: false });

    const _setDisableSave = (disabled) => {
        refButtonSave.current.setDisable(disabled);
    };

    const _changeTemplateName = (e) => {
        _setDisableSave(!!!e.target.value);
    };

    const _removeSaveLoading = () => refButtonSave.current.removeLoading();

    const _showStatusBar = (id, message, status) => {
        refStatusBar.current.showStatusBar(id, message, status);
    };

    const _addNewTemplate = (items) => {
        const _success = () => {
            _removeSaveLoading();
            _showStatusBar('show_success', 'Create success', LIST_STATUS.SUCCESS);
            refDescription.current.value = '';
            refButtonSave.current.setDisable(true);
            dispatchState({
                templateId: Date.now(),
                items: []
            });
        };

        const _fail = () => {
            _removeSaveLoading();
            _showStatusBar('show_error', 'Create failed', LIST_STATUS.ERROR);
        };

        clientQuery(
            CREATE_TEMPLATE,
            {
                method: 'POST',
                data: {
                    name: refDescription.current.value,
                    todos: items.map((item) => {
                        return { description: item.description };
                    })
                }
            },
            _success,
            _fail
        );
    };

    const _saveTemplate = () => {
        const newItems = refBoxControl.current._getFinalItems();
        if (!newItems.length) {
            _showStatusBar('show_error', 'Need one more item', LIST_STATUS.ERROR);
            _removeSaveLoading();
            return;
        }
        _addNewTemplate(newItems);
    };

    if (!isOpen) {
        return false;
    }

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-list-template open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container x-lg wrapper-template">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('jobDetail:todo_list_templates')}</h3>
                    <div onClick={_close} className="v2-btn-default --icon-lg --transparent">
                        <IconClose />
                    </div>
                </div>
                <StatusBar ref={refStatusBar} />
                <div className="name-templates">
                    <input
                        ref={refDescription}
                        className="field-input no-effect border-none"
                        defaultValue={''}
                        type="text"
                        placeholder={t('jobDetail:todo_list_template_name')}
                        onChange={_changeTemplateName}
                    />
                </div>
                <div className="body-modal border-none p-0 scrolls">
                    <BoxControl items={items} templateId={templateId} ref={refBoxControl} />
                </div>
                <div className="footer-modal shadow-top-brown">
                    <ButtonSave
                        ref={refButtonSave}
                        wrapClass="v2-btn-main"
                        title={t('jobDetail:save_template')}
                        onSave={_saveTemplate}
                        tabIndex="0"
                    />
                </div>
            </div>
        </ReactModal>
    );
});

export default SaveAsTemplate;
