export default function IconThreeDots({ isDouble = false, isHorizontal = false, ...props }) {
    if (isDouble)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10 8C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6C9.44772 6 9 6.44772 9 7C9 7.55228 9.44772 8 10 8ZM10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13ZM11 17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17C9 16.4477 9.44772 16 10 16C10.5523 16 11 16.4477 11 17ZM14 13C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13ZM15 7C15 7.55228 14.5523 8 14 8C13.4477 8 13 7.55228 13 7C13 6.44772 13.4477 6 14 6C14.5523 6 15 6.44772 15 7ZM14 18C14.5523 18 15 17.5523 15 17C15 16.4477 14.5523 16 14 16C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18Z"
                    fill="#C1C9D4"
                ></path>
            </svg>
        );
    if (isHorizontal)
        return (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx={9} cy={9} r="1.5" fill="#4C4C4C" />
                <circle cx={3} cy={9} r="1.5" fill="#4C4C4C" />
                <circle cx={15} cy={9} r="1.5" fill="#4C4C4C" />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx={12} cy={12} r="1.5" fill="#4D5054" />
            <circle cx={12} cy={6} r="1.5" fill="#4D5054" />
            <circle cx={12} cy={18} r="1.5" fill="#4D5054" />
        </svg>
    );
}
