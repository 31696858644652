import {
    CUSTOMER_ADD_OPTION_NEW_JOB,
    CUSTOMER_ADD_OPTION_OPEN_CUSTOMER,
    CUSTOMER_ADD_OPTION_VIEW_MAP
} from 'app/const/customer/CustomerAdd';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconThreeDots from 'assets/icon/IconThreeDots';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ButtonSaveWithOption = ({ onSave = () => {} }, ref) => {
    const { t } = useTranslation(['customers']);
    const refButton = useRef(null);
    const refDropdown = useRef(null);

    useImperativeHandle(ref, () => ({
        setLoading: (value) => refButton.current.setLoading(value),
        closeDropdown: () => refDropdown.current._closeDropdown()
    }));

    const _handleSelectOption = (option) => {
        refButton.current.setLoading(true);
        onSave(option);
    };

    return (
        <CalendarDropdown
            customDropButton={() => <CustomButton ref={refButton} />}
            id="save-option-add-customer"
            ref={refDropdown}
            buttonClassName="dropbtn v2-btn-main dropbtn-save active --icon-r svg-more-white"
            wrapperClassName="dropdown-save"
            isMouseOverHandle
            isDropUp
        >
            <ul>
                <li className="items" onClick={() => _handleSelectOption(CUSTOMER_ADD_OPTION_VIEW_MAP)} tabIndex="0">
                    {t('customers:view_on_map')}
                </li>
                <li className="items" onClick={() => _handleSelectOption(CUSTOMER_ADD_OPTION_NEW_JOB)} tabIndex="0">
                    {t('customers:new_job')}
                </li>
                <li className="items" onClick={() => _handleSelectOption(CUSTOMER_ADD_OPTION_OPEN_CUSTOMER)} tabIndex="0">
                    {t('customers:open_customer')}
                </li>
            </ul>
        </CalendarDropdown>
    );
};

const CustomButton = forwardRef((props, ref) => {
    const { t } = useTranslation(['customers']);
    const [isLoading, setIsLoading] = useState(false);

    useImperativeHandle(ref, () => ({
        setLoading: (value) => {
            setIsLoading(value);
        }
    }));

    if (isLoading)
        return (
            <div className="is-disable">
                <div className="loading --ajaxbar">
                    <div className="loading__barlittle --first"></div>
                    <div className="loading__barlittle --second"></div>
                    <div className="loading__barlittle --three"></div>
                    <div className="loading__barlittle --four"></div>
                </div>
            </div>
        );

    return (
        <>
            {t('customers:save')} &
            <IconThreeDots />
        </>
    );
});

export default forwardRef(ButtonSaveWithOption);
