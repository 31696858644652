import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDModalWarning from 'app/components/modal/ModalWarning';
import { actionsRecordingCallNote } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';

const ModalDeleteRecording = ({ onDeleteSuccess = () => {} }, ref) => {
    const { t } = useTranslation('customers');

    const _handleDelete = (id, data) => {
        const _handleSuccess = () => {
            onDeleteSuccess(data);
            ref.current?._close();
        };

        const _handleFail = ({ message }) => {
            ref.current?._setStatusAlert(message);
        };

        clientQuery(actionsRecordingCallNote(data), { method: 'DELETE', data: {} }, _handleSuccess, _handleFail);
    };

    return (
        <GDModalWarning
            ref={ref}
            isLargeContent={false}
            title={t('delete_recording')}
            description={t('delete_recording_confirm')}
            buttonSave
            titleButtonConfirm={t('yes')}
            onConfirm={_handleDelete}
        />
    );
};

export default forwardRef(ModalDeleteRecording);
