import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { LOCATION_GET_LIST_CITY } from 'app/const/Api';

export function* getListLocationCity({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            fetchWithToken,
            LOCATION_GET_LIST_CITY,
            params
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
