import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ADDONS_ACCOUNT_TYPE_PLANS_TEXT } from 'app/const/addons';
import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import IconGrowth from 'assets/icon/IconGrowth';
import IconUpgrade from 'assets/icon/IconUpgrade';

const AddonHeaderTitle = ({ typeAccount = {}, onCheckGoToPlan = () => {} }) => {
    const { t } = useTranslation('addons');
    const isProPlan = typeAccount.isProPlan;
    const isGrowthPlan = typeAccount.isGrowthPlan;

    let accountText = ADDONS_ACCOUNT_TYPE_PLANS_TEXT.FREE_TRIAL;
    if (isProPlan) accountText = ADDONS_ACCOUNT_TYPE_PLANS_TEXT.PRO_PLAN;
    if (isGrowthPlan) accountText = ADDONS_ACCOUNT_TYPE_PLANS_TEXT.GROWTH_PLAN;
    if (typeAccount.isBasicPlan) accountText = ADDONS_ACCOUNT_TYPE_PLANS_TEXT.BASIC_PLAN;

    return (
        <div
            className={classNames('nav-boxs__title', {
                '--pro-plan': isProPlan,
                '--growth-plan': isGrowthPlan
            })}
        >
            {isProPlan && <IconUpgrade isFillOrange />}
            {isGrowthPlan && <IconGrowth isHeader />}
            <span>{t(accountText)}</span>
            {!isProPlan && !isGrowthPlan && (
                <Link to={addBranchPath(SETTINGS_PLANS)} onClick={onCheckGoToPlan} className="status-btn --has-icon">
                    <IconUpgrade isAddonUpgrade />
                    {t(`${typeAccount?.isBasicPlan ? 'upgrade_plan_to_pro' : 'upgrade_plan'}`)}
                </Link>
            )}
        </div>
    );
};

export default AddonHeaderTitle;
