import React from 'react';
import IconGoto from 'assets/icon/IconGoto';

const Header = ({ commentDetail }) => {
    const userDetail = commentDetail.user || {};

    return (
        <>
            <div className="avt-img">
                <img alt="" src={userDetail.avatar} width={24} height={24} />
            </div>
            <div className="name">
                <h5 className="name__label">
                    <IconGoto isSmall />
                    {userDetail.full_name}
                </h5>
                <p className="name__des word-break">
                    <span className="info">{commentDetail.date}</span>
                </p>
            </div>
        </>
    );
};

export default Header;
