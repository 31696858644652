import loadable from '@loadable/component';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { REPORT_PAYMENTS_COLLECTED_GET_LIST } from 'app/const/Api';
import { DELAY_TIME, KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { ARROW_DOWN_KEY_CODE, END_KEY_CODE, PAGE_DOWN_KEY_CODE } from 'app/const/Keyboard';
import { reducer } from 'app/const/Reducer';
import { HEIGHT_TO_SCROLL, REPORT_LIMIT } from 'app/const/Reports';
import { URL_EXPORT_PAYMENT_COLLECTED } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGridColumns } from 'app/const/report/PaymentsCollected';
import { PAYMENTS_COLLECTED_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import {
    getDataChartPaymentsCollected,
    getListYearPaymentsCollected
} from 'common/redux/actions/reports/paymentsCollectedAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, createRowTotal, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportBarChart = loadable(() => import('../components/ReportBarChart'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportPaymentsCollected() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users) || {};
    const contentRef = useRef(null);
    const parentRef = useRef(null);

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        rowTotal: [],
        isLoading: true,
        total: 0,
        refreshScreen: 0,
        isLoadMore: false
    });
    const {
        refreshScreen,
        data: finalData,
        total: finalTotal,
        isLoadMore: finalLoadMore,
        isLoading: finalLoading
    } = dataReport;

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.PAYMENTS_COLLECTED);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.PAYMENTS_COLLECTED);
    const finalGridColumns = getGridColumns(paramsReport?.order);
    const { contentConfig, columns } = finalGridColumns;
    const abortController = useRef(null);

    useEffect(() => {
        const parentHeight = parentRef.current.clientHeight || 0;
        const contentHeight = contentRef.current?.clientHeight || 0;
        if (contentHeight <= parentHeight && !finalLoading) {
            onScrollToEnd();
        }
    }, [finalData]);

    useEffect(() => {
        const loadMoreTimer =
            finalLoadMore &&
            setTimeout(() => {
                getListReport(false, { ...paramsReport, offset: finalData.length }, true);
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [finalLoadMore]);

    useEffect(() => {
        getListReport(true, paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const getListReport = (isReset = false, params, notShowLoading = false) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);

        const checkShouldLoading = !notShowLoading && !finalLoading;

        if (checkShouldLoading) {
            dispatchActionReport((prev) => ({
                ...prev,
                data: isReset ? [] : prev.data,
                isLoading: !!checkShouldLoading || prev.isLoading
            }));
        }

        delete params?.customer_id;
        delete params?.columns;

        clientQuery(
            REPORT_PAYMENTS_COLLECTED_GET_LIST,
            {
                data: convertParamFields({ params, isEmptyFields: finalLoadMore }),
                abortController: abortController.current
            },
            getListSuccess,
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        params.payment_methods = params?.payment_methods?.toString() || '';
        params.clients = params?.customer_id;
        params.limit = REPORT_LIMIT;
        return params;
    };

    const getListSuccess = ({ total_payments_received = {}, total, data = [] }) => {
        dispatchActionReport((prev) => {
            const finalTotal = total ?? prev.total ?? 0;
            const prevLoadMore = prev.isLoadMore;
            return {
                ...prev,
                isLoading: false,
                data: prevLoadMore ? [...prev.data, ...data] : data,
                total: finalTotal,
                rowTotal: prevLoadMore
                    ? prev.rowTotal
                    : createRowTotal({
                          data: total_payments_received,
                          columns,
                          title: t(`report:${finalTotal > 1 ? 'total_payments' : 'total_payment'}`, {
                              number: finalTotal
                          }),
                          isGetColumnId: true
                      }),
                refreshScreen: prev.refreshScreen + 1,
                isLoadMore: false
            };
        });
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadMore: false }));
    }

    const _handleUpdate = () => {
        getListReport(true, getLocalStorage(keyLocal));
    };

    function _handleScroll(e) {
        isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && onScrollToEnd();
    }

    function _handleKeyDown(e) {
        const listKeyDown = [PAGE_DOWN_KEY_CODE, END_KEY_CODE, ARROW_DOWN_KEY_CODE];
        if (listKeyDown.includes(e.keyCode)) {
            isScrollToEndBottom(e.currentTarget, HEIGHT_TO_SCROLL) && onScrollToEnd();
        }
    }

    function onScrollToEnd() {
        const numberData = finalData?.length || 0;

        if (!!numberData && !finalLoading && !finalLoadMore && finalTotal > numberData) {
            dispatchActionReport((prev) => {
                return { ...prev, isLoadMore: true };
            });
        }
    }

    function handleOnClickExport(e) {
        e && e.preventDefault();
    }

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.PAYMENTS_COLLECTED,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    const _handleClickInvoice = ({ row }) => {
        dispatch(
            actionOpenInvoice({
                id: row.invoice_id,
                status: row.status,
                total: '',
                isRecurring: false
            })
        );
    };

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', {
                    count: finalTotal
                })}
                activePrint
                onClick={handleOnClickExport}
                isDisable={finalLoading}
                url={URL_EXPORT_PAYMENT_COLLECTED}
                params={paramsReport}
                pageExport={LIST_EXPORT.EXPORT_REPORT_PAYMENT_COLLECTED}
                refresh={refreshScreen}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.PAYMENTS_COLLECTED}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns has-chart has-total pr-0">
                <div
                    ref={parentRef}
                    className="container-print contents-pages new-customer gap-8"
                    tabIndex={0}
                    onKeyDown={_handleKeyDown}
                    onWheel={_handleScroll}
                >
                    <div className="wrap-border">
                        <ReportBarChart
                            getDataAPI={getDataChartPaymentsCollected}
                            getListYearAPI={getListYearPaymentsCollected}
                            title={t('report:total_payments_collected')}
                            titleColumnTotal={t('report:total_revenue')}
                        />
                    </div>
                    <div ref={contentRef} className="wrap-tables flex-column relative">
                        <HeaderBottom
                            classNameHeader="header --filter"
                            typeReport={REPORT_TYPE.PAYMENTS_COLLECTED}
                            filters={PAYMENTS_COLLECTED_LIST_FILTER}
                            handleUpdate={_handleUpdate}
                            companyUsers={companyUsers}
                            isLoading={finalLoading}
                        />
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={finalData}
                            fileTranslation={'report'}
                            handleClick={_handleClickInvoice}
                            handleClickHeader={handleActionHeader}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                            {...finalGridColumns}
                            isScroll
                            isLoadmore={finalLoadMore}
                            isHaveChart
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportPaymentsCollected;
