import React, { Fragment } from 'react';
import { getJobStatus, getContrastColor } from 'common/utils/JobStatusUtils';

const ItemJobStatus = ({
    idStatus = '',
    excludeWrapper = false,
    isSpanTag = false,
    classWrapper = 'flexcenter flex-wrap gap-4',
    classTextItem = 'status-btn',
    defaultStatus = null
}) => {
    const { name: finalName, label: finalLabel, color: finalColor } = defaultStatus || getJobStatus(idStatus) || {};

    const _renderContent = () => {
        return (
            <Fragment>
                <TextItem title={finalName} classTextItem={classTextItem} color={finalColor} isSpanTag={isSpanTag} />
                <TextItem title={finalLabel} classTextItem={classTextItem} color={finalColor} isSpanTag={isSpanTag} />
            </Fragment>
        );
    };

    if (excludeWrapper) return _renderContent();
    return <div className={classWrapper}>{_renderContent()}</div>;
};

export default ItemJobStatus;

const TextItem = ({ title = '', color: bgColor = '', classTextItem = 'status-btn', isSpanTag = false }) => {
    if (!title) return null;
    const textColor = getContrastColor(bgColor);

    if (isSpanTag)
        return (
            <span className={classTextItem} style={{ '--bg-job-status': bgColor, '--color-job-status': textColor }}>
                {title}
            </span>
        );

    return (
        <div className={classTextItem} style={{ '--bg-job-status': bgColor, '--color-job-status': textColor }}>
            {title}
        </div>
    );
};
