import { CUSTOMERS } from 'app/const/Route';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import CustomerImportProgress from '../components/importCustomer/CustomerImportProgress';
import { addBranchPath } from 'app/const/Branch';

const CustomerImported = () => {
    const { t } = useTranslation(['common', 'customers']);
    const { state: stateLocation } = useLocation();
    const history = useHistory();

    const _redirectCustomer = () => {
        history.push(addBranchPath(CUSTOMERS));
    };

    if (!stateLocation) {
        _redirectCustomer();
        return null;
    }

    return (
        <>
            <div className="title-header">
                <div className="step-import-customer">
                    <div className="step-number">3</div>
                    <div className="step-details">
                        <div className="step-title">{t('customers:step')}</div>
                        <div className="step-description">
                            {t('customers:desc_import_step_3', { total: stateLocation.totalImport })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="list-import is-importing">
                <CustomerImportProgress onReloadCustomer={_redirectCustomer} />
            </div>
        </>
    );
};

export default CustomerImported;
