import React, { useEffect } from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import queryStrings from 'query-string';
import { useSiteTitle } from 'common/hooks/useSiteTitle';

import { activateAccount, clearInfoSignup } from 'common/redux/actions/authAction';

import { AUTH_LOGIN, HOME } from 'app/const/Route';

import AccountHasActivated from './components/AccountHasActivated';
import { restoreOptionJobCalendar } from 'app/modules/calendar/ultil/Calendar';

const SignupActivate = () => {
    useSiteTitle('auth:sign_up');

    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const { errorActivate, isLogin, errorMessage } = useSelector((state) => state.auth);

    const params = queryStrings.parse(location.search);

    useEffect(() => {
        clearInfoSignup();
    }, []);

    useEffect(() => {
        if (params['token']) {
            dispatch(activateAccount(params, handleActiveSuccess, () => {}));
        } else {
            history.push(AUTH_LOGIN);
        }
    }, []);

    const handleActiveSuccess = () => {
        restoreOptionJobCalendar();
    };

    if (errorActivate) return <AccountHasActivated errorMessage={errorMessage} />;

    if (isLogin) return <Redirect to={HOME} />;

    return null;
};

export default SignupActivate;
