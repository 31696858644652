import { WOORPOOL_TAB } from 'app/modules/calendar/const/SideBar';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WorkPoolTabsBar = ({ tab, onSelect, numberPool, numberMissed }) => {
    const { t } = useTranslation(['calendar']);
    function _selectTab(selected) {
        selected !== tab && onSelect(selected);
    }

    return (
        <div className="btn-item m-0">
            <div
                className={`tab-items text-capitalize ${tab === WOORPOOL_TAB.POOL ? 'active-tab-selector' : ''}`}
                onClick={() => _selectTab(WOORPOOL_TAB.POOL)}
            >
                {t('calendar:pool')}
                {parseInt(numberPool) > 0 && (
                    <span className="count --poll">{parseInt(numberPool) > 99 ? '99+' : numberPool} </span>
                )}
            </div>
            <div
                className={`tab-items text-capitalize ${tab === WOORPOOL_TAB.MISSED ? 'active-tab-selector' : ''}`}
                onClick={() => _selectTab(WOORPOOL_TAB.MISSED)}
            >
                {t('calendar:missed')}
                {parseInt(numberMissed) > 0 && (
                    <span className="count --missed">{parseInt(numberMissed) > 99 ? '99+' : numberMissed} </span>
                )}
            </div>
        </div>
    );
};

export default WorkPoolTabsBar;
