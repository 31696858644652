import React from 'react';

export default function LoadingService() {
    return (
        <div className="wrap-loading content-services contents">
            <div className="sidebar-pages h-100 pt-0 px-2">
                <div className="tab-selectors pointer-events-none">
                    <div className="header-title px-1">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="tab-items">
                        <div className="loading --animation --line --twothird" />
                    </div>
                    <div className="tab-items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="tab-items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="tab-items">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="line mx-2" />
                    <div className="tab-items">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="tab-items">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            <div className="content-services__right">
                <div className="tab-conts dashboard-job-detail tab-content-active">
                    <div className="container-setting-center">
                        <div className="header-modal">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="container-column new-service schedule-wrapper">
                            <div className="details-job">
                                <div className="rows">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="details">
                                        <div className="loading v2-btn-default --grey w-100">
                                            <div className="loading --animation --line --twothird" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="details">
                                        <div className="loading v2-btn-default --grey btn-sm">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="line" />
                                <div className="rows">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                    <div className="details d-flex align-center">
                                        <div className="loading v2-btn-default --grey btn-threefourth">
                                            <div className="loading --animation --line --threefourth" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="details">
                                        <div className="loading v2-btn-default --grey btn-sm">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows row-has-line border-top-border-color-grey">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                    <div className="details d-flex align-center">
                                        <div className="loading v2-btn-default --grey btn-md mr-2">
                                            <div className="loading --animation --line --threefourth" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        </div>
                                        <div className="loading v2-btn-default --grey btn-sm">
                                            <div className="loading --animation --line --full" />
                                        </div>
                                        <div className="loading --animation --line --onetenth ml-2" />
                                    </div>
                                </div>
                                <div className="rows row-has-line border-top-border-color-grey">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --twothird" />
                                    </div>
                                    <div className="details d-flex align-center">
                                        <div className="loading v2-btn-default --grey btn-threefourth">
                                            <div className="loading --animation --line --threefourth" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                            <div className="loading --animation --line --threefourth mt-0 ml-2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows row-except-month">
                                    <div className="txt" />
                                    <div className="details d-flex">
                                        <div className="col-haft">
                                            <div className="loading --animation --line --half" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                        </div>
                                        <div className="col-haft">
                                            <div className="loading --animation --line --half" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                            <div className="loading --animation --line --half mt-2" />
                                        </div>
                                    </div>
                                </div>
                                <div className="rows row-has-line border-top-border-color-grey">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="details d-flex align-center mt-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                </div>
                                <div className="rows row-has-line border-top-border-color-grey">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                    <div className="details d-flex align-center mt-2">
                                        <div className="loading --animation --line --half" />
                                    </div>
                                </div>
                                <div className="rows">
                                    <div className="txt pl-2">
                                        <div className="loading --animation --line --threefourth" />
                                    </div>
                                    <div className="details">
                                        <div className="loading v2-btn-default --grey w-100">
                                            <div className="loading --animation --line --onefifth" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
