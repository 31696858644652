import { call } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import {
    GET_LIST_SIMPLE_PAYMENT_METHODS,
    SETTINGS_PAYMENT_METHODS,
    SETTINGS_UPDATE_STATUS_PAYMENT_METHODS,
    getUrlSettingUpdatePaymentMethod
} from 'app/const/Api';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';

export function* getListSimplePaymentMethods({ params, actionSuccess, actionFailed }) {
    try {
        params = { merchant_method: 1 };
        const isInPageSaleTaxSummary = getLocalStorage(
            KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.SALES_TAX_SUMMARY)
        );
        if (isInPageSaleTaxSummary) {
            params.merchant_method = 2;
        }
        if (window.location.pathname.includes('revenue')) {
            params.all = 1;
        }
        const response = yield call(fetchWithToken, GET_LIST_SIMPLE_PAYMENT_METHODS, params);

        if (response && response.success) {
            const newData = [...response.data];

            //Check the page use payment_method, if the page is Payments Collected payment_method has another options Stripe Subscriptions
            const isInPagePaymentColleted = getLocalStorage(
                KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.PAYMENTS_COLLECTED)
            );
            if (isInPagePaymentColleted) {
                const subscriptionOption = {
                    value: '_subscription',
                    id: '_subscription',
                    name: 'Stripe Subscriptions'
                };
                let indexOfStripe = newData.length;

                newData.forEach((item, index) => {
                    item.value = item.id;
                    if (item.id === 'stripe') {
                        indexOfStripe = index;
                    }
                });
                newData.splice(indexOfStripe, 0, subscriptionOption);
            } else {
                newData.forEach((item) => {
                    item.value = item.id;
                });
            }

            actionSuccess({ ...response, data: newData });
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListPaymentMethods({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_PAYMENT_METHODS, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createPaymentMethod({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_PAYMENT_METHODS, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updatePaymentMethod({ params, actionSuccess, actionFailed }) {
    try {
        const URL = getUrlSettingUpdatePaymentMethod(params.id);
        const response = yield call(putWithToken, URL, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusPaymentMethods({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_UPDATE_STATUS_PAYMENT_METHODS, params);
        if (response && response.success) {
            response.listIdChecked = params.ids;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
