import classNames from 'classnames';
import React from 'react';

import IconTrash from 'assets/icon/IconTrash';
import IconAttachment from '../IconAttachment';

const ItemAttachList = ({ keyObj, mime, type, name, isUploading = false, onRemove = () => {} }) => {
    return (
        <div className={classNames('file', { 'is-disable': isUploading })}>
            <div className="file__items">
                <IconAttachment type={mime || type} />
                <p className="names">{name}</p>
            </div>
            {isUploading ? (
                <div className="preloader-small">
                    <span className="preloader-small__items" />
                </div>
            ) : (
                <div
                    className="btn-delete v2-btn-default --icon-sm --delete btn-modal"
                    onClick={() => onRemove(keyObj)}
                >
                    <IconTrash />
                </div>
            )}
        </div>
    );
};

export default ItemAttachList;
