export default function IconPrint() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 16.5H6.5C5.39543 16.5 4.5 15.6046 4.5 14.5V12V11.5C4.5 10.3954 5.39543 9.5 6.5 9.5H17.5C18.6046 9.5 19.5 10.3954 19.5 11.5V14.5C19.5 15.6046 18.6046 16.5 17.5 16.5H16.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 13.5H15.5C16.0523 13.5 16.5 13.9477 16.5 14.5V16.5C16.5 17.6046 15.6046 18.5 14.5 18.5H9.5C8.39543 18.5 7.5 17.6046 7.5 16.5V14.5C7.5 13.9477 7.94772 13.5 8.5 13.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 5.5H14.5C15.6046 5.5 16.5 6.39543 16.5 7.5V9.5H7.5V7.5C7.5 6.39543 8.39543 5.5 9.5 5.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
