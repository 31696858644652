import { updateTaskStatusRealtime } from 'common/redux/actions/calendar/listTaskAction';
import moment from 'moment';
import { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { convertTaskEvent } from './ultil/Calendar';
import { SocketContext } from 'app/services/socket/SocketProvider';

const RealtimeTaskServices = ({ events = [], onUpdateRealtime = () => {}, onUpdateModalTaskSchedules = () => {} }) => {
    const dispatch = useDispatch();
    const calendarReducer = useSelector(({ calendar }) => calendar);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const schedules = calendarReducer.schedules || [];

    const {
        taskCreate: finalTaskCreate,
        taskUpdate: finalTaskUpdate,
        taskDelete: finalTaskDelete,
        taskStatus: finalTaskStatus
    } = useContext(SocketContext);

    useEffect(() => {
        // eslint-disable-next-line no-undef
        global.socket_id_task_service = `${Date.now()}_task_service`;
    }, []);

    useEffect(() => {
        _handleRealTimeTask(finalTaskCreate, finalTaskUpdate, finalTaskDelete, finalTaskStatus);
    }, [finalTaskCreate, finalTaskUpdate, finalTaskDelete, finalTaskStatus]);

    function _handleRealTimeTask(taskCreate, taskUpdate, taskDelete, taskStatus) {
        // eslint-disable-next-line no-undef
        const globalSocketId = global.socket_id_task_service;

        if (!!!taskCreate && !!!taskDelete && !!!taskUpdate && !!!taskStatus) return;

        if (
            globalSocketId === taskCreate?.socket_id ||
            globalSocketId === taskDelete?.socket_id ||
            globalSocketId === taskUpdate?.socket_id ||
            globalSocketId === taskStatus?.socket_id
        ) {
            return;
        }

        if (taskCreate) {
            const dataTask = taskCreate.data;
            const schedule = _handleGetSchedule(dataTask.schedules);
            if (!schedule) return;
            const result = convertTaskEvent({ ...dataTask, schedule });

            onUpdateRealtime([...events, result]);
            onUpdateModalTaskSchedules({ task: result, type: 'add' });
            dispatch(updateTaskStatusRealtime({ ...dataTask, typeAction: 'create' }));
            return;
        }

        if (taskDelete) {
            const ids = taskDelete.data;
            const newEvents = events.filter((item) => !ids.includes(item.id));
            onUpdateRealtime(newEvents);
            onUpdateModalTaskSchedules({ task: ids, type: 'delete' });
            dispatch(updateTaskStatusRealtime({ id: ids, typeAction: 'delete' }));
            return;
        }

        if (taskUpdate) {
            let result = [...events];
            const dataTask = taskUpdate.data;
            const schedule = _handleGetSchedule(dataTask.schedules);
            const taskResult = convertTaskEvent({ ...dataTask, schedule });

            if (!schedule) {
                result = events.filter((item) => item.id !== dataTask.id);
            } else {
                const newDate = moment(dataTask.date).utc().unix();
                const inRangeDate =
                    moment.utc(start).startOf('day').unix() <= newDate &&
                    newDate <= moment.utc(end).subtract(1, 'd').endOf('day').unix();

                if (!inRangeDate) {
                    result = result.filter((item) => item.id !== dataTask.id);
                } else {
                    const indexUpdate = result.findIndex((item) => item.id === dataTask.id);
                    if (indexUpdate !== -1) {
                        result = result.map((item) => {
                            if (item.id === dataTask.id) return convertTaskEvent({ ...item, ...dataTask, schedule });
                            return item;
                        });
                    } else {
                        result.push(taskResult);
                    }
                }
            }

            onUpdateRealtime(result);
            onUpdateModalTaskSchedules({ task: taskResult, type: 'update' });
            dispatch(updateTaskStatusRealtime({ ...dataTask, typeAction: 'update' }));
            return;
        }

        if (taskStatus) {
            const dataTask = taskStatus.data;

            // Update multiple tasks status
            const tasksUpdated = events.map((item) => {
                const dataUpdate = dataTask.find((el) => el.id === item.id);
                if (dataUpdate) return { ...item, ...dataUpdate };
                return item;
            });

            onUpdateRealtime(tasksUpdated);
            onUpdateModalTaskSchedules({ tasksDone: dataTask, type: 'done' });
            dispatch(
                updateTaskStatusRealtime({
                    data: dataTask,
                    isMultipleUpdate: true,
                    active: dataTask[0].active,
                    typeAction: 'status'
                })
            );
        }
    }

    /**
     * It returns the first element in the array that satisfies the provided testing function. Otherwise
     * undefined is returned.
     * @param dataSchedules - the data that is returned from the API
     * @returns the first item in the dataSchedules array that matches the condition.
     */
    const _handleGetSchedule = (dataSchedules = []) => {
        return dataSchedules.find((item) => {
            if (schedules.find((el) => el.id === item.id)) return true;
            return false;
        });
    };

    return null;
};

export default RealtimeTaskServices;
