import { call, put } from "redux-saga/effects";
import { postFetchWithoutToken } from "common/utils/ApiUtils";
import {
  AUTH_TYPE
} from "common/redux/actions/authAction";
import { FORGOT_PASSWORD } from "app/const/Api";

export function* userForgotPassword({ payload, actionSuccess, actionFailed }) {
  const response = yield call(postFetchWithoutToken, FORGOT_PASSWORD, payload, put);
  if (response && response.success) {
    yield put({
      type: AUTH_TYPE.FORGOT_PASSWORD_USER_SUCCESS, payload: {
        'email': payload.email,
        'success': response.success,
        'message': response.message
      }
    });
    actionSuccess(response);
  } else {
    yield put({ type: AUTH_TYPE.FORGOT_PASSWORD_USER_ERROR, payload: response });
    actionFailed(response);
  }
}
