import React, { useReducer, forwardRef, useImperativeHandle } from 'react';
import GdButton from 'app/components/button';
import { reducer } from 'app/const/Reducer';

const ButtonSave = forwardRef(({ onClick }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: false
    });
    const finalIsLoading = state.isLoading;

    useImperativeHandle(ref, () => ({
        removeLoading: _removeLoading
    }));

    function _removeLoading() {
        dispatchState({
            isLoading: false
        });
    }

    function _handleClick() {
        dispatchState({
            isLoading: true
        });
        onClick();
    }

    return (
        <GdButton
            className="v2-btn-main ml-2"
            isLoading={finalIsLoading}
            onClick={_handleClick}
            disabled={state.disableButton}
            id={'button_save_detail_job_unit'}
        >
            Save
        </GdButton>
    );
});

export default ButtonSave;
