import { call, put } from 'redux-saga/effects';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { getUrlSettingUpdateTax, SETTINGS_TAXES, SETTINGS_UPDATE_STATUS_TAXES } from 'app/const/Api';

import { TYPE_TAXES } from 'common/redux/actions/taxesAction';

export function* getListTaxes({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_TAXES, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createTax({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_TAXES, params);
        if (response && response.success) {
            response.data.name = params.name;
            response.data.rate = params.rate;
            yield put({ type: TYPE_TAXES.ACTION_ADD_LIST_TAXES, payload: [response.data] });
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateTax({ params, actionSuccess, actionFailed }) {
    try {
        const URL = getUrlSettingUpdateTax(params.id);
        const response = yield call(putWithToken, URL, params);
        if (response && response.success) {
            response.data.name = params.name;
            response.data.rate = params.rate;

            yield put({ type: TYPE_TAXES.ACTION_UPDATE_TAX_ITEM, payload: response.data });

            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusTaxes({ params, actionSuccess, actionFailed }) {
    try {
        const { listTaxSelected, isAddTaxesInStore, isRemoveTaxesInStore } = params.taxStoreController;
        delete params.taxStoreController;
        const response = yield call(putWithToken, SETTINGS_UPDATE_STATUS_TAXES, params);

        if (response && response.success) {
            response.listIdChecked = params.ids;

            if (isRemoveTaxesInStore) {
                yield put({ type: TYPE_TAXES.ACTION_REMOVE_LIST_TAXES, payload: response.listIdChecked });
            }

            if (isAddTaxesInStore) {
                yield put({ type: TYPE_TAXES.ACTION_ADD_LIST_TAXES, payload: listTaxSelected });
            }

            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
