import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonSave from 'app/components/button/ButtonSave';
import { reducer } from 'app/const/Reducer';
import FilterReport from 'app/modules/report/components/FilterReport';
import ReportDateRangePicker from 'app/modules/report/components/ReportDateRangePicker';
import ReportSearch from 'app/modules/report/components/ReportSearch';

const HeaderFilterChatbot = ({ typeReport = '', filters = [], isShowDateRange = false, handleGetData = () => {} }) => {
    const { t } = useTranslation('common');

    const [state, dispatchState] = useReducer(reducer, { isFirstTime: true });
    const { isFirstTime } = state;

    const refButtonSave = useRef(null);

    useEffect(() => {
        if (!_isDisableBtnSave()) _setDisabledButtonSave(true);
    }, []);

    const _setDisabledButtonSave = (isDisable = false) => {
        if (isFirstTime) {
            dispatchState({ isFirstTime: false });
        }
        refButtonSave.current?.setDisable(isDisable);
    };

    const _handleSelect = (params, type) => {
        if (!type) return;
        if (_isDisableBtnSave()) _setDisabledButtonSave(false);
    };

    const _handleGetData = () => {
        if (!_isDisableBtnSave()) _setDisabledButtonSave(true);
        handleGetData();
    };

    const _isDisableBtnSave = () => {
        return refButtonSave.current?.getStatusBtn();
    };

    const _onChangeImmediately = () => {
        if (_isDisableBtnSave()) _setDisabledButtonSave(false);
    };

    return (
        <div className="header">
            <ReportSearch
                reportType={typeReport}
                placeholder={t('search')}
                isSearchOnClear
                onKeyEnter={_handleGetData}
                onChangeImmediately={_onChangeImmediately}
                isShowClearIcon={false}
            />
            {isShowDateRange ? (
                <ReportDateRangePicker
                    reportType={typeReport}
                    onSelect={_handleGetData}
                    wrapperClassName="header-items"
                />
            ) : null}
            {!!filters?.length ? (
                <FilterReport reportType={typeReport} filters={filters} onSelect={_handleSelect} />
            ) : null}
            <ButtonSave
                ref={refButtonSave}
                title={t('update')}
                wrapClass="v2-btn-main header-items"
                isNotLoading
                disabled={isFirstTime}
                onSave={_handleGetData}
            />
        </div>
    );
};

export default HeaderFilterChatbot;
