import React, { useReducer, useRef } from 'react';
import IconEdit from 'assets/icon/IconEdit';
import { MATERIAL_TYPES } from 'app/const/addons/Material';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionMaterialColumn } from 'app/const/api/Material';
import ButtonSave from 'app/components/button/ButtonSave';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';

let timerStatus;

export default function Header({ type, name, status, id, onUpdate = () => {}, onShowMessage = () => {} }) {
    const [state, dispatchState] = useReducer(reducer, {
        name: name || '',
        status,
        isVisible: false
    });
    const refButtonSave = useRef(null);

    const { name: finalName, isVisible: finalIsVisible } = state;

    function _handleOpenEdit(e) {
        e && e.stopPropagation();
        dispatchState({
            isVisible: true
        });
    }

    function _handleCloseEdit(e) {
        e && e.stopPropagation();
        dispatchState({
            isVisible: false,
            name
        });
    }

    function _handleChangeStatus() {
        const newValue = {
            status: status ? 0 : 1,
            name: name
        };
        onUpdate(newValue);

        timerStatus && clearTimeout(timerStatus);

        timerStatus = setTimeout(() => {
            _apiUpdateStatus(newValue);
        }, 500);
    }

    function _handleChangeName(e) {
        dispatchState({
            name: e.target.value,
            status
        });
    }

    function _handleSave() {
        const newValue = {
            status,
            name: finalName
        };
        onUpdate(newValue);
        clientQuery(actionMaterialColumn(id), { method: 'PUT', data: newValue }, _handleSaveSuccess, _handleSaveFailed);
    }

    function _handleSaveSuccess() {
        refButtonSave.current.removeLoading();
        onShowMessage(new Date().getTime(), 'Update success', LIST_STATUS.SUCCESS);
        dispatchState({
            isVisible: false
        });
    }

    function _handleSaveFailed(reponse) {
        refButtonSave.current.removeLoading();
        onShowMessage(new Date().getTime(), reponse?.message?.toString() || 'Please try again', LIST_STATUS.ERROR);
    }

    function _apiUpdateStatus(params) {
        clientQuery(actionMaterialColumn(id), { method: 'PUT', data: params });
    }

    function _renderToggleActive() {
        const idToggle = `${id}_toggle_active_column`;
        return (
            <div className="switch large">
                <input
                    id={idToggle}
                    className="toggle toggle-round js-active-box"
                    type="checkbox"
                    checked={!!status}
                    onChange={(e) => _handleChangeStatus(e)}
                />
                <label htmlFor={idToggle} />
            </div>
        );
    }

    return (
        <div className="boxs__header">
            <div className={`header-action ${finalIsVisible ? 'dp-hide' : ''}`}>
                <div className="title flex-1">{finalName}</div>
                <div
                    onClick={(e) => _handleOpenEdit(e)}
                    className="v2-btn-default --transparent --icon-lg js-edit-title"
                >
                    <IconEdit />
                </div>
                {type !== MATERIAL_TYPES.MATERIAL && _renderToggleActive()}
            </div>
            <div className={`header-action --edit ${finalIsVisible ? '' : 'dp-hide'}`}>
                <input
                    onChange={(e) => _handleChangeName(e)}
                    value={finalName}
                    className="field-input"
                    type="text"
                    placeholder="Column Title"
                />
                <div className="btn-action">
                    <div onClick={(e) => _handleCloseEdit(e)} className="v2-btn-default --transparent js-cancel-edit">
                        Cancel
                    </div>
                    <ButtonSave disabled={finalName.length === 0} ref={refButtonSave} onSave={_handleSave} />
                </div>
            </div>
        </div>
    );
}
