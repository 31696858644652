import React, { useReducer, useEffect, useRef } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconTrash from 'assets/icon/IconTrash';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconPlus from 'assets/icon/IconPlus';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';

const EmailItem = ({
    removeItem,
    dataEmail,
    index,
    ids,
    isFirstTimeFetch,
    openOptions,
    data,
    canAddMore = true,
    isLocationEmail = false,
    selectOption,
    addItem = () => {}
}) => {
    const refDropdown = useRef(null);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_result_sms_to');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_result_sms_to');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && dispatchState({ isVisible: false });
    }

    function _toggleOptions(e) {
        e.stopPropagation();

        if (!finalIsVisible && isFirstTimeFetch) {
            openOptions();
        }
        dispatchState({ isVisible: !finalIsVisible });
    }

    function _handleSelectOption(e, item) {
        e.stopPropagation();
        selectOption(item, index);
        dispatchState({ isVisible: false });
    }

    function _renderListOptions() {
        return data.map((item) => {
            if (item.id.length && ids.includes(item.id)) return null;
            return (
                <li key={item.id} className={'items'} onClick={(e) => _handleSelectOption(e, item)} tabIndex="0">
                    <p className="txt-ellipsis">{item.email}</p>
                </li>
            );
        });
    }

    return (
        <div className="has-many-field__items">
            <div ref={refDropdown} className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
                <div onClick={(e) => _toggleOptions(e)} className="dropbtn items" tabIndex="0">
                    <p className="txt-ellipsis">{dataEmail?.email || ''}</p>
                    <span className="svg-selectbox">
                        <IconArrowDown width={16} height={16} />
                    </span>
                </div>
                {finalIsVisible && (
                    <div id="show_result_sms_to" className="v2-dropdown__menu scrolls">
                        <ul>
                            {isFirstTimeFetch ? (
                                <div className="items justify-center">
                                    <div className="loading -ajaxbar">
                                        <IconLoading />
                                    </div>
                                </div>
                            ) : (
                                _renderListOptions()
                            )}
                        </ul>
                    </div>
                )}
            </div>

            {isLocationEmail && index === 0 && canAddMore && (
                <div className="v2-btn-default --icon-lg" onClick={addItem}>
                    <IconPlus />
                </div>
            )}

            {index > 0 && (
                <div className="v2-btn-default --delete --icon-lg" onClick={() => removeItem(index)}>
                    <IconTrash />
                </div>
            )}
        </div>
    );
};

export default EmailItem;
