import React from 'react';

const IconZapier = ({ isGrey }) => {
    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M18 16.004C18 16.5807 17.8958 17.1527 17.6926 17.6923C17.1527 17.8956 16.5806 17.9999 16.0037 18H15.9967C15.4198 18 14.8477 17.8959 14.3079 17.6926C14.1043 17.1528 14 16.5806 14 16.0038V15.9962C14 15.4018 14.1091 14.8326 14.3067 14.3074C14.8467 14.1038 15.4189 13.9996 15.996 14H16.0025C16.5971 14 17.1661 14.1091 17.6914 14.3074C17.8948 14.8472 17.999 15.4193 17.9987 15.9962V16.0038L18 16.004ZM24.8753 14.4997H19.6219L23.3363 10.7852C22.7523 9.96496 22.0351 9.2481 21.2146 8.66425L17.4998 12.3788V7.12527C17.0058 7.04218 16.5058 7.00028 16.0049 7H15.9953C15.4858 7 14.9866 7.04355 14.5002 7.12527V12.3788L10.7844 8.66397C10.3753 8.9552 9.99118 9.28024 9.6362 9.63564L9.63453 9.63734C9.27972 9.99248 8.95488 10.3765 8.6637 10.7855L12.3794 14.4997H7.12491C7.12491 14.4997 7 15.4867 7 15.9968V16.0031C7 16.5132 7.0432 17.0132 7.12525 17.4998H12.3797L8.66337 21.2139C9.24791 22.034 9.96506 22.7511 10.7852 23.3353L14.5004 19.6212V24.8749C14.9863 24.9564 15.4846 24.9993 15.9936 25H16.0064C16.5067 24.9995 17.0062 24.9577 17.4996 24.8749V19.6212L21.215 23.3362C21.6242 23.0448 22.0085 22.7197 22.364 22.3646C22.7188 22.009 23.0443 21.6247 23.3355 21.2155L19.6211 17.5002H24.8756C24.957 17.0137 25 16.5156 25 16.0065V15.9935C25 15.4929 24.9578 14.9932 24.8753 14.4997Z"
                    fill="#4D5054"
                />
            </svg>
        );

    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                d="M18 16.004C18 16.5807 17.8958 17.1527 17.6926 17.6923C17.1527 17.8956 16.5806 17.9999 16.0037 18H15.9967C15.4198 18 14.8477 17.8959 14.3079 17.6926C14.1043 17.1528 14 16.5806 14 16.0038V15.9962C14 15.4018 14.1091 14.8326 14.3067 14.3074C14.8467 14.1038 15.4189 13.9996 15.996 14H16.0025C16.5971 14 17.1661 14.1091 17.6914 14.3074C17.8948 14.8472 17.999 15.4193 17.9987 15.9962V16.0038L18 16.004ZM24.8753 14.4997H19.6219L23.3363 10.7852C22.7523 9.96496 22.0351 9.2481 21.2146 8.66425L17.4998 12.3788V7.12527C17.0058 7.04218 16.5058 7.00028 16.0049 7H15.9953C15.4858 7 14.9866 7.04355 14.5002 7.12527V12.3788L10.7844 8.66397C10.3753 8.9552 9.99118 9.28024 9.6362 9.63564L9.63453 9.63734C9.27972 9.99248 8.95488 10.3765 8.6637 10.7855L12.3794 14.4997H7.12491C7.12491 14.4997 7 15.4867 7 15.9968V16.0031C7 16.5132 7.0432 17.0132 7.12525 17.4998H12.3797L8.66337 21.2139C9.24791 22.034 9.96506 22.7511 10.7852 23.3353L14.5004 19.6212V24.8749C14.9863 24.9564 15.4846 24.9993 15.9936 25H16.0064C16.5067 24.9995 17.0062 24.9577 17.4996 24.8749V19.6212L21.215 23.3362C21.6242 23.0448 22.0085 22.7197 22.364 22.3646C22.7188 22.009 23.0443 21.6247 23.3355 21.2155L19.6211 17.5002H24.8756C24.957 17.0137 25 16.5156 25 16.0065V15.9935C25 15.4929 24.9578 14.9932 24.8753 14.4997Z"
                fill="#FF4A00"
            />
        </svg>
    );
};

export default IconZapier;
