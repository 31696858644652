import { LOCATION_ACTIONS } from 'common/redux/actions/location';

const initialState = {
    locationData: null
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOCATION_ACTIONS.OPEN_LOCATION:
            return { ...state, locationData: action.payload || {} };
        default:
            return state;
    }
};

export default locationReducer;
