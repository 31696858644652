import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { getSettingsAPIKey } from 'app/const/Api';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { ITEMS_SETTINGS_API_KEY } from '../../constants';

const ApiKeySettingsModal = ({ onUpdateSuccess = () => {} }, ref) => {
    const { t } = useTranslation('addons');

    const refForm = useRef(null);
    const refButtonSave = useRef(null);
    const refAlert = useRef(null);

    const initState = { id: '', settings: {}, isOpen: false };
    const [state, dispatchState] = useReducer(reducer, initState);
    const { id: finalId, settings: finalSettings, isOpen: finalIsOpen } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = ({ id = '', settings = {} }) => {
        dispatchState({ id, settings, isOpen: true });
    };

    const _close = () => {
        dispatchState(initState);
    };

    const _handleSave = () => {
        const data = {};
        refForm.current
            .querySelectorAll(`[type="checkbox"`)
            .forEach(({ name, checked }) => (data[name] = checked ? 1 : 0));

        const _handleSaveSuccess = (data = {}) => {
            onUpdateSuccess({ id: finalId, settings: data });
            _close();
        };
        const _handleSaveFailed = ({ message }) => {
            refAlert.current?.showStatusBar('error', message, LIST_STATUS.ERROR);
        };
        const _handleFinally = () => {
            refButtonSave.current?.removeLoading();
        };

        clientQuery(
            getSettingsAPIKey(finalId),
            { data, toFormData: false, method: 'PUT' },
            () => _handleSaveSuccess(data),
            _handleSaveFailed,
            _handleFinally
        );
    };

    const _renderListItem = () => {
        return ITEMS_SETTINGS_API_KEY.map(({ id, title }) => {
            return <ItemCheckbox key={id} id={id} title={t(title)} defaultValue={!!finalSettings?.[id]} />;
        });
    };

    if (!finalIsOpen) return null;
    return (
        <ReactModal
            id="apiKeySettings"
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close}></div>
            <div className="modal__container modal-customer-portal-settings">
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('api_integration')}</h3>
                </div>
                <GDStatusBar ref={refAlert} />
                <div ref={refForm} className="body-modal">
                    <div className="portal-settings__description">{t('manage_api_features')}</div>
                    <ul className="portal-settings__list">{_renderListItem()}</ul>
                </div>
                <div className="footer-modal btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleSave} />
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(ApiKeySettingsModal);

const ItemCheckbox = ({ id = '', defaultValue = false, title = '' }) => {
    return (
        <li className="check-items">
            <input id={id} type="checkbox" name={id} defaultChecked={defaultValue} />
            <div className="item-checkbox">
                <label className="label" htmlFor={id}>
                    {title}
                </label>
            </div>
        </li>
    );
};
