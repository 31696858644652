import { CALENDAR_BROADCAST_TYPE } from 'app/modules/calendar/const/Header';
import { SETTING_TEMPLATE_TYPES } from 'app/modules/settings/emailinbox/const';
import { TYPE_SETTING_TEMPLATE } from 'common/redux/actions/settings/templateAction';

const templatesReducer = (
    state = {
        [CALENDAR_BROADCAST_TYPE.SEND_REMINDERS]: {
            isFirstTime: true,
            data: []
        },
        [CALENDAR_BROADCAST_TYPE.SEND_CONFIRMATIONS]: {
            isFirstTime: true,
            data: []
        },
        [CALENDAR_BROADCAST_TYPE.SEND_A_BROADCAST]: {
            isFirstTime: true,
            data: []
        },
        [SETTING_TEMPLATE_TYPES.EMAIL_INBOX]: {
            isFirstTime: true,
            data: []
        }
    },
    action
) => {
    switch (action.type) {
        case TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_REMINDER_SUCCESS:
            return {
                ...state,
                [CALENDAR_BROADCAST_TYPE.SEND_CONFIRMATIONS]: {
                    isFirstTime: false,
                    data: action.payload?.data || []
                }
            };
        case TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_CONFIRMATION_SUCCESS:
            return {
                ...state,
                [CALENDAR_BROADCAST_TYPE.SEND_REMINDERS]: {
                    isFirstTime: false,
                    data: action.payload?.data || []
                }
            };
        case TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_BROADCAST_SUCCESS:
            return {
                ...state,
                [CALENDAR_BROADCAST_TYPE.SEND_A_BROADCAST]: {
                    isFirstTime: false,
                    data: action.payload?.data || []
                }
            };
        case TYPE_SETTING_TEMPLATE.GET_LIST_TEMPLATE_EMAIL_INBOX_SUCCESS:
            return {
                ...state,
                [SETTING_TEMPLATE_TYPES.EMAIL_INBOX]: {
                    isFirstTime: false,
                    data: action.payload?.data || []
                }
            };
        case TYPE_SETTING_TEMPLATE.RESET_LOAD_TEMPLATES:
            return { ...state, [action.payload.key]: { isFirstTime: true, data: [] } };
        default:
            return state;
    }
};

export default templatesReducer;
