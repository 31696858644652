export default function IconQuickBook({ hasColor = false, isGrey }) {
    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                    fill="#F0F0F0"
                />
                <path
                    d="M16 25C20.9706 25 25 20.9706 25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.9706 11.0294 25 16 25Z"
                    fill="#666666"
                />
                <path
                    d="M9 15.9997C9 17.9656 10.5668 19.5592 12.4995 19.5592H12.9994V18.2371H12.4995C11.2864 18.2371 10.2998 17.2335 10.2998 15.9997C10.2998 14.7661 11.2864 13.7623 12.4995 13.7623H13.7007V20.6779C13.7007 21.4081 14.2826 22 15.0005 22V12.4402H12.4995C10.5668 12.4402 9 14.034 9 15.9997ZM18.5005 12.4408H18.0006V13.7629H18.5005C19.7132 13.7629 20.7002 14.7667 20.7002 16.0003C20.7002 17.2339 19.7132 18.2377 18.5005 18.2377H17.2987V11.3221C17.2987 10.5919 16.7168 10 15.9989 10V19.5598H18.5005C20.433 19.5598 22 17.9662 22 16.0003C22 14.0344 20.433 12.4408 18.5005 12.4408Z"
                    fill="white"
                />
            </svg>
        );

    if (hasColor) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    d="M16 25C20.9706 25 25 20.9706 25 16C25 11.0294 20.9706 7 16 7C11.0294 7 7 11.0294 7 16C7 20.9706 11.0294 25 16 25Z"
                    fill="#2CA01C"
                />
                <path
                    d="M9 15.9997C9 17.9656 10.5668 19.5592 12.4995 19.5592H12.9994V18.2371H12.4995C11.2864 18.2371 10.2998 17.2335 10.2998 15.9997C10.2998 14.7661 11.2864 13.7623 12.4995 13.7623H13.7007V20.6779C13.7007 21.4081 14.2826 22 15.0005 22V12.4402H12.4995C10.5668 12.4402 9 14.034 9 15.9997ZM18.5005 12.4408H18.0006V13.7629H18.5005C19.7132 13.7629 20.7002 14.7667 20.7002 16.0003C20.7002 17.2339 19.7132 18.2377 18.5005 18.2377H17.2987V11.3221C17.2987 10.5919 16.7168 10 15.9989 10V19.5598H18.5005C20.433 19.5598 22 17.9662 22 16.0003C22 14.0344 20.433 12.4408 18.5005 12.4408Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                fill="#2CA01C"
            />
            <path
                d="M2 7.99908C2 9.74651 3.36589 11.1631 5.05081 11.1631H5.48664V9.98788H5.05081C3.99331 9.98788 3.13316 9.09581 3.13316 7.99908C3.13316 6.90252 3.99331 6.01028 5.05081 6.01028H6.09803V12.1575C6.09803 12.8066 6.60533 13.3327 7.23118 13.3327V4.83507H5.05081C3.36589 4.83507 2 6.25182 2 7.99908ZM10.2825 4.83562H9.84669V6.01082H10.2825C11.3397 6.01082 12.2002 6.90307 12.2002 7.99962C12.2002 9.09617 11.3397 9.98842 10.2825 9.98842H9.23478V3.84122C9.23478 3.19214 8.72748 2.66602 8.10162 2.66602V11.1636H10.2825C11.9673 11.1636 13.3333 9.74705 13.3333 7.99962C13.3333 6.25219 11.9673 4.83562 10.2825 4.83562Z"
                fill="white"
            />
        </svg>
    );
}
