export default function IconAddonMaterial({ isGrey = false, isGreen }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.8834 12.9933C18.3861 12.9355 18 12.5128 18 12C18 11.4477 18.4477 11 19 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13V15H22.125L25.5978 22.1236C26.0818 23.1165 25.6693 24.3137 24.6764 24.7978C24.4034 24.9308 24.1037 25 23.8 25H17.2C16.0954 25 15.2 24.1046 15.2 23C15.2 22.6963 15.2692 22.3966 15.4023 22.1236L18.875 15H19V13L18.8834 12.9933Z"
                    fill="#1EAA5C"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8834 9.99327C11.3861 9.93551 11 9.51284 11 9C11 8.44772 11.4477 8 12 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10V13L20.5528 22.1056C21.0468 23.0935 20.6463 24.2949 19.6584 24.7889C19.3807 24.9277 19.0744 25 18.7639 25H9.23608C8.13151 25 7.23608 24.1046 7.23608 23C7.23608 22.6895 7.30837 22.3833 7.44723 22.1056L12 13V10L11.8834 9.99327Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.75 17H15.25L18.0438 21.47C18.3365 21.9383 18.1941 22.5553 17.7258 22.848C17.5668 22.9473 17.3832 23 17.1958 23H10.8043C10.252 23 9.80426 22.5523 9.80426 22C9.80426 21.8126 9.85693 21.6289 9.95626 21.47L12.75 17Z"
                    fill="#1EAA5C"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.8834 12.9933C18.3861 12.9355 18 12.5128 18 12C18 11.4477 18.4477 11 19 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13V15H22.125L25.5978 22.1236C26.0818 23.1165 25.6693 24.3137 24.6764 24.7978C24.4034 24.9308 24.1037 25 23.8 25H17.2C16.0954 25 15.2 24.1046 15.2 23C15.2 22.6963 15.2692 22.3966 15.4023 22.1236L18.875 15H19V13L18.8834 12.9933Z"
                    fill="#4D5054"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8834 9.99327C11.3861 9.93551 11 9.51284 11 9C11 8.44772 11.4477 8 12 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10V13L20.5528 22.1056C21.0468 23.0935 20.6463 24.2949 19.6584 24.7889C19.3807 24.9277 19.0744 25 18.7639 25H9.23608C8.13151 25 7.23608 24.1046 7.23608 23C7.23608 22.6895 7.30837 22.3833 7.44723 22.1056L12 13V10L11.8834 9.99327Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.75 17H15.25L18.0438 21.47C18.3365 21.9383 18.1941 22.5553 17.7258 22.848C17.5668 22.9473 17.3832 23 17.1958 23H10.8043C10.252 23 9.80426 22.5523 9.80426 22C9.80426 21.8126 9.85693 21.6289 9.95626 21.47L12.75 17Z"
                    fill="#4D5054"
                />{' '}
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.8834 12.9933C18.3861 12.9355 18 12.5128 18 12C18 11.4477 18.4477 11 19 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13V15H22.125L25.5978 22.1236C26.0818 23.1165 25.6693 24.3137 24.6764 24.7978C24.4034 24.9308 24.1037 25 23.8 25H17.2C16.0954 25 15.2 24.1046 15.2 23C15.2 22.6963 15.2692 22.3966 15.4023 22.1236L18.875 15H19V13L18.8834 12.9933Z"
                fill="#8d4afc"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.8834 9.99327C11.3861 9.93551 11 9.51284 11 9C11 8.44772 11.4477 8 12 8H16C16.5523 8 17 8.44772 17 9C17 9.55228 16.5523 10 16 10V13L20.5528 22.1056C21.0468 23.0935 20.6463 24.2949 19.6584 24.7889C19.3807 24.9277 19.0744 25 18.7639 25H9.23608C8.13151 25 7.23608 24.1046 7.23608 23C7.23608 22.6895 7.30837 22.3833 7.44723 22.1056L12 13V10L11.8834 9.99327Z"
                fill="#dbcbff"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.75 17H15.25L18.0438 21.47C18.3365 21.9383 18.1941 22.5553 17.7258 22.848C17.5668 22.9473 17.3832 23 17.1958 23H10.8043C10.252 23 9.80426 22.5523 9.80426 22C9.80426 21.8126 9.85693 21.6289 9.95626 21.47L12.75 17Z"
                fill="#8d4afc"
            />
        </svg>
    );
}
