import { reducer } from 'app/const/Reducer';
import classNames from 'classnames';
import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const IconMap = ({ onToggleMap = () => {} }) => {
    const { t } = useTranslation(['calendar']);
    const { openmap } = useSelector(({ map }) => map);
    const [state, dispatchState] = useReducer(reducer, { isChecked: !!openmap });
    const { isChecked } = state;

    const _handleChange = () => {
        onToggleMap(!openmap);
        dispatchState(({ isChecked }) => {
            return { isChecked: !isChecked };
        });
    };

    return (
        <div
            id="btn-map-toggle"
            className={classNames('v2-btn-default btn-mapbox cursor', { active: !!isChecked })}
            data-close-sms="false"
            onClick={_handleChange}
        >
            {t('calendar:map')}
            <span className={classNames('switch-icon ml-1', { 'has-toggle': !!isChecked })}>
                <span className="switch-icon__dots"></span>
            </span>
        </div>
    );
};

export default IconMap;
