import React from 'react';

export default function LoadingSchedule() {
    return (
        <div className="wrap-loading modal__container" style={{}}>
            <div className="list-schedules">
                <div className="sidebar-modal">
                    <div className="btn-modal">
                        <div className="loading v2-btn-default --grey --full pointer-events-none">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="list-schedules__boxs --box-group">
                        <div className="box-title">
                            <div className="items --group">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="box-title">
                            <div className="items --group">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="items --group">
                            <div className="checkbox" />
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="items --group">
                            <div className="checkbox" />
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="items --group">
                            <div className="checkbox" />
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
                <div className="list-schedules__boxs --box-schedule">
                    <div className="list-group group-md d-flex">
                        <div className="item-group bg-white">
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                        <div className="item-group bg-white ">
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                            <div className="items --group d-flex">
                                <div className="checkbox" />
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
