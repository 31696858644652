export default function IconNote({ isGrey, isGreen, isPortal = false, isSmallGrey = false, isSmallPurple = false }) {
    if (isGreen)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />{' '}
                <path
                    d="M7 11V14C7 15.1046 7.89543 16 9 16H23C24.1046 16 25 15.1046 25 14V11C25 9.89543 24.1046 9 23 9H9C7.89543 9 7 9.89543 7 11Z"
                    fill="#C0E5CF"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0779 12.0906L13.8441 21.614C13.8305 22.1661 14.2671 22.6247 14.8192 22.6383C15.0558 22.6441 15.2868 22.5658 15.4711 22.4173L17.1586 21.0579L19.0073 25.4129C19.0477 25.5081 19.1229 25.5779 19.2118 25.6139C19.3007 25.6498 19.4033 25.6518 19.4985 25.6113L21.7149 24.6706C21.8064 24.6317 21.8787 24.5582 21.9159 24.4661C21.9532 24.3739 21.9522 24.2708 21.9134 24.1793L20.0648 19.8243L22.215 19.5548C22.763 19.4861 23.1515 18.9861 23.0828 18.4381C23.0534 18.2033 22.9416 17.9865 22.7674 17.8264L15.7544 11.379C15.3478 11.0052 14.7152 11.0318 14.3414 11.4384C14.1775 11.6167 14.0838 11.8485 14.0779 12.0906Z"
                    fill="#1EAA5C"
                />{' '}
            </svg>
        );

    if (isGrey)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M7 11V14C7 15.1046 7.89543 16 9 16H23C24.1046 16 25 15.1046 25 14V11C25 9.89543 24.1046 9 23 9H9C7.89543 9 7 9.89543 7 11Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0779 12.0906L13.8441 21.614C13.8305 22.1661 14.2671 22.6247 14.8192 22.6383C15.0558 22.6441 15.2868 22.5658 15.4711 22.4173L17.1586 21.0579L19.0073 25.4129C19.0477 25.5081 19.1229 25.5779 19.2118 25.6139C19.3007 25.6498 19.4033 25.6518 19.4985 25.6113L21.7149 24.6706C21.8064 24.6317 21.8787 24.5582 21.9159 24.4661C21.9532 24.3739 21.9522 24.2708 21.9134 24.1793L20.0648 19.8243L22.215 19.5548C22.763 19.4861 23.1515 18.9861 23.0828 18.4381C23.0534 18.2033 22.9416 17.9865 22.7674 17.8264L15.7544 11.379C15.3478 11.0052 14.7152 11.0318 14.3414 11.4384C14.1775 11.6167 14.0838 11.8485 14.0779 12.0906Z"
                    fill="#4D5054"
                />
            </svg>
        );

    if (isPortal)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    d="M7 11V14C7 15.1046 7.89543 16 9 16H23C24.1046 16 25 15.1046 25 14V11C25 9.89543 24.1046 9 23 9H9C7.89543 9 7 9.89543 7 11Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.0779 12.0906L13.8441 21.614C13.8305 22.1661 14.2671 22.6247 14.8192 22.6383C15.0558 22.6441 15.2868 22.5658 15.4711 22.4173L17.1586 21.0579L19.0073 25.4129C19.0477 25.5081 19.1229 25.5779 19.2118 25.6139C19.3007 25.6498 19.4033 25.6518 19.4985 25.6113L21.7149 24.6706C21.8064 24.6317 21.8787 24.5582 21.9159 24.4661C21.9532 24.3739 21.9522 24.2708 21.9134 24.1793L20.0648 19.8243L22.215 19.5548C22.763 19.4861 23.1515 18.9861 23.0828 18.4381C23.0534 18.2033 22.9416 17.9865 22.7674 17.8264L15.7544 11.379C15.3478 11.0052 14.7152 11.0318 14.3414 11.4384C14.1775 11.6167 14.0838 11.8485 14.0779 12.0906Z"
                    fill="#4D5054"
                />
            </svg>
        );
    if (isSmallGrey)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 6V9C4 10.1046 4.89543 11 6 11H18C19.1046 11 20 10.1046 20 9V6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.237 8.26952L10.0521 16.1241C10.0391 16.6762 10.4762 17.1343 11.0283 17.1473C11.2717 17.153 11.5088 17.0698 11.6952 16.9132L12.8689 15.9269L14.4437 19.7971C14.4782 19.8817 14.5422 19.9438 14.618 19.9757C14.6937 20.0076 14.7811 20.0094 14.8622 19.9734L16.7505 19.1374C16.8284 19.1029 16.89 19.0375 16.9217 18.9557C16.9534 18.8738 16.9526 18.7821 16.9195 18.7008L15.3447 14.8307L16.9329 14.623C17.4805 14.5513 17.8664 14.0493 17.7948 13.5017C17.7652 13.276 17.6596 13.0672 17.4954 12.9097L11.9289 7.57131C11.5303 7.18904 10.8972 7.20228 10.515 7.60089C10.342 7.78122 10.2429 8.01974 10.237 8.26952Z"
                    fill="#7A83A6"
                />
            </svg>
        );
    if (isSmallPurple)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M4 6V9C4 10.1046 4.89543 11 6 11H18C19.1046 11 20 10.1046 20 9V6C20 4.89543 19.1046 4 18 4L6 4C4.89543 4 4 4.89543 4 6Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.237 8.26952L10.0521 16.1241C10.0391 16.6762 10.4762 17.1343 11.0283 17.1473C11.2717 17.153 11.5088 17.0698 11.6952 16.9132L12.8689 15.9269L14.4437 19.7971C14.4782 19.8817 14.5422 19.9438 14.618 19.9757C14.6937 20.0076 14.7811 20.0094 14.8622 19.9734L16.7505 19.1374C16.8284 19.1029 16.89 19.0375 16.9217 18.9557C16.9534 18.8738 16.9526 18.7821 16.9195 18.7008L15.3447 14.8307L16.9329 14.623C17.4805 14.5513 17.8664 14.0493 17.7948 13.5017C17.7652 13.276 17.6596 13.0672 17.4954 12.9097L11.9289 7.57131C11.5303 7.18904 10.8972 7.20228 10.515 7.60089C10.342 7.78122 10.2429 8.01974 10.237 8.26952Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FAFBFC"
            />
            <path
                d="M7 11V14C7 15.1046 7.89543 16 9 16H23C24.1046 16 25 15.1046 25 14V11C25 9.89543 24.1046 9 23 9H9C7.89543 9 7 9.89543 7 11Z"
                fill="#dac2f2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0779 12.0906L13.8441 21.614C13.8305 22.1661 14.2671 22.6247 14.8192 22.6383C15.0558 22.6441 15.2868 22.5658 15.4711 22.4173L17.1586 21.0579L19.0073 25.4129C19.0477 25.5081 19.1229 25.5779 19.2118 25.6139C19.3007 25.6498 19.4033 25.6518 19.4985 25.6113L21.7149 24.6706C21.8064 24.6317 21.8787 24.5582 21.9159 24.4661C21.9532 24.3739 21.9522 24.2708 21.9134 24.1793L20.0648 19.8243L22.215 19.5548C22.763 19.4861 23.1515 18.9861 23.0828 18.4381C23.0534 18.2033 22.9416 17.9865 22.7674 17.8264L15.7544 11.379C15.3478 11.0052 14.7152 11.0318 14.3414 11.4384C14.1775 11.6167 14.0838 11.8485 14.0779 12.0906Z"
                fill="#8c49fb"
            />
        </svg>
    );
}
