import { COLOR_TITLE } from 'app/modules/dashboard/const';
import { reduceNumberLengthByDigit, transformToCurrency } from 'common/utils/NumberUtils';
import { capitalizeFirstLetter } from 'common/utils/StringUtils';

export const hideDot = {
    point: {
        radius: 0
    }
};

export const tooltip = (currency = {}) => {
    return {
        enabled: true,
        mode: 'index',
        intersect: false,
        boxPadding: 3,
        callbacks: {
            label: ({ dataset, formattedValue }) => {
                return `${dataset?.label}: ${
                    currency?.symbol
                        ? transformToCurrency(parseFloat(formattedValue?.replace(/,/g, '')), currency)
                        : formattedValue
                }`;
            }
        }
    };
};

export const boxSize = {
    boxWidth: 10,
    boxHeight: 6
};

export const handleShowCursorLabelLegend = {
    onHover: ({ native }) => {
        native.target.style.cursor = 'pointer';
    },
    onLeave: ({ native }) => {
        native.target.style.cursor = 'default';
    }
};

export const optionsDefault = ({
    isShowLabel,
    title,
    subTitle,
    currency = {},
    step = null,
    onToggleLegendChart = () => {}
}) => {
    return {
        layout: {
            padding: {
                left: -23,
                right: 0,
                top: -23,
                bottom: 0
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
                title: {
                    display: true,
                    color: COLOR_TITLE
                },
                display: true,
                grid: {
                    display: false
                }
            },
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value) {
                        return `${currency.symbol}${value < 0 ? '-' : ''}${reduceNumberLengthByDigit(Math.abs(value))}`;
                    },
                    stepSize: step
                },
                title: {
                    display: true,
                    color: COLOR_TITLE
                },
                display: true,
                grid: {
                    drawBorder: false
                }
            }
        },
        plugins: {
            legend: isShowLabel && {
                display: true,
                position: 'top',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    ...boxSize,
                    padding: 23
                },
                ...handleShowCursorLabelLegend,
                onClick: (event, legendItem) => {
                    const chart = event.chart;
                    const datasetIndex = legendItem.datasetIndex;
                    const meta = chart.getDatasetMeta(datasetIndex);
                    meta.hidden = meta.hidden === null ? !chart.data.datasets[datasetIndex].hidden : null;
                    onToggleLegendChart(legendItem.text, !!meta.hidden);
                    chart.update();
                }
            },
            legendMargin: true,
            title: title && {
                display: true,
                text: title,
                position: 'top',
                align: 'start',
                font: {
                    size: 20
                }
            },
            subtitle: subTitle && {
                display: true,
                text: subTitle,
                position: 'top',
                align: 'start',
                padding: 10
            },
            tooltip: tooltip(currency)
        },
        elements: hideDot
    };
};

export const generateDataSet = ({ datasets, isFillColor, isShowLabel, ...props }) => {
    const commonOptions = {
        fill: !!isFillColor,
        fillFromStart: !!isFillColor,
        pointBorderColor: 'transparent',
        pointBorderWidth: 0,
        borderWidth: 2,
        ...props
    };

    if (Array.isArray(datasets)) {
        return datasets.map((data) => ({
            label: isShowLabel ? capitalizeFirstLetter(data.label) : '',
            data: data.amount_due,
            backgroundColor: data.color,
            ...commonOptions,
            borderColor: data.borderColor,
            pointBackgroundColor: data.borderColor
        }));
    }

    const finalDataset = datasets.amount_due;
    return [
        {
            label: isShowLabel ? capitalizeFirstLetter(datasets.label) : '',
            data: !!finalDataset && typeof finalDataset === 'object' ? Object.values(finalDataset) : [],
            backgroundColor: datasets.color,
            borderColor: datasets.borderColor,
            pointBackgroundColor: datasets.borderColor,
            ...commonOptions
        }
    ];
};

export const convertColorHexToRGBA = (colorHex, alpha = 1) => {
    colorHex = colorHex.replace('#', '');

    const r = parseInt(colorHex.slice(0, 2), 16);
    const g = parseInt(colorHex.slice(2, 4), 16);
    const b = parseInt(colorHex.slice(4, 6), 16);

    alpha = Math.min(1, Math.max(0, alpha));

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getOrCreateLegendList = (keyWidget = '') => {
    const legendContainer = document.getElementById(`htmlLegend_${keyWidget}`);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
        listContainer = document.createElement('ul');
        listContainer.style.display = 'flex';
        listContainer.style.flexDirection = 'row';
        listContainer.style.margin = 0;
        listContainer.style.padding = 0;

        legendContainer?.appendChild(listContainer);
    }

    return listContainer;
};

export const renderHtmlLegendPlugin = (keyWidget = '') => {
    return {
        id: `htmlLegend_${keyWidget}`,
        afterUpdate(chart) {
            const ul = getOrCreateLegendList(keyWidget);

            while (ul?.firstChild) {
                ul?.firstChild.remove();
            }

            ul.style.marginLeft = 0;
            ul.style.maxWidth = '100%';
            ul.style.maxHeight = '75px';
            ul.style.overflow = 'auto';
            ul.style.pointerEvents = 'auto';
            ul.style.flexWrap = 'wrap';
            ul.style.gap = '7px';

            const items = chart?.options?.plugins?.legend?.labels?.generateLabels(chart);

            items?.forEach((item) => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    chart.update();
                };

                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle.replace('0.5', '0.8');
                boxSpan.style.borderColor = '#fff';
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.flexShrink = 0;
                boxSpan.style.height = '8px';
                boxSpan.style.marginRight = '5px';
                boxSpan.style.width = '8px';
                boxSpan.style.borderRadius = '50%';

                const textContainer = document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = 0;
                textContainer.style.padding = 0;
                textContainer.style.width = '100%';
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        }
    };
};
