import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { COMMON, MAX_UPLOAD_FILE_SIZE } from 'app/const/App';
import { clientQuery } from 'common/utils/ApiUtils';
import { imageType, uploadToS3 } from 'common/utils/FileUtils';
import { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

const ServiceUploadLogo = ({ typePresign = '' }, ref) => {
    const { t } = useTranslation();
    useImperativeHandle(ref, () => ({
        onUpFile: _handleUpFile,
        onUploadS3: _handleUploadS3
    }));

    const _handleUpFile = (e) => {
        return new Promise((resolve) => {
            if (e.target.files.length < 1) return false;
            const imgSelected = e.target.files[0];
            const fileName = imgSelected.name;
            if (!imageType.includes(imgSelected.type)) {
                resolve({ success: false, message: t('setting:invalid_upload_image', { fileName }) });
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(imgSelected);
            if (imgSelected.size > MAX_UPLOAD_FILE_SIZE) {
                resolve({ success: false, message: t('setting:just_support_img_2mb', { fileName }) });
                return;
            }
            if (!imgSelected) {
                resolve({ success: false, message: t('setting:invalid_upload_image', { fileName }) });
                return;
            }

            reader.onloadend = function () {
                const params = {
                    name: fileName,
                    imgSelected: imgSelected,
                    url: reader.result,
                    item_id: imgSelected.lastModified,
                    type: typePresign || COMMON.LOGO
                };

                resolve({ success: true, data: params });
            };
        });
    };

    const _handleUploadS3 = ({ file }) => {
        return new Promise((resolve) => {
            const optionQuery = {
                data: { name: `web-${file.name}`, item_id: file.item_id, type: file.type },
                method: 'POST'
            };

            const _addSuccess = (response) => {
                const idAttach = `${file.name}${new Date().getTime()}`;
                const { presigned_url, object_key, object_tag, thumbnail_url } = response.data;
                const optionsHeaders = { 'x-amz-tagging': object_tag };

                const _handleSuccess = () => {
                    resolve({ object_key, object_tag, thumbnail_url });
                };

                uploadToS3(
                    presigned_url,
                    optionsHeaders,
                    file.imgSelected,
                    {
                        idAttach,
                        object_key,
                        object_tag
                    },
                    _handleSuccess,
                    resolve
                );
            };

            clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, resolve);
        });
    };
};

export default forwardRef(ServiceUploadLogo);
