import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import {
    ACCESS_CUSTOMER_OPP,
    ACCESS_PIPELINE_REPORT,
    ACCESS_SALES_FUNNEL,
    ACCESS_STATUS_CHANGES
} from 'app/const/Permissions';

export const getGridColumns = () => {
    const columns = [
        {
            id: 'user',
            title: 'user',
            status: true
        },
        {
            id: 'role',
            title: 'account',
            status: true
        },
        {
            id: 'edit_opp',
            title: 'edit_opp',
            status: true,
            keyGetValue: 'permission'
        },
        {
            id: 'pipeline_report',
            title: 'pipeline_report',
            status: true,
            keyGetValue: 'permission'
        },
        {
            id: 'sale_funnel',
            title: 'sale_funnel',
            status: true,
            keyGetValue: 'permission'
        },
        {
            id: 'status_change',
            title: 'status_change',
            status: true,
            keyGetValue: 'permission'
        }
    ];

    const header = {
        user: {
            title: 'user',
            style: 'col col-lg'
        },
        role: {
            title: 'role',
            style: 'col col-role'
        },
        edit_opp: {
            title: 'edit_opp',
            style: 'col col-sm'
        },
        pipeline_report: {
            title: 'pipeline_report',
            style: 'col col-sm'
        },
        sale_funnel: {
            title: 'sale_funnel',
            style: 'col col-sm'
        },
        status_change: {
            title: 'status_change',
            style: 'col col-sm'
        }
    };

    const contentConfig = {
        user: {
            id: 'user',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.USER_HAS_AVT
        },
        role: {
            id: 'role',
            style: 'col col-role',
            type: LIST_TABLE_ITEM_TYPE.ROLE_USER
        },
        edit_opp: {
            id: ACCESS_CUSTOMER_OPP,
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
        },
        pipeline_report: {
            id: ACCESS_PIPELINE_REPORT,
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
        },
        sale_funnel: {
            id: ACCESS_SALES_FUNNEL,
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
        },
        status_change: {
            id: ACCESS_STATUS_CHANGES,
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
        }
    };

    return {
        header,
        columns,
        contentConfig
    };
};
