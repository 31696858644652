import { INBOX } from 'app/config/routes';
import { CUSTOMER_UPDATE_TASK_STATUS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import IconArrow from 'assets/icon/IconArrow';
import classNames from 'classnames';
import { updateTaskStatus } from 'common/redux/actions/calendar/listTaskAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TaskList from './TaskList';
import { addBranchPath } from 'app/const/Branch';

const TasksSchedule = forwardRef((props, ref) => {
    const { t } = useTranslation(['common']);
    const dispatch = useDispatch();
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, tasks: [], customer: null });
    const { tasks } = state;
    const dateFormat = useSelector(({ auth }) => auth.user.company.date_format);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const formatDisplay = convertPhpFormatToMoment(dateFormat);

    useImperativeHandle(ref, () => ({ _openTask, _updateRealtime }));

    const _openTask = (data) => {
        dispatchState({ isOpen: true, ...data });
    };

    const _closeTask = () => {
        dispatchState({ isOpen: false, isDone: false });
    };

    /**
     * It updates the state of the component based on the type of the data received from the server
     * @param data - The data that is passed from the server.
     * @returns A function that takes in a data object and updates the state of the tasks array.
     */
    const _updateRealtime = (data) => {
        // eslint-disable-next-line no-undef
        if (!state.isOpen || data.socket_id === global.socket_id_task_service) return;
        let result = [...tasks];

        switch (data.type) {
            case 'add':
                result.push(data.task);
                break;
            case 'delete':
                result = result.filter((item) => !data.task.includes(item.id));
                break;
            case 'update':
                const isUpdate = data.task.schedules.some((el) => el.id === state?.customer?.schedule?.id);
                const newDate = moment(data.task.date).utc().unix();
                const inRangeDate =
                    moment.utc(start).startOf('day').unix() <= newDate &&
                    newDate <= moment.utc(end).subtract(1, 'd').endOf('day').unix();

                if (data.task.assignee.id !== state.customer.id || !inRangeDate || !isUpdate) {
                    result = result.filter((item) => item.id !== data.task.id);
                } else {
                    const indexUpdate = result.findIndex((item) => item.id === data.task.id);
                    if (indexUpdate !== -1) {
                        result = result.map((item) => {
                            if (item.id === data.task.id) return { ...item, ...data.task };
                            return item;
                        });
                    } else {
                        result.push(data.task);
                    }
                }

                break;
            case 'done':
                result = result.map((item) => {
                    const dataUpdate = data.tasksDone.find((el) => el.id === item.id);
                    if (dataUpdate) return { ...item, ...dataUpdate, active: dataUpdate.active };
                    return item;
                });
                break;
            default:
                break;
        }

        const newState = { tasks: result };
        newState['isOpen'] = !!result.length;
        dispatchState(newState);
    };

    const _updateStatusTask = (dataTask = {}, active) => {
        const idTask = dataTask.id;
        /* Updating the task status to done. */
        clientQuery(CUSTOMER_UPDATE_TASK_STATUS, {
            method: 'PUT',
            // eslint-disable-next-line no-undef
            data: { active, ids: [idTask], socket_id: global.socket_id_task_service }
        });
        dispatch(updateTaskStatus({ ...dataTask, active, typeAction: 'status' }));
    };

    const _handleUpdateTask = (dataUpdate) => {
        const newDate = moment(dataUpdate.dateData).utc().unix();
        const inRangeDate =
            moment.utc(start).startOf('day').unix() <= newDate &&
            newDate <= moment.utc(end).subtract(1, 'd').endOf('day').unix();

        if (dataUpdate.assignee.id !== state.customer.id) _removeTask(dataUpdate.id);
        if (!inRangeDate) _removeTask(dataUpdate.id);
        dispatch(updateTaskStatus({ ...dataUpdate, typeAction: 'update' }));
    };

    const _handleDeleteTask = (dataDelete) => {
        _removeTask(dataDelete.id);
        dispatch(updateTaskStatus({ ...dataDelete, typeAction: 'delete' }));
    };

    const _removeTask = (idDelete) => {
        if (!idDelete) return;
        const newTasks = [...tasks].filter((item) => item.id.toString() !== idDelete.toString());
        dispatchState({ tasks: newTasks, isOpen: !!newTasks.length });
    };

    return (
        <div className={classNames('modal container-modal view-task-popup', { open: state.isOpen })}>
            <div onClick={_closeTask} className="modal__overlay bg-transparent" />
            {state.isOpen && (
                <div className="modal__container tasks-list">
                    <div className="task-popup-content">
                        <div className="header bg-transparent">
                            <h5 className="header__title">
                                {tasks.length} {tasks.length > 2 ? t('common:task') : t('common:task_')}
                            </h5>
                            {state.startRange && (
                                <span className="header__time">
                                    {moment(state.startRange).utc().format(formatDisplay)}
                                    {!state.isDayView &&
                                        ` - ${moment(state.endRange).utc().subtract(1, 'd').format(formatDisplay)}`}
                                </span>
                            )}
                        </div>

                        <div className="popup-content scrolls">
                            <TaskList
                                data={tasks}
                                onMarkDone={_updateStatusTask}
                                onUpdate={_handleUpdateTask}
                                onDelete={_handleDeleteTask}
                            />
                        </div>

                        <div className="footer">
                            <Link to={addBranchPath(INBOX)} className="footer__link">
                                {t('common:view_all_tasks_in_your_inbox')}
                                <IconArrow />
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
});

TasksSchedule.propTypes = {};
TasksSchedule.displayName = 'TasksScheduleModal';

export default TasksSchedule;
