import GdTags from 'app/components/tags';
import { GOOGLE_PLACES_API_URL } from 'app/const/Keys';
import { reducer } from 'app/const/Reducer';
import PropTypes from 'prop-types';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AddCustomerContext } from '../../contexts';
import { ADD_CUSTOMER_CONTEXT_TYPES } from '../../contexts/types';
import BoxRows from '../BoxRows';
import AddressBilling from './AddressBilling';
import AddressService from './AddressService';
import BillingEmail from './BillingEmail';
import ManageUnits from './ManageUnits';
import { useTranslation } from 'react-i18next';
import { TYPE_TAGS } from 'app/const/Customers';
import { CUSTOMER_STATUS } from 'app/modules/customer/const';

const BoxAddress = ({ isFastForm = false, hideSame = false, onExistCustomer = () => {}, defaultStatus = 1 }, ref) => {
    const { t } = useTranslation(['customers']);
    const { service_location, address_to, updateContext, additional_contacts, profile } =
        useContext(AddCustomerContext);
    const [state, dispatchState] = useReducer(reducer, { isLoadedMap: false });
    const { isLoadedMap } = state;

    const refFormLocation = useRef(false);
    const currentForm = refFormLocation.current;
    const refTags = useRef(null);
    const refLocationNote = useRef(null);
    const refAddressService = useRef(null);
    const refAddressBilling = useRef(null);
    const refBillingEmail = useRef(null);
    const refMDU = useRef(null);
    const GOOGLE_MAPS_CLIENT_KEY = useSelector(({ auth }) => auth.user.settings.google.geo_key);

    const isSame = service_location.same_billing_location;

    useImperativeHandle(ref, () => ({ getValue: _handleGetValue }));

    const _handleGetValue = () => {
        if (isFastForm) return { ...refAddressService.current.getValue(), ...refAddressBilling.current.getValue() };

        return {
            location_note: refLocationNote.current.value,
            tags: refTags.current.getValue().map((item) => item?.name),
            billing_email: refBillingEmail.current.getValue(),
            mdu: refMDU?.current?.getValue() || {},
            same_billing_location: isSame,
            ...refAddressService.current.getValue(),
            ...refAddressBilling.current.getValue()
        };
    };

    useEffect(() => {
        !isLoadedMap && loadGoogleMapScript(() => dispatchState({ isLoadedMap }));
    }, [isLoadedMap]);

    // load google map script
    const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
            callback();
        } else {
            if (!window?.google?.maps) {
                const googleMapScript = document.createElement('script');
                googleMapScript.src = GOOGLE_PLACES_API_URL(GOOGLE_MAPS_CLIENT_KEY);
                window.document.body.appendChild(googleMapScript);
                googleMapScript.addEventListener('load', callback);
            }
        }
    };

    const _handleChange = (e) => {
        const checked = e.target.checked;
        const elements = currentForm.elements;

        if (checked) {
            const result = {};
            for (let i = 0, element; (element = elements[i++]); ) {
                const [type, key] = element.name.split('_');
                if (type === 'services') result[key] = element.value;
            }

            updateContext(
                { billing_address: result, same_billing_location: true, billing_to: address_to },
                ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION
            );
            return;
        }
        updateContext({ same_billing_location: checked }, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION);
    };

    const _handleServiceChange = (e, name) => {
        if (isSame && name === 'services') {
            const nameInput = e.target.name;
            const key = nameInput.split('_')[1];
            const result = {
                service_address: {
                    ...service_location.service_address,
                    [key]: currentForm[nameInput].value
                },
                billing_address: {
                    ...service_location.billing_address,
                    [key]: currentForm[nameInput].value
                }
            };

            updateContext(result, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION);
        }
    };

    const _handleAddressAutoComplete = (type, params, isStreet2 = false) => {
        const isTypeServices = type === 'services';
        if (isSame) {
            /* It's checking if the address is the same as the service address. */
            if (!isTypeServices) return;
            const result = {
                service_address: { ...service_location.service_address, street2: params['name'] },
                billing_address: { ...service_location.billing_address, street2: params['name'] }
            };

            if (!isStreet2) {
                const street2 = currentForm['services_street2'].value;
                const newParams = { street2, street1: params['name'], ...params };
                result['service_address'] = { ...service_location.service_address, ...newParams };
                result['billing_address'] = { ...service_location.billing_address, ...newParams };
            }

            updateContext(result, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION);
        } else {
            if (isTypeServices) {
                let servicesUpdate = {
                    ...service_location.service_address,
                    street2: currentForm['services_street2'].value,
                    street1: params['name'],
                    ...params
                };
                if (isStreet2) servicesUpdate = { ...service_location.service_address, street2: params['name'] };
                updateContext(servicesUpdate, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_ADDRESS);
            } else {
                let billingUpdate = {
                    ...service_location.billing_address,
                    street1: params['name'],
                    ...params
                };
                if (isStreet2) billingUpdate = { ...service_location.billing_address, street2: params['name'] };
                updateContext(billingUpdate, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_BILLING_ADDRESS);
            }
        }
    };

    const _handleServiceBlur = (data) => {
        updateContext({ ...data }, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_ADDRESS);
    };

    const _handleBillingBlur = (data) => {
        updateContext({ ...data }, ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_BILLING_ADDRESS);
    };

    return (
        <form ref={refFormLocation} className="box-has-rows --box-location">
            <AddressService
                ref={refAddressService}
                onChange={_handleServiceChange}
                onBlur={_handleServiceBlur}
                defaultValue={service_location.service_address}
                onExistCustomer={onExistCustomer}
                onAutoComplete={_handleAddressAutoComplete}
                defaultStatus={defaultStatus}
            />
            <BoxRows
                label={t('customers:billing_address')}
                fieldClass="--has-toggle"
                isRequired={defaultStatus !== CUSTOMER_STATUS[2].type}
            >
                <div className="switch large">
                    <input
                        id="same_address"
                        className="toggle toggle-round"
                        type="checkbox"
                        checked={isSame}
                        onChange={_handleChange}
                    />
                    <label htmlFor="same_address">
                        <span className="same" />
                    </label>
                </div>
            </BoxRows>
            <AddressBilling
                ref={refAddressBilling}
                isSame={isSame}
                isFastForm={isFastForm}
                hideSame={hideSame}
                defaultValue={service_location.billing_address}
                onBlur={_handleBillingBlur}
                onAutoComplete={_handleAddressAutoComplete}
            />
            {!isFastForm && (
                <>
                    <BoxRows label={t('customers:billing_email')} fieldClass="has-many-field has-new-field">
                        <BillingEmail ref={refBillingEmail} />
                    </BoxRows>
                    <BoxRows label={t('customers:location_note')} fieldClass="has-many-field has-new-field ">
                        <textarea
                            ref={refLocationNote}
                            name="location_note"
                            className="field-textarea --no-resize --h-120"
                            cols={30}
                            rows={10}
                            defaultValue={''}
                        />
                    </BoxRows>
                    <ManageUnits ref={refMDU} additionalContacts={[profile, ...additional_contacts]} />
                    <BoxRows label={t('customers:location_tags')} rowClass="--location-tag">
                        <GdTags ref={refTags} id="add-customer-location-tags" type={TYPE_TAGS.TAG_CUSTOMER} />
                    </BoxRows>
                </>
            )}
        </form>
    );
};

BoxAddress.displayName = 'AddCustomerBoxAddress';
BoxAddress.propType = { onExistCustomer: PropTypes.func };
export default forwardRef(BoxAddress);
