import { call } from "redux-saga/effects";
import {
  fetchWithToken
} from "common/utils/ApiUtils";
import {
  DRIP_GET_LIST_UNSUBSCRIBED,
} from "app/const/Api";

export function* getListUnsubscribed({ params, actionSuccess, actionFailed }) {
  try {
    const response = yield call(
      fetchWithToken,
      DRIP_GET_LIST_UNSUBSCRIBED,
      params
    );
    if (response && response.success) {
      actionSuccess(response);
    } else {
      actionFailed(response);
    }
  } catch (error) {
    actionFailed(error);
  }
}
