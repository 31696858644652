import classNames from 'classnames';
import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import { LIST_STATUS_PIPELINES } from 'app/const/setting/SettingPipelines';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';

const DropdownStatusPipelines = ({ defaultValue = '', isDisable = false, isButton = false }, ref) => {
    const { t } = useTranslation('setting');
    const refDropdown = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        selected:
            LIST_STATUS_PIPELINES.find((item) => item.status === defaultValue?.toLowerCase())?.id ||
            LIST_STATUS_PIPELINES[0]['id']
    });
    const { selected: finalSelected } = state;

    useImperativeHandle(ref, () => ({
        getValue: () => finalSelected
    }));

    const _renderButtonOption = () => {
        const { status } = LIST_STATUS_PIPELINES.find(({ id }) => id === finalSelected) || {};
        return (
            <Fragment>
                <div className="d-flex align-center">
                    <span className={`dots --${status}`} />
                    <span className="txt-ellipsis">{t(status)}</span>
                </div>
                <div className="arrow">
                    <IconDropUpDown />
                </div>
            </Fragment>
        );
    };

    const _renderOptions = () => {
        return LIST_STATUS_PIPELINES.map(({ id, status }) => {
            const _handleSelectItem = () => {
                dispatchState({ selected: id });
                refDropdown.current?._closeDropdown();
            };

            return (
                <li
                    key={id}
                    className={classNames('items', { active: id === finalSelected })}
                    onClick={_handleSelectItem}
                >
                    <span className={`dots --${status}`} />
                    {t(status)}
                </li>
            );
        });
    };

    if (isButton) {
        return (
            <div className={classNames('v2-dropdown select-status', { 'is-disable': isDisable })}>
                <div className="dropbtn items selection">{_renderButtonOption()}</div>
            </div>
        );
    }

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="dropdown_pipelines"
            disable={isDisable}
            wrapperClassName="v2-dropdown select-status"
            buttonClassName="dropbtn items selection"
            customDropButton={_renderButtonOption}
            wrapperListClass="v2-dropdown__menu scrolls content-full"
        >
            <ul>{_renderOptions()}</ul>
        </CalendarDropdown>
    );
};

export default forwardRef(DropdownStatusPipelines);
