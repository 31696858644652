import React from 'react';

const LoadingSessionList = () => {
    return (
        <div className="wrapbox-user__frame wrap-sessions wrap-loading">
            <div className="wrap-sessions__header">
                <div className="loading --animation --line --onefourth"></div>
            </div>
            <div className="wrap-sessions__list">
                <div className="device-items">
                    <div className="device-items__icon flex-centeritem"></div>
                    <div className="device-items__info flex-1 my-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="loading --animation --line --half mt-2"></div>
                    </div>
                    <div className="v2-btn-default loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="device-items">
                    <div className="device-items__icon flex-centeritem"></div>
                    <div className="device-items__info flex-1 my-1">
                        <div className="loading --animation --line --threefourth"></div>
                        <div className="loading --animation --line --half mt-2"></div>
                    </div>
                    <div className="v2-btn-default loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="wrap-sessions__link">
                <div className="v2-btn-default loading --grey btn-md justify-center">
                    <div className="loading --animation --line --threefourth"></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingSessionList;
