import classNames from 'classnames';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_BLOCKED_NUMBERS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import { ADDONS_FOOTER_OPTIONS, SMS_TEXT_MESSAGING, VOIP } from 'app/const/addons';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { checkIsEnableVoip } from 'common/utils/PermissionUtils';

const AddonsOptionsFooterBlockedNumbers = ({ keyword, count }) => {
    const { t } = useTranslation(['addons']);
    const settings = useSelector(({ auth }) => auth.user.settings);
    const isActiveSMS = !!settings?.addons?.smsplivo;
    const isHaveVoip = checkIsEnableVoip(settings);
    const isSmsBlockedNumbers = keyword === ADDONS_FOOTER_OPTIONS.SMS_BLOCKED_NUMBERS;

    return (
        <div className="boxs__footer --multi">
            <p className="grey-extrlight txt-ellipsis">
                {count > 1 ? t('total_numbers', { total: count }) : t('total_number', { total: count })}
            </p>
            <Link
                to={{
                    pathname: addBranchPath(ADDONS_BLOCKED_NUMBERS),
                    state: { key: isSmsBlockedNumbers ? SMS_TEXT_MESSAGING : VOIP }
                }}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': isSmsBlockedNumbers ? !isActiveSMS : !isHaveVoip
                })}
            >
                {t('addons:manage')} <IconCogWheel />
            </Link>
        </div>
    );
};

export default AddonsOptionsFooterBlockedNumbers;
