import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { TYPE_OPEN_SMS } from '../Sms';

const getGirdColumnsCustomerContacts = ({ customerId = '' }) => {
    const header = {
        customer: { title: 'customer', style: 'col --info --hascol-md' },
        title: { title: 'title', style: 'col' },
        email: { title: 'email', style: 'col --lg --full' },
        phones: { title: 'phone', style: 'col --lg --full' }
    };
    const columns = [
        { id: 'customer', status: true },
        { id: 'title', status: true },
        { id: 'email', status: true },
        { id: 'phones', status: true }
    ];
    const contentConfig = {
        customer: {
            style: 'col --info --hascol-md --name',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO,
            urlConfig: { isUseOnClickFn: true }
        },
        title: { style: 'col', additionalStyle: 'label-content', type: LIST_TABLE_ITEM_TYPE.DEFAULT },
        email: {
            style: 'col --lg --full',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        phones: {
            style: 'col --lg --full',
            type: LIST_TABLE_ITEM_TYPE.PHONE,
            typeOpen: TYPE_OPEN_SMS.CUSTOMER,
            isUsePopup: true,
            customerId
        }
    };
    return { header, columns, contentConfig };
};

export { getGirdColumnsCustomerContacts };
