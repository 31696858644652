import IconNote from 'assets/icon/IconNote';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const SelectText = ({ icon = null, isTextarea = false, defaultValue = '', ...props }, ref) => {
    const refValue = useRef(null);

    useImperativeHandle(ref, () => ({ getValue: () => refValue.current.value }));

    return (
        <div className="wrap-rows flextop border-bottom-line">
            <div className="wrap-rows__icons flex-auto">{icon || <IconNote />}</div>
            <div className="wrap-rows__details flex-1">
                {isTextarea ? (
                    <TextareaAutosize ref={refValue} className="field-control" defaultValue={defaultValue} {...props} />
                ) : (
                    <input
                        ref={refValue}
                        className="field-control"
                        type="text"
                        defaultValue={defaultValue}
                        {...props}
                    />
                )}
            </div>
        </div>
    );
};

export default forwardRef(SelectText);
