import React, { forwardRef, useReducer, useRef, useImperativeHandle } from 'react';
import EmailTo from 'app/modules/jobdetail/tabs/settingmessaging/email';
import SMSTo from 'app/modules/jobdetail/tabs/settingmessaging/sms';
import { getCustomerContact } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { useSelector } from 'react-redux';
import { checkAddon } from 'common/utils/AddonUtils';
import { reducer } from 'app/const/Reducer';

const CustomerInfo = forwardRef(({ settings, customerId }, ref) => {
    const isFirstTimeFetch = useRef(true);
    const refEmail = useRef(null);
    const refSms = useRef(null);
    const addonsList = useSelector((state) => state.auth.user?.settings?.addons || {});

    const [state, dispatchState] = useReducer(reducer, {
        emails: [],
        phones: []
    });

    function _getValue() {
        return {
            email: refEmail.current.getValue(),
            sms: refSms.current?.getValue() || []
        };
    }

    useImperativeHandle(ref, () => ({
        getValue: _getValue
    }));

    function _getCustomerInfo() {
        const optionsQuery = { method: 'GET', data: { inc: 'phones', fields: 'name' } };
        clientQuery(getCustomerContact(customerId), optionsQuery, _getCustomerInfoSuccess);
    }

    function _getCustomerInfoSuccess(response) {
        isFirstTimeFetch.current = false;

        dispatchState({
            emails: [...[{ id: '', email: 'None' }], ...response.data.emails],
            phones: [...[{ id: '', phone: 'None' }], ...response.data.phones]
        });
    }

    function _openOptions() {
        _getCustomerInfo();
    }

    return (
        <div className="boxs boxs-modified">
            {checkAddon(addonsList.activate_sms) && (
                <SMSTo
                    ref={refSms}
                    selected={settings.sms_to}
                    data={state.phones}
                    openOptions={_openOptions}
                    isFirstTimeFetch={isFirstTimeFetch.current}
                />
            )}
            <EmailTo
                ref={refEmail}
                openOptions={_openOptions}
                isFirstTimeFetch={isFirstTimeFetch.current}
                selected={settings.email_to || []}
                data={state.emails}
            />
        </div>
    );
});

export default CustomerInfo;
