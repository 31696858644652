import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { LIST_SETTING_ACCESS_GROWTH } from 'app/const/Settings';
import { SETTING_MENU_OPTIONS } from 'app/const/StaticLinks';
import classNames from 'classnames';
import { getToggleLocal } from 'common/utils/FunctionUtils';
import { getPlanUser, isNotPermissionValue } from 'common/utils/PermissionUtils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

function SideMenu({ permissions = {} }) {
    const { t } = useTranslation(['setting']);
    const location = useLocation();
    const userProfile = useSelector(({ auth }) => auth.user.profile);

    const { isGrowthPlan, isTrial } = getPlanUser(userProfile);

    const ItemMenuTitle = ({ item }) => {
        return <li className={item.style}>{t(`setting:${item.value}`)}</li>;
    };

    const ItemMenuLink = ({ item }) => {
        return (
            <li>
                <Link
                    to={addBranchPath(item.link)}
                    className={classNames(item.class, {
                        active: matchPath(location.pathname, {
                            path: item.subsLink?.map((sub) => addPrefixPath(sub)) || addPrefixPath(item.link),
                            exact: true
                        })
                    })}
                >
                    {item.icon}
                    <span className="txt-ellipsis">{t(`setting:${item.value}`)}</span>
                </Link>
            </li>
        );
    };

    const renderMenu = (list) => {
        return list.map((item) => {
            const { link, id, value } = item;
            const notPermissionOpps = LIST_SETTING_ACCESS_GROWTH.includes(value) && !isGrowthPlan && !isTrial;
            if (isNotPermissionValue(permissions, value) || notPermissionOpps) return null;
            return link ? <ItemMenuLink key={id} item={item} /> : <ItemMenuTitle key={id} item={item} />;
        });
    };

    return (
        <div
            className={classNames('sidebar-menu sidebar-pages scrolls py-4', {
                'is-hide-sidebar': !getToggleLocal('toggledSettings')
            })}
        >
            <ul>{renderMenu(SETTING_MENU_OPTIONS)}</ul>
        </div>
    );
}

export default SideMenu;
