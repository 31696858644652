import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { ADD_CUSTOM_FIELDS, EDIT_CUSTOM_FIELDS } from 'app/const/Permissions';

const HEADER_GRID = {
    user: { title: 'user', style: 'col col-lg' },
    role: { title: 'role', style: 'col col-role' },
    add_custom_field: { title: 'add', style: 'col col-sm' },
    edit_custom_field: { title: 'edit', style: 'col col-sm' }
};
const COLUMNS_GRID = [
    { id: 'user', title: 'user', status: true },
    { id: 'role', title: 'account', status: true },
    { id: 'add_custom_field', title: 'add', status: true, keyGetValue: 'permission' },
    { id: 'edit_custom_field', title: 'edit', status: true, keyGetValue: 'permission' }
];
const CONTENT_GRID = {
    user: { id: 'user', style: 'col col-lg', type: LIST_TABLE_ITEM_TYPE.USER_HAS_AVT },
    role: { id: 'role', style: 'col col-role', type: LIST_TABLE_ITEM_TYPE.ROLE_USER },
    add_custom_field: {
        id: ADD_CUSTOM_FIELDS,
        style: 'col col-sm',
        type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
    },
    edit_custom_field: {
        id: EDIT_CUSTOM_FIELDS,
        style: 'col col-sm',
        type: LIST_TABLE_ITEM_TYPE.SWITCH_CHECKBOX_OPP_PERMISSION
    }
};
export const GRID_COLUMNS = { header: HEADER_GRID, columns: COLUMNS_GRID, contentConfig: CONTENT_GRID };
