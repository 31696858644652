import classNames from 'classnames';
import React, { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { SMART_VIEW } from 'app/config/routes';
import BookMark from 'app/modules/calendar/components/bookmark';
import 'app/modules/inbox/css/_inbox.scss';
import { InboxLayout } from './InboxLayout';
import { addPrefixPath } from 'app/const/Branch';

const EmailInbox = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('inbox-page', 'container-main');
        return () => {
            mainPageDiv.classList.remove('inbox-page', 'container-main');
        };
    }, []);

    return (
        <div
            className={classNames('container-inbox container-wrap dashboard-wrapper --main grid-right-side', {
                'container-print': matchPath(pathname, { path: addPrefixPath(SMART_VIEW) })
            })}
        >
            <BookMark />
            <InboxLayout />
        </div>
    );
};

export default EmailInbox;
