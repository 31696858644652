import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';
function RealtimeServices({ jobData, onUpdate = () => {}, onClose }) {
    const {
        jobStatus: statusResponse,
        jobDelete: deleteResponse,
        invoiceStatus: invoiceStatusResponse,
        estimateStatus: estimateStatusResponse
    } = useContext(SocketContext);

    useEffect(() => {
        _setSocketId(`${Date.now().toString()}_job_detail`);
    }, []);

    useEffect(() => {
        statusResponse && _updateRealTimeStatus(statusResponse);
        deleteResponse && _jobDelete(deleteResponse);
    }, [statusResponse, deleteResponse]);

    useEffect(() => {
        if (invoiceStatusResponse) {
            const parenInvoiceData = jobData?.invoice || {};
            const { status: oldStatus, id = '' } = parenInvoiceData;

            const { status: newStatus, id: invoiceRealTimeId = '' } = invoiceStatusResponse;
            if (oldStatus !== newStatus && invoiceRealTimeId.toString() === id.toString()) {
                onUpdate({
                    ...jobData,
                    invoice: {
                        ...jobData.invoice,
                        status: newStatus
                    }
                });
            }
        }
    }, [invoiceStatusResponse]);

    useEffect(() => {
        if (estimateStatusResponse) {
            const parenEstimateData = jobData?.estimate || {};
            const { status: oldStatus, id = '' } = parenEstimateData;

            const { status: newStatus, id: estimateRealTimeId = '' } = estimateStatusResponse;
            if (oldStatus !== newStatus && estimateRealTimeId.toString() === id.toString()) {
                onUpdate({
                    ...jobData,
                    estimate: {
                        ...jobData.estimate,
                        status: newStatus
                    }
                });
            }
        }
    }, [estimateStatusResponse]);

    function _setSocketId(socketId) {
        // eslint-disable-next-line no-undef
        global.socketIdJobDetail = socketId;
    }

    function _updateRealTimeStatus(statusResponse) {
        if (parseInt(statusResponse.jobId) === parseInt(jobData?.job?.id)) {
            const newJobDetail = {
                ...jobData,
                job: {
                    ...jobData.job,
                    status: parseInt(statusResponse.status)
                }
            };
            onUpdate(newJobDetail);
        }
    }

    function _jobDelete(jobDelete) {
        const { jobs: jobDeleted, socket_id: responseSocketId } = jobDelete;

        // eslint-disable-next-line no-undef
        const soketJobDetail = global.socketIdJobDetail || '';
        const currentJobId = jobData?.job?.id;
        let isJobDetail = false;

        if (responseSocketId === soketJobDetail) {
            return false;
        }

        jobDeleted.forEach((item) => {
            if (currentJobId?.toString() === item.id?.toString()) {
                isJobDetail = true;
            }
        });

        if (isJobDetail) {
            onClose();
        }
    }

    return null;
}

RealtimeServices.propTypes = { events: PropTypes.arrayOf(PropTypes.shape({})), onUpdateRealtime: PropTypes.func };

export default RealtimeServices;
