import React from 'react';
import Filter from 'app/modules/customer/detail/timeline/header/Filter';
import IconNote from 'assets/icon/IconNote';
import IconTask from 'assets/icon/IconTask';
import IconCheckList from 'assets/icon/IconCheckList';
import { useTranslation } from 'react-i18next';

const Header = ({ onShowAddNote, onShowAddTask, onChangeFilter, filters, onShowJobTodo }) => {
    const { t } = useTranslation(['common', 'setting']);
    function _handleShowFormAddNote(e) {
        e.stopPropagation();
        onShowAddNote();
    }

    function _handleShowFormAddTask(e) {
        e.stopPropagation();
        onShowAddTask();
    }

    return (
        <div className="header-action">
            <div className="d-flex">
                <div className="v2-btn-default has-icon js-togglenote" onClick={_handleShowFormAddNote}>
                    <IconNote isAddNote />
                    {t('common:note')}
                </div>
                <div className="v2-btn-default has-icon js-toggletask" onClick={_handleShowFormAddTask}>
                    <IconTask />
                    {t('common:task_')}
                </div>
                <div id={'js-add-todolist'} className={`v2-btn-default has-icon`} onClick={onShowJobTodo}>
                    <IconCheckList isChecklist />
                    {t('setting:todo_list')}
                </div>
            </div>
            <Filter onChangeFilter={onChangeFilter} filters={filters} />
        </div>
    );
};

export default Header;
