export default function IconStripe({ hasColor = false, inactive = false }) {
    if (inactive)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                    fill="#F0F0F0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3 12.2C15.3 11.51 15.87 11.2 16.79 11.2C18.3145 11.233 19.8106 11.6198 21.16 12.33V8.24001C19.7716 7.69555 18.2912 7.42393 16.8 7.44001C13.24 7.44001 10.86 9.3 10.86 12.44C10.86 17.3 17.54 16.51 17.54 18.61C17.54 19.42 16.83 19.68 15.86 19.68C14.1512 19.5858 12.4875 19.0963 11 18.25V22.44C12.5157 23.095 14.1488 23.4352 15.8 23.44C19.45 23.44 21.9701 21.64 21.9701 18.44C22 13.23 15.3 14.17 15.3 12.2Z"
                    fill="#666666"
                />
            </svg>
        );

    if (hasColor)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.3 12.2C15.3 11.51 15.87 11.2 16.79 11.2C18.3145 11.233 19.8106 11.6198 21.16 12.33V8.24001C19.7716 7.69555 18.2912 7.42393 16.8 7.44001C13.24 7.44001 10.86 9.3 10.86 12.44C10.86 17.3 17.54 16.51 17.54 18.61C17.54 19.42 16.83 19.68 15.86 19.68C14.1512 19.5858 12.4875 19.0963 11 18.25V22.44C12.5157 23.095 14.1488 23.4352 15.8 23.44C19.45 23.44 21.9701 21.64 21.9701 18.44C22 13.23 15.3 14.17 15.3 12.2Z"
                    fill="#6773E6"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 4.5H16.5C18.7091 4.5 20.5 6.29086 20.5 8.5V15.5C20.5 17.7091 18.7091 19.5 16.5 19.5H9.5C7.29086 19.5 5.5 17.7091 5.5 15.5V8.5C5.5 6.29086 7.29086 4.5 9.5 4.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4921 10.3824C12.4921 10.0396 12.7441 9.90768 13.1615 9.90768C13.7598 9.90768 14.5157 10.1099 15.1142 10.4704V8.40438C14.4606 8.11429 13.815 8 13.1615 8C11.563 8 10.5 8.9319 10.5 10.488C10.5 12.9143 13.4921 12.5275 13.4921 13.5737C13.4921 13.978 13.1772 14.1099 12.7362 14.1099C12.0827 14.1099 11.248 13.811 10.5866 13.4066V15.4989C11.3189 15.8506 12.0591 16 12.7362 16C14.374 16 15.5 15.0946 15.5 13.5209C15.4921 10.9011 12.4921 11.367 12.4921 10.3824Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
