import React, { useRef } from 'react';
import { getIconTypeFile } from 'app/const/icon/IconsType';
import { isFileImage } from 'common/utils/FileUtils';
import GDPreviewAttach from 'app/components/attachments/GDPreviewAttach';
import { handleGetInfoAttach } from 'app/modules/jobdetail/const/Utils';
import { TooltipElement } from './Attachment';
import { GDGlobalImg } from 'app/components/img';

const RenderAttachment = ({ attachments = [], onDeleteAttachment = () => {}, noteId, commentId }) => {
    const refTooltip = useRef(null);
    const refPreviewAttach = useRef(null);

    const _handleDisplayTooltip = (event, { id, size, name }) => {
        refTooltip.current.visible({ id, size, name, event });
    };
    const _handleHideTooltip = () => {
        refTooltip.current.visible(null);
    };

    function _handleClickItem(selected) {
        refPreviewAttach.current.openPreviewWithAttach(
            attachments.map((item) => {
                item.type = item.mime;
                return item;
            }),
            selected.url
        );
    }

    function _renderListAttachment() {
        return attachments.map((item, index) => {
            const { name, size, url, thumbnail_url } = item;
            const finalItem = item.mime || item.type;
            const idEl = `${name}${index}`;

            return (
                <div draggable={false} key={idEl} className="attachment-wrap">
                    <div
                        id={idEl}
                        onMouseOver={(event) => _handleDisplayTooltip(event, { id: idEl, size, name })}
                        onMouseOut={_handleHideTooltip}
                        className="attachment-image-items tooltip"
                        onClick={() => _handleClickItem(item)}
                    >
                        {isFileImage(finalItem) ? (
                            <GDGlobalImg thumbnailUrl={thumbnail_url} src={url} width={95} height={70} alt="" />
                        ) : (
                            getIconTypeFile(finalItem, 43, 56)
                        )}
                    </div>
                    <p className="attachment-name">
                        <span className="prefix-name">
                            {handleGetInfoAttach({ value: name, limitCharacter: 39, maxCharacter: 34 })}
                        </span>
                    </p>
                </div>
            );
        });
    }

    return (
        <>
            {_renderListAttachment()}
            <TooltipElement ref={refTooltip} />
            <GDPreviewAttach
                ref={refPreviewAttach}
                hasDetail
                preventSetSource
                onDeleteAttachment={onDeleteAttachment}
                noteId={noteId}
                commentId={commentId}
            />
        </>
    );
};

export default RenderAttachment;
