import { LIST_TABLE_ITEM_TYPE } from 'app/const/App';

export const getListColumnsReferral = () => {
    const HEADER_TABLE = {
        icon_referral: {
            title: '',
            style: 'col col-icon'
        },
        referral: {
            title: 'referral',
            style: 'col col-xl'
        },
        referral_date: {
            title: 'referral_date',
            style: 'col col-lg'
        },
        activation_date: {
            title: 'activation_date',
            style: 'col col-lg'
        },
        applied_date: {
            title: 'applied_date',
            style: 'col col-lg'
        },
        status: {
            title: 'status',
            style: 'col col-md'
        }
    };

    const COLUMN_TABLE = [
        {
            id: 'icon_referral',
            title: '',
            status: true,
            keyGetValue: 'status'
        },
        {
            id: 'referral',
            title: 'referral',
            status: true,
            keyGetValue: 'email'
        },
        {
            id: 'referral_date',
            title: 'referral_date',
            status: true,
            keyGetValue: 'referred_date'
        },
        {
            id: 'activation_date',
            title: 'activation_date',
            status: true
        },
        {
            id: 'applied_date',
            title: 'applied_date',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];

    const CONTENT_TABLE = {
        icon_referral: {
            id: 'status_referral',
            style: 'col col-icon',
            type: LIST_TABLE_ITEM_TYPE.REFERRAL_STATUS,
            isIcon: true
        },
        referral: {
            id: 'referral',
            style: 'col col-xl'
        },
        referral_date: {
            id: 'referral_date',
            style: 'col col-lg'
        },
        activation_date: {
            id: 'activation_date',
            style: 'col col-lg'
        },
        applied_date: {
            id: 'applied_date',
            style: 'col col-lg'
        },
        status: {
            id: 'status_referral',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.REFERRAL_STATUS
        }
    };

    return {
        header: HEADER_TABLE,
        columns: COLUMN_TABLE,
        contentConfig: CONTENT_TABLE
    };
};
