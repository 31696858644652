/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconClose from 'assets/icon/IconClose';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { useTranslation } from 'react-i18next';
import { isScrollToEndBottom, showImageLoadError } from 'common/utils/FunctionUtils';
import IconArrow from 'assets/icon/IconArrow';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { getListJobNotes } from 'app/const/api/Notes';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import LoadingJobNotes from './LoadingJobNotes';
import { actionToggleInsertJobImages } from 'common/redux/actions/photosAction';

export default function InsertJobNotes({ jobId }) {
    const { t } = useTranslation(['jobDetail', 'header']);

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        showMore: false,
        keyword: '',
        isChanged: 0
    });
    const dispatch = useDispatch();

    const { openTime, callback: functionCallBack } = useSelector(({ notesReducer }) => notesReducer.toggleInsert || {});

    const { keyword: finalKeyword, isLoading, data: finalData, showMore, isChanged } = state;

    useEffect(() => {
        if (openTime) {
            dispatch(actionToggleInsertJobImages(null));
            _handleGetJobNotes(0);
        } else {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: true,
                    data: [],
                    showMore: false,
                    keyword: ''
                };
            });
        }
    }, [openTime]);

    useEffect(() => {
        if (isChanged) {
            _handleGetJobNotes(0);
        }
    }, [isChanged]);

    function _handleClose() {
        dispatch(actionToggleInsertJobNotes(null));
    }

    function _handleGetJobNotes(offset) {
        clientQuery(
            getListJobNotes(jobId),
            { method: 'GET', data: { limit: 10, offset, keyword: finalKeyword } },
            _getDataSuccess
        );
    }

    function _getDataSuccess(response) {
        dispatchState((prev) => {
            return {
                ...prev,
                isLoading: false,
                data: [...prev.data, ...response.data],
                showMore: response.show_more
            };
        });
    }

    const handleOnScrollContent = (e) => {
        if (!isLoading && isScrollToEndBottom(e.target) && showMore) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    isLoading: true
                };
            });
            _handleGetJobNotes(finalData.length - 1);
        }
    };

    const _handleSelectNote = (dataSelect) => {
        if (typeof functionCallBack === 'function') {
            functionCallBack(dataSelect);
        }
    };

    if (!openTime) {
        return false;
    }

    const _handleChangeSearch = (keyword) => {
        dispatchState((prev) => {
            return {
                ...prev,
                keyword,
                isLoading: true,
                isChanged: Date.now(),
                data: []
            };
        });
    };

    const _renderListData = () => {
        if (!isLoading && !finalData.length) {
            return (
                <div className="boxs border-bt-grey">
                    <p className="py-3 px-4">
                        {finalKeyword
                            ? t('header:search_not_match')
                            : t('common:no_data_to_display', { title: t('common:job_notes') })}
                    </p>
                </div>
            );
        }
        return finalData.map((item) => {
            return <JobNoteDetail key={item.id} noteData={item} onSelect={_handleSelectNote} />;
        });
    };

    const _renderLoading = () => {
        if (!isLoading) return false;
        return <LoadingJobNotes />;
    };

    return (
        <div className="wrapper-pane-sub">
            <div className="is-overlay" />
            <div className="wrapper-pane-sub__content">
                <div className="header-modal px-3 border-bt-grey">
                    <h3 className="header-modal__label">{t('jobDetail:existing_job_notes')}</h3>
                    <div onClick={_handleClose} className="v2-btn-default --icon-lg --transparent js-close-insert-note">
                        <IconClose />
                    </div>
                </div>
                <div className="wrap-content flex-column">
                    <div className="wrap-search border-bt-grey">
                        <SearchOption
                            timeDdebound={500}
                            isSearchIcon
                            parentStyle="search-input has-closeicon"
                            defaultValue={''}
                            placeholder={`${t('jobDetail:search')}...`}
                            onSearch={_handleChangeSearch}
                        />
                    </div>
                    <div className="flex-column__content dashboard-wrapper --main" onScroll={handleOnScrollContent}>
                        {_renderListData()}
                        {_renderLoading()}
                    </div>
                </div>
            </div>
        </div>
    );
}

const JobNoteDetail = ({ noteData, onSelect }) => {
    const { user: userDetail = {}, content, date: finalDate } = noteData || {};

    return (
        <div className="boxs border-bt-grey">
            <div className="content elm-parent">
                <div className="header-box">
                    <div className="avt-img">
                        <img
                            onError={showImageLoadError}
                            alt={userDetail.full_name}
                            src={userDetail.avatar}
                            width={24}
                            height={24}
                        />
                    </div>
                    <div className="name flex-1">
                        <h5 className="name__label">{userDetail.full_name}</h5>
                        <p className="name__des">
                            <span className="info">{finalDate}</span>
                        </p>
                    </div>
                    <div className="v2-btn-default --icon-r" onClick={() => onSelect({ content })}>
                        Insert
                        <IconArrow />
                    </div>
                </div>
                <div className="wrap-content is-complete">
                    <div className="wrap-content-box">
                        <div className="description" dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
            </div>
        </div>
    );
};
