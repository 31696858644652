import { call } from 'redux-saga/effects';
import { fetchWithToken, postWithToken } from 'common/utils/ApiUtils';

import {
    REPORT_ACCOUNTS_AGING_GET_LIST,
    REPORT_ACCOUNTS_AGING_PRINT_INVOICE,
    REPORT_ACCOUNTS_AGING_GENERATE_INVOICE_PDF,
    REPORT_ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDERS,
    REPORT_ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDERS,
    REPORT_ACCOUNTS_AGING_EMAIL_STATEMENTS
} from 'app/const/Api';

export function* getListReportAccountsAging({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_ACCOUNTS_AGING_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* printInvoices({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ACCOUNTS_AGING_PRINT_INVOICE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* generateInvoicesPdf({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ACCOUNTS_AGING_GENERATE_INVOICE_PDF, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* emailLatePaymentReminders({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDERS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* smsLatePaymentReminders({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDERS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* emailStatements({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, REPORT_ACCOUNTS_AGING_EMAIL_STATEMENTS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
