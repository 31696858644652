export const CALENDAR_ACTIONS = {
    UPDATE_CALENDAR: 'UPDATE_CALENDAR',
    UPDATE_AGENDA: 'CALENDAR_UPDATE_AGENDA',
    UPDATE_TIME_LINE_VIEW: 'CALENDAR_UPDATE_TIME_LINE_VIEW',
    UPDATE_SCHEDULING: 'CALENDAR_UPDATE_SCHEDULING',
    UPDATE_SERVICE_COLOR: 'CALENDAR_UPDATE_SERVICE_COLOR',
    UPDATE_SELECT_DATE: 'CALENDAR_UPDATE_SELECT_DATE',
    ACTION_GET_JOBS: 'CALENDAR_ACTION_GET_JOBS',
    UPDATE_SHOW_WEEKENDS: 'CALENDAR_UPDATE_SHOW_WEEKENDS',
    ACTION_RELOAD_FULL_CALENDAR: 'ACTION_RELOAD_FULL_CALENDAR',
    UPDATE_COLUMNS_LIST_VIEW: 'UPDATE_COLUMNS_LIST_VIEW',
    UPDATE_SCHEDULES: 'UPDATE_SCHEDULES'
};

export const actionReloadCalendar = () => {
    return {
        type: CALENDAR_ACTIONS.ACTION_RELOAD_FULL_CALENDAR
    };
};

// Update main state of calendar
export const updateCalendar = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
        payload
    };
};

export const updateAgenda = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_AGENDA,
        payload
    };
};

// Update state of schedule
export const updateSchedule = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_SCHEDULING,
        payload: { schedules: payload.schedules }
    };
};

// Update state of service color
export const updateServiceColor = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_SERVICE_COLOR,
        payload
    };
};

// Update state date
export const updateSelectDate = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_SELECT_DATE,
        payload
    };
};

// Update state show weekends
export const updateShowWeekends = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_SHOW_WEEKENDS,
        payload
    };
};

export const actionGetListJob = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: CALENDAR_ACTIONS.ACTION_GET_JOBS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateTimeLineModeView = (payload) => {
    return {
        type: CALENDAR_ACTIONS.UPDATE_TIME_LINE_VIEW,
        payload
    };
};
// Payload is string contains columns key separated by comma
export const updateColumnsAction = (payload) => {
    return { type: CALENDAR_ACTIONS.UPDATE_COLUMNS_LIST_VIEW, payload };
};
