export const TYPE_SETTING_COMPANY = {
    GET_DETAIL: 'SETTING_GET_DETAIL_COMPANY',
    CREATE_AND_UPDATE: 'SETTING_ADD_AND_EDIT_COMPANY',
    UPLOAD_LOGO: 'USER_UPLOAD_LOGO',
    DELETE_LOGO: 'DELETE_LOGO',
    GET_LIST_TIMEZONE: 'SETTINGS_GET_LIST_TIMEZONE',
    GET_LIST_INDUSTRIES: 'SETTINGS_GET_LIST_INDUSTRIES'
};

export const getDetailCompany = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_COMPANY.GET_DETAIL,
        params,
        actionFailed,
        actionSuccess
    };
};

export const getListIndustries = (profileCompany, actionSuccess, actionFailed) => {
    return {
        type: TYPE_SETTING_COMPANY.GET_LIST_INDUSTRIES,
        profileCompany,
        actionFailed,
        actionSuccess
    };
};

export const crateAndUpdateCompany = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_COMPANY.CREATE_AND_UPDATE,
        params,
        actionSuccess,
        actionFailed
    };
};

export const uploadLogo = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_COMPANY.UPLOAD_LOGO,
        params,
        actionSuccess,
        actionFailed
    };
};

export const deleteLogo = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_COMPANY.DELETE_LOGO,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListTimeZone = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_SETTING_COMPANY.GET_LIST_TIMEZONE,
        params,
        actionSuccess,
        actionFailed
    };
};
