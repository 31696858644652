import React from 'react';

export default function () {
    return (
        <div className="wrap-loading --stripe-subscription h-100 body-modal">
            <div className="user-info">
                <div className="d-flex align-center">
                    <div className="flexinline-center loading --onefifth">
                        <div className="avt-img mr-1" />
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="info dots d-flex align-center loading --fourth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="user-info__details">
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__decs">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="rows__decs">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="rows__decs">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --modify --has-field">
                        <div className="rows__label">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="rows__decs">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="plans-content mt-9">
                <div className="header pb-3">
                    <div className="title-x-sm loading --onetenth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="content">
                    <div className="rows --modify --header">
                        <div className="col">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col --sm">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col --remove">
                            <div className="loading --animation --line --fourth" />
                        </div>
                    </div>
                    <div className="rows --modify">
                        <div className="col">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col">
                            <div className="loading --animation --line --half" />
                        </div>
                        <div className="col --sm">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="col --remove">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="rows --modify">
                        <div className="col" />
                        <div className="col" />
                        <div className="col --sm">
                            <div className="loading --animation --line --fourth" />
                        </div>
                        <div className="col --remove">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="plans-content mt-9">
                <div className="header pb-3">
                    <div className="title-x-sm loading --onetenth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="content">
                    <div className="rows --wrap">
                        <div className="col --sm d-flex align-center">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="col --stt d-flex align-center">
                            <div className="status-btn loading --half bg-white-cultured" />
                        </div>
                        <div className="col --stt d-flex align-center">
                            <div className="status-btn loading --half bg-white-cultured" />
                        </div>
                        <div className="flex-betweenitems mt-2 w-100">
                            <div className="col --md loading-lines">
                                <div className="loading --animation --line --ninety" />
                            </div>
                            <div className="col --md">
                                <div className="loading --animation --line --half" />
                            </div>
                            <div className="col --time flex-1 d-flex justify-end">
                                <div className="loading --animation --line --threefourth" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="plans-content mt-5">
                <div className="header">
                    <div className="title-x-sm loading --onefourth">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="header__link d-flex justify-end">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
            </div>
        </div>
    );
}
