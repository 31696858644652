import moment from 'moment-timezone';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { formatTimeUnix } from 'common/utils/TimeUtils';
import { TRACKING_LOCAL_NAME } from '../constant';

const TimerCounter = forwardRef(({ className = 'time flex-1', isHide = false }, ref) => {
    const timezone = useSelector((state) => state.auth.user.settings.timezone);
    const [state, dispatchState] = useReducer(reducer, { isCounting: false, startTime: null, elapsedTime: 0 });
    const { isCounting, startTime, elapsedTime } = state;

    const start = () => {
        if (isCounting) return;
        const timeStart = getLocalStorage(TRACKING_LOCAL_NAME) || moment().tz(timezone).unix();
        setLocalStorage(TRACKING_LOCAL_NAME, timeStart);
        dispatchState((prevState) => ({
            ...prevState,
            startTime: timeStart - prevState.elapsedTime,
            isCounting: true
        }));
    };

    const pause = () => {
        if (!isCounting) return;
        dispatchState((prevState) => ({ ...prevState, isCounting: false, startTime: null, elapsedTime: 0 }));
    };

    useImperativeHandle(ref, () => ({ start, pause }));

    useEffect(() => {
        let interval = null;
        if (isCounting && startTime) {
            interval = setInterval(() => {
                dispatchState((prevState) => {
                    const newElapsedTime = moment().tz(timezone).unix() - prevState.startTime;
                    return { ...prevState, elapsedTime: newElapsedTime < 0 ? 0 : newElapsedTime };
                });
            }, 100);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [isCounting, startTime]);

    if (isHide) return null;
    return (
        <div className={className} style={{ fontVariantNumeric: 'tabular-nums' }}>
            {formatTimeUnix(elapsedTime)}
        </div>
    );
});

export default TimerCounter;
