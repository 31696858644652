import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { LIST_TABLE_ITEM_TYPE, COMMON } from '../App';

export const getGidColumnsTableMaterial = (actionsStatus, isShowCountyColumn, country) => {
    const HEADER_TABLE_MATERIAL = {
        county: {
            title: 'county',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.ASC
        },
        material: {
            title: 'material',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        epa: {
            title: country === 'ca' ? 'pcp' : 'epa',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        qty: {
            title: 'qty',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        applications: {
            title: 'of_applications',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const REPORT_COLUMNS_TABLE_MATERIAL = [
        {
            id: 'county',
            status: true
        },
        {
            id: 'material',
            status: true
        },
        {
            id: 'epa',
            status: true
        },
        {
            id: 'qty',
            status: true
        },
        {
            id: 'applications',
            status: true
        }
    ];
    const REPORT_CONTENT_TABLE_MATERIAL = {
        county: {
            id: 'county',
            style: 'col'
        },
        material: {
            id: 'material',
            style: 'col'
        },
        epa: {
            id: 'epa',
            style: 'col'
        },
        qty: {
            id: 'qty',
            style: 'col'
        },
        applications: {
            id: 'applications',
            style: 'col col-md'
        }
    };

    let newColumns = [...REPORT_COLUMNS_TABLE_MATERIAL];
    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_TABLE_MATERIAL });
    const newContentConfig = { ...REPORT_CONTENT_TABLE_MATERIAL };

    if (isShowCountyColumn === false) {
        delete newHeader.county;
        delete newContentConfig.county;
        newColumns = REPORT_COLUMNS_TABLE_MATERIAL.filter((column) => column.id !== 'county');
    }
    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContentConfig
    };
};

export const getGidColumnsTableCompletedJob = () => {
    const HEADER_TABLE_COMPLETED_JOB = {
        customer: {
            title: 'customer',
            style: 'col'
        },
        service_address: {
            title: 'location',
            style: 'col --full flex-1'
        },
        list_material_name: {
            title: 'date',
            style: 'col --info --hascol-md'
        },
        service_name: {
            title: 'service',
            style: 'col --info --hascol-md'
        },
        list_application: {
            title: '',
            style: 'col col-sm'
        }
    };
    const REPORT_COLUMNS_TABLE_COMPLETED_JOB = [
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'service_address',
            title: 'location',
            status: true
        },
        {
            id: 'list_material_name',
            title: 'date',
            status: true
        },
        {
            id: 'service_name',
            title: 'service_name',
            status: true
        },
        {
            id: 'list_application',
            style: 'list_application',
            status: true
        }
    ];
    const REPORT_CONTENT_TABLE_COMPLETED_JOB = {
        customer: {
            id: 'customer',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        service_address: {
            id: 'location',
            styleText: 'name',
            style: 'col --full flex-1',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        list_material_name: {
            id: 'list_name',
            style: 'col --info --hascol-md',
            type: LIST_TABLE_ITEM_TYPE.LIST
        },
        service_name: {
            id: 'service_name',
            style: 'col --info --hascol-md',
            type: LIST_TABLE_ITEM_TYPE.LIST
        },
        list_application: {
            id: 'list_application',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.LIST
        }
    };

    return {
        header: HEADER_TABLE_COMPLETED_JOB,
        columns: REPORT_COLUMNS_TABLE_COMPLETED_JOB,
        contentConfig: REPORT_CONTENT_TABLE_COMPLETED_JOB
    };
};

export const getGridColumnsTableDetailed = (actionsStatus, columnStatus, country) => {
    const HEADER_TABLE_DETAILED = {
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_name: {
            title: 'service_type',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        check_in: {
            title: 'check_in',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        check_out: {
            title: 'check_out',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        weather: {
            title: 'weather',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        epa: {
            title: country === 'ca' ? 'pcp' : 'epa',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        material: {
            title: 'material',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        qty: {
            title: 'qty',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        units: {
            title: 'units',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        county: {
            title: 'county',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        address_1: {
            title: 'address_line_1',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        address_2: {
            title: 'address_line_2',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_city: {
            title: 'city',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_state: {
            title: 'state',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_zip: {
            title: 'zip',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        dilution: {
            title: 'dilution',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        method: {
            title: 'method',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        target: {
            title: 'target',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        location: {
            title: 'location',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        area: {
            title: 'area',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        assigned_to: {
            title: 'assigned_to',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const REPORT_COLUMNS_TABLE_DETAILED = [
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'service_name',
            title: 'service_name',
            status: true
        },
        {
            id: 'check_in',
            title: 'check_in',
            status: true
        },
        {
            id: 'check_out',
            title: 'check_out',
            status: true
        },
        {
            id: 'weather',
            title: 'weather',
            status: true
        },
        {
            id: 'epa',
            title: country === 'ca' ? 'pcp' : 'epa',
            status: true
        },
        {
            id: 'material',
            title: 'material',
            status: true
        },
        {
            id: 'qty',
            title: 'qty',
            status: true
        },
        {
            id: 'units',
            title: 'units',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'county',
            title: 'county',
            status: true
        },
        {
            id: 'address_1',
            title: 'address_line_1',
            status: true
        },
        {
            id: 'address_2',
            title: 'address_line_2',
            status: true
        },
        {
            id: 'service_city',
            title: 'service_city',
            status: true
        },
        {
            id: 'service_state',
            title: 'service_state',
            status: true
        },
        {
            id: 'service_zip',
            title: 'service_zip',
            status: true
        },
        {
            id: 'dilution',
            title: 'dilution',
            status: true
        },
        {
            id: 'method',
            title: 'method',
            status: true
        },
        {
            id: 'target',
            title: 'target',
            status: true
        },
        {
            id: 'location',
            title: 'location',
            status: true
        },
        {
            id: 'area',
            title: 'area',
            status: true
        },
        {
            id: 'assigned_to',
            title: 'assigned_to',
            status: true
        }
    ];

    const REPORT_CONTENT_TABLE_DETAILED = {
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        service_name: {
            id: 'service_name',
            styleText: 'name',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        check_in: {
            id: 'check_in',
            style: 'col col-sm'
        },
        check_out: {
            id: 'check_out',
            style: 'col col-sm'
        },
        weather: {
            id: 'weather',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.WEATHER
        },
        epa: {
            id: 'epa',
            style: 'col col-sm'
        },
        material: {
            id: 'material',
            style: 'col col-md'
        },
        qty: {
            id: 'qty',
            style: 'col col-sm'
        },
        units: {
            id: 'units',
            style: 'col col-sm'
        },
        date: {
            id: 'date',
            style: 'col col-md'
        },
        county: {
            id: 'county',
            style: 'col col-sm'
        },
        address_1: {
            id: 'address_1',
            style: 'col col-xl'
        },
        address_2: {
            id: 'address_2',
            style: 'col col-xl'
        },
        service_city: {
            id: 'service_city',
            style: 'col col-lg'
        },
        service_state: {
            id: 'service_state',
            style: 'col col-lg'
        },
        service_zip: {
            id: 'service_zip',
            style: 'col col-lg'
        },
        dilution: {
            id: 'dilution',
            style: 'col col-sm'
        },
        method: {
            id: 'method',
            style: 'col col-sm'
        },
        target: {
            id: 'target',
            style: 'col col-sm'
        },
        location: {
            id: 'location',
            style: 'col col-md'
        },
        area: {
            id: 'area',
            style: 'col col-sm'
        },
        assigned_to: {
            id: 'assigned_to',
            style: 'col col-md'
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_TABLE_DETAILED });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_TABLE_DETAILED.map((col) => {
        const item = col.id;
        if (columnStatus.includes(item)) {
            newContent[item] = REPORT_CONTENT_TABLE_DETAILED[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};

export const getGridColumnsTableNewYork = (actionsStatus, columnStatus) => {
    const HEADER_TABLE_NEW_YORK = {
        epa: {
            title: 'epa',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        material: {
            title: 'material',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        qty: {
            title: 'qty',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        units: {
            title: 'units',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        county: {
            title: 'county_code',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        address_1: {
            title: 'address',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_city: {
            title: 'city',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_zip: {
            title: 'zip_code',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };
    const REPORT_COLUMNS_TABLE_NEW_YORK = [
        {
            id: 'epa',
            title: 'epa',
            status: true
        },
        {
            id: 'material',
            title: 'material',
            status: true
        },
        {
            id: 'qty',
            title: 'qty',
            status: true
        },
        {
            id: 'units',
            title: 'units',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'county',
            title: 'county',
            status: true
        },
        {
            id: 'address_1',
            title: 'address_1',
            status: true
        },
        {
            id: 'service_city',
            title: 'service_city',
            status: true
        },
        {
            id: 'service_zip',
            title: 'service_zip',
            status: true
        }
    ];

    const REPORT_CONTENT_TABLE_NEW_YORK = {
        epa: {
            title: 'epa',
            style: 'col col-sm'
        },
        material: {
            title: 'material',
            style: 'col col-xl'
        },
        qty: {
            title: 'qty',
            style: 'col col-md'
        },
        units: {
            title: 'units',
            style: 'col col-sm'
        },
        date: {
            title: 'date',
            style: 'col col-md'
        },
        county: {
            title: 'county',
            style: 'col col-md'
        },
        address_1: {
            title: 'address_1',
            style: 'col col-lg'
        },
        service_city: {
            title: 'service_city',
            style: 'col col-sm'
        },
        service_zip: {
            title: 'service_zip',
            style: 'col col-sm'
        }
    };
    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_TABLE_NEW_YORK });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_TABLE_NEW_YORK.forEach((col) => {
        const item = col.id;
        if (columnStatus.includes(item)) {
            newContent[item] = REPORT_CONTENT_TABLE_NEW_YORK[item];
            newColumns.push(col);
        }
    });

    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
