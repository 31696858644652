import { SUCCESS_CODE } from 'app/const/App';
import { clientQuery } from './ApiUtils';

const TEXT_TYPES_FILE = ['text/vcard', 'text/csv', 'text/rtf', 'text/richtext', 'text/calendar', 'text/directory'];

export const imageType = 'image/x-png, image/jpeg, image/png, image/jpg';

const AUDIO_TYPES_FILE = [
    'audio/basic',
    'audio/l24',
    'audio/mp4',
    'audio/mpeg',
    'audio/ogg',
    'audio/vorbis',
    'audio/vnd.rn-realaudio',
    'audio/vnd.wave',
    'audio/3gpp',
    'audio/3gpp2',
    'audio/ac3',
    'audio/vnd.wave',
    'audio/webm',
    'audio/amr-nb',
    'audio/amr'
];

const VIDEO_TYPES_FILE = [
    'video/3gpp',
    'video/mp4',
    'video/webm',
    'video/mpeg',
    'video/quicktime',
    'video/3gpp2',
    'video/3gpp-tt',
    'video/H261',
    'video/H263',
    'video/H263-1998',
    'video/H263-2000',
    'video/H264'
];

/**
 * Check file is image
 * @param {string} mime - Mime of file
 * @returns {boolean}
 */
export const isFileImage = (mime) => mime && mime.split('/')[0] === 'image';

/**
 * Check file is text.
 * @param {string} mime - Mime of file
 * @returns {boolean}
 */
export const isFileText = (mime) => mime && TEXT_TYPES_FILE.includes(mime);

/**
 * Check file is audio.
 * @param {string} mime - Mime of file
 * @returns {boolean}
 */
export const isFileAudio = (mime) => mime && AUDIO_TYPES_FILE.includes(mime);

/**
 * Check file is video.
 * @param {string} mime - Mime of file
 * @returns {boolean}
 */
export const isFileVideo = (mime) => mime && VIDEO_TYPES_FILE.includes(mime);

/**
 * Get type of file
 * @param {string} mime - Mime of file
 * @returns {string} type of file
 */
// export const getFileType = (mime) => fileName.split('.').pop();

/**
 * Upload file to S3 with presign url
 * @param {string} presigned_url - Presign url
 * @param {object} headers - Headers for request
 * @param {File} file - File to upload
 * @param {object} data - Data for pass to `successFunc` and `failureFunc` when request done
 * @param {func} successFunc - Execute when request success
 * @param {func} failureFunc - Execute when request failure
 */
export const uploadToS3 = (
    presigned_url,
    headers = {},
    file = null,
    data = null,
    successFunc = () => {},
    failureFunc = () => {}
) => {
    if (!presigned_url || typeof presigned_url !== 'string') {
        throw new Error('Must have `presigned_url` and `presigned_url` must be a string.');
    }

    //'x-amz-tagging': 'rm1d=rm1d'

    const optionsQuery = {
        data: {},
        headers: { ...headers },
        body: file,
        method: 'PUT'
    };

    const _uploadFinish = (status) => {
        // If failure execute `failureFunc`
        if (status !== SUCCESS_CODE && isFunction(failureFunc)) return failureFunc(data);
        // If success execute `successFunc`
        isFunction(successFunc) && successFunc(data);
    };

    clientQuery(presigned_url, optionsQuery, null, null, null, true, true).then(_uploadFinish);
};

const isFunction = (fn) => typeof fn === 'function';

/**
 * Convert bytes to Mb
 * @param {number} bytes
 * @returns {number} Megabytes
 */
export const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);

/* It's a function that takes a url, a filename, and a type, and returns a promise that resolves to a
File object. */
export const urlToFile = (url, filename, type) => {
    return fetch(url)
        .then(function (res) {
            return res.arrayBuffer();
        })
        .then(function (buf) {
            return new File([buf], filename, { type });
        });
};

export const getBase64FromUrl = (url, onCallbacl) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onload = function (event) {
            onCallbacl(event.target.result);
        };
        reader.readAsDataURL(this.response);
    };
    xhr.send();
};

/**
 * It takes a number of bytes and returns a human-readable string of the size
 * @param bytes - The number of bytes to convert.
 * @returns the size of the file in bytes.
 */
export const bytesToSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

/**
 * It takes a list of files, converts them to base64, and returns an array of objects with the file's
 * name, url, mime type, and last modified date
 * @param files - The files that were dropped.
 * @returns An array of objects.
 */
export const filesEventToData = (files, cb = () => {}) => {
    if (!files?.length) return [];

    for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            cb({
                id: file.lastModified,
                file,
                name: file.name,
                url: reader.result,
                mime: file.type
            });
        };
    }
};

/**
 * check Limit file size
 * if file size more than 5MB return false
 * else return true
 */
export const checkLimtFileSize = (attachments, limit = 5) => {
    return attachments.reduce((a, b) => a + b.file?.size, 0) > limit * 1024 * 1024;
};
