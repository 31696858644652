import { LIST_TABLE_ITEM_TYPE } from '../App';

export const getGidColumns = (columns = []) => {
    const HEADER_LOCATIONS_WO_ACTIVE_JOBS = {
        status: {
            title: 'status',
            style: 'col col-xs'
        },
        account_number: {
            title: 'account_number_#',
            style: 'col'
        },
        customer: {
            title: 'customer',
            style: 'col col-xl'
        },
        mobile: {
            title: 'phone_mobile',
            style: 'col col-phone'
        },
        service_address: {
            title: 'service_address',
            style: 'col col-address'
        },
        service_address_city: {
            title: 'service_city',
            style: 'col col-sm'
        },
        service_address_zip: {
            title: 'service_zip',
            style: 'col col-sm'
        },
        last_service_type: { title: 'last_service_type', style: 'col col-md' },
        job_status: {
            title: 'job_status',
            style: 'col col-job-status'
        },
        last_service_date: { title: 'last_service_date', style: 'col col-md' },
        left_time: { title: '', style: 'col col-sm' }
    };

    const REPORT_COLUMNS_LOCATIONS_WO_ACTIVE_JOBS = [
        {
            id: 'status',
            title: 'next_invoice',
            status: true
        },
        {
            id: 'account_number',
            style: 'col',
            status: true
        },
        {
            id: 'customer',
            title: 'customer',
            status: true
        },
        {
            id: 'mobile',
            title: 'phone_mobile',
            status: true
        },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'service_address_city',
            title: 'service_city',
            status: true
        },
        {
            id: 'service_address_zip',
            title: 'service_zip',
            status: true
        },
        { id: 'last_service_type', title: 'last_service_type', status: true },
        {
            id: 'job_status',
            title: 'job_status',
            status: true
        },
        { id: 'last_service_date', title: 'last_service_date', status: true },
        { id: 'left_time', title: '', status: true }
    ];

    const REPORT_CONTENT_LOCATIONS_WO_ACTIVE_JOBS = {
        status: {
            id: 'status',
            style: 'col col-xs',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        account_number: {
            id: 'account_number',
            style: 'col',
            styleText: 'col-label',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        mobile: {
            id: 'mobile',
            style: 'col col-phone'
        },
        service_address: {
            id: 'service_address',
            style: 'col col-address',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS
        },
        service_address_city: {
            id: 'service_city',
            style: 'col col-sm'
        },
        service_address_zip: {
            id: 'service_zip',
            style: 'col col-sm'
        },
        last_service_type: { id: 'last_service_type', style: 'col col-md' },
        job_status: {
            id: 'job_status',
            style: 'col col-job-status d-flex gap-4',
            type: LIST_TABLE_ITEM_TYPE.JOBS_STATUS
        },
        last_service_date: { id: 'last_service_date', style: 'col col-md' },
        left_time: { id: 'left_time', style: 'col col-sm' }
    };

    const newHeader = {};
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_LOCATIONS_WO_ACTIVE_JOBS.map((col) => {
        const item = col.id;

        if (columns.includes(item)) {
            newHeader[item] = HEADER_LOCATIONS_WO_ACTIVE_JOBS[item];
            newContent[item] = REPORT_CONTENT_LOCATIONS_WO_ACTIVE_JOBS[item];
            newColumns.push(col);
        }
    });
    return {
        header: newHeader,
        columns: newColumns,
        contentConfig: newContent
    };
};
