export const TYPE_REPORT_LEADS = {
    GET_LIST_CUSTOMER_LEADS: 'GET_LIST_REPORT_LEADS',
    GET_LIST_REPORT_BOOKING_JOBS: 'GET_LIST_REPORT_BOOKING_JOBS',

    GET_LIST_YEARS_INBOUND_LEADS_CHART: 'GET_LIST_YEARS_INBOUND_LEADS_CHART',
    GET_LIST_INBOUND_LEADS_CHART: 'GET_LIST_INBOUND_LEADS_CHART',

    GET_LIST_YEARS_ONLINE_BOOKING_CHART: 'GET_LIST_YEARS_ONLINE_BOOKING_CHART',
    GET_LIST_ONLINE_BOOKINGS_CHART: 'GET_LIST_DATA_ONLINE_BOOKINGS_CHART'
};

export const getListReportLeads = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_CUSTOMER_LEADS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportBookingJobs = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_REPORT_BOOKING_JOBS,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListYearInboundLeadsChart = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_YEARS_INBOUND_LEADS_CHART,
        actionSuccess,
        actionFailed
    };
};

export const getListDataInboundLeadsChart = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_INBOUND_LEADS_CHART,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListYearOnlineBookingsChart = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_YEARS_ONLINE_BOOKING_CHART,
        actionSuccess,
        actionFailed
    };
};

export const getListDataOnlineBookingsChart = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_LEADS.GET_LIST_ONLINE_BOOKINGS_CHART,
        params,
        actionSuccess,
        actionFailed
    };
};
