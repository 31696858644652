import React from 'react';

import IconClose from 'assets/icon/IconClose';
import { OPERATOR_IDS, OPERATOR_TYPES } from '../../constants/types';

const ItemFilteredNum = ({ filter, field, onRemove = () => {}, onUpdate = () => {} }) => {
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    const value = filter.value;
    const { prefixLabel, label } = field || {};
    const isBetween = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN].includes(filter.operatorId);
    const isSome = [OPERATOR_IDS.NONE, OPERATOR_IDS.SOME].includes(filter.operatorId);

    const moreString = `${value?.moreThan?.floatValue} ${
        isBetween ? (value?.moreThan?.includeEquals ? '≤' : '<') : ''
    }`;
    const lessString = `${value?.lessThan?.includeEquals ? '≤' : '<'} ${value?.lessThan?.floatValue}`;
    return (
        <>
            <div onClick={onUpdate} className="flex-1">
                <span className="black-darklight fw-500">
                    {prefixLabel ? `${prefixLabel} ` : null}
                    {label}:
                </span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{prefixToken}</span> : null}
                <span className="fw-600 ml-1">
                    {typeof value === 'object' ? (
                        <>
                            {moreString} {isBetween ? ' x ' : ''} {value?.lessThan && lessString}
                        </>
                    ) : value >= 0 && !isSome ? (
                        value
                    ) : null}
                </span>
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

export default ItemFilteredNum;
