import IconThreeDots from 'assets/icon/IconThreeDots';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BoxDragCalendar = ({ jobsLength = 0 }) => {
    const { t } = useTranslation('calendar');
    return (
        <div className="box-drag is-selected flexcenter">
            <IconThreeDots isDouble />
            {jobsLength > 1
                ? t('drag_selected_jobs', { length: jobsLength })
                : t('drag_selected_job', { length: jobsLength })}
        </div>
    );
};

export default BoxDragCalendar;
