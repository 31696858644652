export const LIST_HOURS = [
    {
        value: 0,
        name: '0 hr'
    },
    {
        value: 1,
        name: '01 hr'
    },
    {
        value: 2,
        name: '02 hrs'
    },
    {
        value: 3,
        name: '03 hrs'
    },
    {
        value: 4,
        name: '04 hrs'
    },
    {
        value: 5,
        name: '05 hrs'
    },
    {
        value: 6,
        name: '06 hrs'
    },
    {
        value: 7,
        name: '07 hrs'
    },
    {
        value: 8,
        name: '08 hrs'
    },
    {
        value: 9,
        name: '09 hrs'
    },
    {
        value: 10,
        name: '10 hrs'
    },
    {
        value: 11,
        name: '11 hrs'
    },
    {
        value: 12,
        name: '12 hrs'
    }
];

export const LIST_MINUTE = [
    {
        value: 0,
        name: '0 min'
    },
    {
        value: 5,
        name: '5 mins'
    },
    {
        value: 10,
        name: '10 mins'
    },
    {
        value: 15,
        name: '15 mins'
    },
    {
        value: 20,
        name: '20 mins'
    },
    {
        value: 25,
        name: '25 mins'
    },
    {
        value: 30,
        name: '30 mins'
    },
    {
        value: 35,
        name: '35 mins'
    },
    {
        value: 40,
        name: '40 mins'
    },
    {
        value: 45,
        name: '45 mins'
    },
    {
        value: 50,
        name: '50 mins'
    },
    {
        value: 55,
        name: '55 mins'
    }
];
