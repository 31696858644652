import { LIST_API_UPDATE_ADDONS_OPTIONS } from 'app/const/addons';
import { React } from 'react';
import { useTranslation } from 'react-i18next';
import AddonsBoxHeader from '../../components/AddonsBoxHeader';

const ReviewAddonsContent = ({ keyword, name, upgrade, sub_title, status, level }) => {
    const { t } = useTranslation(['addons']);

    return (
        <>
            <div className="boxs boxs--no-border --hasline">
                <AddonsBoxHeader
                    keyword={keyword}
                    name={name}
                    upgrade={upgrade}
                    status={status}
                    level={level}
                    urlUpdateStatus={LIST_API_UPDATE_ADDONS_OPTIONS[keyword]}
                />
                <div className="boxs__contents mt-3">
                    <p className="description">{t('addons:description_review')}</p>
                    <p className="description mt-5">{sub_title}</p>
                </div>
            </div>
        </>
    );
};

export default ReviewAddonsContent;
