export default function IconGoto({ isSmall = false }) {
    if (isSmall) {
        return (
            <svg width={12} height={20} viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                {' '}
                <path
                    d="M7.5 12.5L10.5 9.5L7.5 6.5"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
                <path
                    d="M10.5 9.5H7.5C4.18629 9.5 1.5 7.48528 1.5 5V3.5"
                    stroke="#1E7EF7"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />{' '}
            </svg>
        );
    }
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5939 18.0494V22.1788C14.594 22.6325 14.9521 23 15.3938 23C15.6202 23 15.8361 22.9012 15.9877 22.7283L23.1844 14.514C23.4476 14.2129 23.4581 13.7598 23.2092 13.4461L16.0124 4.4103C15.7328 4.05918 15.229 4.00739 14.8872 4.29464C14.702 4.45025 14.5944 4.68287 14.5939 4.92862V9.02183C8.2767 8.81073 4.33452 6.03674 2.55768 0.56102C2.4487 0.226028 2.14344 0 1.79963 0C1.75652 0 1.71342 0.00346947 1.67088 0.0106888C1.28405 0.0755215 0.999975 0.418793 0.999975 0.821457C0.999975 10.4947 6.66863 17.6009 14.5939 18.0494Z"
                fill="#C1C9D4"
            ></path>
        </svg>
    );
}
