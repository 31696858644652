import { call, put } from 'redux-saga/effects';
import { deleteWithToken, fetchWithToken, putWithToken } from 'common/utils/ApiUtils';
import {
    REPORT_DOCUMENT_GET_LIST,
    REPORT_DOCUMENT_GET_LIST_TYPE,
    REPORT_DOCUMENT_DELETE,
    REPORT_DOCUMENT_UNDELETE,
    REPORT_DOCUMENT_TRASH
} from 'app/const/Api';
import { TYPE_DOCUMENT_ACTIONS } from 'common/redux/actions/job/documents';

export function* getListReportDocument({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_DOCUMENT_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteReportDocument({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, REPORT_DOCUMENT_DELETE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* unDeleteReportDocument({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, REPORT_DOCUMENT_UNDELETE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* trashReportDocumentRequest({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(deleteWithToken, REPORT_DOCUMENT_TRASH, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListTypeFilter({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_DOCUMENT_GET_LIST_TYPE, params);
        if (response && response.success) {
            const newData = {
                data: response.data.map((item) => {
                    return { ...item, id: item.parent_id };
                })
            };
            yield put({
                type: TYPE_DOCUMENT_ACTIONS.GET_LIST_DOCUMENT_TYPE_SUCCESS,
                payload: newData
            });
            actionSuccess(newData);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
