import { DEFAULT_ALL } from 'app/const/App';
import { ROUTING_EVENT_TYPE } from 'app/modules/routeoptimizer/const';
import i18n from 'assets/i18n';

export const SPECIAL_DATE_RANGE_FORMAT = 'MMM DD YYYY';
export const DATE_RANGE_FORMAT_JOB_WITHIN = 'm/d/Y';
export const CALENDAR_MODE = {
    VERTICAL: 'vertical',
    HORIZONTAL: 'horizontal'
};

export const CALENDAR_MODE_CLASS = {
    [CALENDAR_MODE.VERTICAL]: 'calendar-mode-vertical display-vertical',
    [CALENDAR_MODE.HORIZONTAL]: 'calendar-mode-horizontal display-horizontal'
};

export const TIME_LINE_MODE_VIEW = { SMALL: 'small', REGULAR: 'regular', LARGE: 'large' };

export const TIME_LINE_VIEW_VALUES = {
    [TIME_LINE_MODE_VIEW.SMALL]: '1',
    [TIME_LINE_MODE_VIEW.REGULAR]: '2',
    [TIME_LINE_MODE_VIEW.LARGE]: '3'
};
export const TIME_LINE_MODES = {
    [TIME_LINE_VIEW_VALUES[TIME_LINE_MODE_VIEW.SMALL]]: TIME_LINE_MODE_VIEW.SMALL,
    [TIME_LINE_VIEW_VALUES[TIME_LINE_MODE_VIEW.REGULAR]]: TIME_LINE_MODE_VIEW.REGULAR,
    [TIME_LINE_VIEW_VALUES[TIME_LINE_MODE_VIEW.LARGE]]: TIME_LINE_MODE_VIEW.LARGE
};

export const TIME_LINE_MODE_VIEW_CLASS = {
    [TIME_LINE_MODE_VIEW.SMALL]: 'small-tasks',
    [TIME_LINE_MODE_VIEW.REGULAR]: 'regular-tasks',
    [TIME_LINE_MODE_VIEW.LARGE]: 'large-tasks'
};

export const CALENDAR_MODES = {
    TIME_LINE: 'timeLine',
    DAY_GRID_MONTH: 'month',
    AGENDA_DAY: 'agendaDay',
    AGENDA_WEEK: 'agendaWeek',
    AGENDA_2_WEEK: 'agendaTwoWeeks',
    AGENDA_3_WEEK: 'agenda3Weeks',
    AGENDA_4_WEEK: 'agenda4Weeks',
    AGENDA_3_DAY: 'agenda3Days',
    AGENDA_4_DAY: 'agenda4Days',
    LIST_DAY: 'listDay',
    LIST_WEEK: 'listWeek',
    LIST_MONTH: 'listMonth'
};

export const MODES_ALLOWS_CHANGE_SHOW_WEEKEND = [
    CALENDAR_MODES.AGENDA_WEEK,
    CALENDAR_MODES.DAY_GRID_MONTH,
    CALENDAR_MODES.LIST_WEEK,
    CALENDAR_MODES.LIST_MONTH
];
export const CALENDAR_LIST_VIEWS_MODE = [CALENDAR_MODES.LIST_DAY, CALENDAR_MODES.LIST_WEEK, CALENDAR_MODES.LIST_MONTH];

export const CALENDAR_MODES_CLASS = {
    [CALENDAR_MODES.TIME_LINE]: 'view-timeline',
    [CALENDAR_MODES.DAY_GRID_MONTH]: 'view-month',
    [CALENDAR_MODES.AGENDA_DAY]: 'view-oneday',
    [CALENDAR_MODES.AGENDA_WEEK]: 'view-week',
    [CALENDAR_MODES.AGENDA_2_WEEK]: 'view-twoweek',
    [CALENDAR_MODES.AGENDA_3_WEEK]: 'view-twoweek',
    [CALENDAR_MODES.AGENDA_4_WEEK]: 'view-twoweek',
    [CALENDAR_MODES.AGENDA_3_DAY]: 'view-threeday',
    [CALENDAR_MODES.AGENDA_4_DAY]: 'view-fourday',
    [CALENDAR_MODES.LIST_DAY]: 'view-agenda',
    [CALENDAR_MODES.LIST_WEEK]: 'view-agenda',
    [CALENDAR_MODES.LIST_MONTH]: 'view-agenda'
};

export const CALENDAR_MODE_VIEW = {
    [CALENDAR_MODES.DAY_GRID_MONTH]: 'dayGridMonth',
    [CALENDAR_MODES.AGENDA_DAY]: 'resourceTimeGridDay',
    [CALENDAR_MODES.AGENDA_WEEK]: 'agendaWeek',
    [CALENDAR_MODES.AGENDA_2_WEEK]: 'agendaTwoWeek',
    [CALENDAR_MODES.AGENDA_3_WEEK]: 'agendaThreeWeek',
    [CALENDAR_MODES.AGENDA_4_WEEK]: 'agendaFourWeek',
    [CALENDAR_MODES.AGENDA_3_DAY]: 'agendaThreeDay',
    [CALENDAR_MODES.AGENDA_4_DAY]: 'agendaFourDay',
    [CALENDAR_MODES.LIST_DAY]: 'listDay',
    [CALENDAR_MODES.LIST_WEEK]: 'listWeek',
    [CALENDAR_MODES.LIST_MONTH]: 'listMonth'
};

export const CALENDAR_TIMELINE_MODE_VIEW = {
    [CALENDAR_MODES.AGENDA_WEEK]: 'resourceTimelineWeek',
    [CALENDAR_MODES.AGENDA_3_DAY]: 'resourceTimelineThreeDays',
    [CALENDAR_MODES.AGENDA_4_DAY]: 'resourceTimelineFourDays',
    [CALENDAR_MODES.AGENDA_DAY]: 'resourceTimeOneDay',
    [CALENDAR_MODES.AGENDA_2_WEEK]: 'resourceTimelineTwoWeek',
    [CALENDAR_MODES.AGENDA_3_WEEK]: 'resourceTimelineThreeWeek',
    [CALENDAR_MODES.AGENDA_4_WEEK]: 'resourceTimelineFourWeek',
    [CALENDAR_MODES.DAY_GRID_MONTH]: 'resourceTimelineMonth',
    [CALENDAR_MODES.LIST_DAY]: 'listDay',
    [CALENDAR_MODES.LIST_WEEK]: 'listWeek',
    [CALENDAR_MODES.LIST_MONTH]: 'listMonth'
};

export const VIEW_OPTIONS_AGENDAR = {
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_WEEK]]: {
        type: 'resourceTimeGrid',
        duration: { days: 7 }
    },
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_2_WEEK]]: {
        type: 'resourceTimeGrid',
        duration: { days: 14 },
        dateIncrement: { days: 7 }
    },
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_3_WEEK]]: {
        type: 'resourceTimeGrid',
        duration: { days: 21 },
        dateIncrement: { days: 7 }
    },
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_4_WEEK]]: {
        type: 'resourceTimeGrid',
        duration: { days: 28 },
        dateIncrement: { days: 7 }
    },
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_4_DAY]]: {
        type: 'resourceTimeGrid',
        duration: { days: 4 }
    },
    [CALENDAR_MODE_VIEW[CALENDAR_MODES.AGENDA_3_DAY]]: {
        type: 'resourceTimeGrid',
        duration: { days: 3 }
    },
    resourceTimelineWeek: {
        type: 'timelineWeek'
    },
    resourceTimelineTwoWeek: {
        type: 'resourceTimeline',
        duration: { days: 14 },
        dateIncrement: { days: 7 }
    },
    resourceTimelineThreeWeek: {
        type: 'resourceTimeline',
        duration: { days: 21 },
        dateIncrement: { days: 7 }
    },
    resourceTimelineFourWeek: {
        type: 'resourceTimeline',
        duration: { days: 28 },
        dateIncrement: { days: 7 }
    },
    resourceTimelineThreeDays: {
        type: 'resourceTimeline',
        duration: { days: 3 }
    },
    resourceTimelineFourDays: {
        type: 'resourceTimeline',
        duration: { days: 4 }
    },
    resourceTimeOneDay: {
        type: 'resourceTimelineDay'
    },
    resourceTimelineMonth: {
        type: 'resourceTimelineMonth'
    }
};

export const TYPE_RESOURCE_HORIZONTAL = [
    'resourceTimeGridDay',
    'agendaTwoWeek',
    'agendaThreeWeek',
    'agendaFourWeek',
    'agendaThreeDay',
    'agendaFourDay',
    'agendaWeek'
];

export const ACTIONS_JOB = {
    MOVE: 'move',
    RESIZE: 'resize',
    MOVE_WP: 'move_to_WP',
    MOVE_JL: 'move_to__job_list'
};

export const MAP_JOB_STATUS = 'job_status';
export const MAP_WEEK_DAYS = 'week_days';
export const MAP_TYPES = 'map_types';
export const MAP_FILTER_LOCAL_STORAGE = 'map_filter';
export const MAP_FILTER_DEFAULT = {
    [MAP_WEEK_DAYS]: DEFAULT_ALL,
    [MAP_JOB_STATUS]: [0, 1, 2, 3, 4, 6],
    [MAP_TYPES]: DEFAULT_ALL
};
export const MAP_FILTER_DEFAULT_OPTIMIZE = { [MAP_WEEK_DAYS]: DEFAULT_ALL, [MAP_JOB_STATUS]: DEFAULT_ALL };

export const TYPE_STORE = {
    [MAP_JOB_STATUS]: 11,
    [MAP_WEEK_DAYS]: 12,
    [MAP_TYPES]: 15
};

// EVENT SETTINGS
export const TASK_TEXT_DEFAULT = '#555b64';
export const TASK_BACKGROUND_DEFAULT = '#f9f9f9';
export const TASK_BORDER_DEFAULT = '#f9f9f9';
export const TASK_CLASS_NAME = 'event-task';

export const DEFAULT_WIDTH_TIMELINE = 30;
export const DEFAULT_HEIGHT_OF_ROW = 15;
export const STEP_CHANGE_HEIGHT_ROW = 10;
export const MIN_HEIGHT_OF_ROW = 15;
export const MAX_HEIGHT_OF_ROW = 35;

// Colors schedule
export const COLOR_SCHEDULES = [
    '#E004F9',
    '#045AF9',
    '#85B501',
    '#FA6601',
    '#7651A8',
    '#9DA5B3',
    '#E6C40B',
    '#000000',
    '#FF0000',
    '#FFFF00',
    '#967117',
    '#FF00BF',
    '#9FD2F5',
    '#00FFFF',
    '#B0BF1A',
    '#C0E8D5',
    '#B284BE',
    '#EDEAE0',
    '#9F2B68',
    '#FF7E00',
    '#841B2D',
    '#FF91AF',
    '#C8FCB9',
    '#9F8170',
    '#3B2F2F',
    '#702963',
    '#FFFF99',
    '#FFA6C9',
    '#C95A49',
    '#536878'
];

export const ID_POPPER_ADD_JOB = 'add-job-popcorn';
export const ID_TIME_WINDOW_FRAME = 'frame-timewindow';
export const TIME_WINDOW_HORIZONTAL_CLASS = 'frame-timewindown is-horizontal';
export const TIME_WINDOW_VERTICAL_CLASS = 'frame-timewindown';
export const LIST_ACTIONS_HANDLE_DRAG_SCROLL = ['mousedown', 'mousemove', 'mouseup', 'mouseleave', 'wheel'];
export const NUMBER_SHOW_SCHEDULE = 3;
export const SCHEDULE_GROUP_SIZE = {
    SMALL: 11,
    MEDIUM: 22
};

export const CALENDAR_EVENT_TYPE = {
    [ROUTING_EVENT_TYPE.EVENT]: {
        title: i18n.t('calendar:recurring_title', { name: i18n.t('calendar:custom_event') }),
        onlyText: i18n.t('calendar:recurring_only_text', { name: i18n.t('calendar:custom_event') }),
        recurringText: i18n.t('calendar:recurring_text', { name: i18n.t('calendar:custom_event') })
    },
    [ROUTING_EVENT_TYPE.TIMEOFF]: {
        title: i18n.t('calendar:recurring_title', { name: i18n.t('calendar:time_off') }),
        onlyText: i18n.t('calendar:recurring_only_text', { name: i18n.t('calendar:time_off') }),
        recurringText: i18n.t('calendar:recurring_text', { name: i18n.t('calendar:time_off') })
    }
};
export const DAYS_OF_WEEK = [0, 1, 2, 3, 4, 5, 6];

export const EVENT_TOOLTIPS = {
    linked: i18n.t('calendar:this_job_frequency_is_linked_to_another_job'),
    draft: i18n.t('calendar:this_job_is_draft_status'),
    sms: i18n.t('calendar:open_unread_sms_msg'),
    locked: i18n.t('calendar:a_locked_job_in_calendar'),
    not_match_time_window: i18n.t('calendar:not_match_time_window')
};

// For Job Magnet
export const JOB_MAGNET_ABILITY = [
    CALENDAR_MODES.AGENDA_DAY,
    CALENDAR_MODES.AGENDA_WEEK,
    CALENDAR_MODES.AGENDA_2_WEEK,
    CALENDAR_MODES.AGENDA_3_DAY,
    CALENDAR_MODES.AGENDA_4_DAY
];
export const COLORS_MAGNET_RANGE = ['#009E46', '#79A914', '#C9A21B', '#FF9B26', '#E20D0D', '#AE1500'];
export const CALENDAR_CUSTOM_EVENTS = {
    OPEN_MAGNET_JOB: 'open-magnet-job',
    DROPPED_JOB: 'dropped-job',
    RESET_MAGNET_JOB: 'reset-magnet-job',
    RESET_MAGNET_CALENDAR: 'reset-magnet-calendar'
};
