export const TYPE_REPORT_NEW_CUSTOMERS = {
    GET_LIST: 'GET_LIST_REPORT_NEW_CUSTOMERS',
    GET_LIST_SERVICE_FILTER_REQUEST: 'GET_LIST_SERVICE_FILTER_REQUEST',
    GET_LIST_CHART: 'GET_LIST_DATA_CHART_NEW_CUSTOMERS',
    GET_LIST_YEAR: 'GET_LIST_YEAR_NEW_CUSTOMERS',
    SOURCE: 'GET_LIST_SOURCE_FILTER_REPORT_NEW_CUSTOMERS'
};

export const getListReportNewCustomers = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_NEW_CUSTOMERS.GET_LIST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListServiceFilterRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_NEW_CUSTOMERS.GET_LIST_SERVICE_FILTER_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const getDataChartNewCustomers = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_NEW_CUSTOMERS.GET_LIST_CHART,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListYearNewCustomers = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_NEW_CUSTOMERS.GET_LIST_YEAR,
        actionSuccess,
        actionFailed
    };
};

export const getListSourceNewCustomerFilter = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_NEW_CUSTOMERS.SOURCE,
        actionSuccess,
        actionFailed
    };
};
