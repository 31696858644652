import moment from 'moment';

/**
 *  Convert socket data to object sms when fetch data.
 * @param {object} wsData - Socket data object.
 * @return {object} Sms object.
 */
export const convertWsSendToSms = (wsData) => {
    const sms_data = wsData.sms_data || {};
    const { date, created, phone, filter = {}, is_read, sender = {} } = sms_data;

    return {
        ...sms_data,
        group: {
            value: wsData.date || date,
            key: moment.unix(created).format('MM/DD/YYYY')
        },
        sender: { ...sender, id: sender?.id?.toString() },
        receiver: { ...phone },
        is_read: parseInt(is_read),
        filter: {
            ...filter,
            sender_id: filter?.sender_id?.toString()
        }
    };
};

/**
 *  Convert socket data to object sms when fetch data.
 * @param {object} wsData - Socket data object.
 * @return {object} Sms object.
 */
export const convertWsReceiveToSms = (wsData) => {
    const sms_data = wsData.sms_data || {};

    const { date, created, filter, is_read, type } = sms_data;
    const finalCustomerData = wsData.customer || {};

    return {
        ...sms_data,
        group: {
            value: wsData.date || date,
            key: moment.unix(created).format('MM/DD/YYYY')
        },
        sender: { ...finalCustomerData, id: parseInt(finalCustomerData.id || 0), full_name: finalCustomerData.name },
        is_read: parseInt(is_read),
        filter: {
            user_id: wsData.user_id,
            user_deleted: parseInt(filter.user_deleted),
            phone: filter.phone,
            phone_deleted: parseInt(filter.phone_deleted)
        },
        type: parseInt(type)
    };
};

export const getPhoneCustomerSmsDetails = (sms_to, phones) => {
    if (!Array.isArray(phones) || !phones.length) return null;

    if (Array.isArray(sms_to)) {
        if (sms_to.length) {
            return sms_to[0];
        } else {
            return phones[0].phone;
        }
    }
    return null;
};

export const replaceContentHaveBreakDown = (content, breakTag = '<br/>') => {
    return content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
};

export const sortListSms = ({ data = [], isReverse = false }) => {
    return data.sort((prevItem, nextItem) => {
        const { id: prevItemId, created: prevItemCreated } = prevItem || {};
        const { id: nextItemId, created: nextItemCreated } = nextItem || {};

        if (prevItemCreated === nextItemCreated) {
            return parseInt(isReverse ? nextItemId : prevItemId) - parseInt(isReverse ? prevItemId : nextItemId);
        }
        return (isReverse ? nextItemCreated : prevItemCreated) - (isReverse ? prevItemCreated : nextItemCreated);
    });
};
