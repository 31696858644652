import React from 'react';

export default function Loading() {
    return (
        <div className="wrap-loading flex-column wrap-document-edit">
            <div className="wrap-header">
                <div className="wrap-header__conts mt-1 mb-3">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="wrap-header__conts rows-description">
                    <div className="check-items flex-1">
                        <div className="checkbox" />
                        <div className="item-checkbox flex-1">
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                    <div className="btn-x-sm v2-btn-default --grey loading">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows-description">
                    <div className="log-items">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="log-items mt-3">
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
            <div className="wrap-body">
                <div className="edit-document-form">
                    <div className="wrap-loading document-field --section">
                        <div className="document-field__title-section mb-2">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="document-field__desc-section loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                    <div className="wrap-loading document-field --input">
                        <div className="document-field__label mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="wrap-loading document-field --input">
                        <div className="document-field__label mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                    </div>
                    <div className="wrap-loading --edit-docs document-field --textarea">
                        <div className="document-field__label mb-2">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="document-field__typing loading loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="wrap-loading document-field --listbox">
                        <div className="document-field__label mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100 justify-start">
                            <div className="loading --animation --line --half" />
                        </div>
                    </div>
                    <div className="wrap-loading --edit-docs document-field --listbox">
                        <div className="document-field__label mb-1">
                            <div className="loading --animation --line --onefifth" />
                        </div>
                        <div className="v2-btn-default loading --grey w-100 justify-start">
                            <div className="box-tags">
                                <div className="tag-label btn-x-sm loading --animation" />
                                <div className="tag-label btn-x-sm loading --animation" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading document-field">
                        <div className="document-field__title-section mb-2">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="check-items">
                            <div className="checkbox" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onefifth" />
                            </div>
                        </div>
                        <div className="check-items">
                            <div className="checkbox" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onefifth" />
                            </div>
                        </div>
                        <div className="check-items">
                            <div className="checkbox" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onefifth" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading document-field">
                        <div className="document-field__title-section mb-2">
                            <div className="loading --animation --line --onefourth" />
                        </div>
                        <div className="check-radio">
                            <div className="checkbox --radio" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onetenth" />
                            </div>
                        </div>
                        <div className="check-radio">
                            <div className="checkbox --radio" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onetenth" />
                            </div>
                        </div>
                        <div className="check-radio">
                            <div className="checkbox --radio" />
                            <div className="item-checkbox flex-1">
                                <div className="loading --animation --line --onetenth" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading --edit-docs document-field --upload-file is-uploaded">
                        <div className="document-field__label mb-2">
                            <div className="loading --animation --line --onetenth" />
                        </div>
                        <div className="upload-action">
                            <div className="v2-btn-default btn-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="upload-has-file">
                            <div className="image-preview" />
                            <div className="upload-has-file__label">
                                <div className="file-name btn-sm">
                                    <div className="loading --animation --line" />
                                </div>
                                <div className="loading --animation v2-btn-default --transparent --icon-lg flex-auto" />
                            </div>
                        </div>
                    </div>
                    <div className="wrap-loading --edit-docs document-field --signature">
                        <div className="document-field__label mb-1">
                            <div className="loading --animation --line --twothird" />
                        </div>
                        <div className="document-field__signature">
                            <div className="signature-image" />
                            <div className="signature-add">
                                <div className="v2-btn-default loading --grey w-100 justify-center">
                                    <div className="loading --animation --line --twothird" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
