import React from 'react';

export default function IconDashboard({ isMenuActive = false }) {
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width={32} height={32} rx={12} fill="#755FFF" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.35271 18.6338C5.88424 16.9179 5.88192 15.1081 6.346 13.391C6.81008 11.6739 7.72375 10.1116 8.99282 8.8653C10.0234 7.85324 11.258 7.07925 12.6095 6.59223C13.1291 6.405 13.6746 6.73503 13.809 7.27071L14.7826 11.1504C14.9171 11.6861 14.5822 12.219 14.0964 12.4818C13.7684 12.6593 13.4654 12.8825 13.1969 13.1461C12.6893 13.6447 12.3238 14.2696 12.1382 14.9564C11.9526 15.6433 11.9535 16.3672 12.1409 17.0536C12.3283 17.7399 12.6953 18.3639 13.2042 18.8611C13.7131 19.3583 14.3455 19.7108 15.036 19.8822C15.7265 20.0536 16.4503 20.0378 17.1326 19.8362C17.815 19.6347 18.4312 19.2548 18.9178 18.7358C19.1752 18.4613 19.3913 18.1531 19.5611 17.8211C19.8125 17.3293 20.3376 16.9822 20.8762 17.1041L24.7775 17.9874C25.3161 18.1094 25.6587 18.6471 25.4836 19.1709C25.0281 20.5334 24.283 21.7856 23.2951 22.8393C22.0786 24.137 20.538 25.0867 18.8321 25.5905C17.1262 26.0943 15.3168 26.134 13.5905 25.7055C11.8642 25.277 10.2834 24.3958 9.01113 23.1527C7.73887 21.9097 6.82119 20.3497 6.35271 18.6338Z"
                    fill="#B8ACFE"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 7C16 6.44772 16.4488 5.99482 16.9983 6.04996C17.9691 6.14736 18.9222 6.38648 19.8268 6.7612C21.0401 7.26375 22.1425 8.00035 23.0711 8.92893C23.9997 9.85752 24.7362 10.9599 25.2388 12.1732C25.6135 13.0778 25.8526 14.0309 25.95 15.0017C26.0052 15.5512 25.5523 16 25 16H21C20.4477 16 20.0123 15.5455 19.8756 15.0104C19.8287 14.8264 19.7685 14.6455 19.6955 14.4693C19.4945 13.984 19.1999 13.543 18.8284 13.1716C18.457 12.8001 18.016 12.5055 17.5307 12.3045C17.3545 12.2315 17.1736 12.1713 16.9896 12.1244C16.4545 11.9877 16 11.5523 16 11L16 7Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width={32} height={32} rx={12} fill="#f4f6fa" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.35271 18.6338C5.88424 16.9179 5.88192 15.1081 6.346 13.391C6.81008 11.6739 7.72375 10.1116 8.99282 8.8653C10.0234 7.85324 11.258 7.07925 12.6095 6.59223C13.1291 6.405 13.6746 6.73503 13.809 7.27071L14.7826 11.1504C14.9171 11.6861 14.5822 12.219 14.0964 12.4818C13.7684 12.6593 13.4654 12.8825 13.1969 13.1461C12.6893 13.6447 12.3238 14.2696 12.1382 14.9564C11.9526 15.6433 11.9535 16.3672 12.1409 17.0536C12.3283 17.7399 12.6953 18.3639 13.2042 18.8611C13.7131 19.3583 14.3455 19.7108 15.036 19.8822C15.7265 20.0536 16.4503 20.0378 17.1326 19.8362C17.815 19.6347 18.4312 19.2548 18.9178 18.7358C19.1752 18.4613 19.3913 18.1531 19.5611 17.8211C19.8125 17.3293 20.3376 16.9822 20.8762 17.1041L24.7775 17.9874C25.3161 18.1094 25.6587 18.6471 25.4836 19.1709C25.0281 20.5334 24.283 21.7856 23.2951 22.8393C22.0786 24.137 20.538 25.0867 18.8321 25.5905C17.1262 26.0943 15.3168 26.134 13.5905 25.7055C11.8642 25.277 10.2834 24.3958 9.01113 23.1527C7.73887 21.9097 6.82119 20.3497 6.35271 18.6338Z"
                fill="#B8ACFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 7C16 6.44772 16.4488 5.99482 16.9983 6.04996C17.9691 6.14736 18.9222 6.38648 19.8268 6.7612C21.0401 7.26375 22.1425 8.00035 23.0711 8.92893C23.9997 9.85752 24.7362 10.9599 25.2388 12.1732C25.6135 13.0778 25.8526 14.0309 25.95 15.0017C26.0052 15.5512 25.5523 16 25 16H21C20.4477 16 20.0123 15.5455 19.8756 15.0104C19.8287 14.8264 19.7685 14.6455 19.6955 14.4693C19.4945 13.984 19.1999 13.543 18.8284 13.1716C18.457 12.8001 18.016 12.5055 17.5307 12.3045C17.3545 12.2315 17.1736 12.1713 16.9896 12.1244C16.4545 11.9877 16 11.5523 16 11L16 7Z"
                fill="#755FFF"
            />
        </svg>
    );
}
