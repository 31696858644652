import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import IconClose from 'assets/icon/IconClose';
import IconEdit from 'assets/icon/IconEdit';
import IconPlus from 'assets/icon/IconPlus';
import IconTrash from 'assets/icon/IconTrash';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import DeletePhoneLabel from './DeletePhoneLabel';
import EditPhoneLabel from './EditPhoneLabel';
import { reducer } from 'app/const/Reducer';

const ManagePhoneLabels = ({ onCreatePhone = () => {} }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false
    });
    const { phoneTypes } = useSelector(({ phoneTypes }) => phoneTypes);

    const refAlert = useRef(null);
    const refDelete = useRef(null);
    const refUpdate = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleDelete = (id) => {
        refDelete.current._open(id);
    };

    const _handleUpdate = (item) => {
        refUpdate.current._open(item);
    };

    const _handleShowAlert = (data) => refAlert.current.showStatusBar(data);

    if (!state.isOpen) return null;
    return (
        <>
            <ReactModal
                isOpen
                id="manage_phone_label"
                style={{ overlay: { background: 'transparent' } }}
                onRequestClose={_close}
                portalClassName="ReactModalPortal_customer"
                className="modal --has-contain container-modal --manage-phone-label open"
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h3 className="header-modal__label">
                            {t('manage_phone_labels')}
                            <div className="v2-btn-default has-icon btn-modal" onClick={onCreatePhone} tabIndex="0">
                                <IconPlus />
                                {t('add_new')}
                            </div>
                        </h3>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_close} tabIndex="0">
                            <IconClose />
                        </div>
                    </div>
                    <AlertCustomer ref={refAlert} />
                    <div className="body-modal list-types scrolls">
                        <ListPhone data={phoneTypes} onUpdate={_handleUpdate} onDelete={_handleDelete} />
                    </div>
                </div>
            </ReactModal>

            {/* Modals */}
            <EditPhoneLabel ref={refUpdate} onShowAlert={_handleShowAlert} />
            <DeletePhoneLabel ref={refDelete} onShowAlert={_handleShowAlert} />
        </>
    );
};

const ListPhone = ({ data = [], onUpdate = () => {}, onDelete = () => {} }) => {
    return data.map((item) => {
        return (
            <div key={item.id} className="list-types__rows">
                <p className="label-type">{item.name}</p>
                {!item.is_default && (
                    <>
                        <div
                            className="btn-modal v2-btn-default --icon-lg --transparent"
                            onClick={() => onUpdate(item)}
                            tabIndex="0"
                        >
                            <IconEdit />
                        </div>
                        <div
                            className="btn-modal v2-btn-default --icon-lg --delete"
                            onClick={() => onDelete(item.id)}
                            tabIndex="0"
                        >
                            <IconTrash />
                        </div>
                    </>
                )}
            </div>
        );
    });
};

ManagePhoneLabels.displayName = 'ModalManagePhoneLabels';
export default forwardRef(ManagePhoneLabels);
