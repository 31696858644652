import {
    SETTINGS_CREATE_BROADCAST,
    SETTINGS_GET_LIST_BROADCAST_TEMPLATE,
    SETTINGS_UPDATE_BROADCAST
} from 'app/const/Api';
import { fetchWithToken, postWithToken, putWithToken, deleteWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListBroadcast({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_BROADCAST_TEMPLATE);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getBroadcastDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_GET_LIST_BROADCAST_TEMPLATE, params);

        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (err) {
        actionFailed(err);
    }
}

export function* createBroadcast({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(postWithToken, SETTINGS_CREATE_BROADCAST, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateBroadcast({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_UPDATE_BROADCAST, params);
        if (response && response.success) {
            actionSuccess(response, params);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusBroadcast({ params, actionSuccess, actionFailed, actionFinally }) {
    try {
        const response = yield call(deleteWithToken, SETTINGS_GET_LIST_BROADCAST_TEMPLATE, params);

        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
        actionFinally();
    } catch (error) {
        actionFailed(error);
    }
}
