import React from 'react'

function LoadingBarLittle() {
    return (
        <div className="loading --ajaxbar">
            <div className="loading__barlittle --first" />
            <div className="loading__barlittle --second" />
            <div className="loading__barlittle --three" />
            <div className="loading__barlittle --four" />
        </div>
    )
}

export default LoadingBarLittle

