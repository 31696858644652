import GdButton from 'app/components/button';
import ButtonSave from 'app/components/button/ButtonSave';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = ({ onClose = () => {}, isEdit = false, onSave = () => {} }, ref) => {
    const { t } = useTranslation(['setting']);
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({
        _setDisableSave,
        _removeLoadingSave
    }));

    const _setDisableSave = (disable) => {
        refButtonSave.current.getStatusBtn() !== disable && refButtonSave.current.setDisable(disable);
    };

    const _removeLoadingSave = () => {
        refButtonSave.current.removeLoading();
    };

    return (
        <div className="template-content__footer shadow-top-brown flex-betweenitems">
            <GdButton className="v2-btn-default" title={t('close')} onClick={onClose} />
            <ButtonSave
                ref={refButtonSave}
                wrapClass="v2-btn-main"
                title={isEdit ? t('save_changes') : t('save')}
                onSave={onSave}
            />
        </div>
    );
};
export default forwardRef(Footer);
