import React from 'react';
import { useTranslation } from 'react-i18next';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import IconSync from 'assets/icon/IconSync';
import { useSelector } from 'react-redux';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';

const ActionHeader = ({
    checkedItems = {},
    totalItems = 0,
    isLoadCustomerSuccess = false,
    onAddCredit = () => {},
    onDelete = () => {},
    onHandleQuickBook = () => {}
}) => {
    const { t } = useTranslation(['report']);
    const { quickbooks } = useSelector(({ auth }) => auth.user.settings?.addons);

    return (
        <div className="header --filter">
            <div className="header__left flex-1">
                <CheckBoxHeader isShowTotal total={totalItems} checkedItems={checkedItems} />
                <div
                    className={classNames('v2-btn-default --grey has-icon svg-delete-grey header-items', {
                        'is-disable': !checkedItems.ids.length
                    })}
                    onClick={() => onDelete()}
                >
                    <IconTrash />
                    {t('report:delete')}
                </div>
                {!!quickbooks && (
                    <div
                        className={classNames('v2-btn-default --grey has-icon header-items', {
                            'is-disable': !checkedItems.ids.length
                        })}
                        onClick={onHandleQuickBook}
                    >
                        <IconSync />
                        {t('sync_to_qb')}
                    </div>
                )}
            </div>
            <div
                className={classNames('v2-btn-main btn-modal --bg-green', { 'is-disable': isLoadCustomerSuccess })}
                onClick={onAddCredit}
            >
                {t('customers:new_credit')}
            </div>
        </div>
    );
};

export default ActionHeader;
