import DatePickerInput from 'app/components/date/DateInput';
import IconFuture from 'assets/icon/IconFuture';
import { convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment';
import React from 'react';

const InputDate = ({ date = '', onChange = () => {} }) => {
    const finalDate = convertTimeToISO(moment(date).startOf('date'));
    const _handleChange = (date) => {
        onChange(moment(date).format('YYYY-MM-DD'));
    };
    return (
        <div className="field">
            <div className="search-input">
                <span className="svg-search-absolute">
                    <IconFuture />
                </span>
                <DatePickerInput
                    selectDefault={finalDate}
                    isAutofocus={!!!date}
                    isShowIcon={false}
                    isShouldDefaultValue={false}
                    onChange={_handleChange}
                />
            </div>
        </div>
    );
};

export default InputDate;
