import ButtonSave from 'app/components/button/ButtonSave';
import { UPDATE_ADDONS_INBOX_EMAIL_SETTINGS } from 'app/const/Api';
import IconEdit from 'assets/icon/IconEdit';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { reducer } from 'app/const/Reducer';

const EditFormModal = forwardRef(
    ({ isSended = 0, isSynced = 0, onUpdateSuccess = () => {}, onUpdateSyncFail = () => {} }, ref) => {
        const [state, dispatchState] = useReducer(reducer, { isOpen: false, email: '', selectedName: '', options: [] });
        const refButtonSave = useRef(null);
        const refForm = useRef(null);
        const { isOpen } = state;

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = () => {
            dispatchState({ isOpen: true });
        };

        const _close = () => {
            dispatchState({ isOpen: false, email: '', selectedName: '', options: [] });
        };

        const _handleSaveForm = () => {
            const data = {};
            const elements = refForm.current.elements;

            for (let index = 0; index < elements.length; index++) {
                const { type, name, checked } = elements[index];
                if (type === 'checkbox') data[name] = checked ? 1 : 0;
            }

            const _handleUpdateSuccess = () => {
                onUpdateSuccess(data);
                _close();
            };

            const _handleUpdateFail = (res) => {
                onUpdateSyncFail(res);
                _close();
            };

            const _handleUpdateFinally = () => refButtonSave.current.removeLoading();

            clientQuery(
                UPDATE_ADDONS_INBOX_EMAIL_SETTINGS,
                {
                    data,
                    toFormData: false,
                    method: 'PUT'
                },
                _handleUpdateSuccess,
                _handleUpdateFail,
                _handleUpdateFinally
            );
        };

        if (!isOpen) return null;
        return (
            <ReactModal
                id="set_up_email"
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal open"
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className="modal__container large">
                    <div className="header-modal">
                        <h3 className="header-modal__label">Set Up Your Email</h3>
                    </div>
                    <form ref={refForm} className="body-modal scrolls --email">
                        <div className="row">
                            <div className="check-items">
                                <input id="setup_email_1" name="send" type="checkbox" defaultChecked={isSended} />
                                <div className="item-checkbox">
                                    <label htmlFor="setup_email_1">
                                        <span className="title">Enable email sending</span>
                                    </label>
                                </div>
                            </div>
                            <div className="description">Send emails from this email account, in GorillaDesk.</div>
                        </div>
                        <div className="row">
                            <div className="check-items has-tag">
                                <input id="setup_email_2" name="sync" type="checkbox" defaultChecked={isSynced} />
                                <div className="item-checkbox">
                                    <label htmlFor="setup_email_2">
                                        <span className="title pr-1">Enable email sync</span>
                                        <span className="status-btn upgrade-pro fw-500 bg-orange-default">
                                            Highly Recommended
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="description">
                                Attach incoming &amp; outgoing emails (even those sent outside of GorillaDesk) to Leads
                                and share them with coworkers.
                            </div>
                        </div>
                    </form>
                    <div className="footer-modal btn-close">
                        <span className="v2-btn-default --transparent" onClick={_close}>
                            Cancel
                        </span>
                        <ButtonSave ref={refButtonSave} onSave={_handleSaveForm} />
                    </div>
                </div>
            </ReactModal>
        );
    }
);

const EditEmailInfoGG = (props) => {
    const { t } = useTranslation();
    const refModal = useRef(null);

    const _handleEdit = () => refModal.current._open();

    return (
        <div>
            <div className="boxs__footer">
                <div className="v2-btn-default svg-purple --purple --icon-r btn-modal" onClick={_handleEdit}>
                    {t('manager')} <IconEdit />
                </div>
            </div>
            <EditFormModal ref={refModal} {...props} />
        </div>
    );
};

export default EditEmailInfoGG;
