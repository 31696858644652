import React, { useReducer, useRef, useEffect, useContext } from 'react';
import { AddPaymentContext } from 'app/modules/jobdetail/contexts/AddPaymentContext';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import { INVOICE_PAYMENT_STATUS } from 'app/modules/jobdetail/const/Invoice';
import IconCalendar from 'assets/icon/IconCalendar';
import IconDropDown from 'assets/icon/IconDropDown';
import { reducer } from 'app/const/Reducer';

const InvoiceStatus = () => {
    const { addPayment, updatePaymentDataContext } = useContext(AddPaymentContext);
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });
    const refDropDown = useRef(null);
    const isVisible = state.isVisible;

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    const _stopPropagation = (e) => {
        e && e.stopPropagation();
    };

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById('show_list_option_payment_invoice_status');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_option_payment_invoice_status');
        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isVisible && dispatchState({ isVisible: false });
    };

    const _handleOpenDropdown = (e) => {
        _stopPropagation(e);

        dispatchState({ isVisible: !isVisible });
    };

    function _handleSelect(e, status, isActive) {
        e && e.stopPropagation();
        if (isActive) {
            return false;
        }
        _closeDropdown();
        updatePaymentDataContext({ filterInvoice: status });
    }

    function _renderStatus() {
        if (!isVisible) {
            return false;
        }

        return INVOICE_PAYMENT_STATUS.map((item) => {
            const statusValue = item.value;
            const isActive = statusValue === addPayment.filterInvoice;

            return (
                <li
                    onClick={(e) => _handleSelect(e, statusValue, isActive)}
                    key={statusValue}
                    className={`items text-capitalize ${statusValue === '-1' ? 'select-all' : ''} ${
                        isActive ? 'active' : ''
                    }`}
                    tabIndex="0"
                >
                    <span className={`dots --${statusValue}`} />
                    {item.name}
                </li>
            );
        });
    }

    function _renderSelected() {
        const itemSelected = INVOICE_PAYMENT_STATUS.find((item) => item.value === addPayment.filterInvoice) || {};

        return (
            <div className="d-flex align-center text-capitalize">
                {itemSelected.value !== '-1' && <span className={`dots --${itemSelected.name}`} />}
                {itemSelected.name}
            </div>
        );
    }

    return (
        <div className="sm-row mt-5">
            <div className="txt">
                <IconCalendar />
                Invoice Status
            </div>
            <div className="detail">
                <div ref={refDropDown} className={`v2-dropdown select-invoice-stt ${isVisible ? 'active' : ''}`}>
                    <div onClick={_handleOpenDropdown} className="dropbtn items selection" tabIndex="0">
                        {_renderSelected()}
                        <IconDropDown />
                    </div>
                    <div id={'show_list_option_payment_invoice_status'} className="v2-dropdown__menu scrolls">
                        <ul>{_renderStatus()}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceStatus;
