import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, TYPES_BOOKMARK } from 'app/modules/calendar/const/BookMark';
import { SocketContext } from 'app/services/socket/SocketProvider';
import { addNewBookMarkAction, setAllBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';

const BookMarkOption = loadable(() => import('app/modules/calendar/components/bookmark/components/Options'));
const BookMarkItem = loadable(() => import('app/modules/calendar/components/bookmark/components/BookMarkItem'));

const BookMark = () => {
    const dispatch = useDispatch();
    const { jobStatus } = useContext(SocketContext);
    const bookMarkList = useSelector((state) => state.bookMarkReducer.list || []);

    /**
     * First time init we need get local stogare save list book mark. ( only first time )
     * - get list book from local we will set this list with action setAllBookMarkAction
     * - add new bookmark with action addNewBookMarkAction
     * - remove 1 bookmark with action removeBookMarkAction
     * - remove all bookmark with action removeAllBookMarkAction
     */
    useEffect(() => {
        const listBookmardLocal = getLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER);
        if (Array.isArray(listBookmardLocal) && !!listBookmardLocal.length) {
            dispatch(setAllBookMarkAction(Array.isArray(listBookmardLocal) ? listBookmardLocal : []));
        }
    }, []);

    useEffect(() => {
        const statusResponse = jobStatus?.status?.toString();
        if (!statusResponse) return;
        bookMarkList.forEach((prevItem) => {
            const { data, type } = prevItem;
            if (data.id === jobStatus.jobId && [TYPES_BOOKMARK.JOB_DETAIL, TYPES_BOOKMARK.JOB].includes(type)) {
                dispatch(addNewBookMarkAction({ ...prevItem, data: { ...prevItem.data, status: statusResponse } }));
            }
        });
    }, [jobStatus]);

    function _renderListBookMark() {
        return bookMarkList.map((item) => {
            return <BookMarkItem key={item.id} markData={item} />;
        });
    }

    const elemntMain = document.getElementById('main-page');

    if (bookMarkList.length === 0) {
        elemntMain && elemntMain.classList.remove('has-jobtabs');
        return false;
    } else {
        elemntMain && elemntMain.classList.add('has-jobtabs');
    }

    return (
        <div className="job-group">
            <div className="wrap-job-group">
                <div className="job-group-lists">{_renderListBookMark()}</div>
                <BookMarkOption />
            </div>
        </div>
    );
};

export default BookMark;
