export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={24}
        height={24}
        viewBox="0 0 24 24"
    >
        <defs>
            <path id="v55blfzvwa" d="M0 0H24V24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g>
                <path d="M0 0H1175V3224H0z" transform="translate(-307 -2929)" />
                <g>
                    <g transform="translate(-307 -2929) translate(128 2929) translate(179)">
                        <mask id="y1u7pbhnab" fill="#fff">
                            <use xlinkHref="#v55blfzvwa" />
                        </mask>
                        <g mask="url(#y1u7pbhnab)">
                            <path d="M0 0H24V24H0z" />
                            <path
                                fill="#C4CCD9"
                                d="M7 6h12c.552 0 1 .448 1 1v10.586c0 .552-.448 1-1 1-.265 0-.52-.106-.707-.293l-2-2c-.188-.188-.442-.293-.707-.293H7c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1z"
                            />
                            <rect width={13} height={2} x={4} y={8} fill="#7489AE" rx={1} />
                            <rect width={9} height={2} x={4} y={12} fill="#7489AE" rx={1} />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
