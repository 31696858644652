import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { jobSummary } from 'app/const/Api';

export function* actionGetJobSummary({ params, actionSuccess, actionFailed }) {
    try {

        const jobId = params.jobId;
        delete params.jobId;

        const response = yield call(
            fetchWithToken,
            jobSummary(jobId),
            params
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
