import GdSearch from 'app/components/search/GdSearch';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import React, { useEffect } from 'react';

function ReportSearch({
    reportType = '',
    placeholder = '',
    isSearchOnClear = false,
    isShowClearIcon = true,
    onKeyEnter = () => {},
    onChangeImmediately = () => {}
}) {
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', reportType);

    const setParams = (value) => {
        const newParams = getLocalStorage(keyLocal);

        if (!!newParams && typeof newParams === 'object') {
            newParams.keyword = value;
            setLocalStorage(keyLocal, newParams);
        }
    };

    useEffect(() => {
        setParams('');
        return () => {
            setParams('');
        };
    }, []);

    const handleSearch = (value) => {
        setParams(value);
    };

    const _handleOnKeyEnter = (value) => {
        setParams(value);
        onKeyEnter && onKeyEnter();
    };

    return (
        <GdSearch
            placeholder={placeholder}
            onSearch={handleSearch}
            onKeyEnter={_handleOnKeyEnter}
            defaultValue={''}
            isSearchOnClear={isSearchOnClear}
            style="header-items"
            isShowClearIcon={isShowClearIcon}
            onChangeImmediately={onChangeImmediately}
        />
    );
}

export default ReportSearch;
