import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconDropDown from 'assets/icon/IconDropDown';
import IconLoading from 'assets/icon/IconLoading';
import classNames from 'classnames';
import React, { useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { COMMON, DEFAULT_ALL } from 'app/const/App';
import { reducer } from 'app/const/Reducer';

const ID_DROPDOWN = 'select_with_search_dropdown';

const SelectWithSearch = ({
    name,
    classWrapper = '',
    options: optionsDefault = [],
    onGetAsyncOptions = () => {},
    selected,
    onSelect
}) => {
    const [state, dispatchState] = useReducer(reducer, { isVisible: false, options: optionsDefault, selected });
    const { id } = useParams();
    const { t } = useTranslation('customers');
    const refDropdown = useRef(null);
    const refInput = useRef(null);
    const isFirstTime = useRef(true);
    const refData = useRef(optionsDefault);

    const options = state.options;
    const isVisible = state.isVisible;

    useEffect(() => {
        refData.current = optionsDefault;
        dispatchState({ options: optionsDefault });
    }, [optionsDefault]);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [isVisible]);

    const _handleOpen = () => {
        if (!isVisible && isFirstTime.current) {
            onGetAsyncOptions({
                item: name,
                params: { customer_id: id, isMergedId: true, has_job: 1, order: COMMON.SERVICE_ADDRESS },
                callBack: () => {
                    isFirstTime.current = false;
                }
            });
        }
        !isVisible && dispatchState({ isVisible: true });
    };

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById(ID_DROPDOWN);
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    };

    const _closeDropdown = () => {
        isVisible && dispatchState({ isVisible: false });
    };

    const handleHideDropdown = (event) => {
        const elPrevent = document.getElementById(ID_DROPDOWN);
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) _closeDropdown();
    };

    const _handleSelectOption = (item) => {
        refInput.current.value = item.address || item.name;

        const finalValue = item?.value || item?.id || DEFAULT_ALL;
        dispatchState({ selected: item });

        _closeDropdown();
        onSelect(name, finalValue);
    };

    /**
     * Handle input change
     * @param {event} event - Event of input DOM
     */
    const _handleInputChange = (event) => {
        dispatchState({
            options: refData.current.filter((locations) =>
                locations.name.toLowerCase().startsWith(event.target.value.toLowerCase())
            )
        });
    };

    const _renderOptionAll = () => {
        return (
            <li
                className={classNames('items', { active: !state.selected.id })}
                onClick={() => {
                    _handleSelectOption({ name: t('customers:all_location') });
                }}
            >
                {t('customers:all_location')}
            </li>
        );
    };

    // Render Handles
    const _renderList = () => {
        if (isFirstTime.current && !options.length) return <LoadingIcon />;
        if (!isFirstTime.current && !options.length)
            return (
                <div className="items">
                    <div className="i-left">
                        <div className="content">
                            <p className="name fw-500">{t('header:not_found')}</p>
                        </div>
                    </div>
                </div>
            );

        return state.options.map((item, index) => {
            if (!item.address) {
                return null;
            }

            return (
                <li
                    key={item.id || index.toString()}
                    className={classNames('items', { active: state.selected.id === item.id })}
                    onClick={() => _handleSelectOption(item)}
                >
                    <p className="txt-ellipsis">{item.address}</p>
                </li>
            );
        });
    };

    const renderTitle = () => {
        return (
            <>
                <div className="search-input">
                    <input
                        ref={refInput}
                        type="text"
                        placeholder={t('customers:search...')}
                        defaultValue={t('customers:all_location')}
                        onFocus={_handleOpen}
                        onChange={_handleInputChange}
                        spellCheck
                    />
                    <div className="close-icon dp-hide">
                        <IconCircleClose />
                    </div>
                </div>
                <IconDropDown />
            </>
        );
    };

    return (
        <div ref={refDropdown} className={classNames('v2-dropdown', classWrapper, { active: isVisible })}>
            <div onClick={_handleOpen} className="dropbtn items --grey">
                {renderTitle()}
            </div>
            <div id={ID_DROPDOWN} className="v2-dropdown__menu content-full scrolls">
                <ul>
                    {_renderOptionAll()}
                    {_renderList()}
                </ul>
            </div>
        </div>
    );
};

const LoadingIcon = () => (
    <div className="items justify-center">
        <div className="loading -ajaxbar">
            <IconLoading />
        </div>
    </div>
);

export default SelectWithSearch;
