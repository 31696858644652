import React from 'react';

const LoadingSettings = () => {
    return (
        <div className="wrap-loading body-modal --addon">
            <div className="portal-settings__description">
                <div className="loading --animation --line --full" />
                <div className="loading --animation --line --full" />
            </div>
            <ul className="portal-settings__list">
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --half" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </li>
                <li className="check-items">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default LoadingSettings;
