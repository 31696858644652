import React, { useReducer, useRef, useEffect } from 'react';
import IconUpgrade from 'assets/icon/IconUpgrade';
import IconTooltip from 'assets/icon/IconTooltip';
import ListTax from './ListTax';
import ButtonSave from 'app/components/button/ButtonSave';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACTIONN_UPDATE_QUICKBOOKS_ADDON, GET_LIST_QUICKBOOKS_TAX } from 'app/const/api/V2';
import MappingTax from '../mapping';
import { reducer } from 'app/const/Reducer';

let timmer;

export default function TabTaxSync({ parentData, taxData, onUpdateTaxData, onUpdateOption, keyword }) {
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        ...parentData,
        isGroup: !!taxData.auto_group_tax,
        valueTax: taxData.tax_code || 0,
        isFetched: false,
        tax_codes: []
    });

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refMappingTax = useRef(null);

    const finalIsActive = !!state.value;

    const {
        country,
        using_sales_tax: usingSalesTax,
        isGroup: finalIsGroup,
        valueTax,
        partner_tax_enabled: parterTaxEnabled
    } = state;
    const finalTaxSelected = valueTax.toString();

    const isHideToggle =
        (country === 'CA' && !usingSalesTax) || (usingSalesTax && country !== 'US' && country !== 'CA');

    useEffect(() => {
        _getListTax();
    }, []);

    function _getListTax() {
        clientQuery(GET_LIST_QUICKBOOKS_TAX, { method: 'GET' }, _getListTaxSuccess, _getListTaxFailed);
    }

    function _getListTaxSuccess(response) {
        dispatchState({
            ...response.data,
            isFetched: true
        });
    }

    function _getListTaxFailed() {
        dispatchState({
            isFetched: true
        });
    }

    function _handleChangeValue() {
        dispatchState({
            value: !finalIsActive
        });

        timmer && clearTimeout(timmer);
        timmer = setTimeout(() => {
            onUpdateOption(keyword, !finalIsActive ? 1 : 0);
        }, 500);
    }

    function _handleChangeOption() {
        dispatchState({
            isGroup: !finalIsGroup
        });
    }

    function _handleSelectTax(value) {
        dispatchState({
            valueTax: value
        });
    }

    function _handleSave() {
        let params = {
            is_auto_tax: 1,
            auto_group_tax: 1
        };

        if (!finalIsGroup) {
            if (!finalTaxSelected) {
                _removeButtonLoading();
                refStatusBar.current.showStatusBar('show_error', 'Please select tax code', LIST_STATUS.ERROR);
                return false;
            }
            params = {
                is_tax: 1,
                tax: finalTaxSelected
            };
        }

        clientQuery(
            ACTIONN_UPDATE_QUICKBOOKS_ADDON,
            {
                method: 'PUT',
                data: params
            },
            _handleSaveSuccess,
            _handleSaveFailed
        );
    }

    function _handleSaveSuccess() {
        onUpdateTaxData({
            ...taxData,
            auto_group_tax: finalIsGroup ? 1 : 0,
            tax_code: finalTaxSelected
        });
        _removeButtonLoading();
        refStatusBar.current.showStatusBar('show_error', 'Update successfully', LIST_STATUS.SUCCESS);
    }

    function _handleSaveFailed(response) {
        _removeButtonLoading();
        refStatusBar.current.showStatusBar(
            'show_error',
            response?.message?.toString() || 'Please try again',
            LIST_STATUS.ERROR
        );
    }

    function _removeButtonLoading() {
        refButtonSave.current.removeLoading();
    }

    function _checkDisable() {
        if (
            finalIsGroup !== !!taxData.auto_group_tax ||
            (!finalIsGroup && finalTaxSelected !== taxData.tax_code.toString())
        ) {
            return false;
        }
        return true;
    }

    function _showGroupTax() {
        refMappingTax.current._open();
    }

    const _renderFinalContent = () => {
        if (country === 'US' && !usingSalesTax) {
            return <p className={'description'}>{'Oops! you need to enable Sales Tax in QuickBooks Online.'}</p>;
        }

        if (country !== 'US' && country !== 'CA') {
            return <p className={'description'}>{'This feature is only supported for USA tax codes.'}</p>;
        }

        if (usingSalesTax && country === 'US') {
            if (parterTaxEnabled) {
                return (
                    <p className={'description'}>
                        {
                            'You have Automatic Taxes turned on in QuickBooks Online. There is no need to configure any tax mappings.'
                        }
                    </p>
                );
            }

            return (
                <div className={'tax-desc'}>
                    <div className="check-radio">
                        <input
                            onChange={_handleChangeOption}
                            id="taxe_sync"
                            type="radio"
                            name="TaxSync"
                            checked={!finalIsGroup}
                        />
                        <label htmlFor="taxe_sync" className="check-radio__label">
                            Best for a single tax rate. Select which tax account to sync to:
                        </label>
                    </div>
                    <ListTax
                        tax_codes={state.tax_codes}
                        isFetched={state.isFetched}
                        isGroup={finalIsGroup}
                        valueTax={finalTaxSelected}
                        onSelect={_handleSelectTax}
                    />
                    <div className="check-radio mt-3">
                        <input
                            onChange={_handleChangeOption}
                            id="taxe_groups"
                            type="radio"
                            checked={finalIsGroup}
                            name="TaxSync"
                        />
                        <label htmlFor="taxe_groups" className="check-radio__label">
                            Best for multi-tax Groups. Automatically create tax groups.
                        </label>
                    </div>
                    <div className="wrap-tooltip">
                        <p onClick={_showGroupTax} className="description violet-name btn-modal">
                            Map your existing Quickbooks tax groups to GorillaDesk
                        </p>
                        <div className="tooltip">
                            <IconTooltip />
                            <div className="tooltiptext top">
                                If you agree to select this option, We will create Combined tax groups and push them
                                into Quickbooks Online.
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (!parterTaxEnabled && country === 'CA' && usingSalesTax) {
            return (
                <div className={'tax-desc'}>
                    <p className={'description'}>{'Best for multi-ttax Groups. Automatically create tax groups.'}</p>
                    <div className="wrap-tooltip">
                        <p onClick={_showGroupTax} className="description violet-name btn-modal">
                            Map your existing Quickbooks tax groups to GorillaDesk
                        </p>
                        <div className="tooltip">
                            <IconTooltip />
                            <div className="tooltiptext top">
                                If you agree to select this option, We will create Combined tax groups and push them
                                into Quickbooks Online.
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return false;
    };

    return (
        <div className={`boxs boxs-has-footer ${finalIsActive ? 'active' : ''}`}>
            <div className="boxs__header">
                <div className="header-title">
                    <div className="title">{parentData.name}</div>
                    <div className="dots powerups">
                        <IconUpgrade isFillOrange />
                        Power Up
                    </div>
                </div>
                {!isHideToggle && (
                    <div className="switch large">
                        <input
                            id={'toggle_tax'}
                            className="toggle toggle-round"
                            checked={finalIsActive}
                            onChange={_handleChangeValue}
                            type="checkbox"
                        />
                        <label htmlFor={'toggle_tax'}>
                            <span className="enable-disable" />
                        </label>
                    </div>
                )}
            </div>
            <div className={'boxs__contents'}>
                <StatusBar ref={refStatusBar} />
                <p className={'description --hasline'}>{parentData.description}</p>
                {finalIsActive && _renderFinalContent()}
            </div>
            {usingSalesTax && country === 'US' && !parterTaxEnabled && (
                <div className="boxs__footer">
                    <ButtonSave disabled={_checkDisable()} ref={refButtonSave} onSave={_handleSave} />
                </div>
            )}
            <MappingTax taxCode={state.tax_codes || []} ref={refMappingTax} />
        </div>
    );
}
