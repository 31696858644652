import React, { useState, forwardRef, useImperativeHandle } from 'react';

const ShowMoreComment = forwardRef(({ onPress = () => { }, number, total }, ref) => {

    const [isLoading, setIsLoading] = useState(false);

    function _removeLoading() {
        setIsLoading(false);
    }

    useImperativeHandle(ref, () => ({
        removeLoading: _removeLoading
    }));

    function _handleClick() {
        setIsLoading(true);
        onPress();
    }

    if (total <= number) {
        return false;
    }

    return (
        <div
            className="rows-load-more"
            onClick={_handleClick}
        >
            <div className="rows-load-more__btn flex-centeritem">
                {`View All ${total} Comments`}
                {
                    isLoading && (
                        <div className="preloader-small">
                            <span className="preloader-small__items" />
                        </div>
                    )
                }
            </div>
        </div>
    );
});

export default ShowMoreComment;