import React from 'react';

import GdLazyImage from 'app/components/lazyimage';
import { AVATAR_DEFAULT } from 'app/const/App';

const GdAvatar = ({ width = '32', height = '32', src = AVATAR_DEFAULT, alt = 'avatar' }) => {
    return <GdLazyImage src={src || AVATAR_DEFAULT} alt={alt} width={width} height={height} />;
};

export default GdAvatar;
