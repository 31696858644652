import React from 'react';

export default function IconTopNote() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.99928 4.01678L6 4C6 5.65685 7.34315 7 9 7H11C12.6511 7 13.9907 5.66611 14 4.01714C14.4873 4.05076 14.7313 4.13582 14.9768 4.26713C15.3028 4.44144 15.5586 4.69723 15.7329 5.02315C15.9072 5.34908 16 5.67237 16 6.56389V9H12C10.4023 9 9.09634 10.2489 9.00509 11.8237L9 12V17H6.56389C5.67237 17 5.34908 16.9072 5.02315 16.7329C4.69723 16.5586 4.44144 16.3028 4.26713 15.9768C4.09283 15.6509 4 15.3276 4 14.4361V6.56389C4 5.67237 4.09283 5.34908 4.26713 5.02315C4.44144 4.69723 4.69723 4.44144 5.02315 4.26713C5.26868 4.13582 5.51271 4.05076 5.99928 4.01678Z"
                fill="#C1C9D4"
            />{' '}
            <path
                d="M11.5 3H8.5C7.67157 3 7 3.67157 7 4.5C7 5.32843 7.67157 6 8.5 6H11.5C12.3284 6 13 5.32843 13 4.5C13 3.67157 12.3284 3 11.5 3Z"
                fill="#7A83A6"
            />{' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 10H18C19.1046 10 20 10.8954 20 12V19.6953C20 20.2476 19.5523 20.6953 19 20.6953C18.7463 20.6953 18.5021 20.5989 18.3169 20.4256L16.3662 18.601C15.5974 17.8819 14.4026 17.8819 13.6338 18.601L11.6831 20.4256C11.2798 20.8029 10.647 20.7818 10.2697 20.3784C10.0964 20.1932 10 19.949 10 19.6953V12C10 10.8954 10.8954 10 12 10Z"
                fill="#FF871E"
            />{' '}
        </svg>
    );
}
