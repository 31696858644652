import { convertWorklog } from '.';
import { RESOURCE_TYPES } from '../constant';

export const getGroupsEvents = (data = {}) => {
    let result = [];
    for (const key in data) {
        result = [...result, ...convertGroups(data[key], key)];
    }
    return result;
};

const createDataItem = (data, groupId) => {
    return { ...data, group_id: groupId, props: { overlapEvents: data.overlap || [], parentId: groupId } };
};

export const convertGroups = (data, groupId) => {
    const dataGroups = data || [];
    const lastIndex = dataGroups.length - 1;
    let result = [];
    let currentLoop = dataGroups[0];

    // check dataGroups is only one item
    if (dataGroups.length === 1) {
        const dataItem = createDataItem(currentLoop, groupId);
        result = [...result, convertWorklog(dataItem, RESOURCE_TYPES.GROUP)];
        return result;
    }

    dataGroups.forEach((group, index) => {
        if (currentLoop.id === group.id) return;
        if (currentLoop.end >= group.start) {
            // check currentLoop is overlap time with group
            currentLoop.start = Math.min(currentLoop.start, group.start);
            currentLoop.end = Math.max(currentLoop.end, group.end);
            currentLoop.overlap = [...new Set([...currentLoop.overlap, ...group.overlap])];

            if (index === lastIndex) {
                const dataItem = createDataItem(currentLoop, groupId);
                result = [...result, convertWorklog(dataItem, RESOURCE_TYPES.GROUP)];
                currentLoop = group;
            }
        } else {
            const dataItem = createDataItem(currentLoop, groupId);
            result = [...result, convertWorklog(dataItem, RESOURCE_TYPES.GROUP)];
            currentLoop = group;

            if (index === lastIndex) {
                const dataItem = createDataItem(currentLoop, groupId);
                result = [...result, convertWorklog(dataItem, RESOURCE_TYPES.GROUP)];
            }
        }
    });

    return result;
};
