import React from 'react';
import { useTranslation } from 'react-i18next';

const HeaderPipelines = ({ title = '' }) => {
    const { t } = useTranslation('setting');

    return (
        <div className="boxs__header flex-column gap-4">
            <p className="fs-16">{title || t('title_opportunity_pipeline_and_status')}</p>
            <p className="black-darker3">{t('desc_opportunity_pipeline_and_status')}</p>
        </div>
    );
};

export default HeaderPipelines;
