import GridEmpty from 'app/components/grid/components/GridEmpty';
import { getDeleteDocumentAddons } from 'app/const/Api';
import { URL_PDF_DOCUMENT_TEMPLATE } from 'app/const/api/Export';
import { ACCESS_TOKEN } from 'app/const/App';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import IconEye from 'assets/icon/IconEye';
import classNames from 'classnames';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { getPlanUser } from 'common/utils/PermissionUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ConfirmDelete from './ConfirmDelete';
import { reducer } from 'app/const/Reducer';
import { getBranchId } from 'app/const/Branch';
import NameTagPdf from './manage/NameTagPdf';

const ListDocument = ({ data = [], onShowAlert = () => {} }) => {
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const [state, dispatchState] = useReducer(reducer, {
        data: data,
        typeAccount: {
            isTrial: true,
            isProPlan: false,
            isBasicPlan: false
        }
    });
    const { data: documents = [], typeAccount = {} } = state;
    const isDisable = typeAccount?.isBasicPlan;

    useEffect(() => {
        dispatchState({ typeAccount: getPlanUser(user?.profile) });
    }, []);

    const refConfirm = useRef(null);

    const _handleDelete = (document) => {
        refConfirm.current._open(document, {
            url: getDeleteDocumentAddons(document.id),
            options: { data: {}, method: 'DELETE' }
        });
    };

    const _handleDeleteSuccess = (id, message) => {
        dispatchState({ data: documents.filter((item) => item.id !== id) });
        onShowAlert({ id: 'delete-success', message, type: LIST_STATUS.SUCCESS });
    };

    const _handleDeleteFail = (message) => {
        onShowAlert({ id: 'delete-fail', message, type: LIST_STATUS.ERROR });
    };

    const _renderListDocument = () => {
        return documents.map((item) => <ItemDocument key={item.id} onDelete={_handleDelete} {...item} />);
    };

    return (
        <>
            {documents.length ? (
                <div
                    className={classNames('list__document bg-white', {
                        'is-disable': isDisable
                    })}
                >
                    {_renderListDocument()}
                </div>
            ) : (
                <GridEmpty className="tables-empty mx-0 w-100" />
            )}
            <ConfirmDelete
                ref={refConfirm}
                title={t('addons:delete_document')}
                onCallSuccess={_handleDeleteSuccess}
                onCallFail={_handleDeleteFail}
            />
        </>
    );
};

const ItemDocument = ({ id, name, type, original_name = '', onDelete = () => {} }) => {
    const _handleExport = (documentId) => {
        const token = getLocalStorageValue(ACCESS_TOKEN);

        const form = document.createElement('form');
        form.action = URL_PDF_DOCUMENT_TEMPLATE;
        form.method = 'POST';
        form.style.display = 'none';
        form.className = 'export-preview-document-pdf';
        form.innerHTML = `
            <input name="token" value="${token}">
            <input name="id" value="${documentId}">
            <input name="only_user" value="1">
            <input name="gd-branch-id" value="${getBranchId()}">
        `;
        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();

        const elements = document.getElementsByClassName('export-preview-document-pdf');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
    };

    return (
        <div className="rows">
            <div className="rows__icon svg-purple">
                <div onClick={() => _handleExport(id)} className="v2-btn-default --icon-lg --purple">
                    <IconEye />
                </div>
            </div>
            <div className="rows__name">
                <NameTagPdf type={type} name={name} originalName={original_name} />
            </div>
            <div className="rows__action">
                <div className="v2-btn-default --icon-r --delete" onClick={() => onDelete({ id, name })}>
                    Remove
                    <IconClose />
                </div>
            </div>
        </div>
    );
};

export default ListDocument;
