import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_OPPORTUNITY_MANAGE, ADDONS_OPPORTUNITY_PERMISSIONS as routeAddons } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { checkPermission } from 'common/utils/PermissionUtils';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { VOIPAddonsOptionsFooter } from '../../voip/components/VOIPAddonsOptionsFooter';
import { ADDONS_FOOTER_OPTIONS, ADDONS_OPPORTUNITY_PERMISSIONS } from 'app/const/addons';

const OpportunityAddonsOptionsFooter = ({ keyword, items, status, upgrade }) => {
    const { t } = useTranslation('addons');
    const opportunityAddon = useSelector(({ auth }) => auth.user.settings.addons.opportunity);
    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const havePermissionSetting = !!opportunityAddon && checkPermission(permissionsList, ACCESS_SETTINGS_TAB);
    const isDisable = !havePermissionSetting || !status || !!upgrade;

    switch (keyword) {
        case ADDONS_OPPORTUNITY_PERMISSIONS:
            return (
                <VOIPAddonsOptionsFooter
                    keyword={ADDONS_FOOTER_OPTIONS.VOIP_ACTIVE_USER}
                    items={Object.values(items)}
                    isDisableManage={isDisable}
                    pathManage={routeAddons}
                />
            );

        default:
            return (
                <div className="boxs__footer">
                    <Link
                        to={addBranchPath(ADDONS_OPPORTUNITY_MANAGE)}
                        className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                            'is-disable': isDisable
                        })}
                    >
                        {t('manage')}
                        <IconCogWheel />
                    </Link>
                </div>
            );
    }
};

export default OpportunityAddonsOptionsFooter;
