export const TYPE_DOCUMENT = {
    ACTION_OPEN_DOCUMENT_DETAIL: 'ACTION_OPEN_DOCUMENT_DETAIL'
};

export const actionOpenDocumentDetail = (params) => {
    return {
        type: TYPE_DOCUMENT.ACTION_OPEN_DOCUMENT_DETAIL,
        params
    };
};
