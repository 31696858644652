import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const TaskName = forwardRef(({ defaultValue = '' }, ref) => {
    const { t } = useTranslation(['customers']);
    const refTaslName = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return refTaslName.current.value;
        },
        checkVerifyError: _checkVerifyError,
        resetValue: () => {
            refTaslName.current.value = '';
        },
        focus: () => {
            refTaslName.current.focus();
        }
    }));

    function _checkVerifyError() {
        refTaslName.current.classList.add('field-error');
    }

    function _handleChange() {
        refTaslName.current.classList.remove('field-error');
    }

    return (
        <div className="rows">
            <p className="txt">{t('customers:task')}</p>
            <textarea
                ref={refTaslName}
                onChange={_handleChange}
                defaultValue={defaultValue}
                type="text"
                className="field-textarea --no-resize"
                autoFocus
                placeholder={t('customers:what_do_you_need_to_do')}
                spellCheck
            />
        </div>
    );
});

export default TaskName;
