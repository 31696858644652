import React, { useContext } from 'react';
import { InvoiceDetailContext } from '../../contexts/InvoiceDetailContext';
import AbilityPhotos from './images';

export default function InvoiceImages({ parentType }) {
    const { invoiceDetail, onUpdateInvoiceData } = useContext(InvoiceDetailContext);

    function _handleActionsImages(newImages) {
        onUpdateInvoiceData({ invoiceImages: newImages });
    }

    if (invoiceDetail.isLoading) {
        return false;
    }

    return (
        <AbilityPhotos
            parentType={parentType}
            parentId={invoiceDetail.id}
            reload={invoiceDetail.reloadInvoice}
            haveFetchList={!!parseInt(invoiceDetail.has_image)}
            onTriggerUpdate={_handleActionsImages}
            isInsertImage={!!invoiceDetail?.job?.id}
        />
    );
}
