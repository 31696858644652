import React, { useReducer, useRef, useEffect } from 'react';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconLoading from 'assets/icon/IconLoading';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

export default function ListAccount({ accountSelected, account, isFetched, onSelect }) {
    const { t } = useTranslation('common');
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_quickbook_account');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_quickbook_account');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleSelect(e, accountId, isActive) {
        e.stopPropagation();
        if (isActive) {
            return false;
        }
        setState({ isVisible: false });
        onSelect(accountId);
    }

    function _getCurrentAccount() {
        const currentAccount = account.find((item) => item.id === accountSelected) || {};

        return currentAccount.name || 'Please select an account';
    }

    function _renderListAccount() {
        if (!isFetched) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }

        if (isFetched && account.length === 0) {
            return (
                <li className="items pointer-events-none">
                    <div className="txt-ellipsis">{t('no_data_to_display', { title: t('accounts') })}</div>
                </li>
            );
        }

        return account.map((item) => {
            const itemId = item.id;
            const isActive = itemId === accountSelected;

            return (
                <li
                    onClick={(e) => _handleSelect(e, itemId, isActive)}
                    key={itemId}
                    className={`items ${isActive ? 'active' : ''}`}
                >
                    <div className="txt-ellipsis">{item.name}</div>
                </li>
            );
        });
    }

    return (
        <div ref={refDropdown} className={`v2-dropdown ${finalIsVisible ? 'active' : ''}`}>
            <div onClick={(e) => _handleOpen(e)} className="dropbtn items --grey ">
                <div className="txt-ellipsis fw-500">{_getCurrentAccount()}</div>
                <IconDropUpDown />
            </div>
            <div id={'show_list_quickbook_account'} className="v2-dropdown__menu scrolls">
                <ul>{_renderListAccount()}</ul>
            </div>
        </div>
    );
}
