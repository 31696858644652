import { reorder } from 'app/modules/smartview/components/utils';
import { CUSTOM_FIELDS_ACTIONS } from 'common/redux/actions/customFields';

const initialState = { isFirstTime: true, data: [] };
const customFieldsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS_SUCCESS:
            return { ...state, isFirstTime: false, data: action.payload || [] };
        case CUSTOM_FIELDS_ACTIONS.GET_CUSTOM_FIELDS_FAILED:
            return { ...state, isFirstTime: false, data: [] };
        case CUSTOM_FIELDS_ACTIONS.ADD_CUSTOM_FIELD_SUCCESS:
            return { ...state, data: [...state.data, action.payload] };
        case CUSTOM_FIELDS_ACTIONS.EDIT_CUSTOM_FIELD_SUCCESS:
            return {
                ...state,
                isFirstTime: true,
                data: state.data.map((item) => (item.id === action.payload.id ? action.payload : item))
            };
        case CUSTOM_FIELDS_ACTIONS.REORDER_CUSTOM_FIELD:
            return {
                ...state,
                data: reorder([...state.data], action.payload.sourceIndex, action.payload.destinationIndex)
            };
        case CUSTOM_FIELDS_ACTIONS.RESET_CUSTOM_FIELD:
            return initialState;
        default:
            return state;
    }
};
export default customFieldsReducer;
