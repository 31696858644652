export const SET_APP_LOADING = 'SET_APP_LOADING';
export const FETCH_APP_DATA = 'FETCH_APP_DATA';
export const SET_TOGGLED_SIDEBAR = 'SET_TOGGLED_SIDEBAR';

export function setToggledSidebar() {
    return { type: SET_TOGGLED_SIDEBAR };
}

export const setAppLoading = (payload) => {
    return { type: SET_APP_LOADING, payload };
};
