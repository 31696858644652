import classNames from 'classnames';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GdBadge from 'app/components/badge';
import GDModalWarning from 'app/components/modal/ModalWarning';
import { actionAuthMethod, CREATE_AUTH_METHOD, GET_AUTH_PROFILE, setDefaultAuth } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { clientQuery } from 'common/utils/ApiUtils';
import {
    DEFAULT_TIMEOUT,
    TWO_AUTHENTICATION_EDIT_TYPES,
    TWO_AUTHENTICATION_OPTIONS,
    TWO_AUTHENTICATION_TYPES,
    TYPE_VERIFY_MODAL_AUTH
} from '../constants';
import LoadingTwoStepAuthList from './LoadingTwoStepAuthList';
import ModalAuthVerify from './ModalAuthVerify';
import TableTwoAuth from './TableTwoAuth';

const TwoStepAuth = () => {
    const { t } = useTranslation('setting');

    const { DEFAULT, DELETE } = TWO_AUTHENTICATION_EDIT_TYPES;

    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true, activeAddAuthMethod: false });
    const { data: authData, isLoading: finalIsLoading, activeAddAuthMethod: finalActiveAddAuthMethod } = state;
    const listOptions = TWO_AUTHENTICATION_OPTIONS.filter((item) => !authData.some(({ method }) => item.id === method));

    const refModal = useRef(null);
    const refWarning = useRef(null);
    const refTimeoutModal = useRef(null);

    useEffect(() => {
        getAuthProfile(true);

        return () => {
            clearTimeout(refTimeoutModal.current);
        };
    }, []);

    const _handleOpenModal = ({ id, type: method, typeSelect, typeVerify }) => {
        switch (typeSelect) {
            case DEFAULT:
                _handleSetDefault(id);
                return;
            case DELETE:
                _handleConfirmDelete(id);
                return;
        }
        refModal.current?.open({ typeModal: method, typeVerify, id });
    };

    const _handleSelectAuth = (value) => {
        _handleOpenModal({ type: value, typeVerify: TYPE_VERIFY_MODAL_AUTH.ADD });
    };

    const _handleVerify = ({ methodId, value }) => {
        const _handleSuccess = (res) => {
            _handleUpdateData(res.data);
            _handleShowAlertModal(res);
            refModal.current?.setDisableButton();
            refTimeoutModal.current = setTimeout(() => {
                refModal.current?.close();
            }, DEFAULT_TIMEOUT);
        };

        clientQuery(
            CREATE_AUTH_METHOD,
            { method: 'POST', data: { code: value, method_id: methodId || 0 } },
            _handleSuccess,
            _handleShowAlertModal
        );
    };

    const _handleUpdateData = (dataUpdate) => {
        const { id: finalId, method: finalMethod } = dataUpdate;
        dispatchState((prev) => {
            const newData = [...prev.data];
            const index = newData.findIndex(({ id, method }) => id === finalId || method === finalMethod);
            if (index > -1) newData[index] = dataUpdate;
            else newData.push(dataUpdate);

            return { ...prev, data: newData };
        });
    };

    const getAuthProfile = () => {
        const _handleSuccess = ({ data = [], active = false }) => {
            dispatchState({ data, isLoading: false, activeAddAuthMethod: active });
        };
        const _handleFail = () => {
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(GET_AUTH_PROFILE, { method: 'GET', data: {} }, _handleSuccess, _handleFail);
    };

    const _handleSetDefault = (id) => {
        clientQuery(setDefaultAuth(id), { method: 'PUT', data: {} });
        dispatchState((prev) => ({
            ...prev,
            data: prev.data.map((item) => ({ ...item, is_default: id === item.id ? 1 : 0 }))
        }));
    };

    const _handleDeleteMethod = (id) => {
        const _handleSuccess = ({ data = {} }) => {
            const { set_default_id: finalSetDefaultID = 0 } = data;
            dispatchState((prev) => {
                const { data: prevData = [] } = prev;
                const finalData = [];
                prevData.forEach((item) => {
                    const { id: itemId, is_default: itemIsDefault } = item || {};
                    if (itemId !== id) {
                        finalData.push({
                            ...item,
                            is_default: itemId === finalSetDefaultID.toString() ? 1 : itemIsDefault
                        });
                    }
                });
                return { ...prev, data: finalData };
            });
            refWarning.current?._close();
        };

        const _handleFail = ({ message }) => {
            refWarning.current?._setStatusAlert(message);
        };

        clientQuery(actionAuthMethod(id), { method: 'DELETE', data: {} }, _handleSuccess, _handleFail);
    };

    const _handleShowAlertModal = (data) => {
        refModal.current?.alert(data);
    };

    const _handleConfirmDelete = (id) => {
        refWarning.current?._open({ dataConfirm: id, description: t('desc_delete_auth_method') });
    };

    return (
        <Fragment>
            {finalIsLoading ? (
                <LoadingTwoStepAuthList />
            ) : (
                <div className="wrapbox-user__frame px-0 container-print">
                    <div className="row flexcenter">
                        <div className="flexcenter gap-2 flex-1">
                            <h5 className="txt-label fs-14">{t('two_step_authentication')}</h5>
                            {!finalActiveAddAuthMethod ? (
                                <GdBadge className="--disable fw-600" title={t('disable')} />
                            ) : null}
                        </div>
                        <CalendarDropdown
                            buttonClassName="dropbtn v2-btn-default"
                            customDropButton={() => t('add_authentication_method')}
                            wrapperListClass="v2-dropdown__menu content-full scrolls"
                            options={listOptions}
                            wrapperItemClass="items fs-13 fw-500"
                            keyGetValue="id"
                            keyGetKey="id"
                            disable={!finalActiveAddAuthMethod || !listOptions.length}
                            onSelect={_handleSelectAuth}
                        />
                    </div>
                    <p className="row py-2 fs-13">{t('desc_two_step_authentication')}</p>
                    <TableTwoAuth onEdit={_handleOpenModal} data={authData} />
                    <p className="row --desc pt-2 fs-13">
                        {t('if_you_lose_key')}
                        <span
                            className={classNames('purple-default mx-1 cursor-pointer', {
                                'is-disable': !finalActiveAddAuthMethod
                            })}
                            onClick={() => _handleSelectAuth(TWO_AUTHENTICATION_TYPES.BACKUP)}
                        >
                            {t('generate_a_backup_code')}
                        </span>
                        {t('to_sign_in_your_account')}
                    </p>
                    <ModalAuthVerify ref={refModal} onVerify={_handleVerify} onUpdateData={_handleUpdateData} />
                    <GDModalWarning
                        ref={refWarning}
                        title={t('delete')}
                        isLargeContent={false}
                        buttonSave
                        titleButtonConfirm={t('delete')}
                        onConfirm={(_, id) => _handleDeleteMethod(id)}
                    />
                </div>
            )}
        </Fragment>
    );
};

export default TwoStepAuth;
