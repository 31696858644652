import React, { useEffect, useReducer } from 'react';

import { ADDONS_CUSTOM_FIELDS_MANAGE, ADDONS_CUSTOM_FIELDS_PERMISSIONS } from 'app/config/routes';
import { ADDONS_CUSTOM_FIELDS_DETAIL, UPDATE_ADDONS_CUSTOM_FIELDS_OPTIONS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { ADDONS_FOOTER_OPTIONS } from 'app/const/addons';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import AddonBack from '../components/AddonBack';
import { AddonsOptionsItem } from '../components/AddonsOptions';
import CustomFieldsContent from './components/CustomFieldsContent';
import LoadingCustomFields from './components/Loading';

const AddonsCustomFields = () => {
    const [state, dispatchState] = useReducer(reducer, { data: {}, isLoading: true });
    const { isLoading, data } = state;

    useEffect(() => {
        _detailCustomFields();
    }, []);

    const _detailCustomFields = () => {
        const _handleGetSuccess = ({ data }) => dispatchState({ data, isLoading: false });
        clientQuery(ADDONS_CUSTOM_FIELDS_DETAIL, { data: {}, method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper">
                    {isLoading ? (
                        <LoadingCustomFields />
                    ) : (
                        <>
                            <CustomFieldsContent {...data} />
                            <AddonsCustomFieldsOptions data={data.options || []} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const AddonsCustomFieldsOptions = ({ data = [] }) => {
    if (!data || !data.length) return null;
    return data.map((item) => {
        const keyword = item.keyword;
        const pathManage =
            keyword === ADDONS_FOOTER_OPTIONS.CUSTOM_FIELDS
                ? ADDONS_CUSTOM_FIELDS_MANAGE
                : ADDONS_CUSTOM_FIELDS_PERMISSIONS;
        return (
            <AddonsOptionsItem
                key={keyword}
                isPowerUp
                shouldCheckManage
                pathManage={pathManage}
                urlUpdate={UPDATE_ADDONS_CUSTOM_FIELDS_OPTIONS}
                {...item}
            />
        );
    });
};

export default AddonsCustomFields;
