import { TYPE_TAXES } from 'common/redux/actions/taxesAction';

const taxesReducer = (state = {
    list_taxes: [],
    first_time: true
}, action) => {
    switch (action.type) {
        case TYPE_TAXES.ACTION_GET_LIST_TAXES_SUCCESS:
            // eslint-disable-next-line no-undef
            global.fetchedListTax = true;
            return { ...state, list_taxes: action.payload, first_time: false };

        case TYPE_TAXES.ACTION_ADD_LIST_TAXES:
            if (state.first_time) return state;
            return { ...state, list_taxes: [...action.payload, ...state.list_taxes] };

        case TYPE_TAXES.ACTION_UPDATE_TAX_ITEM:
            if (state.first_time) return state;
            const listTaxesAfterUpdate = [...state.list_taxes];
            listTaxesAfterUpdate.every((tax, index) => {
                if (tax.id === action.payload.id) {
                    listTaxesAfterUpdate[index] = { ...action.payload };
                    return false;
                } else return true;
            });
            return { ...state, list_taxes: listTaxesAfterUpdate };

        case TYPE_TAXES.ACTION_REMOVE_LIST_TAXES:
            if (state.first_time) return state;
            const listTaxesAfterRemove = state.list_taxes.filter((tax) => !action.payload.includes(tax.id));
            return { ...state, list_taxes: listTaxesAfterRemove };

        default:
            return state;
    }
};

export default taxesReducer;
