import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ButtonSave from 'app/components/button/ButtonSave';
import GdConfirm from 'app/components/confirm';
import { TEST_SMS_CHATBOT } from 'app/const/api/V2';
import { ENTER_KEY_CODE } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { MODULE_SMS } from 'app/const/Sms';
import { clientQuery } from 'common/utils/ApiUtils';
import { detectKeyPress } from 'common/utils/FunctionUtils';

const FooterConversation = ({ onAddData = () => {} }) => {
    const { t } = useTranslation('addons');

    const { profile = {} } = useSelector(({ auth }) => auth.user);
    const { avatar, first_name, last_name } = profile || {};
    const [state, dispatchState] = useReducer(reducer, { isLoading: false });
    const { isLoading: finalIsLoading } = state;

    const refTextarea = useRef(null);
    const refButtonSave = useRef(null);
    const refConfirm = useRef(null);

    useEffect(() => {
        _setDisableButton(true);
    }, []);

    const _keyPress = (e) => {
        if (detectKeyPress(e, ENTER_KEY_CODE)) {
            _handleSend();
            e.preventDefault();
        }
    };

    const _handleChange = (e) => {
        const value = e.target.value.trim();
        if (!refButtonSave.current?.getStatusBtn() && !!value) return;
        _setDisableButton(!value);
    };

    const _handleSend = () => {
        const message = refTextarea.current?.value?.trim();

        if (message.length > 160) {
            refConfirm.current?.open(null, t('desc_warning_limit_characters'));
            refButtonSave.current?.removeLoading();
            return;
        }
        if (refTextarea.current) refTextarea.current.value = '';
        !finalIsLoading && dispatchState((prev) => ({ ...prev, isLoading: true }));

        _setDisableButton(true);
        refButtonSave.current?.setLoading(true);
        onAddData({
            data: {
                id: Date.now(),
                message,
                sender: { avatar, full_name: `${first_name} ${last_name || ''}`.trim() },
                date: moment().unix(),
                type: MODULE_SMS.TYPE_SMS.GO
            }
        });

        const _success = ({ data = {} }) => {
            onAddData({ data: { ...data, type: MODULE_SMS.TYPE_SMS.TO } });
        };

        const _fail = ({ message }) => {
            refConfirm.current?.open(null, message);
        };

        const _finally = () => {
            dispatchState((prev) => ({ ...prev, isLoading: false }));
            refButtonSave.current?.setLoading(false);
            refTextarea.current?.focus();
        };

        clientQuery(
            TEST_SMS_CHATBOT,
            { data: { message }, method: 'POST', toFormData: false },
            _success,
            _fail,
            _finally
        );
    };

    const _setDisableButton = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    return (
        <div className="message-chat">
            <textarea
                ref={refTextarea}
                name="ai-chatbot"
                className={classNames('field-textarea --no-border --no-resize flex-1', {
                    'is-disable': finalIsLoading
                })}
                placeholder={t('message')}
                autoFocus
                onChange={_handleChange}
                onKeyDown={_keyPress}
            />
            <div className="d-flex justify-end">
                <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main" disabled={finalIsLoading} onSave={_handleSend}>
                    {t('common:send')}
                </ButtonSave>
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('confirm')}
                titleConfirm={t('confirm')}
                listButton={{ confirm: true, cancel: true }}
            />
        </div>
    );
};

export default FooterConversation;
