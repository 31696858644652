import { searchAccountCustomerCard } from 'app/const/api/V2';
import { KEY_CODE_ENTER } from 'app/const/Keyboard';
import { TYPE_SEARCH_ACCOUNT_ID } from 'app/const/Payments';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import ListResultSearch from './ListResultSearch';
import { LoadingFormResult } from './Loading';
import { MERCHANT_ID } from 'app/const/Customers';
import { useTranslation } from 'react-i18next';
import { PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';

export const SearchByID = ({
    isActive = false,
    customer = {},
    stripeId = '',
    type = PAYMENT_MERCHANT_KEYWORD.STRIPE,
    onConnectSuccess = () => {}
}) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, { data: [], isLoading: true });
    const { data, isLoading } = state;
    const customerId = customer.id;

    const refAlert = useRef(null);

    useEffect(() => {
        if (isActive && stripeId && customerId) {
            _handleSearch(stripeId);
        } else {
            dispatchState({ data: [], isLoading: false });
        }
    }, []);

    const _handleSearch = (valueSearch) => {
        dispatchState({ isLoading: true });
        const _handleSearchSuccess = ({ data }) => {
            dispatchState({ data, isLoading: false });
        };

        const _handleSearchFailed = () => {
            dispatchState({ data: [], isLoading: false });
        };

        clientQuery(
            searchAccountCustomerCard(customerId),
            { data: { type: TYPE_SEARCH_ACCOUNT_ID, keyword: valueSearch, merchant: MERCHANT_ID[type] } },
            _handleSearchSuccess,
            _handleSearchFailed
        );
    };

    const _handleRemoveAlert = () => {
        refAlert.current.closeStatusBar();
    };

    const _handleConnectSuccess = ({ data }) => {
        onConnectSuccess(data);
    };

    const _handleConnectFailed = (message) => {
        refAlert.current.showStatusBar({ id: LIST_STATUS.ERROR, message, type: LIST_STATUS.ERROR });
    };

    const _handleKeyPress = (event) => {
        if (event.keyCode === KEY_CODE_ENTER) {
            _handleSearch(event.target.value);
        }
    };

    return (
        <div className={classNames('tab-conts h-100', { 'tab-content-active': isActive })}>
            <div className="form-search has-form px-3 pb-2">
                <p className="txt">{t('customers:search_by_type_id', { type })}</p>
                <input
                    type="text"
                    className={classNames('field-input', { 'is-disable': !!stripeId })}
                    onKeyDown={_handleKeyPress}
                    defaultValue={stripeId || ''}
                    spellCheck
                />
            </div>

            {isLoading ? (
                <LoadingFormResult />
            ) : (
                <div className="form-results">
                    <AlertCustomer ref={refAlert} />
                    <ListResultSearch
                        data={data}
                        customerId={customerId}
                        disableConnect={!!stripeId}
                        type={type}
                        onRemoveAlert={_handleRemoveAlert}
                        onConnectSuccess={_handleConnectSuccess}
                        onConnectFailed={_handleConnectFailed}
                    />
                </div>
            )}
        </div>
    );
};
