const Loading = () => {
    return (
        <div className="wrap-loading wrap-note-template flex-column flex-1 is-show">
            <div className="template-header">
                <div className="loading --animation --line --twothird"></div>
                <div className="template-header__name mt-3">
                    <div className="loading --animation --line --onefifth mb-1"></div>
                    <div className="v2-btn-default loading --grey --full">
                        <div className="loading --animation --line --onefifth"></div>
                    </div>
                </div>
            </div>
            <div className="template-content flex-column flex-1">
                <div className="wrap-content wrapbox-editor">
                    <div className="wrapbox-editor__form">
                        <div className="public-DraftEditor-content">
                            <div className="loading --animation --line --onefifth"></div>
                            <div className="loading --animation --line --fourth mt-3 ml-4"></div>
                            <div className="loading --animation --line --fourth mt-3 ml-4"></div>
                            <div className="loading --animation --line --half mt-3 ml-4"></div>
                        </div>
                    </div>
                    <div className="wrapbox-editor__controls">
                        <div className="editor-controls">
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="break-line"></span>
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="editor-controls__btn loading btn-modal">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="break-line"></span>
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                            <span className="editor-controls__btn loading">
                                <span className="v2-btn-default loading --transparent --icon-sm bg-white-cultured"></span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="template-content__footer shadow-top-brown flex-betweenitems">
                    <div className="loading v2-btn-default --grey btn-x-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="loading v2-btn-default --grey btn-md">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Loading;
