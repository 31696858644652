import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import ItemJobStatus from 'app/components/job/ItemJobStatus';
import IconClose from 'assets/icon/IconClose';
import { getJobStatus, getListJobStatus } from 'common/utils/JobStatusUtils';
import { OPERATOR_TYPES, REFERENCE_TYPES } from '../../constants/types';

const ItemFilteredReference = ({ filter, field, onUpdate = () => {}, onRemove = () => {} }) => {
    const { values } = filter?.value?.set || {};
    const valuesLength = values?.length || 0;
    const isSingleValue = valuesLength === 1;
    const { prefixToken } = OPERATOR_TYPES[filter.operatorId];
    const { keyGetValue = '', options = null, prefixLabel, label, referencedType } = field || {};
    const isStatusField = [REFERENCE_TYPES.STATUS_OPPORTUNITY].includes(referencedType);
    const isCustomerTag = [REFERENCE_TYPES.TAG, REFERENCE_TYPES.ZIP, REFERENCE_TYPES.CITY].includes(referencedType);
    const isScheduleField = referencedType === REFERENCE_TYPES.SCHEDULES;

    const users = useSelector(({ companyUsers }) => companyUsers.users || []);
    const services = useSelector(({ serviceReducer }) => serviceReducer?.list_service || []);
    const pipelines = useSelector(({ pipelines }) => pipelines?.data?.[0]?.pipelines || []);
    const schedulesList = useSelector(({ schedulesList }) => schedulesList.data || []);
    const customerTags = useSelector(({ tags }) => tags.customer.data || []);
    const { data: dataItems } = useSelector(({ smartViews }) => smartViews.items);
    const objectStore = { users, services, pipelines, schedulesList, customerTags, items: dataItems };
    const arrayFilter = field.isJobStatus
        ? getListJobStatus({ isIncludeDeleted: true })
        : options || objectStore?.[keyGetValue] || [];

    let currentData;
    if (values && isSingleValue && arrayFilter) {
        currentData = arrayFilter?.find((item) => {
            const valueCompare = item?.[field?.keySelect]?.toString() || item?.id?.toString();
            return valueCompare === values?.[0]?.toString();
        });
    }

    // In case reference value is deleted, Just show id
    if (!currentData && isSingleValue && (isStatusField || isScheduleField || isCustomerTag)) {
        currentData = { name: values?.[0]?.toString() };
        if (isScheduleField) currentData = { name: 'Schedule Unassigned', isDeleted: true };
        if (isCustomerTag) currentData = { name: values[0] };
    }

    return (
        <>
            <div onClick={onUpdate} className="flex-1 flexcenter gap-5">
                <span className="black-darklight fw-500">
                    {prefixLabel ? `${prefixLabel} ` : null}
                    {label}:
                </span>
                {prefixToken ? <span className="budget --grey fw-600 text-uppercase">{prefixToken}</span> : null}
                {values && valuesLength > 1 ? <span className="budget --black">{valuesLength}</span> : null}
                {isSingleValue ? (
                    <NameReference
                        name={currentData?.name || currentData?.full_name || ''}
                        status={currentData?.status}
                        keyGetValue={keyGetValue}
                        userId={currentData?.user_id || ''}
                        idStatus={currentData?.id || ''}
                        isJobStatus={field.isJobStatus}
                        isScheduleDeleted={currentData?.isDeleted}
                        isScheduleField={isScheduleField}
                        isStatusField={isStatusField}
                        isCustomerTag={isCustomerTag}
                    />
                ) : null}
            </div>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </>
    );
};

const NameReference = ({
    name = '',
    status = '',
    userId = '',
    idStatus = '',
    isJobStatus = false,
    isStatusField = false,
    isScheduleDeleted = false,
    isScheduleField = false,
    isCustomerTag = false
}) => {
    const isFirstTime = useSelector(({ pipelines }) => pipelines?.isFirstTime);
    const isFirstTimeSchedule = useSelector(({ schedulesList }) => schedulesList?.isFirstTime);
    const isLoading = (isFirstTime && isStatusField) || (isFirstTimeSchedule && isScheduleField);

    if (isLoading) {
        return (
            <div className="load-inline wrap-action wrap-loading ml-1">
                <div className="loading --animation field-h16 --light" />
            </div>
        );
    }

    if (isJobStatus) {
        return getJobStatus(idStatus) ? (
            <ItemJobStatus idStatus={idStatus} classTextItem="filter-tag --job-status" excludeWrapper />
        ) : (
            <div>Deleted Job Status</div>
        );
    }
    if (isScheduleField) return <ScheduleFilteredItem isDeleted={isScheduleDeleted} name={name} userId={userId} />;
    return (
        <span
            className={classNames({
                budget: isStatusField,
                [`--${status?.toLowerCase()}`]: isStatusField,
                'filter-tag': isCustomerTag,
                'fw-600 ml-1': !isCustomerTag
            })}
        >
            {name}
        </span>
    );
};

const ScheduleFilteredItem = ({ name, userId = '', isDeleted = false }) => {
    const users = useSelector(({ companyUsers }) => companyUsers.users || []);
    const user = users.find((item) => +item.id === +userId) || {};

    return isDeleted ? (
        <div className="item-checkbox flexcenter">
            <span className="txt-ellipsis ml-1 fw-600">{name}</span>
        </div>
    ) : (
        <div className="item-checkbox flexcenter">
            {user.avatar ? (
                <div className="avt-img" style={{ width: 18, height: 18 }}>
                    <img loading="lazy" src={user?.avatar} width="100%" height="100%" alt="" />
                </div>
            ) : (
                <div className="avt fs-11" style={{ width: 18, height: 18 }}>
                    {name?.substring(0, 2)}
                </div>
            )}
            <span className="txt-ellipsis ml-1 fw-600">{name}</span>
        </div>
    );
};
export default ItemFilteredReference;
