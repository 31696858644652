import ButtonSave from 'app/components/button/ButtonSave';
import { SQUARE_ADDONS_DISCONNECT } from 'app/const/Api';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import ReactModal from 'react-modal';
import { reducer } from 'app/const/Reducer';

const SquareAddonsDisconnect = forwardRef(({ onDisconnect = () => {}, onDisconnectSuccess = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const refButtonSave = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (data) => {
        dispatchState({ isOpen: true, ...data });
    };

    const _close = () => {
        dispatchState({ isOpen: false });
    };

    const _handleDisconnect = () => {
        onDisconnect();
        clientQuery(
            SQUARE_ADDONS_DISCONNECT,
            { data: { value: 0, keyword: 'SQUARE' }, method: 'POST' },
            _handleDisconnectSuccess
        );
    };

    const _handleDisconnectSuccess = () => {
        dispatchState({ isOpen: false });
        onDisconnectSuccess();
    };

    return (
        <ReactModal
            id="confirm_disconnect"
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 999, background: 'transparent' } }}
            className="modal container-modal modal-confirm-disconnect open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <div className="header-modal btn-close">
                    <h3 className="header-modal__label">Disconnect Square</h3>
                    <div className="v2-btn-default --icon-lg --transparent" onClick={_close}>
                        <IconClose />
                    </div>
                </div>
                <div className="body-modal">
                    <div className="text">Are you sure you want to disconnect Square?</div>
                </div>
                <div className="footer-modal footer-hasbtn btn-close">
                    <span className="v2-btn-default --transparent" onClick={_close}>
                        Cancel
                    </span>
                    <ButtonSave
                        ref={refButtonSave}
                        title="Confirm"
                        className="v2-btn-main"
                        onSave={_handleDisconnect}
                    />
                </div>
            </div>
        </ReactModal>
    );
});

export default SquareAddonsDisconnect;
