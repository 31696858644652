export const QUESTLOG_TYPES = {
  GET_QUESTLOGS: "GET_QUESTLOGS"
}

export const getQuestLogs = (onSuccess, onFailed) => {
  return {
    type: QUESTLOG_TYPES.GET_QUESTLOGS,
    onSuccess,
    onFailed,
  };
};
