import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { JOB_EXCLUDE } from 'app/const/Job';
import { getListJobStatus } from 'common/utils/JobStatusUtils';

const useJobStatus = ({ filter = JOB_EXCLUDE.DEFAULT, isInclude = false, isIncludeDeleted = false }) => {
    const jobStatusAddons = useSelector(({ auth }) => auth.user.settings.addons.custom_job_status);
    const jobStatuses = useSelector(({ auth }) => auth.user.settings.job_statuses);

    return useMemo(() => {
        return getListJobStatus({ filter, isInclude, isIncludeDeleted });
    }, [jobStatuses, jobStatusAddons]);
};

export default useJobStatus;
