import React from 'react';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

import { PROFILE_AUTHENTICATION, PROFILE_AUTHENTICATION_ACTIVE_DEVICE } from 'app/config/routes';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { ROUTES_AUTH_PROFILE } from '../constants/routesProfile';

const Sidebar = () => {
    const { pathname } = useLocation();

    const _handleScrollToView = () => {
        document.getElementById('2fa')?.scrollIntoView({ behavior: 'smooth' });
    };
    const defaultClassName = 'items svg-account svg-noeffect';

    return (
        <div className="sidebar-pages sidebar-menu scrolls">
            <ul>
                {ROUTES_AUTH_PROFILE.map(({ path, title, icon }) => {
                    const isAuthProfile = matchPath(pathname, {
                        path: addPrefixPath(PROFILE_AUTHENTICATION),
                        exact: true
                    });
                    if (isAuthProfile && path === PROFILE_AUTHENTICATION_ACTIVE_DEVICE) {
                        return (
                            <div key={path} className={defaultClassName} onClick={_handleScrollToView}>
                                {icon}
                                {title}
                            </div>
                        );
                    }
                    return (
                        <li key={path}>
                            <NavLink to={addBranchPath(path)} exact className={defaultClassName}>
                                {icon}
                                {title}
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Sidebar;
