import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PhoneDropdown from 'app/components/phone/PhoneDropdown';
import { STATUS_ACCOUNT } from 'app/const/Account';
import DocumentDetail from 'app/modules/document';
import EstimateDetail from 'app/modules/estimate';
import InvoiceDetail from 'app/modules/invoice';
import JobDetail from 'app/modules/jobdetail';
import Avatar from 'app/modules/layouts/components/Avatar';
import Help from 'app/modules/layouts/components/Help';
import Inbox from 'app/modules/layouts/components/Inbox';
import NotificationHeader from 'app/modules/layouts/components/NotificationHeader';
import Profile from 'app/modules/layouts/components/Profile';
import QuickAdd from 'app/modules/layouts/components/QuickAdd';
import SearchHeader from 'app/modules/layouts/components/SearchHeader';
import SidebarHeader from 'app/modules/layouts/components/SidebarHeader';
import SmsHeader from 'app/modules/layouts/components/SmsHeader';
import AddonHeader from 'app/modules/layouts/components/addonsHeader';
import LocationDetail from 'app/modules/location';
import NewDocument from 'app/modules/quickadd/document';
import NewEstimate from 'app/modules/quickadd/estimate';
import NewInvoice from 'app/modules/quickadd/invoice';
import AddPayment from 'app/modules/quickadd/payment';
import { createCustomer } from 'common/redux/actions/customer';
import { actionCloseEstimate } from 'common/redux/actions/estimateAction';
import { actionCloseInvoice } from 'common/redux/actions/invoiceAction';
import { actionCreateJob } from 'common/redux/actions/job/create';
import { actionCloseJobDetail } from 'common/redux/actions/job/detail';
import AddJob from '../addjob';
import NewCustomer from '../customer/add';
import AssignLead from '../inbox/components/assignlead';
import ButtonTracking from '../tracker/components/ButtonTracking';
import TrackerAlert from '../tracker/components/TrackerAlert';
import { PhoneSettings } from './GDVoip/settings';
import { KEY_DEFAULT, LIST_COLOR_BRANCH } from 'app/const/Branch';
import LocationDropdown from 'app/components/dropdown/DropdownLocation';

const Header = () => {
    const status = useSelector((state) => state.auth?.user?.profile?.plan?.status);
    const worklogAddon = useSelector(({ auth }) => auth.user.settings?.addons?.work_log);
    const permissionTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const isDisplayTracking = worklogAddon && permissionTracking;
    const branchColor = useSelector((state) => state.auth.user.company.branch.color) || KEY_DEFAULT;

    const isExpired = status === STATUS_ACCOUNT.EXPIRED;
    const refFormNewDocument = useRef(null);
    const refFormNewInvoice = useRef(null);
    const refFormNewCustomer = useRef(null);
    const refFormNewEstimate = useRef(null);
    const refFormAddPayment = useRef(null);
    const refAddonHeader = useRef(null);
    const { pathname, state } = useLocation() || {};
    const dispatch = useDispatch();

    useEffect(() => {
        if (state?.isRedirectSearchBar) return;

        dispatch(actionCloseEstimate());
        dispatch(actionCloseJobDetail());
        dispatch(actionCloseInvoice());
        dispatch(actionCreateJob(null));
        _handleClosenForm();
    }, [pathname]);

    const _handleClosenForm = () => {
        if (!isExpired) {
            refFormNewEstimate.current?._handleCloseForm();
        }
    };

    const _handleOpenForm = (type, params) => {
        switch (type) {
            case 'document':
                refFormNewDocument.current._handleOpenForm();
                break;
            case 'invoice':
                refFormNewInvoice.current._handleOpenForm();
                break;
            case 'customer':
                dispatch(createCustomer({ ...params, isVisibleModal: true }));
                break;
            case 'estimate':
                refFormNewEstimate.current._handleOpenForm();
                break;
            case 'paymnet':
                refFormAddPayment.current.openForm();
                break;
            default:
                break;
        }
    };

    const _handleChangeQuestLog = (value) => {
        refAddonHeader.current?.setActive(value);
    };

    return (
        <Fragment>
            <div
                className="header-v2"
                id="header_v2"
                style={{ '--background': LIST_COLOR_BRANCH[branchColor]?.bgColor }}
            >
                <div className="container flex-betweenitems">
                    <div className="container__header header-v2-left h-100">
                        {!isExpired && <SidebarHeader />}
                        <Avatar />
                        {!isExpired && <AddonHeader ref={refAddonHeader} />}
                    </div>
                    <div className="container__header header-v2-right h-100">
                        {isDisplayTracking ? <ButtonTracking /> : null}
                        <TrackerAlert />
                        <SearchHeader isExpired={isExpired} />
                        <QuickAdd handleOpenForm={_handleOpenForm} isExpired={isExpired} />
                        <div className="is-divider" />
                        <SmsHeader isExpired={isExpired} />
                        <NotificationHeader isExpired={isExpired} />
                        <Inbox isExpired={isExpired} />
                        <div className="is-divider ml-1" />
                        <PhoneSettings />
                        <Help onHandleChangeQuestLog={_handleChangeQuestLog} isExpired={isExpired} />
                        <Profile isExpired={isExpired} />
                    </div>
                </div>
            </div>
            {!isExpired && (
                <Fragment>
                    <NewDocument ref={refFormNewDocument} />
                    <NewInvoice ref={refFormNewInvoice} />
                    <NewCustomer ref={refFormNewCustomer} />
                    <NewEstimate ref={refFormNewEstimate} />
                    <AddPayment ref={refFormAddPayment} />
                    <AddJob />
                    <JobDetail />
                    <InvoiceDetail />
                    <LocationDetail />
                    <EstimateDetail />
                    <DocumentDetail />
                    <AssignLead />
                </Fragment>
            )}
            <PhoneDropdown />
            <LocationDropdown />
        </Fragment>
    );
};

export default Header;
