import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconEdit from 'assets/icon/IconEdit';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
function TaskItemActions({ isDone = false, onEdit = () => {}, onDelete = () => {} }) {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const refDropdown = useRef(null);

    useEffect(() => {
        if (isVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('task-item-actions2');

        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeDropdown();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('task-item-actions2');
        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeDropdown();
        }
    }

    function _closeDropdown() {
        setIsVisible(false);
    }

    function _handleOpen() {
        setIsVisible(!isVisible);
    }

    const _handleEdit = () => {
        onEdit();
        _closeDropdown();
    };

    const _handleDelete = () => {
        const valueConfirm = window.confirm(t('are_you_sure_you_want_to_delete_this_task'));
        if (valueConfirm) onDelete();
    };

    return (
        <div ref={refDropdown} className={classNames('v2-dropdown v2-dropdown--more', { active: isVisible })}>
            <div onClick={_handleOpen} className="dropbtn v2-btn-default --transparent --icon-lg">
                <IconThreeDots />
            </div>
            <div id="task-item-actions2" className="v2-dropdown__menu scrolls">
                <ul>
                    {!isDone && (
                        <li className="items has-icon js-edit-tasks" onClick={_handleEdit}>
                            <IconEdit />
                            <p className="txt-ellipsis">{t('edit_task')}</p>
                        </li>
                    )}
                    <li className="items has-icon red-default" onClick={_handleDelete}>
                        <IconTrash />
                        <p className="txt-ellipsis">{t('delete_task')}</p>
                    </li>
                </ul>
            </div>
        </div>
    );
}

TaskItemActions.propTypes = { onEdit: PropTypes.func, onDelete: PropTypes.func };

export default TaskItemActions;
