import { transformToCurrency } from 'common/utils/NumberUtils';
import { useImperativeHandle, useRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import RepeatValue from './RepeatValue';

const RepeatItem = ({ item = {}, userSelected = null, setDisableButtonSave = () => {} }, ref) => {
    const currency = useSelector(({ auth }) => auth?.user?.settings?.currency);
    const refSalesValue = useRef(null);
    const refProductionValue = useRef(null);
    const { cost: costValue, id } = item;
    useImperativeHandle(ref, () => ({ _getItemValue }));

    const _getItemValue = () => {
        const sale = refSalesValue.current._getValue();
        const product = refProductionValue.current._getValue();
        return {
            id,
            production_value: parseFloat(product.value),
            production_type: product.type,
            production_repeat: product.repeat,
            sale_value: parseFloat(sale.value),
            sale_type: sale.type,
            sale_repeat: sale.repeat
        };
    };

    return (
        <div className="rows" key={id}>
            <div className="col --line-item">
                <p className="col-label">{item.item_name}</p>
            </div>
            <div className="col --cost pr-2">
                <p className="col-label">{transformToCurrency(parseFloat(costValue), currency)}</p>
            </div>
            <RepeatValue
                ref={refProductionValue}
                id={id}
                type={item.production_type}
                repeat={item.production_repeat}
                value={item.production_value}
                cost={costValue}
                isDisable={!userSelected?.production_active}
                setDisableButtonSave={setDisableButtonSave}
            />
            <div className="col-divider"></div>
            <RepeatValue
                ref={refSalesValue}
                id={id}
                type={item.sale_type}
                repeat={item.sale_repeat}
                value={item.sale_value}
                cost={costValue}
                isDisable={!userSelected?.sale_active}
                setDisableButtonSave={setDisableButtonSave}
            />
        </div>
    );
};
export default forwardRef(RepeatItem);
