import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ErrorPage from '../error';
import TrackerBar from './components/TrackerBar';
import TrackerCalendar from './components/TrackerCalendar';
import { TRACKING_EVENTS_DETAIL_PAGE } from './constant';
import { getTitleWeek, isThisWeekOrLastWeek } from './utils';

import './css/clock.scss';

const Tracker = () => {
    const { t } = useTranslation();
    const dateFormat = useSelector(({ auth }) => auth?.user?.company?.date_format);
    const isActivated = useSelector(({ auth }) => !!auth.user.settings?.addons?.work_log);
    const permissionTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const companyTimezone = useSelector(({ auth }) => auth.user.settings.timezone);
    const currentWeek = moment().tz(companyTimezone).startOf('weeks');
    const lastWeek = moment().tz(companyTimezone).subtract(1, 'weeks').startOf('weeks');

    const refLastWeek = useRef(null);
    const refThisWeek = useRef(null);

    const _handleAddLog = (data) => {
        const finalData = data?.detail || data;
        if (!finalData || !finalData?.end) return;
        const newLogWeek = isThisWeekOrLastWeek(finalData?.start, companyTimezone);
        if (newLogWeek === 'this_week') refThisWeek.current?.addWorkLog(finalData);
        if (newLogWeek === 'last_week') refLastWeek.current?.addWorkLog(finalData);
    };

    useEffect(() => {
        const mainPageDiv = document.getElementById('main-page');
        mainPageDiv.classList.add('clock-page', 'container-main');
        addEventListener(TRACKING_EVENTS_DETAIL_PAGE.ADD_NEW_WORK_LOG, _handleAddLog);
        return () => {
            mainPageDiv.classList.remove('clock-page', 'container-main');
            removeEventListener(TRACKING_EVENTS_DETAIL_PAGE.ADD_NEW_WORK_LOG, _handleAddLog);
        };
    }, []);

    if (!isActivated || !permissionTracking) return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    return (
        <div className="container-wrap container-clock">
            <div className="wrapper-clock">
                <TrackerBar onAddWorkLog={_handleAddLog} />
                <div className="wrapper-clock__content scrolls">
                    <TrackerCalendar
                        ref={refThisWeek}
                        prefixTitle={t('this_week')}
                        title={getTitleWeek(currentWeek, dateFormat)}
                        start={currentWeek.clone().startOf('week').format('YYYY-MM-DD')}
                        end={currentWeek.clone().endOf('week').format('YYYY-MM-DD')}
                    />
                    <TrackerCalendar
                        ref={refLastWeek}
                        isHideEdit
                        isShouldCathError={false}
                        title={getTitleWeek(lastWeek, dateFormat, 'last_week')}
                        start={lastWeek.clone().startOf('week').format('YYYY-MM-DD')}
                        end={lastWeek.clone().endOf('week').format('YYYY-MM-DD')}
                    />
                </div>
            </div>
        </div>
    );
};

export default Tracker;
