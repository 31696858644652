import React from 'react';
import IconClose from 'assets/icon/IconClose';

const HeaderModal = ({
    title,
    className = 'wrapper-events__header flexcenter border-bottom-line',
    titleClass = 'flex-1 fw-600 fs-16',
    onClose = () => {}
}) => {
    return (
        <div className={className}>
            <div className={titleClass}>{title}</div>
            <div className="v2-btn-default --transparent --icon-lg" onClick={onClose}>
                <IconClose />
            </div>
        </div>
    );
};

export default HeaderModal;
