import React from 'react';
import { useTranslation } from 'react-i18next';

import SearchOption from 'app/modules/report/filter/SearchOption';
import IconClose from 'assets/icon/IconClose';
import IconFilter from 'assets/icon/IconFilter';
import { useFilters } from '../../context/addFilterContext';

const FilterHeader = ({ onClose = () => {} }) => {
    const { t } = useTranslation('smartView');
    const { handleSearchFilter, isEditing } = useFilters();

    return (
        <div className="header-modal">
            <div className="header-modal__title">
                <IconFilter isHasColor />
                {t('filters')}
            </div>
            <div className="header-modal__close v2-btn-default --icon-sm --transparent" onClick={onClose}>
                <IconClose />
            </div>
            {isEditing ? null : (
                <SearchOption
                    isSearchIcon
                    placeholder={t('search_filter')}
                    parentStyle="header-modal__search search-input"
                    timeDdebound={0}
                    autoFocus
                    onSearch={handleSearchFilter}
                />
            )}
        </div>
    );
};

export default FilterHeader;
