import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const getGridColumns = (columns = [], actionsStatus) => {
    const HEADER_NEW_JOB = {
        customer: {
            title: 'customer',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        account_number: {
            title: 'account_number_#',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_type: {
            title: 'service_type',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phones: {
            title: 'phones',
            style: 'col col-phone',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        email: {
            title: 'email',
            style: 'col col-email',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address: {
            title: 'service_address',
            style: 'col col-address',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_1: {
            title: 'service_address_1',
            style: 'col col-address',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_2: {
            title: 'service_address_2',
            style: 'col col-address',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_city: {
            title: 'service_address_city',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_state: {
            title: 'service_address_state',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_address_zip: {
            title: 'service_address_zip',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        county: { title: 'county', style: 'col', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        billing_address: {
            title: 'billing_address',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        source: { title: 'source', style: 'col', actionType: COMMON.ORDER, currentValue: COMMON.NOT_SET },
        invoice_number: {
            title: 'invoice_#',
            style: 'col',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_value: {
            title: 'invoice_value',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        w_o_tax: {
            title: 'w_o_tax',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        balance: {
            title: 'balance',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        invoice_status: {
            title: 'invoice_status',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        scheduled_date: {
            title: 'scheduled_date',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        job_created_date: {
            title: 'job_created_date',
            style: 'col col-date',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        job_status: {
            title: 'job_status',
            style: 'col col-job-status',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        assigned_to: {
            title: 'assigned_to',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        created_by: {
            title: 'created_by',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        last_service_date: {
            title: 'last_service_date',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        tag: {
            title: 'tag',
            style: 'col --tags',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        service_no: {
            title: 'service_no',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        check_in_time: {
            title: 'check_in_time',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        check_out_time: {
            title: 'check_out_time',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        location_name: {
            title: 'location_name',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_NEW_JOB = [
        { id: 'customer', title: 'customer', status: true },
        { id: 'account_number', title: 'account_number', status: true },
        { id: 'service_type', title: 'service_type', status: true },
        {
            id: 'phones',
            title: 'phones',
            status: true
        },
        { id: 'email', title: 'email', status: true },
        {
            id: 'service_address',
            title: 'service_address',
            status: true
        },
        {
            id: 'service_address_1',
            title: 'service_address_1',
            status: true
        },
        {
            id: 'service_address_2',
            title: 'service_address_2',
            status: true
        },
        {
            id: 'service_address_city',
            title: 'service_address_city',
            status: true
        },
        {
            id: 'service_address_state',
            title: 'service_address_state',
            status: true
        },
        {
            id: 'service_address_zip',
            title: 'service_address_zip',
            status: true
        },
        { id: 'county', title: 'county', status: true },
        {
            id: 'billing_address',
            title: 'billing_address',
            status: true
        },
        { id: 'source', title: 'source', status: true },
        { id: 'invoice_number', title: 'invoice_number', status: true },
        {
            id: 'invoice_value',
            title: 'invoice_value',
            status: true
        },
        { id: 'w_o_tax', title: 'w_o_tax', status: true },
        { id: 'balance', title: 'balance', status: true },
        {
            id: 'invoice_status',
            title: 'invoice_status',
            status: true
        },
        { id: 'scheduled_date', title: 'scheduled_date', status: true },
        { id: 'job_created_date', title: 'job_created_date', status: true },
        {
            id: 'job_status',
            title: 'job_status',
            status: true
        },
        { id: 'assigned_to', title: 'assigned_to', status: true },
        { id: 'created_by', title: 'created_by', status: true },
        { id: 'last_service_date', title: 'last_service_date', status: true },
        {
            id: 'tag',
            title: 'tag',
            status: true
        },
        { id: 'service_no', title: 'service_no', status: true },
        { id: 'check_in_time', title: 'check_in_time', status: true },
        { id: 'check_out_time', title: 'check_out_time', status: true },
        { id: 'location_name', title: 'location_name', status: true }
    ];

    const REPORT_CONTENT_NEW_JOB = {
        customer: {
            id: 'customer',
            style: 'col col-xl',
            type: LIST_TABLE_ITEM_TYPE.CUSTOMER_INFO
        },
        account_number: { id: 'account_number', style: 'col' },
        service_type: {
            id: 'service_type',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_TYPE,
            styleText: 'name'
        },
        phones: {
            id: 'phones',
            style: 'col col-phone',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        email: { id: 'email', style: 'col col-email' },
        service_address: {
            id: 'service_address',
            style: 'col col-address',
            type: LIST_TABLE_ITEM_TYPE.SERVICE_ADDRESS
        },
        service_address_1: {
            id: 'service_address_1',
            style: 'col col-address'
        },
        service_address_2: {
            id: 'service_address_2',
            style: 'col col-address'
        },
        service_address_city: {
            id: 'service_address_city',
            style: 'col col-lg'
        },
        service_address_state: {
            id: 'service_address_state',
            style: 'col col-xl'
        },
        service_address_zip: {
            id: 'service_address_zip',
            style: 'col col-lg'
        },
        county: { id: 'county', style: 'col' },
        billing_address: {
            id: 'billing_address',
            style: 'col col-md txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        source: { id: 'source', style: 'col' },
        invoice_number: {
            id: 'invoice_number',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.LINK,
            styleText: 'name'
        },
        invoice_value: {
            id: 'invoice_value',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL
        },
        w_o_tax: { id: 'w_o_tax', style: 'col col-md', type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL },
        balance: { id: 'balance', style: 'col col-md', type: LIST_TABLE_ITEM_TYPE.CURRENCY_SYMBOL },
        invoice_status: {
            id: 'invoice_status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        scheduled_date: {
            id: 'scheduled_date',
            style: 'col col-date txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        job_created_date: {
            id: 'job_created_date',
            style: 'col col-date txt-ellipsis'
        },
        job_status: {
            id: 'job_status',
            style: 'col col-job-status d-flex gap-4',
            type: LIST_TABLE_ITEM_TYPE.JOBS_STATUS
        },
        assigned_to: { id: 'assigned_to', style: 'col col-sm' },
        created_by: { id: 'created_by', style: 'col col-sm' },
        last_service_date: {
            id: 'last_service_date',
            style: 'col col-xl txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        },
        tag: {
            id: 'tag',
            style: 'col --tags',
            type: LIST_TABLE_ITEM_TYPE.TAGS
        },
        service_no: { id: 'service_no', style: 'col col-sm' },
        check_in_time: { id: 'check_in_time', style: 'col col-md' },
        check_out_time: { id: 'check_out_time', style: 'col col-md' },
        location_name: {
            id: 'location_name',
            style: 'col col-md txt-ellipsis',
            type: LIST_TABLE_ITEM_TYPE.PARSE_HTML
        }
    };

    const newHeader = mapCurrentValueHeader(actionsStatus, { ...HEADER_NEW_JOB });
    const newContent = {};
    const newColumns = [];

    REPORT_COLUMNS_NEW_JOB.forEach((col) => {
        const item = col.id;

        if (columns.includes(item) || item === 'customer') {
            newHeader[item] = HEADER_NEW_JOB[item];
            newContent[item] = REPORT_CONTENT_NEW_JOB[item];
            newColumns.push(col);
        }
    });

    return { header: newHeader, columns: newColumns, contentConfig: newContent };
};
