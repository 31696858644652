import { API_VERSION } from 'app/const/URL';

export const GET_TEMPLATES_NOTE = `${API_VERSION}templates/notes`;

export const ADD_TEMPLATE_NOTE = GET_TEMPLATES_NOTE;

export const updateTemplateNote = (id) => `${API_VERSION}templates/notes/${id}`;

export const getListJobNotes = (id) => `${API_VERSION}jobs/${id}/notes`;

export const getWorkOrderNotes = (id) => `${API_VERSION}jobs/${id}/note`;

export const getInvoiceNotes = (id) => `${API_VERSION}invoices/${id}/note`;
