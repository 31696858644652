import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'app/components/daterange';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { PARAMS_LOCAL_REVIEW } from 'app/const/report/ReportParams';

const ReviewDateRangePicker = ({ activeTab, onSelectDate = () => {} }) => {
    const defaultParams = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', PARAMS_LOCAL_REVIEW[activeTab]));
    const defaultDate = {
        start: defaultParams.start,
        end: defaultParams.end
    };
    const [state, setState] = useState(defaultDate);
    const generateKeyLocalStorage = (tab = '') => KEY_REPORT_LOCAL_STORAGE.concat('_', PARAMS_LOCAL_REVIEW[tab]);

    useEffect(() => {
        setLocalStorage(generateKeyLocalStorage(activeTab), { ...defaultParams });
        setParamsService(defaultDate);
    }, []);

    const setParamsService = (paramsDate) => {
        const params = defaultParams;
        const newParams = {
            ...params,
            ...paramsDate
        };
        setLocalStorage(generateKeyLocalStorage(activeTab), {
            ...newParams
        });
    };

    const handleSelectDate = (value) => {
        const paramsDate = {
            start: value?.start,
            end: value?.end
        };
        let newParamsLocal = getLocalStorage(generateKeyLocalStorage(activeTab));
        setState(paramsDate);
        newParamsLocal = { ...newParamsLocal, ...paramsDate };
        setLocalStorage(generateKeyLocalStorage(activeTab), { ...newParamsLocal });
        onSelectDate();
    };

    const renderDatePicker = () => {
        const date = {
            startDate: defaultDate?.start || state.start,
            endDate: defaultDate?.end || state.end
        };

        return (
            <DateRangePicker
                i18Text="report"
                handleSelect={handleSelectDate}
                selectDefault={date}
                reportType={PARAMS_LOCAL_REVIEW[activeTab]}
                wrapperClassName="header-items has-bg-blue"
            />
        );
    };

    return renderDatePicker();
};

ReviewDateRangePicker.propTypes = {
    activeTab: PropTypes.string,
    reportType: PropTypes.string
};

const compare = (prevProps, nextProps) => {
    return prevProps.reportType === nextProps.reportType && prevProps.activeTab === nextProps.activeTab;
};

export default React.memo(ReviewDateRangePicker, compare);
