import { call, put } from 'redux-saga/effects';
import {fetchWithToken, putWithToken} from 'common/utils/ApiUtils';
import { DRIVE_MATRIX } from 'app/const/Api';
import { TYPE_ROUTE_OPTIMIZER } from 'common/redux/actions/routeOptimizerAction';

export function* getDriveMatrix({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, DRIVE_MATRIX, params);
        if (response && response.success) {
            yield put({
                type: TYPE_ROUTE_OPTIMIZER.GET_DRIVE_MATRIX_SUCCESS,
                payload: response
            });
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateDriveMatrix({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, DRIVE_MATRIX, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
