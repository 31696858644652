export const CURRENCY_VALUE = 'currency';
export const PERCENT_VALUE = '%';
export const OVERRIDE_PERCENT_ID = 1;
export const OVERRIDE_CURRENCY_ID = 2;
export const TYPE_INPUT_OVERRIDE = [
    {
        id: OVERRIDE_PERCENT_ID,
        value: PERCENT_VALUE,
        limitValue: {
            max: 100000,
            min: -100000
        }
    },
    {
        id: OVERRIDE_CURRENCY_ID,
        value: CURRENCY_VALUE,
        limitValue: {
            max: 1000000000,
            min: -1000000000
        }
    }
];

export const OVERRIDE_KEYWORD = {
    TAX: 'tax',
    ITEM: 'line_item'
};

export const OVERRIDE_TYPE = {
    [OVERRIDE_KEYWORD.TAX]: 1,
    [OVERRIDE_KEYWORD.ITEM]: 2
};

export const TITLE_OVERRIDE_RATE = {
    [OVERRIDE_KEYWORD.TAX]: { title: 'tax', change_rate: 'change_rate' },
    [OVERRIDE_KEYWORD.ITEM]: { title: 'line_item', change_rate: 'add_rate' }
};
