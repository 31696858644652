/* eslint-disable no-unsafe-optional-chaining */
import store from 'common/redux/store/configureStore';

export const TYPE_NOTI = {
    REFRESH_NOTIFICATION_LIST: 'REFRESH_NOTIFICATION_LIST',
    GET_COUNT_NOTIFICATION_REQUEST: 'GET_COUNT_NOTIFICATION_REQUEST',

    GET_LIST_NOTIFICATION_REQUEST: 'GET_LIST_NOTIFICATION_REQUEST',

    UPDATE_MARK_READ_AND_UNREAD_REQUEST: 'UPDATE_MARK_READ_AND_UNREAD_REQUEST',
    UPDATE_MARK_READ_AND_UNREAD_SUCCESS: 'UPDATE_MARK_READ_AND_UNREAD_SUCCESS',
    UPDATE_MARK_READ_AND_UNREAD_FAILURE: 'UPDATE_MARK_READ_AND_UNREAD_FAILURE',

    GET_LIST_NOTIFICATIONS_SETTING_REQUEST: 'GET_LIST_NOTIFICATIONS_SETTING_REQUEST',
    UPDATE_NOTIFICATIONS_SETTING_STATUS_REQUEST: 'UPDATE_NOTIFICATIONS_SETTING_STATUS_REQUEST'
};

export const refreshNotificationList = () => {
    return {
        type: TYPE_NOTI.REFRESH_NOTIFICATION_LIST,
        fetching: {},
        contents: { get_list_noti: [], load_more: null },
        error: {}
    };
};

// ------------ START: GET COUNT NOTIFICATION ----------
export const getCountNotificationRequest = (actionSuccess, actionFailed) => {
    return {
        type: TYPE_NOTI.GET_COUNT_NOTIFICATION_REQUEST,
        actionSuccess,
        actionFailed
    };
};

// ------------ START: GET LIST NOTIFICATION ----------

export const getListNotificationRequest = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_NOTI.GET_LIST_NOTIFICATION_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListNotificationSuccess = (params, payload) => {
    const { notification } = store.getState();
    return {
        type: TYPE_NOTI.GET_LIST_NOTIFICATION_SUCCESS,
        fetching: { get_list_noti: false },
        contents: {
            get_list_noti: [...notification.contents?.['get_list_noti'], ...payload?.data],
            load_more: { status: payload?.show_more, offset: params.offset + 20 },
            count_unread: payload?.total
        },
        error: null
    };
};

export const getListNotificationFailure = (error) => {
    return {
        type: TYPE_NOTI.GET_LIST_NOTIFICATION_FAILURE,
        fetching: { get_list_noti: false },
        contents: null,
        error: { get_list_noti: error }
    };
};

// ------------ END: GET LIST NOTIFICATION ----------

// ------------ START: MARK READ AND UNREAD ----------

export const updateMarkReadAndUnReadRequest = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_NOTI.UPDATE_MARK_READ_AND_UNREAD_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateMarkReadAndUnReadSuccess = (params, payload) => {
    const { ids } = params;
    const { notification } = store.getState();
    let contents = notification.contents?.['get_list_noti'];
    if (contents) {
        ids.forEach((id) => {
            contents = contents.filter((i) => i.id !== id);
        });
    }
    return {
        type: TYPE_NOTI.UPDATE_MARK_READ_AND_UNREAD_SUCCESS,
        fetching: { update_mark_note: false },
        contents: { get_list_noti: contents, count_unread: payload?.total },
        error: null
    };
};

export const updateMarkReadAndUnReadFailure = (error) => {
    return {
        type: TYPE_NOTI.UPDATE_MARK_READ_AND_UNREAD_FAILURE,
        fetching: { update_mark_note: false },
        contents: null,
        error: { update_mark_note: error }
    };
};

// ------------ END: MARK READ AND UNREAD ----------

export const getListNotificationsSetting = (actionSuccess, actionFailed) => {
    return {
        type: TYPE_NOTI.GET_LIST_NOTIFICATIONS_SETTING_REQUEST,
        actionSuccess,
        actionFailed
    };
};

export const updateNotificationsSettingStatus = (params, actionSuccess, actionFailed) => {
    return {
        type: TYPE_NOTI.UPDATE_NOTIFICATIONS_SETTING_STATUS_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
