import { DELAY_TIME } from 'app/const/App';
import IconCircleClose from 'assets/icon/IconCircleClose';
import React, { useEffect, useRef, useState } from 'react';
let timer;

const SearchInput = ({ placeholder, onSearch, className = '' }) => {
    const searchInputRef = useRef(null);

    const [state, setState] = useState({
        term: '',
        typing: false
    });

    useEffect(() => {
        timer && clearTimeout(timer);
        timer = setTimeout(() => {
            if (state.typing) {
                onSearch(state.term);
            }
        }, DELAY_TIME);
        return () => clearTimeout(timer);
    }, [state.term]);

    const _handleSearchTerm = (e) => {
        setState({ term: e.target.value, typing: true });
    };

    const _handleRemoveInput = async (e) => {
        e && e.stopPropagation();
        setState({ term: '', typing: true });
    };

    return (
        <>
            <input
                ref={searchInputRef}
                type="text"
                value={state.term}
                placeholder={placeholder}
                className={className}
                onChange={_handleSearchTerm}
            />
            {state.term && (
                <div className="close-tags" onClick={_handleRemoveInput}>
                    <IconCircleClose />
                </div>
            )}
        </>
    );
};

export default SearchInput;
