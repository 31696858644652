import { CALENDAR_MODES } from 'app/modules/calendar/const';
import { HeaderCustom } from 'app/components/daterange/components/HeaderCustom';
import JumpHeader from 'app/modules/calendar/header/components/date/Jump';
import IconSingleStep from 'assets/icon/IconSingleStep';
import { updateSelectDate } from 'common/redux/actions/calendar';
import { convertISOTodate, convertTimeToISO } from 'common/utils/DateUtils';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import Today from '../Today';
import { useTranslation } from 'react-i18next';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';

const HeaderDate = ({ onPrev = () => {}, onNext = () => {}, onGoToDate = () => {}, onToday = () => {} }) => {
    const { t } = useTranslation(['calendar']);
    const [isVisibleModal, setIsvisibleModal] = useState(false);
    const { date: dateStore, view: finalCalendarView } = useSelector((state) => state.calendar);
    const { start, end } = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer);
    const dispatch = useDispatch();

    const finaleDate = useMemo(() => convertISOTodate(moment(dateStore).utc()), [dateStore]);
    const finaleStart = useMemo(() => convertISOTodate(moment(start).utc()), [start]);
    const finaleEnd = useMemo(() => convertISOTodate(moment(end).utc().subtract(1, 'd')), [end]);

    function _openDatePicker() {
        setIsvisibleModal(true);
    }

    function _closeDatePicker() {
        setIsvisibleModal(false);
    }

    function _actionChangePrevOrNext(type) {
        if (type === 'prev') {
            onPrev();
        } else {
            onNext();
        }
    }

    function _onChangeDate(date) {
        const newDate = moment(date).utc().unix();
        const viewsDisabled = [
            CALENDAR_MODES.AGENDA_WEEK,
            CALENDAR_MODES.AGENDA_2_WEEK,
            CALENDAR_MODES.DAY_GRID_MONTH,
            CALENDAR_MODES.AGENDA_3_DAY,
            CALENDAR_MODES.AGENDA_4_DAY
        ];

        if (
            moment(convertTimeToISO(moment(date)))
                .utc()
                .format('MM-DD-YYYY') === moment(dateStore).utc().format('MM-DD-YYYY')
        ) {
            return false;
        }

        if (
            viewsDisabled.includes(finalCalendarView) &&
            moment(start).utc().unix() <= newDate &&
            newDate <= moment(end).utc().subtract(1, 'd').unix()
        ) {
            // case select date in range
        } else {
            dispatch(updateSelectDate({ date: convertTimeToISO(moment(date)) }));
        }
    }

    function _selectJump(date) {
        dispatch(updateSelectDate({ date: date }));
        onGoToDate(date);
    }

    const _renderModalDatePicker = () => {
        if (!isVisibleModal) {
            return false;
        }
        return (
            <div className={'modal container-modal wrap-datepicker w open'}>
                <div className="modal__overlay bg-transparent" onClick={_closeDatePicker} />
                <div className="modal__container">
                    <DatePicker
                        inline
                        dateFormat="MM-DD-YYYY"
                        renderCustomHeader={HeaderCustom}
                        startDate={finaleStart}
                        endDate={finaleEnd}
                        selected={finaleDate}
                        onSelect={(date) => _onChangeDate(date)}
                        monthsShown={2}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="action">
                <TooltipPopper
                    onClick={() => _actionChangePrevOrNext('prev')}
                    className={`action__items btn-action tooltip`}
                    tooltipText={t('calendar:jump_back')}
                >
                    <IconSingleStep isPrev />
                </TooltipPopper>
                <JumpHeader type="prev" currentDate={dateStore} onSelect={_selectJump} />
                <Today onToday={onToday} />
                <span id="title-calendar-time" onClick={_openDatePicker} className="action__items time" />
                <JumpHeader currentDate={dateStore} onSelect={_selectJump} type="next" />
                <TooltipPopper
                    onClick={() => _actionChangePrevOrNext('next')}
                    className={`action__items btn-action tooltip`}
                    tooltipText={t('calendar:jump_next')}
                >
                    <IconSingleStep />
                </TooltipPopper>
            </div>

            {_renderModalDatePicker()}
        </>
    );
};

export default HeaderDate;
