export const MANAGE_BRANCH = 'manage_branch';
export const KEYWORD_MULTIPLE_BRANCH = 'MULTIPLE_BRANCH';
export const ACTIVE = 'Active';
export const ARCHIVE = 'Archive';
export const DELETE = 'Delete';
export const EMAIL = 'email';

export const FIELD_INPUT_COMPANY = {
    YOUR_BRANCH_NAME: 'name',
    COMPANY_NAME: 'company_name',
    COMPANY_EMAIL: 'email',
    COMPANY_PHONE: 'phone'
};

export const FIELD_COPY_DATA = {
    SERVICE_TEMPLATES: 'service',
    LINE_ITEMS: 'item',
    TAXES: 'tax',
    PAYMENT_METHODS: 'method',
    SOURCES: 'source',
    PAPERWORK: 'paperwork',
    TILES: 'tile'
};

export const STATUS_MULTI_BRANCH = {
    EDIT: 0,
    ACTIVE: 1,
    ARCHIVE: 2,
    DELETE: -1
};
