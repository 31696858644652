import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFilters } from '../../context/addFilterContext';
import Subcategories from './Subcategories';

const FilterSearchContent = () => {
    const { t } = useTranslation('smartView');
    const { listOfFilters } = useFilters();
    if (!listOfFilters.length)
        return (
            <div className="filter-result scrolls">
                <div className="rows border-none cursor-default">
                    <p className="my-1 py-1">{t('no_filter_matches')}</p>
                </div>
            </div>
        );
    return listOfFilters.map((category) => (
        <>
            <div className="filter-result__title">
                <span className="txt-ellipsis">{category.title}</span>
            </div>
            <Subcategories key={category.id} subcategories={category.subcategories} />
        </>
    ));
};

export default FilterSearchContent;
