import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { createPopper } from '@popperjs/core';

const CalendarTooltip = (props, ref) => {
    const refButtonElement = useRef(null);
    const refTooltipElement = useRef(null);
    const refCurrentShow = useRef(false);
    const refInstance = useRef(null);

    useImperativeHandle(ref, () => ({ show, hide }));

    const show = (dataShow) => {
        const { element: elementButton, content } = dataShow;
        refButtonElement.current = elementButton;
        if (refCurrentShow.current) return;
        refCurrentShow.current = true;

        const popperInstance = createPopper(elementButton, refTooltipElement.current, {
            placement: 'top',
            modifiers: [
                {
                    name: 'preventOverflow',
                    options: { boundary: refButtonElement.current }
                }
            ]
        });

        refInstance.current = popperInstance;
        refTooltipElement.current.textContent = content;

        // Make the tooltip visible
        refTooltipElement.current.style.visibility = 'visible';
        refTooltipElement.current.style.display = 'block';
        // Enable the event listeners
        popperInstance.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: 'eventListeners', enabled: true }]
        }));
        // Update its position
        popperInstance.update();
    };

    const hide = () => {
        if (!refInstance.current) return;
        refCurrentShow.current = false;
        // Hide the tooltip
        refTooltipElement.current.style.visibility = 'hidden';
        refTooltipElement.current.style.display = 'none';

        // Disable the event listeners
        refInstance.current.setOptions((options) => ({
            ...options,
            modifiers: [...options.modifiers, { name: 'eventListeners', enabled: false }]
        }));
    };

    return (
        <p className="tooltip">
            <span
                ref={refTooltipElement}
                id="calendar_tooltip"
                className="tooltiptext"
                style={{ display: 'none', visibility: 'hidden', zIndex: 99999, maxWidth: '280px' }}
            />
        </p>
    );
};

export default forwardRef(CalendarTooltip);
