/* eslint-disable no-undef */
export const APP_URL = process.env.REACT_APP_APP_URL;
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const URL_EXPORT_DATA = process.env.REACT_APP_URL_EXPORT_DATA;
export const CDN_ENDPOINT = process.env.REACT_APP_CDN_URL;
export const ENDPOINT_AVATAR = CDN_ENDPOINT?.concat('/avatars');
export const API_VERSION = '/api/';
export const WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT;
export const LINK_CDN_IMG = 'https://cdn.gorilladesk.com/assets/images/';
export const LINK_CDN_IMG_BROKEN = `${LINK_CDN_IMG}image-broken.png`;
export const LINK_SQUARE_DASHBOARD = process.env.REACT_APP_LINK_SQUARE_DASHBOARD;
export const LINK_STRIPE_DASHBOARD = process.env.REACT_APP_LINK_STRIPE_DASHBOARD;
export const LINK_OPEN_API_DOC = process.env.REACT_APP_LINK_OPEN_API_DOC;
