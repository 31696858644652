import React, { useRef } from 'react';

import Chart from 'app/components/chart';
import { configSalesFunnel } from './chart/config';

const SalesFunnelChart = ({
    data = [],
    selectedTab = '',
    style = {},
    convertValueToCurrency = () => {},
    toggleTooltip = () => {}
}) => {
    const refChart = useRef(null);
    const { width: widthChartContainer } = style;

    const configData = configSalesFunnel({ data, selectedTab, style, convertValueToCurrency, toggleTooltip });

    return (
        <Chart
            id="sales_funnel_chart"
            ref={refChart}
            chartConfig={{
                type: 'bar',
                ...configData
            }}
            style={{
                width: widthChartContainer
            }}
            plugins={[]}
        />
    );
};

export default SalesFunnelChart;
