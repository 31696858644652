import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ADVANCE_ROUTING } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import BoxDefaultSetup from '../components/BoxDefaultSetup';
import ManageUsersTable from '../components/ManageUsersTable';
import { useSelector } from 'react-redux';
import { TYPE_ADVANCE_ROUTING } from '../constants';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
const AddonsBestAvailableTime = () => {
    const { t } = useTranslation('addons');
    const availableSetup = useSelector(
        ({ auth }) => auth.user.settings.addons.advance_routing?.best_available_time || {}
    );
    const typeOption = TYPE_ADVANCE_ROUTING.AVAILABLE;
    return (
        <div className="addons-wrap__container">
            <div className="header">
                <Link to={addBranchPath(ADVANCE_ROUTING)} className="v2-btn-default has-icon --grey">
                    <IconArrow isPrev />
                    {t('advance_routing')}
                </Link>
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --pipeline --custom-field container-print">
                    <BoxDefaultSetup
                        title={t('title_best_available_time')}
                        description={t('desc_best_available_time')}
                        defaultData={availableSetup || {}}
                        type={typeOption}
                    />
                    <ManageUsersTable
                        title={t('manage_users')}
                        description={t('desc_manage_users_best_available_time')}
                        type={typeOption}
                        reportType={REPORT_TYPE.MANAGE_BEST_AVAILABLE_TIME}
                    />
                </div>
            </div>
        </div>
    );
};
export default AddonsBestAvailableTime;
