import { SETTINGS_LINE_ITEMS, SETTINGS_UPDATE_STATUS_LINE_ITEMS, getUrlSettingUpdateLineItem } from 'app/const/Api';
import { fetchWithToken, postWithToken, putWithToken } from 'common/utils/ApiUtils';
import { call } from 'redux-saga/effects';

export function* getListLineItems({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, SETTINGS_LINE_ITEMS, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* createLineItem({ params, actionSuccess, actionFailed }) {
    try {
        const newParam = {
            name: params.name,
            description: params.description,
            tax1_id: params.tax1.value,
            tax2_id: params.tax2.value,
            cost: params.cost,
            attachments: params.attachments
        };
        const response = yield call(postWithToken, SETTINGS_LINE_ITEMS, newParam);
        if (response && response.success) {
            response.data.name = params.name;
            response.data.description = params.description;
            response.data.tax1 = params.tax1;
            response.data.tax2 = params.tax2;
            response.data.cost = params.cost;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateLineItem({ params, actionSuccess, actionFailed }) {
    try {
        const newParam = {
            name: params.name,
            description: params.description,
            tax1_id: params.tax1.value,
            tax2_id: params.tax2.value,
            cost: params.cost,
            attachments: params.attachments
        };
        const URL = getUrlSettingUpdateLineItem(params.id);
        delete params.id;
        const response = yield call(putWithToken, URL, newParam);
        if (response && response.success) {
            response.data = { ...response.data, ...params };
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* updateStatusLineItems({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(putWithToken, SETTINGS_UPDATE_STATUS_LINE_ITEMS, params);
        if (response && response.success) {
            response.listIdChecked = params.ids;
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
