import React, { Fragment, useRef } from 'react';
import GridLoading from 'app/components/grid/components/GridLoading';
import JobDetail from 'app/modules/calendar/components/sidebar/components/workpool/Job';
import { ACTIONS_JOB } from 'app/modules/calendar/const';
import JobMove from 'app/modules/calendar/components/sidebar/components/workpool/JobMove';
import { updateGetJobActive } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import ModalRecurringJob from 'app/modules/calendar/components/modal/RecurringJob';
import { useDispatch } from 'react-redux';
import { actionOpenJobDetail } from 'common/redux/actions/job/detail';
import { useTranslation } from 'react-i18next';
import IconArrow from 'assets/icon/IconArrow';

const WorkPoolJobList = ({ activeTab, isLoading, jobList }) => {
    const { t } = useTranslation('calendar');
    const dispatch = useDispatch();
    const refModalRecurringJob = useRef(null);

    function _renderJobList() {
        if (!jobList.length && !isLoading) {
            return (
                <div id={'show_ui_list_wookpool_empty'} className="box-drop-file">
                    <p className="description mb-2">{t('calendar:drag_a_job_to_the_workpool')}</p>
                    <IconArrow isBelowBlue />
                </div>
            );
        }

        return jobList.map((item) => {
            return (
                <JobDetail
                    key={item.id}
                    dataJob={{ ...item, typeMove: ACTIONS_JOB.MOVE_WP }}
                    onDBClick={_handleDBClick}
                />
            );
        });
    }

    function _handleDBClick(jobData) {
        const jobId = jobData.jobId || jobData.id;
        if (jobData.recurrence.length !== 0) {
            clientQuery(updateGetJobActive(jobId), { method: 'GET' }, _openModalRecurringJob);
            return false;
        }

        _openJobDetail(jobId);
    }

    function _openModalRecurringJob(finalJobData) {
        refModalRecurringJob.current &&
            refModalRecurringJob.current._showOptions(finalJobData.data, () => _openJobDetail(finalJobData.data.id));
    }

    function _openJobDetail(jobId) {
        dispatch(actionOpenJobDetail({ id: jobId }));
    }

    function _renderLoadingList() {
        if (isLoading) {
            return (
                <div>
                    <GridLoading />
                </div>
            );
        }

        return null;
    }

    return (
        <div id={'external-events-work-pool'} className="external-events-list-job list-workpool details-jobslist">
            {activeTab && (
                <Fragment>
                    <JobMove />
                    {_renderJobList()}
                    {_renderLoadingList()}
                    <ModalRecurringJob ref={refModalRecurringJob} />
                </Fragment>
            )}
        </div>
    );
};

export default WorkPoolJobList;
