import { reducer } from 'app/const/Reducer';
import React, { createContext, useReducer } from 'react';
import { ADD_CUSTOMER_CONTEXT_TYPES } from './types';

export const AddCustomerContext = createContext();

const AddCustomerProvider = ({ accountNumber, children, defaultPhones = [] }) => {
    const [state, dispatchState] = useReducer(reducer, {
        profile: {
            account_no: accountNumber,
            first_name: '',
            last_name: '',
            email: '',
            company: '',
            phones: defaultPhones
        },
        additional_contacts: [],
        service_location: {
            location_name: '',
            service_address: {},
            billing_address: {},
            same_billing_location: true
        },
        address_to: '',
        billing_to: ''
    });

    const _handleUpdateContext = (data, type) => {
        const newState = { ...state };
        const serviceLocation = { ...newState['service_location'] };

        switch (type) {
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_PROFILE:
                dispatchState({ ...newState, profile: { ...state.profile, ...data } });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_PROFILE_PHONES:
                dispatchState({ ...newState, profile: { ...state.profile, phones: data } });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.ADD_ADDITIONAL_CONTACT:
                dispatchState({
                    ...newState,
                    additional_contacts: [...state.additional_contacts, data]
                });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.DELETE_ADDITIONAL_CONTACT:
                dispatchState({
                    ...newState,
                    additional_contacts: state.additional_contacts.filter((item) => item.id !== data)
                });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_ADDITIONAL_CONTACT:
                dispatchState({ ...newState, additional_contacts: data });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.SELECT_ADDRESS_SERVICE:
                dispatchState({ ...newState, address_to: data });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.SELECT_BILLING_SERVICE:
                dispatchState({ ...newState, billing_to: data });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.SELECT_BILLING_TO:
                dispatchState({ ...newState, billing_to: data });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_LOCATION:
                const newServiceLocation = { ...newState, service_location: { ...serviceLocation, ...data } };
                if (data.billing_to) {
                    newServiceLocation['billing_to'] = data.billing_to;
                    delete newServiceLocation['service_location']['billing_to'];
                }
                dispatchState(newServiceLocation);
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_SERVICE_ADDRESS:
                dispatchState({
                    ...newState,
                    service_location: {
                        ...serviceLocation,
                        service_address: { ...serviceLocation.service_address, ...data }
                    }
                });
                break;
            case ADD_CUSTOMER_CONTEXT_TYPES.UPDATE_BILLING_ADDRESS:
                dispatchState({
                    ...newState,
                    service_location: {
                        ...serviceLocation,
                        billing_address: { ...serviceLocation.billing_address, ...data }
                    }
                });
                break;
            default:
                break;
        }
    };

    const AddCustomerContextData = {
        ...state,
        updateContext: _handleUpdateContext,
        getDataSubmit: () => state
    };

    return <AddCustomerContext.Provider value={AddCustomerContextData}>{children}</AddCustomerContext.Provider>;
};

export default AddCustomerProvider;
