import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { convertTaskEvent } from './ultil/Calendar';

const ServicesTask = ({
    events = [],
    schedules = [],
    onReloadEvents = () => {},
    onCloseTaskModal = () => {},
    onGetCurrentRange = () => {}
}) => {
    const taskUpdate = useSelector(({ listTaskReducer }) => listTaskReducer.taskUpdateStatus);

    // Delete or Update task from events list when update status of task to done
    useEffect(() => {
        if (taskUpdate) {
            let result = [...events];
            let isRemoved = false;
            const idTaskUpdate = taskUpdate.id || '';

            switch (taskUpdate.typeAction) {
                case 'status':
                case 'update':
                    const { activeStart, activeEnd } = onGetCurrentRange();
                    const newDate = moment(taskUpdate.dateData).utc().unix();
                    const isUpdate = schedules.some((schedule) => schedule.user_id === taskUpdate.assignee.id);
                    const isUpdateStatus = taskUpdate.typeAction === 'status';
                    const inRangeDate =
                        moment.utc(activeStart).startOf('day').unix() <= newDate &&
                        newDate <= moment.utc(activeEnd).subtract(1, 'd').endOf('day').unix();

                    if ((isUpdate && inRangeDate) || isUpdateStatus) {
                        const index = result.findIndex((item) => item.id === idTaskUpdate);
                        const newTask = { ...taskUpdate };

                        if (index >= 0) {
                            if (newTask['typeAction'] === 'status') {
                                delete newTask['typeAction'];
                                result[index] = { ...result[index], ...newTask, active: newTask.active };
                            } else {
                                delete newTask['typeAction'];
                                result[index] = newTask;
                            }
                        } else {
                            !isUpdateStatus && result.push(newTask);
                        }
                    } else {
                        !isUpdateStatus && _removeEvent(idTaskUpdate);
                        isRemoved = true;
                    }
                    break;
                case 'delete':
                    onCloseTaskModal();
                    _removeEvent(idTaskUpdate);
                    isRemoved = true;

                    break;
                case 'create':
                    onCloseTaskModal();
                    result.push(convertTaskEvent(taskUpdate));
                    break;
                default:
                    if (Array.isArray(taskUpdate)) {
                        result = result.filter((item) => !taskUpdate.some((el) => el.id === item.id));
                    } else {
                        _removeEvent(idTaskUpdate);
                        isRemoved = true;
                    }
                    break;
            }

            !isRemoved && onReloadEvents(result);
        }
    }, [taskUpdate]);

    /**
     * Remove event from events list.
     * @param {string} idEvent - ID of event
     * @return {void}
     */
    const _removeEvent = (idEvent) => {
        const result = [...events].filter((item) => item.id.toString() !== idEvent.toString());
        onReloadEvents(result);
    };

    return null;
};

export default ServicesTask;
