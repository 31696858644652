import { UPDATE_ADDONS_EMAIL_ACCOUNT } from 'app/const/Api';
import { DISCOVERY_DOCS_GOOGLE_EMAIL, SCOPES_GOOGLE_EMAIL } from 'app/const/Keys';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AddonsAlert from 'app/modules/addons/components/AddonsAlert';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import queryStrings from 'query-string';
import React, {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useReducer,
    useRef
} from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SetupEmailContext } from '../../context/SetupEmailContext';
import FooterSetupEmail from './Footer';
import Header from './Header';
import StepChooseSetup from './StepChooseSetup';
import StepConfirmEmail from './StepConfirmEmail';
import StepSelectCustomer from './StepSelectCustomer';
import StepSetupSMTP from './StepSetupSMTP';

const SetupEmailAccount = ({ onAddAccountFail = () => {}, onAddAccountSuccess = () => {} }, ref) => {
    const history = useHistory();
    const { search } = useLocation();
    const gapi = window.gapi;

    const googleData = useSelector(({ auth }) => auth.user.settings.google);
    const [state, dispatchState] = useReducer(reducer, { isOpen: false });
    const { step, setup, setState, restoreState } = useContext(SetupEmailContext);
    const { isOpen } = state;

    const refSelectCustomer = useRef(null);
    const refSetupSMTP = useRef(null);
    const refFooter = useRef(null);
    const refAlert = useRef(null);

    useImperativeHandle(ref, () => ({ _open, _close }));

    useLayoutEffect(() => {
        const { code, error_description } = queryStrings.parse(search);
        const setup = getLocalStorage('setUpMail');

        if (error_description) {
            _handleAddAccountFail(error_description);
            return;
        }

        if (code && setup) {
            if (!setup) return;
            const { email, customer } = setup;
            clientQuery(
                UPDATE_ADDONS_EMAIL_ACCOUNT,
                {
                    data: { email, name: `${customer.full_name}`, code, account_type: 'microsoft' },
                    toFormData: false,
                    method: 'POST'
                },
                _handleUpdateAddonsSuccess,
                _handleUpdateAddonsFail,
                _handleUpdateAddonsFinally
            );
        }
    }, [location]);

    const _handleUpdateAddonsFail = ({ message }) => {
        refAlert.current && refAlert.current.showStatusBar({ id: 'call_failed', message, type: LIST_STATUS.ERROR });
        _handleAddAccountFail(message);
    };

    const _handleAddAccountFail = (message) => {
        history.replace();
        removeLocalStorage('setUpMail');
        onAddAccountFail(message);
    };

    const _handleUpdateAddonsFinally = () => {
        history.replace();
        removeLocalStorage('setUpMail');
    };

    useEffect(() => {
        if (gapi) gapi.load('client:auth', () => {});
    }, []);

    const _open = () => {
        dispatchState({ isOpen: true });
    };

    const _close = () => dispatchState({ isOpen: false });

    const _handleSaveSetup = (dataSubmit) => {
        const data = {
            name: dataSubmit.customer.full_name,
            email: dataSubmit.email,
            smtp: dataSubmit.smtp,
            imap: dataSubmit.imap,
            send: 1,
            sync: dataSubmit.isAutomatically ? 1 : 0
        };
        const _handleSuccess = ({ data: dataResponse, message }) => {
            restoreState();
            onAddAccountSuccess(
                {
                    ...data,
                    smtp: { ...data.smtp, ...(dataResponse?.smtp || {}), username: data.smtp.user, status: 1 },
                    imap: { ...data.imap, ...(dataResponse?.imap || {}), username: data.imap.user, status: 1 },
                    is_google: !!!Object.keys(dataSubmit.smtp).length,
                    type: dataResponse.type
                },
                message
            );
        };
        const _handleFailed = ({ message }) =>
            refAlert.current.showStatusBar({ id: 'call_failed', message, type: LIST_STATUS.ERROR });
        const _handleFinally = () => refFooter.current.removeLoading();

        clientQuery(
            UPDATE_ADDONS_EMAIL_ACCOUNT,
            { data, toFormData: false, method: 'PUT' },
            _handleSuccess,
            _handleFailed,
            _handleFinally
        );
    };

    const _handleSaveSetupWithGG = () => {
        if (!gapi.auth) return;

        const _handleAuthSuccess = (data) => {
            if (data.error) return;

            const { email, customer } = setup;
            const _handleFailed = ({ message }) => {
                refAlert.current.showStatusBar({ id: 'email_error', message, type: LIST_STATUS.ERROR });
            };

            const dataQuery = { email, name: `${customer.full_name}`, code: data.code, account_type: 'google' };
            clientQuery(
                UPDATE_ADDONS_EMAIL_ACCOUNT,
                { data: dataQuery, method: 'POST' },
                _handleUpdateAddonsSuccess,
                _handleFailed
            );
        };

        const dataAuthorize = {
            client_id: googleData.client_id,
            discoveryDocs: DISCOVERY_DOCS_GOOGLE_EMAIL.split(','),
            scope: SCOPES_GOOGLE_EMAIL,
            immediate: false,
            cookie_policy: 'single_host_origin',
            access_type: 'offline',
            approval_prompt: 'force',
            response_type: 'code'
        };
        gapi.auth.authorize(dataAuthorize, _handleAuthSuccess);
    };

    const _handleSaveSetupWithMS = () => {
        setLocalStorage('setUpMail', setup);
    };

    const _handleUpdateAddonsSuccess = ({ data, message }) => {
        restoreState();
        onAddAccountSuccess(data, message);
    };

    const _handleNextStep = () => {
        let errors = [];
        let dataUpdate = {};
        refAlert.current.closeStatusBar();

        switch (step) {
            case 1:
                const { email, customer, error: errorSelect } = refSelectCustomer.current.getValue();
                dataUpdate = { email, customer };
                errors = errorSelect;
                break;
            case 4:
                const { error: errorSetup, ...dataSetup } = refSetupSMTP.current.getValue();
                dataUpdate = dataSetup;
                errors = errorSetup;
                errorSetup.length ? refFooter.current.removeLoading() : _handleSaveSetup({ ...setup, ...dataUpdate });
                break;
        }

        if (errors.length)
            refAlert.current.showStatusBar({ id: 'email_error', message: errors, type: LIST_STATUS.ERROR });
        if (dataUpdate && !errors.length && step !== 4) setState({ step: step + 1, ...dataUpdate });
    };

    const _renderStep = () => {
        switch (step) {
            case 1:
                return <StepSelectCustomer ref={refSelectCustomer} />;
            case 2:
                return <StepConfirmEmail />;
            case 3:
                return <StepChooseSetup onSaveWithMS={_handleSaveSetupWithMS} onSaveWithGG={_handleSaveSetupWithGG} />;
            case 4:
                return <StepSetupSMTP ref={refSetupSMTP} />;
            default:
                break;
        }
    };

    if (!isOpen) return null;
    return (
        <ReactModal
            id="addLocation"
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal --add-email open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className={classNames('modal__container', { '--sm': step === 1 })}>
                <Header onClose={_close} />
                <div className={classNames('body-modal wrap-step', { 'is-overflow': step === 1 })}>
                    <AddonsAlert ref={refAlert} />
                    {_renderStep()}
                </div>
                {step !== 3 && <FooterSetupEmail ref={refFooter} onClose={_close} onNextStep={_handleNextStep} />}
            </div>
        </ReactModal>
    );
};

export default forwardRef(SetupEmailAccount);
