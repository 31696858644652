import React from 'react';

export const LoadingMore = () => {
    return (
        <div className="todo-items">
            <div className="todo-items__action flextop">
                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured mx-1 pointer-events-none" />
                <div className="checkbox" />
                <div className="col-desc flex-1">
                    <div className="loading --animation --line --full" />
                    <div className="loading --animation --line --threefourth mt-2" />
                </div>
            </div>
        </div>
    );
};

export default function Loading() {
    return (
        <div className="wrap-loading wrap-todolist boxs is-show">
            <div className="left-icons">
                <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
            </div>
            <div className="wrapper-template content">
                <div className="content__header flexcenter">
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --onefifth" />
                    </div>
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured mx-1" />
                    <div className="loading v2-btn-default --transparent --icon-sm bg-white-cultured" />
                </div>
                <LoadingMore />
                <div className="todo-items-action">
                    <div className="loading v2-btn-default --transparent btn-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
}
