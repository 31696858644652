import { PHONE_TYPE_ACTIONS } from 'common/redux/actions/phones';

const phoneTypeReducer = (state = {
    isFirstTime: true,
    phoneTypes: [],
    newPhoneType: null,
    phoneUpdatedType: null,
    phoneDeletedType: null
}, action) => {
    switch (action.type) {
        case PHONE_TYPE_ACTIONS.ADD_PHONE_TYPES:
            return {
                ...state,
                phoneTypes: handleSortPhones([...state.phoneTypes, action.payload]),
                newPhoneType: action.payload
            };
        case PHONE_TYPE_ACTIONS.DELETE_PHONE_TYPES:
            return {
                ...state,
                phoneTypes: [...state.phoneTypes].filter((item) => item.id !== action.payload),
                phoneDeletedType: action.payload
            };
        case PHONE_TYPE_ACTIONS.UPDATE_PHONE_TYPES:
            const dataUpdate = action.payload.dataUpdate;
            const idUpdate = action.payload.idUpdate;
            return {
                ...state,
                phoneTypes: handleSortPhones(
                    [...state.phoneTypes].map((item) => {
                        if (item.id === idUpdate) return dataUpdate;
                        return item;
                    })
                ),
                phoneUpdatedType: { oldId: idUpdate, data: dataUpdate }
            };
        case PHONE_TYPE_ACTIONS.GET_LIST_PHONE_TYPES:
            return { ...state, isFirstTime: true };
        case PHONE_TYPE_ACTIONS.SET_STORE_LIST_PHONE_TYPES:
            return {
                ...state,
                isFirstTime: false,
                phoneTypes: action.payload.phoneTypes || state.phoneTypes
            };
        case PHONE_TYPE_ACTIONS.CALL_API_FAILURE:
            return {
                ...state,
                isFirstTime: true
            };
        default:
            return state;
    }
};

/**
 * It takes an array of objects, and returns a new array of objects sorted by the name property
 * @param arr - The array to be sorted.
 */
const handleSortPhones = (arr) => arr.sort((a, b) => a.name.localeCompare(b.name));

export default phoneTypeReducer;
