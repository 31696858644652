import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FieldInput = ({ name = '', label = '', placeholder = '', maxLength, unit = '', currency }) => {
    const { t } = useTranslation('customers');

    return (
        <div className="rows">
            <div className="txt">{t(label)}</div>
            <div
                className={classNames({ 'cost-input --verify --left': !!unit })}
                data-currency-unit={unit}
                data-currency-microdeposit={currency}
            >
                <input
                    name={name}
                    type="text"
                    className="field-input"
                    placeholder={t(placeholder || label)}
                    maxLength={maxLength}
                />
            </div>
        </div>
    );
};

export default FieldInput;
