import { TYPE_ROUTE_OPTIMIZER } from 'common/redux/actions/routeOptimizerAction';

const optimizeRouteReducer = (state = { rangeSelected: null }, action) => {
    switch (action.type) {
        case TYPE_ROUTE_OPTIMIZER.SET_RANGE_TIME_OPTIMIZE:
            return { ...state, rangeSelected: action.range };
        default:
            return state;
    }
};

export default optimizeRouteReducer;
