import React from 'react';

export default function IconPrimary() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                d="M13.25 7H10.75C8.67893 7 7 8.67893 7 10.75V13.25C7 15.3211 8.67893 17 10.75 17H13.25C15.3211 17 17 15.3211 17 13.25V10.75C17 8.67893 15.3211 7 13.25 7Z"
                fill="#FF871E"
            />{' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.0047 15.0059H14.0299L15.0611 9.99031L13.031 11.982L12.0299 9L11.0539 11.982L9 9.99031L10.0047 15.0059Z"
                fill="white"
            />{' '}
        </svg>
    );
}
