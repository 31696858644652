export default function IconVoicemail({ isGreen }) {
    if (isGreen)
        return (
            <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.16667 12.0003C6.19171 12.0003 7.83333 10.3587 7.83333 8.33366C7.83333 6.30861 6.19171 4.66699 4.16667 4.66699C2.14162 4.66699 0.5 6.30861 0.5 8.33366C0.5 10.3587 2.14162 12.0003 4.16667 12.0003ZM4.16667 10.5337C5.38169 10.5337 6.36667 9.54869 6.36667 8.33366C6.36667 7.11863 5.38169 6.13366 4.16667 6.13366C2.95164 6.13366 1.96667 7.11863 1.96667 8.33366C1.96667 9.54869 2.95164 10.5337 4.16667 10.5337Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.16667 12.0003C6.19171 12.0003 7.83333 10.3587 7.83333 8.33366C7.83333 6.30861 6.19171 4.66699 4.16667 4.66699C2.14162 4.66699 0.5 6.30861 0.5 8.33366C0.5 10.3587 2.14162 12.0003 4.16667 12.0003ZM4.16667 10.5337C5.38169 10.5337 6.36667 9.54869 6.36667 8.33366C6.36667 7.11863 5.38169 6.13366 4.16667 6.13366C2.95164 6.13366 1.96667 7.11863 1.96667 8.33366C1.96667 9.54869 2.95164 10.5337 4.16667 10.5337Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.16667 12.0003C6.19171 12.0003 7.83333 10.3587 7.83333 8.33366C7.83333 6.30861 6.19171 4.66699 4.16667 4.66699C2.14162 4.66699 0.5 6.30861 0.5 8.33366C0.5 10.3587 2.14162 12.0003 4.16667 12.0003ZM4.16667 10.5337C5.38169 10.5337 6.36667 9.54869 6.36667 8.33366C6.36667 7.11863 5.38169 6.13366 4.16667 6.13366C2.95164 6.13366 1.96667 7.11863 1.96667 8.33366C1.96667 9.54869 2.95164 10.5337 4.16667 10.5337Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8334 12.0003C14.8585 12.0003 16.5001 10.3587 16.5001 8.33366C16.5001 6.30861 14.8585 4.66699 12.8334 4.66699C10.8084 4.66699 9.16675 6.30861 9.16675 8.33366C9.16675 10.3587 10.8084 12.0003 12.8334 12.0003ZM12.8334 10.5337C14.0484 10.5337 15.0334 9.54869 15.0334 8.33366C15.0334 7.11863 14.0484 6.13366 12.8334 6.13366C11.6184 6.13366 10.6334 7.11863 10.6334 8.33366C10.6334 9.54869 11.6184 10.5337 12.8334 10.5337Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8334 12.0003C14.8585 12.0003 16.5001 10.3587 16.5001 8.33366C16.5001 6.30861 14.8585 4.66699 12.8334 4.66699C10.8084 4.66699 9.16675 6.30861 9.16675 8.33366C9.16675 10.3587 10.8084 12.0003 12.8334 12.0003ZM12.8334 10.5337C14.0484 10.5337 15.0334 9.54869 15.0334 8.33366C15.0334 7.11863 14.0484 6.13366 12.8334 6.13366C11.6184 6.13366 10.6334 7.11863 10.6334 8.33366C10.6334 9.54869 11.6184 10.5337 12.8334 10.5337Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8334 12.0003C14.8585 12.0003 16.5001 10.3587 16.5001 8.33366C16.5001 6.30861 14.8585 4.66699 12.8334 4.66699C10.8084 4.66699 9.16675 6.30861 9.16675 8.33366C9.16675 10.3587 10.8084 12.0003 12.8334 12.0003ZM12.8334 10.5337C14.0484 10.5337 15.0334 9.54869 15.0334 8.33366C15.0334 7.11863 14.0484 6.13366 12.8334 6.13366C11.6184 6.13366 10.6334 7.11863 10.6334 8.33366C10.6334 9.54869 11.6184 10.5337 12.8334 10.5337Z"
                    fill="#1EAA5C"
                />
                <rect x="3.83325" y="10.667" width="9.33333" height="1.33333" fill="#1EAA5C" />
            </svg>
        );

    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.5" cy="12.5" r="4.5" stroke="white" strokeWidth="1.3" />
            <circle cx="5.5" cy="12.5" r="4.5" stroke="white" strokeWidth="1.3" />
            <path d="M5.5 17H18.5" stroke="white" strokeWidth="1.3" />
        </svg>
    );
}
