import React, { useContext } from 'react';
import IconServiceTemplate from 'assets/icon/IconServiceTemplate';
import { DataRepeatContext } from 'app/modules/jobdetail/contexts/DataRepeatContext';

export default function ServiceName() {
    const { repeatSettings, onChangeDataRepeatContext } = useContext(DataRepeatContext);

    function _handleSubmitValue(e) {
        document.getElementById('input_service_name').classList.remove('field-error');
        onChangeDataRepeatContext({
            name: e.target.value
        });
    }

    return (
        <div className="rows">
            <div className="txt">
                <IconServiceTemplate />
                <span className="txt-ellipsis">Service Name</span>
            </div>
            <div className="details">
                <input
                    defaultValue={repeatSettings.name}
                    className="field-input"
                    type="text"
                    onBlur={(e) => _handleSubmitValue(e)}
                    placeholder="Service Name"
                    id="input_service_name"
                    autoFocus
                />
            </div>
        </div>
    );
}
