import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import GDModalWarning from 'app/components/modal/ModalWarning';
import { SMS_ACCEPTED_ATTACHMENTS } from 'app/const/App';
import IconAttachment from 'assets/icon/IconAttachment';

const CustomAttachment = ({
    buttonClassName = 'dropbtn btn-attachment',
    isShowTooltip = false,
    onInsert = () => {}
}) => {
    const { t } = useTranslation('header');
    const refUpload = useRef(null);
    const refWarning = useRef(null);

    const _uploadClick = () => refUpload.current.click();

    const _uploadAttachments = (event) => {
        const files = event.target.files;
        if (!files?.length) return;

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            if (!SMS_ACCEPTED_ATTACHMENTS.includes(file.type)) {
                refWarning.current._open({
                    subDescription: t('error_this_file_is_not_permitted')
                });
            } else {
                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onloadend = () => {
                    onInsert({
                        id: file.lastModified,
                        file,
                        name: file.name,
                        url: reader.result,
                        mime: file.type
                    });
                };
            }
        }

        refUpload.current.value = '';
    };

    const _handleCloseConfirm = () => {
        refWarning.current._close();
    };

    return (
        <Fragment>
            <div className="v2-dropdown add-attachment tooltip">
                <div onClick={_uploadClick} className={buttonClassName}>
                    <IconAttachment />
                    <input
                        onChange={_uploadAttachments}
                        type="file"
                        ref={refUpload}
                        style={{ display: 'none' }}
                        multiple
                        accept={SMS_ACCEPTED_ATTACHMENTS.join(',')}
                    />
                    {isShowTooltip ? <span className="tooltiptext top">{t('attachment')}</span> : null}
                </div>
            </div>
            <GDModalWarning
                ref={refWarning}
                descriptionClassName="text"
                id="sms_warning_type"
                title={t('gorilladesk_says')}
                isDisplayClose={false}
                footer={
                    <div className="footer-modal btn-close justify-end">
                        <div className="v2-btn-main" onClick={_handleCloseConfirm}>
                            {t('common:confirm')}
                        </div>
                    </div>
                }
            />
        </Fragment>
    );
};

export default CustomAttachment;
