import { TYPE_MATH } from 'app/const/opportunity';

//  annualized = One-time + (Monthly x 12) + (Bi-Monthly x 6) + (Quarterly x 4) + Annual
const _calculateValue = (data) => {
    const values = {
        one_time: 0,
        monthly: 0,
        bi_monthly: 0,
        quarterly: 0,
        annual: 0,
        annualized: 0
    };
    if (!data) return values;
    const { period, value } = data || {};

    values[period.name.toLowerCase().replace(/-/g, '_')] = value.value;
    const { one_time, monthly, quarterly, bi_monthly, annual } = values;
    values.annualized = one_time + monthly * 12 + bi_monthly * 6 + quarterly * 4 + annual;
    return values;
};

export const calculatePipeline = ({ type = TYPE_MATH.ADD, pipeline = {}, item = {}, oldItem = null }) => {
    const math_it_up = {
        [TYPE_MATH.ADD]: (x, y) => parseFloat(x) + parseFloat(y),
        [TYPE_MATH.SUB]: (x, y) => parseFloat(x) - parseFloat(y)
    };
    const prevPipeline = { ...pipeline };
    const valuesOldItem = _calculateValue(oldItem);
    Object.entries(_calculateValue(item)).forEach(([key, value]) => {
        prevPipeline[key] = math_it_up[type](
            prevPipeline[key],
            !!oldItem ? math_it_up[TYPE_MATH.SUB](value, valuesOldItem[key]) : value
        );
    });
    prevPipeline.count = math_it_up[type](prevPipeline.count, !!oldItem ? 0 : 1);

    return prevPipeline;
};
