import { takeLatest } from 'redux-saga/effects';

import {
    getCustomerInformation,
    updateStatusCustomer,
    getStatisticCustomer,
    getListSearchCustomer,
    getListCustomer,
    markAsCustomer,
    getCustomerAccountNumber,
    getListSettingCompanyPhoneType,
    getListCustomerSource,
    getListCustomersearchTags,
    getListCustomerReport,
    calendarGetListCustomerSending,
    getListCustomerCharacter
} from './customer';
import { updateStatusCustomerDetailCredit } from './credit';
import { getListContact, createContact, updateContact, deleteContacts } from './contact';

import { getListLocation, createLocation, updateLocation } from './location';

import { getCustomerJobs, actionJobWorkPool, actionGetCustomerListTask, actionUpdateTaskStatus } from './job';

import { getListCustomerTag } from './tag';

import { getStatementHistories, getStatementSumary, statementSendByEmail, statementToPDF } from './statement';

import { addPaymentMethod, getCustomerAccountInformation, saveCustomerAccountInfo } from './account';

import { TYPE_CUSTOMER } from 'common/redux/actions/customers/customerAction';
import { TYPE_CREDIT } from 'common/redux/actions/customers/creditAction';
import { TYPE_CONTACT } from 'common/redux/actions/customers/contactAction';

import { TYPE_LOCATION } from 'common/redux/actions/customers/locationAction';
import { TYPE_STATEMENT } from 'common/redux/actions/customers/statementAction';
import { TYPE_ACCOUNT } from 'common/redux/actions/customers/accountAction';
import { TYPE_CUSTOMER_JOB } from 'common/redux/actions/customers/jobAction';
import { TYPE_CUSTOMER_TAG } from 'common/redux/actions/customers/tagAction';

export function* getListCustomerWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_SEARCH_CUSTOMER, getListSearchCustomer);
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_CUSTOMER_CHARACTER, getListCustomerCharacter);
}

export function* getListSearchCustomerWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_CUSTOMER, getListCustomer);
}

export function* customerMarkAsWatcher() {
    yield takeLatest(TYPE_CUSTOMER.MASK_AS_CUSTOMER, markAsCustomer);
}

export function* updateStatusCustomerWatcher() {
    yield takeLatest(TYPE_CUSTOMER.UPDATE_STATUS_CUSTOMER, updateStatusCustomer);
}

export function* getStatisticCustomerWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_STATISTIC_CUSTOMER, getStatisticCustomer);
}

export function* getCustomerAccountNumberWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_CUSTOMER_ACCOUNT_NUMBER, getCustomerAccountNumber);
}

export function* getListSettingCompanyPhoneTypeWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_SETTING_COMPANY_PHONE_TYPE, getListSettingCompanyPhoneType);
}

export function* getListCustomerSourceWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_CUSTOMER_SOURCE, getListCustomerSource);
}

export function* getListCustomersearchTagsWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_CUSTOMER_SEARCH_TAGS, getListCustomersearchTags);
}

export function* getListCustomerReportWatcher() {
    yield takeLatest(TYPE_CUSTOMER.REFRESH_LIST_CUSTOMER_REPORT, getListCustomerReport);
}

export function* calendarGetListCustomerSendingWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_LIST_CUSTOMERS_SENDING, calendarGetListCustomerSending);
}

export function* updateStatusCustomerDetailCreditWatcher() {
    yield takeLatest(TYPE_CREDIT.UPDATE_STATUS_CREDIT, updateStatusCustomerDetailCredit);
}

export function* getListContactWatcher() {
    yield takeLatest(TYPE_CONTACT.GET_LIST_CONTACT, getListContact);
}

export function* createContactWatcher() {
    yield takeLatest(TYPE_CONTACT.CREATE_CONTACT, createContact);
}

export function* updateContactWatcher() {
    yield takeLatest(TYPE_CONTACT.UPDATE_CONTACT, updateContact);
}

export function* deleteContactWatcher() {
    yield takeLatest(TYPE_CONTACT.DELETE_CONTACTS, deleteContacts);
}

export function* getCustomerInformationWatcher() {
    yield takeLatest(TYPE_CUSTOMER.GET_CUSTOMER_INFORMATION, getCustomerInformation);
}

export function* getListLocationWatcher() {
    yield takeLatest(TYPE_LOCATION.GET_LIST_LOCATION, getListLocation);
}

export function* createLocationWatcher() {
    yield takeLatest(TYPE_LOCATION.CREATE_LOCATION, createLocation);
}

export function* updateLocationWatcher() {
    yield takeLatest(TYPE_LOCATION.UPDATE_LOCATION, updateLocation);
}

export function* getStatementSummaryWatcher() {
    yield takeLatest(TYPE_STATEMENT.GET_STATEMENT_SUMMARY, getStatementSumary);
}

export function* getStatementHistoriesWatcher() {
    yield takeLatest(TYPE_STATEMENT.GET_STATEMENT_HISTORIES, getStatementHistories);
}

export function* statementToPDFWatcher() {
    yield takeLatest(TYPE_STATEMENT.STATEMENT_TO_PDF, statementToPDF);
}

export function* statementSendByEmailWatcher() {
    yield takeLatest(TYPE_STATEMENT.STATEMENT_SEND_BY_EMAIL, statementSendByEmail);
}

export function* saveCustomerAccountInfoWatcher() {
    yield takeLatest(TYPE_ACCOUNT.CUSTOMER_ACCOUNT_SAVE, saveCustomerAccountInfo);
}

export function* getCustomerAccountInformationWatcher() {
    yield takeLatest(TYPE_ACCOUNT.GET_CUSTOMER_ACCOUNT_INFORMATION, getCustomerAccountInformation);
}

export function* addPaymentMethodWatcher() {
    yield takeLatest(TYPE_ACCOUNT.CUSTOMER_ACCOUNT_ADD_PAYMENT_METHOD, addPaymentMethod);
}

export function* getCustomerJobsWatcher() {
    yield takeLatest(TYPE_CUSTOMER_JOB.GET_LIST_JOB, getCustomerJobs);
}

export function* getListCustomerTagHistoryWatcher() {
    yield takeLatest(TYPE_CUSTOMER_TAG.GET_LIST, getListCustomerTag);
}

export function* getListJobWorkPoolWatcher() {
    yield takeLatest(TYPE_CUSTOMER_JOB.GET_LIST_JOB_WORK_POOL, actionJobWorkPool);
}

export function* getCustomerListTaskWatcher() {
    yield takeLatest(TYPE_CUSTOMER_JOB.GET_LIST_TASKS, actionGetCustomerListTask);
}

export function* updateTaskStatusWatcher() {
    yield takeLatest(TYPE_CUSTOMER_JOB.UPDATE_TASK_STATUS, actionUpdateTaskStatus);
}
