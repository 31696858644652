import React from 'react';
import { clientQuery } from 'common/utils/ApiUtils';
import { ACION_MATERIAL_ADDON_OPTIONS } from 'app/const/api/Material';
import { getLocalStorageValue } from 'common/utils/LocalStorageUtils';
import { ACCESS_TOKEN } from 'app/const/App';
import { URL_PRINT_MASTER_KEY } from 'app/const/api/Export';
import { useTranslation } from 'react-i18next';
import { getBranchId } from 'app/const/Branch';

let timmerHide, timmerShow;

export default function HeaderItem({ options }) {
    const { t } = useTranslation(['addons']);
    function _handleChange(e, key) {
        if (key === 'show_key') {
            timmerShow && clearTimeout(timmerShow);
            timmerShow = setTimeout(() => {
                _handleUpdate(key, e.target.checked);
            }, 500);
        } else {
            timmerHide && clearTimeout(timmerHide);
            timmerHide = setTimeout(() => {
                _handleUpdate(key, e.target.checked);
            }, 500);
        }
    }

    function _handleUpdate(keyword, value) {
        clientQuery(ACION_MATERIAL_ADDON_OPTIONS, {
            method: 'PUT',
            data: {
                value: value ? 1 : 0,
                keyword
            }
        });
    }

    function _handleExport() {
        const token = getLocalStorageValue(ACCESS_TOKEN);

        const form = document.createElement('form');
        form.action = URL_PRINT_MASTER_KEY;
        form.method = 'POST';
        form.style.display = 'none';
        form.innerHTML = `
            <input name="token" value="${token}">
            <input name="gd-branch-id" value="${getBranchId()}">
        `;
        form.setAttribute('target', '_blank');
        document.body.append(form);
        form.submit();

        const elements = document.getElementsByClassName('print-master-key');
        elements.length > 0 && elements[0].parentNode.removeChild(elements[0]);
    }

    function _renderOptions() {
        return options.map((item) => {
            const itemKey = item.keyword;

            return (
                <div key={itemKey} className="check-items">
                    <input
                        onChange={(e) => _handleChange(e, itemKey)}
                        id={itemKey}
                        defaultChecked={!!item.value}
                        type="checkbox"
                    />
                    <div className="item-checkbox">
                        <label htmlFor={itemKey}>
                            <span className="txt-ellipsis">{item.name}</span>
                        </label>
                    </div>
                </div>
            );
        });
    }
    return (
        <div className="boxs__contents">
            <div className="description">
                {t(
                    'addons:this_is_where_you_add_a_master_list_of_all_your_materials_locations_target_pests_and_methods'
                )}
            </div>
            <div className="v2-btn-main" onClick={_handleExport}>
                {t('addons:print_master_key')}
            </div>
            {_renderOptions()}
        </div>
    );
}
