import { FIELDS_TYPE, OPERATOR_IDS } from './types';

export const PAST_ID = 'ago';
export const FUTURE_ID = 'from now';
export const CUSTOM_ID = 'custom';
export const NOW_ID = 'now';

export const DATE_FIELD_TYPES = [
    FIELDS_TYPE.CREATED_DATE,
    FIELDS_TYPE.DATE_WITHOUT_PRESENT,
    FIELDS_TYPE.LATEST_CALL_CREATED_DATE,
    FIELDS_TYPE.CUSTOMER_CREATED_DATE
];
export const RANGE_BEFORE_AFTER = { ON_OR_AFTER: 'onOrAfterMoment', BEFORE: 'beforeMoment' };
export const RANGE_PAST_FUTURE = { FUTURE: 'FUTURE', PAST: 'PAST' };
export const RANGE_START_END = { START: 'START', END: 'END' };
export const RANGE_TIMES = ['years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds'];
export const RANGE_DAYS = ['24 hours', '7 days', '30 days'];

export const OPTIONS_DATE_EXACTLY = [
    { id: 'today', value: 'today', label: 'Today' },
    { id: 'tomorrow', value: 'tomorrow', label: 'Tomorrow' },
    { id: 'this week', value: 'this week', label: 'This week' },
    { id: 'this month', value: 'this month', label: 'This month' },
    { id: 'this quarter', value: 'this quarter', label: 'This quarter' },
    { id: 'this year', value: 'this year', label: 'This year' },
    '__DIVIDER_ITEM__',
    { id: 'yesterday', value: 'yesterday', label: 'Yesterday' },
    { id: 'last week', value: 'last week', label: 'Last week' },
    { id: 'last month', value: 'last month', label: 'Last month' },
    { id: 'last quarter', value: 'last quarter', label: 'Last quarter' },
    { id: 'last year', value: 'last year', label: 'Last year' },
    { id: PAST_ID, value: PAST_ID, label: 'Days ago…' },
    '__DIVIDER_ITEM__',
    { id: 'tomorrow', value: 'tomorrow', label: 'Tomorrow' },
    { id: 'next week', value: 'next week', label: 'Next week' },
    { id: 'next month', value: 'next month', label: 'Next month' },
    { id: 'next quarter', value: 'next quarter', label: 'Next quarter' },
    { id: 'next year', value: 'next year', label: 'Next year' },
    { id: FUTURE_ID, value: FUTURE_ID, label: 'Days from now…' },
    '__DIVIDER_ITEM__',
    { id: CUSTOM_ID, value: CUSTOM_ID, label: 'Custom date…' }
];

export const OPTIONS_DATE_RANGE = [
    { id: NOW_ID, value: NOW_ID, label: 'Now' },
    '__DIVIDER_ITEM__',
    ...OPTIONS_DATE_EXACTLY
];

export const OPTIONS_DATE_WITHIN = [
    { id: '24 hours ago', value: '24 hours ago', label: 'The last 24 hours' },
    { id: '7 days ago', value: '7 days ago', label: 'The last 7 days' },
    { id: '30 days ago', value: '30 days ago', label: 'The last 30 days' },
    { id: PAST_ID, value: PAST_ID, label: 'The last…' },
    '__DIVIDER_ITEM__',
    { id: '24 hours from now', value: '24 hours from now', label: 'The next 24 hours' },
    { id: '7 days from now', value: '7 days from now', label: 'The next 7 days' },
    { id: '30 days from now', value: '30 days from now', label: 'The next 30 days' },
    { id: FUTURE_ID, value: FUTURE_ID, label: 'The next…' }
];

export const DATE_OPTIONS = {
    [OPERATOR_IDS.IS]: OPTIONS_DATE_EXACTLY,
    [OPERATOR_IDS.NOT]: OPTIONS_DATE_EXACTLY,
    [OPERATOR_IDS.BEFORE]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.ON_OR_BEFORE]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.ON_OR_AFTER]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.AFTER]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.BETWEEN]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.NOT_BETWEEN]: OPTIONS_DATE_RANGE,
    [OPERATOR_IDS.WITHIN]: OPTIONS_DATE_WITHIN,
    [OPERATOR_IDS.NOT_WITHIN]: OPTIONS_DATE_WITHIN
};
