import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import ItemJobStatus from 'app/components/job/ItemJobStatus';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';

const ItemJobStatusAddons = ({
    permission = true,
    item = {},
    editItem = {},
    onClickEdit = () => {},
    onDelete = () => {}
}) => {
    const { t } = useTranslation(['addons']);
    return (
        <Fragment>
            <div className="col-status flex-1">
                <ItemJobStatus defaultStatus={item} excludeWrapper />
            </div>
            {permission ? (
                <div className="col-icon flexcenter gap-4">
                    <div
                        className={classNames('v2-btn-default --icon-lg tooltip', {
                            'is-disable': editItem?.id === item.id
                        })}
                        onClick={() => onClickEdit(item)}
                    >
                        <IconEdit />
                        <span className="tooltiptext top">{t('addons:edit')}</span>
                    </div>
                    <div className="v2-btn-default --icon-lg tooltip" onClick={() => onDelete(item)}>
                        <IconTrash />
                        <span className="tooltiptext top">{t('addons:delete')}</span>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};

export default ItemJobStatusAddons;
