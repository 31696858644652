import { OPERATOR_IDS } from '../../constants/types';

export const getOperationDurationRange = (condition, negate = false) => {
    const { gt, lt, gte, lte } = condition;

    if (gte && lte)
        return { value: { durationValue: gte }, operatorId: negate ? OPERATOR_IDS.NOT_EXACTLY : OPERATOR_IDS.EXACTLY };
    if (gt || lt) {
        return {
            value: { durationValue: condition[!!gt ? 'gt' : 'lt'] },
            operatorId: gt ? OPERATOR_IDS.MORE : OPERATOR_IDS.LESS
        };
    }

    return {
        value: { durationValue: condition[!!gt ? 'gt' : 'lt'] },
        operatorId: !!gt ? OPERATOR_IDS.MORE : OPERATOR_IDS.LESS
    };
};
