import { takeLatest } from 'redux-saga/effects';
import { actionGetJobSummary } from './info';
import { actionUpdateJobStatus } from './update';
import { JOB_PREVIEW_ACTIONS } from 'common/redux/actions/job';

export function* actionGetJobSummaryWatcher() {
    yield takeLatest(JOB_PREVIEW_ACTIONS.GET_JOB_SUMMARY, actionGetJobSummary);
}

export function* actionUpdateStautsJobWatcher() {
    yield takeLatest(JOB_PREVIEW_ACTIONS.UPDATE_JOB_STATUS, actionUpdateJobStatus);
}
