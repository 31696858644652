import { COMMON, DEFAULT_ALL } from 'app/const/App';
import {
    CONTACT_PHONES_EXCEPT_ATTRIBUTE,
    CUSTOMERS_IMPORT_OPTIONS,
    LIMIT_SHOW_ROW_IMPORT,
    PHONES_EXCEPT_ATTRIBUTE,
    ALL_OPTIONS_IMPORT_CUSTOMER
} from 'app/const/Customers';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDone from 'assets/icon/IconDone';
import classNames from 'classnames';
import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconDropDown from 'assets/icon/IconDropDown';

const ColumnPreviewImport = ({
    indexColumn = null,
    name = null,
    children = [],
    moreCount = 0,
    optionsSelected = [],
    onSelect = () => {},
    isLimitPhone,
    isLimitContactPhone
}) => {
    const { t } = useTranslation(['common', 'customers']);
    const [state, dispatchState] = useReducer(reducer, {
        selected: { id: null, title: 'select_attribute', value: null }
    });
    const attributeSelected = state.selected.value;
    const refDropdown = useRef(null);
    const refKeyCurrent = useRef({ key: 's', lastIndex: 0 });

    const _filterColumnOptions = (key) => {
        return ALL_OPTIONS_IMPORT_CUSTOMER.filter((item) => {
            const { id, name } = item;
            if (name.toLowerCase().startsWith(key)) {
                if (PHONES_EXCEPT_ATTRIBUTE.includes(id)) {
                    if (isLimitPhone && !PHONES_EXCEPT_ATTRIBUTE.includes(attributeSelected)) return false;
                    return true;
                }
                if (CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(id)) {
                    if (isLimitContactPhone && !CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(attributeSelected))
                        return false;
                    return true;
                }
                return !optionsSelected.some((option) => option.field === id);
            }
            return false;
        });
    };

    const _handleKeydown = ({ key }) => {
        const finalOptions = _filterColumnOptions(key);
        const indexExisted = finalOptions.findIndex((item) => item.name.toLowerCase().startsWith(key));

        const _bindOption = (id = null, index = 0) => {
            _handleSelect(id);
            _scrollToItemSelected(id);
            refKeyCurrent.current = { key, lastIndex: index };
        };

        const _bindFirstOption = () => {
            _bindOption(finalOptions[indexExisted]?.id, indexExisted);
        };

        if (indexExisted > DEFAULT_ALL) {
            if (key !== refKeyCurrent.current.key) {
                _bindFirstOption();
            } else {
                const indexItemNext = finalOptions.findIndex(
                    (item, index) => item.name.toLowerCase().startsWith(key) && index > refKeyCurrent.current.lastIndex
                );
                if (indexItemNext > DEFAULT_ALL) {
                    _bindOption(finalOptions[indexItemNext]?.id, indexItemNext);
                } else {
                    _bindFirstOption();
                }
            }
        }
    };

    const _scrollToItemSelected = (id) => {
        const el = document.getElementById(`option-import-${id}`);
        el && el.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    };

    const _handleSelect = (id) => {
        dispatchState({ selected: { id: id, title: id || 'select_attribute', value: id } });
        onSelect({ column: `${indexColumn}`, field: id });
    };

    const _handleSelectOption = (dataItem) => {
        dispatchState({ selected: dataItem });
        onSelect({ column: `${indexColumn}`, field: dataItem.value });
        refDropdown.current._closeDropdown();
    };

    const _renderListDropdown = () => {
        return CUSTOMERS_IMPORT_OPTIONS.map((item, index) => {
            let newChilds = [...item.children];
            const { title } = item;

            if (isLimitPhone && title === COMMON.PHONES && !PHONES_EXCEPT_ATTRIBUTE.includes(attributeSelected))
                return null;
            if (
                isLimitContactPhone &&
                title === COMMON.CONTACTS &&
                !CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(attributeSelected)
            )
                newChilds = newChilds.filter((child) => !CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(child.id));

            return (
                <React.Fragment key={index.toString()}>
                    {!item.isHiddenTitle && <div className="title-section">{t(`customers:${item.title}`)}</div>}
                    {newChilds.map((children) => _renderItemOptions(children))}
                </React.Fragment>
            );
        });
    };

    const _renderItemOptions = ({ id, title, value }) => {
        const isHidden =
            optionsSelected.some((item) => item?.field === id && id !== null) &&
            !PHONES_EXCEPT_ATTRIBUTE.includes(id) &&
            !CONTACT_PHONES_EXCEPT_ATTRIBUTE.includes(id);
        const isSelectedItem = attributeSelected === value;

        if (isHidden && !isSelectedItem) return null;
        return (
            <div
                key={id}
                id={`option-import-${id}`}
                className={classNames('items txt-ellipsis', { active: isSelectedItem })}
                onClick={() => _handleSelectOption({ id, title, value })}
            >
                {t(`customers:${title}`)}
            </div>
        );
    };

    const _renderItemsImport = () => {
        return children.map((item, index) => {
            if (index < LIMIT_SHOW_ROW_IMPORT)
                return (
                    <li key={index.toString()} className="item">
                        <span className="number">{index + 1}.</span>
                        <span className="txt-ellipsis" title={item}>
                            {item}
                        </span>
                    </li>
                );
            return null;
        });
    };

    return (
        <div className={classNames('list-comlumn', { active: !!attributeSelected })}>
            <div className="column-header">
                <div className="title">{name}</div>
                <div className="column-header__icon">
                    {!!attributeSelected ? (
                        <span className="checked">
                            <IconDone isSuccess />
                        </span>
                    ) : (
                        <span className="checkbox-items">
                            <IconDone />
                        </span>
                    )}
                </div>
            </div>

            <div className="select-attribute">
                <CalendarDropdown
                    id="import_customer_column_dropdown"
                    ref={refDropdown}
                    buttonClassName="dropbtn v2-btn-default selection"
                    wrapperListClass="v2-dropdown__menu scrolls"
                    onKeydown={_handleKeydown}
                    customDropButton={() => (
                        <>
                            <p className="txt-ellipsis">{t(`customers:${state?.selected?.title}`)}</p>
                            <IconDropDown />
                        </>
                    )}
                >
                    <ul>{_renderListDropdown()}</ul>
                </CalendarDropdown>
            </div>

            <div className="import-items">
                <ul>
                    {_renderItemsImport()}
                    {moreCount > 0 && <li className="item last">… +{moreCount} more</li>}
                </ul>
            </div>
        </div>
    );
};

export default ColumnPreviewImport;
