import React, { forwardRef, useEffect, useId, useImperativeHandle, useReducer } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { CALENDAR_CUSTOM_EVENTS } from '../../const';
import { MagnetProvider } from './MagnetContext';

const MagnetContent = React.lazy(() => import('./components/MagnetContent'));
const JobMagnet = forwardRef(({ onRefetchCalendar = () => {} }, ref) => {
    const idModal = useId();
    const defaultMagnetValues = useSelector(
        ({ auth }) => auth.user.settings.addons.advance_routing?.job_magnet || null
    );
    const [state, dispatchState] = useReducer(reducer, { isOpen: false, eventSelected: null });
    const { isOpen, eventSelected } = state;

    useImperativeHandle(ref, () => ({ open: handleOpen, close: handleClose }));

    useEffect(() => {
        window.addEventListener(CALENDAR_CUSTOM_EVENTS.OPEN_MAGNET_JOB, handleOpen);
        return () => {
            window.removeEventListener(CALENDAR_CUSTOM_EVENTS.OPEN_MAGNET_JOB, handleOpen);
        };
    }, []);

    const handleOpen = (data) => {
        dispatchState({ isOpen: true, eventSelected: data.detail });
    };

    const handleClose = () => {
        dispatchState({ isOpen: false, eventSelected: null });
    };

    return (
        <ReactModal
            id={idModal}
            isOpen={isOpen}
            style={{ overlay: { background: 'transparent' } }}
            className="modal container-modal modal-job-magnet open"
            onRequestClose={handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={handleClose} />
            {isOpen ? (
                <React.Suspense fallback={null}>
                    <MagnetProvider
                        defaultMagnetValues={defaultMagnetValues}
                        defaultEventSelected={eventSelected}
                        onClose={handleClose}
                        onSuccess={onRefetchCalendar}
                    >
                        <MagnetContent onClose={handleClose} />
                    </MagnetProvider>
                </React.Suspense>
            ) : null}
        </ReactModal>
    );
});

JobMagnet.displayName = 'JobMagnetModal';
export default JobMagnet;
