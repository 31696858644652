import { ADDONS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconArrow from 'assets/icon/IconArrow';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function AddonBack({ path = ADDONS, title = '' }) {
    const { t } = useTranslation(['addons']);

    return (
        <Link to={addBranchPath(path)} className="v2-btn-default has-icon --grey">
            <IconArrow isPrev />
            {title || t('common:addons')}
        </Link>
    );
}
