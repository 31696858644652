import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IconMicrophone from 'assets/icon/IconMicrophone';
import IconCall from 'assets/icon/IconCall';
import TooltipPopper from 'app/components/tooltip/TooltipPopper';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { getTypeCoachingVoip } from 'app/const/Voip';

const ListBarge = ({ listUser = [] }) => {
    const { t } = useTranslation('header');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const LENGTH_USER = 4;

    const _renderItems = (list = [], isShowAll = true) => {
        return list.map(({ id: userId, isMute = false, isCoaching = false }, index) => {
            if (!isShowAll && index > LENGTH_USER - 2) return null;

            const { full_name, avatar } = companyUsers.find((item) => item.id === userId);
            const typeUser = getTypeCoachingVoip({ isMute, isCoaching });

            return (
                <TooltipPopper
                    key={userId}
                    className={classNames('avt-has-micro relative tooltip', {
                        'is-muted': isMute,
                        'is-whisper': isCoaching
                    })}
                    tooltipText={`${full_name} ${t(`is_doing_${typeUser}`)}`}
                    popperConfig={{
                        placement: 'bottom',
                        modifiers: [{ name: 'offset', options: { offset: [0, 5] } }],
                        strategy: 'fixed'
                    }}
                >
                    <img className="avt-img --lg" src={avatar} width={32} height={32} />
                    <span className="avt-has-micro__icon absolute">
                        {isCoaching ? <IconCall isWhisper /> : <IconMicrophone isSmGrey isSmGreen={!isMute} />}
                    </span>
                </TooltipPopper>
            );
        });
    };

    const _renderDropdown = (list = []) => {
        const _renderItem = () => {
            return list.map(({ id: userId, isMute = false, isCoaching = false }, index) => {
                if (index <= LENGTH_USER - 2) return null;

                const { full_name, avatar } = companyUsers.find((item) => item.id === userId);

                return (
                    <li key={userId} className="items">
                        <div
                            className={classNames('avt-has-micro relative tooltip', {
                                'is-muted': isMute,
                                'is-whisper': isCoaching
                            })}
                        >
                            <img className="avt-img --lg" src={avatar} width={32} height={32} />
                            <span className="avt-has-micro__icon absolute">
                                {isCoaching ? <IconCall isWhisper /> : <IconMicrophone isSmGrey isSmGreen={!isMute} />}
                            </span>
                        </div>
                        <span className="txt-ellipsis flex-1 fw-500">{full_name}</span>
                    </li>
                );
            });
        };

        return (
            <DropdownPopper
                wrapperClassName="v2-dropdown"
                wrapperListClass="v2-dropdown__menu scrolls --right"
                buttonClassName="dropbtn more-user fs-12 black"
                customButton={`+${list.length - LENGTH_USER + 1}`}
                isUseToggle
            >
                <ul>{_renderItem()}</ul>
            </DropdownPopper>
        );
    };

    const _renderUsers = () => {
        if (listUser.length > LENGTH_USER) {
            return (
                <Fragment>
                    {_renderItems(listUser, false)}
                    {_renderDropdown(listUser)}
                </Fragment>
            );
        }
        return _renderItems(listUser);
    };

    if (!listUser.length) return null;

    return <div className="flexcenter">{_renderUsers()}</div>;
};

export default ListBarge;
