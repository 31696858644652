import React, { useContext } from 'react';
import { TABS_INOIVE_REPEAT, TYPE_INVOICE_REPEAT } from 'app/modules/jobdetail/const/Invoice';
import Summary from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/Summary';
import { InvoiceRepeatContext } from 'app/modules/jobdetail/contexts/InvoiceRepeatContext';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import Weekly from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/Weekly';
import Monthly from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/Monthly';
import Yearly from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/Yearly';
import Actions from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/Actions';
import End from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/End';
import InvoiceDate from 'app/modules/jobdetail/tabs/addinvoice/form/settings/repeat/InvoiceDate';
import IconSync from 'assets/icon/IconSync';
import moment from 'moment';
import IconArrow from 'assets/icon/IconArrow';
import { useTranslation } from 'react-i18next';

const FormRepeat = ({ onClose, listEmail, listCard }) => {
    const { t } = useTranslation();
    const { invoiceRepeatData, updateInvoiRepeatContext } = useContext(InvoiceRepeatContext);
    const { invoiceData, updateInvoiceDataContext } = useContext(AddInvoiceContext);
    const invoiceRepeatDetail = invoiceRepeatData.offset;
    const { date: invoiceDate, next_date: invoiceNextDate, isJobInvoice } = invoiceData;

    function _changeTab(e, tabAcitve) {
        e.stopPropagation();

        let refRecurrence = { ...invoiceRepeatData };

        switch (tabAcitve) {
            case TABS_INOIVE_REPEAT.OFF:
                refRecurrence = {
                    ...refRecurrence,
                    offset: {
                        ...invoiceRepeatDetail,
                        frequency: 'OFF',
                        interval: 0
                    },
                    repeat: TYPE_INVOICE_REPEAT.OFF
                };
                break;
            case TABS_INOIVE_REPEAT.NONE:
                refRecurrence = {
                    ...refRecurrence,
                    offset: {
                        ...invoiceRepeatDetail,
                        frequency: TABS_INOIVE_REPEAT.NONE,
                        interval: 0
                    },
                    repeat: TYPE_INVOICE_REPEAT.OFF
                };
                break;
            case TABS_INOIVE_REPEAT.WEEKLY:
                refRecurrence = {
                    ...refRecurrence,
                    offset: {
                        ...invoiceRepeatDetail,
                        MO: '0',
                        TU: '0',
                        WE: '0',
                        TH: '0',
                        FR: '0',
                        SA: '0',
                        SU: '0',
                        [moment().format('dd').toUpperCase()]: '1',
                        interval: 1,
                        frequency: TABS_INOIVE_REPEAT.WEEKLY,
                        nextDateInvoice: invoiceNextDate || invoiceDate,
                        bymonthday: '',
                        byyearday: ''
                    },
                    action: 1,
                    repeat: TYPE_INVOICE_REPEAT.REPEAT
                };
                break;
            case TABS_INOIVE_REPEAT.MONTHLY:
                refRecurrence = {
                    ...refRecurrence,
                    offset: {
                        ...invoiceRepeatDetail,
                        frequency: TABS_INOIVE_REPEAT.MONTHLY,
                        interval: 1,
                        bymonthday: 'dayofweek',
                        nextDateInvoice: invoiceNextDate || invoiceDate,
                        dateIssue: invoiceDate
                    },
                    action: 1,
                    repeat: TYPE_INVOICE_REPEAT.REPEAT
                };
                break;
            case TABS_INOIVE_REPEAT.YEARLY:
                refRecurrence = {
                    ...refRecurrence,
                    offset: {
                        ...invoiceRepeatDetail,
                        frequency: TABS_INOIVE_REPEAT.YEARLY,
                        interval: 1,
                        byyearday: 'dayofweek',
                        nextDateInvoice: invoiceNextDate || invoiceDate,
                        dateIssue: invoiceDate
                    },
                    action: 1,
                    repeat: TYPE_INVOICE_REPEAT.REPEAT
                };
                break;
            default:
                break;
        }

        updateInvoiRepeatContext(refRecurrence);
    }

    const _handleSave = (e) => {
        e.stopPropagation();
        updateInvoiceDataContext({
            ...invoiceData,
            recurrence: { ...invoiceRepeatData },
            note_repeat: !!invoiceRepeatData?.repeat ? 1 : 0
        });

        onClose();
    };

    const finalTabActive = invoiceRepeatDetail?.frequency || TABS_INOIVE_REPEAT.OFF;
    const isTabOff =
        (!invoiceRepeatData.repeat && finalTabActive !== TABS_INOIVE_REPEAT.NONE) ||
        finalTabActive === TABS_INOIVE_REPEAT.OFF;
    const isTabWeekly = finalTabActive === TABS_INOIVE_REPEAT.WEEKLY;
    const isTabMonthly = finalTabActive === TABS_INOIVE_REPEAT.MONTHLY;
    const isTabYearly = finalTabActive === TABS_INOIVE_REPEAT.YEARLY;

    const isTabNone = finalTabActive === TABS_INOIVE_REPEAT.NONE;

    return (
        <div className="container-column form-invoice-repeat content-has-tabs">
            <div className="header-modal flex-auto">
                <div onClick={onClose} className="v2-btn-default --icon-lg" tabIndex="0">
                    <IconArrow isPrev />
                </div>
                <h1 className="title-h1 flex-1">{t('invoice_frequency')}</h1>
            </div>
            <div className="content-form details-job customer-info-number pt-0">
                <div className="rows tabs tabs-daily nav-tabs">
                    <div className="txt">
                        <IconSync isHasColor />
                        Repeat
                    </div>
                    <div className="detail btn-item m-0">
                        <button
                            onClick={(e) => _changeTab(e, TABS_INOIVE_REPEAT.OFF)}
                            className={`tab-items item-off ${isTabOff ? 'active-tab-selector' : ''}`}
                            type="button"
                        >
                            {!!isJobInvoice ? 'Repeat with the job' : 'Does not repeat'}
                        </button>
                        <button
                            onClick={(e) => _changeTab(e, TABS_INOIVE_REPEAT.WEEKLY)}
                            className={`tab-items ${isTabWeekly ? 'active-tab-selector' : ''}`}
                            type="button"
                        >
                            Weekly
                        </button>
                        <button
                            onClick={(e) => _changeTab(e, TABS_INOIVE_REPEAT.MONTHLY)}
                            className={`tab-items ${isTabMonthly ? 'active-tab-selector' : ''}`}
                            type="button"
                        >
                            Monthly
                        </button>
                        <button
                            onClick={(e) => _changeTab(e, TABS_INOIVE_REPEAT.YEARLY)}
                            className={`tab-items ${isTabYearly ? 'active-tab-selector' : ''}`}
                            type="button"
                        >
                            Yearly
                        </button>
                        {isJobInvoice && (
                            <button
                                onClick={(e) => _changeTab(e, TABS_INOIVE_REPEAT.NONE)}
                                className={`tab-items ${isTabNone ? 'active-tab-selector' : ''}`}
                                type="button"
                            >
                                Does not repeat
                            </button>
                        )}
                    </div>
                </div>

                <div className="tab-content mb-4">
                    {isTabWeekly && <Weekly />}
                    {isTabMonthly && <Monthly />}
                    {isTabYearly && <Yearly />}
                </div>
                <End />
                {!!invoiceRepeatData.repeat && (
                    <InvoiceDate date={invoiceRepeatDetail.nextDateInvoice || invoiceNextDate || invoiceDate} />
                )}
                {/* hide with case add service teamplate */}
                {!invoiceData.settingService && !isTabOff && !isTabNone && (
                    <Actions listEmail={listEmail} listCard={listCard} />
                )}

                <Summary />
            </div>
            <div className="footer-form is-sticky justify-end">
                <div onClick={onClose} className="v2-btn-default --transparent" tabIndex="0">
                    {t('cancel')}
                </div>
                <div onClick={_handleSave} className="v2-btn-main ml-2" tabIndex="0">
                    {t('save_frequency')}
                </div>
            </div>
        </div>
    );
};

export default FormRepeat;
