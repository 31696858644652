import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { MODES_TIME_WINDOW } from '../Const';
import IconClose from 'assets/icon/IconClose';
import Templates from './Templates';
import TimePicker from 'app/modules/jobdetail/components/time/picker';
import moment from 'moment';
import ReactModal from 'react-modal';
import { convertTimeToISO } from 'common/utils/DateUtils';
import ButtonSave from 'app/components/button/ButtonSave';
import { convertTimeToNumber } from 'common/utils/TimeUtils';
import StatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/Status';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const FormTimeWindow = forwardRef(({ onSubmit = () => {}, isAddJob = false }, ref) => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        mode: MODES_TIME_WINDOW.CUSTOM,
        id: '',
        start: '',
        end: '',
        jobInfo: {}
    });

    const { company: companySetting } = useSelector(({ auth }) => auth.user);

    const refButtonSave = useRef(null);
    const refStatusBar = useRef(null);
    const refTimePickerStart = useRef(null);
    const refTimePickerEnd = useRef(null);

    useImperativeHandle(ref, () => ({ openForm: _handleOpenForm }));

    const {
        isVisible,
        start: finalTimeStart,
        end: finalTimeEnd,
        mode: finalMode,
        id: finalIdSelected,
        jobInfo
    } = state;

    const momentStart = moment(finalTimeStart).utc();
    const momentEnd = moment(finalTimeEnd).utc();

    const isModeAny = finalMode === MODES_TIME_WINDOW.ANY_TIME;
    const isModeTemplate = finalMode === MODES_TIME_WINDOW.TEMPLATE;
    const isModeCustom = finalMode === MODES_TIME_WINDOW.CUSTOM;

    const getMode = (id) => {
        switch (id) {
            case '':
                return MODES_TIME_WINDOW.CUSTOM;
            case '-1':
                return MODES_TIME_WINDOW.ANY_TIME;
            default:
                return MODES_TIME_WINDOW.TEMPLATE;
        }
    };

    function _handleOpenForm(dataTime) {
        dispatchState({
            mode: getMode(dataTime.id),
            isVisible: true,
            start: convertTimeToISO(
                moment(`${moment().utc().format('MM-DD-YYYY')} ${dataTime.timeStart}`, 'MM-DD-YYYY hh:mm A')
            ),
            end: convertTimeToISO(
                moment(`${moment().utc().format('MM-DD-YYYY')} ${dataTime.timeEnd}`, 'MM-DD-YYYY hh:mm A')
            ),
            id: dataTime.id,
            jobInfo: dataTime.jobInfo
        });
    }

    const _handleClose = () => {
        dispatchState({
            isVisible: false
        });
    };

    const _handleChangeModeAny = () => {
        dispatchState((prev) => {
            const oldActive = prev.mode === MODES_TIME_WINDOW.ANY_TIME;
            return {
                ...prev,
                mode: oldActive ? MODES_TIME_WINDOW.CUSTOM : MODES_TIME_WINDOW.ANY_TIME,
                id: oldActive ? '' : '-1'
            };
        });
    };

    const _handleChangeCustom = () => {
        dispatchState({
            mode: MODES_TIME_WINDOW.CUSTOM,
            id: ''
        });
    };

    const _handleChangeTemplate = (newIdTemplate) => {
        dispatchState({
            mode: MODES_TIME_WINDOW.TEMPLATE,
            ...newIdTemplate
        });
    };

    const _handleSaveSuccess = () => {
        refButtonSave.current.removeLoading();
        dispatchState({
            isVisible: false
        });
    };

    const _handleSaveFailed = (response) => {
        refButtonSave.current.removeLoading();
        refStatusBar.current.showStatusBar('show_error', response?.message || 'Please try again', LIST_STATUS.ERROR);
    };

    const _handleApply = () => {
        if (finalMode === MODES_TIME_WINDOW.CUSTOM) {
            const { hour: hourStart, minute: minuteStart, midDay: midDayStart } = refTimePickerStart.current._getTime();
            const { hour: hourEnd, minute: minuteEnd, midDay: midDayEnd } = refTimePickerEnd.current._getTime();

            const numberStart = convertTimeToNumber(
                parseInt(hourStart) + (midDayStart === 'PM' && hourStart !== '12' ? 12 : 0),
                minuteStart
            );

            const numberEnd = convertTimeToNumber(
                parseInt(hourEnd) + (midDayEnd === 'PM' && hourEnd !== '12' ? 12 : 0),
                minuteEnd
            );

            if (numberStart >= numberEnd) {
                _handleSaveFailed({ message: 'The start time must be greater than the end time.' });
                return false;
            }
            onSubmit(
                {
                    id: '',
                    start: numberStart,
                    end: numberEnd
                },
                _handleSaveSuccess,
                _handleSaveFailed
            );
        } else {
            onSubmit(
                {
                    id: finalIdSelected,
                    start: convertTimeToNumber(momentStart.format('HH'), momentStart.format('mm')),
                    end: convertTimeToNumber(momentEnd.format('HH'), momentEnd.format('mm'))
                },
                _handleSaveSuccess,
                _handleSaveFailed
            );
        }
    };

    if (!isVisible) {
        return false;
    }

    const _renderAnyTime = () => {
        const { start, end } = companySetting?.business_hours || {};

        return (
            <div className="switch large">
                <span className="switch__label desc-time">
                    {t('any_time')}{' '}
                    {`(${moment(start, 'HH:mm').format('hh:mm A')} - ${moment(end, 'HH:mm').format('hh:mm A')})`}
                </span>
                <input
                    onChange={_handleChangeModeAny}
                    checked={isModeAny}
                    id="any_time"
                    className="toggle toggle-round js-active-anytime"
                    type="checkbox"
                />
                <label htmlFor="any_time" />
            </div>
        );
    };

    return (
        <ReactModal
            isOpen
            style={{ overlay: { background: 'transparent' } }}
            className={'modal container-modal wrapper-timewindow open'}
            onRequestClose={_handleClose}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />

            <div className="modal__container large">
                {!isAddJob && (
                    <div className="wrap-jobs-time">
                        <div className="wrap-jobs-time__desc">
                            <h3 className="title-h1 txt-ellipsis">{jobInfo.name}</h3>
                            <span className="desc-time ml-2">
                                {moment(jobInfo.start).utc().format('hh:mm A')} -{' '}
                                {moment(jobInfo.end).utc().format('hh:mm A')}
                            </span>
                        </div>
                        <div onClick={_handleClose} className="v2-btn-default --transparent --icon-lg">
                            <IconClose />
                        </div>
                    </div>
                )}
                <div className="header-modal">
                    <h3 className="header-modal__label">{t('add_a_timewindow')}</h3>
                    {isAddJob && (
                        <div onClick={_handleClose} className="v2-btn-default --transparent --icon-lg">
                            <IconClose />
                        </div>
                    )}
                </div>

                {!isModeAny && (
                    <div className="body-modal" id="timewindow_content">
                        <StatusBar ref={refStatusBar} />

                        <Templates
                            onChange={_handleChangeTemplate}
                            isActive={isModeTemplate}
                            selected={isModeTemplate ? finalIdSelected : ''}
                        />
                        <div className={`wrap-select-time box-selection --custom ${isModeCustom ? 'is-selected' : ''}`}>
                            <p className="box-selection__title">{t('assign_fixed_time')}</p>
                            <TimePicker
                                onChange={_handleChangeCustom}
                                ref={refTimePickerStart}
                                timeSelected={momentStart}
                                reset={finalIdSelected}
                            />
                            <span className="label-to">{t('to')}</span>
                            <TimePicker
                                onChange={_handleChangeCustom}
                                ref={refTimePickerEnd}
                                timeSelected={momentEnd}
                                reset={finalIdSelected}
                            />
                        </div>
                    </div>
                )}

                <div className="footer-modal">
                    {_renderAnyTime()}
                    <span onClick={_handleClose} className="v2-btn-default --transparent">
                        {t('cancel')}
                    </span>
                    <ButtonSave ref={refButtonSave} onSave={_handleApply} />
                </div>
            </div>
        </ReactModal>
    );
});

export default FormTimeWindow;
