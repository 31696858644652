import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import GDAttachments from 'app/components/attachments';

const MessageTextarea = forwardRef(({ customerId, onSubmit = () => {} }, ref) => {
    const { t } = useTranslation(['header']);
    const refTextarea = useRef(null);
    const refAttachments = useRef(null);

    useImperativeHandle(ref, () => ({
        focus: () => refTextarea.current.focus(),
        getCurrent: () => refTextarea.current,
        addAttachment: refAttachments.current.addAttachment,
        getAttachments: () => refAttachments.current.getAttachments(),
        getValueMessage: () => refTextarea.current.value,
        clearAttachments: () => refAttachments.current.clearAttachments(),
        setAttachments: refAttachments.current.setAttachments,
        setValue: (value) => (refTextarea.current.value = value)
    }));

    const _keyPress = (e) => {
        if (!e.shiftKey && (e.key === 'Enter' || e.charCode === 13 || e.keyCode === 13)) {
            onSubmit();
            e.preventDefault();
        }
    };

    return (
        <div className="wrap-show-message-chat">
            <div className="file-attachment scrolls-x">
                <div className="file-attachment-conts">
                    <GDAttachments ref={refAttachments} itemId={customerId} type="sms" />
                </div>
            </div>
            <textarea
                ref={refTextarea}
                type="text"
                name="text-box-chat"
                className="box-message field-textarea --no-resize --no-border p-0"
                placeholder={t('report:textarea_messages')}
                onKeyDown={_keyPress}
            />
        </div>
    );
});

MessageTextarea.propTypes = { customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) };

export default MessageTextarea;
