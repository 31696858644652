import { DEFAULT_OFFSET } from 'app/const/App';
import { TYPE_DOCUMENT_ACTIONS } from 'common/redux/actions/job/documents';

const documentsReducer = (state = {
    isFirstTime: true,
    offset: DEFAULT_OFFSET,
    keyword: '',
    show_more: false,
    data: []
}, action) => {
    switch (action.type) {
        case TYPE_DOCUMENT_ACTIONS.GET_LIST_DOCUMENT_TYPE_SUCCESS:
            const dataPayLoad = action.payload || {};

            return {
                ...state,
                isFirstTime: false,
                keyword: dataPayLoad.keyword,
                show_more: dataPayLoad.show_more,
                data: dataPayLoad.reset ? dataPayLoad.data || [] : [...state.data, ...(dataPayLoad.data || [])]
            };
        default:
            return state;
    }
};

export default documentsReducer;
