/* eslint-disable no-unused-vars */
import GdLazyImage from 'app/components/lazyimage';
import { RouteWithSubRoutes, RouteWithSubRoutesPrivate } from 'app/components/routes/RouteWithSubRoutes';
import { ACCESS_TOKEN, ACCOUNT_ROLE, STATUS_ACCOUNT } from 'app/const/App';
import { REDIRECT_MAP, SETTINGS_PLANS_REDIRECT_CODE } from 'app/const/Auth';
import { CALENDAR, HOME } from 'app/const/Route';
import { LINK_CDN_IMG } from 'app/const/URL';
import NotFoundPage from 'app/modules/notFoundPage';
import { AuthBanner } from 'app/modules/auth/components/AuthBanner';
import 'app/modules/auth/css/background-effect.scss';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { addBranchPath } from 'app/const/Branch';

const AuthenticatePages = ({ routes = [] }) => {
    const { t } = useTranslation(['auth', 'common']);
    const history = useHistory();
    const { profile } = useSelector((state) => state?.auth?.user || {});
    const redirectCode =
        profile?.plan?.status === STATUS_ACCOUNT.EXPIRED ? SETTINGS_PLANS_REDIRECT_CODE : profile?.redirect;

    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const userInfo = useSelector((state) => state.auth?.user?.profile);
    const calendar = useSelector(({ calendar }) => calendar);
    const role = userInfo?.role;
    const isTechnician = role === ACCOUNT_ROLE.TECHNICIAN;
    const isExpired = userInfo?.plan?.remaining_days <= 0;

    useEffect(() => {
        if (accessToken) {
            if ((isTechnician && isExpired) || !calendar.schedules.length) {
                history.push(HOME);
                return null;
            }
            return <Redirect to={REDIRECT_MAP[redirectCode] || CALENDAR} />;
        }

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const handleStorageChange = ({ key, newValue }) => {
        if (key === ACCESS_TOKEN && !!newValue) {
            window.location.href = addBranchPath(CALENDAR);
            return;
        }
    };

    return (
        <div className="gorilladesk auth-page">
            <div className="wrapper auth-container">
                <AuthBanner />
                <div className="main-login mt-10">
                    <Switch>
                        {routes.map((route, i) => (
                            <RouteWithSubRoutes key={i} {...route} />
                        ))}
                    </Switch>
                    <p className="mascot">
                        <GdLazyImage
                            src={`${LINK_CDN_IMG}half-gorilla.png`}
                            width={165}
                            height={150}
                            alt={t('common:gorilladesk')}
                        />
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AuthenticatePages;
