import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { LIST_PAGE_SIZE, PAGE_SIZE_DEFAULT } from 'app/const/App';
import IconDropDown from 'assets/icon/IconDropDown';
import useClickOutside from 'common/hooks/useClickOutside';

function GdPageSize({ pageSize = LIST_PAGE_SIZE, onChangePageSize = () => {}, currentPageSize = PAGE_SIZE_DEFAULT }) {
    const { t } = useTranslation(['common']);
    const [refSelect, isOpen, setIsOpen] = useClickOutside(false);

    const _renderListPageSize = () => {
        return pageSize.map((item) => {
            return (
                <li
                    key={item}
                    onClick={(e) => {
                        handleChangePageSize(e, parseInt(item));
                    }}
                >
                    <div className={classNames('items', { active: item === currentPageSize })}>
                        <p className="txt-ellipsis">
                            {item} {t('common:per_page')}
                        </p>
                    </div>
                </li>
            );
        });
    };

    const handleChangePageSize = (e, value) => {
        e.preventDefault();
        onChangePageSize(value);
        setIsOpen(!isOpen);
    };

    const handleOnClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    return (
        <div ref={refSelect} className={`v2-dropup ${isOpen && 'active'}`} onClick={handleOnClick}>
            <div className="dropbtn v2-btn-default selection justify-start">
                {currentPageSize} {t('common:per_page')}
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </div>
            <div className="v2-dropdown__menu scrolls">
                <ul>{_renderListPageSize()}</ul>
            </div>
        </div>
    );
}

export default GdPageSize;
