import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GdSearch from 'app/components/search/GdSearch';
import { reducer } from 'app/const/Reducer';
import IconPlus from 'assets/icon/IconPlus';
import DropdownPopper from 'app/components/dropdown/DropdownPopper';

const DropdownAssignedUsers = ({ item = {}, companyUsers = [] }) => {
    const { id, users, onAssign = () => {} } = item;
    const userOwnerId = useSelector(({ auth }) => auth.user.company.user_id);
    const { t } = useTranslation();
    const isFirstTime = useRef(true);
    const isChanged = useRef(false);
    const refDropdown = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        keyword: '',
        selectedUsers: users
    });

    const { keyword, selectedUsers } = state;

    const _handleChange = (e, value) => {
        e.preventDefault();
        e.stopPropagation();
        if (!isChanged.current) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    selectedUsers: users
                };
            });
            isChanged.current = true;
        }
        const newUsers = [...selectedUsers];
        dispatchState((prev) => {
            return {
                ...prev,
                selectedUsers: !newUsers.includes(value) ? [...newUsers, value] : newUsers.filter((id) => id !== value)
            };
        });
    };

    const _handleSearch = (keyword) => {
        dispatchState({ keyword });
    };

    const _handleOnHide = () => {
        !isFirstTime.current && isChanged.current && onAssign({ branchID: id, usersID: selectedUsers });
        dispatchState({ keyword: '' });
        isChanged.current = false;
    };

    const _handleVisible = () => {
        if (isFirstTime.current) isFirstTime.current = false;
    };

    const _renderListUser = () => {
        const listUser = companyUsers.filter((user) =>
            user.full_name
                .replace(/\s{2,}/g, ' ')
                .toLowerCase()
                .includes(keyword.replace(/\s{2,}/g, ' ').toLowerCase())
        );
        return !!listUser.length ? (
            listUser.map((user) => {
                const { id: userId, full_name, avatar } = user;
                const labelId = `${id}_${userId}`;

                if (userOwnerId === userId * 1) return null;
                return (
                    <li key={userId} className="items" onClick={(e) => _handleChange(e, userId * 1)}>
                        <div className="check-items w-100">
                            <input
                                id={labelId}
                                type="checkbox"
                                value={userId * 1}
                                onChange={(e) => e.preventDefault()}
                                checked={selectedUsers.includes(userId * 1)}
                            />
                            <div className="item-checkbox">
                                <label htmlFor={labelId}>
                                    <div className="avt-img">
                                        <img src={avatar} width={24} height={24} />
                                    </div>
                                    <span className="txt-ellipsis">{full_name}</span>
                                </label>
                            </div>
                        </div>
                    </li>
                );
            })
        ) : (
            <li key={'empty'} className="items pointer-events-none">
                <div className="w-100">
                    {keyword
                        ? t('header:search_not_match')
                        : t('common:no_data_to_display', { title: t('common:users') })}
                </div>
            </li>
        );
    };

    return (
        <DropdownPopper
            ref={refDropdown}
            isUseToggle
            wrapperClassName="v2-dropdown search-member d-flex"
            wrapperListClass="v2-dropdown__menu content-search"
            buttonClassName="dropbtn v2-btn-default --icon-sm"
            customButton={<IconPlus />}
            onOpen={_handleVisible}
            onClose={_handleOnHide}
        >
            <div className="container-column">
                <div className="text-uppercase grey-generic border-bottom-border-color-grey p-2 fs-12">
                    {t('addons:manage_activated_users')}
                </div>
                <div className="flex-auto header-search">
                    <GdSearch isSearchOnClear placeholder={t('search')} onSearch={_handleSearch} />
                </div>
                <ul className="box-auto">{_renderListUser()}</ul>
            </div>
        </DropdownPopper>
    );
};

export default DropdownAssignedUsers;
