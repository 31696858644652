import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const CustomSaveButton = ({
    isUploading = false,
    showCancel = false,
    showSave = true,
    onCancel = () => {},
    onSubmit = () => {},
    classBtnSave = ''
}) => {
    const { t } = useTranslation();

    const refButtonSave = useRef(null);
    const refButtonCancel = useRef(null);

    const _onBlurButtonSave = () => {
        refButtonCancel.current && refButtonCancel.current.focus();
        refButtonSave.current.tabIndex = '-1';
    };

    return (
        <Fragment>
            {showCancel && (
                <div className="v2-btn-default --transparent" onClick={onCancel} tabIndex="-1" ref={refButtonCancel}>
                    {t('cancel')}
                </div>
            )}
            {showSave && (
                <div
                    className={classNames('v2-btn-main', classBtnSave, { 'is-disable': isUploading })}
                    onClick={onSubmit}
                    tabIndex="0"
                    ref={refButtonSave}
                    onBlur={_onBlurButtonSave}
                    id="rich-text-btn-save"
                >
                    {t('save')}
                </div>
            )}
        </Fragment>
    );
};

export default CustomSaveButton;
