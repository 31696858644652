export const TYPE_STATEMENT = {
	GET_STATEMENT_SUMMARY: "GET_STATEMENT_SUMMARY",
	GET_STATEMENT_HISTORIES: "GET_STATEMENT_HISTORIES",
	STATEMENT_TO_PDF: "STATEMENT_TO_PDF",
	STATEMENT_SEND_BY_EMAIL: "STATEMENT_SEND_BY_EMAIL"
}

export const getStatementSummary = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
	return {
		type: TYPE_STATEMENT.GET_STATEMENT_SUMMARY,
		params,
		actionSuccess,
		actionFailed
	}
}

export const getStatementHistories = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
	return {
		type: TYPE_STATEMENT.GET_STATEMENT_HISTORIES,
		params,
		actionSuccess,
		actionFailed
	}
}
export const statementToPDF = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
	return {
		type: TYPE_STATEMENT.STATEMENT_TO_PDF,
		params,
		actionSuccess,
		actionFailed
	}
}

export const statementSendByEmail = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
	return {
		type: TYPE_STATEMENT.STATEMENT_SEND_BY_EMAIL,
		params,
		actionSuccess,
		actionFailed
	}
}
