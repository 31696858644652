import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import DropdownPopper from './DropdownPopper';
import { getListStaffs } from 'common/utils/FunctionUtils';

const DropdownUsers = forwardRef(
    (
        {
            defaultUser = {},
            placeholder = 'None',
            modifiers = [],
            onVisible = () => {},
            isHasCrews = false,
            dropdownProps = {
                isUseToggle: true,
                wrapperClassName: 'v2-dropdown',
                buttonClassName: 'dropbtn items',
                wrapperListClass: 'v2-dropdown__menu content-user scrolls'
            }
        },
        ref
    ) => {
        const { users: companyUsers, crew } = useSelector(({ companyUsers }) => companyUsers);
        const users = getListStaffs({ users: companyUsers, crew, isHasCrews });
        const [state, dispatchState] = useReducer(reducer, {
            isLoading: true,
            selected: defaultUser.id ? users?.find((user) => +user.id === +defaultUser.id) || defaultUser : null
        });
        const { selected } = state;
        const refDropdown = useRef(null);

        useImperativeHandle(ref, () => ({ getValue: () => selected }));

        useEffect(() => {
            // eslint-disable-next-line no-prototype-builtins
            if (!!defaultUser.hasOwnProperty('id') && defaultUser.id !== selected?.id) {
                dispatchState((prevState) => ({
                    ...prevState,
                    selected: users?.find((user) => +user.id === +defaultUser.id) || defaultUser || null
                }));
            }
        }, [defaultUser]);

        const _handleVisible = () => {
            onVisible();
        };

        const _handleSelect = (user) => {
            refDropdown.current?._close();
            dispatchState((prevState) => ({ ...prevState, selected: user }));
        };

        return (
            <DropdownPopper
                ref={refDropdown}
                {...dropdownProps}
                isCalculateWidth
                modifiers={modifiers}
                onOpen={_handleVisible}
                customButton={
                    <>
                        {selected ? (
                            <div className="avt-img">
                                <img src={selected?.avatar} width="24" height="24" />
                            </div>
                        ) : null}
                        <span className="txt-ellipsis ml-1">{selected?.full_name || placeholder}</span>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </>
                }
            >
                <ul>
                    {defaultUser?.is_deleted ? (
                        <ItemUser
                            {...defaultUser}
                            isActive={defaultUser?.id === selected?.id}
                            onSelect={() => _handleSelect(defaultUser)}
                        />
                    ) : null}
                    {users.map((user) => (
                        <ItemUser
                            key={user.id}
                            {...user}
                            isActive={user?.id === selected?.id}
                            onSelect={() => _handleSelect(user)}
                        />
                    ))}
                </ul>
            </DropdownPopper>
        );
    }
);

const ItemUser = ({ full_name, avatar, isActive = false, onSelect = () => {} }) => {
    return (
        <li className={classNames('items', { active: isActive })} onClick={onSelect}>
            <div className="user-name">
                <div className="avt-img">
                    <img src={avatar} width="24" height="24" />
                </div>
                <span className="txt-ellipsis">{full_name}</span>
            </div>
        </li>
    );
};

export default DropdownUsers;
