import React, { useReducer, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { CUSTOMERS_WO_ACTIVE_JOBS_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { URL_REPORT_CUSTOMERS_WO_ACTIVE_JOB } from 'app/const/api/Export';

import { getGridColumns } from 'app/const/report/CustomersWoActiveJobs';
import { getListReportCustomersWoActiveJobs } from 'common/redux/actions/reports/customersWoActiveJobsAction';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { LIST_EXPORT } from 'app/const/report/Common';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';

const Export = loadable(() => import('app/modules/report/components/Export'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));

function ReportCustomersWoActiveJobs() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const refStatusBar = useRef(null);
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        isLoading: true,
        total: 0,
        refreshScreen: 0
    });
    const { total, isLoading, refreshScreen } = dataReport;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS);

    useEffect(() => {
        getListReport(paramsReport);
    }, []);

    const getListReport = (params) => {
        params.service = params.service_type_single;
        delete params?.service_type_single;
        delete params?.currentPage;
        delete params?.columns;

        dispatch(
            getListReportCustomersWoActiveJobs(params, (response) => getListSuccess(response, params), getListFailed)
        );
    };

    const getListSuccess = (response) => {
        const dataReducer = {};

        dataReducer.isLoading = false;
        dataReducer.data = response.data;
        dataReducer.total = dataReducer.data.length;
        dataReducer.refreshScreen = refreshScreen + 1;

        dispatchActionReport(dataReducer);
    };

    function getListFailed() {
        refStatusBar.current.showStatusBar(LIST_STATUS.ERROR, t('common:action_failure'), LIST_STATUS.ERROR);
        dispatchActionReport({ isLoading: false, total: 0 });
    }

    const _handleUpdate = () => {
        dispatchActionReport({ isLoading: true, total: 0 });
        getListReport(getLocalStorage(keyLocal));
    };
    const _handleChangeFilter = (params, mode) => {
        if (mode === 'sort_by') {
            dispatchActionReport({ isLoading: true, total: 0 });
            getListReport(params);
        }
    };

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: total })}
                    activePrint
                    params={paramsReport}
                    url={URL_REPORT_CUSTOMERS_WO_ACTIVE_JOB}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_CUSTOMER_WO_ACTIVE}
                    isDisable={isLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages">
                    <StatusBar ref={refStatusBar} />

                    <div className="wrap-tables flex-column relative">
                        <HeaderBottom
                            isLoading={isLoading}
                            typeReport={REPORT_TYPE.CUSTOMERS_WO_ACTIVE_JOBS}
                            filters={CUSTOMERS_WO_ACTIVE_JOBS_LIST_FILTER}
                            handleChangeFilter={_handleChangeFilter}
                            handleUpdate={_handleUpdate}
                            classNameHeader="header --filter"
                        />
                        <GdGridView
                            isEmptyFlat
                            isLoading={isLoading}
                            classTable="scrolls-x table-multi-column has-text-ellipsis"
                            classTableContent=""
                            content={dataReport.data}
                            fileTranslation={'report'}
                            handleClick={null}
                            {...getGridColumns(paramsReport?.order)}
                            handleClickHeader={_handleActionHeader}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportCustomersWoActiveJobs;
