import { useEffect, useRef } from 'react';
import StatusBar from 'app/components/status/statusbar';
import Header from './Header';
import classNames from 'classnames';
import Footer from './Footer';
import { forwardRef, useImperativeHandle, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';
import { useParams } from 'react-router-dom';
import Loading from './Loading';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADD_TEMPLATE_NOTE, updateTemplateNote } from 'app/const/api/Notes';
import { LIST_STATUS } from 'app/const/Status';
import { useTranslation } from 'react-i18next';
import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import GdRichText from 'app/components/richtext';
import { TITLE_NOTES_TEMPLATE } from 'app/const/Notes';

const Detail = ({ onAddNew = () => {}, onUpdate = () => {} }, ref) => {
    const { id } = useParams();
    const { t } = useTranslation(['setting']);
    const [state, dispatchState] = useReducer(reducer, {
        name: '',
        content: '',
        id: null,
        type: -1,
        isLoading: false,
        isOpen: false,
        isEdit: false
    });

    const {
        id: finalId,
        type: finalType,
        name: finalName,
        content: finalContent,
        isLoading: finalIsLoading,
        isOpen: finalIsOpen,
        isEdit: finalIsEdit
    } = state;

    const refStatusBar = useRef(null);
    const refHeader = useRef(null);
    const refFooter = useRef(null);
    const refRichText = useRef(null);

    useImperativeHandle(ref, () => ({
        _getDetail,
        _addNew: _openNewForm,
        _showStatusBar
    }));

    useEffect(() => {
        !finalIsLoading && dispatchState({ isLoading: true });
    }, [id]);

    useEffect(() => {
        if (!finalIsLoading && finalId) {
            refHeader.current._setInput(finalName);
            refHeader.current._autoFocus();
            _setDisableSave(true);
            refRichText.current.setValue(finalContent);
        }
    }, [finalId]);

    const _getDetail = (item) => {
        if (!item) {
            dispatchState({ isLoading: false, isOpen: false });
            return;
        }
        dispatchState({ ...item, isLoading: false, isOpen: true, isEdit: true });
    };

    const _openNewForm = (type) => {
        dispatchState({
            isOpen: true,
            name: '',
            content: '',
            id: null,
            type: type,
            isEdit: false
        });
        refHeader.current._setInput('');
        refHeader.current._autoFocus();
        _setDisableSave(true);
        refRichText.current.setValue('');
    };

    const _close = () => {
        dispatchState({
            isOpen: false
        });
    };

    const _changeName = (e) => {
        _setDisableSave(!!!e.target.value);
    };

    const _updateNote = (content) => {
        const _success = ({ data }) => {
            onUpdate(data);
            refFooter.current._removeLoadingSave();
            _showStatusBar('success', t('changes_saved_successfully'), LIST_STATUS.SUCCESS);
        };

        const _fail = ({ message }) => {
            refFooter.current._removeLoadingSave();
            _showStatusBar('error', message, LIST_STATUS.ERROR);
        };

        clientQuery(
            updateTemplateNote(finalId),
            {
                method: 'PUT',
                data: {
                    name: refHeader.current._getValueInput(),
                    content,
                    type: finalType
                }
            },
            _success,
            _fail
        );
    };

    const _addNote = (content) => {
        const _success = ({ data }) => {
            refFooter.current._removeLoadingSave();
            onAddNew(data);
            _showStatusBar('success', t('created_note_success'), LIST_STATUS.SUCCESS);
            _openNewForm(finalType);
        };

        const _fail = ({ message }) => {
            refFooter.current._removeLoadingSave();
            _showStatusBar('error', message, LIST_STATUS.ERROR);
        };

        clientQuery(
            ADD_TEMPLATE_NOTE,
            {
                method: 'POST',
                data: {
                    name: refHeader.current._getValueInput(),
                    content,
                    type: finalType
                }
            },
            _success,
            _fail
        );
    };

    const _saveNote = () => {
        let content = refRichText.current.getValue();
        content = content === '<br/>' ? '' : content;
        finalIsEdit ? _updateNote(content) : _addNote(content);
    };

    const _setDisableSave = (disable = false) => {
        refFooter.current._setDisableSave(disable);
    };

    const _checkDisableSave = (text) => {
        finalContent !== text && _setDisableSave(false);
    };

    const _showStatusBar = (id, message, status) => {
        refStatusBar.current?.showStatusBar(id, message, status);
    };

    if (finalIsLoading) return <Loading />;

    return (
        <div className="wrap-note-template flex-column flex-1">
            <StatusBar ref={refStatusBar} />
            <div className={classNames('wrap-note-template__content flex-column', { 'is-show': finalIsOpen })}>
                <Header
                    ref={refHeader}
                    type={finalType}
                    defaultValue={finalName}
                    onChangeName={_changeName}
                    isEdit={finalIsEdit}
                    titleTemplate={TITLE_NOTES_TEMPLATE}
                />
                <div className="template-content flex-column flex-1">
                    <GdRichText
                        ref={refRichText}
                        toolbarConfig={{
                            left: [
                                {
                                    group: 'style',
                                    children: [
                                        { type: TYPE_FUNCTION_RICHTEXT.EMOJI },
                                        { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                                        { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                                        { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                                        { type: TYPE_FUNCTION_RICHTEXT.LINK },
                                        { type: TYPE_FUNCTION_RICHTEXT.UL },
                                        { type: TYPE_FUNCTION_RICHTEXT.OL }
                                    ]
                                }
                            ]
                        }}
                        defaultContent={finalContent}
                        onChange={_checkDisableSave}
                    />
                    <Footer ref={refFooter} onClose={_close} isEdit={finalIsEdit} onSave={_saveNote} />
                </div>
            </div>
        </div>
    );
};
export default forwardRef(Detail);
