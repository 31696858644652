import React from 'react';

const JobMove = () => {
    return (
        <div
            id={'work_pool_job_move'}
            style={{ display: 'none' }}
            className="fc-event jobslist active-joblist completed has-drag"
        ></div>
    );
};

export default JobMove;
