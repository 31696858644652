import React from 'react';

const StripeAddonsLoading = () => {
    return (
        <>
            <div className="wrap-loading --addon">
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --transparent --icon-lg mr-0" />
                            <div className="title">
                                <div className="loading --animation --line --half" />
                            </div>
                        </div>
                        <div className="loading --onefifth switch ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents mt-3">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --ninety" />
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="desc-connect v2-btn-default --grey loading btn-md">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="wrap-loading --addon">
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --ninety" />
                        </div>
                    </div>
                    <div className="boxs__footer --multi loading">
                        <div className="grey-extrlight flex-1 pr-7">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default --transparent loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                        <div className="description mt-2">
                            <div className="loading --animation --line --threefourth" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2">
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="title loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --ninety" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export const StripeSubscriptionLoading = () => (
    <div className="wrap-loading --addon mt-2">
        <div className="boxs loading">
            <div className="boxs__header">
                <div className="loading --animation --line --twothird" />
            </div>
            <div className="boxs__contents mt-5">
                <div className="description --hasline --line-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="rows flex-betweentop py-2">
                    <div className="txt loading --threefourth">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows flex-betweentop py-2">
                    <div className="txt loading --threefourth">
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows flex-betweentop py-2">
                    <div className="txt loading --threefourth">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows flex-betweentop py-2">
                    <div className="txt loading --threefourth">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="rows flex-betweentop py-2">
                    <div className="txt loading --threefourth">
                        <div className="loading --animation --line --fourth" />
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default StripeAddonsLoading;
