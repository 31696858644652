import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useClickOutside from 'common/hooks/useClickOutside';
import { smsGetListVariable } from 'common/redux/actions/sms/smsAction';
import IconInsertVariable from 'assets/icon/IconInsertVariable';
import SearchOption from 'app/modules/report/filter/SearchOption';

function MessageListTags({ onAddTags = () => {}, isUnknow }) {
    const [refOutside, isVisible, setIsVisible] = useClickOutside(false);
    const { list_variables: tags = [], fetched: finalFetchedVariable } = useSelector(({ sms }) => sms);

    const dispatch = useDispatch();

    useEffect(() => {
        !finalFetchedVariable && dispatch(smsGetListVariable());
    }, [dispatch]);

    const _toggleActive = () => setIsVisible(!isVisible);

    const _addTagToInput = useCallback(
        (tag) => {
            onAddTags(tag);
            setIsVisible(false);
        },
        [onAddTags, setIsVisible]
    );

    return (
        <div ref={refOutside} className={`v2-dropup dropdown-taglabel ${isVisible && 'active'}`}>
            <div
                onClick={_toggleActive}
                className={classNames('dropbtn btn-taglabel', {
                    active: isVisible
                })}
            >
                <IconInsertVariable />
            </div>
            <ListTags isUnknow={isUnknow} tags={tags} onAddTags={_addTagToInput} />
        </div>
    );
}

const ListTags = React.memo(({ tags = [], onAddTags = () => {}, isUnknow }) => {
    const { t } = useTranslation(['header']);
    const [dataTags, setDataTags] = useState(tags);
    const refData = useRef([]);

    useEffect(() => {
        setDataTags(tags);
        refData.current = tags;
    }, [tags]);

    const _searchTags = (keyword) => {
        const newTags = refData.current.filter((el) => {
            const searchValue = el.name.toLowerCase();
            return searchValue.indexOf(keyword) !== -1;
        });
        setDataTags(newTags);
    };

    const _selectTag = (e, tag) => {
        e.stopPropagation();
        onAddTags(tag);
    };

    const variableRemove = ['[::company_email::]', '[::company_name::]', '[::company_phone::]'];

    function _renderListTags() {
        if (!dataTags.length) {
            return (
                <li className="items pointer-events-none">
                    <div className="txt-ellipsis">{t('search_not_match')}</div>
                </li>
            );
        }
        return (
            <>
                <div className="title-section">{t('header:info')}</div>
                {dataTags.map((item, index) => {
                    const { value } = item;

                    if (isUnknow && !variableRemove.includes(value)) {
                        return null;
                    }
                    return (
                        <li key={item?.id || index.toString()} className="items" onClick={(e) => _selectTag(e, item)}>
                            <div className="txt-ellipsis">{item.name}</div>
                        </li>
                    );
                })}
            </>
        );
    }

    return (
        <div className="v2-dropdown__menu content-search">
            <div className="container-column">
                <div className="flex-auto header-search">
                    <SearchOption
                        placeholder={t('report:search')}
                        onSearch={_searchTags}
                        defaultValue={''}
                        parentStyle={`search-form relative`}
                        isSearchIcon
                    />
                </div>
                <ul className="box-auto scrolls">{_renderListTags()}</ul>
            </div>
        </div>
    );
});

export default MessageListTags;
