export const TYPE_ACTION = {
    ACTION_ADD_NEW_BOOK_MARK: "ACTION_ADD_NEW_BOOK_MARK",
    ACTION_REMOVE_BOOK_MARK: "ACTION_REMOVE_BOOK_MARK",
    ACTION_REMOVE_ALL_BOOK_MARK: "ACTION_REMOVE_ALL_BOOK_MARK",
    SET_ALL_BOOK_MARK: "SET_ALL_BOOK_MARK",
};

export const addNewBookMarkAction = (params) => {
    return {
        type: TYPE_ACTION.ACTION_ADD_NEW_BOOK_MARK,
        params,
    };
};

export const removeBookMarkAction = (params) => {
    return {
        type: TYPE_ACTION.ACTION_REMOVE_BOOK_MARK,
        params,
    };
};

export const removeAllBookMarkAction = (params) => {
    return {
        type: TYPE_ACTION.ACTION_REMOVE_ALL_BOOK_MARK,
        params,
    };
};

export const setAllBookMarkAction = (params) => {
    return {
        type: TYPE_ACTION.SET_ALL_BOOK_MARK,
        params,
    };
};


