import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import { REPORT_MATERIAL_USE_DETAIL_GET_LIST } from 'app/const/Api';

export function* getListReportMaterialUseDetail({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_MATERIAL_USE_DETAIL_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
