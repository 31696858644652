import React from 'react';

const Loading = () => {
    return (
        <>
            <div className="wrap-loading --addon" style={{}}>
                <div className="boxs boxs--no-border --hasline">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="v2-btn-default loading --animation --light --transparent --icon-lg mr-0" />
                            <div className="title flex-column gap-4">
                                <div className="loading --animation --light --line --half" />
                                <div className="v2-btn-default loading --animation --light --transparent field-h16 btn-x-sm" />
                            </div>
                        </div>
                        <div className="loading --onefifth switch ml-0">
                            <div className="loading --animation --light --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents mt-3 mb-0">
                        <div className="description loading-lines">
                            <div className="loading --animation --light --line --half" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon" style={{}}>
                <div className="boxs boxs-has-footer loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --twothird" />
                        </div>
                    </div>
                    <div className="boxs__footer --multi loading">
                        <div className="loading" />
                        <div className="v2-btn-default --transparent loading btn-sm">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrap-loading --addon mt-2" style={{}}>
                <div className="boxs boxs--power-up loading">
                    <div className="boxs__header">
                        <div className="header-title">
                            <div className="loading --onefifth">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="dots powerups loading --twothird">
                                <div className="loading --animation --line --full" />
                            </div>
                        </div>
                        <div className="switch loading --onefifth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                    <div className="boxs__contents">
                        <div className="description loading-lines">
                            <div className="loading --animation --line --full" />
                            <div className="loading --animation --line --onefourth" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export const LoadingManager = () => (
    <div className="wrapper-opp-pipelines__content">
        <div className="boxs wrap-loading ">
            <div className="boxs__header flex-column gap-4">
                <div className="loading --animation --line --half" />
                <div className="loading --animation --line --full mt-3" />
                <div className="loading --animation --line --twothird" />
            </div>
            <div className="boxs__search flexcenter gap-8 bg-white">
                <div className="v2-btn-default loading --grey flex-1" />
                <div className="v2-btn-default loading --grey btn-sm">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --grey btn-x-sm">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
            <div className="boxs__contents">
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
                <div className="rows align-center pointer-events-none">
                    <div className="v2-btn-default loading bg-white-cultured --icon-sm status-btn mt-0 mx-1" />
                    <div className="flex-1 flexcenter">
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="v2-btn-default loading --grey btn-x-sm field-h24 status-btn mt-0">
                        <div className="loading --animation --line --full" />
                    </div>
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                    <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
                </div>
            </div>
        </div>
    </div>
);

export const LoadingModalDelete = () => {
    return (
        <div className="modal__container large wrap-loading">
            <div className="header-modal">
                <div className="loading --animation --line --half" />
                <div className="v2-btn-default loading bg-white-cultured --icon-lg" />
            </div>
            <div className="body-modal">
                <div className="rows --alert">
                    <div className="loading --animation --line --half" />
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="rows">
                    <div className="loading --animation --line --twothird" />
                    <div className="v2-btn-default loading --grey">
                        <div className="loading --animation --line --onefourth" />
                    </div>
                </div>
            </div>
            <div className="footer-modal footer-hasbtn">
                <div className="v2-btn-default loading --transparent btn-x-sm">
                    <div className="loading --animation --line --full" />
                </div>
                <div className="v2-btn-default loading --grey btn-x-sm ml-1">
                    <div className="loading --animation --line --full" />
                </div>
            </div>
        </div>
    );
};

export default Loading;
