import React, { Fragment, useContext, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useStripe } from '@stripe/react-stripe-js';

import ButtonSave from 'app/components/button/ButtonSave';
import GDStatusBar from 'app/components/status/statusbar';
import { LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_ACCOUNT_TYPE } from 'app/const/Settings';
import { addBankAccount } from 'app/const/api/V2';
import IconClose from 'assets/icon/IconClose';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import FieldDropdown from './FieldDropdown';
import FieldInput from './FieldInput';
import { CustomerDetailContext } from '../../../context/CustomerDetailContext';
import { PAYMENT_MERCHANT, PAYMENT_MERCHANT_KEYWORD } from 'app/const/customer/CustomerPayment';
import { getValueElements } from 'common/utils/ObjectUtils';

const ButtonAddBankACH = ({ onAddSuccess = () => {} }) => {
    const { t } = useTranslation('customers');
    const refSave = useRef(null);
    const refForm = useRef(null);
    const refAlert = useRef(null);
    const stripe = useStripe();

    const { customer } = useContext(CustomerDetailContext);
    const addons = useSelector(({ auth }) => auth.user.settings.addons);
    const isHaveACH = !!addons.stripe?.ach;

    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false
    });

    const { isVisible: visibleModal } = state;

    const _handleVisibleModal = (value = false) => {
        dispatchState({ isVisible: value });
    };

    const _isOnlyNumber = (string) => {
        return /^[0-9]*$/.test(string);
    };

    const _handleSave = () => {
        refAlert.current?.clearAllStatusBar();
        const dataValue = { merchant: PAYMENT_MERCHANT.STRIPE_GATE_WAY, ...getValueElements(refForm.current.elements) };
        const { account_number, routing_number, confirm_number } = dataValue;
        const msgError = [];
        Object.entries(dataValue).forEach(([key, value]) => {
            if (!value) msgError.push(t('cannot_be_blank', { name: t(key) }));
            if (key === 'account_number' && (!_isOnlyNumber(account_number) || !account_number))
                msgError.push(t('account_number_must_be_at_most_17_digits'));
            if (key === 'routing_number' && (!_isOnlyNumber(routing_number) || routing_number.length !== 9))
                msgError.push(t('routing_number_must_have_nine_digits'));
        });
        if (!!account_number && !!confirm_number && account_number !== confirm_number)
            msgError.push(t('confirm_number_doesnt_match'));
        if (!!msgError.length) {
            _handleShowAlert({ message: msgError, type: LIST_STATUS.ERROR });
            return;
        }

        delete dataValue.confirm_number;

        const _handleSuccess = async ({ data }) => {
            const { meta, account_id } = data;
            const { error } = await stripe.confirmUsBankAccountSetup(meta.client_secret, {
                payment_method: account_id
            });

            if (error) {
                _handleShowAlert({ message: error.message });
                return;
            }
            onAddSuccess({ ...data, name: PAYMENT_MERCHANT_KEYWORD.STRIPE, isACH: true });
            _handleVisibleModal(false);
        };

        const _handleFail = ({ message }) => {
            _handleShowAlert({ message });
        };

        clientQuery(
            addBankAccount(customer.id),
            { method: 'POST', data: dataValue, toFormData: false },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleShowAlert = ({ message, type = LIST_STATUS.ERROR }) => {
        refAlert.current?.handeAddStatus({ id: 'modal_add_ach', message, type });
        refSave.current?.removeLoading();
    };

    return (
        <Fragment>
            <div
                className={classNames('v2-btn-main has-icon --bg-green svg-white btn-modal fs-14', {
                    'is-disable': !isHaveACH
                })}
                onClick={() => _handleVisibleModal(true)}
            >
                <IconPlus />
                {t('bank_account')}
            </div>
            <ReactModal isOpen={visibleModal} className="modal container-modal modal-add-bank open">
                <div className="modal__overlay bg-fixed" onClick={() => _handleVisibleModal(false)} />
                <div className="modal__container --lg">
                    <div className="header-modal btn-close">
                        <h3 className="header-modal__label pr-1">{t('add_bank_account')}</h3>
                        <span
                            className="v2-btn-default --icon-lg --transparent"
                            onClick={() => _handleVisibleModal(false)}
                        >
                            <IconClose />
                        </span>
                    </div>
                    <form ref={refForm} className="body-modal has-form flex-column gap-8 scrolls">
                        <GDStatusBar ref={refAlert} />
                        <FieldInput label="name_on_account" name="account_name" />
                        <FieldDropdown label="account_type" name="account_type" options={LIST_ACCOUNT_TYPE} />
                        <FieldInput label="routing_number" name="routing_number" maxLength={9} />
                        <FieldInput label="account_number" name="account_number" maxLength={17} />
                        <FieldInput label="confirm_number" name="confirm_number" maxLength={17} />
                    </form>
                    <div className="footer-modal btn-close">
                        <span className="v2-btn-default --transparent" onClick={() => _handleVisibleModal(false)}>
                            {t('cancel')}
                        </span>
                        <ButtonSave ref={refSave} onSave={_handleSave} />
                    </div>
                </div>
            </ReactModal>
        </Fragment>
    );
};

export default ButtonAddBankACH;
