export default function IconBuilding({ isAddons = false, isHasColor = false, isAddonsGreen, isAddonsGrey }) {
    if (isAddonsGreen)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 10C16.1046 10 17 10.8954 17 12V23C17 23.5523 16.5523 24 16 24H8C7.44772 24 7 23.5523 7 23V12C7 10.8954 7.89543 10 9 10H15ZM10 20C9.44772 20 9 20.4477 9 21C9 21.5523 9.44772 22 10 22C10.5523 22 11 21.5523 11 21C11 20.4477 10.5523 20 10 20ZM14 20C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20ZM10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16ZM14 16C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17C15 16.4477 14.5523 16 14 16ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12ZM14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12Z"
                    fill="#C0E5CF"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 14C23.1046 14 24 14.8954 24 16V23C24 23.5523 23.5523 24 23 24H19C18.4477 24 18 23.5523 18 23V15C18 14.4477 18.4477 14 19 14H22ZM21 20C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20ZM21 16C20.4477 16 20 16.4477 20 17C20 17.5523 20.4477 18 21 18C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    if (isAddonsGrey)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 10C16.1046 10 17 10.8954 17 12V23C17 23.5523 16.5523 24 16 24H8C7.44772 24 7 23.5523 7 23V12C7 10.8954 7.89543 10 9 10H15ZM10 20C9.44772 20 9 20.4477 9 21C9 21.5523 9.44772 22 10 22C10.5523 22 11 21.5523 11 21C11 20.4477 10.5523 20 10 20ZM14 20C13.4477 20 13 20.4477 13 21C13 21.5523 13.4477 22 14 22C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20ZM10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16ZM14 16C13.4477 16 13 16.4477 13 17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17C15 16.4477 14.5523 16 14 16ZM10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12ZM14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 14C23.1046 14 24 14.8954 24 16V23C24 23.5523 23.5523 24 23 24H19C18.4477 24 18 23.5523 18 23V15C18 14.4477 18.4477 14 19 14H22ZM21 20C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20ZM21 16C20.4477 16 20 16.4477 20 17C20 17.5523 20.4477 18 21 18C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16Z"
                    fill="#4D5054"
                />
            </svg>
        );
    if (isAddons)
        return (
            <svg width={32} height={32} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 5C12.1046 5 13 5.89543 13 7V18C13 18.5523 12.5523 19 12 19H4C3.44772 19 3 18.5523 3 18V7C3 5.89543 3.89543 5 5 5H11ZM6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17C6.55228 17 7 16.5523 7 16C7 15.4477 6.55228 15 6 15ZM10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7Z"
                    fill="#dac2f2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 9C19.1046 9 20 9.89543 20 11V18C20 18.5523 19.5523 19 19 19H15C14.4477 19 14 18.5523 14 18V10C14 9.44772 14.4477 9 15 9H18ZM17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15ZM17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11Z"
                    fill="#8d4afc"
                />
            </svg>
        );
    if (isHasColor)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 5C12.1046 5 13 5.89543 13 7V18C13 18.5523 12.5523 19 12 19H4C3.44772 19 3 18.5523 3 18V7C3 5.89543 3.89543 5 5 5H11ZM6 15C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17C6.55228 17 7 16.5523 7 16C7 15.4477 6.55228 15 6 15ZM10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11ZM10 11C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18 9C19.1046 9 20 9.89543 20 11V18C20 18.5523 19.5523 19 19 19H15C14.4477 19 14 18.5523 14 18V10C14 9.44772 14.4477 9 15 9H18ZM17 15C16.4477 15 16 15.4477 16 16C16 16.5523 16.4477 17 17 17C17.5523 17 18 16.5523 18 16C18 15.4477 17.5523 15 17 15ZM17 11C16.4477 11 16 11.4477 16 12C16 12.5523 16.4477 13 17 13C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.1 10.5H16.5C17.6045 10.5 18.5 11.3954 18.5 12.5V17.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.1 6.5H11.5C12.6046 6.5 13.5 7.39543 13.5 8.5V18.5H5.5V8.1C5.5 7.21634 6.21634 6.5 7.1 6.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M3.5 18.5H20.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.5 9.5H8.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 9.5H11.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.5 11.5H8.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 11.5H11.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.5 13.5H8.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 13.5H11.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M7.5 15.5H8.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M10.5 15.5H11.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M15.5 13.5H16.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                d="M15.5 15.5H16.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
