import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { URL_PREVIEW_ESTIMATE, URL_PREVIEW_INVOICE, URL_PREVIEW_WORK_ORDER } from 'app/const/api/Export';
import { SETTING_TEMPLATE_TYPE } from 'app/const/Settings';
import BoxContentInfo from './BoxContentInfo';
import { DEFAULT_INVOICE_TEMPLATE } from '../constant';
import IconTooltip from 'assets/icon/IconTooltip';
import GdSwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';

const BoxContentTemplate = (
    {
        invoice = {},
        estimate = {},
        workOrder = {},
        workOrderTitle = '',
        invoiceTitle = '',
        estimateTitle = '',
        belowLogoText = '',
        invoiceFooterText = '',
        estimateFooterText = '',
        estimateCost = false
    },
    ref
) => {
    const { t } = useTranslation(['setting']);
    const refInvoice = useRef(null);
    const refEstimate = useRef(null);
    const refWorkOrder = useRef(null);
    const refTextBelow = useRef(null);
    const refInvoiceFooterText = useRef(null);
    const refEstimateFooterText = useRef(null);
    const refEstimateHideCost = useRef(null);
    useImperativeHandle(ref, () => ({ _getValue }));

    const _getValue = () => ({
        ...refInvoice.current._getValue(),
        ...refEstimate.current._getValue(),
        ...refWorkOrder.current._getValue(),
        footer_text: refInvoiceFooterText.current.value,
        estimate_footer_text: refEstimateFooterText.current.value,
        below_logo_text: refTextBelow.current.value,
        estimate_cost: refEstimateHideCost.current.getIsActive() ? 1 : 0
    });

    return (
        <div className="boxs__content has-form">
            <BoxContentInfo
                id={1}
                keyValue={SETTING_TEMPLATE_TYPE.INVOICE}
                ref={refInvoice}
                action={URL_PREVIEW_INVOICE}
                titleChoiceTemplate="Choose Your Invoice Template"
                descriptionChoiceTemplate="Changes to your invoice template do not affect existing invoices."
                titleInfo="Invoice Title"
                titleDescription="Changes the title of all your new and existing invoices."
                defaultSelect={invoice && Object.keys(invoice)?.length ? invoice : DEFAULT_INVOICE_TEMPLATE}
                defaultTitle={invoiceTitle}
                autoFocus
            />
            <BoxContentInfo
                id={2}
                keyValue={SETTING_TEMPLATE_TYPE.ESTIMATE}
                ref={refEstimate}
                action={URL_PREVIEW_ESTIMATE}
                titleChoiceTemplate="Choose Your Estimate Template"
                descriptionChoiceTemplate="Changes to your estimate template do not affect existing estimates."
                titleInfo="Estimate Title"
                titleDescription="Changes the title of all your new and existing estimates."
                defaultSelect={estimate}
                defaultTitle={estimateTitle}
            />
            <div className="rows flex-betweenitems">
                <div className="name">
                    <span className="txt-ellipsis fw-bold">{t('hide_estimate_cost')}</span>
                    <div className="tooltip">
                        <IconTooltip />
                        <span className="tooltiptext top">{t('tooltip_hide_estimate_cost')}</span>
                    </div>
                </div>
                <GdSwitchCheckbox
                    ref={refEstimateHideCost}
                    name={t('hide_estimate_cost')}
                    id={t('hide_estimate_cost')}
                    textChecked={t('hide')}
                    textUnChecked={t('show')}
                    inputClassName="toggle toggle-round"
                    defaultChecked={estimateCost}
                />
            </div>
            <BoxContentInfo
                id={3}
                keyValue={SETTING_TEMPLATE_TYPE.WORK_ORDER}
                ref={refWorkOrder}
                action={URL_PREVIEW_WORK_ORDER}
                titleChoiceTemplate="Choose Your Work Order Template"
                descriptionChoiceTemplate="Changes to your work order template do not affect existing work orders."
                titleInfo="Work Order Title"
                titleDescription="Changes the title of all your new and existing work orders."
                defaultSelect={workOrder}
                defaultTitle={workOrderTitle}
            />
            <div className="rows">
                <div className="txt">Text Below Your Logo</div>
                <input
                    ref={refTextBelow}
                    className="field-input"
                    type="text"
                    placeholder="Text Below Your Logo"
                    defaultValue={belowLogoText}
                    spellCheck
                />
                <p className="rows__info">Adds text to all your new and existing documents.</p>
            </div>
            <div className="rows">
                <div className="txt">Invoice Footer Text</div>
                <textarea
                    ref={refInvoiceFooterText}
                    className="field-textarea --no-resize"
                    placeholder="Invoice Footer Text"
                    rows={5}
                    cols={30}
                    defaultValue={invoiceFooterText}
                    spellCheck
                />
            </div>
            <div className="rows">
                <div className="txt">Estimate Footer Text</div>
                <textarea
                    ref={refEstimateFooterText}
                    className="field-textarea --no-resize"
                    placeholder="Estimate Footer Text"
                    rows={5}
                    cols={30}
                    defaultValue={estimateFooterText}
                    spellCheck
                />
            </div>
        </div>
    );
};
BoxContentTemplate.displayName = 'BoxContentTemplate';
export default forwardRef(BoxContentTemplate);
