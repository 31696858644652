import moment from 'moment';

const conversions = {
    d: 'DD',
    D: 'ddd',
    j: 'D',
    l: 'dddd',
    N: 'E',
    S: 'o',
    w: 'e',
    z: 'DDD',
    W: 'W',
    F: 'MMMM',
    m: 'MM',
    M: 'MMM',
    n: 'M',
    t: '',
    L: '',
    o: 'YYYY',
    Y: 'YYYY',
    y: 'YY',
    a: 'a',
    A: 'A',
    B: '',
    g: 'h',
    G: 'H',
    h: 'hh',
    H: 'HH',
    i: 'mm',
    s: 'ss',
    u: 'SSS',
    e: 'zz',
    I: '',
    O: '',
    P: '',
    T: '',
    Z: '',
    c: '',
    r: '',
    U: 'X',
    '/': '/',
    ':': ':',
    ' ': ' '
};

export function formatDateTime(value, format, isPhp, currentFormat) {
    if (!format) return false;
    const newFormat = isPhp ? convertPhpMomentFormat(format) : format;
    if (currentFormat) return moment(value, currentFormat).format(newFormat);
    return moment(value).format(newFormat);
}

export function convertPhpMomentFormat(formatPhp) {
    if (!formatPhp) return '';

    return formatPhp.replace(/[A-Za-z]+/g, function (match) {
        return conversions[match] || match;
    });
}

export const getListYear = (yearStart, yearEnd) => {
    const years = [];
    while (yearStart <= yearEnd) {
        years.push(yearStart++);
    }
    return years;
};

/***
 * note: timestamp is always a unix time by milliseconds. But this function
 * will returns unix time by seconds.
 * if developer doesn't pass d as a getTimestamp's argument. This fn return
 * timestamp now.
 * @param {Date | undefined} d
 * @returns {number}
 * */
export const getTimestamp = (d) => {
    /**
     * closure fn.
     * @param {number} t - utc time by milliseconds
     * */
    const toUnixtime = (t) => {
        return Math.round(t / 1000);
    };

    if (d) return toUnixtime(d.getTime());
    return toUnixtime(new Date().getTime());
};
