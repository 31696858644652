import classNames from 'classnames';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import GdConfirm from 'app/components/confirm';
import GdSearch from 'app/components/search/GdSearch';
import GDStatusBar from 'app/components/status/statusbar';
import { getChatbotAnswers, getChatbotTrainAISource } from 'app/const/api/V2';
import { reducer } from 'app/const/Reducer';
import { handleAbortController } from 'app/modules/customer/utils';
import IconArrow from 'assets/icon/IconArrow';
import IconClose from 'assets/icon/IconClose';
import IconPlus from 'assets/icon/IconPlus';
import { clientQuery } from 'common/utils/ApiUtils';
import LoadingModalSelectOptions from '../components/LoadingModalSelectOptions';
import ModalAddCustomAnswer from '../components/ModalAddCustomAnswer';
import TableChatbot from '../components/TableChatbot';
import { gridColumnTrainAISource } from '../constants/gridColumnTrainAISource';
import ChartTrainAI from './ChartTrainAI';
import { showStatusBar } from 'common/utils/FunctionUtils';

const ModalTrainAI = (props, ref) => {
    const { t } = useTranslation('addons');
    const [state, dispatchState] = useReducer(reducer, {
        visible: false,
        id: null,
        commonQuestion: '',
        isAddExistingAnswer: false,
        keyword: '',
        answers: [],
        isLoadingGetAnswer: false
    });
    const {
        visible,
        id: finalId,
        commonQuestion,
        isAddExistingAnswer = false,
        keyword = '',
        answers = [],
        isLoadingGetAnswer = false
    } = state;

    const refModalAddCustomAnswer = useRef(null);
    const refInput = useRef(null);
    const abortController = useRef(null);
    const refConfirm = useRef(null);
    const refAnswers = useRef([]);
    const refAlert = useRef(null);

    useImperativeHandle(ref, () => ({ setVisible: _handleVisible, open: _handleOpen }));

    useEffect(() => {
        if (isAddExistingAnswer) {
            _getListAnswer();
        }

        return () => {
            handleAbortController(abortController);
        };
    }, [isAddExistingAnswer]);

    const _getListAnswer = () => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        dispatchState((prev) => ({ ...prev, isLoadingGetAnswer: true }));

        const _handleSuccess = ({ data = [] }) => {
            refAnswers.current = data;
            dispatchState((prev) => ({ ...prev, isLoadingGetAnswer: false, answers: data }));
        };
        const _handleFail = ({ message }) => {
            refConfirm.current?.open(null, message);
            refAnswers.current = [];
            dispatchState((prev) => ({ ...prev, isLoadingGetAnswer: false }));
        };

        clientQuery(
            getChatbotAnswers(finalId),
            { data: {}, method: 'GET', abortController: abortController.current },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleVisible = (value = false) => {
        dispatchState({ visible: value, isAddExistingAnswer: false });
    };

    const _handleOpen = (data = {}) => {
        dispatchState((prev) => ({ ...prev, ...data, visible: true }));
    };

    const _handleClose = () => {
        refAnswers.current = [];
        dispatchState((prev) => ({ ...prev, visible: false, isAddExistingAnswer: false }));
    };

    const _handleOpenModalAnswer = (data = {}) => {
        dispatchState((prev) => ({ ...prev, keyword: '' }));
        refModalAddCustomAnswer.current?._open(data);
    };

    const _handleCloseModalAnswer = () => {
        refModalAddCustomAnswer.current?._close({});
    };

    const _handleChangeAddExistingAnswer = () => {
        dispatchState((prev) => ({ ...prev, isAddExistingAnswer: !prev.isAddExistingAnswer }));
    };

    const _handleSearch = (value) => {
        const dataAnswers = refAnswers.current;
        if (!answers.length && !dataAnswers.length) return;
        dispatchState((prev) => ({
            ...prev,
            answers: dataAnswers.filter(
                ({ question, answer }) => question.toLowerCase().includes(value) || answer.toLowerCase().includes(value)
            )
        }));
    };

    const _renderListAnswers = () => {
        if (isLoadingGetAnswer) return <LoadingModalSelectOptions />;
        if (!answers.length) {
            return (
                <div className="option-items">
                    {refInput.current?.value
                        ? t('header:search_not_match')
                        : t('common:no_data_to_display', { title: t('addons:options') })}
                </div>
            );
        }

        return answers.map(({ id, question, answer }) => (
            <div
                key={id}
                className="option-items cursor-pointer"
                onClick={() => _handleOpenModalAnswer({ id, commonQuestion })}
            >
                <div className="fs-14 black mb-1">{question}</div>
                <p className="option-items__desc fs-12 black-darker2">{answer}</p>
            </div>
        ));
    };

    const _handleUpdateAnswerSuccess = () => {
        _handleCloseModalAnswer();
        if (isAddExistingAnswer) {
            dispatchState((prev) => ({ ...prev, isAddExistingAnswer: false }));
        }
    };

    const _handleShowAlert = (alert = {}) => {
        showStatusBar({ id: 'chat_bot_train_ai_modal', ...alert, refAlert });
    };

    if (!visible) return null;

    return (
        <ReactModal
            isOpen
            className={classNames('modal container-modal modal-chatbot open', {
                '--select-option': isAddExistingAnswer
            })}
        >
            <div className="modal__overlay bg-fixed" onClick={_handleClose} />
            <div className="modal__container --preview-r">
                <GDStatusBar ref={refAlert} />
                {isAddExistingAnswer ? (
                    <Fragment>
                        <div className="header-modal flex-column gap-4">
                            <div className="flexcenter gap-8 w-100">
                                <div
                                    className="v2-btn-default --transparent --icon-lg ml-n1"
                                    onClick={_handleChangeAddExistingAnswer}
                                >
                                    <IconArrow isPrev />
                                </div>
                                <h3 className="header-modal__label">{t('addons:select_option')}</h3>
                                <div
                                    className="v2-btn-default --transparent --icon-lg"
                                    onClick={_handleChangeAddExistingAnswer}
                                >
                                    <IconClose />
                                </div>
                            </div>
                            <p className="black-darker1 pl-7 w-100">{commonQuestion}</p>
                        </div>
                        <div className="body-modal flex-column p-0 scrolls">
                            <div className="rows py-0">
                                <GdSearch
                                    placeholder={t('addons:search')}
                                    inputClassName="field-input no-effect border-none"
                                    isSearchOnClear
                                    defaultValue={keyword}
                                    onSearch={_handleSearch}
                                />
                            </div>
                            <div className="rows flex-column flex-1 p-0 scrolls">{_renderListAnswers()}</div>
                        </div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <div className="header-modal">
                            <h3 className="header-modal__label">{t('addons:similar_questions')}</h3>
                            <div className="v2-btn-default --transparent --icon-lg" onClick={_handleClose}>
                                <IconClose />
                            </div>
                        </div>
                        <div className="body-modal container-print container-wrap gap-16 scrolls">
                            <div className="fw-700 fs-14 black">{commonQuestion}</div>
                            <p className="black-darker2">{t('addons:modal_desc_train_ai')}</p>
                            <div className="flexcenter gap-8">
                                <div
                                    className="v2-btn-main has-icon svg-white"
                                    onClick={() => _handleOpenModalAnswer({ commonQuestion })}
                                >
                                    <IconPlus /> {t('addons:new_answer')}
                                </div>
                                <div className="v2-btn-default has-icon" onClick={_handleChangeAddExistingAnswer}>
                                    <IconPlus /> {t('addons:add_to_existing_answer')}
                                </div>
                            </div>
                            <div className="is-divider --horizontal" />
                            <div className="fw-700 fs-14 black">{t('addons:occurrences_of_this_question')}</div>
                            <ChartTrainAI id={finalId} />
                            <TableChatbot
                                isShowHeaderBottom={false}
                                isShowHeaderFilter={false}
                                isShowCheckbox={false}
                                gridColumnReport={gridColumnTrainAISource}
                                classTable="table-multi-column has-text-ellipsis"
                                endpoint={getChatbotTrainAISource(finalId)}
                                onShowAlert={_handleShowAlert}
                            />
                        </div>
                    </Fragment>
                )}
            </div>
            <ModalAddCustomAnswer ref={refModalAddCustomAnswer} onUpdateData={_handleUpdateAnswerSuccess} />
            <GdConfirm
                ref={refConfirm}
                title={t('addons:confirm')}
                titleConfirm={t('addons:confirm')}
                listButton={{ confirm: true, cancel: true }}
            />
        </ReactModal>
    );
};

export default forwardRef(ModalTrainAI);
