import React, { useReducer } from 'react';
import IconThreeDots from 'assets/icon/IconThreeDots';
import IconTrash from 'assets/icon/IconTrash';
import IconEdit from 'assets/icon/IconEdit';
import IconSave from 'assets/icon/IconSave';
import { LIST_STATUS } from 'app/const/Status';
import { MATERIAL_TYPES } from 'app/const/addons/Material';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { actionMaterialItem } from 'app/const/api/Material';
import { reducer } from 'app/const/Reducer';
import { useSelector } from 'react-redux';

export default function Item({ dataItem, parentType, onUpdate = () => {}, onShowMessage, onConfirm }) {
    const { epa: parentEPA = '', name: parentName = '', id: finalItemId } = dataItem || {};

    const [state, dispatchState] = useReducer(reducer, {
        name: parentName,
        epa: parentEPA,
        isVisible: false
    });
    const country = useSelector(({ auth }) => auth.user.company.country);
    const { name: finalName, epa: finalEpa, isVisible: finalIsVisible } = state;

    const isMaterialType = parentType === MATERIAL_TYPES.MATERIAL;

    function _handleOpenEdit(e) {
        e && e.stopPropagation();
        dispatchState({
            isVisible: true
        });
    }

    function _handleSave(e) {
        e && e.stopPropagation();

        onUpdate(finalItemId, {
            name: finalName,
            epa: finalEpa
        });

        clientQuery(
            actionMaterialItem(finalItemId),
            {
                method: 'PUT',
                data: {
                    name: finalName,
                    epa: finalEpa,
                    sort_order: dataItem.sort_order,
                    type: parentType
                }
            },
            (response) => _handleUpdateSuccess(response, finalItemId),
            (response) => _handleUpdateFailed(response, finalItemId)
        );
    }

    function _handleUpdateSuccess(response, itemId) {
        onShowMessage(itemId, `${finalName} saved successfully`, LIST_STATUS.SUCCESS);
        dispatchState({
            isVisible: false
        });
    }

    function _handleUpdateFailed(response, itemId) {
        onShowMessage(itemId, response?.message?.toString() || `${finalName} saved failed`, LIST_STATUS.ERROR);
    }

    function _handleChangeName(e) {
        dispatchState({
            name: e.target.value
        });
    }

    function _handleChangeEPA(e) {
        dispatchState({
            epa: e.target.value
        });
    }

    return (
        <div
            className={classNames('rows material-items', {
                'is-edit': finalIsVisible,
                'is-disable': dataItem.quick_add
            })}
        >
            <div className="col-icon drag">
                <IconThreeDots isDouble />
            </div>
            <div className="rows__action">
                <div className="material-value w-100">
                    <div className="col-title">{`${dataItem.sort_order}. ${parentName}`}</div>
                    {isMaterialType && (
                        <div className="col-title">
                            {country === 'ca' ? 'PCP#' : 'EPA#'} {parentEPA}
                        </div>
                    )}
                </div>
                <div className="material-edit">
                    <input
                        value={finalName}
                        onChange={(e) => _handleChangeName(e)}
                        className="field-input"
                        placeholder="Material"
                        type="text"
                        spellCheck
                    />
                    {isMaterialType && (
                        <input
                            value={finalEpa}
                            className="field-input"
                            placeholder="EPA#"
                            type="text"
                            onChange={(e) => _handleChangeEPA(e)}
                        />
                    )}
                </div>
            </div>
            <div className="col-icon">
                <a onClick={() => onConfirm(finalItemId)} className="v2-btn-default --icon-sm --delete btn-modal">
                    <IconTrash />
                </a>
                <div
                    onClick={(e) => _handleOpenEdit(e)}
                    className={classNames('v2-btn-default --icon-sm --transparent js-edit-material-items', {
                        'dp-hide': finalIsVisible
                    })}
                >
                    <IconEdit />
                </div>
                <div
                    onClick={(e) => _handleSave(e)}
                    className={classNames('v2-btn-default --icon-sm --transparent js-save-material-items', {
                        'dp-hide': !finalIsVisible,
                        'is-disable': finalName.length === 0
                    })}
                >
                    <IconSave />
                </div>
                <div className="v2-btn-default --icon-sm --transparent loading dp-hide">
                    <div className="preloader-small">
                        <span className="preloader-small__items" />
                    </div>
                </div>
            </div>
        </div>
    );
}
