import React, { Fragment, forwardRef, useEffect, useId, useImperativeHandle, useReducer, useRef } from 'react';
import { reducer } from 'app/const/Reducer';
import { SMS_GET_LIST_SENDER } from 'app/const/api/Sms';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';
import { clientQuery } from 'common/utils/ApiUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const MessageFrom = forwardRef(({ defaultSender }, ref) => {
    const refDropdown = useRef(null);
    const modalId = useId();
    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        data: [],
        isFetched: false,
        selected: {}
    });
    const { isFetched, data: finalListSender, selected: finalSelected, isLoading } = state;

    useImperativeHandle(ref, () => ({
        getData: () => {
            return finalSelected.id;
        }
    }));

    useEffect(() => {
        // eslint-disable-next-line no-undef
        const oldDataSender = global.userPhoneNumbers;

        if (!isFetched && !!!oldDataSender) {
            _getListSender();
        } else {
            dispatchState({
                data: oldDataSender,
                selected: defaultSender
                    ? oldDataSender.find((item) => item.phone === defaultSender) || oldDataSender[0]
                    : oldDataSender[0],
                isFetched: true,
                isLoading: false
            });
        }
    }, [defaultSender]);

    const _getListSender = () => {
        clientQuery(SMS_GET_LIST_SENDER, { method: 'GET' }, _getListSenderSuccess, _getListSenderFailure);
    };

    const _getListSenderSuccess = ({ data: senders }) => {
        // eslint-disable-next-line no-undef
        global.userPhoneNumbers = senders;

        dispatchState({
            data: senders,
            selected: defaultSender ? senders.find((item) => item.phone === defaultSender) || senders[0] : senders[0],
            isFetched: true,
            isLoading: false
        });
    };

    const _getListSenderFailure = () => {
        dispatchState((prev) => ({ ...prev, isLoading: false }));
    };

    const _handleOpen = () => {};

    const _handleSelectSender = (itemSelect) => {
        dispatchState({
            selected: itemSelect
        });
        refDropdown.current._closeDropdown();
    };

    const _renderAvatar = ({ name, user }, addClass) => {
        if (!!user) {
            return (
                <span className={`schedule-user ${addClass ? 'ml-1' : ''}`}>
                    <span className="tech-name m-0">
                        <img className="avt-img mr-0" src={user.avatar} width={24} height={24} />
                        <span className="txt-ellipsis black fw-500">{user.full_name}</span>
                    </span>
                </span>
            );
        }

        return <span className={`${addClass ? 'ml-1' : ''} txt-ellipsis black fw-500`}>{name}</span>;
    };

    const _renderLoading = () => {
        return (
            <Fragment>
                <div className="border-bottom-border-color-grey">
                    <div className="number-items flex-betweenitems gap-8">
                        <div className="loading --animation --line --fourth" />
                        <div className="loading --animation --line --fourth" />
                    </div>
                </div>
                <div className="border-bottom-border-color-grey">
                    <div className="number-items flex-betweenitems gap-8">
                        <div className="loading --animation --line --fourth" />
                        <div className="loading --animation --line --fourth" />
                    </div>
                </div>
                <div className="border-bottom-border-color-grey">
                    <div className="number-items flex-betweenitems gap-8">
                        <div className="loading --animation --line --fourth" />
                        <div className="loading --animation --line --fourth" />
                    </div>
                </div>
            </Fragment>
        );
    };

    const _renderListSender = () => {
        if (isLoading) {
            return _renderLoading();
        }
        return finalListSender.map((item, index) => {
            const { phone } = item;

            const isActive = phone === finalSelected?.phone;

            return (
                <div
                    key={index}
                    onClick={() => _handleSelectSender(item)}
                    className={'border-bottom-border-color-grey'}
                >
                    <div className={`number-items flex-betweenitems gap-8  ${isActive ? 'active' : ''}`}>
                        <span className="txt-ellipsis flex-1">{formatPhoneNumberVoip(phone)}</span>
                        {_renderAvatar(item)}
                    </div>
                </div>
            );
        });
    };

    const _renderSelectedItem = () => {
        const { phone } = finalSelected || {};

        return (
            <div className="message-name">
                From
                {_renderAvatar(finalSelected, true)}
                <span className="phone mx-1">{formatPhoneNumberVoip(phone)}</span>
            </div>
        );
    };

    return (
        <CalendarDropdown
            id={modalId}
            ref={refDropdown}
            onVisible={_handleOpen}
            buttonClassName="wrap-action"
            wrapperClassName="v2-dropdown title-message-chat"
            wrapperListClass="v2-dropdown__menu select-phone-number scrolls"
            customDropButton={() => (
                <Fragment>
                    {_renderSelectedItem()}
                    <div className="arrow">
                        <IconDropDown />
                    </div>
                </Fragment>
            )}
        >
            {_renderListSender()}
        </CalendarDropdown>
    );
});
export default MessageFrom;
