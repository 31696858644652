import { ACTIVATE_ACCOUNT, SIGNUP, SIGNUP_SOCIAL_COMPANY } from 'app/const/Api';
import { ACCESS_TOKEN, KEY_CURRENT_BRANCH, KEY_DEVICE_ID } from 'app/const/App';
import { setBranchId } from 'app/const/Branch';
import { KEY_LOCAL_STORAGE_BOOK_MARK_HEADER } from 'app/modules/calendar/const/BookMark';
import { SET_APP_LOADING } from 'common/redux/actions/appAction';
import { AUTH_TYPE } from 'common/redux/actions/authAction';
import { CALENDAR_ACTIONS } from 'common/redux/actions/calendar';
import { TYPE_SCHEDULE_ACTION } from 'common/redux/actions/calendar/scheduleAction';
import { TYPE_ACTION_COMPANY_USERS } from 'common/redux/actions/companyUsersAction';
import { MAP_ACTIONS } from 'common/redux/actions/map';
import { fetchWithToken, postFetchWithoutToken, postWithToken } from 'common/utils/ApiUtils';
import { removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { call, put } from 'redux-saga/effects';

export function* userSignup({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postFetchWithoutToken, SIGNUP, payload, put);
    if (response?.success) {
        yield put({ type: AUTH_TYPE.SIGNUP_USER_SUCCESS });
        actionSuccess(response);
    } else {
        yield put({ type: AUTH_TYPE.SIGNUP_USER_ERROR, payload: response.message });
        actionFailed(response);
    }
}

export function* userActivateAccount({ payload, actionSuccess = () => {}, actionFailed = () => {} }) {
    const response = yield call(fetchWithToken, ACTIVATE_ACCOUNT, payload);

    if (response?.success) {
        const reponseBranchId = response.company.branch.id;
        const deviceId = response.device_id;

        setLocalStorage(KEY_DEVICE_ID, deviceId || '');
        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);
        removeLocalStorage(ACCESS_TOKEN);
        const calendar = response?.calendar || {};
        yield put({
            type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
            payload: calendar
        });
        yield put({
            type: MAP_ACTIONS.UPDATE_MAP_SIZE,
            payload: {
                heightofmap: calendar?.heightofmap,
                widthofmap: calendar?.widthofmap,
                statusofmap: calendar?.statusofmap,
                typeofmap: calendar.typeofmap,
                dayofmap: calendar?.dayofmap,
                openmap: calendar?.openmap
            }
        });
        yield put({
            type: AUTH_TYPE.LOGIN_USER_SUCCESS,
            payload: response
        });
        yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
        yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });
        typeof actionSuccess === 'function' && actionSuccess();
    } else {
        yield put({ type: AUTH_TYPE.ACTIVATE_USER_ACCOUNT_ERROR, payload: response.message });
        actionFailed();
    }
}

export function* signupSocialCompany({ payload, actionSuccess, actionFailed }) {
    const response = yield call(postWithToken, SIGNUP_SOCIAL_COMPANY, payload, {
        'gd-device-id': localStorage.getItem(KEY_DEVICE_ID) || ''
    });

    if (response.success) {
        const reponseBranchId = response.company.branch.id;
        const deviceId = response.device_id;

        setLocalStorage(KEY_DEVICE_ID, deviceId || '');
        setBranchId(reponseBranchId);
        setLocalStorage(KEY_CURRENT_BRANCH, reponseBranchId);
        setLocalStorage(KEY_LOCAL_STORAGE_BOOK_MARK_HEADER, []);
        const calendar = response?.calendar || {};
        yield put({
            type: CALENDAR_ACTIONS.UPDATE_CALENDAR,
            payload: calendar
        });
        yield put({
            type: MAP_ACTIONS.UPDATE_MAP_SIZE,
            payload: {
                heightofmap: calendar?.heightofmap,
                widthofmap: calendar?.widthofmap,
                statusofmap: calendar?.statusofmap,
                typeofmap: calendar.typeofmap,
                dayofmap: calendar?.dayofmap,
                openmap: calendar?.openmap
            }
        });
        yield put({
            type: AUTH_TYPE.LOGIN_USER_SUCCESS,
            payload: response
        });
        yield put({ type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING });
        yield put({ type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST });
        yield put({
            type: SET_APP_LOADING,
            payload: false
        });

        actionSuccess();
    } else {
        yield put({ type: AUTH_TYPE.LOGIN_USER_ERROR, payload: response });
        actionFailed();
    }
}
