import { reducer } from 'app/const/Reducer';
import { ACTIVE_ACCOUNT_VOIP } from 'app/const/api/Voip';
import IconArrow from 'assets/icon/IconArrow';
import { clientQuery } from 'common/utils/ApiUtils';
import moment from 'moment';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PlanChooseCard from './PlanChooseCard';
import { ADDONS_VOIP } from 'app/config/routes';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { updateAddonStatus } from 'common/redux/actions/authAction';
import { useDispatch } from 'react-redux';
import IconTooltip from 'assets/icon/IconTooltip';
import { addBranchPath } from 'app/const/Branch';

export const VOIPAddonsModifyPlan = ({
    data,
    isShowFooter = true,
    onChange = () => {},
    isManage = false,
    onVisibleToolTip = () => {},
    isCanceled
}) => {
    const { t } = useTranslation('addons');
    const history = useHistory();
    const dispatch = useDispatch();
    const refCard = useRef(null);

    const _addMonth = (date, month) => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + month);
        return newDate;
    };

    const list_recharge_threshold = [10, 25, 50];
    const list_recharge_amount = [100, 250, 500];
    const listClassDevide = ['sm', 'md', 'lg'];

    const [state, dispatchState] = useReducer(reducer, {
        ...data,
        isCanceled,
        isChanged: false
    });

    useEffect(() => {
        dispatchState({ ...data });
    }, [data]);

    const { account, plans, handleBack, oldAccount, isCanceled: finalIsCanceled, isChanged: finalIsChanged } = state;
    const {
        plan_id = plans[0]['id'],
        balance = list_recharge_amount[0],
        usage_this_month = 0,
        recharge_amount = list_recharge_amount[0],
        recharge_threshold = list_recharge_threshold[0],
        user_quantity,
        isActive = true,
        expiration_date = ''
    } = account || {};

    const { plan_id: oldPlanId = plans[0]['id'], user_quantity: oldUser = 0 } = oldAccount || {};
    const isReActivate = isManage && finalIsCanceled && finalIsChanged;

    const {
        credit,
        price,
        name: planName,
        user_price: pricePerUser,
        user_quantity: userFree
    } = plans.find((item) => item.id === plan_id);
    const newBalance = isActive ? balance : recharge_amount;
    const expireDate = expiration_date || moment(_addMonth(new Date(), 1)).format('DD/MM/YYYY');

    const _formatMoney = ({ number = 0, isPerMo = false }) => {
        const value = Number(number).toFixed(2);
        if (isPerMo) return t('price_per_mo', { price: value });
        return `\$${value}`;
    };

    const _renderProgressBalance = () => {
        const perCredit = credit > 0 ? Math.round((usage_this_month / credit) * 100) : 0;
        const colorCredit = perCredit >= 100 ? 'green' : 'orange';
        const calcBalance = usage_this_month - credit;
        const balanceUsed = calcBalance >= 0 ? calcBalance : 0;
        const perBalance = Math.round((balanceUsed / newBalance) * 100);
        const perBelow = Math.round((recharge_threshold / newBalance) * 100);

        return (
            <div className="wrap-plans__balance">
                <p>
                    {t('your_team_has_used')} <b className="fw-600">{_formatMoney({ number: usage_this_month })}</b>
                    {t('which_resets_on', { date: expireDate })}
                </p>
                <div className="flex-betweentop col-gap-4">
                    {!!credit && (
                        <div className="balance-credit">
                            <div className={`progress-bar --${colorCredit} --lg per-${perCredit}`}>
                                <span />
                            </div>
                            <span className={`status-btn fs-11 bg-${colorCredit}-default`}>
                                {`\$${credit} ${t('credit')}`}
                            </span>
                        </div>
                    )}
                    <div className="balance-remaining flex-1 text-right">
                        <div
                            className={`progress-bar --blue --lg per-${perBalance} balance-remaining-${perBelow} relative`}
                        >
                            {isActive && (
                                <span className="relative">
                                    <span className="remaining-value absolute blue-default fs-10 fw-600">
                                        {_formatMoney({ number: balanceUsed })}
                                    </span>
                                </span>
                            )}
                            <div className="divide-bar is-balance"></div>
                        </div>
                        <span className="fw-600">{_formatMoney({ number: newBalance })}</span>
                    </div>
                </div>
            </div>
        );
    };

    const _renderBoxTotal = () => {
        const isRaw = !isManage || isReActivate;
        const isUpgradePlan = oldPlanId < plan_id;
        const isUpgradeUser = oldUser < user_quantity;
        const compareUser = user_quantity > oldUser;

        const oldPlan = plans.find((item) => item.id === oldPlanId);

        const totalUserQuantity = Math.max(user_quantity - userFree, 0);

        let priceForUsers = 0;
        if (isRaw) {
            priceForUsers = pricePerUser * totalUserQuantity;
        } else if (isUpgradePlan || isUpgradeUser) {
            const userPriceDiff = Math.max(pricePerUser - oldPlan.user_price, 0);
            const userQuantityDiff = compareUser ? oldUser : user_quantity;
            priceForUsers =
                userPriceDiff * (userQuantityDiff - userFree) +
                pricePerUser * (compareUser ? user_quantity - oldUser : 0);
        }

        const pricePlan = isRaw ? price : Math.max(price - oldPlan.price, 0);
        const priceTotal = pricePlan + priceForUsers + (!isManage ? recharge_amount : 0);

        return (
            <div className="box-plans --lg flex-column gap-10 black fs-14">
                <div className="flex-betweenitems">
                    <span>{t(`voip_${planName.toLowerCase()}_plan`)}</span>
                    <span className="blue-default fw-600">{_formatMoney({ number: pricePlan, isPerMo: true })}</span>
                </div>
                <div className="flex-betweenitems">
                    <span>{t('voip_activated_users_one_free', { users: user_quantity, free: userFree })}</span>
                    <span className="blue-default fw-600">
                        {_formatMoney({ number: priceForUsers, isPerMo: true })}
                    </span>
                </div>
                <div className="flex-betweenitems">
                    <span>{t('starting_voip_credit_balance')}</span>
                    <span className="blue-default fw-600">
                        {_formatMoney({ number: isManage ? 0 : recharge_amount })}
                    </span>
                </div>
                <div className="is-divider --horizontal" />
                <div className="flex-betweenitems fw-600">
                    <span>{t('total')}</span>
                    <span className="blue-default fs-16">{_formatMoney({ number: priceTotal })}</span>
                </div>
                <div className="note-billing flex-centeritem gap-4 fs-12">
                    {t('future_monthly_voip_plan_billing')}
                    <span className="blue-default">
                        {_formatMoney({ number: price + pricePerUser * totalUserQuantity, isPerMo: true })}
                    </span>
                </div>
            </div>
        );
    };

    const _renderBoxRange = () => {
        const indexBelow = list_recharge_threshold.findIndex((value) => value === recharge_threshold);
        const indexBalance = list_recharge_amount.findIndex((value) => value === recharge_amount);

        const _renderDevide = (list = []) => {
            return list.map((item, i) => <div key={i.toString()} className="divide-bar" />);
        };

        const _renderValues = (list = []) => {
            return list.map((item, i) => (
                <span key={i.toString()} className="values absolute">
                    ${item}
                </span>
            ));
        };

        return (
            <div className="wrap-plans__cost flexcenter gap-24">
                <div className="flex-column gap-4 flex-1">
                    <span className="flex-betweenitems gap-2">
                        {t('when_your_balance_goes_below')}
                        <span className="blue-default fw-600 fs-14">${recharge_threshold}</span>
                    </span>
                    <div className="box-plans pb-6">
                        <div className={`progress-bar --md relative is-${listClassDevide[indexBelow]}`}>
                            <div className="range-overlay" />
                            <input
                                id="list_recharge_threshold"
                                className="slider-main"
                                type="range"
                                min={0}
                                max={list_recharge_threshold.length - 1}
                                defaultValue={indexBelow}
                                step={1}
                                onChange={_handleChangeRange}
                            />
                            {_renderDevide(list_recharge_threshold)}
                        </div>
                        <div className="progress-value relative black">{_renderValues(list_recharge_threshold)}</div>
                    </div>
                </div>
                <div className="flex-column gap-4 flex-1">
                    <span className="flex-betweenitems gap-2">
                        {t('recharge_credit_balance_to')}
                        <span className="blue-default fw-600 fs-14">${recharge_amount}</span>
                    </span>
                    <div className="box-plans pb-6">
                        <div className={`progress-bar --md relative is-${listClassDevide[indexBalance]}`}>
                            <div className="range-overlay" />
                            <input
                                id="list_recharge_amount"
                                className="slider-main"
                                type="range"
                                min={0}
                                max={list_recharge_amount.length - 1}
                                step={1}
                                defaultValue={indexBalance}
                                onChange={_handleChangeRange}
                            />
                            {_renderDevide(list_recharge_amount)}
                        </div>
                        <div className="progress-value relative black">{_renderValues(list_recharge_amount)}</div>
                    </div>
                </div>
            </div>
        );
    };

    const _handleChangeRange = (e) => {
        const { id: idList, value } = e.target;
        const key = idList.replace('list_', '').toLowerCase();
        const data = { [key]: eval(idList)[value * 1] };
        dispatchState((prev) => ({ ...prev, account: { ...prev.account, ...data } }));
        onChange(data);
    };

    const _handleClickActive = () => {
        refCard.current._open();
    };

    const _handleActivePlan = ({ isNewCard = false, cardDetail = {} }) => {
        const newData = {
            plan_id,
            user_quantity,
            recharge_amount,
            recharge_threshold
        };

        if (isNewCard) {
            newData.stripe_card_token = cardDetail.token.id;
        }

        const _handleSuccess = () => {
            refCard.current._close();
            dispatch(updateAddonStatus({ keyword: 'voip', data: {} }));
            history.push(addBranchPath(ADDONS_VOIP), { isActiveSuccess: true });
        };

        const _handleFail = ({ message }) => {
            refCard.current._removeLoading(message);
        };

        clientQuery(
            ACTIVE_ACCOUNT_VOIP,
            {
                method: 'POST',
                toFormData: false,
                data: newData
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderCreditBalance = () => {
        if (isManage)
            return (
                <h3 className="fs-14 fw-600 has-tooltip">
                    {t('credit_balance')}
                    <span className="ml-1 cursor-pointer tooltip" onClick={onVisibleToolTip}>
                        <IconTooltip />
                        <p className="tooltiptext top">
                            Per minute call rates apply & require a prepaid VOIP credit package.
                            <p className="dots">Local Numbers - Send Calls $0.04/min | Receive Calls $0.03/min</p>
                            <p className="dots">Toll-Free Numbers - Send Calls $0.04/min | Receive Calls $0.07/min</p>
                            To ensure there is available credit in your account, and your VOIP service is not
                            interrupted, set up your credit threshold & balance. <br />
                            If your plan includes credit, this value will be deducted from first each month before
                            deducting from the available credit balance.
                        </p>
                    </span>
                </h3>
            );

        return (
            <Fragment>
                <h3 className="fs-14 fw-600">
                    {t('voip_credit_balance', {
                        balance: Number(
                            newBalance - (credit >= usage_this_month ? 0 : usage_this_month - credit)
                        ).toFixed(2)
                    })}
                </h3>
                <div className="flex-column gap-3">
                    <p className="dots">{t('desc_local_numbers')}</p>
                    <p className="dots">{t('desc_toll_free_numbers')}</p>
                </div>
                <p>{t('desc_set_up_your_credit')}</p>
                <p className="mt-4">{t('desc_credit_notice')}</p>
            </Fragment>
        );
    };

    return (
        <div className="wrap-plans flex-column gap-12 mt-2">
            <div className="flex-column gap-6">{_renderCreditBalance()}</div>
            {!isManage && <div className="is-divider --horizontal" />}
            {_renderProgressBalance()}
            <div className="is-divider --horizontal" />
            {_renderBoxRange()}
            {_renderBoxTotal()}
            {isShowFooter && (
                <div className="flex-betweenitems">
                    <div className="v2-btn-default has-icon --grey" onClick={handleBack}>
                        <IconArrow isPrev />
                        {t('back')}
                    </div>
                    <div className="v2-btn-default has-bg-blue white --transparent" onClick={_handleClickActive}>
                        {t('pay_total_and_activate_plan')}
                    </div>
                </div>
            )}
            <PlanChooseCard ref={refCard} onChooseCardSuccess={_handleActivePlan} />
        </div>
    );
};
