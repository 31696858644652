export const TYPE_REPORT_REVENUE_SOURCE = {
    GET_LIST_MONTH_TAB: 'GET_LIST_REPORT_REVENUE_SOURCE_MONTH_TAB',
    GET_LIST_DETAIL_TAB: 'GET_LIST_REPORT_REVENUE_SOURCE_DETAIL_TAB',
    GET_LIST_METHOD_FILTER_REVENUE_BY_SOURCE: 'GET_LIST_METHOD_FILTER_REVENUE_BY_SOURCE',
    GET_LIST_SERVICE_FILTER_REVENUE_BY_SOURCE: 'GET_LIST_SERVICE_FILTER_REVENUE_BY_SOURCE'
};

// actions
export const getListReportRevenueBySourceMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SOURCE.GET_LIST_MONTH_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueBySourceDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SOURCE.GET_LIST_DETAIL_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterRevenueBySourceRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SOURCE.GET_LIST_METHOD_FILTER_REVENUE_BY_SOURCE,
        actionSuccess,
        actionFailed
    };
};

export const getListSettingSource = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SOURCE.GET_LIST_SERVICE_FILTER_REVENUE_BY_SOURCE,
        actionSuccess,
        actionFailed
    };
};
