import React from 'react';

const ListDocsAvailableLoading = () => {
    return (
        <div className="wrap-loading --addon list__document">
            <div className="rows">
                <div className="rows__icon">
                    <div className="check-items">
                        <div className="checkbox" />
                    </div>
                </div>
                <div className="rows__name">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="rows__action">
                    <div className="v2-btn-default --transparent --icon-sm loading" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__icon">
                    <div className="check-items">
                        <div className="checkbox" />
                    </div>
                </div>
                <div className="rows__name">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="rows__action">
                    <div className="v2-btn-default --transparent --icon-sm loading" />
                </div>
            </div>
            <div className="rows">
                <div className="rows__icon">
                    <div className="check-items">
                        <div className="checkbox" />
                    </div>
                </div>
                <div className="rows__name">
                    <div className="loading --animation --line --half" />
                </div>
                <div className="rows__action">
                    <div className="v2-btn-default --transparent --icon-sm loading" />
                </div>
            </div>
        </div>
    );
};

export default ListDocsAvailableLoading;
