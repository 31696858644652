export default function IconMenuDrip({ isMenuActive = false }) {
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FF7047"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 25C19.866 25 23 21.866 23 18C23 14.0893 20.6667 10.4227 16 7C11.3333 10.4227 9 14.0893 9 18C9 21.866 12.134 25 16 25Z"
                    fill="#FFA88F"
                />
                <path
                    d="M16 21C17.6569 21 19 19.6569 19 18"
                    stroke="white"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FDDAD0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 25C19.866 25 23 21.866 23 18C23 14.0893 20.6667 10.4227 16 7C11.3333 10.4227 9 14.0893 9 18C9 21.866 12.134 25 16 25Z"
                fill="#FFA88F"
            />
            <path
                d="M16 21C17.6569 21 19 19.6569 19 18"
                stroke="#FF7047"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
