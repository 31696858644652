import { reducer } from 'app/const/Reducer';
import { ADDONS_VOIP_GROUP, SOURCE_PHONE_NUMBER_VOIP, TYPE_MANAGE_ADDONS_VOIP } from 'app/const/addons';
import React, { Fragment, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IconTrash from 'assets/icon/IconTrash';
import { AssignGroupMember } from './settings/AssignGroupMember';
import HeaderSettings from './settings/HeaderSettings';
import VoicemailSettings from './settings/VoicemailSettings';
import ForwardingSettings from './settings/ForwardingSettings';
import ButtonSave from 'app/components/button/ButtonSave';
import { clientQuery } from 'common/utils/ApiUtils';
import { updateSettingsNumberVoip } from 'app/const/api/Voip';
import { ACCOUNT_ROLE, LIST_STATUS } from 'app/const/App';
import ModalWarningDelete from './settings/ModalWarningDelete';
import GridEmpty from 'app/components/grid/components/GridEmpty';
import { rawPhone, validatePhoneNumberVoip } from 'common/utils/PhoneUtils';
import { useSelector } from 'react-redux';
import RecordingDisclosure from './settings/RecordingDisclosure';
import AdjustRingDuration from './settings/AdjustRingDuration';

const { [ADDONS_VOIP_GROUP]: typeGroup } = TYPE_MANAGE_ADDONS_VOIP;

export const VOIPAddonsSettings = ({
    item,
    refAlert,
    onGoBack = () => {},
    onUpdateSuccess = () => {},
    onDeleteSuccess = () => {},
    listData = []
}) => {
    if (!item) return <GridEmpty />;

    const { t } = useTranslation();

    const refButtonSave = useRef(null);
    const refVoicemail = useRef(null);
    const refForwarding = useRef(null);
    const refHeader = useRef(null);
    const refWarningDelete = useRef(null);
    const refRecordingDisclosure = useRef(null);
    const refRingDuration = useRef(null);

    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    const [state, dispatchState] = useReducer(reducer, {
        isLoading: true,
        isLoadingModal: false,
        data: item
    });

    const { forwarding, id, phone_number, type, voicemail, assignees, source, recording_disclosure, timeout } =
        state.data || {};

    const _handleSave = async () => {
        const valueVoicemail = await refVoicemail.current?._getValue();
        const valueForwarding = await refForwarding.current?._getValue();
        const valueRecordingDisclosure = await refRecordingDisclosure.current?._getValue();

        const data = {
            ...item,
            ...valueVoicemail,
            ...valueForwarding,
            ...valueRecordingDisclosure,
            ...refHeader.current?._getValue(),
            ...refRingDuration.current?._getValue()
        };

        if (type === typeGroup) {
            delete data.role;
            data.assignees = assignees;
        }

        if (source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) {
            delete data.voicemail;
            delete data.forwarding;
        }

        const { forwarding, forwarding_number } = valueForwarding || {};
        const { voicemail, greeting, greeting_text, objectKey } = valueVoicemail || {};

        if (voicemail && !greeting && greeting_text === '') {
            _handleFail({ message: t('addons:greeting_cannot_be_blank') });
            return false;
        }
        data.greeting = objectKey || greeting;
        delete data.objectKey;

        if (forwarding) {
            if (!forwarding_number?.trim()) {
                _handleFail({ message: t('addons:forwarding_number_can_not_be_blank') });
                return false;
            }
            if (!validatePhoneNumberVoip(forwarding_number)) {
                _handleFail({ message: t('addons:error_phone_number', { number: forwarding_number }) });
                return false;
            }
            if (rawPhone(phone_number) === rawPhone(forwarding_number)) {
                _handleFail({ message: t('addons:err_forwarding_to_itself') });
                return false;
            }
        }

        const _handleSuccess = (res) => {
            const { message } = res;
            refButtonSave.current.setLoading(false);
            refAlert.current.showStatusBar({
                id: 'manage_settings_number',
                message: !!message.length ? message : t('addons:save_settings_number_successfully'),
                type: LIST_STATUS.SUCCESS
            });
            onUpdateSuccess(res);
        };

        clientQuery(
            updateSettingsNumberVoip(id),
            { method: 'PUT', toFormData: false, data },
            _handleSuccess,
            _handleFail
        );
    };

    const _handleFail = (err) => {
        refButtonSave.current.setLoading(false);
        refAlert.current.showStatusBar({
            id: 'manage_settings_number',
            message: err.message,
            type: LIST_STATUS.ERROR
        });
    };

    const _handleDeleteSuccess = () => {
        onDeleteSuccess(item.id);
        onGoBack();
    };

    const _handleUpdateMember = (id, assignees) => {
        dispatchState((prev) => ({ data: { ...prev.data, assignees } }));
    };

    const _handleClickDelete = () => {
        refWarningDelete.current.onDelete(item);
    };

    const _setDisableSave = (value = false) => {
        refButtonSave.current?.setDisable(value);
    };

    const _renderFooter = () => {
        return (
            <div className="flex-betweenitems mt-2">
                {isSupperAdmin && (
                    <div className="v2-btn-default has-bg-red has-icon" onClick={_handleClickDelete}>
                        <IconTrash />
                        {t('addons:delete_number')}
                    </div>
                )}
                <div className="flexcenter justify-end flex-1">
                    <div className="v2-btn-default --transparent" onClick={onGoBack}>
                        {t('addons:cancel')}
                    </div>
                    <ButtonSave ref={refButtonSave} wrapClass="v2-btn-main ml-2" onSave={_handleSave}>
                        {t('addons:save')}
                    </ButtonSave>
                </div>
            </div>
        );
    };

    const _renderOptionsNumber = () => {
        if (source === SOURCE_PHONE_NUMBER_VOIP.EXTERNAL) return null;

        return (
            <Fragment>
                <AdjustRingDuration ref={refRingDuration} ring={timeout} />
                <VoicemailSettings ref={refVoicemail} voicemail={voicemail} onDisableButtonSave={_setDisableSave} />
                <ForwardingSettings ref={refForwarding} forwarding={forwarding} />
            </Fragment>
        );
    };

    return (
        <Fragment>
            <div className="wrap-manager">
                <div className="box-voip bg-white">
                    <HeaderSettings ref={refHeader} data={item} listData={listData} />
                    {_renderOptionsNumber()}
                    <RecordingDisclosure ref={refRecordingDisclosure} recording_disclosure={recording_disclosure} />
                    {type === typeGroup && isSupperAdmin && source !== SOURCE_PHONE_NUMBER_VOIP.EXTERNAL && (
                        <AssignGroupMember
                            item={state.data}
                            onUpdateMember={_handleUpdateMember}
                            isReload={false}
                            isShowAlert
                            wrapperClassName="box-voip__rows flex-betweenitems --setting-number"
                        />
                    )}
                </div>
                {_renderFooter()}
            </div>

            <ModalWarningDelete ref={refWarningDelete} onFail={_handleFail} onSuccess={_handleDeleteSuccess} />
        </Fragment>
    );
};
