import { ROUTING_UNITS } from 'app/modules/addons/advanceRouting/constants';
import { convertSecondsToETA } from 'common/utils/TimeUtils';
import React from 'react';

const BoxDistance = ({ distance = {}, time }) => {
    if (!distance?.value) return <div className="box-distance">0 miles - 0 mins</div>;
    return (
        <div className="box-distance">
            <span>
                {distance.value}
                {ROUTING_UNITS[distance.unit]?.label?.toLowerCase()}
            </span>
            <span className="dots" />
            {convertSecondsToETA(time || 0)}
        </div>
    );
};

export default BoxDistance;
