import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { DEFAULT_ALL } from 'app/const/App';
import { CUSTOMER_LOCATION_SEARCH_KEY } from 'app/const/Customers';
import { KEY_LOCATION_FILTER_SELECTED } from 'app/modules/customer/const';
import { setLocalStorage } from 'common/utils/LocalStorageUtils';
import {
    DEFAULT_SELECT_LOCATION,
    DEFAULT_SELECT_UNIT,
    PAGES_DISPLAY_UNIT_FILTER,
    PAGES_HIDE_LOCATION_FILTER
} from '../../constant';
import { CustomerDetailContext } from '../../context/CustomerDetailContext';
import { handleCombineLocationSameAddress } from '../../context/utils';
import FilterDropdown, { TYPE_DROPDOWN } from './FilterDropdown';

const SideMenuFilter = () => {
    const { pathname } = useLocation();
    const { id: customerId } = useParams();
    const { locations, locationSelected, _handleUpdateLocation, units, unitSelected, _handleUpdateUnit } =
        useContext(CustomerDetailContext);
    const canDisplayUnitFilter = PAGES_DISPLAY_UNIT_FILTER.some((item) => pathname.includes(item));
    const hideLocationFilter = PAGES_HIDE_LOCATION_FILTER.some((item) => pathname.includes(item));
    const shouldDisplayUnit = units.length >= 1 && canDisplayUnitFilter;

    const _handleSelectLocation = (item) => {
        setLocalStorage(KEY_LOCATION_FILTER_SELECTED, {
            location: item,
            customer_id: customerId,
            location_ids: item.id
        });
        _handleUpdateLocation(item);
    };

    if (hideLocationFilter && !canDisplayUnitFilter) return null;
    if (!shouldDisplayUnit && locations.length <= 1) return null;
    return (
        <div className="wrap-filter flexcenter fs-13">
            {locations.length > 1 ? (
                <FilterDropdown
                    data={handleCombineLocationSameAddress(locations)}
                    type={TYPE_DROPDOWN.LOCATION}
                    searchKey={CUSTOMER_LOCATION_SEARCH_KEY}
                    className="wrap-filter__location is-lg flex-1"
                    optionDefault={DEFAULT_SELECT_LOCATION}
                    defaultSelected={locationSelected?.id || DEFAULT_ALL}
                    onSelect={_handleSelectLocation}
                />
            ) : null}
            {shouldDisplayUnit ? (
                <FilterDropdown
                    type={TYPE_DROPDOWN.UNIT}
                    data={units}
                    optionDefault={DEFAULT_SELECT_UNIT}
                    defaultSelected={unitSelected?.id || DEFAULT_ALL}
                    className="wrap-filter__mdu is-sm flex-1"
                    onSelect={_handleUpdateUnit}
                />
            ) : null}
        </div>
    );
};

export default SideMenuFilter;
