import React from 'react';

export const VOIPAddonsLoading = () => {
    return (
        <div className="wrap-loading --addon ">
            <div className="boxs boxs--no-border">
                <div className="boxs__header page-heading">
                    <div className="header-title">
                        <div className="header-title__svg v2-btn-default loading --icon-lg"></div>
                        <div className="wrap-title flex-1">
                            <div className="loading --animation --line --light --twothird"></div>
                            <div className="status-btn loading --animation --line --light --onefifth"></div>
                        </div>
                    </div>
                    <div className="loading --animation --line --light --onefifth"></div>
                </div>
            </div>
            <div className="boxs boxs-has-footer border-border-color-grey mt-3">
                <div className="flex-column gap-8 p-4">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --threefourth"></div>
                    <div className="loading --animation --line --fourth"></div>
                    <div className="is-divider --horizontal"></div>
                    <div className="flexcenter mt-1">
                        <div className="loading --animation --line --fourth"></div>
                        <div className="dots flexcenter loading --twothird">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                </div>
                <div className="boxs__footer">
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="is-divider --horizontal my-3"></div>
            <div className="boxs boxs-has-footer border-border-color-grey mt-3">
                <div className="flex-column gap-8 p-4">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --full"></div>
                    <div className="loading --animation --line --threefourth"></div>
                </div>
                <div className="boxs__footer">
                    <div className="wrap-member-group flexcenter flex-1">
                        <div className="avt-img"></div>
                        <div className="avt-img"></div>
                        <div className="avt-img"></div>
                        <div className="avt-img"></div>
                        <div className="avt-img"></div>
                    </div>
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer border-border-color-grey mt-3">
                <div className="flex-column gap-8 p-4">
                    <div className="loading --animation --line --twothird"></div>
                    <div className="loading --animation --line --full"></div>
                    <div className="loading --animation --line --threefourth"></div>
                </div>
                <div className="boxs__footer">
                    <div className="flex-1">
                        <div className="loading --animation --line --twothird"></div>
                    </div>
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer border-border-color-grey">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="title loading --twothird ml-0">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="dots powerups loading --twothird">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="loading --animation --line --twothird"></div>
                </div>
                <div className="boxs__footer">
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer border-border-color-grey">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="title loading --twothird ml-0">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="dots powerups loading --twothird">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="loading --animation --line --twothird"></div>
                </div>
                <div className="boxs__footer">
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer border-border-color-grey">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="title loading --twothird ml-0">
                            <div className="loading --animation --line --full"></div>
                        </div>
                        <div className="dots powerups loading --twothird">
                            <div className="loading --animation --line --full"></div>
                        </div>
                    </div>
                    <div className="switch loading --onefifth">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="loading --animation --line --full"></div>
                    <div className="loading --animation --line --onefifth mt-2"></div>
                    <div className="mt-3 pt-3 border-top-border-color-grey">
                        <div className="flex-betweenitems mb-1">
                            <div className="loading --animation --line --fourth"></div>
                            <div className="loading --animation --line --onetenth"></div>
                        </div>
                        <div className="flex-betweenitems">
                            <div className="loading --animation --line --half"></div>
                            <div className="loading --animation --line --onetenth"></div>
                        </div>
                    </div>
                </div>
                <div className="boxs__footer">
                    <div className="flex-1">
                        <div className="loading --animation --line --twothird"></div>
                    </div>
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};
