import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import { REVIEW_SERVICE_GET_LIST, REPORT_REVIEW_USERS_FILTER } from 'app/const/Api';
import { GET_LIST_TEAMMATE } from 'app/const/api/V2';

export function* getListReviewService({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REVIEW_SERVICE_GET_LIST, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListUserFilter({ actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, REPORT_REVIEW_USERS_FILTER);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* getListUser({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(fetchWithToken, GET_LIST_TEAMMATE, params);
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
