import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ADDONS_DOCUMENTS_MANAGE } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';
import IconCogWheel from 'assets/icon/IconCogWheel';

const FooterManageDocument = ({ isEnabled = false, status = 0, documents = 0 }) => {
    const { t } = useTranslation('addons');

    return (
        <div className="boxs__footer --multi">
            <p className="grey-extrlight txt-ellipsis">
                {t(documents !== 1 ? 'count_documents' : 'count_document', { number: documents })}
            </p>
            <Link
                to={addBranchPath(ADDONS_DOCUMENTS_MANAGE)}
                className={classNames('v2-btn-default svg-purple btn-bg-purple --icon-r --sm', {
                    'is-disable': !isEnabled || !status
                })}
            >
                {t('manage_documents_library')}
                <IconCogWheel />
            </Link>
        </div>
    );
};

export default FooterManageDocument;
