import React from 'react';

const LoadingListAddons = () => {
    return (
        <div className="wrap-loading --addon scrolls">
            <div className="tab-contents wrapper-addons">
                <div className="wrapper-addons__content tab-content-active">
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --fourth mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                        </div>
                        <div className="boxs__last-items">
                            <div className="check-radio flex-1">
                                <div className="checkbox" />
                                <div className="item-checkbox flex-1">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --fourth mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                        </div>
                        <div className="boxs__last-items">
                            <div className="check-radio flex-1">
                                <div className="checkbox" />
                                <div className="item-checkbox flex-1">
                                    <div className="loading --animation --line --half" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --half mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --threefourth mt-3" />
                        </div>
                        <div className="boxs__last-items has-pro-btn">
                            <div className="status-btn loading --animation --onefifth" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --twothird mt-3" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --half mt-3" />
                        </div>
                        <div className="boxs__last-items has-pro-btn">
                            <div className="status-btn loading --animation --onefifth" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --fourth mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --half mt-3" />
                        </div>
                        <div className="boxs__last-items has-pro-btn">
                            <div className="status-btn loading --animation --onefifth" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --twothird mt-3" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --half mt-3" />
                        </div>
                        <div className="boxs__last-items has-pro-btn">
                            <div className="status-btn loading --animation --onefifth" />
                        </div>
                    </div>
                    <div className="boxs">
                        <div className="boxs__header">
                            <div className="btn-x-sm">
                                <div className="loading --animation --line --full" />
                            </div>
                            <div className="loading --animation v2-btn-default --transparent --icon-lg" />
                        </div>
                        <div className="boxs__contents">
                            <div className="loading --animation --line --twothird mt-2" />
                            <div className="loading --animation --line --full mt-3" />
                            <div className="loading --animation --line --onefifth mt-3" />
                        </div>
                        <div className="boxs__last-items has-pro-btn">
                            <div className="status-btn loading --animation --onefifth" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingListAddons;
