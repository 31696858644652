import React, { useRef, useEffect } from 'react';
import ItemConversation from './ItemConversation';

const BodyConversation = ({ data = [] }) => {
    const refContent = useRef(null);

    useEffect(() => {
        if (refContent.current) {
            const { scrollHeight, clientHeight } = refContent.current;
            if (scrollHeight > clientHeight) {
                refContent.current.scrollTop = scrollHeight;
            }
        }
    }, [data]);

    const _renderContent = () => {
        return data.map((item) => <ItemConversation key={item.id} item={item} />);
    };

    return (
        <div ref={refContent} className="message-contents">
            {_renderContent()}
        </div>
    );
};

export default BodyConversation;
