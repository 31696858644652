import React, { Fragment, useEffect } from 'react';

import { addBranchPath } from 'app/const/Branch';
import { SETTINGS_PLANS } from 'app/const/Route';
import UpgradeSuccess from '../components/UpgradeSuccess';
import MainHeaderSettings from '../../components/MainHeaderSettings';

const ThanksPage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.location.href = addBranchPath(SETTINGS_PLANS);
        }, 5000);
    }, []);

    return (
        <Fragment>
            <MainHeaderSettings />
            <div className="wrapper-columns">
                <UpgradeSuccess />
            </div>
        </Fragment>
    );
};

export default ThanksPage;
