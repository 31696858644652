export default function IconCheck({ isActive = false, isActiveColor = false }) {
    if (isActive)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 3H8C8.55228 3 9 3.44772 9 4V8C9 8.55228 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V15C15 16.1046 14.1046 17 13 17H5C3.89543 17 3 16.1046 3 15V5C3 3.89543 3.89543 3 5 3Z"
                    fill="#C1C9D4"
                />{' '}
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7071 3.70711L14.2929 6.29289C14.6834 6.68342 14.6834 7.31658 14.2929 7.70711C14.1054 7.89464 13.851 8 13.5858 8H11C10.4477 8 10 7.55229 10 7V4.41422C10 3.86193 10.4477 3.41422 11 3.41422C11.2652 3.41422 11.5196 3.51957 11.7071 3.70711Z"
                    fill="#7A83A6"
                />{' '}
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />{' '}
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="#7A83A6"
                    strokeWidth={2}
                    strokeLinecap="round"
                />{' '}
            </svg>
        );
    if (isActiveColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 3H8C8.55228 3 9 3.44772 9 4V8C9 8.55228 9.44772 9 10 9H14C14.5523 9 15 9.44772 15 10V15C15 16.1046 14.1046 17 13 17H5C3.89543 17 3 16.1046 3 15V5C3 3.89543 3.89543 3 5 3Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.7071 3.70711L14.2929 6.29289C14.6834 6.68342 14.6834 7.31658 14.2929 7.70711C14.1054 7.89464 13.851 8 13.5858 8H11C10.4477 8 10 7.55229 10 7V4.41422C10 3.86193 10.4477 3.41422 11 3.41422C11.2652 3.41422 11.5196 3.51957 11.7071 3.70711Z"
                    fill="#8D4AFC"
                />
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="white"
                    strokeWidth={5}
                    strokeLinecap="round"
                />
                <path
                    d="M8.43414 17.5487C8.43414 15.9551 10.3012 15 12.2306 17C14.1599 19 16.3035 16.3602 16.8247 12.628"
                    stroke="#8D4AFC"
                    strokeWidth={2}
                    strokeLinecap="round"
                />
            </svg>
        );
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 5H11C11.5523 5 12 5.44772 12 6V10C12 10.5523 12.4477 11 13 11H17C17.5523 11 18 11.4477 18 12V17C18 18.1046 17.1046 19 16 19H8C6.89543 19 6 18.1046 6 17V7C6 5.89543 6.89543 5 8 5Z"
                fill="#C1C9D4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7071 5.70711L17.2929 8.29289C17.6834 8.68342 17.6834 9.31658 17.2929 9.70711C17.1054 9.89464 16.851 10 16.5858 10H14C13.4477 10 13 9.55229 13 9V6.41422C13 5.86193 13.4477 5.41422 14 5.41422C14.2652 5.41422 14.5196 5.51957 14.7071 5.70711Z"
                fill="#7A83A6"
            />
        </svg>
    );
}
