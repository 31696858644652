import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getRangeDateDisplay } from 'common/utils/DateUtils';
import { DATE_FORMAT } from 'app/const/App';

function SelectRangeOptions({ data = [], i18Value, activeRange = null, onClick = () => {} }) {
    const { t } = useTranslation(i18Value);

    const uniqueDateRanges = new Set();

    const filteredOptions = data.filter(({ date_range }) => {
        const formatDateRange = (date) => moment(date).format(DATE_FORMAT);
        const dateRangeString = `${formatDateRange(date_range.start)}-${formatDateRange(date_range.end)}`;
        if (uniqueDateRanges.has(dateRangeString)) return false;
        uniqueDateRanges.add(dateRangeString);
        return true;
    });

    return (
        <div className="filter-range scrolls">
            {filteredOptions.map((option) => {
                const { value: currentValue, date_range: dateRange, name } = option;

                const compareRangeStart = moment(dateRange?.start).isSame(activeRange?.startDate, 'day');
                const compareRangeEnd = moment(dateRange?.end).isSame(activeRange?.endDate, 'day');

                return (
                    <div
                        key={currentValue}
                        className={classNames('filter-range__date', {
                            selected: compareRangeStart && compareRangeEnd
                        })}
                        onClick={() => onClick(currentValue)}
                    >
                        <p className="range-label">{t(name)}</p>
                        <p className="range-day">{getRangeDateDisplay(currentValue)}</p>
                    </div>
                );
            })}
        </div>
    );
}

SelectRangeOptions.propTypes = {
    data: PropTypes.array,
    i18Value: PropTypes.string,
    activeRange: PropTypes.shape({}),
    onClick: PropTypes.func
};

export default React.memo(SelectRangeOptions);
