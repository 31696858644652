export const TYPE_CONVERSATION = {
    SMS_CONVERSATION_GET_TOTAL_REQUEST: 'SMS_CONVERSATION_GET_TOTAL_REQUEST',
    SMS_GET_LIST_CONVERSATION_REQUEST: 'SMS_GET_LIST_CONVERSATION_REQUEST',
    SMS_CONVERSATION_UPDATE_STATUS_REQUEST: 'SMS_CONVERSATION_UPDATE_STATUS_REQUEST'
};

export const smsConversationGetTotal = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_CONVERSATION.SMS_CONVERSATION_GET_TOTAL_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};
