import moment from 'moment';
import React, { Fragment, useReducer, useRef, forwardRef, useImperativeHandle } from 'react';

import DateRangePicker from 'app/components/daterange';
import { DATE_FORMAT } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconFuture from 'assets/icon/IconFuture';
import { DEFAULT_SELECT_DATE_WIDGET, LIST_OPTIONS_SELECT_DATE_WIDGET, TYPE_PICKER } from '../../const';
import MultiSelect from 'app/components/select/multiSelect';

const WidgetDatePicker = (
    {
        keyWidget = 'widget',
        isShowIcon = false,
        typePicker = TYPE_PICKER.RANGE,
        selectDefault = DEFAULT_SELECT_DATE_WIDGET[typePicker],
        numberFutureYear = 0,
        numberDefaultYear = 5,
        onSelect = () => {}
    },
    ref
) => {
    const refDropdown = useRef(null);
    const refSelected = useRef(selectDefault);
    const refIsChanged = useRef(false);

    const [state, dispatchState] = useReducer(reducer, {
        selected: selectDefault
    });

    const { selected: finalSelected } = state;
    const finalKey = `widget_date_${keyWidget}`;

    useImperativeHandle(ref, () => ({
        _setToggleYear
    }));

    const _setToggleYear = ({ year, isHidden, listLabelYear = [] }) => {
        const fnFilter = (item, year) => item.toString() !== year.toString();
        dispatchState((prev) => ({
            ...prev,
            selected:
                prev.selected.length === 0 && isHidden
                    ? listLabelYear.filter((item) => fnFilter(item, year))
                    : isHidden
                    ? (prev.selected === -1 ? listLabelYear : prev.selected).filter((item) => fnFilter(item, year))
                    : [...prev.selected, +year]
        }));
    };

    const _handleSelectedYear = (value) => {
        dispatchState({ selected: value });
        onSelect({ year: value });
    };

    const _handleSelectDateRange = (value) => {
        const newValue = { start: moment(value.start).format(DATE_FORMAT), end: moment(value.end).format(DATE_FORMAT) };
        onSelect(newValue);
        dispatchState((prev) => ({ ...prev, selected: { ...newValue } }));
    };

    const _handleSelect = (name, newList) => {
        refSelected.current = newList;
        refIsChanged.current = true;
        dispatchState({ selected: newList });
    };

    const _handleClose = () => {
        if (refIsChanged.current) {
            onSelect(refSelected.current);
            refIsChanged.current = false;
        }
    };

    const _renderDateRange = () => {
        return (
            <DateRangePicker
                key={finalKey}
                id={finalKey}
                name={'widget'}
                i18Text={'report'}
                handleSelect={_handleSelectDateRange}
                selectDefault={{
                    startDate: moment(finalSelected.start)._d,
                    endDate: moment(finalSelected.end)._d
                }}
                isNoRefresh
                monthsShown={1}
                isShowLabel
                isUseThisDate
                listOptionsDateDefault={LIST_OPTIONS_SELECT_DATE_WIDGET}
            />
        );
    };

    const _optionYears = () => {
        const startYear = DEFAULT_SELECT_DATE_WIDGET[TYPE_PICKER.YEAR] + numberFutureYear;
        const options = [];

        let i = 0;
        while (i < numberDefaultYear) {
            const value = startYear - i;
            options.push({
                id: value,
                name: value
            });
            i++;
        }
        return options;
    };

    const _renderSelectYear = () => {
        return (
            <CalendarDropdown
                ref={refDropdown}
                id={`widget_${keyWidget}_date_picker`}
                customDropButton={() => (
                    <Fragment>
                        <div className="dropbtn__label">
                            {isShowIcon && <IconFuture />}
                            <div className="txt-ellipsis">{finalSelected}</div>
                        </div>
                        <div className="arrow">
                            <IconArrowDown />
                        </div>
                    </Fragment>
                )}
                wrapperListClass="v2-dropdown__menu content-full scrolls"
                buttonClassName="dropbtn v2-btn-default has-icon items"
                options={_optionYears()}
                keyGetKey="id"
                keyGetValue="name"
                keyGetName="name"
                onSelect={_handleSelectedYear}
                selectedOption={{ id: finalSelected }}
                isUseToggle
            />
        );
    };

    const _renderSelectYears = () => {
        const length = finalSelected?.length;
        const showBarge = length > 1 || finalSelected === -1;
        const title = length === 1 ? finalSelected[0] : showBarge ? 'years' : 'year';
        return (
            <MultiSelect
                id={`widget_schedules_${keyWidget}`}
                name={keyWidget}
                options={_optionYears()}
                selected={finalSelected}
                onSelect={_handleSelect}
                fileTranslation="report"
                titleSelectAllOption="all"
                title={title?.toString()}
                onCloseSelect={_handleClose}
                isShowBarge={showBarge}
            />
        );
    };

    const _renderDropdown = () => {
        switch (typePicker) {
            case TYPE_PICKER.RANGE:
                return _renderDateRange();

            case TYPE_PICKER.YEAR:
                return _renderSelectYear();

            case TYPE_PICKER.YEARS:
                return _renderSelectYears();

            default:
                return null;
        }
    };

    return _renderDropdown();
};

export default forwardRef(WidgetDatePicker);
