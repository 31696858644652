import React, { forwardRef, useContext, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';

import { getUrlSubscriptionRetrieve } from 'app/const/Api';
import { clientQuery } from 'common/utils/ApiUtils';
import { getCustomerPaymentList } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_CURRENCY } from 'app/const/Settings';
import { CustomerDetailContext } from 'app/modules/customer/detail/context/CustomerDetailContext';
import AlertAddSubscription from '../add/components/AlertAddSubscription';
import BillingContent from '../add/components/BillingContent';
import PlansContent from '../add/components/PlansContent';
import SubscriptionProvider, { initialStateSubscription, SubscriptionContext } from '../context/SubscriptionContext';
import HeaderModalEdit from './components/HeaderModalEdit';
import LoadingBody from './components/LoadingBody';

const EditSubscription = forwardRef((props, ref) => {
    const { customer: customerData, payments } = useContext(CustomerDetailContext) || {};
    const { typeSubscription, _handleSetContext } = useContext(SubscriptionContext);
    const { t } = useTranslation(['report']);
    const [state, dispatchState] = useReducer(reducer, {
        isOpen: false,
        isLoading: true,
        customer: customerData || {},
        payment: {},
        isCustomer: true,
        listPayment: payments || []
    });
    const { customer, payment, listPayment } = state;

    useImperativeHandle(ref, () => ({ _open, _close }));

    const _open = (customer, subscription, isReport) => {
        dispatchState({ isOpen: true });
        _handleGetSubscription(customer, subscription);
        isReport && _handleGetPayments(parseInt(customer.id));
    };

    const _close = () => {
        dispatchState({ isOpen: false, isLoading: true, isCustomer: true });
        _handleSetContext(initialStateSubscription);
    };

    const _handleGetPayments = (customerId) => {
        if (customerId) {
            const _handleGetSuccess = ({ data }) => {
                dispatchState((prev) => {
                    return {
                        ...prev,
                        listPayment: data
                    };
                });
            };

            clientQuery(getCustomerPaymentList(customerId), { data: {}, method: 'GET' }, _handleGetSuccess);
        }
    };

    const _handleGetSubscription = (customer, subscription) => {
        const _handleSuccess = ({ data }) => {
            dispatchState((prevState) => {
                const payment = data.payment_method;
                return {
                    ...prevState,
                    isLoading: false,
                    customer: {
                        ...(prevState.customer || {}),
                        ...(data.customer || {}),
                        account_customer_id: data.account_customer_id,
                        email: data.email
                    },
                    payment
                };
            });
            _handleSetContext({
                isEditing: true,
                subscription_id: subscription.subscription_id,
                selectedPlans: data.plans,
                tax: data.tax,
                billing: data.billing,
                billingNumber: data.days_until_due,
                typePlanSelect: {
                    currency: data.currency.toUpperCase() || LIST_CURRENCY[0].value,
                    interval: data.interval,
                    interval_count: data.interval_count,
                    symbol: '$'
                },
                end_date: data.end_date,
                customerId: data.customer.id,
                trial_end: data.start_date
            });
        };

        const _handleFailed = (response) => {
            alert(response?.message || t('report:card_no_longer_available'));
            _close();
        };

        clientQuery(
            getUrlSubscriptionRetrieve(customer.id),
            {
                data: {
                    subscription_id: subscription.subscription_id,
                    account_customer_id:
                        customer.merchant_customer_id?.[typeSubscription] || customer.account_customer_id,
                    merchant: typeSubscription === 'square' ? 2 : 1
                }
            },
            _handleSuccess,
            _handleFailed
        );
    };

    const _handleSetCardByCardId = (cardId, paymentMethod) => {
        dispatchState((prev) => ({
            ...prev,
            payment: paymentMethod.find((item) => item.account_id === cardId)
        }));
    };

    function _renderNotFoundCustomer(response) {
        return (
            <div className="user-info">
                <div className="flexinline-center">
                    <div className="name">{response?.message || t('report:card_no_longer_available')}</div>
                </div>
            </div>
        );
    }

    return (
        <ReactModal
            id="add_subscription"
            isOpen={state.isOpen}
            style={{ overlay: { zIndex: 99, background: 'transparent' } }}
            className="modal container-modal form-stripe-subscription --form-main open"
            onRequestClose={_close}
        >
            <div className="modal__overlay bg-fixed" onClick={_close} />
            <div className="modal__container">
                <HeaderModalEdit payment={payment} onClose={_close} />
                {state.isLoading ? (
                    <LoadingBody />
                ) : (
                    <div className="body-modal scrolls">
                        {state.isCustomer ? (
                            <>
                                <AlertAddSubscription />
                                <div className="user-info">
                                    <div className="flexinline-center">
                                        <span className="avt fs-12">{customer.avatar}</span>
                                        <div className="name">{customer.full_name}</div>
                                    </div>
                                    {customer.email && <span className="info dots">{customer.email}</span>}
                                    <span className="info dots">
                                        {customer?.merchant_customer_id?.[typeSubscription] ||
                                            customer.account_customer_id}
                                    </span>
                                </div>
                                <PlansContent customerId={customer.id} isEdit />
                                <BillingContent
                                    payments={listPayment}
                                    paymentDefault={payment}
                                    getDefaultPayment={_handleSetCardByCardId}
                                />
                            </>
                        ) : (
                            _renderNotFoundCustomer()
                        )}
                    </div>
                )}
            </div>
        </ReactModal>
    );
});

const WrapperEditSubscription = forwardRef(({ customer = {}, typeSubscription, onEditSuccess = () => {} }, ref) => {
    const refModal = useRef(null);
    useImperativeHandle(ref, () => ({
        _open: (customer, data, isReport) => refModal.current._open(customer, data, isReport),
        _close: () => refModal.current._close()
    }));

    return (
        <SubscriptionProvider
            customerId={customer.id}
            typeSubscription={typeSubscription}
            onEditSuccess={onEditSuccess}
        >
            <EditSubscription ref={refModal} customer={customer} />
        </SubscriptionProvider>
    );
});

export default WrapperEditSubscription;
