import React, { useReducer, useRef, useEffect } from 'react';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import IconPlus from 'assets/icon/IconPlus';
import classNames from 'classnames';
import { reducer } from 'app/const/Reducer';

const OptionsEmailTo = ({ onClickCC, onClickBCC, emailCC, emailBCC }) => {
    const [state, setState] = useReducer(reducer, {
        isVisible: false
    });

    const finalIsVisible = state.isVisible;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_list_option_cc_bcc');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_list_option_cc_bcc');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    function _handleOpen(e) {
        e.stopPropagation();
        setState({ isVisible: !finalIsVisible });
    }

    function _handleClickCC() {
        _closeSearchResult();
        onClickCC();
    }

    function _handleClickBCC() {
        _closeSearchResult();
        onClickBCC();
    }

    return (
        <div
            className={classNames('v2-dropdown --add-email', {
                active: finalIsVisible,
                'is-disable': emailCC && emailBCC
            })}
            ref={refDropdown}
        >
            <div className="dropbtn v2-btn-default --icon-lg" onClick={_handleOpen}>
                <IconPlus />
            </div>
            <div className="v2-dropdown__menu scrolls" id={'show_list_option_cc_bcc'}>
                <ul>
                    {!emailCC && (
                        <li onClick={_handleClickCC} className="items js-toggle-cc-email">
                            <div className="txt-ellipsis">Add CC Email</div>
                        </li>
                    )}
                    {!emailBCC && (
                        <li onClick={_handleClickBCC} className="items js-add-bcc-email">
                            <div className="txt-ellipsis">Add BCC Email</div>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default OptionsEmailTo;
