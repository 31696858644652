export default function IconItalic() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.8305 18.4996L13.5 18.4994H7.5L8.78242 18.4997C9.76971 18.4999 10.6094 17.7796 10.7595 16.8038L12.1441 7.80411C12.3121 6.71239 11.5632 5.69121 10.4715 5.52326C10.3708 5.50777 10.2692 5.5 10.1674 5.5H9.5H15.5H14.2157C13.2286 5.5 12.3891 6.22011 12.2389 7.19568L10.8534 16.1952C10.6853 17.2869 11.4341 18.3082 12.5258 18.4763C12.6266 18.4918 12.7285 18.4996 12.8305 18.4996Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
