import { URL_EXPORT_TOTAL_SALES } from 'app/const/api/Export';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { LIST_EXPORT } from 'app/const/report/Common';
import { TOTAL_SALES_LIST_FILTER } from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { getGidColumns } from 'app/const/report/TotalSales';
import {
    getDataChartTotalSales,
    getListReportTotalSales,
    getListYearTotalSales
} from 'common/redux/actions/reports/totalSalesAction';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { reducer } from 'app/const/Reducer';
import loadable from '@loadable/component';
import { getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdGridRowTotal = loadable(() => import('app/components/grid/GdGridRowTotal'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportBarChart = loadable(() => import('../components/ReportBarChart'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));

function ReportTotalSales() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const companyUsers = useSelector((state) => state.companyUsers.users || []);
    const parentChartRef = useRef();
    const childRef = useRef();
    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        data: [],
        totalPage: 1,
        rowTotal: [],
        isLoading: true,
        total: 0,
        refreshScreen: 0
    });
    const { refreshScreen } = dataReport;
    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.TOTAL_SALES);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.TOTAL_SALES);

    useEffect(() => {
        _reloadScreen();
    }, []);

    const _reloadScreen = () => {
        getListReport(paramsReport);
    };

    const _handleClickInvoice = ({ row }) => {
        dispatch(
            actionOpenInvoice({
                id: row.invoice_id,
                status: row.status,
                isRecurring: false,
                onDeleteSuccess: _reloadScreen
            })
        );
    };

    const getListReport = (params) => {
        dispatchActionReport({ isLoading: true });
        params = getNewAPIRequest(params);
        delete params?.customer_id;

        dispatch(getListReportTotalSales(params, (response) => getListSuccess(response, params), getListFailed));
    };

    const getNewAPIRequest = (params) => {
        params.client = params?.customer_id;
        params.order = params.order.replace('invoice_number', 'invoice_id');
        return params;
    };

    const getListSuccess = (response) => {
        const dataReducer = {};
        dataReducer.isLoading = false;
        dataReducer.data = response.data;
        dataReducer.total = dataReducer.data.length;
        dataReducer.rowTotal = handleCreateRowTotal(
            response['total_amount'],
            getGidColumns().columns,
            dataReducer.data.length
        );
        dataReducer.refreshScreen = refreshScreen + 1;
        dispatchActionReport(dataReducer);
    };

    const handleCreateRowTotal = (data, columns, total) => {
        const totalColumns = columns.map((column) => {
            if (typeof data[column.id] !== 'undefined') {
                return { id: column.id, isShow: true, totalAmount: data[column.id], isCurrency: true };
            }
            return { id: column.id, isShow: true };
        });
        totalColumns[0].title = `${t('report:total')} ${total ?? dataReport.total} ${t('report:total_sales')}`;
        return totalColumns;
    };

    function getListFailed() {
        dispatchActionReport({ isLoading: false });
    }

    const _handleUpdate = () => {
        const params = getLocalStorage(keyLocal);
        getListReport(params);
    };

    const handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.TOTAL_SALES,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    function _renderHeaderRight() {
        return (
            <Export
                title={t('report:records', { count: dataReport.total })}
                activePrint
                url={URL_EXPORT_TOTAL_SALES}
                params={paramsReport}
                refresh={refreshScreen}
                isDisable={dataReport.isLoading}
                pageExport={LIST_EXPORT.EXPORT_REPORT_TOTAL_SALES}
            />
        );
    }

    function _renderHeaderLeft() {
        return (
            <HeaderBottom
                typeReport={REPORT_TYPE.TOTAL_SALES}
                filters={TOTAL_SALES_LIST_FILTER}
                companyUsers={companyUsers}
                isLoading={dataReport.isLoading}
                handleUpdate={_handleUpdate}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.TOTAL_SALES}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns has-chart has-total pr-0">
                <div className="container-print contents-pages new-customer gap-8" ref={parentChartRef}>
                    <div className="wrap-border" ref={childRef}>
                        <ReportBarChart
                            getDataAPI={getDataChartTotalSales}
                            getListYearAPI={getListYearTotalSales}
                            title={t('report:year_to_year_total_sales')}
                            titleColumnTotal={t('report:total_sales')}
                            isGetTotal
                        />
                    </div>
                    <div className="wrap-tables flex-column">
                        <GdGridView
                            isEmptyFlat
                            isLoading={dataReport.isLoading}
                            classTable="table-multi-column scrolls-x has-text-ellipsis"
                            classTableContent="--hastotal"
                            content={dataReport.data}
                            fileTranslation={'report'}
                            handleClick={_handleClickInvoice}
                            handleClickHeader={handleActionHeader}
                            rowTotal={(props) => (
                                <GdGridRowTotal
                                    columns={dataReport.rowTotal}
                                    contentConfig={getGidColumns()?.contentConfig}
                                    showCheckBox={false}
                                    {...props}
                                />
                            )}
                            offsetHeightChild={childRef.current?.offsetHeight}
                            parentRefProps={parentChartRef}
                            {...getGidColumns(paramsReport?.order)}
                            overscanItems={7}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportTotalSales;
