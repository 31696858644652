import { call, put } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';
import { AUTH_TYPE, actionUpdateJobStatus } from 'common/redux/actions/authAction';
import { ADDONS_EMAIL_DETAIL, CUSTOM_JOB_STATUS_LIST } from 'app/const/Api';

export function* userGetSignature({ payload }) {
    const response = yield call(fetchWithToken, ADDONS_EMAIL_DETAIL, payload, put);
    if (response?.success) {
        yield put({ type: AUTH_TYPE.GET_EMAIL_INBOX_SIGNATURE_SUCCESS, payload: response });
    }
}

export function* refreshListJobStatus({ payload }) {
    const { callback } = payload;
    const response = yield call(fetchWithToken, CUSTOM_JOB_STATUS_LIST, put);

    if (response?.success) {
        yield put(actionUpdateJobStatus(response.data));
    }

    callback && callback();
}
