import React, { Fragment, Suspense, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { addNewBookMarkAction } from 'common/redux/actions/calendar/bookMarkAction';
import moment from 'moment';
import ReactModal from 'react-modal';
import { LIST_STATUS } from 'app/const/Status';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';
import InvoiceService from 'app/modules/invoice/service';
import { useEnterKeydownClick } from 'common/hooks';
import InvoiceImages from '../jobdetail/tabs/invoice/InvoiceImages';
import { TYPES_PHOTO } from '../jobdetail/const/Images';
import InsertImageInvoice from './InsertImages';

const HeaderModal = loadable(() => import('./HeaderModal'));
const HeaderRecurring = loadable(() => import('app/modules/invoice/Header'));
const CustomerInfo = loadable(() => import('app/modules/jobdetail/tabs/invoice/CustomerInfo'));
const InvoiceItem = loadable(() => import('app/modules/jobdetail/tabs/invoice/item'));
const Summary = loadable(() => import('app/modules/jobdetail/tabs/invoice/summary'));
const Terms = loadable(() => import('app/modules/jobdetail/tabs/invoice/terms'));
const Notes = loadable(() => import('app/modules/jobdetail/tabs/invoice/notes'));
const InvoiceSignature = loadable(() => import('app/modules/jobdetail/tabs/invoice/signature'));
const InvoiceHistory = loadable(() => import('app/modules/jobdetail/tabs/invoice/history'));
const InvoiceDetailContextProvider = loadable(() => import('app/modules/jobdetail/contexts/InvoiceDetailContext'));
const Header = loadable(() => import('app/modules/jobdetail/tabs/invoice/header'));
const StatusBar = loadable(() => import('app/components/status/statusbar'));
const ServiceInvoice = loadable(() => import('app/modules/jobdetail/tabs/invoice/service'));
const Insert = loadable(() => import('app/modules/invoice/Insert'));

export default function InvoiceDetail({ invoiceType = 0 }) {
    const [state, dispatchState] = useReducer(reducer, {
        isVisible: false,
        invoiceData: null,
        reloadInvoice: 0
    });

    const dispatch = useDispatch();
    const { t } = useTranslation(['customers']);
    const refStatusBar = useRef(null);

    const { invoiceData: finalInvoiceData, reloadInvoice, isVisible } = state;

    const {
        onUpdateSuccess,
        onDeleteSuccess,
        isRecurring: isInvoiceRecurring,
        onEditSuccess = () => {},
        onTriggerUpdated = () => {}
    } = finalInvoiceData || {};

    useEnterKeydownClick(isVisible);

    function _handleOpenInvoice(invoiceData) {
        dispatchState({
            isVisible: !!invoiceData,
            invoiceData,
            reloadInvoice: Date.now()
        });
    }

    function _handleClose(e) {
        e && e.stopPropagation();

        dispatchState({
            isVisible: false,
            invoiceData: null
        });
        dispatch(actionOpenInvoice(null));
    }

    function _handleColInvoice(invoiceDetail) {
        dispatch(
            addNewBookMarkAction({
                type: 'invoice_detail',
                id: moment().format('x'),
                data: {
                    id: invoiceDetail.id,
                    name: `Invoice #${invoiceDetail.number}`,
                    customer: invoiceDetail.customer,
                    oldData: state.invoiceData
                }
            })
        );
        _handleClose();
    }

    function _handleUpdateInvoice(status, total, recurrence) {
        onUpdateSuccess && onUpdateSuccess(status, total, recurrence);
    }

    function _handleUpdateSuccess() {
        onEditSuccess();
        refStatusBar.current.showStatusBar('show_success', t('update_invoice_success'), LIST_STATUS.SUCCESS);
    }

    function _handleDelete() {
        _handleClose();
        onDeleteSuccess && onDeleteSuccess();
    }

    function _renderInvoiceDetail() {
        if (!isVisible) {
            return false;
        }

        return (
            <ReactModal
                isOpen
                onRequestClose={_handleClose}
                ariaHideApp={false}
                shouldCloseOnEsc
                style={{ overlay: { zIndex: 99999, background: 'transparent' } }}
                className="modal container-modal c-job-details open"
            >
                <Suspense fallback>
                    <div className="modal__overlay bg-fixed" />
                    <div className="modal__container --preview-r has-existing-notes">
                        <InvoiceDetailContextProvider
                            invoiceData={finalInvoiceData}
                            isActive
                            invoiceType={invoiceType}
                            onUpdate={_handleUpdateInvoice}
                            onClose={_handleClose}
                            isInvoiceRecurring={isInvoiceRecurring}
                            onEditSuccess={_handleUpdateSuccess}
                            isOpenWithJob={false}
                            onDelete={_handleDelete}
                            reloadInvoice={reloadInvoice}
                            onTriggerUpdated={onTriggerUpdated}
                        >
                            <HeaderModal onCol={_handleColInvoice} onClose={_handleClose} />
                            <div className="body-modal scrolls">
                                <div className="tab-conts form-tabpane-details" id="tab_inoive_detail_setting">
                                    <StatusBar ref={refStatusBar} />
                                    <div className="container-column form-has-content">
                                        {isInvoiceRecurring ? <HeaderRecurring /> : <Header isInvoice />}
                                        <CustomerInfo isJobDetail={false} />
                                        <InvoiceItem />
                                        <Summary />
                                        <div className="content-elm rows note-details">
                                            <div className="dashboard-wrapper --main">
                                                <div className="content-top-notes">
                                                    <Terms />
                                                    <Notes />
                                                    {!isInvoiceRecurring && (
                                                        <InvoiceImages parentType={TYPES_PHOTO.INVOICE} />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {!isInvoiceRecurring && (
                                            <div className="content-elm rows box-signature is-signed">
                                                <InvoiceSignature />
                                            </div>
                                        )}
                                        <InvoiceHistory
                                            reloadInvoice={reloadInvoice}
                                            isInvoiceRecurring={isInvoiceRecurring}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Insert />
                            <InsertImageInvoice />
                            <ServiceInvoice isInvoice parentId={finalInvoiceData?.id} />
                        </InvoiceDetailContextProvider>
                    </div>
                </Suspense>
            </ReactModal>
        );
    }

    return (
        <Fragment>
            {_renderInvoiceDetail()}
            <InvoiceService onOpenInvoiceDetail={_handleOpenInvoice} />
        </Fragment>
    );
}
