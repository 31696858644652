import React, { useContext, useRef } from 'react';
import { SetupEmailContext } from '../../context/SetupEmailContext';

const EmailChoose = () => {
    const { email, isAutomatically, changeEmail, setAutomatically } = useContext(SetupEmailContext);
    const refInput = useRef(null);

    const _handleChange = (e) => setAutomatically(e.target.checked);

    return (
        <>
            <div className="row --email-address">
                <span className="txt">Work email address:</span>
                <div className="label-email">{email}</div>
                <div className="change-email" onClick={changeEmail}>
                    Change
                </div>
            </div>
            <div className="row --recommended">
                <span className="txt">* Highly Recommended</span>
                <div className="check-items">
                    <input
                        ref={refInput}
                        id="setup_recommend"
                        type="checkbox"
                        onChange={_handleChange}
                        checked={isAutomatically}
                    />
                    <div className="item-checkbox">
                        <label htmlFor="setup_recommend">Log incoming &amp; outgoging emails automatically</label>
                    </div>
                </div>
                <p className="fs-12 btn-hover-black">
                    Tracks all emails with your sales contacts and shares them with coworkers in GorillaDesk
                </p>
            </div>
        </>
    );
};

export default EmailChoose;
