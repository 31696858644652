import React, { useEffect, useReducer } from 'react';

import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import IconClose from 'assets/icon/IconClose';
import { TRACKING_ALERT_EVENT } from '../constant';

const TrackerAlert = () => {
    const initialState = { isDisplayAlert: false, notification: '', type: LIST_STATUS.ERROR, timeout: 0 };
    const [state, dispatchState] = useReducer(reducer, initialState);
    const { isDisplayAlert, notification, timeout, type } = state;

    const _handleHideAlert = () => {
        dispatchState(() => initialState);
    };

    const _handleDisplayAlert = (event) => {
        const { notification, timeout = 3000, type = LIST_STATUS.ERROR } = event.detail || {};
        if (notification && notification.length)
            dispatchState(() => ({ isDisplayAlert: true, notification, timeout, type }));
    };

    useEffect(() => {
        addEventListener(TRACKING_ALERT_EVENT, _handleDisplayAlert);
        return () => {
            removeEventListener(TRACKING_ALERT_EVENT, _handleDisplayAlert);
        };
    }, []);

    useEffect(() => {
        let timer = null;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            dispatchState((state) => ({ ...state, isDisplayAlert: false }));
        }, timeout);
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [isDisplayAlert]);

    if (!isDisplayAlert) return null;
    return (
        <div className={`alert --${type} m-0`} style={{ maxWidth: 700 }}>
            <p className="alert__description">{notification}</p>
            <div className="alert__btn svg-white-stroke" onClick={_handleHideAlert}>
                <IconClose isSmall />
            </div>
        </div>
    );
};

export default TrackerAlert;
