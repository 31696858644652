import classNames from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import IconEmail from 'assets/icon/IconEmail';
import { useSmartView } from '../../context';
import SetLimitSmartView from '../SetLimit';
import BulkEmail from './BulkEmail';
import Loading from './Loading';
import PrintButton from './PrintButton';
import SaveAsButton from './SaveAsButton';
import CallButton from './CallButton';

const HeaderSmartView = ({ isLoading = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { data, loading, isSupperAdmin, name, isHaveFilter } = useSmartView();
    const refBulkEmailModal = useRef(null);
    const isDisable = !data.length || loading;

    const _handleBulkEmail = () => {
        refBulkEmailModal.current?.open();
    };

    if (isLoading) return <Loading />;
    return (
        <>
            <div className="nav-top header flex-betweenitems --smart-views">
                <div className="header__left flex-1">
                    <div className="header-items flexcenter fw-500 gap-4">
                        <SetLimitSmartView />
                    </div>
                </div>
                <CallButton smartId={id} smartName={name} isDisable={isDisable || !isHaveFilter} />

                <div
                    className={classNames('header-items v2-btn-default has-icon tooltip', { 'is-disable': isDisable })}
                    onClick={_handleBulkEmail}
                >
                    <IconEmail />
                    {t('email')}
                    <span className="tooltiptext top">{t('smartView:send_bulk_email')}</span>
                </div>
                <PrintButton isDisable={isDisable} />
                {isSupperAdmin ? <SaveAsButton /> : null}
            </div>
            <BulkEmail ref={refBulkEmailModal} />
        </>
    );
};

export default HeaderSmartView;
