import { API_VERSION } from 'app/const/URL';

export const getJobTodo = (jobId) => `${API_VERSION}jobs/${jobId}/todos`;

export const GET_LIST_TEMPLATE = `${API_VERSION}templates/todos`;

export const getCheckListImTemplate = (templateId) => `${API_VERSION}templates/todos/${templateId}/items`;

export const CREATE_TEMPLATE = `${API_VERSION}templates/todos`;

export const updateTodoTemplate = (templateId) => `${API_VERSION}templates/todos/${templateId}`;

export const createCheckList = (jobId) => `${API_VERSION}jobs/${jobId}/todos`;

export const moveCheckList = (jobId) => `${API_VERSION}jobs/${jobId}/todos/orders`;

export const actionCheckList = (jobId, checklistId) => `${API_VERSION}jobs/${jobId}/todos/${checklistId}`;

export const actionCheckListStatus = (jobId, checklistId) => `${API_VERSION}jobs/${jobId}/todos/${checklistId}/status`;

export const actionCopyTemplate = (jobId) => `${API_VERSION}jobs/${jobId}/todos/apply`;

export const actionSetRepeatWithJob = (jobId) => `${API_VERSION}jobs/${jobId}/todos/recurring`;
