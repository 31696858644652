import loadable from '@loadable/component';
import { URL_EXPORT_PAYMENT_REVENUES_STAFF, URL_EXPORT_REVENUES_STAFF } from 'app/const/api/Export';
import { COMMON, CURRENT_MONTH_SHORT_NAME, DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import { LIST_EXPORT } from 'app/const/report/Common';
import {
    REVENUE_BY_STAFF_LIST_FILER,
    REVENUE_BY_STAFF_TABS,
    SALE,
    TOTAL_COLLECTED
} from 'app/const/report/ReportFilter';
import { REPORT_TYPE } from 'app/const/report/ReportTypeContent';
import { DetailTabGridView, handleExportDetailTab } from 'app/modules/report/revenuebystaff/components/DetailsTab';
import { handleExportMonthTab, MonthTabGridView } from 'app/modules/report/revenuebystaff/components/MouthTab';
import { actionOpenInvoice } from 'common/redux/actions/invoiceAction';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import moment from 'moment';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MainHeaderReport from 'app/modules/report/components/MainHeader';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import {
    convertParamFields,
    getListRevenueSuccess,
    getLocalParamsReport,
    getNewListFilterRevenue,
    initStateRevenueReport,
    removeObjParams
} from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';
import { handleAbortController } from 'app/modules/customer/utils';
import { clientQuery } from 'common/utils/ApiUtils';
import { REPORT_REVENUE_BY_STAFF_DETAIL_GET_LIST, REPORT_REVENUE_BY_STAFF_MONTH_GET_LIST } from 'app/const/Api';
import GDStatusBar from 'app/components/status/statusbar';

const Export = loadable(() => import('app/modules/report/components/Export'));
const ReportTabs = loadable(() => import('app/modules/report/components/ReportTabs'));

function ReportRevenueByStaff() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();

    const keyCurrentTab = COMMON.CURRENT_TAB.concat('_', REPORT_TYPE.REVENUE_BY_STAFF);
    const keyLocalStore = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.REVENUE_BY_STAFF);

    const MONTH_TAB = REPORT_TYPE.REVENUE_BY_STAFF_MONTH_TAB;
    const DETAIL_TAB = REPORT_TYPE.REVENUE_BY_STAFF_DETAIL_TAB;

    const keyTotalRow = {
        [MONTH_TAB]: 'total_customer',
        [DETAIL_TAB]: 'total_payments_received'
    };
    const initialState = initStateRevenueReport({ keyCurrentTab, monthTab: MONTH_TAB, detailTab: DETAIL_TAB });
    const [dataReport, dispatchActionReport] = useReducer(reducer, initialState);

    const paramsReport = getLocalParamsReport(keyLocalStore, REPORT_TYPE.REVENUE_BY_STAFF) || {};
    const abortController = useRef(null);
    const refAlert = useRef(null);
    const { currentTab, rerender, refreshScreen, isLoadMore: finalLoadMore } = dataReport;
    const finalData = dataReport[currentTab].data || [];

    useEffect(() => {
        getListReport({ ...paramsReport, offset: 0, changeTab: Date.now() });
        setLocalStorage(keyCurrentTab, currentTab);
        return () => {
            handleAbortController(abortController);
        };
    }, [currentTab]);

    useEffect(() => {
        let timeoutId = null;
        if (finalLoadMore) {
            timeoutId = setTimeout(() => getListReport({ ...paramsReport, offset: finalData.length }), DELAY_TIME);
        }
        return () => {
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [finalLoadMore]);

    useEffect(() => {
        return () => {
            removeLocalStorage(keyCurrentTab);
        };
    }, []);

    const getListReport = (params = {}) => {
        refAlert.current?.clearAllStatusBar();
        handleAbortController(abortController);
        abortController.current = new AbortController();
        !finalLoadMore &&
            !dataReport[currentTab]?.isLoading &&
            dispatchActionReport({ [currentTab]: { isLoading: true } });
        let objectParams = convertParamFields({ params, isEmptyFields: finalLoadMore, isPaymentMethods: true });

        delete objectParams?.currentPage;
        delete objectParams?.changeTab;

        switch (currentTab) {
            case MONTH_TAB:
                objectParams.order = objectParams['orderMonth'];
                objectParams = removeObjParams(objectParams, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);

                return clientQuery(
                    REPORT_REVENUE_BY_STAFF_MONTH_GET_LIST,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );
            case DETAIL_TAB:
                objectParams.order = objectParams['orderDetail'];
                objectParams = removeObjParams(objectParams, ['orderMonth', 'orderDetail', 'year', 'customer_id']);

                return clientQuery(
                    REPORT_REVENUE_BY_STAFF_DETAIL_GET_LIST,
                    { data: objectParams, abortController: abortController.current },
                    (response) => getListSuccess(response, params),
                    getListFailed
                );
            default:
                break;
        }
    };

    const getListSuccess = (response, { changeTab }) => {
        dispatchActionReport((prev) =>
            getListRevenueSuccess({
                prev,
                response,
                changeTab,
                keyTotalRow,
                monthTab: MONTH_TAB,
                titleTotalRow: 'staff'
            })
        );
    };

    function getListFailed({ isAborted = false, message }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({
            ...prev,
            [currentTab]: { ...prev[currentTab], isLoading: false },
            isLoadMore: false
        }));
        refAlert.current?.handeAddStatus({ id: 'revenue_by_staff_error', message, type: LIST_STATUS.ERROR });
    }

    const handleChangeFilter = (params, type) => {
        if (type === 'year') {
            const newParamsReport = { ...params };
            const selectDate = `${CURRENT_MONTH_SHORT_NAME} ${newParamsReport.year}`;
            newParamsReport.start = moment(selectDate).clone().startOf('month').format();
            newParamsReport.end = moment(selectDate).clone().endOf('month').format();
            setLocalStorage(keyLocalStore, newParamsReport);
        }

        if (!type) {
            dispatchActionReport({ rerender: false });
        } else if (type === SALE) {
            dispatchActionReport({ rerender: params.sale === TOTAL_COLLECTED });
        }
    };

    const _handleUpdate = () => {
        dispatchActionReport({ [currentTab]: { ...initStateRevenueReport[currentTab] } });
        getListReport(getLocalStorage(keyLocalStore) || paramsReport);
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType,
            reportType: REPORT_TYPE.REVENUE_BY_STAFF,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate,
            keyOrder: currentTab === DETAIL_TAB ? 'orderDetail' : 'orderMonth'
        });
    };

    const _getNewListFilter = () => {
        return getNewListFilterRevenue({
            listFilter: REVENUE_BY_STAFF_LIST_FILER,
            keyLocalStore,
            currentTab,
            keyCurrentTab,
            detailTab: DETAIL_TAB
        });
    };

    function _renderHeaderLeft() {
        return <ReportTabs list={REVENUE_BY_STAFF_TABS} onChange={handleChangeTab} tabActive={currentTab} />;
    }

    function _renderHeaderBottom() {
        return (
            <HeaderBottom
                classNameHeader="header --filter"
                forceRerenderFilter={rerender}
                typeReport={REPORT_TYPE.REVENUE_BY_STAFF}
                filters={_getNewListFilter()}
                handleChangeFilter={handleChangeFilter}
                handleUpdate={_handleUpdate}
                isLoading={dataReport[currentTab].isLoading}
                isNotShowDateRange={currentTab === MONTH_TAB}
            />
        );
    }

    function handleOnClickExport(e, isCsv, actionPrint) {
        e && e.preventDefault();

        switch (currentTab) {
            case MONTH_TAB:
                return handleExportMonthTab(isCsv, actionPrint, paramsReport);
            case DETAIL_TAB:
                return handleExportDetailTab(isCsv, actionPrint, paramsReport);
            default:
                break;
        }
    }

    const handleChangeTab = (tab) => {
        dispatchActionReport({ currentTab: tab, rerender: tab === MONTH_TAB });
    };

    const _handleOpenInvoice = (data) => {
        dispatch(
            actionOpenInvoice({
                id: data?.row?.invoice_id,
                status: '',
                total: '',
                isRecurring: false
            })
        );
    };

    const _handleLoadmore = () => {
        const numberData = finalData?.length;
        if (!!numberData && numberData < dataReport[currentTab].total && !finalLoadMore) {
            dispatchActionReport((prev) => ({ ...prev, isLoadMore: true }));
        }
    };

    function _getDataExport() {
        let paramsExport = { ...paramsReport };
        switch (currentTab) {
            case MONTH_TAB:
                paramsExport.order = paramsExport['orderMonth'];
                paramsExport = removeObjParams(paramsExport, [
                    'orderMonth',
                    'orderDetail',
                    'start',
                    'end',
                    'payment_for'
                ]);
                return {
                    urlExport: URL_EXPORT_REVENUES_STAFF,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF,
                    paramsExport
                };
            case DETAIL_TAB:
                paramsExport.order = paramsExport['orderDetail'];
                paramsExport = removeObjParams(paramsExport, ['orderMonth', 'orderDetail', 'year']);
                return {
                    urlExport: URL_EXPORT_PAYMENT_REVENUES_STAFF,
                    pageExport: LIST_EXPORT.EXPORT_REPORT_REVENUE_STAFF_DETAIL,
                    paramsExport
                };
            default:
                return {};
        }
    }

    const _renderGirdView = () => {
        switch (currentTab) {
            case MONTH_TAB:
                return (
                    <MonthTabGridView
                        dataReport={dataReport[MONTH_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                        monthTab={currentTab}
                        isLoadmore={finalLoadMore}
                        onScrollToEnd={_handleLoadmore}
                    />
                );
            case DETAIL_TAB:
                return (
                    <DetailTabGridView
                        dataReport={dataReport[DETAIL_TAB]}
                        paramsReport={paramsReport}
                        handleActionHeader={_handleActionHeader}
                        handleOpenInvoice={_handleOpenInvoice}
                        isLoadmore={finalLoadMore}
                        onScrollToEnd={_handleLoadmore}
                    />
                );
            default:
                break;
        }
    };

    function _renderHeaderRight() {
        const dataExport = _getDataExport();
        const totalReport = dataReport[currentTab]?.total || 0;
        return (
            <Export
                title={t('report:records', { count: totalReport })}
                activePrint
                onClick={handleOnClickExport}
                url={dataExport.urlExport}
                params={dataExport.paramsExport}
                pageExport={dataExport.pageExport}
                isDisable={dataReport[currentTab].isLoading}
                refresh={refreshScreen}
            />
        );
    }

    return (
        <>
            <MainHeaderReport
                contentLeft={_renderHeaderLeft}
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.REVENUE_BY_STAFF}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages has-tab gap-8">
                    <GDStatusBar ref={refAlert} />
                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBottom()}
                        <div className="tab-contents">{_renderGirdView()}</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportRevenueByStaff;
