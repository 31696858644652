import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { actionNewCall, triggerRealtimeCall } from 'common/redux/actions/voipAction';
import { STATUS_CALL_TIME_LINE, TYPE_CALL_TIME_LINE } from 'app/modules/customer/const/Call';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';

export default function ServiceCall({ customerId, onAddCallLog = () => {}, onTriggerCall = () => {} }) {
    const { data, newCall } = useSelector(({ voipReducer }) => voipReducer);
    const dispatch = useDispatch();
    const refVoicemailDrop = useRef({});

    useEffect(() => {
        if (newCall) {
            dispatch(actionNewCall(null));
        }
    }, [newCall]);

    useEffect(() => {
        if (data && customerId === data?.customer_id) {
            const { call_type: callType, call_status: callStatus, is_transfer: isTransferCall } = data;
            const finalCallType = isTransferCall ? TYPE_CALL_TIME_LINE.INCOMMING : callType;

            if (callStatus === -1) {
                onAddCallLog({
                    id: data.id,
                    call_id: data.parent_call_id || data.call_id,
                    call_status: STATUS_CALL_TIME_LINE.CALLED,
                    call_type: finalCallType,
                    to: data.receiver,
                    from: data.caller,
                    user_id: data.user_id
                });
            } else {
                const call_id = data.parent_call_id || data.call_id;
                onTriggerCall({
                    ...data,
                    call_id,
                    voicemail: refVoicemailDrop.current[call_id] || null
                });
            }

            dispatch(triggerRealtimeCall(null));
        }
    }, [data]);

    useEffect(() => {
        addEventListener(CUSTOMER_CUSTOM_EVENTS.VOICEMAIL_DROPPED, _handleVoicemailDropped);
        return () => {
            removeEventListener(CUSTOMER_CUSTOM_EVENTS.VOICEMAIL_DROPPED, _handleVoicemailDropped);
        };
    }, []);

    const _handleVoicemailDropped = ({ detail }) => {
        const { call_id, voicemail = null } = detail || {};
        refVoicemailDrop.current[call_id] = voicemail;
    };

    return null;
}
