import React from 'react';

const SkeletonFooter = () => {
    return (
        <div className="wrap-loading form-footer">
            <div className="form-footer__action">
                <div className="v2-btn-default loading --grey btn-md">
                    <div className="loading --animation --line --full"></div>
                </div>
            </div>
        </div>
    );
};

const SkeletonReviewBox = () => {
    return (
        <div className="wrap-loading set-user-wrap --tiles">
            <div className="w-user-infomations w-title-preview">
                <div className="col-date">
                    <div className="col-date__title">
                        <div className="loading --animation --line --onefourth"></div>
                    </div>
                </div>
                <div className="col-date">
                    <div className="col-date__title">
                        <div className="loading --animation --line --onefourth"></div>
                    </div>
                    <div className="w-service-preview bg-white border-border-color-grey">
                        <div className="events-job">
                            <div className="event-content loading-lines">
                                <div className="loading --animation --line --full"></div>
                                <div className="loading --animation --line --fifth"></div>
                                <div className="loading --animation --line --threefourth"></div>
                                <div className="loading --animation --line --fifth"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-date">
                    <div className="col-date__title">
                        <div className="loading --animation --line --onefourth"></div>
                    </div>
                </div>
                <div className="row-time">
                    <div className="time btn-x-sm loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
                <div className="row-time">
                    <div className="time btn-x-sm loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SkeletonListOptions = () => {
    return (
        <div className="set-user-wrap">
            <div className="w-user-infomations">
                <div className="wrap-loading">
                    <div className="content">
                        <div className="rows">
                            <div className="btn-drag">
                                <div className="v2-btn-default --transparent --icon-sm loading"></div>
                            </div>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                            <span className="circle loading"></span>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                        </div>
                        <div className="rows">
                            <div className="btn-drag">
                                <div className="v2-btn-default --transparent --icon-sm loading"></div>
                            </div>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                            <span className="circle loading"></span>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                        </div>
                        <div className="rows">
                            <div className="btn-drag">
                                <div className="v2-btn-default --transparent --icon-sm loading"></div>
                            </div>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                            <span className="circle loading"></span>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                        </div>
                        <div className="rows">
                            <div className="btn-drag">
                                <div className="v2-btn-default --transparent --icon-sm loading"></div>
                            </div>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                            <span className="circle loading"></span>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                        </div>
                        <div className="rows">
                            <div className="btn-drag">
                                <div className="v2-btn-default --transparent --icon-sm loading"></div>
                            </div>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                            <span className="circle loading"></span>
                            <div className="rows__item">
                                <div className="v2-btn-default --grey loading --full">
                                    <div className="loading --animation --line --fourth"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export { SkeletonFooter, SkeletonReviewBox, SkeletonListOptions };
