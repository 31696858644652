import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import classNames from 'classnames';
import { transformToCurrency } from 'common/utils/NumberUtils';
import { useEffect, useRef, forwardRef, useImperativeHandle, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { LIST_TYPE_COMMISSION, MENU_REPEAT_ITEMS, TYPES_VALUE_COMMISSION } from '../const';

const RepeatValue = (
    { id = '', type = 0, value = 0, cost = 0, repeat = 0, isDisable = false, setDisableButtonSave = () => {} },
    ref
) => {
    const _handleCaculCommission = (value, type) => {
        return type === TYPES_VALUE_COMMISSION.PERCENT ? (value / 100) * cost : value;
    };

    const [state, dispatchState] = useReducer(reducer, {
        type,
        value,
        commission: _handleCaculCommission(value, type),
        repeat
    });
    const refDropdown = useRef(null);
    const currency = useSelector(({ auth }) => auth?.user?.settings?.currency);
    const { type: finalType, value: finalValue, repeat: finalRepeat, commission } = state;
    const repeatSelected = MENU_REPEAT_ITEMS.find((item) => item.id === finalRepeat) || {};
    const exchangeValue = transformToCurrency(commission, currency);

    useEffect(() => {
        dispatchState({ type, repeat, value, commission: _handleCaculCommission(value, type) });
    }, [type, repeat, value]);

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _getValue = () => {
        !finalValue && dispatchState({ value: 0 });
        return { type: finalType, value: finalValue || 0, repeat: finalRepeat };
    };

    const _changeRepeat = (value) => {
        if (value !== finalRepeat) {
            dispatchState({ repeat: value });
            setDisableButtonSave();
        }
        refDropdown.current._closeDropdown();
    };

    const _renderDropdown = () => {
        return (
            <ul>
                {MENU_REPEAT_ITEMS.map((item) => {
                    return (
                        <li
                            className={classNames(item.style, { active: item.id === finalRepeat })}
                            key={item.id}
                            onClick={() => _changeRepeat(item.id)}
                        >
                            {item.icon}
                            {item.name}
                        </li>
                    );
                })}
            </ul>
        );
    };

    const _renderTypes = () => {
        return LIST_TYPE_COMMISSION.map((item) => (
            <button
                key={item.id}
                className={classNames('tab-items', {
                    'active-tab-selector': item.id === finalType
                })}
                onClick={() => _onChangeType(item)}
            >
                {item.name}
            </button>
        ));
    };

    const _onChangeType = ({ id }) => {
        if (id !== finalType) {
            setDisableButtonSave();
            dispatchState({ type: id, commission: _handleCaculCommission(finalValue, id) });
        }
    };

    const _onChangeValue = (e) => {
        const newValue = e.target.value;
        setDisableButtonSave();
        dispatchState({ value: newValue, commission: _handleCaculCommission(newValue, finalType) });
    };

    return (
        <div className="col --commission d-flex">
            <div className={classNames('lineitems-selection', { 'is-disable': isDisable })}>
                <div className={classNames('tabs', { 'is-disable': !finalRepeat })}>
                    <div className="btn-item --has-value m-0">
                        <input
                            className="btn-item__input"
                            type="number"
                            placeholder=""
                            value={finalValue}
                            onChange={_onChangeValue}
                        />
                        {_renderTypes()}
                        <div className="btn-item__value" title={exchangeValue}>
                            {exchangeValue}
                        </div>
                    </div>
                </div>
                <CalendarDropdown
                    id={`dropdown-commission-${id}`}
                    buttonClassName="dropbtn v2-btn-default selection bg-white"
                    selected={repeatSelected.selected || repeatSelected.icon}
                    ref={refDropdown}
                >
                    {_renderDropdown()}
                </CalendarDropdown>
            </div>
        </div>
    );
};
export default forwardRef(RepeatValue);
