import React, { Fragment, forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { GdAudioPlayer } from 'app/components/audio/GdAudioPlayer';
import { ATTACHMENT_PRESIGN } from 'app/const/Api';
import { VOIP_ACCEPTED_AUDIO } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconAlert from 'assets/icon/IconAlert';
import { IconRecord } from 'assets/icon/IconRecord';
import IconUpload from 'assets/icon/IconUpload';
import { clientQuery } from 'common/utils/ApiUtils';
import { uploadToS3 } from 'common/utils/FileUtils';
import ModalRecording from './settings/ModalRecording';
import VOIPVoicemailTyping from './VOIPVoicemailTyping';
import GdConfirm from 'app/components/confirm';
import IconTrash from 'assets/icon/IconTrash';
import IconLoading from 'assets/icon/IconLoading';
import { VOIP_PRESIGNED_TYPE } from 'app/const/Voip';

const VOIPVoicemailDrop = (
    {
        greeting,
        greetingText,
        required = false,
        msgError = '',
        msgConfirmDelete = '',
        isUseTyping = false,
        isHideOnNull = true,
        isHideDelete = false,
        isHideActions = false,
        isUploadS3OnSuccess = true,
        presignedType = VOIP_PRESIGNED_TYPE.VOICEMAIL,
        isDisable = false,
        tooltipDisable = '',
        wrapperClassName = 'box-record flex-column gap-8 tooltip',
        onUploadFile = () => {},
        onUploadSuccess = () => {}
    },

    ref
) => {
    const { t } = useTranslation();
    const refUpload = useRef(null);
    const userId = useSelector(({ auth }) => auth.user.profile.id);
    const refModalRecording = useRef(null);
    const refVoicemailTyping = useRef(null);
    const refConfirm = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        file: null,
        greeting,
        greetingText,
        object_key: null,
        isLoading: false
    });
    const {
        greeting: finalGreeting,
        greetingText: finalGreetingText,
        isLoading: finalIsLoading,
        object_key: finalObjectKey
    } = state;

    useImperativeHandle(ref, () => ({
        onSave: _handleSave,
        getValue: _handleGetValue,
        setGreeting: _handleSetGreeting
    }));

    const _handleGetValue = () => {
        return {
            greeting: finalGreeting,
            greetingText: refVoicemailTyping.current?._getValue() || '',
            objectKey: finalObjectKey
        };
    };

    const _handleOpenUpload = () => {
        refUpload.current.click();
    };

    const _handleOnUpload = (event) => {
        const files = event.target.files;

        if (!files.length) {
            return false;
        }
        _handleUploadFile(files[0]);
        refUpload.current.value = '';
    };

    const _handleUploadFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const fileData = {
                id: file.lastModified,
                file,
                name: file.name,
                url: reader.result,
                mime: file.type
            };
            dispatchState({
                file: fileData
            });

            isUploadS3OnSuccess && _handleSave(fileData);
        };
    };

    const _handleSave = (fileData) => {
        if (!fileData) return { greeting: finalGreeting };
        dispatchState({ isLoading: true });
        onUploadFile();
        const text = refVoicemailTyping.current?._getValue() || '';
        const promise = new Promise((resolve, reject) => {
            const { name: fileName } = fileData;
            const optionQuery = {
                data: { name: `web-${fileName}`, item_id: userId, type: presignedType },
                method: 'POST'
            };

            const _addSuccess = (response) => {
                const idAttach = `${fileName}${new Date().getTime()}`;
                const { presigned_url, public_url, object_key, object_tag } = response.data;
                const optionsHeaders = { 'x-amz-tagging': object_tag };

                const _handleSuccess = (res) => {
                    dispatchState({ greeting: public_url, isLoading: false, object_key });
                    resolve({ ...res, greeting: public_url, object_key, text });
                    onUploadSuccess({ url: public_url });
                };

                uploadToS3(
                    presigned_url,
                    optionsHeaders,
                    fileData.file,
                    {
                        idAttach,
                        object_key
                    },
                    _handleSuccess,
                    (err) => reject(err)
                );
            };

            const _addFailure = (err) => {
                reject(err);
            };

            clientQuery(ATTACHMENT_PRESIGN, optionQuery, _addSuccess, _addFailure);
        });
        return promise;
    };

    const _handleVisibleModal = () => {
        refModalRecording.current._setVisible(true);
    };

    const _handleConfirmDelete = () => {
        dispatchState((prev) => ({ ...prev, file: null, greeting: '' }));
    };

    const _handleSetGreeting = ({ url = '' }) => {
        dispatchState((prev) => ({ ...prev, greeting: url }));
    };

    const _renderAudio = () => {
        if (required && !finalGreeting)
            return (
                <div className="attention --danger flexcenter gap-6">
                    <IconAlert />
                    <p className="red-default fs-13">{msgError || t('addons:voicemail_is_required')}</p>
                </div>
            );
        if (isHideOnNull && !finalGreeting) return null;
        return <GdAudioPlayer src={finalGreeting} />;
    };

    const _handleCancel = () => {
        refConfirm.current.close();
    };

    const _handleOpenConfirm = () => {
        refConfirm.current.open();
    };

    return (
        <Fragment>
            <div className={wrapperClassName}>
                {_renderAudio()}
                <div
                    className={classNames('flexcenter gap-8 tooltip', {
                        'is-disable': finalIsLoading,
                        'dp-hide': isHideActions
                    })}
                >
                    <div
                        className={classNames('v2-btn-default has-icon flex-1', { 'is-disable': isDisable })}
                        onClick={_handleVisibleModal}
                    >
                        <IconRecord />
                        {finalIsLoading ? <IconLoading isPurple /> : t('addons:record_new')}
                    </div>
                    <div
                        className={classNames('v2-btn-default has-icon flex-1', { 'is-disable': isDisable })}
                        onClick={_handleOpenUpload}
                    >
                        <IconUpload />
                        {finalIsLoading ? <IconLoading isPurple /> : t('addons:upload_file')}
                        <input
                            ref={refUpload}
                            onChange={_handleOnUpload}
                            type="file"
                            style={{ display: 'none' }}
                            multiple
                            accept={VOIP_ACCEPTED_AUDIO}
                        />
                    </div>
                    {!!finalGreeting && !isHideDelete ? (
                        <div className="v2-btn-default --icon-lg --delete" onClick={_handleOpenConfirm}>
                            <IconTrash />
                        </div>
                    ) : null}
                </div>
                {isDisable ? <span className="tooltiptext bottom">{tooltipDisable}</span> : null}
            </div>
            {isUseTyping && (
                <VOIPVoicemailTyping ref={refVoicemailTyping} textVoicemail={finalGreetingText} defaultText="" />
            )}

            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={msgConfirmDelete || t('addons:desc_delete_voicemail_greeting')}
                titleConfirm="Yes"
                listButton={{ confirm: true, cancel: true }}
                onClose={_handleCancel}
                onCancel={_handleCancel}
                onConfirm={_handleConfirmDelete}
            />
            <ModalRecording ref={refModalRecording} onUpload={_handleUploadFile} />
        </Fragment>
    );
};

export default forwardRef(VOIPVoicemailDrop);
