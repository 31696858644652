import GdButton from 'app/components/button';
import GdConfirm from 'app/components/confirm';
import { GET_LIST_TEMPLATE, updateTodoTemplate } from 'app/const/api/Todo';
import { reducer } from 'app/const/Reducer';
import IconCircleClose from 'assets/icon/IconCircleClose';
import IconPlus from 'assets/icon/IconPlus';
import IconSearch from 'assets/icon/IconSearch';
import IconTrash from 'assets/icon/IconTrash';
import classNames from 'classnames';
import { clientQuery } from 'common/utils/ApiUtils';
import { useImperativeHandle, forwardRef, useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingTemplates } from './Loading';
import { LIST_STATUS } from 'app/const/Status';

import GridLoading from 'app/components/grid/components/GridLoading';
import { isScrollToEndBottom } from 'common/utils/FunctionUtils';
import { checkAccessFail } from 'common/utils/PermissionUtils';
import { ACCESS_SETTINGS_TAB } from 'app/const/Permissions';
import { handleAbortController } from 'app/modules/customer/utils';

const Todos = (
    {
        getDetailItems = () => {},
        onOpenAddTemplate = () => {},
        onCloseDetailItems = () => {},
        onShowStatusBar = () => {}
    },
    ref
) => {
    const { t } = useTranslation(['setting']);
    const abortController = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        todos: [],
        isLoading: true,
        isLoadingMore: false,
        todoSelected: null,
        keyword: '',
        total: 0
    });

    const {
        todos: finalTodos,
        isLoading,
        todoSelected,
        keyword: finalKeyword,
        total: finalTotal,
        isLoadingMore
    } = state;

    const refConfirm = useRef(null);

    useImperativeHandle(ref, () => ({
        _addTodo,
        _updateTodo
    }));

    useEffect(() => {
        _getTodos('');

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    const _getTodos = (keyword) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();

        const _success = ({ data, total }) => {
            const firstItem = data[0] || { id: null };
            dispatchState({ todos: data, todoSelected: firstItem.id, isLoading: false, total });
            getDetailItems(firstItem);
        };

        const _fail = ({ isAborted }) => {
            if (isAborted) return;
            dispatchState({ isLoading: false });
        };

        clientQuery(
            GET_LIST_TEMPLATE,
            {
                method: 'GET',
                data: { limit: 20, offet: 0, total: 1, keyword },
                abortController: abortController.current
            },
            _success,
            _fail
        );
    };

    const _selectTodo = (item) => {
        dispatchState({ todoSelected: item.id });
        getDetailItems(item);
    };

    const _addTodo = (item) => {
        const newTodos = [...finalTodos, item].sort((a, b) => a.name.localeCompare(b.name));
        dispatchState({ todos: newTodos });
    };

    const _updateTodo = (data) => {
        dispatchState((prev) => ({
            ...prev,
            todos: prev.todos.map((item) => {
                if (item.id === data.id) return data;
                return item;
            })
        }));
    };

    const _deleteTodo = (id) => {
        const _success = () => {
            const newTodos = finalTodos.filter((item) => item.id !== id);
            const newState = { todos: newTodos };
            if (id === todoSelected) {
                newState.todoSelected = null;
                onCloseDetailItems();
            }
            dispatchState(newState);
            onShowStatusBar('show_success', t('delete_template_successfully'), LIST_STATUS.SUCCESS);
        };

        const _fail = () => {
            onShowStatusBar('show_error', t('delete_template_error'), LIST_STATUS.ERROR);
        };

        clientQuery(updateTodoTemplate(id), { method: 'DELETE', data: {} }, _success, _fail);
    };

    const _openConfirmDelete = (e, id) => {
        e.stopPropagation();
        refConfirm.current.open(id);
    };

    const _onSearch = (e) => {
        const value = e.target.value;
        dispatchState((prev) => ({
            ...prev,
            keyword: value,
            isLoading: true,
            todos: []
        }));
        _getTodos(value);
    };

    const _closeSearch = () => {
        dispatchState((prev) => ({
            ...prev,
            keyword: '',
            isLoading: true,
            todos: []
        }));
        _getTodos('');
    };

    const _renderHeader = () => (
        <div className="header-title">
            <h2 className="header-title__label fs-14">{t('todo_lists')}</h2>
            <GdButton
                className="v2-btn-main has-icon svg-white"
                title={t('add_new')}
                iconSvg={<IconPlus />}
                onClick={onOpenAddTemplate}
            />
        </div>
    );

    const _triggerLoadMore = () => {
        dispatchState({
            isLoadingMore: true
        });

        const _success = ({ data }) => {
            dispatchState((prev) => {
                return {
                    ...prev,
                    todos: [...prev.todos, ...data],
                    isLoadingMore: false
                };
            });
        };

        const _fail = (err) => {
            dispatchState({ isLoadingMore: false });
            checkAccessFail(err, ACCESS_SETTINGS_TAB);
        };

        clientQuery(
            GET_LIST_TEMPLATE,
            { method: 'GET', data: { limit: 20, offset: finalTodos.length } },
            _success,
            _fail
        );
    };

    const _handleScrolll = (e) => {
        if (!isLoading && !isLoadingMore && isScrollToEndBottom(e.target) && finalTotal > finalTodos.length) {
            _triggerLoadMore();
        }
    };

    const _renderSearchBar = () => {
        return (
            <div className="wrap-list-items__search">
                <div className="search-input has-closeicon">
                    <span className="svg-search-absolute">
                        <IconSearch />
                    </span>
                    <input
                        placeholder={t('common:search')}
                        type={'text'}
                        value={finalKeyword}
                        onChange={_onSearch}
                        spellCheck
                    />
                    {!!finalKeyword && (
                        <div className="close-icon" onClick={_closeSearch}>
                            <IconCircleClose />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const _renderTodos = () => {
        if (isLoading) return <LoadingTemplates />;

        return finalTodos.map((item) => {
            const { id, name } = item;
            return (
                <div
                    className={classNames('list-details__items', { active: todoSelected === id })}
                    key={id}
                    onClick={() => _selectTodo(item)}
                >
                    <div className="template-name flex-1">
                        <p className="word-break">{name}</p>
                        <span className="status-btn --sm bg-granite-gray">{t('todo_list')}</span>
                    </div>
                    <GdButton
                        className="v2-btn-default --icon-lg --transparent svg-delete-grey"
                        iconSvg={<IconTrash />}
                        onClick={(e) => _openConfirmDelete(e, id)}
                    />
                </div>
            );
        });
    };

    return (
        <div className="wrap-list-items flex-auto">
            {_renderHeader()}
            {_renderSearchBar()}
            <div className="list-details is-active" onScroll={_handleScrolll}>
                {_renderTodos()}
                {isLoadingMore && (
                    <div className="items justify-center">
                        <GridLoading />
                    </div>
                )}
            </div>
            <GdConfirm
                ref={refConfirm}
                title={t('common:notification')}
                message={t('setting:confirm_delete_template')}
                titleConfirm={t('common:confirm')}
                listButton={{ confirm: true, cancel: true }}
                onConfirm={_deleteTodo}
            />
        </div>
    );
};
export default forwardRef(Todos);
