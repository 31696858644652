import React, { useContext, useEffect, useRef } from 'react';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconNote from 'assets/icon/IconNote';
import { useTranslation } from 'react-i18next';

const InvoiceTerms = () => {
    const { t } = useTranslation(['jobDetail']);
    const refTerm = useRef(null);

    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);
    const { terms } = invoiceData;

    useEffect(() => {
        if (terms !== refTerm.current.value) {
            refTerm.current.value = terms;
        }
    }, [terms]);

    const _handleBlur = (e) => {
        onUpdateInvoiceDataContext({
            terms: e.target.value
        });
    };

    return (
        <div className="boxs --terms-note">
            <div className="left-icons">
                <IconNote isActiveColor />
            </div>
            <div className="content elm-parent has-edited">
                <div className="header-box">
                    <div className="name">
                        <div className="name__label">
                            <h4 className="fs-13">{t('jobDetail:terms')}</h4>
                        </div>
                    </div>
                </div>
                <div className="wrap-content is-editing">
                    <div className="wrap-content-box">
                        <div className="wrapbox-editor">
                            <div className="wrapbox-editor__form">
                                <textarea
                                    defaultValue={invoiceData.terms}
                                    name="terms"
                                    className="field-textarea --no-border --px-12 d-flex"
                                    onBlur={_handleBlur}
                                    ref={refTerm}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvoiceTerms;
