import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CUSTOMERS } from 'app/const/Route';
import IconCall from 'assets/icon/IconCall';
import IconPhone from 'assets/icon/IconPhone';
import TimeCounter from './Time';
import { calculateSecondVoip } from 'common/utils/TimeUtils';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';
import { addBranchPath } from 'app/const/Branch';

const InviteCall = ({ item = {}, onDisconnect = () => {}, onAcceptCall = () => {} }) => {
    const { t } = useTranslation('header');
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);

    const {
        id: callId,
        customer_name: customerName,
        customer_title: customerTitle,
        customer_id: customerId,
        inviteData,
        connection
    } = item;

    const fromUser = companyUsers.find(({ id }) => id === inviteData.from) || {};
    const { full_name, avatar } = fromUser;

    const parameters = connection.customParameters;

    return (
        <div className="call-bar flexcenter is-invited">
            <div className="call-bar__invited gap-md flexcenter flex-1">
                <div className="avt-has-micro relative">
                    <img className="avt-img --lg" src={avatar} width={32} height={32} />
                    <span className="avt-has-micro__icon absolute">
                        <IconCall isOnCall />
                    </span>
                </div>
                <b className="black">{full_name}</b>
                <span className="grey-very-dark">{t('invited_you_to_join_call')}</span>
                {!!customerId ? (
                    <div className="valid-name">
                        <Link to={addBranchPath(`${CUSTOMERS}/${customerId}`)} className="purple-default">
                            {customerName}
                        </Link>
                        {customerTitle ? <div className="label-content">{customerTitle}</div> : null}
                    </div>
                ) : (
                    <div className="purple-default">{formatPhoneNumberVoip(parameters.get('PhoneNumber'))}</div>
                )}

                <TimeCounter
                    isUseTime
                    wrapperClassName="dots"
                    startAt={calculateSecondVoip(parameters.get('StartTime'))}
                />
            </div>
            <div className="call-bar__action flexcenter pr-2">
                <span
                    className="action-call has-label bg-green-default flex-centeritem svg-white"
                    onClick={() => onAcceptCall(callId, item)}
                >
                    <IconPhone isBlack />
                    {t('listen')}
                </span>
                <span
                    className="action-call border-grey-verylight grey-very-dark bg-white flex-centeritem px-3"
                    onClick={() => onDisconnect(callId, { isReject: true })}
                >
                    {t('ignore')}
                </span>
            </div>
            <span className="call-bar__status w-100" />
        </div>
    );
};

export default InviteCall;
