import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import SwitchCheckbox from 'app/components/checkbox/SwitchCheckbox';
import { reducer } from 'app/const/Reducer';
import IconTooltip from 'assets/icon/IconTooltip';
import { detectBrowser } from 'common/utils/FunctionUtils';
import VOIPVoicemailTyping from '../VOIPVoicemailTyping';

const RecordingDisclosure = ({ recording_disclosure = {} }, ref) => {
    const { t } = useTranslation();

    const { enable, statement } = recording_disclosure;

    const refTyping = useRef(null);
    const refEnable = useRef(enable);

    const [state, dispatchState] = useReducer(reducer, {
        isActive: !!enable,
        isSpeechError: false,
        browser: detectBrowser()
    });
    const { isActive } = state;

    useEffect(() => {
        if (!('speechSynthesis' in window)) {
            dispatchState({
                isSpeechError: true
            });
            return;
        }
        window.speechSynthesis.getVoices();

        return () => {
            window.speechSynthesis.cancel();
        };
    }, []);

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _getValue = () => {
        const isEnable = refEnable.current;
        return {
            recording_disclosure: isEnable ? 1 : 0,
            recording_disclosure_statement: isEnable ? refTyping.current?._getValue() : statement
        };
    };

    const _handleChangeActive = (value) => {
        refEnable.current = value;
        dispatchState({ isActive: value });
    };

    return (
        <div className="box-voip__rows flexcenter flex-wrap is-enabled">
            <p className="flex-1 fw-500">
                {t('addons:call_recording_disclosure')}
                <span className="tooltip ml-1">
                    <IconTooltip />
                    <p className="tooltiptext top">{t('addons:required_by_law')}</p>
                </span>
            </p>
            <SwitchCheckbox
                id="call_recording_disclosure"
                textChecked={t('addons:enabled')}
                textUnChecked={t('addons:disabled')}
                defaultChecked={!!enable}
                onChangeImmediately={_handleChangeActive}
            />
            <div className="detail-enable flex-column gap-8 w-100 mt-2">
                <p className="word-break">{t('addons:desc_call_recording_disclosure')}</p>
                {isActive && <VOIPVoicemailTyping ref={refTyping} textVoicemail={statement} />}
            </div>
        </div>
    );
};

export default forwardRef(RecordingDisclosure);
