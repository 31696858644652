import React, { Fragment, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import 'app/modules/error/css/oops.scss';
import GdLazyImage from 'app/components/lazyimage';
import { ACCESS_TOKEN } from 'app/const/App';
import { LINK_CDN_IMG } from 'app/const/URL';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import { getPlanUser } from 'common/utils/PermissionUtils';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import { GET_BRANCHES } from 'app/const/api/V2';
import { getAvatarCustomer } from 'common/utils/StringUtils';
import IconArrowDown from 'assets/icon/IconArrowDown';

function NotHaveSchedules() {
    const { t } = useTranslation(['common, settings']);
    const { profile, company } = useSelector(({ auth }) => auth.user);

    const { isGrowthPlan } = getPlanUser(profile);
    const isBranch = company.is_branch;
    const isShowSelectBranch = isGrowthPlan || isBranch;

    const [state, dispatchState] = useReducer(reducer, {
        branches: [],
        selected: {}
    });
    const { branches: finalBranches, selected: finalSelected } = state;

    useEffect(() => {
        fetchListBranch();
    }, []);

    const fetchListBranch = () => {
        if (!isShowSelectBranch) {
            localStorage.removeItem(ACCESS_TOKEN);
            return;
        }

        const _handleSuccess = ({ data }) => {
            dispatchState((prev) => ({
                ...prev,
                branches: data,
                selected: data.find(({ id }) => id === company.branch.id) || {}
            }));
        };

        clientQuery(GET_BRANCHES, { method: 'GET' }, _handleSuccess);
    };

    const _renderListBranch = () => {
        return finalBranches.map((item) => {
            const { id, name, email, avatar } = item;
            const isActive = id === finalSelected.id;

            const _handleSelect = () => {
                if (isActive) return;
                window.location.href = `${window.location.origin}/${id}`;
            };

            return (
                <li key={id} className={classNames('items', { active: isActive })} onClick={_handleSelect}>
                    <div className="user-name">
                        <div className={classNames('--large', { 'avt-img': !!avatar, avt: !avatar })}>
                            {avatar ? <img src={avatar} width="32" height="32" /> : getAvatarCustomer(name)}
                        </div>
                        <div className="user-name-info">
                            <p className="txt-ellipsis fw-bold">{name}</p>
                            <p className="txt-ellipsis user-name-info__sub">{email}</p>
                        </div>
                    </div>
                </li>
            );
        });
    };

    const _renderButton = () => {
        const { id, name, avatar } = finalSelected;

        return (
            <Fragment>
                {!!id ? (
                    <div className={classNames({ 'avt-img': !!avatar, avt: !avatar })}>
                        {avatar ? <img src={avatar} width="24" height="24" /> : getAvatarCustomer(name)}
                    </div>
                ) : null}
                <span className="txt-ellipsis fw-bold ml-1">{name}</span>
                <IconArrowDown />
            </Fragment>
        );
    };

    return (
        <div className="c-error">
            <div className="container">
                <div className="container__box">
                    <p className="desc mb-3">{t('common:not_have_schedules_description')}</p>
                    <p className="desc --number"> {t('common:assign_a_calendar_to_you')}</p>
                    <p className="desc">{t('setting:or')}</p>
                    <p className="desc --number">{t('common:make_you_admin')}</p>
                    {isShowSelectBranch ? (
                        <CalendarDropdown
                            id="branch_select"
                            wrapperClassName="mt-2"
                            wrapperListClass="v2-dropdown__menu content-user content-full scrolls"
                            customDropButton={_renderButton}
                        >
                            <ul>{_renderListBranch()}</ul>
                        </CalendarDropdown>
                    ) : null}
                </div>
            </div>
            <div className="logo">
                <GdLazyImage
                    src={`${LINK_CDN_IMG}bottom_logo.png`}
                    alt={t('common:gorilladesk')}
                    width="143"
                    height="134"
                />
            </div>
        </div>
    );
}

export default NotHaveSchedules;
