import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const Name = forwardRef(({ title = '', defaultValue = '' }, ref) => {
    const { t } = useTranslation(['customers']);
    const refTaskName = useRef(null);

    useImperativeHandle(ref, () => ({
        getValue: () => {
            return refTaskName.current.value;
        },
        checkVerifyError: _checkVerifyError,
        resetValue: () => {
            refTaskName.current.value = '';
        },
        focus: () => {
            refTaskName.current.focus();
        }
    }));

    useEffect(() => {
        if (refTaskName.current) refTaskName.current.value = defaultValue;
    }, [defaultValue]);

    function _checkVerifyError() {
        refTaskName.current.classList.add('field-error');
    }

    function _handleChange() {
        refTaskName.current.classList.remove('field-error');
    }

    return (
        <div className="flex-column gap-4 flex-1">
            <p>{title}</p>
            <textarea
                ref={refTaskName}
                type="text"
                className="field-textarea --no-resize"
                placeholder={t('customers:what_do_you_need_to_do')}
                spellCheck
                autoFocus
                defaultValue={defaultValue}
                onChange={_handleChange}
            />
        </div>
    );
});

export default Name;
