import React from 'react';

// import ProfilePassword from './components/ProfilePassword';
import ProfileSessionUser from './components/ProfileSessionUser';
import TwoStepAuth from './components/TwoStepAuth';

const ProfileAuthentication = () => {
    return (
        <div className="set-user-right wrapper-box-edit">
            <div className="wrapper-box-edit__content form-default form-authentication">
                <div className="page-wrapper wrapbox-user p-0 border-none">
                    {/* <ProfilePassword /> */}
                    <TwoStepAuth />
                    <ProfileSessionUser />
                </div>
            </div>
        </div>
    );
};

export default ProfileAuthentication;
