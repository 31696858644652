import IconThreeDots from 'assets/icon/IconThreeDots';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import IconPen from 'assets/icon/IconPen';
import IconTrash from 'assets/icon/IconTrash';
import { reducer } from 'app/const/Reducer';
import Add from './Add';
import Checkbox from 'app/components/checkbox';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';

export default function CheckListDetail({
    itemDetail = {},
    onUpdate = () => {},
    onUpdateStatus = () => {},
    onDelete = () => {},
    isShowComplete = true
}) {
    const {
        id: checkListId,
        description: parentDescription,
        status: parentStatus,
        completed_by,
        completed_date
    } = itemDetail;

    const [state, dispatchState] = useReducer(reducer, {
        isOpenEdit: false,
        completed: 0,
        description: parentDescription,
        status: parentStatus
    });
    const refTimmerStatus = useRef(null);
    const refFormAdd = useRef(null);
    const { isOpenEdit: finalIsOpenEdit, status: finalStatus, description: finalDescription } = state;
    const { profile: userProfile, company: companyData, settings } = useSelector(({ auth }) => auth.user);
    const users = useSelector(({ companyUsers }) => companyUsers.users);

    useEffect(() => {
        if (parentDescription !== finalDescription || parentStatus !== finalStatus) {
            dispatchState((prev) => {
                return {
                    ...prev,
                    description: parentDescription,
                    status: parentStatus
                };
            });
        }
    }, [parentDescription, parentStatus]);

    const _handleOpenForm = () => {
        dispatchState({
            isOpenEdit: true
        });
        refFormAdd.current._open();
    };

    const _handleCloseForm = () => {
        dispatchState({
            isOpenEdit: false
        });
    };

    const _handleChangeComplete = (e, value) => {
        const finalValue = !!value ? 0 : 1;

        dispatchState((prev) => {
            return {
                ...prev,
                status: finalValue
            };
        });

        if (refTimmerStatus.current) {
            clearTimeout(refTimmerStatus.current);
        }

        refTimmerStatus.current = setTimeout(() => {
            onUpdateStatus({
                ...itemDetail,
                description: finalDescription,
                status: finalValue,
                completed_date: moment().format('X'),
                completed_by: userProfile.id
            });
        }, 500);
    };

    const _handleEdit = (newdescription) => {
        dispatchState((prev) => {
            return {
                ...prev,
                description: newdescription
            };
        });

        onUpdate({
            ...itemDetail,
            description: newdescription,
            status: finalStatus,
            updated_date: moment().format('X')
        });
    };

    function _renderAvatar(userDetail) {
        if (userDetail?.avatar) {
            return <img src={userDetail.avatar} alt="" width="24" height="24" />;
        } else {
            return (
                <div className="avt fs-11">{`${userDetail.first_name.substring(0, 1)}${userDetail.last_name.substring(
                    0,
                    1
                )}`}</div>
            );
        }
    }

    function _handleDelete() {
        onDelete(checkListId);
    }

    const _renderCompleteBy = useCallback(() => {
        if (!finalStatus || !completed_by || !completed_date) {
            return false;
        }

        const userAction = users.find((item) => item.id === completed_by?.toString());

        if (!userAction) {
            return false;
        }

        return (
            <div className="avt-name tooltip">
                <div className="avt-img">{_renderAvatar(userAction)}</div>
                <span className="txt-ellipsis mx-1">{userAction.full_name}</span>
                <p className="tooltiptext top">
                    {moment(parseInt(completed_date), 'X')
                        .tz(settings?.timezone)
                        .format(`${convertPhpFormatToMoment(companyData.date_format)} HH:mm`)}
                </p>
            </div>
        );
    }, [completed_by, finalStatus]);

    return (
        <div className={`todo-items ${finalIsOpenEdit ? 'has-edit' : ''}`}>
            <div className={`todo-items__action flextop ${!!finalStatus ? 'is-checked' : ''}`}>
                <div className="col-drag v2-btn-default --icon-sm --transparent">
                    <IconThreeDots isDouble />
                </div>
                {!!isShowComplete && (
                    <div className="col-check flex-centeritem">
                        <Checkbox
                            id={checkListId}
                            checked={!!finalStatus}
                            onChangeValue={_handleChangeComplete}
                            value={finalStatus}
                        />
                    </div>
                )}
                <div className="col-desc flex-1">
                    <span
                        className="col-desc__label word-break mr-1 description"
                        dangerouslySetInnerHTML={{ __html: finalDescription }}
                    />

                    {_renderCompleteBy()}
                </div>
                <div className="col-icon flexcenter">
                    <div onClick={_handleOpenForm} className="v2-btn-default --icon-lg ml-1">
                        <IconPen />
                    </div>
                    <a onClick={_handleDelete} className="v2-btn-default --icon-lg svg-delete-grey ml-1">
                        <IconTrash />
                    </a>
                </div>
            </div>

            <Add
                isAddNew
                ref={refFormAdd}
                onClose={_handleCloseForm}
                defaultValue={finalDescription}
                onSave={_handleEdit}
            />
        </div>
    );
}
