import { TYPE_SETTING_SERVICE } from 'common/redux/actions/settings/serviceAction';

const serviceReducer = (
    state = {
        list_service: [],
        first_time: true,
        total_service: false
    },
    action
) => {
    switch (action.type) {
        case TYPE_SETTING_SERVICE.GET_LIST_SUCCESS:
            const newListCustomer = action.resetData ? action.data.data : [...state.list_service, ...action.data.data];
            const finalTotal = action.resetData ? action.data.total : state.total_service;
            return { ...state, list_service: newListCustomer, first_time: false, total_service: finalTotal };
        case TYPE_SETTING_SERVICE.RESET_SERVICE_LIST:
            return { list_service: [], first_time: true, total_service: false };

        default:
            return state;
    }
};

export default serviceReducer;
