export const TYPE_DOCUMENT_ACTIONS = {
    GET_LIST_DOCUMENT_TYPE_SUCCESS: 'GET_LIST_DOCUMENT_TYPE_SUCCESS'
};

export const setListDocumentTypeReducer = (payload) => {
    return {
        type: TYPE_DOCUMENT_ACTIONS.GET_LIST_DOCUMENT_TYPE_SUCCESS,
        payload
    };
};
