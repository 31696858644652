import {
    CUSTOMERS_QB_SYNC,
    QUICK_BOOK_CUSTOMER_PAYMENTS,
    QUICK_BOOK_CUSTOMER_INVOICE,
    QUICK_BOOK_CREDIT_SYNC
} from '../Api';

export const QUICK_BOOK_PAYMENT = 'QUICK_BOOK_PAYMENT';
export const QUICK_BOOK_CUSTOMER = 'QUICK_BOOK_CUSTOMER';
export const QUICK_BOOK_CUS_INVOICE = 'QUICK_BOOK_CUS_INVOICE';
export const QUICK_BOOK_CREDIT = 'QUICK_BOOK_CREDIT';

export const URL_QUICK_BOOK_COLUMN = {
    [QUICK_BOOK_PAYMENT]: QUICK_BOOK_CUSTOMER_PAYMENTS,
    [QUICK_BOOK_CUSTOMER]: CUSTOMERS_QB_SYNC,
    [QUICK_BOOK_CUS_INVOICE]: QUICK_BOOK_CUSTOMER_INVOICE,
    [QUICK_BOOK_CREDIT]: QUICK_BOOK_CREDIT_SYNC
};

export const QB_SYNC_STATUS = {
    LOADING: -1,
    DEFAULT: 0,
    DONE: 1,
    FAIL: 2
};
