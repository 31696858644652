import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SettingTemplateHeader({ onCancel, onSubmit, title }) {
    const { t } = useTranslation(['setting']);

    const _handleCancel = () => {
        onCancel();
    };

    const _handleSubmit = () => {
        onSubmit();
    };

    return (
        <div className="header-title">
            <h2 className="header-title__label">
                {t(`setting:${title}_template`)}
            </h2>
            <div className="w-button">
                <span
                    className="v2-btn-default --noborder --label"
                    onClick={_handleCancel}
                >
                    {t('setting:cancel')}
                </span>
                <span className="v2-btn-main" onClick={_handleSubmit}>
                    {t('setting:save')}
                </span>
            </div>
        </div>
    );
}

SettingTemplateHeader.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    title: PropTypes.string
};

export default SettingTemplateHeader;
