import React from 'react';
import ResourceTimeEdit from './ResourceTimeEdit';

const ResourceGroupLabel = ({ title, isEditing = false, extendedProps = {}, onBlur = () => {} }) => {
    return (
        <>
            <div className="col col-date flexcenter gap-4">
                <p className="col-label fw-500">{title}</p>
            </div>
            <ResourceTimeEdit isEditing={isEditing} {...extendedProps} onBlur={onBlur} />
        </>
    );
};

export default ResourceGroupLabel;
