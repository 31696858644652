import { commentOfNote } from 'app/const/Api';
import Content from 'app/modules/customer/components/Content';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import Header from 'app/modules/customer/detail/comment/Header';
import { clientQuery } from 'common/utils/ApiUtils';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';
import moment from 'moment';
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

const AddComment = forwardRef(({ noteId, onAdd, cleanQuickAdd, type, jobId = '', unitId = '' }, ref) => {
    const refContent = useRef(null);

    const { id } = useParams();
    const userData = useSelector(({ auth }) => auth.user);
    const userProfile = userData.profile || {};
    const formatDisplay = convertPhpFormatToMoment(userData.company?.date_format);
    const { t } = useTranslation(['customers']);

    const [state, setState] = useState({
        isVisible: false,
        commentData: {
            attachments: []
        }
    });

    const { isVisible: finalIsVisible } = state;

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide
    }));

    useEffect(() => {
        finalIsVisible && refContent.current && refContent.current.focus();
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elm = document.getElementById(`add_comment_${noteId}`);
        if (event.keyCode === KEY_CODE_ESCAPE && elm) {
            _handleCancelEdit();
        }
    }

    function _handleToggleShowHide() {
        setState((prev) => {
            return {
                ...prev,
                isVisible: !prev.isVisible
            };
        });
    }

    function _handleCancelEdit() {
        const elm = document.getElementById(`form_time_line_note_detail_${noteId}`);
        if (elm) {
            elm.classList.remove('has-add-comments');
            setState({ ...state, isVisible: false });
        }
    }

    function _handleAdd(content) {
        const idTemp = new Date().getTime();
        const notifySelected = refContent.current.getNotify() || [];
        const attachments = refContent.current.getAttachments() || [];

        clientQuery(
            commentOfNote(noteId),
            {
                method: 'POST',
                data: {
                    content,
                    notified_users: notifySelected,
                    attachments: attachments.map((item) => {
                        return {
                            object_key: item.object_key,
                            object_tag: item.object_tag,
                            mime: item.mime,
                            name: item.name,
                            size: item.size,
                            type: item.type
                        };
                    })
                },
                toFormData: false
            },
            (reponse) => _addCommentSuccess(reponse, idTemp, content, notifySelected, attachments)
        );

        setTimeout(() => {
            const elm = document.getElementById(`form_time_line_note_detail_${noteId}`);

            if (elm) {
                elm.classList.remove('has-add-comments');
            }

            setState((prev) => {
                return {
                    ...prev,
                    isVisible: false,
                    commentData: {
                        attachments: []
                    }
                };
            });
        }, 300);
    }

    function _addCommentSuccess(reponse, idTemp, content, notifySelected, attachments) {
        const newAttach = reponse.data.attachments.map((item) => {
            return { ...item, isUpdate: true };
        });

        onAdd({
            id: idTemp,
            content: content,
            user: {
                ...userProfile,
                full_name: userProfile.first_name.concat(' ', userProfile.last_name)
            },
            date: `${moment().format(formatDisplay)} ${moment().format('h:mmA')}`,
            notified_users: notifySelected,
            attachments: [...attachments, ...newAttach].filter((item) => item.isUpdate || item.isDefault),
            quick_add: true
        });

        cleanQuickAdd(idTemp, reponse.data.id);
    }

    function _getData() {
        return {
            user: {
                ...userProfile,
                full_name: userProfile.first_name.concat(' ', userProfile.last_name)
            },
            date: `${moment().format(formatDisplay)} ${moment().format('h:mmA')}`
        };
    }

    if (!finalIsVisible) {
        return (
            <div className="pre-comment-field" role="textbox" aria-multiline="true" onClick={_handleToggleShowHide}>
                {t('write_a_comment')}
            </div>
        );
    }

    function _getItemId() {
        switch (type) {
            case TYPE_NOTE.CUSTOMER:
                return id;
            case TYPE_NOTE.JOB:
                return jobId;
            case TYPE_NOTE.UNIT:
                return unitId;
            default:
                return '';
        }
    }

    return (
        <Fragment>
            <div className="header-box">
                <Header commentDetail={_getData()} />
            </div>
            <div className="wrap-content is-editing">
                <div className="wrap-content-box">
                    <Content
                        ref={refContent}
                        content={''}
                        typePresign="comment"
                        itemIdPresign={_getItemId()}
                        attachmentProps={{ itemId: _getItemId(), typeAttachment: 'note', haveNotify: true }}
                        onCloseEdit={_handleCancelEdit}
                        onUpdate={_handleAdd}
                    />
                </div>
            </div>
        </Fragment>
    );
});

export default AddComment;
