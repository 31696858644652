export default function IconDilution() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 12V17C18 18.1046 17.1046 19 16 19H8C6.89543 19 6 18.1046 6 17V12H18Z"
                fill="#7A83A6"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 6C5.44772 6 5 5.55228 5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5C19 5.55228 18.5523 6 18 6V11H6V6Z"
                fill="#C1C9D4"
            ></path>
        </svg>
    );
}
