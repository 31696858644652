import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { COMMON } from 'app/const/App';
import CheckBoxHeader from 'app/modules/report/components/CheckBoxHeader';
import IconDoubleCheck from 'assets/icon/IconDoubleCheck';
import IconTime from 'assets/icon/IconTime';
import IconTrash from 'assets/icon/IconTrash';
import { CUSTOM_FIELDS_ACTIONS, CUSTOM_FIELDS_VIEWS } from '../../../customfields/constant';

const HeaderTable = ({
    type = CUSTOM_FIELDS_VIEWS.ACTIVE,
    ids = [],
    isLoading = false,
    total = 0,
    isShowArchive = true,
    onActions = () => {},
    onUncheckAll = () => {}
}) => {
    const _handleAction = (type) => onActions(type, ids);
    const selectedLength = ids?.length;
    const Components = Object.freeze({
        active: <ActiveElements onActions={_handleAction} />,
        archived: <ArchivedElements onActions={_handleAction} />,
        deleted: <DeletedElements onActions={_handleAction} isShowArchive={isShowArchive} />
    });

    return (
        <div className={classNames('header --filter', { 'is-disable': isLoading })}>
            <CheckBoxHeader
                checkedItems={{ ids, is_check_all: !!selectedLength && selectedLength === total }}
                total={total}
                isShowTotal
                onUncheckAll={onUncheckAll}
                isDisableEmptyIds={!total || false}
            />
            <div className={classNames('flexcenter gap-4', { 'is-disable': !selectedLength })}>{Components[type]}</div>
        </div>
    );
};

const DeleteButton = ({ onActions = () => {} }) => {
    const { t } = useTranslation('common');
    return (
        <div className="header-items v2-btn-default has-icon has-bg-red" onClick={() => onActions(COMMON.DELETE)}>
            <IconTrash />
            {t('delete')}
        </div>
    );
};

const ArchiveButton = ({ onActions = () => {} }) => {
    const { t } = useTranslation('common');
    return (
        <div className="header-items v2-btn-default has-icon" onClick={() => onActions(CUSTOM_FIELDS_ACTIONS.ARCHIVE)}>
            <IconTime />
            {t('archive')}
        </div>
    );
};

const ActiveElements = ({ onActions = () => {} }) => {
    return (
        <>
            <DeleteButton onActions={onActions} />
            <ArchiveButton onActions={onActions} />
        </>
    );
};
const ArchivedElements = ({ onActions = () => {} }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <DeleteButton onActions={onActions} />
            <div
                className="header-items v2-btn-default has-icon"
                onClick={() => onActions(CUSTOM_FIELDS_ACTIONS.ACTIVE)}
            >
                <IconDoubleCheck />
                {t('active')}
            </div>
        </>
    );
};
const DeletedElements = ({ onActions = () => {}, isShowArchive = true }) => {
    return (
        <>
            <div
                className="header-items v2-btn-default has-icon"
                onClick={() => onActions(CUSTOM_FIELDS_ACTIONS.UN_DELETE)}
            >
                <IconDoubleCheck />
                Undelete
            </div>
            {isShowArchive && <ArchiveButton onActions={onActions} />}
            <div
                className="header-items v2-btn-default has-icon has-bg-red"
                onClick={() => onActions(CUSTOM_FIELDS_ACTIONS.PERMANENTLY_DELETE)}
            >
                <IconTrash />
                Permanently Delete
            </div>
        </>
    );
};
export default HeaderTable;
