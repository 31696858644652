import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { STATUS_TRAIN_OPTIONS } from '.';

export const getColumnTrainAI = ({ actionsStatus = '' }) => {
    const HEADER_TABLE = {
        question: {
            title: 'common_questions',
            style: 'col col-xl',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        occurrences: {
            title: 'occurrences',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_TABLE = [
        {
            id: 'question',
            title: 'common_questions',
            status: true
        },
        {
            id: 'occurrences',
            title: 'occurrences',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        }
    ];

    const REPORT_CONTENT_TABLE = {
        question: {
            id: 'question',
            style: 'col col-xl',
            styleText: 'name',
            type: LIST_TABLE_ITEM_TYPE.LINK
        },
        occurrences: {
            id: 'occurrences',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        status: {
            id: 'status',
            style: 'col col-sm d-flex',
            type: LIST_TABLE_ITEM_TYPE.STATUS_ITEM,
            listStatus: STATUS_TRAIN_OPTIONS
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_TABLE }),
        columns: REPORT_COLUMNS_TABLE,
        contentConfig: REPORT_CONTENT_TABLE
    };
};
