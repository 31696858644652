import { TYPE_FUNCTION_RICHTEXT } from 'app/components/richtext/const';
import { TYPE_TEMPLATE_ID } from 'app/const/Settings';

export const getEmailRichTextSetup = (template, idTypeTemplate) => {
    const result = {
        left: [
            { group: 'emoji', children: [{ type: TYPE_FUNCTION_RICHTEXT.EMOJI }] },
            {
                group: 'inline-style',
                children: [
                    { type: TYPE_FUNCTION_RICHTEXT.BOLD },
                    { type: TYPE_FUNCTION_RICHTEXT.ITALIC },
                    { type: TYPE_FUNCTION_RICHTEXT.UNDERLINE },
                    { type: TYPE_FUNCTION_RICHTEXT.LINK }
                ]
            },
            {
                group: 'list',
                children: [{ type: TYPE_FUNCTION_RICHTEXT.UL }, { type: TYPE_FUNCTION_RICHTEXT.OL }]
            },
            {
                group: 'image',
                children: [{ type: TYPE_FUNCTION_RICHTEXT.IMAGE, props: { canUpload: true } }]
            }
        ],
        right: [
            {
                group: 'variable',
                children: [
                    {
                        isHide: template === TYPE_TEMPLATE_ID.SMS,
                        type: TYPE_FUNCTION_RICHTEXT.VARIABLES,
                        props: { id: template.id, template: idTypeTemplate, type: 1 }
                    },
                    { type: TYPE_FUNCTION_RICHTEXT.RESTORE }
                ]
            },
            {
                group: 'code',
                children: [{ type: TYPE_FUNCTION_RICHTEXT.CODE }]
            }
        ]
    };

    if (idTypeTemplate === TYPE_TEMPLATE_ID.SMS) result.right[0].children = [{ type: TYPE_FUNCTION_RICHTEXT.RESTORE }];
    return result;
};

export const getSMSRichTextSetup = (template, idTypeTemplate, type = 2) => {
    return {
        right: [
            {
                group: 'code',
                children: [
                    {
                        type: TYPE_FUNCTION_RICHTEXT.VARIABLES,
                        props: { id: template.id, template: idTypeTemplate, type }
                    },
                    { type: TYPE_FUNCTION_RICHTEXT.RESTORE }
                ]
            }
        ]
    };
};

export const MAX_LENGTH_ATTACH = 2;

export const CONFIG_TOOLBAR_SMS_TEMPLATE = {
    right: [
        {
            group: 'variable',
            children: [
                { type: TYPE_FUNCTION_RICHTEXT.VARIABLES, props: { isSMSVariable: true } },
                {
                    type: TYPE_FUNCTION_RICHTEXT.EMOJI,
                    props: {
                        wrapperClassName: 'v2-dropdown',
                        buttonClassName: 'dropbtn editor-controls__btn tooltip',
                        isCalculateWidth: true,
                        customStyle: { maxWidth: 'auto' }
                    }
                },
                {
                    type: TYPE_FUNCTION_RICHTEXT.ATTACHMENT,
                    props: {
                        buttonClassName: 'dropbtn btn-attachment editor-controls__btn tooltip',
                        isShowTooltip: true
                    }
                },
                { type: TYPE_FUNCTION_RICHTEXT.RESTORE }
            ]
        }
    ]
};
