export default function IconTax({ isOverride = false }) {
    if (isOverride)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5 3.00513L10.9933 3.00659L18.5724 10.5857C19.3124 11.3256 19.3513 12.5011 18.6893 13.2869L18.5724 13.4141L13.4075 18.579C12.6676 19.319 11.4921 19.3579 10.7063 18.6959L10.5791 18.579L3 10.9999L2.99951 5.00415C2.99978 3.89958 3.89543 3.00437 5 3.00513ZM9.5 6.99991C8.11929 6.99991 7 8.1192 7 9.49991C7 10.6283 7.74751 11.582 8.77433 11.893L7.12617 13.5403L9.45962 15.8737L11.1069 14.2256C11.4179 15.2524 12.3717 15.9999 13.5 15.9999C14.8807 15.9999 16 14.8806 16 13.4999C16 12.3716 15.2525 11.4178 14.2257 11.1069L15.8738 9.45953L13.5404 7.12608L11.8931 8.77424C11.5821 7.74742 10.6283 6.99991 9.5 6.99991ZM6 4.99991C5.44771 4.99991 5 5.44762 5 5.99991C5 6.55219 5.44771 6.99991 6 6.99991C6.55228 6.99991 7 6.55219 7 5.99991C7 5.44762 6.55228 4.99991 6 4.99991Z"
                    fill="#C1C9D4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 11.9999C14.3284 11.9999 15 12.6715 15 13.4999C15 14.3283 14.3284 14.9999 13.5 14.9999C12.6716 14.9999 12 14.3283 12 13.4999C12 12.6715 12.6716 11.9999 13.5 11.9999ZM13.5404 8.54029L14.4596 9.45953L9.45962 14.4595L8.54038 13.5403L13.5404 8.54029ZM13.5 12.9999C13.2239 12.9999 13 13.2238 13 13.4999C13 13.7761 13.2239 13.9999 13.5 13.9999C13.7761 13.9999 14 13.7761 14 13.4999C14 13.2238 13.7761 12.9999 13.5 12.9999ZM9.5 7.99991C10.3284 7.99991 11 8.67148 11 9.49991C11 10.3283 10.3284 10.9999 9.5 10.9999C8.67157 10.9999 8 10.3283 8 9.49991C8 8.67148 8.67157 7.99991 9.5 7.99991ZM9.5 8.99991C9.22386 8.99991 9 9.22377 9 9.49991C9 9.77605 9.22386 9.99991 9.5 9.99991C9.77614 9.99991 10 9.77605 10 9.49991C10 9.22377 9.77614 8.99991 9.5 8.99991Z"
                    fill="#7A83A6"
                />
            </svg>
        );

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
                <g>
                    <g>
                        <g>
                            <path
                                fill="#C1C9D4"
                                d="M7 5.005l5.993.002 7.58 7.579c.74.74.778 1.915.116 2.701l-.117.127-5.164 5.165c-.74.74-1.916.779-2.702.117l-.127-.117L5 13V7.004c0-1.104.895-2 2-1.999zM11.5 9C10.12 9 9 10.12 9 11.5c0 1.128.748 2.082 1.774 2.393L9.126 15.54l2.334 2.334 1.647-1.648C13.417 17.252 14.372 18 15.5 18c1.38 0 2.5-1.12 2.5-2.5 0-1.128-.748-2.082-1.774-2.393l1.648-1.647-2.334-2.334-1.647 1.648C13.583 9.748 12.628 9 11.5 9zM8 7c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
                                transform="translate(-307 -1489) translate(130 1489) translate(177)"
                            />
                            <path
                                fill="#929BA9"
                                d="M15.5 14c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm.04-3.46l.92.92-5 5-.92-.92 5-5zM15.5 15c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5zm-4-5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0 1c-.276 0-.5.224-.5.5s.224.5.5.5.5-.224.5-.5-.224-.5-.5-.5z"
                                transform="translate(-307 -1489) translate(130 1489) translate(177)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
