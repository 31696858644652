import i18n from 'assets/i18n';

export const DOCUMENT_LIBRARY = {
    ALL: -1,
    DOC: 1,
    PDF: 2
};

export const TABS_DOCUMENT_FILTER = [
    {
        value: DOCUMENT_LIBRARY.DOC,
        label: i18n.t('addons:docs')
    },
    {
        value: DOCUMENT_LIBRARY.PDF,
        label: i18n.t('addons:pdfs')
    }
];

export const TABS_DOCUMENT_LIBRARY = [
    {
        value: DOCUMENT_LIBRARY.ALL,
        label: i18n.t('addons:all')
    },
    ...TABS_DOCUMENT_FILTER
];

export const TYPE_PRESIGN_PDF = 'document_pdf';
