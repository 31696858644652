import IconPlay from 'assets/icon/IconPlay';
import IconEdit from 'assets/icon/IconEdit';
import i18n from 'assets/i18n';

export const TRACKING_SHORTCUTS = { START: 'n', STOP: 'm' };
export const TRACKING_TYPES = {
    TIMER: 'TIMER',
    MANUAL: 'MANUAL'
};
export const TRACKING_TYPES_ERRORS = {
    ADDONS: 'addon',
    PERMISSION: 'permission',
    DOWNGRADE: 'downgrade'
};

export const TRACKING_OFFLINE = 'TRACKING_OFFLINE';
export const TRACKING_ONLINE = 'TRACKING_ONLINE';
export const TRACKING_EVENTS = {
    START: 'START',
    START_WITH_ID: 'START_WITH_ID',
    START_WITH_ID_DETAIL: 'START_WITH_ID_DETAIL',
    STOP: 'STOP',
    SETTED_TRACKING_ID: 'SETTED_TRACKING_ID',
    SETTING_TRACKING_ID: 'SETTING_TRACKING_ID'
};

export const TRACKING_LOCAL_NAME = 'tracking_time_start';
export const TRACKING_ID_TRACKING = 'tracking_time_id';
export const TIME_LABEL_FORMAT = [{ hour: 'numeric', minute: '2-digit', hour12: true }];

export const START_TRACKING_EVENT = 'START_TRACKING_EVENT';
export const STOP_TRACKING_EVENT = 'STOP_TRACKING_EVENT';

export const TRACKING_EVENTS_DETAIL_PAGE = {
    START: 'START_TRACKING_EVENT_FROM_DETAIL',
    STOP: 'STOP_TRACKING_EVENT_FROM_DETAIL',
    SETTED_TRACKING_ID: 'SETTED_TRACKING_ID',
    SETTING_TRACKING_ID: 'SETTING_TRACKING_ID',
    ADD_NEW_WORK_LOG: 'ADD_NEW_WORK_LOG',
    CONFIRM_STOP_TRACKING: 'CONFIRM_STOP_TRACKING'
};

export const TRACKING_ALERT_EVENT = 'TRACKING_ALERT_EVENT';
export const TABS_TRACKERS = [
    { label: i18n.t('timer'), icon: <IconPlay isBlack />, value: TRACKING_TYPES.TIMER },
    { label: i18n.t('manual'), icon: <IconEdit />, value: TRACKING_TYPES.MANUAL }
];

export const TRACKER_CALENDAR_VIEWS = { DAY: 'day', WEEK: 'week', MONTH: 'month' };
export const TABS_SELECT_VIEWS = [
    { label: i18n.t('day'), value: TRACKER_CALENDAR_VIEWS.DAY },
    { label: i18n.t('week'), value: TRACKER_CALENDAR_VIEWS.WEEK },
    { label: i18n.t('month'), value: TRACKER_CALENDAR_VIEWS.MONTH }
];
export const TRACKER_EVENT_COLOR = {
    MAIN: '#007bff',
    SECONDARY: '#6c757d'
};
export const RESOURCE_TYPES = {
    GROUP: 'group',
    CHILDREN: 'children'
};
export const RESOURCE_EMPTY = {
    id: 'resource_empty',
    title: 'There are no data to display',
    isResourceEmpty: true,
    duration: 0,
    amount: 0
};
