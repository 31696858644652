import React from 'react';

const SmartViewLoading = () => {
    return (
        <div className="wrap-loading --addon">
            <div className="boxs boxs--no-border">
                <div className="boxs__header page-heading">
                    <div className="header-title">
                        <div className="header-title__svg v2-btn-default loading --animation --light --transparent mr-0"></div>
                        <div className="title flex-column gap-4">
                            <div className="loading --animation --light --line --twothird"></div>
                            <div className="v2-btn-default loading --animation --light --transparent field-h16 btn-x-sm"></div>
                        </div>
                    </div>
                    <div className="loading --onefifth switch ml-0">
                        <div className="loading --animation --light --line --full"></div>
                    </div>
                </div>
                <div className="boxs__contents mt-3 mb-0">
                    <div className="description loading-lines">
                        <div className="loading --animation --light --line --full"></div>
                        <div className="loading --animation --light --line --full"></div>
                        <div className="loading --animation --light --line --threefourth"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmartViewLoading;
