import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ADDONS_OPPORTUNITY_MANAGE } from 'app/config/routes';
import { REFERENCE_TYPES } from 'app/modules/smartview/constants/types';
import IconCogWheel from 'assets/icon/IconCogWheel';
import ReferenceItem from './ReferenceItem';
import { addBranchPath } from 'app/const/Branch';

const ListContent = ({
    update = () => {},
    onChange = () => {},
    options = [],
    selected,
    keyGetLabel = 'name',
    keySelect = 'id',
    referencedType = 'id',
    ...props
}) => {
    useEffect(() => {
        update();
    }, [options]);

    const _isActive = (id) => {
        return (
            selected?.value?.toString() === id?.toString() ||
            (Array.isArray(selected) && selected.some((option) => option?.toString() === id?.toString()))
        );
    };

    if (!Array.isArray(options) || !options?.length) return null;
    return (
        <>
            <ul>
                {options.map((option) => {
                    const optionId = option.id;
                    const valueSelect = option?.[keySelect] || optionId;
                    const isActive = _isActive(valueSelect);

                    return (
                        <ReferenceItem
                            key={optionId}
                            label={option?.[keyGetLabel] || ''}
                            {...option}
                            {...props}
                            isActive={isActive}
                            referencedType={referencedType}
                            onChange={() => onChange(valueSelect)}
                        />
                    );
                })}
            </ul>
            <LinkToManage referencedType={referencedType} />
        </>
    );
};

const LinkToManage = ({ referencedType = '' }) => {
    const { t } = useTranslation('smartView');
    const permissionsEnable = useSelector(({ auth }) => auth.user.permissions.enabled || []);

    if (referencedType !== REFERENCE_TYPES.STATUS_OPPORTUNITY) return null;
    return permissionsEnable.includes('accessSettingsTab') ? (
        <>
            <div className="line" />
            <Link to={addBranchPath(ADDONS_OPPORTUNITY_MANAGE)} className="items">
                <span className="mx-1">
                    <IconCogWheel />
                </span>
                {t('manage_pipelines')}
            </Link>
        </>
    ) : null;
};

export default ListContent;
