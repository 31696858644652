export default function IconNote({
    isHasLocation = false,
    isAddNote = false,
    isActive = false,
    isLocationActive = false,
    isActiveColor = false,
    isActiveNoteRecurring = false
}) {
    if (isActiveColor)
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99928 6.01678L8 6C8 7.65685 9.34315 9 11 9H13C14.6511 9 15.9907 7.66611 16 6.01714C16.4873 6.05076 16.7313 6.13582 16.9768 6.26713C17.3028 6.44144 17.5586 6.69723 17.7329 7.02315C17.9072 7.34908 18 7.67237 18 8.56388V16.4361C18 17.3276 17.9072 17.6509 17.7329 17.9768C17.5586 18.3028 17.3028 18.5586 16.9768 18.7329C16.6509 18.9072 16.3276 19 15.4361 19H8.56388C7.67237 19 7.34908 18.9072 7.02315 18.7329C6.69723 18.5586 6.44144 18.3028 6.26713 17.9768C6.09283 17.6509 6 17.3276 6 16.4361V8.56388C6 7.67237 6.09283 7.34908 6.26713 7.02315C6.44144 6.69723 6.69723 6.44144 7.02315 6.26713C7.26868 6.13582 7.51271 6.05076 7.99928 6.01678Z"
                    fill="#DAC2F2"
                />
                <path
                    d="M13.5 5H10.5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8H13.5C14.3284 8 15 7.32843 15 6.5C15 5.67157 14.3284 5 13.5 5Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    if (isLocationActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.99928 4.01678L5 4C5 5.65685 6.34315 7 8 7H10C11.6511 7 12.9907 5.66611 13 4.01714C13.4873 4.05076 13.7313 4.13582 13.9768 4.26713C14.3028 4.44144 14.5586 4.69723 14.7329 5.02315C14.9072 5.34908 15 5.67237 15 6.56389V7C11.6863 7 9 9.64496 9 12.9077C9 14.3551 9.26227 15.7195 9.7868 17.0009L5.56389 17C4.67237 17 4.34908 16.9072 4.02315 16.7329C3.69723 16.5586 3.44144 16.3028 3.26713 15.9768C3.09283 15.6509 3 15.3276 3 14.4361V6.56389C3 5.67237 3.09283 5.34908 3.26713 5.02315C3.44144 4.69723 3.69723 4.44144 4.02315 4.26713C4.26868 4.13582 4.51271 4.05076 4.99928 4.01678Z"
                    fill="#DAC2F2"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 8C17.7614 8 20 10.149 20 12.8C20 16.0339 18.3333 18.7672 15 21C11.6667 18.7809 10 16.0476 10 12.8C10 10.149 12.2386 8 15 8ZM15 11C13.8954 11 13 11.8954 13 13C13 14.1046 13.8954 15 15 15C16.1046 15 17 14.1046 17 13C17 11.8954 16.1046 11 15 11Z"
                    fill="#ff871e"
                />
                <path
                    d="M10.5 3H7.5C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6H10.5C11.3284 6 12 5.32843 12 4.5C12 3.67157 11.3284 3 10.5 3Z"
                    fill="#8D4AFC"
                />
            </svg>
        );
    }
    if (isActive) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.5 5.5H16C16.8284 5.5 17.5 6.17157 17.5 7V16.5C17.5 17.6046 16.6046 18.5 15.5 18.5H8.5C7.39543 18.5 6.5 17.6046 6.5 16.5V7.5C6.5 6.39543 7.39543 5.5 8.5 5.5H9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 4.5H13C13.8284 4.5 14.5 5.17157 14.5 6C14.5 6.82843 13.8284 7.5 13 7.5H11C10.1716 7.5 9.5 6.82843 9.5 6C9.5 5.17157 10.1716 4.5 11 4.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 11.5H14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 14.5H14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    if (isHasLocation)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.99928 4.01678L5 4C5 5.65685 6.34315 7 8 7H10C11.6511 7 12.9907 5.66611 13 4.01714C13.4873 4.05076 13.7313 4.13582 13.9768 4.26713C14.3028 4.44144 14.5586 4.69723 14.7329 5.02315C14.9072 5.34908 15 5.67237 15 6.56389V7C11.6863 7 9 9.64496 9 12.9077C9 14.3551 9.26227 15.7195 9.7868 17.0009L5.56389 17C4.67237 17 4.34908 16.9072 4.02315 16.7329C3.69723 16.5586 3.44144 16.3028 3.26713 15.9768C3.09283 15.6509 3 15.3276 3 14.4361V6.56389C3 5.67237 3.09283 5.34908 3.26713 5.02315C3.44144 4.69723 3.69723 4.44144 4.02315 4.26713C4.26868 4.13582 4.51271 4.05076 4.99928 4.01678Z"
                    fill="#C1C9D4"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 8C17.7614 8 20 10.149 20 12.8C20 16.0339 18.3333 18.7672 15 21C11.6667 18.7809 10 16.0476 10 12.8C10 10.149 12.2386 8 15 8ZM15 11C13.8954 11 13 11.8954 13 13C13 14.1046 13.8954 15 15 15C16.1046 15 17 14.1046 17 13C17 11.8954 16.1046 11 15 11Z"
                    fill="#7A83A6"
                ></path>
                <path
                    d="M10.5 3H7.5C6.67157 3 6 3.67157 6 4.5C6 5.32843 6.67157 6 7.5 6H10.5C11.3284 6 12 5.32843 12 4.5C12 3.67157 11.3284 3 10.5 3Z"
                    fill="#7A83A6"
                ></path>
            </svg>
        );

    if (isAddNote) {
        return (
            <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.5 5.5H16C16.8284 5.5 17.5 6.17157 17.5 7V16.5C17.5 17.6046 16.6046 18.5 15.5 18.5H8.5C7.39543 18.5 6.5 17.6046 6.5 16.5V7.5C6.5 6.39543 7.39543 5.5 8.5 5.5H9.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 4.5H13C13.8284 4.5 14.5 5.17157 14.5 6C14.5 6.82843 13.8284 7.5 13 7.5H11C10.1716 7.5 9.5 6.82843 9.5 6C9.5 5.17157 10.1716 4.5 11 4.5Z"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 11.5H14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.5 14.5H14.5"
                    stroke="#4D5054"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        );
    }

    if (isActiveNoteRecurring)
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.99928 6.01678L8 6C8 7.65685 9.34315 9 11 9H13C14.6511 9 15.9907 7.66611 16 6.01714C16.4873 6.05076 16.7313 6.13582 16.9768 6.26713C17.3028 6.44144 17.5586 6.69723 17.7329 7.02315C17.9072 7.34908 18 7.67237 18 8.56388V16.4361C18 17.3276 17.9072 17.6509 17.7329 17.9768C17.5586 18.3028 17.3028 18.5586 16.9768 18.7329C16.6509 18.9072 16.3276 19 15.4361 19H8.56388C7.67237 19 7.34908 18.9072 7.02315 18.7329C6.69723 18.5586 6.44144 18.3028 6.26713 17.9768C6.09283 17.6509 6 17.3276 6 16.4361V8.56388C6 7.67237 6.09283 7.34908 6.26713 7.02315C6.44144 6.69723 6.69723 6.44144 7.02315 6.26713C7.26868 6.13582 7.51271 6.05076 7.99928 6.01678Z"
                    fill="#cfe9ff"
                ></path>
                <path
                    d="M13.5 5H10.5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8H13.5C14.3284 8 15 7.32843 15 6.5C15 5.67157 14.3284 5 13.5 5Z"
                    fill="#1E7EF7"
                ></path>
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99928 6.01678L8 6C8 7.65685 9.34315 9 11 9H13C14.6511 9 15.9907 7.66611 16 6.01714C16.4873 6.05076 16.7313 6.13582 16.9768 6.26713C17.3028 6.44144 17.5586 6.69723 17.7329 7.02315C17.9072 7.34908 18 7.67237 18 8.56388V16.4361C18 17.3276 17.9072 17.6509 17.7329 17.9768C17.5586 18.3028 17.3028 18.5586 16.9768 18.7329C16.6509 18.9072 16.3276 19 15.4361 19H8.56388C7.67237 19 7.34908 18.9072 7.02315 18.7329C6.69723 18.5586 6.44144 18.3028 6.26713 17.9768C6.09283 17.6509 6 17.3276 6 16.4361V8.56388C6 7.67237 6.09283 7.34908 6.26713 7.02315C6.44144 6.69723 6.69723 6.44144 7.02315 6.26713C7.26868 6.13582 7.51271 6.05076 7.99928 6.01678Z"
                fill="#C1C9D4"
            ></path>
            <path
                d="M13.5 5H10.5C9.67157 5 9 5.67157 9 6.5C9 7.32843 9.67157 8 10.5 8H13.5C14.3284 8 15 7.32843 15 6.5C15 5.67157 14.3284 5 13.5 5Z"
                fill="#7A83A6"
            ></path>
        </svg>
    );
}
