import { useContext, useEffect } from 'react';
import { SocketContext } from 'app/services/socket/SocketProvider';

export default function RealTimeSMS({ onUpdateTotalInbox }) {
    const { inboxHeader: inboxReceiverData } = useContext(SocketContext);

    useEffect(() => {
        if (inboxReceiverData && typeof inboxReceiverData?.total !== 'undefined') {
            onUpdateTotalInbox(inboxReceiverData.total);
        }
    }, [inboxReceiverData]);

    return null;
}
