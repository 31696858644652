import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { PREFIX_TITLE, STATUS_ACCOUNT } from 'app/const/App';
import { AUTH_LOGIN, SETTINGS_ACCOUNT, SETTINGS_INVOICES, SETTINGS_PLANS } from 'app/const/Route';
import ErrorPage from 'app/modules/error';
import { checkPermission } from 'common/utils/PermissionUtils';
import { addBranchPath, addPrefixPath, convertPrefixPath } from 'app/const/Branch';
import { ACCOUNT_PLANS_THANKS } from 'app/config/routes';

const NOT_ON_PLAN_ROUTES = [SETTINGS_PLANS, SETTINGS_ACCOUNT, SETTINGS_INVOICES, ACCOUNT_PLANS_THANKS];

const RouteWithSubRoutesPrivate = (route) => {
    const user = useSelector((state) => state?.auth?.user);

    if (Array.isArray(route.path)) {
        const paths = route.path.map((item) => addPrefixPath(item));
        return (
            <Route
                path={paths}
                exact={route.exact}
                // component={route.component}
                render={(props) => {
                    // `location` props for feature redirect after login
                    if (!user)
                        return <Redirect to={{ pathname: AUTH_LOGIN, replace: true, state: { from: location } }} />;
                    return (
                        <route.component
                            routes={
                                route?.routes?.map((item) => ({ ...item, path: convertPrefixPath(item.path) })) || []
                            }
                            {...props}
                        />
                    );
                }}
            />
        );
    }
    return (
        <Route
            path={convertPrefixPath(route.path)}
            exact={route.exact}
            render={(props) => {
                // `location` props for feature redirect after login
                if (!user) return <Redirect to={{ pathname: AUTH_LOGIN, replace: true, state: { from: location } }} />;
                return <RouteRender props={props} route={route} />;
            }}
        />
    );
};

export const RouteRender = ({ route, props = {} }) => {
    return (
        <WithTitle
            title={route.title}
            permissionKey={route.permissionKey}
            excludePermissionRoute={route.excludePermissionRoute}
        >
            <route.component
                {...props}
                routes={route?.routes?.map((item) => ({ ...item, path: convertPrefixPath(item.path) })) || []}
            >
                {route.children}
            </route.component>
        </WithTitle>
    );
};

const RouteWithSubRoutes = (route) => {
    return (
        <Route
            path={route.path}
            exact={route.exact}
            render={(props) => (
                <WithTitle title={route.title} shouldCheckPlan={false}>
                    <route.component {...props} routes={route.routes} />
                </WithTitle>
            )}
        />
    );
};

export const RouteCustom = ({ route, ...props }) => {
    return (
        <WithTitle
            title={route.title}
            permissionKey={route.permissionKey}
            excludePermissionRoute={route.excludePermissionRoute}
        >
            <Route path={route.path} component={route.component} {...props} />
        </WithTitle>
    );
};

const WithTitle = withRouter(
    ({ title, children, history, location, permissionKey, excludePermissionRoute = [], shouldCheckPlan = true }) => {
        const { t } = useTranslation('pageTitle');
        const status = useSelector((state) => state.auth?.user?.profile?.plan?.status);
        const permissionsList = useSelector((state) => state.auth?.user?.permissions?.enabled || []);
        const isNotPermission = excludePermissionRoute.some((exRoute) => addBranchPath(exRoute) === location.pathname)
            ? false
            : permissionKey !== undefined
            ? !checkPermission(permissionsList, permissionKey)
            : false;

        const isExpired =
            status === STATUS_ACCOUNT.EXPIRED &&
            !NOT_ON_PLAN_ROUTES.some((path) => addBranchPath(path) === location.pathname) &&
            shouldCheckPlan;

        useEffect(() => {
            if (isExpired) history.push(addBranchPath(SETTINGS_PLANS));
        }, [location]);

        useEffect(() => {
            if (title) document.title = `${PREFIX_TITLE} ${title ? `- ${t(title)}` : ''}`;
        }, [t, title]);

        if (isNotPermission) return <ErrorPage errorMessage={t('auth:no_permission_access')} />;
        return isExpired ? null : children;
    }
);

export { RouteWithSubRoutes, RouteWithSubRoutesPrivate, WithTitle };
