import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useSelector } from 'react-redux';
import TextareaAutosize from 'react-textarea-autosize';

import { handlePlacesAddressComponent } from 'app/components/places/utils';

const PlaceInput = (
    {
        isGetFullAddress = false,
        withClassName,
        placeholder,
        name,
        isTextarea = false,
        defaultValue = '',
        onSelect = () => {},
        onBlur = () => {},
        ...props
    },
    ref
) => {
    const placeInputRef = useRef(null);
    const refFormattedAddress = useRef('');
    const refGeo = useRef({ lng: null, lat: null });
    const refAutocomplete = useRef(null);
    const companyCountry = useSelector(({ auth }) => auth.user.company.country);

    useImperativeHandle(ref, () => ({
        _setValue: (value) => (placeInputRef.current.value = value),
        getValue: () => placeInputRef.current.value,
        getFormattedAddress: () => refFormattedAddress.current,
        getGeo: () => refGeo.current
    }));

    useEffect(() => {
        const timer = setTimeout(() => {
            if (placeInputRef.current) placeInputRef.current.value = defaultValue;
        }, 0);

        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [defaultValue]);

    const refFirstBlur = useRef(true);

    // initialize the google place autocomplete
    const initPlaceAPI = () => {
        if (!window.google || refAutocomplete.current) return;
        const autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current, {
            types: ['address'],
            componentRestrictions: { country: companyCountry }
        });

        // Clear any existing event listeners.
        new window.google.maps.event.clearInstanceListeners(autocomplete);
        autocomplete.addListener('place_changed', _handleFillAddress);

        placeInputRef.current.addEventListener('blur', (event) => {
            const hover = document.querySelector('.pac-container .pac-item:hover');
            // if an item has been clicked, do nothing, otherwise get first solution and use Geocoder to get the place
            if (hover && hover.length === 0) {
                // don't do anything
            } else {
                if (!refFirstBlur.current) onBlur(event.target.value);
            }
        });

        refAutocomplete.current = autocomplete;
    };

    const _handleFillAddress = (placeData, street1) => {
        if (!refAutocomplete.current) return;
        if (!placeData) {
            const place = refAutocomplete.current.getPlace();
            if (place) _handleBlur(place);
        }
        if (placeData && placeData.address_components) _handleBlur(placeData, street1);
    };

    const _handleBlur = (placeData, street1) => {
        refFirstBlur.current = false;
        const formattedAddress = placeData.formatted_address;
        const lng = placeData.geometry.location.lng();
        const lat = placeData.geometry.location.lat();
        const dataAddress = handlePlacesAddressComponent(placeData.address_components);
        const nameInput = dataAddress.nameInput;

        onSelect(name, { ...dataAddress, name: nameInput, lng, lat, formattedAddress });
        refGeo.current = { lng, lat };
        refFormattedAddress.current = formattedAddress;
        if (!street1) placeInputRef.current.value = isGetFullAddress ? formattedAddress : nameInput;

        onBlur(street1);
    };

    const _handleFocus = () => {
        initPlaceAPI();
    };

    return isTextarea ? (
        <TextareaAutosize
            className={withClassName}
            placeholder={placeholder}
            name={name}
            type="text"
            ref={placeInputRef}
            defaultValue={defaultValue}
            onFocus={_handleFocus}
            {...props}
        />
    ) : (
        <input
            className={withClassName}
            placeholder={placeholder}
            name={name}
            type="text"
            ref={placeInputRef}
            defaultValue={defaultValue}
            onFocus={_handleFocus}
            {...props}
        />
    );
};

export default forwardRef(PlaceInput);
