import classNames from 'classnames';
import React from 'react';

import { SORT_ORDER } from 'app/const/Reports';
import IconDropDown from 'assets/icon/IconDropDown';

const SortOrderTable = ({ value = '', currentSort = '', onClickOrder = () => {} }) => {
    const _renderOrderTable = ({ key }) => {
        const _handleClick = () => {
            if (key === currentSort) return;
            onClickOrder({ field: value, nextOrder: key });
        };

        return (
            <span key={key} className={classNames('icon', `--${key}`)} onClick={_handleClick}>
                <IconDropDown isSortOrder />
            </span>
        );
    };

    return (
        <div className="orderby-icons flex-column gap-3">
            {Object.values(SORT_ORDER).map((key) => _renderOrderTable({ key }))}
        </div>
    );
};

export default SortOrderTable;
