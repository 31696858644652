import { ADDONS_EMAIL_DETAIL } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import EmailContent from './components/EmailContent';
import EmailLoading from './components/EmailLoading';
import AddonBack from '../components/AddonBack';
import { checkAccessFail } from 'common/utils/PermissionUtils';

const AddonsInboxEmail = () => {
    const { t } = useTranslation();
    const [state, dispatchState] = useReducer(reducer, {
        email: {},
        isLoading: true
    });
    const { email, isLoading } = state;
    const refAlert = useRef(null);

    useEffect(() => {
        _getDetailReviewAddons();
    }, []);

    const _getDetailReviewAddons = () => {
        clientQuery(ADDONS_EMAIL_DETAIL, { method: 'GET' }, _handleGetSuccess, checkAccessFail);
    };

    const _handleGetSuccess = ({ data }) => {
        dispatchState({ email: data, isLoading: false });
    };

    const _handleSuccess = (value) => {
        refAlert.current.showStatusBar(value);
    };

    const _handleAddAccountSuccess = (account) => {
        dispatchState({ email: { ...email, account } });
        _handleSuccess({ id: 'update_smtp_success', message: t('update_successfully'), type: LIST_STATUS.SUCCESS });
    };

    const _handleAddAccountFail = (message) => {
        _handleSuccess({ id: 'update_smtp_fail', message, type: LIST_STATUS.ERROR, timeout: 10000 });
    };

    const _handleUpdateAccountSuccess = (account, message = '') => {
        dispatchState({ email: { ...email, account } });
        _handleSuccess({
            id: 'update_smtp_success',
            message: message || t('update_successfully'),
            type: LIST_STATUS.SUCCESS
        });
    };

    const _handleUpdateSyncSuccess = ({ send, sync }) => {
        const newAccount = {
            ...state.email.account,
            smtp: { ...state.email.account.smtp, active: send, status: send },
            imap: { ...state.email.account.imap, active: sync, status: sync }
        };
        dispatchState({ email: { ...email, account: newAccount } });
        _handleSuccess({ id: 'update_smtp_success', message: t('update_successfully'), type: LIST_STATUS.SUCCESS });
    };

    const _handleUpdateSyncFail = ({ message }) => {
        _handleSuccess({
            id: 'update_smtp_fail',
            message: message || t('action_failure'),
            type: LIST_STATUS.ERROR
        });
    };

    const _handleDeleteAccountSuccess = (type, message) => {
        let newAccount = { ...state.email.account };

        switch (type) {
            case 'smtp':
                newAccount['smtp'] = {};
                break;
            case 'imap':
                newAccount['imap'] = {};
                break;
            default:
                newAccount = null;
                break;
        }
        dispatchState({ email: { ...email, account: newAccount } });
        _handleSuccess({ id: 'update_smtp_success', message, type: LIST_STATUS.SUCCESS });
    };

    const _handleUpdateSMTPSuccess = (data, type) => {
        dispatchState({
            email: { ...email, account: { ...email.account, [type]: { ...email.account[type], ...data } } }
        });
        _handleSuccess({ id: 'update_smtp_success', message: t('update_successfully'), type: LIST_STATUS.SUCCESS });
    };

    return (
        <div className="addons-wrap__container">
            <div className="header">
                <AddonBack />
            </div>
            <div className="scrolls">
                <div className="boxs-wrapper --email">
                    <AlertCustomer ref={refAlert} />
                    {isLoading ? (
                        <EmailLoading />
                    ) : (
                        <EmailContent
                            {...email}
                            isLoading={isLoading}
                            onAddAccountFail={_handleAddAccountFail}
                            onAddAccountSuccess={_handleAddAccountSuccess}
                            onUpdateAccountSuccess={_handleUpdateAccountSuccess}
                            onUpdateSMTPSuccess={_handleUpdateSMTPSuccess}
                            onUpdateSyncSuccess={_handleUpdateSyncSuccess}
                            onUpdateSyncFail={_handleUpdateSyncFail}
                            onDeleteSuccess={_handleDeleteAccountSuccess}
                            onSuccess={_handleSuccess}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddonsInboxEmail;
