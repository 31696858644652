import { SocketContext } from 'app/services/socket/SocketProvider';
import { useContext, useEffect } from 'react';

const RealtimeInvoiceList = ({ onUpdate = () => {} }) => {
    const { invoiceStatus } = useContext(SocketContext);

    useEffect(() => {
        if (!!invoiceStatus) {
            onUpdate(invoiceStatus);
        }
    }, [invoiceStatus]);
};
export default RealtimeInvoiceList;
