import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconAlert from 'assets/icon/IconAlert';
import IconPlay from 'assets/icon/IconPlay';
import { detectBrowser } from 'common/utils/FunctionUtils';
import { getVoiceBrowser } from 'app/const/Voip';

const VOIPVoicemailTyping = ({ className = 'box-voicemail', textVoicemail = '', defaultText = null }, ref) => {
    const { t } = useTranslation();

    const refInput = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        isSpeechError: false,
        browser: detectBrowser()
    });
    const { browser, isSpeechError } = state;
    const defaultMessage = defaultText ?? t('addons:all_calls_are_recorded');

    useEffect(() => {
        if (!('speechSynthesis' in window)) {
            dispatchState({
                isSpeechError: true
            });
            return;
        }
        window.speechSynthesis.getVoices();

        return () => {
            window.speechSynthesis.cancel();
        };
    }, []);

    useImperativeHandle(ref, () => ({
        _getValue
    }));

    const _getValue = () => {
        return refInput.current?.value?.trim() || '';
    };

    const _handleSpeech = () => {
        const text = refInput.current.value?.trim() || defaultMessage;
        if (!text || isSpeechError) return false;

        const utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = window.speechSynthesis.getVoices().find((item) => item.name === getVoiceBrowser(browser));
        window.speechSynthesis.speak(utterance);
    };

    return (
        <div className={className}>
            <textarea
                ref={refInput}
                className="field-textarea"
                type="text"
                rows={3}
                defaultValue={textVoicemail || defaultMessage}
                placeholder={defaultMessage}
            />
            {!isSpeechError ? (
                <div className="v2-btn-default has-icon btn-speech" onClick={_handleSpeech}>
                    <IconPlay />
                    {t('addons:listen')}
                </div>
            ) : (
                <div className="attention --danger flexcenter gap-6">
                    <IconAlert />
                    <p className="red-default fs-13">{t('addons:error_call_recording_disclosure')}</p>
                </div>
            )}
        </div>
    );
};

export default forwardRef(VOIPVoicemailTyping);
