import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MODES_HANDLE_SETTING_USERS } from 'app/const/App';
import IconDoubleStep from 'assets/icon/IconDoubleStep';
import SessionDeviceItems from './SessionDeviceItems';

const SessionUser = ({ id = '', deviceItems = [], onClickSession = () => {}, onSignOut = () => {} }) => {
    const { t } = useTranslation('setting');

    return (
        <div
            id={id}
            className={classNames('wrapbox-user__frame wrap-sessions', {
                'is-empty': !deviceItems.length
            })}
        >
            <div className="wrap-sessions__header">
                <h5 className="fs-14">{t('active_devices')}</h5>
                <span className="dots fs-13">{t('no_active_devices')}</span>
            </div>
            <div className="wrap-sessions__list">
                <SessionDeviceItems data={deviceItems} onSignOut={onSignOut} />
            </div>
            <div className="wrap-sessions__link">
                <div
                    className="v2-btn-default --icon-r --purple svg-purple"
                    onClick={() => onClickSession(MODES_HANDLE_SETTING_USERS.SESSION)}
                >
                    {t('session_history')}
                    <IconDoubleStep />
                </div>
            </div>
        </div>
    );
};

export default SessionUser;
