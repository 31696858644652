import { call } from 'redux-saga/effects';
import { deleteWithToken, fetchWithToken } from 'common/utils/ApiUtils';
import { REPORT_CREDIT_GET_LIST, REPORT_CREDIT_DELETE } from 'app/const/Api';

export function* getListReportCredit({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_CREDIT_GET_LIST,
            params
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}

export function* deleteReportCredit({ params, actionSuccess, actionFailed }) {
    try {
        const response = yield call(
            deleteWithToken,
            REPORT_CREDIT_DELETE,
            params
        );
        if (response && response.success && !response.error) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
