import React from 'react';
import IconArrow from 'assets/icon/IconArrow';

const PaymentHeader = ({ invoiceNumber, onClose }) => {
    return (
        <div className="header-modal flex-auto">
            <div className="name-tabs">
                <div onClick={onClose} className="v2-btn-default --icon-lg --transparent" tabIndex="0">
                    <IconArrow isPrev />
                </div>
                <div className="txt-ellipsis ml-2">Add Payment • Invoice {invoiceNumber}</div>
            </div>
            <div onClick={onClose} className="v2-btn-default --transparent" tabIndex="0">
                Cancel
            </div>
        </div>
    );
};

export default PaymentHeader;
