import React, { useRef } from 'react';
import IconClose from 'assets/icon/IconClose';

const ItemFilteredError = ({ idError = '', onRemove = () => {}, onDisplayTooltip = () => {} }) => {
    const refEl = useRef(null);
    const _handleMouseOver = (event) => {
        onDisplayTooltip(event?.currentTarget, {
            operatorData: {
                label: 'Unrecognized field ' + idError + '. That could be a Custom Field' + idError + " doesn't exist.",
                useQuotes: false
            }
        });
    };
    const _handleMouseOut = () => {
        onDisplayTooltip(null);
    };
    return (
        <div
            ref={refEl}
            onMouseOver={_handleMouseOver}
            onMouseOut={_handleMouseOut}
            className="wrapper__filter-tags --error flexcenter btn-modal tooltip"
        >
            <span className="red-default fw-600">Field error</span>
            <span className="icon-close" onClick={onRemove}>
                <IconClose isSmall />
            </span>
        </div>
    );
};

export default ItemFilteredError;
