export const TYPE_REPORT_REVENUE_SERVICE = {
    GET_LIST_MONTH_TAB: 'GET_LIST_REPORT_REVENUE_SERVICE',
    GET_LIST_DETAIL_TAB: 'GET_LIST_REPORT_REVENUE_SERVICE_PAYMENT',
    GET_LIST_METHOD_FILTER_REVENUE_BY_SERVICE: 'GET_LIST_METHOD_FILTER_REVENUE_BY_SERVICE',
    GET_LIST_SERVICE_FILTER_REVENUE_BY_SERVICE: 'GET_LIST_SERVICE_FILTER_REVENUE_BY_SERVICE'
};

// actions
export const getListReportRevenueByServiceMonth = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SERVICE.GET_LIST_MONTH_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListReportRevenueByServiceDetail = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SERVICE.GET_LIST_DETAIL_TAB,
        params,
        actionSuccess,
        actionFailed
    };
};

export const getListMethodFilterRevenueByServiceRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SERVICE.GET_LIST_METHOD_FILTER_REVENUE_BY_SERVICE,
        actionSuccess,
        actionFailed
    };
};

export const getListServiceFilterRevenueByServiceRequest = (actionSuccess, actionFailed = () => {}) => {
    return {
        type: TYPE_REPORT_REVENUE_SERVICE.GET_LIST_SERVICE_FILTER_REVENUE_BY_SERVICE,
        actionSuccess,
        actionFailed
    };
};
