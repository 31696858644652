import React from 'react';

const LoadingAdd = () => {
    return (
        <div className="wrap-loading body-modal --assign">
            <div className="row">
                <div className="check-items w-100">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="check-items w-100">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="check-items w-100">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="check-items w-100">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="check-items w-100">
                    <div className="checkbox" />
                    <div className="item-checkbox flex-1">
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingAdd;
