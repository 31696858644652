import classNames from 'classnames';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import ContentNoteVisible from 'app/components/notevisible/Content';
import TemplateOptions from 'app/components/templatenotes';
import { NOTES_TEMPLATE_TYPE } from 'app/const/Notes';
import SaveAsTemplate from 'app/modules/customer/note/detail/SaveAsTemplate';
import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconNote from 'assets/icon/IconNote';
import IconSync from 'assets/icon/IconSync';
import { actionToggleInsertJobNotes } from 'common/redux/actions/notesAction';
import { getWorkOrderNotes } from 'app/const/api/Notes';
import { clientQuery } from 'common/utils/ApiUtils';
import { LIST_ACTIONS } from 'app/modules/jobdetail/const';

const InvoiceNotes = ({ isInvoice = true, jobId = '' }) => {
    const { t } = useTranslation(['jobDetail']);
    const { invoiceData, onUpdateInvoiceDataContext } = useContext(AddInvoiceContext);
    const dispatch = useDispatch();

    const refContentNoteVisible = useRef(null);
    const refSaveAsTemplate = useRef(null);
    const refFirstTimeInsertNote = useRef(true);
    const refInsertWorkOrder = useRef('');

    const {
        note: invoiceNote,
        customer_job_id: finalJobId,
        recurrence: finalRecurrence,
        note_repeat: finalNoteRepeat
    } = invoiceData;

    const typeTemplate = isInvoice ? NOTES_TEMPLATE_TYPE.INVOICE : NOTES_TEMPLATE_TYPE.ESTIMATE;
    const isActiveNoteRecurrence = !!finalRecurrence?.repeat && !!finalNoteRepeat;

    useEffect(() => {
        dispatch(actionToggleInsertJobNotes(null));
        if (refFirstTimeInsertNote.current) _handleGetInsertWorkOrder();
    }, []);

    function _handleSelectTemplate({ content: newContent, type = '' }) {
        const content = type === LIST_ACTIONS.INSERT_WORK_ORDER_NOTE ? refInsertWorkOrder.current : newContent;
        refContentNoteVisible.current.setValue(content, true);
    }

    const _handleUpdateNote = (content) => {
        onUpdateInvoiceDataContext({ note: content });
    };

    const openSaveAs = () => {
        refSaveAsTemplate.current.open(invoiceNote, typeTemplate);
    };

    const _handleGetInsertWorkOrder = () => {
        if (!jobId) return;
        const _handleSuccess = ({ data }) => {
            const note = data.note;
            refFirstTimeInsertNote.current = false;
            if (!note) return;
            refInsertWorkOrder.current = note;
        };
        clientQuery(getWorkOrderNotes(jobId), { method: 'GET' }, _handleSuccess);
    };

    return (
        <div className={classNames('boxs --terms-note', { 'has-recursjob': isActiveNoteRecurrence })}>
            <div className="left-icons">
                <IconNote isActiveColor={!finalNoteRepeat} isActiveNoteRecurring={isActiveNoteRecurrence} />
            </div>
            <div className="content elm-parent has-edited">
                <div className="header-box">
                    <div className="name">
                        <div className="name__label">
                            <h4 className="fs-13">{t('jobDetail:notes')}</h4>
                            <span className="fs-10 fw-500 grey-generic">{t('jobDetail:visible_to_client')}</span>
                        </div>
                    </div>
                    {isInvoice && !!finalRecurrence?.repeat ? (
                        <div
                            className={classNames('v2-btn-default --icon-lg --transparent tooltip click-icon mr-1', {
                                active: finalNoteRepeat
                            })}
                            onClick={() => onUpdateInvoiceDataContext({ note_repeat: !finalNoteRepeat ? 1 : 0 })}
                        >
                            <div className="click-icon__btn stick">
                                <IconSync />
                                <span className="tooltiptext top sp-hide">{t('jobDetail:repeat_invoice_note')}</span>
                            </div>
                            <div className="click-icon__btn unstick">
                                <IconSync isActive />
                                <span className="tooltiptext top sp-hide">{t('jobDetail:invoice_note_repeat')}</span>
                            </div>
                        </div>
                    ) : null}
                    <TemplateOptions
                        isShow
                        typeFilter={typeTemplate}
                        onSelectTemplate={_handleSelectTemplate}
                        isInsertJobNote={!!finalJobId}
                        isShowSaveAs={!!invoiceNote && invoiceNote !== '<br/>'}
                        onOpenSaveAs={openSaveAs}
                        isInsertWorkOrderNote={!!jobId}
                    />
                </div>
                <ContentNoteVisible
                    ref={refContentNoteVisible}
                    content={invoiceNote}
                    showRightToolbar={false}
                    onBlur={_handleUpdateNote}
                />
                <SaveAsTemplate ref={refSaveAsTemplate} />
            </div>
        </div>
    );
};

export default InvoiceNotes;
