import { LIST_STATUS } from 'app/const/Status';
import AlertCustomer from 'app/modules/customer/components/AlertCustomer';
import React, { useContext, useEffect, useRef } from 'react';
import { SubscriptionContext } from '../../context/SubscriptionContext';

const AlertAddSubscription = () => {
    const { errors } = useContext(SubscriptionContext);
    const refAlert = useRef(null);

    useEffect(() => {
        if (errors.length) refAlert.current.showStatusBar({ id: 'error', message: errors, type: LIST_STATUS.ERROR });
    }, [errors]);

    return <AlertCustomer ref={refAlert} />;
};

export default AlertAddSubscription;
