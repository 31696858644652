import React, { forwardRef, useReducer, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useClickOutside from 'common/hooks/useClickOutside';
import QuestLog from 'app/modules/questLog';
import IconRocket from 'assets/icon/IconRocket';
import { getTimestamp } from 'common/utils/DateTimeUtils';
import { getQuestLogs } from 'common/redux/actions/questlogAction';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';

const QuestlogHeader = (props, ref) => {
    const { t } = useTranslation(['header']);
    const questLogActive = useSelector(({ auth }) => auth.user.settings.questlog.question_active);
    const [state, dispatchAction] = useReducer(reducer, {
        questLogInfo: null,
        lastTimeClicked: 0,
        isLoading: false,
        isShow: !!questLogActive
    });
    const [refMenu, isMenuVisible, setIsMenuVisible] = useClickOutside(false);
    const dispatch = useDispatch();

    const classActive = isMenuVisible ? 'active' : '';

    useImperativeHandle(ref, () => ({
        setActive: handleActive
    }));

    const handleActive = (value) => {
        dispatchAction({ isShow: value });
    };

    const handleActiveMenu = () => {
        const now = getTimestamp();
        if (!isMenuVisible) {
            if (now - state.lastTimeClicked > 60 || !state.questLogInfo) {
                handleGetQuestLog(now);
            }
        }
        setIsMenuVisible(!isMenuVisible);
    };

    const handleGetQuestLog = (latestTimeClicked) => {
        const handleOnSuccess = (resp) => {
            dispatchAction({
                lastTimeClicked: latestTimeClicked,
                questLogInfo: resp,
                isLoading: false
            });
        };
        const handleOnFailed = () => {
            dispatchAction({ isLoading: false });
        };
        dispatchAction({ isLoading: true });
        dispatch(getQuestLogs(handleOnSuccess, handleOnFailed));
    };

    const _handleCloseQuestLog = () => {
        setIsMenuVisible(false);
    };

    if (!state.isShow) {
        return null;
    }

    return (
        <div className={`v2-dropdown questlog-menu ${classActive}`} ref={refMenu}>
            <div className="dropbtn v2-btn-default --icon-lg tooltip" onClick={handleActiveMenu}>
                <IconRocket />
                <p className="tooltiptext bottom">{t('questlog')}</p>
            </div>
            <QuestLog
                info={state.questLogInfo}
                isLoading={state.isLoading && isMenuVisible}
                onClose={_handleCloseQuestLog}
            />
        </div>
    );
};

export default forwardRef(QuestlogHeader);
