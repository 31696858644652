import loadable from '@loadable/component';
import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { CUSTOMERS_DOCUMENTS } from 'app/config/routes';
import { REPORT_DOCUMENT_GET_LIST } from 'app/const/Api';
import { COMMON, DELAY_TIME, KEY_REPORT_LOCAL_STORAGE, LIST_STATUS, TYPE_BUTTON_ACTIONS } from 'app/const/App';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import { REPORT_LIMIT, REPORT_LIMIT_LOADMORE } from 'app/const/Reports';
import { URL_EXPORT_DOCUMENT } from 'app/const/api/Export';
import { LIST_EXPORT } from 'app/const/report/Common';
import { getGidColumns } from 'app/const/report/Documents';
import { DOCUMENT_LIST_FILTER } from 'app/const/report/ReportFilter';
import { MODE_VIEW_REPORT, REPORT_TYPE, getListButtonDocument } from 'app/const/report/ReportTypeContent';
import { handleAbortController } from 'app/modules/customer/utils';
import { actionOpenDocumentDetail } from 'common/redux/actions/documentAction';
import {
    deleteReportDocumentRequest,
    trashReportDocumentRequest,
    unDeleteReportDocumentRequest
} from 'common/redux/actions/reports/documentAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { handleActionHeaderReport } from 'common/utils/GridViewUtils';
import { getLocalStorage } from 'common/utils/LocalStorageUtils';
import { convertParamFields, getLocalParamsReport } from 'common/utils/ReportUtils';
import HeaderBottom from '../components/HeaderBottom';

const GdButton = loadable(() => import('app/components/button'));
const GdGridView = loadable(() => import('app/components/grid/GdGridView'));
const GDStatusBar = loadable(() => import('app/components/status/statusbar'));
const Export = loadable(() => import('app/modules/report/components/Export'));
const MainHeaderReport = loadable(() => import('app/modules/report/components/MainHeader'));
const ReportSearch = loadable(() => import('app/modules/report/components/ReportSearch'));
const CheckBoxHeader = loadable(() => import('app/modules/report/components/CheckBoxHeader'));

function ReportDocument() {
    const { t } = useTranslation(['report']);
    const dispatch = useDispatch();
    const history = useHistory();

    const [dataReport, dispatchActionReport] = useReducer(reducer, {
        refesh: false,
        dataProgressBar: false,
        checkedItems: { is_check_all: false, ids: [] },
        data: [],
        isLoading: true,
        isLoadmore: false,
        total: 0,
        refreshScreen: 0,
        reloadFilter: 0,
        actionSuccess: 0
    });

    const {
        refreshScreen,
        data: finalData,
        isLoading: finalIsLoading,
        isLoadmore: finalIsLoadmore,
        checkedItems,
        total: finalTotal,
        actionSuccess
    } = dataReport;

    const { ids } = checkedItems;
    const numberCurrentData = finalData.length;
    const refAlert = useRef(null);
    const abortController = useRef(null);

    const keyLocal = KEY_REPORT_LOCAL_STORAGE.concat('_', REPORT_TYPE.DOCUMENT);
    const paramsReport = getLocalParamsReport(keyLocal, REPORT_TYPE.DOCUMENT);

    useEffect(() => {
        getListReport(paramsReport);

        return () => {
            handleAbortController(abortController);
        };
    }, []);

    useEffect(() => {
        if (
            actionSuccess &&
            numberCurrentData < finalTotal &&
            numberCurrentData < REPORT_LIMIT_LOADMORE &&
            !finalIsLoading &&
            !finalIsLoadmore
        ) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    isLoadmore: true
                };
            });
        }
    }, [actionSuccess]);

    useEffect(() => {
        const loadMoreTimer =
            finalIsLoadmore &&
            setTimeout(() => {
                getListReport(
                    { ...paramsReport, offset: finalData.length, reloadFilter: dataReport.reloadFilter },
                    true,
                    false
                );
            }, DELAY_TIME);

        return () => {
            if (loadMoreTimer) clearTimeout(loadMoreTimer);
        };
    }, [finalIsLoadmore]);

    const getListReport = (params, notShowLoading = false, isReset = true) => {
        handleAbortController(abortController);
        abortController.current = new AbortController();
        params = getNewAPIRequest(params);
        const checkShouldLoading = !notShowLoading && !finalIsLoading;
        const checkRefresh = dataReport.reloadFilter;

        if (ids.length || checkShouldLoading) {
            dispatchActionReport((prev) => {
                return {
                    ...prev,
                    data: isReset ? [] : prev.data,
                    isLoading: !!checkShouldLoading || prev.isLoading,
                    checkedItems: checkShouldLoading ? { is_check_all: false, ids: [] } : prev.checkedItems
                };
            });
        }

        delete params?.columns;
        delete params?.filterTab;
        delete params?.archived;
        delete params?.currentPage;
        delete params?.reloadFilter;

        clientQuery(
            REPORT_DOCUMENT_GET_LIST,
            {
                data: convertParamFields({ params, isEmptyFields: finalIsLoadmore }),
                method: 'GET',
                abortController: abortController.current
            },
            (res) => getListSuccess({ ...res, isReset }, checkRefresh),
            getListFailed
        );
    };

    const getNewAPIRequest = (params) => {
        if (!params.total) {
            params.total = 1;
        }

        params.status = params.status.toString();
        params.types = params.types.toString();
        params.limit = REPORT_LIMIT;
        return params;
    };

    const getListSuccess = ({ data, total, isReset }, tabQuery) => {
        dispatchActionReport((prev) => {
            const { checkedItems } = prev;
            const checkDiffrentTabs = !!tabQuery && prev.reloadFilter !== tabQuery;
            const newData = isReset ? data : checkDiffrentTabs ? prev.data : [...prev.data, ...data];

            return {
                ...prev,
                isLoading: false,
                isLoadmore: false,
                data: newData,
                total: total ?? prev.total ?? 0,
                refreshScreen: prev.refreshScreen + 1,
                checkedItems: {
                    ...checkedItems,
                    ids: checkedItems.is_check_all ? newData.map((e) => e.id) : checkedItems.ids
                }
            };
        });
    };

    function getListFailed({ isAborted = false }) {
        if (isAborted) return;
        dispatchActionReport((prev) => ({ ...prev, isLoading: false, isLoadmore: false }));
    }

    const handleChangeFilter = (params, mode) => {
        if (mode && mode === 'columns') dispatchActionReport({ refesh: !dataReport.refesh });
    };

    const _handleUpdate = () => {
        abortController.current.abort();
        getListReport(getLocalStorage(keyLocal));
    };

    const onChangeStateCheckedItems = (stateCheckedItems) => {
        dispatchActionReport({ checkedItems: stateCheckedItems });
    };

    const handleClickButton = (value) => {
        const ids = dataReport.checkedItems.ids;

        const newDataReport = [...finalData];

        newDataReport.forEach((item) => {
            if (ids.includes(item.id)) {
                item.manualHide = true;
            }
        });
        dispatchActionReport({
            data: newDataReport,
            total: finalTotal - ids.length,
            checkedItems: { is_check_all: false, ids: [] }
        });

        switch (value) {
            case TYPE_BUTTON_ACTIONS.DELETE:
                dispatch(
                    deleteReportDocumentRequest(
                        { ids: ids },
                        (response) => clickButtonSuccess(response, t(`report:delete_success`)),
                        (response) => {
                            _handleActionFailed(response);
                        }
                    )
                );
                break;
            case TYPE_BUTTON_ACTIONS.UNDELETE:
                dispatch(
                    unDeleteReportDocumentRequest({ ids: ids, type: value }, clickButtonSuccess, (response) => {
                        _handleActionFailed(response, ids);
                    })
                );
                break;
            case TYPE_BUTTON_ACTIONS.TRASH:
                dispatch(
                    trashReportDocumentRequest({ ids: ids, type: value }, clickButtonSuccess, (response) => {
                        _handleActionFailed(response, ids);
                    })
                );
                break;
            default:
                break;
        }
    };

    function _handleActionFailed(response) {
        refAlert.current.handeAddStatus({
            id: `${Date.now()}_error`,
            message: response.message,
            type: LIST_STATUS.ERROR
        });
    }

    const clickButtonSuccess = (response, msgSuccess) => {
        const responseMessage = response?.message;
        const finalMessage = responseMessage || msgSuccess;

        const errorResponse = response.error;
        const idsFailed = errorResponse?.items || [];
        const numberIdsFailed = idsFailed.length;

        if (!!errorResponse || !!numberIdsFailed) {
            refAlert.current.handeAddStatus({
                id: `${Date.now()}_error`,
                message: finalMessage,
                type: LIST_STATUS.ERROR
            });
        }

        refAlert.current.handeAddStatus({
            id: `${Date.now()}_sussess`,
            message: finalMessage,
            type: LIST_STATUS.SUCCESS
        });

        dispatchActionReport((prev) => {
            const prevData = [...prev.data];
            const prevCheckedIds = prev.checkedItems.ids;

            return {
                ...prev,
                checkedItems: {
                    is_check_all: numberIdsFailed === ids.length,
                    ids: prevCheckedIds.filter((item) => idsFailed.includes(item))
                },
                data: prevData
                    .map((item) => {
                        if (idsFailed.includes(item.id)) {
                            item.manualHide = false;
                        }
                        return item;
                    })
                    .filter((itemRemove) => !itemRemove.manualHide),
                total: prev.total + numberIdsFailed,
                actionSuccess: Date.now()
            };
        });
    };

    const _handleClick = ({ key, row }) => {
        switch (key) {
            case COMMON.ACCOUNT_NUMBER:
            case COMMON.CUSTOMER:
                history.push(addBranchPath(CUSTOMERS_DOCUMENTS.replace(':id', row.customer?.id)));
                break;
            case COMMON.DOCUMENT_TYPE:
            case COMMON.DOCUMENT_NUMBER:
                if (getLocalStorage(keyLocal)?.deleted) return;
                dispatch(
                    actionOpenDocumentDetail({
                        id: row.id,
                        name: row.document_type,
                        onUpdateProp: _handleForceUpdate,
                        onDeleteProp: _handleDelete
                    })
                );
                break;
            default:
                break;
        }
    };

    function _handleForceUpdate(documentId, data) {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                data: prev.data.map((item) => {
                    if (item.id.toString() === documentId.toString()) {
                        return {
                            ...item,
                            ...data
                        };
                    }
                    return item;
                })
            };
        });
    }

    function _handleDelete(documentId) {
        dispatchActionReport((prev) => {
            return {
                ...prev,
                total: prev.total - 1,
                data: prev.data.filter((item) => item.id?.toString() !== documentId?.toString()),
                checkedItems: {
                    ...prev.checkedItems,
                    ids: prev.checkedItems.ids?.filter((id) => id.toString() !== documentId.toString())
                }
            };
        });
    }

    const _handleScroll = () => {
        const numberData = finalData?.length;
        if (!!numberData && numberData < finalTotal && !finalIsLoadmore) {
            const refreshId = Date.now();
            dispatchActionReport((prev) => ({ ...prev, isLoadmore: true, reloadFilter: refreshId }));
        }
    };

    const renderListActionButton = () => {
        if (!paramsReport) {
            return false;
        }

        let finalMode = MODE_VIEW_REPORT.ACTIVE;

        switch (paramsReport.deleted - paramsReport.archived) {
            case 1:
                finalMode = MODE_VIEW_REPORT.DELETE;
                break;
            case 0:
                finalMode = MODE_VIEW_REPORT.ACTIVE;
                break;
            default:
                break;
        }

        return getListButtonDocument(finalMode).map((item) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`report:${item.label}`)}
                    onClick={() => {
                        handleClickButton(item.value);
                    }}
                    className={item.className}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    const _handleActionHeader = ({ actionType, columnsTarget, currentValue }) => {
        handleActionHeaderReport({
            actionType: actionType,
            reportType: REPORT_TYPE.DOCUMENT,
            columnsTarget,
            currentValue,
            paramsReport,
            callBack: _handleUpdate
        });
    };

    function _renderHeaderRight() {
        return (
            <>
                <Export
                    title={t('report:records', { count: dataReport.total })}
                    activePrint
                    url={URL_EXPORT_DOCUMENT}
                    params={paramsReport}
                    pageExport={LIST_EXPORT.EXPORT_REPORT_DOCUMENT}
                    isDisable={finalIsLoading}
                    refresh={refreshScreen}
                />
                <ReportSearch
                    reportType={REPORT_TYPE.DOCUMENT}
                    placeholder={t('report:search')}
                    onKeyEnter={_handleUpdate}
                />
            </>
        );
    }

    function _renderHeaderBootom() {
        const idsSelected = dataReport.checkedItems.ids.length || 0;

        return (
            <div className={classNames('header --filter', { 'is-disable': !idsSelected })}>
                <CheckBoxHeader isShowTotal total={finalTotal} checkedItems={dataReport.checkedItems} />
                {renderListActionButton()}
            </div>
        );
    }

    return (
        <>
            <MainHeaderReport
                contentRight={_renderHeaderRight}
                reportType={REPORT_TYPE.DOCUMENT}
                onSelectTab={_handleUpdate}
            />
            <div className="wrapper-columns">
                <div className="container-print contents-pages gap-8">
                    <GDStatusBar ref={refAlert} />
                    <HeaderBottom
                        typeReport={REPORT_TYPE.DOCUMENT}
                        filters={DOCUMENT_LIST_FILTER}
                        isLoading={finalIsLoading}
                        handleChangeFilter={handleChangeFilter}
                        handleUpdate={_handleUpdate}
                    />

                    <div className="wrap-tables flex-column relative">
                        {_renderHeaderBootom()}
                        <GdGridView
                            isEmptyFlat
                            isLoading={finalIsLoading}
                            classTable="table-multi-column has-checkbox scrolls-x has-text-ellipsis"
                            classTableContent=""
                            content={finalData.filter((item) => !item.manualHide)}
                            showCheckBox
                            fileTranslation={'report'}
                            handleClick={_handleClick}
                            checkedItems={dataReport.checkedItems}
                            onChangeStateCheckedItems={onChangeStateCheckedItems}
                            {...getGidColumns(paramsReport?.columns, paramsReport?.order)}
                            handleClickHeader={_handleActionHeader}
                            isScroll
                            isLoadmore={finalIsLoadmore}
                            onScrollToEnd={_handleScroll}
                            autoLoad={false}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportDocument;
