import { call, put } from 'redux-saga/effects';
import { getFetch } from 'common/utils/ApiUtils';
import { TYPES_TIME_WINDOW } from 'common/redux/actions/timeWindowAction';
import { GET_LIST_TIME_WINDOW_TEMPLATE } from 'app/const/api/V2';

export function* actionGetListTimeWindow() {
    const response = yield call(getFetch, GET_LIST_TIME_WINDOW_TEMPLATE);
    if (response && response.success) {
        yield put({
            type: TYPES_TIME_WINDOW.ACTION_GET_LIST_TIME_WINDOW_SUCCESS,
            payload: response.data
        });
    }
}
