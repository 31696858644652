import classNames from 'classnames';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import { reducer } from 'app/const/Reducer';
import { LIST_TERM_SETTING_PLANS, LIST_TYPE_PACKAGE } from 'app/const/setting/SettingPlans';
import LoadingPlan from './LoadingPlan';
import { countSavedFee } from 'common/utils/SettingUtils';

const StatusBar = loadable(() => import('app/components/status/statusbar'));
const AccountPlan = loadable(() => import('app/modules/settings/plans/components/AccountPlan'));
const PlanPackage = loadable(() => import('app/modules/settings/plans/components/PlanPackage'));

const PlanStepOne = ({ initialData = {}, isFetchedPlan = false, plans = {}, onContinue = () => {} }, ref) => {
    const { t } = useTranslation('addons');
    const refStatusBar = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        package: LIST_TYPE_PACKAGE.BASIC,
        term: LIST_TERM_SETTING_PLANS[0].key,
        schedules: 1,
        fee: 0,
        savedFee: 0,
        ...initialData
    });
    const {
        term: finalTerm,
        schedules: finalSchedules,
        fee: finalFee,
        savedFee: finalSavedFee,
        package: finalPackage
    } = state;

    useEffect(() => {
        if (isFetchedPlan)
            dispatchState((prev) => ({
                ...prev,
                fee: _getPackageData({})?.price || 0,
                savedFee: countSavedFee({ selectedPackage: finalPackage, numUser: finalSchedules, listPlans: plans })
            }));
    }, [isFetchedPlan]);

    useImperativeHandle(ref, () => ({
        _getValue: _handleGetValue
    }));

    const _handleGetValue = () => {
        return { ...state };
    };

    const _handleChangePackage = (value) => {
        const { price } = _getPackageData({ packagePlan: value });
        dispatchState((prev) => ({
            ...prev,
            package: value,
            fee: price,
            savedFee: countSavedFee({
                selectedPackage: value,
                numUser: finalSchedules,
                listPlans: plans
            })
        }));
    };

    const _handleChangeTerm = (value) => {
        const keyTerm = LIST_TERM_SETTING_PLANS.find((item) => item.value === value)?.key;
        const { price } = _getPackageData({ termPlan: keyTerm });
        dispatchState((prev) => ({ ...prev, term: keyTerm, fee: price }));
    };

    const _handleChangeAmountUser = (value) => {
        const { price } = _getPackageData({ schedulesPlan: value });
        dispatchState((prev) => ({
            ...prev,
            schedules: value,
            fee: price,
            savedFee: countSavedFee({
                selectedPackage: finalPackage,
                numUser: value,
                listPlans: plans
            })
        }));
    };

    const _getPackageData = ({ packagePlan, termPlan, schedulesPlan }) => {
        if (!Object.keys(plans).length) return {};
        const selectedTerm = [...plans[termPlan || finalTerm][packagePlan || finalPackage]];
        return selectedTerm.find((item) => item.schedule_num === (schedulesPlan || finalSchedules)) || {};
    };

    if (!isFetchedPlan) return <LoadingPlan />;

    return (
        <div className="wrap-plans flex-column gap-12">
            <h3 className="fs-14 fw-600">{t('start_by_selecting_your_plan')}</h3>
            <StatusBar ref={refStatusBar} />

            <div className="page-plans">
                <AccountPlan
                    users={finalSchedules}
                    fee={finalFee}
                    savedFee={finalSavedFee}
                    term={LIST_TERM_SETTING_PLANS.find((item) => item.key === finalTerm)?.value}
                    packageType={finalPackage}
                    onChangeTerm={_handleChangeTerm}
                    onChangeAmountUser={_handleChangeAmountUser}
                    numberSchedules={plans[finalTerm]?.[finalPackage]?.length || 1}
                />

                <PlanPackage selectedPackage={finalPackage} onChange={_handleChangePackage} />
            </div>
            <div className="d-flex justify-end">
                <a
                    className={classNames('v2-btn-default has-bg-blue white --transparent', {
                        'is-disable': !isFetchedPlan
                    })}
                    onClick={onContinue}
                >
                    {t('continue')}
                </a>
            </div>
        </div>
    );
};

export default forwardRef(PlanStepOne);
