import moment from 'moment-timezone';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { SocketContext } from 'app/services/socket/SocketProvider';
import { convertPhpFormatToMoment } from 'common/utils/DateUtils';

export default function RealtimeJobLogs({ onAddNewLog = () => {} }) {
    const { logCreate: logCreateData } = useContext(SocketContext);
    const { company, settings } = useSelector(({ auth }) => auth.user);

    useEffect(() => {
        if (logCreateData) {
            onAddNewLog({
                ...logCreateData,
                date: moment
                    .unix(logCreateData.date)
                    .tz(settings.timezone)
                    .format(`hh:mmA ${convertPhpFormatToMoment(company.date_format)}`)
            });
        }
    }, [logCreateData]);

    return null;
}
