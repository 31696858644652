import IconArrowDown from 'assets/icon/IconArrowDown';
import React from 'react';

export default function ShowMore({ onToggle = () => {}, isShowMore = false }) {
    return (
        <div className={`todo-items-action ${isShowMore ? 'is-change-btn' : ''}`}>
            <div className="v2-btn-default btn-bg-grey --icon-r fs-13 --transparent" onClick={onToggle}>
                {!isShowMore ? 'Hide' : 'Show All'} <IconArrowDown isUp={!isShowMore} />
            </div>
        </div>
    );
}
