import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GdButton from 'app/components/button';

function ListButtonAction({ list, fileTranslation, onSubmit }) {
    const { t } = useTranslation(['customers']);

    if (!Array.isArray(list)) return null;

    const handleSubmit = (value) => {
        onSubmit(value);
    };

    const renderListButton = (list) => {
        return list.map((item, index) => {
            return (
                <GdButton
                    key={item.id}
                    title={t(`${fileTranslation}:${item.label}`)}
                    onClick={() => {
                        handleSubmit(item.value);
                    }}
                    className={`${item.className} ${index === 0 && 'header-items'}`}
                    iconClassName={item.iconClassName}
                    iconSvg={item.iconSvg}
                />
            );
        });
    };

    return renderListButton(list);
}

ListButtonAction.propTypes = {
    list: PropTypes.array,
    fileTranslation: PropTypes.string,
    onSubmit: PropTypes.func
};

ListButtonAction.defaultProps = {
    list: [],
    fileTranslation: 'customers',
    onSubmit: () => {}
};

export default ListButtonAction;
