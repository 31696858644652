import { PROFILE_AUTHENTICATION, PROFILE_AUTHENTICATION_ACTIVE_DEVICE } from 'app/config/routes';
import ProfileAuthentication from '..';
import IconAccount from 'assets/icon/IconAccount';
import IconUser from 'assets/icon/IconUser';
import ProfileActiveDevices from '../profileActiveDevices';

export const ROUTES_AUTH_PROFILE = [
    {
        path: PROFILE_AUTHENTICATION,
        component: ProfileAuthentication,
        exact: true,
        title: 'Authentication',
        icon: <IconAccount />
    },
    {
        path: PROFILE_AUTHENTICATION_ACTIVE_DEVICE,
        component: ProfileActiveDevices,
        exact: true,
        title: 'Active Devices',
        icon: <IconUser />
    }
];
