import { transformToCurrency } from 'common/utils/NumberUtils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StatementTotal = ({ isLoading, totalStatement = {} }) => {
    const { t } = useTranslation('customers');
    const { amount_total, paid_total, account_balance } = totalStatement;
    const currency = useSelector(({ auth }) => auth?.user?.settings?.currency);

    if (isLoading) return <StatementTotalLoading />;

    return (
        <div className="account-summary --is-total flex-column gap-4 fs-16 fw-600">
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('amount_total')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(amount_total), currency)}
                </span>
            </div>
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('paid_total')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(paid_total), currency)}
                </span>
            </div>
            <div className="details flex-betweenitems gap-10">
                <span className="details__label txt-ellipsis">{t('account_balance')}</span>
                <span className="details__label txt-ellipsis text-right">
                    {transformToCurrency(parseFloat(account_balance), currency)}
                </span>
            </div>
        </div>
    );
};

const StatementTotalLoading = () => (
    <div className="wrap-loading account-summary flex-column gap-12">
        <div className="details flex-betweenitems gap-10">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="details flex-betweenitems gap-10">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
        <div className="details flex-betweenitems gap-10">
            <span className="details__label">
                <div className="loading --animation --line --half" />
            </span>
            <span className="details__label d-flex justify-end">
                <div className="loading --animation --line --half" />
            </span>
        </div>
    </div>
);

export default StatementTotal;
