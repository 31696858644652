export const BATCH_ACTIONS_TYPE = {
    1: 'INVOICES_EMAIL_REQUEST',
    2: 'INVOICES_PRINT',
    3: 'INVOICES_GENERATE_PDF',
    4: 'INVOICES_SYNC_TO_QB',
    5: 'ESTIMATES_EMAIL',
    6: 'ESTIMATES_PRINT',
    7: 'SERVICE_LOOKUP_REASSIGN_JOBS',
    8: 'ACCOUNTS_AGING_PRINT_INVOICE',
    9: 'ACCOUNTS_AGING_GENERATE_INVOICE_PDF',
    10: 'ACCOUNTS_AGING_PRINT_STATEMENT',
    11: 'ACCOUNTS_AGING_GENERATE_STATEMENT_PDF',
    12: 'ACCOUNTS_AGING_EMAIL_LATE_PAYMENT_REMINDER',
    13: 'ACCOUNTS_AGING_SMS_LATE_PAYMENT_REMINDER',
    14: 'ACCOUNTS_AGING_EMAIL_STATEMENT'
};

// actions
export const batchActionsRequest = (params, actionSuccess, actionFailed = () => {}) => {
    return {
        type: BATCH_ACTIONS_TYPE[params.value],
        params,
        actionSuccess,
        actionFailed
    };
};
