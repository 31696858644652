import { COMMON, PAGE_SIZE_DEFAULT } from 'app/const/App';
import * as SETTING from './SettingPageName';
import { LIST_ACTION_TAGS, LIST_ACTION_BASIC } from 'app/const/Settings';

export const getDefaultParams = (settingPageName) => {
    const SETTING_DEFAULT_PARAMS = {
        [SETTING.TAXES]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            active: 1,
            archived: 0,
            deleted: 0,
            order: COMMON.NAME.concat(' ', COMMON.ASC),
            fields: COMMON.RATE
        },
        [SETTING.LINE_ITEMS]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            active: 1,
            archived: 0,
            deleted: 0,
            order: COMMON.NAME.concat(' ', COMMON.ASC)
        },
        [SETTING.PAYMENT_METHODS]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            active: 1,
            archived: 0,
            deleted: 0,
            order: COMMON.NAME.concat(' ', COMMON.ASC)
        },
        [SETTING.SOURCES]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            active: 1,
            archived: 0,
            deleted: 0,
            order: COMMON.NAME.concat(' ', COMMON.ASC)
        },
        [SETTING.TAGS]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            type: 1,
            order: COMMON.NAME.concat(' ', COMMON.ASC)
        },
        [SETTING.SERVICE]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1,
            fields: `${COMMON.LENGTH},${COMMON.COLOR_CODE},${COMMON.FREQUENCY}`,
            order: COMMON.NAME.concat(' ', COMMON.ASC)
        },
        [SETTING.BROADCAST]: {
            fields: `${COMMON.SUBJECT},${COMMON.EMAIL_CONTENT},${COMMON.SMS_CONTENT}`
        },
        [SETTING.EMAIL_INBOX]: {
            fields: `${COMMON.SUBJECT},${COMMON.CONTENT}`
        },
        [SETTING.SCHEDULES]: {
            limit: PAGE_SIZE_DEFAULT,
            offset: 0,
            total: 1
        }
    };
    return SETTING_DEFAULT_PARAMS[settingPageName];
};

export const getTabParams = (tabName) => {
    const SETTING_PARAMS_TABS = {
        [LIST_ACTION_BASIC.ACTIVE]: { active: 1, archived: 0, deleted: 0 },
        [LIST_ACTION_BASIC.ARCHIVE]: { active: 0, archived: 1, deleted: 0 },
        [LIST_ACTION_BASIC.DELETE]: { active: 0, archived: 0, deleted: 1 },
        [LIST_ACTION_TAGS.CUSTOMER]: { type: 1 },
        [LIST_ACTION_TAGS.LOCATION]: { type: 2 }
    };

    return SETTING_PARAMS_TABS[tabName];
};
