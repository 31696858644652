import { AddInvoiceContext } from 'app/modules/jobdetail/contexts/AddInvoiceContext';
import IconPlus from 'assets/icon/IconPlus';
import React, { useContext } from 'react';
import { DEFAULT_VALUE_PACKAGE, PACKAGE_LIST_COLOR } from 'app/modules/jobdetail/const/Estimate';

export default function ButtonAdd() {
    const { onChangeData } = useContext(AddInvoiceContext);

    const _handleAddPackage = () => {
        onChangeData((prev) => {
            const lastItem = [...prev.packages].pop();
            return {
                ...prev,
                packages: [
                    ...prev.packages,
                    {
                        ...DEFAULT_VALUE_PACKAGE,
                        id: Date.now().toString(),
                        color: PACKAGE_LIST_COLOR.find((item) => item.prev === lastItem?.color)?.color || '#ff9823'
                    }
                ]
            };
        });
    };

    return (
        <div className="btn-add-package">
            <div onClick={_handleAddPackage} className="v2-btn-main --bg-green has-icon svg-white btn-package">
                <IconPlus />
                Add Package
            </div>
        </div>
    );
}
