import React from 'react';
import { useTranslation } from 'react-i18next';

import GDInputNumber from 'app/components/input/InputNumber';
import { CUSTOM_ID, DATE_OPTIONS, FUTURE_ID, PAST_ID } from '../../constants/dateOptions';
import { OPERATOR_IDS } from '../../constants/types';
import SelectCondition from './SelectCondition';
import InputDate from './date/InputDate';

const DateCondition = ({
    operatorId,
    value = {
        value: null,
        value2: null,
        customDate: null,
        customDate2: null,
        offset: null,
        offset2: null
    },
    onChange = () => {}
}) => {
    const { t } = useTranslation('smartView');
    const items = DATE_OPTIONS[operatorId];
    const isBetween = [OPERATOR_IDS.BETWEEN, OPERATOR_IDS.NOT_BETWEEN].includes(operatorId);
    const isPresent = [OPERATOR_IDS.PRESENT, OPERATOR_IDS.NOT_PRESENT].includes(operatorId);

    const _handleChange = (item) => {
        onChange({ ...value, value: item.value });
    };
    const _handleChangeSecondValue = (item) => {
        onChange({ ...value, value2: item.value });
    };

    const _handleChangeOffset = ({ type = 'offset', offset }) => {
        onChange({ ...value, [type]: offset });
    };

    const _handleChangeCustomDate = (date, suffix = '') => {
        onChange({ ...value, [`customDate${suffix}`]: date });
    };

    if (isPresent) return null;
    return (
        <>
            <DateOffset
                label={isBetween ? t('the_start_of') : ''}
                items={items}
                selectedValue={value?.value}
                relativeDate={value?.offset}
                relativeKey="offset"
                customDate={value?.customDate}
                onValueChange={_handleChange}
                onRelativeDateChange={_handleChangeOffset}
                onCustomDateChange={_handleChangeCustomDate}
            />
            {isBetween ? (
                <DateOffset
                    label={t('and_the_end_of')}
                    selectedValue={value?.value2}
                    items={items}
                    relativeKey="offset2"
                    suffix="2"
                    relativeDate={value?.offset2}
                    customDate={value?.customDate2}
                    onValueChange={_handleChangeSecondValue}
                    onRelativeDateChange={_handleChangeOffset}
                    onCustomDateChange={_handleChangeCustomDate}
                />
            ) : null}
        </>
    );
};

const DateOffset = ({
    label = '',
    items = [],
    selectedValue = '',
    relativeKey = '',
    relativeDate = {},
    customDate = '',
    suffix = '',
    onValueChange = () => {},
    onRelativeDateChange = () => {},
    onCustomDateChange = () => {}
}) => {
    const handleChange = (value, name) => {
        const offset = { ...relativeDate, [name]: Number(value) || undefined };
        onRelativeDateChange({
            type: relativeKey,
            offset: Object.values(offset).every((value) => value === undefined || value === 0) ? undefined : offset
        });
    };

    return (
        <div className="flex-column gap-8 field">
            <SelectCondition
                label={label}
                items={items}
                keyId="value"
                selected={items.find((item) => item.value === selectedValue)}
                onSelect={onValueChange}
            />

            {[PAST_ID, FUTURE_ID].includes(selectedValue) ? (
                <div className="field wrap-time">
                    <GDInputNumber
                        className="field-input"
                        type="text"
                        placeholder="months"
                        name="months"
                        autoFocus
                        defaultValue={relativeDate?.months || ''}
                        onChange={handleChange}
                    />
                    <GDInputNumber
                        className="field-input"
                        type="text"
                        placeholder="weeks"
                        name="weeks"
                        defaultValue={relativeDate?.weeks || ''}
                        onChange={handleChange}
                    />
                    <GDInputNumber
                        className="field-input"
                        type="text"
                        placeholder="days"
                        name="days"
                        defaultValue={relativeDate?.days || ''}
                        onChange={handleChange}
                    />
                    <GDInputNumber
                        className="field-input"
                        type="text"
                        placeholder="hours"
                        name="hours"
                        defaultValue={relativeDate?.hours || ''}
                        onChange={handleChange}
                    />
                    <GDInputNumber
                        className="field-input"
                        type="text"
                        placeholder="minutes"
                        name="minutes"
                        defaultValue={relativeDate?.minutes || ''}
                        onChange={handleChange}
                    />
                </div>
            ) : null}

            {selectedValue === CUSTOM_ID ? (
                <InputDate date={customDate || ''} onChange={(date) => onCustomDateChange(date, suffix)} />
            ) : null}
        </div>
    );
};

export default DateCondition;
