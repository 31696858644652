const IconApiLock = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.60003 1.71993C6.11991 1.71993 4.92003 2.91981 4.92003 4.39993V5.40328C5.70703 4.94344 6.62276 4.67993 7.60003 4.67993C8.5773 4.67993 9.49303 4.94344 10.28 5.40328V4.39993C10.28 2.91981 9.08015 1.71993 7.60003 1.71993ZM3.88003 5.99993C3.88003 6.05925 3.88996 6.11625 3.90826 6.16936C2.90445 7.13702 2.28003 8.49555 2.28003 9.99993C2.28003 12.9381 4.66187 15.3199 7.60003 15.3199C10.5382 15.3199 12.92 12.9381 12.92 9.99993C12.92 8.49555 12.2956 7.13702 11.2918 6.16936C11.3101 6.11625 11.32 6.05925 11.32 5.99993V4.39993C11.32 2.34543 9.65453 0.679932 7.60003 0.679932C5.54553 0.679932 3.88003 2.34543 3.88003 4.39993V5.99993ZM3.32003 9.99993C3.32003 7.63615 5.23625 5.71993 7.60003 5.71993C9.96381 5.71993 11.88 7.63615 11.88 9.99993C11.88 12.3637 9.96381 14.2799 7.60003 14.2799C5.23625 14.2799 3.32003 12.3637 3.32003 9.99993ZM7.32003 9.19993C7.32003 9.04529 7.44539 8.91993 7.60003 8.91993C7.75467 8.91993 7.88003 9.04529 7.88003 9.19993C7.88003 9.35452 7.75475 9.47985 7.60017 9.47993C7.60013 9.47993 7.60008 9.47993 7.60003 9.47993C7.44539 9.47993 7.32003 9.35457 7.32003 9.19993ZM7.08003 10.4136C6.60956 10.2117 6.28003 9.74431 6.28003 9.19993C6.28003 8.47092 6.87101 7.87993 7.60003 7.87993C8.32905 7.87993 8.92003 8.47092 8.92003 9.19993C8.92003 9.74431 8.5905 10.2117 8.12003 10.4136V11.5999C8.12003 11.8871 7.88722 12.1199 7.60003 12.1199C7.31284 12.1199 7.08003 11.8871 7.08003 11.5999V10.4136Z"
                fill="#A4A5A7"
            ></path>
        </svg>
    );
};

export default IconApiLock;
