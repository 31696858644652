export const TYPE_SCHEDULE_ACTION = {
    ACTION_LIST_SCHEDULE_REQUEST: 'ACTION_LIST_SCHEDULE_REQUEST',
    ACTION_LIST_SCHEDULE_SUCCESS: 'ACTION_LIST_SCHEDULE_SUCCESS',
    UPDATE_SCHEDULE_COLOR: 'UPDATE_SCHEDULE_COLOR',
    ADD_SCHEDULE: 'ADD_SCHEDULE'
};

export const actionGetListSchedule = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_SCHEDULE_ACTION.ACTION_LIST_SCHEDULE_REQUEST,
        params,
        actionSuccess,
        actionFailed
    };
};

export const updateScheduleColor = (payload) => ({ type: TYPE_SCHEDULE_ACTION.UPDATE_SCHEDULE_COLOR, payload });
