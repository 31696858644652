export default function IconMenuReport({ isMenuActive = false }) {
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#3AC8B0"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.32869 11C12.066 11 12.3347 13.9178 13.4169 15.8436C13.151 16.3355 13 16.9001 13 17.5C13 18.8962 13.8176 20.1014 15 20.6632L15 23H6.00005C5.49538 15 6.60493 11 9.32869 11ZM22.2689 15.8762C24.5665 15.8762 25.8102 18.2508 26 23H18L18.0011 20.6627C19.183 20.1007 20 18.8958 20 17.5C20 17.1134 19.9374 16.7414 19.8216 16.3937L19.8722 16.3642C20.3738 16.0909 21.0156 15.8762 22.2689 15.8762Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 8H17V15.05C18.1411 15.2816 19 16.2905 19 17.5C19 18.7095 18.1411 19.7184 17 19.95V23H16V19.95C14.8589 19.7184 14 18.7095 14 17.5C14 16.2905 14.8589 15.2816 16 15.05V8Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#EFFAF8"
            />
            <path
                opacity="0.6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.32869 11C12.066 11 12.3347 13.9178 13.4169 15.8436C13.151 16.3355 13 16.9001 13 17.5C13 18.8962 13.8176 20.1014 15 20.6632L15 23H6.00005C5.49538 15 6.60493 11 9.32869 11ZM22.2689 15.8762C24.5665 15.8762 25.8102 18.2508 26 23H18L18.0011 20.6627C19.183 20.1007 20 18.8958 20 17.5C20 17.1134 19.9374 16.7414 19.8216 16.3937L19.8722 16.3642C20.3738 16.0909 21.0156 15.8762 22.2689 15.8762Z"
                fill="#3AC8B0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 8H17V15.05C18.1411 15.2816 19 16.2905 19 17.5C19 18.7095 18.1411 19.7184 17 19.95V23H16V19.95C14.8589 19.7184 14 18.7095 14 17.5C14 16.2905 14.8589 15.2816 16 15.05V8Z"
                fill="#3AC8B0"
            />
        </svg>
    );
}
