import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import { actionGetListSchedule } from 'common/redux/actions/calendar/scheduleAction';
import MultiSelect from 'app/components/select/multiSelect';

const Schedules = ({ keyWidget = 'widget', defaultSelected = -1, onSelect = () => {} }, ref) => {
    const dispatch = useDispatch();

    const schedules = useSelector(({ schedules }) => schedules);
    const listUser = useSelector(({ companyUsers }) => companyUsers.users);

    const {
        data: { schedules: scheduleList = [] },
        isFirstTime: isFirstTimeSchedule
    } = schedules;

    const refSelected = useRef(defaultSelected);
    const refIsChanged = useRef(false);

    const [state, dispatchState] = useReducer(reducer, {
        selected: defaultSelected
    });

    const { selected: finalSelected } = state;

    useImperativeHandle(ref, () => ({
        getScheduleId: () => finalSelected.id
    }));

    useEffect(() => {
        isFirstTimeSchedule && dispatch(actionGetListSchedule());
    }, []);

    const _renderOptions = () => {
        if (!scheduleList.length) return [];

        return scheduleList.map((item) => {
            const { id: scheduleId, user_id, name } = item;
            const avatar = listUser.find(({ id }) => id === user_id)?.avatar;
            return {
                id: scheduleId,
                name,
                avatar
            };
        });
    };

    const _handleSelect = (name, newList) => {
        refSelected.current = newList;
        refIsChanged.current = true;
        dispatchState({ selected: newList });
    };

    const _handleClose = () => {
        if (refIsChanged.current) {
            onSelect(refSelected.current);
            refIsChanged.current = false;
        }
    };

    return (
        <MultiSelect
            id={`widget_schedules_${keyWidget}`}
            name={keyWidget}
            options={_renderOptions()}
            selected={finalSelected}
            onSelect={_handleSelect}
            fileTranslation="report"
            titleSelectAllOption="all_schedules"
            title={`schedule${finalSelected?.length > 1 || finalSelected === -1 ? 's' : ''}`}
            classWrapper="dropdown-listname"
            isShowAvatar
            onCloseSelect={_handleClose}
        />
    );
};

export default forwardRef(Schedules);
