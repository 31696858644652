import classNames from 'classnames';
import moment from 'moment';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';

import { TODAY } from 'app/const/App';
import { reducer } from 'app/const/Reducer';
import IconSingleStep from 'assets/icon/IconSingleStep';

const InputDate = ({ start, onChangeDate = () => {} }, ref) => {
    const CustomInputRef = forwardRef(CustomInput);
    const [state, dispatchState] = useReducer(reducer, {
        isFocus: false,
        selected: moment(start)._d || TODAY
    });

    useImperativeHandle(ref, () => ({ getValue: () => state.selected }));

    const _handleOnClick = (date) => {
        dispatchState({ selected: date });
        onChangeDate(date);
    };

    const renderContainer = ({ children }) => {
        return (
            <div className="format-container-date-picker">
                <div className="wrapper-date-picker-container">
                    <CalendarContainer className="react-datepicker-custom">
                        <div style={{ position: 'relative' }}>{children}</div>
                    </CalendarContainer>
                </div>
            </div>
        );
    };

    return (
        <DatePicker
            selected={state.selected}
            onCalendarOpen={() => dispatchState({ isFocus: true })}
            onCalendarClose={() => dispatchState({ isFocus: false })}
            shouldCloseOnSelect={false}
            calendarContainer={renderContainer}
            onChange={_handleOnClick}
            customInput={<CustomInputRef date={state.selected} isFocus={state.isFocus} />}
            renderCustomHeader={CustomHeader}
        />
    );
};

const CustomInput = ({ isFocus = false, onChange = () => {}, onFocus = () => {}, ...props }, ref) => {
    const value = moment.utc(props.date);
    return (
        <input
            ref={ref}
            onFocus={onFocus}
            onChange={onChange}
            className={classNames('field-control', { 'has-focus': isFocus })}
            type="text"
            placeholder="Select Date"
            value={`${value.format('ddd MMM Do')}, ${value.format('YYYY')}`}
        />
    );
};

const CustomHeader = ({ monthDate, decreaseMonth, increaseMonth }) => {
    return (
        <div className="datepicker-header-custom --just-label">
            <div onClick={decreaseMonth} className="btn-navigation --previous v2-btn-default --icon-sm --transparent">
                <IconSingleStep isPrev />
            </div>

            <p className="label-month w-100">{moment(monthDate).format('MMMM YYYY')}</p>

            <div onClick={increaseMonth} className="btn-navigation --next v2-btn-default --icon-sm --transparent">
                <IconSingleStep />
            </div>
        </div>
    );
};

export default forwardRef(InputDate);
