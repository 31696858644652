import { actionNotes } from 'app/const/Api';
import Content from 'app/modules/customer/components/Content';
import { TYPE_NOTE } from 'app/modules/customer/const/Note';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const Edit = forwardRef(({ noteDetail, onUpdate, type, jobId, unitId }, ref) => {
    const { id } = useParams();
    const [state, setState] = useState({ isVisible: false, noteData: {} });
    const refContent = useRef(null);

    const finalIsVisible = state.isVisible;

    useImperativeHandle(ref, () => ({
        toggleShowHide: _handleToggleShowHide,
        setContent: _handleSelectNewContent
    }));

    useEffect(() => {
        if (finalIsVisible) refContent.current && refContent.current.focus();
    }, [finalIsVisible]);

    function _handleSelectNewContent(newContent, isConcat = false) {
        if (isConcat) {
            refContent.current.setValue(refContent.current.getContent().concat(newContent));
        } else {
            refContent.current.setValue(newContent);
        }
    }

    function _handleToggleShowHide() {
        setState({ isVisible: !finalIsVisible, noteData: noteDetail });
    }

    function _handleEdit(content) {
        const attachments = refContent.current.getAttachments() || [];
        const newAttachments = [];
        attachments.forEach((item) => {
            if (!item.isDefault) {
                newAttachments.push({
                    object_key: item.object_key,
                    object_tag: item.object_tag,
                    mime: item.mime,
                    name: item.name,
                    size: item.size,
                    type: item.type
                });
            }
        });

        clientQuery(
            actionNotes(noteDetail.id),
            {
                method: 'PUT',
                data: { content: content, attachments: newAttachments },
                toFormData: false
            },
            (response) => _handleSuccess(response, content, attachments)
        );
    }

    const _handleSuccess = ({ data }, content, prevAttach) => {
        const newAttach = data.attachment.map((item) => {
            return { ...item, isUpdate: true };
        });

        onUpdate({
            ...state.noteData,
            content,
            attachments: [...prevAttach, ...newAttach].filter((item) => item.isUpdate || item.isDefault)
        });

        _handleCancelEdit();
    };

    function _handleCancelEdit() {
        refContent.current.resetValue();
        const elm = document.getElementById(`form_detail_and_edit_note_${noteDetail.id}`);
        if (elm) {
            elm.classList.toggle('has-edited');
            setState({ isVisible: false });
        }
    }

    if (!finalIsVisible) return null;

    function _getItemId() {
        switch (type) {
            case TYPE_NOTE.CUSTOMER:
                return id;
            case TYPE_NOTE.JOB:
                return jobId;
            case TYPE_NOTE.UNIT:
                return unitId;
            default:
                return '';
        }
    }

    return (
        <div className="wrap-content is-editing">
            <div className="wrap-content-box">
                <Content
                    ref={refContent}
                    content={noteDetail.content}
                    typePresign="note"
                    itemIdPresign={_getItemId()}
                    onCloseEdit={_handleCancelEdit}
                    onUpdate={_handleEdit}
                    attachmentProps={{
                        itemId: _getItemId(),
                        typeAttachment: 'note',
                        haveNotify: true,
                        haveEditNotify: false,
                        defaultNotify: noteDetail.notified_users || [],
                        defaultAttachments: noteDetail.attachments || []
                    }}
                />
            </div>
        </div>
    );
});

export default Edit;
