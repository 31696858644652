export const NOTE_DEFAULT_VALUE = {
    id: '',
    user: {},
    content: '<p>sdfsdfsdfsdf</p>',
    date: '11/03/21 11:27AM',
    notified_users: [],
    job: null,
    is_customer_note: 0,
    is_sticky: 0,
    is_repeated: 0,
    is_recurring: 0,
    total_notifications: 0,
    attachments: [],
    comment: {
        items: [],
        total: 0
    },
    type: 'note'
};

export const TYPE_NOTE = {
    CUSTOMER: 'customer',
    JOB: 'job',
    UNIT: 'unit'
};

export const NOTE_PIN = {
    STICKY: 1,
    NONE_STICKY: 0
};

export const NOTE_REPEAT = {
    REPEAT: 1,
    NONE_REPEAT: 0
};
