import { useMutation } from '@apollo/client';
import React, { forwardRef, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ButtonSave from 'app/components/button/ButtonSave';
import { addBranchPath } from 'app/const/Branch';
import { reducer } from 'app/const/Reducer';
import IconClose from 'assets/icon/IconClose';
import { SMART_VIEW_CUSTOM_ACTIONS } from '../../constants';
import { useSmartView } from '../../context';
import { CREATE_SMART_VIEW_MUTATION } from '../../graphql/mutation/create';
import ShareOptions from '../listSmartView/ShareOptions';
import { getSmartViewUrl } from '../utils';
import { convertColumnToQuery } from '../utils/columns';
import { convertToQuery } from '../utils/query';
import { sortToQuery } from '../utils/sortQuery';

const initialState = { isOpen: false, dataSmartView: {} };
const SaveAsModal = forwardRef(({ onErrorSmartView = () => {} }, ref) => {
    const { t } = useTranslation(['common', 'smartView']);
    const history = useHistory();

    const customFields = useSelector(({ customFields }) => customFields?.data || []);

    const { filtered, sort, columns, limit, name } = useSmartView();
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [createSmartView] = useMutation(CREATE_SMART_VIEW_MUTATION, {
        onCompleted: (data) => {
            const listSmartView = document.getElementById('list-wrapper-smart-view');
            if (listSmartView) {
                const convertedData = { ...data };
                convertedData['createSmartView'] = {
                    ...(convertedData['createSmartView'] || {}),
                    shared_with: data?.createSmartView?.['sharedWith'] || [],
                    share_option: data?.createSmartView?.['shareOption'],
                    created_by: data?.createSmartView?.['createdBy']
                };

                listSmartView.dispatchEvent(
                    new CustomEvent(SMART_VIEW_CUSTOM_ACTIONS.CREATE_SMART_VIEW_QUERY, {
                        detail: { data: convertedData }
                    })
                );
            }
            _handleClose();
            history.push(addBranchPath(getSmartViewUrl(data.createSmartView.id)), {
                name: data.createSmartView.name,
                definition: data.createSmartView.definition
            });
        },
        onError: onErrorSmartView
    });
    const { isOpen } = state;
    const refButtonSave = useRef(null);
    const refForm = useRef(null);
    const refOption = useRef(null);

    useImperativeHandle(ref, () => ({ open: _handleOpen, close: _handleClose }));

    const _handleOpen = (dataSmartView = {}) => {
        dispatchState((prevState) => ({ ...prevState, isOpen: true, dataSmartView }));
    };

    const _handleClose = () => {
        dispatchState(initialState);
    };

    const _handleChangeName = (e) => {
        if (e) e.preventDefault();
        const name = e.target.value.trim();
        if (name) refButtonSave.current?.setDisable(false);
        else refButtonSave.current?.setDisable(true);
    };

    const _handleSave = (e) => {
        try {
            e && e.preventDefault();
            const shareOption = refOption.current?.getValue() || {};
            const data = {
                name: refForm.current.name.value.trim(),
                ...shareOption,
                q: { bool: { queries: [{ bool: { queries: convertToQuery(filtered) } }] } },
                sort: sortToQuery(sort),
                columns: convertColumnToQuery(columns, customFields),
                limit
            };
            createSmartView({ variables: { data } });
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                style={{ overlay: { background: 'transparent' } }}
                className="modal container-modal modal-edit-smartview open"
                ariaHideApp={false}
                onRequestClose={_handleClose}
            >
                <div className="modal__overlay bg-fixed" onClick={_handleClose} />
                <div className="modal__container">
                    <div className="header-modal">
                        <h2 className="header-modal__label fs-17 fw-600">{t('smartView:save_as_smart_view')}</h2>
                        <div className="v2-btn-default --icon-lg --transparent" onClick={_handleClose}>
                            <IconClose />
                        </div>
                    </div>

                    <form ref={refForm} className="body-modal" onSubmit={_handleSave}>
                        <div className="row">
                            <span className="txt fs-14">{t('name')}</span>
                            <input
                                className="field-input"
                                type="text"
                                name="name"
                                autoComplete="off"
                                defaultValue={name ? name + ' (Copy)' : ''}
                                placeholder={t('name')}
                                onChange={_handleChangeName}
                                autoFocus
                            />
                        </div>
                        <ShareOptions ref={refOption} isGraphqlForm />
                    </form>

                    <div className="footer-modal">
                        <div className="flexcenter justify-end flex-1">
                            <div className="v2-btn-default --transparent" onClick={_handleClose}>
                                {t('cancel')}
                            </div>
                            <ButtonSave ref={refButtonSave} onSave={_handleSave} />
                        </div>
                    </div>
                </div>
            </ReactModal>
        </>
    );
});

export default SaveAsModal;
