import React, { useRef, useEffect, useReducer } from 'react';
import { FILTER_CUSTOMER_TIMELINE, ID_CALL_FILTER } from 'app/modules/customer/const';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';
import Checkbox from 'app/components/checkbox';
import IconFilter from 'assets/icon/IconFilter';
import IconArrowDown from 'assets/icon/IconArrowDown';
import { reducer } from 'app/const/Reducer';
import { useDispatch, useSelector } from 'react-redux';
import { actionUpdateFilterTimeLine } from 'common/redux/actions/authAction';
import { combineString } from 'common/utils/ReportUtils';

const Filter = ({ onChangeFilter, filters }) => {
    const isHaveVoip = useSelector(({ auth }) => auth.user.settings.addons?.voip);
    const [state, setState] = useReducer(reducer, {
        isVisible: false,
        selected: filters
    });
    const DEFAULT_ALL = '-1';

    useEffect(() => {
        setState({
            selected: typeof filters === 'string' && filters !== DEFAULT_ALL ? filters.split(',') : filters
        });
    }, [filters]);

    const { isVisible: finalIsVisible, selected: listSelected } = state;

    const refDropdown = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (finalIsVisible) {
            document.addEventListener('click', handleClickOutside, true);
            document.addEventListener('keydown', handleHideDropdown, true);
        } else {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        }
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('keydown', handleHideDropdown, true);
        };
    }, [finalIsVisible]);

    function handleHideDropdown(event) {
        const elPrevent = document.getElementById('show_customer_time_linefilter');
        if (event.keyCode === KEY_CODE_ESCAPE && elPrevent) {
            _closeSearchResult();
        }
    }

    function handleClickOutside(event) {
        const elPrevent = document.getElementById('show_customer_time_linefilter');

        if (
            refDropdown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropdown.current.contains(event.target)
        ) {
            _closeSearchResult();
        }
    }

    function _closeSearchResult() {
        finalIsVisible && setState({ isVisible: false });
    }

    const _handleOpen = (e) => {
        e.stopPropagation();

        setState({ isVisible: !finalIsVisible });
    };

    const _handleSelectAll = (e) => {
        e.preventDefault();
        setState({
            selected:
                listSelected === DEFAULT_ALL || listSelected.length === FILTER_CUSTOMER_TIMELINE.length
                    ? []
                    : DEFAULT_ALL
        });
    };

    const _handleSelectOption = (e, value) => {
        e.preventDefault();

        let newSelected =
            listSelected === DEFAULT_ALL ? FILTER_CUSTOMER_TIMELINE.map((item) => item.id) : [...listSelected];

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((item) => item !== value);
        } else {
            newSelected.push(value);
        }

        if (newSelected.length === FILTER_CUSTOMER_TIMELINE.length) {
            newSelected = DEFAULT_ALL;
        }

        setState({
            selected: newSelected
        });
    };

    function _handleApplyFilter(e) {
        if (listSelected.length === 0) {
            return false;
        }

        e.preventDefault();

        dispatch(actionUpdateFilterTimeLine({ timeline_filters: listSelected.toString() }));

        onChangeFilter(listSelected);
    }

    function _renderListOptions() {
        return FILTER_CUSTOMER_TIMELINE.map((item) => {
            const optionId = item.id;

            const isCheck = listSelected === DEFAULT_ALL || listSelected.includes(optionId);
            if (item.id === ID_CALL_FILTER && !isHaveVoip) return null;

            return (
                <li key={optionId} className="items" onClick={(e) => _handleSelectOption(e, optionId)}>
                    <Checkbox
                        label={item.name}
                        checked={isCheck}
                        id={`check-box-${item.name}-all`}
                        onChangeValue={() => _handleSelectOption}
                        value={optionId}
                        typeIcon={item.name}
                    />
                </li>
            );
        });
    }

    function _renderSelectAll() {
        const isCheckAll = listSelected === DEFAULT_ALL || FILTER_CUSTOMER_TIMELINE.length === listSelected.length;

        return (
            <div className="content-checked__all" onClick={_handleSelectAll}>
                <div className="items">
                    <Checkbox
                        label={'All'}
                        checked={isCheckAll}
                        id={`check-box-all`}
                        onChangeValue={_handleSelectAll}
                        value={listSelected?.length || 0}
                    />
                </div>
            </div>
        );
    }

    const renderSelected = () => {
        const limit = 2;
        const lengthSelected = listSelected.length;
        const isCheckedAll = listSelected === DEFAULT_ALL || lengthSelected >= FILTER_CUSTOMER_TIMELINE.length;
        const moreCount = lengthSelected - limit;

        if (lengthSelected === 0) return <p className="txt-ellipsis">Select filter</p>;
        if (isCheckedAll) return <p className="txt-ellipsis">All activities</p>;

        const stringSelected = combineString(
            [...listSelected]
                .splice(0, 2)
                .map(
                    (itemSelect) =>
                        FILTER_CUSTOMER_TIMELINE.find((itemDropdown) => +itemDropdown.id === +itemSelect)?.name || ''
                )
        );

        return (
            <>
                <p className="txt-ellipsis">{stringSelected}</p>
                {moreCount > 0 ? <span className="budget bg-grey-dark">+{moreCount}</span> : null}
            </>
        );
    };

    return (
        <div className={`v2-dropdown filter-activities ${finalIsVisible ? 'active' : ''}`} ref={refDropdown}>
            <div className="dropbtn items --has-icon" onClick={_handleOpen}>
                <div className="dropbtn__label">
                    <IconFilter isHasColor />
                    {renderSelected()}
                </div>
                <div className="arrow">
                    <IconArrowDown />
                </div>
            </div>
            {finalIsVisible && (
                <div className="v2-dropdown__menu content-checked">
                    {_renderSelectAll()}
                    <ul className="scrolls" id={'show_customer_time_linefilter'}>
                        {_renderListOptions()}
                    </ul>
                    <div className="content-checked__btn">
                        <div
                            className={`v2-btn-main ${listSelected.length === 0 ? 'is-disable' : ''}`}
                            onClick={_handleApplyFilter}
                        >
                            Update
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Filter;
