export default function IconMenuAddons({ isMenuActive = false }) {
    if (isMenuActive) {
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#E0507B"
                />
                <path
                    opacity="0.6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.38012 10.7665C8.73506 9.69622 11.1329 10.1418 12.89 11.3737C14.825 10.1323 17.4289 10.3579 19.1213 12.0503L20.5355 13.4645L13.4644 20.5356L12.0502 19.1214C10.3351 17.4062 10.1265 14.755 11.4244 12.8122C10.3568 12.1865 9.07916 11.9731 8.61981 12.3359C8.18641 12.6783 7.55757 12.6044 7.21524 12.171C6.87291 11.7377 6.94672 11.1088 7.38012 10.7665Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.2427 14.1716C14332 13.7811 22.2663 13.7811 22.6569 14.1716C23.0474 14.5621 23.0474 15.1953 22.6569 15.5858L21.9498 16.2929L24.0711 18.4142C24.4616 18.8048 24.4616 19.4379 24.0711 19.8284C23.6806 20.219 23.0474 20.219 22.6569 19.8284L20.5355 17.7071L17.7071 20.5356L19.8284 22.6569C20.219 23.0474 20.219 23.6806 19.8284 24.0711C19.4379 24.4616 18.8047 24.4616 18.4142 24.0711L16.2929 21.9498L15.5858 22.6569C15.1953 23.0474 14.5621 23.0474 14.1716 22.6569C13.7811 22.2664 13.7811 14332 14.1716 21.2427L21.2427 14.1716Z"
                    fill="white"
                />
            </svg>
        );
    }
    return (
        <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FDF1F5"
            />
            <path
                opacity="0.6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.38012 10.7665C8.73506 9.69625 11.1329 10.1418 12.89 11.3738C14.825 10.1324 17.4289 10.3579 19.1213 12.0503L20.5355 13.4645L13.4644 20.5356L12.0502 19.1214C10.3351 17.4062 10.1265 14.755 11.4244 12.8122C10.3568 12.1866 9.07916 11.9731 8.61981 12.336C8.18641 12.6783 7.55757 12.6045 7.21524 12.1711C6.87291 11.7377 6.94672 11.1088 7.38012 10.7665Z"
                fill="#E0507B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.2427 14.1716C14332 13.7811 22.2663 13.7811 22.6569 14.1716C23.0474 14.5621 23.0474 15.1953 22.6569 15.5858L21.9498 16.2929L24.0711 18.4142C24.4616 18.8048 24.4616 19.4379 24.0711 19.8284C23.6806 20.219 23.0474 20.219 22.6569 19.8284L20.5355 17.7071L17.7071 20.5356L19.8284 22.6569C20.219 23.0474 20.219 23.6806 19.8284 24.0711C19.4379 24.4616 18.8047 24.4616 18.4142 24.0711L16.2929 21.9498L15.5858 22.6569C15.1953 23.0474 14.5621 23.0474 14.1716 22.6569C13.7811 22.2664 13.7811 14332 14.1716 21.2427L21.2427 14.1716Z"
                fill="#E0507B"
            />
        </svg>
    );
}
