import classNames from 'classnames';
import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DropdownPopper from 'app/components/dropdown/DropdownPopper';
import { ADDONS_OPPORTUNITY_MANAGE } from 'app/config/routes';
import { reducer } from 'app/const/Reducer';
import IconArrowDown from 'assets/icon/IconArrowDown';
import IconCogWheel from 'assets/icon/IconCogWheel';
import { getListPipelines } from 'common/redux/actions/settings/pipelinesAction';
import { addBranchPath } from 'app/const/Branch';

const DropdownStatus = ({ modifiers = [], defaultValue = null, onChangePipeline = () => {} }) => {
    const { t } = useTranslation('customers');
    const dispatch = useDispatch();
    const { isLoading, isFirstTime, data } = useSelector(({ pipelines }) => pipelines);
    const permissionsEnable = useSelector(({ auth }) => auth.user.permissions.enabled || []);
    const [state, dispatchState] = useReducer(reducer, {
        selected: defaultValue || data?.[0]?.pipelines?.[0] || {}
    });
    const { selected } = state;
    const refDropdown = useRef(null);

    useEffect(() => {
        if (!isFirstTime) return;
        const _handleSuccess = ({ data }) => {
            const selected = defaultValue || data?.[0]?.pipelines?.[0] || {};
            dispatchState((prevState) => ({ ...prevState, selected }));
            onChangePipeline(selected);
        };
        dispatch(getListPipelines({}, _handleSuccess));
    }, []);

    useEffect(() => {
        if (selected?.id !== defaultValue?.id) dispatchState((prevState) => ({ ...prevState, selected: defaultValue }));
    }, [defaultValue]);

    const _handleSelect = (pipeline) => {
        refDropdown.current?._close();
        if (pipeline.id === selected.id) return;
        dispatchState((prevState) => ({ ...prevState, selected: pipeline }));
        onChangePipeline(pipeline);
    };
    return (
        <DropdownPopper
            ref={refDropdown}
            isUseToggle
            isCalculateWidth
            isDisable={isFirstTime || isLoading}
            wrapperClassName="v2-dropdown select-status"
            buttonClassName="dropbtn items selection"
            wrapperListClass="v2-dropdown__menu scrolls"
            modifiers={modifiers}
            customButton={
                <>
                    {selected.status ? <span className={`dots --${selected.status.toLowerCase()}`} /> : null}
                    <span className="txt-ellipsis ml-1">{selected.name || t('none')}</span>
                    <IconArrowDown />
                </>
            }
        >
            <ul>
                {data.length ? (
                    data?.map((status) => (
                        <ListItem
                            key={status.id}
                            items={status.pipelines}
                            idSelected={selected?.id}
                            onSelect={_handleSelect}
                        />
                    ))
                ) : (
                    <li className="items">
                        <span className="txt-ellipsis flex-1">Not matches</span>
                    </li>
                )}

                {permissionsEnable.includes('accessSettingsTab') ? (
                    <>
                        <div className="line" />
                        <Link to={addBranchPath(ADDONS_OPPORTUNITY_MANAGE)} className="items">
                            <span className="mx-1">
                                <IconCogWheel />
                            </span>
                            {t('manage_pipelines')}
                        </Link>
                    </>
                ) : null}
            </ul>
        </DropdownPopper>
    );
};

const ListItem = ({ items = [], idSelected = '', onSelect = () => {} }) => {
    if (!items?.length) return null;
    return items.map((item) => (
        <li
            key={item.id}
            className={classNames('items', { active: idSelected === item.id })}
            onClick={() => onSelect(item)}
        >
            <span className={`dots --${item.status.toLowerCase()}`} />
            <span className="txt-ellipsis flex-1">{item.name}</span>
        </li>
    ));
};

export default DropdownStatus;
