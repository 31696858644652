import IconFuture from 'assets/icon/IconFuture';
import IconTime from 'assets/icon/IconTime';
// eslint-disable-next-line no-unused-vars
import IconWorkOrder from 'assets/icon/IconWorkOrder';

export const REPORT_INVOIVE_OPTIONS = [
    {
        id: 'date-range',
        name: 'date-range',
        i18Text: 'report',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        name: 'columns',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'invoice_number', name: 'invoice_number', isShow: false, isI18: true },
            { id: 'customer_name', name: 'customer_name', isShow: false, isI18: true },
            { id: 'job_name', name: 'job_name', isShow: false, isI18: true },
            { id: 'repeat_text', name: 'repeat_text', isShow: false, isI18: true },
            { id: 'billing_email', name: 'billing_email', isShow: false, isI18: true },
            { id: 'total', name: 'total', isShow: false, isI18: true },
            { id: 'status', name: 'status', isShow: false, isI18: true },
            { id: 'signature_status', name: 'signature_status', isShow: false, isI18: true }
        ]
    },
    {
        id: 'status',
        name: 'status',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_statuses', isShow: false, isI18: true },
            { id: 'draft', name: 'draft', isShow: false, isI18: true },
            { id: 'void', name: 'void', isShow: false, isI18: true },
            { id: 'partial', name: 'partial', isShow: false, isI18: true },
            { id: 'partial_paid', name: 'partial_paid', isShow: false, isI18: true },
            { id: 'sent', name: 'sent', isShow: false, isI18: true },
            { id: 'paid', name: 'paid', isShow: false, isI18: true },
            { id: 'write_off', name: 'write_off', isShow: false, isI18: true },
            { id: 'failed', name: 'failed', isShow: false, isI18: true }
        ]
    },
    {
        id: 'sign_status',
        name: 'select_signature_status',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_signature_statuses', isShow: false, isI18: true },
            { id: 'draft', name: 'draft', isShow: false, isI18: true },
            { id: 'emailed', name: 'sent', isShow: false, isI18: true },
            { id: 'pending_sig', name: 'pending_sig', isShow: false, isI18: true },
            { id: 'signed', name: 'signed', isShow: false, isI18: true }
        ]
    },
    {
        id: 'service',
        name: 'service',
        i18Text: 'report',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    }
];

export const REPORT_ESTIMATE_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        i18Text: 'report',
        name: 'columns',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'estimate_number', name: 'estimate_number', isShow: false, isI18: true },
            { id: 'account_number', name: 'account_number', isShow: false, isI18: true },
            { id: 'customer_name', name: 'customer_name', isShow: false, isI18: true },
            { id: 'phones', name: 'phones', isShow: false, isI18: true },
            { id: 'billing_email', name: 'billing_email', isShow: false, isI18: true },
            { id: 'created_by', name: 'created_by', isShow: false, isI18: true },
            { id: 'date', name: 'date', isShow: false, isI18: true },
            { id: 'total', name: 'total', isShow: false, isI18: true },
            { id: 'status', name: 'status', isShow: false, isI18: true },
            { id: 'signature_status', name: 'signature_status', isShow: false, isI18: true }
        ]
    },
    {
        id: 'status',
        i18Text: 'report',
        name: 'status',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_statuses', isShow: false, isI18: true },
            { id: 'draft', name: 'draft', isShow: false, isI18: true },
            { id: 'pending', name: 'pending', isShow: false, isI18: true },
            { id: 'won', name: 'won', isShow: false, isI18: true },
            { id: 'invoiced', name: 'won_invoiced', isShow: false, isI18: true },
            { id: 'lost', name: 'lost', isShow: false, isI18: true }
        ]
    },
    {
        id: 'sign_status',
        i18Text: 'report',
        name: 'select_signature_status',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_signature_statuses', isShow: false, isI18: true },
            { id: 'draft', name: 'draft', isShow: false, isI18: true },
            { id: 'emailed', name: 'sent', isShow: false, isI18: true },
            { id: 'pending_sig', name: 'pending_sig', isShow: false, isI18: true },
            { id: 'signed', name: 'signed', isShow: false, isI18: true }
        ]
    }
];

export const REPORT_PAYMENT_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        i18Text: 'report',
        name: 'columns',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'payment_for', name: 'payment_for_filter', isShow: false, isI18: true },
            { id: 'customer_name', name: 'customer', isShow: false, isI18: true },
            { id: 'method', name: 'method', isShow: false, isI18: true },
            { id: 'date', name: 'date', isShow: false, isI18: true },
            { id: 'amount', name: 'amount', isShow: false, isI18: true },
            { id: 'status', name: 'status', isShow: false, isI18: true }
        ]
    },
    {
        id: 'methods',
        i18Text: 'report',
        name: 'method',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    }
];

export const REPORT_DOCUMENT_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        i18Text: 'report',
        name: 'columns',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'document', name: 'customer_id_document', isShow: false, isI18: true },
            { id: 'customer_name', name: 'customer_name', isShow: false, isI18: true },
            { id: 'account_number', name: 'account_document', isShow: false, isI18: true },
            { id: 'document_type', name: 'document_type', isShow: false, isI18: true },
            { id: 'created_by', name: 'created_by', isShow: false, isI18: true },
            { id: 'date_created', name: 'date_created', isShow: false, isI18: true },
            { id: 'status', name: 'status', isShow: false, isI18: true }
        ]
    },
    {
        id: 'sign_status',
        i18Text: 'report',
        name: 'select_signature_status',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_signature_statuses', isShow: false, isI18: true },
            { id: 'draft', name: 'draft', isShow: false, isI18: true },
            { id: 'emailed', name: 'sent', isShow: false, isI18: true },
            { id: 'pending_sig', name: 'pending_sig', isShow: false, isI18: true },
            { id: 'signed', name: 'signed', isShow: false, isI18: true }
        ]
    },
    {
        id: 'types',
        i18Text: 'report',
        name: 'types',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    }
];

export const REPORT_CREDIT_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        i18Text: 'report',
        name: 'columns',
        type: 'select-multi',
        isDynamic: false,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'customer_name', name: 'customer_name', isShow: false, isI18: true },
            { id: 'method', name: 'method', isShow: false, isI18: true },
            { id: 'date', name: 'date', isShow: false, isI18: true },
            { id: 'total', name: 'total', isShow: false, isI18: true },
            { id: 'available_credit', name: 'available_credit', isShow: false, isI18: true },
            { id: 'status', name: 'status', isShow: false, isI18: true }
        ]
    }
];

export const REPORT_SERVICE_LOOKUP_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'columns',
        i18Text: 'report',
        name: 'columns',
        type: 'select-multi',
        isDynamic: false,
        selectAll: false,
        options: [
            { id: 'all', name: 'all_columns', isShow: false, isI18: true },
            { id: 'account_number', name: 'account_number', isShow: false, isI18: true },
            { id: 'service_type', name: 'service_type', isShow: true, isI18: true },
            { id: 'phones', name: 'phones', isShow: true, isI18: true },
            { id: 'email', name: 'email', isShow: false, isI18: true },
            { id: 'service_address', name: 'service_address', isShow: true, isI18: true },
            { id: 'service_address_city', name: 'service_address_city', isShow: true, isI18: true },
            { id: 'service_address_state', name: 'service_address_state', isShow: false, isI18: true },
            { id: 'service_address_zip', name: 'service_address_zip', isShow: true, isI18: true },
            { id: 'county', name: 'county', isShow: true, isI18: true },
            { id: 'billing_address', name: 'billing_address', isShow: false, isI18: true },
            { id: 'source', name: 'source', isShow: false, isI18: true },
            { id: 'invoice_number', name: 'invoice_number', isShow: false, isI18: true },
            { id: 'invoice_value', name: 'invoice_value', isShow: true, isI18: true },
            { id: 'w_o_tax', name: 'w_o_tax', isShow: false, isI18: true },
            { id: 'invoice_status', name: 'invoice_status', isShow: false, isI18: true },
            { id: 'scheduled_date', name: 'scheduled_date', isShow: true, isI18: true },
            { id: 'job_status', name: 'job_status', isShow: true, isI18: true },
            { id: 'assigned_to', name: 'assigned_to', isShow: true, isI18: true },
            { id: 'created_by', name: 'created_by', isShow: false, isI18: true },
            { id: 'last_service_date', name: 'last_service_date', isShow: false, isI18: true },
            { id: 'tag', name: 'tag', isShow: false, isI18: true },
            { id: 'service_no', name: 'service_no', isShow: false, isI18: true },
            { id: 'check_in_time', name: 'check_in_time', isShow: false, isI18: true },
            { id: 'check_out_time', name: 'check_out_time', isShow: false, isI18: true }
        ]
    },
    {
        id: 'service_type',
        i18Text: 'report',
        name: 'service_type',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'job_status',
        i18Text: 'report',
        name: 'job_status',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_statuses', isShow: false, isI18: true },
            { id: 'unconfirmed', name: 'unconfirmed', isShow: false, isI18: true },
            { id: 'confirmed', name: 'confirmed', isShow: false, isI18: true },
            { id: 'reschedule', name: 'reschedule', isShow: false, isI18: true },
            { id: 'pending_confirmation', name: 'pending_confirmation', isShow: false, isI18: true },
            { id: 'completed', name: 'completed', isShow: false, isI18: true },
            { id: 'canceled', name: 'canceled', isShow: false, isI18: true },
            { id: 'recurrence', name: 'recurrence', isShow: false, isI18: true }
        ]
    },
    {
        id: 'assigned_to',
        i18Text: 'report',
        name: 'assigned_to',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'service_address_city',
        i18Text: 'report',
        name: 'city',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'service_address_zip',
        i18Text: 'report',
        name: 'zip',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'tag',
        i18Text: 'report',
        name: 'tags',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'sort_name',
        i18Text: 'report',
        name: 'sort_name',
        type: 'checkbox',
        isDynamic: true,
        selectAll: true,
        isMultiSelect: false,
        options: [
            { id: 'first_name', name: 'first_name', isShow: false, isI18: true },
            { id: 'last_name', name: 'last_name', isShow: false, isI18: true }
        ]
    }
];

export const REVIEW_SERVICE_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'users',
        i18Text: 'review',
        name: 'teammate',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: []
    },
    {
        id: 'all_rating',
        i18Text: 'review',
        name: 'all_ratings',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_ratings', isShow: false, isI18: true },
            { id: 'rating_5', name: 'rating_great', isShow: false, isI18: true, leftIcon: true, total: 5 },
            { id: 'rating_4', name: 'rating_good', isShow: false, isI18: true, leftIcon: true, total: 4 },
            { id: 'rating_3', name: 'rating_average', isShow: false, isI18: true, leftIcon: true, total: 3 },
            { id: 'rating_2', name: 'rating_poor', isShow: false, isI18: true, leftIcon: true, total: 2 },
            { id: 'rating_1', name: 'rating_terrible', isShow: false, isI18: true, leftIcon: true, total: 1 }
        ]
    }
];

export const REVIEW_LIKELY_OPTIONS = [
    {
        id: 'date-range',
        i18Text: 'report',
        name: 'date-range',
        type: 'date-range',
        date: null
    },
    {
        id: 'all_rating_likely',
        i18Text: 'review',
        name: 'all_ratings',
        type: 'select-multi',
        isDynamic: true,
        selectAll: true,
        options: [
            { id: 'all', name: 'all_ratings', isShow: false, isI18: true },
            { id: 'rating_10', name: 'rating_10', isShow: false, isI18: false, title: 10 },
            { id: 'rating_9', name: 'rating_9', isShow: false, isI18: false, title: 9 },
            { id: 'rating_8', name: 'rating_8', isShow: false, isI18: false, title: 8 },
            { id: 'rating_7', name: 'rating_7', isShow: false, isI18: false, title: 7 },
            { id: 'rating_6', name: 'rating_5', isShow: false, isI18: false, title: 6 },
            { id: 'rating_5', name: 'rating_4', isShow: false, isI18: false, title: 5 },
            { id: 'rating_4', name: 'rating_6', isShow: false, isI18: false, title: 4 },
            { id: 'rating_3', name: 'rating_3', isShow: false, isI18: false, title: 3 },
            { id: 'rating_2', name: 'rating_2', isShow: false, isI18: false, title: 2 },
            { id: 'rating_1', name: 'rating_1', isShow: false, isI18: false, title: 1 },
            { id: 'rating_0', name: 'rating_0', isShow: false, isI18: false, title: 0 }
        ]
    }
];

export const JOB_TYPES_MAP = [
    { id: 'work_pool', name: 'work_pool', icon: <IconWorkOrder /> },
    { id: 'timeoff', name: 'time_off', icon: <IconTime /> },
    { id: 'event', name: 'custom', icon: <IconFuture /> }
];

export const INVOICE_STATUS = {
    STATUS_VOID: 'void',
    STATUS_DRAFT: 'draft',
    STATUS_SENT: 'sent',
    STATUS_PARTIAL: 'partial',
    STATUS_PARTIAL_PAID: 'partial paid',
    STATUS_PAID: 'paid',
    STATUS_WRITE_OFF: 'write off',
    STATUS_FAILED: 'failed'
};
