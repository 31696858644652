import React, { createContext, useEffect, useReducer } from 'react';
import { reducer } from 'app/const/Reducer';
import { LIST_USER_VOIP } from 'app/const/api/Voip';
import { clientQuery } from 'common/utils/ApiUtils';
import { useSelector } from 'react-redux';
import { ACCOUNT_ROLE } from 'app/const/App';

export const AddonsManageContext = createContext();

export const VOIPAddonsManageProvider = ({ children }) => {
    const userRole = useSelector(({ auth }) => auth.user.profile.role);
    const isSupperAdmin = userRole === ACCOUNT_ROLE.SUPERADMIN;

    const [state, dispatchState] = useReducer(reducer, {
        activedUsers: []
    });

    useEffect(() => {
        fetchListActivedUser();
    }, []);

    const fetchListActivedUser = () => {
        if (!isSupperAdmin) return;
        const _handleSuccess = (res) => {
            dispatchState({ activedUsers: res.data });
        };

        clientQuery(LIST_USER_VOIP, { method: 'GET' }, _handleSuccess);
    };

    return <AddonsManageContext.Provider value={{ ...state }}>{children}</AddonsManageContext.Provider>;
};
