import IconConversation from 'assets/icon/IconConversation';
import {
    SMS_DECREMENT_TOTAL,
    SMS_DECREMENT_TOTAL_CODE,
    SMS_INCREMENT_TOTAL,
    SMS_INCREMENT_TOTAL_CODE
} from 'app/const/Sms';
import Sms from 'app/modules/sms/customer';
import classNames from 'classnames';
import { checkAddon } from 'common/utils/AddonUtils';
import React, { useEffect, useRef, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import RealTimeSMS from 'app/modules/sms/service/RealTimeSMS';
import ServiceSms from 'app/modules/sms/service/ServiceSms';
import { SMS_COUNT } from 'app/const/api/Sms';
import { clientQuery } from 'common/utils/ApiUtils';
import { reducer } from 'app/const/Reducer';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

function SmsHeader({ isExpired = false }) {
    const { t } = useTranslation('header');

    const [state, dispatchState] = useReducer(reducer, {
        loading: true,
        total: 0,
        smsReceiver: null,
        smsConversation: null
    });

    const { settings: userSetting, settings } = useSelector((state) => state.auth.user) || {};
    const isActiveSMS = settings?.addons?.smsplivo;
    const refDropDown = useRef(null);
    const refSms = useRef(null);

    const _closeListener = () => {
        document.removeEventListener('click', handleClickOutside, true);
        document.removeEventListener('keydown', handleHideDropdown, true);
        dispatchState({
            smsReceiver: null,
            smsConversation: null
        });
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('keydown', handleHideDropdown, true);

        return () => {
            _closeListener();
        };
    }, []);

    const handleClickOutside = (event) => {
        const elPrevent = document.getElementById('show_list_dropdown_sms_header');
        const elAttachModal = document.getElementById('gd-preview-attach');
        const smsTypeWarning = document.getElementById('sms_warning_type');
        const assignLead = document.getElementById('modal_assign_lead');
        const createCustomer = document.getElementById('add_new_customer');

        if (
            refDropDown.current &&
            elPrevent &&
            !elPrevent.contains(event.target) &&
            !refDropDown.current.contains(event.target) &&
            !smsTypeWarning?.contains(event.target) &&
            !elAttachModal &&
            !assignLead?.contains(event.target) &&
            !createCustomer?.contains(event.target) &&
            event.target.dataset.closeSms !== 'false'
        ) {
            _handleClose();
        }
    };

    function handleHideDropdown(event) {
        if (
            event.keyCode === KEY_CODE_ESCAPE &&
            document.getElementById('show_list_dropdown_sms_header') &&
            !document.getElementById('gd-preview-attach') &&
            event.target.dataset.closeSms !== 'false'
        ) {
            _handleClose();
        }
    }

    function _handleClose() {
        refSms.current._handleClose();
        dispatchState({
            smsReceiver: null,
            smsConversation: null
        });
    }

    const activate_addon_sms = checkAddon(userSetting?.addons?.smsplivo || 0);
    const activate_sms = checkAddon(userSetting?.addons?.activate_sms || 0);

    const total = state.total || 0;

    useEffect(() => {
        isActiveSMS && !isExpired && clientQuery(SMS_COUNT, { method: 'GET' }, smsGetTotalSuccess);
    }, []);

    function _handleOpenCustomerSmsDetail(detailSms) {
        refSms.current._openModalSMS(null, { ...detailSms }, detailSms.phone_location, 'CUSTOMER');
    }

    function _handleUpdateTotalSMS(total, smsReciverData, smsConversationData) {
        dispatchState((prevState) => {
            return {
                ...prevState,
                total: prevState.total + total,
                smsReceiver: smsReciverData,
                smsConversation: smsConversationData
            };
        });
    }

    const smsGetTotalSuccess = (response) => {
        dispatchState((prevState) => {
            return {
                ...prevState,
                loading: false,
                total: parseInt(response.data?.total) || 0
            };
        });
    };

    function handleActiveMenu() {
        dispatchState((prev) => {
            return {
                ...prev,
                smsReceiver: null,
                smsConversation: null
            };
        });
        refSms.current._openModalSMS();
    }

    const _updateTotalSms = (statusTotal, isSetByCount = false, count = 0) => {
        dispatchState((prevState) => {
            let total = prevState?.total || 0;

            if (isSetByCount) {
                if (statusTotal === SMS_INCREMENT_TOTAL_CODE) total += count;
                if (statusTotal === SMS_DECREMENT_TOTAL_CODE) total = total - count >= 0 ? total - count : 0;
            } else {
                if (statusTotal === SMS_INCREMENT_TOTAL) total += 1;
                if (statusTotal === SMS_DECREMENT_TOTAL) total = total - 1 >= 0 ? total - 1 : 0;
            }
            return {
                ...prevState,
                total
            };
        });
    };

    return (
        <div ref={refDropDown} className={classNames('boxs-notification notifi nav-sms', { 'is-disable': isExpired })}>
            <div
                onClick={handleActiveMenu}
                id={'button_show_number_sms'}
                className={classNames('notify-btn tooltip', {
                    'has-notify': total > 0,
                    'dp-hide': !activate_addon_sms || !activate_sms
                })}
            >
                <IconConversation width={18} height={18} />
                {total > 0 && <span className="budget">{total > 999 ? '+999' : total}</span>}
                <p className="tooltiptext bottom">{t('sms')}</p>
            </div>
            <Sms
                ref={refSms}
                socketReceiver={state.smsReceiver}
                socketConversation={state.smsConversation}
                onUpdateTotal={_updateTotalSms}
                onCloseSms={_closeListener}
            />
            <RealTimeSMS onUpdateTotalSms={_handleUpdateTotalSMS} currentTotal={total} />
            <ServiceSms onOpenCustomerSms={_handleOpenCustomerSmsDetail} />
        </div>
    );
}

export default SmsHeader;
