export default function IconFolder() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            {' '}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 5.5H9.5L12.5 7.5H17.5C18.6046 7.5 19.5 8.39543 19.5 9.5V16.5C19.5 17.6046 18.6046 18.5 17.5 18.5H6.5C5.39543 18.5 4.5 17.6046 4.5 16.5V7.5C4.5 6.39543 5.39543 5.5 6.5 5.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M4.5 12.5C4.5 11.4373 5.36152 10.5757 6.42427 10.5757H17.5757C18.6385 10.5757 19.5 11.4373 19.5 12.5"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
        </svg>
    );
}
