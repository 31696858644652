import { createPopper } from '@popperjs/core';
import React, { forwardRef, useEffect, useImperativeHandle, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import IconEdit from 'assets/icon/IconEdit';
import IconTrash from 'assets/icon/IconTrash';

const MenuContentPopper = forwardRef(
    ({ onToggleActive = () => {}, onToggleUpdate = () => {}, onDeleteResource = () => {} }, ref) => {
        const { t } = useTranslation('common');
        const [state, dispatchState] = useReducer(reducer, { data: null });
        const data = state?.data || null;
        const refEl = useRef(null);
        const refButton = useRef(null);
        const refPopper = useRef(null);

        useImperativeHandle(ref, () => ({ open: _handleOpenMenu, close: _handleCloseMenu }));

        const handleClickOutside = (event) => {
            if (refEl.current && !refEl.current.contains(event.target) && !refButton.current?.contains(event.target))
                _handleCloseMenu();
        };

        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        const _handleOpenMenu = (data) => {
            if (refPopper.current) refPopper.current.destroy();
            const popperConfig = { placement: 'bottom-end', strategy: 'fixed', modifiers: [{ name: 'hide' }] };
            refPopper.current = createPopper(data?.el, refEl.current, popperConfig);
            refButton.current = data?.el;
            dispatchState((prevState) => ({ ...prevState, data: data.data }));
        };

        const _handleCloseMenu = () => {
            if (refPopper.current) refPopper.current.destroy();
            onToggleActive();
            dispatchState((prevState) => ({ ...prevState, data: null }));
        };
        return (
            <div ref={refEl} className="v2-dropdown active" style={{ display: !!data ? 'flex' : 'none' }}>
                {!!data ? (
                    <div className="v2-dropdown__menu content-full --right" data-popper-reference-hidden>
                        <ul>
                            {data.type !== 'group' ? (
                                <li
                                    className="items has-icon btn-modal"
                                    onClick={() => onToggleUpdate(data.idSchedule)}
                                >
                                    <IconEdit />
                                    <div className="txt-ellipsis">{t('edit')}</div>
                                </li>
                            ) : null}
                            <li
                                className="items has-icon red-default btn-modal"
                                onClick={() => onDeleteResource(data.idSchedule, data.type)}
                            >
                                <IconTrash />
                                <div className="txt-ellipsis red-delete">{t('delete')}</div>
                            </li>
                        </ul>
                    </div>
                ) : null}
            </div>
        );
    }
);

export default MenuContentPopper;
