export default function IconChatbot({ type }) {
    if (type === 'agent-resolved') {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M18.7668 2.24921C18.6669 1.91563 18.1945 1.91563 18.0945 2.24921L17.3323 4.79348C17.2985 4.90656 17.21 4.99503 17.0969 5.0289L14.5527 5.7911C14.2191 5.89103 14.2191 6.3634 14.5527 6.46334L17.0969 7.22553C17.21 7.25941 17.2985 7.34788 17.3323 7.46096L18.0945 10.0052C18.1945 10.3388 18.6669 10.3388 18.7668 10.0052L19.529 7.46096C19.5629 7.34788 19.6513 7.25941 19.7644 7.22553L22.3087 6.46334C22.6423 6.3634 22.6423 5.89103 22.3087 5.7911L19.7644 5.0289C19.6513 4.99503 19.5629 4.90656 19.529 4.79348L18.7668 2.24921ZM10.1025 6.91587C9.91705 6.32645 9.08291 6.32645 8.8975 6.91587L7.18163 12.3709C7.11791 12.5734 6.95664 12.7304 6.75243 12.7887L1.12871 14.3932C0.517643 14.5675 0.517644 15.4335 1.12871 15.6079L6.75243 17.2124C6.95664 17.2706 7.11791 17.4276 7.18163 17.6302L8.8975 23.0852C9.08291 23.6746 9.91705 23.6746 10.1025 23.0852L11.8183 17.6302C11.8821 17.4276 12.0433 17.2706 12.2475 17.2124L17.8713 15.6079C18.4823 15.4335 18.4823 14.5675 17.8713 14.3932L12.2475 12.7887C12.0433 12.7304 11.8821 12.5734 11.8183 12.3709L10.1025 6.91587Z"
                    fill="#FFBB00"
                ></path>
            </svg>
        );
    }
    if (type === 'not-resolved') {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    opacity="0.4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 10L20 13L13.9979 19.0021L11 16L17 10Z"
                    fill="#8D4AFC"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.54897 2.40862C8.38575 1.86379 7.61425 1.86379 7.45103 2.40862L6.37731 5.9928C6.32198 6.17749 6.17749 6.32198 5.9928 6.37731L2.40862 7.45103C1.86379 7.61425 1.86379 8.38575 2.40862 8.54897L5.9928 9.62269C6.17749 9.67802 6.32198 9.82251 6.37731 10.0072L7.45103 13.5914C7.61425 14.1362 8.38575 14.1362 8.54897 13.5914L9.62269 10.0072C9.67802 9.82251 9.82251 9.67802 10.0072 9.62269L13.5914 8.54897C14.1362 8.38575 14.1362 7.61425 13.5914 7.45103L10.0072 6.37731C9.82251 6.32198 9.67802 6.17749 9.62269 5.9928L8.54897 2.40862ZM21.4999 8.50023C22.3283 9.32865 22.3283 10.6718 21.4999 11.5002L20.9639 12.0352L17.9639 9.03523L18.4999 8.50023C19.3283 7.6718 20.6714 7.6718 21.4999 8.50023ZM9.86364 17.1362L12.8636 20.1362L11.9978 21.0024L9.17918 22.1338C8.66665 22.3396 8.08437 22.0909 7.87862 21.5784C7.78327 21.3408 7.78263 21.0758 7.87682 20.8378L8.99993 18.0002L9.86364 17.1362Z"
                    fill="#8D4AFC"
                ></path>
            </svg>
        );
    }
    if (type === 'resolved') {
        return (
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.895 1.01967C18.0749 0.419225 18.9251 0.419225 19.105 1.01967L19.9007 3.67564C19.9617 3.87919 20.1209 4.03843 20.3244 4.09941L22.9804 4.89507C23.5809 5.07495 23.5809 5.92522 22.9804 6.1051L20.3244 6.90076C20.1209 6.96174 19.9617 7.12098 19.9007 7.32453L19.105 9.9805C18.9251 10.5809 18.0749 10.5809 17.895 9.9805L17.0993 7.32453C17.0383 7.12098 16.8791 6.96174 16.6756 6.90076L14.0196 6.1051C13.4191 5.92522 13.4191 5.07495 14.0196 4.89507L16.6756 4.09941C16.8791 4.03843 17.0383 3.87919 17.0993 3.67564L17.895 1.01967ZM5.57061 4.02743H10.0802L14.6507 22.0001H11.8488L10.7941 17.8526H4.85666L3.8019 22.0001H1L5.57061 4.02743ZM5.55983 15.0875H10.0909L7.98147 6.79245H7.66934L5.55983 15.0875ZM20 12.0001H17V22.0001H20V12.0001Z"
                    fill="#1EAA5C"
                ></path>
            </svg>
        );
    }
    if (type === 'inactive') {
        return (
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                    fill="black"
                    fillOpacity="0.04"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.7668 6.24921C22.6669 5.91563 22.1945 5.91563 22.0945 6.24921L21.3323 8.79348C21.2985 8.90656 21.21 8.99503 21.0969 9.0289L18.5527 9.7911C18.2191 9.89103 18.2191 10.3634 18.5527 10.4633L21.0969 11.2255C21.21 11.2594 21.2985 11.3479 21.3323 11.461L22.0945 14.0052C22.1945 14.3388 22.6669 14.3388 22.7668 14.0052L23.529 11.461C23.5629 11.3479 23.6513 11.2594 23.7644 11.2255L26.3087 10.4633C26.6423 10.3634 26.6423 9.89103 26.3087 9.7911L23.7644 9.0289C23.6513 8.99503 23.5629 8.90656 23.529 8.79348L22.7668 6.24921ZM14.1025 10.9159C13.9171 10.3264 13.0829 10.3264 12.8975 10.9159L11.1816 16.3709C11.1179 16.5734 10.9566 16.7304 10.7524 16.7887L5.12871 18.3932C4.51764 18.5675 4.51764 19.4335 5.12871 19.6079L10.7524 21.2124C10.9566 21.2706 11.1179 21.4276 11.1816 21.6302L12.8975 27.0852C13.0829 27.6746 13.9171 27.6746 14.1025 27.0852L15.8183 21.6302C15.8821 21.4276 16.0433 21.2706 16.2475 21.2124L21.8713 19.6079C22.4823 19.4335 22.4823 18.5675 21.8713 18.3932L16.2475 16.7887C16.0433 16.7304 15.8821 16.5734 15.8183 16.3709L14.1025 10.9159Z"
                    fill="black"
                    fillOpacity="0.4"
                ></path>
            </svg>
        );
    }
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                fill="#FFF"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.7668 6.24921C22.6669 5.91563 22.1945 5.91563 22.0945 6.24921L21.3323 8.79348C21.2985 8.90656 21.21 8.99503 21.0969 9.0289L18.5527 9.7911C18.2191 9.89103 18.2191 10.3634 18.5527 10.4633L21.0969 11.2255C21.21 11.2594 21.2985 11.3479 21.3323 11.461L22.0945 14.0052C22.1945 14.3388 22.6669 14.3388 22.7668 14.0052L23.529 11.461C23.5629 11.3479 23.6513 11.2594 23.7644 11.2255L26.3087 10.4633C26.6423 10.3634 26.6423 9.89103 26.3087 9.7911L23.7644 9.0289C23.6513 8.99503 23.5629 8.90656 23.529 8.79348L22.7668 6.24921ZM14.1025 10.9159C13.9171 10.3264 13.0829 10.3264 12.8975 10.9159L11.1816 16.3709C11.1179 16.5734 10.9566 16.7304 10.7524 16.7887L5.12871 18.3932C4.51764 18.5675 4.51764 19.4335 5.12871 19.6079L10.7524 21.2124C10.9566 21.2706 11.1179 21.4276 11.1816 21.6302L12.8975 27.0852C13.0829 27.6746 13.9171 27.6746 14.1025 27.0852L15.8183 21.6302C15.8821 21.4276 16.0433 21.2706 16.2475 21.2124L21.8713 19.6079C22.4823 19.4335 22.4823 18.5675 21.8713 18.3932L16.2475 16.7887C16.0433 16.7304 15.8821 16.5734 15.8183 16.3709L14.1025 10.9159Z"
                fill="#1EAA5C"
            ></path>
        </svg>
    );
}
