import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropDown from 'assets/icon/IconDropDown';
import classNames from 'classnames';
import React, { useRef } from 'react';
import GridEmpty from '../grid/components/GridEmpty';
import IconPhone from './IconPhone';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const GdDropdownPhone = ({ data = [], idsSelected = [], selectedPhone = null, onSelectPhone = () => {}, ...props }) => {
    const refDropdown = useRef(null);

    const _handleSelect = (item) => {
        if (item.is_valid === 0) return;
        refDropdown.current._closeDropdown();
        onSelectPhone(item);
    };

    return (
        <CalendarDropdown
            ref={refDropdown}
            id="GdDropdownPhone"
            customDropButton={() => <CustomDropButton selected={selectedPhone} />}
            {...props}
        >
            <DropdownPhoneList
                data={data}
                idsSelected={idsSelected}
                selected={selectedPhone}
                onSelectPhone={_handleSelect}
            />
        </CalendarDropdown>
    );
};

const CustomDropButton = ({ selected = {} }) => {
    if (selected.is_valid !== 0 && selected.is_valid !== 1) {
        return (
            <>
                <div className="item-selected flexcenter">
                    <p className="txt-ellipsis">{formatPhoneNumberVoip(selected.phone) || selected.phone}</p>
                    <p className="valid-phone__name">{selected.name}</p>
                </div>
                <span className="svg-selectbox">
                    <IconDropDown />
                </span>
            </>
        );
    }

    return (
        <>
            <div className="valid-phone --just-icon">
                <p className="valid-phone__number">{formatPhoneNumberVoip(selected.phone || '')}</p>
                <div className="valid-phone__btn">
                    <IconPhone isValid={selected.is_valid} />
                </div>
                <p className="valid-phone__name">{selected.name}</p>
            </div>
            <span className="svg-selectbox">
                <IconDropDown />
            </span>
        </>
    );
};

const DropdownPhoneList = ({ data = [], selected = {}, idsSelected = [], onSelectPhone = () => {} }) => {
    if (!data.length) return <GridEmpty />;

    return (
        <ul>
            {data.map((item) => (
                <DropdownPhoneItem
                    key={item.id}
                    isActive={selected.id === item.id}
                    isDisplay={!idsSelected.includes(item.id)}
                    onSelectPhone={() => onSelectPhone(item)}
                    isValid={item.is_valid}
                    {...item}
                />
            ))}
        </ul>
    );
};

const DropdownPhoneItem = ({
    isValid = null,
    isActive = false,
    isDisplay = true,
    phone,
    name = '',
    onSelectPhone = () => {}
}) => {
    if (!isDisplay) return null;
    const title = `${phone || ''} ${name || ''}`.trim();
    if (isValid !== 0 && isValid !== 1)
        return (
            <div className={classNames('items', { active: isActive })} onClick={onSelectPhone} title={title}>
                <p className="txt-ellipsis">{phone}</p>
                {name ? <p className="valid-phone__name">{name}</p> : null}
            </div>
        );
    return (
        <li
            title={title}
            className={classNames('items', { 'phone-disable': isValid === 0, active: isActive })}
            onClick={onSelectPhone}
        >
            <div className="valid-phone --just-icon">
                <p className="valid-phone__number">{phone}</p>
                <div className="valid-phone__btn">
                    <IconPhone isValid={isValid} isIconDropdown />
                </div>
                {name ? <p className="valid-phone__name">{name}</p> : null}
            </div>
        </li>
    );
};

GdDropdownPhone.displayName = 'GdDropdownPhone';
export default GdDropdownPhone;
