import React from 'react';
import { useSelector } from 'react-redux';

const UserWithAvatar = ({ userId = '' }) => {
    const users = useSelector(({ companyUsers }) => companyUsers.users || []);
    const user = users.find((item) => +item.id === +userId) || null;

    if (!user) return null;
    return (
        <div className="item-checkbox flexcenter">
            <div className="avt-img" style={{ width: 18, height: 18 }}>
                <img loading="lazy" src={user.avatar} width="100%" height="100%" alt="" />
            </div>
            <span className="txt-ellipsis ml-1 fw-600">{user.full_name}</span>
        </div>
    );
};

export default UserWithAvatar;
