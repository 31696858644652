export default function IconWarning({ isDanger = false, isFlat = false }) {
    if (isFlat)
        return (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.93457 10.2715C9.62493 10.2715 10.1846 10.8311 10.1846 11.5215C10.1846 12.2118 9.62493 12.7715 8.93457 12.7715C8.24421 12.7715 7.68457 12.2118 7.68457 11.5215C7.68457 10.8311 8.24421 10.2715 8.93457 10.2715Z"
                    fill="#77787A"
                />
                <rect x="8.16699" y="4.83301" width="1.66667" height={5} rx="0.833333" fill="#77787A" />
                <rect x={1} y={1} width={16} height={16} rx={8} stroke="#77787A" strokeWidth="1.5" />
            </svg>
        );
    if (isDanger)
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="8" fill="#EE4646"></rect>
                <path
                    d="M9.03917 3.1332C8.57729 2.3332 7.42259 2.3332 6.96071 3.1332L2.68832 10.5332C2.22644 11.3332 2.80379 12.3332 3.72755 12.3332H12.2723C13.1961 12.3332 13.7734 11.3332 13.3116 10.5332L9.03917 3.1332Z"
                    fill="#EE4646"
                ></path>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.94739 11.0176C8.49968 11.0176 8.94739 11.4653 8.94739 12.0175C8.94739 12.5699 8.49968 13.0175 7.94739 13.0175C7.39511 13.0175 6.94739 12.5699 6.94739 12.0175C6.94739 11.4653 7.39511 11.0176 7.94739 11.0176Z"
                    fill="white"
                ></path>
                <path
                    d="M8.6666 4.33341C8.6666 3.96522 8.36812 3.66675 7.99993 3.66675C7.63174 3.66675 7.33327 3.96522 7.33327 4.33341L7.33325 8.83333C7.33325 9.20152 7.63173 9.5 7.99992 9.5C8.36811 9.5 8.66658 9.20152 8.66658 8.83333L8.6666 4.33341Z"
                    fill="white"
                ></path>
            </svg>
        );
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="8" fill="#7A83A6"></rect>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11Z"
                fill="white"
            ></path>
            <rect x="7" y="3" width="2" height="6" rx="1" fill="white"></rect>
        </svg>
    );
}
