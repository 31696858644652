import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Service = ({ onOnpenJob }) => {
    const jobPreView = useSelector(({ jobReducer }) => jobReducer.jobPreView);

    useEffect(() => {
        onOnpenJob(jobPreView);
    }, [jobPreView]);

    return false;
};

export default Service;
