import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DOCUMENT_LIBRARY } from '../../constants';

const NameTagPdf = ({
    wrapClassName = 'flexcenter',
    type = DOCUMENT_LIBRARY.PDF,
    name: fileName = '',
    originalName = '',
    icon = null,
    onClickName = () => {}
}) => {
    const { t } = useTranslation();
    const isTypeDocumentPDF = type === DOCUMENT_LIBRARY.PDF;

    const _renderOriginalName = () => {
        if (isTypeDocumentPDF || !originalName || originalName === fileName) return null;

        return <p className="txt-ellipsis black-darker2 fs-13">{originalName}</p>;
    };

    return (
        <Fragment>
            <div className={wrapClassName}>
                {icon}
                <div className="name" onClick={onClickName}>
                    {fileName}
                </div>
                {isTypeDocumentPDF ? <div className="budget --black-dark">{t('pdf')}</div> : null}
            </div>
            {_renderOriginalName()}
        </Fragment>
    );
};

export default NameTagPdf;
