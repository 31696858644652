import React, { useEffect } from 'react';
import IconClose from 'assets/icon/IconClose';
import { Link } from 'react-router-dom';
import { SETTINGS_PLANS } from 'app/config/routes';
import { addBranchPath } from 'app/const/Branch';

function StatusBar({ type = 'success', message = '', timeout = 5000, closeStatusBar = () => {}, id = '' }) {
    useEffect(() => {
        const timeClear = setTimeout(() => {
            _handleClickClose();
        }, timeout);

        return () => {
            clearTimeout(timeClear);
        };
    }, []);

    function _handleClickClose() {
        closeStatusBar(id);
    }

    const _renderAlertLink = () => {
        return (
            <>
                <p className="alert__description">{message.textAlert}</p>
                <p className="alert__description">
                    {message.textNotice}
                    <Link to={addBranchPath(SETTINGS_PLANS)} className="pl-1 pr-1 cursor-pointer fw-bold">
                        {message.linkedLetters}
                    </Link>
                    your account.
                </p>
            </>
        );
    };

    function _renderMessages() {
        if (message.isLink && !Array.isArray(message)) {
            return _renderAlertLink();
        }

        if (Array.isArray(message)) {
            return message.map((item, i) => {
                return (
                    <p key={i.toString()} className="alert__description">
                        {item}
                    </p>
                );
            });
        } else {
            return <p className="alert__description">{message.toString()}</p>;
        }
    }

    return (
        <div id={id} className={`alert --${type}`}>
            {_renderMessages()}
            <div className="alert__btn svg-white-stroke" onClick={_handleClickClose}>
                <IconClose />
            </div>
        </div>
    );
}

export default StatusBar;
