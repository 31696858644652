import React from 'react';
import ItemPhoneSelect from './ItemPhoneSelect';
import { formatPhoneNumberVoip } from 'common/utils/PhoneUtils';

const ListPhones = ({ data = [], ...props }) => {
    return data.map((item, index) => {
        return (
            <ItemPhoneSelect
                key={item.id}
                hasPhone={index === 0}
                defaultType={item.type}
                defaultPhone={formatPhoneNumberVoip(item.phone)}
                isDisable={item.isDisable}
                {...props}
                {...item}
            />
        );
    });
};

export default ListPhones;
