import { CUSTOMER_IMPORT, CUSTOMER_IMPORT_PROGRESS } from 'app/const/Api';
import { reducer } from 'app/const/Reducer';
import { clientQuery } from 'common/utils/ApiUtils';
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useReducer } from 'react';
import { useSelector } from 'react-redux';
import ProgressBarImport from './ProgressBarImport';
import { SocketContext } from 'app/services/socket/SocketProvider';

const CustomerImportProgress = forwardRef(({ onReloadCustomer = () => {} }, ref) => {
    const [state, dispatchState] = useReducer(reducer, { progressList: [] });
    const { import_customer } = useSelector(({ auth }) => auth.user.profile.progress);
    const { customerImported } = useContext(SocketContext);

    const progressList = state.progressList;

    useImperativeHandle(ref, () => ({
        reloadProgressList: _reloadProgressList,
        pushProgressList: _handlePushProgress
    }));

    useEffect(() => {
        import_customer && _reloadProgressList();
    }, []);

    useEffect(() => {
        if (customerImported) {
            const { id, cache = {}, show } = customerImported;
            if (!show) {
                dispatchState({ progressList: progressList.filter((item) => item.import_number_key !== id) });
                _reloadProgressList();
            } else {
                const count = parseInt(cache.count);
                const success = parseInt(cache.success);
                const total = parseInt(cache.total);
                const hasId = progressList.some((item) => item.import_number_key === id);
                const objectUpdate = {
                    count,
                    success,
                    total,
                    import_number_key: id,
                    failed: count - success,
                    percent: parseInt((count / total) * 100),
                    type: cache.type
                };

                if (hasId) {
                    const newProgressList = [...progressList];
                    const indexUpdate = newProgressList.findIndex((item) => item.import_number_key === id);
                    newProgressList[indexUpdate] = objectUpdate;
                    dispatchState({ progressList: newProgressList });
                } else {
                    dispatchState({ progressList: [...progressList, objectUpdate] });
                }
            }
        }
    }, [customerImported]);

    const _removeImportProgress = (key) => {
        dispatchState({ progressList: progressList.filter((item) => item.import_number_key !== key[0]) });
        _hideProgress(key);
    };

    function _reloadProgressList() {
        const _success = ({ data }) => dispatchState({ progressList: data });
        clientQuery(CUSTOMER_IMPORT_PROGRESS, { data: {}, method: 'GET' }, _success);
    }

    const _handleReload = () => {
        const ids = [];
        const newProgressList = [];

        progressList.forEach((element) => {
            element.count === element.total ? ids.push(element.import_number_key) : newProgressList.push(element);
        });

        dispatchState({ progressList: newProgressList });
        _hideProgress(ids);
        onReloadCustomer();
    };

    const _hideProgress = (ids) => clientQuery(CUSTOMER_IMPORT, { data: { ids }, method: 'DELETE', toFormData: false });

    const _handlePushProgress = (item) => {
        dispatchState((prev) => {
            const prevList = [...(prev.progressList || [])];
            const indexItem = prevList.findIndex((process) => process.id === item.id);
            if (indexItem > -1) return prev;
            return { ...prev, progressList: [...prevList, item] };
        });
    };

    return progressList.map((item) => (
        <ProgressBarImport
            key={item.import_number_key}
            onRemove={_removeImportProgress}
            onReloadCustomer={_handleReload}
            hideProgress={_hideProgress}
            {...item}
        />
    ));
});

export default CustomerImportProgress;
