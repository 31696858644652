import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addBranchPath, getAvtColorBranch, getBranchId, isFetchedBranch } from 'app/const/Branch';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { TYPE_LINK, profileOptions } from 'app/const/StaticLinks';
import { GET_BRANCHES } from 'app/const/api/V2';
import IconLoading from 'assets/icon/IconLoading';
import { userLogOutRequest } from 'common/redux/actions/authAction';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { getAvatarCustomer } from 'common/utils/StringUtils';
import { TRACKING_EVENTS_DETAIL_PAGE } from '../tracker/constant';
import { getTracking, isHaveLoggingInAnother } from '../tracker/utils';

function DropdownProfile({ isExpired = false, isVisible, isShowBranchInfo = false, onChangeBranches = () => {} }) {
    const { t } = useTranslation(['header']);
    const history = useHistory();
    const dispatch = useDispatch();

    const worklogAddon = useSelector(({ auth }) => auth.user.settings?.addons?.work_log);
    const permissionTracking = useSelector(({ auth }) => !!auth.user.settings?.time_tracking?.tracking);
    const isDisplayTracking = worklogAddon && permissionTracking;

    const { profile: profileData, company: companyUser } = useSelector(({ auth }) => auth.user);
    const { isGrowthPlan, isProPlan, isTrial } = getPlanUser(profileData);
    const hasPermission = isProPlan || isGrowthPlan || isTrial;

    const [state, dispatchState] = useReducer(reducer, {
        brandList: [],
        isLoading: true,
        fetched: false
    });
    const { brandList, isLoading } = state;
    const refTimmer = useRef(null);

    const permissionsList = useSelector(({ auth }) => auth.user?.permissions?.enabled || []);
    const currentBranchId = getBranchId();
    const isHavePermission = checkPermission(permissionsList, PERMISSIONS.accessAccount);
    const isHaveMultiBranch = isGrowthPlan || companyUser.is_branch;

    useEffect(() => {
        isHaveMultiBranch && fetchListBranch();
    }, []);

    useEffect(() => {
        if (isVisible && !isFetchedBranch.isFetched && isHaveMultiBranch) {
            fetchListBranch();
        }
    }, [isVisible]);

    const fetchListBranch = () => {
        const _failed = () => dispatchState((prev) => ({ ...prev, isLoading: false }));

        clientQuery(GET_BRANCHES, { method: 'GET' }, _success, _failed);
    };

    const _success = ({ data }) => {
        clearTimeout(refTimmer.current);
        _handleSetFetched(true);
        onChangeBranches(data);
        dispatchState({
            brandList: data,
            isLoading: false
        });
        refTimmer.current = setTimeout(() => {
            _handleSetFetched();
        }, 60000);
    };

    const _handleSetFetched = (value = false) => {
        isFetchedBranch.isFetched = value;
    };

    const _handleOpenLink = (link) => {
        const newWindow = window.open(link, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    const _handleClick = (e, item) => {
        switch (item.type) {
            case TYPE_LINK.INTERNAL: {
                history.push(addBranchPath(item.path));
                break;
            }

            case TYPE_LINK.EXTERNAL: {
                _handleOpenLink(item.path);
                break;
            }

            case TYPE_LINK.ACTION: {
                dispatch(item.action());
                break;
            }

            default: {
                break;
            }
        }
    };

    const _renderInfo = () => {
        return profileOptions.map((item) => {
            const { type, id } = item;

            if (item.isCheckPermision && !isHavePermission) return null;
            if (type === 'line') return <li key={id} className={type}></li>;
            if (type === TYPE_LINK.COMPONENT) {
                if (isExpired && id === 'sync_google') return null;
                return <Fragment key={id}>{item.component}</Fragment>;
            }
            const _handleCheck = (e) => {
                const { id: currentBranchTracking } = getTracking();
                // check is current tracking and click logout
                if (id === 'log-out') {
                    if (!currentBranchTracking && isHaveLoggingInAnother())
                        return alert(t('header:alert_tracking_logout'));
                    if (!currentBranchTracking) return dispatch(userLogOutRequest());
                    if (!isDisplayTracking || !hasPermission) return alert(t('header:alert_tracking_logout'));

                    const customEvent = new CustomEvent(TRACKING_EVENTS_DETAIL_PAGE.CONFIRM_STOP_TRACKING, {
                        detail: { isLogout: true, isDisplayAlert: true }
                    });
                    dispatchEvent(customEvent);
                } else {
                    _handleClick(e, item);
                }
            };

            return (
                <li key={id} className="items has-icon" onClick={_handleCheck}>
                    {item.icon}
                    <p className="txt-ellipsis">{t(`header:${id}`)}</p>
                </li>
            );
        });
    };

    const _handleSwithBranch = (swithId, isActive) => {
        if (isActive) {
            return false;
        }
        window.open(`${window.location.origin}/${swithId}`, '_blank');
    };

    const _renderList = () => {
        return brandList.map((item) => {
            const { id: branchId, avatar, name, email, logo, color } = item;
            const isActive = branchId === currentBranchId;

            return (
                <li
                    key={branchId}
                    className={`items items-switch ${isActive ? 'active' : ''}`}
                    onClick={() => _handleSwithBranch(branchId, isActive)}
                >
                    <div className="items-switch__logo flex-centeritem bg-white flex-auto">
                        {!!logo || !!avatar ? (
                            <img src={logo || avatar} width={70} height={40} alt={name} />
                        ) : (
                            <div className="avt" style={{ '--branch-color': getAvtColorBranch({ color }) }}>
                                {getAvatarCustomer(name)}
                            </div>
                        )}
                    </div>
                    <div className="items-switch__info">
                        <p className="info-elm status-btn --sm black-eerie">{name}</p>
                        <p className="info-elm granite-gray fs-11">{email}</p>
                    </div>
                </li>
            );
        });
    };

    const _renderBranch = () => {
        if (!isHaveMultiBranch) return null;

        if (isLoading) {
            return (
                <div className="items justify-center">
                    <div className="loading -ajaxbar">
                        <IconLoading />
                    </div>
                </div>
            );
        }
        if (!brandList.length || !isShowBranchInfo) {
            return null;
        }

        return (
            <ul>
                <li className="line" />
                <li className="title-section my-2">{t('switch_account')}</li>
                {_renderList()}
            </ul>
        );
    };

    return (
        <ul>
            {_renderInfo()}
            {_renderBranch()}
        </ul>
    );
}

export default DropdownProfile;
