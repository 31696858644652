import React, { Fragment, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { reducer } from 'app/const/Reducer';
import IconPhone from 'assets/icon/IconPhone';
import IconPlay from 'assets/icon/IconPlay';
import IconLoading from 'assets/icon/IconLoading';
import { clientQuery } from 'common/utils/ApiUtils';
import { VOIP_HOLD_CALL } from 'app/const/api/Voip';

const className = 'action-hold has-label flex-centeritem border-grey-verylight grey-very-dark fw-500 ml-3';

const ButtonHoldCall = ({ callInfo = {}, isHidden = false, onHoldCall = () => {} }) => {
    const { parentId, callSId, inviteCallId } = callInfo;

    const { t } = useTranslation('header');

    const [state, dispatchState] = useReducer(reducer, {
        isHold: false,
        isLoading: false
    });

    const { isHold, isLoading } = state;

    const _handleChangeType = (value = true) => {
        if (isLoading) return;

        dispatchState({ isLoading: true });

        const _handleSuccess = () => {
            dispatchState((prev) => ({ ...prev, isHold: value, isLoading: false }));
            onHoldCall(value);
        };
        const _handleFail = () => {
            dispatchState({ isLoading: false });
        };

        clientQuery(
            VOIP_HOLD_CALL,
            {
                method: 'POST',
                data: { call_id: callSId, parent_call_id: parentId, user_call_id: inviteCallId, unhold: value ? 0 : 1 }
            },
            _handleSuccess,
            _handleFail
        );
    };

    const _renderIcon = () => {
        if (isHold)
            return (
                <Fragment>
                    <IconPhone isHoldCall />
                    {t('on_hold')}
                </Fragment>
            );

        return (
            <Fragment>
                <IconPlay isPauseFlat />
                {t('hold_call')}
            </Fragment>
        );
    };

    if (isHidden) return null;

    return (
        <span className={classNames(className, { 'btn-back': isHold })} onClick={() => _handleChangeType(!isHold)}>
            {isLoading ? <IconLoading isPurple /> : _renderIcon()}
        </span>
    );
};

export default ButtonHoldCall;
