import React from 'react';

const LoadingModalAddCustomAnswer = () => {
    return (
        <div className="modal__container --preview-r wrap-loading">
            <div className="header-modal">
                <div className="loading --animation --line --onefourth"></div>
                <div className="v2-btn-default loading --animation --icon-lg bg-white-cultured"></div>
            </div>
            <div className="body-modal flex-column p-0 scrolls">
                <div className="rows flexcenter">
                    <div className="rows__title">
                        <div className="loading --animation --line --twothird"></div>
                    </div>
                    <div className="loading --animation --line --onefourth"></div>
                </div>
                <div className="rows flex-column gap-16 flex-1">
                    <div className="flex-column gap-4">
                        <div className="loading --animation --line --twothird"></div>
                        <div className="field-textarea flex-1 loading border-border-color-grey"></div>
                    </div>
                    <div className="v2-btn-default --grey loading --twothird">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="flex-column gap-4">
                        <div className="loading --animation --line --twothird"></div>
                        <div className="field-textarea --h-200 flex-1 loading border-border-color-grey"></div>
                    </div>
                </div>
            </div>
            <div className="footer-modal">
                <div className="v2-btn-default btn-sm --grey loading">
                    <div className="loading --animation --line --full"></div>
                </div>
                <div className="flexcenter flex-1 justify-end gap-8">
                    <div className="v2-btn-default btn-x-sm --transparent loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                    <div className="v2-btn-default btn-x-sm --grey loading">
                        <div className="loading --animation --line --full"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoadingModalAddCustomAnswer;
