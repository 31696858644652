import moment from 'moment';
import { forwardRef, useImperativeHandle } from 'react';

import { GET_WIDGET_DATA } from 'app/const/Api';
import { DATE_FORMAT } from 'app/const/App';
import { clientQuery } from 'common/utils/ApiUtils';
import { getLocalStorage, setLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_WIDGET_LOCAL_STORAGE, getDefaultParamsWidget } from './const';

const ServiceDashboard = ({ keyWidget = '', onGetSuccess = () => {}, onGetFailed = () => {} }, ref) => {
    useImperativeHandle(ref, () => ({
        _handleGetData,
        _handleGetFilter,
        _handleChangeFilter
    }));

    const _handleGetData = (options = {}) => {
        const newData = {};
        Object.keys(options).forEach((key) => {
            const value = options[key];
            newData[`options[${key}]`] = Array.isArray(value) ? value.join(',') : value;
        });

        clientQuery(
            GET_WIDGET_DATA,
            { method: 'GET', data: { key: keyWidget, ...newData } },
            onGetSuccess,
            onGetFailed
        );
    };

    const _handleChangeFilter = (data) => {
        const { start, end } = data || {};
        if (!!start && !!end) {
            data.start = moment(start).format(DATE_FORMAT);
            data.end = moment(end).format(DATE_FORMAT);
        }
        const filters = { ...getLocalStorage(KEY_WIDGET_LOCAL_STORAGE) };
        filters[keyWidget] = { ...filters[keyWidget], ...data };
        setLocalStorage(KEY_WIDGET_LOCAL_STORAGE, filters);
        return filters[keyWidget];
    };

    const _handleGetFilter = () => {
        const filters = getLocalStorage(KEY_WIDGET_LOCAL_STORAGE)?.[keyWidget];
        if (!filters) {
            return _handleChangeFilter({ ...getDefaultParamsWidget(keyWidget) });
        }
        return filters;
    };
};

export default forwardRef(ServiceDashboard);
