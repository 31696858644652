import { reducer } from 'app/const/Reducer';
import React, { forwardRef, useImperativeHandle, useReducer } from 'react';
import { RICHTEXT_FUNCTION } from '../const';

const ActionToolBar = ({ toolbarConfig = {}, ...props }, ref) => {
    const [state, dispatchState] = useReducer(reducer, {
        isUploading: false,
        imagesLoadings: [],
        resetActions: 0
    });
    const { isUploading, imagesLoadings, resetActions } = state;
    const leftActions = toolbarConfig['left'] || [];
    const rightActions = toolbarConfig['right'] || [];

    useImperativeHandle(ref, () => ({
        setIsUploading: (newStateLoading) => {
            if (isUploading !== newStateLoading) dispatchState({ isUploading: newStateLoading });
        },
        setImageLoading: (id, type) => {
            switch (type) {
                case 'ADD':
                    dispatchState((prev) => {
                        return { imagesLoadings: [...prev.imagesLoadings, id] };
                    });
                    break;
                case 'REMOVE':
                    dispatchState((prev) => {
                        return { imagesLoadings: prev.imagesLoadings.filter((item) => item !== id) };
                    });
                    break;

                default:
                    break;
            }
        },
        resetActions: () => dispatchState((prev) => ({ ...prev, resetActions: Date.now() }))
    }));

    return (
        <div className="wrapbox-editor__controls">
            <ToolbarOptions data={leftActions} className="editor-controls" {...props} />
            <ToolbarOptions
                data={rightActions}
                className="editor-controls --action"
                isUploading={isUploading || imagesLoadings.length > 0}
                resetActions={resetActions}
                {...props}
            />
        </div>
    );
};

const ToolbarOptions = ({ data = [], className = '', ...props }) => (
    <div className={className}>
        {data.map((item, index) => (
            <ToolbarGroup key={item.group} data={item.children} isLastGroup={index === data.length - 1} {...props} />
        ))}
    </div>
);

const ToolbarGroup = ({
    data = [],
    editorState,
    typePresign,
    isLastGroup = false,
    isUploading = false,
    onInsertSuccess = () => {},
    onGetAction = () => {},
    resetActions = 0
}) =>
    data.map((item, index) => {
        const _handleInsert = () => onGetAction(item.type);
        const finalProps = {
            ...(item.props || {}),
            typePresign,
            editorState,
            onInsertSuccess,
            onInsert: _handleInsert(),
            isUploading,
            resetActions
        };

        if (item.isHide) return null;
        return (
            <React.Fragment key={item.type}>
                {RICHTEXT_FUNCTION[item.type].render(finalProps)}
                {index === data.length - 1 && !isLastGroup && <span className="break-line" />}
            </React.Fragment>
        );
    });

export default forwardRef(ActionToolBar);
