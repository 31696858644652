import React from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { CALENDAR } from 'app/const/Route';
import IconGorilla from 'assets/icon/IconGorilla';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';

function Avatar() {
    const { pathname, search } = useLocation();

    return (
        <Link
            to={`${addBranchPath(CALENDAR)}${matchPath(pathname, { path: addPrefixPath(CALENDAR) }) ? search : ''}`}
            className="logo"
        >
            <IconGorilla />
        </Link>
    );
}

export default Avatar;
