import React, { useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { reducer } from 'app/const/Reducer';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';

const FieldDropdown = ({ name = '', label = '', options = [] }) => {
    const { t } = useTranslation('customers');
    const refInput = useRef(null);

    const [state, dispatchState] = useReducer(reducer, {
        selected: options[0]
    });

    const { selected: finalSelected } = state;

    const _handleSelect = (value) => {
        refInput.current.value = value;
        dispatchState((prev) => ({ ...prev, selected: options.find((item) => item.value === value) }));
    };

    return (
        <div className="rows">
            <div className="txt">{t(label)}</div>
            <CalendarDropdown
                id={name}
                options={options}
                keyGetValue="value"
                keyGetKey="id"
                keyGetName="name"
                selected={finalSelected.name}
                selectedOption={finalSelected}
                onSelect={_handleSelect}
            />
            <input ref={refInput} name={name} hidden defaultValue={finalSelected.value} />
        </div>
    );
};

export default FieldDropdown;
