import {
    CURRENT_DAY,
    END_OF_LAST_MONTH,
    END_OF_MONTH,
    LAST_30_DAYS,
    START_OF_LAST_3_MONTH,
    START_OF_LAST_6_MONTH,
    START_OF_LAST_MONTH,
    START_OF_MONTH,
    START_OF_YEAR,
    START_OF_LAST_YEAR,
    END_OF_LAST_YEAR,
    START_DATE_LAST_WEEK,
    END_DATE_LAST_WEEK,
    START_DATE_THIS_WEEK,
    END_DATE_THIS_WEEK,
    START_DATE_LAST_7_DAYS,
    START_DATE_ALL_TIME
} from '../App';

export const DATE_RANGE = {
    SET_OPEN: 'set_open',
    CANCEL: 'cancel',
    APPLY: 'apply',
    CLEAR: 'clear',
    SELECT: 'select',
    TO_DAY: 'to_day',
    YESTERDAY: 'yesterday',
    LAST_7_DAYS: 'last_7_days',
    LAST_WEEK: 'last_week',
    THIS_WEEK: 'this_week',
    MONTH_TO_DATE: 'month_to_date',
    PREVIOUS_MONTH: 'previous_month',
    YEAR_TO_DATE: 'year_to_date',
    // New range for v2 follow design
    LAST_30_DAYS: 'last_30_days',
    THIS_MONTH: 'this_month',
    LAST_MONTH: 'last_month',
    LAST_3_MONTH: 'last_3_month',
    LAST_6_MONTH: 'last_6_month',
    THIS_YEAR: 'this_year',
    LAST_YEAR: 'last_year',
    ALL_TIME: 'all_time'
};

export const OPTION_SELECT_DATE_RANGE_PICKER = {
    LAST_30_DAYS: {
        id: 1,
        name: 'last_30_days_date_picker',
        value: DATE_RANGE.LAST_30_DAYS,
        date_range: { start: LAST_30_DAYS._d, end: CURRENT_DAY._d }
    },
    THIS_MONTH: {
        id: 2,
        name: 'this_month_date_picker',
        value: DATE_RANGE.THIS_MONTH,
        date_range: {
            start: START_OF_MONTH._d,
            end: END_OF_MONTH._d
        }
    },
    LAST_MONTH: {
        id: 3,
        name: 'last_month_date_picker',
        value: DATE_RANGE.LAST_MONTH,
        date_range: {
            start: START_OF_LAST_MONTH._d,
            end: END_OF_LAST_MONTH._d
        }
    },
    LAST_3_MONTH: {
        id: 4,
        name: 'last_3_month_date_picker',
        value: DATE_RANGE.LAST_3_MONTH,
        date_range: {
            start: START_OF_LAST_3_MONTH._d,
            end: END_OF_LAST_MONTH._d
        }
    },
    LAST_6_MONTH: {
        id: 5,
        name: 'last_6_month_date_picker',
        value: DATE_RANGE.LAST_6_MONTH,
        date_range: {
            start: START_OF_LAST_6_MONTH._d,
            end: END_OF_LAST_MONTH._d
        }
    },
    THIS_YEAR: {
        id: 6,
        name: 'this_year_date_picker',
        value: DATE_RANGE.THIS_YEAR,
        date_range: {
            start: START_OF_YEAR._d,
            end: CURRENT_DAY._d
        }
    },
    LAST_YEAR: {
        id: 7,
        name: 'last_year_date_picker',
        value: DATE_RANGE.LAST_YEAR,
        date_range: {
            start: START_OF_LAST_YEAR._d,
            end: END_OF_LAST_YEAR._d
        }
    },
    THIS_WEEK: {
        id: 8,
        name: 'this_week_date_picker',
        value: DATE_RANGE.THIS_WEEK,
        date_range: {
            start: START_DATE_THIS_WEEK._d,
            end: END_DATE_THIS_WEEK._d
        }
    },
    LAST_WEEK: {
        id: 9,
        name: 'last_week_date_picker',
        value: DATE_RANGE.LAST_WEEK,
        date_range: {
            start: START_DATE_LAST_WEEK._d,
            end: END_DATE_LAST_WEEK._d
        }
    },
    LAST_7_DAYS: {
        id: 10,
        name: 'last_7_days_date_picker',
        value: DATE_RANGE.LAST_7_DAYS,
        date_range: {
            start: START_DATE_LAST_7_DAYS._d,
            end: CURRENT_DAY._d
        }
    },
    THIS_DATE: {
        id: 11,
        name: 'this_day_date_picker',
        value: DATE_RANGE.TO_DAY,
        date_range: {
            start: CURRENT_DAY._d,
            end: CURRENT_DAY._d
        }
    },
    ALL_TIME: {
        id: 12,
        name: 'all_time_date_picker',
        value: DATE_RANGE.ALL_TIME,
        date_range: {
            start: START_DATE_ALL_TIME._d,
            end: CURRENT_DAY._d
        }
    }
};

export const LIST_OPTIONS_SELECT_DATE_DEFAULT = [
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_WEEK,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_WEEK,
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_30_DAYS,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_3_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_6_MONTH,
    OPTION_SELECT_DATE_RANGE_PICKER.THIS_YEAR,
    OPTION_SELECT_DATE_RANGE_PICKER.LAST_YEAR
];
