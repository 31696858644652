export const TYPE_REPORT_MATERIAL_USE_DETAIL = {
  GET_LIST: "GET_LIST_REPORT_MATERIAL_USE_DETAIL",
  GET_LIST_SERVICE_FILTER_REQUEST: "GET_LIST_SERVICE_FILTER_REQUEST"
}

export const getListReportMaterialUseDetail = (params, actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_MATERIAL_USE_DETAIL.GET_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListServiceFilterRequest = (actionSuccess, actionFailed = () => { }) => {
  return {
    type: TYPE_REPORT_MATERIAL_USE_DETAIL.GET_LIST_SERVICE_FILTER_REQUEST,
    actionSuccess,
    actionFailed
  }
}
