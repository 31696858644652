import React, { useReducer } from 'react';

import IconSearch from 'assets/icon/IconSearch';
import { reducer } from 'app/const/Reducer';
import SearchOption from 'app/modules/report/filter/SearchOption';
import { KEY_CODE_ESCAPE } from 'app/const/Keyboard';

const GDButtonInputSearch = ({
    buttonClassName = 'v2-btn-default --icon-lg',
    inputProps = {
        defaultValue: '',
        parentStyle: 'flex-auto absolute',
        isSearchIcon: true,
        isAlwaysDisplayClose: true,
        autoFocus: true,
        timeDdebound: 0,
        placeholder: 'Search...'
    },
    onSearch = () => {}
}) => {
    const [state, dispatchState] = useReducer(reducer, { toggleSearch: false });
    const { toggleSearch } = state;

    const _handleToggleSearch = () => {
        onSearch('');
        dispatchState({ toggleSearch: !toggleSearch });
    };

    const _handleHandleKeydown = (e) => {
        if (e.keyCode === KEY_CODE_ESCAPE) {
            _handleToggleSearch();
        }
    };

    return (
        <>
            {!toggleSearch ? (
                <div className={buttonClassName} onClick={_handleToggleSearch}>
                    <IconSearch />
                </div>
            ) : (
                <SearchOption
                    {...inputProps}
                    onCloseClick={_handleToggleSearch}
                    onKeyDown={_handleHandleKeydown}
                    onSearch={onSearch}
                />
            )}
        </>
    );
};

export default GDButtonInputSearch;
