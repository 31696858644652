import React, { Suspense, useEffect, useId, useReducer, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import GridEmpty from 'app/components/grid/components/GridEmpty';
import GridLoading from 'app/components/grid/components/GridLoading';
import { PERMISSIONS } from 'app/const/Permissions';
import { reducer } from 'app/const/Reducer';
import { CUSTOM_FIELDS_ORDER } from 'app/const/api/V2';
import { CUSTOM_FIELDS_VIEWS } from 'app/modules/customfields/constant';
import { reorder } from 'app/modules/smartview/components/utils';
import { CUSTOM_FIELDS_ACTIONS } from 'common/redux/actions/customFields';
import { clientQuery } from 'common/utils/ApiUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import Row from './Row';

const HeaderTable = React.lazy(() => import('./HeaderTable'));
const queryAttr = 'data-rbd-drag-handle-draggable-id';
const TableManage = ({
    defaultData = [],
    activeTab,
    isLoading = true,
    isSupperAdmin = false,
    onActions = () => {},
    onEdit = () => {}
}) => {
    const idDraggable = useId();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const [state, dispatchState] = useReducer(reducer, { data: defaultData, selectedIds: [] });
    const { data, selectedIds } = state;

    const isFirstTime = useSelector(({ customFields }) => customFields.isFirstTime);
    const permissionList = useSelector(({ auth }) => auth?.user?.permissions?.enabled);
    const permissionEdit = checkPermission(permissionList, PERMISSIONS.editCustomField);
    const canReorder = activeTab === CUSTOM_FIELDS_VIEWS.ACTIVE;

    const refData = useRef([]);
    const refPlaceHolder = useRef(null);

    useEffect(() => {
        if (defaultData) {
            refData.current = defaultData;
            dispatchState({ data: defaultData, selectedIds: [] });
        }
    }, [defaultData]);

    const _handleUncheckAll = (event) => {
        dispatchState((prevState) => ({
            ...prevState,
            selectedIds: event.target.checked ? data.map((item) => item.id) : []
        }));
    };

    const _handleCheck = (id) => {
        dispatchState((prevState) => {
            const newSelectedIds = selectedIds.includes(id)
                ? selectedIds.filter((selectedId) => selectedId !== id)
                : [...selectedIds, id];
            return { ...prevState, selectedIds: newSelectedIds };
        });
    };

    const _handleDragUpdate = (update) => {
        if (!update.destination) return;
        const draggableId = update.draggableId;
        const initialIndex = update.source.index;
        const destinationIndex = update.destination.index;

        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) return;
        const parentDom = draggedDOM.closest('.tables-list');

        const arr = [...parentDom.children];
        if (initialIndex < destinationIndex) arr.splice(initialIndex, 1);

        const clientY = arr.slice(0, destinationIndex).reduce((total, curr) => {
            const style = curr.currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
        }, 0);

        if (refPlaceHolder.current) {
            const textDiv = refPlaceHolder.current.querySelector('.txt-ellipsis');
            if (textDiv) textDiv.textContent = data[initialIndex].name;
            refPlaceHolder.current.style.display = 'flex';
            refPlaceHolder.current.style.top = `${clientY}px`;
            refPlaceHolder.current.style.left = `${parseFloat(window.getComputedStyle(parentDom).paddingLeft)}px`;
        }
    };

    const _handleReorder = ({ destinationIndex, sourceIndex }) => {
        const items = reorder(data, sourceIndex, destinationIndex);
        const findPreItem = items[destinationIndex - 1];
        const currentItem = items[destinationIndex];

        dispatchState({ data: items });
        const _handleSuccess = () => {
            !isFirstTime && dispatch({ type: CUSTOM_FIELDS_ACTIONS.RESET_CUSTOM_FIELD });
        };

        refData.current = items;
        const dataSubmit = { method: 'PUT', data: { id: currentItem?.id || '', after_id: findPreItem?.id || '' } };
        clientQuery(CUSTOM_FIELDS_ORDER, dataSubmit, _handleSuccess);
    };

    const _handleDragEnd = (result) => {
        if (refPlaceHolder.current) {
            refPlaceHolder.current.style.display = 'none';
            refPlaceHolder.current.style.top = 0;
            refPlaceHolder.current.style.left = 0;
        }

        if (!result.destination) return;
        const destinationIndex = result.destination.index;
        _handleReorder({ destinationIndex, sourceIndex: result.source.index });
    };

    return (
        <>
            <Suspense fallback={null}>
                {isSupperAdmin ? (
                    <HeaderTable
                        isLoading={isLoading}
                        type={activeTab}
                        ids={selectedIds}
                        total={data.length}
                        onActions={onActions}
                        onUncheckAll={_handleUncheckAll}
                    />
                ) : null}
            </Suspense>

            <div className="tables table-multi-column has-checkbox custom-field">
                <div className="rows --fixed --header">
                    {isSupperAdmin ? <div className="col --checkbox" /> : null}
                    {permissionEdit && canReorder ? <div className="col --menu" /> : null}
                    <div className="col">
                        <p className="col-label">{t('label')}</p>
                    </div>
                    <div className="col">
                        <p className="col-label">{t('type')}</p>
                    </div>
                    <div className="col col-value">
                        <p className="col-label">{t('value')}</p>
                    </div>
                </div>

                <DragDropContext onDragEnd={_handleDragEnd} onDragUpdate={_handleDragUpdate}>
                    <Droppable droppableId={idDraggable}>
                        {(provided) => (
                            <div className="tables-list relative" {...provided.droppableProps} ref={provided.innerRef}>
                                {isLoading ? (
                                    <GridLoading />
                                ) : data.length ? (
                                    data.map((item, index) => (
                                        <Draggable
                                            key={item.id}
                                            isDragDisabled={!permissionEdit || !canReorder}
                                            draggableId={item.id}
                                            index={index}
                                        >
                                            {(provided) => (
                                                <Row
                                                    provided={provided}
                                                    {...item}
                                                    permissionEdit={permissionEdit && canReorder}
                                                    isSupperAdmin={isSupperAdmin}
                                                    active={selectedIds.includes(item.id)}
                                                    onCheck={() => _handleCheck(item.id)}
                                                    onOpenEdit={() => onEdit(item)}
                                                />
                                            )}
                                        </Draggable>
                                    ))
                                ) : (
                                    <GridEmpty isFlat />
                                )}
                                {provided.placeholder}
                                <div
                                    ref={refPlaceHolder}
                                    className="rows has-drag"
                                    style={{ position: 'absolute', display: 'none', height: 30, width: '100%' }}
                                />
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    );
};
export default TableManage;
