import IconPlus from 'assets/icon/IconPlus';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SetupEmailProvider from '../context/SetupEmailContext';
import SetupEmailAccount from './setup';

const AddEmail = ({ onAddAccountSuccess = () => {}, onAddAccountFail = () => {} }, ref) => {
    const { t } = useTranslation('setting');
    const refSetup = useRef(null);

    useImperativeHandle(ref, () => ({ _handleOpenSetupModal }));

    const _handleOpenSetupModal = () => refSetup.current._open();

    return (
        <>
            <div className="btn-action">
                <div className="btn-modal v2-btn-default has-icon" onClick={_handleOpenSetupModal}>
                    <IconPlus />
                    {t('add_an_email_account')}
                </div>
            </div>
            <SetupEmailProvider>
                <SetupEmailAccount
                    ref={refSetup}
                    onAddAccountSuccess={onAddAccountSuccess}
                    onAddAccountFail={onAddAccountFail}
                />
            </SetupEmailProvider>
        </>
    );
};

export default forwardRef(AddEmail);
