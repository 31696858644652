import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { InvoiceDetailContext } from '../../contexts/InvoiceDetailContext';
import AbilityPhotos from './images';

const EditInvoiceImages = forwardRef(({ parentType }, ref) => {
    const {
        invoiceDetail: { id: parentId, invoiceImages, job: invoiceJob }
    } = useContext(InvoiceDetailContext);
    const refAbilityPhotos = useRef(null);

    useImperativeHandle(ref, () => ({
        getImages: _getImages
    }));

    useEffect(() => {
        refAbilityPhotos.current && refAbilityPhotos.current.setImages(invoiceImages);
    }, [invoiceImages]);

    function _getImages() {
        return refAbilityPhotos.current ? refAbilityPhotos.current.getImages() : [];
    }

    return (
        <AbilityPhotos
            ref={refAbilityPhotos}
            parentType={parentType}
            parentId={parentId}
            haveFetchList={false}
            isParentEdit
            isInsertImage={!!invoiceJob?.id}
        />
    );
});

export default EditInvoiceImages;
