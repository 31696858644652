export default function IconSquare({ hasColor = false, inactive }) {
    if (inactive)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                    fill="#F0F0F0"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 7C23.6569 7 25 8.34315 25 10V22C25 23.6569 23.6569 25 22 25H10C8.34315 25 7 23.6569 7 22V10C7 8.34315 8.34315 7 10 7H22ZM22 10H10V22H22V10Z"
                    fill="#666666"
                />
                <path
                    d="M17 13H15C13.8954 13 13 13.8954 13 15V17C13 18.1046 13.8954 19 15 19H17C18.1046 19 19 18.1046 19 17V15C19 13.8954 18.1046 13 17 13Z"
                    fill="#666666"
                />
            </svg>
        );
    if (hasColor)
        return (
            <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22 7C23.6569 7 25 8.34315 25 10V22C25 23.6569 23.6569 25 22 25H10C8.34315 25 7 23.6569 7 22V10C7 8.34315 8.34315 7 10 7H22ZM22 10H10V22H22V10Z"
                    fill="black"
                />
                <path
                    d="M17 13H15C13.8954 13 13 13.8954 13 15V17C13 18.1046 13.8954 19 15 19H17C18.1046 19 19 18.1046 19 17V15C19 13.8954 18.1046 13 17 13Z"
                    fill="black"
                />
            </svg>
        );

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.5 4.5H16.5C18.7091 4.5 20.5 6.29086 20.5 8.5V15.5C20.5 17.7091 18.7091 19.5 16.5 19.5H9.5C7.29086 19.5 5.5 17.7091 5.5 15.5V8.5C5.5 6.29086 7.29086 4.5 9.5 4.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 9.5H14.5C15.0523 9.5 15.5 9.94772 15.5 10.5V13.5C15.5 14.0523 15.0523 14.5 14.5 14.5H11.5C10.9477 14.5 10.5 14.0523 10.5 13.5V10.5C10.5 9.94772 10.9477 9.5 11.5 9.5Z"
                stroke="#4D5054"
                strokeWidth="1.3"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
        </svg>
    );
}
