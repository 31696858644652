import { checkCrewUser } from 'app/modules/settings/utils/userCompany';

export const TYPE_ACTION_COMPANY_USERS = {
    GET_LIST_USERS_REQUESTING: 'GET_LIST_USERS_REQUESTING',
    GET_LIST_USERS_SUCCESS: 'GET_LIST_USERS_SUCCESS',
    GET_LIST_USERS_FAILURE: 'GET_LIST_USERS_FAILURE',
    UPDATE_USER_COMPANY: 'UPDATE_USER_COMPANY',
    DELETE_USER_COMPANY: 'DELETE_USER_COMPANY',
    ADD_USER_COMPANY: 'ADD_USER_COMPANY',
    EDIT_USER_COMPANY: 'EDIT_USER_COMPANY',
    UPDATE_CREW_USER: 'UPDATE_CREW_USER'
};

export const getListCompanyUsers = (params, actionSuccess = () => {}, actionFailed = () => {}) => {
    return {
        type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING,
        actionSuccess,
        actionFailed
    };
};

export const updateUserCompany = (payload) => {
    return {
        type: TYPE_ACTION_COMPANY_USERS.EDIT_USER_COMPANY,
        payload,
        actionType: TYPE_ACTION_COMPANY_USERS.UPDATE_USER_COMPANY
    };
};

export const addUserCompany = (payload) => {
    return {
        type: TYPE_ACTION_COMPANY_USERS.EDIT_USER_COMPANY,
        payload,
        actionType: TYPE_ACTION_COMPANY_USERS.ADD_USER_COMPANY
    };
};

export const deleteUserCompany = (payload) => {
    if (checkCrewUser(payload.type)) return { type: TYPE_ACTION_COMPANY_USERS.GET_LIST_USERS_REQUESTING };
    return { type: TYPE_ACTION_COMPANY_USERS.DELETE_USER_COMPANY, payload };
};
