import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';
import { COMMON, LIST_TABLE_ITEM_TYPE } from '../App';

export const GRID_RECURRING_TABLE = {
    header: {
        number: {
            title: 'recurring_invoice',
            style: 'col col-md-plus'
        },
        job: {
            title: 'job',
            style: 'col col-md-plus'
        },
        recurrence: {
            title: 'frequency',
            style: 'col col-md-plus'
        },
        status: {
            title: 'status',
            style: 'col col-sm'
        },
        created: {
            title: 'created',
            style: 'col col-sm'
        },
        upcoming: {
            title: 'up_coming_invoice',
            style: 'col col-md flex-1'
        },
        edit: {
            title: '',
            style: 'col --menu'
        }
    },
    columns: [
        {
            id: 'number',
            title: 'recurring_invoice',
            status: true
        },
        {
            id: 'job',
            title: 'job',
            status: true
        },
        {
            id: 'recurrence',
            title: 'frequency',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'created',
            title: 'created',
            status: true
        },
        {
            id: 'upcoming',
            title: 'up_coming_invoice',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true
        }
    ],
    contentConfig: {
        number: {
            title: 'recurring_invoice',
            style: 'col col-md-plus --name',
            type: LIST_TABLE_ITEM_TYPE.INVOICE
        },
        job: {
            title: 'job',
            style: 'col col-md-plus',
            type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
            firstKey: 'service_name',
            secondKey: 'service.name'
        },
        recurrence: {
            title: 'frequency',
            style: 'col col-md-plus',
            type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
            firstKey: 'recurrence.frequency',
            secondKey: 'recurrence.repeat_text'
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        created: {
            title: 'created',
            style: 'col col-sm'
        },
        upcoming: {
            title: 'up_coming_invoice',
            style: 'col col-md flex-1'
        },
        edit: {
            title: '',
            style: 'col --menu',
            type: LIST_TABLE_ITEM_TYPE.INVOICE_RECURRING_EDIT
        }
    }
};

export const getGridInvoiceTable = (params) => {
    const newContent = {
        number: {
            title: 'invoice',
            style: 'col --name',
            type: LIST_TABLE_ITEM_TYPE.INVOICE
        },
        service_name: {
            title: 'job',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
            firstKey: 'service_name',
            secondKey: 'service.name'
        },
        recurrence: {
            title: 'frequency',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.TWO_ROWS,
            firstKey: 'recurrence.frequency',
            secondKey: 'recurrence.repeat_text'
        },
        created: {
            title: 'date',
            style: 'col'
        },
        total: {
            title: 'total',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.TEXT,
            keyGetValue: 'total.format'
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        quickbooks: {
            title: 'invoice',
            style: 'col',
            type: LIST_TABLE_ITEM_TYPE.QB_SYNC
        },
        view_invoice: {
            style: 'col --name flex-none',
            type: LIST_TABLE_ITEM_TYPE.VIEW_ESTIMATE
        }
    };
    let newColumns = [
        {
            id: 'number',
            title: 'invoice',
            status: true
        },
        {
            id: 'service_name',
            title: 'job',
            status: true
        },
        {
            id: 'recurrence',
            title: 'frequency',
            status: true
        },
        {
            id: 'created',
            title: 'date',
            status: true
        },
        {
            id: 'total',
            title: 'total',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'view_invoice',
            status: true
        }
    ];
    const newHeader = mapCurrentValueHeader(params.order, {
        ...{
            number: {
                title: 'invoice',
                style: 'col',
                actionType: COMMON.ORDER,
                currentValue: COMMON.NOT_SET
            },
            service_name: {
                title: 'job',
                style: 'col',
                actionType: COMMON.ORDER,
                currentValue: COMMON.NOT_SET
            },
            recurrence: {
                title: 'frequency',
                style: 'col'
            },
            created: {
                title: 'date',
                style: 'col',
                actionType: COMMON.ORDER,
                currentValue: COMMON.NOT_SET
            },
            total: {
                title: 'total',
                style: 'col',
                actionType: COMMON.ORDER,
                currentValue: COMMON.NOT_SET
            },
            status: {
                title: 'status',
                style: 'col col-sm',
                actionType: COMMON.ORDER,
                currentValue: COMMON.NOT_SET
            },
            view_invoice: {
                title: '',
                style: 'col --name flex-none'
            }
        }
    });

    const numberInvoice = { ...newContent['number'] };

    if (!params.deleted) {
        newColumns = newColumns.filter((item) => item.id !== 'view_invoice');
    } else {
        numberInvoice.type = null;
        numberInvoice.style = 'col --name';
    }

    return { header: newHeader, columns: newColumns, contentConfig: { ...newContent, number: numberInvoice } };
};

export const getGridColumnSubscription = ({ typeSubscription = 'stripe' }) => ({
    header: {
        name: {
            title: 'subscription_id',
            style: 'col col-md-plus'
        },
        location_id: {
            title: 'location',
            style: 'col col-md-plus'
        },
        user_id: {
            title: 'assigned_to',
            style: 'col col-md-plus'
        },
        status: {
            title: 'status',
            style: 'col col-sm'
        },
        billing: {
            title: 'billing',
            style: 'col col-sm'
        },
        date_created: {
            title: 'created',
            style: 'col'
        },
        payment_method: {
            title: 'payment_method',
            style: 'col col-md'
        },
        upcoming: {
            title: 'upcoming_invoice',
            style: 'col col-lg'
        },
        edit: {
            title: '',
            style: 'col --menu pr-2'
        }
    },
    columns: [
        {
            id: 'name',
            title: 'subscription_id',
            status: true
        },
        {
            id: 'location_id',
            title: 'location_id',
            status: true
        },
        {
            id: 'user_id',
            title: 'user_id',
            status: true
        },
        {
            id: 'status',
            title: 'status',
            status: true
        },
        {
            id: 'billing',
            title: 'billing',
            status: true
        },
        {
            id: 'date_created',
            title: 'date_created',
            status: true
        },
        {
            id: 'payment_method',
            title: 'payment_method',
            status: true
        },
        {
            id: 'upcoming',
            title: 'upcoming',
            status: true
        },
        {
            id: 'edit',
            title: '',
            status: true
        }
    ],
    contentConfig: {
        name: {
            title: 'subscription_id',
            style: 'col col-md-plus --name',
            type: LIST_TABLE_ITEM_TYPE.SUBSCRIPTION
        },
        location_id: {
            title: 'location',
            style: 'col col-md-plus',
            type: LIST_TABLE_ITEM_TYPE.LOCATIONS_SELECT,
            typeSubscription
        },
        user_id: {
            title: 'assigned_to',
            style: 'col col-md-plus',
            type: LIST_TABLE_ITEM_TYPE.ASSIGNEES_SELECT,
            typeSubscription
        },
        status: {
            title: 'status',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        billing: {
            title: 'billing',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.STATUS
        },
        date_created: {
            title: 'date_created',
            style: 'col'
        },
        payment_method: {
            title: 'payment_method',
            style: 'col col-md'
        },
        upcoming: {
            title: 'Upcoming Invoice',
            style: 'col col-lg'
        },
        edit: {
            title: '',
            style: 'col --menu pr-2',
            type: LIST_TABLE_ITEM_TYPE.SUBSCRIPTION_EDIT,
            strategy: 'fixed',
            typeSubscription
        }
    }
});

export const INVOICE_DETAIL_TYPE = {
    ALL: 0,
    NORMAL: 1,
    RECURRING: 2
};
