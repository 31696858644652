import classNames from 'classnames';
import React from 'react';

const TabSwitcher = ({
    isDisable = false,
    isDisableAll = false,
    listTabs = [],
    activeTab = null,
    keyGetValue = 'value',
    keyGetLabel = 'label',
    wrapperClassName = 'tabs',
    buttonClassName = 'tab-items flexcenter gap-2 svg-noeffect',
    onChange = () => {}
}) => {
    return (
        <div className={classNames(wrapperClassName, { 'is-disable': isDisableAll })}>
            <div className="btn-item ml-0">
                {listTabs.map((tab, index) => {
                    return (
                        <div
                            key={tab[keyGetValue]}
                            className={classNames(buttonClassName, {
                                'active-tab-selector': activeTab === tab[keyGetValue],
                                'is-disable': isDisable && !!index
                            })}
                            onClick={() => onChange(tab[keyGetValue])}
                        >
                            {tab.icon}
                            <span>{tab[keyGetLabel]}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default TabSwitcher;
