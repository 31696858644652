import React, { forwardRef, useImperativeHandle, useReducer, useRef, useEffect } from 'react';
import ReactModal from 'react-modal';
import { reducer } from 'app/const/Reducer';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import IconClose from 'assets/icon/IconClose';
import { clientQuery } from 'common/utils/ApiUtils';
import { ADDONS_API_KEYS_LIST, getUpdateAndDeleteAPIKey } from 'app/const/Api';
import ButtonSave from 'app/components/button/ButtonSave.js';
import { ENTER_KEY_CODE } from 'app/const/Keyboard';

const ApiKeyModal = forwardRef(
    (
        {
            modalClassName = 'modal container-modal modal-api-connect open',
            headerClassName = 'header-modal',
            bodyClassName = 'body-modal has-form',
            onSuccess = () => {},
            onFailure = () => {}
        },
        ref
    ) => {
        const refInput = useRef(null);
        const refButtonSave = useRef(null);
        const { t } = useTranslation();
        const [state, dispatchState] = useReducer(reducer, {
            isOpen: false,
            data: {
                name: '',
                id: ''
            },
            type: 'create'
        });

        const { data, type, isOpen } = state;

        const isCreate = type === 'create';

        useEffect(() => {
            if (isOpen) {
                setTimeout(() => {
                    _setDisableSave(!!!data.name);
                }, 0);
            }
        }, [isOpen, data?.name]);

        useImperativeHandle(ref, () => ({ _open, _close }));

        const _open = ({ data, type = 'create' }) => {
            dispatchState((prev) => ({
                ...prev,
                isOpen: true,
                type,
                data: {
                    name: data?.name || '',
                    id: data?.id || ''
                }
            }));
        };
        const _close = () => {
            dispatchState((prev) => ({ ...prev, isOpen: false }));
        };

        const _handleChange = (e) => {
            refInput.current.value = e.target.value;
            _setDisableSave(!!!e.target.value);
        };

        const _setDisableSave = (disabled) => {
            refButtonSave.current.setDisable(disabled);
        };

        const _handleCreateOrUpdateApi = () => {
            clientQuery(
                isCreate ? ADDONS_API_KEYS_LIST : getUpdateAndDeleteAPIKey(data.id),
                { data: { name: refInput.current?.value, status: 1 }, method: isCreate ? 'POST' : 'PUT' },
                (res) => onSuccess(res, type),
                (res) => onFailure(res, type)
            );
        };

        const _handleOnKeyPress = (e) => {
            if (e.keyCode === ENTER_KEY_CODE) {
                _handleCreateOrUpdateApi();
            }
        };

        const modalTitle = isCreate ? t('addons:new_api_key_title') : t('addons:update_api_key_title');
        const btnTitle = isCreate ? t('addons:create_api_key') : t('addons:save');

        if (!isOpen) return null;
        return (
            <ReactModal
                isOpen
                style={{ overlay: { background: 'transparent' } }}
                className={classNames(modalClassName)}
                onRequestClose={_close}
            >
                <div className="modal__overlay bg-fixed" onClick={_close} />
                <div className={'modal__container'}>
                    <div className={classNames(headerClassName)}>
                        <h3 className="header-modal__label">{modalTitle}</h3>
                        <span onClick={_close} className="v2-btn-default --icon-lg --transparent">
                            <IconClose />
                        </span>
                    </div>
                    <div className={classNames(bodyClassName)}>
                        <div className="rows mb-1">
                            <div className="txt fw-normal fs-14 grey-faux-granite">{t('addons:api_key_name')}</div>
                            <input
                                ref={refInput}
                                defaultValue={data.name}
                                onKeyUp={_handleOnKeyPress}
                                autoFocus
                                className="field-input fs-14 black-jungle-green"
                                onChange={_handleChange}
                            />
                        </div>
                    </div>
                    <div className="footer-modal btn-close">
                        <span className="v2-btn-default --noborder --label" onClick={_close}>
                            {t('cancel')}
                        </span>
                        <ButtonSave ref={refButtonSave} title={btnTitle} onSave={_handleCreateOrUpdateApi} />
                    </div>
                </div>
            </ReactModal>
        );
    }
);

export default ApiKeyModal;
