export default function IconPipeline() {
    return (
        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path />
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7143 10H5.28572L3.85494 7.49614C3.474 6.82948 3.95537 6 4.72319 6H19.2768C20.0447 6 20.526 6.82948 20.1451 7.49614L18.7143 10Z"
                fill="#CCCCCC"
            />
            <path
                opacity="0.5"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.14285 15L5.85714 11H18.1429L15.8571 15H8.14285Z"
                fill="#CCCCCC"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9982 20C11.3805 19.9994 10.8104 19.6681 10.5039 19.1318L8.71429 16H15.2857L13.4961 19.1318C13.1896 19.6681 12.6195 19.9994 12.0018 20H11.9982Z"
                fill="#999999"
            />
        </svg>
    );
}
