import React from 'react';

export default function LoadingJobNotes() {
    return (
        <div className="wrap-loading boxs border-bt-grey">
            <div className="content pointer-events-none">
                <div className="header-box">
                    <div className="avt-img" />
                    <div className="flex-1">
                        <div className="loading --animation --line --half" />
                        <div className="loading --animation --line --threefourth" />
                    </div>
                    <div className="loading v2-btn-default --grey btn-x-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
                <div className="wrap-content is-complete">
                    <div className="wrap-content-box bg-transparent">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        </div>
    );
}
