import { reducer } from 'app/const/Reducer';
import ServiceSettingVoip from 'app/services/voip/ServiceSettingVoip';
import React, { Fragment, useEffect, useReducer, useRef } from 'react';
import { Route, matchPath, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import routesInbox from './routesInbox';
import FilterStatus from './header/FilterStatus';
import { useSelector } from 'react-redux';
import { checkIsEnableVoip, checkPermission, getPlanUser } from 'common/utils/PermissionUtils';
import { PERMISSIONS } from 'app/const/Permissions';
import { getLocalStorage, removeLocalStorage } from 'common/utils/LocalStorageUtils';
import { KEY_REPORT_LOCAL_STORAGE } from 'app/const/App';
import { CONST_INBOX_PAGE, FILTER_TYPE_INBOX, LIST_FILTER_STATUS_INBOX, TYPE_FEATURE_SETTING } from './const';
import { INBOX_EMAIL } from 'app/const/addons';
import { INBOX } from 'app/const/Route';
import ErrorPage from '../error';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';
import ListSmartView from '../smartview/components/listSmartView';
import { WithTitle } from 'app/components/routes/RouteWithSubRoutes';
import AlertInboxPage from './components/Alert';
import OpportunitySideBar from './opportunity/OpportunitySideBar';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import ComparisonSidebar from './comparison/ComparisonSidebar';

export const InboxLayout = () => {
    const { t } = useTranslation();
    const { pathname, state: stateLocation } = useLocation();
    const history = useHistory();
    const refFilter = useRef(null);
    const refService = useRef(null);

    const { settings, permissions: userPermissions, profile } = useSelector(({ auth }) => auth.user);
    const currentPlan = getPlanUser(profile);

    const permissionsList = userPermissions?.enabled || [];
    const activeInboxAddon = !!settings.addons.inbox_email;
    const isHavePermissionInbox = checkPermission(permissionsList, PERMISSIONS.viewEmailInbox);
    const isHavePermissionReport = checkPermission(permissionsList, PERMISSIONS.accessReportsTab);
    const isActivateVoip = checkIsEnableVoip(settings, false);

    const paramsReport = getLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX)) || {};
    const parentTypeLocal = paramsReport?.type;

    const itemInbox =
        routesInbox.find((item) => {
            const match = matchPath(pathname, { path: addPrefixPath(item.path), exact: true });
            if (!!match) item.match = match;

            return !!match;
        }) || {};

    const finalParam = itemInbox?.match?.params?.sub;
    const finalItem = LIST_FILTER_STATUS_INBOX.find((item) => item.name === finalParam) || LIST_FILTER_STATUS_INBOX[0];
    const initialValue = {
        type:
            activeInboxAddon && isHavePermissionInbox
                ? parentTypeLocal || FILTER_TYPE_INBOX.ALL
                : parentTypeLocal || FILTER_TYPE_INBOX.TASKS,
        status: itemInbox?.status || finalItem.type
    };

    const [state, dispatchState] = useReducer(reducer, {
        isLoadingSetting: stateLocation?.isLoadingSideBar ?? true,
        ...initialValue,
        features: {
            [TYPE_FEATURE_SETTING.COMPARISON]: true,
            ...stateLocation?.features
        },
        isFetchedFeature: false
    });

    const { status: finalStatus, type: finalType, features: finalFeatures, isLoadingSetting } = state;
    const isNotRouter = !itemInbox.id;
    const isNotAccessComparison =
        itemInbox.id === TYPE_FEATURE_SETTING.COMPARISON && (!isActivateVoip || !isHavePermissionReport);
    const hasAccessSmartViews = (currentPlan?.isGrowthPlan || currentPlan?.isTrial) && !!settings.addons.smart_views;
    const isNotAccessSmartView = itemInbox.id === TYPE_FEATURE_SETTING.SMART_VIEWS && !hasAccessSmartViews;

    useEffect(() => {
        const unregisterHistoryListener = history.listen(({ pathname: nextPathname }) => {
            if (!!matchPath(nextPathname, { path: addPrefixPath(INBOX), exact: true })) {
                dispatchState({ ...initialValue });
                refFilter.current?.changeStatus(initialValue.status);
            }
        });

        return () => {
            unregisterHistoryListener();
        };
    }, []);

    if (
        isNotRouter ||
        isNotAccessComparison ||
        (finalItem.isCheckActivateInbox && !activeInboxAddon) ||
        isNotAccessSmartView
    ) {
        removeLocalStorage(KEY_REPORT_LOCAL_STORAGE.concat('_', CONST_INBOX_PAGE.INBOX));
        return <ErrorPage errorMessage={t('common:page_is_unavailable')} />;
    }

    const _handleChangeStatus = (status) => {
        dispatchState({ status });
    };

    const _handleTrigger = (features) => {
        const { id: key, isUseFeature } = itemInbox;
        if (key !== INBOX_EMAIL && isUseFeature && !features[key]) history.push(addBranchPath(INBOX));
        dispatchState((prev) => {
            return { ...prev, isLoadingSetting: false, features, isFetchedFeature: true };
        });
    };

    const _handleChangeType = (type) => {
        refFilter.current?.changeType(type);
    };

    const _renderSideBarListSmartView = () => {
        if (!hasAccessSmartViews) return null;
        return <ListSmartView />;
    };

    return (
        <Fragment>
            <div className="sidebar-left flex-column gap-4">
                <FilterStatus
                    ref={refFilter}
                    filterStatus={finalStatus}
                    filterType={finalType}
                    isLoadingSetting={isLoadingSetting}
                    onSelect={_handleChangeStatus}
                    features={finalFeatures}
                />
                <ComparisonSidebar isLoadingSetting={isLoadingSetting} features={finalFeatures} />
                <OpportunitySideBar />
                {_renderSideBarListSmartView()}
            </div>
            <AlertInboxPage />
            <Switch>
                {routesInbox.map((route) => (
                    <Route
                        key={route.id}
                        path={addPrefixPath(route.path)}
                        // exact
                        render={() => (
                            <WithTitle title={route.title}>
                                <route.component
                                    statusPage={finalStatus}
                                    typePage={finalType}
                                    param={finalParam}
                                    onChangeType={_handleChangeType}
                                />
                            </WithTitle>
                        )}
                    />
                ))}
            </Switch>
            <ServiceSettingVoip ref={refService} isAllFeature onTrigger={_handleTrigger} />
        </Fragment>
    );
};
