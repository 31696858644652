import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    actionOpenJobPreview,
    actionJobReassign,
    actionUpdateJobStatus,
    actionDeleteJob
} from 'common/redux/actions/job';
import { JOB_ACTIVE } from './const/Job';

const Services = ({ events, onReloadEvents, onRefesh, onUpdateTimeWindow = () => {}, isMap = false }) => {
    const { updateJobStatus, updateJobTimeWindow, jobReAssign, jobDelete, updateJobEmoji } = useSelector(
        ({ jobReducer }) => jobReducer
    );
    const { schedules } = useSelector(({ calendar }) => calendar);
    const { reloadCalendar } = useSelector(({ serviceCalendar }) => serviceCalendar);
    const { newJobData } = useSelector(({ createJobReducer }) => createJobReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        updateJobEmoji && _handleUpdateEmoji(updateJobEmoji);
    }, [updateJobEmoji]);

    useEffect(() => {
        reloadCalendar && onRefesh();
    }, [reloadCalendar]);

    useEffect(() => {
        newJobData && onRefesh();
    }, [newJobData]);

    useEffect(() => {
        updateJobStatus && _handleUpdateStatus(updateJobStatus);
    }, [updateJobStatus]);

    useEffect(() => {
        jobReAssign && _handleJobReAssign(jobReAssign);
    }, [jobReAssign]);

    useEffect(() => {
        // NOTICE: Using for timeoff, event and job
        jobDelete && _handleDeleteJob(jobDelete);
    }, [jobDelete]);

    useEffect(() => {
        updateJobTimeWindow && _handleUpdateTimeWindow(updateJobTimeWindow);
    }, [updateJobTimeWindow]);

    function _handleUpdateEmoji(newData) {
        onReloadEvents(
            events.map((item) => {
                if (item.parent_job_id === newData.id) {
                    return {
                        ...item,
                        emoji: newData.emoji
                    };
                }
                return item;
            })
        );
    }

    function _handleUpdateStatus(jobData) {
        if (isMap) return;
        const { current_job: currentJob, new_job: newJob } = jobData;

        const currentJobData = currentJob.job || {};
        const newJobData = newJob?.job;
        let checkUpdate = false;

        events = events.map((item) => {
            const currentJobId = item.id;

            if (item.id === currentJob.job.id) {
                checkUpdate = true;

                item.status = currentJobData.status;
                item.previously_completed = currentJobData.previously_completed;
                item.editable = currentJob.editable;
                // item.draft_invoice = currentJob.draft_invoice;
                item.colorEvent = currentJob.color || item.colorEvent;
                item.refetchEvents = Date.now();

                // eslint-disable-next-line no-undef
                if (item.id === global.jobPreviewId) {
                    dispatch(actionOpenJobPreview({ ...item, schedule: { ...item.schedule } }));
                }
            }

            if (newJob && newJobData) {
                if (newJobData.id === currentJobId) {
                    checkUpdate = true;

                    item.status = newJobData.status;
                    item.previously_completed = newJobData.previously_completed;
                    item.recurrence = '';
                    item.editable = newJob.editable;
                    // item.draft_invoice = newJob.draft_invoice;
                    item.colorEvent = newJob.color || item.colorEvent;
                    item.refetchEvents = Date.now();
                    item.active_job = JOB_ACTIVE.ACTIVE;
                }
            }
            return item;
        });

        checkUpdate && onReloadEvents(events);
        dispatch(actionUpdateJobStatus(null));
    }

    /**
     * Function change schedule of job.
     * This function call from job assign via reducer job
     * 2 cases: 0 move only, 1 move all
     * If new schedule not include in  list schedule current we will hide modal job preview
     */
    function _handleJobReAssign(infoEvent) {
        const newSchedule = { ...infoEvent.newResource };
        const newScheduleId = newSchedule.id;
        const scheduleExist = schedules.some((item) => newScheduleId === item.id);
        const currentParentJob = infoEvent.event.parent_job_id;
        const currentJobNo = infoEvent.event.job_no;

        if (!infoEvent.all) {
            let jobPrimaryTemp = {};
            const jobIdUpdate = infoEvent?.event?.jobId;

            /**
             * This action check new schedule isexit in list schedules current
             * If true we will find and create new reference for add new
             */
            if (scheduleExist) {
                jobPrimaryTemp = events.find(
                    (eventDetail) =>
                        currentParentJob === eventDetail.parent_job_id &&
                        !eventDetail.previously_completed &&
                        eventDetail.job_no >= currentJobNo &&
                        eventDetail.jobId === jobIdUpdate
                );
            }

            events = events.filter((eventDetail) => {
                if (
                    currentParentJob === eventDetail.parent_job_id &&
                    !eventDetail.previously_completed &&
                    eventDetail.job_no >= currentJobNo &&
                    eventDetail.jobId === jobIdUpdate
                ) {
                    const currentScheduleId = eventDetail.schedule.id;
                    /**
                     * This action will check and remove 2 job.
                     * 1. Job is primary schedule
                     * 2. Job want replace. ( check for 2 cases schedule don't have job )
                     */
                    if (eventDetail.schedule.primary_id !== currentScheduleId && currentScheduleId !== newScheduleId) {
                        const newSchedulePrimary = { ...newSchedule };
                        const newScheduleTemp = { ...eventDetail.schedule };
                        newScheduleTemp.primary_id = newScheduleId;
                        newScheduleTemp.primary = newSchedulePrimary;
                        newScheduleTemp.id = currentScheduleId;
                        eventDetail.schedule = newScheduleTemp;

                        return eventDetail;
                    } else {
                        return false;
                    }
                } else {
                    return eventDetail;
                }
            });

            /**
             * This action will add new after remove max 2 job.
             * We will use job reference for clone to new job with new primary
             * Remove max 2 -> add 1 primary
             */
            if (scheduleExist) {
                const jobPrimaryAdd = { ...jobPrimaryTemp };
                const scheduleAdd = { ...newSchedule };

                events = events.concat({
                    ...jobPrimaryAdd,
                    schedule: {
                        ...scheduleAdd,
                        id: newScheduleId,
                        primary_id: newScheduleId,
                        primary: newSchedule
                    },
                    resourceId: newScheduleId
                });
            } else {
                dispatch(actionOpenJobPreview(null));
            }
        } else {
            const eventsTemp = [...events];
            let eventsClone = [];

            /**
             * This action will get list job of shcedules want change Use this list for reference
             */
            if (scheduleExist) {
                eventsClone = eventsTemp.filter(
                    (eventDetail) =>
                        currentParentJob === eventDetail.parent_job_id &&
                        eventDetail.previously_completed === 0 &&
                        eventDetail.job_no >= currentJobNo
                );
            }

            events = events.filter((eventDetail) => {
                if (
                    currentParentJob === eventDetail.parent_job_id &&
                    eventDetail.previously_completed === 0 &&
                    eventDetail.job_no >= currentJobNo
                ) {
                    const currentScheduleId = eventDetail.schedule.id;
                    if (eventDetail.schedule.primary_id !== currentScheduleId && currentScheduleId !== newScheduleId) {
                        const newSchedulePrimary = { ...newSchedule };
                        const newScheduleTemp = { ...eventDetail.schedule };
                        newScheduleTemp.primary_id = newScheduleId;
                        newScheduleTemp.primary = newSchedulePrimary;
                        newScheduleTemp.id = currentScheduleId;
                        eventDetail.schedule = newScheduleTemp;
                        return eventDetail;
                    }
                } else {
                    return eventDetail;
                }
            });

            if (scheduleExist) {
                let newEvent = [];
                let eventIds = [];

                eventsClone.forEach((item) => {
                    const convertItem = { ...item };
                    convertItem.schedule = {
                        ...newSchedule,
                        id: newScheduleId,
                        primary_id: newScheduleId,
                        primary: newSchedule
                    };
                    convertItem.resourceId = newScheduleId;

                    if (!eventIds.includes(convertItem.event.id)) {
                        newEvent = newEvent.concat(convertItem);
                        eventIds = eventIds.concat(convertItem.event.id);
                    }
                });
                events = events.concat(newEvent);
            } else {
                dispatch(actionOpenJobPreview(null));
            }
        }

        onReloadEvents(events);
        dispatch(actionJobReassign(null));
    }

    function _handleDeleteJob(infoEvent) {
        const currentParentJob = infoEvent.parent_job_id;
        const jobComplete = parseInt(infoEvent.previously_completed);
        const currentJobNo = infoEvent.job_no;

        if (jobComplete === 1) {
            events = events.filter((eventItem) => eventItem.jobId !== infoEvent.id);
        } else {
            events = events.filter((eventDetail) => {
                if (currentParentJob === eventDetail.parent_job_id && eventDetail.job_no >= currentJobNo) {
                    //
                } else {
                    return eventDetail;
                }
            });
        }

        // `infoEvent` Is using for map
        onReloadEvents(events, infoEvent);
        dispatch(actionOpenJobPreview(null));
        dispatch(actionDeleteJob(null));
    }

    const _handleUpdateTimeWindow = (data) => {
        onUpdateTimeWindow({ time_window: data.time_window });
        onReloadEvents(
            events.map((item) => {
                if (item.id === data.id) return { ...item, time_window: data.time_window };
                return item;
            })
        );
    };

    return null;
};

export default Services;
