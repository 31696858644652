import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'common/utils/FunctionUtils';

function GDInputRange({
    classWrapper = 'zoom-bar',
    classSlider = 'slider',
    classContainer = 'slidecontainer',
    leftComponent: LeftComponent,
    rightComponent: RightComponent,
    displayOverlay = false,
    step = 5,
    min = 15,
    max = 50,
    defaultValue = 30,
    debounceTime = 350,
    onChange = () => {}
}) {
    const refRange = useRef(null);
    const refOverlay = useRef(null);

    React.useLayoutEffect(() => {
        refRange.current && refRange.current.addEventListener('wheel', _handleMouseWheel);
    }, []);

    useEffect(() => {
        refRange.current.value = defaultValue;
    }, [defaultValue]);

    const _incrementRange = (value) => {
        let newValue = Number.isNaN(value) ? 0 : value;

        if (newValue) {
            const valueIncrement = refRange.current.valueAsNumber + step;

            if (valueIncrement <= max) {
                newValue = valueIncrement;
            } else {
                newValue = max;
            }
        }

        refRange.current.value = newValue;
        onChange(newValue);
    };

    const _decrementRange = (value) => {
        let newValue = Number.isNaN(value) ? 0 : value;

        if (newValue) {
            const valueDecrement = refRange.current.valueAsNumber - step;
            if (valueDecrement >= min) {
                newValue = valueDecrement;
            } else {
                newValue = min;
            }
        }

        refRange.current.value = newValue;
        onChange(newValue);
    };

    const _handleChange = debounce((event) => {
        onChange(event.target.valueAsNumber);
        if (refOverlay.current && displayOverlay) {
            refOverlay.current.style.width = `${(event.target.valueAsNumber / max) * 100}%`;
        }
    }, debounceTime);

    const _handleMouseWheel = (e) => {
        let result = refRange.current.valueAsNumber;

        if (e.deltaY < 0) {
            result += step;
        } else {
            result -= step;
        }

        onChange(result);
    };

    return (
        <div className={classWrapper}>
            {LeftComponent && <LeftComponent onClick={_decrementRange} />}
            <span className={classContainer}>
                {displayOverlay ? (
                    <div
                        ref={refOverlay}
                        className="range-overlay"
                        style={{ width: `${(+defaultValue / max) * 100}%` }}
                    />
                ) : null}
                <input
                    ref={refRange}
                    type="range"
                    min={min}
                    max={max}
                    step={step}
                    className={classSlider}
                    defaultValue={defaultValue}
                    onChange={_handleChange}
                    spellCheck
                />
            </span>
            {RightComponent && <RightComponent onClick={_incrementRange} />}
        </div>
    );
}

GDInputRange.propTypes = {
    classWrapper: PropTypes.string,
    classSlider: PropTypes.string,
    classContainer: PropTypes.string,
    rangeClick: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func
};

export default GDInputRange;
