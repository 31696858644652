import { LINK_OPEN_GOOGLE_MAP } from 'app/components/dropdown/constant';
import { CUSTOMER_CUSTOM_EVENTS } from 'app/modules/customer/const';
import { combineString } from 'common/utils/ReportUtils';

export const responseToLocationSelected = (response, customerId) => {
    const address = response.address || {};
    return {
        id: response.id,
        name: response.location_name,
        address: combineString([
            address.service.line1,
            address.service.line2,
            address.service.city,
            address.service.state,
            address.service.zip
        ]),
        street1: address.service.line1,
        street2: address.service.line2,
        city: address.service.city,
        state: address.service.state,
        zip: address.service.zip,
        bill_to: address.billing.bill_to,
        billing_address_1: address.billing.line1,
        billing_address_2: address.billing.line2,
        billing_city: address.billing.city,
        billing_state: address.billing.state,
        billing_zip: address.billing.zip,
        customer_id: customerId,
        location_note: response.location_note,
        tags: response.tags
    };
};

export const getQueryLocationMap = (location = {}) => {
    if (typeof location !== 'object') throw new Error('Location must be an object');
    return `${LINK_OPEN_GOOGLE_MAP}${location?.street1 || ''} ${location?.street2 || ''} ${location?.city || ''}, ${
        location?.state || ''
    } ${location?.zip || ''} `;
};

export const displayLocation = ({
    street1,
    street2,
    city,
    state,
    zip,
    withoutHTML = false,
    placeInput = false,
    placeInputSchedule = false
}) => {
    const formattedLocation = `${combineString([city || '', state || ''])} ${zip || ''}`;

    if (placeInput) return `${street1}, ${formattedLocation}`;
    if (withoutHTML) return `${street1}${street2 ? ` ${street2}` : ''} ${formattedLocation}`;
    if (placeInputSchedule) return combineString([street1, [city, state, zip].join(' ')?.trim()]);

    return (
        <>
            {`${street1 || ''}${street2 ? ` ${street2}` : ''}`} <br />
            {formattedLocation}
        </>
    );
};

export const eventLocationDropdown = (detail) => {
    const event = new CustomEvent(CUSTOMER_CUSTOM_EVENTS.OPEN_LOCATION_POPPER, { detail });
    dispatchEvent(event);
};
