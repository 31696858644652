import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CUSTOMERS } from 'app/const/Route';
import IconProfile from 'assets/icon/IconProfile';
import IconUnderscore from 'assets/icon/IconUnderscore';
import IconClose from 'assets/icon/IconClose';
import { addBranchPath } from 'app/const/Branch';

function HeaderActions({ customer, onBookmark = () => {}, onCancel = () => {}, isUnknow }) {
    const urlToCustomer = `${CUSTOMERS}/${customer?.id}`;

    return (
        <div className="wrap-btn">
            {!isUnknow && (
                <Link
                    to={addBranchPath(urlToCustomer)}
                    onClick={onCancel}
                    className="v2-btn-default --icon-sm --transparent btn-plus"
                >
                    <IconProfile />
                </Link>
            )}
            {!isUnknow && (
                <span onClick={onBookmark} className="v2-btn-default --icon-sm --transparent">
                    <IconUnderscore />
                </span>
            )}
            <div onClick={onCancel} className="v2-btn-default --icon-sm --transparent close-sms-step-2">
                <IconClose />
            </div>
        </div>
    );
}

HeaderActions.propTypes = {
    customer: PropTypes.shape({}),
    onClose: PropTypes.func,
    onBookmark: PropTypes.func,
    onCancel: PropTypes.func
};

export default HeaderActions;
