import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from 'app/const/Reducer';
import IconTask from 'assets/icon/IconTask';
import { updateSchedule } from 'common/redux/actions/calendar';
import { getUserInfo } from 'common/utils/CompanyUserUtils';
import { overCountString } from 'common/utils/StringUtils';
import { CALENDAR_MODES, COLOR_SCHEDULES } from '../../const';
import AvatarEvent from './AvatarEvent';
import { RESOURCE_EVENT_STYLE } from './EventStyles';

export function ResourceContent({
    date = null,
    schedules = null,
    isMagnetResource = false,
    isDayResource = false,
    isHorizontal = false,
    tasks = [],
    resource = {},
    onOpenTasks = () => {}
}) {
    const dispatch = useDispatch();
    const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
    const { schedules: calendarSchedules, view } = useSelector(({ calendar }) => calendar);
    const extendedProps = resource?._resource?.extendedProps || {};
    const userId = extendedProps.user_id;

    const _getData = useMemo(() => {
        if (!companyUsers.length) {
            return {};
        }
        return getUserInfo(userId) || {};
    }, [userId, companyUsers]);

    const [state, dispatchState] = useReducer(reducer, {
        user: { name: extendedProps.name },
        totalSms: 0
    });

    const { user } = state;
    const totalTasks = tasks.length || 0;
    let timer = null;

    useEffect(() => {
        if (!isHorizontal) {
            if (timer) {
                clearTimeout(timer);
                return;
            }
            timer = setTimeout(() => {
                const [resourceColumn, resourceLane] = document.querySelectorAll(
                    `[data-resource-id="${resource._resource.id}"]`
                );
                if (resourceColumn) {
                    const childrenCell = resourceColumn.querySelector('.fc-datagrid-cell-frame');
                    if (childrenCell) childrenCell.style.height = `${resourceLane.offsetHeight - 1}px`;
                }
            }, 0);
        }
    });

    useEffect(() => {
        if (!!companyUsers.length) {
            dispatchState({
                user: {
                    ..._getData,
                    currentDate: resource?._context?.calendarApi?.getDate(),
                    schedule: { ...extendedProps, id: resource.id },
                    name: extendedProps.name
                }
            });
        }
    }, [companyUsers]);

    const _handleOpenTasksList = () => {
        totalTasks && onOpenTasks({ tasks, customer: user });
    };

    const _handleResourceClick = () => {
        if (calendarSchedules.length <= 1) return;
        dispatch(updateSchedule({ schedules: [user.schedule] }));
    };

    if (!user) return null;

    if (isHorizontal && resource._resource.parentId.length === 0) {
        const currentDay = moment().startOf('day').format('D');
        const isCurrentDay = moment(date).utc().startOf('day').format('D') === currentDay;

        const styleTodayBG = isCurrentDay
            ? view === CALENDAR_MODES.AGENDA_DAY
                ? {}
                : RESOURCE_EVENT_STYLE.todayBgColor
            : {};
        const userName = user.name || '';
        return (
            <>
                {isDayResource && (
                    <div style={RESOURCE_EVENT_STYLE.colHeader}>
                        <p style={RESOURCE_EVENT_STYLE.colHeaderDate}>{moment(date).utc().format('D')}</p>
                        <p>{moment(date).utc().format('ddd')}</p>
                    </div>
                )}
                <div
                    data-date={moment(date).utc().format('YYYY-MM-DD')}
                    data-resource-id={resource?._resource?.id || ''}
                    style={{ ...RESOURCE_EVENT_STYLE.schedulesWrap, ...styleTodayBG }}
                    onClick={_handleResourceClick}
                >
                    <span style={RESOURCE_EVENT_STYLE.scheduleName} className="user-character">
                        {userName}
                    </span>
                </div>

                <EventColor
                    date={date}
                    resource={resource?._resource || {}}
                    schedules={schedules}
                    isMagnetResource={isMagnetResource}
                />
            </>
        );
    }

    return (
        <Fragment>
            <div className="username hidden-view-large btn-view-tasks">
                <span className="avt-img relative">
                    <AvatarEvent avatar={user.avatar} name={user.name || ''} />
                    <span className="status online" />
                </span>
                <span className="user-info">
                    <span className="name">{user.name}</span>
                </span>
                <span className="col-icon">
                    <span
                        className={classNames('v2-btn-default --icon-lg view-listtask', {
                            'has-notify': !!totalTasks
                        })}
                        onClick={_handleOpenTasksList}
                    >
                        <IconTask />
                        <span className="budget">{overCountString(totalTasks)}</span>
                    </span>
                </span>
            </div>
        </Fragment>
    );
}

const EventColor = ({ resource, date, schedules = null, isMagnetResource = false }) => {
    const rangeTime = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer.rangeTime);
    const calendarSchedules = useSelector(({ calendar }) => calendar.schedules);

    const finalBorderColor = useMemo(() => {
        const schedulesCheck = schedules || calendarSchedules;
        const isOnlyOne = schedulesCheck.length <= 1;
        const indexSchedule =
            isOnlyOne && !isMagnetResource ? 0 : schedulesCheck.findIndex((schedule) => schedule.id === resource.id);

        let borderColor = COLOR_SCHEDULES[indexSchedule % COLOR_SCHEDULES.length];
        if (isMagnetResource) return borderColor;
        const rangeFinal = rangeTime;
        if (isOnlyOne) {
            const index = rangeFinal.findIndex((item) => item === moment.utc(date).startOf('days').unix()) || 0;
            borderColor = COLOR_SCHEDULES[index % COLOR_SCHEDULES.length];
        }

        return borderColor;
    }, [resource, date, rangeTime]);

    const indexRange = useMemo(() => {
        if (Array.isArray(rangeTime))
            return rangeTime.findIndex((item) => item === moment.utc(date).startOf('days').unix()) || 0;
        return 0;
    }, [rangeTime]);

    return (
        <div
            style={{ width: '100%', height: '5px', backgroundColor: finalBorderColor }}
            data-resource-id-color={`${resource.id}_${indexRange}`}
        />
    );
};

// import IconTask from 'assets/icon/IconTask';
// import classNames from 'classnames';
// import { updateSchedule } from 'common/redux/actions/calendar';
// import { overCountString } from 'common/utils/StringUtils';
// import moment from 'moment';
// import React, { useEffect, useMemo, useReducer } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { CALENDAR_MODES } from '../../const';
// import AvatarEvent from './AvatarEvent';
// import { reducer } from 'app/const/Reducer';
// import { RESOURCE_EVENT_STYLE } from './EventStyles';

// export function ResourceContent({
//     date = null,
//     isDayResource = false,
//     isHorizontal = false,
//     tasks = [],
//     resource = {},
//     onOpenTasks = () => {},
//     onUpdateColor = () => {}
// }) {
//     const dispatch = useDispatch();
//     const companyUsers = useSelector(({ companyUsers }) => companyUsers.users);
//     const { schedules: calendarSchedules, view } = useSelector(({ calendar }) => calendar);
//     const rangeTime = useSelector(({ inlineCalendarReducer }) => inlineCalendarReducer.rangeTime);
//     const schedulesList = useSelector(({ schedules }) => schedules.data.schedules);
//     const extendedProps = resource?._resource?.extendedProps || {};
//     const userId = extendedProps.user_id;

//     const _getData = useMemo(() => {
//         return companyUsers.find((user) => user.id === userId) || {};
//     }, [userId, companyUsers]);

//     const color = useMemo(() => {
//         const colors = schedulesList.find((item) => resource.id === item.id)?.colors || [];
//         return colors[+moment.utc(date).format('DD') - 1] || '#e004f9';
//     }, [schedulesList, rangeTime]);

//     const [state, dispatchState] = useReducer(reducer, {
//         user: { ..._getData, name: extendedProps.name },
//         totalSms: 0
//     });

//     const { user } = state;
//     const totalTasks = tasks.length || 0;
//     let timer = null;

//     useEffect(() => {
//         if (!isHorizontal) {
//             if (timer) {
//                 clearTimeout(timer);
//                 return;
//             }
//             timer = setTimeout(() => {
//                 const [resourceColumn, resourceLane] = document.querySelectorAll(
//                     `[data-resource-id="${resource._resource.id}"]`
//                 );
//                 if (resourceColumn) {
//                     const childrenCell = resourceColumn.querySelector('.fc-datagrid-cell-frame');
//                     if (childrenCell) childrenCell.style.height = `${resourceLane.offsetHeight - 1}px`;
//                 }
//             }, 0);
//         }
//     });

//     useEffect(() => {
//         dispatchState({
//             user: {
//                 ..._getData,
//                 currentDate: resource?._context?.calendarApi?.getDate(),
//                 schedule: { ...extendedProps, id: resource.id },
//                 name: extendedProps.name
//             }
//         });
//     }, [companyUsers, calendarSchedules]);

//     const _handleOpenTasksList = () => {
//         totalTasks && onOpenTasks({ tasks, customer: user });
//     };

//     const _handleResourceClick = () => {
//         if (calendarSchedules.length <= 1) return;
//         dispatch(updateSchedule({ schedules: [user.schedule] }));
//     };

//     if (!user) return null;

//     const _handleUpdateColor = (element) => {
//         const dataColors = [];
//         const colors = schedulesList.find((item) => item.id === user.schedule.id)?.colors || [];

//         rangeTime.forEach((item) => {
//             const date = moment.unix(item).utc();
//             const indexRange = +date.format('DD') - 1;
//             dataColors.push({
//                 id: item,
//                 date: date.format('DD MMMM, dddd'),
//                 color: colors[indexRange],
//                 indexRange
//             });
//         });
//         onUpdateColor(element, { user, dataColors });
//     };

//     if (isHorizontal && resource._resource.parentId.length === 0) {
//         const currentDay = moment().startOf('day').format('D');
//         const isCurrentDay = moment(date).utc().startOf('day').format('D') === currentDay;
//         const styleTodayBG = isCurrentDay
//             ? view === CALENDAR_MODES.AGENDA_DAY
//                 ? {}
//                 : RESOURCE_EVENT_STYLE.todayBgColor
//             : {};

//         return (
//             <>
//                 {isDayResource && (
//                     <div style={RESOURCE_EVENT_STYLE.colHeader}>
//                         <p style={RESOURCE_EVENT_STYLE.colHeaderDate}>{moment(date).utc().format('D')}</p>
//                         <p>{moment(date).utc().format('ddd')}</p>
//                     </div>
//                 )}
//                 <div style={{ ...RESOURCE_EVENT_STYLE.schedulesWrap, ...styleTodayBG }} onClick={_handleResourceClick}>
//                     <span style={RESOURCE_EVENT_STYLE.scheduleName}>{user.name}</span>
//                 </div>

//                 <EventColor color={color} onUpdateColor={_handleUpdateColor} />
//             </>
//         );
//     }

//     return (
//         <>
//             <div className="username hidden-view-large btn-view-tasks">
//                 <span className="avt-img relative">
//                     <AvatarEvent avatar={user.avatar} name={user.name || ''} />
//                     <span className="status online" />
//                 </span>
//                 <span className="user-info">
//                     <span className="name">{user.name}</span>
//                 </span>
//                 <span className="col-icon">
//                     <span
//                         className={classNames('v2-btn-default --icon-lg --transparent view-listtask btn-modal', {
//                             'has-notify': !!totalTasks
//                         })}
//                         onClick={_handleOpenTasksList}
//                     >
//                         <IconTask />
//                         <span className="budget">{overCountString(totalTasks)}</span>
//                     </span>
//                 </span>
//             </div>
//         </>
//     );
// }

// const EventColor = ({ color = '#e004f9', onUpdateColor = () => {} }) => {
//     return (
//         <div
//             style={{ width: '100%', height: '5px', cursor: 'pointer', backgroundColor: color }}
//             onClick={(e) => onUpdateColor(e.currentTarget)}
//         />
//     );
// };
