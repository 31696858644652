export const TYPE_JOBLIST_ACTION = {
    ACTION_SELECT_CUSTOMER: 'ACTION_SELECT_CUSTOMER'
};

export const actionCustomerJobList = (params) => {
    return {
        type: TYPE_JOBLIST_ACTION.ACTION_SELECT_CUSTOMER,
        params
    };
};
