import { takeLatest } from "redux-saga/effects";
import { TYPE_NOTI } from "common/redux/actions/notificationAction";
import {
  getCountNotification,
  getListNotification,
  updateMarkReadAndUnRead,
  getListNotificationsSettingRequest,
  updateNotificationsSettingStatus
} from './notification';

export function* notificationWatcher() {
  yield takeLatest(TYPE_NOTI.GET_COUNT_NOTIFICATION_REQUEST, getCountNotification);
  yield takeLatest(TYPE_NOTI.GET_LIST_NOTIFICATION_REQUEST, getListNotification);
  yield takeLatest(TYPE_NOTI.UPDATE_MARK_READ_AND_UNREAD_REQUEST, updateMarkReadAndUnRead);
  yield takeLatest(TYPE_NOTI.GET_LIST_NOTIFICATIONS_SETTING_REQUEST, getListNotificationsSettingRequest);
  yield takeLatest(TYPE_NOTI.UPDATE_NOTIFICATIONS_SETTING_STATUS_REQUEST, updateNotificationsSettingStatus);
}
