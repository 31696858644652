import React from 'react';

const IconMultiBranch = ({ isGray = false, isGreen = false }) => {
    if (isGray)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FAFBFC"
                />
                <path
                    opacity="0.35"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 10C13 10.5523 13.4477 11 14 11H17C18.6569 11 20 12.3431 20 14V19H18V14C18 13.4477 17.5523 13 17 13H14C13.2316 13 12.5308 12.7111 12 12.2361C11.4692 12.7111 10.7684 13 10 13H7C6.44772 13 6 13.4477 6 14V19H4V14C4 12.3431 5.34315 11 7 11H10C10.5523 11 11 10.5523 11 10V5H13V10Z"
                    fill="#5F6164"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM7 19C7 20.1046 6.10457 21 5 21C3.89543 21 3 20.1046 3 19C3 17.8954 3.89543 17 5 17C6.10457 17 7 17.8954 7 19ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"
                    fill="#5F6164"
                />
            </svg>
        );

    if (isGreen)
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} viewBox="0 0 32 32" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0H20C26.6274 0 32 5.37258 32 12V20C32 26.6274 26.6274 32 20 32H12C5.37258 32 0 26.6274 0 20V12Z"
                    fill="#FFF"
                />
                <path
                    opacity="0.35"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17 14C17 14.5523 17.4477 15 18 15H21C22.6569 15 24 16.3431 24 18V23H22V18C22 17.4477 21.5523 17 21 17H18C17.2316 17 16.5308 16.7111 16 16.2361C15.4692 16.7111 14.7684 17 14 17H11C10.4477 17 10 17.4477 10 18V23H8V18C8 16.3431 9.34315 15 11 15H14C14.5523 15 15 14.5523 15 14V9H17V14Z"
                    fill="#1EAA5C"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11ZM11 23C11 24.1046 10.1046 25 9 25C7.89543 25 7 24.1046 7 23C7 21.8954 7.89543 21 9 21C10.1046 21 11 21.8954 11 23ZM25 23C25 24.1046 24.1046 25 23 25C21.8954 25 21 24.1046 21 23C21 21.8954 21.8954 21 23 21C24.1046 21 25 21.8954 25 23Z"
                    fill="#1EAA5C"
                />
            </svg>
        );

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
            <path
                opacity="0.35"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 10C13 10.5523 13.4477 11 14 11H17C18.6569 11 20 12.3431 20 14V19H18V14C18 13.4477 17.5523 13 17 13H14C13.2316 13 12.5308 12.7111 12 12.2361C11.4692 12.7111 10.7684 13 10 13H7C6.44772 13 6 13.4477 6 14V19H4V14C4 12.3431 5.34315 11 7 11H10C10.5523 11 11 10.5523 11 10V5H13V10Z"
                fill="#1EAA5C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7ZM7 19C7 20.1046 6.10457 21 5 21C3.89543 21 3 20.1046 3 19C3 17.8954 3.89543 17 5 17C6.10457 17 7 17.8954 7 19ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"
                fill="#1EAA5C"
            />
        </svg>
    );
};

export default IconMultiBranch;
