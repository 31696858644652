export const TYPE_DRIP_SMART_LISTS = {
  GET_LIST: "DRIP_GET_LIST_SMART_LISTS",
  DELETE_ITEM_SMART_LIST: "DELETE_ITEM_SMART_LIST",
  DUPLICATE_ITEM_SMART_LIST: "DUPLICATE_ITEM_SMART_LIST",
  CREATE_SMART_LIST: "CREATE_SMART_LIST",
  UPDATE_SMART_LIST: "UPDATE_SMART_LIST",

  DRIP_GET_LIST_CUSTOMER: "DRIP_GET_LIST_CUSTOMER",
  UPDATE_TYPE_OF_CUSTOMER: "UPDATE_TYPE_OF_CUSTOMER",
  GET_DETAIL_FILTERS: "GET_DETAIL_FILTERS",

  GET_LIST_FILTERS: "GET_LIST_FILTERS",
  GET_LIST_FILTER_OPTIONS_BY_URI: "GET_LIST_FILTER_OPTIONS_BY_URI",

  GET_LIST_BASIC: "DRIP_GET_LIST_SMART_LISTS_BASIC",
};

// Smart List
export const getListDripSmartLists = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.GET_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const deleteItemSmartList = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.DELETE_ITEM_SMART_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const duplicateSmartList = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.DUPLICATE_ITEM_SMART_LIST,
    params,
    actionSuccess,
    actionFailed,
  };
};

export const createSmartList = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.CREATE_SMART_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const updateSmartList = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.UPDATE_SMART_LIST,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getListDripSmartListsBasicInfo = (
  params,
  actionSuccess,
  actionFailed = () => { }
) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.GET_LIST_BASIC,
    params,
    actionSuccess,
    actionFailed,
  };
};

// Customer
export const getListCustomer = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.DRIP_GET_LIST_CUSTOMER,
    params,
    actionSuccess,
    actionFailed,
  };
}

export const updateStatusCustomer = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.UPDATE_TYPE_OF_CUSTOMER,
    params,
    actionSuccess,
    actionFailed
  }
}

export const getDetailFiltersItemSmartList = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.GET_DETAIL_FILTERS,
    params,
    actionSuccess,
    actionFailed
  }
}



// Filters
export const fetchListFilters = (params, actionSuccess, actionFailed) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.GET_LIST_FILTERS,
    params,
    actionSuccess,
    actionFailed,
  };
}

export const getListFilterOptionsByURI = (
  params,
  actionSuccess,
  actionFailed
) => {
  return {
    type: TYPE_DRIP_SMART_LISTS.GET_LIST_FILTER_OPTIONS_BY_URI,
    params,
    actionSuccess,
    actionFailed,
  };
};
