import React from 'react';

const WorkLogLoading = () => {
    return (
        <div className="boxs-wrapper wrap-loading --addon">
            <div className="boxs boxs--no-border --hasline">
                <div className="boxs__header page-heading">
                    <div className="header-title">
                        <div className="header-title__svg v2-btn-default loading --animation --light --transparent mr-0" />
                        <div className="title flex-column gap-4">
                            <div className="loading --animation --light --line --half" />
                            <div className="v2-btn-default loading --animation --light --transparent field-h16 btn-x-sm" />
                        </div>
                    </div>
                    <div className="loading --onefifth switch ml-0">
                        <div className="loading --animation --light --line --full" />
                    </div>
                </div>
                <div className="boxs__contents mt-3 mb-0">
                    <div className="description loading-lines">
                        <div className="loading --animation --light --line --full" />
                        <div className="loading --animation --light --line --threefourth" />
                    </div>
                </div>
            </div>
            <div className="boxs boxs-has-footer loading">
                <div className="boxs__header">
                    <div className="header-title">
                        <div className="title loading --onetenth ml-0">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="dots powerups loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
                <div className="boxs__contents">
                    <div className="description loading-lines">
                        <div className="loading --animation --line --full" />
                        <div className="loading --animation --line --threefourth" />
                    </div>
                </div>
                <div className="boxs__footer">
                    <div className="wrap-member-group flexcenter flex-1">
                        <div className="avt-img" />
                        <div className="avt-img" />
                        <div className="avt-img" />
                        <div className="avt-img" />
                        <div className="avt-img" />
                    </div>
                    <div className="v2-btn-default field-h24 loading --grey btn-sm">
                        <div className="loading --animation --line --full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ManageLoading = () => {
    return (
        <div className="tables-list wrap-loading">
            <div className="rows">
                <div className="col col-lg">
                    <div className="col-customer flexcenter gap-6">
                        <span className="avt-img" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-rate flexcenter gap-8">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default --grey loading field-h32 flex-1 btn-plus px-0">
                        <div className="v2-btn-default loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading flex-1">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col col-lg">
                    <div className="col-customer flexcenter gap-6">
                        <span className="avt-img" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-rate flexcenter gap-8">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default --grey loading field-h32 flex-1 btn-plus px-0">
                        <div className="v2-btn-default loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading flex-1">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col col-lg">
                    <div className="col-customer flexcenter gap-6">
                        <span className="avt-img" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-rate flexcenter gap-8">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default --grey loading field-h32 flex-1 btn-plus px-0">
                        <div className="v2-btn-default loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading flex-1">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col col-lg">
                    <div className="col-customer flexcenter gap-6">
                        <span className="avt-img" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-rate flexcenter gap-8">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default --grey loading field-h32 flex-1 btn-plus px-0">
                        <div className="v2-btn-default loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading flex-1">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="rows">
                <div className="col col-lg">
                    <div className="col-customer flexcenter gap-6">
                        <span className="avt-img" />
                        <div className="loading --animation --line --twothird" />
                    </div>
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-sm">
                    <div className="loading --animation --line --threefourth" />
                </div>
                <div className="col col-rate flexcenter gap-8">
                    <div className="loading --animation --line --onefourth" />
                    <div className="v2-btn-default --grey loading field-h32 flex-1 btn-plus px-0">
                        <div className="v2-btn-default loading --onefourth">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading flex-1">
                            <div className="loading --animation --line --full" />
                        </div>
                        <div className="v2-btn-default loading --twothird">
                            <div className="loading --animation --line --full" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default WorkLogLoading;
