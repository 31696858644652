import { COMMON, LIST_TABLE_ITEM_TYPE } from 'app/const/App';
import { mapCurrentValueHeader } from 'common/utils/GridViewUtils';

export const getGridColumns = ({ actionsStatus = '' }) => {
    const HEADER_COMPARISON = {
        customer: {
            title: 'name',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        account_number: {
            title: 'account',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        contact: {
            title: 'contact',
            style: 'col col-lg',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        phone: {
            title: 'phone_number',
            style: 'col col-phone',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        date: {
            title: 'date',
            style: 'col col-md',
            actionType: COMMON.ORDER,
            currentValue: COMMON.DESC
        },
        time: {
            title: 'time',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        },
        duration: {
            title: 'duration',
            style: 'col col-sm',
            actionType: COMMON.ORDER,
            currentValue: COMMON.NOT_SET
        }
    };

    const REPORT_COLUMNS_COMPARISON = [
        {
            id: 'customer',
            title: 'name',
            status: true
        },
        {
            id: 'account_number',
            title: 'account',
            status: true
        },
        {
            id: 'contact',
            title: 'contact',
            status: true
        },
        {
            id: 'phone',
            title: 'phone_number',
            status: true
        },
        {
            id: 'date',
            title: 'date',
            status: true
        },
        {
            id: 'time',
            title: 'time',
            status: true
        },
        {
            id: 'duration',
            title: 'duration',
            status: true
        }
    ];

    const REPORT_CONTENT_COMPARISON = {
        customer: {
            id: 'customer',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.NAME_CUSTOMER
        },
        account_number: {
            id: 'account_number',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        contact: {
            id: 'contact',
            style: 'col col-lg',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        phone: {
            id: 'phone',
            style: 'col col-phone',
            type: LIST_TABLE_ITEM_TYPE.PHONE
        },
        date: {
            id: 'date',
            style: 'col col-md',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        time: {
            id: 'time',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.DEFAULT
        },
        duration: {
            id: 'duration',
            style: 'col col-sm',
            type: LIST_TABLE_ITEM_TYPE.TIME_HMS
        }
    };

    return {
        header: mapCurrentValueHeader(actionsStatus, { ...HEADER_COMPARISON }),
        columns: REPORT_COLUMNS_COMPARISON,
        contentConfig: REPORT_CONTENT_COMPARISON
    };
};
