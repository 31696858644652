/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { MESSAGE_TABS } from 'app/modules/calendar/const/Job';
import { useTranslation } from 'react-i18next';

const MessageTabs = ({ type, value = 4, activateSms = false }) => {
    const { t } = useTranslation(['customers']);
    const [selected, setSelected] = useState(value);

    useEffect(() => {
        value !== selected && setSelected(value);
    }, [value]);

    function _handleChangeTab(e, value) {
        e.stopPropagation();
        setSelected(value);
    }

    return (
        <div id={type} data-value-selected={selected} className="field">
            <div className="tabs-daily">
                <div className="btn-item m-0">
                    <button
                        onClick={(e) => _handleChangeTab(e, MESSAGE_TABS.OFF)}
                        className={classNames('tab-items item-off', {
                            'active-tab-selector': selected === MESSAGE_TABS.OFF
                        })}
                    >
                        {t('customers:off')}
                    </button>
                    <button
                        onClick={(e) => _handleChangeTab(e, MESSAGE_TABS.EMAIL)}
                        className={classNames('tab-items', {
                            'active-tab-selector': selected === MESSAGE_TABS.EMAIL
                        })}
                    >
                        {t('customers:email')}
                    </button>
                    {activateSms && (
                        <>
                            <button
                                onClick={(e) => _handleChangeTab(e, MESSAGE_TABS.SMS)}
                                className={classNames('tab-items', {
                                    'active-tab-selector': selected === MESSAGE_TABS.SMS
                                })}
                            >
                                {t('customers:sms')}
                            </button>
                            <button
                                onClick={(e) => _handleChangeTab(e, MESSAGE_TABS.BOTH)}
                                className={classNames('tab-items', {
                                    'active-tab-selector': selected === MESSAGE_TABS.BOTH
                                })}
                            >
                                {t('customers:both')}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MessageTabs;
