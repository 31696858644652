import { call } from 'redux-saga/effects';
import { fetchWithToken } from 'common/utils/ApiUtils';

import { REPORT_SALES_FORECAST_GET_LIST } from 'app/const/Api';

export function* getListReportSalesForecast({
    params,
    actionSuccess,
    actionFailed
}) {
    try {
        const response = yield call(
            fetchWithToken,
            REPORT_SALES_FORECAST_GET_LIST,
            params
        );
        if (response && response.success) {
            actionSuccess(response);
        } else {
            actionFailed(response);
        }
    } catch (error) {
        actionFailed(error);
    }
}
