import React from 'react';
import { useFilters } from '../../context/addFilterContext';
import Category from './Category';

const Categories = () => {
    const { categoryId = null, listOfFilters = [] } = useFilters();
    const finalListFilter = categoryId
        ? listOfFilters.find(({ id }) => id === categoryId)?.categories || []
        : listOfFilters;

    return (
        <ul className="filter-result scrolls">
            {finalListFilter.map((category) => (
                <Category key={category.id} category={category} />
            ))}
        </ul>
    );
};

export default Categories;
