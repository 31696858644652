import classNames from 'classnames';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, matchPath, useLocation } from 'react-router-dom';

import { PERMISSIONS } from 'app/const/Permissions';
import CalendarDropdown from 'app/modules/calendar/components/CalendarDropdown';
import IconDropUpDown from 'assets/icon/IconDropUpDown';
import { checkAddon } from 'common/utils/AddonUtils';
import { checkPermission } from 'common/utils/PermissionUtils';
import AddonsHeaderTabs from '../AddonsHeaderTabs';
import { accessName, mustCheckAccessArray, mustCheckActivePages } from '../MainFeatureHeader';
import { MENUS_PIN_NAME } from '../constants';
import ContentMenu from './ContentMenu';
import MenuWithLink from './MenuWithLink';
import PinButton from './PinButton';
import { addBranchPath, addPrefixPath } from 'app/const/Branch';
import { SETTINGS_MAIN_PATH } from 'app/config/routes';

const MenuWithDropdown = ({
    addons = [],
    typeAccount,
    icon = null,
    isDropdown = false,
    isInsideDropdown = false,
    canEditSetting,
    path = '/',
    name = '',
    idPin = '',
    itemClassName = '',
    onSetRef = () => {},
    onLinkClick = () => {},
    onCloseDropdown = () => {}
}) => {
    const { pathname } = useLocation();
    const user = useSelector((state) => state.auth.user);
    const permissionsList = user?.permissions?.enabled || [];
    const addonsList = user?.settings?.addons || {};
    const refDropdown = useRef(null);

    let isActive = matchPath(pathname, { path });

    if (
        mustCheckActivePages.includes(pathname) &&
        pathname.split('/')[1] === 'reports' &&
        isActive &&
        idPin !== 'leads'
    ) {
        isActive = false;
    }

    /* Check has access to display item */
    const isMustCheckAccess = mustCheckAccessArray.includes(idPin);
    const isSuperAdmin = user.profile.role === 'SuperAdmin';
    const hasAccess =
        idPin === 'settings' && isSuperAdmin
            ? canEditSetting
            : checkPermission(permissionsList, PERMISSIONS[accessName[idPin]]);

    /* If must check access to display and access is not return null */
    if (isMustCheckAccess && !hasAccess && !isSuperAdmin) return null;

    if (
        idPin === 'commissions' &&
        !(checkPermission(permissionsList, PERMISSIONS.admin) && checkAddon(addonsList?.commission_tracking))
    )
        return null;

    if (
        idPin === 'reviews' &&
        !(checkPermission(permissionsList, PERMISSIONS.accessReportsTab) && checkAddon(addonsList?.review_engine))
    )
        return null;
    if (
        idPin === 'leads' &&
        !(
            checkPermission(permissionsList, PERMISSIONS.accessReportsTab) &&
            (checkAddon(addonsList?.customer_portal) ||
                checkAddon(addonsList?.online_booking) ||
                checkAddon(addonsList?.zapier))
        )
    )
        return null;

    if (isInsideDropdown) {
        return (
            <MenuInsideDropdown
                path={path}
                idPin={idPin}
                name={name}
                icon={icon}
                addons={addons}
                typeAccount={typeAccount}
                isActive={isActive}
                itemClassName={itemClassName}
                isDropdown={isDropdown}
                onCloseDropdown={onCloseDropdown}
                onLinkClick={onLinkClick}
            />
        );
    }

    function _closeDropdown() {
        refDropdown.current?._closeDropdown();
    }

    if (!isDropdown)
        return <MenuWithLink onSetRef={onSetRef} name={name} path={path} itemClassName={itemClassName} icon={icon} />;
    let newActive = pathname.includes(idPin);
    if (
        (mustCheckActivePages.includes(pathname) &&
            pathname.split('/')[1] === 'reports' &&
            newActive &&
            idPin !== 'leads') ||
        isDiffPathSetting({ idPin, pathname })
    )
        newActive = false;

    return (
        <CalendarDropdown
            id={`menu_pin_${name}`}
            ref={refDropdown}
            wrapperClassName="wrap-nav nav-menu"
            buttonClassName={classNames('dropbtn v2-btn-default nav mr-1', itemClassName, {
                active: newActive
            })}
            wrapperListClass="v2-dropdown__menu scrolls"
            customDropButton={() => (
                <>
                    {icon || null}
                    <p className="nav__label is-nav-items">{name}</p>
                    <span className="arrow">
                        <IconDropUpDown />
                    </span>
                </>
            )}
        >
            {idPin === MENUS_PIN_NAME.ADDONS ? (
                <AddonsHeaderTabs
                    addons={addons}
                    typeAccount={typeAccount}
                    shouldRemoveSettings
                    onLinkClick={() => {
                        onLinkClick();
                        _closeDropdown();
                    }}
                />
            ) : (
                <ContentMenu name={name} onCloseDropdown={_closeDropdown} />
            )}
        </CalendarDropdown>
    );
};

export const MenuInsideDropdown = ({
    icon = null,
    idPin,
    path = '/',
    isActive = false,
    isDropdown = false,
    name = '',
    addons: addons,
    typeAccount: typeAccount,
    onLinkClick = () => {},
    onCloseDropdown = () => {}
}) => {
    const { pathname } = useLocation();
    const refDropdown = useRef(null);

    const _handleLinkClick = () => {
        onLinkClick();
        onCloseDropdown();
    };
    if (isDropdown) {
        let newActive = pathname.includes(idPin);
        if (
            (mustCheckActivePages.includes(pathname) &&
                pathname.split('/')[1] === 'reports' &&
                newActive &&
                idPin !== 'leads') ||
            isDiffPathSetting({ idPin, pathname })
        )
            newActive = false;

        return (
            <CalendarDropdown
                id={`menu_pin_${name}`}
                ref={refDropdown}
                wrapperClassName="nav-menu__item"
                buttonClassName={classNames('dropbtn items shadow-none border-none relative', {
                    active: newActive
                })}
                wrapperListClass="v2-dropdown__menu"
                customDropButton={() => (
                    <Link
                        to={addBranchPath(path)}
                        className="flexcenter gap-8 flex-1"
                        onClick={(e) => e.preventDefault()}
                    >
                        {icon || null}
                        <p className="txt-ellipsis">{name}</p>
                        <PinButton idPin={idPin} />
                    </Link>
                )}
            >
                {idPin === MENUS_PIN_NAME.ADDONS ? (
                    <AddonsHeaderTabs
                        addons={addons}
                        typeAccount={typeAccount}
                        shouldRemoveSettings
                        onLinkClick={_handleLinkClick}
                    />
                ) : (
                    <ContentMenu name={name} onCloseDropdown={_handleLinkClick} />
                )}
            </CalendarDropdown>
        );
    }

    return (
        <div className="nav-menu__item">
            <div
                className={classNames('items shadow-none border-none relative', {
                    active: isActive
                })}
            >
                <Link to={addBranchPath(path)} className="flexcenter gap-8 flex-1" onClick={_handleLinkClick}>
                    {icon || null}
                    <p className="txt-ellipsis">{name}</p>
                    <PinButton idPin={idPin} defaultPinned={true} />
                </Link>
            </div>
        </div>
    );
};

export default MenuWithDropdown;

const isDiffPathSetting = ({ idPin, pathname }) => {
    return idPin === 'settings' && !matchPath(pathname, { path: addPrefixPath(`${SETTINGS_MAIN_PATH}/:sub`) });
};
