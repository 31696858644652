import React from 'react';

export default function Loading() {
    return (
        <div className="wrap-loading boxs --terms-note ">
            <div className="left-icons">
                <div className="avt-img bg-white" />
            </div>
            <div className="content boxs-file-upload">
                <div className="header-box">
                    <div className="loading --animation --line --onetenth" />
                    <div className="loading v2-btn-default --grey flex-1 ml-4 mr-10 pointer-events-none" />
                </div>
                <div className="border-top-grey-softlight attachment-image p-2">
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                    <div className="attachment-wrap m-0">
                        <div className="attachment-image-items bg-transparent border-grey-softlight pointer-events-none" />
                        <div className="loading --animation --line --full mt-1" />
                        <div className="loading --animation --line --half" />
                    </div>
                </div>
            </div>
        </div>
    );
}
