import { COMMISSION_ACTIONS } from 'common/redux/actions/commission';

const initialState = {
    isFirstTime: true,
    isFirstTimeUsers: true,
    items: [],
    users: []
};

const commissionReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMISSION_ACTIONS.GET_COMMISSION_ITEMS_SUCCESS:
            return {
                ...state,
                items: action.payload || [],
                isFirstTime: false
            };
        case COMMISSION_ACTIONS.GET_COMMISSION_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload || [],
                isFirstTimeUsers: false
            };
        case COMMISSION_ACTIONS.RESET_COMMISSION_DATA:
            return { ...initialState };
        default:
            return state;
    }
};

export default commissionReducer;
